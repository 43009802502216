import React, { FC } from 'react';
import icAddHorizontal from '../../../../../../resources/icons/ic-ribbon-add-horz.svg';
import icAddVertical from '../../../../../../resources/icons/ic-ribbon-add-vert.svg';
import icDeleteVertical from '../../../../../../resources/icons/ic-delete-space-vert.svg';
import icDeleteHorizontal from '../../../../../../resources/icons/ic-delete-space-horiz.svg';
import { createCompactableSelect } from '../../../CompactableSelect/CompactableSelect.component';
import { SpaceAction } from '../../../../../../models/insertionSpace';
import messages from './InsertSpaceSelect.messages';
import { useIntl } from 'react-intl';
import { TCompactableSelectItemType } from '../../../CompactableSelect/CompactableSelect.types';
import { useDispatch } from 'react-redux';
import { processSpaceAction } from '../../../../../../actions/editor.actions';
import menuItemTheme from '../../../items/MenuItem.scss';

interface IInsertionSpaceSelectProps {
    compact?: boolean;
    disabled?: boolean;
}

const InsertionSpaceSelect = createCompactableSelect<SpaceAction>();

export const InsertSpaceSelect: FC<IInsertionSpaceSelectProps> = (props) => {
    const { compact, disabled } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const onSelect = (action: SpaceAction) => {
        dispatch(processSpaceAction(action));
    };

    return (
        <span>
            <div className={menuItemTheme.tooltipContainer} data-test="general-toolbar_insert-space-btn">
                <InsertionSpaceSelect
                    placement="top"
                    title={intl.formatMessage(messages.title)}
                    disabledTitle={intl.formatMessage(messages.onlyInEditModeAvailable)}
                    compact={compact || false}
                    value={SpaceAction.InsertHorizontal}
                    items={[
                        {
                            type: TCompactableSelectItemType.Value,
                            value: SpaceAction.InsertVervical,
                            label: intl.formatMessage(messages.vertically),
                            spriteSymbol: icAddVertical,
                        },
                        {
                            type: TCompactableSelectItemType.Value,
                            value: SpaceAction.InsertHorizontal,
                            label: intl.formatMessage(messages.horizontally),
                            spriteSymbol: icAddHorizontal,
                        },
                        {
                            type: TCompactableSelectItemType.Value,
                            value: SpaceAction.DeleteVertical,
                            label: intl.formatMessage(messages.deleteVertically),
                            spriteSymbol: icDeleteVertical,
                        },
                        {
                            type: TCompactableSelectItemType.Value,
                            value: SpaceAction.DeleteHorizontal,
                            label: intl.formatMessage(messages.deleteHorizontally),
                            spriteSymbol: icDeleteHorizontal,
                        },
                    ]}
                    disabled={disabled}
                    onSelect={onSelect}
                />
            </div>
        </span>
    );
};
