import {
    SUBMIT_ATTRIBUTE_TYPE_GROUP,
    ADD_ATTRIBUTE_TYPE_GROUPS,
    CREATE_ATTRIBUTE_TYPE_GROUP,
    DELETE_ATTRIBUTE_TYPE_GROUP,
    EDIT_ATTRIBUTE_TYPE_GROUP,
    DELETE_ATTRIBUTE_TYPE_GROUP_SUCCESS,
} from '../actionsTypes/attributeTypeGroup.actionTypes';
import {
    TAddAttributeTypeGroupAction,
    TCreateAttributeTypeGroupAction,
    TCreateAttributeTypeGroupPayload,
    TDeleteAttributeTypeGroupAction,
    TDeleteAttributeTypeGroupPayload,
    TDeleteAttributeTypeGroupSuccessAction,
    TEditAttributeTypeGroupAction,
    TEditAttributeTypeGroupPayload,
    TSubmitAttributeTypeGroupAction,
    TSubmitAttributeTypeGroupPayload,
} from './attributeTypeGroup.actions.types';

export const deleteAttributeTypeGroup = (
    payload: TDeleteAttributeTypeGroupPayload,
): TDeleteAttributeTypeGroupAction => ({
    type: DELETE_ATTRIBUTE_TYPE_GROUP,
    payload,
});

export const deleteAttributeTypeGroupSuccess = (
    payload: TDeleteAttributeTypeGroupPayload,
): TDeleteAttributeTypeGroupSuccessAction => ({
    type: DELETE_ATTRIBUTE_TYPE_GROUP_SUCCESS,
    payload,
});

export const createAttributeTypeGroup = (
    payload: TCreateAttributeTypeGroupPayload,
): TCreateAttributeTypeGroupAction => ({
    type: CREATE_ATTRIBUTE_TYPE_GROUP,
    payload,
});

export const submitAttributeTypeGroup = (
    payload: TSubmitAttributeTypeGroupPayload,
): TSubmitAttributeTypeGroupAction => ({
    type: SUBMIT_ATTRIBUTE_TYPE_GROUP,
    payload,
});

export const addAttributeTypeGroups = (payload: TSubmitAttributeTypeGroupPayload): TAddAttributeTypeGroupAction => ({
    type: ADD_ATTRIBUTE_TYPE_GROUPS,
    payload,
});

export const editAttributeTypeGroup = (payload: TEditAttributeTypeGroupPayload): TEditAttributeTypeGroupAction => ({
    type: EDIT_ATTRIBUTE_TYPE_GROUP,
    payload,
});
