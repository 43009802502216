import { IntlShape } from "react-intl";
import { Locale } from "../modules/Header/components/Header/header.types";
import { AttributeTypeSelectPropertyValue } from "../serverapi/api";
import { LocalesService } from "../services/LocalesService";
import edgeNames from '../modules/AdminTools/Methodology/components/Presets/EdgeType/EdgeType.messages';

export enum EdgeArrow {
    none,
    diamond,
    diamondThin,
    block,
    blockThin,
    classic,
    classicThin,
    open,
    openThin,
    oval,
}

export const edgeArrowTypeNames = (locale: Locale, intl: IntlShape): AttributeTypeSelectPropertyValue[] => {
    return Object.values(EdgeArrow)
        .filter(value => typeof value === 'string')
        .map(e => e as string)
        .map(e => {
            return {
                id: e,
                value: LocalesService.changeLocaleValue({}, locale, e && edgeNames[e] && intl.formatMessage(edgeNames[e]) || '')
            };
        });
};
