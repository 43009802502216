import { defineMessages } from 'react-intl';

export default defineMessages({
    generalSettings: {
        id: 'EdgeType.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    stylesSettings: {
        id: 'EdgeType.stylesSettings',
        defaultMessage: 'Стиль связи',
    },
    decompositions: {
        id: 'EdgeType.decompositions',
        defaultMessage: 'Доступные декомпозиции',
    },
    name: {
        id: 'EdgeType.name',
        defaultMessage: 'Название',
    },
    description: {
        id: 'EdgeType.description',
        defaultMessage: 'Описание',
    },
    attributes: {
        id: 'EdgeType.attributes',
        defaultMessage: 'Атрибуты связи',
    },
    elementAttributes: {
        id: 'EdgeType.elementAttributes',
        defaultMessage: 'Атрибуты экземпляра',
    },
    addAttributes: {
        id: 'EdgeType.addAttributes',
        defaultMessage: 'Добавить атрибуты',
    },
    none: {
        id: 'EdgeArrow.none',
        defaultMessage: 'Нет',
    },
    diamond: {
        id: 'EdgeArrow.diamond',
        defaultMessage: 'Ромб',
    },
    diamondThin: {
        id: 'EdgeArrow.diamondThin',
        defaultMessage: 'Ромб тонкий',
    },
    // todo заменить no на none
    no: {
        id: 'EdgeType.no',
        defaultMessage: 'Нет',
    },
    classic: {
        id: 'EdgeType.classic',
        defaultMessage: 'Классический',
    },
    blockThin: {
        id: 'EdgeType.blockThin',
        defaultMessage: 'Блок тонкий',
    },
    openThin: {
        id: 'EdgeType.openThin',
        defaultMessage: 'Открытый тонкий',
    },
    open: {
        id: 'EdgeType.open',
        defaultMessage: 'Открытый',
    },
    oval: {
        id: 'EdgeType.oval',
        defaultMessage: 'Овал',
    },
    classicThin: {
        id: 'EdgeType.classicThin',
        defaultMessage: 'Классический тонкий',
    },
    containment: {
        id: 'EdgeType.containment',
        defaultMessage: 'Сдерживание',
    },
    block: {
        id: 'EdgeType.block',
        defaultMessage: 'Блок',
    },
    typeCommunication: {
        id: 'EdgeType.typeCommunication',
        defaultMessage: 'Тип связи',
    },
    importStyle: {
        id: 'EdgeType.importStyle',
        defaultMessage: 'Загрузить шаблон',
    },
    exportStyle: {
        id: 'EdgeType.exportStyle',
        defaultMessage: 'Сохранить как шаблон',
    },
    fullStyle: {
        id: 'EdgeType.fullStyle',
        defaultMessage: 'Строка стиля',
    },
    lineType: {
        id: 'EdgeType.lineType',
        defaultMessage: 'Тип линии',
    },
    lineWidth: {
        id: 'EdgeType.lineWidth',
        defaultMessage: 'Толщина линии',
    },
    lineAngle: {
        id: 'EdgeType.lineAngle',
        defaultMessage: 'Углы линии',
    },
    lineEnd: {
        id: 'EdgeType.lineEnd',
        defaultMessage: 'Конец линии',
    },
    lineStart: {
        id: 'EdgeType.lineStart',
        defaultMessage: 'Начало линии',
    },
    lineDirection: {
        id: 'EdgeType.lineDirection',
        defaultMessage: 'Направление связи',
    },
    color: {
        id: 'EdgeType.color',
        defaultMessage: 'Цвет',
    },
    preview: {
        id: 'EdgeType.preview',
        defaultMessage: 'Предпросмотр стиля связи',
    },
    errorRender: {
        id: 'EdgeType.errorRender',
        defaultMessage: 'Ошибка отрисовки символа',
    },
    errorRenderDescription: {
        id: 'EdgeType.errorRenderDescription',
        defaultMessage: 'Не удалось сконвертировать символ',
    },
    edgeTypeGroups: {
        id: 'EdgeType.edgeTypeGroups',
        defaultMessage: 'Группа типа связи',
    },
    solid: {
        id: 'EdgeType.solid',
        defaultMessage: 'Сплошная',
    },
    intermittent: {
        id: 'EdgeType.intermittent',
        defaultMessage: 'Прерывистая',
    },
    canBeInvisible: {
        id: 'EdgeType.canBeInvisible',
        defaultMessage: 'Может быть невидимой',
    },
    alwaysCreateDefintion: {
        id: 'EdgeType.alwaysCreateDefintion',
        defaultMessage: 'Создавать определение автоматически',
    },
    requiredField: {
        id: 'EdgeType.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    allowEdgeApproval: {
        id: 'EdgeType.allowEdgeApproval',
        defaultMessage: 'Разрешить согласования типа связи',
    },
    inputEdgeSettingPlaceholder: {
        id: 'ObjectPropertiesDialog.inputEdgeSettingPlaceholder',
        defaultMessage: 'От {min} до {max}',
    },
    matrixStyleTypeLabel: {
        id: 'EdgeType.matrixStyleTypeLabel',
        defaultMessage: 'Представление связи в матричной модели',
    },
    ICON: {
        id: 'EdgeType.ICON',
        defaultMessage: 'Иконка',
    },
    CUSTOM: {
        id: 'EdgeType.CUSTOM',
        defaultMessage: 'Произвольный',
    },
    USER_ICON: {
        id: 'EdgeType.USER_ICON',
        defaultMessage: 'Пользовательский',
    },
    PLUS: {
        id: 'EdgeType.PLUS',
        defaultMessage: 'Плюс',
    },
    MINUS: {
        id: 'EdgeType.MINUS',
        defaultMessage: 'Минус',
    },
    CHECK: {
        id: 'EdgeType.CHECK',
        defaultMessage: 'Галочка',
    },
    CROSS: {
        id: 'EdgeType.CROSS',
        defaultMessage: 'Крестик',
    },
    matrixStyleSymbol: {
        id: 'EdgeType.matrixStyleSymbol',
        defaultMessage: 'Символ',
    },
    uploadText: {
        id: 'EdgeType.uploadText',
        defaultMessage: 'Загрузить файл',
    },
    uploadDescription: {
        id: 'EdgeType.uploadDescription',
        defaultMessage: 'Общий объем не должен превышать',
    },
    uploadDescriptionSize: {
        id: 'EdgeType.uploadDescriptionSize',
        defaultMessage: '50 Мбайт',
    },
    fileMaxSize: {
        id: 'EdgeType.fileMaxSize',
        defaultMessage: 'превышает 50Mb',
    },
});
