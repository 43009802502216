import { switchFocusElement } from '@/utils/elementFocus.utils';
import React, { ReactNode } from 'react';
import classNames from 'classnames';

import theme from './FocusableElementWrapper.scss';

type TFocusableElementWrapperProps = {
    children: ReactNode;
    className?: string;
};

export const FocusableElementWrapper = ({ children, className }: TFocusableElementWrapperProps) => {
    return (
        <div
            aria-selected="true"
            tabIndex={0}
            onKeyDown={(e) => {
                const target = e.target as HTMLElement;

                if (e.key === 'Enter') {
                    switchFocusElement(target);
                    e.stopPropagation();
                }
            }}
            className={classNames(theme.root, className)}
        >
            {children}
        </div>
    );
};
