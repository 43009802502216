import React from 'react';
import type { MenuProps } from 'antd';

type TMenuItem = Required<MenuProps>['items'][number];

export function getMenuItem(
    label: React.ReactNode,
    key: React.Key,
    disabled?: boolean,
    icon?: React.ReactNode,
    children?: TMenuItem[],
    type?: 'group',
    dataTest?: string,
): TMenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
        disabled,
        ['data-test']: dataTest,
    } as TMenuItem;
}
