import { defineMessages } from 'react-intl';

export default defineMessages({
    paragraph: {
        id: 'ParagraphStyleSelect.paragraph',
        defaultMessage: 'Абзац',
    },
    heading1: {
        id: 'ParagraphStyleSelect.heading1',
        defaultMessage: 'Заголовок 1',
    },
    heading2: {
        id: 'ParagraphStyleSelect.heading2',
        defaultMessage: 'Заголовок 2',
    },
    heading3: {
        id: 'ParagraphStyleSelect.heading3',
        defaultMessage: 'Заголовок 3',
    },
    heading4: {
        id: 'ParagraphStyleSelect.heading4',
        defaultMessage: 'Заголовок 4',
    },
    heading5: {
        id: 'ParagraphStyleSelect.heading5',
        defaultMessage: 'Заголовок 5',
    },
    heading6: {
        id: 'ParagraphStyleSelect.heading6',
        defaultMessage: 'Заголовок 6',
    },
});

