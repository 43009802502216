import { TReducer } from '../utils/types';
import { IKanbanModelTypeData, TKanbanModelTypeState } from './kanbanModelType.reducer.types';
import { KANBAN_MODEL_TYPE_REQUEST_SUCCESS } from '../actionsTypes/kanbanModelType.actionTypes';
import { KanbanBoardType } from '../serverapi/api';

const initial: TKanbanModelTypeState = {};

export const INITIAL_KANBAN_MODEL_TYPE_SERVER_STATE: IKanbanModelTypeData = {
    byId: {},
};

export const kanbanModelTypeReducer: TReducer<TKanbanModelTypeState> = (state = initial, action) => {
    switch (action.type) {
        case KANBAN_MODEL_TYPE_REQUEST_SUCCESS: {
            const {
                payload: { kanbanModelTypes, presetId },
            } = action;
            const kanbanModelTypesMap = kanbanModelTypes.reduce(
                (result: {}, item: KanbanBoardType) => ({ ...result, [item.id]: item }),
                {},
            );

            return {
                ...state,
                [presetId]: {
                    byId: {
                        ...kanbanModelTypesMap,
                    },
                },
            };
        }

        default:
            return state;
    }
};
