import classNames from 'classnames';
import React, { ReactNode } from 'react';

import theme from './Notice.scss';
import { Icon } from '@/modules/UIKit/components/Icon/Icon.component';
import errorSvg from '@/resources/icons/UIKit/attention-error.svg';
import infoSvg from '@/resources/icons/UIKit/info.svg';
import successSvg from '@/resources/icons/UIKit/success.svg';

const alertIconTypeMap = {
    success: successSvg,
    error: errorSvg,
    info: infoSvg,
};

interface INoticeProps {
    type?: 'success' | 'info' | 'error';
    children: ReactNode;
    className?: string;
}

export const Notice = ({ type = 'info', children, className }: INoticeProps) => {
    return (
        <div className={classNames(className, theme.root, theme[`root_${type}`])}>
            <Icon className={theme.icon} spriteSymbol={alertIconTypeMap[type]} />
            <div className={theme.text}>{children}</div>
        </div>
    );
};
