import { Collapse, DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import React, { ChangeEvent, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../../../../../models/bpm/bpm-model-impl';
import { TreeNode } from '../../../../../models/tree.types';
import {
    ModelNode,
    ModelType,
    NodeId,
    SimulationRun,
    SimulationRunParameters,
    SimulationSettings,
} from '../../../../../serverapi/api';
import messages from '../../../messages/SimulationModeling.messages';
import { EventParametersTables } from './EventParametersTables.component';
import theme from './SettingsSidebar.scss';
import { SimulationSetupDialogContainer } from '../Dialog/SimulationSetupDialog.container';
import { useDispatch, useSelector } from 'react-redux';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { SimulationModelingSelectors } from '../../../../../../src/selectors/simulationModeling.selectors';
import { SimulationPanel } from '../../SimulationRunHeader/SimulationPanel.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TSimulationSettingsProps = WrappedComponentProps & {
    model?: ModelNode;
    object?: ObjectDefinitionImpl;
    objectDiagramElement?: ObjectInstanceImpl;
    simulationSettings: SimulationSettings;
    simulationNode?: TreeNode;
    modelTypes: Array<ModelType>;
    simulationRuns: Array<SimulationRun>;

    onActiveSimulationChange: (simulationRun: SimulationRun) => void;
    runSimulation: (simulation: SimulationRun) => void;
    addNewSimulation: (simulation: SimulationRun) => void;
    deleteSimulation: (simulation: SimulationRun) => void;
    saveSimulation: (simulation: SimulationRun) => void;
    saveSimulationSettings: (simulationSettings: SimulationSettings) => void;
    getReport: (simulation: SimulationRun) => void;
};

const SettingsSidebarComponent = (props: TSimulationSettingsProps) => {
    const { intl, saveSimulation, deleteSimulation } = props;

    const [simulationSetUpDialogVisible, setSimulationSetUpDialogVisible] = useState<boolean>(false);
    const [isCollapseDisabled, setIsCollapseDisabled] = useState<boolean>(false);
    const [timeStartErrMessageShow, setTimeStartErrMessageShow] = useState<boolean>(false);
    const [timeEndErrMessageShow, setTimeEndErrMessageShow] = useState<boolean>(false);
    const [simulationRuns, setSimulationRuns] = useState<Array<SimulationRun>>(props.simulationRuns);
    const [simulationSettings, setSimulationSettings] = useState<SimulationSettings>(props.simulationSettings);
    const [activeSimulationKey = '', setActiveSimulationKey] = useState<string>();
    const findSimulation = (id) => simulationRuns.find((s) => s.id.id === id);

    const activeSimulation = findSimulation(activeSimulationKey);
    const activeSimulationRun: SimulationRun | undefined = useSelector(
        SimulationModelingSelectors.simulationRunById(
            activeSimulation?.simulationId || ({} as NodeId),
            activeSimulationKey,
        ),
    );

    const dispatch = useDispatch();

    const onActiveSimulationChange = (keys) => {
        const nextSimulationKey = keys.find((key) => key !== activeSimulationKey);
        setActiveSimulationKey(nextSimulationKey);
        const nextSimulation = findSimulation(nextSimulationKey);
        if (nextSimulation) {
            props.onActiveSimulationChange(nextSimulation!);
            props.getReport(nextSimulation);
        }
    };

    const onChangeSimulationRunParameters = (field, value, simulationRun: SimulationRun) => {
        refreshSimulations({
            ...simulationRun,
            simulationRunParameters: { ...simulationRun.simulationRunParameters, [field]: value },
        });
    };

    const renameSimulation = (simulation: SimulationRun, e: ChangeEvent<HTMLInputElement>) => {
        // todo: save on type end
        refreshSimulations({
            ...simulation,
            name: e.target.value,
        });
    };

    const cloneSimulation = (simulation: SimulationRun) => {
        const clone = {
            ...simulation,
            id: {
                ...(simulation.id || { serverId: '', repositoryId: '' }),
                id: uuid(),
            },
            name: intl.formatMessage(messages.cloneName, { name: simulation.name }),
        };
        addSimulation(clone);
    };

    const addSimulation = (simulation?: SimulationRun) => {
        const newSimulation = simulation || {
            simulationId: {
                ...(props.simulationNode?.nodeId || {}),
            } as NodeId,
            id: {
                ...(props.simulationNode?.nodeId || {}),
                id: uuid(),
            } as NodeId,
            name: `${intl.formatMessage(messages.launch)} № ${simulationRuns.length + 1}`,
            simulationRunParameters: {
                startObjectInstances: [],
                endObjectInstances: [],
                timeStart: new Date(),
                timeEnd: new Date(),
                // breakAfterCyclesCount: 1,
                randomInitVector: 10,
            } as SimulationRunParameters,
            result: {},
        };
        setSimulationRuns([...simulationRuns, newSimulation]);
        setActiveSimulation(newSimulation);
        props.addNewSimulation(newSimulation);
    };

    const setActiveSimulation = (newSimulation: SimulationRun) => {
        setActiveSimulationKey(newSimulation.id.id);
        props.onActiveSimulationChange(newSimulation);
    };

    const [form] = Form.useForm();

    const handleSaveSimulation = () => {
        if (!activeSimulation || form.getFieldError(activeSimulation.id.id).length) {
            return;
        }

        if (activeSimulationRun && activeSimulationRun.reportFull) {
            dispatch(openDialog(DialogType.SAVE_SIMULATION_PARAMS_DIALOG, { activeSimulation, saveSimulation }));

            return;
        }
        saveSimulation(activeSimulation);
    };

    const refreshSimulations = (simulationRun: SimulationRun) => {
        setSimulationRuns(simulationRuns.map((r) => (r.id?.id === simulationRun.id?.id ? simulationRun : r)));
    };

    const runSimulation = () => {
        const activeSimulation = findSimulation(activeSimulationKey);
        activeSimulation && props.runSimulation(activeSimulation);
    };

    return (
        <div className={theme.sidebar}>
            <div className={theme.buttonSettingsContainer}>
                {simulationSetUpDialogVisible && (
                    <SimulationSetupDialogContainer
                        simulationSettings={simulationSettings}
                        serverId={props.simulationNode?.nodeId.serverId || ''}
                        modelTypes={props.modelTypes}
                        onSubmit={(simulationSettings: SimulationSettings) => {
                            setSimulationSettings(simulationSettings);
                            setSimulationSetUpDialogVisible(false);
                            props.saveSimulationSettings(simulationSettings);
                        }}
                        onClose={() => {
                            setSimulationSetUpDialogVisible(false);
                        }}
                    />
                )}
                <Button
                    visualStyle="text"
                    onClick={() => {
                        setSimulationSetUpDialogVisible(true);
                    }}
                    dataTest="simulation_open-setup-button"
                >
                    {intl.formatMessage(messages.setupHandler)}
                </Button>
                <Button visualStyle="primary" onClick={() => addSimulation()} dataTest="simulation_add-launch-button">
                    {intl.formatMessage(messages.addSimulation)}
                </Button>
            </div>
            <Collapse
                activeKey={[activeSimulationKey]}
                onChange={onActiveSimulationChange}
                className={theme.settings}
                collapsible={isCollapseDisabled ? 'disabled' : 'header'}
            >
                {simulationRuns
                    .filter((sim) => sim.id.id)
                    .map((sim) => {
                        const { timeStart, timeEnd } = sim.simulationRunParameters;

                        return (
                            <SimulationPanel
                                key={sim.id.id}
                                renameSimulation={renameSimulation}
                                sim={sim}
                                isCollapseDisabled={isCollapseDisabled}
                                setSimulationRuns={setSimulationRuns}
                                cloneSimulation={cloneSimulation}
                                simulationRuns={simulationRuns}
                                deleteSimulation={deleteSimulation}
                                setIsCollapseDisabled={setIsCollapseDisabled}
                                form={form}
                            >
                                <Form autoComplete='off' form={form}>
                                    <div>
                                        <div className={theme.timeChoose}>
                                            <div className={theme.dateSetting} data-test="simulation_start-time">
                                                <p>{intl.formatMessage(messages.timeStart)}</p>
                                                <DatePicker
                                                    showTime
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    value={dayjs(timeStart)}
                                                    onChange={(value) => {
                                                        onChangeSimulationRunParameters(
                                                            'timeStart',
                                                            value?.toDate().toISOString(),
                                                            sim,
                                                        );
                                                        setTimeEndErrMessageShow(false);
                                                        setTimeStartErrMessageShow(
                                                            value ? value?.toDate() > new Date(timeEnd) : true,
                                                        );
                                                    }}
                                                    // onOpenChange={handleStartOpenChange}
                                                />
                                            </div>
                                            {timeStartErrMessageShow && (
                                                <div className={theme.timeErrMessage}>
                                                    {intl.formatMessage(messages.timeStartErr)}
                                                </div>
                                            )}
                                            <div className={theme.dateSetting} data-test="simulation_end-time">
                                                <p>{intl.formatMessage(messages.timeEnd)}</p>
                                                <DatePicker
                                                    showTime
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    value={dayjs(timeEnd)}
                                                    placeholder="End"
                                                    onChange={(value) => {
                                                        onChangeSimulationRunParameters(
                                                            'timeEnd',
                                                            value?.toDate().toISOString(),
                                                            sim,
                                                        );
                                                        setTimeStartErrMessageShow(false);
                                                        setTimeEndErrMessageShow(
                                                            value ? value?.toDate() < new Date(timeStart) : true,
                                                        );
                                                    }}
                                                    // open={endOpen}
                                                    // onOpenChange={handleEndOpenChange}
                                                />
                                            </div>
                                            {timeEndErrMessageShow && (
                                                <div className={theme.timeErrMessage}>
                                                    {intl.formatMessage(messages.timeEndErr)}
                                                </div>
                                            )}
                                        </div>

                                        <EventParametersTables
                                            object={props.object}
                                            model={props.model}
                                            activeSimulation={sim}
                                            objectDiagramElement={props.objectDiagramElement}
                                            saveSimulation={refreshSimulations}
                                        />

                                        <div className={theme.simulationActionButtonGroup}>
                                            <Button
                                                onClick={runSimulation}
                                                disabled={timeStartErrMessageShow || timeEndErrMessageShow}
                                                dataTest="simulation_execute-launch_button"
                                            >
                                                {intl.formatMessage(messages.startSimulation)}
                                            </Button>
                                            <div className={theme.saveSimulation}>
                                                <Button onClick={handleSaveSimulation}>
                                                    {intl.formatMessage(messages.save)}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </SimulationPanel>
                        );
                    })}
            </Collapse>
        </div>
    );
};

const IntlComponent = injectIntl(SettingsSidebarComponent);

export { IntlComponent as SettingsSidebar };
