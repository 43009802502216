import type { IComplexSymbolSerializer } from './ComplexSymbolSerializer.class.types';
import type { ShapeInstance } from '@/serverapi/api';
import type { MxCell } from 'MxGraph';

export default class ShapeInstanceSerializer implements IComplexSymbolSerializer {
    rootCell: MxCell;

    afterCreate(rootCell) {
        this.rootCell = rootCell;
    }

    serialize(): ShapeInstance {
        const root = this.rootCell;
        const rootCellValue = root.getValue();
        const { x, y, width, height } = root.getGeometry();

        return {
            ...rootCellValue,
            parent: root.getParent().getId(),
            style: root.getStyle(),
            x,
            y,
            width,
            height,
        } as ShapeInstance;
    }
}
