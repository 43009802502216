import type { Symbol } from '../../../serverapi/api';
import { defaultPSDGridLayout } from './grid/PSDGridInitial.config';
import GridModelGraph from '../GridModelGraph';
import PSDGrid from './grid/PSDGrid.class';
import { MxCell } from 'MxGraph';
import { GridLayout } from '@/serverapi/api';
import { PSDModelVertexHandler } from './handlers/PSDModelVertexHadler.class';
import { BPMMxVertexHandler } from '@/mxgraph/handler/BPMMxVertexHandler.class';

class PSDModelGraph extends GridModelGraph {
    grid: PSDGrid;
    createGrid(gridLayout: GridLayout = defaultPSDGridLayout, name) {
        this.grid = new PSDGrid(this, gridLayout, name);
    }

    createVertexHandler(state: any): BPMMxVertexHandler {
        return new PSDModelVertexHandler(state);
    }

    getAvailableToReplace(symbol: MxCell, symbols: Symbol[]) {
        const superSymbols = super.getAvailableToReplace(symbol, symbols);

        return this.grid.getAvailableToChangeSymbolTypeType(symbol, superSymbols);
    }

    drawInvisibleEdges(sourceCell: MxCell) {
        // специфика для автоматических связей этой модели
        this.grid?.drawInvisibleEdges(sourceCell);
    }
}

export default PSDModelGraph;
