export const GENERAL_MENU_CLOSE = 'GENERAL_MENU_CLOSE';

export const GENERAL_MENU_COLLAPSE = 'GENERAL_MENU_COLLAPSE';

export const GENERAL_MENU_EXPAND = 'GENERAL_MENU_EXPAND';

export const GENERAL_MENU_OPEN = 'GENERAL_MENU_OPEN';

export const GENERAL_MENU_LABEL_STYLE_CHANGE = 'GENERAL_MENU_LABEL_STYLE_CHANGE';

export const GENERAL_MENU_TOGGLE_STYLE_BUTTONS = 'GENERAL_MENU_TOGGLE_STYLE_BUTTONS';

export const GENERAL_MENU_UPDATE_AVAILABLE_EDGE_TYPES = 'GENERAL_MENU_UPDATE_AVAILABLE_EDGE_TYPES';

export const GENERAL_MENU_CLICK = 'GENERAL_MENU_CLICK';
