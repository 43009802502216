// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutAppDialog__about__XxBBR{text-align:center;margin:10px 0px}", "",{"version":3,"sources":["webpack://./src/modules/AboutAppDialog/components/AboutAppDialog.scss"],"names":[],"mappings":"AAAA,8BACE,iBAAA,CACA,eAAA","sourcesContent":[".about {\r\n  text-align: center;\r\n  margin: 10px 0px;\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": "AboutAppDialog__about__XxBBR"
};
export default ___CSS_LOADER_EXPORT___;
