import { ContentBlock, ContentState, CharacterMetadata } from 'draft-js';
import { LinkRendererContainer } from '../../renderers/Link/LinkRenderer.container';

const findLinkEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
) => {
    contentBlock.findEntityRanges((character: CharacterMetadata) => {
        const entityKey = character.getEntity();

        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK_CUSTOM';
    }, callback);
};

const LinkDecorator = {
    strategy: findLinkEntities,
    component: LinkRendererContainer,
};

export { LinkDecorator };
