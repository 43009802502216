import { TEditReportModelTypeWorkspaceTabState } from './editReportModelTypeWorkspaceTab.reducers.types';
import { AttributeType, AttributeTypeGroup, ReportType } from '../../../serverapi/api';
import { TReportModelTypeWorkspaceTabStoreActions } from '../../../actions/workspaceTab/editReportModelTypeWorkspaceTab.actions.types';
import {
    REPORT_MODEL_TYPE_ADD_ATTRIBUTE_TYPES,
    CHANGE_REPORT_MODEL_TYPE_DESCRIPTION,
    CHANGE_REPORT_MODEL_TYPE_ENABLE_MODEL,
    CHANGE_REPORT_MODEL_TYPE_ID,
    CHANGE_REPORT_MODEL_TYPE_NAME,
    ADD_STORE_EDIT_REPORT_MODEL_TYPE_WORKSPACE_TAB,
    REPORT_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES,
    REPORT_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS,
    CHANGE_REPORT_MODEL_TYPE_ALLOW_APPROVALS,
    CHANGE_REPORT_MODEL_TYPE_GROUP,
} from '../../../actionsTypes/workspaceTab/editReportModelTypeWorkspaceTab.actionTypes';

const initial: TEditReportModelTypeWorkspaceTabState = {} as TEditReportModelTypeWorkspaceTabState;

const setNewData = (
    state: TEditReportModelTypeWorkspaceTabState,
    presetId: string,
    modelTypeId: string,
    modelType: ReportType,
): TEditReportModelTypeWorkspaceTabState => {
    return {
        ...state,
        [presetId]: {
            ...state[presetId],
            [modelTypeId]: modelType,
        },
    };
};

const getModelType = (
    state: TEditReportModelTypeWorkspaceTabState,
    presetId: string,
    modelTypeId: string,
): ReportType => {
    return { ...state[presetId][modelTypeId] };
};

export const editReportModelTypeWorkspaceTabReducer = (
    state = initial,
    action: TReportModelTypeWorkspaceTabStoreActions,
): TEditReportModelTypeWorkspaceTabState => {
    switch (action.type) {
        case ADD_STORE_EDIT_REPORT_MODEL_TYPE_WORKSPACE_TAB: {
            const { modelType, presetId } = action.payload;

            return setNewData(state, presetId, modelType.id, modelType);
        }

        case CHANGE_REPORT_MODEL_TYPE_NAME: {
            const { presetId, modelTypeId, multilingualName } = action.payload;

            const modelType: ReportType = getModelType(state, presetId, modelTypeId);

            const changedModelType: ReportType = { ...modelType, multilingualName };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_REPORT_MODEL_TYPE_ID: {
            const { presetId, modelTypeId, id } = action.payload;

            const modelType: ReportType = getModelType(state, presetId, modelTypeId);

            const changedModelType: ReportType = { ...modelType, id };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_REPORT_MODEL_TYPE_DESCRIPTION: {
            const { presetId, modelTypeId, multilingualDescription } = action.payload;

            const modelType: ReportType = getModelType(state, presetId, modelTypeId);

            const changedModelType: ReportType = { ...modelType, multilingualDescription };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_REPORT_MODEL_TYPE_GROUP: {
            const { presetId, modelTypeId, modelTypeGroup } = action.payload;

            const modelType: ReportType = getModelType(state, presetId, modelTypeId);

            const changedModelType: ReportType = { ...modelType, groupId: modelTypeGroup.id };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_REPORT_MODEL_TYPE_ENABLE_MODEL: {
            const { presetId, modelTypeId, enableModel } = action.payload;

            const modelType: ReportType = getModelType(state, presetId, modelTypeId);

            const changedModelType: ReportType = { ...modelType, enableModel };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case REPORT_MODEL_TYPE_ADD_ATTRIBUTE_TYPES: {
            const { presetId, modelTypeId, attributeTypes } = action.payload;

            const modelType: ReportType = getModelType(state, presetId, modelTypeId);

            const newAttributes: AttributeType[] = (modelType.attributes || []).concat(attributeTypes);

            const changedModelType: ReportType = { ...modelType, attributes: newAttributes };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case REPORT_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES: {
            const { presetId, modelTypeId, attributeTypes } = action.payload;

            const modelType: ReportType = getModelType(state, presetId, modelTypeId);

            const newAttributeTypes: AttributeType[] | undefined = modelType.attributes?.filter(
                (at: AttributeType) => !attributeTypes.some((a) => a.id === at.id),
            );

            const changedModelType: ReportType = { ...modelType, attributes: newAttributeTypes };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case REPORT_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS: {
            const { presetId, modelTypeId, attributeTypeGroups } = action.payload;

            const modelType: ReportType = getModelType(state, presetId, modelTypeId);

            const newAttributeTypes: AttributeTypeGroup[] | undefined = attributeTypeGroups
                ? modelType.attributes?.filter(
                      (at: AttributeType) => !attributeTypeGroups.some((atg) => at.attributeTypeGroup?.id === atg.id),
                  )
                : [];

            const changedModelType: ReportType = { ...modelType, attributes: newAttributeTypes };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_REPORT_MODEL_TYPE_ALLOW_APPROVALS: {
            const { presetId, modelTypeId, allowApprovals } = action.payload;

            const modelType: ReportType = getModelType(state, presetId, modelTypeId);

            const changedModelType: ReportType = { ...modelType, allowApprovals };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        default: {
            return state;
        }
    }
};
