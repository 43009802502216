import { TWorkspaceTab } from '../../../../models/tab.types';
import { NodeId, NodeTypeEnum, ScriptNode } from '../../../../serverapi/api';
import { TreeItemType } from '../../../Tree/models/tree';
import { WorkSpaceTabTypes } from '../../WorkSpaceTabTypesEnum';

export const getItemType = (
    type: WorkSpaceTabTypes,
    content: TWorkspaceTab['content'],
): string | TreeItemType | NodeTypeEnum => {
    if (type === WorkSpaceTabTypes.CONTENT_LOADING_PAGE_TAB) {
        return 'MODEL_LOADING';
    }
    if (type === WorkSpaceTabTypes.DB_SEARCH_DELETED_ELEMENTS) {
        return 'DELETED_ELEMENTS';
    }
    if (type === WorkSpaceTabTypes.MY_APPROVALS) {
        return 'MY_APPROVALS';
    }
    if (
        type === WorkSpaceTabTypes.ADMINTOOLS_TAB ||
        type === WorkSpaceTabTypes.METHODOLOGY_SETTINGS ||
        type === WorkSpaceTabTypes.SAFETY ||
        type === WorkSpaceTabTypes.MONITORING_TAB ||
        type === WorkSpaceTabTypes.DB_SEARCH ||
        type === WorkSpaceTabTypes.IMPORT_VISIO ||
        type === WorkSpaceTabTypes.SERVER_SETTINGS ||
        type === WorkSpaceTabTypes.USER_MANAGMENT ||
        type === WorkSpaceTabTypes.LICENSES ||
        type === WorkSpaceTabTypes.USERDATA_EDITING_TAB ||
        type === WorkSpaceTabTypes.GROUPDATA_EDITING_TAB ||
        type === WorkSpaceTabTypes.LICENSEOWNERS_EDITING_TAB ||
        type === WorkSpaceTabTypes.ACCESS_PERMISSION_OWNERS_EDITING_TAB ||
        type === WorkSpaceTabTypes.DBACCESS_OWNERS_EDITING_TAB ||
        type === WorkSpaceTabTypes.SCRIPT_DASHBOARD ||
        type === WorkSpaceTabTypes.SCRIPT_SCHEDULER ||
        type === WorkSpaceTabTypes.EVENT_ACCESS ||
        type === WorkSpaceTabTypes.HOME_PAGE_TAB ||
        type === WorkSpaceTabTypes.APPROVALS_TAB ||
        type === WorkSpaceTabTypes.NOTIFICATIONS ||
        type === WorkSpaceTabTypes.PUBLISHED_MODELS
    ) {
        return TreeItemType.AdminTool;
    }
    if (type === WorkSpaceTabTypes.COPY_PRESET_TAB) {
        return TreeItemType.Folder;
    }
    if (type === WorkSpaceTabTypes.EDIT_MODEL_TYPE_TAB || type === WorkSpaceTabTypes.EDITOR) {
        return TreeItemType.Model;
    }
    if (type === WorkSpaceTabTypes.SIMULATION_MODELING) {
        return TreeItemType.SimulationModeling;
    }
    if (type === WorkSpaceTabTypes.USER_ACCOUNT) {
        return TreeItemType.UserAccount;
    }
    if (content?.type) {
        return content?.type;
    }

    return TreeItemType.Default;
};

export const getSpecialIcon = (type: WorkSpaceTabTypes, content: TWorkspaceTab['content']): string => {
    if (type === WorkSpaceTabTypes.SCRIPT_EDITOR) {
        return content ? ((content as ScriptNode).language as string) : '';
    }

    return TreeItemType.Default;
};

export const convertNodeIdToString = (nodeId: NodeId | undefined): string => {
    if (!nodeId) return '{}';

    return `{"id":"${nodeId?.id || ''}","repositoryId":"${nodeId?.repositoryId || ''}","serverId":"${
        nodeId?.serverId || ''
    }"}`;
};
