export const SPREADSHEET_OPEN_BY_ID = 'SPREADSHEET_OPEN_BY_ID';

export const SPREADSHEET_LOAD_BY_ID = 'SPREADSHEET_LOAD_BY_ID';

export const SPREADSHEET_EDITOR_MODE_CHANGED = 'SPREADSHEET_EDITOR_MODE_CHANGED';

export const SPREADSHEET_SAVE = 'SPREADSHEET_SAVE';

export const SPREADSHEET_LOCK = 'SPREADSHEET_LOCK';

export const SPREADSHEET_UNLOCK = 'SPREADSHEET_UNLOCK';

export const SPREADSHEET_REMOVE_SUCCESS = 'SPREADSHEET_REMOVE_SUCCESS';

export const SPREADSHEET_SAVE_AND_UNLOCK = 'SPREADSHEET_SAVE_AND_UNLOCK';

export const SPREADSHEET_RENAME = 'SPREADSHEET_RENAME';

export const SPREADSHEET_UPDATE = 'SPREADSHEET_UPDATE';

export const SPREADSHEET_UPDATE_AFTER_EDIT_IN_PROPERTY_TAB = 'SPREADSHEET_UPDATE_AFTER_EDIT_IN_PROPERTY_TAB';
