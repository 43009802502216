// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentsAdditionalMenu__actionIcon__XIWoc{width:16px;height:16px}.CommentsAdditionalMenu__actionIcon__XIWoc+.CommentsAdditionalMenu__actionIcon__XIWoc{margin-left:16px}.CommentsAdditionalMenu__menuItem__rzRHk{font-size:14px;line-height:22px}.CommentsAdditionalMenu__iconContainer___T3cU{width:16px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/modules/Comments/components/CommentsAdditionalMenu/CommentsAdditionalMenu.scss"],"names":[],"mappings":"AAAA,2CACI,UAAA,CACA,WAAA,CAGJ,sFACI,gBAAA,CAGJ,yCACI,cAAA,CACA,gBAAA,CAGJ,8CACI,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".actionIcon {\r\n    width: 16px;\r\n    height: 16px;\r\n}\r\n\r\n.actionIcon + .actionIcon {\r\n    margin-left: 16px;\r\n}\r\n\r\n.menuItem {\r\n    font-size: 14px;\r\n    line-height: 22px;\r\n}\r\n\r\n.iconContainer {\r\n    width: 16px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionIcon": "CommentsAdditionalMenu__actionIcon__XIWoc",
	"menuItem": "CommentsAdditionalMenu__menuItem__rzRHk",
	"iconContainer": "CommentsAdditionalMenu__iconContainer___T3cU"
};
export default ___CSS_LOADER_EXPORT___;
