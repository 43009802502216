import { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { StateObserver } from '../common/sharedState.class';

type TSharedStateProps<T, K> = {
    stateObserver?: StateObserver;
    getFocusedBlock?: (editorState: EditorState) => T;
    getChangedBlock: (EditorState: EditorState, value: K) => EditorState;
};

type TUseSharedState<T, K> = {
    setCurrentState: (value: K) => void;
    value: T;
    editorState: any;
};

type TChangeWithCallback = (editorState: EditorState) => EditorState;

type THooksResources = {
    focusEditor: () => void;
    changeWith: (fn: TChangeWithCallback) => void;
};

export function useSharedState<T, K>({
    stateObserver,
    getFocusedBlock,
    getChangedBlock,
}: TSharedStateProps<T, K>): TUseSharedState<T | undefined, K> {
    const [value, setValue] = useState<T>();
    const [wikiSharedState, setWikiSharedState] = useState<THooksResources>();
    const [editorState, setEditorState] = useState<EditorState>();

    useEffect(() => {
        const handler = (wikiState) => {
            setWikiSharedState(wikiState);
            if (!wikiState) return;
            if (!getFocusedBlock) return;

            const { getEditorState, focusWith } = wikiState;
            setEditorState(getEditorState());

            focusWith(getFocusedBlock).then((block) => {
                setValue(block);
            });
        };
        stateObserver?.attach(handler);

        return () => {
            stateObserver?.detach(handler);
        };
    }, []);

    const setCurrentState = (newValue: K): void => {
        if (!wikiSharedState) {
            return;
        }
        const { focusEditor, changeWith } = wikiSharedState;

        changeWith((_editorState: EditorState) => {
            focusEditor();

            return getChangedBlock(_editorState, newValue);
        });
    };

    return { setCurrentState, value, editorState };
}
