// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AnalysisStep__container__CARi6{width:200px}.AnalysisStep__icon___HSzv,.AnalysisStep__analyses_icon_error__jN7P3,.AnalysisStep__analyses_icon_download___5gnc,.AnalysisStep__analyses_icon_info__eu6nJ{padding-left:5px;font-size:20px}.AnalysisStep__analyses___Aa8D{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.AnalysisStep__analyses_icon_info__eu6nJ{color:#01a101}.AnalysisStep__analyses_icon_download___5gnc{color:var(--mainColor)}.AnalysisStep__analyses_description___ukxH{font-size:16px;color:var(--text)}.AnalysisStep__analyses_icon_error__jN7P3{color:red}.AnalysisStep__analyses_error__ABDQy{font-size:16px;color:red}", "",{"version":3,"sources":["webpack://./src/modules/Import/Aris/components/AnalysisStep/AnalysisStep.scss"],"names":[],"mappings":"AAAA,gCACE,WAAA,CAGF,2JACE,gBAAA,CACA,cAAA,CAGF,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,0BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,yCAEE,aAAA,CAEF,6CAEE,sBAAA,CAEF,2CACE,cAAA,CACA,iBAAA,CAEF,0CAEE,SAAA,CAEF,qCACE,cAAA,CACA,SAAA","sourcesContent":[".container {\r\n  width: 200px;\r\n}\r\n\r\n.icon {\r\n  padding-left: 5px;\r\n  font-size: 20px;\r\n}\r\n\r\n.analyses {\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n  &_icon_info {\r\n    @extend .icon;\r\n    color: #01a101;\r\n  }\r\n  &_icon_download {\r\n    @extend .icon;\r\n    color: var(--mainColor);\r\n  }\r\n  &_description {\r\n    font-size: 16px;\r\n    color: var(--text);\r\n  }\r\n  &_icon_error {\r\n    @extend .icon;\r\n    color: red;\r\n  }\r\n  &_error {\r\n    font-size: 16px;\r\n    color: red;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AnalysisStep__container__CARi6",
	"icon": "AnalysisStep__icon___HSzv",
	"analyses_icon_error": "AnalysisStep__analyses_icon_error__jN7P3",
	"analyses_icon_download": "AnalysisStep__analyses_icon_download___5gnc",
	"analyses_icon_info": "AnalysisStep__analyses_icon_info__eu6nJ",
	"analyses": "AnalysisStep__analyses___Aa8D",
	"analyses_description": "AnalysisStep__analyses_description___ukxH",
	"analyses_error": "AnalysisStep__analyses_error__ABDQy"
};
export default ___CSS_LOADER_EXPORT___;
