import { defineMessages } from 'react-intl';

export default defineMessages({
    actionsAudit: {
        id: 'actionsAudit',
        defaultMessage: 'Аудит действий',
    },
    sessionsList: {
        id: 'sessionsList',
        defaultMessage: 'Сессии пользователей',
    },
    clearJournal: {
        id: 'clearJournal',
        defaultMessage: 'Очистка журнала',
    },
    accessTokens: {
        id: 'accessTokens',
        defaultMessage: 'Ключи доступа',
    },
});
