// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompactableDropdownButton__container___HC_0{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.CompactableDropdownButton__menuIcon__I4h6h{display:inline-block;margin-right:8px;height:16px;width:16px;vertical-align:middle}.CompactableDropdownButton__arrow__D2mPi{margin-left:4px}.CompactableDropdownButton__btn__jq_Gx.ant-btn{width:auto}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/CompactableDropdownButton/CompactableDropdownButton.scss"],"names":[],"mappings":"AAAA,6CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGJ,4CACI,oBAAA,CACA,gBAAA,CACA,WAAA,CACA,UAAA,CACA,qBAAA,CAGJ,yCACI,eAAA,CAIA,+CACI,UAAA","sourcesContent":[".container {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n}\r\n\r\n.menuIcon {\r\n    display: inline-block;\r\n    margin-right: 8px;\r\n    height: 16px;\r\n    width: 16px;\r\n    vertical-align: middle;\r\n}\r\n\r\n.arrow {\r\n    margin-left: 4px;\r\n}\r\n\r\n.btn {\r\n    &:global(.ant-btn) {\r\n        width: auto;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CompactableDropdownButton__container___HC_0",
	"menuIcon": "CompactableDropdownButton__menuIcon__I4h6h",
	"arrow": "CompactableDropdownButton__arrow__D2mPi",
	"btn": "CompactableDropdownButton__btn__jq_Gx"
};
export default ___CSS_LOADER_EXPORT___;
