export type TSideBarMenuItem = {
    id: string;
    titleId: {
        id: string;
    };
};

export enum SideBarItemsId {
    ACTIONS_AUDIT = 'actionsAudit',
    SESSIONS_LIST = 'sessionsList',
    CLEAR_JOURNAL = 'clearJournal',
    ACCESS_TOKENS = 'accessTokens',
}

export type TAvailableSideBarItems = {
    availableMenuItems: TSideBarMenuItem[];
    sideBarItemDefaultId: SideBarItemsId | '';
};
