import type { Symbol } from '@/serverapi/api';
import { MxUtils } from '@/mxgraph/mxgraph';
import { MxConstants } from '@/mxgraph/mxgraph';

export const getDefaultSymbolStyle = (symbol: Symbol | undefined, symbolId: string): string => {
    if (!symbol) {
        return `${symbolId};`;
    }

    const additionalStyle =
        symbol?.style && symbol.style.indexOf('silaText=1') >= 0 ? 'html=1;' : 'html=1;whiteSpace=wrap;';
    let symbolStyle = symbol?.style ? symbol.style : '';
    symbolStyle = MxUtils.setStyle(symbolStyle, MxConstants.STYLE_NOLABEL, Number(!symbol?.showLabel));
    symbolStyle = MxUtils.setStyle(symbolStyle, MxConstants.STYLE_EDITABLE, Number(!!symbol?.showLabel));

    return `${symbolId};${symbolStyle}${additionalStyle}`;
};

