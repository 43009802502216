// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpreadsheetEditorToolbar__modeGroup_full__RPUN5{margin-top:15px}.SpreadsheetEditorToolbar__modeButton__mVQVv svg{height:35px;width:35px}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/SpreadsheetEditorToolbar/SpreadsheetEditorToolbar.scss"],"names":[],"mappings":"AAAA,iDACE,eAAA,CAIA,iDACI,WAAA,CACA,UAAA","sourcesContent":[".modeGroup_full {\r\n  margin-top: 15px;\r\n}\r\n\r\n.modeButton {\r\n  svg {\r\n      height: 35px;\r\n      width: 35px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modeGroup_full": "SpreadsheetEditorToolbar__modeGroup_full__RPUN5",
	"modeButton": "SpreadsheetEditorToolbar__modeButton__mVQVv"
};
export default ___CSS_LOADER_EXPORT___;
