import { NotificationTemplateDTO } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { TTableRowData } from './NotificationTemplatesList.types';
import { Locale } from '@/modules/Header/components/Header/header.types';

export const getFiltredRows = (
    notificationTemplates: NotificationTemplateDTO[],
    searchInput: string,
    currentLocale: Locale,
): TTableRowData[] => {
    return notificationTemplates
        .filter((template) => {
            const name = LocalesService.internationalStringToString(template.name, currentLocale).toLowerCase();

            return name.includes(searchInput);
        })
        .map((notification) => {
            const { id, name } = notification;

            return {
                key: id,
                id,
                name: LocalesService.internationalStringToString(name, currentLocale),
            };
        })
        .sort((a, b) => {
            return a.name.localeCompare(b.name, currentLocale);
        });
};
