import { MxShape, MxSvgCanvas2D, MxRectangle, MxStencil } from '../mxgraph';

export class UMLNote extends MxShape {
    constructor(stencil: MxStencil) {
        super(stencil);
        this.bounds = new MxRectangle(0, 0, 156, 64);
        this.stroke = '#000000';
        this.strokewidth = 1;
    }

    triangleSize = 15;

    paintBackground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        c.translate(x, y);
        c.begin();
        c.moveTo(0, 0);
        c.lineTo(w - this.triangleSize, 0);
        c.lineTo(w, this.triangleSize);
        c.lineTo(w, h);
        c.lineTo(0, h);
        c.lineTo(0, 0);
        c.setFillColor('#87CEEB');
        c.fillAndStroke();
        c.end();

        c.begin();
        c.moveTo(w - this.triangleSize, 0);
        c.lineTo(w - this.triangleSize, this.triangleSize);
        c.lineTo(w, this.triangleSize);
        c.lineTo(w - this.triangleSize, 0);
        c.setFillColor('#FFFFFF');
        c.fillAndStroke();
        c.end();
    }

    paintForeground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        c.fillAndStroke();
    }
}
