import {
    CREATE_SYMBOL_REQUEST,
    EDIT_MODEL_TYPE_EDGE_ATTRIBUTE_TYPE_STYLE,
    EDIT_MODEL_TYPE_SYMBOL_ATTRIBUTE_TYPE_STYLE,
    INIT_SYMBOL_EDITOR,
    SUBMIT_KANBAN_CARD_REQUEST,
    SUBMIT_SYMBOL_REQUEST,
    SYMBOL_EDITOR_UPDATE,
    SYMBOL_SVG_CONVERT_REQUEST_SUCCESS,
    UPLOAD_SYMBOL_SVG_REQUEST,
} from '../../actionsTypes/symbol/symbolEditor.actionTypes';
import {
    TCreateSymbolRequestAction,
    TCreateSymbolRequestPayload,
    TEditModelTypeEdgeAttributeTypeStyleAction,
    TEditModelTypeEdgeAttributeTypeStylePayload,
    TEditModelTypeSymbolAttributeTypeStyleAction,
    TEditModelTypeSymbolAttributeTypeStylePayload,
    TInitSymbolEditorAction,
    TInitSymbolEditorPayload,
    TSubmitKanbanCardRequestAction,
    TSubmitKanbanCardRequestPayload,
    TSubmitSymbolRequestAction,
    TSubmitSymbolRequestPayload,
    TSymbolEditorUpdateAction,
    TSymbolEditorUpdatePayload,
    TSymbolsSVGConvertRequestSuccessAction,
    TSymbolsSVGConvertRequestSuccessPayload,
    TUploadSymbolSVGRequestAction,
    TUploadSymbolSVGRequestPayload,
} from './symbolEditor.actions.types';

export const symbolSVGConvertRequestSuccess = (
    payload: TSymbolsSVGConvertRequestSuccessPayload,
): TSymbolsSVGConvertRequestSuccessAction => ({
    type: SYMBOL_SVG_CONVERT_REQUEST_SUCCESS,
    payload,
});

export const initSymbolEditor = (payload: TInitSymbolEditorPayload): TInitSymbolEditorAction => ({
    type: INIT_SYMBOL_EDITOR,
    payload,
});

export const submitSymbolRequest = (payload: TSubmitSymbolRequestPayload): TSubmitSymbolRequestAction => ({
    type: SUBMIT_SYMBOL_REQUEST,
    payload,
});
export const createSymbolRequest = (payload: TCreateSymbolRequestPayload): TCreateSymbolRequestAction => ({
    type: CREATE_SYMBOL_REQUEST,
    payload,
});

export const editModelTypeSymbolAttributeType = (
    payload: TEditModelTypeSymbolAttributeTypeStylePayload,
): TEditModelTypeSymbolAttributeTypeStyleAction => ({
    type: EDIT_MODEL_TYPE_SYMBOL_ATTRIBUTE_TYPE_STYLE,
    payload,
});

export const editModelTypeEdgeAttributesStyles = (
    payload: TEditModelTypeEdgeAttributeTypeStylePayload,
): TEditModelTypeEdgeAttributeTypeStyleAction => ({
    type: EDIT_MODEL_TYPE_EDGE_ATTRIBUTE_TYPE_STYLE,
    payload,
});

export const uploadSymbolSVGRequest = (payload: TUploadSymbolSVGRequestPayload): TUploadSymbolSVGRequestAction => ({
    type: UPLOAD_SYMBOL_SVG_REQUEST,
    payload,
});

export const submitKanbanCardRequestAction = (
    payload: TSubmitKanbanCardRequestPayload,
): TSubmitKanbanCardRequestAction => ({
    type: SUBMIT_KANBAN_CARD_REQUEST,
    payload,
});

export const symbolEditorUpdate = (payload: TSymbolEditorUpdatePayload): TSymbolEditorUpdateAction => ({
    type: SYMBOL_EDITOR_UPDATE,
    payload,
});
