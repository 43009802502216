// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconTab__buttonContainer___s09w{margin-bottom:20px}.IconTab__dragContainer___7swR{margin:10px 0 10px 0;height:105px;width:300px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/SymbolEditorTab/components/IconTab/IconTab.scss"],"names":[],"mappings":"AAAA,iCACI,kBAAA,CAGJ,+BACI,oBAAA,CACA,YAAA,CACA,WAAA","sourcesContent":[".buttonContainer {\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.dragContainer {\r\n    margin: 10px 0 10px 0;\r\n    height: 105px;\r\n    width: 300px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "IconTab__buttonContainer___s09w",
	"dragContainer": "IconTab__dragContainer___7swR"
};
export default ___CSS_LOADER_EXPORT___;
