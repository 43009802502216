import { v4 as uuid } from 'uuid';
import { takeEvery, select, put, call } from 'redux-saga/effects';
import { createEdgeDefinition, edgeDefinitionsAdd } from '../actions/entities/edgeDefinition.actions';
import {
    TCreateEdgeDefinitionAction,
    TCreateEdgeDefinitionFromMatrixAction,
    TEdgeDefinitionsUpdateAction,
    TInitEdgeDefinitionCreationAction,
    TUpdateEdgeDefinitionNameAction,
} from '../actions/entities/edgeDefinitions.actions.types';
import { treeItemAdd } from '../actions/tree.actions';
import {
    CREATE_EDGE_DEFINITION,
    CREATE_EDGE_DEFINITION_FROM_MATRIX,
    EDGE_DEFINITIONS_UPDATE,
    INIT_EDGE_DEFINITION_CREATION,
    UPDATE_EDGE_DEFINITION_NAME,
} from '../actionsTypes/entities/edgeDefinition.actionTypes';
import { EdgeInstanceImpl } from '../models/bpm/bpm-model-impl';
import { TServerEntity } from '../models/entities.types';
import { TreeNode } from '../models/tree.types';
import { TreeItemType } from '../modules/Tree/models/tree';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { EdgeDefinitionSelectors } from '../selectors/edgeDefinition.selector';
import { EdgeDefinitionNode, ObjectInstance } from '../serverapi/api';
import { LocalesService } from '../services/LocalesService';
import { updateGraph } from './tree.saga';
import { EdgeDefinitionDAOService } from '../services/dao/EdgeDefinitionDAOService';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { updateCellsOverlays, updateDefinitionOverlay } from '../actions/overlay.actions';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';

function* handleInitEdgeDefinitionCreation({ payload }: TInitEdgeDefinitionCreationAction) {
    const { cell, graphId } = payload;

    if (!cell.isEdge()) return;

    const sourceObj: ObjectInstance | undefined = cell.source.getValue();
    const targetObj: ObjectInstance | undefined = cell.target.getValue();

    if (!sourceObj?.objectDefinitionId || !targetObj?.objectDefinitionId) return;

    const instances: EdgeDefinitionNode[] = yield EdgeDefinitionDAOService.searchExistingEdgeDefinitions(
        graphId,
        [sourceObj.objectDefinitionId],
        [targetObj.objectDefinitionId],
    );

    if (!instances.length) {
        yield put(createEdgeDefinition({ graphId, cell }));
        yield put(updateCellsOverlays({ graphId, cells: [cell] }));

        return;
    }

    yield put(
        openDialog(DialogType.SELECT_EDGE_DIALOG, {
            instances,
            cellId: cell.id,
            sourceObjectDefinitionId: sourceObj.objectDefinitionId,
            targetObjectDefinitionId: targetObj.objectDefinitionId,
        }),
    );
}

function* handleCreateEdgeDefinition({ payload }: TCreateEdgeDefinitionAction) {
    const { cell, graphId } = payload;

    const value = cell.getValue();

    if (!(value instanceof EdgeInstanceImpl)) return;

    const sourceObj: ObjectInstance | undefined = cell.source.getValue();
    const targetObj: ObjectInstance | undefined = cell.target.getValue();

    if (!sourceObj?.objectDefinitionId) return;

    const { name, multilingualName, edgeTypeId } = value;

    const edgeDefinition: EdgeDefinitionNode = yield EdgeDefinitionDAOService.createEdgeDefinition(graphId.serverId, {
        type: TreeItemType.EdgeDefinition,
        nodeId: {
            ...graphId,
            id: uuid(),
        },
        parentNodeId: {
            ...graphId,
            id: sourceObj.objectDefinitionId,
        },
        name: name || '',
        multilingualName,
        edgeTypeId,
        sourceObjectDefinitionId: sourceObj.objectDefinitionId,
        targetObjectDefinitionId: targetObj?.objectDefinitionId,
    });

    yield put(edgeDefinitionsAdd([edgeDefinition]));

    const graph = instancesBPMMxGraphMap.get(graphId);

    if (graph) {
        graph.getModel().beginUpdate();

        try {
            const edgeValue = new EdgeInstanceImpl({
                ...value,
                edgeDefinitionId: edgeDefinition.nodeId.id,
            });

            graph.getModel().setValue(cell, edgeValue);
            /*
                  FIXME:  BPM-6195 Костыль для тулбара "Формат".
                  Нужен для блокировки кнопок изменения стиля связи при создании определения.
                  Вызывает перерендер тулбара
                */
            graph.setSelectionCells(graph.getSelectionCells());
        } finally {
            graph.getModel().endUpdate();
        }
    }

    yield put(treeItemAdd(<TreeNode>edgeDefinition));
    yield put(updateDefinitionOverlay(edgeDefinition.nodeId));
}

function* handleUpdateEdgeDefinitionName(action: TUpdateEdgeDefinitionNameAction) {
    const { serverId, edgeDefinition, name } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    yield server.api.tree.rename({
        repositoryId: edgeDefinition.nodeId.repositoryId,
        nodeId: edgeDefinition.nodeId.id,
        body: name,
    });

    const uptatedEdgeDefinition: EdgeDefinitionNode = {
        ...edgeDefinition,
        name,
        multilingualName: LocalesService.changeLocaleValue(
            edgeDefinition.multilingualName,
            LocalesService.getLocale(),
            name,
        ),
    };

    yield put(edgeDefinitionsAdd([uptatedEdgeDefinition]));
    yield put(treeItemAdd(<TreeNode>uptatedEdgeDefinition));
    yield call(updateGraph, edgeDefinition, uptatedEdgeDefinition);
}

function* handleEdgeDefinitionUpdate(action: TEdgeDefinitionsUpdateAction) {
    const { edgeDefinition } = action.payload;
    const { serverId } = edgeDefinition.nodeId;
    const existing = yield select(EdgeDefinitionSelectors.byId(edgeDefinition.nodeId));

    const updatedEdgeDefinition: EdgeDefinitionNode | undefined = yield EdgeDefinitionDAOService.createEdgeDefinition(
        serverId,
        edgeDefinition,
    );

    if (updatedEdgeDefinition) {
        yield put(edgeDefinitionsAdd([updatedEdgeDefinition]));
        yield call(updateGraph, existing, updatedEdgeDefinition);
        yield put(updateDefinitionOverlay(updatedEdgeDefinition.nodeId));
    }
}

function* handleCreateEdgeDefinitionFromMatrix({ payload }: TCreateEdgeDefinitionFromMatrixAction) {
    const { nodeId, edgeType, sourceObjectDefinitionId, targetObjectDefinitionId } = payload;

    const instances: EdgeDefinitionNode[] = yield EdgeDefinitionDAOService.searchExistingEdgeDefinitions(
        nodeId,
        [sourceObjectDefinitionId],
        [targetObjectDefinitionId],
    );

    const isInstanceExists = instances.some((instance) => instance.edgeTypeId === edgeType.id);

    if (!isInstanceExists) {
        const edgeDefinition: EdgeDefinitionNode = yield EdgeDefinitionDAOService.createEdgeDefinition(
            nodeId.serverId,
            {
                type: TreeItemType.EdgeDefinition,
                nodeId: {
                    ...nodeId,
                    id: uuid(),
                },
                parentNodeId: {
                    ...nodeId,
                    id: sourceObjectDefinitionId,
                },
                name: '',
                edgeTypeId: edgeType.id,
                sourceObjectDefinitionId,
                targetObjectDefinitionId,
            },
        );

        yield put(edgeDefinitionsAdd([edgeDefinition]));
        yield put(treeItemAdd(<TreeNode>edgeDefinition));

        return;
    }

    yield put(
        openDialog(DialogType.SELECT_EDGE_DIALOG, {
            instances,
            cellId: '',
            sourceObjectDefinitionId,
            targetObjectDefinitionId,
            isCreatingFromMatrix: true,
        }),
    );
}

export function* edgeDefinitionSaga() {
    yield takeEvery(CREATE_EDGE_DEFINITION, handleCreateEdgeDefinition);
    yield takeEvery(UPDATE_EDGE_DEFINITION_NAME, handleUpdateEdgeDefinitionName);
    yield takeEvery(EDGE_DEFINITIONS_UPDATE, handleEdgeDefinitionUpdate);
    yield takeEvery(INIT_EDGE_DEFINITION_CREATION, handleInitEdgeDefinitionCreation);
    yield takeEvery(CREATE_EDGE_DEFINITION_FROM_MATRIX, handleCreateEdgeDefinitionFromMatrix);
}
