import { SCRIPT_CONTEXT_FETCH } from '../actionsTypes/entities/script.actionTypes';
import { LOAD_TREE_PART_FOR_CONTEXT, SET_SCRIPT_CONTEXT_LOADING } from '../actionsTypes/scriptContext.actionTypes';
import { TLoadTreePart, TSaveScriptContext, TScriptContextLoadingStatus } from './scriptContext.actions.types';
import { NodeId, AllowedScriptContext } from '../serverapi/api';

export const scriptContextFetch = (scriptContext: AllowedScriptContext, scriptId: NodeId): TSaveScriptContext => ({
    type: SCRIPT_CONTEXT_FETCH,
    payload: {
        scriptContext,
        scriptId,
    },
});

export const loadTreePart = (nodeId: NodeId): TLoadTreePart => ({
    type: LOAD_TREE_PART_FOR_CONTEXT,
    payload: {
        nodeId,
    },
});

export const setScriptContextLoadingStatus = (isLoading: boolean): TScriptContextLoadingStatus => ({
    type: SET_SCRIPT_CONTEXT_LOADING,
    payload: {
        isLoading,
    },
});
