import { TReducer } from '../utils/types';
import { UPDATE_ERROR_DATABASE_ACTION } from '../actionsTypes/databaseDialog.actionTypes';
import { TDatabaseDialogState } from './databaseDialog.reducer.types';

const initial: TDatabaseDialogState = {};

export const databaseDialogReducer: TReducer<TDatabaseDialogState> = (state = initial, action) => {
    switch (action.type) {
        case UPDATE_ERROR_DATABASE_ACTION:
            return {
                ...state,
                error: action.payload.error,
            };
        default:
            return state;
    }
};
