import React, { FC } from 'react';
import { Button, ButtonProps } from 'antd';
import classnames from 'classnames';
import theme from './NUIButton.component.scss';

type TNUIButtonProps = ButtonProps & {
    selected?: boolean;
};

const baseClass = 'ant-btn-';

export const NUIButton: FC<TNUIButtonProps> = (props) => {
    const { ...otherProps } = props;
    const className = classnames(props.className, {
        [`${baseClass}selected`]: props.selected,
        [theme.selected]: props.selected,
    });

    return <Button {...otherProps} className={className} />;
};

NUIButton.defaultProps = {
    selected: false,
};
