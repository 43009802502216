import { Tooltip } from 'antd';
import React, { FC } from 'react';
import cx from 'classnames';
import theme from '../items/MenuItem.scss';
import messages from '../../messages/CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { Icon, NUIButton } from '../../../UIKit';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralMenuButtonsState } from '../../../../selectors/generalMenu.selectors';
import { Alignment } from '../../../../models/alignment';
import icLeft from '../../../../resources/icons/ic-ribbon-text-left.svg';
import icRight from '../../../../resources/icons/ic-ribbon-text-right.svg';
import icCenter from '../../../../resources/icons/ic-ribbon-text-center.svg';
import { LabelStyle } from '../../../../models/labelStyle';
import { MxConstants } from '../../../../mxgraph/mxgraph';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';

type TGraphObjectToolbarTextAlignSettingsProps = {
    disabled: boolean;
};

export const GraphObjectToolbarTextAlignSettings: FC<TGraphObjectToolbarTextAlignSettingsProps> = ({ disabled }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const buttonEditLabelState = useSelector(getGeneralMenuButtonsState);

    const onChange = (value: any, isActive: boolean) => () => {
        dispatch(generalMenuLabelStyleChangeAction(LabelStyle.alignmentStyle, value, isActive));
    };

    return (
        <span className={theme.spanGroupRow}>
            <Tooltip
                mouseLeaveDelay={0}
                title={
                    !disabled ? intl.formatMessage(messages.textLeft) : intl.formatMessage(messages.selectEdgeOrObject)
                }
            >
                <span className={cx(theme.buttonWrapper, disabled && theme.buttonDisabled)}>
                    <NUIButton
                        data-test="object-toolbar_text-aligne_left"
                        onClick={onChange(MxConstants.ALIGN_LEFT, buttonEditLabelState.alignment === Alignment.Left)}
                        selected={buttonEditLabelState.alignment === Alignment.Left}
                        disabled={disabled}
                    >
                        <Icon spriteSymbol={icLeft} disabled={disabled} />
                    </NUIButton>
                </span>
            </Tooltip>
            <Tooltip
                mouseLeaveDelay={0}
                title={
                    !disabled
                        ? intl.formatMessage(messages.textCenter)
                        : intl.formatMessage(messages.selectEdgeOrObject)
                }
            >
                <span className={cx(theme.buttonWrapper, disabled && theme.buttonDisabled)}>
                    <NUIButton
                        data-test="object-toolbar_text-aligne_center"
                        onClick={onChange(
                            MxConstants.ALIGN_CENTER,
                            buttonEditLabelState.alignment === Alignment.CenterVert,
                        )}
                        selected={buttonEditLabelState.alignment === Alignment.CenterVert}
                        disabled={disabled}
                    >
                        <Icon spriteSymbol={icCenter} disabled={disabled} />
                    </NUIButton>
                </span>
            </Tooltip>
            <Tooltip
                mouseLeaveDelay={0}
                title={
                    !disabled ? intl.formatMessage(messages.textRight) : intl.formatMessage(messages.selectEdgeOrObject)
                }
            >
                <span className={cx(theme.buttonWrapper, disabled && theme.buttonDisabled)}>
                    <NUIButton
                        data-test="object-toolbar_text-aligne_right"
                        onClick={onChange(MxConstants.ALIGN_RIGHT, buttonEditLabelState.alignment === Alignment.Right)}
                        selected={buttonEditLabelState.alignment === Alignment.Right}
                        disabled={disabled}
                    >
                        <Icon spriteSymbol={icRight} disabled={disabled} />
                    </NUIButton>
                </span>
            </Tooltip>
        </span>
    );
};
