import React, { useState, useLayoutEffect } from 'react';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import icArrowDown from '../../../resources/icons/icArrowDown.svg';
import icArrowUp from '../../../resources/icons/icArrowUp.svg';
import icPinnedComments from '../../../resources/icons/icPinnedComments.svg';
import theme from './CommentsPanel.scss';
import { Comment, NodeId } from '../../../serverapi/api';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { CommentsSelectors } from '@/selectors/comments.selectors';

type TCommentPinnedPanelProps = {
    modelId: NodeId;
    filteredPinnedComments: Comment[];
    onFilterPinnedComments: () => void;
    handleMoveToPinnedComment: (commentId: string) => void;
    isPinnedCommentsFiltered: boolean;
};

export const CommentPinnedPanel = ({
    modelId,
    filteredPinnedComments,
    onFilterPinnedComments,
    handleMoveToPinnedComment,
    isPinnedCommentsFiltered,
}: TCommentPinnedPanelProps) => {
    const idx: number = useSelector(CommentsSelectors.getLastIndexOfPinnedComment(modelId));
    const [currentIndex, setCurrentIndex] = useState(idx);
    const authorName: string = useSelector(CommentsSelectors.getCommentAuthorName(modelId, filteredPinnedComments[currentIndex]?.commentId.id));

    useLayoutEffect(() => {
        setCurrentIndex(idx);
    }, [idx]);

    const handleNext = () => {
        if (currentIndex < filteredPinnedComments.length - 1) {
            setCurrentIndex((prev) => prev + 1);
        } else {
            setCurrentIndex(0);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        } else {
            setCurrentIndex(filteredPinnedComments.length - 1);
        }
    };

    return (
        <div className={theme.pinnedPanelOuterConatainer} data-test="pinned_panel-container">
            <div className={theme.pinnedPanelConatainer}>
                <div className={theme.leftSideContainer}>
                    <div>
                        <div className={theme.pinnedPanelArrow}>
                            <Icon dataTest="pinned_panel-arrow_up_button" onClick={handlePrev} spriteSymbol={icArrowUp} className={theme.arrowUp} />
                        </div>
                        <div className={theme.pinnedPanelArrow}>
                            <Icon dataTest="pinned_panel-arrow_down_button" onClick={handleNext} spriteSymbol={icArrowDown} className={theme.arrowDown} />
                        </div>
                    </div>
                    <span
                        onClick={() => handleMoveToPinnedComment(filteredPinnedComments[currentIndex].commentId.id)}
                        className={theme.commentPinnedText}
                        data-test="pinned_panel-text"
                    >
                        <span>{authorName}</span>
                        <br/> {filteredPinnedComments[currentIndex]?.text}
                    </span>
                </div>
                <Icon
                    spriteSymbol={icPinnedComments}
                    className={theme.filterIcon}
                    dataTest="pinned_panel-filter_button"
                    onClick={onFilterPinnedComments}
                />
            </div>
            {!isPinnedCommentsFiltered && <Divider className={theme.divider} />}
        </div>
    );
};
