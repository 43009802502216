import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'AlignSelectDialog.title',
        defaultMessage: 'Выровнять',
    },
    left: {
        id: 'AlignSelectDialog.left',
        defaultMessage: 'По левому краю',
    },
    centered: {
        id: 'AlignSelectDialog.centered',
        defaultMessage: 'По центру',
    },
    right: {
        id: 'AlignSelectDialog.right',
        defaultMessage: 'По правому краю',
    },
    top: {
        id: 'AlignSelectDialog.top',
        defaultMessage: 'По верхнему краю',
    },
    middle: {
        id: 'AlignSelectDialog.middle',
        defaultMessage: 'По середине',
    },
    bottom: {
        id: 'AlignSelectDialog.bottom',
        defaultMessage: 'По нижнему краю',
    },
    horizontally: {
        id: 'AlignSelectDialog.horizontally',
        defaultMessage: 'Распределить по горизонтали',
    },
    vertically: {
        id: 'AlignSelectDialog.vertically',
        defaultMessage: 'Распределить по вертикали',
    },
    onlyInEditModeAvailable: {
        id: 'AlignSelectDialog.onlyInEditModeAvailable',
        defaultMessage: 'Доступно только в режиме редактирования',
    },
});
