// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KanbanModelTypeGeneralTab__firstRow__K0paP{display:-webkit-box;display:-ms-flexbox;display:flex}.KanbanModelTypeGeneralTab__firstRow__K0paP .ant-checkbox-wrapper{margin-top:-5px}.KanbanModelTypeGeneralTab__firstRow__K0paP .KanbanModelTypeGeneralTab__name__x58IC{width:50%}.KanbanModelTypeGeneralTab__firstRow__K0paP .KanbanModelTypeGeneralTab__name__x58IC input{width:300px}.KanbanModelTypeGeneralTab__firstRow__K0paP .KanbanModelTypeGeneralTab__modelTypeVisibility___2PLr{width:50%}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/KanbanModelType/KanbanModelTypeGeneralTab/KanbanModelTypeGeneralTab.scss"],"names":[],"mappings":"AAAA,4CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEI,kEACI,eAAA,CAGR,oFACI,SAAA,CACA,0FACI,WAAA,CAGR,mGACI,SAAA","sourcesContent":[".firstRow {\r\n    display: flex;\r\n    :global {\r\n        .ant-checkbox-wrapper {\r\n            margin-top: -5px;\r\n        }\r\n    }\r\n    .name {\r\n        width: 50%;\r\n        input {\r\n            width: 300px;\r\n        }\r\n    }\r\n    .modelTypeVisibility {\r\n        width: 50%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstRow": "KanbanModelTypeGeneralTab__firstRow__K0paP",
	"name": "KanbanModelTypeGeneralTab__name__x58IC",
	"modelTypeVisibility": "KanbanModelTypeGeneralTab__modelTypeVisibility___2PLr"
};
export default ___CSS_LOADER_EXPORT___;
