import { defineMessages } from 'react-intl';

export default defineMessages({
    dialogTitle: {
        id: 'SelectTreeItemApprovalDialog.dialogTitle',
        defaultMessage: 'Выберите элемент для нового согласования',
    },
    confirm: {
        id: 'SelectTreeItemApprovalDialog.confirmButton',
        defaultMessage: 'Далее',
    },

    cancel: {
        id: 'SelectTreeItemApprovalDialog.cancel',
        defaultMessage: 'Отмена',
    },
    name: {
        id: 'SelectTreeItemApprovalDialog.name',
        defaultMessage: 'Наименование',
    },
    models: {
        id: 'SelectTreeItemApprovalDialog.models',
        defaultMessage: 'Отображать модели',
    },
    objects: {
        id: 'SelectTreeItemApprovalDialog.objects',
        defaultMessage: 'Отображать объекты',
    },
});
