import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'MatrixEdgesListDialog.title',
        defaultMessage: 'Список связей',
    },
    close: {
        id: 'MatrixEdgesListDialog.close',
        defaultMessage: 'Закрыть',
    },
    ID: {
        id: 'MatrixEdgesListDialog.ID',
        defaultMessage: 'ID',
    },
    elementType: {
        id: 'MatrixEdgesListDialog.elementType',
        defaultMessage: 'Тип элемента',
    },
    sourceObject: {
        id: 'MatrixEdgesListDialog.sourceObject',
        defaultMessage: 'Исходящий объект',
    },
    edgeType: {
        id: 'MatrixEdgesListDialog.edgeType',
        defaultMessage: 'Тип связи',
    },
    targetObject: {
        id: 'MatrixEdgesListDialog.targetObject',
        defaultMessage: 'Входящий объект',
    },
    symbol: {
        id: 'MatrixEdgesListDialog.symbol',
        defaultMessage: 'Символ',
    },
    instance: {
        id: 'MatrixEdgesListDialog.instance',
        defaultMessage: 'Экземпляр связи',
    },
    definition: {
        id: 'MatrixEdgesListDialog.definition',
        defaultMessage: 'Определение связи',
    },
    deleteDefinition: {
        id: 'MatrixEdgesListDialog.deleteDefinition',
        defaultMessage: 'Удаление определение связи',
    },
    delete: {
        id: 'MatrixEdgesListDialog.delete',
        defaultMessage: 'Удалить',
    },
    cancel: {
        id: 'MatrixEdgesListDialog.cancel',
        defaultMessage: 'Отмена',
    },
    deleteMessage: {
        id: 'MatrixEdgesListDialog.deleteMessage',
        defaultMessage: 'Будет удалено определение связи: ',
    },
});
