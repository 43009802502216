import { getGUIDdArray } from '../../ComplexSymbol.utils';

export const getSequenceLifeLineTemplate = (complexSymbolTypeId, shape) => {
    const [u0, u1, u2] = getGUIDdArray(3);

    return `
  <mxGraphModel>
    <root>
      <mxCell id="${u0}" />
      <mxCell id="${u1}" parent="${u0}" />
      <mxCell
        id="${u2}"
        parent="${u1}"
        complexSymbolTypeId="${complexSymbolTypeId}"
        style="shape=${shape};connectable=1;"
        value="${shape}"
        vertex="1"
      >
        <mxGeometry x="0" y="0" width="120" height="460" as="geometry" />
      </mxCell>
    </root>
  </mxGraphModel>
`;
};
