import { isPlainObject } from 'lodash-es';
import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { KanbanCardType } from '../serverapi/api';

export const kanbanCardTypeStateSelector = (state: TRootState) => state.kanbanCardType;

export namespace KanbanCardTypeSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector(kanbanCardTypeStateSelector, (state) => {
            return state.byPresetId[presetId] || {};
        });

    export const listAllByPreset = (presetId: string) =>
        createSelector(byPresetId(presetId), (state) => toKanbanCardTypeArray(state));

    const toKanbanCardTypeArray = (kanbanCardTypesMap): KanbanCardType[] => {
        return (
            (kanbanCardTypesMap && Object.keys(kanbanCardTypesMap.byId).map((k) => kanbanCardTypesMap.byId[k])) || []
        );
    };

    export const byId = (compositeId: { kanbanCardTypeId: string; presetId: string }) =>
        createSelector(byPresetId(compositeId.presetId), (state) => {
            return state?.byId && isPlainObject(state.byId) ? state.byId[compositeId.kanbanCardTypeId] : undefined;
        });

    export const byIds = (compositeId: { kanbanCardTypeIds: string[]; presetId: string }) =>
        createSelector(byPresetId(compositeId.presetId), (state) =>
            compositeId.kanbanCardTypeIds.map((id) => state?.byId[id]).filter((type) => type),
        );
}
