export const SCRIPT_EXECUTE_DIALOG_ADD_NODE_ID = 'SCRIPT_EXECUTE_DIALOG_ADD_NODE_ID';

export const SCRIPT_EXECUTE_DIALOG_ADD_NODE_PATH = 'SCRIPT_EXECUTE_DIALOG_ADD_NODE_PATH';

export const SCRIPT_EXECUTE_DIALOG_DELETE_NODE_ID = 'SCRIPT_EXECUTE_DIALOG_DELETE_NODE_ID';

export const SCRIPT_EXECUTE_DIALOG_DELETE_PARAM_FILES = 'SCRIPT_EXECUTE_DIALOG_DELETE_EXECUTION_STORAGE';

export const SCRIPT_EXECUTE_DIALOG_REQUEST_SUCCESS = 'SCRIPT_EXECUTE_DIALOG_REQUEST_SUCCESS';

export const SCRIPT_EXECUTE_DIALOG_SET_UPLOAD_STATUS = 'SCRIPT_EXECUTE_DIALOG_SET_UPLOAD_STATUS';

export const SCRIPT_EXECUTE_DIALOG_UPLOAD_FILE = 'SCRIPT_EXECUTE_DIALOG_UPLOAD_FILE';
