import { defineMessages } from 'react-intl';

export default defineMessages({
    changePasswordTitle: {
        id: 'ChangePassword.changePasswordTitle',
        defaultMessage: 'Смена пароля',
    },
    oldPassword: {
        id: 'ChangePassword.oldPassword',
        defaultMessage: 'Старый пароль',
    },
    newPassword: {
        id: 'ChangePassword.newPassword',
        defaultMessage: 'Новый пароль',
    },
    confirmNewPassword: {
        id: 'ChangePassword.confirmNewPassword',
        defaultMessage: 'Подтверждение пароля',
    },
    cancel: {
        id: 'ChangePassword.cancel',
        defaultMessage: 'Отмена',
    },
    changePassword: {
        id: 'ChangePassword.changePassword',
        defaultMessage: 'Сменить пароль',
    },
    differentPasswords: {
        id: 'ChangePassword.differentPasswords',
        defaultMessage: 'Пароли не совпадают',
    },
});
