import { UserDTOAccessesEnum } from '../serverapi/api';
import { TAccessPermissionOwnersMap } from '../reducers/accessPermissions.reducer.types';
import {
    OPEN_ACCESS_PERMISSION_OWNERS_EDITING_TAB,
    FETCH_ACCESS_PERMISSION_OWNERS_INFO,
    FETCHED_ACCESS_PERMISSION_INFO_SUCCESS,
    SAVE_INFO_OF_ACCESS_OWNERS,
} from '../actionsTypes/accessPermissions.actionTypes';

type TOpenAccessPermissionOwnersEditingTabPayload = {
    accessType: UserDTOAccessesEnum;
};

export type TOpenAccessPermissionOwnersEditingTabAction = {
    type: typeof OPEN_ACCESS_PERMISSION_OWNERS_EDITING_TAB;
    payload: TOpenAccessPermissionOwnersEditingTabPayload;
};

export const openAccessPermissionOwnersEditingTab: (
    payload: TOpenAccessPermissionOwnersEditingTabPayload,
) => TOpenAccessPermissionOwnersEditingTabAction = (payload) => ({
    type: OPEN_ACCESS_PERMISSION_OWNERS_EDITING_TAB,
    payload,
});

export type TFetchAccessPermissionsOwnersInfoAction = {
    type: typeof FETCH_ACCESS_PERMISSION_OWNERS_INFO;
    payload: {
        serverId: string;
        accesses?: UserDTOAccessesEnum[];
    };
};

export const fetchAccessOwnerInfo: (
    serverId: string,
    accesses?: UserDTOAccessesEnum[],
) => TFetchAccessPermissionsOwnersInfoAction = (serverId, accesses) => ({
    type: FETCH_ACCESS_PERMISSION_OWNERS_INFO,
    payload: { serverId, accesses },
});

export type TFetchAccessPermissionInfoSuccessPayload = {
    serverId: string;
    owners: TAccessPermissionOwnersMap;
};

export type TFetchAccessPermissionsInfoSuccessAction = {
    type: typeof FETCHED_ACCESS_PERMISSION_INFO_SUCCESS;
    payload: TFetchAccessPermissionInfoSuccessPayload;
};

export const fetchedAccessPermissionInfoSuccess: (
    payload: TFetchAccessPermissionInfoSuccessPayload,
) => TFetchAccessPermissionsInfoSuccessAction = (payload) => ({
    type: FETCHED_ACCESS_PERMISSION_INFO_SUCCESS,
    payload,
});

export type TSaveInfoOfAccessOwnersPayload = {
    action: string;
    serverId: string;
    accessType: UserDTOAccessesEnum;
    ids: number[];
};

export type TSaveInfoOfAccessOwnersAction = {
    type: typeof SAVE_INFO_OF_ACCESS_OWNERS;
    payload: TSaveInfoOfAccessOwnersPayload;
};

export const saveInfoOfAccessOwners: (payload: TSaveInfoOfAccessOwnersPayload) => TSaveInfoOfAccessOwnersAction = (
    payload,
) => ({
    type: SAVE_INFO_OF_ACCESS_OWNERS,
    payload,
});

export type TAccessPermissionsActions =
    | TFetchAccessPermissionsOwnersInfoAction
    | TFetchAccessPermissionsInfoSuccessAction
    | TOpenAccessPermissionOwnersEditingTabAction
    | TSaveInfoOfAccessOwnersAction;
