import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import messages from './CommonSettings.messages';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TConfirmationDialogProps = {
    open: boolean;
    onClear: () => void;
    onCancel: () => void;
};

export const ConfirmationDialog: FC<TConfirmationDialogProps> = ({ open, onCancel, onClear }) => {
    const intl = useIntl();

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.onCancel),
                },
                {
                    key: 'ok',
                    onClick: onClear,
                    value: intl.formatMessage(messages.onClear),
                    visualStyle: 'primary',
                    danger: true,
                },
            ]}
        />
    );

    return (
        <Dialog onCancel={onCancel} onOk={onClear} footer={footer} open={open}>
            {intl.formatMessage(messages.clearMessage)}
        </Dialog>
    );
};
