import type { TFontStyle, THorizontalAlign, TVerticalAlign } from '../mxgraph.types';
import { parseInt } from 'lodash-es';
import { MxImageShape, MxRectangle } from '../mxgraph';
import { isClickablePart } from '../util/MxUtils';
import BPMMxCellOverlay, { IOverlayPart, TPartGroup } from './BPMMxCellOverlay';
import { OverlayType } from './CellOverlayManager.constants';

export default class MxHTMLOverlayShape extends MxImageShape {
    overlay: BPMMxCellOverlay;
    constructor(overlay: BPMMxCellOverlay) {
        super(new MxRectangle(), 'black');
        this.overlay = overlay;
    }

    actualPartsHeight = 0;
    LINE_HEIGHT = 1.5715;
    BASE_IMAGE_Z_INDEX = 1;
    PT_TO_PX__DEFAULT_COEFFICIENT = 1.33;
    // Большое значение, чтобы текст был точно выше иконок
    BASE_TEXT_Z_INDEX = 1000;
    TEXT_PADDING = 2;
    UML_ATTRIBUTE_TEXT_PADDING = 12;
    IMAGE_MARGIN = 2;

    public getActualPartsHeight() {
        return this.actualPartsHeight;
    }

    private getPartIndex(part: IOverlayPart): number {
        return this.overlay.parts.findIndex((p: IOverlayPart) => p.attributeValue.id === part.attributeValue.id);
    }

    paintVertexShape(c, x, y, w, h, align, valign, wrap, format, overflow, clip, rotation, dir): void {
        const { root } = c;
        const { scale } = this;
        const group = c.createElement('g');
        const fo = c.createElement('foreignObject');
        fo.setAttribute('x', `${x}`);
        fo.setAttribute('y', `${y}`);
        fo.setAttribute('width', `${w}`);
        fo.setAttribute('height', `${h}`);
        fo.setAttribute('style', 'overflow:visible;');
        fo.setAttribute('pointer-events', 'none');

        const partsContainerStyle = `
            display: flex;
            flex-direction: column;
            width: ${w}px;
            height: ${h}px;
            overflow: hidden;
        `;

        let partsInnerHtml = `
            <div style="${partsContainerStyle}">
        `;
        partsInnerHtml += this.processImages(h, w);
        partsInnerHtml += this.processText(h, w);
        partsInnerHtml += '</div>';
        const areaContainer = c.createDiv(partsInnerHtml);

        group.setAttribute('transform', scale !== 1 ? `scale(${scale})` : '');
        fo.appendChild(areaContainer);

        const style = `
            display: flex;
            overflow: hidden;
            text-align: center;
            align-items: center;
            justify-content: center;
        `;
        areaContainer.setAttribute('style', style);
        fo.appendChild(areaContainer);
        this.actualPartsHeight = areaContainer.clientHeight || areaContainer.offsetHeight;
        group.appendChild(fo);
        root.appendChild(group);
    }

    private processImages(areaHeight: number, areaWidth: number): string {
        const imageParts: IOverlayPart[] = this.overlay.parts.filter((p) => p.image);
        const imagePartGroups = this.groupPartsByAlign(imageParts);

        if (!imagePartGroups) return '';

        const imagesLayerStyles = `
                position: absolute;
                display: flex;
                overflow: hidden;
                width: ${areaWidth}px;
                height: ${areaHeight}px;
            `;

        let imagesLayer = `<div style="${imagesLayerStyles}">`;

        imagePartGroups.forEach((group, index) => {
            imagesLayer += this.getImageGroupHTML(group, index, areaHeight, areaWidth);
        });

        imagesLayer += `</div>`;

        return imagesLayer;
    }

    private processImagePart(part: IOverlayPart): string {
        const imageSize = part.style.size || BPMMxCellOverlay.DEFAULT_IMAGE_SIZE;
        const idx = this.getPartIndex(part);
        let imageStyles = `
            display: block;
            width: ${imageSize}px;
            height: ${imageSize}px;
            margin: ${this.IMAGE_MARGIN}px;
        `;

        if (isClickablePart(part)) {
            imageStyles += 'cursor: pointer; pointer-events: all;';
        }

        return `<img data-part-index="${idx}" style="${imageStyles}" src="${part.image}"/>`;
    }

    private getHorizontalImageAlign(horizontalAlign: string, groupWidth: number, areaWidth: number): THorizontalAlign {
        switch (horizontalAlign) {
            case 'left':
                return {
                    left: '0',
                    right: 'auto',
                };
            case 'right':
                return {
                    left: 'auto',
                    right: '0',
                };
            case 'center':
                return {
                    left: groupWidth > areaWidth ? '0' : `calc(50% - ${groupWidth / 2}px)`,
                    right: 'auto',
                };
            default:
                return {
                    left: '0',
                    right: '0',
                };
        }
    }

    private getVerticalImageAlign(verticalAlign: string, groupHeight: number, areaHeight: number): TVerticalAlign {
        switch (verticalAlign) {
            case 'top':
                return {
                    top: '0',
                    bottom: 'auto',
                };
            case 'bottom':
                return {
                    top: 'auto',
                    bottom: '0',
                };
            case 'middle':
                return {
                    top: groupHeight > areaHeight ? '0' : `calc(50% - ${groupHeight / 2}px)`,
                    bottom: 'auto',
                };
            default:
                return {
                    top: '0',
                    bottom: '0',
                };
        }
    }

    private processText(areaHeight: number, areaWidth: number): string {
        const textParts = this.overlay.parts.filter((part) => part.text);
        const textPartGroups = this.groupPartsByAlign(textParts);

        if (!textPartGroups.length) return '';

        const baseTextLayerStyles = `
            position: absolute;
            display: flex;
            width: ${areaWidth}px;
            height: ${areaHeight}px;
            z-index: ${this.BASE_TEXT_Z_INDEX};
        `;

        let baseTextLayer = `<div style="${baseTextLayerStyles}">`;

        textPartGroups.forEach((group, index) => {
            baseTextLayer += this.getTextGroupHTML(group, index, areaHeight, areaWidth);
        });

        baseTextLayer += `</div>`;

        return baseTextLayer;
    }

    private groupPartsByAlign(parts: IOverlayPart[]): TPartGroup[] {
        const groups: TPartGroup[] = [];

        parts.forEach((part) => {
            const {
                style: { align, baseline },
            } = part;

            const key: string = BPMMxCellOverlay.key(align, baseline);

            const index: number = groups.findIndex((group) => group.key === key);
            if (index === -1) {
                groups.push({
                    key,
                    parts: [part],
                    attributeWrap: !!part.attributeWrap,
                });
            } else {
                groups[index].parts.push(part);
                if (!groups[index].attributeWrap) {
                    groups[index].attributeWrap = !!part.attributeWrap;
                }
            }
        });

        return groups;
    }

    private getBaseTextGroupStyle(zIndex: number, width: number, height: number, textAlign: string): string {
        return `
            position: absolute;
            z-index: ${zIndex};
            width: ${width}px;
            height: ${height}px;
            text-align: ${textAlign};
            overflow: hidden;
        `;
    }

    private calcGroupHeight(height: number, areaHeight: number): number {
        return height > areaHeight ? areaHeight : height;
    }

    private convertFontToPx(fontSizePt: number) {
        // коэффициенты для конвертации размеров из points(pt) в pixels(px)
        // с 1 по 8 размер (pt)
        const coefficientMap: { [size: number]: number } = {
            1: 12,
            2: 6,
            3: 4,
            4: 3,
            5: 2.4,
            6: 2,
            7: 1.715,
            8: 1.525,
        };
        // у всех размеров начиная с 9 коэффициент равен 1.33
        const pt_to_px_coefficient = coefficientMap[fontSizePt] || this.PT_TO_PX__DEFAULT_COEFFICIENT;
    
        const fontSizePx = fontSizePt * pt_to_px_coefficient;
        const lineHeightPx = this.LINE_HEIGHT * fontSizePx;
    
        return { lineHeightPx };
    }

    private getImageGroupHTML(group: TPartGroup, index: number, areaHeight: number, areaWidth: number): string {
        const { key, parts, attributeWrap } = group;
        const [align, baseline] = BPMMxCellOverlay.unkey(key);

        let groupInnerHTML: string = '';

        let groupWidth = 0;
        let groupHeight = 0;

        parts.forEach((part) => {
            const html = this.processImagePart(part);
            groupInnerHTML += html;
            const sizeWithMargin = part.style.size + this.IMAGE_MARGIN * 2;
            groupWidth = group.attributeWrap ? Math.max(groupWidth, sizeWithMargin) : groupWidth + sizeWithMargin;
            groupHeight = group.attributeWrap ? groupHeight + sizeWithMargin : Math.max(groupHeight, sizeWithMargin);
        });

        const { left, right } = this.getHorizontalImageAlign(align, groupWidth, areaWidth);
        const { top, bottom } = this.getVerticalImageAlign(baseline, groupHeight, areaHeight);

        const groupStyles = `
            position: absolute;
            z-index: ${this.BASE_IMAGE_Z_INDEX + index};
            display: flex;
            flex-direction: ${attributeWrap ? 'column' : 'row'};
            top: ${top};
            bottom: ${bottom};
            left: ${left};
            right: ${right};
        `;

        return `<div style="${groupStyles}">${groupInnerHTML}</div>`;
    }

    private getTextGroupHTML(group: TPartGroup, index: number, areaHeight: number, areaWidth: number): string {
        const [align, baseline] = BPMMxCellOverlay.unkey(group.key);
        const { attributeWrap } = group;
        const groupZIndex: number = this.BASE_TEXT_Z_INDEX + index;

        let groupInnerHTML: string = '';
        let groupHeight = 0;

        group.parts.forEach((part) => {
            const { html, height } = this.getTextPartHTML(
                part,
                areaHeight,
                areaWidth,
                groupHeight,
                attributeWrap,
            );
            groupInnerHTML += html;

            if (attributeWrap && height !== null) {
                groupHeight = this.calcGroupHeight(groupHeight + height, areaHeight);
            }
        });

        let additionalStyles = '';

        if (!attributeWrap) {
            const { height } = BPMMxCellOverlay.getActualTextSize({ text: groupInnerHTML, areaWidth });
            const fontSizes = group.parts.map((part) => parseInt(part.style.size), 10);
            const size = Math.max(...fontSizes);
            additionalStyles += this.getTextOverflowStyles(areaHeight, height, groupHeight, size, attributeWrap);
            groupHeight = this.calcGroupHeight(height, areaHeight);
        }

        additionalStyles += this.getVerticalTextAlignStyles(baseline, groupHeight, areaHeight);

        let groupStyles: string = this.getBaseTextGroupStyle(groupZIndex, areaWidth, groupHeight, align);
        groupStyles += additionalStyles;

        return `<div style="${groupStyles}">${groupInnerHTML}</div>`;
    }

    private getBaseTextStyle(areaWidth: number, style: TFontStyle, attributeWrap: boolean) {
        const { size, color, family, italic, bold, underline } = style;
        
        return `
            display: ${attributeWrap ? 'block' : 'inline'};
            width: ${areaWidth}px;
            overflow: hidden;
            color: ${color};
            font-family: ${family};
            font-style: ${italic ? 'italic' : 'normal'};
            font-weight: ${bold ? 'bold' : 'normal'};
            text-decoration: ${underline ? 'underline' : 'none'};
            font-size: ${size};
            line-height: ${this.LINE_HEIGHT};
            overflow-wrap: anywhere;
            padding: 0 ${this.overlay.overlayType === OverlayType.UML_ATTRIBUTES ? this.UML_ATTRIBUTE_TEXT_PADDING : this.TEXT_PADDING}px;
            white-space: pre-wrap;
        `;
    }

    private getTextOverflowStyles(
        areaHeight: number,
        textHeight: number,
        groupHeight: number,
        sizePt: number,
        attributeWrap: boolean,
    ): string {
        const { lineHeightPx } = this.convertFontToPx(sizePt);

        let remainingHeight: number = areaHeight;

        if (attributeWrap) {
            remainingHeight = areaHeight - groupHeight;
        }

        if (textHeight <= remainingHeight) return '';

        const linesThatFit = Math.floor(remainingHeight / lineHeightPx) || 1;

        if (attributeWrap && linesThatFit < 1) return 'display: none;';
        const visibileHeight = linesThatFit * lineHeightPx;

        return `
            display: -webkit-box;
            height: ${visibileHeight}px;
            -webkit-line-clamp: ${linesThatFit};
            -webkit-box-orient: vertical;
            -webkit-box-pack: justify;
        `;
    }

    private getTextPartHTML(
        part: IOverlayPart,
        areaHeight: number,
        areaWidth: number,
        groupHeight: number,
        attributeWrap: boolean,
    ) {
        const { style } = part;
        const text = style.uppercase ? part.text.toUpperCase() : part.text;
        const idx = this.getPartIndex(part);

        let textStyle = this.getBaseTextStyle(areaWidth, style, attributeWrap);
        let textHeight: null | number = null;

        if (attributeWrap) {
            const { height } = BPMMxCellOverlay.getActualTextSize({
                text,
                fontStyle: style,
                areaWidth,
            });
            textHeight = height;
            textStyle += this.getTextOverflowStyles(
                areaHeight,
                textHeight,
                groupHeight,
                parseInt(style.size, 10),
                attributeWrap,
            );
        }

        if (isClickablePart(part)) {
            textStyle += 'cursor: pointer; pointer-events: all;';
        }

        return {
            height: textHeight,
            html: `<span data-part-index="${idx}" style="${textStyle}">${text}</span>`,
        };
    }

    private getVerticalTextAlignStyles(baseline: string, height: number, areaHeight: number) {
        switch (baseline) {
            case 'top':
                return 'top: 0;';
            case 'middle':
                return `top: ${height <= areaHeight ? `calc(50% - ${height / 2}px);` : '0;'}`;
            case 'bottom':
                return 'bottom: 0;';
            default:
                return 'top: 0;';
        }
    }
}
