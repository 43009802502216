import { defineMessages } from 'react-intl';

export default defineMessages({
    dialogTitleObjects: {
        id: 'DecompositionsListDialog.dialogTitleObjects',
        defaultMessage: 'Декомпозиции объекта:',
    },
    dialogTitleEdges: {
        id: 'DecompositionsListDialog.dialogTitleEdges',
        defaultMessage: 'Декомпозиции связи:',
    },
    dialogLabelSelectModel: {
        id: 'DecompositionsListDialog.dialogLabelSelectModel',
        defaultMessage: 'Выберите модель для открытия:',
    },
    modelName: {
        id: 'DecompositionsListDialog.modelName',
        defaultMessage: 'Имя модели',
    },
    modelPath: {
        id: 'DecompositionsListDialog.modelPath',
        defaultMessage: 'Путь',
    },
    modelType: {
        id: 'DecompositionsListDialog.modelType',
        defaultMessage: 'Тип',
    },
});
