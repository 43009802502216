import { NotificationDTO } from '@/serverapi/api';
import { ApiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';

export class NotificationsDAOService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getAll(): Promise<NotificationDTO[]> {
        const api = this.getApi();
        const notifications: NotificationDTO[] = await api.notifications.getAll();

        return notifications;
    }

    public static async save(notification?: NotificationDTO): Promise<NotificationDTO> {
        const api = this.getApi();
        const savedNotification: NotificationDTO = await api.notifications.save({
            body: notification,
        });

        return savedNotification;
    }

    public static async create(notification?: NotificationDTO): Promise<NotificationDTO> {
        const api = this.getApi();
        const createdNotification: NotificationDTO = await api.notifications.create({
            body: notification,
        });

        return createdNotification;
    }

    public static async delete(notificationId: string): Promise<void> {
        const api = this.getApi();
        await api.notifications._delete({
            id: notificationId,
        });
    }
}
