import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../../../messages/CommonToolbar.messages';
import icSearch from '../../../../../../resources/icons/ic-search.svg';
import { workspaceTabSetAction } from '../../../../../../actions/tabs.actions';
import { NodeId } from '../../../../../../serverapi/api';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton.component';

interface ISearchButtonProps {
    disabled: boolean | undefined;
    nodeId: NodeId;
    tooltipTitle: string;
}

export const SearchButton: FC<ISearchButtonProps> = (props) => {
    const { disabled, nodeId, tooltipTitle } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const onActionChange = () => {
        dispatch(workspaceTabSetAction({ nodeId, name: 'focusSearch', value: true }));
    };

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icSearch}
            tooltipTitle={tooltipTitle}
            dataTest="general-toolbar_search-btn"
            onClick={() => onActionChange()}
            text={intl.formatMessage(messages.search)}
        />
    );
};
