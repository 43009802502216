import { fontFamilyOptions, fontSizeOptions, lineHeightOptions } from '@/utils/configuration';

export const DEFAULT_FONT_COLOR = 'rgba(0, 0, 0, 0.65)';

export const DEFAULT_BG_COLOR = 'rgb(255, 255, 255)';

export const DEFAULT_FONT_FAMILY = fontFamilyOptions[0].value;

export const DEFAULT_FONT_SIZE = fontSizeOptions[5].value;

export const DEFAULT_LINE_HEIGHT = lineHeightOptions[2].value;
