import { defineMessages } from 'react-intl';

export default defineMessages({
    LOGIN_LASTNAME_NAME_MIDDLENAME: {
        id: 'AttributeTypePrincipal.LOGIN_LASTNAME_NAME_MIDDLENAME',
        defaultMessage: 'Логин (Фамилия Имя Отчество)',
    },
    LOGIN: {
        id: 'AttributeTypePrincipal.LOGIN',
        defaultMessage: 'Логин',
    },
    LASTNAME_NAME_MIDDLENAME: {
        id: 'AttributeTypePrincipal.LASTNAME_NAME_MIDDLENAME',
        defaultMessage: 'Фамилия Имя Отчество',
    },
});

