// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextDirectionSelect__buttonGroup___LAqP .ant-btn:first-child:not(:last-child),.TextDirectionSelect__buttonGroup___LAqP .ant-btn:not(:first-child):not(:last-child),.TextDirectionSelect__buttonGroup___LAqP .ant-btn:last-child:not(:first-child){padding-right:5px;padding-left:5px;border-radius:4px}.TextDirectionSelect__buttonGroup___LAqP .ant-btn:first-child:not(:last-child){margin-right:4px}.TextDirectionSelect__active__gRH2n,.TextDirectionSelect__active__gRH2n:hover{background-color:var(--secondColorActive)}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/TextDirectionSelect/TextDirectionSelect.scss"],"names":[],"mappings":"AAIG,mPAGC,iBAAA,CACA,gBAAA,CACA,iBAAA,CAGD,+EACC,gBAAA,CAOH,8EAEC,yCAAA","sourcesContent":[".buttonGroup {\r\n\t:global {\r\n\t\t.ant-btn {\r\n\r\n\t\t\t&:first-child:not(:last-child),\r\n\t\t\t&:not(:first-child):not(:last-child),\r\n\t\t\t&:last-child:not(:first-child) {\r\n\t\t\t\tpadding-right: 5px;\r\n\t\t\t\tpadding-left: 5px;\r\n\t\t\t\tborder-radius: 4px;\r\n\t\t\t}\r\n\r\n\t\t\t&:first-child:not(:last-child) {\r\n\t\t\t\tmargin-right: 4px;\r\n\t\t\t}\r\n\t\t}\r\n\t}\r\n}\r\n\r\n.active {\r\n\t&,\r\n\t&:hover {\r\n\t\tbackground-color: var(--secondColorActive);\r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroup": "TextDirectionSelect__buttonGroup___LAqP",
	"active": "TextDirectionSelect__active__gRH2n"
};
export default ___CSS_LOADER_EXPORT___;
