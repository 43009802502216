const addFontBgColorStyle = (size: number) => ({
    [`bgcolor-${  size}`]: {
        open: () => `<BGCOLOR${  size  }>`,
        close: () => '</BGCOLOR>',
    },
});

const fontBgColorStyles = {
    bgcolor: addFontBgColorStyle,
};

// @TODO: Output in draftjs-utils - need fix it
const addFontBgColorBlockStyle = (color: string) => ({
    [`bgcolor-${  color}`]: {
        open: () => `<span style="color: ${  color  };">`,
        close: () => '</span>',
    },
});

const fontBgColorBlockStyles = {
    bgcolor: addFontBgColorBlockStyle,
};

export { fontBgColorBlockStyles, fontBgColorStyles };
