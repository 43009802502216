import {
    SET_SESSION_SEARCH_DATA,
    SET_SESSION_SEARCH_TEXT,
    SET_SESSION_ACTIVE,
    SET_SESSION_RANGE,
} from '../actionsTypes/sessions.actionTypes';
import { TSessionsState } from './sessions.reducer.types';

const initialState: TSessionsState = {
    start: null,
    end: null,
    searchText: '',
    active: false,
    searchData: [],
};

export const SessionsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_SESSION_SEARCH_DATA: {
            return {
                ...state,
                searchData: payload,
            };
        }

        case SET_SESSION_SEARCH_TEXT: {
            return {
                ...state,
                searchText: payload,
            };
        }

        case SET_SESSION_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case SET_SESSION_RANGE: {
            const { start, end } = payload;

            return {
                ...state,
                start,
                end,
            };
        }

        default: {
            return state;
        }
    }
};
