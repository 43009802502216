import { TMessage } from '../utils/forms.types';
import {
    SPREADSHEET_DIALOG_SUBMIT_DATA,
    SPREADSHEET_DIALOG_SUBMIT_RESULT,
} from '../actionsTypes/spreadsheetDialog.actionTypes';
import {
    TPayloadSpreadsheetDialogSubmitDataAction,
    TSpreadsheetDialogSubmitDataAction,
    TSpreadsheetDialogSubmitResultAction,
} from './spreadsheetDialog.actions.types';

export const spreadsheetDialogSubmit = (
    payload: TPayloadSpreadsheetDialogSubmitDataAction,
): TSpreadsheetDialogSubmitDataAction => ({
    type: SPREADSHEET_DIALOG_SUBMIT_DATA,
    payload,
});

export const spreadsheetDialogSubmitResult = (
    result: string,
    error?: TMessage,
): TSpreadsheetDialogSubmitResultAction => ({
    type: SPREADSHEET_DIALOG_SUBMIT_RESULT,
    payload: {
        result,
        error,
    },
});
