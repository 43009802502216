import { connect } from 'react-redux';
import { DecompositionDialog } from '../components/ObjectDecomposition/DecompositionDialog.component';
import { TDecompositionDialogProps } from '../components/ObjectDecomposition/DecompositionDialog.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { objectDecompositionDialogSubmit } from '../../../actions/entities/objectDecomposition.actions';
import { getTreeItemsExceptIds, treeNestedChildrenMap, TreeSelectors } from '../../../selectors/tree.selectors';
import { TreeItemType } from '../../Tree/models/tree';
import { TreeNode, TTreeEntityState } from '../../../models/tree.types';
import { EdgeInstance, ModelAssignment, NodeId } from '../../../serverapi/api';
import { TPayloadObjectDecompositionDialogSubmit } from '../../../actions/entities/objectDecomposition.actions.types';
import { ObjectDefinitionSelectors } from '../../../selectors/objectDefinition.selectors';
import { getActiveGraph } from '../../../selectors/editor.selectors';
import { hasModelEditorLicense } from '../../../selectors/authorization.selectors';
import { ModelTypeUtils } from '../../../utils/modelType.utils';
import { treeItemsClearSelection, treeItemSelect } from '@/actions/tree.actions';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';

type TDecompositionDialogOwnProps = {
    objectDefinitionId?: NodeId;
    edgeInstance?: EdgeInstance;
    repositoryId: string;
    serverId: string;
    allowAnyDecomposition: boolean;
    validDecompositionModelTypesIds?: string[];
    modelAssignmentsIds: string[];
    stateAssignments?: ModelAssignment[];
};

const CONTAINERS = [TreeItemType.Folder, TreeItemType.Repository];
const EXCLUDE_DECOMPOSITION_TYPES = [TreeItemType.ObjectDefinition, TreeItemType.File, TreeItemType.SimulationModeling];

const mapStateToProps = (
    state: TRootState,
    ownProps: TDecompositionDialogOwnProps,
): Partial<TDecompositionDialogProps> => {
    const { repositoryId, serverId, objectDefinitionId, modelAssignmentsIds, stateAssignments } = ownProps;
    const addedAssignmentIds: string[] = stateAssignments
        ? stateAssignments.map((modelAssignment) => modelAssignment.modelId!)
        : modelAssignmentsIds;
    const treeItemsById: { [id: string]: TTreeEntityState } = getTreeItemsExceptIds(
        serverId,
        repositoryId,
        addedAssignmentIds,
    )(state);
    const activeGraphId = getActiveGraph(state);
    const isModelEditor: boolean = hasModelEditorLicense(state);

    let objectDefinition;
    if (objectDefinitionId) {
        objectDefinition = ObjectDefinitionSelectors.byId(objectDefinitionId)(state);
    }
    const tree = convertToFilteredTree(state, ownProps, treeItemsById);

    const existingModel: TreeNode | undefined = SelectedNodesSelector.getNode(DialogType.OBJECT_DECOMPOSITION_CREATE)(
        state,
    );

    return {
        treeData: tree,
        treeItemsById,
        storeName: DialogType.OBJECT_DECOMPOSITION_CREATE,
        objectName: objectDefinition?.name,
        activeGraphId,
        isModelEditor,
        existingModel,
    };
};
// todo: можно ли упростить или конвертировать методами в tree.selectors
const convertToFilteredTree = (
    state: TRootState,
    ownProps: TDecompositionDialogOwnProps,
    treeItemsById: { [id: string]: TTreeEntityState },
) => {
    const { repositoryId, serverId } = ownProps;

    const repository: TTreeEntityState | undefined = TreeSelectors.itemById({
        repositoryId,
        serverId,
        id: repositoryId,
    })(state);

    const filteredTreeItemsById = Object.values(treeItemsById)
        .filter((item: TTreeEntityState) => !EXCLUDE_DECOMPOSITION_TYPES.includes(item.type))
        .reduce((acc, node: TreeNode) => {
            if (node.type === TreeItemType.Folder && !node.hasChildren) return acc;
            if (
                ownProps.allowAnyDecomposition ||
                CONTAINERS.includes(node.type) ||
                ownProps.validDecompositionModelTypesIds?.includes(ModelTypeUtils.getIdByTreeNode(node))
            ) {
                return {
                    ...acc,
                    [node.nodeId.id]: node,
                };
            }

            return acc;
        }, {});

    return repository && treeNestedChildrenMap(filteredTreeItemsById, repository.nodeId.id);
};

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (props: TPayloadObjectDecompositionDialogSubmit) => {
        dispatch(objectDecompositionDialogSubmit(props));
        dispatch(treeItemsClearSelection());
    },
    onCancel: () => {
        dispatch(closeDialog(DialogType.OBJECT_DECOMPOSITION_CREATE));
        dispatch(treeItemsClearSelection());
    },
    onTreeItemSelect: (node?: TreeNode) => dispatch(treeItemSelect(node, DialogType.OBJECT_DECOMPOSITION_CREATE)),
});

export const ObjectDecompositonDialogContainer = connect(mapStateToProps, mapDispatchToProps)(DecompositionDialog);
