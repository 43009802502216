import {
    ADD_VISIO_FILES,
    ADD_VISIO_MATCHING,
    CANCEL_IMPORT_VISIO,
    CLEAR_VISIO_DATA_AFTER_IMPORT,
    DELETE_VISIO_FILE,
    DELETE_VISIO_PAGE,
    IMPORT_VISIO_FILE,
    MAP_VALUE_TO_ALL_PAGES,
    RESET_IMPORT_VISIO_DATA,
    SET_IMPORT_VISIO_DATA,
    SET_PAGE_OPTIONS,
} from '../actionsTypes/import.actionTypes';
import { TImportVisioState } from '../reducers/import.reducer.types';
import {
    TSetPageOptionsPayload,
    TMapValueToAllPagesPayload,
    TDeleteVisioPagePayload,
    TDeleteVisioFilePayload,
    TAddVisioMatchingPayload,
    TImportVisioFileAction,
    TDeleteVisioFileAction,
    TMapValueToAllPagesAction,
    TDeleteVisioPageAction,
    TAddVisioMatchingAction,
    TSetPageOptionsAction,
    TCancelImportVisio,
    TAddVisioFiles,
} from './import.actions.types';

export const setImportVisioData = (payload: TImportVisioState) => ({
    type: SET_IMPORT_VISIO_DATA,
    payload,
});

export const addVisioMatching = (payload: TAddVisioMatchingPayload): TAddVisioMatchingAction => ({
    type: ADD_VISIO_MATCHING,
    payload,
});

export const mapValueToAllPages = (payload: TMapValueToAllPagesPayload): TMapValueToAllPagesAction => ({
    type: MAP_VALUE_TO_ALL_PAGES,
    payload,
});

export const importVisioFile = (): TImportVisioFileAction => ({
    type: IMPORT_VISIO_FILE,
});

export const setPageOptions = (payload: TSetPageOptionsPayload): TSetPageOptionsAction => ({
    type: SET_PAGE_OPTIONS,
    payload,
});

export const deleteVisioPage = (payload: TDeleteVisioPagePayload): TDeleteVisioPageAction => ({
    type: DELETE_VISIO_PAGE,
    payload,
});

export const deleteVisioFile = (payload: TDeleteVisioFilePayload): TDeleteVisioFileAction => ({
    type: DELETE_VISIO_FILE,
    payload,
});

export const resetImportVisioData = () => ({
    type: RESET_IMPORT_VISIO_DATA,
});

export const cancelImportVisio = (): TCancelImportVisio => ({
    type: CANCEL_IMPORT_VISIO,
});

export const addVisioFiles = (fileList: File[]): TAddVisioFiles => ({
    type: ADD_VISIO_FILES,
    payload: { fileList },
});

export const clearDataAfterImport = () => ({
    type: CLEAR_VISIO_DATA_AFTER_IMPORT,
});