import {
    SCRIPT_EXECUTE_DIALOG_ADD_NODE_ID,
    SCRIPT_EXECUTE_DIALOG_ADD_NODE_PATH,
    SCRIPT_EXECUTE_DIALOG_DELETE_NODE_ID,
    SCRIPT_EXECUTE_DIALOG_DELETE_PARAM_FILES,
    SCRIPT_EXECUTE_DIALOG_REQUEST_SUCCESS,
    SCRIPT_EXECUTE_DIALOG_SET_UPLOAD_STATUS,
} from '../actionsTypes/scriptExecuteDialog.actionTypes';
import { TReducer } from '../utils/types';
import { TScriptExecuteDialogState } from './scriptExecuteDialog.reducer.types';

const initial: TScriptExecuteDialogState = {
    fileParams: {},
    nodeParams: {},
};

export const ScriptExecuteDialogReducer: TReducer<TScriptExecuteDialogState> = (state = initial, action) => {
    switch (action.type) {
        case SCRIPT_EXECUTE_DIALOG_SET_UPLOAD_STATUS: {
            const { paramName, uploadStatus, uploadedFileName, originalFileName } = action.payload;

            return {
                ...state,
                fileParams: {
                    ...state.fileParams,
                    [paramName]: {
                        uploadStatus,
                        uploadedFileName,
                        originalFileName,
                    },
                },
            };
        }

        case SCRIPT_EXECUTE_DIALOG_REQUEST_SUCCESS:
        case SCRIPT_EXECUTE_DIALOG_DELETE_PARAM_FILES: {
            return initial;
        }

        case SCRIPT_EXECUTE_DIALOG_ADD_NODE_ID: {
            const { paramName, nodeId } = action.payload;

            return {
                ...state,
                nodeParams: {
                    ...state.nodeParams,
                    [paramName]: {
                        ...state.nodeParams[paramName],
                        nodeId,
                    },
                },
            };
        }

        case SCRIPT_EXECUTE_DIALOG_DELETE_NODE_ID: {
            const { paramName } = action.payload;

            return {
                ...state,
                nodeParams: {
                    ...state.nodeParams,
                    [paramName]: {
                        nodeId: undefined
                    },
                },
            };
        }

        case SCRIPT_EXECUTE_DIALOG_ADD_NODE_PATH: {
            const { paramName, nodePath } = action.payload;

            return {
                ...state,
                nodeParams: {
                    ...state.nodeParams,
                    [paramName]: {
                        ...state.nodeParams[paramName],
                        path: nodePath,
                    },
                },
            };
        }

        default:
            return state;
    }
};
