import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../../SystemProperties.messages';
import theme from '../../SystemProperties.scss';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../../src/selectors/systemProperties.selectors';
import { FormItemInputWithLabel } from '../FormItemInputWithLabelComponent/FormItemInputWithLabel.component';
import { SystemPropertiesFormItemNames } from '../../SystemProperties.types';

export const AppDBSettings = () => {
    const intl = useIntl();
    const appDbHost = useSelector(SystemPropertiesSelectors.getAppHost);
    const appDbName = useSelector(SystemPropertiesSelectors.getAppDBName);
    const appDbPort = useSelector(SystemPropertiesSelectors.getAppDbPort);
    const appDbUser = useSelector(SystemPropertiesSelectors.getAppDbUser);
    const appDbUrl = useSelector(SystemPropertiesSelectors.getAppDbUrl);

    return (
        <div className={theme.formTab}>
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.appDbHost}
                initialValue={appDbHost}
                label={intl.formatMessage(messages.appDbHost)}
                disabled
                placeholder="localhost"
            />
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.appDbName}
                initialValue={appDbName}
                label={intl.formatMessage(messages.appDbName)}
                disabled
                placeholder="bpm"
            />
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.appDbPort}
                initialValue={appDbPort}
                label={intl.formatMessage(messages.appDbPort)}
                disabled
                placeholder="5432"
            />
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.appDbUser}
                initialValue={appDbUser}
                label={intl.formatMessage(messages.appDbUser)}
                disabled
                placeholder="bpm"
            />
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.appDbUrl}
                initialValue={appDbUrl}
                label={intl.formatMessage(messages.appDbUrl)}
                disabled
            />
        </div>
    );
};
