import type { DiagramElement, ObjectInstance, Symbol } from '@/serverapi/api';
import type { MxCell } from 'MxGraph';
import { isSystemStyle, symbolService } from '@/services/SymbolsService';
import ObjectInstanceSerializer from './ObjectInstanceSerializer.class';
import { getDefaultSymbolStyle } from '../Simple/SimpleSymbol.utils';

export default class FlatObjectInstanceSerializer extends ObjectInstanceSerializer {
    labelCell: MxCell;
    isEditable: boolean;
    initialSymbol: Symbol;

    public setLabelCell(cell: MxCell) {
        this.labelCell = cell;
    }

    public setEditable(isEditable: boolean) {
        this.isEditable = isEditable;
    }

    public setInitialSymbol(symbol: Symbol) {
        this.initialSymbol = symbol;
    }

    protected replaceSystemStyles(): DiagramElement {
        const rootCellStyle = this.rootCell.getStyle();
        const rootCellValue = this.rootCell.getValue();
        const updatedValue = { ...rootCellValue };

        // если стиль не был заменен на стиль не читаемого / не изменяемго / без определения ... символа то сохраняем его
        if (!isSystemStyle(rootCellStyle) && this.isEditable) {
            const labelCellStyle = this.labelCell.getStyle();

            // модель может быть в режиме поиска или фильтрации элементов, в этом случае часть определений отображаются полупрозрачными
            // убираем прозрачность
            updatedValue.style = symbolService().deleteOpacity(rootCellStyle);
            updatedValue.labelStyle = symbolService().prepareLabelStyleToSave(labelCellStyle);
        }

        return updatedValue;
    }

    serialize(): ObjectInstance {
        const objectInstance = super.serialize();
        // в DiagramElement хранятся style и labelStyle только, если они отличаются от style и labelStyle в символе
        const isStyleChanged =
            objectInstance.style !== getDefaultSymbolStyle(this.initialSymbol, this.rootCell.getValue().symbolId);
        const isLabelStyleChanged = objectInstance.labelStyle !== this.initialSymbol?.labelStyle;

        const { x, y } = this.rootCell.getGeometry();
        const { x: labelX, y: labelY, width: labelWidth, height: labelHeight } = this.labelCell.getGeometry();

        return {
            ...objectInstance,
            style: isStyleChanged ? objectInstance.style : '',
            labelStyle: isLabelStyleChanged ? objectInstance.labelStyle : '',
            labelHeight,
            labelWidth,
            labelXOffset: Number(labelX) - Number(x),
            labelYOffset: Number(labelY) - Number(y),
        };
    }
}
