export const CREATE_DATABASE_ACTION = 'CREATE_DATABASE_ACTION';

export const UPDATE_REPOSITORY_ACTION = 'UPDATE_REPOSITORY_ACTION';

export const UPDATE_ERROR_DATABASE_ACTION = 'UPDATE_ERROR_DATABASE_ACTION';

export const FETCH_DB_ACCESS_INFO = 'FETCH_DB_ACCESS_INFO';

export const FETCHED_DB_ACCESS_INFO_SUCCESS = 'FETCHED_DB_ACCESS_INFO_SUCCESS';

export const OPEN_DBACCESS_OWNERS_EDITING_TAB = 'OPEN_DBACCESS_OWNERS_EDITING_TAB';

export const SAVE_INFO_OF_DBACCESS_OWNERS = 'SAVE_INFO_OF_DBACCESS_OWNERS';

export const COPY_DATABASE_DIALOG_SUBMIT = 'COPY_DATABASE_DIALOG_SUBMIT';
