import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '../serverapi/api';

export const databaseAccessState = (state: TRootState) => state.databaseAccess;

export namespace DBAccessSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(databaseAccessState, (state) => state.byServerId[serverId]);

    export const getOwnersByRepositoryId = (nodeId: NodeId) =>
        createSelector(byServerId(nodeId.serverId), (state) => state && state.get(nodeId.repositoryId));
}
