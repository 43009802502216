import React, { useEffect, useState } from 'react';
import theme from './approvalTemplates.scss';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table, TableCellProps } from 'react-virtualized';
import messages from './messages/approvalTemplates.messages';
import { LocalesService } from '../../../services/LocalesService';
import { Input, Tooltip } from 'antd';
import icApprovalEdit from '../../../resources/icons/ic-approval-edit.svg';
import { Icon } from '@/modules/UIKit';
import { SearchOutlined } from '@ant-design/icons';
import icSorter from '../../../resources/icons/ic-sorter.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RowCheckBox } from './RowChexbox.component';
import { HeadCheckBox } from './HeadCheckBox.component';
import {
    checkApprovalTemplates,
    createApprovalTemplate,
    deleteApprovalTemplates,
    editExistingApprovalTemplate,
    getApprovalTemplates,
} from '@/actions/approvalTemplates.actions';
import { useIntl } from 'react-intl';
import { ApprovalTemplateDTO } from '@/serverapi/api';
import { ApprovalTemplatesSelectors } from '@/selectors/approvalTemplates.selectors';
import { DeleteButton } from '../ApprovalsTable/DeleteButton.component';
import noData from '../../../resources/icons/noData.svg';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

const ROW_HEIGHT = 48;

type TApprovalTemplatesProps = {
    dialogTemplates?: boolean;
    lastApprovalTemplateIds?: string[];
    setCheckedTemplateId?: (id: string) => void;
};

export const ApprovalTemplates: React.FC<TApprovalTemplatesProps> = ({
    dialogTemplates,
    lastApprovalTemplateIds = [],
    setCheckedTemplateId,
}) => {
    const [sortDirection, setSortDirection] = useState<string>(dialogTemplates ? '' : 'desc');
    const [sortProperty, setSortProperty] = useState<string>('name');
    const locale = LocalesService.getLocale();
    const [searchText, setSearchText] = useState<string>('');
    const data: ApprovalTemplateDTO[] = useSelector(
        ApprovalTemplatesSelectors.getSortedApprovalTemplateData(
            searchText,
            sortProperty,
            sortDirection,
            lastApprovalTemplateIds,
            locale,
        ),
    );
    const intl = useIntl();
    const [clickedRowId, setClickedRowId] = useState<string | null>(null);
    const dispatch = useDispatch();
    const checkedTemplatesNodeIds: string[] = useSelector(ApprovalTemplatesSelectors.getCheckedApprovalTemplatesIds);
    const isDisabled: boolean = !checkedTemplatesNodeIds.length;

    const cellHeightCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: ROW_HEIGHT,
    });

    useEffect(() => {
        if (dialogTemplates) {
            dispatch(getApprovalTemplates());
        }
    }, []);

    const rowGetter = ({ index }) => data[index];

    const headerRender = (): JSX.Element => {
        return !dialogTemplates ? <HeadCheckBox tableDataLength={data.length} /> : <></>;
    };

    const handleChange = (id: string) => {
        dispatch(checkApprovalTemplates([id]));
    };

    function selectionColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element {
        const { id } = data[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={0}>
                <RowCheckBox handleChange={handleChange} id={id} />
            </CellMeasurer>
        );
    }

    function nameColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element {
        const { name } = data[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={1}>
                <span>{LocalesService.internationalStringToString(name, locale)}</span>
            </CellMeasurer>
        );
    }

    function idColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element {
        const { id } = data[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={2}>
                <span>{id}</span>
            </CellMeasurer>
        );
    }

    function actionsColumnRenderer({ dataKey, parent, rowIndex }: TableCellProps) {
        const { id } = data[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={7}>
                <div className={theme.actionBtnsContainer}>
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(editExistingApprovalTemplate({ approvalTemplateId: id }));
                        }}
                    >
                        <Tooltip title={intl.formatMessage(messages.edit)}>
                            <span>
                                <Icon className={theme.edit} spriteSymbol={icApprovalEdit} />
                            </span>
                        </Tooltip>
                    </span>
                </div>
            </CellMeasurer>
        );
    }

    const idHeaderRenderer = (): JSX.Element => {
        return (
            <div className={theme.sortableColumn}>
                <span>{intl.formatMessage(messages.id)}</span>
                <span
                    onClick={() => {
                        setSortProperty('id');
                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                    }}
                >
                    <Icon spriteSymbol={icSorter} />
                </span>
            </div>
        );
    };

    const nameHeaderRenderer = (): JSX.Element => {
        return (
            <div className={theme.sortableColumn}>
                <span>{intl.formatMessage(messages.name)}</span>
                <span
                    onClick={() => {
                        setSortProperty('name');
                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                    }}
                >
                    <Icon spriteSymbol={icSorter} />
                </span>
            </div>
        );
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value.toLowerCase());
    };

    const handleCreateApprovalTemplate = () => {
        dispatch(createApprovalTemplate());
    };

    const handleRowClick = ({ rowData }) => {
        const { id } = rowData;
        if (dialogTemplates) {
            setClickedRowId(id);
            setCheckedTemplateId && setCheckedTemplateId(id);
        } else {
            dispatch(checkApprovalTemplates([id]));
        }
    };

    const handleClick = () => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    dispatch(
                        deleteApprovalTemplates({
                            approvalTemplateIds: checkedTemplatesNodeIds,
                        }),
                    );
                },
                title: intl.formatMessage(messages.deletingApproval),
                question: intl.formatMessage(messages.deletingApprovalDescription),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    return (
        <>
            <div className={theme.headerContainer}>
                <div className={theme.searchInput}>
                    <Input suffix={<SearchOutlined />} onChange={handleSearch} />
                </div>
                {!dialogTemplates ? (
                    <div className={theme.buttonContainer}>
                        <DeleteButton handleClick={handleClick} isDisabled={isDisabled} />
                        <Button
                            visualStyle="primary"
                            onClick={handleCreateApprovalTemplate}
                        >
                            {intl.formatMessage(messages.create)}
                        </Button>
                    </div>
                ) : null}
            </div>
            <div className={dialogTemplates ? theme.templatesTableContainer : theme.tableContainer}>
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            width={width}
                            height={height}
                            rowHeight={cellHeightCache.rowHeight}
                            className={dialogTemplates ? theme.tableTemplates : theme.table}
                            headerHeight={ROW_HEIGHT}
                            rowCount={data.length}
                            rowGetter={rowGetter}
                            headerClassName={theme.headerName}
                            rowClassName={({ index }: { index: number }) => {
                                const rowData = data[index];

                                return rowData && rowData.id === clickedRowId
                                    ? theme.highlightedRowClass
                                    : theme.rowClassName;
                            }}
                            onRowClick={handleRowClick}
                        >
                            {!dialogTemplates ? (
                                <Column
                                    flexGrow={1}
                                    maxWidth={40}
                                    minWidth={40}
                                    width={40}
                                    dataKey="check"
                                    headerRenderer={headerRender}
                                    cellRenderer={selectionColumnRenderer}
                                />
                            ) : null}

                            <Column
                                width={width}
                                dataKey="id"
                                minWidth={300}
                                maxWidth={350}
                                headerRenderer={idHeaderRenderer}
                                cellRenderer={idColumnRenderer}
                                className={theme.columnClassName}
                            />
                            <Column
                                width={width}
                                dataKey="name"
                                headerRenderer={nameHeaderRenderer}
                                cellRenderer={nameColumnRenderer}
                                className={theme.columnClassName}
                            />
                            {!dialogTemplates ? (
                                <Column width={100} dataKey="actions" cellRenderer={actionsColumnRenderer} />
                            ) : null}
                        </Table>
                    )}
                </AutoSizer>
                {data.length === 0 ? (
                    <div className={theme.NoDataWrapper}>
                        <Icon spriteSymbol={noData} className={theme.noDataIcon} />
                        <div>{intl.formatMessage(messages.noData)}</div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};
