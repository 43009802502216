import { defineMessages } from 'react-intl';

export default defineMessages({
    allowed: {
        id: 'FolderTypeTab.allowed',
        defaultMessage: 'Разрешено',
    },
    allowAll: {
        id: 'FolderType.allowAll',
        defaultMessage: 'Разрешить все',
    },
});
