import React, { FC } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Form, Input } from 'antd';
import theme from './theme/UserEdit.component.scss';
import messages from './ServerForm.messages';
import IconInputLoginComponent from '../../UIKit/components/Icons/IconInputLogin.component';
import IconInputPasswordComponent from '../../UIKit/components/Icons/IconInputPassword.component';
import { TUserConnectInfo } from '../ServerForm.types';

type TFullUserEditProps = WrappedComponentProps & {
    value: TUserConnectInfo;
    disabled?: boolean;
    onChange: (info: TUserConnectInfo) => void;
};

const UserEdit: FC<TFullUserEditProps> = (props) => {
    const {
        intl,
        onChange,
        value: { username, password, remember },
        disabled,
    } = props;

    return (
        <>
            <Form.Item>
                <Input
                    name="username"
                    data-test="login-page_login-input"
                    disabled={disabled}
                    placeholder={intl.formatMessage(messages.userName)}
                    prefix={<IconInputLoginComponent color="var(--mainColor)" className={theme.icon} />}
                    value={username}
                    onChange={(e) =>
                        onChange({
                            username: e.target.value,
                            password,
                            remember,
                        })
                    }
                    autoComplete="off"
                />
            </Form.Item>
            <Form.Item>
                <Input.Password
                    name="password"
                    data-test="login-page_password-input"
                    placeholder={intl.formatMessage(messages.password)}
                    prefix={<IconInputPasswordComponent color="var(--mainColor)" className={theme.icon} />}
                    value={password}
                    onChange={(e) =>
                        onChange({
                            username,
                            password: e.target.value,
                            remember,
                        })
                    }
                    autoComplete="off"
                />
            </Form.Item>
        </>
    );
};
const IntlComponent = injectIntl(UserEdit);

export { IntlComponent as UserEdit };
