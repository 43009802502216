import { defineMessages } from 'react-intl';

export default defineMessages({
    selectSymbolToReplaceOnPasteFormName: {
        id: 'SelectSymbolToReplaceOnPasteDialogFormName.selectSymbolToReplaceOnPasteFormName',
        defaultMessage: 'Выберите символ',
    },
    selectSymbolToReplaceOnPasteFormConfirmButton: {
        id: 'SelectSymbolToReplaceOnPasteDialogFormName.selectSymbolToReplaceOnPasteFormConfirmButton',
        defaultMessage: 'Добавить на холст',
    },
    selectSymbolToReplaceOnPasteFormDeclineButton: {
        id: 'SelectSymbolToReplaceOnPasteDialogFormName.selectSymbolToReplaceOnPasteFormDeclineButton',
        defaultMessage: 'Отмена',
    },
});
