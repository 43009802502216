import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { ScriptDashboard } from '../components/ScriptDashboard/ScriptDashboard.component';
import { TScriptDashboardProps } from '../components/ScriptDashboard/ScriptDashboard.types';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { NodeId } from '../../../serverapi/api';
import { ScriptExecutionSelectors } from '../../../selectors/scriptExecution.selectors';
import { fileDownload } from '../../../actions/file.actions';
import { TWorkspaceTab } from '../../../models/tab.types';
import { downloadLogFile } from '@/actions/scriptDashboard.actions';
import { openScriptDialog, scriptOpenByNodeId } from '@/actions/entities/script.actions';

type TScriptDashboardContainerOwnProps = {
    tab: TWorkspaceTab;
};

const mapStateToProps = (
    state: TRootState,
    ownProps: TScriptDashboardContainerOwnProps,
): Partial<TScriptDashboardProps> => {
    return {
        content: ScriptExecutionSelectors.byServerId(ownProps.tab.nodeId.serverId)(state),
        server: ServerSelectors.server(ownProps.tab.nodeId.serverId)(state),
        serverId: ownProps.tab.nodeId.serverId
    };
};

const mapDispatchToProps: (dispatch) => Partial<TScriptDashboardProps> = (dispatch) => ({
    downloadFile: (fileId: NodeId) => dispatch(fileDownload(fileId)),
    downloadLogFile: (operationId: string, serverId: string) => dispatch(downloadLogFile(operationId, serverId)),
    runScriptStep: (operationId: string, serverId: string) => dispatch(openScriptDialog(serverId, operationId)),
    openScript: (scriptId: NodeId) => dispatch(scriptOpenByNodeId(scriptId))
});

export const ScriptDashboardContainer = connect(mapStateToProps, mapDispatchToProps)(ScriptDashboard);
