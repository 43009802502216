import { PermissionModel, PrincipalDescriptor, PrincipalPermissions } from '../../../../serverapi/api';

export interface IPrincipalPermissionsColumnData {
    principalPermissions?: PrincipalPermissions;
    principal?: PrincipalDescriptor;
    authCreate?: PermissionModel;
    authRead?: PermissionModel;
    authWrite?: PermissionModel;
    authDelete?: PermissionModel;
    authAdministration?: PermissionModel;
}

export enum ResultPermissions {
    allUsers = 'allUsers',
    usersWithPermissions = 'usersWithPermissions',
    usersWithoutPermissions = 'usersWithoutPermissions',
}
