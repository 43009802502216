// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DesignTab__actionsRow__RMMJL{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;grid-gap:12px;gap:12px;width:100%;margin:16px 0 20px}.DesignTab__actionsRow__RMMJL button{margin:0}.DesignTab__settingsTitle__FqcNu{color:#363636;font-size:16px;font-weight:500;line-height:24px}.DesignTab__inputsRow__FXxNi{display:-webkit-box;display:-ms-flexbox;display:flex;grid-gap:12px;gap:12px;width:100%;margin-top:16px}.DesignTab__input__ltjec{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/SymbolEditorTab/components/DesignTab/DesignTab.scss"],"names":[],"mappings":"AAAA,8BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kBAAA,CAAA,cAAA,CACA,aAAA,CAAA,QAAA,CACA,UAAA,CACA,kBAAA,CAEA,qCACI,QAAA,CAIR,iCACI,aAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAGJ,6BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,aAAA,CAAA,QAAA,CACA,UAAA,CACA,eAAA,CAGJ,yBACI,kBAAA,CAAA,mBAAA,CAAA,WAAA","sourcesContent":[".actionsRow {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    gap: 12px;\r\n    width: 100%;\r\n    margin: 16px 0 20px;\r\n\r\n    button {\r\n        margin: 0;\r\n    }\r\n}\r\n\r\n.settingsTitle {\r\n    color: rgb(54, 54, 54);\r\n    font-size: 16px;\r\n    font-weight: 500;\r\n    line-height: 24px;\r\n}\r\n\r\n.inputsRow {\r\n    display: flex;\r\n    gap: 12px;\r\n    width: 100%;\r\n    margin-top: 16px;\r\n}\r\n\r\n.input {\r\n    flex-grow: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsRow": "DesignTab__actionsRow__RMMJL",
	"settingsTitle": "DesignTab__settingsTitle__FqcNu",
	"inputsRow": "DesignTab__inputsRow__FXxNi",
	"input": "DesignTab__input__ltjec"
};
export default ___CSS_LOADER_EXPORT___;
