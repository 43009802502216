import { defineMessages } from 'react-intl';

export default defineMessages({
    alignTop: {
        id: 'VerticalAlignSelector.alignTop',
        defaultMessage: 'Сверху',
    },
    alignCenter: {
        id: 'VerticalAlignSelector.alignCenter',
        defaultMessage: 'По центру',
    },
    alignBottom: {
        id: 'VerticalAlignSelector.alignBottom',
        defaultMessage: 'Снизу',
    },
});
