import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import {
    TAppHaveAuthTab,
    TNodeOpenFailed,
    TNodeOpenSuccessful,
    TNewTabOpened,
    TTabsBusAppExitRequest,
    TTabsBusSubscribe,
    TTabsBusUnsubscribe,
} from './tabsBus.actions.types';

export const tabsBusAppExitRequestAction = (): TTabsBusAppExitRequest => ({
    type: TabsBusActions.TABS_BUS_APP_EXIT_REQUEST,
});

export const appHaveAuthTabAction = (): TAppHaveAuthTab => ({
    type: TabsBusActions.APP_HAVE_AUTHORIZED_TAB,
});

export const tabsBusSubscribeAction = (): TTabsBusSubscribe => ({
    type: TabsBusActions.TABS_BUS_SUBSCRIBE,
});

export const tabsBusUnsubscribeAction = (): TTabsBusUnsubscribe => ({
    type: TabsBusActions.TABS_BUS_UNSUBSCRIBE,
});

export const newAppInstanceIsOpenedAction = (externalLink: string): TNewTabOpened => ({
    type: TabsBusActions.NEW_APP_INSTANCE_IS_OPENED,
    payload: {
        externalLink,
    },
});

export const nodeOpenSuccessfulAction = (): TNodeOpenSuccessful => ({
    type: TabsBusActions.NODE_OPEN_SUCCESSFUL,
});

export const nodeOpenFailedAction = (): TNodeOpenFailed => ({
    type: TabsBusActions.NODE_OPEN_FAILED,
});
