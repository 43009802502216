import { EditButton, DeleteButton, RowButtons } from '@/modules/AdminTools/Button/RowButtons';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { NotificationDTO, NotificationTemplateDTO } from '@/serverapi/api';
import { Col, Input, Row, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import theme from './NotificationsList.scss';
import messages from './NotificationsList.messages';
import { SearchOutlined } from '@ant-design/icons';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { deleteNotification, saveNotification } from '@/actions/notifications.actions';
import { TTableRowData } from './NotificationList.types';
import { getFiltredRows } from './NotificationsList.utils';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TNotificationsListProps = {
    notifications: NotificationDTO[];
    notificationTemplates: NotificationTemplateDTO[];
};

export const NotificationsList: FC<TNotificationsListProps> = (props) => {
    const { notifications, notificationTemplates } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const [searchInput, setSearchInput] = useState<string>('');
    const containerElem = useRef<HTMLDivElement>(null);
    const headerElem = useRef<HTMLDivElement>(null);

    const currentLocale: Locale = useSelector(getCurrentLocale);

    const createHandler = () => {
        dispatch(openDialog(DialogType.NOTIFICATION_SETTINGS_DIALOG, { templates: notificationTemplates }));
    };

    const deleteHandler = (notificationId: string) => {
        dispatch(deleteNotification(notificationId));
    };
    const editHandler = (notificationId: string) => {
        const notificationForEdit = notifications.find((notification) => notification.id === notificationId);
        dispatch(
            openDialog(DialogType.NOTIFICATION_SETTINGS_DIALOG, {
                isEditing: true,
                data: notificationForEdit,
                templates: notificationTemplates,
            }),
        );
    };

    const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== searchInput) {
            setSearchInput(e.target.value.trim().toLowerCase());
        }
    };

    const onChange = (id: string, enabled: boolean) => {
        const foundNotification = notifications.find((notification) => notification.id === id);
        if (foundNotification) dispatch(saveNotification({ ...foundNotification, enabled }));
    };

    const columns: ColumnsType<TTableRowData> = [
        {
            title: intl.formatMessage(messages.id),
            dataIndex: 'id',
            width: '15%',
        },
        {
            title: intl.formatMessage(messages.name),
            dataIndex: 'name',
            width: '20%',
            ellipsis: true,
        },
        {
            title: intl.formatMessage(messages.type),
            dataIndex: 'type',
            width: '20%',
            ellipsis: true,
        },
        {
            title: intl.formatMessage(messages.template),
            dataIndex: 'template',
            width: '25%',
            ellipsis: true,
        },
        {
            title: intl.formatMessage(messages.on_off),
            dataIndex: 'switch',
            width: '10%',
            align: 'center',
            render: (value, record) => (
                <Switch checked={!!record.enabled} onChange={(checked) => onChange(record.id, checked)} />
            ),
        },
        {
            title: '',
            dataIndex: 'buttons',
            width: '10%',
            align: 'right',
            render: (value, record) => (
                <RowButtons
                    className={theme.rowButtons}
                    buttons={[
                        EditButton.build(() => editHandler(record.id), undefined, `${record.id}_editBtn`),
                        DeleteButton.build(
                            () => deleteHandler(record.id),
                            undefined,
                            `${record.id}_delBtn`,
                            undefined,
                            intl.formatMessage(messages.delete),
                            intl.formatMessage(messages.deleteMessage, { name: record.name }),
                        ),
                    ]}
                />
            ),
        },
    ];

    const rows: TTableRowData[] = getFiltredRows(notifications, notificationTemplates, searchInput, currentLocale);

    let tableHeight: number = 700;

    if (containerElem.current && headerElem.current) {
        tableHeight = containerElem.current.clientHeight - headerElem.current.clientHeight - 100;
    }

    return (
        <Col className={theme.container} ref={containerElem}>
            <Row className={theme.row} ref={headerElem}>
                <Input className={theme.searchInput} suffix={<SearchOutlined />} onChange={searchHandler} />
                <Button onClick={createHandler} visualStyle="primary">
                    {intl.formatMessage(messages.create)}
                </Button>
            </Row>
            <Row className={theme.row}>
                <Table scroll={{ y: tableHeight }} pagination={false} columns={columns} dataSource={rows} />
            </Row>
        </Col>
    );
};
