import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { createCompactableSelect } from '../../../CompactableSelect/CompactableSelect.component';
import { MoveTo } from '../../../../../../models/move-to';
import messages from './MoveToSelect.messages';
import icTransition from '../../../../../../resources/icons/transition.svg';
import icObject from '../../../../../../resources/icons/object.svg';
import icDecomposition from '../../../../../../resources/icons/decomposition.svg';
import { TCompactableSelectItemType } from '../../../CompactableSelect/CompactableSelect.types';
import { useDispatch } from 'react-redux';
import { moveToAction } from '../../../../../../actions/editor.actions';
import menuItemTheme from '../../../items/MenuItem.scss';

interface IMoveToSelectProps {
    compact?: boolean;
    disabled?: boolean;
    hasModelAssignments: boolean;
    showMoveToTreeBtn: boolean;
}

const MoveToCompactableSelect = createCompactableSelect<MoveTo>();

export const MoveToSelect: FC<IMoveToSelectProps> = (props) => {
    const { hasModelAssignments, showMoveToTreeBtn, compact, disabled } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const onMoveToAction = (moveTo: MoveTo) => {
        dispatch(moveToAction(moveTo));
    };

    return (
        <div data-test="main-panel_button-go-to" className={menuItemTheme.tooltipContainer}>
            <MoveToCompactableSelect
                title={intl.formatMessage(messages.title)}
                compact={compact}
                items={[
                    {
                        type: TCompactableSelectItemType.Value,
                        value: MoveTo.Decomposition,
                        label: intl.formatMessage(messages.decomposition),
                        spriteSymbol: icDecomposition,
                        disabled: !hasModelAssignments,
                    },
                    {
                        type: TCompactableSelectItemType.Value,
                        value: MoveTo.ObjectInTree,
                        label: intl.formatMessage(messages.objectInTree),
                        spriteSymbol: icObject,
                        disabled: !showMoveToTreeBtn,
                    },
                ]}
                disabled={disabled}
                value={MoveTo.Noop}
                onSelect={onMoveToAction}
                defaultSprite={icTransition}
            />
        </div>
    );
};
