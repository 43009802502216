// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TooltipTitle__verticalContainer__x6Jzz{-webkit-writing-mode:vertical-rl;-ms-writing-mode:tb-rl;writing-mode:vertical-rl;letter-spacing:.5px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-weight:750}.TooltipTitle__horizontalContainer____vL5{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/modules/TooltipTitle/TooltipTitle.scss"],"names":[],"mappings":"AAAA,wCACI,gCAAA,CAAA,sBAAA,CAAA,wBAAA,CACA,mBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,eAAA,CAGJ,0CACI,kBAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".verticalContainer {\r\n    writing-mode: vertical-rl;\r\n    letter-spacing: 0.5px;\r\n    white-space: nowrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    font-weight: 750;\r\n}\r\n\r\n.horizontalContainer {\r\n    white-space: nowrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verticalContainer": "TooltipTitle__verticalContainer__x6Jzz",
	"horizontalContainer": "TooltipTitle__horizontalContainer____vL5"
};
export default ___CSS_LOADER_EXPORT___;
