export const OPEN_MONITOR_TAB = 'OPEN_MONITOR_TAB';

export const LOAD_MONITORING_INFO = 'LOAD_MONITORING_INFO';

export const LOAD_MONITORING_INFO_SUCCESS = 'LOAD_MONITORING_INFO_SUCCESS';

export const MONITORING_INFO_LOADING = 'MONITORING_INFO_LOADING';

export const MONITORING_INFO_REFRESH = 'MONITORING_INFO_REFRESH';

export const CLOSE_MONITORING_TAB = 'CLOSE_MONITORING_TAB';

export const DELETE_MONITORING_STATUS = 'DELETE_MONITORING_STATUS';
