import { defineMessages } from 'react-intl';

export default defineMessages({
    script: {
        id: 'ModelEvents.script',
        defaultMessage: 'Скрипт',
    },
    eventAction: {
        id: 'ModelEvents.eventAction',
        defaultMessage: 'Событие запуска',
    },
    eventRunStrategy: {
        id: 'ModelEvents.eventRunStrategy',
        defaultMessage: 'Стратегия запуска',
    },
    BEFORE: {
        id: 'ModelEvents.BEFORE',
        defaultMessage: 'До',
    },
    AFTER: {
        id: 'ModelEvents.AFTER',
        defaultMessage: 'После',
    },
    ERROR: {
        id: 'ModelEvents.ERROR',
        defaultMessage: 'В случае ошибки',
    },
    INSTEAD: {
        id: 'ModelEvents.INSTEAD',
        defaultMessage: 'Вместо',
    },
    READ_TREE_NODE: {
        id: 'ModelEvents.READ_TREE_NODE',
        defaultMessage: 'Открытие модели',
    },
    DELETE_TREE_NODE: {
        id: 'ModelEvents.DELETE_TREE_NODE',
        defaultMessage: 'Удаление узла дерева',
    },
    SAVE_TREE_NODE: {
        id: 'ModelEvents.SAVE_TREE_NODE',
        defaultMessage: 'Сохранение модели',
    },
    MOVE_TREE_NODE: {
        id: 'ModelEvents.MOVE_TREE_NODE',
        defaultMessage: 'Перемещение модели',
    },
    UNLOCK_TREE_NODE: {
        id: 'ModelEvents.UNLOCK_TREE_NODE',
        defaultMessage: 'Снятие блокировки узла дерева',
    },
    selectScript: {
        id: 'ModelEvents.selectScript',
        defaultMessage: 'Выберите скрипт:',
    },
    selectAction: {
        id: 'ModelEvents.selectAction',
        defaultMessage: 'Событие запуска:',
    },

    selectStrategy: {
        id: 'ModelEvents.selectStrategy',
        defaultMessage: 'Стратегия запуска:',
    },
    add: {
        id: 'ModelEvents.add',
        defaultMessage: 'Добавить',
    },
    notSelected: {
        id: 'ModelEvents.notSelected',
        defaultMessage: 'Не выбрано',
    },
    CHANGE_STATUS_APPROVAL: {
        id: 'ModelEvents.CHANGE_STATUS_APPROVAL',
        defaultMessage: 'Изменение статуса согласования',
    },
    CHANGE_STATUS_APPROVAL_STAGE: {
        id: 'ModelEvents.CHANGE_STATUS_APPROVAL_STAGE',
        defaultMessage: 'Изменение статуса этапа согласования',
    },
    VOTE_EVENT: {
        id: 'ModelEvents.VOTE_EVENT',
        defaultMessage: 'Принято решение по согласованию',
    },
    COMMENT_APPROVAL: {
        id: 'ModelEvents.COMMENT_APPROVAL',
        defaultMessage: 'Добавлен комментарий по согласованию',
    },
    CREATE_APPROVAL: {
        id: 'ModelEvents.CREATE_APPROVAL',
        defaultMessage: 'Создание согласования',
    },
    UPDATE_APPROVAL: {
        id: 'ModelEvents.UPDATE_APPROVAL',
        defaultMessage: 'Обновление согласования',
    },
});
