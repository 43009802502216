import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { NodeId } from '../serverapi/api';
import { TabsSelectors } from './tabs.selectors';
import { TreeSelectors } from './tree.selectors';
import { UserProfileSelectors } from './userProfile.selectors';
import { EditorMode } from '../models/editorMode';
import { LocalesService } from '../services/LocalesService';
import messages from '../modules/Editor/messages/EditorPopupMenu.messages';
import { EdgeInstanceImpl } from '../models/bpm/bpm-model-impl';
import { divideEdgesBychangeAbility } from '../utils/edgesForChangeType.utils';
import { TDividingEdges } from '../utils/edgesForChangeType.utils.types';

export namespace EdgesForChangeSelectors {
    export const getEdgesForChangeType = (edges: EdgeInstanceImpl[]) => {
        return (state: TRootState): TDividingEdges => {
            const nodeId: NodeId = TabsSelectors.getActiveTabId(state);
            const presetId: string = TreeSelectors.presetById(nodeId)(state);
            const forbiddenToEditEdgeTypesByProfileIds: string[] = UserProfileSelectors.getForbiddenToEditEdgeTypeIds(
                nodeId.serverId,
                presetId,
                edges,
            )(state);

            return divideEdgesBychangeAbility(edges, forbiddenToEditEdgeTypesByProfileIds);
        };
    };

    export const getTooltipMessage = (edges: EdgeInstanceImpl[], hasAvailableTypes: boolean, mode: EditorMode) =>
        createSelector<TRootState, TDividingEdges, string>(getEdgesForChangeType(edges), (edgesToEdit) => {
            const intl = LocalesService.useIntl();
            const { deniedEdgesByProfile, edgesWithDefinition, edgesWithOnlyOneType } = edgesToEdit;
            let tooltip = '';
            if (mode !== EditorMode.Edit) {
                tooltip = intl.formatMessage(messages.onlyInEditModeAvailable);
            } else if (edges.length === 1 && edgesWithDefinition.length === 1) {
                tooltip = intl.formatMessage(messages.edgeDefinitionTypeWarning);
            } else if (edges.length === 1 && deniedEdgesByProfile.length === 1) {
                tooltip = intl.formatMessage(messages.edgeProfileTypeWarning);
            } else if (edges.length === edgesWithOnlyOneType.length) {
                tooltip = intl.formatMessage(messages.edgesWithoutOtherTypesWarning);
            } else if (!hasAvailableTypes) {
                tooltip = intl.formatMessage(messages.noCommonEdgeTypesWarning);
            }

            return tooltip;
        });
}
