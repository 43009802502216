// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabsarea__tabContainer___pto1 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:var(--grey-dark-UIKit)}.Tabsarea__tabContainer___pto1 .ant-tabs-tab{padding:12px 10px}.Tabsarea__tabContainer___pto1 .ant-tabs-tab-btn{padding-bottom:4px}.Tabsarea__tabContainer___pto1 .ant-tabs-nav{height:28px;margin-top:2px;margin-left:5px}.Tabsarea__tabContainer___pto1 .ant-tabs-content-holder{border-top:1px solid var(--Neutral100);margin-top:-17px}.Tabsarea__tabContainer___pto1 .ant-tabs-tab:hover:not(.ant-tabs-tab-active){color:var(--grey-dark-UIKit);border-bottom:2px solid #e2e1e1;border-radius:unset}", "",{"version":3,"sources":["webpack://./src/modules/Tabs/components/Tabsarea/Tabsarea.scss"],"names":[],"mappings":"AAEQ,mFACI,4BAAA,CAEJ,6CACI,iBAAA,CAEJ,iDACI,kBAAA,CAEJ,6CACI,WAAA,CACA,cAAA,CACA,eAAA,CAEJ,wDACI,sCAAA,CACA,gBAAA,CAEJ,6EACI,4BAAA,CACA,+BAAA,CACA,mBAAA","sourcesContent":[".tabContainer {\r\n    :global {\r\n        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\r\n            color: var(--grey-dark-UIKit);\r\n        }\r\n        .ant-tabs-tab {\r\n            padding: 12px 10px;\r\n        }\r\n        .ant-tabs-tab-btn {\r\n            padding-bottom: 4px;\r\n        }\r\n        .ant-tabs-nav {\r\n            height: 28px;\r\n            margin-top: 2px;\r\n            margin-left: 5px;\r\n        }\r\n        .ant-tabs-content-holder {\r\n            border-top: 1px solid var(--Neutral100);\r\n            margin-top: -17px;\r\n        }\r\n        .ant-tabs-tab:hover:not(.ant-tabs-tab-active) {\r\n            color: var(--grey-dark-UIKit);\r\n            border-bottom: 2px solid #e2e1e1;\r\n            border-radius: unset;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "Tabsarea__tabContainer___pto1"
};
export default ___CSS_LOADER_EXPORT___;
