import {
    MxRectangle,
    MxEllipse,
    MxUtils,
    MxConstraintHandler,
    MxGraph,
    MxShape,
    MxConstants,
    MxEvent,
    MxClient,
    MxImage,
    MxCellState,
    MxPoint,
    MxConnectionConstraint,
    MxMouseEvent,
    MxImageShape,
    MxCell,
} from '../mxgraph';
import {
    VALID_PORTS_IMAGE,
    NOT_VALID_PORTS_IMAGE,
    ACTIVE_PORTS_FOCUS_FILL_COLOR,
    NOT_ACTIVE_PORTS_FOCUS_FILL_COLOR,
    NO_VALID_PORT_FOCUS_FILL_COLOR,
} from '../util/PortsDefinitions.utils';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolManager.class';

export class BPMMxConstraintHandler extends MxConstraintHandler {
    /* tslint:disable:no-any */
    focusHighlight: MxShape | null = null; // started point
    focusTargetHighlight: MxShape | null = null;
    focusIcons: any = null;
    currentConstraint: any = null;
    currentFocusArea: any = null;
    currentPoint: any = null;
    currentFocus: MxCellState | null = null;
    focusPoints: any = null;
    mouseleaveHandler: any = null;
    resetHandler: any = null;
    constraints: any = null;

    constructor(graph: MxGraph) {
        super(graph);
        this.highlightColor = NOT_ACTIVE_PORTS_FOCUS_FILL_COLOR;
    }

    createHighlightShape(): MxShape {
        return new MxEllipse(null, this.highlightColor, 'white', 2);
    }

    destroyFocusHighlight() {
        if (this.focusHighlight !== null && !this.graph.connectionHandler.isConnecting()) {
            this.focusHighlight.destroy();
            this.focusHighlight = null;
        } else if (this.focusHighlight !== null) {
            let error;
            if (this.currentFocus && this.graph.connectionHandler.previous) {
                error = this.graph.connectionHandler.validateConnection(
                    this.graph.connectionHandler.previous!.cell,
                    this.currentFocus!.cell,
                );
            }
            if (error) {
                this.focusHighlight.fill = NO_VALID_PORT_FOCUS_FILL_COLOR;
            } else {
                this.focusHighlight.fill = ACTIVE_PORTS_FOCUS_FILL_COLOR;
            }
            this.focusHighlight.redraw();
        }
    }

    destroyFocusTargetHighlight() {
        if (this.focusTargetHighlight !== null) {
            this.focusTargetHighlight.destroy();
            this.focusTargetHighlight = null;
        }
    }

    reset() {
        if (this.focusIcons != null) {
            for (let i = 0; i < this.focusIcons.length; i++) {
                this.focusIcons[i].destroy();
            }
            this.focusIcons = null;
        }
        this.destroyFocusHighlight();
        this.destroyFocusTargetHighlight();
        this.currentConstraint = null;
        this.currentFocusArea = null;
        this.currentPoint = null;
        this.currentFocus = null;
        this.focusPoints = null;
    }

    resetExceptFocusIcon() {
        this.destroyFocusHighlight();
        if (this.focusTargetHighlight !== null) {
            this.focusTargetHighlight.fill = NO_VALID_PORT_FOCUS_FILL_COLOR;
            this.focusTargetHighlight.redraw();
        }
        this.currentConstraint = null;
        this.currentFocusArea = null;
        this.currentPoint = null;
        this.currentFocus = null;
        this.focusPoints = null;
    }

    setFocus(me: MxMouseEvent, state: MxCellState | null) {
        if (state !== null && this.graph.isCellConnectable(state.cell)) {
            this.constraints = this.isEnabled() ? this.graph.getAllConnectionConstraints(state) : [];
        } else {
            this.constraints = null;
        }

        // Only uses cells which have constraints
        if (this.constraints != null && state) {
            this.currentFocus = state;
            this.currentFocusArea = new MxRectangle(state.x, state.y, state.width, state.height);

            if (this.focusIcons != null) {
                for (let i = 0; i < this.focusIcons.length; i++) {
                    this.focusIcons[i].destroy();
                }

                this.focusIcons = null;
                this.focusPoints = null;
            }

            this.focusPoints = [];
            this.focusIcons = [];

            let error: string | null = null;
            const focusedCell = this.currentFocus!.cell;

            // for first created edge
            if (this.graph.connectionHandler.previous) {
                error = this.graph.connectionHandler.validateConnection(
                    this.graph.connectionHandler.previous!.cell,
                    focusedCell,
                );
            } else if (this.graph.segmentHandler?.isActive()) {
                // for reconnecting edge
                error = this.graph.segmentHandler?.validateConnectionTo(focusedCell);
            }

            this.constraints.forEach((constraint: any, index: number) => {
                const cp = this.graph.getConnectionPoint(state, constraint);
                const img = this.getImageForConstraint(state, constraint, cp, error);

                const { src } = img;
                const bounds = new MxRectangle(
                    Math.round(cp.x - img.width / 2),
                    Math.round(cp.y - img.height / 2),
                    img.width,
                    img.height,
                );

                const icon = new MxImageShape(bounds, src);

                icon.dialect =
                    this.graph.dialect !== MxConstants.DIALECT_SVG
                        ? MxConstants.DIALECT_MIXEDHTML
                        : MxConstants.DIALECT_SVG;
                icon.preserveImageAspect = false;
                icon.init(this.graph.getView().getDecoratorPane());

                // Move the icon behind all other overlays
                if (icon.node.previousSibling != null) {
                    icon.node.parentNode!.insertBefore(icon.node, icon.node.parentNode!.firstChild);
                }

                const getState = () => {
                    return this.currentFocus != null ? this.currentFocus : state;
                };

                this.setNodeTestId(icon.node, constraint, index);

                icon.redraw();

                MxEvent.redirectMouseEvents(icon.node, this.graph, getState.bind(this));
                this.currentFocusArea.add(icon.bounds);
                icon.setCursor(MxConstants.CURSOR_BEND_HANDLE);
                this.focusIcons.push(icon);
                this.focusPoints.push(cp);
            });

            this.currentFocusArea.grow(this.getTolerance(me));
        } else {
            this.destroyIcons();
            this.destroyFocusHighlight();
            this.destroyFocusTargetHighlight();
        }
    }

    getCellForEvent(me: MxMouseEvent, point: MxPoint | null): MxCell | null {
        let cell: MxCell | null = me.getCell();

        // Gets cell under actual point if different from event location
        if (cell === null && point !== null && (me.getGraphX() !== point.x || me.getGraphY() !== point.y)) {
            cell = this.graph.getCellAt(point.x, point.y);
        }

        if (!cell) {
            return null;
        }

        if (ComplexSymbolManager.isComplexSymbolCell(cell)) {
            cell = ComplexSymbolManager.getConnectionCell(cell);
        }

        cell = this.graph.isCellLocked(cell) ? null : cell;

        return cell;
    }

    // Updates the state of this handler based on the given MxMouseEvent.
    // Source is a boolean indicating if the cell is a source or target. (from Documetation)
    update(me: MxMouseEvent, source: boolean, existingEdge: boolean, point: MxPoint | null) {
        if (this.isEnabled() && !this.isEventIgnored(me)) {
            // Lazy installation of mouseleave handler
            if (this.mouseleaveHandler == null && this.graph.container != null) {
                this.mouseleaveHandler = MxUtils.bind(this, () => {
                    this.reset();
                });

                MxEvent.addListener(this.graph.container, 'mouseleave', this.resetHandler);
            }

            const tol = this.getTolerance(me);
            const x = this.graph.snap(point !== null ? point.x : me.getGraphX());
            const y = this.graph.snap(point !== null ? point.y : me.getGraphY());
            const grid = new MxRectangle(x - tol, y - tol, 2 * tol, 2 * tol);
            const mouse = new MxRectangle(me.getGraphX(), me.getGraphY(), 1, 1);
            const state: MxCellState | null = this.graph.view.getState(this.getCellForEvent(me, point));
            // Keeps focus icons visible while over vertex bounds and no other cell under mouse or shift is pressed
            if (
                !this.isKeepFocusEvent(me) &&
                (this.currentFocusArea === null ||
                    this.currentFocus === null ||
                    state !== null ||
                    !this.graph.getModel().isVertex(this.currentFocus.cell) ||
                    !MxUtils.intersects(this.currentFocusArea, mouse)) &&
                state !== this.currentFocus
            ) {
                const cellIsSwimlane = state && this.graph.isSwimlane(state.cell);
                const parentIsSwimlane = state && this.graph.isSwimlane(state.cell.parent);
                let parentState: MxCellState | null = null;
                if (cellIsSwimlane && parentIsSwimlane) {
                    parentState = this.graph.getView().getState(state.cell.parent);
                }

                this.currentFocusArea = null;
                this.currentFocus = null;
                this.setFocus(me, parentState || state);
            }

            this.currentConstraint = null;
            this.currentPoint = null;
            let minDistSq: null | number = null;

            if (
                this.focusIcons !== null &&
                this.focusPoints !== null &&
                this.constraints !== null &&
                (state === null || this.currentFocus === state)
            ) {
                const cx = mouse.getCenterX();
                const cy = mouse.getCenterY();

                for (let i = 0; i < this.focusIcons.length; i++) {
                    const dx = cx - this.focusIcons[i].bounds.getCenterX();
                    const dy = cy - this.focusIcons[i].bounds.getCenterY();
                    const tmp: number = dx * dx + dy * dy;

                    if (
                        (this.intersects(this.focusIcons[i], mouse, source, existingEdge) ||
                            (point !== null && this.intersects(this.focusIcons[i], grid, source, existingEdge))) &&
                        (minDistSq === null || tmp < minDistSq)
                    ) {
                        this.currentConstraint = this.constraints[i];
                        this.currentPoint = this.focusPoints[i];
                        minDistSq = tmp;

                        const tmp1: MxRectangle = this.focusIcons[i].bounds.clone();
                        this.focusIcons[i].visible = false;
                        this.focusIcons[i].redraw();
                        tmp1.grow(2);

                        if (MxClient.IS_IE) {
                            tmp1.grow(1);
                            tmp1.width -= 1;
                            tmp1.height -= 1;
                        }

                        if (this.focusHighlight === null) {
                            const hl = this.createHighlightShape();
                            hl.dialect =
                                this.graph.dialect === MxConstants.DIALECT_SVG
                                    ? MxConstants.DIALECT_SVG
                                    : MxConstants.DIALECT_VML;
                            hl.pointerEvents = false;

                            hl.init(this.graph.getView().getOverlayPane());
                            this.focusHighlight = hl;

                            const getState = MxUtils.bind(this, () => {
                                return this.currentFocus !== null ? this.currentFocus : state;
                            });

                            MxEvent.redirectMouseEvents(hl.node, this.graph, getState);
                        }

                        if (this.focusTargetHighlight === null) {
                            const hl = this.createHighlightShape();
                            hl.dialect =
                                this.graph.dialect === MxConstants.DIALECT_SVG
                                    ? MxConstants.DIALECT_SVG
                                    : MxConstants.DIALECT_VML;
                            hl.pointerEvents = false;

                            hl.init(this.graph.getView().getOverlayPane());
                            this.focusTargetHighlight = hl;

                            const getState = MxUtils.bind(this, () => {
                                return this.currentFocus !== null ? this.currentFocus : state;
                            });

                            MxEvent.redirectMouseEvents(hl.node, this.graph, getState);
                        }

                        if (!this.graph.connectionHandler.isConnecting()) {
                            this.focusHighlight.bounds = tmp1;
                        }
                        this.focusTargetHighlight.bounds = tmp1;
                        this.focusTargetHighlight.redraw();

                        this.focusHighlight.redraw();
                    } else {
                        this.focusIcons[i].visible = true;
                        this.focusIcons[i].redraw();
                    }
                }
            }

            if (this.currentConstraint === null) {
                this.destroyFocusHighlight();
                this.destroyFocusTargetHighlight();
            }
        } else {
            this.currentConstraint = null;
            this.currentFocus = null;
            this.currentPoint = null;
        }
    }

    getImageForConstraint(
        state: MxCellState,
        constraint: MxConnectionConstraint,
        point: MxPoint,
        error?: string | null,
    ): MxImage {
        let image: MxImage;
        if (error) {
            image = new MxImage(NOT_VALID_PORTS_IMAGE, 10, 10);
        } else {
            image = new MxImage(VALID_PORTS_IMAGE, 10, 10);
        }

        return image;
    }

    private setNodeTestId(node: Element, constraint: MxConnectionConstraint, index: number = 0): Element {
        const iconId = constraint?.name?.toLowerCase().split(' ').join('_') || String(index);
        node.setAttribute('data-test-id', `constraint-icon_${iconId}`);

        return node;
    }
}
/* tslint:enable:no-any */
