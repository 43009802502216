import { applyMiddleware, compose, createStore } from 'redux';
import { rootReducer } from './reducers/root.reducer';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './sagas/root.saga';
import { getStore, setStore } from './store';

type TOptions = {
    trace?: boolean;
    traceLimit?: number;
    stateSanitizer?: (state: any) => any;
    actionsBlacklist?: string[] | string;
};

export default function configureStore() {
    const sagaMiddleware = createSagaMiddleware();

    const reduxDevtoolsExtension = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'];

    const options: TOptions = {
        ...(process.env.REDUX_ACTION_TRACE === 'true' ? { trace: true, traceLimit: 50 } : {}),
        //stateSanitizer: (state) => ({ recent: state.recent }),
        //actionsBlacklist: 'TREE_ITEM_RENAME_CANCEL',
    };

    const composeEnhancers = reduxDevtoolsExtension ? reduxDevtoolsExtension(options) : compose; // tslint:disable-line no-string-literal

    let localStore = getStore();
    if (localStore) {
        return localStore;
    }

    // const logger = createLogger({
    //     diff: false,
    //     collapsed: true,
    // });

    const middlewares = [
        applyMiddleware(sagaMiddleware),
        // applyMiddleware(logger)
    ];

    localStore = createStore(rootReducer, composeEnhancers(...middlewares));

    let sagaTasks = sagaMiddleware.run(function* () {
        yield rootSaga();
    });

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers/root.reducer', () => {
            console.clear();
            const nextRootReducer = require('./reducers/root.reducer').rootReducer;
            localStore.replaceReducer(nextRootReducer);
        });

        module.hot.accept('./sagas/root.saga', () => {
            const newSagas = require('./sagas/root.saga').rootSaga;
            sagaTasks.cancel();
            console.clear();
            // @ts-ignore
            sagaTasks.done.then(() => {
                sagaTasks = sagaMiddleware.run(function* replacedSaga() {
                    yield newSagas();
                });
            });
        });
    }

    setStore(localStore);

    return localStore;
}
