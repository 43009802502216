import { OPEN_COMPARE_MODELS_TAB, OPEN_GRAPHICAL_COMPARISON_TAB } from '@/actionsTypes/compareModels.actionTypes';
import {
    TOpenCompareModelsTab,
    TOpenCompareModelsTabPayload,
    TOpenGraphicalComparisonTab,
    TOpenGraphicalComparisonTabPayload,
} from './compareModels.actions.types';

export const openCompareModelsTab = ({
    comparedModelNodeId1,
    comparedModelNodeId2,
}: TOpenCompareModelsTabPayload): TOpenCompareModelsTab => ({
    type: OPEN_COMPARE_MODELS_TAB,
    payload: {
        comparedModelNodeId1,
        comparedModelNodeId2,
    },
});

export const openGraphicalComparisonTab = ({
    comparedModelNodeId1,
    comparedModelNodeId2,
}: TOpenGraphicalComparisonTabPayload): TOpenGraphicalComparisonTab => ({
    type: OPEN_GRAPHICAL_COMPARISON_TAB,
    payload: {
        comparedModelNodeId1,
        comparedModelNodeId2,
    },
});
