import type { IOverlayPart, IOverlayParts, TImageOverlayPart, TTextOverlayPart } from './BPMMxCellOverlay';
import type { TCurrentUserProfile } from '../../reducers/userProfile.reducer.types';
import type { TAlign, TAttributeOverlay, TBaseline } from '../../mxgraph/mxgraph.types';
import type {
    AttributeType,
    AttributeTypeStyle,
    AttributeValue,
    EdgeInstance,
    ModelAssignment,
    ObjectInstance,
    PresetImage,
    PrincipalDescriptor,
    Node,
} from '../../serverapi/api';
import type { MxCell, MxCellOverlay } from '../mxgraph.d';
import type { Locale } from '../../modules/Header/components/Header/header.types';
import type { BPMMxGraph } from '../bpmgraph';
import { unionBy } from 'lodash-es';
import {
    MxRectangle,
    MxImageShape,
    MxPoint,
    MxImage,
    MxConstants,
    MxEvent,
    MxEventObject,
    MxUtils,
    MxCellOverlay as MxCellOverlayClass,
} from '../mxgraph';
import {
    findStyleByAttributeTypeAndCellType,
    mapEdgeInstanceToSystemAttributeValues,
    mapNodeToSystemAttributeValues,
    setOverlay,
} from './BPMMxCellOverlay.utils';
import {
    CLICK_DECOMPOSITION_ICON_EVENT,
    DECOMPOSTION_ICON_TOOLTIP,
    DELETED_DEFINITION_ICON_TOOLTIP,
    OverlayType,
} from './CellOverlayManager.constants';
import { isUmlAttribute } from '../../modules/Uml/UmlEdgeAttributesConst';
import { LocalesService } from '../../services/LocalesService';
import { OverlayStyle, createOverlayFormat } from '../../services/bll/CheckAttributeRulesBllService';
import MxHTMLOverlayShape from './MxHTMLOverlayShape.class';
import BPMMxCellOverlay from './BPMMxCellOverlay';
import { getStore } from '../../store';
import { openAttributeLinkAction } from '../../actions/openAttributeLink.actions';
import { storageValueToString } from '../../modules/ObjectPropertiesDialog/components/utils';
import BPMMxDecompositionCellOverlay from './BPMMxDecompositionCellOverlay';
import { DECOMPOSITION_ICON, DELETED_DEFINITION_ICON } from '../util/PortsDefinitions.utils';
import { ProfileBllService } from '../../services/bll/ProfileBllService';
import { isUmlClassAttribute } from '../ComplexSymbols/symbols/UML/ClassSymbol/classSymbol.utils';
import { TAttributeDiscriminator } from '@/modules/FloatingAttributes/FloatingAttributes.types';
import { modelSystemAttributeTypes, systemAttributeTypes } from '@/utils/constants/systemAttributes.const';

type TAddDecompositionIconProps = {
    objectDefinitionId?: string;
    edgeDefinitionId?: string;
};

const overlayOnPartClickHandler = (graph: BPMMxGraph) => (object: BPMMxCellOverlay, event: MxEventObject) => {
    const index: number = parseInt(event.properties.event.target.getAttribute('data-part-index'), 10);
    const { parts } = object;
    const targetPart: IOverlayPart | undefined = parts[index];

    if (targetPart) {
        const { attributeValue } = targetPart;
        getStore().dispatch(openAttributeLinkAction(attributeValue, graph.id));
    }
};

export default class CellOverlayManager {
    graph: BPMMxGraph;

    constructor(graph: BPMMxGraph) {
        this.graph = graph;
    }

    /**
     * Добавляет оверлей к ячейке
     * @param cell
     * @param overlay
     */
    private addCellOverlay(cell: MxCell, overlay: MxCellOverlay) {
        this.graph.addCellOverlay(cell, overlay);
    }

    /**
     * Возвращает все оверлеи ячейки
     * @param cell
     */
    public getCellOverlays(cell: MxCell) {
        return this.graph.getCellOverlays(cell);
    }

    /**
     * Возвращает shape для оверлея
     * @param overlay
     */
    public getShape(overlay: BPMMxCellOverlay): MxImageShape {
        return [OverlayType.FLOATING_ATTRIBUTES, OverlayType.UML_ATTRIBUTES, OverlayType.UML_CLASS_ATTRIBUTES].includes(
            overlay.overlayType,
        )
            ? new MxHTMLOverlayShape(overlay)
            : new MxImageShape(new MxRectangle(), overlay.image.src);
    }

    /**
     * Для MxHTMLOverlayShape возвращает высоту всех частей
     * @param shape
     */
    public getRenderedHeight(shape: MxImageShape): number {
        return shape instanceof MxHTMLOverlayShape ? shape.getActualPartsHeight() : 0;
    }

    /**
     * Удаляет отображение атрибутов у учейки по выбранным значениям параметров
     * @param cell
     * @param propName
     * @param propValue
     */
    private removeCellOverlaysBy<T extends keyof BPMMxCellOverlay>(
        cell: MxCell,
        propName: T,
        propValue: BPMMxCellOverlay[T],
    ): void {
        const overlays = this.getCellOverlays(cell);
        if (overlays && overlays.length > 0) {
            overlays
                .filter((overlay) => overlay[propName] === propValue)
                .forEach((overlay) => this.graph.removeCellOverlay(cell, overlay));
        }
    }

    /**
     * Добавляет иконку декомпозиции к ячейке на графе
     * @param cell
     * @param optionalParams
     */
    private addDecompositionIconOverlay(cell: MxCell, optionalParams: TAddDecompositionIconProps): void {
        const { objectDefinitionId, edgeDefinitionId } = optionalParams;
        const haveDecompositionIcon = cell.overlays?.some(
            (overlay) => overlay.overlayType === OverlayType.DECOMPOSITION,
        );

        if (haveDecompositionIcon) {
            return;
        }

        let overlay: BPMMxDecompositionCellOverlay | undefined;

        if (objectDefinitionId) {
            overlay = new BPMMxDecompositionCellOverlay(
                new MxImage(DECOMPOSITION_ICON, 16, 16),
                DECOMPOSTION_ICON_TOOLTIP,
                MxConstants.ALIGN_LEFT,
                MxConstants.ALIGN_TOP,
                new MxPoint(15, -8),
                'hand',
            );
        }
        if (edgeDefinitionId) {
            overlay = new BPMMxDecompositionCellOverlay(
                new MxImage(DECOMPOSITION_ICON, 16, 16),
                DECOMPOSTION_ICON_TOOLTIP,
                MxConstants.ALIGN_LEFT,
                MxConstants.ALIGN_BOTTOM,
                new MxPoint(0, 0),
                'hand',
            );
        }
        if (overlay) {
            overlay.addListener(
                MxEvent.CLICK,
                MxUtils.bind(null, () => {
                    this.graph.fireEvent(
                        new MxEventObject(
                            CLICK_DECOMPOSITION_ICON_EVENT,
                            'cellId',
                            cell.id,
                            'objectDefinitionId',
                            objectDefinitionId,
                            'graphId',
                            this.graph.id,
                            'edgeDefinitionId',
                            edgeDefinitionId,
                        ),
                    );
                }),
            );

            this.addCellOverlay(cell, overlay);
        }
    }

    /**
     * Добавляет иконку удаленного определения к ячейке на графе
     * @param cell
     * @param optionalParams
     */
    private addDeletedDefinitionIconOverlay(cell: MxCell): void {
        const haveDeletedDefinitionIcon = cell.overlays?.some(
            (overlay) => overlay.overlayType === OverlayType.DELETED_DEFINITION,
        );

        if (haveDeletedDefinitionIcon) {
            return;
        }

        let overlay: MxCellOverlayClass | undefined;

        if (cell.value?.objectDefinitionId) {
            overlay = new MxCellOverlayClass(
                new MxImage(DELETED_DEFINITION_ICON, 16, 16),
                DELETED_DEFINITION_ICON_TOOLTIP,
                MxConstants.ALIGN_LEFT,
                MxConstants.ALIGN_TOP,
                new MxPoint(40, -8),
                'auto',
            );
        }
        if (cell.value?.edgeDefinitionId) {
            overlay = new MxCellOverlayClass(
                new MxImage(DELETED_DEFINITION_ICON, 16, 16),
                DELETED_DEFINITION_ICON_TOOLTIP,
                MxConstants.ALIGN_LEFT,
                MxConstants.ALIGN_BOTTOM,
                new MxPoint(0, 0),
                'auto',
            );
        }

        if (overlay) {
            this.addCellOverlay(cell, overlay);
        }
    }

    /**
     * Обновляет стили отображения атрибутов (цвет, положение и тп) на графе
     * @param overlays
     * @param cell
     * @param currentLocale
     * @param attributeTypes
     * @param presetImages
     * @param optionalParams
     */
    private updateOverlays(
        overlays: Map<string, TAttributeOverlay[]>,
        cell: MxCell,
        currentLocale: Locale,
        attributeTypes: AttributeType[],
        presetImages: PresetImage[],
        optionalParams?: {
            userProfile?: TCurrentUserProfile;
            principals?: PrincipalDescriptor[];
            edgeDefinitionId?: string;
            objectDefinitionId?: string;
            isDefinitionDeleted?: boolean;
        },
    ) {
        const { userProfile, principals, edgeDefinitionId, objectDefinitionId, isDefinitionDeleted } =
            optionalParams || {};

        this.removeCellOverlaysBy(cell, 'overlayType', OverlayType.FLOATING_ATTRIBUTES);
        this.removeCellOverlaysBy(cell, 'overlayType', OverlayType.UML_ATTRIBUTES);
        this.removeCellOverlaysBy(cell, 'overlayType', OverlayType.UML_CLASS_ATTRIBUTES);
        this.removeCellOverlaysBy(cell, 'tooltip', DECOMPOSTION_ICON_TOOLTIP);
        this.removeCellOverlaysBy(cell, 'tooltip', DELETED_DEFINITION_ICON_TOOLTIP);

        overlays.forEach((value, key) => {
            const aligns = BPMMxCellOverlay.unkey(key);
            const align = { horizontal: aligns[0], vertical: aligns[1] };
            const parts: IOverlayParts = [];
            const partsForUml: IOverlayParts = [];
            const partsForUmlClass: IOverlayParts = [];

            for (const overlay of value) {
                const { attributeValue, style, imageId, format, hasNotValue, replacementText, attributeWrap } = overlay;
                const accessToAttributeGranted = ProfileBllService.isAttributeViewable(
                    userProfile,
                    attributeValue.typeId,
                );
                const customText = LocalesService.internationalStringToString(replacementText, currentLocale);
                const text = accessToAttributeGranted
                    ? customText ||
                      storageValueToString(attributeValue, currentLocale, {
                          system: false,
                          attributeTypes,
                          principals,
                      }) ||
                      hasNotValue
                    : '';

                if (format === 'TEXT' && text) {
                    const textPart: TTextOverlayPart = {
                        text,
                        attributeValue,
                        style: BPMMxCellOverlay.parseFontStyle(style),
                        attributeWrap,
                    };

                    if (isUmlAttribute(attributeValue.typeId)) {
                        partsForUml.push(textPart as IOverlayPart);
                    } else if (isUmlClassAttribute(attributeValue.typeId)) {
                        partsForUmlClass.push({ ...textPart, attributeWrap: true } as IOverlayPart);
                    } else {
                        parts.push(textPart as IOverlayPart);
                    }
                } else if (format === 'IMAGE') {
                    const image = accessToAttributeGranted
                        ? BPMMxCellOverlay.getImageForOverlay(imageId, presetImages)
                        : null;

                    if (image) {
                        const imagePart: TImageOverlayPart = {
                            image,
                            attributeValue,
                            style: BPMMxCellOverlay.parseImageStyle(style),
                            attributeWrap,
                        };
                        parts.push(imagePart as IOverlayPart);
                    }
                }
            }

            if (parts.length) {
                const overlay = new BPMMxCellOverlay({
                    cursor: '',
                    overlayType: OverlayType.FLOATING_ATTRIBUTES,
                    tooltip: '',
                    offset: new MxPoint(5, 5),
                    verticalAlign: align.vertical as TBaseline,
                    align: align.horizontal as TAlign,
                    zIndex: 0,
                    parts,
                });

                overlay.addListener('click', overlayOnPartClickHandler(this.graph));
                this.addCellOverlay(cell, overlay);
            }

            if (partsForUml.length) {
                const umlOverlay = new BPMMxCellOverlay({
                    cursor: '',
                    overlayType: OverlayType.UML_ATTRIBUTES,
                    tooltip: '',
                    offset: new MxPoint(0, 0),
                    verticalAlign: align.vertical as TBaseline,
                    align: align.horizontal as TAlign,
                    zIndex: 0,
                    parts: partsForUml,
                });

                this.addCellOverlay(cell, umlOverlay);
            }

            if (partsForUmlClass.length) {
                const umlClassOverlay = new BPMMxCellOverlay({
                    cursor: '',
                    overlayType: OverlayType.UML_CLASS_ATTRIBUTES,
                    tooltip: '',
                    offset: new MxPoint(0, 0),
                    verticalAlign: align.vertical as TBaseline,
                    align: align.horizontal as TAlign,
                    zIndex: 0,
                    parts: partsForUmlClass,
                });
                this.addCellOverlay(cell, umlClassOverlay);
            }
        });

        this.addDecompositionIconOverlay(cell, { edgeDefinitionId, objectDefinitionId });
        if (isDefinitionDeleted) {
            this.addDeletedDefinitionIconOverlay(cell);
        }
    }

    /**
     * Обновляет связи на графе с новыми стилями атрибутов
     * @param cell
     * @param edgeAttrTypes
     * @param instanceAttrTypes
     * @param edgeAttrValues
     * @param instanceAttrValues
     * @param presetImages
     * @param modelAssignments
     * @param optionalParams
     */
    public updateEdgeAttributesOverlays = (
        cell: MxCell,
        edgeAttrTypes: AttributeType[],
        instanceAttrTypes: AttributeType[],
        edgeAttrValues: AttributeValue[],
        instanceAttrValues: AttributeValue[],
        definition: Node | undefined,
        model: Node | undefined,
        presetImages: PresetImage[],
        modelAssignments: ModelAssignment[],
        optionalParams?: {
            userProfile?: TCurrentUserProfile;
            principals?: PrincipalDescriptor[];
        },
    ) => {
        const { userProfile, principals } = optionalParams || {};
        const currentLocale = LocalesService.getLocale();
        const diagramElement = cell.getValue() as EdgeInstance;
        const userModelTypeAttributeTypes: AttributeType[] = this.graph.modelType?.attributes || [];
        const userModelAttributeValues: AttributeValue[] = model?.attributes || [];

        const attributeTypes: AttributeType[] = unionBy(edgeAttrTypes, instanceAttrTypes, 'id');
        let offset = 0;
        const overlays = new Map<string, TAttributeOverlay[]>();
        let currentAttributeTypes: AttributeType[];
        let currentAttributesValues: AttributeValue[];
        const discriminators: TAttributeDiscriminator[] = [
            'AUTO',
            'SYSTEM',
            'DEFINITION',
            'INSTANCE',
            'MODEL_SYSTEM',
            'MODEL',
        ];

        discriminators.forEach((discriminator) => {
            switch (discriminator) {
                case 'AUTO':
                    const attributesValues = unionBy(instanceAttrValues, edgeAttrValues, 'typeId');
                    currentAttributeTypes = attributeTypes;
                    currentAttributesValues = attributesValues;
                    break;
                case 'SYSTEM':
                    const systemAttributeValues = definition
                        ? mapNodeToSystemAttributeValues(definition, this.graph.modelType, false)
                        : mapEdgeInstanceToSystemAttributeValues(diagramElement, this.graph.modelType);
                    currentAttributeTypes = systemAttributeTypes;
                    currentAttributesValues = systemAttributeValues;
                    break;
                case 'DEFINITION':
                    currentAttributeTypes = edgeAttrTypes;
                    currentAttributesValues = edgeAttrValues;

                    break;
                case 'INSTANCE':
                    currentAttributeTypes = instanceAttrTypes;
                    currentAttributesValues = instanceAttrValues;

                    break;
                case 'MODEL_SYSTEM':
                    const modelSystemAttributeValues = mapNodeToSystemAttributeValues(
                        model,
                        this.graph.modelType,
                        true,
                    );
                    currentAttributeTypes = modelSystemAttributeTypes;
                    currentAttributesValues = modelSystemAttributeValues;
                    break;
                case 'MODEL':
                    currentAttributeTypes = userModelTypeAttributeTypes;
                    currentAttributesValues = userModelAttributeValues;
                    break;
            }
            offset += setEdgeOverlays(
                overlays,
                currentAttributeTypes,
                currentAttributesValues,
                diagramElement,
                discriminator,
                currentLocale,
                offset,
                cell,
                this.graph,
            );
        });

        // изменяем даже если overlays.size === 0, возможно, пользователь удалил все атрибуты
        this.updateOverlays(overlays, cell, currentLocale, attributeTypes, presetImages, {
            userProfile,
            principals,
            edgeDefinitionId: modelAssignments?.length ? diagramElement.edgeDefinitionId || '' : undefined,
            isDefinitionDeleted: !!definition?.deleted,
        });
    };

    /**
     * Обновляет объекты на графе с новыми стилями атрибутов
     * @param cell
     * @param objectAttrTypes
     * @param instanceAttrTypes
     * @param objectAttrTypes
     * @param instanceAttrValues
     * @param presetImages
     * @param modelAssignments
     * @param optionalParams
     */
    public updateObjectOverlays(
        cell: MxCell,
        objectAttrTypes: AttributeType[],
        instanceAttrTypes: AttributeType[],
        objectAttrValues: AttributeValue[],
        instanceAttrValues: AttributeValue[],
        definition: Node | undefined,
        model: Node | undefined,
        presetImages: PresetImage[],
        modelAssignments: ModelAssignment[],
        optionalParams?: {
            userProfile?: TCurrentUserProfile;
            principals?: PrincipalDescriptor[];
        },
    ) {
        if (!cell) return;

        const { userProfile, principals } = optionalParams || {};
        const currentLocale: Locale = LocalesService.getLocale();
        const diagramElement: ObjectInstance = cell.getValue() as ObjectInstance;
        const userModelTypeAttributeTypes: AttributeType[] = this.graph.modelType?.attributes || [];
        const userModelAttributeValues: AttributeValue[] = model?.attributes || [];

        const attributeTypes: AttributeType[] = unionBy(objectAttrTypes, instanceAttrTypes, 'id');
        const overlays = new Map<string, TAttributeOverlay[]>();
        const discriminators: TAttributeDiscriminator[] = [
            'AUTO',
            'SYSTEM',
            'DEFINITION',
            'INSTANCE',
            'MODEL_SYSTEM',
            'MODEL',
        ];
        let currentAttributeTypes: AttributeType[];
        let currentAttributesValues: AttributeValue[];

        discriminators.forEach((discriminator) => {
            switch (discriminator) {
                case 'AUTO':
                    const attributesValues: AttributeValue[] = unionBy(instanceAttrValues, objectAttrValues, 'typeId');
                    currentAttributeTypes = attributeTypes;
                    currentAttributesValues = attributesValues;

                    break;
                case 'SYSTEM':
                    const systemAttributeValues = mapNodeToSystemAttributeValues(definition, this.graph.modelType);
                    currentAttributeTypes = systemAttributeTypes;
                    currentAttributesValues = systemAttributeValues;

                    break;
                case 'DEFINITION':
                    currentAttributeTypes = objectAttrTypes;
                    currentAttributesValues = objectAttrValues;

                    break;
                case 'INSTANCE':
                    currentAttributeTypes = instanceAttrTypes;
                    currentAttributesValues = instanceAttrValues;

                    break;
                case 'MODEL_SYSTEM':
                    const modelSystemAttributeValues = mapNodeToSystemAttributeValues(
                        model,
                        this.graph.modelType,
                        true,
                    );
                    currentAttributeTypes = modelSystemAttributeTypes;
                    currentAttributesValues = modelSystemAttributeValues;

                    break;
                case 'MODEL':
                    currentAttributeTypes = userModelTypeAttributeTypes;
                    currentAttributesValues = userModelAttributeValues;
                    break;
            }

            setOverlays(
                overlays,
                currentAttributeTypes,
                currentAttributesValues,
                diagramElement,
                discriminator,
                currentLocale,
                this.graph,
            );
        });

        // изменяем даже если overlays.size === 0, возможно, пользователь удалил все атрибуты
        this.updateOverlays(overlays, cell, currentLocale, attributeTypes, presetImages, {
            userProfile,
            principals,
            objectDefinitionId: modelAssignments?.length ? diagramElement.objectDefinitionId || '' : undefined,
            isDefinitionDeleted: !!definition?.deleted,
        });
    }
}

const setOverlays = (
    overlays: Map<string, TAttributeOverlay[]>,
    attributeTypes: AttributeType[],
    attributesValues: AttributeValue[],
    diagramElement: ObjectInstance,
    discriminator: TAttributeDiscriminator,
    currentLocale: Locale,
    graph?: BPMMxGraph,
) => {
    attributeTypes.forEach((attributeType) => {
        const attributeValue: AttributeValue | undefined = attributesValues.find(
            (value) => value.typeId === attributeType.id,
        );
        const attributeStyles: AttributeTypeStyle[] = findStyleByAttributeTypeAndCellType(
            diagramElement,
            attributeType,
            graph,
            discriminator,
        );
        attributeStyles.forEach((attributeStyle) => {
            if (!attributeStyle || !attributeValue) return;

            const overlay: OverlayStyle = createOverlayFormat(
                attributeStyle,
                attributeValue,
                attributeTypes,
                currentLocale,
            );

            if (attributeStyle && attributeValue && overlay.isVisible()) {
                setOverlay(overlays, overlay.getConfig());
            }
        });
    });
};

const setEdgeOverlays = (
    overlays: Map<string, TAttributeOverlay[]>,
    attributeTypes: AttributeType[],
    attributesValues: AttributeValue[],
    diagramElement: EdgeInstance,
    discriminator: TAttributeDiscriminator,
    currentLocale: Locale,
    offset: number,
    cell?: MxCell,
    graph?: BPMMxGraph,
) => {
    attributeTypes?.forEach((attributeType) => {
        const attributeValue = attributesValues.find((value) => value.typeId === attributeType.id);
        const attributeStyle: AttributeTypeStyle = findStyleByAttributeTypeAndCellType(
            diagramElement,
            attributeType,
            graph,
            discriminator,
            cell,
        )[0];

        if (!attributeStyle || !attributeValue) return;

        const overlay = createOverlayFormat(attributeStyle, attributeValue, attributeTypes, currentLocale);

        if (overlay.isVisible()) {
            attributeStyle.y = offset * 20; // временный костыль пока атрибутам связи нельзя задать положение

            setOverlay(overlays, overlay.getConfig());

            if (!isUmlAttribute(attributeValue.typeId)) offset++;
        }
    });

    return offset;
};
