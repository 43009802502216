import { defineMessages } from 'react-intl';

export default defineMessages({
    pieChart: {
        id: 'DashboardSymbols.pieChart',
        defaultMessage: 'Круговая диаграмма',
    },
    lineChart: {
        id: 'DashboardSymbols.lineChart',
        defaultMessage: 'Линейный график',
    },
    barChart: {
        id: 'DashboardSymbols.barChart',
        defaultMessage: 'Столбчатая диаграмма',
    },
    summary: {
        id: 'DashboardSymbols.summary',
        defaultMessage: 'Сводка',
    },
    textBlock: {
        id: 'DashboardSymbols.textBlock',
        defaultMessage: 'Текстовый блок',
    },
});
