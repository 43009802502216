import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import icCut from '../../../../../../resources/icons/cut.svg';
import { cutAction } from '../../../../../../actions/editor.actions';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton.component';

interface ICutButtonProps {
    disabled: boolean | undefined;
    tooltipTitle: string;
}

export const CutButton: FC<ICutButtonProps> = (props) => {
    const { disabled, tooltipTitle } = props;
    const dispatch = useDispatch();

    const onCutAction = () => {
        dispatch(cutAction());
    };

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icCut}
            tooltipTitle={tooltipTitle}
            dataTest="general-toolbar_cut-btn"
            onClick={onCutAction}
        />
    );
};
