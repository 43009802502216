import { processError } from '../actions/error.actions';
import { setSessionSearchData, userSessionLogout } from '../actions/sessions.actions';
import {
    TUserSessionLogoutPayload,
    TUserSessionLogoutAction,
    TUserSessionEndAction,
} from '../actions/sessions.actions.types';
import { GET_SESSION_DATA, USER_SESSION_LOGOUT, USER_SESSION_END } from '../actionsTypes/sessions.actionTypes';
import { TServerEntity } from '../models/entities.types';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { SessionSelectors } from '../selectors/sessions.selector';
import { TParams } from '../selectors/sessions.selector.types';
import { TWorkspaceTab } from '../models/tab.types';
import { SessionHistory } from '../serverapi/api';

export function* handleSessionSearchDataRequest() {
    const tab: TWorkspaceTab | undefined = yield select(TabsSelectors.getActiveTab);

    if (!tab) return;

    const { serverId } = tab.nodeId;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    const params: TParams = yield select(SessionSelectors.params);

    try {
        const response: Promise<SessionHistory[]> = yield call(() => server.api.session.getUsersSessionsHistory(params));

        yield put(setSessionSearchData(Object.values(response)));
    } catch (e) {
        yield put(processError(e, serverId));
    }
}

export function* handleEndSessionRequest(action: TUserSessionLogoutAction) {
    const { userId }: TUserSessionLogoutPayload = action.payload;
    const tab: TWorkspaceTab | undefined = yield select(TabsSelectors.getActiveTab);

    if (!tab) return;

    const { serverId } = tab.nodeId;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    try {
        yield call(() => server.api.session.userLogout({ userId }));
    } catch (e) {
        yield put(processError(e, serverId));
    }
}

export function* handleEndSessionByUser(action: TUserSessionEndAction) {
    const { userId } = action.payload;
    yield handleEndSessionRequest(userSessionLogout(userId));
    yield handleSessionSearchDataRequest();
}

export function* sessionSaga() {
    yield takeEvery(GET_SESSION_DATA, handleSessionSearchDataRequest);
    yield takeEvery(USER_SESSION_LOGOUT, handleEndSessionRequest);
    yield takeEvery(USER_SESSION_END, handleEndSessionByUser);
}
