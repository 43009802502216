import { editServerSuccess } from '../actions/entities/servers.actions';
import { TOpenAuthServerDialogAction, TServerEditAction } from '../actions/entities/servers.actions.types';
import {
    SERVER_DELETE,
    SERVER_EDIT,
    SERVER_ADD_BATCH,
    SERVER_EDIT_SUCCESS,
    OPEN_SERVER_AUTH_DIALOG,
    CLEAR_SERVERS_SESSION,
} from '../actionsTypes/entities/servers.actionTypes';
import { all, call, CallEffect, put, select, takeEvery } from 'redux-saga/effects';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';

function* openServerAuthDialog({ payload: { serverId } }: TOpenAuthServerDialogAction) {
    const connected: string[] = yield select(ServerSelectors.connected);
    const isConnected = connected.find((id) => serverId === id);
    if (!isConnected) {
        yield put(openDialog(DialogType.AUTHORIZATION, { serverId }));
    }
}

function* updateStoredServers() {
    const servers: TServerEntity[] = yield select(ServerSelectors.serverList);
    LocalStorageDaoService.setServers(servers);
}

function* handleServerEditServer({ payload: { entity: serverForm } }: TServerEditAction) {
    const { url, id, serverName } = serverForm;
    const connected: string[] = yield select(ServerSelectors.connected);
    const isConnected = connected.find((serverId) => serverId === id);
    if (isConnected) {
        return;
    }
    const entity = {
        id,
        name: serverName,
        url,
    };
    yield put(editServerSuccess(entity));
    yield put(closeDialog(DialogType.SERVER_EDIT));
}

function* handleClearServersSession() {
    const effects: CallEffect<void>[] = [];
    const connectedServers: TServerEntity[] = yield select(ServerSelectors.connectedServers);
    connectedServers.forEach((server: TServerEntity) => {
        const effect = call(() => server?.api.session.logout());
        effects.push(effect);
    });
    yield all(effects);
}

export function* serverListSaga() {
    yield takeEvery(SERVER_ADD_BATCH, updateStoredServers);
    yield takeEvery(SERVER_DELETE, updateStoredServers);
    yield takeEvery(SERVER_EDIT_SUCCESS, updateStoredServers);
    yield takeEvery(SERVER_EDIT, handleServerEditServer);
    yield takeEvery(OPEN_SERVER_AUTH_DIALOG, openServerAuthDialog);
    yield takeEvery(CLEAR_SERVERS_SESSION, handleClearServersSession);
}
