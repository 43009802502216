import { defineMessages } from 'react-intl';

export default defineMessages({
    close: {
        id: 'ModelHistoryDialog.close',
        defaultMessage: 'Закрыть',
    },
    restore: {
        id: 'ModelHistoryDialog.restore',
        defaultMessage: 'Восстановить',
    },
    titleMessage: {
        id: 'ModelHistoryDialog.history',
        defaultMessage: 'История изменений',
    },
    todayTimePrefix: {
        id: 'ModelHistoryDialog.todayTimePrefix',
        defaultMessage: 'Сегодня',
    },
    yesterdayTimePrefix: {
        id: 'ModelHistoryDialog.yesterdayTimePrefix',
        defaultMessage: 'Вчера',
    },
    openImage: {
        id: 'ModelHistoryDialog.openImage',
        defaultMessage: 'Нажмите на изображение чтобы открыть в новой вкладке',
    },
    imgNotFound: {
        id: 'ModelHistoryDialog.imgNotFound',
        defaultMessage: 'Изображение отсутствует',
    },
});
