import { VisioPageAdapter } from './VisioPage.adapter';
import {
    VisioImportRequest,
    VisioTransferMap,
    VisioImportRequestConflictResolutionStrategyEnum,
    VisioConflictResolution,
    NodeId,
} from '../../serverapi/api';

export class ResponseVisioFileAdapter implements VisioImportRequest {
    transferMap: VisioTransferMap;
    conflictResolutionStrategy: VisioImportRequestConflictResolutionStrategyEnum;
    resolutionById?: { [key: string]: VisioConflictResolution };

    constructor(
        file: VisioPageAdapter[],
        nodeId: NodeId,
        conflictResolutionStrategy: VisioImportRequestConflictResolutionStrategyEnum,
        resolutionById: { [key: string]: VisioConflictResolution },
    ) {
        this.transferMap = {
            fileToModelMaps: {
                [file[0].fileUUID || 'noName']: file.map(
                    (page) => new VisioPageAdapter(page, page.fileName || '', page.fileUUID || '', nodeId),
                ),
            },
            repositoryId: nodeId.repositoryId,
        };
        this.conflictResolutionStrategy = conflictResolutionStrategy;
        this.resolutionById = resolutionById;
    }
}
