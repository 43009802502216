import { ISearchRuleWithValueId } from '@/modules/Search/AttributeFilter/AttributeFilter.types';
import {
    GET_SEARCH_RESULT,
    OPEN_MODEL_ON_CANVAS,
    OPEN_SEARCH_IN_DIALOG,
    OPEN_SEARCH_PATH_DIALOG,
    RESET_SEARCH_DATA,
    SET_ROOT_SEARCH_NODE_ID,
    SET_SEARCH_DATA,
    SET_SEARCH_NODE_TYPES,
    SET_SEARCH_PATH_ELEMENT,
    SET_SEARCH_RULES,
    SET_SEARCH_TEXT,
    SET_SEARCH_VISIBILITY,
} from '../actionsTypes/search.actionTypes';
import { NodeId, SearchRequest, SearchRequestNodeTypesEnum, SearchRequestSearchVisibilityEnum } from '../serverapi/api';
import {
    TGetSearchResultAction,
    TOpenModelOnCanvasAction,
    TOpenModelOnCanvasPayload,
    TOpenSearchInDialogAction,
    TOpenSearchPathDialogAction,
    TResetSearchDataAction,
    TSearchDataAction,
    TSearchDataPayload,
    TSetRootSearchNodeIdAction,
    TSetSearchNodeTypesAction,
    TSetSearchPathElementAction,
    TSetSearchRulesAction,
    TSetSearchTextAction,
    TSetSearchVisibilityAction,
} from './search.actions.types';

export const openModelOnCanvas = (payload: TOpenModelOnCanvasPayload): TOpenModelOnCanvasAction => ({
    type: OPEN_MODEL_ON_CANVAS,
    payload,
});

export const setSearchData = (payload: TSearchDataPayload): TSearchDataAction => ({
    type: SET_SEARCH_DATA,
    payload,
});

export const resetSearchData = (nodeId: NodeId): TResetSearchDataAction => ({
    type: RESET_SEARCH_DATA,
    payload: { nodeId },
});

export const openSearchPathDialog = (): TOpenSearchPathDialogAction => ({
    type: OPEN_SEARCH_PATH_DIALOG,
});

export const setSearchPathElement = (nodeId: NodeId, type: string, name: string): TSetSearchPathElementAction => ({
    type: SET_SEARCH_PATH_ELEMENT,
    payload: {
        nodeId,
        type,
        name,
    },
});

export const getSearchResult = (
    searchText: string,
    searchRules: ISearchRuleWithValueId[],
    searchVisibility: SearchRequestSearchVisibilityEnum,
    searchNodeTypes: SearchRequestNodeTypesEnum[],
    rootSearchNodeId: NodeId,
): TGetSearchResultAction => ({
    type: GET_SEARCH_RESULT,
    payload: { searchText, searchRules, searchVisibility, searchNodeTypes, rootSearchNodeId },
});

export const setSearchRules = (nodeId: NodeId, searchRules: ISearchRuleWithValueId[]): TSetSearchRulesAction => ({
    type: SET_SEARCH_RULES,
    payload: {
        nodeId,
        searchRules,
    },
});

export const setSearchNodeTypes = (
    nodeId: NodeId,
    searchNodeTypes: SearchRequestNodeTypesEnum[],
): TSetSearchNodeTypesAction => ({
    type: SET_SEARCH_NODE_TYPES,
    payload: {
        nodeId,
        searchNodeTypes,
    },
});

export const setSearchText = (nodeId: NodeId, searchText: string): TSetSearchTextAction => ({
    type: SET_SEARCH_TEXT,
    payload: {
        nodeId,
        searchText,
    },
});

export const setSearchVisibility = (
    nodeId: NodeId,
    visibility: SearchRequestSearchVisibilityEnum,
): TSetSearchVisibilityAction => ({
    type: SET_SEARCH_VISIBILITY,
    payload: {
        nodeId,
        visibility,
    },
});

export const setRootSearchNodeId = (nodeId: NodeId, rootSearchNodeId: NodeId): TSetRootSearchNodeIdAction => ({
    type: SET_ROOT_SEARCH_NODE_ID,
    payload: {
        nodeId,
        rootSearchNodeId,
    },
});

export const openSearchInDialog = (nodeId: NodeId, searchRequests: SearchRequest[]): TOpenSearchInDialogAction => ({
    type: OPEN_SEARCH_IN_DIALOG,
    payload: {
        nodeId,
        searchRequests,
    },
});
