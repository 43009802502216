import { CharacterMetadata, ContentBlock, ContentState } from 'draft-js';
import { ContentToken, StateInline } from 'remarkable/lib';
import { getImageRenderer } from '../../renderers/Image/ImageRenderer.component';

const findImageEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
) => {
    try {
        contentBlock.findEntityRanges((character: CharacterMetadata) => {
            const entityKey = character.getEntity();

            return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
        }, callback);
    } catch (e) {
        console.error(e);
    }
};

function parser(state: StateInline, silent: boolean) {
    const start = state.pos;
    const isOpen = state.src.slice(start, start + 7) === '<IMAGE ';
    const isClose = state.src.slice(start, start + 8) === '</IMAGE>';
    if (!(isOpen || isClose) || silent) {
        return false;
    }

    const tail = state.src.slice(start);

    const openMatch =
        /^<IMAGE src="([a-zA-Z0-9\/\-.]+)"(\ alt="?([^"]*)")?(\ title="?([^"]*)")?(\ width="?([^"]*)")?(\ height="?([^"]*)")?>/g.exec(
            tail,
        );
    const closeMatch = /^<\/IMAGE>/g.exec(tail);
    if (!openMatch && !closeMatch) {
        state.pos = start;
        state.parser.tokenize(state);

        return false;
    }

    if (isOpen && openMatch) {
        if (!silent) {
            const content = <ContentToken>{ type: 'IMAGE_open', level: state.level++ };

            Object.assign(content, {
                data: {
                    src: openMatch[1],
                    alt: openMatch[3],
                    title: openMatch[5],
                    width: openMatch[7],
                    height: openMatch[9],
                },
            });
            state.push(content);
            state.pos += openMatch[0].length;
            state.parser.tokenize(state);
        }
    }

    if (isClose && closeMatch) {
        state.push(<ContentToken>{ type: 'IMAGE_close', level: --state.level });
        state.pos += closeMatch[0].length;
        state.parser.tokenize(state);
    }

    return true;
}

const ImageDecorator = (imageLinkMapper) => ({
    strategy: findImageEntities,
    component: getImageRenderer(imageLinkMapper),
    parser,
});

export { ImageDecorator };
