import { defineMessages } from 'react-intl';

export default defineMessages({
    move: {
        id: 'GroupSelectionDialog.move',
        defaultMessage: 'Перенести',
    },
    cancel: {
        id: 'GroupSelectionDialog.cancel',
        defaultMessage: 'Отмена',
    },
    selectGroupTitle: {
        id: 'GroupSelectionDialog.selectGroupTitle',
        defaultMessage: 'Выберите группу',
    },
    groupSelectionDialog: {
        id: 'GroupSelectionDialog.groupSelectionDialog',
        defaultMessage: 'Выбор группы для переноса',
    },
});
