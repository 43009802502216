import { FETCH_NODES_REQUEST_SUCCESS } from '../actionsTypes/nodes.actionTypes';
import { TReducer } from '../utils/types';
import { TNodeState } from './entities/TNodeState';
import { TNodesState } from './nodes.reducer.types';

const INITIAL_STATE: TNodesState = new TNodeState();

export const nodesReducer: TReducer<TNodesState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NODES_REQUEST_SUCCESS: {
            const { nodes } = action.payload;
            if (!nodes.length) {
                return state;
            }
            let newState: TNodesState = new TNodeState();
            nodes.reduce((acc, cur) => {
                newState = acc.set(cur.nodeId, cur);

                return newState;
            }, state);

            return newState;
        }

        default:
            return state;
    }
};
