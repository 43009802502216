// @test-ignore_ru

import { DROP_TARGET_ATTRIBUTE, FRAME_STYLES_INDICATOR } from '../ComplexSymbol.constants';
import { getGUIDdArray } from '../../ComplexSymbol.utils';

export const frameVersionData = () =>
    JSON.stringify({
        __descriptor_version__: '1',
        format: 'Версия {0} ({1}): {2}',
        params: [
            { paramSource: 'model', attributesIds: ['au_vr_mod_version'], defaultValue: '1.0' },
            { paramSource: 'model', attributesIds: ['au_vr_mod_status'], defaultValue: 'to be' },
            {
                paramSource: 'model',
                attributesIds: ['createdAt'],
                defaultValue: new Date().toLocaleDateString('ru-Ru'),
            },
        ],
    }).replace(/\"/g, "'");

export const frameNameData = () =>
    JSON.stringify({
        __descriptor_version__: '1',
        params: [{ paramSource: 'model', attributesIds: ['name'], defaultValue: 'Оформление документа' }],
    }).replace(/\"/g, "'");

export const getVRFrameDiagramTemplate = () => {
    const u = getGUIDdArray(21);

    return `
  <mxGraphModel>
    <root>
      <mxCell id="${u[0]}" value="" style="${DROP_TARGET_ATTRIBUTE}=0;"/>
      <mxCell id="${u[1]}" value="" style="${DROP_TARGET_ATTRIBUTE}=0;" parent="${u[0]}" />
      <mxCell id="${
          u[2]
      }" value="" complexSymbolTypeId="frame.type.vr" style="${FRAME_STYLES_INDICATOR};shape=table;editable=0;movable=1;html=1;whiteSpace=wrap;startSize=30;collapsible=0;childLayout=tableLayout;strokeWidth=2;" vertex="1" parent="${
        u[1]
    }">
        <mxGeometry x="0" y="0" width="660" height="720" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[3]
      }" value="" style="deletable=0;editable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;collapsible=0;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[2]
    }">
        <mxGeometry y="0" width="660" height="40" as="geometry" />
      </mxCell>
      <mxCell id="${u[4]}" 
        value="${frameNameData()}"
        style="editable=0;deletable=0;movable=0;startSize=40;html=1;whiteSpace=wrap;collapsible=0;strokeWidth=2;recursiveResize=0;expand=0;pointerEvents=1;" vertex="1" parent="${
            u[3]
        }">
        <mxGeometry x="0" y="0" width="660" height="40" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[5]
      }" value="" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=0;strokeColor=none;html=1;whiteSpace=wrap;collapsible=0;overflow=hidden;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[3]
    }">
        <mxGeometry x="608" width="52" height="40" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[6]
      }" value="" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;collapsible=0;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[2]
    }">
        <mxGeometry y="80" width="660" height="40" as="geometry" />
      </mxCell>
      <mxCell id="${u[7]}" frameCellType="dateVR" 
        value="${frameVersionData()}"
        style="editable=0;deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;pointerEvents=1;strokeWidth=2;html=1;whiteSpace=wrap;overflow=hidden;fillColor=none;" vertex="1" parent="${
            u[6]
        }">
        <mxGeometry width="608" height="40" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[8]
      }" value="0" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;overflow=hidden;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[6]
    }">
        <mxGeometry x="608" width="52" height="40" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[9]
      }" value="" style="removalbeFramesLine;deletable=1;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;collapsible=0;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[2]
    }">
        <mxGeometry y="140" width="660" height="116" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[10]
      }" value="" style="deletable=0;movable=0;swimlane;horizontal=0;startSize=40;html=1;whiteSpace=wrap;collapsible=0;strokeWidth=2;recursiveResize=0;expand=0;pointerEvents=1;" vertex="1" parent="${
        u[9]
    }">
        <mxGeometry width="608" height="116" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[11]
      }" value="0" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;overflow=hidden;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[9]
    }">
        <mxGeometry x="608" width="52" height="116" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[12]
      }" value="" style="removalbeFramesLine;deletable=1;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;collapsible=0;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[2]
    }">
        <mxGeometry y="256" width="660" height="160" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[13]
      }" value="1. Создание \\ доработка" style="deletable=0;movable=0;swimlane;startSize=40;horizontal=0;strokeWidth=2;fillColor=none;pointerEvents=1;html=1;collapsible=0;" vertex="1" parent="${
        u[12]
    }">
        <mxGeometry width="608" height="160" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[14]
      }" value="0" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;overflow=hidden;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[12]
    }">
        <mxGeometry x="608" width="52" height="160" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[15]
      }" value="" style="removalbeFramesLine;deletable=1;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;collapsible=0;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[2]
    }">
        <mxGeometry y="416" width="660" height="150" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[16]
      }" value="2. Согласование" style="deletable=0;movable=0;swimlane;startSize=40;horizontal=0;strokeWidth=2;fillColor=none;pointerEvents=1;html=1;collapsible=0;" vertex="1" parent="${
        u[15]
    }">
        <mxGeometry width="608" height="150" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[17]
      }" value="0" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;overflow=hidden;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[15]
    }">
        <mxGeometry x="608" width="52" height="150" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[18]
      }" value="" style="removalbeFramesLine;deletable=1;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;collapsible=0;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[2]
    }">
        <mxGeometry y="566" width="660" height="154" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[19]
      }" value="3. Подписание" style="deletable=0;movable=0;swimlane;startSize=40;horizontal=0;strokeWidth=2;fillColor=none;html=1;pointerEvents=1;collapsible=0;" vertex="1" parent="${
        u[18]
    }">
        <mxGeometry width="608" height="154" as="geometry" />
      </mxCell>
      <mxCell id="${
          u[20]
      }" value="0" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;overflow=hidden;pointerEvents=1;fillColor=none;" vertex="1" parent="${
        u[18]
    }">
        <mxGeometry x="608" width="52" height="154" as="geometry" />
      </mxCell>
    </root>
  </mxGraphModel>`;
};

export const getVRFrameLineTemplate = () => {
    const [u0, u1, u2, u3, u4] = getGUIDdArray(5);

    return `
  <mxGraphModel>
    <root>
      <mxCell id="${u0}" />
      <mxCell id="${u1}" parent="${u0}" />
      <mxCell id="${u2}" value="" style="removalbeFramesLine;deletable=1;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;collapsible=0;pointerEvents=1;fillColor=none;" parent="${u1}" vertex="1">
        <mxGeometry y="566" width="660" height="154" as="geometry" />
      </mxCell>
      <mxCell id="${u3}" value="" style="deletable=0;movable=0;swimlane;startSize=40;horizontal=0;strokeWidth=2;fillColor=none;html=1;collapsible=0;" vertex="1" parent="${u2}">
        <mxGeometry width="608" height="154" as="geometry" />
      </mxCell>
      <mxCell id="${u4}" value="0" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=rectangle;strokeWidth=2;html=1;whiteSpace=wrap;overflow=hidden;fillColor=none;" vertex="1" parent="${u2}">
        <mxGeometry x="608" width="52" height="154" as="geometry" />
      </mxCell>
    </root>
  </mxGraphModel>`;
};
