import { call, put, select, takeEvery } from 'redux-saga/effects';
import { admintoolSelect } from '../actions/admintools.actions';
import { CLOSE_MONITORING_TAB, LOAD_MONITORING_INFO, OPEN_MONITOR_TAB } from '../actionsTypes/monitoring.actionTypes';
import { saveMonitoringStatus, setMonitoringStatusLoading } from '../actions/monitoring.actions';
import { TOpenMonitoringTabAction, TLoadStatusAction } from '../actions/monitoring.actions.types';
import { workspaceAddTab, workspaceRemoveTabRequest } from '../actions/tabs.actions';
import { EditorMode } from '../models/editorMode';
import { TServerEntity } from '../models/entities.types';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTabItemDefaultParams, TWorkspaceTab } from '../models/tab.types';
import { AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { SystemStatus } from '../serverapi/api';
import { TabsSelectors } from '../selectors/tabs.selectors';

function* handleOpenMonitoringTab({ payload: { node } }: TOpenMonitoringTabAction) {
    if (node) {
        const monitoringTab: TWorkspaceTab = {
            title: node.name,
            nodeId: node.nodeId,
            type: WorkSpaceTabTypes.MONITORING_TAB,
            mode: EditorMode.Read,
            params: {
                serverId: node.nodeId.serverId,
            } as TWorkspaceTabItemDefaultParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        yield put(
            admintoolSelect({ currentServerId: node.nodeId.serverId, activeTypeId: AdminToolTreeType.MONITORING_TAB }),
        );
        yield put(workspaceAddTab(monitoringTab));
    }
}

function* handleLoadStatus({ payload: { serverId } }: TLoadStatusAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    try {
        const status: SystemStatus = yield call(() => server.api.systemProperty.check());

        if (status.imgServiceStatus && status.scriptEngineStatus) {
            yield put(saveMonitoringStatus(serverId, status));
        }
    } finally {
        yield put(setMonitoringStatusLoading(serverId, false));
    }
}

function* closeMonitoringTabSaga() {
    const tab = yield select(TabsSelectors.getActiveTab);
    yield put(workspaceRemoveTabRequest(tab));
}

export function* monitoringSaga() {
    yield takeEvery(OPEN_MONITOR_TAB, handleOpenMonitoringTab);
    yield takeEvery(LOAD_MONITORING_INFO, handleLoadStatus);
    yield takeEvery(CLOSE_MONITORING_TAB, closeMonitoringTabSaga);
}
