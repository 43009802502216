import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import icSampleFormat from '../../../../../../resources/icons/sample-format.svg';
import { formatByExampleAction } from '../../../../../../actions/editor.actions';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton.component';

interface IFormatButtonProps {
    disabled: boolean | undefined;
    tooltipTitle: string;
}

export const FormatButton: FC<IFormatButtonProps> = (props) => {
    const { disabled, tooltipTitle } = props;
    const dispatch = useDispatch();

    const onFormatAction = () => {
        dispatch(formatByExampleAction());
    };

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icSampleFormat}
            tooltipTitle={tooltipTitle}
            dataTest="general-toolbar_format-btn"
            onClick={onFormatAction}
        />
    );
};
