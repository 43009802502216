import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { ApiBundle } from '../api/api-bundle';
import { InternationalString, ModelTemplateDTO } from '@/serverapi/api';

export class ModelTemplateDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async createModelTemplate(
        repositoryId: string,
        modelId: string,
        name: InternationalString,
    ): Promise<ModelTemplateDTO> {
        const api = this.getApi();

        const modelTemplate: ModelTemplateDTO = await api.modelTemplate.create({
            repositoryId,
            modelId,
            body: { name },
        });

        return modelTemplate;
    }
}
