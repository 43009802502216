import React, { FC, useState } from 'react';
import { Select } from '../UIKit/components/Select/Select.component';
import { useIntl } from 'react-intl';
import theme from './myApprovalsTab.scss';
import messages from './myApprovalsTab.messages';
import { useSelector } from 'react-redux';
import { ApprovalSelectors } from '../../selectors/approval.selectors';

type TCreatorSelectorProps = {
    creatorFilter: string[];
    setCreatorFilter: (value: string[]) => void;
};

export const CreatorSelector: FC<TCreatorSelectorProps> = ({ creatorFilter, setCreatorFilter }): JSX.Element => {
    const intl = useIntl();
    const allCreators: string[] = useSelector(ApprovalSelectors.getMyApprovalsCreators);
    const [searchValue, setSearchValue] = useState<string>('');

    const handleChange = (clickedCreator: string) => {
        const creators: string[] = creatorFilter.includes(clickedCreator)
            ? creatorFilter.filter((currentCreator) => currentCreator !== clickedCreator)
            : [...creatorFilter, clickedCreator];
        setCreatorFilter(creators);
    };

    const handleSearchInputChange = (value: string) => {
        setSearchValue(value);
    };

    return (
        <div className={theme.selectContainer}>
            <Select
                isMultiSelect
                isControlledMultiSelect
                value={intl.formatMessage(messages.chooseCreater)}
                onChange={handleChange}
                showSearch
                onSearch={handleSearchInputChange}
                searchValue={searchValue}
            >
                {allCreators
                    .filter((creator) => {
                        return creator.toLowerCase().includes(searchValue.toLowerCase().trim());
                    })
                    .map((creator) => (
                        <Select.Option
                            value={creator}
                            label={creator}
                            key={creator}
                            checked={creatorFilter.includes(creator)}
                        />
                    ))}
            </Select>
        </div>
    );
};
