import * as React from 'react';
import { Dropdown, Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import messages from '../../../../Wiki/messages/WikiEditor.messages';
import { useIntl } from 'react-intl';
import theme from './RichTextEditor.component.scss';
import { addComment, getSelectionEntityData } from './decorators/Comment/CommentBlocks.utils';
import { actionPaste, getSelectedText, actionCut, actionDelete } from '../common/actions';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { v4 as uuid } from 'uuid';
import { NodeId } from '@/serverapi/api';
import { EditorState } from 'draft-js';
import { useContextMenuDublicateDelete } from '../../../../../hooks/useContextMenuDublicateDelete';

type TWikiEditorContextMenuComponent = {
    editorState: EditorState;
    changeEditorState: any;
    children: JSX.Element;
    readFromClipboard: (callback: (text: string) => void) => void;
    copyToClipboard: (text: string) => void;
    modelId?: NodeId;
};

const WikiEditorContextMenuComponent: React.FC<TWikiEditorContextMenuComponent> = ({
    editorState,
    changeEditorState,
    children,
    readFromClipboard,
    copyToClipboard,
    modelId,
}) => {
    const intl = useIntl();
    const dispath = useDispatch();
    const [isOpen, setDropdownOpenState] = useContextMenuDublicateDelete('wiki', modelId);
    const renderContextMenu = () => {
        const { formatMessage } = intl;
        const clipboardData = true;
        const selectionState = editorState.getSelection();
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();
        const menuClickHandler = (action) => {
            const { key } = action;
            if (key === 'paste') {
                readFromClipboard((text) => changeEditorState(actionPaste(editorState, text)));
            } else if (key === 'copy') {
                const selectedText = getSelectedText(editorState);
                copyToClipboard(selectedText);
            } else if (key === 'cut') {
                const selectedText = getSelectedText(editorState);
                copyToClipboard(selectedText);
                changeEditorState(actionCut(editorState));
            } else if (key === 'delete') {
                changeEditorState(actionDelete(editorState));
            } else if (key === 'comment') {
                const { threadId: oldThreadId } = getSelectionEntityData(editorState);
                const threadId = oldThreadId || uuid();
                dispath(
                    openDialog(DialogType.WRITE_COMMENT_DIALOG, {
                        modelId,
                        parentId: threadId,
                        threadId,
                        commentId: uuid(),
                        onSubmit: () => changeEditorState(addComment(editorState, threadId)),
                    }),
                );
            }

            setDropdownOpenState(false);
        };

        return (
            <Menu onClick={(param: MenuInfo) => menuClickHandler(param)}>
                <Menu.Item disabled={start === end} key="cut" data-test="wiki_context-menu_cut">
                    {formatMessage(messages.cut)}
                </Menu.Item>
                <Menu.Item disabled={start === end} key="copy" data-test="wiki_context-menu_copy">
                    {formatMessage(messages.copy)}
                </Menu.Item>
                <Menu.Item disabled={!clipboardData} key="paste" data-test="wiki_context-menu_paste">
                    {formatMessage(messages.paste)}
                </Menu.Item>
                <Menu.Item disabled={start === end} key="delete" data-test="wiki_context-menu_delete">
                    {formatMessage(messages.delete)}
                </Menu.Item>
                <Menu.Item key="comment" data-test="wiki_context-menu_comment">
                    {formatMessage(messages.toggleComment)}
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <Dropdown
            destroyPopupOnHide
            trigger={['contextMenu']}
            overlay={renderContextMenu()}
            onOpenChange={setDropdownOpenState}
            open={isOpen}
        >
            <div onClick={(e) => e.stopPropagation()} className={theme.viewerContainer}>
                {children}
            </div>
        </Dropdown>
    );
};

export default WikiEditorContextMenuComponent;
