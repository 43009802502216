import type { MenuInfo } from 'rc-menu/lib/interface';
import React, { FC } from 'react';
import { Menu, Dropdown, Button, Tooltip } from 'antd';
import cx from 'classnames';
import { CaretDownOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import icPaste from '../../../../../../resources/icons/insert.svg';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import theme from './InsertionButton.scss';
import menuItemTheme from '../../../items/MenuItem.scss';
import messages from './InsertionButton.messages';
import { useDispatch } from 'react-redux';
import { pasteAction } from '../../../../../../actions/editor.actions';

interface IInsertionButtonProps {
    tooltipTitle: string;
    isActiveScheme?: boolean;
    isWhiteBoard?: boolean;
    compact?: boolean;
    disabled?: boolean;
}

enum InsertKeys {
    InsertAsInstance = 'InsertAsInstance',
    InsertAsObjectDefinition = 'InsertAsObjectDefinition',
}

export const InsertionButton: FC<IInsertionButtonProps> = (props) => {
    const { compact, disabled, isActiveScheme, isWhiteBoard, tooltipTitle } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const onPasteAction = (insertOption: string) => {
        dispatch(pasteAction(insertOption === InsertKeys.InsertAsObjectDefinition));
    };

    const menu = (
        <Menu onClick={(e: MenuInfo) => onPasteAction(e.key)}>
            <Menu.Item key={InsertKeys.InsertAsInstance}>
                {isActiveScheme ? intl.formatMessage(messages.insertAsInstance) : ''}
            </Menu.Item>
            <Menu.Item key={InsertKeys.InsertAsObjectDefinition} disabled={isWhiteBoard}>
                {isActiveScheme ? intl.formatMessage(messages.insertAsObjectDefinition) : ''}
            </Menu.Item>
        </Menu>
    );
    const btnContainer = compact ? (
        <Dropdown destroyPopupOnHide disabled={disabled} overlay={menu}>
            <Button>
                <Icon spriteSymbol={icPaste} disabled={disabled} />
            </Button>
        </Dropdown>
    ) : (
        <div className={theme.buttonInsert}>
            <Dropdown destroyPopupOnHide disabled={disabled} overlay={menu}>
                <Button disabled={disabled} data-test="general-toolbar_insert-btn">
                    <div>
                        <div className={theme.pasteIcon}>
                            <Icon spriteSymbol={icPaste} disabled={disabled} />
                        </div>
                        <div className={theme.paste}>{intl.formatMessage(messages.insert)}</div>
                        <div className={theme.caretDownIcon}>
                            <CaretDownOutlined />
                        </div>
                    </div>
                </Button>
            </Dropdown>
        </div>
    );

    return (
        <span>
            <Tooltip placement="top" title={tooltipTitle} mouseLeaveDelay={0} overlayStyle={{ maxWidth: '350px' }}>
                <span
                    className={cx(menuItemTheme.buttonWrapper, disabled && menuItemTheme.buttonDisabled)}
                    data-test="general-toolbar_insert-btn"
                >
                    {btnContainer}
                </span>
            </Tooltip>
        </span>
    );
};
