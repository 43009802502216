import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dropdown, MenuProps } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import theme from './MethodologySetting.scss';
import messages from '../../messages/MethodologySetting.messages';
import addIcon from '../../../../../resources/icons/Add_Other.svg';
import importIcon from '../../../../../resources/icons/Import.svg';
import { getMenuItem } from '../../../../../utils/antdMenuItem.utils';
import { getAntdPopupContainer } from '../../../../../utils/getAntdPopupContainer';
import { Icon } from '../../../../UIKit';
import { openAddPreset } from '../../../../../actions/methodologySetting.actions';
import { TreeNode } from '../../../../../models/tree.types';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { PRESET_IMPORT_EXTENSIONS } from '../../../../FileUpload/types/FileUploadDialog.constants';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

enum MethodologySettingMenuActions {
    addPreset = 'addPreset',
    importPreset = 'importPreset',
}

type TMethodologySettingHeaderButtonProps = {
    serverNode: TreeNode;
};

export const MethodologySettingHeaderButton = (props: TMethodologySettingHeaderButtonProps) => {
    const { serverNode } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const onImport = () =>
        dispatch(
            openDialog(DialogType.UPLOAD_PRESET_DIALOG, {
                serverNode,
                filters: PRESET_IMPORT_EXTENSIONS,
            }),
        );

    const addPreset = () => dispatch(openAddPreset(serverNode));

    const onClick = (param: MenuInfo) => {
        switch (param.key) {
            case MethodologySettingMenuActions.addPreset:
                addPreset();
                toggleOpen();
                break;
            case MethodologySettingMenuActions.importPreset:
                onImport();
                toggleOpen();
                break;
            default:
                break;
        }
    };

    const items: MenuProps['items'] = [
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.addPreset)}</div>,
            MethodologySettingMenuActions.addPreset,
            false,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={addIcon} />
            </div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.importPreset)}</div>,
            MethodologySettingMenuActions.importPreset,
            false,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={importIcon} />
            </div>,
        ),
    ];

    return (
        <Dropdown
            onOpenChange={toggleOpen}
            open={open}
            menu={{ onClick, items }}
            trigger={['click']}
            getPopupContainer={getAntdPopupContainer}
            destroyPopupOnHide
            overlayClassName={theme.dropdown}
            placement="bottomRight"
        >
            <Button>{intl.formatMessage(messages.addMethodology)}</Button>
        </Dropdown>
    );
};
