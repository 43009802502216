import { useRef } from 'react';

export const useRecentData = (key: string): [string[], (newData: string) => void] => {
    const recentData = useRef<string[]>(JSON.parse(localStorage.getItem(key) || '[]'));

    const updateRecentData = (newData: string) => {
        if (newData && recentData.current) {
            // убираеми повторяющиеся элементы
            recentData.current = [...new Set(recentData.current)];

            // если записываем элемент, который уже есть, прошлый нежно удалить
            const index = recentData.current.findIndex((data) => data === newData);
            if (index !== -1) recentData.current.splice(index, 1);

            // помещаем новый элемент в начало массива
            recentData.current.unshift(newData);

            // ограничиваем количество запоминаемых элементов до 10
            recentData.current.splice(10);
            localStorage.setItem(key, JSON.stringify(recentData.current));
        }
    };

    return [recentData.current, updateRecentData];
};
