// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterButtons__generalButtons__vUusP{background-color:rgba(17,255,238,0);text-align:right;padding:10px;white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/modules/Footer/FooterButtons.scss"],"names":[],"mappings":"AAAA,sCACI,mCAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".generalButtons {\r\n    background-color: #11ffee00;\r\n    text-align: right;\r\n    padding: 10px;\r\n    white-space: nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalButtons": "FooterButtons__generalButtons__vUusP"
};
export default ___CSS_LOADER_EXPORT___;
