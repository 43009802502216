import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Column, Table } from 'react-virtualized';
import messages from '../KanbanModelType.messages';
import theme from '../../ModelType/Dialogs/SymbolsTabDialog.scss';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { toggleArraySymbol } from '../../../../../../../utils/toggleArraySymbol';
import { KanbanCardType, Symbol } from '../../../../../../../serverapi/api';
import icKanbanCard from '../../../../../../../resources/icons/ic-kanban-card.svg';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

const WIDTH_TABLE_CONTAINER = 432;
const HEIGHT_TABLE_CONTAINER = 430;
const HEADER_HEIGHT = 35;
const ROW_HEIGHT = 80;

type TCardsTabDialogIntlProps = {
    availableCards: KanbanCardType[];
    modelTypeCards: KanbanCardType[];
};

type TCardsTabDialogIntlActionProps = {
    onCancel: () => void;
    onAddCards: (cards: KanbanCardType[]) => void;
};

type TCardsTabDialogIntlFullProps = WrappedComponentProps & TCardsTabDialogIntlProps & TCardsTabDialogIntlActionProps;

type TWidthsTableParts = {
    checkbox: number;
    name: number;
    symbol: number;
};

type TCardsTabDialogState = {
    newCards: KanbanCardType[];
    cardFilter?: string;
    widthsTableParts: TWidthsTableParts;
};

class CardsTabDialog extends Component<TCardsTabDialogIntlFullProps, TCardsTabDialogState> {
    constructor(props: TCardsTabDialogIntlFullProps) {
        super(props);
        this.state = {
            newCards: [],
            cardFilter: '',
            widthsTableParts: {
                checkbox: 0.1,
                name: 0.55,
                symbol: 0.35,
            },
        };
    }

    headerRenderer = ({ dataKey, label }) => {
        return (
            <React.Fragment key={dataKey}>
                <div className={theme.tableLabel}>{label}</div>
            </React.Fragment>
        );
    };

    filter = (card: KanbanCardType, filter?: string) => {
        if (!filter) {
            return true;
        }
        const filterL = filter.toLowerCase();
        // получить локаль через getCurrentLocale
        return (
            card.multilingualName.ru?.toLocaleLowerCase().includes(filterL) ||
            card.id?.toLocaleLowerCase().includes(filterL)
        );
    };

    onRow = (card: KanbanCardType) => {
        const { newCards } = this.state;
        const newState: KanbanCardType[] = toggleArraySymbol(
            card as unknown as Symbol,
            newCards as unknown as Symbol[],
        ) as unknown as KanbanCardType[];

        this.setState({ newCards: newState });
    };

    renderDialogRowActionButtons(card: KanbanCardType) {
        const { newCards } = this.state;

        return (
            <div data-test={`symbol-to-modelType-dialog_checkbox_${card.multilingualName.ru}`}>
                <Checkbox
                    onChange={(e: CheckboxChangeEvent) => {
                        const cards = (newCards || []).filter((s) => s.id !== card.id);
                        if (e.target.checked) {
                            cards.push(card);
                        }
                        this.setState({ newCards: cards });
                    }}
                    checked={Boolean(this.state.newCards?.find((s) => s.id === card.id))}
                />
            </div>
        );
    }

    render() {
        const modelCardsIds = this.props.modelTypeCards.map((card: KanbanCardType) => card.id);
        const cards = this.props.availableCards
            .filter((card: KanbanCardType) => !modelCardsIds.includes(card.id))
            .filter((card: KanbanCardType) => this.filter(card, this.state.cardFilter));
        const { intl, onCancel, onAddCards } = this.props;
        const { widthsTableParts } = this.state;

        const onOkHandler = () => {
            const modelTypeCards = [...this.props.modelTypeCards, ...(this.state.newCards || [])];
            onAddCards(modelTypeCards);
        };
        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: onCancel,
                        value: intl.formatMessage(messages.cancel),
                    },
                    {
                        key: 'ok',
                        onClick: onOkHandler,
                        value: intl.formatMessage(messages.create),
                        visualStyle: 'primary',
                    },
                ]}
            />
        );

        return (
            <Dialog
                onOk={onOkHandler}
                onCancel={onCancel}
                visible
                title={intl.formatMessage(messages.addCards)}
                footer={footer}
            >
                <div className={theme.creationTitle}>
                    <Input
                        suffix={<SearchOutlined />}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({ cardFilter: e.target.value })
                        }
                    />
                    <Table
                        width={WIDTH_TABLE_CONTAINER}
                        height={HEIGHT_TABLE_CONTAINER}
                        headerHeight={HEADER_HEIGHT}
                        rowHeight={ROW_HEIGHT}
                        rowCount={cards.length}
                        rowGetter={({ index }) => cards[index]}
                        onRowClick={({ rowData }) => this.onRow(rowData)}
                        rowClassName={theme.tableRow}
                    >
                        <Column
                            headerRenderer={this.headerRenderer}
                            dataKey="checkbox"
                            width={widthsTableParts.checkbox * WIDTH_TABLE_CONTAINER}
                            cellRenderer={({ rowData }) => this.renderDialogRowActionButtons(rowData)}
                            className={theme.tableCheckbox}
                        />
                        <Column
                            headerRenderer={this.headerRenderer}
                            dataKey="name"
                            label={this.props.intl.formatMessage(messages.name)}
                            width={widthsTableParts.name * WIDTH_TABLE_CONTAINER}
                            cellRenderer={({ rowData }) => <div>{rowData.multilingualName.ru}</div>} // TODO поправить на текущую локаль
                        />
                        <Column
                            headerRenderer={this.headerRenderer}
                            dataKey="description"
                            label={this.props.intl.formatMessage(messages.card)}
                            width={widthsTableParts.symbol * WIDTH_TABLE_CONTAINER}
                            cellRenderer={() => (
                                <div>
                                    <img style={{ width: 120, height: 50 }} src={icKanbanCard.toString()} />
                                </div>
                            )}
                        />
                    </Table>
                </div>
            </Dialog>
        );
    }
}

const CardsTabDialogIntl = injectIntl(CardsTabDialog);

export { CardsTabDialogIntl as CardsTabDialog };
