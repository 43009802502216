import { TReducer } from '../utils/types';
import { TNavigatorTab } from '../reducers/navigator.reducer.types';
import { NAVIGATOR_DESELECT_TAB, NAVIGATOR_SELECT_TAB } from '../actionsTypes/navigator.actionTypes';
import { TNavigatorPanelState } from './navigatorPanel.reducer.types';

const initial: TNavigatorPanelState = {
    panels: [TNavigatorTab.Tree],
};

export const navigatorPanelReducer: TReducer<TNavigatorPanelState> = (state = initial, action) => {
    switch (action.type) {
        case NAVIGATOR_DESELECT_TAB: {
            return { ...state, panels: state.panels.filter((panel) => panel !== action.payload.tab) };
        }
        case NAVIGATOR_SELECT_TAB: {
            const panels = state.panels.slice();
            panels.push(action.payload.tab);

            return { ...state, panels };
        }
        default: {
            return state;
        }
    }
};
