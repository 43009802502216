import type { MxCell } from 'MxGraph';
import { SymbolTypeId } from '../ComplexSymbol.constants';
import { getIDEFFrameDiagramTemplate } from './IDEFSymbol.template';
import MetaInfoSerializer from '../serializers/MetaInfoSerializer.class';
import MetaInfoComplexSymbol from '../MetaInfoComplexSymbol.class';
import { MxRectangle } from 'MxGraph';
import { MIN_IDEF_SYMBOL_HEIGHT, MIN_IDEF_SYMBOL_WIDTH } from './IDEFSymbol.constants';

export class IDEFSymbol extends MetaInfoComplexSymbol {
    template = getIDEFFrameDiagramTemplate();
    complexSymbolTypeId = SymbolTypeId.IDEF;
    serializer = new MetaInfoSerializer();

    protected isTitleHidden() {
        return true;
    }

    public getCopyableCell(): MxCell | null {
        return this.rootCell;
    }

    public getResizedCells(bound: MxRectangle) {
        const height = Math.max(MIN_IDEF_SYMBOL_HEIGHT, bound.height);
        const width = Math.max(MIN_IDEF_SYMBOL_WIDTH, bound.width);
        const newRectangle = new MxRectangle(bound.x, bound.y, width, height);

        return [[this.rootCell], [newRectangle]];
    }
}
