import { DashboardNode, NodeId } from '@/serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';
import { IDashboardNode } from '@/models/bpm/bpm-model-impl.types';
import { setServerIdToNodeOriginal } from '@/utils/nodeId.utils';
import { TDashboard } from '@/modules/Dashboard/Dashboard.types';
import { TreeItemType } from '@/modules/Tree/models/tree';

export class DashboardDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async loadDashboard(nodeId: NodeId): Promise<DashboardNode | undefined> {
        const api = this.getApi();

        const dashboard: DashboardNode = await api.dashboard.byId({
            dashboardId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });
        setServerIdToNodeOriginal(dashboard as IDashboardNode, nodeId.serverId);

        return dashboard;
    }

    public static async saveDashboard(
        dashboard: TDashboard | undefined,
        nodeId: NodeId,
        name: string,
        parentNodeId: NodeId,
    ): Promise<IDashboardNode> {
        const api = this.getApi();
        const dashboardNode: DashboardNode = {
            nodeId,
            name,
            parentNodeId,
            type: TreeItemType.Dashboard,
            dataValue: JSON.stringify(dashboard || ''),
        };

        const savedDashboard = (await api.dashboard.save({ body: dashboardNode })) as IDashboardNode;
        setServerIdToNodeOriginal(savedDashboard, nodeId.serverId);

        return savedDashboard;
    }
}
