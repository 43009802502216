import { Symbol } from '../serverapi/api';
import { DRAG_FILE, CREATE_DIAGRAM_ELEMENT } from '../actionsTypes/navigatorSymbol.actionTypes';
import { TCreateDiagramElementAction, TDragFileSymbolAction } from './navigatorSymbol.actions.types';
import { MxCell } from '../mxgraph/mxgraph';

export const createDiagramElement = (draggedSymbol: Symbol, sourceCell?: MxCell): TCreateDiagramElementAction => ({
    type: CREATE_DIAGRAM_ELEMENT,
    payload: {
        draggedSymbol,
        sourceCell,
    },
});

export const editorDragFile = (draggedFile: File): TDragFileSymbolAction => ({
    type: DRAG_FILE,
    payload: {
        draggedFile,
    },
});
