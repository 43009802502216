export const PRESET_SETTINGS_CREATE_REPORT_MODEL_TYPE = 'PRESET_SETTINGS_CREATE_REPORT_MODEL_TYPE';

export const PRESET_SETTINGS_SUBMIT_REPORT_MODEL_TYPE = 'PRESET_SETTINGS_SUBMIT_REPORT_MODEL_TYPE';

export const PRESET_SETTINGS_GET_ALL_REPORT_MODEL_TYPES_SUCCESS = 'PRESET_SETTINGS_GET_ALL_REPORT_MODEL_TYPES_SUCCESS';

export const PRESET_SETTINGS_ADD_REPORT_MODEL_TYPE = 'PRESET_SETTINGS_ADD_REPORT_MODEL_TYPE';

export const PRESET_SETTINGS_DELETE_REPORT_MODEL_TYPE = 'PRESET_SETTINGS_DELETE_REPORT_MODEL_TYPE';

export const PRESET_SETTINGS_TRANSFER_REPORT_MODEL_TYPE = 'PRESET_SETTINGS_TRANSFER_REPORT_MODEL_TYPE';

export const PRESET_SETTINGS_EDIT_REPORT_MODEL_TYPE = 'PRESET_SETTINGS_EDIT_REPORT_MODEL_TYPE';

export const PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_REPORT_MODEL_TYPE =
    'PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_REPORT_MODEL_TYPE';
