import React from 'react';
import { EditorBlock, EditorState, SelectionState } from 'draft-js';
import theme from './CheckableListRenderer.scss';

const updateDataOfBlock = (editorState, block, newData) => {
    const contentState = editorState.getCurrentContent();
    const newBlock = block.merge({
        data: newData,
    });
    const newContentState = contentState.merge({
        blockMap: contentState.getBlockMap().set(block.getKey(), newBlock),
    });

    const newEditorState = EditorState.push(editorState, newContentState, 'change-block-type');
    const selection = SelectionState.createEmpty(block.get('key'));

    return EditorState.forceSelection(newEditorState, selection);
};

const CheckableListRenderer = (props) => {
    const {
        offsetKey,
        blockProps: { block },
    } = props;

    const toggleChecked = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { changeEditorState, getEditorState } = props.blockProps;
        const data = block.getData();
        const checked = data.has('checked') && data.get('checked') === true;
        const newData = data.set('checked', !checked);
        const editorState = getEditorState();

        return changeEditorState(updateDataOfBlock(editorState, block, newData));
    };

    const data = block.getData();
    const checked = data.get('checked') === true;

    return (
        <div data-offset-key={offsetKey}>
            <div className={theme.checkbox}>
                <input type="checkbox" className={theme.checkbox} checked={checked} onChange={toggleChecked} />
            </div>
            <div className={theme.text}>
                <EditorBlock {...props} />
            </div>
        </div>
    );
};

export default CheckableListRenderer;
