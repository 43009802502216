import { DiagramElement, NodeId } from '../serverapi/api';
import { TreeItemType } from '../modules/Tree/models/tree';
import {
    SELECT_SCRIPT_TO_SCHEDULE_DIALOG_INIT,
    SCRIPT_SELECT_DIALOG_INIT,
    SCRIPT_SETTINGS_SELECT_DIALOG_INIT,
} from '../actionsTypes/scriptSelectDialog.actionTypes';
import {
    TSelectScriptToScheduleDialogInitAction,
    TScriptSelectDialogInitAction,
    TScriptSettingsDialogInitAction,
    TSelectScriptToScheduleDialogInitPayload,
} from './scriptSelectDialog.actions.types';

export const scriptSelectDialogInit = (payload: {
    serverId: string;
    nodeId?: NodeId;
    nodesIdsList?: NodeId[];
    modelId?: NodeId;
    element?: DiagramElement;
    elementsIdsList?: string[];
    rootType: TreeItemType;
    scriptType: TreeItemType;
}): TScriptSelectDialogInitAction => ({
    type: SCRIPT_SELECT_DIALOG_INIT,
    payload,
});

export const selectScriptToScheduleDialogInit = (
    payload: TSelectScriptToScheduleDialogInitPayload,
): TSelectScriptToScheduleDialogInitAction => ({
    type: SELECT_SCRIPT_TO_SCHEDULE_DIALOG_INIT,
    payload,
});

export const scriptSettingsSelectDialogInit = (): TScriptSettingsDialogInitAction => ({
    type: SCRIPT_SETTINGS_SELECT_DIALOG_INIT,
});
