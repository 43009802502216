import React from 'react';
import { Table, Row, Col } from 'antd';
import { UsingLicensesTable } from './UsingLicensesTable.component';
import { useIntl } from 'react-intl';
import { deleteLicense } from '../../../actions/licenses.actions';
import messages from '../messages/licensestool.messages';
import { LicenseDTO } from '../../../serverapi/api';
import theme from './LicenseTool.component.scss';
import { ColumnProps } from 'antd/es/table/Column';
import { useDispatch, useSelector } from 'react-redux';
import { MetaDataSelectors } from '../../../selectors/admintools.selectors';
import { LicenseSelectors } from '../../../selectors/license.selectors';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { useLicensesDataSource } from './useLicensesDataSource';
import DeleteButton from '../Button/DeleteButton';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

export const LicensesTool = () => {
    const intl = useIntl();
    const serverId = useSelector(MetaDataSelectors.getCurrentServerId);
    const {
        licenses,
        resulting: resultingLicense,
        usingLicense,
        usingCompetitiveLicense,
    } = useSelector(LicenseSelectors.byServerId(serverId));
    const dispatch = useDispatch();
    const onClick = () => dispatch(openDialog(DialogType.UPLOAD_LICENSE_DIALOG, {}));
    const dataSource = useLicensesDataSource(licenses);

    const columns: ColumnProps<any>[] = [
        {
            title: intl.formatMessage(messages.loadDate),
            dataIndex: 'loadDate',
            key: 'loadDate',
            width: 95,
        },
        {
            title: intl.formatMessage(messages.companyName),
            dataIndex: 'companyName',
            key: 'companyName',
            width: 130,
        },
        {
            title: intl.formatMessage(messages.licenseTypes),
            dataIndex: 'licenseTypes',
            key: 'licenseTypes',
            width: 160,
        },
        {
            title: intl.formatMessage(messages.licenseOrTech),
            dataIndex: 'licenseOrTech',
            key: 'licenseOrTech',
            width: 100,
            render: (text: string) => <div style={{ textIndent: '10px' }}>{text}</div>,
        },
        {
            title: intl.formatMessage(messages.serialNumber),
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            width: 110,
        },
        {
            title: intl.formatMessage(messages.licenseID),
            dataIndex: 'id',
            key: 'licenseID',
            width: 130,
        },
        {
            title: intl.formatMessage(messages.beginDate),
            dataIndex: 'beginDate',
            key: 'beginDate',
            width: 100,
        },
        {
            title: intl.formatMessage(messages.endDate),
            dataIndex: 'endDate',
            key: 'endDate',
            width: 100,
        },
        {
            title: intl.formatMessage(messages.daysLeft),
            dataIndex: 'daysLeft',
            key: 'daysLeft',
            width: 90,
            render: (text: string) => <div style={{ textAlign: 'center' }}>{text}</div>,
        },
        {
            title: '',
            dataIndex: 'manageBtn',
            key: 'manageBtn',
            width: 25,
            render: (text, license: LicenseDTO) => (
                <div className={theme.buttonsBox}>
                    <DeleteButton
                        onDelete={() => dispatch(deleteLicense(license.id))}
                        deleteQuestion={`${intl.formatMessage(messages.deleteLicenseQuestion)} ${license.id} ?`}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={theme.container} data-test="license-tab_container">
            <div className={theme.usingLicensesTableWrapper}>
                {resultingLicense && (
                    <UsingLicensesTable {...{ resultingLicense, usingLicense, usingCompetitiveLicense }} />
                )}
            </div>

            <Row className={theme.loadLicenseRowTitle}>
                <Col span={16}>
                    <h3>{intl.formatMessage(messages.loadLicensesHeader)}</h3>
                </Col>
                <Col span={8}>
                    <div className={theme.loadLicenseBtnLabelWrapper}>
                        <Button data-test="add-license-file-button" visualStyle="primary" size="large" onClick={onClick}>
                            {intl.formatMessage(messages.loadLicenseBtnLabel)}
                        </Button>
                    </div>
                </Col>
            </Row>

            <Table
                data-test="license-files-table"
                className={theme.table}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
            />
        </div>
    );
};
