import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import theme from './TablePicker.scss';
import messages from './TablePicker.messages';
import { TTablePickerProps } from './TablePicker.types';
import { useClickOutside } from '../Select/useClickOutside';

const CELL_WIDTH = 17;
const CELL_HEIGHT = 17;
const DEFAULT_ROWS_COUNT = 3;
const DEFAULT_COLS_COUNT = 4;
const DEFAULT_PICKED_ROWS = 1;
const DEFAULT_PICKED_COLS = 1;
const MAX_ROWS = 15;
const MAX_COLS = 20;

const limitBy = (x: number, limit: number) => (x > limit ? limit : x);

const getIntersectCellPair = (event: MouseEvent, rect: DOMRect, size = { width: CELL_WIDTH, height: CELL_HEIGHT }) => {
    const { pageX, pageY } = event;
    const { x, y } = rect;
    const { width, height } = size;

    return [Math.abs(Math.ceil((pageX - x) / width)), Math.abs(Math.ceil((pageY - y) / height))];
};

const TablePicker = ({ onChange }: TTablePickerProps) => {
    const intl = useIntl();
    const [counts, setCounts] = useState<{ rows: number; cols: number }>({
        rows: DEFAULT_ROWS_COUNT,
        cols: DEFAULT_COLS_COUNT,
    });
    const ref = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [picked, setPicked] = useState<{ rows: number; cols: number }>({
        rows: DEFAULT_PICKED_ROWS,
        cols: DEFAULT_PICKED_COLS,
    });
    const selectedSize = {
        width: picked.cols * CELL_WIDTH,
        height: picked.rows * CELL_HEIGHT,
    };
    const generalWidth = counts.cols * CELL_WIDTH;
    const generalSize = {
        width: generalWidth,
        height: counts.rows * CELL_HEIGHT,
    };

    useEffect(() => {
        const pickerHandler = (event: MouseEvent) => {
            if (!ref.current) {
                return;
            }

            const rect = ref.current.getBoundingClientRect();
            const [col, row] = getIntersectCellPair(event, rect);

            if (!col || !row) {
                return;
            }

            setPicked({ cols: limitBy(col, MAX_COLS), rows: limitBy(row, MAX_ROWS) });

            const maxRows = Math.max(row + 1, DEFAULT_ROWS_COUNT + 1);
            const maxCols = Math.max(col + 1, DEFAULT_COLS_COUNT + 1);

            setCounts({
                rows: limitBy(maxRows, MAX_ROWS),
                cols: limitBy(maxCols, MAX_COLS),
            });
        };

        containerRef?.current?.addEventListener('mousemove', pickerHandler);

        return () => {
            containerRef?.current?.removeEventListener('mousemove', pickerHandler);
        };
    }, [counts]);

    const setDefaultSize = () =>
        setTimeout(() => {
            setPicked({ rows: DEFAULT_PICKED_ROWS, cols: DEFAULT_PICKED_COLS });
            setCounts({ rows: DEFAULT_ROWS_COUNT, cols: DEFAULT_COLS_COUNT });
        }, 400);

    const handleClick = () => {
        if (onChange) {
            onChange(picked);
            setDefaultSize();
        }
    };

    useClickOutside(containerRef, () => setDefaultSize());

    return (
        <div ref={containerRef} className={theme.container}>
            <div className={theme.header} style={{ width: generalWidth }}>
                {intl.formatMessage(messages.title)} {picked.rows}x{picked.cols}
            </div>
            <div className={theme.box} style={generalSize} onClick={handleClick}>
                <div ref={ref} className={theme.pickerCell} data-test="table_size-picker" />
                <div className={theme.pickerSelectedCell} style={selectedSize} />
            </div>
        </div>
    );
};

export default TablePicker;
