import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '../../serverapi/api';
import { TDashboardState } from '../../reducers/entities/dashboard.reducer.types';
import { TDashboard, TDashboardCellsCount, TWidget } from '@/modules/Dashboard/Dashboard.types';
import { defaultCellCount, defaultCellSize } from '@/modules/Dashboard/DashboardsConsts';
import { homePageTabId } from '@/models/home-page';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { filterTreeIncludeTypes, getTreeItems, treeNestedChildrenMap } from '../tree.selectors';
import { TTreeEntityState } from '@/models/tree.types';

const dashboardStateSelector = (state: TRootState): TDashboardState => state.dashboard;

export namespace DashboardSelector {
    export const dashboardById = (nodeId: NodeId) =>
        createSelector<TRootState, TDashboardState, TDashboard | undefined>(
            dashboardStateSelector,
            (state) => state?.[nodeId.repositoryId]?.[nodeId.id],
        );

    export const widgetsbyId = (nodeId: NodeId) =>
        createSelector<TRootState, TDashboardState, TWidget[]>(
            dashboardStateSelector,
            (state) => state?.[nodeId.repositoryId]?.[nodeId.id]?.widgets || [],
        );

    export const selectedWidget = (nodeId: NodeId | undefined) =>
        createSelector<TRootState, TDashboardState, TWidget | undefined>(dashboardStateSelector, (state) => {
            if (nodeId) {
                const selectedId = state?.[nodeId.repositoryId]?.[nodeId.id]?.selectedWidgetId || '';

                return (state?.[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).find(
                    (widget) => widget.id === selectedId,
                );
            }

            return undefined;
        });

    export const cellSizeById = (nodeId: NodeId) =>
        createSelector<TRootState, TDashboardState, number>(
            dashboardStateSelector,
            (state) => state?.[nodeId.repositoryId]?.[nodeId.id]?.cellSize || defaultCellSize,
        );

    export const cellsCountById = (nodeId: NodeId) =>
        createSelector<TRootState, TDashboardState, TDashboardCellsCount>(dashboardStateSelector, (state) => {
            return {
                verticalCount: state?.[nodeId.repositoryId]?.[nodeId.id]?.verticalCount || defaultCellCount,
                horizontalCount: state?.[nodeId.repositoryId]?.[nodeId.id]?.horizontalCount || defaultCellCount,
            };
        });

    export const getSpreadsheetTreeNodes = (repositoryId: string, serverId: string) => {
        const INCLUDE_TYPES: TreeItemType[] = [TreeItemType.Spreadsheet, TreeItemType.Repository, TreeItemType.Folder];

        return createSelector(
            getTreeItems(serverId, repositoryId),
            (treeItemsById: { [id: string]: TTreeEntityState }) => {
                if (repositoryId !== homePageTabId.repositoryId) {
                    const nodes = treeNestedChildrenMap(treeItemsById, repositoryId);

                    return filterTreeIncludeTypes(nodes, INCLUDE_TYPES);
                }

                return [];
            },
        );
    };
}
