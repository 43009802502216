import { connect } from 'react-redux';
import { PsdTableDeleteConfirmationDialog } from '../components/PsdTableDeleteConfirmationDialog.component';
import { TPsdTableDeleteConfirmationDialogProps } from '../PsdTableDeleteConfirmationDialog.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { removeTableRow, removeTableColumn } from '../../../actions/editor.actions';

const mapStateToProps = (): Partial<TPsdTableDeleteConfirmationDialogProps> => ({});

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeDialog(DialogType.PSD_TABLE_DELETE_CONFIRMATION_DIALOG)),
    onCancel() {
        dispatch(closeDialog(DialogType.PSD_TABLE_DELETE_CONFIRMATION_DIALOG));
    },
    onSubmitRow(cellId: string) {
        dispatch(removeTableRow(cellId));
    },
    onSubmitColumn(cellId: string) {
        dispatch(removeTableColumn(cellId));
    },
});

export const PsdTableDeleteConfirmationDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PsdTableDeleteConfirmationDialog);
