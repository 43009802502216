import { defineMessages } from 'react-intl';

export default defineMessages({
    ELEMENT_SHOULD_HAVE_ONLY_ONE_OUTPUT: {
        id: 'Simulation.ELEMENT_SHOULD_HAVE_ONLY_ONE_OUTPUT',
        defaultMessage: 'Элемент должен иметь только одну исходящую связь',
    },
    ELEMENT_SHOULD_HAVE_ONLY_ONE_INPUT: {
        id: 'Simulation.ELEMENT_SHOULD_HAVE_ONLY_ONE_INPUT',
        defaultMessage: 'Элемент должен иметь только одну входящую связь',
    },
    ELEMENT_HAVE_WRONG_COUNT_OF_EDGE: {
        id: 'Simulation.ELEMENT_HAVE_WRONG_COUNT_OF_EDGE',
        defaultMessage: 'Неверное количество связей у элемента',
    },
    ATTRIBUTES_ARE_SKIPPED: {
        id: 'Simulation.ATTRIBUTES_ARE_SKIPPED',
        defaultMessage: 'Отсутствует атрибут',
    },
    DEADLOCK: {
        id: 'Simulation.DEADLOCK',
        defaultMessage: 'Тупик',
    },

    WRONG_PERIOD: {
        id: 'Simulation.WRONG_PERIOD',
        defaultMessage: 'Неверный период моделирования',
    },
    GRAPH_CONTAIN_CYCLE: {
        id: 'Simulation.GRAPH_CONTAIN_CYCLE',
        defaultMessage: 'Граф содержит циклы',
    },
    EVENT_LIMIT_EXHAUSTED: {
        id: 'Simulation.EVENT_LIMIT_EXHAUSTED',
        defaultMessage: 'Превышен лимит событий',
    },
    TOKEN_LIMIT_EXHAUSTED: {
        id: 'Simulation.TOKEN_LIMIT_EXHAUSTED',
        defaultMessage: 'Превышен лимит токенов',
    },
    SETTINGS_ERROR: {
        id: 'Simulation.SETTINGS_ERROR',
        defaultMessage: 'Ошибки в настройках моделирования',
    },

    MODEL_NOT_FOUND: {
        id: 'Simulation.MODEL_NOT_FOUND',
        defaultMessage: 'Модель не найдена',
    },
    OBJECT_DEFINITION_NOT_FOUND: {
        id: 'Simulation.OBJECT_DEFINITION_NOT_FOUND',
        defaultMessage: 'Объект не найден',
    },
    ELEMENT_NOT_FOUND: {
        id: 'Simulation.ELEMENT_NOT_FOUND',
        defaultMessage: 'Элемент диаграммы не найден',
    },

    INVALID_SYMBOL_ROLE: {
        id: 'Simulation.INVALID_SYMBOL_ROLE',
        defaultMessage: 'Неверная роль символа',
    },

    PROHIBITED_ELEMENT_ACTION: {
        id: 'Simulation.PROHIBITED_ELEMENT_ACTION',
        defaultMessage: 'Недопустимое действие для элемента',
    },
    ALGORITHM_ERROR: {
        id: 'Simulation.ALGORITHM_ERROR',
        defaultMessage: 'Ошибка работы алгоритма',
    },
    WRONG_TOKEN_STATUS: {
        id: 'Simulation.WRONG_TOKEN_STATUS',
        defaultMessage: 'Неверный статус токена',
    },

    GRAPH_CONTAIN_DECOMPOSITION_CYCLE: {
        id: 'Simulation.GRAPH_CONTAIN_DECOMPOSITION_CYCLE',
        defaultMessage: 'Существуют циклы декомпозиции',
    },
    ATTRIBUTE_VALUE_NOT_A_NUMBER: {
        id: 'Simulation.ATTRIBUTE_VALUE_NOT_A_NUMBER',
        defaultMessage: 'Значение атрибута должно быть числом',
    },
    INTERNAL_ERROR: {
        id: 'Simulation.INTERNAL_ERROR',
        defaultMessage: 'Ошибка сервера.',
    },
});
