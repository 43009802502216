import { GET_NOTATION_REPLACEMENT_DATA_BY_NODE_ID_SUCCESS } from '../actionsTypes/changeEntityType.actionTypes';
import { TReducer } from '../utils/types';
import { TChangeEntityTypeState } from './changeEntityType.types';
import { TNodeState } from './entities/TNodeState';

const INITIAL_STATE = new TNodeState() as TChangeEntityTypeState;

export const changeEntityTypeReducer: TReducer<TChangeEntityTypeState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NOTATION_REPLACEMENT_DATA_BY_NODE_ID_SUCCESS:
            const { nodeId, notationReplacementData } = action.payload;

            return state.set(nodeId, notationReplacementData);
        default:
            return state;
    }
};
