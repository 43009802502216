import { defineMessages } from 'react-intl';

export default defineMessages({
    elementType: {
        id: 'CompareModels.elementType',
        defaultMessage: 'Тип элемента',
    },
    attributeTypeName: {
        id: 'CompareModels.attributeTypeName',
        defaultMessage: 'Имя типа атрибута',
    },
    valueModel1: {
        id: 'CompareModels.valueModel1',
        defaultMessage: 'Значение модель 1',
    },
    valueModel2: {
        id: 'CompareModels.valueModel2',
        defaultMessage: 'Значение модель 2',
    },
    attributeIsAbsent: {
        id: 'CompareModels.attributeIsAbsent',
        defaultMessage: 'Атрибут отсутствует',
    },
    MODEL: {
        id: 'CompareModels.MODEL',
        defaultMessage: 'Модель',
    },
    OBJECT: {
        id: 'CompareModels.OBJECT',
        defaultMessage: 'Определение объекта',
    },
    EDGE: {
        id: 'CompareModels.EDGE',
        defaultMessage: 'Определение связи',
    },
    EDGE_INSTANCE: {
        id: 'CompareModels.EDGE_INSTANCE',
        defaultMessage: 'Экземпляр связи',
    },
    OBJECT_INSTANCE: {
        id: 'CompareModels.OBJECT_INSTANCE',
        defaultMessage: 'Экземпляр объекта',
    },
    SHAPE: {
        id: 'CompareModels.SHAPE',
        defaultMessage: 'Фигура',
    },
    compareModels: {
        id: 'CompareModels.compareModels',
        defaultMessage: 'Сравнение моделей',
    },
    graphicalComparison: {
        id: 'CompareModels.graphicalComparison',
        defaultMessage: 'Графическое сравнение',
    },
    showDifferent: {
        id: 'CompareModels.showDifferent',
        defaultMessage: 'Показывать отличия',
    },
});
