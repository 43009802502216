import React from 'react';
import { LocalesService } from '../../../../services/LocalesService';
import { NodeId } from '../../../../serverapi/api';
import { useSelector } from 'react-redux';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import messages from '../../messages/CommentsPanel.messages';

import theme from './CommentTooltipHeader.scss';

type TCommentTooltipHeader = {
    modelId: NodeId;
    commentId: string;
    isSmallFormat: boolean;
};

export const CommentTooltipHeader = (props: TCommentTooltipHeader) => {
    const { modelId, commentId, isSmallFormat } = props;
    const updatedDate: string = useSelector(CommentsSelectors.getUpdateDate(modelId, commentId));
    const authorName: string = useSelector(CommentsSelectors.getCommentAuthorName(modelId, commentId));
    const comment = useSelector(CommentsSelectors.getCommentById(modelId, commentId));
    const intl = LocalesService.useIntl();

    return (
        <div className={isSmallFormat ? theme.smallCommentHeader : theme.commentHeader}>
            <div className={theme.commentHeaderName}>{`${authorName}`}</div>
            <div>
                <div className={theme.commentHeaderDate}>
                    {updatedDate}
                    {comment?.updatedDate ? (
                        <span className={theme.commentHeaderEdited} data-test="user-comment_edited">
                            {intl.formatMessage(messages.edited)}
                        </span>
                    ) : (
                        ''
                    )}
                </div>  
            </div>
        </div>
    );
};
