import React, { FC } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Tooltip } from 'antd';
import messages from '../../messages/PrincipalAttributeDialog.messages';
import theme from './PrincipalAttributeDialog.scss';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { TUserListTableRecord } from './UserList.types';
import icDeleted from '../../../../resources/icons/ic-g-u-deleted.svg';
import icBlocked from '../../../../resources/icons/ic-g-u-blocked.svg';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import { DELETED_USER_ID } from '../utils';

type TUserLoginProps = {
    text?: string;
    user: PrincipalDescriptor | TUserListTableRecord;
    withGap?: boolean;
} & WrappedComponentProps;

const UserLogin: FC<TUserLoginProps> = ({ intl, text: rawText, user, withGap = false }) => {
    const isDeleted = user.id === DELETED_USER_ID;
    const isBlocked = !isDeleted && user.blocked;
    const tooltipText = intl.formatMessage(isDeleted ? messages.userNotFound : messages.userBlocked);
    const tooltipIcon = isDeleted ? icDeleted : icBlocked;
    const text = isDeleted ? user.login : rawText;
    const isIconVisible = isDeleted || isBlocked;

    return (
        <div className={theme.principalContainer}>
            <Tooltip title={isIconVisible ? tooltipText : ""}>
                <div
                    className={theme.tooltipIcon}
                    style={
                        withGap
                            ? { visibility: isIconVisible ? 'visible' : 'hidden', display: 'unset' }
                            : { display: isIconVisible ? 'inherit' : 'none', visibility: 'unset' }
                    }
                >
                    <Icon spriteSymbol={tooltipIcon} />
                </div>
            </Tooltip>
            {text}
        </div>
    );
};

export default injectIntl(UserLogin);
