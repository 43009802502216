import React from 'react';
import { NavigatorPanelButton } from '../../../Navigator/components/NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { CloseIcon } from '../../../../../src/modules/Navigator/components/NavigatorPanel/icons/CloseIcon.component';
import theme from './CommentTooltipActions.scss';
import { scrollIntoView } from '../../../../utils/scrollIntoView';
import { GoToCommentIcon } from '../icons/GoToCommentIcon.component';
import { EditAndClearFromCanvasMenuDropDown } from '../CommentsAdditionalMenu/EditAndClearMenu/EditAndClearFromCanvasMenuDropDown.component';
import { NodeId } from '../../../../serverapi/api';
import { deleteEditingComment } from '@/actions/comments.actions';
import { useDispatch, useSelector } from 'react-redux';
import { CommentsSelectors } from '@/selectors/comments.selectors';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';

type TCommentTooltipActions = {
    commentId: string;
    modelId: NodeId;
    tooltip?: boolean;
    onClose: () => void;
    onChangeEditMode: (mode: boolean) => void;
};

export const CommentTooltipActions = (props: TCommentTooltipActions) => {
    const { commentId, modelId, tooltip, onClose, onChangeEditMode } = props;
    const isEditedComment: boolean = useSelector(CommentsSelectors.isEditedComment(modelId, commentId));
    const dispatch = useDispatch();
    const onHandleClose = () => {
        if (isEditedComment && tooltip) {
            dispatch(
                openDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG, {
                    onCancel: () => {
                        dispatch(deleteEditingComment(modelId));
                        if (onClose) onClose();
                    },
                }),
            );
        } else if (onClose) {
            onClose();
        }
    };
    const goToComment = () => {
        scrollIntoView(commentId);
    };

    return (
        <div className={theme.actionsContainer}>
            <EditAndClearFromCanvasMenuDropDown
                commentId={commentId}
                modelId={modelId}
                closeTooltip={onHandleClose}
                onChangeEditMode={onChangeEditMode}
            />
            <NavigatorPanelButton onClick={goToComment} IconComponent={GoToCommentIcon} dataTest="go-to-comment" />
            <div className={theme.closeIconContainer}>
                <NavigatorPanelButton onClick={onHandleClose} IconComponent={CloseIcon} />
            </div>
        </div>
    );
};
