export enum BpmNodeFilter {
    Off,
    Single,
    SingleAndInOut,
    SingleAndIn,
    SingleAndOut,
    SingleNotSame,
    SingleAndInOutNotSame,
    SingleAndInNotSame,
    SingleAndOutNotSame,
    Many,
    ManyAndInOut,
    ManyAndIn,
    ManyAndOut,
}
