import type { NodeId, ObjectDefinitionNode, ObjectInstance, Symbol } from '@/serverapi/api';
import { ObjectDefinitionSelectors } from '@/selectors/objectDefinition.selectors';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { getStore } from '@/store';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { BpmMxEditorBLLService } from '@/services/bll/BpmMxEditorBLLService';
import { DEFAULT_SYMBOL, NOT_ACCESS_SYMBOL, NOT_READABLE_SYMBOL } from '@/services/SymbolsService';
import { MxUtils, MxConstants } from '@/mxgraph/mxgraph';
import { objectDefinitionService } from '@/services/ObjectDefinitionService';

export type TObjectStyle = {
    style: string;
    labelStyle: string;
    connectable: boolean;
};

function lockObjectStyle(style: string): string {
    let lockedStyle = style;
    lockedStyle = MxUtils.setStyle(lockedStyle, MxConstants.STYLE_MOVABLE, 0);
    lockedStyle = MxUtils.setStyle(lockedStyle, MxConstants.STYLE_RESIZABLE, 0);

    return BpmMxEditorBLLService.setLabelStatus(lockedStyle, false);
}

export const getLabelValue = (objectDefinitionNodeId: NodeId, objNodes?: ObjectDefinitionNode[]): string => {
    const definition = objectDefinitionService().getObjectDefinition(objectDefinitionNodeId);

    if (!definition && objNodes) {
        const objNode = objNodes.find((node) => node.nodeId.id === objectDefinitionNodeId.id);

        return objNode ? objNode.name : '';
    }

    return definition?.name || '';
};

export const getSystemStyles = (objInstance: ObjectInstance, graphId: NodeId): TObjectStyle => {
    const state = getStore().getState();

    const { serverId, repositoryId } = graphId;
    const { objectDefinitionId, symbolId } = objInstance;

    const presetId: string | undefined = TreeSelectors.presetById(graphId)(state);
    const symbols = SymbolSelectors.byServerIdPresetId(serverId, presetId || '')(state);

    const getStyleBySymbol = ({ id, style }: Symbol) => `${id};${style}`;

    // экземпляр без определения
    const symbolHaveDefinition: boolean = !!ObjectDefinitionSelectors.byId({
        serverId,
        repositoryId,
        id: objectDefinitionId || '',
    })(state);

    if (!symbolHaveDefinition) {
        return {
            style: getStyleBySymbol(NOT_ACCESS_SYMBOL),
            labelStyle: NOT_ACCESS_SYMBOL.labelStyle || '',
            connectable: false,
        };
    }

    // символ экземпляра не найден в методологии
    const isSymbolKnown = !!symbols?.find((sym) => sym.id === symbolId);

    if (!isSymbolKnown) {
        return {
            style: getStyleBySymbol(DEFAULT_SYMBOL),
            labelStyle: DEFAULT_SYMBOL.labelStyle || '',
            connectable: false,
        };
    }

    // не читаемый экземпляр
    const isSymbolReadable = UserProfileSelectors.isSymbolReadable(graphId, symbolId)(state);

    if (!isSymbolReadable) {
        return {
            style: getStyleBySymbol(NOT_READABLE_SYMBOL),
            labelStyle: NOT_READABLE_SYMBOL.labelStyle || '',
            connectable: false,
        };
    }

    const labelStyleBySymbol: string | undefined = symbols?.find(
        (symbol: Symbol) => symbol.id === symbolId,
    )?.labelStyle;
    const isNoLabel: boolean = !!labelStyleBySymbol?.includes('noLabel=1');
    const labelStyle: string = BpmMxEditorBLLService.setLabelStatus(objInstance.labelStyle || '', !isNoLabel);

    // не изменяемый экземпляр
    const isSymbolEditable = UserProfileSelectors.isSymbolEditable(graphId, symbolId)(state);

    if (!isSymbolEditable) {
        return {
            style: lockObjectStyle(objInstance.style || ''),
            labelStyle,
            connectable: false,
        };
    }

    return {
        style: objInstance.style || '',
        labelStyle,
        connectable: true,
    };
};
