import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'EdgeManagementDialog.title',
        defaultMessage: 'Управление связями',
    },
    sourceObjectName: {
        id: 'EdgeManagementDialog.sourceObjectName',
        defaultMessage: 'Начальный объект',
    },
    edgeTypeName: {
        id: 'EdgeManagementDialog.edgeTypeName',
        defaultMessage: 'Тип связи',
    },
    edgeName: {
        id: 'EdgeManagementDialog.edgeName',
        defaultMessage: 'Имя связи',
    },
    targetObjectName: {
        id: 'EdgeManagementDialog.targetObjectName',
        defaultMessage: 'Конечный объект',
    },
    save: {
        id: 'EdgeManagementDialog.ok',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'EdgeManagementDialog.cancel',
        defaultMessage: 'Отмена',
    },
});

