import { instancesBPMMxGraphMap } from '@/mxgraph/bpm-mxgraph-instance-map';
import { NodeId } from '@/serverapi/api';
import { MxCell } from 'MxGraph';

export const getActiveGraphSelectedCells = (activeGraphId: NodeId | undefined): MxCell[] => {
    if (!activeGraphId) {
        return [];
    }

    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return [];
    }

    return graph.getSelectionCells();
};

export const isActiveGraphSelectedCells = (activeGraphId: NodeId | undefined) => {
    const selectedCells = getActiveGraphSelectedCells(activeGraphId);

    return !!selectedCells.length;
};
