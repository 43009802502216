import React, { FC } from 'react';
import { WrappedComponentProps } from 'react-intl';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Form, Row, Select } from 'antd';
import messages from './ServerForm.messages';
import css from './theme/ServerSelect.scss';
import { withTheme } from '../../../utils/withTheme';
import { TServerEntity } from '../../../models/entities.types';
import IconInputServerComponent from '../../UIKit/components/Icons/IconInputServer.component';
import addOtherWhite from '@/resources/icons/addOtherWhite.svg'
import settings from '@/resources/icons/settings.svg'
import electron from '../../../electron';
import { useCheckTextOverflow } from '../../../hooks/useCheckTextOverflow';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

const { Option } = Select;
type TServerSelectProps = WrappedComponentProps & {
    availableServers: TServerEntity[];
    selectedServerKey: string;
    onChange: (id: string) => void;
    onServerEdit: (id: string) => void;
    onServerAdd: () => void;
    theme: {
        option?: string;
        group?: string;
        optionIcon?: string;
        icon?: string;
        item?: string;
        row?: string;
        select?: string;
        editButton?: string;
        addButton?: string;
        staticServerIcon?: string;
        disable_pointer_events?: string;
    };
};

const RawServerSelect: FC<TServerSelectProps> = (props) => {
    const { intl, onServerEdit, onServerAdd, onChange, availableServers, theme, selectedServerKey } = props;

    const isTextOverflow = useCheckTextOverflow('.ant-select-selection-item');

    return (
        <FormGroup className={theme.group}>
            <Form.Item className={theme.item}>
                <Row className={theme.row}>
                    <IconInputServerComponent color="#FFFFFF" className={theme.staticServerIcon} />
                    {availableServers && availableServers.length ? (
                        <Select
                            disabled={!electron}
                            className={`${theme.select} ${isTextOverflow ? '' : theme.disable_pointer_events}`}
                            placeholder={intl.formatMessage(messages.serverUrl)}
                            onChange={onChange}
                            notFoundContent={intl.formatMessage(messages.serversNotFound)}
                            value={selectedServerKey}
                        >
                            {availableServers.map((s) => (
                                <Option key={s.id} value={s.id} title={s.url}>
                                    {s.name}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <Select
                            className={`${theme.select} ${isTextOverflow ? '' : theme.disable_pointer_events}`}
                            placeholder={intl.formatMessage(messages.serverUrl)}
                            notFoundContent={intl.formatMessage(messages.serversNotFound)}
                            value={intl.formatMessage(messages.serverName)}
                        />
                    )}
                    {electron && (
                        <>
                            <Button
                                onClick={() => onServerEdit(selectedServerKey)}
                                visualStyle="primary"
                                icon={settings}
                                disabled={!selectedServerKey}
                            />
                            <Button visualStyle="primary" icon={addOtherWhite} onClick={onServerAdd} />
                        </>
                    )}
                </Row>
            </Form.Item>
        </FormGroup>
    );
};

export const ServerSelect = withTheme(Symbol('UserEdit'), css)(RawServerSelect);
