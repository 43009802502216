import type { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TExpandedNode, TExpandedNodesState } from '@/reducers/expandedNodes.reducer.types';

const getState = (state: TRootState) => state.expandedNodes;

export namespace ExpandedNodesSelector {
    export const expandTreeByName = (treeName: string) =>
        createSelector<TRootState, TExpandedNodesState, TExpandedNode | undefined>(getState, (state) => {
            return state[treeName];
        });
}
