import { KANBAN_COLUMN_ITEM_TYPE } from '../../models/kanban.types';
import { AttributeType, KanbanBoardColumnsSettings, NodeId, PresetImage } from '../../serverapi/api';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import theme from './Kanban.scss';
import { KanbanCard } from './KanbanCard';
import { PresetImageSelectors } from '@/selectors/presetSettings/presetImage.selectors';
import { KanbanSelectors } from '@/selectors/kanban.selectors';
import { kanbanMoveItem } from '@/actions/entities/kanban.actions';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import { KanbanBll } from '@/services/bll/KanbanBllService';

type TKanbanColumnProps = {
    columnsSettings: KanbanBoardColumnsSettings;
    columnId: string;
    kanbanNodeId: NodeId;
    presetId: string;
};

export const KanbanColumn: FC<TKanbanColumnProps> = ({ columnsSettings, columnId, kanbanNodeId, presetId }) => {
    const currentLocale = useSelector(getCurrentLocale);
    const attributeTypes: AttributeType[] =
        useSelector(AttributeTypeSelectors.allInPreset(kanbanNodeId.serverId, presetId)) || [];
    const principals = useSelector(PrincipalsSelectors.getAll);
    const dispatch = useDispatch();
    const presetImages: PresetImage[] = useSelector(
        PresetImageSelectors.listAllByPreset(kanbanNodeId.serverId, presetId),
    );
    const singleColSettings = columnsSettings.columns.find((col) => col.id === columnId)!;

    let kanbanColumnItems = useSelector(KanbanSelectors.getKanbanColumnData(kanbanNodeId, presetId, columnId));

    if (singleColSettings?.hasCardsLimitInColumn) {
        kanbanColumnItems = kanbanColumnItems.filter((item, index) => index < singleColSettings.maxCardsCountInColumn!);
    }
    //     let style = {};

    //     if (columnsSettings.sameColumnsWidth) {
    //         if (columnsSettings.widthType === KanbanSizeType.RELATIVE) {
    //             const width = 100 / columnsSettings.columns.length;
    //             style = { width: `${width}%` };
    //         } else {
    //             style = { width: `${columnsSettings.widthValue}px` };
    //         }
    //     } else {
    //         const unit = singleColSettings.widthType === KanbanSizeType.RELATIVE ? '%' : 'px';
    //         style = { width: `${singleColSettings.widthValue}${unit}` };
    //     }

    //     style = {
    //         ...style,
    //         minWidth: 'min-content',
    //         backgroundColor: singleColSettings.columnColor,
    //     };
    //     const headerStyle = {
    //         backgroundColor: singleColSettings.headerColor,
    //     };
    //     let icon: PresetImage | undefined;

    //     if (singleColSettings.hasHeaderIcon)
    //         icon = presetImages.find((image) => image.id === singleColSettings.headerIcon);

    //     return {
    //         style,
    //         icon,
    //         headerStyle,
    //         title: `${singleColSettings?.multilingualName[currentLocale]}`,
    //     };
    // };

    const { style, headerStyle, icon, title } = KanbanBll.computeColumnStyleData({
        singleColSettings,
        columnsSettings,
        presetImages,
        currentLocale,
    });

    const onDragOver = (e) => e.preventDefault();
    return (
        <div
            id={columnId}
            className={theme.column}
            style={style}
            onDragOver={onDragOver}
            onDrop={(e) => {
                e.preventDefault();
                const itemId = e.dataTransfer.getData('kanbanItemId');

                dispatch(kanbanMoveItem({ kanbanNodeId, itemId, columnId }));
            }}
        >
            <header className={theme.header} style={{ ...headerStyle }}>
                {icon ? <img src={icon?.graphical} style={{ width: '30px', marginRight: '15px' }} /> : null}
                {title}
            </header>
            <main>
                {kanbanColumnItems.map((item) => {
                    if (item.type === KANBAN_COLUMN_ITEM_TYPE.KANBAN_CARD_TYPE) {
                        return (
                            <KanbanCard
                                cardType={item.kanbanCardType!}
                                id={item.id}
                                presetImages={presetImages}
                                key={item.id}
                                columnId={columnId}
                                columnItem={item}
                                currentLocale={currentLocale}
                                attributeTypes={attributeTypes}
                                principals={principals}
                                nodeId={kanbanNodeId}
                            />
                        );
                    }

                    return (
                        <div
                            id={item.id}
                            key={item.id}
                            draggable="true"
                            onDragStart={(e) => {
                                e.dataTransfer.setData('kanbanItemId', item.id);
                                item.allowedColumnIds.forEach((colId) => {
                                    if (columnId === colId) return;
                                    document.getElementById(colId)?.classList.add(theme.active);
                                });
                                item.forbiddenColumnIds.forEach((colId) => {
                                    if (columnId === colId) return;
                                    document.getElementById(colId)?.classList.add(theme.disabled);
                                });
                            }}
                            onDragEnd={(e) => {
                                item.allowedColumnIds.forEach((colId) => {
                                    document.getElementById(colId)?.classList.remove(theme.active);
                                });
                                item.forbiddenColumnIds.forEach((colId) => {
                                    document.getElementById(colId)?.classList.remove(theme.disabled);
                                });
                            }}
                            style={{ textAlign: 'center' }}
                            className={theme.card}
                        >
                            <img
                                style={{ width: '100%' }}
                                src={`data:image/svg+xml;base64,${Base64.encode(item.symbolSvg!)}`}
                            />
                        </div>
                    );
                })}
            </main>
        </div>
    );
};
