import { defineMessages } from 'react-intl';

export default defineMessages({
    importArisTitle: {
        id: 'importArisTitle',
        defaultMessage: 'Импорт из ARIS',
    },
    selectFileStep: {
        id: 'selectFileStep',
        defaultMessage: 'Выбор файла',
    },
    pathImport: {
        id: 'pathImport: ',
        defaultMessage: 'Путь импорта',
    },
    analysisStep: {
        id: 'analysisStep',
        defaultMessage: 'Анализ',
    },
    selectDataStep: {
        id: 'selectDataStep',
        defaultMessage: 'Выбор данных',
    },
    methodologyStep: {
        id: 'methodologyStep',
        defaultMessage: 'Методология',
    },
    paramsStep: {
        id: 'paramsStep',
        defaultMessage: 'Параметры',
    },
    importStep: {
        id: 'importStep',
        defaultMessage: 'Импорт',
    },
    finishStep: {
        id: 'finishStep',
        defaultMessage: 'Готово',
    },
    buttonNext: {
        id: 'buttonNext',
        defaultMessage: 'Далее',
    },
    buttonPrevious: {
        id: 'buttonPrevious',
        defaultMessage: 'Назад',
    },
    buttonClose: {
        id: 'buttonClose',
        defaultMessage: 'Закрыть',
    },
    uploadText: {
        id: 'ImportAris.uploadText',
        defaultMessage: 'Нажмите или перетащите файл сюда',
    },
    fileDownload: {
        id: 'fileDownload',
        defaultMessage: 'Загрузить',
    },
    needLicense: {
        id: 'importARIS.needLicense',
        defaultMessage: 'Для этого действия вам необходима лицензия',
    },
    fileDownloadCompleted: {
        id: 'fileDownloadCompleted',
        defaultMessage: 'Файл загружен',
    },
    fileSelect: {
        id: 'fileSelect',
        defaultMessage: 'Выбран файл',
    },
    notDownLoadOption: {
        id: 'notDownLoadOption',
        defaultMessage: 'Не загружать',
    },
    selectModelType: {
        id: 'selectModelType',
        defaultMessage: 'Выберите тип модели',
    },
    selectObjectType: {
        id: 'selectObjectType',
        defaultMessage: 'Выберите тип объекта',
    },
    selectEdgeType: {
        id: 'selectEdgeType',
        defaultMessage: 'Выберите тип связи',
    },
    selectSymbolType: {
        id: 'selectSymbolType',
        defaultMessage: 'Выберите тип символа',
    },
    selectAttributeType: {
        id: 'selectAttributeType',
        defaultMessage: 'Выберите тип атрибута',
    },
    cellTypeObject: {
        id: 'cellTypeObject',
        defaultMessage: 'Тип объекта',
    },
    cellTypeModel: {
        id: 'cellTypeModel',
        defaultMessage: 'Тип модели',
    },
    cellTypeSymbol: {
        id: 'cellTypeSymbol',
        defaultMessage: 'Тип символа',
    },
    cellTypeEdge: {
        id: 'cellTypeEdge',
        defaultMessage: 'Тип связи',
    },
    cellTypeAttribute: {
        id: 'cellTypeAttribute',
        defaultMessage: 'Тип атрибута',
    },
    else: {
        id: 'else',
        defaultMessage: 'и еще',
    },
    total: {
        id: 'MethodologyStep.total',
        defaultMessage: 'всего',
    },
    modelsMap: {
        id: 'modelsMap',
        defaultMessage: 'Соответствие типов моделей',
    },
    objectsMap: {
        id: 'objectMap',
        defaultMessage: 'Соответствие типов объекта: {arisId}',
    },
    symbolsMap: {
        id: 'symbolsMap',
        defaultMessage: 'Соответствие типов символов объекта: {arisId}',
    },
    edgesMap: {
        id: 'edgesMap',
        defaultMessage: 'Соответствие типов связей',
    },
    attributesMap: {
        id: 'attributesMap',
        defaultMessage: 'Соответствие типов атрибутов',
    },
    copyFolderLocation: {
        id: 'copyFolderLocation',
        defaultMessage: 'Сохранение иерархии папок и объектов',
    },
    modelLocation: {
        id: 'modelLocation',
        defaultMessage: 'Размещение объектов в папке с моделями',
    },
    conflictResolveClone: {
        id: 'conflictResolveClone',
        defaultMessage: 'Создать клон',
    },
    conflictResolveLeave: {
        id: 'conflictResolveLeave',
        defaultMessage: 'Не импортировать',
    },
    conflictResolveReplace: {
        id: 'conflictResolveReplace',
        defaultMessage: 'Заменять',
    },
    objectImportPlace: {
        id: 'objectImportPlace',
        defaultMessage: 'Место размещения определений объектов (если не все папки импортируются)',
    },
    conflictResolveStrategy: {
        id: 'conflictResolveStrategy',
        defaultMessage: 'Стратегия разрешения конфликтов',
    },
    attachingEdgesToSymbolBorder: {
        id: 'attachingEdgesToSymbolBorder',
        defaultMessage: 'Присоединять связи к границам символа',
    },
    importIsReady: {
        id: 'importIsReady',
        defaultMessage: 'Все готово для запуска импорта',
    },
    importRun: {
        id: 'importRun',
        defaultMessage: 'Запуск импорта',
    },
    importSuccess: {
        id: 'importSuccess',
        defaultMessage: 'Импорт завершен успешно',
    },
    startAnalysesFile: {
        id: 'startAnalysesFile',
        defaultMessage: 'Идет анализ файла',
    },
    analysesFileComplete: {
        id: 'analysesFileComplete',
        defaultMessage: 'Анализ завершен',
    },
    selectModelForImport: {
        id: 'selectModelForImport',
        defaultMessage: 'Выберите модели для импорта',
    },
    errorAnalysis: {
        id: 'errorAnalysis',
        defaultMessage: 'Ошибка анализа файла',
    },
    errorUploadFile: {
        id: 'errorUploadFile',
        defaultMessage: 'Загрузка файла не удалась',
    },
    errorArisImport: {
        id: 'errorArisImport',
        defaultMessage: 'Ошибка импорта файла',
    },
    cancel: {
        id: 'SelectFileStep.Cancel',
        defaultMessage: 'Отмена',
    },
    saveArisInExcel: {
        id: 'saveArisInExcel',
        defaultMessage: 'Сохранить таблицу соответствия',
    },
    loadArisFromExcel: {
        id: 'loadArisFromExcel',
        defaultMessage: 'Загрузить таблицу соответствия',
    },
});
