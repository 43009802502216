import { SearchResult } from '@/serverapi/api';

export class SearchDeletedElementsBllService {
    public static getIdsToDeleteOnEraseNodes(erasedIds: string[], searchResult: SearchResult[]): string[] {
        const idsToDelete: string[] = [...erasedIds];

        const computeAllChildrenIdsRecursively = (id: string) => {
            searchResult.forEach((searchItem) => {
                if (searchItem.parentId === id) {
                    idsToDelete.push(searchItem.nodeId.id);
                    computeAllChildrenIdsRecursively(searchItem.nodeId.id);
                }
            });
        };

        erasedIds.forEach((id) => {
            computeAllChildrenIdsRecursively(id);
        });

        return [...new Set(idsToDelete)];
    }

    public static getIdsToDeleteOnRestoreNodes(
        repositoryId: string,
        restoredIds: string[],
        searchResult: SearchResult[],
    ): string[] {
        const idsToDelete: string[] = [...restoredIds];

        const computeAllChildrenIdsRecursively = (id: string) => {
            const parentIdOfDeletedElement: string | undefined = searchResult.find(
                (searchItem) => searchItem.nodeId.id === id,
            )?.parentId;

            if (parentIdOfDeletedElement && parentIdOfDeletedElement !== repositoryId) {
                idsToDelete.push(parentIdOfDeletedElement);
                computeAllChildrenIdsRecursively(parentIdOfDeletedElement);
            }
        };

        restoredIds.forEach((id) => {
            computeAllChildrenIdsRecursively(id);
        });

        return [...new Set(idsToDelete)];
    }
}
