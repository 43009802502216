import { ArisTypeMap } from '../serverapi/api';
import {
    CHANGE_IMPORT_ARIS_STAGE,
    CLEAR_ARIS_DESCRIPTOR_FIELDS,
    EXPORT_FILE_ARIS_SUCCESS,
    SET_ARIS_ATTACHING_EDGES_TO_SYMBOL_BORDER,
    SET_ARIS_DATA,
    SET_ARIS_IMPORT_FILE_LOCATION,
    SET_ARIS_METHODOLOGY_DATA,
    SET_ARIS_NODE_ID_FOR_IMPORT,
    SET_ARIS_RESOLVE_CONFLICT_STRATEGY,
    SET_ARIS_RESPONSE_ERROR,
    SET_INITIAL_ARIS_DESCRIPTOR,
} from '../actionsTypes/importAris.actionTypes';
import { TImportArisActionTypes } from '../actions/importAris.actions.types';
import { TArisWorkspaceTabData, TImportArisState } from './importAris.reducer.types';

const initialArisState: TImportArisState = {} as TImportArisState;

const setNewData = (
    state: TImportArisState,
    serverId: string,
    repositoryId: string,
    id: string,
    newWorkspaceTabData: TArisWorkspaceTabData,
): TImportArisState => {
    return {
        ...state,
        [serverId]: {
            ...state[serverId],
            [repositoryId]: {
                ...state[serverId]?.[repositoryId],
                [id]: newWorkspaceTabData,
            },
        },
    };
};

const getArisWorkspaceTabData = (
    state: TImportArisState,
    serverId: string,
    repositoryId: string,
    id: string,
): TArisWorkspaceTabData => {
    return { ...state[serverId]?.[repositoryId]?.[id] };
};

export const importArisReducer = (state = initialArisState, action: TImportArisActionTypes): TImportArisState => {
    switch (action.type) {
        case SET_INITIAL_ARIS_DESCRIPTOR: {
            const { serverId, repositoryId, nodeId, path, stage } = action.payload;
            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, nodeId.id);
            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                path,
                descriptor: { ...arisWorkspaceTabData.descriptor, destination: nodeId, stage },
            };

            return setNewData(state, serverId, repositoryId, nodeId.id, newArisWorkspaceTabData);
        }

        case EXPORT_FILE_ARIS_SUCCESS: {
            const { serverId, repositoryId, id } = action.payload.nodeId;
            const { fileId, fileName } = action.payload;
            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: { ...arisWorkspaceTabData.descriptor, fileId, fileName },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        case CLEAR_ARIS_DESCRIPTOR_FIELDS: {
            const { nodeId } = action.payload;
            const { serverId, repositoryId, id } = nodeId;

            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: { destination: nodeId, stage: arisWorkspaceTabData.descriptor.stage },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        case CHANGE_IMPORT_ARIS_STAGE: {
            const { nodeId, stage } = action.payload;
            const { serverId, repositoryId, id } = nodeId;

            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: { ...arisWorkspaceTabData.descriptor, stage },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        case SET_ARIS_DATA: {
            const { nodeId, arisData } = action.payload;
            const { serverId, repositoryId, id } = nodeId;

            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: { ...arisWorkspaceTabData.descriptor, arisData },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        case SET_ARIS_NODE_ID_FOR_IMPORT: {
            const { nodeId, nodeIdsForImport } = action.payload;
            const { serverId, repositoryId, id } = nodeId;

            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: { ...arisWorkspaceTabData.descriptor, nodeIdsForImport },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        case SET_ARIS_METHODOLOGY_DATA: {
            const { nodeId, arisDataItem, typeArisData } = action.payload;
            const { serverId, repositoryId, id } = nodeId;

            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);
            const { arisData } = arisWorkspaceTabData.descriptor;
            const selectedTypes: ArisTypeMap[] = arisData?.[typeArisData] as ArisTypeMap[];

            const changedArisTypes: ArisTypeMap[] = selectedTypes.map((type) =>
                type.arisId === arisDataItem.arisId ? arisDataItem : type,
            );

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: {
                    ...arisWorkspaceTabData.descriptor,
                    arisData: { ...arisData, [typeArisData]: changedArisTypes },
                },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        case SET_ARIS_IMPORT_FILE_LOCATION: {
            const { nodeId, fileLocation } = action.payload;
            const { serverId, repositoryId, id } = nodeId;

            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: { ...arisWorkspaceTabData.descriptor, fileLocation },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        case SET_ARIS_RESOLVE_CONFLICT_STRATEGY: {
            const { nodeId, conflictResolutionStrategy } = action.payload;
            const { serverId, repositoryId, id } = nodeId;

            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: { ...arisWorkspaceTabData.descriptor, conflictResolutionStrategy },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        case SET_ARIS_ATTACHING_EDGES_TO_SYMBOL_BORDER: {
            const { nodeId, attachingEdgesToSymbolBorder } = action.payload;
            const { serverId, repositoryId, id } = nodeId;

            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: { ...arisWorkspaceTabData.descriptor, attachingEdgesToSymbolBorder },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        case SET_ARIS_RESPONSE_ERROR: {
            const { nodeId, error } = action.payload;
            const { serverId, repositoryId, id } = nodeId;

            const arisWorkspaceTabData = getArisWorkspaceTabData(state, serverId, repositoryId, id);

            const newArisWorkspaceTabData: TArisWorkspaceTabData = {
                ...arisWorkspaceTabData,
                descriptor: { ...arisWorkspaceTabData.descriptor, error },
            };

            return setNewData(state, serverId, repositoryId, id, newArisWorkspaceTabData);
        }

        default: {
            return state;
        }
    }
};
