import { Orientation, PaperFormat } from "../../../utils/types";

export const DEFAULT_ZOOM_LEVEL = 100;

export const DEFAULT_OFFSET = { top: 0, left: 0 };

export const DEFAULT_ORIENTATION = Orientation.PORTRAIT;

export const DEFAULT_PAPER_FORMAT = PaperFormat.A4;

export enum PrintDialogFormItemNames {
    paperFormat = 'paperFormat',
    orientation = 'orientation',
    zoomMode = 'zoomMode',
    zoomScale = 'zoomScale',
    zoomFitAcross = 'zoomFitAcross',
    zoomFitDown = 'zoomFitDown',
    showOffsets = 'showOffsets',
    grayscale = 'grayscale',
    hideDecompositions = 'hideDecompositions',
}