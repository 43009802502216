import { NodeId } from '../../../serverapi/api';
import { getNodeName } from '../../Navigator/navigatorUtils/navigatorTreeSearch.utils';
import { TTreeNodeWithLevel } from '../Tree.types';
import { TreeItemType } from '../models/tree';
import defaultIcon from '../../../resources/icons/defaultIcon.svg';
import imIcon from '../../../resources/icons/ic-tree-im.svg';
import TreeItemIconsMap from '../TreeItemIcons/treeItemIcons';
import { TSpriteSymbol } from '@/models/spriteSymbol.types';

export const getFoundNodeIds = (searchString: string, data: TTreeNodeWithLevel[]): NodeId[] => {
    return searchString
        ? data.filter((node) => isIncludeStr(getNodeName(node), searchString)).map((node) => node.nodeId)
        : [];
};

export const isIncludeStr = (mainSring: string, searchString: string): boolean => {
    return mainSring.toLowerCase().includes(searchString.toLowerCase());
};

export const getNodeIconSpriteSymbol = (type: TreeItemType): TSpriteSymbol => {
    if (type === TreeItemType.ObjectDefinition) return defaultIcon;

    if (type === TreeItemType.SimulationModeling) return imIcon;

    return TreeItemIconsMap[type];
};
