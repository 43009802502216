import React from 'react';
import { useIntl } from 'react-intl';
import { Locale } from '../Header/header.types';
import messages from './LocaleSelect.messages';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';

type TLocaleSelectProps = {
    locale: Locale;
    onLocaleSelect: (locale: Locale) => void;
    isEnLocaleAllowed: boolean;
};

export const LocaleSelect = (props: TLocaleSelectProps) => {
    const { locale, onLocaleSelect, isEnLocaleAllowed } = props;
    const intl = useIntl();

    const options: TRadioOption<Locale>[] = [
        {
            title: intl.formatMessage(messages.russian),
            value: Locale.ru,
            dataTest: "locale-ru",
        },
        {
            title: 'English',
            value: Locale.en,
            dataTest: "locale-en",
            disabled: !isEnLocaleAllowed,
        },
    ];

    return (
        <RadioGroup<Locale>
            value={locale}
            options={options}
            onChange={onLocaleSelect}
            margin={8}
        />
    );
};
