export const ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS = 'ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS';

export const DELETE_ATTRIBUTE_TYPE = 'DELETE_ATTRIBUTE_TYPE';

export const ATTRIBUTE_TYPE_SAVE_REQUEST = 'ATTRIBUTE_TYPE_SAVE_REQUEST';

export const ATTRIBUTE_TYPE_CREATE_REQUEST = 'ATTRIBUTE_TYPE_CREATE_REQUEST';

export const ATTRIBUTE_TYPES_REQUEST_SUCCESS = 'ATTRIBUTE_TYPES_REQUEST_SUCCESS';

export const DELETE_ATTRIBUTE_TYPES_AND_GROUPS = 'DELETE_ATTRIBUTE_TYPES_AND_ATTRIBUTE_TYPE_GROUPS';
