import React from 'react';
import theme from './UserIcon.scss';
import cx from 'classnames';

type TUserIconProps = {
    circleBackgroundColor: string;
    initials: string;
    circleSize?: number;
    fontSize?: number;
    isDraggable?: boolean;
    isCriticalIcon?: boolean;
    withBoarder?: boolean;
    dataTestCircle?: string;
    dataTestInitials?: string;
    handleClick?: () => void;
    onDragStartHandler?: (event: React.DragEvent<HTMLSpanElement>) => void;
};

export const UserIcon = (props: TUserIconProps) => {
    const {
        circleBackgroundColor,
        initials,
        circleSize,
        fontSize,
        isDraggable,
        isCriticalIcon,
        withBoarder,
        dataTestCircle,
        dataTestInitials,
        handleClick,
        onDragStartHandler,
    } = props;

    return (
        <div
            className={cx(
                isCriticalIcon ? theme.circleCritical : theme.circle,
                withBoarder && !isCriticalIcon && theme.withBoarder,
            )}
            style={{
                background: circleBackgroundColor,
                height: `${circleSize || 24}px`,
                width: `${circleSize || 24}px`,
            }}
            data-test={dataTestCircle}
        >
            <span
                className={theme.circleContent}
                onClick={handleClick}
                draggable={isDraggable}
                style={{
                    fontSize: `${fontSize || 10}px`,
                }}
                onDragStart={onDragStartHandler}
                data-test={dataTestInitials}
            >
                {initials}
            </span>
        </div>
    );
};
