// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FloatingAttributes__divider___AAEc{border-top:2px solid #000;margin:16px -24px}.FloatingAttributes__title___yntm{word-break:break-word}.FloatingAttributes__col__il6GD{height:100%;overflow-y:auto;overflow-x:hidden}", "",{"version":3,"sources":["webpack://./src/modules/FloatingAttributes/components/FloatingAttributes.scss"],"names":[],"mappings":"AAAA,oCACI,yBAAA,CACA,iBAAA,CAGJ,kCACI,qBAAA,CAGJ,gCACI,WAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".divider {\r\n    border-top: 2px solid black;\r\n    margin: 16px -24px;\r\n}\r\n\r\n.title {\r\n    word-break: break-word;\r\n}\r\n\r\n.col {\r\n    height: 100%;\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "FloatingAttributes__divider___AAEc",
	"title": "FloatingAttributes__title___yntm",
	"col": "FloatingAttributes__col__il6GD"
};
export default ___CSS_LOADER_EXPORT___;
