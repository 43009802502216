import {
    CHANGE_IMPORT_ARIS_STAGE,
    CLEAR_ARIS_DESCRIPTOR_FIELDS,
    EXPORT_FILE_ARIS_SUCCESS,
    LOAD_ARIS_FROM_EXCEL,
    SAVE_ARIS_IN_EXCEL,
    SEND_ANALYSIS_ARIS_FILE,
    SET_ARIS_ATTACHING_EDGES_TO_SYMBOL_BORDER,
    SET_ARIS_DATA,
    SET_ARIS_IMPORT_FILE_LOCATION,
    SET_ARIS_METHODOLOGY_DATA,
    SET_ARIS_NODE_ID_FOR_IMPORT,
    SET_ARIS_RESOLVE_CONFLICT_STRATEGY,
    SET_ARIS_RESPONSE_ERROR,
    SET_INITIAL_ARIS_DESCRIPTOR,
    START_ARIS_IMPORT,
    UPLOAD_FILE_EXPORT_ARIS,
} from '../actionsTypes/importAris.actionTypes';
import { NodeId } from '../serverapi/api';
import {
    TChangeImportArisStageAction,
    TChangeImportArisStagePayload,
    TClearDescriptorFieldsAction,
    TClearDescriptorFieldsPayload,
    TExportFileArisSuccessAction,
    TExportFileArisSuccessPayload,
    TLoadArisFromExcel,
    TLoadArisFromExcelPayload,
    TSaveArisInExcel,
    TSendAnalysisArisFileAction,
    TSendAnalysisArisFilePayload,
    TSetArisAttachingEdgesToSymbolBorderAction,
    TSetArisAttachingEdgesToSymbolBorderPayload,
    TSetArisDataAction,
    TSetArisDataPayload,
    TSetArisImportFileLocationAction,
    TSetArisImportFileLocationPayload,
    TSetArisMethodologyDataAction,
    TSetArisMethodologyDataPayload,
    TSetArisNodeIdsForImportAction,
    TSetArisNodeIdsForImportPayload,
    TSetArisResolveConflictStrategyAction,
    TSetArisResolveConflictStrategyPayload,
    TSetArisResponseErrorAction,
    TSetArisResponseErrorPayload,
    TSetInitialArisDescriptorAction,
    TSetInitialArisDescriptorPayload,
    TStartArisImportAction,
    TStartArisImportPayload,
    TUploadFileExportArisAction,
    TUploadFileExportArisPayload,
} from './importAris.actions.types';

export const setInitialArisDescriptor = (
    payload: TSetInitialArisDescriptorPayload,
): TSetInitialArisDescriptorAction => ({
    type: SET_INITIAL_ARIS_DESCRIPTOR,
    payload,
});

export const uploadFileExportAris = (payload: TUploadFileExportArisPayload): TUploadFileExportArisAction => ({
    type: UPLOAD_FILE_EXPORT_ARIS,
    payload,
});

export const exportFileArisSuccess = (payload: TExportFileArisSuccessPayload): TExportFileArisSuccessAction => ({
    type: EXPORT_FILE_ARIS_SUCCESS,
    payload,
});

export const clearArisDescriptorFields = (payload: TClearDescriptorFieldsPayload): TClearDescriptorFieldsAction => ({
    type: CLEAR_ARIS_DESCRIPTOR_FIELDS,
    payload,
});

export const changeImportArisStage = (payload: TChangeImportArisStagePayload): TChangeImportArisStageAction => ({
    type: CHANGE_IMPORT_ARIS_STAGE,
    payload,
});

export const sendAnalysisArisFile = (payload: TSendAnalysisArisFilePayload): TSendAnalysisArisFileAction => ({
    type: SEND_ANALYSIS_ARIS_FILE,
    payload,
});

export const setArisData = (payload: TSetArisDataPayload): TSetArisDataAction => ({
    type: SET_ARIS_DATA,
    payload,
});

export const setArisNodeIdsForImport = (payload: TSetArisNodeIdsForImportPayload): TSetArisNodeIdsForImportAction => ({
    type: SET_ARIS_NODE_ID_FOR_IMPORT,
    payload,
});

export const setArisMethodologyData = (payload: TSetArisMethodologyDataPayload): TSetArisMethodologyDataAction => ({
    type: SET_ARIS_METHODOLOGY_DATA,
    payload,
});

export const setArisImportFileLocation = (
    payload: TSetArisImportFileLocationPayload,
): TSetArisImportFileLocationAction => ({
    type: SET_ARIS_IMPORT_FILE_LOCATION,
    payload,
});

export const setArisResolveConflictStrategy = (
    payload: TSetArisResolveConflictStrategyPayload,
): TSetArisResolveConflictStrategyAction => ({
    type: SET_ARIS_RESOLVE_CONFLICT_STRATEGY,
    payload,
});

export const setArisAttachingEdgesToSymbolBorder = (
    payload: TSetArisAttachingEdgesToSymbolBorderPayload,
): TSetArisAttachingEdgesToSymbolBorderAction => ({
    type: SET_ARIS_ATTACHING_EDGES_TO_SYMBOL_BORDER,
    payload,
});

export const startArisImport = (payload: TStartArisImportPayload): TStartArisImportAction => ({
    type: START_ARIS_IMPORT,
    payload,
});

export const setArisResponseError = (payload: TSetArisResponseErrorPayload): TSetArisResponseErrorAction => ({
    type: SET_ARIS_RESPONSE_ERROR,
    payload,
});

export const loadArisFromExcel = (payload: TLoadArisFromExcelPayload): TLoadArisFromExcel => ({
    type: LOAD_ARIS_FROM_EXCEL,
    payload,
})

export const saveArisInExcel = (payload: {nodeId: NodeId}): TSaveArisInExcel => ({
    type: SAVE_ARIS_IN_EXCEL,
    payload,
})