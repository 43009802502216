import { defineMessages } from 'react-intl';

export default defineMessages({
    directional: {
        id: 'EdgeTypesName.directional',
        defaultMessage: 'Направленная связь',
    },
    nonDirectional: {
        id: 'EdgeTypesName.nonDirectional',
        defaultMessage: 'Обычная связь',
    },
    controlFlow: {
        id: 'EdgeTypesName.controlFlow',
        defaultMessage: 'Поток управления',
    },
    informationFlow: {
        id: 'EdgeTypesName.informationFlow',
        defaultMessage: 'Информационный поток',
    },
    informationOutputFlow: {
        id: 'EdgeTypesName.informationOutputFlow',
        defaultMessage: 'Поток информационных услуг',
    },
    materialOutputFlow: {
        id: 'EdgeTypesName.materialOutputFlow',
        defaultMessage: 'Поток ТМЦ',
    },
    organizationalFlow: {
        id: 'EdgeTypesName.organizationalFlow',
        defaultMessage: 'Организационный поток',
    },
    resourcesFlow: {
        id: 'EdgeTypesName.resourcesFlow',
        defaultMessage: 'Поток ресурсов',
    },
    title: {
        id: 'EdgeTypeSelect.title',
        defaultMessage: 'Тип связи',
    }
});
