// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TreeItemNameEditor__container__TU2JZ{margin-bottom:0;width:100%}.TreeItemNameEditor__container__TU2JZ .ant-form-item{margin-bottom:0}.TreeItemNameEditor__container__TU2JZ input{width:100%}", "",{"version":3,"sources":["webpack://./src/modules/Tree/components/TreeItemNameEditor/TreeItemNameEditor.scss"],"names":[],"mappings":"AAAA,sCACI,eAAA,CACA,UAAA,CAEI,qDACI,eAAA,CAGR,4CACI,UAAA","sourcesContent":[".container {\r\n    margin-bottom: 0;\r\n    width: 100%;\r\n    :global {\r\n        .ant-form-item {\r\n            margin-bottom: 0;\r\n        }\r\n    }\r\n    input {\r\n        width: 100%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TreeItemNameEditor__container__TU2JZ"
};
export default ___CSS_LOADER_EXPORT___;
