import { ContentBlock, EditorState } from 'draft-js';

export function getImage(contentState, block: ContentBlock) {
    const entityKey = block.getEntityAt(0);

    return contentState.getEntity(entityKey).getData();
}

export function updateImage(editorState, block: ContentBlock, withData) {
    const contentState = editorState.getCurrentContent();
    const entityKey = block.getEntityAt(0);
    const oldImage = getImage(contentState, block);
    contentState.replaceEntityData(entityKey, {
        ...oldImage,
        ...withData,
    });
}

export function restoreFocus(editorState: EditorState, changeEditorState) {
    const contentState = editorState.getCurrentContent();
    const newEditorState = EditorState.forceSelection(editorState, contentState.getSelectionAfter());

    changeEditorState(newEditorState);
}
