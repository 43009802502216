import type { TUMLPackageSymbolCustomProps } from '../../ComplexSymbol.class.types';
import type { TUmlPackageData } from './packageSymbol.types';
import type { MxCell } from 'MxGraph';
import { v4 as uuid } from 'uuid';
import { MxPoint, MxRectangle } from 'MxGraph';
import { FIXED_STYLE_INDICATOR, MULTILINE_OVERFLOW_ATTRIBUTE, SymbolTypeId } from '../../ComplexSymbol.constants';
import { getUmlPackageSymbolCustomProps } from './sideEffects';
import { UMLSymbol } from '../UMLSymbol.class';
import { getUmlPackageCellValue, getUmlPackageCellSizes } from './packageSymbol.utils';
import { UML_PACKAGE_HEADER } from './packageSymbol.constants';
import { SYMBOL_HORIZONTAL_PADDING } from '../UMLSymbols.constants';

export class UMLPackageSymbol extends UMLSymbol {
    complexSymbolTypeId = SymbolTypeId.UML_PACKAGE;
    customProps: TUMLPackageSymbolCustomProps;
    getObjectAttributes: () => TUmlPackageData;

    public addToGraph() {
        const staticAttributes = this.customProps?.getObjectAttributes?.();

        this.getObjectAttributes = staticAttributes
            ? () => staticAttributes
            : () => getUmlPackageSymbolCustomProps(this.graph, this.rootCellValue);

        const objectAttributes = this.getObjectAttributes();
        const root = this.createSymbolCells(objectAttributes);

        return this.afterCreate(root);
    }

    public convertValueToString(cell: MxCell): string {
        const { complexSymbolTypeId } = cell;
        const { height, width } = cell.getGeometry();
        const objectAttributes = this.getObjectAttributes();

        return getUmlPackageCellValue(objectAttributes, complexSymbolTypeId || '', { height, width });
    }

    public getResizedCells(bound: MxRectangle) {
        const objectAttributes = this.getObjectAttributes();
        const model = this.graph.getModel();
        const cells: MxCell[] = [];
        const updatedBounds: MxRectangle[] = [];

        const cellSizes = getUmlPackageCellSizes(objectAttributes, bound);

        const rootCellGeo = this.rootCell.getGeometry();
        const itemSize = cellSizes[this.rootCell?.complexSymbolTypeId || ''];
        const newRectangle = new MxRectangle(rootCellGeo.x, rootCellGeo.y, itemSize.width, itemSize.height);

        const bodySize = cellSizes[SymbolTypeId.UML_PACKAGE];

        bodySize?.style && model.setStyle(this.rootCell, bodySize.style || '');

        cells.push(this.rootCell);
        updatedBounds.push(newRectangle);

        this.setCustomBound(true);

        this.rootCell.children?.forEach((childCell: MxCell) => {
            if (childCell?.complexSymbolTypeId && cellSizes[childCell?.complexSymbolTypeId]) {
                const { complexSymbolTypeId } = childCell;
                const childCellGeo = childCell.getGeometry();
                const childSize = cellSizes[complexSymbolTypeId];
                const childRectangle = new MxRectangle(childCellGeo.x, childSize.y, childSize.width, childSize.height);

                cells.push(childCell);
                updatedBounds.push(childRectangle);
            }
        });

        return [cells, updatedBounds];
    }

    public redraw() {
        const { rootCell } = this;
        const objectAttributes = this.getObjectAttributes();
        const model = this.graph.getModel();
        const bodyGeo = model.getGeometry(rootCell).clone();
        const cellSizes = getUmlPackageCellSizes(objectAttributes, this.hasCustomBound() ? bodyGeo : undefined);
        const bodySize = cellSizes[SymbolTypeId.UML_PACKAGE];

        const bodyStyle = model.getStyle(rootCell);

        if (bodyGeo.height !== bodySize.height || bodyGeo.width !== bodySize.width || bodyStyle !== bodySize.style) {
            bodyGeo.height = bodySize.height;
            bodyGeo.width = bodySize.width;

            model.setGeometry(rootCell, bodyGeo);
            model.setStyle(rootCell, bodySize.style);
        }

        rootCell.children?.forEach((childCell) => {
            if (childCell?.complexSymbolTypeId && cellSizes[childCell?.complexSymbolTypeId]) {
                const childCellGeo = model.getGeometry(childCell).clone();
                const { height, width, y } = cellSizes[childCell?.complexSymbolTypeId];

                if (childCellGeo.height !== height || childCellGeo.width !== width || childCellGeo.y !== y) {
                    childCellGeo.height = height;
                    childCellGeo.width = width;
                    childCellGeo.y = y;

                    model.setGeometry(childCell, childCellGeo);
                }
            }
        });

        // вызывает пересчет value в convertValueToString
        this.graph.refresh(rootCell);
    }

    private createSymbolCells(packageAttributes: TUmlPackageData): MxCell {
        const { graph } = this;
        const cellBound = this.hasCustomBound()
            ? {
                  height: this.rootCellValue.height,
                  width: this.rootCellValue.width,
              }
            : {};
        const cellSizes = getUmlPackageCellSizes(packageAttributes, cellBound);
        const bodySize = cellSizes[SymbolTypeId.UML_PACKAGE];
        const headerSize = cellSizes[UML_PACKAGE_HEADER];
        const headerStyle =
            `${FIXED_STYLE_INDICATOR};text;fillColor=none;${MULTILINE_OVERFLOW_ATTRIBUTE}=1;` +
            `align=center;spacingLeft=${SYMBOL_HORIZONTAL_PADDING};spacingRight=${SYMBOL_HORIZONTAL_PADDING};` +
            'verticalAlign=middle;overflow=hidden;rotatable=0;html=1;deletable=0;cloneable=0;expand=0;connectable=0;' +
            'allowArrows=0;resizable=0;pointerEvents=0;editable=0;movable=0;strokeColor=none;strokeWidth=0;';
        const parent: MxCell = this.rootCellValue?.parent
            ? this.graph.getModel().getCell(this.rootCellValue?.parent)
            : this.graph.getDefaultParent();

        const bodyCell = graph.createVertex(
            parent,
            this.rootCellValue?.id || uuid(),
            getUmlPackageCellValue(packageAttributes, SymbolTypeId.UML_PACKAGE, {
                height: bodySize.height,
                width: bodySize.width,
            }),
            0,
            0,
            bodySize.width,
            bodySize.height,
            bodySize.style,
        );

        const headerCell = graph.createVertex(
            null,
            uuid(),
            getUmlPackageCellValue(packageAttributes, UML_PACKAGE_HEADER, {
                height: headerSize.height,
                width: headerSize.width,
            }) || '',
            0,
            headerSize.y,
            headerSize.width,
            headerSize.height,
            headerStyle,
        );

        headerCell.complexSymbolTypeId = UML_PACKAGE_HEADER as SymbolTypeId;
        bodyCell.complexSymbolTypeId = SymbolTypeId.UML_PACKAGE;

        bodyCell.insert(headerCell);

        const { x = 0, y = 0 } = this.rootCellValue;
        const position = new MxPoint(x, y);
        const [cells] = this.graph.importCellsWithIds([bodyCell], position, parent);
        const [root] = cells;

        return root;
    }
}
