import type { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import type { TUmlPackageData } from './packageSymbol.types';
import { FRAME_STYLES_INDICATOR, MULTILINE_OVERFLOW_ATTRIBUTE, SymbolTypeId } from '../../ComplexSymbol.constants';
import {
    UML_ATTR_CLASS,
    SYMBOL_VERTICAL_PADDING,
    SYMBOL_LINE_HEIGHT,
    textBlockStyles,
    SYMBOL_HORIZONTAL_PADDING,
} from '../UMLSymbols.constants';
import { getObjectSignature, getStereotypeString } from '../UMLSymbols.utils';
import {
    UML_ATTR_PACKAGE,
    UML_PACKAGE_HEADER,
    MIN_PACKAGE_SYMBOL_WIDTH,
    MIN_PACKAGE_SYMBOL_HEIGHT,
    MAX_PACKAGE_SYMBOL_WIDTH,
    PACKAGE_SYMBOL_LINE_HEIGHT,
    PACKAGE_VERTICAL_OVERFLOW_SYMBOL,
} from './packageSymbol.constants';
import { getTextBlockRenderedSize } from '@/utils/render.utils';

const packageIconSvg =
    '<svg width="11" height="11" style="float: left; margin-right: 6px;" viewBox="0 0 24 24" fill="none" ' +
    'xmlns="http://www.w3.org/2000/svg"><path d="M22 11V17C22 21 21 22 17 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C21 6 22 7 22 11Z" ' +
    'stroke="black" stroke-width="1.5" stroke-miterlimit="10"/></svg>';

const classIconSvg =
    '<svg width="11" height="11" style="float: left; margin-right: 6px;" viewBox="0 0 22 22" fill="none" ' +
    'xmlns="http://www.w3.org/2000/svg"><path d="M14.75 8H7.25M14.75 14H7.25M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z" ' +
    'stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const addPackageIcon = (item: string) => `${packageIconSvg} ${item}`;

const addClassIcon = (item: string) => `${classIconSvg} ${item}`;

type TCellSize = { y: number; height: number; width: number };
type TUmlPackageCellSizes = {
    [SymbolTypeId.UML_PACKAGE]: TCellSize & { style: string };
    [UML_PACKAGE_HEADER]: TCellSize;
};

export const mapPackageObject = ({ name, attributes }: ObjectDefinitionImpl): string => {
    const visibilityAttribute = attributes?.find((el) => el.typeId === UML_ATTR_PACKAGE.VISIBILITY);

    return getObjectSignature(name, { visibility: visibilityAttribute });
};

export const mapClassObject = ({ name, attributes }: ObjectDefinitionImpl): string => {
    const visibilityAttribute = attributes?.find((el) => el.typeId === UML_ATTR_CLASS.VISIBILITY);

    return getObjectSignature(name, { visibility: visibilityAttribute });
};

export const getUmlPackageCellValue = (
    objectAttributes: TUmlPackageData,
    complexSymbolTypeId: string,
    bound: { height: number; width: number },
): string => {
    if (!complexSymbolTypeId) {
        return '';
    }

    const { name, stereotype, packages = [], classes = [] } = objectAttributes;
    const { height } = bound || {};
    const headerHeight = 2 * SYMBOL_VERTICAL_PADDING + SYMBOL_LINE_HEIGHT + (stereotype ? SYMBOL_LINE_HEIGHT : 0);
    const items = [...classes.map(addClassIcon), ...packages.map(addPackageIcon)];

    switch (complexSymbolTypeId) {
        case SymbolTypeId.UML_PACKAGE: {
            return maskVerticalOverflow(items, height - headerHeight).join('\n\n');
        }
        case UML_PACKAGE_HEADER: {
            const stereotypeStr = stereotype ? `${getStereotypeString(stereotype)}\n` : '';

            return `${stereotypeStr}${name}`;
        }
        default: {
            return '';
        }
    }
};

export const getSymbolPartsHeight = (objectAttributes: TUmlPackageData, height?: number): number[] => {
    const { stereotype, packages = [], classes = [] } = objectAttributes;
    const data = [...classes, ...packages];

    const headerHeight = 2 * SYMBOL_VERTICAL_PADDING + SYMBOL_LINE_HEIGHT + (stereotype ? SYMBOL_LINE_HEIGHT : 0);

    const bodyHeight = data.length ? 2 * SYMBOL_VERTICAL_PADDING + PACKAGE_SYMBOL_LINE_HEIGHT * data.length : 0;
    const packageEstimatedHeight = headerHeight + bodyHeight;
    const packageHeight = Math.max(packageEstimatedHeight, MIN_PACKAGE_SYMBOL_HEIGHT);

    if (!height) {
        return [headerHeight, packageHeight];
    }

    const boundsHeight = Math.max(height, MIN_PACKAGE_SYMBOL_HEIGHT);

    return [headerHeight, boundsHeight];
};

const getSymbolPartsWidth = (objectAttributes: TUmlPackageData, width?: number): number => {
    const { name, stereotype, packages = [], classes = [] } = objectAttributes;
    const data = [...classes.map(addClassIcon), ...packages.map(addPackageIcon)];

    if (width) return Math.min(Math.max(MIN_PACKAGE_SYMBOL_WIDTH, width), MAX_PACKAGE_SYMBOL_WIDTH);

    const string = [
        `${getStereotypeString(stereotype)} ${getStereotypeString(stereotype)}`,
        `${name}${name}${name}`,
        data.join('<br/>'),
    ].join('<br/>');
    const { width: packageEstimatedWidth } = getTextBlockRenderedSize(string, textBlockStyles);

    return Math.min(Math.max(MIN_PACKAGE_SYMBOL_WIDTH, packageEstimatedWidth), MAX_PACKAGE_SYMBOL_WIDTH);
};

export const getUmlPackageCellSizes = (
    objectAttributes: TUmlPackageData,
    bound?: { height?: number; width?: number },
): TUmlPackageCellSizes => {
    const { height, width } = bound || {};
    const [headerHeight, packageHeight] = getSymbolPartsHeight(objectAttributes, height);
    const packageWidth = getSymbolPartsWidth(objectAttributes, width);

    return {
        [SymbolTypeId.UML_PACKAGE]: {
            y: 0,
            height: packageHeight,
            width: packageWidth,
            style:
                `${FRAME_STYLES_INDICATOR};shape=folder;spacingTop=${headerHeight + SYMBOL_VERTICAL_PADDING};` +
                `spacingLeft=${SYMBOL_HORIZONTAL_PADDING};spacingRight=${SYMBOL_HORIZONTAL_PADDING};` +
                `${MULTILINE_OVERFLOW_ATTRIBUTE}=1;tabWidth=${Math.round(packageWidth / 2)};` +
                `tabHeight=${headerHeight};tabPosition=left;html=1;pointerEvents=0;strokeColor=default;` +
                'expand=0;rotatable=0;cloneable=0;movableLabel=0;editable=0;align=left;verticalAlign=top;resizable=1;',
        },
        [UML_PACKAGE_HEADER]: {
            y: 0,
            height: headerHeight,
            width: packageWidth / 2,
        },
    };
};

export const maskVerticalOverflow = (items: string[], height: number): string[] => {
    const itemsLength = Math.min(
        Math.floor((height - 2 * SYMBOL_VERTICAL_PADDING) / PACKAGE_SYMBOL_LINE_HEIGHT),
        items.length,
    );
    const showAllItems = itemsLength === items.length;

    return showAllItems ? items : [...items.slice(0, itemsLength), PACKAGE_VERTICAL_OVERFLOW_SYMBOL];
};
