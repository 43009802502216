
export const FIND_DUPLICATES_DATA_REQUEST = 'FIND_DUPLICATES_DATA_REQUEST';

export const FIND_DUPLICATES_DATA_SUCCESS = 'FIND_DUPLICATES_DATA_SUCCESS';

export const FIND_DUPLICATES_DATA_FAILURE = 'FIND_DUPLICATES_DATA_FAILURE';

export const MERGE_DATA_REQUEST_FAILURE = 'MERGE_DATA_REQUEST_FAILURE';

export const MERGE_DATA_REQUEST = 'MERGE_DATA_REQUEST';

export const UPDATE_GROUP_STATUSES = 'UPDATE_GROUP_STATUSES';

export const MERGE_DATA_REQUEST_SUCCESS = 'MERGE_DATA_REQUEST_SUCCESS';

export const SET_STATUSES = 'SET_STATUSES';

export const CLEAR_STATUSES = 'CLEAR_STATUSES';
