import React, { FC } from 'react';
import { TDialogState } from '../../reducers/dialogs.reducer.types';
import { dialogComponents } from './DialogRoot.components';
import theme from './DialogRoot.scss';
import { connect } from 'react-redux';
import { TRootState } from '../../reducers/root.reducer.types';

type TDialogRootProps = {
    dialogs: TDialogState[];
};

const DialogRoot: FC<TDialogRootProps> = (props) => {
    const dialogs = props.dialogs.map((d) => {
        const DialogComponent = dialogComponents[d.type];

        return <DialogComponent {...d.props} open key={d.guid} />;
    });

    return <div className={theme.container}>{dialogs}</div>;
};

export const DialogRootContainer = connect((state: TRootState) => ({
    dialogs: state.dialogs.open,
}))(DialogRoot);
