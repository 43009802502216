import React, { forwardRef, ForwardedRef } from 'react';

import theme from './TextArea.scss';
import { Label } from '@/modules/UIKit/components/Label/Label.component';
import classnames from 'classnames';
import resizeSvg from '@/resources/icons/UIKit/resize-input.svg';
import { Icon } from '@/modules/UIKit/components/Icon/Icon.component';
import { ITextAreaProps } from './TextArea.types';

export const TextArea = forwardRef(
    (
        {
            className = '',
            classNameTextArea = '',
            isError,
            label,
            name,
            error,
            isRequired,
            isDisabled,
            onClick = () => {},
            onChange,
            maxHeight,
            isAutoResize = true,
            value,
            ...rest
        }: ITextAreaProps,
        ref: ForwardedRef<HTMLTextAreaElement>,
    ) => {
        return (
            <Label
                name={name}
                isDisabled={isDisabled}
                isError={isError}
                label={label}
                error={error}
                isRequired={isRequired}
                className={className}
            >
                <div
                    className={classnames(theme.textarea, {
                        [theme.textarea_resize]: !isAutoResize,
                    })}
                >
                    <textarea
                        id={name}
                        name={name}
                        className={classnames(theme.textarea__field, classNameTextArea)}
                        style={{ maxHeight: maxHeight ? `${maxHeight}px` : 'auto' }}
                        onClick={onClick}
                        value={value}
                        onChange={onChange}
                        ref={ref}
                        disabled={isDisabled}
                        {...rest}
                    />
                    <Icon spriteSymbol={resizeSvg} className={theme.resizeIcon} />
                </div>
            </Label>
        );
    },
);
