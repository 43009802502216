import React from 'react';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { LocalesService } from '@/services/LocalesService';
import { Modal, List } from 'antd';
import { useDispatch } from 'react-redux';
import messages from './EPCSelectRowTitleDialog.messages';
import theme from './EPCSelectRowTitleDialog.scss';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TSelectedRow = {name: string, id: string}
type TEPCSelectRowTitleDialogComponentProps = {
    open: boolean;
    rows: TSelectedRow[],
    onSelect: (item: TSelectedRow) => void;
};

const EPCSelectRowTitleDialogComponent = ({ open, onSelect, rows }: TEPCSelectRowTitleDialogComponentProps) => {
    const dispatch = useDispatch();
    const handleCancelClick = () => {
        dispatch(closeDialog(DialogType.EPC_SELECT_MODEL_ROW_NAME_DIALOG));
    };

    const handleOkClick = () => {
        dispatch(closeDialog(DialogType.EPC_SELECT_MODEL_ROW_NAME_DIALOG));
    };
    const intl = LocalesService.useIntl();

    const handleOnClick = (element: TSelectedRow) => () => {
        dispatch(closeDialog(DialogType.EPC_SELECT_MODEL_ROW_NAME_DIALOG));
        onSelect(element);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCancelClick,
                    value: intl.formatMessage(messages.cancel),
                },
            ]}
        />
    );

    return (
        <Modal open onCancel={handleCancelClick} onOk={handleOkClick} footer={footer}>
            <List
                dataSource={rows}
                renderItem={(item, i) => (
                    <List.Item className={theme.listItem} onClick={handleOnClick(item)} key={i}>
                        {item.name}
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default EPCSelectRowTitleDialogComponent;
