import { defineMessages } from 'react-intl';

export default defineMessages({
    copy: {
        id: 'RowButton.copy',
        defaultMessage: 'Копировать',
    },
    edit: {
        id: 'RowButton.edit',
        defaultMessage: 'Редактировать',
    },
    delete: {
        id: 'RowButton.delete',
        defaultMessage: 'Удалить',
    },
    cancel: {
        id: 'RowButton.cancel',
        defaultMessage: 'Отмена',
    },
    deleteTitle: {
        id: 'RowButton.deleteTitle',
        defaultMessage: 'Удалить элементы методологии?',
    },
    settings: {
        id: 'RowButton.settings',
        defaultMessage: 'Расширенные настройки',
    },
});
