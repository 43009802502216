import { TReducer } from '../utils/types';
import { DIALOG_CLOSE, DIALOG_OPEN } from '../actionsTypes/dialogs.actionTypes';
import { TDialogsState } from './dialogs.reducer.types';

const initial: TDialogsState = { open: [] };

export const dialogsReducer: TReducer<TDialogsState> = (state = initial, action) => {
    switch (action.type) {
        case DIALOG_OPEN: {
            const { open } = state;
            const { type } = action.payload;
            // проверяем, что окно не открыто
            const opened = open.find((d) => d.type === type);
            if (!opened) {
                return {
                    ...state,
                    open: [...state.open, action.payload],
                };
            }

            return state;
        }

        case DIALOG_CLOSE: {
            const type = action.payload;
            const dialogIndex = state.open.findIndex((d) => d.type === type);
            if (dialogIndex === -1) {
                return state;
            }

            const visibleDialogs = state.open.concat();
            visibleDialogs.splice(dialogIndex, 1);

            return {
                ...state,
                open: visibleDialogs,
            };
        }

        default:
            return state;
    }
};
