import { IComplexSymbol, TComplexSymbolCreationProps } from './ComplexSymbol.class.types';

function createComplexSymbol(ComplexSymbol: any, props: TComplexSymbolCreationProps): IComplexSymbol {
    const instance = new ComplexSymbol(props);

    instance.addToGraph();

    return instance;
}

export default createComplexSymbol;
