import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { EdgeTypeSelectors } from './edgeType.selectors';
import { ModelTypeSelectors } from './modelType.selectors';
import { ObjectTypeSelectors } from './objectType.selectors';
import { SymbolSelectors } from './symbol.selectors';
import { TTypeMap } from './import.selectors.types';

export namespace ImportSelectors {
    export const nodeId = (state: TRootState) => state.import.nodeId;
    export const isLoading = (state: TRootState) => state.import.isLoading;
    export const files = (state: TRootState) => state.import.files;
    export const presetId = (state: TRootState) => state.import.presetId;
    export const conflictResolutionStrategy = (state: TRootState) => state.import.conflictResolutionStrategy;
    export const resolutionById = (state: TRootState) => state.import.resolutionById;

    export const simbolsList = createSelector(
        SymbolSelectors.all,
        (state) =>
            ObjectTypeSelectors.byPresetId({
                serverId: nodeId(state)?.serverId || '',
                presetId: presetId(state) || '',
            })(state),

        (symbolsMap, objectTypes) => {
            return [...symbolsMap.values()].map(
                (symbol) =>
                    ({
                        type: `${symbol.objectType}: ${symbol.id}`,
                        objectType: symbol.objectType,
                        objectName: objectTypes?.byId?.[symbol.objectType]?.name || '',
                        symbolId: symbol.id,
                        symbolName: symbol.name,
                    } as TTypeMap),
            );
        },
    );

    export const modelTypes = createSelector(
        (state: TRootState) =>
            ModelTypeSelectors.byServerIdPresetIdArr(nodeId(state)?.serverId || '', presetId(state) || '')(state),

        (types) =>
            types.map((type) => ({
                name: type.name,
                id: type.id,
                symbols: type.symbols,
                modelEdgeDefinitions: type.modelEdgeDefinitions,
            })),
    );

    export const filteredSimbolsListByModelType = (modelTypeId: string) =>
        createSelector(
            (state: TRootState) => simbolsList(state),
            (state: TRootState) => modelTypes(state),

            (simbolsList, modelTypes) => {
                const model = modelTypes.find((type) => type.id === modelTypeId);

                if (model && model.symbols && Array.isArray(model.symbols) && model.symbols.length) {
                    return simbolsList.filter((symbol) => model.symbols.some((item) => item.id === symbol.symbolId));
                }

                return simbolsList;
            },
        );

    export const edgeList = createSelector(
        (state: TRootState) =>
            EdgeTypeSelectors.listByPresetId(nodeId(state)?.serverId || '', presetId(state) || '')(state),
        (edgeList) => {
            return edgeList;
        },
    );

    export const filterEdgesListByModelType = (modelTypeId: string) =>
        createSelector(
            (state: TRootState) => edgeList(state),
            (state: TRootState) => modelTypes(state),

            (edges, models) => {
                const model = models.find((m) => m.id === modelTypeId);

                if (model && model.modelEdgeDefinitions) {
                    const edgeIds: string[] = model.modelEdgeDefinitions.map((d) => d.edgeType);

                    return edges.filter((e) => edgeIds.includes(e.id));
                }

                return edges;
            },
        );

    export const fileNamesList = createSelector(
        (state: TRootState) => files(state),
        (filelist) => filelist?.map((file) => file[0].fileName),
    );

    export const getNumberOfVisioPages = (fileName: string) =>
        createSelector(
            (state: TRootState) => files(state),
            (filelist) => filelist?.find((file) => file[0].fileName === fileName)?.length || 0,
        );
}
