import type { DiagramElement } from '@/serverapi/api';
import type { IComplexSymbolSerializer } from './ComplexSymbolSerializer.class.types';
import type { MxCell } from '@/mxgraph/mxgraph';

export abstract class ComplexSymbolSerializer implements IComplexSymbolSerializer {
    abstract serialize(): DiagramElement;
    abstract afterCreate(rootCell: MxCell): void;

    protected rootCell: MxCell;

    protected replaceSystemStyles(): DiagramElement {
        const style: string = this.rootCell.getStyle();
        
        return { ...this.rootCell.getValue(), style };
    }
}
