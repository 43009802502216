import { defineMessages } from 'react-intl';

export default defineMessages({
    PRESETS: {
        id: 'AttributeTypeQuery.PRESETS',
        defaultMessage: 'Пресет',
    },
    MODEL_SYMBOLS: {
        id: 'AttributeTypeQuery.MODEL_SYMBOLS',
        defaultMessage: 'Символы модели',
    },
    SPREADSHEET: {
        id: 'AttributeTypeQuery.SPREADSHEET',
        defaultMessage: 'Таблица',
    },
});
