import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import theme from './ApprovalDialog.scss';
import messages from './ApprovalDialog.messages';
import { Form, FormInstance } from 'antd';
import { useDispatch } from 'react-redux';
import { InputId } from '../InputId/InputId.component';
import { ApprovalTemplateDTO, InternationalString } from '../../serverapi/api';
import { MultiLangInputDialog } from '../MultiLangInputDialog/MultiLangInputDialog.component';
import { editApprovalTemplate } from '@/actions/approvalTemplates.actions';

type TGeneralSettingsProps = {
    formRef: React.RefObject<FormInstance>;
    approvalTemplate: ApprovalTemplateDTO;
};

export const ApprovalTemplatesGeneralSettingsTab: FC<TGeneralSettingsProps> = (props) => {
    const intl = useIntl();
    const { approvalTemplate, formRef } = props;
    const [generalForm] = Form.useForm();
    const dispatch = useDispatch();

    const onChangeApprovalTemplateName = (name: InternationalString) => {
        dispatch(editApprovalTemplate({ approvalTemplate: { ...approvalTemplate, name } }));
    };

    const onChangeApprovalName = (approvalName: InternationalString) => {
        dispatch(editApprovalTemplate({ approvalTemplate: { ...approvalTemplate, approvalName } }));
    };

    const onChangeApprovalDescription = (approvalDescription: InternationalString) => {
        dispatch(editApprovalTemplate({ approvalTemplate: { ...approvalTemplate, approvalDescription } }));
    };

    return (
        <>
            <Form autoComplete='off' form={generalForm} ref={formRef} layout="vertical" className={theme.form}>
                <InputId disabled value={approvalTemplate.id} required mainInputName="GUID" />
                <MultiLangInputDialog
                    generalForm={generalForm}
                    label={intl.formatMessage(messages.approvalTemplateName)}
                    mainInputName="ApprovalTemplateName"
                    onChange={onChangeApprovalTemplateName}
                    multiLangValue={approvalTemplate.name}
                    formItemClassName={theme.inputContainer}
                    required
                    placeholder={intl.formatMessage(messages.approvalTemplateName)}
                    maxLength={100}
                />
                <MultiLangInputDialog
                    generalForm={generalForm}
                    label={intl.formatMessage(messages.approvalName)}
                    mainInputName="ApprovalName"
                    onChange={onChangeApprovalName}
                    required
                    multiLangValue={approvalTemplate.approvalName}
                    formItemClassName={theme.inputContainer}
                    placeholder={intl.formatMessage(messages.approvalName)}
                    maxLength={100}
                />
                <MultiLangInputDialog
                    generalForm={generalForm}
                    label={intl.formatMessage(messages.approvalTemplateDescription)}
                    mainInputName="ApprovalDescription"
                    onChange={onChangeApprovalDescription}
                    multiLangValue={approvalTemplate.approvalDescription}
                    formItemClassName={theme.inputContainer}
                    placeholder={intl.formatMessage(messages.approvalTemplateDescription)}
                    maxLength={1000}
                    textarea
                />
            </Form>

            {approvalTemplate.stages?.length === 0 ? (
                <div className={theme.emptyStagesError}>{intl.formatMessage(messages.emptyStagesError)}</div>
            ) : null}
        </>
    );
};
