import { List } from 'antd';
import React, { FC, useRef } from 'react';
import { instancesBPMMxGraphMap } from '../../../../../mxgraph/bpm-mxgraph-instance-map';
import { MxCell } from '../../../../../mxgraph/mxgraph';
import { NodeId, ObjectModelConnections } from '../../../../../serverapi/api';
import messages from './NavigatorEdgeTab.messages';
import { useIntl } from 'react-intl';
import theme from './NavigatorEdgeTab.scss';
import NavigatorEdgeTabListItem from './NavigatorEdgeTabListItem.component';
import { useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { EditorMode } from '../../../../../models/editorMode';
import { TreeSelectors } from '../../../../../selectors/tree.selectors';
import { compareNodeIds } from '../../../../../utils/nodeId.utils';
import { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import { ObjectDefinitionSelectors } from '../../../../../selectors/objectDefinition.selectors';
import { ModelObjectConnectionsList } from './ModelObjectConnectionsList.components';

type TNavigatorEdgeTab = {
    nodeId: NodeId;
    cellId?: string;
    graphId?: NodeId;
    showAllEdgesOnModel?: boolean;
};

const NavigatorEdgeTab: FC<TNavigatorEdgeTab> = (props) => {
    const { graphId, cellId, nodeId, showAllEdgesOnModel } = props;
    const intl = useIntl();

    const graph = graphId ? instancesBPMMxGraphMap.get(graphId) : null;
    const presetId: string = useSelector(TreeSelectors.presetById(graph?.id || nodeId));
    const activeScheme = useSelector(TabsSelectors.getActiveTab);
    const prevNodeId = useRef<NodeId | null>(null);
    const prevCellId = useRef('');

    const nodeIdForSelector = nodeId || { id: '', repositoryId: '', serverId: '' };
    const objectDefinition: ObjectDefinitionImpl | undefined = useSelector(
        ObjectDefinitionSelectors.byId(nodeIdForSelector),
    );

    const isObjectSelectedInGraphAndNavigator =
        !compareNodeIds(prevNodeId.current, nodeId) && prevCellId.current === cellId;

    if (!graphId || !cellId || !graph || isObjectSelectedInGraphAndNavigator) {
        if (objectDefinition) {
            const objectModelConnections: ObjectModelConnections[] = objectDefinition.objectModelConnections || [];

            return <ModelObjectConnectionsList nodeId={nodeId} objectModelConnections={objectModelConnections} />;
        }

        return null;
    }

    prevNodeId.current = nodeIdForSelector;
    prevCellId.current = cellId;

    const isEditMode: boolean = activeScheme?.mode !== EditorMode.Edit;

    const focusObj: MxCell = graph?.model.getCell(cellId);
    const graphCells: MxCell[] = showAllEdgesOnModel ? Object.values<MxCell>(graph?.model.cells) : [focusObj];
    const objectDefinitionCells: MxCell[] = graphCells.filter(
        (cell) => cell.value?.objectDefinitionId === nodeId.id && cell.value?.type === 'object',
    );

    const edgesList: { edge: MxCell; cell: MxCell }[] = [];
    objectDefinitionCells.forEach((cell) => {
        cell.edges?.forEach((edge) => {
            edgesList.push({ cell, edge });
        });
    });

    let objectConnectionsOnOtherModels: ObjectModelConnections[] = [];
    if (showAllEdgesOnModel && objectDefinition && graphId) {
        const objectModelConnections: ObjectModelConnections[] = objectDefinition.objectModelConnections || [];
        objectConnectionsOnOtherModels = objectModelConnections.filter(
            (connection) => connection.modelId !== graphId.id,
        );
    }

    return (
        <>
            {edgesList.length !== 0 && (
                <List
                    header={
                        <b data-test="connection-window_model-name">{intl.formatMessage(messages.currentModel)}:</b>
                    }
                    dataSource={edgesList}
                    renderItem={({ edge, cell }) => (
                        <NavigatorEdgeTabListItem
                            presetId={presetId}
                            isEditMode={isEditMode}
                            focusObj={cell}
                            edge={edge}
                            graph={graph}
                        />
                    )}
                    className={theme.list}
                />
            )}
            {objectConnectionsOnOtherModels.length !== 0 && (
                <ModelObjectConnectionsList nodeId={nodeId} objectModelConnections={objectConnectionsOnOtherModels} />
            )}
        </>
    );
};
const withMemo = React.memo(NavigatorEdgeTab);

export { withMemo as NavigatorEdgeTab };
