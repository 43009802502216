import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../../../messages/CommonToolbar.messages';
import { NodeId } from '../../../../../../serverapi/api';
import { MxCell } from '../../../../../../mxgraph/mxgraph';
import { objectPropertyView } from '../../../../../../actions/objectProperty.actions';
import { ObjectPropertiesDialogActiveTab } from '../../../../../../models/objectPropertiesDialog';
import icDecomposition from '../../../../../../resources/icons/decomposition.svg';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton.component';

interface IDecompositionButtonProps {
    disabled: boolean | undefined;
    tabNodeId: NodeId | undefined;
    compact: boolean | undefined;
    oneEdgeOrObjectSelected: boolean;
    selectedCells: MxCell[];
    createTooltipMessage: (message: string, disabled: boolean | undefined, disabledMessage: string) => string;
}

export const DecompositionButton: FC<IDecompositionButtonProps> = (props) => {
    const { disabled, tabNodeId, selectedCells, compact, oneEdgeOrObjectSelected, createTooltipMessage } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const messageDecomposition = compact ? intl.formatMessage(messages.textDecomposition) : '';

    const onOpenDecompositionAction = (workspaceId: NodeId) => {
        if (!workspaceId) {
            return;
        }

        if (selectedCells.length) {
            const cell = selectedCells[0];
            if (cell?.value) {
                dispatch(objectPropertyView(cell.id, ObjectPropertiesDialogActiveTab.Detailing));
            }
        }
    };

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icDecomposition}
            tooltipTitle={createTooltipMessage(
                oneEdgeOrObjectSelected ? messageDecomposition : intl.formatMessage(messages.selectElementOnCanvas),
                disabled,
                !oneEdgeOrObjectSelected && selectedCells.length === 1
                    ? intl.formatMessage(messages.onlyObjectAndEdgeAvailable)
                    : intl.formatMessage(messages.selectElementOnCanvas),
            )}
            dataTest="graph-general-toolbar_decomposition-button"
            containerDataTest="graph-general-toolbar_decomposition-box"
            onClick={() => tabNodeId && onOpenDecompositionAction(tabNodeId)}
            text={intl.formatMessage(messages.textDecomposition)}
        />
    );
};
