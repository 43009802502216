import { ReactNode } from 'react';
import { ContentBlock, ContentState, CharacterMetadata } from 'draft-js';
import { getCommentRenderer } from '../../renderers/Comment/CommentRenderer';
import { COMMENT_TYPE } from './Comment.constants';

type TCommentDecorator = {
    onCommentClick: (isOpen: boolean, threadId: string) => void;
    renderPopover: (threadId: string) => ReactNode;
};

const findCommentEntity = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
) => {
    contentBlock.findEntityRanges((character: CharacterMetadata) => {
        const entityKey = character.getEntity();

        return entityKey !== null && contentState.getEntity(entityKey).getType() === COMMENT_TYPE;
    }, callback);
};

export const CommentsDecorator = ({ onCommentClick, renderPopover }: TCommentDecorator) => ({
    strategy: findCommentEntity,
    component: getCommentRenderer({ onCommentClick, renderPopover }),
});
