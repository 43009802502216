import React, { FC, memo } from 'react';

export const FilterIcon: FC = memo(() => (
    <svg width="13" height="13" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-8.74228e-08 12L6.17071 12C6.58254 13.1652 7.69378 14 9 14C10.3062 14 11.4175 13.1652 11.8293 12L14 12L14 10L11.8293 10C11.4175 8.83481 10.3062 8 9 8C7.69378 8 6.58254 8.83481 6.17071 10L-1.74846e-07 10L-8.74228e-08 12ZM9 10C9.55228 10 10 10.4477 10 11C10 11.5523 9.55228 12 9 12C8.44772 12 8 11.5523 8 11C8 10.4477 8.44772 10 9 10Z"
            fill="#676767"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.17071 2L-5.24537e-07 2L-4.37114e-07 4L2.17071 4C2.58254 5.16519 3.69378 6 5 6C6.30622 6 7.41746 5.16519 7.82929 4L14 4L14 2L7.82929 2C7.41746 0.834807 6.30622 -2.75654e-07 5 -2.18557e-07C3.69378 -1.6146e-07 2.58254 0.834807 2.17071 2ZM4 3C4 3.55228 4.44771 4 5 4C5.55228 4 6 3.55228 6 3C6 2.44772 5.55228 2 5 2C4.44771 2 4 2.44772 4 3Z"
            fill="#676767"
        />
    </svg>
));
