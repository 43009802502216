import React from 'react';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { Tabs } from 'antd';
import messages from './ModelHistoryDialog.messages';
import { ModelVersionInfo, FullModelDefinition, ModelNode } from '../../../../serverapi/api';
import theme from './ModelHistoryDialog.scss';
import { formatDate } from '../../../../utils/date.time.utils';
import { DefaultGraph } from '../../../../mxgraph/DefaultGraph';
import { addElementsToGraph } from '../../../../mxgraph/util/BpmMxEditorUtils';
import { Spinner } from '../../../Spinner/Spinner.component';
import { SymbolsService } from '../../../../services/SymbolsService';
import { WhiteboardGraph } from '../../../../mxgraph/WhiteboardGraph';
import { ModelTypes } from '../../../../models/ModelTypes';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';
import { TModelHistoryDialogProps, TModelHistoryDialogState } from './ModelHistoryDialog.types';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { EditorMode } from '@/models/editorMode';
import { TBPMMxGraphOptions } from '@/mxgraph/bpmgraph.types';
import EPCModelGraph from '@/mxgraph/GridGraph/EPC/EPCModelGraph.class';
import PSDModelGraph from '@/mxgraph/GridGraph/PSD/PSDModelGraph.class';
import { PSDGraph } from '@/mxgraph/psdDiagram/PSDGraph';
import { SequenceGraph } from '@/mxgraph/SequenceGraph/SequenceGraph';

class ModelHistoryDialog extends React.Component<TModelHistoryDialogProps, TModelHistoryDialogState> {
    state = {
        activeKey: +this.props.history[0]?.version || undefined,
        svgGraph: undefined,
    };

    private graphRef: HTMLDivElement;

    componentDidMount() {
        this.renderTab();
    }

    initGraphRef = (element: HTMLDivElement) => {
        this.graphRef = element;
    };

    changeTab = (activeKey: string) => {
        this.setState(
            {
                activeKey: +activeKey,
                svgGraph: undefined,
            },
            () => {
                this.renderTab();
            },
        );
    };

    renderTab = async () => {
        if (this.state.activeKey) {
            const modelData = await this.props.loadVersion(this.state.activeKey);

            if (modelData && this.graphRef) {
                modelData.objects && this.props.addNewObjectDefinitions(modelData.objects as ObjectDefinitionImpl[]);
                modelData.edges && this.props.addNewEdgeDefinitions(modelData.edges);

                const graph = this.createGraph(modelData);

                this.drawSvg(graph);
            }
        }
    };

    createGraph = (modelData: FullModelDefinition): BPMMxGraph => {
        const graph = this.graphFactory(
            modelData.model, 
            {
                id: modelData.model.nodeId,
                modelType: modelData.modelType,
                container: this.graphRef,
                mode: 1,
            }
        );

        const symbolsService = new SymbolsService();
        const modelGraph = modelData.model.graph ? JSON.parse(modelData.model.graph).graph : [];

        symbolsService.applyStylesToGraph(graph, modelData.symbols);
        addElementsToGraph(graph, modelGraph, modelData.model.elements, this.props.serverUrl, modelData.objects);

        return graph;
    };

    graphFactory = (modelNode: ModelNode, options: TBPMMxGraphOptions & { mode?: EditorMode } = {}): BPMMxGraph => {
        switch (options.modelType?.id) {
            case ModelTypes.MIND_MAP:
                return new WhiteboardGraph(options);
            case ModelTypes.PSD_MODEL: {
                const { layout: gridLayout } = modelNode;
    
                return new PSDModelGraph({
                    ...options,
                    gridLayout,
                });
            }
            case ModelTypes.EPC_MODEL: {
                const { layout: gridLayout } = modelNode;
    
                return new EPCModelGraph({
                    ...options,
                    gridLayout,
                });
            }
            case ModelTypes.PSD_CHART: //deprecated
                return new PSDGraph(options);
            case ModelTypes.SEQUENCE_DIAGRAM:
                return new SequenceGraph(options);
            default:
                return new DefaultGraph(options);
        }
    }

    drawSvg = (graph: BPMMxGraph) => {
        const svg = graph.getSvgStrAllGraph().svgString;

        this.setState({
            svgGraph: svg,
        });
    };

    renderRows = () => {
        const { intl, locale } = this.props;
        const { svgGraph } = this.state;

        const createdAtToDate = (h: ModelVersionInfo) =>
            !h.createdAt
                ? ''
                : formatDate(
                      intl.formatMessage(messages.todayTimePrefix),
                      intl.formatMessage(messages.yesterdayTimePrefix),
                      locale,
                      new Date(h.createdAt),
                  );

        return this.props.history.map((h) => {
            return {
                label: (
                    <div>
                        <div className={theme.historyDate}>{createdAtToDate(h)}</div>
                        <div className={theme.historyLogin}>{h.createdBy}</div>
                    </div>
                ),
                key: h.version,
                children: (
                    <>
                        <div ref={this.initGraphRef} style={{ display: 'none' }} />

                        <Spinner loading={!svgGraph}>
                            <div className={theme.svgGraph} dangerouslySetInnerHTML={{ __html: svgGraph! }} />
                        </Spinner>
                    </>
                ),
            };
        });
    };

    restore = () => {
        const { nodeId, onRestore } = this.props;
        const { activeKey } = this.state;

        if (nodeId && activeKey) {
            onRestore(nodeId, activeKey);
        }
    };

    render() {
        const { intl, open, onCancel } = this.props;
        const { activeKey } = this.state;

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: onCancel,
                        value: intl.formatMessage(messages.close),
                    },
                    {
                        key: 'ok',
                        onClick: this.restore,
                        value: intl.formatMessage(messages.restore),
                        visualStyle: 'primary',
                        disabled: activeKey === undefined,
                    },
                ]}
            />
        );

        return (
            <Dialog
                className={theme.dialog}
                open={open}
                title={intl.formatMessage(messages.titleMessage)}
                footer={footer}
                onCancel={onCancel}
                onOk={() => {
                    if (activeKey !== undefined) this.restore();
                }}
            >
                <Tabs tabPosition="left" onChange={this.changeTab} items={this.renderRows()} />
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ModelHistoryDialog);

export { IntlComponent as ModelHistoryDialog };
