import { TreeItemType } from '../../modules/Tree/models/tree';

/**
 * @see https://confluence.silaunion.ru/pages/viewpage.action?pageId=2142142480
 */
export const ALLOW_OBJECT_DESTINATIONS = {
    MODEL: [TreeItemType.Repository, TreeItemType.Folder, TreeItemType.Model, TreeItemType.ObjectDefinition],
    WIKI: [
        TreeItemType.Repository,
        TreeItemType.Folder,
        TreeItemType.Model,
        TreeItemType.Wiki,
        TreeItemType.ObjectDefinition,
    ],
    MATRIX: [
        TreeItemType.Repository,
        TreeItemType.Folder,
        TreeItemType.Model,
        TreeItemType.Matrix,
        TreeItemType.ObjectDefinition,
    ],
    REPORT: [
        TreeItemType.Repository,
        TreeItemType.Folder,
        TreeItemType.Model,
        TreeItemType.Matrix,
        TreeItemType.ObjectDefinition,
    ],
    OBJECT: [
        TreeItemType.Repository,
        TreeItemType.Folder,
        TreeItemType.Model,
        TreeItemType.Matrix,
        TreeItemType.ObjectDefinition,
    ],
    FOLDER: [TreeItemType.Repository, TreeItemType.Folder, TreeItemType.ObjectDefinition],
    SIMULATION: [TreeItemType.Repository, TreeItemType.Folder, TreeItemType.ObjectDefinition],
    SCRIPT: [TreeItemType.ScriptFolder],
    SCRIPT_FOLDER: [TreeItemType.ScriptFolder],
    FILE_FOLDER: [TreeItemType.FileFolder],
    FILE: [
        TreeItemType.Repository,
        TreeItemType.Folder,
        TreeItemType.FileFolder,
        TreeItemType.Model,
        TreeItemType.Wiki,
        TreeItemType.ObjectDefinition,
    ],
    SPREADSHEET: [
        TreeItemType.Repository,
        TreeItemType.Folder,
        TreeItemType.Model,
        TreeItemType.Spreadsheet,
        TreeItemType.ObjectDefinition,
    ],
    DASHBOARD: [TreeItemType.Repository, TreeItemType.Folder],
};

const ALLOWED_DECOMPOSITION_TYPES = {
    TYPES: [TreeItemType.Model, TreeItemType.Matrix, TreeItemType.Wiki, TreeItemType.Spreadsheet],
};

export const isPossibleParentNodeType = (src: TreeItemType, dst: TreeItemType): boolean =>
    ALLOW_OBJECT_DESTINATIONS[src]?.includes(dst);

export const hasNodeTypeChild = (type: TreeItemType): boolean =>
    Object.values(ALLOW_OBJECT_DESTINATIONS).flat().includes(type);

export const allowedDecompositionNodeType = (type: TreeItemType): boolean =>
    Object.values(ALLOWED_DECOMPOSITION_TYPES).flat().includes(type);
