import { defineMessages } from 'react-intl';

export default defineMessages({
    formValidationMessage: {
        id: 'FontFamilySelect.formValidationMessage',
        defaultMessage: 'Имя шрифта не должно быть пустым',
    },

    formLabel: {
        id: 'FontFamilySelect.formLabel',
        defaultMessage: 'Имя шрифта',
    },
});
