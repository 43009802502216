import React from "react";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../../actions/dialogs.actions";
import { deleteScriptContext } from "../../../../actions/entities/script.actions";
import { DeleteImmediately, EditButton, RowButtons } from "../../../AdminTools/Button/RowButtons";
import { DialogType } from "../../../DialogRoot/DialogRoot.constants";
import { scriptContextTypes } from "./scriptContext.types";
import theme from './ScriptContextComponent.scss';
import { ScriptNode } from "../../../../serverapi/api";

type TRowWithButtons = {
    rowData: JSX.Element;
    script: ScriptNode;
    type: scriptContextTypes;
};

export const RowWithButtons = (props: TRowWithButtons) => {
    const dispatch = useDispatch();
    const { rowData, script, type } = props;
    const editScriptContext = (contextToEditType: scriptContextTypes) => {
        dispatch(openDialog(DialogType.ADD_SCRIPT_CONTEXT, { scriptNodeWithNewContext: script, contextToEditType }));
    };

    return (
        <div className={theme.rowButtonsContainer}>
            <div>{rowData}</div>
            <div>
                <RowButtons
                    className={theme.rowButtons}
                    disabled
                    key={type}
                    buttons={[
                        EditButton.build(() => editScriptContext(type), false),
                        DeleteImmediately.build(() => dispatch(deleteScriptContext(type, script.nodeId)), false),
                    ]}
                />
            </div>
        </div>
    );
};