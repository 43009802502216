import { connect } from 'react-redux';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { UserDTO } from '../../../../serverapi/api';
import AssignUserLicenseDialogComponent from '../components/AssignUserLicenseDialog.component';
import {
    TAssignUserLicenseDialogProps,
    TAssignUserLicenseDialogActionProps,
    TAssignUserLicenseDialogOwnProps,
} from '../AssignUserLicenseDialog.types';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { saveUsersLicensesChanges } from '../../../../actions/users.actions';

const mapStateToProps = (
    state: TRootState,
    ownProps: TAssignUserLicenseDialogOwnProps,
): Partial<TAssignUserLicenseDialogProps> => ({
    userData: UsersSelectors.getEditingUser(`${ownProps.userId}`)(state) || ({} as UserDTO),
});

const mapDispatchToProps: (
    dispatch,
    ownProps: TAssignUserLicenseDialogOwnProps,
) => TAssignUserLicenseDialogActionProps = (dispatch, ownProps: TAssignUserLicenseDialogOwnProps) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.EDIT_ASSIGN_USER_LICENSE));
    },
    onSubmit: (usersLicenses) => {
        dispatch(saveUsersLicensesChanges(usersLicenses, `${ownProps.userId}`));
        dispatch(closeDialog(DialogType.EDIT_ASSIGN_USER_LICENSE));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignUserLicenseDialogComponent);
