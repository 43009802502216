import { findConnectedEdgeInstances } from '@/modules/Matrix/utils/Matrix.utils';
import { Node, NodeId, ObjectConnection, ObjectDefinitionNode } from '../../serverapi/api';
import { setServerIdToNodeInterface } from '../../utils/nodeId.utils';
import { apiBundle } from '../api/api-bundle';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';

export class ObjectDefinitionsDAOService {
    public static async getObjectDefinitions(
        serverId: string,
        params: { repositoryId: string; objectDefinitionName: string; objectTypeId: string },
    ): Promise<ObjectDefinitionNode[]> {
        const nodes: ObjectDefinitionNode[] = await apiBundle(
            serverId,
        ).objectsDefinition.objectDefinitionsByRepositoryByNameAndType(params);

        return nodes.map((node) => setServerIdToNodeInterface(node, serverId));
    }

    public static async getObjectDefinitionById(nodeId: NodeId): Promise<ObjectDefinitionNode | undefined> {
        const node: ObjectDefinitionNode = await apiBundle(nodeId.serverId).objectsDefinition.getObjectDefinition({
            repositoryId: nodeId.repositoryId,
            objectDefinitionId: nodeId.id,
        });

        return setServerIdToNodeInterface(node, nodeId.serverId);
    }

    public static async createBulkObjectDefinitions(serverId: string, objectDefinitions: ObjectDefinitionNode[]) {
        return await apiBundle(serverId).objectsDefinition.createBulk({ body: objectDefinitions });
    }

    public static async getObjectDefinitionWithChildren(nodeId: NodeId): Promise<ObjectDefinitionNode[]> {
        const { serverId } = nodeId;
        const listNode: Node[] = await apiBundle(nodeId.serverId).tree.getAllChild({
            repositoryId: nodeId.repositoryId,
            id: nodeId.id,
        });
        const nodesId: NodeId[] = listNode.map((n) => n.nodeId);
        const objectDefinitions: ObjectDefinitionNode[] = await apiBundle(serverId).objectsDefinition.loadBulk({
            body: nodesId,
        });

        return objectDefinitions.map((node) => setServerIdToNodeInterface(node, serverId));
    }

    public static async saveObjectDefinition(
        serverId: string,
        objectDefinition: ObjectDefinitionNode,
    ): Promise<ObjectDefinitionNode> {
        const updatedObjectDefinition: ObjectDefinitionNode = await apiBundle(serverId).objectsDefinition.save({
            body: objectDefinition,
        });
        return setServerIdToNodeInterface(updatedObjectDefinition, serverId);
    }

    public static async getConnectedEdgeInstances(
        sourceObjectDefenitionNodeId: NodeId,
        targetObjectdefinitionId: string,
    ): Promise<ObjectConnection[]> {
        const { serverId, repositoryId, id } = sourceObjectDefenitionNodeId;

        const node: Node | undefined = await apiBundle(serverId).tree.getNode({
            repositoryId: repositoryId,
            nodeId: id,
        });

        const edgeInstances: ObjectConnection[] = findConnectedEdgeInstances(
            node as ObjectDefinitionImpl,
            targetObjectdefinitionId,
        );

        return edgeInstances;
    }
}
