import { TReducer } from '../../utils/types';
import { KanbanBoardType } from '../../serverapi/api';
import { CLEAR_PRESET_REQUEST } from '../../actionsTypes/methodologySetting.actionTypes';
import {
    IPresetSettingsKanbanModelTypeData,
    TPresetSettingsKanbanModelTypeState,
} from './presetSettingsKanbanModelType.reducer.types';
import {
    PRESET_SETTINGS_ADD_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_GET_ALL_KANBAN_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_SUBMIT_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_KANBAN_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsKanbanModelTypes.actionTypes';

export const PRESET_SETTINGS_INITIAL_KANBAN_MODEL_TYPE_SERVER_STATE: IPresetSettingsKanbanModelTypeData = {
    byId: {},
    kanbanModelTypesForDelete: [],
    kanbanModelTypesForSave: [],
};

const initial: TPresetSettingsKanbanModelTypeState = {
    byPresetId: {},
};

const mappingKanbanModelTypes = (
    kanbanModelTypes: KanbanBoardType[],
): {
    [id: string]: KanbanBoardType;
} => {
    const kanbanModelTypesMap = kanbanModelTypes.reduce(
        (
            result: {
                [id: string]: KanbanBoardType;
            },
            item: KanbanBoardType,
        ) => {
            result[item.id] = item;

            return result;
        },
        {},
    );

    return kanbanModelTypesMap;
};

const setNewData = (
    state: TPresetSettingsKanbanModelTypeState,
    presetId: string,
    newData: Partial<IPresetSettingsKanbanModelTypeData>,
): TPresetSettingsKanbanModelTypeState => {
    return {
        ...state,
        byPresetId: {
            ...state.byPresetId,
            [presetId]: {
                ...PRESET_SETTINGS_INITIAL_KANBAN_MODEL_TYPE_SERVER_STATE,
                ...state.byPresetId[presetId],
                ...newData,
            },
        },
    };
};

export const presetSettingsKanbanModelTypeReducer: TReducer<TPresetSettingsKanbanModelTypeState> = (
    state = initial,
    action,
) => {
    switch (action.type) {
        case PRESET_SETTINGS_GET_ALL_KANBAN_MODEL_TYPES_SUCCESS: {
            const { kanbanModelTypes, presetId } = action.payload;
            const kanbanModelTypesMap = mappingKanbanModelTypes(kanbanModelTypes);

            const newData: Partial<IPresetSettingsKanbanModelTypeData> = {
                byId: kanbanModelTypesMap,
                kanbanModelTypesForDelete: [],
                kanbanModelTypesForSave: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_ADD_KANBAN_MODEL_TYPE: {
            const { kanbanModelTypes, presetId } = action.payload;
            const kanbanModelTypesMap = mappingKanbanModelTypes(kanbanModelTypes);

            const newData: Partial<IPresetSettingsKanbanModelTypeData> = {
                byId: {
                    ...state.byPresetId[presetId]?.byId,
                    ...kanbanModelTypesMap,
                },
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_SUBMIT_KANBAN_MODEL_TYPE: {
            const { presetId } = action.payload;

            const newData: Partial<IPresetSettingsKanbanModelTypeData> = {
                kanbanModelTypesForDelete: [],
                kanbanModelTypesForSave: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_TRANSFER_KANBAN_MODEL_TYPE: {
            const { kanbanModelTypes, presetId } = action.payload;
            const kanbanModelTypesMap = mappingKanbanModelTypes(kanbanModelTypes);

            const newData: Partial<IPresetSettingsKanbanModelTypeData> = {
                byId: {
                    ...state.byPresetId[presetId]?.byId,
                    ...kanbanModelTypesMap,
                },
                kanbanModelTypesForSave: [...state.byPresetId[presetId]?.kanbanModelTypesForSave, ...kanbanModelTypes],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_DELETE_KANBAN_MODEL_TYPE: {
            const { kanbanModelTypesForDelete, presetId } = action.payload;
            const allKanbanModelTypes = state.byPresetId[presetId]?.byId || {};
            const filteredKanbanModelTypes = Object.keys(allKanbanModelTypes).reduce(
                (res: { [id: string]: KanbanBoardType }, id) => {
                    const findInDeleteList = kanbanModelTypesForDelete.some(
                        (kanbanModelType) => kanbanModelType.id === id,
                    );
                    if (!findInDeleteList) {
                        res[id] = allKanbanModelTypes[id];
                    }

                    return res;
                },
                {},
            );

            const newData: Partial<IPresetSettingsKanbanModelTypeData> = {
                byId: filteredKanbanModelTypes,
                kanbanModelTypesForDelete: [
                    ...state.byPresetId[presetId]?.kanbanModelTypesForDelete,
                    ...kanbanModelTypesForDelete,
                ],
            };

            return setNewData(state, presetId, newData);
        }

        case CLEAR_PRESET_REQUEST: {
            const { presetId } = action.payload;
            delete state.byPresetId?.[presetId];

            return { ...state };
        }

        default: {
            return state;
        }
    }
};
