import { defineMessages } from 'react-intl';

export default defineMessages({
    save: {
        id: 'MethodologySetting.save',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'MethodologySetting.cancel',
        defaultMessage: 'Отмена',
    },
    symbolTextEditorDialogTitle: {
        id: 'MethodologySetting.symbolTextEditorDialogTitle',
        defaultMessage: 'Текстовый редактор символов',
    },
});
