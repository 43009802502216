import { createSelector } from 'reselect';
import { TSpreadsheetState } from '../../reducers/entities/spreadsheet.reducer.types';
import { TRootState } from '../../reducers/root.reducer.types';
import { NodeId } from '../../serverapi/api';
import { ISpreadsheetNode } from '../../models/bpm/bpm-model-impl.types';

const spreadsheetStateSelector = (state: TRootState): TSpreadsheetState => state.spreadsheet;

export namespace SpreadsheetSelectors {
    export const byId = (nodeId?: NodeId) =>
        createSelector<TRootState, TSpreadsheetState, ISpreadsheetNode | undefined>(
            spreadsheetStateSelector,
            (state) => nodeId && state.get(nodeId),
        );
    export const getSpreadsheetNameByNodeId = (nodeId?: NodeId) =>
        createSelector<TRootState, ISpreadsheetNode | undefined, string | undefined>(
            byId(nodeId),
            (spreadsheet) => spreadsheet?.name,
        );
}
