import {
    ATTRIBUTE_TYPE_CREATE_REQUEST,
    ATTRIBUTE_TYPE_SAVE_REQUEST,
    ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS,
    ATTRIBUTE_TYPES_REQUEST_SUCCESS,
    DELETE_ATTRIBUTE_TYPE,
    DELETE_ATTRIBUTE_TYPES_AND_GROUPS,
} from '../actionsTypes/attributeType.actionTypes';
import { TPreset } from '../models/preset.types';
import { TreeNode } from '../models/tree.types';
import { AttributeType, AttributeTypeGroup } from '../serverapi/api';

export type TAttributeTypeDeleteRequestSuccessAction = {
    type: typeof ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS;
    payload: TDeleteAttributeTypePayload;
};

export type TAttributeTypeRequestSuccess = {
    type: typeof ATTRIBUTE_TYPES_REQUEST_SUCCESS;
    payload: {
        serverId: string;
        presetId: string;
        attributeTypes: AttributeType[];
    };
};

export type TSaveAttributeTypesPayload = {
    attributeTypes: AttributeType[];
    serverNode: TreeNode;
    preset: TPreset;
};

export type TSaveTAttributeTypeAction = {
    type: typeof ATTRIBUTE_TYPE_SAVE_REQUEST;
    payload: TSaveAttributeTypesPayload;
};

export const saveAttributeTypes = (payload: TSaveAttributeTypesPayload): TSaveTAttributeTypeAction => ({
    type: ATTRIBUTE_TYPE_SAVE_REQUEST,
    payload,
});

export type TCreateAttributeTypesPayload = {
    attributeType: AttributeType;
    serverNode: TreeNode;
    preset: TPreset;
};

export type TCreateAttributeTypeAction = {
    type: typeof ATTRIBUTE_TYPE_CREATE_REQUEST;
    payload: TCreateAttributeTypesPayload;
};

export const createAttributeTypes = (payload: TCreateAttributeTypesPayload): TCreateAttributeTypeAction => ({
    type: ATTRIBUTE_TYPE_CREATE_REQUEST,
    payload,
});

export type TDeleteAttributeTypePayload = {
    serverNode: TreeNode;
    attributeTypes: AttributeType[];
};

export type TDeleteTAttributeTypeAction = {
    type: typeof DELETE_ATTRIBUTE_TYPE;
    payload: TDeleteAttributeTypePayload;
};

export type TDeleteAttributeTypesAndGroupsPayload = {
    serverNode: TreeNode;
    attributeTypes: AttributeType[];
    attributeTypeGroups: AttributeTypeGroup[];
};

export type TDeleteAttributeTypesAndGroupsAction = {
    type: typeof DELETE_ATTRIBUTE_TYPES_AND_GROUPS;
    payload: TDeleteAttributeTypesAndGroupsPayload;
};

export type TAttributeTypeAction =
    | TAttributeTypeDeleteRequestSuccessAction
    | TDeleteTAttributeTypeAction
    | TCreateAttributeTypeAction
    | TAttributeTypeRequestSuccess
    | TSaveTAttributeTypeAction;
