import type { Level } from '@tiptap/extension-heading';
import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import { useSharedState } from '../UseSharedState.hook';
import { ParagraphStyleSelect } from '@/modules/MainMenu/components/ParagraphStyleSelect/ParagraphStyleSelect.component';
import { ControlsContext } from '../Controls.context';
import theme from '../../../Toolbar/Toolbar.scss';

const getFocusedBlock = (editor: Editor) =>
    (editor.isActive('heading', { level: 1 }) && 'H1') ||
    (editor.isActive('heading', { level: 2 }) && 'H2') ||
    (editor.isActive('heading', { level: 3 }) && 'H3') ||
    (editor.isActive('heading', { level: 4 }) && 'H4') ||
    (editor.isActive('heading', { level: 5 }) && 'H5') ||
    (editor.isActive('heading', { level: 6 }) && 'H6') ||
    'P';

const getChangedBlock = (editor: Editor, action: string) => {
    switch (action) {
        case 'P':
            return editor.commands.clearNodes();
        case 'H1':
        case 'H2':
        case 'H3':
        case 'H4':
        case 'H5':
        case 'H6':
            return editor
                .chain()
                .focus()
                .toggleHeading({ level: Number(action[1]) as Level })
                .run();
        default:
            return false;
    }
};

export const ParagraphStyle = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <div data-test="wiki-toolbar-group_select-paragraph-style">
            <ParagraphStyleSelect
                onChange={setCurrentState}
                value={value || ''}
                className={theme.selectParagraphStyle}
                renderNewOption={false}
                tooltipTitle={intl.formatMessage(messages.paragraphStyle)}
            />
        </div>
    );
};

