import { STYLE_MAIN_EDGE_ID, SequenceEdgeTypesId } from '@/mxgraph/SequenceGraph/SequenceConstants';
import { ObjectInstanceImpl, EdgeInstanceImpl, ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { SequenceGraph } from '@/mxgraph/SequenceGraph/SequenceGraph';
import { EdgeType } from '@/serverapi/api';
import { objectDefinitionService } from '@/services/ObjectDefinitionService';
import { MxCell, MxGeometry, MxCellState, MxConstants } from 'MxGraph';
import { ComplexSymbolManager } from '../../ComplexSymbolManager.class';
import MetaInfoSerializer from '../serializers/MetaInfoSerializer.class';
import { v4 as uuid } from 'uuid';
import MetaInfoComplexSymbol from '../MetaInfoComplexSymbol.class';
import SequenceUtils from '../Sequence/sequence.utils';
import LifeLineUtils from './LifeLine.utils';

export abstract class LifelineSymbolMainClass extends MetaInfoComplexSymbol {
    headerSize: number;
    minLineHeight = 20;
    graph: SequenceGraph;
    serializer = new MetaInfoSerializer();

    abstract template: string;

    public convertValueToString(cell: MxCell): string {
        const objectDefinitionId: string | undefined = (cell.getValue() as ObjectInstanceImpl | undefined)
            ?.objectDefinitionId;

        if (!objectDefinitionId) return '';

        const objectDefinition: ObjectDefinitionImpl | undefined= objectDefinitionService().getObjectDefinition({
            ...this.graph.id,
            id: objectDefinitionId,
        });

        return objectDefinition?.name || '';
    }

    public getCopyableCell(): MxCell | null {
        return this.rootCell;
    }

    public addExecutionSymbol(parent: MxCell | undefined) {
        if (parent) {
            const { triggerY }: { triggerY: number } = this.graph.popupMenuHandler;
            const { y, width }: MxGeometry = parent.geometry;
            const isSequenceExecutionSymbol: boolean = SequenceUtils.isSequenceExecutionSymbol(parent);

            const lineWidth: number = 10;
            let lineHeight: number = 50;
            let currentX: number = (width - lineWidth) / 2;
            let currentY: number = Math.max(triggerY - y, this.headerSize);

            if (isSequenceExecutionSymbol) {
                lineHeight = Math.max(parent.geometry.height / 2, this.minLineHeight);
                currentX = lineWidth / 2 + 2;
                currentY = lineHeight / 2;
                if (parent?.children?.length) {
                    parent.children.forEach((child: MxCell) => {
                        currentY = Math.max(currentY, child.geometry.y + child.geometry.height);
                    });
                    currentY += 10;
                }
            }

            const cell: MxCell = this.graph.insertVertex(
                parent,
                uuid(),
                '',
                currentX,
                currentY,
                lineWidth,
                lineHeight,
                'shape=rect;whiteSpace=wrap;fillColor=#87CEEB;editable=0;',
            );
            cell.frame = parent.frame;
            cell.complexSymbolRef = parent.complexSymbolRef;
        }
    }

    public startConnection(source: MxCell, edgeType?: EdgeType, availableEdgeType?: EdgeType[]) {
        const frameParent: MxCell | undefined = ComplexSymbolManager.getComplexSymbolRootCell(source);
        if (frameParent) {
            const edgeStyle: string = edgeType?.edgeStyle || '';
            const cellState: MxCellState = this.graph.view.getState(frameParent);
            const target: string | null =
                SequenceEdgeTypesId.RECURSIVE_MESSAGE === edgeType?.id ? frameParent.id : null;
            const edgeInstance: EdgeInstanceImpl = new EdgeInstanceImpl({
                id: uuid(),
                style: edgeStyle,
                edgeType,
                edgeTypeId: edgeType?.id,
                source: frameParent.id,
                target,
                name: '',
                invisible: false,
            });

            const edge: MxCell = new MxCell(edgeInstance, new MxGeometry(), edgeStyle);
            edge.setEdge(true);
            const edgeState: MxCellState = this.graph.view.createState(edge);
            edgeState.style[MxConstants.STYLE_ROUNDED] = false;
            this.graph.connectionHandler.start(cellState, null, null, edgeState, availableEdgeType);
        }
    }

    public startForkConnection(sourceEdge: MxCell) {
        const { source } = sourceEdge;
        const { edgeType } = sourceEdge.value;
        const frameParent = ComplexSymbolManager.getComplexSymbolRootCell(source);
        if (frameParent) {
            const edgeStyle = `${edgeType?.edgeStyle || ''}${STYLE_MAIN_EDGE_ID}=${sourceEdge.value.id};`;
            const cellState = this.graph.view.getState(frameParent);
            const edgeInstance = new EdgeInstanceImpl({
                id: uuid(),
                style: edgeStyle,
                edgeType,
                edgeTypeId: edgeType?.id,
                source: frameParent.id,
                target: null,
                name: '',
                invisible: false,
            });

            const edge: MxCell = new MxCell(edgeInstance, new MxGeometry(), edgeStyle);
            edge.setEdge(true);
            const edgeState: MxCellState = this.graph.view.createState(edge);
            edgeState.style[MxConstants.STYLE_ROUNDED] = false;
            edgeState.style[STYLE_MAIN_EDGE_ID] = `${sourceEdge.value.id}`;
            this.graph.connectionHandler.start(cellState, null, null, edgeState, [edgeType], sourceEdge);
        }
    }

    public createDestroySymbol(parent: MxCell | undefined) {
        if (parent) {
            const parentHeight: number = parent.geometry.height;
            const parentWidth: number = parent.geometry.width;
            const width: number = 25;
            const height: number = 25;

            const currentX: number = (parentWidth - width) / 2;
            const currentY: number = parentHeight + width / 2;

            const cell: MxCell = this.graph.insertVertex(
                parent,
                LifeLineUtils.getDestroySymbolId(parent),
                '',
                currentX,
                currentY,
                width,
                height,
                'shape=sequenceDestroyAttribute;connectable=0;editable=0;resizable=0;movable=0;selectable=0;',
            );
            cell.setConnectable(false);
            cell.frame = parent.frame;
        }
    }

    public deleteDestroySymbol(parent: MxCell) {
        const cell: MxCell = this.graph.getModel().getCell(LifeLineUtils.getDestroySymbolId(parent));
        this.graph.removeCells([cell]);
    }

    public splitExecutionSymbol(initCell: MxCell) {
        this.graph.graphHandler.splitCell(initCell);
    }
}
