import { CONTEXT_MENU_CHANGE_PARENT, CONTEXT_MENU_CLEAR_PARENT } from '../actionsTypes/TreeItem.actionsTypes';
import { ACTIVE_CONTEXT_MENU_CHANGE } from '../actionsTypes/contextMenu.actionTypes';
import { TreeNode } from '../models/tree.types';
import { NodeId } from '../serverapi/api';
import {
    TClearContextMenuParentIdAction,
    TSetContextMenuParentIdAction,
    TContextMenuChangeAction,
} from './contextMenu.actions.types';

export const activeContextMenuChange = (selectedNode?: TreeNode, treeName?: string): TContextMenuChangeAction => ({
    type: ACTIVE_CONTEXT_MENU_CHANGE,
    payload: {
        selectedNode,
        treeName,
    },
});

export const setContextMenuParentId = (
    parentId: NodeId,
    contextMenuAreaName: string,
): TSetContextMenuParentIdAction => ({
    type: CONTEXT_MENU_CHANGE_PARENT,
    payload: {
        parentId,
        contextMenuAreaName,
    },
});

export const clearContextMenuParentId = (): TClearContextMenuParentIdAction => {
    return {
        type: CONTEXT_MENU_CLEAR_PARENT,
    };
};
