import type { TMessage } from '../../utils/forms.types';
import type {
    TPayloadObjectDecompositionIconClicked,
    TObjectDecompositionIconClicked,
    TPayloadObjectDecompositionDialogInit,
    TObjectDecompositionDialogInit,
    TPayloadObjectDecompositionDialogSubmit,
    TObjectDecompositionDialogSubmit,
    TObjectDecompositionDialogError,
    TPayloadObjectDecompositionOpen,
    TObjectDecompositionOpen,
    TObjectDecompositionChooseObjectDialogSubmit,
} from './objectDecomposition.actions.types';
import {
    OBJECT_DECOMPOSITION_DIALOG_SUBMIT,
    OBJECT_DECOMPOSITION_DIALOG_ERROR,
    OBJECT_DECOMPOSITION_DIALOG_INIT,
    OBJECT_DECOMPOSITION_ICON_CLICKED,
    OBJECT_DECOMPOSITION_OPEN,
    OBJECT_DECOMPOSITION_CHOOSE_OBJECT_DIALOG_SUBMIT,
} from '../../actionsTypes/entities/objectDecomposition.actionTypes';

export const objectDecompositionDialogInit = (
    payload: TPayloadObjectDecompositionDialogInit,
): TObjectDecompositionDialogInit => ({
    type: OBJECT_DECOMPOSITION_DIALOG_INIT,
    payload,
});

export const objectDecompositionDialogSubmit = (
    payload: TPayloadObjectDecompositionDialogSubmit,
): TObjectDecompositionDialogSubmit => ({
    type: OBJECT_DECOMPOSITION_DIALOG_SUBMIT,
    payload,
});

export const objectDecompositionIconClicked = (
    payload: TPayloadObjectDecompositionIconClicked,
): TObjectDecompositionIconClicked => ({
    type: OBJECT_DECOMPOSITION_ICON_CLICKED,
    payload,
});

export const objectDecompositionOpen = (payload: TPayloadObjectDecompositionOpen): TObjectDecompositionOpen => ({
    type: OBJECT_DECOMPOSITION_OPEN,
    payload,
});

export const objectDecompositionDialogError = (error?: TMessage): TObjectDecompositionDialogError => ({
    type: OBJECT_DECOMPOSITION_DIALOG_ERROR,
    payload: {
        error,
    },
});

export const objectDecompositionChooseObjectDialogSubmit = (payload): TObjectDecompositionChooseObjectDialogSubmit => ({
    type: OBJECT_DECOMPOSITION_CHOOSE_OBJECT_DIALOG_SUBMIT,
    payload,
});
