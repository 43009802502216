import { TReducer } from '../utils/types';
import { CREATE_EVENT_SUCCESS, DELETE_EVENT_SUCCESS, EVENT_DIALOG_ADD_NODE_ID, EVENT_DIALOG_ADD_NODE_PATH, EVENT_DIALOG_DELETE_NODE_ID, EVENT_DIALOG_DELETE_PARENT_NODE_ID, EVENT_DIALOG_DELETE_SCRIPT_NODE_ID, FETCH_ALL_EVENTS, FETCH_EVENTS_SUCCESS, UPDATE_EVENT_SUCCESS } from '../actionsTypes/eventAccess.actionTypes';
import { TEventAccessState } from './eventsAccess.reducer.types';
import { NodeId } from '../serverapi/api';

const initial: TEventAccessState = {
    byServerId: {},
    nodeParams: {
        nodeId: {},
        parentNodeId: {},
        scriptId: {},
    },
};

export const eventsAccessReducer: TReducer<TEventAccessState> = (state = initial, action) => {
    switch (action.type) {
        case FETCH_ALL_EVENTS: {
            return {
                ...state,
            };
        }

        case FETCH_EVENTS_SUCCESS: {
            const { events, serverId } = action.payload;
            const eventsMap = events.reduce((result: {}, item) => ({ ...result, [item.id || 0]: item }), {});

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...(state.byServerId[serverId] || {}),
                        byId: {
                            ...(state.byServerId[serverId] ? state.byServerId[serverId].byId : {}),
                            ...eventsMap,
                        },
                    },
                },
            };
        }



        case DELETE_EVENT_SUCCESS: {
            const { id, serverId } = action.payload;
            const byId = { ...state.byServerId[serverId].byId };

            delete byId[id];

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...state.byServerId[serverId],
                        byId: {
                            ...byId,
                        },
                    },
                },
            };
        }
        
        case CREATE_EVENT_SUCCESS:
        case UPDATE_EVENT_SUCCESS: {
            const { event, serverId } = action.payload;
            const byIdNewEvent = { ...state.byServerId[serverId].byId, [event.id]: event };
            
            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...state.byServerId[serverId],
                        byId: {
                            ...(state.byServerId[serverId] ? state.byServerId[serverId].byId : {}),
                            ...byIdNewEvent,
                        },
                    },
                },
            };
        }

        case EVENT_DIALOG_ADD_NODE_ID: {
            const { paramName, nodeId } = action.payload;

            return {
                ...state,
                nodeParams: {
                    ...state.nodeParams,
                    [paramName]: {
                        ...state.nodeParams[paramName],
                        nodeId,
                    },
                },
            };
        }

        case EVENT_DIALOG_ADD_NODE_PATH: {
            const { paramName, nodePath } = action.payload;

            return {
                ...state,
                nodeParams: {
                    ...state.nodeParams,
                    [paramName]: {
                        ...state.nodeParams[paramName],
                        path: nodePath,
                    },
                },
            };
        }

        case EVENT_DIALOG_DELETE_NODE_ID: {
            const { nodeIdWasCleared } = action.payload;

            return {
                ...state,
                nodeParams: {
                    ...state.nodeParams,
                    nodeId: {
                        nodeId: nodeIdWasCleared ? {} as NodeId : undefined,
                        path: nodeIdWasCleared ? '' : undefined,
                    },
                },
            };
        }
      
        case EVENT_DIALOG_DELETE_PARENT_NODE_ID: {
            const { nodeIdWasCleared } = action.payload;

            return {
                ...state,
                nodeParams: {
                    ...state.nodeParams,
                    parentNodeId: {
                        nodeId: nodeIdWasCleared ? {} as NodeId  : undefined,
                        path: nodeIdWasCleared ? '' : undefined,
                    },
                },
            };
        }

        case EVENT_DIALOG_DELETE_SCRIPT_NODE_ID: {
            const { nodeIdWasCleared } = action.payload;

            return {
                ...state,
                nodeParams: {
                    ...state.nodeParams,
                    scriptId: {
                        nodeId: nodeIdWasCleared ? {} as NodeId : undefined,
                        path: nodeIdWasCleared ? '' : undefined,
                    },
                },
            };
        }

        default:
            return state;
    }
};
