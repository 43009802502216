import React, { useCallback, useMemo } from 'react';
import { Table } from 'antd';
import messages from '../messages/ObjectPropertiesDialog.messages';
import theme from './ObjectPropertiesDialog.scss';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { TTableData } from './MethodologyChangeTab.types';
import { SortOrder } from 'antd/es/table/interface';
import { setRowInFirstPositionByKey } from './utils/methodologyChangeTabTable.utils';

type TMethodologyChangeTabTableProps = {
    presetId?: string;
    dataSource: TTableData[];
    onChangePresetId: (presetId: string) => void;
};

type TColumnData = {
    title: string;
    dataIndex: string;
    key: string;
    sorter?: (a: TTableData, b: TTableData, sortOrder: SortOrder) => number;
    render?: (name: string) => JSX.Element;
    defaultSortOrder?: SortOrder;
};

export const MethodologyChangeTabTable = (props: TMethodologyChangeTabTableProps) => {
    const { dataSource, presetId, onChangePresetId } = props;
    const intl = useIntl();

    const rowClassName = useCallback(
        (record: TTableData) => (record.key === presetId ? theme.highlight : ''),
        [presetId],
    );

    const columns: TColumnData[] = useMemo(
        () => [
            {
                title: intl.formatMessage(messages.methodologyName),
                dataIndex: 'name',
                key: 'name',
                sorter: (a: TTableData, b: TTableData, sortOrder: SortOrder) => {
                    return setRowInFirstPositionByKey(a, b, sortOrder, presetId || '') || a.name.localeCompare(b.name);
                },
                render: (name: string) => <div data-test="edit-DB-methodology-table_name">{name}</div>,
            },
            {
                title: intl.formatMessage(messages.desc),
                dataIndex: 'desc',
                key: 'desc',
            },
            {
                title: intl.formatMessage(messages.changeDate),
                dataIndex: 'changeDate',
                key: 'changeDate',
                sorter: (a: TTableData, b: TTableData, sortOrder: SortOrder) => {
                    return (
                        setRowInFirstPositionByKey(a, b, sortOrder, presetId || '') ||
                        (dayjs(a.changeDate, 'DD.MM.YYYY HH:mm:ss').valueOf() >
                        dayjs(b.changeDate, 'DD.MM.YYYY HH:mm:ss').valueOf()
                            ? 1
                            : -1)
                    );
                },
                render: (date: string) => <div data-test="edit-DB-methodology-table_date">{date}</div>,
                defaultSortOrder: 'descend',
            },
        ],
        [presetId],
    );

    const onRow = useCallback(
        (row: { key: string }) => ({
            onClick: () => onChangePresetId(row.key),
        }),
        [],
    );

    return (
        <Table
            rowClassName={rowClassName}
            dataSource={dataSource}
            className={theme.table}
            onRow={onRow}
            columns={columns}
            sortDirections={['ascend', 'descend', 'ascend']}
            size="middle"
            bordered
            pagination={false}
            scroll={{ y: '' }}
        />
    );
};
