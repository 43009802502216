import { Menu, Popover } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appExit } from '../../../../actions/app.actions';
import messages from './Logo.messages';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import iLogo from '../../../../resources/icons/ic-logo.svg';
import classnames from 'classnames';
import { AppExitMethod } from '../../../../models/app';
import style from './Logo.scss';
import { LogoContextMenuItems } from './logo.types';

type TLogoProps = {
    isStarted: boolean;
    clientIcon: string | null;
};

const Logo = (props: TLogoProps) => {
    const { isStarted, clientIcon } = props;
    const [show, setShow] = useState<boolean>(false);
    const dispatch = useDispatch();

    const popoverRef = useCallback((node) => {
        if (node?.state?.prevPopupVisible) setShow(false);
    }, []);

    const handleClickMenuItem = (item: MenuInfo) => {
        if (item.key === LogoContextMenuItems.EXIT) dispatch(appExit(AppExitMethod.Logout));
    };

    const logo = (
        <div
            onClick={() => setShow(true)}
            className={classnames(style.title, { [style.disableHover as string]: !isStarted })}
            data-test="logo"
        >
            <Icon className={style.titleContentIcon} spriteSymbol={iLogo} />
            { clientIcon && <>
                <div className={style.clientIconWrapper}>
                    <div className={style.verticalSeparator} />
                    <img src={clientIcon} />
                </div>
            </>
            }
        </div>
    );

    const overlay = (
        <Menu className={style.menu} onClick={handleClickMenuItem}>
            <Menu.Item data-test="exit" key={LogoContextMenuItems.EXIT}>
                <FormattedMessage id={messages.exit.id} />
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {isStarted ? (
                <Popover
                    ref={popoverRef}
                    overlayClassName={style.menuContainer}
                    mouseLeaveDelay={0.5}
                    content={overlay}
                    trigger={show ? 'hover' : 'click'}
                >
                    {logo}
                </Popover>
            ) : (
                <>{logo}</>
            )}
        </>
    );
};

const withMemo = memo(Logo);

export { withMemo as Logo };
