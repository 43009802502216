import type { InternationalString } from '../../../../../../../serverapi/api';
import React, { useMemo } from 'react';
import { Select, Form } from 'antd';
import { useIntl } from 'react-intl';
import { getAntdPopupContainer } from '../../../../../../../utils/getAntdPopupContainer';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import messages from './AttributeCustomText.messages';
import theme from './AttributeCustomText.scss';

type TAttributeCustomTextProps = {
    multiLangValue?: InternationalString;
    styleId?: string;
    disabled?: boolean;
    onlyCustom?: boolean;
    useReplacementText?: boolean;
    changeAttributeCustomText: (text: InternationalString, styleId: string, flag: boolean) => void;
    changeUseReplacementText: (flag: boolean) => void;
};

enum AttributeTextOptions {
    attrValue,
    customText,
}

export const AttributeCustomText = ({
    multiLangValue,
    styleId,
    disabled,
    onlyCustom,
    useReplacementText,
    changeAttributeCustomText,
    changeUseReplacementText,
}: TAttributeCustomTextProps) => {
    const intl = useIntl();
    const [generalForm] = Form.useForm();
    const curentStyleId = styleId || '';
    const options = useMemo(
        () =>
            onlyCustom
                ? [
                      {
                          value: AttributeTextOptions.customText,
                          message: intl.formatMessage(messages.customText),
                          dataTest: 'select_custom-text',
                      },
                  ]
                : [
                      {
                          value: AttributeTextOptions.attrValue,
                          message: intl.formatMessage(messages.attrValue),
                          dataTest: 'select_attribute-value',
                      },
                      {
                          value: AttributeTextOptions.customText,
                          message: intl.formatMessage(messages.customText),
                          dataTest: 'select_custom-text',
                      },
                  ],
        [onlyCustom],
    );

    const optionValue = useMemo(() => {
        if (onlyCustom || useReplacementText) return AttributeTextOptions.customText;

        return AttributeTextOptions.attrValue;
    }, [onlyCustom, useReplacementText]);

    const onChangeTextHandler = (multiLang: InternationalString) => {
        const hasValue = !!(multiLang.en || multiLang.ru);

        changeAttributeCustomText(multiLang, curentStyleId, hasValue);
    };

    const handleChangeTextType = (textTypeId: number) => {
        changeUseReplacementText(textTypeId === AttributeTextOptions.customText);
    };

    return (
        <div className={theme.editableTextContainer}>
            <Select
                data-test="rule_text_setting"
                className={theme.textTypeSelect}
                value={optionValue}
                disabled={disabled}
                onChange={handleChangeTextType}
                getPopupContainer={getAntdPopupContainer}
            >
                {options.map((option) => (
                    <Select.Option data-test={option.dataTest} key={option.value} value={option.value}>
                        {option.message}
                    </Select.Option>
                ))}
            </Select>
            {optionValue === AttributeTextOptions.customText && (
                <>
                    <div className={theme.inputLabel}>{intl.formatMessage(messages.text)}</div>
                    <div className={theme.formContainer}>
                        <Form form={generalForm} layout="horizontal">
                            <MultiLangInputDialog
                                required
                                disabled={disabled}
                                multiLangValue={multiLangValue}
                                onChange={onChangeTextHandler}
                                formItemClassName={theme.multiLangFormItem}
                                generalForm={generalForm}
                                autoFocus
                                label=""
                                mainInputName="mainInput"
                                data-test='rule_custom-text_input'
                            />
                        </Form>
                    </div>
                </>
            )}
        </div>
    );
};
