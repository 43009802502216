// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChoosePrincipalsPanel__table__DgrNd .ant-table-tbody>tr>td{padding-top:0;padding-bottom:0}.ChoosePrincipalsPanel__table__DgrNd .ant-table-placeholder{display:none}.ChoosePrincipalsPanel__table__DgrNd .ant-table-thead>tr>th{padding-top:0;padding-bottom:0;background-color:var(--bright_bg)}.ChoosePrincipalsPanel__formGroup__IlaSZ{padding-bottom:10px}", "",{"version":3,"sources":["webpack://./src/modules/Permissions/components/ChoosePrincipalsPanel/ChoosePrincipalsPanel.scss"],"names":[],"mappings":"AAEQ,4DACI,aAAA,CACA,gBAAA,CAEJ,4DACI,YAAA,CAEJ,4DACI,aAAA,CACA,gBAAA,CACA,iCAAA,CAKZ,yCACI,mBAAA","sourcesContent":[".table {\r\n    :global {\r\n        .ant-table-tbody > tr > td {\r\n            padding-top: 0;\r\n            padding-bottom: 0;\r\n        }\r\n        .ant-table-placeholder {\r\n            display: none;\r\n        }\r\n        .ant-table-thead > tr > th {\r\n            padding-top: 0;\r\n            padding-bottom: 0;\r\n            background-color: var(--bright_bg);\r\n        }\r\n    }\r\n}\r\n\r\n.formGroup {\r\n    padding-bottom: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "ChoosePrincipalsPanel__table__DgrNd",
	"formGroup": "ChoosePrincipalsPanel__formGroup__IlaSZ"
};
export default ___CSS_LOADER_EXPORT___;
