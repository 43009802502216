import React, { ChangeEvent } from "react";
import { Form, Input } from 'antd';
import messages from './InputSynonymsIds.messages';
import { IntlShape, useIntl } from 'react-intl';
import theme from './InputSynonymsIds.scss';
import { separator } from "../../utils/convertStringToArray";

type TInputSynonymsIdsProps = {
    value?: string[];
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    mainInputName: string;
    mainInputClassName?: string;
    wrapperClassName?: string;
}

export const InputSynonymsIds = (props: TInputSynonymsIdsProps) => {
    const { value, onChange, mainInputName, mainInputClassName, wrapperClassName } = props;
    const intl: IntlShape = useIntl();

    return (
        <div className={wrapperClassName}>
            <Form.Item
                className = {theme.formItem} 
                label = {intl.formatMessage(messages.synonymsIds)}
                name = {mainInputName}
                initialValue = {value?.join(`${separator} `) || ''}
                
            >
                <Input className={mainInputClassName}  onChange={onChange}/>
            </Form.Item>
        </div>
    );
};