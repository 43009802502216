import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'PsdTableDeleteConfirmationDialog.title',
        defaultMessage: 'Удаление строки/столбца',
    },

    removeRowText: {
        id: 'PsdTableDeleteConfirmationDialog.removeRowText',
        defaultMessage: 'Вы точно хотите удалить строку?',
    },

    removeColumnText: {
        id: 'PsdTableDeleteConfirmationDialog.removeColumnText',
        defaultMessage: 'Вы точно хотите удалить колонку?',
    },

    removeChildrenRowText: {
        id: 'PsdTableDeleteConfirmationDialog.removeChildrenRowText',
        defaultMessage: 'Удаляемая строка содержит дочерние элементы, точно удалить?',
    },

    removeChildrenColumnText: {
        id: 'PsdTableDeleteConfirmationDialog.removeChildrenColumnText',
        defaultMessage: 'Удаляемый столбец содержит дочерние элементы, точно удалить?',
    },

    cancelText: {
        id: 'PsdTableDeleteConfirmationDialog.cancelText',
        defaultMessage: 'Отмена',
    },

    submitText: {
        id: 'PsdTableDeleteConfirmationDialog.submitText',
        defaultMessage: 'Удалить',
    },
});
