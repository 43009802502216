import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TScriptSchedulerReducerState } from '../reducers/scriptScheduler.reducer.types';
import { TSchedulerTaskWithName } from '../actions/scriptScheduler.actions.types';

const scriptSchedulerStateSelector = (state: TRootState): TScriptSchedulerReducerState => state.scriptScheduler;

export namespace ScriptSchedulerSelectors {
    export const getAllData = (serverId: string) =>
        createSelector(scriptSchedulerStateSelector, (allServerListTasks) => allServerListTasks?.[serverId] || {});

    export const getTaskList = (serverId: string) => (state: TRootState) => state.scriptScheduler?.[serverId]?.taskList;

    export const getTaskListSortByTimeStart = (serverId: string) =>
        createSelector(getTaskList(serverId), (taskList: TSchedulerTaskWithName[]) =>
            taskList?.sort((x, y) => Date.parse(`${y.timeStart}`) - Date.parse(`${x.timeStart}`)),
        );

    export const getisLoading = (serverId: string) => (state: TRootState) =>
        state.scriptScheduler?.[serverId]?.isLoading;
}
