import {  ApprovalTemplateDTO } from '@/serverapi/api';
import { TApprovalTemplates } from '@/reducers/approvalTemplates.reducer.types';


export const getApprovalTemplatesArray = (byId: TApprovalTemplates): ApprovalTemplateDTO[] => {
    return Object.values(byId)
        .flat();
};

export const getApprovalTemplatesIds = (byId: TApprovalTemplates): string[] => {
    return Object.values(byId).map((template: ApprovalTemplateDTO) => template.id);
};
