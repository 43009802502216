// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WhiteboardSidebar__whiteboardSidebar__xQWZV{height:100%;border-radius:4px;overflow:hidden;width:100%;position:relative;left:0;top:0}.WhiteboardSidebar__whiteboardContainer__teI4Q{position:static !important;position:initial !important}", "",{"version":3,"sources":["webpack://./src/modules/Navigator/components/WhiteboardSidebar/WhiteboardSidebar.scss"],"names":[],"mappings":"AAAA,6CACI,WAAA,CACA,iBAAA,CACA,eAAA,CACA,UAAA,CACA,iBAAA,CACA,MAAA,CACA,KAAA,CAGJ,+CACI,0BAAA,CAAA,2BAAA","sourcesContent":[".whiteboardSidebar {\r\n    height: 100%;\r\n    border-radius: 4px;\r\n    overflow: hidden;\r\n    width: 100%;\r\n    position: relative;\r\n    left: 0;\r\n    top: 0;\r\n}\r\n\r\n.whiteboardContainer {\r\n    position: initial !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whiteboardSidebar": "WhiteboardSidebar__whiteboardSidebar__xQWZV",
	"whiteboardContainer": "WhiteboardSidebar__whiteboardContainer__teI4Q"
};
export default ___CSS_LOADER_EXPORT___;
