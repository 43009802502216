export const FLOATING_ATTRIBUTES_SET_STATE = 'FLOATING_ATTRIBUTES_SET_STATE';

export const FLOATING_ATTRIBUTES_SWITCH_PRESET_STYLES = 'FLOATING_ATTRIBUTES_SWITCH_PRESET_STYLES';

export const FLOATING_ATTRIBUTES_SELECT_AREA = 'FLOATING_ATTRIBUTES_SELECT_AREA';

export const FLOATING_ATTRIBUTES_ADD_STYLE = 'FLOATING_ATTRIBUTES_ADD_STYLE';

export const FLOATING_ATTRIBUTES_SELECT_STYLE = 'FLOATING_ATTRIBUTES_SELECT_STYLE';

export const FLOATING_ATTRIBUTES_CHANGE_TYPE = 'FLOATING_ATTRIBUTES_CHANGE_TYPE';

export const FLOATING_ATTRIBUTES_REMOVE_STYLE = 'FLOATING_ATTRIBUTES_REMOVE_STYLE';

export const FLOATING_ATTRIBUTES_CHANGE_RULES = 'FLOATING_ATTRIBUTES_CHANGE_RULES';

export const FLOATING_ATTRIBUTES_CHANGE_FORMAT = 'FLOATING_ATTRIBUTES_CHANGE_FORMAT';

export const FLOATING_ATTRIBUTES_CHANGE_TEXT_STYLE = 'FLOATING_ATTRIBUTES_CHANGE_TEXT_STYLE';

export const FLOATING_ATTRIBUTES_CHANGE_IMAGE_STYLE = 'FLOATING_ATTRIBUTES_CHANGE_IMAGE_STYLE';

export const FLOATING_ATTRIBUTES_CLEAR_STATE = 'FLOATING_ATTRIBUTES_CLEAR_STATE';

export const FLOATING_ATTRIBUTES_CHANGE_AREA_ATTRIBUTE_WRAP = 'FLOATING_ATTRIBUTES_CHANGE_AREA_ATTRIBUTE_WRAP';
