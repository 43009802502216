import React from 'react';
import theme from './TableHeadControl.component.scss';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import BlockTree from './blockTree';
import { useIntl } from 'react-intl';
import messages from './TableRenderer.messages';

const TableHeadControl = ({ getEditor, tableKey, columnIndex }) => {
    const editor = getEditor();
    const { editorState } = editor.state;
    const { onChange } = editor;
    const intl = useIntl();
    const { formatMessage } = intl;
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <div>{formatMessage(messages.sortAZ)}</div>,
            onClick: (el) => {
                const blockTree = new BlockTree(editorState, onChange);

                blockTree.sortColumn(tableKey, columnIndex);
            },
        },
    ];

    return (
        <div className={theme.control}>
            <Dropdown destroyPopupOnHide menu={{ items }} trigger={['click']}>
                <Space>
                    <DownOutlined className={theme.arrow} />
                </Space>
            </Dropdown>
        </div>
    );
};

export default TableHeadControl;
