import { defineMessages } from 'react-intl';

export default defineMessages({
    modelNotFoundError: {
        id: 'CreateModelTemplateDialog.modelNotFoundError',
        defaultMessage: 'Модель не найдена',
    },
    title: {
        id: 'CreateModelTemplateDialog.title',
        defaultMessage: 'Создание шаблона модели',
    },
    confirm: {
        id: 'CreateModelTemplateDialog.confirm',
        defaultMessage: 'Создать',
    },
    cancel: {
        id: 'CreateModelTemplateDialog.cancel',
        defaultMessage: 'Отмена',
    },
    modelTemplateNamePlaceholder: {
        id: 'CreateModelTemplateDialog.modelTemplateNamePlaceholder',
        defaultMessage: 'Имя нового шаблона модели',
    },
    modelTemplateNameLabel: {
        id: 'CreateModelTemplateDialog.modelTemplateNameLabel',
        defaultMessage: 'Имя шаблона',
    },
});

