import { useAutoSave } from '@/hooks/useAutoSave';
import { EditorMode } from '@/models/editorMode';
import { TReportTabType } from '@/models/tab.types';
import { Icon } from '@/modules/UIKit';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import addIcon from '@/resources/icons/Add_Other.svg';
import noData from '@/resources/icons/noData.svg';
import { NodeId, ReportColumnData, ReportData, ReportDataFillingTypeEnum, ReportNode } from '@/serverapi/api';
import { xor } from 'lodash-es';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchNodesWithAttributes,
    reportCreateNewColumn,
    reportDropNodes,
    reportSaveRequest,
    reportSearchRequest,
    reportSelectColumn,
    reportSetSearchValue,
} from '../actions/report.actions';
import { ReportEditorSidePanel } from './ReportEditorSidebar/ReportEditorSidePanel.component';
import { ReportSelectors } from '../selectors/report.selectors';
import theme from './ReportEditor.scss';
import messages from './ReportEditor.messages';
import { resetSearchData } from '@/actions/search.actions';
import { Spinner } from '@/modules/Spinner/Spinner.component';
import { ReportEditorFilterBar } from './ReportEditorFilterBar/ReportEditorFilterBar.component';
import { EllipsisText } from '@/modules/AdminTools/Methodology/components/MethodologySettingPage/EllipsisText/EllipsisText.component';
import { CellMeasurer } from 'react-virtualized';
import { isEqual } from 'lodash-es';

type TReportEditorProps = {
    tab: TReportTabType;
};

type TReportColumn = TColumn & {
    columnId?: string;
    selected?: boolean;
};

const COLUMN_WIDTH = 160;

export const ReportEditor: FC<TReportEditorProps> = ({ tab: { nodeId, mode } }) => {
    const report: ReportNode | undefined = useSelector(ReportSelectors.byId(nodeId));
    const reportData: ReportData | undefined = report?.reportData;
    const isReadMode: boolean = mode === EditorMode.Read;
    const reportColumns: ReportColumnData[] = reportData?.columns || [];
    const manuallyFilledNodes: NodeId[] = reportData?.manuallyFilledNodes || [];
    const selectedColumnId: string | undefined = useSelector(ReportSelectors.selectedColumnId(nodeId));
    const loading: boolean = useSelector(ReportSelectors.isLoading(nodeId));
    const fillingType: ReportDataFillingTypeEnum = useSelector(ReportSelectors.fillingType(nodeId));
    const searchValue: string = useSelector(ReportSelectors.searchValue(nodeId));

    const [checedIds, setCheckedIds] = useState<string[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>();

    const tableData: TTableData[] = useSelector(
        ReportSelectors.getReportTableData(nodeId, manuallyFilledNodes, checedIds),
        isEqual,
    );

    const dispatch = useDispatch();
    const intl = useIntl();

    const saveReportHandler = () => {
        if (report) dispatch(reportSaveRequest(report));
    };

    useAutoSave({ isEditMode: !isReadMode, nodeId, dependences: [report], callback: saveReportHandler });

    useEffect(() => {
        if (reportData?.fillingType === 'MANUAL') {
            dispatch(fetchNodesWithAttributes(manuallyFilledNodes, true));
        }

        return () => {
            dispatch(resetSearchData(nodeId));
        };
    }, []);

    useEffect(() => {
        if (reportData?.searchRequests && reportData.fillingType === 'AUTO') {
            dispatch(reportSearchRequest(nodeId, reportData.searchRequests));
        }
        setCheckedIds([]);
    }, [reportData?.searchRequests, reportData?.fillingType]);

    useEffect(() => {
        if (searchValue === '') {
            setCurrentIndex(undefined);
        } else {
            setCurrentIndex(0);
        }
    }, [searchValue]);

    useEffect(() => {
        if (isReadMode) {
            setCheckedIds([]);
        }
    }, [isReadMode]);

    const handleAddColumn = () => {
        dispatch(reportCreateNewColumn(nodeId));
    };

    const handleSetSearchValue = (searchValue: string) => {
        dispatch(reportSetSearchValue(nodeId, searchValue));
    };

    const columns: TReportColumn[] = reportColumns
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((reportColumn) => {
            const isColSelected: boolean = reportColumn.columnId === selectedColumnId;

            return {
                title: reportColumn.columnName || '',
                dataKey: `${reportColumn.attributeType}_${reportColumn.attributeTypeId}`,
                withoutSorter: true,
                columnId: reportColumn.columnId,
                selected: isColSelected,
                width: COLUMN_WIDTH,
                headerRenderer: () => (
                    <div
                        className={`
                              ${theme.columnHeader} 
                              ${isColSelected ? theme.selectedHeaderCell : ''} 
                              ${tableData.length === 0 && isColSelected ? theme.emptyDataSelectedHeaderCell : ''}`}
                    >
                        <EllipsisText text={reportColumn.columnName || ''} linesQuantity={1} />
                    </div>
                ),
                cellRender: ({ data, index, cache, sortedList }) => {
                    const { dataKey, parent, rowIndex } = data;
                    const value = sortedList[rowIndex][dataKey] ?? '';
                    const isLastRow = sortedList.length - 1 === rowIndex;

                    return (
                        <CellMeasurer
                            cache={cache}
                            key={dataKey}
                            parent={parent}
                            rowIndex={rowIndex}
                            columnIndex={index}
                        >
                            <div
                                className={`${theme.cell} ${
                                    isColSelected ? (isLastRow ? theme.lastRowSelectedCell : theme.selectedCell) : ''
                                }`}
                            >
                                <div>
                                    <EllipsisText text={value} />
                                </div>
                            </div>
                        </CellMeasurer>
                    );
                },
            } as TReportColumn;
        });

    const foundIndexes: number[] = tableData.reduce((prev, current, index) => {
        if (current.found) {
            prev.push(index);
        }
        return prev;
    }, [] as number[]);

    const incIndexHandler = () => {
        setCurrentIndex((prev) => {
            if (prev === undefined) {
                return 0;
            }
            if (prev !== undefined && prev + 1 < foundIndexes.length) {
                return prev + 1;
            } else {
                return 0;
            }
        });
    };

    const decIndexHandler = () => {
        setCurrentIndex((prev) => {
            if (prev === undefined) {
                return 0;
            }
            if (prev !== undefined && prev - 1 >= 0) {
                return prev - 1;
            } else {
                return foundIndexes.length - 1;
            }
        });
    };

    const currentColumn: ReportColumnData | undefined = reportColumns.find(
        (column) => column.columnId === selectedColumnId,
    );

    const onDropHandler = (event: React.DragEvent) => {
        const droppedNodeId: NodeId = JSON.parse(event.dataTransfer.getData('nodeId'));
        dispatch(reportDropNodes(nodeId, droppedNodeId));
    };

    const onColumnClickHandler = ({ columnData }) => {
        if (isReadMode) return;

        if (columnData?.columnId) {
            dispatch(reportSelectColumn(nodeId, columnData.columnId));
        }
    };

    const checkRowHandler = (rows) => {
        const ids: string[] = rows.map((row) => row.id);
        if (ids.length === 1) {
            setCheckedIds((prevIds) => xor(prevIds, ids));
        } else {
            if (rows.length > checedIds.length) {
                setCheckedIds(ids);
            } else {
                setCheckedIds([]);
            }
        }
    };

    const disableDelete: boolean = reportColumns.filter((col) => col.columnName).length < 2;

    const getIndexForScroll = (): number | undefined => {
        if (currentIndex !== undefined && foundIndexes.length > 0) {
            return foundIndexes[currentIndex];
        }

        return undefined;
    };

    const selectedNodeIds: NodeId[] = tableData
        .filter((row) => typeof row.id === 'string' && checedIds.includes(row.id))
        .map((row) => row.nodeId as NodeId);

    return (
        <div className={theme.container}>
            <div className={theme.colContainer}>
                <ReportEditorFilterBar
                    reportNodeId={nodeId}
                    selectedNodeIds={selectedNodeIds}
                    searchValue={searchValue}
                    disableSearchButtons={foundIndexes.length === 0}
                    showDeleteBtn={fillingType === 'MANUAL'}
                    setSearchValue={handleSetSearchValue}
                    incIndex={incIndexHandler}
                    decIndex={decIndexHandler}
                />
                <div className={theme.rowContainer}>
                    <div
                        onDrop={onDropHandler}
                        onDragOver={(event: React.DragEvent) => {
                            if (!isReadMode && fillingType !== 'AUTO') {
                                event.preventDefault();
                            }
                        }}
                        className={`${theme.tableContainer} ${tableData.length === 0 ? theme.noData : ''} `}
                    >
                        <TableUIKit
                            withCkeckBox={!isReadMode}
                            checkRows={checkRowHandler}
                            columns={columns}
                            tableData={tableData}
                            onHeaderClick={onColumnClickHandler}
                            onColumnClick={onColumnClickHandler}
                            minWidth={columns.length * COLUMN_WIDTH + 60}
                            scrollToIndex={getIndexForScroll()}
                            disableRowClick
                        />
                        {tableData.length === 0 && (
                            <div className={theme.noDataWrapper}>
                                <Spinner loading={loading}>
                                    <Icon spriteSymbol={noData} className={theme.noDataIcon} />
                                    <div>{intl.formatMessage(messages.noData)}</div>
                                </Spinner>
                            </div>
                        )}
                    </div>
                    {!isReadMode && (
                        <div className={theme.addButtonContainer}>
                            <Button icon={addIcon} visualStyle="text" onClick={handleAddColumn} />
                        </div>
                    )}
                </div>
            </div>
            {!isReadMode && (
                <ReportEditorSidePanel
                    disableDelete={disableDelete}
                    reportNodeId={nodeId}
                    currentColumn={currentColumn}
                />
            )}
        </div>
    );
};
