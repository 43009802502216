import { IWorkspaceTabItemModelParams } from '@/models/tab.types';
import { BpmNodeFilterBase } from '../../models/bpmNodeFilterBase';
import { MxCell } from '../../mxgraph/mxgraph';
import { TButtonsVisibilityParams, TObjectButtonsVisibilityParams, TSelectedCellElements } from './ToolbarButtonsBllService.types';
import { isImageCell } from '@/utils/bpm.mxgraph.utils';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';

export class ToolbarButtonsBllService {
    public static buttonsVisibility({
        activeWorkspaceTab,
        isActiveToolbar,
        isReadMode,
        hasCopiedElements,
        hasCopyableCells,
        isPrintable,
        hasNonEditableSelectedObject,
        hasNonEditableSelectedEdge,
        hasNonDeletableSelectedEdge,
        hasOneCellSelected,
        hasOneOrMoreCellsSelected,
        isWhiteBoard,
        oneEdgeOrObjectSelected,
        hasTransition,
        hasSelectedShapesWithoutImage,
        hasMoreThanOneCellsSelected,
        minTwoObjectsSelected,
        onlyEdgesSelected,
        isSequenceGraph,
        isGridModelGraph,
        edgeDefinition,
    }: TButtonsVisibilityParams) {
        const editModeBtnDisabled = Boolean(activeWorkspaceTab?.content?.userEditDisabled);
        const pasteBtnDisabled = !isActiveToolbar || isReadMode || !hasCopiedElements;
        const copyBtnDisabled = !isActiveToolbar || !hasCopyableCells;
        const printBtnDisabled = !isActiveToolbar || !isPrintable;
        const cutBtnDisabled = hasNonDeletableSelectedEdge || isReadMode || !hasCopyableCells;
        const deleteBtnDisabled =
            !(hasOneCellSelected || hasMoreThanOneCellsSelected) ||
            hasNonDeletableSelectedEdge ||
            isReadMode ||
            hasNonEditableSelectedObject;
        const insertSpaceBtnDisabled = !isActiveToolbar || isReadMode || isGridModelGraph;
        const alignBtnDisabled = !isActiveToolbar || isReadMode || !minTwoObjectsSelected || isSequenceGraph;
        const moveBtnDisabled =
            !isActiveToolbar || isReadMode || hasNonEditableSelectedEdge || !hasOneOrMoreCellsSelected;
        const propertyBtnDisabled =
            !isActiveToolbar ||
            hasSelectedShapesWithoutImage ||
            hasMoreThanOneCellsSelected ||
            (isWhiteBoard && hasOneCellSelected);
        const decompositionBtnDisabled =
            !(oneEdgeOrObjectSelected && !isWhiteBoard && isActiveToolbar) || (!edgeDefinition && onlyEdgesSelected);
        const updateBtnDisabled = !isActiveToolbar || !isReadMode;
        const pickOutBtnDisabled = !isActiveToolbar;
        const searchBtnDisabled = !isActiveToolbar || !isReadMode;
        const moveToBtnDisabled = !isActiveToolbar || !hasTransition || hasNonEditableSelectedObject;
        const switchFilterModeBtnDisabled = !isActiveToolbar || !isReadMode || isWhiteBoard;
        const filterCheckboxDisabled =
            !isReadMode ||
            !(activeWorkspaceTab?.params as IWorkspaceTabItemModelParams)?.nodeFilterBase ||
            (activeWorkspaceTab?.params as IWorkspaceTabItemModelParams)?.nodeFilterBase === BpmNodeFilterBase.Off;

        return {
            editModeBtnDisabled,
            pasteBtnDisabled,
            copyBtnDisabled,
            printBtnDisabled,
            cutBtnDisabled,
            deleteBtnDisabled,
            insertSpaceBtnDisabled,
            alignBtnDisabled,
            moveBtnDisabled,
            propertyBtnDisabled,
            decompositionBtnDisabled,
            updateBtnDisabled,
            pickOutBtnDisabled,
            searchBtnDisabled,
            moveToBtnDisabled,
            switchFilterModeBtnDisabled,
            filterCheckboxDisabled,
        };
    }

    public static objectButtonsVisibility({
        isReadMode,
        isActiveToolbar,
        isSelectedCellsEditable,
        objectWithoutSymbol,
        hasOneOrMoreElSelected,
        isEntityEditable,
        poolOrSwimlaneCellSelected,
        isSequenceGraph,
        hasSelectedOnlyImages,
    }: TObjectButtonsVisibilityParams) {
        const formatBtnDisabled =
            isReadMode ||
            !hasOneOrMoreElSelected ||
            !isActiveToolbar ||
            !isSelectedCellsEditable ||
            !objectWithoutSymbol ||
            poolOrSwimlaneCellSelected ||
            hasSelectedOnlyImages ||
            isSequenceGraph;
        const clearFormatBtnDisabled =
            isReadMode ||
            !hasOneOrMoreElSelected ||
            !isEntityEditable ||
            !isSelectedCellsEditable ||
            !objectWithoutSymbol ||
            poolOrSwimlaneCellSelected ||
            hasSelectedOnlyImages ||
            isSequenceGraph;
        return {
            formatBtnDisabled,
            clearFormatBtnDisabled,
        };
    }

    public static getCellsWorkspace(selectedCells: MxCell[]) {
        let selectedCellsWithoutLabels: MxCell[] = selectedCells;

        if (selectedCells.length > 1) {
            selectedCellsWithoutLabels = selectedCells.filter((cell) => !(cell.getValue()?.type === 'label'));
        }

        return selectedCellsWithoutLabels;
    }

    public static getSelectedCellsWithoutImages(cells: MxCell[]) {
        return cells.filter((cell) => !isImageCell(cell));
    }

    public static getRawSelectedCellsAndSelectedCellsWithoutLabels(
        graph: BPMMxGraph | undefined,
        cells: string[],
    ): TSelectedCellElements {
        const rawSelectedCells: MxCell[] = graph
            ? cells.map((cellId) => graph.getModel().getCell(cellId)).filter(Boolean)
            : [];
        const selectedCellsWithoutLabels: MxCell[] = ToolbarButtonsBllService.getCellsWorkspace(rawSelectedCells);

        return {
            rawSelectedCells,
            selectedCellsWithoutLabels,
        };
    }
}
