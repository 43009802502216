import React, { ReactNode } from 'react';
import { ContentLoadingPageTab } from '../../modules/Workspace/components/ContentLoadingPageTab/ContentLoadingPageTab.component';

type TSpinnerProps = {
    children: ReactNode;
    loading: boolean;
    className?: string;
};

export const Spinner = ({ children, loading, className }: TSpinnerProps) => {
    return <>{loading ? <ContentLoadingPageTab className={className} /> : children}</>;
};
