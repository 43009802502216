// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateInvisibleEdgeDialog__radioContainer__yHClL{margin:15px 0}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/CreateInvisibleEdgeDialog/CreateInvisibleEdgeDialog.scss"],"names":[],"mappings":"AAAA,kDACI,aAAA","sourcesContent":[".radioContainer {\r\n    margin: 15px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioContainer": "CreateInvisibleEdgeDialog__radioContainer__yHClL"
};
export default ___CSS_LOADER_EXPORT___;
