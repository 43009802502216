import React, { useRef, useState } from 'react';
import { Form, Input, Row, InputNumber } from 'antd';
import messages from './WikiTableDialog.messages';
import { useIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { TWikiTable } from '../../../models/tab.types';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { TTableDialogProps } from './WikiTableDialog.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TFormValues = TWikiTable;
const DEFAULT_FROM_DATA = {
    rows: 2,
    cols: 2,
};

export const WikiTableDialog = ({ onClose, onSubmit, formInitData = DEFAULT_FROM_DATA }: TTableDialogProps) => {
    const formRef = useRef<any>();
    const [row, setRow] = useState<any>({
        value: formInitData.rows || 2,
    });
    const [col, setCol] = useState<any>({
        value: formInitData.cols || 2,
    });
    const intl = useIntl();
    const validatePrimeNumber = (number) => {
        if (number > 0 && number <= 100) {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }

        return {
            validateStatus: 'error',
            errorMsg: intl.formatMessage(messages.formValidationMessageRange),
        };
    };
    const handleSubmit = () => {
        const form = formRef.current;
        if (form) {
            form.validateFields().then((formValues: TFormValues) => {
                onSubmit(formValues);
            });
        }
    };

    const onRowChange = (value) => setRow({ ...validatePrimeNumber(value), value });
    const onColChange = (value) => setCol({ ...validatePrimeNumber(value), value });

    const children = (
        <Form ref={formRef}>
            <Input.Group compact>
                <Row justify="space-between">
                    <Form.Item
                        label={intl.formatMessage(messages.tableColCountLable)}
                        name="cols"
                        initialValue={col.value}
                        validateStatus={col.validateStatus}
                        help={col.errorMsg}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage(messages.formValidationMessageRequiredCol),
                            },
                        ]}
                    >
                        <InputNumber min={1} max={100} value={col.value} onChange={onColChange} />
                    </Form.Item>
                    <Form.Item
                        label={intl.formatMessage(messages.tableRowCountLable)}
                        initialValue={row.value}
                        validateStatus={row.validateStatus}
                        name="rows"
                        help={row.errorMsg}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage(messages.formValidationMessageRequiredCol),
                            },
                        ]}
                    >
                        <InputNumber value={row.value} min={1} max={100} onChange={onRowChange} />
                    </Form.Item>
                </Row>
            </Input.Group>
        </Form>
    );

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.formDeclineButton),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.formConfirmButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.formName)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
        >
            {children}
        </Dialog>
    );
};
