import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'KanbanCardEditor.name',
        defaultMessage: 'Название',
    },
    symbolBreadCrumbs: {
        id: 'KanbanCardEditor.symbolBreadCrumbs',
        defaultMessage: '{presetName} > Типы объектов > {objectTypeName} > Символы > {symbolName}',
    },
    newSymbol: {
        id: 'KanbanCardEditor.newSymbol',
        defaultMessage: 'Новый символ',
    },
    cancel: {
        id: 'KanbanCardEditor.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'KanbanCardEditor.save',
        defaultMessage: 'Сохранить',
    },
    create: {
        id: 'KanbanCardEditor.create',
        defaultMessage: 'Создать',
    },
    mergeCells: {
        id: 'KanbanCardEditor.mergeCells',
        defaultMessage: 'Объединить ячейки',
    },
    splitCells: {
        id: 'KanbanCardEditor.splitCells',
        defaultMessage: 'Разделить ячейки',
    },
    plainTextOutput: {
        id: 'KanbanCardEditor.plainTextOutput',
        defaultMessage: 'Вывод простого текста',
    },
    cardGridControl: {
        id: 'KanbanCardEditor.cardGridControl',
        defaultMessage: 'Управление сеткой карточки',
    },
    column: {
        id: 'KanbanCardEditor.column',
        defaultMessage: 'Колонка',
    },
    adaptiveWidth: {
        id: 'KanbanCardEditor.adaptiveWidth',
        defaultMessage: 'Ширина адаптивная',
    },
    fixedWidth: {
        id: 'KanbanCardEditor.fixedWidth',
        defaultMessage: 'Ширина фиксированная',
    },
    adaptiveHeight: {
        id: 'KanbanCardEditor.adaptiveHeight',
        defaultMessage: 'Высота адаптивная',
    },
    fixedHeight: {
        id: 'KanbanCardEditor.fixedHeight',
        defaultMessage: 'Высота фиксированная',
    },
    columns: {
        id: 'KanbanCardEditor.columns',
        defaultMessage: 'Колонки',
    },
    columnsCount: {
        id: 'KanbanCardEditor.columnsCount',
        defaultMessage: 'Количество колонок:',
    },
    rows: {
        id: 'KanbanCardEditor.rows',
        defaultMessage: 'Ряды',
    },
    rowsCount: {
        id: 'KanbanCardEditor.rowsCount',
        defaultMessage: 'Количество рядов:',
    },
    locationWithinCell: {
        id: 'KanbanCardEditor.locationWithinCell',
        defaultMessage: 'Расположение внутри ячейки:',
    },
    sector: {
        id: 'KanbanCardEditor.sector',
        defaultMessage: 'Сектор',
    },
    leftCorner: {
        id: 'KanbanCardEditor.leftCorner',
        defaultMessage: 'По левому краю',
    },
    center: {
        id: 'KanbanCardEditor.center',
        defaultMessage: 'По центру',
    },
    rightCorner: {
        id: 'KanbanCardEditor.rightCorner',
        defaultMessage: 'По правому краю',
    },
    top: {
        id: 'KanbanCardEditor.top',
        defaultMessage: 'Сверху',
    },
    bottom: {
        id: 'KanbanCardEditor.bottom',
        defaultMessage: 'Снизу',
    },
    cellPaddings: {
        id: 'KanbanCardEditor.cellPaddings',
        defaultMessage: 'Отступы внутри ячейки (верх, право, низ, лево):',
    },
    cellFilling: {
        id: 'KanbanCardEditor.cellFilling',
        defaultMessage: 'Заливка ячейки:',
    },
    cellBorder: {
        id: 'KanbanCardEditor.cellBorder',
        defaultMessage: 'Контур ячейки',
    },
    borderColor: {
        id: 'KanbanCardEditor.borderColor',
        defaultMessage: 'Цвет контура:',
    },
    borderThickness: {
        id: 'KanbanCardEditor.borderThickness',
        defaultMessage: 'Толщина контура:',
    },
    borders: {
        id: 'KanbanCardEditor.borders',
        defaultMessage: 'Границы:',
    },
    left: {
        id: 'KanbanCardEditor.left',
        defaultMessage: 'Слева',
    },
    right: {
        id: 'KanbanCardEditor.right',
        defaultMessage: 'Справа',
    },
    selectViewFormat: {
        id: 'KanbanCardEditor.selectViewFormat',
        defaultMessage: 'Выберите формат отображения',
    },
    text: {
        id: 'KanbanCardEditor.text',
        defaultMessage: 'Текст',
    },
    icon: {
        id: 'KanbanCardEditor.icon',
        defaultMessage: 'Иконка',
    },
    displayAttributeValue: {
        id: 'KanbanCardEditor.displayAttributeValue',
        defaultMessage: 'Вывести значение атрибута',
    },
    arbitrary: {
        id: 'KanbanCardEditor.arbitrary',
        defaultMessage: 'Произвольный',
    },
    bold: {
        id: 'KanbanCardEditor.bold',
        defaultMessage: 'Жирный',
    },
    italic: {
        id: 'KanbanCardEditor.italic',
        defaultMessage: 'Курсив',
    },
    underline: {
        id: 'KanbanCardEditor.underline',
        defaultMessage: 'Подчеркнутый',
    },
    displayOnNewLine: {
        id: 'KanbanCardEditor.displayOnNewLine',
        defaultMessage: 'Выводить с новой строки',
    },
    specifyDisplayConditions: {
        id: 'KanbanCardEditor.specifyDisplayConditions',
        defaultMessage: 'Укажите условия отображения (условия суммируются)',
    },
    addCondition: {
        id: 'KanbanCardEditor.addCondition',
        defaultMessage: 'Добавить условие',
    },
    addAttribute: {
        id: 'KanbanCardEditor.addAttribute',
        defaultMessage: 'Добавить атрибут',
    },
    attributeDeletion: {
        id: 'KanbanCardEditor.attributeDeletion',
        defaultMessage: 'Удаление атрибута',
    },
    doYouWantToDelete: {
        id: 'KanbanCardEditor.doYouWantToDelete',
        defaultMessage: 'Вы действительно хотите удалить?',
    },
    selectAttributeType: {
        id: 'KanbanCardEditor.selectAttributeType',
        defaultMessage: 'Выберите тип атрибута',
    },
    format: {
        id: 'KanbanCardEditor.format',
        defaultMessage: 'Формат',
    },
    row: {
        id: 'KanbanCardEditor.row',
        defaultMessage: 'Ряд',
    },
});

