import { TReducer } from '../utils/types';
import { UserDTO } from '../serverapi/api';
import {
    FETCH_USERS_SUCCESS,
    FETCH_USER_BYID_SUCCESS,
    EDIT_USER_DATA_BEGIN,
    EDIT_USER_DATA_END,
    SAVE_USERS_LICENSES_CHANGES,
    SAVE_USERS_GROUPS_CHANGES,
    SAVE_USERS_REPOS_CHANGES,
    SAVE_USERS_ACCESSES_CHANGES,
    DELETE_USER_SUCCESS,
    FETCH_USERS_BY_GROUPID_SUCCESS,
    FETCH_USER_SESSIONS_SUCCESS,
    FETCH_ALL_USERS,
} from '../actionsTypes/users.actionTypes';
import { DELETE_GROUP_SUCCESS } from '../actionsTypes/groups.actionTypes';
import { TUsersState } from './users.reducer.types';
import { cloneDeep } from 'lodash-es';

const initial: TUsersState = {
    byServerId: {},
    editingUser: { byId: {} },
    usersSessions: {},
    fetchAllUsersLoading: false,
};

export const usersReducer: TReducer<TUsersState> = (state = initial, action) => {
    switch (action.type) {
        case FETCH_USER_SESSIONS_SUCCESS: {
            const { userId, sessions } = action.payload;

            return {
                ...state,
                usersSessions: {
                    ...state.usersSessions,
                    [userId]: sessions,
                },
            };
        }

        case FETCH_ALL_USERS: {
            return {
                ...state,
                fetchAllUsersLoading: true,
            };
        }

        case FETCH_USERS_SUCCESS: {
            const { users, serverId } = action.payload;
            const usersMap = users.reduce((result: {}, item: UserDTO) => ({ ...result, [item.id || 0]: item }), {});

            return {
                ...state,
                fetchAllUsersLoading: false,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...(state.byServerId[serverId] || {}),
                        byId: {
                            ...usersMap,
                        },
                    },
                },
            };
        }
        case EDIT_USER_DATA_BEGIN: {
            const { userData } = action.payload;
            return {
                ...state,
                editingUser: {
                    byId: {
                        ...state.editingUser.byId,
                        [userData.id]: userData,
                    },
                },
            };
        }
        case EDIT_USER_DATA_END: {
            const { userId } = action.payload;
            const newEditingUserState = cloneDeep(state.editingUser);
            delete newEditingUserState.byId[userId];

            return {
                ...state,
                editingUser: newEditingUserState,
            };
        }
        case FETCH_USER_BYID_SUCCESS: {
            const { userData, serverId } = action.payload;
            const byIdNew = { ...state.byServerId[serverId].byId };
            byIdNew[userData.id || 0] = { ...userData };

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...(state.byServerId[serverId] || {}),
                        byId: {
                            ...byIdNew,
                        },
                    },
                },
                editingUser: {
                    byId: {
                        ...state.editingUser.byId,
                        [userData.id]: userData,
                    },
                },
            };
        }
        case FETCH_USERS_BY_GROUPID_SUCCESS: {
            const { users, groupId, serverId } = action.payload;
            const usersIds: (number | undefined)[] = users.map((u) => u.id);

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...(state.byServerId[serverId] || {}),
                        byGroupId: {
                            ...state.byServerId[serverId].byGroupId,
                            [groupId]: [...usersIds],
                        },
                    },
                },
            };
        }
        case SAVE_USERS_LICENSES_CHANGES: {
            const { usersLicenses, userId } = action.payload;

            return {
                ...state,
                editingUser: {
                    byId: {
                        ...state.editingUser.byId,
                        [userId]: {
                            ...state.editingUser.byId[userId],
                            licenses: usersLicenses,
                        },
                    },
                },
            };
        }
        case SAVE_USERS_GROUPS_CHANGES: {
            const { usersGroups, userId } = action.payload;

            return {
                ...state,
                editingUser: {
                    byId: {
                        ...state.editingUser.byId,
                        [userId]: {
                            ...state.editingUser.byId[userId],
                            groups: usersGroups,
                        },
                    },
                },
            };
        }
        case SAVE_USERS_REPOS_CHANGES: {
            const { repos, userId } = action.payload;

            return {
                ...state,
                editingUser: {
                    byId: {
                        ...state.editingUser.byId,
                        [userId]: {
                            ...state.editingUser.byId[userId],
                            repositories: repos,
                        },
                    },
                },
            };
        }
        case SAVE_USERS_ACCESSES_CHANGES: {
            const { accesses, userId } = action.payload;

            return {
                ...state,
                editingUser: {
                    byId: {
                        ...state.editingUser.byId,
                        [userId]: {
                            ...state.editingUser.byId[userId],
                            accesses,
                        },
                    },
                },
            };
        }
        case DELETE_USER_SUCCESS: {
            const { userId, serverId } = action.payload;
            const byIdNew = { ...state.byServerId[serverId].byId };
            delete byIdNew[userId];

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...state.byServerId[serverId],
                        byId: {
                            ...byIdNew,
                        },
                    },
                },
            };
        }
        case DELETE_GROUP_SUCCESS: {
            const { groupId, serverId } = action.payload;
            const byGroupIdNew = { ...state.byServerId[serverId].byGroupId };
            delete byGroupIdNew[groupId];

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...state.byServerId[serverId],
                        byGroupId: {
                            ...byGroupIdNew,
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};
