import { TReducer } from '../utils/types';
import {
    ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS,
    ATTRIBUTE_TYPES_REQUEST_SUCCESS,
} from '../actionsTypes/attributeType.actionTypes';
import { TAttributeTypeState } from './attributeType.reducer.types';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import { cloneState } from './utils/cloneState.utils';

export const INITIAL_ATTRIBUTE_TYPE_SERVER_STATE = {
    byPresetId: {},
};

const initial: TAttributeTypeState = {
    byServerId: {},
};

export const attributeTypeReducer: TReducer<TAttributeTypeState> = (state = initial, action) => {
    switch (action.type) {
        case ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS: {
            const {
                attributeTypes,
                serverNode: {
                    nodeId: { serverId },
                },
            } = action.payload;
            const stateClone = JSON.parse(JSON.stringify(state));
            attributeTypes.forEach((attributeType) => {
                const byId =
                    (attributeType.presetId &&
                        stateClone.byServerId[serverId] &&
                        stateClone.byServerId[serverId].byPresetId[attributeType.presetId] &&
                        stateClone.byServerId[serverId].byPresetId[attributeType.presetId].byId) ||
                    {};
                if (attributeType.id) delete byId[attributeType.id];
            });

            return stateClone;
        }
        case ATTRIBUTE_TYPES_REQUEST_SUCCESS: {
            const {
                payload: { serverId, presetId, attributeTypes },
            } = action;
            const stateClone = cloneState(state, serverId, presetId);

            const { byId } = stateClone.byServerId[serverId].byPresetId[presetId];

            attributeTypes.forEach((attrType) => {
                byId[attrType.id] = attrType;
            });

            return stateClone;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }

        default:
            return state;
    }
};
