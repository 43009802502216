import { put, takeEvery } from 'redux-saga/effects';
import { RENAME_OBJECT_DIALOG_SUBMIT } from '../actionsTypes/renameObjectDialog.actionTypes';
import { updateObjectDefinitionName } from '../actions/entities/objectDefinition.actions';
import type { TRenameObjectDialogSubmitAction } from '../actions/renameObjectDialog.actions.types';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { objectDefinitionService } from '../services/ObjectDefinitionService';
import { SymbolTypeId } from '../mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { IModelContext } from './utils.types';
import { getActiveModelContext } from './utils';

const SWIMLANE_SYMBOL_IDS = [SymbolTypeId.HORIZONTAL_SWIMLANE, SymbolTypeId.VERTICAL_SWIMLANE];

function* handleRenameObjectDialogSubmit({ payload }: TRenameObjectDialogSubmitAction) {
    const { cell, objectDefinition, newName } = payload;
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const { graph } = modelContext;

    if (!objectDefinition) {
        cell.setValue(newName);
        graph.refresh(cell);
    }

    yield put(closeDialog(DialogType.RENAME_OBJECT_DIALOG));

    const symbolId: string | undefined = cell.getValue()?.symbolId;

    if (SWIMLANE_SYMBOL_IDS.includes(<SymbolTypeId>symbolId)) {
        const instances: ObjectDefinitionImpl[] = yield objectDefinitionService().findByNameAndType(
            objectDefinition.nodeId,
            newName,
        );

        if (instances.length) {
            yield put(openDialog(DialogType.SELECT_OBJECT_DIALOG, { cellId: cell.id, instances }));

            return;
        }
    }

    yield put(updateObjectDefinitionName(objectDefinition, newName));
}

export function* renameObjectDialogSaga() {
    yield takeEvery(RENAME_OBJECT_DIALOG_SUBMIT, handleRenameObjectDialogSubmit);
}
