// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterDuplicates__selectText__Ea5TC{font-size:14px;margin-left:-1px;color:var(--grey-dark-UIKit)}.FilterDuplicates__labelSelectContainer__b45kZ{margin-right:100px}.FilterDuplicates__select__CNi4j{width:300px}.FilterDuplicates__select__CNi4j>button{border-radius:4px;margin-bottom:0}.FilterDuplicates__container__Wsvec{display:-webkit-box;display:-ms-flexbox;display:flex}.FilterDuplicates__stickyContainer__fjw26{position:sticky;top:0;background:#fff;z-index:1000;border-bottom:1px solid gray;border-bottom:1px solid var(--Neutral100);top:-10px;padding-top:20px;padding-bottom:10px}", "",{"version":3,"sources":["webpack://./src/modules/FindDuplicates/components/FilterDuplicates.scss"],"names":[],"mappings":"AAAA,qCACI,cAAA,CACA,gBAAA,CACA,4BAAA,CAEJ,+CACI,kBAAA,CAGJ,iCACI,WAAA,CACA,wCACI,iBAAA,CACA,eAAA,CAIR,oCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGJ,0CACI,eAAA,CACA,KAAA,CACA,eAAA,CACA,YAAA,CACA,4BAAA,CACA,yCAAA,CACA,SAAA,CACA,gBAAA,CACA,mBAAA","sourcesContent":[".selectText {\r\n    font-size: 14px;\r\n    margin-left: -1px;\r\n    color: var(--grey-dark-UIKit);\r\n}\r\n.labelSelectContainer {\r\n    margin-right: 100px;\r\n}\r\n\r\n.select {\r\n    width: 300px;\r\n    > button {\r\n        border-radius: 4px;\r\n        margin-bottom: 0;\r\n    }\r\n}\r\n\r\n.container {\r\n    display: flex;\r\n}\r\n\r\n.stickyContainer {\r\n    position: sticky;\r\n    top: 0;\r\n    background: white;\r\n    z-index: 1000;\r\n    border-bottom: 1px solid gray;\r\n    border-bottom: 1px solid var(--Neutral100);\r\n    top: -10px;\r\n    padding-top: 20px;\r\n    padding-bottom: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectText": "FilterDuplicates__selectText__Ea5TC",
	"labelSelectContainer": "FilterDuplicates__labelSelectContainer__b45kZ",
	"select": "FilterDuplicates__select__CNi4j",
	"container": "FilterDuplicates__container__Wsvec",
	"stickyContainer": "FilterDuplicates__stickyContainer__fjw26"
};
export default ___CSS_LOADER_EXPORT___;
