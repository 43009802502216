import {
    TCreatePresetImageAction,
    TCreatePresetImagePayload,
    TDeletePresetImageAction, TDeletePresetImageAndGroupPayload,
    TDeletePresetImagePayload,
    TEditPresetImageAction,
    TEditPresetImagePayload,
    TPresetImageDeleteRequestSuccessAction,
    TPresetImageRequestSuccessAction,
    TPresetImageRequestSuccessPayload,
    TSubmitPresetImageAction,
    TSubmitPresetImagePayload
} from "./presetImage.actions.types";
import {
    CREATE_PRESET_IMAGE,
    DELETE_PRESET_IMAGE, DELETE_PRESET_IMAGE_AND_GROUP,
    EDIT_PRESET_IMAGE,
    PRESET_IMAGE_DELETE_REQUEST_SUCCESS,
    PRESET_IMAGE_REQUEST_SUCCESS,
    SUBMIT_PRESET_IMAGE
} from "../../actionsTypes/presetSettings/presetImage.actionTypes";
import {PresetImage} from "../../serverapi/api";

export const deletePresetImage = (payload: TDeletePresetImagePayload): TDeletePresetImageAction => ({
    type: DELETE_PRESET_IMAGE,
    payload,
});

export const presetImageRequestSuccess = (
    payload: TPresetImageRequestSuccessPayload,
): TPresetImageRequestSuccessAction => ({
    type: PRESET_IMAGE_REQUEST_SUCCESS,
    payload,
});

export const presetImageDeleteRequestSuccess = (
    serverId: string,
    presetImages: PresetImage[],
): TPresetImageDeleteRequestSuccessAction => ({
    type: PRESET_IMAGE_DELETE_REQUEST_SUCCESS,
    payload: {
        serverId,
        presetImages,
    },
});

export const createPresetImage = (payload: TCreatePresetImagePayload): TCreatePresetImageAction => ({
    type: CREATE_PRESET_IMAGE,
    payload,
});

export const editPresetImage = (payload: TEditPresetImagePayload): TEditPresetImageAction => ({
    type: EDIT_PRESET_IMAGE,
    payload,
});

export const submitPresetImages = (payload: TSubmitPresetImagePayload): TSubmitPresetImageAction => ({
    type: SUBMIT_PRESET_IMAGE,
    payload,
});

export const deletePresetImageAndGroup = (payload: TDeletePresetImageAndGroupPayload) => ({
    type: DELETE_PRESET_IMAGE_AND_GROUP,
    payload,
});
