// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServerProfileChange__selectProfile__m17EB{width:300px}.ServerProfileChange__warn___a3f6{margin-top:20px;margin-bottom:-10px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.ServerProfileChange__warnIcon___U0et{margin-right:5px}", "",{"version":3,"sources":["webpack://./src/modules/ServerProfile/components/Logo/ServerProfileChange.scss"],"names":[],"mappings":"AAAA,2CACI,WAAA,CAEJ,kCACI,eAAA,CACA,mBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGJ,sCACI,gBAAA","sourcesContent":[".selectProfile {\r\n    width: 300px;\r\n}\r\n.warn {\r\n    margin-top: 20px;\r\n    margin-bottom: -10px;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.warnIcon {\r\n    margin-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectProfile": "ServerProfileChange__selectProfile__m17EB",
	"warn": "ServerProfileChange__warn___a3f6",
	"warnIcon": "ServerProfileChange__warnIcon___U0et"
};
export default ___CSS_LOADER_EXPORT___;
