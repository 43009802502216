import type { Node } from '@tiptap/pm/model';
import { Mark, mergeAttributes } from '@tiptap/core';
import { CommentTooltipPlugin } from '../plugins/comment/CommentTooltip.plugin';

type TCommentOptions = {
    HTMLAttributes: Record<string, any>;
    tooltipContainerName?: string;
    tooltipDelay?: number;
    commentTooltipComponent?: React.ElementType;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        comment: {
            /**
             * Set a comment mark
             */
            setComment: (threadId: string, commentId: string) => ReturnType;
            /**
             * Unset a comment mark
             */
            unsetComment: (threadId: string, commentId: string) => ReturnType;
        };
    }
}

export const Comment = Mark.create<TCommentOptions>({
    name: 'comment',

    inclusive: false,

    // TODO
    // excludes: '_',

    addOptions() {
        return {
            HTMLAttributes: {},
            tooltipDelay: 500,
            tooltipContainerName: 'commentTextAreaContainer',
        };
    },

    addAttributes() {
        return {
            threadId: {
                default: null,
                parseHTML: (element) => {
                    return element.getAttribute('thread-id');
                },
                renderHTML: (attributes) => {
                    if (!attributes.threadId) {
                        return {};
                    }

                    return {
                        'thread-id': attributes.threadId,
                    };
                },
            },
            commentId: {
                default: null,
                parseHTML: (element) => {
                    return element.getAttribute('comment-id');
                },
                renderHTML: (attributes) => {
                    if (!attributes.commentId) {
                        return {};
                    }

                    return {
                        'comment-id': attributes.commentId,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span[comment-id]',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },

    addCommands() {
        return {
            setComment:
                (threadId: string, commentId: string) =>
                ({ commands }) => {
                    return commands.setMark(this.name, { threadId, commentId });
                },
            unsetComment:
                (threadId: string, commentId: string) =>
                ({ chain }) => {
                    const commentNodes: { node: Node; pos: number }[] = [];

                    this.editor.view.state.doc.nodesBetween(0, this.editor.view.state.doc.content.size, (node, pos) => {
                        if (
                            node.isText &&
                            node.marks?.find(
                                (mark) => mark.type.name === this.name && mark.attrs['commentId'] === commentId,
                            )
                        ) {
                            commentNodes.push({ node, pos });
                        }
                    });

                    if (commentNodes.length === 0) {
                        return false;
                    }

                    return chain()
                        .focus()
                        .forEach(commentNodes, ({ node, pos }, { chain }) =>
                            chain()
                                .setTextSelection({
                                    from: pos,
                                    to: pos + node.nodeSize,
                                })
                                .unsetMark(this.name)
                                .setTextSelection(pos)
                                .run(),
                        )
                        .run();
                },
        };
    },

    // TODO
    // onUpdate({ editor }) {
    //     console.log('onUpdate', editor.$nodes(this.name), this.name);
    // },

    addProseMirrorPlugins() {
        if (!this.options.commentTooltipComponent) {
            return [];
        }

        return [
            // TODO использовать default values в типизации
            CommentTooltipPlugin({
                delay: this.options.tooltipDelay || 500,
                commentMarkName: this.name,
                tooltipContainerName: this.options.tooltipContainerName || 'commentTextAreaContainer',
                commentTooltipComponent: this.options.commentTooltipComponent,
            }),
        ];
    },
});
