import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import icEdit from '../../../resources/icons/edit.svg';
import messages from './RowButtons.messages';
import { Button } from '../../UIKit/components/Button/Button.component';

type TEditButton = {
    onClick: () => void;
};

const EditButton: FC<TEditButton> = (props) => {
    const { onClick } = props;
    const intl = useIntl();

    return (
        <Button
            tooltip={intl.formatMessage(messages.edit)}
            size="small"
            dataTest="row-button_EDIT"
            visualStyle={{ type: 'text' }}
            onClick={onClick}
            icon={icEdit}
        />
    );
};

export default EditButton;
