import React, { FC, useCallback } from 'react';
import cx from 'classnames';
import theme from './NavigatorPanel.scss';
import electron from '../../../../electron';
import { useDispatch, useSelector } from 'react-redux';
import { navigatorTabDeselect } from '../../../../actions/navigator.actions';
import { TNavigatorPanelProps } from './NavigatorPanel.types';
import { NavigatorPanelButton } from './NavigatorPanelButton/NavigatorPanelButton.component';
import { CloseIcon } from './icons/CloseIcon.component';
import { TreeSearchField } from '../TreeSearchField/TreeSearchField.component';
import { NavigatorTreeSearchSelector } from '../../../../selectors/navigatorTreeSearch.selectors';
import { TNavigatorTab } from '../../../../reducers/navigator.reducer.types';
import { NodeId } from '../../../../serverapi/api';
import { getActiveGraph } from '../../../../selectors/editor.selectors';
import { instancesBPMMxGraphMap } from '../../../../mxgraph/bpm-mxgraph-instance-map';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';

export const NavigatorPanel: FC<TNavigatorPanelProps> = (props) => {
    const { type, titleProps, className, customButtons, children, aside, hideCloseButton } = props;

    const isTreeSearchActive: boolean = useSelector(NavigatorTreeSearchSelector.getIsSearchActive);
    const activeGraphId: NodeId | undefined = useSelector(getActiveGraph);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        if (type === TNavigatorTab.Comments) {
            let graph: BPMMxGraph | undefined;
            if (activeGraphId) graph = instancesBPMMxGraphMap.get(activeGraphId);
            graph?.tooltipHandler.close();
        }
        if (props.onClose) {
            return props.onClose();
        }

        return dispatch(navigatorTabDeselect(type));
    }, [props.onClose]);

    return (
        <div data-test={`navigator_tree-container_${titleProps.title}`} className={cx(theme.container, className)}>
            <div className={theme.title}>
                <div data-test="navigator_tree-container_title">
                    {titleProps.title}
                    {titleProps.icon}
                </div>
                {aside && electron && <div className={theme.aside}>{aside}</div>}
                <div className={theme.actions}>
                    {customButtons}
                    {!hideCloseButton && (
                        <div className={theme.closeIconContainer}>
                            <NavigatorPanelButton onClick={onClose} IconComponent={CloseIcon} />
                        </div>
                    )}
                </div>
            </div>
            {isTreeSearchActive && type === TNavigatorTab.Tree && <TreeSearchField />}
            <div className={theme.inner}>{children}</div>
        </div>
    );
};
