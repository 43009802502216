import {
    CHANGE_USER_PASSWORD,
    SET_PERSONAL_PROFILE,
    OPEN_USER_ACCOUNT_TAB,
} from '../actionsTypes/userAccount.actionTypes';
import { PersonalProfile } from '../serverapi/api';
import {
    TChangeUserPasswordAction,
    TOpenUserAccountTabAction,
    TSetPersonalProfileAction,
} from './userAccount.actions.types';

export const openUserAccountTab = (): TOpenUserAccountTabAction => ({
    type: OPEN_USER_ACCOUNT_TAB,
});

export const setPersonalProfile = (data: PersonalProfile): TSetPersonalProfileAction => ({
    type: SET_PERSONAL_PROFILE,
    payload: { data },
});

export const changeUserPassword = (oldPassword: string, newPassword: string): TChangeUserPasswordAction => ({
    type: CHANGE_USER_PASSWORD,
    payload: { oldPassword, newPassword },
});
