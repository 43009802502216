import { TFolderTypeState } from './folderType.reducer.types';
import { TReducer } from '../utils/types';
import {
    FOLDER_TYPE_DELETE_SUCCESS,
    FOLDER_TYPE_REQUEST_SUCCESS,
    FOLDER_TYPE_SAVE_SUCCESS,
} from '../actionsTypes/folderType.actionTypes';
import { CLEAR_PRESET_REQUEST, SUBMIT_PRESET_SUCCESS } from '../actionsTypes/methodologySetting.actionTypes';
import { cloneState } from './utils/cloneState.utils';

export const INITIAL_FOLDER_TYPE_SERVER_STATE = {
    byPresetId: {},
};

const initial: TFolderTypeState = {
    byServerId: {},
};

export const folderTypeReducer: TReducer<TFolderTypeState> = (state = initial, action) => {
    switch (action.type) {
        case FOLDER_TYPE_REQUEST_SUCCESS: {
            const { serverId, presetId, folderTypes } = action.payload;
            const stateClone = cloneState(state, serverId, presetId);

            const { byId } = stateClone.byServerId[serverId].byPresetId[presetId];

            folderTypes.forEach((folderType) => {
                byId[folderType.id] = folderType;
            });

            return stateClone;
        }

        case FOLDER_TYPE_DELETE_SUCCESS: {
            const { serverId, presetId, folderTypeId } = action.payload;
            const stateClone: TFolderTypeState = JSON.parse(JSON.stringify(state));
            // если вместо глубокой копии стейта мутировать текущий стейт
            // и возвращать его поверхностную копию, то компоненты не перерендериваются
            const byId = stateClone.byServerId[serverId]?.byPresetId[presetId].byId;
            delete byId[folderTypeId];

            return stateClone;
        }

        case FOLDER_TYPE_SAVE_SUCCESS: {
            const { serverId, presetId, folderType } = action.payload;
            const stateClone: TFolderTypeState = JSON.parse(JSON.stringify(state));
            const byId = stateClone.byServerId[serverId]?.byPresetId[presetId]?.byId;
            byId[folderType.id] = folderType;

            return stateClone;
        }

        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            const stateClone: TFolderTypeState = JSON.parse(JSON.stringify(state));
            delete stateClone.byServerId?.[serverId]?.byPresetId?.[presetId];

            return stateClone;
        }

        case SUBMIT_PRESET_SUCCESS: {
            const { preset, serverNode } = action.payload;
            const presetId = preset.id;
            const { serverId } = serverNode.nodeId;
            const stateClone: TFolderTypeState = JSON.parse(JSON.stringify(state));
            if (!stateClone.byServerId[serverId]?.byPresetId[presetId]) {
                stateClone.byServerId[serverId] = {
                    byPresetId: {
                        [presetId]: {
                            byId: {},
                        },
                    },
                };
            }

            return stateClone;
        }

        default:
            return state;
    }
};
