import { SessionHistory } from '../serverapi/api';
import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TSessionSelectorParams } from './sessions.selector.types';

export namespace SessionSelectors {
    export const start = (state: TRootState): number | null => state.session.start;

    export const end = (state: TRootState): number | null => state.session.end;

    export const search = (state: TRootState): string => state.session.searchText;

    export const active = (state: TRootState): boolean => state.session.active;

    export const searchData = (state: TRootState): SessionHistory[] => state.session.searchData;

    export const params: TSessionSelectorParams = createSelector(
        start,
        end,
        search,
        active,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (start: number, end: number, search: string, active: boolean) => ({
            start,
            end,
            search,
            active,
        }),
    );
}
