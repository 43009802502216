import React, { FC } from 'react';

type TIconInputServerProps = {
    color: string;
    className?: string;
};

const IconInputLoginComponent: FC<TIconInputServerProps> = ({ color, className }) => {
    return (
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                    fill={color}
                    fillRule="evenodd"
                    d="M7.874 9c-2.46 0-4.512 1.718-4.983 4H3.95a4.046 4.046 0 0 1 3.924-3 4.046 4.046 0 0 1 3.925 3h1.058c-.471-2.282-2.523-4-4.983-4zm0-1.001c1.685 0 3.051-1.343 3.051-2.999 0-1.657-1.366-3.001-3.051-3.001S4.823 3.343 4.823 5c0 1.656 1.366 2.999 3.051 2.999zm0-4.999c1.124 0 2.035.896 2.035 2 0 1.105-.911 2-2.035 2C6.751 7 5.84 6.105 5.84 5c0-1.104.911-2 2.034-2z"
                />
                <path fill="none" d="M0 0h16v16H0V0z" />
            </svg>
        </div>
    );
};

export default IconInputLoginComponent;
