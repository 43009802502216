import type { MxUndoableEdit } from './mxgraph';
import { MxEvent } from './mxgraph';
import { MxEventObject } from './mxgraph';
import { MxUndoManager } from './mxgraph';
import MxGraphSideEffects from './sideEffects';

export class BPMMxUndoManager extends MxUndoManager {
    removeCurrentHistoryItem() {
        const removedEdits: MxUndoableEdit[] = this.history.splice(this.indexOfNextAdd, 1);

        for (let i = 0; i < removedEdits.length; i++) {
            removedEdits[i].die();
        }
    }

    undo() {
        while (this.indexOfNextAdd > 0) {
            const edit = this.history[--this.indexOfNextAdd];

            if (MxGraphSideEffects.isChangeRevertable(edit)) {
                edit.undo();

                if (edit.isSignificant()) {
                    this.fireEvent(new MxEventObject(MxEvent.UNDO, 'edit', edit));
                    break;
                }
            } else {
                this.removeCurrentHistoryItem();
            }
        }
    }

    redo() {
        while (this.indexOfNextAdd < this.history.length) {
            const edit = this.history[this.indexOfNextAdd];

            if (MxGraphSideEffects.isChangeRevertable(edit)) {
                this.indexOfNextAdd++;
                edit.redo();

                if (edit.isSignificant()) {
                    this.fireEvent(new MxEventObject(MxEvent.REDO, 'edit', edit));
                    break;
                }
            } else {
                this.removeCurrentHistoryItem();
            }
        }
    }
}
