import { connect } from 'react-redux';
import { ObjectDefinitionDialog } from '../components/ObjectDefinition/ObjectDefinitionDialog.component';
import { TObjectDefinitionDialogProps } from '../components/ObjectDefinition/ObjectDefinition.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { objectDefinitionDialogSubmit } from '../../../actions/objectDefinitionDialog.actions';
import { ObjectType, NodeId } from '../../../serverapi/api';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { TreeNode } from '../../../models/tree.types';
import { UserProfileSelectors } from '../../../selectors/userProfile.selectors';
import { getCreatableObjectDefinitionTypes } from '../../../services/bll/FolderTypeBLLService';
import { FolderTypeSelectors } from '../../../selectors/folderType.selectors';

type TObjectDefinitionDialogContainerProps = TObjectDefinitionDialogProps & {
    parentNodeId: NodeId;
    objectTypeList: ObjectType[];
    objectDefinitionDialogTree: TreeNode[];
};

const mapStateToProps = (
    state: TRootState,
    props: TObjectDefinitionDialogContainerProps,
): Partial<TObjectDefinitionDialogProps> => {
    const {
        objectTypeList,
        parentNodeId: { serverId },
        parentNodeId,
    } = props;
    const selectedNode: TreeNode = TreeSelectors.itemById(parentNodeId)(state)!;
    const presetId: string = TreeSelectors.presetById(parentNodeId)(state);
    let objectDefinitionTypes = objectTypeList || [];

    objectDefinitionTypes = objectDefinitionTypes.filter((t) =>
        UserProfileSelectors.isObjectTypeCreatable(serverId, presetId, t.id)(state),
    );

    const parentFolderType = FolderTypeSelectors.byId({
        serverId,
        presetId,
        folderTypeId: selectedNode?.folderType || '',
    })(state);

    objectDefinitionTypes = getCreatableObjectDefinitionTypes(objectDefinitionTypes, parentFolderType);

    return {
        formInitData: {
            objectDefinitionTypes,
            selectedNode,
        },
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.OBJECT_DEFINITION_CREATE));
    },
    onSubmit: (parentNodeId: NodeId, objectDefinitionType: ObjectType, objectDefinitionName: string) =>
        dispatch(objectDefinitionDialogSubmit({ parentNodeId, objectDefinitionType, objectDefinitionName })),
});

export const ObjectDefinitionDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ObjectDefinitionDialog);
