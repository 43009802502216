import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { EdgeDefinitionNode, EdgeInstance, NodeId } from '../serverapi/api';

export const edgeDefinitionStateSelector = (state: TRootState) => state.edgeDefinition;

export namespace EdgeDefinitionSelectors {
    export const byId = (nodeId: NodeId) => createSelector(edgeDefinitionStateSelector, (state) => state.get(nodeId));

    export const byIds = (ids: NodeId[]) =>
        createSelector(edgeDefinitionStateSelector, (state) => ids.map((nodeId) => state.get(nodeId)));

    export const values = () => createSelector(edgeDefinitionStateSelector, (state) => state.values());

    export const byServerIdRepositoryId = (serverId: string, repositoryId: string) =>
        createSelector(edgeDefinitionStateSelector, (state) => {
            const objectsMap: { [key: string]: EdgeDefinitionNode } = {};
            state.values().forEach((o) => {
                const { nodeId } = o;
                if (nodeId.serverId === serverId && nodeId.repositoryId === repositoryId) {
                    objectsMap[nodeId.id] = o;
                }
            });

            return objectsMap;
        });

    export const byInstanceAndModelId = (instance: EdgeInstance, modelId: NodeId) =>
        createSelector(
            (state: TRootState) => {
                return instance.edgeDefinitionId
                    ? byId({ ...modelId, id: instance.edgeDefinitionId })(state)
                    : undefined;
            },
            (edgeDefinition: EdgeDefinitionNode | undefined) => edgeDefinition,
        );
}
