// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputSynonymsIds__formItem__SuPjO .ant-form-item-label{padding-bottom:0}", "",{"version":3,"sources":["webpack://./src/modules/InputSynonymsIds/InputSynonymsIds.scss"],"names":[],"mappings":"AAEQ,wDACI,gBAAA","sourcesContent":[".formItem {\r\n    :global {\r\n        .ant-form-item-label {\r\n            padding-bottom: 0;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": "InputSynonymsIds__formItem__SuPjO"
};
export default ___CSS_LOADER_EXPORT___;
