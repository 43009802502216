import { connect } from 'react-redux';
import { Workspace } from '../components/Workspace/Workspace.component';
import { TRootState } from '../../../reducers/root.reducer.types';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { workspaceActivateTab, workspaceAddTab, workspaceRemoveTabRequest } from '../../../actions/tabs.actions';
import { TWorkspaceTab } from '../../../models/tab.types';
import { workSpaceTabComponents } from '../workspaceTab.constants';
import { TWorkspaceProps } from '../Workspace.types';
import { activeContextMenuChange } from '../../../actions/contextMenu.actions';

const mapStateToProps = (state: TRootState): Partial<TWorkspaceProps> => ({
    tabs: TabsSelectors.getTabList(state) || [],
    components: workSpaceTabComponents,
    activeKey: TabsSelectors.getActiveTabId(state),
});

const mapDispatchToProps: (dispatch, ownProps: {}) => Partial<TWorkspaceProps> = (dispatch) => ({
    onAddTab: (tab: TWorkspaceTab) => dispatch(workspaceAddTab(tab)),
    onChangeTab: (tab: TWorkspaceTab) => {
        dispatch(activeContextMenuChange());
        dispatch(workspaceActivateTab(tab));
    },
    onRemoveTabRequest: (tab: TWorkspaceTab) => dispatch(workspaceRemoveTabRequest(tab)),
});

export const TabContainer = connect(mapStateToProps, mapDispatchToProps)(Workspace);
