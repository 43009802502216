import { NodeId } from '../../../serverapi/api';
import messages from './SystemProperties.messages';
import { WorkSpaceTabTypes } from 'src/modules/Workspace/WorkSpaceTabTypesEnum';
import { IWorkspaceTabServerSettingsParams, TWorkspaceTabItemActions } from 'src/models/tab.types';
import { FormInstance } from 'antd';

export type TColorsPicker = {
    mainColor?: string;
    secondColor?: string;
    selectedRowColor?: string;
};

export enum CheckStatus {
    validating,
    error,
    success,
    internalFail,
}

export enum KeycloakStatus {
    KEYCLOAK_IS_ACTIVE = 'KEYCLOAK_IS_ACTIVE',
    FAIL = 'FAIL',
}

export enum SideBarItemsId {
    SERVER = 'server',
    MODEL = 'model',
    USER_SESSION = 'userSession',
    SCRIPT_ENGINE = 'scriptEngine',
    IMAGE = 'image',
    LDAP = 'ldap',
    KERBEROS = 'kerberos',
    KEYCLOAK = 'keycloak',
    APP_DB = 'appDb',
    MAIL_SETTINGS = 'mailSetting',
    INTERFACE_SETTINGS = 'interfaceSetting',
    HOME_PAGE_SETTINGS = 'homePageSetting',
    ADDITIONAL_SETTINGS = 'additionalSetting',
    SECURITY = 'security',
    LICENSE_AGREEMENT = 'licenseAgreement',
}

export const menuItems = [
    { id: SideBarItemsId.SERVER, titleId: messages.server },
    { id: SideBarItemsId.MODEL, titleId: messages.model },
    { id: SideBarItemsId.USER_SESSION, titleId: messages.userSession },
    { id: SideBarItemsId.SCRIPT_ENGINE, titleId: messages.scriptEngine },
    { id: SideBarItemsId.IMAGE, titleId: messages.image },
    { id: SideBarItemsId.LDAP, titleId: messages.ldap },
    { id: SideBarItemsId.KERBEROS, titleId: messages.kerberos },
    { id: SideBarItemsId.KEYCLOAK, titleId: messages.keycloak },
    { id: SideBarItemsId.APP_DB, titleId: messages.appDb },
    { id: SideBarItemsId.MAIL_SETTINGS, titleId: messages.mailSetting },
    { id: SideBarItemsId.INTERFACE_SETTINGS, titleId: messages.interfaceSetting },
    { id: SideBarItemsId.HOME_PAGE_SETTINGS, titleId: messages.homePageSetting },
    { id: SideBarItemsId.ADDITIONAL_SETTINGS, titleId: messages.additionalSetting },
    { id: SideBarItemsId.SECURITY, titleId: messages.security },
    { id: SideBarItemsId.LICENSE_AGREEMENT, titleId: messages.licenseAgreement },
];

export enum SystemPropertiesFormItemNames {
    ldapSettings = 'ldapSettings',
    serverUrl = 'serverUrl',
    serverName = 'serverName',
    serverIp = 'serverIp',
    serverPort = 'serverPort',
    serverTime = 'serverTime',
    fileTempStoragePath = 'fileTempStoragePath',
    modelAutoSaveTimeout = 'modelAutoSaveTimeout',
    modelVersionSaveTimeout = 'modelVersionSaveTimeout',
    userSessionInactivity = 'userSessionInactivity',
    userSessionLengthMax = 'userSessionLengthMax',
    scriptEngineUrl = 'scriptEngineUrl',
    serverCallbackUrl = 'serverCallbackUrl',
    uiUrl = 'uiUrl',
    imageUrl = 'imageUrl',
    id = 'id',
    baseDn = 'baseDn',
    bindDn = 'bindDn',
    bindPasswd = 'bindPasswd',
    domainNameNetBios = 'domainNameNetBios',
    domainName = 'domainName',
    usersGroup = 'usersGroup',
    ignoreFailCert = 'ignoreFailCert',
    defaultDomain = 'defaultDomain',
    searchFilter = 'searchFilter',
    ldapServerUrl = 'ldapServerUrl',
    serverUrlAlt = 'serverUrlAlt',
    ignore = 'ignore',
    kerberosKrb5Path = 'kerberosKrb5Path',
    kerberosDebugStatus = 'kerberosDebugStatus',
    appDbHost = 'appDbHost',
    appDbName = 'appDbName',
    appDbPort = 'appDbPort',
    appDbUser = 'appDbUser',
    appDbUrl = 'appDbUrl',
    mailProtocol = 'mailProtocol',
    mailHost = 'mailHost',
    mailPort = 'mailPort',
    mailUser = 'mailUser',
    mailPassword = 'mailPassword',
    mailFrom = 'mailFrom',
    mailSsl = 'mailSsl',
    mailSslProtocols = 'mailSslProtocols',
    isOpenNavigator = 'isOpenNavigator',
    isCompactMainMenu = 'isCompactMainMenu',
    greetingLogo = 'greetingLogo',
    isOpenMainMenu = 'isOpenMainMenu',
    mainColor = 'mainColor',
    secondColor = 'secondColor',
    selectedRowColor = 'selectedRowColor',
    isActiveHidingUsers = 'isActiveHidingUsers',
    experimentalFeatures = 'experimentalFeatures',
    isActiveAuthorizationSettings = 'isActiveAuthorizationSettings',
    activeAuthorizationMethods = 'activeAuthorizationMethods',
    blockAuthTime = 'blockAuthTime',
    maxFailAuthAttempts = 'maxFailAuthAttempts',
    minPasswordLength = 'minPasswordLength',
    isActiveUpperAndLowerCase = 'isActiveUpperAndLowerCase',
    isActiveNumbers = 'isActiveNumbers',
    isActiveSpecialCharacters = 'isActiveSpecialCharacters',
    passwordMinDurationTime = 'passwordMinDurationTime',
    passwordMaxDurationTime = 'passwordMaxDurationTime',
    countOfLastUsedPasswords = 'countOfLastUsedPasswords',
    isActiveSilaAgreement = 'isActiveSilaAgreement',
    multilingualSilaAgreementDescription = 'multilingualSilaAgreementDescription',
    isActiveAgreement = 'isActiveAgreement',
    multilingualAgreementDescription = 'multilingualAgreementDescription',
    agreement = 'agreement',
    silaAgreement = 'silaAgreement',
    extraButtonProperties = 'extraButtonProperties',
    extraButtonPropertiesPanelWidth  = 'extraButtonPropertiesPanelWidth',
    messageStorageDays = 'messageStorageDays',
    keycloakSettings = 'keycloakSettings',
    realm = 'realm',
    baseUrl = 'baseUrl',
    clientId = 'clientId',
    receiveGroupsFromLdap = 'receiveGroupsFromLdap',
    updateUserInfoLdap = 'updateUserInfoLdap',
    receiveGroupsFromKeycloak = 'receiveGroupsFromKeycloak',
    updateUserInfoKeycloak = 'updateUserInfoKeycloak',
    isEnLocaleAllowed = 'isEnLocaleAllowed',
    kerberosReceiveGroupsFromLdap = 'kerberosReceiveGroupsFromLdap',
    kerberosUpdateUserInfoFromLdap = 'kerberosUpdateUserInfoFromLdap',
    greetingText = 'greetingText',
    greetingTextInput = 'greetingTextInput',
    passwordChangePermission = 'passwordChangePermission',
}

export const DEFAULT_MAIN_COLOR: string = '#0088cc';

export const DEFAULT_SECOND_COLOR: string = '#41bcfb';

export const DEFAULT_SELECTED_ROW_COLOR: string = '#41bcfb';

export const SESSION_MIN_VALUE = 300;
// значение выбрано исходя из максимально возможного значения типа int деленного на 1000(млсек -> сек)

export const SESSION_MAX_VALUE = 2147483;

export const MODEL_SAVE_MAX_LENGTH = SESSION_MAX_VALUE;

export const BUTTONS_PANNEL_MIN_WIDTH = 150;

export const BUTTONS_PANNEL_MAX_WIDTH = 800;

type TTab = {
    title: string;
    nodeId: NodeId;
    type: WorkSpaceTabTypes;
    params: IWorkspaceTabServerSettingsParams;
    actions: TWorkspaceTabItemActions;
};

export type TSystemPropertiesProps = {
    tab: TTab;
};

export type TPropsWithFormRef = {
    formRef: React.RefObject<FormInstance<any>>;
};

export const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        lg: { span: 8 },
    },
    wrapperCol: {
        sm: { span: 24 },
        lg: { span: 10 },
    },
};

export type TLdapFormRef = {
    [key: string]: React.RefObject<FormInstance<any>>;
};
