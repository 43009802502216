export const WIKI_TABLE_KEY = 'tableKey';

export const WIKI_TABLE_SHAPE_KEY = 'tableShape';

export const WIKI_TABLE_POSITION_KEY = 'tablePosition';

export const WIKI_TABLE_TABLE_STYLE_KEY = 'tableStyle';

export const WIKI_TABLE_ROW_STYLE_KEY = 'rowStyle';

export const WIKI_TABLE_SHOW_HEADER_KEY = 'showHeader';
