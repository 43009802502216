export enum PortsOrientation {
    W,
    E,
    N,
    S,
    NNW,
    NNE,
    ENE,
    ESE,
    SSE,
    SSW,
    WSW,
    WNW,
}
