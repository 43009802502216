import type { IComplexSymbolSerializer } from './ComplexSymbolSerializer.class.types';
import type { MxCell } from 'MxGraph';
import { grabFrameCells, encode, markCellsAsFrame } from '../../utils';
import { v4 as uuid } from 'uuid';

export default class MetaInfoSerializer implements IComplexSymbolSerializer {
    frameId = uuid();
    rootCell: MxCell;

    afterCreate(root: MxCell): MxCell {
        this.rootCell = root;
        this.includeCell(root);

        return root;
    }

    includeCell(root: MxCell) {
        markCellsAsFrame([root], this.frameId);
    }

    serialize() {
        const root = this.rootCell;
        const rootCellValue = root.getValue();
        const { x, y } = root.getGeometry();
        const frameCells = grabFrameCells([root], this.frameId);

        return {
            ...rootCellValue,
            parent: root.getParent().getId(),
            metaInfo: encode(frameCells),
            x,
            y,
        };
    }
}
