import { EDITOR_CHANGE_EDGE_TYPE } from '../actionsTypes/editor.actionTypes';
import { TStatisticsState } from './statistics.reducer.types';

const initial: TStatisticsState = {
    lastEdgesTypesIds: {},
};

export const statisticsReducer = (state = initial, action) => {
    switch (action.type) {
        case EDITOR_CHANGE_EDGE_TYPE: {
            const { type } = action.payload;
            return {
                ...state,
                lastEdgesTypesIds: {
                    ...state.lastEdgesTypesIds,
                    [type]: Date.now(),
                },
            };
        }
        default:
            return state;
    }
};

