import React, { FC, useEffect, useRef } from 'react';
import theme from './BarChart.scss';
import messages from '../widget.messages';
import { useIntl } from 'react-intl';
import { TChartData, TWidgetProps } from '../widget.types';
import {
    Chart,
    BarController,
    BarElement,
    LinearScale,
    CategoryScale,
    PointElement,
    ChartConfiguration,
    ChartData,
    Legend,
    Title,
} from 'chart.js';
import { Col } from 'antd';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const BarChart: FC<TWidgetProps> = ({ data, title }) => {
    const intl = useIntl();
    const { datasets, labels } = data as TChartData;

    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        Chart.register(
            BarController,
            LinearScale,
            BarElement,
            CategoryScale,
            PointElement,
            Legend,
            Title,
            ChartDataLabels,
        );
    }, []);

    const chartData: ChartData<'bar', any[], any> = {
        labels: labels,
        datasets: datasets.map((dataset) => {
            return { ...dataset, backgroundColor: dataset.borderColor };
        }),
    };

    const config: ChartConfiguration<'bar', any[], any> = {
        type: 'bar',
        data: chartData,
        plugins: [ChartDataLabels],
        options: {
            plugins: {
                legend: {
                    position: 'bottom',
                    display: true,
                    labels: {
                        filter: (item) => !!item.text,
                        usePointStyle: true,
                        pointStyle: 'rectRounded',
                    },
                },
                title: {
                    display: true,
                    text: title,
                    padding: {
                        bottom: 20,
                        top: 5,
                    },
                },
                datalabels: {
                    align: 'top',
                    anchor: 'end',
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                },
                y: {
                    display: false,
                },
            },
            elements: {
                bar: {
                    borderRadius: 8,
                    borderSkipped: false,
                },
            },
            maintainAspectRatio: false,
        },
    };

    useEffect(() => {
        let lineChart: Chart<'bar', number[], string> | null = null;
        if (ref.current) {
            lineChart = new Chart(ref.current, config);
        }
        return () => {
            lineChart && lineChart.destroy();
        };
    }, [ref, data, title]);

    const checkData = datasets.some(({ data }) => data.length);

    return (
        <div className={theme.lineChartContainer}>
            {checkData ? (
                <canvas ref={ref}></canvas>
            ) : (
                <Col style={{ width: '100%' }}>
                    <div className={theme.header}>{title}</div>
                    <div className={theme.text}>{intl.formatMessage(messages.noData)}</div>
                </Col>
            )}
        </div>
    );
};
