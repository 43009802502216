import type { Symbol } from '@/serverapi/api';
import { SequenceSymbolTypeId, SymbolTypeId } from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { DefaultId } from '@/serverapi/api';
import {
    horizontalSwimlaneIcon,
    lifelineActorIcon,
    lifelineBounderyIcon,
    lifelineControlIcon,
    lifelineEntityIcon,
    lifelineIcon,
    verticalSwimlaneIcon,
} from './Symbols.constants';

export const getShape = (shape: string): Symbol => ({
    id: shape,
    presetId: DefaultId.DEFAULT_PRESET_ID,
    graphical: shape,
    name: shape,
    description: '',
    icon: '',
    objectType: '',
    showLabel: true,
    width: 75,
    height: 80,
    color: 'RGB(30,30,30)',
    style: `shape=${shape};rounded=1;fillColor=#fff;outlineColor=#00000;`,
});

const umlMessgae =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCIgdmlld0JveD0iNTEzLjUgMzEwLjUgMjggMjgiPjxkZWZzLz48ZyBkYXRhLXRlc3Q9ImdyYXBoX2VsZW1lbnQiPjxlbGxpcHNlIGN4PSI1MjcuNSIgY3k9IjMyNC41IiByeD0iMTMuNSIgcnk9IjEzLjUiIGZpbGw9IiMwMDAwMDAiIHN0cm9rZT0ibm9uZSIgcG9pbnRlci1ldmVudHM9Im5vbmUiLz48L2c+PC9zdmc+';

export const getSwimlaneSymbolWithIcon = (symbol: Symbol): Symbol => {
    const symbolType = ComplexSymbolManager.getSymbolType(symbol) as SymbolTypeId | SequenceSymbolTypeId;

    let icon = symbol.icon || '';

    if (!icon) {
        switch (symbolType) {
            case SymbolTypeId.HORIZONTAL_SWIMLANE:
                icon = horizontalSwimlaneIcon;
                break;
            case SymbolTypeId.VERTICAL_SWIMLANE:
                icon = verticalSwimlaneIcon;
                break;
            case SequenceSymbolTypeId.LIFE_LINE:
                icon = lifelineIcon;
                break;
            case SequenceSymbolTypeId.LIFE_LINE_BOUNDERY:
                icon = lifelineBounderyIcon;
                break;
            case SequenceSymbolTypeId.LIFE_LINE_CONTROL:
                icon = lifelineControlIcon;
                break;
            case SequenceSymbolTypeId.LIFE_LINE_ENTITY:
                icon = lifelineEntityIcon;
                break;
            case SequenceSymbolTypeId.LIFE_LINE_ACTOR:
                icon = lifelineActorIcon;
                break;
            case SymbolTypeId.UML_MESSAGE:
                icon = umlMessgae;
                break;
            default:
                icon = '';
        }
    }

    return { ...symbol, icon };
};
