import { Resizable } from 're-resizable';
import React, { useRef, useState } from 'react';
import theme from './ImageRenderer.component.scss';
import classnames from 'classnames';
import { Card } from 'antd';
import { getImage, restoreFocus, updateImage } from './ImageContentBlocks.utils';
import WikiImageContextMenuComponent from './ImageContextMenu.component';

const ImageRenderer = (props) => {
    const { getEditorState, changeEditorState, imageLinkMapper } = props.blockProps;
    const ref = useRef<any>();
    const { contentState, block } = props;

    const { src, alt, title, width: imageWidth, height: imageHeight } = getImage(contentState, block);

    const [width, setWidth] = useState(imageWidth);
    const [height, setHeight] = useState(imageHeight);
    const onResizeEnd = (event: MouseEvent | TouchEvent, direction, elementRef: HTMLDivElement) => {
        const rect = elementRef.getBoundingClientRect();
        const editorState = getEditorState();

        setWidth(rect.width);
        setHeight(rect.height);
        updateImage(editorState, block, {
            width: rect.width,
            height: rect.height,
        });
        restoreFocus(editorState, changeEditorState);
    };

    const imageSrc = imageLinkMapper(src);

    return (
        <span ref={ref}>
            <WikiImageContextMenuComponent
                getEditorState={getEditorState}
                changeEditorState={changeEditorState}
                block={block}
                items={
                    <Resizable
                        size={{ width, height }}
                        onResizeStop={onResizeEnd}
                        className={theme.resizeContainer}
                        bounds="window"
                    >
                        <Card className={theme.imageCard}>
                            <img src={imageSrc} alt={alt} title={title} />
                        </Card>
                        <span className={classnames(theme.resizePoints, theme.resizePointsTop)} />
                        <span className={classnames(theme.resizePoints, theme.resizePointsBottom)} />
                        <span className={classnames(theme.resizePoints, theme.resizePointsMidH)} />
                        <span className={classnames(theme.resizePoints, theme.resizePointsMidV)} />
                    </Resizable>
                }
            />
        </span>
    );
};
const getImageRenderer = (imageLinkMapper) => (ownProps) => ImageRenderer({ imageLinkMapper, ...ownProps });

export { ImageRenderer, getImageRenderer };
