import { AnyAction } from 'redux';
import { SCRIPT_SCHEDULE_LOADING, SCRIPT_SCHEDULE_SET } from '../actionsTypes/entities/script.actionTypes';
import { TScriptSchedulerReducerState } from './scriptScheduler.reducer.types';

export const scriptSchedulerReducer = (state: TScriptSchedulerReducerState = {}, action: AnyAction) => {
    const { type, payload } = action;

    switch (type) {
        case SCRIPT_SCHEDULE_SET: {
            return {
                ...state,
                [payload.serverId]: {
                    taskList: payload.schedule,
                    isLoading: false,
                },
            };
        }
        case SCRIPT_SCHEDULE_LOADING: {
            return {
                ...state,
                [payload.serverId]: {
                    ...state[payload.serverId],
                    isLoading: payload.isLoading,
                },
            };
        }

        default: {
            return state;
        }
    }
};
