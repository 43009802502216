import React, { ChangeEvent, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Col, FormInstance, Select } from 'antd';
import presetCss from '../../Presets.scss';
import css from './KanbanModelTypeGeneralTab.scss';
import messages from '../KanbanModelType.messages';
import { ModelTypeGroup, InternationalString, KanbanBoardType } from '../../../../../../../serverapi/api';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { InputId } from '../../../../../../InputId/InputId.component';
import {
    changeKanbanModelTypeDescription,
    changeKanbanModelTypeGroupId,
    changeKanbanModelTypeId,
    changeKanbanModelTypeName,
} from '../../../../../../../actions/workspaceTab/editKanbanModelTypeWorkspaceTab.actions';

type TModelTypeGeneralTabProps = {
    modelType: KanbanBoardType;
    createMode: boolean;
    generalForm: FormInstance<any> | undefined;
    modelTypeGroups: ModelTypeGroup[];
};

const KanbanModelTypeGeneralTab: FC<TModelTypeGeneralTabProps> = (props) => {
    const dispatch = useDispatch();
    const { createMode, modelType, generalForm, modelTypeGroups } = props;
    const { presetId } = modelType;
    const modelTypeId = modelType.id;
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);

    const onChangeModelTypeName = useCallback((multilingualName: InternationalString) => {
        dispatch(changeKanbanModelTypeName({ multilingualName, presetId, modelTypeId }));
    }, []);

    const onChangeModelTypeId = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeKanbanModelTypeId({ presetId, modelTypeId, id: e.target.value.trim() }));
    }, []);

    const onChangeModelTypeDescription = useCallback((multilingualDescription: InternationalString) => {
        dispatch(changeKanbanModelTypeDescription({ presetId, modelTypeId, multilingualDescription }));
    }, []);

    const onChangeModelTypeGroupId = useCallback((groupId: string) => {
        dispatch(changeKanbanModelTypeGroupId({ presetId, modelTypeId: modelTypeId, groupId }));
    }, []);

    return (
        <div data-test="kanban-model-type-general-tab" className={presetCss.form}>
            <div className={css.firstRow}>
                <div className={css.name}>
                    <MultiLangInputDialog
                        data-test="kanban-model-type-name-input"
                        placeholder={intl.formatMessage(messages.name)}
                        multiLangValue={modelType.multilingualName}
                        onChange={onChangeModelTypeName}
                        label={intl.formatMessage(messages.name)}
                        mainInputName="multilingualName"
                        generalForm={generalForm}
                        required
                        autoFocus
                    />
                </div>
            </div>
            <div className={presetCss.guidInput}>
                <InputId
                    disabled={!createMode}
                    value={modelType.id}
                    required
                    mainInputName="GUID"
                    onChange={onChangeModelTypeId}
                />
            </div>
            <Col span={12} className={presetCss.selectGroupType}>
                <div>{intl.formatMessage(messages.modelType)}</div>
                <Select onChange={() => {}} value="kanbanBoard" disabled>
                    <Select.Option value="kanbanBoard">{intl.formatMessage(messages.kanbanBoard)}</Select.Option>
                </Select>
            </Col>
            <div className={presetCss.description}>
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.description)}
                    multiLangValue={modelType.multilingualDescription}
                    onChange={onChangeModelTypeDescription}
                    textarea
                    label={intl.formatMessage(messages.description)}
                    mainInputName="multilingualDescription"
                    generalForm={generalForm}
                    data-test="kanban-model-type-description-input"
                />
            </div>
            <Col span={12} className={presetCss.selectGroupType}>
                <div>{intl.formatMessage(messages.modelTypeGroup)}</div>
                <Select onChange={onChangeModelTypeGroupId} value={modelType.groupId}>
                    {modelTypeGroups.map((group: ModelTypeGroup) => {
                        return (
                            <Select.Option key={group.id} value={group.id}>
                                {LocalesService.internationalStringToString(group.multilingualName, currentLocale)}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Col>
        </div>
    );
};

const withMemo = React.memo(KanbanModelTypeGeneralTab);

export { withMemo as KanbanModelTypeGeneralTab };
