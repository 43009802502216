export const EDGE_TYPE_REQUEST_SUCCESS = 'EDGE_TYPE_REQUEST_SUCCESS';

export const EDGE_TYPE_DELETE_REQUEST_SUCCESS = 'EDGE_TYPE_DELETE_REQUEST_SUCCESS';

export const CHECK_INVISIBLE_EDGE_TYPE = 'CHECK_INVISIBLE_EDGE_TYPE';

export const SUBMIT_EDGE_TYPE = 'SUBMIT_EDGE_TYPE';

export const DELETE_EDGE_TYPE = 'DELETE_EDGE_TYPE';

export const CREATE_EDGE_TYPE = 'CREATE_EDGE_TYPE';

export const EDIT_EDGE_TYPE = 'EDIT_EDGE_TYPE';

export const EDGE_TYPE_REQUEST_FAILURE = 'EDGE_TYPE_REQUEST_FAILURE';

export const DELETE_EDGE_TYPE_AND_GROUP = 'DELETE_EDGE_TYPE_AND_GROUP';
