import { connect } from 'react-redux';
import { TModelFormProps } from '../../ModelDialog/components/ModelDialog/Model/ModelForm.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { NodeId } from '../../../serverapi/api';
import { TreeNode, TTreeEntityState } from '../../../models/tree.types';
import { TreeItemType } from '../../Tree/models/tree';
import {
    filterTreeIncludeTypes,
    getTreeItems,
    treeNestedChildrenMap,
    TreeSelectors,
} from '../../../selectors/tree.selectors';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { CloneModelDialog, ModelCloneStrategy } from '../components/CloneModelDialog.component';
import { cloneModel } from '../../../actions/cloneModel.actions';
import { treeItemCollapseAll, treeItemsClearSelection } from '../../../actions/tree.actions';
import { TCloneModelDialogContainerProperties, TOwnProperties } from '../CloneModelDialog.types';

const INCLUDE_TYPES: TreeItemType[] = [TreeItemType.Repository, TreeItemType.Folder];

const mapStateToProps = (
    state: TRootState,
    props: Partial<TCloneModelDialogContainerProperties>,
): Partial<TModelFormProps> => {
    const serverId = props.modelNodeId?.serverId || ServerSelectors.connected(state)[0];
    const repositoryId = props.modelNodeId?.repositoryId;

    let nodes: TreeNode[];
    if (repositoryId && serverId) {
        const treeItemsById: { [id: string]: TTreeEntityState } = getTreeItems(serverId, repositoryId)(state);
        nodes = treeNestedChildrenMap(treeItemsById, repositoryId);
    } else {
        nodes = TreeSelectors.treeStructure(state).filter((n) => n.nodeId.serverId === serverId);
    }
    nodes = filterTreeIncludeTypes(nodes, INCLUDE_TYPES);

    return {
        treeLocation: nodes,
    };
};

const mapDispatchToProps = (dispatch, ownProperties: TOwnProperties) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.CLONE_MODEL_DIALOG));
        dispatch(treeItemCollapseAll(DialogType.CLONE_MODEL_DIALOG));
        dispatch(treeItemsClearSelection());
    },
    onSubmit: (strategy: ModelCloneStrategy, parentNodeIdOfModelCopy: NodeId) => {
        dispatch(cloneModel(strategy, ownProperties.modelNodeId, parentNodeIdOfModelCopy));
        dispatch(closeDialog(DialogType.CLONE_MODEL_DIALOG));
        dispatch(treeItemCollapseAll(DialogType.CLONE_MODEL_DIALOG));
        dispatch(treeItemsClearSelection());
    },
});

export const CloneModelDialogContainer = connect(mapStateToProps, mapDispatchToProps)(CloneModelDialog);
