import React, { createRef, Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Form, FormInstance, Input, Select } from 'antd';
import loginDatabase from '../../../../resources/icons/ic-tree-database-on.svg';
import { NodeId, PresetDTO } from '../../../../serverapi/api';
import { TMessage } from '../../../../utils/forms.types';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../../UIKit/components/Dialog/Dialog.types';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import messages from './DatabaseDialog.messages';
import { TCreateDatabasePayload } from '../../../../actions/databaseDialog.actions.types';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TDatabaseDialogProps = WrappedComponentProps & {
    error?: TMessage;
    parentId: NodeId;
    presets: Array<PresetDTO>;
    open: boolean;
    onSubmit: (payload: TCreateDatabasePayload) => void;
    onCancel: () => void;
    onDelete?: (id: string) => void;
};

type TFormValues = {
    dbName: string;
    presetId: string;
};

class DatabaseDialog extends Component<TDatabaseDialogProps> {
    formRef = createRef<FormInstance>();

    getPrimePreset = () => {
        const { presets } = this.props;
        const [primePreset] = presets.filter((preset) => preset.prime);

        return primePreset?.id;
    };

    handleSubmit = () => {
        const form = this.formRef.current;
        if (form) {
            form.validateFields()
                .then((formValues: TFormValues) => {
                    this.props.onSubmit({
                        ...formValues,
                        parentId: this.props.parentId,
                    });
                })
                .catch(() => undefined);
        }
    };

    handleCancel = () => {
        this.props.onCancel();
    };

    render() {
        const { intl, open } = this.props;

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: this.handleCancel,
                        value: intl.formatMessage(messages.cancel),
                        dataTest: 'create-element_bd_cancel-button',
                    },
                    {
                        key: 'ok',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.save),
                        visualStyle: 'primary',
                        dataTest: 'create-element_bd_save-button',
                    },
                ]}
            />
        );

        const dialogProps: Partial<TDialogProps> = {
            open: open,
            title: intl.formatMessage(messages.title),
            onCancel: this.handleCancel,
            onOk: this.handleSubmit,
            footer: footer,
        };

        const selectOptions = this.props.presets.map((preset) => {
            return {
                label: <div data-test={preset.name}>{preset.name}</div>,
                value: preset.id,
            };
        });

        return (
            <Dialog {...dialogProps}>
                {/* todo 2979 alert */}
                {this.props.error && <Alert type="error" message={intl.formatMessage(this.props.error)} showIcon />}
                <Form autoComplete='off' layout="vertical" ref={this.formRef}>
                    <FormGroup>
                        <Form.Item
                            name="dbName"
                            label={intl.formatMessage(messages.dbNameLabel)}
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: intl.formatMessage(messages.requiredField),
                                },
                            ]}
                        >
                            <Input
                                placeholder={intl.formatMessage(messages.dbNamePlaceholder)}
                                data-test="data-base-form_base-name-input"
                                prefix={<Icon spriteSymbol={loginDatabase} />}
                                autoComplete="off"
                                autoFocus
                            />
                        </Form.Item>
                        <Form.Item
                            name="presetId"
                            initialValue={this.getPrimePreset()}
                            label={intl.formatMessage(messages.preset)}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage(messages.requiredField),
                                },
                            ]}
                        >
                            <Select
                                data-test="ant-select-methodology"
                                placeholder={intl.formatMessage(messages.preset)}
                                showSearch
                                options={selectOptions}
                                filterOption={(input, option) => {
                                    return (option?.label?.props?.children ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase());
                                }}
                            />
                        </Form.Item>
                    </FormGroup>
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(DatabaseDialog);

export { IntlComponent as DatabaseDialog };
