import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { INITIAL_OBJECT_TYPE_SERVER_STATE } from '../reducers/modelTypeGroup.reducer';
import { ModelTypeGroup } from '../serverapi/api';

export const modelTypeGroupStateSelector = (state: TRootState) => state.modelTypeGroup;

export const deletedModelTypeGroupStateSelector = (state: TRootState) => state.deletedModelTypeGroup;

export namespace ModelTypeGroupSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(modelTypeGroupStateSelector, (state) => {
            const s = state.byServerId[serverId];
            if (s) {
                return { ...s };
            }

            return { ...INITIAL_OBJECT_TYPE_SERVER_STATE };

        });

    export const byPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), (state) => state.byPresetId[compositeId.presetId]);

    export const byId = (compositeId: { modelTypeGroupId: string; presetId: string; serverId: string }) =>
        createSelector(byPresetId({ serverId: compositeId.serverId, presetId: compositeId.presetId }), (state) => {
            return state.byId[compositeId.modelTypeGroupId];
        });

    export const deletedModelTypeGroups = (payload: { presetId: string; serverId: string }) =>
        createSelector(deletedModelTypeGroupStateSelector, (state) => {
            return state.byServerId[payload.serverId]?.byPresetId[payload.presetId] || [];
        });

    export const byPresetIdExcludeDeleted = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), deletedModelTypeGroups(compositeId), (state, deleted) => {
            const mtg = state.byPresetId[compositeId.presetId];
            const modelTypeGroups: ModelTypeGroup[] = mtg ? Object.values(mtg.byId) : [];

            return modelTypeGroups.filter(m => !deleted.some(d => d.id === m.id));
        });
}
