import { MxCell, MxEventObject, MxEvent } from './mxgraph';
import { BPMMxGraph } from './bpmgraph';

export class BPMMxObjectDefinitionNameChangeClass {
    private isFirstExecute = true;

    constructor(public cell: MxCell, public previous: string, public value: string, public graph: BPMMxGraph) {}

    execute() {
        if (!this.isFirstExecute) {
            this.graph.fireEvent(new MxEventObject(MxEvent.LABEL_CHANGED, 'cell', this.cell, 'value', this.value));
        } else {
            this.isFirstExecute = false;
        }

        const tmp = this.value;
        this.value = this.previous;
        this.previous = tmp;
    }
}
