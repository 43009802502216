import {
    ADD_KANBAN_CARD_ATTRIBUTE,
    ADD_STORE_EDIT_KANBAN_CARD_TYPE_WORKSPACE_TAB,
    CHANGE_KANBAN_CARD_ATTRIBUTE,
    CHANGE_KANBAN_CARD_GRID_SETTINGS,
    CHANGE_KANBAN_CARD_SECTOR_SETTINGS,
    CHANGE_KANBAN_CARD_TYPE_ID,
    CHANGE_KANBAN_CARD_TYPE_NAME,
    DELETE_KANBAN_CARD_ATTRIBUTES,
    MOVE_KANBAN_CARD_ATTRIBUTE,
} from '../../actionsTypes/workspaceTab/editKanbanCardTypeWorkspaceTab.actionTypes';
import {
    TAddKanbanCardAttributeAction,
    TAddKanbanCardAttributePayload,
    TAddStoreEditKanbanCardTypeWorkspaceTabAction,
    TAddStoreEditKanbanCardTypeWorkspaceTabPayload,
    TChangeKanbanCardAttributeAction,
    TChangeKanbanCardAttributePayload,
    TChangeKanbanCardGridSettingsAction,
    TChangeKanbanCardGridSettingsPayload,
    TChangeKanbanCardSectorSettingsAction,
    TChangeKanbanCardSectorSettingsPayload,
    TChangeKanbanCardTypeIdAction,
    TChangeKanbanCardTypeIdPayload,
    TChangeKanbanCardTypeNameAction,
    TChangeKanbanCardTypeNamePayload,
    TDeleteKanbanCardAttributesAction,
    TDeleteKanbanCardAttributesPayload,
    TMoveKanbanCardAttributeAction,
    TMoveKanbanCardAttributePayload,
} from './editKanbanCardWorkspaceTab.actions.types';

export const addStoreEditKanbanCardTypeWorkspaceTab = (
    payload: TAddStoreEditKanbanCardTypeWorkspaceTabPayload,
): TAddStoreEditKanbanCardTypeWorkspaceTabAction => ({
    type: ADD_STORE_EDIT_KANBAN_CARD_TYPE_WORKSPACE_TAB,
    payload,
});

export const changeKanbanCardTypeName = (
    payload: TChangeKanbanCardTypeNamePayload,
): TChangeKanbanCardTypeNameAction => ({
    type: CHANGE_KANBAN_CARD_TYPE_NAME,
    payload,
});

export const changeKanbanCardTypeId = (payload: TChangeKanbanCardTypeIdPayload): TChangeKanbanCardTypeIdAction => ({
    type: CHANGE_KANBAN_CARD_TYPE_ID,
    payload,
});

export const changeKanbanCardGridSettings = (
    payload: TChangeKanbanCardGridSettingsPayload,
): TChangeKanbanCardGridSettingsAction => ({
    type: CHANGE_KANBAN_CARD_GRID_SETTINGS,
    payload,
});

export const changeKanbanCardSectorSettings = (
    payload: TChangeKanbanCardSectorSettingsPayload,
): TChangeKanbanCardSectorSettingsAction => ({
    type: CHANGE_KANBAN_CARD_SECTOR_SETTINGS,
    payload,
});

export const changeKanbanCardAttribute = (
    payload: TChangeKanbanCardAttributePayload,
): TChangeKanbanCardAttributeAction => ({
    type: CHANGE_KANBAN_CARD_ATTRIBUTE,
    payload,
});

export const moveKanbanCardAttribute = (payload: TMoveKanbanCardAttributePayload): TMoveKanbanCardAttributeAction => ({
    type: MOVE_KANBAN_CARD_ATTRIBUTE,
    payload,
});

export const deleteKanbanCardAttributes = (
    payload: TDeleteKanbanCardAttributesPayload,
): TDeleteKanbanCardAttributesAction => ({
    type: DELETE_KANBAN_CARD_ATTRIBUTES,
    payload,
});

export const addKanbanCardAttribute = (payload: TAddKanbanCardAttributePayload): TAddKanbanCardAttributeAction => ({
    type: ADD_KANBAN_CARD_ATTRIBUTE,
    payload,
});
