import type { TReducer } from '../utils/types';
import { TREE_ITEM_RENAME_REQUEST, TREE_ITEM_RENAME_CANCEL } from '../actionsTypes/tree.actionTypes';
import { TRenameNodeState } from './renameNode.reducer.types';

const initial: TRenameNodeState = {
    nodeId: undefined,
};

export const renameNodeReducer: TReducer<TRenameNodeState> = (state = initial, action) => {
    switch (action.type) {
        case TREE_ITEM_RENAME_REQUEST: {
            const {
                payload: { nodeId },
            } = action;

            return {
                nodeId,
            };
        }

        case TREE_ITEM_RENAME_CANCEL: {
            return initial;
        }
        default: {
            return state;
        }
    }
};
