import { connect } from 'react-redux';
import AboutAppDialog from '../components/AboutAppDialog.component';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';

const mapDispatchToProps = (dispatch) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.ABOUT_APP_DIALOG));
    },
});

export const AboutAppDialogContainer = connect(null, mapDispatchToProps)(AboutAppDialog);
