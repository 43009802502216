import { TDialogCloseAction } from "@/actions/dialogs.actions.types";
import { ApprovalDTO, ApprovalDTOStatusEnum, ApprovalStageDTO, ApprovalStageDTOStatusEnum, ApprovalTemplateDTO } from "@/serverapi/api";
import { FormInstance } from "antd";

export type TApprovalDialogProps = {
    onCancel: () => TDialogCloseAction;
    tabName: string;
    setTabName: React.Dispatch<React.SetStateAction<string>>;
    formRef: React.RefObject<FormInstance<any>>;
    title: string;
    generalFormComponent: JSX.Element;
    isTemplate?: boolean;
    open?: boolean;
    isCreateMode: boolean;
    approval: ApprovalDTO | ApprovalTemplateDTO;
    onChangeStages: (stages: ApprovalStageDTO[] | undefined) => void;
    submitModifiedApproval: (stages: ApprovalStageDTO[] | undefined, status: ApprovalDTOStatusEnum | undefined) => void;
};

export type TStageTab = {
    stage: ApprovalStageDTO;
    index: number;
    arr: ApprovalStageDTO[];
    onStageMove:(indexOfClickedStage: number, isDirectionUp: boolean) => void;
    onStageRemove: (id: string) => void;
    isNotDeletable?: boolean;
    isNotMovableUp?: false | ApprovalStageDTOStatusEnum | undefined;
    isNotMovableDown?: false | ApprovalStageDTOStatusEnum | undefined;
};

export type TApprovalDialogContainerProps = {
    isDraft: boolean;
    isCreateMode: boolean;
    open?: boolean;
};

export type TApprovalDialogTemplateContainerProps = {
    isCreateMode: boolean;
    open?: boolean;
};

export enum ApprovalStageDTOType {
    FOLLOWING = 'FOLLOWING',
    SUCCESSIVE = 'SUCCESSIVE',
    PARALLEL = 'PARALLEL',
}

export enum ApprovalStageDTOAbstentionVoteStrategy {
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
}

export enum ApprovalStageDTOStatus {
    IN_PROCESS = 'IN_PROCESS',
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
}

export enum ApprovalDTOStatus {
    DRAFT = 'DRAFT',
    IN_PROCESS = 'IN_PROCESS',
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
    CANCELLED = 'CANCELLED',
}

export enum ApprovalUserDTOVote {
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
    ABSTAINED = 'ABSTAINED',
    CANCELLED = 'CANCELLED',
}

export enum ApprovalRateOfSuccess {
    ALL_POSITIVE = 'ALL_POSITIVE',
    PERCENTAGE = 'PERCENTAGE',
}
