import React from 'react';
import theme from './SelectValue.scss';
import cx from 'classnames';
import { CloseOutlined } from '@ant-design/icons';

type TSelectValueProps = {
    deleteValue: () => void;
    value: string;
    isNotFound?: boolean;
};

export const SelectValue = (props: TSelectValueProps) => {
    const { value, isNotFound, deleteValue } = props;

    const onClickHandle = (e) => {
        e.stopPropagation();
        deleteValue();
    };

    return (
        <div className={cx(theme.container, { [theme.notFoundcontainer]: isNotFound })} data-test="selectValue-container">
            <div className={theme.valueContainer}>{value}</div>
            <div className={theme.iconContainer}>
                <div className={theme.icon} onClick={onClickHandle} data-test="icon-delete-btn">
                    <CloseOutlined />
                </div>
            </div>
        </div>
    );
};
