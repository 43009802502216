import { defineMessages } from 'react-intl';

export default defineMessages({
    alignLeft: {
        id: 'HorizontalAlignSelector.alignLeft',
        defaultMessage: 'По левому краю',
    },
    alignCenter: {
        id: 'HorizontalAlignSelector.alignCenter',
        defaultMessage: 'По центру',
    },
    alignRight: {
        id: 'HorizontalAlignSelector.alignRight',
        defaultMessage: 'По правому краю',
    },
});
