import { MxCell, MxCellState } from 'MxGraph';
import { BPMMxGraphView } from './BPMGraphClasses';

export class MethodologiesGraphView extends BPMMxGraphView {
    validateCellState(cell: MxCell, recurse?: boolean | null) {
        recurse = recurse != null ? recurse : true;
        let state: MxCellState | null = null;

        if (cell != null) {
            state = this.getState(cell);

            if (state != null) {
                const model = this.graph.getModel();

                if (state.invalid) {
                    state.invalid = false;

                    if (state.style == null || state.invalidStyle) {
                        state.style = this.graph.getCellStyle(state.cell);
                        state.invalidStyle = false;
                    }

                    if (cell != this.currentRoot) {
                        this.validateCellState(model.getParent(cell), false);
                    }

                    state.setVisibleTerminalState(
                        this.validateCellState(this.getVisibleTerminal(cell, true), false),
                        true,
                    );
                    state.setVisibleTerminalState(
                        this.validateCellState(this.getVisibleTerminal(cell, false), false),
                        false,
                    );

                    this.updateCellState(state);

                    // Repaint happens immediately after the cell is validated
                    if (cell != this.currentRoot && !state.invalid) {
                        this.graph.cellRenderer.redraw(state, false, this.isRendering());

                        // Handles changes to invertex paintbounds after update of rendering shape
                        state.updateCachedBounds();
                    }
                }

                if (recurse && !state.invalid) {
                    // Updates order in DOM if recursively traversing
                    if (state.shape != null) {
                        this.stateValidated(state);
                    }

                    const childCount = model.getChildCount(cell);

                    // Необходимо соблюдать порядок добавление объектов и лейблов
                    // Если лейбл добавлен раньше объекта, объект будет лежать сверху и лейбл не будет виден
                    // https://jira.silaunion.ru/browse/BPM-9784
                    const objects: MxCell[] = [];
                    const labels: MxCell[] = [];
                    for (let i = 0; i < childCount; i++) {
                        const child = model.getChildAt(cell, i);
                        if (child?.value?.type === 'label') {
                            labels.push(child);
                        } else {
                            objects.push(child);
                        }
                    }
                    [...objects, ...labels].forEach((cell) => {
                        this.validateCellState(cell);
                    });
                }
            }
        }

        return state;
    }
}
