import React from 'react';
import { Wrapper } from '../DialogWrapper/Wrapper.component';
import { TDialogFooterButtomsProps } from './DialogFooterButtons.types';
import { Button } from '../Button/Button.component';

export const DialogFooterButtons = (props: TDialogFooterButtomsProps) => {
    const { buttons, padding, reverse } = props;

    return (
        <Wrapper reverse={reverse} padding={padding}>
            {buttons.map((button) => {
                const { key, value, onClick, visualStyle, danger, dataTest, disabled, onKeyDown } = button;

                return (
                    <Button
                        key={key}
                        size="large"
                        onClick={onClick}
                        onKeyDown={onKeyDown}
                        visualStyle={visualStyle}
                        danger={danger}
                        dataTest={dataTest}
                        disabled={disabled}
                    >
                        {value}
                    </Button>
                );
            })}
        </Wrapper>
    );
};
