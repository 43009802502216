import { defineMessages } from 'react-intl';

export default defineMessages({
    create: {
        id: 'accessToken.create',
        defaultMessage: 'Создать',
    },
    name: {
        id: 'accessToken.name',
        defaultMessage: 'Название ключа',
    },
    sessionId: {
        id: 'accessToken.sessionId',
        defaultMessage: 'ID сессии',
    },
    login: {
        id: 'accessToken.login',
        defaultMessage: 'Пользователь',
    },
    startedAt: {
        id: 'accessToken.startedAt',
        defaultMessage: 'Создан',
    },
    expiresAt: {
        id: 'accessToken.expiresAt',
        defaultMessage: 'Действителен до',
    },
    lisence: {
        id: 'accessToken.lisence',
        defaultMessage: 'Лицензия',
    },
    token: {
        id: 'accessToken.token',
        defaultMessage: 'Ключ',
    },
    locale: {
        id: 'accessToken.locale',
        defaultMessage: 'Локаль',
    },
    deleteConfirmationTitle: {
        id: 'accessToken.deleteConfirmationTitle',
        defaultMessage: 'Удаление ключa',
    },
    deleteConfirmationQuestion: {
        id: 'accessToken.deleteConfirmationQuestion',
        defaultMessage: 'Вы уверены, что хотите удалить ключ "{name}"?',
    },
    deleteConfirmationSelectedSessionsTitle: {
        id: 'accessToken.deleteConfirmationSelectedSessionsTitle',
        defaultMessage: 'Удаление ключей',
    },
    deleteConfirmationSelectedSessionsQuestion: {
        id: 'accessToken.deleteConfirmationSelectedSessionsQuestion',
        defaultMessage: 'Вы уверены, что хотите удалить ключи: "{names}"?',
    },
    deleteButtonText: {
        id: 'accessToken.deleteButtonText',
        defaultMessage: 'Удалить',
    },
});
