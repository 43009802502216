import { TRootState } from '../../../reducers/root.reducer.types';
import { navigatorTabDeselect } from '../../../actions/navigator.actions';
import { connect } from 'react-redux';
import { TNavigatorTab } from '../../../reducers/navigator.reducer.types';
import { NavigatorOutline } from '../components/NavigatorOutline/NavigatorOutline.component';
import {
    TNavigatorOutlineReduxProps,
    TNavigatorOutlineActionsProps,
} from '../components/NavigatorOutline/NavigatorOutline.types';
import { getActiveGraph } from '../../../selectors/editor.selectors';
import { instancesBPMMxGraphMap } from '../../../mxgraph/bpm-mxgraph-instance-map';

const panelType = TNavigatorTab.Outline;

const mapStateToProps = (state: TRootState): TNavigatorOutlineReduxProps => {
    const activeGraphId = getActiveGraph(state);

    const graph = activeGraphId && instancesBPMMxGraphMap.get(activeGraphId);

    return {
        graph: graph || null,
    };
};

const mapDispatchToProps = (dispatch): TNavigatorOutlineActionsProps => ({
    onClose: () => dispatch(navigatorTabDeselect(panelType)),
});

export const NavigatorOutlineContainer = connect(mapStateToProps, mapDispatchToProps)(NavigatorOutline);
