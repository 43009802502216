import React from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import messages from './DeleteEditingCommentDialog.messages';
import { useIntl } from 'react-intl';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TDeleteEditingCommentDialogProps = {
    onCancel?: () => void;
};

export const DeleteEditingCommentDialog = (props: TDeleteEditingCommentDialogProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const onCancel = () => {
        dispatch(closeDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG));
    };

    const onOk = () => {
        if (props.onCancel) props.onCancel();
        dispatch(closeDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onOk,
                    value: intl.formatMessage(messages.deleteComment),
                    visualStyle: 'primary',
                    danger: true,
                },
            ]}
        />
    );

    return (
        <Dialog open onOk={onOk} onCancel={onCancel} title={intl.formatMessage(messages.title)} footer={footer}>
            <div>{intl.formatMessage(messages.text)}</div>
        </Dialog>
    );
};
