// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PermissionSwitch__permissionSwitchContainer__k5VNs .PermissionSwitch__button__NswKg{padding:0;border-radius:2px;height:18px;width:18px}", "",{"version":3,"sources":["webpack://./src/modules/Permissions/components/PermissionSwitch/PermissionSwitch.scss"],"names":[],"mappings":"AACI,qFACI,SAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".permissionSwitchContainer {\r\n    .button {\r\n        padding: 0;\r\n        border-radius: 2px;\r\n        height: 18px;\r\n        width: 18px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"permissionSwitchContainer": "PermissionSwitch__permissionSwitchContainer__k5VNs",
	"button": "PermissionSwitch__button__NswKg"
};
export default ___CSS_LOADER_EXPORT___;
