import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Row, Col } from 'antd';
import formMessages from '../../../models/formDefault.messages';
import umTheme from '../UserManagement/components/UserManagment.scss';
import admTheme from '../style/AdminTools.scss';
import { ALLUSERS_GROUPID } from '../data/admintool';
import { TWithWindowResizeProps } from '../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import GroupListComponents from '../LicenseOwnersEditingTab/components/GroupList.components';
import UserListComponent from '../LicenseOwnersEditingTab/components/UserList.component';
import { isNullOrUndefined } from 'is-what';
import GUPToolBarRoot from './GUPToolBarRoot.component';
import {
    TGUPrivilegeEditingTabActionProps,
    TGUPrivilegeEditingTabProps,
} from './GroupsAndUsersPrivilegeEditingTab.components.types';
import { FooterButtons } from '../../Footer/FooterButtons.component';
import { TButtonProps } from '@/modules/UIKit/components/Button/Button.types';

type TGUPrivilegeEditingTabFullProps = TGUPrivilegeEditingTabProps &
    TGUPrivilegeEditingTabActionProps &
    WrappedComponentProps &
    TWithWindowResizeProps;

type TGUPrivilegeEditingTabState = {
    searchInput: string;
    groupClicked: number | undefined;
    addedOwnersUsers?: number[];
    removedOwnersUsers?: number[];
    addedOwnersGroups?: number[];
    removedOwnersGroups?: number[];
};

class GroupsAndUsersPrivilegeEditingTab extends Component<
    TGUPrivilegeEditingTabFullProps,
    TGUPrivilegeEditingTabState
> {
    state: TGUPrivilegeEditingTabState = {
        searchInput: '',
        groupClicked: this.props.groupClicked || ALLUSERS_GROUPID,
    };

    componentDidMount() {
        const { serverId, fetchAllGroups, fetchUsersByGroup } = this.props;
        fetchAllGroups(serverId);
        fetchUsersByGroup({ serverId, groupId: ALLUSERS_GROUPID });
    }

    handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== this.state.searchInput) {
            this.setState({
                searchInput: e.target.value.trim().toLowerCase(),
            });
        }
    };

    handleGroupRowClick = (groupClicked: number) => {
        const { serverId } = this.props;
        if (groupClicked !== this.state.groupClicked) {
            this.setState(
                {
                    groupClicked,
                },
                () => {
                    this.props.groupItemClicked(groupClicked);
                    this.props.fetchUsersByGroup({ serverId, groupId: groupClicked });
                },
            );
        }
    };

    handleUsersOwnersChange = (addedOwnersUsers: number[], removedOwnersUsers: number[]) => {
        this.setState({
            addedOwnersUsers,
            removedOwnersUsers,
        });
    };

    handleGroupOwnersChange = (addedOwnersGroups: number[], removedOwnersGroups: number[]) => {
        this.setState({
            addedOwnersGroups,
            removedOwnersGroups,
        });
    };

    getFilteredUsers() {
        const { usersInGroup } = this.props;
        const convertedData =
            this.state.searchInput === ''
                ? usersInGroup
                : usersInGroup.filter(
                    (item) =>
                        [item.login, item.id, item.lastName, item.firstName, item.middleName]
                            .filter((s) => s)
                            .map((s) => `${s}`.toLowerCase())
                            .filter((s) => s.includes(this.state.searchInput)).length,
                );

        return convertedData.map((item) => ({
            key: item.id,
            userLogin: item.login || '',
            userFIO: [item.lastName, item.firstName, item.middleName].filter((s) => s).join(' ') || '',
        }));
    }

    containsChange = () => {
        const { removedOwnersUsers, removedOwnersGroups, addedOwnersUsers, addedOwnersGroups } = this.state;

        return (
            (addedOwnersUsers && addedOwnersUsers.length) ||
            (addedOwnersGroups && addedOwnersGroups.length) ||
            (removedOwnersUsers && removedOwnersUsers.length) ||
            (removedOwnersGroups && removedOwnersGroups.length)
        );
    };

    saveChanges = () => {
        const { addonParamToSave, tab, onClose } = this.props;
        const { removedOwnersUsers, removedOwnersGroups, addedOwnersUsers, addedOwnersGroups } = this.state;

        const addedUsers = addedOwnersUsers && addedOwnersUsers.length ? addedOwnersUsers : [];
        const addedGroups = addedOwnersGroups && addedOwnersGroups.length ? addedOwnersGroups : [];
        const added = [...addedUsers, ...addedGroups];

        if (added.length) {
            this.props.onSaveChanges({
                serverId: this.props.serverId,
                profileId: this.props.addonParamToSave.profileId,
                ids: added,
                action: 'ADD',
                ...addonParamToSave,
            });
        }

        const removedUsers = removedOwnersUsers && removedOwnersUsers.length ? removedOwnersUsers : [];
        const removedGroup = removedOwnersGroups && removedOwnersGroups.length ? removedOwnersGroups : [];
        const removed = [...removedUsers, ...removedGroup];
        if (removed.length) {
            this.props.onSaveChanges({
                serverId: this.props.serverId,
                ids: removed,
                action: 'REMOVE',
                ...addonParamToSave,
            });
        }
        onClose(tab);
    };

    render() {
        const { intl, tab, allGroups, usersInGroup, owners, wHeight, wWidth, groupClicked, toolBarParams } = this.props;
        const groupsChecked = owners && owners.groups && owners.groups.map((g) => Number(g.id));
        const usersChecked = owners && owners.users && owners.users.map((u) => Number(u.id));
        const shouldAllUsersCheckboxed =
            groupClicked &&
            ((this.state.addedOwnersGroups && this.state.addedOwnersGroups.includes(groupClicked)) ||
                (groupsChecked &&
                    groupsChecked.includes(groupClicked) &&
                    (isNullOrUndefined(this.state.removedOwnersGroups) ||
                        !this.state.removedOwnersGroups.includes(groupClicked))));

        const buttons: TButtonProps[] = [
            {
                children: intl.formatMessage(formMessages.cancelButton),
                size: "large",
                dataTest: "user-management_button_cancel",
                key: "cancel",
                onClick: () => this.props.onClose(tab),
            },
            {
                children: intl.formatMessage(formMessages.saveButtonLabel),
                size: "large",
                visualStyle: "primary",
                dataTest: "user-management_button_save",
                key: "ok",
                onClick: this.saveChanges,
            }
        ];

        return (
            <div className={umTheme.tabContent}>
                <div className={admTheme.containerInner}>
                    <div className={admTheme.m10}>
                        <div className={admTheme.inputHeaderContainer}>
                            <GUPToolBarRoot params={toolBarParams} handleSearch={this.handleSearch} />
                        </div>
                        <Row gutter={16} style={{ marginTop: 10 }}>
                            <Col span={8}>
                                <GroupListComponents
                                    search={this.state.searchInput}
                                    allGroups={allGroups}
                                    selected={groupsChecked}
                                    onGroupClicked={this.handleGroupRowClick}
                                    onCheckbox={this.handleGroupOwnersChange}
                                    currentClicked={this.state.groupClicked}
                                    wHeight={wHeight}
                                    wWidth={wWidth}
                                />
                            </Col>
                            <Col span={16}>
                                <UserListComponent
                                    search={this.state.searchInput}
                                    usersInGroup={usersInGroup}
                                    filteredUsers={this.getFilteredUsers()}
                                    selected={usersChecked}
                                    onCheckbox={this.handleUsersOwnersChange}
                                    selectedAll={!!shouldAllUsersCheckboxed}
                                    checkboxable={groupClicked === ALLUSERS_GROUPID}
                                    wHeight={wHeight}
                                    wWidth={wWidth}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <FooterButtons buttons={buttons} />
            </div>
        );
    }
}

const withIntl = injectIntl(GroupsAndUsersPrivilegeEditingTab);

export default withIntl;
