import { TReducer } from '../utils/types';
import { TNodeState } from './entities/TNodeState';
import { TUndoRedoData, TUndoRedoState } from './undoRedo.reducer.types';
import { cloneDeep } from 'lodash-es';
import {
    ADD_UNDO_REDO_STATE,
    CLEAR_UNDO_REDO_STATE,
    REDO_ACTION,
    UNDO_ACTION,
} from '@/actionsTypes/undoRedo.actionTypes';

const INITIAL_STATE: TUndoRedoState = new TNodeState();

export const MAX_NUMBER_OF_STATES = 50;

export const undoRedoReducer: TReducer<TUndoRedoState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_UNDO_REDO_STATE: {
            const {
                payload: { nodeId, state: newStata, nodeType },
            } = action;

            const clonedState = cloneDeep(newStata);

            const existState: TUndoRedoData | undefined = state.byNodeId.get(nodeId);
            if (existState) {
                const slicedStates = existState.states.slice(existState.currentIndex, MAX_NUMBER_OF_STATES);
                const newStates = [clonedState, ...slicedStates];
                return state.set(nodeId, { currentIndex: 0, states: newStates, nodeType });
            } else {
                return state.set(nodeId, { currentIndex: 0, states: [clonedState], nodeType });
            }
        }

        case UNDO_ACTION: {
            const {
                payload: { nodeId },
            } = action;

            const existState: TUndoRedoData | undefined = state.byNodeId.get(nodeId);
            if (existState) {
                const { currentIndex } = existState;
                const newIndex = Math.min(currentIndex + 1, MAX_NUMBER_OF_STATES, existState.states.length - 1);
                return state.set(nodeId, { ...existState, currentIndex: newIndex });
            }

            return state;
        }

        case REDO_ACTION: {
            const {
                payload: { nodeId },
            } = action;

            const existState: TUndoRedoData | undefined = state.byNodeId.get(nodeId);
            if (existState) {
                const { currentIndex } = existState;
                const newIndex = Math.max(currentIndex - 1, 0);
                return state.set(nodeId, { ...existState, currentIndex: newIndex });
            }

            return state;
        }

        case CLEAR_UNDO_REDO_STATE: {
            const {
                payload: { nodeId },
            } = action;

            return state.delete(nodeId);
        }

        default:
            return state;
    }
};
