export const CHECK_APPROVAL_TEMPLATES = 'CHECK_APPROVAL_TEMPLATES';

export const CHANGE_CHECK_ALL_APPROVAL_TEMPLATES = 'CHANGE_CHECK_ALL_APPROVAL_TEMPLATES';

export const ADD_APPROVAL_TEMPLATES = 'ADD_APPROVAL_TEMPLATES';

export const CREATE_APPROVAL_TEMPLATE = 'CREATE_APPROVAL_TEMPLATE';

export const SUBMIT_APPROVAL_TEMPLATE = 'SUBMIT_APPROVAL_TEMPLATE';

export const EDIT_APPROVAL_TEMPLATE = 'EDIT_APPROVAL_TEMPLATE';

export const DELETE_APPROVAL_TEMPLATES = 'DELETE_APPROVAL_TEMPLATES';

export const DELETE_APPROVAL_TEMPLATES_FROM_STORE = 'DELETE_APPROVAL_TEMPLATES_FROM_STORE';

export const EDIT_EXISTING_APPROVAL_TEMPLATE = 'EDIT_EXISTING_APPROVAL_TEMPLATE';

export const GET_APPROVAL_TEMPLATES = 'GET_APPROVAL_TEMPLATES';