import {
    OBJECT_DEFINITION_DIALOG_SUBMIT_DATA,
    OBJECT_DEFINITION_DIALOG_SUBMIT_RESULT,
} from '../actionsTypes/objectDefinitionDialog.actionTypes';
import { TMessage } from '../utils/forms.types';
import {
    TPayloadObjectDefinitionDialogSubmitData,
    TObjectDefinitionDialogSubmitData,
    TObjectDefinitionDialogSubmitResult,
} from './objectDefinitionDialog.actions.types';

export const objectDefinitionDialogSubmit = (
    payload: TPayloadObjectDefinitionDialogSubmitData,
): TObjectDefinitionDialogSubmitData => ({
    type: OBJECT_DEFINITION_DIALOG_SUBMIT_DATA,
    payload,
});

export const objectDefinitionDialogSubmitResult = (
    result: string,
    error?: TMessage,
): TObjectDefinitionDialogSubmitResult => ({
    type: OBJECT_DEFINITION_DIALOG_SUBMIT_RESULT,
    payload: {
        result,
        error,
    },
});
