import { takeEvery } from 'redux-saga/effects';
import { closeDialog } from '../actions/dialogs.actions';
import { IMPORT_NODE_DIALOG_SUBMIT } from '../actionsTypes/exportNotation.actionTypes';
import { TImportNodeDialogSubmitAction } from '../actions/exportNotation.actions.types';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { getStore } from '../store';
import { treeImportNode } from '../actions/tree.actions';

function importNodeSubmit({ payload: { file, parentNodeId, type } }: TImportNodeDialogSubmitAction) {
    const timerId = setInterval(() => {
        clearInterval(timerId);
        getStore().dispatch(
            treeImportNode({
                parentNodeId,
                type,
                data: file,
            }),
        );
        getStore().dispatch(closeDialog(DialogType.IMPORT_UPLOAD_DIALOG));
    }, 1000);
}

export function* exportNotationSaga() {
    yield takeEvery(IMPORT_NODE_DIALOG_SUBMIT, importNodeSubmit);
}
