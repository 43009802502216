import type { TFontStyle } from '../../../../../../mxgraph/mxgraph.types';
import type { TTextStyleEditorProps, TOnChangeStyleReturn } from '../../../../FloatingAttributes.types';
import type { TFloatingAttributesChangeTextStylePayload } from '../../../../actions/FloatingAttributes.actions.types';
import type { InternationalString } from '../../../../../../serverapi/api';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Col, Form, Row } from 'antd';
import { FontFamilySelect } from '../../../../../MainMenu/components/FontFamilySelect/FontFamilySelect.component';
import {
    FontColorPickerButton,
    FontSizeInput,
    FontStyleButtons,
} from '../../../../../UIKit/components/FontSettings/FontSettings.component';
import BPMMxCellOverlay from '../../../../../../mxgraph/overlays/BPMMxCellOverlay';
import messages from './AttributeTextStyleEditor.messages';
import editorTheme from '../../AttributesEditor.scss';
import { AttributeHorizontalAlignSelector } from '../common/AttributeHorizontalAlignSelector.component';
import { AttributeVerticalAlignSelector } from '../common/AttributeVerticalAlignSelector.component';
import { AttributeCustomText } from './AttributeCustomText/AttributeCustomText.component';
import { floatingAttributesChangeTextStyleAction } from '../../../../actions/FloatingAttributes.actions';
import { FloatingAttributesPanelSelectors } from '../../../../selectors/FloatingAttributes.selectors';

export const TextStyleEditor = ({ selectedStyle, disabled }: TTextStyleEditorProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { replacementTextStatusMap, rulesHasNotValueStatusMap } = useSelector(
        FloatingAttributesPanelSelectors.allData,
    );
    const parsedStyle = useMemo(() => BPMMxCellOverlay.parseFontStyle(selectedStyle?.style), [selectedStyle]);
    const defaultStyle = BPMMxCellOverlay.parsedDefaultTextStyle;
    const currentStyle: TFontStyle = parsedStyle || defaultStyle;

    const handleChangeTextStyle = (payload: TFloatingAttributesChangeTextStylePayload): void => {
        dispatch(floatingAttributesChangeTextStyleAction(payload));
    };

    const handleChangeStyle =
        (key: string): TOnChangeStyleReturn =>
        (value: string | boolean): void => {
            const changedStyle: TFontStyle = { ...(currentStyle || {}), [key]: value };
            const styleString: string = BPMMxCellOverlay.stringifyStyle(changedStyle);
            handleChangeTextStyle({ style: styleString });
        };

    const handleChangeFontSize = (value: string): void => handleChangeStyle('size')(`${value}pt`);

    const handleChangeReplacementText = (replacementText: InternationalString) =>
        handleChangeTextStyle({ replacementText });

    const handleChangeUseReplacementText = (useReplacementText: boolean) =>
        handleChangeTextStyle({ useReplacementText });

    return (
        !!currentStyle && (
            <>
                <div className={editorTheme.formRow}>{intl.formatMessage(messages.textSettingsTitle)}:</div>
                <Form>
                    <Form.Item>
                        <Row className={editorTheme.formRow}>
                            <AttributeCustomText
                                multiLangValue={selectedStyle?.replacementText}
                                styleId={selectedStyle?.id}
                                disabled={disabled}
                                onlyCustom={rulesHasNotValueStatusMap[selectedStyle?.id || '']}
                                useReplacementText={replacementTextStatusMap[selectedStyle?.id || '']}
                                changeAttributeCustomText={handleChangeReplacementText}
                                changeUseReplacementText={handleChangeUseReplacementText}
                            />
                        </Row>
                        <Row className={editorTheme.formRow}>
                            <Col id="attribute-text-style-selector" span={24}>
                                <FontFamilySelect
                                    disabled={disabled}
                                    onChange={handleChangeStyle('family')}
                                    value={currentStyle.family}
                                    renderNewOption={false}
                                />
                            </Col>
                        </Row>
                        <Row className={editorTheme.formRow}>
                            <Col span={12}>
                                <FontStyleButtons
                                    disabled={disabled}
                                    value={currentStyle}
                                    onChange={handleChangeStyle}
                                />
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <FontColorPickerButton
                                        disabled={disabled}
                                        value={currentStyle.color}
                                        onChange={handleChangeStyle('color')}
                                    />
                                    <FontSizeInput
                                        disabled={disabled}
                                        value={currentStyle.size}
                                        onChange={handleChangeFontSize}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row className={editorTheme.formRow}>
                            <Col span={12}>
                                <AttributeHorizontalAlignSelector
                                    initialValue={currentStyle.align}
                                    disabled={disabled}
                                    onChange={handleChangeStyle('align')}
                                />
                            </Col>
                            <Col span={12}>
                                <div className={editorTheme.fontBaseLineButtonsWrapper}>
                                    <AttributeVerticalAlignSelector
                                        initialValue={currentStyle.baseline}
                                        disabled={disabled}
                                        onChange={handleChangeStyle('baseline')}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </>
        )
    );
};
