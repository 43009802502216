import { defineMessages } from 'react-intl';

export default defineMessages({
    notSelect: {
        id: 'ImportVisio.table.header.notSelect',
        defaultMessage: 'Не выбран',
    },
    file: {
        id: 'ImportVisio.table.header.file',
        defaultMessage: 'Файл',
    },
    page: {
        id: 'ImportVisio.table.header.page',
        defaultMessage: 'Вкладка',
    },
    modelType: {
        id: 'ImportVisio.table.header.modelType',
        defaultMessage: 'Тип модели',
    },
    modelName: {
        id: 'ImportVisio.table.header.modelName',
        defaultMessage: 'Имя модели',
    },
});
