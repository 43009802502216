import {
    ADD_SERVER_PROFILE_DATA,
    DELETE_SERVER_PROFILE_DATA,
    GET_ALL_SERVER_PROFILES,
    GET_ALL_SERVER_PROFILES_DATA,
    LOAD_ACTIVE_SERVER_PROFILE_REQUEST, LOAD_ACTIVE_SERVER_PROFILE_SUCCESS,
    OPEN_ACTIVE_SERVER_PROFILE_SELECTOR,
    SERVER_PROFILE_SET_STATE,
    SWITCH_ACTIVE_SERVER_PROFILE,
    SWITCH_ACTIVE_SERVER_PROFILE_SUCCESS,
    UPDATE_TRINSITIVE_PROFILE_IDS,
    UPDATE_USER_SERVER_PROFILES,
} from '../actionsTypes/serverProfile.actionTypes';
import {ServerProfile} from '../serverapi/api';
import {
    TAddServerProfileDataAction,
    TDeleteServerProfilesDataAction,
    TGetAllServerProfilesAction,
    TGetAllServerProfilesDataAction,
    TGetAllServerProfilesPayload,
    TLoadActiveServerProfileRequestAction,
    TLoadActiveServerProfileSuccessAction,
    TOpenActiveServerProfileSelectorAction,
    TPayloadAddServerProfileData,
    TPayloadDeleteServerProfileData,
    TSetStateServerProfilesDataAction,
    TSwitchActiveServerProfileAction,
    TSwitchActiveServerProfilePayload,
    TSwitchActiveServerProfileSuccessAction,
    TSwitchActiveServerProfileSuccessPayload,
    TUpdateTransitiveProfileIdsAction,
    TUpdateUserServerProfilesAction,
} from './serverProfile.actions.types';

export const addServerProfile = (payload: TPayloadAddServerProfileData): TAddServerProfileDataAction => ({
    type: ADD_SERVER_PROFILE_DATA,
    payload,
});

export const serverProfileSetState = (
    serverProfiles: ServerProfile[],
    serverId: string,
): TSetStateServerProfilesDataAction => ({
    type: SERVER_PROFILE_SET_STATE,
    payload: {
        serverProfiles,
        serverId,
    },
});

export const getServerProfilesData = (serverId: string): TGetAllServerProfilesDataAction => ({
    type: GET_ALL_SERVER_PROFILES_DATA,
    payload: {
        serverId,
    },
});

export const switchActiveServerProfileSuccess = (payload: TSwitchActiveServerProfileSuccessPayload): TSwitchActiveServerProfileSuccessAction => ({
    type: SWITCH_ACTIVE_SERVER_PROFILE_SUCCESS,
    payload
});

export const switchActiveServerProfile = (payload: TSwitchActiveServerProfilePayload): TSwitchActiveServerProfileAction => ({
    type: SWITCH_ACTIVE_SERVER_PROFILE,
    payload
});

export const loadActiveServerProfile = (serverId: string): TLoadActiveServerProfileRequestAction => ({
    type: LOAD_ACTIVE_SERVER_PROFILE_REQUEST,
    payload: {
        serverId
    }
});

export const loadActiveServerProfileSuccess = (serverId: string, activeProfile: ServerProfile): TLoadActiveServerProfileSuccessAction => ({
    type: LOAD_ACTIVE_SERVER_PROFILE_SUCCESS,
    payload: {
        serverId,
        activeProfile
    }
});

export const openActiveServerProfileSelector = (serverId: string): TOpenActiveServerProfileSelectorAction => ({
    type: OPEN_ACTIVE_SERVER_PROFILE_SELECTOR,
    payload: {
        serverId
    },
});

export const deleteServerProfileData = (payload: TPayloadDeleteServerProfileData): TDeleteServerProfilesDataAction => ({
    type: DELETE_SERVER_PROFILE_DATA,
    payload,
});

export const getAllServerProfiles = (payload: TGetAllServerProfilesPayload): TGetAllServerProfilesAction => ({
    type: GET_ALL_SERVER_PROFILES,
    payload,
});

export const updateTransitiveProfileIds = (ids: string[]): TUpdateTransitiveProfileIdsAction=> ({
    type: UPDATE_TRINSITIVE_PROFILE_IDS,
    payload: {
        ids
    }
});

export const updateUserServerProfiles = (serverId: string): TUpdateUserServerProfilesAction => ({
    type: UPDATE_USER_SERVER_PROFILES,
    payload: {
        serverId
    }
});
