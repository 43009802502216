import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PresetSelectors } from '../../../selectors/preset.selectors';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { TTableData } from './MethodologyChangeTab.types';
import { SearchInput } from './SearchInput.component';
import { MethodologyChangeTabTable } from './MethodologyChangeTabTable.component';
import theme from './ObjectPropertiesDialog.scss';

type TMethodologyChangeTabProps = {
    presetId?: string;
    onChangePresetId: (presetId: string) => void;
};

export const MethodologyChangeTab = (props: TMethodologyChangeTabProps) => {
    const { presetId, onChangePresetId } = props;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const serverId: string = useSelector(ServerSelectors.serverId);
    const dataSource: TTableData[] = useSelector(PresetSelectors.methodologyTableData(serverId, searchFilter));

    return (
        <div className={theme.tableWrapper}>
            <SearchInput value={searchFilter} onSearch={setSearchFilter} />
            <MethodologyChangeTabTable
                presetId={presetId}
                dataSource={dataSource}
                onChangePresetId={onChangePresetId}
            />
        </div>
    );
};
