// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormGroup__container__tusDD{margin-bottom:32px}.FormGroup__container__tusDD .ant-form-item-label{padding-bottom:15px;font-size:12px;letter-spacing:.3px;text-align:left;color:var(--text)}.FormGroup__container__tusDD .ant-form-item-label label{font-size:12px;color:var(--text)}.FormGroup__container__tusDD .ant-row.ant-form-item{margin-bottom:16px}.FormGroup__container__tusDD .ant-row.ant-form-item:last-child{margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Forms/components/FormGroup/FormGroup.scss"],"names":[],"mappings":"AAAA,6BACI,kBAAA,CAOI,kDACI,mBAAA,CACA,cAAA,CACA,mBAAA,CACA,eAAA,CACA,iBAAA,CAEA,wDACI,cAAA,CACA,iBAAA,CAIR,oDACI,kBAAA,CAEA,+DACI,eAAA","sourcesContent":[".container {\r\n    margin-bottom: 32px;\r\n\r\n    //&:last-child {\r\n    //    margin-bottom: 0;\r\n    //}\r\n\r\n    :global {\r\n        .ant-form-item-label {\r\n            padding-bottom: 15px;\r\n            font-size: 12px;\r\n            letter-spacing: 0.3px;\r\n            text-align: left;\r\n            color: var(--text);\r\n\r\n            label {\r\n                font-size: 12px;\r\n                color: var(--text);\r\n            }\r\n        }\r\n\r\n        .ant-row.ant-form-item {\r\n            margin-bottom: 16px;\r\n\r\n            &:last-child {\r\n                margin-bottom: 0;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FormGroup__container__tusDD"
};
export default ___CSS_LOADER_EXPORT___;
