import React from 'react';
import { v4 as uuid } from 'uuid';
import style from './HightLighted.scss';
import { getTextParts } from '../../../../utils/textParts.utils';

type THightLightedProps = {
    searchText: string;
    children: string;
};

export const HightLighted = ({ searchText, children }: THightLightedProps) => {
    return (
        <>
            {getTextParts(searchText, children).map((part: string) => {
                if (part.toLowerCase() === searchText.toLowerCase()) {
                    return (
                        <span className={style.hightlighted} key={uuid()}>
                            {part}
                        </span>
                    );
                }

                return part;
            })}
        </>
    );
};
