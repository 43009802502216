import { CHOSE_SYMBOL_FOR_DRAG_DIALOG_SUBMIT_DATA } from '../actionsTypes/choseSymbolForDragDialog.actionTypes';
import {
    TChoseSymbolForDragDialogSubmitDataAction,
    TChoseSymbolForDragDialogSubmitDataActionPayload,
} from './choseSymbolForDragDialog.actions.types';

export const choseSymbolForDragDialogSubmit = (
    submitData: TChoseSymbolForDragDialogSubmitDataActionPayload,
): TChoseSymbolForDragDialogSubmitDataAction => ({
    type: CHOSE_SYMBOL_FOR_DRAG_DIALOG_SUBMIT_DATA,
    payload: submitData,
});
