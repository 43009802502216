import { groupBy } from 'lodash-es';
import { LocalesService } from '@/services/LocalesService';
import { GridDiagram } from '../../grid/GridDiagram';
import messages from './EPCGrid.messages';
import { showSelectRowNameDialog } from './SideEffects';
import { MxCell } from 'MxGraph';
import { showGridNotification } from '../../grid/SideEffects';
import { NotificationType } from '@/models/notificationType';
import { EPCGridColumnType, EPCGridRowType } from './EPCGrid.const';
import {
    commonEdgeTypesMap,
    exeptionEdgeTypesMap,
    generalSourcesSymbolId,
    generalTargetsSymbolsId,
    MainColumnSymbols,
    OrgUnitColumnSymbols,
} from './EPCGridInitial.config';

class EPCGrid extends GridDiagram {
    isRenderEdges: boolean = true;
    invisibleEdges: boolean = true;
    static rowTypeStyleKeyName = 'EPCRowType';
    static columnTypeStyleKeyName = 'EPCColumnType';

    getNewColumnTitle(): string {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(messages.newColumnTitle);
    }

    addRow(cell: MxCell) {
        showSelectRowNameDialog(({ name }) => {
            super.addRow(cell, name);
        });
    }
    protected addColumn(cell: MxCell): void {
        const columnIndex = this.graph.indexOfColumn(cell);
        const columns = this.graph.getTableColumnHeader(cell);

        if (columnIndex === columns.length - 1) {
            showGridNotification(NotificationType.EPC_MOVE_EMPTY_COLUMN_ERROR);
            return;
        }

        return super.addColumn(cell);
    }

    showRenameDialog(cell: MxCell, callback) {
        const rowIndex = this.graph.indexOfRow(cell);
        if (rowIndex === 0) {
            //rename column
            super.showRenameDialog(cell, callback);
        } else {
            //rename row
            showSelectRowNameDialog(({ name, id }) => {
                callback(name);
            });
        }
    }

    private getRowType(tableRowCell: MxCell): EPCGridRowType | undefined {
        // Временная реализация, опирающаяся на строки локализации
        try {
            const [topRow] = tableRowCell.getStyle().includes('shape=tableRow')
                ? tableRowCell.children
                : tableRowCell.getParent().children || [];
            const rowName = topRow.getValue();
            const intl = LocalesService.useIntl();

            let localesGridRow = '';
            for (const [key, value] of Object.entries(intl.messages)) {
                if (value === rowName) {
                    localesGridRow = key;
                }
            }

            const EPCGridRowTypeKey = localesGridRow.replace('EPCGrid.', '').trim();

            return EPCGridRowType[EPCGridRowTypeKey];
        } catch (e) {
            console.error(e);

            return undefined;
        }
    }

    private getColumnType(tableRowCell: MxCell): EPCGridColumnType | undefined {
        try {
            const columnIndex = this.graph.indexOfColumn(tableRowCell);
            const tableColumns = this.graph.getTableColumns(tableRowCell);
            if (columnIndex === tableColumns.length - 1) {
                return EPCGridColumnType.MAIN;
            }

            return EPCGridColumnType.ORG_UNIT;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    deleteColumn(cell: MxCell) {
        const index = this.graph.indexOfColumn(cell);
        const firstRow = this.getFirstRowCell(cell);

        if (!firstRow) {
            return;
        }

        try {
            const currentHeadColumn = firstRow.children[index];

            if (!currentHeadColumn.getValue()) {
                showGridNotification(NotificationType.EPC_DELETE_EMPTY_COLUMN_ERROR);

                return;
            }

            super.deleteColumn(cell);
        } catch (e) {
            return;
        }
    }

    getFirstRowCell(tableCell: MxCell): MxCell | null {
        try {
            return tableCell.parent.parent.children[0];
        } catch (e) {
            return null;
        }
    }

    public isGridValidTarget({ target, source, symbolId }) {
        try {
            const { symbolId: sourceSymbolId } = source?.getValue() || {};
            const actualSymbolId = (symbolId || sourceSymbolId || '').trim();

            const columnIndex = this.graph.indexOfColumn(target);

            const header = this.graph.getTableColumnHeader(target);

            const targetHeader: MxCell = header[columnIndex];

            const columnType = this.getColumnType(targetHeader);
            const commonTarget = super.isGridValidTarget({ target, source, symbolId: actualSymbolId });

            if (columnType === EPCGridColumnType.ORG_UNIT) {
                return OrgUnitColumnSymbols.includes(actualSymbolId) || commonTarget;
            }

            if (columnType === EPCGridColumnType.MAIN) {
                return MainColumnSymbols.includes(actualSymbolId) || commonTarget;
            }

            return commonTarget;
        } catch (e) {
            console.error(e);

            return false;
        }
    }

    renderEdges(source: MxCell): void {
        if (!this.isRenderEdges) {
            return;
        }
        try {
            const tableRowCell = source.getParent();
            const rowType: EPCGridRowType | undefined = this.getRowType(tableRowCell);
            const columnType: EPCGridColumnType | undefined = this.getColumnType(tableRowCell);

            if (rowType === undefined || columnType === undefined) {
                return;
            }

            this.clearEdges(source);

            const [, ...columnCells] = this.graph.getTableColumns(tableRowCell);
            const columnTypeCellMap = columnCells.map((targetGridCell) => {
                const type = this.getColumnType(targetGridCell);

                return {
                    type,
                    targetGridCell,
                };
            });

            const byType = groupBy(columnTypeCellMap, 'type');
            const getEdgeTypeId = (source, target) => this.getAutoEdgeType(rowType, source, target);

            if (columnType === EPCGridColumnType.MAIN) {
                const orgUnitColumnCellRow = byType[EPCGridColumnType.ORG_UNIT] || [];
                orgUnitColumnCellRow.forEach(({ targetGridCell }) => {
                    this.connectTableCellChildren({
                        sourceCell: source,
                        targetGridCell,
                        getEdgeTypeId,
                        reverseDirection: true,
                    });
                });
            }

            if (columnType === EPCGridColumnType.ORG_UNIT) {
                const mainColumnCellRow = byType[EPCGridColumnType.MAIN] || [];

                mainColumnCellRow.forEach(({ targetGridCell }) => {
                    this.connectTableCellChildren({
                        sourceCell: source,
                        targetGridCell,
                        getEdgeTypeId,
                        reverseDirection: false,
                    });
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Реализация бизнес логики определяющей возможность проведения связей между ячейками
     *
     * @param rowType - EPC Grid row type
     * @param source - source cell
     * @param target - target cell
     * @returns edge type id or undefined if not found
     */
    //
    getAutoEdgeType(rowType: EPCGridRowType, source: MxCell, target: MxCell): string | undefined {
        const { symbolId: sourceSymbolId } = source.getValue();
        const { symbolId: targetSymbolId } = target.getValue();

        if (!targetSymbolId || !sourceSymbolId) {
            console.error('target or source symbolId is undefined', targetSymbolId, sourceSymbolId);
            return;
        }

        const available = [...generalTargetsSymbolsId, ...generalSourcesSymbolId];

        if (sourceSymbolId === 'ST_APPL_SYS_TYPE') {
            if (
                rowType === EPCGridRowType.CT_EXEC_CT_CAN_SUPP &&
                exeptionEdgeTypesMap[rowType] &&
                generalTargetsSymbolsId.includes(targetSymbolId)
            ) {
                return exeptionEdgeTypesMap[rowType];
            }

            return;
        }

        if (commonEdgeTypesMap[rowType] && available.includes(targetSymbolId) && available.includes(targetSymbolId)) {
            return commonEdgeTypesMap[rowType];
        }

        return;
    }
}

export default EPCGrid;
