import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import AddUserToGroupDialogComponent from '../components/AddUserToGroupDialog.component';
import {
    TAddUserToGroupDialogActionProps,
    TAddUserToGroupDialogOwnProps,
    TAddUserToGroupDialogProps,
} from '../AddUserToGroupDialog.types';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { GroupsSelectors } from '../../../../selectors/groups.selectors';
import { GroupDTO, UserDTO } from '../../../../serverapi/api';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { addUsersToGroupRequest } from '../../../../actions/groups.actions';
import { TAddUsersToGroupRequestPayload } from '../../../../actions/groups.actions.types';

const mapStateToProps = (
    state: TRootState,
    ownProps: TAddUserToGroupDialogOwnProps,
): Partial<TAddUserToGroupDialogProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const editingGroup: GroupDTO = GroupsSelectors.getEditing(ownProps.groupId)(state);

    return {
        allUsers: UsersSelectors.getAll(serverId)(state) || Array<UserDTO>(),
        usersInGroup:
            editingGroup && editingGroup.id
                ? UsersSelectors.byGroupId(serverId, editingGroup.id)(state)
                : Array<UserDTO>(),
        serverId,
        groupId: editingGroup && editingGroup.id,
    };
};

const mapDispatchToProps: (dispatch) => TAddUserToGroupDialogActionProps = (dispatch) => ({
    onClose: () => dispatch(closeDialog(DialogType.ADD_USER_TO_GROUP)),
    onSubmit: (payload: TAddUsersToGroupRequestPayload) => dispatch(addUsersToGroupRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToGroupDialogComponent);
