// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DialogRoot__container__mgka3{width:0;height:0;position:absolute}", "",{"version":3,"sources":["webpack://./src/modules/DialogRoot/DialogRoot.scss"],"names":[],"mappings":"AAAA,8BACI,OAAA,CACA,QAAA,CACA,iBAAA","sourcesContent":[".container {\r\n    width: 0;\r\n    height: 0;\r\n    position: absolute;\r\n    // opacity: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DialogRoot__container__mgka3"
};
export default ___CSS_LOADER_EXPORT___;
