// import { TRootState } from '../reducers/root.reducer';
import { createSelector } from 'reselect';
import { TPreset } from '../models/preset.types';
import { TRootState } from '../reducers/root.reducer.types';
import { TTableData } from '../modules/ObjectPropertiesDialog/components/MethodologyChangeTab.types';
import { dateFormat, timeFormat } from '../utils/date.time.utils';
import dayjs from 'dayjs';

const presetSelector = (state: TRootState) => {
    return state.presets;
};

export namespace PresetSelectors {
    export const byId = (payload: { presetId: string; serverId: string }) =>
        createSelector(byServerId(payload.serverId), (state) => {
            return state[payload.presetId];
        });

    export const loading = () =>
        createSelector(presetSelector, (state): boolean => {
            return !!state.loading;
        });

    export const byServerId = (serverId: string) =>
        createSelector(presetSelector, (state) => {
            const presets = state.byServerId[serverId];
            if (presets) {
                return { ...presets.byId };
            }

            return {};
        });

    export const presetArrByServerId = (serverId?: string) =>
        createSelector(presetSelector, (state): TPreset[] => {
            const presets = serverId && state.byServerId[serverId];

            return presets ? Object.values(presets.byId) : [];
        });

    export const methodologyTableData = (serverId: string, searchFilter: string) =>
        createSelector(presetArrByServerId(serverId), (presets: TPreset[]): TTableData[] => {
            return presets
                .map((preset) => ({
                    key: preset.id,
                    name: preset.name,
                    desc: preset.description,
                    changeDate: dayjs(preset.updatedAt).format(`${dateFormat} ${timeFormat}`),
                }))
                .filter(
                    (data: TTableData) =>
                        data.name.toLowerCase().includes(searchFilter.toLowerCase().trim()) ||
                        data?.desc?.toLowerCase().includes(searchFilter.toLowerCase().trim()),
                );
        });

    export const defaultPresetByServerId = (serverId: string) =>
        createSelector<TRootState, TPreset[], TPreset | undefined>(
            presetArrByServerId(serverId),
            (presets: TPreset[]): TPreset | undefined => {
                return presets.find((preset) => preset.prime);
            },
        );
}
