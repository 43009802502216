import React from 'react';
import theme from './DropdownButton.scss';

type TOptionItemProps = {
    title: string;
    icon?: JSX.Element;
};

export const OptionItem = (props: TOptionItemProps) => {
    const { title, icon } = props;

    return (
        <div className={theme.actionLabel}>
            {icon ? icon : null}
            {title}
        </div>
    );
};
