import { TreeItemType } from '../../modules/Tree/models/tree';
import { NodeId } from '../../serverapi/api';

export type TNodeIdWithType = {
    nodeId: NodeId;
    elementId: string;
    type: TObjectTypesForLink;
};

export enum CustomObjectTypesForLink {
    Approval = 'APPROVAL',
}

export const ObjectTypesForLink = { ...TreeItemType, ...CustomObjectTypesForLink };

export type TObjectTypesForLink = TreeItemType | CustomObjectTypesForLink;

export type TLinkType = 'KERBEROS' | 'KEYCLOAK' | 'DB';
