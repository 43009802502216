import { defineMessages } from 'react-intl';

export default defineMessages({
    moveSelected: {
        id: 'TabHeader.moveSelected',
        defaultMessage: 'Перенести',
    },
    deleteSelected: {
        id: 'TabHeader.deleteSelected',
        defaultMessage: 'Удалить',
    },
    yes: {
        id: 'TabHeader.yes',
        defaultMessage: 'Да',
    },
    no: {
        id: 'TabHeader.no',
        defaultMessage: 'Нет',
    },
    deleteTitle: {
        id: 'TabHeader.deleteTitle',
        defaultMessage: 'Вы действительно хотите продолжить удаление?',
    },
    deleteSelectedElements: {
        id: 'TabHeader.deleteSelectedElements',
        defaultMessage: 'Удаление выбранных элементов',
    },
    addModelType: {
        id: 'TabHeader.addModelType',
        defaultMessage: 'Добавить тип модели',
    },
});
