import React, { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from './PublishedModelsTab.messages';
import theme from './PublishedModelsTab.scss';
import { SearchInput } from '@/modules/UIKit/components/Select/SearchInput.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import deleteIcon from '../../../resources/icons/Deleted.svg';
import updateIcon from '../../../resources/icons/Update.svg';
import { TPublishedModelsTableData } from './PublishedModelTab.types';
import { Alert } from 'antd';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { deletePublishedModels, updatePublishedModels } from '@/actions/publishedModels.actions';

type TModelsListControlsProps = {
    checkedModels: TPublishedModelsTableData[];
    searchInput: string;
    setCheckedModels: (models: TPublishedModelsTableData[]) => void;
    handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    handleClearSearch: () => void;
};

export const ModelsListControls: FC<TModelsListControlsProps> = (props) => {
    const { checkedModels, setCheckedModels, searchInput, handleSearch, handleClearSearch } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const deleteModelHandler = () => {
        const onDelete = () => {
            dispatch(deletePublishedModels(checkedModels.map((model) => model.id)));
            setCheckedModels([]);
        };

        const modelNames: string = checkedModels
            .map((model: TPublishedModelsTableData) => `"${model.modelName}"`)
            .join(', ');

        dispatch(
            openDialog(DialogType.DELETE_CONFIRMATION, {
                onDelete,
                deleteQuestion: intl.formatMessage(messages.deleteModelsQuestion),
                dialogContent: (
                    <Alert
                        message={intl.formatMessage(messages.deleteModelsDialogContent, { modelNames })}
                        type="warning"
                    />
                ),
            }),
        );
    };

    return (
        <div className={theme.searchDiv}>
            <div className={theme.searchInput}>
                <SearchInput
                    showSearch
                    originalTheme
                    dataTest="published-models-list-controls-search-input"
                    searchValue={searchInput}
                    onSearch={handleSearch}
                    allowClear
                    onClear={handleClearSearch}
                />
            </div>

            <div className={theme.btnContainer}>
                <div className={theme.updateContainer}>
                    <Button
                        visualStyle={{ type: 'text' }}
                        onClick={() => dispatch(updatePublishedModels())}
                        icon={updateIcon}
                        tooltip={intl.formatMessage(messages.update)}
                    ></Button>
                </div>

                <div className={theme.binContainer}>
                    <Button
                        dataTest={'published-models-list-controls-delete-button'}
                        visualStyle={{ type: 'text' }}
                        onClick={deleteModelHandler}
                        disabled={!checkedModels.length}
                        icon={deleteIcon}
                        tooltip={intl.formatMessage(messages.delete)}
                    ></Button>
                </div>
            </div>
        </div>
    );
};
