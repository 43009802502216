import { put, select, takeEvery } from 'redux-saga/effects';
import { PROCESS_ERROR } from '../actionsTypes/error.actionTypes';
import { TProcessErrorAction } from '../actions/error.actions.types';
import { ServerErrorType } from '../models/serverErrorType';
import { showNotification, showNotificationByType } from '../actions/notification.actions';
import { v4 as uuid } from 'uuid';
import { NotificationType } from '../models/notificationType';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TServerEntity } from '../models/entities.types';
import { appExit } from '../actions/app.actions';
import { AppExitMethod } from '../models/app';
import { TErrorBodyType, BadRequestErrors, notificationTypeMap, NEED_LICENSE_EDITOR } from './error.saga.types';

function* handleProcessError({ payload: { error, serverId } }: TProcessErrorAction) {
    let notificated: boolean = false;
    if (error?.status) {
        if (error.status === ServerErrorType.BAD_REQUEST) {
            const errorBody: TErrorBodyType | undefined = yield (error as Response).json();
            const code: string | undefined = errorBody?.code;
            const badRequestErrorsValue: BadRequestErrors | undefined = BadRequestErrors[code || ''];

            if (badRequestErrorsValue !== undefined) {
                yield put(showNotificationByType(NotificationType[code as string]));
                notificated = true;
            } else {
                const message: string | undefined = errorBody?.message;
                yield put(
                    showNotification({
                        type: NotificationType.DEFAULT_BAD_REQUEST,
                        id: uuid(),
                        data: { message, code },
                    }),
                );
                notificated = true;
            }
        }
        if (error.status === ServerErrorType.OBJECT_STALE) {
            yield put(
                showNotification({
                    id: uuid(),
                    data: { modelName: error.modelName },
                    type: NotificationType.STALE_GRAPH_NOTIFICATION,
                }),
            );
            notificated = true;
        }
        if (error.status === ServerErrorType.FORBIDDEN) {
            yield put(showNotificationByType(NotificationType.FORBIDDEN_ERROR));
            notificated = true;
        }
        if (error.status === ServerErrorType.NOT_FOUND) {
            yield put(showNotificationByType(NotificationType.NOT_FOUND_ERROR));
            notificated = true;
        }
        if (error.status === ServerErrorType.UNAUTHORIZED) {
            yield put(appExit(AppExitMethod.Unauthorized));
        }
        if (error.status === ServerErrorType.LOCKED) {
            yield put(showNotificationByType(NotificationType.OBJECT_LOCK_ERROR));
            notificated = true;
        }
        if (error.status === ServerErrorType.PAYMENT_REQUIRED) {
            const errorBody: TErrorBodyType | undefined = yield (error as Response).json();

            if (errorBody?.errors.some((error) => error.subCode.includes(NEED_LICENSE_EDITOR))) {
                yield put(showNotificationByType(NotificationType.NEED_LICENSE_EDITOR));
            } else {
                yield put(showNotificationByType(NotificationType.PAYMENT_REQUIRED));
            }

            notificated = true;
        }
        if (error.status === ServerErrorType.DUPLICATE_ENTITY) {
            yield put(showNotificationByType(NotificationType.DUPLICATE_ENTITY));
            notificated = true;
        }
        if (error.status === ServerErrorType.ACCESS_DENIED_BY_PROFILE) {
            yield put(showNotificationByType(NotificationType.ACCESS_DENIED_BY_PROFILE));
            notificated = true;
        }
    }

    if (!notificated) {
        const anotherNotification: NotificationType | undefined = notificationTypeMap.get(error?.message);
        if (anotherNotification !== undefined) {
            yield put(showNotificationByType(anotherNotification));
        } else if (error?.url && error?.status) {
            // ошибка сервера
            yield put(
                showNotification({
                    id: uuid(),
                    data: error.message,
                    type: NotificationType.SERVER_ERROR,
                }),
            );
            yield sendErrorToServer(serverId, error);
        } else {
            yield put(
                showNotification({
                    id: uuid(),
                    data: error?.message,
                    type: NotificationType.COMMON_ERROR,
                }),
            );
            yield sendErrorToServer(serverId, error);
        }
    }
    console.error(error); // tslint:disable-line no-console
}

function* sendErrorToServer(serverId?: string, error?: any) {
    try {
        const serverIds: string[] = serverId ? [serverId] : yield select(ServerSelectors.connected);
        const message = error ? `${error.url} ${error.status} ${error.message} ${JSON.stringify(error)}` : '';
        for (const sId of serverIds) {
            if (!sId) {
                continue;
            }
            const server: TServerEntity = yield select(ServerSelectors.server(sId));
            if (!server) {
                continue;
            }
            server.api.errorLogger.addError({ body: `${sId} ${message}` });
        }
    } catch (ex) {
        // tslint:disable-next-line: no-console
        console.error('Error is not sent to server', ex);
    }
}

export function* errorSagaInit() {
    yield takeEvery(PROCESS_ERROR, handleProcessError);
}
