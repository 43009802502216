import { TImportArisState } from '../reducers/importAris.reducer.types';
import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { ArisImportDescriptor } from '../serverapi/api';

export const importArisState = (state: TRootState) => state.importAris;

export namespace ImportArisSelectors {
    export const getArisDescriptor = (serverId: string, repositoryId: string, workspaceTabId: string) =>
        createSelector<TRootState, TImportArisState, ArisImportDescriptor>(importArisState, (state) => state[serverId]?.[repositoryId]?.[workspaceTabId]?.descriptor);

    export const getPath = (serverId: string, repositoryId: string, workspaceTabId: string) =>
        createSelector(importArisState, (state) => state[serverId]?.[repositoryId]?.[workspaceTabId].path);
}
