// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProcessIndicator__container__uZHK0{width:100%;white-space:nowrap;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.ProcessIndicator__text__hYLRY{margin-right:10px;max-width:70%;overflow-x:hidden;display:inline-block;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/modules/StatusBar/components/ProcessIndicator/ProcessIndicator.scss"],"names":[],"mappings":"AAAA,oCACI,UAAA,CACA,kBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGJ,+BACI,iBAAA,CACA,aAAA,CACA,iBAAA,CACA,oBAAA,CACA,sBAAA","sourcesContent":[".container{\r\n    width: 100%;\r\n    white-space: nowrap;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.text {\r\n    margin-right: 10px;\r\n    max-width: 70%;\r\n    overflow-x: hidden;\r\n    display: inline-block;\r\n    text-overflow: ellipsis;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ProcessIndicator__container__uZHK0",
	"text": "ProcessIndicator__text__hYLRY"
};
export default ___CSS_LOADER_EXPORT___;
