import {
    ApprovalDTOStatus,
    ApprovalStageDTOType,
    ApprovalUserDTOVote,
} from '@/modules/ApprovalDialog/ApprovalDialog.types';
import {
    ApprovalDTO,
    ApprovalStageDTOTypeEnum,
    ApprovalUserDTOVoteEnum,
    NodeId,
    Node,
    ApprovalSearchResult,
} from '@/serverapi/api';
import { TreeNode } from '../../models/tree.types';
import { LocalesService } from '../LocalesService';
import { TApprovalTableData } from '../../modules/AdminTools/ApprovalsTable/approvalsTable.types';
import {
    getElementNodes,
    getUniqNodeIdsFromApprovals,
} from '../../modules/AdminTools/ApprovalsTable/utils/approvalTable.utils';
import { compareNodeIds } from '../../utils/nodeId.utils';
import { TApprovals } from '../../reducers/approval.reducer.types';
import { TMyApprovalsTableData } from '@/modules/MyApprovalsTab/myApprovals.types';

export class ApprovalBllService {
    public static canUserVote(
        isVoted: boolean,
        stageType: ApprovalStageDTOTypeEnum,
        isUserFirstInList: boolean,
        voteOfPreviousUser: ApprovalUserDTOVoteEnum | undefined,
    ): boolean {
        return (
            !isVoted &&
            !!(
                stageType === ApprovalStageDTOType.PARALLEL ||
                isUserFirstInList ||
                (stageType === ApprovalStageDTOType.FOLLOWING && voteOfPreviousUser) ||
                (stageType === ApprovalStageDTOType.SUCCESSIVE && voteOfPreviousUser === ApprovalUserDTOVote.APPROVED)
            )
        );
    }

    public static canUserChangeVote(
        isVoted: boolean,
        isChangeVoteAllowed: boolean,
        stageType: ApprovalStageDTOTypeEnum,
        isNextUserVoted: boolean,
    ): boolean {
        return (
            isVoted &&
            isChangeVoteAllowed &&
            (stageType === ApprovalStageDTOType.PARALLEL ||
                stageType === ApprovalStageDTOType.SUCCESSIVE ||
                stageType === ApprovalStageDTOType.FOLLOWING ||
                !isNextUserVoted)
        );
    }
}

// функция getNodeName будет доступна после BPM-7289, тогда объявление ее тут нужно удалить
const getNodeName = (node: TreeNode | Node): string => {
    const { multilingualName, name } = node;

    return LocalesService.internationalStringToString(multilingualName) || name || '';
};

export const createApprovalTableData = (approvals: ApprovalDTO[], nodes: Node[]): TApprovalTableData[] => {
    const uniqElementNodeIdsFromApprovals: NodeId[] = getUniqNodeIdsFromApprovals(approvals);
    const elementNodes: Node[] = nodes.filter((node) =>
        uniqElementNodeIdsFromApprovals.find((nodeId) => compareNodeIds(nodeId, node.nodeId)),
    );

    return approvals.map(({ id, name, description, status, createdBy, createdAt, elementIds }) => {
        return {
            id,
            name,
            description,
            status,
            createdBy,
            createdAt,
            elementNames: getElementNodes(elementNodes, elementIds, id).map((node) => getNodeName(node)),
            elementNodeIds: getElementNodes(elementNodes, elementIds, id).map((node) => node.nodeId),
        };
    });
};

export const sortApprovals = (approvals: ApprovalDTO[]): ApprovalDTO[] => {
    return approvals.sort((a, b) => {
        if (a.status !== ApprovalDTOStatus.IN_PROCESS && b.status === ApprovalDTOStatus.IN_PROCESS) {
            return 1;
        } else if (b.status !== ApprovalDTOStatus.IN_PROCESS && a.status === ApprovalDTOStatus.IN_PROCESS) {
            return -1;
        }
        return (b.createdAt || 0) - (a.createdAt || 0);
    });
};

export const getApprovalsArray = (byRepositoryId: TApprovals): ApprovalDTO[] => {
    return Object.values(byRepositoryId)
        .map((byId) => Object.values(byId.byId))
        .flat();
};

export const createMyApprovalsTableData = (
    myApprovals: ApprovalSearchResult[],
    nodes: Node[],
): TMyApprovalsTableData[] => {
    const uniqElementNodeIdsFromApprovals: NodeId[] = getUniqNodeIdsFromApprovals(
        myApprovals.filter((myApproval) => !!myApproval.approval).map((myApproval) => myApproval.approval!),
    );
    const elementNodes: Node[] = nodes.filter((node) =>
        uniqElementNodeIdsFromApprovals.find((nodeId) => compareNodeIds(nodeId, node.nodeId)),
    );

    return myApprovals.map((approvalSearch) => {
        return {
            id: approvalSearch.approval?.id || { id: '', repositoryId: '', serverId: '' },
            name: approvalSearch.approval?.name || '',
            stageName: approvalSearch.schedule ? approvalSearch?.schedule[0]?.stageName : '',
            elementNames: getElementNodes(
                elementNodes,
                approvalSearch.approval?.elementIds || [],
                approvalSearch.approval?.id || { id: '', repositoryId: '', serverId: '' },
            ).map((node) => getNodeName(node)),
            elementIds: approvalSearch.approval?.elementIds || [],
            approvalPeriod: approvalSearch.currentUserHaveToVoteBeforeDate,
            createdAt: approvalSearch.approval?.createdAt,
            status: approvalSearch.approval?.status,
            userStatuses: approvalSearch.currentUserStatuses,
            createdBy: approvalSearch.approval?.createdBy,
            usersToVote: approvalSearch.schedule?.map((user) => user.userLogin || user.userName || '') || [],
        };
    });
};
