import React, { FC, MouseEvent } from 'react';
import theme from './ContentLoadingPageTab.scss';
import { Spin } from 'antd';
import cn from 'classnames';

type TContentLoadingPageTabProps = {
    className?: string;
};

export const ContentLoadingPageTab: FC<TContentLoadingPageTabProps> = ({ className }) => {
    const canceledMoseAction = (event: MouseEvent<HTMLDivElement>) => event.preventDefault();

    return (
        <div
            className={cn(theme.container, className)}
            onMouseUp={canceledMoseAction}
            onMouseDown={canceledMoseAction}
            onClick={canceledMoseAction}
        >
            <Spin size="large" className={theme.loader} />
        </div>
    );
};
