import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'CreateGraphSymbolDialog.title',
        defaultMessage: 'Настройка нового символа',
    },
    insert: {
        id: 'CreateGraphSymbolDialog.insert',
        defaultMessage: 'Вставить',
    },
    cancel: {
        id: 'CreateGraphSymbolDialog.cancel',
        defaultMessage: 'Отмена',
    },
    keyColumn: {
        id: 'CreateGraphSymbolDialog.keyColumn',
        defaultMessage: 'Параметр',
    },
    valueColumn: {
        id: 'CreateGraphSymbolDialog.valueColumn',
        defaultMessage: 'Значение',
    },
    crossSybolColumnCount: {
        id: 'CreateGraphSymbolDialog.crossSybolColumnCount',
        defaultMessage: 'Количество столбцов',
    },
    crossSybolRowCount: {
        id: 'CreateGraphSymbolDialog.crossSybolRowCount',
        defaultMessage: 'Количество строк',
    },
    crossSybolColumnName: {
        id: 'CreateGraphSymbolDialog.crossSybolColumnName',
        defaultMessage: 'Наименование для столбцов',
    },
    crossSybolRowName: {
        id: 'CreateGraphSymbolDialog.crossSybolRowName',
        defaultMessage: 'Наименование для строк',
    },
    crossSybolColumnTitleValue: {
        id: 'CreateGraphSymbolDialog.crossSybolColumnTitleValue',
        defaultMessage: 'Столбец',
    },
    crossSybolRowTitleValue: {
        id: 'CreateGraphSymbolDialog.crossSybolRowTitleValue',
        defaultMessage: 'Строка',
    },
    crossSybolRowHideHeaders: {
        id: 'CreateGraphSymbolDialog.crossSybolRowHideHeaders',
        defaultMessage: 'Скрыть заголовки (PSD)',
    },
    requiredField: {
        id: 'CreateGraphSymbolDialog.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    countError: {
        id: 'CreateGraphSymbolDialog.countError',
        defaultMessage: 'Допустимо числовое значение от 1 до 20',
    },
    nameError: {
        id: 'CreateGraphSymbolDialog.nameError',
        defaultMessage: 'Допустимо не более 100 символов',
    }
});
