const sizes = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];

const addFontSizeStyle = (size: number) => ({
    [`fontsize-${  size}`]: {
        open: () => `<FONTSIZE${  size  }>`,
        close: () => '</FONTSIZE>',
    },
});

const fontSizeStyles = {
    fontSize: addFontSizeStyle,
    ...sizes.reduce((result, size) => ({ ...result, ...addFontSizeStyle(size) }), {}),
};

// @TODO: Output in draftjs-utils - need fix it
const addFontSizeBlockStyle = (size: number) => ({
    [`fontsize-${  size}`]: {
        open: () => `<span style="font-size: ${  size  }px">`,
        close: () => '</span>',
    },
});

const fontSizeBlockStyles = {
    fontSize: addFontSizeBlockStyle,
    ...sizes.reduce((result, size) => ({ ...result, ...addFontSizeBlockStyle(size) }), {}),
};

export { fontSizeBlockStyles, fontSizeStyles };
