import React from 'react';
import theme from './WriteCommentDialog.scss';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { NodeId } from '../../../../serverapi/api';
import { CommentNameHeader } from '../CommentNameHeader.component';
import { CommentCustomTextArea } from '../CommentCustomTextArea/CommentCustomTextArea.component';
import { deleteEditingComment } from '../../../../actions/comments.actions';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';

type TWriteCommentDialogProps = {
    modelId: NodeId;
    parentId?: string;
    commentId?: string;
    threadId?: string;
    onSubmit?: () => void;
};

export const WriteCommentDialog = (props: TWriteCommentDialogProps) => {
    const { modelId, parentId, commentId, threadId, onSubmit } = props;
    const dispatch = useDispatch();
    const isEditComment: boolean = useSelector(CommentsSelectors.isEditedComment(modelId, commentId));

    const onCancel = () => {
        if (isEditComment) {
            dispatch(
                openDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG, {
                    onCancel: () => {
                        dispatch(deleteEditingComment(modelId));
                        dispatch(closeDialog(DialogType.WRITE_COMMENT_DIALOG));
                    },
                }),
            );
        } else {
            dispatch(deleteEditingComment(modelId));
            dispatch(closeDialog(DialogType.WRITE_COMMENT_DIALOG));
        }
    };

    const dialogTitle = (
        <CommentNameHeader className={theme.commentNameHeaderContainer} modelId={modelId} commentId={commentId} />
    );

    return (
        <Dialog title={dialogTitle} onCancel={onCancel} open width="612px" className={theme.dialog} footer={<></>}>
            <CommentCustomTextArea
                modelId={modelId}
                parentId={parentId}
                commentId={commentId}
                threadId={threadId}
                onFinishEditing={() => {
                    dispatch(closeDialog(DialogType.WRITE_COMMENT_DIALOG));
                    onSubmit && onSubmit();
                }}
                onCancel={() => {
                    dispatch(closeDialog(DialogType.WRITE_COMMENT_DIALOG));
                    dispatch(deleteEditingComment(modelId));
                }}
                isFullSize
            />
        </Dialog>
    );
};
