import React, { FC } from 'react';
import { TreeItemNameEditor } from '../TreeItemNameEditor/TreeItemNameEditor.component';
import { TreeItemType } from '../../models/tree';
import { HightLighted } from '@/modules/UIKit/H.O.C/HightLighted/HightLighted.hoc';
import theme from '../TreeItem/TreeItem.scss';
import { NodeId } from '@/serverapi/api';

type TTreeItemNameProps = {
    name: string;
    isEditing: boolean;
    suffix?: string;
    searchValue: string | undefined;
    nodeId: NodeId;
    type: TreeItemType;
    onChange?: (text: string) => void;
};

export const TreeItemName: FC<TTreeItemNameProps> = ({
    name,
    isEditing,
    suffix,
    searchValue,
    nodeId,
    type,
    onChange,
}) => {
    const hasSearchValue: boolean = !!searchValue?.length;

    if (!hasSearchValue) {
        return <TreeItemNameEditor name={name} suffix={suffix} isEditing={isEditing} onChange={onChange} />;
    }

    const isHighlightedName: boolean = name.toLowerCase().includes(searchValue!.toLowerCase());
    const isHighlightedId: boolean =
        type !== TreeItemType.Folder && nodeId.id.toLowerCase().includes(searchValue!.toLowerCase());

    if (isHighlightedName && isHighlightedId) {
        return (
            <>
                <HightLighted searchText={searchValue || ''}>{name}</HightLighted>
                <div className={theme.highlightedId}>
                    GUID <HightLighted searchText={searchValue || ''}>{nodeId.id}</HightLighted>
                </div>
            </>
        );
    }

    if (isHighlightedName) {
        return <HightLighted searchText={searchValue || ''}>{name}</HightLighted>;
    }

    if (isHighlightedId) {
        return (
            <>
                <span>{name}</span>
                <div className={theme.highlightedId}>
                    GUID <HightLighted searchText={searchValue || ''}>{nodeId.id}</HightLighted>
                </div>
            </>
        );
    }

    return <TreeItemNameEditor name={name} suffix={suffix} isEditing={isEditing} onChange={onChange} />;
};
