import type { AttributeTypeStyleRule, AttributeTypeStyleRuleTypeEnum } from '../../../../../serverapi/api';
import React, { useEffect } from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { momentDateToTimestamp, timestampToMomentDate, timeFormat } from '../../../../../utils/date.time.utils';

type TTimeInput = {
    rule: AttributeTypeStyleRule;
    disabled?: boolean;
    handleRuleParams: (ruleType: AttributeTypeStyleRuleTypeEnum | undefined, value1: string, value2?: string) => void;
};

export const TimeInput = ({ rule, disabled, handleRuleParams }: TTimeInput) => {
    useEffect(() => {
        if (!rule.param) {
            if (rule.type === 'HAS_NOT_VALUE') {
                handleRuleParams(rule.type, dayjs().format(timeFormat));
            } else {
                handleRuleParams(rule.type, momentDateToTimestamp(dayjs())?.toString() || '');
            }
        }
    }, [rule.param]);

    const onChangeHandler = (event: dayjs.Dayjs | null, value: string) => {
        if (rule.type === 'HAS_NOT_VALUE') {
            handleRuleParams(rule.type, value);
        } else {
            handleRuleParams(rule.type, momentDateToTimestamp(event || dayjs())?.toString() || '');
        }
    };

    const currentValue = () => {
        if (rule.type === 'HAS_NOT_VALUE') {
            return dayjs(rule.param, timeFormat);
        }

        return timestampToMomentDate(Number(rule.param || Date.now()));
    };

    return (
        <TimePicker
            data-test="change-the-value-of-the-rule_time-type"
            id={rule.type}
            disabled={disabled}
            allowClear={false}
            onChange={onChangeHandler}
            value={currentValue()}
        />
    );
};
