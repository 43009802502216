import React from 'react';
import messages from './ApprovalDialog.messages';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { ApprovalStageDTOStatus, ApprovalStageDTOType } from './ApprovalDialog.types';
import { StagesBllService } from '@/services/bll/ApprovalDialogStagesBLLService';
import { TApprovalTypeComponentProps } from './StageSettingsTab.types';
import { TRadioOption } from '../UIKit/components/Radio/Radio.types';
import { RadioGroup } from '../UIKit/components/Radio/RadioGroup.component';
import { ApprovalStageDTOTypeEnum } from '@/serverapi/api';

export const ApprovalTypeComponent = ({
    selectedStage,
    isTemplate,
    isEditingDisabled,
    isCreateMode,
    onChangeStages,
    stages,
    stageId,
}: TApprovalTypeComponentProps) => {
    const intl = useIntl();

    const onTypeChange = (value: ApprovalStageDTOType) => {
        const stageList = StagesBllService.changeType(stages, stageId, value);

        onChangeStages(stageList);
    };

    const isUserVoted: boolean | undefined = selectedStage.approvalUsersDTO?.some((user) => !!user.vote);

    const options: TRadioOption<ApprovalStageDTOType | ApprovalStageDTOTypeEnum>[] = [
        {
            title: intl.formatMessage(messages.following),
            value: ApprovalStageDTOType.FOLLOWING,
        },
        {
            title: intl.formatMessage(messages.followingSuccessive),
            value: ApprovalStageDTOType.SUCCESSIVE,
        },
        {
            title: intl.formatMessage(messages.parallel),
            value: ApprovalStageDTOType.PARALLEL,
        },
    ];

    return (
        <Form.Item
            label={intl.formatMessage(messages.approvalType)}
            initialValue={selectedStage.type || ApprovalStageDTOType.PARALLEL}
            name="ApprovalType"
        >
            <RadioGroup<ApprovalStageDTOType | ApprovalStageDTOTypeEnum>
                options={options}
                onChange={onTypeChange}
                value={selectedStage.type || ApprovalStageDTOType.PARALLEL}
                disabled={
                    !isTemplate &&
                    (isEditingDisabled ||
                        (selectedStage.status === ApprovalStageDTOStatus.IN_PROCESS && !isCreateMode && isUserVoted))
                }
                direction="Row"
                margin={8}
            />
        </Form.Item>
    );
};
