import { defineMessages } from 'react-intl';

export default defineMessages({
    text: {
        id: 'OverlayTypeSelector.text',
        defaultMessage: 'Текст',
    },
    icon: {
        id: 'OverlayTypeSelector.icon',
        defaultMessage: 'Иконка',
    },
    none: {
        id: 'OverlayTypeSelector.none',
        defaultMessage: 'Не отображать',
    },
});
