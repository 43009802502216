import { defineMessages } from 'react-intl';

export default defineMessages({
    bgColor: {
        id: 'TextBlockSettings.bgColor',
        defaultMessage: 'Основной фон',
    },
    recomendedColors: {
        id: 'TextBlockSettings.recomendedColors',
        defaultMessage: 'Рекомендованные',
    },
    borders: {
        id: 'TextBlockSettings.borders',
        defaultMessage: 'Границы',
    },
    display: {
        id: 'TextBlockSettings.display',
        defaultMessage: 'Отображать',
    },
    notDisplay: {
        id: 'TextBlockSettings.notDisplay',
        defaultMessage: 'Не отображать',
    },
});
