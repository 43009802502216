import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { TDeleteSeveralNodesDialogProps } from './DeleteSeveralNodesDialog.types';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import theme from './DeleteSeveralNodesDialog.scss';
import messages from './DeleteSeveralNodesDialog.messages';
import { Alert } from 'antd';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { useDispatch } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { deleteSeveralNodesFromServer } from '@/actions/tree.actions';
import { ShowToBeClosedTabs } from '../DeleteNodeDialog/ShowToBeClosedTabs';

export const DeleteSeveralNodesDialog: FC<TDeleteSeveralNodesDialogProps> = (props) => {
    const { closeTabNames, deleteNodeRequestData } = props;

    const intl = useIntl();
    const dispatch = useDispatch();

    const onSubmit = () => {
        dispatch(deleteSeveralNodesFromServer(deleteNodeRequestData));
        onClose();
    };

    const onClose = () => {
        dispatch(closeDialog(DialogType.DELETE_SEVERAL_NODES_DIALOG));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.delete),
                    visualStyle: 'primary',
                    danger: true,
                },
            ]}
        />
    );

    const nodeNames: string = deleteNodeRequestData.map((deleteNode) => `"${deleteNode.nodeName}"`).join(', ');

    return (
        <Dialog
            open
            onOk={onSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.title)}
            footer={footer}
            className={theme.dialog}
        >
            <div className={theme.content}>{intl.formatMessage(messages.text, { names: nodeNames })}</div>

            {closeTabNames.length > 0 && (
                <div className={theme.alert}>
                    <Alert
                        message={
                            <>
                                <div className={theme.alertTitle}>{intl.formatMessage(messages.tabsToBeClosed)}</div>
                                <ShowToBeClosedTabs tabsToBeClosed={closeTabNames.map((tab) => tab.name)} intl={intl} />
                            </>
                        }
                        type="warning"
                    />
                </div>
            )}
        </Dialog>
    );
};
