import {
    UmlAttributeHorizontolAlign,
    UmlAttributeVerticalAlign,
} from '../../mxgraph/overlays/CellOverlayManager.constants';

export const UML_ATTRIBUTES_SETTINGS = [
    {
        id: 'a_uml_con_multiplicity_initial',
        align: UmlAttributeHorizontolAlign.Left,
        verticalAlign: UmlAttributeVerticalAlign.Top,
    },
    {
        id: 'a_uml_con_multiplicity_final',
        align: UmlAttributeHorizontolAlign.Right,
        verticalAlign: UmlAttributeVerticalAlign.Bottom,
    },
    {
        id: 'a_uml_con_role_final',
        align: UmlAttributeHorizontolAlign.Right,
        verticalAlign: UmlAttributeVerticalAlign.Top,
    },
    {
        id: 'a_uml_con_role_initial',
        align: UmlAttributeHorizontolAlign.Left,
        verticalAlign: UmlAttributeVerticalAlign.Bottom,
    },
];

export const umlAttributeStyle = (v, h, align) => ({
    v,
    h,
    x: 0,
    y: 0,
    style: `7pt "Verdana" #808080 ${align} middle`,
    imageId: '',
    format: 'TEXT',
    rules: [],
});

export const isUmlAttribute = (typeId: string) => {
    return UML_ATTRIBUTES_SETTINGS.some((attr) => attr.id === typeId);
};
