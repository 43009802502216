// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheckBrowserDialog__about__lsU80{margin:10px 0px}.CheckBrowserDialog__title__RwaZF{margin-bottom:.6em;font-weight:bold}.CheckBrowserDialog__paragraph__Y2MKq{font-size:13px;margin-bottom:.6em;font-weight:400}.CheckBrowserDialog__buttonRow__Nbbow{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/CheckBrowserDialog/CheckBrowserDialog.scss"],"names":[],"mappings":"AAAA,kCACE,eAAA,CAGF,kCACE,kBAAA,CACA,gBAAA,CAGF,sCACE,cAAA,CACA,kBAAA,CACA,eAAA,CAGF,sCACE,oBAAA,CAAA,iBAAA,CAAA,wBAAA","sourcesContent":[".about {\r\n  margin: 10px 0px;\r\n}\r\n\r\n.title {\r\n  margin-bottom: 0.6em;\r\n  font-weight: bold;\r\n}\r\n\r\n.paragraph {\r\n  font-size: 13px;\r\n  margin-bottom: 0.6em;\r\n  font-weight: 400;\r\n}\r\n\r\n.buttonRow {\r\n  justify-content: flex-end;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": "CheckBrowserDialog__about__lsU80",
	"title": "CheckBrowserDialog__title__RwaZF",
	"paragraph": "CheckBrowserDialog__paragraph__Y2MKq",
	"buttonRow": "CheckBrowserDialog__buttonRow__Nbbow"
};
export default ___CSS_LOADER_EXPORT___;
