import { defineMessages } from 'react-intl';

export default defineMessages({
    fillingTypeTitle: {
        id: 'CommonSettings.fillingTypeTitle',
        defaultMessage: 'Способ наполнения справочника',
    },
    AUTO: {
        id: 'CommonSettings.AUTO',
        defaultMessage: 'С помощью поиска',
    },
    MANUAL: {
        id: 'CommonSettings.MANUAL',
        defaultMessage: 'Ручное наполнение',
    },
    searchRequests: {
        id: 'CommonSettings.searchRequests',
        defaultMessage: 'Условия поиска',
    },
    clear: {
        id: 'CommonSettings.clear',
        defaultMessage: 'Очистить условия поиска',
    },
    clearMessage: {
        id: 'CommonSettings.clearMessage',
        defaultMessage: 'Вы действительно хотите очистить условия поиска?',
    },
    onClear: {
        id: 'CommonSettings.onClear',
        defaultMessage: 'Очистить',
    },
    onCancel: {
        id: 'CommonSettings.onCancel',
        defaultMessage: 'Отмена',
    },
});
