// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainMenuContent__container__Jz4k6{background-color:var(--bright_bg)}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/MainMenuContent/MainMenuContent.scss"],"names":[],"mappings":"AAEA,mCACC,iCAAA","sourcesContent":["\r\n\r\n.container {\r\n\tbackground-color: var(--bright_bg);\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MainMenuContent__container__Jz4k6"
};
export default ___CSS_LOADER_EXPORT___;
