import { defineMessages } from 'react-intl';

export default defineMessages({
    byte: {
        id: 'attribute.byte',
        defaultMessage: 'байт',
    },
    kilobyte: {
        id: 'attribute.kilobyte',
        defaultMessage: 'Кб',
    },
    megabyte: {
        id: 'attribute.megabyte',
        defaultMessage: 'Мб',
    },
    gigabyte: {
        id: 'attribute.gigabyte',
        defaultMessage: 'Гб',
    }
});