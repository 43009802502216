// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SilaAcceptAgreementDialog__container__DRa2X{height:100%;max-height:100%;padding:15px 0;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/modules/SilaAcceptAgreement/SilaAcceptAgreementDialog.scss"],"names":[],"mappings":"AAAA,6CACI,WAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".container {\r\n    height: 100%;\r\n    max-height: 100%;\r\n    padding: 15px 0;\r\n    overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SilaAcceptAgreementDialog__container__DRa2X"
};
export default ___CSS_LOADER_EXPORT___;
