import { TReducer } from '../utils/types';
import { TNodeState } from './entities/TNodeState';
import { TModelEventsState } from './modelEvents.reducer.types';
import { GET_MODEL_EVENTS_REQUEST, GET_MODEL_EVENTS_SUCCESS } from '../actionsTypes/modelEvents.actionsTypes';

const initial: TModelEventsState = {
    data: new TNodeState(),
    loading: false,
    scripts: [],
};

export const modelEventsReducer: TReducer<TModelEventsState> = (state = initial, action) => {
    switch (action.type) {
        case GET_MODEL_EVENTS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_MODEL_EVENTS_SUCCESS: {
            const {
                payload: { nodeId, events },
            } = action;

            return {
                ...state,
                data: state.data.set(nodeId, events),
                loading: false,
            };
        }
        default:
            return state;
    }
};
