import { defineMessages } from 'react-intl';

export default defineMessages({
    line: {
        id: 'WidgetSourceInput.line',
        defaultMessage: 'Линия {number}',
    },

    yAxis: {
        id: 'WidgetSourceInput.yAxis',
        defaultMessage: 'Ось Y',
    },
    xAxis: {
        id: 'WidgetSourceInput.xAxis',
        defaultMessage: 'Ось X',
    },
    legend: {
        id: 'WidgetSourceInput.legend',
        defaultMessage: 'Легенда',
    },
    addLine: {
        id: 'WidgetSourceInput.addLine',
        defaultMessage: 'Добавить линию',
    },
});
