import { InternationalString, PresetTypesAcl } from '../../../../../../serverapi/api';
import { TEntityDataWithAcl } from './presetProfile.types';
import { Locale } from '../../../../../Header/components/Header/header.types';
import { LocalesService } from '../../../../../../services/LocalesService';

// ModelType, AttributeType итд
type TEntity = {
    id: string;
    multilingualName?: InternationalString | undefined;
    name: string;
};

// функция ищет в массиве acl[] тот который совпадает с id переданной сущности (ModelType, AttributeType и др)
// и возвращает объект с данными сущности (id, name) и acl.
// если в найденном acl какие-то права undefined устанавливается true

export function getEntityWithAcl(
    acls: PresetTypesAcl[],
    entity: TEntity,
    currentLocale: Locale,
    parentId?: string,
): TEntityDataWithAcl {
    const findedAcl = acls.find((acl) => acl.id === entity.id);
    const acl: PresetTypesAcl = {
        id: entity.id,
        create: findedAcl?.create ?? true,
        read: findedAcl?.read ?? true,
        update: findedAcl?.update ?? true,
        delete: findedAcl?.delete ?? true,
    };
    const entityTypeDataWithAcl = {
        id: entity.id,
        name: entity.multilingualName
            ? LocalesService.internationalStringToString(entity.multilingualName, currentLocale)
            : entity.name,
        acl,
        parentId,
    };

    return entityTypeDataWithAcl;
}
