import {defineMessages} from 'react-intl';

// файл изменять только сгенерированными с помощью AuditActions.main() сообщениями

export default defineMessages({

	READ_TREE_NODE:{
		id: 'READ_TREE_NODE',
		defaultMessage: 'Получение подробной информации об объекте',
	},
	READ_TREE_NODES:{
		id: 'READ_TREE_NODES',
		defaultMessage: 'Получение подробной информации об объектах',
	},
	MOVE_TREE_NODE:{
		id: 'MOVE_TREE_NODE',
		defaultMessage: 'Перемещение объекта',
	},
	DELETE_TREE_NODE:{
		id: 'DELETE_TREE_NODE',
		defaultMessage: 'Удаление узла дерева',
	},
	ERASE_TREE_NODE:{
		id: 'ERASE_TREE_NODE',
		defaultMessage: 'Полное удаление узла дерева',
	},
	CLONE_TREE_NODE:{
		id: 'CLONE_TREE_NODE',
		defaultMessage: 'Клонирование узла дерева',
	},
	SAVE_TREE_NODE:{
		id: 'SAVE_TREE_NODE',
		defaultMessage: 'Сохранение узла дерева',
	},
	READ_NODE_STATS:{
		id: 'READ_NODE_STATS',
		defaultMessage: 'Получение статистики элементов дерева',
	},
	SAVE_TREE_NODES:{
		id: 'SAVE_TREE_NODES',
		defaultMessage: 'Сохранение узлов дерева',
	},
	RENAME_TREE_NODE:{
		id: 'RENAME_TREE_NODE',
		defaultMessage: 'Переименование узла дерева',
	},
	CHANGE_TREE_NODE_PROPERTIES:{
		id: 'CHANGE_TREE_NODE_PROPERTIES',
		defaultMessage: 'Изменение свойства объекта',
	},
	CREATE_TREE_NODE:{
		id: 'CREATE_TREE_NODE',
		defaultMessage: 'Создание узла дерева',
	},
	RESTORE_TREE_NODE:{
		id: 'RESTORE_TREE_NODE',
		defaultMessage: 'Восстановление версии узла дерева',
	},
	RESTORE_NODE:{
		id: 'RESTORE_NODE',
		defaultMessage: 'Восстановление удаленного узла дерева',
	},
	LOCK_TREE_NODE:{
		id: 'LOCK_TREE_NODE',
		defaultMessage: 'Блокировка узла дерева',
	},
	UNLOCK_TREE_NODE:{
		id: 'UNLOCK_TREE_NODE',
		defaultMessage: 'Снятие блокировки узла дерева',
	},
	READ_TREE_NODE_VERSION:{
		id: 'READ_TREE_NODE_VERSION',
		defaultMessage: 'Получение версии узла дерева',
	},
	READ_TREE_NODE_VERSIONS:{
		id: 'READ_TREE_NODE_VERSIONS',
		defaultMessage: 'Получение всех версий узла дерева',
	},
	SAVE_TREE_NODE_VERSION:{
		id: 'SAVE_TREE_NODE_VERSION',
		defaultMessage: 'Сохранение версии узла дерева',
	},
	READ_MODEL_ASSIGNMENT:{
		id: 'READ_MODEL_ASSIGNMENT',
		defaultMessage: 'Получение декомпозиций',
	},
	SAVE_FAVORITE:{
		id: 'SAVE_FAVORITE',
		defaultMessage: 'Добавление элемента в избранные',
	},
	DELETE_FAVORITE:{
		id: 'DELETE_FAVORITE',
		defaultMessage: 'Удаление элемента из избранных',
	},
	USER_LOCK_TREE_NODE:{
		id: 'USER_LOCK_TREE_NODE',
		defaultMessage: 'Блокировка пользователем узла дерева',
	},
	USER_UNLOCK_TREE_NODE:{
		id: 'USER_UNLOCK_TREE_NODE',
		defaultMessage: 'Снятие блокировки пользователем узла дерева ',
	},
	SCRIPT_LOCK_TREE_NODE:{
		id: 'SCRIPT_LOCK_TREE_NODE',
		defaultMessage: 'Блокировка скриптом узла дерева',
	},
	SCRIPT_UNLOCK_TREE_NODE:{
		id: 'SCRIPT_UNLOCK_TREE_NODE',
		defaultMessage: 'Снятие блокировки скриптом узла дерев',
	},
	PRINT_NODE:{
		id: 'PRINT_NODE',
		defaultMessage: 'Получение изображения, печать модели',
	},
	READ_MODEL_IMAGE:{
		id: 'READ_MODEL_IMAGE',
		defaultMessage: 'Получение изображения',
	},
	READ_MODEL_BY_NODE:{
		id: 'READ_MODEL_BY_NODE',
		defaultMessage: 'Получение списка моделей, содержащих объект',
	},
	READ_TREE_NODES_BY_PARENT:{
		id: 'READ_TREE_NODES_BY_PARENT',
		defaultMessage: 'Получение дочерних узлов дерева',
	},
	MODEL_CRC_ERROR:{
		id: 'MODEL_CRC_ERROR',
		defaultMessage: 'Ошибка контрольной суммы модели',
	},
	DELETE_MODEL_DECOMPOSITION:{
		id: 'DELETE_MODEL_DECOMPOSITION',
		defaultMessage: 'Удаление декомпозиции модели',
	},
	READ_TREE_ROOT_NODES:{
		id: 'READ_TREE_ROOT_NODES',
		defaultMessage: 'Получение корневых узлов дерева',
	},
	REPLACE_TREE_NOTATIONS:{
		id: 'REPLACE_TREE_NOTATIONS',
		defaultMessage: 'Смена типа элемента',
	},
	MERGE_TREE_NODE:{
		id: 'MERGE_TREE_NODE',
		defaultMessage: 'Слияние объектов',
	},
	OPERATION_SEARCH_FULL:{
		id: 'OPERATION_SEARCH_FULL',
		defaultMessage: 'Запрос результатов операций пользователем с ФП OPERATION_ACCESS',
	},
	OPERATION_DOWNLOAD_LOG:{
		id: 'OPERATION_DOWNLOAD_LOG',
		defaultMessage: 'Скачивание лога',
	},
	OPERATION_CREATE:{
		id: 'OPERATION_CREATE',
		defaultMessage: 'Создание операции',
	},
	OPERATION_UPDATE:{
		id: 'OPERATION_UPDATE',
		defaultMessage: 'Обновление операции',
	},
	OPERATION_FINISH:{
		id: 'OPERATION_FINISH',
		defaultMessage: 'Завершение операции',
	},
	RUN_SCRIPT:{
		id: 'RUN_SCRIPT',
		defaultMessage: 'Запуск скрипта',
	},
	READ_SCRIPT_EXECUTION_RESULT:{
		id: 'READ_SCRIPT_EXECUTION_RESULT',
		defaultMessage: 'Загрузка результатов выполнения скрипта',
	},
	SAVE_SCRIPT_EXECUTION:{
		id: 'SAVE_SCRIPT_EXECUTION',
		defaultMessage: 'Сохранение результата выполнения скрипта',
	},
	PARSE_SCRIPT_PARAMETERS:{
		id: 'PARSE_SCRIPT_PARAMETERS',
		defaultMessage: 'Разбор параметров скрипта',
	},
	RUN_SCRIPT_SCHEDULER:{
		id: 'RUN_SCRIPT_SCHEDULER',
		defaultMessage: 'Запуск скрипта по расписанию',
	},
	IMPORT:{
		id: 'IMPORT',
		defaultMessage: 'Импорт',
	},
	REPOSITORY_IMPORT:{
		id: 'REPOSITORY_IMPORT',
		defaultMessage: 'Импорт базы данных',
	},
	EXPORT:{
		id: 'EXPORT',
		defaultMessage: 'Экспорт',
	},
	COMMENT_CREATE:{
		id: 'COMMENT_CREATE',
		defaultMessage: 'Комментарий создан',
	},
	COMMENT_SAVE:{
		id: 'COMMENT_SAVE',
		defaultMessage: 'Комментарий сохранен',
	},
	COMMENT_PIN:{
		id: 'COMMENT_PIN',
		defaultMessage: 'Закрепление комментария',
	},
	COMMENT_UNPIN:{
		id: 'COMMENT_UNPIN',
		defaultMessage: 'Открепление комментария',
	},
	COMMENT_CHANGE:{
		id: 'COMMENT_CHANGE',
		defaultMessage: 'Изменение комментария',
	},
	READ_COMMENT:{
		id: 'READ_COMMENT',
		defaultMessage: 'Чтение комментария',
	},
	READ_COMMENT_LIST:{
		id: 'READ_COMMENT_LIST',
		defaultMessage: 'Получение списка комментариев',
	},
	COMMENT_DELETE:{
		id: 'COMMENT_DELETE',
		defaultMessage: 'Комментарий удален',
	},
	COMMENT_ADDITIONAL_STATUS:{
		id: 'COMMENT_ADDITIONAL_STATUS',
		defaultMessage: 'Изменение дополнительного статуса комментария',
	},
	COMMENT_STATUS:{
		id: 'COMMENT_STATUS',
		defaultMessage: 'Изменение статуса комментария',
	},
	DOWNLOAD_FILE_NODE:{
		id: 'DOWNLOAD_FILE_NODE',
		defaultMessage: 'Скачивание файла',
	},
	DOWNLOAD_FILE:{
		id: 'DOWNLOAD_FILE',
		defaultMessage: 'Скачивание временного файла',
	},
	UPLOAD_FILE_NODE:{
		id: 'UPLOAD_FILE_NODE',
		defaultMessage: 'Загрузка файла на сервер',
	},
	UPLOAD_FILE:{
		id: 'UPLOAD_FILE',
		defaultMessage: 'Загрузка временного файла на сервер',
	},
	DELETE_FILE:{
		id: 'DELETE_FILE',
		defaultMessage: 'Удаление файла',
	},
	EXECUTE_SIMULATION:{
		id: 'EXECUTE_SIMULATION',
		defaultMessage: 'Запуск имитационного моделирования',
	},
	READ_SIMULATION_RUN:{
		id: 'READ_SIMULATION_RUN',
		defaultMessage: 'Чтение параметров имитационного моделирования',
	},
	DELETE_SIMULATION_RUN:{
		id: 'DELETE_SIMULATION_RUN',
		defaultMessage: 'Удаление параметров имитационного моделирования',
	},
	SAVE_SIMULATION_RUN:{
		id: 'SAVE_SIMULATION_RUN',
		defaultMessage: 'Сохранение параметров имитационного моделирования',
	},
	USER_TRY_AUTH:{
		id: 'USER_TRY_AUTH',
		defaultMessage: 'Попытка авторизации',
	},
	USER_SUCCESS_AUTH:{
		id: 'USER_SUCCESS_AUTH',
		defaultMessage: 'Успешный вход',
	},
	USER_FAIL_AUTH:{
		id: 'USER_FAIL_AUTH',
		defaultMessage: 'Вход запрещен',
	},
	USER_BLOCKED_FAIL_AUTH:{
		id: 'USER_BLOCKED_FAIL_AUTH',
		defaultMessage: 'Пользователь заблокирован',
	},
	USER_AUTH_TMP_BLOCKED:{
		id: 'USER_AUTH_TMP_BLOCKED',
		defaultMessage: 'Пользователь временно заблокирован',
	},
	LOGOUT_USER:{
		id: 'LOGOUT_USER',
		defaultMessage: 'Выход из системы',
	},
	SOME_USER_LOGOUT:{
		id: 'SOME_USER_LOGOUT',
		defaultMessage: 'Отключение пользователя от системы',
	},
	CLOSE_SESSION:{
		id: 'CLOSE_SESSION',
		defaultMessage: 'Завершение сессии',
	},
	CREATE_EXTERNAL_USER_SESSION:{
		id: 'CREATE_EXTERNAL_USER_SESSION',
		defaultMessage: 'Создание сессии для внешнего приложения',
	},
	READ_USER_SESSION_HISTORY:{
		id: 'READ_USER_SESSION_HISTORY',
		defaultMessage: 'Запрос сессий пользователя',
	},
	READ_ALL_USERS_SESSION_HISTORY:{
		id: 'READ_ALL_USERS_SESSION_HISTORY',
		defaultMessage: 'Запрос сессий всех пользователей',
	},
	READ_ALL_LICENSES:{
		id: 'READ_ALL_LICENSES',
		defaultMessage: 'Получение списка всех лицензий',
	},
	READ_LICENSE:{
		id: 'READ_LICENSE',
		defaultMessage: 'Получение лицензии',
	},
	ADD_LICENSE:{
		id: 'ADD_LICENSE',
		defaultMessage: 'Добавление лицензии',
	},
	DELETE_LICENSE:{
		id: 'DELETE_LICENSE',
		defaultMessage: 'Удаление лицензии',
	},
	READ_RESULTING_LICENSE:{
		id: 'READ_RESULTING_LICENSE',
		defaultMessage: 'Получение количества активных лицензий',
	},
	READ_USED_LICENSE:{
		id: 'READ_USED_LICENSE',
		defaultMessage: 'Получение количества использованных лицензий',
	},
	READ_OBJECT_ACL:{
		id: 'READ_OBJECT_ACL',
		defaultMessage: 'Чтение прав доступа',
	},
	WRITE_OBJECT_ACL:{
		id: 'WRITE_OBJECT_ACL',
		defaultMessage: 'Запись прав доступа',
	},
	CREATE_PRINCIPAL:{
		id: 'CREATE_PRINCIPAL',
		defaultMessage: 'Создание пользователя/группы',
	},
	DELETE_PRINCIPAL:{
		id: 'DELETE_PRINCIPAL',
		defaultMessage: 'Удаление пользователя/группы',
	},
	CHANGE_PRINCIPAL:{
		id: 'CHANGE_PRINCIPAL',
		defaultMessage: 'Изменение пользователя/группы',
	},
	CHANGE_USER_PASSWORD:{
		id: 'CHANGE_USER_PASSWORD',
		defaultMessage: 'Изменение пароля пользователя',
	},
	READ_PRINCIPAL:{
		id: 'READ_PRINCIPAL',
		defaultMessage: 'Получение пользователя/группы',
	},
	READ_PERSONAL_PROFILE:{
		id: 'READ_PERSONAL_PROFILE',
		defaultMessage: 'Получение информации пользователя для личного кабинета',
	},
	READ_PRINCIPAL_LIST:{
		id: 'READ_PRINCIPAL_LIST',
		defaultMessage: 'Получение списка пользователей/групп',
	},
	READ_ALL_PRINCIPALS:{
		id: 'READ_ALL_PRINCIPALS',
		defaultMessage: 'Получение списка всех пользователей/групп',
	},
	CHANGE_PRINCIPAL_GROUP:{
		id: 'CHANGE_PRINCIPAL_GROUP',
		defaultMessage: 'Изменение группы пользователя',
	},
	CHANGE_PRINCIPAL_LICENSE:{
		id: 'CHANGE_PRINCIPAL_LICENSE',
		defaultMessage: 'Изменение лицензии пользователя/группы',
	},
	CHANGE_PRINCIPAL_ACCESS:{
		id: 'CHANGE_PRINCIPAL_ACCESS',
		defaultMessage: 'Изменение функциональныx прав пользователя/группы',
	},
	CHANGE_PRINCIPAL_PROFILE:{
		id: 'CHANGE_PRINCIPAL_PROFILE',
		defaultMessage: 'Изменение профиля пользователя/группы',
	},
	READ_USER_ACCESSIBLE_REPOSITORIES:{
		id: 'READ_USER_ACCESSIBLE_REPOSITORIES',
		defaultMessage: 'Получение списка доступных репозиториев',
	},
	CREATE_USER_FOLDER:{
		id: 'CREATE_USER_FOLDER',
		defaultMessage: 'Создание пользовательской папки с файлами',
	},
	LICENSE_AGREEMENT_CONFIRMED:{
		id: 'LICENSE_AGREEMENT_CONFIRMED',
		defaultMessage: 'Лицензионное соглашение подтверждено',
	},
	LICENSE_AGREEMENT_DELETE:{
		id: 'LICENSE_AGREEMENT_DELETE',
		defaultMessage: 'Сброс согласия с лицензионным соглашением',
	},
	WRITE_SYSTEM_PROPERTY:{
		id: 'WRITE_SYSTEM_PROPERTY',
		defaultMessage: 'Сохранение параметров сервера',
	},
	READ_ALL_SYSTEM_PROPERTIES:{
		id: 'READ_ALL_SYSTEM_PROPERTIES',
		defaultMessage: 'Чтение всех параметров сервера',
	},
	CHECK_SCRIPT_ENGINE:{
		id: 'CHECK_SCRIPT_ENGINE',
		defaultMessage: 'Проверка сервера скриптов',
	},
	CHECK_IMAGE_SERVICE:{
		id: 'CHECK_IMAGE_SERVICE',
		defaultMessage: 'Проверка сервиса изображений',
	},
	READ_SYSTEM_STATUS:{
		id: 'READ_SYSTEM_STATUS',
		defaultMessage: 'Запрос состояния всех систем',
	},
	SERVER_IS_STARTED:{
		id: 'SERVER_IS_STARTED',
		defaultMessage: 'Сервер запущен',
	},
	SEARCH:{
		id: 'SEARCH',
		defaultMessage: 'Поиск в базе данных',
	},
	FIND_DUPLICATE_TREE_NODE:{
		id: 'FIND_DUPLICATE_TREE_NODE',
		defaultMessage: 'Поиск дублирующихся узлов',
	},
	SEARCH_SEVERAL:{
		id: 'SEARCH_SEVERAL',
		defaultMessage: 'Поиск в базе данных неслоклькими запросами в одном вызове апи',
	},
	SAVE_AIP_ALIAS:{
		id: 'SAVE_AIP_ALIAS',
		defaultMessage: 'Создание алиаса',
	},
	DELETE_AIP_ALIAS:{
		id: 'DELETE_AIP_ALIAS',
		defaultMessage: 'Удаление алиаса',
	},
	AIP_SEARCH:{
		id: 'AIP_SEARCH',
		defaultMessage: 'Поиск с использованием алиаса',
	},
	AIP_SEARCH_HISTORY:{
		id: 'AIP_SEARCH_HISTORY',
		defaultMessage: 'История поиска с использованием алиасов',
	},
	AUDIT_SEARCH:{
		id: 'AUDIT_SEARCH',
		defaultMessage: 'Запрос событий аудита',
	},
	AUDIT_DELETE_RANGE:{
		id: 'AUDIT_DELETE_RANGE',
		defaultMessage: 'Очистка журнала аудита',
	},
	SESSION_DELETE_RANGE:{
		id: 'SESSION_DELETE_RANGE',
		defaultMessage: 'Очистка журнала сессий',
	},
	SET_SHOW_DELETED_OBJECT:{
		id: 'SET_SHOW_DELETED_OBJECT',
		defaultMessage: 'Сохранение признака отображения удаленных обьектов',
	},
	READ_SHOW_DELETED_OBJECT:{
		id: 'READ_SHOW_DELETED_OBJECT',
		defaultMessage: 'Чтение признака отображения удаленных обьектов',
	},
	READ_PRESET:{
		id: 'READ_PRESET',
		defaultMessage: 'Получение методологии',
	},
	READ_ALL_PRESETS:{
		id: 'READ_ALL_PRESETS',
		defaultMessage: 'Получение списка всех методологий',
	},
	CREATE_PRESET:{
		id: 'CREATE_PRESET',
		defaultMessage: 'Создание методологии',
	},
	DELETE_PRESET:{
		id: 'DELETE_PRESET',
		defaultMessage: 'Удаление методологии',
	},
	CHANGE_PRESET:{
		id: 'CHANGE_PRESET',
		defaultMessage: 'Изменение методологии',
	},
	IMPORT_PRESET:{
		id: 'IMPORT_PRESET',
		defaultMessage: 'Импорт методологии',
	},
	IMPORT_PRESET_ELEMENTS:{
		id: 'IMPORT_PRESET_ELEMENTS',
		defaultMessage: 'Импорт элементов методологии',
	},
	EXPORT_PRESET:{
		id: 'EXPORT_PRESET',
		defaultMessage: 'Экспорт методологии',
	},
	CREATE_SERVER_PROFILE:{
		id: 'CREATE_SERVER_PROFILE',
		defaultMessage: 'Создание профиля сервера',
	},
	EDIT_SERVER_PROFILE:{
		id: 'EDIT_SERVER_PROFILE',
		defaultMessage: 'Редактирование профиля сервера',
	},
	DELETE_SERVER_PROFILE:{
		id: 'DELETE_SERVER_PROFILE',
		defaultMessage: 'Удаление профиля сервера',
	},
	CREATE_SCHEDULER_TASK:{
		id: 'CREATE_SCHEDULER_TASK',
		defaultMessage: 'Создание задачи',
	},
	EDIT_SCHEDULER_TASK:{
		id: 'EDIT_SCHEDULER_TASK',
		defaultMessage: 'Редактирование задачи',
	},
	DELETE_SCHEDULER_TASK:{
		id: 'DELETE_SCHEDULER_TASK',
		defaultMessage: 'Удаление задачи',
	},
	CREATE_EVENT:{
		id: 'CREATE_EVENT',
		defaultMessage: 'Добавление события',
	},
	READ_EVENTS:{
		id: 'READ_EVENTS',
		defaultMessage: 'Запрос списка событий',
	},
	UPDATE_EVENT:{
		id: 'UPDATE_EVENT',
		defaultMessage: 'Изменение события',
	},
	DELETE_EVENT:{
		id: 'DELETE_EVENT',
		defaultMessage: 'Удаление события',
	},
	CREATE_APPROVAL:{
		id: 'CREATE_APPROVAL',
		defaultMessage: 'Создание согласования',
	},
	COPY_APPROVAL:{
		id: 'COPY_APPROVAL',
		defaultMessage: 'Создание копии согласования',
	},
	UPDATE_APPROVAL:{
		id: 'UPDATE_APPROVAL',
		defaultMessage: 'Изменение согласования',
	},
	RUN_APPROVAL:{
		id: 'RUN_APPROVAL',
		defaultMessage: 'Запуск согласования',
	},
	CANCEL_APPROVAL:{
		id: 'CANCEL_APPROVAL',
		defaultMessage: 'Отмена согласования',
	},
	DELETE_APPROVAL:{
		id: 'DELETE_APPROVAL',
		defaultMessage: 'Удаление согласования',
	},
	READ_APPROVALS:{
		id: 'READ_APPROVALS',
		defaultMessage: 'Получение информации о согласованиях',
	},
	CREATE_APPROVAL_TEMPLATE:{
		id: 'CREATE_APPROVAL_TEMPLATE',
		defaultMessage: 'Создание шаблона согласования',
	},
	DELETE_APPROVAL_TEMPLATE:{
		id: 'DELETE_APPROVAL_TEMPLATE',
		defaultMessage: 'Удаление шаблона согласования',
	},
	UPDATE_APPROVAL_TEMPLATE:{
		id: 'UPDATE_APPROVAL_TEMPLATE',
		defaultMessage: 'Изменение шаблона согласования',
	},
	READ_APPROVAL_TEMPLATE:{
		id: 'READ_APPROVAL_TEMPLATE',
		defaultMessage: 'Получение согласования по id',
	},
	READ_APPROVAL_TEMPLATES:{
		id: 'READ_APPROVAL_TEMPLATES',
		defaultMessage: 'Получение всех согласований',
	},
	APPROVAL_ADD_ASSISTANT:{
		id: 'APPROVAL_ADD_ASSISTANT',
		defaultMessage: 'Добавление заместителя согласующего',
	},
	VOTE_EVENT:{
		id: 'VOTE_EVENT',
		defaultMessage: 'Принято решение по согласованию',
	},
	COMMENT_APPROVAL:{
		id: 'COMMENT_APPROVAL',
		defaultMessage: 'Добавлен комментарий по согласованию',
	},
	CHANGE_STATUS_APPROVAL:{
		id: 'CHANGE_STATUS_APPROVAL',
		defaultMessage: 'Изменение статуса согласования',
	},
	CHANGE_STATUS_APPROVAL_STAGE:{
		id: 'CHANGE_STATUS_APPROVAL_STAGE',
		defaultMessage: 'Изменение статуса этапа согласования',
	},
	SEND_EMAIL:{
		id: 'SEND_EMAIL',
		defaultMessage: 'Отправка письма на электронную почту',
	},
	CREATE_SUBSCRIPTION:{
		id: 'CREATE_SUBSCRIPTION',
		defaultMessage: 'Создание подписки',
	},
	DELETE_SUBSCRIPTION:{
		id: 'DELETE_SUBSCRIPTION',
		defaultMessage: 'Удаление подписки',
	},
	READ_MESSAGES:{
		id: 'READ_MESSAGES',
		defaultMessage: 'Чтение сообщений',
	},
	DELETE_MESSAGE:{
		id: 'DELETE_MESSAGE',
		defaultMessage: 'Удаление сообщений',
	},
	CLEAN_MESSAGES:{
		id: 'CLEAN_MESSAGES',
		defaultMessage: 'Удаление сообщений за выбранный промежуток времени',
	},
	READ_NOTIFICATION:{
		id: 'READ_NOTIFICATION',
		defaultMessage: 'Получение подробной информации об уведомлении',
	},
	READ_NOTIFICATIONS:{
		id: 'READ_NOTIFICATIONS',
		defaultMessage: 'Получение подробной информации об уведомлениях',
	},
	DELETE_NOTIFICATION:{
		id: 'DELETE_NOTIFICATION',
		defaultMessage: 'Удаление уведомления',
	},
	SAVE_NOTIFICATION:{
		id: 'SAVE_NOTIFICATION',
		defaultMessage: 'Сохранение уведомления',
	},
	CREATE_NOTIFICATION:{
		id: 'CREATE_NOTIFICATION',
		defaultMessage: 'Создание уведомления',
	},
	READ_NOTIFICATION_TEMPLATE:{
		id: 'READ_NOTIFICATION_TEMPLATE',
		defaultMessage: 'Получение подробной информации об шаблоне уведомления',
	},
	READ_NOTIFICATIONS_TEMPLATES:{
		id: 'READ_NOTIFICATIONS_TEMPLATES',
		defaultMessage: 'Получение подробной информации об шаблонах уведомлений',
	},
	DELETE_NOTIFICATION_TEMPLATE:{
		id: 'DELETE_NOTIFICATION_TEMPLATE',
		defaultMessage: 'Удаление шаблона уведомления',
	},
	SAVE_NOTIFICATION_TEMPLATE:{
		id: 'SAVE_NOTIFICATION_TEMPLATE',
		defaultMessage: 'Сохранение шаблона уведомления',
	},
	CREATE_NOTIFICATION_TEMPLATE:{
		id: 'CREATE_NOTIFICATION_TEMPLATE',
		defaultMessage: 'Создание шаблона уведомления',
	},
	COMPARE_ELEMENTS:{
		id: 'COMPARE_ELEMENTS',
		defaultMessage: 'Сравнение элементов',
	},
	CREATE_PUBLICATION:{
		id: 'CREATE_PUBLICATION',
		defaultMessage: 'Создание публикации',
	},
	DELETE_PUBLICATION:{
		id: 'DELETE_PUBLICATION',
		defaultMessage: 'Удаление публикации',
	},
	READ_PUBLIC_NODE_LIST:{
		id: 'READ_PUBLIC_NODE_LIST',
		defaultMessage: 'Список опубликованных элементов',
	},
	READ_PUBLIC_TREE_NODE:{
		id: 'READ_PUBLIC_TREE_NODE',
		defaultMessage: 'Получение опубликованного объекта',
	},
	UPLOAD_WIKI_IMAGE:{
		id: 'UPLOAD_WIKI_IMAGE',
		defaultMessage: 'Добавление изображения Wiki',
	},
	DOWNLOAD_WIKI_IMAGE:{
		id: 'DOWNLOAD_WIKI_IMAGE',
		defaultMessage: 'Загрузка изображения Wiki',
	},
    CHANGE_UI_TEMPLATE:{
        id: 'CHANGE_UI_TEMPLATE',
        defaultMessage: 'Изменение шаблона страницы',
    }
});
