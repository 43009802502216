import { PresetTypesAcl } from "../../serverapi/api";
import { TPresetTypesAclList } from "../userProfile.reducer.types";

export const profileAclsMap = (list: Array<PresetTypesAcl> | undefined): TPresetTypesAclList => {
    return list
        ? list.reduce((acc, current) => {
            acc[current.id] = current;

            return acc;
        }, {})
        : {};
};