import { isUndefined } from 'is-what';

let currentTool: SaveModelLockTool | null = null;

export const getLockingTool = (): SaveModelLockTool => {
    if (currentTool !== null) {
        return currentTool;
    }
    currentTool = new SaveModelLockTool();

    return currentTool;
};

export class SaveModelLockTool {
    private savings: Map<string, number> = new Map();

    isLocked(graphId: string): boolean {
        const count = this.savings.get(graphId);

        return !isUndefined(count) && count > 0;
    }

    deleteLock(graphId: string) {
        const count = this.savings.get(graphId);
        if (!isUndefined(count) && count > 0) {
            this.savings.set(graphId, count - 1);
        } else {
            this.savings.set(graphId, 0);
        }
    }

    addLock(graphId: string) {
        const count = this.savings.get(graphId);
        if (!isUndefined(count)) {
            this.savings.set(graphId, count + 1);
        } else {
            this.savings.set(graphId, 1);
        }
    }
}
