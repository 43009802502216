import { BpmNodeFilter } from '../../../models/bpmNodeFilter';
import { BPMMxGraph } from '../../../mxgraph/bpmgraph';
import { MxCell } from '../../../mxgraph/mxgraph';
import { ObjectInstanceImpl } from '../../../models/bpm/bpm-model-impl';
import { BpmNodeFilterBase } from '../../../models/bpmNodeFilterBase';

interface IFilterModel {
    type: BpmNodeFilterBase;
    input: boolean;
    output: boolean;
    result: BpmNodeFilter;
}

export class NodeFilterTool {
    private allCells: MxCell[];
    private allEdges: MxCell[];
    private filter: BpmNodeFilter = BpmNodeFilter.Off;
    private isNewFilterType: boolean = false;
    private startCell: MxCell | null = null;
    private filterMap: IFilterModel[] = [];

    // tslint:disable:max-line-length
    constructor(private graph: BPMMxGraph) {
        this.filterMap.push({
            type: BpmNodeFilterBase.Single,
            input: true,
            output: true,
            result: BpmNodeFilter.SingleAndInOut,
        });
        this.filterMap.push({
            type: BpmNodeFilterBase.Many,
            input: true,
            output: true,
            result: BpmNodeFilter.ManyAndInOut,
        });
        this.filterMap.push({
            type: BpmNodeFilterBase.NotSame,
            input: true,
            output: true,
            result: BpmNodeFilter.SingleAndInOutNotSame,
        });

        this.filterMap.push({
            type: BpmNodeFilterBase.Single,
            input: true,
            output: false,
            result: BpmNodeFilter.SingleAndIn,
        });
        this.filterMap.push({
            type: BpmNodeFilterBase.Many,
            input: true,
            output: false,
            result: BpmNodeFilter.ManyAndIn,
        });
        this.filterMap.push({
            type: BpmNodeFilterBase.NotSame,
            input: true,
            output: false,
            result: BpmNodeFilter.SingleAndInNotSame,
        });

        this.filterMap.push({
            type: BpmNodeFilterBase.Single,
            input: false,
            output: true,
            result: BpmNodeFilter.SingleAndOut,
        });
        this.filterMap.push({
            type: BpmNodeFilterBase.Many,
            input: false,
            output: true,
            result: BpmNodeFilter.ManyAndOut,
        });
        this.filterMap.push({
            type: BpmNodeFilterBase.NotSame,
            input: false,
            output: true,
            result: BpmNodeFilter.SingleAndOutNotSame,
        });

        this.filterMap.push({
            type: BpmNodeFilterBase.Single,
            input: false,
            output: false,
            result: BpmNodeFilter.Single,
        });
        this.filterMap.push({ type: BpmNodeFilterBase.Many, input: false, output: false, result: BpmNodeFilter.Many });
        this.filterMap.push({
            type: BpmNodeFilterBase.NotSame,
            input: false,
            output: false,
            result: BpmNodeFilter.SingleNotSame,
        });
    }

    setFilter(filterBase: BpmNodeFilterBase, filterInput: boolean, filterOutput: boolean) {
        const filter = this.calcFilter(filterBase, filterInput, filterOutput);

        if (filter !== undefined && filter !== this.filter) {
            this.filter = filter;
            this.isNewFilterType = true;
            this.filtrate();
        }
    }

    filtrateByCell(startCell: MxCell) {
        if (this.startCell !== startCell) {
            this.startCell = startCell;
            this.filtrate();
        }
    }

    resetFiltration() {
        if (this.startCell) {
            this.clearFilter();
            this.startCell = null;
        }
    }

    filtrate() {
        if (!this.isNewFilterType && this.filter === BpmNodeFilter.Off) {
            return;
        }

        if (!this.startCell) {
            return;
        }

        try {
            this.graph.getModel().beginUpdate();

            this.findAllCells();
            this.clearFilter();

            if (this.filter !== BpmNodeFilter.Off) {
                this.graph.setCellsInactive(this.allCells);
            }
            let connectionCells: MxCell[] = [];
            let inputResult: MxCell[] = [];
            let outputResult: MxCell[] = [];
            let result: MxCell[] = [];
            let cells: MxCell[] = [];
            switch (this.filter) {
                case BpmNodeFilter.Off:
                    this.graph.setCellsActive(this.allCells);
                    break;
                case BpmNodeFilter.Single:
                    cells = this.getCellsByObject(this.startCell);
                    this.graph.setCellsActive([...cells]);
                    break;
                case BpmNodeFilter.SingleAndInOut:
                    cells = this.getCellsByObject(this.startCell);
                    inputResult = this.getInputCells(cells);
                    outputResult = this.getOutputCells(cells);
                    result = [...inputResult, ...outputResult, ...cells];
                    this.graph.setCellsActive(result);
                    break;
                case BpmNodeFilter.SingleAndIn:
                    cells = this.getCellsByObject(this.startCell);
                    inputResult = this.getInputCells(cells);
                    result = [...inputResult, ...cells];
                    this.graph.setCellsActive(result);
                    break;
                case BpmNodeFilter.SingleAndOut:
                    cells = this.getCellsByObject(this.startCell);
                    outputResult = this.getOutputCells(cells);
                    result = [...outputResult, ...cells];
                    this.graph.setCellsActive(result);
                    break;
                case BpmNodeFilter.SingleAndInOutNotSame:
                    cells = this.filterByType(this.getCellsByObject(this.startCell));
                    connectionCells = [...cells, this.startCell];
                    inputResult = this.getInputCells(connectionCells);
                    outputResult = this.getOutputCells(connectionCells);
                    this.graph.setCellsActive([...connectionCells, ...inputResult, ...outputResult]);
                    break;
                case BpmNodeFilter.SingleAndInNotSame:
                    cells = this.filterByType(this.getCellsByObject(this.startCell));
                    connectionCells = [...cells, this.startCell];
                    inputResult = this.getInputCells(connectionCells);
                    this.graph.setCellsActive([...connectionCells, ...inputResult]);
                    break;
                case BpmNodeFilter.SingleAndOutNotSame:
                    cells = this.filterByType(this.getCellsByObject(this.startCell));
                    connectionCells = [...cells, this.startCell];
                    outputResult = this.getOutputCells(connectionCells);
                    this.graph.setCellsActive([...connectionCells, ...outputResult]);
                    break;
                case BpmNodeFilter.SingleNotSame:
                    cells = this.filterByType(this.getCellsByObject(this.startCell));
                    this.graph.setCellsActive([...cells, ...[this.startCell]]);
                    break;
                case BpmNodeFilter.Many:
                    cells = this.getCellsByType(this.startCell);
                    this.graph.setCellsActive(cells);
                    break;
                case BpmNodeFilter.ManyAndInOut:
                    cells = this.getCellsByType(this.startCell);
                    inputResult = this.getInputCells(cells);
                    outputResult = this.getOutputCells(cells);
                    result = [...inputResult, ...outputResult, ...cells];
                    this.graph.setCellsActive(result);
                    break;
                case BpmNodeFilter.ManyAndIn:
                    cells = this.getCellsByType(this.startCell);
                    inputResult = this.getInputCells(cells);
                    result = [...inputResult, ...cells];
                    this.graph.setCellsActive(result);
                    break;
                case BpmNodeFilter.ManyAndOut:
                    cells = this.getCellsByType(this.startCell);
                    outputResult = this.getOutputCells(cells);
                    result = [...outputResult, ...cells];
                    this.graph.setCellsActive(result);
                    break;
                default:
                    console.warn('No such filter type implementation!');
            }
            this.isNewFilterType = false;
        } finally {
            this.graph.getModel().endUpdate();
        }
    }

    private calcFilter(
        filterBase: BpmNodeFilterBase,
        filterInput: boolean,
        filterOutput: boolean,
    ): BpmNodeFilter | undefined {
        if (filterBase === BpmNodeFilterBase.Off) {
            return BpmNodeFilter.Off;
        }
        const input = filterInput || false;
        const output = filterOutput || false;

        return this.filterMap.find((t) => t.type === filterBase && t.input === input && t.output === output)?.result;
    }

    private filterByType(cells: MxCell[]) {
        const symbolId = (this.startCell?.getValue() as ObjectInstanceImpl).symbolId;

        return cells.filter((t) => (t.getValue() as ObjectInstanceImpl).symbolId !== symbolId);
    }

    private getInputCells(cells: MxCell[]): MxCell[] {
        return this.allEdges.filter((t) => cells.indexOf(t.target) > -1).map((x) => x.source);
    }

    private getOutputCells(cells: MxCell[]): MxCell[] {
        return this.allEdges.filter((t) => cells.indexOf(t.source) > -1).map((x) => x.target);
    }

    private findAllCells() {
        this.allCells = Object.keys(this.graph.getModel().cells)
            .map((key) => this.graph.getModel().cells[key])
            .filter((cell: MxCell) => cell.getValue()?.type === 'object')
            .map((cell: MxCell) => cell);
        this.allEdges = Object.keys(this.graph.getModel().cells)
            .map((key) => this.graph.getModel().cells[key])
            .filter((cell: MxCell) => cell.getValue()?.type === 'edge')
            .map((cell: MxCell) => cell);
    }

    private clearFilter() {
        if (this.allCells) {
            this.graph.setCellsActive(this.allCells);
        }
        if (this.allEdges) {
            this.graph.setCellsActive(this.allEdges);
        }
    }

    private getCellsByType(cell: MxCell) {
        const symbolId: string = (this.startCell?.getValue() as ObjectInstanceImpl).symbolId;

        return this.allCells.filter((t) => (t.getValue() as ObjectInstanceImpl).symbolId === symbolId);
    }

    private getCellsByObject(cell: MxCell) {
        const objectDefinitionId = (this.startCell?.getValue() as ObjectInstanceImpl).objectDefinitionId;

        return this.allCells.filter(
            (t) => (t.getValue() as ObjectInstanceImpl)?.objectDefinitionId === objectDefinitionId,
        );
    }
}
