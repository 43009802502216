import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import { TREE_NODE_TYPE_REQUEST_SUCCESS } from '../actionsTypes/treeNodeType.actionTypes';
import { CustomMap } from '../utils/map';
import { TReducer } from '../utils/types';
import { TTreeNodeTypeState } from './treeNodeType.reducer.types';

const initial: TTreeNodeTypeState = {
    byKey: new CustomMap(),
};

export const treeNodeTypeReducer: TReducer<TTreeNodeTypeState> = (state = initial, action) => {
    switch (action.type) {
        case TREE_NODE_TYPE_REQUEST_SUCCESS: {
            const {
                payload: { serverId, treeNodeTypes, presetId },
            } = action;
            const clone = { byKey: state.byKey.clone() };
            treeNodeTypes.forEach((type) =>
                clone.byKey.set({ id: type.id, presetId, serverId }, { ...type, serverId }),
            );

            return clone;
        }

        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            const mapClone = state.byKey.clone();
            mapClone.deleteByPartKey({ serverId, presetId });

            return { ...state, byKey: mapClone };
        }

        default:
            return state;
    }
};
