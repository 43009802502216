import { TReducer } from '../../utils/types';
import { SCRIPTS_EXECUTION_SAVE_TO_STORE, SCRIPTS_EXECUTION_UPDATE_IN_STORE, UPDATE_OPERATION_PROGRESS_IN_STORE } from '../../actionsTypes/entities/script.actionTypes';
import { TScriptExecutionState } from './scriptExecution.reducer.types';
import { CustomMap } from '@/utils/map';
import { ScriptOperationData } from '@/serverapi/api';

const INITIAL_SCRIPT_EXECUTION_STATE: TScriptExecutionState = {operationData: new CustomMap()};

export const scriptExecutionReducer: TReducer<TScriptExecutionState> = (
    state: TScriptExecutionState = INITIAL_SCRIPT_EXECUTION_STATE,
    action,
) => {
    switch (action.type) {
        case SCRIPTS_EXECUTION_SAVE_TO_STORE: {
            const {
                payload: { executions },
            } = action;

            const data = new CustomMap<string, ScriptOperationData>();
            executions.forEach(e => data.set(e.id, e));

            return  {
                ...state, 
                operationData: data
            };
        }
        case SCRIPTS_EXECUTION_UPDATE_IN_STORE: {
            const {
                payload: { executions },
            } = action;

            const data = state.operationData.clone();
            executions.forEach(e => data.set(e.id, e));

            return  {
                ...state, 
                operationData: data
            };
        }
        case UPDATE_OPERATION_PROGRESS_IN_STORE: {
            const {
                payload: { operationId, progress },
            } = action;

            const data = state.operationData.clone();
            const operation = data.get(operationId);
            if (operation) {
                operation.progress = progress;
                data.set(operationId, operation);
            }

            return  {
                ...state, 
                operationData: data
            };
        }
        default:
            return state;
    }
};
