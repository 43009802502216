import type { TAttributeOverlay } from '../../mxgraph/mxgraph.types';
import type {
    AttributeType,
    AttributeTypeStyle,
    AttributeValue,
    AttributeValuePrincipal,
    AttributeValueString,
    DiagramElement,
    DiagramElementAttributeStyle,
    EdgeDefinitionNode,
    EdgeInstance,
    InternationalString,
    ModelType,
    Node,
    ObjectDefinitionNode,
    ObjectInstance,
} from '../../serverapi/api';
import { isUmlAttribute, umlAttributeStyle, UML_ATTRIBUTES_SETTINGS } from '../../modules/Uml/UmlEdgeAttributesConst';
import { isUmlClassAttribute } from '../ComplexSymbols/symbols/UML/ClassSymbol/classSymbol.utils';
import { umlClassOverlayStyle } from '../ComplexSymbols/symbols/UML/ClassSymbol/classSymbol.constants';
import { TAttributeDiscriminator } from '@/modules/FloatingAttributes/FloatingAttributes.types';
import { BPMMxGraph } from '../bpmgraph';
import { MxCell, MxPoint } from '../mxgraph';
import {
    UmlAttributeHorizontolAlign,
    UmlAttributeTextAlign,
    UmlAttributeVerticalAlign,
} from './CellOverlayManager.constants';
import { modelSystemAttributeTypes, systemAttributeTypes } from '@/utils/constants/systemAttributes.const';

export const setOverlay = (overlays: Map<string, TAttributeOverlay[]>, config: Record<string, TAttributeOverlay>) => {
    const key = Object.keys(config)[0];
    const overlay = overlays.get(key);

    if (overlay) {
        overlay.push(config[key]);
    } else {
        overlays.set(key, [config[key]]);
    }
};

/**
 * Определяет стиль для атрибута. Если Нет стиля в элементе диаграммы то искать в стилях типа модели для выделенной ячейки
 * @param diagramElement - обьект или связь
 * @param attribute - тип атрибута
 * @param modelType - тип модели
 */
export function findStyleByAttributeTypeAndCellType(
    diagramElement: DiagramElement | EdgeInstance | undefined,
    attribute: AttributeType,
    graph?: BPMMxGraph,
    attributeDiscriminator?: TAttributeDiscriminator,
    cell?: MxCell,
): AttributeTypeStyle[] {
    if (!diagramElement) return [];

    const attributeStyles: DiagramElementAttributeStyle[] = [...(diagramElement.attributeStyles || [])];
    const modelType: ModelType | undefined = graph?.modelType;

    if (isUmlClassAttribute(attribute.id)) {
        attributeStyles.push({
            attributeDiscriminator: 'AUTO',
            typeId: attribute.id,
            styles: [umlClassOverlayStyle as AttributeTypeStyle],
        });
    }

    if (isUmlAttribute(attribute.id)) {
        const currentAttrStyle = attributeStyles.find((as) => as.typeId === attribute.id);
        const umlAttrSettings = UML_ATTRIBUTES_SETTINGS.find((item) => item.id === attribute.id);
        const v = umlAttrSettings?.align || UmlAttributeHorizontolAlign.Left;
        const h = umlAttrSettings?.verticalAlign || UmlAttributeVerticalAlign.Top;

        if (cell) graph?.refresh(cell);
        const edgePoints: MxPoint[] | undefined = graph?.getView().getState(cell || null)?.absolutePoints;

        const align = getUmlAttributeTextAlign(edgePoints || [], v, h);

        const umlAttrStyle = umlAttributeStyle(v, h, align) as AttributeTypeStyle;

        if (!currentAttrStyle) {
            attributeStyles.push({ typeId: attribute.id, styles: [umlAttrStyle], attributeDiscriminator: 'AUTO' });
            diagramElement.attributeStyles = attributeStyles;
        } else {
            currentAttrStyle.styles = [umlAttrStyle];
        }
    }

    const objectStyle =
        attributeStyles.find(
            (as) =>
                as.typeId === attribute.id && compareDiscriminators(as.attributeDiscriminator, attributeDiscriminator),
        )?.styles || [];

    if (diagramElement.ignorePresetStyles) return objectStyle;

    if (diagramElement.type === 'edge') {
        const presetStyle = modelType?.edgeAttributeStyles?.find(
            (attributeStyle) =>
                attributeStyle.edgeTypeId === (diagramElement as EdgeInstance).edgeTypeId &&
                attributeStyle.attributeTypeId === attribute.id &&
                compareDiscriminators(attributeStyle.attributeDiscriminator, attributeDiscriminator),
        )?.attributeStyle;
        const allStyles = presetStyle ? [...objectStyle, presetStyle] : objectStyle;

        return allStyles;
    }

    if (diagramElement.type === 'object') {
        // todo 4365
        // eslint-disable-next-line prefer-destructuring
        const presetStyle =
            modelType?.symbolAttributeStyles?.find(
                (attributeStyle) =>
                    attributeStyle.symbolId === (diagramElement as ObjectInstance)?.symbolId &&
                    attributeStyle.attributeTypeId === attribute.id &&
                    compareDiscriminators(attributeStyle.attributeDiscriminator, attributeDiscriminator),
            )?.styles || [];
        const allStyles = [...objectStyle, ...presetStyle];

        return allStyles;
    }

    return objectStyle;
}

export const compareDiscriminators = (
    discriminator1: TAttributeDiscriminator | undefined | null | string,
    discriminator2: TAttributeDiscriminator | undefined | null | string,
): boolean =>
    // если Discriminator пустой то считаем что это AUTO
    (discriminator1 || 'AUTO') == (discriminator2 || 'AUTO');

export const mapNodeToSystemAttributeValues = (
    node: Node | undefined,
    modelType: ModelType | undefined,
    forModel?: boolean,
) => {
    if (!node) return [];
    const systemAttributeValues: AttributeValue[] = (forModel ? modelSystemAttributeTypes : systemAttributeTypes).map(
        ({ id, valueType }) => {
            let value: string | number | boolean | undefined = '';
            let multilingualName: InternationalString | undefined;
            switch (id) {
                case 'confidential':
                case 'scriptEngineEditDisabled':
                case 'userEditDisabled':
                case 'createdAt':
                case 'updatedAt':
                case 'createdBy':
                case 'updatedBy':
                case 'deletedBy':
                case 'deletedAt': {
                    value = node[id];
                    break;
                }

                case 'nodeId': {
                    value = node[id].id;
                    multilingualName = { ru: node[id].id, en: node[id].id };
                    break;
                }

                case 'name': {
                    value = node[id];
                    multilingualName = node.multilingualName;
                    break;
                }
                case 'modelTypeId': {
                    value = modelType?.name;
                    multilingualName = modelType?.multilingualName;
                    break;
                }
                case 'objectTypeId': {
                    const object = modelType?.objectTypes.find((object) => object.id === node[id]);
                    value = object?.name;
                    multilingualName = object?.multilingualName;
                    break;
                }
                case 'edgeTypeId': {
                    const edgeType = modelType?.edgeTypes.find(
                        (edgeType) => edgeType.id === (node as EdgeDefinitionNode).edgeTypeId,
                    );
                    value = edgeType?.name;
                    multilingualName = edgeType?.multilingualName;
                    break;
                }
                case 'symbolId': {
                    const symbol = modelType?.symbols.find(
                        (symbol) => symbol.id === (node as ObjectDefinitionNode).idSymbol,
                    );
                    value = symbol?.name;
                    multilingualName = symbol?.multilingualName;
                    break;
                }
            }

            const defaultAttributeValue: AttributeValue = {
                id,
                typeId: id,
                value,
                valueType,
            } as AttributeValue;

            if (valueType === 'PRINCIPAL') {
                return {
                    ...defaultAttributeValue,
                    logins: [value],
                } as AttributeValuePrincipal;
            }

            if (valueType === 'STRING') {
                return {
                    ...defaultAttributeValue,
                    str: multilingualName,
                } as AttributeValueString;
            }

            return defaultAttributeValue;
        },
    );

    return systemAttributeValues;
};

export const mapEdgeInstanceToSystemAttributeValues = (edge: EdgeInstance, modelType: ModelType | undefined) => {
    const systemAttributeValues: AttributeValue[] = systemAttributeTypes.map(({ id, valueType }) => {
        let value: string | number | boolean | undefined = '';
        let multilingualName: InternationalString | undefined;
        switch (id) {
            case 'name': {
                multilingualName = edge.multilingualName;
                break;
            }
            case 'edgeTypeId': {
                const edgeType = modelType?.edgeTypes.find((edgeType) => edgeType.id === edge.edgeTypeId);
                value = edgeType?.name;
                multilingualName = edgeType?.multilingualName;
                break;
            }
        }

        const defaultAttributeValue: AttributeValue = {
            id,
            typeId: id,
            value,
            valueType,
        } as AttributeValue;

        if (valueType === 'STRING') {
            return {
                ...defaultAttributeValue,
                str: multilingualName,
            } as AttributeValueString;
        }

        return defaultAttributeValue;
    });

    return systemAttributeValues;
};

export const checkIsStartToEndDirection = (start: number, end: number): boolean | undefined => {
    return start === end ? undefined : start < end;
};

const chekIsLeftToRightSegment = (startX: number, endX: number): boolean | undefined => {
    return checkIsStartToEndDirection(startX, endX);
};

const checkIsTopToButtonSegment = (startY: number, endY: number): boolean | undefined => {
    return checkIsStartToEndDirection(startY, endY);
};

const getSegmentDirection = (start: MxPoint, end: MxPoint): [boolean | undefined, boolean | undefined] => {
    return [chekIsLeftToRightSegment(start.x, end.x), checkIsTopToButtonSegment(start.y, end.y)];
};

export const getUmlAttributeTextAlign = (
    edgePoints: MxPoint[],
    attributeHorizontolAlign: UmlAttributeHorizontolAlign,
    attributeVerticalAlign: UmlAttributeVerticalAlign,
): UmlAttributeTextAlign => {
    if (edgePoints.length < 2) return UmlAttributeTextAlign.Center;

    if (
        attributeHorizontolAlign === UmlAttributeHorizontolAlign.Left &&
        attributeVerticalAlign === UmlAttributeVerticalAlign.Bottom
    ) {
        const [start, end] = edgePoints;
        const [isLeftToRightSegment, isTopToButtonSegment] = getSegmentDirection(start, end);
        if (isLeftToRightSegment) return UmlAttributeTextAlign.Left;
        if (isLeftToRightSegment === false) return UmlAttributeTextAlign.Right;
        if (isTopToButtonSegment) return UmlAttributeTextAlign.Right;
        if (isTopToButtonSegment === false) return UmlAttributeTextAlign.Right;
    } else if (
        attributeHorizontolAlign === UmlAttributeHorizontolAlign.Left &&
        attributeVerticalAlign === UmlAttributeVerticalAlign.Top
    ) {
        const [start, end] = edgePoints;
        const [isLeftToRightSegment, isTopToButtonSegment] = getSegmentDirection(start, end);
        if (isLeftToRightSegment) return UmlAttributeTextAlign.Left;
        if (isLeftToRightSegment === false) return UmlAttributeTextAlign.Right;
        if (isTopToButtonSegment) return UmlAttributeTextAlign.Left;
        if (isTopToButtonSegment === false) return UmlAttributeTextAlign.Left;
    } else if (
        attributeHorizontolAlign === UmlAttributeHorizontolAlign.Right &&
        attributeVerticalAlign === UmlAttributeVerticalAlign.Top
    ) {
        const [end, start] = edgePoints.reverse();
        const [isLeftToRightSegment, isTopToButtonSegment] = getSegmentDirection(start, end);
        if (isLeftToRightSegment) return UmlAttributeTextAlign.Right;
        if (isLeftToRightSegment === false) return UmlAttributeTextAlign.Left;
        if (isTopToButtonSegment) return UmlAttributeTextAlign.Left;
        if (isTopToButtonSegment === false) return UmlAttributeTextAlign.Left;
    } else if (
        attributeHorizontolAlign === UmlAttributeHorizontolAlign.Right &&
        attributeVerticalAlign === UmlAttributeVerticalAlign.Bottom
    ) {
        const [end, start] = edgePoints.reverse();
        const [isLeftToRightSegment, isTopToButtonSegment] = getSegmentDirection(start, end);
        if (isLeftToRightSegment) return UmlAttributeTextAlign.Right;
        if (isLeftToRightSegment === false) return UmlAttributeTextAlign.Left;
        if (isTopToButtonSegment) return UmlAttributeTextAlign.Right;
        if (isTopToButtonSegment === false) return UmlAttributeTextAlign.Right;
    }

    return UmlAttributeTextAlign.Center;
};
