import { TReducer } from '../utils/types';
import { NOTIFICATION_CLOSE, NOTIFICATION_SHOW } from '../actionsTypes/notification.actionTypes';
import { TNotificationState } from './notification.reducer.types';

const initial: TNotificationState = {
    ids: [],
    entities: {},
};

export const notificationReducer: TReducer<TNotificationState> = (state = initial, action) => {
    switch (action.type) {
        case NOTIFICATION_SHOW: {
            const { notification } = action.payload;
            const { id } = notification;

            return {
                ids: [...state.ids, id],
                entities: {
                    ...state.entities,
                    [id]: {
                        ...notification,
                        id,
                    },
                },
            };
        }
        case NOTIFICATION_CLOSE: {
            const { id } = action.payload;
            const index = state.ids.indexOf(id);
            if (index !== -1) {
                const entities = {
                    ...state.entities,
                };
                delete entities[id];

                return {
                    ids: [...state.ids.splice(0, index), ...state.ids.splice(1)],
                    entities,
                };
            }
 
            return state;
            
        }

        default: {
            return state;
        }
    }
};
