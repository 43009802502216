import * as Stomp from 'stompjs';
import { Subscription } from './Subscription';

export class StompClientDecorator {
    private static readonly TOPIC_BASE: string = '/user/topic/';
    private stompClient: Stomp.Client;
    private readonly url: string;

    public constructor(serverURL: string){
        this.url = serverURL;
    }
    
    send = (destination: string, headers: any, body: any) => {
        if (this.isConnected()) {
            this.stompClient.send(destination, headers, JSON.stringify(body));
        }
    };

    isConnected = () => {
        return !!this.stompClient?.connected;
    };

    connect = (onConnectSuccess: () => void,
        onConnectError: () => void
    ) => {
        if (this.isConnected()) {
            return;
        }
        this.stompClient = Stomp.client(this.url);
        this.stompClient.connect(
            {},
            () => {
                // соединение установленно успешно
                onConnectSuccess();
            },
            () => {
                // ошибка соединения
                onConnectError();
            }
        );
    };

    subscribe = (endpointId: string, msgHandler: (message: any) => void): Subscription | undefined => {

        const destination: string = StompClientDecorator.TOPIC_BASE + endpointId;

        const subscription: Stomp.Subscription = this.stompClient.subscribe(destination, (msg: any) => {
            msgHandler(JSON.parse(msg.body));
        });
        
        return subscription ? new Subscription(endpointId, subscription.id, msgHandler, subscription.unsubscribe) : undefined;
    };

    disconnect = (onDisconnect?: () => any) => {
        this.stompClient.disconnect(onDisconnect || (() => {}));
    };

    getUrl(): string {
        return this.url;
    }
}
