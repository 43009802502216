import type { EditorState } from '@tiptap/pm/state';
import type { SingleCommands } from '@tiptap/core';
import { Extension } from '@tiptap/core';
import { getSelectedNode } from '../../common/helpers';

type TIndentOptions = {
    types: string[];
    minIndent: number;
    maxIndent: number;
};

enum IndentProps {
    MAX = 7,
    MIN = 0,

    MORE = 1,
    LESS = -1,
}

const createIndentCommand =
    ({ delta, types }: { delta: number; types: string[] }) =>
    ({ commands, state }: { commands: SingleCommands; state: EditorState }) => {
        const selectedNode = getSelectedNode(state.selection);

        if (!selectedNode) {
            return false;
        }

        const nodeType = selectedNode.type.name;
        const currentIndent = +selectedNode.attrs.indent || 0;
        const nextIndent = Math.max(IndentProps.MIN, Math.min(IndentProps.MAX, currentIndent + delta));

        if (types.includes(nodeType)) {
            return commands.updateAttributes(nodeType, { indent: nextIndent });
        }

        return false;
    };

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        indent: {
            /**
             * Set the indent attribute
             */
            indent: () => ReturnType;
            /**
             * Set the outdent attribute
             */
            outdent: () => ReturnType;
            /**
             * Unset the indent attribute
             */
            unsetIndent: () => ReturnType;
        };
    }
}

export const Indent = Extension.create<TIndentOptions>({
    name: 'indent',

    addOptions() {
        return {
            // TODO другие типы
            types: ['paragraph'],
            minIndent: IndentProps.MIN,
            maxIndent: IndentProps.MAX,
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    indent: {
                        default: 0,
                        parseHTML: (element) => {
                            // TODO нужно адаптировать под разные величины
                            const indent = element.style.paddingLeft;

                            return (indent ? parseInt(indent, 10) : 0) || 0;
                        },
                        renderHTML: (attributes) => {
                            const indent = attributes['indent'];

                            if (!indent) {
                                return {};
                            }

                            return { style: `padding-left: ${indent}em` };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            indent: () =>
                createIndentCommand({
                    delta: IndentProps.MORE,
                    types: this.options.types,
                }),
            outdent: () =>
                createIndentCommand({
                    delta: IndentProps.LESS,
                    types: this.options.types,
                }),
            unsetIndent:
                () =>
                ({ commands }) => {
                    return this.options.types.every((type: string) => commands.resetAttributes(type, 'indent'));
                },
        };
    },

    // TODO добавить шорткаты для всех команд
    // addKeyboardShortcuts() {
    //     return {
    //         Tab: () => this.editor.commands.indent(),
    //         'Shift-Tab': () => this.editor.commands.outdent(),
    //     };
    // },
});
