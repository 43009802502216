import {
    GET_QUERY_SELECT_ATTRIBUTE_VALUES,
    QUERY_SELECT_ATTRIBUTE_VALUES_REQUEST_SUCCESS,
} from '../actionsTypes/querySelectAttributeTypeValues.actionTypes';
import { AttributeType, NodeId } from '../serverapi/api';
import {
    TGetQuerySelectAttributeTypeValuesAction,
    TQueryData,
    TquerySelectAttributeTypeValuesRequestSuccessAction,
} from './querySelectAttributeTypeValues.actions.types';

export const getQuerySelectAttributeTypeValues = (
    presetId: string,
    modelId: NodeId | undefined,
    attributeTypes: AttributeType[],
): TGetQuerySelectAttributeTypeValuesAction => ({
    type: GET_QUERY_SELECT_ATTRIBUTE_VALUES,
    payload: {
        presetId,
        modelId,
        attributeTypes,
    },
});

export const querySelectAttributeTypeValuesRequestSuccess = (
    presetId: string,
    data: TQueryData[],
): TquerySelectAttributeTypeValuesRequestSuccessAction => ({
    type: QUERY_SELECT_ATTRIBUTE_VALUES_REQUEST_SUCCESS,
    payload: {
        presetId,
        data,
    },
});
