import React, { useEffect } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientEvent } from '@react-keycloak/core/lib/types';
import { KeycloakInitOptions } from 'keycloak-js';
import Keycloak from 'keycloak-js';
import { KeycloakSettings } from '@/serverapi/api';
import { useDispatch } from 'react-redux';
import { keycloakLogin } from '@/actions/authorization.actions';

type TKeycloakAuthProps = {
    keycloakSettings: KeycloakSettings;
    handleReRender: () => void;
};

export const KeycloakAuth = (props: TKeycloakAuthProps) => {
    const { keycloakSettings, handleReRender } = props;
    const dispatch = useDispatch();

    const initOpts: KeycloakInitOptions = {
        onLoad: 'login-required',
        checkLoginIframe: false // при завершении сессии не авторизовываться повторно
    };

    let keycloakClient: any = Keycloak({
        realm: keycloakSettings.realm,
        url: keycloakSettings.baseUrl,
        clientId: keycloakSettings.clientId,
    });


    useEffect(() => {
        return () => {
            keycloakClient = undefined; // костыль от дополнительного запроса авторизации
        };
    }, []);

    const onEvent = (event: AuthClientEvent) => {
        if (event === 'onAuthSuccess' && keycloakClient?.token) {
            dispatch(keycloakLogin(keycloakClient.token, handleReRender));
        }
    };

    return (
        <ReactKeycloakProvider
            autoRefreshToken={false}
            authClient={keycloakClient}
            onEvent={onEvent}
            initOptions={initOpts}
        />
    );
};
