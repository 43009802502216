import { defineMessages } from 'react-intl';

export default defineMessages({
    formConfirmButton: {
        id: 'ExtendableSelect.formConfirmButton',
        defaultMessage: 'Применить',
    },
    formCancelButton: {
        id: 'ExtendableSelect.formCancelButton',
        defaultMessage: 'Отмена',
    },
    formDefaultValidationMessage: {
        id: 'ExtendableSelect.formDefaultValidationMessage',
        defaultMessage: 'Поле не должно быть пустым',
    },
    selectAddNewValue: {
        id: 'ExtendableSelect.selectAddNewValue',
        defaultMessage: 'Добавить...',
    },
    selectResetValues: {
        id: 'ExtendableSelect.selectResetValues',
        defaultMessage: 'Очистить',
    },
});
