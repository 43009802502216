export const containsIgnoreCase = (strings: string | undefined | (string | undefined)[], value: string): boolean =>
    Array.isArray(strings) ? contains(strings, value) : contains([strings], value);

// Returns true if value is undefined
export const contains = (properties: (string | undefined)[], value: string): boolean =>
    properties.some((p: string) => !value || p?.toLowerCase().includes(value.toLowerCase()));

export const isString = (value): boolean => typeof value === 'string' || value instanceof String;

export const parseDomToXml = (domObj: Document): string => {
    const serializer = new XMLSerializer();

    return serializer.serializeToString(domObj);
};

export const parseXmlToDom = (xmlDoc: string): Document => {
    const parser = new DOMParser();

    return parser.parseFromString(xmlDoc, 'text/xml');
};

export function checkXmlParseError(parsedDocument) {
    const parser = new DOMParser();
    const errorneousParse = parser.parseFromString('<', 'application/xml');
    const parsererrorNS = errorneousParse.getElementsByTagName('parsererror')[0].namespaceURI;

    if (parsererrorNS === 'http://www.w3.org/1999/xhtml') {
        return parsedDocument.getElementsByTagName('parsererror').length > 0;
    }

    return parsedDocument.getElementsByTagNameNS(parsererrorNS, 'parsererror').length > 0;
}

export function floatToIntegerConverter(pointValue: string): string {
    const value = Number(pointValue);
    const result = Math.floor(value * 100);

    return result.toString();
}

export function integerToFloatConverter(pointValue: string): string {
    const value = Number(pointValue);
    const result = value / 100;

    return result.toString();
}
