export enum EventRunStrategy {
    BEFORE = 'BEFORE',
    AFTER = 'AFTER',
    ERROR = 'ERROR',
    INSTEAD = 'INSTEAD',
}

export enum EventActions {
    READ_TREE_NODE = 'READ_TREE_NODE',
    SAVE_TREE_NODE = 'SAVE_TREE_NODE',
    MOVE_TREE_NODE = 'MOVE_TREE_NODE',
    UNLOCK_TREE_NODE = 'UNLOCK_TREE_NODE',
    DELETE_TREE_NODE = 'DELETE_TREE_NODE',
    CHANGE_STATUS_APPROVAL = "CHANGE_STATUS_APPROVAL",
    CHANGE_STATUS_APPROVAL_STAGE = "CHANGE_STATUS_APPROVAL_STAGE",
    VOTE_EVENT = "VOTE_EVENT",
    COMMENT_APPROVAL = "COMMENT_APPROVAL",
    CREATE_APPROVAL = "CREATE_APPROVAL",
    UPDATE_APPROVAL = "UPDATE_APPROVAL",
}
