import { MxCellState } from '../mxgraph';
import { BPMMxSelectionCellsHandler } from '../handler/BPMMxSelectionCellsHandler.class';
import { SequenceGraph } from './SequenceGraph';

export class SequenceSelectionCellsHandler extends BPMMxSelectionCellsHandler {
    graph: SequenceGraph;
    updateHandler(state: MxCellState) {
        let handler = this.handlers.remove(state.cell);
        if (handler != null) {
            // Transfers the current state to the new handler
            const { index } = handler;
            const x = handler.startX;
            const y = handler.startY;

            handler.destroy();
            handler = this.graph.createHandler(state);

            if (handler != null) {
                this.handlers.put(state.cell, handler);

                if (index != null && x != null && y != null) {
                    handler.start(x, y, index);
                }
            }
        }
    }
}
