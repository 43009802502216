import { connect } from 'react-redux';
import { openDialog } from '../../../../actions/dialogs.actions';
import { resetImportVisioData } from '../../../../actions/import.actions';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { ImportSelectors } from '../../../../selectors/import.selectors';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { ImportVisio } from '../components/ImportVisio/ImportVisio.component';

const mapStateToProps = (state: TRootState) => ({
    isLoading: ImportSelectors.isLoading(state),
});

const mapDispatchToProps = {
    addFiles: () => openDialog(DialogType.UPLOAD_VISIO_FILES_DIALOG, {}),
    resetStoreData: resetImportVisioData,
};

export const ImportVisioContainer = connect(mapStateToProps, mapDispatchToProps)(ImportVisio);
