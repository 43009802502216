import { TPreset } from '../../../../../../models/preset.types';
import { TreeNode } from '../../../../../../models/tree.types';
import { AttributeType, EdgeType, EdgeTypeGroup, ModelType } from '../../../../../../serverapi/api';
import { TWithWindowResizeProps } from '../../../../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import { WrappedComponentProps } from 'react-intl';
import { TSubmitEdgeTypePayload } from '../../../../../../actions/edgeType.actions.types';
import { Locale } from '../../../../../Header/components/Header/header.types';
import { TWorkspaceTab } from '../../../../../../models/tab.types';

type TEdgeTypeEditorProps = TWithWindowResizeProps & {
    edgeType: EdgeType;
    edgeTypeGroups: EdgeTypeGroup[];
    serverNode: TreeNode;
    preset: TPreset;
    availableAttributeTypes: AttributeType[];
    createMode: boolean;
    modelTypes: ModelType[];
    currentLocale: Locale;
    tab: TWorkspaceTab;
};

export type TEdgeTypeEditorActionProps = {
    onCancel: () => void;
    exportStyle: (edgeType: EdgeType) => void;
    onSubmit: (payload: TSubmitEdgeTypePayload) => void;
};

export type TEdgeTypeEditorFullProps = TEdgeTypeEditorProps & TEdgeTypeEditorActionProps & WrappedComponentProps;

export enum EdgeMatrixStyleType {
    CUSTOM = 'CUSTOM',
    ICON = 'ICON',
    USER_ICON = 'USER_ICON',
}

export enum EdgeMatrixStyleIconIcon {
    PLUS = 'PLUS',
    MINUS = 'MINUS',
    CHECK = 'CHECK',
    CROSS = 'CROSS',
}

export type TEdgeTypeMatrixStyleValidation = {
    custom: boolean;
    userIcon: boolean;
};
