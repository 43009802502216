import { TReducer } from '../utils/types';
import { FETCHED_DB_ACCESS_INFO_SUCCESS } from '../actionsTypes/databaseDialog.actionTypes';
import { TDBAccessOwnersState } from './databaseAccess.reducer.types';

const initial: TDBAccessOwnersState = {
    byServerId: {},
};

export const databaseAccessReducer: TReducer<TDBAccessOwnersState> = (state = initial, action) => {
    switch (action.type) {
        case FETCHED_DB_ACCESS_INFO_SUCCESS: {
            const { serverId, owners } = action.payload;
            const ownersMap = new Map(state.byServerId[serverId]);
            owners.forEach((value, key) => {
                ownersMap.set(key, value);
            });

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: ownersMap,
                },
            };
        }
        default:
            return state;
    }
};
