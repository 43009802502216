import React, { FC, useState } from 'react';
import theme from './ApprovalDialog.scss';
import { Form, FormInstance } from 'antd';
import { useSelector } from 'react-redux';
import {
    ApprovalStageDTO,
    PrincipalDescriptor,
} from '@/serverapi/api';
import {  ApprovalStageDTOStatus } from './ApprovalDialog.types';
import { PrincipalsSelectors } from '../../selectors/principals.selectors';
import { StageNameAndApprovalUsersComponent } from './StageNameAndApprovalUsers.component';
import { ApprovalTypeComponent } from './ApprovalType.component';
import { VoteStrategyAndAllowVoteChangeComponent } from './VoteStrategyAndAllowVoteChange.component';
import { RateOfSuccessComponent } from './RateOfSuccess.component';

type TStageSettingsProps = {
    stages: ApprovalStageDTO[] | undefined;
    stageFormRef: React.RefObject<FormInstance>;
    isCreateMode?: boolean;
    stageId: string;
    isTemplate?: boolean;
    onChangeStages: (stageList: ApprovalStageDTO[] | undefined) => void;
};

const StageSettingsTab: FC<TStageSettingsProps> = (props) => {
    const { stages, stageFormRef, stageId, isCreateMode, isTemplate, onChangeStages } = props;

    const [isSelectUserOpen, setIsSelectUserOpen] = useState<boolean>(false);
    const [userIdToAddAssistantTo, setUserIdToAddAssistantTo] = useState<number | null>(null);

    const selectedStage = stages?.find((stage) => stage.id === stageId);

    if (!selectedStage) return null;

    const users: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getUsers);

    const notSelectedUsers = users.filter((user) => {
        if (!selectedStage.approvalUsersDTO) return true;

        const isUserInApprovalUsersOrAssistants = selectedStage.approvalUsersDTO.find((userDTO) => {
            if (userDTO.principalId === user.id) return true;

            if (userIdToAddAssistantTo) {
                const userToAddAssistantTo = selectedStage.approvalUsersDTO?.find(
                    (usr) => usr.principalId === userIdToAddAssistantTo,
                );

                return userToAddAssistantTo?.assistants?.find((assistant) => assistant.assistantId === user.id);
            }

            const isAssistant = userDTO.assistants?.find((assistant) => assistant.assistantId === user.id);

            return isAssistant;
        });

        return !isUserInApprovalUsersOrAssistants;
    });

    const showApprovalUsersError = selectedStage.approvalUsersDTO?.length === 0;
    const showApprovalUsersErrorTemplate = selectedStage.approvalUsersDTO?.length === 0;

    const isEditingDisabled =
        !isTemplate &&
        !isCreateMode &&
        (selectedStage.status === ApprovalStageDTOStatus.APPROVED ||
            selectedStage.status === ApprovalStageDTOStatus.NOT_APPROVED);

    return (
        <Form autoComplete='off' ref={stageFormRef} layout="vertical" className={theme.form}>
            <StageNameAndApprovalUsersComponent
                selectedStage={selectedStage}
                isTemplate={isTemplate}
                isEditingDisabled={isEditingDisabled}
                showApprovalUsersErrorTemplate={showApprovalUsersErrorTemplate}
                showApprovalUsersError={showApprovalUsersError}
                isSelectUserOpen={isSelectUserOpen}
                notSelectedUsers={notSelectedUsers}
                isCreateMode={isCreateMode}
                userIdToAddAssistantTo={userIdToAddAssistantTo}
                setIsSelectUserOpen={setIsSelectUserOpen}
                setUserIdToAddAssistantTo={setUserIdToAddAssistantTo}
                stageFormRef={stageFormRef}
                users={users}
                stages={stages}
                stageId={stageId}
                onChangeStages={onChangeStages}
            />
            <hr />
            <ApprovalTypeComponent
                selectedStage={selectedStage}
                isTemplate={isTemplate}
                isEditingDisabled={isEditingDisabled}
                isCreateMode={isCreateMode}
                onChangeStages={onChangeStages}
                stages={stages}
                stageId={stageId}
            />
            <hr />
            <VoteStrategyAndAllowVoteChangeComponent
                selectedStage={selectedStage}
                isTemplate={isTemplate}
                isEditingDisabled={isEditingDisabled}
                stages={stages}
                stageId={stageId}
                onChangeStages={onChangeStages}
                stageFormRef={stageFormRef}
            />
            <hr />
            <RateOfSuccessComponent
                selectedStage={selectedStage}
                isTemplate={isTemplate}
                isEditingDisabled={isEditingDisabled}
                onChangeStages={onChangeStages}
                stageFormRef={stageFormRef}
                stages={stages}
                stageId={stageId}
            />
        </Form>
    );
};

export { StageSettingsTab };
