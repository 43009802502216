import { TREE_ITEM_DELETE } from '@/actionsTypes/tree.actionTypes';
import { APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM } from '../../actionsTypes/app.actionTypes';
import {
    EDGE_DEFINITIONS_ADD,
    EDGE_DEFINITIONS_ADD_ONLY_NEW,
} from '../../actionsTypes/entities/edgeDefinition.actionTypes';
import { EdgeDefinitionNode } from '../../serverapi/api';
import { TReducer } from '../../utils/types';
import { TEdgeDefinitionState } from './edgeDefinition.reducer.types';
import { TNodeState } from './TNodeState';

const INITIAL_EDGE_STATE = new TNodeState() as TEdgeDefinitionState;

export const edgeDefinitionReducer: TReducer<TEdgeDefinitionState> = (state = INITIAL_EDGE_STATE, action) => {
    switch (action.type) {
        case EDGE_DEFINITIONS_ADD: {
            const newState = state.cloneByNodeId();
            action.payload?.forEach((ed) => {
                newState.byNodeId.set(ed.nodeId, ed);
            });

            return newState;
        }

        case APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM: {
            const {
                payload: { definition },
            } = action;

            if (definition?.edges) {
                const edgeDefinitions: EdgeDefinitionNode[] = definition.edges;
                const newState = state.cloneByNodeId();
                edgeDefinitions.forEach((ed) => newState.byNodeId.set(ed.nodeId, ed));

                return newState;
            }

            return state;
        }

        case EDGE_DEFINITIONS_ADD_ONLY_NEW: {
            const { edgeDefinitions } = action.payload;
            const newState = state.cloneByNodeId();

            edgeDefinitions.forEach((ed) => {
                if (!state.get(ed.nodeId)) {
                    newState.byNodeId.set(ed.nodeId, ed);
                }
            });

            return newState;
        }

        case TREE_ITEM_DELETE: {
            const { nodeId } = action.payload;

            return state.delete(nodeId);
        }

        default: {
            return state;
        }
    }
};
