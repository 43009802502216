/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import BPMMxCellOverlay from './overlays/BPMMxCellOverlay';
import { MxCellState, MxConstants, MxPoint, MxUtils, MxCellRenderer, MxDictionary, MxClient, MxShape } from './mxgraph';
import { BPMMxGraph } from './bpmgraph';
import { isCommentCell } from '@/utils/bpm.mxgraph.utils';

MxCellRenderer.prototype.createCellOverlays = function (state: MxCellState) {
    const graph = state.view.graph as BPMMxGraph;

    if (!graph.cellOverlayManager) return;

    const overlays: BPMMxCellOverlay[] = graph.cellOverlayManager.getCellOverlays(state.cell);

    overlays?.sort((a, b) => a.zIndex - b.zIndex);

    let dict: MxDictionary | null = null;

    if (overlays) {
        dict = new MxDictionary();

        for (const overlay of overlays) {
            const shape = graph.cellOverlayManager.getShape(overlay);

            shape.dialect = graph.dialect;
            shape.preserveImageAspect = false;
            shape.overlay = overlay;

            this.initializeOverlay(state, shape);
            this.installCellOverlayListeners(state, overlay, shape);

            const renderedHeight = graph.cellOverlayManager.getRenderedHeight(shape);

            if (renderedHeight) {
                shape.overlay.updateRenderedHeight(renderedHeight);
            }
            if (overlay.cursor != null) {
                shape.node.style.cursor = overlay.cursor;
            }

            dict.put(overlay, shape);
        }
    }

    // Removes unused
    if (state.overlays != null) {
        state.overlays.visit((id, shape) => {
            shape.destroy();
        });
    }

    state.overlays = dict;
};

MxCellRenderer.prototype.redrawCellOverlays = function (state: MxCellState, forced: boolean) {
    this.createCellOverlays(state);

    if (state.overlays != null) {
        const rot = MxUtils.mod(MxUtils.getValue(state.style, MxConstants.STYLE_ROTATION, 0), 90);
        const rad = MxUtils.toRadians(rot);
        const cos = Math.cos(rad);
        const sin = Math.sin(rad);

        state.overlays.visit((id, shape) => {
            const bounds = shape.overlay.getBounds(state);

            if (!state.view.graph.getModel().isEdge(state.cell)) {
                if (state.shape !== null && rot !== 0) {
                    let cx = bounds.getCenterX();
                    let cy = bounds.getCenterY();

                    const point = MxUtils.getRotatedPoint(
                        new MxPoint(cx, cy),
                        cos,
                        sin,
                        new MxPoint(state.getCenterX(), state.getCenterY()),
                    );

                    cx = point.x;
                    cy = point.y;
                    bounds.x = Math.round(cx - bounds.width / 2);
                    bounds.y = Math.round(cy - bounds.height / 2);
                }
            }

            if (forced || shape.bounds == null || shape.scale !== state.view.scale || !shape.bounds.equals(bounds)) {
                shape.bounds = bounds;
                shape.scale = state.view.scale;
                shape.node.setAttribute('data-test-id', id);
                shape.redraw();
            }
        });
    }
};

MxCellRenderer.prototype.initializeShape = function (state: MxCellState) {
    state.shape.dialect = state.view.graph.dialect;
    this.configureShape(state);

    if (isCommentCell(state.cell)) {
        state.shape.init(state.view.getDecoratorPane());
    } else {
        state.shape.init(state.view.getDrawPane());
    }
};

MxCellRenderer.prototype.initializeLabel = function (state: MxCellState, shape: MxShape) {
    if (MxClient.IS_SVG && MxClient.NO_FO && shape.dialect !== MxConstants.DIALECT_SVG) {
        shape.init(state.view.graph.container);
    } else if (isCommentCell(state.cell)) {
        shape.init(state.view.getDecoratorPane());
    } else {
        shape.init(state.view.getDrawPane());
    }
};

MxCellRenderer.prototype.isShapeInvalid = function (state: MxCellState, shape: MxShape) {
    return (
        shape.bounds == null ||
        shape.scale !== state.view.scale ||
        (state.absolutePoints == null && !shape.bounds.equals(state)) ||
        (shape.bounds.x === 0 && shape.bounds.y === 0) ||
        (state.absolutePoints != null && !MxUtils.equalPoints(shape.points, state.absolutePoints))
    );
};
