import { CREATE_MODEL_TEMPLATE } from '@/actionsTypes/createModelTemplate.actionTypes';
import {
    TCreateModelTemplateRequestAction,
    TCreateModelTemplateRequestPayload,
} from './createModelTemplate.actions.types';

export const createModelTemplate = (
    payload: TCreateModelTemplateRequestPayload,
): TCreateModelTemplateRequestAction => ({
    type: CREATE_MODEL_TEMPLATE,
    payload,
});
