import { takeEvery } from 'redux-saga/effects';
import { getStore } from '../store';
import {
    subscribeToScriptExecutions
} from '../actions/entities/script.actions';
import { changeOnlineServerStatus } from '../actions/entities/servers.actions';
import { showNotification } from '../actions/notification.actions';
import { IConnectionOpenModel } from '../models/connectionOpenModel.types';
import { NotificationType } from '../models/notificationType';
import { IConnectionErrorModel } from '../models/connectionErrorModel.types';
import { WSService } from '../services/ws/WSService';
import { StompClientDecorator } from '../services/ws/StompClientDecorator';
import { WebsocketUtils } from '../utils/WebsocketUtils';
import { INIT_WS_CONNECTION } from '../actionsTypes/wsConnection.actionTypes';
import { TInitWSConnectionAction } from '../actions/wsConnection.actions.types';
import { v4 as uuid } from 'uuid';

function* initializeWSConnection(params: TInitWSConnectionAction) {
    const {
        payload: {serverId, url},
    } = params;
    const serverURL = new URL(url);

    // Установка соединения по вебсокетам.
    // Дальнейшая подписка на события(уведомления скриптов, обновление дерева и тд) по WS будет
    // по этому соединению(1 соединение на 1 сервер)
    WSService.initialize(new StompClientDecorator(WebsocketUtils.getWSServerURL(serverId, serverURL.host)))
        .connect(onConnectionSuccess(serverId), onReconnectSuccess(serverId, serverURL), onConnectError(serverId, serverURL));
}

const onConnectionSuccess = (serverId: string) => () => {
    //подписка для обновления SE
    getStore().dispatch(subscribeToScriptExecutions(serverId));
};

const onConnectError = (serverId: string, url: URL) => () => {
    getStore().dispatch(changeOnlineServerStatus(serverId, false));
    getStore().dispatch(
        showNotification({
            id: uuid(),
            data: {hostAndPort: url.host} as IConnectionErrorModel,
            type: NotificationType.CONNECTION_LOST_ERROR,
        }),
    );
};

const onReconnectSuccess = (serverId: string, url: URL) => () => {
    getStore().dispatch(changeOnlineServerStatus(serverId, true));
    getStore().dispatch(
        showNotification({
            id: uuid(),
            data: {hostAndPort: url.host} as IConnectionOpenModel,
            type: NotificationType.CONNECTION_OPENED_NOTIFICATION,
        }),
    );
};

export function* wsConnectionSaga() {
    yield takeEvery(INIT_WS_CONNECTION, initializeWSConnection);
}
