// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spreadsheet__spreadsheetContainer__Z8gGm{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/Spreadsheet/Spreadsheet.scss"],"names":[],"mappings":"AAAA,0CACI,UAAA,CACA,WAAA","sourcesContent":[".spreadsheetContainer {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spreadsheetContainer": "Spreadsheet__spreadsheetContainer__Z8gGm"
};
export default ___CSS_LOADER_EXPORT___;
