import { getExternalEdgeConnections } from '@/services/bll/NavigatorEdgePropertyBll';
import { TModelTypes, TObjectConnectionData } from '@/services/types/NavigatorEdgePropertyBll.types';
import { List } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import messages from './NavigatorEdgeTab.messages';
import theme from './NavigatorEdgeTab.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loadModelById } from '@/actions/loadModel.actions';
import { NodeId, ObjectModelConnections } from '@/serverapi/api';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';

type TModelObjectConnectionsListProps = {
    nodeId: NodeId;
    objectModelConnections: ObjectModelConnections[];
};

export const ModelObjectConnectionsList: FC<TModelObjectConnectionsListProps> = (props) => {
    const { nodeId, objectModelConnections } = props;

    const intl = useIntl();
    const dispatch = useDispatch();

    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const modelTypes: TModelTypes =
        useSelector(ModelTypeSelectors.byServerIdPresetId(nodeId.serverId, presetId)).byId || [];

    const handleOpenModelById = (id: string, idToSelect?: string) => {
        if (nodeId) {
            dispatch(loadModelById({ ...nodeId, id }, [idToSelect]));
        }
    };

    const unknownText = intl.formatMessage(messages.unknown);

    const objectConnectionsData: TObjectConnectionData[] = getExternalEdgeConnections(
        objectModelConnections,
        modelTypes,
        unknownText,
        nodeId?.repositoryId || '',
        nodeId.serverId,
    );

    return (
        <>
            {objectConnectionsData.map((data) => (
                <List
                    key={data.modelId}
                    header={<b data-test="connection-window_model-name">{data.modelName}:</b>}
                    dataSource={data.connections}
                    renderItem={(connection) => (
                        <List.Item>
                            <div>
                                <div data-test="connection-window_edge">
                                    {`${intl.formatMessage(messages.edge)}: `}
                                    <span className={theme.link} onClick={() => handleOpenModelById(data.modelId)}>
                                        {connection.edgeTypeName}
                                    </span>
                                    {connection.isOutgoingEdge
                                        ? ` (${intl.formatMessage(messages.source)})`
                                        : ` (${intl.formatMessage(messages.target)})`}
                                </div>
                                <div data-test="connection-window_object-name">
                                    {`${intl.formatMessage(messages.object)}: `}
                                    <span
                                        className={theme.link}
                                        onClick={() => {
                                            handleOpenModelById(data.modelId, connection.connectedObjectInstanceId);
                                        }}
                                    >
                                        {connection.connectedObjectName}
                                    </span>
                                    {` (${connection.objectTypeName})`}
                                </div>
                            </div>
                        </List.Item>
                    )}
                    className={`${theme.list} ${theme.header}`}
                />
            ))}
        </>
    );
};
