import { NotificationDTORecipientsTypeEnum, NotificationDTOTypeEnum } from '@/serverapi/api';

export const notificationTypes: NotificationDTOTypeEnum[] = ['SAVE_TREE_NODE'];

export const notificationRecipients: NotificationDTORecipientsTypeEnum[] = [
    'ALL',
    'AUTHOR',
    'CHANGED_USER',
    'EMAILS',
    'SELECTED',
    'SUBSCRIBER',
];
