import {
    DELETE_PRESET_SUCCESS,
    EDIT_PRESET_REQUEST,
    EDIT_PRESET_REQUEST_SUCCESS,
    LOAD_PRESETS_SUCCESS,
    SUBMIT_PRESET,
    SUBMIT_PRESET_SUCCESS,
    SUBMIT_PRESET_COPY,
    PRESET_COPY_LOADING_FINISH,
    CHANGE_DEFAULT_PRESET,
} from '../actionsTypes/methodologySetting.actionTypes';
import { TPreset } from '../models/preset.types';
import { TReducer } from '../utils/types';
import { TPresetState } from './preset.reducer.types';
import {
    EDGE_TYPE_REQUEST_FAILURE,
    EDGE_TYPE_REQUEST_SUCCESS,
    SUBMIT_EDGE_TYPE,
} from '../actionsTypes/edgeType.actionTypes';
import { MODEL_TYPE_REQUEST_SUCCESS } from '../actionsTypes/modelType.actionTypes';
import {
    OBJECT_TYPE_REQUEST_FAILURE,
    OBJECT_TYPE_REQUEST_SUCCESS,
    SUBMIT_OBJECT_TYPE,
} from '../actionsTypes/objectType.actionTypes';
import { FOLDER_TYPE_REQUEST_SUCCESS } from '../actionsTypes/folderType.actionTypes';
import { PRESET_IMAGE_REQUEST_SUCCESS } from '../actionsTypes/presetSettings/presetImage.actionTypes';
import { cloneDeep } from 'lodash-es';

const initial: TPresetState = {
    byServerId: {},
    loading: false,
};

export const presetReducer: TReducer<TPresetState> = (state = initial, action) => {
    switch (action.type) {
        case LOAD_PRESETS_SUCCESS: {
            const {
                presets,
                serverNode: {
                    nodeId: { serverId },
                },
            } = action.payload;

            return {
                ...state,
                byServerId: {
                    [serverId]: {
                        byId: presets.reduce((acc, preset: TPreset) => {
                            return {
                                ...acc,
                                [preset.id]: {
                                    ...preset,
                                    saved: true,
                                },
                            };
                        }, {}),
                    },
                },
                loading: false,
            };
        }

        case SUBMIT_PRESET_SUCCESS: {
            const {
                preset,
                serverNode: {
                    nodeId: { serverId },
                },
            } = action.payload;
            const byId = (state.byServerId[serverId] && state.byServerId[serverId].byId) || {};

            return {
                ...state,
                byServerId: {
                    [serverId]: {
                        byId: {
                            ...byId,
                            [preset.id]: {
                                ...preset,
                                saved: true,
                            },
                        },
                    },
                },
                loading: false,
            };
        }

        case DELETE_PRESET_SUCCESS: {
            const {
                preset,
                serverNode: {
                    nodeId: { serverId },
                },
            } = action.payload;
            const byId = (state.byServerId[serverId] && state.byServerId[serverId].byId) || {};
            delete byId[preset.id];

            return JSON.parse(JSON.stringify(state));
        }

        case EDGE_TYPE_REQUEST_SUCCESS:
        case MODEL_TYPE_REQUEST_SUCCESS:
        case OBJECT_TYPE_REQUEST_SUCCESS:
        case PRESET_IMAGE_REQUEST_SUCCESS:
        case FOLDER_TYPE_REQUEST_SUCCESS:
        case EDIT_PRESET_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case OBJECT_TYPE_REQUEST_FAILURE:
        case EDGE_TYPE_REQUEST_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }
        case PRESET_COPY_LOADING_FINISH: {
            return {
                ...state,
                loading: false,
            };
        }

        case SUBMIT_PRESET_COPY:
        case SUBMIT_PRESET:
        case SUBMIT_EDGE_TYPE:
        case SUBMIT_OBJECT_TYPE:
        case EDIT_PRESET_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case CHANGE_DEFAULT_PRESET: {
            const { defaultPresetId, newDefaultPresetId, serverId } = action.payload;

            const stateClone: TPresetState = cloneDeep(state);

            const defaultPreset: TPreset | undefined = stateClone.byServerId[serverId]?.byId[defaultPresetId];
            if (defaultPreset) {
                stateClone.byServerId[serverId].byId[defaultPresetId] = {
                    ...defaultPreset,
                    prime: false,
                };
            }

            const newDefaultPreset: TPreset | undefined = stateClone.byServerId[serverId]?.byId[newDefaultPresetId];
            if (newDefaultPreset) {
                stateClone.byServerId[serverId].byId[newDefaultPresetId] = {
                    ...newDefaultPreset,
                    prime: true,
                };
            }

            return stateClone;
        }

        default:
            return state;
    }
};
