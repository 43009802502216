import { Locale } from '@/modules/Header/components/Header/header.types';
import { TApprovalTemplates, TApprovalTemplatesState } from '@/reducers/approvalTemplates.reducer.types';
import { TRootState } from '@/reducers/root.reducer.types';
import { ApprovalDTO, ApprovalTemplateDTO, NodeId } from '@/serverapi/api';
import { getApprovalTemplatesArray } from '@/services/bll/ApprovalTemplatesBLLService';
import { createSelector } from 'reselect';
import { LocalesService } from '@/services/LocalesService';
import { getCurrentLocale } from './locale.selectors';
import { v4 as uuid } from 'uuid';

export const approvalTemplateStateSelector = (state: TRootState) => state.approvalTemplates;

export const approvalTemplates = (state: TRootState) => state.approvalTemplates.byId;

export namespace ApprovalTemplatesSelectors {
    export const getCheckedApprovalTemplatesIds = createSelector<TRootState, TApprovalTemplatesState, string[]>(
        approvalTemplateStateSelector,
        (state: TApprovalTemplatesState) => {
            return state.checkedApprovalTemplatesIds;
        },
    );

    export const getEditingApprovalTemplate = createSelector<TRootState, TApprovalTemplatesState, ApprovalTemplateDTO>(
        approvalTemplateStateSelector,
        (state) => {
            return state.editingApprovalTemplate || {};
        },
    );

    export const isApprovalTemplatesChecked = (id: string) =>
        createSelector<TRootState, string[], boolean>([getCheckedApprovalTemplatesIds], (checkedIds) => {
            return checkedIds.includes(id);
        });

    export const getFilteredApprovalTemplates = (searchText: string, locale: Locale) =>
        createSelector<TRootState, TApprovalTemplates, ApprovalTemplateDTO[]>(approvalTemplates, (byId) => {
            if (!byId) return [];
            const approvalTemplates: ApprovalTemplateDTO[] = getApprovalTemplatesArray(byId);

            return approvalTemplates.filter(
                (item) =>
                    item.id.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.name[locale]?.toLowerCase().includes(searchText.toLowerCase()),
            );
        });

    export const byApprovalTemplateId = (id: string) =>
        createSelector<TRootState, TApprovalTemplatesState, ApprovalTemplateDTO | undefined>(
            approvalTemplateStateSelector,
            (state): ApprovalTemplateDTO => {
                return state.byId[id];
            },
        );

    export const getSortedApprovalTemplateData = (
        searchText: string,
        property: string,
        sortDirection: string,
        lastApprovalTemplateIds: string[],
        locale: Locale,
    ) =>
        createSelector<TRootState, ApprovalTemplateDTO[], ApprovalTemplateDTO[]>(
            getFilteredApprovalTemplates(searchText, locale),
            (data: ApprovalTemplateDTO[]): ApprovalTemplateDTO[] => {
                if (!sortDirection && lastApprovalTemplateIds.length) {
                    const end: ApprovalTemplateDTO[] = data.filter(
                        (template) => !lastApprovalTemplateIds.includes(template.id),
                    );
                    const start: ApprovalTemplateDTO[] = [];
                    lastApprovalTemplateIds.forEach((templateId) => {
                        const lastTemplate = data.find((template) => template.id === templateId);
                        if (lastTemplate) start.push(lastTemplate);
                    });

                    return [...start, ...end];
                }

                return data.sort((a, b) => {
                    const valueA = typeof a[property] === 'object' ? a[property][locale] : a[property];
                    const valueB = typeof b[property] === 'object' ? b[property][locale] : b[property];

                    return sortDirection === 'desc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                });
            },
        );

    export const getNewApproval = (approvalTemplateId: string, approvedItemNodeId: NodeId) => {
        return (state: TRootState): ApprovalDTO => {
            const locale: Locale = getCurrentLocale(state);
            const approvalTemplate: ApprovalTemplateDTO | undefined = byApprovalTemplateId(approvalTemplateId)(state);
            const name: string = LocalesService.internationalStringToString(approvalTemplate?.approvalName, locale);
            const description: string = LocalesService.internationalStringToString(
                approvalTemplate?.approvalDescription,
                locale,
            );
    
            return {
                id: {
                    ...approvedItemNodeId,
                    id: uuid(),
                },
                name,
                description,
                elementIds: [approvedItemNodeId.id],
                stages: approvalTemplate?.stages,
            };
        };
    };
}
