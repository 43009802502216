import { GridLayout } from '@/serverapi/api';
import GridModelGraph from '../GridModelGraph';
import EPCGrid from './grid/EPCGrid.class';
import { defaultEPCGridLayout } from './grid/EPCGridInitial.config';

class EPCModelGraph extends GridModelGraph {
    createGrid(gridLayout: GridLayout = defaultEPCGridLayout, name) {
        this.grid = new EPCGrid(this, gridLayout, name);
    }
}

export default EPCModelGraph;

