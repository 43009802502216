import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'MessagesContentDialog.cancel',
        defaultMessage: 'Закрыть',
    },
    notificationsTemplate: {
        id: 'MessagesContentDialog.notificationsTemplate',
        defaultMessage: 'Шаблон уведомлений',
    },
});
