import { TOpenKanbanCardEditorTabAction } from '@/actions/methodologySetting.actions.types';
import { showNotificationByType } from '@/actions/notification.actions';
import { workspaceAddTab, workspaceRemoveTabByNodeId } from '@/actions/tabs.actions';
import { addStoreEditKanbanCardTypeWorkspaceTab } from '@/actions/workspaceTab/editKanbanCardWorkspaceTab.actions';
import { OPEN_KANBAN_CARD_EDITOR_TAB } from '@/actionsTypes/methodologySetting.actionTypes';
import { SUBMIT_KANBAN_CARD_REQUEST } from '@/actionsTypes/symbol/symbolEditor.actionTypes';
import { EditorMode } from '@/models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { KanbanHorizontalAlign, KanbanSizeType, KanbanVerticalAlign } from '@/models/kanban.types';
import { NotificationType } from '@/models/notificationType';
import messages from '../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import { IWorkspaceTabItemOpenKanbanCardEditorParams, TWorkspaceTab } from '@/models/tab.types';
import { TPresetId } from '@/modules/AdminTools/Methodology/components/Presets/TPresetId.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { ObjectTypeSelectors } from '@/selectors/objectType.selectors';
import { KanbanCardType, ObjectType } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { TSubmitKanbanCardRequestAction } from '@/actions/symbol/symbolEditor.actions.types';
import { KanbanDaoService } from '@/services/dao/KanbanDaoService';
import { addKanbanCardType } from '@/actions/presetSettings/presetSettingsKanbanCardType.actions';

function* handleEditKanbanCard(action: TOpenKanbanCardEditorTabAction) {
    const { serverNode, preset, objectTypeId } = action.payload;
    let { kanbanCardType } = action.payload;
    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);

    const objectType: ObjectType | undefined = yield select(
        ObjectTypeSelectors.byId({ objectTypeId, presetId: preset.id, serverId: serverNode.nodeId.serverId }),
    );

    if (!objectType) {
        yield put(showNotificationByType(NotificationType.OBJECT_TO_EDIT_NOT_FOUND));

        return;
    }

    const isNewKanbanCardType: boolean = !kanbanCardType;
    if (isNewKanbanCardType) {
        kanbanCardType = {
            id: uuid(),
            presetId: preset.id,
            multilingualName: {},
            multilingualDescription: {},
            objectTypeId,
            gridSettings: {
                columns: [{ name: 'A', type: KanbanSizeType.ABSOLUTE, value: 30 }],
                rows: [{ name: '1', type: KanbanSizeType.ABSOLUTE, value: 30 }],
            },
            sectorSettings: [
                {
                    name: 'A1',
                    position: {
                        start: {
                            column: 1,
                            row: 1,
                        },
                        end: {
                            column: 1,
                            row: 1,
                        },
                    },
                    styles: {
                        verticalAlign: KanbanVerticalAlign.TOP,
                        horizontalAlign: KanbanHorizontalAlign.LEFT,
                        padding: {
                            top: 5,
                            right: 5,
                            bottom: 5,
                            left: 5,
                        },
                        backgroundColor: '#f5f5f5',
                        border: {
                            top: true,
                            right: true,
                            bottom: true,
                            left: true,
                            color: '#b3b3b3',
                            width: 2,
                        },
                    },
                },
            ],
            sectorAttributes: [],
        };
    }

    yield put(
        addStoreEditKanbanCardTypeWorkspaceTab({
            presetId: preset.id,
            kanbanCardType: kanbanCardType as KanbanCardType,
        }),
    );

    const breadCrumbsSymbolName: string = isNewKanbanCardType
        ? intl.formatMessage(messages.cardForKanbanBoard)
        : kanbanCardType?.multilingualName[currentLocale];

    const contentLoadingPageTab: TWorkspaceTab & TPresetId = {
        title: `${intl.formatMessage(messages.typeSymbol)}: ${breadCrumbsSymbolName}`,
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${kanbanCardType!.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_KANBAN_CARD_TYPE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            kanbanCardTypeId: kanbanCardType?.id,
            serverNode,
            preset,
            isNewKanbanCardType,
            breadCrumbsSymbolName,
            objectTypeName: objectType.name,
        } as IWorkspaceTabItemOpenKanbanCardEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* handleSubmitKanbanCardRequest(action: TSubmitKanbanCardRequestAction) {
    const { serverId, presetId, kanbanCardType, needTabClose, tabNodeId, isNewKanbanCardType } = action.payload;

    if (isNewKanbanCardType) {
        yield KanbanDaoService.createKanbanCardType(serverId, kanbanCardType);
    } else {
        yield KanbanDaoService.saveKanbanCardType(serverId, kanbanCardType);
    }

    if (needTabClose && tabNodeId) {
        yield put(workspaceRemoveTabByNodeId(tabNodeId));
    }

    yield put(addKanbanCardType({ presetId, kanbanCardType }));
}

export function* kanbanMethodologySettingSaga() {
    yield takeEvery(OPEN_KANBAN_CARD_EDITOR_TAB, handleEditKanbanCard);
    yield takeEvery(SUBMIT_KANBAN_CARD_REQUEST, handleSubmitKanbanCardRequest);
}
