import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { getSelectedBlock } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import icListIndentDescrease from 'icons/toolbar/controls/ic-indent-decrease.svg';
import icListIndentIncrease from 'icons/toolbar/controls/ic-indent-increase.svg';
import { ToolbarButton, ToolbarButtonGroup, ToolbarPopoverButton } from 'UIKit';
import icArrowDown from 'icons/toolbar/controls/ic-arrow-down.svg';
import { Button } from 'antd';
import { setBlockIndent, removeIndent } from '../../common/indentBlocks.utils';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../RichTextEditorToolbar.scss';

const ButtonGroup = Button.Group;
const getFocusedBlock = (editorState) => getSelectedBlock(editorState).get('type');

const getChangedBlock = (editorState, value) => {
    if (value === 'indent') {
        return setBlockIndent(editorState);
    }

    return removeIndent(editorState);
};

const extraActionsDefinitions = {
    indent: {
        action: 'indent',
        tooltip: messages.indent,
        icon: icListIndentIncrease,
    },
    outdent: {
        action: 'outdent',
        tooltip: messages.outdent,
        icon: icListIndentDescrease,
    },
};

const IndentComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, string>({ stateObserver, getFocusedBlock, getChangedBlock });
    const [activeExtraDefinition, setActiveExtraDefinition] = useState(extraActionsDefinitions.indent);
    const onChange = (activeActionId) => () => {
        setCurrentState(activeActionId);
        setActiveExtraDefinition(extraActionsDefinitions[activeActionId]);
    };
    const setExtraInlineStyle = () => {
        setCurrentState(activeExtraDefinition.action);
    };

    const indentButtons = (
        <ToolbarButtonGroup>
            <ToolbarButton
                tooltip={intl.formatMessage(messages.indent)}
                spriteSymbol={icListIndentIncrease}
                onClick={onChange('indent')}
                dataTest="wiki-toolbar-group_font-indent-button_increase"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.outdent)}
                spriteSymbol={icListIndentDescrease}
                onClick={onChange('outdent')}
                dataTest="wiki-toolbar-group_font-indent-button_descrease"
            />
        </ToolbarButtonGroup>
    );

    return (
        <ButtonGroup className={theme.buttonGroup} data-test="wiki-toolbar-group_font-indent-button">
            <ToolbarButton
                className={theme.indicator}
                tooltip={intl.formatMessage(activeExtraDefinition.tooltip)}
                onClick={setExtraInlineStyle}
                spriteSymbol={activeExtraDefinition.icon}
            />
            <ToolbarPopoverButton
                className={theme.arrow}
                tooltip={intl.formatMessage(messages.textIndentation)}
                spriteSymbol={icArrowDown}
                dataTest="wiki-toolbar-group_font-indent-button_popover"
            >
                {indentButtons}
            </ToolbarPopoverButton>
        </ButtonGroup>
    );
};

export default IndentComponent;
