import { PersonalProfile } from '../../serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';

export class PersonalDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getPersonalProfile(): Promise<PersonalProfile> {
        const api = this.getApi();
        const personalProfile = await api.personal.getPersonalProfile();

        return personalProfile;
    }
}
