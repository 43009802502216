import React, { FC, useState } from 'react';
import theme from './ApprovalsTab.scss';
import messages from './ApprovalsTab.messages';
import icApprovalVotePositive from '../../../../resources/icons/ic-approval-vote-positive.svg';
import icApprovalVoteNegative from '../../../../resources/icons/ic-approval-vote-negative.svg';
import icRemoveApprovalUser from '../../../../resources/icons/ic-remove-approval-user.svg';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Icon } from '@/modules/UIKit';
import {
    ApprovalDTOStatus,
    ApprovalStageDTOStatus,
    ApprovalStageDTOType,
    ApprovalUserDTOVote,
} from '@/modules/ApprovalDialog/ApprovalDialog.types';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from '@/selectors/authorization.selectors';
import {
    ApprovalAssistantDTO,
    ApprovalAssistantsDTO,
    ApprovalDTOStatusEnum,
    ApprovalStageDTO,
    ApprovalUserDTO,
    ApprovalUserDTOVoteEnum,
    NodeId,
    PrincipalDescriptor,
} from '@/serverapi/api';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import { ApprovalBllService } from '@/services/bll/ApprovalBLLService';
import { Input } from 'antd';
import { ApprovalStageUserStatus } from './ApprovalStageUserStatus.component';
import { changeApprovalAssistantsList, commentApproval, voteApproval } from '@/actions/approval.actions';
import { notification } from 'antd';
import { MEDIUM_NOTIFICATION_DURATION } from '@/models/notificationType';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';

type TApprovalStageContent = {
    stage: ApprovalStageDTO;
    approvalStatus: ApprovalDTOStatusEnum;
    approvalId: NodeId;
};

export const ApprovalStageContent: FC<TApprovalStageContent> = ({ stage, approvalStatus, approvalId }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentUserId: number | undefined = useSelector(getUserId);
    const allUsersInSystem: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getUsers);

    const [approvalUserIdToCommentMap, setApprovalUserIdToCommentMap] = useState<{
        [key: number]: string | undefined;
    }>(() => {
        return (
            stage.approvalUsersDTO?.reduce((result, currentValue) => {
                result[currentValue.principalId] = currentValue.comment;
                return result;
            }, {}) || {}
        );
    });

    const onChangeComment = (principalId: number, comment: string) => {
        setApprovalUserIdToCommentMap({ ...approvalUserIdToCommentMap, [principalId]: comment });
    };

    const onSubmitComment = (principalId: number) => {
        dispatch(
            commentApproval({
                comment: {
                    comment: approvalUserIdToCommentMap[principalId]!,
                    approvalId,
                    stageId: stage.id,
                    principalId,
                },
            }),
        );
    };

    const onChangeVote = (vote: ApprovalUserDTOVoteEnum, principalId: number) => {
        const userDTO: ApprovalUserDTO | undefined = stage.approvalUsersDTO?.find(
            (user) => user.principalId === principalId,
        );
        const comment: string | undefined = approvalUserIdToCommentMap[principalId];

        if (vote === 'NOT_APPROVED') {
            if (!userDTO || (!userDTO.comment && !comment)) {
                return notification.error({
                    message: intl.formatMessage(messages.commentRequired),
                    description: intl.formatMessage(messages.commentRequiredDescription),
                    duration: MEDIUM_NOTIFICATION_DURATION,
                });
            }
        }

        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    let currentComment: string | undefined;
                    if (comment && comment !== '' && comment !== userDTO?.comment) {
                        currentComment = comment;
                    }
                    dispatch(
                        voteApproval({
                            vote: { vote, stageId: stage.id, approvalId, principalId },
                            comment: currentComment,
                        }),
                    );
                },
                title: intl.formatMessage(messages.voting),
                question: intl.formatMessage(messages.votingQuestion),
                OKButtonText: intl.formatMessage(messages.confirm),
            }),
        );
    };

    const onDeleteAssistant = (approvalUserId: number, assistantId: number) => {
        const approvalUserAssistants: ApprovalAssistantDTO[] | undefined = stage.approvalUsersDTO?.find(
            (approvalUser) => approvalUser.principalId === approvalUserId,
        )?.assistants;

        const approvalAssistantsDTO: ApprovalAssistantsDTO = {
            approvalId,
            stageId: stage.id,
            principalId: approvalUserId,
            assistantIds:
                approvalUserAssistants
                    ?.filter((assistant) => assistant.assistantId !== assistantId)
                    .map((assistant) => assistant.assistantId) || [],
        };

        dispatch(changeApprovalAssistantsList(approvalAssistantsDTO));
    };

    const onAddAssistant = (approvalUserId: number, assistantId: number) => {
        const approvalUserAssistants: ApprovalAssistantDTO[] | undefined = stage.approvalUsersDTO?.find(
            (approvalUser) => approvalUser.principalId === approvalUserId,
        )?.assistants;

        const newApprovalAssistantsIds = approvalUserAssistants?.map((assistant) => assistant.assistantId) || [];
        newApprovalAssistantsIds.push(assistantId);

        const approvalAssistantsDTO: ApprovalAssistantsDTO = {
            approvalId,
            stageId: stage.id,
            principalId: approvalUserId,
            assistantIds: newApprovalAssistantsIds,
        };

        dispatch(changeApprovalAssistantsList(approvalAssistantsDTO));
    };

    return (
        <>
            {stage.approvalUsersDTO
                ? stage.approvalUsersDTO.map((user, index, approvalUsers) => {
                      const isUserDeleted: boolean = !allUsersInSystem?.find((usr) => usr.id === user.principalId);
                      const isAssistantUserDeleted: boolean = !allUsersInSystem?.some((usr) =>
                          user?.assistants?.some((ass) => ass.assistantId === usr.id),
                      );
                      const isAssistant = !!user.assistants?.find(
                          (assistant) => assistant.assistantId === currentUserId,
                      );
                      const isApprovalUser = currentUserId === user.principalId;

                      if (
                          (isApprovalUser || isAssistant) &&
                          approvalStatus === ApprovalDTOStatus.IN_PROCESS &&
                          stage.status === ApprovalStageDTOStatus.IN_PROCESS
                      ) {
                          const isUserFirstInList: boolean = index === 0;
                          const isUserLastInList: boolean = index === approvalUsers.length - 1;
                          const voteOfPreviousUser: ApprovalUserDTOVoteEnum | undefined = isUserFirstInList
                              ? undefined
                              : approvalUsers[index - 1]?.vote;
                          const isNextUserVoted: boolean = !isUserLastInList || !!approvalUsers[index + 1]?.vote;
                          const isSuccessiveTypeAndNextUserVoted: boolean =
                              stage.type === ApprovalStageDTOType.SUCCESSIVE && !!approvalUsers[index + 1]?.vote;

                          const canVote = ApprovalBllService.canUserVote(
                              !!user.vote,
                              stage.type!,
                              isUserFirstInList,
                              voteOfPreviousUser,
                          );

                          const canChangeVote: boolean = ApprovalBllService.canUserChangeVote(
                              !!user.vote,
                              !!stage.changeVoteAllowed,
                              stage.type!,
                              isNextUserVoted,
                          );

                          const canChangeOrAddComment = user.comment !== undefined || !!user.vote || canVote;
                          const currentApprovalUserComment = approvalUserIdToCommentMap[user.principalId];

                          return (
                              <div key={user.principalId} className={theme.stageUserContainer}>
                                  <div className={theme.stageUserInfo}>
                                      <div className={theme.userTitle}>{`${user.name} (@${user.login})`}</div>
                                      <ApprovalStageUserStatus
                                          stageStatus={stage.status}
                                          userVote={user.vote}
                                          userVotedAt={user.votedAt}
                                          principleId={user.principalId}
                                          approvalUsers={stage.approvalUsersDTO}
                                          addAssistant={onAddAssistant}
                                      />
                                  </div>
                                  {user.assistants
                                      ? user.assistants?.map((assistant) => {
                                            return (
                                                <div className={theme.assistant}>
                                                    <div
                                                        className={classNames(theme.assistantTitle, {
                                                            [theme.deletedUser]: isAssistantUserDeleted,
                                                        })}
                                                    >{`${assistant.assistantName} (@${assistant.assistantLogin})`}</div>
                                                    <Button
                                                        visualStyle={{ type: 'text' }}
                                                        onClick={() =>
                                                            onDeleteAssistant(user.principalId, assistant.assistantId)
                                                        }
                                                        icon={icRemoveApprovalUser}
                                                    />
                                                </div>
                                            );
                                        })
                                      : null}
                                  {canChangeOrAddComment ? (
                                      <div className={theme.flexColumn}>
                                          <Input.TextArea
                                              placeholder={intl.formatMessage(messages.typeComment)}
                                              defaultValue={currentApprovalUserComment}
                                              value={currentApprovalUserComment}
                                              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                  onChangeComment(user.principalId, e.target.value);
                                              }}
                                          />
                                          <div className={theme.sendButtonWrapper}>
                                              <Button
                                                  disabled={
                                                      user.comment === currentApprovalUserComment ||
                                                      currentApprovalUserComment === ''
                                                  }
                                                  visualStyle="primary"
                                                  size="small"
                                                  onClick={() => onSubmitComment(user.principalId)}
                                              >
                                                  {intl.formatMessage(messages.send)}
                                              </Button>
                                          </div>
                                      </div>
                                  ) : null}

                                  {(canVote || canChangeVote) && !isSuccessiveTypeAndNextUserVoted ? (
                                      <div className={theme.actionsContainer} data-test="actions-container">
                                          <div
                                              className={classNames(theme.voteAction, {
                                                  [theme.positiveActionActive]:
                                                      user?.vote === ApprovalUserDTOVote.APPROVED,
                                              })}
                                              onClick={() =>
                                                  onChangeVote(ApprovalUserDTOVote.APPROVED, user.principalId)
                                              }
                                          >
                                              <Icon spriteSymbol={icApprovalVotePositive} />
                                              <span>{intl.formatMessage(messages.approved)}</span>
                                          </div>

                                          <div
                                              className={classNames(theme.voteAction, {
                                                  [theme.negativeActionActive]:
                                                      user?.vote === ApprovalUserDTOVote.NOT_APPROVED,
                                              })}
                                              onClick={() =>
                                                  onChangeVote(ApprovalUserDTOVote.NOT_APPROVED, user.principalId)
                                              }
                                          >
                                              <Icon spriteSymbol={icApprovalVoteNegative} />
                                              <span>{intl.formatMessage(messages.notApproved)}</span>
                                          </div>
                                      </div>
                                  ) : null}
                              </div>
                          );
                      }

                      return (
                          <div key={user.principalId} className={theme.stageUserContainer}>
                              <div className={theme.stageUserInfo}>
                                  <div
                                      className={classNames(theme.userTitle, {
                                          [theme.deletedUser]: isUserDeleted,
                                      })}
                                  >{`${user.name} (@${user.login})`}</div>
                                  <ApprovalStageUserStatus
                                      stageStatus={stage.status}
                                      userVote={user.vote}
                                      userVotedAt={user.votedAt}
                                      principleId={user.principalId}
                                      approvalUsers={stage.approvalUsersDTO}
                                      addAssistant={onAddAssistant}
                                  />
                              </div>
                              {user.assistants
                                  ? user.assistants?.map((assistant) => {
                                        return (
                                            <div className={theme.assistant}>
                                                <div
                                                    className={classNames(theme.userTitle, {
                                                        [theme.deletedUser]: isAssistantUserDeleted,
                                                    })}
                                                >{`${assistant.assistantName} (@${assistant.assistantLogin})`}</div>
                                                {stage.status !== ApprovalDTOStatus.APPROVED &&
                                                stage.status !== ApprovalDTOStatus.NOT_APPROVED ? (
                                                    <Button
                                                        visualStyle={{ type: 'text' }}
                                                        onClick={() =>
                                                            onDeleteAssistant(user.principalId, assistant.assistantId)
                                                        }
                                                        icon={icRemoveApprovalUser}
                                                    />
                                                ) : null}
                                            </div>
                                        );
                                    })
                                  : null}
                              {user.comment ? <div className={theme.stageUserComment}>{user.comment}</div> : null}
                          </div>
                      );
                  })
                : null}
        </>
    );
};
