import type { TImageRenderProps } from './bpmgraph.types';
import { MxStencil } from './mxgraph';

export class BPMMxStencil {
    /**
     * Нужен для того что бы BPMMxStencil подтянулся и переопределил drawNode
     */
    static init(): void {}

    static override(): void {
        MxStencil.prototype.doDrawNode = MxStencil.prototype.drawNode;
        MxStencil.prototype.drawNode = BPMMxStencil.drawNode;
    }

    static drawNode(canvas, shape, node, aspect, disableShadow, paint): void {
        const name = node.nodeName;
        if (name === 'image') {
            canvas.imageRenderProps = {
                align: node.getAttribute('align'),
                h: node.getAttribute('h'),
                w: node.getAttribute('w'),
            } as TImageRenderProps;
        }
        MxStencil.prototype.doDrawNode(canvas, shape, node, aspect, disableShadow, paint);
        const style = shape.state?.style;

        if (style && style.fillColor && style.fillColor !== 'none' && node?.nodeName === 'fillcolor') {
            canvas?.setFillColor(style.fillColor);
        }
    }
}

BPMMxStencil.override();
