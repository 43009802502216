import { TWorkspaceTab } from '@/models/tab.types';
import { TFavoritesState } from '@/reducers/favorites.reducer.types';
import type { TRootState } from '@/reducers/root.reducer.types';
import { FavoriteNodeDTO, NodeId } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { getMenuItem } from '@/utils/antdMenuItem.utils';
import { compareNodeIds } from '@/utils/nodeId.utils';
import messages from '../modules/Workspace/messages/WorkspaceTabTitleContextMenu.messages';
import { createSelector } from 'reselect';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { IntlShape } from 'react-intl';

export namespace FavoritesSelectors {
    export const getState = (state: TRootState) => state.favorites;
    export const nodes = createSelector<TRootState, TFavoritesState, FavoriteNodeDTO[]>(getState, (favorites) =>
        favorites.nodes.sort((a, b) => {
            if (a.addedAt && b.addedAt) {
                return b.addedAt - a.addedAt;
            }

            return 0;
        }),
    );
    export const isFavorite = (nodeId: NodeId) =>
        createSelector<TRootState, TFavoritesState, boolean>(getState, (favorites) =>
            favorites.nodes.some((node) => compareNodeIds(node.nodeId, nodeId)),
        );
    export const getFavouriteTabStatus = (tab: TWorkspaceTab) =>
        createSelector<TRootState, TFavoritesState, ItemType>(getState, (favorites) => {
            const intl: IntlShape = LocalesService.useIntl();
            const addableToFavouriteTabTypes: Set<WorkSpaceTabTypes> = new Set([
                WorkSpaceTabTypes.EDITOR,
                WorkSpaceTabTypes.WIKI_EDITOR,
                WorkSpaceTabTypes.DASHBOARD,
                WorkSpaceTabTypes.SPREADSHEET,
                WorkSpaceTabTypes.SIMULATION_MODELING,
            ]);
            const favorite: boolean = !!favorites?.nodes?.find((node) => compareNodeIds(node.nodeId, tab.nodeId));
            const favouriteMenuItemStatus: ItemType = favorite
                ? getMenuItem(`${intl.formatMessage(messages.deleteFromFavourite)}`, 'deleteFromFavourite')
                : getMenuItem(`${intl.formatMessage(messages.addToFavourite)}`, 'addToFavourite');

            return addableToFavouriteTabTypes.has(tab.type) ? favouriteMenuItemStatus : null;
        });
}
