import { AttributeTypeSelectPropertyValue, FolderType } from '../serverapi/api';
import { IntlShape } from 'react-intl';
import { Locale } from '../modules/Header/components/Header/header.types';
import messages from './accessible-properties.messages';
import { LocalesService } from '../services/LocalesService';
import { getIsDefaultFolder } from '../services/bll/FolderTypeBLLService';

export const folderTypeNames = (
    folderTypes: FolderType[],
    locale: Locale,
    intl: IntlShape,
    shouldAddDefaultFolderType: boolean = true,
): AttributeTypeSelectPropertyValue[] => {
    const folderNames = folderTypes.map((ft) => ({
        id: ft.id,
        value: ft.multilingualName,
    }));

    const isMissingDefaultFolder = !folderTypes.some((ft) => getIsDefaultFolder(ft.id));

    if (isMissingDefaultFolder && shouldAddDefaultFolderType) {
        folderNames.unshift({
            id: 'default',
            value: LocalesService.changeLocaleValue({}, locale, intl.formatMessage(messages.defaultFolderType)),
        });
    }

    return folderNames;
};
