import { ModelSvgImage } from '../sagas/types/imageSaga.types';
import { NodeId } from '../serverapi/api';
import { CREATE_PRINT_MODEL_IMAGES, SAVE_DATA_AND_SELECTED_DATA } from '../actionsTypes/printModel.actionTypes';
import { TCreatePrintModelImagesAction, TSavePrintModelDatasAction } from './printModel.actions.types';

export const savePrintModelImages = (
    data: ModelSvgImage | undefined,
    selectedData: ModelSvgImage | undefined,
): TSavePrintModelDatasAction => ({
    type: SAVE_DATA_AND_SELECTED_DATA,
    payload: {
        data,
        selectedData,
    },
});

export const createPrintModelImages = (nodeId: NodeId, hideDecompositions: boolean): TCreatePrintModelImagesAction => ({
    type: CREATE_PRINT_MODEL_IMAGES,
    payload: {
        nodeId,
        hideDecompositions
    },
});
