import { StyledAttributeType } from '../models/bpm/bpm-model-impl';
import { Locale } from '../modules/Header/components/Header/header.types';
import { AttributeType, AttributeTypeStyle } from '../serverapi/api';
import { LocalesService } from '../services/LocalesService';
import { UNKNOWN_ATTRIBUTE_TYPE } from './consts';
import messages from './messages/attribute.messages';

export const getUnknownAttributeType = (locale: Locale): AttributeType => {
    const name = LocalesService.useIntl(locale).formatMessage(messages.unknownAttribute);

    return {
        ...UNKNOWN_ATTRIBUTE_TYPE,
        name,
        multilingualName: LocalesService.changeLocaleValue(undefined, locale, name),
    };
};

export const getStylesForSelectedArea = (
    selectedAreaKey: string,
    attributeTypes: StyledAttributeType[] | undefined,
): AttributeTypeStyle[] => {
    if (!attributeTypes?.length) return [];

    const [v, h] = selectedAreaKey.split('_');

    const attributeTypesWithStyles = attributeTypes.filter((at) => at.styles);

    const allStyles: AttributeTypeStyle[] = attributeTypesWithStyles
        .map((at) => at.styles)
        .flat() as AttributeTypeStyle[];

    const selectedAreaStyles = allStyles.filter((style) => style.h === h && style.v === v);

    return selectedAreaStyles;
};
