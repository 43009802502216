import React, { useContext, useState } from 'react';
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import ControlsContext from '../Controls.context';
import { DEFAULT_FONT_COLOR } from './Controls.constants';
import { ToolbarPopoverButton } from 'UIKit';
import icFontColor from 'icons/toolbar/controls/ic-font-color.svg';
import { Button } from 'antd';
import { useSharedState } from '../UseSharedState.hook';
import { ColorResult, SketchPicker } from 'react-color';
import theme from '../RichTextEditorToolbar.scss';

const ButtonGroup = Button.Group;
const getFocusedBlock = (editorState) => {
    const fontColor = getSelectionCustomInlineStyle(editorState, ['COLOR'])?.COLOR;

    if (fontColor) {
        return fontColor.replace('color-', '');
    }

    return DEFAULT_FONT_COLOR;
};

const getChangedBlock = (editorState, value) => toggleCustomInlineStyle(editorState, 'color', value);

const FontColorComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const [currentColor, setCurrentColor] = useState<string>(DEFAULT_FONT_COLOR);
    const selectColorHandler = (colorObject: ColorResult) => {
        const { hex } = colorObject;
        setCurrentColor(hex);
        setCurrentState(hex);
    };

    return (
        <ButtonGroup className={theme.buttonGroup} data-test="wiki-toolbar-group_font-color-button">
            <ToolbarPopoverButton
                className={theme.indicator}
                tooltip={intl.formatMessage(messages.fontColor)}
                color={value}
                spriteSymbol={icFontColor}
            >
                <SketchPicker color={currentColor} onChange={selectColorHandler} disableAlpha/>
            </ToolbarPopoverButton>
        </ButtonGroup>
    );
};

export default FontColorComponent;
