import * as getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';
import { ContentBlock, EditorState, Modifier } from 'draft-js';

export const actionCut = (editorState) => {
    const selection = editorState.getSelection();
    const contentState = Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward');

    return EditorState.push(editorState, contentState, 'backspace-character');
};

export const actionPaste = (editorState, clipboardText) => {
    const selection = editorState.getSelection();
    const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        selection,
        clipboardText,
        editorState.getCurrentInlineStyle(),
    );

    return EditorState.push(editorState, contentState, 'insert-characters');
};

export const actionDelete = (editorState) => {
    const selection = editorState.getSelection();
    if (!selection.getFocusKey() || selection.getFocusKey() === '') {
        return editorState;
    }
    if (selection.isCollapsed()) {
        return editorState;
    }
    const contentState = Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward');

    return EditorState.push(editorState, contentState, 'backspace-character');
};

export const getSelectedText = (editorState) => {
    const fragment = getFragmentFromSelection(editorState);
    if (!fragment) {
        return '';
    }

    return fragment.map((block: ContentBlock) => block.getText()).join(' ');
};
