import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import menuItemTheme from '../items/MenuItem.scss';
import { useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { ComplexSymbolManager } from '../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { instancesBPMMxGraphMap } from '../../../../mxgraph/bpm-mxgraph-instance-map';
import { isSystemStyle } from '../../../../services/SymbolsService';
import { isCompact } from '../../../../selectors/generalMenu.selectors';
import { TreeSelectors } from '../../../../selectors/tree.selectors';
import { ToolbarButtonsBllService } from '../../../../services/bll/ToolbarButtonsBllService';
import { getSelectedCellIds } from '../../../../selectors/editor.selectors';
import { NotationHelper } from '../../../../services/utils/NotationHelper';
import { GraphObjectToolbarEdgeSettings } from './GraphObjectToolbarEdgeSettings.component';
import { GraphObjectToolbarTextAlignSettings } from './GraphObjectToolbarTextAlignSettings.component';
import { GraphObjectToolbarTextStyleSettings } from './GraphObjectToolbarTextStyleSettings.component';
import { GraphObjectToolbarFormatSettings } from './GraphObjectToolbarFormatSettings.component';
import { GraphObjectToolbarColorSettings } from './GraphObjectToolbarColorSettings.component';
import { GraphObjectToolbarFontSettings } from './GraphObjectToolbarFontSettings.component';
import { GraphGeneralToolbarTextDirectionSettings } from './GraphObjectToolbarTextDirectionSettings.component';
import { MxCell } from '../../../../mxgraph/mxgraph';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';

const filterSelectedCells = (selectedCells: MxCell[]) =>
    selectedCells.filter((cell) => !isSystemStyle(cell.style) && ComplexSymbolManager.isCellStyleEditable(cell));

export const GraphObjectToolbar: FC = () => {
    const compact = useSelector(isCompact);
    const nodeId = useSelector(TabsSelectors.getActiveTabId);
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const selectedCellsIds = useSelector(getSelectedCellIds);

    const graph: BPMMxGraph | undefined = useMemo(() => instancesBPMMxGraphMap.get(nodeId), [nodeId]);

    const rawSelectedCells = useMemo(() => {
        if (!graph) return [];

        return ToolbarButtonsBllService.getCellsWorkspace(
            selectedCellsIds.map((cellId) => graph.getModel().getCell(cellId)).filter(Boolean),
        );
    }, [selectedCellsIds, graph]);

    const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
    const isEntityEditable: boolean = NotationHelper.isEntityEditable(nodeId, presetId, selectedCells);
    const countSelectedImages: number = selectedCells.filter((cell) => cell.getValue().imageId).length;
    const hasSelectedElement: boolean = !!selectedCells.length;
    const hasSelectedOnlyImages: boolean = selectedCells.length === countSelectedImages;
    const objectEditDisabled: boolean = !isEntityEditable || !hasSelectedElement || hasSelectedOnlyImages;
    const selectedEdges: MxCell[] = selectedCells.filter((cell) => cell.isEdge());

    return (
        <div className={cx(menuItemTheme.container, compact && menuItemTheme.container_compact)}>
            <GraphObjectToolbarFontSettings isShapeWithPicture={hasSelectedOnlyImages} disabled={!isEntityEditable} />
            <div className={menuItemTheme.editGroup}>
                <GraphObjectToolbarColorSettings
                    compact={compact}
                    disabled={objectEditDisabled}
                    selectedCells={selectedCells}
                />
                <GraphObjectToolbarFormatSettings
                    nodeId={nodeId}
                    presetId={presetId}
                    compact={compact}
                    hasSelectedOnlyImages={hasSelectedOnlyImages}
                    rawSelectedCells={rawSelectedCells}
                    selectedCells={selectedCells}
                    isEntityEditable={isEntityEditable}
                    modelTypeId={graph?.modelType?.id}
                />
            </div>
            <div className={menuItemTheme.divider} />
            <div className={menuItemTheme.editGroup}>
                <GraphObjectToolbarTextStyleSettings disabled={objectEditDisabled} compact={compact} />
                <GraphObjectToolbarTextAlignSettings disabled={objectEditDisabled} />
            </div>
            <div className={menuItemTheme.divider} />
            <GraphGeneralToolbarTextDirectionSettings disabled={objectEditDisabled} compact={compact} />
            <div className={menuItemTheme.divider} />
            <GraphObjectToolbarEdgeSettings
                compact={compact}
                selectedEdges={selectedEdges}
                isEntityEditable={isEntityEditable}
            />
        </div>
    );
};
