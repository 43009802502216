import React, { FC, memo } from 'react';

export const EditCommentTooltipIcon: FC = memo(() => (
    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 2C0 2.82843 0.671573 3.5 1.5 3.5C2.32843 3.5 3 2.82843 3 2C3 1.17157 2.32843 0.5 1.5 0.5C0.671573 0.5 0 1.17157 0 2Z"
            fill="#8F8F8F"
        />
        <path
            d="M5.5 2C5.5 2.82843 6.17157 3.5 7 3.5C7.82843 3.5 8.5 2.82843 8.5 2C8.5 1.17157 7.82843 0.5 7 0.5C6.17157 0.5 5.5 1.17157 5.5 2Z"
            fill="#8F8F8F"
        />
        <path
            d="M12.5 3.5C11.6716 3.5 11 2.82843 11 2C11 1.17157 11.6716 0.5 12.5 0.5C13.3284 0.5 14 1.17157 14 2C14 2.82843 13.3284 3.5 12.5 3.5Z"
            fill="#8F8F8F"
        />
    </svg>
));
