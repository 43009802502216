import {
    PRESET_SETTINGS_GET_ALL_KANBAN_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_CREATE_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_SUBMIT_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_ADD_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_KANBAN_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsKanbanModelTypes.actionTypes';
import {
    TAddKanbanModelTypeAction,
    TAddKanbanModelTypePayload,
    TCreateKanbanModelTypeAction,
    TCreateKanbanModelTypePayload,
    TDeleteKanbanModelTypeAction,
    TDeleteKanbanModelTypePayload,
    TEditKanbanModelTypeAction,
    TEditKanbanModelTypePayload,
    TGetAllKanbanModelTypesSuccessAction,
    TGetAllKanbanModelTypesSuccessPayload,
    TSubmitKanbanModelTypeAction,
    TSubmitKanbanModelTypePayload,
    TTransferKanbanModelTypeAction,
    TTransferKanbanModelTypePayload,
    TUpdateSymbolsAddedToKanbanModelTypeAction,
    TUpdateSymbolsAddedToKanbanModelTypePayload,
} from './presetSettingsKanbanModelType.actions.types';

export const createKanbanModelType = (payload: TCreateKanbanModelTypePayload): TCreateKanbanModelTypeAction => ({
    type: PRESET_SETTINGS_CREATE_KANBAN_MODEL_TYPE,
    payload,
});

export const submitKanbanModelType = (payload: TSubmitKanbanModelTypePayload): TSubmitKanbanModelTypeAction => ({
    type: PRESET_SETTINGS_SUBMIT_KANBAN_MODEL_TYPE,
    payload,
});

export const getAllKanbanModelTypesSuccess = (
    payload: TGetAllKanbanModelTypesSuccessPayload,
): TGetAllKanbanModelTypesSuccessAction => ({
    type: PRESET_SETTINGS_GET_ALL_KANBAN_MODEL_TYPES_SUCCESS,
    payload,
});

export const addKanbanModelType = (payload: TAddKanbanModelTypePayload): TAddKanbanModelTypeAction => ({
    type: PRESET_SETTINGS_ADD_KANBAN_MODEL_TYPE,
    payload,
});

export const deleteKanbanModelType = (payload: TDeleteKanbanModelTypePayload): TDeleteKanbanModelTypeAction => ({
    type: PRESET_SETTINGS_DELETE_KANBAN_MODEL_TYPE,
    payload,
});

export const editKanbanModelType = (payload: TEditKanbanModelTypePayload): TEditKanbanModelTypeAction => ({
    type: PRESET_SETTINGS_EDIT_KANBAN_MODEL_TYPE,
    payload,
});

export const transferKanbanModelType = (payload: TTransferKanbanModelTypePayload): TTransferKanbanModelTypeAction => ({
    type: PRESET_SETTINGS_TRANSFER_KANBAN_MODEL_TYPE,
    payload,
});

export const updateSymbolsAddedToKanbanModelType = (
    payload: TUpdateSymbolsAddedToKanbanModelTypePayload,
): TUpdateSymbolsAddedToKanbanModelTypeAction => ({
    type: PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_KANBAN_MODEL_TYPE,
    payload,
});
