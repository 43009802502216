import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import theme from './DeleteSeveralNodesDialog.scss';
import messages from './DeleteSeveralNodesDialog.messages';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { useDispatch } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TDeleteSeveralNodesErrorDialogProps } from './DeleteSeveralNodesDialog.types';
import { deleteSeveralNodesFromServer } from '@/actions/tree.actions';

export const DeleteSeveralNodesErrorDialog: FC<TDeleteSeveralNodesErrorDialogProps> = ({
    deleteNodeRequestData,
    name,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const onSubmit = () => {
        dispatch(deleteSeveralNodesFromServer(deleteNodeRequestData));
        onClose();
    };

    const onClose = () => {
        dispatch(closeDialog(DialogType.DELETE_SEVERAL_NODES_ERROR_DIALOG));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'stop',
                    onClick: onClose,
                    value: intl.formatMessage(messages.stopDeliting),
                },
                {
                    key: 'skip',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.skip),
                    visualStyle: 'primary',
                    danger: true,
                },
            ]}
        />
    );

    return (
        <Dialog
            open
            onOk={onSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.errorTitle)}
            footer={footer}
            className={theme.dialog}
        >
            {intl.formatMessage(messages.errorMessage, { name })}
        </Dialog>
    );
};
