import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { objectDecompositionOpen } from '../../../actions/entities/objectDecomposition.actions';
import { NodeId } from '../../../serverapi/api';
import { DecompositionsListDialog } from '../components/DecompositionsList/DecompositionsListDialog.component';
import { TDecompositionsListDialogOwnProps, TDecompositionsListDialogProps } from '../components/DecompositionsList/DecompositionsListDialog.types';
import { getSymbolsByModelId } from '../../../selectors/navigator.selectors';
import { TreeItemType } from '../../Tree/models/tree';

const mapStateToProps = (
    state: TRootState,
    ownProps: TDecompositionsListDialogOwnProps,
): Partial<TDecompositionsListDialogProps> => {
    const { objectDefinition, edgeDefinition, modelId } = ownProps;

    const symbols = getSymbolsByModelId(modelId)(state);
    const symbol = symbols.find((item) => item.id === objectDefinition?.idSymbol);

    return {
        symbol,
        serverId: modelId.serverId,
        repositoryId: modelId.repositoryId,
        modelAssignment: objectDefinition?.modelAssignments || edgeDefinition?.modelAssignments || [],
        name: objectDefinition?.name || edgeDefinition?.name ,
        nodeType: objectDefinition?.type || edgeDefinition?.type
    };
};

const mapDispatchToProps = (dispatch) => ({
    onCancel() {
        dispatch(closeDialog(DialogType.DECOMPOSITIONS_LIST_DIALOG));
    },
    onOpenDetailing(nodeId: NodeId, type: TreeItemType) {
        dispatch(closeDialog(DialogType.DECOMPOSITIONS_LIST_DIALOG));
        dispatch(objectDecompositionOpen({ nodeId, type }));
    },
});

export const DecompositionsListDialogContainer = connect(mapStateToProps, mapDispatchToProps)(DecompositionsListDialog);
