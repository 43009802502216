// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DnDTabs__container__iXnao{height:100%}", "",{"version":3,"sources":["webpack://./src/modules/Workspace/components/Workspace/DnDTabs.scss"],"names":[],"mappings":"AAAA,2BACI,WAAA","sourcesContent":[".container {\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DnDTabs__container__iXnao"
};
export default ___CSS_LOADER_EXPORT___;
