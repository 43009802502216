import { PresetImage, SymbolSettingsTypeEnum } from "../../../../../../../../../serverapi/api";
import { TSymbolsSettings } from "../SymbolGenerator.types";
import { SymbolGeneratorBllService } from "../SymbolGeneratorService/SymbolGeneratorBllService";
import { indentsCalc } from "./indentsCalc";

export const iconChangeParametersHandler = (currentStyles: TSymbolsSettings, symbolsSettings: TSymbolsSettings, symbolType: SymbolSettingsTypeEnum, icon: PresetImage): TSymbolsSettings => {
    return SymbolGeneratorBllService.changeParametersHandler(
        symbolsSettings,
        {
            iconWidth: 25,
            iconHeight: 25,
            svgIcon: icon,
            iconPosition: symbolsSettings[symbolType].iconSettings?.iconPosition || 'right top',
            indentFromTop: symbolsSettings[symbolType].iconSettings?.indentFromTop || indentsCalc(1, currentStyles, symbolType, { iconPosition: 'right top' }),
            indentFromBottom: symbolsSettings[symbolType].iconSettings?.indentFromBottom || indentsCalc(1, currentStyles, symbolType, { iconPosition: 'right top' }),
            indentFromLeft: symbolsSettings[symbolType].iconSettings?.indentFromLeft || indentsCalc(1, currentStyles, symbolType, { iconPosition: 'right top' }),
            indentFromRight: symbolsSettings[symbolType].iconSettings?.indentFromRight || indentsCalc(1, currentStyles, symbolType, { iconPosition: 'right top' }),
        },
        'iconSettings',
        symbolType,
    );
};