export const SAVE_OBJECT_DEFINITION = 'SAVE_OBJECT_DEFINITION';

export const CHECK_OBJECT_DEFINITION = 'CHECK_OBJECT_DEFINITION';

export const SAVE_OBJECT_DEFINITION_SUCCESS = 'SAVE_OBJECT_DEFINITION_SUCCESS';

export const SAVE_OBJECT_DEFINITION_FAIL = 'SAVE_OBJECT_DEFINITION_FAIL';

export const UPDATE_OBJECT_DEFINITION_NAME = 'UPDATE_OBJECT_DEFINITION_NAME';

export const OBJECT_DEFINITIONS_ADD = 'OBJECT_DEFINITIONS_ADD';

export const OBJECT_DEFINITION_MOVE = 'OBJECT_DEFINITION_MOVE';

export const OBJECT_DEFINITION_DELETE = 'OBJECT_DEFINITION_DELETE';

export const MODEL_MOVE = 'MODEL_MOVE';

export const OBJECT_DEFINITION_UML_UPDATE = 'OBJECT_DEFINITION_UML_UPDATE';

export const OBJECT_DEFINITIONS_ADD_ONLY_NEW = 'OBJECT_DEFINITIONS_ADD_ONLY_NEW';
