import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import theme from './DragItem.scss';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

type TDragItem = {
    jsxTab: JSX.Element;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    isActiveTab: boolean;
    isHomePageTab: boolean;
};

const portal: HTMLElement = document.createElement('div');

document.body.appendChild(portal);

export const DragItem = (props: TDragItem) => {
    const { snapshot, provided, jsxTab, isActiveTab, isHomePageTab } = props;
    const { isDragging } = snapshot;
    const getItemStyle = (draggableStyle) => {
        const style = { ...draggableStyle };
        if (style.width) {
            style.width -= 26;
        }
        if (style.transform) {
            const transformArray = style.transform.split(',');
            // заменяем любые перемещения по оси Y на 0, костыль но react-beautiful-dnd по другому не может
            const axisLockY = `${transformArray[0]}, 0px)`; // transformArray[0] = translate(Xpx
            style.transform = axisLockY;
        }

        return style;
    };

    const child = (
        <div
            className={cx(
                isDragging && theme.dragging,
                isActiveTab && theme.activeTab,
                isHomePageTab && theme.homePageTab,
            )}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(provided.draggableProps.style)}
        >
            {jsxTab}
        </div>
    );

    if (!isDragging) {
        return child;
    }

    return ReactDOM.createPortal(child, portal);
};
