import { AttributeValueType, RuleType } from '@/modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import { AttributeType, AttributeTypeValueTypeEnum, SearchRuleQueryRuleEnum } from '@/serverapi/api';

export const SEARCH_RULES = [
    RuleType.HAS_VALUE,
    RuleType.EQUALS,
    RuleType.GREATER,
    RuleType.GREATER_OR_EQUAL,
    RuleType.LESS,
    RuleType.LESS_OR_EQUAL,
    RuleType.CONTAINS,
    RuleType.STARTS_WITH,
    RuleType.ENDS_WITH,
];

export const getQueryRulesByAttributeType = (
    valueType: AttributeTypeValueTypeEnum | undefined,
    isSystemEntityAttributeType: boolean,
): SearchRuleQueryRuleEnum[] => {
    if (!valueType) return [];

    if (isSystemEntityAttributeType) return [RuleType.EQUALS];

    let queryRules: SearchRuleQueryRuleEnum[] = [];

    switch (valueType) {
        case AttributeValueType.INTEGER:
        case AttributeValueType.NUMERIC:
            queryRules = [
                RuleType.HAS_VALUE,
                RuleType.EQUALS,
                RuleType.GREATER,
                RuleType.GREATER_OR_EQUAL,
                RuleType.LESS,
                RuleType.LESS_OR_EQUAL,
            ];
            break;

        case AttributeValueType.STRING:
        case AttributeValueType.MULTI_STRING:
        case AttributeValueType.JSON:
        case AttributeValueType.URL:
            queryRules = [
                RuleType.HAS_VALUE,
                RuleType.EQUALS,
                RuleType.CONTAINS,
                RuleType.STARTS_WITH,
                RuleType.ENDS_WITH,
            ];
            break;

        case AttributeValueType.BOOLEAN:
        case AttributeValueType.NODE:
            queryRules = [RuleType.HAS_VALUE, RuleType.EQUALS];
            break;

        case AttributeValueType.PERIOD:
            queryRules = [RuleType.HAS_VALUE];
            break;

        case AttributeValueType.DATE:
        case AttributeValueType.DATE_TIME:
        case AttributeValueType.TIME:
            queryRules = [
                RuleType.HAS_VALUE,
                RuleType.EQUALS,
                RuleType.GREATER,
                RuleType.GREATER_OR_EQUAL,
                RuleType.LESS,
                RuleType.LESS_OR_EQUAL,
            ];
            break;

        case AttributeValueType.SELECT:
            queryRules = [RuleType.HAS_VALUE, RuleType.EQUALS];
            break;

        case AttributeValueType.MULTI_SELECT:
        case AttributeValueType.PRINCIPAL:
            queryRules = [RuleType.HAS_VALUE, RuleType.EQUALS, RuleType.CONTAINS];
            break;

        default:
            queryRules = [RuleType.HAS_VALUE];
            break;
    }

    return queryRules;
};

export const SYSTEM_ENTITY_ATTRIBUTE_TYPE_IDS = [
    'modelTypeId',
    'objectTypeId',
    'folderTypeId',
    'edgeTypeId',
    'symbolId',
];

export const canAddMultipleValues = (
    attributeType: AttributeType | undefined,
    isSystemAttribute: boolean,
    queryRule: SearchRuleQueryRuleEnum,
): boolean => {
    if (
        !attributeType ||
        !attributeType?.valueType ||
        [
            RuleType.HAS_VALUE,
            RuleType.GREATER,
            RuleType.GREATER_OR_EQUAL,
            RuleType.LESS,
            RuleType.LESS_OR_EQUAL,
            RuleType.HAS_NOT_VALUE,
        ].includes(queryRule as RuleType)
    )
        return false;

    if (isSystemAttribute) {
        return [
            'nodeId',
            'updatedAt',
            'createdAt',
            'deletedAt',
            'name',
            'modelTypeId',
            'objectTypeId',
            'folderTypeId',
            'edgeTypeId',
            'symbolId',
        ].includes(attributeType.id);
    }

    return [
        AttributeValueType.NUMERIC,
        AttributeValueType.INTEGER,
        AttributeValueType.STRING,
        AttributeValueType.DATE,
        AttributeValueType.DATE_TIME,
        AttributeValueType.TIME,
        AttributeValueType.SELECT,
        AttributeValueType.NODE,
        AttributeValueType.URL,
    ].includes(attributeType.valueType as AttributeValueType);
};
