import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';

const modelDialogState = (state: TRootState) => state.modelDialog;

export namespace ModelDialogSelectors {
    export const getFormStatus = createSelector(modelDialogState, (state) => state.modelFormStatus);

    export const getFormInitData = createSelector(modelDialogState, (state) => state.modelFormInitData);
}
