import {
    ADD_OBJECT_TYPE_GROUPS,
    CREATE_OBJECT_TYPE_GROUP, DELETE_OBJECT_TYPE_GROUP,
    DELETE_OBJECT_TYPE_GROUP_REQUEST,
    DELETE_OBJECT_TYPE_GROUP_SUCCESS,
    EDIT_OBJECT_TYPE_GROUP,
    SUBMIT_OBJECT_TYPE_GROUP
} from '../actionsTypes/objectTypeGroup.actionTypes';
import {
    TAddObjectTypeGroupAction,
    TCreateObjectTypeGroupAction,
    TCreateObjectTypeGroupPayload,
    TDeleteObjectTypeGroupAction, TDeleteObjectTypeGroupPayload,
    TDeleteObjectTypeGroupRequestAction,
    TDeleteObjectTypeGroupRequestPayload,
    TDeleteObjectTypeGroupSuccessAction,
    TEditObjectTypeGroupAction,
    TEditObjectTypeGroupPayload,
    TSubmitObjectTypeGroupAction,
    TSubmitObjectTypeGroupPayload
} from './objectTypeGroup.actions.types';


export const deleteObjectTypeGroupRequest = (payload: TDeleteObjectTypeGroupRequestPayload): TDeleteObjectTypeGroupRequestAction => ({
    type: DELETE_OBJECT_TYPE_GROUP_REQUEST,
    payload
});

export const deleteObjectTypeGroup = (payload: TDeleteObjectTypeGroupPayload): TDeleteObjectTypeGroupAction => ({
    type: DELETE_OBJECT_TYPE_GROUP,
    payload
});

export const deleteObjectTypeGroupSuccess
                = (payload: TDeleteObjectTypeGroupRequestPayload): TDeleteObjectTypeGroupSuccessAction => ({
                    type: DELETE_OBJECT_TYPE_GROUP_SUCCESS,
                    payload
                });

export const createObjectTypeGroup = (payload: TCreateObjectTypeGroupPayload): TCreateObjectTypeGroupAction => ({
    type: CREATE_OBJECT_TYPE_GROUP,
    payload,
});

export const submitObjectTypeGroup = (payload: TSubmitObjectTypeGroupPayload): TSubmitObjectTypeGroupAction => ({
    type: SUBMIT_OBJECT_TYPE_GROUP,
    payload
});

export const addObjectTypeGroups = (payload: TSubmitObjectTypeGroupPayload): TAddObjectTypeGroupAction => ({
    type: ADD_OBJECT_TYPE_GROUPS,
    payload,
});

export const editObjectTypeGroup = (payload: TEditObjectTypeGroupPayload): TEditObjectTypeGroupAction => ({
    type: EDIT_OBJECT_TYPE_GROUP,
    payload
});
