import { KanbanCardType } from '../../serverapi/api';
import {
    PRESET_SETTINGS_ADD_KANBAN_CARD_TYPE,
    PRESET_SETTINGS_CLEAR_KANBAN_CARD_TYPES_FOR_DELETE,
    PRESET_SETTINGS_DELETE_KANBAN_CARD_TYPES,
    PRESET_SETTINGS_GET_ALL_KANBAN_CARD_TYPES,
    PRESET_SETTINGS_SUBMIT_KANBAN_CARD_TYPES,
} from '../../actionsTypes/presetSettings/presetSettingsKanbanCardTypes.actionTypes';
import { TReducer } from '../../utils/types';
import {
    IPresetSettingsKanbanCardTypeData,
    TPresetSettingsKanbanCardTypeState,
} from './presetSettingsKanbanCardType.reducer.types';

export const PRESET_SETTINGS_INITIAL_KANBAN_CARD_TYPE_SERVER_STATE: IPresetSettingsKanbanCardTypeData = {
    byId: {},
    kanbanCardTypesForDelete: [],
};

const initial: TPresetSettingsKanbanCardTypeState = {
    byPresetId: {},
};

const mappingKanbanCardTypes = (
    kanbanCardTypes: KanbanCardType[],
): {
    [id: string]: KanbanCardType;
} => {
    const kanbanCardTypesMap = kanbanCardTypes.reduce(
        (
            result: {
                [id: string]: KanbanCardType;
            },
            item: KanbanCardType,
        ) => {
            result[item.id] = item;

            return result;
        },
        {},
    );

    return kanbanCardTypesMap;
};

const setNewData = (
    state: TPresetSettingsKanbanCardTypeState,
    presetId: string,
    newData: Partial<IPresetSettingsKanbanCardTypeData>,
): TPresetSettingsKanbanCardTypeState => {
    return {
        ...state,
        byPresetId: {
            ...state.byPresetId,
            [presetId]: {
                ...PRESET_SETTINGS_INITIAL_KANBAN_CARD_TYPE_SERVER_STATE,
                ...state.byPresetId[presetId],
                ...newData,
            },
        },
    };
};

export const presetSettingsKanbanCardTypeReducer: TReducer<TPresetSettingsKanbanCardTypeState> = (
    state = initial,
    action,
) => {
    switch (action.type) {
        case PRESET_SETTINGS_GET_ALL_KANBAN_CARD_TYPES: {
            const { kanbanCardTypes, presetId } = action.payload;
            const kanbanCardTypesMap = mappingKanbanCardTypes(kanbanCardTypes);
            const newData: Partial<IPresetSettingsKanbanCardTypeData> = {
                byId: kanbanCardTypesMap,
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_ADD_KANBAN_CARD_TYPE: {
            const { kanbanCardType, presetId } = action.payload;
            const newData: Partial<IPresetSettingsKanbanCardTypeData> = {
                byId: {
                    ...state.byPresetId[presetId]?.byId,
                    ...{ [kanbanCardType.id]: kanbanCardType },
                },
                kanbanCardTypesForDelete: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_DELETE_KANBAN_CARD_TYPES: {
            const { kanbanCardTypesForDelete, presetId } = action.payload;
            const allKanbanCardTypes = state.byPresetId[presetId]?.byId || {};
            const newData: Partial<IPresetSettingsKanbanCardTypeData> = {
                byId: allKanbanCardTypes,
                kanbanCardTypesForDelete: [
                    ...state.byPresetId[presetId]?.kanbanCardTypesForDelete,
                    ...kanbanCardTypesForDelete,
                ],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_SUBMIT_KANBAN_CARD_TYPES: {
            const { kanbanCardTypesForDelete, presetId } = action.payload;
            const allKanbanCardTypes = state.byPresetId[presetId]?.byId || {};
            const filteredKanbanCardTypes = Object.keys(allKanbanCardTypes).reduce(
                (res: { [id: string]: KanbanCardType }, id) => {
                    const findInDeleteList = kanbanCardTypesForDelete.some(
                        (kanbanModelType) => kanbanModelType.id === id,
                    );
                    if (!findInDeleteList) {
                        res[id] = allKanbanCardTypes[id];
                    }

                    return res;
                },
                {},
            );
            const newData: Partial<IPresetSettingsKanbanCardTypeData> = {
                byId: filteredKanbanCardTypes,
                kanbanCardTypesForDelete: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_CLEAR_KANBAN_CARD_TYPES_FOR_DELETE: {
            const { presetId } = action.payload;
            const allKanbanCardTypes = state.byPresetId[presetId]?.byId || {};
            const newData: Partial<IPresetSettingsKanbanCardTypeData> = {
                byId: allKanbanCardTypes,
                kanbanCardTypesForDelete: [],
            };

            return setNewData(state, presetId, newData);
        }

        default: {
            return state;
        }
    }
};
