import { SelectedStrategy } from '../models/selectObjectDialog.types';
import { EdgeDefinitionNode, ObjectDefinitionNode } from '../serverapi/api';
import {
    SELECT_EDGE_DEFINITION_DIALOG_PREPARE_DATA_REQUEST,
    SELECT_EDGE_DEFINITION_DIALOG_PREPARE_DATA_SUCCESS,
    SELECT_EDGE_DIALOG_SUBMIT_DATA,
    SELECT_EDGE_DIALOG_SUBMIT_DATA_FROM_MATRIX,
} from '../actionsTypes/selectEdgeDefinitionDialog.actionTypes';
import {
    TSelectEdgeDialogPrepareDataRequestAction,
    TSelectEdgeDialogPrepareDataSuccessAction,
    TSelectEdgeDialogSubmitData,
    TSelectEdgeDialogSubmitDataFromMatrix,
} from './selectEdgeDefinitionDialog.actions.types';
import { TSelectEdgeDefinitionDialogState } from '../reducers/selectEdgeDefinitionDialog.reducer.types';

export const selectEdgeDialogPrepareDataRequest = (
    instances: ObjectDefinitionNode[],
    sourceObjectDefinitionId: string,
    targetObjectDefinitionId: string,
): TSelectEdgeDialogPrepareDataRequestAction => ({
    type: SELECT_EDGE_DEFINITION_DIALOG_PREPARE_DATA_REQUEST,
    payload: {
        instances,
        sourceObjectDefinitionId,
        targetObjectDefinitionId,
    },
});

export const selectEdgeDialogPrepareDataSuccess = (
    payload: TSelectEdgeDefinitionDialogState,
): TSelectEdgeDialogPrepareDataSuccessAction => ({
    type: SELECT_EDGE_DEFINITION_DIALOG_PREPARE_DATA_SUCCESS,
    payload,
});

export const selectEdgeDialogSubmit = (
    strategy: SelectedStrategy,
    edgeDefinition: EdgeDefinitionNode,
    cellId: string,
): TSelectEdgeDialogSubmitData => ({
    type: SELECT_EDGE_DIALOG_SUBMIT_DATA,
    payload: {
        strategy,
        edgeDefinition,
        cellId,
    },
});

export const selectEdgeDialogSubmitFromMatrix = (
    strategy: SelectedStrategy,
    edgeDefinition: EdgeDefinitionNode,
): TSelectEdgeDialogSubmitDataFromMatrix => ({
    type: SELECT_EDGE_DIALOG_SUBMIT_DATA_FROM_MATRIX,
    payload: {
        strategy,
        edgeDefinition,
    },
});
