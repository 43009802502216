import React from 'react';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './DeleteCommentDialog.messages';
import { TDeleteCommentDialogProps } from './DeleteCommentDialog.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

class DeleteCommentDialog extends React.Component<TDeleteCommentDialogProps, {}> {
    handleSubmit = () => {
        const { modelId, commentId, onSubmit } = this.props;
        if (onSubmit) {
            onSubmit(modelId, commentId);
        }
    };

    render() {
        const { intl, isAnswer, onClose } = this.props;

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: onClose,
                        value: intl.formatMessage(messages.cancel),
                        dataTest: 'cancel-delete-comment_btn',
                    },
                    {
                        key: 'ok',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.deleteComment),
                        visualStyle: 'primary',
                        dataTest: 'confirm-delete-comment_btn',
                        danger: true,
                    },
                ]}
            />
        );

        return (
            <Dialog
                open
                onOk={this.handleSubmit}
                onCancel={onClose}
                title={intl.formatMessage(messages.title)}
                footer={footer}
            >
                <div>{isAnswer ? intl.formatMessage(messages.textForAnswer) : intl.formatMessage(messages.text)}</div>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(DeleteCommentDialog);

export { IntlComponent as DeleteCommentDialog };
