import React, { FC } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Tooltip } from 'antd';
import messages from '../../messages/PrincipalAttributeDialog.messages';
import theme from './PrincipalAttributeDialog.scss';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { TGroupListTableRecord } from './GroupList.component.types';
import icDeleted from '../../../../resources/icons/ic-g-u-deleted.svg';
import icBlocked from '../../../../resources/icons/ic-g-u-blocked.svg';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import { DELETED_GROUP_NAME } from '../utils';

type TGroupNameProps = {
    text?: string;
    group: PrincipalDescriptor | TGroupListTableRecord;
    withGap?: boolean;
} & WrappedComponentProps;

const GroupName: FC<TGroupNameProps> = ({ intl, text: rawText, group, withGap = false }) => {
    const isDeleted = rawText === DELETED_GROUP_NAME;
    const isBlocked = !isDeleted && group.blocked;
    const tooltipText = intl.formatMessage(isDeleted ? messages.groupNotFound : messages.groupBlocked);
    const tooltipIcon = isDeleted ? icDeleted : icBlocked;
    const text = isDeleted ? `${intl.formatMessage(messages.groupNotFoundPrefix)} ${group.id}` : rawText;
    const isIconVisible = isDeleted || isBlocked;

    return (
        <div className={theme.principalContainer}>
            <Tooltip title={isIconVisible ? tooltipText : ""}>
                <div
                    className={theme.tooltipIcon}
                    style={
                        withGap
                            ? { visibility: isIconVisible ? 'visible' : 'hidden', display: 'unset' }
                            : { display: isIconVisible ? 'inherit' : 'none', visibility: 'unset' }
                    }
                >
                    <Icon spriteSymbol={tooltipIcon} />
                </div>
            </Tooltip>
            {text}
        </div>
    );
};

export default injectIntl(GroupName);
