import { call, put, select, takeEvery } from 'redux-saga/effects';
import { openDialog } from '../actions/dialogs.actions';
import { OBJECT_PROPERTY_VIEW } from '../actionsTypes/objectProperty.actionTypes';
import { ObjectPropertiesDialogActiveTab } from '../models/objectPropertiesDialog';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { MxGraph } from '../mxgraph/mxgraph';
import { TreeSelectors } from '../selectors/tree.selectors';
import {
    DefaultId,
    DiagramElement,
    EdgeDefinitionNode,
    EdgeInstance,
    EdgeType,
    FolderType,
    NodeId,
    ObjectDefinitionNode,
    ObjectInstance,
    ObjectType,
    Node,
    FolderNode,
    AttributeType,
} from '../serverapi/api';
import { UserProfileSelectors } from '../selectors/userProfile.selectors';
import { NotificationType } from '../models/notificationType';
import { showNotificationByType } from '../actions/notification.actions';
import { TObjectPropertyViewAction } from '../actions/objectProperty.actions.types';
import { ObjectTypeSelectors } from '../selectors/objectType.selectors';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { ObjectDefinitionsDAOService } from '../services/dao/ObjectDefinitionsDAOService';
import { EdgeDefinitionDAOService } from '../services/dao/EdgeDefinitionDAOService';
import { EdgeTypeSelectors } from '../selectors/edgeType.selectors';
import { TObjectPropertiesDialogProps } from '../modules/ObjectPropertiesDialog/components/ObjectPropertiesDialog.types';
import { edgeDefinitionsAdd } from '../actions/entities/edgeDefinition.actions';
import { objectDefinitionsAdd } from '../actions/entities/objectDefinition.actions';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { UML_OBJECT_TYPE } from '../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
import { getActiveGraph } from '@/selectors/editor.selectors';
import { loadNodeApprovals } from '@/actions/approval.actions';
import { nodeService } from '@/services/NodeService';
import { FolderTypeSelectors } from '@/selectors/folderType.selectors';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { EdgeDefinitionSelectors } from '@/selectors/edgeDefinition.selector';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { CellTypes } from '@/utils/bpm.mxgraph.utils';

type TOpenFolderDialogProps = {
    node: Node;
    attributeTypes: AttributeType[];
    parentFolderTypeId?: string;
    path: string;
};

function* getEdgeObjects(edgeDefinition: EdgeDefinitionNode | undefined) {
    if (edgeDefinition) {
        const { nodeId } = edgeDefinition;
        let objectList: NodeId[] = [];

        edgeDefinition.sourceObjectDefinitionId &&
            objectList.push({ ...nodeId, id: edgeDefinition.sourceObjectDefinitionId });

        edgeDefinition.targetObjectDefinitionId &&
            objectList.push({ ...nodeId, id: edgeDefinition.targetObjectDefinitionId });

        const edgeObjects: Node[] = yield TreeDaoService.getNodes(objectList, nodeId.serverId);

        const sourceObject = edgeObjects.find((item) => item.nodeId.id === edgeDefinition.sourceObjectDefinitionId);
        const targetObject = edgeObjects.find((item) => item.nodeId.id === edgeDefinition.targetObjectDefinitionId);

        return [sourceObject, targetObject];
    }

    return [];
}

export function* openFolderPropertyDialog(nodeId: NodeId) {
    const { serverId, id, repositoryId } = nodeId;
    const [pathRes, nodeRes] = yield Promise.allSettled([
        TreeDaoService.getNodePath(serverId, id, repositoryId),
        nodeService().loadNodeFromServer(nodeId),
    ]);

    const { path } = pathRes.value;
    const node: Node | undefined = nodeRes.value;

    if (!node) {
        return;
    }

    const presetId: string = (yield select(TreeSelectors.presetById(nodeId))) || DefaultId.DEFAULT_PRESET_ID;
    const props: TOpenFolderDialogProps = {
        node,
        attributeTypes: [],
        path,
    };

    if (node.parentNodeId) {
        const parentFolderType: FolderType | undefined = yield select(
            FolderTypeSelectors.getParentNodeFolderType(nodeId),
        );
        const folderType: FolderType | undefined = yield select(
            FolderTypeSelectors.byId({
                folderTypeId: (node as FolderNode).folderType || 'default',
                presetId,
                serverId,
            }),
        );
        if (folderType?.allowApprovals) {
            yield put(loadNodeApprovals({ nodeId }));
        }
        if (parentFolderType) {
            props.parentFolderTypeId = parentFolderType.id;
        } else {
            const parentNode: Node = yield TreeDaoService.getNode(serverId, node.parentNodeId);
            if (parentNode.type === TreeItemType.Folder) {
                props.parentFolderTypeId = (parentNode as FolderNode).folderType;
            }
        }
    }

    yield put(openDialog(DialogType.PROPERTIES_DIALOG, props));
}

export function* openObjectPropertyDialog(
    nodeId: NodeId,
    tab: ObjectPropertiesDialogActiveTab,
    graphId?: NodeId,
    cellId?: string,
) {
    const { serverId, id, repositoryId } = nodeId;
    const [pathRes, objectDefinitionRes] = yield Promise.allSettled([
        TreeDaoService.getNodePath(serverId, id, repositoryId),
        ObjectDefinitionsDAOService.getObjectDefinitionById(nodeId),
    ]);
    const path: string = pathRes.value.path;
    const objectDefinition: ObjectDefinitionNode | undefined = objectDefinitionRes.value;

    if (objectDefinition?.objectTypeId === UML_OBJECT_TYPE.CLASS) {
        const classChildObjects: ObjectDefinitionNode[] =
            yield ObjectDefinitionsDAOService.getObjectDefinitionWithChildren(nodeId);
        yield put(objectDefinitionsAdd(classChildObjects as ObjectDefinitionImpl[]));
    }

    if (!objectDefinition) {
        yield put(showNotificationByType(NotificationType.NOT_FOUND_ERROR));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);

        return;
    }
    const presetId: string = yield select(TreeSelectors.presetById(objectDefinition.nodeId));

    const objectType: ObjectType | undefined = yield select(
        ObjectTypeSelectors.byId({
            objectTypeId: objectDefinition.objectTypeId || '',
            presetId,
            serverId,
        }),
    );

    let isSymbolReadable = true;
    if (cellId && graphId) {
        const graph: MxGraph | undefined = instancesBPMMxGraphMap.get(graphId);
        const value: ObjectInstance | undefined = graph?.getModel().getCell(cellId)?.getValue();
        isSymbolReadable = yield select(UserProfileSelectors.isSymbolReadable(nodeId, value?.symbolId));
    }

    const isObjectTypeReadable = yield select(
        UserProfileSelectors.isObjectTypeReadable(serverId, presetId, objectDefinition.objectTypeId || ''),
    );

    if (!isObjectTypeReadable || !isSymbolReadable) {
        yield put(showNotificationByType(NotificationType.ACCESS_DENIED_BY_PROFILE));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);

        return;
    }
    const graph = graphId && instancesBPMMxGraphMap.get(graphId);
    yield put(objectDefinitionsAdd([objectDefinition as ObjectDefinitionImpl]));
    yield put(
        openDialog(DialogType.PROPERTIES_DIALOG, {
            serverId,
            node: objectDefinition,
            attributeTypes: objectType?.nodeAttributes || [],
            diagramElementAttributeTypes: objectType?.diagramElementAttributes || [],
            tab,
            cellId,
            graph,
            path,
        } as TObjectPropertiesDialogProps),
    );
    LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);

    if (objectType?.allowApprovals) {
        yield put(loadNodeApprovals({ nodeId }));
    }
}

export function* openEdgeDefinitionPropertyDialog(nodeId: NodeId, tab: ObjectPropertiesDialogActiveTab) {
    const { serverId, id, repositoryId } = nodeId;

    const [pathRes, edgeDefinitionRes] = yield Promise.allSettled([
        TreeDaoService.getNodePath(serverId, id, repositoryId),
        EdgeDefinitionDAOService.getEdgeDefinition(nodeId),
    ]);
    const { path } = pathRes.value;
    const edgeDefinition: EdgeDefinitionNode | undefined = edgeDefinitionRes.value;

    if (!edgeDefinition) {
        yield put(showNotificationByType(NotificationType.NOT_FOUND_ERROR));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);

        return;
    }

    const presetId: string = yield select(TreeSelectors.presetById(nodeId));
    const edgeTypeId: string = edgeDefinition.edgeTypeId || '';

    const edgeType: EdgeType | undefined = yield select(EdgeTypeSelectors.byId({ edgeTypeId, presetId, serverId }));

    const isEdgeTypeReadable = yield select(UserProfileSelectors.isEdgeTypeReadable(serverId, presetId, edgeTypeId));

    if (!isEdgeTypeReadable) {
        yield put(showNotificationByType(NotificationType.ACCESS_DENIED_BY_PROFILE));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);

        return;
    }

    const [sourceNode, targetNode]: Node[] = yield getEdgeObjects(edgeDefinition);

    yield put(edgeDefinitionsAdd([edgeDefinition]));
    yield put(
        openDialog(DialogType.PROPERTIES_DIALOG, {
            serverId,
            node: edgeDefinition,
            attributeTypes: edgeType?.attributeTypes || [],
            sourceNode,
            targetNode,
            diagramElementAttributeTypes: edgeType?.diagramElementAttributes || [],
            tab,
            path,
        } as TObjectPropertiesDialogProps),
    );
    LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);

    if (edgeType?.allowApprovals) {
        yield put(loadNodeApprovals({ nodeId }));
    }
}

export function* openEdgeInstancePropertyDialog(tab: ObjectPropertiesDialogActiveTab, graphId: NodeId, cellId: string) {
    const { serverId, id, repositoryId } = graphId;
    const { path } = yield call(() => TreeDaoService.getNodePath(serverId, id, repositoryId));

    const graph: MxGraph | undefined = instancesBPMMxGraphMap.get(graphId);
    const edgeInstance: EdgeInstance | undefined = graph?.getModel().getCell(cellId)?.getValue();
    if (!edgeInstance) {
        return;
    }

    let edgeDefinition: EdgeDefinitionNode | undefined;
    if (edgeInstance.edgeDefinitionId) {
        const edgeDefinitionId: NodeId = { ...graphId, id: edgeInstance.edgeDefinitionId };
        edgeDefinition = yield EdgeDefinitionDAOService.getEdgeDefinition(edgeDefinitionId);
    }

    const presetId: string = yield select(TreeSelectors.presetById(graphId || graph));
    const edgeTypeId: string | undefined = edgeDefinition?.edgeTypeId || edgeInstance.edgeTypeId;

    const edgeType: EdgeType | undefined = yield select(EdgeTypeSelectors.byId({ edgeTypeId, presetId, serverId }));

    const isEdgeTypeReadable = yield select(UserProfileSelectors.isEdgeTypeReadable(serverId, presetId, edgeTypeId));

    if (!isEdgeTypeReadable) {
        yield put(showNotificationByType(NotificationType.ACCESS_DENIED_BY_PROFILE));

        return;
    }

    const [sourceNode, targetNode] = yield getEdgeObjects(edgeDefinition);

    yield put(
        openDialog(DialogType.PROPERTIES_DIALOG, {
            serverId,
            node: edgeDefinition,
            attributeTypes: (edgeDefinition && edgeType?.attributeTypes) || [], // если нет определения то нет атрибутов определения
            diagramElementAttributeTypes: edgeType?.diagramElementAttributes || [],
            sourceNode,
            targetNode,
            tab,
            cellId,
            graphId,
            graph,
            path,
        } as TObjectPropertiesDialogProps),
    );
}

function* handlePropertyView({ payload }: TObjectPropertyViewAction) {
    const { cellId, activeTab } = payload;
    const tab: ObjectPropertiesDialogActiveTab = activeTab || ObjectPropertiesDialogActiveTab.NameAndAttributes;
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    if (activeGraphId) {
        const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
        const value: DiagramElement | undefined = graph?.getModel().getCell(cellId)?.getValue();

        if (value?.type === CellTypes.Edge) {
            const edgeDefinition: EdgeDefinitionNode | undefined = yield select(
                EdgeDefinitionSelectors.byId({ ...activeGraphId, id: (value as EdgeInstance)?.edgeDefinitionId || '' }),
            );
            yield openEdgeInstancePropertyDialog(tab, activeGraphId, cellId);

            if (edgeDefinition && (value as EdgeInstance)?.edgeType?.allowApprovals) {
                yield put(loadNodeApprovals({ nodeId: edgeDefinition.nodeId }));
            }
        }
        if (value?.type === CellTypes.Object) {
            const object: ObjectInstance = value as ObjectInstance;
            if (!object.objectDefinitionId) {
                return;
            }

            const nodeId: NodeId = { ...activeGraphId, id: object.objectDefinitionId };
            yield openObjectPropertyDialog(nodeId, tab, activeGraphId, cellId);
        }
    }
}

export function* objectPropertyDialogInit() {
    yield takeEvery(OBJECT_PROPERTY_VIEW, handlePropertyView);
}
