// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Notice__root___64kH{display:-webkit-box;display:-ms-flexbox;display:flex;padding:8px 18px;border-radius:8px;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.Notice__root_error__TiRnA{background-color:var(--light-red-UIKit)}.Notice__root_info___AMa3{background-color:var(--Primary-BgMain50-UIKit)}.Notice__root_success__ObbW1{background-color:var(--green50-UIKit)}.Notice__text__UMaWA{color:var(--grey-dark-UIKit);font-size:14px;font-weight:400;line-height:22px;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.Notice__icon__ZnrMb{margin-right:8px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Notice/Notice.scss"],"names":[],"mappings":"AAAA,qBACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,gBAAA,CACA,iBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEA,2BACI,uCAAA,CAGJ,0BACI,8CAAA,CAGJ,6BACI,qCAAA,CAIR,qBACI,4BAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CAAA,mBAAA,CAAA,WAAA,CAGJ,qBACI,gBAAA","sourcesContent":[".root {\r\n    display: flex;\r\n    padding: 8px 18px;\r\n    border-radius: 8px;\r\n    align-items: center;\r\n\r\n    &_error {\r\n        background-color: var(--light-red-UIKit);\r\n    }\r\n\r\n    &_info {\r\n        background-color: var(--Primary-BgMain50-UIKit);\r\n    }\r\n\r\n    &_success {\r\n        background-color: var(--green50-UIKit);\r\n    }\r\n}\r\n\r\n.text {\r\n    color: var(--grey-dark-UIKit);\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    line-height: 22px;\r\n    flex-grow: 1;\r\n}\r\n\r\n.icon {\r\n    margin-right: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Notice__root___64kH",
	"root_error": "Notice__root_error__TiRnA",
	"root_info": "Notice__root_info___AMa3",
	"root_success": "Notice__root_success__ObbW1",
	"text": "Notice__text__UMaWA",
	"icon": "Notice__icon__ZnrMb"
};
export default ___CSS_LOADER_EXPORT___;
