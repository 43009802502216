import { Table } from 'antd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import licenseTypes from '../../../../models/licenseTypes';
import licenseTypesMsg from '../../../../models/licenseTypes.messages';
import { TLicenseOwners } from '../../../../reducers/admintool/licenses.reducer.types';
import { UserDTOLicensesEnum } from '../../../../serverapi/api';
import adminToolTheme from '../../style/AdminTools.scss';
import { EditButton, RowButtons } from '../../Button/RowButtons';
import licenseMessages from '../../messages/licensestool.messages';
import {
    TLicensesDistributionFullProps,
    TLicensesDistributionTableRecord,
} from '../LicensesDistribution.types';
import { sortByAlpha } from '../../../../utils/sortByAlpha';

class LicensesDistribution extends Component<TLicensesDistributionFullProps> {
    componentDidMount() {
        this.props.fetchData(this.props.serverId);
    }

    getColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage(licenseMessages.licenseTypes),
                dataIndex: 'title',
                width: 150,
                sorter: (a: TLicensesDistributionTableRecord, b: TLicensesDistributionTableRecord) =>
                    sortByAlpha(a.title, b.title),
            },
            {
                title: this.props.intl.formatMessage(licenseMessages.usersGroupsColTitle),
                dataIndex: 'usersGroups',
                width: 150,
            },
            {
                title: '',
                dataIndex: 'manageBtn',
                key: 'manageBtn',
                width: 40,
                render: (text = '', record: TLicensesDistributionTableRecord) => (
                    <RowButtons buttons={[EditButton.build(() => this.props.onEditClicked(record.key), undefined, undefined, undefined, true)]} />
                ),
            },
        ];
    };

    prepareGroups = (lTypes: UserDTOLicensesEnum) => {
        const { owners } = this.props;
        const licenseOwners: TLicenseOwners = owners && owners[lTypes];

        return licenseOwners?.groups?.map((g) => g.groupName).sort() || [];
    };

    prepareUsers = (lTypes: UserDTOLicensesEnum) => {
        const { owners } = this.props;
        const licenseOwners: TLicenseOwners = owners && owners[lTypes];

        return licenseOwners?.users?.map((u) => u.login).sort() || [];
    };

    getData = () =>
        Object.keys(licenseTypes).map((k) => {
            const groups = this.prepareGroups(k as UserDTOLicensesEnum).join(', ');
            const users = this.prepareUsers(k as UserDTOLicensesEnum).join(', ');

            return {
                key: k,
                title: this.props.intl.formatMessage(licenseTypesMsg[k]),
                usersGroups: `${groups}\r\n${users}`,
            };
        });

    render() {
        const maxTableHeight = this.props.wHeight - 250;

        return (
            <div>
                <Table
                    className={adminToolTheme.dataTable}
                    columns={this.getColumns()}
                    dataSource={this.getData()}
                    pagination={false}
                    scroll={{ y: maxTableHeight }}
                />
            </div>
        );
    }
}

export default injectIntl(LicensesDistribution);
