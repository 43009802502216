/* eslint-disable no-param-reassign */
import { ContentBlock, ContentState, EditorState, genKey, Modifier, SelectionState } from 'draft-js';
import { compact, unzip, zip } from 'lodash-es';
import { Map } from 'immutable';
import { getCurrentBlock, setBlockData, setBlockType } from '../../../common/contentBlocks.utils';
import {
    WIKI_TABLE_KEY,
    WIKI_TABLE_POSITION_KEY,
    WIKI_TABLE_ROW_STYLE_KEY,
    WIKI_TABLE_SHAPE_KEY,
    WIKI_TABLE_SHOW_HEADER_KEY,
    WIKI_TABLE_TABLE_STYLE_KEY,
} from './WikiTable.constants';
import { TTableShapeElement, TTablePosition, TTableShape } from './WikiTable.types';

export const defaultCellStyle = { border: '1px solid rgba(0, 0, 0, 0.2)', padding: '6px' };

type TBlocksTablePosition = string;
type TParsedTablePosition = {
    tableKey: string;
    rowCount: number;
    colCount: number;
};
const getTablePositionBlockString = (tableKey, rowCount, colCount): TBlocksTablePosition =>
    `${tableKey}-${rowCount}-${colCount}`;
const getParsedTablePosition = (tablePosition: TBlocksTablePosition): TParsedTablePosition => {
    const [tableKey, rowCount, colCount] = tablePosition.split('-');

    return {
        tableKey,
        rowCount: parseInt(rowCount, 10),
        colCount: parseInt(colCount, 10),
    };
};

export const insertTable = (editorState, size): EditorState => {
    let selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
        return editorState;
    }
    // don't insert a table within a table
    if (editorState.getCurrentContent().getBlockForKey(selection.getAnchorKey()).getType() === 'TABLE') {
        return editorState;
    }

    const cols = Array(size.cols).fill(1);
    const tableHead = Array(size.cols)
        .fill(1)
        .map((row) => ({
            element: 'th',
            style: { ...defaultCellStyle },
        }));
    const tableShape = [
        tableHead,
        ...Array(size.rows)
            .fill(cols)
            .map((row) => row.map(() => ({ element: 'td', style: { ...defaultCellStyle } }))),
    ];

    const tableKey = genKey();
    const newBlocks: ContentBlock[] = [];

    tableShape.forEach((row, i) => {
        row.forEach((cell, j) => {
            const key = genKey();
            let data = Map<string | object>({
                tableKey,
                tablePosition: getTablePositionBlockString(tableKey, i, j),
            });
            if (i === 0 && j === 0) {
                data = data
                    .set(WIKI_TABLE_SHAPE_KEY, tableShape)
                    .set(WIKI_TABLE_TABLE_STYLE_KEY, { 'border-collapse': 'collapse', margin: '15px 0', width: '100%' })
                    .set(WIKI_TABLE_ROW_STYLE_KEY, {})
                    .set(WIKI_TABLE_SHOW_HEADER_KEY, true as any);
            }
            const newBlock = new ContentBlock({ key, type: 'TABLE', text: ' ', data });
            newBlocks.push(newBlock);
        });
    });

    const selectionKey = selection.getAnchorKey();
    let contentState = editorState.getCurrentContent();
    contentState = Modifier.splitBlock(contentState, selection);
    const blockArray = contentState.getBlocksAsArray();
    const currBlock = contentState.getBlockForKey(selectionKey);
    const index = blockArray.findIndex((block) => block === currBlock);
    const isEnd = index === blockArray.length - 1;

    if (blockArray[index]?.getType() === 'TABLE') {
        newBlocks.unshift(new ContentBlock({ key: genKey() }));
    }
    if (blockArray[index + 1]?.getType() === 'TABLE') {
        newBlocks.push(new ContentBlock({ key: genKey() }));
    }
    blockArray.splice(index + 1, 0, ...newBlocks);
    if (isEnd) {
        blockArray.push(new ContentBlock({ key: genKey() }));
    }

    const entityMap = contentState.getEntityMap();
    contentState = ContentState.createFromBlockArray(blockArray, entityMap);
    let newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
    const key = newBlocks[0].getKey();
    selection = SelectionState.createEmpty(key);
    newEditorState = EditorState.acceptSelection(newEditorState, selection);

    return newEditorState;
};

export const isTableBlock = (block): boolean => {
    if (block.getType() === 'TABLE' || block.getData().get('type') === 'TABLE') {
        return block;
    }

    return false;
};

export const isTableRootBlock = (block: ContentBlock): boolean => {
    return !!block.getData().get(WIKI_TABLE_SHAPE_KEY);
};

const getTableRoot = (editorState, tableKey): ContentBlock => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    return blockMap.find((block) => {
        const data = block.getData();
        if (data.get(WIKI_TABLE_SHAPE_KEY) && data.get(WIKI_TABLE_KEY) === tableKey) {
            return block;
        }

        return false;
    });
};

export const putInTable = (editorState, block: ContentBlock): EditorState => {
    const tableKey = block.getData().get(WIKI_TABLE_KEY);
    const tablePosition = block.getData().get(WIKI_TABLE_POSITION_KEY);
    let newEditorState = setBlockData(editorState, block, { tableKey, tablePosition });
    newEditorState = setBlockType(newEditorState, block, 'TABLE');

    return newEditorState;
};

const getTableShapeCell = (): TTableShapeElement =>
    ({
        element: 'td',
        style: { ...defaultCellStyle },
    } as TTableShapeElement);

export const createTableShapeLine = (size: number): TTableShapeElement[] => Array(size).fill(getTableShapeCell());

export const getBlockCell = (tableKey, x, y) =>
    Map<string | object>({
        tableKey,
        tablePosition: getTablePositionBlockString(tableKey, y, x),
    });

export const createBlocksLine = (y, rowSize, tableKey): ContentBlock[] => {
    return Array(rowSize)
        .fill(' ')
        .map((cell, x) => {
            const data = getBlockCell(tableKey, x, y);

            return new ContentBlock({ key: genKey(), type: 'TABLE', text: ' ', data });
        });
};

export const createTableBlock = (objectData, oldBlock): ContentBlock => {
    const key = genKey();
    const { tableKey, tableShape, text = oldBlock?.getText() || '', colIndex, rowIndex, showHeader } = objectData;

    let data = oldBlock?.getData() || Map<string | object>({});

    data = tableKey ? data.set(WIKI_TABLE_KEY, tableKey) : data;
    data =
        rowIndex !== undefined && colIndex !== undefined
            ? data.set(WIKI_TABLE_POSITION_KEY, getTablePositionBlockString(tableKey, rowIndex, colIndex))
            : data;
    data = rowIndex === 0 && colIndex === 0 && tableShape ? data.set(WIKI_TABLE_SHAPE_KEY, tableShape) : data;
    data = showHeader !== undefined ? data.set(WIKI_TABLE_SHOW_HEADER_KEY, showHeader) : data;

    return new ContentBlock({ key, type: 'TABLE', text, data });
};

export const getBlockTablePosition = (block: ContentBlock): TTablePosition => {
    const tableKey = block.getData().get(WIKI_TABLE_KEY);
    const tablePosition = block.getData().get(WIKI_TABLE_POSITION_KEY);
    const tableShape = block.getData().get(WIKI_TABLE_SHAPE_KEY);
    const { rowCount, colCount } = getParsedTablePosition(tablePosition);

    return {
        x: colCount,
        y: rowCount,
        tableKey,
        tableShape,
    };
};

export const getCurrentBlockTablePosition = (editorState) => {
    const block = getCurrentBlock(editorState);
    if (!isTableBlock(block)) {
        return { x: -1, y: -1 };
    }

    return getBlockTablePosition(block);
};

export const setTableShape = (editorState, rootBlock, tableShape): EditorState => {
    const merged = rootBlock.data.set(WIKI_TABLE_SHAPE_KEY, tableShape);

    const selection = SelectionState.createEmpty(rootBlock.get('key'));
    const content = Modifier.setBlockData(editorState.getCurrentContent(), selection, merged);

    return EditorState.push(editorState, content, 'change-block-data');
};

export const isTableBlockSelected = (block: ContentBlock) => block.get('type') === 'TABLE';

export const getRowspanDelta = (line) => {
    return line.reduce((aac, cell) => {
        if (cell.rowspan) {
            return Math.max(cell.rowspan - 1, aac);
        }

        return aac;
    }, 0);
};

export const transpos = (array) => {
    return zip(...array);
};

export const unTranspos = (array) => {
    return unzip(array).map(compact);
};

type TTableRootInfo = {
    tableKey: string | null;
    tableRoot: ContentBlock | null;
    tableShape: TTableShape | null;
};
const getTableRootInfo = (editorState, currentBlock = null): TTableRootInfo => {
    const block = currentBlock || getCurrentBlock(editorState);
    const { tableKey } = getBlockTablePosition(block);
    const tableRoot = getTableRoot(editorState, tableKey);

    if (!tableRoot) {
        return { tableKey: null, tableRoot: null, tableShape: null };
    }
    const tableShape = tableRoot.getData().get(WIKI_TABLE_SHAPE_KEY);

    return { tableKey, tableRoot, tableShape };
};

export const resizeTable = (editorState, currentBlock, sizes): EditorState => {
    const { tableShape, tableRoot } = getTableRootInfo(editorState, currentBlock);

    if (!tableShape || !tableShape) {
        return editorState;
    }

    const { width, height } = sizes;
    const { length } = tableShape;
    tableShape.forEach((line, lineIndex) => {
        line.forEach((col, index) => {
            if (!col?.style) {
                return;
            }
            if (width) {
                col.style.width = width[index];
            }
            if (height) {
                col.style.height = height[length * lineIndex + index];
            }
        });
    });

    return setTableShape(editorState, tableRoot, tableShape);
};

export const getTableSize = (editorState, currentBlock): [number] => {
    const { tableKey } = getBlockTablePosition(currentBlock);
    const tableRoot = getTableRoot(editorState, tableKey);
    const [shapeLine] = tableRoot.getData().get(WIKI_TABLE_SHAPE_KEY);

    return shapeLine.map((col) => col.style.width);
};
