import { connect } from 'react-redux';
import { TFileUploadDialogProps } from '../../../FileUpload/types/FileUploadDialog.types';
import { FileUploadDialog } from '../../../FileUpload/components/FileUploadDialog.component';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { importPresetRequest } from '../../../../actions/methodologySetting.actions';
import {
    PresetElementTransferType
} from '../../../../serverapi/api';
import { TPreset } from '../../../../models/preset.types';
import { TreeNode } from '../../../../models/tree.types';

type TContainerOwnProps = {
    type: PresetElementTransferType;
    preset: TPreset;
    serverNode: TreeNode;
};

const mapDispatchToProps = (dispatch, properties: TContainerOwnProps): Partial<TFileUploadDialogProps> => ({
    onClose: () => dispatch(closeDialog(DialogType.UPLOAD_PRESET_DIALOG)),
    onSubmit: (file: File) => {
        dispatch(closeDialog(DialogType.UPLOAD_PRESET_DIALOG));
        dispatch(
            importPresetRequest({
                serverNode: properties.serverNode,
                file,
                type: properties.type,
                preset: properties.preset,
            }),
        );
    },
});

export const FileUploadPresetDialogContainer = connect(null, mapDispatchToProps)(FileUploadDialog);
