import { SchedulerMonthsEnum, SchedulerWeekDaysEnum } from './DatetimeSchedulerSettings.types';

export const repeatEverySecondsOptions = [
    {
        timeUnitName: 'empty',
        timeUnitValue: '',
        valueInSeconds: undefined,
    },
    {
        timeUnitName: 'minutes',
        timeUnitValue: 5,
        valueInSeconds: 300,
    },
    {
        timeUnitName: 'minutes',
        timeUnitValue: 10,
        valueInSeconds: 600,
    },
    {
        timeUnitName: 'minutes',
        timeUnitValue: 15,
        valueInSeconds: 900,
    },
    {
        timeUnitName: 'minutes',
        timeUnitValue: 30,
        valueInSeconds: 1800,
    },
    {
        timeUnitName: 'hour',
        timeUnitValue: 1,
        valueInSeconds: 3600,
    },
];

export const stopRepeatAfterSecondsOptions = [
    {
        timeUnitName: 'empty',
        timeUnitValue: '',
        valueInSeconds: undefined,
    },
    {
        timeUnitName: 'minutes',
        timeUnitValue: 15,
        valueInSeconds: 900,
    },
    {
        timeUnitName: 'minutes',
        timeUnitValue: 30,
        valueInSeconds: 1800,
    },
    {
        timeUnitName: 'hourGenitive',
        timeUnitValue: 1,
        valueInSeconds: 3600,
    },
    {
        timeUnitName: 'hoursGenitive',
        timeUnitValue: 12,
        valueInSeconds: 43200,
    },
    {
        timeUnitName: 'dayGenitive',
        timeUnitValue: 1,
        valueInSeconds: 86400,
    },
];

export const ALL = 'all';
export const LAST = 'last';

export const weekDays = [
    SchedulerWeekDaysEnum.MONDAY,
    SchedulerWeekDaysEnum.TUESDAY,
    SchedulerWeekDaysEnum.WEDNESDAY,
    SchedulerWeekDaysEnum.THURSDAY,
    SchedulerWeekDaysEnum.FRIDAY,
    SchedulerWeekDaysEnum.SATURDAY,
    SchedulerWeekDaysEnum.SUNDAY,
];

export const months = [
    SchedulerMonthsEnum.JANUARY,
    SchedulerMonthsEnum.FEBRUARY,
    SchedulerMonthsEnum.MARCH,
    SchedulerMonthsEnum.APRIL,
    SchedulerMonthsEnum.MAY,
    SchedulerMonthsEnum.JUNE,
    SchedulerMonthsEnum.JULY,
    SchedulerMonthsEnum.AUGUST,
    SchedulerMonthsEnum.SEPTEMBER,
    SchedulerMonthsEnum.OCTOBER,
    SchedulerMonthsEnum.NOVEMBER,
    SchedulerMonthsEnum.DECEMBER,
];

export const days = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
];

export const weekNumber = ['1', '2', '3', '4'];
