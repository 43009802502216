import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { TNavigatorTreeSearchState } from '../reducers/navigatorTreeSearch.reducer.types';
import { NodeId } from '../serverapi/api';

export const navigatorTreeSearchStateSelector = (state: TRootState) => state.navigatorTreeSearch;

export namespace NavigatorTreeSearchSelector {
    export const getIsSearchActive = createSelector<TRootState, TNavigatorTreeSearchState, boolean>(
        navigatorTreeSearchStateSelector,
        (state) => state?.isActive || false,
    );

    export const getSearchString = createSelector<TRootState, TNavigatorTreeSearchState, string>(
        navigatorTreeSearchStateSelector,
        (state) => state?.searchString || '',
    );

    export const getFoundedNodeIds = createSelector<TRootState, TNavigatorTreeSearchState, NodeId[]>(
        navigatorTreeSearchStateSelector,
        (state) => state?.foundNodeIds || [],
    );

    export const getSelectedFoundNodeId = createSelector<TRootState, TNavigatorTreeSearchState, NodeId | undefined>(
        navigatorTreeSearchStateSelector,
        (state) => state?.selectedFoundNodeId,
    );
}
