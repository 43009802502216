import { Alert } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { ConflictResolutionStrategy } from '../../../../../services/api/custom/TransferApi';
import { Dialog } from '../../../../UIKit/components/Dialog/Dialog.component';
import { TImportPresetDialogProps, TImportPresetDialogState } from './ImportPresetDialog.types';
import messages from './ImportPresetDialog.messages';
import { DialogFooterButtons } from '../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';

class ImportPresetDialog extends React.Component<TImportPresetDialogProps, TImportPresetDialogState> {
    state = {
        resolutionStrategy: ConflictResolutionStrategy.REPLACE,
    };

    handleMethodChange = (value: ConflictResolutionStrategy) => {
        this.setState({
            resolutionStrategy: value,
        });
    };

    onHandleOk = () => {
        this.props.onSubmit(
            this.state.resolutionStrategy,
            this.props.serverNode,
            this.props.fileName,
            this.props.process,
        );
    };

    render() {
        const { intl } = this.props;
        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: this.props.onCancel,
                        value: intl.formatMessage(messages.cancelButton),
                    },
                    {
                        key: 'ok',
                        onClick: this.onHandleOk,
                        value: intl.formatMessage(messages.conflictDialogForNodeButton),
                        visualStyle: 'primary',
                    },
                ]}
            />
        );

        const radioOptions: TRadioOption<ConflictResolutionStrategy>[] = [
            {
                title: intl.formatMessage(messages.conflictDialogReplaceRadio),
                value: ConflictResolutionStrategy.REPLACE,
            },
            {
                title: intl.formatMessage(messages.conflictDialogCloneRadio),
                value: ConflictResolutionStrategy.CLONE,
            }
        ];

        return (
            <Dialog
                open
                title={`${intl.formatMessage(messages.dialogLabel)} ${this.props.conflictingPreset.name}`}
                closable={false}
                onOk={this.onHandleOk}
                onCancel={this.props.onCancel}
                footer={footer}
            >
                {/* todo 2979 удалить алерт */}
                {this.props.error && <Alert type="error" message={intl.formatMessage(this.props.error)} showIcon />}
                <RadioGroup<ConflictResolutionStrategy>
                    value={this.state.resolutionStrategy}
                    onChange={this.handleMethodChange}
                    options={radioOptions}
                    dataTest="import_conflict"
                    margin={8}
                />
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ImportPresetDialog);

export { IntlComponent as ImportPresetDialog };
