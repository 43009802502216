import { TObjectTypesForLink } from '@/services/bll/ExternalLinkBLLService.types';
import { COPY_ITEM_LINK, COPY_MODEL_IMAGE_LINK } from '../actionsTypes/modelLink.actionTypes';
import { NodeId } from '../serverapi/api';
import { TCopyLinkAction, TCopyModelImageLinkAction } from './copyLink.actions.types';

export const copyLinkAction = (nodeId: NodeId, type: TObjectTypesForLink, elementId?: string): TCopyLinkAction => ({
    type: COPY_ITEM_LINK,
    payload: {
        nodeId,
        type,
        elementId,
    },
});

export const copyModelImageLinkAction = (nodeId: NodeId): TCopyModelImageLinkAction => ({
    type: COPY_MODEL_IMAGE_LINK,
    payload: {
        nodeId,
    },
});
