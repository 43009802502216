// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardCellsCountDialog__dialog__AHTnj .ant-modal-header{border-radius:8px}.DashboardCellsCountDialog__dialog__AHTnj .ant-modal-content{border-radius:8px}.DashboardCellsCountDialog__dialog__AHTnj .ant-modal-title{font-weight:600;font-size:20px;line-height:28px}.DashboardCellsCountDialog__dialog__AHTnj .DashboardCellsCountDialog__button___Uf7n{border-radius:4px}.DashboardCellsCountDialog__dialog__AHTnj .DashboardCellsCountDialog__label__J94ri{font-size:12px;line-height:18px}.DashboardCellsCountDialog__dialog__AHTnj .DashboardCellsCountDialog__input__g5CgR{border-radius:4px;margin-top:4px}.DashboardCellsCountDialog__dialog__AHTnj .DashboardCellsCountDialog__error___3FU5{color:red;margin-top:5px}", "",{"version":3,"sources":["webpack://./src/modules/Dashboard/DashboardCellsCountDialog/DashboardCellsCountDialog.scss"],"names":[],"mappings":"AAEQ,4DACI,iBAAA,CAEJ,6DACI,iBAAA,CAEJ,2DACI,eAAA,CACA,cAAA,CACA,gBAAA,CAGR,oFACI,iBAAA,CAEJ,mFACI,cAAA,CACA,gBAAA,CAEJ,mFACI,iBAAA,CACA,cAAA,CAEJ,mFACI,SAAA,CACA,cAAA","sourcesContent":[".dialog {\r\n    :global {\r\n        .ant-modal-header {\r\n            border-radius: 8px;\r\n        }\r\n        .ant-modal-content {\r\n            border-radius: 8px;\r\n        }\r\n        .ant-modal-title {\r\n            font-weight: 600;\r\n            font-size: 20px;\r\n            line-height: 28px;\r\n        }\r\n    }\r\n    .button {\r\n        border-radius: 4px;\r\n    }\r\n    .label {\r\n        font-size: 12px;\r\n        line-height: 18px;\r\n    }\r\n    .input {\r\n        border-radius: 4px;\r\n        margin-top: 4px;\r\n    }\r\n    .error {\r\n        color: red;\r\n        margin-top: 5px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "DashboardCellsCountDialog__dialog__AHTnj",
	"button": "DashboardCellsCountDialog__button___Uf7n",
	"label": "DashboardCellsCountDialog__label__J94ri",
	"input": "DashboardCellsCountDialog__input__g5CgR",
	"error": "DashboardCellsCountDialog__error___3FU5"
};
export default ___CSS_LOADER_EXPORT___;
