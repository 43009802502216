import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormInstance } from 'antd';
import { LdapProperties } from '../../../../../serverapi/api';
import { LdapPropertiesComponent } from './LdapProperties.component';
import messages from '../../SystemProperties.messages';
import { v4 as uuid } from 'uuid';
import theme from '../../SystemProperties.scss';
import { SystemPropertiesFormItemNames, TLdapFormRef } from '../../SystemProperties.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TLdapSettingsProps = {
    form: FormInstance<any> | null;
    isNeedValidateLdap: boolean;
    setIsNeedValidateLdap: (isNeedValidateLdap: boolean) => any;
};

const LdapFormRefs: TLdapFormRef = {};

export const LdapSettingsComponent = (props: TLdapSettingsProps) => {
    const intl = useIntl();
    const { form, isNeedValidateLdap, setIsNeedValidateLdap } = props;
    const [reLoadForm, setReloadForm] = useState(false);
    // изменения ldapSettings записываются в основную форму в Form.Item name="ldapSettings"
    // Form.Item name="ldapSettings" создан внутри формы в SystemProperties.component (а не в текущем компоненте), т.к. LdapSettingsComponent находится вне формы в SystemProperties.component
    const ldapPropertiesMas: LdapProperties[] = form?.getFieldValue(SystemPropertiesFormItemNames.ldapSettings) || [];

    const handleRemoveLdapServer = (index: number) => {
        const newLdapSettings = form?.getFieldValue(SystemPropertiesFormItemNames.ldapSettings);
        const resultSettings = newLdapSettings.filter((item, ind) => ind !== index);
        form?.setFieldValue(SystemPropertiesFormItemNames.ldapSettings, resultSettings);
        setReloadForm(true);
    };

    const handleAddLdapServer = () => {
        const newLdapSettings = form?.getFieldValue(SystemPropertiesFormItemNames.ldapSettings);
        newLdapSettings.push({ id: uuid() });
        form?.setFieldValue(SystemPropertiesFormItemNames.ldapSettings, newLdapSettings);
        setReloadForm(true);
        setIsNeedValidateLdap(false);
    };

    const handleChangeProperies = (value: LdapProperties, index: number) => {
        const newLdapSettings = form?.getFieldValue(SystemPropertiesFormItemNames.ldapSettings);
        newLdapSettings[index] = value;
        form?.setFieldValue(SystemPropertiesFormItemNames.ldapSettings, newLdapSettings);
    };

    useEffect(() => {
        if (reLoadForm) setReloadForm(false);
    }, [reLoadForm]);

    useEffect(() => {
        if (reLoadForm) setReloadForm(false);
    }, [reLoadForm]);

    return (
        <div className={theme.formTab}>
            {ldapPropertiesMas.map((p, index) => {
                return (
                    <LdapPropertiesComponent
                        form={form}
                        key={uuid()}
                        index={index}
                        onRemoveServer={() => handleRemoveLdapServer(index)}
                        onChangeSetter={(value: LdapProperties) => handleChangeProperies(value, index)}
                        ldapSettings={ldapPropertiesMas}
                        isNeedValidateLdap={isNeedValidateLdap}
                        LdapFormRefs={LdapFormRefs}
                    />
                );
            })}
            <div className={theme.addLdapServerBtnContainer}>
                <Button dataTest="LDAP_add-server_button" onClick={handleAddLdapServer}>
                    {ldapPropertiesMas.filter((s) => s).length
                        ? intl.formatMessage(messages.ldapAddAnotherServer)
                        : intl.formatMessage(messages.ldapAddServer)}
                </Button>
            </div>
        </div>
    );
};
