import { ADD_MESSAGES, LOAD_MESSAGES_REQUEST } from '@/actionsTypes/messages.actionTypes';
import { TAddMessagesAction, TLoadMessagesAction } from './messages.actions.types';
import { MailMessageDTO } from '@/serverapi/api';

export const loadMessagesAction = (): TLoadMessagesAction => ({
    type: LOAD_MESSAGES_REQUEST,
});

export const addMessagesAction = (messages: MailMessageDTO[]): TAddMessagesAction => ({
    type: ADD_MESSAGES,
    payload: { messages },
});

