import { useDispatch } from 'react-redux';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { useIntl } from 'react-intl';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import React from 'react';
import { Browsers } from '../../../services/consts/Browser.const';
import theme from './CheckBrowserDialog.scss';
import messages from './CheckBrowserDialog.messages';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TCheckBrowserDialig = {
    isUnsupportedBrowser?: boolean;
    name?: Browsers;
    currentVersion?: string;
    minVersion?: string;
};

export const CheckBrowserDialog = (props: TCheckBrowserDialig) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { isUnsupportedBrowser, name, currentVersion, minVersion } = props;

    const close = () => {
        dispatch(closeDialog(DialogType.CHECK_BROWSER_DIALOG));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'ok',
                    onClick: close,
                    value: intl.formatMessage(messages.continue),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog footer={footer} closable={false} open>
            <div className={theme.about}>
                <h3 className={theme.title}>{intl.formatMessage(messages.warning)}</h3>
                <p className={theme.paragraph}>
                    {isUnsupportedBrowser
                        ? intl.formatMessage(messages.unsupportedBrowser, { name })
                        : intl.formatMessage(messages.oldBrowserVersion, { name, version: currentVersion, minVersion })}
                </p>
            </div>
        </Dialog>
    );
};
