import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../../../../Spinner/Spinner.component';
import messages from '../../messages/ImportVisio.messages';
import { ImportVisioCollapseTable } from '../ImportVisioCollapseTable/ImportVisioCollapseTable';
import style from './ImportVisio.component.scss';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

export const ImportVisio = (props) => {
    const { addFiles, resetStoreData, isLoading } = props;

    useEffect(() => {
        return () => {
            resetStoreData();
        };
    }, []);

    return (
        <Spinner loading={isLoading} data-test="import-visio_spinner">
            <div className={style.container} data-test="import-visio_container">
                <Button visualStyle="primary" onClick={addFiles} dataTest="import-visio_add-file_button">
                    <FormattedMessage {...messages.importButtonText} />
                </Button>
                <ImportVisioCollapseTable data-test="import-visio_table"/>
            </div>
        </Spinner>
    );
};
