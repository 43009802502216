import { NodeStatistics, TTableData } from '@/modules/ObjectPropertiesDialog/components/Statistics/StatisticsTab.types';
import { TNodeStatisticsState } from '@/reducers/nodeStatisticsReducer.types';
import { TRootState } from '@/reducers/root.reducer.types';
import { NodeId, NodeStats } from '@/serverapi/api';
import { createSelector } from 'reselect';

const nodeStatisticsStateSelector = (state: TRootState) => state.nodeStatistics;

export namespace NodeStatisticsSelectors {
    export const byNodeId = (nodeId: NodeId) =>
        createSelector<TRootState, TNodeStatisticsState, NodeStats | undefined>(
            nodeStatisticsStateSelector,
            (state): NodeStats | undefined => {
                return state.byRepositoryId[nodeId.repositoryId]?.byId[nodeId.id];
            },
        );
    export const nodeStatisticsTableData = (nodeId: NodeId) =>
        createSelector<TRootState, NodeStats | undefined, TTableData[] | undefined>(
            byNodeId(nodeId),
            (nodeStatistics: NodeStats | undefined) => {
                if (!nodeStatistics) return;
                return [
                    {
                        key: NodeStatistics.modelsCount,
                        name: NodeStatistics.modelsCount,
                        count: nodeStatistics.modelsCount,
                        deletedCount: nodeStatistics.deletedModelsCount,
                    },
                    {
                        key: NodeStatistics.objectsCount,
                        name: NodeStatistics.objectsCount,
                        count: nodeStatistics.objectsCount,
                        deletedCount: nodeStatistics.deletedObjectsCount,
                    },
                    {
                        key: NodeStatistics.filesCount,
                        name: NodeStatistics.filesCount,
                        count: nodeStatistics.filesCount,
                        deletedCount: nodeStatistics.deletedFilesCount,
                    },
                    {
                        key: NodeStatistics.foldersCount,
                        name: NodeStatistics.foldersCount,
                        count: nodeStatistics.foldersCount,
                        deletedCount: nodeStatistics.deletedFoldersCount,
                    },
                    {
                        key: NodeStatistics.otherNodesCount,
                        name: NodeStatistics.otherNodesCount,
                        count: nodeStatistics.otherNodesCount,
                        deletedCount: nodeStatistics.deletedOtherNodesCount,
                    },
                ];
            },
        );
}

