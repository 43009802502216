import { Form, Select, Table } from 'antd';
import React, { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useChangeNodeAttributeValue } from '../../../hooks/useChangeNodeAttributeValue';
import iconAdd from '@/resources/icons/ic-add.svg';
import icDelete from '@/resources/icons/Deleted.svg';
import cx from 'classnames';
import {
    AttributeType,
    AttributeValueNode,
    AttributeValueString,
    InternationalString,
    Node,
    NodeId,
    ObjectDefinitionNode,
} from '../../../serverapi/api';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import messages from '../messages/ObjectPropertiesDialog.messages';
import theme from './ObjectPropertiesDialog.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AttributeTypeSelectors } from '../../../selectors/attributeType.selectors';
import { LocalesService } from '../../../services/LocalesService';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { EditableText } from '../../UIKit/components/EditableText/EditableText.component';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { TValueTypeEnum } from '../../../models/ValueTypeEnum.types';
import { getUmlColumnId, getUmlObjectIdFromColumnId, storageValueToString, UML_STRING_COLUMN_KEY } from './utils';
import { openAttributeLinkAction } from '../../../actions/openAttributeLink.actions';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { useIntl } from 'react-intl';
import { UML_ID_SYMBOL, UML_OBJECT_TYPE } from '../../../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import { UML_ATTR_PARAMETER } from '../../../mxgraph/ComplexSymbols/symbols/UML/ClassSymbol/classSymbol.constants';
import { TreeItemType } from '../../Tree/models/tree';
import { TAttributeValueRecord } from './AttributeTab.types';
import { ChangePositionButtons } from '../../ChangePositionButtons/ChangePositionButtons.component';
import { upElementIdInArray } from '../../../utils/upElementIdInArray.utils';
import { MultiLangEditableText } from '../../UIKit/components/MultiLangEditableText/MultiLangEditableText.component';
import icEdit from '@/resources/icons/edit.svg';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TClassMethodParametersProps = {
    nodeId: NodeId;
    parentNodeId: NodeId;
    classMethodParameterObjects: ObjectDefinitionNode[];
    onChangeClassMethodParameterObjects: (objects: ObjectDefinitionNode[]) => void;
    deletedClassObjectNodeIds: NodeId[];
    onDeleteClassObjectNodeIds: (nodeIds: NodeId[]) => void;
};

type TAttributeValueStringRecord = {
    id: string;
    attributeValue?: AttributeValueString;
};

type TAttributeValueNodeRecord = {
    id: string;
    attributeValue?: AttributeValueNode;
};

type TColumnsData = {
    multilingualName: AttributeValueString;
    typeString: TAttributeValueStringRecord;
    typeEnum: TAttributeValueRecord;
    typeLink: TAttributeValueNodeRecord;
    defValue: TAttributeValueStringRecord;
    key: string;
};

export const ClassMethodParameters: FC<TClassMethodParametersProps> = ({
    nodeId,
    parentNodeId,
    classMethodParameterObjects,
    onChangeClassMethodParameterObjects,
    deletedClassObjectNodeIds,
    onDeleteClassObjectNodeIds,
}) => {
    const { serverId, repositoryId } = nodeId;
    const dispatch = useDispatch();
    const intl = useIntl();
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const currentLocale = useSelector(getCurrentLocale);
    const [selectedMethodParameterIds, setSelectedMethodParameterIds] = useState<string[]>([]);

    const parameterObjectsData = classMethodParameterObjects.filter((obj) => obj.parentNodeId?.id === parentNodeId.id);
    const columnsData = parameterObjectsData.map((obj) => {
        const multilingualName =
            obj.type === TreeItemType.ObjectDefinition ? (obj as Node).multilingualName : undefined;
        const typeString = obj.attributes?.find((attr) => attr.typeId === UML_ATTR_PARAMETER.STRING);
        const typeEnum = obj.attributes?.find((attr) => attr.typeId === UML_ATTR_PARAMETER.ENUM);
        const typeLink = obj.attributes?.find((attr) => attr.typeId === UML_ATTR_PARAMETER.LINK);
        const defValue = obj.attributes?.find((attr) => attr.typeId === UML_ATTR_PARAMETER.DEF_VALUE);

        const record: TColumnsData = {
            multilingualName: {
                typeId: 'SYSTEM',
                value: LocalesService.internationalStringToString(multilingualName),
                str: multilingualName,
                valueType: 'STRING',
                id: getUmlColumnId(obj.nodeId.id, UML_STRING_COLUMN_KEY.MULTILINGUAL_NAME),
            },
            typeString: { id: obj.nodeId.id, attributeValue: typeString },
            typeEnum: { id: obj.nodeId.id, attributeValue: typeEnum },
            typeLink: { id: obj.nodeId.id, attributeValue: typeLink },
            defValue: {
                id: getUmlColumnId(obj.nodeId.id, UML_STRING_COLUMN_KEY.DEF_VALUE),
                attributeValue: defValue,
            },
            key: obj.nodeId.id,
        };

        return record;
    });

    const attributeTypes: AttributeType[] = useSelector(AttributeTypeSelectors.allInPreset(nodeId.serverId, presetId));

    const handleChangeSelectAttributeValue = (
        record: TAttributeValueRecord,
        value: string | undefined,
        attrTypeId: string | undefined,
        valueType: TValueTypeEnum,
    ) => {
        const changedObjects = classMethodParameterObjects.map((obj) => {
            if (obj.nodeId.id !== record.id) return obj;

            if (!record.attributeValue) {
                const oldAttributes = obj.attributes || [];

                return {
                    ...obj,
                    attributes: [
                        ...oldAttributes,
                        {
                            id: uuid(),
                            typeId: attrTypeId,
                            value,
                            valueType,
                        },
                    ],
                } as ObjectDefinitionNode;
            }

            const changedAttributes = obj.attributes?.map((attr) => {
                if (attr.typeId === attrTypeId) {
                    return {
                        ...attr,
                        value,
                    };
                }

                return attr;
            });

            return { ...obj, attributes: changedAttributes } as ObjectDefinitionNode;
        });

        onChangeClassMethodParameterObjects(changedObjects);
    };

    const handleChangeObjectDefinitionName = (id: string, value: InternationalString | string, columnKey: string) => {
        const objectId = getUmlObjectIdFromColumnId(id, columnKey);

        const changedObjects = classMethodParameterObjects.map((obj) => {
            if (obj.nodeId.id !== objectId) return obj;
            const multilingualName =
                obj.type === TreeItemType.ObjectDefinition ? (obj as Node).multilingualName : undefined;
            const newVal =
                typeof value === 'string'
                    ? {
                          ...multilingualName,
                          [currentLocale]: value,
                      }
                    : value;

            return {
                ...obj,
                multilingualName: newVal,
            } as ObjectDefinitionNode;
        });

        onChangeClassMethodParameterObjects(changedObjects);
    };

    const handleChangeStringAttributeValue = (
        id: string,
        valueType: TValueTypeEnum,
        value: InternationalString | string,
    ) => {
        const changedObjects = classMethodParameterObjects.map((obj) => {
            if (obj.nodeId.id !== id) return obj;

            if (!obj.attributes?.find((attr) => attr.typeId === UML_ATTR_PARAMETER.STRING)) {
                const newStr = typeof value === 'string' ? { [currentLocale]: value } : value;
                const oldAttributes = obj.attributes || [];

                return {
                    ...obj,
                    attributes: [
                        ...oldAttributes,
                        {
                            id: uuid(),
                            typeId: UML_ATTR_PARAMETER.STRING,
                            str: newStr,
                            value: LocalesService.internationalStringToString(newStr),
                            valueType,
                        },
                    ],
                } as ObjectDefinitionNode;
            }

            const changedAttributes = obj.attributes.map((attr) => {
                if (attr.typeId === UML_ATTR_PARAMETER.STRING) {
                    const newStr =
                        typeof value === 'string'
                            ? LocalesService.changeLocaleValue((attr as AttributeValueString).str, currentLocale, value)
                            : value;

                    return {
                        ...attr,
                        str: newStr,
                        value: LocalesService.internationalStringToString(newStr),
                    };
                }

                return attr;
            });

            return { ...obj, attributes: changedAttributes } as ObjectDefinitionNode;
        });

        onChangeClassMethodParameterObjects(changedObjects);
    };

    const handleChangeNodeAttributeValue = (id: string, valueType: TValueTypeEnum, value: any) => {
        const { nodeId, multilingualName, type, name } = value as Node;
        const changedObjects = classMethodParameterObjects.map((obj) => {
            if (obj.nodeId.id !== id) return obj;

            if (!obj.attributes?.find((attr) => attr.typeId === UML_ATTR_PARAMETER.LINK)) {
                const oldAttributes = obj.attributes || [];

                return {
                    ...obj,
                    attributes: [
                        ...oldAttributes,
                        {
                            id: uuid(),
                            value: '',
                            linkedNodeId: nodeId?.id,
                            name: LocalesService.changeLocaleValue(multilingualName, currentLocale, name),
                            nodeType: type,
                            typeId: UML_ATTR_PARAMETER.LINK,
                            valueType: 'NODE',
                            notFound: false,
                        },
                    ],
                } as ObjectDefinitionNode;
            }

            const changedAttributes = obj.attributes.map((attr) => {
                if (attr.typeId === UML_ATTR_PARAMETER.LINK) {
                    return {
                        ...attr,
                        value: '',
                        linkedNodeId: nodeId?.id,
                        name: LocalesService.changeLocaleValue(multilingualName, currentLocale, name),
                        nodeType: type,
                    } as AttributeValueNode;
                }

                return attr;
            });

            return { ...obj, attributes: changedAttributes } as ObjectDefinitionNode;
        });
        onChangeClassMethodParameterObjects(changedObjects);
    };

    const { setNewNodeId, setNodeAttributeToChange } = useChangeNodeAttributeValue(handleChangeNodeAttributeValue);

    const changeNodeAttribute = (nodeId: NodeId, nodeAttribute: AttributeValueNode): void => {
        setNodeAttributeToChange(nodeAttribute);
        setNewNodeId(nodeId);
    };

    const clearNodeAttributeValue = (id: string) => {
        const changedObjects = classMethodParameterObjects.map((obj) => {
            if (obj.nodeId.id !== id || !obj.attributes?.find((attr) => attr.typeId === UML_ATTR_PARAMETER.LINK))
                return obj;

            const changedAttributes = obj.attributes.map((attr) => {
                if (attr.typeId === UML_ATTR_PARAMETER.LINK) {
                    return {
                        id: attr.id,
                        value: '',
                        name: {},
                        notFound: true,
                        typeId: attr.typeId,
                        valueType: attr.valueType,
                    } as AttributeValueNode;
                }

                return attr;
            });

            return { ...obj, attributes: changedAttributes } as ObjectDefinitionNode;
        });
        onChangeClassMethodParameterObjects(changedObjects);
    };

    const handleChangeDefaultAttributeValue = (
        id: string,
        valueType: TValueTypeEnum,
        value: InternationalString | string,
        columnKey: string,
    ) => {
        const objectId = getUmlObjectIdFromColumnId(id, columnKey);
        const changedObjects = classMethodParameterObjects.map((obj) => {
            if (obj.nodeId.id !== objectId) return obj;

            if (!obj.attributes?.find((attr) => attr.typeId === UML_ATTR_PARAMETER.DEF_VALUE)) {
                const newStr = typeof value === 'string' ? { [currentLocale]: value } : value;
                const oldAttributes = obj.attributes || [];

                return {
                    ...obj,
                    attributes: [
                        ...oldAttributes,
                        {
                            id: uuid(),
                            typeId: UML_ATTR_PARAMETER.DEF_VALUE,
                            str: newStr,
                            value: LocalesService.internationalStringToString(newStr),
                            valueType,
                        },
                    ],
                } as ObjectDefinitionNode;
            }

            const changedAttributes = obj.attributes.map((attr) => {
                if (attr.typeId === UML_ATTR_PARAMETER.DEF_VALUE) {
                    const newStr =
                        typeof value === 'string'
                            ? LocalesService.changeLocaleValue((attr as AttributeValueString).str, currentLocale, value)
                            : value;

                    return {
                        ...attr,
                        str: newStr,
                        value: LocalesService.internationalStringToString(newStr),
                    };
                }

                return attr;
            });

            return { ...obj, attributes: changedAttributes } as ObjectDefinitionNode;
        });

        onChangeClassMethodParameterObjects(changedObjects);
    };

    const handleAddMethodParameter = () => {
        const newProperty = {
            attributes: [],
            children: [],
            idSymbol: UML_ID_SYMBOL.PARAMETER,
            objectTypeId: UML_OBJECT_TYPE.PARAMETER,
            modelAssignments: [],
            description: { ru: '', en: '' },
            multilingualName: {
                [currentLocale]: intl.formatMessage(messages.newParameter),
            },
            name: intl.formatMessage(messages.newParameter),
            nodeId: { id: uuid(), repositoryId, serverId },
            parentNodeId,
            objectEntries: [],
            type: TreeItemType.ObjectDefinition,
        } as ObjectDefinitionNode;
        onChangeClassMethodParameterObjects([...classMethodParameterObjects, newProperty]);
    };

    const handleDeleteMethodParameter = () => {
        const objectNodeIdsToDelete = classMethodParameterObjects
            .filter((obj) => selectedMethodParameterIds.includes(obj.nodeId.id))
            .map((obj) => obj.nodeId);
        onChangeClassMethodParameterObjects(
            classMethodParameterObjects.filter((obj) => !selectedMethodParameterIds.includes(obj.nodeId.id)),
        );
        onDeleteClassObjectNodeIds([...deletedClassObjectNodeIds, ...objectNodeIdsToDelete]);
    };

    const handleClickMethodParameter = (selectedId: string) => {
        if (selectedMethodParameterIds.includes(selectedId)) {
            setSelectedMethodParameterIds(selectedMethodParameterIds.filter((id) => id !== selectedId));
        } else {
            setSelectedMethodParameterIds([...selectedMethodParameterIds, selectedId]);
        }
    };

    const onChangePosition = (id: number) => {
        onChangeClassMethodParameterObjects([
            ...classMethodParameterObjects.filter((obj) => obj.parentNodeId?.id !== parentNodeId.id),
            ...upElementIdInArray(parameterObjectsData, id),
        ]);
    };

    return (
        <FormGroup>
            <Form.Item>
                <Table
                    dataSource={columnsData}
                    className={theme.table}
                    onRow={(row) => ({
                        onClick: () => {
                            handleClickMethodParameter(row.key);
                        },
                    })}
                    rowClassName={(row: { key: string }) =>
                        cx(theme.attribute, {
                            [theme.attribute_selected]: selectedMethodParameterIds.indexOf(row.key) !== -1,
                        })
                    }
                    size="middle"
                    bordered
                    pagination={false}
                >
                    <Table.Column
                        width={180}
                        title={intl.formatMessage(messages.parameterName)}
                        dataIndex="multilingualName"
                        key="multilingualName"
                        render={(record: AttributeValueString) => {
                            const text: string | undefined = LocalesService.internationalStringToString(record.str);

                            return (
                                <div className={theme.editableTextContainer}>
                                    <MultiLangEditableText
                                        text={text}
                                        disabled={false}
                                        onChange={(value: string | InternationalString) =>
                                            handleChangeObjectDefinitionName(
                                                record.id,
                                                value,
                                                UML_STRING_COLUMN_KEY.MULTILINGUAL_NAME,
                                            )
                                        }
                                        allowEmptyValue={false}
                                        inputType="text"
                                        record={record}
                                        clearSelecting={() => setSelectedMethodParameterIds([])}
                                        dataTestContainer="field-with-class-parameter-name"
                                        dataTestBtn="change-parameter-name_btn"
                                    />
                                </div>
                            );
                        }}
                    />
                    <Table.ColumnGroup title={intl.formatMessage(messages.parameterType)}>
                        <Table.Column
                            width={180}
                            title={intl.formatMessage(messages.manualFilling)}
                            dataIndex="typeString"
                            key="typeString"
                            render={(record: TAttributeValueStringRecord) => {
                                const text: string | undefined = LocalesService.internationalStringToString(
                                    record.attributeValue?.str,
                                );

                                return (
                                    <div className={theme.editableTextContainer}>
                                        <MultiLangEditableText
                                            text={text}
                                            disabled={false}
                                            onChange={(value: string | InternationalString) =>
                                                handleChangeStringAttributeValue(record.id, 'STRING', value)
                                            }
                                            allowEmptyValue
                                            inputType="text"
                                            dataTestContainer="field-with-class-parameter-type-string"
                                            dataTestBtn="change-parameter-type-string_btn"
                                            record={
                                                record.attributeValue || {
                                                    id: uuid(),
                                                    typeId: UML_ATTR_PARAMETER.STRING,
                                                    value: '',
                                                    valueType: 'STRING',
                                                    str: { ru: '', en: '' },
                                                }
                                            }
                                            clearSelecting={() => setSelectedMethodParameterIds([])}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Table.Column
                            className={theme.classObjectColumn}
                            width={120}
                            title={intl.formatMessage(messages.selectFromList)}
                            dataIndex="typeEnum"
                            key="typeEnum"
                            render={(record: TAttributeValueRecord) => {
                                const attributeType = attributeTypes.find(
                                    (attrType) => attrType.id === UML_ATTR_PARAMETER.ENUM,
                                );

                                const attributeTypeValue = attributeType?.selectPropertyValues?.find(
                                    (type) => type.id === record.attributeValue?.value,
                                );

                                const currentValue = LocalesService.internationalStringToString(
                                    attributeTypeValue?.value,
                                );
                                const defaultValue = currentValue || undefined;

                                return (
                                    <div className={theme.editableElementMedium}>
                                        <Select
                                            defaultValue={defaultValue}
                                            allowClear
                                            data-test="change-parameter-type-list_select"
                                            onChange={(value) =>
                                                handleChangeSelectAttributeValue(
                                                    record,
                                                    value,
                                                    attributeType?.id,
                                                    'SELECT',
                                                )
                                            }
                                        >
                                            {attributeType?.selectPropertyValues?.map((v) => (
                                                <Select.Option key={v.id} value={v.id}>
                                                    {LocalesService.internationalStringToString(v.value) || ''}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                );
                            }}
                        />
                        <Table.Column
                            width={120}
                            title={intl.formatMessage(messages.selectFromNavigator)}
                            dataIndex="typeLink"
                            key="typeLink"
                            render={(record: TAttributeValueNodeRecord) => {
                                const nodeRecord: AttributeValueNode = record.attributeValue as AttributeValueNode;
                                const nodePath = storageValueToString(nodeRecord, currentLocale) || '';
                                const propertyNodeId = classMethodParameterObjects.find(
                                    (obj) => obj.nodeId.id === record.id,
                                )?.nodeId;

                                const onClickIcon = () =>
                                    dispatch(
                                        openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                                            serverId,
                                            repositoryId,
                                            disableContextMenu: true,
                                            isTreeWithClearButton: true,
                                            onSubmit: (nodeId: NodeId) =>
                                                changeNodeAttribute(nodeId, { ...nodeRecord, id: record.id }),
                                            onClear: () => {
                                                clearNodeAttributeValue(record.id);
                                            },
                                        }),
                                    );

                                const onClickLink = () => dispatch(openAttributeLinkAction(nodeRecord, propertyNodeId));

                                return (
                                    <div className={theme.editableTextContainer}>
                                        <div className={theme.editableElementLarge}>
                                            <EditableText
                                                className={theme.linkEditableTextContainer}
                                                text={nodePath}
                                                isEditing={false}
                                                disabled={false}
                                                allowEmptyValue
                                                onClickLink={onClickLink}
                                                isUrlType
                                            />
                                        </div>
                                        <Button icon={icEdit} onClick={onClickIcon} dataTest="change-parameter-type-link_btn" />
                                    </div>
                                );
                            }}
                        />
                    </Table.ColumnGroup>
                    <Table.Column
                        width={180}
                        title={intl.formatMessage(messages.defaultValue)}
                        dataIndex="defValue"
                        key="defValue"
                        render={(record: TAttributeValueStringRecord) => {
                            const text: string | undefined = LocalesService.internationalStringToString(
                                record.attributeValue?.str,
                            );

                            return (
                                <div className={theme.editableTextContainer}>
                                    <MultiLangEditableText
                                        text={text}
                                        disabled={false}
                                        onChange={(value: string | InternationalString) =>
                                            handleChangeDefaultAttributeValue(
                                                record.id,
                                                'STRING',
                                                value,
                                                UML_STRING_COLUMN_KEY.DEF_VALUE,
                                            )
                                        }
                                        allowEmptyValue
                                        inputType="text"
                                        dataTestContainer="field-with-class-parameter-default-value"
                                            dataTestBtn="change-parameter-default-value_btn"
                                        record={
                                            record.attributeValue || {
                                                id: uuid(),
                                                typeId: UML_ATTR_PARAMETER.DEF_VALUE,
                                                value: '',
                                                valueType: 'STRING',
                                                str: { ru: '', en: '' },
                                            }
                                        }
                                        clearSelecting={() => setSelectedMethodParameterIds([])}
                                    />
                                </div>
                            );
                        }}
                    />
                    <Table.Column
                        width={30}
                        title=""
                        dataIndex="typeEnum"
                        key="changePositionButtons"
                        render={(record: TAttributeValueRecord) => {
                            const index: number = parameterObjectsData.findIndex(
                                (node) => node.nodeId.id === record.id,
                            );

                            return (
                                <ChangePositionButtons
                                    upButtonDisabled={index === 0}
                                    downButtonDisabled={index === parameterObjectsData.length - 1}
                                    onUp={() => onChangePosition(index - 1)}
                                    onDown={() => onChangePosition(index)}
                                />
                            );
                        }}
                    />
                </Table>
                <div className={theme.attributeActions}>
                    <Button size="large" onClick={handleAddMethodParameter} dataTest="class-properties-window_add-parameter_btn" icon={iconAdd}>
                        {intl.formatMessage(messages.parameterAdd)}
                    </Button>
                    <div className={theme.attributeActionsInner}>
                        <Button
                            size="large"
                            onClick={handleDeleteMethodParameter}
                            disabled={!selectedMethodParameterIds.length}
                            icon={icDelete}
                            dataTest="class-properties-window_delete-parameter_btn"
                        >
                            {intl.formatMessage(messages.parametersDelete)}
                        </Button>
                    </div>
                </div>
            </Form.Item>
        </FormGroup>
    );
};
