// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FocusableElementWrapper__root___TUjs{display:-webkit-box;display:-ms-flexbox;display:flex}.FocusableElementWrapper__root___TUjs.FocusableElementWrapper__focus-visible___c_aM{outline-color:var(--blue-dark-UIKit);outline-width:2px;outline-offset:2px}.FocusableElementWrapper__root___TUjs:focus-visible{outline-color:var(--blue-dark-UIKit);outline-width:2px;outline-offset:2px}", "",{"version":3,"sources":["webpack://./src/modules/ObjectPropertiesDialog/components/FocusableElementWrapper.scss"],"names":[],"mappings":"AAAA,sCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEA,oFACI,oCAAA,CACA,iBAAA,CACA,kBAAA,CAHJ,oDACI,oCAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":[".root {\r\n    display: flex;\r\n\r\n    &:focus-visible {\r\n        outline-color: var(--blue-dark-UIKit);\r\n        outline-width: 2px;\r\n        outline-offset: 2px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FocusableElementWrapper__root___TUjs",
	"focus-visible": "FocusableElementWrapper__focus-visible___c_aM"
};
export default ___CSS_LOADER_EXPORT___;
