import React from 'react';
import messages from './ApprovalDialog.messages';
import { useIntl } from 'react-intl';
import theme from './ApprovalDialog.scss';
import { Checkbox } from 'antd';
import { TimeConstraintComponent } from './TimeConstraint.component';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { StagesBllService } from '@/services/bll/ApprovalDialogStagesBLLService';
import { ApprovalStageDTOAbstentionVoteStrategyEnum } from '@/serverapi/api';
import dayjs from 'dayjs';
import { momentDateToTimestamp } from '@/utils/date.time.utils';
import { TRateAndVoteStrategyProps } from './StageSettingsTab.types';

export const VoteStrategyAndAllowVoteChangeComponent = ({
    selectedStage,
    isEditingDisabled,
    isTemplate,
    stages,
    stageId,
    onChangeStages,
    stageFormRef,
}: TRateAndVoteStrategyProps) => {
    const intl = useIntl();

    const onChangeTimeConstraints = (e: CheckboxChangeEvent) => {
        const stageList = StagesBllService.changeTimeConstraints(stages, stageId, e.target.checked);

        onChangeStages(stageList);
    };

    const onChangeVoteAllowed = (e: CheckboxChangeEvent) => {
        const stageList = StagesBllService.changeVoteAllowed(stages, stageId, e.target.checked);

        onChangeStages(stageList);
    };

    const onVoteStrategyChange = (value: ApprovalStageDTOAbstentionVoteStrategyEnum) => {
        const stageList = StagesBllService.changeVoteStrategy(stages, stageId, value);

        onChangeStages(stageList);
    };

    const onFinishDateChange = (date: dayjs.Dayjs) => {
        const scheduledFinishDate: number | null = momentDateToTimestamp(date);
        const fieldsValue = stageFormRef.current?.getFieldsValue();
        if (fieldsValue) {
            fieldsValue.scheduledFinishDate = scheduledFinishDate || undefined;
            stageFormRef.current?.setFieldsValue(fieldsValue);
            if (!scheduledFinishDate) {
                stageFormRef.current?.validateFields(['scheduledFinishDate']);
            }
        }

        const stageList = StagesBllService.changeFinishDate(stages, stageId, scheduledFinishDate);

        onChangeStages(stageList);
    };

    return (
        <>
            <div className={theme.timeCheckboxContainer}>
                <Checkbox
                    disabled={!isTemplate && isEditingDisabled}
                    defaultChecked={!!selectedStage.abstentionVoteStrategy}
                    onChange={onChangeTimeConstraints}
                >
                    <span>{intl.formatMessage(messages.restrictVotingTime)}</span>
                </Checkbox>
            </div>
            {!!selectedStage.abstentionVoteStrategy ? (
                <TimeConstraintComponent
                    selectedStage={selectedStage}
                    isTemplate={isTemplate}
                    isEditingDisabled={isEditingDisabled}
                    onFinishDateChange={onFinishDateChange}
                    onVoteStrategyChange={onVoteStrategyChange}
                />
            ) : null}
            <Checkbox
                disabled={!isTemplate && isEditingDisabled}
                checked={selectedStage.changeVoteAllowed}
                onChange={onChangeVoteAllowed}
            >
                <span>{intl.formatMessage(messages.allowVoteChange)}</span>
            </Checkbox>
        </>
    );
};
