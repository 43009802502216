// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportDialog__itemType__MMcpj .ant-form-item-label label{white-space:normal;font-size:14px}.ImportDialog__itemType__MMcpj .ant-form-item-control-input-content{font-size:14px}", "",{"version":3,"sources":["webpack://./src/modules/ImportDialog/components/ImportDialog/ImportDialog.scss"],"names":[],"mappings":"AAEQ,0DACI,kBAAA,CACA,cAAA,CAEJ,oEACI,cAAA","sourcesContent":[".itemType {\r\n    :global {\r\n        .ant-form-item-label label {\r\n            white-space: normal;\r\n            font-size: 14px;\r\n        }\r\n        .ant-form-item-control-input-content {\r\n            font-size: 14px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemType": "ImportDialog__itemType__MMcpj"
};
export default ___CSS_LOADER_EXPORT___;
