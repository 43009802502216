import { NodeId } from '@/serverapi/api';
import { TWidgetGeometry } from './Dashboard.types';

export const calcWidgetGeometry = (
    widget: HTMLDivElement,
    cellSize: number,
    verticalCount: number,
    horizontalCount: number,
) => {
    const currentWidth = !isNaN(parseFloat(widget.style.width)) ? parseFloat(widget.style.width) : cellSize;
    const currentHeight = !isNaN(parseFloat(widget.style.height)) ? parseFloat(widget.style.height) : cellSize;

    const width = Math.min(Math.round(currentWidth / cellSize), verticalCount);
    const height = Math.min(Math.round(currentHeight / cellSize), horizontalCount);

    const maxX = verticalCount - width;
    const maxY = horizontalCount - height;
    const currentX = !isNaN(parseFloat(widget.style.left)) ? parseFloat(widget.style.left) : cellSize;
    const currentY = !isNaN(parseFloat(widget.style.top)) ? parseFloat(widget.style.top) : cellSize;

    const x = Math.min(Math.round(currentX / cellSize), maxX);
    const y = Math.min(Math.round(currentY / cellSize), maxY);

    const geometry: TWidgetGeometry = {
        x,
        y,
        height,
        width,
    };

    return geometry;
};

export const calcWidgetPosition = (
    newPosition: number,
    isHorizontal: boolean,
    sizeParams: { width: number; height: number; verticalCount: number; horizontalCount: number; cellSize: number },
) => {
    const { width, height, horizontalCount, verticalCount, cellSize } = sizeParams;
    const maxCellsCount = isHorizontal ? verticalCount - width : horizontalCount - height;
    const cellsCount = Math.min(Math.max(Math.floor((newPosition + cellSize / 2) / cellSize), 0), maxCellsCount);

    return cellsCount * cellSize;
};

export const calcWidgetSize = (
    newSize: number,
    isHorizontal: boolean,
    posParams: {
        x: number;
        y: number;
        verticalCount: number;
        horizontalCount: number;
        cellSize: number;
    },
) => {
    const { x, y, horizontalCount, verticalCount, cellSize } = posParams;
    const maxCellsCount = isHorizontal ? verticalCount - x : horizontalCount - y;
    const cellsCount = Math.min(Math.max(Math.floor((newSize + cellSize / 2) / cellSize), 1), maxCellsCount);

    return cellsCount * cellSize;
};

export const getDashboardSelectorKey = (nodeId: NodeId) => `dashboardEditor_${nodeId.id}`;

export const getDashboardContainer = (nodeId: NodeId) =>
    document.getElementById(`${getDashboardSelectorKey(nodeId)}`) as HTMLDivElement | null;

export const updateToolbarPosition = (
    editor: HTMLDivElement | null,
    toolbar: HTMLDivElement | null,
    dashboard: HTMLDivElement | null,
) => {
    if (editor && editor.parentElement && toolbar && dashboard) {
        const widgetContaier = editor.parentElement as HTMLDivElement;
        // равные отступы слева и справа для тулбара
        const leftOffset = (toolbar.clientWidth - widgetContaier.clientWidth) / 2;

        // если сободное пространство слева от виджета меньше leftOffset,
        // располагаем тулбар ровно по левому краю холста
        toolbar.style.left =
            leftOffset < widgetContaier.offsetLeft ? `${-leftOffset}px` : `${-widgetContaier.offsetLeft}px`;

        // расстояние на которое тулбал выпирает СПРАВА от виджета
        const toolbarRightOffset = widgetContaier.clientWidth - toolbar.offsetLeft - toolbar.clientWidth;
        // свободное расстояние СПРАВА от виджета
        const widgetRightEmptySpace = dashboard.clientWidth - widgetContaier.clientWidth - widgetContaier.offsetLeft;

        // расстояние на которое тулбар выходит СПРАВА за границы холста(если отрицательное)
        // или не выходит(если положительное)
        const rightOffset = toolbarRightOffset + widgetRightEmptySpace;

        // если тулбар выходить за ПРАВЫЙ край холста, смещаем его на это значение влево
        if (rightOffset < 0) {
            toolbar.style.left = `${toolbar.offsetLeft + rightOffset}px`;
        }

        // свободное пространство на холсте СНИЗУ от виджета
        const widgetBottomEmptySpace = dashboard.clientHeight - widgetContaier.offsetTop - widgetContaier.clientHeight;
        const toolbarMargin = 8;

        // если высота тулбара меньше свободного пространства СНИЗУ, переносим его ВВЕРХ
        toolbar.style.top =
            widgetBottomEmptySpace < toolbar.clientHeight + toolbarMargin
                ? `${-toolbar.clientHeight - toolbarMargin}px`
                : `${widgetContaier.clientHeight + toolbarMargin}px`;
    }
};

export const checkWikiEditorClick = (target: HTMLElement) => {
    const child = target.children[0];

    const checkTagName = target.tagName === 'SPAN' || target.tagName === 'IMG';

    const checkClassNames =
        target?.className?.split &&
        (target.className.split(' ').includes('public-DraftEditor-content') ||
            target.className.split(' ').includes('wiki-block') ||
            target.className.split(' ').includes('public-DraftStyleDefault-block') ||
            target.className.split(' ').some((className) => className.includes('WikiEditor-component')));

    const checkChildClassNames =
        child?.className?.split &&
        (child.className.split(' ').includes('wiki-block') ||
            child.className.split(' ').includes('public-DraftStyleDefault-block'));

    return checkTagName || checkClassNames || checkChildClassNames;
};
