import type { AttributeType, AttributeValue } from '../../../../serverapi/api';
import { ObjectDefinitionImpl } from '../../../../models/bpm/bpm-model-impl';
import {
    UML_STEREOTYPE_SPECIAL_END_CHARS,
    UML_STEREOTYPE_SPECIAL_START_CHARS,
    attributeVisibilities,
} from './UMLSymbols.constants';
import { LocalesService } from '../../../../services/LocalesService';
import { storageValueToString } from '../../../../modules/ObjectPropertiesDialog/components/utils';

type TSignatureOptions = {
    visibility?: AttributeValue;
    type?: AttributeValue | null;
    nodeAttributes?: AttributeType[];
    showVisibility?: boolean;
    parameters?: string;
    defaultValue?: string;
};

export const getStereotypeString = (stereotype?: string) =>
    stereotype ? `${UML_STEREOTYPE_SPECIAL_START_CHARS}${stereotype}${UML_STEREOTYPE_SPECIAL_END_CHARS}` : '';

export const getNestedChildren = (
    parent: ObjectDefinitionImpl,
    items: { [key: string]: ObjectDefinitionImpl },
    nestingDepth: number,
) => {
    const children = Object.values(items).filter((el) => el?.parentNodeId?.id === parent.nodeId.id);

    return children.length > 0 && nestingDepth !== 0
        ? children.map((el) => ({
              ...el,
              children: getNestedChildren(el, items, nestingDepth - 1),
          }))
        : [];
};

export const getObjectSignature = (
    name: string,
    { visibility, type, nodeAttributes, showVisibility = true, parameters, defaultValue }: TSignatureOptions = {},
): string => {
    const visibilityString = showVisibility
        ? `${visibility?.value ? attributeVisibilities[visibility?.value] : attributeVisibilities.a_package} `
        : '';
    const parametersString = parameters ? ` (${parameters})` : '';
    const mappedType = storageValueToString(type, LocalesService.currentLocale, {
        attributeTypes: nodeAttributes,
    });
    const typeString = type ? `: ${mappedType}` : '';
    const defaultValueString = defaultValue ? ` = ${defaultValue}` : '';

    return `${visibilityString}${name}${parametersString}${typeString}${defaultValueString}`;
};
