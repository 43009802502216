import { SET_PERSONAL_PROFILE } from '../actionsTypes/userAccount.actionTypes';
import { TReducer } from '../utils/types';
import { userAccountInitialState } from './userAccount.reducer.consts';
import { TUserAccountState } from './userAccount.reducer.types';

export const userAccountReducer: TReducer<TUserAccountState> = (state = userAccountInitialState, action) => {
    switch (action.type) {
        case SET_PERSONAL_PROFILE: {
            const { data } = action.payload;

            return { ...state, ...data };
        }

        default:
            return state;
    }
};
