import React from 'react';
import theme from './Wrapper.scss';
import { convertPaddingToValue } from './utils/styleProperties.utils';
import { TWrapper } from './Wrapper.types';
import classNames from 'classnames';

export const Wrapper = (props: TWrapper) => {
    const { children, padding, reverse } = props;
    return (
        <div className={classNames(theme.container, {[theme.reverse]:reverse})} style={{ padding: convertPaddingToValue(padding) }}>
            {children}
        </div>
    );
};
