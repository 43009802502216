import { Agreements } from '../../serverapi/api';
import { apiBundle } from '../api/api-bundle';

export class UserAgreementsDaoService {
    public static async getUserAgreements(serverId: string): Promise<Agreements> {
        const agreements: Agreements = await apiBundle(serverId).agreements.getAgreements();

        return agreements;
    }

    public static async confirmAgriment(serverId: string): Promise<void> {
        await apiBundle(serverId).agreements.confirm();
    }

    public static async confirmSilaAgriment(serverId: string): Promise<void> {
        await apiBundle(serverId).agreements.confirmSila();  
    }

    public static async deleteAgreement(serverId: string): Promise<void> {
        await apiBundle(serverId).agreements.confirm_1();  
    }

    public static async deleteSilaAgreement(serverId: string): Promise<void> {
        await apiBundle(serverId).agreements.confirm_2();  
    }
}
