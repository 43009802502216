import { TScriptQueryData } from '../../actions/scriptQueryParams.actions.types';
import { TQueryData } from '../../actions/querySelectAttributeTypeValues.actions.types';
import {
    AttributeValueQueryModelSymbolsValueParam,
    AttributeValueQueryList,
    AttributeValueQueryParam,
} from '../../serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';

export class AttributeQuerySelectDAOService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getAttributeQueryData(param: AttributeValueQueryParam, typeId: string): Promise<TQueryData> {
        const api = this.getApi();
        if (param.queryAttributeType === 'MODEL_SYMBOLS' && !(param as AttributeValueQueryModelSymbolsValueParam).modelId)
            return {
                attributeTypeId: typeId,
                values: [],
            };
        const attribueValues: AttributeValueQueryList = await api.attributeQuery.getAttributeValueQueryList({ body: param });

        return {
            attributeTypeId: typeId,
            values: attribueValues.values,
        };
    }

    public static async getscriptQueryParamsValues(
        param: AttributeValueQueryParam,
        name: string,
    ): Promise<TScriptQueryData> {
        const api = this.getApi();
        if (param.queryAttributeType === 'MODEL_SYMBOLS' && !(param as AttributeValueQueryModelSymbolsValueParam).modelId)
            return {
                name,
                values: [],
            };
        const attribueValues: AttributeValueQueryList = await api.attributeQuery.getAttributeValueQueryList({ body: param });

        return {
            name,
            values: attribueValues.values,
        };
    }
}
