import { connect } from 'react-redux';
import { Navigator } from '../components/Navigator/Navigator.component';
import { TNavigatorStoreProps, TNavigatorActionsProps } from '../components/Navigator/Navigator.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { navigatorTabSelect, navigatorTabDeselect } from '../../../actions/navigator.actions';
import { closeCommentsPanel, hideAllCommentMarkers, openCommentsPanel } from '../../../actions/comments.actions';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { CommentsSelectors } from '../../../selectors/comments.selectors';
import { NodeId } from '../../../serverapi/api';

const mapStateToProps = (state: TRootState): TNavigatorStoreProps => ({
    activeTabs: state.navigator.activeTabs,
    activeSchemeId: TabsSelectors.getActiveTabId(state)!,
    scheme: TabsSelectors.getActiveTab(state),
    commentsEnabledSchemesIds: CommentsSelectors.commentsEnabledSchemesIds(state),
});

const mapDispatchToProps = (dispatch): TNavigatorActionsProps => ({
    onTabSelect: (item) => dispatch(navigatorTabSelect(item)),
    onTabDeselect: (item) => dispatch(navigatorTabDeselect(item)),
    onCommentsOpen: (modelId: NodeId) => {
        dispatch(openCommentsPanel({ modelId }));
    },
    onCommentsClose: (modelId: NodeId) => {
        dispatch(closeCommentsPanel({ modelId }));
        dispatch(hideAllCommentMarkers());
    },
});

export const NavigatorContainer = connect(mapStateToProps, mapDispatchToProps)(Navigator);
