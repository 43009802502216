import type { TValueTypeEnum } from '../models/ValueTypeEnum.types';
import type { AttributeValueNode, NodeId } from '../serverapi/api';
import type { TTreeEntityState } from '@/models/tree.types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TreeSelectors } from '../selectors/tree.selectors';

export const useChangeNodeAttributeValue = (callback: (id: string, valueType: TValueTypeEnum, value: any) => void) => {
    const [newNodeId, setNewNodeId] = useState<NodeId | undefined>(undefined);
    const [nodeAttributeToChange, setNodeAttributeToChange] = useState<AttributeValueNode | undefined>(undefined);
    const newNode: TTreeEntityState | undefined = useSelector(TreeSelectors.itemById(newNodeId));

    useEffect(() => {
        if (newNode && nodeAttributeToChange)
            callback(nodeAttributeToChange.id, nodeAttributeToChange.valueType, newNode);
    }, [newNodeId]);

    return {
        newNodeId,
        nodeAttributeToChange,
        setNewNodeId,
        setNodeAttributeToChange,
    };
};
