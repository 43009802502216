import type { TUmlPackageData } from './packageSymbol.types';
import type { ObjectInstance } from '@/serverapi/api';
import type { BPMMxGraph } from '@/mxgraph/bpmgraph';
import type { NodeId } from '@/serverapi/api';
import { ObjectDefinitionSelectors } from '@/selectors/objectDefinition.selectors';
import { getStore } from '@/store';
import { getUmlPackageDefinitionData } from '../umlDefinitionData.selectors';

export const umlPackageDataGetter =
    (graphId: NodeId) =>
    (id?: string): TUmlPackageData => {
        if (!id) {
            return { name: '' };
        }

        const state = getStore().getState();

        const nodeId = {
            id,
            repositoryId: graphId.repositoryId,
            serverId: graphId.serverId,
        };

        const objectDefinition = ObjectDefinitionSelectors.byId(nodeId)(state);

        if (!objectDefinition) {
            return { name: '' };
        }

        const umlPackageData = getUmlPackageDefinitionData(objectDefinition)(state);

        return umlPackageData;
    };

export const getUmlPackageSymbolCustomProps = (graph: BPMMxGraph, rootCellValue: ObjectInstance): TUmlPackageData => {
    const { objectDefinitionId } = rootCellValue;
    const getUmlClassData = umlPackageDataGetter(graph.id);

    return getUmlClassData(objectDefinitionId);
};
