import { TFolderFormData, TFolderFormStatus, TFolderFormInitData } from '../models/folderDialog.types';
import { TReducer } from '../utils/types';
import { FOLDER_DIALOG_SUBMIT_RESULT } from '../actionsTypes/folderDialog.actionTypes';
import { TFolderDialogState } from './folderDialog.reducer.types';

const initial: TFolderDialogState = {
    folderFormData: <TFolderFormData>{
        folderName: '',
    },

    folderFormInitData: <TFolderFormInitData>{},

    folderFormStatus: <TFolderFormStatus>{
        folderNameStatus: 'success',
    },
};

export const folderDialogReducer: TReducer<TFolderDialogState> = (state = initial, action) => {
    switch (action.type) {
        case FOLDER_DIALOG_SUBMIT_RESULT: {
            const { result, error } = action.payload;
            const { folderFormStatus } = state;
            if (result === 'error') {
                folderFormStatus.error = error;
                state.folderFormStatus = { ...folderFormStatus };
            }

            return { ...state };
        }

        default: {
            return state;
        }
    }
};
