import type { AttributeTypeStyle } from '../../../../../serverapi/api';
import React from 'react';
import { useIntl } from 'react-intl';
import theme from '../AttributesEditor.scss';
import messages from './AttributeStyleEditor.messages';
import { AttributeImageStyleEditor } from './AttributeImageStyleEditor/AttributeImageStyleEditor.component';
import { TextStyleEditor } from './TextStyleEditor/AttributeTextStyleEditor.component';
import { OverlayTypeSelector } from './OverlayTypeSelector/OverlayTypeSelector.component';
import { StyleFormat } from '../../../FloatingAttributes.constants';
import { PresetImageSelectors } from '@/selectors/presetSettings/presetImage.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { getActiveGraph } from '@/selectors/editor.selectors';
import { FloatingAttributesPanelSelectors } from '@/modules/FloatingAttributes/selectors/FloatingAttributes.selectors';
import { useSelector } from 'react-redux';

type TAttributeStyleEditorProps = {
    selectedStyle: AttributeTypeStyle;
    disabled?: boolean;
};

export const AttributeStyleEditor = ({ selectedStyle, disabled }: TAttributeStyleEditorProps) => {
    const intl = useIntl();

    const activeGraphId = useSelector(getActiveGraph);
    const serverId = activeGraphId?.serverId || '';
    const presetId: string =
        useSelector(TreeSelectors.presetById(activeGraphId)) ||
        useSelector(FloatingAttributesPanelSelectors.getPresetId);
    const presetImages = useSelector(PresetImageSelectors.listAllByPreset(serverId, presetId));

    const isText = selectedStyle?.format === StyleFormat.TEXT;
    const isImage = selectedStyle?.format === StyleFormat.IMAGE;

    return (
        <>
            <h3 className={theme.stepTitle}>{intl.formatMessage(messages.overlayType)}</h3>
            <OverlayTypeSelector disabled={disabled} value={selectedStyle?.format} images={presetImages} />
            {isText && <TextStyleEditor selectedStyle={selectedStyle} disabled={disabled} />}
            {isImage && (
                <AttributeImageStyleEditor selectedStyle={selectedStyle} images={presetImages} disabled={disabled} />
            )}
        </>
    );
};
