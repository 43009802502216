import type { AttributeTypeStyleFormatEnum, AttributeTypeStyleRule } from '../../../serverapi/api';
import type { TAttributeDiscriminator } from '../FloatingAttributes.types';
import type {
    TFloatingAttributesAddStyleAction,
    TFloatingAttributesChangeFormatAction,
    TFloatingAttributesChangeImageStyleAction,
    TFloatingAttributesChangeImageStylePayload,
    TFloatingAttributesChangeRulesAction,
    TFloatingAttributesChangeTextStyleAction,
    TFloatingAttributesChangeTextStylePayload,
    TFloatingAttributesChangeTypeAction,
    TFloatingAttributesClearStateAction,
    TFloatingAttributesRemoveStyleAction,
    TFloatingAttributesSelectAreaAction,
    TFloatingAttributesSelectStyleAction,
    TFloatingAttributesSetStatePayload,
    TFloatingAttributesSetStateAction,
    TFloatingAttributesChangeAreaAttributeWrapPayload,
    TFloatingAttributesChangeAreaAttributeWrapAction,
    TFloatingAttributesSwitchPresetStylesPayload,
    TFloatingAttributesSwitchPresetStylesAction,
} from './FloatingAttributes.actions.types';
import {
    FLOATING_ATTRIBUTES_SET_STATE,
    FLOATING_ATTRIBUTES_SWITCH_PRESET_STYLES,
    FLOATING_ATTRIBUTES_SELECT_AREA,
    FLOATING_ATTRIBUTES_ADD_STYLE,
    FLOATING_ATTRIBUTES_SELECT_STYLE,
    FLOATING_ATTRIBUTES_CHANGE_TYPE,
    FLOATING_ATTRIBUTES_REMOVE_STYLE,
    FLOATING_ATTRIBUTES_CHANGE_RULES,
    FLOATING_ATTRIBUTES_CHANGE_FORMAT,
    FLOATING_ATTRIBUTES_CHANGE_TEXT_STYLE,
    FLOATING_ATTRIBUTES_CHANGE_IMAGE_STYLE,
    FLOATING_ATTRIBUTES_CLEAR_STATE,
    FLOATING_ATTRIBUTES_CHANGE_AREA_ATTRIBUTE_WRAP,
} from '../actionsTypes/FloatingAttributes.actionTypes';

export const floatingAttributesSetStateAction = (
    payload: TFloatingAttributesSetStatePayload,
): TFloatingAttributesSetStateAction => ({
    type: FLOATING_ATTRIBUTES_SET_STATE,
    payload,
});

export const floatingAttributesSwitchPresetStylesAction = (
    payload: TFloatingAttributesSwitchPresetStylesPayload,
): TFloatingAttributesSwitchPresetStylesAction => ({
    type: FLOATING_ATTRIBUTES_SWITCH_PRESET_STYLES,
    payload,
});

export const floatingAttributesSelectAreaAction = (positionKey: string): TFloatingAttributesSelectAreaAction => ({
    type: FLOATING_ATTRIBUTES_SELECT_AREA,
    payload: positionKey,
});

export const floatingAttributesAddStyleAction = (): TFloatingAttributesAddStyleAction => ({
    type: FLOATING_ATTRIBUTES_ADD_STYLE,
});

export const floatingAttributesSelectStyleAction = (selectedStyle: string): TFloatingAttributesSelectStyleAction => ({
    type: FLOATING_ATTRIBUTES_SELECT_STYLE,
    payload: selectedStyle,
});

export const floatingAttributesChangeTypeAction = (
    attributeId: string,
    attributeDiscriminator: TAttributeDiscriminator,
): TFloatingAttributesChangeTypeAction => ({
    type: FLOATING_ATTRIBUTES_CHANGE_TYPE,
    payload: {
        attributeId,
        attributeDiscriminator,
    },
});

export const floatingAttributesRemoveStyleAction = (): TFloatingAttributesRemoveStyleAction => ({
    type: FLOATING_ATTRIBUTES_REMOVE_STYLE,
});

export const floatingAttributesChangeRulesAction = (
    rules: AttributeTypeStyleRule[],
): TFloatingAttributesChangeRulesAction => ({
    type: FLOATING_ATTRIBUTES_CHANGE_RULES,
    payload: rules,
});

export const floatingAttributesChangeFormatAction = (
    format: AttributeTypeStyleFormatEnum,
): TFloatingAttributesChangeFormatAction => ({
    type: FLOATING_ATTRIBUTES_CHANGE_FORMAT,
    payload: format,
});

export const floatingAttributesChangeTextStyleAction = (
    payload: TFloatingAttributesChangeTextStylePayload,
): TFloatingAttributesChangeTextStyleAction => ({
    type: FLOATING_ATTRIBUTES_CHANGE_TEXT_STYLE,
    payload,
});

export const floatingAttributesChangeImageStyleAction = (
    payload: TFloatingAttributesChangeImageStylePayload,
): TFloatingAttributesChangeImageStyleAction => ({
    type: FLOATING_ATTRIBUTES_CHANGE_IMAGE_STYLE,
    payload,
});

export const floatingAttributesClearStateAction = (): TFloatingAttributesClearStateAction => ({
    type: FLOATING_ATTRIBUTES_CLEAR_STATE,
});

export const floatingAttributesChangeAreaAttributeWrap = (
    payload: TFloatingAttributesChangeAreaAttributeWrapPayload,
): TFloatingAttributesChangeAreaAttributeWrapAction => ({
    type: FLOATING_ATTRIBUTES_CHANGE_AREA_ATTRIBUTE_WRAP,
    payload,
});
