import { apiBundle } from '../api/api-bundle';
import { FolderType } from '../../serverapi/api';

export class FolderTypeDAOService {
    public static async byPresetId(serverId: string, presetId: string): Promise<FolderType[]> {
        const folderTypes = await apiBundle(serverId).folderType.byPresetId({ presetId });

        return folderTypes;
    }

    public static async byId(serverId: string, presetId: string, id: string): Promise<FolderType> {
        const folderType = await apiBundle(serverId).folderType.byId({ presetId, id });

        return folderType;
    }

    public static async deleteById(serverId: string, presetId: string, id: string): Promise<void> {
        await apiBundle(serverId).folderType.deleteById({ presetId, id });
    }

    public static async save(serverId: string, body: FolderType): Promise<FolderType> {
        const folderType = await apiBundle(serverId).folderType.save({ body });

        return folderType;
    }

    public static async create(serverId: string, body: FolderType): Promise<FolderType> {
        const folderType = await apiBundle(serverId).folderType.create({ body });

        return folderType;
    }
}
