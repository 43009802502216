import type {
    NodeId,
    ObjectDefinitionNode,
    ModelLite,
    EdgeDefinitionNode,
    ParentModelOfEdgeDefinition,
    Node,
} from '../serverapi/api';
import type { TExecutingProcess } from '../reducers/statusBar.reducer.types';
import type { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import type { TServerEntity } from '../models/entities.types';
import type { TWorkspaceTab } from '../models/tab.types';
import type { TreeNode, TTreeEntityState } from '../models/tree.types';
import type {
    TDeleteSeveralNodesFromServer,
    TDeleteSeveralNodesFromServerRequest,
    TTreeItemDeleteNodeFromServer,
    TTreeItemDeleteNodeFromServerRequest,
    TTreeItemDeleteServer,
} from '../actions/tree.actions.types';
import { v4 as uuid } from 'uuid';
import { select, put, call, takeEvery } from 'redux-saga/effects';
import { deleteAction } from '../actions/editor.actions';
import { objectDefinitionDelete, objectDefinitionsAdd } from '../actions/entities/objectDefinition.actions';
import { recentRefresh } from '../actions/recent.actions';
import { setProcessIndicator } from '../actions/statusBar.actions';
import { workspaceRemoveTabByNodeId } from '../actions/tabs.actions';
import {
    deleteSeveralNodesFromServer,
    treeItemDelete,
    treeItemDeleteNodeFromServer,
    treeItemRefresh,
} from '../actions/tree.actions';
import ProcessIndicatorMessages from '../modules/StatusBar/components/ProcessIndicator/ProcessIndicator.messages';
import { TreeItemType } from '../modules/Tree/models/tree';
import { ProcessType } from '../reducers/statusBar.reducer.types';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { TreeSelectors, getShowDeletedObjectsFilter } from '../selectors/tree.selectors';
import { LocalesService } from '../services/LocalesService';
import {
    DELETE_SEVERAL_NODES_FROM_SERVER,
    DELETE_SEVERAL_NODES_FROM_SERVER_REQUEST,
    TREE_ITEM_DELETE_FROM_SERVER,
    TREE_ITEM_DELETE_FROM_SERVER_REQUEST,
    TREE_ITEM_DELETE_NODE_SERVER,
} from '../actionsTypes/tree.actionTypes';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { compareNodeIds } from '../utils/nodeId.utils';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { deleteServer } from '../actions/entities/servers.actions';
import {
    deleteNode,
    DeleteNodeRequest,
    findTabsIdsToBeClosed,
    getModelsLiteOfDeletingEdge,
    getModelsLiteOfDeletingObject,
} from '../services/bll/DeleteNodeBllService';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { navigatorClearProperties } from '../actions/navigatorProperties.actions';
import { nodeService } from '../services/NodeService';
import { RepositoryDAOService } from '../services/dao/RepositoryDAOService';
import { EdgeDefinitionSelectors } from '../selectors/edgeDefinition.selector';
import { edgeDefinitionsAdd } from '../actions/entities/edgeDefinition.actions';
import { updateCellsOverlays } from '../actions/overlay.actions';
import { favoritesFetchRequest } from '@/actions/favorites.actions';
import { isUmlAttributeTreeNode } from '../mxgraph/ComplexSymbols/symbols/UML/ClassSymbol/classSymbol.utils';
import { deleteMatrixObjectHeaders } from '../modules/Matrix/actions/matrixEditor.actions';
import { uniqWith, isEqual } from 'lodash-es';
import { showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { TRootState } from '@/reducers/root.reducer.types';

export function* getDeleteNodeRequestData(nodeId: NodeId) {
    // подготовка данных для удаления, хорошо бы вынести в отдельную функцию
    const node: TreeNode = yield select(TreeSelectors.unfilteredItemById(nodeId));

    if (!node) {
        return;
    }

    let modelsWhereObjectToDelete: ModelLite[] = [];
    let edgeInstancesToDelete: ParentModelOfEdgeDefinition[] = [];

    const tabs: TWorkspaceTab[] = yield select(TabsSelectors.getTabList);

    const closeTabsIds: NodeId[] = yield call(() =>
        findTabsIdsToBeClosed(node, tabs, TreeDaoService.findAllParentElementId),
    );

    const closeTabsNames = tabs
        .filter((tab) => closeTabsIds.find((ctId) => compareNodeIds(ctId, tab.nodeId)))
        .map((tab) => ({ tabId: tab.nodeId, name: tab.title }));

    const objectsMap: { [key: string]: ObjectDefinitionImpl } = yield select(
        ObjectDefinitionSelectors.byServerIdRepositoryId(nodeId.serverId, nodeId.repositoryId),
    );

    const edgesMap: { [key: string]: EdgeDefinitionNode } = yield select(
        EdgeDefinitionSelectors.byServerIdRepositoryId(nodeId.serverId, nodeId.repositoryId),
    );

    if (node.type === TreeItemType.ObjectDefinition) {
        const nodeObject: ObjectDefinitionNode = yield nodeService().loadNodeFromServer(node.nodeId);
        modelsWhereObjectToDelete = getModelsLiteOfDeletingObject(nodeObject, tabs);
    }

    if (node.type === TreeItemType.EdgeDefinition) {
        const edgeDefinitionNode: EdgeDefinitionNode = yield nodeService().loadNodeFromServer(node.nodeId);

        edgeInstancesToDelete = getModelsLiteOfDeletingEdge(edgeDefinitionNode, tabs);
    }

    // eslint-disable-next-line consistent-return
    return {
        nodeId,
        parentNodeId: node.parentNodeId,
        nodeType: node.type,
        nodeName: node.name,
        countChildren: node.countChildren,
        closeTabsNames,
        objectsMap,
        edgesMap,
        closeTabsIds,
        modelsWhereObjectToDelete,
        edgeInstancesToDelete,
    };
}

export function* deleteNodeServer({ payload: { serverId } }: TTreeItemDeleteServer) {
    const server: TServerEntity | undefined = yield select(ServerSelectors.server(serverId));

    if (!server) {
        return;
    }

    const deleteRequestData: DeleteNodeRequest = {
        nodeId: { id: serverId, repositoryId: serverId, serverId },
        nodeType: TreeItemType.Server,
        nodeName: server.name,
        countChildren: 0,
        closeTabsNames: [],
        objectsMap: {},
        edgesMap: {},
        closeTabsIds: [],
    };

    yield put(openDialog(DialogType.DELETE_NODE_DIALOG, deleteRequestData));
}

export function* deleteNodeFromServerRequest({ payload: { nodeId } }: TTreeItemDeleteNodeFromServerRequest) {
    const deleteRequestData: DeleteNodeRequest | undefined = yield getDeleteNodeRequestData(nodeId);

    if (!deleteRequestData) {
        return;
    }

    yield put(openDialog(DialogType.DELETE_NODE_DIALOG, deleteRequestData));
}

const sortNodesForDeletion = (deleteRequestData: DeleteNodeRequest[], rootState: TRootState): DeleteNodeRequest[] => {
    return deleteRequestData
        .sort(({ nodeId: { repositoryId: r1 } }, { nodeId: { repositoryId: r2 } }) => {
            if (r1 > r2) {
                return 1;
            }
            if (r1 < r2) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            if (TreeSelectors.isParent(a.nodeId, b.nodeId, rootState)) {
                return -1;
            }
            if (TreeSelectors.isParent(b.nodeId, a.nodeId, rootState)) {
                return 1;
            }
            return -1;
        });
};

export function* deleteSeveralNodesFromServerRequest({ payload: { nodeIds } }: TDeleteSeveralNodesFromServerRequest) {
    const deleteRequestData: DeleteNodeRequest[] = [];

    for (let nodeId of nodeIds) {
        const treeNode: TTreeEntityState | undefined = yield select(TreeSelectors.itemById(nodeId));
        if (!treeNode || treeNode.type === TreeItemType.AdminTool) continue;

        const nodeDeleteRequestData: DeleteNodeRequest | undefined = yield getDeleteNodeRequestData(nodeId);
        if (nodeDeleteRequestData) {
            deleteRequestData.push(nodeDeleteRequestData);
        }
    }

    if (deleteRequestData.length === 0) {
        yield put(showNotificationByType(NotificationType.ITEMS_CANNOT_BE_DELETED));
        return;
    }
    const rootState: TRootState = yield select();

    const sortedDeleteRequestData: DeleteNodeRequest[] = sortNodesForDeletion(deleteRequestData, rootState);

    const closeTabNames: { tabId: NodeId; name: string }[] = uniqWith(
        sortedDeleteRequestData.map((data) => data.closeTabsNames).flat(),
        isEqual,
    );

    yield put(
        openDialog(DialogType.DELETE_SEVERAL_NODES_DIALOG, {
            deleteNodeRequestData: sortedDeleteRequestData,
            closeTabNames,
        }),
    );
}

export function* deleteNodeFromServer({ payload: { deleteNodeRequest } }: TTreeItemDeleteNodeFromServer) {
    const { closeTabsIds, nodeId, nodeName, nodeType, objectsMap } = deleteNodeRequest;

    if (nodeType === TreeItemType.Server) {
        yield put(deleteServer(nodeId.serverId));

        return;
    }

    const showDeletedObjects: boolean = yield select(getShowDeletedObjectsFilter);

    // сначала закрываем вкладки, только после этого удаляем узел, иначе может возникнуть ситуация когда срабатывает автосохранение, а узла уже нет
    for (const closeTabId of closeTabsIds) {
        yield put(workspaceRemoveTabByNodeId(closeTabId));
    }

    const locale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(locale);
    const node: TreeNode = yield select(TreeSelectors.unfilteredItemById(nodeId));
    const processName: string = `${intl.formatMessage(ProcessIndicatorMessages.processDelete)} "${nodeName}"`;
    const process: TExecutingProcess = { id: uuid(), name: processName, type: ProcessType.DELETE };
    const idSymbol: string | undefined = objectsMap[nodeId.id]?.idSymbol;

    yield put(setProcessIndicator(true, process));

    try {
        if (nodeType === TreeItemType.Repository) {
            yield call(() => RepositoryDAOService.delete(nodeId));
        } else {
            yield call(() => TreeDaoService.delete(nodeId));

            yield put(deleteMatrixObjectHeaders([node as Node]));
        }
    } finally {
        yield put(setProcessIndicator(false, process));
    }

    const deleteDescriptors = deleteNode(deleteNodeRequest);

    for (const descriptor of deleteDescriptors) {
        const {
            changedDecompositionCells,
            changedObjectsDefinitions,
            changedEdgesDefinitions,
            deletableCells,
            graphId,
        } = descriptor;

        if (changedObjectsDefinitions?.length) {
            yield put(objectDefinitionsAdd(changedObjectsDefinitions));
        }
        if (changedEdgesDefinitions?.length) {
            yield put(edgeDefinitionsAdd(changedEdgesDefinitions));
        }
        if (changedDecompositionCells?.length) {
            yield put(updateCellsOverlays({ graphId, cells: changedDecompositionCells }));
        }
        if (deletableCells?.length) {
            yield put(deleteAction(deletableCells, graphId, false));
        }
    }

    if (isUmlAttributeTreeNode(idSymbol)) {
        yield put(objectDefinitionDelete(nodeId));
    }

    yield put(recentRefresh());
    yield put(favoritesFetchRequest(nodeId.serverId));

    if (showDeletedObjects) {
        yield put(treeItemRefresh(nodeId));
    } else {
        yield put(treeItemDelete(nodeId));
        yield put(treeItemRefresh(node.parentNodeId));
    }

    yield put(navigatorClearProperties());
}

export function* handleDeleteSeveralNodesFromServer({
    payload: { deleteNodeRequestData },
}: TDeleteSeveralNodesFromServer) {
    const deleteNodeRequest: DeleteNodeRequest | undefined = deleteNodeRequestData.shift();

    if (deleteNodeRequest) {
        try {
            yield call(deleteNodeFromServer, treeItemDeleteNodeFromServer(deleteNodeRequest));
            yield put(deleteSeveralNodesFromServer(deleteNodeRequestData));
        } catch (error) {
            yield put(
                openDialog(DialogType.DELETE_SEVERAL_NODES_ERROR_DIALOG, {
                    deleteNodeRequestData: deleteNodeRequestData,
                    name: deleteNodeRequest.nodeName,
                }),
            );
            throw error;
        }
    }
}

export function* deleteNodeSaga() {
    yield takeEvery(TREE_ITEM_DELETE_FROM_SERVER, deleteNodeFromServer);
    yield takeEvery(TREE_ITEM_DELETE_FROM_SERVER_REQUEST, deleteNodeFromServerRequest);
    yield takeEvery(DELETE_SEVERAL_NODES_FROM_SERVER_REQUEST, deleteSeveralNodesFromServerRequest);
    yield takeEvery(DELETE_SEVERAL_NODES_FROM_SERVER, handleDeleteSeveralNodesFromServer);
    yield takeEvery(TREE_ITEM_DELETE_NODE_SERVER, deleteNodeServer);
}
