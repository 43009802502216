import React from 'react';
import { useDispatch } from 'react-redux';
import icPrinter from '../../../../../../resources/icons/printer.svg';
import { printModel } from '../../../../../../actions/image.actions';
import { NodeId } from '../../../../../../serverapi/api';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton.component';

interface IPrintButtonProps {
    nodeId: NodeId;
    disabled: boolean;
    tooltipTitle: string;
}

export const PrintButton: React.FC<IPrintButtonProps> = (props) => {
    const { nodeId, disabled, tooltipTitle } = props;
    const dispatch = useDispatch();

    const onPrintAction = (id: NodeId) => {
        dispatch(printModel(id));
    };

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icPrinter}
            tooltipTitle={tooltipTitle}
            dataTest="general-toolbar_print-btn"
            onClick={() => onPrintAction(nodeId)}
        />
    );
};
