import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from './PublishedModelsTab.messages';
import theme from './PublishedModelsTab.scss';
import icDelete from '../../../resources/icons/Deleted.svg';
import icOpen from '../../../resources/icons/ic-open.svg';
import { PubslishedModelsSelectors } from '@/selectors/publishedModels.selectors';
import { TPublishedModelsTableData } from './PublishedModelTab.types';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { sortByAlpha } from '@/utils/sortByAlpha';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Alert } from 'antd';
import { TreeNode } from '@/models/tree.types';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { moveToDirectAction } from '@/actions/editor.actions';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { openNode } from '@/actions/openNode.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { deletePublishedModels } from '@/actions/publishedModels.actions';

type TModelList = {
    searchInput: string;
    checkedModels: TPublishedModelsTableData[];
    setCheckedModels: (models: TPublishedModelsTableData[]) => void;
};

export const ModelsList: FC<TModelList> = (props) => {
    const { searchInput, checkedModels, setCheckedModels } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const publishedModelsTableData: TPublishedModelsTableData[] = useSelector(
        PubslishedModelsSelectors.getPublishedModelsTableData,
    );

    const deleteModelHandler = (modelData: TPublishedModelsTableData) => {
        const onDelete = () => {
            dispatch(deletePublishedModels([modelData.id]));
            setCheckedModels(checkedModels.filter((model) => !compareNodeIds(modelData.id, model.id)));
        };

        const modelNames: string = `"${modelData.modelName}"`;

        dispatch(
            openDialog(DialogType.DELETE_CONFIRMATION, {
                onDelete,
                deleteQuestion: intl.formatMessage(messages.deleteModelsQuestion),
                dialogContent: (
                    <Alert
                        message={intl.formatMessage(messages.deleteModelsDialogContent, { modelNames })}
                        type="warning"
                    />
                ),
            }),
        );
    };

    const goToModelHandler = (modelData: TPublishedModelsTableData) => {
        const treeNode: TreeNode = {
            hasChildren: false,
            nodeId: modelData.id,
            name: '',
            type: TreeItemType.Model,
            countChildren: 0,
        };
        dispatch(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));
        dispatch(openNode({ nodeId: modelData.id, type: TreeItemType.Model }));
    };

    const renderRowBtns = (tableData: TPublishedModelsTableData) => {
        return (
            <div className={theme.rowBtnContainer}>
                <div className={theme.buttonsBox}>
                    <Button
                        visualStyle={{ type: 'text' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteModelHandler(tableData);
                        }}
                        icon={icDelete}
                        tooltip={intl.formatMessage(messages.delete)}
                    ></Button>
                </div>

                <div className={theme.buttonsBox}>
                    <Button
                        visualStyle={{ type: 'text' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            goToModelHandler(tableData);
                        }}
                        icon={icOpen}
                        tooltip={{ title: intl.formatMessage(messages.goToModel), position: 'left' }}
                    ></Button>
                </div>
            </div>
        );
    };

    const getDataSource = (): TPublishedModelsTableData[] => {
        const filteredData: TPublishedModelsTableData[] =
            searchInput === ''
                ? publishedModelsTableData
                : publishedModelsTableData.filter(
                      (item) =>
                          [item.modelName, item.repositoryName, item.publishedBy].filter((s) => {
                              if (!s) return false;
                              const lowerCased = s.trim().toLowerCase();

                              return lowerCased.includes(searchInput.trim().toLowerCase());
                          }).length,
                  );

        return filteredData;
    };

    const handleCheckModels = (rows: TTableData[], checked: boolean) => {
        const models: TPublishedModelsTableData[] = publishedModelsTableData.filter((model) =>
            rows.find((row) =>
                compareNodeIds(
                    {
                        id: row.id as string,
                        repositoryId: row.repositoryId as string,
                        serverId: model.id.serverId,
                    },
                    model.id,
                ),
            ),
        );
        if (checked) {
            setCheckedModels([...checkedModels, ...models]);
        } else {
            setCheckedModels(
                checkedModels.filter(
                    (model) =>
                        !rows.find((row) =>
                            compareNodeIds(
                                {
                                    id: row.id as string,
                                    repositoryId: row.repositoryId as string,
                                    serverId: model.id.serverId,
                                },
                                model.id,
                            ),
                        ),
                ),
            );
        }
    };

    const tableData: TTableData[] = getDataSource().map((model) => ({
        id: model.id.id,
        repositoryId: model.id.repositoryId,
        repositoryName: model.repositoryName,
        modelName: model.modelName,
        publishedBy: model.publishedBy,
        publishedAtStr: model.publishedAtStr,
        publishedAt: model.publishedAt,
        manageBtn: renderRowBtns(model),
        checked: !!checkedModels.find((checkedModel) => compareNodeIds(checkedModel.id, model.id)),
    }));

    const columns: TColumn[] = [
        {
            dataKey: 'repositoryName',
            title: intl.formatMessage(messages.dbName),
            sortFunction: (a: TTableData, b: TTableData) =>
                sortByAlpha(a.repositoryName as string, b.repositoryName as string),
        },
        {
            dataKey: 'modelName',
            title: intl.formatMessage(messages.modelName),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.modelName as string, b.modelName as string),
        },
        {
            dataKey: 'publishedBy',
            title: intl.formatMessage(messages.publishedBy),
            sortFunction: (a: TTableData, b: TTableData) =>
                sortByAlpha(a.publishedBy as string, b.publishedBy as string),
        },
        {
            dataKey: 'publishedAtStr',
            title: intl.formatMessage(messages.publishedAt),
            sortFunction: (a: TTableData, b: TTableData) => (a.publishedAt as number) - (b.publishedAt as number),
            defaultSort: 'DESC',
        },
        {
            dataKey: 'manageBtn',
            title: '',
            withoutSorter: true,
            width: 80,
        },
    ];

    return (
        <TableUIKit columns={columns} tableData={tableData} withCkeckBox checkRows={handleCheckModels} />
    );
};
