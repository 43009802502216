import { TreeNode } from '../../models/tree.types';
import { Node, NodeId } from '../../serverapi/api';

// TODO 1647 refactoring, why no implicit return type in the function ?
// нужен был для handleStopEditingCell, но пока что не ясно нужен ли handleStopEditingCell
// export function* findNodeIdsInModel (treeNodes: { [id: string]: TTreeEntityState },
//                                      modelId: NodeId,
//                                      serverId: string) {
//
//     const objectDefinitions = yield objectDefinitionService()
//         .getObjectDefinitionsByModel(
//         serverId,
//         modelId.repositoryId,
//         modelId);
//     if (objectDefinitions) {
//         return objectDefinitions
//             .filter(objectDefinition => treeNodes[objectDefinition.nodeId.id])
//             .map(d => d.nodeId);
//     }
//     return [];
// }

export const getChainFromChildToParent = (nodes: { [id: string]: TreeNode }, id: string): TreeNode[] => {
    const result: TreeNode[] = [];
    for (let n = nodes[id]; n; n = nodes[id]) {
        result.push(n);
        id = n.parentNodeId ? n.parentNodeId.id : '';
    }

    return result;
};

export const isContain = (idParent: string, idChild: string, nodes: { [id: string]: TreeNode }): boolean => {
    let child = nodes[idChild];
    while (child && child.parentNodeId) {
        if (child.parentNodeId.id === idParent) {
            return true;
        }
        child = nodes[child.parentNodeId.id];
    }

    return false;
};

export const getNodeWithChildrenFromNodes = (nodeId: NodeId, allNodes: Node[]) => {
    const nodes: Node[] = [];
    const allNodesObject: { [key: string]: Node } = allNodes.reduce((result, node) => {
        result[node.nodeId.id] = node;
        return result;
    }, {});

    const addChildNodesOfNode = (nodeId: NodeId) => {
        const node = allNodesObject[nodeId.id];
        if (!node) return;

        nodes.push(node);

        delete allNodesObject[nodeId.id];

        for (const id in allNodesObject) {
            if (allNodesObject[id].parentNodeId?.id === nodeId.id) {
                addChildNodesOfNode(allNodesObject[id].nodeId);
            }
        }
    };

    addChildNodesOfNode(nodeId);

    return nodes;
};
