import { OPEN_SYSTEM_PROPERTIES_TAB, SAVE_SYSTEM_PROPERTIES, SYSTEM_PROPERTIES_SET_STATE } from '../actionsTypes/systemProperties.actionTypes';
import { TreeNode } from '../models/tree.types';
import { SystemProperties } from '../serverapi/api';
import { TFetchSystemPropertiesAction, TOpenSystemPropertiesTabAction, TSaveSystemPropertiesAction } from './systemProperties.actions.types';

export const openSystemProperiesTab = (node: TreeNode): TOpenSystemPropertiesTabAction => ({
    type: OPEN_SYSTEM_PROPERTIES_TAB,
    payload: {
        serverNode: node,
    },
});

export const saveSystemProperties = (properties: SystemProperties): TSaveSystemPropertiesAction => ({
    type: SAVE_SYSTEM_PROPERTIES,
    payload: {
        properties,
    },
});

export const fetchSystemPropertiesState = ( properties: SystemProperties): TFetchSystemPropertiesAction => ({
    type: SYSTEM_PROPERTIES_SET_STATE,
    payload: {
        properties,
    },
});