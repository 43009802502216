import { call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { showNotification } from '../actions/notification.actions';
import { fetchedPrincipalServerProfileInfoSuccess } from '../actions/principalServerProfile.actions';
import {
    switchActiveServerProfileSuccess,
    getAllServerProfiles,
    openActiveServerProfileSelector,
    serverProfileSetState,
    updateTransitiveProfileIds,
} from '../actions/serverProfile.actions';
import {
    TAddServerProfileDataAction,
    TSwitchActiveServerProfileAction,
    TDeleteServerProfilesDataAction,
    TGetAllServerProfilesAction,
    TGetAllServerProfilesDataAction,
    TOpenActiveServerProfileSelectorAction,
    TLoadActiveServerProfileRequestAction,
    TUpdateUserServerProfilesAction,
} from '../actions/serverProfile.actions.types';
import {
    ADD_SERVER_PROFILE_DATA,
    SWITCH_ACTIVE_SERVER_PROFILE,
    DELETE_SERVER_PROFILE_DATA,
    GET_ALL_SERVER_PROFILES,
    GET_ALL_SERVER_PROFILES_DATA,
    OPEN_ACTIVE_SERVER_PROFILE_SELECTOR,
    LOAD_ACTIVE_SERVER_PROFILE_REQUEST,
    UPDATE_USER_SERVER_PROFILES,
} from '../actionsTypes/serverProfile.actionTypes';
import { TServerEntity } from '../models/entities.types';
import { NotificationType } from '../models/notificationType';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { GroupDTO, ServerProfile, UserDTO } from '../serverapi/api';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction } from '../modules/Tree/models/tree';
import { getUser, getUserLogin } from '../selectors/authorization.selectors';
import { appExit } from '../actions/app.actions';
import { AppExitMethod } from '../models/app';
import { ServerProfilesDaoService } from '@/services/dao/ServerProfilesDaoService';

function* handleAddServerProfile(action: TAddServerProfileDataAction) {
    const { serverProfile, serverId } = action.payload;

    yield put(closeDialog(DialogType.ADD_USER_PROFILE));
    try {
        yield ServerProfilesDaoService.create(serverProfile);
        const serverProfiles: ServerProfile[] = yield ServerProfilesDaoService.getAll();

        yield put(serverProfileSetState([...serverProfiles], serverId));
    } catch (error) {
        if (error.status === 409) {
            yield put(
                showNotification({
                    id: uuid(),
                    data: error.message,
                    type: NotificationType.PROFILE_CREATE_ERROR,
                }),
            );
        } else {
            throw error;
        }
    }
}

function* handleGetAllServerProfiles(action: TGetAllServerProfilesAction) {
    const { serverId } = action.payload;
    const serverProfiles: ServerProfile[] = yield ServerProfilesDaoService.getAll();

    yield put(serverProfileSetState(serverProfiles, serverId));
}

function* handleFetchServerProfilesData(action: TGetAllServerProfilesDataAction) {
    const { serverId } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const serverProfiles: ServerProfile[] = yield ServerProfilesDaoService.getAll();

    yield put(serverProfileSetState(serverProfiles, serverId));

    for (let i = 0; i < serverProfiles.length; i++) {
        const users: UserDTO[] = yield call(() => server.api.user.getByProfileId({ profileId: serverProfiles[i].id }));
        const groups: GroupDTO[] = yield call(() =>
            server.api.group.getByProfileId({ profileId: serverProfiles[i].id }),
        );
        yield put(
            fetchedPrincipalServerProfileInfoSuccess({
                serverId,
                profileId: serverProfiles[i].id,
                users,
                groups,
            }),
        );
    }
}

function* handleDeleteServerProfileData(action: TDeleteServerProfilesDataAction) {
    const { profileId, serverId } = action.payload;

    yield ServerProfilesDaoService.delete(profileId);

    const serverProfiles: ServerProfile[] = yield ServerProfilesDaoService.getAll();
    yield put(serverProfileSetState(serverProfiles, serverId));
}

function* handleOpenActiveServerProfileSelectorDialog(action: TOpenActiveServerProfileSelectorAction) {
    const { serverId } = action.payload;
    yield put(getAllServerProfiles({ serverId }));
    yield put(openDialog(DialogType.SERVER_PROFILE_CHANGE, { serverId }));
}

function* handleContextMenuClick({ payload: { nodeId, action } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.SWITCH_ACTIVE_SERVER_PROFILE) {
        yield put(openActiveServerProfileSelector(nodeId.serverId));
    }
}

function* handleSwitchActiveServerProfile(action: TSwitchActiveServerProfileAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(action.payload.serverId));
    const user: UserDTO | undefined = yield select(getUser);

    if (user) {
        yield call(() => server.api.user.switchProfile({ profileId: action.payload.profileId, userId: user?.id }));
    }

    yield put(switchActiveServerProfileSuccess(action.payload)); // todo: можно удалить думаю
    yield put(appExit(AppExitMethod.Logout));
}

function* loadActiveProfile(action: TLoadActiveServerProfileRequestAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(action.payload.serverId));
    const userLogin: string = yield select(getUserLogin);
    try {
        const serverProfile: ServerProfile = yield ServerProfilesDaoService.getActiveProfile();
        yield put(serverProfileSetState([serverProfile], server.id));
    } catch (error) {
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType.NO_USER_PROFILE_ASSIGNED,
                data: { message: userLogin },
            }),
        );
    }
}

function* handleUpdateUserServerProfiles({ payload: { serverId } }: TUpdateUserServerProfilesAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const curentUser: UserDTO = yield call(() => server.api.user.getCurrentUser());
    yield put(updateTransitiveProfileIds(curentUser.transitiveProfilesIds || []));
}

export function* serverProfileSagaInit() {
    yield takeEvery(ADD_SERVER_PROFILE_DATA, handleAddServerProfile);
    yield takeEvery(GET_ALL_SERVER_PROFILES_DATA, handleFetchServerProfilesData);
    yield takeEvery(GET_ALL_SERVER_PROFILES, handleGetAllServerProfiles);
    yield takeEvery(DELETE_SERVER_PROFILE_DATA, handleDeleteServerProfileData);
    yield takeEvery(OPEN_ACTIVE_SERVER_PROFILE_SELECTOR, handleOpenActiveServerProfileSelectorDialog);
    yield takeEvery(SWITCH_ACTIVE_SERVER_PROFILE, handleSwitchActiveServerProfile);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleContextMenuClick);
    yield takeEvery(LOAD_ACTIVE_SERVER_PROFILE_REQUEST, loadActiveProfile);
    yield takeEvery(UPDATE_USER_SERVER_PROFILES, handleUpdateUserServerProfiles);
}
