import { defineMessages } from 'react-intl';

export default defineMessages({
    models: {
        id: 'ChildElementsTab.models',
        defaultMessage: 'Модели',
    },
    objects: {
        id: 'ChildElementsTab.objects',
        defaultMessage: 'Объекты',
    },
    folders: {
        id: 'ChildElementsTab.folders',
        defaultMessage: 'Папки',
    },
    elements: {
        id: 'ChildElementsTab.elements',
        defaultMessage: 'Элементы',
    },
});
