import {
    PRESET_SETTINGS_ADD_KANBAN_CARD_TYPE,
    PRESET_SETTINGS_CLEAR_KANBAN_CARD_TYPES_FOR_DELETE,
    PRESET_SETTINGS_DELETE_KANBAN_CARD_TYPES,
    PRESET_SETTINGS_GET_ALL_KANBAN_CARD_TYPES,
    PRESET_SETTINGS_SUBMIT_KANBAN_CARD_TYPES,
} from '../../actionsTypes/presetSettings/presetSettingsKanbanCardTypes.actionTypes';
import {
    TAddKanbanCardTypeAction,
    TAddKanbanCardTypePayload,
    TClearKanbanCardTypesForDeleteAction,
    TClearKanbanCardTypesForDeletePayload,
    TDeleteKanbanCardTypesAction,
    TDeleteKanbanCardTypesPayload,
    TGetAllKanbanCardTypesAction,
    TGetAllKanbanCardTypesPayload,
    TSubmitKanbanCardTypesAction,
    TSubmitKanbanCardTypesPayload,
} from './presetSettingsKanbanCardType.actions.types';

export const getAllKanbanCardTypes = (payload: TGetAllKanbanCardTypesPayload): TGetAllKanbanCardTypesAction => ({
    type: PRESET_SETTINGS_GET_ALL_KANBAN_CARD_TYPES,
    payload,
});

export const addKanbanCardType = (payload: TAddKanbanCardTypePayload): TAddKanbanCardTypeAction => ({
    type: PRESET_SETTINGS_ADD_KANBAN_CARD_TYPE,
    payload,
});

export const deleteKanbanCardTypes = (payload: TDeleteKanbanCardTypesPayload): TDeleteKanbanCardTypesAction => ({
    type: PRESET_SETTINGS_DELETE_KANBAN_CARD_TYPES,
    payload,
});

export const submitKanbanCardTypes = (payload: TSubmitKanbanCardTypesPayload): TSubmitKanbanCardTypesAction => ({
    type: PRESET_SETTINGS_SUBMIT_KANBAN_CARD_TYPES,
    payload,
});

export const clearKanbanCardTypesForDelete = (
    payload: TClearKanbanCardTypesForDeletePayload,
): TClearKanbanCardTypesForDeleteAction => ({
    type: PRESET_SETTINGS_CLEAR_KANBAN_CARD_TYPES_FOR_DELETE,
    payload,
});
