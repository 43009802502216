export const OBJECT_TYPE_REQUEST_SUCCESS = 'OBJECT_TYPE_REQUEST_SUCCESS';

export const OBJECT_TYPE_DELETE_REQUEST_SUCCESS = 'OBJECT_TYPE_DELETE_REQUEST_SUCCESS';

export const OBJECT_TYPE_DATA = 'OBJECT_TYPE_DATA';

export const DELETE_OBJECT_TYPE = 'DELETE_OBJECT_TYPE';

export const EDIT_OBJECT_TYPE = 'EDIT_OBJECT_TYPE';

export const CREATE_OBJECT_TYPE = 'CREATE_OBJECT_TYPE';

export const SUBMIT_OBJECT_TYPE = 'SUBMIT_OBJECT_TYPE';

export const DELETE_OBJECT_TYPE_AND_GROUP = 'DELETE_OBJECT_TYPE_AND_GROUP';

export const OBJECT_TYPE_REQUEST_FAILURE = 'OBJECT_TYPE_REQUEST_FAILURE';
