// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportStep__description__KfTN5{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;font-size:14px;font-weight:500;color:var(--text)}.ImportStep__icon__b4_Op{padding:3px 0 0 5px;font-size:20px;color:var(--mainColor)}.ImportStep__error__qUkTE{font-size:16px;color:red}", "",{"version":3,"sources":["webpack://./src/modules/Import/Aris/components/ImportStep/ImportStep.scss"],"names":[],"mappings":"AAAA,gCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,0BAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,yBACE,mBAAA,CACA,cAAA,CACA,sBAAA,CAGF,0BACE,cAAA,CACA,SAAA","sourcesContent":[".description {\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n  color: var(--text);\r\n}\r\n\r\n.icon {\r\n  padding: 3px 0 0 5px;\r\n  font-size: 20px;\r\n  color: var(--mainColor);\r\n}\r\n\r\n.error {\r\n  font-size: 16px;\r\n  color: red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "ImportStep__description__KfTN5",
	"icon": "ImportStep__icon__b4_Op",
	"error": "ImportStep__error__qUkTE"
};
export default ___CSS_LOADER_EXPORT___;
