import { TServerEntity } from '../models/entities.types';
import { apiBundle } from './api/api-bundle';
import { LocalStorageDaoService } from './dao/LocalStorageDaoService';

export class ServerStoreService {
    loadServersFromLocalStorage(): TServerEntity[] {
        const json = LocalStorageDaoService.getServers();
        if (!json) {
            return [];
        }
        try {
            const storedServerList: TServerEntity[] = JSON.parse(json);

            return storedServerList.map((storedServer) => ({
                ...storedServer,
                api: apiBundle(storedServer.id, `${storedServer.url}`),
            }));
        } catch {
            console.error(`Fail parse servers from store ${json}`);

            return [];
        }
    }
}

let serverStoreInstance: ServerStoreService;

export function serverStoreService(): ServerStoreService {
    if (!serverStoreInstance) {
        serverStoreInstance = new ServerStoreService();
    }

    return serverStoreInstance;
}
