import {
    SCRIPT_SCHEDULE_LOADING,
    SCRIPT_SCHEDULE_LOAD_ALL,
    SCRIPT_SCHEDULE_SET,
    SCRIPT_SCHEDULE_TASK_DELETE,
    SCRIPT_SCHEDULE_TASK_EDIT,
} from '../actionsTypes/entities/script.actionTypes';
import {
    TDeleteScheduledTaskAction,
    TEditScheduledTaskAction,
    TEditScheduledTaskPayload,
    TLoadScriptScheduleAction,
    TSchedulerTaskWithName,
    TSetScriptScheduleAction,
    TSetScriptSchedulerLoadingAction,
} from './scriptScheduler.actions.types';

export const loadScriptSchedule = (serverId: string): TLoadScriptScheduleAction => ({
    type: SCRIPT_SCHEDULE_LOAD_ALL,
    payload: { serverId },
});

export const setScriptSchedule = (serverId: string, schedule: TSchedulerTaskWithName[]): TSetScriptScheduleAction => ({
    type: SCRIPT_SCHEDULE_SET,
    payload: {
        serverId,
        schedule,
    },
});

export const deleteScheduledTask = (serverId: string, taskIds: string[]): TDeleteScheduledTaskAction => ({
    type: SCRIPT_SCHEDULE_TASK_DELETE,
    payload: {
        serverId,
        taskIds,
    },
});

export const setLoadingScriptSchedule = (serverId: string, isLoading: boolean): TSetScriptSchedulerLoadingAction => ({
    type: SCRIPT_SCHEDULE_LOADING,
    payload: {
        serverId,
        isLoading,
    },
});

export const editScheduledTask = (payload: TEditScheduledTaskPayload): TEditScheduledTaskAction => ({
    type: SCRIPT_SCHEDULE_TASK_EDIT,
    payload,
});
