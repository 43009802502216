import React, { FC, useState } from 'react';
import theme from './ReportEditorFilterBar.scss';
import { SearchInput } from '@/modules/UIKit/components/Select/SearchInput.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import icRefresh from '@/resources/icons/ic-update.svg';
import icDelete from '@/resources/icons/Deleted.svg';
import messages from './ReportEditorFilterBar.messages';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { reportDeleteNodes, reportRefresh } from '../../actions/report.actions';
import { NodeId } from '@/serverapi/api';
import { ConfirmationDialog } from './ConfirmationDialog.component';

type TReportEditorFilterBarProps = {
    reportNodeId: NodeId;
    selectedNodeIds: NodeId[];
    searchValue: string;
    disableSearchButtons: boolean;
    showDeleteBtn: boolean;
    setSearchValue: (value: string) => void;
    incIndex: () => void;
    decIndex: () => void;
};

export const ReportEditorFilterBar: FC<TReportEditorFilterBarProps> = ({
    reportNodeId,
    searchValue,
    disableSearchButtons,
    selectedNodeIds,
    showDeleteBtn,
    setSearchValue,
    incIndex,
    decIndex,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [open, setOpen] = useState<boolean>(false);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== searchValue) {
            setSearchValue(e.target.value.trim().toLowerCase());
        }
    };

    const handleClearSearch = () => setSearchValue('');

    const refreshHandler = () => {
        dispatch(reportRefresh(reportNodeId));
    };

    const deletehHandler = () => {
        dispatch(reportDeleteNodes(reportNodeId, selectedNodeIds));
        setOpen(false);
    };

    return (
        <div className={theme.filterBarContainer}>
            <div className={theme.searchContainer}>
                <SearchInput
                    showSearch
                    originalTheme
                    searchValue={searchValue}
                    onSearch={handleSearch}
                    allowClear
                    onClear={handleClearSearch}
                />

                <Button visualStyle="text" size="small" onClick={decIndex} disabled={disableSearchButtons}>
                    <UpOutlined />
                </Button>
                <Button visualStyle="text" size="small" onClick={incIndex} disabled={disableSearchButtons}>
                    <DownOutlined />
                </Button>
            </div>
            <div className={theme.actionBtnsContainer}>
                <Button
                    onClick={refreshHandler}
                    visualStyle={{ type: 'text' }}
                    size="small"
                    tooltip={intl.formatMessage(messages.refresh)}
                    icon={icRefresh}
                />
                {showDeleteBtn && (
                    <>
                        <Button
                            disabled={selectedNodeIds.length === 0}
                            onClick={() => setOpen(true)}
                            visualStyle={{ type: 'text' }}
                            size="small"
                            tooltip={intl.formatMessage(messages.deleteMessage)}
                            icon={icDelete}
                        />
                        <ConfirmationDialog open={open} onDelete={deletehHandler} onCancel={() => setOpen(false)} />
                    </>
                )}
            </div>
        </div>
    );
};
