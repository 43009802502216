// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label__label__zg_dQ{position:relative;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;cursor:default}.Label__label__text__Y0pJf{font-size:12px;font-weight:400;line-height:18px;color:var(--grey-dark-UIKit);margin-bottom:4px}.Label__label__required__uz1Wz{margin-right:2px;vertical-align:top;color:var(--TextRed600-UIKit)}.Label__label__error___SGA_{padding-left:8px;margin-top:2px;font-size:12px;line-height:20px;font-weight:400;color:var(--TextRed600-UIKit)}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Label/Label.scss"],"names":[],"mappings":"AAAA,qBACI,iBAAA,CACA,0BAAA,CAAA,0BAAA,CAAA,mBAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,cAAA,CAEA,2BACI,cAAA,CACA,eAAA,CACA,gBAAA,CACA,4BAAA,CACA,iBAAA,CAGJ,+BACI,gBAAA,CACA,kBAAA,CACA,6BAAA,CAGJ,4BACI,gBAAA,CACA,cAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,6BAAA","sourcesContent":[".label {\r\n    position: relative;\r\n    display: inline-flex;\r\n    flex-direction: column;\r\n    cursor: default;\r\n\r\n    &__text {\r\n        font-size: 12px;\r\n        font-weight: 400;\r\n        line-height: 18px;\r\n        color: var(--grey-dark-UIKit);\r\n        margin-bottom: 4px;\r\n    }\r\n\r\n    &__required {\r\n        margin-right: 2px;\r\n        vertical-align: top;\r\n        color: var(--TextRed600-UIKit);\r\n    }\r\n\r\n    &__error {\r\n        padding-left: 8px;\r\n        margin-top: 2px;\r\n        font-size: 12px;\r\n        line-height: 20px;\r\n        font-weight: 400;\r\n        color: var(--TextRed600-UIKit);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "Label__label__zg_dQ",
	"label__text": "Label__label__text__Y0pJf",
	"label__required": "Label__label__required__uz1Wz",
	"label__error": "Label__label__error___SGA_"
};
export default ___CSS_LOADER_EXPORT___;
