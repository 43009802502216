import type { TEdgeManagementDialogSubmitData } from '../components/EdgeManagement/EdgeManagementDialog.types';
import { connect } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { EdgeManagementDialog } from '../components/EdgeManagement/EdgeManagementDialog.component';
import { submitEdgeManagementDialog } from '@/actions/edgeManagement.actions';

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (submitData: TEdgeManagementDialogSubmitData) => dispatch(submitEdgeManagementDialog(submitData)),
    onCancel: () => dispatch(closeDialog(DialogType.EDGE_MANAGEMENT_DIALOG)),
});

export const EdgeManagementDialogContainer = connect(null, mapDispatchToProps)(EdgeManagementDialog);

