// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Monitoring__tabContent__nANaU{height:100%;max-height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;overflow:hidden}.Monitoring__wrapper__yANvC{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:100%;padding:10px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Monitoring/components/Monitoring.scss"],"names":[],"mappings":"AACI,+BACI,WAAA,CACA,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,eAAA,CAGJ,4BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[":local {\r\n    .tabContent {\r\n        height: 100%;\r\n        max-height: 100%;\r\n        display: flex;\r\n        flex-direction: column;\r\n        justify-content: space-between;\r\n        overflow: hidden;\r\n    }\r\n    \r\n    .wrapper {\r\n        display: flex;\r\n        flex-direction: column;\r\n        height: 100%;\r\n        padding: 10px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContent": "Monitoring__tabContent__nANaU",
	"wrapper": "Monitoring__wrapper__yANvC"
};
export default ___CSS_LOADER_EXPORT___;
