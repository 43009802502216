import { TReducer } from '../utils/types';
import { SET_PROCESS_INDICATOR } from '../actionsTypes/statusBar.actionTypes';
import { TStatusBarState } from './statusBar.reducer.types';

const initial: TStatusBarState = {
    processes: [],
};

export const StatusBarReducer: TReducer<TStatusBarState> = (state = initial, action) => {
    switch (action.type) {
        case SET_PROCESS_INDICATOR: {
            const { enable, process } = action.payload;
            if (enable) {
                return { ...state, processes: [...state.processes, process] };
            } 
            state.processes = state.processes.filter((p) => p.id !== process.id);

            return { ...state, processes: [...state.processes.filter((p) => p.id !== process.id)] };
            
        }
        default: {
            return state;
        }
    }
};
