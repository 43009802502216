import React, { FC, useEffect, useState } from 'react';
import { ArisImportDescriptor, NodeId } from '../../../../../serverapi/api';
import { PreloaderAris } from '../Preloader/PreloaderAris';
import style from './AnalysisStep.scss';
import { CheckOutlined, InfoCircleOutlined, StopOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import messages from '../../messages/ImportAris.messages';
import { setArisResponseError } from '../../../../../actions/importAris.actions';
import { useDispatch, useSelector } from 'react-redux';
import { ImportArisSelectors } from '../../../../../selectors/importAris.selectors';

type TAnalysisStep = {
    nodeId: NodeId;
};

const AnalysisStep: FC<TAnalysisStep> = ({ nodeId }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { serverId, repositoryId, id } = nodeId;

    const descriptor: ArisImportDescriptor = useSelector(
        ImportArisSelectors.getArisDescriptor(serverId, repositoryId, id),
    );

    const { arisData, error } = descriptor;

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (arisData) {
            setLoading(false);
            dispatch(setArisResponseError({ nodeId, error: undefined }));
        }
    }, [arisData]);

    useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    const getDescription = () => {
        if (loading) {
            return (
                <>
                    <div className={style.analyses_description}>{intl.formatMessage(messages.startAnalysesFile)}</div>
                    <InfoCircleOutlined className={style.analyses_icon_download} />
                </>
            );
        }

        return (
            <>
                {error ? (
                    <div className={style.analyses_error}>
                        {error}
                        <StopOutlined className={style.analyses_icon_error} />
                    </div>
                ) : (
                    <div className={style.analyses_description}>
                        {intl.formatMessage(messages.analysesFileComplete)}
                        <CheckOutlined className={style.analyses_icon_info} />
                    </div>
                )}
            </>
        );
    };

    return (
        <div className={style.container}>
            <div className={style.analyses}>{getDescription()}</div>
            {loading && <PreloaderAris />}
        </div>
    );
};

export default AnalysisStep;
