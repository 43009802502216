import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { ApiBundle } from '../api/api-bundle';
import { SchedulerTask } from '@/serverapi/api';

export class SchedulerDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getSchedulerTask(taskId: string): Promise<SchedulerTask> {
        const api = this.getApi();
        const schedulerTask: SchedulerTask = await api.scheduler.get({ id: taskId });

        return schedulerTask;
    }

    public static async updateSchedulerTask(schedulerTask: SchedulerTask): Promise<SchedulerTask> {
        const api = this.getApi();
        const updatedSchedulerTask: SchedulerTask = await api.scheduler.update({ body: schedulerTask });

        return updatedSchedulerTask;
    }
}

