import React, { ForwardedRef, forwardRef } from 'react';
import classnames from 'classnames';
import theme from './Input.scss';
import { IInputProps } from './Input.types';
import { noop } from 'lodash-es';
import { Label } from '@/modules/UIKit/components/Label/Label.component';
import { Icon } from '@/modules/UIKit/components/Icon/Icon.component';

/**
 * ссылка на фигму (https://www.figma.com/design/QwNP21XezszK7TasNIoDQf/SDS-Design-KIT?node-id=10-18&t=qxHCmm3HZbbQAPbx-0)
 */

export const Input = forwardRef(
    (
        {
            className = '',
            classNameInput = '',
            isError,
            label,
            name,
            error,
            isRequired,
            leftIcon,
            rightIcon,
            isDisabled,
            onClick = noop,
            onChange,
            dataTest,
            ...rest
        }: IInputProps,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        return (
            <Label
                name={name}
                isDisabled={isDisabled}
                isError={isError}
                label={label}
                error={error}
                isRequired={isRequired}
                className={className}
            >
                <div
                    onClick={onClick}
                    className={classnames(theme.input, {
                        [theme.input_iconLeft]: !!leftIcon,
                        [theme.input_iconRight]: !!rightIcon,
                    })}
                    data-testid={dataTest}
                    role="button"
                    tabIndex={0}
                    onKeyDown={noop}
                >
                    {!!leftIcon && (
                        <span className={theme.inputLeftIcon}>
                            <Icon spriteSymbol={leftIcon} />
                        </span>
                    )}
                    <input
                        id={name}
                        name={name}
                        className={classnames(theme.inputField, classNameInput)}
                        onClick={onClick}
                        ref={ref}
                        disabled={isDisabled}
                        onChange={onChange}
                        {...rest}
                    />
                    {!!rightIcon && (
                        <span className={theme.inputRightIcon}>
                            <Icon spriteSymbol={rightIcon} />
                        </span>
                    )}
                </div>
            </Label>
        );
    },
);
