import { SYMBOLS_DELETE_REQUEST_SUCCESS, SYMBOLS_REQUEST_SUCCESS } from '../actionsTypes/symbol.actionTypes';
import { Symbol } from '../serverapi/api';
import {
    TSymbolsDeleteRequestPayload,
    TSymbolsDeleteRequestAction,
    TSymbolsRequestSuccessAction,
} from './symbol.actions.types';

export const symbolsDeleteRequest = (payload: TSymbolsDeleteRequestPayload): TSymbolsDeleteRequestAction => ({
    type: SYMBOLS_DELETE_REQUEST_SUCCESS,
    payload,
});

export const symbolsRequestSuccess = (
    serverId: string,
    data: Symbol[],
    presetId: string,
): TSymbolsRequestSuccessAction => ({
    type: SYMBOLS_REQUEST_SUCCESS,
    payload: {
        serverId,
        data,
        presetId,
    },
});
