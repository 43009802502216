export const MIN_PACKAGE_SYMBOL_HEIGHT = 110;

export const MIN_PACKAGE_SYMBOL_WIDTH = 50;

export const MAX_PACKAGE_SYMBOL_WIDTH = 720;

export const PACKAGE_SYMBOL_LINE_HEIGHT = 26;

export const PACKAGE_VERTICAL_OVERFLOW_SYMBOL = `\u2003\u2003\u2003${String.fromCharCode(8943)}`;

export enum UML_ATTR_PACKAGE {
    STEREOTYPE = 'a_uml_package_stereotype',
    VISIBILITY = 'a_uml_package_visibility',
}

export const UML_PACKAGE_HEADER = 'uml.package.header';
