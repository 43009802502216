import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'DashboardCellsCountDialog.title',
        defaultMessage: 'Настройки сетки',
    },

    verticalCount: {
        id: 'DashboardCellsCountDialog.verticalCount',
        defaultMessage: 'Ячеек по вертикали',
    },
    horizontalCount: {
        id: 'DashboardCellsCountDialog.horizontalCount',
        defaultMessage: 'Ячеек по горизонтали',
    },
    cancel: {
        id: 'DashboardCellsCountDialog.cancel',
        defaultMessage: 'Отмена',
    },
    apply: {
        id: 'DashboardCellsCountDialog.apply',
        defaultMessage: 'Применить',
    },
    error: {
      id: 'DashboardCellsCountDialog.error',
      defaultMessage: 'Доступны значения от 4 до 50',
  },
});
