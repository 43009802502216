import { IDBPDatabase, openDB } from 'idb';
import { TCacheDb } from './indexedDb.types';

const DB_NAME = 'cache';
const STORE_NAME = 'presets';

const connectDb = async (name: string, store: string): Promise<IDBPDatabase> => {
    const db: IDBPDatabase = await openDB(name, 1, {
        upgrade(database) {
            database.createObjectStore(store);
        },
    });

    return db;
};

export const getPresetFromIndexedDb = async (presetId: string): Promise<TCacheDb | undefined> => {
    const db = await connectDb(DB_NAME, STORE_NAME);

    const transaction = db.transaction(STORE_NAME);
    const preset = await transaction.store.get(presetId);
    await transaction.done;

    return preset;
};

export const setPresetToIndexedDb = async (presetId: string, preset: object, version: number): Promise<void> => {
    const db = await connectDb(DB_NAME, STORE_NAME);

    const transaction = db.transaction(STORE_NAME, 'readwrite');
    await transaction.store.put({ preset, version }, presetId);
    await transaction.done;
};
