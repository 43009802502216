import { SET_PROCESS_INDICATOR } from '../actionsTypes/statusBar.actionTypes';
import { TExecutingProcess } from '../reducers/statusBar.reducer.types';
import { TSetProcessIndicatorAction } from './statusBar.actions.types';

export const setProcessIndicator = (enable: boolean, process: TExecutingProcess): TSetProcessIndicatorAction => ({
    type: SET_PROCESS_INDICATOR,
    payload: {
        enable,
        process,
    },
});
