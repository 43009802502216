import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { PRESET_SETTINGS_INITIAL_KANBAN_MODEL_TYPE_SERVER_STATE } from '../../reducers/presetSettings/presetSettingsKanbanModelType.reducer';

const kanbanModelTypeStateSelector = (state: TRootState) => state.presetSettings.kanbanModelTypes;

export namespace PresetSettingsKanbanModelTypeSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector(kanbanModelTypeStateSelector, (state) => {
            return state.byPresetId[presetId] || PRESET_SETTINGS_INITIAL_KANBAN_MODEL_TYPE_SERVER_STATE;
        });

    export const listByPresetId = (presetId: string) =>
        createSelector(byPresetId(presetId), (state) => Object.values(state.byId));
}
