import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'PresetProfile.name',
        defaultMessage: 'Название',
    },
    id: {
        id: 'PresetProfile.id',
        defaultMessage: 'ID',
    },
    create: {
        id: 'PresetProfile.create',
        defaultMessage: 'Создание',
    },
    read: {
        id: 'PresetProfile.read',
        defaultMessage: 'Чтение',
    },
    update: {
        id: 'PresetProfile.update',
        defaultMessage: 'Изменение',
    },
    delete: {
        id: 'PresetProfile.delete',
        defaultMessage: 'Удаление',
    },
    allowAll: {
        id: 'PresetProfile.allowAll',
        defaultMessage: 'Разрешить все',
    },
    denyAll: {
        id: 'PresetProfile.denyAll',
        defaultMessage: 'Запретить все',
    },
});
