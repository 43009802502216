import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'MatrixSettingsDialog.title',
        defaultMessage: 'Свойства матричной модели:',
    },
    settingsLinks: {
        id: 'MatrixSettingsDialog.settingsLinks',
        defaultMessage: 'Представление связей',
    },
    okButton: {
        id: 'MatrixSettingsDialog.okButton',
        defaultMessage: 'Сохранить',
    },
    cancelButton: {
        id: 'MatrixSettingsDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
});
