import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { UserDTO } from '../serverapi/api';

export const usersState = (state: TRootState) => state.users;

export namespace UsersSelectors {
    export const fetchAllUsersLoading = () => createSelector(usersState, (state) => state.fetchAllUsersLoading);
    export const byServerId = (serverId: string) =>
        createSelector(usersState, (state) => {
            return state.byServerId[serverId];
        });

    export const byId = (serverId: string, userId: number) =>
        createSelector(byServerId(serverId), (state) => (state && state.byId && state.byId[userId]) || <UserDTO>{});

    export const getAll = (serverId: string) =>
        createSelector(byServerId(serverId), (state) => state && (Object.values(state.byId) as UserDTO[]));

    export const getEditingUser = (userId: string) =>
        createSelector(usersState, (state) => state.editingUser.byId[userId] || <UserDTO>{});

    export const getUserSessions = (userId: number) =>
        createSelector(usersState, (state) => state.usersSessions && state.usersSessions[userId]);

    export const byGroupId = (serverId: string, groupdId: number) =>
        createSelector(byServerId(serverId), (state) =>
            state && state.byGroupId && state.byGroupId[groupdId]
                ? (Object.values(state.byGroupId[groupdId])
                      .filter((userId: number) => userId && state.byId[userId])
                      .map((userId: number) => state.byId[userId]) as UserDTO[])
                : Array<UserDTO>(),
        );
}
