import { SubscriptionRegistry } from './SubscriptionRegistry';
import { StompClientDecorator } from './StompClientDecorator';
import { Subscription } from './Subscription';

export class WSClient {
    private readonly subscriptionRegistry: SubscriptionRegistry;
    private readonly stompClientDecorator: StompClientDecorator;
    private readonly connectionTimeout: number = 10000;
    private readonly connectionInterval: number = 20000;
    private readonly healthcheck: string = 'healthcheck';
    private healthCheckInterval: ReturnType<typeof setTimeout> | null = null;
    private connectionLost: boolean = false;

    public constructor(stompClientDecorator: StompClientDecorator, connectionTimeout?: number) {
        this.subscriptionRegistry = new SubscriptionRegistry(stompClientDecorator);
        this.stompClientDecorator = stompClientDecorator;
        if (connectionTimeout) {
            this.connectionTimeout = connectionTimeout;
        }
    }

    disconnect = (disconnectCallback?: () => any) => {
        this.stopHealthCheckInterval();
        this.stompClientDecorator.disconnect(disconnectCallback);
    };

    sendHealthCheck = (url: string) => {
        if (!this.stompClientDecorator.isConnected()) {
            return;
        }

        this.stompClientDecorator.send(url, {}, this.healthcheck);
    };

    startHealthCheckInterval = (url: string) => {
        this.healthCheckInterval = setInterval(() => this.sendHealthCheck(url), this.connectionInterval);
    };

    stopHealthCheckInterval = () => {
        if (this.healthCheckInterval) {
            clearInterval(this.healthCheckInterval);
            this.healthCheckInterval = null;
        }
    };

    connect = (onConnectSuccess: () => void, onReconnectSuccess: () => void, onConnectError: () => void) => {
        this.stompClientDecorator.connect(
            () => {
                // не показывать реконнект при первом самом первом соединения
                if (this.connectionLost) {
                    onReconnectSuccess();
                }
                this.subscriptionRegistry.resubscribeAll();
                onConnectSuccess();
                this.connectionLost = false;
                this.startHealthCheckInterval(this.stompClientDecorator.getUrl());
            },
            () => {
                // показываем всплывашку о потере соединения только 1 раз(последующие реконнекты игнорим)
                if (!this.connectionLost) {
                    onConnectError();
                }
                this.connectionLost = true;
                this.stopHealthCheckInterval();
                setTimeout(
                    () => this.connect(onConnectSuccess, onReconnectSuccess, onConnectError),
                    this.connectionTimeout,
                );
            },
        );
    };

    subscribe = (endpointId: string, msgHandler: (message: any) => void): Subscription | undefined => {
        return this.subscriptionRegistry.subscribe(endpointId, msgHandler);
    };

    unsubscribe = (endpointId: string) => {
        this.subscriptionRegistry.unsubscribe(endpointId);
    };

    unsubscribeAll = () => {
        this.subscriptionRegistry.unsubscribeAll();
    };
}
