import { NodeId } from 'src/serverapi/api';
import {
    ALL_PRESETS_META_DATA_REQUEST,
    PRESET_META_DATA_REQUEST,
    PRESET_META_DATA_REQUEST_FAIL,
    PRESET_META_DATA_REQUEST_SUCCESS,
    PRESET_META_DATA_REQUEST_WITH_PRESET_ID,
} from '../actionsTypes/notation.actionTypes';
import {
    TPresetMetaDataRequestFinished,
    TPresetMetaDataRequestAction,
    TAllPresetsMetaDataRequestAction,
    TPresetMetaDataRequestWithPresetIdAction,
} from './notation.actions.types';

export const presetMetaDataRequest = ( presetIds: string[]): TPresetMetaDataRequestAction => ({
    type: PRESET_META_DATA_REQUEST,
    payload: {
        presetIds,
    },
});

export const AllPresetsMetaDataRequest = (): TAllPresetsMetaDataRequestAction => ({
    type: ALL_PRESETS_META_DATA_REQUEST,
});

export const presetMetaDataRequestSuccess = (): TPresetMetaDataRequestFinished => ({
    type: PRESET_META_DATA_REQUEST_SUCCESS,
});

export const presetMetaDataRequestFail = (): TPresetMetaDataRequestFinished => ({
    type: PRESET_META_DATA_REQUEST_FAIL,
});

export const presetMetaDataRequestWithPresetId = (nodeId: NodeId): TPresetMetaDataRequestWithPresetIdAction => ({
    type: PRESET_META_DATA_REQUEST_WITH_PRESET_ID,
    payload: {
        nodeId,
    },
});
