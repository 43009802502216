import { ApprovalStageDTO, ApprovalStageDTOAbstentionVoteStrategyEnum, ApprovalUserDTO } from '../../serverapi/api';
import {
    ApprovalStageDTOAbstentionVoteStrategy,
    ApprovalStageDTOType,
} from '@/modules/ApprovalDialog/ApprovalDialog.types';
import { cloneDeep } from 'lodash-es';
export class StagesBllService {
    public static changeName(
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        value: string,
    ): ApprovalStageDTO[] | undefined {
        return stages?.map((st) => {
            return st.id === stageId ? { ...st, name: value } : st;
        });
    }

    public static changeType = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        value: ApprovalStageDTOType,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            return st.id === stageId ? { ...st, type: value } : st;
        });
    };

    public static changeVoteAllowed = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        value: boolean,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            return st.id === stageId ? { ...st, changeVoteAllowed: value } : st;
        });
    };

    public static changeFinishDate = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        scheduledFinishDate:  number | null,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            return st.id === stageId ? { ...st, scheduledFinishDate: scheduledFinishDate || undefined } : st;
        });
    };

    public static changeRateOfSuccess = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        rateOfSuccess: number | undefined,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            return st.id === stageId ? { ...st, rateOfSuccess } : st;
        });
    };

    public static changeVoteStrategy = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        value: ApprovalStageDTOAbstentionVoteStrategyEnum,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            return st.id === stageId ? { ...st, abstentionVoteStrategy: value } : st;
        });
    };

    public static changeTimeConstraints = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        value: boolean,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            if (st.id !== stageId) return st;
            const newStageData = cloneDeep(st);
            if (value) {
                newStageData.abstentionVoteStrategy = ApprovalStageDTOAbstentionVoteStrategy.APPROVED;

                return newStageData;
            }
            delete newStageData.abstentionVoteStrategy;
            delete newStageData.scheduledFinishDate;

            return newStageData;
        });
    };

    public static changeApprovalUsersDto = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        approvalUsersDTO: ApprovalUserDTO[],
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            return st.id === stageId ? { ...st, approvalUsersDTO } : st;
        });
    };

    public static approvalUserRemove = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        id: number,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            return st.id === stageId
                ? { ...st, approvalUsersDTO: st.approvalUsersDTO?.filter((userDTO) => userDTO.principalId !== id) }
                : st;
        });
    };

    public static approvalAssistantRemove = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        approvalUserId: number,
        assistantId: number,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            return st.id === stageId
                ? {
                    ...st,
                    approvalUsersDTO: st.approvalUsersDTO?.map((approvalUser) => {
                        if (approvalUser.principalId !== approvalUserId) return approvalUser;

                        return {
                            ...approvalUser,
                            assistants: approvalUser.assistants?.filter(
                                (assistant) => assistant.assistantId !== assistantId,
                            ),
                        };
                    }),
                }
                : st;
        });
    };

    public static dragEnd = (
        stages: ApprovalStageDTO[] | undefined,
        stageId: string,
        sourceIndex: number,
        destinationIndex: number,
        approvalUsersDTO: ApprovalUserDTO[],
    ): ApprovalStageDTO[] | undefined => {

        const newApprovalUsers = cloneDeep(approvalUsersDTO);
        const [removed] = newApprovalUsers.splice(sourceIndex, 1);
        newApprovalUsers.splice(destinationIndex, 0, removed);

        return stages?.map((st) => {
            return st.id === stageId
                ? { ...st, approvalUsersDTO: newApprovalUsers }
                : st;
        });
    };

    public static changeCurrentStageUsersErrorDisplay = (
        stages: ApprovalStageDTO[] | undefined,
        tabName: string,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((st) => {
            return st.id === tabName
                ? {
                    ...st,
                    approvalUsersDTO: [],
                }
                : st;
        });
    };

    public static trimStagesName = (
        stages: ApprovalStageDTO[] | undefined,
    ): ApprovalStageDTO[] | undefined => {
        return stages?.map((stage) => {
            return { ...stage, name: stage.name.trim() };
        });
    };
}
