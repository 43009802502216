import {
    OPEN_SCRIPT_SCHEDULER_DATA_CLEAR,
    OPEN_SCRIPT_SCHEDULER_DATA_SET,
} from '@/actionsTypes/openScriptSchedulerData.actionTypes';
import {
    TClearOpenScriptSchedulerDataAction,
    TSetOpenScriptSchedulerDataAction,
} from './openScriptSchedulerData.actions.types';

export const setOpenScriptSchedulerData = (payload): TSetOpenScriptSchedulerDataAction => ({
    type: OPEN_SCRIPT_SCHEDULER_DATA_SET,
    payload,
});

export const clearOpenScriptSchedulerData = (): TClearOpenScriptSchedulerDataAction => ({
    type: OPEN_SCRIPT_SCHEDULER_DATA_CLEAR,
});

