import { DELETE_EDGE_TYPE_GROUP_SUCCESS } from '../actionsTypes/edgeTypeGroup.actionTypes';
import { TReducer } from '../utils/types';
import { CANCEL_PRESET_EDIT } from '../actionsTypes/methodologySetting.actionTypes';
import { TDeletedEdgeTypeGroupState } from './deletedEdgeTypeGroup.reducer.types';

const initial: TDeletedEdgeTypeGroupState = {
    byServerId: {},
};

export const deletedEdgeTypeGroupReducer: TReducer<TDeletedEdgeTypeGroupState> = (state = initial, action) => {
    const cloneState = (serverId: string) => {
        return {
            byServerId: {
                ...state.byServerId,
                [serverId]: {
                    byPresetId: {
                        ...(state.byServerId[serverId]?.byPresetId || {}),
                    },
                },
            },
        };
    };

    switch (action.type) {
        case DELETE_EDGE_TYPE_GROUP_SUCCESS: {
            const {
                edgeTypeGroups,
                serverNode: {
                    nodeId: { serverId },
                },
            } = action.payload;
            if (edgeTypeGroups) {
                const stateClone = cloneState(serverId);
                edgeTypeGroups.forEach((modelTypeGroup) => {
                    const { presetId } = modelTypeGroup;
                    if (serverId && presetId) {
                        const byPresetId = stateClone.byServerId[serverId]?.byPresetId;
                        byPresetId[presetId] = byPresetId[presetId]?.filter((otg) => otg.id !== modelTypeGroup.id);
                    }
                });

                return stateClone;
            }

            return state;
        }

        case CANCEL_PRESET_EDIT: {
            const {
                preset,
                serverNode: {
                    nodeId: { serverId },
                },
            } = action.payload;
            if (serverId && preset?.id) {
                const stateClone = cloneState(serverId);
                delete stateClone.byServerId[serverId]?.byPresetId[preset?.id];

                return stateClone;
            }

            return state;
        }
        default:
            return state;
    }
};
