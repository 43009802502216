import { TMessage } from '../utils/forms.types';
import { FOLDER_DIALOG_SUBMIT_DATA, FOLDER_DIALOG_SUBMIT_RESULT, NAVIGATE_TO_FOLDER_NODE } from '../actionsTypes/folderDialog.actionTypes';
import {
    TPayloadFolderDialogSubmitDataAction,
    TFolderDialogSubmitDataAction,
    TFolderDialogSubmitResultAction,
    TNavigateToFolderNodeAction,
} from './folderDialog.actions.types';
import { NodeId } from '../serverapi/api';

export const folderDialogSubmit = (payload: TPayloadFolderDialogSubmitDataAction): TFolderDialogSubmitDataAction => ({
    type: FOLDER_DIALOG_SUBMIT_DATA,
    payload,
});

export const folderDialogSubmitResult = (result: string, error?: TMessage): TFolderDialogSubmitResultAction => ({
    type: FOLDER_DIALOG_SUBMIT_RESULT,
    payload: {
        result,
        error,
    },
});

export const navigateToFolderNode = (nodeId: NodeId): TNavigateToFolderNodeAction => ({
    type: NAVIGATE_TO_FOLDER_NODE,
    payload: {
        nodeId,
    },
});
