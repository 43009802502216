import {
    FOLDER_TYPE_DELETE,
    FOLDER_TYPE_DELETE_SUCCESS,
    FOLDER_TYPE_REQUEST_SUCCESS,
    FOLDER_TYPE_SAVE,
    FOLDER_TYPE_SAVE_SUCCESS,
} from '../actionsTypes/folderType.actionTypes';
import {
    TDeleteFolderTypeAction,
    TDeleteFolderTypePayload,
    TSaveFolderTypeAction,
    TSaveFolderTypePayload,
    TSuccessDeleteFolderTypeAction,
    TSuccessDeleteFolderTypePayload,
    TSuccessRequestFolderTypeAction,
    TSuccessRequestFolderTypePayload,
    TSuccessSaveFolderTypeAction,
    TSuccessSaveFolderTypePayload,
} from './folderType.actions.types';

export const successRequestFolderType = (
    payload: TSuccessRequestFolderTypePayload,
): TSuccessRequestFolderTypeAction => ({
    type: FOLDER_TYPE_REQUEST_SUCCESS,
    payload,
});

export const saveFolderType = (payload: TSaveFolderTypePayload): TSaveFolderTypeAction => ({
    type: FOLDER_TYPE_SAVE,
    payload,
});

export const deleteFolderType = (payload: TDeleteFolderTypePayload): TDeleteFolderTypeAction => ({
    type: FOLDER_TYPE_DELETE,
    payload,
});

export const successDeleteFolderType = (payload: TSuccessDeleteFolderTypePayload): TSuccessDeleteFolderTypeAction => ({
    type: FOLDER_TYPE_DELETE_SUCCESS,
    payload,
});

export const successSaveFolderType = (payload: TSuccessSaveFolderTypePayload): TSuccessSaveFolderTypeAction => ({
    type: FOLDER_TYPE_SAVE_SUCCESS,
    payload,
});