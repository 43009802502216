import { TPreset } from '../../models/preset.types';
import {
    EdgeType,
    ModelType,
    ModelTypeGroup,
    ObjectType,
    ObjectTypeGroup,
    Symbol,
    AllowedScriptContext,
    EdgeTypeGroup,
    FolderType,
} from '../../serverapi/api';
import { TreeDataNode } from 'antd';
import { LocalesService } from '../LocalesService';
import { TreeNode } from '../../models/tree.types';
import TreeItemIcon from '../../modules/Tree/components/TreeItem/TreeItemIcon.component';
import React from 'react';
import { TreeItemType } from '../../modules/Tree/models/tree';
import {
    CTreeNodeKey,
    DIVIDER,
    IEdgeTypeGroupWithTypeGroup,
    IFolderTypeGroupWithTypeGroup,
    IModelTypeGroupWithTypeGroup,
    IObjectTypeGroupWithTypeGroup,
    scriptContextTypes,
} from '../../modules/ObjectPropertiesDialog/components/ScriptContext/scriptContext.types';
import { toString } from '../../utils/nodeId.utils';
import { getGroupByContextType, getTypeByContextType } from '../../utils/scriptContext.utils';

export const getAmountOfCheckedItemsInPreset = (
    children: TreeDataNode[],
    checkedItems: string[] | undefined,
    context: AllowedScriptContext,
    contextType: scriptContextTypes,
): number => {
    let savedItemsKeys: Array<string> = [];

    switch (contextType) {
        case scriptContextTypes.runningOnObjects: {
            savedItemsKeys = context.allowedObjectTypeIds || [];
            break;
        }
        case scriptContextTypes.runningOnObjectInstances: {
            savedItemsKeys = context.allowedSymbolTypeIds || [];
            break;
        }
        case scriptContextTypes.runningOnModels: {
            savedItemsKeys = context.allowedModelTypeIds || [];
            break;
        }
        case scriptContextTypes.runningOnEdges: {
            savedItemsKeys = context.allowedEdgeTypeIds || [];
            break;
        }
        case scriptContextTypes.runningOnFolders: {
            savedItemsKeys = context.allowedFolderTypeIds || [];
            break;
        }
        case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
            savedItemsKeys =
                context.allowedModelSymbols?.flatMap((modelSymbol) =>
                    modelSymbol.symbolIds.map((symbolId) => {
                        return new CTreeNodeKey({
                            symbolId,
                            modelTypeId: modelSymbol.modelTypeIds,
                        }).getSymbolIdAndModelTypeId();
                    }),
                ) || [];
            break;
        }
        default:
            savedItemsKeys = [];
    }
    if (contextType === scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes) {
        if (checkedItems) {
            return children.map((child) => child.key).filter((key) => checkedItems.includes(key as string)).length;
        }

        return children
            .map((child) => CTreeNodeKey.keyToObject(child.key as string).getSymbolIdAndModelTypeId())
            .filter((key) => savedItemsKeys.includes(key as string)).length;
    }

    if (
        contextType === scriptContextTypes.runningOnObjects ||
        contextType === scriptContextTypes.runningOnModels ||
        contextType === scriptContextTypes.runningOnEdges ||
        contextType === scriptContextTypes.runningOnFolders ||
        contextType === scriptContextTypes.runningOnObjectInstances
    ) {
        if (checkedItems) {
            return children.map((child) => child.key).filter((key) => checkedItems.includes(key as string)).length;
        }

        return children
            .map((child) => CTreeNodeKey.keyToObject(child.key as string).getTypeId())
            .filter((key) => savedItemsKeys.includes(key as string)).length;
    }

    return 0;
};

export const getUniversalTypes = (
    contextType: scriptContextTypes,
    itemTypes: Array<ObjectType | ModelType | EdgeType | FolderType>,
) => {
    switch (contextType) {
        case scriptContextTypes.runningOnObjectInstances:
        case scriptContextTypes.runningOnObjects: {
            return (itemTypes as ObjectType[]).map((itemType) => {
                return { ...itemType, typeGroup: itemType.objectTypeGroup } as IObjectTypeGroupWithTypeGroup;
            });
        }
        case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes:
        case scriptContextTypes.runningOnModels: {
            return (itemTypes as ModelType[]).map((itemType) => {
                return { ...itemType, typeGroup: itemType.modelTypeGroup } as IModelTypeGroupWithTypeGroup;
            });
        }

        case scriptContextTypes.runningOnEdges: {
            return (itemTypes as EdgeType[]).map((itemType) => {
                return { ...itemType, typeGroup: itemType.edgeTypeGroup } as IEdgeTypeGroupWithTypeGroup;
            });
        }

        case scriptContextTypes.runningOnFolders: {
            return (itemTypes as FolderType[]).map((itemType) => {
                return { ...itemType, typeGroup: undefined } as IFolderTypeGroupWithTypeGroup;
            });
        }

        default: {
            return [];
        }
    }
};

export const ObjectTypesToTreeDataChildren = (
    typesGroups:
        | {
              byId: {
                  [id: string]: ModelTypeGroup | ObjectTypeGroup | EdgeTypeGroup;
              };
          }
        | undefined,
    types: ObjectType[] | ModelType[] | EdgeType[] | FolderType[],
    contextType: scriptContextTypes,
    symbolsTypes?: Symbol[],
): TreeDataNode[] => {
    const locale = LocalesService.getLocale();

    const univeralTypes: Array<
        | IObjectTypeGroupWithTypeGroup
        | IModelTypeGroupWithTypeGroup
        | IEdgeTypeGroupWithTypeGroup
        | IFolderTypeGroupWithTypeGroup
    > = getUniversalTypes(contextType, types);
    const typesGroupsArr: ModelTypeGroup[] | ObjectTypeGroup[] | EdgeTypeGroup[] = Object.values(
        typesGroups?.byId || {},
    );
    const typesWithoutGroups = univeralTypes.filter((type) => !type.typeGroup?.id);

    const childrenWithoutGroups: TreeDataNode[] = typesWithoutGroups.map((type) => {
        return {
            title: LocalesService.internationalStringToString(type.multilingualName, locale),
            key: new CTreeNodeKey({
                presetId: type.presetId,
                ...getTypeByContextType(contextType, type.id),
            }).createKey(),
            children: [],
            id: type.id,
            isLeaf: true,
        };
    });
    const GroupsWithCildren: TreeDataNode[] = typesGroupsArr.map((group) => {
        return {
            title: LocalesService.internationalStringToString(group.multilingualName, locale),
            key: new CTreeNodeKey({
                presetId: group.presetId,
                ...getGroupByContextType(contextType, group.id),
            }).createKey(),
            children: univeralTypes
                .filter((type) => type.typeGroup?.id === group.id)
                .map((child) => {
                    const children =
                        (symbolsTypes && getSymbolItems(child.id, symbolsTypes)) ||
                        (contextType === scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes &&
                            getModelSymbolItems(child as ModelType)) ||
                        [];

                    return {
                        title: LocalesService.internationalStringToString(child.multilingualName, locale),
                        key: new CTreeNodeKey({
                            presetId: child.presetId,
                            ...getTypeByContextType(contextType, child.id),
                        }).createKey(),
                        children,
                        id: child.id,
                        isLeaf:
                            !symbolsTypes &&
                            contextType !== scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes,
                    };
                }),
            id: group.id,
        };
    });

    const allChildren: TreeDataNode[] = [...childrenWithoutGroups, ...GroupsWithCildren];

    return allChildren;
};

const getSymbolItems = (parentId: string, symbolsTypes: Symbol[]): TreeDataNode[] => {
    const locale = LocalesService.getLocale();

    return symbolsTypes
        .filter((type) => type.objectType === parentId)
        .map((type) => {
            return {
                title: LocalesService.internationalStringToString(type.multilingualName, locale),
                key: new CTreeNodeKey({
                    presetId: type.presetId,
                    symbolId: type.id,
                    objectTypeId: parentId,
                }).createKey(),
                children: [],
                id: type.id,
                isLeaf: true,
            };
        });
};

const getModelSymbolItems = (modelType: ModelType): TreeDataNode[] => {
    const locale = LocalesService.getLocale();

    return modelType.symbols.map((symbol) => {
        return {
            title: LocalesService.internationalStringToString(symbol.multilingualName, locale),
            key: new CTreeNodeKey({
                presetId: modelType.presetId,
                symbolId: symbol.id,
                modelTypeId: modelType.id,
            }).createKey(),
            children: [],
            id: symbol.id,
            modelTypeId: modelType.id,
            isLeaf: true,
        };
    });
};

export const treeDataWithRoots = (preset: TPreset, children: TreeDataNode[]): TreeDataNode => {
    const locale = LocalesService.getLocale();

    return {
        title: LocalesService.internationalStringToString(preset.multilingualName, locale),
        key: new CTreeNodeKey({ presetId: preset.id }).createKey(),
        children,
        checkable: false,
        isLeaf: false,
    };
};

export const getChildren = (treeNodes: TreeNode[], showModels: boolean, showObjects: boolean): TreeDataNode[] => {
    const locale = LocalesService.getLocale();

    return treeNodes
        .filter((child) => showModels || child.type !== TreeItemType.Model)
        .filter((child) => showObjects || child.type !== TreeItemType.ObjectDefinition)
        .map((node) => {
            const children: TreeDataNode[] = node.children ? getChildren(node.children, showModels, showObjects) : [];

            return {
                title: LocalesService.internationalStringToString(node.multilingualName, locale),
                key: toString(node.nodeId, DIVIDER),
                children,
                icon: <TreeItemIcon {...{ nodeId: node.nodeId, type: node.type }} />,
                nodeId: node.nodeId,
                isLeaf: !node.hasChildren,
            };
        });
};

export const getNodesTreeData = (servers: TreeNode[], showModels: boolean, showObjects: boolean): TreeDataNode[] => {
    const allowedRepositories: TreeNode[] =
        servers[0].children?.filter((child) => child.type !== TreeItemType.AdminTool) || [];
    const locale = LocalesService.getLocale();

    return allowedRepositories.map((item) => {
        const children = getChildren(item.children || [], showModels, showObjects);

        return {
            title: LocalesService.internationalStringToString(item.multilingualName, locale),
            key: toString(item.nodeId, DIVIDER),
            children,
            nodeId: item.nodeId,
            icon: <TreeItemIcon {...{ nodeId: item.nodeId, type: item.type }} />,
            isLeaf: !item.hasChildren,
        };
    });
};
