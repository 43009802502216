/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
import { EditorBlock } from 'draft-js';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Map } from 'immutable';
import { camelCase } from 'lodash-es';
import { getBlockAlignClass } from '../BlockStyleRenderer';
import theme from './TableRenderer.component.scss';
import { addHorizontalResizeListeners, addVerticalResizeListeners } from './TableResizeRenderer.utils';
import WikiTableContextMenuComponent from './TableContextMenu.component';
import { addMultiSelectionListeners } from './TableMultiSelection.utils';
import TableHeadControl from './TableHeadControl.component';
import BlockTree from './blockTree';
import {
    WIKI_TABLE_KEY,
    WIKI_TABLE_POSITION_KEY,
    WIKI_TABLE_ROW_STYLE_KEY,
    WIKI_TABLE_SHAPE_KEY,
    WIKI_TABLE_TABLE_STYLE_KEY,
} from './WikiTable.constants';

export const isTablePortalBlock = (block) =>
    block.getData().get(WIKI_TABLE_POSITION_KEY) && !block.getData().get(WIKI_TABLE_SHAPE_KEY);

export const isTableShapeBlock = (block) => !!block.getData().get(WIKI_TABLE_SHAPE_KEY);

export const getTableBlockType = (block) => {
    if (isTablePortalBlock(block)) {
        return 'PortalBlock';
    }
    if (isTableShapeBlock(block)) {
        return 'ShapeBlock';
    }

    return 'TableBlock';
};

const TABLE_HEAD_COLOR = '#d0d7de';

const getCellStyle = (cell, showHeader, rowIndex) => ({
    width: cell?.style?.width,
    height: cell?.style?.height,
    backgroundColor: showHeader && rowIndex === 0 ? TABLE_HEAD_COLOR : cell?.style?.backgroundColor,
    overflow: 'hidden',
});

export const TableShapeBlock = (editorBlockProps) => {
    const {
        block,
        blockProps: { onResize, getEditor },
    } = editorBlockProps;
    const ref = useRef<any>();
    const renderCondition = ref?.current?.querySelectorAll('td').length;

    useEffect(() => {
        if (!ref.current) {
            return () => { };
        }

        const removeVEventListeners = addVerticalResizeListeners(ref.current, (sizes) => {
            onResize(block, { width: sizes });
        });

        const removeHEventListeners = addHorizontalResizeListeners(ref.current, (sizes) => {
            onResize(block, { height: sizes });
        });

        const removeMultiSelectionListeners = addMultiSelectionListeners(ref.current);

        return () => {
            removeMultiSelectionListeners();
            removeHEventListeners();
            removeVEventListeners();
        };
    }, [renderCondition]);

    const data = block.getData();
    const tableKey = data.get(WIKI_TABLE_KEY);
    const tableStyle = Map<object>(data.get(WIKI_TABLE_TABLE_STYLE_KEY))
        .mapKeys((k) => camelCase(k))
        .toJS();

    const tableShape = data.get(WIKI_TABLE_SHAPE_KEY);
    const alignClass = getBlockAlignClass(block);
    const editor = getEditor();
    const { editorState, changeEditorState } = editor.state;

    // const showHeader = isShowTableHeader(editorState, tableKey);
    const blockTree = new BlockTree(editorState, changeEditorState);
    const showHeader = blockTree.isShowTableHeader(tableKey);
    const sortAvailable = blockTree.isSortAvailable(tableKey);

    return (
        <table
            key={tableKey}
            style={tableStyle}
            ref={ref}
            id={tableKey}
            className={theme.table}
            onContextMenu={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <tbody>
                {tableShape.map((row, i) => (
                    <tr
                        key={`${tableKey}-tr-${i}`}
                        style={
                            data.get(WIKI_TABLE_ROW_STYLE_KEY)[i] &&
                            Map<object>(data.get(WIKI_TABLE_ROW_STYLE_KEY)[i])
                                .mapKeys((k) => camelCase(k))
                                .toJS()
                        }
                    >
                        {row.map((cell, j) => {
                            return (
                                <td
                                    key={`${tableKey}-td-${i}-${j}`}
                                    colSpan={cell.colspan}
                                    rowSpan={cell.rowspan}
                                    data-position={`${tableKey}-${i}-${j}`}
                                    style={getCellStyle(cell, showHeader, i)}
                                >
                                    {sortAvailable && i === 0 && (
                                        <TableHeadControl tableKey={tableKey} columnIndex={j} getEditor={getEditor} />
                                    )}
                                    <div data-test="wiki-editor_container_table_column-boundary" className={`${theme.vSizerContainer} js-vsizer`}></div>
                                    {!!(i === 0 && j === 0) && (
                                        <WikiTableContextMenuComponent editor={getEditor()} block={block}>
                                            <div className={alignClass}>
                                                <EditorBlock {...editorBlockProps} />
                                            </div>
                                        </WikiTableContextMenuComponent>
                                    )}
                                    <div data-test="wiki-editor_container_table_line-boundary" className={`${theme.hSizerContainer} js-hsizer`}></div>
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export const TableBlock = (editorBlockProps) => <EditorBlock {...editorBlockProps} />;

export const TablePortalBlock = (editorBlockProps) => {
    const {
        block,
        blockProps: { getEditor },
    } = editorBlockProps;
    const position = block.getData().get(WIKI_TABLE_POSITION_KEY);
    const editor = getEditor();
    const { wrapper } = editor;
    const target = (wrapper || document).querySelector(`[data-position='${position}']`);

    const alignClass = getBlockAlignClass(block);
    if (target) {
        return createPortal(
            <WikiTableContextMenuComponent editor={editor} block={block}>
                <div className={alignClass}>
                    <EditorBlock {...editorBlockProps} />
                </div>
            </WikiTableContextMenuComponent>,
            target,
        );
    }

    return null;
};
