import { SET_SCRIPTS_NODES } from "../actionsTypes/fetchScripts.actionTypes";
import { TSetScriptsNodesAction } from "./fetchScripts.actions.types";
import { Node } from "../serverapi/api";

export const setScriptsNodesAction = (scriptNodes: Node[]): TSetScriptsNodesAction => ({
    type: SET_SCRIPTS_NODES,
    payload: {
        scriptNodes,
    }
});
