import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'NotificationSettingsDialog.title',
        defaultMessage: 'Новый тип уведомлений',
    },
    editTitle: {
        id: 'NotificationSettingsDialog.editTitle',
        defaultMessage: 'Редактирование типа уведомлений',
    },
    cancel: {
        id: 'NotificationSettingsDialog.cancel',
        defaultMessage: 'Отмена',
    },
    create: {
        id: 'NotificationSettingsDialog.create',
        defaultMessage: 'Создать',
    },
    edit: {
        id: 'NotificationSettingsDialog.save',
        defaultMessage: 'Сохранить',
    },
    ID: {
        id: 'NotificationSettingsDialog.ID',
        defaultMessage: 'ID',
    },
    notificationName: {
        id: 'NotificationSettingsDialog.notificationName',
        defaultMessage: 'Название уведомления',
    },
    setNotificatonName: {
        id: 'NotificationSettingsDialog.setNotificatonName',
        defaultMessage: 'Укажите название уведомления',
    },
    notificationType: {
        id: 'NotificationSettingsDialog.notificationType',
        defaultMessage: 'Тип события',
    },
    recipients: {
        id: 'NotificationSettingsDialog.recipients',
        defaultMessage: 'Получатели',
    },
    templates: {
        id: 'NotificationSettingsDialog.templates',
        defaultMessage: 'Шаблоны',
    },
    templatesPlaceholder: {
        id: 'NotificationSettingsDialog.templatesPlaceholder',
        defaultMessage: 'Выберите шаблон письма',
    },
    SAVE_TREE_NODE: {
        id: 'NotificationSettingsDialog.SAVE_TREE_NODE',
        defaultMessage: 'Сохранение узла дерева',
    },
    ALL: {
        id: 'NotificationSettingsDialog.ALL',
        defaultMessage: 'Все пользователи',
    },
    SELECTED: {
        id: 'NotificationSettingsDialog.SELECTED',
        defaultMessage: 'Выборочные пользователи',
    },
    EMAILS: {
        id: 'NotificationSettingsDialog.EMAILS',
        defaultMessage: 'Ручной ввод email',
    },
    AUTHOR: {
        id: 'NotificationSettingsDialog.AUTHOR',
        defaultMessage: 'Автор действия',
    },
    SUBSCRIBER: {
        id: 'NotificationSettingsDialog.SUBSCRIBER',
        defaultMessage: 'Подписанным пользователям',
    },
    CHANGED_USER: {
        id: 'NotificationSettingsDialog.CHANGED_USER',
        defaultMessage: 'Измененный пользователь',
    },
});
