import { NodeId } from '../serverapi/api';
import {
    DASHBOARD_ADD_NEW_DATASET,
    DASHBOARD_ADD_WIDGET,
    DASHBOARD_CHANGE_CELL_SIZE,
    DASHBOARD_CHANGE_WIDGET_GEOMETRY,
    DASHBOARD_CREATE,
    DASHBOARD_DELETE_DATASET,
    DASHBOARD_DELETE_WIDGET,
    DASHBOARD_EDITOR_MODE_CHANGED,
    DASHBOARD_RESTORE,
    DASHBOARD_SAVE,
    DASHBOARD_SELECT_WIDGET,
    DASHBOARD_SET_CELLS_COUNT,
    DASHBOARD_SET_WIDGET_BG,
    DASHBOARD_SET_WIDGET_BORDER,
    DASHBOARD_SET_WIDGET_NAME,
    DASHBOARD_SET_WIDGET_SOURCE,
    DASHBOARD_SET_WIDGET_TEXT,
    LOAD_DASHBOARD_BY_ID,
} from '../actionsTypes/dashboard.actionTypes';
import {
    TDashboardAddNewDataset,
    TDashboardAddWidget,
    TDashboardChangeCellSize,
    TDashboardChangeWidgetGeometry,
    TDashboardCreate,
    TDashboardDeleteDataset,
    TDashboardDeleteWidget,
    TDashboardEditorModeChangedAction,
    TDashboardRestore,
    TDashboardSave,
    TDashboardSelectWidget,
    TDashboardSetCellsCount,
    TDashboardSetWidgetBg,
    TDashboardSetWidgetBorder,
    TDashboardSetWidgetName,
    TDashboardSetWidgetSource,
    TDashboardSetWidgetText,
    TLoadDashboardByIdAction,
} from './dashboard.actions.types';
import { EditorMode } from '@/models/editorMode';
import { TWidget, TWidgetGeometry, TWidgetSource } from '@/modules/Dashboard/Dashboard.types';

export const loadDashboardById = (nodeId: NodeId, isCreateAtion?: boolean): TLoadDashboardByIdAction => ({
    type: LOAD_DASHBOARD_BY_ID,
    payload: {
        nodeId,
        isCreateAtion,
    },
});

export const createDashboard = (name: string, parentNodeId: NodeId): TDashboardCreate => ({
    type: DASHBOARD_CREATE,
    payload: {
        name,
        parentNodeId,
    },
});

export const saveDashboard = (nodeId: NodeId): TDashboardSave => ({
    type: DASHBOARD_SAVE,
    payload: {
        nodeId,
    },
});

export const restoreDashboard = (nodeId: NodeId, dataValue: string): TDashboardRestore => ({
    type: DASHBOARD_RESTORE,
    payload: {
        nodeId,
        dataValue,
    },
});

export const setCellsCount = (
    nodeId: NodeId,
    verticalCount: number,
    horizontalCount: number,
): TDashboardSetCellsCount => ({
    type: DASHBOARD_SET_CELLS_COUNT,
    payload: {
        nodeId,
        verticalCount,
        horizontalCount,
    },
});

export const dashboardEditorModeChanged = (mode: EditorMode): TDashboardEditorModeChangedAction => ({
    type: DASHBOARD_EDITOR_MODE_CHANGED,
    payload: {
        mode,
    },
});

export const addWidget = (widget: TWidget, nodeId: NodeId): TDashboardAddWidget => ({
    type: DASHBOARD_ADD_WIDGET,
    payload: {
        widget,
        nodeId,
    },
});

export const deleteWidget = (widgetId: string, nodeId: NodeId): TDashboardDeleteWidget => ({
    type: DASHBOARD_DELETE_WIDGET,
    payload: {
        widgetId,
        nodeId,
    },
});

export const changeCellSize = (size: number, nodeId: NodeId): TDashboardChangeCellSize => ({
    type: DASHBOARD_CHANGE_CELL_SIZE,
    payload: {
        size,
        nodeId,
    },
});

export const changeWidgetGeometry = (
    nodeId: NodeId,
    widgetId: string,
    geometry: TWidgetGeometry,
): TDashboardChangeWidgetGeometry => ({
    type: DASHBOARD_CHANGE_WIDGET_GEOMETRY,
    payload: {
        nodeId,
        widgetId,
        geometry,
    },
});

export const selectWidget = (nodeId: NodeId, widgetId: string): TDashboardSelectWidget => ({
    type: DASHBOARD_SELECT_WIDGET,
    payload: {
        nodeId,
        widgetId,
    },
});

export const setWidgetName = (nodeId: NodeId, widgetId: string, name: string): TDashboardSetWidgetName => ({
    type: DASHBOARD_SET_WIDGET_NAME,
    payload: {
        nodeId,
        widgetId,
        name,
    },
});

export const setWidgetBg = (nodeId: NodeId, widgetId: string, bgColor: string): TDashboardSetWidgetBg => ({
    type: DASHBOARD_SET_WIDGET_BG,
    payload: {
        nodeId,
        widgetId,
        bgColor,
    },
});

export const setWidgetBorder = (nodeId: NodeId, widgetId: string, border: boolean): TDashboardSetWidgetBorder => ({
    type: DASHBOARD_SET_WIDGET_BORDER,
    payload: {
        nodeId,
        widgetId,
        border,
    },
});

export const setWidgetText = (nodeId: NodeId, widgetId: string, text: string): TDashboardSetWidgetText => ({
    type: DASHBOARD_SET_WIDGET_TEXT,
    payload: {
        nodeId,
        widgetId,
        text,
    },
});

export const setWidgetSource = (
    nodeId: NodeId,
    widgetId: string,
    source: TWidgetSource,
): TDashboardSetWidgetSource => ({
    type: DASHBOARD_SET_WIDGET_SOURCE,
    payload: {
        nodeId,
        widgetId,
        source,
    },
});

export const addNewDataset = (nodeId: NodeId, widgetId: string): TDashboardAddNewDataset => ({
    type: DASHBOARD_ADD_NEW_DATASET,
    payload: {
        nodeId,
        widgetId,
    },
});

export const deleteDataset = (nodeId: NodeId, widgetId: string, index: number): TDashboardDeleteDataset => ({
    type: DASHBOARD_DELETE_DATASET,
    payload: {
        nodeId,
        widgetId,
        index,
    },
});
