import { TRootState } from '../reducers/root.reducer.types';
import { OutputSelector, createSelector } from 'reselect';
import { NodeId, PresetTypesAcl } from '../serverapi/api';
import { TreeSelectors } from './tree.selectors';
import { TCurrentUserProfile, TUserProfileState } from '../reducers/userProfile.reducer.types';
import { nvlAcl } from '../services/bll/ProfileBllService';
import { EdgeInstanceImpl } from '../models/bpm/bpm-model-impl';

type TIsModelReadableOutput = OutputSelector<TRootState, boolean, (res: TCurrentUserProfile | undefined) => boolean>;

type TIsModelReadableSelector = (modelId: NodeId | undefined, modelTypeId?: string) => TIsModelReadableOutput;

export const userProfileStateSelector = (state: TRootState) => state.userProfile;

export namespace UserProfileSelectors {
    export const selectUserProfileByNodeId = (nodeId: NodeId | undefined) =>
        createSelector(
            userProfileStateSelector,
            TreeSelectors.presetById(nodeId),
            (
                userProfile: TUserProfileState | undefined,
                presetId: string | undefined,
            ): TCurrentUserProfile | undefined => {
                if (!presetId) {
                    return undefined;
                }
                if (!nodeId) {
                    return undefined;
                }

                return userProfile?.get({ serverId: nodeId.serverId, presetId });
            },
        );

    export const isModelUpdatable = (modelId: NodeId | undefined, modelTypeId: string = '') =>
        createSelector(selectUserProfileByNodeId(modelId), (userProfile: TCurrentUserProfile | undefined): boolean =>
            Boolean(nvlAcl(modelId?.id, userProfile?.modelTypeAcls[modelTypeId]).update),
        );

    export const isModelReadable: TIsModelReadableSelector = (
        modelId: NodeId | undefined,
        modelTypeId: string = '',
    ): TIsModelReadableOutput =>
        createSelector(selectUserProfileByNodeId(modelId), (userProfile: TCurrentUserProfile | undefined): boolean =>
            Boolean(nvlAcl(modelId?.id, userProfile?.modelTypeAcls[modelTypeId]).read),
        );

    export const isSymbolCreatable = (nodeId: NodeId | undefined, symbolId: string = '') =>
        createSelector(selectUserProfileByNodeId(nodeId), (userProfile: TCurrentUserProfile | undefined): boolean =>
            Boolean(nvlAcl(nodeId?.id, userProfile?.symbolAcls[symbolId]).create),
        );

    export const isSymbolReadable = (nodeId: NodeId | undefined, symbolId: string = '') =>
        createSelector(selectUserProfileByNodeId(nodeId), (userProfile: TCurrentUserProfile | undefined): boolean =>
            Boolean(nvlAcl(nodeId?.id, userProfile?.symbolAcls[symbolId]).read),
        );

    export const isSymbolEditable = (nodeId: NodeId | undefined, symbolId: string = '') =>
        createSelector(selectUserProfileByNodeId(nodeId), (userProfile: TCurrentUserProfile | undefined): boolean =>
            Boolean(nvlAcl(nodeId?.id, userProfile?.symbolAcls[symbolId]).update),
        );

    export const isSymbolDeletable = (nodeId: NodeId | undefined, symbolId: string = '') =>
        createSelector(selectUserProfileByNodeId(nodeId), (userProfile: TCurrentUserProfile | undefined): boolean =>
            Boolean(nvlAcl(nodeId?.id, userProfile?.symbolAcls[symbolId]).delete),
        );

    export const isObjectCreatable = (nodeId: NodeId | undefined, objectTypeId: string = '') =>
        createSelector(selectUserProfileByNodeId(nodeId), (userProfile: TCurrentUserProfile | undefined): boolean =>
            Boolean(nvlAcl(nodeId?.id, userProfile?.objectTypeAcls[objectTypeId]).create),
        );

    export const selectUserProfileByType = (serverId: string, presetId: string) =>
        createSelector(userProfileStateSelector, (userProfile: TUserProfileState | undefined):
            | TCurrentUserProfile
            | undefined => userProfile?.get({ serverId, presetId }));

    export const isModelTypeCreatable = (serverId: string, presetId: string, modelTypeId: string) =>
        createSelector(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined): boolean =>
                userProfile?.modelTypeAcls[modelTypeId]?.create ?? true,
        );

    export const isObjectTypeCreatable = (serverId: string, presetId: string, objectTypeId: string) =>
        createSelector(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined): boolean =>
                userProfile?.objectTypeAcls[objectTypeId]?.create ?? true,
        );

    export const isObjectTypeEditable = (serverId: string, presetId: string, objectTypeId: string) =>
        createSelector(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined): boolean =>
                userProfile?.objectTypeAcls[objectTypeId]?.update ?? true,
        );

    export const isEdgeTypeReadable = (serverId: string, presetId: string, edgeTypeId: string) =>
        createSelector(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined): boolean =>
                userProfile?.edgeTypeAcls[edgeTypeId]?.read ?? true,
        );

    export const isEdgeTypeCreateable = (serverId: string, presetId: string, edgeTypeId: string) =>
        createSelector(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined): boolean =>
                userProfile?.edgeTypeAcls[edgeTypeId]?.create ?? true,
        );

    export const isEdgeTypeEditable = (serverId: string, presetId: string, edgeTypeId: string) =>
        createSelector(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined): boolean =>
                userProfile?.edgeTypeAcls[edgeTypeId]?.update ?? true,
        );

    export const isEdgeTypeDeletable = (serverId: string, presetId: string, edgeTypeId: string) =>
        createSelector(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined): boolean =>
                userProfile?.edgeTypeAcls[edgeTypeId]?.delete ?? true,
        );

    export const isObjectTypeReadable = (serverId: string, presetId: string, objectTypeId: string) =>
        createSelector(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined): boolean =>
                userProfile?.objectTypeAcls[objectTypeId]?.read ?? true,
        );

    export const isAttributeTypeReadable = (serverId: string, presetId: string, attributeTypeId: string) =>
        createSelector(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined): boolean =>
                userProfile?.attributeTypeAcls[attributeTypeId]?.read ?? true,
        );

    export const getForbiddenToEditEdgeTypeIds = (serverId: string, presetId: string, edges: EdgeInstanceImpl[]) =>
        createSelector<TRootState, TCurrentUserProfile | undefined, string[]>(
            selectUserProfileByType(serverId, presetId),
            (userProfile: TCurrentUserProfile | undefined) => {
                const types: PresetTypesAcl[] = edges
                    .filter((edge) => userProfile?.edgeTypeAcls[edge.edgeTypeId]?.update === false)
                    .map((edge) => (userProfile as TCurrentUserProfile).edgeTypeAcls[edge.edgeTypeId]);

                return Array.from(new Set(types.map((type) => type.id)));
            },
        );
}
