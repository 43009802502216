import { StompClientDecorator } from './StompClientDecorator';
import { WSClient } from './WSClient';


export class WSService {
    private static inst: WSClient;

    public static initialize(stompClientAdapter: StompClientDecorator): WSClient {
        if (WSService.inst) {
            console.error('Web socket client already initialized')
        }
        WSService.inst = new WSClient(stompClientAdapter);
        return WSService.inst;
    }

    public static client(): WSClient {
        if (!WSService.inst) {
            console.error('Web socket client not initialized')
        }
        return WSService.inst;
    }
}
