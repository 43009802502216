import React, { FC } from 'react';
import classnames from 'classnames';
import theme from './FormGroup.scss';

type TFormGroupProps = JSX.IntrinsicAttributes & {
    className?: string;
    children: React.ReactNode;
    dataTest?: string;
};

export const FormGroup: FC<TFormGroupProps> = (props) => {
    return <div data-test={props.dataTest} className={classnames(theme.container, props.className)}>{props.children}</div>;
};
