import {NodeId} from '../serverapi/api';
import {FILE_DOWNLOAD} from '../actionsTypes/uploader.actionTypes';
import { TFileDownloadAction } from './file.actions.types';

export const fileDownload = (nodeId: NodeId): TFileDownloadAction => ({
    type: FILE_DOWNLOAD,
    payload: {
        nodeId
    }
});

