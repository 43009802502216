import { defineMessages } from 'react-intl';

export default defineMessages({
    attrValue: {
        id: 'AttributeCustomText.attrValue',
        defaultMessage: 'Вывести значение атрибута',
    },
    customText: {
        id: 'AttributeCustomText.customText',
        defaultMessage: 'Произвольный текст',
    },
    text: {
        id: 'AttributeCustomText.text',
        defaultMessage: 'Текст',
    },
});
