import { defineMessages } from 'react-intl';

export default defineMessages({
    selectFontColorTitle: {
        id: 'FontSettings.selectFontColorTitle',
        defaultMessage: 'Выбрать цвет текста',
    },
    selectColor: {
        id: 'FontSettings.selectColorTitle',
        defaultMessage: 'Выбрать цвет',
    },
    selectFontSizeTitle: {
        id: 'FontSettings.selectFontSizeTitle',
        defaultMessage: 'Выбрать размер текста',
    },

    selectBoldFontTitle: {
        id: 'FontSettings.selectBoldFontTitle',
        defaultMessage: 'Жирный',
    },
    selectItalicFontTitle: {
        id: 'FontSettings.selectItalicFontTitle',
        defaultMessage: 'Курсив',
    },
    selectUnderlinedFontTitle: {
        id: 'FontSettings.selectUnderlinedFontTitle',
        defaultMessage: 'Подчеркнутый',
    },

    selectLeftAlignFontTitle: {
        id: 'FontSettings.selectLeftAlignFontTitle',
        defaultMessage: 'Выравнивание по левому краю',
    },
    selectCenterAlignFontTitle: {
        id: 'FontSettings.selectCenterAlignFontTitle',
        defaultMessage: 'Выравнивание по центру',
    },
    selectRightAlignFontTitle: {
        id: 'FontSettings.selectRightAlignFontTitle',
        defaultMessage: 'Выравнивание по правому краю',
    },

    selectTopAlignmentFontTitle: {
        id: 'FontSettings.selectTopAlignmentFontTitle',
        defaultMessage: 'Выравнивание по верхнему краю',
    },
    selectCenterAlignmentFontTitle: {
        id: 'FontSettings.selectCenterAlignmentFontTitle',
        defaultMessage: 'Выравнивание по середине',
    },
    selectBottomAlignmentFontTitle: {
        id: 'FontSettings.selectBottomAlignmentFontTitle',
        defaultMessage: 'Выравнивание по нижнему краю',
    },
});
