import {
    SCRIPT_EXECUTE_DIALOG_ADD_NODE_ID,
    SCRIPT_EXECUTE_DIALOG_ADD_NODE_PATH,
    SCRIPT_EXECUTE_DIALOG_DELETE_NODE_ID,
    SCRIPT_EXECUTE_DIALOG_DELETE_PARAM_FILES,
    SCRIPT_EXECUTE_DIALOG_REQUEST_SUCCESS,
    SCRIPT_EXECUTE_DIALOG_SET_UPLOAD_STATUS,
    SCRIPT_EXECUTE_DIALOG_UPLOAD_FILE,
} from '../actionsTypes/scriptExecuteDialog.actionTypes';
import { FileUploadStatus } from '../reducers/scriptExecuteDialog.reducer.types';
import { NodeId } from '../serverapi/api';
import { TScriptExecuteDialogUploadFileAction,
    TScriptExecuteDialogSetUploadStateAction,
    TScriptExecuteDialogDeleteParamFilesAction,
    TScriptExecuteDialogRequestSuccessAction,
    TScriptExecuteDialogAddNodeId,
    TScriptExecuteDialogAddNodePath, 
    TScriptExecuteDialogDeleteNodeId} from './scriptExecuteDialog.actions.types';

export const scriptExecuteDialogUploadFile = (
    file: File,
    serverId: string,
    paramName: string,
): TScriptExecuteDialogUploadFileAction => ({
    type: SCRIPT_EXECUTE_DIALOG_UPLOAD_FILE,
    payload: {
        file,
        serverId,
        paramName,
    },
});

export const scriptExecuteDialogSetStatus = (
    paramName: string,
    uploadStatus: FileUploadStatus,
    uploadedFileName?: string,
    originalFileName?: string,
): TScriptExecuteDialogSetUploadStateAction => ({
    type: SCRIPT_EXECUTE_DIALOG_SET_UPLOAD_STATUS,
    payload: {
        paramName,
        uploadStatus,
        uploadedFileName,
        originalFileName,
    },
});

export const scriptExecuteDialogDeleteParamFiles = (
    serverId: string,
    fileNames: string[],
): TScriptExecuteDialogDeleteParamFilesAction => ({
    type: SCRIPT_EXECUTE_DIALOG_DELETE_PARAM_FILES,
    payload: {
        serverId,
        fileNames,
    },
});

export const scriptExecuteDialogRequestSuccess = (): TScriptExecuteDialogRequestSuccessAction => ({
    type: SCRIPT_EXECUTE_DIALOG_REQUEST_SUCCESS,
    payload: {},
});

export const scriptExecuteDialogAddNodeId = (paramName: string, nodeId: NodeId): TScriptExecuteDialogAddNodeId => {
    return {
        type: SCRIPT_EXECUTE_DIALOG_ADD_NODE_ID,
        payload: {
            paramName,
            nodeId,
        },
    };
};

export const scriptExecuteDialogDeleteNodeId = (paramName): TScriptExecuteDialogDeleteNodeId => {
    return {
        type: SCRIPT_EXECUTE_DIALOG_DELETE_NODE_ID,
        payload: {
            paramName,
        },
    };
};

export const scriptExecuteDialogAddNodePath = (
    paramName: string,
    nodePath: string,
): TScriptExecuteDialogAddNodePath => {
    return {
        type: SCRIPT_EXECUTE_DIALOG_ADD_NODE_PATH,
        payload: {
            paramName,
            nodePath,
        },
    };
};

