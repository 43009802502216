import { apiBundle } from '../api/api-bundle';
import { PrincipalPermissions } from '../../serverapi/api';

export class ManagePermissionsDAOService {
    public static async allPermissionsForNode(serverId: string, objectId: string, repositoryId: string): Promise<Array<PrincipalPermissions>> {
        const allPermissions: PrincipalPermissions[] = await apiBundle(serverId).securityManagement.allPermissionsForNode({
            objectId,
            repositoryId,
        });

        return allPermissions;
    }
}
