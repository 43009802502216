import { FindDuplicatesResponse, MergeObjectStatus, MergeResponse, NodeId } from '@/serverapi/api';
import {
    CLEAR_STATUSES,
    FIND_DUPLICATES_DATA_FAILURE,
    FIND_DUPLICATES_DATA_REQUEST,
    FIND_DUPLICATES_DATA_SUCCESS,
    MERGE_DATA_REQUEST,
    MERGE_DATA_REQUEST_FAILURE,
    MERGE_DATA_REQUEST_SUCCESS,
    SET_STATUSES,
} from '../actionsTypes/findDuplicates.actionTypes';
import {
    TClearStatusessAction,
    TFindDuplicatesAction,
    TFindDuplicatesDataFailureAction,
    TFindDuplicatesDataSuccessAction,
    TMergeDataRequestAction,
    TMergeDataRequestFailureAction,
    TMergeRequestSuccessAction,
    TSetStatusesAction,
} from './findDuplicates.actions.types';
import { TMergeDuplicatesRequest } from '@/reducers/findDuplicates.types';

export const findDuplicatesDataRequest = (nodeId: NodeId, findOnlyChildDuplicates: boolean): TFindDuplicatesAction => ({
    type: FIND_DUPLICATES_DATA_REQUEST,
    payload: { nodeId, findOnlyChildDuplicates },
});

export const findDuplicatesDataSuccess = (
    nodeId: NodeId,
    data: FindDuplicatesResponse,
): TFindDuplicatesDataSuccessAction => ({
    type: FIND_DUPLICATES_DATA_SUCCESS,
    payload: { nodeId, data },
});

export const findDuplicatesDataFailure = (): TFindDuplicatesDataFailureAction => ({
    type: FIND_DUPLICATES_DATA_FAILURE,
});

export const mergeDataRequestFailure = (): TMergeDataRequestFailureAction => ({
    type: MERGE_DATA_REQUEST_FAILURE,
});

export const mergeDataRequest = (mergeRequest: TMergeDuplicatesRequest, nodeId: NodeId): TMergeDataRequestAction => ({
    type: MERGE_DATA_REQUEST,
    payload: {
        mergeRequest,
        nodeId,
    },
});

export const mergeDataRequestSuccess = (nodeId: NodeId, data: MergeResponse): TMergeRequestSuccessAction => ({
    type: MERGE_DATA_REQUEST_SUCCESS,
    payload: { nodeId, updatedData: data },
});

export const setStatuses = (nodeId: NodeId, statuses: MergeObjectStatus[]): TSetStatusesAction => ({
    type: SET_STATUSES,
    payload: { nodeId, statuses },
});

export const clearStatuses = (nodeId: NodeId): TClearStatusessAction => ({
    type: CLEAR_STATUSES,
    payload: { nodeId },
});
