import { KANBAN_MODEL_TYPE_REQUEST_SUCCESS } from '../actionsTypes/kanbanModelType.actionTypes';
import {
    TKanbanModelTypeRequestSuccessAction,
    TKanbanModelTypeRequestSuccessPayload,
} from './kanbanModelType.actions.types';

export const kanbanModelTypeRequestSuccess = (
    payload: TKanbanModelTypeRequestSuccessPayload,
): TKanbanModelTypeRequestSuccessAction => ({
    type: KANBAN_MODEL_TYPE_REQUEST_SUCCESS,
    payload,
});

