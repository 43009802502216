import React from 'react';
import { useIntl } from 'react-intl';
import { NodeId } from '../../../serverapi/api';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { deleteWidget } from '../../../actions/dashboard.actions';
import messages from './DeleteWidgetDialog.messages';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TDeleteWidgetDialogProps = {
    parentNodeId: NodeId;
    widgetId: string;
    widgetName: string;
};

export const DeleteWidgetDialog = (props: TDeleteWidgetDialogProps) => {
    const dispatch = useDispatch();
    const { parentNodeId, widgetId, widgetName } = props;

    const onClose = () => dispatch(closeDialog(DialogType.DASHBOARD_DELETE_WIDGET_DIALOG));
    const onSubmit = () => {
        dispatch(deleteWidget(widgetId, parentNodeId));
        dispatch(closeDialog(DialogType.DASHBOARD_DELETE_WIDGET_DIALOG));
    };

    const intl = useIntl();
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.delete),
                    visualStyle: 'primary',
                    danger: true,
                },
            ]}
        />
    );

    return (
        <Dialog
            open
            onOk={() => onSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.title)}
            footer={footer}
        >
            <div>{intl.formatMessage(messages.message, { name: widgetName })}</div>
        </Dialog>
    );
};
