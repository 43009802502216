import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

export const systemAttributeTypeArrow = 'SYSTEM_ATTRIBUTE_TYPE_ARROW_ID';

export const systemAttributeEdgeStyle = 'SYSTEM_ATTRIBUTE_EDGE_STYLE_ID';

export const systemAttributeFolderType = 'SYSTEM_ATTRIBUTE_FOLDER_TYPE_ID';

export const options: DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
};

