import type { Editor } from '@tiptap/react';
import type { TSharedState } from './sharedState.types';

type TObserver = (state: TSharedState) => void;

interface IObserverSubject {
    attach(observer: TObserver);
    detach(observer: TObserver);
    notify(editorState: TSharedState);
}

export class StateObserver implements IObserverSubject {
    public state: TSharedState;

    private observers: TObserver[] = [];

    public attach(observer: TObserver) {
        const isExist = this.observers.includes(observer);
        if (isExist) {
            return console.log('Subject: Observer has been attached already.');
        }

        this.observers.push(observer);

        return undefined;
    }

    public detach(observer: TObserver) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex === -1) {
            return console.log('Subject: Nonexistent observer.');
        }

        this.observers.splice(observerIndex, 1);

        return undefined;
    }

    public notify(editorState: TSharedState) {
        this.state = editorState;
        for (const observer of this.observers) {
            if (observer) {
                observer(this.state);
            }
        }

        return undefined;
    }

    public share(editor: Editor) {
        const wikiSharedState = {
            focusEditor: () => editor.commands.focus(),
            changeWith: (fn) => fn(editor),
            focusWith: (fn) => {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(fn(editor));
                    });
                });
            },
            getEditorState: () => editor,
        };

        this.notify(wikiSharedState);
    }
}

export const stateObserver = new StateObserver();

const stateObservers = {};

export function getStateObserver(id: string): StateObserver {
    if (!stateObservers[id]) {
        stateObservers[id] = new StateObserver();
    }

    return stateObservers[id];
}
