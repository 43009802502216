import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { getStore } from '@/store';
import messages from './EPCGrid.messages';
import { LocalesService } from '@/services/LocalesService';
import { EPCGridColumnType, EPCGridRowType } from './EPCGrid.const';
import { TRenameDialogItem } from './EPCGrid.types';
import { ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';

const rowNames = [
    {
        id: EPCGridRowType.CT_EXEC_CT_CAN_SUPP,
        message: messages.CT_EXEC_CT_CAN_SUPP,
    },
    {
        id: EPCGridRowType.CT_MUST_BE_INFO_ABT,
        message: messages.CT_MUST_BE_INFO_ABT,
    },
    {
        id: EPCGridRowType.CT_MUST_BE_INFO_ON_CNC,
        message: messages.CT_MUST_BE_INFO_ON_CNC,
    },
    {
        id: EPCGridRowType.CT_MUST_BE_INFO_ABT_RES,
        message: messages.CT_MUST_BE_INFO_ABT_RES,
    },
    {
        id: EPCGridRowType.CT_IS_TECH_RESP,
        message: messages.CT_IS_TECH_RESP,
    },
    {
        id: EPCGridRowType.CT_IS_DP_RESP,
        message: messages.CT_IS_DP_RESP,
    },
    {
        id: EPCGridRowType.CT_AGREES,
        message: messages.CT_AGREES,
    },
    {
        id: EPCGridRowType.CT_DECID_ON,
        message: messages.CT_DECID_ON,
    },
    {
        id: EPCGridRowType.CT_CONTR_TO,
        message: messages.CT_CONTR_TO,
    },
    {
        id: EPCGridRowType.CT_HAS_CONSLT_ROLE_IN,
        message: messages.CT_HAS_CONSLT_ROLE_IN,
    },
    {
        id: EPCGridRowType.OTHER,
        message: messages.OTHER,
    },
    {
        id: EPCGridRowType.CONNECTED_WITH,
        message: messages.CONNECTED_WITH,
    },
];

export const showSelectRowNameDialog = (onSelect: (item: TRenameDialogItem) => void) => {
    const { dispatch } = getStore();
    const intl = LocalesService.useIntl();
    const newRowNames = rowNames.map(({ id, message }) => ({
        id,
        name: intl.formatMessage(message),
    }));

    dispatch(openDialog(DialogType.EPC_SELECT_MODEL_ROW_NAME_DIALOG, { onSelect, rows: newRowNames }));
};

export const showSelectColumnNameDialog = (onSelect: (item: TRenameDialogItem) => void) => {
    const { dispatch } = getStore();
    const intl = LocalesService.useIntl();
    const newRowNames: TRenameDialogItem[] = [
        {
            id: EPCGridColumnType.MAIN,
            name: ' ',
        },
        {
            id: EPCGridColumnType.ORG_UNIT,
            name: intl.formatMessage(messages.newColumnTitle),
        },
    ];

    dispatch(openDialog(DialogType.EPC_SELECT_MODEL_ROW_NAME_DIALOG, { onSelect, rows: newRowNames }));
};

export const getObjectName = (objectInstance: ObjectInstanceImpl) => {};