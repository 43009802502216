import { defineMessages } from 'react-intl';

export default defineMessages({
    modalTitle: {
        id: 'MultiLangInputDialog.modalTitle',
        defaultMessage: 'Перевод на другие языки',
    },
    requiredField: {
        id: 'MultiLangInputDialog.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    whitespacesOnly: {
        id: 'MultiLangInputDialog.whitespacesOnly',
        defaultMessage: 'Вы ввели только пробелы',
    },
});
