// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentPopover__container__MJHvz{min-width:600px;max-height:600px;overflow-y:auto}.CommentPopover__quotedText__DhTTb{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/modules/Comments/components/CommentPopover/CommentPopover.scss"],"names":[],"mappings":"AAAA,kCACI,eAAA,CACA,gBAAA,CACA,eAAA,CAEJ,mCACE,cAAA","sourcesContent":[".container {\r\n    min-width: 600px;\r\n    max-height: 600px;\r\n    overflow-y: auto;\r\n}\r\n.quotedText {\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CommentPopover__container__MJHvz",
	"quotedText": "CommentPopover__quotedText__DhTTb"
};
export default ___CSS_LOADER_EXPORT___;
