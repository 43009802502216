import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { DiagramElement, MatrixLane, MatrixNode, NodeId } from '@/serverapi/api';
import { MatrixSelectors } from './matrix.selectors';
import { getSelectedLanesWithChildren } from '@/modules/Matrix/utils/Matrix.utils';
import { getCopiedElements } from '../../../selectors/editor.selectors';
import { HeaderType } from '../MatrixEditor/Matrix.types';
import {
    TMatrixEditorState,
    TMatrixCellData,
    TSelectedHeadersCells,
    TMatrixEditor,
} from '../reducers/matrixEditor.reducer.types';

const matrixEditorStateSelector = (state: TRootState): TMatrixEditorState => state.matrixEditor;

export namespace MatrixEditorSelectors {
    export const getSelectedHeaderCells = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, TSelectedHeadersCells>(
            matrixEditorStateSelector,
            (state) => state.get(nodeId)?.selectedHeaderCells || { type: HeaderType.column, ids: [] },
        );

    export const getSelectedCells = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, string[]>(
            matrixEditorStateSelector,
            (state) => state.get(nodeId)?.selectedCells || [],
        );

    export const isMatrixUnsaved = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(
            matrixEditorStateSelector,
            (state) => !!state.get(nodeId)?.unsaved,
        );

    export const isCopyEnabled = (nodeId: NodeId) =>
        createSelector<TRootState, TSelectedHeadersCells, MatrixNode | undefined, boolean>(
            getSelectedHeaderCells(nodeId),
            MatrixSelectors.byId(nodeId),
            (selectedHeadersCells, matrix) => {
                if (!matrix?.content) return false;

                const lanes = selectedHeadersCells.type === HeaderType.row ? matrix.content.rows : matrix.content.columns;
                const lastFilledIndex = lanes.findLastIndex((lane) => lane.text);
                const filledLanes = lanes.slice(0, lastFilledIndex + 1);
                const lanesToCopyWithChildren: MatrixLane[] = getSelectedLanesWithChildren(
                    selectedHeadersCells.ids,
                    filledLanes,
                    true,
                );

                return !!lanesToCopyWithChildren.length;
            },
        );

    export const isPastEnabled = (nodeId: NodeId) =>
        createSelector<
            TRootState,
            TSelectedHeadersCells,
            MatrixLane[] | DiagramElement[],
            MatrixNode | undefined,
            boolean
        >(
            getSelectedHeaderCells(nodeId),
            getCopiedElements,
            MatrixSelectors.byId(nodeId),
            (selectedHeaderCells, copiedElements, matrix) => {
                if (!matrix?.content) return false;

                const isAutomatic =
                    (matrix.content.rowSettings.isAutomatic && selectedHeaderCells.type === HeaderType.row) ||
                    (matrix.content.columnSettings.isAutomatic && selectedHeaderCells.type === HeaderType.column);

                return !!selectedHeaderCells.ids.length && !!copiedElements.length && !isAutomatic;
            },
        );

    export const getCellData = (nodeId: NodeId, cellId: string) =>
        createSelector<TRootState, TMatrixEditorState, TMatrixCellData | undefined>(
            matrixEditorStateSelector,
            (state) => state.get(nodeId)?.cellsData?.[cellId],
        );

    export const isLoadingCellData = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(
            matrixEditorStateSelector,
            (state) => !!state.get(nodeId)?.isLoadingCellData,
        );

    export const isHeaderCellToggled = (matrixNodeId: NodeId, headerCellId: string, type: HeaderType) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(matrixEditorStateSelector, (state) => {
            if (type === HeaderType.column) {
                return !!state.get(matrixNodeId)?.toggledColumnsIds.includes(headerCellId);
            } else {
                return !!state.get(matrixNodeId)?.toggledRowsIds.includes(headerCellId);
            }
        });

    export const getToggledHeaderCellsIds = (matrixNodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, { toggledColumnsIds: string[]; toggledRowsIds: string[] }>(
            matrixEditorStateSelector,
            (state) => {
                const matrix: TMatrixEditor | undefined = state.get(matrixNodeId);
                if (matrix) {
                    const { toggledColumnsIds, toggledRowsIds } = matrix;
                    return { toggledColumnsIds, toggledRowsIds };
                }
                return { toggledColumnsIds: [], toggledRowsIds: [] };
            },
        );
    export const isRowPastEnabled = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(matrixEditorStateSelector, (state) => {
            const selectedHeaderCells: TSelectedHeadersCells | undefined = state.get(nodeId)?.selectedHeaderCells;
            return selectedHeaderCells?.type === HeaderType.row && selectedHeaderCells.ids.length === 1;
        });

    export const isColPastEnabled = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(matrixEditorStateSelector, (state) => {
            const selectedHeaderCells: TSelectedHeadersCells | undefined = state.get(nodeId)?.selectedHeaderCells;
            return selectedHeaderCells?.type === HeaderType.column && selectedHeaderCells.ids.length === 1;
        });
}
