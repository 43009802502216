import { SpaceAction } from '../../../models/insertionSpace';
import { BPMMxGraph } from '../../../mxgraph/bpmgraph';
import { MxCell, MxConstants, MxEvent, MxGeometry, MxMouseEvent, MxPoint, MxUtils } from '../../../mxgraph/mxgraph';
import { graphContainsEvent, setIgnoredEdit } from '../../../utils/bpm.mxgraph.utils';
import { isNullOrUndefined } from 'is-what';
import { BPMMxConstants } from '../../../mxgraph/bpmgraph.constants';

let currentTool: SpaceTool | null = null;

export const getTool = (action: SpaceAction, graph: BPMMxGraph): SpaceTool => {
    if (currentTool !== null) {
        currentTool.destroy();
    }
    currentTool = new SpaceTool(action, graph);

    return currentTool;
};

class SpaceTool {
    private firstLine: MxCell | null = null;
    private secondLine: MxCell | null = null;
    private container: HTMLElement;
    private removeCurrentListener: (destroy?: boolean) => void;
    private xBoundaries: number[] = [];
    private yBoundaries: number[] = [];

    constructor(private action: SpaceAction, private graph: BPMMxGraph) {
        const allGraphVertexCells = this.getVertexCellsByParrentId('1');
        allGraphVertexCells.forEach((cell) => {
            this.xBoundaries.push(cell.geometry.x, cell.geometry.x + cell.geometry.width);
            this.yBoundaries.push(cell.geometry.y, cell.geometry.y + cell.geometry.height);
        });
    }

    attachEvents() {
        this.container = this.graph.container;
        this.addIndicatorLine()
            .then((line: MxCell | null) => {
                this.firstLine = line;

                return this.addIndicatorLine();
            })
            .then((line: MxCell | null) => {
                this.secondLine = line;
                this.removeLines([this.firstLine, this.secondLine]);
                this.moveCells();
            });
    }

    destroy() {
        this.removeLines([this.firstLine, this.secondLine]);
        this.removeCurrentListener(true);
    }

    private getVertexCellsByParrentId(parentId: string) {
        const allGraphCells = Object.values<MxCell>(this.graph.model.cells);
        const allGraphVertexCells = allGraphCells.filter(
            (cell) => cell.isVertex() && cell.value?.type === 'object' && cell.parent.id === parentId,
        );

        return allGraphVertexCells;
    }

    private addIndicatorLine() {
        return new Promise<MxCell | null>((resolve) => {
            let line: MxCell | null = null;
            const listener = {
                currentState: null,
                previousState: null,
                mouseMove: (sender: BPMMxGraph, me: MxMouseEvent) => {
                    if (line !== null) {
                        const offsets = this.calculateOffset(me, sender, line);
                        this.graph.moveCells([line], offsets.x, offsets.y, false);

                        return;
                    }
                    this.graph.getModel().beginUpdate();
                    try {
                        const parent = this.graph.getDefaultParent();
                        const params = this.generateVertexForLine(me);
                        line = this.graph.insertVertex(
                            parent,
                            '',
                            '',
                            params.x,
                            params.y,
                            params.width,
                            params.height,
                            params.style,
                        );
                        this.graph.setCellStyles(MxConstants.STYLE_EDITABLE, '0', [line]);
                        this.graph.setCellStyles(MxConstants.STYLE_MOVABLE, '0', [line]);
                        this.graph.setCellStyles(MxConstants.STYLE_DELETABLE, '0', [line]);
                        this.graph.setCellStyles(BPMMxConstants.STYLE_SELECTABLE, '0', [line]);
                        line.setConnectable(false);
                        setIgnoredEdit(this.graph, line);
                    } finally {
                        this.graph.getModel().endUpdate();
                    }
                },
                mouseDown: () => {
                    this.removeCurrentListener();
                    resolve(line);
                },
                mouseUp: () => {},
            };
            this.removeCurrentListener = (destroy?: boolean) => {
                if (destroy === true) {
                    this.removeLines([line]);
                }
                this.graph.removeMouseListener(listener);
            };
            this.graph.addMouseListener(listener);
            // BPM-173 Inserting space. Put the first line at the beginning of the canvas when you click on the frame
            if (this.firstLine === null) {
                const mouseMove = (evt: PointerEvent) => {
                    if (!this.graph.container) this.removeCurrentListener();

                    if (!graphContainsEvent(evt, this.graph) && this.graph.container && line !== null) {
                        const state = this.graph.view.getState(line, false);
                        const origin = MxUtils.getScrollOrigin(this.graph.container);
                        const relativeZeroX = origin.x - state.x;
                        const relativeZeroY = origin.y - state.y;
                        this.graph.moveCells([line], relativeZeroX, relativeZeroY, false);
                    }
                };
                const mouseDown = (evt: PointerEvent) => {
                    // TODO: find more elegant way of checking that mouseDown occurred on the workspace edge
                    const element = evt.target as HTMLElement;
                    if (!graphContainsEvent(evt, this.graph) && element?.className?.startsWith?.('Editor__container')) {
                        this.removeCurrentListener();
                        resolve(line);
                    } else if (graphContainsEvent(evt, this.graph)) {
                        MxEvent.removeGestureListeners(document, mouseDown, mouseMove, null);
                    }
                };
                MxEvent.addGestureListeners(document, mouseDown, mouseMove, null);
                const mainRemoveListener = this.removeCurrentListener;
                this.removeCurrentListener = (destroy?: boolean) => {
                    MxEvent.removeGestureListeners(document, mouseDown, mouseMove, null);
                    mainRemoveListener(destroy);
                };
            }
        });
    }

    private removeLines(cells: Array<MxCell | null>) {
        const realCells: MxCell[] = cells.filter((t) => !isNullOrUndefined(t)).map((t) => t!);
        this.graph.removeCells(realCells, false);
    }

    private calculateOffset(evt: MxMouseEvent, graph: BPMMxGraph, cell: MxCell): MxPoint {
        const state = graph.view.getState(cell, false);
        const { scale } = graph.view;

        switch (this.action) {
            case SpaceAction.InsertVervical:
            case SpaceAction.DeleteVertical: {
                let y = evt.graphY;
                // считаем верхнюю и нижнюю границы для второй линии
                if (this.firstLine && this.action === SpaceAction.DeleteVertical) {
                    const firstLineY = this.firstLine.geometry.y;
                    const topBoundary =
                        Math.max(...this.yBoundaries.filter((yBoundary) => yBoundary < firstLineY)) * scale;
                    const bottomBoundary =
                        Math.min(...this.yBoundaries.filter((yBoundary) => yBoundary > firstLineY)) * scale;
                    y = Math.min(Math.max(topBoundary, evt.graphY), bottomBoundary);
                }

                const offsetY = (y - state.y) / scale;

                return new MxPoint(0, offsetY);
            }
            case SpaceAction.InsertHorizontal:
            case SpaceAction.DeleteHorizontal: {
                let x = evt.graphX;
                // считаем левую и правую границы для второй линии
                if (this.firstLine && this.action === SpaceAction.DeleteHorizontal) {
                    const firstLineX = this.firstLine.geometry.x;
                    const leftBoundar =
                        Math.max(...this.xBoundaries.filter((xBoundary) => xBoundary < firstLineX)) * scale;
                    const rightBoundary =
                        Math.min(...this.xBoundaries.filter((xBoundary) => xBoundary > firstLineX)) * scale;
                    x = Math.min(Math.max(leftBoundar, evt.graphX), rightBoundary);
                }

                const offsetX = (x - state.x) / scale;

                return new MxPoint(offsetX, 0);
            }
            default:
                return new MxPoint(0, 0);
        }
    }

    private generateVertexForLine(evt: MxMouseEvent) {
        switch (this.action) {
            case SpaceAction.InsertVervical:
            case SpaceAction.DeleteVertical:
                return {
                    x: 0,
                    y: evt.graphY,
                    width: this.container.scrollWidth / this.graph.view.scale,
                    height: 1,
                    style: 'shape=h-line-dashed',
                };
            case SpaceAction.InsertHorizontal:
            case SpaceAction.DeleteHorizontal:
                return {
                    x: evt.graphX,
                    y: 0,
                    width: 1,
                    height: this.container.scrollHeight / this.graph.view.scale,
                    style: 'shape=v-line-dashed',
                };
            default:
                return {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 1,
                    style: '',
                };
        }
    }

    private moveCells() {
        if (this.firstLine !== null && this.secondLine !== null && this.graph !== null) {
            this.graph.getModel().beginUpdate();
            const { scale } = this.graph.view;
            try {
                const parent: MxCell = this.graph.getDefaultParent();
                const align = {
                    right: false,
                    bottom: false,
                };
                let offset: MxPoint;
                if ([SpaceAction.InsertVervical, SpaceAction.DeleteVertical].includes(this.action)) {
                    align.bottom = true;
                    offset = new MxPoint(0, Math.abs(this.secondLine.geometry.y - this.firstLine.geometry.y));
                } else {
                    align.right = true;
                    offset = new MxPoint(Math.abs(this.secondLine.geometry.x - this.firstLine.geometry.x), 0);
                }

                if ([SpaceAction.InsertVervical, SpaceAction.InsertHorizontal].includes(this.action)) {
                    const cellsBeyond: MxCell[] = this.graph.getCellsBeyond(
                        this.firstLine.geometry.x * scale,
                        this.firstLine.geometry.y * scale,
                        parent,
                        align.right,
                        align.bottom,
                    );
                    this.graph.moveCells(cellsBeyond, offset.x, offset.y, false);
                } else {
                    const firstLineGeo: MxGeometry = this.firstLine.geometry;
                    const secondLineGeo: MxGeometry = this.secondLine.geometry;

                    const w =
                        this.action === SpaceAction.DeleteHorizontal
                            ? Math.abs(firstLineGeo.x - secondLineGeo.x)
                            : firstLineGeo.width;
                    const h =
                        this.action === SpaceAction.DeleteVertical
                            ? Math.abs(firstLineGeo.y - secondLineGeo.y)
                            : firstLineGeo.height;

                    const cellsBeyond: MxCell[] = this.graph.getCellsBeyond(
                        firstLineGeo.x * scale,
                        firstLineGeo.y * scale,
                        parent,
                        align.right,
                        align.bottom,
                    );

                    const dx = this.action === SpaceAction.DeleteHorizontal ? -w : 0;
                    const dy = this.action === SpaceAction.DeleteVertical ? -h : 0;

                    this.graph.moveCells(cellsBeyond, dx, dy, false);
                }
            } finally {
                this.graph.getModel().endUpdate();
            }
        }
    }
}
