import * as React from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './AboutAppDialog.scss';
import { FormattedMessage } from 'react-intl';
import messages from '../messages/AboutAppDialog.messages';

type TAboutAppDialogProps = {
    open: boolean;
    onClose: () => any; // tslint:disable-line:no-any
} & JSX.IntrinsicAttributes;

const AboutAppDialog: React.FC<TAboutAppDialogProps> = ({ open, onClose }) => {
    return (
        <Dialog onCancel={onClose} open={open} title="SILA BPM" footer={false}>
            <div className={theme.about}>
                <img src="favicon.ico" width="64px" />
                <p>
                    <h3>
                        <FormattedMessage {...messages.commonQuestions} />
                    </h3>
                    <a href="mailto:sales@trysila.ru">sales@trysila.ru</a>
                </p>
                <p>
                    <h3>
                        <FormattedMessage {...messages.cooperationIssues} />
                    </h3>
                    <a href="mailto:partner@trysila.ru">partner@trysila.ru</a>
                </p>
            </div>
        </Dialog>
    );
};

export default AboutAppDialog;
