import { Alert, Form } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { ConflictResolutionStrategy } from '../../../../services/api/custom/TransferApi';
import { TreeItemType } from '../../../Tree/models/tree';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../../UIKit/components/Dialog/Dialog.types';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import messages from '../../messages/ImportDialog.messages';
import theme from './ImportDialog.scss';
import { TImportDialogProps } from '../../types/ImportDialog.types';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';

type TImportDialogState = {
    resolveMethod: string;
};

class ImportDialog extends React.Component<TImportDialogProps, TImportDialogState> {
    state = {
        resolveMethod: ConflictResolutionStrategy.REPLACE,
    };

    handleForAllNodes = () => {
        this.props.submitAllNodes(this.state.resolveMethod);
    };

    handleForCurrentNode = () => {
        this.props.submitNode(this.props.itemId, this.state.resolveMethod);
    };

    handleMethodChange = (value: string) => {
        this.setState({
            resolveMethod: value,
        });
    };

    render() {
        const { intl, open } = this.props;
        const dialogProps: Partial<TDialogProps> = {
            open,
            closable: false,
            title: `${intl.formatMessage(messages.dialogName)} - ${this.props.conflictsNumber}`,
        };
        const isRepo = this.props.itemType === TreeItemType.Repository;
        const forAllNodesDisabled: boolean =
            isRepo ||
            (this.state.resolveMethod === ConflictResolutionStrategy.REPLACE && this.props.nodesWithoutAccess);

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'forCurrentNode',
                        onClick: this.handleForCurrentNode,
                        value: intl.formatMessage(messages.conflictDialogForNodeButton),
                        dataTest: 'import_conflict-dialog_for-node_btn',
                    },
                    {
                        key: 'forAllNodes',
                        onClick: this.handleForAllNodes,
                        value: intl.formatMessage(messages.conflictDialogForAllNodesButton),
                        dataTest: 'import_conflict-dialog_for-all-nodes_btn',
                        disabled: forAllNodesDisabled,
                    },
                    {
                        key: 'cancel',
                        onClick: this.props.onCancel,
                        value: intl.formatMessage(messages.cancelButton),
                        dataTest: 'import_conflict-dialog_cancel_btn',
                    },
                ]}
            />
        );

        const leaveOption: TRadioOption<ConflictResolutionStrategy> | undefined = !isRepo // БД не мержим, никто не помнит почему так решили
            ? {
                  title: intl.formatMessage(messages.conflictDialogLeaveRadio),
                  value: ConflictResolutionStrategy.LEAVE,
                  dataTest: 'import_conflict-dialog_leave_radio',
              }
            : undefined;

        const radioOptions: TRadioOption<ConflictResolutionStrategy>[] = [
            {
                title: intl.formatMessage(messages.conflictDialogReplaceRadio),
                value: ConflictResolutionStrategy.REPLACE,
                disabled: this.props.noAccess,
                dataTest: 'import_conflict-dialog_replace_radio',
            },
            leaveOption,
            {
                title: intl.formatMessage(messages.conflictDialogCloneRadio),
                value: ConflictResolutionStrategy.CLONE,
                dataTest: 'import_conflict-dialog_new-guid_radio',
            },
        ].filter((option) => !!option) as TRadioOption<ConflictResolutionStrategy>[];

        return (
            <Dialog {...dialogProps} footer={footer}>
                {this.props.error && <Alert type="error" message={intl.formatMessage(this.props.error)} showIcon />}
                <Form layout="vertical" hideRequiredMark>
                    <FormGroup>
                        <Form.Item
                            className={theme.itemType}
                            label={`${
                                this.props.noAccess
                                    ? `${intl.formatMessage(messages.conflictDialogNotAccessToNode)}\n`
                                    : this.props.nodesWithoutAccess
                                    ? `${intl.formatMessage(messages.conflictDialogNoAccessInSet)}\n`
                                    : ''
                            }${intl.formatMessage(messages.dialogLabel)}`}
                        >
                            <div style={{ lineHeight: 1.5 }}>
                                <p style={{ marginBottom: '2em', whiteSpace: 'pre-wrap' }}>
                                    {intl.formatMessage(messages.conflictDialogNodeInfo, {
                                        id: this.props.itemId.id,
                                        itemTypeLocal: this.props.itemTypeLocal,
                                        path: this.props.path,
                                    })}
                                </p>
                            </div>
                            <RadioGroup<ConflictResolutionStrategy>
                                value={this.state.resolveMethod}
                                onChange={this.handleMethodChange}
                                options={radioOptions}
                                dataTest="import_conflict"
                                margin={8}
                            />
                        </Form.Item>
                    </FormGroup>
                    <p />
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ImportDialog);

export { IntlComponent as ImportDialog };
