export const MODEL_TYPE_REQUEST_SUCCESS = 'MODEL_TYPE_REQUEST_SUCCESS';

export const DELETE_MODEL_TYPE_GROUP_REQUEST = 'DELETE_MODEL_TYPE_GROUP_REQUEST';

export const DELETE_MODEL_TYPE_GROUP = 'DELETE_MODEL_TYPE_GROUP';

export const EDIT_MODEL_TYPE_GROUP = 'EDIT_MODEL_TYPE_GROUP';

export const DELETE_MODEL_TYPE_GROUP_SUCCESS = 'DELETE_MODEL_TYPE_GROUP_SUCCESS';

export const UPDATE_MODEL_TYPE = 'UPDATE_MODEL_TYPE';
