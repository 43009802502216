import { ApprovalDTOStatus } from '@/modules/ApprovalDialog/ApprovalDialog.types';
import { TRootState } from '@/reducers/root.reducer.types';
import {
    ApprovalDTO,
    NodeId,
    Node,
    ApprovalDTOStatusEnum,
    ApprovalSearchResult,
    ApprovalSearchResultCurrentUserStatusesEnum,
} from '@/serverapi/api';
import { createSelector } from 'reselect';
import { TApprovalState, TApprovals } from '../reducers/approval.reducer.types';
import { nodesSelector } from './nodes.selector';
import { TApprovalTableData } from '../modules/AdminTools/ApprovalsTable/approvalsTable.types';
import {
    createApprovalTableData,
    createMyApprovalsTableData,
    getApprovalsArray,
    sortApprovals,
} from '../services/bll/ApprovalBLLService';
import { compareNodeIds } from '../utils/nodeId.utils';
import { RangeValue } from 'rc-picker/lib/interface';
import { Dayjs } from 'dayjs';
import { timestampToMomentDate } from '@/utils/date.time.utils';
import { TMyApprovalsStatus, TMyApprovalsTableData } from '@/modules/MyApprovalsTab/myApprovals.types';
import { getUserLogin } from './authorization.selectors';

export const approvalStateSelector = (state: TRootState) => state.approval;

export const approvals = (state: TRootState) => state.approval.byRepositoryId;

export const myApprovals = (state: TRootState) => state.approval.myApprovals;

export namespace ApprovalSelectors {
    export const byApprovedItemIdArr = (approvedItemNodeId: NodeId) =>
        createSelector<TRootState, TApprovalState, ApprovalDTO[]>(approvalStateSelector, (state) => {
            const approvals = state.byRepositoryId;
            if (!approvals) return [];

            const approvalArray: ApprovalDTO[] = getApprovalsArray(approvals).filter((approval) =>
                approval.elementIds.find((elementId) =>
                    compareNodeIds({ ...approval.id, id: elementId }, approvedItemNodeId),
                ),
            );
            return sortApprovals(approvalArray);
        });

    export const byApprovedItemIdArrNoDraft = (approvedItemNodeId: NodeId) =>
        createSelector<TRootState, ApprovalDTO[], ApprovalDTO[]>(byApprovedItemIdArr(approvedItemNodeId), (state) => {
            return state.filter((approval) => approval.status !== ApprovalDTOStatus.DRAFT);
        });

    export const byApprovedItemIdDraft = (approvedItemNodeId: NodeId) =>
        createSelector<TRootState, ApprovalDTO[], ApprovalDTO | undefined>(
            byApprovedItemIdArr(approvedItemNodeId),
            (state) => {
                return state.find((approval) => approval.status === ApprovalDTOStatus.DRAFT);
            },
        );

    export const getEditingApproval = createSelector<TRootState, TApprovalState, ApprovalDTO>(
        approvalStateSelector,
        (state) => {
            return state.editingApproval || {};
        },
    );

    export const byApprovalId = (nodeId: NodeId) =>
        createSelector<TRootState, TApprovalState, ApprovalDTO | undefined>(
            approvalStateSelector,
            (state): ApprovalDTO => {
                const approval: ApprovalDTO | undefined = state.byRepositoryId[nodeId.repositoryId]?.byId[nodeId.id];
                return (
                    approval || state.myApprovals.find((appr) => compareNodeIds(appr.approval?.id, nodeId))?.approval
                );
            },
        );

    export const getAllApprovals = createSelector<TRootState, TApprovals, ApprovalDTO[]>(
        approvals,
        (byRepositoryId) => {
            if (!byRepositoryId) return [];
            const approvals: ApprovalDTO[] = getApprovalsArray(byRepositoryId);
            return approvals;
        },
    );

    export const getAllCreators = createSelector<TRootState, ApprovalDTO[], string[]>(getAllApprovals, (approvals) => {
        const creators: string[] = approvals.map((approval) => approval.createdBy || '').filter((creator) => creator);
        const uniqCreators: Set<string> = new Set(creators);

        return Array.from(uniqCreators);
    });

    export const getCheckedApprovalIds = createSelector<TRootState, TApprovalState, NodeId[]>(
        approvalStateSelector,
        (state) => {
            return state.checkedApprovals;
        },
    );

    export const getApprovalTableData = createSelector<TRootState, ApprovalDTO[], Node[], TApprovalTableData[]>(
        getAllApprovals,
        nodesSelector,
        (approvals, nodes) => {
            return createApprovalTableData(approvals, nodes);
        },
    );

    export const isApprovalChecked = (nodeId: NodeId) =>
        createSelector<TRootState, NodeId[], boolean>(getCheckedApprovalIds, (checkedIds) => {
            return !!checkedIds.find((id) => compareNodeIds(id, nodeId));
        });

    export const getFilteredApprovalTableData = (
        statusFilter: ApprovalDTOStatusEnum[],
        creatorFilter: string[],
        createdAtRangeFilter: RangeValue<Dayjs>,
    ) =>
        createSelector<TRootState, TApprovalTableData[], TApprovalTableData[]>(
            getApprovalTableData,
            (approvalTableData) => {
                return approvalTableData.filter((data) => {
                    const createdAt = timestampToMomentDate(data.createdAt!);
                    const isCreatedAtFilterSuccess =
                        !createdAtRangeFilter ||
                        (!createdAt?.isBefore(createdAtRangeFilter[0], 'day') &&
                            !createdAt?.isAfter(createdAtRangeFilter[1], 'day'));

                    const isStatusFilterSuccess =
                        !statusFilter.length || (statusFilter.length && statusFilter.includes(data.status!));
                    const isCreatorFilterSuccess =
                        !creatorFilter.length || (creatorFilter.length && creatorFilter.includes(data.createdBy!));

                    return isStatusFilterSuccess && isCreatorFilterSuccess && isCreatedAtFilterSuccess;
                });
            },
        );

    export const getMyApprovalsTableData = createSelector<
        TRootState,
        ApprovalSearchResult[],
        Node[],
        TMyApprovalsTableData[]
    >(myApprovals, nodesSelector, (approvals, nodes) => {
        return createMyApprovalsTableData(approvals, nodes);
    });

    export const getMyApprovalsTableDataFiltered = (
        statusFilter: TMyApprovalsStatus[],
        creatorFilter: string[],
        createdAtRangeFilter: RangeValue<Dayjs>,
        userStatusFilter: ApprovalSearchResultCurrentUserStatusesEnum[],
    ) =>
        createSelector<TRootState, TMyApprovalsTableData[], TMyApprovalsTableData[]>(
            getMyApprovalsTableData,
            (myApprovalsTableData) => {
                return myApprovalsTableData.filter((data) => {
                    const createdAt = timestampToMomentDate(data.createdAt!);
                    const isCreatedAtFilterSuccess =
                        !createdAtRangeFilter ||
                        (!createdAt?.isBefore(createdAtRangeFilter[0], 'day') &&
                            !createdAt?.isAfter(createdAtRangeFilter[1], 'day'));

                    let isStatusFilterSuccess: boolean =
                        statusFilter.length === 0 || (statusFilter.length !== 0 && statusFilter.includes(data.status!));
                    if (!isStatusFilterSuccess && statusFilter.length && data.status === 'IN_PROCESS') {
                        isStatusFilterSuccess =
                            statusFilter.includes('WAITING_TO_APPROVE') ||
                            (!statusFilter.includes('WAITING_TO_APPROVE') &&
                                statusFilter.includes('MY_APPROVALS') &&
                                !!data.userStatuses?.some((status) => status === 'USER' || status === 'ASSISTANT'));
                    }

                    const isCreatorFilterSuccess =
                        creatorFilter.length === 0 ||
                        (creatorFilter.length !== 0 && creatorFilter.includes(data.createdBy!));

                    const isUserStatusFilterSuccess =
                        userStatusFilter.length === 0 ||
                        (userStatusFilter.length !== 0 &&
                            userStatusFilter.some((userStatus) => data.userStatuses?.includes(userStatus)));

                    return (
                        isStatusFilterSuccess &&
                        isCreatorFilterSuccess &&
                        isCreatedAtFilterSuccess &&
                        isUserStatusFilterSuccess
                    );
                });
            },
        );

    export const getMyApprovalsCreators = createSelector<TRootState, ApprovalSearchResult[], string[]>(
        myApprovals,
        (myApprovals) => {
            const creators: string[] = myApprovals
                .map((myApproval) => myApproval.approval?.createdBy || '')
                .filter((creator) => creator);
            const uniqCreators: Set<string> = new Set(creators);

            return Array.from(uniqCreators);
        },
    );

    export const getApprovalsToVoteCount = createSelector<TRootState, ApprovalSearchResult[], string, number>(
        myApprovals,
        getUserLogin,
        (approvals, userLogin) =>
            approvals.filter((approval) =>
                approval.schedule?.find((userSchedule) => userSchedule.userLogin === userLogin),
            ).length,
    );
}
