import { REPORT_MODEL_TYPE_REQUEST_SUCCESS } from '@/actionsTypes/reportModelType.actionTypes';
import {
    TReportModelTypeRequestSuccessAction,
    TReportModelTypeRequestSuccessPayload,
} from './reportModelType.actions.types';

export const reportModelTypeRequestSuccess = (
    payload: TReportModelTypeRequestSuccessPayload,
): TReportModelTypeRequestSuccessAction => ({
    type: REPORT_MODEL_TYPE_REQUEST_SUCCESS,
    payload,
});
