/* tslint:disable */
import { MxCell } from './mxgraph';
import { DefaultGraph } from './DefaultGraph';
import { PictureSymbolConstants } from '../models/pictureSymbolConstants';

export class WhiteboardGraph extends DefaultGraph {
    /*
     * This method is called in MxGraph.getEdgeValidationError()
     * after successful checking default MxGraph validation mechanics.
     */
    validateEdge(edge: MxCell, source: MxCell, target: MxCell): string | null {
        return null;
    }

    isCloneInvalidEdges(): boolean {
        return true;
    }

    convertValueToString(cell: MxCell): string {
        if (cell.value && cell.value.symbolId === PictureSymbolConstants.PICTURE_SYMBOL_ID) {
            return '';
        }

        return super.convertValueToString(cell);
    }
}
