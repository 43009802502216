import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import {
    TUserSessionsHistoryDialogActionProps,
    TUserSessionsHistoryDialogOwnProps,
    TUserSessionsHistoryDialogProps,
} from '../UserSessionsHistoryDialog.types';
import UserSessionsHistoryDialogComponent from '../components/UserSessionsHistoryDialog.component';
import { fetchUserSessions } from '../../../../actions/users.actions';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';

const mapStateToProps = (
    state: TRootState,
    ownProps: TUserSessionsHistoryDialogOwnProps,
): Partial<TUserSessionsHistoryDialogProps> => ({
    userId: ownProps.userId,
    sessions: UsersSelectors.getUserSessions(ownProps.userId)(state)?.filter((s) => s.type !== 'SCRIPT'),
    locale: getCurrentLocale(state),
});

const mapDispatchToProps: (dispatch) => TUserSessionsHistoryDialogActionProps = (dispatch) => ({
    fetchUserSessions: (userId: number) => dispatch(fetchUserSessions(userId)),
    onClose: () => dispatch(closeDialog(DialogType.USERS_SESSIONS_HISTORY)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSessionsHistoryDialogComponent);
