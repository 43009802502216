// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentRenderer__container__FqVfv,.CommentRenderer__container__FqVfv span{background-color:#fbcc33;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/RichTextEditor/Editor/renderers/Comment/CommentRenderer.scss"],"names":[],"mappings":"AAAA,2EACI,wBAAA,CACA,cAAA","sourcesContent":[".container, .container span {\r\n    background-color: #fbcc33;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CommentRenderer__container__FqVfv"
};
export default ___CSS_LOADER_EXPORT___;
