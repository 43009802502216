import React, { FC, useState } from 'react';
import { NavigatorPanel } from '../NavigatorPanel/NavigatorPanel.component';
import { useIntl } from 'react-intl';
import messages from '../../messages/Navigator.messages';
import theme from './NavigatorSymbols.scss';
import { EditorMode } from '../../../../models/editorMode';
import { WhiteboardSidebar } from '../WhiteboardSidebar/WhiteboardSidebar.component';
import SymbolsList from './SymbolsList.component';
import { SelectOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { transformToExpandable } from './utils';
import { PictureSymbolConstants } from '../../../../models/pictureSymbolConstants';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { TNavigatorSymbolsProps } from './NavigatorSymbols.types';
import { TNavigatorTab } from '../../../../reducers/navigator.reducer.types';
import DashboardSymbolsList from '../DashboardSymbols/DashboardSymbolsList.component';
import { ModelTypes } from '@/models/ModelTypes';

const NavigatorSymbols: FC<TNavigatorSymbolsProps> = (props) => {
    const { symbols, graph, content, editorMode, isReadOnly, isDashboard, nodeId, onSymbolDrag } = props;
    const intl = useIntl();
    const [expandMode, setExpand] = useState<boolean>(graph?.modelType?.groupSymbol || false);

    const toggleExpand = () => setExpand(!expandMode);

    const filteredSymbols = symbols.filter((k) => k.id !== PictureSymbolConstants.PICTURE_SYMBOL_ID);

    const expandableSymbols =
        graph?.modelType?.id !== ModelTypes.MIND_MAP ? transformToExpandable(filteredSymbols) : [];
    const hasExpandableItems = expandableSymbols.some((symbol) => symbol.nestedSymbols.length);

    const getIcon = () => {
        const titleIcon = expandMode ? (
            <Tooltip
                mouseLeaveDelay={0}
                className={theme.expandIcon}
                title={intl.formatMessage(messages.groupSymbolOff)}
            >
                <UnorderedListOutlined
                    className={theme.expandIcon}
                    onClick={toggleExpand}
                    data-test="symbols-ungroup-icon"
                />
            </Tooltip>
        ) : (
            <Tooltip
                mouseLeaveDelay={0}
                className={theme.expandIcon}
                title={intl.formatMessage(messages.groupSymbolOn)}
            >
                <SelectOutlined className={theme.expandIcon} onClick={toggleExpand} data-test="symbols-group-icon" />
            </Tooltip>
        );

        const disabledIcon = (
            <Tooltip
                mouseLeaveDelay={0}
                className={cx(theme.expandIcon, theme.iconDisabled)}
                title={intl.formatMessage(messages.groupSymbolNotAvailable)}
            >
                <SelectOutlined />
            </Tooltip>
        );

        return hasExpandableItems ? titleIcon : disabledIcon;
    };

    return (
        <NavigatorPanel
            type={TNavigatorTab.Symbols}
            titleProps={{
                title: intl.formatMessage(messages.symbols),
                icon: filteredSymbols.length ? getIcon() : undefined,
            }}
        >
            {isDashboard ? (
                <DashboardSymbolsList nodeId={nodeId} />
            ) : graph?.modelType?.id === ModelTypes.MIND_MAP ? (
                <WhiteboardSidebar onClose={props.onClose} graph={graph} />
            ) : (
                <SymbolsList
                    symbols={expandMode ? expandableSymbols : filteredSymbols}
                    onSymbolDrag={onSymbolDrag}
                    expandMode={expandMode && hasExpandableItems}
                />
            )}
            {(content?.type === 'MODEL' || isDashboard) && editorMode !== EditorMode.Edit && (
                <div className={theme.readModeWarning}>
                    {intl.formatMessage(messages.readModeWarning).concat(' ')}
                    <b>{intl.formatMessage(messages.readModeBoldWarning)}</b>
                    <br />
                    <a onClick={() => !isReadOnly && props.onEditModeEnable()}>
                        {intl.formatMessage(isReadOnly ? messages.editModeForbiddenByProfile : messages.editModeEnable)}
                    </a>
                </div>
            )}
        </NavigatorPanel>
    );
};

export const withMemo = React.memo(
    NavigatorSymbols,
    (props: TNavigatorSymbolsProps, nextProps: TNavigatorSymbolsProps) => {
        if (props.symbols.length !== nextProps.symbols.length) {
            return false;
        }
        if (props.editorMode !== nextProps.editorMode) {
            return false;
        }
        if (!compareNodeIds(props.graph?.id, nextProps.graph?.id)) {
            return false;
        }

        return true; // don't re-render/update
    },
);

export { withMemo as NavigatorSymbols };
