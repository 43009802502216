export const OBJECT_DECOMPOSITION_DIALOG_SUBMIT = 'OBJECT_DECOMPOSITION_DIALOG_SUBMIT';

export const OBJECT_DECOMPOSITION_DIALOG_ERROR = 'OBJECT_DECOMPOSITION_DIALOG_ERROR';

export const OBJECT_DECOMPOSITION_DIALOG_INIT = 'OBJECT_DECOMPOSITION_DIALOG_INIT';

export const OBJECT_DECOMPOSITION_ICON_CLICKED = 'OBJECT_DECOMPOSITION_ICON_CLICKED';

export const OBJECT_DECOMPOSITION_OPEN = 'OBJECT_DECOMPOSITION_OPEN';

export const OBJECT_DECOMPOSITION_CHOOSE_OBJECT_DIALOG_SUBMIT = 'OBJECT_DECOMPOSITION_CHOOSE_OBJECT_DIALOG_SUBMIT';
