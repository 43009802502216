import type { Editor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { StateObserver } from '../common/sharedState.class';

type TSharedStateProps<T, K> = {
    stateObserver?: StateObserver;
    getFocusedBlock?: (editor: Editor) => T;
    getChangedBlock: (editor: Editor, value: K) => boolean;
};

type TUseSharedState<T, K> = {
    setCurrentState: (value: K) => void;
    value: T;
    editor: Editor | undefined;
};

type TChangeWithCallback = (editor: Editor) => boolean;

type THooksResources = {
    focusEditor: () => void;
    changeWith: (fn: TChangeWithCallback) => void;
};

export function useSharedState<T, K>({
    stateObserver,
    getFocusedBlock,
    getChangedBlock,
}: TSharedStateProps<T, K>): TUseSharedState<T | undefined, K> {
    const [value, setValue] = useState<T>();
    const [editorSharedState, setEditorSharedState] = useState<THooksResources>();
    const [editor, setEditor] = useState<Editor>();

    useEffect(() => {
        const handler = (sharedState) => {
            setEditorSharedState(sharedState);

            if (!sharedState) return;
            if (!getFocusedBlock) return;

            const { getEditorState, focusWith } = sharedState;
            setEditor(getEditorState());

            focusWith(getFocusedBlock).then((block) => {
                setValue(block);
            });
        };
        stateObserver?.attach(handler);

        return () => {
            stateObserver?.detach(handler);
        };
    }, []);

    const setCurrentState = (newValue: K): void => {
        if (!editorSharedState) {
            return;
        }

        const { focusEditor, changeWith } = editorSharedState;

        changeWith((_editor: Editor) => {
            focusEditor();

            return getChangedBlock(_editor, newValue);
        });
    };

    return { setCurrentState, value, editor };
}
