import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import theme from './ChildElementsTab.scss';
import messages from './ChildElementsTab.messages';
import { PresetSettingsModelTypeSelectors } from '../../../../../../../selectors/presetSettings/presetSettingsModelType.selectors';
import {
    ModelType,
    FolderType,
    ObjectType,
    ObjectTypeGroup,
    FolderTypeValidNodeTypesEnum,
} from '../../../../../../../serverapi/api';
import icModel from '../../../../../../../resources/icons/ic-tree-model-active.svg';
import icObject from '../../../../../../../resources/icons/object.svg';
import { ObjectTypeSelectors } from '../../../../../../../selectors/objectType.selectors';
import { ObjectTypeGroupSelectors } from '../../../../../../../selectors/objectTypeGroup.selectors';
import { FolderTypeSelectors } from '../../../../../../../selectors/folderType.selectors';
import { useIntl } from 'react-intl';
import { TreeItemType } from '../../../../../../Tree/models/tree';
import modelTypeMessages from '../../../../../../../models/modelType.messages';
import icSimulationModel from '../../../../../../../resources/icons/ic-tree-im.svg';
import icFile from '../../../../../../../resources/icons/file.svg';
import icSpreadsheet from '../../../../../../../resources/icons/ic-spreadsheet.svg';
import { TNodeType } from '../../FolderType/FolderTypeTab.types';
import { TChildElementsTabProps } from './ChildElementsTab.types';
import { GroupedDataTab } from '../../common/GroupedDataTab.component';
import { FolderTypesTab } from '../../common/FolderTypesTab.component';
import { DataTypesTab } from '../../common/DataTypesTab.component';

export const ChildElementsTab = ({
    preset,
    serverNode,
    modelTypeGroups,
    getValidTypeSetter,
    getAllowTypeSetter,
    validModelTypes,
    validObjectTypes,
    validFolderTypes,
    validNodeTypes,
    allowAnyModelType,
    allowAnyObjectType,
    allowAnyFolderType,
    allowAnyNodeType,
}: TChildElementsTabProps) => {
    const intl = useIntl();

    const { serverId } = serverNode.nodeId;
    const presetId = preset.id;
    const modelTypes: ModelType[] = useSelector(PresetSettingsModelTypeSelectors.listByPresetId(serverId, presetId));

    const objectTypes: ObjectType[] = useSelector(ObjectTypeSelectors.listAllByPreset(serverId, presetId));
    const objectTypesWithIds = objectTypes.map((type) => ({
        ...type,
        groupId: type.objectTypeGroup?.id,
    }));
    const objectTypeGroups: ObjectTypeGroup[] = useSelector(
        ObjectTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId,
        }),
    );

    const folderTypes: FolderType[] = useSelector(FolderTypeSelectors.listByPresetId({ serverId, presetId }));

    // NodeTypes
    const file = {
        name: intl.formatMessage(modelTypeMessages.FILE),
        id: TreeItemType.File as FolderTypeValidNodeTypesEnum,
        icon: icFile,
    };
    const simulationModel = {
        name: intl.formatMessage(modelTypeMessages.SIMULATION),
        id: TreeItemType.SimulationModeling as FolderTypeValidNodeTypesEnum,
        icon: icSimulationModel,
    };
    const spreadsheet = {
        name: intl.formatMessage(modelTypeMessages.SPREADSHEET),
        id: TreeItemType.Spreadsheet as FolderTypeValidNodeTypesEnum,
        icon: icSpreadsheet,
    };
    const nodeTypes: TNodeType[] = [file, simulationModel, spreadsheet];

    const tabs = [
        {
            label: intl.formatMessage(messages.models),
            key: 'Models',
            children: (
                <GroupedDataTab
                    dataTypes={modelTypes}
                    dataTypeGroups={modelTypeGroups}
                    validTypes={validModelTypes}
                    setValidTypes={getValidTypeSetter('validModelTypes')}
                    icon={icModel}
                    isAllowAny={allowAnyModelType}
                    setIsAllowAny={getAllowTypeSetter('allowAnyModelType')}
                />
            ),
        },
        {
            label: intl.formatMessage(messages.objects),
            key: 'Objects',
            children: (
                <GroupedDataTab
                    dataTypes={objectTypesWithIds}
                    dataTypeGroups={objectTypeGroups}
                    validTypes={validObjectTypes}
                    setValidTypes={getValidTypeSetter('validObjectTypes')}
                    icon={icObject}
                    isAllowAny={allowAnyObjectType}
                    setIsAllowAny={getAllowTypeSetter('allowAnyObjectType')}
                />
            ),
        },
        {
            label: intl.formatMessage(messages.folders),
            key: 'Folders',
            children: (
                <FolderTypesTab
                    serverId={serverId}
                    presetId={presetId}
                    folderTypes={folderTypes}
                    validTypes={validFolderTypes}
                    setValidFolderTypes={getValidTypeSetter('validFolderTypes')}
                    isAllowAny={allowAnyFolderType}
                    setIsAllowAny={getAllowTypeSetter('allowAnyFolderType')}
                />
            ),
        },
        {
            label: intl.formatMessage(messages.elements),
            key: 'Elements',
            children: (
                <DataTypesTab
                    dataTypes={nodeTypes}
                    validTypes={validNodeTypes}
                    setValidTypes={getValidTypeSetter('validNodeTypes')}
                    isAllowAny={allowAnyNodeType}
                    setIsAllowAny={getAllowTypeSetter('allowAnyNodeType')}
                />
            ),
        },
    ];

    return (
        <div className={theme.container}>
            <Tabs className={theme.tabs} items={tabs} />
        </div>
    );
};
