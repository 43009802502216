import rectangleAllBorders from '../../../../../../../../../resources/icons/rectangleAllBorders.svg';
import rectangleLeftBorder from '../../../../../../../../../resources/icons/rectangleLeftBorder.svg';
import rectangleTopBorder from '../../../../../../../../../resources/icons/rectangleTopBorder.svg';
import rectangleRightBorder from '../../../../../../../../../resources/icons/rectangleRightBorder.svg';
import rectangleBottomBorder from '../../../../../../../../../resources/icons/rectangleBottomBorder.svg';
import rhombusLeft from '../../../../../../../../../resources/icons/rhombusLeft.svg';
import rhombusTop from '../../../../../../../../../resources/icons/rhombusTop.svg';
import rhombusRight from '../../../../../../../../../resources/icons/rhombusRight.svg';
import rhombusBottom from '../../../../../../../../../resources/icons/rhombusBottom.svg';
import circleSingleLine from '../../../../../../../../../resources/icons/circleSingleLine.svg';
import rectangleAllRounded from '../../../../../../../../../resources/icons/rectangleAllRounded.svg';
import rectangleTopLeftRounded from '../../../../../../../../../resources/icons/rectangleTopLeftRounded.svg';
import rectangleTopRightRounded from '../../../../../../../../../resources/icons/rectangleTopRightRounded.svg';
import rectangleBottomRightRounded from '../../../../../../../../../resources/icons/rectangleBottomRightRounded.svg';
import rectangleBottomLeftRounded from '../../../../../../../../../resources/icons/rectangleBottomLeftRounded.svg';
import hexagonTopBorder from '../../../../../../../../../resources/icons/hexagonTopBorder.svg';
import hexagonTopRightBorder from '../../../../../../../../../resources/icons/hexagonTopRightBorder.svg';
import hexagonBottomRightBorder from '../../../../../../../../../resources/icons/hexagonBottomRightBorder.svg';
import hexagonBottomBorder from '../../../../../../../../../resources/icons/hexagonBottomBorder.svg';
import hexagonBottomLeftBorder from '../../../../../../../../../resources/icons/hexagonBottomLeftBorder.svg';
import hexagonTopLeftBorder from '../../../../../../../../../resources/icons/hexagonTopLeftBorder.svg';
import hexagonAllBorders from '../../../../../../../../../resources/icons/hexagonAllBorders.svg';
import rhombusBottomLeftBorder from '../../../../../../../../../resources/icons/rhombusBottomLeftBorder.svg';
import rhombusTopLeftBorder from '../../../../../../../../../resources/icons/rhombusTopLeftBorder.svg';
import rhombusTopRightBorder from '../../../../../../../../../resources/icons/rhombusTopRightBorder.svg';
import rhombusBottomRightBorder from '../../../../../../../../../resources/icons/rhombusBottomRightBorder.svg';
import rhombusAllBorder from '../../../../../../../../../resources/icons/rhombusAllBorder.svg';
import hexagonTopLeft from '../../../../../../../../../resources/icons/hexagonTopLeft.svg';
import hexagonTopRight from '../../../../../../../../../resources/icons/hexagonTopRight.svg';
import hexagonLeft from '../../../../../../../../../resources/icons/hexagonLeft.svg';
import hexagonRight from '../../../../../../../../../resources/icons/hexagonRight.svg';
import hexagonBottomRight from '../../../../../../../../../resources/icons/hexagonBottomRight.svg';
import hexagonBottomLeft from '../../../../../../../../../resources/icons/hexagonBottomLeft.svg';
import { SymbolSettingsTypeEnum } from '../../../../../../../../../serverapi/api';

export const getIcons = (typeSymbol: SymbolSettingsTypeEnum, typeMenu: 'rounding' | 'borders') => {
    if (typeSymbol === 'RECTANGLE' || typeSymbol === 'RECTANGLE_FIXED_ICON' || typeSymbol === 'FUNCTION_FIXED_ICON') {
        if (typeMenu === 'rounding') {
            return [
                rectangleAllRounded,
                rectangleTopLeftRounded,
                rectangleTopRightRounded,
                rectangleBottomRightRounded,
                rectangleBottomLeftRounded,
            ];
        }
        if (typeMenu === 'borders') {
            return [
                rectangleAllBorders,
                rectangleLeftBorder,
                rectangleTopBorder,
                rectangleRightBorder,
                rectangleBottomBorder,
            ];
        }
    }
    if (typeSymbol === 'RHOMBUS') {
        if (typeMenu === 'rounding') {
            return [rhombusAllBorder, rhombusLeft, rhombusTop, rhombusRight, rhombusBottom];
        }
        if (typeMenu === 'borders') {
            return [
                rhombusAllBorder,
                rhombusBottomLeftBorder,
                rhombusTopLeftBorder,
                rhombusTopRightBorder,
                rhombusBottomRightBorder,
            ];
        }
    }
    if (typeSymbol === 'HEXAGON') {
        if (typeMenu === 'rounding') {
            return [
                hexagonAllBorders,
                hexagonTopLeft,
                hexagonTopRight,
                hexagonRight,
                hexagonBottomRight,
                hexagonBottomLeft,
                hexagonLeft,
            ];
        }
        if (typeMenu === 'borders') {
            return [
                hexagonAllBorders,
                hexagonTopLeftBorder,
                hexagonTopBorder,
                hexagonTopRightBorder,
                hexagonBottomRightBorder,
                hexagonBottomBorder,
                hexagonBottomLeftBorder,
            ];
        }
    }
    if (typeSymbol === 'CIRCLE' && typeMenu === 'borders') {
        return [circleSingleLine];
    }

    return [];
};
