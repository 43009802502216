import React, { FC } from 'react';
import icRibbonLineType from '../../../../resources/icons/toolbar/ic-edge-type.svg';
import { createCompactableSelect } from '../CompactableSelect/CompactableSelect.component';
import { EdgeType } from '../../../../serverapi/api';
import { useIntl } from 'react-intl';
import messages from '../../messages/EdgeTypesName.messages';
import { TCompactableSelectItemType, ICompactableSelectItemValue } from '../CompactableSelect/CompactableSelect.types';
import theme from '../CompactableDropdownButton/CompactableDropdownButton.scss';

type TEdgeTypeSelectProps = {
    items: EdgeType[];
    onSelect?: (value: string) => void;
    compact?: boolean;
    disabled?: boolean;
};

const CompactableSelect = createCompactableSelect<string>();

const EdgeTypeSelect: FC<TEdgeTypeSelectProps> = (props) => {
    const { items, disabled, compact, onSelect } = props;
    const intl = useIntl();

    const createSelectItemsForEdgeTypes = (): ICompactableSelectItemValue<string>[] => {
        return items.reduce((acc, item) => {
            if (!item) return acc;

            let label;
            if (item.localizableName && messages[item.localizableName]) {
                label = intl.formatMessage(messages[item.localizableName]);
            } else {
                label = item.localizableName;
            }

            return [
                ...acc,
                {
                    type: TCompactableSelectItemType.Value,
                    value: item.id,
                    label,
                    disabled: false,
                },
            ];
        }, []);
    };

    return (
        <CompactableSelect
            title={intl.formatMessage(messages.title)}
            compact={compact}
            items={createSelectItemsForEdgeTypes()}
            onSelect={onSelect}
            defaultSprite={icRibbonLineType}
            disabled={disabled}
            className={theme.btn}
        />
    );
};

export { EdgeTypeSelect };
