import { differenceBy } from 'lodash-es';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import icAttribute from '../../../../../../resources/icons/ic-attribute.svg';
import { AttributeType, AttributeTypeGroup } from '../../../../../../serverapi/api';
import { DeleteSelected, TabHeader } from '../Header/TabHeader.component';
import { GroupedTypesTable } from '../util/GroupedTypesTable.component';
import { AttributesTabDialog } from './AttributesTabDialog/AddAttributesDialog.component';
import messages from './AttributeType.messages';
import presetMessages from '../../../messages/Presets.messages';
import modelTypeMessages from '../../../../../../models/modelType.messages';
import theme from './AttributeType.scss';
import { Alert } from 'antd';
import { TreeItemType } from '../../../../../Tree/models/tree';
import { attributeTypeFormatMessage } from '../AttributeTypesTab/util/attributeTypesTab.utils';
import { getMetodologyElementsDeleteMessages } from '../util/metodologyElementsDeleteMessages.utils';
import { Select } from '../../../../../UIKit/components/Select/Select.component';

type TAttributesTabProps = {
    attributeTypes: AttributeType[];
    availableAttributeTypes: AttributeType[];
    availableTreeNodeTypesState?: TreeItemType[];
    selectedTreeItemType?: string;
    disabled?: boolean;
};

type TAttributesTabActionProps = {
    addAttributeTypes: (attributeTypes: AttributeType[]) => void;
    deleteAttributeTypes: (attributeTypes: AttributeType[]) => void;
    deleteAttributeTypeGroups: (attributeTypeGroups: AttributeTypeGroup[]) => void;
    setSelectedTreeItemType?: (value: React.SetStateAction<string>) => void;
};

type TAttributesTabFullProps = TAttributesTabProps & TAttributesTabActionProps;

export const AttributesTab = (props: TAttributesTabFullProps) => {
    const {
        attributeTypes,
        availableAttributeTypes,
        availableTreeNodeTypesState,
        selectedTreeItemType,
        disabled,
        addAttributeTypes,
        deleteAttributeTypes,
        deleteAttributeTypeGroups,
        setSelectedTreeItemType,
    } = props;
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [selectedAttributeTypes, setSelectedAttributeTypes] = useState<AttributeType[]>([]);
    const [selectedAttributeTypeGroups, setSelectedAttributeTypeGroups] = useState<AttributeTypeGroup[]>([]);
    const selected = selectedAttributeTypes.length || selectedAttributeTypeGroups.length;
    const intl = useIntl();

    const attributeTypeGroups: AttributeTypeGroup[] = attributeTypes
        .map((at) => at.attributeTypeGroup)
        .reduce((acc: AttributeTypeGroup[], atg: AttributeTypeGroup) => {
            if (!acc.some((attributeTypeGroup) => atg.id === attributeTypeGroup.id)) {
                acc.push(atg);
            }

            return acc;
        }, []);

    const filteredAvailableAttributes = () => differenceBy(availableAttributeTypes, attributeTypes, 'id');

    const selectOnChange = (type) => {
        if (setSelectedTreeItemType) {
            setSelectedTreeItemType(type);
        }
    };

    const { deleteMessage: deleteAttributesMessage, deleteGroupsMessage } = getMetodologyElementsDeleteMessages({
        selectedAttributeTypes,
        selectedAttributeTypeGroups,
    });

    const tableRender = () => (
        <GroupedTypesTable
            types={attributeTypes?.map((type) => ({
                ...type,
                groupId: type.attributeTypeGroup?.id,
                valueType: type.valueType && attributeTypeFormatMessage(type.valueType),
            }))}
            icon={icAttribute}
            onSelectType={setSelectedAttributeTypes as any}
            selectedTypes={selectedAttributeTypes}
            onSelectGroup={setSelectedAttributeTypeGroups as any}
            selectedTypeGroups={selectedAttributeTypeGroups}
            typeGroups={attributeTypeGroups}
            searchFilter={searchFilter}
            actionsDisabled={!!disabled}
            onDeleteType={(type: AttributeType) => deleteAttributeTypes([type])}
            onDeleteGroup={(group: AttributeTypeGroup) => deleteAttributeTypeGroups([group])}
            columns={[
                {
                    label: intl.formatMessage(messages.format),
                    maxWidth: 130,
                    minWidth: 130,
                    width: 130,
                    dataKey: 'valueType',
                    cellRenderer: ({ rowData }) => rowData.valueType,
                },
            ]}
            titleDeleteMessage={messages.deleteAttributesDialogTitle}
            deleteGroupMessage={messages.deleteAttributeGroups}
            deleteElMessage={messages.deleteAttributes}
        />
    );

    return (
        <div className={theme.container}>
            {availableTreeNodeTypesState && (
                <div className={theme.selectWrapper}>
                    <Select
                        data-test="edit-methodology_select-element"
                        value={selectedTreeItemType ? intl.formatMessage(modelTypeMessages[selectedTreeItemType]) : ''}
                        label={intl.formatMessage(presetMessages.choosingNodeType)}
                        onChange={selectOnChange}
                        originalTheme
                    >
                        {availableTreeNodeTypesState.map((type) => (
                            <Select.Option
                                data-test={`edit-methodology_select-element_${intl.formatMessage(
                                    modelTypeMessages[type],
                                )}`}
                                value={type}
                                key={type}
                                label={intl.formatMessage(modelTypeMessages[type])}
                            />
                        ))}
                    </Select>
                </div>
            )}
            {dialogVisible && (
                <AttributesTabDialog
                    dialogVisible={dialogVisible}
                    attributeTypes={filteredAvailableAttributes()}
                    onCancel={() => setDialogVisible(false)}
                    onOk={(attributeTypes: AttributeType[]) => {
                        setDialogVisible(false);
                        addAttributeTypes(attributeTypes);
                    }}
                />
            )}
            <TabHeader
                buttons={[
                    {
                        children: intl.formatMessage(messages.addAttributes),
                        dataTest: 'new-attribute_type_button',
                        onClick: () => setDialogVisible(true),
                        disabled,
                    },
                    DeleteSelected.build(
                        () => {
                            if (selectedAttributeTypes.length) {
                                setSelectedAttributeTypes([]);
                                deleteAttributeTypes(selectedAttributeTypes);
                            }
                            if (selectedAttributeTypeGroups.length) {
                                deleteAttributeTypeGroups(selectedAttributeTypeGroups);
                            }
                        },
                        !selected,
                        undefined,
                        intl.formatMessage(messages.deleteAttributesDialogTitle),
                        <Alert
                            message={
                                <>
                                    {deleteGroupsMessage}
                                    {deleteAttributesMessage}
                                </>
                            }
                            type="warning"
                        />,
                    ),
                ]}
                onSearchChange={setSearchFilter}
            />
            {availableTreeNodeTypesState
                ? availableTreeNodeTypesState?.map((availableTreeNodeType: TreeItemType) => (
                      <div
                          className={
                              availableTreeNodeType === selectedTreeItemType ? theme.tableContainer : theme.hidden
                          }
                      >
                          {tableRender()}
                      </div>
                  ))
                : tableRender()}
        </div>
    );
};
