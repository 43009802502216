import { showNotification } from '../actions/notification.actions';
import { TNotificationShowAction } from '../actions/notification.actions.types';
import { EdgeInstanceImpl } from '../models/bpm/bpm-model-impl';
import { NotificationType } from '../models/notificationType';
import AppNotificationsMessages from '../modules/App/messages/AppNotifications.messages';
import { SequenceEdgeTypesId } from '../mxgraph/SequenceGraph/SequenceConstants';
import { ModelType } from '../serverapi/api';
import { LocalesService } from '../services/LocalesService';
import { NotationHelper } from '../services/utils/NotationHelper';
import { v4 as uuid } from 'uuid';
import { TDividingEdges } from './edgesForChangeType.utils.types';

export const divideEdgesBychangeAbility = (
    edges: EdgeInstanceImpl[],
    forbiddenByProfileIds: string[],
): TDividingEdges => {
    const { allowedEdges, deniedEdgesByProfile, edgesWithDefinition, edgesWithOnlyOneType } = edges.reduce(
        (acc, el) => {
            const isEdgeWithOnlyOneType: boolean = el.edgeTypeId === SequenceEdgeTypesId.RECURSIVE_MESSAGE;
            const isDeniedEdgeByProfile: boolean = forbiddenByProfileIds.includes(el.edgeTypeId);
            const isEdgeWithDefinition: boolean = !!el.edgeDefinitionId;
            if (isDeniedEdgeByProfile) acc.deniedEdgesByProfile.push(el);
            if (isEdgeWithDefinition) acc.edgesWithDefinition.push(el);
            if (isEdgeWithOnlyOneType) acc.edgesWithOnlyOneType.push(el);
            if (!(isEdgeWithOnlyOneType || isDeniedEdgeByProfile || isEdgeWithDefinition)) acc.allowedEdges.push(el);

            return acc;
        },
        {
            allowedEdges: [] as EdgeInstanceImpl[],
            deniedEdgesByProfile: [] as EdgeInstanceImpl[],
            edgesWithDefinition: [] as EdgeInstanceImpl[],
            edgesWithOnlyOneType: [] as EdgeInstanceImpl[],
        },
    );

    return { allowedEdges, deniedEdgesByProfile, edgesWithDefinition, edgesWithOnlyOneType };
};

export const getShowNotificationActions = (
    forbiddenEdges: EdgeInstanceImpl[],
    edgesWithDefinition: EdgeInstanceImpl[],
    edgesWithOnlyOneType: EdgeInstanceImpl[],
    modelType: ModelType | undefined,
): TNotificationShowAction[] => {
    const intl = LocalesService.useIntl();

    return [
        ...forbiddenEdges.map((edge) =>
            showNotification({
                id: uuid(),
                data: {
                    message: intl.formatMessage(AppNotificationsMessages.forbiddenEdgeByProfileMessage, {
                        edgeTypeName: LocalesService.internationalStringToString(
                            NotationHelper.getEdgeTypeByName(modelType, edge.edgeTypeId)?.multilingualName,
                        ),
                    }),
                },
                type: NotificationType.EDGE_IS_FORBIDDEN_TO_EDIT,
            }),
        ),
        ...edgesWithDefinition.map((edge) =>
            showNotification({
                id: uuid(),
                data: {
                    message: intl.formatMessage(AppNotificationsMessages.edgeHasDefinitionMessage, {
                        edgeTypeName: LocalesService.internationalStringToString(
                            NotationHelper.getEdgeTypeByName(modelType, edge.edgeTypeId)?.multilingualName,
                        ),
                    }),
                },
                type: NotificationType.EDGE_IS_FORBIDDEN_TO_EDIT,
            }),
        ),
        ...edgesWithOnlyOneType.map((edge) =>
            showNotification({
                id: uuid(),
                data: {
                    message: intl.formatMessage(AppNotificationsMessages.edgeCanHabeOnlyOneTypeMessage, {
                        edgeTypeName: LocalesService.internationalStringToString(
                            NotationHelper.getEdgeTypeByName(modelType, edge.edgeTypeId)?.multilingualName,
                        ),
                    }),
                },
                type: NotificationType.EDGE_IS_FORBIDDEN_TO_EDIT,
            }),
        ),
    ];
};
