import React from 'react';
import theme from './SortIndicator.scss';
import { CaretOutlined } from './CaretOutlined/CaretUpOutlined.component';
import { SortDirectionType } from 'react-virtualized';

type TSortIndicatorProps = {
    sortDirection?: SortDirectionType;
    isActive: boolean;
};

export const SortIndicator = (props: TSortIndicatorProps) => {
    const { sortDirection, isActive } = props;
    const isASC: boolean = sortDirection === 'ASC';

    return (
        <span className={theme.sortIndicator}>
            <CaretOutlined
                isUpOutlined
                className={isASC && isActive ? theme.sortIndicatorBlueIcon : theme.sortIndicatorIcon}
            />
            <CaretOutlined className={!isASC && isActive ? theme.sortIndicatorBlueIcon : theme.sortIndicatorIcon} />
        </span>
    );
};
