import type { TRootState } from '../reducers/root.reducer.types';
import type { NodeId } from '../serverapi/api';
import { createSelector } from 'reselect';
import { ExpandStatus } from '@/reducers/tree.reducer.types';
import { TExpandedStatusState } from '@/reducers/expandedStatus.reducer.types';

const getState = (state: TRootState) => state.expandedStatus;

export namespace ExpandedStatusSelector {
    export const expandStatus = (treeName: string, nodeId: NodeId) =>
        createSelector<TRootState, TExpandedStatusState, ExpandStatus>(getState, (expandedNodes) => {
            const { id, repositoryId, serverId } = nodeId;
            const expand = expandedNodes[treeName]?.[serverId]?.[repositoryId]?.[id]?.expand;
            if (expand) return expand;

            return ExpandStatus.CLOSED;
        });
}
