import { connect } from 'react-redux';
import { DatabaseDialog } from '../components/Database/DatabaseDialog.component';
import { TRootState } from '../../../reducers/root.reducer.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { createDatabase } from '../../../actions/databaseDialog.actions';
import { getDatabaseDialogState } from '../../../selectors/databaseDialog.selectors';
import { TCreateDatabasePayload } from '../../../actions/databaseDialog.actions.types';

const mapStateToProps = (state: TRootState) => {
    return {
        error: getDatabaseDialogState(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (payload: TCreateDatabasePayload) => dispatch(createDatabase(payload)),
    onCancel: () => dispatch(closeDialog(DialogType.DATABASE_CREATE)),
});

export const DatabaseCreateDialogContainer = connect(mapStateToProps, mapDispatchToProps)(DatabaseDialog);
