import { Dropdown, Menu } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import messages from '../../../../messages/SimulationModeling.messages';

type TEditActionsIconProps = WrappedComponentProps & {
    onDelete: () => void;
    onEdit: () => void;
};
enum Actions {
    EDIT,
    DELETE,
}

const EditActionsIconComponent = (props: TEditActionsIconProps) => {
    const onClick = ({ key }) => {
        switch (Number(key)) {
            case Actions.EDIT: {
                props.onEdit();

                return;
            }
            case Actions.DELETE: {
                props.onDelete();

                return;
            }
            default: {
                return;
            }
        }
    };
    const menu = (
        <Menu onClick={onClick}>
            <Menu.Item key={Actions.EDIT}>{props.intl.formatMessage(messages.edit)}</Menu.Item>
            <Menu.Item key={Actions.DELETE}>{props.intl.formatMessage(messages.delete)}</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown destroyPopupOnHide overlay={menu} placement="bottomLeft">
            <EditOutlined onClick={(event) => event.stopPropagation()} />
        </Dropdown>
    );
};

const IntlComponent = injectIntl(EditActionsIconComponent);

export { IntlComponent as EditActionsIcon };
