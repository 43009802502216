export const INSTANCE_PERMISSIONS_DIALOG_SUBMIT_DATA = 'INSTANCE_PERMISSIONS_DIALOG_SUBMIT_DATA';

export const INSTANCE_PERMISSIONS_DIALOG_SUBMIT_RESULT = 'INSTANCE_PERMISSIONS_DIALOG_SUBMIT_RESULT';

export const INSTANCE_PERMISSIONS_REQUEST = 'INSTANCE_PERMISSIONS_REQUEST';

export const INSTANCE_PERMISSIONS_DATA = 'INSTANCE_PERMISSIONS_DATA';

export const INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_UPDATE = 'INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_UPDATE';

export const INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_DELETE = 'INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_DELETE';

export const INSTANCE_PERMISSIONS_ADD_PRINCIPALS = 'INSTANCE_PERMISSIONS_ADD_PRINCIPALS';

export const INSTANCE_PERMISSIONS_FAILURE = 'INSTANCE_PERMISSIONS_FAILURE';
