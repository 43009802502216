import React, { useState } from 'react';
import { TreeNode } from '../../../models/tree.types';
import { TPreset } from '../../../models/preset.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { useDispatch } from 'react-redux';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { shouldCallModalSubmitHandler } from '../../../services/utils/ModalHelper';
import { createModelType } from '../../../actions/presetSettings/presetSettingsModelType.actions';
import { createKanbanModelType } from '../../../actions/presetSettings/presetSettingsKanbanModelType.actions';
import { GeneralModelTypeDiscriminator } from '../../../models/ModelTypes';
import { GeneralModelTypeDiscriminatorEnum } from '../../../serverapi/api';
import { useIntl } from 'react-intl';
import messages from './SelectModelTypeDialog.messages';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { Select } from '../../UIKit/components/Select/Select.component';
import theme from './SelectModelTypeDialog.scss';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { createMatrixModelType } from '@/actions/presetSettings/presetSettingsMatrixModelType.actions';
import { createReportModelType } from '@/actions/presetSettings/presetSettingsReportModelType.actions';

type TSelectModelTypeDialog = {
    serverNode: TreeNode;
    preset: TPreset;
};

export const SelectModelTypeDialog = ({ serverNode, preset }: TSelectModelTypeDialog) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [modelType, setModelType] = useState<GeneralModelTypeDiscriminatorEnum>(
        GeneralModelTypeDiscriminator.MODEL_TYPE,
    );

    const handleCancelClick = () => {
        dispatch(closeDialog(DialogType.SELECT_MODEL_TYPE));
    };

    const handleOkClick = (): void => {
        dispatch(closeDialog(DialogType.SELECT_MODEL_TYPE));

        switch (modelType) {
            case GeneralModelTypeDiscriminator.MODEL_TYPE:
                dispatch(
                    createModelType({
                        serverNode,
                        preset,
                    }),
                );
                break;

            case GeneralModelTypeDiscriminator.KANBAN:
                dispatch(
                    createKanbanModelType({
                        serverNodeId: serverNode.nodeId,
                        presetId: preset.id,
                    }),
                );
                break;

            case GeneralModelTypeDiscriminator.MATRIX:
                dispatch(
                    createMatrixModelType({
                        serverNodeId: serverNode.nodeId,
                        presetId: preset.id,
                    }),
                );
                break;

            case GeneralModelTypeDiscriminator.REPORT:
                dispatch(
                    createReportModelType({
                        serverNodeId: serverNode.nodeId,
                        presetId: preset.id,
                    }),
                );
                break;

            default:
                break;
        }
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCancelClick,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: handleOkClick,
                    value: intl.formatMessage(messages.create),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    const selectValues = {
        [GeneralModelTypeDiscriminator.MODEL_TYPE]: intl.formatMessage(messages.defaultModel),
        [GeneralModelTypeDiscriminator.KANBAN]: intl.formatMessage(messages.kanbanBoard),
        [GeneralModelTypeDiscriminator.MATRIX]: intl.formatMessage(messages.matrix),
        [GeneralModelTypeDiscriminator.REPORT]: intl.formatMessage(messages.report),
    };

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    handleOkClick();
                }
            }}
        >
            <Dialog
                open
                title={intl.formatMessage(messages.selectModelType)}
                onCancel={handleCancelClick}
                className={theme.dialog}
                footer={footer}
            >
                <Select
                    originalTheme
                    value={selectValues[modelType]}
                    onChange={(val: GeneralModelTypeDiscriminator) => setModelType(val)}
                >
                    <Select.Option
                        key="1"
                        value={GeneralModelTypeDiscriminator.MODEL_TYPE}
                        label={intl.formatMessage(messages.defaultModel)}
                    />
                    <Select.Option
                        key="2"
                        value={GeneralModelTypeDiscriminator.KANBAN}
                        label={intl.formatMessage(messages.kanbanBoard)}
                    />
                    <Select.Option
                        key="3"
                        value={GeneralModelTypeDiscriminator.MATRIX}
                        label={intl.formatMessage(messages.matrix)}
                    />
                    <Select.Option
                        key="4"
                        value={GeneralModelTypeDiscriminator.REPORT}
                        label={intl.formatMessage(messages.report)}
                    />
                </Select>
            </Dialog>
        </div>
    );
};
