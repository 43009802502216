import {
    EDGE_TYPE_REQUEST_SUCCESS,
    EDGE_TYPE_DELETE_REQUEST_SUCCESS,
    CHECK_INVISIBLE_EDGE_TYPE,
    CREATE_EDGE_TYPE,
    EDIT_EDGE_TYPE,
    DELETE_EDGE_TYPE,
    SUBMIT_EDGE_TYPE,
    EDGE_TYPE_REQUEST_FAILURE,
    DELETE_EDGE_TYPE_AND_GROUP,
} from '../actionsTypes/edgeType.actionTypes';
import { EdgeType } from '../serverapi/api';
import {
    TCheckInvisibleEdgeTypesAction,
    TCheckInvisibleEdgeTypesPayload,
    TCreateEdgeTypeAction,
    TCreateEdgeTypePayload,
    TDeleteEdgeTypeAction,
    TDeleteEdgeTypePayload,
    TEdgeTypeDeleteRequestSuccessAction,
    TEdgeTypeRequestFailureAction,
    TEdgeTypeRequestSuccessAction,
    TEditEdgeTypeAction,
    TEditEdgeTypePayload,
    TSubmitEdgeTypeAction,
    TSubmitEdgeTypePayload,
    TDeleteEdgeTypeAndGroupPayload,
} from './edgeType.actions.types';

export const edgeTypeRequestSuccess = (
    serverId: string,
    edgeTypes: EdgeType[],
    presetId: string,
): TEdgeTypeRequestSuccessAction => ({
    type: EDGE_TYPE_REQUEST_SUCCESS,
    payload: {
        serverId,
        edgeTypes,
        presetId,
    },
});

export const edgeTypeDeleteRequestSuccess = (
    serverId: string,
    edgeTypes: EdgeType[],
): TEdgeTypeDeleteRequestSuccessAction => ({
    type: EDGE_TYPE_DELETE_REQUEST_SUCCESS,
    payload: {
        serverId,
        edgeTypes,
    },
});

export const checkInvisibleEdgeTypes = (payload: TCheckInvisibleEdgeTypesPayload): TCheckInvisibleEdgeTypesAction => ({
    type: CHECK_INVISIBLE_EDGE_TYPE,
    payload,
});

export const submitEdgeType = (payload: TSubmitEdgeTypePayload): TSubmitEdgeTypeAction => ({
    type: SUBMIT_EDGE_TYPE,
    payload,
});

export const deleteEdgeType = (payload: TDeleteEdgeTypePayload): TDeleteEdgeTypeAction => ({
    type: DELETE_EDGE_TYPE,
    payload,
});

export const editEdgeType = (payload: TEditEdgeTypePayload): TEditEdgeTypeAction => ({
    type: EDIT_EDGE_TYPE,
    payload,
});

export const createEdgeType = (payload: TCreateEdgeTypePayload): TCreateEdgeTypeAction => ({
    type: CREATE_EDGE_TYPE,
    payload,
});

export const edgeTypeRequesFailure = (): TEdgeTypeRequestFailureAction => ({
    type: EDGE_TYPE_REQUEST_FAILURE,
});

export const deleteEdgeTypeAndGroup = (payload: TDeleteEdgeTypeAndGroupPayload) => ({
    type: DELETE_EDGE_TYPE_AND_GROUP,
    payload,
});
