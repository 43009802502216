import { BPMMxSvgCanvas2D } from '../BPMMxSvgCanvas2D';
import { MxShape } from '../mxgraph';

MxShape.prototype.createSvgCanvas = function () {
    const options = {
        multilineOverflow: this.state?.style?.multilineOverflow,
    };
    const canvas = new BPMMxSvgCanvas2D(this.node, false, options);
    canvas.strokeTolerance = this.pointerEvents ? this.svgStrokeTolerance : 0;
    canvas.pointerEventsValue = this.svgPointerEvents;
    const off = this.getSvgScreenOffset();

    if (off !== 0) {
        this.node.setAttribute('transform', `translate(${off},${off})`);
    } else {
        this.node.removeAttribute('transform');
    }

    canvas.minStrokeWidth = this.minSvgStrokeWidth;

    if (!this.antiAlias) {
        // Rounds all numbers in the SVG output to integers
        canvas.format = function (value) {
            return Math.round(parseFloat(value));
        };
    }

    return canvas;
};

const originaLShapePaint = MxShape.prototype.paint;
MxShape.prototype.paint = function (c) {
    originaLShapePaint.apply(this, arguments);
    c.root.setAttribute('data-test', 'graph_element');
};

export { MxShape };
