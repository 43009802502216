import type { TTabComponentProps } from '@/modules/Workspace/Workspace.types';
import type { TWikiEditorProps } from '../components/WikiEditor.types';
import type { TRootState } from '@/reducers/root.reducer.types';
import type { NodeId, WikiContent } from '../../../serverapi/api';
import type { TDispatch } from '@/utils/types';
import WikiEditor from '../components/WikiEditor.component';
import { connect } from 'react-redux';
import { loadComments } from '@/actions/comments.actions';
import { wikiChangeRequest, wikiDropDraggedNode, wikiUploadImage } from '@/actions/entities/wiki.actions';
import { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { CommentsSelectors } from '@/selectors/comments.selectors';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { openNodeById } from '@/actions/openNode.actions';
import { getEditorData, getWikiImageSrc, isNewEditorData, WIKI_CONTENT_PROP_NAME } from '../WikiEditor.utils';
import { timestampToStringDate } from '@/utils/date.time.utils';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import { getShortDisplayName } from '@/utils/users.utils';

const mapStateToProps = (state: TRootState, ownProps: TTabComponentProps) => {
    const content = ownProps.tab.content as IWikiNode;
    const server = ServerSelectors.server(content.serverId)(state);
    const baseUrl = server?.url ? `${server.url}/${PictureSymbolConstants.DOWNLOAD_LINK}` : '';
    const { zoomLevel } = ownProps.tab.params;
    const commentsEnabledSchemesIds: NodeId[] = CommentsSelectors.commentsEnabledSchemesIds(state);
    const isShowCommentsPanel = commentsEnabledSchemesIds.some((id) => compareNodeIds(id, ownProps.tab.nodeId));
    const { nodeId } = ownProps.tab;
    const { createdBy, updatedBy, updatedAt } = content;
    const creator = createdBy ? PrincipalsSelectors.getUser(createdBy)(state) : null;
    const updator = updatedBy ? PrincipalsSelectors.getUser(updatedBy)(state) : null;

    return {
        id: 'WikiModel',
        value: getEditorData(content),
        isNewEditor: isNewEditorData(content),
        zoomLevel,
        baseUrl,
        isShowCommentsPanel,
        modelId: nodeId,
        title: content.name,
        createdBy: creator ? getShortDisplayName(creator) : '',
        updatedBy: updator ? getShortDisplayName(updator) : '',
        updatedAt: updatedAt ? timestampToStringDate(updatedAt, 'MMM DD, YYYY') : '',
    };
};

const mapDispatchToProps = (dispatch: TDispatch, ownProps: TTabComponentProps) => {
    const { nodeId } = ownProps.tab;
    const wikiNode = ownProps.tab.content as IWikiNode;
    const isNewEditor = isNewEditorData(wikiNode);

    return {
        onRendered: () => {
            dispatch(loadComments(nodeId));
        },
        onChange: (value: string | WikiContent) => {
            const source = isNewEditor ? wikiNode.source : (value as string);
            const content = !isNewEditor ? wikiNode[WIKI_CONTENT_PROP_NAME] : (value as WikiContent);

            dispatch(wikiChangeRequest(nodeId, source, content));
        },
        handlers: {
            imageLinkMapper: (src: string, baseUrl: string) =>
                src?.startsWith('data:image/') ? src : `${baseUrl}/${src}/`,
            readFromClipboard: (callback) => {
                navigator.clipboard.readText().then(callback);
            },
            copyToClipboard: (text: string) => {
                navigator.clipboard.writeText(text);
            },
            openComment: (commentId: string, threadId: string, onSubmit: (threadId: string) => void) =>
                dispatch(
                    openDialog(DialogType.WRITE_COMMENT_DIALOG, {
                        modelId: nodeId,
                        parentId: threadId,
                        threadId,
                        commentId,
                        onSubmit,
                    }),
                ),
            openInternalLink: (objectId: string) => {
                dispatch(openNodeById({ nodeId: { ...nodeId, id: objectId } }));
            },
            uploadImage: (file: File, fileId: string) => {
                const fileNodeId = { ...nodeId, id: fileId };

                dispatch(wikiUploadImage({ file, fileId: fileNodeId, modelId: nodeId }));

                return {
                    id: fileNodeId,
                    src: getWikiImageSrc(fileNodeId, nodeId),
                };
            },
            dropNode: (position: number, draggedNodeId: NodeId, baseUrl: string) => {
                const src = `${baseUrl}/${draggedNodeId.repositoryId}/${draggedNodeId.id}/`;

                dispatch(wikiDropDraggedNode({ src, position, modelId: nodeId }));
            },
        },
    };
};

const mergeProps = (ownProps, mapProps, dispatchProps): TWikiEditorProps => {
    const { handlers } = mapProps;
    const { baseUrl } = ownProps;

    const newMapProps = {
        ...mapProps,
        handlers: {
            ...handlers,
            imageLinkMapper: (src: string) => {
                return handlers.imageLinkMapper(src, baseUrl);
            },
            dropNode: (position: number, nodeId: NodeId) => {
                return handlers.dropNode(position, nodeId, baseUrl);
            },
        },
    };

    return { ...ownProps, ...newMapProps, ...dispatchProps };
};

export const WikiEditorContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(WikiEditor);
