import { Tooltip, Dropdown, Menu } from 'antd';
import type { MenuProps } from 'antd';
import React, { Component, FC } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import icDelete from '../../../resources/icons/Deleted.svg';
import icEdit from '../../../resources/icons/ic-edit.svg';
import edit from '../../../resources/icons/edit.svg';
import copy from '../../../resources/icons/ic-copy.svg';
import icSettings from '../../../resources/icons/ic-settings.svg';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from './RowButtons.messages';
import buttonTheme from './RowButtons.scss';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { noop } from 'lodash-es';
import { TSpriteSymbol } from '../../../models/spriteSymbol.types';
import { showDeleteConfirmation } from './DeleteButton';
import { getMenuItem } from '../../../utils/antdMenuItem.utils';

type TIconPropsWithEvent = {
    spriteSymbol: TSpriteSymbol;
    onClick?: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
} & JSX.IntrinsicAttributes;

type TDropdownMenu = {
    items: Array<{
        key: string;
        message: MessageDescriptor;
        onClick: () => void;
    }>;
};

type TRowButtonProps = {
    buttons?: Array<
        TIconPropsWithEvent & {
            tooltip: MessageDescriptor;
            loading?: boolean;
            menu?: TDropdownMenu;
            dataTestID?: string;
        }
    >;
    loading?: boolean;
    className?: string;
    disabled?: boolean;
};

enum ButtonKey {
    DELETE = 'DELETE',
    EDIT = 'EDIT',
    COPY = 'COPY',
    SETTINGS = 'SETTINGS',
}

export const RowButtons: FC<TRowButtonProps> = (props) => {
    const intl = useIntl();

    const { loading, className, buttons } = props;

    return (
        <div className={cx(buttonTheme.alignRight, className)}>
            {buttons?.map((button) => {
                const items: MenuProps['items'] =
                    button?.menu?.items.map((item) =>
                        getMenuItem(
                            <Menu.Item
                                className={buttonTheme.overlayItem}
                                key={item.key + uuid()}
                                onClick={item.onClick}
                            >
                                {intl.formatMessage(item.message)}
                            </Menu.Item>,
                            item.key + uuid(),
                        ),
                    ) || [];
                const disabled = props.disabled || loading;

                /* 
                  Dropdown и Tooltip (и прочие компоеннты) из atnd имеют большую вложенность 
                  и очень тяжелые для отрисовки в таблицах
                  что плохо влияет на их производительность и скорость отрисовки
                */

                return items.length ? (
                    <Tooltip mouseLeaveDelay={0} title={intl.formatMessage(button.tooltip)} key={button.key + uuid()}>
                        <Dropdown overlayClassName={buttonTheme.overlay} destroyPopupOnHide menu={{ items }}>
                            <span data-test={`row-button_${button.dataTestID || ''}`}>
                                <Icon
                                    className={cx(buttonTheme.button, loading && buttonTheme.displayWaitScreen)}
                                    disabled={disabled}
                                    onClick={disabled ? noop : button.onClick}
                                    {...button}
                                />
                            </span>
                        </Dropdown>
                    </Tooltip>
                ) : (
                    <Tooltip mouseLeaveDelay={0} title={intl.formatMessage(button.tooltip)} key={button.key + uuid()}>
                        <span data-test={`row-button_${button.dataTestID || ''}`}>
                            <Icon
                                className={cx(buttonTheme.button, loading && buttonTheme.displayWaitScreen)}
                                disabled={disabled}
                                onClick={disabled ? noop : button.onClick}
                                {...button}
                            />
                        </span>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export class EditButton {
    static build(
        onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void,
        disabled?: boolean,
        key?: string,
        menu?: TDropdownMenu,
        // временно, чтобы оставить старый дизайн в части приложения
        oldSymbol?: boolean,
    ) {
        return {
            key: key || ButtonKey.EDIT,
            onClick: disabled ? noop : onClick,
            disabled,
            spriteSymbol: oldSymbol ? icEdit : edit,
            tooltip: messages.edit,
            menu,
            dataTestID: 'EDIT',
        };
    }
}

export class CopyButton {
    static build(
        onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void,
        disabled?: boolean,
        key?: string,
        menu?: TDropdownMenu,
    ) {
        return {
            key: key || ButtonKey.COPY,
            onClick: disabled ? noop : onClick,
            disabled,
            spriteSymbol: copy,
            tooltip: messages.copy,
            menu,
            dataTestID: 'COPY',
        };
    }
}

export class DeleteButton extends Component<TRowButtonProps> {
    static build(
        onConfirm: () => void,
        disabled?: boolean,
        key?: string,
        menu?: TDropdownMenu,
        title?: string,
        dialogContent?: React.ReactNode,
    ) {
        return {
            key: key || ButtonKey.DELETE,
            onClick: disabled
                ? noop
                : (e) => {
                      e.stopPropagation();
                      showDeleteConfirmation({ dialogContent, onDelete: onConfirm, deleteQuestion: title });
                  },
            disabled,
            spriteSymbol: icDelete,
            tooltip: messages.delete,
            menu,
            dataTestID: 'DELETE',
        };
    }
}

export class SettingsButton extends Component<TRowButtonProps> {
    static build(onClick: () => void, disabled?: boolean, key?: string, menu?: TDropdownMenu) {
        return {
            key: key || ButtonKey.SETTINGS,
            onClick: disabled ? noop : onClick,
            disabled,
            spriteSymbol: icSettings,
            tooltip: messages.settings,
            menu,
            dataTestID: 'SETTINGS',
        };
    }
}

export class DeleteImmediately {
    static build(
        onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void,
        disabled?: boolean,
        key?: string,
        menu?: TDropdownMenu,
    ) {
        return {
            key: key || ButtonKey.EDIT,
            onClick: disabled ? noop : onClick,
            disabled,
            spriteSymbol: icDelete,
            tooltip: messages.delete,
            menu,
            dataTestID: 'DeleteImmediately',
        };
    }
}
