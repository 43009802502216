export const PRESET_SETTINGS_GET_ALL_KANBAN_MODEL_TYPES_SUCCESS = 'PRESET_SETTINGS_GET_ALL_KANBAN_MODEL_TYPES_SUCCESS';

export const PRESET_SETTINGS_SUBMIT_KANBAN_MODEL_TYPE = 'PRESET_SETTINGS_SUBMIT_KANBAN_MODEL_TYPE';

export const PRESET_SETTINGS_ADD_KANBAN_MODEL_TYPE = 'PRESET_SETTINGS_ADD_KANBAN_MODEL_TYPE';

export const PRESET_SETTINGS_CREATE_KANBAN_MODEL_TYPE = 'PRESET_SETTINGS_CREATE_KANBAN_MODEL_TYPE';

export const PRESET_SETTINGS_DELETE_KANBAN_MODEL_TYPE = 'PRESET_SETTINGS_DELETE_KANBAN_MODEL_TYPE';

export const PRESET_SETTINGS_TRANSFER_KANBAN_MODEL_TYPE = 'PRESET_SETTINGS_TRANSFER_KANBAN_MODEL_TYPE';

export const PRESET_SETTINGS_EDIT_KANBAN_MODEL_TYPE = 'PRESET_SETTINGS_EDIT_KANBAN_MODEL_TYPE';

export const PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_KANBAN_MODEL_TYPE =
    'PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_KANBAN_MODEL_TYPE';
