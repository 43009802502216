import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'PickOutSelectDialog.title',
        defaultMessage: 'Выделить',
    },
    pickOutAll: {
        id: 'PickOutSelectDialog.all',
        defaultMessage: 'Все',
    },
    reversePickOut: {
        id: 'PickOutSelectDialog.reverse',
        defaultMessage: 'Обратное выделение',
    },
    pickOutByObjectType: {
        id: 'PickOutSelectDialog.byObjectType',
        defaultMessage: 'Все этого типа объекта',
    },
    pickOutBySymbol: {
        id: 'PickOutSelectDialog.bySymbol',
        defaultMessage: 'Все этого типа символа',
    },
});
