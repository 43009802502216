import type { MxCell } from 'MxGraph';
import { ComplexSymbol } from '../ComplexSymbol.class';
import ObjectInstanceSerializer from '../serializers/ObjectInstanceSerializer.class';
import { ObjectInstance } from '@/serverapi/api';
import { getIsTitleHidden } from './sideEffects';

export default abstract class PoolSymbol extends ComplexSymbol {
    protected rootCellValue: ObjectInstance;
    serializer = new ObjectInstanceSerializer();

    public addToGraph() {
        const { createSwimlanes, isNewObjectDefinition } = this.customProps || {};

        if (createSwimlanes && isNewObjectDefinition) {
            const root = this.insert();
            this.addSwimlanes(root);

            return this.afterCreate(root);
        }

        const root = this.restore();

        return this.afterCreate(root);
    }

    protected isTitleHidden(): boolean {
        return getIsTitleHidden(this.rootCellValue?.symbolId, this.graph.modelType?.symbols);
    }

    abstract insert(): MxCell;
    abstract restore(): MxCell;
    abstract addSwimlanes(parent: MxCell): void;

    public get isConnectable(): boolean {
        return true;
    }
}
