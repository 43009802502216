import { Form, Table } from 'antd';
import React, { FC, useState } from 'react';
import { NodeDefinitionInstanceInfo, NodeId, NodeTypeEnum } from '../../../serverapi/api';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';
import theme from './ObjectPropertiesDialog.scss';
import messages from '../messages/ObjectPropertiesDialog.messages';
import modelTypeMessages from '../../../models/modelType.messages';
import { useIntl } from 'react-intl';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { TreeItemType } from '../../Tree/models/tree';
import { objectDecompositionOpen } from '../../../actions/entities/objectDecomposition.actions';
import { useDispatch } from 'react-redux';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TParentObjectsProps = {
    parentObjectsInfo: NodeDefinitionInstanceInfo[];
    toNodeId: (id: string) => NodeId;
};

type TTableRow = {
    key: string;
    modelNodeId?: NodeId;
    modelName: string;
    objectName: string;
    objectNodeId: NodeId;
    elementType?: string; // тип обьекта/связи в методологии
    elementNodeType?: NodeTypeEnum;
};

const ParentObjects: FC<TParentObjectsProps> = (props) => {
    const { parentObjectsInfo, toNodeId } = props;
    const [selectedRowId, setSelectedRowId] = useState<number | undefined>(undefined);
    const dispatch = useDispatch();
    const intl = useIntl();

    const tableData: TTableRow[] = parentObjectsInfo.reduce(
        (tableArray: TTableRow[], parentObject: NodeDefinitionInstanceInfo) => {
            const alwaysData = {
                objectNodeId: toNodeId(parentObject.nodeId),
                objectName: parentObject.nodeName || '',
                elementType: parentObject.elementType,
                elementNodeType: parentObject.nodeType,
            };
            if (parentObject.nodeAllocations?.length) {
                parentObject.nodeAllocations.forEach((model) =>
                    tableArray.push({
                        key: uuid(),
                        modelName: model.modelName || '',
                        modelNodeId: toNodeId(model.modelId!),
                        ...alwaysData,
                    }),
                );
            } else {
                tableArray.push({
                    key: uuid(),
                    modelName: intl.formatMessage(messages.noInstance),
                    ...alwaysData,
                });
            }

            return tableArray;
        },
        [],
    );

    const rowSelect = (rowId: number) => () => {
        const setArgument = selectedRowId === rowId ? undefined : rowId;

        setSelectedRowId(setArgument);
    };

    const parentObjectsColumns = [
        {
            title: intl.formatMessage(messages.elementType),
            dataIndex: 'elementNodeType',
            render: (elementNodeType?: NodeTypeEnum) => {
                if (!elementNodeType || !modelTypeMessages[elementNodeType]) return '';
                
                return intl.formatMessage(modelTypeMessages[elementNodeType]) || '';
            },
            width: '20%',
        },
        {
            title: intl.formatMessage(messages.elementName),
            dataIndex: 'objectName',
            render: (objectName: string, row: TTableRow) =>
                row.elementType ? `${objectName} (${row.elementType})` : objectName,
            width: '40%',
        },
        {
            title: intl.formatMessage(messages.modelName),
            dataIndex: 'modelName',
            width: '40%',
        },
    ];

    const openParent = () => {
        if (selectedRowId !== undefined) {
            const id: string | undefined = tableData[selectedRowId]?.modelNodeId?.id;

            if (id) {
                dispatch(
                    objectDecompositionOpen({
                        nodeId: toNodeId(id),
                        type: TreeItemType.Model,
                    }),
                );
            }
        }
    };

    return (
        <FormGroup className={theme.formGroup}>
            <Form.Item>
                <Table
                    dataSource={tableData}
                    className={theme.table}
                    columns={parentObjectsColumns}
                    size="middle"
                    bordered
                    pagination={false}
                    onRow={(row: TTableRow) => ({
                        onClick: rowSelect(tableData.indexOf(row)),
                    })}
                    rowClassName={(row: TTableRow) =>
                        cx(theme.attribute, selectedRowId === tableData.indexOf(row) && theme.attribute_selected)
                    }
                    scroll={{
                        y: 'max-content',
                        x: 'max-content',
                    }}
                />
                <div>
                    <div className={theme.detailingActionsInner}>
                        <Button
                            size="large"
                            disabled={selectedRowId === undefined}
                            onClick={openParent}
                            dataTest="open_parent-model_btn"
                        >
                            {intl.formatMessage(messages.open)}
                        </Button>
                    </div>
                </div>
            </Form.Item>
        </FormGroup>
    );
};

export { ParentObjects };
