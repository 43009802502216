import type { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import type { NodeId, WikiImage, WikiImageRequest } from '@/serverapi/api';
import { apiBundle } from '../api/api-bundle';
import { setServerIdToNodeOriginal } from '@/utils/nodeId.utils';

export class WikiDaoService {
    public static async saveWiki(serverId: string, body: IWikiNode): Promise<IWikiNode> {
        const wiki = (await apiBundle(serverId).wiki.save({ body })) as IWikiNode;
        setServerIdToNodeOriginal(wiki, serverId);
        return wiki;
    }

    public static async getWikiById(serverId: string, repositoryId: string, wikiId: string): Promise<IWikiNode> {
        const wiki = (await apiBundle(serverId).wiki.get({ wikiId, repositoryId })) as IWikiNode;
        setServerIdToNodeOriginal(wiki, serverId);
        return wiki;
    }

    public static async uploadWikiImage(wikiNode: NodeId, file: WikiImageRequest): Promise<WikiImage> {
        return apiBundle(wikiNode.serverId).wiki.uploadWikiImage({
            repositoryId: wikiNode.repositoryId,
            wikiId: wikiNode.id,
            body: file,
        });
    }
}
