import { defineMessages } from 'react-intl';

export default defineMessages({
    generalSettings: {
        id: 'MatrixModelType.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    name: {
        id: 'MatrixModelType.name',
        defaultMessage: 'Название',
    },
    description: {
        id: 'MatrixModelType.description',
        defaultMessage: 'Описание',
    },
    modelTypeGroups: {
        id: 'MatrixModelType.modelTypeGroups',
        defaultMessage: 'Группа типа модели',
    },
    attributes: {
        id: 'MatrixModelType.attributes',
        defaultMessage: 'Атрибуты матрицы',
    },
    typeModel: {
        id: 'MatrixModelType.typeModel',
        defaultMessage: 'Тип модели',
    },
    enableModel: {
        id: 'MatrixModelType.enableModel',
        defaultMessage: 'Видимость типа модели при создании',
    },
    allowApprovals: {
        id: 'MatrixModelType.allowApprovals',
        defaultMessage: 'Разрешить согласование типа модели',
    },
});
