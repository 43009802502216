import type { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { orderBy } from 'lodash-es';
import { getAvailableAttributeTypes } from '../FloatingAttributes.utils';
import { StyledAttributeType } from '@/models/bpm/bpm-model-impl';

export const floatingAttributesPanelStateSelector = (state: TRootState) => state.floatingAttributesPanelData;

export namespace FloatingAttributesPanelSelectors {
    export const allData = createSelector(floatingAttributesPanelStateSelector, (state) => state);

    export const getAttributeTypes = createSelector(floatingAttributesPanelStateSelector, getAvailableAttributeTypes);

    export const getAttributeTypesForSelect = createSelector(
        floatingAttributesPanelStateSelector,
        getAttributeTypes,
        (state, availableAttributeTypes) => [
            ...(state.useUniqueStyledAttributeTypes && state.selectedStyle
                ? [state.stylesAttribute[state.selectedStyle]]
                : []),
            ...availableAttributeTypes,
        ],
    );

    export const getSelectedAreaState = (overlayPositionKey: string, suffix: string) =>
        createSelector(floatingAttributesPanelStateSelector, (state) => {
            const { areas } = state;
            const { attributes, filled, disabled } = areas[overlayPositionKey];

            return {
                title: orderBy(
                    attributes.map((item: StyledAttributeType) =>
                        item.readOnly ? `${item.name} ${suffix}` : item.name,
                    ),
                    undefined,
                    'desc',
                ).join(', '),
                filled,
                disabled,
            };
        });

    export const getSelectedAreaKey = createSelector(
        floatingAttributesPanelStateSelector,
        (state) => state.selectedAreaKey,
    );

    export const getSelectedAreaStyles = createSelector(floatingAttributesPanelStateSelector, (state) => {
        const { selectedAreaStyles, stylesAttribute } = state;

        return orderBy(
            selectedAreaStyles.map((el) => el && stylesAttribute[el] && { id: el, name: stylesAttribute[el].name }),
            'name',
            'desc',
        );
    });

    export const getSelectedStyle = createSelector(
        floatingAttributesPanelStateSelector,
        (state) => state.styles[state.selectedStyle || ''] || null,
    );

    export const getSelectedStyleAttribute = createSelector(
        floatingAttributesPanelStateSelector,
        (state) => state.stylesAttribute[state.selectedStyle || ''] || null,
    );

    export const getPresetId = createSelector(floatingAttributesPanelStateSelector, (state) => state.presetId || '');
}
