export function shouldCallModalSubmitHandler(key: string): Boolean {
    if (key !== 'Enter') return false;

    const activeElement = document.activeElement;
    if (activeElement) {
        const isConflictingElement =
            (activeElement.tagName === 'INPUT' &&
                (activeElement.className.includes('ant-select-selection-search-input') ||
                    activeElement.parentElement?.className.includes('ant-picker-input'))) ||
            activeElement.tagName === 'TEXTAREA';

        if (isConflictingElement) return false;
    }

    return true;
}
