import { defineMessages } from 'react-intl';

export default defineMessages({
    onlyActive: {
        id: 'SessionList.checkbox.label',
        defaultMessage: 'Только активные сессии',
    },
    stop: {
        id: 'SessionList.stopSessionButton.label',
        defaultMessage: 'Завершить',
    },
    load: {
        id: 'SessionList.loadButton.text',
        defaultMessage: 'Обновить',
    },
    today: {
        id: 'SessionList.datePicker.todayButton.text',
        defaultMessage: 'Сегодня',
    },
    currentMonth: {
        id: 'SessionList.datePicker.thisMonthButton.text',
        defaultMessage: 'Текущий месяц',
    },
    startDate: {
        id: 'SessionList.datePicker.startDate',
        defaultMessage: 'Начальная дата',
    },
    endDate: {
        id: 'SessionList.datePicker.endDate',
        defaultMessage: 'Конечная дата',
    },
    N: {
        id: 'SessionList.table.header.firstColumn.text',
        defaultMessage: '№',
    },
    timeStart: {
        id: 'SessionList.table.header.secondColumn.text',
        defaultMessage: 'Время старта',
    },
    timeEnd: {
        id: 'SessionList.table.header.thirdColumn.text',
        defaultMessage: 'Время завершения',
    },
    lastActionAt: {
        id: 'SessionList.lastActionAt',
        defaultMessage: 'Последняя активность',
    },
    login: {
        id: 'SessionList.table.header.fourthColumn.text',
        defaultMessage: 'Логин',
    },
    userName: {
        id: 'SessionList.table.header.fifthColumn.text',
        defaultMessage: 'ФИО',
    },
    sessionType: {
        id: 'SessionList.table.header.sessionType.text',
        defaultMessage: 'Скрипт',
    },
    sessionTypeIsScript: {
        id: 'SessionList.table.header.sessionTypeIsScript.text',
        defaultMessage: 'Да',
    },
});
