import React from 'react';
import { Icon } from '../Icon/Icon.component';
import { useIntl } from 'react-intl';
import newTheme from './Select.scss';
import likeAnAntdTheme from './SelectLikeAnAntd.scss';
import Close from '../../../../resources/icons/UIKit/Close.svg';
import messages from './Select.messages';

type TClearButtonProps = {
    allowClear?: boolean;
    // todo: после перехода на новые стили этот пропс будет не нужен
    originalTheme?: boolean;
    onChange?: (value?: string | string[]) => void;
};

export const ClearButton = (props: TClearButtonProps) => {
    const { allowClear, originalTheme, onChange } = props;
    const theme = originalTheme ? newTheme : likeAnAntdTheme;
    const intl = useIntl();

    const handleClearValue = () => onChange && onChange(undefined);

    return (
        <>
            {allowClear && (
                <div role="button" tabIndex={0} className={theme.clearButton} onClick={handleClearValue}>
                    <Icon spriteSymbol={Close} />
                    <div className={theme.clearButtonText}>{intl.formatMessage(messages.clear)}</div>
                </div>
            )}
        </>
    );
};
