import { MailMessageDTO, NotificationDTO } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import notificationMessages from '../../../../dialogs/NotificationSettingsDialog/NotificationSettingsDialog.messages';
import { TTableRowData } from './MessagesList.types';
import { dateTimeNoSecondsFormat, dateToStringFormat } from '@/utils/date.time.utils';

const intl = LocalesService.useIntl();

export const getFormattedRows = (mailMessages: MailMessageDTO[], notifications: NotificationDTO[]): TTableRowData[] => {
    return mailMessages.map((message) => {
        const notification = notifications.find((notification) => notification.id === message.notificationId);
        const createdAt: Date = new Date(message?.createdAt || new Date());

        return {
            id: message.id || '',
            emailsList: message.emailsList?.join(', ') || '',
            createdAt: dateToStringFormat(createdAt, dateTimeNoSecondsFormat),
            status: message.status || '',
            notificationType: notification?.type ? intl.formatMessage(notificationMessages[notification?.type]) : '',
            notificationRecipientType: notification?.recipientsType
                ? intl.formatMessage(notificationMessages[notification?.recipientsType])
                : '',
            templateId: notification?.templateId || '',
        };
    });
};
