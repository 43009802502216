import { AttributeTypeGroup } from '../serverapi/api';
import { TReducer } from '../utils/types';
import {
    ADD_ATTRIBUTE_TYPE_GROUPS,
    DELETE_ATTRIBUTE_TYPE_GROUP_SUCCESS,
    SUBMIT_ATTRIBUTE_TYPE_GROUP,
} from '../actionsTypes/attributeTypeGroup.actionTypes';
import { TAttributeTypeGroupState } from './attributeTypeGroup.reducer.types';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';

export const INITIAL_ATTRIBUTE_TYPE_SERVER_STATE = {
    byPresetId: {},
};

const initial: TAttributeTypeGroupState = {
    byServerId: {},
};

const initStateWithServerAndPreset = (state: TAttributeTypeGroupState, serverId: string, presetId: string) => {
    if (!state.byServerId[serverId]) {
        state.byServerId[serverId] = {
            byPresetId: {},
        };
    }
    if (!state.byServerId[serverId].byPresetId[presetId]) {
        state.byServerId[serverId].byPresetId[presetId] = {
            byId: {},
        };
    }
};

export const attributeTypeGroupReducer: TReducer<TAttributeTypeGroupState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_ATTRIBUTE_TYPE_GROUPS:
        case SUBMIT_ATTRIBUTE_TYPE_GROUP: {
            const {
                payload: { serverNode, preset, attributeTypeGroups },
            } = action;
            const stateClone: TAttributeTypeGroupState = JSON.parse(JSON.stringify(state));
            const presetId = preset.id;
            const { serverId } = serverNode.nodeId;
            if (serverId && presetId) {
                initStateWithServerAndPreset(stateClone, serverId, presetId);
                const preset = stateClone.byServerId[serverId].byPresetId[presetId];
                preset.byId = {
                    ...preset.byId,
                    ...attributeTypeGroups.reduce((acc, attributeTypeGroup: AttributeTypeGroup) => {
                        acc[attributeTypeGroup.id] = attributeTypeGroup;

                        return acc;
                    }, {}),
                };
            }

            return stateClone;
        }
        case DELETE_ATTRIBUTE_TYPE_GROUP_SUCCESS: {
            const { attributeTypeGroups, serverNode } = action.payload;
            const stateClone = JSON.parse(JSON.stringify(state));
            const { serverId } = serverNode.nodeId;
            attributeTypeGroups.forEach((attributeTypeGroup) => {
                const byId =
                    (attributeTypeGroup.presetId &&
                        stateClone.byServerId[serverId] &&
                        stateClone.byServerId[serverId].byPresetId[attributeTypeGroup.presetId] &&
                        stateClone.byServerId[serverId].byPresetId[attributeTypeGroup.presetId].byId) ||
                    {};
                delete byId[attributeTypeGroup.id];
            });

            return stateClone;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }
        default:
            return state;
    }
};
