import React from 'react';
import { useIntl } from 'react-intl';
import theme from './ApprovalDialog.scss';
import messages from './ApprovalDialog.messages';
import { DatePicker, Form, Select } from 'antd';
import { dateFormat, timeFormat, timestampToMomentDate } from '@/utils/date.time.utils';
import icArrowDownDark from '../../resources/icons/icArrowDownDark.svg';
import icCalendar from '../../resources/icons/icCalendar.svg';
import { Icon } from '../UIKit';
import { ApprovalStageDTOAbstentionVoteStrategy } from './ApprovalDialog.types';
import { DatePickerProvider } from '@/modules/UIKit/H.O.C/DatePickerProvider/DatePickerProvider.hoc';

export const TimeConstraintComponent = ({
    selectedStage,
    isTemplate,
    isEditingDisabled,
    onFinishDateChange,
    onVoteStrategyChange,
}) => {
    const intl = useIntl();

    return (
        <div className={theme.timeConstraintContainer}>
            <Form.Item
                label={intl.formatMessage(messages.finishDateTime)}
                initialValue={
                    selectedStage.scheduledFinishDate
                        ? timestampToMomentDate(selectedStage.scheduledFinishDate)
                        : undefined
                }
                name="scheduledFinishDate"
                required
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages.thisFieldIsRequired),
                    },
                ]}
            >
                <DatePickerProvider>
                    <DatePicker
                        suffixIcon={<Icon spriteSymbol={icCalendar} />}
                        disabled={!isTemplate && isEditingDisabled}
                        placeholder={intl.formatMessage(messages.selectDateTime)}
                        className={theme.w100}
                        showTime
                        format={`${dateFormat} ${timeFormat}`}
                        defaultValue={
                            selectedStage.scheduledFinishDate
                                ? timestampToMomentDate(selectedStage.scheduledFinishDate)
                                : undefined
                        }
                        onChange={onFinishDateChange}
                    />
                </DatePickerProvider>
            </Form.Item>

            <Form.Item
                label={intl.formatMessage(messages.abstentionsCountedAs)}
                initialValue={selectedStage.abstentionVoteStrategy || ApprovalStageDTOAbstentionVoteStrategy.APPROVED}
                name="AbstentionVoteStrategy"
            >
                <Select
                    suffixIcon={<Icon spriteSymbol={icArrowDownDark} />}
                    className={theme.w100}
                    defaultValue={
                        selectedStage.abstentionVoteStrategy || ApprovalStageDTOAbstentionVoteStrategy.APPROVED
                    }
                    onChange={onVoteStrategyChange}
                    disabled={!isTemplate && isEditingDisabled}
                >
                    <Select.Option className='data-test_approved-option_btn' value={ApprovalStageDTOAbstentionVoteStrategy.APPROVED}>
                        {intl.formatMessage(messages.approved)}
                    </Select.Option>
                    <Select.Option className='data-test_not-approved-option_btn' value={ApprovalStageDTOAbstentionVoteStrategy.NOT_APPROVED}>
                        {intl.formatMessage(messages.notApproved)}
                    </Select.Option>
                </Select>
            </Form.Item>
        </div>
    );
};
