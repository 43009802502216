import { defineMessages } from 'react-intl';

export default defineMessages({
    iconDescription: {
        id: 'EdgeStyleSettings.iconDescription',
        defaultMessage: 'Расшифровка',
    },
    iconSymbol: {
        id: 'EdgeStyleSettings.iconSymbol',
        defaultMessage: 'Символ',
    },
    symbolsVariants: {
        id: 'EdgeStyleSettings.symbolsVariants',
        defaultMessage: 'Варианты символов:',
    },
    CUSTOM: {
        id: 'EdgeStyleSettings.CUSTOM',
        defaultMessage: 'Произвольный',
    },
    ICON: {
        id: 'EdgeStyleSettings.ICON',
        defaultMessage: 'Иконка',
    },
    USER_ICON: {
        id: 'EdgeStyleSettings.USER_ICON',
        defaultMessage: 'Пользовательская иконка',
    },
    type: {
        id: 'EdgeStyleSettings.type',
        defaultMessage: 'Тип:',
    },
    symbol: {
        id: 'EdgeStyleSettings.symbol',
        defaultMessage: 'Символ:',
    },
    color: {
        id: 'EdgeStyleSettings.color',
        defaultMessage: 'Цвет:',
    },
    filling: {
        id: 'EdgeStyleSettings.filling',
        defaultMessage: 'Заливка:',
    },
    addSymbol: {
        id: 'EdgeStyleSettings.addSymbol',
        defaultMessage: 'Добавить символ',
    },
    saveSymbol: {
        id: 'EdgeStyleSettings.saveSymbol',
        defaultMessage: 'Сохранить символ',
    },
    PLUS: {
        id: 'EdgeStyleSettings.PLUS',
        defaultMessage: 'Плюс',
    },
    MINUS: {
        id: 'EdgeStyleSettings.MINUS',
        defaultMessage: 'Минус',
    },
    CHECK: {
        id: 'EdgeStyleSettings.CHECK',
        defaultMessage: 'Галочка',
    },
    CROSS: {
        id: 'EdgeStyleSettings.CROSS',
        defaultMessage: 'Крестик',
    },
    fileDropText: {
        id: 'EdgeStyleSettings.fileDropText',
        defaultMessage: 'Нажмите или перетащите сюда картинку',
    },
    automaticCellFillingMode: {
        id: 'EdgeStyleSettings.automaticCellFillingMode',
        defaultMessage: 'Автоматический режим заполнения ячеек',
    },
});
