import { Form, Input } from 'antd';
import React, { ChangeEvent } from 'react';
import theme from './FormItemInputWithLabel.scss';

type TFormItemInputWithLabelProps = {
    label: string;
    placeholder?: string;
    initialValue: number | string | undefined | Date;
    name: string | number | (string | number)[];
    disabled?: boolean;
    type?: string;
    help?: string;
    getValueFromEvent?: (e: ChangeEvent<HTMLInputElement>) => void;
    autoComplete?: string;
    rules?: any;
};

export const FormItemInputWithLabel = (props: TFormItemInputWithLabelProps) => {
    const { label, placeholder, initialValue, name, disabled, getValueFromEvent, type, help, autoComplete, rules } = props;

    return (
        <Form.Item label={label} initialValue={initialValue} name={name} className={theme.formItem} getValueFromEvent={getValueFromEvent} help={help} rules={rules}>
            <Input disabled={disabled} placeholder={placeholder} type={type} autoComplete={autoComplete}/>
        </Form.Item>
    );
};
