import { defineMessages } from 'react-intl';

export default defineMessages({
    deleteConfirmTitle: {
        id: 'DbSearch.deleteConfirmTitle',
        defaultMessage: 'Удаление условий поиска',
    },
    deleteConfirmQuestion: {
        id: 'DbSearch.deleteConfirmQuestion',
        defaultMessage: 'Вы уверены, что хотите удалить все условия поиска?',
    },
    delete: {
        id: 'DbSearch.delete',
        defaultMessage: 'Удалить',
    },
    filters: {
        id: 'DbSearch.filters',
        defaultMessage: 'Фильтров: {number}',
    },
    elementsFound: {
        id: 'DbSearch.elementsFound',
        defaultMessage: 'Найдено элементов:',
    },
});
