import { PROCESS_ERROR } from '../actionsTypes/error.actionTypes';
import { TProcessErrorAction } from './error.actions.types';

export const processError = (error: any, serverId: string): TProcessErrorAction => ({
    // tslint:disable-line:no-any
    type: PROCESS_ERROR,
    payload: {
        serverId,
        error,
    },
});
