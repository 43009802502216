import { TRootState } from '../../../reducers/root.reducer.types';
import { connect } from 'react-redux';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { ChoosePrincipalDialog } from '../components/ChoosePrincipalDialog/ChoosePrincipalDialog.component';
import { TChoosePrincipalDialogProps } from '../components/ChoosePrincipalDialog/ChoosePrincipalDialog.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { choosePrincipalDialogSubmitResult } from '../../../actions/principal.actions';
import { PrincipalDescriptor } from '../../../serverapi/api';

const mapStateToProps = (
    state: TRootState,
    props: TChoosePrincipalDialogProps,
): Partial<TChoosePrincipalDialogProps> => {
    const { loading, error, data } = state.principals;

    return {
        ...props,
        loading,
        error,
        data,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeDialog(DialogType.CHOOSE_PRINCIPAL_DIALOG)),
    onSubmit: (selectedRows: PrincipalDescriptor[]) => dispatch(choosePrincipalDialogSubmitResult(selectedRows)),
});

export const ChoosePrincipalDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ChoosePrincipalDialog);
