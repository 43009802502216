import { ObjectType } from '../serverapi/api';
import {
    CREATE_OBJECT_TYPE,
    DELETE_OBJECT_TYPE,
    DELETE_OBJECT_TYPE_AND_GROUP,
    EDIT_OBJECT_TYPE,
    OBJECT_TYPE_DATA,
    OBJECT_TYPE_DELETE_REQUEST_SUCCESS,
    OBJECT_TYPE_REQUEST_FAILURE,
    OBJECT_TYPE_REQUEST_SUCCESS,
    SUBMIT_OBJECT_TYPE,
} from '../actionsTypes/objectType.actionTypes';
import {
    TCreateObjectTypeAction,
    TCreateObjectTypePayload,
    TDeleteObjectTypeAction,
    TDeleteObjectTypeAndGroupPayload,
    TDeleteObjectTypePayload,
    TEditObjectTypeAction,
    TEditObjectTypePayload,
    TObjectTypeDeleteRequestSuccessAction,
    TObjectTypeRequestFailureAction,
    TObjectTypeRequestSuccessAction,
    TObjectTypeRequestSuccessPayload,
    TSubmitObjectTypeAction,
    TSubmitObjectTypePayload,
} from './objectType.actions.types';

export const deleteObjectType = (payload: TDeleteObjectTypePayload): TDeleteObjectTypeAction => ({
    type: DELETE_OBJECT_TYPE,
    payload,
});

export const objectTypeRequestSuccess = (
    payload: TObjectTypeRequestSuccessPayload,
): TObjectTypeRequestSuccessAction => ({
    type: OBJECT_TYPE_REQUEST_SUCCESS,
    payload,
});

export const objectTypeRequesFailure = (
): TObjectTypeRequestFailureAction => ({
    type: OBJECT_TYPE_REQUEST_FAILURE,
});

export const objectTypeDeleteRequestSuccess = (
    serverId: string,
    objectTypes: ObjectType[],
): TObjectTypeDeleteRequestSuccessAction => ({
    type: OBJECT_TYPE_DELETE_REQUEST_SUCCESS,
    payload: {
        serverId,
        objectTypes,
    },
});

export const objectTypeData = (serverId: string, data: Array<ObjectType>) => ({
    type: OBJECT_TYPE_DATA,
    payload: {
        serverId,
        data,
    },
});

export const createObjectType = (payload: TCreateObjectTypePayload): TCreateObjectTypeAction => ({
    type: CREATE_OBJECT_TYPE,
    payload,
});

export const editObjectType = (payload: TEditObjectTypePayload): TEditObjectTypeAction => ({
    type: EDIT_OBJECT_TYPE,
    payload,
});

export const submitObjectTypes = (payload: TSubmitObjectTypePayload): TSubmitObjectTypeAction => ({
    type: SUBMIT_OBJECT_TYPE,
    payload,
});

export const deleteObjectTypeAndGroup = (payload: TDeleteObjectTypeAndGroupPayload) => ({
    type: DELETE_OBJECT_TYPE_AND_GROUP,
    payload,
});
