import { createSelector } from 'reselect';
import { TQueryAttributeTypeData, TQuerySelectState } from '../reducers/querySelect.reducer.types';
import { TRootState } from '../reducers/root.reducer.types';

const getState = (state: TRootState): TQuerySelectState => state.querySelectData;

export namespace QuerySelectSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector<TRootState, TQuerySelectState, TQueryAttributeTypeData>(
            getState,
            (state) => state[presetId] || {},
        );
}
