import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import theme from './MatrixEditor.scss';
import { Table } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TMatrixTabType } from '@/models/tab.types';
import { matrixSaveRequest, updateMatrixData } from '@/modules/Matrix/actions/matrix.actions';
import {
    InternationalString,
    MatrixCell as _MatrixCell,
    MatrixData,
    MatrixLane,
    MatrixNode,
    Symbol,
} from '@/serverapi/api';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { MatrixMainHeader } from './MatrixMainHeader/MatrixMainHeader.component';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { EditorMode } from '@/models/editorMode';
import { cloneDeep } from 'lodash-es';
import {
    completHeadersArray,
    fillCellsWithSameLinkedNodeIds,
    filterToggledHeaderCells,
    findSelectedHeadersByIds,
    getCellIdByRowIdAndColId,
    getCells,
    getHeaderCellText,
    getRowIdAndColIdByCellId,
    getSelectedLanesWithChildren,
    hasDeletedHeaderNode,
    isForbiddenByProfile,
} from '../utils/Matrix.utils';
import { HeaderResizeDirections, HeaderType } from './Matrix.types';
import { useAutoSave } from '@/hooks/useAutoSave';
import { MatrixHeaderCell } from './MatrixHeaderCell.component';
import { MatrixCell } from './MatrixCell.component';
import cn from 'classnames';
import { DialogsSelectors } from '@/selectors/dialogs.selectors';
import { MIN_LANE_SIZE } from '../utils/Matrix.constants';
import { CustomMap } from '@/utils/map';
import { TSymbolStateKey } from '@/reducers/symbol.reducer.types';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { useMatrixResize } from './useMatrixResize';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { ObjectDefinitionSelectors } from '@/selectors/objectDefinition.selectors';
import { TCurrentUserProfile } from '@/reducers/userProfile.reducer.types';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { showNotification } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { v4 as uuid } from 'uuid';
import { MatrixSelectors } from '../selectors/matrix.selectors';
import { MatrixEditorSelectors } from '../selectors/matrixEditor.selectors';
import { TSelectedHeadersCells } from '../reducers/matrixEditor.reducer.types';
import { matrixClearSelectedCells, matrixCopyLanes, matrixPastObjects } from '../actions/matrixEditor.actions';
import { useRelock } from '@/hooks/useRelock';
import { useMatrixZoom } from '../utils/useMatrixZoom';

type TMatrixEditorProps = {
    tab: TMatrixTabType;
};

export const MatrixEditor: FC<TMatrixEditorProps> = ({ tab: { nodeId, mode, params, actions } }) => {
    const zoomFit: boolean = !!actions?.zoomFit;
    const zoomLevel: number = params.zoomLevel !== undefined ? params.zoomLevel / 100 : 1;

    const [isRowDrag, setIsRowDrag] = useState<boolean>(false);

    const isVisibleDialog: boolean = useSelector(DialogsSelectors.isVisibleDialog);
    const selectedHeaderCells: TSelectedHeadersCells = useSelector(
        MatrixEditorSelectors.getSelectedHeaderCells(nodeId),
    );
    const selectedCells: string[] = useSelector(MatrixEditorSelectors.getSelectedCells(nodeId));
    const objectDefinitions: { [key: string]: ObjectDefinitionImpl } = useSelector(
        ObjectDefinitionSelectors.byServerIdRepositoryId(nodeId.serverId, nodeId.repositoryId),
    );
    const currentLocale: Locale = useSelector(getCurrentLocale);
    const symbols: CustomMap<TSymbolStateKey, Symbol> = useSelector(SymbolSelectors.all);
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const matrix: MatrixNode | undefined = useSelector(MatrixSelectors.byId(nodeId));
    const userProfile: TCurrentUserProfile | undefined = useSelector(
        UserProfileSelectors.selectUserProfileByNodeId(nodeId),
    );
    const isCopyEnabled: boolean = useSelector(MatrixEditorSelectors.isCopyEnabled(nodeId));
    const isPastEnabled: boolean = useSelector(MatrixEditorSelectors.isPastEnabled(nodeId));

    const isMatrixAutofill = useSelector(MatrixSelectors.isMatrixAutofilled(nodeId));

    const {
        toggledColumnsIds,
        toggledRowsIds,
    }: {
        toggledColumnsIds: string[];
        toggledRowsIds: string[];
    } = useSelector(MatrixEditorSelectors.getToggledHeaderCellsIds(nodeId));

    const matrixData: MatrixData | undefined = cloneDeep(matrix?.content);

    const matrixContainerRef = useRef<HTMLDivElement>(null);
    const lastColRef = useRef<HTMLDivElement>(null);
    const lastRowRef = useRef<HTMLDivElement>(null);

    useMatrixZoom({ nodeId, zoomFit, zoomLevel, lastColRef, lastRowRef, matrixContainerRef });

    const colsHeaders: MatrixLane[] = matrixData?.columns || [];
    const colsHeadersForRender: MatrixLane[] = filterToggledHeaderCells(colsHeaders, toggledColumnsIds);

    const rowsHeaders: MatrixLane[] = matrixData?.rows || [];
    const rowsHeadersForRender: MatrixLane[] = filterToggledHeaderCells(rowsHeaders, toggledRowsIds);

    const cells: _MatrixCell[] = getCells(matrixData);
    const isReadMode: boolean = mode === EditorMode.Read;

    const updateMatrixDataHandler = (data: MatrixData) => {
        if (matrix) {
            completHeadersArray(data.columns);
            completHeadersArray(data.rows);

            dispatch(updateMatrixData(nodeId, data));
        }
    };

    const {
        actualHeaderSizes,
        currentColWidth,
        currentRowHeight,
        colResizeDataRef,
        rowResizeDataRef,
        setColResizeData,
        setCurrentColWidth,
        setCurrentRowHeight,
        setHeaderResizeData,
        setRowResizeData,
    } = useMatrixResize({ colsHeaders, rowsHeaders, matrixData, updateMatrixData: updateMatrixDataHandler });

    const dispatch = useDispatch();

    const saveMatrixAction = () => {
        if (matrix) {
            dispatch(matrixSaveRequest(matrix));
        }
    };

    useAutoSave({ isEditMode: !isReadMode, nodeId, dependences: [matrix], callback: saveMatrixAction });
    useRelock(!isReadMode, nodeId, 'MATRIX');

    const clearCellsSelectionAction = () => {
        dispatch(matrixClearSelectedCells(nodeId));
    };

    useEffect(() => {
        if (isReadMode && selectedCells.length !== 0 && selectedHeaderCells.ids.length !== 0) {
            clearCellsSelectionAction();

            return;
        }

        const keyDownHandler = (event: KeyboardEvent) => {
            if (isVisibleDialog) return;

            // ctrl + c
            if (event.ctrlKey && event.code === 'KeyC' && isCopyEnabled) {
                dispatch(matrixCopyLanes(nodeId));
            }
            // ctrl + v
            if (event.ctrlKey && event.code === 'KeyV' && isPastEnabled) {
                dispatch(matrixPastObjects(nodeId));
            }
            // Delete col/row
            if (event.key === 'Delete' && matrixData && selectedHeaderCells.ids.length !== 0) {
                const isRows: boolean = selectedHeaderCells.type === 'row';
                const notForbiddenToDelCells: string[] = selectedHeaderCells.ids.filter((id) => {
                    const isForbidden: boolean = isForbiddenByProfile({
                        selectedHeaders: findSelectedHeadersByIds(isRows ? rowsHeaders : colsHeaders, [id]),
                        userProfile,
                        objectDefinitions,
                        rights: ['delete', 'update'],
                    });
                    if (isForbidden) {
                        dispatch(
                            showNotification({
                                id: uuid(),
                                type: NotificationType.ACCESS_DENIED_BY_PROFILE,
                            }),
                        );
                    }

                    return !isForbidden;
                });

                const notForbiddenToDelSelectedIdsByChildren: string[] = notForbiddenToDelCells.filter((cell) => {
                    const selectedLanesWithChildren: MatrixLane[] = getSelectedLanesWithChildren(
                        [cell],
                        isRows ? rowsHeaders : colsHeaders,
                        true,
                    );
                    const isForbidden: boolean = isForbiddenByProfile({
                        selectedHeaders: selectedLanesWithChildren,
                        userProfile,
                        objectDefinitions,
                        rights: ['delete', 'update'],
                    });
                    if (isForbidden) {
                        dispatch(
                            showNotification({
                                id: uuid(),
                                type: NotificationType.ACCESS_DENIED_BY_PROFILE,
                            }),
                        );
                    }

                    return !isForbidden;
                });

                const selectedIdsWithChildren: string[] = getSelectedLanesWithChildren(
                    notForbiddenToDelSelectedIdsByChildren,
                    isRows ? rowsHeaders : colsHeaders,
                    true,
                ).map((header) => header.id);

                if (isRows) {
                    matrixData.rows = rowsHeaders.filter((row) => !selectedIdsWithChildren.includes(row.id));
                    matrixData.cells = cells.filter(
                        ({ rowId }) => !selectedIdsWithChildren.some((cellId) => cellId === rowId),
                    );
                } else {
                    matrixData.columns = colsHeaders.filter((col) => !selectedIdsWithChildren.includes(col.id));
                    matrixData.cells = cells.filter(
                        ({ columnId }) => !selectedIdsWithChildren.some((cellId) => cellId === columnId),
                    );
                }
                clearCellsSelectionAction();
                updateMatrixDataHandler(matrixData);
            }

            // Delete cell
            if (event.key === 'Delete' && matrixData && selectedCells.length !== 0 && !isMatrixAutofill) {
                selectedCells.forEach((selectedCellId) => {
                    const { colId, rowId } = getRowIdAndColIdByCellId(selectedCellId);
                    const selectedHeaders: MatrixLane[] = [
                        ...findSelectedHeadersByIds(colsHeaders, [colId]),
                        ...findSelectedHeadersByIds(rowsHeaders, [rowId]),
                    ];
                    const isForbiddenToDel = isForbiddenByProfile({
                        selectedHeaders: selectedHeaders,
                        userProfile,
                        objectDefinitions,
                        rights: ['delete', 'update'],
                    });
                    if (isForbiddenToDel) {
                        dispatch(
                            showNotification({
                                id: uuid(),
                                type: NotificationType.ACCESS_DENIED_BY_PROFILE,
                            }),
                        );

                        return;
                    }
                    const currentCell: _MatrixCell | undefined = cells.find(
                        ({ rowId: cellRowId, columnId: cellColId }) => cellRowId === rowId && cellColId === colId,
                    );
                    if (!currentCell) return;

                    currentCell.styleIds = [];
                    fillCellsWithSameLinkedNodeIds(currentCell, matrixData);
                });
                updateMatrixDataHandler(matrixData);
            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [selectedHeaderCells, isReadMode, selectedCells, mode]);

    const openRenameDialog = (onSubmit: (newName: InternationalString) => void, initName?: InternationalString) => {
        if (isReadMode) return;

        const onClose = () => dispatch(closeDialog(DialogType.MATRIX_HEADER_RENAME_DIALOG));

        dispatch(
            openDialog(DialogType.MATRIX_HEADER_RENAME_DIALOG, {
                initName,
                onClose,
                onSubmit: (newName: InternationalString) => {
                    onSubmit(newName);
                    onClose();
                },
            }),
        );
    };

    const checkIsCellSelectable = (rowIndex: number, colIndex: number, rowText: string, colText: string) => {
        return (
            !!rowText &&
            !!colText &&
            Array.isArray(rowsHeaders) &&
            rowsHeaders.findLastIndex((row) => row.text || row.linkedNodeId) >= rowIndex &&
            Array.isArray(colsHeaders) &&
            colsHeaders.findLastIndex((col) => col.text || col.linkedNodeId) >= colIndex &&
            !isReadMode
        );
    };

    const getHeaderCellIcon = (symbolId: string = '') => {
        const symbol: Symbol | undefined = symbols.get({ serverId: nodeId.serverId, presetId, symbolId });

        return symbol?.icon || '';
    };

    const getActualLaneSize = (id: string, size: number, isRow: boolean): number => {
        if (!isRow && colResizeDataRef.current.isResizing && colResizeDataRef.current.id === id) {
            return currentColWidth;
        }
        if (isRow && rowResizeDataRef.current.isResizing && rowResizeDataRef.current.id === id) {
            return currentRowHeight;
        }

        return size;
    };

    const lastIndexFilledColHeader: number = colsHeadersForRender.findLastIndex((col) => !!col.text);
    const lastIndexFilledRowHeader: number = rowsHeadersForRender.findLastIndex((row) => !!row.text);

    return (
        <div ref={matrixContainerRef} className={theme.container} data-test="matrix-editor_container">
            {
                <Table
                    definition
                    className={theme.table}
                    style={{ transform: `scale(${zoomLevel})` } as React.CSSProperties}
                >
                    <Table.Header>
                        <Table.Row className={theme.columnsRowContainer}>
                            <MatrixMainHeader
                                columnHeaderHeight={actualHeaderSizes.height}
                                rowHeaderWidth={actualHeaderSizes.width}
                                currentLocale={currentLocale}
                                data={matrixData}
                                isReadMode={isReadMode}
                                updateMatrixData={updateMatrixDataHandler}
                                openRenameDialog={openRenameDialog}
                                setHeaderResizeData={setHeaderResizeData}
                            />
                            <div
                                className={cn({ [theme.colResizer]: !isReadMode })}
                                onDragStart={(e) => {
                                    e.preventDefault();
                                }}
                                onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                    if (isReadMode) return;

                                    e.stopPropagation();
                                    e.preventDefault();

                                    setHeaderResizeData({
                                        isResizing: true,
                                        direction: HeaderResizeDirections.horizontal,
                                    });
                                }}
                            />
                            {colsHeadersForRender.map((colHeader, colIndex) => {
                                if (!matrixData) return undefined;

                                const { id, symbolId, linkedNodeId, headerSize = MIN_LANE_SIZE } = colHeader;
                                const isForbiidenToReadByProfile: boolean = isForbiddenByProfile({
                                    selectedHeaders: [colHeader],
                                    userProfile,
                                    objectDefinitions,
                                    rights: ['read'],
                                });
                                const localeText: string = getHeaderCellText(
                                    objectDefinitions[linkedNodeId || ''],
                                    colHeader,
                                    isForbiidenToReadByProfile,
                                );
                                const icon: string = getHeaderCellIcon(symbolId);
                                const actualHeaderSize: number = getActualLaneSize(id, headerSize, false);

                                return (
                                    <Fragment key={id}>
                                        <MatrixHeaderCell
                                            id={id}
                                            index={colIndex}
                                            headerSize={actualHeaderSize}
                                            columnHeaderHeight={actualHeaderSizes.height}
                                            rowHeaderWidth={actualHeaderSizes.width}
                                            type={HeaderType.column}
                                            text={localeText}
                                            icon={icon}
                                            nodeId={nodeId}
                                            linkedNodeId={linkedNodeId}
                                            matrixData={matrixData}
                                            colsHeaders={colsHeaders}
                                            colsHeadersForRender={colsHeadersForRender}
                                            rowsHeaders={rowsHeaders}
                                            rowsHeadersForRender={rowsHeadersForRender}
                                            isReadMode={isReadMode}
                                            isRowDrag={isRowDrag}
                                            isCopyEnabled={isCopyEnabled}
                                            isPastEnabled={isPastEnabled}
                                            objectDefinitions={objectDefinitions}
                                            selectedHeaderCells={selectedHeaderCells}
                                            matrixContainerRef={matrixContainerRef}
                                            userProfile={userProfile}
                                            openRenameDialog={openRenameDialog}
                                            setIsRowDrag={setIsRowDrag}
                                            updateMatrixData={updateMatrixDataHandler}
                                        />
                                        {
                                            <div
                                                ref={colIndex === lastIndexFilledColHeader ? lastColRef : null}
                                                className={cn({ [theme.colResizer]: !isReadMode })}
                                                onDragStart={(e) => {
                                                    e.preventDefault();
                                                }}
                                                onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                                    if (isReadMode) return;

                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    setCurrentColWidth(headerSize);
                                                    setColResizeData({
                                                        id,
                                                        isResizing: true,
                                                    });
                                                }}
                                            />
                                        }
                                    </Fragment>
                                );
                            })}
                        </Table.Row>
                        <div
                            style={{ width: `100%` }}
                            className={cn({ [theme.rowResizer]: !isReadMode })}
                            onDragStart={(e) => {
                                e.preventDefault();
                            }}
                            onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                if (isReadMode) return;

                                e.stopPropagation();
                                e.preventDefault();

                                setHeaderResizeData({
                                    isResizing: true,
                                    direction: HeaderResizeDirections.vertical,
                                });
                            }}
                        />
                    </Table.Header>

                    <Table.Body>
                        {rowsHeadersForRender.map((rowHeader, rowIndex) => {
                            const {
                                id: rowId,
                                symbolId: rowSymbolId,
                                linkedNodeId,
                                headerSize: rowHeight = MIN_LANE_SIZE,
                            } = rowHeader;
                            const isForbiidenToReadByProfile: boolean = isForbiddenByProfile({
                                selectedHeaders: [rowHeader],
                                userProfile,
                                objectDefinitions,
                                rights: ['read'],
                            });
                            const rowLocaleText: string = getHeaderCellText(
                                objectDefinitions[linkedNodeId || ''],
                                rowHeader,
                                isForbiidenToReadByProfile,
                            );
                            const rowIcon = getHeaderCellIcon(rowSymbolId);
                            const actualHeaderSize = getActualLaneSize(rowId, rowHeight, true);

                            return (
                                <Fragment key={rowId}>
                                    <Table.Row style={{ height: `${actualHeaderSize}px` }}>
                                        {matrixData && (
                                            <MatrixHeaderCell
                                                id={rowId}
                                                index={rowIndex}
                                                headerSize={actualHeaderSize}
                                                columnHeaderHeight={actualHeaderSizes.height}
                                                rowHeaderWidth={actualHeaderSizes.width}
                                                type={HeaderType.row}
                                                text={rowLocaleText}
                                                icon={rowIcon}
                                                nodeId={nodeId}
                                                linkedNodeId={linkedNodeId}
                                                matrixData={matrixData}
                                                colsHeaders={colsHeaders}
                                                colsHeadersForRender={colsHeadersForRender}
                                                rowsHeaders={rowsHeaders}
                                                rowsHeadersForRender={rowsHeadersForRender}
                                                isReadMode={isReadMode}
                                                isRowDrag={isRowDrag}
                                                isCopyEnabled={isCopyEnabled}
                                                isPastEnabled={isPastEnabled}
                                                objectDefinitions={objectDefinitions}
                                                selectedHeaderCells={selectedHeaderCells}
                                                matrixContainerRef={matrixContainerRef}
                                                userProfile={userProfile}
                                                openRenameDialog={openRenameDialog}
                                                setIsRowDrag={setIsRowDrag}
                                                updateMatrixData={updateMatrixDataHandler}
                                            />
                                        )}
                                        <div
                                            className={cn({ [theme.colResizer]: !isReadMode })}
                                            onDragStart={(e) => {
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                                if (isReadMode) return;

                                                e.stopPropagation();
                                                e.preventDefault();

                                                setHeaderResizeData({
                                                    isResizing: true,
                                                    direction: HeaderResizeDirections.horizontal,
                                                });
                                            }}
                                        />
                                        {colsHeadersForRender.map((colHeader, colIndex) => {
                                            const colLocaleText: string = getHeaderCellText(
                                                objectDefinitions[colHeader.linkedNodeId || ''],
                                                colHeader,
                                                isForbiidenToReadByProfile,
                                            );
                                            const {
                                                id: colId,
                                                headerSize: colWidth = MIN_LANE_SIZE,
                                                symbolId: colSymbolId,
                                            } = colHeader;

                                            const colIcon = getHeaderCellIcon(colSymbolId);
                                            const cellId = getCellIdByRowIdAndColId(rowId, colId);

                                            const colObjectDefinition: ObjectDefinitionImpl | undefined =
                                                objectDefinitions[colHeader.linkedNodeId || ''];
                                            const rowObjectDefinition: ObjectDefinitionImpl | undefined =
                                                objectDefinitions[rowHeader.linkedNodeId || ''];

                                            const hasDeletedHeader: boolean =
                                                hasDeletedHeaderNode(colObjectDefinition, colHeader) &&
                                                hasDeletedHeaderNode(rowObjectDefinition, rowHeader);

                                            const isCellSelectable: boolean =
                                                checkIsCellSelectable(
                                                    rowIndex,
                                                    colIndex,
                                                    rowLocaleText,
                                                    colLocaleText,
                                                ) && hasDeletedHeader;

                                            const isCellForbiddenToEdit: boolean = isForbiddenByProfile({
                                                selectedHeaders: [colHeader, rowHeader],
                                                userProfile,
                                                objectDefinitions,
                                                rights: ['update', 'read'],
                                            });
                                            const isCellForbiddenToRead: boolean = isForbiddenByProfile({
                                                selectedHeaders: [colHeader, rowHeader],
                                                userProfile,
                                                objectDefinitions,
                                                rights: ['read'],
                                            });

                                            const currentCell: _MatrixCell | undefined = cells.find(
                                                ({ rowId: cellRowId, columnId: cellColId }) =>
                                                    cellRowId === rowId && cellColId === colId,
                                            );

                                            const actualHeaderSize = getActualLaneSize(colId, colWidth, false);

                                            if (!matrixData || !currentCell) return undefined;

                                            return (
                                                <MatrixCell
                                                    showItem={hasDeletedHeader && !isCellForbiddenToRead}
                                                    key={cellId}
                                                    cellId={cellId}
                                                    cell={currentCell}
                                                    colId={colId}
                                                    colIndex={colIndex}
                                                    rowId={rowId}
                                                    rowIndex={rowIndex}
                                                    colsHeaders={colsHeadersForRender}
                                                    rowsHeaders={rowsHeadersForRender}
                                                    colIcon={colIcon}
                                                    rowIcon={rowIcon}
                                                    width={actualHeaderSize}
                                                    isCellSelectable={isCellSelectable}
                                                    isReadMode={isReadMode}
                                                    matrixData={matrixData}
                                                    nodeId={nodeId}
                                                    selectedCells={selectedCells}
                                                    selectedHeaderCells={selectedHeaderCells}
                                                    currentLocale={currentLocale}
                                                    isCellForbiddenToEdit={isCellForbiddenToEdit}
                                                    updateMatrixData={updateMatrixDataHandler}
                                                />
                                            );
                                        })}
                                    </Table.Row>
                                    <div
                                        ref={rowIndex === lastIndexFilledRowHeader ? lastRowRef : null}
                                        style={{ width: `${actualHeaderSizes.width}px` }}
                                        className={cn({ [theme.rowResizer]: !isReadMode })}
                                        onDragStart={(e) => {
                                            e.preventDefault();
                                        }}
                                        onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                                            if (isReadMode) return;

                                            e.stopPropagation();
                                            e.preventDefault();

                                            setCurrentRowHeight(rowHeight);
                                            setRowResizeData({
                                                isResizing: true,
                                                id: rowId,
                                            });
                                        }}
                                    />
                                </Fragment>
                            );
                        })}
                    </Table.Body>
                </Table>
            }
        </div>
    );
};
