import { TreeNode } from '../models/tree.types';
import { NodeId, PresetElementTransferType } from '../serverapi/api';
import { TPreset } from '../models/preset.types';
import {
    ADD_MODEL_TYPE_GROUPS,
    CANCEL_PRESET_EDIT,
    CLEAR_PRESET_REQUEST,
    COPY_PRESET,
    CREATE_MODEL_TYPE_GROUP,
    DELETE_PRESET,
    DELETE_PRESET_SUCCESS,
    EDIT_FOLDER_TYPE,
    EDIT_PRESET_REQUEST,
    EDIT_PRESET_REQUEST_SUCCESS,
    EXPORT_PRESET,
    IMPORT_PRESET_REQUEST,
    LOAD_PRESETS_SUCCESS,
    MAKE_DEFAULT,
    CREATE_FOLDER_TYPE,
    OPEN_ADD_PRESET,
    OPEN_METHODOLOGY_TAB,
    OPEN_SYMBOL_EDITOR_TAB,
    OVERLAY_UPDATE_STYLE,
    SUBMIT_MODEL_TYPE_GROUP,
    SUBMIT_PRESET,
    SUBMIT_PRESET_COPY,
    SUBMIT_PRESET_SUCCESS,
    PRESET_COPY_LOADING_FINISH,
    OPEN_KANBAN_CARD_EDITOR_TAB,
    GET_PRESET_LIST,
    EXPORT_EXCEL_PRESET,
    CHANGE_DEFAULT_PRESET,
} from '../actionsTypes/methodologySetting.actionTypes';
import {
    TAddModelTypeGroupAction,
    TCancelEditAction,
    TCancelEditPayload,
    TChangeDefaultPresetAction,
    TChangeDefaultPresetPayload,
    TClearPresetAction,
    TClearPresetPayload,
    TCopyPresetAction,
    TCreateFolderTypeAction,
    TCreateFolderTypePayload,
    TCreateModelTypeGroupAction,
    TCreateModelTypeGroupPayload,
    TDeletePresetAction,
    TDeletePresetSuccessAction,
    TDeletePresetSuccessPayload,
    TEditFolderTypeAction,
    TEditFolderTypePayload,
    TEditPresetRequestAction,
    TEditPresetRequestSuccessAction,
    TExportExcelPresetAction,
    TExportPresetAction,
    TGetPresetList,
    TImportPresetRequestAction,
    TImportPresetRequestPayload,
    TLoadPresetsSuccessAction,
    TLoadPresetsSuccessPayload,
    TMakeDefaultAction,
    TMakeDefaultPayload,
    TOpenAddPresetAction,
    TOpenKanbanCardEditorTabAction,
    TOpenKanbanCardEditorTabPayload,
    TOpenMethodologyTabAction,
    TOpenSymbolEditorTabAction,
    TOpenSymbolEditorTabPayload,
    TPresetCopyLoadingFinishAction,
    TSubmitModelTypeGroupAction,
    TSubmitModelTypeGroupPayload,
    TSubmitPresetAction,
    TSubmitPresetCopyAction,
    TSubmitPresetPayload,
    TSubmitPresetSuccessAction,
    TSubmitPresetSuccessPayload,
    TUpdateModelTypeWithFloatingAttributesStylesPayload,
} from './methodologySetting.actions.types';

export const openMethodologySettingTab = (node: TreeNode): TOpenMethodologyTabAction => ({
    type: OPEN_METHODOLOGY_TAB,
    payload: { serverNode: node },
});

export const cancelEdit = (payload: TCancelEditPayload): TCancelEditAction => ({
    type: CANCEL_PRESET_EDIT,
    payload,
});

export const importPresetRequest = (payload: TImportPresetRequestPayload): TImportPresetRequestAction => ({
    type: IMPORT_PRESET_REQUEST,
    payload,
});

export const openSymbolEditorTab = (payload: TOpenSymbolEditorTabPayload): TOpenSymbolEditorTabAction => ({
    type: OPEN_SYMBOL_EDITOR_TAB,
    payload,
});

export const openAddPreset = (node: TreeNode): TOpenAddPresetAction => ({
    type: OPEN_ADD_PRESET,
    payload: { serverNode: node },
});

export const deletePresetAction = (serverNode: TreeNode, preset: TPreset): TDeletePresetAction => ({
    type: DELETE_PRESET,
    payload: {
        serverNode,
        preset,
    },
});

export const editPresetRequest = (
    preset: TPreset,
    serverNode: TreeNode,
    tabNodeId: NodeId,
): TEditPresetRequestAction => ({
    type: EDIT_PRESET_REQUEST,
    payload: {
        serverNode,
        preset,
        tabNodeId,
    },
});

export const editPresetRequestSuccess = (): TEditPresetRequestSuccessAction => ({
    type: EDIT_PRESET_REQUEST_SUCCESS,
    payload: {},
});

export const copyPreset = (preset: TPreset, serverNode: TreeNode): TCopyPresetAction => ({
    type: COPY_PRESET,
    payload: {
        serverNode,
        preset,
    },
});

export const submitPresetCopy = (
    preset: TPreset,
    serverNode: TreeNode,
    tabNodeId: NodeId,
): TSubmitPresetCopyAction => ({
    type: SUBMIT_PRESET_COPY,
    payload: {
        serverNode,
        preset,
        tabNodeId,
    },
});

export const createModelTypeGroup = (payload: TCreateModelTypeGroupPayload): TCreateModelTypeGroupAction => ({
    type: CREATE_MODEL_TYPE_GROUP,
    payload,
});

export const editFolderType = (payload: TEditFolderTypePayload): TEditFolderTypeAction => ({
    type: EDIT_FOLDER_TYPE,
    payload,
});

export const newFolderType = (payload: TCreateFolderTypePayload): TCreateFolderTypeAction => ({
    type: CREATE_FOLDER_TYPE,
    payload,
});

export const submitPreset = (payload: TSubmitPresetPayload): TSubmitPresetAction => ({
    type: SUBMIT_PRESET,
    payload,
});

export const presetCopyLoadingFinish = (): TPresetCopyLoadingFinishAction => ({
    type: PRESET_COPY_LOADING_FINISH,
});

export const makeDefault = (payload: TMakeDefaultPayload): TMakeDefaultAction => ({
    type: MAKE_DEFAULT,
    payload,
});

export const submitModelTypeGroup = (payload: TSubmitModelTypeGroupPayload): TSubmitModelTypeGroupAction => ({
    type: SUBMIT_MODEL_TYPE_GROUP,
    payload,
});

export const addModelTypeGroups = (payload: TSubmitModelTypeGroupPayload): TAddModelTypeGroupAction => ({
    type: ADD_MODEL_TYPE_GROUPS,
    payload,
});

export const submitPresetSuccess = (payload: TSubmitPresetSuccessPayload): TSubmitPresetSuccessAction => ({
    type: SUBMIT_PRESET_SUCCESS,
    payload,
});

export const exportPreset = (
    preset: TPreset,
    serverId: string,
    type?: PresetElementTransferType,
    ids?: string[],
): TExportPresetAction => ({
    type: EXPORT_PRESET,
    payload: {
        preset,
        serverId,
        type,
        ids,
    },
});

export const exportExcelPreset = (preset: TPreset, serverId: string): TExportExcelPresetAction => ({
    type: EXPORT_EXCEL_PRESET,
    payload: {
        preset,
        serverId,
    },
});

export const deletePresetSuccess = (payload: TDeletePresetSuccessPayload): TDeletePresetSuccessAction => ({
    type: DELETE_PRESET_SUCCESS,
    payload,
});

export const loadPresetsSuccess = (payload: TLoadPresetsSuccessPayload): TLoadPresetsSuccessAction => ({
    type: LOAD_PRESETS_SUCCESS,
    payload,
});

export const updateModelTypeWithFloatingAttributesStyles = (
    payload: TUpdateModelTypeWithFloatingAttributesStylesPayload,
) => ({
    type: OVERLAY_UPDATE_STYLE,
    payload,
});

export const clearPresetRequest = (payload: TClearPresetPayload): TClearPresetAction => ({
    type: CLEAR_PRESET_REQUEST,
    payload,
});

export const openKanbanCardEditorTab = (payload: TOpenKanbanCardEditorTabPayload): TOpenKanbanCardEditorTabAction => ({
    type: OPEN_KANBAN_CARD_EDITOR_TAB,
    payload,
});

export const getPresetList = (): TGetPresetList => ({
    type: GET_PRESET_LIST,
});

export const changeDefaultPreset = (payload: TChangeDefaultPresetPayload): TChangeDefaultPresetAction => ({
    type: CHANGE_DEFAULT_PRESET,
    payload,
});
