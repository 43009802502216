// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WriteCommentDialog__dialog__cCNkF .ant-modal-footer,.WriteCommentDialog__dialog__cCNkF .ant-modal-header{border:none;padding:0}.WriteCommentDialog__dialog__cCNkF .ant-modal-content,.WriteCommentDialog__dialog__cCNkF .ant-modal-header{border-radius:8px !important}.WriteCommentDialog__dialog__cCNkF .ant-modal-close{margin-top:5px}.WriteCommentDialog__dialog__cCNkF .ant-modal-body{padding:0}.WriteCommentDialog__commentNameHeaderContainer__EtwOt{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:16px 0 0 24px}", "",{"version":3,"sources":["webpack://./src/modules/Comments/components/WriteCommentDialog/WriteCommentDialog.scss"],"names":[],"mappings":"AAEQ,0GACI,WAAA,CACA,SAAA,CAEJ,2GACI,4BAAA,CAEJ,oDACI,cAAA,CAEJ,mDACI,SAAA,CAKZ,uDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,qBAAA","sourcesContent":[".dialog {\r\n    :global {\r\n        .ant-modal-footer, .ant-modal-header {\r\n            border: none;\r\n            padding: 0;\r\n        }\r\n        .ant-modal-content, .ant-modal-header {\r\n            border-radius: 8px !important;\r\n        }\r\n        .ant-modal-close {\r\n            margin-top: 5px;\r\n        }\r\n        .ant-modal-body {\r\n            padding: 0;\r\n        }\r\n    }\r\n}\r\n\r\n.commentNameHeaderContainer {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    padding: 16px 0 0 24px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "WriteCommentDialog__dialog__cCNkF",
	"commentNameHeaderContainer": "WriteCommentDialog__commentNameHeaderContainer__EtwOt"
};
export default ___CSS_LOADER_EXPORT___;
