import { put, takeEvery, select } from 'redux-saga/effects';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { closeDialog } from '../actions/dialogs.actions';
import { TChoseSymbolForDragDialogSubmitDataAction } from '../actions/choseSymbolForDragDialog.actions.types';
import { CHOSE_SYMBOL_FOR_DRAG_DIALOG_SUBMIT_DATA } from '../actionsTypes/choseSymbolForDragDialog.actionTypes';
import { modelMove, objectDefinitionMove } from '../actions/entities/objectDefinition.actions';
import { TreeNode } from '../models/tree.types';
import { TreeSelectors } from '../selectors/tree.selectors';
import { TreeItemType } from '../modules/Tree/models/tree';

function* handleChoseSymbolForDragDialogSubmitData({ payload }: TChoseSymbolForDragDialogSubmitDataAction) {
    const { symbolId, nodeId, supportedObjectTypeSymbols } = payload;
    const symbol = supportedObjectTypeSymbols.find((e) => e.id === symbolId);

    if (symbol) {
        const draggedModelNode: TreeNode = yield select(TreeSelectors.itemById(nodeId));

        if (draggedModelNode.type === TreeItemType.ObjectDefinition) {
            yield put(objectDefinitionMove({ symbol, nodeId, serverUrl: undefined }));
        } else {
            yield put(modelMove({ symbol, nodeId, serverUrl: undefined }));
        }
    }

    yield put(closeDialog(DialogType.CHOOSE_SYMBOL_FOR_DRAG_DIALOG));
}

export function* choseSymbolForDragDialogSaga() {
    yield takeEvery(CHOSE_SYMBOL_FOR_DRAG_DIALOG_SUBMIT_DATA, handleChoseSymbolForDragDialogSubmitData);
}
