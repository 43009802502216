import { Alignment } from './alignment';

/**
 * Created by kolpakovd on 10/28/2017.
 * Class for state of buttons in general menu, which respond for edit text inside of labels
 */
export class ButtonEditLabelState {
    isFontBoldSelected: boolean = false;
    isFontItalicSelected: boolean = false;
    isFontUnderlineSelected: boolean = false;
    alignment: Alignment = Alignment.CenterHorz;
    fontSize: string = '';
    fontFamily: string = '';
}
