import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { saveAclsByType } from '../actions/acl.actions';
import { TSaveAclsAction, TSaveAllAclsAction } from '../actions/acl.actions.types';
import { SAVE_ACLS_BY_TYPE, SAVE_ALL_ACLS } from '../actionsTypes/acl.actionTypes';
import { TServerEntity } from '../models/entities.types';
import { TPresetProfileEntityTypes } from '../modules/AdminTools/Methodology/components/Presets/PresetProfile/presetProfile.types';
import { AclSelectors } from '../selectors/acl.selectors';
import { ServerSelectors } from '../selectors/entities/server.selectors';

function* handleSaveAcls({ payload }: TSaveAclsAction) {
    const { serverId, profileId, presetId, type } = payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const data = yield select(AclSelectors.allOfTypeInPreset({ serverId, presetId, type, profileId }));
    if (data) {
        // data пустая если для данного типа acl еще не открывали вкладку соответственно в сторе еще нет данных
        yield call(() => server.api.profile.setAcls({ presetId, profileId, type, body: data.acls }));
    }
}

function* handleSaveAllAcls({ payload }: TSaveAllAclsAction) {
    const { serverId, profileId, presetId } = payload;
    const effects = Object.keys(TPresetProfileEntityTypes).map((type) =>
        put(
            saveAclsByType({
                serverId,
                profileId,
                presetId,
                type: TPresetProfileEntityTypes[type],
            }),
        ),
    );
    yield all(effects);
}

export function* aclSaga() {
    yield takeEvery(SAVE_ACLS_BY_TYPE, handleSaveAcls);
    yield takeEvery(SAVE_ALL_ACLS, handleSaveAllAcls);
}
