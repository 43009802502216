import {
    FolderType,
    FolderTypeValidNodeTypesEnum,
    InternationalString,
    ModelType,
    ObjectType,
} from '../../serverapi/api';
import { Locale } from '../../modules/Header/components/Header/header.types';
import messages from '../../modules/FolderDialog/FolderDialog.messages';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { TreeNode } from '../../models/tree.types';
import { LocalesService } from '../LocalesService';

const allowAnyTypeKeyByTypeName: Record<
    keyof Pick<FolderType, 'validModelTypes' | 'validObjectTypes' | 'validFolderTypes' | 'validNodeTypes'>,
    keyof Pick<FolderType, 'allowAnyModelType' | 'allowAnyObjectType' | 'allowAnyFolderType' | 'allowAnyNodeType'>
> = {
    validModelTypes: 'allowAnyModelType',
    validObjectTypes: 'allowAnyObjectType',
    validFolderTypes: 'allowAnyFolderType',
    validNodeTypes: 'allowAnyNodeType',
};

const typeKeysByNodeType: Record<
    TreeItemType.Model | TreeItemType.Folder | TreeItemType.ObjectDefinition,
    { [key: string]: keyof FolderType | keyof TreeNode }
> = {
    [TreeItemType.Model]: {
        itemTypeId: 'modelTypeId',
        validItemTypes: 'validModelTypes',
        allowAnyItemType: 'allowAnyModelType',
    },
    [TreeItemType.Folder]: {
        itemTypeId: 'folderType',
        validItemTypes: 'validFolderTypes',
        allowAnyItemType: 'allowAnyFolderType',
    },
    [TreeItemType.ObjectDefinition]: {
        itemTypeId: 'objectTypeId',
        validItemTypes: 'validObjectTypes',
        allowAnyItemType: 'allowAnyObjectType',
    },
};

/**
 * @return folderTypeId === 'default'
 */
export function getIsDefaultFolder(folderTypeId: string | undefined): boolean {
    return folderTypeId === 'default';
}

export function getCreatableFolderTypes(
    allFolderTypes: FolderType[] | undefined,
    parentFolderType: FolderType | undefined,
    currentLocale: Locale,
): FolderType[] {
    if (!allFolderTypes) return [];
    const allFolderTypesCopy = [...allFolderTypes];
    const isMissingDefaultFolder: boolean = !allFolderTypesCopy.find((ft) => getIsDefaultFolder(ft.id));
    const intl = LocalesService.useIntl();
    const multilingualName: InternationalString = { [currentLocale]: intl.formatMessage(messages.defaultFolderType) };
    const defaultFolderMock: FolderType = {
        id: 'default',
        multilingualName,
    } as FolderType;

    if (isMissingDefaultFolder) allFolderTypesCopy.push(defaultFolderMock);

    if (
        !parentFolderType?.allowAnyFolderType &&
        parentFolderType?.validFolderTypes &&
        parentFolderType.id !== 'default'
    ) {
        const validFolderTypeIds: string[] = parentFolderType.validFolderTypes;

        return allFolderTypesCopy.filter((ft) => validFolderTypeIds.includes(ft.id));
    }

    return allFolderTypesCopy;
}

export function getCreatableTypes({
    allModelTypes,
    allObjectDefinitionTypes,
    parentFolderType,
}: {
    allModelTypes?: ModelType[];
    allObjectDefinitionTypes?: ObjectType[];
    parentFolderType: FolderType | undefined;
}): ModelType[] | ObjectType[] {
    const allTypes = allModelTypes || allObjectDefinitionTypes || [];
    if (!parentFolderType) return allTypes;

    const isAllowAny = allModelTypes ? parentFolderType?.allowAnyModelType : parentFolderType?.allowAnyObjectType;
    const validTypes = allModelTypes ? parentFolderType?.validModelTypes : parentFolderType?.validObjectTypes;

    if (!isAllowAny && validTypes) {
        // @ts-ignore
        return allTypes.filter((t) => validTypes.includes(t.id));
    }

    if (!isAllowAny && !validTypes) {
        return [];
    }

    return allTypes;
}

export function getCreatableModelTypes(
    allModelTypes: ModelType[] | undefined,
    parentFolderType: FolderType | undefined,
): ModelType[] {
    return getCreatableTypes({ allModelTypes, parentFolderType }) as ModelType[];
}

export function getCreatableObjectDefinitionTypes(
    allObjectDefinitionTypes: ObjectType[] | undefined,
    parentFolderType: FolderType | undefined,
): ObjectType[] {
    return getCreatableTypes({ allObjectDefinitionTypes, parentFolderType }) as ObjectType[];
}

export function getCreatableTreeContextMenuItem(
    folderType: FolderType | undefined,
): Record<
    | TreeItemType.Folder
    | TreeItemType.Model
    | TreeItemType.ObjectDefinition
    | TreeItemType.Spreadsheet
    | TreeItemType.Kanban
    | TreeItemType.File
    | TreeItemType.SimulationModeling,
    boolean
> {
    const permissionsToCreate = {
        FOLDER: true,
        MODEL: true,
        OBJECT: true,
        SPREADSHEET: true,
        FILE: true,
        SIMULATION: true,
        KANBAN: true,
    };

    if (!folderType) return permissionsToCreate;

    if (!folderType.allowAnyFolderType && !folderType.validFolderTypes?.length) {
        permissionsToCreate.FOLDER = false;
    }

    if (!folderType.allowAnyModelType && !folderType.validModelTypes?.length) {
        permissionsToCreate.MODEL = false;
    }

    if (!folderType.allowAnyObjectType && !folderType.validObjectTypes?.length) {
        permissionsToCreate.OBJECT = false;
    }

    if (!folderType.allowAnyNodeType && !folderType?.validNodeTypes?.includes(TreeItemType.Spreadsheet)) {
        permissionsToCreate.SPREADSHEET = false;
    }

    if (!folderType.allowAnyModelType && !folderType.validModelTypes?.length) {
        permissionsToCreate.KANBAN = false;
    }

    // if (!folderType.allowAnyNodeType && !folderType?.validNodeTypes?.includes(TreeItemType.Kanban)) {
    //     permissionsToCreate.KANBAN = false;
    // }

    if (!folderType.allowAnyNodeType && !folderType?.validNodeTypes?.includes(TreeItemType.File)) {
        permissionsToCreate.FILE = false;
    }

    if (!folderType.allowAnyNodeType && !folderType?.validNodeTypes?.includes(TreeItemType.SimulationModeling)) {
        permissionsToCreate.SIMULATION = false;
    }

    return permissionsToCreate;
}

export function getFolderTypeByNode(
    node: TreeNode,
    folderTypes: Record<string, FolderType> | FolderType[],
): FolderType | undefined {
    return Object.values(folderTypes).find((ft) => ft.id === node?.folderType);
}

export function getIsTreeItemAllowedInFolder(node: TreeNode, parentFolderType: FolderType | undefined): boolean {
    const isDefaultFolder = getIsDefaultFolder(parentFolderType?.id);
    if (!parentFolderType || isDefaultFolder) return true;

    switch (node.type) {
        case TreeItemType.Folder:
        case TreeItemType.ObjectDefinition:
        case TreeItemType.Model: {
            const { itemTypeId, validItemTypes, allowAnyItemType } = typeKeysByNodeType[node.type];
            const isAllowCurrentNode = parentFolderType[validItemTypes]?.includes(node[itemTypeId]);

            return Boolean(isAllowCurrentNode || parentFolderType[allowAnyItemType]);
        }
        case TreeItemType.File:
        case TreeItemType.SimulationModeling:
        case TreeItemType.Spreadsheet: {
            const isAllowCurrentNode = Boolean(parentFolderType.validNodeTypes?.includes(node.type));

            return parentFolderType.allowAnyNodeType || isAllowCurrentNode;
        }
        default: {
            return true;
        }
    }
}

export function getIsTypeByIdAllowedInFolder(
    typeId: string | FolderTypeValidNodeTypesEnum,
    typeName: keyof Pick<FolderType, 'validModelTypes' | 'validObjectTypes' | 'validFolderTypes' | 'validNodeTypes'>,
    parentFolderType: FolderType | undefined,
): boolean {
    const isDefaultFolder: boolean = getIsDefaultFolder(parentFolderType?.id);
    if (!parentFolderType || isDefaultFolder) return true;

    const isAllowAny: boolean | undefined = parentFolderType[allowAnyTypeKeyByTypeName[typeName]];

    if (!isAllowAny) {
        return Boolean(parentFolderType[typeName]?.includes(typeId as FolderTypeValidNodeTypesEnum)); // or typeId as string
    }

    return true;
}

export const getAllowedTypeIds = (
    typeIds: string[] | FolderTypeValidNodeTypesEnum[],
    typeName: keyof Pick<FolderType, 'validModelTypes' | 'validObjectTypes' | 'validFolderTypes' | 'validNodeTypes'>,
    parentFolderType: FolderType | undefined,
): string[] | FolderTypeValidNodeTypesEnum[] =>
    typeIds.filter((typeId) => getIsTypeByIdAllowedInFolder(typeId, typeName, parentFolderType));

export const getIsObjectTypeByIdAllowedInFolder = (objectTypeId: string, folderType: FolderType | undefined): boolean =>
    getIsTypeByIdAllowedInFolder(objectTypeId, 'validObjectTypes', folderType);

export const getIsModelTypeByIdAllowedInFolder = (modelTypeId: string, folderType: FolderType | undefined): boolean =>
    getIsTypeByIdAllowedInFolder(modelTypeId, 'validModelTypes', folderType);

export const getIsFolderTypeByIdAllowedInFolder = (folderTypeId: string, folderType: FolderType | undefined): boolean =>
    getIsTypeByIdAllowedInFolder(folderTypeId, 'validFolderTypes', folderType);

export const getIsNodeTypeByIdAllowedInFolder = (
    nodeTypeId: FolderTypeValidNodeTypesEnum,
    folderType: FolderType | undefined,
): boolean => getIsTypeByIdAllowedInFolder(nodeTypeId, 'validNodeTypes', folderType);

export const getAllowedObjectTypeIds = (objectTypeIds: string[], folderType: FolderType | undefined): string[] =>
    getAllowedTypeIds(objectTypeIds, 'validObjectTypes', folderType);

export const getAllowedModelTypeIds = (modelTypeIds: string[], folderType: FolderType | undefined): string[] =>
    getAllowedTypeIds(modelTypeIds, 'validModelTypes', folderType);

export const getAllowedFolderTypeIds = (folderTypeIds: string[], folderType: FolderType | undefined): string[] =>
    getAllowedTypeIds(folderTypeIds, 'validFolderTypes', folderType);

export const getAllowedNodeTypeIds = (
    nodeTypeIds: FolderTypeValidNodeTypesEnum[],
    folderType: FolderType | undefined,
): FolderTypeValidNodeTypesEnum[] =>
    getAllowedTypeIds(nodeTypeIds, 'validNodeTypes', folderType) as FolderTypeValidNodeTypesEnum[];
