import { TReducer } from '@/utils/types';
import { TKeycloakSettingsState } from './keycloakSettings.reducer.types';
import { KEYCLOAK_SETTINGS_SET } from '@/actionsTypes/keycloakSettings.actionTypes';

const initial: TKeycloakSettingsState = {
    settings: undefined,
};

export const keycloakSettingsReducer: TReducer<TKeycloakSettingsState> = (state = initial, action) => {
    switch (action.type) {
        case KEYCLOAK_SETTINGS_SET: {
            return {
                settings: action.payload,
            };
        }

        default:
            return state;
    }
};

