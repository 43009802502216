import { put, takeEvery, select } from 'redux-saga/effects';
import { OPEN_SAFETY_TAB } from '../actionsTypes/safety.actionTypes';
import { TOpenSafetyTabAction } from '../actions/safety.actions.types';
import { workspaceAddTab } from '../actions/tabs.actions';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { isNullOrUndefined } from 'is-what';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTab, TWorkspaceTabItemDefaultParams } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';
import { admintoolSelect } from '../actions/admintools.actions';

function* handleOpenSafetyTab({ payload: { node } }: TOpenSafetyTabAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(node.nodeId.serverId));

    if (isNullOrUndefined(server)) {
        throw new Error(`Cannot find server with ID=${node.nodeId.serverId}`);
    }

    if (node) {
        const safetySettingsTab: TWorkspaceTab = {
            title: node.name,
            nodeId: node.nodeId,
            type: WorkSpaceTabTypes.SAFETY,
            mode: EditorMode.Read,
            params: {} as TWorkspaceTabItemDefaultParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };

        yield put(admintoolSelect({ currentServerId: node.nodeId.serverId, activeTypeId: AdminToolTreeType.SAFETY }));

        yield put(workspaceAddTab(safetySettingsTab));
    }
}

export function* safetySaga() {
    yield takeEvery(OPEN_SAFETY_TAB, handleOpenSafetyTab);
}
