// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServerEditDialog__alert___QYKP{margin-bottom:20px}.ServerEditDialog__deleteButton__IfKPs{float:left}.ServerEditDialog__dialog__tS8hj{-webkit-animation-duration:0s !important;animation-duration:0s !important}", "",{"version":3,"sources":["webpack://./src/modules/ServerForm/components/theme/ServerEditDialog.scss"],"names":[],"mappings":"AAAA,gCACI,kBAAA,CAGJ,uCACI,UAAA,CAGJ,iCACI,wCAAA,CAAA,gCAAA","sourcesContent":[".alert {\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.deleteButton {\r\n    float: left;\r\n}\r\n\r\n.dialog {\r\n    animation-duration: 0s !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "ServerEditDialog__alert___QYKP",
	"deleteButton": "ServerEditDialog__deleteButton__IfKPs",
	"dialog": "ServerEditDialog__dialog__tS8hj"
};
export default ___CSS_LOADER_EXPORT___;
