import React, { ForwardedRef, forwardRef } from 'react';
import classnames from 'classnames';
import theme from './Label.scss';
import { ILabelProps } from './Label.types';

/**
 * ссылка на фигму (https://www.figma.com/design/QwNP21XezszK7TasNIoDQf/SDS-Design-KIT?node-id=10-18&t=qxHCmm3HZbbQAPbx-0)
 */

export const Label = forwardRef(
    (
        { className = '', isError, label, name, error, isDisabled, children, isRequired, dataTest }: ILabelProps,
        ref: ForwardedRef<HTMLLabelElement>,
    ) => (
        <label
            htmlFor={name}
            className={classnames(
                theme.label,
                {
                    [theme.label_error]: isError || !!error,
                    [theme.label_disabled]: !!isDisabled,
                },
                className,
            )}
            ref={ref}
            data-testid={dataTest}
        >
            {(label || isRequired) && (
                <span className={theme.label__text}>
                    {isRequired && <sup className={theme.label__required}>*</sup>}
                    {label}
                </span>
            )}
            {children}
            {error && <span className={theme.label__error}>{error}</span>}
        </label>
    ),
);
