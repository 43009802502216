import { TReducer } from '../utils/types';
import { ReportType } from '../serverapi/api';
import { IReportModelTypeData, TReportModelTypeState } from './reportModelType.reducer.types';
import { REPORT_MODEL_TYPE_REQUEST_SUCCESS } from '@/actionsTypes/reportModelType.actionTypes';

const initial: TReportModelTypeState = {};

export const INITIAL_REPORT_MODEL_TYPE_SERVER_STATE: IReportModelTypeData = {
    byId: {},
};

export const reportModelTypeReducer: TReducer<TReportModelTypeState> = (state = initial, action) => {
    switch (action.type) {
        case REPORT_MODEL_TYPE_REQUEST_SUCCESS: {
            const {
                payload: { reportModelTypes, presetId },
            } = action;
            const reportModelTypesMap = reportModelTypes.reduce(
                (result: {}, item: ReportType) => ({ ...result, [item.id]: item }),
                {},
            );

            return {
                ...state,
                [presetId]: {
                    byId: {
                        ...reportModelTypesMap,
                    },
                },
            };
        }

        default:
            return state;
    }
};
