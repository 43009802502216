export enum AppStatus {
    Initial,
    Bootstrapping,
    Bootstrapped,
    Logout,
    Kicked,
}

export enum AppExitMethod {
    Logout,
    Unauthorized,
}
