import { LocalesService } from '@/services/LocalesService';
import { ConfigProvider } from 'antd';

import React from 'react';
import { useIntl } from 'react-intl';

import messages from './DatePickerProvider.messages';

export const DatePickerProvider = ({ children }) => {
    const intl = useIntl();
    const locale = LocalesService.checkAndSetLocale(intl.locale);

    return (
        <ConfigProvider
            locale={{
                ...locale,
                DatePicker: locale.DatePicker
                    ? {
                          ...locale.DatePicker,
                          lang: {
                              ...locale.DatePicker.lang,
                              ok: intl.formatMessage(messages.apply),
                          },
                      }
                    : undefined,
            }}
        >
            {children}
        </ConfigProvider>
    );
};
