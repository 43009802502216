import { takeEvery, select } from 'redux-saga/effects';
import { RELOCK, UNLOCK } from '../actionsTypes/lock.actionTypes';
import { TRelockAction, TUnlockAction } from '../actions/lock.actions.types';
import { modelContextByGraphId } from './utils';
import { IModelContext } from './utils.types';
import { EditorMode } from '../models/editorMode';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { lockService } from '@/services/LockService';

export function* unlock({ payload: { nodeId, type } }: TUnlockAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    if (server) {
        yield lockService().unlock(type, nodeId);
    }
}

export function* relock({ payload: { nodeId, type } }: TRelockAction) {
    const modelContext: IModelContext = yield modelContextByGraphId(nodeId);
    if (modelContext && modelContext.schema.mode === EditorMode.Edit) {
        yield lockService().lock(type, nodeId);
    }
}

export function* lockSaga() {
    yield takeEvery(RELOCK, relock);
    yield takeEvery(UNLOCK, unlock);
}
