import { put, all, select, takeEvery } from 'redux-saga/effects';
import { TTreeItemContextMenuAction } from '../../actions/tree.actions.types';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../../actionsTypes/tree.actionTypes';
import { CLONE_MODEL } from '../../actionsTypes/cloneModel.actionTypes';
import { TCloneModelAction } from '../../actions/cloneModel.actions.types';
import { TreeItemContextMenuAction, TreeItemType } from '../../modules/Tree/models/tree';
import { openDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../../modules/DialogRoot/DialogRoot.constants';
import { TreeSelectors } from '../../selectors/tree.selectors';
import { DiagramElement, ModelNode, Node, NodeId, ObjectInstance, ShapeInstance } from '../../serverapi/api';
import { treeItemAdd, treeItemRefresh } from '../../actions/tree.actions';
import { LocalesService } from '../../services/LocalesService';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import messages from '../../modules/CloneModelDialog/components/CloneModelDialog.messages';
import { modelService } from '../../services/ModelService';
import { nodeService } from '../../services/NodeService';
import { TreeNode } from '../../models/tree.types';
import { TCloneModelDialogContainerProperties } from '../../modules/CloneModelDialog/CloneModelDialog.types';

function* handleCloneModelRequest({
    payload: { modelNodeId, strategy, newParentNodeIdForModelCopy },
}: TCloneModelAction) {
    const modelClone: ModelNode = yield modelService().cloneModel(modelNodeId, newParentNodeIdForModelCopy, strategy);

    if (modelClone) {
        const { serverId, repositoryId } = modelClone.nodeId;
        const elementsNodeIds: (undefined | NodeId)[] | undefined = modelClone.elements
            ?.map((element: DiagramElement): NodeId | undefined => {
                let id: string | undefined;
                if (element.type === 'object') {
                    id = (element as ObjectInstance).objectDefinitionId;
                } else if (element.type === 'shape') {
                    id = (element as ShapeInstance).imageId;
                }

                return id ? { serverId, repositoryId, id } : undefined;
            })
            .filter((id) => id);
        const relatedNodes: Node[] = yield nodeService().loadNodesFromServer(serverId, elementsNodeIds as NodeId[]);

        yield all(
            relatedNodes.map((relatedNode) =>
                put(
                    treeItemAdd({
                        ...relatedNode,
                        hasChildren: false,
                    } as TreeNode),
                ),
            ),
        );

        const nodeId: NodeId | undefined = modelClone.parentNodeId;
        yield put(treeItemRefresh(nodeId));
    }
}

function* handleContextMenuAction({ payload }: TTreeItemContextMenuAction) {
    if (payload.type === TreeItemType.Model && payload.action === TreeItemContextMenuAction.CLONE_MODEL) {
        const model: ModelNode = yield select(TreeSelectors.itemById(payload.nodeId));

        if (model) {
            const dialogProperties: TCloneModelDialogContainerProperties = {
                modelNodeId: payload.nodeId,
                modelName: model.name,
                type: model.type,
            };
            yield put(openDialog(DialogType.CLONE_MODEL_DIALOG, dialogProperties));
        } else {
            const intl = LocalesService.useIntl(yield select(getCurrentLocale));
            throw Error(intl.formatMessage(messages.modelNotFoundError));
        }
    }
}

export function* cloneModelSagaInit() {
    yield takeEvery(CLONE_MODEL, handleCloneModelRequest);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleContextMenuAction);
}
