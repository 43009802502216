import { ContentToken, StateInline } from 'remarkable/lib';
import { findLastIndex } from 'lodash-es';

function colorParser(state: StateInline, silent: boolean) {
    const start = state.pos;
    const isOpen = state.src.slice(start, start + 7) === '<COLOR#';
    const isClose = state.src.slice(start, start + 7) === '</COLOR';

    if (!(isOpen || isClose) || silent) {
        return false;
    }

    const tail = state.src.slice(start);
    const openMatch = /^<COLOR#([a-zA-Z0-9\ ]+)>/g.exec(tail);
    const closeMatch = /^<\/COLOR>/g.exec(tail);

    if (!openMatch && !closeMatch) {
        state.pos = start;
        state.parser.tokenize(state);

        return false;
    }

    if (isOpen && openMatch) {
        if (!silent) {
            state.push(<ContentToken>{ type: `color-#${openMatch[1]}`, level: state.level++ });
            state.pos += openMatch[0].length;
            state.parser.tokenize(state);
        }
    }

    if (isClose && closeMatch) {
        state.push(<ContentToken>{ type: 'color_close', level: --state.level });
        const lastUnmodifiedTokenIndex = findLastIndex(
            state.tokens,
            (token: ContentToken) => token.content === undefined && token.type.indexOf('color-') !== -1,
        );

        const token = state.tokens[lastUnmodifiedTokenIndex];

        if (token?.content) {
            token.content = state.tokens.reduce(
                (result: string, token: ContentToken, index: number) =>
                    result + (index > lastUnmodifiedTokenIndex ? token.content || '' : ''),
                '',
            );
        }
        state.pos += closeMatch[0].length;
        state.parser.tokenize(state);
    }

    return true;
}

export { colorParser };
