import { defineMessages } from 'react-intl';

export default defineMessages({
    profileName: {
        id: 'UserProfileDialog.profileName',
        defaultMessage: 'Добавление нового профиля пользователей',
    },
    profileCancelButton: {
        id: 'UserProfileDialog.modelFormCancelButton',
        defaultMessage: 'Отмена',
    },
    profileConfirmButton: {
        id: 'UserProfileDialog.modelFormConfirmButton',
        defaultMessage: 'Сохранить',
    },
    profileNamePlaceholder: {
        id: 'UserProfileDialog.profileNamePlaceholder',
        defaultMessage: 'Название нового профиля',
    },
    profileNameLabel: {
        id: 'UserProfileDialog.profileNameLabel',
        defaultMessage: 'Введите название профиля',
    },
    profileNameRequiredError: {
        id: 'UserProfileDialog.profileNameRequiredError',
        defaultMessage: 'Название профиля должно быть заполнено',
    },
});
