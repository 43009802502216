import React, { FC, useEffect } from 'react';
import {
    ArisData,
    ArisImportDescriptor,
    ArisSymbolMap,
    ArisTypeMap,
    AttributeType,
    EdgeType,
    ModelType,
    NodeId,
    ObjectType,
    Symbol,
} from '../../../../../serverapi/api';
import { Select, Table } from 'antd';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { TreeSelectors } from '../../../../../selectors/tree.selectors';
import { ModelTypeSelectors } from '../../../../../selectors/modelType.selectors';
import { SymbolSelectors } from '../../../../../selectors/symbol.selectors';
import { AttributeTypeSelectors } from '../../../../../selectors/attributeType.selectors';
import style from './MethodologyStep.scss';
import { ObjectTypeSelectors } from '../../../../../selectors/objectType.selectors';
import { presetMetaDataRequest } from '../../../../../actions/notation.actions';
import { setArisMethodologyData } from '../../../../../actions/importAris.actions';
import messages from '../../messages/ImportAris.messages';
import { useIntl } from 'react-intl';
import { ImportArisSelectors } from '../../../../../selectors/importAris.selectors';
import { getArisCellTableData, getSymbolsOfObject, getSymbolsWithoutObject } from './MethodologyStep.utils';

type TMethodologyStepProps = {
    nodeId: NodeId;
};

type TTableDataArisType = 'modelTypeAris' | 'objectTypeAris' | 'symbolsAris' | 'edgeAris' | 'attributeAris';
type TTableDataSilaType = 'modelTypeSila' | 'objectTypeSila' | 'symbolsSila' | 'edgesSila' | 'attributeSila';

export enum DefaultOptionMessage {
    modelTypes = 'selectModelType',
    objectTypes = 'selectObjectType',
    symbols = 'selectSymbolType',
    edgeTypes = 'selectEdgeType',
    attributeTypes = 'selectAttributeType',
}

const { Option } = Select;

const MethodologyStep: FC<TMethodologyStepProps> = ({ nodeId }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { serverId, repositoryId, id } = nodeId;
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const descriptor: ArisImportDescriptor = useSelector(
        ImportArisSelectors.getArisDescriptor(serverId, repositoryId, id),
    );
    const { arisData } = descriptor;
    const modelTypesSila: ModelType[] = useSelector(ModelTypeSelectors.getAllModelTypes(serverId, presetId));
    const symbolsSila: Symbol[] = useSelector(SymbolSelectors.byServerIdPresetId(serverId, presetId));
    const attributeTypesSila: AttributeType[] = useSelector(AttributeTypeSelectors.allInPreset(serverId, presetId));
    const objectTypesSila: ObjectType[] = useSelector(ObjectTypeSelectors.listAllByPreset(serverId, presetId));
    const edgeTypesSila: EdgeType[] = useSelector(ModelTypeSelectors.takeEdgeTypesFromModelTypes(serverId, presetId));

    useEffect(() => {
        dispatch(presetMetaDataRequest([presetId]));
    }, []);

    const setSilaId = (arisDataItem: ArisTypeMap, silaId: string): ArisTypeMap => {
        return { ...arisDataItem, silaId };
    };

    const changeArisData = (arisDataItem: ArisTypeMap, typeArisData: keyof ArisData) => {
        dispatch(
            setArisMethodologyData({
                nodeId,
                arisDataItem,
                typeArisData,
            }),
        );
    };

    const showNotDownloadOption = () => {
        return (
            <Option
                key={intl.formatMessage(messages.notDownLoadOption)}
                value={intl.formatMessage(messages.notDownLoadOption)}
            >
                {intl.formatMessage(messages.notDownLoadOption)}
            </Option>
        );
    };

    const getSilaCellTableData = (
        arisItem: ArisTypeMap,
        silaDataArrayTypes: Array<ModelType | Symbol | AttributeType | ObjectType | EdgeType>,
        typeArisData: keyof ArisData,
    ) => {
        const selectDefaultOption = arisItem.skip
            ? intl.formatMessage(messages.notDownLoadOption)
            : intl.formatMessage(messages[DefaultOptionMessage[typeArisData] || DefaultOptionMessage['modelTypes']]);
        const foundModelType = silaDataArrayTypes.find((silaDataType) => silaDataType.id === arisItem.silaId);

        const onSelectTypeSila = (value: string) => {
            const selectedTypeSila: ModelType | Symbol | AttributeType | ObjectType | EdgeType | undefined =
                silaDataArrayTypes.find((silaDataArrayType) => silaDataArrayType.name === value);

            if (value === intl.formatMessage(messages.notDownLoadOption)) {
                const changedTypeAris: ArisTypeMap = { ...arisItem, skip: true };
                changeArisData(changedTypeAris, typeArisData);
            }

            if (selectedTypeSila && arisItem.arisId) {
                const changedTypeAris: ArisTypeMap = {
                    ...setSilaId(arisItem, selectedTypeSila.id),
                    skip: false,
                };
                changeArisData(changedTypeAris, typeArisData);
            }
        };

        if (foundModelType && !arisItem.skip) {
            return (
                <Select
                    size="small"
                    bordered={false}
                    className={style.select}
                    showSearch
                    defaultValue={foundModelType.name}
                    onSelect={onSelectTypeSila}
                >
                    {silaDataArrayTypes.map((silaDataArrayType) => {
                        if (silaDataArrayType.name === intl.formatMessage(messages.notDownLoadOption)) {
                            return (
                                <Option
                                    className={style.option}
                                    key={silaDataArrayType.id}
                                    value={silaDataArrayType.name}
                                >
                                    {silaDataArrayType.name}
                                </Option>
                            );
                        }

                        return (
                            <Option key={silaDataArrayType.id} value={silaDataArrayType.name}>
                                {silaDataArrayType.name}
                            </Option>
                        );
                    })}
                    {showNotDownloadOption()}
                </Select>
            );
        }

        return (
            <Select
                size="small"
                className={style.select_required}
                bordered={false}
                showSearch
                defaultValue={selectDefaultOption}
                onSelect={onSelectTypeSila}
            >
                {silaDataArrayTypes.map((silaDataArrayType) => {
                    return (
                        <Option key={silaDataArrayType.id} value={silaDataArrayType.name}>
                            {silaDataArrayType.name}
                        </Option>
                    );
                })}
                {showNotDownloadOption()}
            </Select>
        );
    };

    const tableDataColumnGenerate = (tableDataArisType: TTableDataArisType, tableDataSilaType: TTableDataSilaType) => {
        return [
            {
                dataIndex: tableDataArisType,
                width: '50%',
            },
            {
                dataIndex: tableDataSilaType,
                width: '50%',
            },
        ];
    };

    const arisModelTypesDataColumn = tableDataColumnGenerate('modelTypeAris', 'modelTypeSila');
    const modelTypesArisDataSource = arisData?.modelTypes?.map((modelTypeAris) => {
        return {
            key: uuid(),
            modelTypeAris: getArisCellTableData(modelTypeAris, intl, DefaultOptionMessage.modelTypes),
            modelTypeSila: getSilaCellTableData(modelTypeAris, modelTypesSila, 'modelTypes'),
        };
    });

    const arisObjectTypesDataColumn = tableDataColumnGenerate('objectTypeAris', 'objectTypeSila');
    const objectTypesArisDataSource = arisData?.objectTypes?.map((objectTypeAris) => {
        return {
            key: uuid(),
            objectTypeAris: getArisCellTableData(objectTypeAris, intl, DefaultOptionMessage.objectTypes),
            objectTypeSila: getSilaCellTableData(objectTypeAris, objectTypesSila, 'objectTypes'),
        };
    });

    const arisSymbolsDataColumn = tableDataColumnGenerate('symbolsAris', 'symbolsSila');
    const getSymbolsArisDataSource = (symbols: ArisSymbolMap[] | undefined) => {
        return symbols?.map((symbol) => {
            return {
                key: uuid(),
                symbolsAris: getArisCellTableData(symbol, intl, DefaultOptionMessage.symbols),
                symbolsSila: getSilaCellTableData(symbol, symbolsSila, 'symbols'),
            };
        });
    };

    const arisEdgeTypesDataColumn = tableDataColumnGenerate('edgeAris', 'edgesSila');
    const edgeTypesArisDataSource = arisData?.edgeTypes?.map((edgeType) => {
        return {
            key: uuid(),
            edgeAris: getArisCellTableData(edgeType, intl, DefaultOptionMessage.edgeTypes),
            edgesSila: getSilaCellTableData(edgeType, edgeTypesSila, 'edgeTypes'),
        };
    });

    const arisAttributeTypesDataColumn = tableDataColumnGenerate('attributeAris', 'attributeSila');
    const attributeTypesTypesArisDataSource = arisData?.attributeTypes?.map((attributeType) => {
        return {
            key: uuid(),
            attributeAris: getArisCellTableData(attributeType, intl, DefaultOptionMessage.attributeTypes),
            attributeSila: getSilaCellTableData(attributeType, attributeTypesSila, 'attributeTypes'),
        };
    });

    const symbolsWithoutObject: ArisSymbolMap[] | undefined = getSymbolsWithoutObject(
        arisData?.symbols,
        arisData?.objectTypes,
    );

    return (
        <div className={style.container}>
            {arisData?.modelTypes && (
                <div className={style.typesBlock}>
                    {intl.formatMessage(messages.modelsMap)}:
                    <Table
                        className={style.table}
                        pagination={false}
                        showHeader={false}
                        dataSource={modelTypesArisDataSource}
                        columns={arisModelTypesDataColumn}
                        tableLayout="fixed"
                    />
                </div>
            )}
            {!!arisData?.objectTypes?.length &&
                objectTypesArisDataSource &&
                arisData.objectTypes.map((type, ind) => {
                    const objectSymbols: ArisSymbolMap[] | undefined = getSymbolsOfObject(
                        arisData?.symbols,
                        type.arisId,
                    );
                    return (
                        <div className={style.typesBlock}>
                            {intl.formatMessage(messages.objectsMap, { arisId: type.arisId })}
                            <Table
                                className={style.table}
                                pagination={false}
                                showHeader={false}
                                dataSource={[objectTypesArisDataSource[ind]]}
                                columns={arisObjectTypesDataColumn}
                                tableLayout="fixed"
                            />
                            {!!objectSymbols?.length && (
                                <div className={style.typesBlock}>
                                    {intl.formatMessage(messages.symbolsMap, { arisId: type.arisId })}:
                                    <Table
                                        className={style.table}
                                        pagination={false}
                                        showHeader={false}
                                        dataSource={getSymbolsArisDataSource(objectSymbols)}
                                        columns={arisSymbolsDataColumn}
                                        tableLayout="fixed"
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            {!!symbolsWithoutObject?.length && (
                <div className={style.typesBlock}>
                    {intl.formatMessage(messages.symbolsMap)}:
                    <Table
                        className={style.table}
                        pagination={false}
                        showHeader={false}
                        dataSource={getSymbolsArisDataSource(symbolsWithoutObject)}
                        columns={arisSymbolsDataColumn}
                        tableLayout="fixed"
                    />
                </div>
            )}
            {arisData?.edgeTypes && (
                <div className={style.typesBlock}>
                    {intl.formatMessage(messages.edgesMap)}:
                    <Table
                        className={style.table}
                        pagination={false}
                        showHeader={false}
                        dataSource={edgeTypesArisDataSource}
                        columns={arisEdgeTypesDataColumn}
                        tableLayout="fixed"
                    />
                </div>
            )}
            {arisData?.attributeTypes && (
                <div className={style.typesBlock}>
                    {intl.formatMessage(messages.attributesMap)}:
                    <Table
                        className={style.table}
                        pagination={false}
                        showHeader={false}
                        dataSource={attributeTypesTypesArisDataSource}
                        columns={arisAttributeTypesDataColumn}
                        tableLayout="fixed"
                    />
                </div>
            )}
        </div>
    );
};

export default MethodologyStep;
