import * as React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ShapeStyle } from '../../../../models/Symbols.constants';
import theme from './ShapeSelect.scss';
import messages from './ShapeSelect.messages';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import icShape from '../../../../resources/icons/icShape.svg';
import iconRound from '../../../../resources/icons/toolbar/shape/icons8-round.svg';
import iconRectangle from '../../../../resources/icons/toolbar/shape/rectangle.svg';
import iconTriangle from '../../../../resources/icons/toolbar/shape/triangle.svg';
import iconCloud from '../../../../resources/icons/toolbar/shape/cloud.svg';
import iconPolygon from '../../../../resources/icons/toolbar/shape/polygon.svg';
import iconDoubleCircle from '../../../../resources/icons/toolbar/shape/disc.svg';
import iconRhombus from '../../../../resources/icons/toolbar/shape/rhombus.svg';
import iconSwimLane from '../../../../resources/icons/toolbar/shape/display.svg';
import { useClickOutside } from '../../../UIKit/components/Select/useClickOutside';

interface IShapeSelectProps {
    onSelect: (value: string) => void;
    isActiveScheme?: boolean;
}

type TShapeSelectPropsWidthIntl = IShapeSelectProps & WrappedComponentProps;

const ShapeSelectComponent: FC<TShapeSelectPropsWidthIntl> = (props: TShapeSelectPropsWidthIntl) => {
    const menu = (
        <Menu onClick={(e: MenuInfo) => props.onSelect(e.key)}>
            <Menu.Item key={ShapeStyle.RECTANGLE}>
                <Icon spriteSymbol={iconRectangle} className={theme.symbol} />
                {props.intl.formatMessage(messages.rectangle)}
            </Menu.Item>
            <Menu.Item key={ShapeStyle.ELLIPSE}>
                <Icon spriteSymbol={iconRound} className={theme.symbol} />
                {props.isActiveScheme ? props.intl.formatMessage(messages.ellipse) : ''}
            </Menu.Item>
            <Menu.Item key={ShapeStyle.TRIANGLE}>
                <Icon spriteSymbol={iconTriangle} className={theme.symbol} />
                {props.isActiveScheme ? props.intl.formatMessage(messages.triangle) : ''}
            </Menu.Item>
            <Menu.Item key={ShapeStyle.CLOUD}>
                <Icon spriteSymbol={iconCloud} className={theme.symbol} />
                {props.isActiveScheme ? props.intl.formatMessage(messages.cloud) : ''}
            </Menu.Item>
            <Menu.Item key={ShapeStyle.HEXAGON}>
                <Icon spriteSymbol={iconPolygon} className={theme.symbol} />
                {props.isActiveScheme ? props.intl.formatMessage(messages.hexagon) : ''}
            </Menu.Item>
            <Menu.Item key={ShapeStyle.DOUBLE_ELLIPSE}>
                <Icon spriteSymbol={iconDoubleCircle} className={theme.symbol} />
                {props.isActiveScheme ? props.intl.formatMessage(messages.doubleEllipse) : ''}
            </Menu.Item>
            <Menu.Item key={ShapeStyle.RHOMBUS}>
                <Icon spriteSymbol={iconRhombus} className={theme.symbol} />
                {props.isActiveScheme ? props.intl.formatMessage(messages.rhombus) : ''}
            </Menu.Item>
            <Menu.Item key={ShapeStyle.SWIM_LANE}>
                <Icon spriteSymbol={iconSwimLane} className={theme.symbol} />
                {props.isActiveScheme ? props.intl.formatMessage(messages.swimLane) : ''}
            </Menu.Item>
        </Menu>
    );
    const containerRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
    const [isOpenDropdownMenu, setIsOpenDropdownMenu] = React.useState<boolean>(false);

    const onClick = () => {
        setIsOpenDropdownMenu((prevState) => !prevState);
    };

    useClickOutside(containerRef, () => setIsOpenDropdownMenu(false));

    return (
            <Dropdown destroyPopupOnHide overlay={menu} trigger={['click']} open={isOpenDropdownMenu}>
                <Button ref={containerRef} onClick={onClick}>
                    <Icon spriteSymbol={icShape} />
                    {props.isActiveScheme ? props.intl.formatMessage(messages.shapes) : ''} <DownOutlined />
                </Button>
            </Dropdown>
    );
};

const ShapeSelectWithIntl = injectIntl(ShapeSelectComponent);

export { ShapeSelectWithIntl as ShapeSelect };
