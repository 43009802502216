import type { MxCell } from 'MxGraph';
import { SymbolTypeId, FRAME_STYLES_INDICATOR } from '../ComplexSymbol.constants';
import { getPoolSymbolData } from './sideEffects';
import { HorizontalSwimLaneSymbol } from './HorizontalSwimLaneSymbol.class';
import createComplexSymbol from '../createComplexSymbol';
import PoolSymbol from './PoolSymbol.class';

export class HorizontalPoolSymbol extends PoolSymbol {
    complexSymbolTypeId = SymbolTypeId.HORIZONTAL_POOL;

    addSwimlanes(parent: MxCell) {
        const { graph } = this;
        const swimlanes = getPoolSymbolData(graph, this.rootCellValue);
        swimlanes.forEach((swimlaneRootValue) => {
            const rootCellValue = {
                ...swimlaneRootValue,
                parent: parent.getId(),
            };

            createComplexSymbol(HorizontalSwimLaneSymbol, { rootCellValue, graph });
        });
    }

    insert(): MxCell {
        const { graph } = this;
        const [width, height] = [480, 360];
        const { x = 0, y = 0, parent = graph.getDefaultParent() } = this.rootCellValue;
        const style = `${FRAME_STYLES_INDICATOR};swimlane;html=1;childLayout=stackLayout;resizeParent=1;resizeParentMax=0;horizontal=0;startSize=30;horizontalStack=0;`;
        const pool = graph.insertVertexFromValue({
            ...this.rootCellValue,
            parent,
            x,
            y,
            width,
            height,
            style,
        });

        return pool;
    }

    restore(): MxCell {
        const { graph } = this;
        const { x = 0, y = 0, width = 480, height = 360, parent = graph.getDefaultParent() } = this.rootCellValue;
        const style =
            this.rootCellValue.style ||
            `${FRAME_STYLES_INDICATOR};swimlane;html=1;childLayout=stackLayout;resizeParent=1;resizeParentMax=0;horizontal=0;startSize=30;horizontalStack=0;`;
        const pool = graph.insertVertexFromValue({
            ...this.rootCellValue,
            parent,
            x,
            y,
            width,
            height,
            style,
        });

        return pool;
    }
}
