import * as React from 'react';
import { FC } from 'react';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { PrincipalType } from '../../../../models/security/PrincipalType';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import icPersonal from '../../../../resources/icons/ic-personal.svg';
import icGroup from '../../../../resources/icons/ic-group.svg';
import theme from './PrincipalLabel.scss';

type TPrincipalLabelProps = PrincipalDescriptor & JSX.IntrinsicAttributes;

const PrincipalLabel: FC<TPrincipalLabelProps> = ({ name, principalType: type, login }) => {
    const icon = type === PrincipalType.USER ? icPersonal : icGroup;

    let nameStr: string;
    if (type === PrincipalType.USER) {
        nameStr = name == null || name === '' ? (login || '') : `${login} (${name})`;
    } else {
        nameStr = (login || '');
    }

    return (
        <span>
            <Icon className={theme.principalIcon} spriteSymbol={icon} /> {nameStr}{' '}
        </span>
    );
};

export default PrincipalLabel;
