import * as React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { Table, Popover, Progress } from 'antd';
import theme from './ScriptExecutionsTable.scss';
import messages from '../../messages/ScriptDashboardTable.messages';
import statusMessages from '../../../App/messages/AppScriptExecutionStatuses.messages';
import { isUndefined } from 'is-what';
import icStatusFail from '../../../../resources/icons/ic-script-exec-fail.svg';
import icStatusSuccess from '../../../../resources/icons/ic-script-exec-success.svg';
import icStatusStopped from '../../../../resources/icons/ic-script-exec-stopped.svg';
import icStatusRunning from '../../../../resources/icons/ic-script-exec-running.svg';
import icStatusWait from '../../../../resources/icons/ic-script-exec-wait.svg';
import icEdit from '../../../../resources/icons/ic-edit.svg';
import dots from '../../../../resources/icons/dotsGrey.svg';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import {
    NextStepParameters,
    NodeId,
    OperationDataStatusEnum,
    OperationProgress,
    PercentOperationProgress,
    ScriptOperationData,
} from '../../../../serverapi/api';
import { isEqual } from 'lodash-es';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { ColumnProps } from 'antd/es/table';

type TScriptExecutionsTableProps = {
    data: ScriptOperationData[];
    serverId: string;
    downloadFile: (fileId: NodeId) => void;
    downloadLogFile: (scriptExecutionId: string) => void;
    runScriptStep: (operationId: string, serverId: string) => void;
    openScript: (scriptId: NodeId) => void;
} & JSX.IntrinsicAttributes;

type TScriptExecutionsTableAllProps = WrappedComponentProps & TScriptExecutionsTableProps;

type TScriptExecutionsTableState = {
    data: ScriptOperationData[];
};

type TParameter = {
    name: string;
    value: string;
};

type TColumnDataItem = Record<string, React.ReactNode | Date>;

class ScriptExecutionsTable extends React.Component<TScriptExecutionsTableAllProps, TScriptExecutionsTableState> {
    firstLoad: boolean = true;

    columns: TColumn[];
    parametersColumns: ColumnProps<TParameter>[];

    constructor(props: TScriptExecutionsTableAllProps) {
        super(props);

        this.columns = [
            {
                title: props.intl.formatMessage(messages.scriptColumnName),
                dataKey: 'name',
                withoutSorter: true,
            },
            {
                width: 130,
                title: props.intl.formatMessage(messages.statusColumnName),
                dataKey: 'statusLabel',
                withoutSorter: true,
            },
            {
                width: 160,
                title: props.intl.formatMessage(messages.startDateColumnName),
                dataKey: 'createdDateStr',
                withoutSorter: true,
            },
            {
                width: 160,
                title: props.intl.formatMessage(messages.finishDateColumnName),
                dataKey: 'finishedDateStr',
                withoutSorter: true,
            },
            {
                width: 120,
                title: props.intl.formatMessage(messages.resultColumnName),
                dataKey: 'scripExecutionId',
                withoutSorter: true,
            },
            {
                width: 110,
                title: props.intl.formatMessage(messages.parameters),
                dataKey: 'parameters',
                withoutSorter: true,
            },
            {
                width: 120,
                title: props.intl.formatMessage(messages.stepDialogColumnName),
                dataKey: 'nextStep',
                withoutSorter: true,
            },
            {
                width: 100,
                title: props.intl.formatMessage(messages.stepColumnName),
                dataKey: 'step',
                withoutSorter: true,
            },
            {
                width: 100,
                title: props.intl.formatMessage(messages.logColumnName),
                dataKey: 'download',
                withoutSorter: true,
            },
        ];

        this.parametersColumns = [
            {
                width: 150,
                title: props.intl.formatMessage(messages.paramNameColumnName),
                dataIndex: 'name',
            },
            {
                width: 300,
                title: props.intl.formatMessage(messages.paramValueColumnName),
                dataIndex: 'value',
            },
        ];

        this.state = { data: props.data };
    }

    renderParamsColumn(value: TParameter[]) {
        if (value.length) {
            return (
                // tslint:disable-line
                <Popover
                    trigger="hover"
                    placement="left"
                    content={
                        // tslint:disable-line
                        <Table
                            rowKey={(record: TParameter) => `${record.name}`}
                            columns={this.parametersColumns}
                            dataSource={value}
                            size="small"
                            className={theme.table}
                            bordered
                            pagination={false}
                        />
                    }
                >
                    <div className={theme.params}>
                        <Icon className={theme.iconParams} spriteSymbol={dots} />
                    </div>
                </Popover>
            );
        }

        return (
            <div>
                <FormattedMessage {...messages.noParameters} />
            </div>
        );
    }

    renderStringColumn(value: string) {
        return (
            <div>
                <span>{value}</span>
            </div>
        );
    }

    renderScriptColumn(value: string | undefined, scriptId: NodeId) {
        return (
            <span className={theme.script_name} onClick={() => this.props.openScript(scriptId)}>
                <span>{value}</span>
                <div className={theme.icon_edit}>
                    <span>
                        <Icon spriteSymbol={icEdit} />
                    </span>
                </div>
            </span>
        );
    }

    renderStepColumn(value: string | undefined) {
        return (
            <div>
                <span>{value}</span>
            </div>
        );
    }

    renderStepDialogColumn(nextStep: NextStepParameters | undefined, scripExecutionId: string | undefined) {
        const { serverId } = this.props;
        if (!nextStep) {
            return (
                <Button size="small" disabled>
                    <FormattedMessage {...messages.noData} />
                </Button>
            );
        }

        return (
            <div>
                <Button
                    size="small"
                    onClick={() => {
                        this.props.runScriptStep(scripExecutionId!, serverId);
                    }}
                >
                    <FormattedMessage {...messages.openStepDialog} />
                </Button>
            </div>
        );
    }

    renderStatusColumn(
        value: string,
        status: OperationDataStatusEnum | undefined,
        operationProgress: OperationProgress | undefined,
        statusIcon: TSpriteSymbol | undefined,
    ) {
        if (status === 'RUN' && operationProgress && operationProgress.type === 'PERCENTS') {
            return (
                <div>
                    <span>
                        <Progress
                            type="circle"
                            percent={Math.floor((operationProgress as PercentOperationProgress).percents || 0)}
                            size={25}
                        />
                        &nbsp;
                        {value}
                    </span>
                </div>
            );
        }

        return (
            <div>
                <span>
                    <Icon className={theme.icon} spriteSymbol={statusIcon} />
                    &nbsp;
                    {value}
                </span>
            </div>
        );
    }

    renderDownloadColumn(executionResult: NodeId | undefined) {
        const { serverId } = this.props;
        if (!executionResult) {
            return (
                <Button size="small" disabled>
                    <FormattedMessage {...messages.noData} />
                </Button>
            );
        }

        return (
            <Button
                size="small"
                onClick={() => {
                    this.props.downloadFile({ ...executionResult, serverId });
                }}
            >
                <FormattedMessage {...messages.download} />
            </Button>
        );
    }

    noDataButton() {
        return (
            <Button size="small" disabled>
                <FormattedMessage {...messages.noData} />
            </Button>
        );
    }

    tableButton(onClick: () => void) {
        return (
            <Button size="small" onClick={onClick}>
                <FormattedMessage {...messages.download} />
            </Button>
        );
    }

    renderLogDownloadColumn(scripExecutionId: string | undefined) {
        if (!scripExecutionId) {
            return this.noDataButton();
        }

        return this.tableButton(() => this.props.downloadLogFile(scripExecutionId));
    }

    componentDidMount() {
        if (this.firstLoad) {
            this.firstLoad = false;
            const { data } = this.state;
            this.setState({ data });
        }
    }

    componentDidUpdate({ data }: Readonly<TScriptExecutionsTableAllProps>): void {
        if (!isEqual(this.state.data, data)) {
            this.setState({ data });
        }
    }

    render() {
        const { intl, serverId } = this.props;
        const { data } = this.state;

        const statuses = {
            WAIT: intl.formatMessage(statusMessages.appScriptExecutionStatusWait),
            RUN: intl.formatMessage(statusMessages.appScriptExecutionStatusRunning),
            SUCCESS: intl.formatMessage(statusMessages.appScriptExecutionStatusSuccess),
            FAIL: intl.formatMessage(statusMessages.appScriptExecutionStatusFail),
            CANCEL: intl.formatMessage(statusMessages.appScriptExecutionStatusStopped),
        };

        const statusesIcons = {
            WAIT: icStatusWait,
            RUN: icStatusRunning,
            SUCCESS: icStatusSuccess,
            FAIL: icStatusFail,
            CANCEL: icStatusStopped,
        };

        let columnsData =
            data &&
            data.map((e) => {
                const {
                    status,
                    progress: operationProgress,
                    result: executionResult,
                    nextStep,
                    id: scripExecutionId,
                    scriptName,
                    startDate,
                    stopDate,
                    step,
                } = e;

                const scriptId = { id: e.scriptId, repositoryId: 'script-root-id', serverId } as NodeId;

                const statusIcon = statusesIcons[status!];

                let parameters: TParameter[] = [];
                if (e.parameters) {
                    parameters = e.parameters.map((p) => ({ name: p.name, value: p.value } as TParameter));
                }

                const item: TColumnDataItem = {};

                item.name = this.renderScriptColumn(scriptName, scriptId);
                item.statusLabel = this.renderStatusColumn(statuses[status!], status, operationProgress, statusIcon);
                item.createdDate = new Date(startDate!);
                item.createdDateStr = this.renderStringColumn(new Date(startDate!).toLocaleString());
                item.finishedDateStr = this.renderStringColumn(
                    isUndefined(stopDate) || stopDate == null
                        ? intl.formatMessage(messages.noData)
                        : new Date(stopDate!).toLocaleString(),
                );
                item.scripExecutionId = this.renderDownloadColumn(executionResult);
                item.parameters = this.renderParamsColumn(parameters);
                item.nextStep = this.renderStepDialogColumn(nextStep, scripExecutionId);
                item.step = this.renderStepColumn(step);
                item.download = this.renderLogDownloadColumn(scripExecutionId);

                return item;
            });

        columnsData =
            columnsData &&
            columnsData.sort((n1, n2) => {
                return ((n2.createdDate as Date)?.getTime() || 0) - ((n1.createdDate as Date)?.getTime() || 0);
            });

        return (
            <div className={theme.container}>
                <TableUIKit columns={this.columns} tableData={columnsData as TTableData[]} />
            </div>
        );
    }
}

const ScriptExecutionsTableWithIntl = injectIntl(ScriptExecutionsTable);

export { ScriptExecutionsTableWithIntl as ScriptExecutionsTable };
