import { defineMessages } from 'react-intl';

export default defineMessages({
    createTitle: {
        id: 'GroupCreateDialog.createTitle',
        defaultMessage: 'Новая группа типов {element}',
    },
    editTitle: {
        id: 'GroupCreateDialog.editTitle',
        defaultMessage: 'Редактирование группы типов {element}',
    },
    newGroup: {
        id: 'GroupCreateDialog.newGroup',
        defaultMessage: 'Новая группа',
    },
    name: {
        id: 'GroupCreateDialog.name',
        defaultMessage: 'Название',
    },
    description: {
        id: 'GroupCreateDialog.description',
        defaultMessage: 'Описание',
    },
    cancel: {
        id: 'GroupCreateDialog.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'GroupCreateDialog.save',
        defaultMessage: 'Сохранить',
    },
    create: {
        id: 'GroupCreateDialog.create',
        defaultMessage: 'Создать',
    }
});
