import React, { forwardRef, useState } from 'react';
import theme from './WikiToolbar.scss';
import classnames from 'classnames';
import { Row } from 'antd';
import icDots from '../../../../../resources/icons/dots.svg';
import icDotsBlue from '../../../../../resources/icons/dotsBlue.svg';
import {
    ControlsContext,
    BGColorComponent,
    FontFamilyComponent,
    FontSizeComponent,
    FontColorComponent,
    InlineStyleComponent,
    TextAlignComponent,
    IndentComponent,
    ListsComponent,
    LinkComponent,
    ImageComponent,
    TableComponent,
    EraserComponent,
} from '@/modules/UIKit/components/RichTextEditor/Toolbar';
import getStateObserver from '@/modules/UIKit/components/RichTextEditor/common/sharedState.class';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TWikiEditorToolbarForWidgetOwnProps = {
    id: string;
    handlers: {
        openImageDialog: (onSubmit: any) => void;
        openLinkDialog: (onSubmit: any) => void;
        uploadFile: (file: File, fileId: string) => { src: string };
    };
};

type TWikiEditorToolbarForWidgetProps = React.ForwardRefExoticComponent<
    Omit<TWikiEditorToolbarForWidgetOwnProps, 'ref'> & React.RefAttributes<HTMLDivElement | null>
>;

const WikiEditorToolbarForWidget: TWikiEditorToolbarForWidgetProps = forwardRef(
    (props: TWikiEditorToolbarForWidgetOwnProps, ref) => {
        const stateObserverStateObserver = getStateObserver(props.id);
        const { openImageDialog, openLinkDialog, uploadFile } = props.handlers;
        const [popupOpen, setPopupOpen] = useState<boolean>(false);

        return (
            <div
                ref={ref}
                className={classnames(theme.container, popupOpen ? theme.popupOpen : '')}
                data-test="wiki-dashboard-toolbar-group"
            >
                {stateObserverStateObserver && (
                    <ControlsContext.Provider value={stateObserverStateObserver}>
                        <Row wrap={false} className={theme.row} onClick={() => setPopupOpen(false)}>
                            <div className={theme.controlsGroup}>
                                <FontFamilyComponent className={theme.fontFamily} />
                            </div>
                            <div className={theme.controlsGroup}>
                                <FontSizeComponent />
                            </div>
                            <div className={theme.controlsGroup}>
                                <FontColorComponent />
                                <BGColorComponent />
                            </div>
                            <div className={theme.controlsGroup}>
                                <InlineStyleComponent hideMoreStyles />
                            </div>
                            <div className={theme.controlsGroup}>
                                <TextAlignComponent />
                                <IndentComponent />
                            </div>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPopupOpen((value) => !value);
                                }}
                                dataTest="wiki-toolbar-group_popup-button"
                                icon={popupOpen ? icDotsBlue : icDots}
                            />
                        </Row>
                        {popupOpen && (
                            <Row wrap={false} className={theme.row}>
                                <div className={theme.controlsGroup}>
                                    <ListsComponent hideIndent />
                                </div>
                                <div className={theme.controlsGroup}>
                                    <LinkComponent openDialog={openLinkDialog} />
                                    <ImageComponent openDialog={openImageDialog} uploadFile={uploadFile} />
                                    <TableComponent />
                                </div>
                                <div className={theme.controlsGroup} data-test="wiki-toolbar-group_eraser-button">
                                    <EraserComponent />
                                </div>
                            </Row>
                        )}
                    </ControlsContext.Provider>
                )}
            </div>
        );
    },
);

export default WikiEditorToolbarForWidget;
