import React, { ChangeEvent } from 'react';
import { Input, Form } from 'antd';
import theme from './SelectLocalizedValues.scss';
import messages from '../../../messages/Presets.messages';
import { TSelectDataTypeValue } from './SelectLocalizedValues.types';
import { AttributeTypeSelectPropertyValue } from '../../../../../../serverapi/api';
import { useIntl } from 'react-intl';

type TColumnProps = {
    value: string;
    selectValue: TSelectDataTypeValue;
    dataSource: AttributeTypeSelectPropertyValue[];
    dataIndex: 'id' | 'en' | 'ru';
    setDataSource: (dataSource: AttributeTypeSelectPropertyValue[]) => void;
};

export const ColumnRender = (props: TColumnProps) => {
    const { value, selectValue, dataSource, dataIndex, setDataSource } = props;
    const intl = useIntl();
    const isIdValue = dataIndex === 'id';
    const otherLocal = dataIndex === 'ru' ? 'en' : 'ru';
    const localDependencie: string = `${otherLocal}:${selectValue.idx}`;
    const idDependencie: string[] = dataSource.map((_, id) => `id:${id}`);
    const dependencies: string[] = isIdValue ? idDependencie : [localDependencie];

    const idValidator = (_, val) => {
        const previousIds = dataSource.filter((attrValue, id) => id !== selectValue.idx).map((d) => d.id);

        if (val && previousIds.includes(val)) {
            return Promise.reject(intl.formatMessage(messages.attributeUniqueValueId));
        }

        return Promise.resolve();
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDataSource(
            dataSource.map((data, id) => {
                if (id === selectValue.idx) {
                    return isIdValue
                        ? { ...data, id: e.target.value }
                        : { ...data, value: { ...data.value, [dataIndex]: e.target.value } };
                }

                return data;
            }),
        );
    };

    return (
        <Form.Item
            name={`${dataIndex}:${selectValue.idx}`}
            className={theme.margin0}
            initialValue={value}
            dependencies={dependencies}
            rules={[
                {
                    whitespace: true,
                    required: isIdValue ? true : !selectValue[otherLocal],
                    message: intl.formatMessage(messages.requiredField),
                },
                () => ({validator: isIdValue ? idValidator : undefined}),
            ]}
        >
            <Input
                data-test="attribute_multi-select_cell-value_input"
                onChange={onChange}
                className={theme.selectInput}
            />
        </Form.Item>
    );
};
