import { DROP_TARGET_ATTRIBUTE, FIXED_STYLE_INDICATOR, FRAME_STYLES_INDICATOR } from '../ComplexSymbol.constants';
import { getGUIDdArray } from '../../ComplexSymbol.utils';

export const frameNodeData = () =>
    JSON.stringify({
        __descriptor_version__: '1',
        format: '{0}: {1}',
        params: [
            { paramSource: 'locale', messageId: 'ComplexSymbol.idefNode', defaultValue: '' },
            { paramSource: 'frame', attributesIds: ['object_decomposition_number'], defaultValue: '' },
        ],
    }).replace(/\"/g, "'");

export const frameTitleData = () =>
    JSON.stringify({
        __descriptor_version__: '1',
        format: '{0}: {1}',
        params: [
            { paramSource: 'locale', messageId: 'ComplexSymbol.idefTitle', defaultValue: '' },
            { paramSource: 'frame', attributesIds: ['name'], defaultValue: '' },
        ],
    }).replace(/\"/g, "'");

export const frameNumberData = () =>
    JSON.stringify({
        __descriptor_version__: '1',
        format: '{0}: {1}',
        params: [
            { paramSource: 'locale', messageId: 'ComplexSymbol.idefNumber', defaultValue: '' },
            { paramSource: 'frame', attributesIds: ['serial_number'], defaultValue: '' },
        ],
    }).replace(/\"/g, "'");

export const getIDEFFrameDiagramTemplate = () => {
    const u = getGUIDdArray(12);

    return `
      <mxGraphModel>;
        <root>
          <mxCell id="${u[0]}" value="" />
          <mxCell id="${u[1]}" value="" parent="${u[0]}" />
          <mxCell id="${
              u[2]
          }" value="" frameType="frame.type.idef" style="${FRAME_STYLES_INDICATOR};shape=table;rotatable=0;editable=0;movable=1;html=1;whiteSpace=wrap;startSize=0;collapsible=0;childLayout=tableLayout;strokeWidth=2;" vertex="1" parent="${
        u[1]
    }">
            <mxGeometry width="1000" height="600" as="geometry" />
          </mxCell>
          <mxCell id="${
              u[3]
          }" value="" style="${FIXED_STYLE_INDICATOR};deletable=0;movable=0;editable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=tableRow;horizontal=0;startSize=0;top=0;left=0;bottom=0;right=0;collapsible=0;fillColor=none;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="${
        u[2]
    }">
            <mxGeometry width="1000" height="564" as="geometry" />
          </mxCell>
          <mxCell id="${
              u[4]
          }" value="" style="${FIXED_STYLE_INDICATOR};deletable=0;movable=0;shape=swimlane;startSize=0;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=0;rowspan=1;colspan=3;" vertex="1" parent="${
        u[3]
    }">
            <mxGeometry width="1000" height="564" as="geometry">
              <mxRectangle width="332" height="564" as="alternateBounds" />
            </mxGeometry>
          </mxCell>
          <mxCell id="${
              u[5]
          }" value="" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=0;" vertex="1" visible="0" parent="${
        u[3]
    }">
            <mxGeometry x="332" width="336" height="564" as="geometry" />
          </mxCell>
          <mxCell id="${
              u[6]
          }" value="" style="deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=0;" vertex="1" visible="0" parent="${
        u[3]
    }">
            <mxGeometry x="668" width="332" height="564" as="geometry" />
          </mxCell>
          <mxCell id="${
              u[7]
          }" value="" style="${FIXED_STYLE_INDICATOR};deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=tableRow;horizontal=0;startSize=0;top=0;left=0;bottom=0;right=0;collapsible=0;fillColor=none;" vertex="1" parent="${
        u[2]
    }">
            <mxGeometry y="564" width="1000" height="36" as="geometry" />
          </mxCell>
          <mxCell id="${u[8]}"
            value="${frameNodeData()}"
            style="${FIXED_STYLE_INDICATOR};deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=0;align=left;spacingLeft=4;"
            vertex="1" parent="${u[7]}">
            <mxGeometry width="332" height="36" as="geometry" />
          </mxCell>
          <mxCell id="${u[9]}"
            value="${frameTitleData()}"
            style="${FIXED_STYLE_INDICATOR};deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=0;align=left;spacingLeft=4"
            vertex="1" parent="${u[7]}">
            <mxGeometry x="332" width="336" height="36" as="geometry" />
          </mxCell>
          <mxCell id="${u[10]}"
            value="${frameNumberData()}"
            style="${FIXED_STYLE_INDICATOR};deletable=0;movable=0;${DROP_TARGET_ATTRIBUTE}=0;shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;overflow=hidden;fillColor=none;top=0;left=0;bottom=0;right=0;pointerEvents=0;align=left;spacingLeft=4;"
            vertex="1" parent="${u[7]}">
            <mxGeometry x="668" width="332" height="36" as="geometry" />
          </mxCell>
        </root>
      </mxGraphModel>`;
};
