import { EditorMode } from '@/models/editorMode';
import GridModelGraph from '../../GridModelGraph';
import { GridVertexHandler } from '../../handlers/GridVertexHandler.class';

export class PSDModelVertexHandler extends GridVertexHandler {
    graph: GridModelGraph;
    createCustomHandles() {
        const handles = super.createCustomHandles();

        return this.graph.mode === EditorMode.Read ? [] : handles?.filter(this.headerHandlesFilter) || [];
    }

    private headerHandlesFilter(handeler) {
        const { cell } = handeler.state;

        return cell.getParent().children.indexOf(cell) === 0;
    }
}
