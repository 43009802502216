import { defineMessages } from 'react-intl';

export default defineMessages({
    SRC_TO_DST: {
        id: 'EdgeTypeDirection.SRC_TO_DST',
        defaultMessage: 'Прямая',
    },
    NO_DIRECTION: {
        id: 'EdgeTypeDirection.NO_DIRECTION',
        defaultMessage: 'Ненаправленная',
    },
});
