// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttributeImageSizeSelector__container__mVyQO{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;padding-right:2px}.AttributeImageSizeSelector__title__fGTm7{margin-bottom:5px}.AttributeImageSizeSelector__inputContainer__FCqDa{width:75px}.AttributeImageSizeSelector__inputContainer__FCqDa>div{position:relative;width:100%}", "",{"version":3,"sources":["webpack://./src/modules/FloatingAttributes/components/AttributesEditor/AttributeStyleEditor/AttributeImageStyleEditor/AttributeImageSizeSelector.scss"],"names":[],"mappings":"AAAA,8CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,UAAA,CACA,iBAAA,CAGJ,0CACI,iBAAA,CAGJ,mDACI,UAAA,CAGJ,uDACI,iBAAA,CACA,UAAA","sourcesContent":[".container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    padding-right: 2px;\r\n}\r\n\r\n.title {\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.inputContainer {\r\n    width: 75px;\r\n}\r\n\r\n.inputContainer > div {\r\n    position: relative;\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AttributeImageSizeSelector__container__mVyQO",
	"title": "AttributeImageSizeSelector__title__fGTm7",
	"inputContainer": "AttributeImageSizeSelector__inputContainer__FCqDa"
};
export default ___CSS_LOADER_EXPORT___;
