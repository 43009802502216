import { ObjectDefinitionImpl } from '../../../models/bpm/bpm-model-impl';
import { MxCell } from '../../../mxgraph/mxgraph.d';
import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { TRootState } from '../../../reducers/root.reducer.types';
import { objectDefinitionService } from '../../../services/ObjectDefinitionService';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TRenameObjectDialogProps } from './RenameObjectDialog.types';
import { RenameObjectDialog } from './RenameObjectDialog.component';
import { BPMMxGraph } from '../../../mxgraph/bpmgraph';
import { renameObjectDialogSubmit } from '../../../actions/renameObjectDialog.actions';

type TRenameObjectDialogOwnProps = {
    open: boolean;
    graph: BPMMxGraph;
    cell: MxCell;
    value: string;
};

const mapStateToProps = (
    state: TRootState,
    { cell, graph, open }: TRenameObjectDialogOwnProps,
): Partial<TRenameObjectDialogProps> => {
    const objectDefinition: ObjectDefinitionImpl | undefined = objectDefinitionService().getObjectDefinitionByInstance(
        cell.value,
        graph.id,
    );
    if (objectDefinition) {
        const { name: value } = objectDefinition;

        return { value, objectDefinition, open };
    }

    return { open, value: cell.value };
};

const mapDispatchToProps = (dispatch, { cell }: TRenameObjectDialogOwnProps) => ({
    onClose: () => dispatch(closeDialog(DialogType.RENAME_OBJECT_DIALOG)),
    onSubmit: (objectDefinition: ObjectDefinitionImpl, newName: string) =>
        dispatch(
            renameObjectDialogSubmit({
                cell,
                objectDefinition,
                newName,
            }),
        ),
});

export const RenameObjectDialogContainer = connect(mapStateToProps, mapDispatchToProps)(RenameObjectDialog);
