import { defineMessages } from 'react-intl';

export default defineMessages({
    header: {
        id: 'ChangeEntityType.header',
        defaultMessage: 'Изменение типа элементов',
    },
    path: {
        id: 'ChangeEntityType.path',
        defaultMessage: `Путь: {path}`,
    },
    objectsAndModels: {
        id: 'ChangeEntityType.objectsAndModels',
        defaultMessage: 'Типы моделей и типы объектов',
    },
    object: {
        id: 'ChangeEntityType.object',
        defaultMessage: 'Объект',
    },
    model: {
        id: 'ChangeEntityType.model',
        defaultMessage: 'Модель',
    },
    objectTypes: {
        id: 'ChangeEntityType.objectTypes',
        defaultMessage: 'Типы объектов',
    },
    modelTypes: {
        id: 'ChangeEntityType.modelTypes',
        defaultMessage: 'Типы моделей',
    },
    searchDepthLimit: {
        id: 'ChangeEntityType.searchDepthLimit',
        defaultMessage: 'Ограничение глубины поиска: {searchDepth}',
    },
    no: {
        id: 'ChangeEntityType.no',
        defaultMessage: 'Нет',
    },
    entity: {
        id: 'ChangeEntityType.entity',
        defaultMessage: 'Сущность',
    },
    currentType: {
        id: 'ChangeEntityType.currentType',
        defaultMessage: 'Текущий тип',
    },
    newType: {
        id: 'ChangeEntityType.newType',
        defaultMessage: 'Новый тип',
    },
    dontChange: {
        id: 'ChangeEntityType.dontChange',
        defaultMessage: 'Не заменять',
    },
    search: {
        id: 'ChangeEntityType.search',
        defaultMessage: 'Начать поиск',
    },
    cancel: {
        id: 'ChangeEntityType.cancel',
        defaultMessage: 'Отменить',
    },
    replace: {
        id: 'ChangeEntityType.replace',
        defaultMessage: 'Заменить',
    },
});
