// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputId__formItem__Gr9VW .ant-form-item-label{padding-bottom:0}", "",{"version":3,"sources":["webpack://./src/modules/InputId/InputId.scss"],"names":[],"mappings":"AAEQ,+CACI,gBAAA","sourcesContent":[".formItem {\r\n    :global {\r\n        .ant-form-item-label {\r\n            padding-bottom: 0;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": "InputId__formItem__Gr9VW"
};
export default ___CSS_LOADER_EXPORT___;
