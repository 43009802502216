import React, { FC } from 'react';
import theme from './ApprovalsTab.scss';
import { Icon } from '@/modules/UIKit';
import { ApprovalStageDTOStatus, ApprovalUserDTOVote } from '@/modules/ApprovalDialog/ApprovalDialog.types';
import { ApprovalDTOStatusEnum, ApprovalStageDTO, NodeId } from '@/serverapi/api';
import icApprovalStageProgress from '../../../../resources/icons/ic-approval-stage-progress.svg';
import icApprovalStageApproved from '../../../../resources/icons/ic-approval-stage-approved.svg';
import icApprovalStageNotApproved from '../../../../resources/icons/ic-approval-stage-not-approved.svg';
import { Collapse } from 'antd';
import messages from './ApprovalsTab.messages';
import { DownOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { ApprovalStageContent } from './ApprovalStageContent.component';
import { timestampToMomentDate } from '@/utils/date.time.utils';
import classNames from 'classnames';

type TApprovalStage = {
    stage: ApprovalStageDTO;
    approvalStatus: ApprovalDTOStatusEnum;
    approvalId: NodeId;
};

export const ApprovalStage: FC<TApprovalStage> = ({ stage, approvalStatus, approvalId }) => {
    const intl = useIntl();

    const renderStageStatus = (stage: ApprovalStageDTO): React.ReactNode => {
        const scheduledFinishDate: React.ReactNode = stage.scheduledFinishDate ? (
            <div className={classNames(theme.stageDateContainer, { [theme.stageFinishDate]: !stage.status })}>
                {intl.formatMessage(messages.approvalPeriod)}{' '}
                {timestampToMomentDate(stage.scheduledFinishDate)?.format('DD.MM.YYYY')}{' '}
                {intl.formatMessage(messages.at)} {timestampToMomentDate(stage.scheduledFinishDate)?.format('HH:mm')}
            </div>
        ) : null;
        const actualFinishedDate: React.ReactNode = stage.actualFinishDate ? (
            <div className={classNames(theme.stageDateContainer, { [theme.stageFinishDate]: !stage.status })}>
                {intl.formatMessage(messages.stageFinished)}{' '}
                {timestampToMomentDate(stage.actualFinishDate)?.format('DD.MM.YYYY')} {intl.formatMessage(messages.at)}{' '}
                {timestampToMomentDate(stage.actualFinishDate)?.format('HH:mm')}
            </div>
        ) : null;

        if (!stage.status) return scheduledFinishDate;

        let status: React.ReactNode;

        switch (stage.status) {
            case ApprovalStageDTOStatus.IN_PROCESS:
                const usersCount: number = stage.approvalUsersDTO?.length || 0;
                const votedUsersCount: number =
                    stage.approvalUsersDTO?.reduce((accum, current) => {
                        return current.vote === ApprovalUserDTOVote.APPROVED ||
                            current.vote === ApprovalUserDTOVote.NOT_APPROVED
                            ? accum + 1
                            : accum;
                    }, 0) || 0;
                status = (
                    <>
                        <div className={theme.stageInfo}>
                            <span className={theme.stageInProgess}>
                                {intl.formatMessage(messages.stageInProgessLable, { votedUsersCount, usersCount })}
                            </span>
                            <Icon spriteSymbol={icApprovalStageProgress} className={theme.editIcon} />
                        </div>

                        {scheduledFinishDate}
                    </>
                );
                break;
            case ApprovalStageDTOStatus.APPROVED:
                status = (
                    <>
                        <div className={theme.stageInfo}>
                            <Icon spriteSymbol={icApprovalStageApproved} />
                            <span className={theme.stageApproved}>{intl.formatMessage(messages.approved)}</span>
                        </div>

                        {actualFinishedDate}
                    </>
                );
                break;
            case ApprovalStageDTOStatus.NOT_APPROVED:
                status = (
                    <>
                        <div className={theme.stageInfo}>
                            <Icon spriteSymbol={icApprovalStageNotApproved} />
                            <span className={theme.stageNotApproved}>{intl.formatMessage(messages.notApproved)}</span>
                        </div>

                        {actualFinishedDate}
                    </>
                );
                break;
            default:
                status = null;
                break;
        }

        return status;
    };

    const header: React.ReactNode = (
        <div className={theme.stageHeader}>
            <span className={theme.stageTitle}>{stage.name}</span>
            <div className={theme.stageStatus}>{renderStageStatus(stage)}</div>
        </div>
    );

    return stage.status === ApprovalStageDTOStatus.IN_PROCESS ? (
        <Collapse
            defaultActiveKey={stage.id}
            size="large"
            expandIcon={({ isActive }) => (
                <DownOutlined style={{ fontSize: '11px', color: '#363636' }} rotate={isActive ? 180 : 0} />
            )}
        >
            <Collapse.Panel header={header} key={stage.id}>
                <ApprovalStageContent stage={stage} approvalStatus={approvalStatus} approvalId={approvalId} />
            </Collapse.Panel>
        </Collapse>
    ) : (
        <Collapse
            size="large"
            expandIcon={({ isActive }) => (
                <DownOutlined style={{ fontSize: '11px', color: '#363636' }} rotate={isActive ? 180 : 0} />
            )}
        >
            <Collapse.Panel header={header} key={stage.id}>
                <ApprovalStageContent stage={stage} approvalStatus={approvalStatus} approvalId={approvalId} />
            </Collapse.Panel>
        </Collapse>
    );
};
