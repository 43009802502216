import { Form, Input } from 'antd';
import React from 'react';
import theme from './PasswordInput.scss';
import { useIntl } from 'react-intl';
import messages from './passwordInput.messages';
import { Rule } from 'antd/es/form';

type TPasswordInputProps = {
    formItemName: string;
    label?: string;
    placeholder?: string;
    rules?: Rule[];
};

export const PasswordInput = (props: TPasswordInputProps) => {
    const { formItemName, label, placeholder, rules = [] } = props;
    const intl = useIntl();

    return (
        <Form.Item
            label={label}
            name={formItemName}
            className={theme.formItem}
            rules={[
                ...rules,
                ...[
                    {
                        required: true,
                        whitespace: true,
                        message: intl.formatMessage(messages.EMPTY_PASSWORD),
                    },
                ],
            ]}
        >
            <Input.Password placeholder={placeholder} className={theme.input} />
        </Form.Item>
    );
};
