import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'deletedElementsTable.name',
        defaultMessage: 'Наименование',
    },
    path: {
        id: 'deletedElementsTable.path',
        defaultMessage: 'Путь до удаления',
    },
    deletedDate: {
        id: 'deletedElementsTable.deletedDate',
        defaultMessage: 'Дата удаления',
    },
    user: {
        id: 'deletedElementsTable.user',
        defaultMessage: 'Пользователь',
    },
    delete: {
        id: 'deletedElementsTable.delete',
        defaultMessage: 'Удалить навсегда',
    },
    restore: {
        id: 'deletedElementsTable.restore',
        defaultMessage: 'Восстановить',
    },
    goToElementInNavigator: {
        id: 'deletedElementsTable.goToElementInNavigator',
        defaultMessage: 'Перейти к элементу в навигаторе',
    },
});
