// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FinishStep__container__ML75U{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}.FinishStep__container__ML75U .anticon{-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}.FinishStep__icon__eclci{padding-left:5px;font-size:20px;color:#01a101}.FinishStep__title___UGRY{font-size:14px;font-weight:500;color:var(--mainColor)}", "",{"version":3,"sources":["webpack://./src/modules/Import/Aris/components/FinishStep/FinishStep.scss"],"names":[],"mappings":"AAAA,8BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,0BAAA,CAEE,uCACE,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAKN,yBACE,gBAAA,CACA,cAAA,CACA,aAAA,CAGF,0BACE,cAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  :global {\r\n    .anticon {\r\n      align-items: flex-start;\r\n    }\r\n  }\r\n}\r\n\r\n.icon {\r\n  padding-left: 5px;\r\n  font-size: 20px;\r\n  color: #01a101;\r\n}\r\n\r\n.title {\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n  color: var(--mainColor);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FinishStep__container__ML75U",
	"icon": "FinishStep__icon__eclci",
	"title": "FinishStep__title___UGRY"
};
export default ___CSS_LOADER_EXPORT___;
