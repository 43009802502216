export enum EPCGridRowType {
    CT_EXEC_CT_CAN_SUPP, //Выполняет/поддерживает
    CT_MUST_BE_INFO_ABT, //Должен быть информирован  о выполнении
    CT_MUST_BE_INFO_ON_CNC, //Должен быть информирован о нестандартном завершении
    CT_MUST_BE_INFO_ABT_RES, //Должен информировать о результате
    CT_IS_TECH_RESP, //Отвечает за техническую часть
    CT_IS_DP_RESP, //Отвечает по ИТ за
    CT_AGREES, //Принимает
    CT_DECID_ON, //Принимает решение по
    CT_CONTR_TO, //Способствует при выполнении
    CT_HAS_CONSLT_ROLE_IN, //Участвует в качестве консультанта
    OTHER, //Прочее
    CONNECTED_WITH, //Связан(а) с
}

export enum EPCGridColumnType {
    MAIN,
    ORG_UNIT,
}
