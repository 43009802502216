import { TReducer } from '../utils/types';
import {
    ADD_COMMENTS,
    CHANGE_EDITING_COMMENT,
    DELETE_EDITING_COMMENT,
    CLOSE_COMMENTS_PANEL,
    DELETE_COMMENT_SUCCESS,
    EDIT_COMMENT,
    OPEN_COMMENTS_PANEL,
    PIN_COMMENT_SUCCESS,
    UNPIN_COMMENT_SUCCESS,
    CLEAR_STATE_COMMENTS,
    SAVE_UPLOADED_FILES_SUCCESS,
    DELETED_UPLOADED_FILE_SUCCESS,
    START_EDIT_COMMENT,
    CHANGE_IS_GRAPH_ELEMENT,
} from '../actionsTypes/comments.actionTypes';
import { NodeId } from '../serverapi/api';
import { CommentState } from './comments.reducer.state';
import { ICommentsState } from './comments.reducer.types';

const initial = new CommentState();

export const commentsReducer: TReducer<ICommentsState> = (state = initial, action) => {
    switch (action.type) {
        case OPEN_COMMENTS_PANEL: {
            const {
                payload: { modelId },
            } = action;

            return state.addOpenPanel(modelId);
        }
        case CLOSE_COMMENTS_PANEL: {
            const {
                payload: { modelId },
            } = action;

            return state.removeOpenPanel(modelId);
        }
        case ADD_COMMENTS: {
            const {
                payload: { modelId: modelNodeId, comments },
            } = action;

            return state.add(modelNodeId, comments);
        }
        case EDIT_COMMENT: {
            const {
                payload: { comment },
            } = action;
            const modelId: NodeId = {
                id: comment.nodeId,
                repositoryId: comment.commentId.repositoryId,
                serverId: comment.commentId.serverId,
            };

            return state.editComment(modelId, comment);
        }
        case DELETE_COMMENT_SUCCESS: {
            const {
                payload: { modelId, commentId },
            } = action;

            return state.delete(modelId, commentId);
        }
        case CLEAR_STATE_COMMENTS: {
            const {
                payload: { modelId },
            } = action;

            return state.deleteByModelId(modelId);
        }
        case PIN_COMMENT_SUCCESS: {
            const {
                payload: { modelId, comment },
            } = action;

            return state.pin(modelId, comment);
        }
        case UNPIN_COMMENT_SUCCESS: {
            const {
                payload: { modelId, comment },
            } = action;

            return state.unpin(modelId, comment);
        }
        case START_EDIT_COMMENT: {
            const {
                payload: { modelId, parentId, commentId, threadId, showInTooltip, popupStyles },
            } = action;

            return state.createEditingComment(modelId, parentId, commentId, threadId, showInTooltip, popupStyles);
        }
        case CHANGE_EDITING_COMMENT: {
            const {
                payload: { modelId, text, files, threadId },
            } = action;

            return state.changeEditingComment(modelId, text, files, threadId);
        }
        case DELETE_EDITING_COMMENT: {
            const {
                payload: { modelId },
            } = action;

            return state.deleteEditingComment(modelId);
        }
        case SAVE_UPLOADED_FILES_SUCCESS: {
            const {
                payload: { comment, files },
            } = action;

            return state.upload(comment, files);
        }
        case DELETED_UPLOADED_FILE_SUCCESS: {
            const {
                payload: { fileId, commentId, modelId },
            } = action;

            return state.deleteFile(fileId, commentId, modelId);
        }
        case CHANGE_IS_GRAPH_ELEMENT: {
            const {
                payload: { isGraphElement, commentId, modelId },
            } = action;

            return state.changeIsGraphElement(commentId, modelId, isGraphElement);
        }
        default: {
            return state;
        }
    }
};
