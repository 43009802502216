import { RawDraftEntity } from 'draft-js';

const imageEntity = {
    IMAGE: {
        open: () => '',
        close: (entity: RawDraftEntity) => {
            let image = `<IMAGE src="${entity.data.src}"`;
            if (entity.data.alt) {
                image += ` alt="${entity.data.alt}"`;
            }

            if (entity.data.title) {
                image += ` title="${entity.data.title}"`;
            }

            if (entity.data.width) {
                image += ` width="${entity.data.width}"`;
            }

            if (entity.data.width) {
                image += ` height="${entity.data.height}"`;
            }
            image += '> </IMAGE>';

            return image;
        },
    },
};

const imageBlock = {
    IMAGE_open: (item: RawDraftEntity) =>
        <RawDraftEntity>{
            type: 'IMAGE',
            mutability: 'MUTABLE',
            data: {
                src: item.data.src,
                alt: item.data.alt,
                title: item.data.title,
                width: item.data.width,
                height: item.data.height,
            },
        },
};

export { imageEntity, imageBlock };
