import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'Simulation.name',
        defaultMessage: 'Наименование',
    },
    attribute: {
        id: 'Simulation.attribute',
        defaultMessage: 'Атрибут',
    },
    action: {
        id: 'Simulation.action',
        defaultMessage: 'Действие',
    },
    event: {
        id: 'Simulation.event',
        defaultMessage: 'Событие',
    },
    and: {
        id: 'Simulation.and',
        defaultMessage: 'И',
    },
    xor: {
        id: 'Simulation.xor',
        defaultMessage: 'Иск. ИЛИ',
    },
    resource: {
        id: 'Simulation.resource',
        defaultMessage: 'Ресурс',
    },
    cancel: {
        id: 'Simulation.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'Simulation.save',
        defaultMessage: 'Сохранить',
    },
    simulationSetupTitle: {
        id: 'Simulation.simulationSetupTitle',
        defaultMessage: 'Настройки обработки',
    },
    MODELS: {
        id: 'Simulation.MODELS',
        defaultMessage: 'Настройки моделей',
    },
    ATTRIBUTES: {
        id: 'Simulation.ATTRIBUTES',
        defaultMessage: 'Настройки атрибутов',
    },
    RESULTS: {
        id: 'Simulation.RESULTS',
        defaultMessage: 'Настройки результатов',
    },
    ALGORITHM: {
        id: 'Simulation.ALGORITHM',
        defaultMessage: 'Настройки алгоритма',
    },
    addAttributeRole: {
        id: 'Simulation.addAttributeRole',
        defaultMessage: 'Добавить назначение',
    },
    decompositionDepth: {
        id: 'Simulation.decompositionDepth',
        defaultMessage: 'Глубина декомпозиции',
    },
    PAUSE_TIME: {
        id: 'Simulation.PAUSE_TIME',
        defaultMessage: 'Среднее время перерыва после исполнения',
    },
    PROCESSING_TIME: {
        id: 'Simulation.PROCESSING_TIME',
        defaultMessage: 'Среднее время исполнения',
    },
    LIMIT_SLOTS: {
        id: 'Simulation.LIMIT_SLOTS',
        defaultMessage: 'Лимит параллельного выполнения',
    },
    empty: {
        id: 'Simulation.empty',
        defaultMessage: 'Нет данных',
    },
});
