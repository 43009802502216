import { defineMessages } from 'react-intl';

export default defineMessages({
    add: {
        id: 'EPCSelectRowTitleDialog.save',
        defaultMessage: 'Добавить',
    },
    cancel: {
        id: 'EPCSelectRowTitleDialog.cancel',
        defaultMessage: 'Отменить',
    },
});
