import { defineMessages } from 'react-intl';

export default defineMessages({
    choseSymbolsForDragDialogFormName: {
        id: 'ChoseSymbolsForDragDialog.choseSymbolsForDragDialogFormName',
        defaultMessage: 'Выберите символ',
    },
    choseSymbolsForDragFormConfirmButton: {
        id: 'ChoseSymbolsForDragDialog.choseSymbolsForDragFormConfirmButton',
        defaultMessage: 'Добавить на холст',
    },
    choseSymbolsForDragFormDeclineButton: {
        id: 'ChoseSymbolsForDragDialog.choseSymbolsForDragFormDeclineButton',
        defaultMessage: 'Отмена',
    },
});
