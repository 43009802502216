import React from 'react';
import theme from './ScriptDashboard.scss';
import { ScriptExecutionsTable } from '../ScriptExecutionTable/ScriptExecutionsTable.component';
import { TScriptDashboardProps } from './ScriptDashboard.types';

export const ScriptDashboard = (props: TScriptDashboardProps) => {
    const { content, server, downloadFile, downloadLogFile, runScriptStep, openScript } = props;

    return (
        <section className={theme.container}>
            <ScriptExecutionsTable
                data={content}
                serverId={server.id}
                downloadFile={downloadFile}
                downloadLogFile={operationId => downloadLogFile(operationId, server.id)}
                runScriptStep={runScriptStep}
                openScript={openScript}
            />
        </section>
    );
};
