import { SYNC_GRAPH_ON_ATTRIBUTE_CHANGE, UPSERT_ATTRIBUTE_VALUE } from '../actionsTypes/attribute.actionTypes';
import {
    TUpsertAttributeValueActionPayload,
    TUpsertAttributeValueAction,
    TSyncGraphOnAttributeChangeActionPayload,
    TSyncGraphOnAttributeChangeAction,
} from './atrribute.actions.types';

export const upsertAttributeValue = (payload: TUpsertAttributeValueActionPayload): TUpsertAttributeValueAction => ({
    type: UPSERT_ATTRIBUTE_VALUE,
    payload,
});

export const syncGraphOnAttributeChange = (
    payload: TSyncGraphOnAttributeChangeActionPayload,
): TSyncGraphOnAttributeChangeAction => ({
    type: SYNC_GRAPH_ON_ATTRIBUTE_CHANGE,
    payload,
});
