import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Form, Input, Empty } from 'antd';
import theme from '../ModelDialog.scss';
import { useIntl } from 'react-intl';
import messages from './ModelForm.messages';
import { TreeItemType } from '../../../../Tree/models/tree';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { TreeNode } from '../../../../../models/tree.types';
import { TModelFormProps } from './ModelForm.types';
import { Tree } from '../../../../Tree/components/Tree/Tree.component';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { useSelector } from 'react-redux';
import { SearchInput } from '@/modules/UIKit/components/Select/SearchInput.component';
import { searchTreeNodes } from './ModelForm.utils';

export const ModelForm: FC<TModelFormProps> = (props) => {
    const {
        formRef,
        defaultModelName,
        selectedNode,
        selectedModelType,
        errors,
        treeLocation,
        openedSelectNode,
        onFormChange,
        onSelectTypeId,
        onParentNodeIdChange,
    } = props;

    const intl = useIntl();
    const [searchValue, setSearchValue] = useState<string>('');
    const selectedType: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode(DialogType.MODEL_DIALOG_TYPE));
    const selectedPath: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode(DialogType.MODEL_DIALOG));

    const treeTypes: TreeNode[] = searchTreeNodes(searchValue, props.treeTypes);

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onSelectType = (typeItem: TreeNode) => {
        if ([TreeItemType.Model, TreeItemType.Wiki, TreeItemType.Matrix, TreeItemType.Kanban].includes(typeItem.type)) {
            onSelectTypeId(typeItem.nodeId.id);
        }
    };

    const onSelectPath = (selectedPathNode: TreeNode) => {
        if (selectedPathNode.type !== TreeItemType.Server) {
            onParentNodeIdChange(selectedPathNode);
        }
    };

    // костыльное решение, т.к. требуется объемный рефакторинг диалогового окна декомпозиций
    useEffect(() => {
        if (selectedPath) onSelectPath(selectedPath);
    }, [selectedPath]);

    useEffect(() => {
        if (selectedType) onSelectType(selectedType);
    }, [selectedType]);

    return (
        <Form ref={formRef} onValuesChange={onFormChange} layout="vertical" className={theme.modelForm}>
            <Form.Item
                label={intl.formatMessage(messages.modelNameLabel)}
                className={theme.modelName}
                name="modelName"
                initialValue={defaultModelName}
                rules={[
                    {
                        whitespace: true,
                        required: true,
                        message: intl.formatMessage(messages.modelNameRequiredError),
                    },
                ]}
            >
                <Input
                    data-test="model-form_model-name-input"
                    placeholder={intl.formatMessage(messages.modelNamePlaceholder)}
                    autoComplete="off"
                    autoFocus
                />
            </Form.Item>
            {!openedSelectNode && (
                <div data-test="model-form_location" className={theme.selectedNodeName}>
                    {intl.formatMessage(messages.pathLabel)}: <b>{selectedNode && selectedNode.name}</b>
                </div>
            )}
            {openedSelectNode && (
                <Form.Item
                    data-test="model-form_location"
                    required
                    name="modelLocation"
                    label={
                        <span>
                            {intl.formatMessage(messages.pathLabel)}: <b>{selectedNode && selectedNode.name}</b>
                        </span>
                    }
                >
                    <div className={theme.treeLocation}>
                        {errors.modelLocation && (
                            <div className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">{intl.formatMessage(messages.requiredModelLocation)}</div>
                            </div>
                        )}
                        <Tree
                            excludeFilterForSelect={[TreeItemType.Server]}
                            data={treeLocation}
                            treeName={DialogType.MODEL_DIALOG}
                            disableContextMenu={false}
                        />
                    </div>
                </Form.Item>
            )}
            <Form.Item
                data-test="model-form_type"
                required
                name="modelType"
                label={
                    <span>
                        {intl.formatMessage(messages.modelTypeLabel)}:{' '}
                        <b>{selectedModelType && selectedModelType.name}</b>
                    </span>
                }
            >
                <div className={theme.treeSearch}>
                    <SearchInput
                        showSearch
                        onSearch={onSearch}
                        originalTheme
                        searchValue={searchValue}
                        placeholder={intl.formatMessage(messages.search)}
                    />
                </div>
                <div className={theme.treeType}>
                    {errors.modelType && (
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                            <div role="alert">
                                {props.treeTypes.length
                                    ? intl.formatMessage(messages.requiredModelType)
                                    : intl.formatMessage(messages.noAvailableModelTypeError)}
                            </div>
                        </div>
                    )}

                    {searchValue.length && !treeTypes.length ? (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={intl.formatMessage(messages.emptyData)}
                        />
                    ) : (
                        <Tree
                            includeFilterForSelect={[
                                TreeItemType.Model,
                                TreeItemType.Wiki,
                                TreeItemType.Matrix,
                                TreeItemType.Kanban,
                            ]}
                            data={treeTypes}
                            treeName={DialogType.MODEL_DIALOG_TYPE}
                            searchValue={searchValue}
                        />
                    )}
                </div>
            </Form.Item>
        </Form>
    );
};
