import {
    ADD_AUTO_SAVE_INTERVAL_ID,
    DELETE_AUTO_SAVE_INTERVAL_ID,
} from '@/actionsTypes/autoSaveModelIntervalIds.actionTypes';
import {
    TAddAutoSaveModelIntervalIdAction,
    TDeleteAutoSaveModelIntervalIdAction,
} from './autoSaveModelIntervalIds.actions.types';

export const addAutoSaveModelIntervalId = (id: number): TAddAutoSaveModelIntervalIdAction => ({
    type: ADD_AUTO_SAVE_INTERVAL_ID,
    payload: id,
});
export const deleteAutoSaveModelIntervalId = (id: number): TDeleteAutoSaveModelIntervalIdAction => ({
    type: DELETE_AUTO_SAVE_INTERVAL_ID,
    payload: id,
});

