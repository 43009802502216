import { Comment } from '@/serverapi/api';
import { isCommentCell, percentToPoint } from '@/utils/bpm.mxgraph.utils';
import { EditorMode } from '../../models/editorMode';
import { BPMMxGraph } from '../bpmgraph';
import { SequenceSymbolShapes } from '../ComplexSymbols/symbols/ComplexSymbol.constants';
import {
    MxVertexHandler,
    MxShape,
    MxImageShape,
    MxRectangle,
    MxEllipse,
    MxRectangleShape,
    MxEvent,
    MxUtils,
    MxGuide,
    MxPoint,
    MxMouseEvent,
    MxCellState,
    MxCell,
} from '../mxgraph';

const HANDLE_FILLCOLOR: string = '#fff';
const HANDLE_STROKECOLOR: string = '#3b78ff';

MxVertexHandler.prototype.rotationEnabled = false;

export class BPMMxVertexHandler extends MxVertexHandler {
    parentHighlightEnabled = true;
    selectionBorder: any;
    blockDelayedSelection: any;
    customHandles: [];
    graph: BPMMxGraph;

    constructor(state: MxCellState) {
        super(state);
        this.parentHighlightEnabled = false;
    }

    isSizerVisible() {
        const graph = this.graph as BPMMxGraph;

        return graph.mode !== EditorMode.Read;
    }

    createSizerShape(bounds: MxRectangle, index: number, fillColor: string): MxShape | MxImageShape {
        if (this.handleImage !== null) {
            const imageBounds = new MxRectangle(bounds.x, bounds.y, this.handleImage.width, this.handleImage.height);
            const shape = new MxImageShape(imageBounds, this.handleImage.src);
            // Allows HTML rendering of the images
            shape.preserveImageAspect = false;

            return shape;
        }
        if (index === MxEvent.ROTATION_HANDLE) {
            return new MxEllipse(bounds, fillColor || HANDLE_FILLCOLOR, HANDLE_STROKECOLOR);
        }

        return new MxRectangleShape(bounds, fillColor || HANDLE_FILLCOLOR, HANDLE_STROKECOLOR);
    }

    getSelectionColor(): string {
        return HANDLE_STROKECOLOR;
    }

    isSelectionDashed(): boolean {
        return false;
    }

    createCustomHandles() {
        const handles = super.createCustomHandles();
        const graph = this.graph as BPMMxGraph;

        return graph.mode === EditorMode.Read ? [] : handles;
    }

    getGuideStates() {
        const parent = this.graph.getDefaultParent();
        const model = this.graph.getModel();

        const filter = MxUtils.bind(this, (cell) => {
            return (
                this.graph.view.getState(cell) != null &&
                model.isVertex(cell) &&
                model.getGeometry(cell) != null &&
                !model.getGeometry(cell).relative
            );
        });

        return this.graph.view.getCellStates(model.filterDescendants(filter, parent));
    }

    lastEventStorage: {
        lastEvent: MxMouseEvent | null;
        lastDelta: MxPoint | null;
    } = {
        lastEvent: null,
        lastDelta: null,
    };

    clearLastEventStorage() {
        this.lastEventStorage.lastDelta = null;
        this.lastEventStorage.lastEvent = null;
    }

    mouseUp() {
        this.guides?.destroy();
        if (this.lastEventStorage.lastEvent && this.lastEventStorage.lastDelta) {
            const upX = this.startX - this.lastEventStorage.lastDelta.x;
            const upY = this.startY - this.lastEventStorage.lastDelta.y;

            if (this.lastEventStorage.lastDelta.x !== 0 || this.lastEventStorage.lastDelta.y !== 0) {
                this.startX = upX;
                this.startY = upY;
                super.resizeVertex(this.lastEventStorage.lastEvent);
                this.clearLastEventStorage();
            }
        }

        super.mouseUp.apply(this, arguments);

        const { cell } = this.state;
        if (cell && cell.children) {
            const model = this.graph.getModel();

            const comments = (cell.children as MxCell[]).filter((child) => isCommentCell(child));
            model.beginUpdate();
            comments.forEach((comment) => {
                const geo = comment.getGeometry();
                const commentValue = comment.getValue().comment as Comment;
                const { elementOffsetX, elementOffsetY } = commentValue;
                if (elementOffsetX && elementOffsetY && geo) {
                    const percent = new MxPoint(elementOffsetX, elementOffsetY);
                    const point = percentToPoint(percent, cell);
                    const dx = point.x - geo.x;
                    const dy = point.y - geo.y;
                    const newGeo = geo.clone();
                    newGeo.translate(dx, dy);
                    model.setGeometry(comment, newGeo);
                }
            });
            model.endUpdate();
        }
    }

    start(x, y, index) {
        const result = super.start(x, y, index);
        const states = this.getGuideStates();
        this.guides = new MxGuide(this.graph, states);
        this.guides.horizontal = false;
        this.guides.vertical = false;
        this.guides.distance = true;

        return result;
    }

    resizeVertex(me: MxMouseEvent) {
        const delta = new MxPoint(0, 0);
        this.guides.setSourceState(me?.sourceState);
        this.lastEventStorage.lastEvent = me;
        this.lastEventStorage.lastDelta = this.guides.move(this.bounds, delta);

        const disableWidthResize = Object.values(SequenceSymbolShapes).includes(
            this.state.style?.shape as SequenceSymbolShapes,
        );

        return super.resizeVertex(me, disableWidthResize);
    }

    destroy() {
        this.guides?.destroy();

        super.destroy();
    }
}
