import { getGUIDdArray } from '../../ComplexSymbol.utils';
import { DROP_TARGET_ATTRIBUTE } from '../ComplexSymbol.constants';

export const getTableSymbolDiagramTemplate = () => {
    const [u0, u1, u2, u3, u4, u5, u31, u32, u33, u41, u42, u43, u51, u52, u53] = getGUIDdArray(15);

    return `
    <mxGraphModel>
    <root>
        <mxCell id="${u0}" />
        <mxCell id="${u1}" parent="${u0}" />
        <mxCell id="${u2}" complexSymbolTypeId="frame.type.table.symbol" value="Table" style="shape=table;startSize=30;container=1;collapsible=0;childLayout=tableLayout;fontStyle=1;align=center;pointerEvents=1;" vertex="1" parent="${u1}">
            <mxGeometry x="0" y="0" width="180" height="150" as="geometry" />
        </mxCell>
        <mxCell id="${u3}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;collapsible=0;${DROP_TARGET_ATTRIBUTE}=0;pointerEvents=0;fillColor=none;top=0;left=0;bottom=0;right=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="${u2}">
            <mxGeometry y="30" width="180" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u31}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;" vertex="1" parent="${u3}">
            <mxGeometry width="60" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u32}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;" vertex="1" parent="${u3}">
            <mxGeometry x="60" width="60" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u33}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;" vertex="1" parent="${u3}">
            <mxGeometry x="120" width="60" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u4}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;collapsible=0;${DROP_TARGET_ATTRIBUTE}=0;pointerEvents=0;fillColor=none;top=0;left=0;bottom=0;right=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="${u2}">
            <mxGeometry y="70" width="180" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u41}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;" vertex="1" parent="${u4}>
            <mxGeometry width="60" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u42}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;" vertex="1" parent="${u4}">
            <mxGeometry x="60" width="60" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u43}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;" vertex="1" parent="${u4}">
            <mxGeometry x="120" width="60" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u5}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;collapsible=0;${DROP_TARGET_ATTRIBUTE}=0;pointerEvents=0;fillColor=none;top=0;left=0;bottom=0;right=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="${u2}">
            <mxGeometry y="110" width="180" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u51}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;" vertex="1" parent="${u5}">
            <mxGeometry width="60" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u52}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;" vertex="1" parent="${u5}">
            <mxGeometry x="60" width="60" height="40" as="geometry" />
        </mxCell>
        <mxCell id="${u53}" value="" style="shape=partialRectangle;html=1;whiteSpace=wrap;connectable=0;fillColor=none;top=0;left=0;bottom=0;right=0;overflow=hidden;pointerEvents=1;" vertex="1" parent="${u5}">
            <mxGeometry x="120" width="60" height="40" as="geometry" />
        </mxCell>
    </root>
    </mxGraphModel>
`;
};

export const getTableSymbolDiagramClassTemplate = () => {
    const [u0, u1, u2, u3, u4, u5, u6, u7] = getGUIDdArray(8);

    return `
    <mxGraphModel>
        <root>
            <mxCell id="${u0}" />
            <mxCell id="${u1}" parent="${u0}" />
            <mxCell id="${u2}" complexSymbolTypeId="frame.type.table.symbol.class" value="Classname" style="swimlane;fontStyle=1;align=center;verticalAlign=top;childLayout=stackLayout;horizontal=1;startSize=26;horizontalStack=0;resizeParent=1;resizeParentMax=0;resizeLast=0;collapsible=1;marginBottom=0;" vertex="1" parent="${u1}">
                <mxGeometry x="0" y="0" width="160" height="138" as="geometry" />
            </mxCell>
            <mxCell id="${u3}" value="- item: attribute" style="text;strokeColor=none;fillColor=none;align=left;verticalAlign=top;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="${u2}">
                <mxGeometry y="26" width="160" height="26" as="geometry" />
            </mxCell>
            <mxCell id="${u4}" value="- field: type" style="text;strokeColor=none;fillColor=none;align=left;verticalAlign=top;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="${u2}">
                <mxGeometry y="52" width="160" height="26" as="geometry" />
            </mxCell>
            <mxCell id="${u5}" value="" style="line;strokeWidth=0;" vertex="1" parent="${u2}">
                <mxGeometry y="78" width="160" height="8" as="geometry" />
            </mxCell>
            <mxCell id="${u6}" value="+ item: attribute" style="text;strokeColor=none;fillColor=none;align=left;verticalAlign=top;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="${u2}">
                <mxGeometry y="86" width="160" height="26" as="geometry" />
            </mxCell>
            <mxCell id="${u7}" value="+ method(type): type" style="text;strokeColor=none;fillColor=none;align=left;verticalAlign=top;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="${u2}">
                <mxGeometry y="112" width="160" height="26" as="geometry" />
            </mxCell>
        </root>
    </mxGraphModel>
`;
};

export const getTableSymbolDiagrammClassLineTemplate = () => {
    const [u0, u1, u2] = getGUIDdArray(3);

    return `
    <mxGraphModel>
    <root>
        <mxCell id="${u0}" />
        <mxCell id="${u1}" parent="${u0}" />
        <mxCell id="${u2}" value="- new item: attribute" style="text;strokeColor=none;fillColor=none;align=left;verticalAlign=top;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="${u1}">
            <mxGeometry y="26" width="160" height="26" as="geometry" />
        </mxCell>
    </root>
    </mxGraphModel>
`;
};
