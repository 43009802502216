import { TSelectObjectFormData } from '../models/selectObjectDialog.types';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import {
    SELECT_OBJECT_DEFINITION_DIALOG_PREPARE_DATA_REQUEST,
    SELECT_OBJECT_DEFINITION_DIALOG_PREPARE_DATA_SUCCESS,
    SELECT_OBJECT_DIALOG_SUBMIT_DATA,
} from '../actionsTypes/selectObjectDefinitionDialog.actionTypes';
import {
    TSelectObjectDialogPrepareDataRequestAction,
    TSelectObjectDialogPrepareDataSuccessAction,
    TSelectObjectDialogSubmitData,
} from './selectObjectDefinitionDialog.actions.types';
import { ObjectDefinitionNode } from '../serverapi/api';
import { TSelectObjectDefinitionDialogState } from '../reducers/selectObjectDefinitionDialog.reducer.types';

export const selectObjectDialogPrepareDataRequest = (
    instances: ObjectDefinitionNode[],
    cellId?: string,
): TSelectObjectDialogPrepareDataRequestAction => ({
    type: SELECT_OBJECT_DEFINITION_DIALOG_PREPARE_DATA_REQUEST,
    payload: {
        instances,
        cellId,
    },
});

export const selectObjectDialogPrepareDataSuccess = ({
    oldName,
    objectTypeTitle,
    isObjectDeletable,
    pathsMap,
}: TSelectObjectDefinitionDialogState): TSelectObjectDialogPrepareDataSuccessAction => ({
    type: SELECT_OBJECT_DEFINITION_DIALOG_PREPARE_DATA_SUCCESS,
    payload: {
        oldName,
        objectTypeTitle,
        isObjectDeletable,
        pathsMap,
    },
});

export const selectObjectDialogSubmit = (
    data: TSelectObjectFormData,
    cellId: string,
    object: ObjectDefinitionImpl,
    isDeletable: boolean,
    newObjectName: string,
): TSelectObjectDialogSubmitData => ({
    type: SELECT_OBJECT_DIALOG_SUBMIT_DATA,
    payload: {
        data,
        cellId,
        object,
        isDeletable,
        newObjectName,
    },
});
