import { defineMessages } from 'react-intl';

export default defineMessages({
    modelFormName: {
        id: 'ModelDialog.modelFormName',
        defaultMessage: 'Новая модель',
    },
    modelTypeRequiredError: {
        id: 'ModelDialog.modelTypeRequiredError',
        defaultMessage: 'Тип модели должен быть заполнен',
    },
    modelFormConfirmButton: {
        id: 'ModelDialog.modelFormConfirmButton',
        defaultMessage: 'Создать',
    },
    modelFormCancelButton: {
        id: 'ModelDialog.modelFormCancelButton',
        defaultMessage: 'Отмена',
    },
    needLicense: {
        id: 'ModelDialog.needLicense',
        defaultMessage: 'Для этого действия вам необходима лицензия',
    },
});
