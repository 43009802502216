import { defineMessages } from 'react-intl';

export default defineMessages({
    mapToAll: {
        id: 'ImportVisioDialog.option.mapToAll',
        defaultMessage: 'Применить ко всем моделям',
    },
    modelType: {
        id: 'ImportVisioDialog.modelType',
        defaultMessage: 'Тип модели',
    },
    modelName: {
        id: 'ImportVisioDialog.modelName',
        defaultMessage: 'Имя модели',
    },
    title: {
        id: 'ImportVisioDialog.tile',
        defaultMessage: 'Модель',
    },
    cancelButtonText: {
        id: 'ImportVisioDialog.cancelButton.text',
        defaultMessage: 'Отмена',
    },
    okButtonText: {
        id: 'ImportVisioDialog.okButton.text',
        defaultMessage: 'Сохранить',
    },
});
