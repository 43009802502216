import type { TFloatingAttributesDialogContentRef } from './FloatingAttributesPanel.types';
import React, { useRef, useState } from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './FloatingAttributes.messages';
import { useIntl } from 'react-intl';
import { FloatingAttributesPanel } from './FloatingAttributesPanel.component';
import { TFloatingAttributesDialogProps } from '../FloatingAttributes.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export const FloatingAttributesDialog = (props: TFloatingAttributesDialogProps) => {
    const [rulesIsValid, setRulesValidity] = useState<boolean>(true);
    const { onCancel, open } = props;
    const intl = useIntl();
    const ref = useRef<TFloatingAttributesDialogContentRef>(null);
    const handleSubmit = () => ref.current?.submit();
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel || (() => undefined),
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                    disabled: !rulesIsValid,
                },
            ]}
        />
    );

    return (
        <Dialog width={750} open={open} footer={footer} onCancel={onCancel}>
            <FloatingAttributesPanel ref={ref} {...props} setRulesValidity={setRulesValidity} />
        </Dialog>
    );
};
