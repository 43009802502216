import { put, takeEvery } from 'redux-saga/effects';
import { FOLDER_TYPE_DELETE, FOLDER_TYPE_SAVE } from '../actionsTypes/folderType.actionTypes';
import { FolderTypeDAOService } from '../services/dao/FolderTypeDAOService';
import { TDeleteFolderTypeAction, TSaveFolderTypeAction } from '../actions/folderType.actions.types';
import { successDeleteFolderType, successSaveFolderType } from '../actions/folderType.actions';
import { FolderType } from '../serverapi/api';
import { FolderTypeModelsConverter } from '../utils/folderType.utils';
import { workspaceRemoveTabByNodeId } from '../actions/tabs.actions';
import { folderTypeEditorCancel } from '../actions/folderTypeEditor.actions';
import messages from '../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import { LocalesService } from '../services/LocalesService';
import { IntlShape } from 'react-intl';
import { ServerErrorType } from '../models/serverErrorType';

function* handleSaveFolderType(action: TSaveFolderTypeAction) {
    const { folderType, presetId, nodeId, createMode } = action.payload;
    const { serverId } = nodeId;
    const {
        id,
        multilingualName,
        multilingualDescription,
        graphical,
        validModelTypes,
        validObjectTypes,
        validFolderTypes,
        validNodeTypes,
        allowAnyModelType,
        allowAnyObjectType,
        allowAnyFolderType,
        allowAnyNodeType,
        synonymsIds,
        allowApprovals,
        attributeTypes
    } = folderType;

    const { modelTypesWithoutTransmittable, nodeTypesWithTransmittable } = FolderTypeModelsConverter.convertFromEntity(
        validModelTypes || [],
        validNodeTypes || [],
    );

    const convertedFolderType: FolderType = {
        id,
        presetId,
        multilingualName,
        multilingualDescription,
        graphical,
        validModelTypes: modelTypesWithoutTransmittable,
        validObjectTypes,
        validFolderTypes,
        validNodeTypes: nodeTypesWithTransmittable,
        allowAnyModelType,
        allowAnyObjectType,
        allowAnyFolderType,
        allowAnyNodeType,
        synonymsIds,
        allowApprovals,
        attributeTypes
    };

    if (createMode) {
        const intl: IntlShape = LocalesService.useIntl();
        yield FolderTypeDAOService.create(serverId, convertedFolderType).catch((err) => {
            if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                throw Error(intl.formatMessage(messages.idAlreadyTaken));
            } else {
                throw err;
            }
        });
    } else {
        yield FolderTypeDAOService.save(serverId, convertedFolderType);
    }

    yield put(successSaveFolderType({ serverId, presetId, folderType }));
    yield put(workspaceRemoveTabByNodeId(nodeId));
    yield put(folderTypeEditorCancel());
}

function* handleDeleteFolderType(action: TDeleteFolderTypeAction) {
    const { folderTypeId, presetId, serverId } = action.payload;
    yield FolderTypeDAOService.deleteById(serverId, presetId, folderTypeId);
    yield put(successDeleteFolderType({ serverId, presetId, folderTypeId }));
}

export function* folderTypeSaga() {
    yield takeEvery(FOLDER_TYPE_SAVE, handleSaveFolderType);
    yield takeEvery(FOLDER_TYPE_DELETE, handleDeleteFolderType);
}
