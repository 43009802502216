import type { Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Extension } from '@tiptap/core';
import { debounce } from 'lodash-es';

type TOnPaste = (pos: number, editor: Editor, event: ClipboardEvent) => void;

type TPasteHandlerOptions = {
    /**
     * The onPaste callback that is called when a file is dropped.
     * @param pos the paste position
     * @param editor the editor instance
     * @param event the paste event
     * @returns Returns nothing.
     */
    onPaste?: TOnPaste;
};

// в ProseMirror существует дефект:
// при вставке нетекстового контента из буфера обмена дважды вызывается handlePaste
// https://discuss.prosemirror.net/t/can-not-paste-a-single-image-from-word-doc-or-local-folder/1874
// поэтому добавлен debounce для исключения дополнительного вызова
const deb = debounce(
    (onPaste: TOnPaste, editor: Editor, event: ClipboardEvent) => {
        if (!onPaste) {
            return;
        }

        const pos = editor.view.state.selection.$anchor.pos;

        onPaste(pos, editor, event);

        return editor.view.focus();
    },
    500,
    // TODO исследовать совместно с setTimeout в image.ts
    // { leading: true, trailing: false },
);

export const PasteHandler = Extension.create<TPasteHandlerOptions>({
    name: 'pasteHandler',

    addOptions: () => ({
        onPaste: () => null,
    }),

    addProseMirrorPlugins() {
        const editor = this.editor;
        const onPaste = this.options.onPaste;

        return [
            new Plugin({
                key: new PluginKey('handlePaste'),
                props: {
                    handlePaste: (view, event) => deb(onPaste, editor, event),
                },
            }),
        ];
    },
});

