import React, { useRef, useEffect, Dispatch } from 'react';
import { GroupDTO } from '../../../../serverapi/api';
import { FormInstance } from 'antd';
import { IntlShape, useIntl } from 'react-intl';
import formMessages from '../../../../models/formDefault.messages';
import GroupMainDataComponent from '../../GroupMainData/components/GroupMainData.component';
import GroupUsersContainer from '../../GroupUsers/containers/GroupUsers.container';
import theme from '../../UserManagement/components/UserManagment.scss';
import admTheme from '../../style/AdminTools.scss';
import { convertStringToArray } from '../../../../utils/convertStringToArray';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { GroupsSelectors } from '@/selectors/groups.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupById, submitingGroupData } from '@/actions/groups.actions';
import { workspaceRemoveTabRequest } from '@/actions/tabs.actions';
import { FooterButtons } from '../../../Footer/FooterButtons.component';
import { TButtonProps } from '@/modules/UIKit/components/Button/Button.types';

export const GroupDataEditingTab: React.FC = () => {
    const intl: IntlShape = useIntl();
    const dispatch: Dispatch<any> = useDispatch();
    const formRef: React.RefObject<FormInstance<any>> = useRef<FormInstance>(null);
    const tab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const groupData: GroupDTO = useSelector(GroupsSelectors.getEditing(tab?.nodeId.id || ''));

    const onSubmit = (groupData: GroupDTO, isNewGroup: boolean): void => {
        dispatch(submitingGroupData({ groupData, isNewGroup }));
    };

    const onClose = (tab: TWorkspaceTab): void => {
        dispatch(workspaceRemoveTabRequest(tab));
    };

    const fetchGroupFullData = (groupId: number): void => {
        dispatch(fetchGroupById(groupId));
    };

    useEffect(() => {
        if (groupData.id) {
            fetchGroupFullData(groupData.id);
        }
    }, [groupData.id]);

    const validateFields = async (): Promise<void | GroupDTO | undefined> => {
        const form = formRef.current;

        if (!form) {
            return Promise.resolve(undefined);
        }

        return form
            .validateFields()
            .then((formValues) => {
                return {
                    ...groupData,
                    groupName: formValues.groupName.trim(),
                    description: formValues.description?.trim(),
                    synonyms: convertStringToArray(formValues.synonyms || ''),
                };
            })
            .catch((error) => console.error('error', error));
    };

    const handleSubmit = async () => {
        const updatedGroupData = await validateFields();
        const isNewGroup = !groupData || !groupData.id;

        if (updatedGroupData) {
            onSubmit(updatedGroupData, isNewGroup);
        }
    };

    const buttons: TButtonProps[] = [
        {
            children: intl.formatMessage(formMessages.cancelButton),
            size: 'large',
            key: 'cancel',
            onClick: () => {
                if (tab) {
                    onClose(tab);
                }
            },
        },
        {
            children: intl.formatMessage(formMessages.saveButtonLabel),
            size: 'large',
            visualStyle: 'primary',
            dataTest: 'user-managment-group_save-adding-user-to-group',
            key: 'ok',
            onClick: handleSubmit,
        },
    ];

    return (
        <div className={theme.tabContent}>
            <div className={admTheme.containerInner}>
                <div className={theme.rightBorder}>
                    <div className={theme.groupDataContainer}>
                        <GroupMainDataComponent groupData={groupData} formRef={formRef} />
                    </div>
                </div>
                <div className={admTheme.m10}>
                    <GroupUsersContainer groupId={`${groupData.id}`} />
                </div>
            </div>
            <FooterButtons buttons={buttons} />
        </div>
    );
};
