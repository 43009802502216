import { defineMessages } from 'react-intl';

export default defineMessages({
    authority: {
        id: 'InstancePermissionsTable.authority',
        defaultMessage: 'Имя пользователя, группы',
    },
    create: {
        id: 'InstancePermissionsTable.create',
        defaultMessage: 'Создание',
    },
    read: {
        id: 'InstancePermissionsTable.read',
        defaultMessage: 'Чтение',
    },
    write: {
        id: 'InstancePermissionsTable.write',
        defaultMessage: 'Изменение',
    },
    delete: {
        id: 'InstancePermissionsTable.delete',
        defaultMessage: 'Удаление',
    },
    administration: {
        id: 'InstancePermissionsTable.administration',
        defaultMessage: 'Управление',
    },
    emptyTable: {
        id: 'InstancePermissionsTable.emptyTable',
        defaultMessage: 'Нет данных',
    },
    import: {
        id: 'InstancePermissionsTable.import',
        defaultMessage: 'Импорт',
    },
    export: {
        id: 'InstancePermissionsTable.export',
        defaultMessage: 'Экспорт',
    },
    exportDB: {
        id: 'InstancePermissionsTable.exportDB',
        defaultMessage: 'Экспорт БД',
    },
    scriptRun: {
        id: 'InstancePermissionsTable.scriptRun',
        defaultMessage: 'Запуск скриптов',
    },
    comment: {
        id: 'InstancePermissionsTable.comment',
        defaultMessage: 'Комментирование',
    },
    controlComment: {
        id: 'InstancePermissionsTable.controlComment',
        defaultMessage: 'Управление комментариями',
    },
    controlDB: {
        id: 'InstancePermissionsTable.controlDB',
        defaultMessage: 'Управление БД',
    },
    fileUpload: {
        id: 'InstancePermissionsTable.fileUpload',
        defaultMessage: 'Загрузка файлов',
    },
    print: {
        id: 'InstancePermissionTable.print',
        defaultMessage: 'Печать, получение изображения'
    },
});
