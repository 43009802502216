import * as React from 'react';
import { FC } from 'react';
import { ExtendableSelect } from '../ExtendableSelect/ExtendableSelect.component';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import messages from './messages/LineHeightSelect.messages';
import { lineHeightOptions } from '@/utils/configuration';

interface ILineHeightSelectProps {
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    className?: string;
    tooltipTitle?: string;
}
type TLineHeightSelectPropsAllProps = WrappedComponentProps & ILineHeightSelectProps;

const LineHeightSelect: FC<TLineHeightSelectPropsAllProps> = (props: TLineHeightSelectPropsAllProps) => (
    <ExtendableSelect
        className={props.className}
        baseOptions={lineHeightOptions}
        onChange={props.onChange}
        newValueLabel={props.intl.formatMessage(messages.formLabel)}
        disabled={props.disabled}
        value={{ value: props.value, label: props.value }}
        tooltipTitle={props.tooltipTitle}
    />
);
const LineHeightSelectWithIntl = injectIntl(LineHeightSelect);

export { LineHeightSelectWithIntl as LineHeightSelect };
