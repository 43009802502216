import React from 'react';
import { NavigatorPanelButton } from '../NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { CollapseIcon } from '../NavigatorPanel/icons/CollapseIcon.component';
import { useDispatch } from 'react-redux';
import { treeCollapse } from '@/actions/tree.actions';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import messages from '../../messages/Navigator.messages';

export const TreeCollapse = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    
    const handleClick = () => {
        dispatch(treeCollapse());
    };

    return (
        <Tooltip mouseLeaveDelay={0} placement="top" title={intl.formatMessage(messages.collapseAll)}>
            <>
                <NavigatorPanelButton
                    dataTest="navigator-panel_collapse_icon"
                    IconComponent={CollapseIcon}
                    onClick={handleClick}
                />
            </>
        </Tooltip>
    );
};
