// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MethodologyStep__container__RShKw{margin-top:10px}.MethodologyStep__typesBlock__AdCX4{font-size:14px;font-weight:500;color:var(--mainColor)}.MethodologyStep__select__wdFFx,.MethodologyStep__select_required__WzpY2{font-weight:400;width:100%}.MethodologyStep__select__wdFFx span,.MethodologyStep__select_required__WzpY2 span{color:var(--mainColor)}.MethodologyStep__select_required__WzpY2 span{color:red}.MethodologyStep__option__fBzId{width:100%}.MethodologyStep__table__EfnBf{font-weight:400;color:var(--text)}", "",{"version":3,"sources":["webpack://./src/modules/Import/Aris/components/MethodologyStep/MethodologyStep.scss"],"names":[],"mappings":"AAAA,mCACI,eAAA,CAGJ,oCACI,cAAA,CACA,eAAA,CACA,sBAAA,CAGJ,yEACI,eAAA,CACA,UAAA,CACA,mFACI,sBAAA,CAIA,8CACI,SAAA,CAKZ,gCACI,UAAA,CAGJ,+BACI,eAAA,CACA,iBAAA","sourcesContent":[".container {\r\n    margin-top: 10px;\r\n}\r\n\r\n.typesBlock {\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n    color: var(--mainColor);\r\n}\r\n\r\n.select {\r\n    font-weight: 400;\r\n    width: 100%;\r\n    & span {\r\n        color: var(--mainColor);\r\n    }\r\n    &_required {\r\n        @extend .select;\r\n        & span {\r\n            color: red;\r\n        }\r\n    }\r\n}\r\n\r\n.option {\r\n    width: 100%;\r\n}\r\n\r\n.table {\r\n    font-weight: 400;\r\n    color: var(--text);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MethodologyStep__container__RShKw",
	"typesBlock": "MethodologyStep__typesBlock__AdCX4",
	"select": "MethodologyStep__select__wdFFx",
	"select_required": "MethodologyStep__select_required__WzpY2",
	"option": "MethodologyStep__option__fBzId",
	"table": "MethodologyStep__table__EfnBf"
};
export default ___CSS_LOADER_EXPORT___;
