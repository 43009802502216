import { WrappedComponentProps } from 'react-intl';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../../models/bpm/bpm-model-impl';
import { TWorkspaceTab } from '../../models/tab.types';
import { TTreeEntityState } from '../../models/tree.types';
import { ModelNode, ModelType, NodeId, SimulationRun, SimulationSettings } from '../../serverapi/api';

export type TSimulationModelingContainerProps = WrappedComponentProps & {
    tab: TWorkspaceTab;
};

export type TSimulationModelingProps = WrappedComponentProps & {
    nodeId: NodeId;
    simulationRuns: SimulationRun[];
    simulationSettings: SimulationSettings;
    simulationNode: TTreeEntityState;
    model: ModelNode;
    object: ObjectDefinitionImpl;
    objectDiagramElement: ObjectInstanceImpl;
    modelTypes: ModelType[];
    runSimulation: (simulation: SimulationRun) => void;
    addNewSimulation: (simulation: SimulationRun) => void;
    saveSimulation: (simulation: SimulationRun) => void;
    deleteSimulation: (simulation: SimulationRun) => void;
    saveSimulationSettings: (simulationSettings: SimulationSettings, simulationId: NodeId) => void;
    getReport: (simulation: SimulationRun) => void;
};

export type TSimulationResultBoardProps =  {
    activeSimulation?: SimulationRun;
};

export enum TabId {
    FUNCTIONS = 'FUNCTIONS',
    RESOURCES = 'RESOURCES',
    EVENTS = 'EVENTS',
    REPORTS = 'REPORTS',
}

export enum ReportFilterTypes {
    REPORTFULL = 'reportFull',
    REPORTFINISHEDSUCCESS = 'reportFinishedSuccess',
    REPORTFINISHEDFAILED = 'reportFinishedFailed',
    REPORTFINISHED = 'reportFinished',
    REPORTUNFINISHNED = 'reportUnfinished',
}