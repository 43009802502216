import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TFileUploadDialogProps } from '../../FileUpload/types/FileUploadDialog.types';
import { FileUploadDialog } from '../../FileUpload/components/FileUploadDialog.component';
import { loadArisFromExcel } from '../../../actions/importAris.actions';
import { NodeId } from '../../../serverapi/api';

type TLoadArisFromExcelDialpgOwnProps = {
    nodeId: NodeId;
};

const mapDispatchToProps = (dispatch, ownProps: TLoadArisFromExcelDialpgOwnProps): Partial<TFileUploadDialogProps> => ({
    onClose: () => dispatch(closeDialog(DialogType.LOAD_ARIS_FROM_EXCEL)),
    onSubmit: (file: File) => {
        dispatch(loadArisFromExcel({file, nodeId: ownProps.nodeId}));
        dispatch(closeDialog(DialogType.LOAD_ARIS_FROM_EXCEL));
    },
});

export const LoadArisFromExcelDialogContainer = connect(null, mapDispatchToProps)(FileUploadDialog);
