import { ObjectDefinitionImpl } from '../../../models/bpm/bpm-model-impl';
import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { TRootState } from '../../../reducers/root.reducer.types';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TRenameObjectDialogProps } from './RenameObjectDialog.types';
import { RenameObjectDialog } from './RenameObjectDialog.component';

type TRenameGraphCellDialogOwnProps = {
    visible: boolean;
    value: string;
    onRename: (name: string) => void;
};

const mapStateToProps = (
    state: TRootState,
    { value, visible }: TRenameGraphCellDialogOwnProps,
): Partial<TRenameObjectDialogProps & { visible: boolean }> => {
    return { visible, value };
};

const mapDispatchToProps = (dispatch, { onRename }: TRenameGraphCellDialogOwnProps) => ({
    onClose: () => dispatch(closeDialog(DialogType.RENAME_GRAPH_CELL_DIALOG)),
    onSubmit: (objectDefinition: ObjectDefinitionImpl, newName: string) => {
        dispatch(closeDialog(DialogType.RENAME_GRAPH_CELL_DIALOG));
        onRename(newName);
    },
});

export const RenameGraphCellDialogContainer = connect(mapStateToProps, mapDispatchToProps)(RenameObjectDialog);

