// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModelLinkDialog__about__dvqxV{margin:10px 0px}.ModelLinkDialog__title__h8Hnx{margin-bottom:.6em;font-weight:bold}.ModelLinkDialog__paragraph__RDBFV{font-size:13px;margin-bottom:.6em;font-weight:400}.ModelLinkDialog__redparagraph___BXcY{font-size:13px;margin-bottom:.6em;font-weight:400;color:red}.ModelLinkDialog__buttonRow__eDd8S{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/modules/ModelLinkDialog/ModelLinkDialog.scss"],"names":[],"mappings":"AAAA,+BACE,eAAA,CAGF,+BACE,kBAAA,CACA,gBAAA,CAGF,mCACE,cAAA,CACA,kBAAA,CACA,eAAA,CAGF,sCACE,cAAA,CACA,kBAAA,CACA,eAAA,CACA,SAAA,CAGF,mCACE,oBAAA,CAAA,iBAAA,CAAA,wBAAA","sourcesContent":[".about {\r\n  margin: 10px 0px;\r\n}\r\n\r\n.title {\r\n  margin-bottom: 0.6em;\r\n  font-weight: bold;\r\n}\r\n\r\n.paragraph {\r\n  font-size: 13px;\r\n  margin-bottom: 0.6em;\r\n  font-weight: 400;\r\n}\r\n\r\n.redparagraph {\r\n  font-size: 13px;\r\n  margin-bottom: 0.6em;\r\n  font-weight: 400;\r\n  color: red;\r\n}\r\n\r\n.buttonRow {\r\n  justify-content: flex-end;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": "ModelLinkDialog__about__dvqxV",
	"title": "ModelLinkDialog__title__h8Hnx",
	"paragraph": "ModelLinkDialog__paragraph__RDBFV",
	"redparagraph": "ModelLinkDialog__redparagraph___BXcY",
	"buttonRow": "ModelLinkDialog__buttonRow__eDd8S"
};
export default ___CSS_LOADER_EXPORT___;
