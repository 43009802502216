import type { EdgeInstance, NodeId } from '../serverapi/api';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { EdgeDefinitionSelectors } from '../selectors/edgeDefinition.selector';
import { getStore } from '../store';
import { CustomMap } from '../utils/map';
import { MxCell } from 'MxGraph';
import { BPMMxGraph } from '../mxgraph/bpmgraph';

class EdgeDefinitionService {
    findCellIdsByEdgeNodeId(edgeNodeId: NodeId): CustomMap<NodeId, string[]> {
        const map = new CustomMap<NodeId, string[]>();
        if (edgeNodeId) {
            instancesBPMMxGraphMap.values().forEach((graph) => {
                const cells = graph.getModel()?.cells;
                const edgeCells = Object.values<MxCell>(cells)
                    .filter(
                        (c) =>
                            c.getValue()?.edgeDefinitionId === edgeNodeId.id &&
                            edgeNodeId.repositoryId === graph.id.repositoryId,
                    )
                    .map((c) => c.getId());
                if (edgeCells.length) {
                    map.set(graph.id, edgeCells);
                }
            });
        }

        return map;
    }

    findCellByEdgeInstanceId(graphId: NodeId, edgeInstanceId: string | undefined): MxCell | undefined {
        const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(graphId);
        if (!(graph && edgeInstanceId)) return undefined;

        const graphModelCells: { [index: string]: MxCell } | undefined = graph.getModel().cells;
        const graphModelEdgeCells = Object.values(graphModelCells || {}).filter((cell) => cell.isEdge());

        return graphModelEdgeCells.find((cell) => cell.getValue()?.id === edgeInstanceId);
    }

    getEdgeNameByInstance(edgeInstance: EdgeInstance, modelId: NodeId) {
        if (edgeInstance.edgeDefinitionId) {
            const edgeDefinition = EdgeDefinitionSelectors.byInstanceAndModelId(
                edgeInstance,
                modelId,
            )(getStore().getState());

            if (edgeDefinition) return edgeDefinition.name || '';
        }

        return edgeInstance.name;
    }
}

let edgeDefinitionServiceInstance: EdgeDefinitionService;

export function edgeDefinitionService(): EdgeDefinitionService {
    if (!edgeDefinitionServiceInstance) {
        edgeDefinitionServiceInstance = new EdgeDefinitionService();
    }

    return edgeDefinitionServiceInstance;
}
