import {
    OPEN_NOTIFICATIONS_TAB,
    CLOSE_NOTIFICATIONS_TAB,
    ADD_NOTIFICATIONS,
    SAVE_NOTIFICATION,
    CREATE_NOTIFICATION,
    LOAD_NOTIFICATIONS,
    DELETE_NOTIFICATION,
} from '@/actionsTypes/notifications.actionTypes';
import { NotificationDTO } from '@/serverapi/api';
import { TreeNode } from '../models/tree.types';
import {
    TAddNotificationsAction,
    TCloseNotificationTabAction,
    TCreateNotificationAction,
    TDeleteNotificationAction,
    TLoadNotificationsAction,
    TOpenNotificationsTabAction,
    TSaveNotificationAction,
} from './notifications.actions.types';

export const openNotificationsTab = (node: TreeNode): TOpenNotificationsTabAction => ({
    type: OPEN_NOTIFICATIONS_TAB,
    payload: { node },
});

export const loadNotificationsAction = (): TLoadNotificationsAction => ({
    type: LOAD_NOTIFICATIONS,
});

export const closeNotificationsTab = (): TCloseNotificationTabAction => ({
    type: CLOSE_NOTIFICATIONS_TAB,
});

export const addNotifications = (notifications: NotificationDTO[]): TAddNotificationsAction => ({
    type: ADD_NOTIFICATIONS,
    payload: { notifications },
});

export const saveNotification = (notification: NotificationDTO): TSaveNotificationAction => ({
    type: SAVE_NOTIFICATION,
    payload: { notification },
});

export const createNotification = (notification: NotificationDTO): TCreateNotificationAction => ({
    type: CREATE_NOTIFICATION,
    payload: { notification },
});

export const deleteNotification = (notificationId: string): TDeleteNotificationAction => ({
    type: DELETE_NOTIFICATION,
    payload: { notificationId },
});
