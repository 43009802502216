import { FRAME_STYLES_INDICATOR, SequenceSymbolShapes, SymbolTypeId } from '../ComplexSymbol.constants';
import { getGUIDdArray } from '../../ComplexSymbol.utils';
import { UMLFrame } from './UMLFrame.class';

export const getSequenceFrameTemplate = (): string => {
    const [u0, u1, u2] = getGUIDdArray(3);

    return `
    <mxGraphModel>
      <root>
        <mxCell id="${u0}" />
        <mxCell id="${u1}" parent="${u0}" />
        <mxCell
          id="${u2}"
          parent="${u1}"
          style="${FRAME_STYLES_INDICATOR};noLabel=1;shape=rectangle;editable=0;connectable=0;pointerEvents=0;"
          vertex="1"
        >
          <mxGeometry x="0" y="0" width="340" height="190" as="geometry" />
        </mxCell>
        <mxCell 
          id="${UMLFrame.getUMLFrameTitleIdByParentId(u2)}"
          style="shape=${SequenceSymbolShapes.UML_FRAME_TITLE};connectable=0;deletable=0;movable=0;"
          vertex="1"
          parent="${u2}"
        >
          <mxGeometry width="160" height="26" as="geometry" />
        </mxCell>
      </root>
    </mxGraphModel>
`;
};

export const getSequenceNoteTemplate = () => {
    const [u0, u1, u2] = getGUIDdArray(3);

    return `
    <mxGraphModel>
      <root>
        <mxCell id="${u0}" />
        <mxCell id="${u1}" parent="${u0}" />
        <mxCell
          id="${u2}"
          parent="${u1}"
          style="shape=uml-note;connectable=0;"
          vertex="1"
        >
          <mxGeometry width="156" height="64" as="geometry" />
        </mxCell>
      </root>
    </mxGraphModel>
  `;
};

export const getUMLMessageTemplate = () => {
    const [u0, u1, u2] = getGUIDdArray(3);

    return `
  <mxGraphModel>
    <root>
      <mxCell id="${u0}" />
      <mxCell id="${u1}" parent="${u0}" />
      <mxCell
        id="${u2}"
        parent="${u1}"
        complexSymbolTypeId="${SymbolTypeId.UML_MESSAGE}"
        style="shape=${SequenceSymbolShapes.UML_MESSAGE_SHAPE};connectable=1;noLabel=1;editable=0;resizable=0;"
        vertex="1"
      >
        <mxGeometry x="0" y="0" width="20" height="20" as="geometry" />
      </mxCell>
    </root>
  </mxGraphModel>
`;
};
