import { put, select, takeEvery } from 'redux-saga/effects';
import { TUndoAction } from '@/actions/undeRedo.actions.types';
import { TUndoRedoData } from '@/reducers/undoRedo.reducer.types';
import { UndoRedoSelectors } from '@/selectors/undoRedo.selectors';
import { updateMatrixData } from '@/modules/Matrix/actions/matrix.actions';
import { REDO_ACTION, UNDO_ACTION } from '@/actionsTypes/undoRedo.actionTypes';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { UPDATE_MATRIX_DATA } from '@/modules/Matrix/actionTypes/matrix.actionTypes';
import { TMatrixUpdateDataAction } from '@/modules/Matrix/actions/matrix.actions.types';
import { addUndoRedoState } from '@/actions/undoRedo.actions';

function* handleUndoRedo(action: TUndoAction) {
    const { nodeId } = action.payload;
    const undoRedoData: TUndoRedoData | undefined = yield select(UndoRedoSelectors.getUndoRedoStates(nodeId));

    if (!undoRedoData) return;

    const { states, currentIndex, nodeType } = undoRedoData;
    const currentState = states[currentIndex];

    if (!currentState) return;

    if (nodeType === TreeItemType.Matrix) {
        yield put(updateMatrixData(nodeId, currentState, true));
    }
}

function* handleMatrixChange(action: TMatrixUpdateDataAction) {
    const { nodeId, data, isUndoRedo } = action.payload;

    if (data && !isUndoRedo) {
        yield put(addUndoRedoState(nodeId, data, TreeItemType.Matrix));
    }
}

export function* undoRedoSaga() {
    yield takeEvery(UNDO_ACTION, handleUndoRedo);
    yield takeEvery(REDO_ACTION, handleUndoRedo);
    yield takeEvery(UPDATE_MATRIX_DATA, handleMatrixChange);
}
