import { put, select, takeEvery } from 'redux-saga/effects';
import {
    TCreateAttributeTypeAction,
    TDeleteAttributeTypesAndGroupsAction,
    TDeleteTAttributeTypeAction,
    TSaveTAttributeTypeAction,
} from '../actions/attributeType.actions.types';
import {
    ATTRIBUTE_TYPE_CREATE_REQUEST,
    ATTRIBUTE_TYPE_SAVE_REQUEST,
    DELETE_ATTRIBUTE_TYPE,
    DELETE_ATTRIBUTE_TYPES_AND_GROUPS,
} from '../actionsTypes/attributeType.actionTypes';
import { attributeTypeDeleteRequestSuccess, attributeTypesRequestSuccess } from '../actions/attributeType.actions';
import { DELETE_ATTRIBUTE_TYPE_GROUP } from '../actionsTypes/attributeTypeGroup.actionTypes';
import { TDeleteAttributeTypeGroupAction } from '../actions/attributeTypeGroup.actions.types';
import { AttributeType } from '../serverapi/api';
import { AttributeTypeSelectors } from '../selectors/attributeType.selectors';
import { deleteAttributeTypeGroupSuccess } from '../actions/attributeTypeGroup.actions';
import { AttributeTypesDaoService } from '../services/dao/AttributeTypesDaoService';
import { ServerErrorType } from '../models/serverErrorType';
import messages from '../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';

function* handleSaveAttributeType(action: TSaveTAttributeTypeAction) {
    const { serverNode, attributeTypes, preset } = action.payload;
    const { serverId } = serverNode.nodeId;
    yield AttributeTypesDaoService.saveAttributeType(serverId, attributeTypes);
    yield put(attributeTypesRequestSuccess(serverId, preset.id, attributeTypes));
}

function* handleCreateAttributeType(action: TCreateAttributeTypeAction) {
    const { serverNode, attributeType, preset } = action.payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    yield AttributeTypesDaoService.createAttributeType(serverId, attributeType).catch((err) => {
        if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
            throw Error(intl.formatMessage(messages.idAlreadyTaken));
        } else {
            throw err;
        }
    });
    yield put(attributeTypesRequestSuccess(serverId, preset.id, [attributeType]));
}

function* handleDeleteAttributeType({ payload }: TDeleteTAttributeTypeAction) {
    const { serverNode, attributeTypes } = payload;
    const { serverId } = serverNode.nodeId;

    if (serverNode && attributeTypes?.length > 0) {
        yield AttributeTypesDaoService.deleteAttributeTypes(serverId, attributeTypes);
    }
    yield put(attributeTypeDeleteRequestSuccess({ serverNode, attributeTypes }));
}

function* handleDeleteAttributeTypeGroup({ payload }: TDeleteAttributeTypeGroupAction) {
    const { serverNode, attributeTypeGroups } = payload;
    const { serverId } = serverNode.nodeId;

    if (serverNode && attributeTypeGroups?.length > 0) {
        const { presetId } = attributeTypeGroups[0];
        const attributeTypes: AttributeType[] = yield select(
            AttributeTypeSelectors.takeAttributeTypesFromGroup(serverId, presetId, attributeTypeGroups),
        );
        yield AttributeTypesDaoService.deleteAttributeTypesAndGroupsRequest(
            serverId,
            attributeTypes,
            attributeTypeGroups,
        );
        if (attributeTypes) {
            yield put(attributeTypeDeleteRequestSuccess({ serverNode, attributeTypes }));
        }
    }
    yield put(deleteAttributeTypeGroupSuccess(payload));
}

function* handleDeleteAttributeTypesAndGroups({ payload }: TDeleteAttributeTypesAndGroupsAction) {
    const { serverNode, attributeTypes, attributeTypeGroups } = payload;
    const { serverId } = serverNode.nodeId;
    yield AttributeTypesDaoService.deleteAttributeTypesAndGroupsRequest(serverId, attributeTypes, attributeTypeGroups);
    yield put(deleteAttributeTypeGroupSuccess({ serverNode, attributeTypeGroups }));
    yield put(attributeTypeDeleteRequestSuccess({ serverNode, attributeTypes }));
}

export function* attributeTypeSagaInit() {
    yield takeEvery(ATTRIBUTE_TYPE_SAVE_REQUEST, handleSaveAttributeType);
    yield takeEvery(ATTRIBUTE_TYPE_CREATE_REQUEST, handleCreateAttributeType);
    yield takeEvery(DELETE_ATTRIBUTE_TYPE, handleDeleteAttributeType);
    yield takeEvery(DELETE_ATTRIBUTE_TYPE_GROUP, handleDeleteAttributeTypeGroup);
    yield takeEvery(DELETE_ATTRIBUTE_TYPES_AND_GROUPS, handleDeleteAttributeTypesAndGroups);
}
