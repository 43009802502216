import { defineMessages } from 'react-intl';

export default defineMessages({
    processExport: {
        id: 'ProcessIndicator.processExport',
        defaultMessage: 'Экспорт',
    },
    processImport: {
        id: 'ProcessIndicator.processImport',
        defaultMessage: 'Импорт',
    },
    processDelete: {
        id: 'ProcessIndicator.processDelete',
        defaultMessage: 'Удаление',
    },
    processExportPreset: {
        id: 'ProcessIndicator.processExportPreset',
        defaultMessage: 'Экспорт методологии',
    },
    processDbCopy: {
        id: 'ProcessIndicator.processDbCopy',
        defaultMessage: 'Копирование базы данных',
    },
});
