import { defineMessages } from 'react-intl';

export default defineMessages({
    DB_ACCUSATIVE: {
        id: 'DB_ACCUSATIVE',
        defaultMessage: 'базу данных',
    },
    FOLDER_ACCUSATIVE: {
        id: 'FOLDER_ACCUSATIVE',
        defaultMessage: 'папку',
    },
    SCRIPT_FOLDER_ACCUSATIVE: {
        id: 'SCRIPT_FOLDER_ACCUSATIVE',
        defaultMessage: 'папку для скриптов',
    },
    MODEL_ACCUSATIVE: {
        id: 'MODEL_ACCUSATIVE',
        defaultMessage: 'модель',
    },
    OBJECT_ACCUSATIVE: {
        id: 'OBJECT_ACCUSATIVE',
        defaultMessage: 'объект',
    },
    SCRIPT_ACCUSATIVE: {
        id: 'SCRIPT_ACCUSATIVE',
        defaultMessage: 'скрипт',
    },
    WIKI_ACCUSATIVE: {
        id: 'WIKI_ACCUSATIVE',
        defaultMessage: 'вики',
    },
    SIMULATION_ACCUSATIVE: {
        id: 'SIMULATION_ACCUSATIVE',
        defaultMessage: 'имитационную модель',
    },
    FILE_FOLDER_ACCUSATIVE: {
        id: 'FILE_FOLDER_ACCUSATIVE',
        defaultMessage: 'папку для файлов',
    },
    FILE_ACCUSATIVE: {
        id: 'FILE_ACCUSATIVE',
        defaultMessage: 'файл',
    },
    MATRIX_ACCUSATIVE: {
        id: 'MATRIX_ACCUSATIVE',
        defaultMessage: 'матричную модель',
    },
    SERVER_ACCUSATIVE: {
        id: 'SERVER_ACCUSATIVE',
        defaultMessage: 'сервер',
    },
    SPREADSHEET_ACCUSATIVE: {
        id: 'SPREADSHEET_ACCUSATIVE',
        defaultMessage: 'таблицу',
    },
    NODE_ACCUSATIVE: {
        id: 'NODE_ACCUSATIVE',
        defaultMessage: 'узел',
    },
});

