import { defineMessages } from 'react-intl';
import {
    SequenceSymbolTypeId,
    SymbolTypeId,
} from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';

export default defineMessages({
    [SymbolTypeId.SIMPLE]: {
        id: 'simple',
        defaultMessage: 'Обычный',
    },
    [SymbolTypeId.HORIZONTAL_SWIMLANE]: {
        id: 'frame.type.swimlane.h',
        defaultMessage: 'Дорожка (горизонтальная)',
    },
    [SymbolTypeId.VERTICAL_SWIMLANE]: {
        id: 'frame.type.swimlane.v',
        defaultMessage: 'Дорожка (вертикальная)',
    },
    [SymbolTypeId.VR]: {
        id: 'frame.type.vr',
        defaultMessage: 'Рамка VR',
    },
    [SymbolTypeId.IDEF]: {
        id: 'frame.type.idef',
        defaultMessage: 'Рамка IDEF',
    },
    [SymbolTypeId.HORIZONTAL_POOL]: {
        id: 'frame.type.pool.h',
        defaultMessage: 'Пул с дорожками (горизонтальный)',
    },
    [SymbolTypeId.VERTICAL_POOL]: {
        id: 'frame.type.pool.v',
        defaultMessage: 'Пул с дорожками (вертикальный)',
    },
    [SymbolTypeId.CROSS]: {
        id: 'frame.type.cross',
        defaultMessage: 'Пул с дорожками (смешанный)',
    },
    [SequenceSymbolTypeId.LIFE_LINE]: {
        id: 'sequence.diagram.lifeline',
        defaultMessage: 'Линия жизни (class)',
    },
    [SequenceSymbolTypeId.LIFE_LINE_BOUNDERY]: {
        id: 'sequence.diagram.lifeline.boundery',
        defaultMessage: 'Линия жизни (boundery)',
    },
    [SequenceSymbolTypeId.LIFE_LINE_ENTITY]: {
        id: 'sequence.diagram.lifeline.entity',
        defaultMessage: 'Линия жизни (entity)',
    },
    [SequenceSymbolTypeId.LIFE_LINE_CONTROL]: {
        id: 'sequence.diagram.lifeline.control',
        defaultMessage: 'Линия жизни (control)',
    },
    [SequenceSymbolTypeId.LIFE_LINE_ACTOR]: {
        id: 'sequence.diagram.lifeline.actor',
        defaultMessage: 'Символ Исполнитель',
    },
    // todo узнать что это такое и добавить перевод
    // 'frame.type..table.symbol': {
    //     id: 'frame.type..table.symbol',
    //     defaultMessage: '',
    // },
    // 'frame.type.table.symbol.class': {
    //     id: 'frame.type.table.symbol.class',
    //     defaultMessage: '',
    // },
    // 'frame.type.table.symbol.class.line': {
    //     id: 'frame.type.table.symbol.class.line',
    //     defaultMessage: '',
    // },
    [SymbolTypeId.UML_CLASS]: {
        id: 'frame.type.uml.class',
        defaultMessage: 'Символ класса',
    },
    [SymbolTypeId.UML_PACKAGE]: {
        id: 'frame.type.uml.package',
        defaultMessage: 'Символ пакета',
    },
    [SymbolTypeId.UML_MESSAGE]: {
        id: 'uml-message',
        defaultMessage: 'Сообщение',
    },
    kanbanCard: {
        id: 'kanbanCard',
        defaultMessage: 'Карточка (для Канбан-доски)',
    },
});
