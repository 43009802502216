import { defineMessages } from 'react-intl';

export default defineMessages({
    addCondition: {
        id: 'AttributeFilter.addCondition',
        defaultMessage: 'Добавить условие',
    },
    attribute: {
        id: 'AttributeFilter.attribute',
        defaultMessage: 'Атрибут',
    },
    condition: {
        id: 'AttributeFilter.condition',
        defaultMessage: 'Условие',
    },
    value: {
        id: 'AttributeFilter.value',
        defaultMessage: 'Значение',
    },
    methodologyAttributes: {
        id: 'AttributeFilter.methodologyAttributes',
        defaultMessage: 'Атрибуты методологии',
    },
    HAS_VALUE: {
        id: 'AttributeFilter.HAS_VALUE',
        defaultMessage: 'Заполнен',
    },
    EQUALS: {
        id: 'AttributeFilter.EQUALS',
        defaultMessage: 'Равно',
    },
    GREATER: {
        id: 'AttributeFilter.GREATER',
        defaultMessage: 'Больше',
    },
    GREATER_OR_EQUAL: {
        id: 'AttributeFilter.GREATER_OR_EQUAL',
        defaultMessage: 'Больше или равно',
    },
    LESS: {
        id: 'AttributeFilter.LESS',
        defaultMessage: 'Меньше',
    },
    LESS_OR_EQUAL: {
        id: 'AttributeFilter.LESS_OR_EQUAL',
        defaultMessage: 'Меньше или равно',
    },
    CONTAINS: {
        id: 'AttributeFilter.CONTAINS',
        defaultMessage: 'Содержит',
    },
    STARTS_WITH: {
        id: 'AttributeFilter.STARTS_WITH',
        defaultMessage: 'Текст начинается с',
    },
    ENDS_WITH: {
        id: 'AttributeFilter.ENDS_WITH',
        defaultMessage: 'Текст заканчивается на',
    },
    systemAttributes: {
        id: 'AttributeFilter.systemAttributes',
        defaultMessage: 'Системные атрибуты',
    },
});
