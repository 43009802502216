import { QUERY_SELECT_ATTRIBUTE_VALUES_REQUEST_SUCCESS } from '../actionsTypes/querySelectAttributeTypeValues.actionTypes';
import { TReducer } from '../utils/types';
import { TQueryAttributeTypeData, TQuerySelectState } from './querySelect.reducer.types';

const initial: TQuerySelectState = {};

export const querySelectReducer: TReducer<TQuerySelectState> = (state = initial, action) => {
    switch (action.type) {
        case QUERY_SELECT_ATTRIBUTE_VALUES_REQUEST_SUCCESS: {
            const { presetId, data } = action.payload;
            const presetData: TQueryAttributeTypeData = {};
            data.forEach((value) => {
                const { attributeTypeId, values } = value;
                presetData[attributeTypeId] = values;
            });

            return {
                ...state,
                [presetId]: { ...state[presetId], ...presetData },
            };
        }
        default:
            return state;
    }
};
