import { getCurrentBlock, setBlockData } from './contentBlocks.utils';
import {
    AtomicBlockUtils,
    CharacterMetadata,
    ContentBlock,
    ContentState,
    EditorState,
    genKey,
    RichUtils,
} from 'draft-js';
import { Map, List, Repeat } from 'immutable';
import { isTableBlockSelected, putInTable, isTableBlock } from '../Editor/renderers/Table/TableContentsBlocks.utils';
import { WIKI_TABLE_KEY, WIKI_TABLE_POSITION_KEY } from '../Editor/renderers/Table/WikiTable.constants';

export const getValidEditorState = (editorState, block) => {
    const currentBlock = getCurrentBlock(editorState);

    if (!isTableBlockSelected(currentBlock)) {
        return editorState;
    }

    return putInTable(editorState, block);
};

export const getAtomicBlock = (editorState, entityKey) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const currentAtomicBlock = blockMap.find((block) => {
        if (block.getEntityAt(0) === entityKey) {
            return block;
        }

        return false;
    });

    return currentAtomicBlock;
};

export const insertAtomicBlock = (editorState, entityKey, character = ' ') => {
    const block = getCurrentBlock(editorState);
    if (!isTableBlock(block)) {
        return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, character);
    }

    const tableKey = block.getData().get(WIKI_TABLE_KEY);
    const tablePosition = block.getData().get(WIKI_TABLE_POSITION_KEY);
    const charData = CharacterMetadata.create({ entity: entityKey });
    const tableBlock = new ContentBlock({
        key: genKey(),
        type: 'atomic',
        text: ' ',
        characterList: List(Repeat(charData, character.length)),
        data: Map({ tableKey, tablePosition, type: 'TABLE' }),
    });
    const newBlock = new ContentBlock({
        key: genKey(),
        type: 'unstyled',
        text: '',
        characterList: List(),
        data: Map({ tableKey, tablePosition }),
    });
    const contentState = editorState.getCurrentContent();
    const newBlockMap = contentState
        .getBlockMap()
        .set(tableBlock.get('key'), tableBlock)
        .set(newBlock.get('key'), newBlock);

    return EditorState.push(
        editorState,
        ContentState.createFromBlockArray(newBlockMap.toArray())
            .set('selectionBefore', contentState.getSelectionBefore())
            .set('selectionAfter', contentState.getSelectionAfter()) as any,
        'insert-fragment',
    );
};

export const toggleBlockType = (editorState, type) => {
    const block = getCurrentBlock(editorState);
    if (!isTableBlock(block)) {
        return RichUtils.toggleBlockType(editorState, type);
    }

    const newEditorState = setBlockData(editorState, block, { type: 'TABLE' });

    return RichUtils.toggleBlockType(newEditorState, type);
};

export const refreshEditor = (changeEditorState, editorState: EditorState) => {
    changeEditorState(EditorState.forceSelection(editorState, editorState.getSelection()));
};
