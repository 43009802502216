import type { Editor } from '@tiptap/react';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import icListBulleted from 'icons/toolbar/controls/ic-format-list-bulleted.svg';
import icListNumbered from 'icons/toolbar/controls/ic-format-list-numbered.svg';
import icListIndentDescrease from 'icons/toolbar/controls/ic-indent-decrease.svg';
import icListIndentIncrease from 'icons/toolbar/controls/ic-indent-increase.svg';
import { Button } from '../../../Toolbar/Button.component';
import icCheckList from 'icons/toolbar/controls/ic-check-list.svg';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';

const BULLET_LIST_ITEM = 'bullet-list-item';

const ORDERED_LIST_ITEM = 'ordered-list-item';

const TASK_LIST_ITEM = 'task-list-item';

const SINK_LIST_ITEM = 'sink-list-item';

const LIFT_LIST_ITEM = 'lift-list-item';

type TListsValue = {
    [key: string]: boolean;
};

const itemsIdMap = {
    [BULLET_LIST_ITEM]: 'listItem',
    [ORDERED_LIST_ITEM]: 'listItem',
    [TASK_LIST_ITEM]: 'taskItem',
};

const getFocusedBlock = (editor: Editor) => ({
    [BULLET_LIST_ITEM]: editor.isActive('bulletList'),
    [ORDERED_LIST_ITEM]: editor.isActive('orderedList'),
    [TASK_LIST_ITEM]: editor.isActive('taskList'),
});

const getChangedBlock = (editor: Editor, action: string) => {
    const activeLists = getFocusedBlock(editor);
    const activeListItemKey = Object.keys(activeLists).find((key) => activeLists[key]) || 'listItem';

    switch (action) {
        case BULLET_LIST_ITEM:
            return editor.chain().focus().toggleBulletList().run();
        case ORDERED_LIST_ITEM:
            return editor.chain().focus().toggleOrderedList().run();
        case TASK_LIST_ITEM:
            return editor.chain().focus().toggleTaskList().run();
        case SINK_LIST_ITEM:
            return editor.chain().focus().sinkListItem(itemsIdMap[activeListItemKey]).run();
        case LIFT_LIST_ITEM:
            return editor.chain().focus().liftListItem(itemsIdMap[activeListItemKey]).run();
        default:
            return false;
    }
};

const getIsIndentDisabled = (editor: Editor | undefined): boolean => {
    if (!editor) {
        return false;
    }

    const canSinkListItem = editor.can().sinkListItem('listItem');
    const canSinkTaskItem = editor.can().sinkListItem('taskItem');

    return !canSinkListItem && !canSinkTaskItem;
};

const getIsOutdentDisabled = (editor: Editor | undefined): boolean => {
    if (!editor) {
        return false;
    }

    const canLiftListItem = editor.can().liftListItem('listItem');
    const canLiftTaskItem = editor.can().liftListItem('taskItem');

    return !canLiftListItem && !canLiftTaskItem;
};

type TListsComponent = {
    hideIndent?: boolean;
};

export const ListsComponent: FC<TListsComponent> = ({ hideIndent }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value, editor } = useSharedState<TListsValue, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const onChange = (listType) => () => {
        return setCurrentState(listType);
    };
    const isIndentDisabled = getIsIndentDisabled(editor);
    const isOutdentDisabled = getIsOutdentDisabled(editor);

    return (
        <>
            <Button
                tooltipTitle={intl.formatMessage(messages.bulletList)}
                icon={icListBulleted}
                onClick={onChange(BULLET_LIST_ITEM)}
                selected={!!value?.[BULLET_LIST_ITEM]}
                dataTest="wiki-toolbar-group_lists-button_bulleted"
            />
            <Button
                tooltipTitle={intl.formatMessage(messages.orderedList)}
                icon={icListNumbered}
                onClick={onChange(ORDERED_LIST_ITEM)}
                selected={!!value?.[ORDERED_LIST_ITEM]}
                dataTest="wiki-toolbar-group_lists-button_numbered"
            />
            <Button
                tooltipTitle={intl.formatMessage(messages.taskList)}
                icon={icCheckList}
                onClick={onChange(TASK_LIST_ITEM)}
                selected={!!value?.[TASK_LIST_ITEM]}
                dataTest="wiki-toolbar-group_lists-button_checkList"
            />
            {!hideIndent && (
                <>
                    <Button
                        tooltipTitle={intl.formatMessage(messages.sinkListItem)}
                        icon={icListIndentIncrease}
                        onClick={onChange(SINK_LIST_ITEM)}
                        disabled={isIndentDisabled}
                        dataTest="wiki-toolbar-group_lists-button_indent"
                    />
                    <Button
                        tooltipTitle={intl.formatMessage(messages.liftListItem)}
                        icon={icListIndentDescrease}
                        onClick={onChange(LIFT_LIST_ITEM)}
                        disabled={isOutdentDisabled}
                        dataTest="wiki-toolbar-group_lists-button_outdent"
                    />
                </>
            )}
        </>
    );
};
