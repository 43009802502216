import { SortOrder } from 'antd/es/table/interface';
import { TTableData } from '../MethodologyChangeTab.types';

export const setRowInFirstPositionByKey = (
    a: TTableData,
    b: TTableData,
    sortOrder: SortOrder,
    key: string,
): 1 | -1 | undefined => {
    if (sortOrder === 'descend') {
        if (a.key === key) return 1;
        if (b.key === key) return -1;
    } else if (sortOrder === 'ascend') {
        if (a.key === key) return -1;
        if (b.key === key) return 1;
    }

    return undefined;
};
