import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TDeleteCommentDialogProps } from './DeleteCommentDialog.types';
import { DeleteCommentDialog } from './DeleteCommentDialog.component';
import { deleteComment } from '../../../actions/comments.actions';
import { NodeId } from '../../../serverapi/api';

const mapStateToProps = (): Partial<TDeleteCommentDialogProps> => ({});

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeDialog(DialogType.DELETE_COMMENT_DIALOG)),
    onSubmit(modelId: NodeId, commentId: NodeId) {
        dispatch(deleteComment(modelId, commentId));
        dispatch(closeDialog(DialogType.DELETE_COMMENT_DIALOG));
    },
});

export const DeleteCommentDialogContainer = connect(mapStateToProps, mapDispatchToProps)(DeleteCommentDialog);
