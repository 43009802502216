import { TreeDataNode } from 'antd';
import {
    EdgeType,
    EdgeTypeGroup,
    FolderType,
    InternationalString,
    ModelType,
    ModelTypeGroup,
    ObjectType,
    ObjectTypeGroup,
    Symbol,
} from '../../../../serverapi/api';

export interface ISymbolWithModelId extends Symbol {
    modelTypeId?: string;
}

export interface IObjectTypeGroupWithTypeGroup extends ObjectType {
    typeGroup: ObjectTypeGroup;
}

export interface IModelTypeGroupWithTypeGroup extends ModelType {
    typeGroup: ModelTypeGroup;
}

export interface IEdgeTypeGroupWithTypeGroup extends EdgeType {
    typeGroup: EdgeTypeGroup;
}

export interface IFolderTypeGroupWithTypeGroup extends FolderType {
    typeGroup: undefined;
}

export interface IDataNodeWithGrops extends TreeDataNode {
    modelTypeId?: string;
    typeGroup?: ObjectTypeGroup | ModelTypeGroup | EdgeTypeGroup | undefined;
    id?: string;
    children?: IDataNodeWithGrops[];
}

export type TCheckedItems = {
    checkedItems: string[] | undefined;
    setCheckedItemsWithDublicates: (
        allItemKeys: string[],
        event,
        defaultCheckedKeys: string[],
        contextType: scriptContextTypes,
    ) => void;
    setUncheckedItemsWithDublicates: (defaultCheckedKeys: string[], event, contextType: scriptContextTypes) => void;
};

export enum scriptContextTypes {
    everyContext = 'everyContext',
    runningOnModels = 'runningOnModels',
    runningOnObjects = 'runningOnObjects',
    runningOnEdges = 'runningOnEdges',
    runningOnObjectInstances = 'runningOnObjectInstances',
    runningOnObjectInstancesWithBindingModelTypes = 'runningOnObjectInstancesWithBindingModelTypes',
    runningOnFiles = 'runningOnFiles',
    runningOnFolders = 'runningOnFolders',
    runningOnDB = 'runningOnDB',
    runningOnScripts = 'runningOnScripts',
    runningOnSpecificElement = 'runningOnSpecificElement',
}

export type TKey = {
    firstPartKey: string;
    secondPartKey?: string;
    thirdPartKey?: string;
};

export const DIVIDER = '_$_';

export class CTreeNodeKey {
    constructor(data: TTreeNodeKeyData) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        });
    }

    modelTypeId: string;
    objectTypeId: string;
    edgeTypeId: string;
    folderTypeId: string;
    symbolId: string;
    modelGroupId: string;
    edgeGroupId: string;
    objectGroupId: string;
    folderGroupId: string;
    presetId: string;
    getTypeId(): string {
        return this.edgeTypeId || this.folderTypeId || this.symbolId || this.modelTypeId || this.objectTypeId || '';
    }

    createKey(): string {
        return [
            this.modelTypeId,
            this.objectTypeId,
            this.edgeTypeId,
            this.folderTypeId,
            this.symbolId,
            this.modelGroupId,
            this.edgeGroupId,
            this.objectGroupId,
            this.folderGroupId,
            this.presetId,
        ].join(DIVIDER);
    }

    static keyToObject(key: string) {
        const keyArr = key.split(DIVIDER);

        return new CTreeNodeKey({
            modelTypeId: keyArr[0],
            objectTypeId: keyArr[1],
            edgeTypeId: keyArr[2],
            folderTypeId: keyArr[3],
            symbolId: keyArr[4],
            modelGroupId: keyArr[5],
            edgeGroupId: keyArr[6],
            objectGroupId: keyArr[7],
            folderGroupId: keyArr[8],
            presetId: keyArr[9],
        });
    }

    getModelTypeId(): string {
        return this.modelTypeId || '';
    }

    getSymbolIdAndModelTypeId(): string {
        return [this.symbolId, this.modelTypeId].join(DIVIDER);
    }

    getPresetId(): string {
        return this.presetId || '';
    }

    getSymbolId(): string {
        return this.symbolId || '';
    }
}

export type TTreeNodeKeyData = {
    modelTypeId?: string;
    objectTypeId?: string;
    edgeTypeId?: string;
    folderTypeId?: string;
    symbolId?: string;
    modelGroupId?: string;
    edgeGroupId?: string;
    objectGroupId?: string;
    folderGroupId?: string;
    presetId?: string;
};

export const MAX_AMOUNT_OF_ITEMS_IN_TOOLTIP = 15;

export type TModelSymbolNames = {
    modelName: string | InternationalString;
    symbolNames: (string | InternationalString)[];
};

export type TItemName = string | InternationalString;
