export const OPEN_SERVER_AUTH_DIALOG = 'OPEN_SERVER_AUTH_DIALOG';

export const SERVER_ADD_BATCH = 'SERVER_ADD_BATCH';

export const SERVER_DELETE = 'SERVER_DELETE';

export const SERVER_EDIT = 'SERVER_EDIT';

export const SERVER_EDIT_SUCCESS = 'SERVER_EDIT_SUCCESS';

export const SERVER_CHANGE_ONLINE_STATUS = 'SERVER_CHANGE_ONLINE_STATUS';

export const CLEAR_SERVERS_SESSION = 'CLEAR_SERVERS_SESSION';
