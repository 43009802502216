// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ObjectPath__objectPath__V8kkJ{color:var(--grey-neutral500-UIKit);font-size:12px;font-weight:400;line-height:18px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;cursor:pointer;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-radius:0;max-width:100%}.ObjectPath__objectPath__V8kkJ:hover{-webkit-box-shadow:inset 0 -1px 0 0 var(--grey-neutral500-UIKit);box-shadow:inset 0 -1px 0 0 var(--grey-neutral500-UIKit)}", "",{"version":3,"sources":["webpack://./src/modules/ObjectPropertiesDialog/components/ObjectPath/ObjectPath.scss"],"names":[],"mappings":"AAAA,+BACI,kCAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,cAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,eAAA,CACA,cAAA,CAEA,qCACI,gEAAA,CAAA,wDAAA","sourcesContent":[".objectPath {\r\n    color: var(--grey-neutral500-UIKit);\r\n    font-size: 12px;\r\n    font-weight: 400;\r\n    line-height: 18px;\r\n    white-space: nowrap;\r\n    text-overflow: ellipsis;\r\n    overflow: hidden;\r\n    cursor: pointer;\r\n    width: fit-content;\r\n    border-radius: 0;\r\n    max-width: 100%;\r\n\r\n    &:hover {\r\n        box-shadow: inset 0 -1px 0 0 var(--grey-neutral500-UIKit);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"objectPath": "ObjectPath__objectPath__V8kkJ"
};
export default ___CSS_LOADER_EXPORT___;
