import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'TextDirectionSelectDialog.title',
        defaultMessage: 'Направление',
    },
    horizontally: {
        id: 'TextDirectionSelectDialog.horizontally',
        defaultMessage: 'По горизонтали',
    },
    vertically: {
        id: 'TextDirectionSelectDialog.vertically',
        defaultMessage: 'По вертикали',
    },
});
