import {
    KanbanBoardObjectChangingRule,
    KanbanBoardPlacingRule,
    KanbanBoardSingleColumnSettings,
    KanbanBoardType,
} from '../../../serverapi/api';
import { TEditKanbanModelTypeWorkspaceTabState } from './editKanbanModelTypeWorkspaceTab.reducers.types';
import { TWorkspaceTabStoreKanbanActions } from '../../../actions/workspaceTab/editKanbanModelTypeWorkspaceTab.actions.types';
import {
    ADD_CARDS_KANBAN_MODEL_TYPE,
    ADD_KANBAN_MODEL_TYPE_COLUMN,
    ADD_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    ADD_KANBAN_MODEL_TYPE_PLACING_RULE,
    ADD_STORE_EDIT_KANBAN_MODEL_TYPE_WORKSPACE_TAB,
    ADD_SYMBOLS_KANBAN_MODEL_TYPE,
    CHANGE_KANBAN_MODEL_TYPE_COLUMNS_SETTINGS,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_CARDS_VIEW_IN_COLUMN,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_COLOR,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_CARDS_LIMIT_IN_COLUMN,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_HEADER_ICON,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_COLOR,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_ICON,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_ID,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_MAX_CARDS_IN_COLUMN,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_MIN_CARD_WIDTH_FOR_ROW_VIEW,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_NAME,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_TYPE,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_VALUE,
    CHANGE_KANBAN_MODEL_TYPE_DESCRIPTION,
    CHANGE_KANBAN_MODEL_TYPE_GROUP_ID,
    CHANGE_KANBAN_MODEL_TYPE_ID,
    CHANGE_KANBAN_MODEL_TYPE_NAME,
    CHANGE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    CHANGE_KANBAN_MODEL_TYPE_PLACING_RULE,
    DELETE_CARDS_KANBAN_MODEL_TYPE,
    DELETE_KANBAN_MODEL_TYPE_COLUMN,
    DELETE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    DELETE_KANBAN_MODEL_TYPE_PLACING_RULE,
    DELETE_SYMBOLS_KANBAN_MODEL_TYPE,
    MOVE_KANBAN_MODEL_TYPE_COLUMN,
    MOVE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    MOVE_KANBAN_MODEL_TYPE_PLACING_RULE,
} from '../../../actionsTypes/workspaceTab/editKanbanModelTypeWorkspaceTab.actionTypes';

const initial: TEditKanbanModelTypeWorkspaceTabState = {} as TEditKanbanModelTypeWorkspaceTabState;

const setNewData = (
    state: TEditKanbanModelTypeWorkspaceTabState,
    presetId: string,
    modelTypeId: string,
    modelType: KanbanBoardType,
): TEditKanbanModelTypeWorkspaceTabState => {
    return {
        ...state,
        [presetId]: {
            ...state[presetId],
            [modelTypeId]: modelType,
        },
    };
};

const getModelType = (
    state: TEditKanbanModelTypeWorkspaceTabState,
    presetId: string,
    modelTypeId: string,
): KanbanBoardType => {
    return { ...state[presetId][modelTypeId] };
};

export const editKanbanModelTypeWorkspaceTabReducer = (
    state = initial,
    action: TWorkspaceTabStoreKanbanActions,
): TEditKanbanModelTypeWorkspaceTabState => {
    switch (action.type) {
        case ADD_STORE_EDIT_KANBAN_MODEL_TYPE_WORKSPACE_TAB: {
            const { modelType, presetId } = action.payload;

            return setNewData(state, presetId, modelType.id, modelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_NAME: {
            const { presetId, modelTypeId, multilingualName } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);
            const changedModelType: KanbanBoardType = { ...modelType, multilingualName };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_ID: {
            const { presetId, modelTypeId, id } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);
            const changedModelType: KanbanBoardType = { ...modelType, id };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_DESCRIPTION: {
            const { presetId, modelTypeId, multilingualDescription } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);
            const changedModelType: KanbanBoardType = { ...modelType, multilingualDescription };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_GROUP_ID: {
            const { presetId, modelTypeId, groupId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);
            const changedModelType: KanbanBoardType = { ...modelType, groupId };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case ADD_SYMBOLS_KANBAN_MODEL_TYPE: {
            const { presetId, modelTypeId, symbolsIds } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);
            const changedModelType: KanbanBoardType = {
                ...modelType,
                availableSymbolsCardTypesIds: {
                    ...modelType.availableSymbolsCardTypesIds,
                    symbolsIds,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case ADD_CARDS_KANBAN_MODEL_TYPE: {
            const { presetId, modelTypeId, cardTypesIds } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);
            const changedModelType: KanbanBoardType = {
                ...modelType,
                availableSymbolsCardTypesIds: {
                    ...modelType.availableSymbolsCardTypesIds,
                    cardTypesIds,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case DELETE_SYMBOLS_KANBAN_MODEL_TYPE: {
            const { presetId, modelTypeId, symbolIds } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);
            const newSymbolIds: string[] = modelType.availableSymbolsCardTypesIds.symbolsIds.filter(
                (oldId) => !symbolIds.some((id) => id === oldId),
            );
            const changedModelType: KanbanBoardType = {
                ...modelType,
                availableSymbolsCardTypesIds: {
                    ...modelType.availableSymbolsCardTypesIds,
                    symbolsIds: newSymbolIds,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case DELETE_CARDS_KANBAN_MODEL_TYPE: {
            const { presetId, modelTypeId, cardIds } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);
            const newCardIds: string[] = modelType.availableSymbolsCardTypesIds.cardTypesIds.filter(
                (oldId) => !cardIds.some((id) => id === oldId),
            );
            const changedModelType: KanbanBoardType = {
                ...modelType,
                availableSymbolsCardTypesIds: {
                    ...modelType.availableSymbolsCardTypesIds,
                    cardTypesIds: newCardIds,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMNS_SETTINGS: {
            const { presetId, modelTypeId, columnsSettings } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);
            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings,
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case ADD_KANBAN_MODEL_TYPE_COLUMN: {
            const { presetId, modelTypeId, column, index } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns: KanbanBoardSingleColumnSettings[] = modelType.columnsSettings.columns.slice();
            changedColumns.splice(index, 0, column);

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case DELETE_KANBAN_MODEL_TYPE_COLUMN: {
            const { presetId, modelTypeId, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.filter((col) => col.innerId !== columnId);

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case MOVE_KANBAN_MODEL_TYPE_COLUMN: {
            const { presetId, modelTypeId, columnId, isDirectionUp } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = [...modelType.columnsSettings.columns];
            const currentIndex = changedColumns.findIndex((col) => col.innerId === columnId);
            const newIndex = isDirectionUp ? currentIndex - 1 : currentIndex + 1;

            if (newIndex >= changedColumns.length || newIndex < 0) return state;

            [changedColumns[currentIndex], changedColumns[newIndex]] = [
                changedColumns[newIndex],
                changedColumns[currentIndex],
            ];

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_NAME: {
            const { presetId, modelTypeId, columnId, multilingualName } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, multilingualName };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_ID: {
            const { presetId, modelTypeId, columnId, newColumnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, id: newColumnId };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_TYPE: {
            const { presetId, modelTypeId, widthType, widthValue, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, widthType, widthValue };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_VALUE: {
            const { presetId, modelTypeId, widthValue, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, widthValue };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_COLOR: {
            const { presetId, modelTypeId, headerColor, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, headerColor };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_COLOR: {
            const { presetId, modelTypeId, columnColor, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, columnColor };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_CARDS_VIEW_IN_COLUMN: {
            const { presetId, modelTypeId, columns } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_MIN_CARD_WIDTH_FOR_ROW_VIEW: {
            const { presetId, modelTypeId, width, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, minCardWidthForRowView: width };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_HEADER_ICON: {
            const { presetId, modelTypeId, hasHeaderIcon, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, hasHeaderIcon };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_ICON: {
            const { presetId, modelTypeId, headerIcon, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, headerIcon };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_CARDS_LIMIT_IN_COLUMN: {
            const { presetId, modelTypeId, hasCardsLimitInColumn, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, hasCardsLimitInColumn };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_COLUMN_MAX_CARDS_IN_COLUMN: {
            const { presetId, modelTypeId, maxCardsCountInColumn, columnId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedColumns = modelType.columnsSettings.columns.map((col) => {
                if (col.innerId !== columnId) return col;
                return { ...col, maxCardsCountInColumn };
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                columnsSettings: {
                    ...modelType.columnsSettings,
                    columns: changedColumns,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case ADD_KANBAN_MODEL_TYPE_PLACING_RULE: {
            const { presetId, modelTypeId, placingRule, index } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedPlacingRules: KanbanBoardPlacingRule[] = modelType.rules.placingRules.slice();
            changedPlacingRules.splice(index, 0, placingRule);

            const changedModelType: KanbanBoardType = {
                ...modelType,
                rules: {
                    ...modelType.rules,
                    placingRules: changedPlacingRules,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_PLACING_RULE: {
            const { presetId, modelTypeId, placingRule } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedPlacingRules: KanbanBoardPlacingRule[] = modelType.rules.placingRules.map((rule) => {
                if (rule.id !== placingRule.id) return rule;

                return placingRule;
            });

            const changedModelType: KanbanBoardType = {
                ...modelType,
                rules: {
                    ...modelType.rules,
                    placingRules: changedPlacingRules,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case DELETE_KANBAN_MODEL_TYPE_PLACING_RULE: {
            const { presetId, modelTypeId, placingRuleId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedPlacingRules: KanbanBoardPlacingRule[] = modelType.rules.placingRules.filter(
                (rule) => rule.id !== placingRuleId,
            );

            const changedModelType: KanbanBoardType = {
                ...modelType,
                rules: {
                    ...modelType.rules,
                    placingRules: changedPlacingRules,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case MOVE_KANBAN_MODEL_TYPE_PLACING_RULE: {
            const { presetId, modelTypeId, placingRuleId, isDirectionUp } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedPlacingRules: KanbanBoardPlacingRule[] = [...modelType.rules.placingRules];
            const currentIndex = changedPlacingRules.findIndex((rule) => rule.id === placingRuleId);
            const newIndex = isDirectionUp ? currentIndex - 1 : currentIndex + 1;

            if (newIndex >= changedPlacingRules.length || newIndex < 0) return state;

            [changedPlacingRules[currentIndex], changedPlacingRules[newIndex]] = [
                changedPlacingRules[newIndex],
                changedPlacingRules[currentIndex],
            ];

            const changedModelType: KanbanBoardType = {
                ...modelType,
                rules: {
                    ...modelType.rules,
                    placingRules: changedPlacingRules,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case ADD_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE: {
            const { presetId, modelTypeId, objectChangingRule, index } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedObjectChangingRule: KanbanBoardObjectChangingRule[] =
                modelType.rules.objectChangingRules.slice();
            changedObjectChangingRule.splice(index, 0, objectChangingRule);

            const changedModelType: KanbanBoardType = {
                ...modelType,
                rules: {
                    ...modelType.rules,
                    objectChangingRules: changedObjectChangingRule,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE: {
            const { presetId, modelTypeId, objectChangingRule } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedObjectChangingRules: KanbanBoardObjectChangingRule[] = modelType.rules.objectChangingRules.map(
                (rule) => {
                    if (rule.id !== objectChangingRule.id) return rule;

                    return objectChangingRule;
                },
            );

            const changedModelType: KanbanBoardType = {
                ...modelType,
                rules: {
                    ...modelType.rules,
                    objectChangingRules: changedObjectChangingRules,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case DELETE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE: {
            const { presetId, modelTypeId, objectChangingRuleId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedObjectChangingRules: KanbanBoardObjectChangingRule[] =
                modelType.rules.objectChangingRules.filter((rule) => rule.id !== objectChangingRuleId);

            const changedModelType: KanbanBoardType = {
                ...modelType,
                rules: {
                    ...modelType.rules,
                    objectChangingRules: changedObjectChangingRules,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        case MOVE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE: {
            const { presetId, modelTypeId, isDirectionUp, objectChangingRuleId } = action.payload;
            const modelType: KanbanBoardType = getModelType(state, presetId, modelTypeId);

            const changedObjectChangingRules: KanbanBoardObjectChangingRule[] = [
                ...modelType.rules.objectChangingRules,
            ];
            const currentIndex = changedObjectChangingRules.findIndex((rule) => rule.id === objectChangingRuleId);
            const newIndex = isDirectionUp ? currentIndex - 1 : currentIndex + 1;

            if (newIndex >= changedObjectChangingRules.length || newIndex < 0) return state;

            [changedObjectChangingRules[currentIndex], changedObjectChangingRules[newIndex]] = [
                changedObjectChangingRules[newIndex],
                changedObjectChangingRules[currentIndex],
            ];

            const changedModelType: KanbanBoardType = {
                ...modelType,
                rules: {
                    ...modelType.rules,
                    objectChangingRules: changedObjectChangingRules,
                },
            };

            return setNewData(state, presetId, modelTypeId, changedModelType);
        }

        default: {
            return state;
        }
    }
};
