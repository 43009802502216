import { shapes, registerShapes } from '../mxgraph/shapes';

export class Bootstrapper {
    initApplication(): Promise<void> {
        registerShapes(shapes);

        return new Promise<void>((resolve, reject) => {
            resolve();
        });
    }
}
