import { defineMessages } from 'react-intl';

export default defineMessages({
    type: {
        id: 'scriptContext.type',
        defaultMessage: 'Тип',
    },
    runningContext: {
        id: 'scriptContext.runningContext',
        defaultMessage: 'Контекст запуска',
    },
    addRunningContext: {
        id: 'scriptContext.addRunningContext',
        defaultMessage: 'Контекст запуска',
    },
    editingRunningContext: {
        id: 'scriptContext.editingRunningContext',
        defaultMessage: 'Редактирование контекста запуска',
    },
    saveContext: {
        id: 'scriptContext.saveContext',
        defaultMessage: 'Сохранить',
    },
    cancelContext: {
        id: 'scriptContext.cancelContext',
        defaultMessage: 'Отменить',
    },
    everyContext: {
        id: 'scriptContext.everyContext',
        defaultMessage: 'Скрипт для обработки событий',
    },
    eventHandlerScript: {
        id: 'scriptContext.eventHandlerScript',
        defaultMessage: 'Скрипт для обработки событий',
    },
    every: {
        id: 'scriptContext.every',
        defaultMessage: 'Любой',
    },
    runningOnModels: {
        id: 'scriptContext.runningOnModels',
        defaultMessage: 'Запуск на моделях',
    },
    runningOnObjects: {
        id: 'scriptContext.runningOnObjects',
        defaultMessage: 'Запуск на объектах',
    },
    runningOnEdges: {
        id: 'scriptContext.runningOnEdges',
        defaultMessage: 'Запуск на связях',
    },
    runningOnObjectInstances: {
        id: 'scriptContext.runningOnObjectInstances',
        defaultMessage: 'Запуск на экземплярах объекта',
    },
    runningOnObjectInstancesWithBindingModelTypes: {
        id: 'scriptContext.runningOnObjectInstancesWithBindingModelTypes',
        defaultMessage: 'Запуск на экземплярах объекта (c привязкой к типу модели)',
    },
    runningOnFiles: {
        id: 'scriptContext.runningOnFiles',
        defaultMessage: 'Запуск на файлах',
    },
    runningOnFolders: {
        id: 'scriptContext.runningOnFolders',
        defaultMessage: 'Запуск на папках',
    },
    runningOnDB: {
        id: 'scriptContext.runningOnDB',
        defaultMessage: 'Запуск на БД',
    },
    runningOnScripts: {
        id: 'scriptContext.runningOnScripts',
        defaultMessage: 'Запуск на скриптах',
    },
    runningOnSpecificElement: {
        id: 'scriptContext.runningOnSpecificElement',
        defaultMessage: 'Запуск на конкретном элементе',
    },
    restrictByModelType: {
        id: 'scriptContext.restrictByModelType',
        defaultMessage: 'Ограничить по типу модели',
    },
    contextType: {
        id: 'scriptContext.contextType',
        defaultMessage: 'Тип контекста',
    },
    permitObjectInstances: {
        id: 'scriptContext.permitObjectInstances',
        defaultMessage: 'Разрешать запуск на экземплярах объектов',
    },
    models: {
        id: 'scriptContext.models',
        defaultMessage: 'Модели',
    },
    objects: {
        id: 'scriptContext.objects',
        defaultMessage: 'Объекты',
    },
    dontCheck: {
        id: 'scriptContext.dontCheck',
        defaultMessage: 'Не выбрано',
    },
    amountOfCheckableItems: {
        id: 'scriptContext.amountOfCheckableItems',
        defaultMessage: 'выбрано {amount} из',
    },
    runningOnAllFiles: {
        id: 'scriptContext.runningOnAllFiles',
        defaultMessage: 'Запуск на всех файлах',
    },
    runningOnAllDB: {
        id: 'scriptContext.runningOnAllDB',
        defaultMessage: 'Запуск на всех БД',
    },
    runningOnAllScripts: {
        id: 'scriptContext.runningOnAllScripts',
        defaultMessage: 'Запуск на всех скриптах',
    },
    deleteContextItems: {
        id: 'scriptContext.deleteContextItems',
        defaultMessage: 'Удалить элементы?',
    },
    amountOfCheckItems: {
        id: 'scriptContext.amountOfCheckItems',
        defaultMessage: '{checkItems} из {allItems}',
    },
    amountOfContextTypesItems: {
        id: 'scriptContext.amountOfContextTypesItems',
        defaultMessage: 'Выбрано {amount}',
    },
    allContextIsChosen: {
        id: 'scriptContext.allContextIsChosen',
        defaultMessage: 'Выбраны все контексты запуска',
    },
    contextNotChosen: {
        id: 'scriptContext.contextNotChosen',
        defaultMessage: 'Нет выбранных элементов',
    },
    name: {
        id: 'scriptContext.name',
        defaultMessage: 'Наименование',
    },
    amount: {
        id: 'scriptContext.amount',
        defaultMessage: 'Количество',
    },
    andMore: {
        id: 'scriptContext.andMore',
        defaultMessage: '... и еще {amount}',
    },
});