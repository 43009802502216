import { ReportDaoService } from '@/modules/Report/dao/ReportDaoService';
import { TreeItemType } from '../modules/Tree/models/tree';
import { LockInfoDTO, NodeId, NodeTypeEnum } from '../serverapi/api';
import { modelService } from './ModelService';
import { ScriptDAOService } from './dao/ScriptDAOService';
import { SpreadsheetDaoService } from './dao/SpreadsheetDaoService';
import { MatrixDaoService } from '@/modules/Matrix/dao/MatrixDaoService';

export class LockService {
    lock(type: NodeTypeEnum, nodeId: NodeId): Promise<LockInfoDTO | void> {
        switch (type) {
            case TreeItemType.Model: {
                return modelService().lockModel(nodeId);
            }
            case TreeItemType.Matrix: {
                return MatrixDaoService.lockMatrix(nodeId);
            }
            case TreeItemType.Report: {
                return ReportDaoService.lockReport(nodeId);
            }
            case TreeItemType.Script: {
                return ScriptDAOService.lockScript(nodeId);
            }
            case TreeItemType.Spreadsheet: {
                return SpreadsheetDaoService.lockSpreadsheet(nodeId);
            }
            default: {
                return Promise.resolve();
            }
        }
    }

    unlock(type: NodeTypeEnum, nodeId: NodeId): Promise<void> {
        switch (type) {
            case TreeItemType.Model: {
                return modelService().unlockModel(nodeId);
            }
            case TreeItemType.Matrix: {
                return MatrixDaoService.unlockMatrix(nodeId);
            }
            case TreeItemType.Report: {
                return ReportDaoService.unlockReport(nodeId);
            }
            case TreeItemType.Script: {
                return ScriptDAOService.unlockScript(nodeId);
            }
            case TreeItemType.Spreadsheet: {
                return SpreadsheetDaoService.unlockSpreadsheet(nodeId);
            }
            default: {
                return Promise.resolve();
            }
        }
    }
}

let LockServiceInstance: LockService;

export function lockService(): LockService {
    if (!LockServiceInstance) {
        LockServiceInstance = new LockService();
    }

    return LockServiceInstance;
}
