import { NodeId } from '@/serverapi/api';

export type TDashboard = {
    cellSize: number;
    verticalCount?: number;
    horizontalCount?: number;
    widgets?: TWidget[];
    selectedWidgetId?: string;
};

export type TWidgetGeometry = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export enum SummaryOperations {
    Count = 'Count',
    Sum = 'Sum',
    AvgSum = 'AvgSum',
}

export type TWidgetDataset = {
    col1?: TUpperCaseAlpValues;
    col2?: TUpperCaseAlpValues;
    legend?: string;
    summaryOperation?: SummaryOperations;
};

export type TWidgetSource = {
    id: NodeId;
    name: string;
    dataset: TWidgetDataset[];
};

export enum DashboardWidgetTypes {
    PieChart = 'PieChart',
    LineChart = 'LineChart',
    BarChart = 'BarChart',
    Summary = 'Summary',
    TextBlock = 'TextBlock',
}

export type TWidget = {
    id: string;
    name: string;
    type: DashboardWidgetTypes;
    geometry: TWidgetGeometry;
    source?: TWidgetSource;
    bgColor?: string;
    border?: boolean;
    text?: string;
};

export type TDashboardModelData = {
    data: TDashboard;
};

export type TDashboardCellsCount = {
    verticalCount: number;
    horizontalCount: number;
};

export type TUpperCaseAlpValues = keyof typeof UpperCaseAlp;

export type TDashboardSymbolType = {
    id: number;
    name: string;
    type: DashboardWidgetTypes;
    icon: {
        content: string;
        id: string;
        node: Node;
        viewBox: string;
    };
};

export enum UpperCaseAlp {
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '',
}
