import React, { useContext, useState } from 'react';
import { getSelectedBlocksMetadata } from 'draftjs-utils';
import icLeft from 'icons/toolbar/controls/ic-text-align-left.svg';
import icCenter from 'icons/toolbar/controls/ic-text-align-center.svg';
import icRight from 'icons/toolbar/controls/ic-text-align-right.svg';
import { useIntl } from 'react-intl';
import icAlignFill from 'icons/toolbar/controls/ic-text-align-fill.svg';
import icArrowDown from 'icons/toolbar/controls/ic-arrow-down.svg';
import { ToolbarButtonGroup, ToolbarButton, ToolbarPopoverButton } from 'UIKit';
import { Button } from 'antd';
import { setSelectedBlocksData } from '../../common/contentBlocks.utils';
import { useSharedState } from '../UseSharedState.hook';
import ControlsContext from '../Controls.context';
import messages from '../CommonToolbar.messages';
import theme from '../RichTextEditorToolbar.scss';

const ButtonGroup = Button.Group;
const alignMap = {
    left: {
        icon: icLeft,
        action: 'left',
    },
    right: {
        icon: icRight,
        action: 'right',
    },
    center: {
        icon: icCenter,
        action: 'center',
    },
    justify: {
        icon: icAlignFill,
        action: 'justify',
    },
};
const getFocusedBlock = (editorState) => getSelectedBlocksMetadata(editorState).get('text-align');
const getChangedBlock = (editorState, value) => setSelectedBlocksData(editorState, { 'text-align': value });

const TextAlignComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const intl = useIntl();
    const [align, setAlign] = useState('center');
    const selectAlignHandler = (newAlign) => () => {
        setAlign(newAlign);
        setCurrentState(newAlign);
    };
    const applyAlignHandler = () => {
        setCurrentState(align);
    };

    const alignButtons = (
        <ToolbarButtonGroup data-test="wiki-toolbar-group_font-align-button_group">
            <ToolbarButton
                tooltip={intl.formatMessage(messages.textLeft)}
                onClick={selectAlignHandler('left')}
                selected={value === 'left'}
                spriteSymbol={icLeft}
                dataTest="wiki-toolbar-group_font-align-button_group_left"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.textCenter)}
                onClick={selectAlignHandler('center')}
                selected={value === 'center'}
                spriteSymbol={icCenter}
                dataTest="wiki-toolbar-group_font-align-button_group_center"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.textRight)}
                onClick={selectAlignHandler('right')}
                selected={value === 'right'}
                spriteSymbol={icRight}
                dataTest="wiki-toolbar-group_font-align-button_group_right"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.textToWidth)}
                onClick={selectAlignHandler('justify')}
                selected={value === 'justify'}
                spriteSymbol={icAlignFill}
                dataTest="wiki-toolbar-group_font-align-button_group_toWidth"
            />
        </ToolbarButtonGroup>
    );

    const selectedAlign = alignMap[align];
    const selectedAlignIcon = selectedAlign.icon;

    return (
        <ButtonGroup className={theme.buttonGroup} data-test="wiki-toolbar-group_font-align-button">
            <ToolbarButton
                className={theme.indicator}
                onClick={applyAlignHandler}
                tooltip={intl.formatMessage(messages.textAlign)}
                spriteSymbol={selectedAlignIcon}
                selected={value === selectedAlign.action}
            />
            <ToolbarPopoverButton
                className={theme.arrow}
                tooltip={intl.formatMessage(messages.textAlign)}
                spriteSymbol={icArrowDown}
                dataTest="wiki-toolbar-group_font-align-button_group_popover"
            >
                {alignButtons}
            </ToolbarPopoverButton>
        </ButtonGroup>
    );
};

export default TextAlignComponent;
