import { TreeNode } from '../../../models/tree.types';
import { LocalesService } from '../../../services/LocalesService';
import type { Node } from '../../../serverapi/api';

export const getCorrectedNodeIdIndex = (index: number, arrLength: number): number => {
    if (index < 0) return arrLength - 1;
    if (index > arrLength - 1) return 0;
    
    return index;
};

export const getNodeName = (node: TreeNode | Node): string => {
    const { multilingualName, name } = node;

    return LocalesService.internationalStringToString(multilingualName) || name || '';
};
