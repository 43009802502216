import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { DuplicatesGroup, MergeObjectStatus, NodeId } from '../serverapi/api';
import { TFindDuplicatesState } from '@/reducers/findDuplicates.types';

const getState = (state: TRootState) => state.findDuplicates;

export const getDuplicatesGroupsByNodeId = (nodeId: NodeId) =>
    createSelector<TRootState, TFindDuplicatesState, DuplicatesGroup[]>([getState], (state) => {
        const duplicatesGroups: DuplicatesGroup[] = state.nodeState.get(nodeId)?.duplicatesGroups || [];

        return duplicatesGroups;
    });

export const getFilteredGroupsByNodeId = (nodeId: NodeId, filter: string) =>
    createSelector<TRootState, TFindDuplicatesState, DuplicatesGroup[]>([getState], (state) => {
        const duplicatesGroups: DuplicatesGroup[] = state.nodeState.get(nodeId)?.duplicatesGroups || [];

        return duplicatesGroups
            .filter((group) => {
                const nameMatches: boolean =
                    group.duplicatesElementInfo[0]?.name?.toLowerCase().includes(filter.toLowerCase()) ?? false;
                const typeMatches: boolean =
                    group.duplicatesElementInfo[0]?.typeId?.toLowerCase().includes(filter.toLowerCase()) ?? false;
                return nameMatches || typeMatches;
            })
            .sort((a, b) => {
                const nameA: string = a.duplicatesElementInfo[0]?.name?.toLowerCase() || '';
                const nameB: string = b.duplicatesElementInfo[0]?.name?.toLowerCase() || '';
                return nameA.localeCompare(nameB);
            });
    });

export const checkFetchingDuplicatesInProgress = () =>
    createSelector<TRootState, TFindDuplicatesState, boolean>(getState, (state) => {
        return state.fetchingDuplicatesInProgress;
    });

export const checkCombiningProcess = () =>
    createSelector<TRootState, TFindDuplicatesState, boolean>(getState, (state) => {
        return state.combiningInProcess;
    });
export const getLastStatuses = (nodeId: NodeId) =>
    createSelector<TRootState, TFindDuplicatesState, MergeObjectStatus[]>(getState, (state) => {
        const statuses: MergeObjectStatus[] = state.statusesState.get(nodeId) || [];

        return statuses;
    });
