import { useEffect, useState } from "react";
import { ExtraButtonProperties } from "../serverapi/api";
import { useCacheFileById } from "./useCacheFileById";
import { TCacheFiles } from "./useCacheFileById.types";

export const useExtraButtonCacheImages = (extraButtonProperties: ExtraButtonProperties[]) => {
    const [imagesIdList, setImagesIdList] = useState<string[]>([]);

    useEffect(() => {
        if (extraButtonProperties?.length) {
            const imagesId: string[] = Array.from(new Set(extraButtonProperties?.filter(p => p?.imageId).map(p => p.imageId!)));
            setImagesIdList(imagesId);
        };
    }, [extraButtonProperties]);

    
    const cacheFiles: TCacheFiles = useCacheFileById(imagesIdList);

    return {
        cacheFiles,
        //  imagesIdList,
        setImagesIdList,
    };
};