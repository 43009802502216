import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ModelType } from '../../../../../../serverapi/api';
import { Dialog } from '../../../../../UIKit/components/Dialog/Dialog.component';
import messages from './ObjectType.messages';
import { GroupedTypesTable } from '../util/GroupedTypesTable.component';
import icAttribute from '../../../../../../resources/icons/ic-attribute.svg';
import theme from './AddModelTypeDialog.scss';
import { SearchInput } from '../../../../../UIKit/components/Select/SearchInput.component';
import { uniqBy } from 'lodash-es';
import { TCommonType } from '../util/GroupedTypesTable.types';
import { DialogFooterButtons } from '../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TAddModelTypeDialogIntlProps = {
    modelTypes: ModelType[];
    dialogVisible: boolean;
};

type TAddModelTypeDialogIntlActionProps = {
    onOk: (modelTypes: ModelType[]) => void;
    onCancel: () => void;
};

type TAddModelTypeDialogIntlFullProps = TAddModelTypeDialogIntlProps & TAddModelTypeDialogIntlActionProps;

export const AddModelTypeDialog = (props: TAddModelTypeDialogIntlFullProps) => {
    const { modelTypes, dialogVisible, onOk, onCancel } = props;

    const intl = useIntl();

    const [newModelTypes, setNewModelTypes] = useState<ModelType[]>([]);
    const [selectedTypeGroups, setSelectedGroups] = useState<TCommonType[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchFilter(e.target.value);
    }

    const modelTypeGroups = uniqBy(
        modelTypes.map((mt) => mt.modelTypeGroup),
        'id',
    );

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: () => onOk(newModelTypes),
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                    dataTest: 'new-model-type-window_save-button',
                },
            ]}
        />
    );

    return (
        <div>
            <Dialog
                className={theme.dialog}
                onCancel={onCancel}
                open={dialogVisible}
                title={intl.formatMessage(messages.addModelType)}
                footer={footer}
            >
                <SearchInput showSearch originalTheme onSearch={handleSearch} searchValue={searchFilter} />
                <div className={theme.title}>{intl.formatMessage(messages.name)}</div>
                <GroupedTypesTable
                    types={modelTypes?.map((type) => ({
                        ...type,
                        groupId: type.modelTypeGroup?.id,
                    }))}
                    typeGroups={modelTypeGroups}
                    searchFilter={searchFilter}
                    actionsDisabled
                    onSelectType={setNewModelTypes as any}
                    selectedTypes={newModelTypes}
                    selectedTypeGroups={selectedTypeGroups}
                    onSelectGroup={setSelectedGroups}
                    icon={icAttribute}
                    hideGUIDColumn
                    disableHeader
                    rowHeight={32}
                    rowClassName={theme.rowTable}
                    containerClassName={theme.tableContainer}
                    minTableWidth={384}
                />
            </Dialog>
        </div>
    );
};
