import React, { FC, useState } from 'react';
import { Form, Input, Select } from 'antd';
import messages from './messages/ObjectDefinition.messages';
import { injectIntl } from 'react-intl';
import theme from './ObjectDefinitionDialog.scss';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { ObjectType } from '../../../../serverapi/api';
import { TObjectDefinitionDialogProps } from './ObjectDefinition.types';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TFormValues = {
    objectDefinitionName: string;
    objectDefinitionType: string;
};

const ObjectDefinitionDialog: FC<TObjectDefinitionDialogProps> = (props) => {
    const { open, intl, formInitData, onClose, onSubmit } = props;
    const [form] = Form.useForm();
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const handleSubmit = () => {
        form.validateFields()
            .then((formValues: TFormValues) => {
                setSubmitDisabled(true);
                const objectDefinitionType = formInitData.objectDefinitionTypes.find(
                    (objectType) => objectType.id === formValues.objectDefinitionType,
                ) as ObjectType;
                onSubmit(
                    formInitData.selectedNode.nodeId,
                    objectDefinitionType,
                    formValues.objectDefinitionName.trim(),
                );
            })
            .catch(() => undefined);
    };

    const selectOptions = formInitData.objectDefinitionTypes.map((option) => {
        return {
            label: option.name,
            value: option.id,
        };
    });

    const children = (
        <Form form={form} layout="vertical">
            <Form.Item
                label={intl.formatMessage(messages.objectDefinitionNameLabel)}
                className={theme.modelName}
                name="objectDefinitionName"
                rules={[
                    {
                        whitespace: true,
                        required: true,
                        message: intl.formatMessage(messages.objectDefinitionNameRequiredError),
                    },
                ]}
            >
                <Input
                    data-test="object-form_folder-name-input"
                    placeholder={intl.formatMessage(messages.objectDefinitionNamePlaceholder)}
                    autoComplete="off"
                    autoFocus
                />
            </Form.Item>
            <Form.Item
                label={intl.formatMessage(messages.objectTypeLabel)}
                className={theme.type}
                name="objectDefinitionType"
                rules={[
                    {
                        required: true,
                        message: formInitData.objectDefinitionTypes.length
                            ? intl.formatMessage(messages.objectTypeRequiredError)
                            : intl.formatMessage(messages.noAvailableObjectTypeError),
                    },
                ]}
            >
                <Select
                    data-test="object-form_object-type-select"
                    placeholder={intl.formatMessage(messages.objectTypePlaceHolder)}
                    showSearch
                    options={selectOptions}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                />
            </Form.Item>
            <p>
                {intl.formatMessage(messages.pathLabel)}: <b>{formInitData.selectedNode?.name}</b>
            </p>
        </Form>
    );

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.objectDefinitionFormDeclineButton),
                    dataTest: 'create-element_object_cancel-button',
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.objectDefinitionFormConfirmButton),
                    visualStyle: 'primary',
                    dataTest: 'create-element_object_save-button',
                    disabled: isSubmitDisabled,
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onClose}
            open={open}
            title={intl.formatMessage(messages.objectDefinitionFormName)}
            footer={footer}
        >
            {children}
        </Dialog>
    );
};

const ObjectDefinitionDialogWithIntl = injectIntl(ObjectDefinitionDialog);

export { ObjectDefinitionDialogWithIntl as ObjectDefinitionDialog };
