import { BPMMxGraph } from './bpmgraph';
import { CustomMap } from '../utils/map';
import { NodeId } from '../serverapi/api';

export const instancesBPMMxGraphMap: CustomMap<NodeId, BPMMxGraph> = new CustomMap();

export const getGraphByServerAndRepository = (serverId: string, repositoryId: string): BPMMxGraph[] =>
    instancesBPMMxGraphMap
        .keys()
        .filter((t) => t.repositoryId === repositoryId && t.serverId === serverId)
        .map((t) => instancesBPMMxGraphMap.get(t))
        .filter((graph): graph is BPMMxGraph => !!graph);
