export const separator = ';';

/**
 * Принимает строку разделенную точкой с запятой и возвращает массив строк, удалив пробелы по бокам каждого элемента и пустые значения
 * @param str string
 * @returns Array<string>
 */
export const convertStringToArray = (str: string): string[] =>
    str
        .split(separator)
        .map((id: string) => id.trim())
        .filter(Boolean);
