import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_FONT_SIZE } from './Controls.constants';
import messages from '../CommonToolbar.messages';
import { FontSizeSelect } from '@/modules/MainMenu/components/FontSizeSelect/FontSizeSelect.component';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../../../Toolbar/Toolbar.scss';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('textStyle').fontSize || DEFAULT_FONT_SIZE;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setFontSize(value).run();

export const FontSizeComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <div data-test="wiki-toolbar-group_select-font-size-button">
            <FontSizeSelect
                onChange={setCurrentState}
                value={value || ''}
                className={theme.selectFontSize}
                tooltipTitle={intl.formatMessage(messages.fontSize)}
            />
        </div>
    );
};
