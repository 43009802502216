import { INode } from '../../models/bpm/bpm-model-impl.types';
import { KanbanBoardType, KanbanCardType, KanbanNode } from '../../serverapi/api';
import { setServerIdToNodeOriginal } from '../../utils/nodeId.utils';
import { apiBundle } from '../api/api-bundle';

export class KanbanDaoService {
    public static async createKanbanBoardType(serverId: string, requestBody: { body: KanbanBoardType }) {
        //const formattedBody = getFormattedKanbanBoardBody(requestBody.body);
        await apiBundle(serverId).kanbanBoardType.create({ body: requestBody.body });
    }

    public static async saveKanbanBoardType(serverId: string, requestBody: { body: KanbanBoardType }) {
        //const formattedBody = getFormattedKanbanBoardBody(requestBody.body);
        await apiBundle(serverId).kanbanBoardType.save({ body: requestBody.body });
    }

    public static async deleteKanbanBoardType(serverId: string, presetId: string, id: string) {
        await apiBundle(serverId).kanbanBoardType._delete({ presetId, id });
    }

    public static async getKanbanBoardTypes(serverId: string, presetId: string): Promise<KanbanBoardType[]> {
        const kanbanBoardTypes: KanbanBoardType[] = await apiBundle(serverId).kanbanBoardType.byPresetId({
            presetId,
        });
        return kanbanBoardTypes;
    }

    public static async createKanbanCardType(serverId: string, kanbanCardType: KanbanCardType) {
        await apiBundle(serverId).kanbanCardType.create({ body: kanbanCardType });
    }

    public static async saveKanbanCardType(serverId: string, kanbanCardType: KanbanCardType) {
        await apiBundle(serverId).kanbanCardType.save({ body: kanbanCardType });
    }

    public static async deleteKanbanCardType(serverId: string, presetId: string, id: string) {
        await apiBundle(serverId).kanbanCardType._delete({ presetId, id });
    }

    public static async getKanbanCardTypes(serverId: string, presetId: string): Promise<KanbanCardType[]> {
        const kanbanCardTypes: KanbanCardType[] = await apiBundle(serverId).kanbanCardType.byPresetId({
            presetId,
        });

        return kanbanCardTypes;
    }

    public static async createKanban(serverId: string, kanban: KanbanNode): Promise<KanbanNode> {
        const kanbanModel = await apiBundle(serverId).kanban.save({ body: kanban });
        setServerIdToNodeOriginal(kanbanModel as INode, serverId);
        return kanbanModel;
    }

    public static async getKanbanById(serverId: string, repositoryId: string, kanbanId: string): Promise<KanbanNode> {
        const kanbanModel = await apiBundle(serverId).kanban.byId({ repositoryId, kanbanId });
        setServerIdToNodeOriginal(kanbanModel as INode, serverId);
        return kanbanModel;
    }
}
