import { ADD_APPROVAL_TEMPLATES, CHANGE_CHECK_ALL_APPROVAL_TEMPLATES, CHECK_APPROVAL_TEMPLATES, CREATE_APPROVAL_TEMPLATE, DELETE_APPROVAL_TEMPLATES, DELETE_APPROVAL_TEMPLATES_FROM_STORE, EDIT_APPROVAL_TEMPLATE, EDIT_EXISTING_APPROVAL_TEMPLATE, GET_APPROVAL_TEMPLATES, SUBMIT_APPROVAL_TEMPLATE } from "@/actionsTypes/approvalTemplates.actionTypes";
import { TAddApprovalTemplatesAction, TAddApprovalTemplatesPayload, TChangeCheckAllApprovalTemplatesAction, TCheckApprovalTemplatesAction, TCreateApprovalTemplateAction, TDeleteApprovalTemplatesAction, TDeleteApprovalTemplatesPayload, TDeleteApprovalTemplatesFromStoreAction, TEditApprovalTemplateAction, TEditApprovalTemplatePayload, TEditExistingApprovalTemplateAction, TEditExistingApprovalTemplatePayload, TSubmitApprovalTemplateAction, TSubmitApprovalTemplatePayload, TGetApprovalTemplatesAction } from "./approvalTemplates.actions.types";

export const addApprovalTemplates = (payload: TAddApprovalTemplatesPayload): TAddApprovalTemplatesAction => ({
    type: ADD_APPROVAL_TEMPLATES,
    payload,
});
 
export const checkApprovalTemplates = (id: string[]): TCheckApprovalTemplatesAction => ({
    type: CHECK_APPROVAL_TEMPLATES,
    payload: {
        id,
    },
});

export const changeCheckAllApprovalTemplates = (): TChangeCheckAllApprovalTemplatesAction => ({
    type: CHANGE_CHECK_ALL_APPROVAL_TEMPLATES,
});

export const createApprovalTemplate = (): TCreateApprovalTemplateAction => ({
    type: CREATE_APPROVAL_TEMPLATE,
});

export const submitApprovalTemplate = (payload: TSubmitApprovalTemplatePayload): TSubmitApprovalTemplateAction => ({
    type: SUBMIT_APPROVAL_TEMPLATE,
    payload,
});

export const editApprovalTemplate = (payload: TEditApprovalTemplatePayload): TEditApprovalTemplateAction => ({
    type: EDIT_APPROVAL_TEMPLATE,
    payload,
});

export const deleteApprovalTemplates = (payload: TDeleteApprovalTemplatesPayload): TDeleteApprovalTemplatesAction => ({
    type: DELETE_APPROVAL_TEMPLATES,
    payload,
});

export const deleteApprovalTemplatesFromStore = (payload: TDeleteApprovalTemplatesPayload): TDeleteApprovalTemplatesFromStoreAction => ({
    type: DELETE_APPROVAL_TEMPLATES_FROM_STORE,
    payload,
});

export const editExistingApprovalTemplate = (payload: TEditExistingApprovalTemplatePayload): TEditExistingApprovalTemplateAction => ({
    type: EDIT_EXISTING_APPROVAL_TEMPLATE,
    payload,
});

export const getApprovalTemplates = (): TGetApprovalTemplatesAction => ({
    type: GET_APPROVAL_TEMPLATES,
});
