import { DefaultGraph } from '../DefaultGraph';
import { MxCell, MxCellState } from '../mxgraph';
import { LayoutInstanceImpl } from '../../models/bpm/bpm-model-impl';
import { BPMMxCellEditor } from '../BPMMxCellEditor.class';

export class PSDGraph extends DefaultGraph {
    symbolsInLabelLine = 34;
    crlf = '\r\n';
    space = ' ';
    emptyString = '';

    intersects(state: MxCellState, x: number, y: number): boolean {
        const bounds = state.getCellBounds();

        return super.intersects(
            {
                x: bounds.x,
                y: bounds.y,
                width: bounds.width,
                height: bounds.height,
            } as any,
            x,
            y,
        );
    }

    moveCells(
        cells: MxCell[],
        dx: number,
        dy: number,
        clone: boolean,
        target?: MxCell,
        evt?: Event,
        mapping?: {},
    ): MxCell[] {
        const point = evt && this.getPointForEvent(evt, true);
        const isLayout =
            cells && cells.filter((cell: MxCell) => cell && cell.getValue() instanceof LayoutInstanceImpl).length > 0;
        const targetCell: MxCell =
            target ||
            this.getCellAt(
                point ? point.x : dx,
                point ? point.y : dy,
                this.getDefaultParent(),
                true,
                false,
                (state: MxCellState) => state.cell.getValue()?.type === 'object',
            ) ||
            this.getDefaultParent();

        return super.moveCells(cells, dx, dy, clone, isLayout ? target! : targetCell, evt, mapping);
    }

    createCellEditor(): PSDCellEditor {
        return new PSDCellEditor(this);
    }

    convertValueToString(cell: MxCell): string {
        const value = cell.getValue();
        if (value instanceof LayoutInstanceImpl) {
            if (value.isPSDCell && value.psdCellMetaInfo!.name) {
                const name = value.psdCellMetaInfo ? value.psdCellMetaInfo.name : this.emptyString;
                let maxLength = this.symbolsInLabelLine;

                return name.split(this.space).reduce((acc, val) => {
                    val = val.replace(/(\r\n|\n|\r)/gm, this.emptyString);
                    if (acc.length + val.length > maxLength) {
                        acc = acc.concat(this.crlf);
                        maxLength += this.symbolsInLabelLine - this.crlf.length;
                    }

                    return acc.concat(this.space).concat(val);
                });
            }

            return super.convertValueToString(cell);
        }

        return super.convertValueToString(cell);
    }
}

class PSDCellEditor extends BPMMxCellEditor {
    resize() {
        super.resize();

        if (this.textarea != null) {
            if (this.editingCell && this.editingCell.value && this.editingCell.value.style === 'headVertical') {
                this.textarea.style.width = `${this.bounds ? this.bounds.height : 100}px`;
            } else {
                this.textarea.style.width = `${this.bounds ? this.bounds.width : 100}px`;
            }
            this.textarea.style.wordBreak = 'normal';
            this.textarea.style.whiteSpace = 'normal';
            this.textarea.style.wordWrap = 'break-word';
        }
    }
}
