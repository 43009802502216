import { put, select, takeEvery } from 'redux-saga/effects';
import { TChangedProperties } from '../actions/changedProperties.types';
import { SYNC_GRAPH_ON_ATTRIBUTE_CHANGE, UPSERT_ATTRIBUTE_VALUE } from '../actionsTypes/attribute.actionTypes';
import { EdgeInstanceImpl, ObjectInstanceImpl } from '../models/bpm/bpm-model-impl';
import { buildPropDescriptorByAttrType } from '../models/properties/property-descriptor';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { AttributeTypeSelectors } from '../selectors/attributeType.selectors';
import { v4 as uuid } from 'uuid';
import { navigatorPropertiesChangePropertySetAction } from '../actions/navigatorProperties.actions';
import { TNavigatorPropertiesData } from '../models/navigatorPropertiesSelectorState.types';
import { TSyncGraphOnAttributeChangeAction, TUpsertAttributeValueAction } from '../actions/atrribute.actions.types';
import { checkBooleanValue } from '../modules/ObjectPropertiesDialog/components/utils';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { LifelineSymbolMainClass } from '@/mxgraph/ComplexSymbols/symbols/LifeLine/LifelineSymbolMain.class';
import { UML_DESTROY_ATTRIBUTE_TYPE_ID } from '@/mxgraph/ComplexSymbols/symbols/Sequence/sequence.constants';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { AttributeValue } from '../serverapi/api';

function* handleUpsertAttributeValue({ payload }: TUpsertAttributeValueAction) {
    const { graphId, cellId, presetId, newAttributeValue } = payload;
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(graphId);
    const attributeType = yield select(
        AttributeTypeSelectors.byId(graphId.serverId, presetId, newAttributeValue.typeId),
    );

    if (!attributeType) return;

    const cell = graph?.getModel().getCell(cellId);
    const instance = <ObjectInstanceImpl | EdgeInstanceImpl | undefined>cell?.value;
    const existingAttribute: AttributeValue | undefined = instance?.attributes?.find((a) => a.typeId === newAttributeValue.typeId);
    const attributeId = existingAttribute?.id || newAttributeValue?.id || uuid();
    const descriptor = buildPropDescriptorByAttrType(attributeType, attributeId);
    const changedProperties: TChangedProperties = {
        [descriptor.key]: {
            descriptor,
            graphId,
            cellId,
            value: {
                ...existingAttribute,
                ...newAttributeValue,
                id: attributeId,
            },
        },
    };

    yield put(
        navigatorPropertiesChangePropertySetAction({
            graphId,
            cellId,
            changes: changedProperties as TNavigatorPropertiesData,
            removed: [],
        }),
    );
}

function* handleSyncGraphOnAttributeChange({ payload }: TSyncGraphOnAttributeChangeAction) {
    const { graphId, cellId, attributes } = payload;
    const graph = instancesBPMMxGraphMap.get(graphId);

    if (!graph) return;

    const cell = graph.getModel().getCell(cellId);

    if (!cell) return;

    if (!attributes?.length) return;

    attributes.forEach((attribute) => {
        switch (attribute.typeId) {
            case UML_DESTROY_ATTRIBUTE_TYPE_ID:
                const complexSymbol = ComplexSymbolManager.getComplexSymbolInstance(cell) as LifelineSymbolMainClass;
                if (complexSymbol) {
                    if (checkBooleanValue(attribute.value)) {
                        complexSymbol.createDestroySymbol && complexSymbol.createDestroySymbol(cell);
                    } else {
                        complexSymbol.deleteDestroySymbol && complexSymbol.deleteDestroySymbol(cell);
                    }
                }
                break;
        }
    });
}

export function* atrributeSaga() {
    yield takeEvery(UPSERT_ATTRIBUTE_VALUE, handleUpsertAttributeValue);
    yield takeEvery(SYNC_GRAPH_ON_ATTRIBUTE_CHANGE, handleSyncGraphOnAttributeChange);
}
