import { defineMessages } from 'react-intl';

export default defineMessages({
    id: {
        id: 'approvalTemplates.id',
        defaultMessage: 'ID',
    },
    name: {
        id: 'approvalTemplates.name',
        defaultMessage: 'Название',
    },
    delete: {
        id: 'approvalTemplates.delete',
        defaultMessage: 'Удалить',
    },
    confirmTitle: {
        id: 'approvalTemplates.confirmTitle',
        defaultMessage: 'Удаление шаблонов согласования(ий)',
    },
    confirmQuestion: {
        id: 'approvalTemplates.confirmQuestion',
        defaultMessage: 'Удалить шаблон(ы) согласоваания?',
    },
    create: {
        id: 'approvalTemplates.create',
        defaultMessage: 'Создать шаблон',
    },
    edit: {
        id: 'approvalTemplates.edit',
        defaultMessage: 'Редактировать',
    },
    header: {
        id: 'approvalTemplates.header',
        defaultMessage: 'Шаблоны согласования',
    },
    selectButtonText: {
        id: 'approvalTemplates.selectButtonText',
        defaultMessage: 'Выбрать',
    },
    cancelButtonText: {
        id: 'approvalTemplates.cancelButtonText',
        defaultMessage: 'Отмена',
    },
    noData: {
        id: 'approvalTemplates.noData',
        defaultMessage: 'Нет данных',
    },
    deletingApproval: {
        id: 'approval.deletingApproval',
        defaultMessage: 'Удаление согласования(ий)',
    },
    deletingApprovalDescription: {
        id: 'approval.deletingApprovalDescription',
        defaultMessage: 'Согласование(ия) и все этапы будут удалены без возможности восстановления',
    },
});
