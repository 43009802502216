import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'FloatingAttributesDialog.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'FloatingAttributesDialog.save',
        defaultMessage: 'Сохранить',
    },
    mainTitle: {
        id: 'FloatingAttributesPanel.position.title',
        defaultMessage: 'Расположение атрибутов',
    },
    subTitleFor: {
        id: 'FloatingAttributesPanel.position.subtitleFor',
        defaultMessage: 'Расположение атрибутов для',
    },
    disablePreset: {
        id: 'FloatingAttributesPanel.disablePreset',
        defaultMessage: 'Отключить настройки методологии',
    },
    displayAttributesInColumn: {
        id: 'FloatingAttributesPanel.displayAttributesInColumn',
        defaultMessage: 'Показывать атрибуты в столбец',
    },
    ignorePresetStylesPostfix: {
        id: 'FloatingAttributesPanel.ignorePresetStylesPostfix',
        defaultMessage: '(наследовано)',
    },
});
