import React, { useEffect, useState } from 'react';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { Checkbox, Input, Modal, Select } from 'antd';
import style from './EditImportVisioPageHeaderDialogComponent.scss';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useDispatch, useSelector } from 'react-redux';
import { ImportSelectors } from '../../../../../selectors/import.selectors';
import { setPageOptions } from '../../../../../actions/import.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../messages/EditImportVisioPageHeaderDialogComponent.messages';
import { shouldCallModalSubmitHandler } from '../../../../../services/utils/ModalHelper';
import { DialogFooterButtons } from '../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

const Row = ({ name, children }) => {
    return (
        <div className={style.row}>
            <span>{name}</span>
            <span>{children}</span>
        </div>
    );
};

export const EditImportVisioPageHeaderDialogComponent = ({
    visioPageId,
    fileName,
    silaModelName,
    silaModelTypeId,
    silaModelTypeName,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const modelTypes = useSelector(ImportSelectors.modelTypes);

    const [options, setOptions] = useState({
        silaModelTypeId: '',
        silaModelName: '',
        silaModelTypeName: '',
        mapToAllPages: false,
    });

    useEffect(() => {
        setOptions({
            ...options,
            silaModelTypeId,
            silaModelName,
            silaModelTypeName,
        });
    }, []);

    const handleSelect = (silaModelTypeId: string) => {
        const type = modelTypes.find((type) => type.id === silaModelTypeId);
        setOptions({
            ...options,
            silaModelTypeId,
            silaModelTypeName: type?.name || '',
        });
    };

    const handleCancelClick = () => {
        dispatch(closeDialog(DialogType.EDIT_IMPORT_VISIO_PAGE_HEADER));
    };

    const handleSaveClick = () => {
        dispatch(
            setPageOptions({
                fileName,
                visioPageId,
                silaModelName: options.silaModelName,
                silaModelTypeId: options.silaModelTypeId,
                silaModelTypeName: options.silaModelTypeName,
                mapToAllPages: options.mapToAllPages,
            }),
        );
        dispatch(closeDialog(DialogType.EDIT_IMPORT_VISIO_PAGE_HEADER));
    };

    const handleChangeInput = (e) => {
        setOptions({
            ...options,
            silaModelName: e.target.value,
        });
    };

    const handleCheckboxChange = (e) => {
        setOptions({
            ...options,
            mapToAllPages: e.target.checked,
        });
    };

    const selectOptions = modelTypes.map((type) => {
        return { label: type.name, value: type.id };
    });

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCancelClick,
                    value: intl.formatMessage(messages.cancelButtonText),
                },
                {
                    key: 'ok',
                    onClick: handleSaveClick,
                    value: intl.formatMessage(messages.okButtonText),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    handleSaveClick();
                }
            }}
        >
            <Modal
                width={400}
                open
                onOk={handleSaveClick}
                onCancel={handleCancelClick}
                footer={footer}
                title={<FormattedMessage {...messages.title} />}
            >
                <div className={style.dialogContainer}>
                    <Row name={<FormattedMessage {...messages.modelName} />}>
                        <Input defaultValue={silaModelName} style={{ width: '200px' }} onChange={handleChangeInput} />
                    </Row>
                    <Row name={<FormattedMessage {...messages.modelType} />}>
                        <Select
                            data-test="import-visio_edit-model-type-select"
                            showSearch
                            options={selectOptions}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            defaultValue={silaModelTypeId}
                            className={style.typeSelect}
                            onChange={handleSelect}
                        />
                    </Row>
                    <Row name={<FormattedMessage {...messages.mapToAll} />}>
                        <Checkbox onChange={handleCheckboxChange} style={{ marginLeft: '20px', marginTop: '4px' }} />
                    </Row>
                </div>
            </Modal>
        </div>
    );
};
