import { Col, Row } from 'antd';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { EditButton, RowButtons } from '../../Button/RowButtons';
import messages from '../messages/userManagment.messages';
import theme from './UserManagment.scss';

type TUserPermisionDataBlockActionProps = {
    onEdit: (id: string) => void;
};

type TUserPermisionDataBlockProps = {
    data: string[] | undefined;
    label: string;
    id: string;
    editable?: boolean;
} & TUserPermisionDataBlockActionProps &
    WrappedComponentProps;

const UserPermisionDataBlock = (props: TUserPermisionDataBlockProps) => {
    const { label, data, onEdit, id, intl, editable = true } = props;

    return (
        <div data-test="user-permissions-block">
            <Row>
                <Col span={16}>
                    <h3>{label}</h3>
                </Col>
                <Col span={8} style={{ textAlign: 'right', lineHeight: '2' }}>
                    {editable && <RowButtons buttons={[EditButton.build(() => onEdit(id))]} />}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className={theme.permissionsDataContainer}>
                        {data ? data.join('\r\n') : intl.formatMessage(messages.noInfoAvailable)}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default injectIntl(UserPermisionDataBlock);
