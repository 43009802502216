export const scrollIntoView = (id?: string) => {
    if (!id) return;

    const element: HTMLElement | null = document.getElementById(id);

    if (element) {
        element.style.backgroundColor = '#c2eafd';
        element.style.transition = '1s';
        element.setAttribute('data-test', 'backlight-comment')
        setTimeout(() => {
            element.style.backgroundColor = '#fff';
            element.removeAttribute('data-test');
        }, 1000);
    }

    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};
