import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TAdminToolsParamsKeys } from '../modules/AdminTools/data/admintool.types';

export const metaData = (state: TRootState) => state.admintool;

export namespace MetaDataSelectors {
    export const getActiveTypeId = createSelector(metaData, (state) => state.activeTypeId);

    export const getCurrentServerId = createSelector(metaData, (state) => state.currentServerId);

    export const getParams = (key: TAdminToolsParamsKeys) =>
        createSelector(metaData, (state) =>
            state && state.params && state.params[key] ? state.params[key] : undefined,
        );
}
