import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'ApprovalDialog.cancel',
        defaultMessage: 'Отмена',
    },
    saveAsDraft: {
        id: 'ApprovalDialog.saveAsDraft',
        defaultMessage: 'Сохранить как черновик',
    },
    createAndBegin: {
        id: 'ApprovalDialog.createAndBegin',
        defaultMessage: 'Создать и начать',
    },
    create: {
        id: 'ApprovalDialog.create',
        defaultMessage: 'Создать',
    },
    creatingNewApproval: {
        id: 'ApprovalDialog.creatingNewApproval',
        defaultMessage: 'Cоздание нового согласования',
    },
    generalSettings: {
        id: 'ApprovalDialog.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    approvalStages: {
        id: 'ApprovalDialog.approvalStages',
        defaultMessage: 'Этапы согласования',
    },
    name: {
        id: 'ApprovalDialog.name',
        defaultMessage: 'Название',
    },
    description: {
        id: 'ApprovalDialog.description',
        defaultMessage: 'Описание',
    },
    youEnteredOnlyWhitespaces: {
        id: 'ApprovalDialog.youEnteredOnlyWhitespaces',
        defaultMessage: 'Вы ввели только пробелы',
    },
    thisFieldIsRequired: {
        id: 'ApprovalDialog.thisFieldIsRequired',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    delete: {
        id: 'ApprovalDialog.delete',
        defaultMessage: 'Удалить',
    },
    deletingApprovalStage: {
        id: 'ApprovalDialog.deletingApprovalStage',
        defaultMessage: 'Удаление этапа согласования',
    },
    deleteStageConfirmationDescription: {
        id: 'ApprovalDialog.deleteStageConfirmationDescription',
        defaultMessage: 'Этап согласования "{name}" будет удалён без возможности его восстановления',
    },
    stageName: {
        id: 'ApprovalDialog.stageName',
        defaultMessage: 'Название этапа',
    },
    emptyStagesError: {
        id: 'ApprovalDialog.emptyStagesError',
        defaultMessage: 'Необходимо добавить этапы согласования',
    },
    listOfApprovers: {
        id: 'ApprovalDialog.listOfApprovers',
        defaultMessage: 'Список согласующих',
    },
    selectUser: {
        id: 'ApprovalDialog.selectUser',
        defaultMessage: 'Выберите пользователя',
    },
    approvalType: {
        id: 'ApprovalDialog.approvalType',
        defaultMessage: 'Тип согласования',
    },
    following: {
        id: 'ApprovalDialog.following',
        defaultMessage: 'Последовательное',
    },
    followingSuccessive: {
        id: 'ApprovalDialog.followingSuccessive',
        defaultMessage: 'Последовательное (Только положительное)',
    },
    parallel: {
        id: 'ApprovalDialog.parallel',
        defaultMessage: 'Параллельное',
    },
    restrictVotingTime: {
        id: 'ApprovalDialog.restrictVotingTime',
        defaultMessage: 'Ограничить время согласования',
    },
    finishDateTime: {
        id: 'ApprovalDialog.finishDateTime',
        defaultMessage: 'Дата и время окончания',
    },
    selectDateTime: {
        id: 'ApprovalDialog.selectDateTime',
        defaultMessage: 'Выберите дату и время',
    },
    abstentionsCountedAs: {
        id: 'ApprovalDialog.abstentionsCountedAs',
        defaultMessage: 'Голоса воздержавшихся считать как',
    },
    approved: {
        id: 'ApprovalDialog.approved',
        defaultMessage: 'Согласован',
    },
    notApproved: {
        id: 'ApprovalDialog.notApproved',
        defaultMessage: 'Не согласован',
    },
    allowVoteChange: {
        id: 'ApprovalDialog.allowVoteChange',
        defaultMessage: 'Разрешить изменение голоса',
    },
    rateOfSuccessLabel: {
        id: 'ApprovalDialog.rateOfSuccessLabel',
        defaultMessage: 'Принцип признания согласования успешным',
    },
    percentage: {
        id: 'ApprovalDialog.percentage',
        defaultMessage: 'Процентный',
    },
    allVotedPositive: {
        id: 'ApprovalDialog.allVotedPositive',
        defaultMessage: 'Все проголосовали ЗА',
    },
    selectPositiveVotePercentage: {
        id: 'ApprovalDialog.selectPositiveVotePercentage',
        defaultMessage: 'Укажите % успешного голосования (>=)',
    },
    save: {
        id: 'ApprovalDialog.save',
        defaultMessage: 'Сохранить',
    },
    editApproval: {
        id: 'ApprovalDialog.editApproval',
        defaultMessage: 'Редактирование согласования',
    },
    cancelled: {
        id: 'ApprovalDialog.cancelled',
        defaultMessage: 'Отменено',
    },
    approvalInProcess: {
        id: 'ApprovalDialog.approvalInProcess',
        defaultMessage: 'В процессе согласования',
    },
    cancelApproval: {
        id: 'ApprovalDialog.cancelApproval',
        defaultMessage: 'Отменить согласование',
    },
    resumeApproval: {
        id: 'ApprovalDialog.resumeApproval',
        defaultMessage: 'Возобновить согласование',
    },
    deletingApproval: {
        id: 'ApprovalDialog.deletingApproval',
        defaultMessage: 'Удаление согласования',
    },
    deletingApprovalDescription: {
        id: 'ApprovalDialog.deletingApprovalDescription',
        defaultMessage: 'Согласование и все его этапы будут удалены без возможности восстановления',
    },
    newStage: {
        id: 'ApprovalDialog.newStage',
        defaultMessage: 'Новый этап',
    },
    apply: {
        id: 'ApprovalDialog.apply',
        defaultMessage: 'Применить',
    },
    editStatusApproved: {
        id: 'ApprovalDialog.editStatusApproved',
        defaultMessage: 'Согласовано',
    },
    editStatusNotApproved: {
        id: 'ApprovalDialog.editStatusNotApproved',
        defaultMessage: 'Не согласовано',
    },
    editStatusDraft: {
        id: 'ApprovalDialog.editStatusDraft',
        defaultMessage: 'Черновик',
    },
    newApprovalTemplate: {
        id: 'ApprovalDialog.newApprovalTemplate',
        defaultMessage: 'Новый шаблон согласования',
    },
    editApprovalTemplate: {
        id: 'ApprovalDialog.editApprovalTemplate',
        defaultMessage: 'Редактирование шаблона согласования',
    },
    approvalTemplateName: {
        id: 'ApprovalDialog.approvalTemplateName',
        defaultMessage: 'Название шаблона',
    },
    approvalName: {
        id: 'ApprovalDialog.approvalName',
        defaultMessage: 'Название согласования',
    },
    approvalTemplateDescription: {
        id: 'ApprovalDialog.approvalTemplateDescription',
        defaultMessage: 'Описание шаблона согласования',
    },
    addAssistant: {
        id: 'ApprovalDialog.addAssistant',
        defaultMessage: 'Добавить заместителя',
    },
});
