import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { EventDescriptorMacros, NodeId } from '../serverapi/api';
import { TModelEventsState } from '@/reducers/modelEvents.reducer.types';

export namespace ModelEventsSelectors {
    export const modelEventsStateSelector = (state: TRootState) => state.modelEvents;

    export const loading = createSelector(modelEventsStateSelector, (state) => state.loading);

    export const byId = (nodeId: NodeId) =>
        createSelector<TRootState, TModelEventsState, EventDescriptorMacros[]>(
            modelEventsStateSelector,
            (state) => state.data.get(nodeId) || [],
        );

    export const scripts = createSelector(modelEventsStateSelector, (state) => state.scripts);
}
