import { Input } from '@/modules/UIKit/components/Input/Input.component';
import { Tooltip } from 'antd';
import React, { useState, useRef, useEffect, InputHTMLAttributes, ChangeEvent } from 'react';
import { FieldValues, Path, PathValue } from 'react-hook-form';

interface IOverflowTipInputProps<T extends FieldValues> extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'onChange'> {
    value?: PathValue<T, Path<T>>;
    name: Path<T>;
    onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
}

export const OverflowTipInput = <T extends FieldValues>({ onChange, ...props }: IOverflowTipInputProps<T>) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const checkOverflow = () => {
        const element = inputRef?.current;
        if (element) {
            const isOverflowing = element.scrollWidth > element.clientWidth;
            setIsOverflowed(isOverflowing);
        }
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => window.removeEventListener('resize', checkOverflow);
    }, [props.value]);

    return (
        <Tooltip title={isOverflowed ? props.value : ''} placement="top">
            <Input
                {...props}
                ref={inputRef}
                onChange={onChange}
                onMouseEnter={() => {
                    if (!inputRef?.current) return;

                    const { scrollWidth, clientWidth } = inputRef.current;

                    setIsOverflowed(scrollWidth > clientWidth);
                }}
            />
        </Tooltip>
    );
};
