import { connect } from 'react-redux';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { TRemoveUserFromGroupDialogActionProps } from '../RemoveUserFromGroupDialog.types';
import RemoveUserFromGroupDialogComponent from '../components/RemoveUserFromGroupDialog.component';
import { removeUsersFromGroup } from '../../../../actions/groups.actions';
import { TRemoveUsersFromGroupPayload } from '../../../../actions/groups.actions.types';

const mapDispatchToProps: (dispatch) => TRemoveUserFromGroupDialogActionProps = (dispatch) => ({
    onCancel: () => dispatch(closeDialog(DialogType.REMOVE_USER_FROM_GROUP)),
    onConfirm: (removePayload: TRemoveUsersFromGroupPayload) => {
        dispatch(removeUsersFromGroup(removePayload));
    },
});

export default connect(null, mapDispatchToProps)(RemoveUserFromGroupDialogComponent);
