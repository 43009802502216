import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import React, { FC, useState } from 'react';
import messages from './AttributeSelectDialog.messages';
import { useIntl } from 'react-intl';
import theme from './AttributeSelectDialog.scss';
import { AttributeType, NodeId, ReportColumnData, ReportColumnDataAttributeTypeEnum } from '@/serverapi/api';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { LARGE_DIALOG_WIDTH } from '@/config/config';
import { sortByAlpha } from '@/utils/sortByAlpha';
import { SearchInput } from '@/modules/UIKit/components/Select/SearchInput.component';
import { useDispatch, useSelector } from 'react-redux';
import { reportSetColumnData } from '../../../../actions/report.actions';
import { TReportColumnData } from '../../../../actions/report.actions.types';
import { ReportSelectors } from '../../../../selectors/report.selectors';

type TAttributeSelectDialog = {
    reportNodeId: NodeId;
    open: boolean;
    currentColumn?: ReportColumnData;
    setOpen: (isOpent: boolean) => void;
};

export const AttributeSelectDialog: FC<TAttributeSelectDialog> = ({ open, currentColumn, reportNodeId, setOpen }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const attributeTypes: AttributeType[] = useSelector(ReportSelectors.getUnusedAttributeTypes(reportNodeId));
    const systemAttributeTypes: AttributeType[] = useSelector(
        ReportSelectors.getAccessibleSystemAttributeTypes(reportNodeId),
    );

    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedAttributeData, setSelectedAttributeData] = useState<TReportColumnData>();

    const cancelHandler = () => {
        setOpen(false);
        setSelectedAttributeData(undefined);
    };

    const submitHandler = () => {
        if (currentColumn && selectedAttributeData) {
            dispatch(reportSetColumnData(reportNodeId, currentColumn.columnId, selectedAttributeData));
        }
        cancelHandler();
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: cancelHandler,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: submitHandler,
                    value: intl.formatMessage(messages.submit),
                    visualStyle: 'primary',
                    disabled: !selectedAttributeData?.attributeTypeId,
                },
            ]}
        />
    );

    const columns: TColumn[] = [
        {
            dataKey: 'name',
            title: intl.formatMessage(messages.attributeType),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.name as string, b.name as string),
            defaultSort: 'ASC',
        },
        {
            dataKey: 'id',
            title: intl.formatMessage(messages.attributeTypeId),
            withoutSorter: true,
        },
    ];
    const attributeTypesData: TTableData[] = attributeTypes
        .filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()))
        .map(({ name, id }) => ({
            name,
            id,
            selected: selectedAttributeData?.attributeTypeId === id,
        }));

    const systemAttributeTypesData: TTableData[] = systemAttributeTypes
        .filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()))
        .map(({ name, id }) => ({
            name,
            id,
            selected: selectedAttributeData?.attributeTypeId === id,
        }));

    const onRowClickHandler = (rowData, attributeType: ReportColumnDataAttributeTypeEnum) => {
        if (rowData.id && rowData.name) {
            setSelectedAttributeData({
                attributeType,
                attributeTypeId: rowData.id,
                columnName: rowData.name,
            });
        }
    };

    return (
        <Dialog
            open={open}
            footer={footer}
            onCancel={cancelHandler}
            onOk={submitHandler}
            title={intl.formatMessage(messages.title)}
            className={theme.dialog}
            width={LARGE_DIALOG_WIDTH}
        >
            <SearchInput
                showSearch
                onSearch={(e) => setSearchValue(e.target.value)}
                originalTheme
                searchValue={searchValue}
            />
            <div className={theme.divider} />
            <div className={theme.tableTitle}>{intl.formatMessage(messages.userAtrtribures)}</div>
            <div className={theme.tableContainer}>
                <TableUIKit
                    columns={columns}
                    tableData={attributeTypesData}
                    onRowClick={(rowData) => onRowClickHandler(rowData, 'USER')}
                />
            </div>
            <div className={theme.divider} />
            <div className={theme.tableTitle}>{intl.formatMessage(messages.systemAtrtribures)}</div>
            <div className={theme.tableContainer}>
                <TableUIKit
                    columns={columns}
                    tableData={systemAttributeTypesData}
                    onRowClick={(rowData) => onRowClickHandler(rowData, 'SYSTEM')}
                />
            </div>
        </Dialog>
    );
};
