import { TReducer } from '../../utils/types';
import { ADMINTOOL_SELECTED, ADMINTOOL_SET_PARAMS } from '../../actionsTypes/admintools.actionTypes';
import { TAdminToolState } from './admintool.reducer.types';

const initState: TAdminToolState = {
    activeTypeId: '',
    currentServerId: '',
    params: undefined,
};

export const adminToolReducer: TReducer<TAdminToolState> = (state = initState, action) => {
    switch (action.type) {
        case ADMINTOOL_SELECTED: {
            const {
                payload: { activeTypeId, currentServerId },
            } = action;

            return {
                ...state,
                activeTypeId,
                currentServerId,
            };
        }
        case ADMINTOOL_SET_PARAMS: {
            const {
                payload: { params },
            } = action;

            return {
                ...state,
                params: {
                    ...state.params,
                    ...params,
                },
            };
        }
        default:
            return state;
    }
};
