import React, { FC } from 'react';
import theme from './TextBlockSettings.scss'; 
import { Col, ColorPicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { TWidget } from '../../Dashboard.types';
import { useIntl } from 'react-intl';
import messages from './TextBlockSettings.messages';
import { chartColors } from '../../DashboardsConsts';
import { Color } from 'antd/es/color-picker/color';
import { setWidgetBg, setWidgetBorder } from '@/actions/dashboard.actions';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';

enum DisplayValue {
    DISPLAY = 'DISPLAY',
    NOT_DISPLAY = 'NOT_DISPLAY',
}

export const TextBlockSettings: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const currentWidget: TWidget | undefined = useSelector(DashboardSelector.selectedWidget(activeTab?.nodeId));

    const onChangeBordersDisplay = (value: string) => {
        if (activeTab?.nodeId && currentWidget?.id)
            dispatch(setWidgetBorder(activeTab.nodeId, currentWidget.id, value === DisplayValue.DISPLAY));
    };

    const onChangeBgColor = (value: Color, hex: string) => {
        if (activeTab?.nodeId && currentWidget?.id) dispatch(setWidgetBg(activeTab.nodeId, currentWidget.id, hex));
    };

    const radioOptions: TRadioOption<DisplayValue>[] = [
        {
            title: intl.formatMessage(messages.display),
            value: DisplayValue.DISPLAY,
            dataTest: "dashboard_widget-settings-panel_borders_display-radio",
        },
        {
            title: intl.formatMessage(messages.notDisplay),
            value: DisplayValue.NOT_DISPLAY,
            dataTest: "dashboard_widget-settings-panel_borders_not-display-radio",
        },
    ];

    return (
        <>
            {currentWidget && (
                <div>
                    <Col className={theme.inputContainer} data-test="dashboard_widget-settings-panel_background-color">
                        <div className={theme.label}>{intl.formatMessage(messages.bgColor)}</div>
                        <ColorPicker
                            size="small"
                            className={theme.colorPicker}
                            showText
                            onChange={onChangeBgColor}
                            value={currentWidget.bgColor || '#ffffff'}
                            presets={[{ label: intl.formatMessage(messages.recomendedColors), colors: chartColors }]}
                        />
                    </Col>
                    <Col className={theme.inputContainer} data-test="dashboard_widget-settings-panel_borders">
                        <div className={theme.label}>{intl.formatMessage(messages.borders)}</div>
                        <RadioGroup<DisplayValue>
                            onChange={onChangeBordersDisplay}
                            value={currentWidget.border ? DisplayValue.DISPLAY : DisplayValue.NOT_DISPLAY}
                            options={radioOptions}
                        />
                    </Col>
                </div>
            )}
        </>
    );
};
