import type { AttributeTypeStyleRule, AttributeTypeStyleRuleTypeEnum } from '../../../../../serverapi/api';
import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import { AttributeTypeSelectors } from '../../../../../selectors/attributeType.selectors';
import { getActiveGraph } from '../../../../../selectors/editor.selectors';
import { getCurrentLocale } from '../../../../../selectors/locale.selectors';
import { TreeSelectors } from '../../../../../selectors/tree.selectors';
import { LocalesService } from '../../../../../services/LocalesService';
import { ErrorWrapper } from './ErrorWrapper';
import { FloatingAttributesPanelSelectors } from '../../../selectors/FloatingAttributes.selectors';
import { TQueryAttributeTypeData } from '@/reducers/querySelect.reducer.types';
import { QuerySelectSelectors } from '@/selectors/querySelect.selectors';
import { AttributeValueType, RuleType } from '../../AttributesEditor/Attribute.types';
import messages from './Inputs.messages';
import { useIntl } from 'react-intl';

type TSelectInput = {
    attributeStyleId: string;
    rule: AttributeTypeStyleRule;
    disabled?: boolean;
    mode?: 'multiple' | 'tags' | undefined;
    handleRuleParams: (ruleType: AttributeTypeStyleRuleTypeEnum | undefined, value1: string, value2?: string) => void;
};

const booleanOptions = [{ id: 'undefined' }, { id: 'true' }, { id: 'false' }];

export const SelectInput = (props: TSelectInput) => {
    const { attributeStyleId, rule, mode, disabled, handleRuleParams } = props;
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);
    const activeGraphId = useSelector(getActiveGraph);
    const serverId = activeGraphId?.serverId || '';
    const presetId: string =
        useSelector(TreeSelectors.presetById(activeGraphId)) ||
        useSelector(FloatingAttributesPanelSelectors.getPresetId);
    const attributeType = useSelector(
        AttributeTypeSelectors.byObjectTypeId({ objectTypeId: attributeStyleId, presetId, serverId }),
    );
    const isQuerySelect = attributeType?.valueType === AttributeValueType.QUERY_SELECT;
    const isBooleanEqual = attributeType?.valueType === AttributeValueType.BOOLEAN && rule.type === RuleType.EQUALS;
    const querySelectData: TQueryAttributeTypeData = useSelector(QuerySelectSelectors.byPresetId(presetId));
    const values =
        (isBooleanEqual
            ? booleanOptions
            : isQuerySelect
            ? querySelectData[attributeType.id]
            : attributeType?.selectPropertyValues) || [];
    const handleChangeAttributeValue = (ids: string[]) => {
        handleRuleParams(rule.type, ids.toString());
    };
    const booleanShownValue =
        isBooleanEqual && rule.param && messages[rule.param] ? intl.formatMessage(messages[rule.param]) : '';

    return (
        <ErrorWrapper error={!rule.param}>
            <Select
                data-test="change-the-value-of-the-rule_select-type"
                value={isBooleanEqual ? booleanShownValue : rule.param ? rule.param?.split(',') : undefined}
                disabled={disabled}
                style={{ width: '100%' }}
                mode={mode}
                onChange={handleChangeAttributeValue}
            >
                {values?.map((v) => (
                    <Select.Option
                        data-test={`select-value-for-the-rule_${
                            LocalesService.internationalStringToString(v.value, currentLocale) || ''
                        }`}
                        key={v.id}
                        value={isQuerySelect ? v.value : v.id}
                    >
                        {isBooleanEqual
                            ? intl.formatMessage(messages[v.id])
                            : isQuerySelect
                            ? v.value
                            : LocalesService.internationalStringToString(v.value, currentLocale) || ''}
                    </Select.Option>
                ))}
            </Select>
        </ErrorWrapper>
    );
};
