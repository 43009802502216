import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';

export enum TCompactableSelectItemType {
    Divider,
    Value,
}

export interface ICompactableSelectItemValue<T> {
    type: TCompactableSelectItemType;
    value: T;
    label: string;
    disabled?: boolean;
    spriteSymbol?: TSpriteSymbol;
    dataTest?: string;
}

export interface ICompactableSelectItemDivider {
    type: TCompactableSelectItemType.Divider;
}

export type TCompactableSelectItem<T> = ICompactableSelectItemValue<T> | ICompactableSelectItemDivider;
