import * as React from 'react';
import { FC } from 'react';
import { ExtendableSelect } from '../ExtendableSelect/ExtendableSelect.component';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import messages from './messages/FontSizeSelect.messages';
import { fontSizeOptions } from '@/utils/configuration';

interface IFontSizeSelectProps {
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    className?: string;
    tooltipTitle?: string;
    dataTest?: string
}

type TFontSizeSelectPropsAllProps = WrappedComponentProps & IFontSizeSelectProps;

const validateNumber = (rule: {}, value: string, callback: (msg?: string) => void) => {
    const pattern = /^\d+$/;
    if (!pattern.test(value) || value === '0') {
        callback('not number');
    } else {
        callback();
    }
};

const FontSizeSelect: FC<TFontSizeSelectPropsAllProps> = (props: TFontSizeSelectPropsAllProps) => (
    <ExtendableSelect
        className={props.className}
        baseOptions={fontSizeOptions}
        validationRules={[
            { required: true, message: props.intl.formatMessage(messages.formValidationMessageRequired) },
            { validator: validateNumber, message: props.intl.formatMessage(messages.formValidationMessageNumberOnly) },
        ]}
        onChange={props.onChange}
        newValueLabel={props.intl.formatMessage(messages.formLabel)}
        disabled={props.disabled}
        value={{ value: props.value, label: props.value }}
        tooltipTitle={props.tooltipTitle}
        dataTest='font-size_select'
    />
);
const FontSizeSelectWithIntl = injectIntl(FontSizeSelect);

export { FontSizeSelectWithIntl as FontSizeSelect };
