import {
    SWITCH_ACTIVE_SERVER_PROFILE_SUCCESS,
    SERVER_PROFILE_SET_STATE
} from '../actionsTypes/serverProfile.actionTypes';
import { TReducer } from '../utils/types';
import {AUTHORIZATION_SUCCESS} from "../actionsTypes/authorization.actionTypes";
import { TServerProfileState } from './serverProfile.reducer.types';

const initial: TServerProfileState = {
    byServerId: {}
};

export const serverProfileReducer: TReducer<TServerProfileState> = (state = initial, action) => {
    switch (action.type) {
        case SERVER_PROFILE_SET_STATE: {
            const { serverProfiles, serverId } = action.payload;

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...(state.byServerId[serverId] || []),
                        serverProfiles,
                    },
                },
            };
        }
        case SWITCH_ACTIVE_SERVER_PROFILE_SUCCESS: {
            return {
                ...state,
                activeServerProfileId: action.payload.profileId
            };
        }
        case AUTHORIZATION_SUCCESS: {
            return {
                ...state,
                activeServerProfileId: action.payload.user.preferredServerProfileId
            };
        }
        default:
            return state;
    }
};
