import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import theme from './ApprovalDialog.scss';
import messages from './ApprovalDialog.messages';
import { Form, FormInstance, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { InputId } from '../InputId/InputId.component';
import { deleteApprovals, editApproval } from '@/actions/approval.actions';
import icApprovalEditInProgress from '../../resources/icons/ic-approval-edit-in-progress.svg';
import icApprovalEditCancelled from '../../resources/icons/ic-approval-vote-negative.svg';
import icApprovalNotApproved from '../../resources/icons/ic-aproval-not-approved.svg';
import icApprovalApproved from '../../resources/icons/ic-aproval-approved.svg';
import { Icon } from '../UIKit';
import { ApprovalDTOStatus } from './ApprovalDialog.types';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { ApprovalDTO, ApprovalDTOStatusEnum } from '../../serverapi/api';
import { Button } from '../UIKit/components/Button/Button.component';

type TGeneralSettingsProps = {
    formRef: React.RefObject<FormInstance>;
    isCreateMode: boolean;
    approval: ApprovalDTO;
};

export const ApprovalGeneralSettingsTab: FC<TGeneralSettingsProps> = (props) => {
    const intl = useIntl();
    const { approval, isCreateMode, formRef } = props;
    const dispatch = useDispatch();

    const isApprovalFinished =
        approval.status === ApprovalDTOStatus.APPROVED || approval.status === ApprovalDTOStatus.NOT_APPROVED;

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(editApproval({ approval: { ...approval, name: e.target.value } }));
    };
    const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(editApproval({ approval: { ...approval, description: e.target.value } }));
    };
    const onChangeApprovalStatus = () => {
        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    status:
                        approval.status === ApprovalDTOStatus.IN_PROCESS
                            ? ApprovalDTOStatus.CANCELLED
                            : ApprovalDTOStatus.IN_PROCESS,
                },
            }),
        );
    };
    const onDeleteApproval = () => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    dispatch(
                        deleteApprovals({
                            approvalNodeIds: [approval.id],
                        }),
                    );
                },
                title: intl.formatMessage(messages.deletingApproval),
                question: intl.formatMessage(messages.deletingApprovalDescription),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };
    const showApprovalStatus = (status: ApprovalDTOStatusEnum | undefined) => {
        let component;

        switch (status) {
            case ApprovalDTOStatus.IN_PROCESS:
                component = (
                    <>
                        <Icon spriteSymbol={icApprovalEditInProgress} />
                        <span>{intl.formatMessage(messages.approvalInProcess)}</span>
                    </>
                );
                break;
            case ApprovalDTOStatus.CANCELLED:
                component = (
                    <>
                        <Icon className={theme.editStatusCancelled} spriteSymbol={icApprovalEditCancelled} />
                        <span>{intl.formatMessage(messages.cancelled)}</span>
                    </>
                );
                break;
            case ApprovalDTOStatus.APPROVED:
                component = (
                    <>
                        <Icon className={theme.editStatusApproved} spriteSymbol={icApprovalApproved} />
                        <span>{intl.formatMessage(messages.editStatusApproved)}</span>
                    </>
                );
                break;
            case ApprovalDTOStatus.NOT_APPROVED:
                component = (
                    <>
                        <Icon className={theme.editStatusNotApproved} spriteSymbol={icApprovalNotApproved} />
                        <span>{intl.formatMessage(messages.editStatusNotApproved)}</span>
                    </>
                );
                break;
            case ApprovalDTOStatus.DRAFT:
                component = (
                    <>
                        <Icon className={theme.editStatusDraft} spriteSymbol={icApprovalEditCancelled} />
                        <span>{intl.formatMessage(messages.editStatusDraft)}</span>
                    </>
                );
                break;
            default:
                component = null;
                break;
        }

        return component;
    };

    return (
        <>
            {!isCreateMode ? (
                <div className={theme.editWrapper}>
                    <div>
                        {approval.status === ApprovalDTOStatus.CANCELLED ||
                        approval.status === ApprovalDTOStatus.IN_PROCESS ? (
                                <Button onClick={onChangeApprovalStatus}>
                                    {approval.status === ApprovalDTOStatus.IN_PROCESS
                                        ? intl.formatMessage(messages.cancelApproval)
                                        : intl.formatMessage(messages.resumeApproval)}
                                </Button>
                            ) : null}

                        <Button danger onClick={onDeleteApproval}>
                            {intl.formatMessage(messages.delete)}
                        </Button>
                    </div>
                    <div className={theme.editStatus}>{showApprovalStatus(approval.status)}</div>
                </div>
            ) : null}
            <Form autoComplete='off' ref={formRef} layout="vertical" className={theme.form}>
                <InputId disabled value={approval.id.id} required mainInputName="GUID" />
                <Form.Item
                    label={intl.formatMessage(messages.name)}
                    name="ApprovalName"
                    initialValue={approval.name}
                    required
                    rules={[
                        {
                            whitespace: true,
                            message: intl.formatMessage(messages.youEnteredOnlyWhitespaces),
                        },
                        {
                            required: true,
                            message: intl.formatMessage(messages.thisFieldIsRequired),
                        },
                    ]}
                >
                    <Input
                        value={approval.name}
                        maxLength={100}
                        onChange={onChangeName}
                        disabled={isApprovalFinished}
                    />
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage(messages.description)}
                    name="ApprovalDescription"
                    initialValue={approval.description}
                >
                    <Input.TextArea
                        value={approval.description}
                        maxLength={1000}
                        onChange={onChangeDescription}
                        disabled={isApprovalFinished}
                    />
                </Form.Item>
            </Form>

            {approval.stages?.length === 0 ? (
                <div className={theme.emptyStagesError}>{intl.formatMessage(messages.emptyStagesError)}</div>
            ) : null}
        </>
    );
};
