import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';

const presetSelector = (state: TRootState) => {
    return state.treeNodeType;
};

export namespace TreeNodeTypeSelectors {
    export const byPresetId = (payload: { presetId: string; serverId: string }) =>
        createSelector(presetSelector, (state) => {
            return state.byKey
                .values()
                .filter((type) => type.presetId === payload.presetId && type.serverId === payload.serverId);
        });
}
