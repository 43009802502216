import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'antd';
import messages from './AttributeVerticalAlignSelector.messages';
import { useIntl } from 'react-intl';
import AlignButton from '../../../common/Buttons/AlignButton.component';
import icTop from '../../../../../../resources/icons/ic-vertical-align-top.svg';
import icMiddle from '../../../../../../resources/icons/ic-vertical-align-center.svg';
import icBottom from '../../../../../../resources/icons/ic-vertical-align-bottom.svg';

type TAttributeImageSizeSelectorProps = {
    initialValue: string | undefined;
    disabled?: boolean;
    onChange: (style: string) => void;
};

export const AttributeVerticalAlignSelector = ({
    initialValue,
    disabled,
    onChange,
}: TAttributeImageSizeSelectorProps) => {
    const intl = useIntl();
    const [verticalAlign, setVerticalAlign] = useState(initialValue);

    useEffect(() => {
        setVerticalAlign(initialValue);
    }, [initialValue]);

    const onClick = useCallback(
        (align: string) => {
            return () => {
                setVerticalAlign(align);
                if (onChange) onChange(align);
            };
        },
        [onChange, setVerticalAlign],
    );

    return (
        <Row gutter={[2, 2]}>
            <Col
                data-test="property-window_attribute-around_align_top">
                <AlignButton
                    tooltip={intl.formatMessage(messages.alignTop)}
                    icon={icTop}
                    onClick={onClick('top')}
                    selected={verticalAlign === 'top'}
                    disabled={disabled}
                />
            </Col>
            <Col
                data-test="property-window_attribute-around_align_middle">
                <AlignButton
                    tooltip={intl.formatMessage(messages.alignCenter)}
                    icon={icMiddle}
                    onClick={onClick('middle')}
                    selected={verticalAlign === 'middle'}
                    disabled={disabled}
                />
            </Col>
            <Col
                data-test="property-window_attribute-around_align_bottom">
                <AlignButton
                    tooltip={intl.formatMessage(messages.alignBottom)}
                    icon={icBottom}
                    onClick={onClick('bottom')}
                    selected={verticalAlign === 'bottom'}
                    disabled={disabled}
                />
            </Col>
        </Row>
    );
};
