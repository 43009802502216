import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import formMessages from '../../../../models/formDefault.messages';
import messages from '../../messages/groups.messages';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { TRemoveUsersFromGroupPayload } from '../../../../actions/groups.actions.types';
import { DEFAULT_DIALOG_WIDTH } from '../../../../config/config';
import { TRemoveUserFromGroupDialogActionProps } from '../RemoveUserFromGroupDialog.types';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TRemoveUserFromGroupDialogProps = {
    open: boolean;
    removePayload: TRemoveUsersFromGroupPayload;
};

type TRemoveUserFromGroupDialogFullProps = TRemoveUserFromGroupDialogProps &
    TRemoveUserFromGroupDialogActionProps &
    WrappedComponentProps;

const RemoveUserFromGroupDialog = (props: TRemoveUserFromGroupDialogFullProps) => {
    const { onCancel, intl, open, onConfirm, removePayload } = props;
    const handleRemoveUserSubmit = () => removePayload.usersIds && onConfirm(removePayload);
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(formMessages.cancelButton),
                },
                {
                    key: 'ok',
                    onClick: handleRemoveUserSubmit,
                    value: intl.formatMessage(formMessages.deleteButtonLabel),
                    visualStyle: 'primary',
                    dataTest: 'user-managment-group_delete-user-from-group_submite',
                    danger: true,
                },
            ]}
        />
    );

    return (
        <Dialog
            onCancel={onCancel}
            title={intl.formatMessage(messages.deleteUsersFromGroupTitle)}
            open={open}
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
            onOk={handleRemoveUserSubmit}
        >
            {`${intl.formatMessage(messages.deleteUsersFromGroup)}?`}
        </Dialog>
    );
};

export default injectIntl(RemoveUserFromGroupDialog);
