import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import theme from '../style/AdminTools.scss';
import { SessionList } from '../SessionList/SessionList';
import { ClearJournalContainer } from './ClearJournal/ClearJournal.container';
import { ActionsAuditList } from '../ActionsAudit/ActionAuditList';
import { SideBarItemsId, TAvailableSideBarItems } from './SafetySettings.types';
import { getAvailableSideBarItems } from './safetySettings.selectors';
import { ExternalSessions } from './ExternalSessions/ExternalSessions.component';
import { TWithWindowResizeProps } from '../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import { TWorkspaceTab } from '../../../models/tab.types';

type TSafetySettingsProps = TWithWindowResizeProps & {
    tab: TWorkspaceTab;
};

export const SafetySettings = (props: TSafetySettingsProps): JSX.Element => {
    const intl = useIntl();
    const { serverId } = props.tab.nodeId;
    const { availableMenuItems, sideBarItemDefaultId }: TAvailableSideBarItems = useSelector(getAvailableSideBarItems);

    const components = {
        [SideBarItemsId.ACTIONS_AUDIT]: <ActionsAuditList />,
        [SideBarItemsId.SESSIONS_LIST]: <SessionList />,
        [SideBarItemsId.CLEAR_JOURNAL]: <ClearJournalContainer />,
        [SideBarItemsId.ACCESS_TOKENS]: <ExternalSessions serverId={serverId} />,
    };

    const tabs = availableMenuItems.map(({ id, titleId }) => ({
        label: intl.formatMessage(titleId),
        key: id,
        children: components[id],
    }));

    return (
        <div className={theme.container} data-test="security-tab_container">
            <div className={theme.containerInner}>
                <Tabs defaultActiveKey={sideBarItemDefaultId} tabPosition="left" items={tabs} />
            </div>
        </div>
    );
};
