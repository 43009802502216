import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import theme from './WhiteboardSidebar.scss';
import { EditorMode } from '../../../../models/editorMode';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';
import { BPMEditorUI } from '../../../../mxgraph/sidebar/BPMEditorUI';
import { DrawioSidebarDecorator } from '../../../../mxgraph/sidebar/DrawioSidebarDecorator';
import { Editor } from '../../../../mxgraph/mxgraph';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

type TWhiteboardSidebarProps = WrappedComponentProps & {
    graph: BPMMxGraph;
    editorMode?: EditorMode;
    onClose: () => void;
};
type TWhiteboardSidebarState = {
    containerElement?: HTMLElement;
};

class WhiteboardSidebarComponent extends Component<TWhiteboardSidebarProps> {
    state: TWhiteboardSidebarState = {
        containerElement: undefined,
    };

    initGraphRef = (element: HTMLDivElement) => {
        const editorGraph = this.props.graph;
        const { onClose } = this.props;
        if (element && !this.state.containerElement) {
            const editor = new Editor(false, undefined!, undefined!, editorGraph, true);
            const editorUi: BPMEditorUI = new BPMEditorUI(editorGraph, editor, element);
            const sidebar = new DrawioSidebarDecorator(editorUi, element);
            editorUi.onClose = () => {
                sidebar.destroy();
                onClose();
            };
            sidebar.showTooltips = false;
            sidebar.enableTooltips = false;
        }
    };

    getUniqKey() {
        return `wb-sidebar_${uuid()}`;
    }

    render() {
        return (
            <div className={cn('geSidebarContainer', theme.whiteboardContainer)}>
                <section
                    className={theme.whiteboardSidebar}
                    key={this.getUniqKey()}
                    ref={(element: HTMLElement) => {
                        this.state.containerElement = element as any;
                    }}
                >
                    <div className={theme.editorPanel}>
                        <div tabIndex={-1} className={theme.graph} ref={this.initGraphRef} />
                    </div>
                </section>
            </div>
        );
    }
}

const WhiteboardSidebarComponentWithIntl = injectIntl(WhiteboardSidebarComponent);

export { WhiteboardSidebarComponentWithIntl as WhiteboardSidebar };
