import { useEffect } from 'react';

/**
 * отслеживает клик вне компонента
 * @param ref элемент, вне которого был совершен клик
 * @param callback функция, которая будет вызвана при клике
 * @param exception элемент, клик по которому не учитывается (не будет вызван callback)
 */
export const useClickOutside = (
    ref: React.MutableRefObject<HTMLDivElement | null>,
    callback: Function,
    exception?: React.MutableRefObject<HTMLDivElement | null>,
) => {
    const handleClick = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as Node) && !exception?.current?.contains(e.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('pointerup', handleClick, true);
        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('pointerup', handleClick, true);
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref, exception, callback]);
};
