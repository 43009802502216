// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GraphObjectToolbar__spanGroupRow__Ul5hE{display:block}.GraphObjectToolbar__spanGroupRow__Ul5hE.GraphObjectToolbar__compact__q2wHF{display:inline}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/GraphObjectToolbar/GraphObjectToolbar.scss"],"names":[],"mappings":"AAAA,yCACI,aAAA,CAGJ,4EACI,cAAA","sourcesContent":[".spanGroupRow {\r\n    display: block;\r\n}\r\n\r\n.spanGroupRow.compact {\r\n    display: inline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spanGroupRow": "GraphObjectToolbar__spanGroupRow__Ul5hE",
	"compact": "GraphObjectToolbar__compact__q2wHF"
};
export default ___CSS_LOADER_EXPORT___;
