import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TScriptExecutionState } from '../reducers/entities/scriptExecution.reducer.types';

const scriptStateSelector = (state: TRootState): TScriptExecutionState => state.scriptExecution;

export namespace ScriptExecutionSelectors {
    export const byServerId = (serverId: string) => createSelector(scriptStateSelector, (state) => state.operationData.values());

    export const byId = (serverId: string, id: string) => createSelector(
        scriptStateSelector,
        (state) => state.operationData.get(id)
    );
}
