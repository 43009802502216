import { EditorMode } from '@/models/editorMode';
import { IWorkspaceTabItemModelParams } from '@/models/tab.types';
import { EditModeButton } from '@/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/EditModeButton/EditModeButton';
import { isCompact } from '@/selectors/generalMenu.selectors';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import classnames from 'classnames';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import menuItemTheme from '@/modules/MainMenu/components/items/MenuItem.scss';
import { TReportEditorToolbarProps } from './ReportEditorToolbar.types';

export const ReportEditorToolbar: FC<TReportEditorToolbarProps> = (props) => {
    const { nodeId } = props;

    const activeWorkspaceTab = useSelector(TabsSelectors.getActiveTab);
    const isActiveScheme = !!activeWorkspaceTab;
    const editModeBtnDisabled = !!activeWorkspaceTab?.content?.userEditDisabled;
    const compact = useSelector(isCompact);
    const params = useSelector(TabsSelectors.getActiveTabParams) as IWorkspaceTabItemModelParams | undefined;
    const { modelType } = params || {};

    const isReadMode = activeWorkspaceTab?.mode === EditorMode.Read;

    const className = classnames(menuItemTheme.container, {
        [menuItemTheme.container_compact]: compact,
        [menuItemTheme.toolbar_disabled]:
            activeWorkspaceTab === undefined ||
            (activeWorkspaceTab && activeWorkspaceTab.type === 'HomePageTab') ||
            activeWorkspaceTab.type === 'ContentLoadingPageTab',
    });
    const groupClassName = classnames(menuItemTheme.group, 'group');

    return (
        <div className={className}>
            <div className={groupClassName}>
                <EditModeButton
                    nodeId={nodeId}
                    compact={compact}
                    isReadMode={isReadMode}
                    isActiveScheme={isActiveScheme}
                    modelTypeId={modelType?.id}
                    unlockDisabled={editModeBtnDisabled}
                />
            </div>
        </div>
    );
};
