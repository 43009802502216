import React, { ChangeEvent, useDeferredValue, useState } from 'react';
import classnames from 'classnames';
import { TPreset } from '../../../../../models/preset.types';
import theme from './MethodologySetting.scss';
import { containsIgnoreCase } from '../Presets/util/preset.utils';
import { useSelector } from 'react-redux';
import { PresetSelectors } from '../../../../../selectors/preset.selectors';
import { IWorkspaceTabItemMethodologyParams, TWorkspaceTab } from '../../../../../models/tab.types';
import { MethodologySettingTable } from './MethodologySettingTable.component';
import { SearchInput } from '../../../../UIKit/components/Select/SearchInput.component';
import { MethodologySettingHeaderButton } from './MethodologySettingHeaderButton.component';

type TMethodologySettingProps = {
    tab: TWorkspaceTab;
};

export const MethodologySetting = (props: TMethodologySettingProps) => {
    const params = props.tab.params as IWorkspaceTabItemMethodologyParams;
    const { serverNode } = params;
    const loading: boolean = useSelector(PresetSelectors.loading());
    /**
     * todo:
     * Не очень понятна логика инкапсуляции данных в текущей реализации. Вроде как есть отдельный компонент таблицы пресетов MethodologySettingTable. Может и пресеты в нем получать селектором?
     * Сервер теперь всегда один и для получения списка пресетов не нужно передавать serverId
     * Фильтрацию пресетов можно сделать проще, перенеся фильтр в редакс.
     * https://gitlab.silaunion.ru/next-ng/bpm/-/merge_requests/4982#note_70840
     */
    const presets: TPreset[] = useSelector(PresetSelectors.presetArrByServerId(serverNode.nodeId.serverId));

    const [presetFilter, setPresetFilter] = useState<string>('');
    const container = classnames(theme.container, loading ? theme.displayWaitScreen : '');

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setPresetFilter(e.target.value);
    };

    const filteredPresets = presets.filter(
        (p: TPreset) =>
            containsIgnoreCase(p.name, presetFilter.trim()) ||
            containsIgnoreCase(p.description, presetFilter.trim()) ||
            containsIgnoreCase(p.id, presetFilter.trim()) ||
            containsIgnoreCase(
                p.repositories.map((rep) => rep.name),
                presetFilter.trim(),
            ),
    );

    const deferredPreset = useDeferredValue(filteredPresets);

    return (
        <div className={container} data-test="methodology-settings-tab_container">
            <div className={theme.header}>
                <div className={theme.searchContainer}>
                    <SearchInput
                        showSearch
                        onSearch={onSearch}
                        originalTheme
                        searchValue={presetFilter}
                        dataTest="search-methodology_btn"
                    />
                </div>
                <MethodologySettingHeaderButton serverNode={serverNode} />
            </div>
            <MethodologySettingTable nodeId={props.tab.nodeId} serverNode={serverNode} presets={deferredPreset} />
        </div>
    );
};
