import React, { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './GroupCreateDialog.messages';
import theme from './GroupCreateDialog.scss';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { closeDialog } from '../../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../../DialogRoot/DialogRoot.constants';
import { Form } from 'antd';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useDispatch } from 'react-redux';
import { InternationalString, ModelTypeGroup, ObjectTypeGroup } from '../../../../../../../serverapi/api';
import { InputId } from '../../../../../../InputId/InputId.component';
import { TSubmitModelTypeGroupAction } from '../../../../../../../actions/methodologySetting.actions.types';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TGroupCreateDialogProps = {
    open: boolean;
    elementName: string;
    elemenTypeGroup: ModelTypeGroup | ObjectTypeGroup;
    createMode: boolean;
    getSubmitAction: (typeGroups: ModelTypeGroup[] | ObjectTypeGroup[]) => TSubmitModelTypeGroupAction;
};

export const GroupCreateDialog = (props: TGroupCreateDialogProps) => {
    const { open, elementName, elemenTypeGroup, createMode, getSubmitAction } = props;
    const intl = useIntl();
    const [generalForm] = Form.useForm();
    const dispatch = useDispatch();
    const [typeGroup, setTypeGroup] = useState<ModelTypeGroup | ObjectTypeGroup>(elemenTypeGroup);

    const onChangeName = (value: InternationalString) => {
        setTypeGroup((prevState) => ({ ...prevState, multilingualName: value }));
    };

    const onChangeDescription = (value: InternationalString) => {
        setTypeGroup((prevState) => ({ ...prevState, multilingualDescription: value }));
    };

    const onChangeGuid = (e: ChangeEvent<HTMLInputElement>) => {
        setTypeGroup((prevState) => ({ ...prevState, id: e.target.value.trim() }));
    };

    const onCancel = () => dispatch(closeDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG));

    const onSubmit = () =>
        generalForm
            .validateFields()
            .then(() => dispatch(getSubmitAction([typeGroup])))
            .catch(() => undefined);

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'type-group-name-button-cancel',
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(createMode ? messages.create : messages.save),
                    visualStyle: 'primary',
                    dataTest: 'type-group-name-button-ok',
                },
            ]}
        />
    );

    return (
        <Dialog
            title={intl.formatMessage(createMode ? messages.createTitle : messages.editTitle, { element: elementName })}
            className={theme.dialog}
            onCancel={onCancel}
            open={open}
            footer={footer}
        >
            <Form form={generalForm} layout="vertical" className={theme.form}>
                <div className={theme.formItemName}>
                    <MultiLangInputDialog
                        placeholder={intl.formatMessage(messages.newGroup)}
                        multiLangValue={typeGroup.multilingualName}
                        onChange={onChangeName}
                        label={<span>{intl.formatMessage(messages.name)}</span>}
                        mainInputName="multilingualName"
                        generalForm={generalForm}
                        required
                        data-test="type-group-name-input"
                        mainInputClassName={theme.input}
                    />
                </div>
                <div className={theme.formItemInputId}>
                    <InputId
                        value={typeGroup.id}
                        onChange={onChangeGuid}
                        required
                        disabled={!createMode}
                        mainInputName="GUID"
                        mainInputClassName={theme.input}
                    />
                </div>
                <div className={theme.formItemMultiLangTextArea}>
                    <MultiLangInputDialog
                        placeholder={intl.formatMessage(messages.description)}
                        multiLangValue={typeGroup.multilingualDescription}
                        onChange={onChangeDescription}
                        textarea
                        label={intl.formatMessage(messages.description)}
                        mainInputName="multilingualDescription"
                        generalForm={generalForm}
                        data-test="type-group-description-input"
                        mainInputClassName={theme.textArea}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                    />
                </div>
            </Form>
        </Dialog>
    );
};
