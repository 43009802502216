import React, { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './CreatePresetImageDialog.messages';
import theme from './CreatePresetImageDialog.scss';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { closeDialog } from '../../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../../DialogRoot/DialogRoot.constants';
import { Form, Upload } from 'antd';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { InternationalString, PresetImage, PresetImageGroup } from '../../../../../../../serverapi/api';
import { InputId } from '../../../../../../InputId/InputId.component';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { Icon, Icon as UIKitIcon } from '../../../../../../UIKit/components/Icon/Icon.component';
import UploadIcon from '../../../../../../../resources/icons/Upload.svg';
import DeletedIcon from '../../../../../../../resources/icons/Deleted.svg';
import { PresetImageGroupSelectors } from '../../../../../../../selectors/presetSettings/presetImageGroup.selectors';
import { TSubmitPresetImagePayload } from '../../../../../../../actions/presetSettings/presetImage.actions.types';
import { submitPresetImages } from '../../../../../../../actions/presetSettings/presetImage.actions';
import { TreeNode } from '../../../../../../../models/tree.types';
import { TPreset } from '../../../../../../../models/preset.types';
import { Select } from '../../../../../../UIKit/components/Select/Select.component';
import { formatBytes } from '../../../../../../../utils/formatBytes.utils';
import { TooltipTitle } from '../../../../../../TooltipTitle/TooltipTitle.component';
import { NotificationType } from '../../../../../../../models/notificationType';
import { showNotificationByType } from '../../../../../../../actions/notification.actions';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { isSvgImage } from '@/utils/image.utils';

type TCreatePresetImageDialogProps = {
    open: boolean;
    presetImage: PresetImage;
    createMode: boolean;
    serverNode: TreeNode;
    preset: TPreset;
};

type TImageParams = {
    name: string;
    size: number;
};

export const CreatePresetImageDialog = (props: TCreatePresetImageDialogProps) => {
    const { open, createMode, presetImage, preset, serverNode } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const presetImageGroupsById = useSelector(
        PresetImageGroupSelectors.byPresetId({
            serverId: serverNode.nodeId.serverId,
            presetId: preset.id || '',
        }),
    );
    const presetImageGroups: PresetImageGroup[] | undefined = Object.values(presetImageGroupsById.byId);
    const [presetImageState, setPresetImageState] = useState<PresetImage>({
        ...presetImage,
        presetImageGroup: presetImage.presetImageGroup || presetImageGroups?.[0],
    });
    const [imageParams, setImageParams] = useState<TImageParams | undefined>();
    const [isImageNotUploadedWarning, setIsImageNotUploadedWarning] = useState<boolean>(false);

    const onChangePresetImageName = (multilingualName: InternationalString) => {
        setPresetImageState({ ...presetImageState, multilingualName });
    };

    const onChangePresetImageId = (id: string) => {
        setPresetImageState({ ...presetImageState, id });
    };

    const onChangePresetImageGroup = (groupId: string) => {
        const changeGroup = presetImageGroups.find((otg) => otg.id === groupId);
        if (changeGroup) {
            setPresetImageState({ ...presetImageState, presetImageGroup: changeGroup });
        }
    };

    const onDropFile = (fileBase64: string) => {
        setPresetImageState({
            ...presetImageState,
            graphical: fileBase64,
        });
    };

    const beforeUpload = (file: File) => {
        const isImage: boolean = isSvgImage(file);

        if (!isImage) {
            dispatch(showNotificationByType(NotificationType.NOT_SVG_IMAGE_ERROR));
        }

        return isImage;
    };

    const dropFile = (e: UploadRequestOption) => {
        if (e.file) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file as File);
            reader.onloadend = () => {
                onDropFile(reader.result as string);
            };
            setImageParams({
                name: (e.file as File).name,
                size: (e.file as File).size,
            });
        }
    };

    const onDeliteFile = () => {
        setImageParams(undefined);
        setPresetImageState({
            ...presetImageState,
            graphical: undefined,
        });
    };

    const onCancel = () => dispatch(closeDialog(DialogType.CREATE_PRESET_IMAGE_DIALOG));

    const onSubmit = (payload: TSubmitPresetImagePayload) => {
        dispatch(submitPresetImages(payload));
    };

    const onOk = () => {
        if (form) {
            form.validateFields()
                .then(() => {
                    if (!presetImageState.graphical) {
                        setIsImageNotUploadedWarning(true);

                        return;
                    }

                    setIsImageNotUploadedWarning(false);

                    onSubmit({
                        serverNode,
                        preset,
                        createMode,
                        presetImages: [
                            {
                                ...presetImageState,
                            },
                        ],
                    });
                })
                .catch(() => undefined);
        }
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'type-group-name-button-cancel',
                },
                {
                    key: 'ok',
                    onClick: onOk,
                    value: intl.formatMessage(createMode ? messages.create : messages.save),
                    visualStyle: 'primary',
                    dataTest: 'type-group-name-button-ok',
                },
            ]}
        />
    );

    const imageSize = formatBytes(imageParams?.size || 0);
    const imageSizeForShow = imageSize === '0' ? '' : imageSize;

    return (
        <Dialog
            title={intl.formatMessage(createMode ? messages.createTitle : messages.editTitle)}
            className={theme.dialog}
            onCancel={onCancel}
            open={open}
            footer={footer}
        >
            <Form form={form} layout="vertical">
                <div className={theme.multiLangInputWrapper}>
                    <MultiLangInputDialog
                        placeholder={intl.formatMessage(messages.name)}
                        multiLangValue={presetImage.multilingualName}
                        onChange={onChangePresetImageName}
                        label={intl.formatMessage(messages.name)}
                        mainInputName="multilingualName"
                        mainInputClassName={theme.input}
                        generalForm={form}
                        required
                        autoFocus
                        data-test="preset-icon-name-input"
                    />
                </div>

                <div className={theme.inputWrapper}>
                    <InputId
                        disabled={!createMode}
                        value={presetImage.id}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onChangePresetImageId(e.target.value);
                        }}
                        required
                        mainInputName={intl.formatMessage(messages.id)}
                        mainInputClassName={theme.input}
                    />
                </div>
                <div className={theme.selectWrapper}>
                    <div className={theme.customRequired}>
                        <Select
                            data-test="preset-icon-group-select"
                            onChange={onChangePresetImageGroup}
                            value={
                                <span className={theme.selectValue}>
                                    {LocalesService.internationalStringToString(
                                        presetImageState.presetImageGroup?.multilingualName,
                                    )}
                                </span>
                            }
                            label={
                                <span className={theme.selectLabel}>
                                    {intl.formatMessage(messages.presetImageGroups)}
                                </span>
                            }
                        >
                            {presetImageGroups.map((group: PresetImageGroup) => {
                                return (
                                    <Select.Option
                                        key={group.id}
                                        value={group.id}
                                        label={LocalesService.internationalStringToString(group.multilingualName)}
                                    />
                                );
                            })}
                        </Select>
                    </div>
                </div>

                {presetImageState.graphical ? (
                    <div className={theme.imageContainer}>
                        <img className={theme.image} src={presetImageState.graphical} />
                        <div className={theme.imageAnnotationContainer}>
                            <div>
                                <div className={theme.imageName}>
                                    <TooltipTitle>{imageParams?.name || ''}</TooltipTitle>
                                </div>
                                <div className={theme.imageSize}>{imageSizeForShow}</div>
                            </div>
                            <Icon className={theme.deleteButton} spriteSymbol={DeletedIcon} onClick={onDeliteFile} />
                        </div>
                    </div>
                ) : (
                    <div className={theme.uploadArea}>
                        <Upload.Dragger
                            name="file"
                            multiple={false}
                            showUploadList={false}
                            customRequest={dropFile}
                            accept=".svg"
                            className={isImageNotUploadedWarning ? theme.draggerWithWarning : theme.dragger}
                            beforeUpload={beforeUpload}
                        >
                            <div className={theme.uploadAreaTextWrapper}>
                                <div className={theme.uploadAreaTitle}>
                                    <span className={theme.uploadAreaTitleIcon}>
                                        <UIKitIcon className={theme.uploadSvg} spriteSymbol={UploadIcon} />
                                    </span>
                                    <span className={theme.uploadAreaTitleText}>
                                        {intl.formatMessage(messages.uploadText)}
                                    </span>
                                </div>
                                <div className={theme.uploadDescription}>
                                    {intl.formatMessage(messages.uploadDescription)}
                                </div>
                                <div className={theme.uploadDescriptionSize}>
                                    {intl.formatMessage(messages.uploadDescriptionSize)}
                                </div>
                            </div>
                        </Upload.Dragger>
                    </div>
                )}
            </Form>
        </Dialog>
    );
};
