import { put, select, takeEvery } from 'redux-saga/effects';
import { TFetchNodesAction } from '../actions/nodes.actions.types';
import { FETCH_NODES_REQUEST } from '../actionsTypes/nodes.actionTypes';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { Node } from '../serverapi/api';
import { fetchNodesSuccess } from '../actions/nodes.actions';
import { ServerSelectors } from '../selectors/entities/server.selectors';

function* handleFetchNodes(action: TFetchNodesAction) {
    const { nodes } = action.payload;
    const serverId = yield select(ServerSelectors.serverId);
    const treeNodes: Node[] = yield TreeDaoService.getNodes(nodes, serverId);
    yield put(fetchNodesSuccess(treeNodes));
}

export function* nodesSaga() {
    yield takeEvery(FETCH_NODES_REQUEST, handleFetchNodes);
}
