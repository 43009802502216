import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../../SystemProperties.messages';
import theme from '../../SystemProperties.scss';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { FormItemInputWithLabel } from '../FormItemInputWithLabelComponent/FormItemInputWithLabel.component';
import { SystemPropertiesFormItemNames } from '../../SystemProperties.types';

export const ServerSettings = () => {
    const intl = useIntl();
    const fileTempStoragePath = useSelector(SystemPropertiesSelectors.getFileTempStoragePath);
    const serverUrl = useSelector(SystemPropertiesSelectors.getServerUrl);
    const serverName = useSelector(SystemPropertiesSelectors.getServerName);
    const serverIp = useSelector(SystemPropertiesSelectors.getServerIp);
    const serverPort = useSelector(SystemPropertiesSelectors.getServerPort);
    const serverTime = useSelector(SystemPropertiesSelectors.getServerTime);

    return (
        <div className={theme.formTab}>
            <FormItemInputWithLabel
                initialValue={serverUrl}
                name={SystemPropertiesFormItemNames.serverUrl}
                label={intl.formatMessage(messages.serverUrl)}
                placeholder={intl.formatMessage(messages.serverUrlPlaceholder)}
                data-test="server-settings_server_url-input"
                autoComplete="off"
            />
            <FormItemInputWithLabel
                initialValue={serverName}
                name={SystemPropertiesFormItemNames.serverName}
                label={intl.formatMessage(messages.serverName)}
                disabled
                data-test="server-settings_server_name"
            />
            <FormItemInputWithLabel
                initialValue={serverIp}
                name={SystemPropertiesFormItemNames.serverIp}
                label={intl.formatMessage(messages.serverIp)}
                disabled
                data-test="server-settings_server_ip"
            />
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.serverPort}
                initialValue={serverPort}
                label={intl.formatMessage(messages.serverPort)}
                disabled
            />
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.serverTime}
                initialValue={serverTime && new Date(Number(serverTime).valueOf())}
                label={intl.formatMessage(messages.serverTime)}
                disabled
                data-test="server-settings_server_time"
            />
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.fileTempStoragePath}
                initialValue={fileTempStoragePath}
                label={intl.formatMessage(messages.fileTempStoragePath)}
                disabled
            />
        </div>
    );
};
