import React from 'react';
import { InitialsCircle } from '../UIKit/components/InitialsCircle/InitialsCircle.component';
import theme from './userAccount.scss';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { UserAccountSelectors } from '../../selectors/userAccount.selectors';
import { TPersonalProfileData, TUserAccountData } from './userAccountData.types';
import { UserProfileSection } from './UserProfileSection.component';

export const UserAccountData = () => {
    const userFullName: string = useSelector(UserAccountSelectors.getFullName);
    const data: TUserAccountData = useSelector(UserAccountSelectors.getAccountData);
    const personalProfileData: TPersonalProfileData = useSelector(UserAccountSelectors.getPersonalProfileData);

    return (
        <div className={theme.accountContainer}>
            <div className={theme.dataHeaderContainer}>
                <InitialsCircle circleSize={56} fontSize={20} />
                <div className={theme.nameContainer}>{userFullName}</div>
            </div>
            <Divider></Divider>
            <UserProfileSection data={data} personalProfileData={personalProfileData} />
        </div>
    );
};
