import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '@/serverapi/api';
import { TUndoRedoData, TUndoRedoState } from '@/reducers/undoRedo.reducer.types';

const matrixUndoRedoSelector = (state: TRootState): TUndoRedoState => state.undoRedo;

export namespace UndoRedoSelectors {
    export const getUndoRedoStates = (nodeId: NodeId) =>
        createSelector<TRootState, TUndoRedoState, TUndoRedoData | undefined>(matrixUndoRedoSelector, (state) =>
            state.byNodeId.get(nodeId),
        );

    export const getUndoRedoStatus = (nodeId: NodeId) =>
        createSelector<TRootState, TUndoRedoState, { undo: boolean; redo: boolean }>(
            matrixUndoRedoSelector,
            (state) => {
                const status = {
                    redo: false,
                    undo: false,
                };
                const undoRedoData: TUndoRedoData | undefined = state.byNodeId.get(nodeId);
                if (!undoRedoData) return status;

                const { states, currentIndex } = undoRedoData;

                status.redo = !!states[currentIndex - 1];
                status.undo = !!states[currentIndex + 1];

                return status;
            },
        );
}
