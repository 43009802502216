import { defineMessages } from 'react-intl';

export default defineMessages({
    dialogTitle: {
        id: 'ExportDialog.dialogTitle',
        defaultMessage: 'Параметры экспорта',
    },
    decompositionModelSettingLabel: {
        id: 'ExportDialog.decompositionModelSettingLabel',
        defaultMessage: 'Выберите уровень требуемой декомпозиции',
    },
    decompositionModelNo: {
        id: 'ExportDialog.decompositionModelNo',
        defaultMessage: 'Без декомпозиции',
    },
    decompositionModelFirstLvl: {
        id: 'ExportDialog.decompositionModelFirstLvl',
        defaultMessage: 'Декомпозиции объектов на модели',
    },
    decompositionModelAll: {
        id: 'ExportDialog.decompositionAll',
        defaultMessage: 'Полная цепочка декомпозиции',
    },
    decompositionFolderSettingLabel: {
        id: 'ExportDialog.decompositionFolderSettingLabel',
        defaultMessage: 'Экспортировать декомпозированные модели вне папки',
    },
    decompositionFolderNo: {
        id: 'ExportDialog.decompositionFolderNo',
        defaultMessage: 'Нет',
    },
    decompositionFolderAll: {
        id: 'ExportDialog.decompositionFolderAll',
        defaultMessage: 'Да',
    },
    cancelButton: {
        id: 'ExportDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    continueButton: {
        id: 'ImportVisioDialog.continueButton',
        defaultMessage: 'Продолжить',
    },
});
