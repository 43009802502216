import { EdgeType } from '../serverapi/api';
import { TReducer } from '../utils/types';
import { EDGE_TYPE_DELETE_REQUEST_SUCCESS, EDGE_TYPE_REQUEST_SUCCESS } from '../actionsTypes/edgeType.actionTypes';
import { TEdgeTypeState } from './edgeType.reducer.types';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import { APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM } from '../actionsTypes/app.actionTypes';
import { cloneState } from './utils/cloneState.utils';

export const INITIAL_EDGE_TYPE_SERVER_STATE = {
    byPresetId: {},
};

const initial: TEdgeTypeState = {
    byServerId: {},
};

export const edgeTypeReducer: TReducer<TEdgeTypeState> = (state = initial, action) => {
    switch (action.type) {
        case APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM: {
            const {
                payload: { definition },
            } = action;

            if (!definition?.modelType?.edgeTypes) {
                return state;
            }
            const byId: { [id: string]: EdgeType } = {};
            const newState = { byServerId: { '': { byPresetId: { '': { byId } } } } };
            definition.modelType.edgeTypes.forEach((et) => {
                byId[et.id] = et;
            });

            return { ...newState };
        }

        case EDGE_TYPE_REQUEST_SUCCESS: {
            const {
                payload: { serverId, edgeTypes, presetId },
            } = action;

            const stateClone = cloneState(state, serverId, presetId);

            const { byId } = stateClone.byServerId[serverId].byPresetId[presetId];

            edgeTypes.forEach((edgeType) => {
                byId[edgeType.id] = edgeType;
            });

            return stateClone;
        }
        case EDGE_TYPE_DELETE_REQUEST_SUCCESS: {
            const { edgeTypes, serverId } = action.payload;
            const stateClone = JSON.parse(JSON.stringify(state));
            edgeTypes.forEach((edgeType) => {
                const byId = stateClone.byServerId[serverId]?.byPresetId[edgeType.presetId]?.byId || {};
                delete byId[edgeType.id];
            });

            return stateClone;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }
        default:
            return state;
    }
};
