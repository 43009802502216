import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_LINE_HEIGHT } from './Controls.constants';
import messages from '../CommonToolbar.messages';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { LineHeightSelect } from '@/modules/MainMenu/components/LineHeightSelect/LineHeightSelect.component';
import theme from '../../../Toolbar/Toolbar.scss';

// TODO getAttributes('paragraph') работает только для типа 'paragraph'
// нужно предусмотреть возможность задавать lineHeight для всех типов
const getFocusedBlock = (editor: Editor) => editor.getAttributes('paragraph').lineHeight || DEFAULT_LINE_HEIGHT;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setLineHeight(value).run();

export const LineHeightComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const intl = useIntl();

    return (
        <div data-test="wiki-toolbar-group_select-line-interval">
            <LineHeightSelect
                onChange={setCurrentState}
                value={value || ''}
                className={theme.selectLineHeight}
                tooltipTitle={intl.formatMessage(messages.lineHeight)}
            />
        </div>
    );
};
