import {
    DELETE_USER_SUCCESS,
    EDIT_USER_DATA_BEGIN,
    EDIT_USER_DATA_END,
    FETCH_ALL_USERS,
    FETCH_USERS_BY_GROUPID_REQUEST,
    FETCH_USERS_BY_GROUPID_SUCCESS,
    FETCH_USERS_BY_TREENODE,
    FETCH_USERS_SUCCESS,
    FETCH_USER_BYID,
    FETCH_USER_BYID_SUCCESS,
    FETCH_USER_SESSIONS,
    FETCH_USER_SESSIONS_SUCCESS,
    OPEN_EDIT_USER_PERMISSIONS_DIALOG,
    OPEN_USERDATA_EDITING_TAB,
    OPEN_USERS_MANAGMENT_TAB,
    SAVE_USERS_ACCESSES_CHANGES,
    SAVE_USERS_GROUPS_CHANGES,
    SAVE_USERS_LICENSES_CHANGES,
    SAVE_USERS_REPOS_CHANGES,
    SUBMIT_USER_DATA,
    SET_USERS_BLOCK,
    DELETE_USERS,
} from '../actionsTypes/users.actionTypes';
import { TreeNode } from '../models/tree.types';
import { UserDTO, UserDTOLicensesEnum, GroupDTO, UserDTOAccessesEnum, UserSessionHistory } from '../serverapi/api';

export type TFetchAllUsersRequestPayload = {
    serverId: string;
};

export type TFetchAllUsersRequestAction = {
    type: typeof FETCH_ALL_USERS;
    payload: TFetchAllUsersRequestPayload;
};

type TFetchUsersPayload = {
    node: TreeNode;
};

export type TFetchUsersAction = {
    type: typeof FETCH_USERS_BY_TREENODE;
    payload: TFetchUsersPayload;
};

export type TFetchUsersSuccessPayload = {
    users: UserDTO[];
    serverId: string;
};

export type TFetchUsersSuccessAction = {
    type: typeof FETCH_USERS_SUCCESS;
    payload: TFetchUsersSuccessPayload;
};

export type TOpenUserManagementTabAction = {
    type: typeof OPEN_USERS_MANAGMENT_TAB;
    payload: {
        node: TreeNode;
    };
};

export enum UserDataEdititngTabMode {
    EDIT = 'edit',
    ADD = 'add',
}

export type TOpenUserDataEditingTabPayload = {
    userData: UserDTO;
    mode: UserDataEdititngTabMode;
};

export type TOpenUserDataEditingTabAction = {
    type: typeof OPEN_USERDATA_EDITING_TAB;
    payload: TOpenUserDataEditingTabPayload;
};

export type TEditUserDataBeginAction = {
    type: typeof EDIT_USER_DATA_BEGIN;
    payload: {
        userData: UserDTO;
    };
};

export type TEditUserDataEndAction = {
    type: typeof EDIT_USER_DATA_END;
    payload: {
        userId: number;
    };
};

export type TSetUsersBlockAction = {
    type: typeof SET_USERS_BLOCK;
    payload: {
        usersId: number[];
        blockStatus: boolean;
    };
};

export type TSubmitUserDataActionPayload = {
    userData: UserDTO;
    isNewUser: boolean;
};

export type TSubmitUserDataAction = {
    type: typeof SUBMIT_USER_DATA;
    payload: TSubmitUserDataActionPayload;
};

export type TFetchUserByIdAction = {
    type: typeof FETCH_USER_BYID;
    payload: {
        userId: number;
    };
};

export type TFetchUserByIdSuccessPayload = { userData: UserDTO; serverId: string };

export type TFetchUserByIdSuccessAction = {
    type: typeof FETCH_USER_BYID_SUCCESS;
    payload: TFetchUserByIdSuccessPayload;
};

export type TFetchUsersByGroupIdPayload = {
    serverId: string;
    groupId: number;
};

export type TFetchUsersByGroupIdAction = {
    type: typeof FETCH_USERS_BY_GROUPID_REQUEST;
    payload: TFetchUsersByGroupIdPayload;
};

export type TFetchUsersByGroupIdSuccessPayload = {
    users: UserDTO[];
    serverId: string;
    groupId: number;
};

export type TFetchUsersByGroupIdSuccessAction = {
    type: typeof FETCH_USERS_BY_GROUPID_SUCCESS;
    payload: TFetchUsersByGroupIdSuccessPayload;
};

export type TEditUserPermissionsParams = {
    userId: number | null;
    dialogTypeId: string;
};

export type TOpenEditUserPermissionsDialogAction = {
    type: typeof OPEN_EDIT_USER_PERMISSIONS_DIALOG;
    payload: TEditUserPermissionsParams;
};

export type TSaveUsersLicensesChangesAction = {
    type: typeof SAVE_USERS_LICENSES_CHANGES;
    payload: {
        usersLicenses: UserDTOLicensesEnum[];
        userId: string;
    };
};

export type TSaveUsersGroupsChangesAction = {
    type: typeof SAVE_USERS_GROUPS_CHANGES;
    payload: {
        usersGroups: GroupDTO[];
        userId: string;
    };
};

export type TSaveUsersReposChangesAction = {
    type: typeof SAVE_USERS_REPOS_CHANGES;
    payload: {
        repos: string[];
        userId: string;
    };
};

export type TSaveUsersAccesesChangesAction = {
    type: typeof SAVE_USERS_ACCESSES_CHANGES;
    payload: {
        accesses: UserDTOAccessesEnum[];
        userId: string;
    };
};

export type TDeleteUsersAction = {
    type: typeof DELETE_USERS;
    payload: { usersId: number[] };
};

export type TDeleteUserSuccessPayload = {
    serverId: string;
    userId: number;
};

export type TDeleteUserSuccessAction = {
    type: typeof DELETE_USER_SUCCESS;
    payload: TDeleteUserSuccessPayload;
};

export type TFetchUserSessionsAction = {
    type: typeof FETCH_USER_SESSIONS;
    payload: { userId: number };
};

export type TFetchUserSessionsSuccessAction = {
    type: typeof FETCH_USER_SESSIONS_SUCCESS;
    payload: {
        userId: number;
        sessions: UserSessionHistory[];
    };
};

export type TUsersActions =
    | TFetchUsersAction
    | TFetchUsersSuccessAction
    | TEditUserDataBeginAction
    | TEditUserDataEndAction
    | TOpenEditUserPermissionsDialogAction
    | TSubmitUserDataAction
    | TFetchUserByIdAction
    | TFetchUserByIdSuccessAction
    | TSaveUsersLicensesChangesAction
    | TSaveUsersGroupsChangesAction
    | TSaveUsersReposChangesAction
    | TSaveUsersAccesesChangesAction
    | TOpenUserManagementTabAction
    | TDeleteUsersAction
    | TDeleteUserSuccessAction
    | TFetchUsersByGroupIdSuccessAction
    | TFetchUserSessionsAction
    | TFetchAllUsersRequestAction
    | TFetchUserSessionsSuccessAction;
