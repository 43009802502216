import { connect } from 'react-redux';
import { ChoseSymbolForDragDialog } from '../components/ChoseSymbolForDragDialog.component';
import { TChoseSymbolForDragDialogSubmitDataActionPayload } from '../../../actions/choseSymbolForDragDialog.actions.types';
import { choseSymbolForDragDialogSubmit } from '../../../actions/choseSymbolForDragDialog.actions';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeDialog(DialogType.CHOOSE_SYMBOL_FOR_DRAG_DIALOG)),
    onSubmit: (submitData: TChoseSymbolForDragDialogSubmitDataActionPayload) =>
        dispatch(choseSymbolForDragDialogSubmit(submitData)),
});

export const ChoseSymbolsForDragDialogContainer = connect(null, mapDispatchToProps)(ChoseSymbolForDragDialog);
