import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import messages from './messages/duplicatesTable.messages';
import theme from './FilterDuplicates.scss';
import React, { ChangeEvent, FC } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { MainStrategyType } from '../types/searchDuplicates.types';
import { TSetState } from '@/typings/state.types';

type TFilterDuplicatesProps = {
    filter: string;
    setFilter: TSetState<string>;
    setStrategy: TSetState<string>;
    strategy: MainStrategyType;
    setMergeAttributes: TSetState<boolean>;
    mergeAttributes: boolean;
    setDeleteSecondary: TSetState<boolean>;
    deleteSecondary: boolean;
    setMergeDecomposition: TSetState<boolean>;
    mergeDecomposition: boolean;
};

export const FilterDuplicates: FC<TFilterDuplicatesProps> = ({
    filter,
    setFilter,
    setStrategy,
    strategy,
    setMergeAttributes,
    setDeleteSecondary,
    setMergeDecomposition,
    mergeAttributes,
    mergeDecomposition,
    deleteSecondary,
}) => {
    const intl: IntlShape = useIntl();
    const mainStrategy: MainStrategyType[] = Object.values(MainStrategyType);

    const handleStrategyChange = (value: string) => {
        if (value) {
            setStrategy(value);
        }
    };

    const handleMergeAttributesChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMergeAttributes(e.target.checked);
    };

    const handleMergeDecompositionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMergeDecomposition(e.target.checked);
    };

    const handleDeleteSecondaryChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDeleteSecondary(e.target.checked);
    };

    const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    };

    return (
        <div className={theme.stickyContainer}>
            <div className={theme.container}>
                <div className={theme.labelSelectContainer}>
                    <Select
                        originalTheme
                        value={<span className={theme.selectText}>{intl.formatMessage(messages[strategy])}</span>}
                        onChange={handleStrategyChange}
                        wrapperClassName={theme.select}
                        label={intl.formatMessage(messages.strategyLabel)}
                    >
                        {mainStrategy.map((strategy: MainStrategyType) => {
                            return (
                                <Select.Option
                                    key={strategy}
                                    value={strategy}
                                    label={intl.formatMessage(messages[strategy])}
                                />
                            );
                        })}
                    </Select>
                </div>
                <div>
                    <Checkbox checked={mergeAttributes} onChange={handleMergeAttributesChange}>
                        {intl.formatMessage(messages.mergeAttributes)}
                    </Checkbox>
                    <Checkbox checked={mergeDecomposition} onChange={handleMergeDecompositionChange}>
                        {intl.formatMessage(messages.mergeDecomposition)}
                    </Checkbox>
                    <Checkbox checked={deleteSecondary} onChange={handleDeleteSecondaryChange}>
                        {intl.formatMessage(messages.deleteSecondary)}
                    </Checkbox>
                </div>
            </div>
            <Input
                value={filter}
                onChange={handleFilterChange}
                name="filter"
                label={intl.formatMessage(messages.search)}
            />
        </div>
    );
};
