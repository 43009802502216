import { TReducer } from '../utils/types';
import { NAVIGATOR_DESELECT_TAB, NAVIGATOR_SELECT_TAB } from '../actionsTypes/navigator.actionTypes';
import { TNavigatorTab, TNavigatorState } from './navigator.reducer.types';

const initial: TNavigatorState = {
    activeTabs: [TNavigatorTab.Tree],
};

export const navigatorReducer: TReducer<TNavigatorState> = (state = initial, action) => {
    switch (action.type) {
        case NAVIGATOR_DESELECT_TAB: {
            const { activeTabs } = state;
            const {
                payload: { tab },
            } = action;
            const index = activeTabs.indexOf(tab);

            return {
                activeTabs: [...activeTabs.slice(0, index), ...activeTabs.slice(index + 1, activeTabs.length)],
            };
        }
        case NAVIGATOR_SELECT_TAB: {
            const { activeTabs } = state;
            const {
                payload: { tab },
            } = action;

            return {
                activeTabs: [...activeTabs, tab],
            };
        }
        default: {
            return state;
        }
    }
};
