import { Popover } from 'antd';
import React from 'react';
import { Color, ColorChangeHandler, SketchPicker } from 'react-color';
import { NUIButton } from '../../../../../../../../UIKit/components/NUIButton/NUIButton.component';
import theme from '../SymbolGeneratorDialog.scss';

type TSymbolColorPickerProps = {
    onChange: ColorChangeHandler;
    style: { width?: string, backgroundColor: string }
    color: Color | undefined;
};

export const SymbolGeneratorColorPicker = ({onChange, style, color}: TSymbolColorPickerProps) => {
 
    return (
        <>
            <Popover
                trigger="click"
                content={
                    <SketchPicker
                        width="200px"
                        color={color}
                        onChange={onChange}
                        disableAlpha
                    />
                }
            >
                <NUIButton 
                    className={theme.colorIndicatorWrapper}
                >
                    <div
                        className={theme.colorIndicator}
                        style={style}
                    />
                </NUIButton>
            </Popover>
        </>
    );
};
