import type { LinkOptions } from '@tiptap/extension-link';
import type { EditorView } from '@tiptap/pm/view';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import Link from '@tiptap/extension-link';
import { getAttributes } from '@tiptap/core';

type TExtendedLinkOptions = LinkOptions & {
    onLinkClick?: (link, href: string) => void;
};

type TLinkClickProps = {
    extensionName: string;
    onLinkClick?: (link, href: string) => void;
};

const isLinkElement = (event): boolean => {
    if (event.button !== 0) {
        return false;
    }

    let a = event.target as HTMLElement;
    const els: HTMLElement[] = [];

    while (a.nodeName !== 'DIV') {
        els.push(a);
        a = a.parentNode as HTMLElement;
    }

    if (!els.find((value) => value.nodeName === 'A')) {
        return false;
    }

    return true;
};

const getClickHandler =
    ({ extensionName, onLinkClick }: TLinkClickProps) =>
    (view: EditorView, pos: number, event: MouseEvent) => {
        if (!isLinkElement(event)) {
            return false;
        }

        const attrs = getAttributes(view.state, extensionName);
        const link = event.target as HTMLLinkElement;
        const href = link?.href ?? attrs.href;

        if (!onLinkClick?.(link, href)) {
            return false;
        }

        return true;
    };

const LinkExtended = Link.extend<TExtendedLinkOptions>({
    addOptions() {
        return {
            ...this.parent?.(),
            onLinkClick: () => {},
        };
    },

    addProseMirrorPlugins() {
        const extensionName = this.name;
        const onLinkClick = this.options.onLinkClick;

        return [
            new Plugin({
                key: new PluginKey('handleClickLink'),
                props: {
                    handleClick: getClickHandler({ extensionName, onLinkClick }),
                },
            }),
        ];
    },
});

export { LinkExtended as Link };
