import { defineMessages } from 'react-intl';

export default defineMessages({
    generalSettings: {
        id: 'PresetImage.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    name: {
        id: 'PresetImage.name',
        defaultMessage: 'Название',
    },
    cancel: {
        id: 'PresetImage.cancel',
        defaultMessage: 'Отмена',
    },
    uploadText: {
        id: 'PresetImage.uploadText',
        defaultMessage: 'Загрузить',
    },
    presetImage: {
        id: 'PresetImage.presetImage',
        defaultMessage: 'Иконка методологии',
    },
    id: {
        id: 'PresetImage.id',
        defaultMessage: 'ID',
    },
    presetImageGroups: {
        id: 'PresetImage.presetImageGroups',
        defaultMessage: 'Группа иконок',
    },
    graphical: {
        id: 'PresetImage.graphical',
        defaultMessage: 'Изображение',
    }
});
