import { MatrixCell, MatrixNode } from '../../../serverapi/api';

export class MatrixBllService {
    public static addStyleIdsToCells = (matrix: MatrixNode): MatrixNode => {
        matrix?.content?.cells?.forEach((cell: MatrixCell) => {
            if (!cell?.styleIds?.length) {
                cell.styleIds = [];
            }
        });

        return matrix;
    };
}
