// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NUIButton-component__selected__sK4ik{background:rgba(39,107,238,.1);border-radius:4px}.NUIButton-component__container___vPu3{padding:6px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/NUIButton/NUIButton.component.scss"],"names":[],"mappings":"AAAA,sCACI,8BAAA,CACA,iBAAA,CAGJ,uCACI,WAAA","sourcesContent":[".selected {\r\n    background: rgba(#276bee, 0.1);\r\n    border-radius: 4px;\r\n}\r\n\r\n.container {\r\n    padding: 6px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": "NUIButton-component__selected__sK4ik",
	"container": "NUIButton-component__container___vPu3"
};
export default ___CSS_LOADER_EXPORT___;
