import React, { FC, useEffect } from 'react';
import theme from './ApprovalsTab.scss';
import { Icon } from '@/modules/UIKit';
import icApprovalEdit from '../../../../resources/icons/ic-approval-edit.svg';
import icApprovalInProgress from '../../../../resources/icons/ic-approval-in-progress.svg';
import { ApprovalDTOStatus } from '@/modules/ApprovalDialog/ApprovalDialog.types';
import icApprovalNotApproved from '../../../../resources/icons/ic-aproval-not-approved.svg';
import icApprovalApproved from '../../../../resources/icons/ic-aproval-approved.svg';
import icLinkCreate from 'icons/toolbar/controls/ic-link-create.svg';
import { useDispatch, useSelector } from 'react-redux';
import { hasModelEditorLicense } from '@/selectors/authorization.selectors';
import { ApprovalDTOStatusEnum, NodeId } from '@/serverapi/api';
import { editExistingApproval } from '@/actions/approval.actions';
import { copyLinkAction } from '@/actions/copyLinkDialog.actions';
import { ObjectTypesForLink } from '@/services/bll/ExternalLinkBLLService.types';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import messages from './ApprovalsTab.messages';

type TApprovalHeader = {
    name: string;
    status: ApprovalDTOStatusEnum;
    nodeId: NodeId;
    approvedItemId: string;
    toggled: boolean;
};

export const ApprovalHeader: FC<TApprovalHeader> = ({ name, status, nodeId, approvedItemId, toggled }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isModelEditor = useSelector(hasModelEditorLicense);

    const onToggleApproval = () => {
        const approvalContent = document.getElementById(nodeId.id);
        if (approvalContent) {
            approvalContent.classList.toggle('hidden');
        }
    };

    useEffect(() => {
        if (toggled) onToggleApproval();
    }, []);

    const onApprovalEdit = () => {
        dispatch(editExistingApproval({ approvalId: nodeId }));
    };

    const onCopyApprovalLink = () => {
        dispatch(copyLinkAction({ ...nodeId, id: approvedItemId }, ObjectTypesForLink.Approval, nodeId.id));
    };

    const renderApprovalIcon = (approvalStatus: ApprovalDTOStatusEnum): React.ReactNode => {
        let icon: React.ReactNode = <Icon spriteSymbol={icApprovalInProgress} />;
        let dataTest: string = 'approval-in-progress_icon';

        switch (approvalStatus) {
            case ApprovalDTOStatus.APPROVED:
                icon = <Icon spriteSymbol={icApprovalApproved} className={theme.statusApproved} />;
                dataTest = `approval-approved_icon`;
                break;
            case ApprovalDTOStatus.NOT_APPROVED:
                icon = <Icon spriteSymbol={icApprovalNotApproved} className={theme.statusNotApproved} />;
                dataTest = `approval-not-approved_icon`;
                break;
            case ApprovalDTOStatus.CANCELLED:
                icon = <Icon spriteSymbol={icApprovalNotApproved} className={theme.statusCancelled} />;
                dataTest = `approval-canceled_icon`;
                break;
            default:
        }

        return (
            <div className={theme.statusIconWrapper} data-test={dataTest}>
                {icon}
            </div>
        );
    };

    return (
        <div className={theme.header}>
            {renderApprovalIcon(status)}
            <div className={theme.title} onClick={onToggleApproval}>
                {name}
            </div>
            {isModelEditor && (status === ApprovalDTOStatus.IN_PROCESS || status === ApprovalDTOStatus.CANCELLED) ? (
                <Tooltip title={intl.formatMessage(messages.edit)} mouseLeaveDelay={0}>
                    <div className={theme.editIconWrapper} onClick={onApprovalEdit} data-test="edit-approval_icon">
                        <Icon spriteSymbol={icApprovalEdit} />
                    </div>
                </Tooltip>
            ) : null}
            <Tooltip title={intl.formatMessage(messages.copyLink)} mouseLeaveDelay={0}>
                <div className={theme.editIconWrapper} onClick={onCopyApprovalLink} data-test="copy-approval-link_icon">
                    <Icon spriteSymbol={icLinkCreate} />
                </div>
            </Tooltip>
        </div>
    );
};
