import { InputNumber, Popover, Tooltip } from 'antd';
import React, { useEffect, useState, FC } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { FontSizeSelect } from '../../../MainMenu/components/FontSizeSelect/FontSizeSelect.component';
import { NUIButton } from '../NUIButton/NUIButton.component';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import icBold from '../../../../resources/icons/ic-ribbon-text-bold.svg';
import icItalic from '../../../../resources/icons/ic-ribbon-text-italic.svg';
import icUnderline from '../../../../resources/icons/ic-ribbon-text-underline.svg';
import icLeft from '../../../../resources/icons/ic-ribbon-text-left.svg';
import icCenter from '../../../../resources/icons/ic-ribbon-text-center.svg';
import icRight from '../../../../resources/icons/ic-ribbon-text-right.svg';
import icTop from '../../../../resources/icons/ic-text-align-top.svg';
import icMiddle from '../../../../resources/icons/ic-text-align-middle.svg';
import icBottom from '../../../../resources/icons/ic-text-align-bottom.svg';
import { IntlShape, useIntl } from 'react-intl';
import messages from './FontSettings.messages';
import theme from './FontSettings.scss';
import { TOnChangeStyleReturn } from '../../../FloatingAttributes/FloatingAttributes.types';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import { TFontStyle, TAlign, TBaseline } from '../../../../mxgraph/mxgraph.types';
import { getAntdPopupContainer } from '../../../../utils/getAntdPopupContainer';

type TFontColorPickerButtonProps = {
    value: string;
    disabled?: boolean;
    onChange: (key: string) => void | TOnChangeStyleReturn;
};

export const FontColorPickerButton = ({
    value = '#000000',
    disabled = false,
    onChange,
}: TFontColorPickerButtonProps) => {
    const [displayFontColorPicker, setDisplayFontColorPicker] = useState<boolean>(false);
    const [fontColor, setFontColor] = useState<string>(value);
    const handleFontPickerChange = (color: ColorResult): void => {
        setFontColor(color.hex);
        setDisplayFontColorPicker(false);
        onChange(color.hex);
    };
    const intl: IntlShape = useIntl();

    useEffect(() => {
        setFontColor(value);
    }, [value]);

    return (
        <div className={theme.fontColorPickerButtonWrapper} data-test="property-window_attribute-around_font-color">
            <Tooltip title={intl.formatMessage(messages.selectFontColorTitle)} mouseLeaveDelay={0}>
                <Popover
                    getPopupContainer={getAntdPopupContainer}
                    content={<SketchPicker color={fontColor} onChange={handleFontPickerChange} disableAlpha />}
                    trigger="click"
                >
                    <NUIButton onClick={() => setDisplayFontColorPicker(!displayFontColorPicker)} disabled={disabled}>
                        <div
                            className={theme.colorIndicator}
                            style={{
                                backgroundColor: fontColor,
                            }}
                        />
                    </NUIButton>
                </Popover>
            </Tooltip>
        </div>
    );
};

export const FontSizeSelectButton = ({ value = '12', onChange, disabled = false }) => {
    const [fontSize, setFontSize] = useState<string>(value);
    const handleFontSizeSelect = (size: string) => {
        setFontSize(size);
        onChange(size);
    };
    const intl = useIntl();

    return (
        <Tooltip title={intl.formatMessage(messages.selectFontSizeTitle)} mouseLeaveDelay={0}>
            <div>
                <FontSizeSelect onChange={handleFontSizeSelect} value={fontSize} disabled={disabled} />
            </div>
        </Tooltip>
    );
};

type TFontSizeInput = {
    value: string;
    disabled?: boolean;
    onChange: (value: string) => void;
};

export const FontSizeInput = ({ value, onChange, disabled }: TFontSizeInput) => {
    const [fontSize, setFontSize] = useState<string>(value);

    useEffect(() => {
        setFontSize(value);
    }, [value]);

    const onChangeFontSize = (valueSize: number): void => {
        onChange(String(valueSize));
    };

    return (
        <div className={theme.fontSizeInputWrapper} data-test="property-window_attribute-around_font-size">
            <InputNumber
                disabled={disabled}
                min={1}
                max={100}
                value={parseInt(fontSize, 10)}
                formatter={(fontValue) => `${parseInt(String(fontValue), 10)}pt`}
                parser={(fontValue) => (fontValue ? +fontValue.replace(/pt/gi, '') : +fontSize)}
                onChange={onChangeFontSize}
            />
        </div>
    );
};

type TFontStyleButtonProps = {
    icon: TSpriteSymbol;
    selected: boolean;
    disabled: boolean;
    tooltip: string;
    onClick: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>;
    padding?: boolean;
    dataTest?: string;
};

export const FontStyleButton = ({
    icon,
    selected,
    disabled,
    tooltip,
    dataTest,
    onClick,
    padding,
}: TFontStyleButtonProps) => {
    return (
        <Tooltip mouseLeaveDelay={0} title={disabled ? '' : tooltip}>
            <span className={padding ? theme.buttonWrapperWithPadding : theme.buttonWrapper} data-test={dataTest}>
                <NUIButton onClick={onClick} selected={selected} disabled={disabled}>
                    <Icon spriteSymbol={icon} />
                </NUIButton>
            </span>
        </Tooltip>
    );
};

FontStyleButton.defaultProps = {
    padding: false,
    dataTest: '',
};

type TFontStyleButtonsProps = {
    value: TFontStyle;
    disabled?: boolean;
    onChange: (key: string) => (value: string | boolean) => void;
};

export const FontStyleButtons: FC<TFontStyleButtonsProps> = (props) => {
    const { value, disabled = false, onChange } = props;
    const intl: IntlShape = useIntl();

    return (
        <>
            <FontStyleButton
                icon={icBold}
                tooltip={intl.formatMessage(messages.selectBoldFontTitle)}
                selected={value.bold}
                disabled={disabled}
                padding
                dataTest="property-window_attribute-around_font-style_bold"
                onClick={() => {
                    onChange('bold')(!value.bold);
                }}
            />
            <FontStyleButton
                icon={icItalic}
                tooltip={intl.formatMessage(messages.selectItalicFontTitle)}
                selected={value.italic}
                disabled={disabled}
                padding
                dataTest="property-window_attribute-around_font-style_italic"
                onClick={() => {
                    onChange('italic')(!value.italic);
                }}
            />
            <FontStyleButton
                icon={icUnderline}
                tooltip={intl.formatMessage(messages.selectUnderlinedFontTitle)}
                selected={value.underline}
                disabled={disabled}
                dataTest="property-window_attribute-around_font-style_underline"
                onClick={() => {
                    onChange('underline')(!value.underline);
                }}
            />
        </>
    );
};

type TFontAlignButtonsProps = {
    value: TAlign;
    disabled?: boolean;
    onChange: (value: TAlign) => void;
};

export const FontAlignButtons: FC<TFontAlignButtonsProps> = (props) => {
    const { value, disabled = false, onChange } = props;
    const intl: IntlShape = useIntl();

    return (
        <>
            <FontStyleButton
                icon={icLeft}
                tooltip={intl.formatMessage(messages.selectLeftAlignFontTitle)}
                selected={value === 'left'}
                disabled={disabled}
                padding
                onClick={() => {
                    onChange('left');
                }}
            />
            <FontStyleButton
                icon={icCenter}
                tooltip={intl.formatMessage(messages.selectCenterAlignFontTitle)}
                selected={value === 'center'}
                disabled={disabled}
                padding
                onClick={() => {
                    onChange('center');
                }}
            />
            <FontStyleButton
                icon={icRight}
                tooltip={intl.formatMessage(messages.selectRightAlignFontTitle)}
                selected={value === 'right'}
                disabled={disabled}
                onClick={() => {
                    onChange('right');
                }}
            />
        </>
    );
};

type TFontBaseLineButtonsProps = {
    value: TBaseline;
    disabled?: boolean;
    onChange: (value: TBaseline) => void;
};

export const FontBaseLineButtons: FC<TFontBaseLineButtonsProps> = (props) => {
    const { value, disabled = false, onChange } = props;
    const intl: IntlShape = useIntl();

    return (
        <>
            <FontStyleButton
                icon={icTop}
                tooltip={intl.formatMessage(messages.selectTopAlignmentFontTitle)}
                selected={value === 'top'}
                disabled={disabled}
                padding
                onClick={() => {
                    onChange('top');
                }}
            />
            <FontStyleButton
                icon={icMiddle}
                tooltip={intl.formatMessage(messages.selectCenterAlignmentFontTitle)}
                selected={value === 'middle'}
                disabled={disabled}
                padding
                onClick={() => {
                    onChange('middle');
                }}
            />
            <FontStyleButton
                icon={icBottom}
                tooltip={intl.formatMessage(messages.selectBottomAlignmentFontTitle)}
                selected={value === 'bottom'}
                disabled={disabled}
                onClick={() => {
                    onChange('bottom');
                }}
            />
        </>
    );
};
