// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CopyDialog__about__i5E7x{margin:10px 0px}.CopyDialog__title__MlCJz{margin-bottom:.6em;font-weight:bold}.CopyDialog__text__KPu76{width:100%}", "",{"version":3,"sources":["webpack://./src/modules/CopyDialog/CopyDialog.scss"],"names":[],"mappings":"AAAA,0BACE,eAAA,CAGF,0BACE,kBAAA,CACA,gBAAA,CAGF,yBACE,UAAA","sourcesContent":[".about {\r\n  margin: 10px 0px;\r\n}\r\n\r\n.title {\r\n  margin-bottom: 0.6em;\r\n  font-weight: bold;\r\n}\r\n\r\n.text{\r\n  width: 100%;\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": "CopyDialog__about__i5E7x",
	"title": "CopyDialog__title__MlCJz",
	"text": "CopyDialog__text__KPu76"
};
export default ___CSS_LOADER_EXPORT___;
