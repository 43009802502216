import { PrincipalPermissions } from '../serverapi/api';
import {
    ALL_PERMISSIONS_FOR_NODE_CLEAR_DATA,
    ALL_PERMISSIONS_FOR_NODE_DATA,
    ALL_PERMISSIONS_FOR_NODE_FAILURE,
    ALL_PERMISSIONS_FOR_NODE_REQUEST,
} from '../actionsTypes/allPermissions.actionTypes';
import {
    TAllPermissionsClearDataAction,
    TAllPermissionsDataAction,
    TAllPermissionsFailure,
    TAllPermissionsForNodeRequestAction,
} from './allPermissions.actions.types';

export const allPermissionsRequest = (nodeId): TAllPermissionsForNodeRequestAction => ({
    type: ALL_PERMISSIONS_FOR_NODE_REQUEST,
    payload: { nodeId },
});

export const allPermissionsData = (serverId: string, data: PrincipalPermissions[]): TAllPermissionsDataAction => ({
    type: ALL_PERMISSIONS_FOR_NODE_DATA,
    payload: {
        serverId,
        data,
    },
});

export const allPermissionsClearData = (): TAllPermissionsClearDataAction => ({
    type: ALL_PERMISSIONS_FOR_NODE_CLEAR_DATA,
});

export const allPermissionsFailure = (error: string = ''): TAllPermissionsFailure => ({
    type: ALL_PERMISSIONS_FOR_NODE_FAILURE,
    payload: { error },
});
