import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { ServerProfileSwitcherComponent } from '../components/Logo/ServerProfileChange.component';
import { ServerProfileSelectors } from '../../../selectors/serverProfile.selectors';
import { ServerProfile } from '../../../serverapi/api';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { switchActiveServerProfile } from '../../../actions/serverProfile.actions';
import { TServerProfileChangeActionProps, TServerProfileChangeProps } from '../ServerProfile.types';
import { getAvailableUserProfiles } from '../../../selectors/authorization.selectors';

type TOwnProps = {
    serverId: string;
};

const mapStateToProps = (state: TRootState, props: Partial<TOwnProps & TServerProfileChangeProps>) => {
    const { serverId, open } = props;
    const serverProfiles: ServerProfile[] = ServerProfileSelectors.getAllServerProfilesByServerId(props.serverId!)(
        state,
    );
    const activeServerProfileId: string | undefined = ServerProfileSelectors.activeProfileId(state);
    const availableUserProfiles: ServerProfile[] = getAvailableUserProfiles(serverProfiles)(state);

    return {
        serverProfiles,
        activeServerProfileId,
        availableUserProfiles,
        serverId,
        open,
    };
};
const mapDispatchToProps: (dispatch, ownProps: TOwnProps) => TServerProfileChangeActionProps = (
    dispatch,
    ownProps: TOwnProps,
) => ({
    onCancel: () => dispatch(closeDialog(DialogType.SERVER_PROFILE_CHANGE)),
    onOk: (profileId: string) => {
        dispatch(closeDialog(DialogType.SERVER_PROFILE_CHANGE));
        dispatch(switchActiveServerProfile({ profileId, serverId: ownProps.serverId }));
    },
});

export const ServerProfileSwitcherContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ServerProfileSwitcherComponent);
