// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolbarButtons__triggerButton__J3hXl{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;margin-right:4px}.ToolbarButtons__triggerButton__J3hXl button{padding:4px !important;height:28px}.ToolbarButtons__relativeTooltipContainer__oF4Pg{position:relative;display:inline}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/ToolbarButton/ToolbarButtons.scss"],"names":[],"mappings":"AAAA,sCACI,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,gBAAA,CAEA,6CACI,sBAAA,CACA,WAAA,CAIR,iDACI,iBAAA,CACA,cAAA","sourcesContent":[".triggerButton {\r\n    justify-content: center;\r\n    align-items: center;\r\n    display: flex;\r\n    margin-right: 4px;\r\n\r\n    button {\r\n        padding: 4px !important;\r\n        height: 28px;\r\n    }\r\n}\r\n\r\n.relativeTooltipContainer {\r\n    position: relative;\r\n    display: inline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"triggerButton": "ToolbarButtons__triggerButton__J3hXl",
	"relativeTooltipContainer": "ToolbarButtons__relativeTooltipContainer__oF4Pg"
};
export default ___CSS_LOADER_EXPORT___;
