import pieChartIcon from '../../../../resources/icons/pieChart.svg';
import lineChartIcon from '../../../../resources/icons/lineChart.svg';
import barChartIcon from '../../../../resources/icons/barChart.svg';
import summary from '../../../../resources/icons/summary.svg';
import textBlock from '../../../../resources/icons/textBlock.svg';
import messages from './DashboardSymbols.messages';
import { LocalesService } from '@/services/LocalesService';
import { DashboardWidgetTypes, TDashboardSymbolType } from '@/modules/Dashboard/Dashboard.types';

const intl = LocalesService.useIntl();

export const symbols: TDashboardSymbolType[] = [
    {
        id: 1,
        name: intl.formatMessage(messages.pieChart),
        type: DashboardWidgetTypes.PieChart,
        icon: pieChartIcon,
    },
    {
        id: 2,
        name: intl.formatMessage(messages.lineChart),
        type: DashboardWidgetTypes.LineChart,
        icon: lineChartIcon,
    },
    {
        id: 3,
        name: intl.formatMessage(messages.barChart),
        type: DashboardWidgetTypes.BarChart,
        icon: barChartIcon,
    },
    {
        id: 4,
        name: intl.formatMessage(messages.summary),
        type: DashboardWidgetTypes.Summary,
        icon: summary,
    },
    {
        id: 5,
        name: intl.formatMessage(messages.textBlock),
        type: DashboardWidgetTypes.TextBlock,
        icon: textBlock,
    },
];
