import { convertToDecimal, convertToNumber } from "../../utils/ConvertToNumber.utils";

enum Chars {
    comma = ',',
    zero = '0',
    dot = '.',
    minus = '-',
};

export class DecimalNumberValidatorBllService {
    // Удаляет все кроме чисел, и "."
    // Сохраняет только первый "-" в строке и удаляет все остальные
    // Удаляет "-" если перед ним стоит число и добавляет это число после "-"
    // Если перед "-" стоит символ а не число, то оставляем "-" и удаляем символ
    public  static sanitizeInputValue (value: string): string {
        return value
            .toString()
            .replace(/[^\d.,-]+/g, '')
            .replace(/^(-)|-/g, '$1')
            .trim();
    } 

    // Валидирует значение в инпуте при вводе. Допустимые значения: числа (0-9), точка ".", минус "-", запятая ","
    // Допускаются любые дробные числа (положительные и отрицательные) "-0" не допускается
    public static convertToFractionalNumber(
        val: string
    ): string {
        const { sanitizeInputValue, convertToFractionalNumber } = DecimalNumberValidatorBllService;
        const str = sanitizeInputValue(val);
        const [zeroIndexVal, firstIndexVal, secondIndexVal] = str;
        const isStartWithZero = str.startsWith(Chars.zero);
        const isStartWithMinus = str.startsWith(Chars.minus);
        const isNaN = Number.isNaN(Number(str));
        const isStartWithComma = str.startsWith(Chars.comma);
        const isStartWithDot = str.startsWith(Chars.dot);
        const startsWithTwoZeros = isStartWithZero && firstIndexVal === '0';
        const startsWithOneZero = isStartWithZero && firstIndexVal !== '0';
        const moreThenThreeChars = str.length > 3;
        const moreThenOneChar = str.length > 1;
        const shouldDeleteFirstChar =
        startsWithTwoZeros ||
        (isStartWithMinus &&
            firstIndexVal === Chars.zero &&
            !(secondIndexVal === Chars.dot) &&
            !(secondIndexVal === Chars.comma) &&
            !isNaN &&
            moreThenThreeChars);

        const shouldDeleteZeroChar =
        (startsWithOneZero && !(firstIndexVal === Chars.dot) && moreThenOneChar && !isNaN) ||
        (isStartWithZero && firstIndexVal === Chars.minus);
    
        if (isStartWithComma) {
            return convertToFractionalNumber(str.replace(Chars.comma, Chars.dot));
        }
        if (shouldDeleteFirstChar) {
            return convertToFractionalNumber(str.replace(firstIndexVal, ''));
        }
        if (shouldDeleteZeroChar) {
            return convertToFractionalNumber(str.replace(zeroIndexVal, ''));
        }
        if (isStartWithDot || isStartWithComma) {
            return convertToFractionalNumber(`${Chars.zero}${str}`);
        }
        if (isStartWithMinus && (firstIndexVal === Chars.dot || firstIndexVal === Chars.comma)) {
            return convertToFractionalNumber(str.replace(/[.,]/, ''));
        }
        const startStr = str.indexOf(Chars.minus) === -1 ? '' : Chars.minus;
        const delimiterPosition = str.indexOf(Chars.dot) === -1 ? str.indexOf(Chars.comma) : str.indexOf(Chars.dot);
        const delimiter = Chars.dot;
    
        if (delimiterPosition === -1) return `${startStr}${convertToNumber(str)}`;
    
        const wholeNumberStr = str.slice(0, delimiterPosition);
        const fractionalNumberStr = str.slice(delimiterPosition);
        const wholeNumber = convertToDecimal(wholeNumberStr);
        const fractionalNumber = convertToDecimal(fractionalNumberStr);
    
        return `${startStr}${wholeNumber}${delimiter}${fractionalNumber}`;
    }

    // Валидирует значение введенное в инпуте после потери фокуса
    // Удаляет "-" перед "0"
    // Удаляет "-" если введен только он
    // Удаляет "." после нуля если после точки ничего нет (если после точки стоит ноль и после него ничего нет то удаляет "." и "0")
    // Удаляет "0" в начале если после него стоит цифра 
    // Удаляет "0" после "." если в конце стоит "0"
    public static onBlurValidator(
        str: string
    ): string {
        const { onBlurValidator } = DecimalNumberValidatorBllService;
        const lastChar = str[str.length - 1];
        if (lastChar === Chars.dot) {
            return onBlurValidator(str.replace(Chars.dot, ''));
        }
        if (str === `-${Chars.zero}` || str === `-${Chars.zero}.`) {
            return onBlurValidator(Chars.zero);
        }
        if (str === Chars.minus) {
            return onBlurValidator('');
        }
        if (lastChar === Chars.zero && str.length > 1 && str.includes(Chars.dot)) {
            return onBlurValidator(String(parseFloat(str)));
        }

        return str;
    }
}
