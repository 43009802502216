import React, { FC, memo } from 'react';

export const CollapseIcon: FC = memo(() => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 11.25C2 11.6642 2.33579 12 2.75 12H13.25C13.6642 12 14 11.6642 14 11.25C14 10.8358 13.6642 10.5 13.25 10.5H2.75C2.33579 10.5 2 10.8358 2 11.25Z"
            fill="#676767"
        />
    </svg>
));
