export enum StyleFormat {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    NONE = 'NONE',
}

export enum Areas {
    LEFT_TOP = 'left_top',
    LEFT_MIDDLE = 'left_middle',
    LEFT_BOTTOM = 'left_bottom',
    CENTER_TOP = 'center_top',
    CENTER_MIDDLE = 'center_middle',
    CENTER_BOTTOM = 'center_bottom',
    RIGHT_TOP = 'right_top',
    RIGHT_MIDDLE = 'right_middle',
    RIGHT_BOTTOM = 'right_bottom',
}

export const edgeDisabledAreas = [
    Areas.LEFT_TOP,
    Areas.LEFT_MIDDLE,
    Areas.LEFT_BOTTOM,
    Areas.RIGHT_TOP,
    Areas.RIGHT_MIDDLE,
    Areas.RIGHT_BOTTOM,
    Areas.CENTER_TOP,
    Areas.CENTER_BOTTOM,
];
