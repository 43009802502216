import { defineMessages } from 'react-intl';

export default defineMessages({
    spreadsheet: {
        id: 'SpreadsheetDialog.spreadsheet',
        defaultMessage: 'Таблица',
    },
    spreadsheetFormName: {
        id: 'SpreadsheetDialog.spreadsheetFormName',
        defaultMessage: 'Новая таблица',
    },
    spreadsheetNameLabel: {
        id: 'SpreadsheetDialog.spreadsheetFormLabel',
        defaultMessage: 'Имя таблицы',
    },
    spreadsheetNamePlaceholder: {
        id: 'SpreadsheetDialog.spreadsheetFormPlaceholder',
        defaultMessage: 'Имя новой таблицы',
    },
    spreadsheetNameRequiredError: {
        id: 'SpreadsheetDialog.spreadsheetFormRequiredError',
        defaultMessage: 'Имя таблицы должно быть заполнено',
    },
    spreadsheetFormConfirmButton: {
        id: 'SpreadsheetDialog.spreadsheetFormConfirmButton',
        defaultMessage: 'Создать',
    },
    spreadsheetFormDeclineButton: {
        id: 'SpreadsheetDialog.spreadsheetFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    spreadsheetCreateError: {
        id: 'SpreadsheetDialog.spreadsheetCreateError',
        defaultMessage: 'Не удалось создать таблицу',
    },
    spreadsheetAccessDeniedError: {
        id: 'SpreadsheetDialog.spreadsheetAccessDeniedError',
        defaultMessage: 'Недостаточно прав доступа',
    },
});
