import { defineMessages } from 'react-intl';

export default defineMessages({
    EmptyValue: {
        id: 'Properties.EmptyValue',
        defaultMessage: 'Нет данных',
    },
    defaultFolderType: {
        id: 'Properties.defaultFolderType',
        defaultMessage: 'Стандартная папка'
    }
});
