import { defineMessages } from 'react-intl';

export default defineMessages({
    formValidationMessageRequired: {
        id: 'FontSizeSelect.formValidationMessageRequired',
        defaultMessage: 'Размер шрифта не должен быть пустым',
    },
    formValidationMessageNumberOnly: {
        id: 'FontSizeSelect.formValidationMessageNumberOnly',
        defaultMessage: 'Размер шрифта должен состоять из положительных чисел',
    },

    formLabel: {
        id: 'FontSizeSelect.formLabel',
        defaultMessage: 'Размер шрифта (pt)',
    },
});
