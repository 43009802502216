export const SUBMIT_PRESET_IMAGE_GROUP = 'SUBMIT_PRESET_IMAGE_GROUP';

export const ADD_PRESET_IMAGE_GROUPS = 'ADD_PRESET_IMAGE_GROUPS';

export const CREATE_PRESET_IMAGE_GROUP = 'CREATE_PRESET_IMAGE_GROUP';

export const DELETE_PRESET_IMAGE_GROUP_REQUEST = 'DELETE_PRESET_IMAGE_GROUP_REQUEST';

export const DELETE_PRESET_IMAGE_GROUP = 'DELETE_PRESET_IMAGE_GROUP';

export const DELETE_PRESET_IMAGE_GROUP_SUCCESS = 'DELETE_PRESET_IMAGE_GROUP_SUCCESS';

export const EDIT_PRESET_IMAGE_GROUP = 'EDIT_PRESET_IMAGE_GROUP';
