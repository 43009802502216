import {
    AttributeValue,
    AttributeValueValueTypeEnum,
    Node,
    NodeId,
    NodeTypeEnum,
    ObjectInstance,
} from '../../serverapi/api';
import { LocalesService } from '../../services/LocalesService';
import { MxCell } from '../mxgraph';

type ParamSource = 'object' | 'model' | 'frame' | 'locale' | undefined;

export const DEPRECATED_VERSION_FIELD_NAME = '__decriptor_version__';

export const VERSION_FIELD_NAME = '__descriptor_version__';

export const DEPRECATED_ATTRIBUTE_FIELD_NAME = 'attrtibutesIds';

export const ATTRIBUTE_FIELD_NAME = 'attributesIds';

class PartNameDescriptor {
    paramSource: ParamSource;
    [ATTRIBUTE_FIELD_NAME]?: string[] | undefined;
    messageId?: string | undefined;
    defaultValue: string | undefined;
}

export const replaceDeprecatedFieldNames = (json: string) =>
    json
        ?.replace(new RegExp(DEPRECATED_VERSION_FIELD_NAME, 'g'), VERSION_FIELD_NAME)
        .replace(new RegExp(DEPRECATED_ATTRIBUTE_FIELD_NAME, 'g'), ATTRIBUTE_FIELD_NAME);

export class NameDescriptor {
    [VERSION_FIELD_NAME]: string;
    format: string;
    params: PartNameDescriptor[];

    formatString(param: string[]): string | undefined {
        if (this.format) {
            return param.reduce((am, c, i) => am.split(`{${i}}`).join(c), this.format);
        }

        return param.join(' ');
    }

    public static fromJSON(json: string): NameDescriptor | undefined {
        try {
            const n = JSON.parse(json.replace(/[']/g, '"'));

            return Object.assign(new NameDescriptor(), n);
        } catch (e) {
            return;
        }
    }
}

export const resolveName = (
    cell: MxCell,
    modelId: NodeId,
    getNode: (nodeId: NodeId | undefined, nodeType: NodeTypeEnum) => Node | undefined,
): string | undefined => {
    if (!cell || !cell.getValue) {
        return;
    }

    const str = cell.getValue();

    if (
        str &&
        typeof str === 'string' &&
        str.startsWith('{') &&
        (str.includes(VERSION_FIELD_NAME) || str.includes(DEPRECATED_VERSION_FIELD_NAME))
    ) {
        const descriptor = NameDescriptor.fromJSON(replaceDeprecatedFieldNames(str));

        if (!descriptor || !descriptor.params) {
            return;
        }

        const arr = descriptor.params.map((d) => {
            let node: Node | undefined;

            if (d.paramSource === 'frame') {
                const nodeId = findFrameObject(cell, modelId);
                node = getNode(nodeId, 'OBJECT');
            }
            if (d.paramSource === 'model') {
                node = getNode(modelId, 'MODEL');
            }
            if (d.paramSource === 'locale') {
                return d.messageId && LocalesService.hasMessage(d.messageId)
                    ? LocalesService.useIntl().formatMessage({ id: d.messageId })
                    : d.defaultValue;
            }

            const attr = findAttribute(node, d[ATTRIBUTE_FIELD_NAME]);

            return !attr ? d.defaultValue : transformValueFromType(attr);
        });

        return descriptor.formatString(arr.filter((s) => s !== undefined) as string[]);
    }

    return undefined;
};

export const findAttribute = (
    node: Node | undefined,
    attributesIds: string[] | undefined,
): AttributeValue | undefined => {
    if (!attributesIds || !node) {
        return undefined;
    }
    const attrtibuteId = attributesIds.find((aId) => node[aId]);
    if (attrtibuteId) {
        let valueType: AttributeValueValueTypeEnum = 'STRING';
        if (attrtibuteId === 'createdAt' || attrtibuteId === 'updatedAt') {
            valueType = 'DATE';
        }

        return {
            id: attrtibuteId,
            typeId: '',
            valueType,
            value: node[attrtibuteId],
        };
    }

    return node.attributes?.find((a) => attributesIds.includes(a.typeId));
};

export const findFrameObject = (cell: MxCell, modelId: NodeId): NodeId | undefined => {
    while (cell?.getValue && cell?.getValue()?.type !== 'object') {
        cell = cell.parent;
    }

    if (!cell?.getValue || cell?.getValue()?.type !== 'object') {
        return;
    }

    return {
        ...modelId,
        id: (cell.getValue() as ObjectInstance).objectDefinitionId || '',
    } as NodeId;
};

// todo переписать, тут может быть и boolean и число, функция для преобразовния атрибута будет много где испоьзоваться
const transformValueFromType = (attr: AttributeValue | undefined): string | undefined => {
    const text = attr?.value;
    const type = attr?.valueType;
    if (!text || !type) {
        return undefined;
    }
    if (type == 'DATE') {
        return new Date(Number.parseInt(text)).toLocaleDateString('ru-RU');
    }

    return text;
};
