import { TReducer } from '@/utils/types';
import { TAutoSaveModelIntervalIdsState } from './autoSaveModelIntervalIds.reducer.types';
import {
    ADD_AUTO_SAVE_INTERVAL_ID,
    DELETE_AUTO_SAVE_INTERVAL_ID,
} from '@/actionsTypes/autoSaveModelIntervalIds.actionTypes';

const initialState: TAutoSaveModelIntervalIdsState = [];

export const autoSaveModelIntervalIdsReducer: TReducer<TAutoSaveModelIntervalIdsState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case ADD_AUTO_SAVE_INTERVAL_ID: {
            return [...state, action.payload];
        }
        case DELETE_AUTO_SAVE_INTERVAL_ID: {
            return state.filter((id) => id !== action.payload);
        }
        default:
            return state;
    }
};
