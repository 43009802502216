export enum Alignment {
    Left,
    CenterVert,
    Right,
    Top,
    CenterHorz,
    Bottom,
    DistributeHor,
    DistributeVert,
}
