import React, { useState, ChangeEvent } from 'react';
import { Form, Input } from 'antd';
import theme from './CreateGraphSymbolDialog.scss';
import { Rule } from 'rc-field-form/es/interface';
import { MAX_ROW_COL_COUNTS } from './CreateCrossSymbolDialog.component';
import { Button } from '../UIKit/components/Button/Button.component';
import icEdit from '@/resources/icons/edit.svg';

type TControledValueProps = {
    initialValue: string;
    disabled?: boolean;
    valuesKey: string;
    inputType?: 'number';
    rules: Rule[];
};

export const ControledValue = (props: TControledValueProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const { initialValue, disabled, valuesKey, rules, inputType } = props;
    const [value, setValue] = useState<string>(initialValue);

    const handleEdit = () => {
        setIsEditing((prevIsEditing) => !prevIsEditing);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

    return (
        <div className={theme.editableTextContainer}>
            <Form.Item
                name={valuesKey}
                rules={rules}
                initialValue={value}
                className={disabled ? theme.formItemDisabled : theme.formItem}
            >
                {isEditing ? (
                    <Input
                        type={inputType}
                        min={1}
                        max={MAX_ROW_COL_COUNTS}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        autoFocus
                    />
                ) : (
                    <span>{value}</span>
                )}
            </Form.Item>
            <Button disabled={disabled} icon={icEdit} onClick={handleEdit} />
        </div>
    );
};
