import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { MatrixType } from '../serverapi/api';
import { IMatrixModelTypeData } from '@/reducers/matrixModelType.reducer.types';
import { INITIAL_MATRIX_MODEL_TYPE_SERVER_STATE } from '@/reducers/matrixModelType.reducer';

export const EMPTY_PRESET_ID = 'empty';

const matrixModelTypeStateSelector = (state: TRootState) => state.matrixModelType;

const toMatrixModelTypeArray = (srcData: IMatrixModelTypeData): MatrixType[] => {
    return Object.keys(srcData.byId).map((k) => srcData.byId[k]);
};

export namespace MatrixModelTypeSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector(matrixModelTypeStateSelector, (state) => {
            const serverModelTypeState = state[presetId];
            if (serverModelTypeState) {
                return { ...serverModelTypeState };
            }

            return { ...INITIAL_MATRIX_MODEL_TYPE_SERVER_STATE };
        });

    export const getAllMatrixModelTypes = (presetId: string) =>
        createSelector(byPresetId(presetId), (state) => toMatrixModelTypeArray(state));

    export const byId = (modelTypeId: string, presetId: string) =>
        createSelector(byPresetId(presetId), (state) => {
            return state.byId[modelTypeId];
        });
}
