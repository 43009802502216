import { defineMessages } from 'react-intl';

export default defineMessages({
    FUNCTIONS: {
        id: 'Simulation.FUNCTIONS',
        defaultMessage: 'Функции',
    },
    EVENTS: {
        id: 'Simulation.EVENTS',
        defaultMessage: 'События',
    },
    RESOURCES: {
        id: 'Simulation.RESOURCES',
        defaultMessage: 'Ресурсы',
    },
    REPORTS: {
        id: 'Simulation.REPORTS',
        defaultMessage: 'Отчеты',
    },

    // Error report
    type: {
        id: 'Simulation.type',
        defaultMessage: 'Ошибка',
    },
    modelName: {
        id: 'Simulation.modelName',
        defaultMessage: 'Модель',
    },
    elementName: {
        id: 'Simulation.elementName',
        defaultMessage: 'Объект',
    },
    message: {
        id: 'Simulation.message',
        defaultMessage: 'Сообщение',
    },
    every: {
        id: 'Simulation.every',
        defaultMessage: 'каждые',
    },
    sec: {
        id: 'Simulation.sec',
        defaultMessage: 'сек',
    },
    copyIDElement: {
        id: 'Simulation.copyIDElement',
        defaultMessage: 'Скопируйте id элемента диаграммы',
    },
    simulationResult: {
        id: 'Simulation.simulationResult',
        defaultMessage: 'Результат симуляции',
    },
    cancelSimulation: {
        id: 'Simulation.cancelSimulation',
        defaultMessage: 'Завершать симуляцию при',
    },
    time: {
        id: 'Simulation.time',
        defaultMessage: 'раз',
    },
    by: {
        id: 'Simulation.by',
        defaultMessage: 'по',
    },
    challenges: {
        id: 'Simulation.challenges',
        defaultMessage: 'вызовах',
    },
    launch: {
        id: 'Simulation.launch',
        defaultMessage: 'Запуск',
    },
    name: {
        id: 'SimulationModeling.name',
        defaultMessage: 'Наименование',
    },
    symbolName: {
        id: 'Simulation.symbolName',
        defaultMessage: 'Тип символа',
    },
    count: {
        id: 'Simulation.count',
        defaultMessage: 'Количество выполнений',
    },
    sumWaitTime: {
        id: 'Simulation.sumWaitTime',
        defaultMessage: 'Суммарное время ожидания'
    },
    maxWaitTime: {
        id: 'Simulation.maxWaitTime',
        defaultMessage: 'Максимальное время ожидания'
    },
    avgWaitTime: {
        id: 'Simulation.avgWaitTime',
        defaultMessage: 'Среднее время ожидания',
    },
    sumProcessTime: {
        id: 'Simulation.sumProcessTime',
        defaultMessage: 'Суммарное время выполнения',
    },
    avgProcessTime: {
        id: 'Simulation.avgProcessTime',
        defaultMessage: 'Среднее время выполнения',
    },
    cancel: {
        id: 'SimulationModeling.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'SimulationModeling.save',
        defaultMessage: 'Сохранить',
    },
    simulationStartAddTitle: {
        id: 'Simulation.simulationStartAddTitle',
        defaultMessage: 'Добавление стартового события',
    },
    simulationEndAddTitle: {
        id: 'Simulation.simulationEndAddTitle',
        defaultMessage: 'Добавление конечного события',
    },
    simulationSetupTitle: {
        id: 'SimulationModeling.simulationSetupTitle',
        defaultMessage: 'Настройки обработки',
    },
    labelID: {
        id: 'Simulation.labelID',
        defaultMessage: 'ID экземпляра объекта',
    },
    startSimulation: {
        id: 'Simulation.startSimulation',
        defaultMessage: 'Старт',
    },
    addSimulation: {
        id: 'Simulation.addSimulation',
        defaultMessage: 'Добавить',
    },
    timeStart: {
        id: 'Simulation.timeStart',
        defaultMessage: 'Время старта',
    },
    timeEnd: {
        id: 'Simulation.timeEnd',
        defaultMessage: 'Время завершения',
    },
    timeStartErr: {
        id: 'Simulation.timeStartErr',
        defaultMessage: 'Время старта должно быть меньше времени завершения',
    },
    timeEndErr: {
        id: 'Simulation.timeEndErr',
        defaultMessage: 'Время завершения должно быть больше времени старта',
    },
    delete: {
        id: 'Simulation.delete',
        defaultMessage: 'Удалить',
    },
    rename: {
        id: 'Simulation.rename',
        defaultMessage: 'Переименовать',
    },
    copy: {
        id: 'Simulation.copy',
        defaultMessage: 'Скопировать',
    },
    edit: {
        id: 'Simulation.edit',
        defaultMessage: 'Редактировать',
    },
    setupHandler: {
        id: 'Simulation.setupHandler',
        defaultMessage: 'Настройки обработки',
    },
    endParameters: {
        id: 'Simulation.endParameters',
        defaultMessage: 'Параметры конечных событий: ',
    },
    startParameters: {
        id: 'Simulation.startParameters',
        defaultMessage: 'Параметры стартовых событий: ',
    },
    notAffectsOnSimulation: {
        id: 'Simulation.notAffectsOnSimulation',
        defaultMessage: 'Не влияет на завершение симуляции',
    },
    eventsCount: {
        id: 'Simulation.eventsCount',
        defaultMessage: 'Количество событий',
    },
    interval: {
        id: 'Simulation.interval',
        defaultMessage: 'Интервал возникновения событий',
    },
    maxRuns: {
        id: 'Simulation.maxRuns',
        defaultMessage: 'Максимальное количество запусков событий',
    },
    endRun: {
        id: 'Simulation.endRun',
        defaultMessage: 'Завершать имитационное моделирование при ',
    },
    cloneName: {
        id: 'Simulation.cloneName',
        defaultMessage: '{name} Клон',
    },
    simulationModel: {
        id: 'Simulation.simulationModel',
        defaultMessage: 'Имитационное моделирование',
    },
    reportFull: {
        id: 'Simulation.reportFull',
        defaultMessage: 'Все результаты',
    },
    reportFinishedSuccess: {
        id: 'Simulation.reportFinishedSuccess',
        defaultMessage: 'Экземпляры симуляции, завершенные успешно',
    },
    reportFinishedFailed: {
        id: 'Simulation.reportFinishedFailed',
        defaultMessage: 'Экземпляры симуляции, завершенные неуспешно',
    },
    reportFinished: {
        id: 'Simulation.reportFinished',
        defaultMessage: 'Экземпляры симуляции, завершенные',
    },
    reportUnfinished: {
        id: 'Simulation.reportUnfinished',
        defaultMessage: 'Экземпляры симуляции, не завершенные',
    },
});
