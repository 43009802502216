import { defineMessages } from 'react-intl';

export default defineMessages({
    source: {
        id: 'NavigatorEdgeTab.source',
        defaultMessage: 'исх.',
    },
    target: {
        id: 'NavigatorEdgeTab.target',
        defaultMessage: 'вх.',
    },
    edge: {
        id: 'NavigatorEdgeTab.edge',
        defaultMessage: 'Связь',
    },
    invisibleEdge: {
        id: 'NavigatorEdgeTab.invisibleEdge',
        defaultMessage: 'Невидимая связь',
    },
    object: {
        id: 'NavigatorEdgeTab.object',
        defaultMessage: 'Объект',
    },
    currentModel: {
        id: 'NavigatorEdgeTab.currentModel',
        defaultMessage: 'Текущая модель',
    },
    deleteEdgeTitle: {
        id: 'NavigatorEdgeTab.deleteEdgeTitle',
        defaultMessage: 'Удалить связь',
    },
    unknown: {
        id: 'NavigatorEdgeTab.unknown',
        defaultMessage: 'Неизвестно'
    }
});
