import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icObject from '../../../../../resources/icons/object.svg';
import { getCurrentLocale } from '../../../../../selectors/locale.selectors';
import { NodeId, PresetElementTransferType, ObjectType, ObjectTypeGroup } from '../../../../../serverapi/api';
import messages from '../../messages/Presets.messages';
import { GroupSelectionDialog } from './Dialog/GroupSelectionDialog/GroupSelectionDialog.component';
import { DeleteSelected, ExportButton, ImportButton, MoveSelected, TabHeader } from './Header/TabHeader.component';
import { GroupedTypesTable } from './util/GroupedTypesTable.component';
import theme from './Presets.scss';
import { useIntl } from 'react-intl';
import { ObjectTypeSelectors } from '../../../../../selectors/objectType.selectors';
import { ObjectTypeGroupSelectors } from '../../../../../selectors/objectTypeGroup.selectors';
import { TreeNode } from '../../../../../models/tree.types';
import {
    createObjectType,
    deleteObjectType,
    deleteObjectTypeAndGroup,
    editObjectType,
    submitObjectTypes,
} from '../../../../../actions/objectType.actions';
import {
    createObjectTypeGroup,
    deleteObjectTypeGroup,
    editObjectTypeGroup,
} from '../../../../../actions/objectTypeGroup.actions';
import { TPreset } from '../../../../../models/preset.types';
import { exportPreset } from '../../../../../actions/methodologySetting.actions';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { LocalesService } from '../../../../../services/LocalesService';
import { Alert } from 'antd';
import { ButtonWithDropdown } from './Header/ButtonWithDropdown.component';
import icFolder from '../../../../../resources/icons/group.svg';
import icImport from '../../../../../resources/icons/Import.svg';
import { PRESET_IMPORT_EXTENSIONS } from '../../../../FileUpload/types/FileUploadDialog.constants';

type TObjectTypesTabProps = {
    disabled: boolean;
    serverNode: TreeNode;
    preset: TPreset;
    tabNodeId: NodeId;
};

export const ObjectTypesTab = ({ serverNode, preset, disabled, tabNodeId }: TObjectTypesTabProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { serverId } = serverNode.nodeId;
    const presetId = preset.id;

    const importProperties = {
        preset,
        filters: PRESET_IMPORT_EXTENSIONS,
    };

    const objectTypesById = useSelector(
        ObjectTypeSelectors.byPresetId({
            serverId,
            presetId,
        }),
    );
    const objectTypes: ObjectType[] = Object.values(objectTypesById?.byId || {});

    const objectTypeGroups: ObjectTypeGroup[] = useSelector(
        ObjectTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId,
        }),
    );

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [selectedObjectTypes, setSelectedObjectTypes] = useState<ObjectType[]>([]);
    const [selectedObjectTypeGroups, setSelectedObjectTypeGroups] = useState<ObjectTypeGroup[]>([]);
    const [selectGroupDialogVisible, setSelectGroupDialogVisible] = useState<boolean>(false);
    const selected = selectedObjectTypes.length || selectedObjectTypeGroups.length;
    const [saveRequired, setSaveRequired] = useState<boolean>(false);
    const currentLocale = useSelector(getCurrentLocale);
    const objects: string | undefined = LocalesService.internationalStringArrayToString(selectedObjectTypes);
    const objectGroups: string | undefined = LocalesService.internationalStringArrayToString(selectedObjectTypeGroups);
    const deleteObjectsMessage = selectedObjectTypes.length ? (
        <div>
            <b>{`${intl.formatMessage(messages.deleteObjects)}: `}</b>
            {objects}
        </div>
    ) : (
        ''
    );
    const deleteGroupsMessage = selectedObjectTypeGroups.length ? (
        <div>
            <b>{`${intl.formatMessage(messages.deleteObjectGroups)}: `}</b>
            {objectGroups}
        </div>
    ) : (
        ''
    );

    const changeObjectTypeGroup = useCallback((changedObjectTypes: ObjectType[]) => {
        dispatch(
            submitObjectTypes({
                objectTypes: changedObjectTypes,
                preset,
                serverNode,
                preventTabClose: true,
                tabNodeId,
            }),
        );
    }, []);

    const onExportObjectTypes = useCallback((exportObjectTypes: ObjectType[]) => {
        const exportObjectTypesIds = exportObjectTypes.map((ot) => ot.id);
        dispatch(exportPreset(preset, serverId, PresetElementTransferType.object, exportObjectTypesIds));
    }, []);

    const onImportObjectTypes = useCallback(() => {
        dispatch(
            openDialog(DialogType.UPLOAD_PRESET_DIALOG, {
                serverNode,
                type: PresetElementTransferType.object,
                ...importProperties,
            }),
        );
    }, []);

    return (
        <div className={theme.container}>
            <TabHeader
                buttons={[
                    ExportButton.build(
                        () => onExportObjectTypes(selectedObjectTypes),
                        intl.formatMessage(messages.export),
                        !(objectTypes.length || objectTypeGroups.length),
                        saveRequired,
                    ),
                    DeleteSelected.build(
                        () => {
                            if (!selectedObjectTypeGroups.length && selectedObjectTypes.length) {
                                dispatch(
                                    deleteObjectType({
                                        objectTypes: selectedObjectTypes,
                                        serverNode,
                                    }),
                                );
                                setSelectedObjectTypes([]);
                            }
                            if (selectedObjectTypeGroups.length && !selectedObjectTypes.length) {
                                dispatch(
                                    deleteObjectTypeGroup({
                                        serverNode,
                                        objectTypeGroups: selectedObjectTypeGroups,
                                    }),
                                );
                                setSelectedObjectTypeGroups([]);
                            }
                            if (selectedObjectTypeGroups.length && selectedObjectTypeGroups.length) {
                                dispatch(
                                    deleteObjectTypeAndGroup({
                                        serverNode,
                                        objectTypes: selectedObjectTypes,
                                        objectTypeGroups: selectedObjectTypeGroups,
                                    }),
                                );
                                setSelectedObjectTypes([]);
                                setSelectedObjectTypeGroups([]);
                            }

                            setSaveRequired(true);
                        },
                        disabled || !selected,
                        undefined,
                        intl.formatMessage(messages.deleteObjectsDialogTitle),
                        <Alert
                            message={
                                <>
                                    {deleteGroupsMessage}
                                    {deleteObjectsMessage}
                                </>
                            }
                            type="warning"
                        />,
                    ),
                    MoveSelected.build(() => setSelectGroupDialogVisible(true), disabled || !selected),
                ]}
                customButtons={
                    <ButtonWithDropdown
                        title={intl.formatMessage(messages.create)}
                        itemButtons={[
                            {
                                name: messages.newObject,
                                disabled: disabled || !objectTypeGroups?.length,
                                onAction: () =>
                                    dispatch(
                                        createObjectType({
                                            serverNode,
                                            preset,
                                        }),
                                    ),
                                dataTestId: 'new-object-type-btn',
                            },
                            {
                                name: messages.newGroup,
                                disabled,
                                icon: icFolder,
                                onAction: () =>
                                    dispatch(
                                        createObjectTypeGroup({
                                            serverNode,
                                            preset,
                                        }),
                                    ),
                            },
                            ImportButton.build(onImportObjectTypes, messages.import, disabled, saveRequired, icImport),
                        ]}
                    />
                }
                onSearchChange={setSearchFilter}
            />
            <GroupedTypesTable
                types={objectTypes?.map((type) => ({
                    ...type,
                    groupId: type.objectTypeGroup?.id,
                }))}
                typeGroups={objectTypeGroups}
                searchFilter={searchFilter}
                actionsDisabled={disabled}
                icon={icObject}
                onSelectType={setSelectedObjectTypes as any}
                selectedTypes={selectedObjectTypes}
                onSelectGroup={setSelectedObjectTypeGroups as any}
                selectedTypeGroups={selectedObjectTypeGroups}
                onDeleteType={(type: ObjectType) =>
                    dispatch(
                        deleteObjectType({
                            objectTypes: [type],
                            serverNode,
                        }),
                    )
                }
                onDeleteGroup={(group: ObjectTypeGroup) =>
                    dispatch(
                        deleteObjectTypeGroup({
                            serverNode,
                            objectTypeGroups: [group],
                        }),
                    )
                }
                onEditType={(objectType: ObjectType) =>
                    dispatch(
                        editObjectType({
                            serverNode,
                            preset,
                            objectTypeId: objectType.id,
                        }),
                    )
                }
                onEditGroup={(objectTypeGroup: ObjectTypeGroup) =>
                    dispatch(
                        editObjectTypeGroup({
                            serverNode,
                            preset,
                            objectTypeGroup,
                        }),
                    )
                }
                columns={[
                    {
                        label: intl.formatMessage(messages.description),
                        dataKey: 'multilingualDescription',
                        cellRenderer: ({ rowData }) =>
                            LocalesService.internationalStringToString(rowData.multilingualDescription, currentLocale),
                        width: 200,
                    },
                ]}
                titleDeleteMessage={messages.deleteObjectsDialogTitle}
                deleteGroupMessage={messages.deleteObjectGroups}
                deleteElMessage={messages.deleteObjects}
            />
            {selectGroupDialogVisible && (
                <GroupSelectionDialog
                    groups={objectTypeGroups}
                    onSubmit={(group) => {
                        if (group) {
                            changeObjectTypeGroup(
                                selectedObjectTypes.map((et) => ({
                                    ...et,
                                    objectTypeGroup: group as ObjectTypeGroup,
                                })),
                            );
                        }
                        setSelectGroupDialogVisible(false);
                        setSaveRequired(true);
                    }}
                    onClose={() => setSelectGroupDialogVisible(false)}
                />
            )}
        </div>
    );
};
