import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { SimulationRun, SimulationSettings } from '../../../../serverapi/api';
import { SimulationResultBoard } from './ResultBoard/SimulationResultBoard.component';
import theme from './SimulationModeling.scss';
import { SettingsSidebar } from './SettingsSidebar/SettingsSidebar.component';
import { TSimulationModelingProps } from '../../SimulationModeling.types';
import SplitPane from 'react-split-pane';

const SimulationModeling = (props: TSimulationModelingProps) => {
    const [activeSimulation, setActiveSimulationRun] = useState<SimulationRun>();
    const [panelSize, setPanelSize] = useState<number>(250);

    return (
        //@ts-ignore
<SplitPane
            onChange={(size: number) => setPanelSize(size)}
            className={theme.container}
            split="vertical"
            maxSize={1000}
            minSize={200}
            size={panelSize}
            paneStyle={{ overflow: 'auto' }}
            primary="first"
            style={{ position: 'relative' }}
            pane2Style={{ borderLeft: '1px solid #e9e9e9' }}
        >
            <SettingsSidebar
                simulationSettings={props.simulationSettings}
                simulationRuns={props.simulationRuns}
                simulationNode={props.simulationNode}
                model={props.model}
                object={props.object}
                objectDiagramElement={props.objectDiagramElement}
                modelTypes={props.modelTypes}
                onActiveSimulationChange={setActiveSimulationRun}
                runSimulation={props.runSimulation}
                addNewSimulation={props.addNewSimulation}
                deleteSimulation={props.deleteSimulation}
                getReport={props.getReport}
                saveSimulation={(simulation: SimulationRun) => {
                    props.saveSimulation({
                        ...simulation
                    });
                }}
                saveSimulationSettings={(simulationSettings: SimulationSettings) =>
                    props.saveSimulationSettings(simulationSettings, props.nodeId)
                }
            />
            <SimulationResultBoard
                activeSimulation={
                    props.simulationRuns.find((s) => s.id?.id === activeSimulation?.id?.id) || activeSimulation
                }
            />
        </SplitPane>
    );
};

const IntlComponent = injectIntl(SimulationModeling);

export { IntlComponent as SimulationModeling };
