import type { TEraseDeletedNodeDialogProps } from './EraseDeletedNodeDialog.types';
import type { DeleteNodeRequest } from '@/services/bll/DeleteNodeBllService';
import { connect } from 'react-redux';
import { DeleteNodeDialog } from '../DeleteNodeDialog/DeleteNodeDialog.component';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { eraseDeletedNodeDialogSubmit } from '@/actions/eraseDeletedNodeDialog.actions';
import messages from './EraseDeletedNodeDialog.messages';

const mapStateToProps = (state, props: DeleteNodeRequest): Partial<TEraseDeletedNodeDialogProps> => {
    return {
        title: messages.title,
        contentText: messages.text,
        deleteNodeRequest: props,
    };
};

const mapDispatchToProps = (dispatch, { nodeId, closeTabsIds }: DeleteNodeRequest) => ({
    onClose: () => dispatch(closeDialog(DialogType.ERASE_DELETED_NODE_DIALOG)),
    onSubmit: () => dispatch(eraseDeletedNodeDialogSubmit(nodeId, closeTabsIds)),
});

export const EraseDeletedNodeDialogContainer = connect(mapStateToProps, mapDispatchToProps)(DeleteNodeDialog);