import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { INITIAL_OBJECT_TYPE_SERVER_STATE } from '../reducers/objectTypeGroup.reducer';
import { ObjectTypeGroup } from '../serverapi/api';

export const objectTypeGroupStateSelector = (state: TRootState) => state.objectTypeGroup;
export const deletedObjectTypeGroupStateSelector = (state: TRootState) => state.deletedObjectTypeGroup;

export namespace ObjectTypeGroupSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(objectTypeGroupStateSelector, (state) => {
            const s = state.byServerId[serverId];
            if (s) {
                return { ...s };
            }

            return { ...INITIAL_OBJECT_TYPE_SERVER_STATE };

        });

    export const byPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), (state) => state.byPresetId[compositeId.presetId]);

    export const byId = (compositeId: { objectTypeGroupId: string; presetId: string; serverId: string }) =>
        createSelector(byPresetId({ serverId: compositeId.serverId, presetId: compositeId.presetId }), (state) => {
            return state.byId[compositeId.objectTypeGroupId];
        });

    export const byPresetIdExcludeDeleted = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), deletedObjectTypeGroups(compositeId), (state, deleted) => {
            const etg = state.byPresetId[compositeId.presetId];
            const objectTypeGroups: ObjectTypeGroup[] = etg ? Object.values(etg.byId) : [];
            return objectTypeGroups.filter(e => !deleted?.some(d => d.id === e.id));
        });

    export const deletedObjectTypeGroups = (payload: { presetId: string; serverId: string }) =>
        createSelector(deletedObjectTypeGroupStateSelector, (state) => {
            return state.byServerId[payload.serverId]?.byPresetId[payload.presetId];
        });
}
