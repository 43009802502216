import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { INITIAL_MODEL_TYPE_SERVER_STATE } from '../reducers/modelType.reducer';
import { IModelTypeTypeData } from '../reducers/modelType.reducer.types';
import { EdgeType, ModelType, ObjectType } from '../serverapi/api';
import { uniqBy } from 'lodash-es';

export const EMPTY_PRESET_ID = 'empty';

const modelTypeStateSelector = (state: TRootState) => state.modelType;

const toModelTypeArray = (srcData: IModelTypeTypeData<ModelType>): ModelType[] => {
    return Object.keys(srcData.byId).map((k) => srcData.byId[k]);
};

export namespace ModelTypeSelectors {
    export const byServerIdPresetId = (serverId: string, presetId: string) =>
        createSelector(modelTypeStateSelector, (state) => {
            const serverModelTypeState = state.byServerId[serverId] && state.byServerId[serverId][presetId];
            if (serverModelTypeState) {
                return { ...serverModelTypeState };
            }

            return { ...INITIAL_MODEL_TYPE_SERVER_STATE };
        });

    export const byServerIdPresetIdArr = (serverId: string, presetId: string) =>
        createSelector(modelTypeStateSelector, (state) => {
            const serverModelTypeState = state.byServerId[serverId] && state.byServerId[serverId][presetId];
            if (serverModelTypeState) {
                return Object.values(serverModelTypeState.byId);
            }

            return [];
        });

    export const isLoad = (serverId: string, presetId: string) =>
        createSelector(
            modelTypeStateSelector,
            (state) => !!(state.byServerId[serverId] && state.byServerId[serverId][presetId]),
        );

    export const getAllModelTypes = (serverId: string, presetId: string) =>
        createSelector(byServerIdPresetId(serverId, presetId), (state) => toModelTypeArray(state));

    export const getEnabledModelTypes = (serverId: string, presetId: string) =>
        createSelector(getAllModelTypes(serverId, presetId), (state) => state.filter((type) => type.enableModel));

    export const byId = (modelType: { modelTypeId: string; serverId: string }, presetId: string) =>
        createSelector(byServerIdPresetId(modelType.serverId, presetId), (state) => {
            return state.byId[modelType.modelTypeId];
        });

    export const takeEdgeTypesFromModelTypes = (serverId: string, presetId: string) =>
        createSelector(getAllModelTypes(serverId, presetId), (modelTypes) => {
            const edgeTypes: EdgeType[] = [];
            if (modelTypes.length) {
                modelTypes.forEach((modelType) => {
                    edgeTypes.push(...modelType.edgeTypes);
                });
            }

            return uniqBy(edgeTypes, 'id');
        });

    export const takeObjectTypesFromModelTypes = (serverId: string, presetId: string) =>
        createSelector(getAllModelTypes(serverId, presetId), (modelTypes) => {
            const objectTypes: ObjectType[] = [];
            if (modelTypes.length) {
                modelTypes.forEach((modelType) => {
                    objectTypes.push(...modelType.objectTypes);
                });
            }

            return uniqBy(objectTypes, 'id');
        });
}
