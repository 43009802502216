export const calcTextWidth = (text: string, font: string = '') => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
        context.font = font;
        const { width } = context.measureText(text);

        return width;
    }

    return 0;
};
