import type { TFileUploadDialogProps, TFileUploadDialogState } from '../types/FileUploadDialog.types';
import * as React from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { injectIntl } from 'react-intl';
import messages from '../messages/FileUploadDialog.messages';
import { Upload } from 'antd';
import { Icon as UIKitIcon } from '../../UIKit/components/Icon/Icon.component';
import theme from './FileUploadDialog.scss';
import iconOk from '../../../resources/icons/Symbol_OK.svg';
import UploadSVG from '../../../resources/icons/Upload.svg';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

class FileUploadDialog<P extends TFileUploadDialogProps, S extends TFileUploadDialogState> extends React.Component<
    P,
    S
> {
    isSubmitEnabled = (): boolean => {
        return !!this.state?.file;
    };

    handleSubmit = () => {
        const { file } = this.state || {};

        if (file) {
            this.props.onSubmit(file);
        }
    };

    dropFile = (e: UploadRequestOption) => {
        const { multiple = false } = this.props;

        if (multiple) {
            this.setState((state) => {
                if (!Array.isArray(state.file)) {
                    return {
                        file: [e.file as File],
                    };
                }

                return { file: [...state.file, e.file as File] };
            });
        } else if (e.file) {
            this.setState({
                file: e.file as File,
            });
        }

        const submitBtn: HTMLButtonElement | null = document.querySelector('[data-test="dragger_submit-button"]');

        if (submitBtn?.focus) {
            submitBtn.focus();
        }
    };

    beforeUpload = () => {
        this.setState(() => ({ file: undefined }));

        return true;
    };

    renderDropper() {
        const { filters, multiple = false } = this.props;

        return (
            <div className={theme.uploadArea}>
                <Upload.Dragger
                    name="file"
                    multiple={multiple}
                    showUploadList={false}
                    customRequest={this.dropFile}
                    accept={filters || ''}
                    className={theme.dragger}
                    beforeUpload={this.beforeUpload}
                >
                    {this.state?.file ? (
                        <UIKitIcon className={theme.uploadedSvgIcon} spriteSymbol={iconOk} />
                    ) : (
                        <div className={theme.uploadAreaTextWrapper}>
                            <div className={theme.uploadAreaTitle}>
                                <span className={theme.uploadAreaTitleIcon}>
                                    <UIKitIcon className={theme.uploadSvg} spriteSymbol={UploadSVG} />
                                </span>
                                <span className={theme.uploadAreaTitleText}>
                                    {this.props.intl.formatMessage(messages.dialogUploadAreaMessage)}
                                </span>
                            </div>
                        </div>
                    )}
                </Upload.Dragger>
            </div>
        );
    }

    renderFooter() {
        const { intl, onClose } = this.props;

        return (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: onClose,
                        value: intl.formatMessage(messages.cancelButton),
                        dataTest: 'dragger_cancel-button',
                    },
                    {
                        key: 'ok',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.okButton),
                        visualStyle: 'primary',
                        dataTest: 'dragger_submit-button',
                        disabled: !this.isSubmitEnabled(),
                    },
                ]}
            />
        );
    }

    render() {
        const { open, intl, onClose } = this.props;

        return (
            <Dialog
                className={theme.dialog}
                onCancel={onClose}
                open={open}
                title={intl.formatMessage(messages.dialogTitle)}
                footer={this.renderFooter()}
            >
                {this.renderDropper()}
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(FileUploadDialog);

export { FileUploadDialog as default, IntlComponent as FileUploadDialog };
