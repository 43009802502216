import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolManager.class';
import { BPMMxConstraintHandler } from '../handler/BPMMxConstraintHandler.class';
import { MxRectangle, MxCellState, MxMouseEvent } from '../mxgraph';

/**
 *Обрабатывает ограничения на целевые объекты подключения. Этот класс отвечает
 *за отображение фиксированных точек при наведении курсора мыши на вершину и обрабатывает ограничения
 *для установления новых подключений.
 */
export class SequenceConstraintHandler extends BPMMxConstraintHandler {
    /**
     * Переводит фокус в заданное состояние в качестве исходного или целевого терминала. Если
     * обработчик не включен, то контур закрашивается.
     * @param { MxMouseEvent } me - информация о событии
     * @param { MxCellState | null } state - текущяя ячейка на графе
     */
    setFocus(me: MxMouseEvent, state: MxCellState | null) {
        // Only uses cells which have constraints
        if (state) {
            const parentFrame = ComplexSymbolManager.getComplexSymbolRootCell(state.cell);
            if (parentFrame) {
                const cellState = this.graph.view.getState(parentFrame);
                this.currentFocus = cellState;
                this.currentFocusArea = new MxRectangle(cellState.x, cellState.y, cellState.width, cellState.height);

                if (this.focusIcons != null) {
                    for (let i = 0; i < this.focusIcons.length; i++) {
                        this.focusIcons[i].destroy();
                    }

                    this.focusIcons = null;
                    this.focusPoints = null;
                }

                this.focusPoints = [];
                this.focusIcons = [];

                this.currentFocusArea.grow(this.getTolerance(me));
            }
        } else {
            this.destroyIcons();
            this.destroyFocusHighlight();
            this.destroyFocusTargetHighlight();
        }
    }
}
/* tslint:enable:no-any */
