import type { TDeleteNodeDialogProps } from './DeleteNodeDialog.types';
import type { DeleteNodeRequest } from '../../../services/bll/DeleteNodeBllService';
import { connect } from 'react-redux';
import { DeleteNodeDialog } from './DeleteNodeDialog.component';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { treeItemDeleteNodeFromServer } from '../../../actions/tree.actions';
import messages from './DeleteNodeDialog.messages';

const mapStateToProps = (state, props: DeleteNodeRequest): Partial<TDeleteNodeDialogProps> => {
    return {
        title: messages.title,
        contentText: messages.text,
        deleteNodeRequest: props,
    };
};

const mapDispatchToProps = (dispatch, props: DeleteNodeRequest) => ({
    onClose: () => dispatch(closeDialog(DialogType.DELETE_NODE_DIALOG)),
    onSubmit: () => {
        dispatch(treeItemDeleteNodeFromServer(props));
        dispatch(closeDialog(DialogType.DELETE_NODE_DIALOG));
    },
});

export const DeleteNodeDialogContainer = connect(mapStateToProps, mapDispatchToProps)(DeleteNodeDialog);
