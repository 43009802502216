import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { INITIAL_ATTRIBUTE_TYPE_SERVER_STATE } from '../reducers/attributeTypeGroup.reducer';

export const attributeTypeGroupStateSelector = (state: TRootState) => state.attributeTypeGroup;

export namespace AttributeTypeGroupSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(attributeTypeGroupStateSelector, (state) => {
            const s = state.byServerId[serverId];
            if (s) {
                return { ...s };
            }

            return { ...INITIAL_ATTRIBUTE_TYPE_SERVER_STATE };
        });

    export const byPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), (state) => {
            const attributeTypeGroupsById = state.byPresetId[compositeId.presetId];

            return (attributeTypeGroupsById && Object.values(attributeTypeGroupsById.byId)) || [];
        });
}
