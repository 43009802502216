import { NAVIGATOR_DESELECT_TAB, NAVIGATOR_SELECT_TAB } from '../actionsTypes/navigator.actionTypes';
import { TNavigatorTab } from '../reducers/navigator.reducer.types';
import { TNavigatorTabDeselectAction, TNavigatorTabSelectAction } from './navigator.actions.types';

export const navigatorTabDeselect = (tab: TNavigatorTab): TNavigatorTabDeselectAction => ({
    type: NAVIGATOR_DESELECT_TAB,
    payload: {
        tab,
    },
});

export const navigatorTabSelect = (tab: TNavigatorTab): TNavigatorTabSelectAction => ({
    type: NAVIGATOR_SELECT_TAB,
    payload: {
        tab,
    },
});
