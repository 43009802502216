import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import theme from './GeneralSettingsTab.scss';
import messages from '../../../messages/Presets.messages';
import { TPreset } from '../../../../../../models/preset.types';
import { MultiLangInputDialog } from '../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { InternationalString } from '../../../../../../serverapi/api';
import { Form, FormInstance } from 'antd';

type TGeneralSettingsProps = {
    preset: TPreset;
    disabled: boolean;
    generalFormRef: React.RefObject<FormInstance>;
    onChangeName: (multilingualName: InternationalString) => void;
    onChangeDescription: (multilingualName: InternationalString) => void;
};

const GeneralSettingsTab: FC<TGeneralSettingsProps> = (props) => {
    const { preset, disabled, generalFormRef, onChangeName, onChangeDescription } = props;
    const intl = useIntl();

    return (
        <Form ref={generalFormRef} layout="vertical" className={theme.form}>
            <MultiLangInputDialog
                containerDataTest="container_title_methodology"
                placeholder={intl.formatMessage(messages.newPreset)}
                multiLangValue={preset?.multilingualName}
                disabled={disabled}
                maxLength={150}
                onChange={onChangeName}
                mainInputName="multilingualName"
                required
                generalForm={generalFormRef.current}
                label={intl.formatMessage(messages.name)}
                data-test="methodology-name-input"
                mainInputClassName={theme.input}
            />
            <div className={theme.descriptionWrapper}>
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.description)}
                    multiLangValue={preset?.multilingualDescription}
                    disabled={disabled}
                    onChange={onChangeDescription}
                    required={false}
                    textarea
                    mainInputName="multilingualDescription"
                    generalForm={generalFormRef.current}
                    label={intl.formatMessage(messages.description)}
                    containerDataTest="container_description_methodology"
                    data-test="methodology-description-input"
                    mainInputClassName={theme.textArea}
                />
            </div>
        </Form>
    );
};

export { GeneralSettingsTab };
