import React from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './changePasswordDialog.messages';
import theme from './changePasswordDialog.scss';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { PasswordInput } from '../../UIKit/components/PasswordInput/PasswordInput.component';
import { changeUserPassword } from '../../../actions/userAccount.actions';
import { ChangePasswordFormItemNames } from './changePasswordDialog.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export const ChangePasswordDialog = () => {
    const intl = useIntl();
    const [generalForm] = Form.useForm();
    const dispatch = useDispatch();

    const onCancel = () => dispatch(closeDialog(DialogType.CHANGE_PASSWORD_DIALOG));

    const onSubmit = () =>
        generalForm
            .validateFields()
            .then((fields) =>
                dispatch(
                    changeUserPassword(
                        fields[ChangePasswordFormItemNames.oldPassword],
                        fields[ChangePasswordFormItemNames.newPassword],
                    ),
                ),
            )
            .catch(() => undefined);

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.changePassword),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            title={intl.formatMessage(messages.changePasswordTitle)}
            className={theme.dialog}
            onCancel={onCancel}
            open
            footer={footer}
        >
            <Form
                form={generalForm}
                layout="vertical"
                className={theme.form}
                onValuesChange={(changedValues) => {
                    if (Object.keys(changedValues)[0] === ChangePasswordFormItemNames.newPassword) {
                        const hasError: boolean =
                            generalForm.getFieldError(ChangePasswordFormItemNames.confirmNewPassword).length !== 0;
                        if (hasError) generalForm.validateFields([ChangePasswordFormItemNames.confirmNewPassword]);
                    }
                }}
            >
                <div className={theme.formItemName}>
                    <PasswordInput
                        formItemName={ChangePasswordFormItemNames.oldPassword}
                        label={intl.formatMessage(messages.oldPassword)}
                    />
                </div>
                <div className={theme.formItemName}>
                    <PasswordInput
                        formItemName={ChangePasswordFormItemNames.newPassword}
                        label={intl.formatMessage(messages.newPassword)}
                    />
                </div>
                <div className={theme.formItemName}>
                    <PasswordInput
                        formItemName={ChangePasswordFormItemNames.confirmNewPassword}
                        label={intl.formatMessage(messages.confirmNewPassword)}
                        rules={[
                            {
                                message: intl.formatMessage(messages.differentPasswords),
                                validator: (_, value: string | null) => {
                                    if (
                                        !value ||
                                        generalForm.getFieldValue(ChangePasswordFormItemNames.newPassword) === value
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject();
                                },
                            },
                        ]}
                    />
                </div>
            </Form>
        </Dialog>
    );
};
