import { VisioEdgeTypeMap } from '../../serverapi/api';

interface IWithSimbolId extends VisioEdgeTypeMap {
    silaSymbolId?: string;
}

export class EdgeMappersAdapter {
    visioMasterId?: string;
    visioMasterName?: string;
    visioShapes?: string[];
    silaTypeId?: string;
    silaSymbolId?: string;
    offeredSilaTypeId?: string;

    constructor(item: IWithSimbolId) {
        this.visioMasterId = item.visioMasterId;
        this.visioMasterName = item.visioMasterName;
        this.visioShapes = item.visioShapes;
        this.silaTypeId = item.silaTypeId;
        this.offeredSilaTypeId = item.offeredSilaTypeId;
    }
}
