import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { getDatabaseDialogState } from '../../../../selectors/databaseDialog.selectors';
import { importDialogCancel, importDialogReplaceConfirm } from '../../../../actions/importDialog.actions';
import { TImportRepositoryReplaceDialogProps } from '../../types/ImportDialog.types';
import { ImportRepositoryReplaceDialog } from '../../components/ImportRepositoryReplaceDialog/ImportRepositoryReplaceDialog.component';

const mapStateToProps = (state: TRootState, props: TImportRepositoryReplaceDialogProps) => {
    return {
        error: getDatabaseDialogState(state),
        path: props.path,
        itemId: props.itemId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => dispatch(importDialogReplaceConfirm()),
    onCancel: () => dispatch(importDialogCancel()),
});

export const ImportRepositoryReplaceDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImportRepositoryReplaceDialog);
