import { Dropdown, MenuProps } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { useCallback, useState } from 'react';
import { getMenuItem } from '../../../../../utils/antdMenuItem.utils';
import messages from '../../../messages/CommentsPanel.messages';
import { getAntdPopupContainer } from '../../../../../utils/getAntdPopupContainer';
import { NavigatorPanelButton } from '../../../../Navigator/components/NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { EditCommentTooltipIcon } from '../../icons/EditCommentTooltipIcon.component';
import { LocalesService } from '../../../../../services/LocalesService';
import { deleteCommentMarker } from '../../../../../actions/comments.actions';
import { NodeId } from '../../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { CommentsSelectors } from '../../../../../selectors/comments.selectors';

enum MenuActions {
    editComment = 'EDIT_COMMENT',
    deleteCommentFromCanvas = 'DELETE_COMMENT_FROM_CANVAS',
}

type TEditAndClearFromCanvasMenuDropDown = {
    commentId: string;
    modelId: NodeId;
    closeTooltip: () => void;
    onChangeEditMode: (mode: boolean) => void;
};

export const EditAndClearFromCanvasMenuDropDown = (props: TEditAndClearFromCanvasMenuDropDown) => {
    const { commentId, modelId, closeTooltip, onChangeEditMode } = props;
    const intl = LocalesService.useIntl();
    const dispatch = useDispatch();
    const isAuthor: boolean = useSelector(CommentsSelectors.isAuthor(modelId, commentId));
    const isEditingComment: boolean = !!useSelector(CommentsSelectors.getEditingComment(modelId));
    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const onClick = (param: MenuInfo) => {
        switch (param.key) {
            case MenuActions.editComment:
                onChangeEditMode(true);
                break;
            case MenuActions.deleteCommentFromCanvas:
                dispatch(deleteCommentMarker({ ...modelId, id: commentId }));
                closeTooltip();
                break;

            default:
                break;
        }
    };

    const items: MenuProps['items'] = [
        getMenuItem(
            <div>{intl.formatMessage(messages.editComment)}</div>,
            MenuActions.editComment,
            !isAuthor || isEditingComment,
        ),
        getMenuItem(
            <div>{intl.formatMessage(messages.deleteCommentFromCanvas)}</div>,
            MenuActions.deleteCommentFromCanvas,
            !isAuthor,
        ),
    ];

    return (
        <Dropdown
            onOpenChange={toggleOpen}
            open={open}
            menu={{ onClick, items }}
            trigger={['click']}
            getPopupContainer={getAntdPopupContainer}
            destroyPopupOnHide
            disabled={false}
        >
            <NavigatorPanelButton IconComponent={EditCommentTooltipIcon} active={open} dataTest="tooltip-comment-options"/>
        </Dropdown>
    );
};
