import React, { FC } from 'react';
import { GroupDTO } from '../../../../serverapi/api';
import { Form, Input, FormInstance } from 'antd';
import messages from '../../messages/groups.messages';
import { useIntl } from 'react-intl';
import formMessages from '../../../../models/formDefault.messages';
import theme from './GroupMainData.scss';
import { separator } from '../../../../utils/convertStringToArray';
import { GroupMainDataFormItemNames } from '../../GroupDataEditingTab/GroupDataEditingTab.types';

type TGroupMainDataProps = {
    groupData: GroupDTO;
    formRef: React.RefObject<FormInstance>;
};

const GroupMainData: FC<TGroupMainDataProps> = (props) => {
    const { groupData, formRef } = props;
    const intl = useIntl();

    return (
        <div>
            <Form autoComplete='off' ref={formRef}>
                <Form.Item label={intl.formatMessage(messages.groupId)} initialValue={groupData.id || null} name="id">
                    <Input placeholder={intl.formatMessage(messages.groupId)} disabled />
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage(messages.groupName)}
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: intl.formatMessage(formMessages.fieldRequiredError),
                        },
                    ]}
                    initialValue={groupData.groupName || null}
                    name={GroupMainDataFormItemNames.groupName}
                >
                    <Input placeholder={intl.formatMessage(messages.groupName)}  />
                </Form.Item>
                <div className={theme.container}>
                    <Form.Item
                        label={intl.formatMessage(messages.description)}
                        initialValue={groupData.description || null}
                        name={GroupMainDataFormItemNames.description}
                    >
                        <Input.TextArea
                            placeholder={intl.formatMessage(messages.description)}
                            autoSize={{ minRows: 1, maxRows: 20 }}
                        />
                    </Form.Item>
                </div>

                <div className={theme.container}>
                    <Form.Item
                        label={intl.formatMessage(messages.groupsSynonyms)}
                        initialValue={groupData.synonyms?.join(`${separator} `) || null}
                        name={GroupMainDataFormItemNames.synonyms}
                    >
                        <Input.TextArea
                            placeholder={intl.formatMessage(messages.groupsSynonymsDescription)}
                            autoSize={{ minRows: 5, maxRows: 20 }}
                        />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default GroupMainData;
