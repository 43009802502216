import React from 'react';
import { Dropdown, Menu } from 'antd';
import theme from './ImageRenderer.component.scss';
import { useIntl } from 'react-intl';
import messages from './ImageRenderer.messages';
import { deleteAtomicBlock } from '../../../common/contentBlocks.utils';

const WikiImageContextMenuComponent = ({ items, getEditorState, changeEditorState, block }) => {
    const renderContextMenu = () => {
        const intl = useIntl();
        const { formatMessage } = intl;

        const menuClickHandler = ({ key }) => {
            const editorState = getEditorState();
            if (key === 'delete') {
                changeEditorState(deleteAtomicBlock(editorState, block));
            }
        };

        return (
            <>
                <Menu onClick={menuClickHandler}>
                    <Menu.Item key="delete">{formatMessage(messages.deleteImage)}</Menu.Item>
                </Menu>
            </>
        );
    };

    return (
        <Dropdown destroyPopupOnHide trigger={['contextMenu']} overlay={renderContextMenu()}>
            <div onContextMenu={(e) => e.stopPropagation()} className={theme.viewerContainer}>
                {items}
            </div>
        </Dropdown>
    );
};

export default WikiImageContextMenuComponent;
