import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';

export const principalServerProfileState = (state: TRootState) => state.principalServerProfile;

export namespace PrincipalServerProfileSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(principalServerProfileState, (state) => state.byServerId[serverId]);

    export const getOwners = (serverId: string) => {
        return createSelector(byServerId(serverId), (state) => (state && state.owners ? state.owners : undefined));
    };

    export const getOwnersByProfileId = (serverId: string, profileId: string) => {
        return createSelector(byServerId(serverId), (state) => (state && state.owners ? state.owners[profileId] : undefined));
    };
}
