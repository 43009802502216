function getStyleVal(elm, css) {
    return window.getComputedStyle(elm, null).getPropertyValue(css);
}

function getVPaddingDiff(col) {
    if (getStyleVal(col, 'box-sizing') === 'border-box') {
        return 0;
    }

    const padLeft = getStyleVal(col, 'padding-left');
    const padRight = getStyleVal(col, 'padding-right');

    return parseInt(padLeft, 10) + parseInt(padRight, 10);
}

function getHPaddingDiff(col) {
    if (getStyleVal(col, 'box-sizing') === 'border-box') {
        return 0;
    }

    const padLeft = getStyleVal(col, 'padding-top');
    const padRight = getStyleVal(col, 'padding-bottom');

    return parseInt(padLeft, 10) + parseInt(padRight, 10);
}

function setColsWidth(table, curCol, width = '') {
    const tdsLine = curCol.parentElement.children;
    const colIndex = Array.prototype.indexOf.call(tdsLine, curCol);
    table.querySelectorAll('tr').forEach((tr) => {
        const cell = tr.children[colIndex];
        if (!cell?.style) {
            return;
        }

        cell.style.width = width;
    });
}

export function addVerticalResizeListeners(table, callback) {
    let pageX;
    let curCol;
    let curColWidth;
    let isDown = false;
    const mouseDownHanler = (e) => {
        curCol = e.target.parentElement;
        pageX = e.pageX;

        const padding = getVPaddingDiff(curCol);

        curColWidth = curCol.offsetWidth - padding;

        setColsWidth(table, curCol);
        curCol.style.width = `${curColWidth}px`;
        isDown = true;
    };
    const mouseMoveHandler = (e) => {
        if (!curCol) {
            return;
        }
        const diffX = e.pageX - pageX;

        curCol.style.width = `${curColWidth + diffX}px`;
    };
    const mouseUpHandler = (e) => {
        if (!isDown) {
            curCol = undefined;
            pageX = undefined;
            curColWidth = undefined;

            return;
        }

        if (callback) {
            const resultSize: string[] = [];

            setColsWidth(table, curCol, curCol.style.width);
            table.querySelectorAll('td').forEach((cell) => {
                const { width } = cell.style;

                resultSize.push(width);
            });
            callback(resultSize);
        }
        isDown = false;
    };
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);

    const sizers = table.querySelectorAll('.js-vsizer');
    sizers.forEach((sizer) => {
        sizer.addEventListener('mousedown', mouseDownHanler);
        sizer.addEventListener('mouseup', mouseUpHandler);
    });

    return () => {
        isDown = false;
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
        sizers.forEach((sizer) => {
            sizer.removeEventListener('mousedown', mouseDownHanler);
            sizer.removeEventListener('mouseup', mouseUpHandler);
        });
    };
}

export function addHorizontalResizeListeners(table, callback) {
    let pageY;
    let curCol;
    let curColHeight;
    let isDown = false;
    const mouseDownHanler = (e) => {
        curCol = e.target.parentElement;
        pageY = e.pageY;

        const padding = getHPaddingDiff(curCol);

        curColHeight = curCol.offsetHeight - padding;
        const tds = curCol.parentElement.children;
        for (let cell of tds) {
            cell.style.height = ``;
        }

        curCol.style.height = `${curColHeight}px`;
        isDown = true;
    };
    const mouseMoveHandler = (e) => {
        if (!curCol) {
            return;
        }
        const diffY = e.pageY - pageY;

        curCol.style.height = `${curColHeight + diffY}px`;
    };
    const mouseUpHandler = (e) => {
        if (!isDown) {
            curCol = undefined;
            pageY = undefined;
            curColHeight = undefined;

            return;
        }

        if (callback) {
            const resultSize: string[] = [];
            table.querySelectorAll('td').forEach((cell) => {
                const { height } = cell.style;
                resultSize.push(height);
            });
            callback(resultSize);
        }
        isDown = false;
    };
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);

    const sizers = table.querySelectorAll('.js-hsizer');
    sizers.forEach((sizer) => {
        sizer.addEventListener('mousedown', mouseDownHanler);
        sizer.addEventListener('mouseup', mouseUpHandler);
    });

    return () => {
        isDown = false;
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
        sizers.forEach((sizer) => {
            sizer.removeEventListener('mousedown', mouseDownHanler);
            sizer.removeEventListener('mouseup', mouseUpHandler);
        });
    };
}
