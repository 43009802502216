import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../messages/ChoosePrincipalDialog.messages';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import { ChoosePrincipalsPanel } from '../ChoosePrincipalsPanel/ChoosePrincipalsPanel.component';
import { TChoosePrincipalDialogProps } from './ChoosePrincipalDialog.types';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TChoosePrincipalDialogPropsAllProps = TChoosePrincipalDialogProps;

const hidePrincipals = (data: PrincipalDescriptor[], hiddenRowKeys?: string[]): PrincipalDescriptor[] => {
    if (!!hiddenRowKeys && hiddenRowKeys.length > 0) {
        const byId = hiddenRowKeys.reduce((acc, key) => ({ ...acc, [`${key}`]: true }), {});

        return data.filter((p) => !byId[`${p.id}`]);
    }

    return data;
};

export const ChoosePrincipalDialog: FC<TChoosePrincipalDialogPropsAllProps> = (props) => {
    const { data, hiddenRowKeys, error, open, loading, onClose, onSubmit } = props;
    const intl = useIntl();
    const [selectedPrincipals, setSelectedPrincipals] = useState<PrincipalDescriptor[]>([]);

    const hiddenPrincipals = hidePrincipals(data, hiddenRowKeys);

    const handleSubmit = () => {
        onSubmit(selectedPrincipals);
    };

    const handleSelectionChanged = (rows: PrincipalDescriptor[]) => {
        setSelectedPrincipals(rows);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancelButton),
                    dataTest: 'permission-dialog_principal_cancel-button',
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.addButton),
                    visualStyle: 'primary',
                    dataTest: 'permission-dialog_principal_confirm-button',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.title)}
            open={open}
            width={480}
            footer={footer}
        >
            <ChoosePrincipalsPanel
                loading={loading}
                error={error}
                principals={hiddenPrincipals}
                selectedPrincipals={selectedPrincipals}
                onSelectionChanged={handleSelectionChanged}
            />
        </Dialog>
    );
};
