import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';

const getState = (state: TRootState) => state.generalMenu;

export const getGeneralMenuButtonsState = createSelector(getState, (state) => state.buttonEditLabelState);

export const getGeneralMenuEdgeTypeSelectorState = createSelector(getState, (state) => state.edgeTypeSelectorState);

export const getGeneralAvailableEdgeTypeSelector = createSelector(
    getState,
    (state) => state.edgeTypeSelectorState.availableTypes,
);

export const isCompact = createSelector(getState, (state) => state.isCompact);
