import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Tree, TreeDataNode, Input, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { clone } from 'lodash-es';
import {
    scriptContextTreeDataKeys,
    scriptContextTreeDataSelectors,
} from '../../../../selectors/scriptContext.selectors';
import { PresetSelectors } from '../../../../selectors/preset.selectors';
import {
    getAllItemsKeysList,
    prepareToSaveKeys,
    getChildren,
} from '../../../../utils/scriptContext.utils';
import { ServerSelectors } from '../../../../selectors/entities/server.selectors';
import { useCheckedItems } from '../../../../hooks/scriptContext.hook';
import theme from './ItemsTreeComponent.scss';
import { getAmountOfCheckedItemsInPreset } from '../../../../../src/services/bll/scriptContextBLLService';
import messages from '../../messages/ScriptContext.messages';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { CTreeNodeKey, scriptContextTypes } from './scriptContext.types';
import { AllowedScriptContext, AllowedScriptModelSymbols, NodeId } from '../../../../serverapi/api';

type TItemsTree = {
    scriptContext: AllowedScriptContext;
    contextType: scriptContextTypes;
    scriptId: NodeId;
    setItemsToPermit: (items: string[] | AllowedScriptModelSymbols[] | undefined) => void;
};

export const ItemsTree = (props: TItemsTree) => {
    const intl = useIntl();
    const { scriptContext, setItemsToPermit, contextType, scriptId } = props;
    const serverId = useSelector(ServerSelectors.serverId);
    const presets = useSelector(PresetSelectors.presetArrByServerId(serverId));
    const { checkedItems, setCheckedItemsWithDublicates, setUncheckedItemsWithDublicates } = useCheckedItems();
    const treeData = useSelector(scriptContextTreeDataSelectors.getTreeDataByContextType(contextType, presets));
    const [filteredTreeData, setFilteredtreeData] = useState<TreeDataNode[]>(treeData);
    const AllItemsKeysList = getAllItemsKeysList(treeData);
    const defaultCheckedKeys: string[] = useSelector(
        scriptContextTreeDataKeys.getDefaultCheckedKeys(contextType, AllItemsKeysList, scriptId),
    );
    const [expandedKeys, setexpandedKeys] = useState<string[]>([]);
    useEffect(() => {
        prepareToSaveKeys(treeData, checkedItems, defaultCheckedKeys, contextType, setItemsToPermit);
    }, [checkedItems]);

    const handleCheck = (keys, e: any) => {
        if (e.checked) {
            setCheckedItemsWithDublicates(AllItemsKeysList, e, defaultCheckedKeys, contextType);
        }
        if (!e.checked) {
            setUncheckedItemsWithDublicates(defaultCheckedKeys, e, contextType);
        }
    };

    const columns = [
        {
            title: intl.formatMessage(messages.name),
            dataIndex: 'name',
            key: 'name',
            width: 440,
            className: theme.nameColumn,
        },
        {
            title: intl.formatMessage(messages.amount),
            dataIndex: 'amount',
            key: 'amount',
            className: theme.amountColumn,
        },
    ];

    const onExpandHandker = (keys, e) => {
        if (e.expanded) {
            setexpandedKeys([...expandedKeys, e.node.key]);

            return;
        }
        setexpandedKeys(expandedKeys.filter((key) => key !== e.node.key));
    };

    const dataSource = filteredTreeData.map((tree) => {
        const allItems: TreeDataNode[] = getChildren([tree]);
        // пустые папки тоже являются листьями, но у них нет поля Leaf, их откидываем
        const itemsWithLeaf = allItems.filter((item) => item.isLeaf);
        const amountOfCheckItems: number = getAmountOfCheckedItemsInPreset(
            itemsWithLeaf,
            checkedItems,
            scriptContext,
            contextType,
        );
        const amountOfAllItems: number = itemsWithLeaf.filter((leaf) => leaf.isLeaf).length;
        const checkedKeys =
            checkedItems?.filter(
                (item) =>
                    CTreeNodeKey.keyToObject(item).getPresetId() ===
                    CTreeNodeKey.keyToObject(tree.key as string).getPresetId(),
            ) ||
            defaultCheckedKeys.filter(
                (item) =>
                    CTreeNodeKey.keyToObject(item).getPresetId() ===
                    CTreeNodeKey.keyToObject(tree.key as string).getPresetId(),
            );

        return {
            name: (
                <Tree
                    checkable
                    treeData={[tree]}
                    switcherIcon={<DownOutlined />}
                    blockNode
                    onCheck={(keys, e) => handleCheck(keys, e)}
                    checkedKeys={checkedKeys}
                    onExpand={onExpandHandker}
                    expandedKeys={expandedKeys}
                    key={tree.key}
                />
            ),
            amount: (
                <div className={theme.amount} key={uuid()}>
                    {intl.formatMessage(messages.amountOfCheckItems, {
                        checkItems: amountOfCheckItems,
                        allItems: amountOfAllItems,
                    })}
                </div>
            ),
        };
    });

    const filterTreeData = (treeDataClone: TreeDataNode[], value: string): TreeDataNode[] => {
        return treeDataClone.filter(node => {
            const title = node.title?.toString().toLowerCase();
            const isMatch = title && title.includes(value.toLowerCase().trim());

            if (isMatch) {
                return true;
            }
    
            if (node.children) {
                node.children = filterTreeData(node.children, value);
                if (node.children.length > 0) {
                    return true;
                }
            }
    
            return false;
        });
    };
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const treeDataClone = clone(treeData);
        const filteredData: TreeDataNode[] = filterTreeData(treeDataClone, value);
        setFilteredtreeData(filteredData);
    };

    return (
        <div className={theme.container}>
            <div className={theme.searchInput}>
                <Input
                    data-test="context-editor-window_search_input"
                    prefix={<SearchOutlined />}
                    onChange={handleChange}
                />
            </div>
            <Table
                rowKey={uuid()}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                scroll={{ y: '' }}
                className={theme.table}
            />
        </div>
    );
};
