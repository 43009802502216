import { TNotificationEntity } from '../models/notification.types';
import { v4 as uuid } from 'uuid';
import { NotificationType } from '../models/notificationType';
import { NOTIFICATION_CLOSE, NOTIFICATION_SHOW } from '../actionsTypes/notification.actionTypes';
import { TNotificationShowAction, TNotificationCloseAction } from './notification.actions.types';

export const showNotification = (notification: TNotificationEntity): TNotificationShowAction => ({
    type: NOTIFICATION_SHOW,
    payload: {
        notification,
    },
});

export const showNotificationByType = (type: NotificationType): TNotificationShowAction => ({
    type: NOTIFICATION_SHOW,
    payload: {
        notification: { id: uuid(), type },
    },
});

export const closeNotification = (id: string): TNotificationCloseAction => ({
    type: NOTIFICATION_CLOSE,
    payload: {
        id,
    },
});
