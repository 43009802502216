// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportRepositoryReplaceDialog__itemType__qgszz .ant-form-item-label label{white-space:normal}.ImportRepositoryReplaceDialog__conflictResolutionStrategyBlock__DKM7N .ant-input-lg{width:10%}.ImportRepositoryReplaceDialog__conflictResolutionStrategyBlock__DKM7N .ant-input-suffix{position:static;position:initial}", "",{"version":3,"sources":["webpack://./src/modules/ImportDialog/components/ImportRepositoryReplaceDialog/ImportRepositoryReplaceDialog.scss"],"names":[],"mappings":"AAEQ,2EACI,kBAAA,CAOJ,qFACI,SAAA,CAEJ,yFACI,eAAA,CAAA,gBAAA","sourcesContent":[".itemType {\r\n    :global {\r\n        .ant-form-item-label label {\r\n            white-space: normal;\r\n        }\r\n    }\r\n}\r\n\r\n.conflictResolutionStrategyBlock {\r\n    :global {\r\n        .ant-input-lg {\r\n            width: 10%;\r\n        }\r\n        .ant-input-suffix {\r\n            position: initial;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemType": "ImportRepositoryReplaceDialog__itemType__qgszz",
	"conflictResolutionStrategyBlock": "ImportRepositoryReplaceDialog__conflictResolutionStrategyBlock__DKM7N"
};
export default ___CSS_LOADER_EXPORT___;
