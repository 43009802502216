import { TReducer } from '../../utils/types';
import { TDashboardState } from './dashboard.reducer.types';
import {
    DASHBOARD_ADD_NEW_DATASET,
    DASHBOARD_ADD_WIDGET,
    DASHBOARD_CHANGE_CELL_SIZE,
    DASHBOARD_CHANGE_WIDGET_GEOMETRY,
    DASHBOARD_DELETE_DATASET,
    DASHBOARD_DELETE_WIDGET,
    DASHBOARD_SELECT_WIDGET,
    DASHBOARD_SET_CELLS_COUNT,
    DASHBOARD_SET_WIDGET_BG,
    DASHBOARD_SET_WIDGET_BORDER,
    DASHBOARD_SET_WIDGET_NAME,
    DASHBOARD_SET_WIDGET_SOURCE,
    DASHBOARD_SET_WIDGET_TEXT,
} from '../../actionsTypes/dashboard.actionTypes';
import { TWidget, TWidgetDataset } from '@/modules/Dashboard/Dashboard.types';

const INITIAL_STATE = {};

export const dashboardReducer: TReducer<TDashboardState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_ADD_WIDGET: {
            const {
                payload: { widget, nodeId },
            } = action;
            const { id } = widget;
            const widgets = state[nodeId.repositoryId]?.[nodeId.id]?.widgets || [];
            const flag = widgets.some((widget) => widget.id === id);
            if (!flag) widgets.push(widget);

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets: [...widgets],
                    },
                },
            };
        }

        case DASHBOARD_DELETE_WIDGET: {
            const {
                payload: { widgetId, nodeId },
            } = action;

            const widgets = (state[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).filter(
                (widget) => widget.id !== widgetId,
            );

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets,
                    },
                },
            };
        }

        case DASHBOARD_CHANGE_CELL_SIZE: {
            const {
                payload: { size, nodeId },
            } = action;

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        cellSize: size,
                    },
                },
            };
        }

        case DASHBOARD_SET_CELLS_COUNT: {
            const {
                payload: { verticalCount, horizontalCount, nodeId },
            } = action;

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        verticalCount,
                        horizontalCount,
                    },
                },
            };
        }

        case DASHBOARD_CHANGE_WIDGET_GEOMETRY: {
            const {
                payload: { nodeId, widgetId, geometry },
            } = action;

            const widgets = (state[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, geometry };
                }

                return widget;
            });

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets,
                    },
                },
            };
        }

        case DASHBOARD_SELECT_WIDGET: {
            const {
                payload: { nodeId, widgetId },
            } = action;

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        selectedWidgetId: widgetId,
                    },
                },
            };
        }

        case DASHBOARD_SET_WIDGET_NAME: {
            const {
                payload: { nodeId, widgetId, name },
            } = action;

            const widgets = (state[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, name };
                }

                return widget;
            });

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets,
                    },
                },
            };
        }

        case DASHBOARD_SET_WIDGET_BG: {
            const {
                payload: { nodeId, widgetId, bgColor },
            } = action;

            const widgets = (state[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, bgColor };
                }

                return widget;
            });

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets,
                    },
                },
            };
        }

        case DASHBOARD_SET_WIDGET_BORDER: {
            const {
                payload: { nodeId, widgetId, border },
            } = action;

            const widgets = (state[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, border };
                }

                return widget;
            });

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets,
                    },
                },
            };
        }

        case DASHBOARD_SET_WIDGET_TEXT: {
            const {
                payload: { nodeId, widgetId, text },
            } = action;

            const widgets = (state[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, text };
                }

                return widget;
            });

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets,
                    },
                },
            };
        }

        case DASHBOARD_SET_WIDGET_SOURCE: {
            const {
                payload: { nodeId, widgetId, source },
            } = action;

            const widgets = (state[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, source };
                }

                return widget;
            });

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets,
                    },
                },
            };
        }

        case DASHBOARD_ADD_NEW_DATASET: {
            const {
                payload: { nodeId, widgetId },
            } = action;
            const initDatasete: TWidgetDataset = { col1: '', col2: '', legend: '' };
            const widgets = (state[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    const dataset = widget?.source?.dataset?.length ? widget?.source?.dataset : [initDatasete];
                    dataset.push(initDatasete);

                    return { ...widget, source: { ...widget?.source, dataset } } as TWidget;
                }

                return widget;
            });

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets,
                    },
                },
            };
        }

        case DASHBOARD_DELETE_DATASET: {
            const {
                payload: { nodeId, widgetId, index },
            } = action;
            const initDatasete: TWidgetDataset = { col1: '', col2: '', legend: '' };
            const widgets = (state[nodeId.repositoryId]?.[nodeId.id]?.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    const dataset = widget?.source?.dataset || [initDatasete];
                    if (dataset[index]) dataset.splice(index, 1);

                    return { ...widget, source: { ...widget?.source, dataset } } as TWidget;
                }

                return widget;
            });

            return {
                ...state,
                [nodeId.repositoryId]: {
                    ...state[nodeId.repositoryId],
                    [nodeId.id]: {
                        ...state[nodeId.repositoryId]?.[nodeId.id],
                        widgets,
                    },
                },
            };
        }

        default: {
            return state;
        }
    }
};
