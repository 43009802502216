import { takeEvery, select, call, put, all, take, cancelled } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import {
    FETCH_LICENSES_OWNERS_INFO,
    OPEN_LICENSEOWNERS_EDITING_TAB,
    SAVE_INFO_OF_LICENSEOWNERS,
    OPEN_LICENSES_TAB,
    FETCH_LICENSES,
    UPLOADING_LICENS_REQUEST,
    DELETE_LICENSE,
    UPLOAD_LICENSE,
} from '../actionsTypes/licenses.actionTypes';
import {
    fetchLicenseByTreeNodeSuccess,
    fetchedOwnerInfoSuccess,
    uploadingLicenseRequest,
    fetchOwnerInfo,
    fetchLicenses,
} from '../actions/licenses.actions';
import {
    TFetchLicensesOwnersInfoAction,
    TSaveInfoOfLicenseOwnersAction,
    TOpenLicenseOwnersEditingTabAction,
    TFetchLicensesAction,
    TOpenLicensesTabAction,
    TUploadingLicenseRequestAction,
    TUploadLicense,
    TDeleteLicense,
} from '../actions/licenses.actions.types';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import licenseTypes from '../models/licenseTypes';
import { MetaDataSelectors } from '../selectors/admintools.selectors';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTab, IWorkspaceLicenseOwnersEditingTabItemParams } from '../models/tab.types';
import { AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';
import { EditorMode } from '../models/editorMode';
import { workspaceAddTab, workspaceRemoveTabRequest } from '../actions/tabs.actions';
import { isNullOrUndefined } from 'is-what';
import { admintoolSelect } from '../actions/admintools.actions';
import { UserDTOLicensesEnum, NodeId, GroupDTO, UserDTO } from '../serverapi/api';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { NotificationType } from '../models/notificationType';
import { showNotification } from '../actions/notification.actions';
import { closeDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { v4 as uuid } from 'uuid';
import { licenseService, LicenseLoadStatus } from '../services/LicenseService';
import licenseTypesMessages from '../models/licenseTypes.messages';
import { AdminToolsUtils } from '../utils/adminToolsUtils';
import { TFullLicenseDataType } from '../reducers/admintool/licenses.reducer.types';
import { userService } from '../services/dao/UsersDAOService';
import { groupService } from '../services/GroupService';
import { LocalesService } from '../services/LocalesService';

function* handleOpenLicensesTab({ payload: { node } }: TOpenLicensesTabAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(node.nodeId.serverId));
    if (isNullOrUndefined(server)) {
        throw new Error(`Cannot find server with ID=${node.nodeId.serverId}`);
    }
    const loadingTabId: NodeId = { ...node.nodeId } as NodeId;
    loadingTabId.id = loadingTabId.id.concat('loading');
    const contentLoadingPageTab: TWorkspaceTab = {
        title: node.name,
        nodeId: loadingTabId,
        type: WorkSpaceTabTypes.CONTENT_LOADING_PAGE_TAB,
        mode: EditorMode.Read,
        params: {
            closable: false,
            serverId: node.nodeId.serverId,
        } as IWorkspaceLicenseOwnersEditingTabItemParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(contentLoadingPageTab));

    try {
        const data: TFullLicenseDataType = yield licenseService().getServerLicenseFullInfo(node.nodeId.serverId);
        if (!isNullOrUndefined(data)) {
            yield put(fetchLicenseByTreeNodeSuccess({ serverId: node.nodeId.serverId, data }));
            yield put(
                admintoolSelect({
                    currentServerId: node.nodeId.serverId,
                    activeTypeId: AdminToolTreeType.LICENSES,
                }),
            );
            yield put(workspaceRemoveTabRequest(contentLoadingPageTab));
            yield put(
                workspaceAddTab({
                    ...contentLoadingPageTab,
                    type: WorkSpaceTabTypes.ADMINTOOLS_TAB,
                    nodeId: node.nodeId,
                }),
            );
        }
    } catch (e) {
        yield put(workspaceRemoveTabRequest(contentLoadingPageTab));
        throw e;
    }
}

function* handleFetchLicensesOwnersInfo({ payload: { serverId } }: TFetchLicensesOwnersInfoAction) {
    const types = Object.keys(licenseTypes).map((k) => k);
    for (let i = 0; i < types.length; i++) {
        const users: UserDTO[] = yield call(() => userService().getByLicense({ license: types[i], serverId }));
        const groups: GroupDTO[] = yield call(() => groupService().getByLicense({ license: types[i], serverId }));
        yield put(
            fetchedOwnerInfoSuccess({
                serverId,
                licenseType: types[i] as UserDTOLicensesEnum,
                users,
                groups,
            }),
        );
    }
}

function* handleOpenLicenseOwnersEditingTab({ payload: { licenseType } }: TOpenLicenseOwnersEditingTabAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    const intl = LocalesService.useIntl();

    const contentLoadingPageTab: TWorkspaceTab = {
        title: intl.formatMessage(licenseTypesMessages[licenseType]),
        nodeId: AdminToolsUtils.createNodeId(serverId, WorkSpaceTabTypes.LICENSEOWNERS_EDITING_TAB),
        type: WorkSpaceTabTypes.ADMINTOOLS_TAB,
        mode: EditorMode.Read,
        params: {
            closable: false,
            serverId,
            licenseType,
        } as IWorkspaceLicenseOwnersEditingTabItemParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* handleSaveInfoOfLicenseOwners({
    payload: { serverId, ids, licenseType: license, action },
}: TSaveInfoOfLicenseOwnersAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    if (action === 'ADD') {
        yield call(() => server.api.principal.addLicense({ license, body: { ids } }));
    } else {
        yield all(ids.map((id) => call(() => server.api.principal.deleteLicense({ license, id }))));
    }
    yield put(fetchOwnerInfo(serverId));
}

function* handleUploadDialogSubmit({ payload: { license } }: TUploadLicense) {
    const uploadlicenseChannel = channel();
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    const reader = new FileReader();
    reader.readAsText(license);
    reader.onload = () => {
        uploadlicenseChannel.put(uploadingLicenseRequest({ reader, serverId }));
    };
    try {
        const action = yield take(uploadlicenseChannel);
        yield put(action);
    } finally {
        if (yield cancelled()) {
            uploadlicenseChannel.close();
        }
    }
}

function* handleUploadLicenseRequest({ payload: { reader, serverId } }: TUploadingLicenseRequestAction) {
    const license: string = reader.result as string;
    const status: LicenseLoadStatus = yield licenseService().addLicense(serverId, license);
    yield put(closeDialog(DialogType.UPLOAD_LICENSE_DIALOG));
    switch (status) {
        case LicenseLoadStatus.SUCCESS:
            yield put(showNotification({ id: uuid(), type: NotificationType.IMPORT_LICENSE_SUCCESS }));
            break;
        case LicenseLoadStatus.FILE_CORRUPTED:
            yield put(showNotification({ id: uuid(), type: NotificationType.IMPORT_LICENSE_FILE_CORRUPTED }));
            break;
        case LicenseLoadStatus.LICENSE_EXIST:
            yield put(showNotification({ id: uuid(), type: NotificationType.IMPORT_LICENSE_LICENSE_EXIST }));
            break;
        default:
            yield put(showNotification({ id: uuid(), type: NotificationType.IMPORT_LICENSE_FAIL }));
            break;
    }
    const licenseData: TFullLicenseDataType = yield licenseService().getServerLicenseFullInfo(serverId);
    if (licenseData) {
        yield put(fetchLicenseByTreeNodeSuccess({ serverId, data: licenseData }));
    }
}

function* handleFetchLicenses({ payload: { serverId } }: TFetchLicensesAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    if (isNullOrUndefined(server)) {
        throw new Error(`Cannot find server with ID=${serverId}`);
    }

    const data: TFullLicenseDataType = yield licenseService().getServerLicenseFullInfo(serverId);

    if (!isNullOrUndefined(data)) {
        yield put(fetchLicenseByTreeNodeSuccess({ serverId, data }));
    }
}

function* hadleDeleteLicense({ licenseId }: TDeleteLicense) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const result = yield call(() => server.api.license.deleteLicense({ licenseId }));
    if (result.status) {
        yield put(fetchLicenses(serverId));
    }
}

export function* licenseSaga() {
    yield takeEvery(OPEN_LICENSES_TAB, handleOpenLicensesTab);
    yield takeEvery(FETCH_LICENSES, handleFetchLicenses);
    yield takeEvery(FETCH_LICENSES_OWNERS_INFO, handleFetchLicensesOwnersInfo);
    yield takeEvery(OPEN_LICENSEOWNERS_EDITING_TAB, handleOpenLicenseOwnersEditingTab);
    yield takeEvery(SAVE_INFO_OF_LICENSEOWNERS, handleSaveInfoOfLicenseOwners);
    yield takeEvery(UPLOAD_LICENSE, handleUploadDialogSubmit);
    yield takeEvery(UPLOADING_LICENS_REQUEST, handleUploadLicenseRequest);
    yield takeEvery(DELETE_LICENSE, hadleDeleteLicense);
}
