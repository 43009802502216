// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ParamsStep__paramsItem__gT17w{width:100%}.ParamsStep__paramsItem_description__ijq8F{padding-top:10px;font-size:14px;font-weight:500;color:var(--text)}.ParamsStep__select___7dTB{width:530px;font-weight:500;color:var(--mainColor)}.ParamsStep__option___AXlH{color:var(--mainColor)}", "",{"version":3,"sources":["webpack://./src/modules/Import/Aris/components/ParamsStep/ParamsStep.scss"],"names":[],"mappings":"AAAA,+BACE,UAAA,CACA,2CACE,gBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAIJ,2BACE,WAAA,CACA,eAAA,CACA,sBAAA,CAGF,2BACE,sBAAA","sourcesContent":[".paramsItem {\r\n  width: 100%;\r\n  &_description {\r\n    padding-top: 10px;\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n    color: var(--text);\r\n  }\r\n}\r\n\r\n.select {\r\n  width: 530px;\r\n  font-weight: 500;\r\n  color: var(--mainColor);\r\n}\r\n\r\n.option {\r\n  color: var(--mainColor);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paramsItem": "ParamsStep__paramsItem__gT17w",
	"paramsItem_description": "ParamsStep__paramsItem_description__ijq8F",
	"select": "ParamsStep__select___7dTB",
	"option": "ParamsStep__option___AXlH"
};
export default ___CSS_LOADER_EXPORT___;
