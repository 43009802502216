import React from 'react';
import { Icon } from 'UIKit';
import icDivider from 'icons/toolbar/controls/ic-divider.svg';
import theme from '../RichTextEditorToolbar.scss';

const DividerComponent = () => (
    <div className={theme.divider}>
        <Icon spriteSymbol={icDivider} className={theme.divider} />
    </div>
);

export default DividerComponent;
