import {
    SPREADSHEET_OPEN_BY_ID,
    SPREADSHEET_EDITOR_MODE_CHANGED,
    SPREADSHEET_SAVE,
    SPREADSHEET_LOCK,
    SPREADSHEET_UNLOCK,
    SPREADSHEET_REMOVE_SUCCESS,
    SPREADSHEET_SAVE_AND_UNLOCK,
    SPREADSHEET_RENAME,
    SPREADSHEET_UPDATE,
    SPREADSHEET_UPDATE_AFTER_EDIT_IN_PROPERTY_TAB,
    SPREADSHEET_LOAD_BY_ID,
} from '../../actionsTypes/entities/spreadsheet.actionTypes';
import { ISpreadsheetNode } from '../../models/bpm/bpm-model-impl.types';
import { EditorMode } from '../../models/editorMode';
import { NodeId } from '../../serverapi/api';
import {
    TSpreadsheetEditorModeChangedAction,
    TSpreadsheetLoadByIdAction,
    TSpreadsheetLockAction,
    TSpreadsheetOpenByIdAction,
    TSpreadsheetRemoveSuccessAction,
    TSpreadsheetRenameAction,
    TSpreadsheetRenamePayload,
    TSpreadsheetSaveAction,
    TSpreadsheetSaveAndUnlockAction,
    TSpreadsheetSaveAndUnlockPayload,
    TSpreadsheetUnlockAction,
    TSpreadsheetUpdate,
    TSpreadsheetUpdateAfterEditInPropertyTab,
} from './spreadsheet.actions.types';

export const spreadsheetOpenById = (nodeId: NodeId): TSpreadsheetOpenByIdAction => ({
    type: SPREADSHEET_OPEN_BY_ID,
    payload: {
        nodeId,
    },
});

export const spreadsheetLoadById = (nodeId: NodeId): TSpreadsheetLoadByIdAction => ({
    type: SPREADSHEET_LOAD_BY_ID,
    payload: {
        nodeId,
    },
});

export const spreadsheetEditorModeChanged = (mode: EditorMode): TSpreadsheetEditorModeChangedAction => ({
    type: SPREADSHEET_EDITOR_MODE_CHANGED,
    payload: {
        mode,
    },
});

export const spreadsheetSave = (
    serverId: string,
    requestBody: {
        body: ISpreadsheetNode;
    },
): TSpreadsheetSaveAction => ({
    type: SPREADSHEET_SAVE,
    payload: {
        serverId,
        requestBody,
    },
});

export const spreadsheetLock = (nodeId: NodeId): TSpreadsheetLockAction => ({
    type: SPREADSHEET_LOCK,
    payload: {
        nodeId,
    },
});

export const spreadsheetUnlock = (nodeId: NodeId): TSpreadsheetUnlockAction => ({
    type: SPREADSHEET_UNLOCK,
    payload: {
        nodeId,
    },
});

export const spreadsheetRemoveSuccess = (nodeId: NodeId): TSpreadsheetRemoveSuccessAction => ({
    type: SPREADSHEET_REMOVE_SUCCESS,
    payload: {
        nodeId,
    },
});

export const spreadsheetSaveAndUnlock = (
    payload: TSpreadsheetSaveAndUnlockPayload,
): TSpreadsheetSaveAndUnlockAction => ({
    type: SPREADSHEET_SAVE_AND_UNLOCK,
    payload,
});

export const spreadsheetRename = (payload: TSpreadsheetRenamePayload): TSpreadsheetRenameAction => ({
    type: SPREADSHEET_RENAME,
    payload,
});

export const spreadsheetUpdate = (payload: ISpreadsheetNode): TSpreadsheetUpdate => ({
    type: SPREADSHEET_UPDATE,
    payload,
});

export const spreadsheetUpdateAfterEditInPropertyTab = (
    payload: ISpreadsheetNode,
): TSpreadsheetUpdateAfterEditInPropertyTab => ({
    type: SPREADSHEET_UPDATE_AFTER_EDIT_IN_PROPERTY_TAB,
    payload,
});
