import React, { FC } from 'react';
import cx from 'classnames';
import menuItemTheme from '../items/MenuItem.scss';
import theme from './GraphObjectToolbar.scss';
import messages from '../../messages/CommonToolbar.messages';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { Icon, NUIButton } from '../../../UIKit';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralMenuButtonsState } from '../../../../selectors/generalMenu.selectors';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';
import { LabelStyle } from '../../../../models/labelStyle';
import { MxConstants } from '../../../../mxgraph/mxgraph';
import icBold from '../../../../resources/icons/ic-ribbon-text-bold.svg';
import icItalic from '../../../../resources/icons/ic-ribbon-text-italic.svg';
import icUnderline from '../../../../resources/icons/ic-ribbon-text-underline.svg';

type TGraphObjectToolbarTextStyleSettingsProps = {
    disabled: boolean;
    compact: boolean;
};

export const GraphObjectToolbarTextStyleSettings: FC<TGraphObjectToolbarTextStyleSettingsProps> = ({
    disabled,
    compact,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const buttonEditLabelState = useSelector(getGeneralMenuButtonsState);

    const onChange = (value: any, isActive: boolean) => () => {
        dispatch(generalMenuLabelStyleChangeAction(LabelStyle.decorationStyle, value, isActive));
    };

    return (
        <span className={cx(menuItemTheme.spanGroupRow, theme.spanGroupRow, compact && theme.compact)}>
            <Tooltip
                mouseLeaveDelay={0}
                title={
                    !disabled ? intl.formatMessage(messages.textBold) : intl.formatMessage(messages.selectEdgeOrObject)
                }
            >
                <span className={cx(menuItemTheme.buttonWrapper, disabled && menuItemTheme.buttonDisabled)}>
                    <NUIButton
                        data-test="object-toolbar_font-text-bold"
                        onClick={onChange(MxConstants.FONT_BOLD, buttonEditLabelState.isFontBoldSelected)}
                        selected={buttonEditLabelState.isFontBoldSelected}
                        disabled={disabled}
                    >
                        <Icon spriteSymbol={icBold} disabled={disabled} />
                    </NUIButton>
                </span>
            </Tooltip>
            <Tooltip
                mouseLeaveDelay={0}
                title={
                    !disabled
                        ? intl.formatMessage(messages.textItalic)
                        : intl.formatMessage(messages.selectEdgeOrObject)
                }
            >
                <span className={cx(menuItemTheme.buttonWrapper, disabled && menuItemTheme.buttonDisabled)}>
                    <NUIButton
                        data-test="object-toolbar_font-text-italic"
                        onClick={onChange(MxConstants.FONT_ITALIC, buttonEditLabelState.isFontItalicSelected)}
                        selected={buttonEditLabelState.isFontItalicSelected}
                        disabled={disabled}
                    >
                        <Icon spriteSymbol={icItalic} disabled={disabled} />
                    </NUIButton>
                </span>
            </Tooltip>
            <Tooltip
                mouseLeaveDelay={0}
                title={
                    !disabled
                        ? intl.formatMessage(messages.textUnderline)
                        : intl.formatMessage(messages.selectEdgeOrObject)
                }
            >
                <span className={cx(menuItemTheme.buttonWrapper, disabled && menuItemTheme.buttonDisabled)}>
                    <NUIButton
                        data-test="object-toolbar_font-text-underline"
                        onClick={onChange(MxConstants.FONT_UNDERLINE, buttonEditLabelState.isFontUnderlineSelected)}
                        selected={buttonEditLabelState.isFontUnderlineSelected}
                        disabled={disabled}
                    >
                        <Icon spriteSymbol={icUnderline} disabled={disabled} />
                    </NUIButton>
                </span>
            </Tooltip>
        </span>
    );
};
