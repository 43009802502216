import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CommentTooltip } from '../CommentTooltip/CommentTooltip.component';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import { NodeId, Comment } from '../../../../serverapi/api';
import theme from './DetailedCommentTooltip.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { NewCommentArea } from '../NewCommentArea/NewCommentArea.component';
import { deleteEditingComment } from '../../../../actions/comments.actions';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TabsSelectors } from '@/selectors/tabs.selectors';

type TDetailedCommentTooltip = {
    modelId: NodeId;
    commentId: string;
    maxTooltipHeight: string;
    setFullTooltipMode: (isFullTooltipMode: boolean) => void;
    onClose: () => void;
    resize: () => void;
    style: CSSStyleDeclaration;
    container: HTMLDivElement;
};

export const DetailedCommentTooltip = forwardRef((props: TDetailedCommentTooltip, ref) => {
    const { modelId, commentId, maxTooltipHeight, style, container, onClose, resize, setFullTooltipMode } = props;

    const dispatch = useDispatch();
    const [isFullFormat, setIsFullFormat] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const comment: Comment | undefined = useSelector(CommentsSelectors.getCommentById(modelId, commentId));
    const childrenComments: Comment[] = useSelector(CommentsSelectors.getCommentsByParentId(modelId, commentId));
    const isEditingComment: boolean = !!useSelector(CommentsSelectors.getEditingComment(modelId));
    const activeTabId: NodeId | undefined = useSelector(TabsSelectors.getActiveTabId);
    const tooltip: boolean | undefined = useSelector(CommentsSelectors.getTooltip(modelId));
    const tooltipStyles:
        | {
              left?: string | undefined;
              top?: string | undefined;
          }
        | undefined = useSelector(CommentsSelectors.getTooltipStyles(modelId));
    const isEditedComment: boolean = useSelector(CommentsSelectors.isEditedComment(modelId, commentId));

    useEffect(() => {
        resize();
    });

    const hideTooltip = () => {
        dispatch(deleteEditingComment(modelId));
        setIsEditMode(false);
        onClose();
    };

    useImperativeHandle(
        ref,
        () => ({
            hideTooltip: () => {
                if (isEditingComment && tooltip) {
                    if (isEditedComment) {
                        dispatch(
                            openDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG, {
                                onCancel: () => {
                                    hideTooltip();
                                },
                            }),
                        );
                    } else {
                        hideTooltip();
                    }
                }
            },
        }),
        [isEditingComment, isEditMode, tooltip, isEditedComment],
    );

    useEffect(() => {
        if (
            (isFullFormat && isEditingComment) ||
            (isFullFormat && !isEditingComment && tooltip) ||
            (isFullFormat && !isEditingComment && !isEditedComment) ||
            (tooltip && !isEditingComment && !isEditedComment)
        ) {
            container.style.visibility = 'hidden';
            container.style.display = 'none';
        }

        if (compareNodeIds(activeTabId, modelId) && tooltip) {
            container.style.visibility = 'visible';
            container.style.display = 'block';
            container.style.left = tooltipStyles?.left || '0';
            container.style.top = tooltipStyles?.top || '0';
        }
    }, [activeTabId]);

    const handleClick = () => {
        setFullTooltipMode(true);
        setIsFullFormat(true);
    };
    const onChangeEditMode = (newStatus: boolean) => {
        setIsEditMode(newStatus);
    };

    return (
        <div onClick={handleClick}>
            {isFullFormat && (
                <div className={theme.fullTooltip} style={{ maxHeight: `${maxTooltipHeight}px` }}>
                    {isEditMode ? (
                        <NewCommentArea
                            onlyFullSizeMode
                            modelId={modelId}
                            comment={comment}
                            onFinishEditing={() => onChangeEditMode(false)}
                            onChangeVisible={() => onChangeEditMode(false)}
                            showInTooltip
                            popupStyles={style}
                        />
                    ) : (
                        <CommentTooltip
                            onChangeEditMode={onChangeEditMode}
                            isHeadComment
                            modelId={modelId}
                            commentId={commentId}
                            onClose={onClose}
                            tooltip={tooltip}
                        />
                    )}
                    {!!childrenComments.length &&
                        childrenComments.map((childComment) => {
                            return (
                                <CommentTooltip
                                    modelId={modelId}
                                    commentId={childComment.commentId.id}
                                    key={childComment.commentId.id}
                                />
                            );
                        })}
                    <NewCommentArea modelId={modelId} comment={comment} isAnswer showInTooltip popupStyles={style} />
                </div>
            )}
            {!isFullFormat && <CommentTooltip modelId={modelId} commentId={commentId} isSmallFormat />}
        </div>
    );
});
