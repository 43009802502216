import { connect } from 'react-redux';
import { Header } from '../components/Header/Header.component';
import { TRootState } from '../../../reducers/root.reducer.types';
import { appCloseRequest, appMinimizeRequest, appSizeToggle } from '../../../actions/app.actions';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { localeSelect } from '../../../actions/locale.actions';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { workspaceTabSetAction } from '../../../actions/tabs.actions';
import { NodeId } from '../../../serverapi/api';
import electron from '../../../electron';
import { Locale, THeaderProps } from '../components/Header/header.types';
import { EditorMode } from '../../../models/editorMode';
import { LocalStorageDaoService } from '../../../services/dao/LocalStorageDaoService';
import { getIsEnLocaleAllowed } from '@/selectors/app.selector';
import { openUserAccountTab } from '../../../actions/userAccount.actions';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { redoAction, undoAction } from '@/actions/undoRedo.actions';
import { UndoRedoSelectors } from '@/selectors/undoRedo.selectors';

const mapStateToProps = (state: TRootState, props: Partial<THeaderProps>) => {
    let undoEnabled = false;
    let redoEnabled = false;

    const activeScheme = TabsSelectors.getActiveTab(state);
    const clientIcon: string | null = LocalStorageDaoService.getGreetingLogo();
    const isMatrix = activeScheme?.type === WorkSpaceTabTypes.MARTIX_EDITOR;

    if (activeScheme?.params?.undoManager && activeScheme?.mode !== EditorMode.Read) {
        undoEnabled = activeScheme.params.undoManager.undo;
        redoEnabled = activeScheme.params.undoManager.redo;
    }

    if (isMatrix && activeScheme?.mode !== EditorMode.Read) {
        const { redo, undo } = UndoRedoSelectors.getUndoRedoStatus(activeScheme.nodeId)(state);
        undoEnabled = undo;
        redoEnabled = redo;
    }

    return {
        isVisibleButtonActions: !!electron,
        isVisibleMainMenu: props.isVisibleMainMenu,
        isMaximized: state.app.isMaximized,
        locale: getCurrentLocale(state),
        activeKey: TabsSelectors.getActiveTabId(state),
        undoEnabled: undoEnabled || false,
        redoEnabled: redoEnabled || false,
        isStarted: state.app.isStarted,
        isEnLocaleAllowed: getIsEnLocaleAllowed(state),
        clientIcon,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClose: () => {
        dispatch(appCloseRequest());
    },
    onToggleSize: () => {
        dispatch(appSizeToggle());
    },
    onMinimize: () => {
        dispatch(appMinimizeRequest());
    },
    onLocaleSelect: (selected: Locale) => {
        LocalStorageDaoService.setLocale(selected);
        dispatch(localeSelect(selected));
    },
    onUndoAction: (activeKey: NodeId) => {
        dispatch(undoAction(activeKey));
        dispatch(
            workspaceTabSetAction({
                nodeId: activeKey,
                name: 'undo',
                value: true,
            }),
        );
    },
    onRedoAction: (activeKey: NodeId) => {
        dispatch(redoAction(activeKey));
        dispatch(
            workspaceTabSetAction({
                nodeId: activeKey,
                name: 'redo',
                value: true,
            }),
        );
    },
    onClickInitialsCircle: () => {
        dispatch(openUserAccountTab());
    },
});

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
