import { defineMessages } from 'react-intl';

export default defineMessages({
    objectName: {
        id: 'DecompositionDialog.objectName',
        defaultMessage: 'Имя объекта',
    },

    decompositionDialogTitle: {
        id: 'DecompositionDialog.title',
        defaultMessage: 'Декомпозиция',
    },

    decompositionDialogSaveButton: {
        id: 'DecompositionDialog.save',
        defaultMessage: 'Создать',
    },

    needLicense: {
        id: 'DecompositionDialog.needLicense',
        defaultMessage: 'Для этого действия вам необходима лицензия',
    },

    decompositionDialogCancelButton: {
        id: 'DecompositionDialog.cancel',
        defaultMessage: 'Отмена',
    },

    decompositionDialogRadioNew: {
        id: 'DecompositionDialog.radioNew',
        defaultMessage: 'Новая модель',
    },

    decompositionDialogRadioExisting: {
        id: 'DecompositionDialog.radioExisting',
        defaultMessage: 'Существующая модель',
    },

    decompositionDialogNewModelName: {
        id: 'DecompositionDialog.newModelName',
        defaultMessage: 'Декомпозиция - {objectName}',
    },

    decompositionDialogExistingModelLabel: {
        id: 'DecompositionDialog.tree.existingModel',
        defaultMessage: 'Выберите модель, которую хотите привязать:',
    },

    modelNameRequiredError: {
        id: 'DecompositionDialog.modelNameRequiredError',
        defaultMessage: 'Необходимо указать имя новой модели',
    },
    edgeText: {
        id: 'DecompositionDialog.edgeText',
        defaultMessage: 'Связь',
    },
    requiredExistingModel: {
        id: 'DecompositionDialog.requiredExistingModel',
        defaultMessage: 'Необходимо выбрать модель',
    },
});
