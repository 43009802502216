import { TRootState } from '@/reducers/root.reducer.types';
import { TSearchedDeletedElementsState } from '@/reducers/searchedDeletedElements.reducer.types';
import { SearchResult } from '@/serverapi/api';
import { createSelector } from 'reselect';

const getState = (state: TRootState) => state.searchedDeletedElements;

export const getSearchedElementsByRepositoryIdById = (repositoryId: string, id: string) =>
    createSelector<TRootState, TSearchedDeletedElementsState, SearchResult[]>(getState, (state) => {
        return state.byRepositoryId[repositoryId]?.byId[id] || [];
    });
