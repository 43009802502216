import { defineMessages } from 'react-intl';

export default defineMessages({
    USER: {
        id: 'AttributeTypePrincipal.USER',
        defaultMessage: 'Пользователь',
    },
    GROUP: {
        id: 'AttributeTypePrincipal.GROUP',
        defaultMessage: 'Группы пользователей',
    },
    USERS_AND_GROUPS: {
        id: 'AttributeTypePrincipal.USERS_AND_GROUPS',
        defaultMessage: 'Пользователь и группы пользователей',
    },
});
