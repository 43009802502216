import { AuthorizationMethod } from '@/models/security/authorizationMethod.types';
import { TOpenNodeRequestPayload } from '../../actions/openNode.actions.types';
import { TServerEntity } from '../../models/entities.types';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { prepareTreeItemData } from '../../modules/UIKit/components/EditableText/editableText.utils';
import {
    LoginResponseAuthorizationMethodEnum,
    NodeId,
    SystemPropertiesActiveAuthorizationMethodsEnum,
} from '../../serverapi/api';
import {
    HASH_LINK,
    HASH_LINK_WITH_KERBEROS_SSO,
    HASH_LINK_WITH_KEYCLOAK,
    HTTP,
    HTTPS,
    KERBEROS_SSO_PARAM,
    KEYCLOAK_PARAM,
    LINK_TYPE_TO_HASH_LINK_MAP,
} from '../../utils/consts';
import { TObjectTypesForLink, TNodeIdWithType, ObjectTypesForLink, TLinkType } from './ExternalLinkBLLService.types';

export class ExternalLinkBLLService {
    public static createExternalLink(
        serverUrl: string,
        objectType: TObjectTypesForLink,
        repositoryId: string,
        objectId: string,
        linkType: TLinkType,
        elementId?: string,
    ) {
        const objectPath: string = `${objectType.toLowerCase()}/${repositoryId}/${objectId}`;
        const fullLink: string = `${serverUrl}${LINK_TYPE_TO_HASH_LINK_MAP[linkType]}${objectPath}${
            elementId ? `/${elementId}` : ''
        }`;

        if (serverUrl.includes(HTTP) || serverUrl.includes(HTTPS)) {
            return fullLink;
        }

        return `${location.protocol}//${fullLink}`;
    }

    public static parseExternalLink(url: string, serverId: string): TNodeIdWithType {
        const nodeIdWithType: TNodeIdWithType = {
            nodeId: {
                id: '',
                repositoryId: '',
                serverId: '',
            },
            elementId: '',
            type: TreeItemType.Default,
        };
        try {
            const parsedHash: string[] = url.split('#')[1].split('/');
            if (
                parsedHash[0] === 'link' &&
                Object.values(ObjectTypesForLink).includes(parsedHash[1].toUpperCase() as TObjectTypesForLink)
            ) {
                nodeIdWithType.nodeId.id = parsedHash[3];
                nodeIdWithType.nodeId.repositoryId = parsedHash[2];
                nodeIdWithType.nodeId.serverId = serverId;
                nodeIdWithType.type = parsedHash[1].toUpperCase() as TObjectTypesForLink;
                nodeIdWithType.elementId = parsedHash[4] || '';
            }

            return nodeIdWithType;
        } catch (e) {
            return nodeIdWithType;
        }
    }

    public static isShortUri(uri: string) {
        return !uri.includes('://');
    }

    public static getNodePayloadFromUri(uri: string, existServer?: TServerEntity): TOpenNodeRequestPayload | undefined {
        if (ExternalLinkBLLService.isShortUri(uri)) {
            return undefined;
        }
        const includedHashLink =
            uri.includes(HASH_LINK) ||
            uri.includes(HASH_LINK_WITH_KERBEROS_SSO) ||
            uri.includes(HASH_LINK_WITH_KEYCLOAK);
        const includedBpmLink = uri.trim().startsWith('bpm://');
        const url = new URL(uri);
        const serverUrl = new URL(existServer?.url || '');

        if (includedBpmLink || (includedHashLink && url.host === serverUrl.host)) {
            if (existServer) {
                let type: TObjectTypesForLink = TreeItemType.Default;
                const elementIds: string[] = [];
                const nodeId: NodeId = {
                    id: '',
                    repositoryId: '',
                    serverId: existServer.id,
                };
                if (includedBpmLink) {
                    const parsedUri = prepareTreeItemData(uri);
                    nodeId.id = parsedUri.id;
                    nodeId.repositoryId = parsedUri.repositoryId;
                    type = parsedUri.type;
                } else {
                    const parsedUri = this.parseExternalLink(uri, existServer.id);
                    nodeId.id = parsedUri.nodeId.id;
                    nodeId.repositoryId = parsedUri.nodeId.repositoryId;
                    type = parsedUri.type;
                    elementIds.push(parsedUri.elementId);
                }
                if (nodeId.id) {
                    return { nodeId, type, elementIds };
                }
            }
        }

        return undefined;
    }

    public static getLinkTypeByAuth(
        authorizationMethod: LoginResponseAuthorizationMethodEnum | undefined,
        activeAuthorizationMethods: SystemPropertiesActiveAuthorizationMethodsEnum[] | undefined,
    ): TLinkType {
        if (!authorizationMethod || !activeAuthorizationMethods) return AuthorizationMethod.DB;

        if (
            authorizationMethod === AuthorizationMethod.KEYCLOAK ||
            authorizationMethod === AuthorizationMethod.KERBEROS ||
            authorizationMethod === AuthorizationMethod.DB
        )
            return authorizationMethod;

        if (
            authorizationMethod === AuthorizationMethod.LDAP &&
            activeAuthorizationMethods.includes(AuthorizationMethod.KERBEROS)
        ) {
            return AuthorizationMethod.KERBEROS;
        }

        return AuthorizationMethod.DB;
    }

    public static hasKeycloakParamInUrl(locationHref: string) {
        return locationHref.includes(KEYCLOAK_PARAM);
    }

    public static hasKerberosParamInUrl(locationHref: string) {
        return locationHref.includes(KERBEROS_SSO_PARAM);
    }
}
