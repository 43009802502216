import {
    SearchRuleAttributeTypeEnum,
    SearchRuleQueryRuleEnum,
} from '@/serverapi/api';

export interface ISearchRuleWithValueId {
    id: string;
    attributeType: SearchRuleAttributeTypeEnum;
    attributeTypeId: string;
    queryRule: SearchRuleQueryRuleEnum;
    values: Array<{ value: string; id: string }>;
}

export enum SearchRuleAttributeType {
    SYSTEM = 'SYSTEM',
    USER = 'USER',
}

export type TSystemAttributeEntityOptions = {
    id: string;
    name: string;
};
