import { defineMessages } from 'react-intl';

export default defineMessages({
    id: {
        id: 'NotificationTemplatesList.id',
        defaultMessage: 'ID',
    },
    name: {
        id: 'NotificationTemplatesList.name',
        defaultMessage: 'Название шаблона',
    },
    delete: {
        id: 'NotificationTemplatesList.delete',
        defaultMessage: 'Удаление шаблона',
    },
    deleteMessage: {
        id: 'NotificationTemplatesList.deleteMessage',
        defaultMessage: 'Вы уверены, что хотите удалить шаблон "{name}"?',
    },
    create: {
        id: 'NotificationTemplatesList.create',
        defaultMessage: 'Создать новый шаблон',
    },
});
