import { createSelector } from 'reselect';
import { TPresetProfileEntityTypes } from '../modules/AdminTools/Methodology/components/Presets/PresetProfile/presetProfile.types';
import { TRootState } from '../reducers/root.reducer.types';

export const aclStateSelector = (state: TRootState) => state.acl;

export namespace AclSelectors {
    export const allOfTypeInPreset = (compositeId: {
        presetId: string;
        serverId: string;
        type: TPresetProfileEntityTypes;
        profileId: string;
    }) => createSelector(aclStateSelector, (state) => state.get(compositeId));
}
