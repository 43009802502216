// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PointsTab__pointsTabForm__NoF_G{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;grid-gap:12px;gap:12px;max-width:400px}.PointsTab__pointsTab_labelBlockWrapper__eMr0W{margin-top:10px}.PointsTab__pointsTab_labelBlockWrapper_errorMessage__igD3Y{color:var(--TextRed600-UIKit)}.PointsTab__pointsTab_labelBlockWrapper_labelBlock__Wtx3j{padding-top:5px}.PointsTab__pointsTab_label__lQCNL{color:var(--textBlack)}.PointsTab__pointsTab_label__lQCNL span{font-weight:600}.PointsTab__pointsTab_divider__CkFeD{margin:10px 0}.PointsTab__inputName__fGyAG{width:170px}.PointsTab__inputNumber__Ch5yT{width:88px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/SymbolEditorTab/components/PointsTab/PointsTab.scss"],"names":[],"mappings":"AAAA,iCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,aAAA,CAAA,QAAA,CACA,eAAA,CAIA,+CACI,eAAA,CAEA,4DACI,6BAAA,CAEJ,0DACI,eAAA,CAGR,mCACI,sBAAA,CACA,wCACI,eAAA,CAIR,qCACI,aAAA,CAIR,6BACI,WAAA,CAGJ,+BACI,UAAA","sourcesContent":[".pointsTabForm {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 12px;\r\n    max-width: 400px;\r\n}\r\n\r\n.pointsTab {\r\n    &_labelBlockWrapper {\r\n        margin-top: 10px;\r\n\r\n        &_errorMessage {\r\n            color: var(--TextRed600-UIKit);\r\n        }\r\n        &_labelBlock {\r\n            padding-top: 5px;\r\n        }\r\n    }\r\n    &_label {\r\n        color: var(--textBlack);\r\n        span {\r\n            font-weight: 600;\r\n        }\r\n    }\r\n\r\n    &_divider {\r\n        margin: 10px 0;\r\n    }\r\n}\r\n\r\n.inputName {\r\n    width: 170px;\r\n}\r\n\r\n.inputNumber {\r\n    width: 88px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pointsTabForm": "PointsTab__pointsTabForm__NoF_G",
	"pointsTab_labelBlockWrapper": "PointsTab__pointsTab_labelBlockWrapper__eMr0W",
	"pointsTab_labelBlockWrapper_errorMessage": "PointsTab__pointsTab_labelBlockWrapper_errorMessage__igD3Y",
	"pointsTab_labelBlockWrapper_labelBlock": "PointsTab__pointsTab_labelBlockWrapper_labelBlock__Wtx3j",
	"pointsTab_label": "PointsTab__pointsTab_label__lQCNL",
	"pointsTab_divider": "PointsTab__pointsTab_divider__CkFeD",
	"inputName": "PointsTab__inputName__fGyAG",
	"inputNumber": "PointsTab__inputNumber__Ch5yT"
};
export default ___CSS_LOADER_EXPORT___;
