import { INIT_WS_CONNECTION } from '../actionsTypes/wsConnection.actionTypes';
import {
    TInitWSConnectionAction,
    TInitWSConnectionPayload
} from './wsConnection.actions.types';

export const initWSConnection: (
    payload: TInitWSConnectionPayload,
) => TInitWSConnectionAction = (payload) => ({
    type: INIT_WS_CONNECTION,
    payload,
});

