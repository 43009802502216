import { defineMessages } from 'react-intl';

export default defineMessages({
    appScriptExecutionStatusWait: {
        id: 'App.ScriptExecutionStatusWait',
        defaultMessage: 'Ожидает',
    },
    appScriptExecutionStatusRunning: {
        id: 'App.ScriptExecutionStatusRunning',
        defaultMessage: 'Выполняется',
    },
    appScriptExecutionStatusSuccess: {
        id: 'App.ScriptExecutionStatusSuccess',
        defaultMessage: 'Завершено',
    },
    appScriptExecutionStatusFail: {
        id: 'App.ScriptExecutionStatusFail',
        defaultMessage: 'Ошибка',
    },
    appScriptExecutionStatusStopped: {
        id: 'App.ScriptExecutionStatusStopped',
        defaultMessage: 'Остановлено',
    },
});
