import { defineMessages } from 'react-intl';

export default defineMessages({
    newColumnTitle: {
        id: 'EPCGrid.newColumnTitle',
        defaultMessage: 'Организационные элементы и прикладная система',
    },
    CT_EXEC_CT_CAN_SUPP: {
        id: 'EPCGrid.CT_EXEC_CT_CAN_SUPP',
        defaultMessage: 'Выполняет и поддерживает',
    },
    CT_MUST_BE_INFO_ABT: {
        id: 'EPCGrid.CT_MUST_BE_INFO_ABT',
        defaultMessage: 'Должен быть информирован о',
    },
    CT_MUST_BE_INFO_ON_CNC: {
        id: 'EPCGrid.CT_MUST_BE_INFO_ON_CNC',
        defaultMessage: 'Должен быть информирован о нестандартном завершении',
    },
    CT_MUST_BE_INFO_ABT_RES: {
        id: 'EPCGrid.CT_MUST_BE_INFO_ABT_RES',
        defaultMessage: 'Должен информировать о результате',
    },
    CT_IS_TECH_RESP: {
        id: 'EPCGrid.CT_IS_TECH_RESP',
        defaultMessage: 'Отвечает за техническую часть',
    },
    CT_IS_DP_RESP: {
        id: 'EPCGrid.CT_IS_DP_RESP',
        defaultMessage: 'Отвечает по ИТ за',
    },
    CT_AGREES: {
        id: 'EPCGrid.CT_AGREES',
        defaultMessage: 'Принимает',
    },
    CT_DECID_ON: {
        id: 'EPCGrid.CT_DECID_ON',
        defaultMessage: 'Принимает решение по',
    },
    OTHER: {
        id: 'EPCGrid.OTHER',
        defaultMessage: 'Прочее',
    },
    CONNECTED_WITH: {
        id: 'EPCGrid.CONNECTED_WITH',
        defaultMessage: 'Связан(а) с',
    },
    CT_CONTR_TO: {
        id: 'EPCGrid.CT_CONTR_TO',
        defaultMessage: 'Способствует при выполнении',
    },
    CT_HAS_CONSLT_ROLE_IN: {
        id: 'EPCGrid.CT_HAS_CONSLT_ROLE_IN',
        defaultMessage: 'Участвует в качестве консультанта',
    },
    edgeConnectionName: {
        id: 'EPCGrid.edgeConnectionName',
        defaultMessage: ' ',
    },
});
