import { FRAME_STYLES_INDICATOR, SequenceSymbolShapes, SymbolTypeId } from '../ComplexSymbol.constants';
import { getSequenceFrameTemplate } from './sequenceSymbolDiagramElements';
import { MxCell } from 'MxGraph';
import { v4 as uuid } from 'uuid';
import { UMLFrameTitle } from './UMLFrameTitle.class';
import { SequenceShapeSymbol } from './SequenceShapeSymbol';
import createComplexSymbol from '../createComplexSymbol';
import { TRootCellValue } from '../ComplexSymbol.class.types';

export abstract class UMLFrame extends SequenceShapeSymbol {
    complexSymbolTypeId = SymbolTypeId.UML_FRAME;
    template = getSequenceFrameTemplate();

    public addToGraph() {
        const root = this.customProps?.createFrameTitle ? this.insert() : this.restore();

        return this.afterCreate(root);
    }

    insert(): MxCell {
        const { graph, rootCellValue } = this;
        const { id, x = 0, y = 0 } = rootCellValue;

        const style = `${FRAME_STYLES_INDICATOR};noLabel=1;shape=rectangle;editable=0;connectable=0;pointerEvents=0;`;
        const parent = graph.getModel().getCell(rootCellValue.parent as string) || graph.getDefaultParent();
        const width = 340;
        const height = 190;
        const root = graph.insertVertex(parent, id, rootCellValue, x, y, width, height, style);

        const shape = {
            id: uuid(),
            type: 'shape',
            parent: id,
            x: 0,
            y: 0,
            width: 160,
            height: 26,
        };

        createComplexSymbol(UMLFrameTitle, {
            graph,
            rootCellValue: shape as TRootCellValue,
        });

        return root;
    }

    restore(): MxCell {
        const { graph, rootCellValue } = this;

        const root = graph.insertVertexFromValue(rootCellValue);

        return root;
    }

    static getUMLFrameTitleIdByCell(cell: MxCell): string {
        return `${cell.parent.id}_${SequenceSymbolShapes.UML_FRAME_TITLE}`;
    }

    static getUMLFrameTitleIdByParentId(parentId: string): string {
        return `${parentId}_${SequenceSymbolShapes.UML_FRAME_TITLE}`;
    }

    static isUMLFrameTitle(cell: MxCell): boolean {
        return cell.id === UMLFrame.getUMLFrameTitleIdByCell(cell);
    }
}
