// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExportDialog__modelDecompositions__HwUAW{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.ExportDialog__folderDecompositions__ulk9w{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row}.ExportDialog__folderDecompositions__ulk9w .ExportDialog__folderDecompositionsSelect__DBRyI{width:80px}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/ExportDialog/ExportDialog.scss"],"names":[],"mappings":"AAAA,0CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAGJ,2CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CAEA,4FACI,UAAA","sourcesContent":[".modelDecompositions {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.folderDecompositions {\r\n    display: flex;\r\n    flex-direction: row;\r\n\r\n    .folderDecompositionsSelect {\r\n        width: 80px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelDecompositions": "ExportDialog__modelDecompositions__HwUAW",
	"folderDecompositions": "ExportDialog__folderDecompositions__ulk9w",
	"folderDecompositionsSelect": "ExportDialog__folderDecompositionsSelect__DBRyI"
};
export default ___CSS_LOADER_EXPORT___;
