// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GreetingText__container__LEhOx .ant-form-item-row{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.GreetingText__container__LEhOx .ant-form-item-row .ant-form-item-control{-webkit-box-flex:unset;-ms-flex:unset;flex:unset;max-width:455px}.GreetingText__container__LEhOx .ant-form-item-label{max-width:455px;text-align:left}.GreetingText__container__LEhOx .ant-form-item-row .ant-form-item-label>label{height:auto;position:static;margin-right:10px;margin-bottom:-4px}.GreetingText__container__LEhOx textarea{border-radius:4px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/ServerSettings/components/InterFaceSetting/GreetingText.scss"],"names":[],"mappings":"AAEQ,mDACI,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,0EACI,sBAAA,CAAA,cAAA,CAAA,UAAA,CACA,eAAA,CAGR,qDACI,eAAA,CACA,eAAA,CAGA,8EACI,WAAA,CACA,eAAA,CACA,iBAAA,CACA,kBAAA,CAIZ,yCACI,iBAAA","sourcesContent":[".container {\r\n    :global {\r\n        .ant-form-item-row {\r\n            flex-direction: column;\r\n            .ant-form-item-control {\r\n                flex: unset;\r\n                max-width: 455px;\r\n            }\r\n        }\r\n        .ant-form-item-label {\r\n            max-width: 455px;\r\n            text-align: left;\r\n        }\r\n        .ant-form-item-row {\r\n            .ant-form-item-label > label {\r\n                height: auto;\r\n                position: static;\r\n                margin-right: 10px;\r\n                margin-bottom: -4px;\r\n            }\r\n        }\r\n    }\r\n    textarea {\r\n        border-radius: 4px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GreetingText__container__LEhOx"
};
export default ___CSS_LOADER_EXPORT___;
