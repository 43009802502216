import { defineMessages } from 'react-intl';

export default defineMessages({
    AttributesDialogTitle: {
        id: 'AttributesDialog.Title',
        defaultMessage: 'Добавить атрибут',
    },
    AttributesDialogCancelButton: {
        id: 'AttributesDialog.CancelButton',
        defaultMessage: 'Отмена',
    },
    AttributesDialogOkButton: {
        id: 'AttributesDialog.OkButton',
        defaultMessage: 'Добавить',
    },
});
