export const MIN_CLASS_SYMBOL_HEIGHT = 110;

export const MAX_CLASS_SYMBOL_HEIGHT = 720;

export const MIN_CLASS_SYMBOL_WIDTH = 50;

export const MAX_CLASS_SYMBOL_WIDTH = 720;

export const CLASS_VERTICAL_OVERFLOW_SYMBOL = `\u2003\u2003${String.fromCharCode(8943)}`;

export enum UML_ATTR_ATTRIBUTE {
    LINK = 'a_uml_atribute_type_link',
    ENUM = 'a_uml_atribute_type_enum',
    STRING = 'a_uml_atribute_type_string',
    VISIBILITY = 'a_uml_atribute_visibility',
}

export enum UML_ATTR_PARAMETER {
    LINK = 'a_uml_parameter_modifier_link',
    ENUM = 'a_uml_parameter_modifier_enum',
    STRING = 'a_uml_parameter_modifier_string',
    DEF_VALUE = 'a_uml_parameter_defValue',
}

export enum UML_ATTR_METHOD {
    LINK = 'a_uml_method_returntype_link',
    ENUM = 'a_uml_method_returntype_enum',
    STRING = 'a_uml_method_returntype_string',
    VISIBILITY = 'a_uml_method_visibility',
}

export enum UML_ATTR_RECEPTION {
    LINK = 'a_uml_reception_type_link',
    ENUM = 'a_uml_reception_type_enum',
    STRING = 'a_uml_reception_type_string',
    VISIBILITY = 'a_uml_reception_visibility',
}

export const umlClassOverlayStyle = {
    v: 'center',
    h: 'top',
    x: 0,
    y: 0,
    style: '8pt "Arial" #000000 right bottom',
    imageId: '',
    format: 'TEXT',
    rules: [],
};

export enum UmlClassSymbolChildren {
    ATTRIBUTES = 'uml.class.attributes',
    OPERATIONS = 'uml.class.operations',
    RECEPTIONS = 'uml.class.receptions',
}
