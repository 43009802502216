// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardEditorToolbar__modeGroup_full__f3Xc3{margin:13px 4px 0 4px}.DashboardEditorToolbar__modeGroup_compact__AKhTE{margin-top:-4px;margin-left:4px}.DashboardEditorToolbar__gridButton_compact__sipy_{margin-top:4px}.DashboardEditorToolbar__gridButtonContent__Wl9Y9{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.DashboardEditorToolbar__gridButtonText___qBVW{color:var(--textGrey)}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/DashboardEditorToolbar/DashboardEditorToolbar.scss"],"names":[],"mappings":"AAAA,+CACI,qBAAA,CAGJ,kDACI,eAAA,CACA,eAAA,CAGJ,mDACI,cAAA,CAGJ,kDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGJ,+CACI,qBAAA","sourcesContent":[".modeGroup_full {\r\n    margin: 13px 4px 0 4px;\r\n}\r\n\r\n.modeGroup_compact {\r\n    margin-top: -4px;\r\n    margin-left: 4px;\r\n}\r\n\r\n.gridButton_compact {\r\n    margin-top: 4px;\r\n}\r\n\r\n.gridButtonContent {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.gridButtonText {\r\n    color: var(--textGrey);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modeGroup_full": "DashboardEditorToolbar__modeGroup_full__f3Xc3",
	"modeGroup_compact": "DashboardEditorToolbar__modeGroup_compact__AKhTE",
	"gridButton_compact": "DashboardEditorToolbar__gridButton_compact__sipy_",
	"gridButtonContent": "DashboardEditorToolbar__gridButtonContent__Wl9Y9",
	"gridButtonText": "DashboardEditorToolbar__gridButtonText___qBVW"
};
export default ___CSS_LOADER_EXPORT___;
