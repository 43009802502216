export const IsWinOS: boolean = navigator.appVersion.indexOf('Win') > 0;

export const IsMacOS: boolean = navigator.appVersion.indexOf('Mac') > 0;

export namespace BPMMxConstants {
    export const STYLE_UNDOABLE: string = 'undoable';
    export const STYLE_SELECTABLE: string = 'selectable';
    export const CURSOR_NOT_ALLOWED = 'not-allowed';
    export const STYLE_DISCONNECTABLE = 'disconnectable';
    export const ARROW_CONTAINMENT = 'plusCircle';
    export const ARROW_DASH = 'dash';
    export const AUTO_EDGE_TYPE_ID = 'edgeTypeAuto';
}

export const MIN_EDGE_TEXT_WIDTH = 160;

export const EDGE_TEXT_PADDING = 20;

export const MIN_EDGE_TEXT_HEIGHT = 60;

export const INACTIVE_CELL_STYLE_OPACITY: string = '20';

export const canExtendParentStyleName: string = 'canExtendParent';
