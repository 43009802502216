// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportEditorSidePanel__reportEditorSidePanel__pLoBk{min-width:280px;max-width:280px}.ReportEditorSidePanel__sidePanelBody__DA7CJ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;height:100%;grid-gap:16px;gap:16px;color:var(--Neutral800)}.ReportEditorSidePanel__sidePanelBody__DA7CJ span{color:var(--Neutral800)}", "",{"version":3,"sources":["webpack://./src/modules/Report/ReportEditor/ReportEditorSidebar/ReportEditorSidePanel.scss"],"names":[],"mappings":"AAAA,qDACI,eAAA,CACA,eAAA,CAGJ,6CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CAAA,QAAA,CACA,uBAAA,CACA,kDACI,uBAAA","sourcesContent":[".reportEditorSidePanel {\r\n    min-width: 280px;\r\n    max-width: 280px;\r\n}\r\n\r\n.sidePanelBody {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    height: 100%;\r\n    gap: 16px;\r\n    color: var(--Neutral800);\r\n    span {\r\n        color: var(--Neutral800);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportEditorSidePanel": "ReportEditorSidePanel__reportEditorSidePanel__pLoBk",
	"sidePanelBody": "ReportEditorSidePanel__sidePanelBody__DA7CJ"
};
export default ___CSS_LOADER_EXPORT___;
