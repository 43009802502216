import { defineMessages } from 'react-intl';

export default defineMessages({
    delete: {
        id: 'DeleteConfirmationDialog.delete',
        defaultMessage: 'Удалить',
    },
    cancel: {
        id: 'DeleteConfirmationDialog.cancel',
        defaultMessage: 'Отмена',
    },
    deleteTitle: {
        id: 'DeleteConfirmationDialog.deleteTitle',
        defaultMessage: 'Удалить элементы методологии?',
    },
});