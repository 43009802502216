import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { workspaceRemoveTabRequest } from '../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { IWorkspaceAccessPermissionOwnersEditingTabItemParams, TWorkspaceTab } from '../../../../models/tab.types';
import { GroupsSelectors } from '../../../../selectors/groups.selectors';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { fetchAllGroupsRequest } from '../../../../actions/groups.actions';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { fetchUsersByGroupIdRequest } from '../../../../actions/users.actions';
import { TFetchUsersByGroupIdPayload } from '../../../../actions/users.actions.types';
import { adminToolSetParams } from '../../../../actions/admintools.actions';
import { withWindowResize } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import { TAccessPermissionsParams, TAdminToolsParams, TAdminToolsParamsKeys } from '../../data/admintool.types';
import { AccessPermissonsSelectors } from '../../../../selectors/accessPermissions.selectors';
import {
    TSaveInfoOfAccessOwnersPayload,
    saveInfoOfAccessOwners,
} from '../../../../actions/accessPermissions.actions.types';
import GroupsAndUsersPrivilegeEditingTabComponents from '../../GroupsAndUsersPrivilegeEditingTab/GroupsAndUsersPrivilegeEditingTab.components';
import {
    TGUPrivilegeEditingTabProps,
    TGUPrivilegeEditingTabActionProps,
} from '../../GroupsAndUsersPrivilegeEditingTab/GroupsAndUsersPrivilegeEditingTab.components.types';

const mapStateToProps = (state: TRootState): Partial<TGUPrivilegeEditingTabProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const allGroups = GroupsSelectors.getAll(serverId)(state);
    const params: TAccessPermissionsParams | undefined = MetaDataSelectors.getParams(
        TAdminToolsParamsKeys.ACCESS_PERMISSIONS,
    )(state);
    const groupClicked = (params && params.groupId) || (allGroups && allGroups.length ? allGroups[0].id : undefined);
    const usersInGroup = groupClicked ? UsersSelectors.byGroupId(serverId, groupClicked)(state) : [];
    const nodeId = TabsSelectors.getActiveTabId(state);
    const tab = TabsSelectors.byId(nodeId)(state);
    const tabParam = tab?.params as IWorkspaceAccessPermissionOwnersEditingTabItemParams;
    const owners = AccessPermissonsSelectors.getOwnersByAccessType(serverId, tabParam?.accessType)(state);

    return {
        tab,
        allGroups,
        usersInGroup,
        serverId,
        groupClicked,
        owners,
        addonParamToSave: { serverId, accessType: tabParam?.accessType },
        toolBarParams: {
            type: 'accessPermissions',
            payload: { accessType: tabParam?.accessType },
        },
    };
};

const mapDispatchToProps: (dispatch) => Partial<TGUPrivilegeEditingTabActionProps> = (dispatch) => ({
    onClose: (tab: TWorkspaceTab) => dispatch(workspaceRemoveTabRequest(tab)),
    fetchAllGroups: (serverId: string) => dispatch(fetchAllGroupsRequest(serverId)),
    groupItemClicked: (groupClicked: number) =>
        dispatch(adminToolSetParams({ accessesRights: { groupId: groupClicked } } as TAdminToolsParams)),
    fetchUsersByGroup: (payload: TFetchUsersByGroupIdPayload) => dispatch(fetchUsersByGroupIdRequest(payload)),
    onSaveChanges: (payload: TSaveInfoOfAccessOwnersPayload) => dispatch(saveInfoOfAccessOwners(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withWindowResize(GroupsAndUsersPrivilegeEditingTabComponents));
