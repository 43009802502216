import { defineMessages } from 'react-intl';

export default defineMessages({
    dataCol: {
        id: 'WidgetSettingsPanel.dataCol',
        defaultMessage: 'Столбец данных',
    },
    legendCol: {
        id: 'WidgetSettingsPanel.legendCol',
        defaultMessage: 'Столбец легенды',
    },
});
