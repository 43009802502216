import {
    TAddPresetImageGroupAction,
    TCreatePresetImageGroupAction,
    TCreatePresetImageGroupPayload,
    TDeletePresetImageGroupAction,
    TDeletePresetImageGroupPayload, TDeletePresetImageGroupRequestAction,
    TDeletePresetImageGroupRequestPayload,
    TDeletePresetImageGroupSuccessAction, TEditPresetImageGroupAction,
    TEditPresetImageGroupPayload,
    TSubmitPresetImageGroupAction,
    TSubmitPresetImageGroupPayload
} from "./presetImageGroup.actions.types";
import {
    ADD_PRESET_IMAGE_GROUPS,
    CREATE_PRESET_IMAGE_GROUP,
    DELETE_PRESET_IMAGE_GROUP,
    DELETE_PRESET_IMAGE_GROUP_REQUEST,
    DELETE_PRESET_IMAGE_GROUP_SUCCESS, EDIT_PRESET_IMAGE_GROUP,
    SUBMIT_PRESET_IMAGE_GROUP
} from "../../actionsTypes/presetSettings/presetImageGroup.actionTypes";


export const deletePresetImageGroupRequest = (payload: TDeletePresetImageGroupRequestPayload): TDeletePresetImageGroupRequestAction => ({
    type: DELETE_PRESET_IMAGE_GROUP_REQUEST,
    payload
});

export const deletePresetImageGroup = (payload: TDeletePresetImageGroupPayload): TDeletePresetImageGroupAction => ({
    type: DELETE_PRESET_IMAGE_GROUP,
    payload
});

export const deletePresetImageGroupSuccess
                = (payload: TDeletePresetImageGroupRequestPayload): TDeletePresetImageGroupSuccessAction => ({
                    type: DELETE_PRESET_IMAGE_GROUP_SUCCESS,
                    payload
                });

export const createPresetImageGroup = (payload: TCreatePresetImageGroupPayload): TCreatePresetImageGroupAction => ({
    type: CREATE_PRESET_IMAGE_GROUP,
    payload,
});

export const submitPresetImageGroup = (payload: TSubmitPresetImageGroupPayload): TSubmitPresetImageGroupAction => ({
    type: SUBMIT_PRESET_IMAGE_GROUP,
    payload
});

export const addPresetImageGroups = (payload: TSubmitPresetImageGroupPayload): TAddPresetImageGroupAction => ({
    type: ADD_PRESET_IMAGE_GROUPS,
    payload,
});

export const editPresetImageGroup = (payload: TEditPresetImageGroupPayload): TEditPresetImageGroupAction => ({
    type: EDIT_PRESET_IMAGE_GROUP,
    payload
});
