import { defineMessages } from 'react-intl';

export default defineMessages({
    groupName: {
        id: 'PrincipalAttributeDialog.groupName',
        defaultMessage: 'Название',
    },
    groupNotFound: {
        id: 'PrincipalAttributeDialog.groupNotFound',
        defaultMessage: 'Не найдена',
    },
    groupNotFoundPrefix: {
        id: 'PrincipalAttributeDialog.groupNotFoundPrefix',
        defaultMessage: 'Группа с id',
    },
    groupBlocked: {
        id: 'PrincipalAttributeDialog.groupBlocked',
        defaultMessage: 'Заблокирована',
    },
    userLogin: {
        id: 'PrincipalAttributeDialog.userLogin',
        defaultMessage: 'Логин',
    },
    userNotFound: {
        id: 'PrincipalAttributeDialog.userNotFound',
        defaultMessage: 'Не найден',
    },
    userBlocked: {
        id: 'PrincipalAttributeDialog.userBlocked',
        defaultMessage: 'Заблокирован',
    },
    userFIO: {
        id: 'PrincipalAttributeDialog.userFIO',
        defaultMessage: 'ФИО пользователя',
    },
    saveButton: {
        id: 'PrincipalAttributeDialog.saveButton',
        defaultMessage: 'Сохранить',
    },
    cancelButton: {
        id: 'PrincipalAttributeDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    allUsers: {
        id: 'PrincipalAttributeDialog.allUsers',
        defaultMessage: 'Все',
    },
    activeUsers: {
        id: 'PrincipalAttributeDialog.activeUsers',
        defaultMessage: 'Активные',
    },
    blockedUsers: {
        id: 'PrincipalAttributeDialog.blockedUsers',
        defaultMessage: 'Заблокированные',
    },
    deletedUsers: {
        id: 'PrincipalAttributeDialog.deletedUsers',
        defaultMessage: 'Удалённые',
    },
});
