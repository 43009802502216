import { MxCell, MxGraphModel, MxStyleChange, MxEventObject, MxEvent } from './mxgraph';
import { isNullOrUndefined } from 'is-what';

export class BPMMxGraphModel extends MxGraphModel {
    setNewIdToCell(cell: MxCell, newId: string) {
        if (!isNullOrUndefined(newId) && cell.getId() !== newId) {
            delete this.cells[cell.getId()];
            cell.setId(newId);
            this.cells[newId] = cell;
        }
    }

    setStyleManual(cell: MxCell, style: string) {
        const change = new MxStyleChange(this, cell, style);
        change.execute();
        this.currentEdit.add(change);
    }

    endUpdate(undoNeeded: boolean = true) {
        this.updateLevel--;

        if (this.updateLevel == 0) {
            this.fireEvent(new MxEventObject(MxEvent.END_EDIT));
        }

        if (!this.endingUpdate) {
            this.endingUpdate = this.updateLevel == 0;
            this.fireEvent(new MxEventObject(MxEvent.END_UPDATE, 'edit', this.currentEdit));

            try {
                if (this.endingUpdate && !this.currentEdit.isEmpty()) {
                    this.fireEvent(new MxEventObject(MxEvent.BEFORE_UNDO, 'edit', this.currentEdit));
                    const tmp = this.currentEdit;
                    this.currentEdit = this.createUndoableEdit();
                    tmp.notify();
                    if (undoNeeded) this.fireEvent(new MxEventObject(MxEvent.UNDO, 'edit', tmp));
                }
            } finally {
                this.endingUpdate = false;
            }
        }
    }
}
