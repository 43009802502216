import { defineMessages } from 'react-intl';

export default defineMessages({
    commonQuestions: {
        id: 'commonQuestions',
        defaultMessage: ' По вопросам ПО SILA:',
    },
    cooperationIssues: {
        id: 'cooperationIssues',
        defaultMessage: 'По вопросам партнерства:',
    },
});
