import { defineMessages } from 'react-intl';

export default defineMessages({
    setBackground: {
        id: 'TableRenderer.setBackground',
        defaultMessage: 'Фон ячейки',
    },
    insertColumn: {
        id: 'TableRenderer.insertColumn',
        defaultMessage: 'Вставить столбец справа',
    },
    insertRow: {
        id: 'TableRenderer.insertRow',
        defaultMessage: 'Вставить строку ниже',
    },
    deleteColumn: {
        id: 'TableRenderer.deleteColumn',
        defaultMessage: 'Удалить столбец',
    },
    deleteRow: {
        id: 'TableRenderer.deleteRow',
        defaultMessage: 'Удалить строку',
    },
    clearCell: {
        id: 'TableRenderer.clearCell',
        defaultMessage: 'Очистить содержимое ячейки',
    },
    unionCells: {
        id: 'TableRenderer.unionCells',
        defaultMessage: 'Объединить ячейки',
    },
    divideCell: {
        id: 'TableRenderer.divideCell',
        defaultMessage: 'Разделить ячейку',
    },
    headerRow: {
        id: 'TableRenderer.headerRow',
        defaultMessage: 'Строка заголовков',
    },
    sortAZ: {
        id: 'TableRenderer.sortAZ',
        defaultMessage: 'Сортировка от А до Я',
    },
});
