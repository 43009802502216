import * as React from 'react';
import { Input, Table } from 'antd';
import formMessages from '../../../../models/formDefault.messages';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { UserDTO } from '../../../../serverapi/api';
import { Icon } from 'semantic-ui-react';
import adminToolTheme from '../../style/AdminTools.scss';
import tabHeaderTheme from '../../Methodology/components/Presets/Header/TabHeader.scss';
import userMessages from '../../UserManagement/messages/userManagment.messages';
import { SearchOutlined } from '@ant-design/icons';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { toggleArrayItem } from '../../../../utils/toggleArrayItem';
import { TGroupUsersActionProps, TGroupUsersProps } from '../GroupUsers.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TGroupUsersFullProps = TGroupUsersActionProps & TGroupUsersProps & WrappedComponentProps;

type TGroupUsersState = {
    searchInput: string;
    selectedRowKeys: undefined | number[];
};

type TGroupUsersTableRecord = {
    key: number;
    id: number;
    userFIO: string;
    userLogin: string;
};

class GroupUsers extends React.Component<TGroupUsersFullProps, TGroupUsersState> {
    state: TGroupUsersState = {
        searchInput: '',
        selectedRowKeys: undefined,
    };

    getData = ((srcData: UserDTO[]) => {
        const convertedData: UserDTO[] =
            this.state.searchInput === ''
                ? srcData
                : srcData.filter(
                    (item) =>
                        [item.id, item.login, item.lastName, item.firstName, item.middleName]
                            .filter((s) => s)
                            .map((s) => `${s}`.toLowerCase())
                            .filter((s) => s.includes(this.state.searchInput)).length,
                );

        return convertedData.map(
            (item): TGroupUsersTableRecord => ({
                key: item.id,
                id: item.id,
                userLogin: item.login || '',
                userFIO: [item.lastName, item.firstName, item.middleName].filter((s) => s).join(' '),
            }),
        );
    })

    handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== this.state.searchInput) {
            this.setState({
                searchInput: e.target.value.trim().toLowerCase(),
            });
        }
    };

    onSelectChange = (selectedRowKeys: number[]) => {
        let selectedGroups: number[] = selectedRowKeys;

        if (this.state.searchInput !== '' && this.state.selectedRowKeys) {
            selectedGroups = [
                ...this.state.selectedRowKeys.filter(
                    (id) => !this.getData(this.props.groupUsers).some((user) => user.key === id),
                ),
                ...selectedRowKeys,
            ];
        }

        this.setState({
            selectedRowKeys: selectedGroups,
        });
    };

    onRow = (row: TGroupUsersTableRecord) => {
        const newState: number[] = toggleArrayItem<number>(row.key, this.state.selectedRowKeys);

        return {
            onClick: () => this.setState({ selectedRowKeys: newState }),
        };
    };

    renderList(srcData: UserDTO[], selectedRows: any) {
        const { intl } = this.props;
        const maxTableHeight = this.props.wHeight - 324;

        const columns = [
            {
                title: intl.formatMessage(userMessages.userId),
                dataIndex: 'id',
                key: 'id',
                width: 50,
                sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
            },
            {
                title: intl.formatMessage(userMessages.userLogin),
                dataIndex: 'userLogin',
                key: 'userLogin',
                width: 200,
                sorter: (a: { userLogin: string; userFIO: string }, b: { userLogin: string; userFIO: string }) =>
                    sortByAlpha(a.userLogin, b.userLogin),
            },
            {
                title: intl.formatMessage(userMessages.userFIO),
                dataIndex: 'userFIO',
                key: 'userFIO',
                width: 200,
                sorter: (a: { userLogin: string; userFIO: string }, b: { userLogin: string; userFIO: string }) =>
                    sortByAlpha(a.userFIO, b.userFIO),
            },
        ];

        return (
            <Table<TGroupUsersTableRecord>
                className={adminToolTheme.dataTable}
                columns={columns}
                dataSource={this.getData(srcData)}
                pagination={false}
                rowSelection={selectedRows}
                scroll={{ y: maxTableHeight }}
                onRow={this.onRow}
            />
        );
    }

    render() {
        const { onAddClicked, intl, onDeleteClicked, groupUsers, groupId, serverId } = this.props;

        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys && selectedRowKeys.length > 0;

        return (
            <>
                <div className={tabHeaderTheme.searchDiv}>
                    <div className={tabHeaderTheme.searchInput}>
                        <Input
                            data-test="user-group_member-search-input"
                            suffix={<SearchOutlined />}
                            onChange={this.handleSearch}
                        />
                    </div>
                    <div className={tabHeaderTheme.actionButtons}>
                        <Button
                            dataTest="user-managment-group_add-user-to-group"
                            key="ok"
                            size="large"
                            visualStyle="primary"
                            disabled={!groupId}
                            onClick={onAddClicked}
                        >
                            {intl.formatMessage(formMessages.addButtonLabel)}
                        </Button>
                        <Button
                            dataTest="user-managment-group_delete-user-from-group"
                            key="delete"
                            size="large"
                            onClick={() =>
                                selectedRowKeys &&
                                groupId &&
                                onDeleteClicked({ groupId, serverId, usersIds: selectedRowKeys.map((id) => id) })
                            }
                            disabled={!hasSelected}
                        >
                            <Icon type="delete" />
                            {intl.formatMessage(formMessages.deleteButtonLabel)}
                        </Button>
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>{groupUsers && this.renderList(groupUsers, rowSelection)}</div>
            </>
        );
    }
}

export default injectIntl(GroupUsers);
