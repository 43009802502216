import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { TOpenPrincipalServerProfileOwnersEditingTabAction, TSaveInfoOfPrincipalServerProfileOwnersAction } from "../actions/principalServerProfile.actions.types";
import { getServerProfilesData } from "../actions/serverProfile.actions";
import { workspaceAddTab } from "../actions/tabs.actions";
import { OPEN_PRINCIPAL_SERVER_PROFILE_OWNERS_EDITING_TAB, SAVE_INFO_OF_PRINCIPAL_SERVER_PROFILE_OWNERS } from "../actionsTypes/principalServerProfile.actionTypes";
import { EditorMode } from "../models/editorMode";
import { TServerEntity } from "../models/entities.types";
import principalServerProfileMessages from "../models/principalServerProfile.messages";
import { defaultWorkspaceTabActions } from "../models/tab";
import { IWorkspacePrincipalServerProfileTabItemParams, TWorkspaceTab } from "../models/tab.types";
import { WorkSpaceTabTypes } from "../modules/Workspace/WorkSpaceTabTypesEnum";
import { ServerSelectors } from "../selectors/entities/server.selectors";
import { LocalesService } from "../services/LocalesService";
import { AdminToolsUtils } from "../utils/adminToolsUtils";

function* handleOpenPrincipalServerProfileOwnersEditingTab(action: TOpenPrincipalServerProfileOwnersEditingTabAction) {
    const {
        payload: { profileId, serverId },
    } = action;
    const intl = LocalesService.useIntl();
   
    const contentLoadingPageTab: TWorkspaceTab = {
        title: intl.formatMessage(principalServerProfileMessages.principalServerProfile),
        nodeId: AdminToolsUtils.createNodeId(serverId, WorkSpaceTabTypes.PRINCIPAL_SERVER_PROFILE_OWNERS_EDITING_TAB),
        type: WorkSpaceTabTypes.ADMINTOOLS_TAB,
        mode: EditorMode.Read,
        params: {
            closable: false,
            serverId,
            profileId,
        } as IWorkspacePrincipalServerProfileTabItemParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
   
    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* handleSaveInfoOfPrincipalServerProfileOwners(params: TSaveInfoOfPrincipalServerProfileOwnersAction) {
    const {
        payload: { serverId, ids, action, profileId },
    } = params;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
   
    if (action === 'ADD') {
        yield call(() => server.api.principal.addProfile({ profileId, body: { ids } }));
    } else {
        yield all(ids.map(id => call(() => server.api.principal.deleteProfile({ profileId, id }))));
    }
    
    yield put(getServerProfilesData(serverId));
}

export function* principalServerProfileSagaInit() {
    yield takeEvery(OPEN_PRINCIPAL_SERVER_PROFILE_OWNERS_EDITING_TAB, handleOpenPrincipalServerProfileOwnersEditingTab);
    yield takeEvery(SAVE_INFO_OF_PRINCIPAL_SERVER_PROFILE_OWNERS, handleSaveInfoOfPrincipalServerProfileOwners);

}
