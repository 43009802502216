import { defineMessages } from 'react-intl';

export default defineMessages({
    groupId: {
        id: 'groupId',
        defaultMessage: 'ID',
    },
    groupName: {
        id: 'groupName',
        defaultMessage: 'Название',
    },
    description: {
        id: 'description',
        defaultMessage: 'Описание',
    },
    usersCount: {
        id: 'usersCount',
        defaultMessage: 'Количество пользователей',
    },
    addBtnLabel: {
        id: 'groups.addBtnLabel',
        defaultMessage: 'Создать',
    },
    deleteGroupQuestion: {
        id: 'deleteGroupQuestion',
        defaultMessage: 'Удалить группу',
    },
    addUsersToGroup: {
        id: 'addUsersToGroup',
        defaultMessage: 'Добавление пользователей в группу',
    },
    deleteUsersFromGroupTitle: {
        id: 'deleteUsersFromGroupTitle',
        defaultMessage: 'Подтверждение удаления',
    },
    deleteUsersFromGroup: {
        id: 'deleteUsersFromGroup',
        defaultMessage: 'Вы уверены, что хотите удалить пользователей из группы',
    },
    newGroupName: {
        id: 'newGroupName',
        defaultMessage: 'Новая группа',
    },
    groupsSynonyms: {
        id: 'groupsSynonyms',
        defaultMessage: 'Синонимы ID (через точку с запятой)',
    },
    groupsSynonymsDescription: {
        id: 'groupsSynonymsDescription',
        defaultMessage: 'Синонимы используются для сопоставления групп SILA Union с группами LDAP и Keycloak',
    },
});
