import { ExcludeFields, FullModelDefinition, NodeId } from '../../serverapi/api';
import { modelService } from '../ModelService';
import { setServerIdToModelDefinition } from '../../utils/nodeId.utils';

export class LoadModelDAOService {
    public static async loadFullModelData(nodeId: NodeId): Promise<FullModelDefinition> {
        const modelDefinition: FullModelDefinition = await modelService().loadModelDefinition(nodeId, [
            ExcludeFields.modelType,
            ExcludeFields.modelSymbols,
            ExcludeFields.entries,
            ExcludeFields.objectModelConnections,
            ExcludeFields.parentObjectsInfo,
            ExcludeFields.images,
        ]);

        return setServerIdToModelDefinition(modelDefinition, nodeId.serverId);
    }
}
