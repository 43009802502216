/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { RootNodeId } from '../serverapi/api';
import { FileDaoService } from '../services/dao/FileDaoService';
import { TCacheFiles } from './useCacheFileById.types';

/** Загружает и кэширует файлы из папки "Файлы" в навигаторе */
export const useCacheFileById = (fileIdList: string[]) => {

    const [cacheFiles, setCacheFiles] = useState<TCacheFiles>({});

    const connectedServer = useSelector(ServerSelectors.connected)[0];

    useEffect(() => {
        fileIdList.forEach(fileId => {
            if (!cacheFiles[fileId]) {
                FileDaoService.getFileWindowUrl(connectedServer, RootNodeId.FILE_FOLDER_ROOT_ID, fileId)
                    .then(url => setCacheFiles(cacheFiles => ({
                        ...cacheFiles,
                        [fileId]: url
                    })));
            }
        });
    }, [fileIdList]);

    return cacheFiles;
};