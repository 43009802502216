import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { orderSortCompareTreeUml } from '@/models/tree';
import { UML_OBJECT_TYPE, UML_ATTR_CLASS } from '@/mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import { ObjectDefinitionSelectors } from '@/selectors/objectDefinition.selectors';
import { ObjectTypeSelectors } from '@/selectors/objectType.selectors';
import { ObjectDefinitionNode, AttributeValue, ObjectType } from '@/serverapi/api';
import { createSelector } from 'reselect';
import { TUmlClassData } from './ClassSymbol/classSymbol.types';
import { mapAttributeObject, mapReceptionObject, mapOperationObject } from './ClassSymbol/classSymbol.utils';
import { TUmlPackageData } from './PackageSymbol/packageSymbol.types';
import { mapPackageObject, mapClassObject } from './PackageSymbol/packageSymbol.utils';
import { getNestedChildren } from './UMLSymbols.utils';
import { UML_ATTR_PACKAGE } from './PackageSymbol/packageSymbol.constants';

export const getDefinitionWithChildren = (parent: ObjectDefinitionImpl, nestingDepth: number) =>
    createSelector(
        ObjectDefinitionSelectors.byServerIdRepositoryId(parent.nodeId.serverId, parent.nodeId.repositoryId),
        (objectDefinitions) => {
            const nesting = typeof nestingDepth === 'number' ? nestingDepth : 0;

            return (
                objectDefinitions && {
                    ...parent,
                    children: getNestedChildren(parent, objectDefinitions, nesting),
                }
            );
        },
    );

// TODO remove ??
export const getRootDefinition = (objectDefinition: ObjectDefinitionImpl) =>
    createSelector(
        ObjectDefinitionSelectors.byServerIdRepositoryId(
            objectDefinition.nodeId.serverId,
            objectDefinition.nodeId.repositoryId,
        ),
        (objectDefinitions) => {
            if (!objectDefinition?.parentNodeId?.id) return null;

            let currentParent = objectDefinition;

            while (currentParent?.parentNodeId?.id && objectDefinitions[currentParent.parentNodeId.id]) {
                currentParent = objectDefinitions[objectDefinition?.parentNodeId?.id];
            }

            return currentParent;
        },
    );

const sortFn = (objectDefinition: ObjectDefinitionNode) => orderSortCompareTreeUml(objectDefinition?.childrenIdOrder);

export const getUmlClassDefinitionData = (objectDefinition: ObjectDefinitionImpl, presetId: string) =>
    createSelector(
        getDefinitionWithChildren(objectDefinition, 2),
        ObjectTypeSelectors.byIds({
            objectTypeIds: [
                UML_OBJECT_TYPE.ATTRIBUTE,
                UML_OBJECT_TYPE.RECEPTION,
                UML_OBJECT_TYPE.METHOD,
                UML_OBJECT_TYPE.PARAMETER,
            ],
            presetId,
            serverId: objectDefinition.nodeId.serverId,
        }),
        (
            {
                name,
                attributes,
                children,
            }: {
                name: string;
                attributes: Array<AttributeValue>;
                children: Array<ObjectDefinitionImpl>;
                childrenIdOrder?: string[];
            },
            objectTypes: ObjectType[] = [],
        ): TUmlClassData => {
            const sorter = sortFn(objectDefinition);

            return {
                name,
                stereotype: attributes?.find((el) => el.typeId === UML_ATTR_CLASS.STEREOTYPE)?.value || '',
                attributes: children
                    ?.filter((el) => el.objectTypeId === UML_OBJECT_TYPE.ATTRIBUTE)
                    .sort(sorter)
                    .map(mapAttributeObject(objectTypes)),
                receptions: children
                    ?.filter((el) => el.objectTypeId === UML_OBJECT_TYPE.RECEPTION)
                    .sort(sorter)
                    .map(mapReceptionObject(objectTypes)),
                operations: children
                    ?.filter((el) => el.objectTypeId === UML_OBJECT_TYPE.METHOD)
                    .sort(sorter)
                    .map(
                        (method) =>
                            ({
                                ...method,
                                children: method.children?.sort(sortFn(method)),
                            } as ObjectDefinitionImpl),
                    )
                    .map(mapOperationObject(objectTypes)),
            };
        },
    );

export const getUmlPackageDefinitionData = (objectDefinition: ObjectDefinitionImpl) =>
    createSelector(
        getDefinitionWithChildren(objectDefinition, 1),
        ({
            name,
            attributes,
            children,
        }: {
            name: string;
            attributes: Array<AttributeValue>;
            children: Array<ObjectDefinitionImpl>;
        }): TUmlPackageData => {
            const sorter = sortFn(objectDefinition);

            return {
                name,
                stereotype: attributes?.find((el) => el.typeId === UML_ATTR_PACKAGE.STEREOTYPE)?.value || '',
                packages: children
                    ?.filter((el) => el.objectTypeId === UML_OBJECT_TYPE.PACKAGE)
                    .sort(sorter)
                    .map(mapPackageObject),
                classes: children
                    ?.filter((el) => el.objectTypeId === UML_OBJECT_TYPE.CLASS)
                    .sort(sorter)
                    .map(mapClassObject),
            };
        },
    );
