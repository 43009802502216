import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import messages from './MatrixEdgesListDialog.messages';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import theme from './MatrixEdgesListDialog.scss';

type TConfirmationDialogProps = {
    open: boolean;
    id: string;
    onDelete: () => void;
    onCancel: () => void;
};

export const ConfirmationDialog: FC<TConfirmationDialogProps> = ({ open, id, onCancel, onDelete }) => {
    const intl = useIntl();

    const footer = (
        <div>
            <Button key="cancel" size="large" onClick={onCancel}>
                {intl.formatMessage(messages.cancel)}
            </Button>
            <Button key="delete" size="large" danger onClick={onDelete}>
                {intl.formatMessage(messages.delete)}
            </Button>
        </div>
    );

    return (
        <Dialog
            onCancel={onCancel}
            onOk={onDelete}
            title={intl.formatMessage(messages.deleteDefinition)}
            footer={footer}
            open={open}
        >
            {intl.formatMessage(messages.deleteMessage)} <span className={theme.id}>{id}</span>
        </Dialog>
    );
};
