import {
    REPORT_MODEL_TYPE_ADD_ATTRIBUTE_TYPES,
    CHANGE_REPORT_MODEL_TYPE_DESCRIPTION,
    CHANGE_REPORT_MODEL_TYPE_ENABLE_MODEL,
    CHANGE_REPORT_MODEL_TYPE_ID,
    CHANGE_REPORT_MODEL_TYPE_NAME,
    ADD_STORE_EDIT_REPORT_MODEL_TYPE_WORKSPACE_TAB,
    REPORT_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES,
    REPORT_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS,
    CHANGE_REPORT_MODEL_TYPE_ALLOW_APPROVALS,
    CHANGE_REPORT_MODEL_TYPE_GROUP,
} from '../../actionsTypes/workspaceTab/editReportModelTypeWorkspaceTab.actionTypes';
import {
    TAddAttributeTypesAction,
    TAddAttributeTypesPayload,
    TChangeReportModelTypeDescriptionAction,
    TChangeReportModelTypeDescriptionPayload,
    TChangeReportModelTypeEnableModelAction,
    TChangeReportModelTypeEnableModelPayload,
    TChangeReportModelTypeIdAction,
    TChangeReportModelTypeIdPayload,
    TChangeReportModelTypeNameAction,
    TChangeReportModelTypeNamePayload,
    TRemoveAttributeTypesAction,
    TRemoveAttributeTypesGroupsAction,
    TRemoveAttributeTypesGroupsPayload,
    TRemoveAttributeTypesPayload,
    TAddStoreEditReportModelTypeWorkspaceTabAction,
    TAddStoreEditreportModelTypeWorkspaceTabPayload,
    TChangeReportModelTypeAllowApprovalsPayload,
    TChangeModelTypeAllowApprovalsAction,
    TChangeReportModelTypeGroupAction,
    TChangeReportModelTypeGroupPayload,
} from './editReportModelTypeWorkspaceTab.actions.types';

export const addStoreEditReportModelTypeWorkspaceTab = (
    payload: TAddStoreEditreportModelTypeWorkspaceTabPayload,
): TAddStoreEditReportModelTypeWorkspaceTabAction => ({
    type: ADD_STORE_EDIT_REPORT_MODEL_TYPE_WORKSPACE_TAB,
    payload,
});

export const changeReportModelTypeName = (
    payload: TChangeReportModelTypeNamePayload,
): TChangeReportModelTypeNameAction => ({
    type: CHANGE_REPORT_MODEL_TYPE_NAME,
    payload,
});

export const changeReportModelTypeId = (payload: TChangeReportModelTypeIdPayload): TChangeReportModelTypeIdAction => ({
    type: CHANGE_REPORT_MODEL_TYPE_ID,
    payload,
});

export const changeReportModelTypeDescription = (
    payload: TChangeReportModelTypeDescriptionPayload,
): TChangeReportModelTypeDescriptionAction => ({
    type: CHANGE_REPORT_MODEL_TYPE_DESCRIPTION,
    payload,
});

export const changeReportModelTypeGroup = (
    payload: TChangeReportModelTypeGroupPayload,
): TChangeReportModelTypeGroupAction => ({
    type: CHANGE_REPORT_MODEL_TYPE_GROUP,
    payload,
});

export const changeReportModelTypeEnableModel = (
    payload: TChangeReportModelTypeEnableModelPayload,
): TChangeReportModelTypeEnableModelAction => ({
    type: CHANGE_REPORT_MODEL_TYPE_ENABLE_MODEL,
    payload,
});

export const reportModelTypeAddAttributeTypes = (payload: TAddAttributeTypesPayload): TAddAttributeTypesAction => ({
    type: REPORT_MODEL_TYPE_ADD_ATTRIBUTE_TYPES,
    payload,
});

export const reportModelTypeRemoveAttributeTypes = (
    payload: TRemoveAttributeTypesPayload,
): TRemoveAttributeTypesAction => ({
    type: REPORT_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES,
    payload,
});

export const reportModelTypeRemoveAttributeTypesGroups = (
    payload: TRemoveAttributeTypesGroupsPayload,
): TRemoveAttributeTypesGroupsAction => ({
    type: REPORT_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS,
    payload,
});

export const changeReportModelTypeAllowApprovals = (
    payload: TChangeReportModelTypeAllowApprovalsPayload,
): TChangeModelTypeAllowApprovalsAction => ({
    type: CHANGE_REPORT_MODEL_TYPE_ALLOW_APPROVALS,
    payload,
});
