import { ObjectType, ObjectTypeGroup } from '../../serverapi/api';
import { apiBundle } from '../api/api-bundle';

export class ObjectTypesDaoService {

    public static async createObjectType(
        serverId: string,
        objectType: ObjectType,
    ): Promise<void> {
        await apiBundle(serverId).objectType.create({ body: objectType });
    }

    public static async getObjectTypeById(
        serverId: string,
        presetId: string,
        objectTypeId: string,
    ): Promise<ObjectType> {
        const givenObjectType: ObjectType = await apiBundle(serverId).objectType.byId({ presetId, objectTypeId });

        return givenObjectType;
    }

    public static async setObjectTypeBulkSave(serverId: string, objectTypes: ObjectType[]): Promise<void> {
        await apiBundle(serverId).objectType.bulkSave({ body: objectTypes });
    }

    public static async objectTypeBulkDelete(serverId: string, objectTypes: ObjectType[]): Promise<void> {
        await apiBundle(serverId).objectType.bulkDelete({ body: objectTypes });
    }


    public static async objectTypeGroupBulkDelete(
        serverId: string,
        objectTypeGroups: ObjectTypeGroup[],
    ): Promise<void> {
        await apiBundle(serverId).objectTypeGroup.bulkDelete({ body: objectTypeGroups });
    }

    public static async deleteObjectTypesAndGroupsRequest(
        serverId,
        objectTypes: ObjectType[],
        objectTypeGroups: ObjectTypeGroup[],
    ): Promise<void> {
        await this.objectTypeBulkDelete(serverId, objectTypes);
        await this.objectTypeGroupBulkDelete(serverId, objectTypeGroups);
    }

    public static async getGivenObjectTypeGroup(
        serverId: string,
        presetId: string,
        objectTypeGroupId: string,
    ): Promise<ObjectTypeGroup> {
        const givenObjectTypeGroup = await apiBundle(serverId).objectTypeGroup.get({ presetId, objectTypeGroupId });

        return givenObjectTypeGroup;
    }

    public static async setObjectTypeGroupBulkSave(
        serverId: string,
        objectTypeGroups: ObjectTypeGroup[],
    ): Promise<void> {
        await apiBundle(serverId).objectTypeGroup.bulkSave({ body: objectTypeGroups });
    }

    public static async createObjectTypeGroup(
        serverId: string,
        objectTypeGroup: ObjectTypeGroup,
    ): Promise<void> {
        await apiBundle(serverId).objectTypeGroup.create({ body: objectTypeGroup });
    }
}
