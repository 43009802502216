import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { workspaceRemoveTabRequest } from '../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { IWorkspacePrincipalServerProfileTabItemParams, TWorkspaceTab } from '../../../../models/tab.types';
import { GroupsSelectors } from '../../../../selectors/groups.selectors';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { fetchAllGroupsRequest } from '../../../../actions/groups.actions';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { fetchUsersByGroupIdRequest } from '../../../../actions/users.actions';
import { TFetchUsersByGroupIdPayload } from '../../../../actions/users.actions.types';
import { adminToolSetParams } from '../../../../actions/admintools.actions';
import { withWindowResize } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import { TServerProfilesParams, TAdminToolsParams, TAdminToolsParamsKeys } from '../../data/admintool.types';
import GroupsAndUsersPrivilegeEditingTabComponents from '../../GroupsAndUsersPrivilegeEditingTab/GroupsAndUsersPrivilegeEditingTab.components';
import {
    TGUPrivilegeEditingTabProps,
    TGUPrivilegeEditingTabActionProps,
} from '../../GroupsAndUsersPrivilegeEditingTab/GroupsAndUsersPrivilegeEditingTab.components.types';
import { saveInfoOfPrincipalServerProfileOwners } from '../../../../actions/principalServerProfile.actions';
import { TSaveInfoOfPrincipalServerProfileOwnersPayload } from '../../../../actions/principalServerProfile.actions.types';
import { PrincipalServerProfileSelectors } from '../../../../selectors/principalServerProfile.selectors';
import { ServerProfileSelectors } from '../../../../selectors/serverProfile.selectors';

const mapStateToProps = (state: TRootState): Partial<TGUPrivilegeEditingTabProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const allGroups = GroupsSelectors.getAll(serverId)(state);
    const params: TServerProfilesParams | undefined = MetaDataSelectors.getParams(
        TAdminToolsParamsKeys.SERVER_PROFILES,
    )(state);
    const groupClicked = (params && params.groupId) || (allGroups && allGroups.length ? allGroups[0].id : undefined);
    const usersInGroup = groupClicked ? UsersSelectors.byGroupId(serverId, groupClicked)(state) : [];
    const nodeId = TabsSelectors.getActiveTabId(state);
    const tab = TabsSelectors.byId(nodeId)(state);
    const tabParam = tab?.params as IWorkspacePrincipalServerProfileTabItemParams;
    const owners = PrincipalServerProfileSelectors.getOwnersByProfileId(serverId, tabParam?.profileId)(state);
    const serverProfiles = ServerProfileSelectors.getAllServerProfilesByServerId(serverId)(state);
    const editingProfile = serverProfiles.find((p) => p.id === tabParam?.profileId);

    return {
        tab,
        allGroups,
        usersInGroup,
        serverId,
        groupClicked,
        owners,
        addonParamToSave: { profileId: tabParam?.profileId },
        toolBarParams: {
            type: 'principalServerProfileOwners',
            payload: { profileName: editingProfile?.name },
        },
    };
};

const mapDispatchToProps: (dispatch) => Partial<TGUPrivilegeEditingTabActionProps> = (dispatch) => ({
    onClose: (tab: TWorkspaceTab) => dispatch(workspaceRemoveTabRequest(tab)),
    fetchAllGroups: (serverId: string) => dispatch(fetchAllGroupsRequest(serverId)),
    groupItemClicked: (groupClicked: number) =>
        dispatch(adminToolSetParams({ serverProfiles: { groupId: groupClicked } } as TAdminToolsParams)),
    fetchUsersByGroup: (payload: TFetchUsersByGroupIdPayload) => dispatch(fetchUsersByGroupIdRequest(payload)),
    onSaveChanges: (payload: TSaveInfoOfPrincipalServerProfileOwnersPayload) =>
        dispatch(saveInfoOfPrincipalServerProfileOwners(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withWindowResize(GroupsAndUsersPrivilegeEditingTabComponents));
