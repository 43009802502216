export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';

export const OPEN_SEARCH_PATH_DIALOG = 'OPEN_SEARCH_PATH_DIALOG';

export const SET_SEARCH_PATH_ELEMENT = 'SET_SEARCH_PATH_ELEMENT';

export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';

export const OPEN_MODEL_ON_CANVAS = 'OPEN_MODEL_ON_CANVAS';

export const RESET_SEARCH_DATA = 'RESET_SEARCH_DATA';

export const SET_SEARCH_RULES = 'SET_SEARCH_RULES';

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';

export const SET_SEARCH_NODE_TYPES = 'SET_SEARCH_NODE_TYPES';

export const SET_SEARCH_VISIBILITY = 'SET_SEARCH_VISIBILITY';

export const SET_ROOT_SEARCH_NODE_ID = 'SET_ROOT_SEARCH_NODE_ID';

export const OPEN_SEARCH_IN_DIALOG = 'OPEN_SEARCH_IN_DIALOG';


