import { MATRIX_MODEL_TYPE_REQUEST_SUCCESS } from '@/actionsTypes/matrixModelType.actionTypes';
import {
    TMatrixModelTypeRequestSuccessAction,
    TMatrixModelTypeRequestSuccessPayload,
} from './matrixModelType.actions.types';

export const matrixModelTypeRequestSuccess = (
    payload: TMatrixModelTypeRequestSuccessPayload,
): TMatrixModelTypeRequestSuccessAction => ({
    type: MATRIX_MODEL_TYPE_REQUEST_SUCCESS,
    payload,
});
