import { EdgeType, ModelEdgeDefinition, ModelType, Symbol } from '../../serverapi/api';
import { find } from 'lodash-es';
import { TPresetTypesAclList } from '../../reducers/userProfile.reducer.types';
import { nvlAcl } from './ProfileBllService';

export class AvailableConnectionForSymbolsBLLService {
    public static getSymbolsForConnection(
        modelType: ModelType,
        sourceSymbolId: string | undefined,
        sourceObjectTypeId: string | undefined,
        symbolAcls?: TPresetTypesAclList,
        edgeTypeAcls?: TPresetTypesAclList,
    ): Symbol[] {
        try {
            const availableSymbolsEdges: Symbol[] = [];
            
            modelType.symbols.forEach((targetSymbol: Symbol) => {
                const edgeTypes = this.getEdgeTypes(
                    modelType,
                    sourceSymbolId,
                    targetSymbol.id,
                    sourceObjectTypeId,
                    targetSymbol.objectType,
                    edgeTypeAcls,
                );
                const hasEdgeType = edgeTypes ? edgeTypes[0] : false;
                if (
                    !availableSymbolsEdges.includes(targetSymbol) &&
                    nvlAcl(undefined, symbolAcls?.[targetSymbol.id]).create &&
                    hasEdgeType
                ) {
                    availableSymbolsEdges.push(targetSymbol);
                }
            });

            return availableSymbolsEdges;
        } catch (e) {
            return [];
        }
    }

    public static getEdgeTypes(
        modelType: ModelType,
        sourceSymbolId?: string,
        destinationSymbolId?: string,
        sourceObjTypelId?: string,
        destinationObjTypeId?: string,
        edgeTypeAcls?: TPresetTypesAclList,
    ): EdgeType[] | undefined {
        try {
            const { modelEdgeDefinitions } = modelType;
            const { edgeTypes } = modelType;
            let foundMed: ModelEdgeDefinition[] = [];

            foundMed = modelEdgeDefinitions?.filter(
                (med: ModelEdgeDefinition) =>
                    (med.anySourceAllowed ||
                        (med.source && med.source === sourceSymbolId) ||
                        (med.sourceObject && med.sourceObject === sourceObjTypelId)) &&
                    (med.anyTargetAllowed ||
                        (med.destination && med.destination === destinationSymbolId) ||
                        (med.destinationObject && med.destinationObject === destinationObjTypeId)),
            );

            return edgeTypes?.filter(
                (edgeType: EdgeType) =>
                    edgeType !== null &&
                    find(foundMed, (med) => med.edgeType === edgeType.id) &&
                    nvlAcl(undefined, edgeTypeAcls?.[edgeType.id]).create,
            );
        } catch (e) {
            return undefined;
        }
    }
}
