// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheckableListRenderer__checkbox___DuF6{position:absolute;top:0;bottom:0;z-index:1;cursor:default}.CheckableListRenderer__text__QBhdp{padding-left:1.5em}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/RichTextEditor/Editor/renderers/CheckableList/CheckableListRenderer.scss"],"names":[],"mappings":"AAAA,wCACI,iBAAA,CACA,KAAA,CACA,QAAA,CACA,SAAA,CACA,cAAA,CAGJ,oCACI,kBAAA","sourcesContent":[".checkbox {\r\n    position: absolute;\r\n    top: 0;\r\n    bottom: 0;\r\n    z-index: 1;\r\n    cursor: default;\r\n}\r\n\r\n.text {\r\n    padding-left: 1.5em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "CheckableListRenderer__checkbox___DuF6",
	"text": "CheckableListRenderer__text__QBhdp"
};
export default ___CSS_LOADER_EXPORT___;
