import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './UserProfileDialog.messages';
import { MultiLangInputDialog } from '../../MultiLangInputDialog/MultiLangInputDialog.component';
import { InternationalString } from '../../../serverapi/api';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { addServerProfile } from '../../../actions/serverProfile.actions';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { MetaDataSelectors } from '../../../selectors/admintools.selectors';
import { Form } from 'antd';
import { LocalesService } from '../../../services/LocalesService';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TAddUserProfileDialogProps = {
    open: boolean;
};

const AddUserProfileDialog: FC<TAddUserProfileDialogProps> = (props) => {
    const { open } = props;
    const intl = useIntl();
    const [generalForm] = Form.useForm();
    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);
    const serverId = useSelector(MetaDataSelectors.getCurrentServerId);
    const [multilingualName, setMultilingualName] = useState<InternationalString>({});
    const onHandleCancelClick = () => {
        dispatch(closeDialog(DialogType.ADD_USER_PROFILE));
    };
    const onHandleOkClick = () => {
        generalForm.validateFields().then(() => {
            const serverProfile = {
                name: LocalesService.internationalStringToString(multilingualName, currentLocale),
                multilingualName,
                id: uuid(),
            };
            dispatch(
                addServerProfile({
                    serverProfile,
                    serverId,
                }),
            );
        });
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onHandleCancelClick,
                    value: intl.formatMessage(messages.profileCancelButton),
                },
                {
                    key: 'ok',
                    onClick: onHandleOkClick,
                    value: intl.formatMessage(messages.profileConfirmButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onCancel={onHandleCancelClick}
            title={intl.formatMessage(messages.profileName)}
            open={open}
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
            onOk={onHandleOkClick}
        >
            <Form form={generalForm} layout="vertical">
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.profileNamePlaceholder)}
                    multiLangValue={multilingualName}
                    data-test="profile-form_profile-name-input"
                    onChange={setMultilingualName}
                    label={intl.formatMessage(messages.profileNameLabel)}
                    mainInputName="multilingualName"
                    generalForm={generalForm}
                    required
                    autoFocus
                />
            </Form>
        </Dialog>
    );
};

export { AddUserProfileDialog };
