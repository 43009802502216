import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'AttributeType.name',
        defaultMessage: 'Наименование',
    },
    format: {
        id: 'AttributeType.format',
        defaultMessage: 'Формат',
    },
    addAttributes: {
        id: 'AttributeType.addAttributes',
        defaultMessage: 'Добавить тип атрибута',
    },
    ok: {
        id: 'AttributeType.ok',
        defaultMessage: 'Добавить',
    },
    cancel: {
        id: 'AttributeType.cancel',
        defaultMessage: 'Отмена',
    },
    deleteAttributesDialogTitle: {
        id: 'AttributeType.deleteAttributesDialogTitle',
        defaultMessage: 'Удалить выбранные типы атрибутов?',
    },
    deleteAttributeGroups: {
        id: 'AttributeType.deleteAttributeGroups',
        defaultMessage: 'Будут удалены группы типов атрибутов',
    },
    deleteAttributes: {
        id: 'AttributeType.deleteAttributes',
        defaultMessage: 'Будут удалены типы атрибутов',
    },
    clearAttributeDefaultValue: {
        id: 'AttributeType.clearAttributeDefaultValue',
        defaultMessage: 'Удалить значение атрибута по умолчанию?',
    },
    selectDateAndTime: {
        id: 'AttributeType.selectDateAndTime',
        defaultMessage: 'Выберите дату и время',
    },
    selectDate: {
        id: 'AttributeType.selectDate',
        defaultMessage: 'Выберите дату',
    },
    edit: {
        id: 'AttributeType.edit',
        defaultMessage: 'Редактировать',
    }
});
