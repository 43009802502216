import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import saveIcon from '@/resources/icons/save.svg';
import messages from '../../../../messages/CommonToolbar.messages';
import { NodeId } from '../../../../../../serverapi/api';
import { useDispatch } from 'react-redux';
import { saveModel } from '@/actions/save.actions';
import { ToolbarButton } from '@/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/ToolbarButton/ToolbarButton.component';

interface ISaveButtonProps {
    disable: boolean | undefined;
    nodeId: NodeId;
}

export const SaveButton: FC<ISaveButtonProps> = (props) => {
    const { disable, nodeId } = props;
    const intl = useIntl();

    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(
            saveModel({
                graphId: nodeId,
                attempts: 1,
                showNotification: true,
                forceSave: true,
                forceSaveHistory: true,
            }),
        );
    };

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            // ctrl + s
            if ((e.metaKey || e.ctrlKey) && (e.code === 'KeyS')) {
                e.preventDefault();
                e.stopPropagation();

                if (nodeId.id !== 'homePageTab' && !disable) {
                    dispatch(
                        saveModel({
                            graphId: nodeId,
                            showNotification: true,
                            forceSave: true,
                            attempts: 1,
                            forceSaveHistory: true,
                        }),
                    );
                }
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [nodeId, disable]);

    return (
        <ToolbarButton
            disabled={disable}
            icon={saveIcon}
            tooltipTitle={intl.formatMessage(messages.save)}
            dataTest="general-toolbar_save-button"
            onClick={onClick}
        />
    );
};
