import React, { FC, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '../../config/config';
import { FolderType, PresetImage } from '../../serverapi/api';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import { TreeItemType } from '../Tree/models/tree';
import { LocalesService } from '../../services/LocalesService';
import icTreeFolder from '../../resources/icons/ic-tree-folder.svg';
import theme from './CommonCreateDialog.scss';
import { Icon } from '../UIKit/components/Icon/Icon.component';
import { DialogFooterButtons } from '../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TCommonCreateDialogProps = {
    type: TreeItemType;
    title: string;
    cancelText: string;
    placeholder: string;
    // labels: Record<'name' | 'folderType', React.ReactNode> | Record<'name', React.ReactNode>;
    labelName: React.ReactNode;
    labelFolderType?: React.ReactNode;
    requiredErrorMsg: string;
    folderTypes?: FolderType[];
    okText: string;
    inputDataTestId?: string;
    presetImages?: PresetImage[];
    onClose: () => void;
    onSubmit: (name: string, folderTypeId: string) => void;
};

type TFormValues = {
    field: string;
    folderTypeId: string;
};

export const CommonCreateDialog: FC<TCommonCreateDialogProps> = (props) => {
    const {
        type,
        labelName,
        title,
        cancelText,
        okText,
        requiredErrorMsg,
        folderTypes = [],
        placeholder,
        inputDataTestId,
        onSubmit,
        onClose,
        labelFolderType,
        presetImages,
    } = props;
    const isFolder = type === TreeItemType.Folder;
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(false);
    const currentLocale = useSelector(getCurrentLocale);

    const handleSubmit = () => {
        form.validateFields()
            .then((values: TFormValues) => {
                setDisabled(true);
                onSubmit(values.field.trim(), values.folderTypeId);
            })
            .catch(() => undefined);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: cancelText,
                    dataTest: `create-element_${type}_cancel-button`,
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: okText,
                    visualStyle: 'primary',
                    disabled: disabled,
                    dataTest: `create-element_${type}_save-button`,
                },
            ]}
        />
    );

    return (
        <Dialog onOk={handleSubmit} onCancel={onClose} title={title} open width={DEFAULT_DIALOG_WIDTH} footer={footer}>
            <Form form={form} layout="vertical" initialValues={{ folderTypeId: folderTypes[0]?.id }}>
                <Form.Item
                    label={labelName}
                    rules={[{ whitespace: true, required: true, message: requiredErrorMsg }]}
                    name="field"
                >
                    <Input placeholder={placeholder} data-test={inputDataTestId} autoComplete="off" autoFocus />
                </Form.Item>
                {isFolder && (
                    <Form.Item label={labelFolderType} name="folderTypeId">
                        <Select>
                            {folderTypes.map(({ id, multilingualName, graphical }) => {
                                const folderIcon: string | undefined = presetImages?.find(
                                    (icon) => icon.id === graphical,
                                )?.graphical;

                                return (
                                    <Select.Option key={id} value={id}>
                                        {folderIcon ? (
                                            <img className={theme.folderIcon} src={folderIcon} alt="" />
                                        ) : (
                                            <Icon className={theme.svgIcon} spriteSymbol={icTreeFolder} />
                                        )}
                                        {LocalesService.internationalStringToString(multilingualName, currentLocale)}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                )}
            </Form>
        </Dialog>
    );
};
