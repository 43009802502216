import React from 'react';
import messages from './FooterButtons.messages';
import theme from './FooterButtons.scss';
import { showDeleteConfirmation } from '../AdminTools/Button/DeleteButton';
import { Button } from '../UIKit/components/Button/Button.component';
import { TButtonProps } from '../UIKit/components/Button/Button.types';

type TFooterButtonsProps = {
    buttons: TButtonProps[];
    deleteQuestion?: string;
};

export const FooterButtons = (props: TFooterButtonsProps) => {
    const { buttons, deleteQuestion } = props;

    return (
        <div className={theme.generalButtons}>
            {buttons.map((button) => {
                const onClick = (event: React.SyntheticEvent<HTMLElement>) => {
                    if (button.children === messages.remove.id) {
                        showDeleteConfirmation({
                                  onDelete: (e) => button.onClick && button.onClick(e),
                                  deleteQuestion,
                              })
                    } else if (button.onClick) {
                        button.onClick(event);
                    }
                };

                return (
                    <Button
                        size={button.size}
                        visualStyle={button.visualStyle}
                        key={button.key}
                        danger={button.danger}
                        disabled={button.disabled}
                        tooltip={button.tooltip}
                        onClick={onClick}
                        dataTest={`${button.dataTest}${button.disabled ? '_disabled' : ''}`}
                    >
                        {button.children}
                    </Button>
                );
            })}
        </div>
    );
};
