// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon__container__PboBS{width:16px;height:16px;border-radius:0}.Icon__iconDisabled___OC2u{-webkit-filter:grayscale(1) opacity(0.4);filter:grayscale(1) opacity(0.4)}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Icon/Icon.scss"],"names":[],"mappings":"AAAA,wBACI,UAAA,CACA,WAAA,CACA,eAAA,CAGJ,2BACI,wCAAA,CAAA,gCAAA","sourcesContent":[".container {\r\n    width: 16px;\r\n    height: 16px;\r\n    border-radius: 0;\r\n}\r\n\r\n.iconDisabled {\r\n    filter: grayscale(1) opacity(0.4);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Icon__container__PboBS",
	"iconDisabled": "Icon__iconDisabled___OC2u"
};
export default ___CSS_LOADER_EXPORT___;
