import type { PresetImage } from '../../../../../../serverapi/api';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import messages from './AttributeImageSelector.messages';
import cx from 'classnames';
import theme from './AttributeImageSelector.scss';
import { LocalesService } from '../../../../../../services/LocalesService';

type TAttributeImageSelectorProps = {
    imageId?: string;
    images: PresetImage[];
    disabled?: boolean;
    onChange: (imageId: string) => void;
    hasPreview?: boolean;
};

export const AttributeImageSelector = ({
    imageId,
    images,
    disabled,
    onChange,
    hasPreview = true,
}: TAttributeImageSelectorProps) => {
    const intl = useIntl();
    const selectedImage = useMemo(() => images.find((image) => image.id === imageId), [images, imageId]);
    const locale = LocalesService.getLocale();

    return (
        <div className={cx(hasPreview && theme.container)}>
            {!!images.length && (
                <>
                    {hasPreview && (
                        <div className={cx(theme.preview, !selectedImage?.id ? theme.required : '')}>
                            {selectedImage && <img src={selectedImage.graphical} />}
                        </div>
                    )}
                    <Select
                        data-test="open-list_select-attribute-icon"
                        popupClassName={theme.attributeImageSelectDropDown}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder={hasPreview ? intl.formatMessage(messages.placeholder) : null}
                        value={selectedImage?.id}
                        onChange={onChange}
                        className={theme.select}
                        disabled={disabled}
                    >
                        {images.map((image, i) => (
                            <Select.Option
                                data-test={`select-icon_${image.id}`}
                                value={image.id}
                                label={image.multilingualName[locale]}
                                key={`image-selector-item_${image.id}-${i}`}
                            >
                                <div className={theme.optionContnent}>
                                    <img src={image.graphical} />
                                    {hasPreview ? <span>{image.multilingualName[locale]}</span> : null}
                                </div>
                            </Select.Option>
                        ))}
                    </Select>
                </>
            )}
            {!images.length && <div>{intl.formatMessage(messages.noImages)}</div>}
        </div>
    );
};
