import type { NodeId } from 'src/serverapi/api';
import type {
    TOpenFloatingAttributesDialogPayload,
    TUpdateAllCellsOverlaysAction,
    TUpdateCellsOverlaysAction,
    TUpdateCellsOverlaysPayload,
} from './overlay.actions.types';
import {
    OVERLAY_CELLS_UPDATE,
    OVERLAY_DEFINITION_UPDATE,
    OVERLAY_EDIT_DIALOG_OPEN,
    UPDATE_ALL_CELLS_OVERLAYS,
} from '../actionsTypes/overlay.actionTypes';

export const updateDefinitionOverlay = (payload: NodeId) => ({
    type: OVERLAY_DEFINITION_UPDATE,
    payload,
});

export const updateCellsOverlays = (payload: TUpdateCellsOverlaysPayload): TUpdateCellsOverlaysAction => ({
    type: OVERLAY_CELLS_UPDATE,
    payload,
});

export const updateAllCellsOverlays = (graphId: NodeId): TUpdateAllCellsOverlaysAction => ({
    type: UPDATE_ALL_CELLS_OVERLAYS,
    payload: { graphId },
});

export const openFloatingAttributesDialog = (payload: TOpenFloatingAttributesDialogPayload) => ({
    type: OVERLAY_EDIT_DIALOG_OPEN,
    payload,
});
