export enum ServerErrorType {
    SERVER_ERROR = 500,
    ACCESS_DENIED_BY_PROFILE = 451,
    DUPLICATE_ENTITY = 409,
    OBJECT_STALE = 406,
    PAYMENT_REQUIRED = 402,
    LOCKED = 423,
    FORBIDDEN = 403,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    BAD_REQUEST = 400,
}
