import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'ImportVisio.dialog.dbConflict.title',
        defaultMessage: 'Обнаружены существующие объекты с импортируемыми именем и типом',
    },
    cancelText: {
        id: 'ImportVisio.dialog.dbConflict.cancelText',
        defaultMessage: 'Отменить импорт',
    },
    newName: {
        id: 'ImportVisio.dialog.dbConflict.newName',
        defaultMessage: 'Новое имя',
    },
    saveOptions: {
        id: 'ImportVisio.dialog.dbConflict.saveOptions',
        defaultMessage: 'Применить выбор для всех конфликтов',
    },
    conflictObject: {
        id: 'ImportVisio.dialog.dbConflict.conflictObject',
        defaultMessage: 'Объект',
    },
});
