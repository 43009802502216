import { defineMessages } from 'react-intl';

export default defineMessages({
    userAccountTab: {
        id: 'UserAccount.userAccountTab',
        defaultMessage: 'Личный кабинет',
    },
    mainTab: {
        id: 'UserAccount.mainTab',
        defaultMessage: 'Главная',
    },
    securityTab: {
        id: 'UserAccount.securityTab',
        defaultMessage: 'Безопасность',
    },
    login: {
        id: 'UserAccount.login',
        defaultMessage: 'Логин',
    },
    firstName: {
        id: 'UserAccount.firstName',
        defaultMessage: 'Имя',
    },
    lastName: {
        id: 'UserAccount.lastName',
        defaultMessage: 'Фамилия',
    },
    middleName: {
        id: 'UserAccount.middleName',
        defaultMessage: 'Отчество',
    },
    
    email: {
        id: 'UserAccount.email',
        defaultMessage: 'Почта',
    },
    company: {
        id: 'UserAccount.company',
        defaultMessage: 'Компания',
    },
    department: {
        id: 'UserAccount.department',
        defaultMessage: 'Отдел',
    },
    position: {
        id: 'UserAccount.position',
        defaultMessage: 'Должность',
    },
    changePassword: {
        id: 'UserAccount.changePassword',
        defaultMessage: 'Сменить пароль',
    },
    lastUpDate: {
        id: 'UserAccount.lastUpDate',
        defaultMessage: 'Последнее обновление ',
    },
    securityNote: {
        id: 'UserAccount.securityNote',
        defaultMessage: 'Видны только вам',
    },
    changePasswordForbidden: {
        id: 'UserAccount.changePasswordForbidden',
        defaultMessage: 'Самостоятельная смена пароля запрещена. Обратитесь к администратору',
    },
    profile: {
        id: 'UserAccount.profile',
        defaultMessage: 'Профиль',
    },
    userAccessRights: {
        id: 'UserAccount.userAccessRights',
        defaultMessage: 'Права и доступы',
    },
    licenses: {
        id: 'UserAccount.licenses',
        defaultMessage: 'Назначенные лицензии',
    },
    accesses: {
        id: 'UserAccount.accesses',
        defaultMessage: 'Функциональные права',
    },
    groups: {
        id: 'UserAccount.groups',
        defaultMessage: 'Группы',
    },
    profiles: {
        id: 'UserAccount.profiles',
        defaultMessage: 'Профили',
    },
});
