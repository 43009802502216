import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import type { AttributeTypeStyleRule, AttributeTypeStyleRuleTypeEnum } from '../../../../../serverapi/api';
import {
    dateFormat,
    dateTimeFormat,
    momentDateToTimestamp,
    timestampToMomentDate,
} from '../../../../../utils/date.time.utils';

type TRangeInput = {
    rule: AttributeTypeStyleRule;
    showTime: boolean;
    disabled?: boolean;
    handleRuleParams: (ruleType: AttributeTypeStyleRuleTypeEnum | undefined, value1: string, value2?: string) => void;
};

export const RangeInput = ({ rule, showTime, disabled, handleRuleParams }: TRangeInput) => {
    useEffect(() => {
        if (!rule.param && !rule.param2) {
            if (rule.type === 'HAS_NOT_VALUE') {
                handleRuleParams(
                    rule.type,
                    dayjs().format(showTime ? dateTimeFormat : dateFormat),
                    dayjs().format(showTime ? dateTimeFormat : dateFormat),
                );
            } else {
                handleRuleParams(
                    rule.type,
                    momentDateToTimestamp(dayjs())?.toString() || '',
                    momentDateToTimestamp(dayjs())?.toString() || '',
                );
            }
        }
    }, [rule.param, rule.param2]);

    const onChangeHandler = (event: [Dayjs, Dayjs], value: [string, string]) => {
        if (rule.type === 'HAS_NOT_VALUE') {
            handleRuleParams(rule.type, value[0], value[1]);
        } else {
            handleRuleParams(
                rule.type,
                momentDateToTimestamp(event?.[0] || dayjs())?.toString() || '',
                momentDateToTimestamp(event?.[1] || dayjs())?.toString() || '',
            );
        }
    };

    const currentValue = (): [Dayjs, Dayjs] => {
        if (rule.type === 'HAS_NOT_VALUE') {
            return [
                dayjs(rule.param, showTime ? dateTimeFormat : dateFormat),
                dayjs(rule.param2, showTime ? dateTimeFormat : dateFormat),
            ];
        }

        return [
            timestampToMomentDate(Number(rule.param)) || dayjs(),
            timestampToMomentDate(Number(rule.param2)) || dayjs(),
        ];
    };

    return (
        <DatePicker.RangePicker
            data-test="change-the-value-of-the-rule_range-type"
            id={`${rule.type}_1`}
            allowClear={false}
            showTime={showTime}
            disabled={disabled}
            format={showTime ? dateTimeFormat : dateFormat}
            onChange={onChangeHandler}
            value={currentValue()}
        />
    );
};
