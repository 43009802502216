import { NodeId, NodeNotationReplacementDTO } from '../../serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';

export class ChangeEntityTypeDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    };

    public static async getNotationReplacementData(
        nodeId: NodeId,
        nodeTypes: Array<string>,
        searchDepth?: number,
    ): Promise<NodeNotationReplacementDTO[]> {
        const api = this.getApi();
        const { id, repositoryId } = nodeId;
        const notations: NodeNotationReplacementDTO[] = await api.treeNotation.findNotationsByNodeId({
            repositoryId,
            nodeId: id,
            searchDepth: searchDepth || undefined,
            nodeTypes,
        });

        return notations;
    }

    public static async replaceNotations(
        nodeId: NodeId,
        nodeTypes: Array<string>,
        body: NodeNotationReplacementDTO[] | undefined,
        searchDepth?: number,
    ): Promise<void> {
        const api = this.getApi();
        const { id, repositoryId } = nodeId;
        await api.treeNotation.replaceNotations({
            repositoryId,
            nodeId: id,
            searchDepth: searchDepth || undefined,
            nodeTypes,
            body,
        });
    }
}
