import { FETCH_PRINCIPAL_SERVER_PROFILE_OWNERS_INFO_SUCCESS, OPEN_PRINCIPAL_SERVER_PROFILE_OWNERS_EDITING_TAB, SAVE_INFO_OF_PRINCIPAL_SERVER_PROFILE_OWNERS } from "../actionsTypes/principalServerProfile.actionTypes";
import { TFetchPrincipalServerProfileOwnersInfoSuccessAction, TFetchPrincipalServerProfileOwnersInfoSuccessPayload, TOpenPrincipalServerProfileOwnersEditingTabAction, TSaveInfoOfPrincipalServerProfileOwnersAction, TSaveInfoOfPrincipalServerProfileOwnersPayload } from "./principalServerProfile.actions.types";

export const fetchedPrincipalServerProfileInfoSuccess: (
    payload: TFetchPrincipalServerProfileOwnersInfoSuccessPayload,
) => TFetchPrincipalServerProfileOwnersInfoSuccessAction = (payload) => ({
    type: FETCH_PRINCIPAL_SERVER_PROFILE_OWNERS_INFO_SUCCESS,
    payload,
});


export const openPrincipalServerProfileOwnersEditingTab: (profileId: string, serverId: string) => TOpenPrincipalServerProfileOwnersEditingTabAction = (profileId, serverId) => ({
    type: OPEN_PRINCIPAL_SERVER_PROFILE_OWNERS_EDITING_TAB,
    payload: {
        profileId,
        serverId,
    }
   
});

export const saveInfoOfPrincipalServerProfileOwners: (payload: TSaveInfoOfPrincipalServerProfileOwnersPayload) => TSaveInfoOfPrincipalServerProfileOwnersAction = (
    payload,
) => ({
    type: SAVE_INFO_OF_PRINCIPAL_SERVER_PROFILE_OWNERS,
    payload,
});