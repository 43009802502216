// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wrapper__container___2kIp{height:64px;border-top:1px solid var(--Border-SecondaryNeutral200-UIKit);width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.Wrapper__reverse__FSGJY{-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;grid-gap:10px;gap:10px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/DialogWrapper/Wrapper.scss"],"names":[],"mappings":"AAAA,2BACI,WAAA,CACA,4DAAA,CACA,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAGJ,yBACI,sBAAA,CAAA,mBAAA,CAAA,0BAAA,CACA,6BAAA,CAAA,6BAAA,CAAA,8BAAA,CAAA,0BAAA,CACA,aAAA,CAAA,QAAA","sourcesContent":[".container {\r\n    height: 64px;\r\n    border-top: 1px solid var(--Border-SecondaryNeutral200-UIKit);\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.reverse {\r\n    justify-content: flex-start;\r\n    flex-direction: row-reverse;\r\n    gap: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Wrapper__container___2kIp",
	"reverse": "Wrapper__reverse__FSGJY"
};
export default ___CSS_LOADER_EXPORT___;
