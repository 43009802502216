import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import {
    TUserAccessPermissionsDialogActionProps,
    TUserAccessPermissionsDialogOwnProps,
    TUserAccessPermissionsDialogProps,
} from '../UserAccessPermissionsDialog.types';
import UserAccessPermissionsDialog from '../components/UserAccessPermissionsDialog.component';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { UserDTO, UserDTOAccessesEnum } from '../../../../serverapi/api';
import { saveUsersAccesesChanges } from '../../../../actions/users.actions';

const mapStateToProps = (
    state: TRootState,
    ownProps: TUserAccessPermissionsDialogOwnProps,
): Partial<TUserAccessPermissionsDialogProps> => ({
    userData: UsersSelectors.getEditingUser(`${ownProps.userId}`)(state) || ({} as UserDTO),
});

const mapDispatchToProps: (
    dispatch,
    ownProps: TUserAccessPermissionsDialogOwnProps,
) => TUserAccessPermissionsDialogActionProps = (dispatch, ownProps) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.EDIT_USER_FUNCTIONAL_PERMISSIONS));
    },
    onSubmit: (accesses: UserDTOAccessesEnum[]) => {
        dispatch(saveUsersAccesesChanges(accesses, `${ownProps.userId}`));
        dispatch(closeDialog(DialogType.EDIT_USER_FUNCTIONAL_PERMISSIONS));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccessPermissionsDialog);
