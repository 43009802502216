import React, { FC, useState, ChangeEvent } from 'react';
import { Divider } from 'antd';
import theme from './PublishedModelsTab.scss';
import { ModelsListControls } from './ModelsListControls.component';
import { TPublishedModelsTableData } from './PublishedModelTab.types';
import { ModelsList } from './ModelsList.component';

export const PublishedModelsTab: FC = () => {
    const [searchInput, setSearchInput] = useState<string>('');
    const [checkedModels, setCheckedModels] = useState<TPublishedModelsTableData[]>([]);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== searchInput) {
            setSearchInput(e.target.value);
        }
    };

    const handleClearSearch = () => setSearchInput('');

    const handleSetCheckedModels = (models: TPublishedModelsTableData[]): void => {
        setCheckedModels(models);
    };

    return (
        <div className={theme.modelListContainer}>
            <ModelsListControls
                checkedModels={checkedModels}
                handleSearch={handleSearch}
                handleClearSearch={handleClearSearch}
                searchInput={searchInput}
                setCheckedModels={handleSetCheckedModels}
            />
            <Divider className={theme.divider} />

            <ModelsList
                checkedModels={checkedModels}
                searchInput={searchInput}
                setCheckedModels={handleSetCheckedModels}
            />
        </div>
    );
};
