import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import messages from './RefreshPageDialog.messages';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TRefreshPageDialogProps = WrappedComponentProps & {
    onSubmit: () => any;
    onClose: () => any;
};

const RefreshPageDialog = (props: TRefreshPageDialogProps) => {
    const { intl, onSubmit, onClose } = props;
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.refresh),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog open closable={false} onOk={onSubmit} onCancel={onClose} footer={footer}>
            {intl.formatMessage(messages.refreshRequestMessage)}
        </Dialog>
    );
};

const IntlComponent = injectIntl(RefreshPageDialog);

export { IntlComponent as RefreshPageDialog };
