import {
    ADD_EDGE_TYPE_GROUPS,
    DELETE_EDGE_TYPE_GROUP_SUCCESS,
    SUBMIT_EDGE_TYPE_GROUP,
} from '../actionsTypes/edgeTypeGroup.actionTypes';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import { TReducer } from '../utils/types';
import { TEdgeTypeGroupState } from './edgeTypeGroup.reducer.types';
import { cloneState } from './utils/cloneState.utils';

const initial: TEdgeTypeGroupState = {
    byServerId: {},
};

export const edgeTypeGroupReducer: TReducer<TEdgeTypeGroupState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_EDGE_TYPE_GROUPS:
        case SUBMIT_EDGE_TYPE_GROUP: {
            const {
                payload: { serverNode, preset, edgeTypeGroups },
            } = action;
            const presetId = preset.id;
            const { serverId } = serverNode.nodeId;

            const stateClone = cloneState(state, serverId, presetId);

            const { byId } = stateClone.byServerId[serverId].byPresetId[presetId];

            edgeTypeGroups.forEach((edgeTypeGroup) => {
                byId[edgeTypeGroup.id] = edgeTypeGroup;
            });

            return stateClone;
        }
        case DELETE_EDGE_TYPE_GROUP_SUCCESS: {
            const { edgeTypeGroups, serverNode } = action.payload;
            if (edgeTypeGroups) {
                const { serverId } = serverNode.nodeId;

                const stateClone = JSON.parse(JSON.stringify(state));
                edgeTypeGroups.forEach((edgeTypeGroup) => {
                    const { presetId } = edgeTypeGroup;
                    if (presetId && serverId) {
                        const byId = stateClone.byServerId[serverId]?.byPresetId[edgeTypeGroup.presetId]?.byId || {};
                        delete byId[edgeTypeGroup.id];
                    }
                });

                return stateClone;
            }

            return state;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }

        default:
            return state;
    }
};
