import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'DeleteEditingCommentDialog.title',
        defaultMessage: 'Изменения не будут сохранены',
    },
    deleteComment: {
        id: 'DeleteEditingCommentDialog.delete',
        defaultMessage: 'Продолжить',
    },
    cancel: {
        id: 'DeleteEditingCommentDialog.cancel',
        defaultMessage: 'Отмена',
    },
    text: {
        id: 'DeleteEditingCommentDialog.text',
        defaultMessage: 'Вы уверены, что не хотите сохранить изменения?',
    },
});
