import TableCell from '@tiptap/extension-table-cell';

const TableCellExtended = TableCell.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            colwidth: {
                default: null,
                parseHTML: (element) => {
                    const colwidth = element.getAttribute('width');
                    const value = colwidth ? [parseInt(colwidth, 10)] : null;

                    return value;
                },
                renderHTML: (attributes) => {
                    return {
                        width: attributes.colwidth,
                    };
                },
            },
            rowheight: {
                default: null,
                parseHTML: (element) => {
                    const rowheight = element.getAttribute('height');
                    const value = rowheight ? [parseInt(rowheight, 10)] : null;

                    return value;
                },
                renderHTML: (attributes) => {
                    return {
                        height: attributes.rowheight,
                    };
                },
            },
        };
    },
});

export { TableCellExtended as TableCell };
