import React, { FC, memo } from 'react';

// BPM-7047 была проблема со стилизацией svg импортнутой из файла, поэтому иконка сделана через инлайновую svg

export const DropdownIcon: FC = memo(() => (
    <svg data-test="navigator_Panel_filter_icon" width="16" height="11" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 1C0 1.41421 0.335786 1.75 0.75 1.75H15.25C15.6642 1.75 16 1.41421 16 1C16 0.585786 15.6642 0.25 15.25 0.25H0.75C0.335786 0.25 0 0.585786 0 1Z"
            fill="#676767"
        />
        <path
            d="M14 5C14 4.58579 13.6642 4.25 13.25 4.25H2.75C2.33579 4.25 2 4.58579 2 5C2 5.41421 2.33579 5.75 2.75 5.75H13.25C13.6642 5.75 14 5.41421 14 5Z"
            fill="#676767"
        />
        <path
            d="M4 9C4 9.41421 4.33579 9.75 4.75 9.75H11.25C11.6642 9.75 12 9.41421 12 9C12 8.58579 11.6642 8.25 11.25 8.25H4.75C4.33579 8.25 4 8.58579 4 9Z"
            fill="#676767"
        />
    </svg>
));
