export const SUBMIT_EDGE_TYPE_GROUP = 'SUBMIT_EDGE_TYPE_GROUP';

export const ADD_EDGE_TYPE_GROUPS = 'ADD_EDGE_TYPE_GROUPS';

export const CREATE_EDGE_TYPE_GROUP = 'CREATE_EDGE_TYPE_GROUP';

export const DELETE_EDGE_TYPE_GROUP_REQUEST = 'DELETE_EDGE_TYPE_GROUP_REQUEST';

export const DELETE_EDGE_TYPE_GROUP_SUCCESS = 'DELETE_EDGE_TYPE_GROUP_SUCCESS';

export const EDIT_EDGE_TYPE_GROUP = 'EDIT_EDGE_TYPE_GROUP';
