import { NodeId } from '../serverapi/api';

export enum FileUploadStatus {
    NONE,
    LOADING,
    DONE,
    ERROR,
}

export enum ParameterType {
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    FILE = 'FILE',
    NODE = 'NODE',
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    SELECT_STRING = 'SELECT_STRING',
    QUERY_SELECT = 'QUERY_SELECT',
    QUERY_MULTI_SELECT = 'QUERY_MULTI_SELECT',
    OUT_STRING = 'OUT_STRING',
    OUT_NODE = 'OUT_NODE',
    KEY_VALUE_SELECT = 'KEY_VALUE_SELECT',
    KEY_VALUE_MULTI_SELECT = 'KEY_VALUE_MULTI_SELECT',
}

export type TScriptExecuteDialogFileParams = {
    [paramName: string]: {
        uploadStatus: FileUploadStatus;
        uploadedFileName?: string;
        originalFileName?: string;
    };
};

export type TScriptExecuteDialogNodeParams = {
    [paramName: string]: {
        nodeId?: NodeId;
        path?: string;
    };
};

export type TScriptExecuteDialogState = {
    fileParams: TScriptExecuteDialogFileParams;
    nodeParams: TScriptExecuteDialogNodeParams;
};
