import { TreeItemType } from '../modules/Tree/models/tree';
import { MessageDescriptor } from 'react-intl';
import treeItemTypeAccusativeMessages from '../models/treeItemTypeAccusative.messages';

/**
 *
 * Возращает имя типа узла дерева в винительном падеже
 *
 * @param nodeType - типа узла дерева
 *
 */
export const getAccusativeItemTypeName = (nodeType: TreeItemType): MessageDescriptor => {
    return treeItemTypeAccusativeMessages[`${nodeType}_ACCUSATIVE`] || treeItemTypeAccusativeMessages.NODE_ACCUSATIVE;
};
