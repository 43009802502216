import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../../../messages/CommonToolbar.messages';
import icUpdate from '../../../../../../resources/icons/ic-update.svg';
import { updateAction } from '../../../../../../actions/editor.actions';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton.component';

interface IUpdateButtonProps {
    disabled: boolean | undefined;
    isReadMode: boolean | undefined;
    compact: boolean | undefined;
}

export const UpdateButton: FC<IUpdateButtonProps> = (props) => {
    const { disabled, isReadMode, compact } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const messageUpdate = compact ? intl.formatMessage(messages.update) : '';

    const onUpdateAction = () => {
        dispatch(updateAction());
    };

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icUpdate}
            tooltipTitle={
                isReadMode ? messageUpdate : intl.formatMessage(messages.onlyInReadModeAvailable)
            }
            dataTest="general-toolbar_refresh-btn"
            onClick={onUpdateAction}
            text={intl.formatMessage(messages.update)}
        />
    );
};
