import { defineMessages } from 'react-intl';

export default defineMessages({
    'admin-tools-root-id': {
        id: 'admin-tools-root-id',
        defaultMessage: 'Администрирование',
    },
    'licenses-id': {
        id: 'licenses-id',
        defaultMessage: 'Лицензии',
    },
    'server-settings-id': {
        id: 'server-settings-id',
        defaultMessage: 'Настройки сервера',
    },
    'methodology-settings-id': {
        id: 'methodology-settings-id',
        defaultMessage: 'Настройки методологии',
    },
    'user-management-id': {
        id: 'user-management-id',
        defaultMessage: 'Управление пользователями',
    },
    'safety-id': {
        id: 'safety-id',
        defaultMessage: 'Безопасность',
    },
    'AdminTools.monitoring.tabName': {
        id: 'AdminTools.monitoring.tabName',
        defaultMessage: 'Мониторинг',
    },
    notifications: {
        id: 'notifications',
        defaultMessage: 'Уведомления',
    },
    'event-access-id': {
        id: 'event-access-id',
        defaultMessage: 'Настройка обработки событий',
    },
    'approvals-id': {
        id: 'approvals-id',
        defaultMessage: 'Согласования',
    },
    MY_APPROVALS: {
        id: 'MY_APPROVALS',
        defaultMessage: 'Мои согласования',
    },
    'published-models': {
        id: 'published-models',
        defaultMessage: 'Опубликованные модели',
    },
});
