import { defineMessages } from 'react-intl';

export default defineMessages({
    MODEL_EDITOR: {
        id: 'LicenseTypes.MODEL_EDITOR',
        defaultMessage: 'Modeler',
    },
    MODEL_LITE_EDITOR: {
        id: 'LicenseTypes.MODEL_LITE_EDITOR',
        defaultMessage: 'Product Designer',
    },
    MODEL_READER: {
        id: 'LicenseTypes.MODEL_READER',
        defaultMessage: 'Viewer',
    },
    MODEL_EDITOR_COMPETITIVE: {
        id: 'LicenseTypes.MODEL_EDITOR_COMPETITIVE',
        defaultMessage: 'Modeler competitive',
    },
    MODEL_LITE_EDITOR_COMPETITIVE: {
        id: 'LicenseTypes.MODEL_LITE_EDITOR_COMPETITIVE',
        defaultMessage: 'Product Designer competitive',
    },
    MODEL_READER_COMPETITIVE: {
        id: 'LicenseTypes.MODEL_READER_COMPETITIVE',
        defaultMessage: 'Viewer competitive',
    },
});
