import { apiBundle } from '../../services/api/api-bundle';
import {
    SERVER_ADD_BATCH,
    SERVER_CHANGE_ONLINE_STATUS,
    SERVER_DELETE,
    SERVER_EDIT_SUCCESS,
} from '../../actionsTypes/entities/servers.actionTypes';
import { TServerEntity } from '../../models/entities.types';
import { TServersState } from './server.reducer.types';

const initial: TServersState = {
    byId: {},
    connected: [],
    disconnected: [],
    pending: [],
};

export const serverReducer: (
    state: TServersState,
    action,
) => { connected: string[]; disconnected: string[]; pending: string[]; byId: { [p: string]: TServerEntity } } = (
    state = initial,
    action,
) => {
    switch (action.type) {
        case SERVER_ADD_BATCH: {
            const { servers } = action.payload;

            const newServersById = servers.reduce(
                (acc: { byId: { [id: string]: TServerEntity } }, server: TServerEntity) => {
                    acc[server.id] = server;

                    return acc;
                },
                {},
            );

            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...newServersById,
                },
            };
        }
        case SERVER_EDIT_SUCCESS: {
            const { entity } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [entity.id]: {
                        ...state.byId[entity.id],
                        ...entity,
                        api: apiBundle(entity.id, entity.url),
                    },
                },
            };
        }
        case SERVER_DELETE:
            const byId = { ...state.byId };
            delete byId[action.payload];

            return {
                ...state,
                byId,
            };

        case SERVER_CHANGE_ONLINE_STATUS: {
            const { id, online } = action.payload;

            let updatedConnectedList: string[];
            let updatedDisconnectedList: string[];

            if (online) {
                // убираем дубликаты из connected
                // сервер мог стать offline через истечение токена, тогда клиент не узнает что сервер offline
                updatedConnectedList = [...state.connected.filter((sId) => sId !== id), id];
                updatedDisconnectedList = state.disconnected.filter((sId) => sId !== id);
            } else {
                updatedConnectedList = state.connected.filter((sId) => sId !== id);
                updatedDisconnectedList = [...state.disconnected.filter((sId) => sId !== id), id];
            }

            return {
                ...state,
                connected: updatedConnectedList,
                disconnected: updatedDisconnectedList,
            };
        }
        default:
            return state;
    }
};
