import React, { ReactNode } from 'react';
import theme from './CommentRenderer.scss';
import { Popover } from 'antd';
import { ContentState } from 'draft-js';

type TCommentRendererProps = {
    children: ReactNode[];
    entityKey: string;
    contentState: ContentState;
    onCommentClick: (isOpen: boolean, threadId: string) => void;
    renderPopover: (threadId: string) => ReactNode;
};

const CommentRenderer = ({
    children,
    contentState,
    entityKey,
    onCommentClick,
    renderPopover,
}: TCommentRendererProps) => {
    const { threadId } = contentState.getEntity(entityKey).getData();
    const onOpenChange = (isOpen: boolean) => {
        onCommentClick(isOpen, threadId);
    };

    return (
        <Popover trigger="click" content={renderPopover(threadId)} onOpenChange={onOpenChange}>
            <span className={theme.container}>{children}</span>
        </Popover>
    );
};

export const getCommentRenderer =
    ({ onCommentClick, renderPopover }) =>
    (ownProps) =>
        CommentRenderer({ onCommentClick, renderPopover, ...ownProps });

export default CommentRenderer;
