import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import cx from 'classnames';
import { useSharedState } from '../UseSharedState.hook';
import { FontFamilySelect } from '@/modules/MainMenu/components/FontFamilySelect/FontFamilySelect.component';
import ControlsContext from '../Controls.context';
import theme from '../RichTextEditorToolbar.scss';

const getFocusedBlock = (editorState) => {
    const fontFamilyStyle = getSelectionCustomInlineStyle(editorState, ['FONTFAMILY']);
    if (fontFamilyStyle) {
        return fontFamilyStyle.FONTFAMILY?.replace('fontfamily-', '');
    }

    return undefined;
};

const getChangedBlock = (editorState, value) => toggleCustomInlineStyle(editorState, 'fontFamily', value);

type TFontSizeComponent = {
    className?: string;
};

const FontFamilyComponent: FC<TFontSizeComponent> = ({ className = '' }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <div className={theme.groupRow}>
            <div className={theme.tooltipContainer} data-test="wiki-toolbar-group_select-font-button">
                <FontFamilySelect
                    onChange={setCurrentState}
                    value={value || ''}
                    className={cx(className, theme.selectFont)}
                    renderNewOption={false}
                    tooltipTitle={intl.formatMessage(messages.fontFamily)}
                />
            </div>
        </div>
    );
};

export default FontFamilyComponent;
