import { Form, Select, Table } from 'antd';
import React, { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useChangeNodeAttributeValue } from '../../../hooks/useChangeNodeAttributeValue';
import iconAdd from '@/resources/icons/ic-add.svg';
import icDelete from '@/resources/icons/Deleted.svg';
import cx from 'classnames';
import {
    AttributeType,
    AttributeValueNode,
    AttributeValueString,
    InternationalString,
    Node,
    NodeId,
    ObjectDefinitionNode,
} from '../../../serverapi/api';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import messages from '../messages/ObjectPropertiesDialog.messages';
import theme from './ObjectPropertiesDialog.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AttributeTypeSelectors } from '../../../selectors/attributeType.selectors';
import { LocalesService } from '../../../services/LocalesService';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { EditableText } from '../../UIKit/components/EditableText/EditableText.component';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { TValueTypeEnum } from '../../../models/ValueTypeEnum.types';
import { getUmlColumnId, getUmlObjectIdFromColumnId, storageValueToString, UML_STRING_COLUMN_KEY } from './utils';
import { openAttributeLinkAction } from '../../../actions/openAttributeLink.actions';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { ClassMethodParameters } from './ClassMethodParameters.component';
import { useIntl } from 'react-intl';
import { UML_ID_SYMBOL, UML_OBJECT_TYPE } from '../../../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import { UML_ATTR_METHOD } from '../../../mxgraph/ComplexSymbols/symbols/UML/ClassSymbol/classSymbol.constants';
import { TreeItemType } from '../../Tree/models/tree';
import { TAttributeValueRecord } from './AttributeTab.types';
import { ChangePositionButtons } from '../../ChangePositionButtons/ChangePositionButtons.component';
import { upElementIdInArray } from '../../../utils/upElementIdInArray.utils';
import { MultiLangEditableText } from '../../UIKit/components/MultiLangEditableText/MultiLangEditableText.component';
import icEdit from '@/resources/icons/edit.svg';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TClassMethodsTabProps = {
    nodeId: NodeId;
    classMethodObjects: ObjectDefinitionNode[];
    onChangeClassMethodObjects: (objects: ObjectDefinitionNode[]) => void;
    classMethodParameterObjects: ObjectDefinitionNode[];
    onChangeClassMethodParameterObjects: (objects: ObjectDefinitionNode[]) => void;
    deletedClassObjectNodeIds: NodeId[];
    onDeleteClassObjectNodeIds: (nodeIds: NodeId[]) => void;
};

type TAttributeValueStringRecord = {
    id: string;
    attributeValue?: AttributeValueString;
};

type TAttributeValueNodeRecord = {
    id: string;
    attributeValue?: AttributeValueNode;
};

type TColumnsData = {
    multilingualName: AttributeValueString;
    visibility: TAttributeValueRecord;
    typeString: TAttributeValueStringRecord;
    typeEnum: TAttributeValueRecord;
    typeLink: TAttributeValueNodeRecord;
    key: string;
};

export const ClassMethodsTab: FC<TClassMethodsTabProps> = ({
    nodeId,
    classMethodObjects,
    classMethodParameterObjects,
    onChangeClassMethodParameterObjects,
    onChangeClassMethodObjects,
    deletedClassObjectNodeIds,
    onDeleteClassObjectNodeIds,
}) => {
    const { serverId, repositoryId } = nodeId;
    const dispatch = useDispatch();
    const intl = useIntl();
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const currentLocale = useSelector(getCurrentLocale);
    const [selectedMethodId, setSelectedMethodId] = useState<string | null>(null);

    const columnsData = classMethodObjects.map((obj) => {
        const multilingualName =
            obj.type === TreeItemType.ObjectDefinition ? (obj as Node).multilingualName : undefined;
        const visibility = obj.attributes?.find((attr) => attr.typeId === UML_ATTR_METHOD.VISIBILITY);
        const typeString = obj.attributes?.find((attr) => attr.typeId === UML_ATTR_METHOD.STRING);
        const typeEnum = obj.attributes?.find((attr) => attr.typeId === UML_ATTR_METHOD.ENUM);
        const typeLink = obj.attributes?.find((attr) => attr.typeId === UML_ATTR_METHOD.LINK);

        const record: TColumnsData = {
            multilingualName: {
                typeId: 'SYSTEM',
                value: LocalesService.internationalStringToString(multilingualName),
                str: multilingualName,
                valueType: 'STRING',
                id: getUmlColumnId(obj.nodeId.id, UML_STRING_COLUMN_KEY.MULTILINGUAL_NAME),
            },
            visibility: { id: obj.nodeId.id, attributeValue: visibility },
            typeString: { id: obj.nodeId.id, attributeValue: typeString },
            typeEnum: { id: obj.nodeId.id, attributeValue: typeEnum },
            typeLink: { id: obj.nodeId.id, attributeValue: typeLink },
            key: obj.nodeId.id,
        };

        return record;
    });

    const attributeTypes: AttributeType[] = useSelector(AttributeTypeSelectors.allInPreset(nodeId.serverId, presetId));

    const handleChangeSelectAttributeValue = (
        record: TAttributeValueRecord,
        value: string | undefined,
        attrTypeId: string | undefined,
        valueType: TValueTypeEnum,
    ) => {
        const changedObjects = classMethodObjects.map((obj) => {
            if (obj.nodeId.id !== record.id) return obj;

            if (!record.attributeValue) {
                const oldAttributes = obj.attributes || [];

                return {
                    ...obj,
                    attributes: [
                        ...oldAttributes,
                        {
                            id: uuid(),
                            typeId: attrTypeId,
                            value,
                            valueType,
                        },
                    ],
                } as ObjectDefinitionNode;
            }

            const changedAttributes = obj.attributes?.map((attr) => {
                if (attr.typeId === attrTypeId) {
                    return {
                        ...attr,
                        value,
                    };
                }

                return attr;
            });

            return { ...obj, attributes: changedAttributes } as ObjectDefinitionNode;
        });
        onChangeClassMethodObjects(changedObjects);
    };

    const handleChangeObjectDefinitionName = (id: string, value: InternationalString | string, columnKey: string) => {
        const objectId = getUmlObjectIdFromColumnId(id, columnKey);

        const changedObjects = classMethodObjects.map((obj) => {
            if (obj.nodeId.id !== objectId) return obj;
            const multilingualName =
                obj.type === TreeItemType.ObjectDefinition ? (obj as Node).multilingualName : undefined;
            const newVal =
                typeof value === 'string'
                    ? {
                          ...multilingualName,
                          [currentLocale]: value,
                      }
                    : value;

            return {
                ...obj,
                multilingualName: newVal,
            } as ObjectDefinitionNode;
        });

        onChangeClassMethodObjects(changedObjects);
    };

    const handleChangeStringAttributeValue = (
        id: string,
        valueType: TValueTypeEnum,
        value: InternationalString | string,
    ) => {
        const changedObjects = classMethodObjects.map((obj) => {
            if (obj.nodeId.id !== id) return obj;

            if (!obj.attributes?.find((attr) => attr.typeId === UML_ATTR_METHOD.STRING)) {
                const newStr = typeof value === 'string' ? { [currentLocale]: value } : value;
                const oldAttributes = obj.attributes || [];

                return {
                    ...obj,
                    attributes: [
                        ...oldAttributes,
                        {
                            id: uuid(),
                            typeId: UML_ATTR_METHOD.STRING,
                            str: newStr,
                            value: LocalesService.internationalStringToString(newStr),
                            valueType,
                        },
                    ],
                } as ObjectDefinitionNode;
            }

            const changedAttributes = obj.attributes.map((attr) => {
                if (attr.typeId === UML_ATTR_METHOD.STRING) {
                    const newStr =
                        typeof value === 'string'
                            ? LocalesService.changeLocaleValue((attr as AttributeValueString).str, currentLocale, value)
                            : value;

                    return {
                        ...attr,
                        str: newStr,
                        value: LocalesService.internationalStringToString(newStr),
                    };
                }

                return attr;
            });

            return { ...obj, attributes: changedAttributes } as ObjectDefinitionNode;
        });

        onChangeClassMethodObjects(changedObjects);
    };

    const handleChangeNodeAttributeValue = (id: string, valueType: TValueTypeEnum, value: any) => {
        const { nodeId, multilingualName, type, name } = value as Node;
        const changedObjects = classMethodObjects.map((obj) => {
            if (obj.nodeId.id !== id) return obj;

            if (!obj.attributes?.find((attr) => attr.typeId === UML_ATTR_METHOD.LINK)) {
                const oldAttributes = obj.attributes || [];

                return {
                    ...obj,
                    attributes: [
                        ...oldAttributes,
                        {
                            id: uuid(),
                            value: '',
                            linkedNodeId: nodeId?.id,
                            name: LocalesService.changeLocaleValue(multilingualName, currentLocale, name),
                            nodeType: type,
                            typeId: UML_ATTR_METHOD.LINK,
                            valueType: 'NODE',
                            notFound: false,
                        },
                    ],
                } as ObjectDefinitionNode;
            }

            const changedAttributes = obj.attributes.map((attr) => {
                if (attr.typeId === UML_ATTR_METHOD.LINK) {
                    return {
                        ...attr,
                        value: '',
                        linkedNodeId: nodeId?.id,
                        name: LocalesService.changeLocaleValue(multilingualName, currentLocale, name),
                        nodeType: type,
                    } as AttributeValueNode;
                }

                return attr;
            });

            return { ...obj, attributes: changedAttributes } as ObjectDefinitionNode;
        });
        onChangeClassMethodObjects(changedObjects);
    };

    const { setNewNodeId, setNodeAttributeToChange } = useChangeNodeAttributeValue(handleChangeNodeAttributeValue);

    const changeNodeAttribute = (nodeId: NodeId, nodeAttribute: AttributeValueNode): void => {
        setNodeAttributeToChange(nodeAttribute);
        setNewNodeId(nodeId);
    };

    const clearNodeAttributeValue = (id: string) => {
        const changedObjects = classMethodObjects.map((obj) => {
            if (obj.nodeId.id !== id || !obj.attributes?.find((attr) => attr.typeId === UML_ATTR_METHOD.LINK))
                return obj;

            const changedAttributes = obj.attributes?.map((attr) => {
                if (attr.typeId === UML_ATTR_METHOD.LINK) {
                    return {
                        id: attr.id,
                        value: '',
                        name: {},
                        notFound: true,
                        typeId: attr.typeId,
                        valueType: attr.valueType,
                    } as AttributeValueNode;
                }

                return attr;
            });

            return { ...obj, attributes: changedAttributes } as ObjectDefinitionNode;
        });
        onChangeClassMethodObjects(changedObjects);
    };

    const handleAddMethod = () => {
        const newMethod = {
            attributes: [],
            children: [],
            idSymbol: UML_ID_SYMBOL.METHOD,
            objectTypeId: UML_OBJECT_TYPE.METHOD,
            modelAssignments: [],
            description: { ru: '', en: '' },
            multilingualName: {
                [currentLocale]: intl.formatMessage(messages.newMethod),
            },
            name: intl.formatMessage(messages.newMethod),
            nodeId: { id: uuid(), repositoryId, serverId },
            parentNodeId: nodeId,
            objectEntries: [],
            type: 'OBJECT',
        } as ObjectDefinitionNode;
        onChangeClassMethodObjects([...classMethodObjects, newMethod]);
    };

    const handleDeleteMethod = () => {
        const methodToDelete = classMethodObjects.find((obj) => selectedMethodId === obj.nodeId.id);
        onChangeClassMethodParameterObjects(
            classMethodParameterObjects.filter((obj) => obj.parentNodeId?.id !== selectedMethodId),
        );
        onChangeClassMethodObjects(classMethodObjects.filter((obj) => selectedMethodId !== obj.nodeId.id));
        onDeleteClassObjectNodeIds([...deletedClassObjectNodeIds, methodToDelete?.nodeId!]);
    };

    const handleClickMethod = (selectedId: string) => {
        if (selectedMethodId === selectedId) {
            setSelectedMethodId(null);
        } else {
            setSelectedMethodId(selectedId);
        }
    };

    const parentNodeId = {
        id: selectedMethodId!,
        serverId,
        repositoryId,
    };

    const onChangePosition = (id: number) => {
        const newNodes = upElementIdInArray(classMethodObjects, id);

        onChangeClassMethodObjects(newNodes);
    };

    return (
        <div className={theme.classMethodsTab}>
            <FormGroup className={cx(theme.classForm, theme.classFormWrap)}>
                <Form.Item>
                    <Table
                        dataSource={columnsData}
                        className={theme.table}
                        onRow={(row) => ({
                            onClick: () => {
                                handleClickMethod(row.key);
                            },
                        })}
                        rowClassName={(row: { key: string }) =>
                            cx(theme.attribute, {
                                [theme.attribute_selected]: selectedMethodId === row.key,
                            })
                        }
                        size="middle"
                        bordered
                        pagination={false}
                    >
                        <Table.Column
                            width={180}
                            title={intl.formatMessage(messages.methodName)}
                            dataIndex="multilingualName"
                            key="multilingualName"
                            render={(record: AttributeValueString) => {
                                const text: string | undefined = LocalesService.internationalStringToString(record.str);

                                return (
                                    <div className={theme.editableTextContainer}>
                                        <MultiLangEditableText
                                            text={text}
                                            disabled={false}
                                            onChange={(value: string | InternationalString) =>
                                                handleChangeObjectDefinitionName(
                                                    record.id,
                                                    value,
                                                    UML_STRING_COLUMN_KEY.MULTILINGUAL_NAME,
                                                )
                                            }
                                            allowEmptyValue={false}
                                            inputType="text"
                                            dataTestContainer="field-with-class-method-name"
                                            dataTestBtn="change-method-name_btn"
                                            record={record}
                                            clearSelecting={() => setSelectedMethodId(null)}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Table.Column
                            className={theme.classObjectColumn}
                            width={120}
                            title={intl.formatMessage(messages.methodVisibility)}
                            dataIndex="visibility"
                            key="visibility"
                            render={(record: TAttributeValueRecord) => {
                                const attributeType = attributeTypes.find(
                                    (attrType) => attrType.id === UML_ATTR_METHOD.VISIBILITY,
                                );
                                const attributeTypeValue = attributeType?.selectPropertyValues?.find(
                                    (type) => type.id === record.attributeValue?.value,
                                );

                                const currentValue = LocalesService.internationalStringToString(
                                    attributeTypeValue?.value,
                                );
                                const defaultValue = currentValue || undefined;

                                return (
                                    <div className={theme.editableElementMedium}>
                                        <Select
                                            defaultValue={defaultValue}
                                            allowClear
                                            data-test="change-method-visibility_select"
                                            onChange={(value) =>
                                                handleChangeSelectAttributeValue(
                                                    record,
                                                    value,
                                                    attributeType?.id,
                                                    'SELECT',
                                                )
                                            }
                                        >
                                            {attributeType?.selectPropertyValues?.map((v) => (
                                                <Select.Option key={v.id} value={v.id}>
                                                    {LocalesService.internationalStringToString(v.value) || ''}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                );
                            }}
                        />
                        <Table.ColumnGroup title={intl.formatMessage(messages.methodReturnType)}>
                            <Table.Column
                                width={180}
                                title={intl.formatMessage(messages.manualFilling)}
                                dataIndex="typeString"
                                key="typeString"
                                render={(record: TAttributeValueStringRecord) => {
                                    const text: string | undefined = LocalesService.internationalStringToString(
                                        record.attributeValue?.str,
                                    );

                                    return (
                                        <div className={theme.editableTextContainer}>
                                            <MultiLangEditableText
                                                text={text}
                                                disabled={false}
                                                onChange={(value: string | InternationalString) =>
                                                    handleChangeStringAttributeValue(record.id, 'STRING', value)
                                                }
                                                allowEmptyValue
                                                inputType="text"
                                                dataTestContainer="field-with-class-method-type-string"
                                                dataTestBtn="change-method-type-string_btn"
                                                record={
                                                    record.attributeValue || {
                                                        id: uuid(),
                                                        typeId: UML_ATTR_METHOD.STRING,
                                                        value: '',
                                                        valueType: 'STRING',
                                                        str: { ru: '', en: '' },
                                                    }
                                                }
                                                clearSelecting={() => setSelectedMethodId(null)}
                                            />
                                        </div>
                                    );
                                }}
                            />
                            <Table.Column
                                className={theme.classObjectColumn}
                                width={120}
                                title={intl.formatMessage(messages.selectFromList)}
                                dataIndex="typeEnum"
                                key="typeEnum"
                                render={(record: TAttributeValueRecord) => {
                                    const attributeType = attributeTypes.find(
                                        (attrType) => attrType.id === UML_ATTR_METHOD.ENUM,
                                    );
                                    const attributeTypeValue = attributeType?.selectPropertyValues?.find(
                                        (type) => type.id === record.attributeValue?.value,
                                    );

                                    const currentValue = LocalesService.internationalStringToString(
                                        attributeTypeValue?.value,
                                    );
                                    const defaultValue = currentValue || undefined;

                                    return (
                                        <div className={theme.editableElementMedium}>
                                            <Select
                                                defaultValue={defaultValue}
                                                allowClear
                                                data-test="change-method-type-list_select"
                                                onChange={(value) =>
                                                    handleChangeSelectAttributeValue(
                                                        record,
                                                        value,
                                                        attributeType?.id,
                                                        'SELECT',
                                                    )
                                                }
                                            >
                                                {attributeType?.selectPropertyValues?.map((v) => (
                                                    <Select.Option key={v.id} value={v.id}>
                                                        {LocalesService.internationalStringToString(v.value) || ''}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    );
                                }}
                            />
                            <Table.Column
                                width={120}
                                title={intl.formatMessage(messages.selectFromNavigator)}
                                dataIndex="typeLink"
                                key="typeLink"
                                render={(record: TAttributeValueNodeRecord) => {
                                    const nodeRecord: AttributeValueNode = record.attributeValue as AttributeValueNode;
                                    const nodePath = storageValueToString(nodeRecord, currentLocale) || '';
                                    const propertyNodeId = classMethodObjects.find(
                                        (obj) => obj.nodeId.id === record.id,
                                    )?.nodeId;

                                    const onClickIcon = () =>
                                        dispatch(
                                            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                                                serverId,
                                                repositoryId,
                                                disableContextMenu: true,
                                                isTreeWithClearButton: true,
                                                onSubmit: (nodeId: NodeId) =>
                                                    changeNodeAttribute(nodeId, { ...nodeRecord, id: record.id }),
                                                onClear: () => {
                                                    clearNodeAttributeValue(record.id);
                                                },
                                            }),
                                        );

                                    const onClickLink = () =>
                                        dispatch(openAttributeLinkAction(nodeRecord, propertyNodeId));

                                    return (
                                        <div className={theme.editableTextContainer}>
                                            <div className={theme.editableElementLarge}>
                                                <EditableText
                                                    className={theme.linkEditableTextContainer}
                                                    text={nodePath}
                                                    isEditing={false}
                                                    disabled={false}
                                                    allowEmptyValue
                                                    onClickLink={onClickLink}
                                                    isUrlType
                                                />
                                            </div>
                                            <Button icon={icEdit} onClick={onClickIcon} dataTest="change-method-type-link_btn" />
                                        </div>
                                    );
                                }}
                            />
                        </Table.ColumnGroup>
                        <Table.Column
                            width={30}
                            title=""
                            dataIndex="typeEnum"
                            key="changePositionButtons"
                            render={(record: TAttributeValueRecord) => {
                                const index: number = classMethodObjects.findIndex(
                                    (node) => node.nodeId.id === record.id,
                                );

                                return (
                                    <ChangePositionButtons
                                        upButtonDisabled={index === 0}
                                        downButtonDisabled={index === classMethodObjects.length - 1}
                                        onUp={() => onChangePosition(index - 1)}
                                        onDown={() => onChangePosition(index)}
                                    />
                                );
                            }}
                        />
                    </Table>
                    <div className={theme.attributeActions}>
                        <Button size="large" onClick={handleAddMethod} dataTest="class-properties-window_add-method_btn" icon={iconAdd}>
                            {intl.formatMessage(messages.methodAdd)}
                        </Button>
                        <div className={theme.attributeActionsInner}>
                            <Button
                                size="large"
                                onClick={handleDeleteMethod}
                                disabled={!selectedMethodId}
                                dataTest="class-properties-window_delete-method_btn"
                                icon={icDelete}
                            >
                                {intl.formatMessage(messages.methodDelete)}
                            </Button>
                        </div>
                    </div>
                </Form.Item>
            </FormGroup>
            {selectedMethodId ? (
                <ClassMethodParameters
                    nodeId={nodeId}
                    classMethodParameterObjects={classMethodParameterObjects}
                    onChangeClassMethodParameterObjects={onChangeClassMethodParameterObjects}
                    deletedClassObjectNodeIds={deletedClassObjectNodeIds}
                    onDeleteClassObjectNodeIds={onDeleteClassObjectNodeIds}
                    parentNodeId={parentNodeId}
                />
            ) : null}
        </div>
    );
};
