import { put, takeEvery, take, race, select } from 'redux-saga/effects';
import {
    IMPORT_DIALOG_CANCEL,
    IMPORT_DIALOG_INIT,
    IMPORT_DIALOG_SUBMIT_ALL,
    IMPORT_DIALOG_SUBMIT_NODE,
    IMPORT_DIALOG_APPLY_NAME,
    IMPORT_DIALOG_REPLACE_CONFIRM,
    IMPORT_DIALOG_IMPORT_REQUEST_DONE,
} from '../actionsTypes/importDialog.actionTypes';
import { importRequestDone, importDialogCancel } from '../actions/importDialog.actions';
import { TImportDialogInitAction, TImportDialogImportRequestDoneAction } from '../actions/importDialog.actions.types';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { treeImportNode } from '../actions/tree.actions';
import { ImportDialogSelectors } from '../selectors/importDialog.selectors';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TreeItemType } from '../modules/Tree/models/tree';
import { ConflictResolutionStrategy } from '../services/api/custom/TransferApi';
import { TServerEntity } from '../models/entities.types';
import messages from '../modules/ImportDialog/messages/ImportDialog.messages';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { setProcessIndicator } from '../actions/statusBar.actions';
import { getStore } from '../store';
import { showNotification } from '../actions/notification.actions';
import { v4 as uuid } from 'uuid';
import { NotificationType } from '../models/notificationType';
import { ConflictingImportNode } from '../serverapi/api';
import { LocalesService } from '../services/LocalesService';
import { Locale } from '../modules/Header/components/Header/header.types';
import { DEFAULT_LOCALE } from '../config/config';

function* importDialogInit({ payload }: TImportDialogInitAction) {
    const { process } = payload;
    try {
        const { type, nodeId, conflictingNodes } = yield select(ImportDialogSelectors.getImportDialogState);

        const existsNodesWithoutAccess: boolean = !!Array.from(conflictingNodes).filter(
            (n) => !(n as ConflictingImportNode).writeAccess,
        ).length;

        for (const conflictingNode of conflictingNodes as ConflictingImportNode[]) {
            const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
            const nodeType: TreeItemType = conflictingNode.nodeType as TreeItemType;
            const locale: Locale | undefined = yield select(getCurrentLocale);
            const nodeTypeLocal: string = getLocalNodeType(nodeType, locale || DEFAULT_LOCALE);

            const path = `${server.name}/${conflictingNode.path}`;

            yield put(
                openDialog(DialogType.CONFLICT_RESOLUTION_DIALOG, {
                    itemId: conflictingNode.nodeId,
                    itemTypeLocal: nodeTypeLocal,
                    itemType: nodeType,
                    path,
                    conflictsNumber: conflictingNodes.length,
                    noAccess: !conflictingNode.writeAccess,
                    nodesWithoutAccess: existsNodesWithoutAccess,
                }),
            );

            const { submit_node, submit_all, cancel } = yield race({
                submit_node: take(IMPORT_DIALOG_SUBMIT_NODE),
                submit_all: take(IMPORT_DIALOG_SUBMIT_ALL),
                cancel: take(IMPORT_DIALOG_CANCEL),
            });

            const conflictResolutionStrategy = submit_node
                ? submit_node.payload.conflictResolutionStrategy
                : submit_all
                    ? submit_all.payload.conflictResolutionStrategy
                    : undefined;

            yield put(closeDialog(DialogType.CONFLICT_RESOLUTION_DIALOG));

            if (
                nodeType === TreeItemType.Repository &&
                conflictResolutionStrategy === ConflictResolutionStrategy.CLONE
            ) {
                yield put(openDialog(DialogType.NEW_REPOSITORY_NAME_DIALOG));
                yield race({
                    apply: take(IMPORT_DIALOG_APPLY_NAME),
                    cancel: take(IMPORT_DIALOG_CANCEL),
                });
                yield put(closeDialog(DialogType.NEW_REPOSITORY_NAME_DIALOG));
            } else if (
                nodeType === TreeItemType.Repository &&
                conflictResolutionStrategy === ConflictResolutionStrategy.REPLACE
            ) {
                yield put(openDialog(DialogType.REPLACE_CONFIRMATION_DIALOG, { path }));
                yield race({
                    apply: take(IMPORT_DIALOG_REPLACE_CONFIRM),
                    cancel: take(IMPORT_DIALOG_CANCEL),
                });
                yield put(closeDialog(DialogType.REPLACE_CONFIRMATION_DIALOG));
            } else if (
                nodeType === TreeItemType.Repository &&
                conflictResolutionStrategy === ConflictResolutionStrategy.LEAVE
            ) {
                yield put(importDialogCancel());
                break;
            } else if (submit_all || cancel) {
                break;
            }
        }

        yield put(
            treeImportNode({
                parentNodeId: nodeId,
                type,
                process,
            }),
        );
    } catch (e) {
        yield put(importRequestDone(process));
        getStore().dispatch(
            showNotification({
                id: uuid(),
                type: NotificationType.IMPORT_FILE_FAIL,
            }),
        );
    }
}

function* handleImportDialogRequestDone({ payload }: TImportDialogImportRequestDoneAction) {
    yield put(setProcessIndicator(false, payload.process));
}

function getLocalNodeType(nodeType: TreeItemType, locale: Locale): string {
    const intl = LocalesService.useIntl(locale);

    switch (nodeType) {
        case TreeItemType.Repository: {
            return intl.formatMessage(messages.conflictDialogItemTypeDB);
        }
        case TreeItemType.Folder: {
            return intl.formatMessage(messages.conflictDialogItemTypeFolder);
        }
        case TreeItemType.Model: {
            return intl.formatMessage(messages.conflictDialogItemTypeModel);
        }
        case TreeItemType.ObjectDefinition: {
            return intl.formatMessage(messages.conflictDialogItemTypeObjectDefinition);
        }
        case TreeItemType.Wiki: {
            return intl.formatMessage(messages.conflictDialogItemTypeWiki);
        }
        case TreeItemType.Matrix: {
            return intl.formatMessage(messages.conflictDialogItemTypeMatrix);
        }
        case TreeItemType.File: {
            return intl.formatMessage(messages.conflictDialogItemTypeFile);
        }
        case TreeItemType.SimulationModeling: {
            return intl.formatMessage(messages.conflictDialogItemTypeSimulation);
        }
        case TreeItemType.Spreadsheet: {
            return intl.formatMessage(messages.conflictDialogItemTypeSpreadsheet);
        }
        case TreeItemType.Kanban: {
            return intl.formatMessage(messages.conflictDialogItemTypeKanban);
        }
        case TreeItemType.Server:
        case TreeItemType.Script:
        case TreeItemType.ScriptFolder:
        case TreeItemType.AdminTool:
        case TreeItemType.FileFolder:
        case TreeItemType.Default:
        default:
            return '';
    }
}

function* dialogCancel() {
    yield put(closeDialog(DialogType.CONFLICT_RESOLUTION_DIALOG));
}

export function* importDialogSagaInit() {
    yield takeEvery(IMPORT_DIALOG_CANCEL, dialogCancel);
    yield takeEvery(IMPORT_DIALOG_INIT, importDialogInit);
    yield takeEvery(IMPORT_DIALOG_IMPORT_REQUEST_DONE, handleImportDialogRequestDone);
}
