import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import umMessages from '../../UserManagement/messages/userManagment.messages';
import { Row, Col, Input } from 'antd';
import accessTypesMsg from '../../../../models/userAccesses.messages';
import { UserDTOAccessesEnum } from '../../../../serverapi/api';
import { SearchOutlined } from '@ant-design/icons';

type TAccessPermissionsEditingTabToolBarProps = {
    accessType: UserDTOAccessesEnum;
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default class AccessPermissionsEditingTabToolBar extends Component<TAccessPermissionsEditingTabToolBarProps> {
    render() {
        const { accessType, handleSearch } = this.props;

        return (
            <Row gutter={16}>
                <Col span={8}>
                    <FormattedMessage {...umMessages.accessesRight} />
                    {accessTypesMsg[accessType] && (
                        <b>
                            {`${': '}`}
                            <FormattedMessage {...accessTypesMsg[accessType]} />
                        </b>
                    )}
                </Col>
                <Col span={6}>
                    <Input suffix={<SearchOutlined />} onChange={handleSearch} style={{ width: 200 }} />
                </Col>
            </Row>
        );
    }
}
