import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import GroupUsers from '../components/GroupUsers.component';
import { TGroupUsersActionProps, TGroupUsersOwnProps, TGroupUsersProps } from '../GroupUsers.types';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { GroupsSelectors } from '../../../../selectors/groups.selectors';
import { GroupDTO, UserDTO } from '../../../../serverapi/api';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { TRemoveUsersFromGroupPayload } from '../../../../actions/groups.actions.types';
import { withWindowResize } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';

const mapStateToProps = (state: TRootState, ownProps: TGroupUsersOwnProps): Partial<TGroupUsersProps> => {
    const serverId: string = MetaDataSelectors.getCurrentServerId(state);
    const groupEdititng: GroupDTO = GroupsSelectors.getEditing(ownProps.groupId)(state);

    return {
        groupId: groupEdititng && groupEdititng.id,
        serverId,
        groupUsers:
            groupEdititng && groupEdititng.id
                ? UsersSelectors.byGroupId(serverId, groupEdititng.id)(state)
                : Array<UserDTO>(),
    };
};

const mapDispatchToProps = (dispatch, ownProps: TGroupUsersOwnProps): TGroupUsersActionProps => ({
    onAddClicked: () => dispatch(openDialog(DialogType.ADD_USER_TO_GROUP, { groupId: ownProps.groupId })),
    onDeleteClicked: (removePayload: TRemoveUsersFromGroupPayload) =>
        dispatch(openDialog(DialogType.REMOVE_USER_FROM_GROUP, { removePayload })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withWindowResize(GroupUsers));
