import { MxShape, MxSvgCanvas2D } from '../mxgraph';

export class UMLFrameTitle extends MxShape {
    paintBackground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        const angleSize = 0.05 * w;

        c.translate(x, y);
        c.begin();
        c.moveTo(0, h);
        c.lineTo(w - angleSize * 2, h);
        c.lineTo(w, h - angleSize);
        c.lineTo(w, 0);
        c.end();
    }

    paintForeground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        c.fillAndStroke();
    }
}
