import React from 'react';
import theme from './Radio.scss';
import classNames from 'classnames';

type TRadioProps = {
    title: string | JSX.Element;
    subtitle?: string;
    checked: boolean;
    disabled?: boolean;
    dataTest?: string;
    onChange: () => void;
};

/**
 * @param {string | JSX.Element} title текст рядом с радиокнопкой
 * @param {string} subtitle серый текст под title
 * @param {boolean} checked активное состояние радиокнопки
 * @param {string} value значение
 * @param {boolean | undefined} disabled дизейбл
 * @param {string | undefined} dataTest идентификатор для тестов
 * @param {() => void} onChange функция, которая будет вызвана при выборе опции
 */
export const Radio = (props: TRadioProps) => {
    const { checked, disabled, onChange, title, subtitle, dataTest } = props;

    return (
        <label className={classNames(theme.radioContainer, {[theme.disabledRadio]: disabled})} data-test={dataTest}>
            <input
                type="radio"
                disabled={disabled}
                checked={checked}
                onChange={onChange}
                className={theme.radioInput}
            />
            <span className={theme.radioInner} />
            <div className={theme.radioContent}>
                <span className={theme.radioTitle}>{title}</span>
                {subtitle && <span className={theme.radioSubtitle}>{subtitle}</span>}
            </div>
        </label>
    );
};
