import { BorderGeneratorSettings, IconGeneratorSettings, SymbolGeneratorSettings, SymbolSettingsTypeEnum } from '../../../../../../../../../serverapi/api';
import { TSymbolsSettings, TTmpIcons } from '../SymbolGenerator.types';
import { getSymbolAmountOfBorders } from '../utils/defaultSymbolSettings';

export const SymbolGeneratorBllService = {
    changeParametersHandler(
        prev: TSymbolsSettings,
        settings: IconGeneratorSettings | SymbolGeneratorSettings | BorderGeneratorSettings,
        settingType: string,
        symbolType: SymbolSettingsTypeEnum,
    ) {
        return {
            ...prev,
            [symbolType]: {
                ...prev[symbolType],
                [settingType]: {
                    ...prev[symbolType]?.[settingType],
                    ...settings,
                },
            },
        };
    },
    changeBorderStyle(
        prev: TSymbolsSettings,
        settings: any,
        settingType: string,
        symbolType: SymbolSettingsTypeEnum,
        i: number,
    ) {
        const bordersCount = getSymbolAmountOfBorders(symbolType);
        const s = {
            ...prev,
            [symbolType]: {
                ...prev[symbolType],
                [settingType]: {
                    ...prev[symbolType]?.[settingType],
                    [i]: { ...prev[symbolType]?.[settingType]?.[i], ...settings },
                },
            },
        };
        s[symbolType].borders = Array.from(new Array(bordersCount), (_, index) => index).map(
            (idx) => s[symbolType]?.borders?.[idx] || ([] as BorderGeneratorSettings),
        );

        return s;
    },
    setTmpIcon(prev: TTmpIcons, graphical: string | ArrayBuffer | null | undefined, symbolType: SymbolSettingsTypeEnum) {
        return {
            ...prev,
            [symbolType]: {
                ...prev[symbolType],
                graphical,
            },
        };
    },
};
