import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { submitSimulationNode } from '../../../actions/simulationModeling.actions';
import { NodeId } from '../../../serverapi/api';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { NewSimulationNodeDialog } from '../components/SimulationModeling/Dialog/NewSimulationNodeDialog.component';

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeDialog(DialogType.SIMULATION_DIALOG)),
    onSubmit: (name: string, parentNodeId: NodeId) => dispatch(submitSimulationNode(name, parentNodeId)),
});

export const SimulationDialogContainer = connect(null, mapDispatchToProps)(NewSimulationNodeDialog);
