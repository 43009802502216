import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'DeleteNodeDialog.title',
        defaultMessage: 'Удаление узла дерева',
    },
    deleteNode: {
        id: 'DeleteNodeDialog.deleteNode',
        defaultMessage: 'Удалить',
    },
    cancel: {
        id: 'DeleteNodeDialog.cancel',
        defaultMessage: 'Отмена',
    },
    text: {
        id: 'DeleteNodeDialog.text',
        defaultMessage: 'Вы уверены, что хотите удалить {type} "{name}"?',
    },
    more: {
        id: 'DeleteNodeDialog.more',
        defaultMessage: 'ещё'
    },
    tabsToBeClosed: {
        id: 'DeleteNodeDialog.tabsToBeClosed',
        defaultMessage: 'Будут закрыты вкладки: ',
    },
    hasChildren: {
        id: 'DeleteNodeDialog.hasChildren',
        defaultMessage: '{type} содержит элементы ({count}), они также будут удалены!',
    },
    hasObjectEntries: {
        id: 'DeleteNodeDialog.hasObjectEntries',
        defaultMessage: 'Экземпляры будут удалены на следующих моделях ({entriesCount}) :',
    },
    entryDeleteMessage: {
        id: 'DeleteNodeDialog.entryDeleteMessage',
        defaultMessage: 'Количество удаляемых экземпляров:',
    },
});
