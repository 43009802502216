import {
    ADD_SEARCHED_DELETED_ELEMENTS,
    CLEAR_SEARCHED_DELETED_ELEMENTS,
    ERASE_SEARCHED_DELETED_ELEMENTS,
    ERASE_SEARCHED_DELETED_ELEMENTS_SUCCESS,
    RESTORE_SEARCHED_DELETED_ELEMENTS,
    RESTORE_SEARCHED_DELETED_ELEMENTS_SUCCESS,
    SEARCH_DELETED_ELEMENTS,
} from '@/actionsTypes/searchedDeletedElements.actionTypes';
import {
    TAddSearchedDeletedElementsAction,
    TAddSearchedDeletedElementsActionPayload,
    TClearSearchedDeletedElementsAction,
    TClearSearchedDeletedElementsActionPayload,
    TEraseSearchedDeletedElementsAction,
    TEraseSearchedDeletedElementsPayload,
    TEraseSearchedDeletedElementsSuccessAction,
    TEraseSearchedDeletedElementsSuccessPayload,
    TRestoreSearchedDeletedElementsAction,
    TRestoreSearchedDeletedElementsPayload,
    TRestoreSearchedDeletedElementsSuccessAction,
    TRestoreSearchedDeletedElementsSuccessPayload,
    TSearchDeletedElementsAction,
    TSearchDeletedElementsActionPayload,
} from './searchedDeletedElements.actions.types';

export const addSearchedDeletedElements = (
    payload: TAddSearchedDeletedElementsActionPayload,
): TAddSearchedDeletedElementsAction => ({
    type: ADD_SEARCHED_DELETED_ELEMENTS,
    payload,
});

export const clearSearchedDeletedElements = (
    payload: TClearSearchedDeletedElementsActionPayload,
): TClearSearchedDeletedElementsAction => ({
    type: CLEAR_SEARCHED_DELETED_ELEMENTS,
    payload,
});

export const searchDeletedElements = (payload: TSearchDeletedElementsActionPayload): TSearchDeletedElementsAction => ({
    type: SEARCH_DELETED_ELEMENTS,
    payload,
});

export const eraseSearchedDeletedElements = (
    payload: TEraseSearchedDeletedElementsPayload,
): TEraseSearchedDeletedElementsAction => ({
    type: ERASE_SEARCHED_DELETED_ELEMENTS,
    payload,
});

export const eraseSearchedDeletedElementsSuccess = (
    payload: TEraseSearchedDeletedElementsSuccessPayload,
): TEraseSearchedDeletedElementsSuccessAction => ({
    type: ERASE_SEARCHED_DELETED_ELEMENTS_SUCCESS,
    payload,
});

export const restoreSearchedDeletedElements = (
    payload: TRestoreSearchedDeletedElementsPayload,
): TRestoreSearchedDeletedElementsAction => ({
    type: RESTORE_SEARCHED_DELETED_ELEMENTS,
    payload,
});

export const restoreSearchedDeletedElementsSuccess = (
    payload: TRestoreSearchedDeletedElementsSuccessPayload,
): TRestoreSearchedDeletedElementsSuccessAction => ({
    type: RESTORE_SEARCHED_DELETED_ELEMENTS_SUCCESS,
    payload,
});
