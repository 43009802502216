import { PresetImageGroup } from '../../serverapi/api';
import { TReducer } from '../../utils/types';
import {
    ADD_PRESET_IMAGE_GROUPS,
    DELETE_PRESET_IMAGE_GROUP_SUCCESS,
    SUBMIT_PRESET_IMAGE_GROUP,
} from '../../actionsTypes/presetSettings/presetImageGroup.actionTypes';
import { TPresetImageGroupState } from './presetImageGroup.reducer.types';
import { CLEAR_PRESET_REQUEST } from '../../actionsTypes/methodologySetting.actionTypes';

export const INITIAL_PRESET_IMAGE_SERVER_STATE = {
    byPresetId: {},
};

const initial: TPresetImageGroupState = {
    byServerId: {},
};

const initStateWithServerAndPreset = (state: TPresetImageGroupState, serverId: string, presetId: string) => {
    if (!state.byServerId[serverId]) {
        state.byServerId[serverId] = {
            byPresetId: {},
        };
    }
    if (!state.byServerId[serverId].byPresetId[presetId]) {
        state.byServerId[serverId].byPresetId[presetId] = {
            byId: {},
        };
    }
};

export const presetImageGroupReducer: TReducer<TPresetImageGroupState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_PRESET_IMAGE_GROUPS:
        case SUBMIT_PRESET_IMAGE_GROUP: {
            const {
                payload: { serverNode, preset: {id: presetId}, presetImageGroups },
            } = action;
            const stateClone: TPresetImageGroupState = JSON.parse(JSON.stringify(state));
            const {serverId} = serverNode.nodeId;
            if (serverId && presetId) {
                initStateWithServerAndPreset(stateClone, serverId, presetId);
                const preset = stateClone.byServerId[serverId].byPresetId[presetId];
                preset.byId = {
                    ...preset.byId,
                    ...presetImageGroups.reduce((acc, presetImageGroup: PresetImageGroup) => {
                        acc[presetImageGroup.id] = presetImageGroup;

                        return acc;
                    }, {}),
                };
            }

            return stateClone;
        }

        case DELETE_PRESET_IMAGE_GROUP_SUCCESS: {
            const { presetImageGroups, serverNode } = action.payload;
            if (presetImageGroups) {
                const stateClone = JSON.parse(JSON.stringify(state));
                const {serverId} = serverNode.nodeId;
                presetImageGroups.forEach((presetImageGroup) => {
                    const {presetId} = presetImageGroup;
                    if (serverId && presetId) {
                        const byId = stateClone.byServerId[serverId]?.byPresetId[presetId]?.byId || {};
                        delete byId[presetImageGroup.id];
                    }
                });

                return stateClone;
            }

            return state;
        }

        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }

        default:
            return state;
    }
};
