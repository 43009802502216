import type { EdgeDefinitionNode } from '../../serverapi/api';
import type {
    TCreateEdgeDefinitionAction,
    TTCreateEdgeDefinitionPayload,
    TEdgeDefinitionsAddAction,
    TEdgeDefinitionsAddPayload,
    TUpdateEdgeDefinitionNameAction,
    TEdgeDefinitionsUpdateAction,
    TInitEdgeDefinitionCreationPayload,
    TInitEdgeDefinitionCreationAction,
    TEdgeDefinitionsAddOnlyNewAction,
    TCreateEdgeDefinitionFromMatrixAction,
    TTCreateEdgeDefinitionFromMatrixPayload,
} from './edgeDefinitions.actions.types';
import {
    CREATE_EDGE_DEFINITION,
    CREATE_EDGE_DEFINITION_FROM_MATRIX,
    EDGE_DEFINITIONS_ADD,
    EDGE_DEFINITIONS_ADD_ONLY_NEW,
    EDGE_DEFINITIONS_UPDATE,
    INIT_EDGE_DEFINITION_CREATION,
    UPDATE_EDGE_DEFINITION_NAME,
} from '../../actionsTypes/entities/edgeDefinition.actionTypes';

export const initEdgeDefinitionCreation = (
    payload: TInitEdgeDefinitionCreationPayload,
): TInitEdgeDefinitionCreationAction => ({
    type: INIT_EDGE_DEFINITION_CREATION,
    payload,
});

export const createEdgeDefinition = (payload: TTCreateEdgeDefinitionPayload): TCreateEdgeDefinitionAction => ({
    type: CREATE_EDGE_DEFINITION,
    payload,
});

export const edgeDefinitionsAdd = (payload: TEdgeDefinitionsAddPayload): TEdgeDefinitionsAddAction => ({
    type: EDGE_DEFINITIONS_ADD,
    payload,
});

export const edgeDefinitionsUpdate = (edgeDefinition: EdgeDefinitionNode): TEdgeDefinitionsUpdateAction => ({
    type: EDGE_DEFINITIONS_UPDATE,
    payload: { edgeDefinition },
});

export const updateEdgeDefinitionName = (
    serverId: string,
    edgeDefinition: EdgeDefinitionNode,
    name: string,
): TUpdateEdgeDefinitionNameAction => ({
    type: UPDATE_EDGE_DEFINITION_NAME,
    payload: { serverId, edgeDefinition, name },
});

export const edgeDefinitionsAddOnlyNew = (edgeDefinitions: EdgeDefinitionNode[]): TEdgeDefinitionsAddOnlyNewAction => ({
    type: EDGE_DEFINITIONS_ADD_ONLY_NEW,
    payload: { edgeDefinitions },
});

export const createEdgeDefinitionFromMatrix = (
    payload: TTCreateEdgeDefinitionFromMatrixPayload,
): TCreateEdgeDefinitionFromMatrixAction => ({
    type: CREATE_EDGE_DEFINITION_FROM_MATRIX,
    payload,
});
