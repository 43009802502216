import type { MxCell } from 'MxGraph';
import { SymbolTypeId, FRAME_STYLES_INDICATOR } from '../ComplexSymbol.constants';
import ObjectInstanceSerializer from '../serializers/ObjectInstanceSerializer.class';
import SwimLaneSymbol from './SwimLaneSymbol.class';

export class VerticalSwimLaneSymbol extends SwimLaneSymbol {
    complexSymbolTypeId = SymbolTypeId.VERTICAL_SWIMLANE;
    serializer = new ObjectInstanceSerializer();

    insert(): MxCell {
        const { graph } = this;
        const [width, height] = [120, 460];
        const { x = 0, y = 0, parent = graph.getDefaultParent() } = this.rootCellValue;
        const style = `${FRAME_STYLES_INDICATOR};swimlane;startSize=30;horizontal=1;`;
        const swimlane = graph.insertVertexFromValue({
            ...this.rootCellValue,
            parent,
            x,
            y,
            width,
            height,
            style,
        });

        return swimlane;
    }

    restore(): MxCell {
        const { graph } = this;
        const { x = 0, y = 0, width = 120, height = 460, parent = graph.getDefaultParent() } = this.rootCellValue;
        const style = this.rootCellValue.style || `${FRAME_STYLES_INDICATOR};swimlane;startSize=30;horizontal=1;`;
        const swimlane = graph.insertVertexFromValue({
            ...this.rootCellValue,
            parent,
            x,
            y,
            width,
            height,
            style,
        });

        return swimlane;
    }
}
