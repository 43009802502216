import {
    DELETE_MONITORING_STATUS,
    LOAD_MONITORING_INFO_SUCCESS,
    MONITORING_INFO_LOADING,
} from '../actionsTypes/monitoring.actionTypes';
import { TMonitoringStatusState } from './monitoring.reducer.types';

const initial: TMonitoringStatusState = {};

export const monitoringReducer = (state = initial, action) => {
    switch (action.type) {
        case LOAD_MONITORING_INFO_SUCCESS: {
            const { serverId, status } = action.payload;

            return {
                ...state,
                [serverId]: {
                    ...state[serverId],
                    status,
                },
            };
        }
        case MONITORING_INFO_LOADING: {
            const { isLoading, serverId } = action.payload;

            return {
                ...state,
                [serverId]: {
                    ...state[serverId],
                    isLoading,
                },
            };
        }
        case DELETE_MONITORING_STATUS: {
            delete state[action.payload.serverId];
            if (!Object.keys(state)) return {};

            return state;
        }
        default:
            return state;
    }
};
