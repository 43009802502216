import { defineMessages } from 'react-intl';

export default defineMessages({
    message: {
        id: 'DeleteWidgetDialog.message',
        defaultMessage: 'Вы действительно хотите удалить виджет "{name}"?',
    },

    cancel: {
        id: 'DeleteWidgetDialog.cancel',
        defaultMessage: 'Отмена',
    },
    delete: {
        id: 'DeleteWidgetDialog.delete',
        defaultMessage: 'Удалить',
    },
    title: {
        id: 'DeleteWidgetDialog.Title',
        defaultMessage: 'Удалить виджет?',
    },
});
