import { InstanceDescriptor } from '../models/security/Principal.types';
import { InstancePermissions, PermissionModelPermissionEnum, PrincipalDescriptor } from '../serverapi/api';
import { TreeNode } from '../models/tree.types';
import {
    INSTANCE_PERMISSIONS_ADD_PRINCIPALS,
    INSTANCE_PERMISSIONS_DATA,
    INSTANCE_PERMISSIONS_DIALOG_SUBMIT_DATA,
    INSTANCE_PERMISSIONS_DIALOG_SUBMIT_RESULT,
    INSTANCE_PERMISSIONS_FAILURE,
    INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_DELETE,
    INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_UPDATE,
    INSTANCE_PERMISSIONS_REQUEST,
} from '../actionsTypes/instancePermissions.actionTypes';
import {
    TAddPrincipalsAction,
    TInstancePermissionsDataAction,
    TInstancePermissionsDialogSubmitDataAction,
    TInstancePermissionsDialogSubmitResultAction,
    TInstancePermissionsFailureAction,
    TInstancePermissionsRequestAction,
    TPrincipalPermissionsDeleteAction,
    TPrincipalPermissionsUpdateAction,
} from './instancePermissions.actions.types';

export const instancePermissionsRequest = (
    instanceDescriptor: InstanceDescriptor,
): TInstancePermissionsRequestAction => ({
    type: INSTANCE_PERMISSIONS_REQUEST,
    payload: { instanceDescriptor },
});

export const instancePermissionsFailure = (error: string = ''): TInstancePermissionsFailureAction => ({
    type: INSTANCE_PERMISSIONS_FAILURE,
    payload: { error },
});

export const addPrincipals = (principals: Array<PrincipalDescriptor>): TAddPrincipalsAction => ({
    type: INSTANCE_PERMISSIONS_ADD_PRINCIPALS,
    payload: { principals },
});

export const principalPermissionsDelete = (selectedIds: number[] = []): TPrincipalPermissionsDeleteAction => ({
    type: INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_DELETE,
    payload: { selectedIds },
});

export const principalPermissionsUpdate = (
    principalId: number,
    actionType: PermissionModelPermissionEnum,
    isGranting: boolean | undefined,
): TPrincipalPermissionsUpdateAction => ({
    type: INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_UPDATE,
    payload: { principalId, actionType, isGranting },
});

export const instancePermissionsData = (
    serverId: string,
    data: InstancePermissions,
): TInstancePermissionsDataAction => ({
    type: INSTANCE_PERMISSIONS_DATA,
    payload: {
        serverId,
        data,
    },
});

export const instancePermissionsDialogSubmitData = (
    treeItem: TreeNode,
): TInstancePermissionsDialogSubmitDataAction => ({
    type: INSTANCE_PERMISSIONS_DIALOG_SUBMIT_DATA,
    payload: { treeItem },
});

export const instancePermissionsDialogSubmitResult = (): TInstancePermissionsDialogSubmitResultAction => ({
    type: INSTANCE_PERMISSIONS_DIALOG_SUBMIT_RESULT,
});
