// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommonSettings__group___2G6O{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;grid-gap:16px;gap:16px;padding:16px;border-bottom:1px solid #d6dee2}.CommonSettings__select___ZRiL button{border-radius:4px;margin-bottom:0}.CommonSettings__container__OKJxS{padding:8px 16px 0 16px}.CommonSettings__container__OKJxS button{width:100%}", "",{"version":3,"sources":["webpack://./src/modules/Report/ReportEditor/ReportEditorSidebar/CommonSettings/CommonSettings.scss"],"names":[],"mappings":"AAAA,8BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,aAAA,CAAA,QAAA,CACA,YAAA,CACA,+BAAA,CAIA,sCACI,iBAAA,CACA,eAAA,CAIR,kCACI,uBAAA,CACA,yCACI,UAAA","sourcesContent":[".group {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 16px;\r\n    padding: 16px;\r\n    border-bottom: 1px solid #d6dee2;\r\n}\r\n\r\n.select {\r\n    button {\r\n        border-radius: 4px;\r\n        margin-bottom: 0;\r\n    }\r\n}\r\n\r\n.container {\r\n    padding: 8px 16px 0 16px;\r\n    button {\r\n        width: 100%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "CommonSettings__group___2G6O",
	"select": "CommonSettings__select___ZRiL",
	"container": "CommonSettings__container__OKJxS"
};
export default ___CSS_LOADER_EXPORT___;
