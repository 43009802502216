import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../../../messages/CommonToolbar.messages';
import menuItemTheme from '../../../items/MenuItem.scss';
import { createCompactableSelect } from '../../../CompactableSelect/CompactableSelect.component';
import { BpmNodeFilterBase } from '../../../../../../models/bpmNodeFilterBase';
import { TCompactableSelectItemType } from '../../../CompactableSelect/CompactableSelect.types';
import icOff from '../../../../../../resources/icons/ic-node-filter-off.svg';
import icSingle from '../../../../../../resources/icons/ic-node-filter-single.svg';
import icMany from '../../../../../../resources/icons/ic-node-filter-many.svg';
import icNotSame from '../../../../../../resources/icons/ic-node-filter-not-same.svg';
import icHightLight from '../../../../../../resources/icons/highlight.svg';
import { NodeId } from '../../../../../../serverapi/api';
import { workspaceTabSetParams } from '../../../../../../actions/tabs.actions';

interface ISwitchFilterModeSelectProps {
    compact: boolean | undefined;
    nodeFilterBase: BpmNodeFilterBase;
    disabled: boolean;
    tabNodeId: NodeId | undefined;
};

const CompactableSelect = createCompactableSelect<BpmNodeFilterBase>();

export const SwitchFilterModeSelect: FC<ISwitchFilterModeSelectProps> = (props) => {
    const { compact, nodeFilterBase, disabled, tabNodeId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const onFilterNodeBaseAction = (nodeFilterBase: BpmNodeFilterBase) => {
        tabNodeId && dispatch(workspaceTabSetParams(tabNodeId, { nodeFilterBase }));
    };

    const nodeFilterItems = [
        {
            type: TCompactableSelectItemType.Value,
            value: BpmNodeFilterBase.Off,
            label: intl.formatMessage(messages.nodeFilterOff),
            spriteSymbol: icOff,
        },
        {
            type: TCompactableSelectItemType.Value,
            value: BpmNodeFilterBase.Single,
            label: intl.formatMessage(messages.nodeFilterSingle),
            spriteSymbol: icSingle,
        },
        {
            type: TCompactableSelectItemType.Value,
            value: BpmNodeFilterBase.Many,
            label: intl.formatMessage(messages.nodeFilterMany),
            spriteSymbol: icMany,
        },
        {
            type: TCompactableSelectItemType.Value,
            value: BpmNodeFilterBase.NotSame,
            label: intl.formatMessage(messages.nodeFilterNotSame),
            spriteSymbol: icNotSame,
        },
    ];

    const activeNodeFilter = nodeFilterItems.find((item) => item.value === (nodeFilterBase || BpmNodeFilterBase.Off));

    const nodeFilterTitle =
        activeNodeFilter && activeNodeFilter.value !== BpmNodeFilterBase.Off
            ? activeNodeFilter.label
            : intl.formatMessage(messages.nodeFilterDisabled);

    return (
        <div className={menuItemTheme.groupRow} data-test="main-panel_button-filtering">
            <span>
                <CompactableSelect
                    className="filter-select"
                    title={nodeFilterTitle}
                    disabledTitle={intl.formatMessage(messages.onlyInReadModeAvailable)}
                    compact={compact}
                    items={nodeFilterItems}
                    disabled={disabled}
                    value={nodeFilterBase}
                    onSelect={onFilterNodeBaseAction}
                    defaultSprite={icHightLight}
                />
            </span>
        </div>
    );
};
