import { defineMessages } from 'react-intl';

export default defineMessages({
    dialogName: {
        id: 'ImportPresetDialog.dialogName',
        defaultMessage: 'Найдены конфликты',
    },
    dialogLabel: {
        id: 'ImportPresetDialog.dialogLabel',
        defaultMessage: 'Выберите один из способов решения конфликта для элемента:',
    },
    mergeError: {
        id: 'ImportPresetDialog.mergeError',
        defaultMessage: 'Не удается решить конфликт',
    },
    importDialogTitle: {
        id: 'ImportPresetDialog.importDialogTitle',
        defaultMessage: 'Выбор ранее выгруженного файла экспорта',
    },
    exportDialogTitle: {
        id: 'ImportPresetDialog.exportDialogTitle',
        defaultMessage: 'Сохранить файл экспорта',
    },
    dialogCloneRepository: {
        id: 'ImportPresetDialog.cloneRepository',
        defaultMessage: 'Клонирование БД',
    },
    dialogNewNameRepository: {
        id: 'ImportPresetDialog.newName',
        defaultMessage: 'Введите новое имя БД:',
    },
    dialogReplaceRepositoryName: {
        id: 'ImportPresetDialog.replaceRepositoryName',
        defaultMessage: 'Замена БД',
    },
    dialogReplaceRepository: {
        id: 'ImportPresetDialog.replaceRepository',
        defaultMessage: 'Эта операция полностью заменит БД',
    },
    dialogConfirmationReplaceRepository: {
        id: 'ImportPresetDialog.confirmationReplaceRepository',
        defaultMessage: 'Операцию невозможно будет отменить. Вы уверены?',
    },
    conflictDialogItemTypeDB: {
        id: 'ImportPresetDialog.conflictItemType.DB',
        defaultMessage: 'База данных',
    },
    conflictDialogItemTypeFolder: {
        id: 'ImportPresetDialog.conflictItemType.Folder',
        defaultMessage: 'Папка',
    },
    conflictDialogItemTypeModel: {
        id: 'ImportPresetDialog.conflictItemType.Model',
        defaultMessage: 'Модель',
    },
    conflictDialogItemTypeObjectDefinition: {
        id: 'ImportPresetDialog.conflictItemType.ObjectDefinition',
        defaultMessage: 'Объект',
    },
    conflictDialogItemTypeWiki: {
        id: 'ImportPresetDialog.conflictItemType.Wiki',
        defaultMessage: 'Wiki-страница',
    },
    conflictDialogItemTypeMatrix: {
        id: 'ImportPresetDialog.conflictItemType.Matrix',
        defaultMessage: 'Матричная модель',
    },
    conflictDialogForNodeButton: {
        id: 'ImportPresetDialog.conflictDialogForNodeButton',
        defaultMessage: 'Подтвердить',
    },
    conflictDialogForAllNodesButton: {
        id: 'ImportPresetDialog.conflictDialogForAllNodesButton',
        defaultMessage: 'Для всех узлов',
    },
    cancelButton: {
        id: 'ImportPresetDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    conflictDialogReplaceRadio: {
        id: 'ImportPresetDialog.conflictDialogReplaceRadio',
        defaultMessage: 'Заменить имеющийся',
    },
    conflictDialogCloneRadio: {
        id: 'ImportPresetDialog.conflictDialogCloneRadio',
        defaultMessage: 'Создать клон узла с новым ID',
    },
    conflictDialogNotAccessToNode: {
        id: 'ImportPresetDialog.conflictDialogNotAccessToNode',
        defaultMessage: 'Нет прав доступа к существующему элементу.',
    },
    conflictDialogNoAccessInSet: {
        id: 'ImportPresetDialog.conflictDialogNoAccessInSet',
        defaultMessage: 'В списке конфликтов присутствуют узлы, к которым недостаточно прав доступа.',
    },
    conflictDialogNodeInfo: {
        id: 'ImportPresetDialog.conflictDialogNodeInfo',
        defaultMessage: `ID: {id}
Тип: {itemTypeLocal}
Путь: {path}`,
    },
});
