import { defineMessages } from 'react-intl';

export default defineMessages({
    errorMessage: {
        id: 'AttributesEditor.errorMessage',
        defaultMessage: 'Обязательно для заполнения',
    },
    true: {
        id: 'AttributesEditor.yes',
        defaultMessage: 'Да',
    },
    false: {
        id: 'AttributesEditor.no',
        defaultMessage: 'Нет',
    },
    undefined: {
        id: 'AttributesEditor.undefined',
        defaultMessage: 'Пусто',
    },
});
