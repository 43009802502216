import { all, cancelled, put, select, take, takeEvery } from 'redux-saga/effects';
import { OPEN_DOCUMENT_REQUEST } from '../actionsTypes/recent.actionTypes';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { DIALOG_CLOSE } from '../actionsTypes/dialogs.actionTypes';
import { AUTHORIZATION_SUCCESS } from '../actionsTypes/authorization.actionTypes';
import { TServerEntity } from '../models/entities.types';
import { openAuthServerDialog } from '../actions/entities/servers.actions';
import { openNode, openNodeById } from '../actions/openNode.actions';
import { TOpenNodeByIdRequestAction, TOpenNodeRequestAction } from '../actions/openNode.actions.types';
import { openNodeByTreeItemNodeId } from './tree.saga';
import { TreeItemType } from '../modules/Tree/models/tree';
import { OPEN_DOCUMENT_REQUEST_BY_ID } from '../actionsTypes/openNode.actionTypes';
import { Node } from '../serverapi/api';
import { nodeService } from '../services/NodeService';

function* handleOpenNodeByType({ payload: { nodeId, type, elementIds } }: TOpenNodeRequestAction) {
    const connectedServers = yield select(ServerSelectors.connected);
    const { serverId } = nodeId;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    if (connectedServers.includes(serverId)) {
        yield openNodeByTreeItemNodeId(nodeId, type, elementIds);
    } else {
        try {
            yield put(openAuthServerDialog(server.id));
            const authResult = yield put(yield take([AUTHORIZATION_SUCCESS, DIALOG_CLOSE]));
            if (authResult.type === DIALOG_CLOSE) {
                yield cancelled();
            } else {
                yield put(openNode({ nodeId, type, elementIds }));
            }
        } catch (e) {
            throw new Error(`Server with id=[${serverId}] does not exists`);
        }
    }
    yield all([]);
}

function* handleOpenNodeById({ payload: { nodeId } }: TOpenNodeByIdRequestAction) {
    const connectedServers = yield select(ServerSelectors.connected);
    const { serverId } = nodeId;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    if (connectedServers.includes(serverId)) {
        const node: Node | undefined = yield nodeService().loadNodeFromServer(nodeId);
        yield put(openNode({ nodeId, type: node?.type! as TreeItemType }));
    } else {
        try {
            yield put(openAuthServerDialog(server.id));
            const authResult = yield put(yield take([AUTHORIZATION_SUCCESS, DIALOG_CLOSE]));
            if (authResult.type === DIALOG_CLOSE) {
                yield cancelled();
            } else {
                yield put(openNodeById({ nodeId }));
            }
        } catch (e) {
            throw new Error(`Server with id=[${serverId}] does not exists`);
        }
    }
    yield all([]);
}

export function* openNodeSaga() {
    yield takeEvery(OPEN_DOCUMENT_REQUEST, handleOpenNodeByType);
    yield takeEvery(OPEN_DOCUMENT_REQUEST_BY_ID, handleOpenNodeById);
}
