import { LdapProperties, SystemKeycloakSettings } from '../../../serverapi/api';
import { LocalesService } from '../../../services/LocalesService';
import messages from '../../../modules/App/messages/AppNotifications.messages';
import { isEmpty } from 'lodash-es';

export const ts = (n: number | undefined) => n && n / 1000;

export const tms = (n: number | undefined) => n && n * 1000;

const transformValuesMap = {
    modelAutoSaveTimeout: tms,
    modelVersionSaveTimeout: tms,
    userSessionInactivity: tms,
    userSessionLengthMax: tms,
};

export const transformValues = (values) =>
    Object.keys(values).reduce((previous, current) => {
        const hasTransform = typeof transformValuesMap[current] === 'function';

        return {
            ...previous,
            [current]: hasTransform ? transformValuesMap[current](values[current]) : values[current],
        };
    }, {});

export function checkLdapProperties(properties: LdapProperties[] | undefined): string | undefined {
    if (!properties) {
        return undefined;
    }
    const intl = LocalesService.useIntl();
    const hasData = (p: LdapProperties) => (p.baseDn && p.bindDn && p.searchFilter && p.serverUrl)?.trim();
    const allPropsHasMandatoryFields = properties.filter((p) => !hasData(p)).length === 0;
    if (!allPropsHasMandatoryFields) {
        return intl.formatMessage(messages.LdapMandatoryFieldsErrorTitle);
    }

    const defaultDomainOneOrNone = properties.filter((p) => p.defaultDomain).length <= 1;
    if (!defaultDomainOneOrNone) {
        return intl.formatMessage(messages.LdapDefaultDomainErrorTitle);
    }

    const urlsAreDifferent = properties.filter((p) => p.serverUrl === p.serverUrlAlt).length === 0;
    if (!urlsAreDifferent) {
        return intl.formatMessage(messages.LdapServerUrlErrorTitle);
    }

    const domains = properties.map((p) => p.domainName).filter((s) => s);
    const isDubDomain = domains.length !== new Set(domains).size;
    if (isDubDomain) {
        return intl.formatMessage(messages.LdapDomainsErrorTitle);
    }

    const nbNames = properties.map((p) => p.domainNameNetBios).filter((s) => s);
    const isDubNbNames = nbNames.length !== new Set(nbNames).size;
    if (isDubNbNames) {
        return intl.formatMessage(messages.LdapNetBiosErrorTitle);
    }

    return undefined;
}

export const validateLdapProperties = (value: LdapProperties[]): Promise<any> => {
    const errorMessage: string | undefined = checkLdapProperties(value);

    return !errorMessage ? Promise.resolve() : Promise.reject(new Error(errorMessage));
};

export const checkKeycloakSettings = (settings: SystemKeycloakSettings | undefined): string | undefined => {
    if (!settings || isEmpty(settings)) {
        return undefined;
    }
    const intl = LocalesService.useIntl();
    const { baseUrl, clientId, realm } = settings;

    const allPropsHasMandatoryFields = baseUrl?.trim() && clientId?.trim() && realm?.trim();

    if (!allPropsHasMandatoryFields) {
        return intl.formatMessage(messages.KeycloakMandatoryFieldsErrorTitle);
    }

    return undefined;
};

export const validateKeycloakSettings = (value: SystemKeycloakSettings): Promise<any> => {
    const errorMessage: string | undefined = checkKeycloakSettings(value);

    return !errorMessage ? Promise.resolve() : Promise.reject(new Error(errorMessage));
};
