import {
    AllowedScriptContext,
    RootNodeId,
    ScriptNode,
    Node,
    DiagramElement,
    ObjectInstance,
    EdgeInstance,
} from '../../serverapi/api';
import { TreeNode, TTreeEntityState } from 'src/models/tree.types';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { treeNestedChildrenMap } from '../../selectors/tree.selectors';
import { compareNodeIds } from '../../utils/nodeId.utils';

export const getParentIds = (nodesObj: { [id: string]: Node }, filteredNodes: Node[]): Set<string> =>
    new Set(
        filteredNodes
            .map((node) =>
                node.parentNodeId?.id
                    ? [node.nodeId.id, ...Array.from(getParentIds(nodesObj, [nodesObj[node.parentNodeId.id]]))]
                    : [node.nodeId.id],
            )
            .flat(),
    );

export const isScriptAllowed = (
    script: TreeNode,
    node: TreeNode | undefined,
    modelNode: TreeNode | undefined,
    element: DiagramElement | undefined,
    isRunFromNavigator: boolean,
): boolean => {
    const { type } = node || {};
    const context: AllowedScriptContext | undefined = (script as ScriptNode).allowedScriptContext;
    if (!context) {
        return false;
    }
    const {
        allowAll,
        allowedNodeIds,
        allowAllFolders,
        allowedFolderTypeIds,
        allowAllFiles,
        allowAllScripts,
        allowAllDBs,
        allowAllModels,
        allowedModelTypeIds,
        allowAllEdges,
        allowedEdgeTypeIds,
        allowAllObjects,
        allowedObjectTypeIds,
        allowObjectInstancesOfAllowedObjectType,
        allowedSymbolTypeIds,
        allowAllSymbols,
        allowedModelSymbols,
    } = context;
    if (allowAll || allowedNodeIds?.find((allowNodeId) => compareNodeIds(allowNodeId, node?.nodeId))) {
        return true;
    }
    if (element?.type === 'edge' && !isRunFromNavigator) {
        return !!(allowAllEdges || allowedEdgeTypeIds?.includes((element as EdgeInstance).edgeTypeId));
    }
    if (element?.type === 'object' && !isRunFromNavigator) {
        return !!(
            allowAllSymbols ||
            ((element as ObjectInstance).objectDefinitionId === node?.nodeId.id &&
                allowedObjectTypeIds?.includes(node?.objectTypeId || '') &&
                allowObjectInstancesOfAllowedObjectType) ||
            allowedSymbolTypeIds?.includes((element as ObjectInstance).symbolId) ||
            allowedModelSymbols
                ?.find((modelSymbol) => modelSymbol.modelTypeIds === modelNode?.modelTypeId)
                ?.symbolIds.includes((element as ObjectInstance).symbolId)
        );
    }
    switch (type) {
        case TreeItemType.Folder: {
            return !!(allowAllFolders || allowedFolderTypeIds?.includes(node?.folderType || ''));
        }
        case TreeItemType.File: {
            return !!allowAllFiles;
        }
        case TreeItemType.Script: {
            return !!allowAllScripts;
        }
        case TreeItemType.Repository: {
            return !!allowAllDBs;
        }
        case TreeItemType.Spreadsheet:
        case TreeItemType.Wiki:
        case TreeItemType.Matrix:
        case TreeItemType.Model: {
            return !!(allowAllModels || allowedModelTypeIds?.includes(node?.modelTypeId || ''));
        }
        case TreeItemType.EdgeDefinition: {
            if (isRunFromNavigator) {
                return !!(allowAllEdges || allowedEdgeTypeIds?.includes(node?.edgeTypeId || ''));
            }

            return false;
        }
        case TreeItemType.ObjectDefinition: {
            if (isRunFromNavigator) {
                return !!(allowAllObjects || allowedObjectTypeIds?.includes(node?.objectTypeId || ''));
            }

            return false;
        }

        default:
            return false;
    }
};

export const filterScriptsByContext = (
    treeItems: { [id: string]: TTreeEntityState },
    node: TTreeEntityState | undefined,
    modelNode: TTreeEntityState | undefined,
    element: DiagramElement | undefined,
    isRunFromNavigator: boolean = true,
): { [id: string]: TTreeEntityState } => {
    const scripts = Object.values(treeItems).filter(
        (item) =>
            item.type !== TreeItemType.Script || isScriptAllowed(item, node, modelNode, element, isRunFromNavigator),
    );

    return scripts.reduce((acc, item) => {
        acc[item.nodeId.id] = item;

        return acc;
    }, {});
};

export const filterScriptsByEvent = (treeItems: {
    [id: string]: TTreeEntityState;
}): { [id: string]: TTreeEntityState } => {
    const scripts = Object.values(treeItems).filter(
        (item) =>
            !!item.allowedScriptContext?.allowAll ||
            !!item.allowedScriptContext?.eventHandlerScript ||
            item.type === 'SCRIPT_FOLDER' ||
            item.type !== 'SCRIPT',
    );

    return scripts.reduce((acc, item) => {
        acc[item.nodeId.id] = item;

        return acc;
    }, {});
};

export const getNestedChildrenScriptsTree = (treeItems: { [id: string]: TTreeEntityState }) => {
    return Object.keys(treeItems).length ?
        treeNestedChildrenMap(treeItems, RootNodeId.ROOT_SCRIPT_FOLDER_ID) : [];
};

export const getFilteredByNameTree = (
    nodes: Node[],
    treeNodes: { [id: string]: TTreeEntityState },
    filter: string,
): TreeNode[] => {
    if (!nodes.length || !filter) return getNestedChildrenScriptsTree(treeNodes);

    const nodesObj: { [id: string]: Node } = nodes.reduce((acc, item) => {
        acc[item.nodeId.id] = item;

        return acc;
    }, {});

    const filteredNodesByName: Node[] = nodes.filter((node) =>
        node?.name?.toUpperCase().includes(filter.toUpperCase()),
    );
    const allParentIds: Set<string> = getParentIds(nodesObj, filteredNodesByName);
    const filteredTreeItems: { [id: string]: TTreeEntityState } = Array.from(allParentIds).reduce((acc, item) => {
        if (!treeNodes[item]) return acc;
        acc[item] = treeNodes[item];

        return acc;
    }, {});

    return getNestedChildrenScriptsTree(filteredTreeItems);
};
