import { TReducer } from '../../utils/types';
import { CustomMap } from '../../utils/map';
import {
    INIT_SYMBOL_EDITOR,
    SYMBOL_EDITOR_UPDATE,
    SYMBOL_SVG_CONVERT_REQUEST_SUCCESS,
} from '../../actionsTypes/symbol/symbolEditor.actionTypes';
import { TSymbolEditorState } from './symbolEditor.reducer.types';

const initial: TSymbolEditorState = {
    data: new CustomMap(),
};

export const symbolEditorReducer: TReducer<TSymbolEditorState> = (state = initial, action) => {
    switch (action.type) {
        case INIT_SYMBOL_EDITOR:
        case SYMBOL_SVG_CONVERT_REQUEST_SUCCESS:
        case SYMBOL_EDITOR_UPDATE: {
            const {
                payload: {
                    serverId,
                    symbol: { id: symbolId, presetId },
                    symbol,
                },
            } = action;
            const mapClone = state.data.clone();
            mapClone.set({ serverId, presetId, symbolId }, symbol);

            return { ...state, data: mapClone };
        }
        default:
            return state;
    }
};
