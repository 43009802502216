import { TreeItemType } from '../modules/Tree/models/tree';
import { ImportResponse, NodeId } from '../serverapi/api';
import { TExecutingProcess } from '../reducers/statusBar.reducer.types';
import { ConflictResolutionStrategy } from '../services/api/custom/TransferApi';
import {
    IMPORT_DIALOG_APPLY_NAME,
    IMPORT_DIALOG_CANCEL,
    IMPORT_DIALOG_IMPORT_REQUEST_DONE,
    IMPORT_DIALOG_INIT,
    IMPORT_DIALOG_REPLACE_CONFIRM,
    IMPORT_DIALOG_SET_STATE,
    IMPORT_DIALOG_SUBMIT_ALL,
    IMPORT_DIALOG_SUBMIT_NODE,
    IMPORT_PRESET_DIALOG_REQUEST_DONE,
} from '../actionsTypes/importDialog.actionTypes';
import {
    TImportDialogInitAction,
    TImportDialogCancelAction,
    TImportDialogSetStateAction,
    TImportDialogSubmitNodeAction,
    TImportDialogSubmitAllAction,
    TImportDialogImportRequestDoneAction,
    TImportPresetDialogRequestDoneAction,
    TImportDialogApplyNameAction,
    TImportDialogReplaceConfirmAction,
} from './importDialog.actions.types';

export const importDialogInit = (process: TExecutingProcess): TImportDialogInitAction => ({
    type: IMPORT_DIALOG_INIT,
    payload: {
        process,
    },
});

export const importDialogCancel = (): TImportDialogCancelAction => ({
    type: IMPORT_DIALOG_CANCEL,
});

export const importDialogSetState = (
    importResponse: ImportResponse,
    type: TreeItemType,
    parentId: NodeId,
    fileUUID: string
): TImportDialogSetStateAction => ({
    type: IMPORT_DIALOG_SET_STATE,
    payload: {
        importResponse,
        type,
        parentId,
        fileUUID
    },
});

export const submitNodeMergeResult = (
    itemId: NodeId,
    conflictResolutionStrategy: ConflictResolutionStrategy,
): TImportDialogSubmitNodeAction => ({
    type: IMPORT_DIALOG_SUBMIT_NODE,
    payload: {
        itemId,
        conflictResolutionStrategy,
    },
});

export const submitAllNodesMergeResult = (
    conflictResolutionStrategy: ConflictResolutionStrategy,
): TImportDialogSubmitAllAction => ({
    type: IMPORT_DIALOG_SUBMIT_ALL,
    payload: {
        conflictResolutionStrategy,
    },
});

export const importRequestDone = (process: TExecutingProcess): TImportDialogImportRequestDoneAction => ({
    type: IMPORT_DIALOG_IMPORT_REQUEST_DONE,
    payload: {
        process,
    },
});

export const importPresetDialogRequestDone = (process: TExecutingProcess): TImportPresetDialogRequestDoneAction => ({
    type: IMPORT_PRESET_DIALOG_REQUEST_DONE,
    payload: {
        process,
    },
});

export const importDialogApplyName = (newName: String): TImportDialogApplyNameAction => ({
    type: IMPORT_DIALOG_APPLY_NAME,
    payload: { newName },
});

export const importDialogReplaceConfirm = (): TImportDialogReplaceConfirmAction => ({
    type: IMPORT_DIALOG_REPLACE_CONFIRM,
});
