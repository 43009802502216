import { RawDraftEntity } from 'draft-js';

const textAlignEntity = {
    TEXTALIGN: {
        open: (entity: RawDraftEntity) => `<TEXTALIGN_${entity.data.align}>`,
        close: () => `</TEXTALIGN>`,
    },
};

const textAlignBlock = {
    TEXT_ALIGN_open: (item: RawDraftEntity) =>
        <RawDraftEntity>{
            type: 'TEXTALIGN',
            mutability: 'MUTABLE',
            data: {
                align: item.data.align,
            },
        },
};

export { textAlignEntity, textAlignBlock };
