import {
    ADD_CARDS_KANBAN_MODEL_TYPE,
    ADD_KANBAN_MODEL_TYPE_COLUMN,
    ADD_KANBAN_MODEL_TYPE_PLACING_RULE,
    CHANGE_KANBAN_MODEL_TYPE_PLACING_RULE,
    ADD_STORE_EDIT_KANBAN_MODEL_TYPE_WORKSPACE_TAB,
    ADD_SYMBOLS_KANBAN_MODEL_TYPE,
    CHANGE_KANBAN_MODEL_TYPE_COLUMNS_SETTINGS,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_CARDS_VIEW_IN_COLUMN,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_COLOR,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_CARDS_LIMIT_IN_COLUMN,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_HEADER_ICON,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_COLOR,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_ICON,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_ID,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_MAX_CARDS_IN_COLUMN,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_MIN_CARD_WIDTH_FOR_ROW_VIEW,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_NAME,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_TYPE,
    CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_VALUE,
    CHANGE_KANBAN_MODEL_TYPE_DESCRIPTION,
    CHANGE_KANBAN_MODEL_TYPE_GROUP_ID,
    CHANGE_KANBAN_MODEL_TYPE_ID,
    CHANGE_KANBAN_MODEL_TYPE_NAME,
    DELETE_CARDS_KANBAN_MODEL_TYPE,
    DELETE_KANBAN_MODEL_TYPE_COLUMN,
    DELETE_SYMBOLS_KANBAN_MODEL_TYPE,
    MOVE_KANBAN_MODEL_TYPE_COLUMN,
    DELETE_KANBAN_MODEL_TYPE_PLACING_RULE,
    MOVE_KANBAN_MODEL_TYPE_PLACING_RULE,
    ADD_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    CHANGE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    DELETE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    MOVE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
} from '../../actionsTypes/workspaceTab/editKanbanModelTypeWorkspaceTab.actionTypes';
import {
    TAddCardsKanbanModelTypeAction,
    TAddCardsKanbanModelTypePayload,
    TAddKanbanModelTypeColumnAction,
    TAddKanbanModelTypeColumnPayload,
    TAddKanbanModelTypePlacingRuleAction,
    TChangeKanbanModelTypePlacingRuleAction,
    TChangeKanbanModelTypePlacingRulePayload,
    TAddKanbanModelTypePlacingRulePayload,
    TAddStoreEditKanbanModelTypeWorkspaceTabAction,
    TAddStoreEditKanbanModelTypeWorkspaceTabPayload,
    TAddSymbolsKanbanModelTypeAction,
    TAddSymbolsKanbanModelTypePayload,
    TChangeKanbanModelTypeColumnCardsViewInColumnAction,
    TChangeKanbanModelTypeColumnCardsViewInColumnPayload,
    TChangeKanbanModelTypeColumnColorAction,
    TChangeKanbanModelTypeColumnColorPayload,
    TChangeKanbanModelTypeColumnHasCardsLimitInColumnAction,
    TChangeKanbanModelTypeColumnHasCardsLimitInColumnPayload,
    TChangeKanbanModelTypeColumnHasHeaderIconAction,
    TChangeKanbanModelTypeColumnHasHeaderIconPayload,
    TChangeKanbanModelTypeColumnHeaderColorAction,
    TChangeKanbanModelTypeColumnHeaderColorPayload,
    TChangeKanbanModelTypeColumnHeaderIconAction,
    TChangeKanbanModelTypeColumnHeaderIconPayload,
    TChangeKanbanModelTypeColumnIdAction,
    TChangeKanbanModelTypeColumnIdPayload,
    TChangeKanbanModelTypeColumnMaxCardsInColumnAction,
    TChangeKanbanModelTypeColumnMaxCardsInColumnPayload,
    TChangeKanbanModelTypeColumnMinCardWidthForRowViewAction,
    TChangeKanbanModelTypeColumnMinCardWidthForRowViewPayload,
    TChangeKanbanModelTypeColumnNameAction,
    TChangeKanbanModelTypeColumnNamePayload,
    TChangeKanbanModelTypeColumnsSettingsAction,
    TChangeKanbanModelTypeColumnsSettingsPayload,
    TChangeKanbanModelTypeColumnWidthTypeAction,
    TChangeKanbanModelTypeColumnWidthTypePayload,
    TChangeKanbanModelTypeColumnWidthValueAction,
    TChangeKanbanModelTypeColumnWidthValuePayload,
    TChangeKanbanModelTypeDescriptionAction,
    TChangeKanbanModelTypeDescriptionPayload,
    TChangeKanbanModelTypeGroupIdAction,
    TChangeKanbanModelTypeGroupIdPayload,
    TChangeKanbanModelTypeIdAction,
    TChangeKanbanModelTypeIdPayload,
    TChangeKanbanModelTypeNameAction,
    TChangeKanbanModelTypeNamePayload,
    TDeleteCardsKanbanModelTypeAction,
    TDeleteCardsKanbanModelTypePayload,
    TDeleteKanbanModelTypeColumnAction,
    TDeleteKanbanModelTypeColumnPayload,
    TDeleteSymbolsKanbanModelTypeAction,
    TDeleteSymbolsKanbanModelTypePayload,
    TMoveKanbanModelTypeColumnAction,
    TMoveKanbanModelTypeColumnPayload,
    TDeleteKanbanModelTypePlacingRulePayload,
    TDeleteKanbanModelTypePlacingRuleAction,
    TMoveKanbanModelTypePlacingRuleAction,
    TMoveKanbanModelTypePlacingRulePayload,
    TAddKanbanModelTypeObjectChangingRulePayload,
    TAddKanbanModelTypeObjectChangingRuleAction,
    TChangeKanbanModelTypeObjectChangingRulePayload,
    TChangeKanbanModelTypeObjectChangingRuleAction,
    TDeleteKanbanModelTypeObjectChangingRulePayload,
    TDeleteKanbanModelTypeObjectChangingRuleAction,
    TMoveKanbanModelTypeObjectChangingRuleAction,
    TMoveKanbanModelTypeObjectChangingRulePayload,
} from './editKanbanModelTypeWorkspaceTab.actions.types';

export const addStoreEditKanbanModelTypeWorkspaceTab = (
    payload: TAddStoreEditKanbanModelTypeWorkspaceTabPayload,
): TAddStoreEditKanbanModelTypeWorkspaceTabAction => ({
    type: ADD_STORE_EDIT_KANBAN_MODEL_TYPE_WORKSPACE_TAB,
    payload,
});

export const changeKanbanModelTypeName = (
    payload: TChangeKanbanModelTypeNamePayload,
): TChangeKanbanModelTypeNameAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_NAME,
    payload,
});

export const changeKanbanModelTypeId = (payload: TChangeKanbanModelTypeIdPayload): TChangeKanbanModelTypeIdAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_ID,
    payload,
});

export const changeKanbanModelTypeDescription = (
    payload: TChangeKanbanModelTypeDescriptionPayload,
): TChangeKanbanModelTypeDescriptionAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_DESCRIPTION,
    payload,
});

export const changeKanbanModelTypeGroupId = (
    payload: TChangeKanbanModelTypeGroupIdPayload,
): TChangeKanbanModelTypeGroupIdAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_GROUP_ID,
    payload,
});

export const addSymbolsKanbanModelType = (
    payload: TAddSymbolsKanbanModelTypePayload,
): TAddSymbolsKanbanModelTypeAction => ({
    type: ADD_SYMBOLS_KANBAN_MODEL_TYPE,
    payload,
});

export const addCardsKanbanModelType = (payload: TAddCardsKanbanModelTypePayload): TAddCardsKanbanModelTypeAction => ({
    type: ADD_CARDS_KANBAN_MODEL_TYPE,
    payload,
});

export const deleteSymbolsKanbanModelType = (
    payload: TDeleteSymbolsKanbanModelTypePayload,
): TDeleteSymbolsKanbanModelTypeAction => ({
    type: DELETE_SYMBOLS_KANBAN_MODEL_TYPE,
    payload,
});

export const deleteCardsKanbanModelType = (
    payload: TDeleteCardsKanbanModelTypePayload,
): TDeleteCardsKanbanModelTypeAction => ({
    type: DELETE_CARDS_KANBAN_MODEL_TYPE,
    payload,
});

export const changeKanbanModelTypeColumnsSettings = (
    payload: TChangeKanbanModelTypeColumnsSettingsPayload,
): TChangeKanbanModelTypeColumnsSettingsAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMNS_SETTINGS,
    payload,
});

export const addKanbanModelTypeColumn = (
    payload: TAddKanbanModelTypeColumnPayload,
): TAddKanbanModelTypeColumnAction => ({
    type: ADD_KANBAN_MODEL_TYPE_COLUMN,
    payload,
});

export const deleteKanbanModelTypeColumn = (
    payload: TDeleteKanbanModelTypeColumnPayload,
): TDeleteKanbanModelTypeColumnAction => ({
    type: DELETE_KANBAN_MODEL_TYPE_COLUMN,
    payload,
});

export const moveKanbanModelTypeColumn = (
    payload: TMoveKanbanModelTypeColumnPayload,
): TMoveKanbanModelTypeColumnAction => ({
    type: MOVE_KANBAN_MODEL_TYPE_COLUMN,
    payload,
});

export const changeKanbanModelTypeColumnName = (
    payload: TChangeKanbanModelTypeColumnNamePayload,
): TChangeKanbanModelTypeColumnNameAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_NAME,
    payload,
});

export const changeKanbanModelTypeColumnId = (
    payload: TChangeKanbanModelTypeColumnIdPayload,
): TChangeKanbanModelTypeColumnIdAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_ID,
    payload,
});

export const changeKanbanModelTypeColumnWidthType = (
    payload: TChangeKanbanModelTypeColumnWidthTypePayload,
): TChangeKanbanModelTypeColumnWidthTypeAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_TYPE,
    payload,
});

export const changeKanbanModelTypeColumnWidthValue = (
    payload: TChangeKanbanModelTypeColumnWidthValuePayload,
): TChangeKanbanModelTypeColumnWidthValueAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_VALUE,
    payload,
});

export const changeKanbanModelTypeColumnHeaderColor = (
    payload: TChangeKanbanModelTypeColumnHeaderColorPayload,
): TChangeKanbanModelTypeColumnHeaderColorAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_COLOR,
    payload,
});

export const changeKanbanModelTypeColumnColor = (
    payload: TChangeKanbanModelTypeColumnColorPayload,
): TChangeKanbanModelTypeColumnColorAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_COLOR,
    payload,
});

export const changeKanbanModelTypeColumnCardsViewInColumn = (
    payload: TChangeKanbanModelTypeColumnCardsViewInColumnPayload,
): TChangeKanbanModelTypeColumnCardsViewInColumnAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_CARDS_VIEW_IN_COLUMN,
    payload,
});

export const changeKanbanModelTypeColumnMinCardWidthForRowView = (
    payload: TChangeKanbanModelTypeColumnMinCardWidthForRowViewPayload,
): TChangeKanbanModelTypeColumnMinCardWidthForRowViewAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_MIN_CARD_WIDTH_FOR_ROW_VIEW,
    payload,
});

export const changeKanbanModelTypeColumnHasHeaderIcon = (
    payload: TChangeKanbanModelTypeColumnHasHeaderIconPayload,
): TChangeKanbanModelTypeColumnHasHeaderIconAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_HEADER_ICON,
    payload,
});

export const changeKanbanModelTypeColumnHeaderIcon = (
    payload: TChangeKanbanModelTypeColumnHeaderIconPayload,
): TChangeKanbanModelTypeColumnHeaderIconAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_ICON,
    payload,
});

export const changeKanbanModelTypeColumnHasCardsLimitInColumn = (
    payload: TChangeKanbanModelTypeColumnHasCardsLimitInColumnPayload,
): TChangeKanbanModelTypeColumnHasCardsLimitInColumnAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_CARDS_LIMIT_IN_COLUMN,
    payload,
});

export const changeKanbanModelTypeColumnMaxCardsInColumn = (
    payload: TChangeKanbanModelTypeColumnMaxCardsInColumnPayload,
): TChangeKanbanModelTypeColumnMaxCardsInColumnAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_COLUMN_MAX_CARDS_IN_COLUMN,
    payload,
});

export const addKanbanModelTypePlacingRule = (
    payload: TAddKanbanModelTypePlacingRulePayload,
): TAddKanbanModelTypePlacingRuleAction => ({
    type: ADD_KANBAN_MODEL_TYPE_PLACING_RULE,
    payload,
});

export const changeKanbanModelTypePlacingRule = (
    payload: TChangeKanbanModelTypePlacingRulePayload,
): TChangeKanbanModelTypePlacingRuleAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_PLACING_RULE,
    payload,
});

export const deleteKanbanModelTypePlacingRule = (
    payload: TDeleteKanbanModelTypePlacingRulePayload,
): TDeleteKanbanModelTypePlacingRuleAction => ({
    type: DELETE_KANBAN_MODEL_TYPE_PLACING_RULE,
    payload,
});

export const moveKanbanModelTypePlacingRule = (
    payload: TMoveKanbanModelTypePlacingRulePayload,
): TMoveKanbanModelTypePlacingRuleAction => ({
    type: MOVE_KANBAN_MODEL_TYPE_PLACING_RULE,
    payload,
});

export const addKanbanModelTypeObjectChangingRule = (
    payload: TAddKanbanModelTypeObjectChangingRulePayload,
): TAddKanbanModelTypeObjectChangingRuleAction => ({
    type: ADD_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    payload,
});

export const changeKanbanModelTypeObjectChangingRule = (
    payload: TChangeKanbanModelTypeObjectChangingRulePayload,
): TChangeKanbanModelTypeObjectChangingRuleAction => ({
    type: CHANGE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    payload,
});

export const deleteKanbanModelTypeObjectChangingRule = (
    payload: TDeleteKanbanModelTypeObjectChangingRulePayload,
): TDeleteKanbanModelTypeObjectChangingRuleAction => ({
    type: DELETE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    payload,
});

export const moveKanbanModelTypeObjectChangingRule = (
    payload: TMoveKanbanModelTypeObjectChangingRulePayload,
): TMoveKanbanModelTypeObjectChangingRuleAction => ({
    type: MOVE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE,
    payload,
});
