import type { TFloatingAttributesDialogContentRef } from './FloatingAttributesPanel.types';
import type { TSymbolFloatingAttributesPanelOwnProps } from '../FloatingAttributes.types';
import type { StyledAttributeType } from '../../../models/bpm/bpm-model-impl';
import React, { forwardRef, useMemo } from 'react';
import { FloatingAttributesPanel } from './FloatingAttributesPanel.component';
import { LocalesService } from '../../../services/LocalesService';
import { getUnknownAttributeType } from '../../../utils/attribute';
import {
    compareAttributesByIdAndDiscriminators,
    getEntityStyledAttributesTypes,
    getModelStyledAttributesTypes,
    getSystemStyledAttributeTypes,
} from '../FloatingAttributes.utils';
import { unionBy } from 'lodash-es';

type TSymbolFloatingAttributesPanel = React.ForwardRefExoticComponent<
    Omit<TSymbolFloatingAttributesPanelOwnProps, 'ref'> & React.RefAttributes<TFloatingAttributesDialogContentRef>
>;

export const SymbolFloatingAttributesPanel: TSymbolFloatingAttributesPanel = forwardRef(
    (props: TSymbolFloatingAttributesPanelOwnProps, ref) => {
        const locale = LocalesService.getLocale();
        const {
            objectStyles,
            presetStyles,
            definitionAttributeTypes,
            instanceAttributeTypes,
            userModelTypeAttributes,
            ignorePresetStyles,
            modelLocked,
            onToggleIgnorePresetStyles,
            onChange,
            setRulesValidity,
        } = props;
        const attributeTypes = useMemo(() => {
            const systemAttributeTypes = getSystemStyledAttributeTypes('SYSTEM', objectStyles);
            const modelAttributeTypes = getSystemStyledAttributeTypes('MODEL_SYSTEM', objectStyles);
            const styledDefinitionAttributes = getEntityStyledAttributesTypes(
                'DEFINITION',
                definitionAttributeTypes || [],
                objectStyles || [],
            );
            const styledInstanceAttributes = getEntityStyledAttributesTypes(
                'INSTANCE',
                instanceAttributeTypes || [],
                objectStyles || [],
            );
            const styledUserModelTypeAttributes = getModelStyledAttributesTypes(
                userModelTypeAttributes || [],
                objectStyles || [],
            );

            const allAttributeTypes = unionBy(
                definitionAttributeTypes,
                instanceAttributeTypes || [],
                userModelTypeAttributes || [],
                'id',
            );
            const allSystemAttributeTypes = unionBy(systemAttributeTypes, modelAttributeTypes, 'id');

            const unknownStyledAttributes: StyledAttributeType[] = (objectStyles || [])
                .filter((style) => {
                    return (
                        !allAttributeTypes.some(({ id }) => id === style.typeId) &&
                        !allSystemAttributeTypes.some(({ id }) => id === style.typeId)
                    );
                })
                .map((style) => ({
                    ...getUnknownAttributeType(locale),
                    styles: style?.styles || [],
                }));

            const styledAttributeTypes: StyledAttributeType[] = [
                ...styledDefinitionAttributes,
                ...styledInstanceAttributes,
                ...systemAttributeTypes,
                ...modelAttributeTypes,
                ...styledUserModelTypeAttributes,
                ...unknownStyledAttributes,
            ];

            const readOnlyAttributeTypes: StyledAttributeType[] = styledAttributeTypes.map(
                ({ id, name, attributeDiscriminator: discriminator }) => ({
                    id,
                    name,
                    styles:
                        presetStyles.find(({ attributeTypeId, attributeDiscriminator }) =>
                            compareAttributesByIdAndDiscriminators(
                                id,
                                attributeTypeId,
                                styledAttributeTypes,
                                discriminator,
                                attributeDiscriminator,
                            ),
                        )?.styles || [],
                }),
            );

            return { styledAttributeTypes, readOnlyAttributeTypes };
        }, [objectStyles, presetStyles, definitionAttributeTypes, instanceAttributeTypes, userModelTypeAttributes]);

        return (
            <FloatingAttributesPanel
                ref={ref}
                styledAttributeTypes={attributeTypes.styledAttributeTypes}
                readOnlyAttributeTypes={attributeTypes.readOnlyAttributeTypes}
                ignorePresetStyles={ignorePresetStyles}
                disabled={modelLocked}
                onToggleIgnorePresetStyles={onToggleIgnorePresetStyles}
                setRulesValidity={setRulesValidity}
                onChange={onChange}
            />
        );
    },
);
