import { put, takeEvery, call, all } from 'redux-saga/effects';
import { GET_SCRIPT_QUERY_PARAM_VALUES } from '../actionsTypes/scriptQueryParams.actionTypes';
import { scriptQueryParamValuesRequestSuccess } from '../actions/scriptQueryParams.actions';
import { TLoadScriptQueryParamValuesAction, TScriptQueryData } from '../actions/scriptQueryParams.actions.types';
import {
    AttributeValueQueryModelSymbolsValueParam,
    AttributeValueQueryParam,
    NodeId,
   
    ScriptParameter,
} from '../serverapi/api';
import { AttributeQuerySelectDAOService } from '../services/dao/AttributeQuerySelectDaoService';

export function* loadScriptQueryParamsValue({ payload: { params, modelIdString } }: TLoadScriptQueryParamValuesAction) {
    let modelId: NodeId | undefined;
    try {
        modelId = JSON.parse(modelIdString || '');
    } catch {
        console.error('parse error');
    }

    const scriptQueryParams: ScriptParameter[] = params.filter(
        (param) =>
            (param.paramType === 'QUERY_MULTI_SELECT' || param.paramType === 'QUERY_SELECT') &&
            // пока таблицы не рассматриваем
            param.queryType !== 'SPREADSHEET',
    );
    const queryValues: TScriptQueryData[] = yield all(
        scriptQueryParams.map((scriptQueryParam) => {
            let param: AttributeValueQueryParam;
            switch (scriptQueryParam.queryType) {
                case 'PRESETS': {
                    param = { queryAttributeType: 'PRESETS' };
                    break;
                }
                case 'MODEL_SYMBOLS': {
                    param = {
                        queryAttributeType: 'MODEL_SYMBOLS',
                        modelId,
                    } as AttributeValueQueryModelSymbolsValueParam;
                    break;
                }
                // case 'SPREADSHEET': {
                //     param = {
                //         queryAttributeType: 'SPREADSHEET',
                //         attributeTypeId: { presetId, id: querySelectAttributesType.id },
                //     } as QueryAttributeTypeIdValueParam;
                //     break;
                // }
                default:
                    break;
            }

            return call(() => AttributeQuerySelectDAOService.getscriptQueryParamsValues(param, scriptQueryParam.name || ''));
        }),
    );
    yield put(scriptQueryParamValuesRequestSuccess(queryValues));
}

export function* scriptQueryParamsSaga() {
    yield takeEvery(GET_SCRIPT_QUERY_PARAM_VALUES, loadScriptQueryParamsValue);
}
