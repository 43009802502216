import { MxMarker } from '../mxgraph';

export const addCustomMarkersForEdges = () => {
    MxMarker.addMarker('plusCircle', plusCircle);
};

function plusCircle(canvas, shape, type, pe, unitX, unitY, size, source, sw, filled) {
    const swFactor = 0.9862;
    const endOffsetX = unitX * sw * swFactor;
    const endOffsetY = unitY * sw * swFactor;

    unitX *= size + sw;
    unitY *= size + sw;

    const pt = pe.clone();
    pt.x -= endOffsetX;
    pt.y -= endOffsetY;

    pe.x += -unitX - endOffsetX;
    pe.y += -unitY - endOffsetY;

    const radius = 5;
    const x = pt.x - unitX;
    const y = pt.y - unitY;

    return function () {
        canvas.begin();
        canvas.moveTo(x, y - radius);
        canvas.lineTo(x, y + radius);
        canvas.moveTo(x - radius, y);
        canvas.lineTo(x + radius, y);
        canvas.stroke();
        canvas.close();
        canvas.begin();
        canvas.ellipse(x - radius, y - radius, radius * 2, radius * 2);
        canvas.stroke();
        canvas.close();
    };
}
