import { createSelector } from 'reselect';
import { PrincipalType } from '../models/security/PrincipalType';
import { TRootState } from '../reducers/root.reducer.types';

export const principalsState = (state: TRootState) => state.principals.data;

export namespace PrincipalsSelectors {
    export const getAll = createSelector(principalsState, (state) => state);

    export const getUsers = createSelector(principalsState, (state) =>
        state.filter((el) => el.principalType === PrincipalType.USER),
    );

    export const getUser = (login: string) =>
        createSelector(getUsers, (state) => state.find((el) => el.login === login));

    export const getGroups = createSelector(principalsState, (state) =>
        state.filter((el) => el.principalType === PrincipalType.GROUP),
    );
}
