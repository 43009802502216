import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'DeleteSeveralNodesDialog.title',
        defaultMessage: 'Удаление элементов дерева',
    },
    delete: {
        id: 'DeleteSeveralNodesDialog.delete',
        defaultMessage: 'Удалить',
    },
    cancel: {
        id: 'DeleteSeveralNodesDialog.cancel',
        defaultMessage: 'Отмена',
    },
    text: {
        id: 'DeleteSeveralNodesDialog.text',
        defaultMessage: 'Вы уверены, что хотите удалить элементы: {names}?',
    },
    tabsToBeClosed: {
        id: 'DeleteSeveralNodesDialog.tabsToBeClosed',
        defaultMessage: 'Будут закрыты вкладки: ',
    },
    more: {
        id: 'DeleteSeveralNodesDialog.more',
        defaultMessage: 'ещё',
    },
    stopDeliting: {
        id: 'DeleteSeveralNodesDialog.stopDeliting',
        defaultMessage: 'Остановить удаление',
    },
    skip: {
        id: 'DeleteSeveralNodesDialog.skip',
        defaultMessage: 'Пропустить',
    },
    errorMessage: {
        id: 'DeleteSeveralNodesDialog.errorMessage',
        defaultMessage: 'Элемент "{name}" не может быть удален',
    },
    errorTitle: {
        id: 'DeleteSeveralNodesDialog.errorTitle',
        defaultMessage: 'Ошибка удаления',
    },
});
