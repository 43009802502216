import { createSelector } from 'reselect';
import { INITIAL_EDGE_TYPE_SERVER_STATE } from '../reducers/edgeType.reducer';
import { TRootState } from '../reducers/root.reducer.types';
import { EdgeTypeGroup } from '../serverapi/api';

export const edgeTypeGroupStateSelector = (state: TRootState) => state.edgeTypeGroup;
export const deletedEdgeTypeGroupStateSelector = (state: TRootState) => state.deletedEdgeTypeGroup;

export namespace EdgeTypeGroupSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(edgeTypeGroupStateSelector, (state) => {
            const s = state.byServerId[serverId];
            if (s) {
                return { ...s };
            }

            return { ...INITIAL_EDGE_TYPE_SERVER_STATE };

        });

    export const byPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), (state) => state.byPresetId[compositeId.presetId]);

    export const byEdgeTypeGroup = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(
            byPresetId({ serverId: compositeId.serverId, presetId: compositeId.presetId }),
            (state): EdgeTypeGroup[] | undefined => state.byId && Object.values(state.byId),
        );

    export const byId = (compositeId: { edgeTypeGroupId: string; presetId: string; serverId: string }) =>
        createSelector(byPresetId({ serverId: compositeId.serverId, presetId: compositeId.presetId }), (state) => {
            return state.byId[compositeId.edgeTypeGroupId];
        });

    export const byPresetIdExcludeDeleted = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), deletedEdgeTypeGroups(compositeId), (state, deleted) => {
            const etg = state.byPresetId[compositeId.presetId];
            const edgeTypeGroups: EdgeTypeGroup[] = etg ? Object.values(etg.byId) : [];
            return edgeTypeGroups.filter(e => !deleted?.some(d => d.id === e.id));
        });

    export const deletedEdgeTypeGroups = (payload: { presetId: string; serverId: string }) =>
        createSelector(deletedEdgeTypeGroupStateSelector, (state) => {
            return state.byServerId[payload.serverId]?.byPresetId[payload.presetId];
        });

}
