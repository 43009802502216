import { NodeId } from '@/serverapi/api';
import { OBJECT_PROPERTY_VIEW } from '../actionsTypes/objectProperty.actionTypes';
import { ObjectPropertiesDialogActiveTab } from '../models/objectPropertiesDialog';
import { TObjectPropertyViewAction } from './objectProperty.actions.types';

export const objectPropertyView = (
    cellId: string,
    activeTab?: ObjectPropertiesDialogActiveTab,
    graphId?: NodeId,
): TObjectPropertyViewAction => ({
    type: OBJECT_PROPERTY_VIEW,
    payload: {
        cellId,
        activeTab,
        graphId,
    },
});
