import type { PrincipalDescriptor } from '@/serverapi/api';

export const getDisplayName = (user: PrincipalDescriptor): string => {
    const { lastname, name, middlename, login } = user;

    if (!lastname && !name && !middlename) {
        return login;
    }

    return [lastname, name, middlename].filter((el) => !!el).join(' ');
};

export const getShortDisplayName = (user: PrincipalDescriptor): string => {
    const { lastname, name } = user;

    return [name, lastname].filter((el) => !!el).join(' ');
};

export const sortUsers = (users: PrincipalDescriptor[]): PrincipalDescriptor[] => {
    return users.slice().sort((a, b) => {
        const nameA = getDisplayName(a).toLowerCase();
        const nameB = getDisplayName(b).toLowerCase();

        return nameA.localeCompare(nameB);
    });
};

export const getUserInitials = (author?: PrincipalDescriptor) =>
    `${author?.name?.[0]?.toUpperCase() || ''}${author?.lastname?.[0]?.toUpperCase() || ''}`;

