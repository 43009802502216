import * as React from 'react';
import { Table } from 'antd';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import messages from '../messages/licensestool.messages';
import { LicenseCountDTO } from '../../../serverapi/api';
import theme from './LicenseTool.component.scss';

interface IUsingLicensesTableProps {
    resultingLicense: LicenseCountDTO;
    usingLicense?: LicenseCountDTO;
    usingCompetitiveLicense?: LicenseCountDTO;
}

class UsingLicensesTable extends React.PureComponent<IUsingLicensesTableProps & WrappedComponentProps> {
    renderData(resulting: LicenseCountDTO, using: LicenseCountDTO, usingCompetitiveLicense?: LicenseCountDTO) {
        const { intl } = this.props;
        const columns = [
            {
                title: intl.formatMessage(messages.licenseName),
                dataIndex: 'licenseName',
                key: 'licenseName',
                width: 160,
            },
            {
                title: intl.formatMessage(messages.licenseCount),
                dataIndex: 'licenseCount',
                key: 'licenseCount',
                width: 100,
            },
            {
                title: intl.formatMessage(messages.licenseUsed),
                dataIndex: 'licenseUsed',
                key: 'licenseUsed',
                width: 100,
            },
            {
                title: intl.formatMessage(messages.usingCmpLicense),
                dataIndex: 'usingCmpLicense',
                key: 'usingCmpLicense',
                width: 140,
            },
        ];

        const data = Object.keys(resulting).map((key) => ({
            key,
            licenseName: intl.formatMessage(messages[key]),
            licenseCount: resulting[key] || 0,
            licenseUsed: using[key] || 0,
            usingCmpLicense: (usingCompetitiveLicense && usingCompetitiveLicense[key]) || 0,
        }));

        return <Table columns={columns} dataSource={data} pagination={false} size="small" />;
    }

    render() {
        const { resultingLicense, usingLicense, usingCompetitiveLicense } = this.props;

        return (
            <div>
                <div className={theme.usingLicensesTitle}>
                    <h3>
                        <FormattedMessage {...messages.usingLicensesHeader} />
                    </h3>
                </div>
                {resultingLicense &&
                    usingLicense &&
                    this.renderData(resultingLicense, usingLicense, usingCompetitiveLicense)}
            </div>
        );
    }
}

const withIntl = injectIntl(UsingLicensesTable);

export { withIntl as UsingLicensesTable };
