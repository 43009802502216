import { defineMessages } from 'react-intl';

export default defineMessages({
    id: {
        id: 'NotificationsList.id',
        defaultMessage: 'ID',
    },
    name: {
        id: 'NotificationsList.name',
        defaultMessage: 'Название уведомления',
    },
    type: {
        id: 'NotificationsList.type',
        defaultMessage: 'Тип события',
    },
    template: {
        id: 'NotificationsList.template',
        defaultMessage: 'Шаблон',
    },
    on_off: {
        id: 'NotificationsList.on_off',
        defaultMessage: 'Вкл/Выкл',
    },
    delete: {
        id: 'NotificationsList.delete',
        defaultMessage: 'Удаление уведомления',
    },
    deleteMessage: {
        id: 'NotificationsList.deleteMessage',
        defaultMessage: 'Вы уверены, что хотите удалить уведомление "{name}"?',
    },
    create: {
        id: 'NotificationsList.create',
        defaultMessage: 'Создать новое уведомление',
    },
    SAVE_TREE_NODE: {
        id: 'NotificationsList.SAVE_TREE_NODE',
        defaultMessage: 'Сохранение узла дерева',
    },
});
