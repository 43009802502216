import { defineMessages } from 'react-intl';

export default defineMessages({
    openSettings: {
        id: 'MatrixEditorToolbar.openSettings',
        defaultMessage: 'Открыть настройки матрицы',
    },
    refreshMatrix: {
        id: 'MatrixEditorToolbar.refreshMatrix',
        defaultMessage: 'Обновить матрицу',
    },
    paste: {
        id: 'MatrixEditorToolbar.paste',
        defaultMessage: 'Вставить',
    },
    copy: {
        id: 'MatrixEditorToolbar.copy',
        defaultMessage: 'Копировать',
    },
    pastRowBtm: {
        id: 'MatrixEditorToolbar.pastRowBtm',
        defaultMessage: 'Вставить строку снизу',
    },
    pastRowTop: {
        id: 'MatrixEditorToolbar.pastRowTop',
        defaultMessage: 'Вставить строку сверху',
    },
    pastColLeft: {
        id: 'MatrixEditorToolbar.pastColLeft',
        defaultMessage: 'Вставить столбец слева',
    },
    pastColRight: {
        id: 'MatrixEditorToolbar.pastColRight',
        defaultMessage: 'Вставить столбец справа',
    },
});
