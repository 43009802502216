import * as React from 'react';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import { closeDialog } from '../../actions/dialogs.actions';
import { useDispatch } from 'react-redux';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import theme from './SilaAcceptAgreementDialog.scss';
import messages from './SilaAcceptAgreement.messages';
import { appExit } from '../../actions/app.actions';
import { AppExitMethod } from '../../models/app';
import { acceptSilaAgreement } from '../../actions/agreement.actions';
import { useIntl } from 'react-intl';
import Linkify from 'linkify-react';
import { DialogFooterButtons } from '../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TSilaAcceptAgreementDialogProps = {
    open: boolean;
    serverId: string;
    text: string;
} & JSX.IntrinsicAttributes;

const SilaAcceptAgreement: React.FC<TSilaAcceptAgreementDialogProps> = ({ open, text, serverId }) => {
    const dispatch = useDispatch();
    const [verticalScroll, setVerticalScroll] = React.useState(false);
    const [end, setEnd] = React.useState(false);
    const intl = useIntl();

    const handleClose = () => {
        dispatch(appExit(AppExitMethod.Logout));
    };

    const elRef = React.useCallback((node) => {
        if (node !== null) {
            const hasVerScroll = node.parentNode.parentNode.scrollHeight > node.parentNode.parentNode.clientHeight;
            if (hasVerScroll) {
                setVerticalScroll(true);
            }
        }
    }, []);

    const handleScroll = (event) => {
        if (Math.ceil(event.target.scrollHeight - event.target.scrollTop) - event.target.clientHeight <= 1) {
            setEnd(true);
        }
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: () => {
                        dispatch(appExit(AppExitMethod.Logout));
                    },
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: () => {
                        dispatch(acceptSilaAgreement(serverId));
                        dispatch(closeDialog(DialogType.ACCEPT_SILA_AGREEMENT));
                    },
                    value: intl.formatMessage(messages.accept),
                    visualStyle: 'primary',
                    disabled: verticalScroll && !end,
                },
            ]}
        />
    );

    return (
        <div className={theme.container} onScroll={handleScroll}>
            <Dialog
                maskClosable={false}
                onCancel={handleClose}
                open={open}
                title={intl.formatMessage(messages.agreement)}
                footer={footer}
            >
                <div>
                    <Linkify options={{ target: '_blank' }}>
                        <div ref={elRef} style={{ whiteSpace: 'pre-wrap', color: 'gray' }}>
                            {text}
                        </div>
                    </Linkify>
                </div>
            </Dialog>
        </div>
    );
};

export default SilaAcceptAgreement;
