import {
    GET_SCRIPT_QUERY_PARAM_VALUES,
    SCRIPT_QUERY_PARAM_VALUES_REQUEST_SUCCESS,
} from '../actionsTypes/scriptQueryParams.actionTypes';
import { ScriptParameter } from '../serverapi/api';
import {
    TLoadScriptQueryParamValuesAction,
    TScriptQueryParamValuesRequestSuccessAction,
    TScriptQueryData,
} from './scriptQueryParams.actions.types';

export const loadScriptQueryParamValues = (
    params: ScriptParameter[],
    modelIdString: string | undefined,
): TLoadScriptQueryParamValuesAction => ({
    type: GET_SCRIPT_QUERY_PARAM_VALUES,
    payload: {
        params,
        modelIdString,
    },
});

export const scriptQueryParamValuesRequestSuccess = (data: TScriptQueryData[]): TScriptQueryParamValuesRequestSuccessAction => ({
    type: SCRIPT_QUERY_PARAM_VALUES_REQUEST_SUCCESS,
    payload: {
        data,
    },
});
