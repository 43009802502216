import { Checkbox, Form, Select } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { FormItemInputWithLabel } from '../FormItemInputWithLabelComponent/FormItemInputWithLabel.component';
import { SystemPropertiesFormItemNames } from '../../SystemProperties.types';
import {
    SystemPropertiesKerberosReceiveGroupsFromLdapEnum,
    SystemPropertiesKerberosUpdateUserInfoFromLdapEnum,
} from '../../../../../serverapi/api';

const TStatuses: (
    | SystemPropertiesKerberosReceiveGroupsFromLdapEnum
    | SystemPropertiesKerberosUpdateUserInfoFromLdapEnum
)[] = ['ALWAYS', 'CREATE', 'LOGIN', 'NEVER'];

export const KerberosSettings = () => {
    const intl = useIntl();
    const kerberosDebugStatus = useSelector(SystemPropertiesSelectors.getKerberosDebugStatus);
    const kerberosKrb5Path = useSelector(SystemPropertiesSelectors.getKerberosKrb5Path);
    const kerberosReceiveGroupsFromLdap = useSelector(SystemPropertiesSelectors.getKerberosReceiveGroupsFromLdap);
    const kerberosUpdateUserInfoFromLdap = useSelector(SystemPropertiesSelectors.getKerberosUpdateUserInfoFromLdap);

    return (
        <div className={theme.formTab}>
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.kerberosKrb5Path}
                initialValue={kerberosKrb5Path}
                label={intl.formatMessage(messages.kerberosKrb5Path)}
            />
            <Form.Item
                className={theme.formItem}
                valuePropName="checked"
                name={SystemPropertiesFormItemNames.kerberosDebugStatus}
                initialValue={kerberosDebugStatus}
                label={intl.formatMessage(messages.kerberosDebugStatus)}
            >
                <Checkbox className={theme.checkbox} />
            </Form.Item>
            <Form.Item
                className={theme.formItem}
                label={intl.formatMessage(messages.receiveGroupFromToken)}
                initialValue={kerberosReceiveGroupsFromLdap || 'NEVER'}
                name={SystemPropertiesFormItemNames.kerberosReceiveGroupsFromLdap}
            >
                <Select>
                    {TStatuses.map((status) => {
                        return (
                            <Select.Option key={status} value={status}>
                                {intl.formatMessage(messages[status])}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                className={theme.formItem}
                label={intl.formatMessage(messages.userInfo)}
                initialValue={kerberosUpdateUserInfoFromLdap || 'NEVER'}
                name={SystemPropertiesFormItemNames.kerberosUpdateUserInfoFromLdap}
            >
                <Select>
                    {TStatuses.map((status) => {
                        return (
                            <Select.Option key={status} value={status}>
                                {intl.formatMessage(messages[status])}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </div>
    );
};
