import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';

const getInstancePermissionsDialogState = (state: TRootState) => state.instancePermissions;

export const getInstancePermissions = createSelector(getInstancePermissionsDialogState, (state) => state.data);

export const getServerId = createSelector(getInstancePermissionsDialogState, (state) => state.serverId);

export const getPrincipalPermissions = createSelector(getInstancePermissions, (state) =>
    state ? state.permissions : [],
);
