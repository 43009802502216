import React from 'react';
import { PresetImage, KanbanCardSectorAttribute } from '../../../../../../../../serverapi/api';
import { AttributeImageSizeSelector } from '../../../../../../../FloatingAttributes/components/AttributesEditor/AttributeStyleEditor/AttributeImageStyleEditor/AttributeImageSizeSelector.component';
import theme from './KanbanCardAttributesEditor.scss';
import { AttributeImageSelector } from '../../../../../../../FloatingAttributes/components/AttributesEditor/AttributeStyleEditor/AttributeImageStyleEditor/AttributeImageSelector.component';

type TKanbanCardAttributeImageStyleSelectorProps = {
    currentAttribute: KanbanCardSectorAttribute;
    presetImages: PresetImage[];
    onImageIdChange: (imageId: string) => void;
    onImageSizeChange: (size: number) => void;
};

export const KanbanCardAttributeImageStyleSelector = ({
    currentAttribute,
    presetImages,
    onImageIdChange,
    onImageSizeChange,
}: TKanbanCardAttributeImageStyleSelectorProps) => {
    return (
        <div className={theme.attributeImageSelector}>
            <AttributeImageSelector
                images={presetImages}
                imageId={currentAttribute.settings.imageId}
                onChange={onImageIdChange}
                hasPreview={false}
            />
            <AttributeImageSizeSelector
                onChange={onImageSizeChange}
                initialValue={currentAttribute.settings.imageSize}
            />
        </div>
    );
};
