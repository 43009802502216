import type { TTreeItemSelectDialogProps } from './TreeItemSelectDialog.types';
import React, { FC } from 'react';
import messages from './TreeItemSelectDialog.messages';
import { useIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './TreeItemSelectDialog.component.scss';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { Tree } from '../../Tree/components/Tree/Tree.component';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { TButton } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.types';

export const TreeItemSelectDialog: FC<TTreeItemSelectDialogProps> = (props) => {
    const intl = useIntl();

    const {
        treeLocation,
        disableContextMenu,
        open,
        isTreeWithClearButton,
        typesAllowedToSelect,
        treeName,
        selectedNode,
        onClear,
        onClose,
    } = props;

    const handleSubmit = () => {
        if (selectedNode) props.onSubmit(selectedNode.nodeId, selectedNode);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                isTreeWithClearButton &&
                    ({
                        key: 'clear',
                        onClick: onClear,
                        value: intl.formatMessage(messages.formClearAttributeButton),
                        dataTest: 'clear_attribute-NODE_btn',
                        danger: true,
                    } as TButton),
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.formDeclineButton),
                    dataTest: 'clear_attribute-NODE_btn',
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.formConfirmButton),
                    visualStyle: 'primary',
                    dataTest: 'node-translation_button_submit',
                    disabled:
                        !selectedNode ||
                        (!!typesAllowedToSelect?.length && !typesAllowedToSelect.includes(selectedNode.type)),
                },
            ].filter((button): button is TButton => !!button)}
        />
    );

    return (
        <Dialog
            footer={footer}
            data-test="node-selection-dialog"
            className={theme.modal}
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.formName)}
            open={open}
            width={DEFAULT_DIALOG_WIDTH}
        >
            <Tree treeName={treeName} data={treeLocation || []} disableContextMenu={disableContextMenu} />
        </Dialog>
    );
};
