import * as React from 'react';
import { FC } from 'react';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import theme from './IconWithText.scss';

interface IIconWithTextProps {
    text: string;
    spriteSymbol?: TSpriteSymbol;
    disabled?: boolean;
    dataTest?: string;
}

export const IconWithText: FC<IIconWithTextProps> = ({ text, spriteSymbol, disabled, dataTest }) => (
    <div data-test={dataTest}>
        {spriteSymbol && <Icon className={theme.menuIcon} spriteSymbol={spriteSymbol} disabled={disabled} />}
        {text}
    </div>
);
