import {
    GET_NOTATION_REPLACEMENT_DATA_BY_NODE_ID_REQUEST,
    GET_NOTATION_REPLACEMENT_DATA_BY_NODE_ID_SUCCESS,
    REPLACE_NOTATIONS,
} from '../actionsTypes/changeEntityType.actionTypes';
import {
    TGetNotationReplacementDataByNodeIdRequestPayload,
    TGetNotationReplacementDataByNodeIdRequestAction,
    TGetNotationReplacementDataByNodeIdSuccessPayload,
    TGetNotationReplacementDataByNodeIdSuccessAction,
    TReplaceNotationsPayload,
    TReplaceNotationsAction,
} from './changeEntityType.types';

export const getNotationReplacementDataByNodeIdRequest = (
    payload: TGetNotationReplacementDataByNodeIdRequestPayload,
): TGetNotationReplacementDataByNodeIdRequestAction => ({
    type: GET_NOTATION_REPLACEMENT_DATA_BY_NODE_ID_REQUEST,
    payload,
});

export const getNotationReplacementDataByNodeIdSuccess = (
    payload: TGetNotationReplacementDataByNodeIdSuccessPayload,
): TGetNotationReplacementDataByNodeIdSuccessAction => ({
    type: GET_NOTATION_REPLACEMENT_DATA_BY_NODE_ID_SUCCESS,
    payload,
});

export const replaceNotations = (payload: TReplaceNotationsPayload): TReplaceNotationsAction => ({
    type: REPLACE_NOTATIONS,
    payload,
});
