import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'EraseDeletedNodeDialog.title',
        defaultMessage: 'Полное удаление узла дерева',
    },
    text: {
        id: 'EraseDeletedNodeDialog.text',
        defaultMessage: 'Вы уверены, что хотите удалить {type} "{name}" без возможности восстановления?',
    },
});

