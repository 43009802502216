import React from 'react';
import theme from './CommentTooltipText.scss';
import cx from 'classnames';

type TCommentTooltipText = {
    isSmallFormat: boolean;
    text: string;
};

const smallTextLength: number = 340;

export const CommentTooltipText = (props: TCommentTooltipText) => {
    const { isSmallFormat, text } = props;

    return (
        <div className={cx(theme.commentText, { [theme.smallFormat]: isSmallFormat })}>
            {isSmallFormat && text.length > smallTextLength ? `${text.slice(0, smallTextLength)} ...` : text}
        </div>
    );
};
