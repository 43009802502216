import { API_PUBLIC, HTTP, HTTPS, MODEL, PNG } from '../../utils/consts';

export class PublicLinkBLLService {
    public static createPublicLink(serverUrl: string, repositoryId: string, modelId: string) {
        const modelPath: string = `${MODEL}/${repositoryId}/${modelId}`;
        const modelImageUrl: string = `${serverUrl}${API_PUBLIC}${modelPath}${PNG}`;

        if (serverUrl.includes(HTTP) || serverUrl.includes(HTTPS)) {
            return modelImageUrl;
        }
        return `${location.protocol}//${modelImageUrl}`;
    }
}
