import { SELECT_EDGE_DEFINITION_DIALOG_PREPARE_DATA_SUCCESS } from '../actionsTypes/selectEdgeDefinitionDialog.actionTypes';
import { TReducer } from '../utils/types';
import { TSelectEdgeDefinitionDialogState } from './selectEdgeDefinitionDialog.reducer.types';

const initial: TSelectEdgeDefinitionDialogState = {
    edgeTypeNameMap: new Map<string, string>(),
    sourceObjectName: '',
    targetObjectName: '',
};

export const selectEdgeDefinitionDialogReducer: TReducer<TSelectEdgeDefinitionDialogState> = (
    state = initial,
    action,
) => {
    switch (action.type) {
        case SELECT_EDGE_DEFINITION_DIALOG_PREPARE_DATA_SUCCESS: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
