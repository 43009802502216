import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { v4 as uuid } from 'uuid';
import { DIALOG_OPEN, DIALOG_CLOSE } from '../actionsTypes/dialogs.actionTypes';
import { TDialogCloseAction, TDialogOpenAction } from './dialogs.actions.types';

export const openDialog = (type: DialogType, props: {} = {}): TDialogOpenAction => ({
    type: DIALOG_OPEN,
    payload: {
        type,
        props,
        guid: uuid(),
    },
});

export const closeDialog = (type: DialogType): TDialogCloseAction => ({
    type: DIALOG_CLOSE,
    payload: type,
});
