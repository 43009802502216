import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TLastEdgesTypesIds } from '../reducers/statistics.reducer.types';
import { EdgeType } from '../serverapi/api';

export namespace StatisticsSelectors {
    const getState = (state: TRootState) => state.statistics;

    export const getLastEdgesTypesIds = createSelector(getState, (state): TLastEdgesTypesIds => {
        return state.lastEdgesTypesIds;
    });

    export const getLastUsedEdgeType = (edgeTypes: EdgeType[]) =>
        createSelector(getLastEdgesTypesIds, (lastEdgesTypesIds) => {
            const sortedEdgeTypes = edgeTypes.sort((type1, type2) => {
                return (lastEdgesTypesIds[type2.id] || 0) - (lastEdgesTypesIds[type1.id] || 0);
            });

            return sortedEdgeTypes[0];
        });
}
