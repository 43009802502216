import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'AddAccessTokentDialog.title',
        defaultMessage: 'Создание нового ключа',
    },
    create: {
        id: 'AddAccessTokentDialog.create',
        defaultMessage: 'Создать',
    },
    cancel: {
        id: 'AddAccessTokentDialog.cancel',
        defaultMessage: 'Отменить',
    },
    tokenName: {
        id: 'AddAccessTokentDialog.tokenName',
        defaultMessage: 'Название ключа',
    },
    locale: {
        id: 'AddAccessTokentDialog.locale',
        defaultMessage: 'Локаль',
    },
    exriresAt: {
        id: 'AddAccessTokentDialog.exriresAt',
        defaultMessage: 'Действителен до',
    },
    user: {
        id: 'AddAccessTokentDialog.user',
        defaultMessage: 'Пользователь',
    },
    selectDate: {
        id: 'AddAccessTokentDialog.selectDate',
        defaultMessage: 'Выберите дату',
    },
    requiredField: {
        id: 'AddAccessTokentDialog.requiredField',
        defaultMessage: 'Обязательные поля должны быть заполнены',
    },
});
