import {
    CLEAR_STATUSES,
    FIND_DUPLICATES_DATA_FAILURE,
    FIND_DUPLICATES_DATA_REQUEST,
    FIND_DUPLICATES_DATA_SUCCESS,
    MERGE_DATA_REQUEST,
    MERGE_DATA_REQUEST_FAILURE,
    MERGE_DATA_REQUEST_SUCCESS,
    SET_STATUSES,
} from '@/actionsTypes/findDuplicates.actionTypes';
import { TReducer } from '../utils/types';
import { TNodeState } from './entities/TNodeState';
import { TFindDuplicatesState } from './findDuplicates.types';
import { DuplicatesElementInfo, DuplicatesGroup, FindDuplicatesResponse, MergeObjectStatus } from '@/serverapi/api';

const INITIAL_STATE: TFindDuplicatesState = {
    nodeState: new TNodeState(),
    statusesState: new TNodeState(),
    fetchingDuplicatesInProgress: false,
    combiningInProcess: false,
};

export const findDuplicatesReducer: TReducer<TFindDuplicatesState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FIND_DUPLICATES_DATA_REQUEST:
            return {
                ...state,
                fetchingDuplicatesInProgress: true,
            };

        case MERGE_DATA_REQUEST:
            return {
                ...state,
                combiningInProcess: true,
            };

        case FIND_DUPLICATES_DATA_SUCCESS: {
            const { nodeId, data } = action.payload;
            return {
                ...state,
                nodeState: state.nodeState.set(nodeId, data),
                fetchingDuplicatesInProgress: false,
            };
        }

        case FIND_DUPLICATES_DATA_FAILURE: {
            return {
                ...state,
                fetchingDuplicatesInProgress: false,
            };
        }

        case MERGE_DATA_REQUEST_FAILURE: {
            return {
                ...state,
                combiningInProcess: false,
            };
        }

        case MERGE_DATA_REQUEST_SUCCESS: {
            const { nodeId, updatedData } = action.payload;
            const currentGroupData: FindDuplicatesResponse | undefined = state.nodeState.get(nodeId);
            const updatedGroupData: DuplicatesGroup[] | undefined = currentGroupData?.duplicatesGroups.map((group) => ({
                ...group,
                duplicatesElementInfo: group.duplicatesElementInfo.map((element: DuplicatesElementInfo) => {
                    const updatedElement: MergeObjectStatus | undefined = updatedData.list.find((item) => item.id === element.id);

                    return updatedElement ? { ...element, status: updatedElement.status } : element;
                }),
            }));

            return {
                ...state,
                combiningInProcess: false,
                nodeState: state.nodeState.set(nodeId, {
                    ...currentGroupData,
                    duplicatesGroups: updatedGroupData || [],
                }),
            };
        }

        case SET_STATUSES: {
            const { nodeId, statuses } = action.payload;
            return {
                ...state,
                statusesState: state.statusesState.set(nodeId, statuses),
            };
        }

        case CLEAR_STATUSES: {
            const { nodeId } = action.payload;
            return {
                ...state,
                statusesState: state.statusesState.delete(nodeId),
            };
        }

        default:
            return state;
    }
};
