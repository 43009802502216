import { defineMessages } from 'react-intl';

export default defineMessages({
    scriptSelectorDialogName: {
        id: 'SelectScriptToScheduleDialog.scriptSelectorDialogName',
        defaultMessage: 'Выберите скрипт для исполнения',
    },
    scriptFormConfirmButtonRun: {
        id: 'SelectScriptToScheduleDialog.scriptFormConfirmButtonRun',
        defaultMessage: 'Выбрать',
    },
    scriptNotSelectedError: {
        id: 'SelectScriptToScheduleDialog.scriptNotSelectedError',
        defaultMessage: 'Не выбран скрипт для исполнения',
    },
});
