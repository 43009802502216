import { defineMessages } from 'react-intl';

export default defineMessages({
    users: {
        id: 'UsersSelect.users',
        defaultMessage: 'Пользователи',
    },
    emails: {
        id: 'UsersSelect.emails',
        defaultMessage: 'Email адреса',
    },
    emailsPlaceholder: {
        id: 'UsersSelect.emailsPlaceholder',
        defaultMessage: "Введите Email'ы через запятую",
    },
    placeholder: {
        id: 'UsersSelect.placeholder',
        defaultMessage: 'Выберите пользователей',
    },
});
