import {
    NOT_SAVE_UNSAVED_DECOMPOSITION_MODELS,
    SAVE_MODEL,
    SAVE_MODEL_COMMON,
    SAVE_MODEL_FAIL,
    SAVE_MODEL_SUCCESS,
    SAVE_UNSAVED_DECOMPOSITION_MODELS,
} from '../actionsTypes/save.actionTypes';
import { ModelNode } from '../serverapi/api';
import {
    TSaveModelSuccessAction,
    TSaveModelCommonAction,
    TSaveModelFailAction,
    TSaveModelAction,
    TSaveUnsavedDecompositionModelsAction,
    TNotSaveUnsavedDecompositionModelsAction,
    TSaveModelPayload,
} from './save.actions.types';

export const saveModelSuccess = (model: ModelNode): TSaveModelSuccessAction => ({
    type: SAVE_MODEL_SUCCESS,
    payload: { model },
});

export const saveModelFail = (): TSaveModelFailAction => ({
    type: SAVE_MODEL_FAIL,
    payload: {},
});

export const saveModel = (payload: TSaveModelPayload): TSaveModelAction => ({
    type: SAVE_MODEL,
    payload,
});

export const saveUnsavedDecompositionModel = (): TSaveUnsavedDecompositionModelsAction => ({
    type: SAVE_UNSAVED_DECOMPOSITION_MODELS,
    payload: {},
});

export const notSaveUnsavedDecompositionModel = (): TNotSaveUnsavedDecompositionModelsAction => ({
    type: NOT_SAVE_UNSAVED_DECOMPOSITION_MODELS,
    payload: {},
});

export const saveModelCommon = (graphId: string): TSaveModelCommonAction => ({
    type: SAVE_MODEL_COMMON,
    payload: {
        graphId,
    },
});
