import React from 'react';
import { useSelector } from 'react-redux';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import { NodeId } from '../../../../serverapi/api';
import { UserIcon } from '../../../UIKit/components/UserIcon/UserIcon.component';

type TCommentCircleProps = {
    modelId: NodeId;
    commentId: string;
    withBorder?: boolean;
    moveToMarker?: () => void;
    addMarker?: () => void;
};

export const CommentCircle = (props: TCommentCircleProps) => {
    const { modelId, commentId, withBorder, moveToMarker, addMarker } = props;
    const isOpenCriticalComment: boolean = useSelector(
        CommentsSelectors.checkIsOpenCriticalComment(modelId, commentId),
    );
    const initials: string = useSelector(CommentsSelectors.getAuthorInitials(modelId, commentId));
    const circleBackgroundColor: string = useSelector(
        CommentsSelectors.getCommentCircleBackgroundColor(modelId, commentId),
    );

    return (
        <UserIcon
            circleBackgroundColor={circleBackgroundColor}
            initials={initials}
            handleClick={() => moveToMarker && moveToMarker()}
            onDragStartHandler={(event) => {
                event.preventDefault();
                addMarker && addMarker();
            }}
            circleSize={32}
            fontSize={12}
            isDraggable
            isCriticalIcon={isOpenCriticalComment}
            withBoarder={withBorder}
            dataTestCircle={'user-comment_circle'}
            dataTestInitials={'user-comment_initials'}
        />
    );
};
