import React, { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './ApprovalDialog.messages';
import { FormInstance } from 'antd';
import { ApprovalDialog, GENERAL_SETTINGS } from './ApprovalDialog.component';
import { ApprovalStageDTO, ApprovalTemplateDTO } from '@/serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { ApprovalTemplatesSelectors } from '@/selectors/approvalTemplates.selectors';
import { editApprovalTemplate, submitApprovalTemplate } from '@/actions/approvalTemplates.actions';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { TApprovalDialogTemplateContainerProps } from './ApprovalDialog.types';
import { ApprovalTemplatesGeneralSettingsTab } from './ApprovalTemplatesGeneralSettingsTab.component';

export const ApprovalDialogTemplateContainer: FC<TApprovalDialogTemplateContainerProps> = (props) => {
    const { isCreateMode, open } = props;

    const intl = useIntl();
    const approvalTemplate: ApprovalTemplateDTO = useSelector(ApprovalTemplatesSelectors.getEditingApprovalTemplate);
    const dispatch = useDispatch();
    const [tabName, setTabName] = useState<string>(GENERAL_SETTINGS);
    const formRef = useRef<FormInstance>(null);

    const onChangeStages = (stages: ApprovalStageDTO[] | undefined) => {
        dispatch(
            editApprovalTemplate({
                approvalTemplate: {
                    ...approvalTemplate,
                    stages,
                },
            }),
        );
    };

    const submitModifiedApproval = (stages: ApprovalStageDTO[] | undefined) => {
        dispatch(
            submitApprovalTemplate({
                approvalTemplate: {
                    ...approvalTemplate,
                    stages,
                },
                isCreateMode,
            }),
        );
    };

    const onCancel = () => dispatch(closeDialog(DialogType.APPROVAL_TEMPLATE_DIALOG));

    const generalFormComponent = (
        <ApprovalTemplatesGeneralSettingsTab approvalTemplate={approvalTemplate} formRef={formRef} />
    );

    const title = isCreateMode
        ? intl.formatMessage(messages.newApprovalTemplate)
        : intl.formatMessage(messages.editApprovalTemplate);

    return (
        <ApprovalDialog
            isTemplate
            onCancel={onCancel}
            tabName={tabName}
            setTabName={setTabName}
            formRef={formRef}
            title={title}
            generalFormComponent={generalFormComponent}
            open={open}
            isCreateMode={isCreateMode}
            approval={approvalTemplate}
            onChangeStages={onChangeStages}
            submitModifiedApproval={submitModifiedApproval}
        />
    );
};
