import { TReducer } from '../utils/types';
import {
    ADD_OBJECT_TYPE_GROUPS,
    DELETE_OBJECT_TYPE_GROUP_SUCCESS,
    SUBMIT_OBJECT_TYPE_GROUP,
} from '../actionsTypes/objectTypeGroup.actionTypes';
import { TObjectTypeGroupState } from './objectTypeGroup.reducer.types';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import { cloneState } from './utils/cloneState.utils';

export const INITIAL_OBJECT_TYPE_SERVER_STATE = {
    byPresetId: {},
};

const initial: TObjectTypeGroupState = {
    byServerId: {},
};

export const objectTypeGroupReducer: TReducer<TObjectTypeGroupState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_OBJECT_TYPE_GROUPS:
        case SUBMIT_OBJECT_TYPE_GROUP: {
            const {
                payload: { serverNode, preset, objectTypeGroups },
            } = action;
            const presetId = preset.id;
            const { serverId } = serverNode.nodeId;

            const stateClone = cloneState(state, serverId, presetId);

            const { byId } = stateClone.byServerId[serverId].byPresetId[presetId];

            objectTypeGroups.forEach((objectTypeGroup) => {
                byId[objectTypeGroup.id] = objectTypeGroup;
            });

            return stateClone;
        }

        case DELETE_OBJECT_TYPE_GROUP_SUCCESS: {
            const { objectTypeGroups, serverNode } = action.payload;
            if (objectTypeGroups) {
                const stateClone = JSON.parse(JSON.stringify(state));
                const { serverId } = serverNode.nodeId;
                objectTypeGroups.forEach((objectTypeGroup) => {
                    const { presetId } = objectTypeGroup;
                    if (serverId && presetId) {
                        const byId = stateClone.byServerId[serverId]?.byPresetId[presetId]?.byId || {};
                        delete byId[objectTypeGroup.id];
                    }
                });

                return stateClone;
            }

            return state;
        }

        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }

        default:
            return state;
    }
};
