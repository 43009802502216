// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropdown__overlayContainer__VB2SM{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;background-color:#fff;background-clip:padding-box;border-radius:0;-webkit-box-shadow:0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);box-shadow:0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);padding:12px;grid-gap:12px;gap:12px}.Dropdown__compactOverlayContainer___JWBi{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;padding:4px;grid-gap:4px;gap:4px}.Dropdown__menuIcon__G3psT{display:inline-block;height:16px;width:16px;vertical-align:middle}.Dropdown__menuLabel__qiUvJ{margin-left:8px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Toolbar/Dropdown.scss"],"names":[],"mappings":"AAAA,mCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,qBAAA,CACA,2BAAA,CACA,eAAA,CACA,6GAAA,CAAA,qGAAA,CACA,YAAA,CACA,aAAA,CAAA,QAAA,CAGJ,0CACI,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,WAAA,CACA,YAAA,CAAA,OAAA,CAGJ,2BACI,oBAAA,CACA,WAAA,CACA,UAAA,CACA,qBAAA,CAGJ,4BACI,eAAA","sourcesContent":[".overlayContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: #ffffff;\r\n    background-clip: padding-box;\r\n    border-radius: 0;\r\n    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);\r\n    padding: 12px;\r\n    gap: 12px;\r\n}\r\n\r\n.compactOverlayContainer {\r\n    flex-direction: row;\r\n    padding: 4px;\r\n    gap: 4px;\r\n}\r\n\r\n.menuIcon {\r\n    display: inline-block;\r\n    height: 16px;\r\n    width: 16px;\r\n    vertical-align: middle;\r\n}\r\n\r\n.menuLabel {\r\n    margin-left: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlayContainer": "Dropdown__overlayContainer__VB2SM",
	"compactOverlayContainer": "Dropdown__compactOverlayContainer___JWBi",
	"menuIcon": "Dropdown__menuIcon__G3psT",
	"menuLabel": "Dropdown__menuLabel__qiUvJ"
};
export default ___CSS_LOADER_EXPORT___;
