import { Modal } from 'antd';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import React from 'react';

type TShowConfirmationDialog = {
    action: string;
    title: string;
    users: string[];
    okText: string;
    cancelText: string;
    onOk: () => void;
};

export const showConfirmationDialog = (props: TShowConfirmationDialog) => {
    const { users, action, okText, cancelText, title, onOk } = props;
    const content = `${action} ${users.join(', ')} ${users.length >= 5 ? `(${users.length})` : ''} ?`;
    const footer = (
        <div style={{ textAlign: 'end' }}>
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: () => Modal.destroyAll(),
                        value: cancelText,
                    },
                    {
                        key: 'ok',
                        onClick: () => {
                            onOk();
                            Modal.destroyAll();
                        },
                        value: okText,
                        danger: true,
                        visualStyle: 'primary',
                    },
                ]}
            />
        </div>
    );
    Modal.confirm({
        className: 'confirmationDialog',
        title,
        content,
        closable: true,
        icon: '',
        onOk() {
            onOk();
            Modal.destroyAll();
        },
        okCancel: true,
        footer: footer,
    });
};
