import { Dropdown, MenuProps } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { useState, useCallback, FC } from 'react';
import { NavigatorPanelButton } from '../../../../Navigator/components/NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { DropdownIcon } from '../../../../Navigator/components/NavigatorPanel/icons/DropdownIcon.component';
import { getMenuItem } from '../../../../../utils/antdMenuItem.utils';
import messages from '../../../messages/CommentsPanel.messages';
import theme from '../CommentsAdditionalMenu.scss';
import check from '../../../../../resources/icons/check.svg';
import { useIntl } from 'react-intl';
import { Icon } from '../../../../UIKit';
import { CommentFilterStatus } from '../../../../../models/commentMarkerConstants';
import { getAntdPopupContainer } from '../../../../../utils/getAntdPopupContainer';

enum PanelMenuActions {
    atFirstNew = 'AT_FIRST_NEW',
    atFirstOld = 'AT_FIRST_OLD',
    showUnresolved = 'SHOW_UNRESOLVED',
    showResolved = 'SHOW_RESOLVED',
    showAll = 'SHOW_ALL',
}

type TPanelActionsDropdownProps = {
    atFirstOld: boolean;
    setTimeFilter: (isAtFirstOld: any) => void;
    filterStatus: CommentFilterStatus;
    setFilterStatus: (filterStatus: CommentFilterStatus) => void;
};

export const PanelActionsDropdown: FC<TPanelActionsDropdownProps> = ({
    atFirstOld,
    filterStatus,
    setTimeFilter,
    setFilterStatus,
}) => {
    const intl = useIntl();

    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const onClick = (param: MenuInfo) => {
        switch (param.key) {
            case PanelMenuActions.atFirstNew:
                setTimeFilter(false);
                break;
            case PanelMenuActions.atFirstOld:
                setTimeFilter(true);
                break;
            case PanelMenuActions.showUnresolved:
                setFilterStatus(CommentFilterStatus.notResolved);
                break;
            case PanelMenuActions.showResolved:
                setFilterStatus(CommentFilterStatus.resolved);
                break;
            case PanelMenuActions.showAll:
                setFilterStatus(CommentFilterStatus.all);
                break;
            default:
                break;
        }
    };

    const items: MenuProps['items'] = [
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.atFirstNew)}</div>,
            PanelMenuActions.atFirstNew,
            false,
            <div className={theme.iconContainer}>{!atFirstOld && <Icon spriteSymbol={check} />}</div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.atFirstOld)}</div>,
            PanelMenuActions.atFirstOld,
            false,
            <div className={theme.iconContainer}>{atFirstOld && <Icon spriteSymbol={check} />}</div>,
        ),
        {
            type: 'divider',
        },
        getMenuItem(
            <div data-test="comment-panel_unresolved-filter-btn" className={theme.menuItem}>{intl.formatMessage(messages.commentsPanelFilterNotResolved)}</div>,
            PanelMenuActions.showUnresolved,
            false,
            <div className={theme.iconContainer}>
                {filterStatus === CommentFilterStatus.notResolved && <Icon spriteSymbol={check} />}
            </div>,
        ),
        getMenuItem(
            <div data-test="comment-panel_resolved-filter-btn" className={theme.menuItem}>{intl.formatMessage(messages.commentsPanelFilterResolved)}</div>,
            PanelMenuActions.showResolved,
            false,
            <div className={theme.iconContainer}>
                {filterStatus === CommentFilterStatus.resolved && <Icon spriteSymbol={check} />}
            </div>,
        ),
        getMenuItem(
            <div data-test="comment-panel_no-filter-btn" className={theme.menuItem}>{intl.formatMessage(messages.commentsPanelFilterAll)}</div>,
            PanelMenuActions.showAll,
            false,
            <div className={theme.iconContainer}>
                {filterStatus === CommentFilterStatus.all && <Icon spriteSymbol={check} />}
            </div>,
        ),
    ];

    return (
        <Dropdown
            onOpenChange={toggleOpen}
            open={open}
            menu={{ onClick, items }}
            trigger={['click']}
            getPopupContainer={getAntdPopupContainer}
            destroyPopupOnHide
        >
            <NavigatorPanelButton IconComponent={DropdownIcon} active={open} dataTest='comment-panel_filters-options-btn'/>
        </Dropdown>
    );
};
