import { Mark, markInputRule, markPasteRule, mergeAttributes } from '@tiptap/core';

type TCodeOptions = {
    HTMLAttributes: Record<string, any>;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        code: {
            /**
             * Set a code mark
             */
            setCode: () => ReturnType;
            /**
             * Toggle inline code
             */
            toggleCode: () => ReturnType;
            /**
             * Unset a code mark
             */
            unsetCode: () => ReturnType;
        };
    }
}

export const inputRegex = /(?:^|\s)((?:`)((?:[^`]+))(?:`))$/;
export const pasteRegex = /(?:^|\s)((?:`)((?:[^`]+))(?:`))/g;

export const Code = Mark.create<TCodeOptions>({
    name: 'code',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    // TODO использовать оригинальное расширение через Mark.extend
    // excludes: '_',

    code: true,

    exitable: true,

    parseHTML() {
        return [{ tag: 'code' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['code', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addCommands() {
        return {
            setCode:
                () =>
                ({ commands }) => {
                    return commands.setMark(this.name);
                },
            toggleCode:
                () =>
                ({ commands }) => {
                    return commands.toggleMark(this.name);
                },
            unsetCode:
                () =>
                ({ commands }) => {
                    return commands.unsetMark(this.name);
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            'Mod-e': () => this.editor.commands.toggleCode(),
        };
    },

    addInputRules() {
        return [
            markInputRule({
                find: inputRegex,
                type: this.type,
            }),
        ];
    },

    addPasteRules() {
        return [
            markPasteRule({
                find: pasteRegex,
                type: this.type,
            }),
        ];
    },
});
