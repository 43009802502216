import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Input } from 'antd';
import { UserDTOLicensesEnum, LicenseCountDTO } from '../../../../serverapi/api';
import licenseTypes from '../../../../models/licenseTypes';
import licenseTypesMsg from '../../../../models/licenseTypes.messages';
import licensesToolMessages from '../../messages/licensestool.messages';
import { SearchOutlined } from '@ant-design/icons';

type TLicenseOwnersEditingTabToolbarProps = {
    licenseType: UserDTOLicensesEnum;
    resulting?: LicenseCountDTO;
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default class LicenseOwnersEditingTabToolbar extends Component<TLicenseOwnersEditingTabToolbarProps> {
    getResulting = (resulting: LicenseCountDTO, licenseType: UserDTOLicensesEnum) => {
        if (licenseType == licenseTypes.MODEL_EDITOR) {
            return resulting.modelEditorsCount;
        } if (licenseType == licenseTypes.MODEL_READER) {
            return resulting.modelReadersCount;
        }

        return resulting.modelLiteEditorsCount;
    };

    render() {
        const { licenseType, handleSearch, resulting } = this.props;

        return (
            <Row gutter={16}>
                <Col span={8}>
                    <FormattedMessage {...licensesToolMessages['licenseType']} />
                    {licenseTypesMsg[licenseType] && (
                        <b>
                            {`${': '}`}
                            <FormattedMessage {...licenseTypesMsg[licenseType]} />
                        </b>
                    )}
                </Col>
                <Col span={6}>
                    <Input suffix={<SearchOutlined />} onChange={handleSearch} style={{ width: 200 }} />
                </Col>
                <Col span={6}>
                    <FormattedMessage {...licensesToolMessages.amountOfFree} />
                    <br /> {resulting && this.getResulting(resulting, licenseType)}
                </Col>
            </Row>
        );
    }
}
