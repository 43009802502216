// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportVisio-component__container__uC5PK{padding:20px 12px 20px 20px;overflow:auto;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/Import/Visio/components/ImportVisio/ImportVisio.component.scss"],"names":[],"mappings":"AAAA,yCACI,2BAAA,CACA,aAAA,CACA,WAAA","sourcesContent":[".container {\r\n    padding: 20px 12px 20px 20px;\r\n    overflow: auto;\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ImportVisio-component__container__uC5PK"
};
export default ___CSS_LOADER_EXPORT___;
