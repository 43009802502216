import { connect } from 'react-redux';
import { GeneralMenuItem } from '../components/items/GeneralMenuItem/GeneralMenuItem.component';
import { TGeneralMenuItemProps } from '../components/items/GeneralMenuItem/GeneralMenuItem.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { generalMenuClose, generalMenuExpand, generalMenuCollapse } from '../../../actions/generalMenu.actions';
import { TabsSelectors } from '../../../selectors/tabs.selectors';

const mapStateToProps = (
    state: TRootState,
    ownProps: Partial<TGeneralMenuItemProps>,
): Partial<TGeneralMenuItemProps> => {
    const activeScheme = TabsSelectors.getActiveTab(state);
    const toolbarComponent = activeScheme && activeScheme.type ? activeScheme.type : 'HomePageTab'; // homePageTabId пустая панель главного меню по умолчанию

    return {
        toolbarComponent,
        itemKey: ownProps.itemKey,
        compact: state.generalMenu.isCompact,
        activeKey: TabsSelectors.getActiveTabId(state),
    };
};

const mapDispatchToProps = (dispatch): Partial<TGeneralMenuItemProps> => ({
    onMenuExpandClick: () => {
        dispatch(generalMenuExpand());
    },
    onMenuCollapseClick: () => {
        dispatch(generalMenuCollapse());
    },
    onMenuCloseClick: () => {
        dispatch(generalMenuClose());
    },
});

export const GeneralMenuItemContainer = connect(mapStateToProps, mapDispatchToProps)(GeneralMenuItem);
