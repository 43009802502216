/**
 * Created by kolpakovd on 11/2/2017.
 */
export enum LabelStyle {
    decorationStyle,
    alignmentStyle,
    fontSize,
    fontFamily,
    textDirection,
}
