import React, { Component } from 'react';
import { debounce } from 'lodash-es';
import { TWithWindowResizeProps } from './withWindowResize.types';

export const withWindowResize = <P extends TWithWindowResizeProps>(WComponent: React.ComponentType<P>) => {
    return class extends Component<P> {
        state = {
            wHeight: 0,
            wWidth: 0,
        };

        onResize = debounce(() => {
            if (this.state.wHeight !== window.innerHeight) {
                this.setState({
                    wHeight: window.innerHeight,
                });
            }

            if (this.state.wWidth !== window.innerWidth) {
                this.setState({
                    wWidth: window.innerWidth,
                });
            }
        }, 400);

        componentDidMount() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.onResize);
        }

        render() {
            return <WComponent {...this.props} wHeight={this.state.wHeight} wWidth={this.state.wWidth} />;
        }
    };
};
