import { TBatchActionsAction, TBatchActionsPayload, TRecentRefreshAction } from './rootReducer.actions.types';
import { BATCH_ACTIONS, CLEAR_STORE } from '../actionsTypes/rootReducer.actionTypes';

export const clearStore = (): TRecentRefreshAction => ({
    type: CLEAR_STORE,
});

export const batchActions = (payload: TBatchActionsPayload): TBatchActionsAction => ({
    type: BATCH_ACTIONS,
    payload,
});
