import { KANBAN_CARD_TYPE_REQUEST_SUCCESS } from '../actionsTypes/kanbanCardType.actionTypes';
import {
    TKanbanCardTypeRequestSuccessAction,
    TKanbanCardTypeRequestSuccessPayload,
} from './kanbanCardType.actions.types';

export const kanbanCardTypeRequestSuccess = (
    payload: TKanbanCardTypeRequestSuccessPayload,
): TKanbanCardTypeRequestSuccessAction => ({
    type: KANBAN_CARD_TYPE_REQUEST_SUCCESS,
    payload,
});
