import React, { FC, useEffect, useRef } from 'react';
import theme from './LineChart.scss';
import messages from '../widget.messages';
import { useIntl } from 'react-intl';
import { TChartData, TWidgetProps } from '../widget.types';
import {
    Chart,
    LineController,
    LinearScale,
    LineElement,
    CategoryScale,
    PointElement,
    ChartConfiguration,
    ChartData,
    Legend,
    Title,
} from 'chart.js';
import { Col } from 'antd';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const LineChart: FC<TWidgetProps> = ({ data, title }) => {
    const intl = useIntl();
    const { labels } = data as TChartData;
    const datasets = (data as TChartData).datasets.map((dataset) => {
        return { ...dataset, backgroundColor: ['#fff'] };
    });
    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        Chart.register(
            LineController,
            LinearScale,
            LineElement,
            CategoryScale,
            PointElement,
            Legend,
            Title,
            ChartDataLabels,
        );
    }, []);

    const chartData: ChartData<'line', any[], any> = {
        labels: labels,
        datasets,
    };

    const config: ChartConfiguration<'line', any[], any> = {
        type: 'line',
        data: chartData,
        plugins: [ChartDataLabels],
        options: {
            plugins: {
                legend: {
                    position: 'bottom',
                    display: true,
                    labels: {
                        filter: (item) => !!item.text,
                        usePointStyle: true,
                        boxWidth: 7,
                        pointStyle: 'circle',
                        boxHeight: 7,
                    },
                },
                title: {
                    display: true,
                    text: title,
                    padding: {
                        bottom: 20,
                        top: 5,
                    },
                },
                datalabels: {
                    align: 'top',
                },
            },
            maintainAspectRatio: false,
        },
    };

    useEffect(() => {
        let lineChart: Chart<'line', number[], string> | null = null;
        if (ref.current) {
            lineChart = new Chart(ref.current, config);
        }
        return () => {
            lineChart && lineChart.destroy();
        };
    }, [ref, data, title]);

    const checkData = datasets.some(({ data }) => data.length);

    return (
        <div className={theme.lineChartContainer}>
            {checkData ? (
                <canvas ref={ref}></canvas>
            ) : (
                <Col style={{ width: '100%' }}>
                    <div className={theme.header}>{title}</div>
                    <div className={theme.text}>{intl.formatMessage(messages.noData)}</div>
                </Col>
            )}
        </div>
    );
};
