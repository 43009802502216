// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrincipalLabel__principalIcon___FOBs{display:inline-block;vertical-align:middle;width:16px;height:16px}.PrincipalLabel__principalIcon___FOBs>img{display:block;max-width:100%}.PrincipalLabel__principalContainer___pevY{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.PrincipalLabel__iconContainer__hMbkt{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:16px;width:16px;padding-right:2px;margin-top:4px}", "",{"version":3,"sources":["webpack://./src/modules/Permissions/components/PrincipalLabel/PrincipalLabel.scss"],"names":[],"mappings":"AAAA,sCACI,oBAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CAEA,0CACI,aAAA,CACA,cAAA,CAIR,2CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGJ,sCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,cAAA","sourcesContent":[".principalIcon {\r\n    display: inline-block;\r\n    vertical-align: middle;\r\n    width: 16px;\r\n    height: 16px;\r\n\r\n    > img {\r\n        display: block;\r\n        max-width: 100%;\r\n    }\r\n}\r\n\r\n.principalContainer {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.iconContainer {\r\n    display: flex;\r\n    align-items: center;\r\n    height: 16px;\r\n    width: 16px;\r\n    padding-right: 2px;\r\n    margin-top: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"principalIcon": "PrincipalLabel__principalIcon___FOBs",
	"principalContainer": "PrincipalLabel__principalContainer___pevY",
	"iconContainer": "PrincipalLabel__iconContainer__hMbkt"
};
export default ___CSS_LOADER_EXPORT___;
