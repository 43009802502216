import React, { useEffect } from 'react';
import {
    NodeId,
    QueryAttributeSpreadsheetTypeParams,
    QueryAttributeTypeParams,
    Node,
    QueryAttributeTypeParamsQueryAttributeTypeEnum,
} from '../../../../../../serverapi/api';
import { useIntl } from 'react-intl';
import messages from '../../../messages/QueryAttribute.messages';
import { EditableText } from '../../../../../UIKit/components/EditableText/EditableText.component';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { DialogType } from '../../../../../DialogRoot/DialogRoot.constants';
import { openDialog } from '../../../../../../actions/dialogs.actions';
import { ServerSelectors } from '../../../../../../selectors/entities/server.selectors';
import { nodeSelectorByNodeId } from '../../../../../../selectors/nodes.selector';
import { fetchNodes } from '../../../../../../actions/nodes.actions';
import { getNodeName } from '../../../../../Navigator/navigatorUtils/navigatorTreeSearch.utils';
import theme from './QueryAttribute.scss';
import { TreeItemType } from '../../../../../Tree/models/tree';
import { Select } from '../../../../../UIKit/components/Select/Select.component';
import { paramValueList } from './QueryAttribute.constans';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TQueryValuesProps = {
    label: string;
    param?: QueryAttributeTypeParams;
    onChange: (value: QueryAttributeTypeParams) => void;
};

export const QueryValues = (props: TQueryValuesProps) => {
    const { param, label, onChange } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const serverId: string = useSelector(ServerSelectors.serverId);
    const isSpreadsheet: boolean = param?.queryAttributeType === 'SPREADSHEET';
    const nodeId: NodeId | undefined = (param as QueryAttributeSpreadsheetTypeParams | undefined)?.nodeId;
    const node: Node | undefined = useSelector(nodeSelectorByNodeId(nodeId));

    useEffect(() => {
        if (nodeId) {
            dispatch(fetchNodes([nodeId]));
        }
    }, [nodeId]);

    const onClickIcon = () =>
        dispatch(
            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                serverId,
                disableContextMenu: true,
                isTreeWithClearButton: true,
                typesAllowedToSelect: [TreeItemType.Spreadsheet],
                includeTypes: [TreeItemType.Repository, TreeItemType.Folder, TreeItemType.Spreadsheet],
                onSubmit: (nodeId: NodeId) => onChange({ nodeId } as QueryAttributeSpreadsheetTypeParams),
                onClear: () => onChange({ nodeId: {} } as QueryAttributeSpreadsheetTypeParams),
            }),
        );

    const selectValue = param?.queryAttributeType
        ? intl.formatMessage(messages[param?.queryAttributeType])
        : intl.formatMessage(messages[paramValueList[0]]);

    return (
        <div>
            <Select
                wrapperClassName={theme.formItemWrapper}
                originalTheme
                label={label}
                value={selectValue}
                onChange={(value: QueryAttributeTypeParamsQueryAttributeTypeEnum) => onChange({ queryAttributeType: value })}
            >
                {paramValueList.map((option) => (
                    <Select.Option key={option} value={option} label={intl.formatMessage(messages[option])} />
                ))}
            </Select>
            {isSpreadsheet && (
                <div className={theme.container}>
                    <div className={theme.linkBoxcontainer}>
                        <div className={theme.textContainer}>
                            <EditableText
                                text={node ? getNodeName(node) : ''}
                                isEditing={false}
                                disabled={false}
                                allowEmptyValue
                                isUrlType
                                onClickLink={onClickIcon}
                            />
                        </div>
                    </div>
                    <div className={theme.editButtonContainer}>
                        <Button onClick={onClickIcon}><EditOutlined className={theme.editButtonIcon} /></Button>
                    </div>
                </div>
            )}
        </div>
    );
};
