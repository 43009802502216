import { Form, Table } from 'antd';
import React, { FC, useState } from 'react';
import cx from 'classnames';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import messages from '../messages/ObjectPropertiesDialog.messages';
import theme from './ObjectPropertiesDialog.scss';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { NodeId, ParentModelOfEdgeDefinition, ParentModelOfObjectDefinition } from '../../../serverapi/api';
import { TreeItemType } from '../../Tree/models/tree';
import { useDispatch, useSelector } from 'react-redux';
import { objectDecompositionOpen } from '../../../actions/entities/objectDecomposition.actions';
import { UserProfileSelectors } from '../../../selectors/userProfile.selectors';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import icOpenModel from '../../../resources/icons/ic-open-model.svg';

type TObjectInstancesProps = WrappedComponentProps & {
    serverId: string;
    objectEntries?: ParentModelOfObjectDefinition[] | ParentModelOfEdgeDefinition[];
    toNodeId: (id: string) => NodeId;
};

type TObjectInstance = {
    modelNodeId: NodeId;
    modelTypeId: string;
    model: string;
    count: string;
};

const ObjectInstances: FC<TObjectInstancesProps> = (props) => {
    const { intl, objectEntries, toNodeId } = props;
    const [selectedObjectEntry, setSelectedObjectEntry] = useState<TObjectInstance | null>(null);
    const dispatch = useDispatch();

    const handleObjectEntryClickItem = (row: TObjectInstance) => () => {
        const { modelNodeId: { id } } = row;

        setSelectedObjectEntry((prevState: TObjectInstance | null) => (prevState?.modelNodeId.id === id ? null : row));
    };

    let objectInstances: TObjectInstance[] = [];
    if (objectEntries) {
        objectInstances = (objectEntries as ParentModelOfObjectDefinition[]).map((t, i) => {
            return {
                key: `object-instance-${i}`,
                modelNodeId: toNodeId(t.modelId!),
                model: `${t.modelName} [${t.modelTypeName}]`,
                modelTypeId: t.modelTypeId,
                count: t.entryCount!.toString(),
            } as TObjectInstance;
        })
        .sort((a: TObjectInstance, b: TObjectInstance) => a.model === b.model ? 0 : (a.model > b.model ? 1 : -1));
    }

    const objectInstancesColumns = [
        {
            title: intl.formatMessage(messages.modelName),
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: intl.formatMessage(messages.count),
            dataIndex: 'count',
            key: 'count',
            width: 110,
        },
    ];

    const openObjectInstance = () => {
        if (!selectedObjectEntry) return;

        dispatch(
            objectDecompositionOpen({ nodeId: toNodeId(selectedObjectEntry.modelNodeId.id), type: TreeItemType.Model }),
        );
    };

    const isSelectedModelReadable: boolean =
        useSelector(
            UserProfileSelectors.isModelReadable(selectedObjectEntry?.modelNodeId, selectedObjectEntry?.modelTypeId),
        );

    return (
        <FormGroup className={theme.formGroup}>
            <Form.Item>
                <Table
                    dataSource={objectInstances}
                    className={theme.table}
                    onRow={(row: TObjectInstance) => ({
                        onClick: handleObjectEntryClickItem(row),
                    })}
                    rowClassName={(row: TObjectInstance) =>
                        cx(theme.attribute, selectedObjectEntry?.modelNodeId.id === row.modelNodeId.id && theme.attribute_selected)
                    }
                    columns={objectInstancesColumns}
                    size="middle"
                    bordered
                    pagination={false}
                    scroll={{
                        y: 'max-content',
                        x: 'max-content',
                    }}
                />
                <div>
                    <div className={theme.detailingActionsInner}>
                        <Button
                            size="large"
                            disabled={!selectedObjectEntry || !isSelectedModelReadable}
                            onClick={openObjectInstance}
                            icon={icOpenModel}
                            tooltip={!isSelectedModelReadable ? intl.formatMessage(messages.noAccess) : ''}
                            dataTest = 'object-instances-settings-window_open-btn'
                        >
                            {intl.formatMessage(messages.open)}
                        </Button>
                    </div>
                </div>
            </Form.Item>
        </FormGroup>
    );
};

const IntlComponent = injectIntl(ObjectInstances);

export { IntlComponent as ObjectInstances };
