import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleScriptTitle: {
        id: 'messages.scheduleScriptTitle',
        defaultMessage: 'Планирование запуска скрипта',
    },
    scriptTitle: {
        id: 'messages.scriptTitle',
        defaultMessage: 'Запуск скрипта',
    },
    scriptName: {
        id: 'messages.scriptName',
        defaultMessage: 'Будет запущен скрипт',
    },
    executeScriptTimeLabel: {
        id: 'messages.executeScriptTimeLabel',
        defaultMessage: 'Время запуска',
    },
    scheduleScriptName: {
        id: 'messages.scheduleScriptName',
        defaultMessage: 'Будет запланирован запуск скрипта',
    },
    selectedNodeName: {
        id: 'messages.selectedNodeName',
        defaultMessage: 'Для узла: {nodeName}',
    },
    scriptParamsLabel: {
        id: 'messages.scriptParamsLabel',
        defaultMessage: 'Параметры',
    },
    scriptParamsPlaceholder: {
        id: 'messages.scriptParamsPlaceholder',
        defaultMessage: 'Введите параметры в формате name=value;',
    },
    scriptFormConfirmButtonPlan: {
        id: 'messages.scriptFormConfirmButtonPlan',
        defaultMessage: 'Запланировать',
    },
    scriptFormConfirmButtonStart: {
        id: 'messages.scriptFormConfirmButtonStart',
        defaultMessage: 'Запустить',
    },
    scriptFormBackButton: {
        id: 'messages.scriptFormBackButton',
        defaultMessage: 'Назад',
    },
    scriptFormDeclineButton: {
        id: 'messages.scriptFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    scriptMissingNodeIdError: {
        id: 'messages.scriptMissingNodeIdError',
        defaultMessage: 'Отсутсвует параметр nodeId в сигнатурре исполняемого скрипта',
    },
    missingRequiredParameter: {
        id: 'messages.missingRequiredParameter',
        defaultMessage: 'Введите значения для параметра',
    },
    loadFileButton: {
        id: 'scriptExecute.loadFileButton',
        defaultMessage: 'Загрузить файл',
    },
    missingParamFileMessage: {
        id: 'scriptExecute.missingParamFileMessage',
        defaultMessage: 'Файл не загружен',
    },
    loadedFileName: {
        id: 'scriptExecute.loadedFileName',
        defaultMessage: 'Загружен файл',
    },
    enterParametersValuesMessage: {
        id: 'scriptExecute.enterParametersValuesMessage',
        defaultMessage: 'Введите значения параметров скрипта:',
    },
    selectDateMessage: {
        id: 'scriptExecute.selectDateMessage',
        defaultMessage: 'Выберите дату и время',
    },
    scriptParam: {
        id: 'messages.scriptParam',
        defaultMessage: 'Параметр',
    },
    scriptParamValue: {
        id: 'messages.scriptParamValue',
        defaultMessage: 'Значение',
    },
    save: {
        id: 'messages.save',
        defaultMessage: 'Сохранить',
    },
});
