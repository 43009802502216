import { NotificationTemplateDTO } from '@/serverapi/api';
import { ApiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';

export class NotificationTemplatesDAOService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getAll(): Promise<NotificationTemplateDTO[]> {
        const api = this.getApi();
        const notificationTemplates: NotificationTemplateDTO[] = await api.notificationTemplate.getAll();

        return notificationTemplates;
    }

    public static async save(notificationTemplate?: NotificationTemplateDTO): Promise<NotificationTemplateDTO> {
        const api = this.getApi();
        const savedNotificationTemplate: NotificationTemplateDTO = await api.notificationTemplate.save({
            body: notificationTemplate,
        });

        return savedNotificationTemplate;
    }

    public static async create(notificationTemplate?: NotificationTemplateDTO): Promise<NotificationTemplateDTO> {
        const api = this.getApi();
        const createdNotificationTemplate: NotificationTemplateDTO = await api.notificationTemplate.create({
            body: notificationTemplate,
        });

        return createdNotificationTemplate;
    }

    public static async delete(notificationTemplateId: string): Promise<void> {
        const api = this.getApi();
        await api.notificationTemplate._delete({
            id: notificationTemplateId,
        });
    }
}
