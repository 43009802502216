import type { IWorkspaceDBAccessOwnersEditingTabItemParams, TWorkspaceTab } from '../../../../models/tab.types';
import type { TDBAccessParams, TAdminToolsParams } from '../../data/admintool.types';
import type { TSaveInfoOfDBAccessOwnersPayload } from '../../../../actions/databaseDialog.actions.types';
import type { TFetchUsersByGroupIdPayload } from '../../../../actions/users.actions.types';
import type { TRootState } from '../../../../reducers/root.reducer.types';
import type { TreeNode } from '../../../../models/tree.types';
import type {
    TGUPrivilegeEditingTabProps,
    TGUPrivilegeEditingTabActionProps,
} from '../../GroupsAndUsersPrivilegeEditingTab/GroupsAndUsersPrivilegeEditingTab.components.types';
import { connect } from 'react-redux';
import { workspaceRemoveTabRequest } from '../../../../actions/tabs.actions';
import { GroupsSelectors } from '../../../../selectors/groups.selectors';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { fetchAllGroupsRequest } from '../../../../actions/groups.actions';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { fetchUsersByGroupIdRequest } from '../../../../actions/users.actions';
import { adminToolSetParams } from '../../../../actions/admintools.actions';
import { withWindowResize } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import { TAdminToolsParamsKeys } from '../../data/admintool.types';
import { saveInfoOfDBAccessOwners } from '../../../../actions/databaseDialog.actions';
import { DBAccessSelectors } from '../../../../selectors/databaseAccess.selectors';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { TreeSelectors } from '../../../../selectors/tree.selectors';
import GroupsAndUsersPrivilegeEditingTabComponents from '../../GroupsAndUsersPrivilegeEditingTab/GroupsAndUsersPrivilegeEditingTab.components';

const mapStateToProps = (state: TRootState): Partial<TGUPrivilegeEditingTabProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const allGroups = GroupsSelectors.getAll(serverId)(state);
    const params: TDBAccessParams | undefined = MetaDataSelectors.getParams(TAdminToolsParamsKeys.DB_ACCESS)(state);
    const groupClicked = (params && params.groupId) || (allGroups && allGroups.length ? allGroups[0].id : undefined);
    const usersInGroup = groupClicked ? UsersSelectors.byGroupId(serverId, groupClicked)(state) : [];
    const nodeId = TabsSelectors.getActiveTabId(state);
    const tab = TabsSelectors.byId(nodeId)(state);
    const tabParam = tab?.params as IWorkspaceDBAccessOwnersEditingTabItemParams;
    const owners = tabParam?.nodeId && DBAccessSelectors.getOwnersByRepositoryId(tabParam?.nodeId)(state);
    const repository: TreeNode | undefined = tabParam?.nodeId && TreeSelectors.itemById(tabParam?.nodeId)(state);

    return {
        tab,
        allGroups,
        usersInGroup,
        serverId,
        groupClicked,
        owners,
        addonParamToSave: { nodeId: tabParam?.nodeId },
        toolBarParams: {
            type: 'dbAccess',
            payload: { repositoryName: repository ? repository.name : '' },
        },
    };
};

const mapDispatchToProps: (dispatch) => Partial<TGUPrivilegeEditingTabActionProps> = (dispatch) => ({
    onClose: (tab: TWorkspaceTab) => dispatch(workspaceRemoveTabRequest(tab)),
    fetchAllGroups: (serverId: string) => dispatch(fetchAllGroupsRequest(serverId)),
    groupItemClicked: (groupClicked: number) =>
        dispatch(adminToolSetParams({ dbAccess: { groupId: groupClicked } } as TAdminToolsParams)),
    fetchUsersByGroup: (payload: TFetchUsersByGroupIdPayload) => dispatch(fetchUsersByGroupIdRequest(payload)),
    onSaveChanges: (payload: TSaveInfoOfDBAccessOwnersPayload) => dispatch(saveInfoOfDBAccessOwners(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withWindowResize(GroupsAndUsersPrivilegeEditingTabComponents));
