import { TReducer } from '../../utils/types';
import { CLEAR_PRESET_REQUEST } from '../../actionsTypes/methodologySetting.actionTypes';
import { TPresetIconState } from './presetIcon.reducer.types';
import {
    PRESET_IMAGE_DELETE_REQUEST_SUCCESS,
    PRESET_IMAGE_REQUEST_SUCCESS,
} from '../../actionsTypes/presetSettings/presetImage.actionTypes';
import { PresetImage } from '../../serverapi/api';
import { APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM } from '../../actionsTypes/app.actionTypes';
import { cloneState } from '../utils/cloneState.utils';

export const INITIAL_PRESET_ICON_SERVER_STATE = {
    byPresetId: {},
};

const initial: TPresetIconState = {
    byServerId: {},
};

export const presetIconReducer: TReducer<TPresetIconState> = (state = initial, action) => {
    switch (action.type) {
        case APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM: {
            const {
                payload: { definition },
            } = action;

            if (!definition?.images) {
                return state;
            }
            const byId: { [id: string]: PresetImage } = {};
            const newState = { byServerId: { '': { byPresetId: { '': { byId } } } } };
            definition.images.forEach((et) => {
                byId[et.id] = et;
            });

            return { ...newState };
        }

        case PRESET_IMAGE_REQUEST_SUCCESS: {
            const {
                payload: { serverId, presetImages, presetId },
            } = action;
            
            const stateClone = cloneState(state, serverId, presetId);

            const { byId } = stateClone.byServerId[serverId].byPresetId[presetId];

            presetImages.forEach((presetImage) => {
                byId[presetImage.id] = presetImage;
            });

            return stateClone;
        }
        case PRESET_IMAGE_DELETE_REQUEST_SUCCESS: {
            const { presetImages, serverId } = action.payload;
            const stateClone = JSON.parse(JSON.stringify(state));
            presetImages?.forEach((icon) => {
                const byId =
                    (icon.presetId &&
                        stateClone.byServerId[serverId] &&
                        stateClone.byServerId[serverId].byPresetId[icon.presetId] &&
                        stateClone.byServerId[serverId].byPresetId[icon.presetId].byId) ||
                    {};
                if (icon.id) delete byId[icon.id];
            });

            return stateClone;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }
        default:
            return state;
    }
};
