import { Popover, Table } from 'antd';
import dayjs from 'dayjs';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteScheduledTask,
    editScheduledTask,
    loadScriptSchedule,
} from '../../../../actions/scriptScheduler.actions';
import { ScriptSchedulerSelectors } from '../../../../selectors/scriptScheduler.selectors';
import { ScriptParameter } from '../../../../serverapi/api';
import { ContentLoadingPageTab } from '../../../Workspace/components/ContentLoadingPageTab/ContentLoadingPageTab.component';
import theme from './ScriptScheduler.component.scss';
import messages from './ScriptScheduler.messages';
import { DeleteButton, EditButton, RowButtons } from '../../../AdminTools/Button/RowButtons';
import { TSchedulerTaskWithName } from '../../../../actions/scriptScheduler.actions.types';
import {
    DeleteSelected,
    TabHeader,
} from '../../../AdminTools/Methodology/components/Presets/Header/TabHeader.component';
import { TWorkspaceTab } from '../../../../models/tab.types';
import { dateTimeFormat } from '@/utils/date.time.utils';

type TScriptSchedulerProps = {
    tab: TWorkspaceTab;
};

type TScriptSchedulerContent = {
    serverId: string;
};

export const ScriptScheduler = (props: TScriptSchedulerProps) => {
    const content = props.tab.content as TScriptSchedulerContent;
    const { serverId } = content;

    const dispatch = useDispatch();
    const intl = useIntl();

    const [taskIdsForDelete, setTaskIdsForDelete] = useState<string[]>([]);

    useEffect(() => {
        dispatch(loadScriptSchedule(serverId));
    }, []);

    const taskList: TSchedulerTaskWithName[] = useSelector(
        ScriptSchedulerSelectors.getTaskListSortByTimeStart(serverId),
    );
    const isLoading = useSelector(ScriptSchedulerSelectors.getisLoading(serverId));

    const parametersColumns = [
        {
            width: 150,
            title: intl.formatMessage(messages.paramNameColumnName),
            dataIndex: 'name',
            align: 'center' as AlignType,
        },
        {
            width: 300,
            title: intl.formatMessage(messages.paramValueColumnName),
            dataIndex: 'value',
            align: 'center' as AlignType,
        },
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as AlignType,
            width: 200,
        },
        {
            title: intl.formatMessage(messages.columnNameScript),
            dataIndex: 'name',
            align: 'center' as AlignType,
            width: 200,
        },
        {
            width: 200,
            title: intl.formatMessage(messages.columnNameUser),
            dataIndex: 'login',
            align: 'center' as AlignType,
        },
        {
            width: 150,
            title: intl.formatMessage(messages.columnNameLastStart),
            dataIndex: 'lastStart',
            align: 'center' as AlignType,
            render: (lastStart: string | undefined) => {
                return lastStart ? dayjs(lastStart).format(dateTimeFormat) : '';
            },
        },
        {
            width: 200,
            title: intl.formatMessage(messages.columnNameLastStartStatus),
            dataIndex: 'lastStartStatus',
            align: 'center' as AlignType,
            render: (lastStartStatus: string | undefined) => {
                return lastStartStatus ? intl.formatMessage(messages[lastStartStatus]) : '';
            },
        },
        {
            width: 150,
            title: intl.formatMessage(messages.columnNameNextStart),
            dataIndex: 'nextStart',
            align: 'center' as AlignType,
            render: (nextStart: string | undefined) => {
                return nextStart ? dayjs(nextStart).format(dateTimeFormat) : '';
            },
        },
        {
            width: 80,
            title: intl.formatMessage(messages.columnNameEnable),
            dataIndex: 'disable',
            align: 'center' as AlignType,
            render: (disable: boolean | undefined) => {
                return !disable ? intl.formatMessage(messages.yes) : intl.formatMessage(messages.no);
            },
        },
        {
            width: 100,
            title: intl.formatMessage(messages.columnNameOption),
            dataIndex: 'parameters',
            align: 'center' as AlignType,
            render: (value: ScriptParameter[]) => {
                if (value.length) {
                    return (
                        <Popover
                            trigger="hover"
                            placement="left"
                            content={
                                <Table
                                    rowKey={(record: ScriptParameter) => `${record.name}`}
                                    columns={parametersColumns}
                                    dataSource={value}
                                    size="small"
                                    className={theme.table}
                                    bordered
                                    pagination={false}
                                />
                            }
                        >
                            <span className={theme.textaslink}>{intl.formatMessage(messages.parameters)}</span>
                        </Popover>
                    );
                }

                return intl.formatMessage(messages.noParameters);
            },
        },
        {
            title: intl.formatMessage(messages.columnNameActions),
            width: 100,
            align: 'center' as AlignType,
            render: (_, record: TSchedulerTaskWithName) => {
                return (
                    <RowButtons
                        buttons={[
                            EditButton.build((e) => {
                                e.stopPropagation();
                                dispatch(editScheduledTask({ serverId, taskId: record.id, scriptId: record.scriptId }));
                            }),
                            DeleteButton.build(
                                () => dispatch(deleteScheduledTask(serverId, [record.id])),
                                undefined,
                                undefined,
                                undefined,
                                intl.formatMessage(messages.delete),
                            ),
                        ]}
                    />
                );
            },
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows: TSchedulerTaskWithName[]) => {
            setTaskIdsForDelete(selectedRows.map((task) => task.id));
        },
        columnWidth: 32,
        selectedRowKeys: taskIdsForDelete,
    };

    const deleteCheckedTasks = () => {
        dispatch(deleteScheduledTask(serverId, taskIdsForDelete));
        setTaskIdsForDelete([]);
    };

    const onRow = (record: TSchedulerTaskWithName) => {
        return {
            onClick: () => {
                if (taskIdsForDelete.includes(record.id)) {
                    setTaskIdsForDelete(taskIdsForDelete.filter((taskId) => taskId !== record.id));
                } else {
                    setTaskIdsForDelete([...taskIdsForDelete, record.id]);
                }
            },
        };
    };

    return (
        <div className={theme.container}>
            <TabHeader
                buttons={[
                    DeleteSelected.build(
                        deleteCheckedTasks,
                        Boolean(!taskIdsForDelete.length),
                        undefined,
                        intl.formatMessage(messages.delete),
                    ),
                ]}
            />
            <Table
                className={theme.table}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                rowKey={(record: TSchedulerTaskWithName) => `${record.id}`}
                onRow={onRow}
                columns={columns}
                dataSource={taskList}
                size="small"
                loading={{
                    spinning: isLoading ?? true,
                    indicator: <ContentLoadingPageTab />,
                }}
                bordered
                pagination={false}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
            />
        </div>
    );
};
