import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { TScriptQueryParamsState } from '../reducers/scriptQueryParams.reducer.types';
import { ScriptParameter } from '../serverapi/api';

export const scriptQueryParamsStateSelector = (state: TRootState): TScriptQueryParamsState => state.scriptQueryParams;

export namespace ScriptQueryParamsSelector {
    export const getScriptQueryParams = (scriptParameters: ScriptParameter[]) =>
        createSelector<TRootState, TScriptQueryParamsState, TScriptQueryParamsState>(
            scriptQueryParamsStateSelector,
            (state) => {
                const scriptQueryParameterNames: string[] = scriptParameters
                    .filter((param) => param.queryType)
                    .map((param) => param.name)
                    .filter((name) => name) as string[];
                const ScriptQueryParams: TScriptQueryParamsState = {};
                for (const field in state) {
                    if (scriptQueryParameterNames.includes(field)) {
                        ScriptQueryParams[field] = state[field];
                    }
                }

                return ScriptQueryParams;
            },
        );
}
