import { workspaceAddTab } from '@/actions/tabs.actions';
import {
    DELETE_PUBLISHED_MODELS,
    OPEN_PUBLISHED_MODELS_TAB,
    UPDATE_PUBLISHED_MODELS,
} from '@/actionsTypes/publishedModels.actionTypes';
import { IWorkspaceTabServerSettingsParams, TWorkspaceTab } from '@/models/tab.types';
import { AdminToolTreeType } from '@/modules/AdminTools/data/admintool.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { Node, NodeId } from '@/serverapi/api';
import admintoolMessages from '../../../bpm-ui/src/modules/AdminTools/messages/admintool.messages';
import { LocalesService } from '@/services/LocalesService';
import { put, select, takeEvery } from 'redux-saga/effects';
import { EditorMode } from '@/models/editorMode';
import { defaultWorkspaceTabActions } from '@/models/tab';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { addPublishedModels } from '@/actions/publishedModels.actions';
import { fetchNodes } from '@/actions/nodes.actions';
import { TDeletePublishedModelsAction } from '@/actions/publishedModels.actions.types';

function* handlOpenPublishedModelsTab() {
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const serverId: string = yield select(ServerSelectors.serverId);
    const nodeId: NodeId = {
        id: WorkSpaceTabTypes.PUBLISHED_MODELS,
        repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
        serverId,
    };

    const publishedNodes: Node[] = yield TreeDaoService.getPublishedModelNodes(serverId);
    yield put(addPublishedModels(publishedNodes));
    yield put(fetchNodes(publishedNodes.map((node) => node.nodeId)));

    const approvalsTab: TWorkspaceTab = {
        title: intl.formatMessage(admintoolMessages[WorkSpaceTabTypes.PUBLISHED_MODELS]),
        nodeId,
        type: WorkSpaceTabTypes.PUBLISHED_MODELS,
        mode: EditorMode.Read,
        params: {} as IWorkspaceTabServerSettingsParams,
        actions: defaultWorkspaceTabActions,
    };

    yield put(workspaceAddTab(approvalsTab));
}

function* handlDeletePublishedModels(action: TDeletePublishedModelsAction) {
    const { publishedModelsNodeIds } = action.payload;
    yield TreeDaoService.deletePublications(publishedModelsNodeIds);
}

function* handleUpdatePublishedModels() {
    const serverId: string = yield select(ServerSelectors.serverId);
    const publishedNodes: Node[] = yield TreeDaoService.getPublishedModelNodes(serverId);
    yield put(addPublishedModels(publishedNodes));
    yield put(fetchNodes(publishedNodes.map((node) => node.nodeId)));
}

export function* publishedModelsSaga() {
    yield takeEvery(OPEN_PUBLISHED_MODELS_TAB, handlOpenPublishedModelsTab);
    yield takeEvery(DELETE_PUBLISHED_MODELS, handlDeletePublishedModels);
    yield takeEvery(UPDATE_PUBLISHED_MODELS, handleUpdatePublishedModels);
}
