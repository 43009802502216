import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { PRESET_SETTINGS_INITIAL_MATRIX_MODEL_TYPE_SERVER_STATE } from '@/reducers/presetSettings/presetSettingsMatrixModelType.reducer';

const matrixModelTypeStateSelector = (state: TRootState) => {
    return state.presetSettings.matrixModelTypes;
};

export namespace PresetSettingsMatrixModelTypeSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector(matrixModelTypeStateSelector, (state) => {
            return state?.byPresetId?.[presetId] || PRESET_SETTINGS_INITIAL_MATRIX_MODEL_TYPE_SERVER_STATE;
        });

    export const listByPresetId = (presetId: string) =>
        createSelector(byPresetId(presetId), (state) => Object.values(state.byId));
}
