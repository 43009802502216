import type { SelectInfo } from 'rc-menu/lib/interface';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';
import theme from './AttributesEditor.scss';
import { AttributeTypeSelector } from './AttributeTypeSelector/AttributeTypeSelector.component';
import messages from './AttributesEditor.messages';
import { AttributeRules } from './AttributeRules/AttributeRules.component';
import { AttributeStyleEditor } from './AttributeStyleEditor/AttributeStyleEditor.component';
import {
    floatingAttributesAddStyleAction,
    floatingAttributesSelectStyleAction,
} from '../../actions/FloatingAttributes.actions';
import { FloatingAttributesPanelSelectors } from '../../selectors/FloatingAttributes.selectors';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TFloatingAttributesSelectorProps = {
    disabled?: boolean;
};

export const AttributesEditor = ({ disabled }: TFloatingAttributesSelectorProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const availableAttributeTypes = useSelector(FloatingAttributesPanelSelectors.getAttributeTypes);
    const selectedAreaStyles = useSelector(FloatingAttributesPanelSelectors.getSelectedAreaStyles);
    const selectedStyle = useSelector(FloatingAttributesPanelSelectors.getSelectedStyle);
    const selectedStyleAttribute = useSelector(FloatingAttributesPanelSelectors.getSelectedStyleAttribute);

    const handleAddStyle = (): void => {
        dispatch(floatingAttributesAddStyleAction());
    };

    const handleSelectStyle = (selectInfo: SelectInfo): void => {
        dispatch(floatingAttributesSelectStyleAction(selectInfo.key));
    };

    return (
        <div className={theme.attributesSelector}>
            <div className={theme.leftSideBar}>
                {availableAttributeTypes.length ? (
                    <div className={selectedAreaStyles?.length ? theme.addAttribute : ''}>
                        <Button
                            size="large"
                            disabled={disabled}
                            onClick={handleAddStyle}
                            dataTest="attributes-around-object_add-attribute_btn"
                        >
                            {intl.formatMessage(messages.addAttribute)}
                        </Button>
                    </div>
                ) : null}
                <div className={theme.menu}>
                    <Menu mode="inline" onSelect={handleSelectStyle} selectedKeys={[selectedStyle?.id || '']}>
                        {selectedAreaStyles?.map((el) => el && <Menu.Item key={el.id}>{el.name}</Menu.Item>)}
                    </Menu>
                </div>
            </div>
            <div className={theme.centerBar}>
                {selectedStyle && (
                    <div key={selectedStyle.id} className={theme.attributeTab}>
                        <AttributeTypeSelector attribute={selectedStyleAttribute} disabled={disabled} />
                        <AttributeRules
                            attribute={selectedStyleAttribute}
                            selectedStyle={selectedStyle}
                            disabled={disabled}
                        />
                        <AttributeStyleEditor selectedStyle={selectedStyle} disabled={disabled} />
                    </div>
                )}
            </div>
        </div>
    );
};
