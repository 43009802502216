import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Input } from 'antd';
import { ChangeEvent, FC } from 'react';
import { SearchOutlined } from '@ant-design/icons';

type TSearchBarProps = {
    placeholder: string;
    disabled: boolean;
    value?: string;
    // tslint:disable-next-line:no-any
    onSearch: (value: string) => any;
} & JSX.IntrinsicAttributes;

const defaultProps: TSearchBarProps = {
    placeholder: '',
    disabled: false,
    onSearch: (f?) => f,
};

interface ISearchAdapterProps {
    readonly placeholder: string;
    readonly value?: string | number;
    readonly onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const SearchAdapter: FC<ISearchAdapterProps> = (props) => <Input suffix={<SearchOutlined />} {...props} />;

const SearchBar: FC<TSearchBarProps> = ({ value = '', placeholder, disabled, onSearch } = defaultProps) => (
    <DebounceInput
        value={value}
        element={SearchAdapter}
        placeholder={placeholder}
        disabled={disabled}
        debounceTimeout={300}
        forceNotifyByEnter
        onChange={({ target: { value: inputValue } }: ChangeEvent<HTMLInputElement>) => onSearch(inputValue.trim())}
    />
);

export default SearchBar;
