import { useRef } from 'react';

export const useFocus = <T extends { focus: Function } | null>(
    init: T | null,
): [React.MutableRefObject<T | null>, () => void] => {
    const htmlElRef = useRef<T>(init);

    const setFocus = () => {
        if (htmlElRef?.current) {
            htmlElRef.current.focus();
        }
    };

    return [htmlElRef, setFocus];
};
