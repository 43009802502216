import { defineMessages } from 'react-intl';

export default defineMessages({
    addLineToFrame: {
        id: 'EditorPopupMenu.addLineToFrame',
        defaultMessage: 'Добавить строку',
    },
    removeLineFromFrame: {
        id: 'EditorPopupMenu.removeLineFromFrame',
        defaultMessage: 'Удалить строку',
    },
    tableSymbolAddRow: {
        id: 'EditorPopupMenu.tableSymbolAddRow',
        defaultMessage: 'Добавить столбец',
    },
    tableSymbolRemoveRow: {
        id: 'EditorPopupMenu.tableSymbolRemoveRow',
        defaultMessage: 'Удалить столбец',
    },
    createElement: {
        id: 'EditorPopupMenu.createElement',
        defaultMessage: 'Создать',
    },
    typeEdge: {
        id: 'EditorPopupMenu.typeEdge',
        defaultMessage: 'Тип связи',
    },
    createExecutionSymbol: {
        id: 'EditorPopupMenu.createExecutionSymbol',
        defaultMessage: 'Символ исполнения',
    },
    createDestroySymbol: {
        id: 'EditorPopupMenu.createDestroySymbol',
        defaultMessage: 'Признак "Уничтожение"',
    },
    deleteDestroySymbol: {
        id: 'EditorPopupMenu.deleteDestroySymbol',
        defaultMessage: 'Удалить Признак "Уничтожение"',
    },
    setFixedSize: {
        id: 'EditorPopupMenu.setFixedSize',
        defaultMessage: 'Зафиксировать размер',
    },
    unsetFixedSize: {
        id: 'EditorPopupMenu.unsetFixedSize',
        defaultMessage: 'Снять фиксацию размера',
    },
    idefNode: {
        id: 'ComplexSymbol.idefNode',
        defaultMessage: 'УЗЕЛ',
    },
    idefTitle: {
        id: 'ComplexSymbol.idefTitle',
        defaultMessage: 'ЗАГОЛОВОК',
    },
    idefNumber: {
        id: 'ComplexSymbol.idefNumber',
        defaultMessage: 'НОМЕР',
    },
});
