// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tree__container___dWC3{width:100%;height:100%}.Tree__container___dWC3 *,.Tree__container___dWC3 *::before,.Tree__container___dWC3 *::after{border-radius:0}.Tree__list__v77BC{overflow-x:scroll !important;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;display:block;list-style:none}.Tree__list__v77BC li{display:block;list-style:none}.Tree__list__v77BC>div{display:grid;grid-template-columns:1fr;grid-template-rows:1fr;overflow:unset !important}.ant-dropdown{min-width:auto !important}", "",{"version":3,"sources":["webpack://./src/modules/Tree/components/Tree/Tree.scss"],"names":[],"mappings":"AAAA,wBACI,UAAA,CACA,WAAA,CAEA,6FACI,eAAA,CAGR,mBACI,4BAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,oBAAA,CAAA,gBAAA,CACA,aAAA,CACA,eAAA,CAEI,sBACI,aAAA,CACA,eAAA,CAGR,uBACI,YAAA,CACA,yBAAA,CACA,sBAAA,CACA,yBAAA,CAKJ,cACI,yBAAA","sourcesContent":[".container {\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    *, *::before, *::after {\r\n        border-radius: 0;\r\n    }\r\n}\r\n.list {\r\n    overflow-x: scroll !important;\r\n    user-select: none;\r\n    display: block;\r\n    list-style: none;\r\n    :global {\r\n        li {\r\n            display: block;\r\n            list-style: none;\r\n        }\r\n    }\r\n    > div {\r\n        display: grid;\r\n        grid-template-columns: 1fr;\r\n        grid-template-rows: 1fr;\r\n        overflow: unset !important;\r\n    }\r\n}\r\n\r\n:global {\r\n    .ant-dropdown {\r\n        min-width: auto !important;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Tree__container___dWC3",
	"list": "Tree__list__v77BC"
};
export default ___CSS_LOADER_EXPORT___;
