import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'RestoreDeletedNodeDialog.title',
        defaultMessage: 'Восстановление узла дерева',
    },
    restoreChildren: {
        id: 'RestoreDeletedNodeDialog.restoreChildren',
        defaultMessage: 'Восстановить дочерние элементы',
    },
    restoreParent: {
        id: 'RestoreDeletedNodeDialog.restoreParent',
        defaultMessage: 'Восстановить родительские элементы',
    },
    restoreModelElements: {
        id: 'RestoreDeletedNodeDialog.restoreModelElements',
        defaultMessage: 'Восстановить элементы моделей',
    },
    restoreOnlySameDeleteOperation: {
        id: 'RestoreDeletedNodeDialog.restoreOnlySameDeleteOperation',
        defaultMessage: 'Восстановить только элементы, удаленные в рамках одной операции',
    },
    chooseNewParent: {
        id: 'RestoreDeletedNodeDialog.chooseNewParent',
        defaultMessage: 'Выбрать нового родителя',
    },
    chooseNewParentDisabled: {
        id: 'RestoreDeletedNodeDialog.chooseNewParentDisabled',
        defaultMessage: 'Выбран режим восстановления родителей, назначить нового родителя нельзя',
    },
    restore: {
        id: 'RestoreDeletedNodeDialog.restore',
        defaultMessage: 'Восстановить',
    },
    cancel: {
        id: 'RestoreDeletedNodeDialog.cancel',
        defaultMessage: 'Отмена',
    },
});
