// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ObjectParametersSelector__container__fP1CQ{-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-right:5px}.ObjectParametersSelector__imageSizeContainer__I_nhB{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/Dialog/SymbolGenerator/ObjectParametersSelector.scss"],"names":[],"mappings":"AAAA,4CACI,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,gBAAA,CAGJ,qDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA","sourcesContent":[".container {\r\n    align-items: center;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-right: 5px;\r\n}\r\n\r\n.imageSizeContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ObjectParametersSelector__container__fP1CQ",
	"imageSizeContainer": "ObjectParametersSelector__imageSizeContainer__I_nhB"
};
export default ___CSS_LOADER_EXPORT___;
