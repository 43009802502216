import { defineMessages } from 'react-intl';

export default defineMessages({
    content: {
        id: 'Workspace.Content',
        defaultMessage: 'Контент',
    },

    schema: {
        id: 'Workspace.schema',
        defaultMessage: 'Схема',
    },
    unknownModel: {
        id: 'Workspace.unknown',
        defaultMessage: 'Неизвестная модель',
    },
});
