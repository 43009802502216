import { connect } from 'react-redux';
import { workspaceRemoveTabByNodeId } from '../../../../../actions/tabs.actions';
import { IWorkspaceTabItemEdgeTypeEditorParams, TWorkspaceTab } from '../../../../../models/tab.types';
import { TRootState } from '../../../../../reducers/root.reducer.types';
import { AttributeTypeSelectors } from '../../../../../selectors/attributeType.selectors';
import { EdgeTypeGroupSelectors } from '../../../../../selectors/edgeTypeGroup.selectors';
import { EdgeType } from '../../../../../serverapi/api';
import { withWindowResize } from '../../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import { EdgeTypeEditor } from '../../components/Presets/EdgeType/EdgeTypeEditor.component';
import {
    TEdgeTypeEditorActionProps,
    TEdgeTypeEditorFullProps,
} from '../../components/Presets/EdgeType/EdgeTypeEditor.types';
import { submitEdgeType } from '../../../../../actions/edgeType.actions';
import { getCurrentLocale } from '../../../../../selectors/locale.selectors';
import { BrowserDaoService } from '../../../../../services/dao/BrowserDaoService';

type TEdgeTypeEditorProps = {
    tab: TWorkspaceTab;
};

const mapStateToProps = (
    state: TRootState,
    props: Partial<TEdgeTypeEditorProps>,
): Partial<TEdgeTypeEditorFullProps> => {
    const params = props.tab?.params as IWorkspaceTabItemEdgeTypeEditorParams;
    const { serverNode, preset } = params;
    const availableAttributeTypes =
        (serverNode && preset && AttributeTypeSelectors.allInPreset(serverNode.nodeId.serverId, preset.id)(state)) ||
        [];
    const etg = EdgeTypeGroupSelectors.byPresetIdExcludeDeleted({
        serverId: serverNode?.nodeId?.serverId || '',
        presetId: preset?.id || '',
    })(state);
    const currentLocale = getCurrentLocale(state);

    return {
        ...params,
        availableAttributeTypes,
        edgeTypeGroups: etg,
        currentLocale,
    };
};

const mapDispatchToProps: (dispatch, ownProps: TEdgeTypeEditorProps) => TEdgeTypeEditorActionProps = (
    dispatch,
    ownProps: TEdgeTypeEditorProps,
) => ({
    exportStyle: (edgeType: EdgeType) => BrowserDaoService.downloadFile(new Blob([edgeType.edgeStyle]), {
        defaultPath: edgeType.name,
        filters: [{ name: 'Text file', extensions: ['txt'] }],
    }),
    onCancel: () => dispatch(workspaceRemoveTabByNodeId(ownProps.tab.nodeId)),
    onSubmit: (payload) => dispatch(submitEdgeType(payload)),
});

export const EdgeTypeEditorContainer = connect(mapStateToProps, mapDispatchToProps)(withWindowResize(EdgeTypeEditor));
