// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ApprovalTemplatesDialog__dialogContainer__Ulcie .ant-modal-body{overflow-y:hidden}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/ApprovalTemplates/ApprovalTemplatesDialog/ApprovalTemplatesDialog.scss"],"names":[],"mappings":"AAEQ,iEACI,iBAAA","sourcesContent":[".dialogContainer {\r\n    :global {\r\n        .ant-modal-body {\r\n            overflow-y: hidden;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": "ApprovalTemplatesDialog__dialogContainer__Ulcie"
};
export default ___CSS_LOADER_EXPORT___;
