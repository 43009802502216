import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { DialogFooterButtons } from '../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import messages from './CreateModelTemplateDialog.messages';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '@/config/config';
import { useDispatch } from 'react-redux';
import { InternationalString } from '@/serverapi/api';
import { Form } from 'antd';
import { MultiLangInputDialog } from '../MultiLangInputDialog/MultiLangInputDialog.component';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { closeDialog } from '@/actions/dialogs.actions';
import { createModelTemplate } from '@/actions/createModelTemplate.actions';

type TCreateModelTemplateDialogProps = {
    modelId: string;
    repositoryId: string;
};

export const CreateModelTemplateDialog: FC<TCreateModelTemplateDialogProps> = (props) => {
    const { modelId, repositoryId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [multilingualName, setMultilingualName] = useState<InternationalString>({});
    const [generalForm] = Form.useForm();

    const onOk = () => {
        generalForm
            .validateFields()
            .then(() => {
                dispatch(createModelTemplate({ modelId, repositoryId, name: multilingualName }));
                dispatch(closeDialog(DialogType.CREATE_MODEL_TEMPLATE_DIALOG));
            })
            .catch(() => undefined);
    };

    const onCancel = () => dispatch(closeDialog(DialogType.CREATE_MODEL_TEMPLATE_DIALOG));

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    dataTest: 'create-model-template-cancel',
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    dataTest: 'create-model-template-ok',
                    key: 'ok',
                    onClick: onOk,
                    value: intl.formatMessage(messages.confirm),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={onOk}
            onCancel={onCancel}
            title={intl.formatMessage(messages.title)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
        >
            <Form autoComplete='off' form={generalForm} layout="vertical">
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.modelTemplateNamePlaceholder)}
                    multiLangValue={multilingualName}
                    data-test="create-model-template-name-input"
                    onChange={setMultilingualName}
                    label={intl.formatMessage(messages.modelTemplateNameLabel)}
                    mainInputName="multilingualName"
                    generalForm={generalForm}
                    required
                    autoFocus
                />
            </Form>
        </Dialog>
    );
};

