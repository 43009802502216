import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'AcceptAgreement.cancel',
        defaultMessage: 'Отмена',
    },
    accept: {
        id: 'AcceptAgreement.accept',
        defaultMessage: 'Принять',
    },
    agreement: {
        id: 'AcceptAgreement.agreement',
        defaultMessage: 'Лицензионное соглашение',
    },
});
