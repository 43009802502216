import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { NodeId, NodeNotationReplacementDTO } from '../serverapi/api';
import { TChangeEntityTypeState } from '../reducers/changeEntityType.types';

const getState = (state: TRootState) => state.changeEntityType;

export const notationsByNodeId = (nodeId: NodeId) =>
    createSelector<TRootState, TChangeEntityTypeState, NodeNotationReplacementDTO[]>(getState, (state) =>
        state.get(nodeId) || [],
    );
