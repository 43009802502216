import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Col, Input, Row } from 'antd';
import { useSelector } from 'react-redux';
import { getDisplayName, sortUsers } from '@/utils/users.utils';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import { NotificationDTORecipientsTypeEnum, PrincipalDescriptor } from '@/serverapi/api';
import messages from './UsersSelect.messages';
import theme from './UsersSelect.scss';
import { SelectedUsersList } from './SelectedUsersList';
import classNames from 'classnames';

type TUsersSelectProps = {
    recipientType: NotificationDTORecipientsTypeEnum;
    selectedIds?: number[];
    emails?: string[];
    'data-test': string;
    onSelect: (ids: number[]) => void;
    onEmailsInput: (emailList: string[]) => void;
};

export const UsersSelect: FC<TUsersSelectProps> = (props) => {
    const { recipientType, selectedIds = [], emails = [], onSelect, onEmailsInput } = props;
    const intl = useIntl();

    const users: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getUsers);
    const selectedUsers = sortUsers(users).filter((user) => selectedIds.includes(user.id));

    const handleSelect = (ids: string[] = []) => {
        onSelect(ids.map(Number));
    };

    const handleRemovet = (removeId: number) => {
        onSelect(selectedIds.filter((id) => id !== removeId));
    };

    const userOptions = sortUsers(users.filter((user) => !user.blocked && user.principalType === 'USER')).map(
        (user) => {
            const label = getDisplayName(user);
            const checked = selectedIds.includes(user.id);

            return <Select.Option checked={checked} key={`${user.id}`} label={label} value={`${user.id}`} />;
        },
    );

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onEmailsInput(e.currentTarget.value.trim().split(';'));
    };

    return (
        <>
            {recipientType === 'EMAILS' && (
                <Col className={theme.col}>
                    <Row className={theme.inputTitle}>{intl.formatMessage(messages.emails)}</Row>
                    <Row className={theme.inputContainer}>
                        <Input.TextArea
                            value={emails.join(';')}
                            onChange={handleInput}
                            placeholder={intl.formatMessage(messages.emailsPlaceholder)}
                            autoSize
                        />
                    </Row>
                </Col>
            )}
            {recipientType === 'SELECTED' && (
                <Col className={classNames(theme.bottomBorder, theme.col)}>
                    <Select
                        label={intl.formatMessage(messages.users)}
                        onChange={handleSelect}
                        showSearch
                        isMultiSelect
                        placeholder={intl.formatMessage(messages.placeholder)}
                        selectTextClassName={theme.selectText}
                        dropdownClassName={theme.dropdown}
                        data-test={props['data-test']}
                    >
                        {userOptions}
                    </Select>
                    <SelectedUsersList
                        selectedUsers={selectedUsers}
                        onRemove={handleRemovet}
                        data-test={props['data-test']}
                    />
                </Col>
            )}
        </>
    );
};
