import { connect } from 'react-redux';
import { navigatorTabDeselect } from '../../../actions/navigator.actions';
import { changeSymbolViewMode, navigatorPropertiesChangeTabAction } from '../../../actions/navigatorProperties.actions';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../../../models/bpm/bpm-model-impl';
import { IKanbanNode, IModelNode } from '../../../models/bpm/bpm-model-impl.types';
import {
    TNavigatorPropertiesData,
    EntityEnum,
    PropertiesTabKey,
    SymbolViewEnum,
} from '../../../models/navigatorPropertiesSelectorState.types';
import {
    buildCommonProperties,
    buildPropertiesForEdgeDefinition,
    buildEdgeProperties,
    buildKanbanProperties,
    buildMatrixProperties,
    buildModelProperties,
    buildObjectProperties,
    buildScriptProperties,
    buildSpreadsheetProperties,
    buildWikiProperties,
} from '../../../models/properties/accessible-properties';
import { instancesBPMMxGraphMap } from '../../../mxgraph/bpm-mxgraph-instance-map';
import { TNavigatorTab } from '../../../reducers/navigator.reducer.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { AttributeTypeSelectors } from '../../../selectors/attributeType.selectors';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { ModelSelectors } from '../../../selectors/model.selectors';
import { ModelTypeSelectors } from '../../../selectors/modelType.selectors';
import { getActiveTab, getSelectedState } from '../../../selectors/navigatorProperties.selectors';
import { ObjectDefinitionSelectors } from '../../../selectors/objectDefinition.selectors';
import { ObjectTypeSelectors } from '../../../selectors/objectType.selectors';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import {
    AttributeType,
    EdgeInstance,
    NodeId,
    NodeDefinitionInstanceInfo,
    ParentModelOfObjectDefinition,
    ObjectType,
    FolderType,
    EdgeDefinitionNode,
    Symbol,
    ParentModelOfEdgeDefinition,
    EdgeType,
} from '../../../serverapi/api';
import { NavigatorProperties } from '../components/NavigatorProperties/NavigatorProperties.component';
import { TNavigatorPropertiesProps } from '../components/NavigatorProperties/NavigatorProperties.types';
import { openNode } from '../../../actions/openNode.actions';
import { TreeItemType } from '../../Tree/models/tree';
import { WikiSelectors } from '../../../selectors/entities/wiki.selectors';
import { MatrixSelectors } from '../../Matrix/selectors/matrix.selectors';
import { ScriptSelectors } from '../../../selectors/script.selectors';
import { UserProfileSelectors } from '../../../selectors/userProfile.selectors';
import messages from '../messages/Navigator.messages';
import { FolderTypeSelectors } from '../../../selectors/folderType.selectors';
import { SpreadsheetSelectors } from '../../../selectors/entities/spreadsheet.selectors';
import { TCurrentUserProfile } from '../../../reducers/userProfile.reducer.types';
import { PrincipalsSelectors } from '../../../selectors/principals.selectors';
import { LocalesService } from '../../../services/LocalesService';
import { EdgeDefinitionSelectors } from '../../../selectors/edgeDefinition.selector';
import { EdgeTypeSelectors } from '../../../selectors/edgeType.selectors';
import { SymbolSelectors } from '../../../selectors/symbol.selectors';
import { BPMMxGraph } from '../../../mxgraph/bpmgraph';
import { MxCell } from '../../../mxgraph/mxgraph';
import { KanbanSelectors } from '@/selectors/kanban.selectors';
import { isUndefined } from 'is-what';
import { TTreeEntityState } from '@/models/tree.types';
import { nodeSelectorByNodeId } from '@/selectors/nodes.selector';

const panelType = TNavigatorTab.Properties;

const hideValueIfNoAccess = (
    propData: TNavigatorPropertiesData | undefined,
    serverId: string,
    presetId: string,
    state: TRootState,
): TNavigatorPropertiesData | undefined => {
    const intl = LocalesService.useIntl();

    if (propData) {
        const newPropData = Object.keys(propData).reduce((summ: TNavigatorPropertiesData, key: string) => {
            const isAttributeTypeReadable = UserProfileSelectors.isAttributeTypeReadable(
                serverId,
                presetId,
                propData[key].descriptor.typeId,
            )(state);

            return {
                ...summ,
                [key]: {
                    value: isAttributeTypeReadable ? propData[key].value : intl.formatMessage(messages.noAccess),
                    descriptor: propData[key].descriptor,
                    isAttributeTypeReadable,
                },
            } as TNavigatorPropertiesData;
        }, {});

        return newPropData;
    }

    return propData;
};

const mapStateToProps = (state: TRootState): Partial<TNavigatorPropertiesProps> => {
    const locale = getCurrentLocale(state);
    const {
        currentGraphId,
        cellId,
        entityType,
        nodeId,
        symbolViewMode = SymbolViewEnum.ENTRY_COUNT,
    } = getSelectedState(state);
    const activeKey = getActiveTab(state);
    const presetId: string = TreeSelectors.presetById(currentGraphId || nodeId)(state);
    const attributeTypes: AttributeType[] = AttributeTypeSelectors.allInPreset(
        currentGraphId?.serverId || nodeId.serverId,
        presetId,
    )(state);
    const userProfile: TCurrentUserProfile | undefined = UserProfileSelectors.selectUserProfileByNodeId(nodeId)(state);
    const availableEdgeType = EdgeTypeSelectors.byPresetId({ serverId: nodeId.serverId, presetId })(state);
    const editorMode = TabsSelectors.getActiveTab(state)?.mode;
    let objectEntries: ParentModelOfObjectDefinition[] | undefined;
    let parentObjectsInfo: NodeDefinitionInstanceInfo[] | undefined;
    let objectDefinition: ObjectDefinitionImpl | undefined;
    let edgeDefinition: EdgeDefinitionNode | undefined;
    let edgeEntries: ParentModelOfEdgeDefinition[] | undefined;
    let propertiesData: TNavigatorPropertiesData | undefined;
    let edgeInstance: EdgeInstance | undefined;
    let objectInstance: ObjectInstanceImpl | undefined;
    let folderTypes: FolderType[] = [];
    let symbol: Symbol | undefined;
    let isModelTypeDeleted: boolean = false;

    if (entityType === EntityEnum.OBJECT) {
        objectDefinition = ObjectDefinitionSelectors.byId(nodeId)(state);
        objectEntries = objectDefinition?.objectEntries;
        const id = {
            objectTypeId: (objectDefinition && objectDefinition.objectTypeId) || '',
            presetId,
            serverId: nodeId.serverId,
        };
        const objectType: ObjectType | undefined = ObjectTypeSelectors.byId(id)(state);

        if (objectDefinition) {
            const isSymbolReadable = UserProfileSelectors.isSymbolReadable(nodeId, objectDefinition.idSymbol!)(state);
            if (isSymbolReadable) {
                let symbolName: string | undefined;

                if (currentGraphId && cellId) {
                    const graph = instancesBPMMxGraphMap.get(currentGraphId);
                    const { value } = graph?.getModel()?.getCell(cellId || '') || {};
                    objectInstance = value;
                    symbol = SymbolSelectors.byId(
                        { id: objectInstance?.symbolId || '', serverId: nodeId.serverId },
                        presetId,
                    )(state);
                    symbolName = symbol?.name;
                }
                propertiesData = buildObjectProperties(
                    objectDefinition,
                    locale,
                    objectType,
                    attributeTypes,
                    userProfile,
                    objectInstance,
                    symbolName,
                );
            }
        }
    } else if (entityType === EntityEnum.EDGE) {
        let graph: BPMMxGraph | undefined;
        let cell: MxCell | undefined;

        if (currentGraphId && cellId) {
            graph = instancesBPMMxGraphMap.get(currentGraphId);
            cell = graph?.getModel().getCell(cellId);
            edgeInstance = cell?.value;
            if (edgeInstance) {
                const edgeDefinitionId: NodeId = { ...currentGraphId, id: edgeInstance.edgeDefinitionId || '' };
                edgeDefinition = EdgeDefinitionSelectors.byId(edgeDefinitionId)(state);
            }
        } else {
            edgeDefinition = EdgeDefinitionSelectors.byId(nodeId)(state);
        }

        edgeEntries = edgeDefinition?.edgeEntries;

        const edgeTypeId = edgeDefinition?.edgeTypeId || edgeInstance?.edgeTypeId || '';
        const edgeType: EdgeType | undefined = EdgeTypeSelectors.byId({
            edgeTypeId,
            presetId,
            serverId: currentGraphId?.serverId || nodeId.serverId,
        })(state);

        const isEdgeTypeReadable = UserProfileSelectors.isEdgeTypeReadable(
            nodeId.serverId,
            presetId,
            edgeTypeId,
        )(state);

        if (isEdgeTypeReadable) {
            if (edgeDefinition) {
                const sourceObject =
                    edgeDefinition.sourceObjectDefinitionId !== undefined
                        ? nodeSelectorByNodeId({
                              ...nodeId,
                              id: edgeDefinition?.sourceObjectDefinitionId,
                          })(state)
                        : undefined;

                const targetObject =
                    edgeDefinition.targetObjectDefinitionId !== undefined
                        ? nodeSelectorByNodeId({
                              ...nodeId,
                              id: edgeDefinition?.targetObjectDefinitionId,
                          })(state)
                        : undefined;

                const edgeObjects = {
                    targetNode: targetObject,
                    sourceNode: sourceObject,
                };

                // имя типа связи
                const edgeDefinitionName = (availableEdgeType?.byId || {})[edgeTypeId]?.localizableName || '';
                propertiesData = buildPropertiesForEdgeDefinition(
                    { ...edgeDefinition, edgeType, edgeDefinitionName } as EdgeDefinitionNode,
                    locale,
                    attributeTypes,
                    userProfile,
                    edgeObjects,
                );
            } else if (edgeInstance && graph && cell) {
                propertiesData = buildEdgeProperties(graph, cell)(
                    { ...edgeInstance, edgeType },
                    locale,
                    attributeTypes,
                    userProfile,
                );
            }
        }
    } else if (entityType === EntityEnum.MODEL) {
        const model = ModelSelectors.byId(nodeId)(state) as IModelNode;
        if (model) {
            model.modelType = ModelTypeSelectors.byId(
                {
                    modelTypeId: model.modelTypeId!,
                    serverId: model.nodeId.serverId,
                },
                presetId,
            )(state);
            isModelTypeDeleted = isUndefined(model.modelType);
            parentObjectsInfo = model.parentNodesInfo;
            propertiesData = buildModelProperties(model, locale, attributeTypes, userProfile);
        }
    } else if (entityType === EntityEnum.KANBAN) {
        const kanban = KanbanSelectors.byId(nodeId)(state);
        const kanbanNode = kanban?.node as IKanbanNode;
        if (kanbanNode) {
            parentObjectsInfo = kanbanNode.parentNodesInfo;
            propertiesData = buildKanbanProperties(kanbanNode, locale, attributeTypes, userProfile);
        }
    } else if (entityType === EntityEnum.SCRIPT) {
        const scriptNode = ScriptSelectors.byId(nodeId)(state);
        propertiesData = scriptNode && buildScriptProperties(scriptNode, locale, attributeTypes, userProfile);
    } else if (entityType === EntityEnum.FOLDER || entityType === EntityEnum.DB) {
        const node: TTreeEntityState | undefined = TreeSelectors.itemById(nodeId)(state);
        const folderNode = node && { ...node };
        propertiesData = folderNode && buildCommonProperties(folderNode, locale, attributeTypes, userProfile);
        if (entityType === EntityEnum.FOLDER) {
            folderTypes = FolderTypeSelectors.listByPresetId({ serverId: nodeId.serverId, presetId })(state);
        }
    } else if (entityType === EntityEnum.WIKI) {
        const wikiNode = WikiSelectors.byId(nodeId)(state);
        if (wikiNode) {
            parentObjectsInfo = wikiNode.parentNodesInfo;
            propertiesData = buildWikiProperties(wikiNode, locale, attributeTypes, userProfile);
        }
    } else if (entityType === EntityEnum.MATRIX) {
        const matrixNode = MatrixSelectors.byId(nodeId)(state);
        if (matrixNode) {
            parentObjectsInfo = matrixNode.parentNodesInfo;
            propertiesData = buildMatrixProperties(matrixNode, locale, attributeTypes, userProfile);
        }
    } else if (entityType === EntityEnum.SPREADSHEET) {
        const spreadSheetNode = SpreadsheetSelectors.byId(nodeId)(state);
        if (spreadSheetNode) {
            parentObjectsInfo = spreadSheetNode.parentNodesInfo;
            propertiesData = buildSpreadsheetProperties(spreadSheetNode, locale, attributeTypes, userProfile);
        }
    } else {
        const node: TTreeEntityState | undefined = TreeSelectors.itemById(nodeId)(state);
        propertiesData = node && buildCommonProperties(node, locale, attributeTypes, userProfile);
    }

    if (entityType !== EntityEnum.FOLDER) {
        // Показывать поле "тип папки" только у папок
        delete propertiesData?.folderType;
    }

    propertiesData = hideValueIfNoAccess(propertiesData, nodeId.serverId, presetId, state);

    return {
        activeKey,
        modelAssignments: objectDefinition?.modelAssignments || edgeDefinition?.modelAssignments || [],
        propertiesData,
        graphId: currentGraphId,
        cellId,
        entityType,
        objectEntries,
        edgeEntries,
        hasEdgeDefinition: !!edgeDefinition,
        isEdgeInstance: !!edgeInstance,
        edgeInstanceAttributes: edgeInstance?.attributes,
        nodeId,
        editorMode,
        parentObjectsInfo,
        attributeTypes,
        folderTypes,
        principals: PrincipalsSelectors.getAll(state),
        symbolViewMode,
        isModelTypeDeleted,
    };
};

const mapDispatchToProps = (dispatch): Partial<TNavigatorPropertiesProps> => ({
    onClose: () => dispatch(navigatorTabDeselect(panelType)),
    onChangeTabs: (activeKey: PropertiesTabKey) => dispatch(navigatorPropertiesChangeTabAction(activeKey)),
    onModelLinkClicked: (nodeId: NodeId, type: TreeItemType, elementIds?: string[]) =>
        dispatch(openNode({ nodeId, type, elementIds })),
    onChangeSymbolViewMode: () => dispatch(changeSymbolViewMode()),
});

export const NavigatorPropertiesContainer = connect(mapStateToProps, mapDispatchToProps)(NavigatorProperties);
