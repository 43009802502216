enum eventGroups {
    anyEvents = 'anyEvents',
    userEvents = 'userEvents',
    nodeEvents = 'nodeEvents',
    nodeWithCommentsEvents = 'nodeWithCommentsEvents',
}

export const eventsList = {
    [eventGroups.anyEvents]: ['recipient_full_name', 'recipient_name', 'recipient_login'],
    [eventGroups.userEvents]: [
        'profile_user_full_name',
        'profile_user_name',
        'profile_user_login',
        'profile_user_password',
    ],
    [eventGroups.nodeEvents]: [
        'node_URL',
        'node_name',
        'node_type',
        'node_create_author',
        'node_create_date',
        'node_change_author',
        'node_change_date',
    ],
    [eventGroups.nodeWithCommentsEvents]: ['node_comment', 'node_comment_author', 'node_comment_date'],
};
