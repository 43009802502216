import React from 'react';
import { AttributeType, AttributeTypeSelectPropertyValue, AttributeValue, AttributeValueMultiSelect } from '../../../../../../serverapi/api';
import { LocalesService } from '../../../../../../services/LocalesService';
import theme from './StorageDefaultValueToComponent.scss';
import { getAttrbuteValue } from './util/attributeTypeEditorDialog.utils';
import { Select } from '../../../../../UIKit/components/Select/Select.component';

type TMultiTSelectComponent = {
    attributeType: AttributeType,
    label: string;
    onChangeDefaultValue: (attributeValue: AttributeValue | undefined) => void,
}

export const MultiSelectComponent = (props: TMultiTSelectComponent) => {
    const { attributeType, label, onChangeDefaultValue } = props;
    const values: AttributeTypeSelectPropertyValue[] | undefined = attributeType?.selectPropertyValues;
    const currentValueIds: string[] | undefined = (attributeType.attributeDefaultValue as AttributeValueMultiSelect)?.valueIds;
    const notEmptyValues: AttributeTypeSelectPropertyValue[] = values?.filter((value) => value.id) || [];

    return (
        <div className={theme.multiSelectContainer}>
            <Select
                data-test="properties-window_multi-select_attribute_value-select"
                label={label}
                onChange={(value) => {
                    onChangeDefaultValue({
                        ...getAttrbuteValue(attributeType),
                        valueIds: value,
                    } as AttributeValueMultiSelect);
                }}
                value={currentValueIds?.join(', ') || ''}
                isMultiSelect
                originalTheme
            >
                {notEmptyValues.map((v) => (
                    <Select.Option
                        data-test={`attribute-MULTI-SELECT_select-value_${LocalesService.internationalStringToString(
                            v.value,
                        )}`}
                        key={v.id}
                        value={v.id}
                        label={LocalesService.internationalStringToString(v.value) || v.id}
                        checked={currentValueIds?.includes(v.id)}
                    />
                ))}
            </Select>
        </div>
    );
};
