import { defineMessages } from 'react-intl';

export default defineMessages({
    formName: {
        id: 'WikiTableDialog.formName',
        defaultMessage: 'Задайте параметры таблицы',
    },
    formConfirmButton: {
        id: 'WikiTableDialog.formConfirmButton',
        defaultMessage: 'Создать',
    },
    formValidationMessageRequiredRow: {
        id: 'WikiTableDialog.formValidationMessageRequiredRow',
        defaultMessage: 'Задайте количество рядов',
    },
    formValidationMessageRequiredCol: {
        id: 'WikiTableDialog.formValidationMessageRequiredCol',
        defaultMessage: 'Задайте количество строк',
    },    
    formValidationMessageRange: {
        id: 'WikiTableDialog.formValidationMessageRange',
        defaultMessage: 'Задайте значение от 1 до 100',
    },        
    formDeclineButton: {
        id: 'WikiTableDialog.formDeclineButton',
        defaultMessage: 'Отмена',
    },
    tableRowCountLable: {
        id: 'WikiTableDialog.tableRowCountLable',
        defaultMessage: 'Количество строк', 
    },
    tableColCountLable: {
        id: 'WikiTableDialog.tableColCountLable',
        defaultMessage: 'Количество столбцов', 
    }        
});
