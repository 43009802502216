import { defineMessages } from 'react-intl';

export default defineMessages({
    warning: {
        id: 'warning',
        defaultMessage: 'Предупреждение',
    },
    continue: {
        id: 'continue',
        defaultMessage: 'Продолжить',
    },
    unsupportedBrowser: {
        id: 'unsupportedBrowser',
        defaultMessage:
            'Вы используете {name}. Для работы системы рекомендуется использовать один из следующих браузеров: Chrome, Chromium, Edge, Яндекс.Браузер',
    },
    oldBrowserVersion: {
        id: 'oldBrowserVersion',
        defaultMessage:
            'Вы используете {name} версии {version}. Для работы системы рекомендуется использовать {name} версии не ниже {minVersion}',
    },
});
