import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../../../messages/CommonToolbar.messages';
import { NodeId } from '../../../../../../serverapi/api';
import { MxCell } from '../../../../../../mxgraph/mxgraph';
import { objectPropertyView } from '../../../../../../actions/objectProperty.actions';
import { viewModelProperties } from '../../../../../../actions/modelProperty.actions';
import { ObjectPropertiesDialogActiveTab } from '../../../../../../models/objectPropertiesDialog';
import icProperty from '../../../../../../resources/icons/properties.svg';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton.component';
import { treeItemOpenPropertyAction } from '../../../../../../actions/tree.actions';
import { TreeItemType } from '../../../../../Tree/models/tree';

interface IPropertyButtonProps {
    tooltipTitle: string;
    selectedCells: MxCell[];
    tabNodeId: NodeId | undefined;
    disabled: boolean;
}

export const PropertyButton: FC<IPropertyButtonProps> = (props) => {
    const { tooltipTitle, selectedCells, tabNodeId, disabled } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const onOpenDialogAction = (workspaceId: NodeId) => {
        if (!workspaceId) {
            return;
        }

        if (selectedCells.length) {
            const cell = selectedCells[0];

            if (cell.isVertex() || cell.isEdge()) {
                dispatch(objectPropertyView(cell.id, ObjectPropertiesDialogActiveTab.NameAndAttributes));
            }

            if (cell.value?.type === 'shape' && cell.value?.imageId) {
                dispatch(treeItemOpenPropertyAction({ ...workspaceId, id: cell.value?.imageId }, TreeItemType.File));
            }
        } else {
            dispatch(viewModelProperties(workspaceId));
        }
    };

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icProperty}
            tooltipTitle={tooltipTitle}
            dataTest="graph-general-toolbar_property-button"
            onClick={() => tabNodeId && onOpenDialogAction(tabNodeId)}
            text={intl.formatMessage(messages.textProperty)}
        />
    );
};
