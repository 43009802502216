import { defineMessages } from 'react-intl';

export default defineMessages({
    serverAddress: {
        id: 'KeycloakSettings.serverAddress',
        defaultMessage: 'Адрес сервера',
    },
    missingRequiredParameter: {
        id: 'KeycloakSettings.missingRequiredParameter',
        defaultMessage: 'Введите значение параметра',
    },
    checkServer: {
        id: 'KeycloakSettings.checkServer',
        defaultMessage: 'Проверить сервер',
    },
    removeServer: {
        id: 'KeycloakSettings.removeServer',
        defaultMessage: 'Удалить сервер',
    },
    addServer: {
        id: 'KeycloakSettings.addServer',
        defaultMessage: 'Добавить сервер',
    },
    keycloakServerStatusOk: {
        id: 'KeycloakSettings.keycloakServerStatusOk',
        defaultMessage: 'Работает',
    },
    keycloakServerStatusNotOk: {
        id: 'KeycloakSettings.keycloakServerStatusNotOk',
        defaultMessage: 'Не работает',
    },
    keycloakServerCheckError: {
        id: 'KeycloakSettings.keycloakServerCheckError',
        defaultMessage: 'Ошибка при запросе',
    },
    keycloakServerChecking: {
        id: 'KeycloakSettings.keycloakServerChecking',
        defaultMessage: 'Проверка',
    },
});
