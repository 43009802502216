import { FIXED_STYLE_INDICATOR } from './symbols/ComplexSymbol.constants';
import { v4 as uuid } from 'uuid';

export const isComplexSymbolChangableStyle = (style: String) => style.startsWith(`${FIXED_STYLE_INDICATOR};`);

export const getGUIDdArray = (count: number) =>
    Array(count)
        .fill(' ')
        .map(() => uuid());

export const LABEL_ID_PREFIX = 'labelTo:';
export const MAIN_CELL_ID_LENGTH = 36;

export const generateLabelCellId = (mainCellId: string): string => `${LABEL_ID_PREFIX}${mainCellId}`;
