import { connect } from 'react-redux';
import { PasteMenuItem } from '../components/items/PasteMenuItem/PasteMenuItem.component';
import { TPasteMenuItemProps } from '../components/items/PasteMenuItem/PasteMenuItem.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { generalMenuClose, generalMenuCollapse, generalMenuExpand } from '../../../actions/generalMenu.actions';

const mapStateToProps = (state: TRootState, ownProps: Partial<TPasteMenuItemProps>): Partial<TPasteMenuItemProps> => {
    const activeScheme = TabsSelectors.getActiveTab(state);
    let toolbarComponent = '';
    if (activeScheme && activeScheme.type) {
        toolbarComponent = activeScheme.type;
    }

    return {
        toolbarComponent,
        itemKey: ownProps.itemKey,
        compact: state.generalMenu.isCompact,
        activeKey: TabsSelectors.getActiveTabId(state),
    };
};

const mapDispatchToProps = (dispatch): Partial<TPasteMenuItemProps> => ({
    onMenuExpandClick: () => {
        dispatch(generalMenuExpand());
    },
    onMenuCollapseClick: () => {
        dispatch(generalMenuCollapse());
    },
    onMenuCloseClick: () => {
        dispatch(generalMenuClose());
    },
});

export const PasteMenuItemContainer = connect(mapStateToProps, mapDispatchToProps)(PasteMenuItem);
