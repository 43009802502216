export const CLICK_DECOMPOSITION_ICON_EVENT: string = 'CLICK_DECOMPOSITION_ICON_EVENT';

export const DECOMPOSTION_ICON_TOOLTIP: string = 'Decomposition';

export const DELETED_DEFINITION_ICON_TOOLTIP: string = 'DELETED_DEFINITION_ICON_TOOLTIP';

export enum OverlayType {
    DECOMPOSITION = 'decomposition',
    DELETED_DEFINITION = 'deletedDefinition',
    FLOATING_ATTRIBUTES = 'floatingAttributes',
    UML_ATTRIBUTES = 'umlAttributes',
    UML_CLASS_ATTRIBUTES = 'umlClassAttributes',
}

export enum UmlAttributeHorizontolAlign {
    Left = 'left',
    Right = 'right',
}

export enum UmlAttributeVerticalAlign {
    Top = 'top',
    Bottom = 'bottom',
}

export enum UmlAttributeTextAlign {
    Left = 'left',
    Right = 'right',
    Center = 'center'
}
