import { TReducer } from '../../utils/types';
import { TNodeState } from './TNodeState';
import { TSpreadsheetState } from './spreadsheet.reducer.types';
import {
    SPREADSHEET_REMOVE_SUCCESS,
    SPREADSHEET_RENAME,
    SPREADSHEET_UPDATE,
    SPREADSHEET_UPDATE_AFTER_EDIT_IN_PROPERTY_TAB,
} from '../../actionsTypes/entities/spreadsheet.actionTypes';

const INITIAL_SPREADSHEET_STATE = new TNodeState() as TSpreadsheetState;

export const spreadsheetReducer: TReducer<TSpreadsheetState> = (state = INITIAL_SPREADSHEET_STATE, action) => {
    switch (action.type) {
        case SPREADSHEET_REMOVE_SUCCESS: {
            const {
                payload: { nodeId },
            } = action;

            return state.delete(nodeId);
        }
        case SPREADSHEET_RENAME: {
            const {
                payload: { nodeId, title, locale },
            } = action;
            const currentState = state.get(nodeId);
            if (!currentState) return state;

            const newState = {
                ...currentState,
                name: title,
                multilingualName: {
                    ...currentState.multilingualName,
                    [locale]: title,
                },
            };

            return state.set(nodeId, newState);
        }
        case SPREADSHEET_UPDATE: {
            const { payload } = action;

            return state.set(payload.nodeId, payload);
        }
        case SPREADSHEET_UPDATE_AFTER_EDIT_IN_PROPERTY_TAB: {
            const { payload } = action;
            const currentState = state.get(payload.nodeId);
            const newState = {
                ...currentState,
                ...payload,
            };

            return state.set(payload.nodeId, newState);
        }
        default:
            return state;
    }
};
