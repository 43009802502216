// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SymbolEditorHeader__headerInputs__YpgVP{display:-webkit-box;display:-ms-flexbox;display:flex;padding:16px;border-bottom-style:solid;border-width:1px;border-color:var(--Border-SecondaryNeutral200-UIKit);grid-gap:12px;gap:12px;-ms-flex-wrap:wrap;flex-wrap:wrap}.SymbolEditorHeader__inputId__cqeDT{height:100%}.SymbolEditorHeader__requiredInput__vU0co{width:240px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/SymbolEditorTab/components/SymbolEditorHeader/SymbolEditorHeader.scss"],"names":[],"mappings":"AAAA,yCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,YAAA,CACA,yBAAA,CACA,gBAAA,CACA,oDAAA,CACA,aAAA,CAAA,QAAA,CACA,kBAAA,CAAA,cAAA,CAGJ,oCACI,WAAA,CAGJ,0CACI,WAAA","sourcesContent":[".headerInputs {\r\n    display: flex;\r\n    padding: 16px;\r\n    border-bottom-style: solid;\r\n    border-width: 1px;\r\n    border-color: var(--Border-SecondaryNeutral200-UIKit);\r\n    gap: 12px;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.inputId {\r\n    height: 100%;\r\n}\r\n\r\n.requiredInput {\r\n    width: 240px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerInputs": "SymbolEditorHeader__headerInputs__YpgVP",
	"inputId": "SymbolEditorHeader__inputId__cqeDT",
	"requiredInput": "SymbolEditorHeader__requiredInput__vU0co"
};
export default ___CSS_LOADER_EXPORT___;
