import { defineMessages } from 'react-intl';

export default defineMessages({
    label: {
        id: 'MatrixRenameDialog.label',
        defaultMessage: 'Наименование',
    },
    placeholder: {
        id: 'MatrixRenameDialog.placeholder',
        defaultMessage: 'Введите новое наименование объекта',
    },
    submit: {
        id: 'MatrixRenameDialog.submit',
        defaultMessage: 'Переименовать',
    },
    cancel: {
        id: 'MatrixRenameDialog.cancel',
        defaultMessage: 'Отмена',
    },
    errorMessage: {
        id: 'MatrixRenameDialog.errorMessage',
        defaultMessage: 'Обязательно для заполнения',
    },
    excessLengthMessage: {
        id: 'MatrixRenameDialog.excessLengthMessage',
        defaultMessage: 'Допустимо не более 100 символов',
    },
});
