import { TWorkspaceTab } from '@/models/tab.types';
import { ISearchRuleWithValueId } from '@/modules/Search/AttributeFilter/AttributeFilter.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { SearchRule } from '@/serverapi/api';
import { v4 as uuid } from 'uuid';

export const isTabSearchable = (tab: TWorkspaceTab): boolean => {
    // Поиск по БД доступен только для элементов, которые могут быть открыты из БД
    const searchableTabTypes = [
        WorkSpaceTabTypes.EDITOR,
        WorkSpaceTabTypes.WIKI_EDITOR,
        WorkSpaceTabTypes.MARTIX_EDITOR,
        WorkSpaceTabTypes.SPREADSHEET,
        WorkSpaceTabTypes.KANBAN,
        WorkSpaceTabTypes.DASHBOARD,
        WorkSpaceTabTypes.SIMULATION_MODELING,
        WorkSpaceTabTypes.REPORT_EDITOR,
    ];

    return searchableTabTypes.includes(tab.type);
};

export const searchRuleWithValueIdToSearchRules = (searchRules: ISearchRuleWithValueId[]): SearchRule[] =>
    searchRules.map(({ attributeType, attributeTypeId, queryRule, values }) => ({
        attributeType,
        attributeTypeId,
        queryRule,
        values: values.map((val) => val.value).filter((value) => value !== ''),
    }));

export const searchRulesToSearchRuleWithValueId = (searchRules: SearchRule[]): ISearchRuleWithValueId[] =>
    searchRules.map(
        (searchRule) =>
            ({
                ...searchRule,
                values: searchRule.values.map((value) => ({ value, id: uuid() })),
            } as ISearchRuleWithValueId),
    );
