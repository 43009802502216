import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'ChoosePrincipalDialog.title',
        defaultMessage: 'Добавить пользователя/группу',
    },
    searchPlaceholder: {
        id: 'ChoosePrincipalDialog.searchPlaceholder',
        defaultMessage: 'Поиск по имени, названию группы',
    },
    columnName: {
        id: 'ChoosePrincipalDialog.columnName',
        defaultMessage: 'Имя',
    },
    addButton: {
        id: 'ChoosePrincipalDialog.addButton',
        defaultMessage: 'Добавить',
    },
    cancelButton: {
        id: 'ChoosePrincipalDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    emptyTable: {
        id: 'ChoosePrincipalDialog.emptyTable',
        defaultMessage: 'Нет данных',
    },
});
