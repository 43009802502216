import { MxCell, MxConstants, MxEvent } from '../mxgraph';
import { BPMMxGraph } from '../bpmgraph';
import { noop } from 'lodash-es';

export default class ConnectionMouseListener {
    currentCell: MxCell | null;
    graph: BPMMxGraph;
    mouseMove = noop;
    mouseDown = noop;
    mouseUp = noop;

    constructor(graph: BPMMxGraph) {
        this.graph = graph;
        this.graph.addListener(MxEvent.CHANGE, this.destroy);
        this.graph.connectionHandler.addListener(MxEvent.RESET, this.destroy);
        this.graph.connectionHandler.addListener(MxEvent.CONNECT, this.destroy);
        this.graph.connectionHandler.addListener(MxEvent.START, this.startConnection);
    }

    startConnection() {
        this.graph.container.style.cursor = MxConstants.CURSOR_BEND_HANDLE;
        this.graph.clearSelection();
    }

    destroy = () => {
        this.graph.container.style.cursor = MxConstants.CURSOR_LABEL_HANDLE;
    };
}
