import React from 'react';
import { Popover, Tooltip, Button } from 'antd';
import { NUIButton } from '../NUIButton/NUIButton.component';
import { Icon } from '../Icon/Icon.component';
import icDefaultIcon from '../../../../resources/icons/toolbar/controls/ic-table.svg';
import { TSpriteSymbol } from 'src/models/spriteSymbol.types';
import theme from './ToolbarButtons.scss';

const ToolbarButtonGroup = Button.Group;

type TTriggerButton = {
    spriteSymbol: TSpriteSymbol;
    onClick?: (value?: any) => void;
    disabled?: boolean;
    selected?: boolean;
    color?: string;
    className?: string;
    dataTest?: string;
    tooltip: string;
};

const TriggerButton = ({
    spriteSymbol,
    onClick,
    selected,
    disabled,
    color = 'currentColor',
    className,
    dataTest,
    tooltip,
}: TTriggerButton) => (
    <Tooltip title={tooltip} mouseLeaveDelay={0}>
        <span className={theme.triggerButton} data-test={`${dataTest || ''}`}>
            <NUIButton
                onClick={onClick}
                disabled={disabled}
                selected={selected}
                className={`${className || ''}`}
                style={{ color }}
            >
                <Icon spriteSymbol={spriteSymbol} />
            </NUIButton>
        </span>
    </Tooltip>
);

type TToolbarButton = TTriggerButton & {
    tooltip: string;
};

const ToolbarButton = ({
    tooltip,
    spriteSymbol = icDefaultIcon,
    onClick,
    disabled,
    selected,
    color,
    className,
    dataTest,
}: TToolbarButton) => {
    return (
        <TriggerButton
            onClick={onClick}
            spriteSymbol={spriteSymbol}
            disabled={disabled}
            selected={selected}
            color={color}
            className={className}
            dataTest={dataTest}
            tooltip={tooltip}
        />
    );
};

type TToolbarPopoverButton = TTriggerButton & {
    tooltip: string;
    children: any;
};

const ToolbarPopoverButton = ({
    tooltip,
    children,
    spriteSymbol = icDefaultIcon,
    onClick,
    disabled,
    selected,
    color,
    className,
    dataTest,
}: TToolbarPopoverButton) => {
    return (
        <Popover content={children} trigger="click" placement="bottom">
            <TriggerButton
                className={className}
                spriteSymbol={spriteSymbol}
                onClick={onClick}
                disabled={disabled}
                selected={selected}
                color={color}
                dataTest={dataTest}
                tooltip={tooltip}
            />
        </Popover>
    );
};

// TODO удалить все эти компоненты и экспортировать новые в UIKit
export { ToolbarButton, ToolbarPopoverButton, ToolbarButtonGroup };
