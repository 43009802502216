import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TRecentModel } from '../models/recentModel.types';
import { NodeId } from '../serverapi/api';

const getState = (state: TRootState) => state.recent;

export const getModels = createSelector(getState, (state) => state.models);

export const getRecentModel = ({ id, serverId, repositoryId }: NodeId) =>
    createSelector(
        getModels,
        (state) => state[serverId] && state[serverId][repositoryId] && state[serverId][repositoryId][id],
    );
type TypedData = {
    // todo: 1647 remove
    [repositoryId: string]: {
        [id: string]: TRecentModel;
    };
};

export const getRecentsByServerIds = (serverId: string) =>
    createSelector(getModels, (models) => {
        const serverModels: TypedData = models[serverId];
        if (serverModels) {
            const flatModels = Object.values(serverModels).reduce((res: TRecentModel[], models) => {
                return [...res, ...Object.values(models)];
            }, []);

            return flatModels;
        }

        return [];
    });
