import { defineMessages } from 'react-intl';

export default defineMessages({
    scriptFormName: {
        id: 'ScriptDialog.modelFormName',
        defaultMessage: 'Создать новый скрипт',
    },
    formStatusError: {
        id: 'ScriptDialog.formStatusError',
        defaultMessage: 'Ошибка',
    },
    scriptNameRequiredError: {
        id: 'ScriptDialog.scriptNameRequiredError',
        defaultMessage: 'Введите имя файла скрипта',
    },
    scriptFormConfirmButton: {
        id: 'ScriptDialog.scriptFormConfirmButton',
        defaultMessage: 'Создать',
    },
    scriptFormDeclineButton: {
        id: 'ScriptDialog.scriptFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    scriptNameLabel: {
        id: 'ScriptDialog.scriptNameLabel',
        defaultMessage: 'Имя скрипта',
    },
    scriptNamePlaceholder: {
        id: 'ScriptDialog.scriptNamePlaceholder',
        defaultMessage: 'Введите имя файла скрипта',
    },
    scriptLanguageLabel: {
        id: 'ScriptDialog.scriptLanguageLabel',
        defaultMessage: 'Язык выполнения скрипта',
    },
});
