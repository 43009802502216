import {
    PRESET_SETTINGS_ADD_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_CREATE_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_GET_ALL_REPORT_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_SUBMIT_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_REPORT_MODEL_TYPE,
} from '@/actionsTypes/presetSettings/presetSettingsReportModelTypes.actionTypes';
import {
    TAddReportModelTypeAction,
    TAddReportModelTypePayload,
    TCreateReportModelTypeAction,
    TCreateReportModelTypePayload,
    TDeleteReportModelTypeAction,
    TDeleteReportModelTypePayload,
    TEditReportModelTypeAction,
    TEditReportModelTypePayload,
    TGetAllReportModelTypesSuccessAction,
    TGetAllReportModelTypesSuccessPayload,
    TSubmitReportModelTypeAction,
    TSubmitReportModelTypePayload,
    TTransferReportModelTypeAction,
    TTransferReportModelTypePayload,
} from './presetSettingsReportModelType.actions.types';

export const createReportModelType = (payload: TCreateReportModelTypePayload): TCreateReportModelTypeAction => ({
    type: PRESET_SETTINGS_CREATE_REPORT_MODEL_TYPE,
    payload,
});

export const submitReportModelType = (payload: TSubmitReportModelTypePayload): TSubmitReportModelTypeAction => ({
    type: PRESET_SETTINGS_SUBMIT_REPORT_MODEL_TYPE,
    payload,
});

export const getAllReportModelTypesSuccess = (
    payload: TGetAllReportModelTypesSuccessPayload,
): TGetAllReportModelTypesSuccessAction => ({
    type: PRESET_SETTINGS_GET_ALL_REPORT_MODEL_TYPES_SUCCESS,
    payload,
});

export const addReportModelType = (payload: TAddReportModelTypePayload): TAddReportModelTypeAction => ({
    type: PRESET_SETTINGS_ADD_REPORT_MODEL_TYPE,
    payload,
});

export const deleteReportModelType = (payload: TDeleteReportModelTypePayload): TDeleteReportModelTypeAction => ({
    type: PRESET_SETTINGS_DELETE_REPORT_MODEL_TYPE,
    payload,
});

export const editReportModelType = (payload: TEditReportModelTypePayload): TEditReportModelTypeAction => ({
    type: PRESET_SETTINGS_EDIT_REPORT_MODEL_TYPE,
    payload,
});

export const transferReportModelType = (payload: TTransferReportModelTypePayload): TTransferReportModelTypeAction => ({
    type: PRESET_SETTINGS_TRANSFER_REPORT_MODEL_TYPE,
    payload,
});
