import { defineMessages } from 'react-intl';

export default defineMessages({
    deleteModelsDialogContent: {
        id: 'PublishedModels.deleteModelsDialogContent',
        defaultMessage: 'Будут удалены модели: {modelNames}',
    },
    deleteModelsQuestion: {
        id: 'PublishedModels.deleteModelsQuestion',
        defaultMessage: 'Удалить выбранные модели?',
    },
    delete: {
        id: 'PublishedModels.delete',
        defaultMessage: 'Удалить',
    },
    goToModel: {
        id: 'PublishedModels.goToModel',
        defaultMessage: 'Перейти к модели',
    },
    dbName: {
        id: 'PublishedModels.dbName',
        defaultMessage: 'Имя БД',
    },
    modelName: {
        id: 'PublishedModels.modelName',
        defaultMessage: 'Имя модели',
    },
    publishedBy: {
        id: 'PublishedModels.publishedBy',
        defaultMessage: 'Опубликована',
    },
    publishedAt: {
        id: 'PublishedModels.publishedAt',
        defaultMessage: 'Дата публикации',
    },
    update: {
        id: 'PublishedModels.update',
        defaultMessage: 'Обновить',
    },
});
