import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { MainMenuItems } from '../models/mainMenu';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';

const getState = (state: TRootState) => state.mainMenu;
const getTabState = (state: TRootState) => state.schemes;

export const getIsOpenedGeneralMenuFlag = createSelector(getState, (state) => state.isOpenedGeneralMenu);

export const getActiveSection = createSelector(getState, getTabState, (state, tabState) => {
    if (!tabState.activeId) {
        return MainMenuItems.GENERAL;
    }
    const tab = tabState.data.get(tabState.activeId);
    
    if (tab?.type === WorkSpaceTabTypes.EDITOR && tab?.mode === EditorMode.Edit) {
        return tab.activeMenu || MainMenuItems.GENERAL;
    }

    return MainMenuItems.GENERAL;
});
