import { defineMessages } from 'react-intl';

export default defineMessages({
    placeholder: {
        id: 'ImageSelector.placeholder',
        defaultMessage: 'Выберите иконку атрибута',
    },
    noImages: {
        id: 'ImageSelector.noImages',
        defaultMessage: 'Нет доступных иконок',
    }
});
