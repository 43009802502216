import { LocalesService } from "../../services/LocalesService";
import { AttributeValue } from '../../serverapi/api';
import { TValueTypeEnum } from '../ValueTypeEnum.types';
import { IPropertyDescriptor, TGetterFn, TPropertyValue, TSetterFn } from './property-descriptor.types';
import propertyMessages from './property.messages';

export const DefaultGetterFn =
    (key: string): TGetterFn =>
        (obj: {}) => {
            return {
                id: key,
                typeId: 'SYSTEM',
                valueType: 'STRING',
                value: obj[key]
            } as AttributeValue;
        };
            

export const DefaultSetterFn =
    (key: string, editable: boolean): TSetterFn =>
        (obj: {}, val: any) => {
            if (editable) {
                obj[key] = val;
            }
        }; // tslint:disable-line:no-any

export class TPropertyDescriptor implements IPropertyDescriptor {
    // eslint-disable-next-line no-useless-constructor
    constructor(
        readonly key: string,
        readonly title: string,
        readonly type: TValueTypeEnum,
        readonly editable: boolean,
        readonly system: boolean,
        readonly getter: TGetterFn = DefaultGetterFn(key),
        readonly setter: TSetterFn = DefaultSetterFn(key, editable),
        readonly dynamic: boolean = false,
        readonly typeId: string = '',
    ) {}

    buildNavigatorPropertyValue(obj: {} | undefined, locale: string): TPropertyValue {
        const value = this.getValue(obj, locale);

        return { descriptor: this, value };
    }

    getValue(obj: {} | undefined, locale: string): any {
        // tslint:disable-line:no-any
        if (obj) {
            return this.getter(obj, locale);
        }

        return undefined;
    }

    setValue(obj: {}, value: any) {
        // tslint:disable-line:no-any
        this.setter(obj, value);
    }

    isDefined(obj: {} | undefined, locale: string): boolean {
        if (obj) {
            return this.getter(obj, locale) !== undefined;
        }

        return false;
    }

    getTitle(): string {
        const intl = LocalesService.useIntl();

        return propertyMessages[this.title] ? intl.formatMessage(propertyMessages[this.title]) : this.title;
    }
}
