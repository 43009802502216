import { defineMessages } from 'react-intl';

export default defineMessages({
    save: {
        id: 'DbDialog.save',
        defaultMessage: 'Создать',
    },
    cancel: {
        id: 'DbDialog.cancel',
        defaultMessage: 'Отмена',
    },
    title: {
        id: 'DbDialog.title',
        defaultMessage: 'Новая база данных',
    },
    dbNamePlaceholder: {
        id: 'DbDialog.dbNamePlaceholder',
        defaultMessage: 'Имя новой базы данных',
    },
    dbNameLabel: {
        id: 'DbDialog.dbNameLabel',
        defaultMessage: 'Введите имя базы данных',
    },
    dbNameRequired: {
        id: 'DbDialog.nameRequired',
        defaultMessage: 'Название не указано',
    },
    internalError: {
        id: 'DbDialog.internalError',
        defaultMessage: 'Неизвестная ошибка.',
    },
    accessDeniedError: {
        id: 'DbDialog.accessDeniedError',
        defaultMessage: 'Недостаточно прав доступа',
    },
    creatingRepositoryError: {
        id: 'DbDialog.creatingRepositoryError',
        defaultMessage: 'Ошибка при создании репозитория: ',
    },
    preset: {
        id: 'DbDialog.preset',
        defaultMessage: 'Методология',
    },
    requiredField: {
        id: 'DbDialog.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
});
