import * as React from 'react';
import { ContentState } from 'draft-js';

type TTextAlignProps = {
    contentState: ContentState;
    children: string[];
    entityKey: string;
} & JSX.IntrinsicAttributes;

const TextAlignRenderer = ({ contentState, entityKey, children }: TTextAlignProps) => {
    const { align } = contentState.getEntity(entityKey).getData();

    return <div style={{ textAlign: align }}>{children}</div>;
};

export { TextAlignRenderer };
