import { AllowedScriptContext } from './../serverapi/api';
import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '../serverapi/api';
import { TScriptState } from '../reducers/entities/script.reducer.types';

const scriptStateSelector = (state: TRootState): TScriptState => state.script;

export namespace ScriptSelectors {
    export const byId = (scriptId: NodeId) => createSelector(scriptStateSelector, (state) => state.get(scriptId));

    export const context = (scriptId: NodeId) =>
        createSelector<TRootState, TScriptState, AllowedScriptContext>(
            scriptStateSelector,
            (state) => state.get(scriptId)?.allowedScriptContext || {},
        );
}
