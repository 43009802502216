import { TPropertyDescriptor } from './TPropertyDescriptor';
import { AttributeValue, AttributeType } from '../../serverapi/api';
import { compareStringIds } from '../../utils/nodeId.utils';
import { IPropertyDescriptor } from './property-descriptor.types';
import { UNKNOWN_ATTRIBUTE_TYPE } from '../../utils/consts';

const lookupAttributeValue = (obj: {}, id: string): AttributeValue | undefined => {
    const attributedObj = <{ attributes?: Array<AttributeValue> }>obj;
    const { attributes } = attributedObj;

    return attributes && attributes.find((av) => compareStringIds(av.id, id));
};

const attributeGetter = (id: string) => (obj: {}) => lookupAttributeValue(obj, id);
    
const attributeSetterByAttributeType =
    ({ id: typeId, valueType }: AttributeType) =>
        (id: string) =>
            (obj: {}, value: AttributeValue) => {
                const attrValue = lookupAttributeValue(obj, id);
                if (attrValue) {
                    Object.assign(attrValue, value);
                } else if (value !== undefined) {
                    if (!!typeId && !!valueType) {

                        const attributedObj = <{ attributes?: Array<AttributeValue> }>obj;
                        if (attributedObj.attributes) {
                            attributedObj.attributes.push(value);
                        } else {
                            attributedObj.attributes = [value];
                        }
                    }
                }
            };

const attributeSetter = (id: string) => (obj: {}, value: AttributeValue) => {
    const attrValue = lookupAttributeValue(obj, id);
    if (attrValue) {
        Object.assign(attrValue, value);
    }
};

export const buildPropDescriptorByAttribute = (
    attributeValue: AttributeValue,
    attributeType: AttributeType,
): IPropertyDescriptor => {
    const { id, valueType, typeId } = attributeValue;
    const typeName = attributeType.name;
    const title = typeName || `unknown-${id}`;

    return new TPropertyDescriptor(
        id,
        title,
        valueType,
        typeId !== UNKNOWN_ATTRIBUTE_TYPE.id,
        false,
        attributeGetter(id),
        attributeSetter(id),
        true,
        typeId,
    );
};

export const buildPropDescriptorByAttrType = (attributeType: AttributeType, id: string): IPropertyDescriptor => {
    const { valueType, name, id: typeId } = attributeType;
    const title = name || `unknown-${id}`;

    return new TPropertyDescriptor(
        id,
        title,
        valueType!,
        true,
        false,
        attributeGetter(id),
        attributeSetterByAttributeType(attributeType)(id),
        true,
        typeId,
    );
};
