import React from 'react';
import theme from './userProperty.scss';

type TUserProperyProps = {
    label: string;
    value: string;
};

export const UserProperty = (props: TUserProperyProps) => {
    const { label, value } = props;

    return (
        <div className={theme.outerContainer}>
            <div className={theme.innerContainer}>
                <span className={theme.labelContainer}>{label}</span>
                <span className={theme.valueContainer}>{value}</span>
            </div>
        </div>
    );
};
