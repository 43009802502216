import {
    CHANGE_SYMBOL_VIEW_MODE,
    NAVIGATOR_CLEAR_PROPERTIES,
    NAVIGATOR_PROPERTIES_CHANGE_OBJECT,
    NAVIGATOR_PROPERTIES_CHANGE_PROPERTY,
    NAVIGATOR_PROPERTIES_CHANGE_PROPERTY_SET,
    NAVIGATOR_PROPERTIES_CHANGE_TAB,
    NAVIGATOR_PROPERTIES_RENDER_PROP,
} from '../actionsTypes/navigatorProperties.actionTypes';
import { TNavigatorPropertiesSelectorState, PropertiesTabKey, TSelectedElement } from '../models/navigatorPropertiesSelectorState.types';
import {
    TNavigatorPropertiesChangeTabAction,
    TPayloadNavigatorPropertiesChangePropertyAction,
    TNavigatorPropertiesChangePropertyAction,
    TPayloadNavigatorPropertiesChangePropertySetAction,
    TNavigatorPropertiesRenderPropAction,
    TNavigatorPropertiesChangeObjectAction,
    TNavigatorClearPropertiesAction,
    TChangeSymbolViewMode,
} from './navigatorProperties.actions.types';

export const navigatorPropertiesChangePropertyAction = (
    payload: TPayloadNavigatorPropertiesChangePropertyAction,
): TNavigatorPropertiesChangePropertyAction => ({
    type: NAVIGATOR_PROPERTIES_CHANGE_PROPERTY,
    payload,
});

export const navigatorPropertiesChangePropertySetAction = (
    payload: TPayloadNavigatorPropertiesChangePropertySetAction,
) => ({
    type: NAVIGATOR_PROPERTIES_CHANGE_PROPERTY_SET,
    payload,
});

export const navigatorPropertiesChangeTabAction = (
    activeKey: PropertiesTabKey,
): TNavigatorPropertiesChangeTabAction => ({
    type: NAVIGATOR_PROPERTIES_CHANGE_TAB,
    payload: {
        activeKey,
    },
});

export const navigatorPropertiesRenderPropAction = (
    navigatorPropertiesSelectorState: TNavigatorPropertiesSelectorState,
): TNavigatorPropertiesRenderPropAction => ({
    type: NAVIGATOR_PROPERTIES_RENDER_PROP,
    payload: {
        navigatorPropertiesSelectorState,
    },
});

export const navigatorPropertiesChangeObjectAction = (
    navigatorPropertiesSelectorState: TSelectedElement,
): TNavigatorPropertiesChangeObjectAction => ({
    type: NAVIGATOR_PROPERTIES_CHANGE_OBJECT,
    payload: {
        navigatorPropertiesSelectorState,
    },
});

export const navigatorClearProperties = (): TNavigatorClearPropertiesAction => ({
    type: NAVIGATOR_CLEAR_PROPERTIES,
});

export const changeSymbolViewMode = (): TChangeSymbolViewMode => ({
    type: CHANGE_SYMBOL_VIEW_MODE,
});
