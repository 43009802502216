import React, { FC } from 'react';
import { Table } from 'antd';
import theme from './DashboardSymbols.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addWidget, selectWidget } from '@/actions/dashboard.actions';
import { NodeId } from '@/serverapi/api';
import { TDashboardSymbolType, TWidget, TWidgetGeometry } from '@/modules/Dashboard/Dashboard.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { v4 as uuid } from 'uuid';
import { symbols } from './DashboardSymbols';
import { Icon } from '@/modules/UIKit';
import { getDashboardSelectorKey } from '@/modules/Dashboard/dashboardUtils';

type TSymbolsList = { nodeId: NodeId };

const DashboardSymbolsList: FC<TSymbolsList> = (props) => {
    const { nodeId } = props;
    const dispatch = useDispatch();
    const cellSize = useSelector(DashboardSelector.cellSizeById(nodeId));
    const { horizontalCount } = useSelector(DashboardSelector.cellsCountById(nodeId));

    const onSymbolDrag = (symbol: TDashboardSymbolType, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        const newWidgetElement: HTMLDivElement = document.createElement('div');
        newWidgetElement.style.width = Math.ceil(horizontalCount / 4) * cellSize + 'px';
        newWidgetElement.style.height = Math.ceil(horizontalCount / 4) * cellSize + 'px';
        newWidgetElement.style.background = 'grey';
        newWidgetElement.style.color = 'black';
        newWidgetElement.style.position = 'absolute';
        newWidgetElement.style.zIndex = '1000';
        newWidgetElement.style.opacity = '0.5';
        newWidgetElement.style.cursor = 'pointer';
        newWidgetElement.innerHTML = symbol.name;
        document.body.append(newWidgetElement);

        moveAt(event.pageX, event.pageY);

        function moveAt(pageX, pageY) {
            newWidgetElement.style.left = pageX + 'px';
            newWidgetElement.style.top = pageY + 'px';
        }

        let currentDroppable: Element | null = null;
        let droppableBelow: Element | null = null;
        let canBeDropped: boolean = false;

        function onMouseMove(event) {
            if (canBeDropped && droppableBelow) {
                const rect = droppableBelow.getBoundingClientRect();
                let x = 0;
                let y = 0;
                if (rect) {
                    x = event.clientX - rect.left;
                    y = event.clientY - rect.top;
                }
                const tmpx = Math.floor(x / cellSize) * cellSize + rect.left;
                const tmpy = Math.floor(y / cellSize) * cellSize + rect.top;
                newWidgetElement.style.left = tmpx + 'px';
                newWidgetElement.style.top = tmpy + 'px';
            } else {
                moveAt(event.pageX, event.pageY);
            }

            newWidgetElement.hidden = true;
            let elemBelow = document.elementFromPoint(event.clientX, event.clientY);
            newWidgetElement.hidden = false;

            if (!elemBelow) return;

            droppableBelow = elemBelow.closest(`#${getDashboardSelectorKey(nodeId)}`);

            if (currentDroppable != droppableBelow) {
                if (currentDroppable) {
                    canBeDropped = false;
                }
                currentDroppable = droppableBelow;
                if (currentDroppable) {
                    canBeDropped = true;
                }
            }
        }

        document.addEventListener('mousemove', onMouseMove);

        const dropHandler = (event) => {
            if (canBeDropped && droppableBelow) {
                const rect = droppableBelow.getBoundingClientRect();
                let x = 0;
                let y = 0;
                if (rect) {
                    x = event.clientX - rect.left;
                    y = event.clientY - rect.top;
                }
                const geometry: TWidgetGeometry = {
                    x: Math.floor(x / cellSize),
                    y: Math.floor(y / cellSize),
                    height: Math.ceil(horizontalCount / 4),
                    width: Math.ceil(horizontalCount / 4),
                };
                const newWidget: TWidget = { id: uuid(), name: symbol.name, type: symbol.type, geometry };
                dispatch(addWidget(newWidget, nodeId));
                dispatch(selectWidget(nodeId, newWidget.id));
            }
        };

        const dropClearHandler = () => {
            newWidgetElement.remove();
            newWidgetElement.onmouseup = null;
            document.removeEventListener('mousemove', onMouseMove);
        };

        newWidgetElement.onmouseup = (event) => {
            dropHandler(event);
            if (canBeDropped && droppableBelow) {
                dropClearHandler();
            }
        };

        newWidgetElement.onmousedown = (event) => {
            dropHandler(event);
            dropClearHandler();
        };
    };

    return (
        <Table
            showHeader={false}
            pagination={false}
            rowKey={(symbol) => symbol.id}
            dataSource={symbols}
            columns={[
                {
                    dataIndex: 1,
                    render: (t, symbol) => (
                        <div
                            data-test="navigator-symbol_item"
                            onDragStart={() => false}
                            className={theme.symbolRow}
                            onMouseDown={(event) => onSymbolDrag(symbol, event)}
                        >
                            <Icon spriteSymbol={symbol.icon} />
                            {symbol.name}
                        </div>
                    ),
                },
            ]}
            className={theme.symbolTable}
        />
    );
};

export default DashboardSymbolsList;
