import { defineMessages } from 'react-intl';

export default defineMessages({
    variable: {
        id: 'AvailableEventsList.variable',
        defaultMessage: 'Переменная',
    },
    description: {
        id: 'AvailableEventsList.description',
        defaultMessage: 'Описание',
    },
    availableEventsList: {
        id: 'AvailableEventsList.availableEventsList',
        defaultMessage: 'Перечень доступных переменных',
    },
    anyEvents: {
        id: 'AvailableEventsList.anyEvents',
        defaultMessage: 'Для любых событий',
    },
    userEvents: {
        id: 'AvailableEventsList.userEvents',
        defaultMessage: 'Только для "События с пользователями"',
    },
    nodeEvents: {
        id: 'AvailableEventsList.nodeEvents',
        defaultMessage: 'Только для "События с узлами дерева"',
    },
    nodeWithCommentsEvents: {
        id: 'AvailableEventsList.nodeWithCommentsEvents',
        defaultMessage: 'Только для "События с узлами дерева (при событиях с комментариями)"',
    },
    recipient_full_name: {
        id: 'AvailableEventsList.recipient_full_name',
        defaultMessage: 'ФИО получателя писем',
    },
    recipient_name: {
        id: 'AvailableEventsList.recipient_name',
        defaultMessage: 'Имя получателя писем',
    },
    recipient_login: {
        id: 'AvailableEventsList.recipient_login',
        defaultMessage: 'Логин получателя писем',
    },
    profile_user_full_name: {
        id: 'AvailableEventsList.profile_user_full_name',
        defaultMessage: 'ФИО владельца профиля',
    },
    profile_user_name: {
        id: 'AvailableEventsList.profile_user_name',
        defaultMessage: 'Имя владельца профиля',
    },
    profile_user_login: {
        id: 'AvailableEventsList.profile_user_login',
        defaultMessage: 'Логин владельца профиля',
    },
    profile_user_password: {
        id: 'AvailableEventsList.profile_user_password',
        defaultMessage: 'Пароль владельца профиля',
    },
    node_URL: {
        id: 'AvailableEventsList.node_URL',
        defaultMessage: 'URL узла дерева',
    },
    node_name: {
        id: 'AvailableEventsList.node_name',
        defaultMessage: 'Название узла дерева',
    },
    node_type: {
        id: 'AvailableEventsList.node_type',
        defaultMessage: 'Тип узла дерева',
    },
    node_create_author: {
        id: 'AvailableEventsList.node_create_author',
        defaultMessage: 'Автор создания узла дерева',
    },
    node_create_date: {
        id: 'AvailableEventsList.node_create_date',
        defaultMessage: 'Дата и время создания узла дерева',
    },
    node_change_author: {
        id: 'AvailableEventsList.node_change_author',
        defaultMessage: 'Автор последнего изменения узла дерева',
    },
    node_change_date: {
        id: 'AvailableEventsList.node_change_date',
        defaultMessage: 'Дата и время последнего изменения узла дерева',
    },
    node_comment: {
        id: 'AvailableEventsList.node_comment',
        defaultMessage: 'Комментарий модели',
    },
    node_comment_author: {
        id: 'AvailableEventsList.node_comment_author',
        defaultMessage: 'Комментарий модели',
    },
    node_comment_date: {
        id: 'AvailableEventsList.node_comment_date',
        defaultMessage: 'Дата и время комментария модели',
    },
});
