import { TReducer } from '../utils/types';
import { TExternalSessionsState } from './externalSession.reducer.types';
import {
    CREATE_EXTERNAL_SESSION_SUCCESS,
    DELETE_EXTERNAL_SESSION_SUCCESS,
    GET_EXTERNAL_SESSIONS_REQUEST,
    GET_EXTERNAL_SESSIONS_SUCCESS,
} from '../actionsTypes/externalSession.actionTypes';

const initial: TExternalSessionsState = {
    loading: false,
    byServerId: {},
};

export const externalSessionsReducer: TReducer<TExternalSessionsState> = (state = initial, action) => {
    switch (action.type) {
        case GET_EXTERNAL_SESSIONS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_EXTERNAL_SESSIONS_SUCCESS: {
            const { externalSessions, serverId } = action.payload;
            const externalSessionsMap = externalSessions.reduce(
                (result: {}, item) => ({ ...result, [item.id]: item }),
                {},
            );

            return {
                ...state,
                loading: false,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...state.byServerId?.[serverId],
                        byId: {
                            ...state.byServerId?.[serverId]?.byId,
                            ...externalSessionsMap,
                        },
                    },
                },
            };
        }

        case CREATE_EXTERNAL_SESSION_SUCCESS: {
            const { externalSession, serverId } = action.payload;
            const byId = { ...state.byServerId?.[serverId]?.byId, [externalSession.id]: externalSession };

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...state.byServerId[serverId],
                        byId,
                    },
                },
            };
        }

        case DELETE_EXTERNAL_SESSION_SUCCESS: {
            const { externalSessionId, serverId } = action.payload;
            const byId = { ...state.byServerId?.[serverId]?.byId };
            delete byId[externalSessionId];

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...state.byServerId[serverId],
                        byId,
                    },
                },
            };
        }

        default:
            return state;
    }
};
