import { TReducer } from '../../utils/types';
import { MODEL_REQUEST_SUCCESS, CHANGE_MODEL_DATA } from '../../actionsTypes/model.actionTypes';
import { ModelNode } from '../../serverapi/api';
import { TNodeState } from './TNodeState';
import { APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM } from '../../actionsTypes/app.actionTypes';
import { TModelState } from './model.reducer.types';

const INITIAL_MODEL_STATE: TModelState = new TNodeState();

export const modelReducer: TReducer<TModelState> = (state = INITIAL_MODEL_STATE, action) => {
    switch (action.type) {
        case MODEL_REQUEST_SUCCESS: {
            const {
                payload: { model },
            } = action;

            return state.set(model.nodeId, model);
        }
        case CHANGE_MODEL_DATA: {
            const {
                payload: { model, nodeId },
            } = action;
            const existModel: ModelNode | undefined= state.byNodeId.get(nodeId);
            if (existModel) {
                return state.set(nodeId, { ...existModel, ...model });
            }
 
            return state.set(nodeId, { ...model, nodeId });
            
        }
        case APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM: {
            const {
                payload: { definition },
            } = action;
            if (definition?.model?.nodeId) {
                return state.set(definition.model.nodeId, definition.model);
            }
 
            return state;
            
        }
        default:
            return state;
    }
};
