// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MatrixSettingsDialog__tabs__q0JJu{padding-top:4px;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/Matrix/MatrixEditor/MatrixSettingsDialog/MatrixSettingsDialog.scss"],"names":[],"mappings":"AAAA,mCACI,eAAA,CACA,WAAA","sourcesContent":[".tabs {\r\n    padding-top: 4px;\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "MatrixSettingsDialog__tabs__q0JJu"
};
export default ___CSS_LOADER_EXPORT___;
