import { ApprovalTemplateDTO} from '@/serverapi/api';
import { ApiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';

export class ApprovalTemplateDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getApprovalTemplates(): Promise<Array<ApprovalTemplateDTO>> {
        const api = this.getApi();
        const approvalTemplates: ApprovalTemplateDTO[] = await api.approvalTemplates.getAll();

        return approvalTemplates;
    }

    public static async create(body: ApprovalTemplateDTO): Promise<ApprovalTemplateDTO> {
        const api = this.getApi();
        const approvalTemplates: ApprovalTemplateDTO = await api.approvalTemplates.create({body});

        return approvalTemplates;
    }

    public static async delete(approvalNodeId: string): Promise<void> {
        const api = this.getApi();
        await api.approvalTemplates.deleteById({ approvalId: approvalNodeId });
    }

    public static async save(body: ApprovalTemplateDTO): Promise<ApprovalTemplateDTO> {
        const api = this.getApi();
        const approvalTemplates: ApprovalTemplateDTO = await api.approvalTemplates.save({body});

        return approvalTemplates;
    }
}
