import React from 'react';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import theme from './CommentTooltip.scss';
import { Comment, NodeId } from '@/serverapi/api';
import messages from '../../messages/CommentsPanel.messages';
import { LocalesService } from '../../../../services/LocalesService';
import { CommentTooltipActions } from '../CommentTooltipAcrions/CommentTooltipActions.component';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import addFileIcon from '../../../../resources/icons/addFileIcon.svg';
import { useSelector } from 'react-redux';
import { CommentTooltipText } from '../CommentTooltipText/CommnetTooltipText.component';
import { CommentTooltipHeader } from '../CommentTooltipHeader/CommentTooltipHeader.component';
import { CommentCircle } from '../CommentCircle/CommentCircle.component';

type TCommentTooltip = {
    isHeadComment?: boolean;
    modelId: NodeId;
    commentId: string;
    isSmallFormat?: boolean;
    tooltip?: boolean;
    onClose?: () => void;
    onChangeEditMode?: (mode: boolean) => void;
};

export const CommentTooltip = (props: TCommentTooltip) => {
    const { isHeadComment, modelId, commentId, isSmallFormat, tooltip, onChangeEditMode, onClose } = props;
    const intl = LocalesService.useIntl();
    const comment: Comment | undefined = useSelector(CommentsSelectors.getCommentById(modelId, commentId));

    return (
        <div className={theme.container}>
            <div className={theme.headerContainer}>
                <CommentCircle modelId={modelId} commentId={commentId} withBorder={isSmallFormat} />
                <div className={theme.authorDateText}>
                    <CommentTooltipHeader modelId={modelId} commentId={commentId} isSmallFormat={!!isSmallFormat} />
                    {isSmallFormat && <CommentTooltipText isSmallFormat={!!isSmallFormat} text={comment?.text || ''} />}
                </div>

                {isHeadComment && comment && !isSmallFormat && onClose && onChangeEditMode && (
                    <div className={theme.actionsContainer}>
                        <CommentTooltipActions
                            modelId={modelId}
                            commentId={commentId}
                            onClose={onClose}
                            onChangeEditMode={onChangeEditMode}
                            tooltip={tooltip}
                        />
                    </div>
                )}
            </div>
            {!isSmallFormat && <CommentTooltipText isSmallFormat={!!isSmallFormat} text={comment?.text || ''} />}
            {!!comment?.commentFiles?.length && !isSmallFormat && (
                <div className={theme.commentFiles}>
                    <div className={theme.iconContainer}>
                        <Icon spriteSymbol={addFileIcon} />
                    </div>
                    <div>{`${comment.commentFiles.length} ${intl.formatMessage(messages.filesAttached)}`}</div>
                </div>
            )}
        </div>
    );
};
