import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import messages from '../messages/approvalTemplates.messages';
import { ApprovalTemplates } from '@/modules/AdminTools/ApprovalTemplates/ApprovalTemplates.components';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { LARGE_DIALOG_WIDTH } from '@/config/config';
import { closeDialog } from '@/actions/dialogs.actions';
import { createApproval } from '@/actions/approval.actions';
import { NodeId } from '@/serverapi/api';
import { useRecentData } from '../useRecentData';
import theme from './ApprovalTemplatesDialog.scss';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TSearchPathDialogProps = {
    intl: IntlShape;
    approvedItemNodeId: NodeId;
};

const localStorageKey = 'lastApprovalTemplateIds';

const ApprovalTemplatesDialog = ({ intl, approvedItemNodeId }: TSearchPathDialogProps) => {
    const dispatch = useDispatch();
    const [approvalTemplateId, setApprovalTemplateId] = useState<string>('');
    const [lastApprovalTemplateIds, setLastApprovalTemplateIds] = useRecentData(localStorageKey);

    const handleClose = () => {
        dispatch(closeDialog(DialogType.APPROVAL_TEMPLATE));
    };

    const setCheckedTemplateId = (id: string) => {
        setApprovalTemplateId(id);
    };

    const handleSelectTemplate = () => {
        setLastApprovalTemplateIds(approvalTemplateId);
        dispatch(createApproval({ approvalTemplateId, approvedItemNodeId }));
        dispatch(closeDialog(DialogType.APPROVAL_TEMPLATE));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleClose,
                    value: intl.formatMessage(messages.cancelButtonText),
                },
                {
                    key: 'ok',
                    onClick: handleSelectTemplate,
                    value: intl.formatMessage(messages.selectButtonText),
                    visualStyle: 'primary',
                    disabled: !approvalTemplateId,
                },
            ]}
        />
    );

    return (
        <Dialog
            width={LARGE_DIALOG_WIDTH}
            onCancel={handleClose}
            onOk={handleSelectTemplate}
            open
            title={intl.formatMessage(messages.header)}
            footer={footer}
            className={theme.dialogContainer}
        >
            <ApprovalTemplates
                dialogTemplates
                setCheckedTemplateId={setCheckedTemplateId}
                lastApprovalTemplateIds={lastApprovalTemplateIds}
            />
        </Dialog>
    );
};

const IntlComponent = injectIntl(ApprovalTemplatesDialog);

export { IntlComponent as ApprovalTemplatesDialog };
