import React, { FC, memo } from 'react';

export const GoToCommentIcon: FC = memo(() => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.40539 2.51519C9.11249 2.2223 8.63762 2.2223 8.34473 2.51519C8.05183 2.80809 8.05183 3.28296 8.34473 3.57585L10.0341 5.26519H8.84473C5.66909 5.26519 3.09473 7.83956 3.09473 11.0152V13.2652C3.09473 13.6794 3.43051 14.0152 3.84473 14.0152C4.25894 14.0152 4.59473 13.6794 4.59473 13.2652V11.0152C4.59473 8.66798 6.49752 6.76519 8.84473 6.76519H10.0341L8.34473 8.45453C8.05183 8.74743 8.05183 9.2223 8.34473 9.51519C8.63762 9.80809 9.11249 9.80809 9.40539 9.51519L12.1983 6.7223C12.5888 6.33178 12.5888 5.69861 12.1983 5.30809L9.40539 2.51519Z"
            fill="#8F8F8F"
        />
    </svg>
));
