import React, { FC } from 'react';
import { TChangedProperties } from '../../../../actions/changedProperties.types';
import { TNavigatorPropertiesData } from '../../../../models/navigatorPropertiesSelectorState.types';
import { EventDescriptorMacros, NodeId } from '../../../../serverapi/api';
import { ModelEventsList } from './ModelEventsList.component';
import { PropertySettings } from '../PropertySettings/PropertySettings.component';

type TModelSettingsProps = {
    nodeId: NodeId;
    changedProperties: TChangedProperties;
    propertiesData: TNavigatorPropertiesData;
    modelEvents: EventDescriptorMacros[];
    setModelEvents: (modelEvents: EventDescriptorMacros[]) => void;
    onChangeProperties: (changedProperties: TChangedProperties) => void;
    setAllowUnauthorizedAccessIsChecked: (value: boolean) => void;
    allowUnauthorizedAccessIsChecked: boolean;
    publishedBy: string | undefined;
    publishedAt: number | undefined;
    onCopyModelLink: (nodeId: NodeId) => void;
};

export const ModelSettings: FC<TModelSettingsProps> = (props) => {
    const {
        nodeId,
        changedProperties,
        propertiesData,
        modelEvents,
        setModelEvents,
        onChangeProperties,
        publishedBy,
        publishedAt,
        onCopyModelLink,
        setAllowUnauthorizedAccessIsChecked,
        allowUnauthorizedAccessIsChecked,
    } = props;

    return (
        <div>
            <PropertySettings
                changedProperties={changedProperties}
                propertiesData={propertiesData}
                onChangeProperties={onChangeProperties}
                nodeId={nodeId}
                publishedBy={publishedBy}
                publishedAt={publishedAt}
                onCopyModelLink={onCopyModelLink}
                isModel
                changeCheckedStatus={setAllowUnauthorizedAccessIsChecked}
                isChecked={allowUnauthorizedAccessIsChecked}
            />
            <ModelEventsList nodeId={nodeId} setModelEvents={setModelEvents} modelEvents={modelEvents} />
        </div>
    );
};
