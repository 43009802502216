import { isCommentCell } from '@/utils/bpm.mxgraph.utils';
import { BPMMxGraph } from '../bpmgraph';
import { MxCellHighlight } from '../mxgraph';

export class BPMMxCellHighlight extends MxCellHighlight {
    graph: BPMMxGraph;

    constructor(graph: BPMMxGraph, highlightColor?: string, strokeWidth?: number, dashed?: boolean) {
        super(graph, highlightColor, strokeWidth, dashed);
        this.keepOnTop = false;
    }

    drawHighlight() {
        if (this.state?.cell && isCommentCell(this.state.cell)) return;

        this.shape = this.createShape();
        if (this.shape?.stencil) this.shape.stencil = null;
        this.repaint();

        if (!this.keepOnTop && this.shape?.node?.parentNode.firstChild !== this.shape?.node) {
            this.shape!.node.parentNode.insertBefore(this.shape!.node, this.shape!.node.parentNode.firstChild);
        }
    }
}
