import React, { FC, memo } from 'react';

export const SeachIcon: FC = memo(() => (
    <svg width="13" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m 6.0336559,10.111046 c 2.2730339,0 4.1178151,-1.8447806 4.1178151,-4.1178145 0,-2.2730342 -1.8447812,-4.1178156 -4.1178151,-4.1178156 -2.2730342,0 -4.1178154,1.8447814 -4.1178154,4.1178156 0,2.2730339 1.8447812,4.1178145 4.1178154,4.1178145 z m 5.4904201,0 4.546983,4.546984 -1.372605,1.372605 -4.546983,-4.546983 V 10.754341 L 9.8943366,10.497207 c -1.0294541,0.900429 -2.402059,1.415613 -3.8606807,1.415613 -3.302488,0 -5.96259671,-2.6161855 -5.96259671,-5.9195885 0,-3.3034032 2.65919361,-5.96259689 5.96259671,-5.96259689 3.303403,0 5.9195871,2.65919369 5.9195871,5.96259689 0,1.4586215 -0.515183,2.8312266 -1.415612,3.8606807 l 0.257134,0.2571338 z"
            fill="#676767"
            fillRule="evenodd"
            clipRule="evenodd"
        />
    </svg>
));
