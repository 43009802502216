import { MxEdgeHandler } from '../mxgraph';
import { BPMMxGraph } from '../bpmgraph';
import { EditorMode } from '../../models/editorMode';

export class BPMMxEdgeHandler extends MxEdgeHandler {
    isHandleVisible() {
        const graph = this.graph as BPMMxGraph;

        return graph.mode !== EditorMode.Read;
    }

    createCustomHandles() {
        const handles = super.createCustomHandles();
        const graph = this.graph as BPMMxGraph;

        return graph.mode === EditorMode.Read ? [] : handles;
    }
}
