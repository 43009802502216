import React, { FC } from 'react';
import icRibbonClose from '../../../resources/icons/closeIcon.svg';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { Col, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { TWidget } from '../Dashboard.types';
import { selectWidget, setWidgetName } from '@/actions/dashboard.actions';
import { useIntl } from 'react-intl';
import messages from './WidgetSettingsPanel.messages';
import theme from './WidgetSettingsPanel.scss';
import { widgetComponents } from '../widgets/widgetsMapper';

export const WidgetSettingsPanel: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const currentWidget: TWidget | undefined = useSelector(DashboardSelector.selectedWidget(activeTab?.nodeId));

    const changeNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (activeTab && currentWidget)
            dispatch(setWidgetName(activeTab.nodeId, currentWidget.id, event.currentTarget.value));
    };

    const closePanelHandler = () => {
        if (activeTab) dispatch(selectWidget(activeTab.nodeId, ''));
    };

    const SourceInputComponent = currentWidget && widgetComponents[currentWidget.type].sourceInputComponent;

    return (
        <div className={theme.widgetSettingsPanelContainer} data-test="dashboard_widget-settings-panel">
            <Row className={theme.header}>
                <div className={theme.title}>{intl.formatMessage(messages.settings)}</div>
                <Icon className={theme.closeIcon} spriteSymbol={icRibbonClose} onClick={closePanelHandler} />
            </Row>
            <Col className={theme.body}>
                <Col className={theme.inputContainer}>
                    <div className={theme.label}>{intl.formatMessage(messages.name)}</div>
                    <Input
                        value={currentWidget?.name}
                        onChange={changeNameHandler}
                        className={theme.input}
                        data-test="dashboard_widget-settings-panel_input-title"
                    >
                    </Input>
                </Col>
                {SourceInputComponent && <SourceInputComponent />}
            </Col>
        </div>
    );
};
