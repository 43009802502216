import { put, select, takeEvery } from 'redux-saga/effects';
import { editorModeChangedAction } from '../actions/editor.actions';
import {
    TSpreadsheetEditorModeChangedAction,
    TSpreadsheetLoadByIdAction,
    TSpreadsheetLockAction,
    TSpreadsheetOpenByIdAction,
    TSpreadsheetSaveAction,
    TSpreadsheetSaveAndUnlockAction,
    TSpreadsheetSaveAndUnlockPayload,
    TSpreadsheetUnlockAction,
} from '../actions/entities/spreadsheet.actions.types';
import { showNotification } from '../actions/notification.actions';
import { workspaceAddTab } from '../actions/tabs.actions';
import {
    SPREADSHEET_EDITOR_MODE_CHANGED,
    SPREADSHEET_LOAD_BY_ID,
    SPREADSHEET_LOCK,
    SPREADSHEET_OPEN_BY_ID,
    SPREADSHEET_SAVE,
    SPREADSHEET_SAVE_AND_UNLOCK,
    SPREADSHEET_UNLOCK,
} from '../actionsTypes/entities/spreadsheet.actionTypes';
import { ISpreadsheetNode } from '../models/bpm/bpm-model-impl.types';
import { EditorMode } from '../models/editorMode';
import { TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { LockInfoDTO, SpreadsheetStyle, UserDTO } from '../serverapi/api';
import { getActiveModelContext } from './utils';
import { IModelContext } from './utils.types';
import { v4 as uuid } from 'uuid';
import { NotificationType } from '../models/notificationType';
import { IDiagramLockError } from '../models/notification/diagramLockError.types';
import { SpreadsheetDaoService } from '../services/dao/SpreadsheetDaoService';
import {
    spreadsheetRemoveSuccess,
    spreadsheetRename,
    spreadsheetSave,
    spreadsheetSaveAndUnlock,
    spreadsheetUnlock,
    spreadsheetUpdate,
} from '../actions/entities/spreadsheet.actions';
import { getUser } from '../selectors/authorization.selectors';
import { TREE_ITEM_TITLE_CHANGE_REQUEST } from '../actionsTypes/tree.actionTypes';
import { TTreeItemTitleChangeRequestAction } from '../actions/tree.actions.types';
import { TreeItemType } from '../modules/Tree/models/tree';
import { TreeSelectors } from '../selectors/tree.selectors';
import { TreeNode } from '../models/tree.types';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { Locale } from '../modules/Header/components/Header/header.types';
import { SpreadsheetSelectors } from '../selectors/entities/spreadsheet.selectors';
import { TWorkspaceTabsRemoveRequestAction } from '../actions/tabs.actions.types';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../actionsTypes/tabs.actionTypes';
import { recentAddModel } from '../actions/recent.actions';
import messages from '../modules/SpreadsheetDialog/SpreadsheetDialog.messages';
import { LocalesService } from '../services/LocalesService';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
import { instancesXSpreadsheetMap } from '../xspreadsheet/xspreadsheet-instances-map';
import Spreadsheet from 'x-data-spreadsheet';
import { getSpreadsheetStyle } from '../utils/spreadsheet.utils';

function* handleSpreadsheetLoadById(action: TSpreadsheetLoadByIdAction) {
    const {
        nodeId: { serverId, repositoryId, id },
    } = action.payload;

    const spreadsheet: ISpreadsheetNode = yield SpreadsheetDaoService.getSpreadsheetById(serverId, repositoryId, id);

    yield put(spreadsheetUpdate(spreadsheet));

    return spreadsheet;
}

function* handleSpreadsheetOpenByTreeNode(action: TSpreadsheetOpenByIdAction) {
    const {
        nodeId: { id, repositoryId, serverId },
    } = action.payload;

    try {
        const spreadsheet: ISpreadsheetNode = yield SpreadsheetDaoService.getSpreadsheetById(
            serverId,
            repositoryId,
            id,
        );

        yield put(spreadsheetUpdate(spreadsheet));
        const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
            title: spreadsheet.name,
            type: WorkSpaceTabTypes.SPREADSHEET,
            nodeId: action.payload.nodeId,
            content: spreadsheet,
        };
        yield put(workspaceAddTab(workspaceTab));
        yield put(
            recentAddModel({
                nodeId: action.payload.nodeId,
                type: TreeItemType.Spreadsheet,
                parentId: spreadsheet.parentNodeId || null,
                createdAt: new Date().toISOString(),
                title: spreadsheet.name,
                modelTypeId: WorkSpaceTabTypes.SPREADSHEET,
                modelTypeName: LocalesService.useIntl(yield select(getCurrentLocale)).formatMessage(
                    messages.spreadsheet,
                ),
                messageDescriptor: messages.spreadsheet,
            }),
        );
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

function* handleModeChanged({ payload: { mode } }: TSpreadsheetEditorModeChangedAction) {
    const activeModelContext: IModelContext = yield getActiveModelContext();

    if (activeModelContext?.schema?.type === WorkSpaceTabTypes.SPREADSHEET) {
        const { nodeId } = activeModelContext.schema;
        if (mode === EditorMode.Edit && activeModelContext.schema.mode !== EditorMode.Edit) {
            const lock: LockInfoDTO = yield SpreadsheetDaoService.unlockSpreadsheet(nodeId);
            if (lock?.locked) {
                yield put(
                    showNotification({
                        id: uuid(),
                        type: NotificationType.DIAGRAM_LOCK_ERROR,
                        data: {
                            lockOwner: lock.ownerName,
                        } as IDiagramLockError,
                    }),
                );

                return;
            }
        } else if (
            mode !== EditorMode.Edit &&
            (activeModelContext.schema.mode === EditorMode.Edit || activeModelContext.schema.mode === undefined)
        ) {
            yield SpreadsheetDaoService.lockSpreadsheet(nodeId);
        }
        const xSpreadSheet: Spreadsheet | undefined = instancesXSpreadsheetMap.get(nodeId);
        const spreadSheetStyle: SpreadsheetStyle = getSpreadsheetStyle(xSpreadSheet?.getData()[0]);
        const spreadsheet: ISpreadsheetNode = yield select(SpreadsheetSelectors.byId(nodeId));
        yield handleSpreadsheetSave(
            spreadsheetSave(nodeId.serverId, {
                body: {
                    ...spreadsheet,
                    style: spreadSheetStyle,
                },
            }),
        );
        yield put(editorModeChangedAction(mode));
    }
}

function* handleSpreadsheetSave(action: TSpreadsheetSaveAction) {
    const { serverId, requestBody } = action.payload;
    const user: UserDTO | undefined = yield select(getUser);

    const spreadsheet: ISpreadsheetNode = yield SpreadsheetDaoService.saveSpreadsheet(serverId, {
        body: {
            ...requestBody.body,
            updatedBy: user?.login,
        } as ISpreadsheetNode,
    });
    yield put(spreadsheetUpdate(spreadsheet));
}

function* handleSpreadsheetLock(action: TSpreadsheetLockAction) {
    const { nodeId } = action.payload;

    yield SpreadsheetDaoService.lockSpreadsheet(nodeId);
}

function* handleSpreadsheetUnlock(action: TSpreadsheetUnlockAction) {
    const { nodeId } = action.payload;

    yield SpreadsheetDaoService.unlockSpreadsheet(nodeId);
}

function* handleSpreadsheetSaveAndUnlock(action: TSpreadsheetSaveAndUnlockAction) {
    const { serverId, nodeId, saveRequestBody } = action.payload;
    yield handleSpreadsheetSave(spreadsheetSave(serverId, saveRequestBody));
    yield handleSpreadsheetUnlock(spreadsheetUnlock(nodeId));
}

function* handleSpreadsheetTitleChange(action: TTreeItemTitleChangeRequestAction) {
    const { nodeId, title } = action.payload;
    const treeNode: TreeNode | undefined = yield select(TreeSelectors.itemById(nodeId));

    if (!treeNode) return;

    if (treeNode.type === TreeItemType.Spreadsheet) {
        const locale: Locale = yield select(getCurrentLocale);

        yield put(spreadsheetRename({ nodeId, title, locale }));
    }
}

function* handleSpreadsheetClose(action: TWorkspaceTabsRemoveRequestAction) {
    const { workspaceTab } = action.payload;

    if (workspaceTab.type !== 'Spreadsheet') return;
    const xSpreadSheet: Spreadsheet | undefined = instancesXSpreadsheetMap.get(workspaceTab.nodeId);
    const spreadSheetStyle: SpreadsheetStyle = getSpreadsheetStyle(xSpreadSheet?.getData()[0]);
    const spreadsheet: ISpreadsheetNode = yield select(SpreadsheetSelectors.byId(workspaceTab.nodeId));
    const payload: TSpreadsheetSaveAndUnlockPayload = {
        serverId: workspaceTab.nodeId.serverId,
        nodeId: workspaceTab.nodeId,
        saveRequestBody: {
            body: {
                ...spreadsheet,
                style: spreadSheetStyle,
            },
        },
    };

    yield handleSpreadsheetSaveAndUnlock(spreadsheetSaveAndUnlock(payload));
    yield put(spreadsheetRemoveSuccess(workspaceTab.nodeId));
}

export function* spreadsheetSaga() {
    yield takeEvery(SPREADSHEET_OPEN_BY_ID, handleSpreadsheetOpenByTreeNode);
    yield takeEvery(SPREADSHEET_LOAD_BY_ID, handleSpreadsheetLoadById);
    yield takeEvery(SPREADSHEET_EDITOR_MODE_CHANGED, handleModeChanged);
    yield takeEvery(SPREADSHEET_SAVE, handleSpreadsheetSave);
    yield takeEvery(SPREADSHEET_LOCK, handleSpreadsheetLock);
    yield takeEvery(SPREADSHEET_UNLOCK, handleSpreadsheetUnlock);
    yield takeEvery(SPREADSHEET_SAVE_AND_UNLOCK, handleSpreadsheetSaveAndUnlock);
    yield takeEvery(TREE_ITEM_TITLE_CHANGE_REQUEST, handleSpreadsheetTitleChange);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleSpreadsheetClose);
}
