import { Checkbox, Form, Popover } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import {
    DEFAULT_MAIN_COLOR,
    DEFAULT_SECOND_COLOR,
    DEFAULT_SELECTED_ROW_COLOR,
    SystemPropertiesFormItemNames,
    TColorsPicker,
    TPropsWithFormRef,
} from '../../SystemProperties.types';
import { v4 as uuid } from 'uuid';
import { ColorResult, SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { getAppColor, getIsEnLocaleAllowed } from '../../../../../selectors/app.selector';
import { TAppColor } from '@/reducers/app.reducer.types';
import { GreetingText } from './GreetingText.component';
import { NotificationType } from '@/models/notificationType';
import { showNotification, showNotificationByType } from '@/actions/notification.actions';
import { RcFile, UploadRequestOption } from 'rc-upload/lib/interface';
import { checkFileSize } from '@/utils/formatBytes.utils';
import { UserLogoUpload } from './UserLogoUpload.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { isSvgImage } from '@/utils/image.utils';

export const InterFaceSettings = (props: TPropsWithFormRef) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { formRef } = props;
    const appColor: TAppColor = useSelector(getAppColor);
    const isEnLocaleAllowed: boolean = useSelector(getIsEnLocaleAllowed);
    const [colorsPicker, setColorsPicker] = useState<TColorsPicker>({
        mainColor: appColor.mainColor || DEFAULT_MAIN_COLOR,
        secondColor: appColor.secondColor || DEFAULT_SECOND_COLOR,
        selectedRowColor: appColor.selectedRowColor || DEFAULT_SELECTED_ROW_COLOR,
    });

    const updateGreetingLogo = (value: string | Blob | RcFile) => {
        formRef.current?.setFieldValue(SystemPropertiesFormItemNames.greetingLogo, value);
    };

    const handleChangeColorPicker = (colors: TColorsPicker) => {
        const newColors = { ...colorsPicker };
        Object.keys(colors).forEach((colorType) => {
            newColors[colorType] = colors[colorType];
            formRef.current?.setFieldValue(colorType, colors[colorType]);
        });
        setColorsPicker(newColors);
    };

    const isOpenNavigator = useSelector(SystemPropertiesSelectors.getIsOpenNavigator);
    const isCompactMainMenu = useSelector(SystemPropertiesSelectors.getIsCompactMainMenu);
    const isOpenMainMenu = useSelector(SystemPropertiesSelectors.getIsOpenMainMenu);
    const greetingLogo: string | undefined = useSelector(SystemPropertiesSelectors.getIsGreetingLogo);
    const { mainColor, secondColor, selectedRowColor } = colorsPicker;
    const [logo, setLogo] = useState<string | undefined>(greetingLogo);

    const onDropFile = (fileBase64: string) => {
        setLogo(fileBase64);
    };

    const beforeUpload = (file: RcFile) => {
        const isImage: boolean = isSvgImage(file);

        if (checkFileSize(file)) {
            dispatch(
                showNotification({
                    id: uuid(),
                    type: NotificationType.MAX_SIZE_FOR_FILE,
                    data: { message: `${intl.formatMessage(messages.fileMaxSize)}` },
                }),
            );

            return false;
        }
        if (!isImage) {
            dispatch(showNotificationByType(NotificationType.NOT_SVG_IMAGE_ERROR));
        }

        return isImage;
    };

    const onDeleteFile = () => {
        updateGreetingLogo('');
        setLogo(undefined);
    };

    const dropFile = (e: UploadRequestOption) => {
        if (e.file) {
            updateGreetingLogo(e.file);
            const reader: FileReader = new FileReader();
            reader.readAsDataURL(e.file as File);
            reader.onloadend = () => {
                onDropFile(reader.result as string);
            };
        }
    };

    const getValueFromEvent = (e) => {
        if (!e || !e.target || !e.target.files || e.target.files.length === 0) {
            return '';
        }

        const file: File = e.target.files[0];

        if (!isSvgImage(file)) {
            return '';
        }

        return file;
    };

    return (
        <div className={theme.formTab}>
            <div className={theme.formItemWrapper}>
                <div className={theme.wrapperColum}>
                    <Form.Item
                        initialValue={isOpenNavigator}
                        name={SystemPropertiesFormItemNames.isOpenNavigator}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isOpenNavigator)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                    <Form.Item
                        initialValue={isCompactMainMenu}
                        name={SystemPropertiesFormItemNames.isCompactMainMenu}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isCompactMainMenu)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                    <Form.Item
                        initialValue={isOpenMainMenu}
                        name={SystemPropertiesFormItemNames.isOpenMainMenu}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isOpenMainMenu)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                    <Form.Item
                        initialValue={isEnLocaleAllowed}
                        name={SystemPropertiesFormItemNames.isEnLocaleAllowed}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isEnLocaleAllowed)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                </div>
                <div className={theme.wrapperColor}>
                    <div className={theme.wrapperColum}>
                        <Form.Item
                            initialValue={mainColor}
                            name={SystemPropertiesFormItemNames.mainColor}
                            className={theme.formItemCheckBox}
                            label={intl.formatMessage(messages.mainColor)}
                        >
                            <Popover
                                content={
                                    <SketchPicker
                                        color={mainColor}
                                        onChange={(color: ColorResult) =>
                                            handleChangeColorPicker({ mainColor: color.hex as string })
                                        }
                                        disableAlpha
                                    />
                                }
                                trigger="click"
                            >
                                <div className={theme.changeColorBlock} style={{ backgroundColor: `${mainColor}` }} />
                            </Popover>
                        </Form.Item>
                        <Form.Item
                            initialValue={secondColor}
                            name={SystemPropertiesFormItemNames.secondColor}
                            className={theme.formItemCheckBox}
                            label={intl.formatMessage(messages.secondColor)}
                        >
                            <Popover
                                content={
                                    <SketchPicker
                                        color={secondColor}
                                        onChange={(color: ColorResult) =>
                                            handleChangeColorPicker({ secondColor: color.hex as string })
                                        }
                                        disableAlpha
                                    />
                                }
                                trigger="click"
                            >
                                <div className={theme.changeColorBlock} style={{ backgroundColor: `${secondColor}` }} />
                            </Popover>
                        </Form.Item>
                        <Form.Item
                            initialValue={selectedRowColor}
                            name={SystemPropertiesFormItemNames.selectedRowColor}
                            className={theme.formItemCheckBox}
                            label={intl.formatMessage(messages.selectedRowColor)}
                        >
                            <Popover
                                content={
                                    <SketchPicker
                                        color={selectedRowColor}
                                        onChange={(color: ColorResult) =>
                                            handleChangeColorPicker({ selectedRowColor: color.hex as string })
                                        }
                                        disableAlpha
                                    />
                                }
                                trigger="click"
                            >
                                <div
                                    className={theme.changeColorBlock}
                                    style={{ backgroundColor: `${selectedRowColor}` }}
                                />
                            </Popover>
                        </Form.Item>
                    </div>
                    <Button
                        onClick={() =>
                            handleChangeColorPicker({
                                mainColor: DEFAULT_MAIN_COLOR,
                                secondColor: DEFAULT_SECOND_COLOR,
                                selectedRowColor: DEFAULT_SELECTED_ROW_COLOR,
                            })
                        }
                    >
                        {intl.formatMessage(messages.resetToDefaultColors)}
                    </Button>
                </div>
            </div>
            <GreetingText formRef={formRef} />
            <UserLogoUpload
                logo={logo}
                greetingLogo={greetingLogo}
                getValueFromEvent={getValueFromEvent}
                onDeleteFile={onDeleteFile}
                beforeUpload={beforeUpload}
                dropFile={dropFile}
            />
        </div>
    );
};
