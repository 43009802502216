import { RawDraftEntity } from 'draft-js';

type TLinkBlockType = {
    type: string;
    href: string;
    title: string;
};

const linkEntity = {
    LINK_CUSTOM: {
        open: () => '[',
        close: (entity: RawDraftEntity) => `](${entity.data.url || entity.data.href})`,
    },
    SUBSCRIPT: {
        open: () => '~',
        close: () => `~`,
    },
    LINK: {},
};

linkEntity.LINK = linkEntity.LINK_CUSTOM;

const linkBlock = {
    link_open: (item: TLinkBlockType) =>
        <RawDraftEntity>{
            type: 'LINK_CUSTOM',
            mutability: 'MUTABLE',
            data: {
                url: item.href,
                href: item.href,
            },
        },
};

export { linkEntity, linkBlock };
