import React from 'react';
import messages from '../../messages/ImportAris.messages';
import { useIntl } from 'react-intl';
import style from './FinishStep.scss';
import { CheckOutlined } from '@ant-design/icons';

const FinishStep = () => {
    const intl = useIntl();

    return (
        <div className={style.container}>
            <div className={style.title}>{intl.formatMessage(messages.importSuccess)}</div>
            <CheckOutlined className={style.icon} />
        </div>
    );
};

export default FinishStep;
