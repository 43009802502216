import { connect } from 'react-redux';
import { TPreset } from '../../../../../../../models/preset.types';
import { TreeNode } from '../../../../../../../models/tree.types';
import { closeDialog } from '../../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../../DialogRoot/DialogRoot.constants';
import { FileUploadDialog } from '../../../../../../FileUpload/components/FileUploadDialog.component';
import { Symbol } from '../../../../../../../serverapi/api';
import { uploadSymbolSVGRequest } from '../../../../../../../actions/symbol/symbolEditor.actions';
import { TFileUploadDialogProps } from '../../../../../../FileUpload/types/FileUploadDialog.types';

type TContainerOwnProps = {
    preset: TPreset;
    serverNode: TreeNode;
    symbol: Symbol;
};

const mapDispatchToProps = (dispatch, properties: TContainerOwnProps): Partial<TFileUploadDialogProps> => ({
    onClose: () => dispatch(closeDialog(DialogType.UPLOAD_SYMBOL_SVG_DIALOG)),
    onSubmit: (file: File) => {
        file.text().then(() => {
            dispatch(closeDialog(DialogType.UPLOAD_SYMBOL_SVG_DIALOG));
            dispatch(
                uploadSymbolSVGRequest({
                    serverNode: properties.serverNode,
                    file,
                    preset: properties.preset,
                    symbol: properties.symbol,
                }),
            );
        });
    },
});

export const UploadSymbolSvgDialogContainer = connect(null, mapDispatchToProps)(FileUploadDialog);
