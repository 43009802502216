import React from 'react';
import { Tooltip } from 'antd';

import { TObjectPathProps } from './ObjectPath.types';

import theme from './ObjectPath.scss';

const maxVisiblePathLength = 140;

export const ObjectPath = ({ path, onClick }: TObjectPathProps) => {
    const isOverflow = path.length > maxVisiblePathLength;
    const visibleText = isOverflow ? `${path.slice(0, maxVisiblePathLength - 1)}...` : path;
    const tooltipText = isOverflow ? path : undefined;

    return (
        <Tooltip title={tooltipText}>
            <span onClick={onClick} className={theme.objectPath}>
                {visibleText}
            </span>
        </Tooltip>
    );
};
