import type { MxCell } from 'MxGraph';
import { ComplexSymbol } from '../ComplexSymbol.class';
import { getIsTitleHidden } from './sideEffects';

export default abstract class SwimLaneSymbol extends ComplexSymbol {
    public addToGraph() {
        const { isNewObjectDefinition } = this.customProps || {};

        const root = isNewObjectDefinition ? this.insert() : this.restore();

        return this.afterCreate(root);
    }

    protected isTitleHidden(): boolean {
        return getIsTitleHidden(this.rootCellValue?.symbolId, this.graph.modelType?.symbols);
    }

    abstract insert(): MxCell;
    abstract restore(): MxCell;

    public get isConnectable(): boolean {
        return true;
    }
}
