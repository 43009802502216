import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    NodeId,
    PermissionModel,
    PermissionModelPermissionEnum,
    PrincipalDescriptor,
    PrincipalPermissions,
} from '../../../../serverapi/api';
import { allPermissionsClearData, allPermissionsRequest } from '../../../../actions/allPermissions.actions';
import {
    getAllPermissionsError,
    getAllPermissionsIsLoading,
    getAllPermissionsWithLeastOneGranting,
} from '../../../../selectors/allPermissions.selectors';
import { Alert, Spin } from 'antd';
import { useIntl } from 'react-intl';
import msg from '../../messages/InstancePermissionTable.messages';
import PrincipalLabel from '../PrincipalLabel/PrincipalLabel.component';
import theme from './AllPermissionsTable.scss';
import messages from '../../messages/InstancePermissionsDialog.messages';
import { getFilteredData } from './PermissionTablesUtils';
import { ResultPermissions } from './PermissionTables.types';
import { TColumn, TTableData } from '../../../UIKit/components/Table/TableUIKit.types';
import { TableUIKit } from '../../../UIKit/components/Table/TableUIKit.component';
import { Select } from '../../../UIKit/components/Select/Select.component';
import { SearchInput } from '../../../UIKit/components/Select/SearchInput.component';
import icOk from '../../../../resources/icons/ic-ok.svg';
import icCross from '../../../../resources/icons/ic-cross.svg';
import { Icon } from '@/modules/UIKit';

type TAllPermissionsTableProps = {
    nodeId: NodeId;
    principals: Array<PrincipalDescriptor>;
};

type TPreparedDataForTable = PrincipalPermissions & {
    principal: PrincipalDescriptor | undefined;
};

export const AllPermissionsResultTable = ({ nodeId, principals }: TAllPermissionsTableProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState<string>('');
    const [filterType, setFilterType] = useState<ResultPermissions>(ResultPermissions.allUsers);
    const isLoading: boolean = useSelector(getAllPermissionsIsLoading);
    const allPermissions: PrincipalPermissions[] = useSelector(getAllPermissionsWithLeastOneGranting);
    const error: string | null = useSelector(getAllPermissionsError);
    const errorMsg: string | null = error === '' ? intl.formatMessage(messages.fetchError) : error;

    useEffect(() => {
        dispatch(allPermissionsRequest(nodeId));

        return () => {
            dispatch(allPermissionsClearData());
        };
    }, []);

    if (isLoading) {
        return (
            <div className={theme.spinnerContainer}>
                <Spin />
            </div>
        );
    }

    const principalIds: number[] = principals.map((p) => p.id);

    const preparedDataForTable: TPreparedDataForTable[] = allPermissions
        .filter((permission) => (permission.principalId ? principalIds.includes(permission.principalId) : false))
        .map((permission) => {
            const principal: PrincipalDescriptor | undefined = principals.find((el) => {
                return el.id === permission.principalId;
            });

            return { ...permission, principal };
        });

    const usersColumnRenderer = (principalId: number | undefined) => {
        const user: TPreparedDataForTable | undefined = preparedDataForTable.find(
            (el) => el.principalId === principalId,
        );

        if (!user || !user.principal) return null;

        return <PrincipalLabel {...user.principal} />;
    };

    const permissionColumnRenderer = (
        permissions: Array<PermissionModel> | undefined,
        dataKey: PermissionModelPermissionEnum,
    ) => {
        const isGranting: boolean = !!permissions?.find((el) => el.permission === dataKey)?.isGranting;

        return (
            <div data-test-id={`additional-rights_checkbox-status_${isGranting ? 'positive' : 'negative'}`}>
                <Icon
                    spriteSymbol={isGranting ? icOk : icCross}
                    dataTest={`additional-rights_table_column_${dataKey}`}
                />
            </div>
        );
    };

    function filterHandler(e: ChangeEvent<HTMLInputElement>) {
        setFilter(e.target.value.toLowerCase());
    }

    const filteredPermissions: PrincipalPermissions[] = getFilteredData(
        filter,
        preparedDataForTable,
        principals,
        filterType,
    );
    const permissions: PrincipalPermissions[] = filteredPermissions || preparedDataForTable;

    const columns: TColumn[] = [
        {
            title: intl.formatMessage(msg.authority),
            dataKey: 'authority',
            withoutSorter: true,
            width: 340,
        },
        {
            title: intl.formatMessage(msg.create),
            dataKey: 'create',
            withoutSorter: true,
        },
        {
            title: intl.formatMessage(msg.read),
            dataKey: 'read',
            withoutSorter: true,
        },
        {
            title: intl.formatMessage(msg.write),
            dataKey: 'update',
            withoutSorter: true,
        },
        {
            title: intl.formatMessage(msg.delete),
            dataKey: 'delete',
            withoutSorter: true,
        },
        {
            title: intl.formatMessage(msg.administration),
            dataKey: 'control',
            withoutSorter: true,
        },
    ];

    const tableData: TTableData[] = permissions.map((permission) => ({
        authority: usersColumnRenderer(permission.principalId),
        create: permissionColumnRenderer(permission.permissions, 'CREATE'),
        read: permissionColumnRenderer(permission.permissions, 'READ'),
        update: permissionColumnRenderer(permission.permissions, 'UPDATE'),
        delete: permissionColumnRenderer(permission.permissions, 'DELETE'),
        control: permissionColumnRenderer(permission.permissions, 'CONTROL'),
    }));

    return (
        <>
            <div className={theme.wrapper}>
                <div className={theme.searchContainer}>
                    <SearchInput
                        onSearch={filterHandler}
                        searchValue={filter}
                        showSearch
                        originalTheme
                        allowClear
                        onClear={() => setFilter('')}
                    />
                </div>
                <Select
                    onChange={(value) => {
                        setFilterType((value as ResultPermissions) || ResultPermissions.allUsers);
                    }}
                    value={intl.formatMessage(messages[ResultPermissions[filterType]])}
                    wrapperClassName={theme.selectContainer}
                >
                    {Object.keys(ResultPermissions).map((permission) => {
                        return (
                            <Select.Option
                                value={ResultPermissions[permission]}
                                key={ResultPermissions[permission]}
                                label={intl.formatMessage(messages[ResultPermissions[permission]])}
                            ></Select.Option>
                        );
                    })}
                </Select>
            </div>
            {errorMsg && <Alert type="error" message={errorMsg} showIcon />}
            <div className={theme.tableWrapper} data-test="permission-dialog_all-permissions-table">
                <TableUIKit columns={columns} tableData={tableData} />
            </div>
        </>
    );
};
