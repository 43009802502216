import { MxRectangle, MxRectangleShape, MxSvgCanvas2D, MxUtils } from '../mxgraph';

export class SequenceLifeline extends MxRectangleShape {
    constructor(
        bounds: MxRectangle,
        fill?: string | null | undefined,
        stroke?: string | null | undefined,
        strokewidth?: number | undefined,
    ) {
        super(bounds, fill, stroke, strokewidth);
        this.bounds = new MxRectangle(0, 0, 120, 460);
        this.stroke = '#000000';
        this.strokewidth = 1;
    }

    size = 40;
    isHtmlAllowed = () => false;

    getLabelBounds(rect: MxRectangle) {
        const size = Math.max(
            0,
            Math.min(rect.height, parseFloat(MxUtils.getValue(this.style, 'size', this.size)) * this.scale),
        );

        return new MxRectangle(rect.x, rect.y, rect.width, size);
    }

    paintBackground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        const size = Math.max(0, Math.min(h, parseFloat(MxUtils.getValue(this.style, 'size', this.size))));
        c.setFillColor('#87CEEB');
        MxRectangleShape.prototype.paintBackground.call(this, c, x, y, w, size);
        c.setDashed(MxUtils.getValue(this.style, 'lifelineDashed', '1'), '1');
        c.begin();
        c.moveTo(x + w / 2, y + size);
        c.lineTo(x + w / 2, y + h);
        c.end();
        c.stroke();
    }
}

export class SequenceBounderyLifeline extends MxRectangleShape {
    constructor(
        bounds: MxRectangle,
        fill?: string | null | undefined,
        stroke?: string | null | undefined,
        strokewidth?: number | undefined,
    ) {
        super(bounds, fill, stroke, strokewidth);
        this.bounds = new MxRectangle(0, 0, 120, 460);
        this.stroke = '#000000';
        this.strokewidth = 1;
    }

    size = 40;
    radius = 40;
    isHtmlAllowed = () => false;

    getLabelBounds(rect: MxRectangle) {
        const size = Math.max(
            0,
            Math.min(rect.height, parseFloat(MxUtils.getValue(this.style, 'size', this.size)) * this.scale),
        );

        return new MxRectangle(rect.x, rect.y + this.radius / 2, rect.width, size);
    }

    paintBackground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        const size = Math.max(0, Math.min(h, parseFloat(MxUtils.getValue(this.style, 'size', this.size))));
        c.setFillColor('#87CEEB');
        c.begin();
        c.ellipse(x + w - this.radius, y + size / 2 - this.radius / 2, this.radius, this.radius);
        c.end();
        c.fillAndStroke();

        c.begin();
        c.moveTo(x, y);
        c.lineTo(x, y + size);
        c.moveTo(x, y + size / 2);
        c.lineTo(x + w - this.radius, y + size / 2);
        c.end();
        c.stroke();

        c.setDashed(MxUtils.getValue(this.style, 'lifelineDashed', '1'), '1');
        c.begin();
        c.moveTo(x + w / 2, y + size + 10);
        c.lineTo(x + w / 2, y + h);
        c.end();
        c.stroke();
    }
}

export class SequenceEntityLifeline extends MxRectangleShape {
    constructor(
        bounds: MxRectangle,
        fill?: string | null | undefined,
        stroke?: string | null | undefined,
        strokewidth?: number | undefined,
    ) {
        super(bounds, fill, stroke, strokewidth);
        this.bounds = new MxRectangle(0, 0, 120, 460);
        this.stroke = '#000000';
        this.strokewidth = 1;
    }

    size = 40;
    radius = 40;
    isHtmlAllowed = () => false;

    getLabelBounds(rect: MxRectangle) {
        const size = Math.max(
            0,
            Math.min(rect.height, parseFloat(MxUtils.getValue(this.style, 'size', this.size)) * this.scale),
        );

        return new MxRectangle(rect.x, rect.y + this.radius / 2 + 10, rect.width, size);
    }

    paintBackground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        const size = Math.max(0, Math.min(h, parseFloat(MxUtils.getValue(this.style, 'size', this.size))));
        c.setFillColor('#87CEEB');
        c.begin();
        c.ellipse(x + w / 2 - this.radius / 2, y + size / 2 - this.radius / 2, this.radius, this.radius);
        c.end();
        c.fillAndStroke();

        c.begin();
        c.moveTo(x, y + size);
        c.lineTo(x + w, y + size);
        c.end();
        c.stroke();

        c.setDashed(MxUtils.getValue(this.style, 'lifelineDashed', '1'), '1');
        c.begin();
        c.moveTo(x + w / 2, y + size + 20);
        c.lineTo(x + w / 2, y + h);
        c.end();
        c.stroke();
    }
}

export class SequenceControlLifeline extends MxRectangleShape {
    constructor(
        bounds: MxRectangle,
        fill?: string | null | undefined,
        stroke?: string | null | undefined,
        strokewidth?: number | undefined,
    ) {
        super(bounds, fill, stroke, strokewidth);
        this.bounds = new MxRectangle(0, 0, 120, 460);
        this.stroke = '#000000';
        this.strokewidth = 1;
    }

    size = 40;
    radius = 40;
    isHtmlAllowed = () => false;

    getLabelBounds(rect: MxRectangle) {
        const size = Math.max(
            0,
            Math.min(rect.height, parseFloat(MxUtils.getValue(this.style, 'size', this.size)) * this.scale),
        );

        return new MxRectangle(rect.x, rect.y + this.radius / 2 + 10, rect.width, size);
    }

    paintBackground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        const size = Math.max(0, Math.min(h, parseFloat(MxUtils.getValue(this.style, 'size', this.size))));
        c.setFillColor('#87CEEB');
        c.begin();
        c.ellipse(x + w / 2 - this.radius / 2, y + size / 2 - this.radius / 2, this.radius, this.radius);
        c.end();
        c.fillAndStroke();

        c.begin();
        c.moveTo(x + w / 2 - 5, y);
        c.lineTo(x + w / 2 + 7, y + 7);
        c.moveTo(x + w / 2 - 5, y);
        c.lineTo(x + w / 2 + 7, y - 7);
        c.end();
        c.stroke();

        c.setDashed(MxUtils.getValue(this.style, 'lifelineDashed', '1'), '1');
        c.begin();
        c.moveTo(x + w / 2, y + size + 20);
        c.lineTo(x + w / 2, y + h);
        c.end();
        c.stroke();
    }
}

export class SequenceActorLifeline extends MxRectangleShape {
    constructor(
        bounds: MxRectangle,
        fill?: string | null | undefined,
        stroke?: string | null | undefined,
        strokewidth?: number | undefined,
    ) {
        super(bounds, fill, stroke, strokewidth);
        this.bounds = new MxRectangle(0, 0, 120, 460);
        this.stroke = '#000000';
        this.strokewidth = 1;
    }

    size = 40;
    radius = 30;
    isHtmlAllowed = () => false;

    getLabelBounds(rect: MxRectangle) {
        const size = Math.max(
            0,
            Math.min(rect.height, parseFloat(MxUtils.getValue(this.style, 'size', this.size)) * this.scale),
        );

        return new MxRectangle(rect.x, rect.y + this.radius + 50, rect.width, size);
    }

    paintBackground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        const size = Math.max(0, Math.min(h, parseFloat(MxUtils.getValue(this.style, 'size', this.size))));

        // Head
        c.setFillColor('#87CEEB');
        c.ellipse(x + w / 2 - this.radius / 2, y, this.radius, this.radius);
        c.fillAndStroke();

        // body
        c.begin();
        c.moveTo(x + w / 2, y + this.radius);
        c.lineTo(x + w / 2, y + this.radius + 40);

        // Arms
        c.moveTo(x + w / 2 - 20, y + this.radius + 10);
        c.lineTo(x + w / 2 + 20, y + this.radius + 10);

        // Legs
        c.moveTo(x + w / 2, y + this.radius + 40);
        c.lineTo(x + w / 2 - 20, y + this.radius + 60);
        c.moveTo(x + w / 2, y + this.radius + 40);
        c.lineTo(x + w / 2 + 20, y + this.radius + 60);
        c.end();

        c.stroke();

        c.setFillColor('#87CEEB');
        c.rect(x + w / 2 - 5, y + this.radius + 40 + size, 10, Math.max(h - (this.radius + 40 + size), 0));
        c.fillAndStroke();
    }
}
