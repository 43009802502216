import { NotificationTemplateDTO } from '@/serverapi/api';
import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTemplatesDAOService } from '@/services/dao/NotificationTemplatesDAOService';
import {
    CREATE_NOTIFICATION_TEMPLATES,
    DELETE_NOTIFICATION_TEMPLATES,
    LOAD_NOTIFICATION_TEMPLATES,
    SAVE_NOTIFICATION_TEMPLATES,
} from '@/actionsTypes/notificationTemplates.actionTypes';
import { addNotificationTemplates, loadNotificationTemplatesAction } from '@/actions/notificationTemplates.actions';
import {
    TCreateNotificationTemplatesAction,
    TDeleteNotificationTemplatesAction,
    TSaveNotificationTemplatesAction,
} from '@/actions/notificationTemplates.actions.types';

function* loadNotificationTemplates() {
    const notificationTemplates: NotificationTemplateDTO[] = yield NotificationTemplatesDAOService.getAll();

    yield put(addNotificationTemplates(notificationTemplates));
}

function* handleSaveNotificationTemplate(action: TSaveNotificationTemplatesAction) {
    const { notificationTemplate } = action.payload;
    yield NotificationTemplatesDAOService.save(notificationTemplate);
    yield put(loadNotificationTemplatesAction());
}

function* handleCreateNotificationTemplate(action: TCreateNotificationTemplatesAction) {
    const { notificationTemplate } = action.payload;
    yield NotificationTemplatesDAOService.create(notificationTemplate);
    yield put(loadNotificationTemplatesAction());
}

function* handleDeleteNotificationTemplate(action: TDeleteNotificationTemplatesAction) {
    const { notificationTemplateId } = action.payload;
    yield NotificationTemplatesDAOService.delete(notificationTemplateId);
    yield put(loadNotificationTemplatesAction());
}

export function* notificationTemplatesSaga() {
    yield takeEvery(LOAD_NOTIFICATION_TEMPLATES, loadNotificationTemplates);
    yield takeEvery(SAVE_NOTIFICATION_TEMPLATES, handleSaveNotificationTemplate);
    yield takeEvery(CREATE_NOTIFICATION_TEMPLATES, handleCreateNotificationTemplate);
    yield takeEvery(DELETE_NOTIFICATION_TEMPLATES, handleDeleteNotificationTemplate);
}
