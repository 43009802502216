import React from 'react';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { Modal } from 'antd';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useDispatch } from 'react-redux';
import messages from '../../messages/ImportVisioErrorDialog.messages';
import { FormattedMessage } from 'react-intl';
import { shouldCallModalSubmitHandler } from '../../../../../services/utils/ModalHelper';
import { DialogFooterButtons } from '../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export const ImportVisioErrorDialog = ({ errorText }) => {
    const dispatch = useDispatch();

    const handlOkClick = () => {
        dispatch(closeDialog(DialogType.IMPORT_VISIO_ERROR));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'ok',
                    onClick: handlOkClick,
                    value: 'OK',
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    handlOkClick();
                }
            }}
        >
            <Modal
                width={400}
                open
                onOk={handlOkClick}
                title={<FormattedMessage {...messages.title} />}
                footer={footer}
            >
                {errorText}
            </Modal>
        </div>
    );
};
