import type { TSpriteSymbol } from '@/models/spriteSymbol.types';
import type { TooltipPlacement } from 'antd/es/tooltip';
import React, { FC } from 'react';
import { Tooltip } from 'antd';
import cx from 'classnames';
import { NUIButton } from '../NUIButton/NUIButton.component';
import { Icon } from '../Icon/Icon.component';
import theme from './Button.scss';
import { TOOLTIP_ENTER_DELAY, TOOLTIP_LEAVE_DELAY } from '@/utils/configuration';
import icArrowDown from 'icons/toolbar/controls/ic-arrow-down.svg';

interface IButtonProps {
    icon?: TSpriteSymbol;
    tooltipTitle?: string;
    tooltipPlacement?: TooltipPlacement;
    title?: string;
    arrow?: boolean;
    compact?: boolean;
    disabled?: boolean;
    selected?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
    onMouseUp?: React.MouseEventHandler<HTMLElement>;
    onMouseDown?: React.MouseEventHandler<HTMLElement>;
    style?: React.CSSProperties;
    className?: string;
    dataTest?: string;
    containerDataTest?: string;
}

export const Button: FC<IButtonProps> = (props) => {
    const {
        icon,
        tooltipTitle,
        tooltipPlacement = 'top',
        title,
        arrow = false,
        compact = true,
        disabled,
        selected,
        onClick,
        onMouseUp,
        onMouseDown,
        style,
        className,
        dataTest,
        containerDataTest,
    } = props;
    const containerTestAttr = containerDataTest ? { 'data-test': containerDataTest } : {};
    const testAttr = dataTest ? { 'data-test': dataTest } : {};

    return (
        <div {...containerTestAttr}>
            <Tooltip
                title={tooltipTitle}
                placement={tooltipPlacement}
                overlayClassName={theme.tooltipOverlay}
                mouseEnterDelay={TOOLTIP_ENTER_DELAY}
                mouseLeaveDelay={TOOLTIP_LEAVE_DELAY}
            >
                <div {...testAttr} className={theme.buttonContainer}>
                    <NUIButton
                        disabled={disabled}
                        selected={selected}
                        className={cx(className, theme.button)}
                        style={style}
                        onClick={onClick}
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                    >
                        {icon && <Icon spriteSymbol={icon} disabled={disabled} />}
                        {!compact && title && <span>{title}</span>}
                        {arrow && <Icon spriteSymbol={icArrowDown} disabled={disabled} />}
                    </NUIButton>
                </div>
            </Tooltip>
        </div>
    );
};
