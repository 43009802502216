import { OPEN_DOCUMENT_REQUEST } from '../actionsTypes/recent.actionTypes';
import { NodeId } from '../serverapi/api';
import { TOpenNodeByIdRequestAction, TOpenNodeRequestAction, TOpenNodeRequestPayload } from './openNode.actions.types';
import { OPEN_DOCUMENT_REQUEST_BY_ID } from '../actionsTypes/openNode.actionTypes';

export const openNode = (payload: TOpenNodeRequestPayload): TOpenNodeRequestAction => ({
    type: OPEN_DOCUMENT_REQUEST,
    payload,
});

export const openNodeById = (payload: { nodeId: NodeId }): TOpenNodeByIdRequestAction => ({
    type: OPEN_DOCUMENT_REQUEST_BY_ID,
    payload,
});
