import { NotificationDTO } from '@/serverapi/api';
import { TReducer } from '@/utils/types';
import { ADD_NOTIFICATIONS } from '@/actionsTypes/notifications.actionTypes';
import { TNotificationsState } from './notifications.reducer.types';

const initial: NotificationDTO[] = [];

export const notificationsReducer: TReducer<TNotificationsState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_NOTIFICATIONS: {
            const {
                payload: { notifications },
            } = action;

            return notifications;
        }

        default:
            return state;
    }
};
