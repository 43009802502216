import { LocalStorageDaoService } from "@/services/dao/LocalStorageDaoService";
import { useEffect, useState } from "react";
import { TDefaultCommentSize } from "../types/defaultCommentSize.types";

export const useDefaultCommentsSize = (): TDefaultCommentSize => {
    const DEFAULT_SIZE: number = 300;
    const [defaultCommentSize, setDefaultCommentSize] = useState<number | undefined>(DEFAULT_SIZE);

    useEffect(() => {
        if (LocalStorageDaoService.getCommentsTabSize()) {
            setDefaultCommentSize(LocalStorageDaoService.getCommentsTabSize());
        }
    }, []);

    const changeCommentsTabSizeHandler = (size: number): void => {
        LocalStorageDaoService.setCommentsTabSize(size);
    };

    return { defaultCommentSize, changeCommentsTabSizeHandler };

};
