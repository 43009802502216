// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FontSettings__fontSizeInputWrapper__v3P__{width:73px}.FontSettings__fontSizeInputWrapper__v3P__>div{position:relative;width:100%}.FontSettings__colorIndicator__sM9KT{width:40px;height:16px}.FontSettings__fontColorPickerButtonWrapper__PhFZ4{width:75px;padding-right:2px}.FontSettings__buttonWrapper__H_jjo,.FontSettings__buttonWrapperWithPadding__nkK1B{position:relative}.FontSettings__buttonWrapper__H_jjo .ant-btn-selected,.FontSettings__buttonWrapperWithPadding__nkK1B .ant-btn-selected{text-decoration:none;border-radius:0;background:rgba(39,107,238,.1)}.FontSettings__buttonWrapper__H_jjo .ant-btn:focus,.FontSettings__buttonWrapperWithPadding__nkK1B .ant-btn:focus{color:var(--textGrey);border-color:#d9d9d9}.FontSettings__buttonWrapperWithPadding__nkK1B{padding-right:2px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/FontSettings/FontSettings.scss"],"names":[],"mappings":"AAAA,2CACI,UAAA,CAGJ,+CACI,iBAAA,CACA,UAAA,CAGJ,qCACI,UAAA,CACA,WAAA,CAGJ,mDACI,UAAA,CACA,iBAAA,CAGJ,mFAEI,iBAAA,CAGI,uHACI,oBAAA,CACA,eAAA,CACA,8BAAA,CAEJ,iHACI,qBAAA,CACA,oBAAA,CAKZ,+CACI,iBAAA","sourcesContent":[".fontSizeInputWrapper {\r\n    width: 73px;\r\n}\r\n\r\n.fontSizeInputWrapper > div {\r\n    position: relative;\r\n    width: 100%;\r\n}\r\n\r\n.colorIndicator {\r\n    width: 40px;\r\n    height: 16px;\r\n}\r\n\r\n.fontColorPickerButtonWrapper {\r\n    width: 75px;\r\n    padding-right: 2px;\r\n}\r\n\r\n.buttonWrapper,\r\n.buttonWrapperWithPadding {\r\n    position: relative;\r\n\r\n    :global {\r\n        .ant-btn-selected {\r\n            text-decoration: none;\r\n            border-radius: 0;\r\n            background: rgba(39, 107, 238, 0.1);\r\n        }\r\n        .ant-btn:focus {\r\n            color: var(--textGrey);\r\n            border-color: #d9d9d9;\r\n        }\r\n    }\r\n}\r\n\r\n.buttonWrapperWithPadding {\r\n    padding-right: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontSizeInputWrapper": "FontSettings__fontSizeInputWrapper__v3P__",
	"colorIndicator": "FontSettings__colorIndicator__sM9KT",
	"fontColorPickerButtonWrapper": "FontSettings__fontColorPickerButtonWrapper__PhFZ4",
	"buttonWrapper": "FontSettings__buttonWrapper__H_jjo",
	"buttonWrapperWithPadding": "FontSettings__buttonWrapperWithPadding__nkK1B"
};
export default ___CSS_LOADER_EXPORT___;
