import { MxCell } from 'MxGraph';
import { createFrameFromString } from '../../utils';
import { TSuggestions } from '../ComplexSymbol.class.types';
import { SymbolTypeId } from '../ComplexSymbol.constants';
import MetaInfoComplexSymbol from '../MetaInfoComplexSymbol.class';
import MetaInfoSerializer from '../serializers/MetaInfoSerializer.class';
import {
    getTableSymbolDiagramClassTemplate,
    getTableSymbolDiagrammClassLineTemplate,
} from './tableSymbolDiagramElements';

export class TableSymbolClass extends MetaInfoComplexSymbol {
    complexSymbolTypeId = SymbolTypeId.TABLE_SYMBOL_CLASS;
    public get isConnectable(): boolean {
        return true;
    }

    template = getTableSymbolDiagramClassTemplate();
    serializer = new MetaInfoSerializer();

    public getSuggestions(): TSuggestions {
        return ['int', 'bool', 'string'];
    }

    addLine(cell: MxCell) {
        const { parent } = cell;
        const [clonedModelCell] = createFrameFromString(getTableSymbolDiagrammClassLineTemplate());
        const index = parent.children.indexOf(cell);
        clonedModelCell.frame = parent.frame;
        const model = this.graph.getModel();

        return model.add(parent, clonedModelCell, index + 1);
    }

    removeLine(cell: MxCell) {
        this.graph.removeCells([cell]);
    }
}
