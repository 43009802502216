import { Form, Input } from 'antd';
import theme from './EditableText.scss';
import React, {
    MouseEvent,
    ChangeEvent,
    FormEvent,
    SyntheticEvent,
    FC,
    useState,
    useEffect,
    KeyboardEvent,
} from 'react';
import cx from 'classnames';
import { TInputTypeEnum } from './EditableText.types';
import { DecimalNumberValidatorBllService } from '../../../../services/bll/DecimalNumberValidatorBllService';
import classNames from 'classnames';

type TEditableTextProps = {
    text: string;
    disabled: boolean;
    allowEmptyValue?: boolean;
    isUrlType?: boolean;
    isEditing?: boolean;
    inputType?: TInputTypeEnum;
    onActivateEditable?: () => void;
    onDeactivateEditable?: () => void;
    onChange?: (text: string) => void;
    onClickLink?: (event: MouseEvent) => void;
    className?: string;
};

export const EditableText: FC<TEditableTextProps> = (props) => {
    const {
        text,
        isUrlType = false,
        inputType = 'text',
        isEditing,
        allowEmptyValue,
        disabled,
        onChange,
        onClickLink,
        onActivateEditable,
        onDeactivateEditable,
        className,
    } = props;
    const [hasError, setHasError] = useState(false);
    const [isLocalEditing, setIsLocalEditing] = useState(false);
    const [currentText, setCurrentText] = useState(text || '');

    useEffect(() => {
        setCurrentText(text);
    }, [text]);

    useEffect(() => {
        if (isEditing) {
            setIsLocalEditing(true);
        }
    }, [isEditing]);

    useEffect(() => {
        if (isLocalEditing) {
            onActivateEditable && onActivateEditable();
        } else {
            onDeactivateEditable && onDeactivateEditable();
        }
    }, [isLocalEditing]);

    const onSaveText = (e: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!hasError && onChange && text !== currentText) {
            if (e.currentTarget?.name === 'number') {
                onChange(DecimalNumberValidatorBllService.onBlurValidator(currentText));
            } else {
                onChange(currentText);
            }
        }
        if (!hasError) setIsLocalEditing(false);
    };

    const onChangeText = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const {
            target: { value },
        } = e;
        if (e.currentTarget.name === 'number') {
            setCurrentText(
                value.trim().length
                    ? DecimalNumberValidatorBllService.convertToFractionalNumber(value)
                    : DecimalNumberValidatorBllService.convertToFractionalNumber(value),
            );
            setHasError(
                DecimalNumberValidatorBllService.convertToFractionalNumber(value).trim().length === 0 &&
                    !allowEmptyValue,
            );
        } else {
            setCurrentText(value.trim().length ? value : value.trim());
            setHasError(value.trim().length === 0 && !allowEmptyValue);
        }
    };

    const onBlur = (e: SyntheticEvent<HTMLInputElement> | SyntheticEvent<HTMLTextAreaElement>) => {
        onSaveText(e);
    };

    const onSaveTextArea = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.shiftKey) return;

        onSaveText(e);
    };

    const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Escape') {
            onChange?.(text);
            setCurrentText(text);
            setIsLocalEditing(false);
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const inputClassName = classNames(theme.input, { [theme.inputWithoutError]: !hasError });

    const editing = (
        <div className={theme.container_editing}>
            <Form.Item validateStatus={hasError ? 'error' : 'success'}>
                {inputType === 'textarea' ? (
                    <Input.TextArea
                        rows={4}
                        autoFocus
                        value={currentText}
                        onChange={onChangeText}
                        onKeyDown={onKeyDown}
                        onPressEnter={onSaveTextArea}
                        onBlur={onBlur}
                        maxLength={10000}
                        disabled={disabled}
                        className={theme.textArea}
                    />
                ) : (
                    <>
                        {inputType === 'number' ? (
                            <Input
                                size="small"
                                name="number"
                                autoFocus
                                style={{ width: '100%' }}
                                value={currentText}
                                onChange={onChangeText}
                                onKeyDown={onKeyDown}
                                onPressEnter={onSaveText}
                                onBlur={onBlur}
                                disabled={disabled}
                                className={inputClassName}
                            />
                        ) : (
                            <Input
                                size="small"
                                autoFocus
                                value={currentText}
                                onChange={onChangeText}
                                onKeyDown={onKeyDown}
                                onPressEnter={onSaveText}
                                onBlur={onBlur}
                                disabled={disabled}
                                type={inputType}
                                className={inputClassName}
                            />
                        )}
                    </>
                )}
            </Form.Item>
        </div>
    );

    const dataText = isLocalEditing ? editing : text;

    const showUrl: boolean = isUrlType && !isLocalEditing;

    const clickLinkHandler = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClickLink && onClickLink(e);
    };

    return (
        <div className={cx(className, theme.container, inputType === 'textarea' && theme.multiLineEllipsis)}>
            {showUrl ? (
                <div className={theme.linkWrapper} onClick={clickLinkHandler}>
                    {dataText}
                </div>
            ) : (
                <div className={theme.textWrapper}>{dataText}</div>
            )}
        </div>
    );
};
