export function compareSize(width: number, height: number, defaultImageDimension: number, value: string) {
    if (width === height && width > defaultImageDimension) {
        return defaultImageDimension;
    }
    if (width > height && width > defaultImageDimension) {
        if (value === 'width') {
            return defaultImageDimension;
        }
        const proportion = width / height;

        return defaultImageDimension / proportion;
    }
    if (height > width && height > defaultImageDimension) {
        if (value === 'width') {
            const proportion = height / width;

            return defaultImageDimension / proportion;
        }

        return defaultImageDimension;
    }

    if (value === 'width') {
        return width;
    }

    return height;
}
