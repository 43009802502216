import { cloneDeep } from 'lodash-es';
import { MxCellState, MxPoint } from '../mxgraph';
import { forkEdgeOffset } from './SequenceConstants';

/**
 * Класс описывающий текущее состояние (отображение) ячейки на графе
 */
export class SequenceCellState extends MxCellState {
    /**
     * Устанавливает положение терминальных точек связи
     * @param { MxPoint } point обновленная терминальная точка
     * @param { boolean } isSource точка для источника (source) или таргета (target)
     * @param { MxPoint } secondPoint обновленная терминальная для противоположного терминала (source/target)
     * @param { boolean } [isRecursive] является ли связь рекурсивной
     */
    setAbsoluteTerminalPoint(
        point: MxPoint,
        isSource: boolean,
        secondPoint: MxPoint,
        isRecursive?: boolean,
        isFork?: boolean,
    ) {
        if (isSource) {
            if (this.absolutePoints == null) {
                this.absolutePoints = [];
            }

            if (this.absolutePoints.length === 0) {
                this.absolutePoints.push(point);
            } else {
                const tmp = this.absolutePoints[0]?.clone();
                if (isRecursive) {
                    const dx = this.absolutePoints[1].x - this.absolutePoints[0].x;
                    const dx1 = this.absolutePoints[2].x - this.absolutePoints[3].x;
                    const minX = Math.max(this.absolutePoints[0].x, this.absolutePoints[3].x) + 20;
                    const newX =
                        point.x < this.absolutePoints[2].x
                            ? secondPoint.x + dx1
                            : Math.max(point.x + dx, secondPoint?.x + dx1);
                    this.absolutePoints[0] = new MxPoint(point.x, tmp.y);
                    this.absolutePoints[1] = new MxPoint(Math.max(newX, minX), tmp.y);
                } else if (isFork) {
                    const isReverce = point.x > secondPoint.x;
                    this.absolutePoints[0] = new MxPoint(point.x, tmp.y);
                    const forkPoint = point.x + (isReverce ? -forkEdgeOffset : forkEdgeOffset);
                    this.absolutePoints[1] = new MxPoint(forkPoint, this.absolutePoints[1].y);
                } else {
                    this.absolutePoints[0] = new MxPoint(point.x, tmp.y);
                }
            }
        }

        if (!isSource) {
            if (this.absolutePoints == null) {
                this.absolutePoints = [];
                this.absolutePoints.push(null);
                this.absolutePoints.push(point);
            } else if (this.absolutePoints.length === 1) {
                this.absolutePoints.push(point);
            } else {
                const tmp = this.absolutePoints[this.absolutePoints.length - 1]?.clone();
                if (isRecursive) {
                    const dx =
                        this.absolutePoints[this.absolutePoints.length - 2].x -
                        this.absolutePoints[this.absolutePoints.length - 1].x;
                    const dx1 = this.absolutePoints[1].x - this.absolutePoints[0].x;
                    const newX = Math.max(point.x + dx, secondPoint?.x + dx1);
                    const minX = Math.max(this.absolutePoints[0].x, this.absolutePoints[3].x) + 20;
                    this.absolutePoints[this.absolutePoints.length - 1] = new MxPoint(point.x, tmp.y);
                    this.absolutePoints[this.absolutePoints.length - 2] = new MxPoint(Math.max(newX, minX), tmp.y);
                } else this.absolutePoints[this.absolutePoints.length - 1] = new MxPoint(point.x, tmp.y);
            }
        }
    }

    /**
     * Клонирование состояния ячейки
        @returns MxCellState
     */
    clone() {
        const clone = new MxCellState(this.view, this.cell, this.style);

        // Clones the absolute points
        if (this.absolutePoints != null) {
            clone.absolutePoints = [];

            for (let i = 0; i < this.absolutePoints.length; i++) {
                clone.absolutePoints[i] = this.absolutePoints[i].clone();
            }
        }

        if (this.origin != null) {
            clone.origin = this.origin.clone();
        }

        if (this.absoluteOffset != null) {
            clone.absoluteOffset = this.absoluteOffset.clone();
        }

        if (this.boundingBox != null) {
            clone.boundingBox = this.boundingBox.clone();
        }

        clone.terminalDistance = this.terminalDistance;
        clone.segments = this.segments;
        clone.length = this.length;
        clone.x = this.x;
        clone.y = this.y;
        clone.width = this.width;
        clone.height = this.height;
        clone.unscaledWidth = this.unscaledWidth;
        clone.unscaledHeight = this.unscaledHeight;
        clone.shape = cloneDeep(this.shape);

        return clone;
    }
}
