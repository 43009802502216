import { defineMessages } from 'react-intl';

export default defineMessages({
    loadDate: {
        id: 'loadDate',
        defaultMessage: 'Дата загрузки',
    },
    beginDate: {
        id: 'beginDate',
        defaultMessage: 'Дата начала',
    },
    endDate: {
        id: 'endDate',
        defaultMessage: 'Дата окончания',
    },
    daysLeft: {
        id: 'daysLeft',
        defaultMessage: 'Осталось дней',
    },
    loadLicensesHeader: {
        id: 'loadLicensesTitle',
        defaultMessage: 'Загруженные файлы лицензий',
    },
    loadLicenseBtnLabel: {
        id: 'loadLicenseBtnLabel',
        defaultMessage: 'Загрузить лицензию',
    },
    licenseName: {
        id: 'licenseName',
        defaultMessage: 'Тип',
    },
    licenseCount: {
        id: 'licenseCount',
        defaultMessage: 'Количество',
    },
    licenseUsed: {
        id: 'licenseUsed',
        defaultMessage: 'Использовано',
    },
    usingCmpLicense: {
        id: 'usingCmpLicense',
        defaultMessage: 'Используются сейчас',
    },
    usingLicensesHeader: {
        id: 'usingLicensesHeader',
        defaultMessage: 'Использование лицензий',
    },
    licenseTypes: {
        id: 'licenseTypes',
        defaultMessage: 'Типы лицензий',
    },
    licenseType: {
        id: 'licenseType',
        defaultMessage: 'Тип лицензии',
    },
    companyName: {
        id: 'companyName',
        defaultMessage: 'Компания',
    },
    licenseOrTech: {
        id: 'licenseOrTech',
        defaultMessage: 'Лицензии/ТП',
    },
    serialNumber: {
        id: 'serialNumber',
        defaultMessage: 'Серийный номер',
    },
    licenseID: {
        id: 'licenseID',
        defaultMessage: 'ID',
    },
    modelEditorsCount: {
        id: 'modelEditorsCount',
        defaultMessage: 'Modeler',
    },
    modelLiteEditorsCount: {
        id: 'modelLiteEditorsCount',
        defaultMessage: 'Product Designer',
    },
    modelReadersCount: {
        id: 'modelReadersCount',
        defaultMessage: 'Viewer',
    },
    modelEditorsCompetitiveCount: {
        id: 'modelEditorsCompetitiveCount',
        defaultMessage: 'Modeler Competitive',
    },
    modelLiteEditorsCompetitiveCount: {
        id: 'modelLiteEditorsCompetitiveCount',
        defaultMessage: 'Product Designer Competitive',
    },
    modelReadersCompetitiveCount: {
        id: 'modelReadersCompetitiveCount',
        defaultMessage: 'Viewer Competitive',
    },
    isALicence: {
        id: 'aLicence',
        defaultMessage: 'Лицензии',
    },
    isATech: {
        id: 'isATech',
        defaultMessage: 'ТП',
    },
    // TODO: удалить
    usersGroupsColTitle: {
        id: 'licensestool.usersGroupsColTitle',
        defaultMessage: 'Пользователи/группы',
    },
    amountOfFree: {
        id: 'amountOfFree',
        defaultMessage: 'Свободно',
    },
    deleteLicenseQuestion: {
        id: 'deleteLicenseQuestion',
        defaultMessage: 'Удалить файл с лицензией',
    },
});
