export const ADD_STORE_EDIT_MATRIX_MODEL_TYPE_WORKSPACE_TAB = 'ADD_STORE_EDIT_MATRIX_MODEL_TYPE_WORKSPACE_TAB';

export const CHANGE_MATRIX_MODEL_TYPE_NAME = 'CHANGE_MATRIX_MODEL_TYPE_NAME';

export const CHANGE_MATRIX_MODEL_TYPE_ID = 'CHANGE_MATRIX_MODEL_TYPE_ID';

export const CHANGE_MATRIX_MODEL_TYPE_DESCRIPTION = 'CHANGE_MATRIX_MODEL_TYPE_DESCRIPTION';

export const CHANGE_MATRIX_MODEL_TYPE_GROUP = 'CHANGE_MATRIX_MODEL_TYPE_GROUP';

export const CHANGE_MATRIX_MODEL_TYPE_ENABLE_MODEL = 'CHANGE_MATRIX_MODEL_TYPE_ENABLE_MODEL';

export const MATRIX_MODEL_TYPE_ADD_ATTRIBUTE_TYPES = 'MATRIX_MODEL_TYPE_ADD_ATTRIBUTE_TYPES';

export const MATRIX_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES = 'MATRIX_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES';

export const MATRIX_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS = 'MATRIX_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS';

export const CHANGE_MATRIX_MODEL_TYPE_ALLOW_APPROVALS = 'CHANGE_MATRIX_MODEL_TYPE_ALLOW_APPROVALS';
