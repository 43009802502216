import type { TRichTextEditorToolbarProps } from '@/modules/UIKit/components/TipTapTextEditor/Toolbar/RichTextEditorToolbar.types';
import type { TWikiEditorToolbarProps } from './WikiEditor.types';
import React, { useMemo, FC } from 'react';
import DraftJsTextEditorToolbar from '../../UIKit/components/RichTextEditor/Toolbar/RichTextEditorToolbar.component';
import { RichTextEditorToolbar as TipTapTextEditorToolbar } from '../../UIKit/components/TipTapTextEditor/Toolbar/RichTextEditorToolbar.component';

const WikiEditorToolbar: FC<TWikiEditorToolbarProps> = ({ isNewEditor, ...props }) => {
    const EditorToolbar: React.FC<TRichTextEditorToolbarProps> = useMemo(
        () => (isNewEditor ? TipTapTextEditorToolbar : DraftJsTextEditorToolbar),
        [isNewEditor],
    );

    return <EditorToolbar {...props} />;
};

export default WikiEditorToolbar;
