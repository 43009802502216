import type { AttributeTypeStyleRule, AttributeTypeStyleRuleTypeEnum } from '../../../../../serverapi/api';
import React, { useEffect } from 'react';
import { DatePicker } from 'antd';
import {
    momentDateToTimestamp,
    timestampToMomentDate,
    dateFormat,
    dateTimeFormat,
} from '../../../../../utils/date.time.utils';
import dayjs from 'dayjs';

type TDateInput = {
    rule: AttributeTypeStyleRule;
    showTime: boolean;
    disabled?: boolean;
    handleRuleParams: (ruleType: AttributeTypeStyleRuleTypeEnum | undefined, value1: string, value2?: string) => void;
};

export const DateInput = ({ rule, showTime, disabled, handleRuleParams }: TDateInput) => {
    useEffect(() => {
        if (!rule.param) {
            if (rule.type === 'HAS_NOT_VALUE') {
                handleRuleParams(rule.type, dayjs().format(showTime ? dateTimeFormat : dateFormat));
            } else {
                handleRuleParams(rule.type, momentDateToTimestamp(dayjs())?.toString() || '');
            }
        }
    }, [rule.param]);

    const onChangeHandler = (event: dayjs.Dayjs | null, value: string) => {
        if (rule.type === 'HAS_NOT_VALUE') {
            handleRuleParams(rule.type, value);
        } else {
            handleRuleParams(rule.type, momentDateToTimestamp(event || dayjs())?.toString() || '');
        }
    };

    const currentValue = () => {
        if (rule.type === 'HAS_NOT_VALUE') {
            return dayjs(rule.param, showTime ? dateTimeFormat : dateFormat);
        }

        return timestampToMomentDate(Number(rule.param || Date.now()));
    };

    return (
        <DatePicker
            data-test="change-the-value-of-the-rule_data-type"
            id={rule.type}
            allowClear={false}
            disabled={disabled}
            showTime={showTime}
            format={showTime ? dateTimeFormat : dateFormat}
            onChange={onChangeHandler}
            value={currentValue()}
        />
    );
};
