import { defineMessages } from 'react-intl';

export default defineMessages({
    NUMERIC: {
        id: 'AttributeType.NUMERIC',
        defaultMessage: 'Число',
    },
    INTEGER: {
        id: 'AttributeType.INTEGER',
        defaultMessage: 'Целое число',
    },
    STRING: {
        id: 'AttributeType.STRING',
        defaultMessage: 'Строка',
    },
    PRINCIPAL: {
        id: 'AttributeType.PRINCIPAL',
        defaultMessage: 'Пользователь',
    },
    MULTI_STRING: {
        id: 'AttributeType.MULTI_STRING',
        defaultMessage: 'Текст',
    },
    BOOLEAN: {
        id: 'AttributeType.BOOLEAN',
        defaultMessage: 'Логический',
    },
    DATE: {
        id: 'AttributeType.DATE',
        defaultMessage: 'Дата',
    },
    TIME: {
        id: 'AttributeType.TIME',
        defaultMessage: 'Время',
    },
    DATE_TIME: {
        id: 'AttributeType.DATE_TIME',
        defaultMessage: 'Дата и время',
    },
    PERIOD: {
        id: 'AttributeType.PERIOD',
        defaultMessage: 'Период',
    },
    SELECT: {
        id: 'AttributeType.SELECT',
        defaultMessage: 'Выбор',
    },
    MULTI_SELECT: {
        id: 'AttributeType.MULTI_SELECT',
        defaultMessage: 'Множественный выбор',
    },
    NODE: {
        id: 'AttributeType.NODE',
        defaultMessage: 'Cсылка на документ',
    },
    DURATION: {
        id: 'AttributeType.DURATION',
        defaultMessage: 'Длительность',
    },
    JSON: {
        id: 'AttributeType.JSON',
        defaultMessage: 'JSON',
    },
    FILE: {
        id: 'AttributeType.FILE',
        defaultMessage: 'Файл',
    },
    URL: {
        id: 'AttributeType.URL',
        defaultMessage: 'Ссылка',
    },
    EXTERNAL_DATA: {
        id: 'AttributeType.EXTERNAL_DATA',
        defaultMessage: 'Внешние данные',
    },
    QUERY_SELECT: {
        id: 'AttributeType.QUERY_SELECT',
        defaultMessage: 'Запрос',
    },
    QUERY_MULTI_SELECT: {
        id: 'AttributeType.QUERY_MULTI_SELECT',
        defaultMessage: 'Запрос множества значений',
    },
    DEFAULT_ATTRIBUE_VALUE: {
        id: 'AttributeType.DEFAULT_ATTRIBUE_VALUE',
        defaultMessage: 'Значение атрибута по умолчанию',
    },
    DEFAULT_ATTRIBUE_VALUE_IS_NOT_ACCESSIBLE: {
        id: 'AttributeType.DEFAULT_ATTRIBUE_VALUE_IS_NOT_ACCESSIBLE',
        defaultMessage: 'Значение атрибута по умолчанию не доступно',
    },
    TIME_WITHOUT_TIMEZONE: {
        id: 'AttributeType.TIME_WITHOUT_TIMEZONE',
        defaultMessage: 'Время без учёта часового пояса',
    },
    DATE_TIME_WITHOUT_TIMEZONE: {
        id: 'AttributeType.DATE_TIME_WITHOUT_TIMEZONE',
        defaultMessage: 'Дата и время без учёта часового пояса',
    },
});
