import { defineMessages } from 'react-intl';

export default defineMessages({
    deleteEvent: {
        id: 'EventAccess.deleteEvent',
        defaultMessage: 'Удалить событие "{eventName}"?',
    },
    eventName: {
        id: 'EventAccess.eventName',
        defaultMessage: 'Название события',
    },
    strategy: {
        id: 'EventAccess.strategy',
        defaultMessage: 'Стратегия',
    },
    actions: {
        id: 'EventAccess.actions',
        defaultMessage: 'Действие',
    },
    userId: {
        id: 'EventAccess.userId',
        defaultMessage: 'Пользователь',
    },
    groupId: {
        id: 'EventAccess.groupId',
        defaultMessage: 'Группа',
    },
    eventNodeElementName: {
        id: 'EventAccess.eventNodeElementName ',
        defaultMessage: 'Элемент',
    },
    eventParentNodeElementName: {
        id: 'EventAccess.eventParentNodeElementName',
        defaultMessage: 'Элемент родитель',
    },
    className: {
        id: 'EventAccess.className',
        defaultMessage: 'Обработчик',
    },
    params: {
        id: 'EventAccess.params',
        defaultMessage: 'Параметры',
    },
    scriptId: {
        id: 'EventAccess.scriptId',
        defaultMessage: 'Скрипт',
    },
    addEvent: {
        id: 'EventAccess.addEvent',
        defaultMessage: 'Добавить',
    },
    launchingScriptUserId: {
        id: 'EventAccess.launchingScriptUserId',
        defaultMessage: 'Пользователь, запускающий скрипт',
    },
    BEFORE: {
        id: 'EventAccess.BEFORE',
        defaultMessage: 'До',
    },
    AFTER: {
        id: 'EventAccess.AFTER',
        defaultMessage: 'После',
    },
    ERROR: {
        id: 'EventAccess.ERROR',
        defaultMessage: 'В случае ошибки',
    },
    INSTEAD: {
        id: 'EventAccess.INSTEAD',
        defaultMessage: 'Вместо',
    },
});
