import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { changeDepth, isListBlock, getBlockBeforeSelectedBlock, getSelectedBlock } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import icListBulleted from 'icons/toolbar/controls/ic-format-list-bulleted.svg';
import icListNumbered from 'icons/toolbar/controls/ic-format-list-numbered.svg';
import icListIndentDescrease from 'icons/toolbar/controls/ic-indent-decrease.svg';
import icListIndentIncrease from 'icons/toolbar/controls/ic-indent-increase.svg';
import { Button } from 'antd';
import { ToolbarButton } from 'UIKit';
import icCheckList from 'icons/toolbar/controls/ic-check-list.svg';
import { toggleBlockType } from '../../common/editorState.utils';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { CHECKABLE_LIST_ITEM, UNORDERED_LIST_ITEM, ORDERED_LIST_ITEM } from '../../common/constants';
import theme from '../RichTextEditorToolbar.scss';

const ButtonGroup = Button.Group;
const isExtendedListBlock = (block) => {
    const type = block.get('type');

    return type === CHECKABLE_LIST_ITEM || isListBlock(block);
};

const getFocusedBlock = (editorState) => getSelectedBlock(editorState).get('type');

const getChangedBlock = (editorState, value) => {
    if (value === UNORDERED_LIST_ITEM) {
        return toggleBlockType(editorState, UNORDERED_LIST_ITEM);
    }
    if (value === ORDERED_LIST_ITEM) {
        return toggleBlockType(editorState, ORDERED_LIST_ITEM);
    }
    if (value === CHECKABLE_LIST_ITEM) {
        return toggleBlockType(editorState, CHECKABLE_LIST_ITEM);
    }
    if (value === 'indent') {
        return changeDepth(editorState, 1, 4);
    }

    return changeDepth(editorState, -1, 4);
};

const getIsIndentDisabled = (editorState) => {
    const currentBlock = getSelectedBlock(editorState);
    const previousBlock = getBlockBeforeSelectedBlock(editorState);
    if (
        !previousBlock ||
        !isExtendedListBlock(currentBlock) ||
        previousBlock.get('type') !== currentBlock.get('type') ||
        previousBlock.get('depth') < currentBlock.get('depth')
    ) {
        return true;
    }

    return false;
};

const getIsOutdentDisabled = (editorState) => {
    const currentBlock = getSelectedBlock(editorState);

    return !currentBlock || !isExtendedListBlock(currentBlock) || currentBlock.get('depth') <= 0;
};

type TListsComponent = {
    hideIndent?: boolean;
};

const ListsComponent: FC<TListsComponent> = ({ hideIndent }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, editorState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const onChange = (listType) => () => {
        return setCurrentState(listType);
    };
    const isIndentDisabled = getIsIndentDisabled(editorState);
    const isOutdentDisabled = getIsOutdentDisabled(editorState);

    return (
        <ButtonGroup className={theme.buttonGroup} data-test="wiki-toolbar-group_lists-button">
            <ToolbarButton
                tooltip={intl.formatMessage(messages.unorderedList)}
                spriteSymbol={icListBulleted}
                onClick={onChange(UNORDERED_LIST_ITEM)}
                selected={value === UNORDERED_LIST_ITEM}
                dataTest="wiki-toolbar-group_lists-button_bulleted"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.orderedList)}
                spriteSymbol={icListNumbered}
                onClick={onChange(ORDERED_LIST_ITEM)}
                selected={value === ORDERED_LIST_ITEM}
                dataTest="wiki-toolbar-group_lists-button_numbered"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.checkableList)}
                spriteSymbol={icCheckList}
                onClick={onChange(CHECKABLE_LIST_ITEM)}
                selected={value === CHECKABLE_LIST_ITEM}
                dataTest="wiki-toolbar-group_lists-button_checkList"
            />
            {!hideIndent && (
                <>
                    <ToolbarButton
                        tooltip={intl.formatMessage(messages.indent)}
                        spriteSymbol={icListIndentIncrease}
                        onClick={onChange('indent')}
                        disabled={isIndentDisabled}
                        dataTest="wiki-toolbar-group_lists-button_indent"
                    />
                    <ToolbarButton
                        tooltip={intl.formatMessage(messages.outdent)}
                        spriteSymbol={icListIndentDescrease}
                        onClick={onChange('outdent')}
                        disabled={isOutdentDisabled}
                        dataTest="wiki-toolbar-group_lists-button_outden"
                    />
                </>
            )}
        </ButtonGroup>
    );
};

export default ListsComponent;
