import {
    PRESET_SETTINGS_ADD_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_CREATE_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_GET_ALL_MATRIX_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_SUBMIT_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_MATRIX_MODEL_TYPE,
} from '@/actionsTypes/presetSettings/presetSettingsMatrixModelTypes.actionTypes';
import {
    TAddMatrixModelTypeAction,
    TAddMatrixModelTypePayload,
    TCreateMatrixModelTypeAction,
    TCreateMatrixModelTypePayload,
    TDeleteMatrixModelTypeAction,
    TDeleteMatrixModelTypePayload,
    TEditMatrixModelTypeAction,
    TEditMatrixModelTypePayload,
    TGetAllMatrixModelTypesSuccessAction,
    TGetAllMatrixModelTypesSuccessPayload,
    TSubmitMatrixModelTypeAction,
    TSubmitMatrixModelTypePayload,
    TTransferMatrixModelTypeAction,
    TTransferMatrixModelTypePayload,
} from './presetSettingsMatrixModelType.actions.types';

export const createMatrixModelType = (payload: TCreateMatrixModelTypePayload): TCreateMatrixModelTypeAction => ({
    type: PRESET_SETTINGS_CREATE_MATRIX_MODEL_TYPE,
    payload,
});

export const submitMatrixModelType = (payload: TSubmitMatrixModelTypePayload): TSubmitMatrixModelTypeAction => ({
    type: PRESET_SETTINGS_SUBMIT_MATRIX_MODEL_TYPE,
    payload,
});

export const getAllMatrixModelTypesSuccess = (
    payload: TGetAllMatrixModelTypesSuccessPayload,
): TGetAllMatrixModelTypesSuccessAction => ({
    type: PRESET_SETTINGS_GET_ALL_MATRIX_MODEL_TYPES_SUCCESS,
    payload,
});

export const addMatrixModelType = (payload: TAddMatrixModelTypePayload): TAddMatrixModelTypeAction => ({
    type: PRESET_SETTINGS_ADD_MATRIX_MODEL_TYPE,
    payload,
});

export const deleteMatrixModelType = (payload: TDeleteMatrixModelTypePayload): TDeleteMatrixModelTypeAction => ({
    type: PRESET_SETTINGS_DELETE_MATRIX_MODEL_TYPE,
    payload,
});

export const editMatrixModelType = (payload: TEditMatrixModelTypePayload): TEditMatrixModelTypeAction => ({
    type: PRESET_SETTINGS_EDIT_MATRIX_MODEL_TYPE,
    payload,
});

export const transferMatrixModelType = (payload: TTransferMatrixModelTypePayload): TTransferMatrixModelTypeAction => ({
    type: PRESET_SETTINGS_TRANSFER_MATRIX_MODEL_TYPE,
    payload,
});
