import type { FavoriteNodeDTO } from '@/serverapi/api';
import type {
    TFavoritesAddAction,
    TFavoritesFetchRequestAction,
    TFavoritesRemoveAction,
} from '@/actions/favorites.actions.types';
import { favoritesFetchSuccess } from '@/actions/favorites.actions';
import { showNotification } from '@/actions/notification.actions';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { put, takeEvery } from 'redux-saga/effects';
import { FAVORITES_FETCH_REQUEST, FAVORITES_ADD, FAVORITES_REMOVE } from '@/actionsTypes/favorites.actionTypes';
import { NotificationType } from '@/models/notificationType';
import { v4 as uuid } from 'uuid';

function* handleFavoritesFetchRequest({ payload: { serverId } }: TFavoritesFetchRequestAction) {
    const favoriteNodes: FavoriteNodeDTO[] = yield TreeDaoService.getFavorites(serverId);

    yield put(favoritesFetchSuccess(favoriteNodes));
}

function* handleFavoritesAdd({ payload: { nodeId } }: TFavoritesAddAction) {
    const updatedFavoriteNodes: FavoriteNodeDTO[] = yield TreeDaoService.addToFavorites(nodeId);

    yield put(favoritesFetchSuccess(updatedFavoriteNodes));
    yield put(showNotification({ id: uuid(), type: NotificationType.NODE_ADDED_TO_FAVORITES }));
}

function* handleFavoritesRemove({ payload: { nodeId } }: TFavoritesRemoveAction) {
    const updatedFavoriteNodes: FavoriteNodeDTO[] = yield TreeDaoService.removeFromFavorites(nodeId);

    yield put(favoritesFetchSuccess(updatedFavoriteNodes));
    yield put(showNotification({ id: uuid(), type: NotificationType.NODE_REMOVED_FROM_FAVORITES }));
}

export function* favoritesSaga() {
    yield takeEvery(FAVORITES_FETCH_REQUEST, handleFavoritesFetchRequest);
    yield takeEvery(FAVORITES_ADD, handleFavoritesAdd);
    yield takeEvery(FAVORITES_REMOVE, handleFavoritesRemove);
}

