import type { TBPMMxGraphOptions } from '../bpmgraph.types';
import type { BPMMxVertexHandler } from '../handler/BPMMxVertexHandler.class';
import { EditorMode } from '@/models/editorMode';
import { MxPopupMenu, MxCell, MxUtils, MxEvent, MxPoint, MxMouseEvent } from '../mxgraph';
import { DefaultGraph } from '../DefaultGraph';
import { GridDiagram } from './grid/GridDiagram';
import { DiagramElement, GridLayout, Symbol } from '@/serverapi/api';
import { BPMMxGraphHandler } from '../handler/BPMMxGraphHandler';
import GridModelGraphHandler from './handlers/GridModelGraphHandler.class';
import { showGridNotification } from './grid/SideEffects';
import { NotificationType } from '@/models/notificationType';
import { defaultGridLayout } from './grid/DefaultGridDIagram.config';
import { GridVertexHandler } from './handlers/GridVertexHandler.class';
import { SymbolType } from '@/models/Symbols.constants';
import { isCommentCell } from '@/utils/bpm.mxgraph.utils';
import { cellTreeWalkerUp } from '../ComplexSymbols/utils';
import { v4 as uuid } from 'uuid';
import { flatten } from 'lodash-es';

const minTableColumnWidth = 20;

export default class GridModelGraph extends DefaultGraph {
    protected grid: GridDiagram;
    protected resizeHeadersOnly = false;
    isHightLightDropUnavailableCells = true;

    constructor(options: TBPMMxGraphOptions & { mode?: EditorMode } = {}) {
        super(options);

        const name = this.modelType?.name;
        this.createGrid(options.gridLayout, name);
    }

    updateMouseEvent(me: MxMouseEvent, evtName: string): MxMouseEvent {
        const defaultMouseEvent = super.updateMouseEvent(me, evtName);
        try {
            const pt = MxUtils.convertPoint(this.container, defaultMouseEvent.getX(), defaultMouseEvent.getY());
            const edgeCell: MxCell = this.getCellAt(pt.x, pt.y, null, false, true);
            if (edgeCell) {
                defaultMouseEvent.state = this.view.getState(edgeCell);
            }

            return defaultMouseEvent;
        } catch (e) {
            console.error(e);

            return defaultMouseEvent;
        }
    }

    public createGrid(gridLayout: GridLayout = defaultGridLayout, name) {
        this.grid = new GridDiagram(this, gridLayout, name);
    }

    public renderEdges(target: MxCell) {
        this.grid?.renderEdges(target);
    }

    public isGridValidTarget({ target, symbolId, source }: { source?: MxCell; target: MxCell; symbolId?: string }) {
        return this.grid?.isGridValidTarget({ target, symbolId, source });
    }

    createEdge(parent: any, id: any, value: any, source: any, target: any, style: any) {
        const edge = super.createEdge(parent, id, value, source, target, style);

        if (!edge.visible) {
            return edge;
        }

        this.orderCells(true, [edge]);

        return edge;
    }

    createVertexHandler(state: any): BPMMxVertexHandler {
        return new GridVertexHandler(state) as BPMMxVertexHandler;
    }

    drawInvisibleEdges(sourceCell: MxCell) {
        // общее поведение для элементов
        super.drawInvisibleEdges(sourceCell);
        // специфика для автоматических связей этой модели
        this.grid?.drawInvisibleEdges(sourceCell);
    }

    dropFailedHandler(target: MxCell, symbol: Symbol) {
        if (target?.getId() === this.getDefaultParent().getId()) {
            showGridNotification(NotificationType.GRID_OUTSIDE_DROP_ERROR);
            return;
        }
        showGridNotification(NotificationType.PSD_DROP_NOT_SUPPORTED);
    }

    getDropTarget(cells: MxCell[] = [], evt: MouseEvent, cell: MxCell, clone?: boolean) {
        const target = super.getDropTarget.apply(this, arguments);
        const objectCells = cells.filter(this.isSupportedCell) || [];

        const diagramElements = cells.map((cell) => cell.getValue());
        const isAvailable = !objectCells.find((objectCell) => {
            return !this.isDropAvailable(diagramElements, target, objectCell?.getValue()?.symbolId);
        });

        if (isAvailable) {
            return target;
        }
    }

    public isDropAvailable(elements: DiagramElement[] = [], target: MxCell, symbolId: string) {
        if (!target) {
            return false;
        }

        if (symbolId === SymbolType.COMMENT) {
            return true;
        }
        const supportedElements = elements.filter((el) => this.isSupportedElement(el));
        const isCommentsMultiMove = supportedElements.every((e) => {
            return (e.type as SymbolType) === SymbolType.COMMENT;
        });
        if (isCommentsMultiMove) {
            return true;
        }

        if (supportedElements.length === 0) {
            return this.grid.isGridValidTarget({ target, symbolId });
        }

        return supportedElements.every((el: any) => {
            return this.grid.isGridValidTarget({ target, symbolId: el.symbolId });
        });
    }

    public loadPopupMenu(menu: MxPopupMenu, cell: MxCell, disabled: boolean = false) {
        super.loadPopupMenu(menu, cell);
        this.grid.loadPopupMenu(menu, cell, disabled);
    }

    public serializeGrid(): GridLayout {
        return this.grid.serialize();
    }

    public isSupportedCell(cell: MxCell): boolean {
        if (isCommentCell(cell)) {
            return true;
        }

        return cell.getValue()?.type === 'object' || cell.getValue()?.type === 'shape';
    }
    public isSupportedElement(element: DiagramElement) {
        return element.type === 'object';
    }

    public createGraphHandler(): BPMMxGraphHandler {
        return new GridModelGraphHandler(this);
    }

    public insertTableRow(cell: MxCell, before: boolean = false): MxCell | null {
        const model = this.getModel();
        model.beginUpdate();
        try {
            let table = cell;
            let row = cell;

            if (this.isTableCell(cell)) {
                row = model.getParent(cell);
                table = model.getParent(row);
            } else if (this.isTableRow(cell)) {
                table = model.getParent(cell);
            } else {
                const rows = model.getChildCells(table, true);
                row = rows[before ? 0 : rows.length - 1];
            }

            const cells = model.getChildCells(row, true);
            const index = table.getIndex(row);
            row = model.cloneCell(row, false);
            row.value = '';

            const rowGeo = this.getCellGeometry(row);

            if (rowGeo != null) {
                for (let i = 0; i < cells.length; i++) {
                    const cell = model.cloneCell(cells[i], false);
                    row.insert(cell);
                    cell.value = '';

                    const geo = this.getCellGeometry(cell);

                    if (geo != null) {
                        geo.height = rowGeo.height;
                    }
                }

                model.add(table, row, index + (before ? 0 : 1));

                let tableGeo = this.getCellGeometry(table);

                if (tableGeo != null) {
                    tableGeo = tableGeo.clone();
                    tableGeo.height += rowGeo.height;

                    model.setGeometry(table, tableGeo);
                }
            }
            model.endUpdate();

            return model.getCell(row.id);
        } catch (e) {
            console.error(e);
            model.endUpdate();

            return null;
        }
    }

    public insertTableColumn(cell: MxCell, before: boolean = false): MxCell[] | null {
        const model = this.getModel();
        model.beginUpdate();

        try {
            let table = cell;
            let index = 0;

            if (this.isTableCell(cell)) {
                const row = model.getParent(cell);
                table = model.getParent(row);
                index = MxUtils.indexOf(model.getChildCells(row, true), cell);
            } else {
                if (this.isTableRow(cell)) {
                    table = model.getParent(cell);
                } else {
                    [cell] = model.getChildCells(table, true);
                }

                if (!before) {
                    index = model.getChildCells(cell, true).length - 1;
                }
            }

            const rows = model.getChildCells(table, true);
            let dw = minTableColumnWidth;
            const columnCells: MxCell[] = [];

            for (let i = 0; i < rows.length; i++) {
                const child = model.getChildCells(rows[i], true)[index];
                const clone = model.cloneCell(child, false);
                const geo = this.getCellGeometry(clone);
                clone.value = '';

                if (geo != null) {
                    dw = geo.width;
                    const rowGeo = this.getCellGeometry(rows[i]);

                    if (rowGeo != null) {
                        geo.height = rowGeo.height;
                    }
                }

                model.add(rows[i], clone, index + (before ? 0 : 1));
                model.setNewIdToCell(clone, uuid());
                columnCells.push(clone);
            }

            let tableGeo = this.getCellGeometry(table);

            if (tableGeo != null) {
                tableGeo = tableGeo.clone();
                tableGeo.width += dw;

                model.setGeometry(table, tableGeo);
            }
            model.endUpdate();

            return columnCells;
        } catch (e) {
            console.error(e);
            model.endUpdate();

            return null;
        }
    }

    public deleteTableColumn(cell: MxCell) {
        const model = this.getModel();
        try {
            model.beginUpdate();

            const columnCells = this.getTableColumnCells(cell);
            const columnCellChildren = columnCells.map((c) => this.getAllEdges(c.children));
            const edges = flatten(columnCellChildren);

            this.removeCells(edges);
            super.deleteTableColumn(cell);
        } catch (e) {
            console.error(e);
        } finally {
            model.endUpdate();
        }
    }

    public getTableColumnCells(cell: MxCell): MxCell[] {
        const columnIndex = this.indexOfColumn(cell);
        const rows = this.getTableRows(cell);

        return rows.map((row: MxCell) => row.children[columnIndex]);
    }

    public deleteTableRow(cell: MxCell) {
        const model = this.getModel();
        try {
            model.beginUpdate();
            const rowCells = this.getTableRowCells(cell);

            const edges = rowCells.filter((c) => c?.children).map((c) => this.getAllEdges(c.children));

            this.removeCells(flatten(edges));
            super.deleteTableRow(cell);
        } catch (e) {
            console.error(e);
        } finally {
            model.endUpdate();
        }
    }

    public getTableRowCells(cell: MxCell) {
        const rowIndex = this.indexOfRow(cell);
        const rows = this.getTableRows(cell) || [];

        return rows[rowIndex].children || [];
    }

    public getLastEventCoordinates(): MxPoint {
        const evt = this.lastEvent;
        const pt = MxUtils.convertPoint(this.container, MxEvent.getClientX(evt), MxEvent.getClientY(evt));
        pt.x -= this.panDx;
        pt.y -= this.panDy;

        return pt;
    }

    public getLastEventTableTargetCell(): MxCell {
        const pt = this.getLastEventCoordinates();

        return this.getCellAt(pt.x, pt.y, null, true, false);
    }

    public getTable(cell: MxCell): MxCell | null {
        try {
            let tableRoot = cell;
            cellTreeWalkerUp(cell, (c) => {
                if (c.getStyle()?.includes('shape=table')) {
                    tableRoot = c;
                }
            });

            return tableRoot;
        } catch (e) {
            console.error(e);

            return null;
        }
    }

    public indexOfRow(cell: MxCell): number {
        try {
            const vertexOnly = this.getTableRows(cell);

            return Number(vertexOnly.indexOf(cell.parent));
        } catch (e) {
            return NaN;
        }
    }

    public getTableRows(cell: MxCell) {
        try {
            const { children } = cell.parent.parent;
            const vertexOnly = children.filter((child) => child.getStyle()?.includes('shape=tableRow'));

            return vertexOnly;
        } catch (e) {
            return [];
        }
    }

    public indexOfColumn(cell: MxCell): number {
        try {
            const vertexOnly = this.getTableColumns(cell);

            return Number(vertexOnly.indexOf(cell));
        } catch (e) {
            return NaN;
        }
    }

    public getTableColumns(cell: MxCell): MxCell[] {
        try {
            const { children } = cell.parent;
            const vertexOnly = children.filter((child) => child.getStyle()?.includes('shape=partialRectangle'));

            return vertexOnly;
        } catch (e) {
            return [];
        }
    }

    public getTableColumnHeader(cell: MxCell): MxCell[] {
        try {
            const table = this.getTable(cell);

            const header = table?.children[0].children;

            return header;
        } catch (e) {
            console.error(e);

            return [];
        }
    }

    public getTableRowHeader(cell: MxCell): MxCell[] {
        try {
            const rows = this.getTableRows(cell);

            return rows.map((row) => row.children[0]);
        } catch (e) {
            console.error(e);

            return [];
        }
    }
}
