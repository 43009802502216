import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { closeDialog, openDialog } from '../../../../actions/dialogs.actions';
import { openScriptDialog, scriptExecute } from '../../../../actions/entities/script.actions';
import { ScriptExecuteDialog } from './ScriptExecuteDialog.component';
import { TScriptExecuteDialogStoreProps } from './ScriptExecuteDialog.types';
import { NodeId, ScriptParameter } from '../../../../serverapi/api';
import {
    scriptExecuteDialogAddNodeId,
    scriptExecuteDialogDeleteNodeId,
    scriptExecuteDialogDeleteParamFiles,
    scriptExecuteDialogRequestSuccess,
} from '../../../../actions/scriptExecuteDialog.actions';
import { TScriptExecuteDialogFileParams } from '../../../../reducers/scriptExecuteDialog.reducer.types';

const mapStateToProps = (state: TRootState): Partial<TScriptExecuteDialogStoreProps> => ({
    fileParams: state.scriptExecuteDialog.fileParams,
    nodeParams: state.scriptExecuteDialog.nodeParams,
});

const mapDispatchToProps = (dispatch, props: TScriptExecuteDialogStoreProps) => ({
    onClose: (fileParams: TScriptExecuteDialogFileParams) => {
        dispatch(closeDialog(DialogType.SCRIPT_EXECUTE_DIALOG));
        dispatch(
            scriptExecuteDialogDeleteParamFiles(
                props.scriptId.serverId,
                Object.values(fileParams)
                    .filter((fileParam) => fileParam.uploadedFileName)
                    .map((fileParam) => fileParam.uploadedFileName!),
            ),
        );
    },
    onSubmit: (scriptId: NodeId, params: ScriptParameter[], parentOperationId?: string) => {
        dispatch(scriptExecute(scriptId, params, props.step, parentOperationId));
        dispatch(closeDialog(DialogType.SCRIPT_EXECUTE_DIALOG));
        dispatch(scriptExecuteDialogRequestSuccess());
    },

    onUploadFile: (serverId: string, paramName: string) => {
        dispatch(openDialog(DialogType.SCRIPT_UPLOAD_PARAM_FILE_DIALOG, { serverId, paramName }));
    },

    onAddNode: (paramName) => {
        dispatch(
            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                serverId: props.scriptId.serverId,
                onSubmit: (nodeId) => dispatch(scriptExecuteDialogAddNodeId(paramName, nodeId)),
                disableContextMenu: true,
            }),
        );
    },

    onDeleteNode: (paramName) => dispatch(scriptExecuteDialogDeleteNodeId(paramName)),

    onPreviousStep: (prevId: string, fileParams: TScriptExecuteDialogFileParams, serverId: string) => {
        dispatch(closeDialog(DialogType.SCRIPT_EXECUTE_DIALOG));
        dispatch(
            scriptExecuteDialogDeleteParamFiles(
                serverId,
                Object.values(fileParams)
                    .filter((fileParam) => fileParam.uploadedFileName)
                    .map((fileParam) => fileParam.uploadedFileName!),
            ),
        );
        dispatch(openScriptDialog(serverId, prevId));
    }
});

export const ScriptExecuteWithParamsDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ScriptExecuteDialog);
