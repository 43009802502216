import { LocalStorageKeys } from './LocalStorageDaoService';

export enum OpenTabStatus {
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    HAVE_AUTH_TAB = 'HAVE_AUTH_TAB',
}

type TListener = ((event: StorageEvent) => void) | null;

export class TabsBusDAOService {
    private static listener: TListener = null;

    public static subscribeToTabsBus(cb: Function) {
        this.listener = (event: StorageEvent) => {
            if (event.key === LocalStorageKeys.TABS_BUS_ACTION) {
                try {
                    cb(event.newValue);
                } catch (e) {
                    console.error('Tabs bus error:', e);
                }
            }
        };
        window.addEventListener('storage', this.listener);
    }

    public static unsubscribeFromTabsBus() {
        if (this.listener) {
            window.removeEventListener('storage', this.listener);
        }
    }
}
