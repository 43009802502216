import { DiagramElement, EdgeInstance, EdgeType, ModelType, ObjectInstance, Symbol } from '@/serverapi/api';
import { getSymbolsFromModelType } from '@/utils/symbol.utils';
import { TCheckElementsBeforePasteResult, TReplaceElementsData } from './PasteElementsBLL.types';
import { CellTypes } from '@/utils/bpm.mxgraph.utils';
import { NotificationType } from '@/models/notificationType';
import { NotationHelper } from '../utils/NotationHelper';

export class PasteElementsBLL {
    public static checkElementsBeforePaste(
        diagramElements: DiagramElement[],
        allMethodologySymbols: Symbol[],
        allMethodologyEdgeTypes: EdgeType[],
        modelType: ModelType,
        serverId: string,
    ): TCheckElementsBeforePasteResult {
        const allowedSymbols: Symbol[] = getSymbolsFromModelType(modelType);
        const allowedSymbolsIds = allowedSymbols.map((symbol) => symbol.id);
        const replaceElementsData: TReplaceElementsData = {
            elementIdsToBeReplaced: [],
            symbols: [],
        };

        let symbolIdToBeReplaced: string | undefined;

        for (const element of diagramElements) {
            const isShape = element.type === CellTypes.Shape;
            const isEdge = element.type === CellTypes.Edge;
            const isObject = element.type === CellTypes.Object;

            if (isShape) continue;

            if (isEdge) {
                const source: DiagramElement = diagramElements.find(
                    (diagramElement) => diagramElement.id === (element as EdgeInstance).source,
                )!;
                const target: DiagramElement = diagramElements.find(
                    (diagramElement) => diagramElement.id === (element as EdgeInstance).target,
                )!;
                const edgeType: EdgeType | undefined = allMethodologyEdgeTypes.find(
                    (edgeType) => edgeType.id === (element as EdgeInstance).edgeTypeId,
                );
                const edgeCheckResult: string | null = NotationHelper.validateEdge(
                    source as ObjectInstance,
                    target as ObjectInstance,
                    modelType,
                    serverId,
                    edgeType,
                );

                if (edgeCheckResult === null) continue;

                return {
                    replaceElementsData,
                    errorNotification: NotificationType.EDGES_OR_EDGE_BETWEEN_SYMBOLS_NOT_SUPPORTED_ON_MODEL_TYPE,
                };
            }

            if (isObject) {
                const isAllowedSymbol =
                    allowedSymbolsIds.includes((element as ObjectInstance).symbolId) || modelType.allowAnyObject;

                if (!isAllowedSymbol) {
                    // проверка на то что можно заменить только один символ
                    if (symbolIdToBeReplaced && symbolIdToBeReplaced !== (element as ObjectInstance).symbolId)
                        return {
                            replaceElementsData,
                            errorNotification: NotificationType.SYMBOLS_OR_EDGES_NOT_SUPPORTED_ON_MODEL_TYPE,
                        };

                    const objectTypeId: string | undefined = allMethodologySymbols.find(
                        (symbol) => symbol.id === (element as ObjectInstance).symbolId,
                    )?.objectType;
                    const symbolsToSelectFrom: Symbol[] = allowedSymbols.filter(
                        (symbol) => symbol.objectType === objectTypeId,
                    );

                    if (!symbolsToSelectFrom.length)
                        return {
                            replaceElementsData,
                            errorNotification: NotificationType.NO_VALID_SYMBOL_ON_MODEL_TYPE,
                        };

                    symbolIdToBeReplaced = (element as ObjectInstance).symbolId;
                    replaceElementsData.symbols = symbolsToSelectFrom;
                    replaceElementsData.elementIdsToBeReplaced.push(element.id);
                }
            }
        }

        return {
            replaceElementsData,
            errorNotification: undefined,
        };
    }

    public static replaceSymbolsInCopiedElements(
        selectedSymbol: Symbol,
        copiedElements: DiagramElement[],
        elementIdsToBeReplaced: string[],
    ): DiagramElement[] {
        return copiedElements.map((element) => {
            if (elementIdsToBeReplaced.includes(element.id)) {
                return {
                    ...element,
                    symbolId: selectedSymbol.id,
                    labelStyle: selectedSymbol.labelStyle,
                    style: `${selectedSymbol.id};${selectedSymbol.style}`,
                    ignorePresetStyles: undefined,
                };
            }
            return element;
        });
    }
}
