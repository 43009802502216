import { VerticalLine } from './VerticalLine';
import { HorizontalLine } from './HorizontalLine';
import { UMLFrameTitle } from './UMLFrameTitle';
import { MxCellRenderer, MxRectangleShape, MxShape } from '../mxgraph';
import {
    SequenceLifeline,
    SequenceBounderyLifeline,
    SequenceEntityLifeline,
    SequenceControlLifeline,
    SequenceActorLifeline,
} from './LifeLine';
import { DestroyAttribute } from './DestroyAttribute';
import { UMLNote } from './UMLNote';
import { SequenceSymbolShapes } from '../ComplexSymbols/symbols/ComplexSymbol.constants';
import { UMLMessage } from './UMLMessage';

type TCustomShapeDefinition = { name: string; shape: typeof MxShape | typeof MxRectangleShape };

export const shapes: TCustomShapeDefinition[] = [
    { name: 'v-line-dashed', shape: VerticalLine },
    { name: 'h-line-dashed', shape: HorizontalLine },
    { name: SequenceSymbolShapes.UML_NOTE_SHAPE, shape: UMLNote },
    { name: SequenceSymbolShapes.UML_MESSAGE_SHAPE, shape: UMLMessage },
    { name: SequenceSymbolShapes.UML_FRAME_TITLE, shape: UMLFrameTitle },
    { name: SequenceSymbolShapes.LIFE_LINE, shape: SequenceLifeline },
    { name: SequenceSymbolShapes.LIFE_LINE_BOUNDERY, shape: SequenceBounderyLifeline },
    { name: SequenceSymbolShapes.LIFE_LINE_ENTITY, shape: SequenceEntityLifeline },
    { name: SequenceSymbolShapes.LIFE_LINE_CONTROL, shape: SequenceControlLifeline },
    { name: SequenceSymbolShapes.LIFE_LINE_ACTOR, shape: SequenceActorLifeline },
    { name: SequenceSymbolShapes.DESTROY_ATTRIBUTE, shape: DestroyAttribute },
];

export const registerShapes = (shapeDefinitions: TCustomShapeDefinition[]) => {
    shapeDefinitions.forEach((shape) => MxCellRenderer.registerShape(shape.name, shape.shape));
};
