import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../messages/ChoosePrincipalPanel.messages';
import { Alert, Table, Form } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import theme from './ChoosePrincipalsPanel.scss';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import PrincipalLabel from '../PrincipalLabel/PrincipalLabel.component';
import SearchBar from '../SearchBar/SearchBar.component';
import { sortByAlpha } from '../../../../utils/sortByAlpha';

type TChoosePrincipalsPanelProps = {
    loading: boolean;
    error: string;
    principals: PrincipalDescriptor[];
    selectedPrincipals: PrincipalDescriptor[];
    onSelectionChanged: (selectedRows: PrincipalDescriptor[]) => void;
};

const filterPrincipals = (filter: string) => (p: PrincipalDescriptor) =>
    !filter || ((p.login || '') + (p.name || '')).toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1;

export const ChoosePrincipalsPanel: FC<TChoosePrincipalsPanelProps> = (props) => {
    const intl = useIntl();
    const { loading, error, principals, selectedPrincipals, onSelectionChanged } = props;

    const [filter, setFilter] = useState<string>('');

    const filtredPrincipals: PrincipalDescriptor[] = principals.filter(filterPrincipals(filter));

    const columns: ColumnProps<PrincipalDescriptor>[] = [
        {
            title: intl.formatMessage(messages.columnName),
            dataIndex: 'name',
            sorter: (a: PrincipalDescriptor, b: PrincipalDescriptor) => sortByAlpha(a.login, b.login),
            render: (value: string, record: PrincipalDescriptor) => {
                return <PrincipalLabel {...record} />;
            },
        },
    ];

    const handleOnSearch = (newFilter: string) => {
        setFilter(newFilter);
    };

    const filtredPrincipalsIds: number[] = filtredPrincipals.map((p) => p.id);
    // необходимо запоминать пользователей, которые были выбраны, но скрыты фильтром
    const selectedHiddenPrincipals: PrincipalDescriptor[] = selectedPrincipals.filter(
        (p) => !filtredPrincipalsIds.includes(p.id),
    );

    const handleRowSelectionChange = (newPrincipalKeys: string[], newPrincipals: PrincipalDescriptor[]) => {
        onSelectionChanged([...selectedHiddenPrincipals, ...newPrincipals]);
    };

    const selectedPrincipalsIds: string[] = selectedPrincipals.map((p) => `${p.id}`);

    const rowSelection: TableRowSelection<PrincipalDescriptor> = {
        type: 'checkbox',
        selectedRowKeys: selectedPrincipalsIds,
        onChange: handleRowSelectionChange,
    };

    return (
        <FormGroup className={theme.formGroup}>
            {error && <Alert type="error" message={error} showIcon />}

            <Form.Item>
                <SearchBar
                    value={filter}
                    placeholder={intl.formatMessage(messages.searchPlaceholder)}
                    disabled={loading}
                    onSearch={handleOnSearch}
                />
            </Form.Item>
            <Form.Item data-test="permission-dialog_principal-table">
                <Table
                    rowKey={(record: PrincipalDescriptor) => `${record.id}`}
                    dataSource={filtredPrincipals}
                    rowSelection={rowSelection}
                    columns={columns}
                    pagination={false}
                    locale={{ emptyText: intl.formatMessage(messages.emptyTable) }}
                    loading={loading}
                    size="small"
                    className={theme.table}
                    bordered
                    scroll={{ y: 380 }}
                />
            </Form.Item>
        </FormGroup>
    );
};
