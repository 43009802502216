import { EditButton, DeleteButton, RowButtons } from '@/modules/AdminTools/Button/RowButtons';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { NotificationTemplateDTO } from '@/serverapi/api';
import { Col, Input, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import theme from './NotificationTemplatesList.scss';
import messages from './NotificationTemplatesList.messages';
import { SearchOutlined } from '@ant-design/icons';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { deleteNotificationTemplate } from '@/actions/notificationTemplates.actions';
import { TTableRowData } from './NotificationTemplatesList.types';
import { getFiltredRows } from './NotificationTemplatesList.utils';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TNotificationTemplatesListProps = {
    notificationTemplates: NotificationTemplateDTO[];
};

export const NotificationTemplatesList: FC<TNotificationTemplatesListProps> = (props) => {
    const { notificationTemplates } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const [searchInput, setSearchInput] = useState<string>('');
    const containerElem = useRef<HTMLDivElement>(null);
    const headerElem = useRef<HTMLDivElement>(null);

    const currentLocale = useSelector(getCurrentLocale);

    const createHandler = () => {
        dispatch(openDialog(DialogType.NOTIFICATION_TEMPLATES_DIALOG));
    };

    const deleteHandler = (templateId: string) => {
        dispatch(deleteNotificationTemplate(templateId));
    };
    const editHandler = (templateId: string) => {
        const templalteForEdit = notificationTemplates.find((template) => template.id === templateId);
        dispatch(
            openDialog(DialogType.NOTIFICATION_TEMPLATES_DIALOG, { isEditing: true, templateData: templalteForEdit }),
        );
    };

    const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== searchInput) {
            setSearchInput(e.target.value.trim().toLowerCase());
        }
    };

    const columns: ColumnsType<TTableRowData> = [
        {
            title: intl.formatMessage(messages.id),
            dataIndex: 'id',
            width: '20%',
        },
        {
            title: intl.formatMessage(messages.name),
            dataIndex: 'name',
            width: '70%',
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: 'buttons',
            width: '10%',
            align: 'right',
            render: (value, record) => (
                <RowButtons
                    className={theme.rowButtons}
                    buttons={[
                        EditButton.build(() => editHandler(record.id), undefined, `${record.id}_editBtn`),
                        DeleteButton.build(
                            () => deleteHandler(record.id),
                            undefined,
                            `${record.id}_delBtn`,
                            undefined,
                            intl.formatMessage(messages.delete),
                            intl.formatMessage(messages.deleteMessage, { name: record.name }),
                        ),
                    ]}
                />
            ),
        },
    ];

    const rows: TTableRowData[] = getFiltredRows(notificationTemplates, searchInput, currentLocale);

    let tableHeight: number = 700;

    if (containerElem.current && headerElem.current) {
        tableHeight = containerElem.current.clientHeight - headerElem.current.clientHeight - 100;
    }

    return (
        <Col className={theme.container} ref={containerElem}>
            <Row className={theme.row} ref={headerElem}>
                <Input className={theme.searchInput} suffix={<SearchOutlined />} onChange={searchHandler} />
                <Button onClick={createHandler} dataTest='create-notification-template' visualStyle="primary">
                    {intl.formatMessage(messages.create)}
                </Button>
            </Row>
            <Row className={theme.row}>
                <Table scroll={{ y: tableHeight }} pagination={false} columns={columns} dataSource={rows} />
            </Row>
        </Col>
    );
};
