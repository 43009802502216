import {
    CLOSE_MONITORING_TAB,
    DELETE_MONITORING_STATUS,
    LOAD_MONITORING_INFO,
    LOAD_MONITORING_INFO_SUCCESS,
    MONITORING_INFO_LOADING,
    OPEN_MONITOR_TAB,
} from '../actionsTypes/monitoring.actionTypes';
import { TreeNode } from '../models/tree.types';
import { SystemStatus } from '../serverapi/api';
import {
    TCloseMonitoringTab,
    TDeleteMonitoringStatus,
    TLoadStatusAction,
    TOpenMonitoringTabAction,
    TSaveMonitoringStatus,
    TSetMonitoringStatusLoading,
} from './monitoring.actions.types';

export const closeMonitoringTab = (): TCloseMonitoringTab => ({
    type: CLOSE_MONITORING_TAB,
});

export const openMonitoringTab = (node: TreeNode): TOpenMonitoringTabAction => ({
    type: OPEN_MONITOR_TAB,
    payload: { node },
});

export const loadMonitoringStatus = (serverId): TLoadStatusAction => ({
    type: LOAD_MONITORING_INFO,
    payload: {
        serverId,
    },
});

export const saveMonitoringStatus = (serverId: string, status: SystemStatus): TSaveMonitoringStatus => ({
    type: LOAD_MONITORING_INFO_SUCCESS,
    payload: {
        status,
        serverId,
    },
});

export const setMonitoringStatusLoading = (serverId: string, isLoading: boolean): TSetMonitoringStatusLoading => ({
    type: MONITORING_INFO_LOADING,
    payload: {
        isLoading,
        serverId,
    },
});

export const deleteMonitoringStatus = (serverId: string): TDeleteMonitoringStatus => ({
    type: DELETE_MONITORING_STATUS,
    payload: {
        serverId,
    },
});
