import electron from '../electron';

export function toClipboard(blob) {
    if (navigator.clipboard && navigator.clipboard.write) {
        navigator.clipboard.write([
            new ClipboardItem({
                [blob.type]: blob,
            }),
        ]);

        return true;
    }

    return false;
}

export const toClipboardText = (text: string): string | undefined => {
    if (electron?.clipboard) {
        electron.clipboard.writeText(text);
    } else if (navigator?.clipboard) {
        navigator.clipboard.writeText(text);
    } else {
        return undefined;
    }

    return text;
};

export async function getIsClipboardGranted(): Promise<boolean> {
    try {
        const queryPromises = ['clipboard-read', 'clipboard-write'].map((name: PermissionName) =>
            navigator.permissions.query({ name }),
        );
        const permissions = await Promise.all(queryPromises);

        return permissions.every(({ state }) => ['granted', 'prompt'].includes(state));
    } catch {
        return false;
    }
}

export function getIsClipboardSupported(): boolean {
    return Boolean(window.navigator?.clipboard);
}

export async function getIsClipboardAvailable(): Promise<boolean> {
    const isGranted: boolean = await getIsClipboardGranted();
    const isSupported: boolean = getIsClipboardSupported();

    return isGranted && isSupported;
}

