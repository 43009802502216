import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'InsertSpaceSelectDialog.title',
        defaultMessage: 'Пространство',
    },
    horizontally: {
        id: 'InsertSpaceSelectDialog.horizontally',
        defaultMessage: 'По горизонтали',
    },
    vertically: {
        id: 'InsertSpaceSelectDialog.vertically',
        defaultMessage: 'По вертикали',
    },
    deleteHorizontally: {
        id: 'InsertSpaceSelectDialog.deleteHorizontally',
        defaultMessage: 'Удалить по горизонтали',
    },
    deleteVertically: {
        id: 'InsertSpaceSelectDialog.deleteVertically',
        defaultMessage: 'Удалить по вертикали',
    },
    onlyInEditModeAvailable: {
        id: 'InsertSpaceSelectDialog.onlyInEditModeAvailable',
        defaultMessage: 'Доступно только в режиме редактирования',
    },
});
