import { TReducer } from '../../utils/types';
import { TNodeState } from './TNodeState';
import {
    KANBAN_REMOVE,
    KANBAN_ADD,
    KANBAN_SET_NODE,
    KANBAN_RENAME,
} from '../../actionsTypes/entities/kanban.actionTypes';
import { TKanbanNodeState } from './kanban.reducer.types';
import { TKanbanState } from '@/models/kanban.types';

const INITIAL_KANBAN_STATE: TKanbanNodeState = new TNodeState();

export const kanbanReducer: TReducer<TKanbanNodeState> = (state = INITIAL_KANBAN_STATE, action) => {
    switch (action.type) {
        case KANBAN_ADD: {
            const {
                payload: { kanban },
            } = action;

            return state.set(kanban.node.nodeId, kanban);
        }

        case KANBAN_REMOVE: {
            const nodeId = action.payload.nodeId;
            return state.delete(nodeId);
        }

        case KANBAN_SET_NODE: {
            const kanban = state.get(action.payload.node.nodeId);
            let updatedKanban: TKanbanState;

            if (kanban) {
                updatedKanban = { ...kanban, node: { ...kanban.node, ...action.payload.node } };
            } else {
                updatedKanban = {
                    node: action.payload.node,
                    columnIdToColumnItemIdsMap: {},
                    columnItems: {},
                };
            }

            return state.set(action.payload.node.nodeId, updatedKanban);
        }

        case KANBAN_RENAME: {
            const {
                payload: { nodeId, title, locale },
            } = action;
            const kanban = state.get(nodeId);
            if (!kanban) return state;

            const updatedKanban = {
                ...kanban,
                node: {
                    ...kanban.node,
                    name: title,
                    multilingualName: {
                        ...kanban.node.multilingualName,
                        [locale]: title,
                    },
                },
            };

            return state.set(nodeId, updatedKanban);
        }

        default:
            return state;
    }
};
