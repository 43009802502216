import React, { FC } from 'react';
import cx from 'classnames';
import theme from '../items/MenuItem.scss';
import messages from '../../messages/CommonToolbar.messages';
import { FontFamilySelect } from '../FontFamilySelect/FontFamilySelect.component';
import { FontSizeSelect } from '../FontSizeSelect/FontSizeSelect.component';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getGeneralMenuButtonsState } from '../../../../selectors/generalMenu.selectors';
import { LabelStyle } from '../../../../models/labelStyle';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';

type TGraphObjectToolbarFontSettingsProps = {
    disabled: boolean;
    isShapeWithPicture: boolean;
};

export const GraphObjectToolbarFontSettings: FC<TGraphObjectToolbarFontSettingsProps> = ({
    disabled,
    isShapeWithPicture,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const buttonEditLabelState = useSelector(getGeneralMenuButtonsState);
    const fontFamilyDisabled = !buttonEditLabelState.fontFamily || disabled || isShapeWithPicture;
    const fontSizeDisabled = !buttonEditLabelState.fontSize || disabled || isShapeWithPicture;

    const onChange = (labelStyle: LabelStyle) => (value: string) => {
        dispatch(generalMenuLabelStyleChangeAction(labelStyle, value, false));
    };

    return (
        <div className={cx(theme.group, theme.inline)}>
            <div className={cx(theme.groupRow, theme.inline)}>
                <div className={theme.tooltipContainer} data-test="object-toolbar_font-family-select">
                    <FontFamilySelect
                        onChange={onChange(LabelStyle.fontFamily)}
                        value={buttonEditLabelState.fontFamily || ''}
                        className={theme.selectFont}
                        disabled={fontFamilyDisabled}
                        renderNewOption={false}
                        tooltipTitle={
                            !fontFamilyDisabled
                                ? intl.formatMessage(messages.fontFamily)
                                : intl.formatMessage(messages.selectEdgeOrObject)
                        }
                    />
                </div>
            </div>
            <div className={theme.groupRow}>
                <div className={theme.groupRow}>
                    <div className={theme.tooltipContainer} data-test="object-toolbar_font-size-select">
                        <FontSizeSelect
                            onChange={onChange(LabelStyle.fontSize)}
                            value={buttonEditLabelState.fontSize || ''}
                            disabled={fontSizeDisabled}
                            className={theme.selectFontSize}
                            tooltipTitle={
                                !fontSizeDisabled
                                    ? intl.formatMessage(messages.fontSize)
                                    : intl.formatMessage(messages.selectEdgeOrObject)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
