// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditModeButton__modeGroup_full__SVsuS{margin:13px 4px 0 4px}.EditModeButton__modeGroup_compact__zdTIQ{margin-top:-4px;margin-left:4px}.EditModeButton__customTooltip__ihZrs{white-space:nowrap}.EditModeButton__customTooltip__ihZrs>.ant-tooltip-content .ant-tooltip-inner{display:inline-block;min-height:24px;padding-top:2px;padding-bottom:2px}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/EditModeButton/EditModeButton.scss"],"names":[],"mappings":"AAAA,uCACI,qBAAA,CAGJ,0CACI,eAAA,CACA,eAAA,CAGJ,sCACI,kBAAA,CACA,8EACI,oBAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".modeGroup_full {\r\n    margin: 13px 4px 0 4px;\r\n}\r\n\r\n.modeGroup_compact {\r\n    margin-top: -4px;\r\n    margin-left: 4px;\r\n}\r\n\r\n.customTooltip {\r\n    white-space: nowrap;\r\n    & > :global(.ant-tooltip-content .ant-tooltip-inner) {\r\n        display: inline-block;\r\n        min-height: 24px;\r\n        padding-top: 2px;\r\n        padding-bottom: 2px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modeGroup_full": "EditModeButton__modeGroup_full__SVsuS",
	"modeGroup_compact": "EditModeButton__modeGroup_compact__zdTIQ",
	"customTooltip": "EditModeButton__customTooltip__ihZrs"
};
export default ___CSS_LOADER_EXPORT___;
