import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { PrincipalPermissions } from '../serverapi/api';
import { TAllPermissionsDialogState } from '../reducers/allPermissions.reducer.types';

const getAllPermissionsDialogState = (state: TRootState) => state.allPermissions;

export const getAllPermissions = createSelector(getAllPermissionsDialogState, (state) => state.data);

export const getServerId = createSelector(getAllPermissionsDialogState, (state) => state.serverId);

export const getAllPermissionsWithLeastOneGranting = createSelector<
    TRootState,
    PrincipalPermissions[] | null,
    PrincipalPermissions[]
>(
    getAllPermissions,
    (state) => state?.filter((permission) => permission.permissions?.some((el) => el.isGranting)) || [],
);

export const getAllPermissionsIsLoading = createSelector<TRootState, TAllPermissionsDialogState, boolean>(
    getAllPermissionsDialogState,
    (state) => state.loading,
);

export const getAllPermissionsError = createSelector<TRootState, TAllPermissionsDialogState, string | null>(
    getAllPermissionsDialogState,
    (state) => state.error,
);
