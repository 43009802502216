import React, { FC } from 'react';

type TIconInputServerProps = {
    color: string;
    className?: string;
};

const IconInputPasswordComponent: FC<TIconInputServerProps> = ({ color, className }) => {
    return (
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                    fill={color}
                    fillRule="evenodd"
                    d="M12 13H4a1 1 0 0 1-1.001-1V6A1 1 0 0 1 4 4.999h1V4a3 3 0 1 1 5.999 0v.999H12A1 1 0 0 1 13 6v6a1 1 0 0 1-1 1zM9.999 4a2 2 0 1 0-4 0v.999h4V4zM12 6H4v6h8V6zM7.999 7.999a1.001 1.001 0 0 1 0 2.001 1 1 0 0 1 0-2.001z"
                />
                <path fill="none" d="M0 0h16v16H0V0z" />
            </svg>
        </div>
    );
};

export default IconInputPasswordComponent;
