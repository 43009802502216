import { defineMessages } from 'react-intl';

export default defineMessages({
    approvalIsNotCreatedYet: {
        id: 'ApprovalsTab.approvalIsNotCreatedYet',
        defaultMessage: 'Согласование еще не создано',
    },
    createApproval: {
        id: 'ApprovalsTab.createApproval',
        defaultMessage: 'Создать согласование',
    },
    newApproval: {
        id: 'ApprovalsTab.newApproval',
        defaultMessage: 'Новое согласование',
    },
    approval: {
        id: 'ApprovalsTab.approval',
        defaultMessage: 'Согласование',
    },
    stageInProgessLable: {
        id: 'ApprovalsTab.stageInProgessLable',
        defaultMessage: 'Проголосовали {votedUsersCount} из {usersCount}',
    },
    approved: {
        id: 'ApprovalsTab.approved',
        defaultMessage: 'Согласовано',
    },
    notApproved: {
        id: 'ApprovalsTab.notApproved',
        defaultMessage: 'Не согласовано',
    },
    notVoted: {
        id: 'ApprovalsTab.notVoted',
        defaultMessage: 'Воздержался',
    },
    waitingForVote: {
        id: 'ApprovalsTab.waitingForVote',
        defaultMessage: 'Ожидание голоса',
    },
    typeComment: {
        id: 'ApprovalsTab.typeComment',
        defaultMessage: 'Укажите комментарий',
    },
    at: {
        id: 'ApprovalsTab.at',
        defaultMessage: 'в',
    },
    approvalPeriod: {
        id: 'ApprovalsTab.approvalPeriod',
        defaultMessage: 'Срок согласования',
    },
    stageFinished: {
        id: 'ApprovalsTab.stageFinished',
        defaultMessage: 'Этап завершён',
    },
    send: {
        id: 'ApprovalsTab.send',
        defaultMessage: 'Отправить',
    },
    chooseTemplate: {
        id: 'ApprovalsTab.chooseTemplate',
        defaultMessage: 'Выбрать шаблон',
    },
    edit: {
        id: 'ApprovalsTab.edit',
        defaultMessage: 'Редактировать',
    },
    copyLink: {
        id: 'ApprovalsTab.copyLink',
        defaultMessage: 'Скопировать ссылку',
    },
    selectUser: {
        id: 'ApprovalsTab.selectUser',
        defaultMessage: 'Выберите пользователя',
    },
    addAssistant: {
        id: 'ApprovalsTab.addAssistant',
        defaultMessage: 'Добавить заместителя',
    },
    commentRequired: {
        id: 'ApprovalsTab.commentRequired',
        defaultMessage: 'Отсутствует комментарий',
    },
    commentRequiredDescription: {
        id: 'ApprovalsTab.commentRequiredDescription',
        defaultMessage: 'Комментарий должен быть заполнен',
    },
});
