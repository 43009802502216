import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { ApiBundle } from '../api/api-bundle';
import { NodeId, StubResponse } from '@/serverapi/api';

export class ModelDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    /**
     * Проверяет права пользователя на печать
     * @param nodeId
     * @returns Promise<string>
     */
    public static async checkPrint(nodeId: NodeId): Promise<StubResponse> {
        const api = this.getApi();

        const result = await api.model.checkPrint({
            repositoryId: nodeId.repositoryId,
            modelId: nodeId.id,
        });
        return result;
    }

    public static async getPng(nodeId: NodeId): Promise<void> {
        const api = this.getApi();

        const result = await api.model.getModelPng({
            repositoryId: nodeId.repositoryId,
            modelId: nodeId.id,
        });

        return result;
    }

    public static async getImage(nodeId: NodeId, format: string): Promise<void> {
        const api = this.getApi();

        const result = await api.model.getModelImage({
            repositoryId: nodeId.repositoryId,
            modelId: nodeId.id,
            format,
        });

        return result;
    }
}
