import { call, put, select, takeEvery } from 'redux-saga/effects';
import { openDialog } from '../actions/dialogs.actions';
import { VIEW_MODEL_PROPERTY } from '../actionsTypes/modelProperty.actionTypes';
import { TViewPropertiesAction } from '../actions/modelProperty.actions.types';
import { IModelNode } from '../models/bpm/bpm-model-impl.types';
import { ObjectPropertiesDialogActiveTab } from '../models/objectPropertiesDialog';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TreeItemType } from '../modules/Tree/models/tree';
import { ModelTypeSelectors } from '../selectors/modelType.selectors';
import { TreeSelectors } from '../selectors/tree.selectors';
import { AttributeType, Node } from '../serverapi/api';
import { nodeService } from '../services/NodeService';
import { loadNodeApprovals } from '@/actions/approval.actions';
import { COPY_MODEL_LINK, MODEL_PUBLISH, MODEL_UNPUBLISH } from '@/actionsTypes/editor.actionTypes';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { TCopyModelLinkAction, TModelPublishAction, TModelUnpublishAction } from '@/actions/editor.actions.types';
import { copyModelImageLinkAction } from '@/actions/copyLinkDialog.actions';

function* handlePropertyView({
    payload,
    payload: {
        nodeId,
        nodeId: { serverId, id, repositoryId },
    },
}: TViewPropertiesAction) {
    const [pathRes, nodeRes] = yield Promise.allSettled([
        TreeDaoService.getNodePath(serverId, id, repositoryId),
        nodeService().loadNodeFromServer(nodeId),
    ]);

    const { path } = pathRes.value;
    const node: Node | undefined = nodeRes.value;

    if (!node) {
        return;
    }

    const modelTypeId: string | undefined = (node as IModelNode).modelTypeId || TreeItemType.Matrix.toLowerCase();
    let attributeTypes: Array<AttributeType> = [];
    if (modelTypeId) {
        const presetId: string = yield select(TreeSelectors.presetById(nodeId));
        (node as IModelNode).modelType = yield select(ModelTypeSelectors.byId({ modelTypeId, serverId }, presetId));
        attributeTypes = (node as IModelNode).modelType?.attributes || [];

        if ((node as IModelNode).modelType?.allowApprovals) {
            yield put(loadNodeApprovals({ nodeId }));
        }
    }

    yield put(
        openDialog(DialogType.PROPERTIES_DIALOG, {
            node,
            path,
            attributeTypes,
            tab:
                (payload.activeTab as ObjectPropertiesDialogActiveTab) ||
                ObjectPropertiesDialogActiveTab.NameAndAttributes,
            serverId,
        }),
    );
}

function* handleTreeNodePublish({ payload: { nodeId } }: TModelPublishAction) {
    yield call(() => TreeDaoService.publish(nodeId));
}

function* handleTreeNodeUnpublish({ payload: { nodeId } }: TModelUnpublishAction) {
    yield call(() => TreeDaoService.unpublish(nodeId));
}

function* handleCopyModelLink({ payload: { nodeId } }: TCopyModelLinkAction) {
    yield put(copyModelImageLinkAction(nodeId));
}

export function* initModelPropertyDialog() {
    yield takeEvery(VIEW_MODEL_PROPERTY, handlePropertyView);
    yield takeEvery(MODEL_PUBLISH, handleTreeNodePublish);
    yield takeEvery(MODEL_UNPUBLISH, handleTreeNodeUnpublish);
    yield takeEvery(COPY_MODEL_LINK, handleCopyModelLink);
}
