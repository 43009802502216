import * as React from 'react';
import { FC } from 'react';
import theme from './MainMenuContent.scss';
import { MainMenuItems } from '../../../../models/mainMenu';
import { MAIN_MENU_CONTENT_ID } from '../../../../mxgraph/components/BPMPopupMenu.types';

type TMainMenuProps = {
    children?: React.ReactNode;
    selectedKey?: MainMenuItems;
} & JSX.IntrinsicAttributes;

export const MainMenuContent: FC<TMainMenuProps> = ({ children, selectedKey }) => {
    const childList = React.Children.toArray(children);

    const selectedItem = childList.find((elem: JSX.Element) => {
        return elem.props.itemKey === selectedKey;
    });

    return <menu data-test="graph-general_container" className={theme.container} id={MAIN_MENU_CONTENT_ID}>{selectedItem}</menu>;
};
