import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'CreateInvisibleEdgeDialog.title',
        defaultMessage: 'Создание невидимой связи',
    },
    description: {
        id: 'CreateInvisibleEdgeDialog.description',
        defaultMessage: 'Для данных объектов, при наложении друг на друга, доступны невидимые связи, создать?',
    },
    createEdge: {
        id: 'CreateInvisibleEdgeDialog.createEdge',
        defaultMessage: 'Создать связь',
    },
    dontCreateEdge: {
        id: 'CreateInvisibleEdgeDialog.dontCreateEdge',
        defaultMessage: 'Не создавать связь',
    },
    fromObject: {
        id: 'CreateInvisibleEdgeDialog.fromObject',
        defaultMessage: 'От объекта',
    },
    edgeType: {
        id: 'CreateInvisibleEdgeDialog.edgeType',
        defaultMessage: 'Тип связи',
    },
    toObject: {
        id: 'CreateInvisibleEdgeDialog.toObject',
        defaultMessage: 'К объекту',
    },
    save: {
        id: 'CreateInvisibleEdgeDialog.save',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'CreateInvisibleEdgeDialog.cancel',
        defaultMessage: 'Отмена',
    },
});
