import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'CloneCloneModelDialog.title',
        defaultMessage: 'Создание копии модели'
    },
    confirm: {
        id: 'CloneCloneModelDialog.confirm',
        defaultMessage: 'Создать'
    },
    cancel: {
        id: 'CloneCloneModelDialog.cancel',
        defaultMessage: 'Отмена'
    },
    secondLevelTitle: {
        id: 'CloneModelDialog.secondLevelTitle',
        defaultMessage: 'Будет создана копия модели'
    },
    CREATE_NEW_NODES_IN_SOURCE_FOLDERS: {
        id: 'CloneModelDialog.CREATE_NEW_NODES_IN_SOURCE_FOLDERS',
        defaultMessage: 'Создать новые определения (в исходных папках)'
    },
    CREATE_NEW_NODES_IN_MODEL_FOLDER: {
        id: 'CloneModelDialog.CREATE_NEW_NODES_IN_MODEL_FOLDER',
        defaultMessage: 'Создать новые определения (в папке с моделью)'
    },
    USE_EXISTING_DEFINITION_NODES: {
        id: 'CloneModelDialog.USE_EXISTING_DEFINITION_NODES',
        defaultMessage: 'Использовать существующие определения элементов'
    },
    modelNamePlaceholder: {
        id: 'CloneModelDialog.modelNamePlaceholder',
        defaultMessage: 'Имя новой модели',
    },
    modelNameLabel: {
        id: 'CloneModelDialog.modelNameLabel',
        defaultMessage: 'Имя модели',
    },
    modelTypeLabel: {
        id: 'CloneModelDialog.modelTypeLabel',
        defaultMessage: 'Тип модели',
    },
    pathRequiredError: {
        id: 'CloneModelDialog.pathRequiredError',
        defaultMessage: 'Путь должен быть заполнен',
    },
    pathLabel: {
        id: 'CloneModelDialog.pathLabel',
        defaultMessage: 'Расположение',
    },
    modelNameRequiredError: {
        id: 'CloneModelDialog.modelNameRequiredError',
        defaultMessage: 'Имя модели должно быть заполнено',
    },
    formStatusError: {
        id: 'CloneModelDialog.formStatusError',
        defaultMessage: 'Ошибка',
    },
    modelNotFoundError: {
        id: 'CloneModelDialog.ModelNotFoundError',
        defaultMessage: 'Модель не найдена',
    },
});
