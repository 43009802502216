import type { TTableOfContentsOptions } from '../../extensions/table-of-contents.types';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { v4 as uuid } from 'uuid';

export const TableOfContentsPlugin = ({ getId, anchorTypes = ['heading'] }: Partial<TTableOfContentsOptions>) =>
    new Plugin({
        key: new PluginKey('tableOfContent'),
        appendTransaction(transactions, oldState, state) {
            const tr = state.tr;
            let flag = false;

            if (transactions.some((transaction) => transaction.docChanged)) {
                const ids: string[] = [];

                state.doc.descendants((node, pos) => {
                    const tocId = node.attrs['data-toc-id'];

                    if (anchorTypes.includes(node.type.name) && 0 !== node.textContent.length) {
                        if (null == tocId || ids.includes(tocId)) {
                            const id = getId ? getId(node.textContent) : uuid();

                            tr.setNodeMarkup(pos, void 0, { ...node.attrs, 'data-toc-id': id, id });

                            flag = true;
                        }

                        ids.push(tocId);
                    }
                });
            }

            return flag ? tr : null;
        },
    });
