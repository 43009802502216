import theme from './TableRenderer.component.scss';

type TSelectedCells = [number, number][];

export const getSelectedCells = (table) => {
    const result: TSelectedCells = [];
    table.querySelectorAll('tr').forEach((tr, i) => {
        tr.querySelectorAll('td').forEach((td, j) => {
            const isActive = td.classList.contains(theme.active);
            if (isActive) {
                result.push([i, j]);
            }
        });
    });

    return result;
};

export const addMultiSelectionListeners = (table, onSelect = (selected) => [], onOverCell = (me) => {}) => {
    let centerCell: HTMLElement | null = null;
    let currentOverElement = null;
    const tds = table.querySelectorAll('td');

    function mouseDownHanlder(event) {
        if (event.button !== 0) {
            return false;
        }

        // @ts-ignore
        centerCell = this;

        tds.forEach((td) => {
            td.classList.remove(theme.active);
        });

        return false;
    }

    const mouseUpHandler = () => {
        centerCell = null;
        if (!onSelect) {
            return;
        }
        onSelect(getSelectedCells(table));
    };

    const getSelectLine = (cell) => {
        if (!centerCell) return null;

        const { x: cellX, y: cellY } = cell.getBoundingClientRect();
        const { x, y } = centerCell.getBoundingClientRect();

        if (x === cellX) {
            return x;
        }

        if (y === cellY) {
            return y;
        }

        return null;
    };

    function clearSelection(cell) {
        cell.classList.remove(theme.active);
    }

    function mouseMoveHandler() {
        if (!centerCell) {
            return;
        }
        // @ts-ignore
        const me: any = this;

        const line = getSelectLine(me);
        me.classList.add(theme.active);
        tds.forEach((cell) => {
            if (cell === centerCell) {
                return;
            }
            const { x: cellX, y: cellY } = cell.getBoundingClientRect();

            if (line === cellY || line === cellX) {
                return;
            }

            clearSelection(cell);
        });
    }

    function mouseOverHandler() {
        // @ts-ignore
        const me: any = this;
        if (me !== currentOverElement) {
            currentOverElement = me;
            onOverCell(me);
        }
    }

    tds.forEach((td) => {
        td.addEventListener('mousedown', mouseDownHanlder);
        td.addEventListener('mousemove', mouseMoveHandler);
        td.addEventListener('mouseover', mouseOverHandler);
    });

    // table.addEventListener('mousemove', mouseMoveHandler);
    table.addEventListener('mouseup', mouseUpHandler);

    return () => {
        tds.forEach((td) => {
            clearSelection(td);
            td.removeEventListener('mousemove', mouseMoveHandler);
            td.removeEventListener('mousedown', mouseDownHanlder);
            td.removeEventListener('mouseover', mouseDownHanlder);
        });
        table.removeEventListener('mouseup', mouseUpHandler);
    };
};
