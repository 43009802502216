import { TReducer } from '../utils/types';
import {
    GENERAL_MENU_CLOSE,
    GENERAL_MENU_OPEN,
} from '../actionsTypes/generalMenu.actionTypes';
import { TMainMenuState } from './mainMenu.reducer.types';

const initial: TMainMenuState = {
    isOpenedGeneralMenu: true
};

export const mainMenuReducer: TReducer<TMainMenuState> = (state = initial, action) => {
    switch (action.type) {
        case GENERAL_MENU_CLOSE: {
            return {
                ...state,
                isOpenedGeneralMenu: false,
            };
        }

        case GENERAL_MENU_OPEN: {
            return {
                ...state,
                isOpenedGeneralMenu: true,
            };
        }

        default: {
            return state;
        }
    }
};
