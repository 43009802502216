import { TAttributeTypeState } from '../attributeType.reducer.types';
import { TEdgeTypeState } from '../edgeType.reducer.types';
import { TEdgeTypeGroupState } from '../edgeTypeGroup.reducer.types';
import { TFolderTypeState } from '../folderType.reducer.types';
import { TModelTypeGroupState } from '../modelTypeGroup.reducer.types';
import { TObjectTypeState } from '../objectType.reducer.types';
import { TObjectTypeGroupState } from '../objectTypeGroup.reducer.types';
import { TPresetIconState } from '../presetSettings/presetIcon.reducer.types';

type TState =
    | TPresetIconState
    | TObjectTypeGroupState
    | TEdgeTypeGroupState
    | TObjectTypeState
    | TAttributeTypeState
    | TEdgeTypeState
    | TModelTypeGroupState
    | TFolderTypeState;

export const cloneState = <T extends TState>(state: T, serverId: string, presetId: string): T => {
    return {
        byServerId: {
            ...state.byServerId,
            [serverId]: {
                byPresetId: {
                    ...(state.byServerId?.[serverId]?.byPresetId || {}),
                    [presetId]: {
                        byId: {
                            ...(state.byServerId?.[serverId]?.byPresetId?.[presetId]?.byId || {}),
                        },
                    },
                },
            },
        },
    } as T;
};
