import { Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import theme from './TooltipTitle.scss';

type TTooltipVerticaleTitleProps = {
    children: JSX.Element | string;
    vertical?: boolean; // если true, то текст будет вертикальным
    withoutTooltip?: boolean; // если true, то не будет тултипа
};

export const TooltipTitle = (props: TTooltipVerticaleTitleProps): JSX.Element => {
    const { children, vertical, withoutTooltip } = props;
    const [isVisibleTooltipe, setIsVisibleTooltipe] = useState<boolean>(false);
    const divEl: React.RefObject<any> = useRef();

    useEffect(() => {
        const parentHeight: number = divEl.current?.parentElement[vertical ? 'clientHeight' : 'clientWidth'];
        const childHeight: number = divEl.current?.childNodes[0][vertical ? 'offsetHeight' : 'offsetWidth'];

        if (childHeight >= parentHeight) {
            setIsVisibleTooltipe(true);
        } else {
            setIsVisibleTooltipe(false);
        }
    }, [divEl]);

    return (
        <Tooltip mouseLeaveDelay={0} title={isVisibleTooltipe && !withoutTooltip ? children : undefined}>
            <div ref={divEl} className={vertical ? theme.verticalContainer : theme.horizontalContainer}>
                <span>{children}</span>
            </div>
        </Tooltip>
    );
};

TooltipTitle.defaultProps = {
    vertical: false,
    withoutTooltip: false,
};
