import { TModelFormStatus, TModelFormInitData } from '../models/modelDialog.types';
import { TReducer } from '../utils/types';
import {
    MODEL_DIALOG_INIT,
    MODEL_DIALOG_SET_STATE,
    MODEL_DIALOG_SUBMIT_RESULT,
} from '../actionsTypes/modelDialog.actionTypes';
import { TModelDialogState } from './modelDialog.reducer.types';

const initial: TModelDialogState = {
    modelFormInitData: <TModelFormInitData>{
        parentNodeId: {
            id: '',
            serverId: '',
            repositoryId: '',
        },
    },

    modelFormStatus: <TModelFormStatus>{
        modelFormStatus: 'success',
    },
};

export const ModelDialogReducer: TReducer<TModelDialogState> = (state = initial, action) => {
    switch (action.type) {
        case MODEL_DIALOG_INIT: {
            const { parentNodeId } = action.payload;
            const modelFormInitData = { parentNodeId: { ...parentNodeId } };

            return { ...state, modelFormInitData };
        }
        case MODEL_DIALOG_SET_STATE: {
            const { parentNodeId } = action.payload;
            const modelFormInitData = { parentNodeId: { ...parentNodeId } };

            return { ...state, modelFormInitData };
        }

        case MODEL_DIALOG_SUBMIT_RESULT: {
            const { result, error } = action.payload;
            const { modelFormStatus } = state;

            if (result === 'error') {
                modelFormStatus.modelFormStatus = 'error';
                modelFormStatus.error = error;
                state.modelFormStatus = { ...modelFormStatus };
            }

            return { ...state };
        }

        default: {
            return state;
        }
    }
};
