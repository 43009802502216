// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GroupMainData__container__K3E8g .ant-row{-ms-flex-wrap:nowrap;flex-wrap:nowrap}.GroupMainData__container__K3E8g .ant-form-item-control{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;-ms-flex-negative:1;flex-shrink:1}.GroupMainData__container__K3E8g .ant-form-item-control-input-content>textarea{resize:vertical !important}.GroupMainData__container__K3E8g .ant-form-item-label{-ms-flex-preferred-size:32%;flex-basis:32%;white-space:normal;-ms-flex-item-align:center;align-self:center}.GroupMainData__container__K3E8g .ant-form-item-label>label{height:auto !important}.GroupMainData__container__K3E8g .ant-form-item-label>label:first-child::after{-ms-flex-item-align:end;align-self:flex-end}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/GroupMainData/components/GroupMainData.scss"],"names":[],"mappings":"AAEQ,0CACI,oBAAA,CAAA,gBAAA,CAEJ,wDACI,kBAAA,CAAA,mBAAA,CAAA,WAAA,CACA,mBAAA,CAAA,aAAA,CAEJ,+EACK,0BAAA,CAEL,sDACI,2BAAA,CAAA,cAAA,CACA,kBAAA,CACA,0BAAA,CAAA,iBAAA,CACA,4DACI,sBAAA,CAGJ,+EACI,uBAAA,CAAA,mBAAA","sourcesContent":[".container {\r\n    :global {\r\n        .ant-row {\r\n            flex-wrap: nowrap;\r\n        }\r\n        .ant-form-item-control {\r\n            flex-grow: 1;\r\n            flex-shrink: 1;\r\n        }\r\n        .ant-form-item-control-input-content > textarea {\r\n             resize: vertical !important;\r\n        }\r\n        .ant-form-item-label {\r\n            flex-basis: 32%;\r\n            white-space: normal;\r\n            align-self: center;\r\n            >label {\r\n                height: auto !important;\r\n           \r\n            }\r\n            >label:first-child::after {\r\n                align-self: flex-end;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GroupMainData__container__K3E8g"
};
export default ___CSS_LOADER_EXPORT___;
