import { defineMessages } from 'react-intl';

export default defineMessages({
    refreshRequestMessage: {
        id: 'RefreshPageDialog.message',
        defaultMessage:
            'Ваша версия клиентского приложения несовместима с данным сервером. Попробуйте обновить страницу (Ctrl + F5).',
    },
    refresh: {
        id: 'RefreshPageDialog.refresh',
        defaultMessage: 'Обновить',
    },
    cancel: {
        id: 'RefreshPageDialog.cancel',
        defaultMessage: 'Отмена',
    },
});
