import { defineMessages } from 'react-intl';

export default defineMessages({
    doNotProcess: {
        id: 'ImportVisio.row.doNotProcess',
        defaultMessage: 'Не обрабатывать'
    },
    frame: {
        id: 'ImportVisio.row.frame',
        defaultMessage: 'Рамка'
    },
    header: {
        id: 'ImportVisio.row.header',
        defaultMessage: 'Шапка'
    },
    laneslist: {
        id: 'ImportVisio.row.laneslist',
        defaultMessage: 'Список дорожек'
    },
    swimlane: {
        id: 'ImportVisio.row.swimlane',
        defaultMessage: 'Дорожка'
    },
    etc: {
        id: 'ImportVisio.row.etc',
        defaultMessage: 'и др.',
    },
    notSelected: {
        id: 'ImportVisio.row.notSelected',
        defaultMessage: 'Не выбрано',
    },
});
