import { TReducer } from '../utils/types';
import {
    CHANGE_SYMBOL_VIEW_MODE,
    NAVIGATOR_CLEAR_PROPERTIES,
    NAVIGATOR_PROPERTIES_CHANGE_TAB,
    NAVIGATOR_PROPERTIES_RENDER_PROP,
} from '../actionsTypes/navigatorProperties.actionTypes';
import { initialNavigatorPropertiesSelectorState } from '../models/navigatorPropertiesSelectorState';
import { PropertiesTabKey, EntityEnum, SymbolViewEnum } from '../models/navigatorPropertiesSelectorState.types';
import { TNavigatorPropertiesState } from './navigatorProperties.reducer.types';

const initial: TNavigatorPropertiesState = {
    activeKey: PropertiesTabKey.ATTRIBUTES,
    navigatorPropertiesSelectorState: initialNavigatorPropertiesSelectorState,
};

const isEntityTypeChanged = (state: TNavigatorPropertiesState, entityType: EntityEnum | undefined): boolean =>
    entityType !== undefined && entityType !== state.navigatorPropertiesSelectorState.entityType;

export const navigatorPropertiesReducer: TReducer<TNavigatorPropertiesState> = (state = initial, action) => {
    switch (action.type) {
        case NAVIGATOR_PROPERTIES_RENDER_PROP: {
            const { navigatorPropertiesSelectorState } = action.payload;
            const { entityType } = navigatorPropertiesSelectorState;
            const activeKey = isEntityTypeChanged(state, entityType) ? initial.activeKey : state.activeKey;

            return {
                ...state,
                activeKey,
                navigatorPropertiesSelectorState: {
                    ...state.navigatorPropertiesSelectorState,
                    ...navigatorPropertiesSelectorState,
                    symbolViewMode: state.navigatorPropertiesSelectorState.symbolViewMode,
                },
            };
        }
        case NAVIGATOR_PROPERTIES_CHANGE_TAB: {
            return {
                ...state,
                activeKey: action.payload.activeKey,
            };
        }
        case CHANGE_SYMBOL_VIEW_MODE: {
            const { symbolViewMode } = state.navigatorPropertiesSelectorState;
            const newMode =
                symbolViewMode === SymbolViewEnum.SYMBOLS ? SymbolViewEnum.ENTRY_COUNT : SymbolViewEnum.SYMBOLS;

            return {
                ...state,
                navigatorPropertiesSelectorState: {
                    ...state.navigatorPropertiesSelectorState,
                    symbolViewMode: newMode,
                },
            };
        }
        case NAVIGATOR_CLEAR_PROPERTIES: {
            return {
                ...state,
                navigatorPropertiesSelectorState: {
                    ...initialNavigatorPropertiesSelectorState,
                    symbolViewMode: state.navigatorPropertiesSelectorState.symbolViewMode,
                },
            };
        }
        default: {
            return state;
        }
    }
};
