import * as React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import print from '../../../utils/print.utils';
import { Printable, TPrintOptions, ZoomMode } from '../../../utils/types';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { PrintForm } from './PrintForm.component';
import { PrintPreview } from './PrintPreview.component';
import { detectOrientation, getPrintHtml, getZoomToFit, getZoomToScale, resizeSvg } from './PrintDialog.utils';
import messages from './PrintDialog.messages';
import theme from './PrintDialog.component.scss';
import { TPrintDialogProps } from './PrintDialog.types';
import { MAX_PAGES, MAX_SCALE, MIN_PAGES, MIN_SCALE } from './PrintForm.constans';
import {
    DEFAULT_OFFSET,
    DEFAULT_ORIENTATION,
    DEFAULT_PAPER_FORMAT,
    DEFAULT_ZOOM_LEVEL,
    PrintDialogFormItemNames,
} from './PrintDialog.constants';
import { useEffect, useState } from 'react';
import { FieldData } from 'rc-field-form/es/interface';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { PrintModel } from '../../../selectors/printModel.selectors';
import { createPrintModelImages } from '../../../actions/printModel.actions';
import { SvgImage } from '../../../mxgraph/bpmgraph';
import { ModelSvgImage } from '../../../sagas/types/imageSaga.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export const PrintDialog = (props: TPrintDialogProps) => {
    const { type, orientation, paperFormat, convertSvg, nodeId } = props;
    const intl: IntlShape = useIntl();
    const dispatch = useDispatch();
    const [data, selectedData] = useSelector(PrintModel.getImages);
    const [preview, setPreview] = useState<boolean>(false);
    const [canvas, setCanvasState] = useState<HTMLCanvasElement | undefined>(undefined);
    const [options, setOptions] = useState<TPrintOptions>({
        grayscale: false,
        orientation,
        paperFormat: paperFormat || DEFAULT_PAPER_FORMAT,
        zoomMode: ZoomMode.SCALE,
        zoomFitAcross: 1,
        zoomFitDown: 1,
        zoomScale: props.zoomScale || DEFAULT_ZOOM_LEVEL,
        showOffsets: true,
        printable: Printable.MODEL,
        hideDecompositions: false,
    });

    useEffect(() => {
        if (!orientation) {
            setOptions({ ...options, orientation: getOrientation() });
        }
    }, [data, selectedData]);

    const onClose = () => {
        dispatch(closeDialog(DialogType.PRINT_DIALOG));
    };

    const getSvg = (): SvgImage | undefined => {
        const { zoomMode, printable, zoomScale } = options;

        const svgImage: ModelSvgImage | undefined = printable === Printable.MODEL ? data : selectedData;
        if (!svgImage) return undefined;
        let svgSize;
        if (zoomMode === ZoomMode.SCALE) {
            svgSize = getZoomToScale([svgImage.width * 0.4, svgImage.height * 0.4], zoomScale);
        } else if (zoomMode === ZoomMode.FIT) {
            svgSize = getZoomToFit([svgImage.width, svgImage.height], options);
        }

        return resizeSvg(svgImage, svgSize);
    };

    const setCanvas = (callback?) => {
        const svg = getSvg();
        if (!svg) return;
        convertSvg(svg, (canvas) => setCanvasState(canvas));
        if (callback) callback();
    };

    useEffect(() => {
        setCanvas();
    }, [options]);

    const getOrientation = () => {
        const svg = getSvg();
        if (!svg || !data) return DEFAULT_ORIENTATION;
        const { width, height } = svg;

        return detectOrientation([width || 0, height || 0], options as TPrintOptions, data.offset);
    };

    const handleOptionsChange = (changedFields: FieldData[]) => {
        const changedOptions = {};
        changedFields.forEach(({ name, value }) => {
            const [formFieldName] = name;
            if (formFieldName === PrintDialogFormItemNames.hideDecompositions) {
                dispatch(createPrintModelImages(nodeId, value));
            }
            if (formFieldName === PrintDialogFormItemNames.zoomScale) {
                if (value < MIN_SCALE) {
                    value = MIN_SCALE;
                }
                if (value > MAX_SCALE) {
                    value = MAX_SCALE;
                }
            }

            if (
                formFieldName === PrintDialogFormItemNames.zoomFitAcross ||
                formFieldName === PrintDialogFormItemNames.zoomFitDown
            ) {
                if (value < MIN_PAGES) {
                    value = MIN_PAGES;
                }
                if (value > MAX_PAGES) {
                    value = MAX_PAGES;
                }
            }
            changedOptions[name as string] = value;
        });
        setOptions({ ...options, ...changedOptions });
    };

    const handlePreviewToggle = () => {
        if (preview) {
            setPreview(false);
        } else if (type === 'svg') {
            setCanvas(() => setPreview(true));
        }
    };

    const handlePrint = (imageData: string) => () => {
        const { showOffsets, zoomMode } = options;

        if (type === 'svg') {
            setCanvas(() => {
                const html = getPrintHtml(
                    canvas!,
                    options,
                    zoomMode === ZoomMode.SCALE ? data?.offset || DEFAULT_OFFSET : DEFAULT_OFFSET,
                    imageData,
                    showOffsets,
                );

                print({
                    printable: html,
                    type: 'raw-html',
                });
            });
        }
    };

    const form = (
        <PrintForm
            options={{ ...options, orientation: options.orientation || getOrientation() }}
            onFieldsChange={handleOptionsChange}
        />
    );
    const imageData = `data:image/svg+xml;base64,${Base64.encode(getSvg()?.svgString || '')}`;

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'window-print-model_cancel_btn',
                },
                {
                    key: 'preview',
                    onClick: handlePreviewToggle,
                    value: intl.formatMessage(messages.preview),
                    dataTest: 'window-print-model_preview_btn',
                },
                {
                    key: 'ok',
                    onClick: handlePrint(imageData),
                    value: intl.formatMessage(messages.print),
                    visualStyle: 'primary',
                    dataTest: 'window-print-model_print_btn',
                },
            ]}
        />
    );

    return (
        <div>
            {preview && canvas && (
                <PrintPreview
                    imageData={imageData}
                    canvas={canvas}
                    form={form}
                    options={options}
                    onClose={handlePreviewToggle}
                    onSubmit={handlePrint(imageData)}
                    offset={options.zoomMode === ZoomMode.SCALE ? data?.offset || DEFAULT_OFFSET : DEFAULT_OFFSET}
                    showOffsets={options.showOffsets && options.zoomMode === ZoomMode.SCALE}
                />
            )}
            <Dialog
                className={theme.dialog}
                footer={footer}
                onCancel={onClose}
                title={intl.formatMessage(messages.title)}
                open
                width="fit-content"
            >
                {form}
            </Dialog>
        </div>
    );
};
