import type { AttributeTypeStyleRule, AttributeTypeStyleRuleTypeEnum } from '../../../../../serverapi/api';
import React from 'react';
import { Input } from 'antd';
import { ErrorWrapper } from './ErrorWrapper';

type TNumberInput = {
    rule: AttributeTypeStyleRule;
    disabled?: boolean;
    handleRuleParams: (ruleType: AttributeTypeStyleRuleTypeEnum | undefined, value1: string, value2?: string) => void;
};

export const NumberInput = ({ rule, disabled, handleRuleParams }: TNumberInput) => {
    return (
        <ErrorWrapper error={!rule.param}>
            <div>
                <Input
                    data-test="change-the-value-of-the-rule_input_number-type"
                    id={rule.type}
                    type="number"
                    disabled={disabled}
                    onChange={(event) => handleRuleParams(rule.type, event.currentTarget.value)}
                    value={rule.param || ''}
                />
            </div>
        </ErrorWrapper>
    );
};
