import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { ObjectMenuItem } from '../components/items/ObjectMenuItem/ObjectMenuItem.component';
import { TObjectMenuItemProps } from '../components/items/ObjectMenuItem/ObjectMenuItem.types';
import { generalMenuClose, generalMenuCollapse, generalMenuExpand } from '../../../actions/generalMenu.actions';

const mapStateToProps = (state: TRootState, ownProps: Partial<TObjectMenuItemProps>): Partial<TObjectMenuItemProps> => {
    const activeScheme = TabsSelectors.getActiveTab(state);
    let toolbarComponent = '';
    if (activeScheme && activeScheme.type) {
        toolbarComponent = activeScheme.type;
    }

    return {
        toolbarComponent,
        itemKey: ownProps.itemKey,
        // TODO: implement correct state
        compact: state.generalMenu.isCompact,
        activeKey: TabsSelectors.getActiveTabId(state),
    };
};

const mapDispatchToProps = (dispatch): Partial<TObjectMenuItemProps> => ({
    onMenuExpandClick: () => {
        dispatch(generalMenuExpand());
    },
    onMenuCollapseClick: () => {
        dispatch(generalMenuCollapse());
    },
    onMenuCloseClick: () => {
        dispatch(generalMenuClose());
    },
});

export const ObjectMenuItemContainer = connect(mapStateToProps, mapDispatchToProps)(ObjectMenuItem);
