import { TSubscribedNodesIdsState } from '@/reducers/subscribedNodesIds.reducer.types';
import { TRootState } from '@/reducers/root.reducer.types';
import { NodeId } from '@/serverapi/api';
import { createSelector } from 'reselect';
import { compareNodeIds } from '@/utils/nodeId.utils';

export namespace SubscribedNodesIdsSelectors {
    const getAllUserSubscribedNodeIds = (state: TRootState): TSubscribedNodesIdsState => state.subscribedNodesIds;

    export const getIsSubscribedToNode = (nodeId: NodeId) =>
        createSelector(getAllUserSubscribedNodeIds, (state: TSubscribedNodesIdsState): boolean =>
            state.some((subscribedNodeId) => compareNodeIds(subscribedNodeId, nodeId)),
        );
}
