import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';

export const externalSessionsState = (state: TRootState) => state.externalSessions;

export namespace ExternalSessionsSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(externalSessionsState, (state) => state.byServerId[serverId]);

    export const externalSessions = (serverId: string) =>
        createSelector(byServerId(serverId), (state) => {
            return (state && Object.values(state.byId)) || [];
        });

    export const loading = createSelector(externalSessionsState, (state) => state.loading);
}
