import { defineMessages } from 'react-intl';

export default defineMessages({
    shapes: {
        id: 'ShapeSelect.shapes',
        defaultMessage: 'Фигуры',
    },
    ellipse: {
        id: 'ShapeSelect.ellipse',
        defaultMessage: 'Эллипс',
    },
    triangle: {
        id: 'ShapeSelect.triangle',
        defaultMessage: 'Треугольник',
    },
    cloud: {
        id: 'ShapeSelect.cloud',
        defaultMessage: 'Облако',
    },
    hexagon: {
        id: 'ShapeSelect.hexagon',
        defaultMessage: 'Шестиугольник',
    },
    cylinder: {
        id: 'ShapeSelect.cylinder',
        defaultMessage: 'Цилиндр',
    },
    swimLane: {
        id: 'ShapeSelect.swimLane',
        defaultMessage: 'Плавательная дорожка',
    },
    rhombus: {
        id: 'ShapeSelect.rhombus',
        defaultMessage: 'Ромб',
    },
    doubleEllipse: {
        id: 'ShapeSelect.doubleEllipse',
        defaultMessage: 'Двойной эллипс',
    },
    rectangle: {
        id: 'ShapeSelect.rectangle',
        defaultMessage: 'Прямоугольник',
    },
});
