import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'StartScreen.title',
        defaultMessage: 'Добро пожаловать!',
    },
    chooseYourServer: {
        id: 'StartScreen.chooseYourServer',
        defaultMessage: 'Выберите сервер для подключения',
    },
    addNewOne: {
        id: 'StartScreen.addNewOne',
        defaultMessage: 'или добавьте новый ',
    },
    serverVersion: {
        id: 'StartScreen.serverVersion',
        defaultMessage: 'Версия: {SERVER_VERSION}',
    },
    release: {
        id: 'StartScreen.release',
        defaultMessage: 'Выпуск: {GIT_BRANCH}',
    },
    kicked: {
        id: 'StartScreen.kicked',
        defaultMessage: 'Ваша сессия была завершена',
    },
    loginKeycloak: {
        id: 'StartScreen.loginKeycloak',
        defaultMessage: 'Войти через Keycloak',
    },
    loginKerberos: {
        id: 'StartScreen.loginKerberos',
        defaultMessage: 'Войти через Kerberos',
    },
});
