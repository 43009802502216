import type { TSpriteSymbol } from '@/models/spriteSymbol.types';
import type { TooltipPlacement } from 'antd/es/tooltip';
import React, { useRef, useState } from 'react';
import { useClickOutside } from '../Select/useClickOutside';
import { Dropdown } from 'antd';
import cx from 'classnames';
import theme from './Dropdown.scss';
import { Button } from './Button.component';

interface IDropdownItem<T> {
    value: T;
    label?: string;
    tooltip?: string;
    selected?: boolean;
    disabled?: boolean;
    dataTest?: string;
    spriteSymbol?: TSpriteSymbol;
}

interface IDropdownProps<T> {
    title: string;
    disabledTitle?: string;
    items?: IDropdownItem<T>[];
    overlay?: React.ReactElement;
    value?: T;
    arrow?: boolean;
    compact?: boolean;
    disabled?: boolean;
    destroyPopupOnHide?: boolean;
    defaultSprite?: TSpriteSymbol;
    tooltipPlacement?: TooltipPlacement;
    onSelect?: (value: T) => void;
    onOpenChange?: (visible: boolean) => void;
    className?: string;
    buttonStyle?: React.CSSProperties;
    dataTest?: string;
}

function createAlignMenu<T>(props: IDropdownProps<T>) {
    const { items, overlay, compact, onSelect } = props;

    const menu = overlay || (
        <>
            {items?.map((item, idx) => (
                <Button
                    key={idx}
                    icon={item.spriteSymbol}
                    title={item.label}
                    tooltipTitle={item.tooltip}
                    compact={compact}
                    disabled={item.disabled}
                    selected={item.selected}
                    onClick={() => onSelect?.(item.value)}
                    dataTest={item.dataTest}
                />
            ))}
        </>
    );

    return (
        <div className={cx(theme.overlayContainer, { [theme.compactOverlayContainer]: !overlay && compact })}>
            {menu}
        </div>
    );
}

const SelectableDropdown = <T,>(props: IDropdownProps<T>) => {
    const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState<boolean>(false);
    const {
        title,
        disabledTitle = '',
        items,
        value,
        arrow = true,
        compact,
        disabled,
        destroyPopupOnHide,
        defaultSprite,
        tooltipPlacement = 'top',
        onOpenChange,
        buttonStyle,
        className,
        dataTest,
    } = props;
    const current = items?.find((i) => (i.spriteSymbol || i.label) && i.value === value);
    const currentSprite: TSpriteSymbol | undefined = current ? current.spriteSymbol : defaultSprite;
    const buttonContainerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    const onClick = () => {
        setIsOpenDropdownMenu((prevState) => !prevState);
    };

    useClickOutside(buttonContainerRef, () => isOpenDropdownMenu && setIsOpenDropdownMenu(false));

    return (
        <Dropdown
            onOpenChange={onOpenChange}
            overlay={createAlignMenu(props)}
            disabled={disabled}
            trigger={['click']}
            destroyPopupOnHide={destroyPopupOnHide}
            open={isOpenDropdownMenu}
        >
            <div ref={buttonContainerRef}>
                <Button
                    icon={currentSprite}
                    title={title}
                    tooltipTitle={!disabled ? title : disabledTitle}
                    tooltipPlacement={tooltipPlacement}
                    arrow={arrow}
                    compact={compact}
                    disabled={disabled}
                    onClick={onClick}
                    style={buttonStyle}
                    className={className}
                    dataTest={dataTest}
                />
            </div>
        </Dropdown>
    );
};

export { SelectableDropdown as Dropdown };
