import Table from '@tiptap/extension-table';
import { columnResizing } from '../plugins/table/cellResize';
import { tableEditing } from '@tiptap/pm/tables';
import { TableView } from '../plugins/table/tableview';
// TODO удалить неиспользуемые плагины
// import { tableEditing } from '../plugins/table/tableEditing';

const ResizeableTable = Table.extend({
    addProseMirrorPlugins() {
        const isResizable = this.options.resizable && this.editor.isEditable;

        return [
            ...(isResizable
                ? [
                      columnResizing({
                          handleWidth: this.options.handleWidth,
                          cellMinWidth: this.options.cellMinWidth,
                          // @ts-ignore (incorrect type)
                          // View: this.options.View,
                          // TODO пробросить через options
                          View: TableView,
                          // TODO: PR for @types/prosemirror-tables
                          // @ts-ignore (incorrect type)
                          lastColumnResizable: this.options.lastColumnResizable,
                          className: this.options.HTMLAttributes.class,
                      }),
                  ]
                : []),
            tableEditing({
                allowTableNodeSelection: this.options.allowTableNodeSelection,
            }),
        ];
    },
});

export { ResizeableTable as Table };
