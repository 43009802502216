import { v4 as uuid } from 'uuid';
import { ObjectDefinitionImpl } from '../../models/bpm/bpm-model-impl';
import { TreeNode } from '../../models/tree.types';
import { IModelContext } from '../../sagas/utils.types';
import { ModelAssignmentNodeTypeEnum, ObjectType, Symbol } from '../../serverapi/api';

export class DragModelBll {
    public static createObjectDefinitionWithDecomposition(
        symbol: Symbol,
        objectType: ObjectType | null,
        modelContext: IModelContext,
        draggedModelNode: TreeNode,
    ): ObjectDefinitionImpl {
        const newObjectDefinitionId = uuid();
        
        return new ObjectDefinitionImpl({
            nodeId: {
                ...modelContext.nodeId,
                id: newObjectDefinitionId,
            },
            name: draggedModelNode.name,
            modelAssignments: [
                {
                    modelId: draggedModelNode.nodeId.id,
                    nodeType: draggedModelNode.type as ModelAssignmentNodeTypeEnum,
                },
            ],
            objectTypeId: (objectType && objectType.id) || symbol.objectType,
            type: 'OBJECT',
            isDirty: true,
        });
    }

    public static addDecompositionToObjectDefinition(objectDefinition: ObjectDefinitionImpl, draggedModelNode: TreeNode) {
        const modelAssignments = [...objectDefinition.modelAssignments];
        modelAssignments.push({
            modelId: draggedModelNode.nodeId.id,
            nodeType: draggedModelNode.type as ModelAssignmentNodeTypeEnum,
        });

        return { ...objectDefinition, modelAssignments };
    }
}

