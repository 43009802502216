import {
    ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS,
    ATTRIBUTE_TYPES_REQUEST_SUCCESS,
    DELETE_ATTRIBUTE_TYPE,
    DELETE_ATTRIBUTE_TYPES_AND_GROUPS,
} from '../actionsTypes/attributeType.actionTypes';
import { AttributeType } from '../serverapi/api';
import {
    TAttributeTypeDeleteRequestSuccessAction,
    TAttributeTypeRequestSuccess,
    TDeleteAttributeTypePayload,
    TDeleteAttributeTypesAndGroupsAction,
    TDeleteAttributeTypesAndGroupsPayload,
    TDeleteTAttributeTypeAction,
} from './attributeType.actions.types';

export const attributeTypeDeleteRequestSuccess = (
    payload: TDeleteAttributeTypePayload,
): TAttributeTypeDeleteRequestSuccessAction => ({
    type: ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS,
    payload,
});

export const attributeTypesRequestSuccess = (
    serverId: string,
    presetId: string,
    attributeTypes: AttributeType[],
): TAttributeTypeRequestSuccess => ({
    type: ATTRIBUTE_TYPES_REQUEST_SUCCESS,
    payload: {
        serverId,
        presetId,
        attributeTypes,
    },
});

export const deleteAttributeType = (payload: TDeleteAttributeTypePayload): TDeleteTAttributeTypeAction => ({
    type: DELETE_ATTRIBUTE_TYPE,
    payload,
});

export const deleteAttributeTypesAndGroups = (
    payload: TDeleteAttributeTypesAndGroupsPayload,
): TDeleteAttributeTypesAndGroupsAction => ({
    type: DELETE_ATTRIBUTE_TYPES_AND_GROUPS,
    payload,
});
