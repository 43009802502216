// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dialog__container__DP68m .ant-modal-header{padding-left:25px}.Dialog__container__DP68m .ant-modal-title{font-size:14px;letter-spacing:.3px;color:var(--text)}.Dialog__container__DP68m .ant-modal-body{padding:16px 24px;overflow-y:auto;max-height:calc(100vh - 200px)}.Dialog__container__DP68m .ant-modal-body .ant-table-wrapper{height:auto}.Dialog__container__DP68m .ant-modal-body .ant-table-body{max-height:calc(80vh - 200px)}.Dialog__container__DP68m .ant-modal-close-x{color:var(--blue-grey)}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Dialog/Dialog.scss"],"names":[],"mappings":"AAEQ,4CACI,iBAAA,CAGJ,2CACI,cAAA,CACA,mBAAA,CACA,iBAAA,CAGJ,0CACI,iBAAA,CACA,eAAA,CACA,8BAAA,CAEA,6DACI,WAAA,CAEJ,0DACI,6BAAA,CAIR,6CACI,sBAAA","sourcesContent":[".container {\r\n    :global {\r\n        .ant-modal-header {\r\n            padding-left: 25px;\r\n        }\r\n\r\n        .ant-modal-title {\r\n            font-size: 14px;\r\n            letter-spacing: 0.3px;\r\n            color: var(--text);\r\n        }\r\n\r\n        .ant-modal-body {\r\n            padding: 16px 24px;\r\n            overflow-y: auto;\r\n            max-height: calc(100vh - 200px);\r\n\r\n            & .ant-table-wrapper {\r\n                height: auto;\r\n            }\r\n            .ant-table-body {\r\n                max-height: calc(80vh - 200px);\r\n            }\r\n        }\r\n\r\n        .ant-modal-close-x {\r\n            color: var(--blue-grey);\r\n        }\r\n\r\n        .ant-modal-footer {\r\n            // padding: 10px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Dialog__container__DP68m"
};
export default ___CSS_LOADER_EXPORT___;
