import { all, call, CallEffect, put, select, takeEvery } from 'redux-saga/effects';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import { LocalesService } from '../../services/LocalesService';
import messages from '../../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import { workspaceAddTab, workspaceRemoveTabByNodeId } from '../../actions/tabs.actions';
import { defaultWorkspaceTabActions } from '../../models/tab';
import { EditorMode } from '../../models/editorMode';
import { WorkSpaceTabTypes } from '../../modules/Workspace/WorkSpaceTabTypesEnum';
import { IWorkspaceTabItemModelEditParams, TWorkspaceTab } from '../../models/tab.types';
import { v4 as uuid } from 'uuid';
import { ReportType } from '../../serverapi/api';
import { TPresetId } from '../../modules/AdminTools/Methodology/components/Presets/TPresetId.types';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode } from '@/models/tree.types';
import { PresetSelectors } from '@/selectors/preset.selectors';
import {
    PRESET_SETTINGS_CREATE_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_SUBMIT_REPORT_MODEL_TYPE,
} from '@/actionsTypes/presetSettings/presetSettingsReportModelTypes.actionTypes';
import { addStoreEditReportModelTypeWorkspaceTab } from '@/actions/workspaceTab/editReportModelTypeWorkspaceTab.actions';
import {
    TCreateReportModelTypeAction,
    TEditReportModelTypeAction,
    TSubmitReportModelTypeAction,
} from '@/actions/presetSettings/presetSettingsReportModelType.actions.types';
import { addReportModelType } from '@/actions/presetSettings/presetSettingsReportModelType.actions';
import { IPresetSettingsReportModelTypeData } from '@/reducers/presetSettings/presetSettingsReportModelType.reducer.types';
import { PresetSettingsReportModelTypeSelectors } from '@/selectors/presetSettings/presetSettingsReportModelType.selectors';
import { GeneralModelTypeDiscriminator } from '@/models/ModelTypes';
import { ReportDaoService } from '@/modules/Report/dao/ReportDaoService';

export function* saveReportModelTypeChanges(presetId: string) {
    const reportModelTypesData: IPresetSettingsReportModelTypeData = yield select(
        PresetSettingsReportModelTypeSelectors.byPresetId(presetId),
    );

    const effects: CallEffect<void>[] = [];

    if (reportModelTypesData.reportModelTypesForSave.length) {
        reportModelTypesData.reportModelTypesForSave.forEach((type) => {
            if (!reportModelTypesData.reportModelTypesForDelete.some((typeToDelete) => typeToDelete.id === type.id))
                effects.push(call(() => ReportDaoService.saveReportType({ body: type })));
        });
    }

    if (reportModelTypesData.reportModelTypesForDelete.length) {
        reportModelTypesData.reportModelTypesForDelete.forEach((type) => {
            effects.push(call(() => ReportDaoService.deleteReportType(presetId, type.id)));
        });
    }

    yield all(effects);
}

function* handleCreateReportModelType(action: TCreateReportModelTypeAction) {
    const { serverNodeId, presetId } = action.payload;

    const serverNode: TreeNode = yield select(TreeSelectors.itemById(serverNodeId));
    const preset = yield select(PresetSelectors.byId({ presetId, serverId: serverNodeId.serverId }));

    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const modelType: ReportType = {
        id: uuid(),
        attributes: [],
        groupId: '',
        presetId: preset.id,
        enableModel: true,
        discriminator: GeneralModelTypeDiscriminator.REPORT,
    };

    const reportTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelTitle),
        nodeId: {
            ...serverNodeId,
            id: `${serverNodeId.serverId}_${presetId}_${modelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_REPORT_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId,
        params: {
            serverNode,
            preset,
            createMode: true,
            modelTypeId: modelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(
        addStoreEditReportModelTypeWorkspaceTab({
            presetId,
            modelType,
        }),
    );

    yield put(workspaceAddTab(reportTypeTab));
}

function* handleSubmitReportModelType(action: TSubmitReportModelTypeAction) {
    const { presetId, requestBody, needTabClose, tabNodeId, createMode } = action.payload;

    if (createMode) {
        yield ReportDaoService.createReportType(requestBody);
    } else {
        yield ReportDaoService.saveReportType(requestBody);
    }

    if (needTabClose && tabNodeId) {
        yield put(workspaceRemoveTabByNodeId(tabNodeId));
    }

    yield put(addReportModelType({ presetId, reportModelTypes: [requestBody.body] }));
}

function* handleEditReportModelType(action: TEditReportModelTypeAction) {
    const { serverNodeId, presetId, reportModelType } = action.payload;

    const serverNode: TreeNode = yield select(TreeSelectors.itemById(serverNodeId));
    const preset = yield select(PresetSelectors.byId({ presetId, serverId: serverNodeId.serverId }));

    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);
    const multilingualName = reportModelType.multilingualName!;

    const createModelTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelType, { type: multilingualName[currentLocale] }),
        nodeId: {
            ...serverNodeId,
            id: `${serverNodeId.serverId}_${presetId}_${reportModelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_REPORT_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId,
        params: {
            serverNode,
            preset,
            createMode: false,
            modelTypeId: reportModelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(
        addStoreEditReportModelTypeWorkspaceTab({
            presetId,
            modelType: reportModelType,
        }),
    );
    yield put(workspaceAddTab(createModelTypeTab));
}

export function* presetSettingsReportModelTypesSaga() {
    yield takeEvery(PRESET_SETTINGS_CREATE_REPORT_MODEL_TYPE, handleCreateReportModelType);
    yield takeEvery(PRESET_SETTINGS_SUBMIT_REPORT_MODEL_TYPE, handleSubmitReportModelType);
    yield takeEvery(PRESET_SETTINGS_EDIT_REPORT_MODEL_TYPE, handleEditReportModelType);
}
