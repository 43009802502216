import type { TFileUploadDialogProps } from '../types/FileUploadDialog.types';
import type { TreeItemType } from '../../Tree/models/tree';
import type { NodeId } from '../../../serverapi/api';
import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { FileUploadDialog } from '../components/FileUploadDialog.component';
import { fileUpload } from '../../../actions/uploader.actions';

type TFileUploadDialogOwnProps = {
    nodeId: NodeId;
    parentNodeId: NodeId;
    type: TreeItemType;
};

const mapDispatchToProps = (dispatch, ownProps: TFileUploadDialogOwnProps): Partial<TFileUploadDialogProps> => ({
    onClose: () => dispatch(closeDialog(DialogType.FILE_UPLOAD_DIALOG)),
    onSubmit: (file: File) => {
        dispatch(fileUpload(file, ownProps.nodeId, ownProps.parentNodeId));
        dispatch(closeDialog(DialogType.FILE_UPLOAD_DIALOG));
    },
});

export const FileUploadDialogContainer = connect(null, mapDispatchToProps)(FileUploadDialog);
