import { defineMessages } from 'react-intl';

export default defineMessages({
    generalSettings: {
        id: 'Preset.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    new: {
        id: 'Preset.new',
        defaultMessage: 'Новый',
    },
    choosingNodeType: {
        id: 'Preset.choosingNodeType',
        defaultMessage: 'Выбор типа узла',
    },
    modelTypesTab: {
        id: 'Preset.modelTypesTab',
        defaultMessage: 'Типы моделей',
    },
    objectTypesTab: {
        id: 'Preset.objectTypesTab',
        defaultMessage: 'Типы объектов',
    },
    edgeTypesTab: {
        id: 'Preset.edgeTypesTab',
        defaultMessage: 'Типы связей',
    },
    attributeTypesTab: {
        id: 'Preset.attributeTypesTab',
        defaultMessage: 'Типы атрибутов',
    },
    elements: {
        id: 'Preset.elements',
        defaultMessage: 'Элементы',
    },
    iconsLib: {
        id: 'Preset.iconsLib',
        defaultMessage: 'Библиотека иконок',
    },
    attributeTypesFormat: {
        id: 'Preset.attributeTypesFormat',
        defaultMessage: 'Формат атрибута',
    },
    attributeTypeGroups: {
        id: 'Preset.attributeTypeGroups',
        defaultMessage: 'Группа типа атрибута',
    },
    createDefault: {
        id: 'Preset.createDefault',
        defaultMessage: 'Создавать автоматически',
    },
    attributeTypesGroup: {
        id: 'Preset.attributeTypesGroup',
        defaultMessage: 'Состоит в группе',
    },
    name: {
        id: 'Preset.name',
        defaultMessage: 'Название',
    },
    selectPropertyValues: {
        id: 'Preset.selectPropertyValues',
        defaultMessage: 'Доступные значения',
    },
    selectOutputFormat: {
        id: 'Preset.selectOutputFormat',
        defaultMessage: 'Формат вывода',
    },
    valuesQuantity: {
        id: 'Preset.valuesQuantity',
        defaultMessage: 'Количество значений',
    },
    withoutLimitation: {
        id: 'Preset.withoutLimitation',
        defaultMessage: 'Без ограничений',
    },
    selectContents: {
        id: 'Preset.selectContents',
        defaultMessage: 'Содержимое',
    },
    newAttributeSet: {
        id: 'Preset.newAttributeSet',
        defaultMessage: 'Добавление набора атрибутов',
    },
    cancel: {
        id: 'Preset.cancel',
        defaultMessage: 'Отмена',
    },
    ok: {
        id: 'Preset.ok',
        defaultMessage: 'Сохранить',
    },
    description: {
        id: 'Preset.description',
        defaultMessage: 'Описание',
    },
    representation: {
        id: 'Preset.representation',
        defaultMessage: 'Представление',
    },
    direction: {
        id: 'Preset.direction',
        defaultMessage: 'Направление',
    },
    history: {
        id: 'Preset.history',
        defaultMessage: 'История изменений',
    },
    delete: {
        id: 'Preset.delete',
        defaultMessage: 'Удалить методологию',
    },
    newPreset: {
        id: 'Preset.newPreset',
        defaultMessage: 'Новая методология',
    },
    id: {
        id: 'Preset.id',
        defaultMessage: 'ID',
    },
    format: {
        id: 'Preset.format',
        defaultMessage: 'Формат',
    },
    create: {
        id: 'Preset.create',
        defaultMessage: 'Создать',
    },
    newObject: {
        id: 'Preset.newObject',
        defaultMessage: 'Новый тип объекта',
    },
    newIcon: {
        id: 'Preset.newIcon',
        defaultMessage: 'Новая иконка',
    },
    newModel: {
        id: 'Preset.newModel',
        defaultMessage: 'Новый тип модели',
    },
    newGroup: {
        id: 'Preset.newGroup',
        defaultMessage: 'Новая группа',
    },
    export: {
        id: 'Preset.export',
        defaultMessage: 'Экспорт',
    },
    import: {
        id: 'Preset.import',
        defaultMessage: 'Импорт',
    },
    newEdge: {
        id: 'Preset.newEdge',
        defaultMessage: 'Новый тип связи',
    },
    attributeTypeTitle: {
        id: 'Preset.attributeTypeTitle',
        defaultMessage: 'Тип атрибута: {name}',
    },
    newAttributeName: {
        id: 'Preset.newAttributeName',
        defaultMessage: 'Новый тип атрибута',
    },
    newAttrSet: {
        id: 'Preset.newAttrSet',
        defaultMessage: 'Новый набор атрибутов',
    },
    addAttributeTypeGroup: {
        id: 'Preset.addAttributeTypeGroup',
        defaultMessage: 'Новая группа',
    },
    plus: {
        id: 'Preset.plus',
        defaultMessage: '+',
    },
    attributesSet: {
        id: 'Preset.attributesSet',
        defaultMessage: 'Набор атрибутов по умолчанию',
    },
    attributes: {
        id: 'Preset.attributes',
        defaultMessage: 'Атрибуты',
    },
    errorRender: {
        id: 'Preset.errorRender',
        defaultMessage: 'Ошибка отрисовки символа',
    },
    errorRenderDescription: {
        id: 'Preset.errorRenderDescription',
        defaultMessage: 'Не удалось сконвертировать символ',
    },
    groupOfmodelType: {
        id: 'Preset.groupOfmodelType',
        defaultMessage: 'Группа типа модели',
    },
    groupOfobjectType: {
        id: 'Preset.groupOfobjectType',
        defaultMessage: 'Группа типа объектов',
    },
    groupOfPresetImage: {
        id: 'Preset.groupOfPresetImage',
        defaultMessage: 'Группа иконок методологии',
    },
    groupOfattributeType: {
        id: 'Preset.groupOfattributeType',
        defaultMessage: 'Группа типа атрибутов',
    },
    groupOfedgeType: {
        id: 'Preset.groupOfedgeType',
        defaultMessage: 'Группа типа связей',
    },
    valuePropertyPlaceholder: {
        id: 'Preset.valuePropertyPlaceholder',
        defaultMessage: 'Введите значения через ;',
    },
    accessRights: {
        id: 'Preset.accessRights',
        defaultMessage: 'Права доступа',
    },
    folderType: {
        id: 'Preset.folderType',
        defaultMessage: 'Типы папок',
    },
    ruValuesTitle: {
        id: 'Preset.ruValuesTitle',
        defaultMessage: '(RU)',
    },
    enValuesTitle: {
        id: 'Preset.enValuesTitle',
        defaultMessage: '(EN)',
    },
    deleteValueType: {
        id: 'Preset.deleteValueType',
        defaultMessage: 'Удалить значение "{value}"?',
    },
    requiredField: {
        id: 'Preset.requiredField',
        defaultMessage: 'обязательно для заполнения',
    },
    attributeValueId: {
        id: 'Preset.attributeValueId',
        defaultMessage: 'ID',
    },
    attributeUniqueValueId: {
        id: 'Preset.attributeUniqueValueId',
        defaultMessage: 'ID должен быть уникальным',
    },
    deleteDefaultMessage: {
        id: 'Preset.deleteDefaultMessage',
        defaultMessage: 'Удалить?',
    },
    deleteGroupModels: {
        id: 'Preset.deleteGroupModels',
        defaultMessage: 'Удалить группу типов моделей?',
    },
    deleteModel: {
        id: 'Preset.deleteModel',
        defaultMessage: 'Удалить тип модели?',
    },
    deleteObject: {
        id: 'Preset.deleteObject',
        defaultMessage: 'Удалить тип объекта?',
    },
    deleteEdge: {
        id: 'Preset.deleteEdge',
        defaultMessage: 'Удалить тип связи?',
    },
    deleteModelsDialogTitle: {
        id: 'Preset.deleteModelsDialogTitle',
        defaultMessage: 'Удалить выбранные типы моделей?',
    },
    deleteModelGroups: {
        id: 'Preset.deleteModelGroups',
        defaultMessage: 'Будут удалены группы типов моделей',
    },
    deleteModels: {
        id: 'Preset.deleteModels',
        defaultMessage: 'Будут удалены типы моделей',
    },
    deleteObjectsDialogTitle: {
        id: 'Preset.deleteObjectsDialogTitle',
        defaultMessage: 'Удалить выбранные типы объектов?',
    },
    deleteObjectGroups: {
        id: 'Preset.deleteObjectGroups',
        defaultMessage: 'Будут удалены группы типов объектов',
    },
    deleteObjects: {
        id: 'Preset.deleteObjects',
        defaultMessage: 'Будут удалены типы объектов',
    },
    deleteEdgesDialogTitle: {
        id: 'Preset.deleteEdgesDialogTitle',
        defaultMessage: 'Удалить выбранные типы связей?',
    },
    deleteEdgeGroups: {
        id: 'Preset.deleteEdgeGroups',
        defaultMessage: 'Будут удалены группы типов связей',
    },
    deleteEdges: {
        id: 'Preset.deleteEdges',
        defaultMessage: 'Будут удалены типы связей',
    },
    deleteAttributesDialogTitle: {
        id: 'Preset.deleteAttributesDialogTitle',
        defaultMessage: 'Удалить выбранные типы атрибутов?',
    },
    deleteAttributeGroups: {
        id: 'Preset.deleteAttributeGroups',
        defaultMessage: 'Будут удалены группы типов атрибутов',
    },
    deleteAttributes: {
        id: 'Preset.deleteAttributes',
        defaultMessage: 'Будут удалены типы атрибутов',
    },
    deletePresetImagesDialogTitle: {
        id: 'Preset.deletePresetImagesDialogTitle',
        defaultMessage: 'Удалить выбранные иконки?',
    },
    deletePresetImageGroups: {
        id: 'Preset.deletePresetImageGroups',
        defaultMessage: 'Будут удалены группы иконок',
    },
    deletePresetImages: {
        id: 'Preset.deletePresetImages',
        defaultMessage: 'Будут удалены иконки',
    },
    deleteSymbols: {
        id: 'Preset.deleteSymbols',
        defaultMessage: 'Будут удалены символы',
    },
    queryType: {
        id: 'Preset.queryType',
        defaultMessage: 'Тип запроса',
    },
    readOnly: {
        id: 'Preset.readOnly',
        defaultMessage: 'Только для чтения пользователем',
    },
});
