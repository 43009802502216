import { TReducer } from '@/utils/types';
import { TSearchedDeletedElementsState } from './searchedDeletedElements.reducer.types';
import {
    ADD_SEARCHED_DELETED_ELEMENTS,
    CLEAR_SEARCHED_DELETED_ELEMENTS,
    ERASE_SEARCHED_DELETED_ELEMENTS_SUCCESS,
    RESTORE_SEARCHED_DELETED_ELEMENTS_SUCCESS,
} from '@/actionsTypes/searchedDeletedElements.actionTypes';

const initial: TSearchedDeletedElementsState = {
    byRepositoryId: {},
};

const initStateWithRepository = (state: TSearchedDeletedElementsState, repositoryId: string) => {
    if (!state.byRepositoryId[repositoryId]) {
        state.byRepositoryId[repositoryId] = {
            byId: {},
        };
    }
};

export const searchedDeletedElementsReducer: TReducer<TSearchedDeletedElementsState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_SEARCHED_DELETED_ELEMENTS: {
            const { repositoryId, id, searchResult } = action.payload;
            initStateWithRepository(state, repositoryId);
            state.byRepositoryId[repositoryId].byId[id] = searchResult;

            return { ...state };
        }
        case ERASE_SEARCHED_DELETED_ELEMENTS_SUCCESS:
        case RESTORE_SEARCHED_DELETED_ELEMENTS_SUCCESS: {
            const { repositoryId, id, idsToDelete } = action.payload;

            initStateWithRepository(state, repositoryId);
            state.byRepositoryId[repositoryId].byId[id] = state.byRepositoryId[repositoryId].byId[id].filter(
                (searchResultItem) => !idsToDelete.includes(searchResultItem.nodeId.id),
            );

            return { ...state };
        }
        case CLEAR_SEARCHED_DELETED_ELEMENTS: {
            const { repositoryId, id } = action.payload;

            initStateWithRepository(state, repositoryId);
            delete state.byRepositoryId[repositoryId].byId[id];

            return { ...state };
        }
        default:
            return state;
    }
};
