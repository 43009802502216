import type { IOverlayPart } from '../../mxgraph/overlays/BPMMxCellOverlay';
import type { TAlign, TBaseline } from '../../mxgraph/mxgraph.types';
import BPMMxCellOverlay from '../../mxgraph/overlays/BPMMxCellOverlay';
import { MxPoint, MxRectangle } from '../../mxgraph/mxgraph';

export const getUmlAttributesBounds = (
    absolutePoints: MxPoint[],
    part: IOverlayPart,
    area: { width: number; height: number },
    align: TAlign,
    verticalAlign: TBaseline,
    scale: number,
) => {
    const { width: textWidth } = BPMMxCellOverlay.getActualTextSize({
        text: part.text,
        fontStyle: part.style,
        areaWidth: area.width,
    });

    const startX = absolutePoints[0].x;
    const startY = absolutePoints[0].y;
    const startX1 = absolutePoints[1].x;
    const startY1 = absolutePoints[1].y;

    const endX = absolutePoints[absolutePoints.length - 1].x;
    const endX1 = absolutePoints[absolutePoints.length - 2].x;
    const endY = absolutePoints[absolutePoints.length - 1].y;
    const endY1 = absolutePoints[absolutePoints.length - 2].y;

    const isStartXReverse = startX > startX1;
    const isEndXReverse = endX < endX1;
    const isStartYReverse = startY > startY1;
    const isEndYReverse = endY < endY1;

    const isStartHorizontal = absolutePoints[0].x !== absolutePoints[1].x;
    const isEndHorizontal = absolutePoints[absolutePoints.length - 1].x !== absolutePoints[absolutePoints.length - 2].x;
    let x: number = 0;
    let y: number = 0;
    const shiftY = 5;
    const n = Math.min(Math.ceil(textWidth / area.width), 3);
    const newWidth = Math.min(textWidth, area.width) * scale;
    const newHeight = Math.min(area.height, n * 14) * scale;

    if (isStartHorizontal) {
        if (align === 'left' && verticalAlign === 'top') {
            x = isStartXReverse ? startX - newWidth : startX;
            y = startY - newHeight - shiftY;
        }
        if (align === 'left' && verticalAlign === 'bottom') {
            x = isStartXReverse ? startX - newWidth : startX;
            y = startY + shiftY;
        }
    } else {
        if (align === 'left' && verticalAlign === 'top') {
            x = startX;
            y = isStartYReverse ? startY - newHeight - shiftY * 2 : startY + shiftY * 2;
        }
        if (align === 'left' && verticalAlign === 'bottom') {
            x = startX - newWidth;
            y = isStartYReverse ? startY - newHeight - shiftY * 2 : startY + shiftY * 2;
        }
    }

    if (isEndHorizontal) {
        if (align === 'right' && verticalAlign === 'top') {
            x = isEndXReverse ? endX : endX - newWidth;
            y = endY - newHeight - shiftY;
        }
        if (align === 'right' && verticalAlign === 'bottom') {
            x = isEndXReverse ? endX : endX - newWidth;
            y = endY + shiftY;
        }
    } else {
        if (align === 'right' && verticalAlign === 'top') {
            x = endX;
            y = isEndYReverse ? endY + shiftY * 2 : endY - newHeight - shiftY * 2;
        }
        if (align === 'right' && verticalAlign === 'bottom') {
            x = endX - newWidth;
            y = isEndYReverse ? endY + shiftY * 2 : endY - newHeight - shiftY * 2;
        }
    }

    return new MxRectangle(x, y, newWidth, newHeight);
};
