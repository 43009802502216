import { TWorkspaceTab, TWorkspaceTabItemParams } from './tab.types';
import { defaultWorkspaceTabActions } from './tab';
import { EditorMode } from './editorMode';
import { NodeId } from '../serverapi/api';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';

export const homePageTabId: NodeId = {
    id: 'homePageTab',
    repositoryId: 'homePageTab',
    serverId: 'homePageTab',
};

export const homePageTab: TWorkspaceTab = {
    title: '',
    nodeId: homePageTabId,
    type: WorkSpaceTabTypes.HOME_PAGE_TAB,
    mode: EditorMode.Read,
    params: {
        closable: false,
    } as TWorkspaceTabItemParams,
    actions: {
        ...defaultWorkspaceTabActions,
    },
};
