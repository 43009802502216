// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HightLighted__hightlighted__aAXfp{background-color:#feedb4}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/H.O.C/HightLighted/HightLighted.scss"],"names":[],"mappings":"AAAA,mCACI,wBAAA","sourcesContent":[".hightlighted {\r\n    background-color: #FEEDB4;\r\n};"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hightlighted": "HightLighted__hightlighted__aAXfp"
};
export default ___CSS_LOADER_EXPORT___;
