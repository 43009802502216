import { Modal } from 'antd';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import theme from './SymbolTextEditorDialog.scss';
import messages from './SymbolTextEditorDialog.messages';
import { shouldCallModalSubmitHandler } from '../../../../../../../services/utils/ModalHelper';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TDialogProps = WrappedComponentProps & {
    graphical: string;
    onSubmit: (graphical: string) => void;
    onClose: () => void;
};

const SymbolTextEditorDialogComponent = (props: TDialogProps) => {
    const { intl, onClose, onSubmit } = props;
    const [graphical, setGraphical] = useState<string>(props.graphical);

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: () => onSubmit(graphical),
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    onSubmit(graphical);
                }
            }}
        >
            <Modal
                width={800}
                open
                bodyStyle={{ height: '480px' }}
                footer={footer}
                onOk={() => onSubmit(graphical)}
                onCancel={onClose}
                className={theme.symbolGraphicalText}
                title={intl.formatMessage(messages.symbolTextEditorDialogTitle)}
            >
                <textarea className={theme.textArea} onChange={(event) => setGraphical(event.target.value)}>
                    {graphical}
                </textarea>
            </Modal>
        </div>
    );
};

const IntlComponent = injectIntl(SymbolTextEditorDialogComponent);

export { IntlComponent as SymbolTextEditorDialog };
