import { put, takeEvery } from 'redux-saga/effects';
import {
    createExternalSessionSuccess,
    deleteExternalSessionRequest,
    deleteExternalSessionSuccess,
    getExternalSessionsSuccess,
} from '../actions/externalSession.actions';
import {
    TCreateExternalSessionRequestAction,
    TDeleteExternalSessionListRequestAction,
    TDeleteExternalSessionRequestAction,
    TGetExternalSessionsRequestAction,
} from '../actions/externalSessions.actions.types';
import {
    CREATE_EXTERNAL_SESSION_REQUEST,
    DELETE_EXTERNAL_SESSION_LIST_REQUEST,
    DELETE_EXTERNAL_SESSION_REQUEST,
    GET_EXTERNAL_SESSIONS_REQUEST,
} from '../actionsTypes/externalSession.actionTypes';
import { ExternalSession } from '../serverapi/api';
import { apiBundle } from '../services/api/api-bundle';

function* handleGetExternalSessions({ payload: { serverId } }: TGetExternalSessionsRequestAction) {
    const externalSessions: ExternalSession[] = yield apiBundle(serverId).session.getExternalActiveSessions();
    yield put(getExternalSessionsSuccess(externalSessions, serverId));
}

function* handleCreateExternalSessionRequest({ payload: { body, serverId } }: TCreateExternalSessionRequestAction) {
    const externalSession: ExternalSession = yield apiBundle(serverId).session.createExternalSession({ body });
    yield put(createExternalSessionSuccess(externalSession, serverId));
}

function* handleDeleteExternalSession({
    payload: { serverId, externalSessionId },
}: TDeleteExternalSessionRequestAction) {
    yield apiBundle(serverId).session.closeSession({ sessionId: externalSessionId });
    yield put(deleteExternalSessionSuccess(externalSessionId, serverId));
}

function* handleDeleteExternalSessionList({
    payload: { serverId, externalSessionIds },
}: TDeleteExternalSessionListRequestAction) {
    for (const externalSessionId of externalSessionIds) {
        yield put(deleteExternalSessionRequest(externalSessionId, serverId));
    }
}

export function* externalSessionSaga() {
    yield takeEvery(GET_EXTERNAL_SESSIONS_REQUEST, handleGetExternalSessions);
    yield takeEvery(CREATE_EXTERNAL_SESSION_REQUEST, handleCreateExternalSessionRequest);
    yield takeEvery(DELETE_EXTERNAL_SESSION_REQUEST, handleDeleteExternalSession);
    yield takeEvery(DELETE_EXTERNAL_SESSION_LIST_REQUEST, handleDeleteExternalSessionList);
}
