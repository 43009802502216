import {
    CREATE_EVENT,
    CREATE_EVENT_SUCCESS,
    DELETE_EVENT,
    DELETE_EVENT_SUCCESS,
    EVENT_DIALOG_ADD_NODE_ID,
    EVENT_DIALOG_ADD_NODE_PATH,
    EVENT_DIALOG_DELETE_NODE_ID,
    EVENT_DIALOG_DELETE_PARENT_NODE_ID,
    EVENT_DIALOG_DELETE_SCRIPT_NODE_ID,
    FETCH_ALL_EVENTS,
    FETCH_EVENTS_SUCCESS,
    OPEN_EVENT_ACCESS_TAB,
    UPDATE_EVENT,
    UPDATE_EVENT_SUCCESS,
} from '../actionsTypes/eventAccess.actionTypes';
import { TreeNode } from '../models/tree.types';
import { NodeId } from '../serverapi/api';
import {
    TCreateEventAction,
    TCreateEventPayload,
    TCreateEventSuccessAction,
    TCreateEventSuccessPayload,
    TDeleteEventAction,
    TDeleteEventSuccessAction,
    TEventDialogAddNodeIdAction,
    TEventDialogAddNodePathAction,
    TEventDialogDeleteNodeIdAction,
    TEventDialogDeleteParentNodeIdAction,
    TEventDialogDeleteScriptNodeIdAction,
    TEventsFetchedSuccessAction,
    TEventsFetchedSuccessPayload,
    TFetchAllEventsRequestAction,
    TOpenEventAccessTabAction,
    TUpdateEventAction,
    TUpdateEventPayload,
    TUpdateEventSuccessAction,
    TUpdateEventSuccessPayload,
} from './eventAccess.actions.types';

export const openEventAccessTab = (node: TreeNode): TOpenEventAccessTabAction => ({
    type: OPEN_EVENT_ACCESS_TAB,
    payload: { node },
});

export const fetchAllEventsRequest = (serverId: string): TFetchAllEventsRequestAction => ({
    type: FETCH_ALL_EVENTS,
    payload: {
        serverId,
    },
});

export const eventsFetchedSuccess = ({
    serverId,
    events,
}: TEventsFetchedSuccessPayload): TEventsFetchedSuccessAction => ({
    type: FETCH_EVENTS_SUCCESS,
    payload: {
        events,
        serverId,
    },
});

export const createEvent = (payload: TCreateEventPayload): TCreateEventAction => ({
    type: CREATE_EVENT,
    payload,
});

export const updateEvent = (payload: TUpdateEventPayload): TUpdateEventAction => ({
    type: UPDATE_EVENT,
    payload,
});

export const deleteEvent = (serverId: string, id: string): TDeleteEventAction => ({
    type: DELETE_EVENT,
    payload: {
        serverId,
        id,
    },
});

export const deleteEventSuccess = ({ id, serverId }): TDeleteEventSuccessAction => ({
    type: DELETE_EVENT_SUCCESS,
    payload: {
        serverId,
        id,
    },
});

export const createEventSuccess = ({ event, serverId }: TCreateEventSuccessPayload): TCreateEventSuccessAction => ({
    type: CREATE_EVENT_SUCCESS,
    payload: {
        serverId,
        event,
    },
});

export const updateEventSuccess = ({ event, serverId }: TUpdateEventSuccessPayload): TUpdateEventSuccessAction => ({
    type: UPDATE_EVENT_SUCCESS,
    payload: {
        serverId,
        event,
    },
});

export const eventDialogAddNodeId = (paramName: string, nodeId: NodeId): TEventDialogAddNodeIdAction => {
    return {
        type: EVENT_DIALOG_ADD_NODE_ID,
        payload: {
            paramName,
            nodeId,
        },
    };
};

export const eventDialogAddNodePath = (paramName: string, nodePath: string): TEventDialogAddNodePathAction => {
    return {
        type: EVENT_DIALOG_ADD_NODE_PATH,
        payload: {
            paramName,
            nodePath,
        },
    };
};

export const eventDialogDeleteNodeId = (nodeIdWasCleared?: boolean): TEventDialogDeleteNodeIdAction => {
    return {
        type: EVENT_DIALOG_DELETE_NODE_ID,
        payload: {
            nodeIdWasCleared,
        },
    };
};

export const eventDialogDeleteParentNodeId = (nodeIdWasCleared?: boolean): TEventDialogDeleteParentNodeIdAction => {
    return {
        type: EVENT_DIALOG_DELETE_PARENT_NODE_ID,
        payload: {
            nodeIdWasCleared,
        },
    };
};

export const eventDialogDeleteScriptNodeId = (nodeIdWasCleared?: boolean): TEventDialogDeleteScriptNodeIdAction => {
    return {
        type: EVENT_DIALOG_DELETE_SCRIPT_NODE_ID,
        payload: {
            nodeIdWasCleared,
        },
    };
};
