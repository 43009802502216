// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchPathDialog__treeContainer__AQU6D{height:300px}", "",{"version":3,"sources":["webpack://./src/modules/Search/SearchPathDialog/SearchPathDialog.scss"],"names":[],"mappings":"AACI,wCACI,YAAA","sourcesContent":[":local {\r\n    .treeContainer {\r\n        height: 300px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"treeContainer": "SearchPathDialog__treeContainer__AQU6D"
};
export default ___CSS_LOADER_EXPORT___;
