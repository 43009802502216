import { Remarkable } from 'remarkable';
import { DraftInlineStyleType, RawDraftContentBlock, RawDraftEntityRange, RawDraftInlineStyleRange } from 'draft-js';
import { fontFamilyParser } from '../parse/inline/fontFamily';
import { fontSizeParser } from '../parse/inline/fontSize';
import { BlockContentToken, ContentToken, Token } from 'remarkable/lib';
import { addBlockStyleItems, addStyleItem, getBlockStyleItems, getStyleItems } from '../entities';
import { TBlockEntities, TBlockTypes, TBlockStyles } from '../RichTextEditor.types';
import { colorParser } from '../parse/inline/color';
import { bgColorParser } from '../parse/inline/bgColor';
import { TextAlignDecorator } from '../decorators/TextAlign/TextAlignDecorator';
import { ImageDecorator } from '../decorators/Image/ImageDecorator';

const TRAILING_NEW_LINE = /\n$/;

type TExtendedMiscTokenProps = any & {
    content?: string;
    align?: string;
};

// In DraftJS, string lengths are calculated differently than in JS itself (due
// to surrogate pairs). Instead of importing the entire UnicodeUtils file from
// FBJS, we use a simpler alternative, in the form of `Array.from`.
//
// Alternative:  const { strlen } = require('fbjs/lib/UnicodeUtils');
function strlen(str: string) {
    return Array.from(str).length;
}

// Block level items, key is Remarkable's key for them, value returned is
// A function that generates the raw draftjs key and block data.
//
// Why a function? Because in some cases (headers) we need additional information
// before we can determine the exact key to return. And blocks may also return data
const DefaultBlockTypes = {
    paragraph_open: (): RawDraftContentBlock => ({
        type: 'unstyled',
        text: '',
        entityRanges: [],
        inlineStyleRanges: [],
        key: '',
        depth: 0,
    }),

    blockquote_open: () => ({
        type: 'blockquote',
        text: '',
    }),

    ordered_list_item_open: () => ({
        type: 'ordered-list-item',
        text: '',
    }),

    unordered_list_item_open: () => ({
        type: 'unordered-list-item',
        text: '',
    }),

    fence: (item: TExtendedMiscTokenProps) => ({
        type: 'code-block',
        data: {
            language: item.params || '',
        },
        // remarkable seems to always append an erronious trailing newline
        // to its codeblock content, so we need to trim it out.
        text: (item.content || '').replace(TRAILING_NEW_LINE, ''),
        entityRanges: [],
        inlineStyleRanges: [],
    }),

    heading_open: (item: TExtendedMiscTokenProps) => {
        const type = `header-${
            {
                1: 'one',
                2: 'two',
                3: 'three',
                4: 'four',
                5: 'five',
                6: 'six',
            }[item.hLevel ? item.hLevel : '']
        }`;

        return {
            type,
            text: '',
        };
    },
};

// Entity types. These are things like links or images that require
// additional data and will be added to the `entityMap`
// again. In this case, key is remarkable key, value is
// meethod that returns the draftjs key + any data needed.
const DefaultBlockEntities = {
    link_open(item: TExtendedMiscTokenProps) {
        return {
            type: 'LINK',
            mutability: 'MUTABLE',
            data: {
                url: item.href,
                href: item.href,
            },
        };
    },
};

// Key generator for entityMap items
let idCounter = -1;

function generateUniqueKey() {
    idCounter++;

    return idCounter;
}

function parseInline(inlineItem: BlockContentToken, BlockEntities: TBlockEntities) {
    let content = '';
    const blockEntities = {};
    const blockEntityRanges: Array<RawDraftEntityRange> = [];
    let blockInlineStyleRanges: Array<RawDraftInlineStyleRange> = [];

    inlineItem.children &&
        inlineItem.children.forEach((child: ContentToken, index: number) => {
            const styleSeparated = child.type.split('-');
            if (
                styleSeparated &&
                styleSeparated.length > 1 &&
                ['fontFamily', 'fontSize', 'color', 'bgcolor'].includes(styleSeparated[0])
            ) {
                addStyleItem(getStyleItems()[styleSeparated[0]](styleSeparated[1]));
                addBlockStyleItems((<Function>getBlockStyleItems()[styleSeparated[0]])(styleSeparated[1]));
            }
            const BlockStyles = <TBlockStyles>getBlockStyleItems();

            const key = generateUniqueKey();
            if (child.type === 'text') {
                content += child.content;
            } else if (child.type === 'softbreak') {
                content += '\n';
            } else if (BlockStyles[child.type]) {
                const style = BlockStyles[child.type];

                const styleBlock: RawDraftInlineStyleRange = {
                    offset: strlen(content) || 0,
                    length: strlen(child.content || ''),
                    style: <DraftInlineStyleType>(typeof style === 'string' ? style : child.type),
                };

                // Edge case hack because code items don't have inline content or open/close, unlike everything else
                if (child.type === 'code' || child.type === 'sub' || child.type === 'sup') {
                    styleBlock.length = strlen(child.content);
                    content += child.content;
                }

                blockInlineStyleRanges.push(styleBlock);
            } else if (BlockEntities[child.type]) {
                blockEntities[key] = BlockEntities[child.type](child);
                blockEntityRanges.push({
                    offset: strlen(content) || 0,
                    length: 0,
                    key,
                });
            } else if (child.type.indexOf('_close') !== -1 && BlockEntities[child.type.replace('_close', '_open')]) {
                const ranges = blockEntityRanges[blockEntityRanges.length - 1];
                if (ranges) {
                    ranges.length = strlen(content) - blockEntityRanges[blockEntityRanges.length - 1]?.offset;
                }
            } else if (child.type.indexOf('_close') !== -1 && BlockStyles[child.type.replace('_close', '_open')]) {
                const type = <string>BlockStyles[child.type.replace('_close', '_open')];

                blockInlineStyleRanges = blockInlineStyleRanges.map((style: RawDraftInlineStyleRange) => {
                    if (style.length === 0 && style.style === type) {
                        style.length = strlen(content) - style.offset;
                    }

                    return style;
                });
            }
        });

    return { content, blockEntities, blockEntityRanges, blockInlineStyleRanges };
}

/*
 * Convert markdown into raw draftjs object
 *
 * @param {String} markdown - markdown to convert into raw draftjs object
 * @param {Object} options - optional additional data, see readme for what options can be passed in.
 *
 * @return {Object} rawDraftObject
 */
type TMarkdownToDraft = {
    blockEntities: TBlockEntities;
    blockStyles?: TBlockStyles;
    blockTypes?: TBlockTypes;
    remarkableOptions?: Remarkable.Options;
    remarkablePlugins: Remarkable.Plugin[];
    preserveNewlines?: boolean;
};

function markdownToDraft(str: string, options: TMarkdownToDraft = <TMarkdownToDraft>{}) {
    const md = new Remarkable(options.remarkableOptions);

    // md.block.ruler.disable(['table']);

    md.inline.ruler.enable(['sub', 'sup', 'ins']);
    md.inline.ruler.push('fontfamily', fontFamilyParser, {});
    md.inline.ruler.push('fontsize', fontSizeParser, {});
    md.inline.ruler.push('color', colorParser, {});
    md.inline.ruler.push('bgcolor', bgColorParser, {});
    md.inline.ruler.push('textAlign', TextAlignDecorator.parser, {});
    md.inline.ruler.push('image', ImageDecorator('').parser, {});
    // If users want to define custom remarkable plugins for custom markdown, they can be added here
    if (options.remarkablePlugins) {
        options.remarkablePlugins.forEach((plugin: Remarkable.Plugin) => {
            md.use(plugin, {});
        });
    }
    // blocks will be returned as part of the final draftjs raw object
    const blocks: RawDraftContentBlock[] = [];
    // entitymap will be returned as part of the final draftjs raw object
    const entityMap = {};
    // remarkable js takes markdown and makes it an array of style objects for us to easily parse
    let parsedData: Token[] = [];
    try {
        parsedData = md.parse(str, {});
    } catch (e) {
        console.error('Parsing error', e);
    }
    // Because of how remarkable's data is formatted, we need to cache what kind of list we're currently dealing with
    let currentListType: string | null = null;
    let previousBlockEndingLine = 1;

    // Allow user to define custom BlockTypes and Entities if they so wish
    const BlockTypes = { ...DefaultBlockTypes, ...(options.blockTypes || {}) };
    const BlockEntities = { ...DefaultBlockEntities, ...(options.blockEntities || {}) };

    parsedData.forEach((item: Token) => {
        // Because of how remarkable's data is formatted,
        // we need to cache what kind of list we're currently dealing with
        if (item.type === 'bullet_list_open') {
            currentListType = 'unordered_list_item_open';
        } else if (item.type === 'ordered_list_open') {
            currentListType = 'ordered_list_item_open';
        }

        let itemType = item.type;
        if (itemType === 'list_item_open') {
            itemType = <string>currentListType;
        }

        if (itemType === 'inline') {
            // Parse inline content and apply it to the most recently created block level item,
            // which is where the inline content will belong.
            const { content, blockEntities, blockEntityRanges, blockInlineStyleRanges } = parseInline(
                <BlockContentToken>item,
                // @ts-ignore
                BlockEntities,
            );
            const blockToModify = blocks[blocks.length - 1];
            blockToModify.text = content;
            blockToModify.inlineStyleRanges = blockInlineStyleRanges;
            blockToModify.entityRanges = blockEntityRanges;
            // The entity map is a master object separate from the block so just add
            // any entities created for this block to the master object
            Object.assign(entityMap, blockEntities);
        } else if ((itemType.indexOf('_open') !== -1 || itemType === 'fence') && BlockTypes[itemType]) {
            let depth = 0;
            let block;

            if (item.level > 0) {
                depth = Math.floor(item.level / 2);
            }

            // Draftjs only supports 1 level of blocks, hence the item.level === 0 check
            // List items will always be at least `level==1` though so we need a separate check for that
            // If there’s nested block level items deeper than that,
            // we need to make sure we capture this by cloning the topmost block
            // otherwise we’ll accidentally overwrite its text.
            // (eg if there's a blockquote with 3 nested paragraphs with inline text,
            // without this check, only the last paragraph would be reflected)
            if (item.level === 0 || item.type === 'list_item_open') {
                block = { depth, ...BlockTypes[itemType](item) };
            } else if (item.level > 0 && blocks[blocks.length - 1].text) {
                block = { ...blocks[blocks.length - 1] };
            }

            if (block && options.preserveNewlines) {
                // Re: previousBlockEndingLine.... omg.
                // So remarkable strips out empty newlines and doesn't make any entities to parse to restore them
                // the only solution I could find is that there's a 2-value array on each block item called "lines"
                // which is the start end line of the block element.
                // by keeping track of the PREVIOUS block element ending line and the NEXT block element starting
                // line, we can find the difference between the new lines and insert
                // an appropriate number of extra paragraphs to re-create those newlines in draftjs.
                // This is probably my least favourite thing in this file, but not sure what could be better.
                if (previousBlockEndingLine && item.lines) {
                    const totalEmptyParagraphsToCreate = item.lines[0] - previousBlockEndingLine;
                    for (let i = 0; i < totalEmptyParagraphsToCreate; i++) {
                        blocks.push(DefaultBlockTypes.paragraph_open());
                    }
                }
            }

            if (block && item.lines) {
                previousBlockEndingLine = item.lines[1] + 1;
                blocks.push(block);
            }
        }
    });

    // EditorState.createWithContent will error if there's no blocks defined
    // Remarkable returns an empty array though. So we have to generate a 'fake'
    // empty block in this case. 😑
    if (!blocks.length) {
        blocks.push(DefaultBlockTypes.paragraph_open());
    }

    return {
        entityMap,
        blocks,
    };
}

export { markdownToDraft };
