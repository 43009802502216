export class PictureSymbolConstants {
    public static readonly PICTURE_SYMBOL_ID: string = 'objectType.position.picture';

    public static readonly PICTURE_SYMBOL_TYPE: string = 'picture';

    public static readonly PICTURE_SYMBOL_NAME: string = ''; // Picture symbol shouldn't have text label by default

    public static readonly SHAPE_IMAGE: string = 'shape=image';

    public static readonly DOWNLOAD_LINK: string = 'api/file/download';

    public static readonly EDGE_TYPE_ID: string = 'edgeType.Directional';

    public static readonly EDGE_TYPE_STYLE: string = 'startArrow=none;endArrow=block;';

    public static readonly EDGE_TYPE_DIRECTION = 'SRC_TO_DST';

    public static readonly EDGE_TYPE_LOCALIZIBLE_NAME: string = 'directional';

    public static readonly PICTURE_SYMBOL_COLOR: string = 'RGB(30,255,192)';

    public static readonly EXTRA_BUTTON_IMAGE: string = 'api/file/button-image';
}
