export const ADD_STORE_EDIT_KANBAN_MODEL_TYPE_WORKSPACE_TAB = 'ADD_STORE_EDIT_KANBAN_MODEL_TYPE_WORKSPACE_TAB';
export const CHANGE_KANBAN_MODEL_TYPE_NAME = 'CHANGE_KANBAN_MODEL_TYPE_NAME';
export const CHANGE_KANBAN_MODEL_TYPE_ID = 'CHANGE_KANBAN_MODEL_TYPE_ID';
export const CHANGE_KANBAN_MODEL_TYPE_DESCRIPTION = 'CHANGE_KANBAN_MODEL_TYPE_DESCRIPTION';
export const ADD_SYMBOLS_KANBAN_MODEL_TYPE = 'ADD_SYMBOLS_KANBAN_MODEL_TYPE';
export const ADD_CARDS_KANBAN_MODEL_TYPE = 'ADD_CARDS_KANBAN_MODEL_TYPE';
export const DELETE_SYMBOLS_KANBAN_MODEL_TYPE = 'DELETE_SYMBOLS_KANBAN_MODEL_TYPE';
export const DELETE_CARDS_KANBAN_MODEL_TYPE = 'DELETE_CARDS_KANBAN_MODEL_TYPE';
export const CHANGE_KANBAN_MODEL_TYPE_GROUP_ID = 'CHANGE_KANBAN_MODEL_TYPE_GROUP_ID';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMNS_SETTINGS = 'CHANGE_KANBAN_MODEL_TYPE_COLUMNS_SETTINGS';
export const ADD_KANBAN_MODEL_TYPE_COLUMN = 'ADD_KANBAN_MODEL_TYPE_COLUMN';
export const DELETE_KANBAN_MODEL_TYPE_COLUMN = 'DELETE_KANBAN_MODEL_TYPE_COLUMN';
export const MOVE_KANBAN_MODEL_TYPE_COLUMN = 'MOVE_KANBAN_MODEL_TYPE_COLUMN';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_NAME = 'CHANGE_KANBAN_MODEL_TYPE_COLUMN_NAME';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_ID = 'CHANGE_KANBAN_MODEL_TYPE_COLUMN_ID';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_TYPE = 'CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_TYPE';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_VALUE = 'CHANGE_KANBAN_MODEL_TYPE_COLUMN_WIDTH_VALUE';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_COLOR = 'CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_COLOR';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_COLOR = 'CHANGE_KANBAN_MODEL_TYPE_COLUMN_COLOR';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_CARDS_VIEW_IN_COLUMN =
    'CHANGE_KANBAN_MODEL_TYPE_COLUMN_CARDS_VIEW_IN_COLUMN';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_MIN_CARD_WIDTH_FOR_ROW_VIEW =
    'CHANGE_KANBAN_MODEL_TYPE_COLUMN_MIN_CARD_WIDTH_FOR_ROW_VIEW';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_HEADER_ICON = 'CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_HEADER_ICON';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_ICON = 'CHANGE_KANBAN_MODEL_TYPE_COLUMN_HEADER_ICON';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_CARDS_LIMIT_IN_COLUMN =
    'CHANGE_KANBAN_MODEL_TYPE_COLUMN_HAS_CARDS_LIMIT_IN_COLUMN';
export const CHANGE_KANBAN_MODEL_TYPE_COLUMN_MAX_CARDS_IN_COLUMN =
    'CHANGE_KANBAN_MODEL_TYPE_COLUMN_MAX_CARDS_IN_COLUMN';
export const ADD_KANBAN_MODEL_TYPE_PLACING_RULE = 'ADD_KANBAN_MODEL_TYPE_PLACING_RULE';
export const CHANGE_KANBAN_MODEL_TYPE_PLACING_RULE = 'CHANGE_KANBAN_MODEL_TYPE_PLACING_RULE';
export const DELETE_KANBAN_MODEL_TYPE_PLACING_RULE = 'DELETE_KANBAN_MODEL_TYPE_PLACING_RULE';
export const MOVE_KANBAN_MODEL_TYPE_PLACING_RULE = 'MOVE_KANBAN_MODEL_TYPE_PLACING_RULE';
export const ADD_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE = 'ADD_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE';
export const CHANGE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE = 'CHANGE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE';
export const DELETE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE = 'DELETE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE';
export const MOVE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE = 'MOVE_KANBAN_MODEL_TYPE_OBJECT_CHANGING_RULE';
