import { AllowedScriptContext } from '../../../../serverapi/api';
import { scriptContextTypes } from './scriptContext.types';

export const checkIsRestrictByType = (
    allowedScriptContext: AllowedScriptContext,
    contextToEditType: scriptContextTypes,
): boolean => {
    switch (contextToEditType) {

        case scriptContextTypes.runningOnModels: {
            return !allowedScriptContext.allowAllModels
        }

        case scriptContextTypes.runningOnObjects: {
            return !allowedScriptContext.allowAllObjects
        }

        case scriptContextTypes.runningOnEdges: {
            return !allowedScriptContext.allowAllEdges
        }

        case scriptContextTypes.runningOnObjectInstances: {
            return !allowedScriptContext.allowAllSymbols
        }

        case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
            return (!allowedScriptContext.allowedModelSymbols || !!allowedScriptContext.allowedModelSymbols.length)
        }
        case scriptContextTypes.runningOnDB: {
            return !allowedScriptContext.allowAllDBs
            
        }
        case scriptContextTypes.runningOnFiles: {
            return !allowedScriptContext.allowAllFiles
            
        }
        case scriptContextTypes.runningOnFolders: {
            return !allowedScriptContext.allowAllFolders
            
        }
        case scriptContextTypes.runningOnScripts: {
            return !allowedScriptContext.allowAllScripts
            
        }
        default: {
            return false;
        }
    }
};
