import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { MethodologiesGraph } from '../../../../mxgraph/MethodologiesGraph';
import { ModelType, ObjectInstanceInfo, Symbol } from '../../../../serverapi/api';
import theme from './NavigatorProperties.scss';
import { SymbolToImageConverterGraph } from '../../../AdminTools/Methodology/components/Presets/SymbolToImageConverterGraph.component';
import { TreeSelectors } from '../../../../selectors/tree.selectors';
import { getSelectedState } from '../../../../selectors/navigatorProperties.selectors';
import { SymbolSelectors } from '../../../../selectors/symbol.selectors';
import { openNode } from '../../../../actions/openNode.actions';
import { TreeItemType } from '../../../Tree/models/tree';
import { SymbolViewEnum } from '../../../../models/navigatorPropertiesSelectorState.types';

type TSymbolViewProps = {
    objectInstanceInfoList?: Array<ObjectInstanceInfo>;
    modelId?: string;
    entryCount?: number;
};

export const SymbolView = (props: TSymbolViewProps) => {
    const { objectInstanceInfoList, modelId, entryCount } = props;
    const { currentGraphId, nodeId, symbolViewMode: symbolView } = useSelector(getSelectedState);
    const [graph, setGraph] = useState<MethodologiesGraph | undefined>();

    const intl = useIntl();
    const presetId: string = useSelector(TreeSelectors.presetById(currentGraphId || nodeId));
    const symbols: Symbol[] = useSelector(SymbolSelectors.byServerIdPresetId(nodeId.serverId, presetId));
    const dispatch = useDispatch();
    const onSymbolClick = (elementId?: string) =>
        elementId &&
        modelId &&
        dispatch(openNode({ nodeId: { ...nodeId, id: modelId }, type: TreeItemType.Model, elementIds: [elementId] }));

    const converterInitialized = (g: MethodologiesGraph) => {
        if (!graph) {
            setGraph(g);
        }
    };

    return (
        <div>
            <SymbolToImageConverterGraph modelType={{ id: uuid() } as ModelType} initialized={converterInitialized} />
            {symbolView === SymbolViewEnum.SYMBOLS
                ? objectInstanceInfoList?.map((inst: ObjectInstanceInfo) => {
                      const symbol: Symbol | undefined = symbols.find((s) => s.id === inst.symbolId);

                      return (
                          <div
                              className={theme.symbolContainer}
                              onClick={() => onSymbolClick(inst.elementId)}
                              key={inst.elementId}
                          >
                              {symbol ? SymbolToImageConverterGraph.convertSymbol(symbol, intl, graph) : inst.symbolId}
                          </div>
                      );
                  })
                : entryCount?.toString()}
        </div>
    );
};
