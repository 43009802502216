import type { TreeItemType } from '../../../Tree/models/tree';
import type { NodeId } from '@/serverapi/api';

export const urlDataTextCheck = (dataTextValue: string, isElectron?: boolean): string => {
    const urlDataText: string = dataTextValue;
    const mail = `${urlDataText}`.includes('mailto:');

    if (mail) {
        return urlDataText;
    }

    if (!urlDataText.includes('://')) {
        if (isElectron) {
            return `${'http://'}${urlDataText}`;
        }

        return `${'//'}${dataTextValue}`;
    }

    return urlDataText;
};

export const prepareUrl = (url: string, nodeId?: NodeId, nodeType?: TreeItemType) => {
    if (url.trim().startsWith('bpm://') && nodeId && nodeType) {
        return `${urlDataTextCheck(url)}/${nodeId.repositoryId}/${nodeId.id}/${nodeType}`;
    } else {
        return urlDataTextCheck(url);
    }
};

export const prepareTreeItemData = (uri: string) => {
    const partsUri = uri.split('/');
    const type = partsUri[partsUri.length - 1] as TreeItemType;
    const id = partsUri[partsUri.length - 2];
    const repositoryId = partsUri[partsUri.length - 3];

    return {
        type,
        id,
        repositoryId,
    };
};
