import { defineMessages } from 'react-intl';

export default defineMessages({
    settings: {
        id: 'WidgetSettingsPanel.settings',
        defaultMessage: 'Настройки',
    },
    name: {
        id: 'WidgetSettingsPanel.name',
        defaultMessage: 'Название',
    },
    dataTable: {
        id: 'WidgetSettingsPanel.dataTable',
        defaultMessage: 'Таблица данных',
    },
    dataCol: {
        id: 'WidgetSettingsPanel.dataCol',
        defaultMessage: 'Столбец данных',
    },
    legendCol: {
        id: 'WidgetSettingsPanel.legendCol',
        defaultMessage: 'Столбец легенды',
    },
    selectDataTable: {
        id: 'WidgetSettingsPanel.selectDataTable',
        defaultMessage: 'Выберите таблицу данных',
    },
});
