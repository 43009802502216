import React, { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './ApprovalDialog.messages';
import { FormInstance } from 'antd';
import { ApprovalDialog, GENERAL_SETTINGS } from './ApprovalDialog.component';
import { ApprovalDTO, ApprovalDTOStatusEnum, ApprovalStageDTO } from '@/serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { editApproval, submitApproval } from '@/actions/approval.actions';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { TApprovalDialogContainerProps } from './ApprovalDialog.types';
import { ApprovalGeneralSettingsTab } from './ApprovalGeneralSettingsTab.component';

export const ApprovalDialogContainer: FC<TApprovalDialogContainerProps> = (props) => {
    const { isCreateMode, isDraft, open } = props;

    const intl = useIntl();
    const approval: ApprovalDTO = useSelector(ApprovalSelectors.getEditingApproval);
    const dispatch = useDispatch();
    const [tabName, setTabName] = useState<string>(GENERAL_SETTINGS);
    const formRef = useRef<FormInstance>(null);

    const onChangeStages = (stages: ApprovalStageDTO[] | undefined) => {
        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages,
                },
            }),
        );
    };

    const submitModifiedApproval = (stages: ApprovalStageDTO[] | undefined, status: ApprovalDTOStatusEnum | undefined) => {
        dispatch(
            submitApproval({
                approval: {
                    ...approval,
                    name: approval.name.trim(),
                    stages,
                    status,
                },
                isDraft,
                isCreateMode,
            }),
        );
    };

    const onCancel = () => dispatch(closeDialog(DialogType.APPROVAL_DIALOG));

    const generalFormComponent = (
        <ApprovalGeneralSettingsTab approval={approval} isCreateMode={isCreateMode} formRef={formRef} />
    );

    const title = isCreateMode
        ? intl.formatMessage(messages.creatingNewApproval)
        : intl.formatMessage(messages.editApproval);

    return (
        <ApprovalDialog
            onCancel={onCancel}
            tabName={tabName}
            setTabName={setTabName}
            formRef={formRef}
            title={title}
            generalFormComponent={generalFormComponent}
            open={open}
            isCreateMode={isCreateMode}
            approval={approval}
            onChangeStages={onChangeStages}
            submitModifiedApproval={submitModifiedApproval}
        />
    );
};
