import { BPMMxPopupMenuHandler } from '../BPMGraphClasses';
import { isNullOrUndefined } from 'is-what';
import { MxMouseEvent, MxCell } from '../mxgraph';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolManager.class';

export class SequencePopupMenuHandler extends BPMMxPopupMenuHandler {
    getCellForPopupEvent(me: MxMouseEvent): MxCell | null {
        let result: MxCell | null = me.getCell();

        if (!isNullOrUndefined(result)) {
            result = ComplexSymbolManager.getComplexSymbolRootCell(result) || result;
        }

        return result;
    }
}
