import { put, takeEvery, call } from 'redux-saga/effects';
import { FETCH_ALL_EVENTS, OPEN_EVENT_ACCESS_TAB, CREATE_EVENT, DELETE_EVENT, EVENT_DIALOG_ADD_NODE_ID, UPDATE_EVENT } from '../actionsTypes/eventAccess.actionTypes';
import { workspaceAddTab } from '../actions/tabs.actions';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTab, TWorkspaceTabItemDefaultParams } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { eventAccessService } from '../services/EventAccessService';
import { admintoolSelect } from '../actions/admintools.actions';
import { AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';
import { createEventSuccess, deleteEventSuccess, eventDialogAddNodePath, eventsFetchedSuccess, fetchAllEventsRequest, updateEventSuccess } from '../actions/eventAccess.actions';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { EventDescriptor, NodeId, RootNodeId } from '../serverapi/api';
import { TCreateEventAction, TDeleteEventAction, TEventDialogAddNodeIdAction, TFetchAllEventsRequestAction, TOpenEventAccessTabAction, TUpdateEventAction } from '../actions/eventAccess.actions.types';
import { principalRequest } from '../actions/principal.actions';
import { fetchNodes } from '../actions/nodes.actions';

function* handleOpenEventAccessTab({ payload: { node } }: TOpenEventAccessTabAction) {
    if (node) {
        const eventAccessTab: TWorkspaceTab = {
            title: node.name,
            nodeId: node.nodeId,
            type: WorkSpaceTabTypes.EVENT_ACCESS,
            mode: EditorMode.Read,
            params: {} as TWorkspaceTabItemDefaultParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
 
        yield put(
            admintoolSelect({ currentServerId: node.nodeId.serverId, activeTypeId: AdminToolTreeType.EVENT_ACCESS }),
        );
        yield put(workspaceAddTab(eventAccessTab));
        yield put(fetchAllEventsRequest(node.nodeId.serverId));
    }
}

function* handleFetchAllEventsRequest({ payload: { serverId } }: TFetchAllEventsRequestAction) {
    const events: EventDescriptor[] = yield call(() => eventAccessService().getEvents({ serverId }));

    yield put(principalRequest(serverId));
    yield put(eventsFetchedSuccess({ serverId, events }));

    const eventNodeElementIds: NodeId[] = [];
    events.forEach(event => {
        if (event?.nodeId) eventNodeElementIds.push(event.nodeId);
        if (event?.parentNodeId) eventNodeElementIds.push(event.parentNodeId);
        if (event?.scriptId) eventNodeElementIds.push({id: event.scriptId, repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID, serverId});
    });
    yield put(fetchNodes(eventNodeElementIds));
}

function* handleCreateEventRequest(action: TCreateEventAction) {
    const { payload } = action;
    const eventNodeElementIds: NodeId[] = [];
    const event: EventDescriptor = yield call(() => eventAccessService().create({serverId: payload.serverId, body: payload}));
    if (event?.nodeId) eventNodeElementIds.push(event.nodeId);
    if (event?.parentNodeId) eventNodeElementIds.push(event.parentNodeId);
    if (event?.scriptId) eventNodeElementIds.push({id: event.scriptId, repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID, serverId: payload.serverId});
    yield put(fetchNodes(eventNodeElementIds));
    yield put(createEventSuccess({ serverId: payload.serverId, event}));
}

function* handleUpdateEventRequest(action: TUpdateEventAction) {
    const { payload } = action;
    const eventNodeElementIds: NodeId[] = [];
    const event: EventDescriptor = yield call(() => eventAccessService().update({serverId: payload.serverId, body: payload}));
    if (event?.nodeId) eventNodeElementIds.push(event.nodeId);
    if (event?.parentNodeId) eventNodeElementIds.push(event.parentNodeId);
    if (event?.scriptId) eventNodeElementIds.push({id: event.scriptId, repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID, serverId: payload.serverId});
    yield put(fetchNodes(eventNodeElementIds));
    yield put(updateEventSuccess({ serverId: payload.serverId, event}));
}

function* handleDeleteEventRequest(action: TDeleteEventAction) {
    const { id, serverId } = action.payload;
    
    yield call(() => eventAccessService().delete({serverId, id}));
    yield put(deleteEventSuccess({ serverId, id }));
}

function* handleEventNodePathRequest(action: TEventDialogAddNodeIdAction) {
    const { paramName, nodeId } = action.payload;
    const { id, repositoryId, serverId } = nodeId;

    if (id && repositoryId && serverId) {
        const { path } = yield TreeDaoService.getNodePath(serverId, id, repositoryId);

        yield put(eventDialogAddNodePath(paramName, path));
    }
}

export function* eventAccessSaga() {
    yield takeEvery(OPEN_EVENT_ACCESS_TAB, handleOpenEventAccessTab);
    yield takeEvery(FETCH_ALL_EVENTS, handleFetchAllEventsRequest);
    yield takeEvery(CREATE_EVENT, handleCreateEventRequest);
    yield takeEvery(DELETE_EVENT, handleDeleteEventRequest);
    yield takeEvery(EVENT_DIALOG_ADD_NODE_ID, handleEventNodePathRequest);
    yield takeEvery(UPDATE_EVENT, handleUpdateEventRequest);
}
