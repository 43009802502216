// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WidgetSelectTableDialog__dialog__MzmRK .ant-modal-header{border-radius:8px}.WidgetSelectTableDialog__dialog__MzmRK .ant-modal-content{border-radius:8px}.WidgetSelectTableDialog__dialog__MzmRK .ant-modal-title{font-weight:600;font-size:20px;line-height:28px}.WidgetSelectTableDialog__dialog__MzmRK .WidgetSelectTableDialog__label__YPr1b{font-size:12px;line-height:18px}.WidgetSelectTableDialog__dialog__MzmRK .WidgetSelectTableDialog__input__mZvDw{border-radius:4px;margin-top:4px}.WidgetSelectTableDialog__treeContainer__PdSJn{height:70vh}", "",{"version":3,"sources":["webpack://./src/modules/Dashboard/WidgetSettingsPanel/WidgetSelectTableDialog/WidgetSelectTableDialog.scss"],"names":[],"mappings":"AAEQ,0DACI,iBAAA,CAEJ,2DACI,iBAAA,CAEJ,yDACI,eAAA,CACA,cAAA,CACA,gBAAA,CAGR,+EACI,cAAA,CACA,gBAAA,CAEJ,+EACI,iBAAA,CACA,cAAA,CAIR,+CACI,WAAA","sourcesContent":[".dialog {\r\n    :global {\r\n        .ant-modal-header {\r\n            border-radius: 8px;\r\n        }\r\n        .ant-modal-content {\r\n            border-radius: 8px;\r\n        }\r\n        .ant-modal-title {\r\n            font-weight: 600;\r\n            font-size: 20px;\r\n            line-height: 28px;\r\n        }\r\n    }\r\n    .label {\r\n        font-size: 12px;\r\n        line-height: 18px;\r\n    }\r\n    .input {\r\n        border-radius: 4px;\r\n        margin-top: 4px;\r\n    }\r\n}\r\n\r\n.treeContainer {\r\n    height: 70vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "WidgetSelectTableDialog__dialog__MzmRK",
	"label": "WidgetSelectTableDialog__label__YPr1b",
	"input": "WidgetSelectTableDialog__input__mZvDw",
	"treeContainer": "WidgetSelectTableDialog__treeContainer__PdSJn"
};
export default ___CSS_LOADER_EXPORT___;
