import { put, race, select, takeEvery, take } from 'redux-saga/effects';
import { OBJECT_DEFINITION_DIALOG_SUBMIT_DATA } from '../actionsTypes/objectDefinitionDialog.actionTypes';
import { objectDefinitionDialogSubmitResult } from '../actions/objectDefinitionDialog.actions';
import { TObjectDefinitionDialogSubmitData } from '../actions/objectDefinitionDialog.actions.types';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { v4 as uuid } from 'uuid';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemsExpandWithoutLoad, treeItemCollapseAll } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { checkObjectDefinition } from '../actions/entities/objectDefinition.actions';
import {
    SAVE_OBJECT_DEFINITION_FAIL,
    SAVE_OBJECT_DEFINITION_SUCCESS,
} from '../actionsTypes/entities/objectDefinition.actionTypes';
import { objectDefinitionService } from '../services/ObjectDefinitionService';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { NodeId, ObjectType } from '../serverapi/api';
import { getChainFromChildToParent } from '../services/utils/treeService.utils';
import { getTreeItems, TreeSelectors } from '../selectors/tree.selectors';
import { ObjectTypeSelectors } from '../selectors/objectType.selectors';
import { presetLoadObjectTypes } from './notation.saga';
import { TTreeEntityState } from '@/models/tree.types';

function* handleContextMenuAction({ payload: { nodeId, type, action } }: TTreeItemContextMenuAction) {
    const { serverId, repositoryId, id } = nodeId;

    if (!serverId) {
        return;
    }

    if (
        [TreeItemType.Folder, TreeItemType.Repository, TreeItemType.Model].includes(type) &&
        action === TreeItemContextMenuAction.ADD_OBJECT
    ) {
        const presetId: string | undefined = yield select(TreeSelectors.presetById(nodeId));

        if (!presetId) return;

        yield presetLoadObjectTypes(serverId, presetId);

        const objectTypes: ObjectType[] = yield select(ObjectTypeSelectors.listAllByPreset(serverId, presetId));

        const treeItemsById: { [id: string]: TTreeEntityState } = yield select(getTreeItems(serverId, repositoryId));
        const nodesIdChain: NodeId[] = getChainFromChildToParent(treeItemsById, id)
            .map(({ nodeId }) => nodeId)
            .splice(1); // узел куда добавляем не разворачиваем

        yield put(treeItemCollapseAll(DialogType.OBJECT_DEFINITION_CREATE));
        yield put(treeItemsExpandWithoutLoad(nodesIdChain, DialogType.OBJECT_DEFINITION_CREATE));

        yield put(
            openDialog(DialogType.OBJECT_DEFINITION_CREATE, {
                parentNodeId: nodeId,
                objectTypeList: objectTypes.sort((a, b) => a.name.localeCompare(b.name)),
            }),
        );
    }
}

function* handleObjectDefinitionDialogSubmit({
    payload: { parentNodeId, objectDefinitionName, objectDefinitionType },
}: TObjectDefinitionDialogSubmitData) {
    const objectDefinitionId = uuid();
    const objectDefinitionForSave: ObjectDefinitionImpl = objectDefinitionService().createObjectDefinition(
        parentNodeId.serverId,
        new ObjectDefinitionImpl({
            nodeId: { ...parentNodeId, id: objectDefinitionId },
            name: objectDefinitionName,
            objectTypeId: objectDefinitionType.id,
            attributes: [],
            version: 0,
            type: 'OBJECT',
            parentNodeId,
        }),
    );

    yield put(checkObjectDefinition(objectDefinitionForSave));
    const { success } = yield race({
        success: take(SAVE_OBJECT_DEFINITION_SUCCESS),
        fail: take(SAVE_OBJECT_DEFINITION_FAIL),
    });
    if (success) {
        yield put(objectDefinitionDialogSubmitResult('success'));
        yield put(closeDialog(DialogType.OBJECT_DEFINITION_CREATE));
    }
}

export function* objectDefinitionDialogSagaInit() {
    yield takeEvery(OBJECT_DEFINITION_DIALOG_SUBMIT_DATA, handleObjectDefinitionDialogSubmit);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleContextMenuAction);
}
