/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages/serverProfiles.messages';
import { Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

type TPrincipalServerProfileEditingTabToolBarProps = {
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    profileName: string;
};

export default class PrincipalServerProfileOwnersEditingTabToolbar extends Component<TPrincipalServerProfileEditingTabToolBarProps> {
    render() {
        const { handleSearch, profileName } = this.props;
    
        return (
            <Row gutter={16}>
                <Col span={8}>
                    <FormattedMessage id={messages.editPrincipalServerProfile.id} />
                    <b>{`${': '}${ profileName}`}</b>
                </Col>
                <Col span={6}>
                    <Input suffix={<SearchOutlined />} onChange={handleSearch} style={{ width: 200 }} />
                </Col>
            </Row>
        );
    }
}
