import type { TUmlClassData } from './classSymbol.types';
import type { ObjectInstance } from '@/serverapi/api';
import type { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { ObjectDefinitionSelectors } from '@/selectors/objectDefinition.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { getStore } from '@/store';
import { getUmlClassDefinitionData } from '../umlDefinitionData.selectors';

export function getUmlClassObjectAttributes(graph: BPMMxGraph, rootCellValue: ObjectInstance): TUmlClassData {
    const { objectDefinitionId: id } = rootCellValue;

    if (!id) {
        return { name: '' };
    }

    const state = getStore().getState();
    const { serverId, repositoryId } = graph.id;

    const nodeId = {
        id,
        repositoryId,
        serverId,
    };

    const presetId: string = TreeSelectors.presetById(nodeId)(state);
    const objectDefinition = ObjectDefinitionSelectors.byId(nodeId)(state);

    if (!objectDefinition) {
        return { name: '' };
    }

    const umlClassData = getUmlClassDefinitionData(objectDefinition, presetId)(state);

    return umlClassData;
}
