// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MatrixRenameDialog__formContainer__VRkrk{margin-top:35px}", "",{"version":3,"sources":["webpack://./src/modules/Matrix/MatrixEditor/MatrixRenameDialog/MatrixRenameDialog.scss"],"names":[],"mappings":"AAAA,0CACI,eAAA","sourcesContent":[".formContainer {\r\n    margin-top: 35px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": "MatrixRenameDialog__formContainer__VRkrk"
};
export default ___CSS_LOADER_EXPORT___;
