import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { getStore } from '@/store';
import { TCrossFunctionalFlowchartSymbolCustomProps } from '../ComplexSymbol.class.types';

export async function requestProps(): Promise<TCrossFunctionalFlowchartSymbolCustomProps> {
    return new Promise((resolve, reject) => {
        const submit = (symbolCustomProps: TCrossFunctionalFlowchartSymbolCustomProps) => {
            resolve(symbolCustomProps);
        };

        const symbol = {};
        const store = getStore();

        store.dispatch(openDialog(DialogType.CROSS_SYMBOL_CREATE_DIALOG, { symbol, submit, cancel: reject }));
    });
}
