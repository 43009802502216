import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import messages from './ReportEditorFilterBar.messages';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TConfirmationDialogProps = {
    open: boolean;
    onDelete: () => void;
    onCancel: () => void;
};

export const ConfirmationDialog: FC<TConfirmationDialogProps> = ({ open, onCancel, onDelete }) => {
    const intl = useIntl();

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onDelete,
                    value: intl.formatMessage(messages.delete),
                    visualStyle: 'primary',
                    danger: true,
                },
            ]}
        />
    );

    return (
        <Dialog onCancel={onCancel} onOk={onDelete} footer={footer} open={open}>
            {intl.formatMessage(messages.deleteQuestion)}
        </Dialog>
    );
};
