import { FETCH_NODES_REQUEST, FETCH_NODES_REQUEST_SUCCESS } from '../actionsTypes/nodes.actionTypes';
import { TFetchNodesAction, TFetchNodesSuccessAction } from './nodes.actions.types';
import { NodeId, Node } from '../serverapi/api';

export const fetchNodes = (nodes: NodeId[]): TFetchNodesAction => {
    return {
        type: FETCH_NODES_REQUEST,
        payload: {
            nodes,
        },
    };
};

export const fetchNodesSuccess = (nodes: Node[]): TFetchNodesSuccessAction => ({
    type: FETCH_NODES_REQUEST_SUCCESS,
    payload: { nodes },
});
