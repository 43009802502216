// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".imageRenderer__image___zkIO{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/TipTapTextEditor/Editor/renderers/image/imageRenderer.scss"],"names":[],"mappings":"AAAA,6BACI,UAAA,CACA,WAAA","sourcesContent":[".image {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "imageRenderer__image___zkIO"
};
export default ___CSS_LOADER_EXPORT___;
