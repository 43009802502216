import { FAVORITES_FETCH_SUCCESS } from '@/actionsTypes/favorites.actionTypes';
import type { TFavoritesState } from './favorites.reducer.types';
import type { TReducer } from '../utils/types';

const initial: TFavoritesState = {
    nodes: [],
};

export const favoritesReducer: TReducer<TFavoritesState> = (state = initial, action) => {
    switch (action.type) {
        case FAVORITES_FETCH_SUCCESS: {
            return {
                ...state,
                nodes: action.payload.favorites,
            };
        }

        default: {
            return state;
        }
    }
};
