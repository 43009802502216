import type { WhiteboardGraph } from '../WhiteboardGraph';
import type { MxPoint } from '../mxgraph.d';
import type { DiagramElement, Symbol } from '../../serverapi/api';
import type { MxCell } from '../mxgraph';
import { MxConstants } from '../mxgraph';
import { CompositeDragSource } from './CompositeDragSource';

const mapCellToElement = (cell: MxCell): DiagramElement => {
    const { height, width, x, y } = cell.getGeometry();

    return {
        height,
        width,
        x,
        y,
    } as DiagramElement;
};

export class WhiteboardDragSource extends CompositeDragSource {
    static cellToSymbol(cells: MxCell[], graph: WhiteboardGraph): Symbol {
        const cell = cells[0];
        const id = graph.getCellStyle(cell)[MxConstants.STYLE_SHAPE];
        const width = cell && cell.getGeometry().width;
        const height = cell && cell.getGeometry().height;

        return {
            id,
            presetId: '',
            width,
            height,
            name: '',
            objectType: undefined!,
            color: '',
            graphical: '',
            icon: '',
            showLabel: true,
        };
    }

    constructor(
        cells: MxCell[],
        dropHandler: (graph: WhiteboardGraph, evt: PointerEvent, target: MxCell, point: MxPoint) => void,
        graph: WhiteboardGraph,
        serverUrl?: string,
    ) {
        super(
            WhiteboardDragSource.cellToSymbol(cells, graph),
            dropHandler,
            graph,
            serverUrl,
            cells.map(mapCellToElement),
        );
    }
}
