import { defineMessages } from 'react-intl';

export default defineMessages({
    'editProfile.create': {
        id: 'PresetProfileTab.editProfile.create',
        defaultMessage: 'Создание нового профиля',
    },
    'editProfile.edit': {
        id: 'PresetProfileTab.editProfile.edit',
        defaultMessage: 'Профиль',
    },
    'editProfile.copy': {
        id: 'PresetProfileTab.editProfile.copy',
        defaultMessage: 'Копирование профиля',
    },
});
