import { Comment, CommentCommentStatusEnum } from '../../../serverapi/api';

/**
 * сопоставляем с цветом английские буквы и символы, допустимые в почте:
 * https://en.wikipedia.org/wiki/Email_address
 */
export const colorsAndLetterMatching = {
    '"': '#ff7300',
    "'": '#feb702',
    '!': '#fe9300',
    '#': '#56bdff',
    '$': '#00b360',
    '%': '#f37174',
    '&': '#009eff',
    '*': '#ff7300',
    '+': '#feb702',
    '-': '#fe9300',
    '/': '#56bdff',
    '=': '#00b360',
    '?': '#f37174',
    '^': '#009eff',
    '_': '#ff7300',
    '`': '#feb702',
    '{': '#fe9300',
    '|': '#56bdff',
    '}': '#00b360',
    '~': '#f37174',
    '0': '#009eff',
    '1': '#ff7300',
    '2': '#feb702',
    '3': '#fe9300',
    '4': '#56bdff',
    '5': '#00b360',
    '6': '#f37174',
    '7': '#009eff',
    '8': '#ff7300',
    '9': '#feb702',
    'a': '#fe9300',
    'b': '#56bdff',
    'c': '#00b360',
    'd': '#f37174',
    'e': '#009eff',
    'f': '#ff7300',
    'g': '#feb702',
    'h': '#fe9300',
    'i': '#56bdff',
    'j': '#00b360',
    'k': '#f37174',
    'l': '#009eff',
    'm': '#ff7300',
    'n': '#feb702',
    'o': '#fe9300',
    'p': '#56bdff',
    'q': '#00b360',
    'r': '#f37174',
    's': '#009eff',
    't': '#ff7300',
    'u': '#feb702',
    'v': '#fe9300',
    'w': '#56bdff',
    'x': '#00b360',
    'y': '#f37174',
    'z': '#009eff',
};

export const CLOSE_COMMENT_COLOR = '#8F8F8F';

export const CRITICAL_COMMENT_COLOR = '#FF3733';

const COMMENT_COLOR = '#08C';

export const getCircleBackgroundColor = (autorLogin: string, commentStatus?: CommentCommentStatusEnum): string =>
    commentStatus === 'CLOSE' ? CLOSE_COMMENT_COLOR : colorsAndLetterMatching[autorLogin[0]?.toLowerCase() || 0];

export const getCommentStrokeColor = (comment: Comment) => {
    if (comment.commentStatus === 'CLOSE') return CLOSE_COMMENT_COLOR;
    if (comment.commentAddStatus === 'CRITICAL') return CRITICAL_COMMENT_COLOR;

    return COMMENT_COLOR;
};