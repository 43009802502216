import { MxCell } from 'MxGraph';
import { SymbolTypeId } from '../ComplexSymbol.constants';
import MetaInfoComplexSymbol from '../MetaInfoComplexSymbol.class';
import MetaInfoSerializer from '../serializers/MetaInfoSerializer.class';
import { getTableSymbolDiagramTemplate } from './tableSymbolDiagramElements';

export class TableSymbol extends MetaInfoComplexSymbol {
    complexSymbolTypeId = SymbolTypeId.TABLE_SYMBOL;
    serializer = new MetaInfoSerializer();
    template = getTableSymbolDiagramTemplate();

    addLine(cell: MxCell) {
        this.graph.insertTableRow(cell, false);
    }

    removeLine(cell: MxCell) {
        this.graph.deleteTableRow(cell);
    }

    addRow(cell: MxCell) {
        this.graph.insertTableColumn(cell, false);
    }

    removeRow(cell: MxCell) {
        this.graph.deleteTableColumn(cell);
    }
}
