import { LoginResponse, UserAuthRequest, UserAuthRequestLocaleEnum, UserKeycloakAuthRequest } from '../serverapi/api';
import { SERVER_VERSION } from '../ServerInfo';
import { ApiBundle } from './api/api-bundle';
import { LocalStorageDaoService } from './dao/LocalStorageDaoService';

interface IAuthorizationService {
    login(userName: string, password: string, api: ApiBundle): Promise<LoginResponse | undefined>;
    loginKeycloak(accessToken: string, api: ApiBundle): Promise<LoginResponse | undefined>;
    ssoLogin(token: string, api: ApiBundle): Promise<LoginResponse | undefined>;
    loadUserInfo(api: ApiBundle): Promise<LoginResponse | undefined>;
}

class AuthorizationServiceImpl implements IAuthorizationService {
    ssoLogin(token: string, api: ApiBundle): Promise<LoginResponse | undefined> {
        return Promise.resolve(undefined);
    }

    login(username: string, password: string, api: ApiBundle): Promise<LoginResponse | undefined> {
        const savedLocale = LocalStorageDaoService.getLocale();

        const authRequest: UserAuthRequest = {
            password,
            systemType: '1',
            username,
            clientInfo: getClientInfo(),
            clientVersion: SERVER_VERSION,
            locale: savedLocale.toUpperCase() as UserAuthRequestLocaleEnum,
        };

        return api.authorization.login({ body: authRequest }).catch((e) => undefined);
    }

    loginKeycloak(accessToken: string, api: ApiBundle): Promise<LoginResponse | undefined> {
        const savedLocale = LocalStorageDaoService.getLocale();

        const authRequest: UserKeycloakAuthRequest = {
            accessToken,
            systemType: '1',
            clientInfo: getClientInfo(),
            clientVersion: SERVER_VERSION,
            locale: savedLocale.toUpperCase() as UserAuthRequestLocaleEnum,
        };

        return api.authorization.loginKeycloak({ body: authRequest }).catch((e) => undefined);
    }

    loadUserInfo(api: ApiBundle): Promise<LoginResponse | undefined> {
        const authRequest: UserAuthRequest = {
            systemType: '1',
            clientInfo: getClientInfo(),
            clientVersion: SERVER_VERSION,
        };

        return api.authorization.userInfo({ body: authRequest }).catch((e) => undefined);
    }
}

function getClientInfo(): string {
    const userAgent: string = navigator.userAgent;
    const screenWidth: number = window.screen.width;
    const screenHeight: number = window.screen.height;
    const innerWidth: number = window.innerWidth;
    const innerHeight: number = window.innerHeight;
    const local: string = navigator.language;
    const systemTime: string = new Date().toString();
    const cookieEnabled: boolean = navigator.cookieEnabled;

    return JSON.stringify({
        userAgent,
        screenWidth,
        screenHeight,
        innerWidth,
        innerHeight,
        local,
        systemTime,
        cookieEnabled,
    });
}

let authorizationInstance: IAuthorizationService;

export function authorizationService(): IAuthorizationService {
    if (!authorizationInstance) {
        authorizationInstance = new AuthorizationServiceImpl();
    }

    return authorizationInstance;
}

export default authorizationService();
