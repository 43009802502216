import { defineMessages } from 'react-intl';

export default defineMessages({
    script: {
        id: 'ScheduleScriptDialog.script',
        defaultMessage: 'Скрипт',
    },
    selectScript: {
        id: 'ScheduleScriptDialog.selectScript',
        defaultMessage: 'Выберите скрипт',
    },
    enableTask: {
        id: 'ScheduleScriptDialog.enableTask',
        defaultMessage: 'Включить задачу',
    },
});
