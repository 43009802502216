import { TReducer } from '@/utils/types';
import { TPublishedModelsState } from './publishedModels.reducer.types';
import { ADD_PUBLISHED_MODELS, DELETE_PUBLISHED_MODELS } from '@/actionsTypes/publishedModels.actionTypes';
import { Node } from '@/serverapi/api';
import { compareNodeIds } from '@/utils/nodeId.utils';

const initial: Node[] = [];

export const publishedModelsReducer: TReducer<TPublishedModelsState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_PUBLISHED_MODELS: {
            const {
                payload: { publishedModels },
            } = action;

            return publishedModels;
        }

        case DELETE_PUBLISHED_MODELS: {
            const {
                payload: { publishedModelsNodeIds },
            } = action;

            return state.filter(
                (node) => !publishedModelsNodeIds.some((nodeId) => compareNodeIds(nodeId, node.nodeId)),
            );
        }

        default:
            return state;
    }
};
