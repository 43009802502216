import { List } from 'antd';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MxCell } from '../../../../../mxgraph/mxgraph';
import { ObjectTypeSelectors } from '../../../../../selectors/objectType.selectors';
import { EdgeType, NodeId } from '../../../../../serverapi/api';
import messages from './NavigatorEdgeTab.messages';
import { objectDefinitionService } from '../../../../../services/ObjectDefinitionService';
import DeleteButton from '../../../../AdminTools/Button/DeleteButton';
import { useIntl } from 'react-intl';
import theme from './NavigatorEdgeTab.scss';
import { objectPropertyView } from '../../../../../actions/objectProperty.actions';
import { BPMMxGraph } from '../../../../../mxgraph/bpmgraph';
import { deleteAction } from '../../../../../actions/editor.actions';
import { EdgeTypeSelectors } from '../../../../../selectors/edgeType.selectors';
import { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';

type TNavigatorEdgeTab = {
    graph: BPMMxGraph;
    focusObj: MxCell;
    edge: MxCell;
    isEditMode: boolean;
    presetId: string;
};

type TEdgeDirection = 'source' | 'target';

const getEdgeDirection = (obj: MxCell, edge: MxCell): TEdgeDirection | null => {
    if (edge.target.id === (obj.value?.id || obj.id)) {
        return 'target';
    }
    if (edge.source.id === (obj.value?.id || obj.id)) {
        return 'source';
    }

    return null;
};

const NavigatorEdgeTabListItem: FC<TNavigatorEdgeTab> = (props) => {
    const { focusObj, graph, isEditMode, presetId, edge } = props;
    const { repositoryId, serverId } = graph.id;
    const intl = useIntl();
    const dispatch = useDispatch();
    const edgeType: EdgeType | undefined = useSelector(
        EdgeTypeSelectors.byId({ edgeTypeId: edge.value.edgeTypeId, presetId, serverId }),
    );

    const isRecursiveEdge = edge.source?.id === edge.target?.id;
    const oppositeObject: MxCell = !isRecursiveEdge ? graph.getOpposites([edge], focusObj, true, true)[0] : focusObj;
    const oppositeNodeId: NodeId = { serverId, repositoryId, id: oppositeObject?.value?.objectDefinitionId };
    const oppositeObjectDefinition: ObjectDefinitionImpl | undefined =
        objectDefinitionService().getObjectDefinition(oppositeNodeId);
    const oppositeObjName = oppositeObjectDefinition?.name;
    const compositeId = { objectTypeId: oppositeObjectDefinition?.objectTypeId || '', serverId, presetId };
    const oppositeObjType = useSelector(ObjectTypeSelectors.byId(compositeId));

    const edgeTypeName = edgeType?.name;
    const edgeName = edge.value?.name;
    const edgeDirection = getEdgeDirection(focusObj, edge);
    if (!edgeDirection) return null;
    const edgeDirectionText = intl.formatMessage(messages[edgeDirection]);

    const openPropertyDialog = (edgeCellId: string) => () => {
        dispatch(objectPropertyView(edgeCellId));
    };

    const focusObjectOnGraph = (obj: MxCell) => () => {
        graph.getSelectionModel().clear();
        graph.scrollCellToVisible(obj, true);
        graph.getSelectionModel().addCell(obj);
    };

    const deleteEdgeFromGraph = () => {
        dispatch(deleteAction([edge], graph.id, true));
    };

    const renderEdgeInfo = () => {
        const edgeText = edge.isVisible() ? messages.edge : messages.invisibleEdge;

        return (
            <div data-test="connection-window_edge">
                {`${intl.formatMessage(edgeText)}: `}
                <span className={theme.link} onClick={openPropertyDialog(edge.id)}>
                    {edgeTypeName}
                </span>
                {` (${edgeDirectionText})`}
            </div>
        );
    };

    const renderObjectInfo = () => {
        const oppositeObjTypeName = oppositeObjType?.name ? `(${oppositeObjType?.name})` : '';

        return (
            <div data-test="connection-window_object-name">
                {`${intl.formatMessage(messages.object)}: `}
                <span className={theme.link} onClick={focusObjectOnGraph(oppositeObject)}>
                    {oppositeObjName}
                </span>
                {` ${oppositeObjTypeName}`}
            </div>
        );
    };

    const renderDeleteButton = () => {
        if (isEditMode) {
            return null;
        }

        return (
            <DeleteButton
                onDelete={deleteEdgeFromGraph}
                deleteQuestion={`${intl.formatMessage(messages.deleteEdgeTitle)}  "${edgeName ? `${edgeName} ` : ''}[${
                    edgeTypeName || ''
                }]" (${edgeDirectionText}) ?`}
            />
        );
    };

    return (
        <List.Item key={edge.id}>
            <div>
                {renderEdgeInfo()}
                {renderObjectInfo()}
            </div>
            {renderDeleteButton()}
        </List.Item>
    );
};

export default NavigatorEdgeTabListItem;
