import React, { ChangeEvent } from 'react';
import { Checkbox } from '../Checkbox/Checkbox.component';
import theme from './TableUIKitComponent.scss';

type TColumnHeaderWithCheckBoxProps = {
    isChecked: boolean;
    isIndeterminate: boolean;
    handleChange: (e: ChangeEvent) => void;
};

export const ColumnHeaderWithCheckBox = (props: TColumnHeaderWithCheckBoxProps): JSX.Element => {
    const { isChecked, isIndeterminate, handleChange } = props;

    return (
        <div className={theme.headerCell}>
            <div className={theme.headerCheckBoxContainer}>
                <Checkbox
                    onChange={(e) => handleChange(e)}
                    className={theme.checkbox}
                    checked={isChecked || undefined}
                    indeterminate={isIndeterminate}
                />
            </div>
        </div>
    );
};
