import { TReducer } from '@/utils/types';
import { TSubscribedNodesIdsState } from './subscribedNodesIds.reducer.types';
import {
    TREE_NODE_ADD_SUBSCRIBTION_TO_STORE,
    TREE_NODE_DELETE_SUBSCRIBTION_FROM_STORE,
} from '@/actionsTypes/tree.actionTypes';
import { compareNodeIds } from '@/utils/nodeId.utils';

const initialState: TSubscribedNodesIdsState = [];

export const subscribedNodesIdsReducer: TReducer<TSubscribedNodesIdsState> = (state = initialState, action) => {
    switch (action.type) {
        case TREE_NODE_ADD_SUBSCRIBTION_TO_STORE: {
            const { nodeId } = action.payload;
            if (!state.some((n) => compareNodeIds(n, nodeId))) {
                return [...state, nodeId];
            }

            return state;
        }

        case TREE_NODE_DELETE_SUBSCRIBTION_FROM_STORE: {
            const { nodeId } = action.payload;

            return state.filter((n) => !compareNodeIds(n, nodeId));
        }

        default:
            return state;
    }
};
