import { PortsOrientation } from './PortsOrientation.enum';
import { TConstraintsPoint } from '../../modules/AdminTools/Methodology/components/Presets/ObjectType/SymbolEditorTab/SymbolEditorTab.types';
import { v4 as uuid } from 'uuid';

export const NO_VALID_PORT_FOCUS_FILL_COLOR = '#ff776b';

export const ACTIVE_PORTS_FOCUS_FILL_COLOR = '#3b78ff';

export const NOT_ACTIVE_PORTS_FOCUS_FILL_COLOR = '#19ba65';

export const VALID_PORTS_IMAGE =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmci' +
    'IHZlcnNpb249IjEuMSI+ICAgIDxjaXJjbGUgY3g9IjUiIGN5PSI1IiByPSI0IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIGZ' +
    'pbGw9IiMxOWJhNjUiIC8+PC9zdmc+';

export const NOT_VALID_PORTS_IMAGE =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd' +
    'mciIHZlcnNpb249IjEuMSI+ICAgIDxjaXJjbGUgY3g9IjUiIGN5PSI1IiByPSI0IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiI' +
    'GZpbGw9IiNmZjc3NmIiIC8+PC9zdmc+';

export const DECOMPOSITION_ICON =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHd' +
    'pZHRoPSIxNiIgaGVpZ2h0PSIxNiI+ICAgIDxwYXRoIGZpbGw9IiM4Mjk1QTMiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTguNSAxYTcuNSA3' +
    'LjUgMCAxIDEgMCAxNSA3LjUgNy41IDAgMCAxIDAtMTV6Ii8+ICAgIDxwYXRoIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iT' +
    'TExLjUgMTJhMS41IDEuNSAwIDEgMSAwLTMgMS41IDEuNSAwIDAgMSAwIDN6TTUgN2g3djFINVY3em0zLjUtMWExLjUgMS41IDAgMSAxIDAtMy' +
    'AxLjUgMS41IDAgMCAxIDAgM3pNNyAxMC41YTEuNSAxLjUgMCAxIDEtMy4wMDEtLjAwMUExLjUgMS41IDAgMCAxIDcgMTAuNXoiLz4gICAgPHB' +
    'hdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgxNnYxNkgwVjB6Ii8+PC9zdmc+';

export const DELETED_DEFINITION_ICON =
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM3LjUgNS4zNzVMMzIuMTI1IDBMMTguNzUgMTMuMzc1TDUuMzc1IDBMMCA1LjM3NUwxMy4zNzUgMTguNzVMMCAzMi4xMjVMNS4zNzUgMzcuNUwxOC43NSAyNC4xMjVMMzIuMTI1IDM3LjVMMzcuNSAzMi4xMjVMMjQuMTI1IDE4Ljc1TDM3LjUgNS4zNzVaIiBmaWxsPSIjRkY1QTc5Ii8+Cjwvc3ZnPgo=';

export function getPorts() {
    const ports: any[] = []; // tslint:disable-line:no-any
    // NOTE: Constraint is used later for orthogonal edge routing (currently ignored)
    ports[PortsOrientation.W] = { x: 0, y: 0.5, perimeter: false, constraint: 'west' };
    ports[PortsOrientation.E] = { x: 1, y: 0.5, perimeter: false, constraint: 'east' };
    ports[PortsOrientation.N] = { x: 0.5, y: 0, perimeter: false, constraint: 'north' };
    ports[PortsOrientation.S] = { x: 0.5, y: 1, perimeter: false, constraint: 'south' };
    ports[PortsOrientation.NNW] = { x: 0.25, y: 0, perimeter: false, constraint: 'north north west' };
    ports[PortsOrientation.NNE] = { x: 0.75, y: 0, perimeter: false, constraint: 'north north east' };
    ports[PortsOrientation.ENE] = { x: 1, y: 0.25, perimeter: false, constraint: 'east north east' };
    ports[PortsOrientation.ESE] = { x: 1, y: 0.75, perimeter: false, constraint: 'east south east' };
    ports[PortsOrientation.SSE] = { x: 0.75, y: 1, perimeter: false, constraint: 'south south east' };
    ports[PortsOrientation.SSW] = { x: 0.25, y: 1, perimeter: false, constraint: 'south south west' };
    ports[PortsOrientation.WSW] = { x: 0, y: 0.75, perimeter: false, constraint: 'west south west' };
    ports[PortsOrientation.WNW] = { x: 0, y: 0.25, perimeter: false, constraint: 'west north west' };

    return ports;
}

export function getSelectionPorts() {
    const ports: any[] = []; // tslint:disable-line:no-any
    // NOTE: Constraint is used later for orthogonal edge routing (currently ignored)
    ports[PortsOrientation.W] = { x: -0.1, y: 0.5, perimeter: false, constraint: 'west' };
    ports[PortsOrientation.E] = { x: 1.1, y: 0.5, perimeter: false, constraint: 'east' };
    ports[PortsOrientation.N] = { x: 0.5, y: -0.2, perimeter: false, constraint: 'north' };
    ports[PortsOrientation.S] = { x: 0.5, y: 1.2, perimeter: false, constraint: 'south' };

    return ports;
}

export const getPointsSymbolEditorTab = (): TConstraintsPoint[] => {
    return [
        { id: uuid(), name: 'West', coordinatesX: '0', coordinatesY: '0.5' },
        { id: uuid(), name: 'East', coordinatesX: '1', coordinatesY: '0.5' },
        { id: uuid(), name: 'North', coordinatesX: '0.5', coordinatesY: '0' },
        { id: uuid(), name: 'South', coordinatesX: '0.5', coordinatesY: '1' },
        { id: uuid(), name: 'North North West', coordinatesX: '0.25', coordinatesY: '0' },
        { id: uuid(), name: 'North North East', coordinatesX: '0.75', coordinatesY: '0' },
        { id: uuid(), name: 'East North East', coordinatesX: '1', coordinatesY: '0.25' },
        { id: uuid(), name: 'East South East', coordinatesX: '1', coordinatesY: '0.75' },
        { id: uuid(), name: 'South South East', coordinatesX: '0.75', coordinatesY: '1' },
        { id: uuid(), name: 'South South West', coordinatesX: '0.25', coordinatesY: '1' },
        { id: uuid(), name: 'West South West', coordinatesX: '0', coordinatesY: '0.75' },
        { id: uuid(), name: 'West North West', coordinatesX: '0', coordinatesY: '0.25' },
    ];
};
