import React, { FC } from 'react';
import DBAccessTabToolBar from '../DBAccessOwnersEditingTab/components/DBAccessTabToolBar.component';
import AccessPermissionsEditingTabToolBar from '../AccessPermissionOwnersEditingTab/components/AccessPermissionOwnersEditingTabToolBar.component';
import LicenseOwnersEditingTabToolbar from '../LicenseOwnersEditingTab/components/LicenseOwnersEditingTabToolbar.component';
import PrincipalServerProfileOwnersEditingTabToolbar from '../PrincipalServerProfileOwnersEditingTabContainer/components/PrincipalServerProfileOwnersEditingTabToolbar.component';

const components = {
    dbAccess: DBAccessTabToolBar,
    accessPermissions: AccessPermissionsEditingTabToolBar,
    licenseOwners: LicenseOwnersEditingTabToolbar,
    principalServerProfileOwners: PrincipalServerProfileOwnersEditingTabToolbar,
};

type TGUPToolBarRootProps = {
    params: any;
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const GUPToolBarRoot: FC<TGUPToolBarRootProps> = ({ params, handleSearch }) => {
    const Component = components[params.type];

    return <Component {...params.payload} handleSearch={handleSearch} />;
};

export default GUPToolBarRoot;
