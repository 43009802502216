import React, { FC } from 'react';
import messages from './DbSearchDialog.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { DialogSize } from '@/config/config';
import { DbSearch } from '../DbSearch/DbSearch.component';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TDbSearchDialogProps } from './DbSearchDialog.types';
import { SearchSelectors } from '@/selectors/dbSearch.selector';
import { TSearcParams } from '@/reducers/search.reducer.types';
import { reportSetSearchRequests } from '@/modules/Report/actions/report.actions';
import { SearchRequest } from '@/serverapi/api';
import { searchRuleWithValueIdToSearchRules } from '@/utils/bdSearchTab.utils';

export const DbSearchDialog: FC<TDbSearchDialogProps> = ({ open, nodeId, searchRequests }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const searcParams: TSearcParams | undefined = useSelector(SearchSelectors.getSearchParams(nodeId));

    const onSubmit = () => {
        if (searcParams) {
            const { nodeTypes, rootSearchNodeId, searchRules, searchText, searchVisibility } = searcParams;
            const searchRequests: SearchRequest = {
                rootSearchNodeId,
                searchText,
                includePath: true,
                includeCount: false,
                searchVisibility,
                searchRules: searchRuleWithValueIdToSearchRules(searchRules),
                nodeTypes,
            };
            dispatch(reportSetSearchRequests(nodeId, [searchRequests]));
        }
        onCancel();
    };

    const onCancel = () => {
        dispatch(closeDialog(DialogType.DB_SEARCH_DIALOG));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog footer={footer} width={DialogSize.HUGE_DIALOG_WIDTH} open={open} onCancel={onCancel} onOk={onSubmit}>
            <DbSearch />
        </Dialog>
    );
};
