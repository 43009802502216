import React, { FC } from 'react';
import cx from 'classnames';
import theme from './TableExpandIcon.scss';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import icArrowDown from '../../../resources/icons/ic-ribbon-arrow-down_2.svg';
import icFolder from '../../../resources/icons/group.svg';
import { TSpriteSymbol } from '../../../models/spriteSymbol.types';

type TTableExpandIcon = {
    expandable: boolean;
    expanded: boolean;
    record: any;
    onExpand: (record: any, event: React.MouseEvent<HTMLElement>) => void;
    expandIcon?: TSpriteSymbol;
};

const TableExpandIcon: FC<TTableExpandIcon> = (props) => {
    const { record, onExpand, expandable, expanded, expandIcon } = props;
    const handleClickExpandIcon = (e) => {
        e.stopPropagation();
        onExpand(record, e);
    };
    if (!expandable) {
        return null;
    }

    return (
        <span className={theme.expandIcon} onClick={handleClickExpandIcon}>
            <Icon
                spriteSymbol={icArrowDown}
                className={cx(theme.togglerIcon, expanded && theme.togglerIcon_expanded)}
            />
            <Icon className={theme.groupIcon} spriteSymbol={expandIcon || icFolder} />
        </span>
    );
};

export default TableExpandIcon;