import type { MxCell } from 'MxGraph';
import { SymbolTypeId, FRAME_STYLES_INDICATOR } from '../ComplexSymbol.constants';
import { getPoolSymbolData } from './sideEffects';
import { VerticalSwimLaneSymbol } from './VerticalSwimLaneSymbol.class';
import createComplexSymbol from '../createComplexSymbol';
import PoolSymbol from './PoolSymbol.class';

export class VerticalPoolSymbol extends PoolSymbol {
    complexSymbolTypeId = SymbolTypeId.VERTICAL_POOL;

    addSwimlanes(parent: MxCell) {
        const { graph } = this;
        const swimlanes = getPoolSymbolData(graph, this.rootCellValue);
        swimlanes.forEach((swimlaneRootValue) => {
            const rootCellValue = {
                ...swimlaneRootValue,
                parent: parent.getId(),
            };

            createComplexSymbol(VerticalSwimLaneSymbol, { graph, rootCellValue });
        });
    }

    insert(): MxCell {
        const { graph } = this;
        const [width, height] = [360, 480];
        const { x = 0, y = 0, parent = graph.getDefaultParent() } = this.rootCellValue;
        const style = `${FRAME_STYLES_INDICATOR};swimlane;horizontal=1;html=1;childLayout=stackLayout;resizeParent=1;resizeParentMax=0;startSize=30`;
        const pool = graph.insertVertexFromValue({
            ...this.rootCellValue,
            parent,
            x,
            y,
            width,
            height,
            style,
        });

        return pool;
    }

    restore(): MxCell {
        const { graph } = this;
        const { x = 0, y = 0, width = 360, height = 480, parent = graph.getDefaultParent() } = this.rootCellValue;
        const style =
            this.rootCellValue.style ||
            `${FRAME_STYLES_INDICATOR};swimlane;horizontal=1;html=1;childLayout=stackLayout;resizeParent=1;resizeParentMax=0;startSize=30`;
        const pool = graph.insertVertexFromValue({
            ...this.rootCellValue,
            parent,
            x,
            y,
            width,
            height,
            style,
        });

        return pool;
    }
}
