import { defineMessages } from 'react-intl';

export default defineMessages({
    ruleTitle: {
        id: 'AttributesEditor.ruleTitle',
        defaultMessage: 'Укажите условие отображения',
    },
    addRule: {
        id: 'AttributesEditor.addRule',
        defaultMessage: 'Добавить правило',
    },
    HAS_VALUE: {
        id: 'AttributesEditor.HAS_VALUE',
        defaultMessage: 'Заполнен',
    },
    HAS_NOT_VALUE: {
        id: 'AttributesEditor.HAS_NOT_VALUE',
        defaultMessage: 'Не заполнен',
    },
    EQUALS: {
        id: 'AttributesEditor.EQUALS',
        defaultMessage: 'Равно',
    },
    NOT_EQUALS: {
        id: 'AttributesEditor.NOT_EQUALS',
        defaultMessage: 'Не равно',
    },
    GREATER: {
        id: 'AttributesEditor.GREATER',
        defaultMessage: 'Больше',
    },
    GREATER_OR_EQUAL: {
        id: 'AttributesEditor.GREATER_OR_EQUAL',
        defaultMessage: 'Больше или равно',
    },
    LESS: {
        id: 'AttributesEditor.LESS',
        defaultMessage: 'Меньше',
    },
    LESS_OR_EQUAL: {
        id: 'AttributesEditor.LESS_OR_EQUAL',
        defaultMessage: 'Меньше или равно',
    },
    CONTAINS: {
        id: 'AttributesEditor.CONTAINS',
        defaultMessage: 'Текст содержит ',
    },
    NOT_CONTAINS: {
        id: 'AttributesEditor.NOT_CONTAINS',
        defaultMessage: 'Текст не содержит',
    },
    STARTS_WITH: {
        id: 'AttributesEditor.STARTS_WITH',
        defaultMessage: 'Текст начинается с',
    },
    ENDS_WITH: {
        id: 'AttributesEditor.ENDS_WITH',
        defaultMessage: 'Текст заканчивается на',
    },
    BETWEEN: {
        id: 'AttributesEditor.BETWEEN',
        defaultMessage: 'Между',
    },
    NOT_BETWEEN: {
        id: 'AttributesEditor.NOT_BETWEEN',
        defaultMessage: 'Не между',
    },
});
