import { remove } from 'lodash-es';
import { FolderTypeValidNodeTypesEnum } from '../serverapi/api';
import { TreeItemType } from '../modules/Tree/models/tree';

export class FolderTypeModelsConverter {
    // Элементы, которые должны быть перемещены
    private static transmittableModelTypes = new Set<FolderTypeValidNodeTypesEnum>([
        TreeItemType.Wiki,
        TreeItemType.Matrix,
    ]);

    /**
     * Извлекает заданные элементы из modelTypes и помещает их в nodeTypes
     * @see transmittableModelTypes
     */
    public static convertFromEntity(
        modelTypes: string[],
        nodeTypes: FolderTypeValidNodeTypesEnum[],
    ): {
        modelTypesWithoutTransmittable: string[];
        nodeTypesWithTransmittable: FolderTypeValidNodeTypesEnum[];
    } {
        const dtoExcludingTransmittable = [...modelTypes];
        const extractedModels = remove(dtoExcludingTransmittable, (mt) =>
            this.transmittableModelTypes.has(mt.toUpperCase() as FolderTypeValidNodeTypesEnum),
        );
        const uppercaseExtractedModels = extractedModels.map((m) => m.toUpperCase());
        const entityIncludingTransmittable = [...nodeTypes, ...uppercaseExtractedModels];

        return {
            modelTypesWithoutTransmittable: dtoExcludingTransmittable,
            nodeTypesWithTransmittable: entityIncludingTransmittable as FolderTypeValidNodeTypesEnum[],
        };
    }

    /**
     * Извлекает заданные элементы из nodeTypes и помещает их в modelTypes
     * @see transmittableModelTypes
     */
    public static convertFromDto(
        nodeTypes: FolderTypeValidNodeTypesEnum[],
        modelTypes: string[],
    ): {
        nodeTypesWithoutTransmittable: FolderTypeValidNodeTypesEnum[];
        modelTypesWithTransmittable: string[];
    } {
        const entityExcludingTransmittable = [...nodeTypes];
        const extractedModels = remove(entityExcludingTransmittable, (mt) => this.transmittableModelTypes.has(mt));
        const lowercaseExtractedModels = extractedModels.map((m) => m.toLowerCase());
        const dtoIncludingTransmittable = [...modelTypes, ...lowercaseExtractedModels];

        return {
            nodeTypesWithoutTransmittable: entityExcludingTransmittable,
            modelTypesWithTransmittable: dtoIncludingTransmittable,
        };
    }
}