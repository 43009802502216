import React, { Component } from 'react';
import { Form, FormInstance, Select } from 'antd';
import messages from '../messages/ChoseSymbolsForDragDialog.messages';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { ModelType, NodeId, Symbol } from '../../../serverapi/api';
import { TChoseSymbolForDragDialogSubmitDataActionPayload } from '../../../actions/choseSymbolForDragDialog.actions.types';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { v4 as uuid } from 'uuid';
import theme from './ChoseSymbolForDragDialog.scss';
import { SymbolToImageConverterGraph } from '../../AdminTools/Methodology/components/Presets/SymbolToImageConverterGraph.component';
import { MethodologiesGraph } from '../../../mxgraph/MethodologiesGraph';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TChoseSymbolForDragDialogProps = {
    supportedObjectTypeSymbols: Array<Symbol>;
    nodeId: NodeId;
    open: boolean;
    onClose: () => any; // tslint:disable-line:no-any
    onSubmit: (submitData: TChoseSymbolForDragDialogSubmitDataActionPayload) => any; // tslint:disable-line:no-any
} & JSX.IntrinsicAttributes;

type TChoseSymbolsForDragDialogState = {
    chosenSymbolId: string;
    graph: MethodologiesGraph | undefined;
};

type TChoseSymbolsForDragDialogAllProps = WrappedComponentProps & TChoseSymbolForDragDialogProps;

class ChoseSymbolForDragDialog extends Component<TChoseSymbolsForDragDialogAllProps> {
    state: TChoseSymbolsForDragDialogState = {
        chosenSymbolId: this.props.supportedObjectTypeSymbols[0].id,
        graph: undefined,
    };

    formRef = React.createRef<FormInstance>();

    handleSubmit = () => {
        this.formRef?.current?.resetFields();
        this.props.onSubmit({
            symbolId: this.state.chosenSymbolId,
            // objectDefinition: this.props.objectDefinition,
            supportedObjectTypeSymbols: this.props.supportedObjectTypeSymbols,
            nodeId: this.props.nodeId,
        });
    };

    handleChosenSymbolChange = (id: string) => {
        this.setState({
            chosenSymbolId: id,
        });
    };

    converterInitialized = (graph: MethodologiesGraph) => {
        if (!this.state.graph) {
            this.setState({ graph });
        }
    };

    render() {
        const { open, onClose, intl, supportedObjectTypeSymbols } = this.props;

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: onClose,
                        value: intl.formatMessage(messages.choseSymbolsForDragFormDeclineButton),
                    },
                    {
                        key: 'ok',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.choseSymbolsForDragFormConfirmButton),
                        visualStyle: 'primary',
                    },
                ]}
            />
        );

        return (
            <Dialog
                onOk={this.handleSubmit}
                onCancel={onClose}
                title={intl.formatMessage(messages.choseSymbolsForDragDialogFormName)}
                open={open}
                width={DEFAULT_DIALOG_WIDTH}
                footer={footer}
            >
                <SymbolToImageConverterGraph
                    modelType={{ id: uuid() } as ModelType}
                    initialized={this.converterInitialized}
                />

                <Form ref={this.formRef}>
                    <div>
                        <Select
                            data-test="drag-to-edit-window_select"
                            defaultValue={supportedObjectTypeSymbols[0].id}
                            style={{ width: '100%' }}
                            onChange={this.handleChosenSymbolChange}
                            className={theme.symbolSelect}
                            popupClassName={theme.dropdownMenu}
                        >
                            {supportedObjectTypeSymbols
                                ? supportedObjectTypeSymbols.map((symbol) => (
                                      <Select.Option
                                          data-test={`drag-to-edit-window-select_option-${symbol.name}`}
                                          value={symbol.id}
                                          key={symbol.id}
                                          className={theme.symbolBox}
                                      >
                                          {SymbolToImageConverterGraph.convertSymbol(symbol, intl, this.state.graph)}
                                          {symbol.name}
                                      </Select.Option>
                                  ))
                                : null}
                        </Select>
                    </div>
                </Form>
            </Dialog>
        );
    }
}

const ChoseSymbolForDragWithIntl = injectIntl(ChoseSymbolForDragDialog);

export { ChoseSymbolForDragWithIntl as ChoseSymbolForDragDialog };
