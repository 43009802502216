import { defineMessages } from 'react-intl';

export default defineMessages({
    newProfile: {
        id: 'PresetProfileGeneralSettings.newProfile',
        defaultMessage: 'Новый профиль',
    },
    name: {
        id: 'PresetProfileGeneralSettings.name',
        defaultMessage: 'Название',
    },
    description: {
        id: 'PresetProfileGeneralSettings.description',
        defaultMessage: 'Описание',
    },
    correspondingServerProfile: {
        id: 'PresetProfileGeneralSettings.correspondingServerProfile',
        defaultMessage: 'Соответствующий профиль сервера',
    },
    withoutPresetProfile: {
        id: 'PresetProfileGeneralSettings.withoutPresetProfile',
        defaultMessage: 'Без профиля сервера',
    },
});
