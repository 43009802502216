import { TreeNode } from '../models/tree.types';
import { TreeItemType } from '../modules/Tree/models/tree';
import { Node, NodeId } from '../serverapi/api';

export class TreeBLLService {
    static findNodeByNodeId<T extends Node | TreeNode>(nodeList: T[], nodeId: NodeId): T | undefined {
        return nodeList.find(
            (node) =>
                node.nodeId.serverId === nodeId.serverId &&
                node.nodeId.repositoryId === nodeId.repositoryId &&
                node.nodeId.id === nodeId.id,
        );
    }

    static getDeletedRepositories = (nodes: Node[], repositoryList: TreeNode[]) =>
        repositoryList
            .filter((repo) => !nodes.some((node) => repo.nodeId.repositoryId === node.nodeId.repositoryId))
            .map((n) => n.nodeId);

    static getAddedRepositories = (nodes: Node[], repositoryList: TreeNode[]) =>
        nodes
            .filter((node) => !repositoryList.some((repo) => repo.nodeId.repositoryId === node.nodeId.repositoryId))
            .filter((n) => n.type === TreeItemType.Repository);
}
