import { Alert, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import React, { useState, FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { MxConstants } from '../../../../../../../mxgraph/mxgraph';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { ModelType, InternationalString, Symbol, KanbanCardType } from '../../../../../../../serverapi/api';
import { toggleArrayItem } from '../../../../../../../utils/toggleArrayItem';
import { DeleteButton, EditButton, RowButtons } from '../../../../../Button/RowButtons';
import { DeleteSelected, TabHeader } from '../../Header/TabHeader.component';
import theme from './SymbolsTab.scss';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import messages from '../ObjectType.messages';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { SymbolTypeSelectDialogComponent } from '../Dialog/SymbolTypeSelectDialog.component';
import { ComplexSymbolManager } from '../../../../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { SymbolTypeId } from '../../../../../../../mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import swimlaneSymbol from '../../../../../../../resources/icons/swimlaneSymbol.svg';
import poolSymbol from '../../../../../../../resources/icons/poolSymbol.svg';
import crossSymbol from '../../../../../../../resources/icons/crossSymbol.svg';
import { Icon } from '../../../../../../UIKit/components/Icon/Icon.component';
import icKanbanCard from '../../../../../../../resources/icons/ic-kanban-card.svg';
import { KANBAN_CARD, isKanbanCardType } from '../../util/KanbanCardEditor.utils';
import { getSymbolsDeleteMessages } from '../../util/metodologyElementsDeleteMessages.utils';
import { EllipsisText } from '../../../MethodologySettingPage/EllipsisText/EllipsisText.component';

type TSymbolsTabProps = {
    symbols: Symbol[];
    kanbanCardTypes?: KanbanCardType[];
    disabled?: boolean;
    openSymbolEditor: ({ symbol, symbolTypeId }: { symbol?: Symbol; symbolTypeId?: string }) => void;
    onDeleteSymbols: (symbols: Symbol[]) => void;
    openKanbanCardEditor?: () => void;
    editKanbanCard?: (kanbanCardType: KanbanCardType) => void;
    deleteKanbanCards?: (kanbanCardTypesForDelete: KanbanCardType[]) => void;
};

const SymbolsTab: FC<TSymbolsTabProps> = (props) => {
    const {
        symbols,
        disabled,
        openSymbolEditor,
        onDeleteSymbols,
        openKanbanCardEditor,
        editKanbanCard,
        deleteKanbanCards,
        kanbanCardTypes = [],
    } = props;

    const allSymbols = [...symbols, ...(kanbanCardTypes as unknown as Symbol[])];

    const [graph, setGraph] = useState<MethodologiesGraph | undefined>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [showSelectSymbolTypeDialog, setShowSelectSymbolTypeDialog] = useState(false);
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();
    const deleteSymbolsMessage = getSymbolsDeleteMessages(symbols, selectedRowKeys);

    const columns: Array<ColumnProps<Symbol>> = [
        {
            title: intl.formatMessage(messages.name),
            dataIndex: 'multilingualName',
            className: theme.column,
            render: (value: InternationalString) => (
                <EllipsisText text={LocalesService.internationalStringToString(value, currentLocale)} />
            ),
        },
        {
            title: intl.formatMessage(messages.id),
            dataIndex: 'id',
            className: theme.column,
        },
        {
            title: intl.formatMessage(messages.symbol),
            dataIndex: 'graphical',
            className: theme.column,
            render: (value: string, record: Symbol) => {
                if (isKanbanCardType(record)) {
                    return (
                        <div>
                            <img style={{ width: 120, height: 50 }} src={icKanbanCard.toString()} />
                        </div>
                    );
                }

                if (ComplexSymbolManager.getSymbolType(record) === SymbolTypeId.VERTICAL_SWIMLANE) {
                    return (
                        <div className={theme.swimlaneVerticalSymbol}>
                            <Icon spriteSymbol={swimlaneSymbol} />
                        </div>
                    );
                }

                if (ComplexSymbolManager.getSymbolType(record) === SymbolTypeId.HORIZONTAL_SWIMLANE) {
                    return (
                        <div className={theme.swimlaneHorizontalSymbol}>
                            <Icon spriteSymbol={swimlaneSymbol} />
                        </div>
                    );
                }

                if (ComplexSymbolManager.getSymbolType(record) === SymbolTypeId.VERTICAL_POOL) {
                    return (
                        <div className={theme.poolVerticalSymbol}>
                            <Icon spriteSymbol={poolSymbol} />
                        </div>
                    );
                }

                if (ComplexSymbolManager.getSymbolType(record) === SymbolTypeId.HORIZONTAL_POOL) {
                    return (
                        <div className={theme.poolHorizontalSymbol}>
                            <Icon spriteSymbol={poolSymbol} />
                        </div>
                    );
                }

                if (ComplexSymbolManager.getSymbolType(record) === SymbolTypeId.CROSS) {
                    return (
                        <div className={theme.poolHorizontalSymbol}>
                            <Icon spriteSymbol={crossSymbol} />
                        </div>
                    );
                }

                return SymbolToImageConverterGraph.convertSymbol(record, intl, graph);
            },
        },
        {
            title: intl.formatMessage(messages.icon),
            dataIndex: 'icon',
            className: theme.column,
            render: (value: string | undefined, record: Symbol) => {
                if (isKanbanCardType(record)) {
                    value = icKanbanCard.toString();
                }
                return (
                    <div>
                        {value ? <img className={theme.icon} src={value} /> : intl.formatMessage(messages.noIcon)}
                    </div>
                );
            },
        },
        {
            dataIndex: 'groupId',
            render: (value: string, record: Symbol) => {
                return renderRowActionButtons(record);
            },
            className: theme.buttonsColumn,
        },
    ];

    const converterInitialized = (g: MethodologiesGraph) => {
        if (!graph) {
            setGraph(g);
        }
    };

    const renderRowActionButtons = (symbol: Symbol) => {
        const deleteContent = (
            <Alert
                message={
                    <>
                        <b>{intl.formatMessage(messages.deleteSymbols)}</b>
                        {`"${LocalesService.internationalStringToString(symbol.multilingualName)}"`}
                    </>
                }
                type="warning"
            />
        );

        return (
            <RowButtons
                buttons={[
                    EditButton.build((e: React.SyntheticEvent<HTMLButtonElement, Event>) => {
                        e.stopPropagation();
                        if (!isKanbanCardType(symbol)) return openSymbolEditor({ symbol });

                        editKanbanCard && editKanbanCard(symbol as unknown as KanbanCardType);
                    }, (symbol.style && symbol.style.indexOf(MxConstants.STYLE_SHAPE) !== -1) || disabled),
                    DeleteButton.build(
                        () => {
                            if (!isKanbanCardType(symbol)) return onDeleteSymbols([symbol]);

                            deleteKanbanCards && deleteKanbanCards([symbol] as unknown as KanbanCardType[]);
                        },
                        undefined,
                        undefined,
                        undefined,
                        intl.formatMessage(messages.deleteSymbolsDialogTitle),
                        deleteContent,
                    ),
                ]}
            />
        );
    };

    const onRow = (row: Symbol) => {
        const newState: string[] = toggleArrayItem<string>(row.id, selectedRowKeys);

        return {
            onClick: () => setSelectedRowKeys(newState),
        };
    };

    const searchElements = (symbol: Symbol): boolean => {
        const name = LocalesService.internationalStringToString(symbol.multilingualName, currentLocale);
        const description = LocalesService.internationalStringToString(symbol.multilingualDescription, currentLocale);
        const searchText = searchFilter.toLowerCase().trim() || '';
        const searchFields = [name, description, symbol.id];
        const found = searchFields.some((field) => field?.toLowerCase().trim().includes(searchText));

        return found;
    };

    const filteredData = allSymbols.filter(searchElements);

    return (
        <section className={theme.container}>
            <SymbolToImageConverterGraph modelType={{ id: uuid() } as ModelType} initialized={converterInitialized} />
            <SymbolTypeSelectDialogComponent
                open={showSelectSymbolTypeDialog}
                onClose={() => {
                    setShowSelectSymbolTypeDialog(false);
                }}
                onSubmit={(symbolTypeId) => {
                    setShowSelectSymbolTypeDialog(false);

                    if (symbolTypeId === KANBAN_CARD) {
                        openKanbanCardEditor && openKanbanCardEditor();
                        return;
                    }
                    openSymbolEditor({ symbolTypeId });
                }}
            />
            <TabHeader
                buttons={[
                    {
                        children: intl.formatMessage(messages.newSymbol),
                        disabled,
                        onClick: (e) => {
                            setShowSelectSymbolTypeDialog(true);
                            e.stopPropagation();
                        },
                        dataTest: 'object-type_new-symbol-btn',
                    },
                    DeleteSelected.build(
                        () => {
                            const symbolsToDelete = allSymbols.filter((symbol) =>
                                selectedRowKeys.some((key) => !isKanbanCardType(symbol) && key === symbol.id),
                            );
                            const kanbanCardsToDelete = allSymbols.filter((symbol) =>
                                selectedRowKeys.some((key) => isKanbanCardType(symbol) && key === symbol.id),
                            );

                            if (symbolsToDelete.length) {
                                onDeleteSymbols(symbolsToDelete);
                            }

                            if (kanbanCardsToDelete.length) {
                                deleteKanbanCards &&
                                    deleteKanbanCards(kanbanCardsToDelete as unknown as KanbanCardType[]);
                            }

                            setSelectedRowKeys([]);
                        },
                        !selectedRowKeys.length,
                        undefined,
                        intl.formatMessage(messages.deleteSymbolsDialogTitle),
                        deleteSymbolsMessage,
                        'object-type-delete-symbol_button',
                    ),
                ]}
                onSearchChange={setSearchFilter}
            />
            <Table
                rowSelection={{
                    selectedRowKeys,
                    onChange: setSelectedRowKeys as any,
                }}
                rowKey={(record: Symbol) => `${record.id}`}
                columns={columns}
                dataSource={filteredData}
                size="small"
                className={theme.table}
                onRow={onRow}
                pagination={false}
            />
        </section>
    );
};

export { SymbolsTab };
