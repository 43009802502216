import {
    CREATE_EXTERNAL_SESSION_REQUEST,
    CREATE_EXTERNAL_SESSION_SUCCESS,
    DELETE_EXTERNAL_SESSION_LIST_REQUEST,
    DELETE_EXTERNAL_SESSION_REQUEST,
    DELETE_EXTERNAL_SESSION_SUCCESS,
    GET_EXTERNAL_SESSIONS_REQUEST,
    GET_EXTERNAL_SESSIONS_SUCCESS,
} from '../actionsTypes/externalSession.actionTypes';
import { ExternalSession, ExternalSessionRequest } from '../serverapi/api';
import {
    TCreateExternalSessionRequestAction,
    TCreateExternalSessionSuccessAction,
    TDeleteExternalSessionListRequestAction,
    TDeleteExternalSessionRequestAction,
    TDeleteExternalSessionSuccessAction,
    TGetExternalSessionsRequestAction,
    TGetExternalSessionsSuccessAction,
} from './externalSessions.actions.types';

export const getExternalSessionsRequest = (serverId: string): TGetExternalSessionsRequestAction => ({
    type: GET_EXTERNAL_SESSIONS_REQUEST,
    payload: {
        serverId,
    },
});

export const getExternalSessionsSuccess = (
    externalSessions: ExternalSession[],
    serverId: string,
): TGetExternalSessionsSuccessAction => ({
    type: GET_EXTERNAL_SESSIONS_SUCCESS,
    payload: {
        serverId,
        externalSessions,
    },
});

export const createExternalSessionRequest = (
    body: ExternalSessionRequest,
    serverId: string,
): TCreateExternalSessionRequestAction => ({
    type: CREATE_EXTERNAL_SESSION_REQUEST,
    payload: {
        serverId,
        body,
    },
});

export const createExternalSessionSuccess = (
    externalSession: ExternalSession,
    serverId: string,
): TCreateExternalSessionSuccessAction => ({
    type: CREATE_EXTERNAL_SESSION_SUCCESS,
    payload: {
        serverId,
        externalSession,
    },
});

export const deleteExternalSessionRequest = (
    externalSessionId: string,
    serverId: string,
): TDeleteExternalSessionRequestAction => ({
    type: DELETE_EXTERNAL_SESSION_REQUEST,
    payload: {
        serverId,
        externalSessionId,
    },
});

export const deleteExternalSessionListRequest = (
    externalSessionIds: string[],
    serverId: string,
): TDeleteExternalSessionListRequestAction => ({
    type: DELETE_EXTERNAL_SESSION_LIST_REQUEST,
    payload: {
        serverId,
        externalSessionIds,
    },
});

export const deleteExternalSessionSuccess = (
    externalSessionId: string,
    serverId: string,
): TDeleteExternalSessionSuccessAction => ({
    type: DELETE_EXTERNAL_SESSION_SUCCESS,
    payload: {
        serverId,
        externalSessionId,
    },
});
