import TextStyle from '@tiptap/extension-text-style';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        backgroundColor: {
            /**
             * Set the background color attribute
             */
            setBackgroundColor: (color: string) => ReturnType;
            /**
             * Unset the background color attribute
             */
            unsetBackgroundColor: () => ReturnType;
        };
        fontSize: {
            /**
             * Set the font size attribute
             */
            setFontSize: (size: string) => ReturnType;
            /**
             * Unset the font size attribute
             */
            unsetFontSize: () => ReturnType;
        };
    }
}

const TextStyleExtended = TextStyle.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            backgroundColor: {
                default: null,
                parseHTML: (element) => element.style.backgroundColor,
                renderHTML: (attributes) => {
                    if (!attributes['backgroundColor']) {
                        return {};
                    }

                    return {
                        style: `background-color: ${attributes['backgroundColor']}`,
                    };
                },
            },
            fontSize: {
                default: null,
                parseHTML: (element) => element.style.fontSize.replace('px', ''),
                renderHTML: (attributes) => {
                    if (!attributes['fontSize']) {
                        return {};
                    }

                    return {
                        style: `font-size: ${attributes['fontSize']}px`,
                    };
                },
            },
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),
            setBackgroundColor:
                (backgroundColor) =>
                ({ commands }) => {
                    return commands.setMark(this.name, { backgroundColor: backgroundColor });
                },
            unsetBackgroundColor:
                () =>
                ({ chain }) => {
                    // TODO unsetMark ??
                    return chain().setMark(this.name, { backgroundColor: null }).removeEmptyTextStyle().run();
                },
            setFontSize:
                (fontSize) =>
                ({ commands }) => {
                    return commands.setMark(this.name, { fontSize: fontSize });
                },
            unsetFontSize:
                () =>
                ({ chain }) => {
                    // TODO unsetMark ??
                    return chain().setMark(this.name, { fontSize: null }).removeEmptyTextStyle().run();
                },
        };
    },
});

export { TextStyleExtended as TextStyle };
