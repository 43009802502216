import { TReducer } from 'src/utils/types';
import { SYSTEM_PROPERTIES_SET_STATE } from '../../src/actionsTypes/systemProperties.actionTypes';
import { SystemProperties } from '../serverapi/api';

const initial: SystemProperties = {};

export const systemPropertiesReducer: TReducer<SystemProperties> = (state = initial, action) => {
    switch (action.type) {
        case SYSTEM_PROPERTIES_SET_STATE: {
            return action.payload.properties;
        }

        default:
            return state;
    }
};
