import { ArisData, ArisImportDescriptor } from '../../serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';

export class ImportArisDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async analizeArisImport(presetId: string, fileId: string): Promise<ArisData> {
        const api = this.getApi();
        const arisData: ArisData = await api.aris.analizeArisImport({ fileId, presetId });
        return arisData;
    }

    public static async arisDoImport(descriptor: ArisImportDescriptor): Promise<void> {
        const api = this.getApi();
        await api.aris.doImport({ body: descriptor });
    }

    public static async arisGetExcel(data: ArisData): Promise<Response> {
        const api = this.getApi();
        const response = (await api.aris.getExcel({ body: data })) as unknown as Response;

        return response;
    }

    public static async arisGetMapping(data: File): Promise<ArisData> {
        const api = this.getApi();
        const arisData = await api.aris.getMapping({ body: data });

        return arisData;
    }
}
