import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'DbSearchDialog.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'DbSearchDialog.save',
        defaultMessage: 'Сохранить',
    },
});
