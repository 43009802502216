import { put, select, takeEvery, all } from 'redux-saga/effects';
import { get } from 'lodash-es';
import {
    OPEN_CONTENT_LOADING_TAB,
    WORKSPACE_TABS_REMOVE_REQUEST,
    WORKSPACE_TABS_SET_ACTION,
    WORKSPACE_TABS_SET_PARAMS,
    WORKSPACE_TABS_COPY_TO_CLIPBOARD,
    WORKSPACE_REMOVE_MULTIPLE_TABS,
} from '../actionsTypes/tabs.actionTypes';
import {
    workspaceChangeTab,
    workspaceRemoveTab,
    workspaceAddTab,
    workspaceRemoveTabRequest,
} from '../actions/tabs.actions';
import {
    TWorkspaceTabsRemoveAction,
    TWorkspaceTabsSetAction,
    TWorkspaceTabsSetParams,
    TOpenContentLoadingTabAction,
    TCopyToClipboardAction,
    TWorkspaceTabsRemoveMultipleAction,
} from '../actions/tabs.actions.types';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { TreeItemType } from '../modules/Tree/models/tree';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTab, TWorkspaceTabItemParams } from '../models/tab.types';
import { NodeId } from '../serverapi/api';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { isNullOrUndefined } from 'is-what';
import { EditorMode } from '../models/editorMode';
import { toClipboardText } from '../utils/clipboardUtils';
import { DEFAULT_DOT_SPACE } from '../modules/Editor/components/Editor/CanvasSettingsConstants';
import { setDotSpace } from '../actions/canvas-settings.actions';
import { showNotification } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { v4 as uuid } from 'uuid';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TCopyContent } from '../modules/CopyDialog/CopyDialog.types';

function* handleWorkspaceTabSetAction(action: TWorkspaceTabsSetAction) {
    const tab = yield select(TabsSelectors.byId(action.payload.nodeId));

    if (tab) {
        tab.actions = {
            ...tab.actions,
            [action.payload.name]: action.payload.value,
        };

        yield put(workspaceChangeTab(tab));
    }
}

function* handleWorkspaceTabSetParams(action: TWorkspaceTabsSetParams) {
    const { nodeId, params } = action.payload;

    if (params.zoomLevel) {
        const dotSpace = (DEFAULT_DOT_SPACE / 100) * params.zoomLevel;

        yield put(setDotSpace({ dotSpace }));
    }

    const tab = yield select(TabsSelectors.byId(nodeId));
    const contentType = get(tab, 'content.type');

    // TODO: при быстром закрытии вкладки данные не успевают прийти в стору (см BPM-2012)
    if (tab && (contentType === TreeItemType.Wiki || contentType === TreeItemType.Model)) {
        tab.params = {
            ...tab.params,
            ...params,
        };

        yield put(workspaceChangeTab(tab));
    }
}

function* handleTabWithoutContentClose(action: TWorkspaceTabsRemoveAction) {
    const { workspaceTab } = action.payload;
    if (
        !workspaceTab.content ||
        workspaceTab.type === WorkSpaceTabTypes.CONTENT_LOADING_PAGE_TAB ||
        workspaceTab.type === WorkSpaceTabTypes.ADMINTOOLS_TAB ||
        workspaceTab.type === WorkSpaceTabTypes.USERDATA_EDITING_TAB ||
        workspaceTab.type === WorkSpaceTabTypes.GROUPDATA_EDITING_TAB ||
        workspaceTab.type === WorkSpaceTabTypes.SERVER_SETTINGS ||
        workspaceTab.type === WorkSpaceTabTypes.SAFETY ||
        workspaceTab.type === WorkSpaceTabTypes.MONITORING_TAB ||
        workspaceTab.type === WorkSpaceTabTypes.DB_SEARCH ||
        workspaceTab.type === WorkSpaceTabTypes.DB_SEARCH_DELETED_ELEMENTS ||
        workspaceTab.type === WorkSpaceTabTypes.HOME_PAGE_TAB ||
        workspaceTab.type === WorkSpaceTabTypes.SCRIPT_DASHBOARD ||
        workspaceTab.type === WorkSpaceTabTypes.SCRIPT_SCHEDULER
    ) {
        yield put(workspaceRemoveTab(workspaceTab));
    }
}

function* handleOpenContentLoadingTab({ payload: { node } }: TOpenContentLoadingTabAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(node.nodeId.serverId));
    if (isNullOrUndefined(server)) {
        throw new Error(`Cannot find server with ID=${node.nodeId.serverId}`);
    }
    const loadingTabId: NodeId = { ...node.nodeId } as NodeId;
    loadingTabId.id = loadingTabId.id.concat('loading');
    const contentLoadingPageTab: TWorkspaceTab = {
        title: node.name,
        nodeId: loadingTabId,
        type: WorkSpaceTabTypes.CONTENT_LOADING_PAGE_TAB,
        mode: EditorMode.Read,
        params: {
            closable: false,
            serverId: node.nodeId.serverId,
        } as TWorkspaceTabItemParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* handleWorkspaceTabsCopyToClipboard(action: TCopyToClipboardAction) {
    const { value } = action.payload;

    const result = toClipboardText(value.objectDiagramElement.id);
    if (result) {
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType.COPY_GUID_SUCCESS,
            }),
        );
    } else {
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType.COPY_GUID_FAIL,
            }),
        );

        const copyContent: TCopyContent = ['id', value.objectDiagramElement.id];
        yield put(openDialog(DialogType.COPY_ITEM_DIALOG, { content: copyContent }));
    }
}

function* handleRemoveMultipleTabs({ payload }: TWorkspaceTabsRemoveMultipleAction) {
    const { workspaceTabs } = payload;
    yield all(workspaceTabs.map((tab) => put(workspaceRemoveTabRequest(tab))));
}

export function* workspaceTabSaga() {
    yield takeEvery(WORKSPACE_TABS_SET_ACTION, handleWorkspaceTabSetAction);
    yield takeEvery(WORKSPACE_TABS_SET_PARAMS, handleWorkspaceTabSetParams);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabWithoutContentClose);
    yield takeEvery(OPEN_CONTENT_LOADING_TAB, handleOpenContentLoadingTab);
    yield takeEvery(WORKSPACE_TABS_COPY_TO_CLIPBOARD, handleWorkspaceTabsCopyToClipboard);
    yield takeEvery(WORKSPACE_REMOVE_MULTIPLE_TABS, handleRemoveMultipleTabs);
}
