import { Form, Input } from 'antd';
import theme from './TreeItemNameEditor.scss';
import React, { MouseEvent, ChangeEvent, FormEvent, SyntheticEvent, FC, useState, useEffect } from 'react';
import { treeItemRenameCancel } from '@/actions/tree.actions';
import { useDispatch } from 'react-redux';

type TTreeItemNameEditorProps = {
    name: string | undefined; // в некоторых случаях текст приходит пустой BPM-5608
    isEditing: boolean;
    suffix?: string;
    allowEmptyValue?: boolean;
    onActivateEditable?: () => void;
    onDeactivateEditable?: () => void;
    onChange?: (text: string) => void;
};

export const TreeItemNameEditor: FC<TTreeItemNameEditorProps> = (props) => {
    const { name, suffix, isEditing, allowEmptyValue, onChange } = props;
    const [hasError, setHasError] = useState<boolean>(false);
    const [currentName, setCurrentName] = useState<string>(name || '');

    const dispatch = useDispatch();

    const onDeactivateEditable = () => dispatch(treeItemRenameCancel());

    useEffect(() => {
        if (name !== undefined) setCurrentName(name);
    }, [name]);

    const onSaveText = (e: MouseEvent | FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (isEditing) {
            onDeactivateEditable();
        }

        if (!hasError && onChange && name !== currentName) {
            onChange(currentName);
        }
    };

    const onChangeText = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const {
            target: { value },
        } = e;
        setCurrentName(value.trim().length ? value : value.trim());
        setHasError(value.trim().length === 0 && !allowEmptyValue);
    };

    const onBlur = (e: SyntheticEvent<HTMLInputElement> | SyntheticEvent<HTMLTextAreaElement>) => {
        onSaveText(e);
    };

    const titleText = isEditing ? '' : (name || '').concat(suffix || '');

    return (
        <div className={theme.container} title={titleText}>
            {isEditing ? (
                <Form.Item validateStatus={hasError ? 'error' : 'success'}>
                    <Input
                        size="small"
                        autoFocus
                        value={currentName}
                        onChange={onChangeText}
                        onPressEnter={onSaveText}
                        onBlur={onBlur}
                        type="text"
                    />
                </Form.Item>
            ) : (
                (name || '').concat(suffix || '')
            )}
        </div>
    );
};
