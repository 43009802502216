import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { NavigatorPanel } from '../NavigatorPanel/NavigatorPanel.component';
import messages from '../../messages/Navigator.messages';
import theme from './NavigatorOutline.scss';
import { BPMMxOutline } from '../../../../mxgraph/view/BPMMxOutline';
import { TNavigatorOutlineActionsProps, TNavigatorOutlineReduxProps } from './NavigatorOutline.types';
import { TNavigatorTab } from '../../../../reducers/navigator.reducer.types';

type TNavigatorOutlineProps = WrappedComponentProps & TNavigatorOutlineReduxProps & TNavigatorOutlineActionsProps;

class NavigatorOutline extends React.Component<TNavigatorOutlineProps> {
    outlineContainer: HTMLDivElement;
    outline: BPMMxOutline;

    constructor(props: TNavigatorOutlineProps) {
        super(props);

        this.setOutlineRef = this.setOutlineRef.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidUpdate(prevProps: TNavigatorOutlineProps) {
        if (prevProps.graph !== this.props.graph) this.drawOutline();
    }

    componentDidMount() {
        this.drawOutline();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.outline.update(true);
    }

    drawOutline() {
        this.outlineContainer.innerHTML = '';
        if (!this.props.graph || !this.outlineContainer) return;

        this.outline = new BPMMxOutline(this.props.graph, this.outlineContainer);
    }

    setOutlineRef(outlineContainer: HTMLDivElement | null) {
        if (outlineContainer) this.outlineContainer = outlineContainer;
    }

    render() {
        return (
            <NavigatorPanel
                type={TNavigatorTab.Outline}
                titleProps={{
                    title: this.props.intl.formatMessage(messages.outline),
                }}
            >
                <div className={theme.outlineContainer} ref={this.setOutlineRef} />
            </NavigatorPanel>
        );
    }
}

const NavigatorOutlineWithIntl = injectIntl(NavigatorOutline);

export { NavigatorOutlineWithIntl as NavigatorOutline };
