import { Input, Form, FormInstance } from 'antd';
import React, { ChangeEvent } from 'react';
import theme from './SimulationPanel.scss';
import messages from './SimulationPanel.messages';
import { SimulationRun } from '../../../../serverapi/api';
import { useIntl } from 'react-intl';

type TCollapseHeaderProps = {
    sim: SimulationRun;
    form: FormInstance;
    renameSimulationAllowed: boolean;
    setRenameSimulationAllowed: React.Dispatch<React.SetStateAction<boolean>>;
    renameSimulation: (simulation: SimulationRun, e: ChangeEvent<HTMLInputElement>) => void;
    setIsCollapseDisabled: (isCollapseDisabled: boolean) => void;
};

export const CollapseHeader = (props: TCollapseHeaderProps) => {
    const {
        sim,
        form,
        renameSimulationAllowed,
        setRenameSimulationAllowed,
        renameSimulation,
        setIsCollapseDisabled
    } = props;
    const intl = useIntl();

    const handleOnBlur = () => {
        setIsCollapseDisabled(false);

        if (form.getFieldError(sim.id.id).length) {
            return;
        }

        setRenameSimulationAllowed(false);
    };

    return (
        <div className={theme.collapseHeader}>
            {renameSimulationAllowed ? (
                <Form autoComplete='off' form={form}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: intl.formatMessage(messages.titleLengthError),
                            },
                        ]}
                        initialValue={sim.name}
                        name={sim.id.id}
                    >
                        <Input
                            onChange={(e: ChangeEvent<HTMLInputElement>) => renameSimulation(sim, e)}
                            onBlur={handleOnBlur}
                            autoFocus={renameSimulationAllowed}
                        />
                    </Form.Item>
                </Form>
            ) : (
                <div className={theme.collapseHeaderTitle}>{sim.name}</div>
            )}
        </div>
    );
};
