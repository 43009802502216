import { editApproval } from '@/actions/approval.actions';
import { FormInstance } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ApprovalDTO, ApprovalStageDTO } from '@/serverapi/api';
import { StageSettingsTab } from './StageSettingsTab.component';

type TApprovalDialogProps = {
    formRef: React.RefObject<FormInstance<any>>;
    isCreateMode: boolean;
    stage: ApprovalStageDTO;
    approval: ApprovalDTO;
};

export const ApprovalStagesTab = ({ approval, formRef, isCreateMode, stage }: TApprovalDialogProps) => {
    const dispatch = useDispatch();

    const selectedStage = approval.stages?.find((st) => st.id === stage.id);

    if (!selectedStage) return null;

    const onChangeStages = (stages: ApprovalStageDTO[] | undefined) => {
        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages,
                },
            }),
        );
    };

    return (
        <StageSettingsTab
            stages={approval.stages}
            stageFormRef={formRef}
            stageId={stage.id}
            isCreateMode={isCreateMode}
            onChangeStages={onChangeStages}
        />
    );
};
