import React, { useState } from 'react';
import ExtraButtonSettings from '../../ExtraButtonSettings.component';
import theme from '../../SystemProperties.scss';
import { ExtraButtonProperties } from '../../../../../../src/serverapi/api';
import { useSelector } from 'react-redux';
import { ServerProfileSelectors } from '../../../../../selectors/serverProfile.selectors';
import { ServerSelectors } from '../../../../../selectors/entities/server.selectors';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { SystemPropertiesFormItemNames, TPropsWithFormRef } from '../../SystemProperties.types';
import { Form } from 'antd';



export const HomePageSettings = (props: TPropsWithFormRef) => {
    const serverId: string = useSelector(ServerSelectors.serverId);
    const extraButtons: ExtraButtonProperties[] | undefined = useSelector(SystemPropertiesSelectors.getExtraButtonProperties);
    const extraButtonsPanelWidth: number | undefined = useSelector(SystemPropertiesSelectors.getExtraButtonPropertiesPanelWidth );
    const serverProfiles = useSelector(ServerProfileSelectors.getAllServerProfilesByServerId(serverId));
    const { formRef } = props;
    const form = formRef.current;
    
    const [extraButtonProperties, setExtraButtonProperties] = useState<ExtraButtonProperties[]>(extraButtons || []);
    const handleExtraButtonProperties = (properties: ExtraButtonProperties[]) => {
        const fieldsValue = form?.getFieldsValue();
        if (fieldsValue) {
            fieldsValue.extraButtonProperties = properties;
            form?.setFieldsValue(fieldsValue);
        }
        setExtraButtonProperties(properties);
    };


    return (
        <div className={theme.formTab}>
            <ExtraButtonSettings
                serverId={serverId}
                onSubmitButton={(button: ExtraButtonProperties) => {
                    handleExtraButtonProperties([
                        button,
                        ...(extraButtonProperties?.filter((b) => b.id !== button.id) || []),
                    ]);
                }}
                onDeleteButton={(button: ExtraButtonProperties) => {
                    handleExtraButtonProperties(
                        extraButtonProperties?.filter((b) => b.id !== button.id) || [], // todo удалять по индексу
                    );
                }}
                sortUp={(button: ExtraButtonProperties) => {
                    const index: number | undefined = extraButtonProperties?.findIndex((b) => b.id === button.id);
                    const newButtons: ExtraButtonProperties[] = [...(extraButtonProperties || [])];
                    if (index && index >= 0) {
                        newButtons[index] = newButtons[index - 1];
                        newButtons[index - 1] = button;
                        handleExtraButtonProperties(newButtons);
                    }
                }}
                sortDown={(button: ExtraButtonProperties) => {
                    if (extraButtonProperties) {
                        const index: number | undefined = extraButtonProperties.findIndex((b) => b.id === button.id);
                        const newButtons: ExtraButtonProperties[] = [...(extraButtonProperties || [])];
                        if (index !== undefined && index < extraButtonProperties.length - 1) {
                            newButtons[index] = newButtons[index + 1];
                            newButtons[index + 1] = button;
                            handleExtraButtonProperties(newButtons);
                        }
                    }
                }}
                serverProfiles={serverProfiles}
                extraButtonProperties={extraButtonProperties}
                extraButtonsPanelWidth={extraButtonsPanelWidth}
            />
            {/* Form.Item name="extraButtonProperties" создан для хранения значения extraButtonProperties в форме, визуально не отображается */}
            <Form.Item name={SystemPropertiesFormItemNames.extraButtonProperties} initialValue={extraButtons} noStyle/>
        </div>
    );
};
