import { MxConstants, MxGraph, MxOutline, MxPoint, MxUtils } from '../mxgraph';
import { BPMMxGraph } from '../bpmgraph';

class BPMMxOutline extends MxOutline {
    border = 1;

    debounceUpdateFormat: Function;
    debounceUpdate: Function;

    constructor(source: MxGraph, container: HTMLElement) {
        super(source, container);
        if (this.container) {
            this.container.style.backgroundColor = 'white';
        }
        this.update(true);
    }

    getOutlineOffset(scale: number): MxPoint {
        const outlineScale: number = this.outline.getView().scale;
        const sourceScale: number = this.source.getView().scale;

        if (MxUtils.hasScrollbars(this.source.container)) {
            const width = this.source.container.scrollWidth;
            const height = this.source.container.scrollHeight;

            const dx = Math.max(
                0,
                (this.outline.container.clientWidth / outlineScale - width - 2 * MxConstants.OUTLINE_STROKEWIDTH) / 2,
            );
            const dy = Math.max(
                0,
                (this.outline.container.clientHeight / outlineScale - height - 2 * MxConstants.OUTLINE_STROKEWIDTH) / 2,
            );

            const x =
                Math.max(
                    0,
                    this.outline.container.clientHeight / outlineScale -
                        this.source.container.scrollHeight / sourceScale,
                ) / 2;
            const y =
                Math.max(
                    0,
                    this.outline.container.clientWidth / outlineScale - this.source.container.scrollWidth / sourceScale,
                ) / 2;

            return new MxPoint(
                Math.round(dx * outlineScale + y - 2 * MxConstants.OUTLINE_STROKEWIDTH),
                Math.round(dy * outlineScale + x - 2 * MxConstants.OUTLINE_STROKEWIDTH),
            );
        }

        return new MxPoint(8 / outlineScale, 8 / outlineScale);
    }

    createGraph(container: HTMLElement): BPMMxGraph {
        const graph = new BPMMxGraph({
            container,
            model: this.source.getModel(),
            renderHint: this.graphRenderHint,
            stylesheet: this.source.getStylesheet(),
        });
        graph.foldingEnabled = false;
        graph.autoScroll = false;

        return graph;
    }
}

export { BPMMxOutline };
