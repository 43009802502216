// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CloneModelDialog__treeLocation__j_G3w{margin-top:25px;margin-bottom:10px;height:25vh}.CloneModelDialog__selectStrategy___DpST{margin-top:25px;width:400px}.CloneModelDialog__alert__BMqvi{color:red}", "",{"version":3,"sources":["webpack://./src/modules/CloneModelDialog/components/CloneModelDialog.scss"],"names":[],"mappings":"AACA,uCACI,eAAA,CACA,kBAAA,CACA,WAAA,CAGJ,yCACI,eAAA,CACA,WAAA,CAGJ,gCACI,SAAA","sourcesContent":["\r\n.treeLocation {\r\n    margin-top: 25px;\r\n    margin-bottom: 10px;\r\n    height: 25vh;\r\n}\r\n\r\n.selectStrategy{\r\n    margin-top: 25px;\r\n    width: 400px;\r\n}\r\n\r\n.alert{\r\n    color: red;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"treeLocation": "CloneModelDialog__treeLocation__j_G3w",
	"selectStrategy": "CloneModelDialog__selectStrategy___DpST",
	"alert": "CloneModelDialog__alert__BMqvi"
};
export default ___CSS_LOADER_EXPORT___;
