export const FRAME_STYLES_INDICATOR = 'framesRoot';

// индикатор неизменяемости стиля у ячейки
export const FIXED_STYLE_INDICATOR = 'fixedStyle';

export const CUSTOM_BOUND_INDICATOR = 'customBound';

export const DEFAULT_SYMBOL_TYPE_ID = 'simple';

export const MULTILINE_OVERFLOW_ATTRIBUTE = 'multilineOverflow';

export const DROP_TARGET_ATTRIBUTE = 'dropTarget';

export enum SymbolTypeId {
    SIMPLE = 'simple',
    VR = 'frame.type.vr',
    IDEF = 'frame.type.idef',
    HORIZONTAL_SWIMLANE = 'frame.type.swimlane.h',
    VERTICAL_SWIMLANE = 'frame.type.swimlane.v',
    HORIZONTAL_POOL = 'frame.type.pool.h',
    VERTICAL_POOL = 'frame.type.pool.v',
    CROSS = 'frame.type.cross',
    TABLE_SYMBOL = 'frame.type.table.symbol',
    TABLE_SYMBOL_CLASS = 'frame.type.table.symbol.class',
    TABLE_SYMBOL_CLASS_LINE = 'frame.type.table.symbol.class.line',
    UML_FRAME = 'uml-frame',
    UML_FRAME_TITLE = 'uml-frame-title',
    UML_NOTE = 'uml-note-id',
    UML_MESSAGE = 'uml-message-id',
    UML_CLASS = 'frame.type.uml.class',
    UML_PACKAGE = 'frame.type.uml.package',
    LIFE_LINE_ACTOR = 'LIFE_LINE_ACTOR',
}

export enum SequenceSymbolTypeId {
    LIFE_LINE = 'sequence.diagram.lifeline',
    LIFE_LINE_BOUNDERY = 'sequence.diagram.lifeline.boundery',
    LIFE_LINE_ENTITY = 'sequence.diagram.lifeline.entity',
    LIFE_LINE_CONTROL = 'sequence.diagram.lifeline.control',
    LIFE_LINE_ACTOR = 'sequence.diagram.lifeline.actor',
}

export const OBJECT_SYMBOL_TYPES = [...Object.values(SymbolTypeId), ...Object.values(SequenceSymbolTypeId)];

export enum SequenceSymbolShapes {
    LIFE_LINE = 'sequenceLifeline',
    LIFE_LINE_BOUNDERY = 'sequenceBounderyLifeline',
    LIFE_LINE_ENTITY = 'sequenceEntityLifeline',
    LIFE_LINE_CONTROL = 'sequenceControlLifeline',
    UML_FRAME_TITLE = 'uml-frame-title',
    UML_NOTE_SHAPE = 'uml-note',
    UML_MESSAGE_SHAPE = 'uml-message',
    LIFE_LINE_ACTOR = 'sequenceActorLifeline',
    DESTROY_ATTRIBUTE = 'sequenceDestroyAttribute',
}

export const sequenceShapeBySymbolId = {
    [SequenceSymbolTypeId.LIFE_LINE]: SequenceSymbolShapes.LIFE_LINE,
    [SequenceSymbolTypeId.LIFE_LINE_BOUNDERY]: SequenceSymbolShapes.LIFE_LINE_BOUNDERY,
    [SequenceSymbolTypeId.LIFE_LINE_ENTITY]: SequenceSymbolShapes.LIFE_LINE_ENTITY,
    [SequenceSymbolTypeId.LIFE_LINE_CONTROL]: SequenceSymbolShapes.LIFE_LINE_CONTROL,
    [SymbolTypeId.UML_NOTE]: SequenceSymbolShapes.UML_NOTE_SHAPE,
    [SymbolTypeId.UML_MESSAGE]: SequenceSymbolShapes.UML_MESSAGE_SHAPE,
    [SequenceSymbolTypeId.LIFE_LINE_ACTOR]: SequenceSymbolShapes.LIFE_LINE_ACTOR,
};
