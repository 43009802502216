import { defineMessages } from 'react-intl';

export default defineMessages({
    colSettings: {
        id: 'ReportEditorSidePanel.colSettings',
        defaultMessage: 'Настройки колонки',
    },
    commonSettings: {
        id: 'ReportEditorSidePanel.commonSettings',
        defaultMessage: 'Настройки',
    },
});
