import { Modifier, EditorState } from 'draft-js';
import { COMMENT_TYPE } from './Comment.constants';
import { v4 as uuid } from 'uuid';
import { getSelectionEntity } from 'draftjs-utils';

export function addComment(editorState: EditorState, threadId?: string) {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    const entityKey = editorState
        .getCurrentContent()
        .createEntity(COMMENT_TYPE, 'MUTABLE', { threadId: threadId || uuid() })
        .getLastCreatedEntityKey();

    const withoutLink = Modifier.applyEntity(contentState, selection, entityKey);

    return EditorState.push(editorState, withoutLink, 'apply-entity');
}

export function clearComment(editorState: EditorState) {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const newContentState = Modifier.applyEntity(contentState, selection, null);

    return EditorState.push(editorState, newContentState, 'apply-entity');
}

export function getSelectionEntityData(editorState: EditorState): any {
    let data = {};
    const entity = getSelectionEntity(editorState);
    if (entity) {
        data = editorState.getCurrentContent().getEntity(entity).getData();
    }

    return data;
}
