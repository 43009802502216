import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    AttributeType,
    AttributeTypeGroup,
    InternationalString,
    KanbanCardType,
    ModelType,
    ModelTypeGroup,
    ObjectType,
    ObjectTypeGroup,
    Symbol,
} from '../../../../../../serverapi/api';
import theme from '../Presets.scss';
import messages from './ObjectType.messages';
import presetMessages from '../../../messages/Presets.messages';
import { Form, Tabs } from 'antd';
import { GeneralTab } from './ObjectTypeGeneralTab.component';
import { SymbolsTab } from './SymbolsTab/SymbolsTab.component';
import { DecompositionsTab } from './DecompositionsTab.component';
import { AttributesTab } from '../AttributeType/AttributesTab.component';
import { FooterButtons } from '../../../../../Footer/FooterButtons.component';
import footerMessages from '../../../../../Footer/FooterButtons.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SymbolSelectors } from '../../../../../../selectors/symbol.selectors';
import { ObjectTypeGroupSelectors } from '../../../../../../selectors/objectTypeGroup.selectors';
import { AttributeTypeSelectors } from '../../../../../../selectors/attributeType.selectors';
import { workspaceRemoveTabByNodeId } from '../../../../../../actions/tabs.actions';
import { submitObjectTypes } from '../../../../../../actions/objectType.actions';
import { TOpenSymbolEditorTabPayload } from '../../../../../../actions/methodologySetting.actions.types';
import { openKanbanCardEditorTab, openSymbolEditorTab } from '../../../../../../actions/methodologySetting.actions';
import { differenceBy } from 'lodash-es';
import { TSubmitObjectTypePayload } from '../../../../../../actions/objectType.actions.types';
import { IWorkspaceTabItemObjectTypeEditorParams, TWorkspaceTab } from '../../../../../../models/tab.types';
import { ChildElementsTab } from './ChildElementsTab/ChildElementsTab.component';
import { TAllowingTypes, TValidTypes } from './ChildElementsTab/ChildElementsTab.types';
import { ObjectTypeSelectors } from '../../../../../../selectors/objectType.selectors';
import { PresetSettingsKanbanCardTypeSelectors } from '../../../../../../selectors/presetSettings/presetSettingsKanbanCardType.selectors';
import {
    clearKanbanCardTypesForDelete,
    deleteKanbanCardTypes,
} from '../../../../../../actions/presetSettings/presetSettingsKanbanCardType.actions';
import { LocalesService } from '../../../../../../services/LocalesService';
import { convertStringToArray } from '../../../../../../utils/convertStringToArray';
import { createButtonsCompositeDataTestId } from '../util/createButtonsCompositeDataTestId.utils';
import { TButtonProps } from '../../../../../UIKit/components/Button/Button.types';
import type { Tab } from 'rc-tabs/lib/interface';

type TObjectTypeEditorProps = {
    tab: TWorkspaceTab;
};

const ObjectTypeEditorComponent: FC<TObjectTypeEditorProps> = (props) => {
    const dispatch = useDispatch();

    const intl = useIntl();

    const params = props.tab.params as IWorkspaceTabItemObjectTypeEditorParams;
    const { objectType, preset, serverNode, modelTypes } = params;

    const { serverId } = serverNode.nodeId;

    const otg = useSelector(
        ObjectTypeGroupSelectors.byPresetId({
            serverId: serverNode.nodeId.serverId,
            presetId: preset.id || '',
        }),
    );
    const objectTypes = useSelector(
        ObjectTypeSelectors.byPresetId({
            serverId: serverNode.nodeId.serverId,
            presetId: preset.id || '',
        }),
    );

    const availableAttributeTypes = useSelector(AttributeTypeSelectors.allInPreset(serverId, preset.id) || []);
    const objectTypeGroups: ObjectTypeGroup[] = Object.values(otg.byId);
    const allSymbols = useSelector(SymbolSelectors.byServerIdPresetId(serverId, preset.id));
    const kanbanCardTypes = useSelector(
        PresetSettingsKanbanCardTypeSelectors.listByObjectTypeId(preset.id, objectType.id),
    );
    const kanbanCardTypesForDelete = useSelector(
        PresetSettingsKanbanCardTypeSelectors.listToDeleteByPresetId(preset.id),
    );
    const kanbanCardTypesToShow = kanbanCardTypes.filter(
        ({ id }) => !kanbanCardTypesForDelete.some((cardType) => cardType.id === id),
    );

    const [form] = Form.useForm();

    const memoizedModelTypeGroupsMap: ModelTypeGroup[] = useMemo(
        () =>
            modelTypes
                .map((at) => at.modelTypeGroup)
                .reduce((acc: ModelTypeGroup[], mtg: ModelTypeGroup) => {
                    if (!acc.some((modelTypeGroup) => mtg.id === modelTypeGroup.id)) {
                        acc.push(mtg);
                    }

                    return acc;
                }, []),
        [modelTypes],
    );

    const memoizedValidDecompositionModelTypes: ModelType[] = useMemo(
        () => modelTypes?.filter((mt) => objectType?.validDecompositionModelTypesIds?.includes(mt.id)),
        [modelTypes],
    );

    const [createMode, setCreateMode] = useState<boolean>(params.createMode);
    const [symbolsToRemoveState, setSymbolsToRemoveState] = useState<Symbol[]>([]);
    const [modelTypeGroupsState] = useState<ModelTypeGroup[]>(memoizedModelTypeGroupsMap);
    const [objectTypeState, setObjectTypeState] = useState<ObjectType>(objectType);
    const [saveDisabled, setSaveDisabled] = useState<boolean>(!objectType.objectTypeGroup);
    const [validDecompositionModelTypes, setValidDecompositionModelTypes] = useState<ModelType[]>(
        memoizedValidDecompositionModelTypes,
    );
    const symbolRemovedIds = symbolsToRemoveState.map((symbol) => symbol.id);
    const symbols = allSymbols.filter(
        (symbol: Symbol) => objectType.id === symbol.objectType && !symbolRemovedIds.includes(symbol.id),
    );

    useEffect(() => {
        if (objectTypes?.byId[objectTypeState.id] && params.createMode) {
            setCreateMode(false);
        }
    }, [objectTypes]);

    useEffect(
        () => () => {
            dispatch(clearKanbanCardTypesForDelete({ presetId: preset.id }));
        },
        [],
    );

    const onChangeObjectTypeId = (id: string) => {
        setObjectTypeState({ ...objectTypeState, id });
    };

    const onChangeObjectTypeName = (multilingualName: InternationalString) => {
        setObjectTypeState({ ...objectTypeState, multilingualName });
    };

    const onChangeObjectTypeDescription = (multilingualDescription: InternationalString) => {
        setObjectTypeState({ ...objectTypeState, multilingualDescription });
    };

    const onChangeObjectTypeGroup = (objectTypeGroup: ObjectTypeGroup) => {
        setObjectTypeState({ ...objectTypeState, objectTypeGroup });
        setSaveDisabled(!objectTypeGroup);
    };

    const onChangeHideInNavigator = (hideInNavigator: boolean) => {
        setObjectTypeState({ ...objectTypeState, hideInNavigator });
    };

    const onChangeObjectAllowApprovals = (allowApprovals: boolean) => {
        setObjectTypeState({ ...objectTypeState, allowApprovals });
    };

    const deleteAvailableModelTypeGroups = (modelTypeGroups: ModelTypeGroup[]) => {
        const newValidDecompositionModelTypes = validDecompositionModelTypes?.filter(
            (mt) => !modelTypeGroups.some((mtg) => mt.modelTypeGroup.id === mtg.id),
        );

        setValidDecompositionModelTypes(newValidDecompositionModelTypes);
    };

    const deleteAvailableModelTypes = (deletedModelTypes: ModelType[]) => {
        const newValidDecompositionModelTypes = differenceBy(validDecompositionModelTypes, deletedModelTypes, 'id');

        setValidDecompositionModelTypes(newValidDecompositionModelTypes);
    };

    const addAvailableModelTypes = (availableModelTypes: ModelType[]) => {
        const newValidDecompositionModelTypes = availableModelTypes.concat(validDecompositionModelTypes || []);

        setValidDecompositionModelTypes(newValidDecompositionModelTypes);
    };

    const onChangeAllowAnyDecompositionType = (checked: boolean) => {
        setObjectTypeState({ ...objectTypeState, allowAnyDecomposition: checked });
    };

    const onDeleteSymbols = (deletedSymbols: Symbol[]) => {
        setSymbolsToRemoveState([...symbolsToRemoveState, ...deletedSymbols]);
    };

    const addAttributeTypes = (attributeTypes: AttributeType[]) => {
        const newNodeAttributes = attributeTypes.concat(objectTypeState.nodeAttributes || []);
        setObjectTypeState({ ...objectTypeState, nodeAttributes: newNodeAttributes });
    };

    const deleteAttributeTypes = (attributeTypes: AttributeType[]) => {
        const newNodeAttributes = objectTypeState.nodeAttributes.filter(
            (at: AttributeType) => !attributeTypes.some((a) => a.id === at.id),
        );
        setObjectTypeState({ ...objectTypeState, nodeAttributes: newNodeAttributes });
    };

    const deleteAttributeTypeGroups = (attributeTypeGroups: AttributeTypeGroup[]) => {
        const newNodeAttributes = objectTypeState.nodeAttributes.filter(
            (at: AttributeType) => !attributeTypeGroups.some((atg) => at.attributeTypeGroup?.id === atg.id),
        );
        setObjectTypeState({ ...objectTypeState, nodeAttributes: newNodeAttributes });
    };

    const addDiagramElementAttributes = (attributeTypes: AttributeType[]) => {
        const newDiagramElementAttributes = attributeTypes.concat(objectTypeState.diagramElementAttributes || []);
        setObjectTypeState({ ...objectTypeState, diagramElementAttributes: newDiagramElementAttributes });
    };

    const deleteDiagramElementAttributes = (attributeTypes: AttributeType[]) => {
        const newDiagramElementAttributes = objectTypeState.diagramElementAttributes.filter(
            (at: AttributeType) => !attributeTypes.some((a) => a.id === at.id),
        );
        setObjectTypeState({ ...objectTypeState, diagramElementAttributes: newDiagramElementAttributes });
    };

    const deleteDiagramElementAttributesTypeGroups = (attributeTypeGroups: AttributeTypeGroup[]) => {
        const newDiagramElementAttributes = objectTypeState.diagramElementAttributes.filter(
            (at: AttributeType) => !attributeTypeGroups.some((atg) => at.attributeTypeGroup?.id === atg.id),
        );
        setObjectTypeState({ ...objectTypeState, diagramElementAttributes: newDiagramElementAttributes });
    };

    const onSubmit = (payload: TSubmitObjectTypePayload) => {
        dispatch(submitObjectTypes(payload));
    };

    const openSymbolEditor = (payload: TOpenSymbolEditorTabPayload) => {
        dispatch(openSymbolEditorTab(payload));
    };

    const onChangePreventMultipleInstances = (preventMultipleInstances: boolean) => {
        setObjectTypeState({ ...objectTypeState, preventMultipleInstances });
    };

    const getValidTypeSetter = (type: TValidTypes) => {
        return (value: string[]) => {
            setObjectTypeState({ ...objectTypeState, [type]: value });
        };
    };

    const getAllowTypeSetter = (allowedType: TAllowingTypes) => {
        return (allowedTypeValue: boolean) => {
            setObjectTypeState({ ...objectTypeState, [allowedType]: allowedTypeValue });
        };
    };

    const onChangeObjectTypeSynonymsIds = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
        const synonymsIds: string[] = convertStringToArray(e.target.value);

        setObjectTypeState((prevState) => ({ ...prevState, synonymsIds }));
    }, []);

    const handleSubmit = () => {
        if (form) {
            form.validateFields()
                .then(() => {
                    const validDecompositionModelTypesIds = validDecompositionModelTypes?.map((mt) => mt.id);
                    onSubmit({
                        serverNode,
                        preset,
                        createMode,
                        objectTypes: [
                            {
                                ...objectTypeState,
                                validDecompositionModelTypesIds,
                            },
                        ],
                        symbolsToRemove: symbolsToRemoveState,
                        preventTabClose: createMode,
                        tabNodeId: props.tab.nodeId,
                        kanbanCardTypesForDelete,
                    });
                })
                .catch(() => undefined);
        }
    };

    const deleteQuestion: string = intl.formatMessage(presetMessages.deleteObject, {
        name: LocalesService.internationalStringToString(objectType.multilingualName),
    });

    const buttons: TButtonProps[] = [
        {
            children: intl.formatMessage(footerMessages.cancel),
            onClick: () => dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId)),
            size: 'large',
        },
        {
            disabled: saveDisabled,
            visualStyle: 'primary',
            children: createMode ? intl.formatMessage(footerMessages.create) : intl.formatMessage(footerMessages.save),
            onClick: handleSubmit,
            dataTest: 'object-type_save-btn',
            size: 'large',
        },
    ];

    const items: Tab[] = [
        {
            label: <span>{intl.formatMessage(messages.generalSettings)}</span>,
            key: 'GeneralSettings',
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <Form autoComplete='off' form={form} layout="vertical">
                        <GeneralTab
                            onChangeObjectAllowApprovals={onChangeObjectAllowApprovals}
                            objectType={objectTypeState}
                            objectTypeGroups={objectTypeGroups}
                            createMode={createMode}
                            onChangeObjectTypeName={onChangeObjectTypeName}
                            onChangeObjectTypeId={onChangeObjectTypeId}
                            onChangeObjectTypeDescription={onChangeObjectTypeDescription}
                            onChangeObjectTypeGroup={onChangeObjectTypeGroup}
                            onChangeHideInNavigator={onChangeHideInNavigator}
                            onChangePreventMultipleInstances={onChangePreventMultipleInstances}
                            onChangeObjectTypeSynonymsIds={onChangeObjectTypeSynonymsIds}
                            generalForm={form}
                        />
                    </Form>
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')}
                    />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.objectTypeSymbols)}</span>,
            key: 'ObjectTypeSymbols',
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <SymbolsTab
                        symbols={symbols}
                        kanbanCardTypes={kanbanCardTypesToShow}
                        disabled={createMode}
                        openSymbolEditor={({ symbol, symbolTypeId }) =>
                            openSymbolEditor({
                                serverNode,
                                preset,
                                objectTypeId: objectTypeState.id,
                                symbol,
                                symbolTypeId,
                            })
                        }
                        openKanbanCardEditor={() =>
                            dispatch(
                                openKanbanCardEditorTab({
                                    serverNode,
                                    preset,
                                    objectTypeId: objectTypeState.id,
                                }),
                            )
                        }
                        editKanbanCard={(kanbanCardType: KanbanCardType) =>
                            dispatch(
                                openKanbanCardEditorTab({
                                    serverNode,
                                    preset,
                                    objectTypeId: objectTypeState.id,
                                    kanbanCardType,
                                }),
                            )
                        }
                        onDeleteSymbols={onDeleteSymbols}
                        deleteKanbanCards={(kanbanCardTypesForDelete: KanbanCardType[]) =>
                            dispatch(
                                deleteKanbanCardTypes({
                                    presetId: preset.id,
                                    kanbanCardTypesForDelete,
                                }),
                            )
                        }
                    />
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'symbols_tab')}
                    />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.decompositions)}</span>,
            key: 'ObjectTypeDecompositions',
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <DecompositionsTab
                        modelTypes={modelTypes}
                        modelTypeGroups={modelTypeGroupsState}
                        availableModelTypes={validDecompositionModelTypes}
                        allowAnyDecompositionType={objectTypeState.allowAnyDecomposition}
                        deleteModelTypeGroups={deleteAvailableModelTypeGroups}
                        deleteModelTypes={deleteAvailableModelTypes}
                        addModelTypes={addAvailableModelTypes}
                        onChangeAllowAnyDecompositionType={onChangeAllowAnyDecompositionType}
                    />
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'decompositions_tab')}
                    />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.attributes)}</span>,
            key: 'ObjectTypeNodeAttributes',
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <AttributesTab
                        attributeTypes={objectTypeState.nodeAttributes}
                        availableAttributeTypes={availableAttributeTypes.filter(
                            (at) => !objectTypeState.nodeAttributes.some((a) => a.id === at.id),
                        )}
                        addAttributeTypes={addAttributeTypes}
                        deleteAttributeTypes={deleteAttributeTypes}
                        deleteAttributeTypeGroups={deleteAttributeTypeGroups}
                    />
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'node-attributes_tab')}
                    />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.objectTypeDiagramElementAttributes)}</span>,
            key: 'ObjectTypeDiagramElementAttributes',
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <AttributesTab
                        attributeTypes={objectTypeState.diagramElementAttributes}
                        availableAttributeTypes={availableAttributeTypes.filter(
                            (at) => !objectTypeState.diagramElementAttributes.some((a) => a.id === at.id),
                        )}
                        addAttributeTypes={addDiagramElementAttributes}
                        deleteAttributeTypes={deleteDiagramElementAttributes}
                        deleteAttributeTypeGroups={deleteDiagramElementAttributesTypeGroups}
                    />
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'diagram-element-attributes_tab')}
                    />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.childElements)}</span>,
            key: 'ObjectTypeChildElements',
            disabled: createMode,
            children: (
                <div className={theme.tabContent}>
                    <ChildElementsTab
                        preset={preset}
                        serverNode={serverNode}
                        modelTypeGroups={modelTypeGroupsState}
                        getValidTypeSetter={getValidTypeSetter}
                        getAllowTypeSetter={getAllowTypeSetter}
                        validModelTypes={objectTypeState.validModelTypes || []}
                        validObjectTypes={objectTypeState.validObjectTypes || []}
                        validFolderTypes={objectTypeState.validFolderTypes || []}
                        validNodeTypes={objectTypeState.validNodeTypes || []}
                        allowAnyModelType={!!objectTypeState.allowAnyModelType}
                        allowAnyObjectType={!!objectTypeState.allowAnyObjectType}
                        allowAnyFolderType={!!objectTypeState.allowAnyFolderType}
                        allowAnyNodeType={!!objectTypeState.allowAnyNodeType}
                    />
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'child-elements_tab')}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={theme.container}>
            <span className={theme.navigationTitle}>{`${preset.name} > ${intl.formatMessage(messages.objectType)} > ${
                objectType.name
            }`}</span>
            <Tabs className={theme.tabs} tabPosition="left" defaultActiveKey="GeneralSettings" items={items} />
        </div>
    );
};

export default ObjectTypeEditorComponent;
