import * as React from 'react';
import { Form } from 'antd';
import messages from './ServerForm.messages';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ServerSelect } from './ServerSelect.component';
import { UserEdit } from './UserEdit.component';
import { TServerEntity } from '../../../models/entities.types';
import { findUserInfo as _findUserInfo } from './ServerFormUtils';
import theme from './theme/ServerSelect.component.scss';
import { TUserConnectInfo, TServerFormProps, TServerFormState, TServerForm } from '../ServerForm.types';
import electron from '../../../electron';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

class ServerForm extends React.Component<TServerFormProps, TServerFormState> {
    constructor(props: TServerFormProps) {
        super(props);
        this.state = {
            ...this.findUserInfo(),
            isTabPressed: false,
        };
    }
    private submitButtonRef = React.createRef<HTMLButtonElement>();

    componentDidUpdate(prevProps: TServerFormProps) {
        const pServ = prevProps.availableServers;
        const currServ = this.props.availableServers;
        if (pServ && currServ && pServ.length >= 0 && pServ.length >= 0) {
            // если в списке серверов добавился сервер, значит надо его выделить
            if (currServ.length > pServ.length) {
                const id: TServerEntity | undefined = currServ.find((c) => !pServ.find((p) => c.id === p.id)); // поиск нового сервера
                this.setState(this.findUserInfo(id && id.id));
            }
            // если в списке серверов удалился сервер, значит надо проверить а не был ли он выделен, если был то выделяем другой
            if (currServ.length < pServ.length) {
                const id = this.state && this.state.selectedServerKey;
                if (!currServ.find((c) => c.id === id)) {
                    this.setState(this.findUserInfo(''));
                }
            }
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleTabPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleTabPress);
    }

    handleTabPress = (event: KeyboardEvent) => {
        if (event.key === 'Tab' && !this.state.isTabPressed) {
            this.setState({ isTabPressed: true }, () => {
                const button: HTMLButtonElement | null = this.submitButtonRef.current;
                if (button) {
  
                    button.classList.add(theme.showFocus);
                }
            });
        }
    };

    onLogin = () => {
        const {
            userInfo: { username, password, remember },
            selectedServerKey,
        } = this.state;

        const { setErrorMessage } = this.props;

        const servers = (this.props.availableServers || []).find((s) => s.id === selectedServerKey);
        if (!servers) {
            setErrorMessage(messages.selectedServerRequired);

            return;
        }
        if (!username || !password || !this.props.onLogin) {
            setErrorMessage(messages.userDataRequired);

            return;
        }
        const { id, url, name } = servers;
        this.props.onLogin({
            id,
            url,
            serverName: name,
            username,
            password,
            remember,
        } as TServerForm);

        if (electron) {
            history.pushState(null, 'Current server url', url);
        }
    };

    findUserInfo = (id?: string): TServerFormState => {
        return _findUserInfo(this.state, this.props, id);
    };

    changeUserInfo = (info: TUserConnectInfo) => {
        this.setState({ userInfo: info });
    };

    render() {
        const { intl, children, availableServers = [], onClickServerEdit, onClickServerAdd } = this.props;
        const { userInfo, selectedServerKey } = this.state;

        const availableServersSort: TServerEntity[] = (availableServers || []).sort(
            (a, b) => (b.lastLoginTimestamp || 0) - (a.lastLoginTimestamp || 0),
        );
        const handleServerSelect = (id: string) => this.setState(this.findUserInfo(id));

        return (
            <div>
                <Form autoComplete='off' layout="vertical" hideRequiredMark onFinish={this.onLogin}>
                    <ServerSelect
                        intl={intl}
                        theme={theme}
                        onChange={handleServerSelect}
                        availableServers={availableServersSort}
                        onServerEdit={onClickServerEdit}
                        onServerAdd={onClickServerAdd}
                        selectedServerKey={selectedServerKey || ''}
                    />
                    <UserEdit value={userInfo} onChange={this.changeUserInfo} />
                    {children}
                    <Form.Item className={theme.submit}>
                        <Button
                            dataTest="login-page_login-button"
                            visualStyle="primary"
                            type="submit"
                            autoFocus
                            ref={this.submitButtonRef}
                            width={285}
                        >
                            <FormattedMessage {...messages.login} />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const IntlComponent = injectIntl(ServerForm);

export { IntlComponent as ServerForm };
