import React, { useState } from 'react';
import theme from './NewCommentArea.scss';
import { CommentCustomTextArea } from '../CommentCustomTextArea/CommentCustomTextArea.component';
import { Comment, NodeId } from '../../../../serverapi/api';
import editCommentIcon from '../../../../resources/icons/editCommentIcon.svg';
import { Icon } from '../../../UIKit';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import messages from '../../messages/CommentsPanel.messages';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import { deleteEditingComment } from '../../../../actions/comments.actions';
import { LocalesService } from '../../../../services/LocalesService';
import cx from 'classnames';

type TNewCommentAreaProps = {
    modelId: NodeId;
    comment?: Comment;
    onlyFullSizeMode?: boolean;
    isAnswer?: boolean;
    onFinishEditing?: () => void;
    onChangeVisible?: () => void;
    showInTooltip?: boolean;
    popupStyles?: CSSStyleDeclaration;
};

export const NewCommentArea = (props: TNewCommentAreaProps) => {
    const { modelId, comment, onlyFullSizeMode, isAnswer, onChangeVisible, showInTooltip, popupStyles } = props;
    const isEditingComment: boolean = !!useSelector(CommentsSelectors.getEditingComment(modelId));
    
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const dispatch = useDispatch();
    const intl = LocalesService.useIntl();

    let parentId = comment?.commentId.id;
    let threadId: string | undefined;
    let commentId: string | undefined;

    if (!isAnswer) {
        parentId = comment?.parentId || comment?.threadId || comment?.commentId.id;
        threadId = comment?.threadId;
        commentId = comment?.commentId.id;
    }

    const openComentEditDialog = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        dispatch(
            openDialog(DialogType.WRITE_COMMENT_DIALOG, {
                modelId,
                parentId,
                commentId,
                threadId,
            }),
        );
        setIsEditMode(false);
    };
  
    const onCancel = () => {
        if (onChangeVisible) onChangeVisible();
        dispatch(deleteEditingComment(modelId));
        setIsEditMode(false);
    };

    const changeEditMode = () => {
        if (isEditingComment) return;

        setIsEditMode(true);
    };

    const onFinishEditing = () => {
        setIsEditMode(false);
        if (props.onFinishEditing) props.onFinishEditing();
        if (onChangeVisible) onChangeVisible();
    };

    const onEscape = () => {
        onFinishEditing();
        dispatch(deleteEditingComment(modelId));
    };

    if (!isEditMode && !onlyFullSizeMode)
        return !isEditingComment ? (
            <div
                data-test="open-comment-box"
                className={cx(theme.emptyHeader, { [theme.showInTooltip]: !!showInTooltip })}
                onClick={changeEditMode}
            >
                <span className={theme.emptyHeaderTitle}>
                    {!showInTooltip
                        ? intl.formatMessage(messages.addCommentTitle)
                        : intl.formatMessage(messages.answerOrMention)}
                </span>
                <Icon
                    dataTest="open-comment_box-icon"
                    spriteSymbol={editCommentIcon}
                    className={theme.hederIcon}
                    onClick={(e?: React.MouseEvent) => {
                        openComentEditDialog(e);
                    }}
                />
            </div>
        ) : (
            <></>
        );

    return (
        <div
            className={cx(
                { [theme.showInTooltipHead]: !!showInTooltip && !!onlyFullSizeMode },
                { [theme.showInTooltipButtom]: !!showInTooltip && !onlyFullSizeMode },
            )}
        >
            <CommentCustomTextArea
                withHeader
                onChangeVisible={onChangeVisible}
                openComentEditDialog={openComentEditDialog}
                modelId={modelId}
                parentId={parentId}
                commentId={commentId}
                isMinSize={isAnswer}
                threadId={threadId}
                onEscape={onEscape}
                onFinishEditing={onFinishEditing}
                onCancel={onCancel}
                showInTooltip={!!showInTooltip}
                popupStyles={popupStyles}
            />
        </div>
    );
};
