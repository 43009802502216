import type { TCommentAttributes } from '../../extensions/comment.types';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { CommentTooltipClass } from './CommentTooltip.class';

type TCommentTooltipPluginProps = {
    delay: number;
    commentMarkName: string;
    tooltipContainerName: string;
    commentTooltipComponent: React.ElementType;
};

export const CommentTooltipPlugin = ({
    delay,
    commentMarkName,
    tooltipContainerName,
    commentTooltipComponent,
}: TCommentTooltipPluginProps) => {
    const tooltipInst = new CommentTooltipClass(delay, tooltipContainerName, commentTooltipComponent);
    const pluginKey = new PluginKey('commentTooltip');
    const plugin: Plugin = new Plugin({
        key: pluginKey,

        view() {
            return {
                destroy: () => {
                    // TODO проверить удаление элементов при смене вкладок
                    tooltipInst.destroy();
                },
            };
        },

        props: {
            handleDOMEvents: {
                mouseover(view, event) {
                    const target = event.target as HTMLElement;
                    const pos = view.posAtDOM(target, 0);
                    const node = view.state.doc.nodeAt(pos);
                    const commentMark = node?.marks?.find((mark) => mark.type.name === commentMarkName);
                    const attrs = commentMark ? (commentMark?.attrs as TCommentAttributes) : null;

                    tooltipInst.mouseMove(attrs, event.x, event.y, target);
                },
                mouseup(view, event) {
                    tooltipInst.mouseUp(event.x, event.y);
                },
            },
        },
    });

    return plugin;
};
