import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { KanbanBoardType } from '../serverapi/api';
import { INITIAL_KANBAN_MODEL_TYPE_SERVER_STATE } from '../reducers/kanbanModelType.reducer';
import { IKanbanModelTypeData } from '../reducers/kanbanModelType.reducer.types';

export const EMPTY_PRESET_ID = 'empty';

const kanbanModelTypeStateSelector = (state: TRootState) => state.kanbanModelType;

const toKanbanModelTypeArray = (srcData: IKanbanModelTypeData): KanbanBoardType[] => {
    return Object.keys(srcData.byId).map((k) => srcData.byId[k]);
};

export namespace KanbanModelTypeSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector(kanbanModelTypeStateSelector, (state) => {
            const serverModelTypeState = state[presetId];
            if (serverModelTypeState) {
                return { ...serverModelTypeState };
            }

            return { ...INITIAL_KANBAN_MODEL_TYPE_SERVER_STATE };
        });

    export const byPresetIdArr = (presetId: string) =>
        createSelector(kanbanModelTypeStateSelector, (state) => {
            const serverModelTypeState = state[presetId];
            if (serverModelTypeState) {
                return Object.values(serverModelTypeState.byId);
            }

            return [];
        });

    export const isLoad = (presetId: string) =>
        createSelector(kanbanModelTypeStateSelector, (state) => !!state[presetId]);

    export const getAllKanbanModelTypes = (presetId: string) =>
        createSelector(byPresetId(presetId), (state) => toKanbanModelTypeArray(state));

    export const byId = (modelTypeId: string, presetId: string) =>
        createSelector(byPresetId(presetId), (state) => {
            return state.byId[modelTypeId];
        });
}
