import { sortCommentFilesByName } from '@/modules/Comments/utils/commentsUtils';
import { Comment, CommentFileDTO, NodeId } from '../serverapi/api';
import { CustomMap } from '../utils/map';
import { compareNodeIds } from '../utils/nodeId.utils';
import { ICommentsState, TComment, TEditingCommentFiles } from './comments.reducer.types';
import { v4 as uuid } from 'uuid';

export class CommentState implements ICommentsState {
    byModelId = new CustomMap<NodeId, Comment[]>();
    editingComments = new CustomMap<NodeId, TComment>();
    commentsEnabledSchemesIds: NodeId[] = [];

    public clone(): CommentState {
        const clone = new CommentState();
        clone.byModelId = this.byModelId.clone();
        clone.commentsEnabledSchemesIds = [...this.commentsEnabledSchemesIds];
        clone.editingComments = this.editingComments.clone();

        return clone;
    }

    public add(modelId: NodeId, comments: Comment[]): CommentState {
        const stateCopy = this.clone();
        const currentComments = stateCopy.byModelId.get(modelId);
        stateCopy.byModelId.set(modelId, [...(currentComments || []), ...comments]);

        return stateCopy;
    }

    public pin(modelId: NodeId, comment: Comment): CommentState {
        const stateCopy = this.clone();

        return stateCopy.delete(modelId, comment.commentId).add(modelId, [{ ...comment, pinDate: Date.now() }]);
    }

    public unpin(modelId: NodeId, comment: Comment): CommentState {
        const stateCopy = this.clone();
        const currentComments: Comment[] = stateCopy.byModelId.get(modelId) || [];
        const restComments: Comment[] = currentComments.filter((c) => c.commentId.id !== comment.commentId.id);
        delete comment?.pinDate;

        stateCopy.byModelId.set(modelId, [...restComments, comment]);

        return stateCopy;
    }

    public upload(comment: Comment, files: CommentFileDTO[]): CommentState {
        const stateCopy = this.clone();

        const modelId = { ...comment.commentId, id: comment.nodeId };

        return stateCopy
            .delete(modelId, comment.commentId)
            .add(modelId, [{ ...comment, commentFiles: [...(comment?.commentFiles || []), ...files] }]);
    }

    public addOpenPanel(modelId: NodeId): CommentState {
        const stateCopy = this.clone();
        stateCopy.commentsEnabledSchemesIds = [...stateCopy.commentsEnabledSchemesIds, modelId];

        return stateCopy;
    }

    public removeOpenPanel(modelId: NodeId): CommentState {
        const stateCopy = this.clone();
        stateCopy.commentsEnabledSchemesIds = [
            ...stateCopy.commentsEnabledSchemesIds.filter((id) => !compareNodeIds(id, modelId)),
        ];

        return stateCopy;
    }

    public delete(modelId: NodeId, commentId: NodeId): CommentState {
        const stateCopy = this.clone();
        const comments = stateCopy.byModelId.get(modelId);
        stateCopy.byModelId.set(modelId, [...(comments || []).filter((c) => !compareNodeIds(commentId, c.commentId))]);

        return stateCopy;
    }

    public deleteByModelId(modelId: NodeId): CommentState {
        const stateCopy = this.clone();
        stateCopy.byModelId.delete(modelId);

        return stateCopy;
    }

    public editComment(modelId: NodeId, comment: Comment): CommentState {
        const stateCopy = this.clone();

        return stateCopy.delete(modelId, comment.commentId).add(modelId, [comment]);
    }

    public createEditingComment(
        modelId: NodeId,
        parentId?: string,
        commentId?: string,
        threadId?: string,
        showInTooltip?: boolean,
        popupStyles?: CSSStyleDeclaration,
    ): CommentState {
        if (this.editingComments.get(modelId)) return this;

        const stateCopy = this.clone();
        const comment: Comment | undefined = this.byModelId.get(modelId)?.find((cur) => cur.commentId.id === commentId);

        stateCopy.editingComments.set(modelId, {
            parentId: parentId || comment?.parentId,
            threadId: threadId || comment?.threadId,
            commentId: commentId || uuid(),
            text: stateCopy.editingComments[modelId.id]?.text
                ? stateCopy.editingComments[modelId.id].text
                : comment?.text || '',
            files: {
                commentFiles: sortCommentFilesByName(comment?.commentFiles || []),
            },
            tooltipRenderedOnGraphProps: {
                styles: {
                    left: popupStyles?.left,
                    top: popupStyles?.top,
                },
                openForEditing: showInTooltip,
            },
        });

        return stateCopy;
    }

    public changeEditingComment(
        modelId: NodeId,
        text?: string,
        files?: TEditingCommentFiles,
        threadId?: string,
    ): CommentState {
        const stateCopy = this.clone();
        stateCopy.editingComments.set(modelId, {
            ...stateCopy.editingComments.get(modelId),
            text: text ?? stateCopy.editingComments.get(modelId)?.text ?? '',
            files: files || stateCopy.editingComments.get(modelId)?.files || {},
            threadId,
        });

        return stateCopy;
    }

    public deleteEditingComment(id: NodeId): CommentState {
        const stateCopy = this.clone();
        stateCopy.editingComments.delete(id);

        return stateCopy;
    }

    public deleteFile(fileId: string, commentId: NodeId, modelId: NodeId): CommentState {
        const stateCopy = this.clone();
        const currentComments: Comment[] = stateCopy.byModelId.get(modelId) || [];
        const editingComment: Comment | undefined = currentComments?.find((cur) => cur.commentId.id === commentId.id);

        if (editingComment) {
            editingComment.commentFiles = editingComment.commentFiles?.filter((cf) => cf.id !== fileId);
        }

        return stateCopy;
    }

    public changeIsGraphElement(commentId: NodeId, modelId: NodeId, isGraphElement: boolean): CommentState {
        const stateCopy = this.clone();
        const currentComments: Comment[] = stateCopy.byModelId.get(modelId) || [];
        const editingComment: Comment | undefined = currentComments?.find((cur) => cur.commentId.id === commentId.id);
        if (editingComment) editingComment.isGraphElement = isGraphElement;

        return stateCopy;
    }
}
