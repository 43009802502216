import { uniqWith, includes, isEqual } from 'lodash-es';
import { ObjectDefinitionImpl } from '../../models/bpm/bpm-model-impl';
import { IWorkspaceTabItemModelParams, TWorkspaceTab } from '../../models/tab.types';
import { TreeNode } from '../../models/tree.types';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { getGraphByServerAndRepository } from '../../mxgraph/bpm-mxgraph-instance-map';
import { BPMMxGraph } from '../../mxgraph/bpmgraph';
import { MxCell } from '../../mxgraph/mxgraph';
import {
    DiagramElement,
    EdgeInstance,
    NodeId,
    ObjectDefinitionNode,
    ObjectInstance,
    ShapeInstance,
    ModelLite,
    EdgeDefinitionNode,
    ParentModelOfEdgeDefinition,
} from '../../serverapi/api';
import { hasNodeTypeChild } from '../consts/TreeConsts';
import { nodeService } from '../NodeService';
import { CustomMap } from '@/utils/map';

export class DeleteNodeRequest {
    nodeId: NodeId;
    parentNodeId?: NodeId; // необходимо для поиска root parent при обновлении графа
    nodeType: TreeItemType;
    nodeName: string;
    countChildren: number; // используется для сообщения что при удалении узла будут удалены дочерние узлы
    closeTabsNames: { tabId: NodeId; name: string }[]; // используется для сообщения что при удалении узла будут закрыты вкладки
    closeTabsIds: NodeId[];
    objectsMap: { [key: string]: ObjectDefinitionImpl }; // объекты у которых необходимо проверить наличие декомпозиции
    edgesMap: { [key: string]: EdgeDefinitionNode }; // связи у которых необходимо проверить наличие декомпозиции
    modelsWhereObjectToDelete?: ModelLite[];
    edgeInstancesToDelete?: ParentModelOfEdgeDefinition[];
}

export class DeleteNodeDescriptor {
    graphId: NodeId;
    deletableCells: MxCell[] = [];
    changedDecompositionCells: MxCell[] = [];
    changedObjectsDefinitions: ObjectDefinitionImpl[] = [];
    changedEdgesDefinitions: EdgeDefinitionNode[] = [];
}

/**
 *
 * @param objectDefinition - объект в котором необходимо убрать лишние декопозиции
 * @param nodeId - удаляемый элемент (его декомпозиции надо очистить)

 * @returns
 */
export function deleteDefinitionDecomposition(
    definition: ObjectDefinitionImpl | EdgeDefinitionNode | undefined,
    nodeId: NodeId | undefined,
): ObjectDefinitionImpl | EdgeDefinitionNode | undefined {
    if (!definition) {
        return undefined;
    }

    const modelAssignments = definition?.modelAssignments || [];
    const newModelAssignments = modelAssignments.filter((ma) => ma.modelId !== nodeId?.id);

    if (modelAssignments.length !== newModelAssignments.length) {
        return { ...definition, modelAssignments: newModelAssignments };
    }

    return undefined;
}

export function deleteFromGraph(graph: BPMMxGraph, deleteRequest: DeleteNodeRequest): DeleteNodeDescriptor {
    const descriptor = new DeleteNodeDescriptor();
    descriptor.graphId = graph.id;

    const {
        nodeId,
        nodeId: { id },
        objectsMap,
        edgesMap,
    } = deleteRequest;
    const cells = Object.values<MxCell>(graph.getModel().cells);

    cells
        .filter((c) => c?.value)
        .forEach((cell) => {
            // eslint-disable-next-line prefer-destructuring
            const value: DiagramElement = cell.value;

            if (value.type === 'object') {
                const objectDefinitionId = (value as ObjectInstance)?.objectDefinitionId || '';
                // при удалении объекта удяляем его с холста
                if (objectDefinitionId === id) {
                    descriptor.deletableCells.push(cell);
                } else {
                    // удяляем декомпозиции объекта
                    const objectDefinitionWithDecomposition = objectsMap[objectDefinitionId];
                    const objectDefinition = deleteDefinitionDecomposition(
                        objectDefinitionWithDecomposition,
                        nodeId,
                    ) as ObjectDefinitionImpl | undefined;

                    if (objectDefinition) {
                        descriptor.changedObjectsDefinitions.push(objectDefinition);
                        descriptor.changedDecompositionCells.push(cell);
                    }
                }
            }

            // удаляем изображение с холста
            if (value.type === 'shape' && (value as ShapeInstance).imageId === id) {
                descriptor.deletableCells.push(cell);
            }

            if (value.type === 'edge') {
                const edgeDefinitionId = (value as EdgeInstance)?.edgeDefinitionId;
                // при удалении связи удяляем ее с холста
                if (edgeDefinitionId === id) {
                    descriptor.deletableCells.push(cell);
                } else if (edgeDefinitionId) {
                    // удаляем декомпозиции связи
                    const edgeDefinitionWithDecomposition = edgesMap[edgeDefinitionId];
                    const edgeDefinition = deleteDefinitionDecomposition(edgeDefinitionWithDecomposition, nodeId) as
                        | EdgeDefinitionNode
                        | undefined;

                    if (edgeDefinition) {
                        descriptor.changedEdgesDefinitions.push(edgeDefinition);
                        descriptor.changedDecompositionCells.push(cell);
                    }
                }
            }
        });

    return descriptor;
}

export function deleteNode(deleteRequest: DeleteNodeRequest): DeleteNodeDescriptor[] {
    const {
        nodeId: { serverId, repositoryId },
    } = deleteRequest;

    return getGraphByServerAndRepository(serverId, repositoryId).map((graph) => deleteFromGraph(graph, deleteRequest));
}

export async function findTabsIdsToBeClosed(
    node: TreeNode,
    tabs: TWorkspaceTab[],
    findAllParentElementId: (nodeId: NodeId[]) => Promise<{ [key: string]: NodeId[] }>,
): Promise<NodeId[]> {
    const { nodeId, type } = node;

    if (type === TreeItemType.Server) {
        return Promise.resolve([]);
    }

    const tabsId = tabs
        .map((tab) => tab.nodeId)
        .filter((tabId) => tabId.repositoryId === nodeId.repositoryId && tabId.serverId === nodeId.serverId);

    // если у удаляемого узла не может быть детей
    if (!hasNodeTypeChild(type)) {
        // возвращаем ИД вкладки с данным узлом, если она открыта
        return Promise.resolve(tabsId.filter((tabId) => tabId.id === nodeId.id));
    }

    // ищем родительские узлы для открытых вкладок
    const childMap = await findAllParentElementId(tabsId);

    // возвращаем ИД вкладок если у родительского узла вкладки есть удаляемый узел
    // или удаляемый узел среди открытых вкладок
    const tabsForDelete = tabsId.filter(
        (tabId) => tabId.id === nodeId.id || childMap[tabId.id]?.find((id) => nodeId.id === id.id),
    );

    return Promise.resolve(tabsForDelete);
}

export function getModelsWithUnSavedElementsNodeIds(nodeId: NodeId): NodeId[] {
    const cellIds: CustomMap<NodeId, string[]> = nodeService().findCellIdsByNodeId(nodeId);

    return Array.from(cellIds.keys());
}

export function getUnsavedModelsWithEdge(
    tabs: TWorkspaceTab[],
    nodeIdsWithUnsavedElements: NodeId[],
    cellIds: CustomMap<NodeId, string[]>,
): ParentModelOfEdgeDefinition[] {
    return tabs
        .filter((tab: TWorkspaceTab) => nodeIdsWithUnsavedElements.some((nId: NodeId) => isEqual(nId, tab.nodeId)))
        .map((tabWithObject: TWorkspaceTab) => {
            const entryCount = cellIds.get(tabWithObject.nodeId)?.length ?? 0;
            const modelName = tabWithObject.title;
            const modelId = tabWithObject.nodeId.id;
            const modelTypeId = (tabWithObject?.params as IWorkspaceTabItemModelParams | undefined)?.modelType?.id;

            return { entryCount, modelName, modelId, modelTypeId };
        });
}

export function getUnsavedModelsIdsWithObject(tabs: TWorkspaceTab[], nodeId: NodeId): ModelLite[] {
    const modelsWithUnSavedObjectNodeIds = getModelsWithUnSavedElementsNodeIds(nodeId);

    return tabs
        .filter((tab) => includes(modelsWithUnSavedObjectNodeIds, tab.nodeId))
        .map((tabWithObject) => ({
            modelName: tabWithObject.title,
            modelId: tabWithObject.nodeId.id,
            modelTypeId: (tabWithObject?.params as IWorkspaceTabItemModelParams | undefined)?.modelType?.id,
        }));
}

export function getModelsLiteOfDeletingObject(nodeObject: ObjectDefinitionNode, tabs: TWorkspaceTab[]): ModelLite[] {
    if (!nodeObject?.objectEntries) return [];

    return uniqWith(
        [...nodeObject.objectEntries, ...getUnsavedModelsIdsWithObject(tabs, nodeObject.nodeId)],
        (a, b) => a.modelId === b.modelId,
    );
}

export function getModelsLiteOfDeletingEdge(
    nodeEdge: EdgeDefinitionNode,
    tabs: TWorkspaceTab[],
): ParentModelOfEdgeDefinition[] {
    if (!nodeEdge?.edgeEntries) return [];
    const modelsWithUnSavedEdgeNodeIds: NodeId[] = getModelsWithUnSavedElementsNodeIds(nodeEdge.nodeId);
    const cellIds: CustomMap<NodeId, string[]> = nodeService().findCellIdsByNodeId(nodeEdge.nodeId);
    const unsavedModelsWithEdge: ParentModelOfEdgeDefinition[] = getUnsavedModelsWithEdge(
        tabs,
        modelsWithUnSavedEdgeNodeIds,
        cellIds,
    );
    const updatedEdgeEntries = nodeEdge.edgeEntries.filter((entry) => {
        return !tabs.some(
            (tab) => tab.nodeId.id === entry.modelId && tab.nodeId.repositoryId === nodeEdge.nodeId.repositoryId,
        );
    });

    return [...unsavedModelsWithEdge, ...updatedEdgeEntries];
}
