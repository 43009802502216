import React, { useEffect, useRef, useState } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { getPaperSize } from '../../../utils/print.utils';
import { LengthUnit, TPrintOptions, ZoomMode } from '../../../utils/types';
import { deleteEmptySheets, getPageCount } from './PrintDialog.utils';
import messages from './PrintDialog.messages';
import theme from './PrintPreview.component.scss';
import { TModelOffset } from '../../../sagas/image.saga.types';

type TPrintPreviewProps = {
    showOffsets: boolean;
    canvas: HTMLCanvasElement;
    offset: TModelOffset;
    form: React.ReactNode;
    options: TPrintOptions;
    imageData: string;
    onClose: () => void;
    onSubmit: () => void;
} & JSX.IntrinsicAttributes;

type TPrintPreviewAllProps = TPrintPreviewProps & WrappedComponentProps;

type TPagePreviewProps = {
    grayscale: boolean;
    showOffsets: boolean;
    imageData: string;
    position: number[];
    paperSize: number[];
    offset: TModelOffset;
};

export const PagePreview = (props: TPagePreviewProps): JSX.Element => {
    const { grayscale, imageData, position, paperSize, offset, showOffsets } = props;

    const [row, column] = position;
    const [paperWidth, paperHeight] = paperSize;
    const { offsetTopWithoutEmptySheet, offsetLeftWithoutEmptySheet } = deleteEmptySheets(
        offset,
        paperHeight,
        paperWidth,
    );

    const verticalOffset = row ? `-${row * paperHeight - offsetTopWithoutEmptySheet}` : offsetTopWithoutEmptySheet;
    const horizontalOffset = column
        ? `-${column * paperWidth - offsetLeftWithoutEmptySheet}`
        : offsetLeftWithoutEmptySheet;

    return (
        <div
            data-test="single_sheet_locator"
            className={theme.page}
            style={{
                width: `${paperWidth}px`,
                height: `${paperHeight}px`,
            }}
        >
            <div
                className={theme.image}
                style={{
                    backgroundImage: `url(${imageData})`,
                    backgroundPosition: `${horizontalOffset}px ${verticalOffset}px`,
                    filter: grayscale ? 'grayscale(100%)' : 'none',
                    transform: `${showOffsets ? 'scale(0.96, 0.98)' : 'unset'}`,
                }}
            />
        </div>
    );
};

const PrintPreview = (props: TPrintPreviewAllProps): JSX.Element => {
    const { canvas, intl, form, options, offset, imageData, showOffsets, onClose, onSubmit } = props;
    const { orientation, paperFormat, grayscale, zoomMode } = options;
    const [paperWidth, paperHeight] = getPaperSize(paperFormat, orientation, LengthUnit.PX);
    const [pagesAcross, pagesDown] = getPageCount([canvas.width, canvas.height], options, offset);
    const printWidth = paperWidth * pagesAcross;
    const printHeight = paperHeight * pagesDown;

    const [scale, setScale] = useState<number>(1);
    const ref = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (zoomMode === ZoomMode.FIT) {
            const paperWidth = printWidth + pagesAcross * 5;
            const paperHeight = printHeight + pagesDown * 5;
            const viewWidth = ref.current ? ref.current.offsetWidth : paperWidth;
            const viewHeight = ref.current ? ref.current.offsetHeight : paperHeight;
            const scale = Math.min(viewWidth / paperWidth, viewHeight / paperHeight);
            setScale(scale);
        }
    }, [ref, printWidth, printHeight, zoomMode]);

    const renderPages = (): JSX.Element[] => {
        const pages: JSX.Element[] = [];

        for (let row = 0; row < pagesDown; row++) {
            for (let column = 0; column < pagesAcross; column++) {
                const page = (
                    <PagePreview
                        key={`${row}-${column}`}
                        grayscale={grayscale}
                        imageData={imageData}
                        paperSize={[paperWidth, paperHeight]}
                        position={[row, column]}
                        offset={offset}
                        showOffsets={showOffsets}
                    />
                );

                pages.push(page);
            }
        }

        return pages;
    };

    const styleForZoomMode: React.CSSProperties = {
        transform: `translate(-50%, -50%) scale(${scale})`,
        position: 'absolute',
        left: '50%',
        top: '50%',
    };

    return (
        <div data-test="model_preview_page" className={theme.container}>
            <div
                data-test="model_preview_window"
                ref={ref}
                className={theme.preview}
                style={{ overflow: `${zoomMode === ZoomMode.FIT ? 'hidden' : 'scroll'}` }}
            >
                <div
                    className={pagesAcross > 1 ? theme.pages : theme.singlePage}
                    style={
                        zoomMode === ZoomMode.FIT
                            ? {
                                width: `${printWidth + pagesAcross * 5}px`,
                                height: `${printHeight + pagesDown * 5}px`,
                                ...styleForZoomMode,
                            }
                            : { width: `${printWidth + pagesAcross * 5}px`, height: `${printHeight + pagesDown * 5}px` }
                    }
                >
                    {renderPages()}
                </div>
            </div>

            <div className={theme.settings}>
                <div className={theme.panel}>
                    {form}
                    <div className={theme.footer}>
                        <Button dataTest="print_cancel_button" key="cancel" size="large" onClick={onClose}>
                            {intl.formatMessage(messages.cancel)}
                        </Button>
                        <Button dataTest="button_print" key="ok" size="large" visualStyle="primary" onClick={onSubmit}>
                            {intl.formatMessage(messages.print)}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PrintPreviewWithIntl = injectIntl(PrintPreview);

export { PrintPreviewWithIntl as PrintPreview };
