import React from 'react';
import { Alert, Form } from 'antd';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../UIKit/components/Dialog/Dialog.types';
import messages from './ServerForm.messages';
import theme from './theme/ServerEditDialog.scss';
import { UserEdit } from './UserEdit.component';
import { TServerAuthDialogProps, TUserConnectInfo } from '../ServerForm.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

class ServerAuthDialog extends React.Component<TServerAuthDialogProps, TUserConnectInfo> {
    constructor(props: TServerAuthDialogProps) {
        super(props);
        this.state = { ...props.value };
    }

    render() {
        const { intl, onSubmit } = this.props;
        const dialogProps: Partial<TDialogProps> = {
            open: true,
            title: intl.formatMessage(messages.userHeader),
            closable: true,
            zIndex: 1001,
            maskClosable: true,
            onCancel: this.props.onCancel,
        };

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: this.props.onCancel,
                        value: intl.formatMessage(messages.cancel),
                        disabled: this.props.pending,
                    },
                    {
                        key: 'ok',
                        onClick: () => onSubmit(this.state, this.props.server),
                        value: intl.formatMessage(messages.connect),
                        visualStyle: 'primary',
                        disabled: !(this.state.password && this.state.username) || this.props.pending,
                    },
                ]}
            />
        );

        return (
            <Dialog {...dialogProps} footer={footer}>
                {this.props.error && <Alert className={theme.alert} type="error" message={this.props.error} showIcon />}
                <Form layout="vertical" hideRequiredMark>
                    <UserEdit
                        value={this.state}
                        onChange={(info) => this.setState(info)}
                        disabled={this.props.pending}
                    />
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ServerAuthDialog);

export { IntlComponent as ServerAuthDialog };
