import { defineMessages } from 'react-intl';

export default defineMessages({
    useExistingId: {
        id: 'ImportVisiotable.select.strategy.useExistingId',
        defaultMessage: 'Использовать существующий',
    },
    askUser: {
        id: 'ImportVisiotable.select.strategy.askUser',
        defaultMessage: 'Спрашивать пользователя',
    },
    fileNameTitle: {
        id: 'ImportVisiotable.select.strategy.fileNameTitle',
        defaultMessage: 'Имя файла',
    },
    findMostCommon: {
        id: 'ImportVisiotable.select.strategy.findMostCommon',
        defaultMessage: 'Выбирать определения с наибольшим количеством экземпляров',
    },
    findOldest: {
        id: 'ImportVisiotable.select.strategy.findOldest',
        defaultMessage: 'Выбирать наиболее старые определения',
    },
    createNew: {
        id: 'ImportVisiotable.select.strategy.createNew',
        defaultMessage: 'Создавать новые определения (не рекомендуется)',
    },
    strategyLabel: {
        id: 'ImportVisiotable.select.strategy.label',
        defaultMessage: 'Обработка объектов с одинаковыми именами',
    },
    buttonImportText: {
        id: 'ImportVisiotable.button.importText',
        defaultMessage: ' Начать импорт',
    },
});
