/* tslint:disable */

import {  MxGraph, Editor } from '../mxgraph';
import { SidebarGraph } from './SidebarGraph';

export class BPMEditorUI {
    editor: Editor;
    container: any;
    graph: MxGraph;
    onClose: any;
    onCollapse: any;

    constructor(graph: MxGraph, editor: Editor, container: any) {
        this.editor = editor;
        this.container = container;
        this.graph = graph;
    }

    /**
     * Required by drawio
     * @param stylesheet
     */
    createTemporaryGraph(stylesheet: any): MxGraph {
        const sidebarGraph = new SidebarGraph(document.createElement('div'), undefined, undefined, stylesheet);

        this.graph.destroy = () => {
            sidebarGraph.destroy();
            // this.onClose();
            // this.onCollapse();
            this.container.parentNode!.removeChild(this.container);
        };
        sidebarGraph.resetViewOnRootChange = false;
        sidebarGraph.setConnectable(false);
        sidebarGraph.gridEnabled = false;
        sidebarGraph.autoScroll = false;
        sidebarGraph.setTooltips(false);
        sidebarGraph.setEnabled(false);

        // Container must be in the DOM for correct HTML rendering
        sidebarGraph.container.style.visibility = 'hidden';
        sidebarGraph.container.style.position = 'absolute';
        sidebarGraph.container.style.overflow = 'hidden';
        sidebarGraph.container.style.height = '1px';
        sidebarGraph.container.style.width = '1px';

        return sidebarGraph;
    }

    insertHandler(cells, asText, model, vertexStyle, edgeStyle, applyAll, recurse) {
    }
    /**
     * Required by drawio
     */
    isOffline() {
        return false;
    }

    /**
     * Required by drawio
     * @param e
     */
    handleError(e: any) {
        console.error(e);
    }
}
