import { VisioObjectTypeMap } from '../../serverapi/api';

export class ObjectMappersAdapter {
    visioMasterId?: string;
    visioMasterName?: string;
    visioShapes?: Array<string>;
    silaTypeId?: string;
    silaSymbolId?: string;
    offeredSilaTypeId?: string;
    offeredSilaSymbolId?: string;

    constructor(item: VisioObjectTypeMap) {
        this.visioMasterId = item.visioMasterId;
        this.visioMasterName = item.visioMasterName;
        this.visioShapes = item.visioShapes;
        this.silaTypeId = item.silaTypeId;
        this.silaSymbolId = item.silaSymbolId;
        this.offeredSilaSymbolId = item.offeredSilaSymbolId;
        this.offeredSilaTypeId = item.offeredSilaTypeId;
    }
}
