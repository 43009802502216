export const SUBMIT_OBJECT_TYPE_GROUP = 'SUBMIT_OBJECT_TYPE_GROUP';

export const ADD_OBJECT_TYPE_GROUPS = 'ADD_OBJECT_TYPE_GROUPS';

export const CREATE_OBJECT_TYPE_GROUP = 'CREATE_OBJECT_TYPE_GROUP';

export const DELETE_OBJECT_TYPE_GROUP_REQUEST = 'DELETE_OBJECT_TYPE_GROUP_REQUEST';

export const DELETE_OBJECT_TYPE_GROUP = 'DELETE_OBJECT_TYPE_GROUP';

export const DELETE_OBJECT_TYPE_GROUP_SUCCESS = 'DELETE_OBJECT_TYPE_GROUP_SUCCESS';

export const EDIT_OBJECT_TYPE_GROUP = 'EDIT_OBJECT_TYPE_GROUP';
