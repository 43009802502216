import { AttributeValue, KanbanCardType, KanbanNode } from '../serverapi/api';

export enum KanbanCardsViewInColumn {
    COLUMN = 'COLUMN',
    ROW = 'ROW',
}

export enum KanbanSizeType {
    ABSOLUTE = 'ABSOLUTE',
    RELATIVE = 'RELATIVE',
}

export enum KanbanBoardPlacingRuleSearchArea {
    REPOSITORY = 'REPOSITORY',
}

export enum KanbanBoardPlacingConditionCondition {
    EQUALS = 'EQUALS',
}

export enum KanbanBoardObjectChangingConditionType {
    OBJECT_IN_COLUMN = 'OBJECT_IN_COLUMN',
    OBJECT_ATTRIBUTE = 'OBJECT_ATTRIBUTE',
}

export enum KanbanBoardObjectChangingConditionCondition {
    EQUALS = 'EQUALS',
}

export enum KanbanObjectChangingActionType {
    CHANGE_OBJECT_ATTRIBUTE = 'CHANGE_OBJECT_ATTRIBUTE',
}

export enum KanbanHorizontalAlign {
    LEFT = 'LEFT',
    CENTER = 'CENTER',
    RIGHT = 'RIGHT',
}

export enum KanbanVerticalAlign {
    TOP = 'TOP',
    CENTER = 'CENTER',
    BOTTOM = 'BOTTOM',
}

export enum KanbanCardSectorAttributeSettingsFormat {
    IMAGE = 'IMAGE',
    TEXT = 'TEXT',
    NONE = 'NONE',
}

export enum KANBAN_COLUMN_ITEM_TYPE {
    SYMBOL = 'SYMBOL',
    KANBAN_CARD_TYPE = 'KANBAN_CARD_TYPE',
}

export type TKanbanStateColumnItem = {
    id: string;
    symbolCardTypeId: string;
    objectDefinitionId: string;
    type: KANBAN_COLUMN_ITEM_TYPE;
    columnId: string;
    allowedColumnIds: string[];
    forbiddenColumnIds: string[];
};

export type TKanbanState = {
    node: KanbanNode;
    columnIdToColumnItemIdsMap: {
        [id: string]: string[];
    };
    columnItems: {
        [id: string]: TKanbanStateColumnItem;
    };
};

export type TKanbanSymbolCardItem = {
    id: string;
    type: KANBAN_COLUMN_ITEM_TYPE;
    objectDefinitionId: string;
    symbolSvg?: string;
    attributes?: AttributeValue[];
    kanbanCardType?: KanbanCardType;
    allowedColumnIds: string[];
    forbiddenColumnIds: string[];
};
