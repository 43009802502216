import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Tree, TreeDataNode } from 'antd';
import { loadTreePart } from '../../../../actions/scriptContext.actions';
import { DownOutlined } from '@ant-design/icons';
import theme from './NodesTree.scss';
import { scriptContextTreeDataSelectors } from '../../../../selectors/scriptContext.selectors';
import { toNodeId, toString } from '../../../../utils/nodeId.utils';
import { AllowedScriptContext, NodeId } from 'src/serverapi/api';

type TNodesTreeProps = {
    showModels: boolean;
    showObjects: boolean;
    scriptContext: AllowedScriptContext;
    setNodesToPermit: (nodeIds: NodeId[]) => void;
};

export const NodesTree = (props: TNodesTreeProps) => {
    const { showModels, showObjects, setNodesToPermit, scriptContext } = props;
    const dispatch = useDispatch();

    const treeData: TreeDataNode[] = useSelector(scriptContextTreeDataSelectors.getNodesTreedata(showModels, showObjects));
    const loadData = (node) => {
        dispatch(loadTreePart(node.nodeId));

        return Promise.resolve();
    };

    const handleCheck = (keys) => {
        setNodesToPermit(
            keys.checked.map((key) => {
                return toNodeId(key);
            }),
        );
    };

    const defaultCheckedKeys = scriptContext.allowedNodeIds?.map((node) => toString(node)) || [];

    return (
        <div className={theme.treeContainer}>
            <Tree
                showIcon
                checkable
                treeData={treeData}
                switcherIcon={<DownOutlined className={theme.arrow} />}
                blockNode
                checkStrictly
                loadData={(node) => loadData(node)}
                onCheck={(keys) => handleCheck(keys)}
                defaultCheckedKeys={defaultCheckedKeys}
                height={560}
            />
        </div>
    );
};
