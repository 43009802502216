// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableHeadControl-component__control__EoDbX{position:absolute;right:5px;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.TableHeadControl-component__arrow__Fjul_ svg:hover{fill:var(--secondColor) !important}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/RichTextEditor/Editor/renderers/Table/TableHeadControl.component.scss"],"names":[],"mappings":"AAAA,4CACI,iBAAA,CACA,SAAA,CACA,OAAA,CACA,kCAAA,CAAA,0BAAA,CAGJ,oDACI,kCAAA","sourcesContent":[".control {\r\n    position: absolute;\r\n    right: 5px;\r\n    top:50%;\r\n    transform: translateY(-50%);\r\n}\r\n\r\n.arrow svg:hover  {\r\n    fill: var(--secondColor) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": "TableHeadControl-component__control__EoDbX",
	"arrow": "TableHeadControl-component__arrow__Fjul_"
};
export default ___CSS_LOADER_EXPORT___;
