const disabledFocusAttributesSelector = ':not([disabled]):not([tabindex="-1"]):not([data-disabled="true"])';
export const focussableElementsSelector = `a${disabledFocusAttributesSelector}, button${disabledFocusAttributesSelector}, input${disabledFocusAttributesSelector}, [tabindex]${disabledFocusAttributesSelector}, [aria-selected="true"]${disabledFocusAttributesSelector}`;

export const getFocusableElements = (node: HTMLElement | null): HTMLElement[] => {
    if (node && document.activeElement) {
        return [...node.querySelectorAll<HTMLElement>(focussableElementsSelector)].filter((element) => {
            //проверка видимости и всегда включение текущего активного элемента
            return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement;
        });
    }

    return [];
};

export const switchFocusElement = (tabPanel: HTMLElement | null, prev = false): HTMLElement | null => {
    //  все элементы, которые мы хотим включить в нашу выборку
    if (tabPanel && document.activeElement) {
        let focussable = getFocusableElements(tabPanel);
        let index = focussable.indexOf(document.activeElement as HTMLElement);

        let nextElement: HTMLElement;
        if (prev) {
            nextElement = focussable[index - 1] || focussable[0];
        } else {
            nextElement = focussable[index + 1] || focussable[focussable.length - 1];
        }

        nextElement?.focus();

        return nextElement;
    }

    return null;
};

export const targetIsTextInput = (tagName: string) => ['TEXTAREA', 'INPUT'].includes(tagName);
