import { Tabs } from 'antd';
import React from 'react';
import { AutomationObjectChanging } from './AutomationObjectChanging.component';
import { AutomationPlacing } from './AutomationPlacing.component';
import { Symbol, KanbanBoardType, KanbanCardType } from '../../../../../../../serverapi/api';
import messages from '../KanbanModelType.messages';
import { useIntl } from 'react-intl';

type TAutomationTabProps = {
    modelType: KanbanBoardType;
    serverId: string;
    presetId: string;
    modelTypeSymbols: Symbol[];
    modelTypeCards: KanbanCardType[];
};

export const AutomationTab = (props: TAutomationTabProps) => {
    const { modelType, serverId, presetId, modelTypeSymbols, modelTypeCards } = props;
    const intl = useIntl();

    return (
        <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={intl.formatMessage(messages.automationBoardPlacing)} key="1">
                <AutomationPlacing
                    modelType={modelType}
                    presetId={presetId}
                    serverId={serverId}
                    modelTypeSymbols={modelTypeSymbols}
                    modelTypeCards={modelTypeCards}
                />
            </Tabs.TabPane>

            <Tabs.TabPane tab={intl.formatMessage(messages.automationObjectChanging)} key="2">
                <AutomationObjectChanging
                    modelType={modelType}
                    presetId={presetId}
                    serverId={serverId}
                    modelTypeSymbols={modelTypeSymbols}
                    modelTypeCards={modelTypeCards}
                />
            </Tabs.TabPane>
        </Tabs>
    );
};
