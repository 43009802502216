import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { ImportPresetDialog} from '../components/ImportDialog/ImportPresetDialog.component';
import { TImportPresetDialogProps } from '../components/ImportDialog/ImportPresetDialog.types';
import { ImportPresetRequestImportPresetResolutionEnum } from '../../../../serverapi/api';
import { TExecutingProcess } from '../../../../reducers/statusBar.reducer.types';
import { clearPresetRequest, importPresetRequest } from '../../../../actions/methodologySetting.actions';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { TreeNode } from '../../../../models/tree.types';

const mapStateToProps = (state: TRootState, props: TImportPresetDialogProps) => {
    return props;
};

const mapDispatchToProps = (dispatch, properties) => ({
    onSubmit: (
        resolutionStrategy: ImportPresetRequestImportPresetResolutionEnum,
        serverNode: TreeNode,
        fileName: string,
        process?: TExecutingProcess,
    ) => {
        const { serverId } = serverNode.nodeId;
        const presetId = properties.conflictingPreset?.id;

        dispatch(closeDialog(DialogType.IMPORT_PRESET_DIALOG));

        if (presetId) dispatch(clearPresetRequest({ serverId, presetId }));

        dispatch(
            importPresetRequest({
                serverNode,
                fileName,
                fileUUID: properties.fileUUID,
                resolveStrategy: resolutionStrategy,
                process,
                type: properties.type,
                preset: properties.conflictingPreset,
            }),
        );
    },
    onCancel: () => dispatch(closeDialog(DialogType.IMPORT_PRESET_DIALOG)),
});

export const ImportPresetDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ImportPresetDialog);
