import type {
    TColumnResizingOptions,
    TPluginAction,
    TSetDraggingActionMeta,
    TSetHandleActionMeta,
    TCellAttrs,
} from './plugins.types';
import { Attrs } from 'prosemirror-model';
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { tableNodeTypes } from './schema';
import { TableMap } from './tablemap';
import { TableView } from './tableview';
import { cellAround, pointsAtCell } from './util';

/**
 * @public
 */
export const columnResizingPluginKey = new PluginKey<ResizeState>('tableColumnResizing');

/**
 * @public
 */
export function columnResizing({
    handleWidth = 5,
    cellMinWidth = 25,
    cellMinHeight = 25,
    View = TableView,
    lastColumnResizable = true,
    className = '',
}: TColumnResizingOptions = {}): Plugin {
    const plugin = new Plugin<ResizeState>({
        key: columnResizingPluginKey,
        state: {
            init(_, state) {
                plugin.spec!.props!.nodeViews![tableNodeTypes(state.schema).table.name] = (node, view) =>
                    new View(node, cellMinWidth, view, className);
                return new ResizeState({ cell: -1, direction: '' }, null);
            },
            apply(tr, prev) {
                return prev.apply(tr);
            },
        },
        props: {
            attributes: (state): Record<string, string> => {
                const pluginState = columnResizingPluginKey.getState(state);

                if (pluginState && pluginState.activeHandle.cell > -1) {
                    return { class: `${pluginState.activeHandle.direction}-resize-cursor` };
                }

                return {};
            },

            handleDOMEvents: {
                mousemove: (view, event) => {
                    handleMouseMove(view, event, handleWidth, cellMinWidth, lastColumnResizable);
                },
                mouseleave: (view) => {
                    handleMouseLeave(view);
                },
                mousedown: (view, event) => {
                    handleMouseDown(view, event, handleWidth, cellMinWidth, cellMinHeight);
                },
            },

            decorations: (editorState) => {
                const pluginState = columnResizingPluginKey.getState(editorState);

                if (
                    pluginState &&
                    pluginState.activeHandle.cell > -1 &&
                    pluginState.activeHandle.direction.length > 0
                ) {
                    return handleDecorations(editorState, pluginState.activeHandle, pluginState.dragging);
                }

                return null;
            },

            nodeViews: {},
        },
    });
    return plugin;
}

/**
 * @public
 */
export class ResizeState {
    constructor(public activeHandle: TSetHandleActionMeta, public dragging: TSetDraggingActionMeta | null) {}

    apply(tr: Transaction): ResizeState {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const state = this;
        const action: TPluginAction = tr.getMeta(columnResizingPluginKey);

        if (action && action.setHandle != null) return new ResizeState(action.setHandle, null);
        if (action && action.setDragging !== undefined) return new ResizeState(state.activeHandle, action.setDragging);
        if (state.activeHandle.cell > -1 && tr.docChanged) {
            let cell = tr.mapping.map(state.activeHandle.cell, -1);

            if (!pointsAtCell(tr.doc.resolve(cell))) {
                cell = -1;
            }

            return new ResizeState({ ...state.activeHandle, cell }, state.dragging);
        }
        return state;
    }
}

type TSide = 'left' | 'right' | 'top' | 'bottom';
type TResizeDirection = 'col' | 'row';

function getResizeDirections(
    cell: HTMLElement,
    event: MouseEvent,
    handleWidth: number,
): { side: TSide | null; resizeDirection: TResizeDirection | null } {
    const { left, right, top, bottom } = cell.getBoundingClientRect();
    let resizeDirection: TResizeDirection | null = null,
        side: TSide | null = null;

    if (event.clientX - left <= handleWidth) {
        side = 'left';
        resizeDirection = 'col';
    } else if (right - event.clientX <= handleWidth) {
        side = 'right';
        resizeDirection = 'col';
    } else if (event.clientY - top <= handleWidth) {
        side = 'top';
        resizeDirection = 'row';
    } else if (bottom - event.clientY <= handleWidth) {
        side = 'bottom';
        resizeDirection = 'row';
    }

    return { side, resizeDirection };
}

function handleMouseMove(
    view: EditorView,
    event: MouseEvent,
    handleWidth: number,
    // TODO
    cellMinWidth: number,
    lastColumnResizable: boolean,
): void {
    const pluginState = columnResizingPluginKey.getState(view.state);

    if (!pluginState) {
        return;
    }

    if (!pluginState.dragging) {
        const targetCell = domCellAround(event.target as HTMLElement);
        let cellPosition = -1;
        let resizeDirection: TResizeDirection | null = null;

        if (targetCell) {
            const directions = getResizeDirections(targetCell, event, handleWidth);
            let side = directions.side;
            resizeDirection = directions.resizeDirection;

            if (side) {
                cellPosition = edgeCell(view, event, side, handleWidth);
            }
        }

        if (cellPosition != pluginState.activeHandle.cell) {
            // check if it is a last column
            if (!lastColumnResizable && cellPosition !== -1) {
                const $cell = view.state.doc.resolve(cellPosition);
                const table = $cell.node(-1);
                const map = TableMap.get(table);
                const tableStart = $cell.start(-1);
                const col = map.colCount($cell.pos - tableStart) + $cell.nodeAfter!.attrs.colspan - 1;

                if (col == map.width - 1) {
                    return;
                }
            }

            // TODO
            // check if it is a last row
            // if (!lastRowResizable && cellPosition !== -1) {

            // }

            // TODO учесть кейс, когда resizeDirection === тгдд
            updateHandle(view, cellPosition, resizeDirection || '');
        }
    }
}

function handleMouseLeave(view: EditorView): void {
    const pluginState = columnResizingPluginKey.getState(view.state);

    if (pluginState && pluginState.activeHandle.cell > -1 && !pluginState.dragging) {
        updateHandle(view, -1, '');
    }
}

function handleMouseDown(
    view: EditorView,
    event: MouseEvent,
    handleWidth: number,
    cellMinWidth: number,
    cellMinHeight: number,
): boolean {
    const pluginState = columnResizingPluginKey.getState(view.state);
    const targetCell = domCellAround(event.target as HTMLElement);

    if (!pluginState || pluginState.activeHandle.cell == -1 || pluginState.dragging || !targetCell) {
        return false;
    }

    const cell = view.state.doc.nodeAt(pluginState.activeHandle.cell)!;
    const width = currentColWidth(view, pluginState.activeHandle.cell, cell.attrs);
    const height = currentRowHeight(view, pluginState.activeHandle.cell, cell.attrs);
    const directions = getResizeDirections(targetCell, event, handleWidth);
    const setDragging =
        directions.resizeDirection === 'col'
            ? { startX: event.clientX, startWidth: width, offsetX: 0 }
            : { startY: event.clientY, startHeight: height, offsetY: 0 };

    view.dispatch(
        view.state.tr.setMeta(columnResizingPluginKey, {
            setDragging,
        }),
    );

    function finish(event: MouseEvent) {
        window.removeEventListener('mouseup', finish);
        window.removeEventListener('mousemove', move);

        const pluginState = columnResizingPluginKey.getState(view.state);

        if (pluginState?.dragging?.startX) {
            updateColumnWidth(
                view,
                pluginState.activeHandle.cell,
                draggedWidth(pluginState.dragging, event, cellMinWidth),
            );
            view.dispatch(view.state.tr.setMeta(columnResizingPluginKey, { setDragging: null }));
        }

        if (pluginState?.dragging?.startY) {
            updateRowHeight(
                view,
                pluginState.activeHandle.cell,
                draggedHeight(pluginState.dragging, event, cellMinHeight),
            );
            view.dispatch(view.state.tr.setMeta(columnResizingPluginKey, { setDragging: null }));
        }
    }

    function move(event: MouseEvent): void {
        // console.log('move', cellMinHeight);
        if (!event.which) {
            return finish(event);
        }

        const pluginState = columnResizingPluginKey.getState(view.state);

        if (!pluginState) {
            return;
        }

        if (pluginState.dragging?.startX) {
            const setDragging = { ...pluginState.dragging, offsetX: event.clientX - pluginState.dragging.startX };

            view.dispatch(view.state.tr.setMeta(columnResizingPluginKey, { setDragging }));
        }

        if (pluginState.dragging?.startY) {
            const setDragging = { ...pluginState.dragging, offsetY: event.clientY - pluginState.dragging.startY };

            view.dispatch(view.state.tr.setMeta(columnResizingPluginKey, { setDragging }));
        }
    }

    window.addEventListener('mouseup', finish);
    window.addEventListener('mousemove', move);
    event.preventDefault();

    return true;
}

function currentColWidth(view: EditorView, cellPos: number, { colspan, colwidth }: Attrs): number {
    const width = colwidth && colwidth[colwidth.length - 1];

    if (width) return width;

    const dom = view.domAtPos(cellPos);
    const node = dom.node.childNodes[dom.offset] as HTMLElement;
    let domWidth = node.offsetWidth,
        parts = colspan;

    if (colwidth)
        for (let i = 0; i < colspan; i++)
            if (colwidth[i]) {
                domWidth -= colwidth[i];
                parts--;
            }

    return domWidth / parts;
}

function currentRowHeight(view: EditorView, cellPos: number, { rowspan, rowheight }: Attrs): number {
    const height = rowheight && rowheight[rowheight.length - 1];

    if (height) return height;

    const dom = view.domAtPos(cellPos);
    const node = dom.node.childNodes[dom.offset] as HTMLElement;
    let domHeight = node.offsetHeight,
        parts = rowspan;

    if (rowheight)
        for (let i = 0; i < rowspan; i++)
            if (rowheight[i]) {
                domHeight -= rowheight[i];
                parts--;
            }

    return domHeight / parts;
}

function domCellAround(target: HTMLElement | null): HTMLElement | null {
    while (target && target.nodeName != 'TD' && target.nodeName != 'TH') {
        target =
            target.classList && target.classList.contains('ProseMirror') ? null : (target.parentNode as HTMLElement);
    }

    return target;
}

function edgeCell(
    view: EditorView,
    event: MouseEvent,
    side: 'left' | 'right' | 'top' | 'bottom',
    handleWidth: number,
): number {
    // posAtCoords returns inconsistent positions when cursor is moving
    // across a collapsed table border. Use an offset to adjust the
    // target viewport coordinates away from the table border.
    const offset = side == 'right' || side == 'bottom' ? -handleWidth : handleWidth;
    const found = view.posAtCoords({
        left: event.clientX + offset,
        top: event.clientY + offset,
    });

    if (!found) {
        return -1;
    }

    const { pos } = found;
    // cell under cursor
    const $cell = cellAround(view.state.doc.resolve(pos));

    if (!$cell) {
        return -1;
    }
    if (side == 'right' || side == 'bottom') {
        return $cell.pos;
    }

    const map = TableMap.get($cell.node(-1)),
        start = $cell.start(-1);
    const cellNumber = map.map.indexOf($cell.pos - start);

    const isFirstColumnLeftEdge = cellNumber % map.width == 0;
    const isFirstRowTopEdge = cellNumber < map.width;
    const cellNumberShift = side == 'left' ? 1 : map.width;

    return isFirstColumnLeftEdge || isFirstRowTopEdge ? -1 : start + map.map[cellNumber - cellNumberShift];
}

function draggedWidth(dragging: TSetDraggingActionMeta, event: MouseEvent, cellMinWidth: number): number {
    const offset = event.clientX - dragging.startX;

    return Math.max(cellMinWidth, dragging.startWidth + offset);
}

function draggedHeight(dragging: TSetDraggingActionMeta, event: MouseEvent, cellMinHeight: number): number {
    const offset = event.clientY - dragging.startY;

    return Math.max(cellMinHeight, dragging.startHeight + offset);
}

function updateHandle(view: EditorView, cell: number, direction: string): void {
    view.dispatch(view.state.tr.setMeta(columnResizingPluginKey, { setHandle: { cell, direction } }));
}

function updateColumnWidth(view: EditorView, cell: number, width: number): void {
    const $cell = view.state.doc.resolve(cell);
    const table = $cell.node(-1),
        map = TableMap.get(table),
        start = $cell.start(-1);
    const colPosition = map.colCount($cell.pos - start) + $cell.nodeAfter!.attrs.colspan - 1;
    const tr = view.state.tr;

    for (let row = 0; row < map.height; row++) {
        const mapIndex = row * map.width + colPosition;

        // Rowspanning cell that has already been handled
        if (row && map.map[mapIndex] == map.map[mapIndex - map.width]) {
            continue;
        }

        const pos = map.map[mapIndex];
        const attrs = table.nodeAt(pos)!.attrs as TCellAttrs;
        const index = attrs.colspan == 1 ? 0 : colPosition - map.colCount(pos);

        if (attrs.colwidth && attrs.colwidth[index] == width) {
            continue;
        }

        const colwidth = attrs.colwidth ? attrs.colwidth.slice() : zeroes(attrs.colspan);
        colwidth[index] = width;

        tr.setNodeMarkup(start + pos, null, { ...attrs, colwidth: colwidth });
    }

    if (tr.docChanged) {
        view.dispatch(tr);
    }
}

function updateRowHeight(view: EditorView, cell: number, height: number): void {
    const $cell = view.state.doc.resolve(cell);
    const table = $cell.node(-1),
        map = TableMap.get(table),
        start = $cell.start(-1);
    const rowPosition = map.rowCount($cell.pos - start) + $cell.nodeAfter!.attrs.rowspan - 1;
    const tr = view.state.tr;

    for (let col = 0; col < map.width; col++) {
        const mapIndex = rowPosition * map.width + col;

        // Rowspanning cell that has already been handled
        if (col && map.map[mapIndex] == map.map[mapIndex - map.height]) {
            continue;
        }

        const pos = map.map[mapIndex];
        const attrs = table.nodeAt(pos)!.attrs as TCellAttrs;
        const index = attrs.rowspan == 1 ? 0 : rowPosition - map.rowCount(pos);

        if (attrs.rowheight && attrs.rowheight[index] == height) {
            continue;
        }

        const rowheight = attrs.rowheight ? attrs.rowheight.slice() : zeroes(attrs.rowspan);
        rowheight[index] = height;

        tr.setNodeMarkup(start + pos, null, { ...attrs, rowheight: rowheight });
    }

    if (tr.docChanged) {
        view.dispatch(tr);
    }
}

function zeroes(n: number): 0[] {
    return Array(n).fill(0);
}

export function handleDecorations(
    state: EditorState,
    handle: TSetHandleActionMeta | null,
    drugging: TSetDraggingActionMeta | null,
): DecorationSet {
    const { cell, direction } = handle || {};
    const decorations: Decoration[] = [];

    if (!cell) {
        return DecorationSet.empty;
    }

    const $cell = state.doc.resolve(cell);
    const table = $cell.node(-1);

    if (!table) {
        return DecorationSet.empty;
    }

    const map = TableMap.get(table);
    const start = $cell.start(-1);
    const colPosition = map.colCount($cell.pos - start) + $cell.nodeAfter!.attrs.colspan;
    const rowPosition = map.rowCount($cell.pos - start) + $cell.nodeAfter!.attrs.rowspan; // TODO colspan?

    if (direction === 'col') {
        for (let row = 0; row < map.height; row++) {
            const index = colPosition + row * map.width - 1;
            // For positions that have either a different cell or the end
            // of the table to their right, and either the top of the table or
            // a different cell above them, add a decoration
            const isLastCellInRow = colPosition == map.width;
            const hasCellOnRight = map.map[index] != map.map[index + 1];
            const isFirstRowCell = row == 0;
            const hasCellUnder = map.map[index] != map.map[index - map.width];

            if ((isLastCellInRow || hasCellOnRight) && (isFirstRowCell || hasCellUnder)) {
                const cellPos = map.map[index];
                const pos = start + cellPos + table.nodeAt(cellPos)!.nodeSize - 1;
                const dom = document.createElement('div');
                dom.className = 'column-resize-handle';
                dom.style.opacity = drugging ? '0.5' : 'unset';
                dom.style.right = `${-2 - (drugging?.offsetX || 0)}px`;

                decorations.push(Decoration.widget(pos, dom));
            }
        }
    }

    if (direction === 'row') {
        for (let col = 0; col < map.width; col++) {
            const index = (rowPosition - 1) * map.width + col;
            // TODO
            // For positions that have either a different cell or the end
            // of the table to their right, and either the top of the table or
            // a different cell above them, add a decoration
            const isLastCellInColumn = rowPosition == map.height;
            const hasCellUnder = map.map[index] != map.map[index + map.width];
            const isFirstColumnCell = col == 0;
            const hasCellOnRight = map.map[index] != map.map[index + 1];

            if ((isLastCellInColumn || hasCellUnder) && (isFirstColumnCell || hasCellOnRight)) {
                const cellPos = map.map[index];
                const pos = start + cellPos + table.nodeAt(cellPos)!.nodeSize - 1;
                const dom = document.createElement('div');
                dom.className = 'row-resize-handle';
                dom.style.opacity = drugging ? '0.5' : 'unset';
                dom.style.bottom = `${-2 - (drugging?.offsetY || 0)}px`;

                decorations.push(Decoration.widget(pos, dom));
            }
        }
    }

    return DecorationSet.create(state.doc, decorations);
}
