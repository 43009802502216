// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigatorOutline__outlineContainer__w_ex0{padding-bottom:75%;background:#e2e2e2;border-bottom:1px solid #e2e2e2;-webkit-box-sizing:border-box;box-sizing:border-box;overflow:hidden;position:relative}.NavigatorOutline__outlineContainer__w_ex0 svg{display:inline-block;position:absolute;left:0;top:0;max-width:100%;max-height:100%}", "",{"version":3,"sources":["webpack://./src/modules/Navigator/components/NavigatorOutline/NavigatorOutline.scss"],"names":[],"mappings":"AAAA,2CACI,kBAAA,CACA,kBAAA,CACA,+BAAA,CACA,6BAAA,CAAA,qBAAA,CACA,eAAA,CACA,iBAAA,CAGI,+CACI,oBAAA,CACA,iBAAA,CAAA,MAAA,CAAA,KAAA,CACA,cAAA,CAAA,eAAA","sourcesContent":[".outlineContainer{\r\n    padding-bottom: 75%;\r\n    background:#e2e2e2;\r\n    border-bottom:1px solid #e2e2e2;\r\n    box-sizing:border-box;\r\n    overflow: hidden;\r\n    position: relative;\r\n\r\n    :global{\r\n        svg{\r\n            display: inline-block;\r\n            position:absolute; left:0; top:0;\r\n            max-width: 100%; max-height:100%;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outlineContainer": "NavigatorOutline__outlineContainer__w_ex0"
};
export default ___CSS_LOADER_EXPORT___;
