// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PasswordInput__input__XG2II{height:32px;color:var(--Neutral800) !important;font-size:14px !important;border-radius:4px !important;padding:0}.PasswordInput__input__XG2II input{padding:5px 0px 6px 7px !important;border-radius:4px !important}.PasswordInput__formItem__WwoBy .ant-col.ant-form-item-label{padding:0 !important;margin-bottom:2px;height:18px}.PasswordInput__formItem__WwoBy .anticon{margin:8px;margin:9px 7px 8px 8px}.PasswordInput__formItem__WwoBy .anticon>svg{width:16px;height:16px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/PasswordInput/PasswordInput.scss"],"names":[],"mappings":"AAAA,6BACI,WAAA,CACA,kCAAA,CACA,yBAAA,CACA,4BAAA,CACA,SAAA,CACA,mCACI,kCAAA,CACA,4BAAA,CAMA,6DACI,oBAAA,CACA,iBAAA,CACA,WAAA,CAEJ,yCACI,UAAA,CACA,sBAAA,CAEJ,6CACI,UAAA,CACA,WAAA","sourcesContent":[".input {\r\n    height: 32px;\r\n    color: var(--Neutral800) !important;\r\n    font-size: 14px !important;\r\n    border-radius: 4px !important;\r\n    padding: 0;\r\n    & input {\r\n        padding: 5px 0px 6px 7px !important;\r\n        border-radius: 4px !important;\r\n    }\r\n}\r\n\r\n.formItem {\r\n    :global {\r\n        .ant-col.ant-form-item-label {\r\n            padding: 0 !important;\r\n            margin-bottom: 2px;\r\n            height: 18px;\r\n        }\r\n        .anticon {\r\n            margin: 8px;\r\n            margin: 9px 7px 8px 8px;\r\n        }\r\n        .anticon > svg {\r\n            width: 16px;\r\n            height: 16px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "PasswordInput__input__XG2II",
	"formItem": "PasswordInput__formItem__WwoBy"
};
export default ___CSS_LOADER_EXPORT___;
