import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import {
    TUserDBAccessDialogActionProps,
    TUserDBAccessDialogOwnProps,
    TUserDBAccessDialogProps,
} from '../UserDBAccessDialog.types';
import UserDBAccessDialog from '../components/UserDBAccessDialog.component';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { getRepositories } from '../../../../selectors/tree.selectors';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { UserDTO } from '../../../../serverapi/api';
import { saveUsersReposChanges } from '../../../../actions/users.actions';

const mapStateToProps = (
    state: TRootState,
    ownProps: TUserDBAccessDialogOwnProps,
): Partial<TUserDBAccessDialogProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const userData = UsersSelectors.getEditingUser(`${ownProps.userId}`)(state) || ({} as UserDTO);
    const repoList = getRepositories(serverId)(state);

    return {
        repoList,
        userData,
    };
};

const mapDispatchToProps: (dispatch, ownProps: TUserDBAccessDialogOwnProps) => TUserDBAccessDialogActionProps = (
    dispatch,
    ownProps,
) => ({
    onClose: () => dispatch(closeDialog(DialogType.EDIT_USER_DBACCESS_PERMISSIONS)),
    onSubmit: (repos: string[]) => {
        dispatch(saveUsersReposChanges(repos, `${ownProps.userId}`));
        dispatch(closeDialog(DialogType.EDIT_USER_DBACCESS_PERMISSIONS));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDBAccessDialog);
