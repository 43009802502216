import { Dropdown, Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import messages from '../../../../messages/SimulationModeling.messages';

type TSimulationActionsIconProps = WrappedComponentProps & {
    disabled: boolean;
    renameSimulation: () => void;
    deleteSimulation: () => void;
    cloneSimulation: () => void;
};
enum SettingsActions {
    RENAME,
    CLONE,
    DELETE,
}

const SimulationActionsIconComponent = (props: TSimulationActionsIconProps) => {
    const { intl, disabled, renameSimulation, deleteSimulation, cloneSimulation } = props;

    const onClick = ({ key }) => {
        switch (Number(key)) {
            case SettingsActions.RENAME: {
                renameSimulation();

                return;
            }
            case SettingsActions.DELETE: {
                deleteSimulation();

                return;
            }
            case SettingsActions.CLONE: {
                cloneSimulation();

                return;
            }
            default: {
                return;
            }
        }
    };
    const menu = (
        <Menu onClick={onClick}>
            <Menu.Item key={SettingsActions.RENAME} data-test="simulation_launch_rename">{intl.formatMessage(messages.rename)}</Menu.Item>
            <Menu.Item key={SettingsActions.CLONE} data-test="simulation_launch_clone">{intl.formatMessage(messages.copy)}</Menu.Item>
            <Menu.Item key={SettingsActions.DELETE} data-test="simulation_launch_delete">{intl.formatMessage(messages.delete)}</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown destroyPopupOnHide overlay={menu} placement="bottomLeft" disabled={disabled}>
            <SettingOutlined onClick={(event) => event.stopPropagation()} />
        </Dropdown>
    );
};

const IntlComponent = injectIntl(SimulationActionsIconComponent);

export { IntlComponent as SimulationActionsIcon };
