export const SET_INITIAL_ARIS_DESCRIPTOR = 'SET_INITIAL_ARIS_DESCRIPTOR';

export const UPLOAD_FILE_EXPORT_ARIS = 'UPLOAD_FILE_EXPORT_ARIS';

export const EXPORT_FILE_ARIS_SUCCESS = 'EXPORT_FILE_ARIS_SUCCESS';

export const CLEAR_ARIS_DESCRIPTOR_FIELDS = 'CLEAR_ARIS_DESCRIPTOR_FIELDS';

export const CHANGE_IMPORT_ARIS_STAGE = 'CHANGE_IMPORT_ARIS_STAGE';

export const SEND_ANALYSIS_ARIS_FILE = 'SEND_ANALYSIS_ARIS_FILE';

export const SET_ARIS_DATA = 'SET_ARIS_DATA';

export const SET_ARIS_NODE_ID_FOR_IMPORT = 'SET_ARIS_NODE_ID_FOR_IMPORT';

export const SET_ARIS_METHODOLOGY_DATA = 'SET_ARIS_METHODOLOGY_DATA';

export const SET_ARIS_IMPORT_FILE_LOCATION = 'SET_ARIS_IMPORT_FILE_LOCATION';

export const SET_ARIS_RESOLVE_CONFLICT_STRATEGY = 'SET_ARIS_RESOLVE_CONFLICT_STRATEGY';

export const SET_ARIS_ATTACHING_EDGES_TO_SYMBOL_BORDER = 'SET_ARIS_ATTACHING_EDGES_TO_SYMBOL_BORDER';

export const START_ARIS_IMPORT = 'START_ARIS_IMPORT';

export const SET_ARIS_RESPONSE_ERROR = 'SET_ARIS_RESPONSE_ERROR';

export const LOAD_ARIS_FROM_EXCEL = 'LOAD_ARIS_FROM_EXCEL';

export const SAVE_ARIS_IN_EXCEL = 'SAVE_ARIS_IN_EXCEL';
