import { NodeId } from '../serverapi/api';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';

export namespace AdminToolsUtils {
    export const createNodeId: (serverId: string, tabType: WorkSpaceTabTypes) => NodeId = (serverId, tabType) => ({
        id: tabType,
        repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
        serverId,
    });
}
