import { TRootState } from '../../../reducers/root.reducer.types';
import { connect } from 'react-redux';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { InstancePermissionsDialog } from '../components/InstancePermissionsDialog/InstancePermissionsDialog.component';
import { TInstancePermissionsDialogProps } from '../components/InstancePermissionsDialog/InstancePermissionsDialog.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import {
    instancePermissionsDialogSubmitResult,
    principalPermissionsDelete,
    principalPermissionsUpdate,
} from '../../../actions/instancePermissions.actions';
import { principalAddDialogOpenAction } from '../../../actions/principal.actions';
import { PermissionModelPermissionEnum } from '../../../serverapi/api';

const mapStateToProps = (
    { instancePermissions, principals }: TRootState,
    props: TInstancePermissionsDialogProps,
): Partial<TInstancePermissionsDialogProps> => ({
    ...props,
    error: instancePermissions.error,
    loading: instancePermissions.loading,
    changed: instancePermissions.changed,
    principalPermissions: instancePermissions.data ? instancePermissions.data.permissions : [],
    principals: principals.data,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange: (principalId: number, actionType: PermissionModelPermissionEnum, isGranting: boolean | undefined) =>
        dispatch(principalPermissionsUpdate(principalId, actionType, isGranting)),
    onClose: () => dispatch(closeDialog(DialogType.INSTANCE_PERMISSIONS_DIALOG)),
    onSubmit: () => dispatch(instancePermissionsDialogSubmitResult()),
    onDelete: (selectedIds: number[]) => dispatch(principalPermissionsDelete(selectedIds)),
    onAddPrincipal: () => dispatch(principalAddDialogOpenAction(ownProps.nodeId.serverId))
});

export const InstancePermissionsDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(InstancePermissionsDialog);
