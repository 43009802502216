import { MailMessageDTO } from '@/serverapi/api';
import { ApiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';

export class MessagesDAOService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getMailMessages(): Promise<MailMessageDTO[]> {
        const api = this.getApi();
        const mailMessages: MailMessageDTO[] = await api.messages.getMailMessages();

        return mailMessages;
    }
}
