import { TreeNode } from '../models/tree.types';
import { TreeSelectors } from './tree.selectors';
import { getRecentModel } from './recent.selector';
import { TRecentModel } from '../models/recentModel.types';
import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
// import { INITIAL_MODEL_SERVER_STATE } from '../reducers/model.reducer';
import { NodeId } from '../serverapi/api';
import { TModelState } from 'src/reducers/entities/model.reducer.types';

const modelTypeStateSelector = (state: TRootState): TModelState => state.model;

export namespace ModelSelectors {
    export const byId = (nodeId: NodeId) =>
        createSelector(modelTypeStateSelector, (state) => state.byNodeId.get(nodeId));

    export const getModelNameByNodeId = (nodeId: NodeId) =>
        createSelector(
            TreeSelectors.itemById(nodeId),
            getRecentModel(nodeId),
            (treeNode: TreeNode, recentNode: TRecentModel) => {
                if (treeNode?.name) {
                    return treeNode.name;
                }

                return recentNode?.title || '';
            },
        );
}
