import type { TRootState } from '../../reducers/root.reducer.types';
import type { NodeId } from '../../serverapi/api';
import { createSelector } from 'reselect';

const wikiStateSelector = (state: TRootState) => state.wiki;

export namespace WikiSelectors {
    export const byId = (nodeId: NodeId) => createSelector(wikiStateSelector, (state) => state.data.get(nodeId));
    export const clipboard = () => createSelector(wikiStateSelector, (state) => state.clipboard);
    export const uploadingFiles = (modelId: NodeId) =>
        createSelector(wikiStateSelector, (state) => state.uploadingFiles.get(modelId));
}
