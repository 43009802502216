import { ACCEPT_AGREEMENT, ACCEPT_SILA_AGREEMENT, DELETE_AGREEMENT, DELETE_SILA_AGREEMENT } from "../actionsTypes/agreementActionTypes";
import { TAcceptAgreementAction, TAcceptSilaAgreementAction, TDeleteAgreementAction, TDeleteSilaAgreementAction } from "./agreement.actions.types";

export const acceptAgreement = (serverId: string): TAcceptAgreementAction => ({
    type: ACCEPT_AGREEMENT,
    payload: {
        serverId
    }
});

export const acceptSilaAgreement = (serverId: string): TAcceptSilaAgreementAction => ({
    type: ACCEPT_SILA_AGREEMENT,
    payload: {
        serverId
    }
});

export const deleteAgreement = (serverId: string): TDeleteAgreementAction => ({
    type: DELETE_AGREEMENT,
    payload: {
        serverId
    }
});

export const deleteSilaAgreement = (serverId: string): TDeleteSilaAgreementAction => ({
    type: DELETE_SILA_AGREEMENT,
    payload: {
        serverId
    }
});
