import { TReducer } from '../utils/types';
import { LOCALE_SELECT } from "../actionsTypes/locale.actionTypes";
import { LocalesService } from '../services/LocalesService';
import { TLocaleState } from './locale.reducer.types';

const initial: TLocaleState = {
    selected: LocalesService.getLocale()
};

export const localeReducer: TReducer<TLocaleState> = (state = initial, action) => {
    switch (action.type) {
        case LOCALE_SELECT: {
            return {
                ...state,
                selected: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
