export const SUBMIT_ATTRIBUTE_TYPE_GROUP = 'SUBMIT_ATTRIBUTE_TYPE_GROUP';

export const ADD_ATTRIBUTE_TYPE_GROUPS = 'ADD_ATTRIBUTE_TYPE_GROUPS';

export const CREATE_ATTRIBUTE_TYPE_GROUP = 'CREATE_ATTRIBUTE_TYPE_GROUP';

export const DELETE_ATTRIBUTE_TYPE_GROUP = 'DELETE_ATTRIBUTE_TYPE_GROUP';

export const EDIT_ATTRIBUTE_TYPE_GROUP = 'EDIT_ATTRIBUTE_TYPE_GROUP';

export const DELETE_ATTRIBUTE_TYPE_GROUP_SUCCESS = 'DELETE_ATTRIBUTE_TYPE_GROUP_SUCCESS';
