import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'DeleteCommentDialog.title',
        defaultMessage: 'Удаление комментария',
    },
    deleteComment: {
        id: 'DeleteCommentDialog.delete',
        defaultMessage: 'Удалить',
    },
    cancel: {
        id: 'DeleteCommentDialog.cancel',
        defaultMessage: 'Отмена',
    },
    text: {
        id: 'DeleteCommentDialog.text',
        defaultMessage: 'Вы уверены, что хотите удалить этот комментарий и все ответы к нему?',
    },
    textForAnswer: {
        id: 'DeleteCommentDialog.textForAnswer',
        defaultMessage: 'Вы уверены, что хотите удалить этот ответ на комментарий?',
    },
});
