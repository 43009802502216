import { defineMessages } from 'react-intl';

export default defineMessages({
    userEditDisabled: {
        id: 'Settings.userEditDisabled',
        defaultMessage: 'Блокировка на ручные изменения',
    },
    scriptEngineEditDisabled: {
        id: 'Settings.scriptEngineEditDisabled',
        defaultMessage: 'Блокировка на изменения скриптами',
    },
    at: {
        id: 'Settings.at',
        defaultMessage: 'в',
    },
    allowUnauthorizedAccess: {
        id: 'Settings.allowUnauthorizedAccess',
        defaultMessage: 'Разрешить доступ неавторизованным пользователям',
    },
    publishedInfo: {
        id: 'Settings.publishedInfo',
        defaultMessage: 'Модель опубликована: ',
    },
    publishingWarning: {
        id: 'Settings.publishingWarning',
        defaultMessage: 'Модель будет опубликована после нажатия кнопки "Сохранить"',
    }
});
