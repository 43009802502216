import React, { FC } from 'react';

type TIconInputServerProps = {
    color: string;
    className?: string;
};

const IconInputServerComponent: FC<TIconInputServerProps> = ({ color, className }) => {
    return (
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                    fill={color}
                    fillRule="evenodd"
                    d="M12.998 13.998h-10c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1zM13 9H3v4h10V9zm-7.001 3H4V9.999h1.999V12zm6.999-5.002h-10c-.55 0-1-.45-1-.999v-4c0-.55.45-1.001 1-1.001h10c.55 0 1 .451 1 1.001v4c0 .549-.45.999-1 .999zM13 1.999H3V6h10V1.999zM5.999 5H4V3h1.999v2z"
                />
                <path fill="none" d="M0 0h16v16H0V0z" />
            </svg>
        </div>
    );
};

export default IconInputServerComponent;
