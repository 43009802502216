export type TTableData = {
    key: string;
    name: string;
    count: number;
    deletedCount: number;
};

export enum NodeStatistics {
    modelsCount = 'modelsCount',
    objectsCount = 'objectsCount',
    filesCount = 'filesCount',
    foldersCount = 'foldersCount',
    otherNodesCount = 'otherNodesCount',
    deletedModelsCount = 'deletedModelsCount',
    deletedObjectsCount = 'deletedObjectsCount',
    deletedFoldersCount = 'deletedFoldersCount',
    deletedFilesCount = 'deletedFilesCount',
    deletedOtherNodesCount = 'deletedOtherNodesCount',
}
