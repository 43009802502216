import { TReducer } from '../utils/types';
import { FolderType } from '../serverapi/api';
import {
    FOLDER_TYPE_EDITOR_CANCEL,
    FOLDER_TYPE_EDITOR_EDIT_VALUE,
    FOLDER_TYPE_EDITOR_INIT,
} from '../actionsTypes/folderTypeEditor.actionTypes';

const initialState: FolderType = {
    id: '',
    presetId: '',
    multilingualName: {},
    multilingualDescription: {},
    graphical: '',
    allowAnyFolderType: true,
    allowAnyModelType: true,
    allowAnyNodeType: true,
    allowAnyObjectType: true,
    allowApprovals: false,
    validModelTypes: [],
    validObjectTypes: [],
    validFolderTypes: [],
    validNodeTypes: [],
    attributeTypes: [],
};

export const folderTypeEditorReducer: TReducer<FolderType> = (state = initialState, action) => {
    switch (action.type) {
        case FOLDER_TYPE_EDITOR_CANCEL: {
            return initialState;
        }
        case FOLDER_TYPE_EDITOR_INIT: {
            return action.payload.folderType;
        }
        case FOLDER_TYPE_EDITOR_EDIT_VALUE: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
};