import {
    Browsers,
    SUPPORTED_BROWSERS,
} from '../consts/Browser.const';
import { TBrowserAndVersion, TCheckBrowserAndVersion } from '../types/Browsers.types';

const browserVersion = (userAgent: string, regex: string | RegExp): string => {
    return userAgent.match(regex)?.[2] || '0.0.0.0';
};

export const getBrowserAndVersion = (): TBrowserAndVersion => {
    const { userAgent } = navigator;
    let browser: Browsers = Browsers.Unkown;
    browser = /edg/i.test(userAgent) ? Browsers.Edge : browser;
    browser = /chromium/i.test(userAgent) ? Browsers.Chromium : browser;
    browser = /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent) ? Browsers.Firefox : browser;
    browser =
        /chrome|crios/i.test(userAgent) && !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
            ? Browsers.Chrome
            : browser;
    browser =
        /safari/i.test(userAgent) && !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(userAgent)
            ? Browsers.Safari
            : browser;
    browser = /opr|opera/i.test(userAgent) ? Browsers.Opera : browser;
    browser = /yabrowser/i.test(userAgent) ? Browsers.YaBrowser : browser;
    switch (browser) {
        case Browsers.Edge:
            return { browser, version: browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d.]+)/i) };
        case Browsers.Chromium:
            return { browser, version: browserVersion(userAgent, /(chromium)\/([\d.]+)/i) };
        case Browsers.Chrome:
            return { browser, version: browserVersion(userAgent, /(chrome|crios)\/([\d.]+)/i) };
        case Browsers.YaBrowser:
            return { browser, version: browserVersion(userAgent, /(yabrowser|fxios)\/([\d.]+)/i) };
        default:
            return { browser, version: '0.0.0.0' };
    }
};


export const getUnsupportedVersionInfo = (
    browser: TBrowserAndVersion,
    currentVersion: string,
): TCheckBrowserAndVersion => {
    if (Number(currentVersion.split('.')[0]) < Number(browser.version.split('.')[0]))
        return { browser: browser.browser, currentVersion, minVersion: browser.version };

    return {};
};

export const checkBrowserAndVersion = (): TCheckBrowserAndVersion => {
    const { browser, version } = getBrowserAndVersion();
    const supportedBrowser: TBrowserAndVersion | undefined = SUPPORTED_BROWSERS.find(
        (supportedBrowser) => supportedBrowser.browser === browser,
    );
    if (!supportedBrowser) return { isUnsupportedBrowser: true, browser };

    return getUnsupportedVersionInfo(supportedBrowser, version);
};
