import CodeBlock from '@tiptap/extension-code-block';
import { DOCUMENT_START_POS, MIN_BLOCK_NODE_SIZE } from './constants';

const ExtendedCodeBlock = CodeBlock.extend({
    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),
            Backspace: () => {
                const { empty, $anchor } = this.editor.state.selection;
                const isAtStartOfDocument = $anchor.pos === DOCUMENT_START_POS;
                const isAtCodeBlock = $anchor.parent.type.name === this.name;
                let isCodeBlockBefore = false;
                let isEmptyCodeBlockBefore = false;

                this.editor.state.doc.nodesBetween($anchor.pos - 2, $anchor.pos - 1, (node) => {
                    if (node.type.name === this.name) {
                        isCodeBlockBefore = true;
                        isEmptyCodeBlockBefore = !node.textContent.length;
                    }
                });

                if (!isAtCodeBlock && isCodeBlockBefore && !isEmptyCodeBlockBefore) {
                    this.editor.commands.setTextSelection($anchor.pos - MIN_BLOCK_NODE_SIZE);

                    return true;
                }

                if (isEmptyCodeBlockBefore) {
                    return false;
                }

                if (!empty || !isAtCodeBlock) {
                    return false;
                }

                if (isAtStartOfDocument || !$anchor.parent.textContent.length) {
                    return this.editor.commands.clearNodes();
                }

                return false;
            },
            Delete: () => {
                const { $anchor } = this.editor.state.selection;
                const isAtEndOfDocument = $anchor.pos === this.editor.view.state.doc.content.size - 1;
                const isAtCodeBlock = $anchor.parent.type.name === this.name;
                let isCodeBlockAfter = false;
                let isEmptyCodeBlockAfter = false;

                if (isAtEndOfDocument) {
                    return true;
                }

                this.editor.state.doc.nodesBetween($anchor.pos + 1, $anchor.pos + 2, (node) => {
                    if (node.type.name === this.name) {
                        isCodeBlockAfter = true;
                        isEmptyCodeBlockAfter = !node.textContent.length;
                    }
                });

                if (
                    (!isAtCodeBlock && isCodeBlockAfter && !isEmptyCodeBlockAfter) ||
                    (isAtCodeBlock && !isCodeBlockAfter)
                ) {
                    this.editor.commands.setTextSelection($anchor.pos + MIN_BLOCK_NODE_SIZE);

                    return true;
                }

                if (isEmptyCodeBlockAfter) {
                    return false;
                }

                return false;
            },
        };
    },
});

export { ExtendedCodeBlock as CodeBlock };
