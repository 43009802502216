import type { DiagramElement } from '../../serverapi/api';
import { MxCell, MxUtils, MxCodec, MxGraphModel } from '../mxgraph';
import { groupBy } from 'lodash-es';

export function createFrameFromString(frameString: string) {
    try {
        const doc = MxUtils.parseXml(frameString);
        const codec = new MxCodec(doc);
        const model = new MxGraphModel();
        codec.decode(doc.documentElement, model);
        const rootCell = model.root.getChildAt(0);

        return rootCell.children;
    } catch (e) {
        console.error(e);

        return [];
    }
}

export function markCellsAsFrame(cells: MxCell[], frameId: string) {
    cellsTreeWalkerDown(cells, (cell: MxCell) => {
        cell.frame = frameId;
    });
}

export function grabFrameCells(root, frameId: string): MxCell[] {
    const result: MxCell[] = [];

    cellsTreeWalkerDown(root, (cell: MxCell) => {
        if (cell.frame === frameId) {
            result.push(cell);
        }
    });

    return result;
}

export function encode(cells: MxCell[]) {
    const tempValues: any[] = [];
    cells.forEach((cell: MxCell) => {
        if (typeof cell.getValue() === 'object') {
            tempValues.push({
                cell,
                value: cell.getValue(),
            });
            cell.setValue('');
        }
    });

    const encoder = new MxCodec();
    const result = encoder.encode(cells);
    const xml = MxUtils.getPrettyXml(result);
    const modelXml = xml
        .replace('<Array>', '<mxGraphModel><root><mxCell id="0" /><mxCell id="1" parent="0" />')
        .replace('</Array>', '</root></mxGraphModel>');

    tempValues.forEach((item) => {
        item.cell.setValue(item.value);
    });

    return modelXml;
}

export function getFrameId(cell: MxCell): string | undefined {
    return cell.frame;
}

export function cellsTreeWalkerDown(cells: MxCell[] = [], cb: Function) {
    cells.forEach((cell) => {
        cb && cb(cell);
        if (cell.children) {
            cellsTreeWalkerDown(cell.children, cb);
        }
    });
}

export function cellTreeWalkerUp(cell: MxCell, cb: Function) {
    while (cell) {
        if (cb && cb(cell)) {
            return cell;
        }
        cell = cell.parent;
    }

    return undefined;
}

export function getTreePath(root, parents): string[] {
    const path: string[] = [...root];
    root.forEach((el) => {
        if (parents[el]) path.push(...getTreePath(parents[el], parents));
    });

    return path;
}

export function currentTreeDepth(cell: MxCell): number {
    let currentCell = cell;
    let n = 0;
    while (currentCell.parent && currentCell.parent.frame && n < 10) {
        n++;
        currentCell = currentCell.parent;
    }

    return n;
}

export function getHierarchicalBinding(elements: DiagramElement[]) {
    const result = {};
    elements.forEach((e) => {
        const { id } = e;
        const { parent } = e;

        if (parent) {
            result[parent] = result[parent] ? result[parent] : [];
            result[parent].push(id);
        }
    });

    return result;
}

export function sortDiagramElementsByRenderOrder(elements: DiagramElement[], rootId: string) {
    const sortedElements = [...elements];
    const parents = getHierarchicalBinding(sortedElements);
    const root = [rootId];
    const path = getTreePath(root, parents);

    return sortedElements.sort((a: DiagramElement, b: DiagramElement) => {
        // @ts-ignore
        return path.indexOf(a.id) - path.indexOf(b.id);
    });
}

/**
 *
 * Возвращает список родителей с уровнем вложенности.
 * Корневые родители имеют уровень вложенности, равный 1.
 *
 * @param elements
 * @param groups элементы, сгрупированные по id родителя
 *
 */
const getGroupsWithTreeDepth = (
    elements: DiagramElement[],
    groups: Record<string, DiagramElement[]>,
): Record<string, number> => {
    const childToParentMap: Record<string, string> = {};
    const groupsWithDepth: Record<string, number> = {};

    const getSequence = (arr: string[], map: Record<string, string>): string[] => {
        const nextParent = map[arr[arr.length - 1]];

        if (nextParent?.length > 0) {
            return getSequence([...arr, nextParent], map);
        }

        return arr;
    };

    elements.forEach((element) => (childToParentMap[element.id] = element.parent || ''));

    Object.keys(groups).forEach((parentId) => {
        const groupFirstChildId = groups[parentId][0].id;
        const groupSequence = getSequence([groupFirstChildId], childToParentMap);

        groupsWithDepth[parentId] = groupSequence.length - 1;
    });

    return groupsWithDepth;
};

/**
 *
 * Возвращает список элементов, отсортированный по уровню вложенности:
 * - в начале списка находятся элементы, у которых нет родителя;
 * - порядок детей одного родителя сохраняется.
 *
 * @param elements
 *
 */
export const sortParentElementsFirst = (elements: DiagramElement[]): DiagramElement[] => {
    const nodesWithChildren: { [key: string]: DiagramElement[] } = groupBy(elements, 'parent');
    const nodesIds = Object.keys(nodesWithChildren);
    const nodesWithDepth = getGroupsWithTreeDepth(elements, nodesWithChildren);

    nodesIds.sort((a, b) => nodesWithDepth[a] - nodesWithDepth[b]);

    const ordered: DiagramElement[] = [];

    nodesIds.forEach((id) => {
        ordered.push(...nodesWithChildren[id]);
    });

    return ordered;
};
