export const CHANGE_NAVIGATOR_TREE_SEARCH_STATUS = 'CHANGE_NAVIGATOR_TREE_SEARCH_STATUS';

export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';

export const SET_FOUND_NODE_IDS = 'SET_FOUND_NODE_IDS';

export const SET_MOVE_DIRECTION = 'SET_MOVE_DIRECTION';

export const FIND_NODE_IDS_BY_SEARCH_STRING = 'FIND_NODE_IDS_BY_SEARCH_STRING';

export const CHANGE_FOUND_NODE_IDS_BY_SEARCH_STRING = 'CHANGE_FOUND_NODE_IDS_BY_SEARCH_STRING';
