import React from 'react';
import messages from '../../messages/ImportDialog.messages';
import { Alert, Form } from 'antd';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../../UIKit/components/Dialog/Dialog.types';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import theme from './ImportRepositoryReplaceDialog.scss';
import { TImportRepositoryReplaceDialogProps } from '../../types/ImportDialog.types';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

class ImportRepositoryReplaceDialog extends React.Component<TImportRepositoryReplaceDialogProps> {
    handleSubmit = () => {
        this.props.onSubmit();
    };

    render() {
        const { intl, open, onCancel } = this.props;
        const dialogProps: Partial<TDialogProps> = {
            open,
            title: intl.formatMessage(messages.dialogReplaceRepositoryName),
        };

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'apply',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.replace),
                    },
                    {
                        key: 'cancel',
                        onClick: onCancel,
                        value: intl.formatMessage(messages.cancelButton),
                    },
                ]}
            />
        );

        return (
            <Dialog {...dialogProps} footer={footer} onCancel={onCancel}>
                {/* todo 2979 удалить алерт */}
                {this.props.error && <Alert type="error" message={intl.formatMessage(this.props.error)} showIcon />}
                <Form layout="vertical" hideRequiredMark>
                    <FormGroup>
                        <Form.Item
                            className={theme.itemType}
                            label={
                                <p style={{ whiteSpace: 'pre-wrap' }}>
                                    {`${intl.formatMessage(messages.dialogReplaceRepository)} - "${this.props.path}"
${intl.formatMessage(messages.dialogConfirmationReplaceRepository)}`}
                                </p>
                            }
                        />
                    </FormGroup>
                    <p />
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ImportRepositoryReplaceDialog);

export { IntlComponent as ImportRepositoryReplaceDialog };
