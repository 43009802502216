import * as React from 'react';
import { Tabs } from 'antd';
import theme from './Tabsarea.scss';
import { TTabsareaTabItem } from './Tabsarea.types';

type TTabsareaProps = {
    activeKey: string;
    tabs: TTabsareaTabItem[];
    children: JSX.Element | JSX.Element[];
    onChangeTab: (key: string) => void;
};

const { TabPane } = Tabs;

export const Tabsarea = (props: TTabsareaProps) => {
    const { children, tabs, activeKey } = props;

    return (
        <div className={theme.tabContainer}>
            <Tabs onChange={props.onChangeTab} activeKey={activeKey} tabBarGutter={2}>
                {tabs &&
                    tabs.map((tab) => {
                        const tabTitle = <span>{tab.title}</span>;

                        return (
                            <TabPane tab={tabTitle} key={tab.id}>
                                {children}
                            </TabPane>
                        );
                    })}
            </Tabs>
        </div>
    );
};
