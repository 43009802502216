import React, { Component } from 'react';
import admTheme from '../../style/AdminTools.scss';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import userMessages from '../../UserManagement/messages/userManagment.messages';
import { Table } from 'antd';
import { difference } from 'lodash-es';
import { TWithWindowResizeProps } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { toggleArrayItem } from '../../../../utils/toggleArrayItem';
import { TUserListProps } from './UserList.types';

type TUserListActionProps = {
    onCheckbox: (added: number[] | undefined, removed: number[] | undefined) => void;
};

type TFilteredUsers = { filteredUsers: { key: number; userLogin: string; userFIO: string }[] };

type TUserListFullProps = TUserListProps &
    TFilteredUsers &
    TUserListActionProps &
    WrappedComponentProps &
    TWithWindowResizeProps;

type TUserListState = {
    selectedRowKeys: number[] | undefined;
    added?: number[] | undefined;
    removed?: number[] | undefined;
};

type TUserListTableRecord = {
    key: number;
    userLogin: string;
    userFIO: string;
};

class UserList extends Component<TUserListFullProps, TUserListState> {
    state: TUserListState = {
        selectedRowKeys: this.props.selected,
    };

    getColumns = () => {
        const { intl } = this.props;

        return [
            {
                title: intl.formatMessage(userMessages.userLogin),
                dataIndex: 'userLogin',
                key: 'userLogin',
                width: 200,
                sorter: (a: TUserListTableRecord, b: TUserListTableRecord) => sortByAlpha(a.userLogin, b.userLogin),
            },
            {
                title: intl.formatMessage(userMessages.userFIO),
                dataIndex: 'userFIO',
                key: 'userFIO',
                width: 200,
                sorter: (a: TUserListTableRecord, b: TUserListTableRecord) => sortByAlpha(a.userFIO, b.userFIO),
            },
        ];
    };

    onSelectChange = (selectedRowKeys: number[]) => {
        let selectedUsers: number[] = selectedRowKeys;

        if (this.props.search !== '' && this.state.selectedRowKeys) {
            selectedUsers = [
                ...this.state.selectedRowKeys.filter(
                    (id) => !this.props.filteredUsers.some((user) => user.key === id),
                ),
                ...selectedRowKeys,
            ];
        }

        const removed = difference(this.props.selected, selectedUsers);
        const added = this.props.selected ? difference(selectedUsers, this.props.selected) : selectedUsers;

        this.setState({ selectedRowKeys: selectedUsers }, () => this.props.onCheckbox(added, removed));
    };

    onRow = (row: TUserListTableRecord) => {
        const newState: number[] = toggleArrayItem<number>(row.key, this.state.selectedRowKeys);

        return {
            onClick: () => this.onSelectChange(newState),
            'data-test': row.userLogin,
        };
    };

    render() {
        const { checkboxable, selectedAll, usersInGroup } = this.props;
        const rowSelection = {
            selectedRowKeys: selectedAll ? usersInGroup.map((u) => u.id) : this.state.selectedRowKeys,
            onChange: checkboxable ? this.onSelectChange : () => {},
        };
        const maxTableHeight = this.props.wHeight - 329;

        return (
            <Table
                className={admTheme.dataTable}
                rowSelection={rowSelection}
                columns={this.getColumns()}
                dataSource={this.props.filteredUsers}
                pagination={false}
                scroll={{ y: maxTableHeight }}
                onRow={this.onRow}
            />
        );
    }
}

export default injectIntl(UserList);
