import { defineMessages } from 'react-intl';

export default defineMessages({
    button: {
        id: 'SearchTab.searchField.button.text',
        defaultMessage: 'Поиск',
    },
    placeholder: {
        id: 'SearchTab.searchField.placeholder.text',
        defaultMessage: 'Введите символы для поиска',
    },
    NOT_DELETED: {
        id: 'SearchDeletedElementsTab.NOT_DELETED',
        defaultMessage: 'Не отображать удалённые'
    },
    ALL_INCLUDE_DELETED: {
        id: 'SearchDeletedElementsTab.ALL_INCLUDE_DELETED',
        defaultMessage: 'Отображать все элементы'
    },
    DELETED: {
        id: 'SearchDeletedElementsTab.DELETED',
        defaultMessage: 'Отображать только удалённые'
    },
});
