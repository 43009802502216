import { TReducer } from '../utils/types';
import { RECENT_ADD_MODEL, RECENT_LOAD_SUCCESS, RECENT_UPDATE_MODEL } from '../actionsTypes/recent.actionTypes';
import { TRecentState } from './recent.reducer.types';

const initial: TRecentState = {
    models: {},
};

export const recentReducer: TReducer<TRecentState> = (state = initial, action) => {
    switch (action.type) {
        case RECENT_LOAD_SUCCESS: {
            const {
                payload: { models },
            } = action;

            return {
                ...state,
                models,
            };
        }
        case RECENT_UPDATE_MODEL:
        case RECENT_ADD_MODEL: {
            const {
                payload: {
                    model,
                    model: {
                        nodeId: { id, serverId, repositoryId },
                    },
                },
            } = action;
            const newState: TRecentState = JSON.parse(JSON.stringify(state));
            if (!(id && serverId && repositoryId)) {
                return newState;
            }
            newState.models[serverId] = newState.models[serverId] || {};
            newState.models[serverId][repositoryId] = newState.models[serverId][repositoryId] || {};
            newState.models[serverId][repositoryId][id] = model;

            return newState;
        }
        default:
            return state;
    }
};
