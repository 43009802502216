export const SAVE_MODEL = 'SAVE_MODEL';

export const SAVE_MODEL_SUCCESS = 'SAVE_MODEL_SUCCESS';

export const SAVE_MODEL_FAIL = 'SAVE_MODEL_FAIL';

export const SAVE_UNSAVED_DECOMPOSITION_MODELS = 'SAVE_UNSAVED_DECOMPOSITION_MODELS';

export const NOT_SAVE_UNSAVED_DECOMPOSITION_MODELS = 'NOT_SAVE_UNSAVED_DECOMPOSITION_MODELS';

export const SAVE_MODEL_COMMON = 'SAVE_MODEL_COMMON';

export const MODEL_CREATE = 'MODEL_CREATE';
