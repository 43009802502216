import type { TPasteMenuItemProps, TPasteToolbarProps } from './PasteMenuItem.types';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { GraphPasteToolbarContainer } from '../../../containers/GraphPasteToolbar.container';
import { homePageTabId } from '../../../../../models/home-page';
import { AdjustToolbarSizeComponent } from '../../AdjustToolbarSize/AdjustToolbarSize.component';

const toolbarComponents = {
    Editor: GraphPasteToolbarContainer,
};

class PasteMenuItemComponent extends AdjustToolbarSizeComponent<TPasteMenuItemProps> {
    getToolbar(toolbarComponent: string) {
        const { compact, activeKey } = this.props;
        const ToolbarComponent: React.ElementType<TPasteToolbarProps> = toolbarComponents[toolbarComponent];

        if (activeKey && activeKey !== homePageTabId) {
            return <ToolbarComponent compact={compact} />;
        }

        return super.getToolbar(toolbarComponent);
    }
}

const PasteMenuItemWithIntl = injectIntl(PasteMenuItemComponent);

export { PasteMenuItemWithIntl as PasteMenuItem };
