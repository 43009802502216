import React from 'react';
import theme from './userAccount.scss';
import accessTypesMsg from '../../models/userAccesses.messages';
import licenseTypesMsg from '../../models/licenseTypes.messages';
import { IntlShape, useIntl } from 'react-intl';
import messages from '../UserAccountTab/userAccount.messages';
import { PersonalProfileAccessesEnum, PersonalProfileLicensesEnum, ServerProfile } from '@/serverapi/api';

type TUserAccessRightsProps = {
    licenses: PersonalProfileLicensesEnum[];
    accesses: PersonalProfileAccessesEnum[];
    groupNames: string[];
    profiles: ServerProfile[];
};

export const UserAccessRights = (props: TUserAccessRightsProps) => {
    const { licenses, accesses, groupNames, profiles } = props;
    const intl: IntlShape = useIntl();

    return (
        <div className={theme.userAccessRightsContainer}>
            <div className={theme.userAccessRights}>{intl.formatMessage(messages.userAccessRights)}</div>
            <div className={theme.section}>
                <h4 className={theme.userAccessHeader}>{intl.formatMessage(messages.licenses)}</h4>
                <div className={theme.license}>
                    {licenses.map((license) => (
                        <div key={license} className={theme.tag}>
                            {intl.formatMessage(licenseTypesMsg[license])}
                        </div>
                    ))}
                </div>
            </div>
            <div className={theme.section}>
                <h4 className={theme.userAccessHeader}>{intl.formatMessage(messages.accesses)}</h4>
                <div className={theme.accesses}>
                    {accesses.map((access) => (
                        <span key={access} className={theme.tag}>
                            {intl.formatMessage(accessTypesMsg[access])}
                        </span>
                    ))}
                </div>
            </div>
            <div className={theme.section}>
                <h4 className={theme.userAccessHeader}>{intl.formatMessage(messages.groups)}</h4>
                <div className={theme.groups}>
                    {groupNames.map((group, index) => (
                        <span key={`${group}_${index}`} className={theme.tag}>
                            {group}
                        </span>
                    ))}
                </div>
            </div>
            <div className={theme.section}>
                <h4 className={theme.userAccessHeader}>{intl.formatMessage(messages.profiles)}</h4>
                <div className={theme.profiles}>
                    {profiles?.map((p) => (
                        <span key={p.id} className={theme.tag}>
                            {p.name || ''}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};
