import { PresetTypesAcl } from '../../../../../../serverapi/api';

export type TAclRightTypes = 'create' | 'read' | 'update' | 'delete';

export enum TPresetProfileEntityTypes {
    model = 'model',
    object = 'object',
    symbol = 'symbol',
    attribute = 'attribute',
    edge = 'edge',
}

export type TEntityPrincipal = {
    id: string;
    name: string;
    children: TEntityDataWithAcl[];
    parentId?: string;
};

export type TEntityDataWithAcl = {
    id: string;
    name: string;
    acl: PresetTypesAcl;
    children?: TEntityDataWithAcl[];
    parentId?: string;
};
