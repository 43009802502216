import * as React from 'react';
import theme from './ContentTable.scss';
import classnames from 'classnames';
import { IContentTableCell } from '../ContentTable.types';

type TContentTableProps = {
    key?: boolean;
    headerTitles?: (string | JSX.Element)[];
    data?: Array<Array<IContentTableCell>>;
    onClick?: any; // tslint:disable-line:no-any
    renderTableCells?: any; // tslint:disable-line:no-any
    renderHeaders?: any; // tslint:disable-line:no-any
    renderTableRows?: any; // tslint:disable-line:no-any
} & JSX.IntrinsicAttributes;

export class ContentTable extends React.Component<TContentTableProps> {
    renderHeaders() {
        const headerTitles = this.props.headerTitles;
        if (headerTitles) {
            const headers = headerTitles.map((item: string, id: number) => {
                return (
                    <th key={id} className={theme.th}>
                        {item}
                    </th>
                );
            });

            return headers;
        }

        return null;
    }

    renderTableRows() {
        const data = this.props.data;
        if (data) {
            const rows = data.map((cells: IContentTableCell[], id: number) => {
                return (
                    <tr key={id} className={theme.tr}>
                        {this.renderTableCells(cells)}
                    </tr>
                );
            });

            return rows;
        }

        return null;
    }

    renderTableCells = (cells: IContentTableCell[]) => {
        return cells.map((cell: IContentTableCell, id: number) => {
            if (cell.editable === undefined || cell.editable) {
                return (
                    <td key={id} className={theme.td}>
                        {cell.content}
                    </td>
                );
            }
 
            return (
                <td key={id} className={classnames(theme.td, theme.disable)}>
                    {cell.content}
                </td>
            );
            
        });
    };

    render() {
        return (
            <table className={theme.table}>
                <thead>
                    <tr className={theme.tr}>{this.renderHeaders()}</tr>
                </thead>
                <tbody>{this.renderTableRows()}</tbody>
            </table>
        );
    }
}
