import { TreeNode } from '../models/tree.types';
import {
    FETCH_LICENSES,
    FETCH_LICENSES_OWNERS_INFO,
    FETCH_LICENSES_OWNERS_INFO_SUCCESS,
    FETCH_LICENSES_SUCCESS,
    OPEN_LICENSEOWNERS_EDITING_TAB,
    OPEN_LICENSES_TAB,
    SAVE_INFO_OF_LICENSEOWNERS,
    UPLOADING_LICENS_REQUEST,
    UPLOAD_LICENSE,
    DELETE_LICENSE,
} from '../actionsTypes/licenses.actionTypes';
import {
    TDeleteLicense,
    TFetchLicensesAction,
    TFetchLicensesOwnersInfoAction,
    TFetchLicensesOwnersInfoSuccessAction,
    TFetchLicensesOwnersInfoSuccessPayload,
    TLicenseFetchSuccess,
    TLicenseFetchSuccessPayload,
    TOpenLicenseOwnersEditingTabAction,
    TOpenLicenseOwnersEditingTabPayload,
    TOpenLicensesTabAction,
    TSaveInfoOfLicenseOwnersAction,
    TSaveInfoOfLicenseOwnersPayload,
    TUploadingLicenseRequestAction,
    TUploadingLicenseRequestPayload,
    TUploadLicense,
    TUploadLicensePayload,
} from './licenses.actions.types';

export const fetchOwnerInfo: (serverId: string) => TFetchLicensesOwnersInfoAction = (serverId) => ({
    type: FETCH_LICENSES_OWNERS_INFO,
    payload: { serverId },
});

export const fetchedOwnerInfoSuccess: (
    payload: TFetchLicensesOwnersInfoSuccessPayload,
) => TFetchLicensesOwnersInfoSuccessAction = (payload) => ({
    type: FETCH_LICENSES_OWNERS_INFO_SUCCESS,
    payload,
});

export const saveInfoOfLicenseOwners: (payload: TSaveInfoOfLicenseOwnersPayload) => TSaveInfoOfLicenseOwnersAction = (
    payload,
) => ({
    type: SAVE_INFO_OF_LICENSEOWNERS,
    payload,
});

export const openLicenseOwnersEditingTab: (
    payload: TOpenLicenseOwnersEditingTabPayload,
) => TOpenLicenseOwnersEditingTabAction = (payload) => ({
    type: OPEN_LICENSEOWNERS_EDITING_TAB,
    payload,
});

export const fetchLicenses = (serverId: string): TFetchLicensesAction => ({
    type: FETCH_LICENSES,
    payload: {
        serverId,
    },
});

export const openLicensesTab: (node: TreeNode) => TOpenLicensesTabAction = (node) => ({
    type: OPEN_LICENSES_TAB,
    payload: { node },
});

export const fetchLicenseByTreeNodeSuccess: (payload: TLicenseFetchSuccessPayload) => TLicenseFetchSuccess = (
    payload,
) => ({
    type: FETCH_LICENSES_SUCCESS,
    payload,
});

export const uploadingLicenseRequest: (payload: TUploadingLicenseRequestPayload) => TUploadingLicenseRequestAction = (
    payload,
) => ({
    type: UPLOADING_LICENS_REQUEST,
    payload,
});

export const uploadLicense = (payload: TUploadLicensePayload): TUploadLicense => ({
    type: UPLOAD_LICENSE,
    payload,
});

export const deleteLicense = (licenseId: string): TDeleteLicense => ({
    type: DELETE_LICENSE,
    licenseId,
});
