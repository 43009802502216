import { TReducer } from '../utils/types';
import {
    ALL_PERMISSIONS_FOR_NODE_CLEAR_DATA,
    ALL_PERMISSIONS_FOR_NODE_DATA,
    ALL_PERMISSIONS_FOR_NODE_FAILURE,
    ALL_PERMISSIONS_FOR_NODE_REQUEST,
} from '../actionsTypes/allPermissions.actionTypes';
import { TAllPermissionsDialogState } from './allPermissions.reducer.types';

const initial: TAllPermissionsDialogState = {
    serverId: null,
    loading: false,
    data: null,
    error: null,
};

export const allPermissionsReducer: TReducer<TAllPermissionsDialogState> = (state = initial, action) => {
    switch (action.type) {
        case ALL_PERMISSIONS_FOR_NODE_REQUEST: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case ALL_PERMISSIONS_FOR_NODE_DATA: {
            const { serverId, data } = action.payload;

            return {
                loading: false,
                error: null,
                serverId,
                data,
            };
        }

        case ALL_PERMISSIONS_FOR_NODE_CLEAR_DATA: {
            return initial;
        }

        case ALL_PERMISSIONS_FOR_NODE_FAILURE: {
            const { error } = action.payload;

            return {
                ...state,
                error,
                loading: false,
            };
        }

        default:
            return state;
    }
};
