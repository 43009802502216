import React, { CSSProperties, FC } from 'react';
import dndStyle from './DndSortTable.scss';
import {
    DragDropContext,
    DraggableProvided,
    DraggableProvidedDraggableProps,
    DraggableRubric,
    DraggableStateSnapshot,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import cx from 'classnames';
import { IRowCache } from './SymbolTab.types';

type TDndWrapper = {
    onDragEnd: (prop: DropResult) => void;
    rowCache: IRowCache;
    children: React.ReactNode;
    getItemStyle: (
        style: CSSProperties,
        isDragging: boolean,
        draggableStyle: DraggableProvidedDraggableProps['style'],
    ) => CSSProperties;
};

export const DndWrapper: FC<TDndWrapper> = (props) => {
    const { onDragEnd, children, rowCache, getItemStyle } = props;

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
                droppableId="droppable"
                mode="virtual"
                renderClone={(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot,
                    rubric: DraggableRubric,
                ) => {
                    const virtualizedRowProps = rowCache[rubric.source.index];

                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={virtualizedRowProps.key}
                            style={getItemStyle({ margin: 0 }, snapshot.isDragging, provided.draggableProps.style)}
                            className={cx(virtualizedRowProps.className, snapshot.isDragging && dndStyle.dndRow)}
                        >
                            {virtualizedRowProps.columns}
                        </div>
                    );
                }}
            >
                {(provided) => (
                    <div className={dndStyle.wrapper} {...provided.droppableProps} ref={provided.innerRef}>
                        {children}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
