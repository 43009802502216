import { RELOCK, UNLOCK } from '../actionsTypes/lock.actionTypes';
import { NodeId, NodeTypeEnum } from '../serverapi/api';
import { TRelockAction, TUnlockAction } from './lock.actions.types';

export const relock = (nodeId: NodeId, type: NodeTypeEnum): TRelockAction => ({
    type: RELOCK,
    payload: { type, nodeId },
});

export const unlock = (nodeId: NodeId, type: NodeTypeEnum): TUnlockAction => ({
    type: UNLOCK,
    payload: { type, nodeId },
});
