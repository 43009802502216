import React from 'react';
import themes from './ObjectParametersSelector.scss';
import { InputNumber } from 'antd';

type TAttributeImageSizeSelectorProps = {
    parameterName?: string;
    editingAllowed?: boolean | undefined;
    onChange: (val: number) => void;
    maxValue?: number;
    minValue?: number;
    value: number | undefined;
    onlyInteger?: boolean;
    dataTest?: string;
};

export const ObjectParametersSelector = ({
    parameterName,
    onChange,
    editingAllowed,
    maxValue,
    minValue,
    value,
    onlyInteger,
    dataTest
}: TAttributeImageSizeSelectorProps) => {
    const onChangeHandler = (actualValue) => {
        onChange && onChange(actualValue);
    };

    return (
        <div className={themes.container} data-test={dataTest}>
            <div> {parameterName}</div>
            <InputNumber
                disabled={!editingAllowed}
                style={{ width: '60px', marginLeft: '5px' }}
                value={ onlyInteger ? Math.round(value || 0) : value || 0 }
                defaultValue={0}
                min={minValue || 0}
                max={maxValue || 500}
                onChange={(actualValue) => onChangeHandler(actualValue)}
            />
        </div>
    );
};
