import type { MxCell } from '../../mxgraph/mxgraph';
import { SymbolType } from '../../models/Symbols.constants';
import { MxConstants, MxUtils } from '../../mxgraph/mxgraph';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { ModelTypes } from '@/models/ModelTypes';

const removeStylename = (style: string, stylename: string): string => {
    const result: string[] = [];

    if (style !== null) {
        const tokens = style.split(';');

        for (let i = 0; i < tokens.length; i++) {
            if (tokens[i].indexOf(stylename) === -1) {
                result.push(tokens[i]);
            }
        }
    }

    return result.join(';');
};

export class BpmMxEditorBLLService {
    static setLabelStatus(style: string, showLabel: boolean): string {
        let labelStyle = style;
        labelStyle = MxUtils.setStyle(labelStyle, MxConstants.STYLE_EDITABLE, Number(showLabel));
        labelStyle = MxUtils.setStyle(labelStyle, MxConstants.STYLE_NOLABEL, Number(!showLabel));

        return labelStyle;
    }

    static unsetLabelStatus(style: string): string {
        let labelStyle = style;
        labelStyle = removeStylename(labelStyle, MxConstants.STYLE_EDITABLE);
        labelStyle = removeStylename(labelStyle, MxConstants.STYLE_NOLABEL);

        return labelStyle;
    }
}

export function hasOnlyComments(selectedCells: MxCell[] | undefined): boolean {
    if (!selectedCells) return false;

    return selectedCells.every((cell) => cell.value?.type === SymbolType.COMMENT);
}

/**
 *
 * Возвращает все ячейки, доступные для копирования
 *
 * @param graph
 * @param cells
 *
 */
export function getCopyableCells(graph: BPMMxGraph, cells: MxCell[]): MxCell[] {
    const isWhiteBoard = graph?.modelType?.id === ModelTypes.MIND_MAP;
    const cellsId: string[] = cells.map((cell) => cell.id);

    const isEdgeBinded = (cell: MxCell): boolean => {
        let isBind: boolean = true;

        if (cell?.getValue()?.type === 'edge') {
            isBind =
                cell?.target?.id &&
                cell?.source?.id &&
                cellsId.includes(cell.target.id) &&
                cellsId.includes(cell.source.id);
        }

        return isBind;
    };

    const copyableCells: Record<string, MxCell> = {};

    cells.forEach((cell: MxCell) => {
        const cellValue = cell?.getValue();

        if (
            (typeof cellValue === 'string' && !isWhiteBoard) ||
            !isEdgeBinded(cell) ||
            cellValue?.type === SymbolType.COMMENT
        ) {
            return;
        }

        const symbolRootCell = ComplexSymbolManager.getComplexSymbolRootCell(cell);

        if (!symbolRootCell) {
            copyableCells[cell.id] = cell;

            return;
        }

        const { objectDefinitionId: id } = symbolRootCell.getValue();
        const copyableCell: MxCell | null = ComplexSymbolManager.getCopyableCell(cell);

        if (!copyableCell || !id) {
            return;
        }

        copyableCells[copyableCell.id] = copyableCell;
    });

    return Object.values(copyableCells);
}

/**
 *
 * Метод обхода всех ячеек от родительских до дочерних
 * Погружение прерывается, если cb возвращает false
 *
 * @param cells
 *
 */
export function cellsTreeWalkerDownWithValidation(cells: MxCell[] = [], cb: (cell: MxCell) => boolean) {
    cells.forEach((cell) => {
        const result = cb && cb(cell);

        if (cell.children && result) {
            cellsTreeWalkerDownWithValidation(cell.children, cb);
        }
    });
}

/**
 *
 * Возвращает все ячейки вместе с дочерними
 *
 * @param cells
 *
 */
export function getCellsWithChildren(cells: MxCell[]) {
    const result: Record<string, MxCell> = {};

    cells.forEach((cell) => (result[cell.id] = cell));

    function grabHandler(cell: MxCell) {
        const copyableCell = ComplexSymbolManager.getCopyableCell(cell);

        if (!copyableCell) {
            return false;
        }

        result[copyableCell.id] = copyableCell;

        return true;
    }

    cellsTreeWalkerDownWithValidation(cells, grabHandler);

    return Object.values(result);
}
