import { takeEvery, select, put, call } from 'redux-saga/effects';
import {
    TOpenAccessPermissionOwnersEditingTabAction,
    TFetchAccessPermissionsOwnersInfoAction,
    TSaveInfoOfAccessOwnersAction,
    fetchAccessOwnerInfo,
    fetchedAccessPermissionInfoSuccess,
} from '../actions/accessPermissions.actions.types';
import {
    OPEN_ACCESS_PERMISSION_OWNERS_EDITING_TAB,
    FETCH_ACCESS_PERMISSION_OWNERS_INFO,
    SAVE_INFO_OF_ACCESS_OWNERS,
} from '../actionsTypes/accessPermissions.actionTypes';
import { defaultWorkspaceTabActions } from '../models/tab';
import {
    TWorkspaceTab,
    IWorkspaceAccessPermissionOwnersEditingTabItemParams
} from '../models/tab.types';
import { MetaDataSelectors } from '../selectors/admintools.selectors';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { workspaceAddTab } from '../actions/tabs.actions';
import { EditorMode } from '../models/editorMode';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import userAccessTypes from '../models/userAccessRightTypes';
import { TServerEntity } from '../models/entities.types';
import { UserDTO, GroupDTO, UserDTOAccessesEnum } from '../serverapi/api';
import userAccessesMessages from '../models/userAccesses.messages';
import { AdminToolsUtils } from '../utils/adminToolsUtils';
import { TAccessPermissionOwnersMap } from '../reducers/accessPermissions.reducer.types';
import { userService } from '../services/dao/UsersDAOService';
import { groupService } from '../services/GroupService';
import { LocalesService } from '../services/LocalesService';

function* handleOpenAccessPermissionOwnersEditingTab(params: TOpenAccessPermissionOwnersEditingTabAction) {
    const {
        payload: { accessType },
    } = params;
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    const intl = LocalesService.useIntl();

    const contentLoadingPageTab: TWorkspaceTab = {
        title: intl.formatMessage(userAccessesMessages[accessType]),
        nodeId: AdminToolsUtils.createNodeId(serverId, WorkSpaceTabTypes.ACCESS_PERMISSION_OWNERS_EDITING_TAB),
        type: WorkSpaceTabTypes.ADMINTOOLS_TAB,
        mode: EditorMode.Read,
        params: {
            closable: false,
            serverId,
            accessType,
        } as IWorkspaceAccessPermissionOwnersEditingTabItemParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* handleFetchAccessesOwnersInfo({ payload: { serverId, accesses } }: TFetchAccessPermissionsOwnersInfoAction) {
    const accessTypes = accesses || (Object.keys(userAccessTypes).map((k) => k) as UserDTOAccessesEnum[]);

    if (accessTypes.length) {
        const ownersMap: TAccessPermissionOwnersMap = new Map();

        const userApiResponse: UserDTO[][] = yield call(() =>
            Promise.all(accessTypes.map((access) => userService().getByAccess({ access, serverId }))),
        );
        const groupApiResponse: GroupDTO[][] = yield call(() =>
            Promise.all(accessTypes.map((access) => groupService().getByAccess({ access, serverId }))),
        );

        accessTypes.map((id, index) =>
            ownersMap.set(id, { users: userApiResponse[index], groups: groupApiResponse[index] }),
        );

        if (ownersMap.size) {
            yield put(fetchedAccessPermissionInfoSuccess({ serverId, owners: ownersMap }));
        }
    }
}

function* handleSaveInfoOfAccessOwners(params: TSaveInfoOfAccessOwnersAction) {
    const {
        payload: { serverId, ids, accessType: access, action },
    } = params;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    if (action === 'ADD') {
        yield call(() => server.api.principal.addAccess({ access, body: { ids } }));
    } else {
        for (let index = 0; index < ids.length; index++) {
            yield call(() => server.api.principal.deleteAccess({ access, id: ids[index] }));
        }
    }
    yield put(fetchAccessOwnerInfo(serverId, [access]));
}

export function* accesPermissionSaga() {
    yield takeEvery(FETCH_ACCESS_PERMISSION_OWNERS_INFO, handleFetchAccessesOwnersInfo);
    yield takeEvery(OPEN_ACCESS_PERMISSION_OWNERS_EDITING_TAB, handleOpenAccessPermissionOwnersEditingTab);
    yield takeEvery(SAVE_INFO_OF_ACCESS_OWNERS, handleSaveInfoOfAccessOwners);
}
