import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { LicenseDTO } from '../../../serverapi/api';
import messages from '../messages/licensestool.messages';

export const checkCount = (str?: string | number): boolean => !!str && str !== '0';

export const useLicensesDataSource = (licenses: LicenseDTO[]) => {
    const intl = useIntl();
    const locale = useSelector(getCurrentLocale);

    return licenses.map((item: LicenseDTO) => {
        return {
            key: item.id,
            loadDate: item.loadDate ? new Date(item.loadDate).toLocaleString(locale) : '',
            companyName: item.companyName,
            licenseTypes:
                ((checkCount(item.modelEditorsCount) &&
                    `${intl.formatMessage(messages.modelEditorsCount)} (${item.modelEditorsCount}),\n`) ||
                    '') +
                ((checkCount(item.modelLiteEditorsCount) &&
                    `${intl.formatMessage(messages.modelLiteEditorsCount)} (${item.modelLiteEditorsCount}),\n`) ||
                    '') +
                ((checkCount(item.modelReadersCount) &&
                    `${intl.formatMessage(messages.modelReadersCount)} (${item.modelReadersCount}),\n`) ||
                    '') +
                ((checkCount(item.modelEditorsCompetitiveCount) &&
                    `${intl.formatMessage(messages.modelEditorsCompetitiveCount)} (${
                        item.modelEditorsCompetitiveCount
                    }),\n`) ||
                    '') +
                ((checkCount(item.modelLiteEditorsCompetitiveCount) &&
                    `${intl.formatMessage(messages.modelLiteEditorsCompetitiveCount)} (${
                        item.modelLiteEditorsCompetitiveCount
                    }),\n`) ||
                    '') +
                ((checkCount(item.modelReadersCompetitiveCount) &&
                    `${intl.formatMessage(messages.modelReadersCompetitiveCount)} (${
                        item.modelReadersCompetitiveCount
                    })`) ||
                    ''),
            licenseOrTech: intl.formatMessage(messages.isALicence),
            serialNumber: item.serialNumber,
            id: item.id || '',
            beginDate: item.beginDate ? new Date(item.beginDate).toLocaleString(locale) : '',
            endDate: item.endDate ? new Date(item.endDate).toLocaleString(locale) : '',
            daysLeft: item.daysLeft,
        };
    });
};
