import { Button } from 'antd';
import * as React from 'react';
import theme from '../items/MenuItem.scss';
import messages from '../../messages/CommonToolbar.messages';
import classnames from 'classnames';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { injectIntl } from 'react-intl';
import icImage from '../../../../resources/icons/icImage.svg';
import icText from '../../../../resources/icons/icText.svg';
import { ShapeSelect } from '../ShapeSelect/ShapeSelect.component';
import { TGraphPasteToolbarProps } from './GraphPasteToolbar.types';

const GraphPasteToolbarComponent = (props: TGraphPasteToolbarProps) => {
    const { isActiveScheme, intl, compact, openImageDialog, onPasteShapeAction, onPasteTextAction } = props;
    const className = classnames(theme.container, {
        [theme.container_compact]: compact,
    });

    return (
        <div className={className}>
            <div className={theme.group}>
                <div className={theme.groupRow}>
                    <span
                        className={classnames(theme.buttonWrapper, {
                            [theme.buttonDisabled]: !isActiveScheme,
                        })}
                    >
                        <Button
                            data-test="general-panel-paste_image"
                            onClick={() => {
                                openImageDialog();
                            }}
                            className={theme.buttonTextColor}
                            disabled={!isActiveScheme}
                        >
                            <Icon spriteSymbol={icImage} />
                            <div className={theme.toolbarLabel}>{intl.formatMessage(messages.labelDownloadImage)}</div>
                        </Button>
                    </span>
                </div>
            </div>
            <div className={theme.divider} />
            <div className={theme.group}>
                <div className={theme.groupRow}>
                    <span
                        className={classnames(theme.buttonWrapper, {
                            [theme.buttonDisabled]: !isActiveScheme,
                        })}
                    >
                        <Button
                            data-test="general-panel-paste_text"
                            onClick={() => onPasteTextAction()}
                            className={theme.buttonTextColor}
                            disabled={!isActiveScheme}
                        >
                            <Icon spriteSymbol={icText} />
                            <div className={theme.toolbarLabel}>{intl.formatMessage(messages.labelPasteText)}</div>
                        </Button>
                    </span>
                </div>
            </div>
            <div className={theme.divider} />
            <div className={theme.group}>
                <div className={theme.groupRow}>
                    <div className={theme.tooltipContainer} data-test="general-panel-paste_shapes">
                        <ShapeSelect onSelect={onPasteShapeAction} isActiveScheme={isActiveScheme} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const GraphPasteToolbarWithIntl = injectIntl(GraphPasteToolbarComponent);

export { GraphPasteToolbarWithIntl as GraphPasteToolbar };
