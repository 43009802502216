import { SymbolType } from '../models/Symbols.constants';
import { BPMMxGraph } from './bpmgraph';
import { MxUtils, MxCell, MxCellEditor, MxCellState, MxEvent, MxRectangle } from './mxgraph';

export const CLICKED_MORE_EVENT: string = 'CLICKED_MORE_EVENT';

export class BPMMxCellEditor extends MxCellEditor {
    moreButton: any = null; // tslint:disable-line:no-any
    bounds: MxRectangle | null = null;
    clearOnChange: boolean = false;
    trigger: MouseEvent | null = null;

    init() {
        super.init();
        this.moreButton = document.createElement('div');
    }

    getCurrentValue() {
        return MxUtils.extractTextWithWhitespace(this.textarea.childNodes);
    }

    isContentEditing() {
        const state = this.graph.view.getState(this.editingCell);

        return state != null;
    }

    startEditing(cell: MxCell, trigger: MouseEvent) {
        super.startEditing(cell, trigger);
        const textarea = this.textarea as HTMLDivElement;
        let prevValue = textarea.innerHTML;
        textarea.oninput = () => {
            const value = textarea.innerHTML;
            const parser = new DOMParser();
            const body = parser.parseFromString(value, 'text/html');
            const elem = body.getElementsByTagName('img');

            if (elem.length) {
                textarea.innerHTML = prevValue;
            } else {
                prevValue = value;
            }
        };

        const value = cell.getValue();
        if (!value?.isPSDCell) {
            this.graph.container.appendChild(this.moreButton);
        }
    }

    resize() {
        super.resize();

        const graph: BPMMxGraph = this.graph as BPMMxGraph;
        if (graph.modelType && graph.modelType.id === 'bpmn2') {
            if (this.textarea != null) {
                if (this.editingCell && this.editingCell.value && this.editingCell.value.style === 'headVertical') {
                    this.textarea.style.width = `${this.bounds ? this.bounds.height * 2 : 150}px`;
                } else {
                    this.textarea.style.width = `${this.bounds ? this.bounds.width * 2 : 150}px`;
                }
                this.textarea.style.wordBreak = 'normal';
                this.textarea.style.whiteSpace = 'normal';
                this.textarea.style.wordWrap = 'break-word';
            }
        } else {
            if (this.textarea != null) {
                const { scale } = this.graph.getView();
                this.textarea.style!.width = `${Math.round(this.bounds!.width / scale) - 36}px`;
                this.textarea.style.wordBreak = 'break-all';
                this.textarea.style.whiteSpace = 'normal';
                const state: MxCellState = this.graph.getView().getState(this.editingCell);
                const isEdge = this.graph.getModel().isEdge(state.cell);
                if (isEdge) {
                    const m = MxUtils.getAlignmentAsPoint(state.style.align, state.style.valign, isEdge);

                    MxUtils.setPrefixedStyle(
                        this.textarea.style,
                        'transform',
                        `scale(${scale},${scale})${` translate(${m.x * 100}%,${m.y * 100}%)`}`,
                    );
                    if (this.bounds) {
                        this.textarea.style.left = `${Math.max(
                            0,
                            Math.round(this.bounds.x - m.x * (this.bounds.width - 2)) + 1,
                        )}px`;
                    }
                }
            }
            if (this.moreButton != null && this.textarea != null) {
                this.moreButton.style.left = `${Math.round(this.bounds!.x) + this.bounds!.width - 20}px`;
                this.moreButton.style.top = `${this.textarea.offsetTop - 8}px`;
            } else {
                try {
                    // иногда в этом месте приложение падает, не ясно что с этим делать
                    this.init();
                } catch (e) {
                    console.error('resize fail', e);
                }
            }
        }
    }

    stopEditing(cancel: boolean = false) {
        if (this.editingCell !== null) {
            if (this.textNode !== null) {
                this.textNode.style.visibility = 'visible';
                this.textNode = null;
            }

            const state = !cancel ? this.graph.view.getState(this.editingCell) : null;

            const initial = this.initialValue;
            this.initialValue = null;
            this.editingCell = null;
            this.trigger = null;
            this.bounds = null;
            this.textarea?.blur();

            if (this.textarea?.parentNode) {
                this.textarea.parentNode.removeChild(this.textarea);
            }
            if (this.moreButton?.parentNode) {
                this.moreButton.parentNode.removeChild(this.moreButton);
            }

            if (this.clearOnChange && this.textarea && this.textarea.innerHTML === this.getEmptyLabelText()) {
                this.textarea.innerHTML = '';
                this.clearOnChange = false;
            }

            if (state && this.textarea && this.textarea.innerHTML !== initial) {
                this.prepareTextarea();
                let value = this.getCurrentValue();
                value = !value ? '' : value.trim();
                // для текстового блока запрещено пустое имя, у связи и фигуры имя может быть пустым
                if (value || state.cell?.value?.type !== SymbolType.LABEL) {
                    this.applyValue(state, value);
                }
            }

            // Forces new instance on next edit for undo history reset
            MxEvent.release(this.textarea);
            this.textarea = null;
            this.moreButton = null;
        }
    }
}
