import { getStore } from '../store';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TRootState } from '../reducers/root.reducer.types';

export namespace WebsocketUtils {
    export const getProto = (serverId: string): string => {
        const store = getStore();
        const serverEntity: TServerEntity = ServerSelectors.server(serverId)(<TRootState>store.getState());
        if (!serverEntity) {
            console.error(`server ${serverId} not found`);
        } else if (!serverEntity.url) {
            console.error(`server ${serverId} contain empty url`);
        } else if (serverEntity.url.startsWith('https://')) {
            return 'wss';
        }

        return 'ws';
    };
    export const getWSServerURL = (serverId: string, host: string): string => {
        const protocol = WebsocketUtils.getProto(serverId);
        const address: string = `${protocol}://${host}/api/ws/notification`;
        return new URL(address).toString();
    };
}
