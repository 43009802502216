import { KanbanCardType } from '../../../serverapi/api';
import { TWorkspaceTabStoreKanbanCardActions } from '../../../actions/workspaceTab/editKanbanCardWorkspaceTab.actions.types';
import {
    ADD_KANBAN_CARD_ATTRIBUTE,
    ADD_STORE_EDIT_KANBAN_CARD_TYPE_WORKSPACE_TAB,
    CHANGE_KANBAN_CARD_ATTRIBUTE,
    CHANGE_KANBAN_CARD_GRID_SETTINGS,
    CHANGE_KANBAN_CARD_SECTOR_SETTINGS,
    CHANGE_KANBAN_CARD_TYPE_ID,
    CHANGE_KANBAN_CARD_TYPE_NAME,
    DELETE_KANBAN_CARD_ATTRIBUTES,
    MOVE_KANBAN_CARD_ATTRIBUTE,
} from '../../../actionsTypes/workspaceTab/editKanbanCardTypeWorkspaceTab.actionTypes';
import { TEditKanbanCardTypeWorkspaceTabState } from './editKanbanCardTypeWorkspaceTab.reducers.types';

const initial: TEditKanbanCardTypeWorkspaceTabState = {} as TEditKanbanCardTypeWorkspaceTabState;

const setNewData = (
    state: TEditKanbanCardTypeWorkspaceTabState,
    presetId: string,
    kanbanCardTypeId: string,
    kanbanCardType: KanbanCardType,
): TEditKanbanCardTypeWorkspaceTabState => {
    return {
        ...state,
        [presetId]: {
            ...state[presetId],
            [kanbanCardTypeId]: kanbanCardType,
        },
    };
};

const getKanbanCardType = (
    state: TEditKanbanCardTypeWorkspaceTabState,
    presetId: string,
    kanbanCardTypeId: string,
): KanbanCardType => {
    return { ...state[presetId][kanbanCardTypeId] };
};

export const editKanbanCardTypeWorkspaceTabReducer = (
    state = initial,
    action: TWorkspaceTabStoreKanbanCardActions,
): TEditKanbanCardTypeWorkspaceTabState => {
    switch (action.type) {
        case ADD_STORE_EDIT_KANBAN_CARD_TYPE_WORKSPACE_TAB: {
            const { kanbanCardType, presetId } = action.payload;

            return setNewData(state, presetId, kanbanCardType.id, kanbanCardType);
        }

        case CHANGE_KANBAN_CARD_TYPE_NAME: {
            const { presetId, kanbanCardTypeId, multilingualName } = action.payload;

            const kanbanCardType: KanbanCardType = getKanbanCardType(state, presetId, kanbanCardTypeId);

            const changedKanbanCardType: KanbanCardType = { ...kanbanCardType, multilingualName };

            return setNewData(state, presetId, kanbanCardTypeId, changedKanbanCardType);
        }

        case CHANGE_KANBAN_CARD_TYPE_ID: {
            const { presetId, kanbanCardTypeId, id } = action.payload;

            const kanbanCardType: KanbanCardType = getKanbanCardType(state, presetId, kanbanCardTypeId);

            const changedKanbanCardType: KanbanCardType = { ...kanbanCardType, id };

            return setNewData(state, presetId, kanbanCardTypeId, changedKanbanCardType);
        }

        case CHANGE_KANBAN_CARD_GRID_SETTINGS: {
            const { presetId, kanbanCardTypeId, gridSettings } = action.payload;
            const kanbanCardType: KanbanCardType = getKanbanCardType(state, presetId, kanbanCardTypeId);
            const changedKanbanCardType: KanbanCardType = {
                ...kanbanCardType,
                gridSettings,
            };

            return setNewData(state, presetId, kanbanCardTypeId, changedKanbanCardType);
        }

        case CHANGE_KANBAN_CARD_SECTOR_SETTINGS: {
            const { presetId, kanbanCardTypeId, sectorSettings } = action.payload;
            const kanbanCardType: KanbanCardType = getKanbanCardType(state, presetId, kanbanCardTypeId);
            const changedKanbanCardType: KanbanCardType = {
                ...kanbanCardType,
                sectorSettings,
            };

            return setNewData(state, presetId, kanbanCardTypeId, changedKanbanCardType);
        }

        case CHANGE_KANBAN_CARD_ATTRIBUTE: {
            const { presetId, kanbanCardTypeId, attribute } = action.payload;
            const kanbanCardType: KanbanCardType = getKanbanCardType(state, presetId, kanbanCardTypeId);
            const changedKanbanCardType: KanbanCardType = {
                ...kanbanCardType,
                sectorAttributes: kanbanCardType.sectorAttributes.map((attr) => {
                    if (attr.id === attribute.id) return attribute;

                    return attr;
                }),
            };

            return setNewData(state, presetId, kanbanCardTypeId, changedKanbanCardType);
        }

        case MOVE_KANBAN_CARD_ATTRIBUTE: {
            const { presetId, kanbanCardTypeId, attributeId, isDirectionUp } = action.payload;
            const kanbanCardType: KanbanCardType = getKanbanCardType(state, presetId, kanbanCardTypeId);

            const changedAttributes = [...kanbanCardType.sectorAttributes];
            const currentIndex = changedAttributes.findIndex((attr) => attr.id === attributeId);
            const newIndex = isDirectionUp ? currentIndex - 1 : currentIndex + 1;

            if (newIndex >= changedAttributes.length || newIndex < 0) return state;

            [changedAttributes[currentIndex], changedAttributes[newIndex]] = [
                changedAttributes[newIndex],
                changedAttributes[currentIndex],
            ];

            const changedKanbanCardType: KanbanCardType = {
                ...kanbanCardType,
                sectorAttributes: changedAttributes,
            };

            return setNewData(state, presetId, kanbanCardTypeId, changedKanbanCardType);
        }

        case DELETE_KANBAN_CARD_ATTRIBUTES: {
            const { presetId, kanbanCardTypeId, attributeIds } = action.payload;
            const kanbanCardType: KanbanCardType = getKanbanCardType(state, presetId, kanbanCardTypeId);
            const changedAttributes = kanbanCardType.sectorAttributes.filter((attr) => !attributeIds.includes(attr.id));
            const changedKanbanCardType: KanbanCardType = {
                ...kanbanCardType,
                sectorAttributes: changedAttributes,
            };

            return setNewData(state, presetId, kanbanCardTypeId, changedKanbanCardType);
        }

        case ADD_KANBAN_CARD_ATTRIBUTE: {
            const { presetId, kanbanCardTypeId, attribute, index } = action.payload;
            const kanbanCardType: KanbanCardType = getKanbanCardType(state, presetId, kanbanCardTypeId);

            const changedAttributes = kanbanCardType.sectorAttributes.slice();
            changedAttributes.splice(index, 0, attribute);

            const changedKanbanCardType: KanbanCardType = {
                ...kanbanCardType,
                sectorAttributes: changedAttributes,
            };

            return setNewData(state, presetId, kanbanCardTypeId, changedKanbanCardType);
        }

        default: {
            return state;
        }
    }
};
