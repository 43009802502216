import { MxPoint, MxCell } from 'MxGraph';
import { createFrameFromString } from '../utils';
import { ComplexSymbol } from './ComplexSymbol.class';
import { MxGeometry } from '@/mxgraph/mxgraph';

abstract class MetaInfoComplexSymbol extends ComplexSymbol {
    abstract template;

    public addToGraph() {
        const metaInfoTemplate = this.rootCellValue?.metaInfo;
        const parent = this.rootCellValue?.parent || this.graph.getDefaultParent();

        const renderConfiguration = metaInfoTemplate
            ? {
                  template: metaInfoTemplate,
                  position: new MxPoint(0, 0),
                  parent,
              }
            : {
                  template: this.template,
                  position: new MxPoint(this.rootCellValue.x || 0, this.rootCellValue.y || 0),
                  parent,
              };

        const root = this.render(renderConfiguration);
        this.afterCreate(root);

        return root;
    }

    render({ template, position, parent }) {
        const clonedModelCells =
            typeof template === 'function'
                ? template()
                : this.backwardCompabilityDecorator(createFrameFromString(template));

        clonedModelCells[0]?.setId(this.rootCellValue.id);

        const [cells] = this.graph.importCellsWithIds(clonedModelCells, position, parent);
        const [root] = cells;

        const geo = new MxGeometry(
            this.rootCellValue.x || 0,
            this.rootCellValue.y || 0,
            root.getGeometry().width,
            root.getGeometry().height,
        );
        root.setGeometry(geo);

        return root;
    }

    backwardCompabilityDecorator(cells: MxCell[]) {
        return cells;
    }
}

export default MetaInfoComplexSymbol;
