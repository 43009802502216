import { MxGeometry } from '../mxgraph';

MxGeometry.prototype.translate = function (dx, dy, fixStartPoint?) {
    // Translates the geometry
    if (!this.relative) {
        this.x += dx;
        this.y += dy;
    }

    // Translates the source point
    if (this.sourcePoint != null) {
        this.sourcePoint.x += dx;
        this.sourcePoint.y += dy;
    }

    // Translates the target point
    if (this.targetPoint != null) {
        this.targetPoint.x += dx;
        this.targetPoint.y += dy;
    }

    // Translate the control points
    if (this.TRANSLATE_CONTROL_POINTS && this.points != null) {
        for (let i = 0; i < this.points.length; i++) {
            if (this.points[i] != null && !(fixStartPoint && i === 0)) {
                this.points[i].x += dx;
                this.points[i].y += dy;
            }
        }
    }
};

export { MxGeometry as SequenseGeometry };
