import { defineMessages } from 'react-intl';

export default defineMessages({
    id: {
        id: 'FolderTab.id',
        defaultMessage: 'ID',
    },
    allowed: {
        id: 'FolderTab.allowed',
        defaultMessage: 'Разрешено',
    },
    allowAll: {
        id: 'FolderTab.allowAll',
        defaultMessage: 'Разрешить все',
    },
    icon: {
        id: 'FolderTab.icon',
        defaultMessage: 'Иконка',
    },
    folderType: {
        id: 'FolderTab.folderType',
        defaultMessage: 'Тип папки',
    },
});
