import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { getDatabaseDialogState } from '../../../../selectors/databaseDialog.selectors';
import { importDialogCancel, importDialogApplyName } from '../../../../actions/importDialog.actions';
import { TImportRepositoryDialogProps } from '../../types/ImportDialog.types';
import { ImportRepositoryDialog } from '../../components/ImportRepositoryDialog/ImportRepositoryDialog.component';

const mapStateToProps = (state: TRootState, props: TImportRepositoryDialogProps) => {
    return {
        error: getDatabaseDialogState(state),
        name: props.name,
        itemId: props.itemId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (newName: String) => dispatch(importDialogApplyName(newName)),
    onCancel: () => dispatch(importDialogCancel()),
});

export const ImportRepositoryDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ImportRepositoryDialog);
