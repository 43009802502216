import React, { useState, useRef, useEffect } from 'react';
import { Input, InputProps, InputRef, Tooltip } from 'antd';

export const OverflowTipInput = (props: InputProps) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const inputRef = useRef<InputRef>(null);

    const checkOverflow = () => {
        const element = inputRef?.current?.input;
        if (element) {
            const isOverflowing = element.scrollWidth > element.clientWidth;
            setIsOverflowed(isOverflowing);
        }
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => window.removeEventListener('resize', checkOverflow);
    }, [props.value]);

    return (
        <Tooltip title={isOverflowed ? props.value : ''} placement="top">
            <Input
                {...props}
                ref={inputRef}
                onMouseEnter={() => {
                    if (!inputRef?.current?.input) return;

                    const { scrollWidth, clientWidth } = inputRef.current.input;

                    setIsOverflowed(scrollWidth > clientWidth);
                }}
            />
        </Tooltip>
    );
};
