export function getTextParts(searchText: string, children: string = ''): string[] {
    const lowerCaseChildren = children.toLowerCase();
    const lowerCaseSearchText = searchText.toLowerCase();

    return lowerCaseChildren
        .split(lowerCaseSearchText)
        .reduce(
            (acc, curr, index, array) => {
                const currentLength = [...acc, curr].join('').length;

                return [
                    ...acc,
                    children.slice(currentLength - curr.length, currentLength),
                    index !== array.length - 1 ? children.slice(currentLength, currentLength + searchText.length) : '',
                ];
            },
            [''],
        )
        .filter((el: string) => el !== '');
}
