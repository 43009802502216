import type { TNodePath } from '../modules/ObjectPropertiesDialog/components/ObjectPropertiesDialog.types';
import type { ObjectInstanceImpl } from '../models/bpm/bpm-model-impl';
import type { IModelNode } from '../models/bpm/bpm-model-impl.types';
import type { EdgeInstance, Node, NodeId } from '../serverapi/api';
import type { BPMMxGraph } from '@/mxgraph/bpmgraph';
import type { MxCell } from 'MxGraph';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { CustomMap } from '../utils/map';
import { setServerIdToNodeInterface } from '../utils/nodeId.utils';
import { apiBundle, ApiBundle } from './api/api-bundle';

interface INodeService {
    loadNodesFromServer(serverId: string, nodeIds: NodeId[]): Promise<Node[]>;

    loadNodeFromServer(nodeId: NodeId): Promise<Node | undefined>;

    loadPathFromNodeId(nodeId: NodeId): Promise<TNodePath | null>;

    findCellIdsByNodeId(nodeId: NodeId): CustomMap<NodeId, string[]>;

    findModelCellsByDefinitionId(definitionId: string, graph: BPMMxGraph): MxCell[];
}

class NodeServiceImpl implements INodeService {
    loadNodeFromServer(nodeId: NodeId): Promise<Node | undefined> {
        return this.getAPI(nodeId.serverId)
            .tree.getNode({ repositoryId: nodeId.repositoryId, nodeId: nodeId.id })
            .then((m) => {
                if (m.type === 'MODEL') {
                    // временный костыль, пока с бэка printable приходит всегда false
                    delete (m as IModelNode).printable;
                }
                setServerIdToNodeInterface(m, nodeId.serverId);

                return m;
            });
    }

    loadNodesFromServer(serverId: string, nodesId: NodeId[]): Promise<Node[]> {
        return this.getAPI(serverId)
            .tree.loadBulk({
                body: nodesId,
            })
            .then((nodes) => {
                nodes.forEach((n) => setServerIdToNodeInterface(n, serverId));

                return nodes;
            })
            .then((nodes) => nodes as Node[]);
    }

    loadPathFromNodeId = async (nodeId: NodeId): Promise<TNodePath | null> => {
        const { id, repositoryId, serverId } = nodeId;

        try {
            const response = await this.getAPI(serverId).tree.getNodePath({ id, repositoryId });

            return { nodeId, path: response.path };
        } catch (e) {
            console.error(e);

            return null;
        }
    };

    private getAPI(serverId: string): ApiBundle {
        try {
            const api = apiBundle(serverId);

            return api;
        } catch (e) {
            throw new Error(`Error in NodeService, no existing API for server with id=[${serverId}]`);
        }
    }

    findCellIdsByNodeId(nodeId: NodeId): CustomMap<NodeId, string[]> {
        const { repositoryId, id } = nodeId;
        const map = new CustomMap<NodeId, string[]>();

        instancesBPMMxGraphMap
            .values()
            .filter((graph) => repositoryId === graph.id.repositoryId)
            .forEach((graph) => {
                const cellIds: string[] = Object.values<MxCell>(graph.getModel().cells)
                    .filter((cell) =>
                        [
                            (cell.getValue() as ObjectInstanceImpl | undefined)?.objectDefinitionId,
                            (cell.getValue() as EdgeInstance | undefined)?.edgeDefinitionId,
                        ].includes(id),
                    )
                    .map((cell) => cell.getId());
                if (cellIds.length) map.set(graph.id, cellIds);
            });

        return map;
    }

    findModelCellsByDefinitionId(definitionId: string, graph: BPMMxGraph): MxCell[] {
        const cells = graph.getModel()?.cells;

        if (!cells) {
            return [];
        }

        return Object.values<MxCell>(cells).filter((cell) =>
            ['objectDefinitionId', 'edgeDefinitionId', 'imageId'].some(
                (key) => cell.getValue()?.[key] === definitionId,
            ),
        );
    }
}

let nodeServiceInstance: INodeService;

export function nodeService(): INodeService {
    if (!nodeServiceInstance) {
        nodeServiceInstance = new NodeServiceImpl();
    }

    return nodeServiceInstance;
}
