import { defineMessages } from 'react-intl';

export default defineMessages({
    connectToServerTitle: {
        id: 'ServerDialog.connectToServerTitle',
        defaultMessage: 'Подключиться к серверу',
    },
    changeConnectionTitle: {
        id: 'ServerDialog.changeConnectionTitle',
        defaultMessage: 'Изменить соединение',
    },
    newConnectionTitle: {
        id: 'ServerDialog.newConnectionTitle',
        defaultMessage: 'Новое соединение',
    },
    readConnectionTitle: {
        id: 'ServerDialog.readConnectionTitle',
        defaultMessage: 'Просмотр соединения',
    },
    splashScreenSelectServer: {
        id: 'ServerDialog.splashScreenSelectServer',
        defaultMessage: '1. Соединение',
    },
    selectServer: {
        id: 'ServerDialog.selectServer',
        defaultMessage: 'Выберите соединение',
    },
    editServerConnection: {
        id: 'ServerDialog.editServerConnection',
        defaultMessage: 'Введите адрес и имя сервера',
    },
    duplicateUrlError: {
        id: 'ServerDialog.duplicateUrlError',
        defaultMessage: 'Сервер с таким URL уже существует',
    },
    serverUrl: {
        id: 'ServerDialog.serverUrl',
        defaultMessage: 'Адрес сервера',
    },
    serverUrlPlaceholder: {
        id: 'ServerDialog.serverUrlPlaceholder',
        defaultMessage: 'Адрес нового сервера',
    },
    serverNamePlaceholder: {
        id: 'ServerDialog.serverNamePlaceholder',
        defaultMessage: 'Имя нового сервера',
    },
    serverName: {
        id: 'ServerDialog.serverName',
        defaultMessage: 'Имя сервера',
    },
    splashScreenUserHeader: {
        id: 'ServerDialog.splashScreenUserHeader',
        defaultMessage: '2. Данные пользователя',
    },
    userHeader: {
        id: 'ServerDialog.userHeader',
        defaultMessage: 'Введите данные пользователя',
    },
    userName: {
        id: 'ServerDialog.userName',
        defaultMessage: 'Имя пользователя',
    },
    password: {
        id: 'ServerDialog.password',
        defaultMessage: 'Пароль',
    },
    remember: {
        id: 'ServerDialog.remember',
        defaultMessage: 'Запомнить пароль',
    },
    cancel: {
        id: 'ServerDialog.cancel',
        defaultMessage: 'Отмена',
    },
    connect: {
        id: 'ServerDialog.connect',
        defaultMessage: 'Подключиться',
    },
    login: {
        id: 'ServerDialog.login',
        defaultMessage: 'Войти',
    },
    create: {
        id: 'ServerDialog.create',
        defaultMessage: 'Сохранить',
    },
    addServer: {
        id: 'ServerDialog.addServer',
        defaultMessage: 'Добавить сервер',
    },
    remove: {
        id: 'ServerDialog.remove',
        defaultMessage: 'Удалить',
    },
    serversNotFound: {
        id: 'ServerDialog.serversNotFound',
        defaultMessage: 'Список серверов пуст',
    },
    serverRequired: {
        id: 'ServerDialog.serverRequired',
        defaultMessage: 'Введите адрес сервера',
    },
    selectedServerRequired: {
        id: 'ServerDialog.selectedServerRequired',
        defaultMessage: 'Создайте сервер и выберите нужный',
    },
    serverNameRequired: {
        id: 'ServerDialog.serverNameRequired',
        defaultMessage: 'Введите имя сервера',
    },
    userNameRequired: {
        id: 'ServerDialog.userNameRequired',
        defaultMessage: 'Введите имя пользователя',
    },
    passwordRequired: {
        id: 'ServerDialog.passwordRequired',
        defaultMessage: 'Введите пароль',
    },
    userDataRequired: {
        id: 'ServerDialog.userDataRequired',
        defaultMessage: 'Введите имя пользователя и пароль',
    },
    requiredField: {
        id: 'ServerDialog.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
});
