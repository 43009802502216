import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import {
    ReportNode,
    NodeId,
    AttributeValue,
    ReportColumnData,
    AttributeType,
    PrincipalDescriptor,
    ReportDataFillingTypeEnum,
    SearchRequest,
    SearchResult,
    GeneralModel,
    ObjectDefinitionNode,
    EdgeDefinitionNode,
    FolderNode,
    Symbol,
} from '../../../serverapi/api';
import { TReportState } from '../reducers/report.reducer.types';
import { TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { TExtendedNode } from '@/selectors/types/nodesSelector.types';
import { mapNodeToSystemAttributeValues } from '@/mxgraph/overlays/BPMMxCellOverlay.utils';
import { storageValueToString } from '@/modules/ObjectPropertiesDialog/components/utils';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import modelTypeMessages from '@/models/modelType.messages';
import { systemAttributeTypes } from '@/utils/constants/systemAttributes.const';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import { TCurrentUserProfile } from '@/reducers/userProfile.reducer.types';
import { ProfileBllService } from '@/services/bll/ProfileBllService';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { TReportEditor } from '../reducers/reportEditor.reducer.types';
import { TNodeState } from '@/reducers/entities/TNodeState';
import { SearchElementTypeSelectors } from '@/modules/Search/selectors/searchElementType.selectors';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { CustomMap } from '@/utils/map';
import { TSymbolStateKey } from '@/reducers/symbol.reducer.types';

const reportStateSelector = (state: TRootState): TReportState => state.report;
const reportEditorStateSelector = (state: TRootState): TNodeState<TReportEditor> => state.reportEditor.editors;
const reportNodes = (state: TRootState): TNodeState<TExtendedNode> => state.reportEditor.nodes;
const getRootState = (state: TRootState) => state;
export namespace ReportSelectors {
    export const byId = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, ReportNode | undefined>(reportStateSelector, (state) =>
            state.get(reportNodeId),
        );

    export const byIds = (nodeIds: NodeId[]) =>
        createSelector<TRootState, TReportState, ReportNode[]>(reportStateSelector, (state) => {
            const reportArr: ReportNode[] = nodeIds
                .map((reportNodeId) => {
                    const report: ReportNode | undefined = state.get(reportNodeId);
                    return report;
                })
                .filter((element): element is ReportNode => !!element);

            return reportArr;
        });

    export const getAccessibleSystemAttributeTypes = (reportId: NodeId) =>
        createSelector<TRootState, ReportNode | undefined, AttributeType[]>(byId(reportId), (report) => {
            const reportColumns: ReportColumnData[] = report?.reportData?.columns || [];

            return systemAttributeTypes.filter(
                (attrType) =>
                    !reportColumns.some(
                        (column) => column.attributeType === 'SYSTEM' && column.attributeTypeId === attrType.id,
                    ),
            );
        });

    export const getUnusedAttributeTypes = (reportId: NodeId) =>
        createSelector<
            TRootState,
            ReportNode | undefined,
            TCurrentUserProfile | undefined,
            AttributeType[],
            AttributeType[]
        >(
            byId(reportId),
            UserProfileSelectors.selectUserProfileByNodeId(reportId),
            AttributeTypeSelectors.allInPresetByNodeIdSorted(reportId),
            (report, profile, attributeTypes) => {
                const reportColumns: ReportColumnData[] = report?.reportData?.columns || [];

                return attributeTypes.filter(
                    (attrType) =>
                        ProfileBllService.isAttributeViewable(profile, attrType.id) &&
                        !reportColumns.some(
                            (column) => column.attributeType === 'USER' && column.attributeTypeId === attrType.id,
                        ),
                );
            },
        );

    export const getAccessibleAttributeTypes = (reportId: NodeId) =>
        createSelector<TRootState, TCurrentUserProfile | undefined, AttributeType[], AttributeType[]>(
            UserProfileSelectors.selectUserProfileByNodeId(reportId),
            AttributeTypeSelectors.allInPresetByNodeIdSorted(reportId),
            (profile, attributeTypes) =>
                attributeTypes.filter((attrType) => ProfileBllService.isAttributeViewable(profile, attrType.id)),
        );

    export const getReportTableData = (reportId: NodeId, nodeIds: NodeId[], checedIds: string[]) =>
        createSelector<
            TRootState,
            TExtendedNode[],
            ReportNode | undefined,
            string,
            SearchResult[],
            string,
            AttributeType[],
            PrincipalDescriptor[],
            Locale,
            CustomMap<TSymbolStateKey, Symbol>,
            TTableData[]
        >(
            getNodesWithPresetIdByIds(nodeIds),
            byId(reportId),
            searchValue(reportId),
            searchResults(reportId),
            TreeSelectors.presetById(reportId),
            getAccessibleAttributeTypes(reportId),
            PrincipalsSelectors.getAll,
            getCurrentLocale,
            SymbolSelectors.all,
            (
                nodes,
                report,
                filterValue,
                searchResults,
                reportPresetId,
                accessibleAttributeTypes,
                principals,
                locale,
                symbols,
            ) => {
                const intl = LocalesService.useIntl();
                const reportColumns: ReportColumnData[] = report?.reportData?.columns || [];
                const fillingType: ReportDataFillingTypeEnum | undefined = report?.reportData?.fillingType;

                const searchedNodes: TExtendedNode[] = searchResults.map((searchResult) => {
                    return {
                        ...searchResult,
                        name: LocalesService.internationalStringToString(searchResult.multilingualName, locale),
                        type: searchResult.nodeType,
                        presetId: reportPresetId,
                    } as TExtendedNode;
                });

                const tableData: TTableData[] = (fillingType === 'AUTO' ? searchedNodes : nodes).map((node) => {
                    const id = `${node.nodeId.repositoryId}_${node.nodeId.id}`;
                    const res: { [id: string]: string | boolean | number | NodeId } = {
                        id,
                        nodeId: node.nodeId,
                        checked: checedIds.includes(id),
                        found: false,
                    };

                    const systemAttributeValues: AttributeValue[] = mapNodeToSystemAttributeValues(node, undefined);

                    reportColumns.forEach((reportColumn) => {
                        let stringAttributeValue = '';

                        if (node.nodeId.repositoryId !== reportId.repositoryId) {
                            res[`${reportColumn.attributeType}_${reportColumn.attributeTypeId}`] = stringAttributeValue;
                        }

                        if (reportColumn.attributeType === 'SYSTEM') {
                            const systemAttributeValue: AttributeValue | undefined = systemAttributeValues.find(
                                (value) => value.typeId === reportColumn.attributeTypeId,
                            );

                            stringAttributeValue = storageValueToString(systemAttributeValue, locale, {
                                system: true,
                                attributeTypes: systemAttributeTypes,
                                principals: principals,
                            });

                            if (reportColumn.attributeTypeId === 'nodeType') {
                                stringAttributeValue =
                                    modelTypeMessages[node.type] && intl.formatMessage(modelTypeMessages[node.type]);
                            }

                            if (reportColumn.attributeTypeId === 'symbolId') {
                                const symbol: Symbol | undefined = symbols.get({
                                    presetId: node.presetId,
                                    symbolId: (node as ObjectDefinitionNode).idSymbol || '',
                                    serverId: node.nodeId.serverId,
                                });

                                if (symbol) {
                                    stringAttributeValue = LocalesService.internationalStringToString(
                                        symbol.multilingualName,
                                        locale,
                                    );
                                }
                            }

                            if (
                                (reportColumn.attributeTypeId === 'modelTypeId' &&
                                    (node.type === 'MODEL' ||
                                        node.type === 'MATRIX' ||
                                        node.type === 'REPORT' ||
                                        node.type === 'WIKI' ||
                                        node.type === 'DASHBOARD')) ||
                                (reportColumn.attributeTypeId === 'objectTypeId' && node.type === 'OBJECT') ||
                                (reportColumn.attributeTypeId === 'edgeTypeId' && node.type === 'EDGE') ||
                                (reportColumn.attributeTypeId === 'folderTypeId' && node.type === 'FOLDER')
                            ) {
                                stringAttributeValue = node.elementTypeName || '';
                            }
                        }

                        if (reportColumn.attributeType === 'USER') {
                            const attributeValue: AttributeValue | undefined = node.attributes?.find(
                                (attribute) => attribute.typeId === reportColumn.attributeTypeId,
                            );
                            if (attributeValue && node.presetId === reportPresetId) {
                                stringAttributeValue = storageValueToString(attributeValue, locale, {
                                    system: false,
                                    attributeTypes: accessibleAttributeTypes,
                                    principals: principals,
                                });
                            }
                        }

                        res[`${reportColumn.attributeType}_${reportColumn.attributeTypeId}`] = stringAttributeValue;
                        if (!res.found) {
                            res.found =
                                filterValue !== '' &&
                                stringAttributeValue.toLowerCase().includes(filterValue.toLowerCase());
                        }
                    });
                    return res;
                });

                return tableData;
            },
        );

    export const fillingType = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, ReportDataFillingTypeEnum>(reportStateSelector, (state) => {
            return state.get(reportNodeId)?.reportData?.fillingType || 'MANUAL';
        });

    export const searchRequests = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, SearchRequest[]>(reportStateSelector, (state) => {
            if (state.get(reportNodeId)?.reportData?.fillingType === 'AUTO') {
                return state.get(reportNodeId)?.reportData?.searchRequests || [];
            }

            return [];
        });

    export const searchResults = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, SearchResult[]>(reportEditorStateSelector, (state) => {
            return state.get(reportNodeId)?.searchResults || [];
        });

    export const isLoading = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, boolean>(
            reportEditorStateSelector,
            (state) => !!state.get(reportNodeId)?.loading,
        );

    export const searchValue = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, string>(
            reportEditorStateSelector,
            (state) => state.get(reportNodeId)?.filterValue || '',
        );

    export const selectedColumnId = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, string | undefined>(
            reportEditorStateSelector,
            (state) => state.get(reportNodeId)?.selectedColumnId,
        );

    export const isUnsaved = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, boolean>(reportEditorStateSelector, (state) => {
            if (state.get(reportNodeId)?.unsaved === undefined) return true;

            return !!state.get(reportNodeId)?.unsaved;
        });

    export const getNodesWithPresetIdByIds = (nodeIds: NodeId[]) =>
        createSelector<TRootState, TRootState, TNodeState<TExtendedNode>, TExtendedNode[]>(
            getRootState,
            reportNodes,
            (rootState: TRootState, nodeState: TNodeState<TExtendedNode>) => {
                const result: TExtendedNode[] = [];
                nodeIds.forEach((nodeId) => {
                    const node: TExtendedNode | undefined = nodeState.byNodeId.get(nodeId);
                    if (node) {
                        const presetId: string = TreeSelectors.presetById(nodeId)(rootState);
                        const elementTypeName: string = SearchElementTypeSelectors.getElementTypeName(
                            node.nodeId.serverId,
                            presetId,
                            node.elementTypeId || '',
                            node.type as TreeItemType,
                        )(rootState);
                        const elementTypeId: string =
                            node?.elementTypeId ||
                            (node as EdgeDefinitionNode)?.edgeTypeId ||
                            (node as ObjectDefinitionNode)?.objectTypeId ||
                            (node as GeneralModel)?.modelTypeId ||
                            (node as FolderNode)?.folderType ||
                            '';
                        const nodeWithPresetId: TExtendedNode = { ...node, presetId, elementTypeId, elementTypeName };
                        result.push(nodeWithPresetId);
                    }
                });

                return result;
            },
        );
}
