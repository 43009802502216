import React, { FC } from 'react';
import theme from './ReportEditorSidePanel.scss';
import { NavigatorPanel } from '@/modules/Navigator/components/NavigatorPanel/NavigatorPanel.component';
import { TNavigatorTab } from '@/reducers/navigator.reducer.types';
import { useIntl } from 'react-intl';
import messages from './ReportEditorSidebar.messages';
import { NodeId, ReportColumnData } from '@/serverapi/api';
import { useDispatch } from 'react-redux';
import { reportClearSelectColumn } from '../../actions/report.actions';
import { ColumnSettings } from './ColumnSettings/ColumnSettings.component';
import { CommonSettings } from './CommonSettings/CommonSettings.component';

type TReportEditorSidePanelProps = {
    reportNodeId: NodeId;
    disableDelete: boolean;
    currentColumn?: ReportColumnData;
};

export const ReportEditorSidePanel: FC<TReportEditorSidePanelProps> = ({
    reportNodeId,
    disableDelete,
    currentColumn,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const onCloseHandler = () => {
        dispatch(reportClearSelectColumn(reportNodeId));
    };

    return (
        <NavigatorPanel
            titleProps={{
                title: currentColumn
                    ? intl.formatMessage(messages.colSettings)
                    : intl.formatMessage(messages.commonSettings),
            }}
            type={TNavigatorTab.ReportSettings}
            className={theme.reportEditorSidePanel}
            hideCloseButton={!currentColumn}
            onClose={onCloseHandler}
        >
            <div className={theme.sidePanelBody}>
                {currentColumn ? (
                    <ColumnSettings
                        disableDelete={disableDelete}
                        reportNodeId={reportNodeId}
                        currentColumn={currentColumn}
                    />
                ) : (
                    <CommonSettings reportNodeId={reportNodeId} />
                )}
            </div>
        </NavigatorPanel>
    );
};
