// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrintForm-component__form___6cqT{width:-webkit-max-content;width:-moz-max-content;width:max-content;margin-bottom:0}.PrintForm-component__form___6cqT .ant-form-item-label{padding:0}.PrintForm-component__formItem___iiLm{margin-bottom:25px}.PrintForm-component__radioGroup__I13UF{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.PrintForm-component__fitBlock__IICJp{display:-webkit-box;display:-ms-flexbox;display:flex}.PrintForm-component__fitBlock_description__YANFY{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.PrintForm-component__divider___Lhb8{margin:-10px 0 10px 0}.PrintForm-component__zoomFitOption__w_nwC{height:100px}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/PrintDialog/PrintForm.component.scss"],"names":[],"mappings":"AAAA,kCACI,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,eAAA,CAEA,uDACI,SAAA,CAIR,sCACI,kBAAA,CAGJ,wCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAGJ,sCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAIR,qCACI,qBAAA,CAGJ,2CACI,YAAA","sourcesContent":[".form {\r\n    width: max-content;\r\n    margin-bottom: 0;\r\n\r\n    :global(.ant-form-item-label) {\r\n        padding: 0;\r\n    }\r\n}\r\n\r\n.formItem {\r\n    margin-bottom: 25px;\r\n}\r\n\r\n.radioGroup {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.fitBlock {\r\n    display: flex;\r\n    &_description {\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n    }\r\n}\r\n\r\n.divider {\r\n    margin: -10px 0 10px 0;\r\n}\r\n\r\n.zoomFitOption {\r\n    height: 100px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "PrintForm-component__form___6cqT",
	"formItem": "PrintForm-component__formItem___iiLm",
	"radioGroup": "PrintForm-component__radioGroup__I13UF",
	"fitBlock": "PrintForm-component__fitBlock__IICJp",
	"fitBlock_description": "PrintForm-component__fitBlock_description__YANFY",
	"divider": "PrintForm-component__divider___Lhb8",
	"zoomFitOption": "PrintForm-component__zoomFitOption__w_nwC"
};
export default ___CSS_LOADER_EXPORT___;
