import { useEffect, useState } from 'react';

export const useCheckTextOverflow = (selector: string) => {
    const [ref, setRef] = useState<Element | null>(null);
    const [isTextOverflow, setIsTextOverflow] = useState<boolean>(false);

    useEffect(() => {
        setRef(document.querySelector(selector));
        if (ref) {
            setIsTextOverflow(ref.clientWidth < ref.scrollWidth);
        }
    });

    return isTextOverflow;
};
