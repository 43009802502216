import { INIT_HISTORY_DIALOG } from '../../actionsTypes/historyDialog.actionTypes';
import { TInitHistoryDialogAction } from '../../actions/historyDialog.actions.types';
import { TServerEntity } from '../../models/entities.types';
import { ServerSelectors } from '../../selectors/entities/server.selectors';
import { select, call, put, takeEvery } from 'redux-saga/effects';
import { ModelVersionInfo } from '../../serverapi/api';
import { DialogType } from '../../modules/DialogRoot/DialogRoot.constants';
import { openDialog } from '../../actions/dialogs.actions';

function* openHistoryDialog({ payload: { nodeId } }: TInitHistoryDialogAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const history: ModelVersionInfo[] = yield call(() =>
        server.api.model.getModelVersions({ repositoryId: nodeId.repositoryId, modelId: nodeId.id }),
    );

    yield put(openDialog(DialogType.MODEL_HISTORY, { history, nodeId }));
}

export function* modelHistorySaga() {
    yield takeEvery(INIT_HISTORY_DIALOG, openHistoryDialog);
}
