// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportVisioCollapseTable__tableContainer__n_ibT{margin-top:20px}.ImportVisioCollapseTable__tableContainer__n_ibT>.ant-collapse > .ant-collapse-item > .ant-collapse-header{padding:0px !important}.ImportVisioCollapseTable__tableContainer__n_ibT>.ant-collapse .ant-collapse-content-box{padding:0px}.ImportVisioCollapseTable__footer__THMhv{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:20px}.ImportVisioCollapseTable__footer__THMhv>button{margin-left:auto}.ImportVisioCollapseTable__footer__THMhv>span{margin-right:5px}.ImportVisioCollapseTable__typeSelect__muNQf{width:420px}", "",{"version":3,"sources":["webpack://./src/modules/Import/Visio/components/ImportVisioCollapseTable/ImportVisioCollapseTable.scss"],"names":[],"mappings":"AAAA,iDACI,eAAA,CAEA,2GACI,sBAAA,CAGJ,yFACI,WAAA,CAIR,yCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,eAAA,CAEA,gDACI,gBAAA,CAGJ,8CACI,gBAAA,CAIR,6CACI,WAAA","sourcesContent":[".tableContainer {\r\n    margin-top: 20px;\r\n\r\n    & > :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {\r\n        padding: 0px !important;\r\n    }\r\n\r\n    & > :global(.ant-collapse .ant-collapse-content-box) {\r\n        padding: 0px;\r\n    }\r\n}\r\n\r\n.footer {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-top: 20px;\r\n\r\n    & > button {\r\n        margin-left: auto;\r\n    }\r\n\r\n    & > span {\r\n        margin-right: 5px;\r\n    }\r\n}\r\n\r\n.typeSelect {\r\n    width: 420px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "ImportVisioCollapseTable__tableContainer__n_ibT",
	"footer": "ImportVisioCollapseTable__footer__THMhv",
	"typeSelect": "ImportVisioCollapseTable__typeSelect__muNQf"
};
export default ___CSS_LOADER_EXPORT___;
