// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectTreeItemApprovalDialog-component__modal__ayQtz .ant-modal-body{height:500px}.SelectTreeItemApprovalDialog-component__buttons__WYyz3{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.SelectTreeItemApprovalDialog-component__title__CT_Vi{font-size:16px}.SelectTreeItemApprovalDialog-component__filterContainer__LQEiu{display:-webkit-box;display:-ms-flexbox;display:flex;margin-top:24px;margin-bottom:16px}.SelectTreeItemApprovalDialog-component__filterContainer__LQEiu label{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.SelectTreeItemApprovalDialog-component__filterContainer__LQEiu label:first-child{margin-right:18px}.SelectTreeItemApprovalDialog-component__search__FU8oi{border-radius:4px}.SelectTreeItemApprovalDialog-component__label__ltFit{margin-top:25px;margin-left:12px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/ApprovalsTable/SelectTreeItemApprovalDialog/SelectTreeItemApprovalDialog.component.scss"],"names":[],"mappings":"AAEQ,sEACI,YAAA,CAKZ,wDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAGJ,sDACI,cAAA,CAGJ,gEACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,eAAA,CACA,kBAAA,CACA,sEACI,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CAEJ,kFACI,iBAAA,CAIR,uDACI,iBAAA,CAGJ,sDACI,eAAA,CACA,gBAAA","sourcesContent":[".modal{\r\n    :global{\r\n        .ant-modal-body{\r\n            height: 500px;\r\n        }\r\n    }\r\n}\r\n\r\n.buttons {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.title {\r\n    font-size: 16px;\r\n}\r\n\r\n.filterContainer {\r\n    display: flex;\r\n    margin-top: 24px;\r\n    margin-bottom: 16px;\r\n    label {\r\n        width: fit-content;\r\n    }\r\n    label:first-child {\r\n        margin-right: 18px;\r\n    }\r\n}\r\n\r\n.search {\r\n    border-radius: 4px;\r\n}\r\n\r\n.label {\r\n    margin-top: 25px;\r\n    margin-left: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "SelectTreeItemApprovalDialog-component__modal__ayQtz",
	"buttons": "SelectTreeItemApprovalDialog-component__buttons__WYyz3",
	"title": "SelectTreeItemApprovalDialog-component__title__CT_Vi",
	"filterContainer": "SelectTreeItemApprovalDialog-component__filterContainer__LQEiu",
	"search": "SelectTreeItemApprovalDialog-component__search__FU8oi",
	"label": "SelectTreeItemApprovalDialog-component__label__ltFit"
};
export default ___CSS_LOADER_EXPORT___;
