import { MxCell } from 'MxGraph';

export function getDestroySymbolId(parent: MxCell) {
    return `${parent.id}_destroy-symbol`;
}

export function getDestroySymbol(cell: MxCell): MxCell | undefined {
    return cell.children?.find((child) => child.id === LifeLineUtils.getDestroySymbolId(cell));
}

const LifeLineUtils = {
    getDestroySymbolId,
    getDestroySymbol,
};

export default LifeLineUtils;
