import { EditorState, Modifier, SelectionState, ContentBlock, ContentState } from 'draft-js';
import { isTableBlock, isTableRootBlock } from '../Editor/renderers/Table/TableContentsBlocks.utils';

export const getCurrentBlock = (editorState) => {
    const currentSelection = editorState.getSelection();
    const blockKey = currentSelection.getStartKey();

    return editorState.getCurrentContent().getBlockForKey(blockKey);
};

const isSelectAvailableBlock = (block: ContentBlock) => {
    if (isTableBlock(block)) {
        return isTableRootBlock(block);
    }

    return true;
};

export const getSelectedBlocks = (contentState, startKey, endKey) => {
    const isSameBlock = startKey === endKey;
    const startingBlock = contentState.getBlockForKey(startKey);
    const selectedBlocks = [startingBlock];

    if (!isSameBlock) {
        let blockKey = startKey;

        while (blockKey !== endKey) {
            const nextBlock = contentState.getBlockAfter(blockKey);
            if (isSelectAvailableBlock(nextBlock)) {
                selectedBlocks.push(nextBlock);
            }
            blockKey = nextBlock.getKey();
        }
    }

    return selectedBlocks;
};

export const getCurrentSelectedBlocks = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const startKey = currentSelection.getStartKey();
    const endKey = currentSelection.getEndKey();

    return getSelectedBlocks(contentState, startKey, endKey);
};

export const modifySelectedBlocks = (editorState, modifier) => {
    const contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const startKey = currentSelection.getStartKey();
    const endKey = currentSelection.getEndKey();
    const startOffset = currentSelection.getStartOffset();
    const endOffset = currentSelection.getEndOffset();

    const isSameBlock = startKey === endKey;
    const selectedBlocks = getSelectedBlocks(contentState, startKey, endKey);

    let finalEditorState = editorState;

    selectedBlocks.forEach((block) => {
        const currentBlockKey = block.getKey();
        let selectionStart = startOffset;
        let selectionEnd = endOffset;

        if (currentBlockKey === startKey) {
            selectionStart = startOffset;
            selectionEnd = isSameBlock ? endOffset : block.getText().length;
        } else if (currentBlockKey === endKey) {
            selectionStart = isSameBlock ? startOffset : 0;
            selectionEnd = endOffset;
        } else {
            selectionStart = 0;
            selectionEnd = block.getText().length;
        }

        const selection = new SelectionState({
            anchorKey: currentBlockKey,
            anchorOffset: selectionStart,
            focusKey: currentBlockKey,
            focusOffset: selectionEnd,
        });

        finalEditorState = modifier(finalEditorState, selection, block);
    });

    return EditorState.forceSelection(finalEditorState, currentSelection);
};

export const insertEmptyBlock = (editorState, data = {}) => {
    const newContentState = Modifier.splitBlock(editorState.getCurrentContent(), editorState.getSelection());
    const newEditorState = EditorState.push(editorState, newContentState, 'split-block');

    return setCurrentBlockData(newEditorState, data);
};

export const getNextBlock = (editorState) => {
    const currentSelection = editorState.getSelection();
    const blockKey = currentSelection.getStartKey();
    const blockArray = editorState.getCurrentContent().getBlocksAsArray();

    const currentBlockIndex = blockArray.findIndex((block) => block.get('key') === blockKey);
    const nextBlock = blockArray[currentBlockIndex + 1];

    return nextBlock || null;
};

export const isCursorPositionInEndOfBlock = (editorState) => {
    const currentSelection = editorState.getSelection();
    const currentBlock = getCurrentBlock(editorState);

    return currentBlock.get('text').length === currentSelection.anchorOffset;
};

export const isCursorPositionInStartOfBlock = (editorState) => {
    const currentSelection = editorState.getSelection();

    return currentSelection.anchorOffset === 0;
};

// Table supports
export const setBlockData = (editorState, block, data) => {
    const userSelection = editorState.getSelection();
    const selection = SelectionState.createEmpty(block.get('key'));
    const previousData = block.getData();
    const merged = previousData.merge(data);
    const newContentState = Modifier.setBlockData(editorState.getCurrentContent(), selection, merged);
    const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');

    return EditorState.forceSelection(newEditorState, userSelection);
};

export const setCurrentBlockData = (editorState, data) => {
    const block = getCurrentBlock(editorState);

    return setBlockData(editorState, block, data);
};

export const setSelectedBlocksData = (editorState, data) => {
    const handler = (finalEditorState, selection, block) => {
        return setBlockData(finalEditorState, block, data);
    };

    return modifySelectedBlocks(editorState, handler);
};

export const setBlockType = (editorState, block, type) => {
    const userSelection = editorState.getSelection();
    const selection = SelectionState.createEmpty(block.get('key'));
    const content = editorState.getCurrentContent();
    const newContent = Modifier.setBlockType(content, selection, type);
    const newEditorState = EditorState.push(editorState, newContent, 'change-block-type');

    return EditorState.forceSelection(newEditorState, userSelection);
};

export function deleteAtomicBlock(editorState, block) {
    const content = editorState.getCurrentContent();
    const blockArray = editorState.getCurrentContent().getBlocksAsArray();
    const witout = blockArray.filter((_block) => _block.getKey() !== block.getKey());
    const entityMap = content.getEntityMap();
    const contentState = ContentState.createFromBlockArray(witout, entityMap);

    return EditorState.push(editorState, contentState, 'insert-fragment');
}
