import {
    ADD_VISIO_MATCHING,
    CLEAR_VISIO_DATA_AFTER_IMPORT,
    DELETE_VISIO_FILE,
    DELETE_VISIO_PAGE,
    MAP_VALUE_TO_ALL_PAGES,
    RESET_IMPORT_VISIO_DATA,
    SET_IMPORT_VISIO_DATA,
    SET_PAGE_OPTIONS,
} from '../actionsTypes/import.actionTypes';
import { EdgeMappersAdapter } from '../adapters/import/EdgeMapper.adapter';
import { ObjectMappersAdapter } from '../adapters/import/ObjectMappers.adapter';
import { addIfExist } from '../utils/redux.utils';
import { TImportVisioState } from './import.reducer.types';

const initial: TImportVisioState = {
    nodeId: {
        id: '',
        repositoryId: '',
        serverId: '',
    },
    isLoading: false,
    files: null,
    conflictResolutionStrategy: 'ASK_USER',
    resolutionById: {},
};

export const importReducer = (state = initial, action) => {
    switch (action.type) {
        case SET_IMPORT_VISIO_DATA: {
            const { nodeId, isLoading, files, presetId, conflictResolutionStrategy, resolutionById } = action.payload;

            return {
                ...state,
                nodeId: addIfExist(nodeId, state.nodeId),
                isLoading: addIfExist(isLoading, state.isLoading),
                files: addIfExist(files, state.files),
                presetId: addIfExist(presetId, state.presetId),
                conflictResolutionStrategy: addIfExist(conflictResolutionStrategy, state.conflictResolutionStrategy),
                resolutionById: addIfExist(resolutionById, state.resolutionById),
            };
        }

        case ADD_VISIO_MATCHING: {
            const { fileName, visioPageId, visioMasterId, silaSymbolId, silaTypeId } = action.payload;

            return {
                ...state,
                files: state.files?.map((file) =>
                    file.map((page) => {
                        if (page.fileName === fileName && page.visioPageId === visioPageId) {
                            return {
                                ...page,
                                edgeMappers: page.edgeMappers?.map((edge) => {
                                    if (edge.visioMasterId === visioMasterId) {
                                        return new EdgeMappersAdapter({ ...edge, silaTypeId });
                                    }

                                    return edge;
                                }),
                                objectMappers: page.objectMappers?.map((obj) => {
                                    if (obj.visioMasterId === visioMasterId) {
                                        return new ObjectMappersAdapter({
                                            ...obj,
                                            silaTypeId,
                                            silaSymbolId,
                                        });
                                    }

                                    return obj;
                                }),
                            };
                        }

                        return page;
                    }),
                ),
            };
        }

        case MAP_VALUE_TO_ALL_PAGES: {
            const { fileName, visioPageId, visioMasterId } = action.payload;

            const values = state.files?.reduce((acc, file) => {
                const page = file.find((page) => page.fileName === fileName && page.visioPageId === visioPageId);

                if (page) {
                    const edgeMappers = page.edgeMappers?.find((item) => item.visioMasterId === visioMasterId);

                    if (edgeMappers) {
                        return {
                            silaTypeId: edgeMappers.silaTypeId,
                        };
                    }

                    const objectMappers = page.objectMappers?.find((item) => item.visioMasterId === visioMasterId);

                    if (objectMappers) {
                        return {
                            silaTypeId: objectMappers.silaTypeId,
                            silaSymbolId: objectMappers.silaSymbolId,
                        };
                    }
                }

                return acc;
            }, null);

            if (!values) return state;

            return {
                ...state,
                files: state.files?.map((file) =>
                    file.map((page) => {
                        if (page.fileName === fileName) {
                            return {
                                ...page,
                                edgeMappers: page.edgeMappers?.map((edge) => {
                                    if (edge.visioMasterId === visioMasterId)
                                        return {
                                            ...edge,
                                            silaTypeId:
                                                edge.silaTypeId === undefined ? values.silaTypeId : edge.silaTypeId,
                                        };

                                    return edge;
                                }),
                                objectMappers: page.objectMappers?.map((obj) => {
                                    if (obj.visioMasterId === visioMasterId)
                                        return {
                                            ...obj,
                                            silaTypeId:
                                                obj.silaTypeId === undefined ? values.silaTypeId : obj.silaTypeId,
                                            silaSymbolId:
                                                obj.silaSymbolId === undefined ? values.silaSymbolId : obj.silaSymbolId,
                                        };

                                    return obj;
                                }),
                            };
                        }

                        return page;
                    }),
                ),
            };
        }
        case SET_PAGE_OPTIONS: {
            const { fileName, visioPageId, silaModelName, silaModelTypeId, mapToAllPages, silaModelTypeName } =
                action.payload;

            return {
                ...state,
                files: state.files?.map((file) =>
                    file.map((page) => {
                        let newPage = { ...page };

                        if (mapToAllPages) {
                            newPage = {
                                ...newPage,
                                silaModelTypeId: newPage.silaModelTypeId || silaModelTypeId,
                                silaModelTypeName: newPage.silaModelTypeName || silaModelTypeName,
                            };
                        }

                        if (fileName === page.fileName && visioPageId === page.visioPageId) {
                            newPage = {
                                ...newPage,
                                silaModelName,
                                silaModelTypeId,
                                silaModelTypeName,
                            };
                        }

                        return newPage;
                    }),
                ),
            };
        }

        case DELETE_VISIO_FILE: {
            const { fileName } = action.payload;

            return {
                ...state,
                files: state.files?.filter((file) => file[0].fileName !== fileName),
            };
        }

        case DELETE_VISIO_PAGE: {
            const { fileName, visioPageId } = action.payload;

            return {
                ...state,
                files: state.files
                    ?.map((file) =>
                        file.filter((page) => !(page.fileName === fileName && page.visioPageId === visioPageId)),
                    )
                    .filter((file) => file.length),
            };
        }
        case RESET_IMPORT_VISIO_DATA:
            return initial;

        case CLEAR_VISIO_DATA_AFTER_IMPORT: {
            return {
                ...state,
                isLoading: false,
                files: null,
                conflictResolutionStrategy: 'ASK_USER',
                resolutionById: {},
            };
        }

        default: {
            return state;
        }
    }
};
