import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Table } from 'antd';
import messages from '../../messages/PrincipalAttributeDialog.messages';
import theme from './PrincipalAttributeDialog.scss';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { TGroupListFullProps, TGroupListTableRecord } from './GroupList.component.types';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import GroupName from './GroupName.component';
import { DELETED_GROUP_NAME } from '../utils';

class GroupList extends Component<TGroupListFullProps> {
    getColumns = () => {
        const { intl } = this.props;

        return [
            {
                title: intl.formatMessage(messages.groupName),
                dataIndex: 'name',
                key: 'name',
                width: 200,
                sorter: (a: TGroupListTableRecord, b: TGroupListTableRecord) => sortByAlpha(a.name, b.name),
                render: (text = '', group: TGroupListTableRecord) => <GroupName text={text} group={group} withGap />,
            },
        ];
    };

    getData = (rawData: PrincipalDescriptor[]): TGroupListTableRecord[] => {
        return rawData.map((item) => ({
            key: item.id,
            id: item.id,
            name: item.login,
            blocked: item.blocked,
        }));
    };


    addRowClassName = (group: TGroupListTableRecord) => {
        const isDeleted = group.name === DELETED_GROUP_NAME;
        const isBlocked = !isDeleted && group.blocked;

        if (this.props.readOnly) {
            return 'disabled-row';
        }

        return isDeleted || isBlocked ? 'blocked-or-deleted' : '';
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.props.selected,
            preserveSelectedRowKeys: true,
            hideSelectAll: this.props.readOnly,
            onChange: this.props.onSelectChange,
        };

        return (
            <Table
                className={theme.dataTable}
                size="middle"
                rowSelection={this.props.readOnly ? undefined : rowSelection}
                bordered
                columns={this.getColumns()}
                dataSource={this.getData(this.props.data)}
                pagination={false}
                rowClassName={this.addRowClassName}
                scroll={{ y: 400 }}
            />
        );
    }
}

export default injectIntl(GroupList);
