import { NodeId, NodeLockRequestLockActionEnum, NodeLockRequestLockTypeEnum } from '../../serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';

export class NodeLockDAOService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async saveNodeLockSettings(
        nodeId: NodeId,
        lockType: NodeLockRequestLockTypeEnum,
        lockAction: NodeLockRequestLockActionEnum,
    ): Promise<void> {
        const api = this.getApi();

        await api.nodeLock.lock({ body: { nodeId, lockType, lockAction, lockParams: [] } });
    }
}
