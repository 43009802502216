import React from 'react';
import { Checkbox, Form } from 'antd';
import theme from './SystemProperties.scss';

type TProps = {
    agreementTypeActive: boolean | undefined;
    label: React.ReactNode;
    name: 'isActiveSilaAgreement' | 'isActiveAgreement';
    onChange: () => void;
};

export const MultiLangInputDialogLabel: React.FC<TProps> = ({ agreementTypeActive, label, name, onChange }) => {
    return (
        <div className={theme.agreementLabelWrapper}>
            <Form.Item
                initialValue={agreementTypeActive}
                name={name}
                className={theme.formItem}
                label={label}
                valuePropName="checked"
            >
                <Checkbox className={theme.checkbox} onChange={onChange} />
            </Form.Item>
        </div>
    );
};
