import { TComment } from "@/reducers/comments.reducer.types";
import { useEffect, useState } from "react";

export const useCommentText = (comment: TComment | undefined) => {
    const [editingText, setEditingText] = useState(comment?.text);

    useEffect(() => {
        setEditingText(comment?.text);
    }, [comment?.text]);

    return { editingText, setEditingText };
};
