import { ContentBlock } from 'draft-js';
import './blockStyles.css';
import { CHECKABLE_LIST_ITEM } from '../../common/constants';

export const getBlockAlignClass = (block: ContentBlock) => {
    const blockAlignment = block.getData().get('text-align');

    return `rdw-${blockAlignment || 'left'}-aligned-block`;
};

function getClass(block: ContentBlock): string {
    const blockData = block.getData();
    if (!blockData) return '';

    const blockDepth = blockData.get('text-indent');
    const blockLineHeight = blockData.get('line-height');
    const styleString: Array<string> = [];
    if (blockLineHeight) {
        const blockLineHeightString = `rdw-lineheight-${blockLineHeight}`.replace('.', '-');
        styleString.push(blockLineHeightString);
    }
    if (block.getType() === CHECKABLE_LIST_ITEM) {
        styleString.push(CHECKABLE_LIST_ITEM);

        return styleString.join(' ');
    }

    styleString.push(getBlockAlignClass(block));
    if (blockDepth) {
        styleString.push(`rdw-text-indent-${blockDepth}`);
    }

    return styleString.join(' ');
}

export default function getBlockStyleFn(block: ContentBlock) {
    return `wiki-block ${getClass(block)}`;
}
