import { EDITOR_SELECTION_MODEL_CHANGE } from '../actionsTypes/editor.actionTypes';
import { TReducer } from '../utils/types';
import { TNodeState } from './entities/TNodeState';
import { TEditorState } from './editor.reducer.types';
import { WORKSPACE_TABS_ADD, WORKSPACE_TABS_REMOVE } from '../actionsTypes/tabs.actionTypes';
import { TWorkspaceTab } from '../models/tab.types';

const initial: TEditorState = {
    data: new TNodeState(),
};

export const editorReducer: TReducer<TEditorState> = (state = initial, action) => {
    switch (action.type) {
        case EDITOR_SELECTION_MODEL_CHANGE: {
            const { nodeId, cellsIds } = action.payload;

            if (!nodeId?.id || !nodeId?.serverId || !nodeId?.repositoryId) {
                return state;
            }

            return {
                ...state,
                data: state.data.set(nodeId, { selectedCellsIds: cellsIds }),
            };
        }

        case WORKSPACE_TABS_ADD: {
            const tab: TWorkspaceTab = action.payload.workspaceTab;
            const { nodeId } = tab;

            if (!nodeId?.id || !nodeId?.serverId || !nodeId?.repositoryId) {
                return state;
            }

            return {
                ...state,
                data: state.data.set(nodeId, { selectedCellsIds: [] }),
            };
        }

        case WORKSPACE_TABS_REMOVE: {
            const { workspaceTab } = action.payload;

            return {
                ...state,
                data: state.data.delete(workspaceTab.nodeId),
            };
        }

        default:
            return state;
    }
};

