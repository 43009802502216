import { MxShape, MxSvgCanvas2D } from '../mxgraph';

export class VerticalLine extends MxShape {
    paintBackground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        c.translate(x, y);
        c.begin();
        c.setDashed('1', '0');
        c.setDashPattern('15 15');
        c.moveTo(0, 0);
        c.lineTo(0, h);
        c.end();
    }

    paintForeground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        c.fillAndStroke();
    }
}
