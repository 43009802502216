import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RECENT_ADD_MODEL, RECENT_REFRESH } from '../actionsTypes/recent.actionTypes';
import { recentLoadSuccess } from '../actions/recent.actions';
import { TRecentAddModelAction } from '../actions/recent.actions.types';
import { TRecentModel, TRecentModelMap, TRecentModels } from '../models/recentModel.types';
import { nodeService } from '../services/NodeService';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { compareNodeIds, toString } from '../utils/nodeId.utils';
import { Node, NodeId } from '../serverapi/api';
import { groupBy } from 'lodash-es';
import { TreeItemType } from '../modules/Tree/models/tree';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';

// todo: save by repositoryId, serverId and id
export function loadRecents(): { [id: string]: TRecentModel } {
    const json: string | null = LocalStorageDaoService.getRecentData();

    try {
        if (json) {
            const data: { [id: string]: TRecentModel } = JSON.parse(json);

            if (Object.values(data).filter((t) => !t.nodeId || !t.nodeId.serverId).length) {
                LocalStorageDaoService.setRecentData({});
            } else {
                return data;
            }
        }
    } catch {
        LocalStorageDaoService.setRecentData({});
    }

    return {};
}

// todo: save by repositoryId, serverId and id
function saveRecentData(model: TRecentModel) {
    const data = loadRecents();
    const newValue = {
        ...data,
        [toString(model.nodeId)]: model,
    };

    LocalStorageDaoService.setRecentData(newValue);
}

// проверяет есть ли на сервере модели, если нет удаляет из списка последние
function* removeDeletedRecents() {
    const models = Object.values(loadRecents());
    const connectedServers = yield select(ServerSelectors.connected);
    const groupedModelsByServer = groupBy(models, (m: TRecentModel) => m.nodeId.serverId);
    const existedNodesId: NodeId[] = [];

    for (const [serverId, serverModels] of Object.entries<TRecentModel[]>(groupedModelsByServer)) {
        if (connectedServers.includes(serverId)) {
            const existedNodes: Node[] = yield nodeService().loadNodesFromServer(
                serverId,
                serverModels.map((m) => m.nodeId),
            );

            existedNodes.forEach((n) => existedNodesId.push(n.nodeId));
        }
    }

    let newRecentModels: TRecentModels = models
        .filter(
            (model) =>
                !connectedServers.includes(model.nodeId.serverId) || // если сервер офлайн то его модели не чистим
                existedNodesId.find((id) => compareNodeIds(model.nodeId, id)),
        )
        .reduce((result, model) => ({ ...result, [toString(model.nodeId)]: model }), {} as TRecentModels);

    // костыль который прописывает тип для узла, нужен для обратной совместимости списка Recent
    // можно удалить после того как большинство клиентов перейдет на версию 1.7.9
    for (const id in newRecentModels) {
        const model = newRecentModels[id];

        if (model && !model.type) {
            try {
                const node: Node[] | undefined = yield call(() =>
                    nodeService().loadNodesFromServer(model.nodeId.serverId, [model.nodeId]),
                );

                if (node && node[0]) {
                    model.type = node[0].type as TreeItemType;
                }
            } catch (error) {
                // при обновлении списка, может быть ситуация когда нет доступа к узлу, пропускаем этот узел
                console.error('Load recent type is fail', error);
            }
        }
    }

    newRecentModels = Object.values(newRecentModels)
        .filter((n) => n?.type)
        .reduce((result, model) => ({ ...result, [toString(model.nodeId)]: model }), {} as TRecentModels);

    LocalStorageDaoService.setRecentData(newRecentModels);

    return newRecentModels;
}

function* handleRecentAddModel({ payload }: TRecentAddModelAction) {
    const { model } = payload;

    yield call(saveRecentData, model);
}

function* recentRefresh() {
    const models: TRecentModels = yield removeDeletedRecents();

    yield put(recentLoadSuccess(toRecentModelMap(models)));
}

function toRecentModelMap(map: { [id: string]: TRecentModel }): TRecentModelMap {
    const recentMap: TRecentModelMap = {};

    for (const value of Object.values(map)) {
        const { id, serverId, repositoryId }: NodeId = value.nodeId;

        if (!recentMap[serverId]) {
            recentMap[serverId] = {};
        }

        if (!recentMap[serverId][repositoryId]) {
            recentMap[serverId][repositoryId] = {};
        }

        recentMap[serverId][repositoryId][id] = value;
    }

    return recentMap;
}

export function* recentSaga() {
    yield takeEvery(RECENT_ADD_MODEL, handleRecentAddModel);
    yield takeEvery(RECENT_REFRESH, recentRefresh);
}
