import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import theme from '../style/AdminTools.scss';
import { TWithWindowResizeProps } from '../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import { TWorkspaceTab } from '../../../models/tab.types';
import { NotificationTemplatesSelectors } from '@/selectors/notificationTemplates.selectors';
import { NotificationDTO, NotificationTemplateDTO } from '@/serverapi/api';
import { NotificationTemplatesList } from '../ServerSettings/components/NotificationTemplatesList/NotificationTemplatesList.component';
import messages from './NotificationTemplates.messages';
import { NotificationsList } from '../ServerSettings/components/NotificationsList/NotificationsList.component';
import { NotificationsSelectors } from '@/selectors/notifications.selectors';
import { Messages } from '../ServerSettings/components/Messages/Messages.component';

type TNotificationTemplatesProps = TWithWindowResizeProps & {
    tab: TWorkspaceTab;
};

export const NotificationTemplates: FC<TNotificationTemplatesProps> = () => {
    const intl = useIntl();
    const notificationTemplates: NotificationTemplateDTO[] = useSelector(NotificationTemplatesSelectors.getAll);
    const notifications: NotificationDTO[] = useSelector(NotificationsSelectors.getAll);

    const tabs = [
        {
            label: intl.formatMessage(messages.notificationSettings),
            key: 'notificationSettings',
            children: <NotificationsList notificationTemplates={notificationTemplates} notifications={notifications} />,
        },
        {
            label: intl.formatMessage(messages.notificationTemplates),
            key: 'notificationTemplates',
            children: <NotificationTemplatesList notificationTemplates={notificationTemplates} />,
        },
        {
            label: intl.formatMessage(messages.messages),
            key: 'messages',
            children: <Messages />,
        },
    ];

    return (
        <div className={theme.container} data-test="notificationTemplates_container">
            <div className={theme.containerInner}>
                <Tabs defaultActiveKey="1" tabPosition="left" items={tabs} />
            </div>
        </div>
    );
};
