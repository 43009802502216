export const defaultWorkspaceTabActions = {
    undo: false,
    redo: false,
    focusSearch: false,
    zoomFit: false,
    cut: false,
    paste: false,
    delete: false,
    copy: false,
};
