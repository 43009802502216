// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentTooltipActions__actionsContainer__D_VVG{display:-webkit-box;display:-ms-flexbox;display:flex}.CommentTooltipActions__closeIconContainer__FoT9v svg{fill:#8f8f8f}", "",{"version":3,"sources":["webpack://./src/modules/Comments/components/CommentTooltipAcrions/CommentTooltipActions.scss"],"names":[],"mappings":"AAAA,gDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEJ,sDACI,YAAA","sourcesContent":[".actionsContainer {\r\n    display: flex;\r\n}\r\n.closeIconContainer svg{\r\n    fill: #8F8F8F;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsContainer": "CommentTooltipActions__actionsContainer__D_VVG",
	"closeIconContainer": "CommentTooltipActions__closeIconContainer__FoT9v"
};
export default ___CSS_LOADER_EXPORT___;
