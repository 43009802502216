import { TReducer } from '../utils/types';
import {
    IMPORT_DIALOG_CANCEL,
    IMPORT_DIALOG_IMPORT_REQUEST_DONE,
    IMPORT_DIALOG_SET_STATE,
    IMPORT_DIALOG_SUBMIT_ALL,
    IMPORT_DIALOG_SUBMIT_NODE,
    IMPORT_DIALOG_APPLY_NAME,
} from '../actionsTypes/importDialog.actionTypes';
import { TREE_IMPORT_NODE } from '../actionsTypes/tree.actionTypes';
import { TImportDialogState } from './importDialog.reducer.types';

const initial: TImportDialogState = {
    nodeId: undefined!,
    type: undefined!,
    name: '',
    conflictingNodes: [],
    resolveStrategyById: {},
    importCanceledByUser: false,
    hasUnresolvedConflicts: false,
    importFileName: undefined,
    fileUUID: undefined,
};

export const ImportDialogReducer: TReducer<TImportDialogState> = (state = initial, action) => {
    switch (action.type) {
        case IMPORT_DIALOG_SET_STATE: {
            const { importResponse, parentId, type, fileUUID } = action.payload;
            const { conflictingNodes, hasUnresolvedConflicts, importFileName } = importResponse;
            state = {
                ...state,
                nodeId: parentId,
                type,
                conflictingNodes,
                hasUnresolvedConflicts,
                importCanceledByUser: false,
                resolveStrategyById: {},
                importFileName,
                fileUUID
            };

            return { ...state };
        }

        case IMPORT_DIALOG_SUBMIT_NODE: {
            const { itemId, conflictResolutionStrategy } = action.payload;
            const resolveStrategyById = { [itemId.id]: conflictResolutionStrategy };

            if (!state.resolveStrategyById[itemId.id]) {
                state = {
                    ...state,
                    resolveStrategyById: {
                        ...state.resolveStrategyById,
                        ...resolveStrategyById,
                    },
                };
            }

            return { ...state };
        }
        case IMPORT_DIALOG_SUBMIT_ALL: {
            const { conflictResolutionStrategy } = action.payload;
            const resolveStrategyById = {};
            const setResolveConflictStrategy = function (id: string) {
                if (!state.resolveStrategyById[id]) {
                    resolveStrategyById[id] = conflictResolutionStrategy;
                }
            };

            state.conflictingNodes.map((n) => n.nodeId.id).forEach(setResolveConflictStrategy);

            state = {
                ...state,
                resolveStrategyById: {
                    ...state.resolveStrategyById,
                    ...resolveStrategyById,
                },
            };

            return { ...state };
        }
        case IMPORT_DIALOG_IMPORT_REQUEST_DONE: {
            return initial;
        }

        case TREE_IMPORT_NODE: {
            const { parentNodeId, type } = action.payload;
            state = {
                ...state,
                nodeId: parentNodeId,
                type,
                conflictingNodes: [],
            };

            return { ...state };
        }

        case IMPORT_DIALOG_CANCEL: {
            state = { ...initial, importCanceledByUser: true };

            return state;
        }

        case IMPORT_DIALOG_APPLY_NAME: {
            const { newName } = action.payload;
            state = { ...state, name: newName.toString() };

            return state;
        }

        default: {
            return state;
        }
    }
};
