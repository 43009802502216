import { TReducer } from '../../utils/types';
import { ReportType } from '../../serverapi/api';
import { CLEAR_PRESET_REQUEST } from '../../actionsTypes/methodologySetting.actionTypes';
import {
    IPresetSettingsReportModelTypeData,
    TPresetSettingsReportModelTypeState,
} from './presetSettingsReportModelType.reducer.types';
import {
    PRESET_SETTINGS_ADD_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_GET_ALL_REPORT_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_SUBMIT_REPORT_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_REPORT_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsReportModelTypes.actionTypes';

export const PRESET_SETTINGS_INITIAL_REPORT_MODEL_TYPE_SERVER_STATE: IPresetSettingsReportModelTypeData = {
    byId: {},
    reportModelTypesForDelete: [],
    reportModelTypesForSave: [],
};

const initial: TPresetSettingsReportModelTypeState = {
    byPresetId: {},
};

const mappingReportModelTypes = (
    reportModelTypes: ReportType[],
): {
    [id: string]: ReportType;
} => {
    const reportModelTypesMap = reportModelTypes.reduce(
        (
            result: {
                [id: string]: ReportType;
            },
            item: ReportType,
        ) => {
            result[item.id] = item;

            return result;
        },
        {},
    );

    return reportModelTypesMap;
};

const setNewData = (
    state: TPresetSettingsReportModelTypeState,
    presetId: string,
    newData: Partial<IPresetSettingsReportModelTypeData>,
): TPresetSettingsReportModelTypeState => {
    return {
        ...state,
        byPresetId: {
            ...state.byPresetId,
            [presetId]: {
                ...PRESET_SETTINGS_INITIAL_REPORT_MODEL_TYPE_SERVER_STATE,
                ...state.byPresetId[presetId],
                ...newData,
            },
        },
    };
};

export const presetSettingsReportModelTypeReducer: TReducer<TPresetSettingsReportModelTypeState> = (
    state = initial,
    action,
) => {
    switch (action.type) {
        case PRESET_SETTINGS_GET_ALL_REPORT_MODEL_TYPES_SUCCESS: {
            const { reportModelTypes, presetId } = action.payload;
            const reportModelTypesMap = mappingReportModelTypes(reportModelTypes);

            const newData: Partial<IPresetSettingsReportModelTypeData> = {
                byId: reportModelTypesMap,
                reportModelTypesForDelete: [],
                reportModelTypesForSave: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_ADD_REPORT_MODEL_TYPE: {
            const { reportModelTypes, presetId } = action.payload;
            const reportModelTypesMap = mappingReportModelTypes(reportModelTypes);

            const newData: Partial<IPresetSettingsReportModelTypeData> = {
                byId: {
                    ...state.byPresetId[presetId]?.byId,
                    ...reportModelTypesMap,
                },
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_SUBMIT_REPORT_MODEL_TYPE: {
            const { presetId } = action.payload;

            const newData: Partial<IPresetSettingsReportModelTypeData> = {
                reportModelTypesForDelete: [],
                reportModelTypesForSave: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_TRANSFER_REPORT_MODEL_TYPE: {
            const { reportModelTypes, presetId } = action.payload;
            const reportModelTypesMap = mappingReportModelTypes(reportModelTypes);

            const newData: Partial<IPresetSettingsReportModelTypeData> = {
                byId: {
                    ...state.byPresetId[presetId]?.byId,
                    ...reportModelTypesMap,
                },
                reportModelTypesForSave: [...state.byPresetId[presetId]?.reportModelTypesForSave, ...reportModelTypes],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_DELETE_REPORT_MODEL_TYPE: {
            const { reportModelTypesForDelete, presetId } = action.payload;
            const allReportModelTypes = state.byPresetId[presetId]?.byId || {};
            const filteredKanbanModelTypes = Object.keys(allReportModelTypes).reduce(
                (res: { [id: string]: ReportType }, id) => {
                    const findInDeleteList = reportModelTypesForDelete.some(
                        (reportModelType) => reportModelType.id === id,
                    );
                    if (!findInDeleteList) {
                        res[id] = allReportModelTypes[id];
                    }

                    return res;
                },
                {},
            );

            const newData: Partial<IPresetSettingsReportModelTypeData> = {
                byId: filteredKanbanModelTypes,
                reportModelTypesForDelete: [
                    ...state.byPresetId[presetId]?.reportModelTypesForDelete,
                    ...reportModelTypesForDelete,
                ],
            };

            return setNewData(state, presetId, newData);
        }

        case CLEAR_PRESET_REQUEST: {
            const { presetId } = action.payload;
            delete state.byPresetId?.[presetId];

            return { ...state };
        }

        default: {
            return state;
        }
    }
};
