import { useCallback, useMemo } from 'react';
import { TreeNode } from '../models/tree.types';
import { NodeId } from '../serverapi/api';
import { ExpandStatus } from '../reducers/tree.reducer.types';
import { reduce } from 'lodash-es';
import { TExpandTree, TTreeNodeWithLevel } from '../modules/Tree/Tree.types';

export const useExpandStatus = (expandTree: TExpandTree | undefined) => {
    return useCallback(
        (nodeId: NodeId): ExpandStatus => {
            const { id, repositoryId, serverId } = nodeId;
            const status = expandTree?.[serverId]?.[repositoryId]?.[id]?.expand;
            if (status) return status;

            return ExpandStatus.CLOSED;
        },
        [expandTree],
    );
};

export const useFlatNodesFromTree = (getExpandStatus: (nodeId: NodeId) => ExpandStatus) => {
    const getFlatNodesFromTree = useCallback(
        (items: TreeNode[], parents: string[]): TTreeNodeWithLevel[] =>
            reduce(
                items,
                (result: TTreeNodeWithLevel[], node: TTreeNodeWithLevel) => {
                    const level = parents.length;
                    const nodeWithHelpers = { ...node, level };
                    if (!node.children?.length) {
                        return [...result, nodeWithHelpers];
                    }
                    const expandStatus = getExpandStatus(node.nodeId);
                    if (expandStatus === ExpandStatus.CLOSED) {
                        return [...result, nodeWithHelpers];
                    }

                    return [
                        ...result,
                        nodeWithHelpers,
                        ...getFlatNodesFromTree(node.children, [...parents, node.nodeId.id]),
                    ];
                },
                [],
            ),
        [getExpandStatus],
    );
    
    return getFlatNodesFromTree;
};

export const useFlatTree = (data: TreeNode[], expandTree: TExpandTree | undefined): TTreeNodeWithLevel[] => {
    const getExpandStatus = useExpandStatus(expandTree);
    const getFlatNodesFromTree = useFlatNodesFromTree(getExpandStatus);
    const flatData = useMemo(() => {
        return getFlatNodesFromTree(data, []);
    }, [data, getFlatNodesFromTree]);

    return flatData;
};
