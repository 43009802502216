import { SequenceSymbolTypeId, SequenceSymbolShapes } from '../ComplexSymbol.constants';
import { LifelineSymbolMainClass } from './LifelineSymbolMain.class';
import { getSequenceLifeLineTemplate } from './lifeLineTemplate';

export class LifelineBounderySymbol extends LifelineSymbolMainClass {
    complexSymbolTypeId = SequenceSymbolTypeId.LIFE_LINE_BOUNDERY;
    headerSize = 50;
    template = getSequenceLifeLineTemplate(
        SequenceSymbolTypeId.LIFE_LINE_BOUNDERY,
        SequenceSymbolShapes.LIFE_LINE_BOUNDERY,
    );
}
