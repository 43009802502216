import React, { useCallback } from 'react';
import { Input } from 'antd';
import theme from './ObjectPropertiesDialog.scss';
import { SearchOutlined } from '@ant-design/icons';

type TSearchInputProps = {
    value: string;
    onSearch: (str: string) => void;
};

export const SearchInput = (props: TSearchInputProps) => {
    const { value } = props;

    // предотвращаем закрытие диалогового окна при нажатии на Enter если input в фокусе
    const handleKeyUp = useCallback((event: React.KeyboardEvent) => event.stopPropagation(), []);
    const onSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => props.onSearch(e.target.value), []);

    return (
        <Input
            value={value}
            className={theme.searchInput}
            suffix={<SearchOutlined />}
            onKeyUp={handleKeyUp}
            onChange={onSearch}
        />
    );
};
