import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NodeId } from '@/serverapi/api';
import { workspaceTabSetAction, workspaceTabSetParams } from '@/actions/tabs.actions';

type TUseUseMatrixZoomProps = {
    nodeId: NodeId;
    zoomFit: boolean;
    zoomLevel: number;
    matrixContainerRef: React.RefObject<HTMLDivElement>;
    lastRowRef: React.RefObject<HTMLDivElement>;
    lastColRef: React.RefObject<HTMLDivElement>;
};

const MAX_ZOOM = 500;
const MIN_ZOOM = 10;

export const useMatrixZoom = ({
    nodeId,
    zoomFit,
    zoomLevel,
    lastColRef,
    lastRowRef,
    matrixContainerRef,
}: TUseUseMatrixZoomProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const wheelHandler = (ev: WheelEvent) => {
            if (ev.ctrlKey) {
                ev.preventDefault();
                let newZoomLevel: number = zoomLevel * 100;
                if (ev.deltaY > 0) {
                    newZoomLevel = Math.round(Math.max(newZoomLevel * 0.8, MIN_ZOOM));
                } else {
                    newZoomLevel = Math.ceil(Math.min(newZoomLevel * 1.2, MAX_ZOOM));
                }

                dispatch(workspaceTabSetParams(nodeId, { zoomLevel: newZoomLevel }));
            }
        };
        if (matrixContainerRef.current) {
            matrixContainerRef.current.addEventListener('wheel', wheelHandler);
        }

        return () => {
            matrixContainerRef.current?.removeEventListener('wheel', wheelHandler);
        };
    }, [zoomLevel]);

    useEffect(() => {
        if (zoomFit && lastColRef.current && lastRowRef.current && matrixContainerRef.current) {
            matrixContainerRef.current.scrollTo(0, 0);

            const containerBounds: DOMRect = matrixContainerRef.current.getBoundingClientRect();
            const lastColBounds: DOMRect = lastColRef.current.getBoundingClientRect();
            const lastRowBounds: DOMRect = lastRowRef.current.getBoundingClientRect();
            const matrixWidth: number = lastColBounds.x - containerBounds.x;
            const matrixHeight: number = lastRowBounds.y - containerBounds.y;
            const { clientHeight, clientWidth } = matrixContainerRef.current;

            const newZoomLevel = Math.floor(
                Math.min(clientWidth / matrixWidth, clientHeight / matrixHeight) * zoomLevel * 100,
            );

            dispatch(workspaceTabSetParams(nodeId, { zoomLevel: newZoomLevel }));
        }

        dispatch(workspaceTabSetAction({ nodeId, name: 'zoomFit', value: false }));
    }, [zoomFit, zoomLevel]);
};
