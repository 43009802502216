import { connect } from 'react-redux';
import { TImportDialogProps } from '../../types/ImportDialog.types';
import { ImportDialog } from '../../components/ImportDialog/ImportDialog.component';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { getDatabaseDialogState } from '../../../../selectors/databaseDialog.selectors';
import {
    importDialogCancel,
    submitAllNodesMergeResult,
    submitNodeMergeResult,
} from '../../../../actions/importDialog.actions';
import { ConflictResolutionStrategy } from '../../../../services/api/custom/TransferApi';
import { NodeId } from '../../../../serverapi/api';

const mapStateToProps = (state: TRootState, props: TImportDialogProps) => {
    return {
        error: getDatabaseDialogState(state),
        parentId: props.parentId,
        name: props.path,
        itemId: props.itemId,
        itemType: props.itemType,
    };
};

const mapDispatchToProps = (dispatch) => ({
    submitNode: (itemId: NodeId, resolveConflictMethod: ConflictResolutionStrategy) =>
        dispatch(submitNodeMergeResult(itemId, resolveConflictMethod)),
    onCancel: () => dispatch(importDialogCancel()),
    submitAllNodes: (resolveConflictMethod: ConflictResolutionStrategy) =>
        dispatch(submitAllNodesMergeResult(resolveConflictMethod)),
});

export const ImportDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ImportDialog);
