import { LinkRenderer } from './LinkRenderer.component';
import { TLinkRendererProps } from '../../RichTextEditor.types';
import { connect } from 'react-redux';
import { appOpenUri } from '@/actions/app.actions';
import { TRootState } from '@/reducers/root.reducer.types';
import { APP_PREFIX } from '@/utils/configuration';
import electron from 'electron';

const mapStateToProps = (state: TRootState, ownProps: TLinkRendererProps): Partial<TLinkRendererProps> => {
    return {
        children: ownProps.children,
        href: ownProps.href,
        title: ownProps.title,
    };
};

const mapDispatchToProps: (dispatch) => Partial<TLinkRendererProps> = (dispatch) => ({
    onClickOpen: (href: string) => {
        if (href.indexOf(APP_PREFIX) !== -1) {
            dispatch(appOpenUri(href));
        } else if (electron) {
            electron.shell.openExternal(href);
        } else {
            dispatch(appOpenUri(href));
        }
    },
});

export const LinkRendererContainer = connect(mapStateToProps, mapDispatchToProps)(LinkRenderer);
