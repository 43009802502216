import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import LicensesDistributionComponent from '../components/LicensesDistribution.component';
import {
    TLicensesDistributionProps,
    TLicensesDistributionActionProps,
} from '../LicensesDistribution.types';
import { fetchOwnerInfo, openLicenseOwnersEditingTab, fetchLicenses } from '../../../../actions/licenses.actions';
import { UserDTOLicensesEnum } from '../../../../serverapi/api';
import { LicenseSelectors } from '../../../../selectors/license.selectors';
import { withWindowResize } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';

const mapStateToProps = (state: TRootState): Partial<TLicensesDistributionProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const owners = serverId ? LicenseSelectors.getOwners(serverId)(state) : undefined;
 
    return {
        serverId,
        owners,
    };
};

const mapDispatchToProps: (dispatch) => TLicensesDistributionActionProps = (dispatch) => ({
    onEditClicked: (licenseType: UserDTOLicensesEnum) => {
        dispatch(openLicenseOwnersEditingTab({ licenseType }))
    },
    fetchData: (serverId: string) => {
        dispatch(fetchOwnerInfo(serverId));
        dispatch(fetchLicenses(serverId));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withWindowResize(LicensesDistributionComponent));
