import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import AssignUserGroupsDialog from '../components/AssignUserGroupsDialog.component';
import {
    TAssignUserGroupsDialogProps,
    TAssignUserGroupsDialogActionProps,
    TAssignUserGroupsDialogOwnProps,
} from '../AssignUserGroupsDialog.types';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { fetchAllGroupsRequest } from '../../../../actions/groups.actions';
import { GroupsSelectors } from '../../../../selectors/groups.selectors';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { UserDTO, GroupDTO } from '../../../../serverapi/api';
import { saveUsersGroupsChanges } from '../../../../actions/users.actions';

const mapStateToProps = (
    state: TRootState,
    ownProps: TAssignUserGroupsDialogOwnProps,
): Partial<TAssignUserGroupsDialogProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const userData = UsersSelectors.getEditingUser(`${ownProps.userId}`)(state) || ({} as UserDTO);

    return {
        groups: GroupsSelectors.getAll(serverId)(state),
        usersGroups: userData.groups,
        serverId,
    };
};

const mapDispatchToProps: (dispatch, ownProps: TAssignUserGroupsDialogOwnProps) => TAssignUserGroupsDialogActionProps =
    (dispatch, ownProps) => ({
        onClose: () => dispatch(closeDialog(DialogType.EDIT_ASSIGN_USER_GROUPS)),
        onSubmit: (usersGroups: GroupDTO[]) => {
            dispatch(saveUsersGroupsChanges(usersGroups, `${ownProps.userId}`));
            dispatch(closeDialog(DialogType.EDIT_ASSIGN_USER_GROUPS));
        },
        fetchGroups: (serverId: string) => dispatch(fetchAllGroupsRequest(serverId)),
    });

export default connect(mapStateToProps, mapDispatchToProps)(AssignUserGroupsDialog);
