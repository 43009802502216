import { TreeNode } from '@/models/tree.types';

export const searchTreeNodes = (searchValue: string, treeTypes: TreeNode[] | undefined): TreeNode[] => {
    if (searchValue === '' || !treeTypes) return treeTypes || [];
    const result: TreeNode[] = [];

    treeTypes.forEach((typeGroup) => {
        if (typeGroup.name.toLowerCase().includes(searchValue.toLowerCase())) {
            result.push(typeGroup);
        } else {
            const filteredTypeGroupChildren: TreeNode[] | undefined = typeGroup.children?.filter((treeNode) => {
                return (
                    treeNode.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                    treeNode.nodeId.id.toLowerCase().includes(searchValue.toLowerCase())
                );
            });
            if (filteredTypeGroupChildren && filteredTypeGroupChildren.length) {
                result.push({ ...typeGroup, children: filteredTypeGroupChildren });
            }
        }
    });

    return result;
};
