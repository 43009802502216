import React from 'react';
import theme from './CommentUploadedFilesArea.scss';
import { renderIcon } from '../../utils/commentsUtils';
import { Icon } from '@/modules/UIKit';
import icBin from '../../../../resources/icons/icBin.svg';
import { CommentFileDTO } from '@/serverapi/api';
import { formatBytes } from '../../../../utils/formatBytes.utils';

type TCommentUploadedFilesAreaProps = {
    commentFiles: CommentFileDTO[];
    uploadedFiles: File[];
    isFullSize?: boolean;
    setFiles: (newFiles?: File[], commentFiles?: CommentFileDTO[]) => void;
};

export const CommentUploadedFilesArea = (props: TCommentUploadedFilesAreaProps) => {
    const { commentFiles, uploadedFiles, isFullSize, setFiles } = props;

    const fileNameRender = (fileName: string) => (
        <div className={theme.fileNameWrapper}>
            {renderIcon(fileName)}
            <div className={theme.fileName}>
                <div className={theme.fileNameText}>{fileName}</div>
            </div>
        </div>
    );

    const fileRender = (file: File | CommentFileDTO, onDelete: () => void) => (
        <>
            {isFullSize ? (
                <div className={theme.fileContainer}>
                    <div className={theme.fileBackgroundContainer}>
                        <div className={theme.fileRow}>
                            {fileNameRender(file.name)}
                            <Icon spriteSymbol={icBin} className={theme.deleteIcon} onClick={onDelete} />
                        </div>

                        <div className={theme.fileSizeContainer}>{formatBytes(file.size)}</div>
                    </div>
                </div>
            ) : (
                <div className={theme.container}>
                    <div className={theme.fileNameWrapper}>
                        {renderIcon(file.name)}
                        <div className={theme.fileName}>
                            <div className={theme.fileNameText}>{file.name}</div>
                        </div>
                    </div>

                    <div className={theme.rowEnd}>
                        <div className={theme.fileSize}>{formatBytes(file.size)}</div>
                        <Icon dataTest='uploaded-files-area_delete-icon' spriteSymbol={icBin} className={theme.deleteIcon} onClick={onDelete} />
                    </div>
                </div>
            )}
        </>
    );

    return (
        <div className={isFullSize ? theme.filesWrapper : ''}>
            {commentFiles.map((file: CommentFileDTO) =>
                fileRender(file, () =>
                    setFiles(
                        undefined,
                        commentFiles.filter((commentFile) => commentFile.id !== file.id),
                    ),
                ),
            )}
            {uploadedFiles.map((file: File, id: number) =>
                fileRender(file, () => setFiles(uploadedFiles.filter((_, i) => i !== id))),
            )}
        </div>
    );
};
