import { cloneDeep } from 'lodash-es';
import { TReducer } from '../utils/types';
import { SCRIPT_QUERY_PARAM_VALUES_REQUEST_SUCCESS } from '../actionsTypes/scriptQueryParams.actionTypes';
import { TScriptQueryParamsState } from './scriptQueryParams.reducer.types';

const initial: TScriptQueryParamsState = {};

export const scriptQueryParamsReducer: TReducer<TScriptQueryParamsState> = (state = initial, action) => {
    switch (action.type) {
        case SCRIPT_QUERY_PARAM_VALUES_REQUEST_SUCCESS: {
            const { data } = action.payload;
            const newState = cloneDeep(state);
            data.forEach((value) => {
                const { name, values } = value;
                newState[name] = values;
            });

            return newState;
        }
        default:
            return state;
    }
};
