import React, { FC } from 'react';
import theme from './MainMenu.scss';
import partTabRight from '../../../../resources/icons/tabParts/part-tab-right-bg.svg';
import partTabLeft from '../../../../resources/icons/tabParts/part-tab-left-bg.svg';
import { Icon } from '@/modules/UIKit';

type TMainMenuTabProps = {
    title: string;
};

export const MainMenuTab: FC<TMainMenuTabProps> = ({ title }) => {
    return (
        <>
            <Icon className={theme.leftIcon} spriteSymbol={partTabLeft} />
            {title}
            <Icon className={theme.rightIcon} spriteSymbol={partTabRight} />
        </>
    );
};
