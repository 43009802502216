import { MatrixLane, NodeId } from '../../../../serverapi/api';

export type TCreateDefinitionDialogProps = {
    open: boolean;
    nodeId: NodeId;
    currentRow: MatrixLane;
    currentCol: MatrixLane;
    rowIcon?: string;
    colIcon?: string;
};

export enum EdgeDefinitionDirection {
    direct = 'direct',
    reverse = 'reverse',
}
