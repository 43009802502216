import type { TAreaCellProps, TAreaSelectorCellProps } from './AreaSelector.types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import theme from './AreaSelector.scss';
import { Areas } from '../../FloatingAttributes.constants';
import messages from '../FloatingAttributes.messages';
import { floatingAttributesSelectAreaAction } from '../../actions/FloatingAttributes.actions';
import { FloatingAttributesPanelSelectors } from '../../selectors/FloatingAttributes.selectors';

const styles = {
    getAreaCellContainerStyle: ({ isMainCell, width, height }) => ({
        width: `${width}px`,
        height: `${height}px`,
        ...(isMainCell && {
            border: '2px dashed #6c8ebf',
            backgroundColor: '#dae8fc',
        }),
    }),
    getInnerAreaCellStyle: ({ gutter, active, width, height, selected }) => ({
        width: `${width - gutter}px`,
        height: `${height - gutter}px`,
        ...(selected && {
            border: '2px dashed #82b366',
            backgroundColor: '#d5e8d4',
        }),
        ...(active && {
            border: '2px solid #d79b00',
            backgroundColor: '#ffe6cc',
        }),
    }),
};

const AreaCell = (props: TAreaCellProps) => {
    const { selected, disabled, width = 200, height = 100, title, active, onClick } = props;
    const getGutter = () => (props.main ? 24 : 8);

    return (
        <div
            className={theme.areaCellContainer}
            style={styles.getAreaCellContainerStyle({ isMainCell: props.main, width, height })}
        >
            {!disabled && (
                <div
                    data-test="container_for_attributes_around_an_object"
                    className={theme.innerAreaCell}
                    style={styles.getInnerAreaCellStyle({ gutter: getGutter(), active, width, height, selected })}
                    onClick={onClick}
                    title={title}
                >
                    {!!selected && (
                        <div data-test={`container_attribute-with-name-${title}`} className={theme.selectedIndicator}>
                            {title}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const AreaSelectorCell = ({ height, width, overlayPositionKey, main }: TAreaSelectorCellProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const suffix = intl.formatMessage(messages.ignorePresetStylesPostfix);
    const selectedAreaKey = useSelector(FloatingAttributesPanelSelectors.getSelectedAreaKey);
    const { title, filled, disabled } = useSelector(
        FloatingAttributesPanelSelectors.getSelectedAreaState(overlayPositionKey, suffix),
    );
    const isActive: boolean = selectedAreaKey === overlayPositionKey;

    const handleClick = (): void => {
        dispatch(floatingAttributesSelectAreaAction(overlayPositionKey));
    };

    return (
        <AreaCell
            main={main || false}
            onClick={handleClick}
            height={height}
            width={width}
            active={isActive}
            title={title}
            selected={filled}
            disabled={disabled}
        />
    );
};

export const OverlayAreaSelector = () => {
    const h = 110;
    const w = 200;
    const hh = h / 2;
    const ww = w / 2;

    return (
        <div>
            <div className={theme.areaRow}>
                <AreaSelectorCell overlayPositionKey={Areas.LEFT_TOP} height={hh} width={ww} />
                <AreaSelectorCell overlayPositionKey={Areas.CENTER_TOP} height={hh} width={w} />
                <AreaSelectorCell overlayPositionKey={Areas.RIGHT_TOP} height={hh} width={ww} />
            </div>
            <div className={theme.areaRow}>
                <AreaSelectorCell overlayPositionKey={Areas.LEFT_MIDDLE} height={h} width={ww} />
                <AreaSelectorCell overlayPositionKey={Areas.CENTER_MIDDLE} main height={h} width={w} />
                <AreaSelectorCell overlayPositionKey={Areas.RIGHT_MIDDLE} height={h} width={ww} />
            </div>
            <div className={theme.areaRow}>
                <AreaSelectorCell overlayPositionKey={Areas.LEFT_BOTTOM} height={hh} width={ww} />
                <AreaSelectorCell overlayPositionKey={Areas.CENTER_BOTTOM} height={hh} width={w} />
                <AreaSelectorCell overlayPositionKey={Areas.RIGHT_BOTTOM} height={hh} width={ww} />
            </div>
        </div>
    );
};
