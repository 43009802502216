import { TReducer } from '../utils/types';
import {
    PRINCIPAL_REQUEST_SUCCESS,
    PRINCIPAL_REQUEST_FAILURE,
    PRINCIPAL_REQUEST,
} from '../actionsTypes/principal.actionTypes';
import { TPrincipalsState } from './principals.reducer.types';

const initial: TPrincipalsState = {
    loading: true,
    error: '',
    data: [],
};

export const principalsReducer: TReducer<TPrincipalsState> = (state = initial, action) => {
    switch (action.type) {
        case PRINCIPAL_REQUEST: {
            return {
                ...state,
                loading: true,
                error: '',
            };
        }
        case PRINCIPAL_REQUEST_SUCCESS: {
            const { data } = action.payload;

            return {
                ...state,
                loading: false,
                error: '',
                data,
            };
        }
        case PRINCIPAL_REQUEST_FAILURE: {
            const { error } = action.payload;

            return {
                ...state,
                error,
            };
        }
        default:
            return state;
    }
};
