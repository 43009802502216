import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'MatrixCreateDefinitionDialog.title',
        defaultMessage: 'Создание определения связи',
    },
    edgeType: {
        id: 'MatrixCreateDefinitionDialog.edgeType',
        defaultMessage: 'Вид связи',
    },
    submit: {
        id: 'MatrixCreateDefinitionDialog.submit',
        defaultMessage: 'Создать',
    },
    cancel: {
        id: 'MatrixCreateDefinitionDialog.cancel',
        defaultMessage: 'Отмена',
    },
    source: {
        id: 'MatrixCreateDefinitionDialog.source',
        defaultMessage: 'Источник:',
    },
    target: {
        id: 'MatrixCreateDefinitionDialog.target',
        defaultMessage: 'Цель:',
    },
});
