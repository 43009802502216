import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import icAttribute from '../../../../../../../resources/icons/ic-attribute.svg';
import { AttributeType, AttributeTypeGroup } from '../../../../../../../serverapi/api';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { GroupedTypesTable } from '../../util/GroupedTypesTable.component';
import messages from '../AttributeType.messages';
import { TCommonType, TTableDataType } from '../../util/GroupedTypesTable.types';
import { useAttributeNameWithType } from '../../util/useAttributeNameWithType';
import { filterAttributes } from '../../util/AttributeTypes.utils';
import theme from './AttributesTabDialog.scss';
import { SearchInput } from '../../../../../../UIKit/components/Select/SearchInput.component';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TAttributesTabDialogIntlProps = {
    attributeTypes: AttributeType[];
    dialogVisible: boolean;
};

type TAttributesTabDialogIntlActionProps = {
    onOk: (attributes: AttributeType[]) => void;
    onCancel: () => void;
};

type TAttributesTabDialogIntlFullProps = TAttributesTabDialogIntlProps & TAttributesTabDialogIntlActionProps;

export const AttributesTabDialog = (props: TAttributesTabDialogIntlFullProps) => {
    const intl = useIntl();

    const [newAttributes, setNewAttributes] = useState<AttributeType[]>([]);
    const [selectedTypeGroups, setSelectedGroups] = useState<TCommonType[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchFilter(e.target.value);
    }

    const attributeTypeGroups: AttributeTypeGroup[] = props.attributeTypes
        .map((at) => at.attributeTypeGroup)
        .reduce((acc: AttributeTypeGroup[], atg: AttributeTypeGroup) => {
            if (!acc.some((attributeTypeGroup) => atg.id === attributeTypeGroup.id)) {
                acc.push(atg);
            }

            return acc;
        }, []);

    const attributeTypes: AttributeType[] = props.attributeTypes?.map((type) => ({
        ...type,
        groupId: type.attributeTypeGroup?.id,
    }));
    const attributeNameWithType = useAttributeNameWithType(attributeTypes);

    const onSelectType = (attributes: TTableDataType[]) =>
        setNewAttributes(filterAttributes(attributeTypes, attributes));

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: props.onCancel,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'new-attribute-type-window_cancel-button',
                },
                {
                    key: 'ok',
                    onClick: () => props.onOk(newAttributes),
                    value: intl.formatMessage(messages.ok),
                    visualStyle: 'primary',
                    dataTest: 'new-attribute-type-window_save-button',
                },
            ]}
        />
    );

    return (
        <div>
            <Dialog
                onCancel={props.onCancel}
                open={props.dialogVisible}
                title={intl.formatMessage(messages.addAttributes)}
                footer={footer}
                className={theme.dialog}
            >
                <SearchInput showSearch originalTheme onSearch={handleSearch} searchValue={searchFilter} />
                <div>
                    <div className={theme.title}>{intl.formatMessage(messages.name)}</div>
                </div>
                <GroupedTypesTable
                    types={attributeNameWithType}
                    typeGroups={attributeTypeGroups}
                    searchFilter={searchFilter}
                    actionsDisabled
                    onSelectType={onSelectType}
                    selectedTypes={newAttributes}
                    selectedTypeGroups={selectedTypeGroups}
                    onSelectGroup={setSelectedGroups}
                    icon={icAttribute}
                    hideGUIDColumn
                    disableHeader
                    rowHeight={32}
                    rowClassName={theme.rowTable}
                    containerClassName={theme.tableContainer}
                    minTableWidth={384}
                />
            </Dialog>
        </div>
    );
};
