import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'antd';
import messages from './AtrributeHorizontalAlignSelector.messages';
import { useIntl } from 'react-intl';
import AlignButton from '../../../common/Buttons/AlignButton.component';
import icLeft from '../../../../../../resources/icons/ic-ribbon-text-left.svg';
import icCenter from '../../../../../../resources/icons/ic-ribbon-text-center.svg';
import icRight from '../../../../../../resources/icons/ic-ribbon-text-right.svg';

type TAttributeImageSizeSelectorProps = {
    initialValue: string | undefined;
    disabled?: boolean;
    onChange: (style: string) => void;
};

export const AttributeHorizontalAlignSelector = ({
    initialValue,
    disabled,
    onChange,
}: TAttributeImageSizeSelectorProps) => {
    const intl = useIntl();
    const [horizontalAlign, setHorizontalAlign] = useState(initialValue);

    useEffect(() => {
        setHorizontalAlign(initialValue);
    }, [initialValue]);

    const onClick = useCallback(
        (align: string) => {
            return () => {
                setHorizontalAlign(align);
                if (onChange) onChange(align);
            };
        },
        [onChange, setHorizontalAlign],
    );

    return (
        <Row gutter={[2, 2]}>
            <Col
                data-test="property-window_attribute-around_align_left">
                <AlignButton
                    tooltip={intl.formatMessage(messages.alignLeft)}
                    icon={icLeft}
                    onClick={onClick('left')}
                    selected={horizontalAlign === 'left'}
                    disabled={disabled}
                />
            </Col>
            <Col
                data-test="property-window_attribute-around_align_center">
                <AlignButton
                    tooltip={intl.formatMessage(messages.alignCenter)}
                    icon={icCenter}
                    onClick={onClick('center')}
                    selected={horizontalAlign === 'center'}
                    disabled={disabled}
                />
            </Col>
            <Col
                data-test="property-window_attribute-around_align_right">
                <AlignButton
                    tooltip={intl.formatMessage(messages.alignRight)}
                    icon={icRight}
                    onClick={onClick('right')}
                    selected={horizontalAlign === 'right'}
                    disabled={disabled}
                />
            </Col>
        </Row>
    );
};
