import { TReducer } from '../../utils/types';
import { MatrixType } from '../../serverapi/api';
import { CLEAR_PRESET_REQUEST } from '../../actionsTypes/methodologySetting.actionTypes';
import {
    IPresetSettingsMatrixModelTypeData,
    TPresetSettingsMatrixModelTypeState,
} from './presetSettingsMatrixModelType.reducer.types';
import {
    PRESET_SETTINGS_ADD_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_GET_ALL_MATRIX_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_SUBMIT_MATRIX_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_MATRIX_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsMatrixModelTypes.actionTypes';

export const PRESET_SETTINGS_INITIAL_MATRIX_MODEL_TYPE_SERVER_STATE: IPresetSettingsMatrixModelTypeData = {
    byId: {},
    matrixModelTypesForDelete: [],
    matrixModelTypesForSave: [],
};

const initial: TPresetSettingsMatrixModelTypeState = {
    byPresetId: {},
};

const mappingMatrixModelTypes = (
    matrixModelTypes: MatrixType[],
): {
    [id: string]: MatrixType;
} => {
    const matrixModelTypesMap = matrixModelTypes.reduce(
        (
            result: {
                [id: string]: MatrixType;
            },
            item: MatrixType,
        ) => {
            result[item.id] = item;

            return result;
        },
        {},
    );

    return matrixModelTypesMap;
};

const setNewData = (
    state: TPresetSettingsMatrixModelTypeState,
    presetId: string,
    newData: Partial<IPresetSettingsMatrixModelTypeData>,
): TPresetSettingsMatrixModelTypeState => {
    return {
        ...state,
        byPresetId: {
            ...state.byPresetId,
            [presetId]: {
                ...PRESET_SETTINGS_INITIAL_MATRIX_MODEL_TYPE_SERVER_STATE,
                ...state.byPresetId[presetId],
                ...newData,
            },
        },
    };
};

export const presetSettingsMatrixModelTypeReducer: TReducer<TPresetSettingsMatrixModelTypeState> = (
    state = initial,
    action,
) => {
    switch (action.type) {
        case PRESET_SETTINGS_GET_ALL_MATRIX_MODEL_TYPES_SUCCESS: {
            const { matrixModelTypes, presetId } = action.payload;
            const matrixModelTypesMap = mappingMatrixModelTypes(matrixModelTypes);

            const newData: Partial<IPresetSettingsMatrixModelTypeData> = {
                byId: matrixModelTypesMap,
                matrixModelTypesForDelete: [],
                matrixModelTypesForSave: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_ADD_MATRIX_MODEL_TYPE: {
            const { matrixModelTypes, presetId } = action.payload;
            const matrixModelTypesMap = mappingMatrixModelTypes(matrixModelTypes);

            const newData: Partial<IPresetSettingsMatrixModelTypeData> = {
                byId: {
                    ...state.byPresetId[presetId]?.byId,
                    ...matrixModelTypesMap,
                },
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_SUBMIT_MATRIX_MODEL_TYPE: {
            const { presetId } = action.payload;

            const newData: Partial<IPresetSettingsMatrixModelTypeData> = {
                matrixModelTypesForDelete: [],
                matrixModelTypesForSave: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_TRANSFER_MATRIX_MODEL_TYPE: {
            const { matrixModelTypes, presetId } = action.payload;
            const matrixModelTypesMap = mappingMatrixModelTypes(matrixModelTypes);

            const newData: Partial<IPresetSettingsMatrixModelTypeData> = {
                byId: {
                    ...state.byPresetId[presetId]?.byId,
                    ...matrixModelTypesMap,
                },
                matrixModelTypesForSave: [...state.byPresetId[presetId]?.matrixModelTypesForSave, ...matrixModelTypes],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_DELETE_MATRIX_MODEL_TYPE: {
            const { matrixModelTypesForDelete, presetId } = action.payload;
            const allMatrixModelTypes = state.byPresetId[presetId]?.byId || {};
            const filteredKanbanModelTypes = Object.keys(allMatrixModelTypes).reduce(
                (res: { [id: string]: MatrixType }, id) => {
                    const findInDeleteList = matrixModelTypesForDelete.some(
                        (matrixModelType) => matrixModelType.id === id,
                    );
                    if (!findInDeleteList) {
                        res[id] = allMatrixModelTypes[id];
                    }

                    return res;
                },
                {},
            );

            const newData: Partial<IPresetSettingsMatrixModelTypeData> = {
                byId: filteredKanbanModelTypes,
                matrixModelTypesForDelete: [
                    ...state.byPresetId[presetId]?.matrixModelTypesForDelete,
                    ...matrixModelTypesForDelete,
                ],
            };

            return setNewData(state, presetId, newData);
        }

        case CLEAR_PRESET_REQUEST: {
            const { presetId } = action.payload;
            delete state.byPresetId?.[presetId];

            return { ...state };
        }

        default: {
            return state;
        }
    }
};
