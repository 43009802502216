import { EditorMode } from '../../models/editorMode';
import { BPMMxEdgeHandler } from '../handler/BPMMxEdgeHandler';
import { MxRectangle, MxCellState, MxConstants, MxMouseEvent, MxPoint } from '../mxgraph';
import { MxShape } from '../shapes/MxShape';
import { MxUtils } from '../util/MxUtils';
import { SequenceGraph } from './SequenceGraph';

/**
 * Класс отвечате за обработку контрольных точек на связи
 */
export class SequenceEdgeHandler extends BPMMxEdgeHandler {
    addEnabled: false;
    preferHtml: false;
    graph: SequenceGraph;
    label: MxPoint;

    init() {
        super.init();
        this.index = null;
    }

    /**
     * Событие обработки движения мыши при перемещении контрольных точек
     * @param { any } sender
     * @param { MxMouseEvent } me информация о событии
     */
    mouseMove(sender, me) {
        if (this.graph.mode === EditorMode.Read) return;
        if (this.isLabel) {
            const currentPoint = this.getPointForEvent(me);
            this.label.x = currentPoint.x;
            this.label.y = currentPoint.y;
            this.drawPreview();
        } else if (this.index !== null && this.state && this.graph.isRecursiveEdge(this.state.cell)) {
            const edgeState = this.state;
            const absPoints = edgeState.absolutePoints;
            const currentPoint = this.getPointForEvent(me);
            const srcState: MxCellState = edgeState.visibleSourceState;
            const trgState: MxCellState = edgeState.visibleTargetState;
            currentPoint.y = Math.max(currentPoint.y, srcState.y);

            if (this.index === 0) {
                const maxY = absPoints[3].y;
                absPoints[0] = new MxPoint(absPoints[0].x, Math.min(currentPoint.y, maxY));
                absPoints[1] = new MxPoint(absPoints[1].x, Math.min(currentPoint.y, maxY));

                const srcContains = MxUtils.contains(srcState, srcState.x, currentPoint.y);
                if (!srcContains) this.graph.setCellHeight(srcState);
            }
            if (this.index === 2) {
                const minY = absPoints[0].y;
                absPoints[2] = new MxPoint(absPoints[2].x, Math.max(currentPoint.y, minY));
                absPoints[3] = new MxPoint(absPoints[3].x, Math.max(currentPoint.y, minY));

                const trgContains = MxUtils.contains(trgState, trgState.x, currentPoint.y);
                if (!trgContains) this.graph.setCellHeight(trgState);
            }

            if (this.index === 1) {
                const minX = Math.max(absPoints[0].x, absPoints[absPoints.length - 1].x);
                const x = Math.max(minX + 20, currentPoint.x);
                absPoints[1] = new MxPoint(x, absPoints[1].y);
                absPoints[2] = new MxPoint(x, absPoints[2].y);
            }
            this.redraw(true, me);
            const s = this.graph.view.scale;
            edgeState.cell.geometry.points = absPoints.map((point: MxPoint) => new MxPoint(point.x / s, point.y / s));
        }
    }

    /**
     * Событие обработки нажатия мыши
     * @param { any } sender
     * @param { MxMouseEvent } me информация о событии
     */
    mouseDown(sender, me: MxMouseEvent) {
        const handle = this.getHandleForEvent(me);
        if (this.bends != null && this.bends[handle] != null) {
            const b = this.bends[handle].bounds;
            this.snapPoint = new MxPoint(b.getCenterX(), b.getCenterY());
            this.index = handle;
        } else {
            this.index = null;
        }

        super.mouseDown(sender, me);
    }

    /**
     * Метод создания контрольных точек для связи
     */
    createBends() {
        const { cell } = this.state;
        const bends: MxShape[] = [];

        for (let i = 0; i < this.abspoints.length; i++) {
            if (this.isHandleVisible()) {
                const source = i === 0;
                const target = i === this.abspoints.length - 1;
                let middle: boolean = false;
                if (this.abspoints.length === 4) {
                    middle = i === 1;
                }
                const terminal = source || target || middle;

                if (terminal || this.graph.isCellBendable(cell)) {
                    const bend = this.createHandleShape(i);
                    this.initBend(bend, () => {
                        if (this.dblClickRemoveEnabled) {
                            this.removePoint(this.state, i);
                        }
                    });

                    if (this.isHandleEnabled(i)) {
                        bend.setCursor(terminal ? MxConstants.CURSOR_TERMINAL_HANDLE : MxConstants.CURSOR_BEND_HANDLE);
                    }

                    bends.push(bend);

                    if (!terminal) {
                        this.points.push(new MxPoint(0, 0));
                        bend.node.style.visibility = 'hidden';
                    }
                }
            }
        }

        return bends;
    }

    /**
     * Метод перерисования изгибов связи
     */
    redrawInnerBends() {
        for (let i = 0; i < this.bends.length - 1; i++) {
            if (this.bends[i] != null) {
                if (this.abspoints[i] != null) {
                    const { x } = this.abspoints[i];
                    let { y } = this.abspoints[i];
                    if (this.abspoints.length === 4 && i === 1) {
                        const y1 = this.abspoints[1].y;
                        const y2 = this.abspoints[2].y;
                        const dy = Math.abs((y2 - y1) / 2);
                        y = Math.max(y1, y2) - dy;
                    }

                    const b = this.bends[i].bounds;
                    this.bends[i].node.style.visibility = 'visible';
                    this.bends[i].bounds = new MxRectangle(
                        Math.round(x - b.width / 2),
                        Math.round(y - b.height / 2),
                        b.width,
                        b.height,
                    );

                    if (this.manageLabelHandle) {
                        this.checkLabelHandle(this.bends[i].bounds);
                    } else if (
                        this.handleImage == null &&
                        this.labelShape.visible &&
                        MxUtils.intersects(this.bends[i].bounds, this.labelShape.bounds)
                    ) {
                        const w = MxConstants.HANDLE_SIZE + 3;
                        const h = MxConstants.HANDLE_SIZE + 3;
                        this.bends[i].bounds = new MxRectangle(Math.round(x - w / 2), Math.round(y - h / 2), w, h);
                    }

                    this.bends[i].redraw();
                } else {
                    this.bends[i].destroy();
                    this.bends[i] = null;
                }
            }
        }
    }

    /**
     * Метод перерисовки предварительный просмотр, контрольных точек и изгибов связи
     * @param { any } ignoreHandles
     * @param { MxMouseEvent } me информация о событии
     */
    redraw(ignoreHandles, me?) {
        if (this.state != null) {
            this.abspoints = this.state.absolutePoints.slice();
            const g = this.graph.getModel().getGeometry(this.state.cell);

            if (g != null) {
                const pts = g.points;

                if (this.bends != null && this.bends.length > 0) {
                    if (pts != null) {
                        if (this.points == null) {
                            this.points = [];
                        }

                        for (let i = 1; i < this.bends.length - 1; i++) {
                            if (this.bends[i] != null && this.abspoints[i] != null) {
                                this.points[i - 1] = pts[i - 1];
                            }
                        }
                    }
                }
            }

            this.drawPreview();

            if (!ignoreHandles) {
                this.redrawHandles();
            }
        }
        if (me && (this.index === 0 || this.index === 2) && this.graph.isRecursiveEdge(this.state.cell)) {
            const edgeState = this.state;
            const currentPoint = this.getPointForEvent(me);
            const srcState: MxCellState = edgeState.visibleSourceState;
            const trgState: MxCellState = edgeState.visibleTargetState;
            currentPoint.y = Math.max(currentPoint.y, srcState.y);
            const constrainSource = this.graph.getOutlineConstraint(
                currentPoint,
                srcState,
                edgeState,
                true,
                false,
                true,
            );
            const constrainTarget = this.graph.getOutlineConstraint(
                currentPoint,
                trgState,
                edgeState,
                false,
                false,
                true,
            );
            this.graph.setConnectionConstraint(edgeState.cell, constrainSource, constrainTarget);
        }
    }
}
