import {
    DELETE_USERS,
    DELETE_USER_SUCCESS,
    EDIT_USER_DATA_BEGIN,
    EDIT_USER_DATA_END,
    FETCH_ALL_USERS,
    FETCH_USERS_BY_GROUPID_REQUEST,
    FETCH_USERS_BY_GROUPID_SUCCESS,
    FETCH_USERS_BY_TREENODE,
    FETCH_USERS_SUCCESS,
    FETCH_USER_BYID,
    FETCH_USER_BYID_SUCCESS,
    FETCH_USER_SESSIONS,
    FETCH_USER_SESSIONS_SUCCESS,
    SET_USERS_BLOCK,
    OPEN_EDIT_USER_PERMISSIONS_DIALOG,
    OPEN_USERDATA_EDITING_TAB,
    OPEN_USERS_MANAGMENT_TAB,
    SAVE_USERS_ACCESSES_CHANGES,
    SAVE_USERS_GROUPS_CHANGES,
    SAVE_USERS_LICENSES_CHANGES,
    SAVE_USERS_REPOS_CHANGES,
    SUBMIT_USER_DATA,
} from '../actionsTypes/users.actionTypes';
import { TreeNode } from '../models/tree.types';
import { UserDTO, UserDTOLicensesEnum, GroupDTO, UserDTOAccessesEnum, UserSessionHistory } from '../serverapi/api';
import {
    TEditUserPermissionsParams,
    TDeleteUserSuccessAction,
    TDeleteUserSuccessPayload,
    TEditUserDataBeginAction,
    TEditUserDataEndAction,
    TFetchUserByIdAction,
    TFetchUserByIdSuccessAction,
    TFetchUserByIdSuccessPayload,
    TFetchUsersAction,
    TFetchUsersByGroupIdAction,
    TFetchUsersByGroupIdPayload,
    TFetchUsersByGroupIdSuccessAction,
    TFetchUsersByGroupIdSuccessPayload,
    TFetchUserSessionsAction,
    TFetchUserSessionsSuccessAction,
    TFetchUsersSuccessAction,
    TFetchUsersSuccessPayload,
    TOpenEditUserPermissionsDialogAction,
    TOpenUserDataEditingTabAction,
    TOpenUserDataEditingTabPayload,
    TOpenUserManagementTabAction,
    TSaveUsersAccesesChangesAction,
    TSaveUsersGroupsChangesAction,
    TSaveUsersLicensesChangesAction,
    TSaveUsersReposChangesAction,
    TSubmitUserDataAction,
    TSubmitUserDataActionPayload,
    TFetchAllUsersRequestAction,
    TFetchAllUsersRequestPayload,
    TSetUsersBlockAction,
    TDeleteUsersAction,
} from './users.actions.types';

export const fetchAllUsersRequest = (payload: TFetchAllUsersRequestPayload): TFetchAllUsersRequestAction => ({
    type: FETCH_ALL_USERS,
    payload,
});

export const fetchUsersRequest = (node: TreeNode): TFetchUsersAction => ({
    type: FETCH_USERS_BY_TREENODE,
    payload: {
        node,
    },
});

export const usersFetchedSuccess: (param: TFetchUsersSuccessPayload) => TFetchUsersSuccessAction = ({
    serverId,
    users,
}) => ({
    type: FETCH_USERS_SUCCESS,
    payload: {
        users,
        serverId,
    },
});

export const openUserManagementTab: (node: TreeNode) => TOpenUserManagementTabAction = (node) => ({
    type: OPEN_USERS_MANAGMENT_TAB,
    payload: { node },
});

export const openUserDataEditingTab: (payload: TOpenUserDataEditingTabPayload) => TOpenUserDataEditingTabAction = (
    payload,
) => ({
    type: OPEN_USERDATA_EDITING_TAB,
    payload,
});

export const editUserDataBegin: (userData: UserDTO) => TEditUserDataBeginAction = (userData) => ({
    type: EDIT_USER_DATA_BEGIN,
    payload: {
        userData,
    },
});

export const editUserDataEnd: (userId: number) => TEditUserDataEndAction = (userId) => ({
    type: EDIT_USER_DATA_END,
    payload: {
        userId,
    },
});

export const setUsersBlock: (usersId: number[], blockStatus: boolean) => TSetUsersBlockAction = (
    usersId,
    blockStatus,
) => ({
    type: SET_USERS_BLOCK,
    payload: {
        usersId,
        blockStatus,
    },
});

export const submitingUserData: (payload: TSubmitUserDataActionPayload) => TSubmitUserDataAction = (payload) => ({
    type: SUBMIT_USER_DATA,
    payload,
});

export const fetchUserById: (userId: number) => TFetchUserByIdAction = (userId) => ({
    type: FETCH_USER_BYID,
    payload: {
        userId,
    },
});

export const fetchUserByIdSuccess: (payload: TFetchUserByIdSuccessPayload) => TFetchUserByIdSuccessAction = (
    payload,
) => ({
    type: FETCH_USER_BYID_SUCCESS,
    payload,
});

export const fetchUsersByGroupIdRequest: (payload: TFetchUsersByGroupIdPayload) => TFetchUsersByGroupIdAction = (
    payload,
) => ({
    type: FETCH_USERS_BY_GROUPID_REQUEST,
    payload,
});

export const fetchUsersByGroupIdSuccess: (
    payload: TFetchUsersByGroupIdSuccessPayload,
) => TFetchUsersByGroupIdSuccessAction = (payload) => ({
    type: FETCH_USERS_BY_GROUPID_SUCCESS,
    payload,
});

export const openEditUserPermissionsDialog: (
    payload: TEditUserPermissionsParams,
) => TOpenEditUserPermissionsDialogAction = (payload) => ({
    type: OPEN_EDIT_USER_PERMISSIONS_DIALOG,
    payload,
});

export const saveUsersLicensesChanges: (
    usersLicenses: UserDTOLicensesEnum[],
    userId: string,
) => TSaveUsersLicensesChangesAction = (usersLicenses, userId) => ({
    type: SAVE_USERS_LICENSES_CHANGES,
    payload: { usersLicenses, userId },
});

export const saveUsersGroupsChanges: (usersGroups: GroupDTO[], userId: string) => TSaveUsersGroupsChangesAction = (
    usersGroups,
    userId,
) => ({
    type: SAVE_USERS_GROUPS_CHANGES,
    payload: { usersGroups, userId },
});

export const saveUsersReposChanges: (repos: string[], userId: string) => TSaveUsersReposChangesAction = (
    repos,
    userId,
) => ({
    type: SAVE_USERS_REPOS_CHANGES,
    payload: { repos, userId },
});

export const saveUsersAccesesChanges: (
    acceses: UserDTOAccessesEnum[],
    userId: string,
) => TSaveUsersAccesesChangesAction = (accesses, userId) => ({
    type: SAVE_USERS_ACCESSES_CHANGES,
    payload: { accesses, userId },
});

export const deleteUsers: (usersId: number[]) => TDeleteUsersAction = (usersId) => ({
    type: DELETE_USERS,
    payload: { usersId },
});

export const deleteUserSuccess: (payload: TDeleteUserSuccessPayload) => TDeleteUserSuccessAction = (payload) => ({
    type: DELETE_USER_SUCCESS,
    payload,
});

export const fetchUserSessions: (userId: number) => TFetchUserSessionsAction = (userId) => ({
    type: FETCH_USER_SESSIONS,
    payload: { userId },
});

export const fetchUserSessionsSuccess: (
    userId: number,
    sessions: UserSessionHistory[],
) => TFetchUserSessionsSuccessAction = (userId: number, sessions: UserSessionHistory[]) => ({
    type: FETCH_USER_SESSIONS_SUCCESS,
    payload: {
        userId,
        sessions,
    },
});
