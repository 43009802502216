export enum KeyCodes {
    BACKSPACE = 8,
    ESCAPE = 27,
    LEFT = 37,
    UP = 38,
    RIGHT = 39,
    DOWN = 40,
    DELETE = 46,
    ENTER = 13,
    A = 65,
    B = 66,
    C = 67,
    E = 69,
    F = 70,
    I = 73,
    L = 76,
    P = 80,
    R = 82,
    U = 85,
    V = 86,
    X = 88,
    Y = 89,
    Z = 90,
    F2 = 113,
    F12 = 123,
    PLUS = 187,
    MINUS = 189,
    KEY0 = 48,
    NUM_PLUS = 107,
    NUM_MINUS = 109,
    PLUS_MOZILLA = 61,
    MINUS_MOZILLA = 173,
}

export enum Keys {
    ENTER = 'Enter',
}
