export const OFFSET_TOP_BOTTOM = 10;

export const OFFSET_LEFT_RIGHT = OFFSET_TOP_BOTTOM * 2;

export const CELLS_GAP = 10;

export const SWIMLANE_NO_LABEL_FLAG = 'swimlaneNoLabel';

export const DEFAULT_CONTENT_COLUMN_WIDTH = 200;

export const DEFAULT_CONTENT_COLUMN_HEIGHT = 200;

