import { NodeId } from '../serverapi/api';
import { TTreeEntityState } from '../models/tree.types';
import { TreeItemType } from '../modules/Tree/models/tree';

export enum ExpandStatus {
    OPEN = 'open',
    CLOSED = 'closed',
    LOADING = 'loading',
}

type TypeData<T> = {
    byRepositoryId: {
        [repositoryId: string]: {
            byId: {
                [id: string]: T;
            };
        };
    };
};

export type TTreeState = {
    byServerId: {
        [serverId: string]: TypeData<TTreeEntityState>;
    };
    appliedFilters: TreeItemType[];
    showDeletedObjectsFilter: boolean;
    draggedId?: NodeId;
    scrolledNodeId?: NodeId;
    error?: string;
};
