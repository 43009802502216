import {
    TFolderTypeEditorCancelAction,
    TFolderTypeEditorInitAction,
    TFolderTypeEditorInitPayload,
    TFolderTypeEditorEditValueAction,
} from './folderTypeEditor.actions.types';
import {
    FOLDER_TYPE_EDITOR_CANCEL,
    FOLDER_TYPE_EDITOR_INIT,
    FOLDER_TYPE_EDITOR_EDIT_VALUE,
} from '../actionsTypes/folderTypeEditor.actionTypes';
import { FolderType } from '../serverapi/api';

export const folderTypeEditorInit = (payload: TFolderTypeEditorInitPayload): TFolderTypeEditorInitAction => ({
    type: FOLDER_TYPE_EDITOR_INIT,
    payload,
});

export const folderTypeEditorEditValue = (payload: Partial<FolderType>): TFolderTypeEditorEditValueAction => ({
    type: FOLDER_TYPE_EDITOR_EDIT_VALUE,
    payload,
});

export const folderTypeEditorCancel = (): TFolderTypeEditorCancelAction => ({
    type: FOLDER_TYPE_EDITOR_CANCEL,
});
