import React from 'react';
import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { SymbolEditorTab } from '../components/Presets/ObjectType/SymbolEditorTab/SymbolEditorTab.component';
import {
    TSymbolEditorTabActionProps,
    TSymbolEditorTabFullProps,
} from '../components/Presets/ObjectType/SymbolEditorTab/SymbolEditorTab.types';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { TreeNode } from '../../../../models/tree.types';
import { TPreset } from '../../../../models/preset.types';
import { SymbolSelectors } from '../../../../selectors/symbol.selectors';
import { ObjectType, Symbol } from '../../../../serverapi/api';
import { SymbolEditorSelectors } from '../../../../selectors/symbol/symbolEditor.selectors';
import {
    createSymbolRequest,
    submitSymbolRequest,
    symbolEditorUpdate,
} from '../../../../actions/symbol/symbolEditor.actions';
import { workspaceRemoveTabByNodeId } from '../../../../actions/tabs.actions';
import { IWorkspaceTabItemOpenSymbolEditorParams, TWorkspaceTab } from '../../../../models/tab.types';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { getSwimlaneSymbolWithIcon } from '../../../../models/Symbols.utils';
import { showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { omit } from 'lodash-es';
import { WrappedComponentProps } from 'react-intl';

type TSymbolEditorTabProps = IWorkspaceTabItemOpenSymbolEditorParams & {
    tab: TWorkspaceTab;
};

const mapStateToProps = (state: TRootState, props: TSymbolEditorTabProps): Partial<TSymbolEditorTabFullProps> => {
    const params = props.tab.params as IWorkspaceTabItemOpenSymbolEditorParams;
    const { serverNode, preset } = params;

    const serverId = serverNode?.nodeId.serverId!;
    const presetId = preset?.id!;
    const symbolId = params.symbol?.id!;
    const symbol = SymbolSelectors.byId(
        {
            id: symbolId,
            serverId,
        },
        presetId,
    )(state);

    const editableSymbol = SymbolEditorSelectors.editableSymbol({
        symbolId,
        presetId,
        serverId,
    })(state);

    return {
        ...params,
        symbol: symbol || editableSymbol,
        graphical: editableSymbol?.graphical,
        currentLocale: getCurrentLocale(state),
    };
};

const mapDispatchToProps: (dispatch, ownProps: TSymbolEditorTabProps) => TSymbolEditorTabActionProps = (
    dispatch,
    ownProps: TSymbolEditorTabProps,
) => ({
    openFileUploadDialog: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) =>
        dispatch(
            openDialog(DialogType.UPLOAD_SYMBOL_SVG_DIALOG, {
                preset,
                serverNode,
                symbol,
            }),
        ),
    onCancel: () => dispatch(workspaceRemoveTabByNodeId(ownProps.tab.nodeId)),

    submitSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) =>
        dispatch(
            submitSymbolRequest({
                preset,
                serverNode,
                symbol,
                tabNodeId: ownProps.tab.nodeId,
            }),
        ),
    createSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) =>
        dispatch(
            createSymbolRequest({
                preset,
                serverNode,
                symbol: getSwimlaneSymbolWithIcon(symbol),
                tabNodeId: ownProps.tab.nodeId,
            }),
        ),
    resetSymbolGraphical: (symbol: Symbol, serverId: string) =>
        dispatch(
            symbolEditorUpdate({
                symbol,
                serverId,
            }),
        ),
    showErrorNotification: () => dispatch(showNotificationByType(NotificationType.CANNOT_OPEN_SVG_ERROR)),
});

type TSymbolEditorTabErrorBoundaryProps = {
    symbol: Symbol;
    preset: TPreset;
    serverNode: TreeNode;
    isNewSymbol: boolean;
    objectType: ObjectType;
    graphical?: string;
    currentLocale: Locale;
    onCancel: () => void;
    submitSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    createSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    openFileUploadDialog: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    resetSymbolGraphical: (symbol: Symbol, serverId: string) => void;
    showErrorNotification: () => void;
} & WrappedComponentProps;

type TState = {
    hasError: boolean;
};

class SymbolEditorTabErrorBoundary extends React.Component<TSymbolEditorTabErrorBoundaryProps, TState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {}

    render() {
        if (this.state.hasError) {
            const symbol = omit(this.props.symbol, ['graphical']);
            const props = omit({ ...this.props, symbol }, ['graphical']) as TSymbolEditorTabErrorBoundaryProps;
            const serverId = this.props.serverNode?.nodeId.serverId!;

            this.props.resetSymbolGraphical(symbol as Symbol, serverId);
            this.setState({ hasError: false });
            this.props.showErrorNotification();
            return <SymbolEditorTab {...props} />;
        }

        return <SymbolEditorTab {...this.props} />;
    }
}

export const SymbolEditorTabContainer = connect(mapStateToProps, mapDispatchToProps)(SymbolEditorTabErrorBoundary);
