import { EventDescriptor } from '../serverapi/api';
import { ApiBundle, apiBundle } from './api/api-bundle';

export class EventAccessService {
    getEvents(params: { serverId: string }): Promise<EventDescriptor[]> {
        return this.getAPI(params.serverId).event.getEvents();
    }

    create(params: { serverId: string, body: EventDescriptor | undefined }): Promise<EventDescriptor> {
        return this.getAPI(params.serverId).event.create({body: params.body});
    }

    delete(params: { serverId: string, id: string }): Promise<void> {
        return this.getAPI(params.serverId).event._delete({eventId: params.id});
    }

    update(params: { serverId: string, body: EventDescriptor | undefined }): Promise<EventDescriptor> {
        return this.getAPI(params.serverId).event.update({body: params.body});
    }

    private getAPI(serverId: string): ApiBundle {
        try {
            const api = apiBundle(serverId);

            return api;
        } catch (e) {
            throw new Error(`Error in EventAccessService, no existing API for server with id=[${serverId}]`);
        }
    }
}

let service: EventAccessService;

export function eventAccessService(): EventAccessService {
    if (!service) {
        service = new EventAccessService();
    }
    
    return service;
}
