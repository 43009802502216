import { LOAD_MODEL_BY_ID, RELOAD_MODEL, RESTORE_MODEL_VERSION } from '../actionsTypes/loadModel.actionTypes';
import { NodeId } from '../serverapi/api';
import { TLoadModelByIdAction, TReloadModelAction, TRestoreModelVersionAction } from './loadModel.actions.types';

export const loadModelById = (nodeId: NodeId, elementIds?: Array<string | undefined>): TLoadModelByIdAction => ({
    type: LOAD_MODEL_BY_ID,
    payload: { nodeId, elementIds },
});

export const reloadModel = (nodeId: NodeId): TReloadModelAction => ({
    type: RELOAD_MODEL,
    payload: { nodeId },
});

export const restoreModelVersionAction = (nodeId: NodeId, version: number): TRestoreModelVersionAction => ({
    type: RESTORE_MODEL_VERSION,
    payload: { nodeId, version },
});
