import { TextBlockStyle } from './types';

export const getTextBlockRenderedSize = (text: string, blockStyle?: Partial<TextBlockStyle>) => {
    const div = document.createElement('div');

    if (blockStyle) {
        Object.keys(blockStyle).forEach((key) => {
            div.style[key] = blockStyle[key];
        });
    }
    div.innerHTML = String(text);

    document.body.append(div);
    const height = div.offsetHeight;
    const width = div.offsetWidth;

    div.remove();

    return { width, height };
};
