import { defineMessages } from 'react-intl';

export default defineMessages({
    copyDbFormName: {
        id: 'CopyDatabaseDialog.copyDbFormName',
        defaultMessage: 'Создание копии',
    },
    copyDbLabel: {
        id: 'CopyDatabaseDialog.copyDbLabel',
        defaultMessage: 'Имя базы данных',
    },
    copyDbPlaceholder: {
        id: 'CopyDatabaseDialog.copyDbPlaceholder',
        defaultMessage: 'Имя новой базы данных',
    },
    copyDbNameRequiredError: {
        id: 'CopyDatabaseDialog.copyDbNameRequiredError',
        defaultMessage: 'Имя базы данных должно быть заполнено',
    },
    copyDbFormConfirmButton: {
        id: 'CopyDatabaseDialog.copyDbFormConfirmButton',
        defaultMessage: 'Создать',
    },
    copyDbFormDeclineButton: {
        id: 'CopyDatabaseDialog.copyDbFormDeclineButton',
        defaultMessage: 'Отмена',
    },
});
