import React, { FC, useEffect } from 'react';
import { Modal } from 'antd';
import classnames from 'classnames';
import theme from './Dialog.scss';
import { TDialogProps } from './Dialog.types';
import { shouldCallModalSubmitHandler } from '../../../../services/utils/ModalHelper';
import { useDispatch } from 'react-redux';
import { clearContextMenuParentId } from '@/actions/contextMenu.actions';
import { activeContextMenuChange } from '../../../../actions/contextMenu.actions';
import { Keys } from '@/utils/keys';

export const Dialog: FC<TDialogProps> = (props) => {
    const dispatch = useDispatch();

    /** При открытии любой модалки закрываем контекстные меню */
    useEffect(() => {
        dispatch(clearContextMenuParentId());
        dispatch(activeContextMenuChange());
    }, []);

    return (
        <div
            ref={props.ref}
            onKeyUp={(e) => {
                // Не закрываем диалоговое окно на ALT + ENTER
                if (e?.altKey && e.key === Keys.ENTER) return;
                
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    // @ts-ignore
                    if (props.onOk) props.onOk(e);
                }
            }}
        >
            <Modal {...props} className={classnames(props.className, theme.container)} transitionName="" />
        </div>
    );
};

Dialog.defaultProps = {
    width: 480,
};
