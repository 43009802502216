import React, { useContext, useState } from 'react';
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import { ColorResult, SketchPicker } from 'react-color';
import ControlsContext from '../Controls.context';
import icBgColor from 'icons/toolbar/controls/ic-fill-color.svg';
import { ToolbarPopoverButton } from 'UIKit';
import theme from '../RichTextEditorToolbar.scss';
import { DEFAULT_BG_COLOR } from './Controls.constants';
import { Button } from 'antd';

const ButtonGroup = Button.Group;
const getFocusedBlock = (editorState) => {
    const bgColorStyle = getSelectionCustomInlineStyle(editorState, ['BGCOLOR'])?.BGCOLOR;

    if (bgColorStyle) {
        return bgColorStyle.replace('bgcolor-', '');
    }

    return DEFAULT_BG_COLOR;
};

const getChangedBlock = (editorState, value) => toggleCustomInlineStyle(editorState, 'bgcolor', value);

const BGColorComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const [currentColor, setCurrentColor] = useState(DEFAULT_BG_COLOR);
    const selectColorHandler = (colorObject: ColorResult) => {
        const { hex } = colorObject;
        setCurrentColor(hex);
        setCurrentState(hex);
    };

    return (
        <ButtonGroup className={theme.buttonGroup} data-test="wiki-toolbar-group_font-bgColor-button">
            <ToolbarPopoverButton
                tooltip={intl.formatMessage(messages.colorFilling)}
                color={value}
                spriteSymbol={icBgColor}
                className={theme.indicator}
            >
                <SketchPicker color={currentColor} onChange={selectColorHandler} disableAlpha/>
            </ToolbarPopoverButton>
        </ButtonGroup>
    );
};

export default BGColorComponent;
