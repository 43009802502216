// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeleteSeveralNodesDialog__dialog__NxhUO{-webkit-animation-duration:0s !important;animation-duration:0s !important}.DeleteSeveralNodesDialog__dialog__NxhUO .ant-modal-body{overflow:auto}.DeleteSeveralNodesDialog__alert__LThdd{width:100%}.DeleteSeveralNodesDialog__alertTitle__AmyCl{margin-bottom:12px}.DeleteSeveralNodesDialog__content__Vxc_a{padding-bottom:5px}.DeleteSeveralNodesDialog__removableElements__UIi_j{max-height:200px;overflow-y:auto;margin-bottom:10px}.DeleteSeveralNodesDialog__tab__cPdWr{height:22px;font-weight:bold}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/DeleteSeveralNodesDialog/DeleteSeveralNodesDialog.scss"],"names":[],"mappings":"AAAA,yCACI,wCAAA,CAAA,gCAAA,CAEA,yDACI,aAAA,CAIR,wCACI,UAAA,CAGJ,6CACI,kBAAA,CAGJ,0CACI,kBAAA,CAGJ,oDACI,gBAAA,CACA,eAAA,CACA,kBAAA,CAGJ,sCACI,WAAA,CACA,gBAAA","sourcesContent":[".dialog {\r\n    animation-duration: 0s !important;\r\n\r\n    :global(.ant-modal-body) {\r\n        overflow: auto;\r\n    }\r\n}\r\n\r\n.alert {\r\n    width: 100%;\r\n}\r\n\r\n.alertTitle {\r\n    margin-bottom: 12px;\r\n}\r\n\r\n.content {\r\n    padding-bottom: 5px;\r\n}\r\n\r\n.removableElements {\r\n    max-height: 200px;\r\n    overflow-y: auto;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.tab {\r\n    height: 22px;\r\n    font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "DeleteSeveralNodesDialog__dialog__NxhUO",
	"alert": "DeleteSeveralNodesDialog__alert__LThdd",
	"alertTitle": "DeleteSeveralNodesDialog__alertTitle__AmyCl",
	"content": "DeleteSeveralNodesDialog__content__Vxc_a",
	"removableElements": "DeleteSeveralNodesDialog__removableElements__UIi_j",
	"tab": "DeleteSeveralNodesDialog__tab__cPdWr"
};
export default ___CSS_LOADER_EXPORT___;
