import { linkBlock, linkEntity } from './link';
import { textAlignBlock, textAlignEntity } from './textAlign';
import { fontFamilyBlockStyles, fontsFamilyStyles } from './fontFamily';
import { fontSizeBlockStyles, fontSizeStyles } from './fontSize';
import { fontColorBlockStyles, fontColorStyles } from './color';
import { fontBgColorBlockStyles, fontBgColorStyles } from './bgColor';
import { imageBlock, imageEntity } from './image';
import { TBlockEntities, TBlockStyles, TMdEntity } from '../RichTextEditor.types';

// Dictionary<EntityMd<MdEntity>>
const entities = {
    ...linkEntity,
    ...textAlignEntity,
    ...imageEntity,
};

const blockEntities: TBlockEntities = {
    ...linkBlock,
    ...textAlignBlock,
    ...imageBlock,
};

let blockStyles: TBlockStyles = {
    strong_open: 'BOLD',
    em_open: 'ITALIC',
    code: 'CODE',
    sub: 'SUBSCRIPT',
    sup: 'SUPERSCRIPT',
    ins_open: 'UNDERLINE',
    del_open: 'STRIKETHROUGH',

    ...fontFamilyBlockStyles,
    ...fontSizeBlockStyles,
    ...fontColorBlockStyles,
    ...fontBgColorBlockStyles,
};

let styleItems = {
    SUPERSCRIPT: {
        open() {
            return '^';
        },

        close() {
            return '^';
        },
    },
    SUBSCRIPT: {
        open() {
            return '~';
        },

        close() {
            return '~';
        },
    },
    UNDERLINE: {
        open() {
            return '++';
        },

        close() {
            return '++';
        },
    },
    ...fontsFamilyStyles,
    ...fontSizeStyles,
    ...fontColorStyles,
    ...fontBgColorStyles,
};

const getStyleItems = () => {
    return styleItems;
};

const addStyleItem = (styleItem: TMdEntity) => {
    styleItems = {
        ...styleItems,
        ...styleItem,
    };
};

const getBlockStyleItems = () => {
    return blockStyles;
};

const addBlockStyleItems = (blockStyle: TMdEntity) => {
    blockStyles = <TBlockStyles>{
        ...blockStyles,
        ...blockStyle,
    };
};

export { entities, blockEntities, getStyleItems, addStyleItem, getBlockStyleItems, addBlockStyleItems };
