import { put, select, takeEvery } from 'redux-saga/effects';
import { TLoadTreePart } from '../actions/scriptContext.actions.types';
import {
    LOAD_TREE_PART_FOR_CONTEXT,
} from '../actionsTypes/scriptContext.actionTypes';
import { treePartFetchSuccess } from '../actions/tree.actions';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { Node } from "../serverapi/api";

export function* loadTreePartContext({ payload: { nodeId } }: TLoadTreePart) {
    const serverId: string = yield select(ServerSelectors.serverId);
    const nodes: Node[] = yield TreeDaoService.getNodesByParentNode(nodeId);
    yield put(treePartFetchSuccess(nodeId, nodes, serverId));
}


export function* scriptContextSaga() {
    yield takeEvery(LOAD_TREE_PART_FOR_CONTEXT, loadTreePartContext);
}
