import { IconGeneratorSettings, SymbolSettingsTypeEnum } from "../../../../../../../../../serverapi/api";
import { TSymbolsSettings } from "../SymbolGenerator.types";
import { SymbolGeneratorBllService } from "../SymbolGeneratorService/SymbolGeneratorBllService";

type TIndentsCalcParams = {
    iconHeight?: number;
    iconWidth?: number;
    height?: number;
    width?: number;
    iconPosition?: IconGeneratorSettings["iconPosition"];
}

enum IndentsType {
    BOTTOM, 
    TOP, 
    LEFT, 
    RIGHT, 
}

export const indentsCalc = (indentType: number, currentStyles: TSymbolsSettings, symbolType: SymbolSettingsTypeEnum, params: TIndentsCalcParams): number=> {
    const iconHeight =  params?.iconHeight || currentStyles?.[symbolType]?.iconSettings?.iconHeight;
    const iconWidth = params?.iconWidth || currentStyles?.[symbolType]?.iconSettings?.iconWidth;
    const symbolHeight = params?.height || currentStyles?.[symbolType]?.symbolSettings?.height || 1;
    const symbolWidth =  params?.width || currentStyles?.[symbolType]?.symbolSettings?.width || 1;
    const pos = params?.iconPosition || currentStyles?.[symbolType]?.iconSettings?.iconPosition;
    const bottomTopIndentIconPosition = pos === "middle center" || pos === "middle right" || pos === "left middle";
    const leftRightIndentIconPosition = pos === "middle center" || pos === "center" || pos === "center bottom";
    
    if (!iconHeight || !iconWidth) {
        return 0;
    }
    if (IndentsType.BOTTOM === indentType) {
        let bottom: number = 0;
        if (bottomTopIndentIconPosition) bottom = (symbolHeight / 2) - (iconHeight / 2);
        if (pos === "right bottom" || pos === "center bottom" || pos === "left bottom") bottom = 0;

        return bottom < 0 ? 0 : bottom / 2;
    }
    if (IndentsType.TOP === indentType) {
        let top: number = 0;
       
        if (bottomTopIndentIconPosition) top = symbolHeight / 2  - (iconHeight / 2);
        if (pos === "right top" || pos === "left top") top = 0;

        return top < 0 ? 0 : top / 2;
    }
    if (IndentsType.LEFT === indentType) {
        let left: number = 0;
        if (leftRightIndentIconPosition) left = symbolWidth / 2 - (iconWidth / 2);
        if (pos === "left top" || pos === "left middle" || pos === "left bottom")  left = 0;

        return left < 0 ? 0 : left / 2;
    }
    if (IndentsType.RIGHT === indentType) {
        let right: number = 0;
        if (leftRightIndentIconPosition) right = symbolWidth / 2 - (iconWidth / 2);
        if (pos === "right top" || pos === "right bottom" || pos === "middle right") right = 0;

        return right < 0 ? 0 : right / 2;
    }

    return 0;
};

export const checkIndentActive = (indentType: number, currentStyles: TSymbolsSettings, symbolType: SymbolSettingsTypeEnum): boolean => {
    const pos = currentStyles?.[symbolType]?.iconSettings?.iconPosition;
    let indentCondition: boolean = false;

    if (IndentsType.BOTTOM === indentType && pos !=='right top' && pos !=='center' && pos !=='left top') indentCondition = true;
    if (IndentsType.TOP === indentType && pos !=='center bottom' && pos !=='left bottom' && pos !=='right bottom') indentCondition = true;
    if (IndentsType.LEFT === indentType  && pos !=='right top' && pos !=='middle right' && pos !=='right bottom') indentCondition = true;
    if (IndentsType.RIGHT === indentType && pos !=='left middle' && pos !=='left bottom' && pos !=='left top') indentCondition = true;

    return currentStyles?.[symbolType]?.iconSettings?.iconEditActive && 
            currentStyles?.[symbolType]?.iconSettings?.svgIcon && indentCondition || false;
};

export const setIndentStyles = (indentType: number, val: number, currentStyles: TSymbolsSettings, symbolType: SymbolSettingsTypeEnum, setStyles: React.Dispatch<React.SetStateAction<TSymbolsSettings>>): void => {
    const iconPos = currentStyles?.[symbolType]?.iconSettings?.iconPosition;
    const iconWidth = currentStyles?.[symbolType]?.iconSettings?.iconWidth || 0;
    const iconHeight = currentStyles?.[symbolType]?.iconSettings?.iconHeight || 0;
    const symbolWidth = currentStyles?.[symbolType]?.symbolSettings?.width || 0 ;
    const symbolHeight= currentStyles?.[symbolType]?.symbolSettings?.height || 0;

    const indentCalcWidth: number = Math.round(Math.abs(iconWidth + val - symbolWidth) - Math.abs((symbolWidth - iconWidth) / 2 ));
    const indentCalcHeight: number = Math.round(Math.abs((iconHeight + val - symbolHeight)) - Math.abs((symbolHeight - iconHeight) / 2 ));

    if (IndentsType.BOTTOM === indentType) {
        setStyles(prev => SymbolGeneratorBllService.changeParametersHandler(prev, {
            indentFromBottom: val,
            indentFromTop: (
                iconPos === 'right bottom' ||
                iconPos === 'center bottom' || 
                iconPos === 'left bottom'
            ) ? 0 : indentCalcHeight
        }, 'iconSettings', symbolType)); 
    }
    if (IndentsType.TOP === indentType) {
        setStyles(prev => SymbolGeneratorBllService.changeParametersHandler(prev, {
            indentFromTop: val,
            indentFromBottom: (
                iconPos === 'right top' || 
                iconPos === 'center' ||
                iconPos === 'left top' 
            )  ? 0 : indentCalcHeight
        }, 'iconSettings', symbolType));
    }

    if (IndentsType.LEFT === indentType) {
        setStyles(prev => SymbolGeneratorBllService.changeParametersHandler(prev, {
            indentFromLeft: val,
            indentFromRight: (
                iconPos === 'left top' ||
                iconPos === 'left middle' || 
                iconPos === 'left bottom' 
            ) ? 0 : indentCalcWidth 
        }, 'iconSettings', symbolType));
    }
    if (IndentsType.RIGHT === indentType) {
        setStyles(prev => SymbolGeneratorBllService.changeParametersHandler(prev, {
            indentFromRight: val,
            indentFromLeft: (
                iconPos === 'right top' ||
                iconPos === 'right bottom' ||
                iconPos === 'middle right'
            ) ? 0 : indentCalcWidth
        }, 'iconSettings', symbolType));
    }
    
};