import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';

const getState = (state: TRootState) => state.canvasSettings;

export namespace CanvasSettings {
    export const isGridEnabled = createSelector(getState, (state) => state.isGridEnabled);
    export const gridType = createSelector(getState, (state) => state.gridType);
    export const dotSpace = createSelector(getState, (state) => state.dotSpace);
}
