import {
    StompClientDecorator
} from './StompClientDecorator';
import { Subscription } from './Subscription';

export class SubscriptionRegistry {
    private subscriptions: Subscription[] = [];
    private readonly stompClientDecorator: StompClientDecorator;

    public constructor(stompClientDecorator: StompClientDecorator) {
        this.stompClientDecorator = stompClientDecorator;
    }


    /**
     * Подписка на топик.
     * @param endpointId - идентификатор подписки.
     * Это может быть как id узла, так и любой другой идентификатор, о котором знает сервер.
     * Например 'script' - подписка на результат выполнения всех скриптов текущего пользователя
     * @param msgHandler
     * @return Stomp.Subscription - если успешно подписались на топик.
     *          undefined - если не удалось подписаться на топик.
     */
    subscribe = (endpointId: string, msgHandler: (message: any) => void): Subscription | undefined => {
        if (this.isClientConnected() && !this.subscriptions.find(s => s.endpointId == endpointId)) {
            const subscription: Subscription | undefined = this.stompClientDecorator.subscribe(endpointId, msgHandler)
            if (subscription) {
                this.subscriptions.push(subscription);
                return subscription;
            }
        }
        return undefined;
    }

    /**
     * Отписка от уведомлений.
     * @param endpointId - идентификатор подписки
     */
    unsubscribe = (endpointId: string) => {
        if (this.isClientConnected()) {
            this.subscriptions = this.subscriptions.filter(s => {
                const shouldUnsubscribe: boolean = s.endpointId == endpointId
                if (shouldUnsubscribe) {
                    s.unsubscribe();
                }
                return !shouldUnsubscribe;
            });
        }
    }

    unsubscribeAll = () => {
        if (this.isClientConnected()) {
            this.subscriptions = this.subscriptions.filter(s => s.unsubscribe());
        }
    }

    resubscribeAll = () => {
        this.subscriptions = this.subscriptions.map(s => {
            const subscription = this.stompClientDecorator.subscribe(s.endpointId, s.msgHandler)
            return {...s, ...subscription}
        });
    }

    getSubscriptions = (): Subscription[] => {
        return [...this.subscriptions]
    }

    private isClientConnected() {
        return this.stompClientDecorator && this.stompClientDecorator.isConnected();
    }
}

