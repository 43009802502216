export const convertToDecimal = (str: string): string => str.replace(/[^\d]/g, '');

// Возвращает строку из которой удалены все кроме чисел, если 0 стоит в начале и число >= 1 - убирает его
export const convertToNumber = (str: string): string => {
    const string = convertToDecimal(str);

    if (string.length > 1) {
        return `${BigInt(string)}`;
    }

    return string;
};
