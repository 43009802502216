import React, { Component } from 'react';
import { Table } from 'antd';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import formMessages from '../../../../models/formDefault.messages';
import dialogMessages from '../../UserManagement/messages/userManagment.messages';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import accessTypesMessages from '../../../../models/userAccesses.messages';
import accessTypes from '../../../../models/userAccessRightTypes';
import theme from './UserAccessPermissionsDialog.scss';
import { UserDTOAccessesEnum } from '../../../../serverapi/api';
import { DEFAULT_DIALOG_WIDTH } from '../../../../config/config';
import { toggleArrayItem } from '../../../../utils/toggleArrayItem';
import {
    TUserAccessPermissionsDialogActionProps,
    TUserAccessPermissionsDialogProps,
} from '../UserAccessPermissionsDialog.types';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TUserAccessPermissionsDialogFullProps = TUserAccessPermissionsDialogActionProps &
    TUserAccessPermissionsDialogProps &
    WrappedComponentProps;

type TUserAccessPermissionsTableRecord = {
    key: string;
    title: string;
};

class UserAccessPermissionsDialog extends Component<TUserAccessPermissionsDialogFullProps> {
    state = {
        selectedRowKeys: this.props.userData.accesses
            ? (this.props.userData.accesses.map((g, i) => g || i) as string[])
            : [],
    };

    handleSubmit = () => {
        if (this.state.selectedRowKeys) {
            this.props.onSubmit(this.state.selectedRowKeys as UserDTOAccessesEnum[]);
        }
    };

    onSelectChange = (selectedRowKeys: string[] | []) => {
        this.setState({ selectedRowKeys });
    };

    addRowDisableClassName = () => {
        return '';
    };

    getColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage(dialogMessages.titleCol),
                dataIndex: 'title',
            },
        ];
    };

    getData = () => {
        return Object.keys(accessTypes).map((k) => ({
            key: k,
            title: this.props.intl.formatMessage(accessTypesMessages[accessTypes[k]]),
        }));
    };

    onRow = (row: TUserAccessPermissionsTableRecord) => {
        const newState: string[] = toggleArrayItem<string>(row.key, this.state.selectedRowKeys);

        return {
            onClick: () => this.setState({ selectedRowKeys: newState }),
        };
    };

    render() {
        const { onClose, intl, open } = this.props;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: onClose,
                        value: intl.formatMessage(formMessages.cancelButton),
                    },
                    {
                        key: 'ok',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(formMessages.choiceButtonLabel),
                        visualStyle: 'primary',
                        dataTest: 'user-managment_add-functions-to-user-submite',
                    },
                ]}
            />
        );

        return (
            <Dialog
                onOk={this.handleSubmit}
                onCancel={onClose}
                title={intl.formatMessage(dialogMessages.accessesRights)}
                open={open}
                width={DEFAULT_DIALOG_WIDTH}
                footer={footer}
                className={theme.dialog}
            >
                <div className={theme.container}>
                    <Table
                        size="middle"
                        rowSelection={rowSelection}
                        className={theme.table}
                        rowClassName={this.addRowDisableClassName}
                        columns={this.getColumns()}
                        dataSource={this.getData()}
                        pagination={false}
                        onRow={this.onRow}
                        scroll={{
                            y: 'max-content',
                            x: 'max-content',
                        }}
                    />
                </div>
            </Dialog>
        );
    }
}

export default injectIntl(UserAccessPermissionsDialog);
