import { call, put, select, takeEvery } from 'redux-saga/effects';
import { treeItemAdd, treeItemUpdate } from '../actions/tree.actions';
import {
    saveObjectDefinitionFail,
    saveObjectDefinitionSuccess,
    objectDefinitionsAdd,
    saveObjectDefinition,
} from '../actions/entities/objectDefinition.actions';
import {
    TObjectDefinitionDeleteAction,
    TSaveObjectDefinition,
    TSaveObjectDefinitionSuccess,
    TUpdateObjectDefinitionName,
} from '../actions/entities/objectDefinition.actions.types';
import {
    CHECK_OBJECT_DEFINITION,
    OBJECT_DEFINITION_DELETE,
    SAVE_OBJECT_DEFINITION,
    SAVE_OBJECT_DEFINITION_SUCCESS,
    UPDATE_OBJECT_DEFINITION_NAME,
} from '../actionsTypes/entities/objectDefinition.actionTypes';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { Node, ObjectDefinitionNode } from '../serverapi/api';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { updateDefinitionOverlay } from '../actions/overlay.actions';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { ObjectDefinitionsDAOService } from '../services/dao/ObjectDefinitionsDAOService';
import { updateGraph } from './tree.saga';
import { TreeSelectors } from '../selectors/tree.selectors';
import { LocalesService } from '@/services/LocalesService';
import { objectDefinitionService } from '../services/ObjectDefinitionService';

function* checkObjectExists(action: TSaveObjectDefinition) {
    const { objectDefinition } = action.payload;

    if (objectDefinition) {
        const nodes: ObjectDefinitionNode[] = yield ObjectDefinitionsDAOService.getObjectDefinitions(
            objectDefinition.nodeId.serverId,
            {
                repositoryId: objectDefinition.nodeId.repositoryId,
                objectDefinitionName: objectDefinition.name,
                objectTypeId: objectDefinition.objectTypeId,
            },
        );

        if (nodes.length) {
            yield put(openDialog(DialogType.CHECK_OBJECT_DIALOG, { instances: nodes, objectDefinition }));
        } else {
            yield put(saveObjectDefinition(objectDefinition.nodeId.serverId, objectDefinition));
        }

        yield put(closeDialog(DialogType.OBJECT_DEFINITION_CREATE));
    }
}

function* handleObjectDefinitionSave(action: TSaveObjectDefinition) {
    const { serverId } = action.payload;
    const objectDefinition = <ObjectDefinitionImpl>action.payload.objectDefinition;

    const updatedObjectDefinition: ObjectDefinitionImpl = yield call(
        objectDefinitionService().saveObjectDefinition,
        objectDefinition,
    );

    yield put(saveObjectDefinitionSuccess(serverId, updatedObjectDefinition));
}

// возможно имеет смысл заменить на handleTreeItemTitleChangeRequest,
// но необходимо учитывать что переименование меняет имя экземпляров на холсте
function* handleUpdateObjectDefinitionName(action: TUpdateObjectDefinitionName) {
    const { objectDefinition, name } = action.payload;
    const serverId: string = yield select(ServerSelectors.serverId);
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    try {
        yield server.api.tree.rename({
            repositoryId: objectDefinition.nodeId.repositoryId,
            nodeId: objectDefinition.nodeId.id,
            body: name,
        });
        const multilingualName = LocalesService.changeLocaleValue(
            objectDefinition.multilingualName,
            LocalesService.getLocale(),
            name,
        );
        yield put(
            saveObjectDefinitionSuccess(serverId, {
                ...objectDefinition,
                name,
                multilingualName,
            } as ObjectDefinitionImpl),
        );
    } catch (e) {
        yield put(saveObjectDefinitionFail(serverId, objectDefinition));
    }
}

function* handleObjectDefinitionSaveSuccess(action: TSaveObjectDefinitionSuccess) {
    const { objectDefinition } = action.payload;
    const existing = yield select(ObjectDefinitionSelectors.byId(objectDefinition.nodeId));

    // метод handleObjectDefinitionSaveSuccess вызывается при обновлении узла в дереве и создании
    // при обновлении у нас нет информации о детях поэтому нужно использовать метод treeItemUpdate
    // при создании treeItemUpdate не подходит т.к. в нем недостаточно данных
    // чтобы убрать костыль надо рефакторить редюсеры и набор событий
    if (yield select(TreeSelectors.itemById(objectDefinition.nodeId))) {
        yield put(treeItemUpdate({ nodeId: objectDefinition.nodeId, data: objectDefinition }));
    } else {
        // считаем что ObjectDefinition совместим с TreeNode (за исключением hasChildren, но в этой операции это не важно)
        yield put(treeItemAdd(objectDefinition as any));
    }

    yield put(objectDefinitionsAdd([objectDefinition]));
    yield call(updateGraph, objectDefinition, existing);
    yield put(updateDefinitionOverlay(objectDefinition.nodeId));
}

function* handleObjectDefinitionDelete(action: TObjectDefinitionDeleteAction) {
    const { nodeId, parentNodeId } = action.payload;

    yield call(updateGraph, { nodeId, parentNodeId } as Node, undefined);
}

export function* objectDefinitionSagaInit() {
    yield takeEvery(CHECK_OBJECT_DEFINITION, checkObjectExists);
    yield takeEvery(SAVE_OBJECT_DEFINITION, handleObjectDefinitionSave);
    yield takeEvery(SAVE_OBJECT_DEFINITION_SUCCESS, handleObjectDefinitionSaveSuccess);
    yield takeEvery(UPDATE_OBJECT_DEFINITION_NAME, handleUpdateObjectDefinitionName);
    yield takeEvery(OBJECT_DEFINITION_DELETE, handleObjectDefinitionDelete);
}
