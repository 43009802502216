export const SIMULATIONS_REQUEST_SUCCESS = 'SIMULATIONS_REQUEST_SUCCESS';

export const SIMULATION_UPDATE = 'SIMULATION_UPDATE';

export const SIMULATION_DELETE = 'SIMULATION_DELETE';

export const SIMULATION_SETTINGS_SAVE = 'SIMULATION_SETTINGS_SAVE';

export const SIMULATION_SETTINGS_REQUEST_SUCCESS = 'SIMULATION_SETTINGS_REQUEST_SUCCESS';

export const SIMULATION_MODELING_OPEN = 'SIMULATION_MODELING_OPEN';

export const SIMULATION_MODELING_RUN = 'SIMULATION_MODELING_RUN';

export const SIMULATION_MODELING_ADD = 'SIMULATION_MODELING_ADD';

export const SIMULATION_MODELING_SUBMIT_NODE = 'SIMULATION_MODELING_SUBMIT_NODE';

export const SIMULATION_MODELING_GET_REPORT = 'SIMULATION_MODELING_GET_REPORT';

export const SIMULATION_MODELING_ADD_REPORT = 'SIMULATION_MODELING_ADD_REPORT';
