import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'AttributeSelectDialog.cancel',
        defaultMessage: 'Отмена',
    },
    submit: {
        id: 'AttributeSelectDialog.submit',
        defaultMessage: 'Выбрать',
    },
    title: {
        id: 'AttributeSelectDialog.title',
        defaultMessage: 'Атрибуты',
    },
    attributeType: {
        id: 'AttributeSelectDialog.attributeType',
        defaultMessage: 'Тип атрибута',
    },
    attributeTypeId: {
        id: 'AttributeSelectDialog.attributeTypeId',
        defaultMessage: 'ID типа атрибута',
    },
    systemAtrtribures: {
        id: 'AttributeSelectDialog.systemAtrtribures',
        defaultMessage: 'Системные атрибуты',
    },
    userAtrtribures: {
        id: 'AttributeSelectDialog.userAtrtribures',
        defaultMessage: 'Атрибуты методологии',
    },
});
