// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TreeItemSelectDialog-component__modal__IJoU5 .ant-modal-body{height:500px}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/TreeItemSelectDialog/TreeItemSelectDialog.component.scss"],"names":[],"mappings":"AAEQ,8DACI,YAAA","sourcesContent":[".modal {\r\n    :global {\r\n        .ant-modal-body {\r\n            height: 500px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "TreeItemSelectDialog-component__modal__IJoU5"
};
export default ___CSS_LOADER_EXPORT___;
