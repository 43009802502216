import * as React from 'react';
import { TLinkRendererProps } from '../../RichTextEditor.types';

class LinkRenderer extends React.Component<TLinkRendererProps> {
    state = {
        url: '',
        title: '',
    };

    constructor(props: TLinkRendererProps) {
        super(props);

        const { url, title } = this.props.contentState.getEntity(this.props.entityKey).getData();

        this.state = { url, title };
        this.onClick = this.onClick.bind(this);
    }

    onClick(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault();
        e.stopPropagation();

        const { onClickOpen } = this.props;
        const { url } = this.state;

        onClickOpen(url);
    }

    render() {
        const { title, url } = this.state;
        const { children } = this.props;

        const attrs = {
            title: title || '',
            href: url,
            onClick: this.onClick,
        };

        return <a {...attrs}>{children}</a>;
    }
}

export { LinkRenderer };
