import { defineMessages } from 'react-intl';

export default defineMessages({
    DB: {
        id: 'SearchDeletedElementsTab.DB',
        defaultMessage: 'Базы данных',
    },
    FOLDER: {
        id: 'SearchDeletedElementsTab.FOLDER',
        defaultMessage: 'Папки',
    },
    MODEL: {
        id: 'SearchDeletedElementsTab.MODEL',
        defaultMessage: 'Модели',
    },
    search: {
        id: 'SearchDeletedElementsTab.search',
        defaultMessage: 'Поиск',
    },
    erase: {
        id: 'SearchDeletedElementsTab.erase',
        defaultMessage: 'Удалить навсегда',
    },
    restore: {
        id: 'SearchDeletedElementsTab.restore',
        defaultMessage: 'Восстановить',
    },
    eraseNode: {
        id: 'SearchDeletedElementsTab.eraseNode',
        defaultMessage: 'Полное удаление узла дерева',
    },
    eraseNodeQuestion: {
        id: 'SearchDeletedElementsTab.eraseNodeQuestion',
        defaultMessage: 'Вы уверены, что хотите удалить узел дерева без возможности восстановления?',
    },
    delete: {
        id: 'SearchDeletedElementsTab.delete',
        defaultMessage: 'Удалить',
    },
    eraseSelectedNodes: {
        id: 'SearchDeletedElementsTab.eraseSelectedNodes',
        defaultMessage: 'Полное удаление выбранных узлов дерева',
    },
    eraseSelectedNodesQuestion: {
        id: 'SearchDeletedElementsTab.eraseSelectedNodesQuestion',
        defaultMessage: 'Вы уверены, что хотите удалить выбранные узлы дерева без возможности восстановления?',
    },
    searchAllObjectTypes: {
        id: 'SearchDeletedElementsTab.searchAllObjectTypes',
        defaultMessage: 'Искать все типы объектов',
    },
});
