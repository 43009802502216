import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import UserProfilesComponent from '../components/UserProfiles.component';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { ServerProfileSelectors } from '../../../../selectors/serverProfile.selectors';
import { deleteServerProfileData, getServerProfilesData } from '../../../../actions/serverProfile.actions';
import { openPrincipalServerProfileOwnersEditingTab } from '../../../../actions/principalServerProfile.actions';
import { PrincipalServerProfileSelectors } from '../../../../selectors/principalServerProfile.selectors';
import { TPayloadDeleteServerProfileData } from '../../../../actions/serverProfile.actions.types';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { TUserProfilesActionProps, TUserProfilesProps } from '../UserProfiles.types';

const mapStateToProps = (state: TRootState): Partial<TUserProfilesProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const serverProfiles = ServerProfileSelectors.getAllServerProfilesByServerId(serverId)(state);
    const owners = PrincipalServerProfileSelectors.getOwners(serverId)(state);

    return {
        serverId,
        owners,
        serverProfiles,
        currentLocale: getCurrentLocale(state),
    };
};

const mapDispatchToProps: (dispatch) => TUserProfilesActionProps = (dispatch) => ({
    onEditClicked: (profileId: string, serverId: string) => {
        dispatch(openPrincipalServerProfileOwnersEditingTab(profileId, serverId));
    },
    addNewProfile: () => dispatch(openDialog(DialogType.ADD_USER_PROFILE, {})),
    getServerProfilesData: (serverId: string) => dispatch(getServerProfilesData(serverId)),
    handleDeleteServerProfile: (payload: TPayloadDeleteServerProfileData) => dispatch(deleteServerProfileData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilesComponent);
