import { call, put, select, takeEvery } from 'redux-saga/effects';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction } from '../modules/Tree/models/tree';
import { IWorkspaceTabChangeEntityTypesParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { workspaceAddTab, workspaceRemoveTab } from '../actions/tabs.actions';
import {
    GET_NOTATION_REPLACEMENT_DATA_BY_NODE_ID_REQUEST,
    REPLACE_NOTATIONS,
} from '../actionsTypes/changeEntityType.actionTypes';
import { ChangeEntityTypeDaoService } from '../services/dao/ChangeEntityTypeDaoService';
import {
    TGetNotationReplacementDataByNodeIdRequestAction,
    TReplaceNotationsAction,
} from '../actions/changeEntityType.types';
import { getNotationReplacementDataByNodeIdSuccess } from '../actions/changeEntityType.actions';
import { GeneralModel, NodeId, NodeNotationReplacementDTO } from '../serverapi/api';
import messages from '../modules/ChangeEntityType/ChangeEntityTypeTab.messages';
import { LocalesService } from '../services/LocalesService';
import { compareNodeIds, generateCustomNodeId } from '../utils/nodeId.utils';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { MEDIUM_NOTIFICATION_DURATION } from '@/models/notificationType';
import { notification } from 'antd';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import AppNotificationsMessages from '@/modules/App/messages/AppNotifications.messages';

function* handleOpenEntityTypeSettingsTab({ payload: { nodeId, action } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.CHANGE_ENTITY_TYPE) {
        const intl = LocalesService.useIntl();
        const tab: TWorkspaceTab = {
            title: intl.formatMessage(messages.header),
            nodeId: generateCustomNodeId(nodeId, 'ChangeEntityType'),
            type: WorkSpaceTabTypes.CHANGE_ENTITY_TYPE,
            mode: EditorMode.Read,
            params: {
                nodeId,
            } as IWorkspaceTabChangeEntityTypesParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };

        yield put(workspaceAddTab(tab));
    }
}

function* handleGetNotationReplacementDataByNodeIdRequest({
    payload,
}: TGetNotationReplacementDataByNodeIdRequestAction) {
    const { nodeId, searchDepth, nodeTypes } = payload;

    const notationReplacementData: NodeNotationReplacementDTO[] =
        yield ChangeEntityTypeDaoService.getNotationReplacementData(nodeId, nodeTypes, searchDepth);

    yield put(getNotationReplacementDataByNodeIdSuccess({ nodeId, notationReplacementData }));
}

function* handleReplaceNotations({ payload }: TReplaceNotationsAction) {
    const { nodeId, tab, searchDepth, nodeTypes, body } = payload;

    const tabs: TWorkspaceTab[] = yield select(TabsSelectors.getTabList);
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    let hasReplacingOpenModels: boolean = false;

    // находим NodeIds открытых моделей того же типа что и у моделей которые хотим заменить
    const openModelsNodeIds = tabs.reduce((result, currentTab) => {
        if (
            currentTab.type === WorkSpaceTabTypes.EDITOR &&
            body?.some((nodeNotation) => nodeNotation.typeId === (currentTab.content as GeneralModel)?.modelTypeId)
        ) {
            return [...result, currentTab.nodeId];
        }
        return result;
    }, []);

    // проверяем есть ли среди открытых моделей та тип которой пытаемся заменить и находится в зоне поиска
    if (openModelsNodeIds.some((modelNodeId) => compareNodeIds(modelNodeId, nodeId))) {
        hasReplacingOpenModels = true;
    } else {
        // получаем родительские NodeIds для всех openModelsNodeIds
        const parentNodeIdsMap: { [key: string]: NodeId[] } = yield call(() =>
            TreeDaoService.findAllParentElementId(openModelsNodeIds),
        );
        // проверяем что есть хотя бы одна открытая модель, которая находится внутри nodeId (зона поиска)
        // если такая модель есть то показываем нотификалку и не вызываем replaceNotations
        for (let key of Object.keys(parentNodeIdsMap)) {
            const parentNodeIds = parentNodeIdsMap[key];
            if (parentNodeIds.find((parentNodeId) => compareNodeIds(parentNodeId, nodeId))) {
                hasReplacingOpenModels = true;
                break;
            }
        }
    }

    if (hasReplacingOpenModels) {
        notification.warning({
            message: intl.formatMessage(AppNotificationsMessages.changeModelTypeOfOpenModelWarn),
            description: intl.formatMessage(AppNotificationsMessages.changeModelTypeOfOpenModelWarnDescription),
            duration: MEDIUM_NOTIFICATION_DURATION,
        });
    } else {
        yield ChangeEntityTypeDaoService.replaceNotations(nodeId, nodeTypes, body, searchDepth);
        yield put(workspaceRemoveTab(tab));
    }
}

export function* changeEntityTypesSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenEntityTypeSettingsTab);
    yield takeEvery(GET_NOTATION_REPLACEMENT_DATA_BY_NODE_ID_REQUEST, handleGetNotationReplacementDataByNodeIdRequest);
    yield takeEvery(REPLACE_NOTATIONS, handleReplaceNotations);
}
