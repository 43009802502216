import { TReducer } from '../../utils/types';
import { FETCH_LICENSES_OWNERS_INFO_SUCCESS, FETCH_LICENSES_SUCCESS } from '../../actionsTypes/licenses.actionTypes';
import { TLicensesState } from './licenses.reducer.types';

const initial: TLicensesState = {
    byServerId: {},
};

export const licensesReducer: TReducer<TLicensesState> = (state = initial, action) => {
    switch (action.type) {
        case FETCH_LICENSES_SUCCESS: {
            const {
                data: { licenses, resulting, usingLicense, usingCompetitiveLicense },
                serverId,
            } = action.payload;

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...(state.byServerId[serverId] || {}),
                        licenses: licenses.length ? [...licenses] : [],
                        resulting: {
                            ...resulting,
                        },
                        usingLicense: {
                            ...usingLicense,
                        },
                        usingCompetitiveLicense: {
                            ...usingCompetitiveLicense,
                        },
                    },
                },
            };
        }
        case FETCH_LICENSES_OWNERS_INFO_SUCCESS: {
            const { serverId, licenseType, users, groups } = action.payload;

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...(state.byServerId[serverId] || {}),
                        owners: {
                            ...(state.byServerId[serverId] ? state.byServerId[serverId].owners : new Map()),
                            [licenseType]: {
                                users: [...users],
                                groups: [...groups],
                            },
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};
