import React, { useState, useCallback } from 'react';
import messages from './AttributeImageSizeSelector.messages';
import theme from './AttributeImageSizeSelector.scss';
import { useIntl } from 'react-intl';
import { InputNumber } from 'antd';
import BPMMxCellOverlay from '../../../../../../mxgraph/overlays/BPMMxCellOverlay';

type TAttributeImageSizeSelectorProps = {
    initialValue: number | undefined;
    disabled?: boolean;
    onChange: (size: number) => void;
};

export const AttributeImageSizeSelector = ({ initialValue, disabled, onChange }: TAttributeImageSizeSelectorProps) => {
    const intl = useIntl();
    const [imageSize, setImageSize] = useState(initialValue);

    const onChangeHandler = useCallback(
        (size) => {
            setImageSize(size);
            if (onChange) onChange(size);
        },
        [onChange, setImageSize],
    );

    return (
        <div className={theme.container}>
            <div className={theme.title}>{intl.formatMessage(messages.title)}</div>
            <div className={theme.inputContainer}>
                <InputNumber
                    value={imageSize}
                    disabled={disabled}
                    min={BPMMxCellOverlay.MIN_IMAGE_SIZE}
                    max={BPMMxCellOverlay.MAX_IMAGE_SIZE}
                    defaultValue={BPMMxCellOverlay.DEFAULT_IMAGE_SIZE}
                    onChange={onChangeHandler}
                />
            </div>
        </div>
    );
};
