// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GraphicalComparison__compareImagesContainer__MiJxU{width:100%;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex}.GraphicalComparison__leftCompareImage__QV1zu{width:100%;height:100%;-webkit-transform-origin:left top;transform-origin:left top;-o-object-fit:contain;object-fit:contain;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;will-change:transform}.GraphicalComparison__imageContainer__YZ_OY{overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;height:100%;width:calc(50% - 2px)}.GraphicalComparison__dotBorder__CpFjj{border-right:dotted 10px #ccc}", "",{"version":3,"sources":["webpack://./src/modules/CompareModels/GraphicalComparison.scss"],"names":[],"mappings":"AAAA,oDACI,UAAA,CACA,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGJ,8CACI,UAAA,CACA,WAAA,CACA,iCAAA,CAAA,yBAAA,CACA,qBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,oBAAA,CAAA,gBAAA,CACA,qBAAA,CAGJ,4CACI,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,WAAA,CACA,qBAAA,CAGJ,uCACI,6BAAA","sourcesContent":[".compareImagesContainer {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n}\r\n\r\n.leftCompareImage {\r\n    width: 100%;\r\n    height: 100%;\r\n    transform-origin: left top;\r\n    object-fit: contain;\r\n    user-select: none;\r\n    will-change: transform;\r\n}\r\n\r\n.imageContainer {\r\n    overflow: hidden;\r\n    display: flex;\r\n    height: 100%;\r\n    width: calc(50% - 2px);\r\n}\r\n\r\n.dotBorder {\r\n    border-right: dotted 10px #ccc;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"compareImagesContainer": "GraphicalComparison__compareImagesContainer__MiJxU",
	"leftCompareImage": "GraphicalComparison__leftCompareImage__QV1zu",
	"imageContainer": "GraphicalComparison__imageContainer__YZ_OY",
	"dotBorder": "GraphicalComparison__dotBorder__CpFjj"
};
export default ___CSS_LOADER_EXPORT___;
