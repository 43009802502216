import React from 'react';
import { Alert, Form, Input } from 'antd';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../UIKit/components/Dialog/Dialog.types';
import messages from './ServerForm.messages';
import theme from './theme/ServerEditDialog.scss';
// import userEditTheme from './theme/UserEdit.component.scss';
import serverUrlSymbol from '../../../resources/icons/ic-input-server.svg';
import serverNameSymbol from '../../../resources/icons/ic-input-server-name.svg';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { TServerConnectInfo, TServerEditDialogProps } from '../ServerForm.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { TButton } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.types';

class ServerEditDialog extends React.Component<TServerEditDialogProps, TServerConnectInfo> {
    constructor(props: TServerEditDialogProps) {
        super(props);
        const {
            value: { id, url, name, error },
        } = props;
        this.state = { id, url, name, error };
    }

    render() {
        const { intl, open, isEditMode, isConnected, allServers } = this.props;
        const { id, url, name, error } = this.state;
        let title;
        if (isConnected) {
            title = intl.formatMessage(messages.readConnectionTitle);
        } else if (isEditMode) {
            title = intl.formatMessage(messages.changeConnectionTitle);
        } else {
            title = intl.formatMessage(messages.newConnectionTitle);
        }

        const dialogProps: Partial<TDialogProps> = {
            open,
            title,
            closable: true,
            maskClosable: true,
            onCancel: this.props.onCancel,
        };

        const onDelete = () => {
            id && name && this.props.onDelete(id, name, isConnected);
        };
        const onEdit = () => {
            name &&
                url &&
                this.props.onSubmit(
                    { id, serverName: name, url, remember: false, password: '', username: '' },
                    isConnected,
                );
        };
        const replaceSym = (url: string) => url && url.replace(/\//g, '').replace(/ /g, '');
        const setStateUrl = (url: string) => {
            const serverIsExist =
                allServers && allServers.filter((s) => replaceSym(s.url) === replaceSym(url) && s.id !== id).length;
            if (serverIsExist) {
                this.setState({ url, error: messages.duplicateUrlError });
            } else {
                this.setState({ url, error: undefined });
            }
        };

        const footer = (
            <DialogFooterButtons
                buttons={[
                    !isConnected &&
                        isEditMode && {
                            key: 'delete',
                            onClick: onDelete,
                            value: intl.formatMessage(messages.remove),
                            dataTest: 'edit-server_delete',
                            danger: true,
                        },
                    {
                        key: 'cancel',
                        onClick: this.props.onCancel,
                        value: intl.formatMessage(messages.cancel),
                        dataTest: 'edit-server_cancel',
                    },
                    !isConnected &&
                        ({
                            key: 'ok',
                            onClick: onEdit,
                            value: intl.formatMessage(messages.create),
                            visualStyle: 'primary',
                            dataTest: 'edit-server_submite',
                            disabled: !(url && name) || !!error,
                        } as TButton),
                ].filter((button): button is TButton => !!button)}
            />
        );

        return (
            <Dialog {...dialogProps} className={theme.dialog} footer={footer}>
                {error && <Alert className={theme.alert} type="error" message={intl.formatMessage(error)} showIcon />}

                <Form layout="vertical">
                    <FormGroup className={theme.group}>
                        <Form.Item
                            className={theme.item}
                            // hasFeedback
                            label={intl.formatMessage(messages.serverName)}
                            rules={[{ required: !isConnected, message: intl.formatMessage(messages.requiredField) }]}
                            name="serverName"
                            initialValue={name}
                        >
                            <Input
                                data-test="edit-server_name-input"
                                disabled={isConnected}
                                className={theme.input}
                                placeholder={intl.formatMessage(messages.serverNamePlaceholder)}
                                prefix={<Icon spriteSymbol={serverNameSymbol} />}
                                onChange={(event) => this.setState({ name: event.target.value })}
                                value={name}
                            />
                        </Form.Item>
                        <Form.Item
                            className={theme.item}
                            // hasFeedback
                            label={intl.formatMessage(messages.serverUrl)}
                            rules={[{ required: !isConnected, message: intl.formatMessage(messages.requiredField) }]}
                            name="serverUrl"
                            initialValue={url}
                        >
                            <Input
                                data-test="edit-server_url-input"
                                disabled={isConnected}
                                className={theme.input}
                                placeholder={intl.formatMessage(messages.serverUrlPlaceholder)}
                                prefix={<Icon spriteSymbol={serverUrlSymbol} />}
                                onChange={(event) => setStateUrl(event.target.value)}
                                value={url}
                            />
                        </Form.Item>
                    </FormGroup>
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ServerEditDialog);

export { IntlComponent as ServerEditDialog };
