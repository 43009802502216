import {
    SUBMIT_EDGE_TYPE_GROUP,
    ADD_EDGE_TYPE_GROUPS,
    CREATE_EDGE_TYPE_GROUP,
    DELETE_EDGE_TYPE_GROUP_REQUEST,
    DELETE_EDGE_TYPE_GROUP_SUCCESS,
    EDIT_EDGE_TYPE_GROUP,
} from '../actionsTypes/edgeTypeGroup.actionTypes';
import {
    TAddEdgeTypeGroupAction,
    TCreateEdgeTypeGroupAction,
    TCreateEdgeTypeGroupPayload,
    TDeleteEdgeTypeGroupRequestAction,
    TDeleteEdgeTypeGroupRequestPayload,
    TDeleteEdgeTypeGroupSuccessAction,
    TEditEdgeTypeGroupAction,
    TEditEdgeTypeGroupPayload,
    TSubmitEdgeTypeGroupAction,
    TSubmitEdgeTypeGroupPayload,
} from './edgeTypeGroup.actions.types';

export const deleteEdgeTypeGroupRequest = (
    payload: TDeleteEdgeTypeGroupRequestPayload,
): TDeleteEdgeTypeGroupRequestAction => ({
    type: DELETE_EDGE_TYPE_GROUP_REQUEST,
    payload,
});

export const deleteEdgeTypeGroupSuccess = (
    payload: TDeleteEdgeTypeGroupRequestPayload,
): TDeleteEdgeTypeGroupSuccessAction => ({
    type: DELETE_EDGE_TYPE_GROUP_SUCCESS,
    payload,
});

export const createEdgeTypeGroup = (payload: TCreateEdgeTypeGroupPayload): TCreateEdgeTypeGroupAction => ({
    type: CREATE_EDGE_TYPE_GROUP,
    payload,
});

export const submitEdgeTypeGroup = (payload: TSubmitEdgeTypeGroupPayload): TSubmitEdgeTypeGroupAction => ({
    type: SUBMIT_EDGE_TYPE_GROUP,
    payload,
});

export const addEdgeTypeGroups = (payload: TSubmitEdgeTypeGroupPayload): TAddEdgeTypeGroupAction => ({
    type: ADD_EDGE_TYPE_GROUPS,
    payload,
});

export const editEdgeTypeGroup = (payload: TEditEdgeTypeGroupPayload): TEditEdgeTypeGroupAction => ({
    type: EDIT_EDGE_TYPE_GROUP,
    payload,
});
