import React, { useEffect } from 'react';
import { FavoriteNodeDTO, NodeId, RootNodeId } from '@/serverapi/api';
import { Input } from 'antd';
import theme from './SelectTreeItemAddFavoriteDialog.scss';
import { useIntl } from 'react-intl';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { useDispatch, useSelector } from 'react-redux';
import { TreeSelectors, filterTreeExcludeTypes } from '@/selectors/tree.selectors';
import messages from './SelectTreeItemAddFavoriteDialog.messages';
import { TreeNode } from '@/models/tree.types';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '@/config/config';
import { Tree } from '@/modules/Tree/components/Tree/Tree.component';
import { Icon } from '@/modules/UIKit';
import icSearch from '../../../../resources/icons/icSearch.svg';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { setFoundNodeIds, setSearchString } from '@/actions/navigatorTreeSearch.actions';
import { NavigatorTreeSearchSelector } from '@/selectors/navigatorTreeSearch.selectors';
import { favoritesAdd } from '@/actions/favorites.actions';
import { FavoritesSelectors } from '@/selectors/favorites.selectors';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { treeItemsClearSelection } from '@/actions/tree.actions';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export const SelectTreeItemAddFavoriteDialog = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const searchStr: string = useSelector(NavigatorTreeSearchSelector.getSearchString);
    const treeStructure: TreeNode[] = useSelector(TreeSelectors.treeStructure);
    const favoriteNodes: FavoriteNodeDTO[] = useSelector(FavoritesSelectors.nodes);
    const selectedNode: TreeNode | undefined = useSelector(
        SelectedNodesSelector.getNode(DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG),
    );
    const selectedNodeId = selectedNode?.nodeId || { id: '', repositoryId: '', serverId: '' };
    const favoriteNodesIds: NodeId[] = favoriteNodes.map((node) => node.nodeId);
    const allRepos = treeStructure[0]?.children || [];
    const filteredRepos = filterTreeExcludeTypes(allRepos, [TreeItemType.AdminTool, TreeItemType.EdgeDefinition]);

    const isSelectedNodeFavorite = favoriteNodesIds.some((nodeId) => compareNodeIds(nodeId, selectedNodeId));

    const isConfirmDisabled =
        !selectedNodeId.id ||
        selectedNodeId.id === RootNodeId.FILE_FOLDER_ROOT_ID ||
        selectedNodeId.id === RootNodeId.ROOT_SCRIPT_FOLDER_ID;

    useEffect(() => {
        return () => {
            dispatch(setSearchString(''));
            dispatch(setFoundNodeIds([]));
        };
    }, []);

    const handleCloseDialog = () => {
        dispatch(closeDialog(DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG));
        dispatch(treeItemsClearSelection());
    };

    const handleSubmit = () => {
        dispatch(favoritesAdd(selectedNodeId));
        handleCloseDialog();
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchString(e.target.value));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCloseDialog,
                    value: intl.formatMessage(messages.cancel),
                },
                isSelectedNodeFavorite
                    ? {
                          key: 'ok',
                          onClick: handleSubmit,
                          value: intl.formatMessage(messages.confirm),
                          visualStyle: 'primary',
                          disabled: true,
                          tooltip: intl.formatMessage(messages.elementInFavorites),
                      }
                    : {
                          key: 'ok',
                          onClick: handleSubmit,
                          value: intl.formatMessage(messages.confirm),
                          visualStyle: 'primary',
                          disabled: isConfirmDisabled,
                      },
            ]}
        />
    );

    const header = (
        <div>
            <span className={theme.title}>{intl.formatMessage(messages.dialogTitle)}</span>
            <Input
                className={theme.search}
                prefix={<Icon spriteSymbol={icSearch} />}
                onChange={handleSearchChange}
                value={searchStr}
            />
            <div className={theme.label}>{intl.formatMessage(messages.name)}</div>
        </div>
    );

    return (
        <Dialog
            footer={footer}
            className={theme.modal}
            onOk={handleSubmit}
            onCancel={handleCloseDialog}
            title={header}
            open
            width={DEFAULT_DIALOG_WIDTH}
        >
            <Tree treeName={DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG} data={filteredRepos} disableContextMenu />
        </Dialog>
    );
};
