import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../UIKit/components/Dialog/Dialog.types';
import messages from '../messages/PsdTableDeleteConfirmationDialog.messages';
import theme from './PsdTableDeleteConfirmationDialog.scss';
import { TPsdTableDeleteConfirmationDialogProps } from '../PsdTableDeleteConfirmationDialog.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TPsdTableDeleteConfirmationDialogState = {
    isPreviousAnswerYes: boolean;
};

class RawPsdTableDeleteConfirmationDialog extends React.Component<
    TPsdTableDeleteConfirmationDialogProps,
    TPsdTableDeleteConfirmationDialogState
> {
    state = { isPreviousAnswerYes: false };

    render() {
        const { open, isRow, onClose, intl } = this.props;
        const { isPreviousAnswerYes } = this.state;
        const title = messages.title;
        let text;
        if (isPreviousAnswerYes) {
            text = isRow ? messages.removeChildrenRowText : messages.removeChildrenColumnText;
        } else {
            text = isRow ? messages.removeRowText : messages.removeColumnText;
        }

        const dialogProps: Partial<TDialogProps> = {
            open,
            onCancel: onClose,
            title: <FormattedMessage {...title} />,
            onOk: this.handleSubmit,
        };

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: this.handleCancel,
                        value: intl.formatMessage(messages.cancelText),
                    },
                    {
                        key: 'ok',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.submitText),
                        visualStyle: 'primary',
                        danger: true,
                    },
                ]}
            />
        );

        return (
            <Dialog {...dialogProps} footer={footer}>
                <div className={theme.content}>
                    <FormattedMessage {...text} />
                </div>
            </Dialog>
        );
    }

    handleSubmit = () => {
        const { onSubmitRow, onSubmitColumn, cellId, isChildren, onCancel, isRow } = this.props;
        const { isPreviousAnswerYes } = this.state;
        const onSubmit = isRow ? onSubmitRow : onSubmitColumn;
        if (!isChildren) {
            if (onSubmit) {
                onSubmit(cellId);
            }
        } else if (isChildren && isPreviousAnswerYes) {
            if (onSubmit) {
                onSubmit(cellId);
            }
        } else if (isChildren && !isPreviousAnswerYes) {
            this.setState({ isPreviousAnswerYes: true });
        } else if (onCancel) {
            onCancel();
        }
    };

    handleCancel = () => {
        const { onCancel } = this.props;
        if (onCancel) {
            onCancel();
        }
    };
}

const IntlComponent = injectIntl(RawPsdTableDeleteConfirmationDialog);

export { IntlComponent as PsdTableDeleteConfirmationDialog };
