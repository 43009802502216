// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScriptEditor-component__container__QLrru{height:100%;position:relative;left:0;top:0;z-index:10;overflow:auto}.ScriptEditor-component__editor___uNMS{height:100%;overflow:auto;position:relative;outline:none}.ScriptEditor-component__editor___uNMS .CodeMirror{height:100%}.ScriptEditor-component__editorContainer__Rakga{height:100%;-webkit-transform-origin:0 0;transform-origin:0 0}.ScriptEditor-component__resizer___lEgU{border-left:3px solid rgba(255,255,255,0);border-right:3px solid rgba(255,255,255,0)}.ScriptEditor-component__hideResizer__vQiwQ{display:none}.ScriptEditor-component__commentsPanel___YkCJ{margin:0;height:100%;overflow:hidden}.ScriptEditor-component__hideCommentsPanel__nIXY1{display:none}", "",{"version":3,"sources":["webpack://./src/modules/Script/ScriptEditor/component/ScriptEditor.component.scss"],"names":[],"mappings":"AAAA,0CACI,WAAA,CACA,iBAAA,CAAA,MAAA,CAAA,KAAA,CAAA,UAAA,CACA,aAAA,CAGJ,uCACI,WAAA,CACA,aAAA,CACA,iBAAA,CACA,YAAA,CAEQ,mDACI,WAAA,CAKhB,gDACI,WAAA,CACA,4BAAA,CAAA,oBAAA,CAGJ,wCACI,yCAAA,CACA,0CAAA,CAGJ,4CACI,YAAA,CAGJ,8CACI,QAAA,CACA,WAAA,CACA,eAAA,CAGJ,kDACI,YAAA","sourcesContent":[".container {\r\n    height: 100%;\r\n    position:relative; left:0; top:0; z-index: 10;\r\n    overflow: auto;\r\n}\r\n\r\n.editor {\r\n    height: 100%;\r\n    overflow: auto;\r\n    position: relative;\r\n    outline: none;\r\n        :global {\r\n            .CodeMirror {\r\n                height: 100%;\r\n        }\r\n    }\r\n}\r\n\r\n.editorContainer{\r\n    height: 100%;\r\n    transform-origin: 0 0;\r\n}\r\n\r\n.resizer {\r\n    border-left: 3px solid rgba(255, 255, 255, 0);\r\n    border-right: 3px solid rgba(255, 255, 255, 0);\r\n}\r\n\r\n.hideResizer{\r\n    display: none;\r\n}\r\n\r\n.commentsPanel {\r\n    margin: 0;\r\n    height: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.hideCommentsPanel{\r\n    display: none;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ScriptEditor-component__container__QLrru",
	"editor": "ScriptEditor-component__editor___uNMS",
	"editorContainer": "ScriptEditor-component__editorContainer__Rakga",
	"resizer": "ScriptEditor-component__resizer___lEgU",
	"hideResizer": "ScriptEditor-component__hideResizer__vQiwQ",
	"commentsPanel": "ScriptEditor-component__commentsPanel___YkCJ",
	"hideCommentsPanel": "ScriptEditor-component__hideCommentsPanel__nIXY1"
};
export default ___CSS_LOADER_EXPORT___;
