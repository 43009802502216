export const GET_EXTERNAL_SESSIONS_REQUEST = 'GET_EXTERNAL_SESSIONS_REQUEST';

export const GET_EXTERNAL_SESSIONS_SUCCESS = 'GET_EXTERNAL_SESSIONS_SUCCESS';

export const CREATE_EXTERNAL_SESSION_REQUEST = 'CREATE_EXTERNAL_SESSION_REQUEST';

export const CREATE_EXTERNAL_SESSION_SUCCESS = 'CREATE_EXTERNAL_SESSION_SUCCESS';

export const DELETE_EXTERNAL_SESSION_REQUEST = 'DELETE_EXTERNAL_SESSION_REQUEST';

export const DELETE_EXTERNAL_SESSION_LIST_REQUEST = 'DELETE_EXTERNAL_SESSION_LIST_REQUEST';

export const DELETE_EXTERNAL_SESSION_SUCCESS = 'DELETE_EXTERNAL_SESSION_SUCCESS';
