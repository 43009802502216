import { RightOutlined } from '@ant-design/icons';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { moveToDirectAction } from '../../../actions/editor.actions';
import { openModelOnCanvas } from '../../../actions/search.actions';
import messages from './DbSearchListItem.messages';
import modelTypeMessages from '../../../models/modelType.messages';
import moveToMessages from '../../MainMenu/components/GraphGeneralToolbar/ToolbarItems/MoveToSelect/MoveToSelect.messages';
import { TreeNode } from '../../../models/tree.types';
import { TSearchDataListItem } from '../../../reducers/search.reducer.types';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { HightLighted } from '../../UIKit/H.O.C/HightLighted/HightLighted.hoc';
import style from './DbSearchListItem.scss';
import { LocalesService } from '../../../services/LocalesService';
import { navigatorTabSelect } from '../../../actions/navigator.actions';
import { TNavigatorTab } from '../../../reducers/navigator.reducer.types';
import { getActiveTabs } from '../../../selectors/navigator.selectors';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TDbSearchListItemProps = WrappedComponentProps &
    TSearchDataListItem & {
        index: number;
    };

const DbSearchListItem = (props: TDbSearchListItemProps) => {
    const { index, path, type, multilingualName, nodeId, intl, itemTypeName, deleted } = props;
    const dispatch = useDispatch();
    const activeTabs: TNavigatorTab[] = useSelector(getActiveTabs);
    const isTreeTabActive = activeTabs.includes(TNavigatorTab.Tree);
    const searchText = useSelector(SearchSelectors.getSearchText(nodeId));

    const handleClick = () => {
        dispatch(openModelOnCanvas({ type, nodeId, multilingualName }));
    };

    const handleMoveTo = () => {
        const treeNode: TreeNode = {
            hasChildren: false,
            nodeId,
            name: LocalesService.internationalStringToString(multilingualName),
            multilingualName,
            type,
            countChildren: 0,
        };

        if (!isTreeTabActive) {
            dispatch(navigatorTabSelect(TNavigatorTab.Tree));
        }

        dispatch(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));
    };

    return (
        <div className={style.listItemContainer}>
            <div className={style.listItem} onClick={handleClick}>
                <div className={style.header}>
                    <span className={style.name}>
                        {`${index}. `}
                        <HightLighted searchText={searchText}>
                            {LocalesService.internationalStringToString(multilingualName)}
                        </HightLighted>
                    </span>
                    <span className={style.type}>{`[${intl.formatMessage(modelTypeMessages[type])}]`}</span>
                    <span className={style.type}>{itemTypeName ? `[${itemTypeName}]` : ''}</span>
                    {deleted && <span className={style.deleted}>{` [${intl.formatMessage(messages.deleted)}]`}</span>}
                </div>
                <div>
                    <span>{path}</span>
                </div>
            </div>
            <Button onClick={handleMoveTo}>
                {intl.formatMessage(moveToMessages.title)}
                <RightOutlined className={style.rightOutlinedIcon} />
            </Button>
        </div>
    );
};

const WithIntl = injectIntl(DbSearchListItem);

const withMemo = React.memo(WithIntl);

export { withMemo as DbSearchListItem };
