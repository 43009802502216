import React, { useEffect, useState } from 'react';
import { Col, Input, Row } from 'antd';
import { useIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import messages from './DashboardCellsCountDialog.messages';
import theme from './DashboardCellsCountDialog.scss';
import { setCellsCount } from '@/actions/dashboard.actions';
import { NodeId } from '@/serverapi/api';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TDashboardCellsCountDialogProps = {
    nodeId: NodeId;
};

export const DashboardCellsCountDialog = (props: TDashboardCellsCountDialogProps) => {
    const { nodeId } = props;
    const { verticalCount, horizontalCount } = useSelector(DashboardSelector.cellsCountById(nodeId));
    const [vCount, setVCount] = useState(verticalCount);
    const [hCount, setHCount] = useState(horizontalCount);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();

    const onClose = () => dispatch(closeDialog(DialogType.DASHBOARD_CELLS_COUNT_DIALOG));
    const onSubmit = () => {
        dispatch(closeDialog(DialogType.DASHBOARD_CELLS_COUNT_DIALOG));
        dispatch(setCellsCount(props.nodeId, vCount, hCount));
    };

    const intl = useIntl();
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.apply),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    useEffect(() => {
        const checkValue = (value: number) => {
            return isNaN(value) || value < 4 || value > 50;
        };

        const isError = checkValue(vCount) || checkValue(hCount);
        setError(isError);
    }, [vCount, hCount]);

    return (
        <Dialog
            width={432}
            open
            onOk={onSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.title)}
            footer={footer}
            className={theme.dialog}
        >
            <Row justify={'space-between'}>
                <Col span={11}>
                    <label className={theme.label}>{intl.formatMessage(messages.verticalCount)}</label>
                    <Input
                        value={vCount}
                        onChange={(e) => setVCount(parseInt(e.target.value))}
                        className={theme.input}
                        type="number"
                    />
                </Col>
                <Col span={11}>
                    <label className={theme.label}>{intl.formatMessage(messages.horizontalCount)}</label>
                    <Input
                        value={hCount}
                        onChange={(e) => setHCount(parseInt(e.target.value))}
                        className={theme.input}
                        type="number"
                    />
                </Col>
            </Row>
            {error && (
                <Row>
                    <div className={theme.error}>{intl.formatMessage(messages.error)}</div>
                </Row>
            )}
        </Dialog>
    );
};
