import { TServerEntity } from '../../../models/entities.types';
import { TServerFormProps, TServerFormState } from '../ServerForm.types';

export const initState: TServerFormState = {
    selectedServerKey: '',
    userInfo: {
        username: '',
        password: '',
        remember: false,
    },
};

export const sortServers = (servs?: TServerEntity[]) =>
    (servs || []).sort((a, b) => (b.lastLoginTimestamp || 0) - (a.lastLoginTimestamp || 0));

export const findUserInfo = (state: TServerFormState, props: TServerFormProps, id?: string): TServerFormState => {
    const { availableServers = [] } = props;
    if (!availableServers || !availableServers.length) {
        return { ...initState };
    }
    id = id || sortServers(availableServers)[0].id;
    const server = availableServers.find((s) => s.id === id);
    if (!server) {
        return { ...initState };
    }

    return {
        selectedServerKey: id,
        userInfo: {
            username: '',
            password: '',
            remember: state && state.userInfo && state.userInfo.remember,
        },
    };
};
