// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BPMPopupMenu__hotKey__FooET{color:rgba(0,0,0,.25)}", "",{"version":3,"sources":["webpack://./src/mxgraph/components/BPMPopupMenu.scss"],"names":[],"mappings":"AAAA,6BACI,qBAAA","sourcesContent":[".hotKey {\r\n    color: rgba(0, 0, 0, 0.25);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hotKey": "BPMPopupMenu__hotKey__FooET"
};
export default ___CSS_LOADER_EXPORT___;
