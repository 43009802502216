import React, { FC, useContext, useState } from 'react';
import { getSelectionInlineStyle } from 'draftjs-utils';
import { RichUtils } from 'draft-js';
import messages from '../CommonToolbar.messages';
import icBold from 'icons/toolbar/controls/ic-font-bold.svg';
import icItalic from 'icons/toolbar/controls/ic-font-italic.svg';
import icUnderline from 'icons/toolbar/controls/ic-font-underline.svg';
import icStrikeThrough from 'icons/toolbar/controls/ic-font-strikethrough.svg';
import icSubscript from 'icons/toolbar/controls/ic-font-subscript.svg';
import icSuperscript from 'icons/toolbar/controls/ic-font-superscript.svg';
import icMonospace from 'icons/toolbar/controls/ic-font-monospace.svg';
import { useIntl } from 'react-intl';
import { ToolbarButton, ToolbarPopoverButton } from 'UIKit';
import icArrowDown from 'icons/toolbar/controls/ic-arrow-down.svg';
import { Button } from 'antd';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../RichTextEditorToolbar.scss';

type TActionDefinition = {
    action: string;
    tooltip: { id: string; defaultMessage: string };
    icon: any;
};

type TExtraActionsDefinitions = {
    [name: string]: TActionDefinition;
};

type TInlineStyleComponent = {
    hideMoreStyles?: boolean;
};

type TInlineStyleValue = {
    [key: string]: boolean;
};

const getFocusedBlock = (editorState) => getSelectionInlineStyle(editorState);

const getChangedBlock = (editorState, style) => {
    console.log('STYLE', style);

    return RichUtils.toggleInlineStyle(editorState, style);
};

const extraActionsDefinitions: TExtraActionsDefinitions = {
    STRIKETHROUGH: {
        action: 'STRIKETHROUGH',
        tooltip: messages.textStrikeThrough,
        icon: icStrikeThrough,
    },
    SUPERSCRIPT: {
        action: 'SUPERSCRIPT',
        tooltip: messages.superscript,
        icon: icSuperscript,
    },
    SUBSCRIPT: {
        action: 'SUBSCRIPT',
        tooltip: messages.subscript,
        icon: icSubscript,
    },
    CODE: {
        action: 'CODE',
        tooltip: messages.monospace,
        icon: icMonospace,
    },
};

const ButtonGroup = Button.Group;

const InlineStyleComponent: FC<TInlineStyleComponent> = ({ hideMoreStyles }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<TInlineStyleValue, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const [activeExtraDefinition, setActiveExtraDefinition] = useState<TActionDefinition>(
        extraActionsDefinitions.STRIKETHROUGH,
    );
    const onExtraChange = (activeActionId) => () => {
        setActiveExtraDefinition(extraActionsDefinitions[activeActionId]);
        setCurrentState(activeActionId);
    };
    const onChange = (activeActionId) => () => {
        setCurrentState(activeActionId);
    };
    const setExtraInlineStyle = () => {
        setCurrentState(activeExtraDefinition.action);
    };

    return (
        <ButtonGroup className={theme.buttonGroup} data-test="wiki-toolbar-group_font-inline-style-button">
            <ToolbarButton
                tooltip={intl.formatMessage(messages.textBold)}
                spriteSymbol={icBold}
                onClick={onChange('BOLD')}
                selected={!!value?.BOLD}
                dataTest="wiki-toolbar-group_font-inline-style_BOLD"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.textItalic)}
                spriteSymbol={icItalic}
                onClick={onChange('ITALIC')}
                selected={!!value?.ITALIC}
                dataTest="wiki-toolbar-group_font-inline-style_ITALIC"
            />
            <ToolbarButton
                tooltip={intl.formatMessage(messages.textUnderline)}
                spriteSymbol={icUnderline}
                onClick={onChange('UNDERLINE')}
                selected={!!value?.UNDERLINE}
                dataTest="wiki-toolbar-group_font-inline-style_UNDERLINE"
            />
            {!hideMoreStyles && (
                <>
                    <ToolbarButton
                        className={theme.indicator}
                        tooltip={intl.formatMessage(activeExtraDefinition.tooltip)}
                        onClick={setExtraInlineStyle}
                        spriteSymbol={activeExtraDefinition.icon}
                        selected={!!(value && value[activeExtraDefinition.action])}
                        dataTest="wiki-toolbar-group_font-inline-style-button_choose-button"
                    />
                    <ToolbarPopoverButton
                        tooltip={intl.formatMessage(messages.moreInlineStyles)}
                        spriteSymbol={icArrowDown}
                        className={theme.arrow}
                        dataTest="wiki-toolbar-group_font-inline-style-button_popover"
                    >
                        <ToolbarButton
                            tooltip={intl.formatMessage(messages.textStrikeThrough)}
                            spriteSymbol={icStrikeThrough}
                            onClick={onExtraChange('STRIKETHROUGH')}
                            selected={!!value?.STRIKETHROUGH}
                            dataTest="wiki-toolbar-group_font-inline-style_strikethrough"
                        />
                        <ToolbarButton
                            tooltip={intl.formatMessage(messages.superscript)}
                            spriteSymbol={icSuperscript}
                            onClick={onExtraChange('SUPERSCRIPT')}
                            selected={!!value?.SUPERSCRIPT}
                            dataTest="wiki-toolbar-group_font-inline-style_superscript"
                        />
                        <ToolbarButton
                            tooltip={intl.formatMessage(messages.subscript)}
                            spriteSymbol={icSubscript}
                            onClick={onExtraChange('SUBSCRIPT')}
                            selected={!!value?.SUBSCRIPT}
                            dataTest="wiki-toolbar-group_font-inline-style_subscript"
                        />
                        <ToolbarButton
                            tooltip={intl.formatMessage(messages.monospace)}
                            spriteSymbol={icMonospace}
                            onClick={onExtraChange('CODE')}
                            selected={!!value?.CODE}
                            dataTest="wiki-toolbar-group_font-inline-style_monospace"
                        />
                    </ToolbarPopoverButton>
                </>
            )}
        </ButtonGroup>
    );
};

export default InlineStyleComponent;
