import { Orientation, PaperFormat } from '../../../../utils/types';

export const GridTypeOnCanvas = {
    noGrid: undefined,
    dot: 'dot',
    'A4/PORTRAIT': `${PaperFormat.A4}/${Orientation.PORTRAIT}`,
    'A4/LANDSCAPE': `${PaperFormat.A4}/${Orientation.LANDSCAPE}`,
    'A3/PORTRAIT': `${PaperFormat.A3}/${Orientation.PORTRAIT}`,
    'A3/LANDSCAPE': `${PaperFormat.A3}/${Orientation.LANDSCAPE}`,
};
