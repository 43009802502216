import { defineMessages } from 'react-intl';

export default defineMessages({
    insert: {
        id: 'InsertionButton.insert',
        defaultMessage: 'Вставить',
    },
    insertAsInstance: {
        id: 'InsertionButton.insertAsInstance',
        defaultMessage: 'Копии экземпляров',
    },
    insertAsObjectDefinition: {
        id: 'InsertionButton.insertAsObjectDefinition',
        defaultMessage: 'Копии определений',
    },
    paste: {
        id: 'InsertionButton.paste',
        defaultMessage: 'Вставить',
    },
    clipboardIsEmpty: {
        id: 'InsertionButton.clipboardIsEmpty',
        defaultMessage: 'Буфер обмена пуст',
    },
});
