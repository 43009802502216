import { TRootState } from '../reducers/root.reducer.types';
import { TExecutingProcess } from '../reducers/statusBar.reducer.types';
import { createSelector } from 'reselect';

export namespace StatusBarSelectors {
    const getState = (state: TRootState) => state.statusBar;

    export const getProcesses = createSelector(getState, (state): TExecutingProcess[] => {
        return state.processes;
    });
}
