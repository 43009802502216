import { defineMessages } from 'react-intl';

export default defineMessages({
    searchPlaceholder: {
        id: 'ChoosePrincipalPanel.searchPlaceholder',
        defaultMessage: 'Поиск по имени, названию группы',
    },
    columnName: {
        id: 'ChoosePrincipalPanel.columnName',
        defaultMessage: 'Имя пользователя, группы',
    },
    emptyTable: {
        id: 'ChoosePrincipalPanel.emptyTable',
        defaultMessage: 'Нет данных',
    },
});
