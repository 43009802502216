import { TREE_NODE_TYPE_REQUEST, TREE_NODE_TYPE_REQUEST_SUCCESS } from '../actionsTypes/treeNodeType.actionTypes';
import { TreeNodeType } from '../serverapi/api';
import { TTreeNodeTypeRequestAction, TTreeNodeTypeRequestSuccessAction } from './treeNodeType.actions.types';

export const treeNodeTypeRequestSuccess = (
    serverId: string,
    treeNodeTypes: TreeNodeType[],
    presetId: string,
): TTreeNodeTypeRequestSuccessAction => ({
    type: TREE_NODE_TYPE_REQUEST_SUCCESS,
    payload: {
        serverId,
        treeNodeTypes,
        presetId,
    },
});

export const treeNodeTypeRequest = (
    serverId: string,
    treeNodeTypes: TreeNodeType[],
    presetId: string,
): TTreeNodeTypeRequestAction => ({
    type: TREE_NODE_TYPE_REQUEST,
    payload: {
        serverId,
        treeNodeTypes,
        presetId,
    },
});
