import React, { useEffect } from 'react';
import { loadMessagesAction } from '@/actions/messages.actions';
import { MessagesSelectors } from '@/selectors/messages.selectors';
import { MailMessageDTO, NotificationDTO, NotificationTemplateDTO } from '@/serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { MessagesList } from '../MessagesList/MessagesList.component';
import theme from './Messages.scss';
import { NotificationTemplatesSelectors } from '@/selectors/notificationTemplates.selectors';
import { NotificationsSelectors } from '@/selectors/notifications.selectors';
import messagesList from '../MessagesList/MessagesList.messages';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { useIntl } from 'react-intl';

export const Messages = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const messages: MailMessageDTO[] = useSelector(MessagesSelectors.getAll);
    const notificationTemplates: NotificationTemplateDTO[] = useSelector(NotificationTemplatesSelectors.getAll);
    const notifications: NotificationDTO[] = useSelector(NotificationsSelectors.getAll);

    useEffect(() => {
        dispatch(loadMessagesAction());
    }, []);

    const onUpdate = () => dispatch(loadMessagesAction());

    return (
        <>
            <div className={theme.btnContainer}>
                <Button onClick={onUpdate} visualStyle="primary">
                    {intl.formatMessage(messagesList.update)}
                </Button>
            </div>
            <MessagesList
                messages={messages}
                notifications={notifications}
                notificationTemplates={notificationTemplates}
            />
        </>
    );
};
