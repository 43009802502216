import React, { FC, useState } from 'react';
import { SearchDuplicates } from './components/SearchDuplicates.component';
import { FilterDuplicates } from './components/FilterDuplicates.component';
import { DuplicatesTable } from './components/DuplicatesTable.component';
import { IWorkspaceTabFindDuplicatesParams, TWorkspaceTab } from '@/models/tab.types';
import { useSelector } from 'react-redux';
import {
    getDuplicatesGroupsByNodeId,
    getFilteredGroupsByNodeId,
    checkFetchingDuplicatesInProgress,
} from '@/selectors/findDuplicates.selector';
import { DuplicatesGroup } from '@/serverapi/api';
import theme from './components/SearchDuplicates.scss';
import { Spin } from 'antd';
import { MainStrategyType } from './types/searchDuplicates.types';

type TFindDuplicatesTab = {
    tab: TWorkspaceTab;
};

export const FindDuplicatesTab: FC<TFindDuplicatesTab> = ({ tab }) => {
    const { nodeId } = tab.params as IWorkspaceTabFindDuplicatesParams;
    const [strategy, setStrategy] = useState<MainStrategyType>(MainStrategyType.Skip);
    const isFetchingDuplicatesInProgress: boolean = useSelector(checkFetchingDuplicatesInProgress());
    const [filter, setFilter] = useState<string>('');
    const filteredGroups: DuplicatesGroup[] = useSelector(getFilteredGroupsByNodeId(nodeId, filter));
    const duplicatesGroupsByNodeId: DuplicatesGroup[] = useSelector(getDuplicatesGroupsByNodeId(nodeId));

    const [mergeAttributes, setMergeAttributes] = useState<boolean>(false);
    const [mergeDecomposition, setMergeDecomposition] = useState<boolean>(false);
    const [deleteSecondary, setDeleteSecondary] = useState<boolean>(false);

    return (
        <div className={theme.wrapper}>
            <SearchDuplicates nodeId={nodeId} isFetchingDuplicatesInProgress={isFetchingDuplicatesInProgress} />
            {(duplicatesGroupsByNodeId.length > 0 && !isFetchingDuplicatesInProgress) && (
                <>
                    <FilterDuplicates
                        filter={filter}
                        setFilter={setFilter}
                        setStrategy={setStrategy}
                        strategy={strategy}
                        setMergeAttributes={setMergeAttributes}
                        setDeleteSecondary={setDeleteSecondary}
                        setMergeDecomposition={setMergeDecomposition}
                        deleteSecondary={deleteSecondary}
                        mergeDecomposition={mergeDecomposition}
                        mergeAttributes={mergeAttributes}
                    />
                    <DuplicatesTable
                        nodeId={nodeId}
                        filteredGroups={filteredGroups}
                        strategy={strategy}
                        deleteSecondary={deleteSecondary}
                        mergeDecomposition={mergeDecomposition}
                        mergeAttributes={mergeAttributes}
                        duplicatesGroups={duplicatesGroupsByNodeId}
                    />
                </>
            )}

            {isFetchingDuplicatesInProgress && <Spin size="large" />}
        </div>
    );
};
