import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { FileUploadDialog } from '../components/FileUploadDialog.component';
import { editorDragFile } from '../../../actions/navigatorSymbol.actions';
import { imageExtensions } from '@/utils/files.utils';

const mapStateToProps = () => ({ filters: imageExtensions.map((el) => `.${el}`).join(', ') });

const mapDispatchToProps = (dispatch) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.IMAGE_UPLOAD_DIALOG));
    },
    onSubmit: (file: File) => {
        dispatch(editorDragFile(file));
        dispatch(closeDialog(DialogType.IMAGE_UPLOAD_DIALOG));
    },
});

export const ImageUploadDialogContainer = connect(mapStateToProps, mapDispatchToProps)(FileUploadDialog);
