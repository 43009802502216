import { IntlShape } from 'react-intl';
import { LocalesService } from '../services/LocalesService';
import messages from './messages/formatBytes.messages';
import { RcFile } from 'rc-upload/lib/interface';

export function formatBytes(bytes: number, decimals = 2): string {
    const intl: IntlShape = LocalesService.useIntl();

    if (bytes === 0) {
        return '0';
    }

    const k = 1024;
    const dm: number = decimals < 0 ? 0 : decimals;
    const sizes: string[] = [
        intl.formatMessage(messages.byte),
        intl.formatMessage(messages.kilobyte),
        intl.formatMessage(messages.megabyte),
        intl.formatMessage(messages.gigabyte),
    ];
    const i: number = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function checkFileSize(file: RcFile): boolean {
    const maxSizeInBytes: number = 500 * 1024;

    return file.size > maxSizeInBytes;
}
