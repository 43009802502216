import { defineMessages } from 'react-intl';

export default defineMessages({
    noImages: {
        id: 'GeneratorImageSelector.noImages',
        defaultMessage: 'Нет доступных иконок',
    },
    cancel: {
        id: 'GeneratorImageSelector.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'GeneratorImageSelector.save',
        defaultMessage: 'Сохранить',
    },
    iconLib: {
        id: 'GeneratorImageSelector.iconLib',
        defaultMessage: 'Библиотека иконок',
    },
    image: {
        id: 'GeneratorImageSelector.image',
        defaultMessage: 'Изображение',
    }
});
