import React, { ChangeEvent, useState } from 'react';
import { PrincipalDescriptor, PrincipalPermissions } from '../../../../serverapi/api';
import { Empty, Table } from 'antd';
import { useIntl } from 'react-intl';
import msg from '../../messages/InstancePermissionTable.messages';
import { PermissionSwitch } from '../PermissionSwitch/PermissionSwitch.component';
import PrincipalLabel from '../PrincipalLabel/PrincipalLabel.component';
import theme from './AdditionalPermissionsTable.scss';
import { ColumnProps } from 'antd/es/table';
import { getFilteredData } from './PermissionTablesUtils';
import { SearchInput } from '../../../UIKit/components/Select/SearchInput.component';

type TAdditionalPermissionsTableProps = {
    principals: Array<PrincipalDescriptor>;
    data: PrincipalPermissions[];
    onChange: (principalId: number, column: string, isGranting: boolean | undefined) => any;
};

export const AdditionalPermissionsTable = ({
    principals,
    data,
    onChange,
}: TAdditionalPermissionsTableProps): JSX.Element => {
    const intl = useIntl();
    const [filter, setFilter] = useState<string>('');

    const principalIds = principals.map((p) => p.id);
    const preparedDataForTable = data
        .filter((permission) => (permission.principalId ? principalIds.includes(permission.principalId) : false))
        .map((permission) => {
            const principal = principals.find((el) => {
                return el.id === permission.principalId;
            });

            return { ...permission, principal };
        });

    function usersAndGroupsColumnRenderer(rowData) {
        const { principalId } = rowData;
        const user = preparedDataForTable.find((el) => el.principalId === principalId);

        if (!user || !user.principal) return null;

        return <PrincipalLabel {...user.principal} />;
    }

    function nextGranting(granting: boolean | undefined): boolean | undefined {
        if (granting === undefined) {
            return true;
        }
        if (granting === true) {
            return false;
        }

        return undefined;
    }

    function permissionColumnRenderer({ rowData, dataKey }) {
        const permissions = rowData.permissions.find((el) => el.permission === dataKey);
        const isGranting = permissions?.isGranting;
        const onClick = () => {
            return onChange(rowData!.principal!.id!, dataKey, nextGranting(isGranting));
        };

        return (
            <div className={theme.permissionCell} onClick={onClick}>
                <PermissionSwitch isGranting={isGranting} propagation dataKey={dataKey} />
            </div>
        );
    }

    function filterHandler(e: ChangeEvent<HTMLInputElement>) {
        setFilter(e.target.value.toLowerCase());
    }

    const filteredPermissions = getFilteredData(filter, preparedDataForTable, principals);
    const permission = filteredPermissions || preparedDataForTable;
    const columns: ColumnProps<PrincipalPermissions>[] = [
        {
            width: 340,
            title: intl.formatMessage(msg.authority),
            dataIndex: 'authority',
            className: theme.principalColumn,
            render: (value, record: PrincipalPermissions) => usersAndGroupsColumnRenderer(record),
            fixed: 'left',
        },
        {
            width: 80,
            title: intl.formatMessage(msg.import),
            dataIndex: 'IMPORT',
            className: theme.permissionColumn,
            render: (value, record: PrincipalPermissions) =>
                permissionColumnRenderer({ rowData: record, dataKey: 'IMPORT' }),
        },
        {
            width: 80,
            title: intl.formatMessage(msg.export),
            dataIndex: 'EXPORT',
            className: theme.permissionColumn,
            render: (value, record: PrincipalPermissions) =>
                permissionColumnRenderer({ rowData: record, dataKey: 'EXPORT' }),
        },
        {
            width: 90,
            title: intl.formatMessage(msg.exportDB),
            dataIndex: 'EXPORT_DB',
            className: theme.permissionColumn,
            render: (value, record: PrincipalPermissions) =>
                permissionColumnRenderer({ rowData: record, dataKey: 'EXPORT_DB' }),
        },
        {
            width: 120,
            title: intl.formatMessage(msg.scriptRun),
            dataIndex: 'SCRIPT_RUN',
            className: theme.permissionColumn,
            render: (value, record: PrincipalPermissions) =>
                permissionColumnRenderer({ rowData: record, dataKey: 'SCRIPT_RUN' }),
        },
        {
            width: 135,
            title: intl.formatMessage(msg.comment),
            dataIndex: 'COMMENT',
            className: theme.permissionColumn,
            render: (value, record: PrincipalPermissions) =>
                permissionColumnRenderer({ rowData: record, dataKey: 'COMMENT' }),
        },
        {
            width: 200,
            title: intl.formatMessage(msg.controlComment),
            dataIndex: 'CONTROL_COMMENT',
            className: theme.permissionColumn,
            render: (value, record: PrincipalPermissions) =>
                permissionColumnRenderer({ rowData: record, dataKey: 'CONTROL_COMMENT' }),
        },
        {
            width: 120,
            title: intl.formatMessage(msg.controlDB),
            dataIndex: 'CONTROL_DB',
            className: theme.permissionColumn,
            render: (value, record: PrincipalPermissions) =>
                permissionColumnRenderer({ rowData: record, dataKey: 'CONTROL_DB' }),
        },
        {
            width: 120,
            title: intl.formatMessage(msg.fileUpload),
            dataIndex: 'FILE_UPLOAD',
            className: theme.permissionColumn,
            render: (value, record: PrincipalPermissions) =>
                permissionColumnRenderer({ rowData: record, dataKey: 'FILE_UPLOAD' }),
        },
        {
            width: 230,
            title: intl.formatMessage(msg.print),
            dataIndex: 'PRINT',
            className: theme.permissionColumn,
            render: (value, record: PrincipalPermissions) =>
                permissionColumnRenderer({ rowData: record, dataKey: 'PRINT' }),
        },
    ];

    return (
        <div className={theme.container}>
            <div className={theme.searchContainer}>
                <SearchInput
                    onSearch={filterHandler}
                    searchValue={filter}
                    showSearch
                    originalTheme
                    allowClear
                    onClear={() => setFilter('')}
                />
            </div>
            {permission.length === 0 ? (
                <Empty description={intl.formatMessage(msg.emptyTable)} />
            ) : (
                <Table
                    scroll={{
                        y: 'max-content',
                        x: 'max-content',
                    }}
                    rowKey={(record: PrincipalPermissions) => `${record.principalId}`}
                    columns={columns}
                    dataSource={permission}
                    size="small"
                    className={theme.table}
                    pagination={false}
                    data-test="permission-dialog_additional-permissions-table"
                />
            )}
        </div>
    );
};
