import { OPEN_ATTRIBUTE_LINK } from "../actionsTypes/openAttributeLink.actionTypes";
import { AttributeValue, NodeId } from "../serverapi/api";
import { TOpenAttributeLinkAction } from "./openAttributeLink.actions.types";

export const openAttributeLinkAction = (attributeValue: AttributeValue, graphId?: NodeId): TOpenAttributeLinkAction => ({
    type: OPEN_ATTRIBUTE_LINK,
    payload: {
        attributeValue,
        graphId,
    },
});