import { TCreateModelTemplateRequestAction } from '@/actions/createModelTemplate.actions.types';
import { openDialog } from '@/actions/dialogs.actions';
import { updateModelType } from '@/actions/modelType.actions';
import { showNotificationByType } from '@/actions/notification.actions';
import { TTreeItemContextMenuAction } from '@/actions/tree.actions.types';
import { CREATE_MODEL_TEMPLATE } from '@/actionsTypes/createModelTemplate.actionTypes';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '@/actionsTypes/tree.actionTypes';
import { NotificationType } from '@/models/notificationType';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TreeItemContextMenuAction, TreeItemType } from '@/modules/Tree/models/tree';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { ModelNode, ModelTemplateDTO, ModelType } from '@/serverapi/api';
import { MethodologySettingDaoService } from '@/services/dao/MethodologySettingDaoService';
import { ModelTemplateDaoService } from '@/services/dao/ModelTemplateDAOService';
import { put, select, takeEvery } from 'redux-saga/effects';

function* handleContextMenuAction({ payload }: TTreeItemContextMenuAction) {
    if (payload.type === TreeItemType.Model && payload.action === TreeItemContextMenuAction.CREATE_MODEL_TEMPLATE) {
        yield put(
            openDialog(DialogType.CREATE_MODEL_TEMPLATE_DIALOG, {
                modelId: payload.nodeId.id,
                repositoryId: payload.nodeId.repositoryId,
            }),
        );
    }
}

function* handleCreateModelTemplate({ payload: { modelId, repositoryId, name } }: TCreateModelTemplateRequestAction) {
    const modelTemplate: ModelTemplateDTO = yield ModelTemplateDaoService.createModelTemplate(
        repositoryId,
        modelId,
        name,
    );

    if (modelTemplate) {
        yield put(showNotificationByType(NotificationType.CREATE_MODEL_TEMPLATE_SUCCESS));

        const serverId: string = yield select(ServerSelectors.serverId);
        const presetId: string = yield select(TreeSelectors.presetById({ repositoryId, id: modelId, serverId }));
        const model: ModelNode = yield select(TreeSelectors.itemById({ id: modelId, repositoryId, serverId }));

        if (model.modelTypeId) {
            const modelType: ModelType = yield MethodologySettingDaoService.getModelType(
                serverId,
                presetId,
                model.modelTypeId,
            );
            yield put(updateModelType({ serverId, presetId, modelType }));
        }
    }
}

export function* createModelTemplateSaga() {
    yield takeEvery(CREATE_MODEL_TEMPLATE, handleCreateModelTemplate);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleContextMenuAction);
}

