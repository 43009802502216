import * as React from 'react';
import { FC } from 'react';
import { Button } from 'antd';
import icHorizontal from '../../../../resources/icons/ic-ribbon-text-horizontal.svg';
import icVertical from '../../../../resources/icons/ic-ribbon-text-vertical.svg';
import { createCompactableSelect } from '../CompactableSelect/CompactableSelect.component';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import messages from './TextDirectionSelect.messages';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import theme from './TextDirectionSelect.scss';
import { TCompactableSelectItemType } from '../CompactableSelect/CompactableSelect.types';

type TTextDirectionSelectProps = WrappedComponentProps & {
    onSelect?: (value: boolean) => void;
    value: boolean;
    buttonGroupMode?: boolean;
    compact?: boolean;
    disabled?: boolean;
    dataTest?: string
};

const TextDirectionSelect = createCompactableSelect<boolean>();

const ChangeDirectionTextSelect: FC<TTextDirectionSelectProps> = (props: TTextDirectionSelectProps) => {
    const { intl, compact, value, disabled, onSelect, buttonGroupMode } = props;

    let Component = (
        <TextDirectionSelect
            title={intl.formatMessage(messages.title)}
            compact={compact}
            value={value}
            items={[
                {
                    type: TCompactableSelectItemType.Value,
                    value: true,
                    label: intl.formatMessage(messages.horizontally),
                    spriteSymbol: icHorizontal,
                },
                {
                    type: TCompactableSelectItemType.Value,
                    value: false,
                    label: intl.formatMessage(messages.vertically),
                    spriteSymbol: icVertical,
                },
            ]}
            disabled={disabled}
            onSelect={onSelect}
            dataTest={props.dataTest}
        />
    );
    if (buttonGroupMode) {
        Component = (
            <Button.Group className={theme.buttonGroup}>
                <Button className={`${!value ? theme.active : ''}`}>
                    <Icon spriteSymbol={icVertical} disabled={disabled} />
                </Button>
                <Button className={`${value ? theme.active : ''}`}>
                    <Icon spriteSymbol={icHorizontal} disabled={disabled} />
                </Button>
            </Button.Group>
        );
    }

    return Component;
};

const TextDirectionSelectWithIntl = injectIntl(ChangeDirectionTextSelect);

export { TextDirectionSelectWithIntl as TextDirectionSelect };

ChangeDirectionTextSelect.defaultProps = {
    compact: false,
};
