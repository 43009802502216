import { FindDuplicatesResponse, MergeResponse, NodeId } from '../../serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';

export class FindDuplicatesDaoService {
    private static getApi() {
        const hostName: string = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getData(nodeId: NodeId, findOnlyChildDuplicates: boolean): Promise<FindDuplicatesResponse> {
        const api: ApiBundle = this.getApi();

        const duplicatesGroups: FindDuplicatesResponse = await api.mergeApi.searchForDuplicatedObjects({
            body: {
                parentNodeId: nodeId,
                findOnlyChildDuplicates,
            },
        });

        return duplicatesGroups;
    }

    public static async merge(mergeRequest): Promise<MergeResponse> {
        const { repositoryId, primaryId, secondaryIds, mergeAttribute, mergeDecomposition, deleteSecondary } =
            mergeRequest;
        const api: ApiBundle = this.getApi();

        const merged: MergeResponse = await api.mergeApi.merge({
            body: {
                repositoryId,
                primaryId,
                secondaryIds,
                mergeAttribute,
                mergeDecomposition,
                deleteSecondary,
            },
        });

        return merged;
    }
}
