import { apiBundle, ApiBundle } from '@/services/api/api-bundle';
import { setServerIdToNodeInterface } from '@/utils/nodeId.utils';
import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { LockInfoDTO, NodeId, ReportNode, ReportType } from '../../../serverapi/api';

export class ReportDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async saveReport(reportNode: ReportNode) {
        const savedReport: ReportNode = await apiBundle(reportNode.nodeId.serverId)
            .report.save({ body: reportNode })
            .then((result) => setServerIdToNodeInterface(result, reportNode.nodeId.serverId));

        return savedReport;
    }

    public static async getReport(repositoryId: string, reportId: string) {
        const api: ApiBundle = this.getApi();
        const report: ReportNode = await api.report.getReport({ repositoryId, reportId });

        return report;
    }

    public static async lockReport(nodeId: NodeId) {
        const lock: LockInfoDTO = await apiBundle(nodeId.serverId).report.lockReport({
            reportId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });
        return lock;
    }

    public static async unlockReport(nodeId: NodeId) {
        const unlock = await apiBundle(nodeId.serverId).report.unlockReport({
            reportId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });
        return unlock;
    }

    public static async createReportType(requestBody: { body: ReportType }) {
        const api = this.getApi();
        await api.reportType.create({ body: requestBody.body });
    }

    public static async saveReportType(requestBody: { body: ReportType }) {
        const api = this.getApi();
        await api.reportType.save({ body: requestBody.body });
    }

    public static async deleteReportType(presetId: string, id: string) {
        const api = this.getApi();
        await api.reportType._delete({ presetId, id });
    }
}
