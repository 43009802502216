import { MxShape, MxSvgCanvas2D, MxRectangle, MxStencil } from '../mxgraph';

export class UMLMessage extends MxShape {
    radius = 20;

    constructor(stencil: MxStencil) {
        super(stencil);
        this.bounds = new MxRectangle(0, 0, this.radius, this.radius);
        this.stroke = '#000000';
        this.strokewidth = 1;
    }

    paintBackground(c: MxSvgCanvas2D, x: number, y: number, w: number, h: number) {
        c.begin();
        c.setFillColor('#000000');
        c.ellipse(x, y, this.radius, this.radius);
        c.fillAndStroke();
        c.end();
    }
}
