// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ObjectDefinitionDialog__tree__i5_0d{margin-bottom:10px;height:300px}.ObjectDefinitionDialog__alert__FQND5{margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/modules/Models/components/ObjectDefinition/ObjectDefinitionDialog.scss"],"names":[],"mappings":"AACA,qCACI,kBAAA,CACA,YAAA,CAGJ,sCACI,kBAAA","sourcesContent":["\r\n.tree {\r\n    margin-bottom: 10px;\r\n    height: 300px;\r\n}\r\n\r\n.alert {\r\n    margin-bottom: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tree": "ObjectDefinitionDialog__tree__i5_0d",
	"alert": "ObjectDefinitionDialog__alert__FQND5"
};
export default ___CSS_LOADER_EXPORT___;
