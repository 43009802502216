import { TFullLicenseDataType } from '../reducers/admintool/licenses.reducer.types';
import { apiBundle, ApiBundle } from './api/api-bundle';

interface ILicenseService {
    getServerLicenseFullInfo(serverId: string): Promise<TFullLicenseDataType>;
    addLicense(serverId: string, license: string): Promise<string>;
}

export enum LicenseLoadStatus {
    SUCCESS = 'SUCCESS',
    FILE_CORRUPTED = 'FILE_CORRUPTED',
    LICENSE_EXIST = 'LICENSE_EXIST',
    FAIL = 'FAIL',
}

class LicenseServiceImpl implements ILicenseService {
    addLicense(serverId: string, license: string): Promise<LicenseLoadStatus> {
        return this.getAPI(serverId).license.addLicense({ body: license }) as Promise<LicenseLoadStatus>;
    }

    getServerLicenseFullInfo(serverId: string): Promise<TFullLicenseDataType> {
        const lApi = this.getAPI(serverId).license;

        return Promise.all([
            lApi.getLicenses(),
            lApi.getResultingLicense(),
            lApi.getAssignedLicense(),
            lApi.getUsedLicense(),
        ]).then((arr) => {
            return {
                licenses: arr[0],
                resulting: arr[1],
                usingLicense: arr[2],
                usingCompetitiveLicense: arr[3],
            } as TFullLicenseDataType;
        });
    }

    private getAPI(serverId: string): ApiBundle {
        try {
            const api = apiBundle(serverId);

            return api;
        } catch (e) {
            throw new Error(`Error in LicenseService, no existing API for server with id=[${serverId}]`);
        }
    }
}

let licenseServiceInstance: ILicenseService;

export function licenseService(): ILicenseService {
    if (!licenseServiceInstance) {
        licenseServiceInstance = new LicenseServiceImpl();
    }

    return licenseServiceInstance;
}
