import { Symbol } from "../serverapi/api";

/**
 * Функция проверяет содержится ли элемент в массиве и возвращает массив, из которого исключен этот элемент если он там был
 * или массив с этим элементом, если в массиве его не было
 *  */

export const toggleArraySymbol = (symbol: Symbol, array: Symbol[] = []): Symbol[] => {
    if (array.map((s) => s.id).includes(symbol.id)) {
        return array.filter((selectedSymbol) => selectedSymbol.id !== symbol.id);
    }

    return [...array, symbol];
};
