import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { TPrintModelState } from '../reducers/printModel.reducer.types';
import { ModelSvgImage } from '../sagas/types/imageSaga.types';

const getState = (state: TRootState) => state.printModel;

export namespace PrintModel {
    export const getImages = createSelector<TRootState, TPrintModelState, (ModelSvgImage | undefined)[]>(
        getState,
    (state) => [state.data, state.selectedData],
    );
}
