import { BPMMxGraphModel } from '../../mxgraph/BPMMxGraphModel.class';
import { MxCell } from '../../mxgraph/mxgraph';
import { TExtendedElement } from './saveModel.types';
import { orderBy } from 'lodash-es';

export function sortCells<T>(elements: T[] | undefined, model: BPMMxGraphModel): T[] {
    if (!elements?.length) return [];

    let extendedElements = elements.slice() as TExtendedElement[];

    extendedElements.forEach((el: TExtendedElement) => {
        const cell: MxCell | undefined = model.cells[el.id] || model.cells[el?.value?.id];
        const parent: MxCell | undefined = cell?.getParent();
        el.parent = el.parent || parent?.id;
        el.indexInParent = parent?.getIndex(cell);
    });

    extendedElements = orderBy(extendedElements, ['parent', 'indexInParent']);

    extendedElements.forEach((el: TExtendedElement) => {
        delete el.indexInParent;
    });

    return extendedElements as T[];
}
