import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../SystemProperties.messages';
import theme from '../../SystemProperties.scss';
import { ts } from '../../SystemProperties.utils';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { FormItemInputNumberWithLabel } from '../FormItemInputNumberWithLabelComponent/FormItemInputNumberWithLabel.component';
import { SESSION_MAX_VALUE, SESSION_MIN_VALUE, SystemPropertiesFormItemNames } from '../../SystemProperties.types';

export const UserSessionSettings = () => {
    const intl = useIntl();
    const userSessionInactivity = useSelector(SystemPropertiesSelectors.getUserSessionInactivity);
    const userSessionLengthMax = useSelector(SystemPropertiesSelectors.getUserSessionLengthMax);
    const [currentUserSessionLengthMax, setMaxcurrentUserSessionLengthMax] = useState(userSessionLengthMax);

    return (
        <div className={theme.formTab}>
            <FormItemInputNumberWithLabel
                dependencies={['userSessionLengthMax']}
                label={intl.formatMessage(messages.userSessionInactivity)}
                initialValue={ts(userSessionInactivity)}
                name={SystemPropertiesFormItemNames.userSessionInactivity}
                placeholder='1800'
                shouldUpdate={(prevValues, curValues) => {
                    setMaxcurrentUserSessionLengthMax(curValues.userSessionLengthMax);

                    return prevValues.userSessionInactivity !== curValues.userSessionLengthMax;
                }}
                rules={[
                    {
                        type: 'number',
                        min: SESSION_MIN_VALUE,
                        max: SESSION_MAX_VALUE,
                        message: intl.formatMessage(messages.rangeValue, {
                            min: SESSION_MIN_VALUE,
                            max: SESSION_MAX_VALUE,
                        }),
                    },
                    {
                        type: 'number',
                        max: currentUserSessionLengthMax,
                        message: intl.formatMessage(messages.userIdleTimeIsTooLong),
                    },
                ]}
            />

            <FormItemInputNumberWithLabel
                label={intl.formatMessage(messages.userSessionLengthMax)}
                initialValue={ts(userSessionLengthMax)}
                name={SystemPropertiesFormItemNames.userSessionLengthMax}
                placeholder='36000'
                rules={[
                    {
                        type: 'number',
                        min: SESSION_MIN_VALUE,
                        max: SESSION_MAX_VALUE,
                        message: intl.formatMessage(messages.rangeValue, {
                            min: SESSION_MIN_VALUE,
                            max: SESSION_MAX_VALUE,
                        }),
                    },
                ]}
            />
        </div>
    );
};
