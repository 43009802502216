import {
    ADD_PRESET_PROFILE,
    DELETE_PRESET_PROFILE,
    OPEN_EDIT_PRESET_PROFILE_TAB,
    GET_PRESET_PROFILES_SUCCESS,
    SAVE_PRESET_PROFILE,
    EDIT_PRESET_PROFILE,
    COPY_PRESET_PROFILE,
} from '../actionsTypes/presetProfile.actionTypes';
import {
    TAddPresetProfileAction,
    TAddPresetProfilePayload,
    TDeletePresetProfileAction,
    TDeletePresetProfilePayload,
    TOpenEditPresetProfileTabAction,
    TOpenEditPresetProfileTabPayload,
    TGetPresetProfileSuccessAction,
    TGetPresetProfileSuccessPayload,
    TSavePresetProfileAction,
    TSavePresetProfilePayload,
    TEditPresetProfilePayload,
    TEditPresetProfileAction,
    TCopyPresetProfilePayload,
    TCopyPresetProfileAction,
} from './presetProfile.actions.types';

export const openEditPresetProfileTab = (
    payload: TOpenEditPresetProfileTabPayload,
): TOpenEditPresetProfileTabAction => ({
    type: OPEN_EDIT_PRESET_PROFILE_TAB,
    payload,
});

export const savePresetProfile = (payload: TSavePresetProfilePayload): TSavePresetProfileAction => ({
    type: SAVE_PRESET_PROFILE,
    payload,
});

export const getPresetProfileSuccess = (payload: TGetPresetProfileSuccessPayload): TGetPresetProfileSuccessAction => ({
    type: GET_PRESET_PROFILES_SUCCESS,
    payload,
});

export const addPresetProfile = (payload: TAddPresetProfilePayload): TAddPresetProfileAction => ({
    type: ADD_PRESET_PROFILE,
    payload,
});

export const editPresetProfile = (payload: TEditPresetProfilePayload): TEditPresetProfileAction => ({
    type: EDIT_PRESET_PROFILE,
    payload,
});

export const deletePresetProfile = (payload: TDeletePresetProfilePayload): TDeletePresetProfileAction => ({
    type: DELETE_PRESET_PROFILE,
    payload,
});

export const copyPresetProfile = (payload: TCopyPresetProfilePayload): TCopyPresetProfileAction => ({
    type: COPY_PRESET_PROFILE,
    payload,
});
