import { put, select, takeEvery } from 'redux-saga/effects';
import { parseTreeItemType, TreeItemType } from '../modules/Tree/models/tree';
import { TREE_ITEM_MOVE } from '../actionsTypes/tree.actionTypes';
import {
    nodePropertiesUpdate,
    treeItemAdd,
    treeItemDelete,
    treeItemRefresh,
    treeItemSelect,
} from '../actions/tree.actions';
import { TTreeItemMoveAction } from '../actions/tree.actions.types';
import { TreeSelectors } from '../selectors/tree.selectors';
import { FolderType, Node, NodeId, ObjectType, RootNodeId } from '../serverapi/api';
import { TreeNode, TTreeEntityState } from '../models/tree.types';
import { showNotification, showNotificationByType } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { compareNodeIds } from '../utils/nodeId.utils';
import { ALLOW_OBJECT_DESTINATIONS } from '../services/consts/TreeConsts';
import { FolderTypeSelectors } from '../selectors/folderType.selectors';
import { getIsTreeItemAllowedInFolder } from '../services/bll/FolderTypeBLLService';
import { v4 as uuid } from 'uuid';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { ObjectTypeSelectors } from '../selectors/objectType.selectors';
import { MoveElementBLL } from '../services/bll/MoveElementBLL';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';

function* handleTreeItemMove(action: TTreeItemMoveAction) {
    const { targetNodeId, draggedNodeId } = action.payload;

    if (compareNodeIds(targetNodeId, draggedNodeId)) return;

    if (targetNodeId.id === targetNodeId.serverId) {
        yield put(showNotificationByType(NotificationType.INVALID_PARENT_FOR_ELEMENT_TYPE));

        return;
    }
    const currentLocale = yield select(getCurrentLocale);

    const targetNode: TTreeEntityState = yield select(TreeSelectors.unfilteredItemById(targetNodeId));

    let draggedNodes: TreeNode[] = [];
    const selectedNodes: TreeNode[] = yield select(SelectedNodesSelector.getNodes());
    /* 
      если перетягиваем ноду, которая не была предварительно выделена
      считаем, что перетаскиваем только ее плюс выделяем
      иначе перетаскиваем все, ранее выделенные ноды, 
      предварительно удалив ноду в которую происходит перенос
    */
    if (!selectedNodes.some((selectedNode) => compareNodeIds(selectedNode.nodeId, draggedNodeId))) {
        const draggedNode: TTreeEntityState = yield select(TreeSelectors.unfilteredItemById(draggedNodeId));
        draggedNodes = [draggedNode];
        yield put(treeItemSelect(draggedNode, NAVIGATOR_STRUCTURE));
    } else {
        draggedNodes = selectedNodes.filter((n) => !compareNodeIds(n.nodeId, targetNode.nodeId));
    }

    const presetId: string = yield select(TreeSelectors.presetById(targetNodeId));
    const targetFolderType: FolderType | undefined = yield select(
        FolderTypeSelectors.byNodeId({
            nodeId: targetNodeId,
            presetId,
        }),
    );
    const firstNotAllowedNode = draggedNodes.find((node) => !getIsTreeItemAllowedInFolder(node, targetFolderType));

    if (firstNotAllowedNode) {
        yield put(
            showNotification({
                type: NotificationType.INSERT_NODE_IN_FOLDER_TYPE,
                id: uuid(),
                data: {
                    nodeName: firstNotAllowedNode.name,
                    nodeType: firstNotAllowedNode.type,
                    folderTypeName: LocalesService.internationalStringToString(
                        targetFolderType?.multilingualName,
                        currentLocale,
                    ),
                },
            }),
        );

        return;
    }

    for (let index = 0; index < draggedNodes.length; index++) {
        const node = draggedNodes[index];
        if (node.nodeId.repositoryId !== targetNode.nodeId.repositoryId) {
            yield put(showNotificationByType(NotificationType.DND_ERROR_INVALID_REPOSITORY));

            return;
        }

        if (
            !node ||
            !targetNode ||
            !ALLOW_OBJECT_DESTINATIONS[node.type] ||
            !ALLOW_OBJECT_DESTINATIONS[node.type].includes(targetNode.type) ||
            node.nodeId.id === RootNodeId.ROOT_SCRIPT_FOLDER_ID
        ) {
            yield put(showNotificationByType(NotificationType.INVALID_PARENT_FOR_ELEMENT_TYPE));

            return;
        }

        if (targetNode.type === TreeItemType.ObjectDefinition) {
            const objectType: ObjectType | undefined = yield select(
                ObjectTypeSelectors.byId({
                    presetId,
                    serverId: targetNode.nodeId.serverId,
                    objectTypeId: targetNode.objectTypeId!,
                }),
            );
            if (!objectType || !MoveElementBLL.isNodeToObjectMovePermitted(node, objectType)) {
                yield put(showNotificationByType(NotificationType.SELECTED_ELEMENTS_CANT_BE_MOVED));

                return;
            }
        }
    }

    try {
        const updatedNodes: Node[] = yield TreeDaoService.move(
            targetNodeId,
            draggedNodes.map((n) => n.nodeId.id),
        );
        yield moveNodesInTree(updatedNodes, draggedNodes, targetNode.nodeId);
    } catch (e) {
        yield put(showNotificationByType(NotificationType.MOVE_NODE_FAILED));
    }
}

export function* moveNodesInTree(updatedNodes: Node[], nodes: TreeNode[], targetNodeId: NodeId) {
    for (const updatedNode of updatedNodes) {
        const node = nodes.find((n) => n.nodeId.id === updatedNode.nodeId.id);
        if (node) {
            yield put(treeItemDelete(node.nodeId));
            yield put(treeItemRefresh(node.parentNodeId));
            yield put(
                treeItemAdd({
                    ...node,
                    children: updatedNode.children as TreeNode[],
                    parentNodeId: targetNodeId,
                }),
            );
            yield put(nodePropertiesUpdate(node.nodeId, parseTreeItemType(node.type), updatedNode));
        }
    }
}

export function* nodeMoveSaga() {
    yield takeEvery(TREE_ITEM_MOVE, handleTreeItemMove);
}
