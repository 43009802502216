import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import { ColorResult, SketchPicker } from 'react-color';
import { ControlsContext } from '../Controls.context';
import icBgColor from 'icons/toolbar/controls/ic-fill-color.svg';
import { DEFAULT_BG_COLOR } from './Controls.constants';
import { Popover } from '../../../Toolbar/Popover.component';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('textStyle').backgroundColor || DEFAULT_BG_COLOR;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setBackgroundColor(value).run();

export const BGColorComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const selectColorHandler = (colorObject: ColorResult) => {
        setCurrentState(colorObject.hex);
    };

    return (
        <Popover
            overlay={<SketchPicker color={value} onChange={selectColorHandler} disableAlpha />}
            title={intl.formatMessage(messages.colorFilling)}
            compact={true}
            arrow={false}
            defaultSprite={icBgColor}
            buttonStyle={{ color: value }}
            dataTest="wiki-toolbar-group_font-bgColor-button"
        />
    );
};
