import { Tooltip } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../messages/CommonToolbar.messages';
import { TextDirectionSelect } from '../TextDirectionSelect/TextDirectionSelect.component';
import theme from '../items/MenuItem.scss';
import { useDispatch } from 'react-redux';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';
import { LabelStyle } from '../../../../models/labelStyle';
import { MxConstants } from '../../../../mxgraph/mxgraph';

type TGraphGeneralToolbarTextDirectionSettingsProps = {
    disabled: boolean;
    compact: boolean;
};

export const GraphGeneralToolbarTextDirectionSettings: FC<TGraphGeneralToolbarTextDirectionSettingsProps> = ({
    disabled,
    compact,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const onChange = (value: boolean) => {
        dispatch(generalMenuLabelStyleChangeAction(LabelStyle.textDirection, MxConstants.STYLE_HORIZONTAL, value));
    };

    return (
        <div className={theme.group}>
            <div className={theme.groupRow}>
                <span>
                    <Tooltip
                        title={disabled ? intl.formatMessage(messages.selectEdgeOrObject) : ''}
                        mouseLeaveDelay={0}
                    >
                        <div className={theme.tooltipContainer} data-test="object-toolbar_text-direction">
                            <TextDirectionSelect
                                value
                                buttonGroupMode={false}
                                compact={compact}
                                onSelect={onChange}
                                disabled={disabled}
                            />
                        </div>
                    </Tooltip>
                </span>
            </div>
        </div>
    );
};
