import type {
    AttributeTypeStyleRule,
    AttributeTypeStyleRuleTypeEnum,
    AttributeTypeValueTypeEnum,
} from '../../../../../../serverapi/api';
import React from 'react';
import theme from '../AttributeRules.scss';
import { AttributeValueType, RuleType } from '../../Attribute.types';
import { NumberInput } from '../../../common/Inputs/NumberInput.component';
import { TimeInput } from '../../../common/Inputs/TimeInput.component';
import { TextInput } from '../../../common/Inputs/TextInput.component';
import { DateInput } from '../../../common/Inputs/DateInput.component';
import { RangeInput } from '../../../common/Inputs/RangeInput.component';
import { SelectInput } from '../../../common/Inputs/MultiSelectInput.component';

type TAttributeRulesInputs = {
    rule: AttributeTypeStyleRule;
    valueType: AttributeTypeValueTypeEnum | undefined;
    attributeStyleId: string;
    disabled?: boolean;
    handleRuleParams: (ruleType: AttributeTypeStyleRuleTypeEnum | undefined, value1: string, value2?: string) => void;
    presetId?: string;
};

export const AttributeRulesInputs = ({
    rule,
    valueType,
    attributeStyleId,
    disabled,
    handleRuleParams,
}: TAttributeRulesInputs) => {
    const withoutInput = [RuleType.HAS_VALUE, RuleType.HAS_NOT_VALUE].includes(rule.type as RuleType);
    const isNumber =
        [
            RuleType.EQUALS,
            RuleType.NOT_EQUALS,
            RuleType.GREATER,
            RuleType.GREATER_OR_EQUAL,
            RuleType.LESS,
            RuleType.LESS_OR_EQUAL,
        ].includes(rule.type as RuleType) && valueType === AttributeValueType.NUMERIC;

    const isDate =
        [RuleType.HAS_NOT_VALUE, RuleType.GREATER, RuleType.LESS, RuleType.BETWEEN, RuleType.NOT_BETWEEN].includes(
            rule.type as RuleType,
        ) && [AttributeValueType.DATE, AttributeValueType.DATE_TIME].includes(valueType as AttributeValueType);

    const isTime =
        [RuleType.HAS_NOT_VALUE, RuleType.GREATER, RuleType.LESS].includes(rule.type as RuleType) &&
        AttributeValueType.TIME === valueType;
    const isSelect =
        ([RuleType.EQUALS, RuleType.NOT_EQUALS].includes(rule.type as RuleType) &&
            [AttributeValueType.MULTI_SELECT, AttributeValueType.SELECT, AttributeValueType.QUERY_SELECT].includes(
                valueType as AttributeValueType,
            )) ||
        (valueType === AttributeValueType.BOOLEAN && rule.type === RuleType.EQUALS);

    const isText =
        ([
            AttributeValueType.JSON,
            AttributeValueType.URL,
            AttributeValueType.NODE,
            AttributeValueType.STRING,
            AttributeValueType.MULTI_STRING,
            AttributeValueType.SELECT,
            AttributeValueType.MULTI_SELECT,
            AttributeValueType.QUERY_MULTI_SELECT,
            AttributeValueType.QUERY_SELECT,
        ].includes(valueType as AttributeValueType) &&
            !isSelect) ||
        (valueType === AttributeValueType.NUMERIC && rule.type === RuleType.HAS_NOT_VALUE);
    const showTime = AttributeValueType.DATE_TIME === valueType;
    const isBetweenRule =
        [RuleType.BETWEEN, RuleType.NOT_BETWEEN].includes(rule.type as RuleType) ||
        ((valueType as AttributeValueType) === AttributeValueType.PERIOD && RuleType.HAS_NOT_VALUE === rule.type);

    return (
        <div className={theme.ruleInput}>
            {!withoutInput && (
                <>
                    {isNumber && <NumberInput rule={rule} disabled={disabled} handleRuleParams={handleRuleParams} />}
                    {isTime && <TimeInput rule={rule} disabled={disabled} handleRuleParams={handleRuleParams} />}
                    {isText && <TextInput rule={rule} disabled={disabled} handleRuleParams={handleRuleParams} />}
                    {isDate && !isBetweenRule && (
                        <DateInput
                            rule={rule}
                            disabled={disabled}
                            showTime={showTime}
                            handleRuleParams={handleRuleParams}
                        />
                    )}
                    {isBetweenRule && (
                        <RangeInput
                            rule={rule}
                            disabled={disabled}
                            showTime={showTime}
                            handleRuleParams={handleRuleParams}
                        />
                    )}
                    {isSelect && (
                        <SelectInput
                            rule={rule}
                            mode={valueType === AttributeValueType.MULTI_SELECT ? 'multiple' : undefined}
                            attributeStyleId={attributeStyleId}
                            handleRuleParams={handleRuleParams}
                            disabled={disabled}
                        />
                    )}
                </>
            )}
        </div>
    );
};
