// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OverlayTypeSelector__container__meBE2{padding-bottom:10px}", "",{"version":3,"sources":["webpack://./src/modules/FloatingAttributes/components/AttributesEditor/AttributeStyleEditor/OverlayTypeSelector/OverlayTypeSelector.scss"],"names":[],"mappings":"AAAA,uCACI,mBAAA","sourcesContent":[".container {\r\n    padding-bottom: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OverlayTypeSelector__container__meBE2"
};
export default ___CSS_LOADER_EXPORT___;
