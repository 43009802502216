import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { UserDTOAccessesEnum } from '../serverapi/api';

export const accessPermissonsState = (state: TRootState) => state.accesses;

export namespace AccessPermissonsSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(accessPermissonsState, (state) => state.byServerId[serverId]);

    export const getOwnersByAccessType = (serverId: string, accessType: UserDTOAccessesEnum) =>
        createSelector(byServerId(serverId), (state) => state && state.get(accessType));
}
