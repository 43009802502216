import { MxConstants } from '../mxgraph/mxgraph';
import { IntlShape } from "react-intl";
import { Locale } from "../modules/Header/components/Header/header.types";
import { AttributeTypeSelectPropertyValue } from "../serverapi/api";
import { LocalesService } from "../services/LocalesService";
import edgeStyleNames from './edge-style.messages';

export enum EdgeStyle {
    straight,
    orthogonal,
    simple,
    isometric,
    curved,
    entityRelation,
}

export const edgeStylesNames = (locale: Locale, intl: IntlShape): AttributeTypeSelectPropertyValue[] => {
    return Object.values(EdgeStyle)
        .filter(value => typeof value === 'string')
        .map(e => e as string)
        .map(e => {
            return {
                id: `${EdgeStyle[e]}`,
                value: LocalesService.changeLocaleValue({}, locale, e && edgeStyleNames[e] && intl.formatMessage(edgeStyleNames[e]) || '')
            };
        });
};

export class EdgeStyleDescriptor {
    public static readonly STYLES: Array<EdgeStyleDescriptor> = [
        // tslint:disable:max-line-length
        new EdgeStyleDescriptor(
            EdgeStyle.straight,
            [MxConstants.STYLE_EDGE, MxConstants.STYLE_CURVED, MxConstants.STYLE_NOEDGESTYLE],
            [null, null, null],
        ),
        new EdgeStyleDescriptor(
            EdgeStyle.orthogonal,
            [MxConstants.STYLE_EDGE, MxConstants.STYLE_CURVED, MxConstants.STYLE_NOEDGESTYLE],
            ['orthogonalEdgeStyle', null, null],
        ),
        new EdgeStyleDescriptor(
            EdgeStyle.simple,
            [MxConstants.STYLE_EDGE, MxConstants.STYLE_ELBOW, MxConstants.STYLE_CURVED, MxConstants.STYLE_NOEDGESTYLE],
            ['elbowEdgeStyle', null, null, null],
        ),
        new EdgeStyleDescriptor(
            EdgeStyle.isometric,
            [MxConstants.STYLE_EDGE, MxConstants.STYLE_ELBOW, MxConstants.STYLE_CURVED, MxConstants.STYLE_NOEDGESTYLE],
            ['isometricEdgeStyle', null, null, null],
        ),
        new EdgeStyleDescriptor(
            EdgeStyle.curved,
            [MxConstants.STYLE_EDGE, MxConstants.STYLE_CURVED, MxConstants.STYLE_NOEDGESTYLE],
            ['orthogonalEdgeStyle', '1', null],
        ),
        new EdgeStyleDescriptor(
            EdgeStyle.entityRelation,
            [MxConstants.STYLE_EDGE, MxConstants.STYLE_CURVED, MxConstants.STYLE_NOEDGESTYLE],
            ['entityRelationEdgeStyle', null, null],
        ),
    ];

    public static of(cellStyle: {}): EdgeStyleDescriptor | undefined {
        return this.STYLES.find(({ styleKeys, styleValues }: EdgeStyleDescriptor) =>
            styleKeys.every((key, index) => {
                const value = styleValues[index];
                if (value) {
                    // need not strict equal because values of styleKeys can be any string or number
                    return cellStyle[key] && cellStyle[key] == value; // tslint:disable-line:triple-equals no-any
                }
 
                return !cellStyle[key];
                
            }),
        );
    }

    public static byValue(value: EdgeStyle): EdgeStyleDescriptor {
        return this.STYLES.find((style) => style.value === value) || this.STYLES[0];
    }

    private constructor(
        public readonly value: EdgeStyle,
        public readonly styleKeys: Array<string>,
        public readonly styleValues: Array<string | null>,
    ) {}
}
