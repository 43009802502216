import type { Editor } from '@tiptap/react';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_FONT_FAMILY } from './Controls.constants';
import messages from '../CommonToolbar.messages';
import { useSharedState } from '../UseSharedState.hook';
import { FontFamilySelect } from '@/modules/MainMenu/components/FontFamilySelect/FontFamilySelect.component';
import { ControlsContext } from '../Controls.context';
import theme from '../../../Toolbar/Toolbar.scss';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('textStyle').fontFamily || DEFAULT_FONT_FAMILY;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setFontFamily(value).run();

type TFontSizeComponent = {
    className?: string;
};

export const FontFamilyComponent: FC<TFontSizeComponent> = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <div data-test="wiki-toolbar-group_select-font-button">
            <FontFamilySelect
                onChange={setCurrentState}
                value={value || ''}
                className={theme.selectFont}
                renderNewOption={false}
                tooltipTitle={intl.formatMessage(messages.fontFamily)}
            />
        </div>
    );
};
