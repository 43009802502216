import React, { ChangeEvent } from 'react';
import messages from './InputSynonymsIds.messages';
import { useIntl } from 'react-intl';
import { FieldValues, Path, PathValue, UseFormRegister } from 'react-hook-form';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import { convertStringToArray, separator } from '@/utils/convertStringToArray';

type TInputSynonymsIdsProps<T extends FieldValues> = {
    value: PathValue<T, Path<T>>;
    onChange?: (value: string[]) => void;
    required?: boolean;
    disabled?: boolean;
    name: Path<T>;
    wrapperClassName?: string;
    mainInputClassName?: string;
    register: UseFormRegister<T>;
};

export const InputSynonymsIds = <T extends FieldValues>(props: TInputSynonymsIdsProps<T>) => {
    const { disabled, required, onChange, name, register, wrapperClassName, mainInputClassName, value } = props;
    const intl = useIntl();

    const formRegister = register(name, {
        value: value?.join(`${separator} `) || '',
        disabled: disabled,
        required,
    });

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        formRegister.onChange(e);
        onChange?.(convertStringToArray(e.target.value));
    };

    return (
        <Input
            className={wrapperClassName}
            classNameInput={mainInputClassName}
            label={intl.formatMessage(messages.synonymsIds)}
            {...formRegister}
            onChange={handleOnChange}
        />
    );
};
