export const NAVIGATOR_PROPERTIES_CHANGE_OBJECT = 'NAVIGATOR_PROPERTIES_CHANGE_OBJECT';

export const NAVIGATOR_PROPERTIES_RENDER_PROP = 'NAVIGATOR_PROPERTIES_RENDER_PROP';

export const NAVIGATOR_PROPERTIES_CHANGE_TAB = 'NAVIGATOR_PROPERTIES_CHANGE_TAB';

export const NAVIGATOR_PROPERTIES_CHANGE_PROPERTY = 'NAVIGATOR_PROPERTIES_CHANGE_PROPERTY';

export const NAVIGATOR_PROPERTIES_CHANGE_PROPERTY_SET = 'NAVIGATOR_PROPERTIES_CHANGE_PROPERTY_SET';

export const NAVIGATOR_CLEAR_PROPERTIES = 'NAVIGATOR_CLEAR_PROPERTIES';

export const CHANGE_SYMBOL_VIEW_MODE = 'CHANGE_SYMBOL_VIEW_MODE';
