import { connect } from 'react-redux';
import { SimulationSetupDialogComponent } from './SimulationSetupDialog.component';
import { TSimulationSetupDialogProps as DialogProps } from './SimulationSetupDialog.types';
import { TRootState } from '../../../../../reducers/root.reducer.types';
import { AttributeTypeSelectors } from '../../../../../selectors/attributeType.selectors';

const mapStateToProps = (state: TRootState, props: Partial<DialogProps>): Partial<DialogProps> => {
    if (props.serverId) {
        return {
            attributeTypes: AttributeTypeSelectors.nameAndIdList(props.serverId)(state),
        };
    }

    return { attributeTypes: [] };
};

const mapDispatchToProps = {};

export const SimulationSetupDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SimulationSetupDialogComponent);
