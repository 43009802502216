type TSortResult = 0 | 1 | -1;

export const sortByAlpha = (a: string = '', b: string = ''): TSortResult => {
    if (a.toLowerCase() === b.toLowerCase()) return 0;

    return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
};


export const sortByNumber = (a: number, b: number) => {
    return a - b;
};