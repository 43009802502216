import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import umMessages from '../../UserManagement/messages/userManagment.messages';
import { Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

type TDBAccessTabToolBarProps = {
    repositoryName: string;
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default class DBAccessTabToolBar extends Component<TDBAccessTabToolBarProps> {
    render() {
        const { repositoryName, handleSearch } = this.props;

        return (
            <Row gutter={16}>
                <Col span={8}>
                    <FormattedMessage {...umMessages.dbTitle} />
                    {repositoryName && <b>{repositoryName}</b>}
                </Col>
                <Col span={6}>
                    <Input suffix={<SearchOutlined />} onChange={handleSearch} style={{ width: 200 }} />
                </Col>
            </Row>
        );
    }
}
