import {
    ApprovalAssistantsDTO,
    ApprovalCommentDTO,
    ApprovalCopyId,
    ApprovalDTO,
    ApprovalSearchRequest,
    ApprovalSearchResult,
    ApprovalVoteDTO,
    NodeId,
} from '@/serverapi/api';
import { ApiBundle, apiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';

export class ApprovalDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async create(body: ApprovalDTO): Promise<ApprovalDTO> {
        const approval = await apiBundle(body.id.serverId).approval.create({ body });
        approval.id.serverId = body.id.serverId;

        return approval;
    }

    public static async save(body: ApprovalDTO): Promise<ApprovalDTO> {
        const approval = await apiBundle(body.id.serverId).approval.save({ body });
        approval.id.serverId = body.id.serverId;

        return approval;
    }

    public static async delete(approvalNodeId: NodeId): Promise<void> {
        await apiBundle(approvalNodeId.serverId).approval.deleteById({
            repositoryId: approvalNodeId.repositoryId,
            approvalId: approvalNodeId.id,
        });
    }

    public static async comment(comment: ApprovalCommentDTO): Promise<ApprovalDTO> {
        const approval = await apiBundle(comment.approvalId.serverId).approval.comment({ body: comment });
        approval.id.serverId = comment.approvalId.serverId;

        return approval;
    }

    public static async vote(vote: ApprovalVoteDTO): Promise<ApprovalDTO> {
        const approval = await apiBundle(vote.approvalId.serverId).approval.vote({ body: vote });
        approval.id.serverId = vote.approvalId.serverId;

        return approval;
    }

    public static async getApprovals(serverId: string): Promise<Array<ApprovalDTO>> {
        const api = this.getApi();
        const approvals: ApprovalDTO[] = await api.approval.getAll();

        return approvals.map((approval) => {
            return { ...approval, id: { ...approval.id, serverId } };
        });
    }

    public static async copy(approvalCopyId: ApprovalCopyId): Promise<ApprovalDTO> {
        const api = this.getApi();
        const approval = await api.approval.copy({
            body: approvalCopyId,
        });
        approval.id.serverId = approvalCopyId.approvedNodeId.serverId;

        return approval;
    }

    public static async addAssistant(assistantsDTO: ApprovalAssistantsDTO): Promise<ApprovalDTO> {
        const api = this.getApi();
        const approval = await api.approval.addAssistant({
            body: assistantsDTO,
        });
        approval.id.serverId = assistantsDTO.approvalId.serverId;

        return approval;
    }

    public static async search(
        searchRequest: ApprovalSearchRequest,
        serverId: string,
    ): Promise<Array<ApprovalSearchResult>> {
        const api = this.getApi();
        const searchResponse = await api.approval.search({
            body: searchRequest,
        });

        const searchResult = searchResponse.searchResults || [];

        return searchResult.map((res) => {
            if (res.approval) {
                return {
                    ...res,
                    approval: {
                        ...res.approval,
                        id: {
                            ...res.approval.id,
                            serverId,
                        },
                    },
                };
            }
            return res;
        });
    }
}
