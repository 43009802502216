import React from 'react';
import { NavigatorPanelButton } from '../../NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { SeachIcon } from '../../NavigatorPanel/icons/SearchIcon.component';
import { useDispatch, useSelector } from 'react-redux';
import { chamgeNavigatorTreeSeachStatus } from '../../../../../actions/navigatorTreeSearch.actions';
import { NavigatorTreeSearchSelector } from '../../../../../selectors/navigatorTreeSearch.selectors';
import messages from '../../../messages/Navigator.messages';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

export const TreeSearch = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const isActive: boolean = useSelector(NavigatorTreeSearchSelector.getIsSearchActive);
    
    const handleClick = () => {
        dispatch(chamgeNavigatorTreeSeachStatus(isActive ? false : true));
    };

    return (
        <Tooltip mouseLeaveDelay={0} placement="top" title={intl.formatMessage(messages.search)}>
            <>
                <NavigatorPanelButton
                    dataTest="navigator-panel_search_icon"
                    IconComponent={SeachIcon}
                    active={isActive}
                    onClick={handleClick}
                />
            </>
        </Tooltip>
    );
};
