
import type { MxCell, MxUndoableEdit } from './mxgraph';
import type { EdgeDefinitionNode, NodeId, ObjectDefinitionNode } from '@/serverapi/api';
import { getActiveGraph } from '@/selectors/editor.selectors';
import { getStore } from '@/store';
import type { CommentMarker, EdgeInstanceImpl, ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { clearContextMenuParentId } from '@/actions/contextMenu.actions';
import { ObjectDefinitionSelectors } from '@/selectors/objectDefinition.selectors';
import { EdgeDefinitionSelectors } from '@/selectors/edgeDefinition.selector';

const isChangeRevertable = (edit: MxUndoableEdit): boolean => {
    const state = getStore().getState();
    const activeGraphId = getActiveGraph(state);

    if (!activeGraphId) {
        return true;
    }

    const hasUndoUnavailableChanges = edit.changes.some((change) => {
        const cell: MxCell = change.child || change.cell;
        const value = cell?.getValue();

        if (!value) {
            return false;
        }
        if (value.objectDefinitionId && (value as ObjectInstanceImpl).type === 'object') {
            const objectDefinitionId: NodeId = { ...activeGraphId, id: value.objectDefinitionId };
            const objectDefinition: ObjectDefinitionNode | undefined = ObjectDefinitionSelectors.byId(objectDefinitionId)(state);

            if (!objectDefinition || objectDefinition.deleted) {
                return true;
            }
        }
        if (value.edgeDefinitionId && (value as EdgeInstanceImpl).type === 'edge') {
            const edgeDefinitionId: NodeId = { ...activeGraphId, id: value.edgeDefinitionId };
            const edgeDefinition: EdgeDefinitionNode | undefined = EdgeDefinitionSelectors.byId(edgeDefinitionId)(state);

            if (!edgeDefinition || edgeDefinition.deleted) {
                return true;
            }
        }
        if ((value as CommentMarker).type === 'CommentMarker') {
            return true;
        }

        return false;
    });

    return !hasUndoUnavailableChanges;
};

const closeNavigatorContextMenu = () => {
    getStore().dispatch(clearContextMenuParentId());
};

const getActiveGraphNodeId = (): NodeId | undefined => {
    const state = getStore().getState();

    return getActiveGraph(state);
};

export default { isChangeRevertable, closeNavigatorContextMenu, getActiveGraphNodeId };
