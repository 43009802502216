export const SYMBOL_HORIZONTAL_PADDING = 4;

export const SYMBOL_VERTICAL_PADDING = 5;

export const SYMBOL_LINE_HEIGHT = 14;

export enum UML_OBJECT_TYPE {
    CLASS = 'o_uml_class',
    PACKAGE = 'o_uml_package',
    ATTRIBUTE = 'o_uml_atribute',
    RECEPTION = 'o_uml_reception',
    METHOD = 'o_uml_method',
    PARAMETER = 'o_uml_parameter',
}

export enum UML_ID_SYMBOL {
    ATTRIBUTE = 's_uml_atribute',
    RECEPTION = 's_uml_reception',
    METHOD = 's_uml_method',
    PARAMETER = 's_uml_parameter',
    CLASS = 's_uml_class',
}

export enum UML_ATTR_CLASS {
    STEREOTYPE = 'a_uml_class_stereotype',
    PARAMETER_ONE = 'a_uml_class_parameter1',
    PARAMETER_TWO = 'a_uml_class_parameter2',
    VISIBILITY = 'a_uml_class_visibility',
}

export const attributeVisibilities = {
    a_public: '+',
    a_package: '~',
    a_protected: '#',
    a_private: '-',
};

export const UML_STEREOTYPE_SPECIAL_START_CHARS = '&#60;&#60;';

export const UML_STEREOTYPE_SPECIAL_END_CHARS = '&#62;&#62;';

export const textBlockStyles = {
    width: 'fit-content',
    fontFamily: 'Helvetica',
    fontSize: '11',
    fontWeight: 'normal',
    textDecoration: 'none',
    fontStyle: 'normal',
    textTransform: 'none',
    textAlign: 'center',
    lineHeight: '1.2',
    overflowWrap: 'anywhere',
    padding: `0px ${SYMBOL_HORIZONTAL_PADDING}px`,
};
