import { put, takeEvery } from 'redux-saga/effects';
import { addMessagesAction } from '@/actions/messages.actions';
import { LOAD_MESSAGES_REQUEST } from '@/actionsTypes/messages.actionTypes';
import { MailMessageDTO } from '@/serverapi/api';
import { MessagesDAOService } from '@/services/dao/MessagesDAOService';

function* loadMessages() {
    const messages: MailMessageDTO[] = yield MessagesDAOService.getMailMessages();

    yield put(addMessagesAction(messages));
}

export function* messagesSaga() {
    yield takeEvery(LOAD_MESSAGES_REQUEST, loadMessages);
}

