import { createSelector } from 'reselect';
import { TSimulationState } from '../reducers/entities/simulation.reducer.types';
import { TRootState } from '../reducers/root.reducer.types';
import { NodeId, SimulationRun } from '../serverapi/api';

const simulationStateSelector = (state: TRootState): TSimulationState => state.simulationModeling;

export namespace SimulationModelingSelectors {
    export const simulationRuns = (nodeId: NodeId) =>
        createSelector<TRootState, TSimulationState, SimulationRun[]>(
            simulationStateSelector,
            (state) => state.simulationRuns.get(nodeId) || [],
        );

    export const simulationSettings = (nodeId: NodeId) =>
        createSelector(simulationStateSelector, (state) => state.simulationSettings.get(nodeId));

    export const simulationRunById = (nodeId: NodeId, id: string) =>
        createSelector<TRootState, SimulationRun[], SimulationRun | undefined>(simulationRuns(nodeId), (runs) =>
            runs.find((simulationRun) => simulationRun.id.id === id),
        );
}
