import { IntlShape } from 'react-intl';
import { ArisSymbolMap, ArisTypeMap } from '../../../../../serverapi/api';
import messages from '../../messages/ImportAris.messages';
import { DefaultOptionMessage } from './MethodologyStep.component';

export const getCountMessage = (sourceType: ArisTypeMap, intl: IntlShape): string => {
    const { examples, count } = sourceType;
    if (!count) return '';

    const endMessage: string =
        count === 1 ? '' : ` ("${examples?.[0]}" ${intl.formatMessage(messages.else)} ${count - 1})`;

    return !examples?.[0] ? ` (${intl.formatMessage(messages.total)} ${count})` : endMessage;
};

export const getArisCellTableData = (sourceType: ArisTypeMap, intl: IntlShape, type: DefaultOptionMessage): string => {
    const { examples, arisId } = sourceType;
    const arisIdMessage: string = arisId ? ` "${arisId}"` : '';
    let cellType: string = intl.formatMessage(messages.cellTypeObject);

    switch (type) {
        case DefaultOptionMessage.objectTypes:
            cellType = intl.formatMessage(messages.cellTypeObject);
            break;
        case DefaultOptionMessage.modelTypes:
            cellType = intl.formatMessage(messages.cellTypeModel);
            break;
        case DefaultOptionMessage.symbols:
            cellType = intl.formatMessage(messages.cellTypeSymbol);
            break;
        case DefaultOptionMessage.edgeTypes:
            cellType = intl.formatMessage(messages.cellTypeEdge);
            break;
        case DefaultOptionMessage.attributeTypes:
            cellType = intl.formatMessage(messages.cellTypeAttribute);
            break;
        default:
            break
    }
  
    const annotation: string = `${cellType}${arisIdMessage}`;
    const countMessage: string = getCountMessage(sourceType, intl);
    const examplesMessage: string = examples?.[0] && countMessage === '' ? ` ("${examples?.[0]}")` : '';

    return `${annotation}${examplesMessage}${countMessage}`;
};

export const getSymbolsOfObject = (
    symbols: ArisSymbolMap[] | undefined,
    objectTypeId: string | undefined,
): ArisSymbolMap[] | undefined => {
    return symbols?.filter((symbol) => symbol.arisObjectTypeId === objectTypeId);
};

export const getSymbolsWithoutObject = (
    symbols: ArisSymbolMap[] | undefined,
    objects: ArisTypeMap[] | undefined,
): ArisSymbolMap[] | undefined => {
    return symbols?.filter(
        (symbol) =>
            !objects?.find(
                (object) => symbol.arisObjectTypeId !== undefined && symbol.arisObjectTypeId === object.arisId,
            ),
    );
};
