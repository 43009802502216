// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectDataStep__description__NVjPW{font-size:14px;font-weight:500;color:var(--mainColor)}.SelectDataStep__tree__kRC9f{padding-top:10px}", "",{"version":3,"sources":["webpack://./src/modules/Import/Aris/components/SelectDataStep/SelectDataStep.scss"],"names":[],"mappings":"AAAA,oCACE,cAAA,CACA,eAAA,CACA,sBAAA,CAGF,6BACE,gBAAA","sourcesContent":[".description {\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n  color: var(--mainColor);\r\n}\r\n\r\n.tree {\r\n  padding-top: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "SelectDataStep__description__NVjPW",
	"tree": "SelectDataStep__tree__kRC9f"
};
export default ___CSS_LOADER_EXPORT___;
