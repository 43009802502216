import type { AttributeTypeStyle, PresetImage } from '../../../../../../serverapi/api';
import type { TFloatingAttributesChangeImageStylePayload } from '../../../../actions/FloatingAttributes.actions.types';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import BPMMxCellOverlay from '../../../../../../mxgraph/overlays/BPMMxCellOverlay';
import theme from '../../AttributesEditor.scss';
import { AttributeHorizontalAlignSelector } from '../common/AttributeHorizontalAlignSelector.component';
import { AttributeImageSelector } from './AttributeImageSelector.component';
import { AttributeImageSizeSelector } from './AttributeImageSizeSelector.component';
import { AttributeVerticalAlignSelector } from '../common/AttributeVerticalAlignSelector.component';
import { floatingAttributesChangeImageStyleAction } from '../../../../actions/FloatingAttributes.actions';

type TAttributeImageStyleEditorProps = {
    selectedStyle?: AttributeTypeStyle | null;
    images: PresetImage[];
    disabled?: boolean;
};

export const AttributeImageStyleEditor = ({ selectedStyle, images, disabled }: TAttributeImageStyleEditorProps) => {
    const dispatch = useDispatch();
    const parsedStyle = useMemo(() => BPMMxCellOverlay.parseImageStyle(selectedStyle?.style), [selectedStyle]);

    const handleChangeImageStyle = (payload: TFloatingAttributesChangeImageStylePayload): void => {
        dispatch(floatingAttributesChangeImageStyleAction(payload));
    };

    const handleImageChange = (imageId?: string) => {
        handleChangeImageStyle({ imageId });
    };

    const handleStyleChange = (imageStyle: string) => {
        const parsedCurrentStyle = BPMMxCellOverlay.parseImageStyle(selectedStyle?.style);
        const parsedNewImageStyle = BPMMxCellOverlay.parseImageStyle(imageStyle);
        const updatedStyle = BPMMxCellOverlay.stringifyImageStyle({
            ...parsedCurrentStyle,
            ...parsedNewImageStyle,
        });

        handleChangeImageStyle({ style: updatedStyle });
    };

    const handleAlignChange = (align: string) => {
        handleStyleChange(`align:${align};`);
    };

    const handleBaselineChange = (baseline: string) => {
        handleStyleChange(`baseline:${baseline};`);
    };

    const handleSizeChange = (size: number) => {
        handleStyleChange(`size:${size};`);
    };

    return (
        <div className={theme.imagesSettings}>
            <Row gutter={[0, 5]}>
                <Col span={12}>
                    <AttributeImageSelector
                        imageId={selectedStyle?.imageId}
                        images={images}
                        disabled={disabled}
                        onChange={handleImageChange}
                    />
                </Col>
                {!!images.length && (
                    <Col span={12}>
                        <Row gutter={[0, 5]}>
                            <AttributeImageSizeSelector
                                initialValue={parsedStyle?.size}
                                disabled={disabled}
                                onChange={handleSizeChange}
                            />
                            <AttributeHorizontalAlignSelector
                                initialValue={parsedStyle?.align}
                                disabled={disabled}
                                onChange={handleAlignChange}
                            />
                            <AttributeVerticalAlignSelector
                                initialValue={parsedStyle?.baseline}
                                disabled={disabled}
                                onChange={handleBaselineChange}
                            />
                        </Row>
                    </Col>
                )}
            </Row>
        </div>
    );
};
