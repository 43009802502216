import React, { useRef, useEffect, useState } from 'react';
import theme from './CommentCustomTextArea.scss';
import { CommentFileDTO, NodeId } from '../../../../serverapi/api';
import addFileIcon from '../../../../resources/icons/addFileIcon.svg';
import editCommentIcon from '../../../../resources/icons/editCommentIcon.svg';
import messages from '../../messages/CommentsPanel.messages';
import { Icon } from '../../../UIKit';
import { useDispatch, useSelector } from 'react-redux';
import { Input, InputRef } from 'antd';
import {
    changeEditingComment,
    commentsPanelFocus,
    deleteEditingComment,
    startEditComment,
    saveEditingComment,
} from '../../../../actions/comments.actions';
import { CommentsSelectors } from '../../../../selectors/comments.selectors';
import { CommentUploadedFilesArea } from '../CommentUploadedFilesArea/CommentUploadedFilesArea';
import { CommentNameHeader } from '../CommentNameHeader.component';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { TComment, TEditingCommentFiles } from '../../../../reducers/comments.reducer.types';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { LocalesService } from '../../../../services/LocalesService';
import cx from 'classnames';
import { useCommentText } from './hooks/useCommentText';
import { v4 as uuid } from 'uuid';
import { MAX_SIZE_IN_BYTES } from '../../utils/commentsUtils';
import { showNotification } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TCommentCustomTextAreaProps = {
    modelId: NodeId;
    parentId?: string;
    commentId?: string;
    threadId?: string;
    isMinSize?: boolean;
    isFullSize?: boolean;
    withHeader?: boolean;
    showInTooltip?: boolean;
    onFinishEditing?: () => void;
    onEscape?: () => void;
    onCancel?: () => void;
    onChangeVisible?: () => void;
    openComentEditDialog?: (e?: React.MouseEvent) => void;
    popupStyles?: CSSStyleDeclaration;
};

const COMMENT_TEXTAREA_CONTAINER = 'commentTextAreaContainer';

export const CommentCustomTextArea = (props: TCommentCustomTextAreaProps) => {
    const ref = useRef<InputRef>(null);
    const {
        modelId,
        parentId,
        commentId,
        threadId,
        isMinSize,
        isFullSize,
        withHeader,
        showInTooltip,
        onFinishEditing,
        onEscape,
        onChangeVisible,
        openComentEditDialog,
        popupStyles,
    } = props;
    const textAreaRef = useRef<TextAreaRef>(null);
    const dispatch = useDispatch();
    const intl = LocalesService.useIntl();
    const isCancelButtonVisible: boolean = !!props.onCancel;
    const comment: TComment | undefined = useSelector(CommentsSelectors.getEditingComment(modelId));
    const editingFiles: TEditingCommentFiles | undefined = comment?.files;
    const isEditedComment: boolean = useSelector(CommentsSelectors.isEditedComment(modelId, commentId));
    const isEmptyEditingText = (comment?.text || '').trim().length === 0;
    const {editingText, setEditingText} = useCommentText(comment);
    const [inputKey, setInputKey] = useState<string>(uuid());

    useEffect(() => {
        if (!isEditedComment) {
            dispatch(startEditComment(modelId, parentId, commentId, threadId, showInTooltip, popupStyles));
        }
    }, []);

    useEffect(() => {
        const div = document.getElementById(COMMENT_TEXTAREA_CONTAINER);
        if (div && !!showInTooltip && !!isMinSize) div.scrollIntoView();
    });

    useEffect(() => {
        // Установка курсора в конец строки при начальном открытии окна на редактирования комментария
        textAreaRef?.current?.resizableTextArea?.textArea?.setSelectionRange?.(editingText?.length || null,
            editingText?.length || null);
    }, []);

    const hangleChangeEditingComment = (text?: string, files?: TEditingCommentFiles) =>
        dispatch(changeEditingComment(modelId, text, files, threadId));

    const onCommentSubmit = () => {
        if (onFinishEditing) onFinishEditing();
        dispatch(saveEditingComment(modelId));
        dispatch(deleteEditingComment(modelId));
    };

    const onKeyPress = (e: React.KeyboardEvent<TextAreaRef>) => {
        if (e.code !== 'Escape') return;

        if (isEditedComment) {
            dispatch(openDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG, { onCancel: onEscape }));
        } else {
            dispatch(deleteEditingComment(modelId));
            if (onEscape) onEscape();
        }
    };

    const onInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const commentText: string | undefined = e.target.value;
        setEditingText(commentText);
        hangleChangeEditingComment(commentText);
    };

    const onFocus = () => {
        dispatch(commentsPanelFocus());
        // без этой строчки фокус ставится со второго клика
        textAreaRef?.current?.focus();
    };

    const onOpenComentEditDialog = (e: React.MouseEvent) => {
        e?.stopPropagation();
        if (onChangeVisible) onChangeVisible();
        if (openComentEditDialog) openComentEditDialog();
    };

    const onCancel = () => {
        if (isEditedComment) {
            dispatch(openDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG, { onCancel: props.onCancel }));
        } else {
            dispatch(deleteEditingComment(modelId));
            if (props.onCancel) props.onCancel();
        }
    };

    const handleChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
        const innerFiles = Array.from(e.target.files || []);
        setInputKey(() => uuid());
        const allowedFiles: File[] = innerFiles.filter(file => file.size < MAX_SIZE_IN_BYTES);

        innerFiles.forEach((file: File) => {
            if (file.size > MAX_SIZE_IN_BYTES) {
                dispatch(
                    showNotification({
                        id: uuid(),
                        type: NotificationType.MAX_SIZE_FOR_FILE,
                        data: { message: `${file.name} ${intl.formatMessage(messages.fileMaxSize)}` },
                    }),
                );
            };
        });

        hangleChangeEditingComment(undefined, {
            ...editingFiles,
            newFiles: [...(editingFiles?.newFiles || []), ...allowedFiles],
        });
    };

    return (
        <div
            className={cx(theme.container, { [theme.showInTooltip]: showInTooltip })}
            onKeyDown={onKeyPress}
            data-test="comment-creation_container"
            id={COMMENT_TEXTAREA_CONTAINER}
        >
            {withHeader && (
                <div className={theme.commentHeaderContainer} data-test="comment-creation_header">
                    <CommentNameHeader
                        className={theme.commentNameHeaderContainer}
                        modelId={modelId}
                        commentId={commentId}
                    />
                    <Icon
                        spriteSymbol={editCommentIcon}
                        className={theme.headerIcon}
                        onClick={onOpenComentEditDialog}
                        dataTest="comment-creation_open-in-window"
                    />
                </div>
            )}
            <Input.TextArea
                data-test="field-for-entering-a-new-comment"
                ref={textAreaRef}
                autoFocus
                value={editingText}
                className={theme.textArea}
                autoSize={{ minRows: isFullSize ? 10 : 4, maxRows: 10 }}
                onChange={onInput}
                onFocus={onFocus}
                bordered={false}
                rows={isFullSize ? 10 : 4}
            />
            {(!!editingFiles?.commentFiles?.length || !!editingFiles?.newFiles?.length) && (
                <div className={theme.customFileArea} data-test="comment-creation_uploaded-files-area">
                    <CommentUploadedFilesArea
                        commentFiles={editingFiles?.commentFiles || []}
                        uploadedFiles={editingFiles?.newFiles || []}
                        setFiles={(newFiles: File[], commentFiles: CommentFileDTO[]) => {
                            hangleChangeEditingComment(undefined, {
                                ...editingFiles,
                                commentFiles: commentFiles || editingFiles?.commentFiles,
                                newFiles: newFiles || editingFiles?.newFiles,
                            });
                        }}
                        isFullSize={isFullSize}
                    />
                </div>
            )}
            <Input
                key={inputKey}
                ref={ref}
                className={theme.unvisible}
                type="file"
                multiple
                onChange={handleChange}
                data-test="comment-creation_new_files-input"
            />
            <div className={`${theme.footer} ${isMinSize ? theme.withBorder : ''}`}>
                <div className={theme.footerIconsContainer}>
                    {/* <Icon spriteSymbol={emojiIcon} className={theme.footerIcon} /> */}
                    <div onClick={() => ref?.current?.input?.click()}>
                        <Icon spriteSymbol={addFileIcon} className={theme.footerIcon} />
                    </div>
                </div>
                <div className={theme.footerButtonsContainer}>
                    {isCancelButtonVisible && (
                        <Button size="small" onClick={onCancel} dataTest="cancel-comment-btn">
                            {intl.formatMessage(messages.cancelText)}
                        </Button>
                    )}
                    <Button
                        dataTest="send-comment-btn"
                        disabled={!isEditedComment || isEmptyEditingText}
                        visualStyle="primary"
                        size="small"
                        onClick={onCommentSubmit}
                    >
                        {intl.formatMessage(messages.sendText)}
                    </Button>
                </div>
            </div>
        </div>
    );
};
