import React, { FC } from 'react';
import theme from './Kanban.scss';
import {
    PresetImage,
    KanbanCardType,
    AttributeType,
    KanbanCardSectorAttribute,
    NodeId,
    PrincipalDescriptor,
    AttributeTypeStyle,
    KanbanCardSectorSettings,
} from '../../serverapi/api';
import { Locale } from '../Header/components/Header/header.types';
import {
    KanbanCardSectorAttributeSettingsFormat,
    TKanbanSymbolCardItem,
    KanbanHorizontalAlign,
    KanbanSizeType,
    KanbanVerticalAlign,
} from '../../models/kanban.types';
import { DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID } from '../AdminTools/Methodology/components/Presets/util/KanbanCardEditor.utils';
import { storageValueToString } from '../ObjectPropertiesDialog/components/utils';
import { AttributeValueType } from '../FloatingAttributes/components/AttributesEditor/Attribute.types';
import { AttributeValue } from '../../serverapi/api';
import { openAttributeLinkAction } from '../../actions/openAttributeLink.actions';
import { useDispatch } from 'react-redux';
import { createOverlayFormat } from '../../services/bll/CheckAttributeRulesBllService';

type TKanbanCardProps = {
    currentLocale: Locale;
    cardType: KanbanCardType;
    presetImages: PresetImage[];
    id: string;
    nodeId: NodeId;
    columnId: string;
    columnItem: TKanbanSymbolCardItem;
    attributeTypes: AttributeType[];
    principals: PrincipalDescriptor[];
};

export const KanbanCard: FC<TKanbanCardProps> = ({
    cardType,
    presetImages,
    id,
    columnItem,
    columnId,
    currentLocale,
    attributeTypes,
    principals,
    nodeId,
}) => {
    const dispatch = useDispatch();
    const getGridTemplateColumns = () => {
        return cardType.gridSettings.columns.reduce((accum, current) => {
            if (current.type === KanbanSizeType.ABSOLUTE) {
                accum = `${accum} ${current.value}px`;
            } else {
                accum = `${accum} ${current.value}fr`;
            }
            return accum;
        }, '');
    };
    const getGridTemplateRows = () => {
        return cardType.gridSettings.rows.reduce((accum, current) => {
            if (current.type === KanbanSizeType.ABSOLUTE) {
                accum = `${accum} ${current.value}px`;
            } else {
                accum = `auto`;
            }
            return accum;
        }, '');
    };
    const onOpenLink = (value: AttributeValue) => dispatch(openAttributeLinkAction(value, nodeId));

    const renderSectorAttibute = (
        { settings, id, fromNewLine, textValue, attributeTypeId }: KanbanCardSectorAttribute,
        index: number,
    ): JSX.Element | null => {
        const attrStyle = {
            color: settings.style.color,
            fontSize: `${settings.style.fontSize}px`,
            fontStyle: settings.style.italic ? 'italic' : 'normal',
            textDecoration: settings.style.underline ? 'underline' : 'none',
            fontWeight: settings.style.bold ? 700 : 400,
        };

        if (attributeTypeId === DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID) {
            if (settings.format === KanbanCardSectorAttributeSettingsFormat.IMAGE) {
                const icon = presetImages.find((image) => image.id === settings.imageId);
                const imgWidth = `${settings.imageSize}px`;

                return (
                    <React.Fragment key={id}>
                        {fromNewLine && index ? <br /> : null}
                        <img src={icon?.graphical} style={{ width: imgWidth }} />
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment key={id}>
                    {fromNewLine && index ? <br /> : null}
                    <span style={attrStyle} className={theme.attribute}>{`${textValue}`}</span>
                </React.Fragment>
            );
        }

        const attributeValue = columnItem.attributes?.find((attr) => attr.typeId === attributeTypeId);

        if (attributeValue === undefined) return null;

        const isRulesFollowed = createOverlayFormat(
            { rules: settings.rules } as AttributeTypeStyle,
            attributeValue,
            attributeTypes,
            currentLocale,
        )?.isVisible();

        if (!isRulesFollowed) return null;

        if (settings.format === KanbanCardSectorAttributeSettingsFormat.IMAGE) {
            const icon = presetImages.find((image) => image.id === settings.imageId);
            const imgWidth = `${settings.imageSize}px`;

            return (
                <React.Fragment key={id}>
                    {fromNewLine && index ? <br /> : null}
                    <img src={icon?.graphical} style={{ width: imgWidth }} />
                </React.Fragment>
            );
        }

        if (textValue !== undefined) {
            return (
                <React.Fragment key={id}>
                    {fromNewLine && index ? <br /> : null}
                    <span style={attrStyle} className={theme.attribute}>
                        {`${textValue}`}{' '}
                    </span>
                </React.Fragment>
            );
        }

        let result: JSX.Element;

        switch (attributeValue?.valueType) {
            case AttributeValueType.NODE:
                result = (
                    <React.Fragment key={id}>
                        {index ? <br /> : null}
                        <span style={attrStyle} className={theme.attribute} onClick={() => onOpenLink(attributeValue)}>
                            {storageValueToString(attributeValue, currentLocale, {})}{' '}
                        </span>
                    </React.Fragment>
                );
                break;
            case AttributeValueType.URL:
                result = (
                    <React.Fragment key={id}>
                        {index ? <br /> : null}
                        <span style={attrStyle} className={theme.attribute} onClick={() => onOpenLink(attributeValue)}>
                            {storageValueToString(attributeValue, currentLocale, {})}{' '}
                        </span>
                    </React.Fragment>
                );
                break;
            default:
                result = (
                    <React.Fragment key={id}>
                        {fromNewLine && index ? <br /> : null}
                        <span style={attrStyle} className={theme.attribute}>
                            {storageValueToString(attributeValue, currentLocale, {
                                system: false,
                                attributeTypes,
                            })}{' '}
                        </span>
                    </React.Fragment>
                );
                break;
        }

        return result;
    };

    const getOverflowXValue = (sector: KanbanCardSectorSettings): string => {
        const column = cardType.gridSettings.columns.find((col) => col.name === sector.name[0]);

        return column?.type === KanbanSizeType.ABSOLUTE ? 'clip' : '';
    };

    const getOverflowYValue = (sector: KanbanCardSectorSettings): string => {
        const row = cardType.gridSettings.rows.find((row) => row.name === sector.name[1]);

        return row?.type === KanbanSizeType.ABSOLUTE ? 'clip' : '';
    };

    return (
        <div
            draggable="true"
            onDragStart={(e) => {
                e.dataTransfer.setData('kanbanItemId', id);
                columnItem.allowedColumnIds.forEach((colId) => {
                    if (columnId === colId) return;
                    document.getElementById(colId)?.classList.add(theme.active);
                });
                columnItem.forbiddenColumnIds.forEach((colId) => {
                    if (columnId === colId) return;
                    document.getElementById(colId)?.classList.add(theme.disabled);
                });
            }}
            onDragEnd={(e) => {
                columnItem.allowedColumnIds.forEach((colId) => {
                    document.getElementById(colId)?.classList.remove(theme.active);
                });
                columnItem.forbiddenColumnIds.forEach((colId) => {
                    document.getElementById(colId)?.classList.remove(theme.disabled);
                });
            }}
            className={theme.card}
            style={{
                display: 'grid',
                gridTemplateColumns: getGridTemplateColumns(),
                gridTemplateRows: getGridTemplateRows(),
            }}
        >
            {
                <>
                    {cardType.sectorSettings.map((sector) => {
                        const styles = sector.styles;

                        const sectorStyle: Record<string, string | number> = {
                            gridColumnStart: sector.position.start.column,
                            gridColumnEnd: sector.position.end.column + 1,
                            gridRowStart: sector.position.start.row,
                            gridRowEnd: sector.position.end.row + 1,
                            backgroundColor: styles.backgroundColor,
                            padding: `${styles.padding.top}px ${styles.padding.right}px ${styles.padding.bottom}px ${styles.padding.left}px`,
                            borderTop: styles.border?.top
                                ? `${styles.border.width}px solid ${styles.border.color}`
                                : 'none',
                            borderRight: styles.border?.right
                                ? `${styles.border.width}px solid ${styles.border.color}`
                                : 'none',
                            borderBottom: styles.border?.bottom
                                ? `${styles.border.width}px solid ${styles.border.color}`
                                : 'none',
                            borderLeft: styles.border?.left
                                ? `${styles.border.width}px solid ${styles.border.color}`
                                : 'none',
                            height: '-webkit-fill-available',
                            display: 'flex',
                            justifyContent:
                                styles.horizontalAlign === KanbanHorizontalAlign.LEFT
                                    ? 'flex-start'
                                    : styles.horizontalAlign === KanbanHorizontalAlign.RIGHT
                                    ? 'flex-end'
                                    : 'center',
                            alignItems:
                                styles.verticalAlign === KanbanVerticalAlign.TOP
                                    ? 'flex-start'
                                    : styles.verticalAlign === KanbanVerticalAlign.BOTTOM
                                    ? 'flex-end'
                                    : 'center',
                            overflowX: getOverflowXValue(sector),
                            overflowY: getOverflowYValue(sector),
                        };

                        const sectorAttributes = cardType.sectorAttributes.filter(
                            (attr) => attr.sectorName === sector.name,
                        );

                        return (
                            <div style={sectorStyle} key={sector.name}>
                                <div>{sectorAttributes.map(renderSectorAttibute)}</div>
                            </div>
                        );
                    })}
                </>
            }
        </div>
    );
};
