import { useIntl } from 'react-intl';
import messages from '../UserAccountTab/userAccount.messages';
import { Tabs } from 'antd';
import React from 'react';
import theme from '../AdminTools/style/AdminTools.scss';
import { UserAccountData } from './UserAccountData.component';
import { UserAccountSecurity } from './UserAccountSecurity.component';

export const UserAccount = () => {
    const intl = useIntl();

    const tabs = [
        {
            label: intl.formatMessage(messages.mainTab),
            key: 'userAccountData',
            children: <UserAccountData />,
        },
        {
            label: intl.formatMessage(messages.securityTab),
            key: 'userAccountSecurity',
            children: <UserAccountSecurity />,
        },
    ];

    return (
        <div className={theme.container}>
            <div className={theme.containerInner}>
                <Tabs defaultActiveKey="1" tabPosition="left" items={tabs} />
            </div>
        </div>
    );
};
