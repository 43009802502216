import {NodeId} from "../serverapi/api";
import {TCloneModelAction} from "./cloneModel.actions.types";
import {CLONE_MODEL} from "../actionsTypes/cloneModel.actionTypes";
import {ModelCloneStrategy} from "../modules/CloneModelDialog/components/CloneModelDialog.component";

export const cloneModel = (strategy: ModelCloneStrategy, modelNodeId: NodeId, parentNodeIdOfModelCopy: NodeId): TCloneModelAction => ({
    type: CLONE_MODEL,
    payload: {
        modelNodeId,
        strategy,
        newParentNodeIdForModelCopy: parentNodeIdOfModelCopy
    }
});
