import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import messages from './Inputs.messages';
import theme from './ErrorWrapper.scss';

type TErrorWrapper = {
    children: ReactNode;
    error: boolean;
};

export const ErrorWrapper: FC<TErrorWrapper> = ({ children, error }) => {
    const intl = useIntl();

    return (
        <>
            <div className={error ? theme.border : ''}>{children}</div>
            {error && <div className={theme.message}>{intl.formatMessage(messages.errorMessage)}</div>}
        </>
    );
};
