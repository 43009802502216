import {
    GET_ACLS_BY_TYPE_SUCCESS,
    SAVE_ACLS_BY_TYPE,
    SAVE_ALL_ACLS,
    SET_ACL_RIGHTS,
    SET_ALL_ACL_RIGHTS,
} from '../actionsTypes/acl.actionTypes';
import {
    TGetAclsByTypeSuccessAction,
    TGetAclsByTypeSuccessPayload,
    TSetAclRightsPayload,
    TSetAclRightsAction,
    TSaveAclsPayload,
    TSaveAclsAction,
    TSetAclAllRightsPayload,
    TSetAllAclRightsAction,
    TSaveAllAclsPayload,
    TSaveAllAclsAction,
} from './acl.actions.types';

export const getAclsByTypeSuccess = (payload: TGetAclsByTypeSuccessPayload): TGetAclsByTypeSuccessAction => ({
    type: GET_ACLS_BY_TYPE_SUCCESS,
    payload,
});

export const setAclRights = (payload: TSetAclRightsPayload): TSetAclRightsAction => ({
    type: SET_ACL_RIGHTS,
    payload,
});

export const setAllAclRights = (payload: TSetAclAllRightsPayload): TSetAllAclRightsAction => ({
    type: SET_ALL_ACL_RIGHTS,
    payload,
});

export const saveAclsByType = (payload: TSaveAclsPayload): TSaveAclsAction => ({
    type: SAVE_ACLS_BY_TYPE,
    payload,
});

export const saveAllAcls = (payload: TSaveAllAclsPayload): TSaveAllAclsAction => ({
    type: SAVE_ALL_ACLS,
    payload,
});
