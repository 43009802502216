export const FETCH_USERS_BY_TREENODE = 'FETCH_USERS_BY_TREENODE';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const FETCH_USER_BYID = 'FETCH_USER_BYID';

export const FETCH_USER_BYID_SUCCESS = 'FETCH_USER_BYID_SUCCESS';

export const FETCH_USERS_BY_GROUPID_REQUEST = 'FETCH_USERS_BY_GROUPID_REQUEST';

export const FETCH_USERS_BY_GROUPID_SUCCESS = 'FETCH_USERS_BY_GROUPID_SUCCESS';

export const EDIT_USER_DATA_BEGIN = 'EDIT_USER_DATA_BEGIN';

export const EDIT_USER_DATA_END = 'EDIT_USER_DATA_END';

export const SET_USERS_BLOCK = 'SET_USERS_BLOCK';

export const SUBMIT_USER_DATA = 'SUBMIT_USER_DATA';

export const OPEN_USERS_MANAGMENT_TAB = 'OPEN_USERS_MANAGMENT_TAB';

export const OPEN_USERDATA_EDITING_TAB_TEST = 'OPEN_USERDATA_EDITING_TAB_TEST';

export const OPEN_EDIT_USER_PERMISSIONS_DIALOG = 'OPEN_EDIT_USER_PERMISSIONS_DIALOG';

export const OPEN_USERDATA_EDITING_TAB = 'OPEN_USERDATA_EDITING_TAB';

export const SAVE_USERS_LICENSES_CHANGES = 'SAVE_USERS_LICENSES_CHANGES';

export const SAVE_USERS_GROUPS_CHANGES = 'SAVE_USERS_GROUPS_CHANGES';

export const SAVE_USERS_REPOS_CHANGES = 'SAVE_USERS_REPOS_CHANGES';

export const SAVE_USERS_ACCESSES_CHANGES = 'SAVE_USERS_ACCESSES_CHANGES';

export const SAVE_USER_MAINDATA_CHANGES = 'SAVE_USER_MAINDATA_CHANGES';

export const DELETE_USERS = 'DELETE_USERS';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const FETCH_USER_SESSIONS = 'FETCH_USER_SESSIONS';

export const FETCH_USER_SESSIONS_SUCCESS = 'FETCH_USER_SESSIONS_SUCCESS';

export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
