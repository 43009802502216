import type { TFloatingAttributesDialogContentRef } from './FloatingAttributesPanel.types';
import type { TFloatingAttributesDialogProps, TFloatingAttributesPanelProps } from '../FloatingAttributes.types';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Col, Row } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import messages from './FloatingAttributes.messages';
import { OverlayAreaSelector } from './AreaSelector/AreaSelector.component';
import theme from './FloatingAttributes.scss';
import { AttributesEditor } from './AttributesEditor/AttributesEditor.component';
import { FloatingAttributesPanelSelectors } from '../selectors/FloatingAttributes.selectors';
import {
    floatingAttributesChangeAreaAttributeWrap,
    floatingAttributesClearStateAction,
    floatingAttributesSetStateAction,
    floatingAttributesSwitchPresetStylesAction,
} from '../actions/FloatingAttributes.actions';
import { useRulesValidation } from '../../../hooks/useAttributeRules';
import { validateStyles, getUpdatedAttributeTypes } from '../FloatingAttributes.utils';
import { getStylesForSelectedArea } from '../../../utils/attribute';

type TFloatingAttributesPanel = React.ForwardRefExoticComponent<
    Omit<TFloatingAttributesDialogProps, 'ref'> & React.RefAttributes<TFloatingAttributesDialogContentRef>
>;

export const FloatingAttributesPanel: TFloatingAttributesPanel = forwardRef(
    (
        {
            disabledAreas,
            styledAttributeTypes,
            readOnlyAttributeTypes,
            elementName,
            presetId,
            ignorePresetStyles,
            disabled,
            useUniqueStyledAttributeTypes,
            onToggleIgnorePresetStyles,
            setRulesValidity,
            onChange,
        }: TFloatingAttributesPanelProps,
        ref,
    ) => {
        const intl = useIntl();
        const dispatch = useDispatch();
        const {
            areas,
            styles,
            stylesAttribute,
            replacementTextStatusMap,
            rulesHasNotValueStatusMap,
            selectedAreaKey,
            selectedAreaStyles,
            inited,
        } = useSelector(FloatingAttributesPanelSelectors.allData);
        const rulesValidation = useRulesValidation(styles);

        useEffect(() => {
            return () => {
                dispatch(floatingAttributesClearStateAction());
            };
        }, []);

        const attributeWrapChangeDisabled = useMemo(() => {
            const presetStylesForArea = getStylesForSelectedArea(selectedAreaKey, readOnlyAttributeTypes);
            return !!presetStylesForArea.length && !ignorePresetStyles;
        }, [selectedAreaStyles, styles, readOnlyAttributeTypes, selectedAreaKey]);

        const setAttributesWrap = (attributeWrap: boolean) => {
            dispatch(floatingAttributesChangeAreaAttributeWrap({ attributeWrap }));
        };

        const title = elementName
            ? `${intl.formatMessage(messages.subTitleFor)} "${elementName}"`
            : intl.formatMessage(messages.mainTitle);

        const handleSubmit = () => {
            if (onChange)
                onChange(
                    getUpdatedAttributeTypes(
                        styledAttributeTypes,
                        !!useUniqueStyledAttributeTypes,
                        styles,
                        stylesAttribute,
                        replacementTextStatusMap,
                        rulesHasNotValueStatusMap,
                        false,
                    ),
                );
        };

        useEffect(() => {
            dispatch(
                floatingAttributesSetStateAction({
                    styledAttributeTypes,
                    readOnlyAttributeTypes,
                    disabledAreas,
                    ignorePresetStyles,
                    useUniqueStyledAttributeTypes,
                    presetId,
                }),
            );
        }, [styledAttributeTypes, readOnlyAttributeTypes, disabledAreas]);

        useEffect(() => {
            dispatch(
                floatingAttributesSwitchPresetStylesAction({
                    styledAttributeTypes,
                    readOnlyAttributeTypes,
                    disabledAreas,
                    ignorePresetStyles,
                    useUniqueStyledAttributeTypes,
                }),
            );
        }, [ignorePresetStyles]);

        useEffect(() => {
            setRulesValidity(
                validateStyles(styles, replacementTextStatusMap, rulesHasNotValueStatusMap) && rulesValidation,
            );
        }, [styles, replacementTextStatusMap, rulesValidation]);

        useImperativeHandle(ref, () => ({
            submit: handleSubmit,
        }));

        if (!inited) {
            return null;
        }

        return (
            <div className={theme.col}>
                <Col>
                    <Row>
                        <h2 className={theme.title}>{title}</h2>
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                        <OverlayAreaSelector />
                    </Row>
                    <Row>
                        {onToggleIgnorePresetStyles && (
                            <Checkbox
                                checked={ignorePresetStyles}
                                disabled={disabled}
                                onChange={(e) => {
                                    onToggleIgnorePresetStyles(e.target.checked);
                                }}
                            >
                                {intl.formatMessage(messages.disablePreset)}
                            </Checkbox>
                        )}
                        {!!areas[selectedAreaKey].attributes.length && (
                            <Checkbox
                                checked={areas[selectedAreaKey].attributeWrap}
                                disabled={disabled || attributeWrapChangeDisabled}
                                onChange={() => setAttributesWrap(!areas[selectedAreaKey].attributeWrap)}
                            >
                                {intl.formatMessage(messages.displayAttributesInColumn)}
                            </Checkbox>
                        )}
                    </Row>
                    <div className={theme.divider} />
                    <Row>
                        <AttributesEditor disabled={disabled} />
                    </Row>
                </Col>
            </div>
        );
    },
);
