import { SequenceSymbolTypeId, SequenceSymbolShapes } from '../ComplexSymbol.constants';
import { LifelineSymbolMainClass } from './LifelineSymbolMain.class';
import { getSequenceLifeLineTemplate } from './lifeLineTemplate';

export class LifelineControlSymbol extends LifelineSymbolMainClass {
    complexSymbolTypeId = SequenceSymbolTypeId.LIFE_LINE_CONTROL;
    headerSize = 60;
    template = getSequenceLifeLineTemplate(
        SequenceSymbolTypeId.LIFE_LINE_CONTROL,
        SequenceSymbolShapes.LIFE_LINE_CONTROL,
    );
}
