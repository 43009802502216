import { defineMessages } from 'react-intl';

export default defineMessages({
    combine: {
        id: 'DuplicatesTable.combine',
        defaultMessage: 'Объединить',
    },
    main: {
        id: 'DuplicatesTable.main',
        defaultMessage: 'Основной',
    },
    destination: {
        id: 'DuplicatesTable.destination',
        defaultMessage: 'Расположение',
    },
    instanceCount: {
        id: 'DuplicatesTable.instanceCount',
        defaultMessage: 'Количество экземпляров',
    },
    createdAt: {
        id: 'DuplicatesTable.createdAt',
        defaultMessage: 'Дата создания',
    },
    createdBy: {
        id: 'DuplicatesTable.createdBy',
        defaultMessage: 'Кем создано',
    },
    updatedAt: {
        id: 'DuplicatesTable.updatedAt',
        defaultMessage: 'Дата изменения',
    },
    updatedBy: {
        id: 'DuplicatesTable.updatedBy',
        defaultMessage: 'Кем изменено',
    },
    blocked: {
        id: 'DuplicatesTable.blocked',
        defaultMessage: 'Заблокировано',
    },
    status: {
        id: 'DuplicatesTable.status',
        defaultMessage: 'Статус операции',
    },
    combineConfirmation: {
        id: 'DuplicatesTable.combineConfirmation',
        defaultMessage: 'Подтверждение объединения',
    },
    combineConfirmationQuestion: {
        id: 'DuplicatesTable.combineConfirmationQuestion',
        defaultMessage: 'Вы действительно хотите объединить выделенные объекты',
    },
    combined: {
        id: 'DuplicatesTable.combined',
        defaultMessage: 'Объединено',
    },
    deleted: {
        id: 'DuplicatesTable.deleted',
        defaultMessage: 'Удалено',
    },
    skipped: {
        id: 'DuplicatesTable.skipped',
        defaultMessage: 'Пропущен',
    },

    blockedByModel: {
        id: 'DuplicatesTable.blockedByModel',
        defaultMessage: 'Заблокировано моделью',
    },
    notFound: {
        id: 'DuplicatesTable.notFound',
        defaultMessage: 'Не найдено',
    },
    noAccess: {
        id: 'DuplicatesTable.noAccess',
        defaultMessage: 'Нет доступа',
    },
    typesDifference: {
        id: 'DuplicatesTable.typesDifference',
        defaultMessage: 'Различие типов',
    },
    minTwoTip: {
        id: 'DuplicatesTable.minTwoTip',
        defaultMessage: 'Необходимо выбрать минимум два элемента для объединения',
    },
    mainTip: {
        id: 'DuplicatesTable.mainTip',
        defaultMessage: 'Необходимо выбрать основной элемент',
    },
    name: {
        id: 'DuplicatesTable.name',
        defaultMessage: 'Имя объекта: ',
    },
    type: {
        id: 'DuplicatesTable.type',
        defaultMessage: 'Тип Объекта: ',
    },
    search: {
        id: 'DuplicatesTable.search',
        defaultMessage: 'Поиск',
    },
    findOnlyChildDuplicates: {
        id: 'DuplicatesTable.findOnlyChildDuplicates',
        defaultMessage: 'Искать только в дочерних элементах',
    },
    path: {
        id: 'DuplicatesTable.path',
        defaultMessage: 'Расположение элементов: ',
    },
    cancel: {
        id: 'DuplicatesTable.cancel',
        defaultMessage: 'Отмена',
    },
    yes: {
        id: 'DuplicatesTable.yes',
        defaultMessage: 'Да',
    },
    no: {
        id: 'DuplicatesTable.no',
        defaultMessage: 'Нет',
    },
    strategyLabel: {
        id: 'DuplicatesTable.strategyLabel',
        defaultMessage: 'Выбор основного элемента',
    },
    AUTOMATIC_INSTANCE_COUNT: {
        id: 'DuplicatesTable.AUTOMATIC_INSTANCE_COUNT',
        defaultMessage: 'Автоматически (Больше экземпляров)',
    },
    OLDEST_DEFINITION: {
        id: 'DuplicatesTable.OLDEST_DEFINITION',
        defaultMessage: 'Наиболее старые определения',
    },
    SKIP: {
        id: 'DuplicatesTable.SKIP',
        defaultMessage: 'Вручную',
    },

    mergeAttributes: {
        id: 'DuplicatesTable.mergeAttributes',
        defaultMessage: 'Объединять атрибуты',
    },
    mergeDecomposition: {
        id: 'DuplicatesTable.mergeDecomposition',
        defaultMessage: 'Объединять декомпозиции',
    },
    deleteSecondary: {
        id: 'DuplicatesTable.deleteSecondary',
        defaultMessage: 'Удалять вторичные объекты',
    },
});
