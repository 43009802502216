import { NumberSize, ResizableProps } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import { useState } from 'react';
import { NodeViewProps } from '@tiptap/core';
import { WIKI_IMAGE_DEFAULT_HEIGHT, WIKI_IMAGE_DEFAULT_WIDTH } from '../../Editor.constants';

type TDefaultSizeState = { width: number; height: number };

type TUseResizeHandlersArguments = {
    width: number | string;
    height: number | string;
    updateAttributes: NodeViewProps['updateAttributes'];
};

type TUseResizeHandlersReturnValue = {
    onResizeStart: ResizableProps['onResizeStart'];
    onResizeStop: ResizableProps['onResizeStop'];
    lockAspectRatio: ResizableProps['lockAspectRatio'];
};

export const useResizeHandlers = ({
    width,
    height,
    updateAttributes,
}: TUseResizeHandlersArguments): TUseResizeHandlersReturnValue => {
    const [defaultSize, setDefaultSize] = useState<TDefaultSizeState | undefined>();

    const [lockAspectRatio, setLockAspectRatio] = useState<boolean>(false);

    const onResizeStart: ResizableProps['onResizeStart'] = (
        e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        direction: Direction,
        elementRef: HTMLElement,
    ): void => {
        if (
            direction === 'bottomLeft' ||
            direction === 'bottomRight' ||
            direction === 'topLeft' ||
            direction === 'topRight'
        ) {
            setLockAspectRatio(true);
        } else {
            setLockAspectRatio(false);
        }

        const img = elementRef.firstElementChild as HTMLImageElement;

        setDefaultSize({
            width: img.clientWidth,
            height: img.clientHeight,
        });

        if (width === WIKI_IMAGE_DEFAULT_WIDTH && height === WIKI_IMAGE_DEFAULT_HEIGHT) {
            updateAttributes({
                width: img.clientWidth,
                height: img.clientHeight,
            });
        }
    };

    const onResizeStop: ResizableProps['onResizeStop'] = (
        event: MouseEvent | TouchEvent,
        direction: Direction,
        elementRef: HTMLElement,
        delta: NumberSize,
    ): void => {
        if (defaultSize === undefined) return;

        updateAttributes({
            width: defaultSize.width + delta.width,
            height: defaultSize.height + delta.height,
        });

        setDefaultSize(undefined);
    };

    return { onResizeStart, onResizeStop, lockAspectRatio };
};
