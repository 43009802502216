import { GroupDTO } from '../serverapi/api';
import {
    ADD_USERS_TO_GROUP_REQUEST,
    DELETE_GROUP,
    DELETE_GROUP_SUCCESS,
    EDIT_GROUP_DATA_BEGIN,
    EDIT_GROUP_DATA_END,
    FETCHED_ALL_GROUPS_SUCCESS,
    FETCHED_GROUP_BYID_SUCCESS,
    FETCH_ALL_GROUPS_REQUEST,
    FETCH_GROUP_BYID_REQUEST,
    OPEN_EDITING_GROUPDATA_TAB,
    REMOVE_USERS_FROM_GROUP,
    SAVE_GROUP_MAINDATA_CHANGES,
    SUBMIT_GROUP_DATA,
} from '../actionsTypes/groups.actionTypes';
import {
    TAddUsersToGroupRequestAction,
    TAddUsersToGroupRequestPayload,
    TDeleteGroupAction,
    TDeleteGroupSuccessAction,
    TDeleteGroupSuccessPayload,
    TEditGroupDataBeginAction,
    TEditGroupDataEndAction,
    TFetchAllGroupsRequestAction,
    TFetchAllGroupsSuccessAction,
    TFetchAllGroupsSuccessPayload,
    TFetchGroupByIdAction,
    TFetchGroupByIdSuccessAction,
    TFetchGroupByIdSuccessPayload,
    TOpenEdititngGroupDataTabAction,
    TOpenEdititngGroupDataTabPayload,
    TRemoveUsersFromGroupAction,
    TRemoveUsersFromGroupPayload,
    TSaveGroupMainDataChangesAction,
    TSubmitGroupDataAction,
    TSubmitGroupDataActionPayload,
} from './groups.actions.types';

export const fetchAllGroupsRequest: (serverId: string) => TFetchAllGroupsRequestAction = (serverId) => ({
    type: FETCH_ALL_GROUPS_REQUEST,
    payload: {
        serverId,
    },
});

export const fetchAllGroupsSuccess: (payload: TFetchAllGroupsSuccessPayload) => TFetchAllGroupsSuccessAction = (
    payload,
) => ({
    type: FETCHED_ALL_GROUPS_SUCCESS,
    payload,
});

export const openEdititngGroupDataTab: (payload: TOpenEdititngGroupDataTabPayload) => TOpenEdititngGroupDataTabAction =
    (payload) => ({
        type: OPEN_EDITING_GROUPDATA_TAB,
        payload,
    });

export const fetchGroupById: (groupId: number) => TFetchGroupByIdAction = (groupId) => ({
    type: FETCH_GROUP_BYID_REQUEST,
    payload: {
        groupId,
    },
});

export const fetchGroupByIdSuccess: (payload: TFetchGroupByIdSuccessPayload) => TFetchGroupByIdSuccessAction = (
    payload,
) => ({
    type: FETCHED_GROUP_BYID_SUCCESS,
    payload,
});

export const editGroupDataBegin: (groupData: GroupDTO) => TEditGroupDataBeginAction = (groupData) => ({
    type: EDIT_GROUP_DATA_BEGIN,
    payload: {
        groupData,
    },
});

export const editGroupDataEnd: (groupId: number) => TEditGroupDataEndAction = (groupId) => ({
    type: EDIT_GROUP_DATA_END,
    payload: {
        groupId,
    },
});

export const deleteGroup: (groupId: number) => TDeleteGroupAction = (groupId) => ({
    type: DELETE_GROUP,
    payload: { groupId },
});

export const deleteGroupSuccess: (payload: TDeleteGroupSuccessPayload) => TDeleteGroupSuccessAction = (payload) => ({
    type: DELETE_GROUP_SUCCESS,
    payload,
});

export const submitingGroupData: (payload: TSubmitGroupDataActionPayload) => TSubmitGroupDataAction = (payload) => ({
    type: SUBMIT_GROUP_DATA,
    payload,
});

export const saveGroupMainDataChanges: (groupData: GroupDTO) => TSaveGroupMainDataChangesAction = (groupData) => ({
    type: SAVE_GROUP_MAINDATA_CHANGES,
    payload: { groupData },
});

export const addUsersToGroupRequest: (payload: TAddUsersToGroupRequestPayload) => TAddUsersToGroupRequestAction = (
    payload,
) => ({
    type: ADD_USERS_TO_GROUP_REQUEST,
    payload,
});

export const removeUsersFromGroup: (payload: TRemoveUsersFromGroupPayload) => TRemoveUsersFromGroupAction = (
    payload,
) => ({
    type: REMOVE_USERS_FROM_GROUP,
    payload,
});
