import { defineMessages } from 'react-intl';

export default defineMessages({
    levelUp: {
        id: 'MatrixContextMenu.levelUp',
        defaultMessage: 'На уровень выше',
    },
    levelDown: {
        id: 'MatrixContextMenu.levelDown',
        defaultMessage: 'На уровень ниже',
    },
    symbolsSettings: {
        id: 'MatrixContextMenu.symbolsSettings',
        defaultMessage: 'Настройка символов',
    },
    createDefinition: {
        id: 'MatrixContextMenu.createDefinition',
        defaultMessage: 'Создать определение',
    },
    copy: {
        id: 'MatrixContextMenu.copy',
        defaultMessage: 'Копировать',
    },
    past: {
        id: 'MatrixContextMenu.past',
        defaultMessage: 'Вставить',
    },
    edgesList: {
        id: 'MatrixContextMenu.edgesList',
        defaultMessage: 'Список связей',
    },
});
