import { TReducer } from '../utils/types';
import { SET_SCRIPTS_NODES } from '../actionsTypes/fetchScripts.actionTypes';
import { TFetchedScriptsState } from './fetchedScripts.reducer.types';

const initialState: TFetchedScriptsState = {
    scripts: [],
};

export const fetchedScriptsReducer: TReducer<TFetchedScriptsState> = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCRIPTS_NODES: {
            return { ...state, scripts: action.payload.scriptNodes };
        }
        default:
            return state;
    }
};