import {
    IMAGE_TO_CLIPBOARD,
    OPEN_IMAGE_DOWNLOAD_SETTINGS,
    PRINT_MODEL,
    SAVE_GRAY_SCALE,
    SAVE_HIDE_DECOMPOSITION,
    SAVE_IMAGE,
    SAVE_IMAGE_SVG,
    SAVE_PDF,
} from '../actionsTypes/image.actionTypes';
import { NodeId } from '../serverapi/api';
import {
    TImageExtension,
    TImageToClipboardAction,
    TOpenImageDownloadSettingsAction,
    TPrintModelAction,
    TSaveGrayScaleToLocalStorageAction,
    TSaveHideDecompositionToLocalStorageAction,
    TSaveImageAction,
    TSaveImageSvgAction,
    TSavePdfAction,
} from './image.actions.types';

export const saveImage = (extension: TImageExtension, nodeId: NodeId): TSaveImageAction => ({
    type: SAVE_IMAGE,
    payload: {
        extension,
        nodeId,
    },
});

export const savePdf = (nodeId: NodeId): TSavePdfAction => ({
    type: SAVE_PDF,
    payload: {
        nodeId,
    },
});

export const printModel = (nodeId: NodeId): TPrintModelAction => ({
    type: PRINT_MODEL,
    payload: {
        nodeId,
    },
});

export const imageToClipboard = (nodeId: NodeId): TImageToClipboardAction => ({
    type: IMAGE_TO_CLIPBOARD,
    payload: {
        nodeId,
    },
});

export const openImageDownloadSettings = (): TOpenImageDownloadSettingsAction => ({
    type: OPEN_IMAGE_DOWNLOAD_SETTINGS,
});

export const saveImageSvg = (nodeId: NodeId): TSaveImageSvgAction => ({
    type: SAVE_IMAGE_SVG,
    payload: {
        nodeId,
    },
});

export const saveGrayScaleToLocalStorage = (checked: boolean): TSaveGrayScaleToLocalStorageAction => ({
    type: SAVE_GRAY_SCALE,
    payload: {
        checked,
    },
});

export const saveHideDecompositionToLocalStorage = (checked: boolean): TSaveHideDecompositionToLocalStorageAction => ({
    type: SAVE_HIDE_DECOMPOSITION,
    payload: {
        checked,
    },
});
