/* eslint-disable react/no-array-index-key */
import React from 'react';
import { CommentElement } from '../CommentElement.component';
import theme from './CommentPopover.scss';
import { sortCommentsByCreatedDate } from '../../utils/commentsUtils';
import { Comment } from '@/serverapi/api';

const CommentPopover = ({
    scheme,
    threadId,
    deleteComment,
    getSortedActualComments,
    getCommentsByParentId,
    pinComment,
    unpinComment,
}) => {
    const filteredComments: Comment[] = getSortedActualComments(scheme.nodeId).filter(
        (comment) => comment.threadId === threadId,
    );
    const serverId = scheme.nodeId ? scheme.nodeId.serverId : '';
    const getAnswers = (comment, atFirstOld = true) => {
        const answers = getCommentsByParentId(
            {
                id: comment.nodeId,
                repositoryId: comment.commentId.repositoryId,
                serverId,
            },
            comment.commentId.id,
        );

        return atFirstOld ? sortCommentsByCreatedDate(answers) : sortCommentsByCreatedDate(answers).reverse();
    };

    return (
        <div
            className={theme.container}
            onContextMenu={(event) => {
                event.stopPropagation();

                return false;
            }}
        >
            {filteredComments.map((comment, index) => (
                <div key={`popover-comment_${comment.commentId.id}_${index}`}>
                    {comment && (
                        <CommentElement
                            deleteComment={deleteComment}
                            comment={comment.threadId ? comment : { ...comment, threadId }}
                            scheme={scheme}
                            answers={getAnswers(comment)}
                            isMarkersEnabled={false}
                            pinComment={pinComment}
                            unpinComment={unpinComment}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default CommentPopover;
