import {
    OPEN_SCRIPT_SCHEDULER_DATA_CLEAR,
    OPEN_SCRIPT_SCHEDULER_DATA_SET,
} from '@/actionsTypes/openScriptSchedulerData.actionTypes';
import { TOpenScriptSchedulerDataReducerState } from './openScriptSchedulerData.reducer.types';

const initialState: TOpenScriptSchedulerDataReducerState = {
    script: undefined,
    existingParams: undefined,
    parsedParams: undefined,
};

export const openScriptSchedulerDataReducer = (state: TOpenScriptSchedulerDataReducerState = initialState, action) => {
    switch (action.type) {
        case OPEN_SCRIPT_SCHEDULER_DATA_SET: {
            return action.payload;
        }
        case OPEN_SCRIPT_SCHEDULER_DATA_CLEAR: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
