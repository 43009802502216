import React from 'react';
import { DefaultDraftBlockRenderMap } from 'draft-js';
import { Map } from 'immutable';
import { CHECKABLE_LIST_ITEM, UNORDERED_LIST_ITEM } from '../../common/constants';

const WRAPPER = <ul className="public-DraftStyleDefault-ul" />;

const blockRenderMap = Map({
    [CHECKABLE_LIST_ITEM]: {
        element: 'li',
        wrapper: WRAPPER,
    },
});

const blockRenderMapForSameWrapperAsUnorderedListItem = blockRenderMap.merge(
    Map({
        [UNORDERED_LIST_ITEM]: {
            element: 'li',
            wrapper: WRAPPER,
        },
    }),
);

export default blockRenderMap;

export { WRAPPER, blockRenderMapForSameWrapperAsUnorderedListItem };

export const getBlockRenderMap = () => {
    return DefaultDraftBlockRenderMap.merge(blockRenderMap);
};
