import type { TEdgeManagementDialogSubmitData } from '@/modules/Models/components/EdgeManagement/EdgeManagementDialog.types';
import type {
    TViewEdgeManagementDialogAction,
    TSubmitEdgeManagementDialogAction,
} from './edgeManagement.actions.types';
import { EDGE_MANAGEMENT_DIALOG_OPEN, EDGE_MANAGEMENT_DIALOG_SUBMIT } from '../actionsTypes/edgeManagement.actionTypes';

export const openEdgeManagementDialog = (): TViewEdgeManagementDialogAction => ({
    type: EDGE_MANAGEMENT_DIALOG_OPEN,
});

export const submitEdgeManagementDialog = (
    payload: TEdgeManagementDialogSubmitData,
): TSubmitEdgeManagementDialogAction => ({
    type: EDGE_MANAGEMENT_DIALOG_SUBMIT,
    payload,
});

