import { ScriptNode } from '../../serverapi/api';
import { TReducer } from '../../utils/types';
import {
    SCRIPT_REMOVE_SUCCESS,
    SCRIPT_REQUEST_SUCCESS,
    SCRIPT_SAVE_REQUEST_SUCCESS,
    CHANGE_SCRIPT_DATA,
    SCRIPT_CONTEXT_DELETE,
    SCRIPT_CONTEXT_SAVE_SUCCESS,
} from '../../actionsTypes/entities/script.actionTypes';
import { TNodeState } from './TNodeState';
import { TScriptState } from './script.reducer.types';
import { scriptContextTypes } from '../../modules/ObjectPropertiesDialog/components/ScriptContext/scriptContext.types';

export const INITIAL_SCRIPT_STATE: TScriptState = new TNodeState();

export const scriptReducer: TReducer<TScriptState> = (state = INITIAL_SCRIPT_STATE, action) => {
    switch (action.type) {
        case CHANGE_SCRIPT_DATA: {
            const { nodeId, script } = action.payload;
            const existScript: ScriptNode | undefined = state.byNodeId.get(nodeId);
            if (existScript) {
                return state.set(nodeId, { ...existScript, ...script });
            }

            return state.set(nodeId, script);
        }

        case SCRIPT_SAVE_REQUEST_SUCCESS:
        case SCRIPT_REQUEST_SUCCESS: {
            const { script } = action.payload;

            return state.set(script.nodeId, script);
        }

        case SCRIPT_REMOVE_SUCCESS: {
            const { nodeId } = action.payload;

            return state.delete(nodeId);
        }
        case SCRIPT_CONTEXT_DELETE: {
            const { type, scriptId } = action.payload;
            if (!scriptId) {
                return state;
            }
            const script: ScriptNode | undefined = state.byNodeId.get(scriptId);
            if (!script) return state;
            if (!script.allowedScriptContext) {
                script.allowedScriptContext = {};
            }
            switch (type) {
                case scriptContextTypes.runningOnDB: {
                    script.allowedScriptContext.allowAllDBs = false;

                    return state.set(scriptId, script);
                }
                case scriptContextTypes.runningOnEdges: {
                    script.allowedScriptContext.allowedEdgeTypeIds = [];
                    script.allowedScriptContext.allowAllEdges = false;

                    return state.set(scriptId, script);
                }
                case scriptContextTypes.runningOnFiles: {
                    script.allowedScriptContext.allowAllFiles = false;

                    return state.set(scriptId, script);
                }
                case scriptContextTypes.runningOnFolders: {
                    script.allowedScriptContext.allowedFolderTypeIds = [];
                    script.allowedScriptContext.allowAllFolders = false;

                    return state.set(scriptId, script);
                }
                case scriptContextTypes.runningOnObjectInstances: {
                    script.allowedScriptContext.allowedSymbolTypeIds = [];
                    script.allowedScriptContext.allowAllSymbols = false;

                    return state.set(scriptId, script);
                }
                case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
                    script.allowedScriptContext.allowedModelSymbols = [];
                    script.allowedScriptContext.allowAllSymbols = false;

                    return state.set(scriptId, script);
                }
                case scriptContextTypes.runningOnObjects: {
                    script.allowedScriptContext.allowedObjectTypeIds = [];
                    script.allowedScriptContext.allowAllObjects = false;

                    return state.set(scriptId, script);
                }
                case scriptContextTypes.runningOnScripts: {
                    script.allowedScriptContext.allowAllScripts = false;

                    return state.set(scriptId, script);
                }
                case scriptContextTypes.runningOnModels: {
                    script.allowedScriptContext.allowedModelTypeIds = [];
                    script.allowedScriptContext.allowAllModels = false;

                    return state.set(scriptId, script);
                }
                case scriptContextTypes.runningOnSpecificElement: {
                    script.allowedScriptContext.allowedNodeIds = [];

                    return state.set(scriptId, script);
                }
                default:
                    return state;
            }
        }

        case SCRIPT_CONTEXT_SAVE_SUCCESS: {
            const { scriptContext, scriptId } = action.payload;
            const script: ScriptNode | undefined= state.byNodeId.get(scriptId);
            if (!script) return state;
            script.allowedScriptContext = scriptContext;

            return state.set(scriptId, script);
        }

        default:
            return state;
    }
};
