export enum ImportErrorType {
    NONE = 'NONE',
    WRONG_TRANSFER_DATA = 'WRONG_TRANSFER_DATA',
    ROOT_MUST_BE_DB = 'ROOT_MUST_BE_DB',
    ROOT_CANT_BE_DB = 'ROOT_CANT_BE_DB',
    ACCESS_DENIED = 'ACCESS_DENIED',
    UNKNOWN = 'UNKNOWN',
    CONVERTER_ERROR = 'CONVERTER_ERROR',
    DENIED_IMPORT_TARGET = 'DENIED_IMPORT_TARGET',
    DELETED_IMPORT_TARGET = 'DELETED_IMPORT_TARGET',
    OUTSIDE_OF_SCRIPT_FOLDER = 'OUTSIDE_OF_SCRIPT_FOLDER',
    NOT_ALLOWED_FOR_SCRIPT_FOLDER = 'NOT_ALLOWED_FOR_SCRIPT_FOLDER',
}

export enum ConflictResolutionStrategy {
    LEAVE = 'LEAVE',
    REPLACE = 'REPLACE',
    CLONE = 'CLONE',
}
