import { Collapse, FormInstance } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import theme from './SimulationPanel.scss';
import { SimulationActionsIcon } from '../SimulationModeling/SettingsSidebar/Icon/SimulationActionsIcon.component';
import { SimulationRun } from '../../../../serverapi/api';
import { CollapseHeader } from './CollapseHeader.component';

const { Panel } = Collapse;

type TSimulationPanelProps = {
    children: React.ReactNode;
    key: string;
    simulationRuns: Array<SimulationRun>;
    sim: SimulationRun;
    isCollapseDisabled: boolean;
    form: FormInstance;
    renameSimulation: (simulation: SimulationRun, e: ChangeEvent<HTMLInputElement>) => void;
    setIsCollapseDisabled: (isCollapseDisabled: boolean) => void;
    setSimulationRuns: (simulationRuns: Array<SimulationRun>) => void;
    cloneSimulation: (simulation: SimulationRun) => void;
    deleteSimulation: (simulation: SimulationRun) => void;
};

export const SimulationPanel = (props: TSimulationPanelProps) => {
    const {
        simulationRuns,
        key,
        sim,
        isCollapseDisabled,
        form,
        setSimulationRuns,
        cloneSimulation,
        renameSimulation,
        setIsCollapseDisabled,
        deleteSimulation,
        ...restProps
    } = props;
    const [renameSimulationAllowed, setRenameSimulationAllowed] = useState<boolean>(false);

    return (
        <Panel
            className={theme.panelContainer}
            {...restProps}
            key={key}
            header={
                <CollapseHeader
                    sim={sim}
                    form={form}
                    renameSimulationAllowed={renameSimulationAllowed}
                    setRenameSimulationAllowed={setRenameSimulationAllowed}
                    renameSimulation={renameSimulation}
                    setIsCollapseDisabled={setIsCollapseDisabled}
                />
            }
            extra={
                <SimulationActionsIcon
                    disabled={isCollapseDisabled && !renameSimulationAllowed}
                    deleteSimulation={() => {
                        deleteSimulation(sim);
                        setSimulationRuns(simulationRuns.filter((sr) => sr.id.id !== sim.id.id));
                    }}
                    renameSimulation={() => {
                        setIsCollapseDisabled(true);
                        setRenameSimulationAllowed(true);
                    }}
                    cloneSimulation={() => {
                        cloneSimulation(sim);
                    }}
                />
            }
        />
    );
};
