import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { ReportType } from '../serverapi/api';
import { IReportModelTypeData } from '@/reducers/reportModelType.reducer.types';
import { INITIAL_REPORT_MODEL_TYPE_SERVER_STATE } from '@/reducers/reportModelType.reducer';

export const EMPTY_PRESET_ID = 'empty';

const reportModelTypeStateSelector = (state: TRootState) => state.reportModelType;

const toReportModelTypeArray = (srcData: IReportModelTypeData): ReportType[] => {
    return Object.keys(srcData.byId).map((k) => srcData.byId[k]);
};

export namespace ReportModelTypeSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector(reportModelTypeStateSelector, (state) => {
            const serverModelTypeState = state[presetId];
            if (serverModelTypeState) {
                return { ...serverModelTypeState };
            }

            return { ...INITIAL_REPORT_MODEL_TYPE_SERVER_STATE };
        });

    export const getAllReportModelTypes = (presetId: string) =>
        createSelector(byPresetId(presetId), (state) => toReportModelTypeArray(state));

    export const byId = (modelTypeId: string, presetId: string) =>
        createSelector(byPresetId(presetId), (state) => {
            return state.byId[modelTypeId];
        });
}
