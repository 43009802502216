// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer__root__BKymz{height:59px;margin-top:auto;border-top:1px solid var(--Border-SecondaryNeutral200-UIKit)}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/SymbolEditorTab/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA,qBACI,WAAA,CACA,eAAA,CACA,4DAAA","sourcesContent":[".root {\r\n    height: 59px;\r\n    margin-top: auto;\r\n    border-top: 1px solid var(--Border-SecondaryNeutral200-UIKit);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Footer__root__BKymz"
};
export default ___CSS_LOADER_EXPORT___;
