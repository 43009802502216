export const SYMBOL_SVG_CONVERT_REQUEST_SUCCESS = 'SYMBOL_SVG_CONVERT_REQUEST_SUCCESS';

export const INIT_SYMBOL_EDITOR = 'INIT_SYMBOL_EDITOR';

export const SUBMIT_SYMBOL_REQUEST = 'SUBMIT_SYMBOL_REQUEST';

export const CREATE_SYMBOL_REQUEST = 'CREATE_SYMBOL_REQUEST';

export const EDIT_MODEL_TYPE_SYMBOL_ATTRIBUTE_TYPE_STYLE = 'EDIT_MODEL_TYPE_SYMBOL_ATTRIBUTE_TYPE_STYLE';

export const EDIT_MODEL_TYPE_EDGE_ATTRIBUTE_TYPE_STYLE = 'EDIT_MODEL_TYPE_EDGE_ATTRIBUTE_TYPE_STYLE';

export const UPLOAD_SYMBOL_SVG_REQUEST = 'UPLOAD_SYMBOL_SVG_REQUEST';

export const SUBMIT_KANBAN_CARD_REQUEST = 'SUBMIT_KANBAN_CARD_REQUEST';

export const SYMBOL_EDITOR_UPDATE = 'SYMBOL_EDITOR_UPDATE';
