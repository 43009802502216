import React, { FC } from 'react';

type TPreloaderArisAnalysis = {
    className?: string;
};

export const PreloaderAris: FC<TPreloaderArisAnalysis> = ({ className }) => {
    return (
        <div className={className}>
            <svg version="1.1" id="Layer_1" x="0px" y="0px" width="130px" height="20px">
                <circle opacity="0" fill="var(--mainColor)" cx="10" cy="8.438" r="5.969">
                    <animate attributeName="opacity" begin=".1" values="0;1;0" dur="1s" repeatCount="indefinite" />
                </circle>
                <circle opacity="0" fill="var(--mainColor)" cx="30" cy="8.438" r="5.969">
                    <animate attributeName="opacity" begin=".2" values="0;1;0" dur="1s" repeatCount="indefinite" />
                </circle>
                <circle opacity="0" fill="var(--mainColor)" cx="50" cy="8.438" r="5.969">
                    <animate attributeName="opacity" begin=".3" values="0;1;0" dur="1s" repeatCount="indefinite" />
                </circle>
                <circle opacity="0" fill="var(--mainColor)" cx="70" cy="8.438" r="5.969">
                    <animate attributeName="opacity" begin=".4" values="0;1;0" dur="1s" repeatCount="indefinite" />
                </circle>
                <circle opacity="0" fill="var(--mainColor)" cx="90" cy="8.438" r="5.969">
                    <animate attributeName="opacity" begin=".5" values="0;1;0" dur="1s" repeatCount="indefinite" />
                </circle>
            </svg>
        </div>
    );
};
