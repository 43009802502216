import { TWorkspaceTab } from '../../../models/tab.types';
import { Comment, NodeId } from '../../../serverapi/api';

export type TCommentsPanelProps = {
    scheme: TWorkspaceTab;
    isEditedComment?: boolean;
    disableFilters?: boolean;
    sortedComments: Comment[];
    commentsInGraph: Comment[];
    setAnyCommentEditingStatus: (newStatus: boolean) => void;
    deleteComment: (modelId: NodeId, commentId: NodeId, isAnswer?: true) => void;
    createMarker: (comment: Comment, isMarkerDragging?: boolean) => void;
    closeCommentsPanel: (modelId: NodeId) => void;
    changeDisplayCommentMarkers: (commentIds: string[], newDisplayStatus: boolean) => void;
    onContextMenuShow?: (menuId: string) => void;
    pinComment: (modelId: NodeId, comment: Comment) => void;
    unpinComment: (modelId: NodeId, comment: Comment) => void;
    deleteUploadedFile: (fileId: string, commentId: NodeId, modelId: NodeId) => void;
    getPinnedComments: (modelId: NodeId) => Comment[];
    getSortedActualComments: (modelId: NodeId) => Comment[];
    getCommentsByParentId: (modelId: NodeId, parentId: string) => Comment[];
    onCancel: (modelId: NodeId) => void;
};

export type TCommentsPanelPropsOwnProps = {
    modelNodeId?: NodeId;
};

export enum SizeModeEnum {
    DEFAULT = 'DEFAULT',
    FULL_SIZE = 'FULL_SIZE',
    COLLAPSED = 'COLLAPSED',
}

export type TTooltipContainerCoordinates = [number, number];
