// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Search__wrapper__ndoem{position:absolute;top:-1px;right:10px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:8px;background-color:#fff;border:1px solid #cecece;border-bottom-left-radius:4px;border-bottom-right-radius:4px;z-index:1}.Search__wrapper__ndoem button,.Search__wrapper__ndoem input{background-color:rgba(0,0,0,0);outline:none}.Search__wrapper__ndoem button{padding:0 4px;color:var(--text)}.Search__input__ecvLr{padding:2px 4px;border:1px solid #cecece}.Search__text__HhM6y{margin:0 6px}", "",{"version":3,"sources":["webpack://./src/modules/Editor/components/Search/Search.scss"],"names":[],"mappings":"AAAA,wBACI,iBAAA,CACA,QAAA,CACA,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA,CACA,qBAAA,CACA,wBAAA,CACA,6BAAA,CACA,8BAAA,CACA,SAAA,CAEA,6DAEI,8BAAA,CACA,YAAA,CAGJ,+BACI,aAAA,CACA,iBAAA,CAIR,sBACI,eAAA,CACA,wBAAA,CAGJ,qBACI,YAAA","sourcesContent":[".wrapper {\r\n    position: absolute;\r\n    top: -1px;\r\n    right: 10px;\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 8px;\r\n    background-color: #fff;\r\n    border: 1px solid #cecece;\r\n    border-bottom-left-radius: 4px;\r\n    border-bottom-right-radius: 4px;\r\n    z-index: 1;\r\n\r\n    button,\r\n    input {\r\n        background-color: transparent;\r\n        outline: none;\r\n    }\r\n\r\n    button {\r\n        padding: 0 4px;\r\n        color: var(--text);\r\n    }\r\n}\r\n\r\n.input {\r\n    padding: 2px 4px;\r\n    border: 1px solid #cecece;\r\n}\r\n\r\n.text {\r\n    margin: 0 6px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Search__wrapper__ndoem",
	"input": "Search__input__ecvLr",
	"text": "Search__text__HhM6y"
};
export default ___CSS_LOADER_EXPORT___;
