import {
    ADD_PUBLISHED_MODELS,
    DELETE_PUBLISHED_MODELS,
    OPEN_PUBLISHED_MODELS_TAB,
    UPDATE_PUBLISHED_MODELS,
} from '@/actionsTypes/publishedModels.actionTypes';
import {
    TAddPublishedModelsAction,
    TDeletePublishedModelsAction,
    TOpenPublishedModelsTabAction,
    TUpdatePublishedModelsAction,
} from './publishedModels.actions.types';
import { Node, NodeId } from '@/serverapi/api';

export const openPublishedModelsTab = (): TOpenPublishedModelsTabAction => ({
    type: OPEN_PUBLISHED_MODELS_TAB,
});

export const addPublishedModels = (publishedModels: Node[]): TAddPublishedModelsAction => ({
    type: ADD_PUBLISHED_MODELS,
    payload: { publishedModels },
});

export const deletePublishedModels = (publishedModelsNodeIds: NodeId[]): TDeletePublishedModelsAction => ({
    type: DELETE_PUBLISHED_MODELS,
    payload: { publishedModelsNodeIds },
});

export const updatePublishedModels = (): TUpdatePublishedModelsAction => ({
    type: UPDATE_PUBLISHED_MODELS,
});
