import { NotificationDTO, NotificationTemplateDTO } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { TTableRowData } from './NotificationList.types';
import { Locale } from '@/modules/Header/components/Header/header.types';
import messages from './NotificationsList.messages';

const intl = LocalesService.useIntl();

export const getFiltredRows = (
    notifications: NotificationDTO[],
    notificationTemplates: NotificationTemplateDTO[],
    searchInput: string,
    currentLocale: Locale,
): TTableRowData[] => {
    return notifications
        .filter((notification) => {
            const name = LocalesService.internationalStringToString(notification.name, currentLocale).toLowerCase();

            return name.includes(searchInput);
        })

        .map((notification) => {
            const { id, name, templateId, type } = notification;
            const currentTemplate: NotificationTemplateDTO | undefined = notificationTemplates.find(
                (template) => template.id === templateId,
            );
            const templateName = currentTemplate
                ? LocalesService.internationalStringToString(currentTemplate.name, currentLocale)
                : '';

            return {
                key: id,
                id,
                name: LocalesService.internationalStringToString(name, currentLocale),
                type: messages[type] ? intl.formatMessage(messages[type]) : '',
                template: templateName,
                enabled: notification.enabled,
            };
        })
        .sort((a, b) => {
            return a.name.localeCompare(b.name, currentLocale);
        });
};
