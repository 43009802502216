export const DASHBOARD_CREATE = 'DASHBOARD_CREATE';

export const DASHBOARD_SAVE = 'DASHBOARD_SAVE';

export const DASHBOARD_RESTORE = 'DASHBOARD_RESTORE';

export const DASHBOARD_SET_CELLS_COUNT = 'DASHBOARD_SET_CELLS_COUNT';

export const DASHBOARD_EDITOR_MODE_CHANGED = 'DASHBOARD_EDITOR_MODE_CHANGED';

export const DASHBOARD_ADD_WIDGET = 'DASHBOARD_ADD_WIDGET';

export const DASHBOARD_DELETE_WIDGET = 'DASHBOARD_DELETE_WIDGET';

export const DASHBOARD_CHANGE_CELL_SIZE = 'DASHBOARD_CHANGE_CELL_SIZE';

export const DASHBOARD_CHANGE_WIDGET_GEOMETRY = 'DASHBOARD_CHANGE_WIDGET_GEOMETRY';

export const DASHBOARD_SELECT_WIDGET = 'DASHBOARD_SELECT_WIDGET';

export const DASHBOARD_SET_WIDGET_NAME = 'DASHBOARD_SET_WIDGET_NAME';

export const DASHBOARD_SET_WIDGET_BG = 'DASHBOARD_SET_WIDGET_BG';

export const DASHBOARD_SET_WIDGET_BORDER = 'DASHBOARD_SET_WIDGET_BORDER';

export const DASHBOARD_SET_WIDGET_TEXT = 'DASHBOARD_SET_WIDGET_TEXT';

export const DASHBOARD_SET_WIDGET_SOURCE = 'DASHBOARD_SET_WIDGET_SOURCE';

export const LOAD_DASHBOARD_BY_ID = 'LOAD_DASHBOARD_BY_ID';

export const DASHBOARD_ADD_NEW_DATASET = 'DASHBOARD_ADD_NEW_DATASET';

export const DASHBOARD_DELETE_DATASET = 'DASHBOARD_DELETE_DATASET';
