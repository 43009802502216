import { BPMMxGraphView } from '../BPMGraphClasses';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolManager.class';
import SequenceUtils from '../ComplexSymbols/symbols/Sequence/sequence.utils';
import { MxCell, MxCellState, MxConstants, MxGeometry, MxPoint } from '../mxgraph';
import { MxUtils } from '../util/MxUtils';
import { SequenceCellState } from './SequenceCellState';
import { SequenceGraph } from './SequenceGraph';
import { forkEdgeOffset } from './SequenceConstants';

export class SequenceGraphView extends BPMMxGraphView {
    graph: SequenceGraph;

    createState(cell) {
        return new SequenceCellState(this, cell, this.graph.getCellStyle(cell));
    }

    /**
     *  Обновление состояние связи
     * @param { MxCellState } edgeState - связь
     * @param { MxGeometry } geo - положение связи
     */
    updateEdgeState(edgeState: MxCellState, geo: MxGeometry) {
        this.geoToAbs(edgeState);

        const srcState: MxCellState = edgeState.visibleSourceState;
        const trgState: MxCellState = edgeState.visibleTargetState;

        if (!srcState || !trgState) return;

        const isRecursive = this.graph.isRecursiveEdge(edgeState.cell);

        if (edgeState.absolutePoints && edgeState.absolutePoints.length > 1) {
            const srcPoint: MxPoint = edgeState.absolutePoints[0].clone();
            const trgPoint: MxPoint = edgeState.absolutePoints[edgeState.absolutePoints.length - 1].clone();
            const isReverce = srcState.getCenterX() > trgState.getCenterX();

            const srcConstrainX = this.graph.connectionHandler.calcXConstrain(
                edgeState,
                true,
                null,
                isReverce,
                isRecursive,
            );
            const trgConstrainX = this.graph.connectionHandler.calcXConstrain(
                edgeState,
                false,
                null,
                isReverce,
                isRecursive,
            );
            const srcBounds = this.getPerimeterBounds(srcState);
            const srcX = srcBounds.x + srcBounds.width * srcConstrainX;
            const trgBounds = this.getPerimeterBounds(trgState);
            const trgX = trgBounds.x + trgBounds.width * trgConstrainX;

            if (isRecursive && edgeState.absolutePoints.length < 4) {
                const startPoint: MxPoint = edgeState.absolutePoints[0];
                const endPoint: MxPoint = edgeState.absolutePoints[1];
                const secondStartPoint: MxPoint = new MxPoint(startPoint.x + 20, startPoint.y);
                const secondEndPoint: MxPoint = new MxPoint(endPoint.x + 20, endPoint.y);
                edgeState.absolutePoints = [startPoint, secondStartPoint, secondEndPoint, endPoint];
            } else if (this.graph.isDurationEdge(edgeState.cell)) {
                trgPoint.y = this.graph.connectionHandler.calcYForDurationEdge(srcX, trgX, srcPoint.y);
                edgeState.absolutePoints = [srcPoint, trgPoint];
            } else if (SequenceUtils.isForkEdge(edgeState.cell)) {
                edgeState.absolutePoints[0].x = srcX;
                edgeState.absolutePoints[1].x = srcX + (isReverce ? -forkEdgeOffset : forkEdgeOffset);
                edgeState.absolutePoints[edgeState.absolutePoints.length - 1].x = trgX;
            } else {
                edgeState.absolutePoints[0].x = srcX;
                edgeState.absolutePoints[edgeState.absolutePoints.length - 1].x = trgX;
            }
            edgeState.style[MxConstants.STYLE_EXIT_X] = srcConstrainX;
            edgeState.style[MxConstants.STYLE_ENTRY_X] = trgConstrainX;
        }

        // This will remove edges with no terminals and no terminal points
        // as such edges are invalid and produce NPEs in the edge styles.
        // Also removes connected edges that have no visible terminals.
        if (
            (this.graph.model.getTerminal(edgeState.cell, true) != null && srcState == null) ||
            (srcState == null && geo.getTerminalPoint(true) == null) ||
            (this.graph.model.getTerminal(edgeState.cell, false) != null && trgState == null) ||
            (trgState == null && geo.getTerminalPoint(false) == null) ||
            (!isRecursive && srcState === trgState)
        ) {
            this.clear(edgeState.cell, true);
        } else {
            this.updateFixedTerminalPoints(edgeState, srcState, trgState);

            const pts = edgeState.absolutePoints;

            if (
                edgeState.cell !== this.currentRoot &&
                (pts == null || pts.length < 2 || pts[0] == null || pts[pts.length - 1] == null)
            ) {
                // This will remove edges with invalid points from the list of states in the view.
                // Happens if the one of the terminals and the corresponding terminal point is null.
                this.clear(edgeState.cell, true);
            } else {
                this.updateEdgeBounds(edgeState);
                this.updateEdgeLabelOffset(edgeState);
            }
        }

        this.absToGeo(edgeState);
    }

    /**
     *  Обновление терминальных точек для свзяи
     * @param { MxCellState } edgeState - связь
     * @param { MxCellState } source - источник связи
     * @param { MxCellState } target - таргет связи
     */
    updateFixedTerminalPoints(edge: SequenceCellState, source: MxCellState, target: MxCellState) {
        const isRecursive = this.graph.isRecursiveEdge(edge.cell);
        const isFork = SequenceUtils.isForkEdge(edge.cell);

        const srcConstraint = this.graph.getConnectionConstraint(edge, source, true);
        const trgConstraint = this.graph.getConnectionConstraint(edge, target, false);
        const srcTerminalPoints = this.getFixedTerminalPoint(edge, source, true, srcConstraint);
        const trgTerminalPoints = this.getFixedTerminalPoint(edge, target, false, trgConstraint);

        if (srcTerminalPoints && trgTerminalPoints) {
            edge.setAbsoluteTerminalPoint(srcTerminalPoints, true, trgTerminalPoints, isRecursive, isFork);
            edge.setAbsoluteTerminalPoint(trgTerminalPoints, false, srcTerminalPoints, isRecursive);
        }
    }

    /**
     *  Перевод абсолютных точек связи в гео
     * @param { MxCellState } edgeState - связь
     */
    absToGeo(state: MxCellState) {
        if (state?.cell?.geometry && state?.absolutePoints)
            state.cell.geometry.points = state.absolutePoints.map(
                (point: MxPoint) => new MxPoint(point.x / this.scale, point.y / this.scale),
            );
    }

    /**
     *  Перевод точек геометри связи в  абсолютные точки
     * @param { MxCellState } edgeState - связь
     */
    geoToAbs(state: MxCellState) {
        if (state?.cell?.geometry?.points)
            state.absolutePoints = state.cell.geometry.points.map(
                (point: MxPoint) => new MxPoint(point.x * this.scale, point.y * this.scale),
            );
    }

    /**
     *  Обновление состояние объекта
     * @param { MxCellState } edgeState - объект
     * @param { MxGeometry } geo
     */
    updateVertexState(state: SequenceCellState, geo) {
        const model = this.graph.getModel();
        const pState = this.getState(model.getParent(state.cell));

        if (geo.relative && pState != null && !model.isEdge(pState.cell)) {
            const alpha = MxUtils.toRadians(pState.style[MxConstants.STYLE_ROTATION] || '0');

            if (alpha !== 0) {
                const cos = Math.cos(alpha);
                const sin = Math.sin(alpha);

                const ct = new MxPoint(state.getCenterX(), state.getCenterY());
                const cx = new MxPoint(pState.getCenterX(), pState.getCenterY());
                const pt = MxUtils.getRotatedPoint(ct, cos, sin, cx);
                state.x = pt.x - state.width / 2;
                state.y = pt.y - state.height / 2;
            }
        }

        this.updateVertexLabelOffset(state);

        if (SequenceUtils.isSequenceExecutionSymbol(state.cell)) {
            const frameParent = ComplexSymbolManager.getComplexSymbolRootCell(state.cell);
            if (frameParent) {
                frameParent?.edges?.forEach((edge: MxCell) => {
                    const edgeState = this.getState(edge);
                    if (edgeState) {
                        this.updateCellState(edgeState);
                        this.graph.cellRenderer.redraw(edgeState, true);
                    }
                });
            }
        }
        if (SequenceUtils.isSequenceDiagramCell(state.cell)) {
            state.cell?.edges?.forEach((edge: MxCell) => {
                const edgeState = this.getState(edge);
                if (edgeState) {
                    this.updateCellState(edgeState);
                    this.graph.cellRenderer.redraw(edgeState, true);
                }
            });
        }
    }
}
