import { defineMessages } from 'react-intl';

export default defineMessages({
    scriptSelectorDialogName: {
        id: 'DiagramScriptDialog.scriptSelectorDialogName',
        defaultMessage: 'Выберите скрипт для исполнения',
    },
    chooseScript: {
        id: 'DiagramScriptDialog.chooseScript',
        defaultMessage: 'Выбор скрипта',
    },
    save: {
        id: 'DiagramScriptDialog.save',
        defaultMessage: 'Сохранить',
    },
    formStatusError: {
        id: 'DiagramScriptDialog.formStatusError',
        defaultMessage: 'Ошибка',
    },
    scriptNameRequiredError: {
        id: 'DiagramScriptDialog.scriptSelectRequiredError',
        defaultMessage: 'Выберите скрипт из списка',
    },
    scriptFormConfirmButtonRun: {
        id: 'DiagramScriptDialog.scriptFormConfirmButtonRun',
        defaultMessage: 'Запустить',
    },
    scriptFormConfirmButtonContinue: {
        id: 'DiagramScriptDialog.scriptFormConfirmButtonContinue',
        defaultMessage: 'Продолжить',
    },
    scriptFormDeclineButton: {
        id: 'DiagramScriptDialog.scriptFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    scriptNameLabel: {
        id: 'DiagramScriptDialog.scriptNameLabel',
        defaultMessage: 'Доступные скрипты',
    },
    pathLabel: {
        id: 'DiagramScriptDialog.pathLabel',
        defaultMessage: 'Расположение',
    },
    searchLabel: {
        id: 'ModelDialog.searchPlaceholder',
        defaultMessage: 'Поиск по имени',
    },
    scriptNotSelectedError: {
        id: 'DiagramScriptDialog.scriptNotSelectedError',
        defaultMessage: 'Не выбран скрипт для исполнения',
    },
    scriptNotSelected: {
        id: 'DiagramScriptDialog.scriptNotSelected',
        defaultMessage: 'Скрипт не выбран',
    },
    pathRequiredError: {
        id: 'DiagramScriptDialog.pathRequiredError',
        defaultMessage: 'Путь должен быть заполнен',
    },
    pathPlaceholder: {
        id: 'DiagramScriptDialog.pathPlaceholder',
        defaultMessage: 'Название сервера/Название базы данных/Папка',
    },
    noScriptAvailable: {
        id: 'DiagramScriptDialog.noScriptAvailable',
        defaultMessage: 'Нет доступных скриптов',
    },
});
