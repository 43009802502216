import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'ServerProfileChange.title',
        defaultMessage: 'Смена активного профиля',
    },
    warn: {
        id: 'ServerProfileChange.warn',
        defaultMessage: 'Приложение будет перезапущено',
    },
    ok: {
        id: 'ServerProfileChange.ok',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'ServerProfileChange.cancel',
        defaultMessage: 'Отмена',
    }
});
