
let store: any; // tslint:disable-line no-any

export function setStore(localStore) {
    store = localStore;
}

export function getStore() {
    return store;
}
