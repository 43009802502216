import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { LicenseDTO } from '../serverapi/api';

export const licenseState = (state: TRootState) => state.licenses;

export namespace LicenseSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(licenseState, (state) => state.byServerId[serverId] || Array<LicenseDTO>());

    export const getOwnersByLicenseType = (serverId: string, licenseType: string) =>
        createSelector(
            byServerId(serverId),
            (state) => state && state.owners && state.owners[licenseType] && state.owners[licenseType],
        );

    export const getOwners = (serverId: string) =>
        createSelector(byServerId(serverId), (state) => (state && state.owners ? state.owners : undefined));
}
