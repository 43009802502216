import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../../SystemProperties.messages';
import theme from '../../SystemProperties.scss';
import { ts } from '../../SystemProperties.utils';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../../src/selectors/systemProperties.selectors';
import { FormItemInputNumberWithLabel } from '../FormItemInputNumberWithLabelComponent/FormItemInputNumberWithLabel.component';
import { MODEL_SAVE_MAX_LENGTH, SystemPropertiesFormItemNames } from '../../SystemProperties.types';
import { LocalesService } from '../../../../../services/LocalesService';
import { Rule } from 'rc-field-form/es/interface';

const getRule = (): Rule => {
    const intl = LocalesService.useIntl();
    
    return {
        type: 'number',
        min: 1,
        max: MODEL_SAVE_MAX_LENGTH,
        message: intl.formatMessage(messages.rangeValue, { min: 1, max: MODEL_SAVE_MAX_LENGTH }),
    };
};

export const ModelSettings = () => {
    const intl = useIntl();
    const modelAutoSaveTimeout = useSelector(SystemPropertiesSelectors.getModelAutoSaveTimeout);
    const modelVersionSaveTimeout = useSelector(SystemPropertiesSelectors.getModelVersionSaveTimeout);

    return (
        <div className={theme.formTab}>
            <FormItemInputNumberWithLabel
                label={intl.formatMessage(messages.modelAutoSaveTimeout)}
                initialValue={ts(modelAutoSaveTimeout)}
                name={SystemPropertiesFormItemNames.modelAutoSaveTimeout}
                placeholder="20"
                rules={[getRule()]}
            />
            <FormItemInputNumberWithLabel
                label={intl.formatMessage(messages.modelVersionSaveTimeout)}
                initialValue={ts(modelVersionSaveTimeout)}
                name={SystemPropertiesFormItemNames.modelVersionSaveTimeout}
                placeholder="1000"
                rules={[getRule()]}
            />
        </div>
    );
};
