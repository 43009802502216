import React from 'react';
import { Form, Input, Select } from 'antd';
import theme from './KanbanCardAttributesEditor.scss';
import { AttributeType, KanbanCardSectorAttribute } from '../../../../../../../../serverapi/api';
import { FormGroup } from '../../../../../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID } from '../../../util/KanbanCardEditor.utils';
import { useIntl } from 'react-intl';
import messages from '../KanbanCardEditor.messages';

type TKanbanCardAttributeTypeSelectorProps = {
    attributeTypes: AttributeType[];
    onChange: (attributeTypeId: KanbanCardSectorAttribute) => void;
    currentAttribute: KanbanCardSectorAttribute;
};

export const KanbanCardAttributeTypeSelector = (props: TKanbanCardAttributeTypeSelectorProps) => {
    const { attributeTypes, currentAttribute, onChange } = props;
    const intl = useIntl();

    const getAttributeValueType = (attrId: string) => {
        const attributeType = attributeTypes.find((attr) => attr.id === currentAttribute.attributeTypeId);
        return attributeType?.valueType;
    };

    const onAttributeTypeChange = (typeId: string): void => {
        const changedAttribute = { ...currentAttribute, attributeTypeId: typeId };
        if (typeId === DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID && !changedAttribute.hasOwnProperty('textValue')) {
            changedAttribute.textValue = '';
        }
        onChange(changedAttribute);
    };

    return (
        <Form>
            <FormGroup>
                <Form.Item>
                    <h3>{intl.formatMessage(messages.selectAttributeType)}</h3>
                </Form.Item>
                <div className={theme.attributeTypeWrapper}>
                    <Form.Item className={theme.selectAttributeWrapper}>
                        <div>
                            <Select
                                dropdownClassName={theme.attributeSelectDropdown}
                                value={currentAttribute.attributeTypeId}
                                onChange={onAttributeTypeChange}
                                getPopupContainer={(trigger) => trigger.parentNode}
                            >
                                {attributeTypes.map((attributeType) => (
                                    <Select.Option value={attributeType.id} key={attributeType.id}>
                                        {attributeType.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </Form.Item>
                    <div className={theme.attributeFormat}>
                        <span>{intl.formatMessage(messages.format)}</span>
                        <Input
                            className={theme.formatValue}
                            value={getAttributeValueType(currentAttribute.attributeTypeId)}
                            disabled
                        />
                    </div>
                </div>
            </FormGroup>
        </Form>
    );
};
