import { NodeId, SimulationNode, SimulationRun } from '../../serverapi/api';
import { setServerIdToNodeInterface, setServerIdToSimulationRun } from '../../utils/nodeId.utils';
import { apiBundle } from '../api/api-bundle';

export class SimulationModelingDaoService {
    public static async getSimulationModeling(nodeId: NodeId): Promise<SimulationNode> {
        const simulationNode: SimulationNode = await apiBundle(nodeId.serverId).simulation.getSimulation({
            repositoryId: nodeId.repositoryId,
            simulationId: nodeId.id,
        });
        setServerIdToNodeInterface(simulationNode, nodeId.serverId);

        return simulationNode;
    }

    public static async getReportOfSimulation(
        serverId: string,
        repositoryId: string,
        simulationId: string,
        id: string,
    ): Promise<SimulationRun> {
        const report: SimulationRun = await apiBundle(serverId).simulation.getReport({
            repositoryId,
            simulationId,
            id,
        });
        const simulationRunWithId = setServerIdToSimulationRun(report, serverId);

        return simulationRunWithId;
    }

    public static async runSimulation(body: SimulationRun): Promise<SimulationRun> {
        const { serverId, repositoryId, id } = body.simulationId;
        const simulationRun: SimulationRun = await apiBundle(serverId).simulation.runSimulation({
            repositoryId,
            simulationId: id,
            body,
        });
        const simulationRunWithServerId = setServerIdToSimulationRun(simulationRun, serverId);

        return simulationRunWithServerId;
    }

    public static async saveSimulationParams(body: SimulationRun): Promise<SimulationRun> {
        const { serverId, repositoryId, id } = body.simulationId;
        const simulationRun: SimulationRun = await apiBundle(serverId).simulation.saveSimulationRun({
            repositoryId,
            simulationId: id,
            body,
        });
        const simulationRunWithServerId = setServerIdToSimulationRun(simulationRun, serverId);

        return simulationRunWithServerId;
    }
}
