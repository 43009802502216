import { EventDescriptorEventActionsEnum, EventDescriptorEventRunStrategyEnum } from "../../../serverapi/api";

export enum EventFormItemsNames  {
    name = 'name',
    eventRunStrategy='eventRunStrategy',
    eventActions='eventActions',
    userId='userId',
    groupId='groupId',
    nodeId='nodeId',
    parentNodeId='parentNodeId',
    className='className',
    params='params',
    scriptId='scriptId',
    launchingScriptUserId='launchingScriptUserId',
}

export type TFormValues = {
    [EventFormItemsNames.name]: string;
    [EventFormItemsNames.eventActions]: EventDescriptorEventActionsEnum;
    [EventFormItemsNames.eventRunStrategy]: EventDescriptorEventRunStrategyEnum;
    [EventFormItemsNames.groupId]: number;
    [EventFormItemsNames.userId]: number;
    [EventFormItemsNames.className]: string;
    [EventFormItemsNames.scriptId]: string;
    [EventFormItemsNames.params]: string;
    [EventFormItemsNames.launchingScriptUserId]: number;
};