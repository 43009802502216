import { call, put, select, takeEvery, all } from 'redux-saga/effects';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemChildAdd, treeItemOpenPropertyAction } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { FOLDER_DIALOG_SUBMIT_DATA, NAVIGATE_TO_FOLDER_NODE } from '../actionsTypes/folderDialog.actionTypes';
import { folderDialogSubmitResult } from '../actions/folderDialog.actions';
import { TFolderDialogSubmitDataAction, TNavigateToFolderNodeAction } from '../actions/folderDialog.actions.types';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { v4 as uuid } from 'uuid';
import { FolderNode, NodeTypeEnum } from '../serverapi/api';
import messages from '../modules/FolderDialog/FolderDialog.messages';
import { TServerEntity } from '../models/entities.types';
import { TreeNode } from '../models/tree.types';
import { setServerIdToNodeInterface } from '../utils/nodeId.utils';
import { TreeSelectors } from '../selectors/tree.selectors';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { presetMetaDataRequest } from '../actions/notation.actions';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
import { moveToDirectAction } from '@/actions/editor.actions';

function* handleContextMenuAction({ payload }: TTreeItemContextMenuAction) {
    const { nodeId: parentNodeId, type, action, treeName } = payload;

    if (
        (type === TreeItemType.Folder || type === TreeItemType.Repository) &&
        action === TreeItemContextMenuAction.ADD_FOLDER
    ) {
        const presetId: string = yield select(TreeSelectors.presetById(parentNodeId));

        yield put(presetMetaDataRequest([presetId]));

        yield put(
            openDialog(DialogType.FOLDER_DIALOG, {
                parentNodeId,
                type: TreeItemType.Folder,
                treeName,
            }),
        );
    }
}

function* handleFolderDialogSubmitData({ payload }: TFolderDialogSubmitDataAction) {
    const { parentNodeId, folderName, folderTypeId, type, extraProperties, treeName } = payload;
    if (!parentNodeId) {
        yield put(folderDialogSubmitResult('error', messages.folderCreateError));

        return;
    }

    const server: TServerEntity = yield select(ServerSelectors.server(parentNodeId.serverId));
    try {
        const folderNode: FolderNode = {
            nodeId: {
                ...parentNodeId,
                id: uuid(),
            },
            folderType: folderTypeId,
            name: folderName,
            type: type as NodeTypeEnum,
            parentNodeId,
            ...extraProperties,
        };
        const newFolder: FolderNode = yield call(() =>
            server.api.tree.save({
                body: folderNode,
            }),
        );
        setServerIdToNodeInterface(newFolder, parentNodeId.serverId);

        yield all([
            put(folderDialogSubmitResult('success')),
            put(closeDialog(DialogType.FOLDER_DIALOG)),
            put(treeItemChildAdd({ parentNodeId, child: [newFolder as TreeNode] })),
        ]);
        yield put(moveToDirectAction(newFolder as TreeNode, treeName));
    } catch (error) {
        yield put(closeDialog(DialogType.FOLDER_DIALOG));
        throw error;
    }
}

function* handleNavigateToFolderNode(action: TNavigateToFolderNodeAction) {
    const { payload } = action;

    yield put(treeItemOpenPropertyAction(payload.nodeId, TreeItemType.Folder));
    try {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

export function* folderDialogSagaInit() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleContextMenuAction);
    yield takeEvery(FOLDER_DIALOG_SUBMIT_DATA, handleFolderDialogSubmitData);
    yield takeEvery(NAVIGATE_TO_FOLDER_NODE, handleNavigateToFolderNode);
}
