import React from 'react';
import messages from '../SystemProperties.messages';
import theme from '../SystemProperties.scss';
import { useIntl } from 'react-intl';
import { Alert, Modal } from 'antd';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TAgreementResetConfirmProps = {
    handleDelete: () => void;
    onHideAgreement: () => void;
    contentMessage: string;
};

export const AgreementResetConfirm = ({
    onHideAgreement,
    handleDelete,
    contentMessage,
}: TAgreementResetConfirmProps) => {
    const intl = useIntl();
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onHideAgreement,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: () => {
                        handleDelete();
                        onHideAgreement();
                    },
                    value: intl.formatMessage(messages.reset),
                    danger: true,
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <div>
            <Modal title={intl.formatMessage(messages.resetAgreement)} open onCancel={onHideAgreement} footer={footer}>
                <div className={theme.content}>{contentMessage}</div>
                <div className={theme.alert}>
                    <Alert
                        className={theme.alert}
                        message={intl.formatMessage(messages.resetAgreementWarningConfirm)}
                        type="error"
                    />
                </div>
            </Modal>
        </div>
    );
};
