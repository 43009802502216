import type { TChooseParentParams, TSubmitParams } from './RestoreDeletedNodeDialog.types';
import { connect } from 'react-redux';
import { closeDialog, openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { RestoreDeletedNodeDialog } from './RestoreDeletedNodeDialog.component';
import { restoreDeletedNodeDialogSubmit } from '../../../actions/restoreDeletedNodeDialog.actions';
import { TreeItemType } from '@/modules/Tree/models/tree';

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeDialog(DialogType.RESTORE_DELETED_NODE_DIALOG)),
    onSubmit: (params: TSubmitParams) => dispatch(restoreDeletedNodeDialogSubmit(params)),
    onChooseParent: (params: TChooseParentParams) =>
        dispatch(
            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                ...params,
                disableContextMenu: true,
                includeTypes: [TreeItemType.Repository, TreeItemType.Folder],
            }),
        ),
});

export const RestoreDeletedNodeDialogContainer = connect(null, mapDispatchToProps)(RestoreDeletedNodeDialog);
