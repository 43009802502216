import { NodeId, VisioModelMap } from '../../serverapi/api';
import { EdgeMappersAdapter } from './EdgeMapper.adapter';
import { ObjectMappersAdapter } from './ObjectMappers.adapter';

export class VisioPageAdapter {
    fileName?: string;
    fileUUID?: string;
    visioPageId?: string;
    visioPageName?: string;
    silaModelName?: string;
    silaModelTypeId?: string;
    silaModelTypeName: string;
    silaParentNodeId?: string;
    silaRepositoryId?: string;
    objectMappers?: Array<ObjectMappersAdapter>;
    edgeMappers?: Array<EdgeMappersAdapter>;

    constructor(item: VisioModelMap, fileName: string, fileUUID: string, nodeId?: NodeId) {
        this.fileName = fileName;
        this.fileUUID = fileUUID;
        this.visioPageId = item.visioPageId;
        this.visioPageName = item.visioPageName;
        this.edgeMappers = item.edgeMappers?.map((item) => new EdgeMappersAdapter(item));
        this.objectMappers = item.objectMappers?.map((item) => new ObjectMappersAdapter(item));
        this.silaModelName = item.silaModelName || item.visioPageName;
        this.silaModelTypeId = item.silaModelTypeId || '';
        this.silaModelTypeName = '';
        this.silaParentNodeId = nodeId?.id || '';
        this.silaRepositoryId = nodeId?.repositoryId || '';
    }
}
