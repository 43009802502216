import { openDialog } from '@/actions/dialogs.actions';
import { setCopiedElementsAction, setRepositoryIdWhereCopiedFrom } from '@/actions/editor.actions';
import { edgeDefinitionsAdd } from '@/actions/entities/edgeDefinition.actions';
import { objectDecompositionOpen } from '@/actions/entities/objectDecomposition.actions';
import { treeItemDelete, treeItemRefresh } from '@/actions/tree.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import {
    MATRIX_COPY_LANES,
    MATRIX_DELETE_EDGE_DEFINITION,
    MATRIX_DELETE_OBJECT_HEADERS,
    MATRIX_GET_EDGES_LIST,
    MATRIX_HANDLE_DECOMPOSITION_CLICK,
    MATRIX_PAST_EMPTY_LANE,
    MATRIX_PAST_OBJECTS,
} from '@/modules/Matrix/actionTypes/matrixEditor.actionTypes';
import {
    checkMatrixAutofilled,
    getCells,
    getCurrentLvl,
    getSelectedLanesWithChildren,
    haveChildren,
    isLaneExist,
    setNewIds,
} from '@/modules/Matrix/utils/Matrix.utils';
import { TDecompositionsListDialogOwnProps } from '@/modules/Models/components/DecompositionsList/DecompositionsListDialog.types';
import { TWhereCopiedFrom } from '@/reducers/copy.reducer.types';
import { EdgeDefinitionSelectors } from '@/selectors/edgeDefinition.selector';
import { EdgeTypeSelectors } from '@/selectors/edgeType.selectors';
import { getCopiedElements, getCopiedMatrixCells, getWhereCopiedFrom } from '@/selectors/editor.selectors';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import { nodeService } from '@/services/NodeService';
import { EdgeDefinitionDAOService } from '@/services/dao/EdgeDefinitionDAOService';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { cloneDeep } from 'lodash-es';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { showNotification, showNotificationByType } from '../../../actions/notification.actions';
import { batchActions } from '../../../actions/rootReducer.actions';
import { EditorMode } from '../../../models/editorMode';
import { NotificationType } from '../../../models/notificationType';
import { TWorkspaceTab } from '../../../models/tab.types';
import { TTreeEntityState } from '../../../models/tree.types';
import { deleteMatrixHeadersByObjectDefinitions } from '../../../sagas/utils/matrix.saga.utils';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { getShowDeletedObjectsFilter, getTreeItems, TreeSelectors } from '../../../selectors/tree.selectors';
import {
    DiagramElement,
    EdgeDefinitionNode,
    EdgeType,
    MatrixCell,
    MatrixData,
    MatrixLane,
    MatrixNode,
    NodeId,
    ObjectConnection,
    ObjectInstance,
} from '../../../serverapi/api';
import { TreeItemType } from '../../Tree/models/tree';
import { HeaderType, TEdgeIdWithType, TObjectDefinitionsId } from '../MatrixEditor/Matrix.types';
import { refreshMatrix, updateMatrixData } from '../actions/matrix.actions';
import {
    matrixAddCellData,
    matrixStartLoadingCellData,
    matrixGetEdgesList,
    matrixSelectHeaderCells,
} from '../actions/matrixEditor.actions';
import {
    TDeleteMatrixObjectHeaders,
    TMatrixCopyLanesAction,
    TMatrixDecompositionIconClickAction,
    TMatrixDeleteEdgeDefinitionAction,
    TMatrixPastObjectsAction,
    TMatrixGetEdgesListAction,
    TMatrixPastEmptyLaneAction,
} from '../actions/matrixEditor.actions.types';
import { TSelectedHeadersCells } from '../reducers/matrixEditor.reducer.types';
import { MatrixSelectors } from '../selectors/matrix.selectors';
import { MatrixEditorSelectors } from '../selectors/matrixEditor.selectors';
import { ObjectDefinitionsDAOService } from '@/services/dao/ObjectDefinitionsDAOService';

function* handleCopyMatrixLanes(action: TMatrixCopyLanesAction) {
    const {
        matrixNodeId: nodeId,
        matrixNodeId: { repositoryId, serverId },
    } = action.payload;
    const matrix: MatrixNode | undefined = yield select(MatrixSelectors.byId(nodeId));

    if (!matrix?.content) return;

    const { ids, type }: TSelectedHeadersCells = yield select(MatrixEditorSelectors.getSelectedHeaderCells(nodeId));

    const lanes = type === HeaderType.row ? matrix.content.rows : matrix.content.columns;
    const lastFilledIndex = lanes.findLastIndex((row) => row.text);
    const filledLanes = lanes.slice(0, lastFilledIndex + 1);

    const lanesToCopyWithChildren: MatrixLane[] = getSelectedLanesWithChildren(ids, filledLanes, true);
    const lanesIds: string[] = lanesToCopyWithChildren.map((lane) => lane.id);
    const selectedCells: MatrixCell[] = matrix.content.cells.filter((cell) =>
        type === HeaderType.row ? lanesIds.includes(cell.rowId) : lanesIds.includes(cell.columnId),
    );

    yield put(setCopiedElementsAction(lanesToCopyWithChildren, selectedCells));
    yield put(setRepositoryIdWhereCopiedFrom(repositoryId, serverId, matrix.type));
}

function* handleMatrixPastObjects({ payload: { matrixNodeId: nodeId } }: TMatrixPastObjectsAction) {
    const matrix: MatrixNode | undefined = yield select(MatrixSelectors.byId(nodeId));

    if (!matrix) return;

    const selectedHeaderCells: TSelectedHeadersCells = yield select(
        MatrixEditorSelectors.getSelectedHeaderCells(nodeId),
    );
    const { ids: selectedCellsId, type: headerType } = selectedHeaderCells;
    const copiedElements: DiagramElement[] | MatrixLane[] = yield select(getCopiedElements);
    const copiedMatrixCells: MatrixCell[] = yield select(getCopiedMatrixCells);
    const whereCopiedFrom: TWhereCopiedFrom | null = yield select(getWhereCopiedFrom);
    const objectDefinitions: TTreeEntityState[] = yield select(getTreeItems(nodeId.serverId, nodeId.repositoryId));

    const matrixData: MatrixData | undefined = cloneDeep(matrix.content);
    const isMatrixAutofilled: boolean = checkMatrixAutofilled(matrixData);

    if (
        !matrixData ||
        selectedCellsId.length === 0 ||
        whereCopiedFrom?.repositoryId !== nodeId.repositoryId ||
        whereCopiedFrom?.serverId !== nodeId.serverId
    )
        return;

    const isColumnPast = headerType === HeaderType.column;
    const currentLanes = isColumnPast ? [...matrixData.columns] : [...matrixData.rows];

    let minSelectedIndex = currentLanes.length - 1;
    selectedCellsId.forEach((cellId) => {
        const laneIndex = currentLanes.findIndex((lane) => lane.id === cellId);
        minSelectedIndex = Math.min(minSelectedIndex, laneIndex);
    });

    if (whereCopiedFrom.nodeType === TreeItemType.Matrix) {
        const { lanesWithNewIds: lanesToPastWithChildern, oldIdToNewIdMap } = setNewIds(copiedElements as MatrixLane[]);

        const lanesForNewParent: MatrixLane[] = lanesToPastWithChildern.filter(
            (lane) => lane.parentId && !lanesToPastWithChildern.some((laneToPast) => laneToPast.id === lane.parentId),
        );

        const lanesBefore: MatrixLane[] = currentLanes.filter(
            (lane, index) => index < minSelectedIndex && !isLaneExist(lane, lanesToPastWithChildern),
        );

        const lanesAfter: MatrixLane[] = currentLanes.filter(
            (lane, index) => index >= minSelectedIndex && !isLaneExist(lane, lanesToPastWithChildern),
        );

        const newLanes: MatrixLane[] = [...lanesBefore, ...lanesToPastWithChildern, ...lanesAfter];

        let newParent: MatrixLane | undefined;
        const lastBeforeLane: MatrixLane = lanesBefore[lanesBefore.length - 1];
        if (
            lastBeforeLane &&
            !haveChildren(lastBeforeLane.id, newLanes) &&
            getCurrentLvl(lastBeforeLane.id, newLanes) === 0
        ) {
            newParent = undefined;
        } else {
            newParent = lanesBefore.findLast((lane) => haveChildren(lane.id, currentLanes));
        }
        lanesForNewParent.forEach((lane) => {
            lane.parentId = newParent?.id;
        });

        if (isColumnPast) {
            matrixData.columns = newLanes;
        } else {
            matrixData.rows = newLanes;
        }
        if (!isMatrixAutofilled) {
            const cells: MatrixCell[] = getCells(matrixData);
            const cellsWithEmptyStyle: MatrixCell[] = cells.filter((cell) => cell.styleIds.length === 0);
            copiedMatrixCells.forEach((copiedCell) => {
                if (copiedCell.styleIds.length !== 0) {
                    const copiedCellsCol: MatrixLane | undefined = matrixData.columns.find(
                        (col) => col.id === copiedCell.columnId,
                    );
                    const copiedCellsRow: MatrixLane | undefined = matrixData.rows.find(
                        (row) => row.id === copiedCell.rowId,
                    );

                    if (!copiedCellsCol || !copiedCellsRow) return;

                    if (copiedCellsCol.linkedNodeId && copiedCellsRow.linkedNodeId) {
                        cellsWithEmptyStyle.forEach((cellWithEmptyStyle) => {
                            const col: MatrixLane | undefined = matrixData.columns.find(
                                (col) => col.id === cellWithEmptyStyle.columnId,
                            );
                            const row: MatrixLane | undefined = matrixData.rows.find(
                                (row) => row.id === cellWithEmptyStyle.rowId,
                            );
                            if (
                                (copiedCellsCol.linkedNodeId === col?.linkedNodeId &&
                                    copiedCellsRow.linkedNodeId === row?.linkedNodeId) ||
                                (copiedCellsCol.linkedNodeId === row?.linkedNodeId &&
                                    copiedCellsRow.linkedNodeId === col?.linkedNodeId)
                            ) {
                                cellWithEmptyStyle.styleIds = [...copiedCell.styleIds];
                            }
                        });
                    } else {
                        const linkedNodeId: string | undefined =
                            copiedCellsCol.linkedNodeId || copiedCellsRow.linkedNodeId;
                        const rowId: string = oldIdToNewIdMap[copiedCell.rowId] || copiedCell.rowId;
                        const colId: string = oldIdToNewIdMap[copiedCell.columnId] || copiedCell.columnId;
                        if (linkedNodeId) {
                            cellsWithEmptyStyle.forEach((cellWithEmptyStyle) => {
                                const col: MatrixLane | undefined = matrixData.columns.find(
                                    (col) => col.id === cellWithEmptyStyle.columnId,
                                );
                                const row: MatrixLane | undefined = matrixData.rows.find(
                                    (row) => row.id === cellWithEmptyStyle.rowId,
                                );
                                if (
                                    (col?.linkedNodeId === linkedNodeId && row?.id === rowId) ||
                                    (row?.linkedNodeId === linkedNodeId && col?.id === colId) ||
                                    (col?.linkedNodeId === linkedNodeId && row?.id === colId) ||
                                    (row?.linkedNodeId === linkedNodeId && col?.id === rowId)
                                ) {
                                    cellWithEmptyStyle.styleIds = [...copiedCell.styleIds];
                                }
                            });
                        } else {
                            const pastedCell: MatrixCell | undefined = cells.find(
                                (cell) => cell.columnId === colId && cell.rowId === rowId,
                            );
                            if (pastedCell) {
                                pastedCell.styleIds = [...copiedCell.styleIds];
                            }
                        }
                    }
                }
            });
        }
    }

    if (whereCopiedFrom.nodeType === TreeItemType.Model) {
        const objectsToAdd: DiagramElement[] = (copiedElements as DiagramElement[]).filter(
            (elem) => elem.type === 'object',
        );

        const parentId: string | undefined = currentLanes[minSelectedIndex]?.parentId;

        const objectDefinitionsToAdd: TTreeEntityState[] = objectsToAdd.map((object) => {
            return objectDefinitions[(object as ObjectInstance).objectDefinitionId || ''];
        });

        const checkObjectDefinition = (objectDefinition: TTreeEntityState) => {
            return (
                !objectDefinition ||
                objectDefinition.type !== TreeItemType.ObjectDefinition ||
                objectDefinition.nodeId.repositoryId !== nodeId.repositoryId ||
                objectDefinition.nodeId.serverId !== nodeId.serverId
            );
        };

        const wrongObjectDefinition = objectDefinitionsToAdd.find((objectDefinition) =>
            checkObjectDefinition(objectDefinition),
        );
        if (wrongObjectDefinition) {
            yield put(
                showNotification({
                    id: uuid(),
                    type: NotificationType.DND_ERROR_WRONG_NODE_TYPE,
                    data: wrongObjectDefinition.type,
                }),
            );

            return;
        }

        const newMatrixLanes: MatrixLane[] = objectDefinitionsToAdd.map((objectDefinition) => {
            return {
                id: uuid(),
                linkedNodeId: objectDefinition.nodeId.id,
                symbolId: objectDefinition.idSymbol,
                text: objectDefinition.multilingualName,
                parentId,
            };
        });
        currentLanes.splice(minSelectedIndex, 0, ...newMatrixLanes);
        if (headerType === HeaderType.column) {
            matrixData.columns = currentLanes;
        }
        if (headerType === HeaderType.row) {
            matrixData.rows = currentLanes;
        }
    }

    yield put(updateMatrixData(nodeId, matrixData));

    if (isMatrixAutofilled) {
        yield put(refreshMatrix(nodeId));
    }
}

function* handleMatrixGetEdgesList(action: TMatrixGetEdgesListAction) {
    const { matrixNodeId, cellId } = action.payload;

    const { rowObjectDefinitionId, colObjectDefinitionId }: TObjectDefinitionsId = yield select(
        MatrixSelectors.objectDefinitionsIdsByCellId(matrixNodeId, cellId),
    );

    if (!rowObjectDefinitionId || !colObjectDefinitionId) return;

    let edgeDefinitionIdsWithEdgeType: TEdgeIdWithType[] = [];
    let edgeInstanceIdsWithEdgeType: TEdgeIdWithType[] = [];

    try {
        yield put(matrixStartLoadingCellData(matrixNodeId));

        const currentLocale = yield select(getCurrentLocale);
        const presetId = yield select(TreeSelectors.presetById(matrixNodeId));
        const edgeTypes: EdgeType[] = yield select(EdgeTypeSelectors.listByPresetId(matrixNodeId.serverId, presetId));

        const allEdgeDefinitions: EdgeDefinitionNode[] =
            yield EdgeDefinitionDAOService.searchAllExistingEdgeDefinitions(
                matrixNodeId,
                rowObjectDefinitionId,
                colObjectDefinitionId,
            );

        yield put(edgeDefinitionsAdd(allEdgeDefinitions));

        edgeDefinitionIdsWithEdgeType = allEdgeDefinitions.map((edgeDefinition: EdgeDefinitionNode) => {
            const currentEdgeType: EdgeType | undefined = edgeTypes.find(
                (edgeType) => edgeType.id === edgeDefinition.edgeTypeId,
            );

            const edgeTypeName =
                LocalesService.internationalStringToString(currentEdgeType?.multilingualName, currentLocale) ||
                currentEdgeType?.name ||
                '';

            return {
                edgeId: edgeDefinition.nodeId.id,
                edgeTypeName,
                edgeTypeId: currentEdgeType?.id || edgeDefinition.edgeTypeId || '',
                isEdgeInctance: false,
                isOutgoingEdge: edgeDefinition.sourceObjectDefinitionId === rowObjectDefinitionId,
                modelAssignments: edgeDefinition.modelAssignments,
            } as TEdgeIdWithType;
        });

        const rowObjectDefenitionNodeId: NodeId = {
            ...matrixNodeId,
            id: rowObjectDefinitionId,
        };
        const edgeInstances: ObjectConnection[] = yield ObjectDefinitionsDAOService.getConnectedEdgeInstances(
            rowObjectDefenitionNodeId,
            colObjectDefinitionId,
        );

        edgeInstanceIdsWithEdgeType = edgeInstances.map((edgeInstance: ObjectConnection) => {
            const currentEdgeType: EdgeType | undefined = edgeTypes.find(
                (edgeType) => edgeType.id === edgeInstance.edgeTypeId,
            );

            const edgeTypeName =
                LocalesService.internationalStringToString(currentEdgeType?.multilingualName, currentLocale) ||
                currentEdgeType?.name ||
                '';

            return {
                edgeId: edgeInstance.edgeInstanceId || '',
                edgeTypeName,
                edgeTypeId: currentEdgeType?.id || edgeInstance.edgeTypeId || '',
                isEdgeInctance: true,
                isOutgoingEdge: !!edgeInstance.isOutgoingEdge,
            } as TEdgeIdWithType;
        });
    } finally {
        yield put(
            matrixAddCellData(matrixNodeId, cellId, {
                edgeDefinitions: edgeDefinitionIdsWithEdgeType,
                edgeInstances: edgeInstanceIdsWithEdgeType,
            }),
        );
    }
}

function* handleDeleteEdgeDefinition(action: TMatrixDeleteEdgeDefinitionAction) {
    const { matrixNodeId, deleteNodeId, cellId } = action.payload;
    const node: EdgeDefinitionNode | undefined = yield nodeService().loadNodeFromServer(deleteNodeId);

    if (!node) return;

    if (node.edgeEntries?.length !== 0) {
        yield put(showNotificationByType(NotificationType.EDGE_DEFINITION_HAS_ENTRIES));

        return;
    }
    yield call(() => TreeDaoService.delete(deleteNodeId));

    const showDeletedObjects: boolean = yield select(getShowDeletedObjectsFilter);

    if (!showDeletedObjects) {
        yield put(treeItemDelete(deleteNodeId));
    }
    yield put(treeItemRefresh(node.parentNodeId));
    yield put(matrixGetEdgesList(matrixNodeId, cellId));
}

function* handleDecompositionIconClick({ payload }: TMatrixDecompositionIconClickAction) {
    const { matrixNodeId: nodeId, edgeDefinitionId, modelAssignments } = payload;

    const edgeNodeId = { ...nodeId, id: edgeDefinitionId } as NodeId;
    const edgeDefinition: EdgeDefinitionNode | undefined = yield select(EdgeDefinitionSelectors.byId(edgeNodeId));

    if (!edgeDefinitionId) return;

    if (modelAssignments.length > 1) {
        const props: TDecompositionsListDialogOwnProps = { edgeDefinition, modelId: nodeId };

        yield put(openDialog(DialogType.DECOMPOSITIONS_LIST_DIALOG, props));
    } else if (modelAssignments.length === 1) {
        const modelAssignment = modelAssignments[0];

        if (modelAssignment?.modelId && modelAssignment?.nodeType) {
            yield put(
                objectDecompositionOpen({
                    nodeId: { ...nodeId, id: modelAssignment.modelId },
                    type: modelAssignment.nodeType as TreeItemType,
                }),
            );
        }
    }
}

function* handleDeleteMatrixObjectHeaders(action: TDeleteMatrixObjectHeaders) {
    const { nodes } = action.payload;

    const matrixTabs: TWorkspaceTab[] = yield select(TabsSelectors.getOpenMatrixTabs);
    const editingMatrixNodeIs: NodeId[] = matrixTabs
        .filter((tab) => tab.mode === EditorMode.Edit)
        .map((tab) => tab.nodeId);
    const matrixArr: MatrixNode[] = yield select(MatrixSelectors.byIds(editingMatrixNodeIs));

    const objectDefinitionIds: string[] = nodes
        .filter((node) => node.type === TreeItemType.ObjectDefinition)
        .map((definition) => definition.nodeId.id);
    const newMatrixArr: MatrixNode[] = deleteMatrixHeadersByObjectDefinitions(matrixArr, objectDefinitionIds);

    yield put(
        batchActions(
            newMatrixArr.map((matrix) => {
                return updateMatrixData(matrix.nodeId, matrix.content);
            }),
        ),
    );
}

function* handleMatrixPastEmptyLane(action: TMatrixPastEmptyLaneAction) {
    const { matrixNodeId, pastOnTheOtherSide } = action.payload;
    const matrix: MatrixNode | undefined = yield select(MatrixSelectors.byId(matrixNodeId));

    if (!matrix?.content) return;

    const { ids: selectedHeaderCellsIds, type: headerType }: TSelectedHeadersCells = yield select(
        MatrixEditorSelectors.getSelectedHeaderCells(matrixNodeId),
    );

    if (selectedHeaderCellsIds.length !== 1) return;

    const currentLanes: MatrixLane[] =
        headerType === HeaderType.column ? [...matrix.content.columns] : [...matrix.content.rows];
    const pastIndex: number = currentLanes.findIndex((lane) => lane.id === selectedHeaderCellsIds[0]);
    const currentLane: MatrixLane = currentLanes[pastIndex];
    const newLaneId = uuid();
    const newLane: MatrixLane = { id: newLaneId, parentId: currentLane.parentId };
    if (pastOnTheOtherSide) {
        currentLanes.splice(pastIndex + 1, 0, newLane);
    } else {
        currentLanes.splice(pastIndex, 0, newLane);
    }

    if (headerType === HeaderType.column) {
        yield put(updateMatrixData(matrixNodeId, { ...matrix.content, columns: currentLanes }));
    } else {
        yield put(updateMatrixData(matrixNodeId, { ...matrix.content, rows: currentLanes }));
    }

    yield put(matrixSelectHeaderCells(matrixNodeId, headerType, [newLaneId]));
}

export function* matrixEditorSaga() {
    yield takeEvery(MATRIX_PAST_OBJECTS, handleMatrixPastObjects);
    yield takeEvery(MATRIX_PAST_EMPTY_LANE, handleMatrixPastEmptyLane);
    yield takeEvery(MATRIX_COPY_LANES, handleCopyMatrixLanes);
    yield takeEvery(MATRIX_GET_EDGES_LIST, handleMatrixGetEdgesList);
    yield takeEvery(MATRIX_DELETE_EDGE_DEFINITION, handleDeleteEdgeDefinition);
    yield takeEvery(MATRIX_HANDLE_DECOMPOSITION_CLICK, handleDecompositionIconClick);
    yield takeEvery(MATRIX_DELETE_OBJECT_HEADERS, handleDeleteMatrixObjectHeaders);
}
