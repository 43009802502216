// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pColor": "#000",
	"pFontWeight": "normal",
	"pFontSize": "14px",
	"h1Color": "var(--Neutral800)",
	"h1FontWeight": "600",
	"h1FontSize": "20px",
	"h2Color": "var(--Neutral800)",
	"h2FontWeight": "400",
	"h2FontSize": "20px",
	"h3Color": "var(--Neutral800)",
	"h3FontWeight": "700",
	"h3FontSize": "16px",
	"h4Color": "var(--Neutral800)",
	"h4FontWeight": "600",
	"h4FontSize": "14px",
	"h5Color": "var(--Neutral600)",
	"h5FontWeight": "600",
	"h5FontSize": "14px",
	"h6Color": "var(--Neutral600)",
	"h6FontWeight": "400",
	"h6FontSize": "12px"
};
export default ___CSS_LOADER_EXPORT___;
