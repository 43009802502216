// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PieChart__lineChartContainer__uOicv{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;height:100%;overflow:hidden}.PieChart__text__Q0HGu{text-align:center;width:100%;height:75%;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;color:#8f8f8f;font-size:20px;font-weight:600;line-height:28px}.PieChart__header__Uhcjk{text-overflow:ellipsis;overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;height:15%;padding:16px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:16px;font-family:Inter;line-height:24px;text-wrap:nowrap}", "",{"version":3,"sources":["webpack://./src/modules/Dashboard/widgets/PieChart/PieChart.scss"],"names":[],"mappings":"AAAA,qCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CAGF,uBACE,iBAAA,CACA,UAAA,CACA,UAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAGF,yBACE,sBAAA,CACA,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,UAAA,CACA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,gBAAA,CACA,gBAAA","sourcesContent":[".lineChartContainer {\r\n  display: flex;\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow: hidden;\r\n}\r\n\r\n.text {\r\n  text-align: center;\r\n  width: 100%;\r\n  height: 75%;\r\n  justify-content: center;\r\n  align-items: center;\r\n  display: flex;\r\n  color: #8f8f8f;\r\n  font-size: 20px;\r\n  font-weight: 600;\r\n  line-height: 28px;\r\n}\r\n\r\n.header {\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n  display: flex;\r\n  width: 100%;\r\n  height: 15%;\r\n  padding: 16px;\r\n  align-items: center;\r\n  font-size: 16px;\r\n  font-family: Inter;\r\n  line-height: 24px;\r\n  text-wrap: nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lineChartContainer": "PieChart__lineChartContainer__uOicv",
	"text": "PieChart__text__Q0HGu",
	"header": "PieChart__header__Uhcjk"
};
export default ___CSS_LOADER_EXPORT___;
