import { Form } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import messages from './MatrixRenameDialog.messages';
import { TRenameDialogProps } from './MatrixRenameDialog.types';
import theme from './MatrixRenameDialog.scss';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { InternationalString } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export function MatrixHeaderRenameDialog(props: TRenameDialogProps) {
    const { open, initName, onClose, onSubmit } = props;

    const [newName, setNewName] = useState<InternationalString>(initName || { ru: '', en: '' });

    const currentLocale = useSelector(getCurrentLocale);
    const currentLocaleName = LocalesService.internationalStringToString(newName, currentLocale);

    const intl = useIntl();
    const [generalForm] = Form.useForm();

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: () => onSubmit(newName),
                    value: intl.formatMessage(messages.submit),
                    visualStyle: 'primary',
                    disabled: !currentLocaleName,
                },
            ]}
        />
    );

    return (
        <>
            <Dialog footer={footer} onCancel={onClose} open={open} width="480px">
                <Form autoComplete='off' className={theme.formContainer} form={generalForm}>
                    <MultiLangInputDialog
                        autoFocus
                        generalForm={generalForm}
                        label={intl.formatMessage(messages.label)}
                        mainInputName="mainInput"
                        placeholder={intl.formatMessage(messages.placeholder)}
                        multiLangValue={initName}
                        onChange={setNewName}
                        required
                    />
                </Form>
            </Dialog>
        </>
    );
}
