import type { TEdgeTypeSelectorState } from '../../../../models/edgeTypeSelectorState.types';
import { Tooltip } from 'antd';
import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeEdgeStyle, changeEdgeType } from '../../../../actions/editor.actions';
import { initialEdgeTypeSelectorState } from '../../../../models/edgeTypeSelectorState';
import { SequenceEdgeTypesId } from '../../../../mxgraph/SequenceGraph/SequenceConstants';
import { getGeneralMenuEdgeTypeSelectorState } from '../../../../selectors/generalMenu.selectors';
import { EdgeTypeSelect } from '../EdgeTypeSelect/EdgeTypeSelect.component';
import menuItemtheme from '../items/MenuItem.scss';
import theme from './GraphObjectToolbar.scss';
import messages from '../../messages/CommonToolbar.messages';
import { MxCell } from '../../../../mxgraph/mxgraph';
import { EdgeArrowSelect } from '../EdgeArrowSelect/EdgeArrowSelect.component';
import {
    PROP_KEY_EDGE_END_ARROW,
    PROP_KEY_EDGE_START_ARROW,
} from '../../../../models/properties/accessible-properties';
import { EdgeLineTypeSelect } from '../EdgeLineTypeSelect/EdgeLineTypeSelect.component';
import icStartArrow from '../../../../resources/icons/toolbar/ic-start-line.svg';
import icEndArrow from '../../../../resources/icons/toolbar/ic-end-line.svg';
import { EdgeStyleDescriptor } from '../../../../models/edge-style';
import { EdgesForChangeSelectors } from '../../../../selectors/edgesForChangeType.selectors';
import { EditorMode } from '../../../../models/editorMode';
import { EdgeInstanceImpl } from '../../../../models/bpm/bpm-model-impl';
import { sortByEdgeTypeName } from '@/modules/AdminTools/Methodology/components/Presets/ModelType/utils/modelTypes.utils';

type TGraphObjectToolbarEdgeSettingsProps = {
    isEntityEditable: boolean;
    selectedEdges: MxCell[];
    compact: boolean;
};

export const GraphObjectToolbarEdgeSettings: FC<TGraphObjectToolbarEdgeSettingsProps> = ({
    isEntityEditable,
    selectedEdges,
    compact,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const edgeTypeSelectorState: TEdgeTypeSelectorState = useSelector(getGeneralMenuEdgeTypeSelectorState);
    const edgeEditDisabled: boolean = !isEntityEditable;
    const selectedEdgeInstances: EdgeInstanceImpl[] = selectedEdges.map((edge) => edge.getValue());
    const someSelectedEdgesAreRecursiveMessage: boolean = edgeTypeSelectorState.availableTypes.some(
        (type) => type.id === SequenceEdgeTypesId.RECURSIVE_MESSAGE,
    );
    const hasAvailableTypes: boolean = edgeTypeSelectorState.availableTypes.length >= 1;
    const tooltipMessage: string = useSelector(
        EdgesForChangeSelectors.getTooltipMessage(selectedEdgeInstances, hasAvailableTypes, EditorMode.Edit),
    );
    const isEdgesBlocked: boolean = !!tooltipMessage;

    const visibleEdgeTypeSelectorState: TEdgeTypeSelectorState = useMemo(() => {
        const availableTypes = edgeTypeSelectorState.availableTypes.filter(
            (type) => type !== null && type.id !== SequenceEdgeTypesId.RECURSIVE_MESSAGE,
        );

        return {
            ...initialEdgeTypeSelectorState,
            ...edgeTypeSelectorState,
            availableTypes,
        };
    }, [edgeTypeSelectorState]);

    const createTooltipMessage = (edgeDefinitionWarning?: string) =>
        (edgeEditDisabled && intl.formatMessage(messages.selectEdge)) ||
        (isEdgesBlocked && edgeDefinitionWarning) ||
        '';

    const handleChangeEdgeType = (type: string) => {
        dispatch(changeEdgeType(type));
    };

    const handleChangeEdgeStyle = (styleKey: string) => (styleValue: string) => {
        selectedEdges.forEach((edge) => {
            dispatch(changeEdgeStyle([styleKey], [styleValue], edge.id));
        });
    };

    const handleChangeLineType = (value: string) => {
        const { styleKeys, styleValues } = EdgeStyleDescriptor.byValue(Number(value));
        selectedEdges.forEach((edge) => {
            dispatch(changeEdgeStyle(styleKeys, styleValues, edge.id));
        });
    };

    return (
        <div className={menuItemtheme.editGroup}>
            <span className={cx(menuItemtheme.spanGroupRow, theme.spanGroupRow, compact && theme.compact)}>
                <span>
                    <Tooltip mouseLeaveDelay={0} title={tooltipMessage}>
                        <div data-test="toolbar_edge-type" className={menuItemtheme.tooltipContainer}>
                            <EdgeTypeSelect
                                compact={compact}
                                onSelect={handleChangeEdgeType}
                                items={sortByEdgeTypeName(visibleEdgeTypeSelectorState.availableTypes)}
                                disabled={edgeEditDisabled || isEdgesBlocked || !hasAvailableTypes}
                            />
                        </div>
                    </Tooltip>
                </span>

                <span>
                    <Tooltip
                        mouseLeaveDelay={0}
                        title={createTooltipMessage(intl.formatMessage(messages.edgeDefinitionStartArrowWarning))}
                    >
                        <div data-test="format-toolbar_edge-start-arrow" className={menuItemtheme.tooltipContainer}>
                            <EdgeArrowSelect
                                icon={icStartArrow}
                                title={intl.formatMessage(messages.startArrow)}
                                compact={compact}
                                onSelect={handleChangeEdgeStyle(PROP_KEY_EDGE_START_ARROW)}
                                disabled={edgeEditDisabled || isEdgesBlocked || !hasAvailableTypes}
                            />
                        </div>
                    </Tooltip>
                </span>
            </span>

            <span className={cx(menuItemtheme.spanGroupRow, theme.spanGroupRow, compact && theme.compact)}>
                <span>
                    <Tooltip mouseLeaveDelay={0} title={createTooltipMessage()}>
                        <div data-test="format-toolbar_edge-line" className={menuItemtheme.tooltipContainer}>
                            <EdgeLineTypeSelect
                                compact={compact}
                                onSelect={handleChangeLineType}
                                disabled={
                                    edgeEditDisabled || someSelectedEdgesAreRecursiveMessage || !hasAvailableTypes
                                }
                            />
                        </div>
                    </Tooltip>
                </span>

                <span>
                    <Tooltip
                        mouseLeaveDelay={0}
                        title={createTooltipMessage(intl.formatMessage(messages.edgeDefinitionEndArrowWarning))}
                    >
                        <div data-test="format-toolbar_edge-end-arrow" className={menuItemtheme.tooltipContainer}>
                            <EdgeArrowSelect
                                icon={icEndArrow}
                                title={intl.formatMessage(messages.endArrow)}
                                compact={compact}
                                onSelect={handleChangeEdgeStyle(PROP_KEY_EDGE_END_ARROW)}
                                disabled={
                                    edgeEditDisabled ||
                                    isEdgesBlocked ||
                                    someSelectedEdgesAreRecursiveMessage ||
                                    !hasAvailableTypes
                                }
                            />
                        </div>
                    </Tooltip>
                </span>
            </span>
        </div>
    );
};
