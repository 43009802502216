import { has, cloneDeep } from 'lodash-es';
import { KanbanCardSectorSettings } from '../../../../../../serverapi/api';
import { KanbanHorizontalAlign, KanbanVerticalAlign } from '../../../../../../models/kanban.types';
import { TSectorPosition } from '../ObjectType/KanbanCardEditor/KanbanCardEditor.types';

export const COLUMN_NUMBER_TO_NAME_MAP = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
};

const getRangeOfSectorPositions = (positionA: TSectorPosition, positionB: TSectorPosition): TSectorPosition => {
    return {
        start: {
            column: Math.min(positionA.start.column, positionB.start.column),
            row: Math.min(positionA.start.row, positionB.start.row),
        },
        end: {
            column: Math.max(positionA.end.column, positionB.end.column),
            row: Math.max(positionA.end.row, positionB.end.row),
        },
    };
};

export const getRangeAndSectorsToHighlight = (
    pressedSector: KanbanCardSectorSettings,
    hoveredSector: KanbanCardSectorSettings,
    allSectorsSettings: KanbanCardSectorSettings[],
): { highlightRange: TSectorPosition; sectorsToHighlight: KanbanCardSectorSettings[] } => {
    let currentRange: TSectorPosition = getRangeOfSectorPositions(pressedSector.position, hoveredSector.position);
    let newRange: TSectorPosition = cloneDeep(currentRange);
    let isRangeChanged: boolean = true;
    const sectorsToHighlight: KanbanCardSectorSettings[] = [pressedSector, hoveredSector];

    while (isRangeChanged) {
        allSectorsSettings.forEach(({ name, position, styles }) => {
            if (sectorsToHighlight.find((sector) => sector.name === name)) return;

            if (
                (position.start.column >= currentRange.start.column &&
                    position.start.column <= currentRange.end.column &&
                    position.start.row >= currentRange.start.row &&
                    position.start.row <= currentRange.end.row) ||
                (position.end.column >= currentRange.start.column &&
                    position.end.column <= currentRange.end.column &&
                    position.end.row >= currentRange.start.row &&
                    position.end.row <= currentRange.end.row)
            ) {
                sectorsToHighlight.push({ name, position, styles });
                newRange = getRangeOfSectorPositions(newRange, position);
            }
        });

        if (
            newRange.start.column !== currentRange.start.column ||
            newRange.end.column !== currentRange.end.column ||
            newRange.start.row !== currentRange.start.row ||
            newRange.end.row !== currentRange.end.row
        ) {
            currentRange = cloneDeep(newRange);
        } else {
            isRangeChanged = false;
        }
    }

    return {
        highlightRange: newRange,
        sectorsToHighlight,
    };
};

export const getSectorSettingsOnSplit = (
    sectorToSplit: KanbanCardSectorSettings,
    sectorSettings: KanbanCardSectorSettings[],
): KanbanCardSectorSettings[] => {
    const {
        position: { start, end },
    } = sectorToSplit;

    const leftUpperSplitedSector = {
        ...sectorToSplit,
        position: {
            start: {
                row: start.row,
                column: start.column,
            },
            end: {
                row: start.row,
                column: start.column,
            },
        },
    };

    const splitedSectors: KanbanCardSectorSettings[] = [leftUpperSplitedSector];

    for (let column = start.column; column <= end.column; column++) {
        for (let row = start.row; row <= end.row; row++) {
            if (column === start.column && row === start.row) continue;
            splitedSectors.push({
                name: `${COLUMN_NUMBER_TO_NAME_MAP[column]}${row}`,
                position: {
                    start: {
                        column,
                        row,
                    },
                    end: {
                        column,
                        row,
                    },
                },
                styles: {
                    verticalAlign: KanbanVerticalAlign.CENTER,
                    horizontalAlign: KanbanHorizontalAlign.CENTER,
                    padding: {
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: 5,
                    },
                    backgroundColor: '#f5f5f5',
                    border: {
                        top: true,
                        right: true,
                        bottom: true,
                        left: true,
                        color: '#b3b3b3',
                        width: 2,
                    },
                },
            });
        }
    }

    const newSectorSettings = [
        ...splitedSectors,
        ...sectorSettings.filter((sector) => sector.name !== sectorToSplit.name),
    ];

    return newSectorSettings;
};

export const getSectorSettingsOnMerge = (
    highlightedRange: TSectorPosition,
    highLightedSectors: KanbanCardSectorSettings[],
    sectorSettings: KanbanCardSectorSettings[],
): { newCardSectorSettings: KanbanCardSectorSettings[]; deletedSectorNames: string[] } => {
    const {
        start: { column, row },
    } = highlightedRange;
    const leftUpperSector = highLightedSectors.find(
        (sector) => column === sector.position.start.column && row === sector.position.start.row,
    );
    if (!leftUpperSector) return { newCardSectorSettings: sectorSettings, deletedSectorNames: [] };

    const mergedSector: KanbanCardSectorSettings = cloneDeep(leftUpperSector);
    mergedSector.position = highlightedRange;
    const newCardSectorSettings: KanbanCardSectorSettings[] = [mergedSector];

    newCardSectorSettings.push(
        ...sectorSettings.filter((sector) => !highLightedSectors.find(({ name }) => sector.name === name)),
    );
    const deletedSectorNames = highLightedSectors
        .filter(({ name }) => name !== mergedSector.name)
        .map(({ name }) => name);

    return { newCardSectorSettings, deletedSectorNames };
};

export const DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID = 'defaultKanbanCardType';
export const KANBAN_CARD = 'kanbanCard';

export const isKanbanCardType = (obj) => has(obj, 'sectorSettings');
export const isKanbanBoardType = (obj) => obj?.discriminator === 'KANBAN' || has(obj, 'availableSymbolsCardTypesIds');
