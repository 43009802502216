import { TReducer } from '../utils/types';
import { CONTEXT_MENU_CHANGE_PARENT, CONTEXT_MENU_CLEAR_PARENT } from '../actionsTypes/TreeItem.actionsTypes';
import { TContextMenuReducerState } from './contextMenu.reducer.types';

const initial: TContextMenuReducerState = {};

export const contextMenuReducer: TReducer<TContextMenuReducerState> = (state = initial, action) => {
    switch (action.type) {
        case CONTEXT_MENU_CHANGE_PARENT: {
            const { parentId, contextMenuAreaName } = action.payload;

            return {
                [contextMenuAreaName]: parentId,
            };
        }

        case CONTEXT_MENU_CLEAR_PARENT: {
            return initial;
        }

        default: {
            return state;
        }
    }
};
