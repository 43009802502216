import { defineMessages } from 'react-intl';

export default defineMessages({
    notificationTemplates: {
        id: 'notificationTemplates',
        defaultMessage: 'Шаблоны уведомлений',
    },
    notificationSettings: {
        id: 'notificationSettings',
        defaultMessage: 'Настройки уведомлений',
    },
    messages: {
        id: 'messages',
        defaultMessage: 'Сообщения',
    },
});
