import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';
import { IProfile } from '../../sagas/types/notation.types';

export class ProfileDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getCurrentUserProfileAcls(presetId: string): Promise<IProfile> {
        const api = this.getApi();
        const profile = await api.profile.getCurrentUserProfileAcls({ presetId });

        return {profile, presetId};
    }
}