import { AuthorizationMethod } from '@/models/security/authorizationMethod.types';

export const SAVE_TIMEOUT: number = 10 * 1000;

export const LOCK_TIMEOUT: number = 30 * 1000;

export const PNG = '/png';

export const HTTPS = 'https://';

export const HTTP = 'http://';

export const HASH_LINK = '/#link/';

export const KEYCLOAK_PARAM = '?auth=keycloak';

export const KERBEROS_SSO_PARAM = '/sso';

export const HASH_LINK_WITH_KERBEROS_SSO = `${KERBEROS_SSO_PARAM}#link/`;

export const HASH_LINK_WITH_KEYCLOAK = `${KEYCLOAK_PARAM}#link/`;

export const API_PUBLIC = '/api/public/';

export const NAVIGATOR_STRUCTURE = 'NavigatorStructure';

export const MAX_GUID_LENGTH = 100;

export const MODEL = 'model';

export const UNKNOWN_ATTRIBUTE_TYPE = {
    id: 'unknown',
    presetId: 'unknownPresetId',
};

export const LINK_TYPE_TO_HASH_LINK_MAP = {
    [AuthorizationMethod.KERBEROS]: HASH_LINK_WITH_KERBEROS_SSO,
    [AuthorizationMethod.KEYCLOAK]: HASH_LINK_WITH_KEYCLOAK,
    [AuthorizationMethod.DB]: HASH_LINK,
};
