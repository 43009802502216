import React, { FC } from 'react';
import cx from 'classnames';
import theme from '../NavigatorPanel.scss';

type TNavigatorPanelButtonProps = {
    onClick?: () => void;
    IconComponent: React.ComponentType;
    active?: boolean;
    dataTest?: string;
};

export const NavigatorPanelButton: FC<TNavigatorPanelButtonProps> = ({ onClick, IconComponent, active, dataTest }) => {
    return (
        <div className={cx(theme.action, active && theme.actionActive)} onClick={onClick} data-test={dataTest}>
            <IconComponent />
        </div>
    );
};
