import React, { FC } from 'react';
import { Slider } from 'antd';
import theme from './ScaleSlider.scss';
import { ZoomValue } from './ZoomValue.component';
import icDistributeVert from '../../../../resources/icons/ic-fit.svg';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';

type TScaleSliderProps = {
    value: number;
    onChange(value: number): void;
    onFitClick(): void;
};

const marks = {
    100: '',
};

const DEFAULT_SCALE = 100;
const ZOOM_STEP = 10;
const { MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL } = BPMMxGraph;

export const ScaleSlider: FC<TScaleSliderProps> = (props) => {
    const onMinusClick = () => {
        props.onChange(Math.max(props.value - ZOOM_STEP, MIN_ZOOM_LEVEL));
    };

    const onPlusClick = () => {
        props.onChange(Math.min(props.value + ZOOM_STEP, MAX_ZOOM_LEVEL));
    };

    const value = props.value || DEFAULT_SCALE;

    return (
        <div className={theme.container}>
            <div className={theme.minus} onClick={onMinusClick}>
                {' '}
                -{' '}
            </div>
            <div className={theme.sliderContainer}>
                <Slider
                    className={theme.sliderControl}
                    marks={marks}
                    included={false}
                    value={value}
                    onChange={props.onChange}
                    min={MIN_ZOOM_LEVEL}
                    max={MAX_ZOOM_LEVEL}
                />
            </div>
            <div className={theme.plus} onClick={onPlusClick}>
                {' '}
                +{' '}
            </div>

            <div data-test="editorWindow_scale_value">
                <ZoomValue value={value} onChange={props.onChange} />
            </div>

            <div className={theme.fit} onClick={props.onFitClick}>
                <Icon spriteSymbol={icDistributeVert} />
            </div>
        </div>
    );
};
