import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { deleteAuditRange, deleteSessionRange } from '../../../../actions/audit.actions';
import { TClearJournalFullProps } from './ClearJournal.types';
import { ClearJournal } from './ClearJournal.component';
import { withWindowResize } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';

const mapStateToProps = (state: TRootState): Partial<TClearJournalFullProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);

    return {
        serverId,
    };
};

const mapDispatchToProps = {
    deleteAudit: deleteAuditRange,
    deleteSession: deleteSessionRange,
};

export const ClearJournalContainer = connect(mapStateToProps, mapDispatchToProps)(withWindowResize(ClearJournal));
