import { TReducer } from '../../utils/types';
import { DELETE_PRESET_IMAGE_GROUP_SUCCESS } from '../../actionsTypes/presetSettings/presetImageGroup.actionTypes';
import { CANCEL_PRESET_EDIT } from '../../actionsTypes/methodologySetting.actionTypes';
import { TDeletedPresetImageGroupState } from './deletedPresetImageGroup.reducer.types';

const initial: TDeletedPresetImageGroupState = {
    byServerId: {},
};

export const deletedPresetImageGroupReducer: TReducer<TDeletedPresetImageGroupState> = (state = initial, action) => {
    const cloneState = (serverId: string) => {
        return {
            byServerId: {
                ...state.byServerId,
                [serverId]: {
                    byPresetId: {
                        ...(state.byServerId[serverId]?.byPresetId || {}),
                    },
                },
            },
        };
    };

    switch (action.type) {
        case DELETE_PRESET_IMAGE_GROUP_SUCCESS: {
            const {
                presetImageGroups,
                serverNode: {
                    nodeId: { serverId },
                },
            } = action.payload;
            if (presetImageGroups) {
                const stateClone = cloneState(serverId);
                presetImageGroups.forEach((presetImageGroup) => {
                    const { presetId } = presetImageGroup;
                    if (serverId && presetId) {
                        const byPresetId = stateClone.byServerId[serverId]?.byPresetId;
                        byPresetId[presetId] = byPresetId[presetId]?.filter((otg) => otg.id !== presetImageGroup.id);
                    }
                });

                return stateClone;
            }

            return state;
        }

        case CANCEL_PRESET_EDIT: {
            const {
                preset,
                serverNode: {
                    nodeId: { serverId },
                },
            } = action.payload;
            if (serverId && preset?.id) {
                const stateClone = cloneState(serverId);
                delete stateClone.byServerId[serverId]?.byPresetId[preset?.id];

                return stateClone;
            }

            return state;
        }
        default:
            return state;
    }
};
