// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigatorEdgeTab__link__eNePq{cursor:pointer;text-decoration:underline;color:var(--secondColor)}.NavigatorEdgeTab__list__h4Y_E .ant-list-header{padding:10px}.NavigatorEdgeTab__list__h4Y_E .ant-list-item{padding:10px}.NavigatorEdgeTab__list__h4Y_E .ant-list-items{border-bottom:1px solid var(--Neutral100)}.NavigatorEdgeTab__header__YKXkn{border-top:4px solid var(--Neutral100)}", "",{"version":3,"sources":["webpack://./src/modules/Navigator/components/NavigatorProperties/NavigatorEdgeTab/NavigatorEdgeTab.scss"],"names":[],"mappings":"AAAA,+BACI,cAAA,CACA,yBAAA,CACA,wBAAA,CAKI,gDACI,YAAA,CAEJ,8CACI,YAAA,CAEJ,+CACI,yCAAA,CAKZ,iCACI,sCAAA","sourcesContent":[".link {\r\n    cursor: pointer;\r\n    text-decoration: underline;\r\n    color: var(--secondColor);\r\n}\r\n\r\n.list {\r\n    :global {\r\n        .ant-list-header {\r\n            padding: 10px;\r\n        }\r\n        .ant-list-item {\r\n            padding: 10px;\r\n        }\r\n        .ant-list-items {\r\n            border-bottom: 1px solid var(--Neutral100);\r\n        }\r\n    }\r\n}\r\n\r\n.header {\r\n    border-top: 4px solid var(--Neutral100);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "NavigatorEdgeTab__link__eNePq",
	"list": "NavigatorEdgeTab__list__h4Y_E",
	"header": "NavigatorEdgeTab__header__YKXkn"
};
export default ___CSS_LOADER_EXPORT___;
