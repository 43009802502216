// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttributeRules__rulesContainer__N4jT5{display:-webkit-box;display:-ms-flexbox;display:flex;grid-gap:15px;gap:15px;min-width:calc(100% - 32px);max-width:calc(100% - 32px);margin-bottom:22px;min-height:30px;max-height:60px}.AttributeRules__ruleSelect__T4Ybd{min-width:50%;max-width:50%;height:32px}.AttributeRules__ruleInput__XkA0Q{width:46%;height:32px}.AttributeRules__ruleInput__XkA0Q .ant-select-selection-overflow{max-height:60px;overflow:auto}.AttributeRules__ruleTitle___whbo{margin-bottom:5px}", "",{"version":3,"sources":["webpack://./src/modules/FloatingAttributes/components/AttributesEditor/AttributeRules/AttributeRules.scss"],"names":[],"mappings":"AAAA,uCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,aAAA,CAAA,QAAA,CACA,2BAAA,CACA,2BAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CAGJ,mCACI,aAAA,CACA,aAAA,CACA,WAAA,CAGJ,kCACI,SAAA,CACA,WAAA,CAEI,iEACI,eAAA,CACA,aAAA,CAKZ,kCACI,iBAAA","sourcesContent":[".rulesContainer {\r\n    display: flex;\r\n    gap: 15px;\r\n    min-width: calc(100% - 32px);\r\n    max-width: calc(100% - 32px);\r\n    margin-bottom: 22px;\r\n    min-height: 30px;\r\n    max-height: 60px;\r\n}\r\n\r\n.ruleSelect {\r\n    min-width: 50%;\r\n    max-width: 50%;\r\n    height: 32px;\r\n}\r\n\r\n.ruleInput {\r\n    width: 46%;\r\n    height: 32px;\r\n    :global {\r\n        .ant-select-selection-overflow {\r\n            max-height: 60px;\r\n            overflow: auto;\r\n        }\r\n    }\r\n}\r\n\r\n.ruleTitle {\r\n    margin-bottom: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rulesContainer": "AttributeRules__rulesContainer__N4jT5",
	"ruleSelect": "AttributeRules__ruleSelect__T4Ybd",
	"ruleInput": "AttributeRules__ruleInput__XkA0Q",
	"ruleTitle": "AttributeRules__ruleTitle___whbo"
};
export default ___CSS_LOADER_EXPORT___;
