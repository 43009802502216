export const PRESET_SETTINGS_CREATE_MATRIX_MODEL_TYPE = 'PRESET_SETTINGS_CREATE_MATRIX_MODEL_TYPE';

export const PRESET_SETTINGS_SUBMIT_MATRIX_MODEL_TYPE = 'PRESET_SETTINGS_SUBMIT_MATRIX_MODEL_TYPE';

export const PRESET_SETTINGS_GET_ALL_MATRIX_MODEL_TYPES_SUCCESS = 'PRESET_SETTINGS_GET_ALL_MATRIX_MODEL_TYPES_SUCCESS';

export const PRESET_SETTINGS_ADD_MATRIX_MODEL_TYPE = 'PRESET_SETTINGS_ADD_MATRIX_MODEL_TYPE';

export const PRESET_SETTINGS_DELETE_MATRIX_MODEL_TYPE = 'PRESET_SETTINGS_DELETE_MATRIX_MODEL_TYPE';

export const PRESET_SETTINGS_TRANSFER_MATRIX_MODEL_TYPE = 'PRESET_SETTINGS_TRANSFER_MATRIX_MODEL_TYPE';

export const PRESET_SETTINGS_EDIT_MATRIX_MODEL_TYPE = 'PRESET_SETTINGS_EDIT_MATRIX_MODEL_TYPE';

export const PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_MATRIX_MODEL_TYPE =
    'PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_MATRIX_MODEL_TYPE';
