import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { PRESET_SETTINGS_INITIAL_REPORT_MODEL_TYPE_SERVER_STATE } from '@/reducers/presetSettings/presetSettingsReportModelType.reducer';

const reportModelTypeStateSelector = (state: TRootState) => {
    return state.presetSettings.reportModelTypes;
};

export namespace PresetSettingsReportModelTypeSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector(reportModelTypeStateSelector, (state) => {
            return state?.byPresetId?.[presetId] || PRESET_SETTINGS_INITIAL_REPORT_MODEL_TYPE_SERVER_STATE;
        });

    export const listByPresetId = (presetId: string) =>
        createSelector(byPresetId(presetId), (state) => Object.values(state.byId));
}
