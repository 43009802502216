import { DuplicatesElementInfo } from "@/serverapi/api";

export type TGroupItem = { main: boolean; combine: boolean };

export type TGroupItems = { [elementId: string]: TGroupItem };

export type TGroupItemUpdater   = (item: TGroupItem, elementId: string) => TGroupItem;

export type TNewSelectedItemsByGroup = {
    [groupKey: string]: TGroupItems;
};


export type TElementInfoWithStatus = {
    status: string;
} & DuplicatesElementInfo

export enum MainStrategyType {
    Auto = 'AUTOMATIC_INSTANCE_COUNT',
    Oldest = 'OLDEST_DEFINITION',
    Skip = 'SKIP',
}

export enum CheckboxType {
    Main = 'main',
    Combine = 'combine',
}
