import {
    previousActionEnum,
    TConstraintsPoint,
} from '@/modules/AdminTools/Methodology/components/Presets/ObjectType/SymbolEditorTab/SymbolEditorTab.types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import messages from '../../../ObjectType.messages';
import {
    floatToIntegerConverter,
    integerToFloatConverter,
} from '@/modules/AdminTools/Methodology/components/Presets/util/preset.utils';

import theme from './PointsTab.scss';
import { Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import { get, template } from 'lodash-es';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

interface IPointsTabProps {
    constraintPoints: TConstraintsPoint[];
    previousAction: previousActionEnum;
    onChangeConstraintPoints: React.Dispatch<React.SetStateAction<TConstraintsPoint[]>>;
    isDoubleConstraintPoints: boolean;
    onChangeIsDoubleConstraintPoints: (isDoubleConstraintPoints: boolean) => void;
    switchToEditPointsTab: () => void;
    addConstraintsPoint: (constraints: TConstraintsPoint) => void;
    deleteConstraintsPoint: (pointId: string) => void;
    onChangePreviousAction: (action: previousActionEnum) => void;
}

export const PointsTab = (props: IPointsTabProps) => {
    const intl = useIntl();
    const {
        constraintPoints,
        previousAction,
        onChangeConstraintPoints,
        onChangeIsDoubleConstraintPoints,
        switchToEditPointsTab,
        addConstraintsPoint,
        deleteConstraintsPoint,
        onChangePreviousAction,
        isDoubleConstraintPoints,
    } = props;

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            name: '',
            coordinatesX: '',
            coordinatesY: '',
        },
    });

    const newPointId: string = uuid();

    const changeConstraintPoints = (newPoint: TConstraintsPoint): void => {
        onChangeConstraintPoints((state) => [...state, { ...newPoint }]);
    };

    const checkDoublePoints = (newPoint: TConstraintsPoint): boolean => {
        onChangeIsDoubleConstraintPoints(false);

        return constraintPoints.some((point) => {
            return (
                Number(newPoint.coordinatesX) === Number(point.coordinatesX) &&
                Number(newPoint.coordinatesY) === Number(point.coordinatesY)
            );
        });
    };

    useEffect((): void => {
        switchToEditPointsTab();
    }, []);

    const requiredMessageTemplate = template(intl.formatMessage(messages.validationPointsRequired));
    const validationNumberMessage = template(intl.formatMessage(messages.validationPointsNumber))({
        min: -100,
        max: 100,
    });

    const onFinish = (values: TConstraintsPoint): void => {
        const coordinatesX = integerToFloatConverter(values.coordinatesX);
        const coordinatesY = integerToFloatConverter(values.coordinatesY);
        const point = { ...values, coordinatesX, coordinatesY, id: newPointId };
        const isDouble = checkDoublePoints(point);

        if (isDouble) {
            onChangeIsDoubleConstraintPoints(true);

            return;
        }

        changeConstraintPoints(point);

        addConstraintsPoint({ ...point });
    };

    const deletePointLabel = (pointId: string): void => {
        onChangeConstraintPoints((state) => state.filter((point) => point.id !== pointId));
        deleteConstraintsPoint(pointId);
    };

    const labelsPoints = constraintPoints.map((point) => {
        const showLabels = () => {
            return (
                <div className={theme.pointsTab_label}>
                    {`${intl.formatMessage(messages.nameConstraintPoints)}: `}
                    <span>{point.name}</span>
                    {`, ${intl.formatMessage(messages.coordinatePointX)}: `}
                    <span>{floatToIntegerConverter(point.coordinatesX)}</span>
                    {`, ${intl.formatMessage(messages.coordinatePointY)}: `}
                    <span>{floatToIntegerConverter(point.coordinatesY)}</span>
                </div>
            );
        };

        return (
            <div
                data-test={`point_coordinates_${point.name}_container`}
                className={theme.pointsTab_labelBlockWrapper_labelBlock}
                key={point.id}
            >
                <Row key={point.id} align="middle">
                    <Col span={18}>{showLabels()}</Col>
                    <Col span={4}>
                        <Button
                            disabled={constraintPoints.length === 1}
                            dataTest={`delete_point_btn${constraintPoints.length === 1 ? '_disabled' : ''}`}
                            width={85}
                            visualStyle="primary"
                            onClick={() => {
                                deletePointLabel(point.id);
                                onChangePreviousAction(previousActionEnum.DELETE);
                            }}
                        >
                            {intl.formatMessage(messages.deleteButton)}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    });

    return (
        <div className={theme.pointsTab_wrapper}>
            <form autoComplete='off' name="pointsLabel" onSubmit={handleSubmit(onFinish)} className={theme.pointsTabForm}>
                <Input
                    {...register('name', {
                        required: requiredMessageTemplate({ label: intl.formatMessage(messages.nameConstraintPoints) }),
                    })}
                    label={intl.formatMessage(messages.nameConstraintPoints)}
                    data-test="name_dot_input"
                    error={get(errors, 'name.message')}
                />

                <Input
                    {...register('coordinatesX', {
                        required: requiredMessageTemplate({ label: 'X' }),
                        valueAsNumber: true,
                        min: {
                            value: -100,
                            message: validationNumberMessage,
                        },
                        max: {
                            value: 100,
                            message: validationNumberMessage,
                        },
                    })}
                    type="number"
                    error={get(errors, 'coordinatesX.message')}
                    label="X"
                    data-test="x-coordinate-dot_input"
                />

                <Input
                    {...register('coordinatesY', {
                        required: requiredMessageTemplate({ label: 'Y' }),
                        valueAsNumber: true,
                        min: {
                            value: -100,
                            message: validationNumberMessage,
                        },
                        max: {
                            value: 100,
                            message: validationNumberMessage,
                        },
                    })}
                    type="number"
                    error={get(errors, 'coordinatesY.message')}
                    label="Y"
                    data-test="y-coordinate-dot_input"
                />

                <Button dataTest="add-new-dot_btn" visualStyle="primary" type="submit">
                    {intl.formatMessage(messages.addButton)}
                </Button>
            </form>
            <div className={theme.pointsTab_labelBlockWrapper}>
                {previousAction === previousActionEnum.DELETE && constraintPoints.length === 1 && (
                    <p className={theme.pointsTab_labelBlockWrapper_errorMessage}>
                        {intl.formatMessage(messages.countPointsValidation)}
                    </p>
                )}
                {isDoubleConstraintPoints && (
                    <p className={theme.pointsTab_labelBlockWrapper_errorMessage}>
                        {intl.formatMessage(messages.doublePointsValidation)}
                    </p>
                )}
                {labelsPoints}
            </div>
        </div>
    );
};
