import { MxGeometry } from '../mxgraph.d';
import { BPMMxGraph } from '../bpmgraph';
import { v4 as uuid } from 'uuid';
import { MxConstants, MxUtils, MxPerimeter, MxCell } from '../mxgraph';
import { LayoutInstanceImpl } from '../../models/bpm/bpm-model-impl';
import { PsdTable, PsdCell, psdCellType } from './psdTable';
import { isUndefined, isArray } from 'is-what';
import { union } from 'lodash-es';
import { BPMMxConstants } from '../bpmgraph.constants';

export class BPMPSDDiagram {
    metaData: PsdTable;
    isLoaded: boolean = false;
    graph: BPMMxGraph;
    labelWidth: number = 20;
    labelHeight: number = 20;
    offsetsForCellX: number = 20;
    offsetsForCellY: number = 42;
    columnWidth: number = 200;
    columnCount: number = 0;
    rowHeight: number = 200;
    rowCount: number = 0;
    mainTable: MxCell;
    cellsMatrix: MxCell | null[][] = [];
    labelMatrix: MxCell | null[][] = [];

    private static isNotUndefinedAndNull<T>(arg: T): boolean {
        return !!(!isUndefined(arg) && arg !== null);
    }

    constructor(metaData: string, isLoaded: boolean, graph: BPMMxGraph) {
        this.metaData = new PsdTable(metaData);
        this.isLoaded = isLoaded;
        this.graph = graph;
        this.initStyleForDiagram();
    }

    // start method should be removed after finishing
    init() {
        const model = this.graph.getModel();
        const parent = this.graph.getDefaultParent();

        this.graph.setResizeContainer(false);
        this.graph.graphHandler.setRemoveCellsFromParent(false);
        this.graph.setDropEnabled(true);
        this.graph.setSplitEnabled(false);
        // Adds cells to the model in a single step
        if (this.isLoaded) {
            model.beginUpdate();
            try {
                this.columnCount = this.metaData.columns.length;
                const countRow = this.metaData.rows.length;
                this.mainTable = this.graph.insertVertex(
                    parent,
                    uuid(),
                    this.metaData.name,
                    0,
                    0,
                    this.labelWidth,
                    this.labelHeight,
                    'swimlane',
                );
                this.mainTable.setConnectable(false);
                this.createLayoutInstance(this.mainTable, {
                    name: this.metaData.name,
                    allowedSymbols: [],
                    type: psdCellType.MAIN_TABLE,
                    rowIndex: 0,
                    columnIndex: 0,
                });

                for (let rowIndex = 0; rowIndex < countRow; rowIndex++) {
                    this.addRow(rowIndex, false);
                }
            } finally {
                // Updates the display
                this.isLoaded = false;
                model.endUpdate();
            }
        }
    }

    addRow(rowIndex: number, isAbove: boolean) {
        const model = this.graph.getModel();
        // if (isAbove) {
        model.beginUpdate();
        try {
            // move all existing cells
            this.rowCount++;
            this.resizeMainTable();
            this.recalculatePositionInMatrix(rowIndex, true, true);
            this.moveCells(rowIndex, true, true);

            for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
                if (isUndefined(this.cellsMatrix[rowIndex])) {
                    this.cellsMatrix[rowIndex] = [];
                    this.labelMatrix[rowIndex] = [];
                }
                // insert header first
                if (columnIndex === 0) {
                    if (this.isLoaded) {
                        this.addVerticalHeader(
                            rowIndex,
                            columnIndex,
                            this.metaData.rows[rowIndex]
                                ? {
                                      ...this.metaData.rows[rowIndex],
                                      type: psdCellType.VERTICAL_HEADER,
                                      rowIndex,
                                      columnIndex,
                                  }
                                : null,
                        );
                    } else {
                        this.addVerticalHeader(rowIndex, columnIndex, null);
                    }
                }
                if (rowIndex === 0) {
                    if (this.isLoaded) {
                        this.addHorizontalHeader(
                            rowIndex,
                            columnIndex,
                            this.metaData.columns[columnIndex]
                                ? {
                                      ...this.metaData.columns[columnIndex],
                                      type: psdCellType.HORIZONTAL_HEADER,
                                      rowIndex,
                                      columnIndex,
                                  }
                                : null,
                        );
                    } else {
                        this.addHorizontalHeader(rowIndex, columnIndex, null);
                    }
                }
                // insert cells
                this.addCell(rowIndex, columnIndex);
            }
        } finally {
            // Updates the display
            model.endUpdate();
        }
    }

    removeRow(rowIndex: number) {
        if (rowIndex === 0) {
            alert('You cant remove first row');
        } else {
            this.rowCount--;
            this.graph.removeCells(this.cellsMatrix[rowIndex]);
            this.graph.removeCells(this.labelMatrix[rowIndex]);
            this.recalculatePositionInMatrix(rowIndex, false, true);
            this.moveCells(rowIndex, false, true);
            this.resizeMainTable();
            this.metaData.rows.slice(rowIndex, 1);
        }
    }

    addColumn(columnIndex: number, isAbove: boolean) {
        const model = this.graph.getModel();
        model.beginUpdate();
        try {
            // move all existing cells
            this.columnCount++;
            this.resizeMainTable();
            this.recalculatePositionInMatrix(columnIndex, true, false);
            this.moveCells(columnIndex, true, false);
            for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
                if (rowIndex === 0) {
                    if (this.isLoaded) {
                        this.addHorizontalHeader(
                            rowIndex,
                            columnIndex,
                            this.metaData.columns[columnIndex]
                                ? {
                                      ...this.metaData.columns[columnIndex],
                                      type: psdCellType.HORIZONTAL_HEADER,
                                      rowIndex,
                                      columnIndex,
                                  }
                                : null,
                        );
                    } else {
                        this.addHorizontalHeader(rowIndex, columnIndex, null);
                    }
                }
                this.addCell(rowIndex, columnIndex);
            }
        } finally {
            model.endUpdate();
        }
    }

    removeColumn(index: number) {
        if (index === 0) {
            alert("You can't remove first column");
        } else {
            this.columnCount--;
            const removedCells = [];
            for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
                // @ts-ignore
                removedCells.push(this.cellsMatrix[rowIndex][index]);
            }
            // @ts-ignore
            removedCells.push(this.labelMatrix[0][index]);
            this.graph.removeCells(removedCells);
            this.recalculatePositionInMatrix(index, false, false);
            this.moveCells(index, false, false);
            this.resizeMainTable();
            this.metaData.columns.splice(index, 1);
        }
    }

    findIndexForCell(cell: MxCell, isRow: boolean): number {
        if (isRow) {
            for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
                for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
                    const cellInArray = this.cellsMatrix[rowIndex][columnIndex];
                    const cellInLabelArray = this.labelMatrix[rowIndex][columnIndex];
                    if (BPMPSDDiagram.isNotUndefinedAndNull(cellInArray)) {
                        if (cellInArray.getId() === cell.getId()) {
                            return rowIndex;
                        }
                    }
                    if (BPMPSDDiagram.isNotUndefinedAndNull(cellInLabelArray)) {
                        if (cellInLabelArray.getId() === cell.getId()) {
                            return rowIndex;
                        }
                    }
                }
            }
        } else {
            for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
                for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
                    const cellInArray = this.cellsMatrix[rowIndex][columnIndex];
                    const cellInLabelArray = this.labelMatrix[rowIndex][columnIndex];
                    if (BPMPSDDiagram.isNotUndefinedAndNull(cellInArray)) {
                        if (cellInArray.getId() === cell.getId()) {
                            return columnIndex;
                        }
                    }
                    if (BPMPSDDiagram.isNotUndefinedAndNull(cellInLabelArray)) {
                        if (cellInLabelArray.getId() === cell.getId()) {
                            return columnIndex;
                        }
                    }
                }
            }
        }

        return 0;
    }

    labelChanged(cell: MxCell, value: string) {
        const isRow = cell.getValue().psdCellMetaInfo.type === psdCellType.VERTICAL_HEADER;
        const index = this.findIndexForCell(cell, isRow);
        if (isRow) {
            this.metaData.rows[index].name = value;
        } else {
            this.metaData.columns[index].name = value;
        }
    }

    isChildElementsInVector(cell: MxCell, isRow: boolean) {
        const index = this.findIndexForCell(cell, isRow);
        const result = false;
        if (isRow) {
            for (let i = 0; i < this.cellsMatrix[index].length; i++) {
                const cellInArray = this.cellsMatrix[index][i];
                if (BPMPSDDiagram.isNotUndefinedAndNull(cellInArray)) {
                    if (cellInArray.getChildCount() > 0) {
                        return true;
                    }
                }
            }
        } else {
            for (let i = 0; i < this.rowCount; i++) {
                const cellInArray = this.cellsMatrix[i][index];
                if (BPMPSDDiagram.isNotUndefinedAndNull(cellInArray)) {
                    if (cellInArray.getChildCount() > 0) {
                        return true;
                    }
                }
            }
        }

        return result;
    }

    private moveCells(index: number, isAdd: boolean, isRow: boolean) {
        const model = this.graph.getModel();
        model.beginUpdate();
        try {
            // move all existing cells
            if (isAdd && isRow) {
                if (index < this.rowCount) {
                    for (let rowIndex = index; rowIndex < this.rowCount; rowIndex++) {
                        this.graph.moveCells(this.labelMatrix[rowIndex], 0, this.rowHeight, false);
                        this.graph.moveCells(this.cellsMatrix[rowIndex], 0, this.rowHeight, false);
                    }
                }
            } else if (!isAdd && isRow) {
                if (index < this.rowCount) {
                    for (let rowIndex = index; rowIndex < this.rowCount; rowIndex++) {
                        this.graph.moveCells(this.labelMatrix[rowIndex], 0, -this.rowHeight, false);
                        this.graph.moveCells(this.cellsMatrix[rowIndex], 0, -this.rowHeight, false);
                    }
                }
            } else if (isAdd && !isRow) {
                if (index < this.columnCount) {
                    const movedCells: MxCell[] = [];
                    for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
                        for (let columnIndex = index; columnIndex < this.columnCount; columnIndex++) {
                            movedCells.push(this.cellsMatrix[rowIndex][columnIndex]);
                        }
                    }
                    for (let columnIndex = index; columnIndex < this.columnCount; columnIndex++) {
                        movedCells.push(this.labelMatrix[0][columnIndex]);
                    }

                    this.graph.moveCells(movedCells, this.columnWidth, 0, false);
                }
            } else if (index < this.columnCount) {
                const movedCells: MxCell[] = [];
                for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
                    for (let columnIndex = index; columnIndex < this.columnCount; columnIndex++) {
                        movedCells.push(this.cellsMatrix[rowIndex][columnIndex]);
                    }
                }
                for (let columnIndex = index; columnIndex < this.columnCount; columnIndex++) {
                    movedCells.push(this.labelMatrix[0][columnIndex]);
                }

                this.graph.moveCells(movedCells, -this.columnWidth, 0, false);
            }
        } finally {
            // Updates the display
            model.endUpdate();
        }
    }

    private resizeMainTable() {
        const model = this.graph.getModel();
        model.beginUpdate();
        try {
            const geo = this.graph.getCellGeometry(this.mainTable);
            if (!isUndefined(geo)) {
                const geo2 = { ...geo };
                geo2.height = this.offsetsForCellY + this.rowHeight * this.rowCount;
                geo2.width = this.labelWidth + this.columnWidth * this.columnCount;
                this.graph.resizeCell(this.mainTable, geo2 as MxGeometry);
            }
        } finally {
            model.endUpdate();
        }
    }

    private recalculateIndexForCell(cell: MxCell, rowIndex: number, columnIndex: number) {
        if (BPMPSDDiagram.isNotUndefinedAndNull(cell)) {
            const cellValue = cell.getValue();
            cellValue.psdCellMetaInfo = { ...cellValue.psdCellMetaInfo, rowIndex, columnIndex };
            cell.setValue(cellValue);
        }

        return cell;
    }

    private recalculateIndexForCellsArray(cells: MxCell[], rowIndex: number) {
        if (isArray(cells)) {
            cells.map((cell: MxCell) => {
                if (BPMPSDDiagram.isNotUndefinedAndNull(cell)) {
                    const value = cell.getValue();
                    value.psdCellMetaInfo = { ...value.psdCellMetaInfo, rowIndex };

                    return cell.setValue(value);
                }
            });
        }

        return cells;
    }

    private recalculatePositionInMatrix(index: number, isAdd: boolean, isRow: boolean) {
        if (isAdd && isRow) {
            for (let rowIndex = this.rowCount; rowIndex > index; rowIndex--) {
                if (isUndefined(this.cellsMatrix[rowIndex])) {
                    this.cellsMatrix[rowIndex] = [];
                }
                this.cellsMatrix[rowIndex] = this.cellsMatrix[rowIndex - 1];
                this.cellsMatrix[rowIndex] = this.recalculateIndexForCellsArray(this.cellsMatrix[rowIndex], rowIndex);
                this.cellsMatrix[rowIndex - 1] = [];

                this.labelMatrix[rowIndex] = this.labelMatrix[rowIndex - 1];
                this.labelMatrix[rowIndex] = this.recalculateIndexForCellsArray(this.labelMatrix[rowIndex], rowIndex);
                this.labelMatrix[rowIndex - 1] = [];
            }
        } else if (!isAdd && isRow) {
            for (let rowIndex = index; rowIndex < this.rowCount; rowIndex++) {
                if (isUndefined(this.cellsMatrix[rowIndex])) {
                    this.cellsMatrix[rowIndex] = [];
                }
                this.cellsMatrix[rowIndex] = this.cellsMatrix[rowIndex + 1];
                this.cellsMatrix[rowIndex] = this.recalculateIndexForCellsArray(this.cellsMatrix[rowIndex], rowIndex);
                this.cellsMatrix[rowIndex + 1] = [];

                this.labelMatrix[rowIndex] = this.labelMatrix[rowIndex + 1];
                this.labelMatrix[rowIndex] = this.recalculateIndexForCellsArray(this.labelMatrix[rowIndex], rowIndex);
                this.labelMatrix[rowIndex + 1] = [];
            }
        } else if (isAdd && !isRow) {
            for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
                for (let columnIndex = this.columnCount; columnIndex > index; columnIndex--) {
                    this.cellsMatrix[rowIndex][columnIndex] = this.cellsMatrix[rowIndex][columnIndex - 1];
                    this.cellsMatrix[rowIndex][columnIndex] = this.recalculateIndexForCell(
                        this.cellsMatrix[rowIndex][columnIndex],
                        rowIndex,
                        columnIndex,
                    );
                    this.cellsMatrix[rowIndex][columnIndex - 1] = null;

                    this.labelMatrix[rowIndex][columnIndex] = this.labelMatrix[rowIndex][columnIndex - 1];
                    this.labelMatrix[rowIndex][columnIndex] = this.recalculateIndexForCell(
                        this.labelMatrix[rowIndex][columnIndex],
                        rowIndex,
                        columnIndex,
                    );
                    this.labelMatrix[rowIndex][columnIndex - 1] = null;
                }
            }
        } else {
            for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
                for (let columnIndex = index; columnIndex < this.columnCount; columnIndex++) {
                    this.cellsMatrix[rowIndex][columnIndex] = this.cellsMatrix[rowIndex][columnIndex + 1];
                    this.cellsMatrix[rowIndex][columnIndex] = this.recalculateIndexForCell(
                        this.cellsMatrix[rowIndex][columnIndex],
                        rowIndex,
                        columnIndex,
                    );
                    this.cellsMatrix[rowIndex][columnIndex + 1] = null;

                    this.labelMatrix[rowIndex][columnIndex] = this.labelMatrix[rowIndex][columnIndex + 1];
                    this.labelMatrix[rowIndex][columnIndex] = this.recalculateIndexForCell(
                        this.labelMatrix[rowIndex][columnIndex],
                        rowIndex,
                        columnIndex,
                    );
                    this.labelMatrix[rowIndex][columnIndex + 1] = null;
                }
            }
        }
    }

    private createLayoutInstance(cell: MxCell, metaInfo: PsdCell) {
        const layoutInstance = new LayoutInstanceImpl({
            type: 'layout',
            isPSDCell: true,
            id: cell.getId(),
            psdCellMetaInfo: metaInfo,
        });
        cell.setValue(layoutInstance);
    }

    private addHorizontalHeader(rowIndex: number, columnIndex: number, psdCell: PsdCell | null) {
        if (psdCell === null) {
            psdCell = {
                name: `Head horizontal ${rowIndex}${columnIndex}`,
                allowedSymbols: this.metaData.columns[columnIndex - 1].allowedSymbols,
                type: psdCellType.HORIZONTAL_HEADER,
                rowIndex,
                columnIndex,
            };
            this.metaData.columns.splice(columnIndex, 0, psdCell);
        }

        this.labelMatrix[rowIndex][columnIndex] = this.graph.insertVertex(
            this.mainTable,
            uuid(),
            '', // won't used in the future
            this.labelWidth + columnIndex * this.columnWidth, // x
            0, // y
            this.columnWidth,
            this.labelHeight,
            'headHorizontal',
        );
        this.labelMatrix[rowIndex][columnIndex].setConnectable(false);
        this.createLayoutInstance(this.labelMatrix[rowIndex][columnIndex], psdCell);
    }

    private addVerticalHeader(rowIndex: number, columnIndex: number, psdCell: PsdCell | null) {
        if (psdCell === null) {
            psdCell = {
                name: `Head Vertical ${rowIndex}${columnIndex}`,
                allowedSymbols: this.metaData.rows[rowIndex - 1].allowedSymbols,
                type: psdCellType.VERTICAL_HEADER,
                rowIndex,
                columnIndex,
            };
            this.metaData.rows.splice(rowIndex, 0, psdCell);
        }

        this.labelMatrix[rowIndex][columnIndex] = this.graph.insertVertex(
            this.mainTable,
            uuid(),
            '', // won't used in the future
            0, // x
            this.offsetsForCellY + rowIndex * this.rowHeight, // y
            this.labelWidth, // width
            this.rowHeight, // height
            'headVertical',
        );
        this.labelMatrix[rowIndex][columnIndex].setConnectable(false);
        this.createLayoutInstance(this.labelMatrix[rowIndex][columnIndex], psdCell);
    }

    private addCell(rowIndex: number, columnIndex: number) {
        this.cellsMatrix[rowIndex][columnIndex] = this.graph.insertVertex(
            this.mainTable,
            uuid(),
            `Cell ${rowIndex}${columnIndex}`,
            this.offsetsForCellX + columnIndex * this.columnWidth, // x
            this.offsetsForCellY + rowIndex * this.rowHeight, // y
            this.columnWidth, // width
            this.rowHeight, // height
            'swimlaneNoLabel',
        );
        this.cellsMatrix[rowIndex][columnIndex].setConnectable(false);
        const allowedSymbolsRow = this.metaData.rows[rowIndex].allowedSymbols;
        const allowedSymbolsColumn = this.metaData.columns[columnIndex].allowedSymbols;
        const allowedSymbols = union(allowedSymbolsRow, allowedSymbolsColumn);
        this.createLayoutInstance(this.cellsMatrix[rowIndex][columnIndex], {
            name: `Cell ${rowIndex}${columnIndex}`,
            allowedSymbols,
            type: psdCellType.CELL,
            rowIndex,
            columnIndex,
        });
    }

    private initStyleForDiagram() {
        const style1 = {};
        style1[MxConstants.STYLE_SHAPE] = MxConstants.SHAPE_SWIMLANE;
        style1[MxConstants.STYLE_PERIMETER] = MxPerimeter.RectanglePerimeter;
        style1[MxConstants.STYLE_VERTICAL_ALIGN] = 'middle';
        style1[MxConstants.STYLE_SWIMLANE_FILLCOLOR] = 'transparent';
        style1[MxConstants.STYLE_FONTSIZE] = 11;
        style1[MxConstants.STYLE_STARTSIZE] = 22;
        style1[MxConstants.STYLE_HORIZONTAL] = true;
        style1[MxConstants.STYLE_FONTCOLOR] = 'black';
        style1[MxConstants.STYLE_STROKECOLOR] = 'black';
        style1[MxConstants.STYLE_MOVABLE] = 0;
        style1[MxConstants.STYLE_DELETABLE] = 0;
        style1[MxConstants.STYLE_RESIZABLE] = 0;
        style1[MxConstants.STYLE_FOLDABLE] = 0;

        delete style1[MxConstants.STYLE_FILLCOLOR];
        this.graph.getStylesheet().putCellStyle('swimlane', style1);

        const swimlaneNoLabel = MxUtils.clone(style1);
        swimlaneNoLabel[MxConstants.STYLE_NOLABEL] = 1;
        swimlaneNoLabel[MxConstants.STYLE_EDITABLE] = 0;
        swimlaneNoLabel[BPMMxConstants.STYLE_SELECTABLE] = 0;
        swimlaneNoLabel[MxConstants.STYLE_STARTSIZE] = 0;
        swimlaneNoLabel[MxConstants.STYLE_MOVABLE] = 0;
        swimlaneNoLabel[MxConstants.STYLE_RESIZABLE] = 0;
        swimlaneNoLabel[MxConstants.STYLE_DELETABLE] = 1;

        this.graph.getStylesheet().putCellStyle('swimlaneNoLabel', swimlaneNoLabel);

        const styleHeadVertical = MxUtils.clone(style1);
        styleHeadVertical[MxConstants.STYLE_SHAPE] = MxConstants.SHAPE_RECTANGLE;
        styleHeadVertical[MxConstants.STYLE_HORIZONTAL] = false;
        styleHeadVertical[MxConstants.STYLE_MOVABLE] = 0;
        styleHeadVertical[MxConstants.STYLE_RESIZABLE] = 0;
        styleHeadVertical[MxConstants.STYLE_DELETABLE] = 1;
        styleHeadVertical[BPMMxConstants.STYLE_SELECTABLE] = 0;
        this.graph.getStylesheet().putCellStyle('headVertical', styleHeadVertical);

        const styleHeadHorizontal = MxUtils.clone(styleHeadVertical);
        styleHeadHorizontal[MxConstants.STYLE_HORIZONTAL] = true;
        this.graph.getStylesheet().putCellStyle('headHorizontal', styleHeadHorizontal);
    }
}
