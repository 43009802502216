import { defineMessages } from 'react-intl';

export default defineMessages({
    dialogName: {
        id: 'ImportNotationDialog.dialogName',
        defaultMessage: 'Найдены конфликты',
    },
    dialogLabel: {
        id: 'ImportNotationDialog.dialogLabel',
        defaultMessage: 'Выберите один из способов решения конфликта для элемента:',
    },
    mergeError: {
        id: 'ImportNotationDialog.mergeError',
        defaultMessage: 'Не удается решить конфликт',
    },
    importDialogTitle: {
        id: 'ImportNotationDialog.importDialogTitle',
        defaultMessage: 'Выбор ранее выгруженного файла экспорта',
    },
    exportDialogTitle: {
        id: 'ImportNotationDialog.exportDialogTitle',
        defaultMessage: 'Сохранить файл экспорта',
    },
    dialogCloneRepository: {
        id: 'ImportDialog.cloneRepository',
        defaultMessage: 'Клонирование БД',
    },
    dialogNewNameRepository: {
        id: 'ImportDialog.newName',
        defaultMessage: 'Введите новое имя БД:',
    },
    dialogReplaceRepositoryName: {
        id: 'ImportDialog.replaceRepositoryName',
        defaultMessage: 'Замена БД',
    },
    dialogReplaceRepository: {
        id: 'ImportDialog.replaceRepository',
        defaultMessage: 'Эта операция полностью заменит БД',
    },
    dialogConfirmationReplaceRepository: {
        id: 'ImportDialog.confirmationReplaceRepository',
        defaultMessage: 'Операцию невозможно будет отменить. Вы уверены?',
    },
    conflictDialogItemTypeDB: {
        id: 'ImportDialog.conflictItemType.DB',
        defaultMessage: 'База данных',
    },
    conflictDialogItemTypeFolder: {
        id: 'ImportDialog.conflictItemType.Folder',
        defaultMessage: 'Папка',
    },
    conflictDialogItemTypeModel: {
        id: 'ImportDialog.conflictItemType.Model',
        defaultMessage: 'Модель',
    },
    conflictDialogItemTypeObjectDefinition: {
        id: 'ImportDialog.conflictItemType.ObjectDefinition',
        defaultMessage: 'Объект',
    },
    conflictDialogItemTypeKanban: {
        id: 'ImportDialog.conflictItemType.Kanban',
        defaultMessage: 'Канбан',
    },
    conflictDialogItemTypeWiki: {
        id: 'ImportDialog.conflictItemType.Wiki',
        defaultMessage: 'Wiki-страница',
    },
    conflictDialogItemTypeSpreadsheet: {
        id: 'ImportDialog.conflictItemType.Spreadsheet',
        defaultMessage: 'Таблица',
    },
    conflictDialogItemTypeMatrix: {
        id: 'ImportDialog.conflictItemType.Matrix',
        defaultMessage: 'Матричная модель',
    },
    conflictDialogItemTypeFile: {
        id: 'ImportDialog.conflictItemType.File',
        defaultMessage: 'Файл',
    },
    conflictDialogItemTypeSimulation: {
        id: 'ImportDialog.conflictItemType.Simulation',
        defaultMessage: 'Имитационное моделирование',
    },
    conflictDialogForNodeButton: {
        id: 'ImportDialog.conflictDialogForNodeButton',
        defaultMessage: 'Для данного узла',
    },
    conflictDialogForAllNodesButton: {
        id: 'ImportDialog.conflictDialogForAllNodesButton',
        defaultMessage: 'Для всех узлов',
    },
    cancelButton: {
        id: 'ImportDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    conflictDialogReplaceRadio: {
        id: 'ImportDialog.conflictDialogReplaceRadio',
        defaultMessage: 'Заменить имеющийся',
    },
    conflictDialogLeaveRadio: {
        id: 'ImportDialog.conflictDialogLeaveRadio',
        defaultMessage: 'Сохранить имеющийся',
    },
    conflictDialogCloneRadio: {
        id: 'ImportDialog.conflictDialogCloneRadio',
        defaultMessage: 'Создать клон узла с новым ID',
    },
    conflictDialogNotAccessToNode: {
        id: 'ImportDialog.conflictDialogNotAccessToNode',
        defaultMessage: 'Нет прав доступа к существующему элементу.',
    },
    conflictDialogNoAccessInSet: {
        id: 'ImportDialog.conflictDialogNoAccessInSet',
        defaultMessage: 'В списке конфликтов присутствуют узлы, к которым недостаточно прав доступа.',
    },
    conflictDialogNodeInfo: {
        id: 'ImportDialog.conflictDialogNodeInfo',
        defaultMessage: `ID: {id}
        Тип: {itemTypeLocal}
        Путь: {path}`,
    },
    accept: {
        id: 'ImportDialog.accept',
        defaultMessage: 'Принять',
    },
    replace: {
        id: 'ImportDialog.replace',
        defaultMessage: 'Заменить',
    },
    headOfDevelopment: {
        id: 'ImportDialog.headOfDevelopment',
        defaultMessage: 'Руководитель отдела разработки',
    },
    director: {
        id: 'ImportDialog.director',
        defaultMessage: 'Директор организации',
    },
});
