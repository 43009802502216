export const OPEN_CONTENT_LOADING_TAB = 'OPEN_CONTENT_LOADING_TAB';

export const WORKSPACE_TABS_ADD = 'WORKSPACE_TABS_ADD';

export const WORKSPACE_TABS_REMOVE_REQUEST = 'WORKSPACE_TABS_REMOVE_REQUEST';

export const WORKSPACE_REMOVE_MULTIPLE_TABS = 'WORKSPACE_REMOVE_MULTIPLE_TABS';

export const WORKSPACE_TABS_REMOVE = 'WORKSPACE_TABS_REMOVE';

export const WORKSPACE_TABS_REMOVE_BY_NODE_ID = 'WORKSPACE_TABS_REMOVE_BY_NODE_ID';

export const WORKSPACE_TABS_ACTIVATE = 'WORKSPACE_TABS_ACTIVATE';

export const WORKSPACE_TABS_COPY_TO_CLIPBOARD = 'WORKSPACE_TABS_COPY_TO_CLIPBOARD';

export const WORKSPACE_TABS_CHANGE_TITLE = 'WORKSPACE_TABS_CHANGE_TITLE';

export const WORKSPACE_TABS_CHANGE = 'WORKSPACE_TABS_CHANGE';

export const WORKSPACE_TABS_SET_ACTION = 'WORKSPACE_TABS_SET_ACTION';

export const WORKSPACE_TABS_SET_PARAMS = 'WORKSPACE_TABS_SET_PARAMS';

export const WORKSPACE_TABS_ORDER_CHANDGED = 'WORKSPACE_TABS_ORDER_CHANDGED';
