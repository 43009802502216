import type { IOverlayPart } from '../overlays/BPMMxCellOverlay';
import { MxConstants, MxUtils } from '../mxgraph';
import { ComplexSymbol } from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.class';

const originaGetAlignmentAsPoint = MxUtils.getAlignmentAsPoint;

MxUtils.getAlignmentAsPoint = function (align, valign, isEdge = false) {
    const point = originaGetAlignmentAsPoint(align, valign);
    if (isEdge) {
        if (align === MxConstants.ALIGN_LEFT) {
            point.x = -1;
        } else if (align === MxConstants.ALIGN_RIGHT) {
            point.x = 0;
        }
    }

    return point;
};

const isClickablePart = (part: IOverlayPart) => {
    const clickableAttributeTypes = ['URL', 'NODE'];
    const valueType = part?.attributeValue?.valueType || '';

    return clickableAttributeTypes.includes(valueType);
};

const originalClone = MxUtils.clone;
MxUtils.clone = function clone(obj, transients, shallow) {
    if (obj instanceof ComplexSymbol) {
        return null;
    }

    return originalClone(obj, transients, shallow);
};

export { MxUtils, isClickablePart };
