import { defineMessages } from 'react-intl';

export default defineMessages({
    UMLFrame: {
        id: 'UMLFrame',
        defaultMessage: 'Рамка UML',
    },
    UMLNote: {
        id: 'UMLNote',
        defaultMessage: 'Примечание',
    },
});
