import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { DEFAULT_DIALOG_WIDTH } from '../../../../../config/config';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../../../UIKit/components/Dialog/Dialog.component';
import messages from '../../../messages/NewSimulationNodeDialog.messages';
import { DialogFooterButtons } from '../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export const SaveSimulationParamsDialog = (props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { activeSimulation, saveSimulation } = props;
    const handleCancel = () => {
        dispatch(closeDialog(DialogType.SAVE_SIMULATION_PARAMS_DIALOG));
    };

    const handleOk = () => {
        saveSimulation(activeSimulation);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCancel,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: handleOk,
                    value: intl.formatMessage(messages.continueSave),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onCancel={handleCancel}
            title={intl.formatMessage(messages.saveParamsLaunch)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
        >
            {intl.formatMessage(messages.clearResults)}
        </Dialog>
    );
};
