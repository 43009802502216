import { INIT_HISTORY_DIALOG } from '../actionsTypes/historyDialog.actionTypes';
import { NodeId } from '../serverapi/api';
import { TInitHistoryDialogAction } from './historyDialog.actions.types';

export const initHistoryDialogAction: (nodeId: NodeId) => TInitHistoryDialogAction = (nodeId) => ({
    type: INIT_HISTORY_DIALOG,
    payload: {
        nodeId,
    },
});
