// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterCheckbox__root___7pK4{-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:32px}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/FilterCheckbox/FilterCheckbox.scss"],"names":[],"mappings":"AAAA,6BACI,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA","sourcesContent":[".root {\r\n    align-items: center;\r\n    height: 32px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FilterCheckbox__root___7pK4"
};
export default ___CSS_LOADER_EXPORT___;
