import React from 'react';
import { Tooltip } from 'antd';
import { Icon } from '../../../../UIKit/components/Icon/Icon.component';
import { NUIButton } from '../../../../UIKit/components/NUIButton/NUIButton.component';
import theme from './AlignButton.scss';
import { TSpriteSymbol } from '../../../../../models/spriteSymbol.types';

type TAlignButtonProps = {
    tooltip?: string;
    padding?: boolean;
    icon: TSpriteSymbol;
    onClick: () => void;
    selected: boolean;
    disabled?: boolean;
};

const AlignButton = ({ tooltip, padding, icon, onClick, selected, disabled }: TAlignButtonProps) => {
    return (
        <Tooltip mouseLeaveDelay={0} title={disabled ? '' : tooltip}>
            <span className={padding ? theme.buttonWrapperWithPadding : theme.buttonWrapper}>
                <NUIButton onClick={onClick} selected={selected} disabled={disabled}>
                    <Icon spriteSymbol={icon} />
                </NUIButton>
            </span>
        </Tooltip>
    );
};

export default AlignButton;
