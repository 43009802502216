import { defineMessages } from 'react-intl';

export default defineMessages({
    iconDescription: {
        id: 'CellSymbolsSettings.iconDescription',
        defaultMessage: 'Расшифровка',
    },
    iconSymbol: {
        id: 'CellSymbolsSettings.iconSymbol',
        defaultMessage: 'Символ',
    },
});
