import {
    CREATE_DATABASE_ACTION,
    FETCH_DB_ACCESS_INFO,
    OPEN_DBACCESS_OWNERS_EDITING_TAB,
    SAVE_INFO_OF_DBACCESS_OWNERS,
    UPDATE_REPOSITORY_ACTION,
    FETCHED_DB_ACCESS_INFO_SUCCESS,
    UPDATE_ERROR_DATABASE_ACTION,
    COPY_DATABASE_DIALOG_SUBMIT,
} from '../actionsTypes/databaseDialog.actionTypes';
import { NodeId, RepositoryNode } from '../serverapi/api';
import { TMessage } from '../utils/forms.types';
import {
    TCreateDatabasePayload,
    TCreateDatabaseAction,
    TFetchDBAccessInfoAction,
    TFetchDBAccessInfoPayload,
    TFetchDBAccessInfoSuccessAction,
    TFetchDBAccessInfoSuccessPayload,
    TOpenDBAccessOwnersEditingTabAction,
    TSaveInfoOfDBAccessOwnersAction,
    TSaveInfoOfDBAccessOwnersPayload,
    TToggleErrorOnCreateDatabase,
    TUpdateRepositoryAction,
    TCopyDatabaseDialogSubmitPayload,
    TCopyDatabaseDialogSubmitAction,
} from './databaseDialog.actions.types';

export const updateRepository = (repositoryNode: RepositoryNode): TUpdateRepositoryAction => ({
    type: UPDATE_REPOSITORY_ACTION,
    payload: { repositoryNode },
});

export const createDatabase = (payload: TCreateDatabasePayload): TCreateDatabaseAction => ({
    type: CREATE_DATABASE_ACTION,
    payload,
});

export const toggleErrorOnCreateDatabase = (error?: TMessage): TToggleErrorOnCreateDatabase => ({
    type: UPDATE_ERROR_DATABASE_ACTION,
    payload: {
        error,
    },
});

export const fetchDBAccessInfo: (payload: TFetchDBAccessInfoPayload) => TFetchDBAccessInfoAction = (payload) => ({
    type: FETCH_DB_ACCESS_INFO,
    payload,
});

export const openDBAccessOwnersEditingTab: (nodeId: NodeId) => TOpenDBAccessOwnersEditingTabAction = (nodeId) => ({
    type: OPEN_DBACCESS_OWNERS_EDITING_TAB,
    nodeId,
});

export const saveInfoOfDBAccessOwners: (payload: TSaveInfoOfDBAccessOwnersPayload) => TSaveInfoOfDBAccessOwnersAction =
    (payload) => ({
        type: SAVE_INFO_OF_DBACCESS_OWNERS,
        payload,
    });

export const fetchedDBAccessInfoSuccess: (
    payload: TFetchDBAccessInfoSuccessPayload,
) => TFetchDBAccessInfoSuccessAction = (payload) => ({
    type: FETCHED_DB_ACCESS_INFO_SUCCESS,
    payload,
});

export const copyDatabaseDialogSubmit = (
    payload: TCopyDatabaseDialogSubmitPayload,
): TCopyDatabaseDialogSubmitAction => ({
    type: COPY_DATABASE_DIALOG_SUBMIT,
    payload,
});
