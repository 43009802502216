import React from 'react';
import theme from './approvalTab.scss';
import { useIntl } from 'react-intl';
import messages from '../messages/approval.messages';
import { ApprovalTemplates } from '../ApprovalTemplates/ApprovalTemplates.components';
import { ApprovalPanel } from '../ApprovalPanel/ApprovalPanel.component';
import { Tabs } from 'antd';

export enum SideBarItemsId {
    APPROVAL_PANEL = 'approvalPanel',
    APPROVAL_TEMPLATES = 'approvalTemplates',
}

export const menuItems = [
    { id: SideBarItemsId.APPROVAL_PANEL, titleId: messages.approvalPanel },
    { id: SideBarItemsId.APPROVAL_TEMPLATES, titleId: messages.approvalTemplates },
];

export const ApprovalTab = React.memo((): JSX.Element => {
    const intl = useIntl();

    const components = {
        [SideBarItemsId.APPROVAL_PANEL]: <ApprovalPanel />,
        [SideBarItemsId.APPROVAL_TEMPLATES]: <ApprovalTemplates />,

    };

    const tabs = menuItems.map(({ id, titleId }) => ({
        label: intl.formatMessage(titleId),
        key: id,
        children: components[id],
    }));

    return (
        <div className={theme.container} >
            <div className={theme.containerInner}>
                <Tabs tabPosition="left" items={tabs} />
            </div>
        </div>
    );
});
