export const ADD_VISIO_FILES = 'ADD_VISIO_FILES';

export const SET_IMPORT_VISIO_DATA = 'SET_IMPORT_VISIO_DATA';

export const RESET_IMPORT_VISIO_DATA = 'RESET_IMPORT_VISIO_DATA';

export const ADD_VISIO_MATCHING = 'ADD_VISIO_MATCHING';

export const MAP_VALUE_TO_ALL_PAGES = 'MAP_VALUE_TO_ALL_PAGES';

export const IMPORT_VISIO_FILE = 'IMPORT_VISIO_FILE';

export const SET_PAGE_OPTIONS = 'SET_PAGE_OPTIONS';

export const DELETE_VISIO_PAGE = 'DELETE_VISIO_PAGE';

export const DELETE_VISIO_FILE = 'DELETE_VISIO_FILE';

export const CANCEL_IMPORT_VISIO = 'CANCEL_IMPORT_VISIO';

export const CLEAR_VISIO_DATA_AFTER_IMPORT = 'CLEAR_VISIO_DATA_AFTER_IMPORT';