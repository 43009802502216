import { FETCH_PRINCIPAL_SERVER_PROFILE_OWNERS_INFO_SUCCESS } from "../actionsTypes/principalServerProfile.actionTypes";
import { TReducer } from "../utils/types";
import { TPrincipalServerProfileState } from './principalServerProfile.reducer.types';

const initial: TPrincipalServerProfileState = {
    byServerId: {},
};

export const principalServerProfileReducer: TReducer<TPrincipalServerProfileState> = (state = initial, action) => {
    switch (action.type) {
        case FETCH_PRINCIPAL_SERVER_PROFILE_OWNERS_INFO_SUCCESS: {
            const { serverId, profileId, users, groups } = action.payload;

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...(state.byServerId[serverId] || {}),
                        owners: {
                            ...(state.byServerId[serverId] ? state.byServerId[serverId].owners : {}),
                            [profileId]: {
                                users: [...users],
                                groups: [...groups],
                            },
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};