import type { TFormValues, TLinkDialogProps } from './WikiLinkDialog.types';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import messages from './WikiLinkDialog.messages';
import { injectIntl } from 'react-intl';
import theme from './WikiLinkDialog.scss';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { TreeNode } from '../../../models/tree.types';
import { openChooseTreeNode, treeItemSelect, treeItemsClearSelection } from '../../../actions/tree.actions';
import {
    LoginResponseAuthorizationMethodEnum,
    NodeId,
    SystemPropertiesActiveAuthorizationMethodsEnum,
} from '../../../serverapi/api';
import { closeDialog, openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { prepareUrl } from '../../UIKit/components/EditableText/editableText.utils';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import iconSubmenu from '../../../resources/icons/ic-sidemenu-navigator.svg';
import { TreeItemType } from '../../Tree/models/tree';
import { TWikiLink } from '@/models/tab.types';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { ExternalLinkBLLService } from '@/services/bll/ExternalLinkBLLService';
import { getAuthorizationMethod } from '@/selectors/authorization.selectors';
import { SystemPropertiesSelectors } from '@/selectors/systemProperties.selectors';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

const LinkDialogComponent = (props: TLinkDialogProps) => {
    const { intl, external } = props;
    const dispatch = useDispatch();
    const selectedNode: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode());
    const activeScheme = useSelector(TabsSelectors.getActiveTab);

    const authMethod: LoginResponseAuthorizationMethodEnum | undefined = useSelector(getAuthorizationMethod);
    const activeAuthorizationMethods: SystemPropertiesActiveAuthorizationMethodsEnum[] | undefined = useSelector(
        SystemPropertiesSelectors.getActiveAuthorizationMethods,
    );

    const workspaceId: NodeId | undefined = activeScheme && activeScheme.nodeId;
    const [form] = Form.useForm<TFormValues>();
    const emptyTreeNode = {
        nodeId: {
            serverId: '',
            id: '',
            repositoryId: '',
        },
        name: '',
        type: '' as TreeItemType,
        hasChildren: false,
        countChildren: 0,
    };

    useEffect(() => {
        const url = selectedNode?.name
            ? ExternalLinkBLLService.createExternalLink(
                  window.location.origin,
                  selectedNode.type,
                  selectedNode.nodeId.repositoryId,
                  selectedNode.nodeId.id,
                  ExternalLinkBLLService.getLinkTypeByAuth(authMethod, activeAuthorizationMethods),
              )
            : '';
        const text = !external && selectedNode?.name ? selectedNode?.name : '';

        form.setFieldsValue({ text, url });
    }, [selectedNode]);

    useEffect(() => {
        dispatch(treeItemSelect(emptyTreeNode));
        form.resetFields();
        form.setFieldsValue({ text: props.text || '', url: props.url || '' });
    }, [form]);

    const onClose = () => {
        dispatch(closeDialog(DialogType.LINK_DIALOG));
        dispatch(treeItemsClearSelection());
    };

    const onSubmit = (workspaceNodeId: NodeId | undefined, link: TWikiLink) => {
        if (workspaceNodeId) {
            props.onSubmit(link);

            onClose();
            dispatch(treeItemSelect(emptyTreeNode));
        }
    };

    const handleSubmit = () => {
        if (form) {
            form.validateFields()
                .then((formValues: TFormValues) => {
                    onSubmit(workspaceId, {
                        url: prepareUrl(formValues.url, selectedNode?.nodeId, selectedNode?.type),
                        text: formValues.text,
                        nodeId: selectedNode?.nodeId.id,
                    } as TWikiLink);
                })
                .catch(() => undefined);
        }
    };
    const openChooseTreeNodeDialog = (serverId: string, repositoryId: string) => {
        dispatch(
            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                serverId,
                repositoryId,
                onSubmit: (nodeId: NodeId) => {
                    dispatch(openChooseTreeNode(nodeId));
                },
                disableContextMenu: true,
            }),
        );
    };

    const linkNodeButton = !external ? (
        <div className={theme.iconContainer} data-test="wiki-toolbar-group_add-link-button_add-tree-node">
            <Icon
                spriteSymbol={iconSubmenu}
                onClick={() => {
                    if (workspaceId) {
                        openChooseTreeNodeDialog(workspaceId.serverId, workspaceId.repositoryId);
                    }
                }}
            />
        </div>
    ) : null;

    const children = (
        <Form autoComplete='off' form={form}>
            <Form.Item
                label={intl.formatMessage(messages.linkUrlLabel)}
                name="url"
                rules={[{ required: true, message: intl.formatMessage(messages.formValidationMessageRequiredUrl) }]}
            >
                <Input
                    disabled={!external}
                    addonAfter={linkNodeButton}
                    data-test="wiki-toolbar-group_add-link-button_add-url-link"
                />
            </Form.Item>

            <Form.Item
                label={intl.formatMessage(messages.linkTextLabel)}
                name="text"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages.formValidationMessageRequiredLabel),
                    },
                ]}
            >
                <Input disabled={!external} data-test="wiki-toolbar-group_add-link-button_add-text-link" />
            </Form.Item>
        </Form>
    );

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.formDeclineButton),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.formConfirmButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.formName)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
        >
            {children}
        </Dialog>
    );
};

const LinkDialogWithIntl = injectIntl(LinkDialogComponent);

export { LinkDialogWithIntl as LinkDialog };
