import React from 'react';
import theme from './InfoButton.scss';
import passwordKey from '../../../../resources/icons/passwordKey.svg';
import grayPasswordKey from '../../../../resources/icons/grayPasswordKey.svg';
import arrowright from '../../../../resources/icons/arrow-Right-Big.svg';
import messages from '../../../../modules/UserAccountTab/userAccount.messages';
import classnames from 'classnames';
import { Icon } from '../../index';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

type TInfoButtonProps = {
    titleText: string;
    infoText: string;
    onClick: () => void;
    passwordChangePermission: boolean;
};

export const InfoButton = (props: TInfoButtonProps) => {
    const { titleText, infoText, onClick, passwordChangePermission } = props;
    const intl = useIntl();
    const handleClick = passwordChangePermission ? onClick : undefined;
    const tooltipMessage: string | null = passwordChangePermission
        ? null
        : intl.formatMessage(messages.changePasswordForbidden);

    return (
        <Tooltip mouseLeaveDelay={0} title={tooltipMessage}>
            <div
                className={classnames(theme.container, {
                    [theme.disabledContainer]: !passwordChangePermission,
                })}
                onClick={handleClick}
                data-test='change-user-password_button'
            >
                <div className={theme.iconContainer}>
                    <Icon spriteSymbol={passwordChangePermission ? passwordKey : grayPasswordKey} />
                </div>
                <div className={theme.textContainer}>
                    <div className={theme.titleTextContainer}>{titleText}</div>
                    <div className={theme.infoTextContainer}>{infoText}</div>
                </div>
                <Icon spriteSymbol={arrowright} />
            </div>
        </Tooltip>
    );
};
