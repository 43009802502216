import { TReducer } from '../utils/types';
import {
    CANVAS_SETTINGS_SET_DOT_SPACE,
    CANVAS_SETTINGS_TOGGLE_GRID,
} from '../actionsTypes/canvas-settings.actionTypes';
import { GridTypeOnCanvas } from '../modules/MainMenu/components/GraphGeneralToolbar/GridTypeOnCanvasConstant';
import { TCanvasSettingsState } from './canvas-settings.reducer.types';
import {
    DEFAULT_DOT_SPACE
} from '../modules/Editor/components/Editor/CanvasSettingsConstants';

const initial: TCanvasSettingsState = {
    isGridEnabled: false,
    gridType: GridTypeOnCanvas.noGrid,
    dotSpace: DEFAULT_DOT_SPACE
};

export const canvasSettingsReducer: TReducer<TCanvasSettingsState> = (state = initial, action) => {
    switch (action.type) {
        case CANVAS_SETTINGS_TOGGLE_GRID: {
            const { gridType } = action.payload;

            if (gridType === GridTypeOnCanvas.noGrid) {
                return {
                    ...state,
                    isGridEnabled: false,
                    gridType: GridTypeOnCanvas.noGrid,
                };
            }

            if (gridType === state.gridType) {
                return {
                    ...state,
                    gridType: initial.gridType,
                };
            }

            return {
                ...state,
                isGridEnabled: true,
                gridType,
            };
        }

        case CANVAS_SETTINGS_SET_DOT_SPACE: {
            const { dotSpace } = action.payload;

            return {
                ...state,
                dotSpace,
            };
        }

        default: {
            return state;
        }
    }
};
