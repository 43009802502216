// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExtendableSelect__relativeTooltipContainer__KnTem{position:relative;display:inline}.ExtendableSelect__arrow__B7tl7{width:8px}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/ExtendableSelect/ExtendableSelect.scss"],"names":[],"mappings":"AAAA,mDACI,iBAAA,CACA,cAAA,CAGJ,gCACI,SAAA","sourcesContent":[".relativeTooltipContainer {\r\n    position: relative;\r\n    display: inline;\r\n}\r\n\r\n.arrow {\r\n    width: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relativeTooltipContainer": "ExtendableSelect__relativeTooltipContainer__KnTem",
	"arrow": "ExtendableSelect__arrow__B7tl7"
};
export default ___CSS_LOADER_EXPORT___;
