import {
    CHANGE_NAVIGATOR_TREE_SEARCH_STATUS,
    SET_FOUND_NODE_IDS,
    SET_SEARCH_STRING,
    SET_MOVE_DIRECTION,
    FIND_NODE_IDS_BY_SEARCH_STRING,
    CHANGE_FOUND_NODE_IDS_BY_SEARCH_STRING,
} from '../actionsTypes/navigatorTreeSearch.actionsTypes';
import { TTreeNodeWithLevel } from '../modules/Tree/Tree.types';
import { NodeId } from '../serverapi/api';
import {
    TChamgeNavigatorTreeSeachStatusAction,
    TSetFoundNodeIds,
    TSetSearchString,
    TSetMoveDirection,
    TFindNodeIdsBySearchStr,
    TChangeFoundNodeIdsBySearchStr,
} from './navigatorTreeSearch.actions.types';

export const chamgeNavigatorTreeSeachStatus = (isActive: boolean): TChamgeNavigatorTreeSeachStatusAction => ({
    type: CHANGE_NAVIGATOR_TREE_SEARCH_STATUS,
    payload: {
        isActive,
    },
});

export const setSearchString = (searchString: string): TSetSearchString => ({
    type: SET_SEARCH_STRING,
    payload: {
        searchString,
    },
});

export const setFoundNodeIds = (foundNodeIds: NodeId[]): TSetFoundNodeIds => ({
    type: SET_FOUND_NODE_IDS,
    payload: {
        foundNodeIds,
    },
});

export const setMoveDirection = (moveDirection: 'up' | 'down'): TSetMoveDirection => ({
    type: SET_MOVE_DIRECTION,
    payload: {
        moveDirection,
    },
});

export const findNodeIdsBySearchStr = (data: TTreeNodeWithLevel[], str: string): TFindNodeIdsBySearchStr => ({
    type: FIND_NODE_IDS_BY_SEARCH_STRING,
    payload: {
        data,
        str
    },
});

export const changeFoundNodeIdsBySearchStr = (data: TTreeNodeWithLevel[]): TChangeFoundNodeIdsBySearchStr => ({
    type: CHANGE_FOUND_NODE_IDS_BY_SEARCH_STRING,
    payload: {
        data,
    },
});
