import React, { FC } from 'react';
import icMoveLevelUp from '../../../../../../resources/icons/ic-ribbon-send-front.svg';
import icMoveLevelTop from '../../../../../../resources/icons/ic-ribbon-send-top.svg';
import icMoveLevelDown from '../../../../../../resources/icons/ic-ribbon-send-back.svg';
import icMoveLevelBottom from '../../../../../../resources/icons/ic-ribbon-send-bottom.svg';
import { createCompactableSelect } from '../../../CompactableSelect/CompactableSelect.component';
import { MoveLayer } from '../../../../../../models/movelayer';
import { useIntl } from 'react-intl';
import messages from './MoveLayerSelect.messages';
import { useDispatch } from 'react-redux';
import { TCompactableSelectItemType } from '../../../CompactableSelect/CompactableSelect.types';
import { moveLayerAction } from '../../../../../../actions/editor.actions';
import menuItemTheme from '../../../items/MenuItem.scss';

interface IMoveLayerSelectProps {
    compact?: boolean;
    disabled?: boolean;
    isReadMode?: boolean;
}

const MoveSelect = createCompactableSelect<MoveLayer>();

export const MoveLayerSelect: FC<IMoveLayerSelectProps> = (props) => {
    const { compact, disabled, isReadMode } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const handleMoveLayer = (moveLayer: MoveLayer) => {
        dispatch(moveLayerAction(moveLayer));
    };

    return (
        <div className={menuItemTheme.tooltipContainer} data-test="general-toolbar_move-layer-btn">
            <MoveSelect
                placement="top"
                title={intl.formatMessage(messages.title)}
                disabledTitle={
                    isReadMode
                        ? intl.formatMessage(messages.selectElementInEditMode)
                        : intl.formatMessage(messages.selectElementOnCanvas)
                }
                compact={compact}
                items={[
                    {
                        type: TCompactableSelectItemType.Value,
                        value: MoveLayer.Up,
                        label: intl.formatMessage(messages.moveLevelUp),
                        spriteSymbol: icMoveLevelUp,
                        disabled,
                    },
                    {
                        type: TCompactableSelectItemType.Value,
                        value: MoveLayer.Top,
                        label: intl.formatMessage(messages.moveLevelTop),
                        spriteSymbol: icMoveLevelTop,
                        disabled,
                    },
                    {
                        type: TCompactableSelectItemType.Value,
                        value: MoveLayer.Down,
                        label: intl.formatMessage(messages.moveLevelDown),
                        spriteSymbol: icMoveLevelDown,
                        disabled,
                    },
                    {
                        type: TCompactableSelectItemType.Value,
                        value: MoveLayer.Bottom,
                        label: intl.formatMessage(messages.moveLevelBottom),
                        spriteSymbol: icMoveLevelBottom,
                        disabled,
                    },
                ]}
                disabled={disabled}
                value={MoveLayer.Up}
                onSelect={handleMoveLayer}
            />
        </div>
    );
};
