import React from 'react';
import { UserProperty } from '../UIKit/components/UserProperty/UserProperty.component';
import { UserAccessRights } from './UserAccessRights.component';
import { IntlShape, useIntl } from 'react-intl';
import theme from './userAccount.scss';
import messages from '../UserAccountTab/userAccount.messages';
import { TPersonalProfileData, TUserAccountData } from './userAccountData.types';

type TUserProfileSectionProps = {
    data: TUserAccountData;
    personalProfileData: TPersonalProfileData;
};

export const UserProfileSection = ({ data, personalProfileData }: TUserProfileSectionProps) => {
    const intl: IntlShape = useIntl();

    return (
        <div>
            <div className={theme.profile}>{intl.formatMessage(messages.profile)}</div>
            <div className={theme.dataContainer}>
                {Object.keys(data).map((key) => {
                    const label: string = messages[key] ? intl.formatMessage(messages[key]) : key;

                    return <UserProperty key={key} label={label} value={data[key]} />;
                })}
            </div>
            <UserAccessRights
                licenses={personalProfileData.licenses}
                accesses={personalProfileData.accesses}
                groupNames={personalProfileData.groupNames}
                profiles={personalProfileData.profiles}
            />
        </div>
    );
};
