import React from 'react';
import messages from './InputId.messages';
import { useIntl } from 'react-intl';
import { FieldErrors, FieldValues, Path, PathValue, UseFormRegister } from 'react-hook-form';
import { MAX_GUID_LENGTH } from '@/utils/consts';
import { Input } from '@/modules/UIKit/components/Input/Input.component';

type TInputIdProps<T extends FieldValues> = {
    value?: PathValue<T, Path<T>>;
    onChange?: (value: string) => void;
    required: boolean;
    disabled: boolean;
    name: Path<T>;
    wrapperClassName?: string;
    mainInputClassName?: string;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
};

const unacceptableCharacters = '"\'\\#[]?%;.';

export const idValidator = (value: string = '') =>
    !value.split('').find((item) => unacceptableCharacters.includes(item));

export const InputId = <T extends FieldValues>(props: TInputIdProps<T>) => {
    const { disabled, required, onChange, name, register, wrapperClassName, mainInputClassName, value, errors } = props;
    const intl = useIntl();

    const formRegister = register(name, {
        value,
        onChange: (e) => onChange?.(e.target.value.trim()),
        validate: (value) => (!disabled && idValidator(value)) || intl.formatMessage(messages.unacceptableCharacters),
        disabled: disabled,
        required: {
            value: required,
            message: intl.formatMessage(messages.requiredField),
        },
        maxLength: {
            value: MAX_GUID_LENGTH,
            message: intl.formatMessage(messages.maxCharacters),
        },
    });

    return (
        <Input
            isRequired
            className={wrapperClassName}
            classNameInput={mainInputClassName}
            label={intl.formatMessage(messages.id)}
            error={errors?.[name]?.message?.toString()}
            {...formRegister}
        />
    );
};
