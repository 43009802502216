import { defineMessages } from 'react-intl';

export default defineMessages({
    header: {
        id: 'SearchPathDialog.header',
        defaultMessage: 'Путь поиска',
    },
    selectButtonText: {
        id: 'SearchPathDialog.button.select',
        defaultMessage: 'Выбрать',
    },
    cancelButtonText: {
        id: 'SearchPathDialog.button.cancel',
        defaultMessage: 'Отменить',
    },
});
