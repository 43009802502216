import { defineMessages } from 'react-intl';

export default defineMessages({
    reportModel: {
        id: 'reportSaga.reportModel',
        defaultMessage: 'Справочник',
    },
    name: {
        id: 'reportSaga.name',
        defaultMessage: 'Название',
    },
    entity: {
        id: 'reportSaga.entity',
        defaultMessage: 'Сущность',
    },
});
