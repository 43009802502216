import * as React from 'react';
import { connect } from 'react-redux';
import { MainMenuContent } from '../components/MainMenuContent/MainMenuContent.component';
import { TRootState } from '../../../reducers/root.reducer.types';
import { getActiveSection, getIsOpenedGeneralMenuFlag } from '../../../selectors/mainMenu.selectors';
import { GeneralMenuItemContainer } from './GeneralMenuItem.container';
import { MainMenuItems } from '../../../models/mainMenu';
import { PasteMenuItemContainer } from './PasteMenuItem.container';
import { ObjectMenuItemContainer } from './ObjectMenuItem.container';

const nodes = [
    <GeneralMenuItemContainer key={MainMenuItems.GENERAL} itemKey={MainMenuItems.GENERAL} />,
    <PasteMenuItemContainer key={MainMenuItems.PASTE} itemKey={MainMenuItems.PASTE} />,
    <ObjectMenuItemContainer key={MainMenuItems.OBJECT} itemKey={MainMenuItems.OBJECT} />,
];

const mapStateToProps = (state: TRootState) => {
    const isOpenedGeneralMenuFlag = getIsOpenedGeneralMenuFlag(state);

    return {
        selectedKey: getActiveSection(state),
        isOpenedGeneralMenuFlag,
        children: isOpenedGeneralMenuFlag ? nodes : [],
    };
};

export const MainMenuContentContainer = connect(mapStateToProps, null)(MainMenuContent);
