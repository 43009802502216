import { MxCell } from 'MxGraph';
import ShapeInstanceSerializer from '../serializers/ShapeInstanceSerializer.class';
import { ComplexSymbol } from '../ComplexSymbol.class';

export abstract class SequenceShapeSymbol extends ComplexSymbol {
    serializer = new ShapeInstanceSerializer();

    get isUseRenameDialog() {
        return false;
    }

    constructor({ graph, rootCellValue, customProps }) {
        super({ graph, rootCellValue, customProps });

        this.rootCellValue = { ...rootCellValue, type: 'shape' };
    }

    convertValueToString(cell: MxCell): string {
        const value = cell.getValue();

        return value?.metaInfo ? value.metaInfo : '';
    }

    public getCopyableCell(): MxCell | null {
        return this.rootCell;
    }
}
