import React, { useState, useEffect } from 'react';
import { Table, FormInstance } from 'antd';
import theme from './SelectLocalizedValues.scss';
import { useIntl } from 'react-intl';
import { RowButtons, DeleteButton } from '../../../../Button/RowButtons';
import { AttributeTypeSelectPropertyValue, InternationalString } from '../../../../../../serverapi/api';
import messages from '../../../messages/Presets.messages';
import { TSelectDataTypeValue } from './SelectLocalizedValues.types';
import { ColumnRender } from './ColumnRender.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TColumnTypes = Exclude<TEditableTableProps['columns'], undefined>;

type TEditableTableProps = Parameters<typeof Table>[0] & {
    selectPropertyValues: Array<AttributeTypeSelectPropertyValue> | undefined;
    form: React.RefObject<FormInstance>;
    changeSelectPropertyValues: (dataSource: AttributeTypeSelectPropertyValue[]) => void;
};

export const SelectLocalizedValues = ({
    form,
    selectPropertyValues,
    changeSelectPropertyValues,
}: TEditableTableProps) => {
    const intl = useIntl();
    const [dataSource, setDataSource] = useState<AttributeTypeSelectPropertyValue[]>(
        selectPropertyValues?.length
            ? selectPropertyValues
            : [
                {
                    id: '',
                    value: {} as InternationalString,
                },
            ],
    );
    const selectDataTypeValue: TSelectDataTypeValue[] = dataSource.map((d, idx) => {
        return {
            idx,
            id: d.id,
            ru: d.value?.ru || '',
            en: d.value?.en || '',
        };
    });

    const [newDataAdded, setNewDataAdded] = useState(false);

    useEffect(() => {
        // если удалили строку, то сбрасываем initialValue
        if (form.current) {
            form.current.resetFields();
        }
    }, [dataSource.length]);

    useEffect(() => {
        changeSelectPropertyValues(dataSource);
        setNewDataAdded(false);
    }, [dataSource]);

    useEffect(() => {
        const table = document.querySelector<HTMLElement>(`${'.'}${theme.selectLocalizedTable} ${' .ant-table-body'}`);
        if (table) {
            table.scrollTo(0, table.scrollHeight);
        }
    }, [newDataAdded]);

    const handleDelete = (idx: number) => () => {
        dataSource.splice(idx, 1);
        setDataSource([...dataSource]);
    };

    const renderRowButtons = (_, record: TSelectDataTypeValue) => {
        const deleteBtn =
            selectPropertyValues?.length && selectPropertyValues?.length > 1
                ? [
                    DeleteButton.build(
                        handleDelete(record.idx),
                        undefined,
                        undefined,
                        undefined,
                        intl.formatMessage(messages.deleteValueType, {value: record.id}),
                        undefined,
                    ),
                ]
                : [];

        return <RowButtons className={theme.deleteValueBtn} buttons={deleteBtn} />;
    };

    const columnsData = [
        {
            title: intl.formatMessage(messages.attributeValueId),
            dataIndex: 'id',
            width: '25%',
            editable: true,
            render: (value: string, record: TSelectDataTypeValue) => (
                <ColumnRender
                    value={value}
                    selectValue={record}
                    dataSource={dataSource}
                    dataIndex="id"
                    setDataSource={setDataSource}
                />
            ),
        },
        {
            title: intl.formatMessage(messages.ruValuesTitle),
            dataIndex: 'ru',
            width: '30%',
            editable: true,
            render: (value: string, record: TSelectDataTypeValue) => (
                <ColumnRender
                    value={value}
                    selectValue={record}
                    dataSource={dataSource}
                    dataIndex="ru"
                    setDataSource={setDataSource}
                />
            ),
        },
        {
            title: intl.formatMessage(messages.enValuesTitle),
            dataIndex: 'en',
            width: '30%',
            editable: true,
            render: (value: string, record: TSelectDataTypeValue) => (
                <ColumnRender
                    value={value}
                    selectValue={record}
                    dataSource={dataSource}
                    dataIndex="en"
                    setDataSource={setDataSource}
                />
            ),
        },
        {
            dataIndex: 'delete',
            render: renderRowButtons,
        },
    ];

    const handleAdd = () => {
        const newPropertyValue: AttributeTypeSelectPropertyValue = {
            id: '',
            value: {} as InternationalString,
        };
        setDataSource([...dataSource, newPropertyValue]);
        setNewDataAdded(true);
    };

    const columns = columnsData.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: AttributeTypeSelectPropertyValue) => {
                return {
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                };
            },
        };
    });

    return (
        <div>
            <Table
                className={theme.selectLocalizedTable}
                style={{ height: '100%' }}
                scroll={{ y: 100 }}
                pagination={false}
                dataSource={selectDataTypeValue}
                columns={columns as TColumnTypes}
                bordered
            />
            <div className={theme.addValueBtn}>
                <Button dataTest="attribute_multi-select_add-values_btn" onClick={handleAdd}>
                    {intl.formatMessage(messages.plus)}
                </Button>
            </div>
            
        </div>
    );
};
