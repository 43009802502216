export const defaultCellSize = 80;

export const defaultCellCount = 10;

export const chartColors = [
    '#21B9FF',
    '#FF7300',
    '#FF000F',
    '#DB00FF',
    '#6100FF',
    '#09DC7A',
    '#D7ECFA',
    '#FA8316',
    '#FFF500',
    '#1869C9',
    '#E3632C',
    '#F21849',
    '#BF1EF8',
    '#711EF8',
    '#00B360',
    '#D8E0E5',
    '#D1760A',
    '#F9E72D',
    '#059BE0',
    '#F8941E',
    '#FF5E82',
    '#BA83FF',
    '#7288C9',
    '#7CD23C',
    '#E8E8E8',
    '#E48F2B',
    '#FFD101',
    '#64CCFC',
    '#FFC42F',
    '#FF8BA5',
    '#CDB5FF',
    '#BCC4DB',
    '#9D5CF',
    '#F5AA51',
    '#FFFA78',
    '#fff',
];
