import * as React from 'react';
import { TStatusBarActionsProps, TStatusBarStoreProps } from '../../StatusBar.types';
import { ProcessIndicator } from '../ProcessIndicator/ProcessIndicator.component';
import { ScaleSlider } from '../ScaleSlider/ScaleSlider.component';
import theme from './StatusBar.scss';

type TStatusBarProps = TStatusBarStoreProps & TStatusBarActionsProps;

type TStatusBarState = {
    measureState?: boolean;
    hideNavigation?: boolean;
    hideSearchLabel?: boolean;
};

export class StatusBar extends React.Component<TStatusBarProps, TStatusBarState> {
    private navigationEl: HTMLDivElement;
    private containerEl: HTMLDivElement;
    private scaleEl: HTMLDivElement;

    constructor(props: TStatusBarProps) {
        super(props);

        this.state = {
            measureState: true,
            hideNavigation: false,
            hideSearchLabel: false,
        };
    }

    componentDidMount() {
        this.updateSize();
    }

    componentDidUpdate() {
        this.updateSize();
    }

    updateSize() {
        if (this.state.measureState) {
            const containerWidth = this.containerEl.getBoundingClientRect().width;
            const navWidth = this.navigationEl.getBoundingClientRect().width;
            const scaleWidth = this.scaleEl.getBoundingClientRect().width;

            if (navWidth + scaleWidth > containerWidth) {
                if (navWidth + scaleWidth < containerWidth) {
                    this.setState({
                        measureState: false,
                        hideNavigation: false,
                        hideSearchLabel: true,
                    });
                } else {
                    this.setState({
                        measureState: false,
                        hideNavigation: true,
                        hideSearchLabel: true,
                    });
                }
            } else {
                this.setState({
                    measureState: false,
                    hideNavigation: false,
                    hideSearchLabel: false,
                });
            }
        }
    }

    render() {
        return (
            <div
                className={
                    theme.container +
                    (this.state.hideNavigation ? ` ${theme.hideNavigation}` : '') +
                    (this.state.hideSearchLabel ? ` ${theme.hideSearchLabel}` : '')
                }
                ref={(r) => (this.containerEl = r!)}
            >
                <div className={theme.navigation} ref={(r) => (this.navigationEl = r!)} />
                <div className={theme.processIndicatorWrapper}>
                    {this.props.processes.length < 1 ? null : (
                        <ProcessIndicator
                            value={
                                this.props.processes.length === 1
                                    ? this.props.processes[0].name
                                    : `${this.props.processes[0].name}, ...(${this.props.processes.length - 1})`
                            }
                        />
                    )}
                </div>
                <div className={theme.scaleContainer} ref={(r) => (this.scaleEl = r!)}>
                    {this.props.disableZoom ? null : (
                        <ScaleSlider
                            value={this.props.scaleValue}
                            onChange={(value) => this.props.onScaleChange(this.props.activeSchemeId, value)}
                            onFitClick={() => this.props.onFitClick(this.props.activeSchemeId)}
                        />
                    )}
                </div>
            </div>
        );
    }
}
