import React from 'react';
import { Alert } from 'antd';
import {
    AttributeType,
    AttributeTypeGroup,
    EdgeType,
    EdgeTypeGroup,
    InternationalString,
    ModelType,
    ModelTypeGroup,
    PresetImage,
    PresetImageGroup,
    Symbol,
} from '../../../../../../serverapi/api';
import { LocalesService } from '../../../../../../services/LocalesService';
import messages from '../../../messages/Presets.messages';

export const createMessageForDelete = <T extends {multilingualName?: InternationalString}>(
    message: string,
    types?: T[],
): JSX.Element | '' => {
    const elements: string = LocalesService.internationalStringArrayToString(types);

    return elements && types?.length ? (
        <div>
            <b>{`${message}: `}</b>
            {elements}
        </div>
    ) : (
        ''
    );
};

type TDeleteMessageParams = {
    selectedModelTypes?: ModelType[];
    selectedModelTypeGroups?: ModelTypeGroup[];
    selectedAttributeTypes?: AttributeType[];
    selectedAttributeTypeGroups?: AttributeTypeGroup[];
    selectedEdgeTypes?: EdgeType[];
    selectedEdgeTypeGroups?: EdgeTypeGroup[];
    selectedPresetImages?: PresetImage[];
    selectedPresetImageGroups?: PresetImageGroup[];
};

export const getMetodologyElementsDeleteMessages = ({
    selectedModelTypes,
    selectedModelTypeGroups,
    selectedAttributeTypes,
    selectedAttributeTypeGroups,
    selectedEdgeTypes,
    selectedEdgeTypeGroups,
    selectedPresetImages,
    selectedPresetImageGroups,
}: TDeleteMessageParams): {
    deleteMessage: JSX.Element | '',
    deleteGroupsMessage: JSX.Element | '',
} => {
    const intl = LocalesService.useIntl();
    const deleteModelsMessage = createMessageForDelete(intl.formatMessage(messages.deleteModels), selectedModelTypes);
    const deleteModelGroupsMessage = createMessageForDelete(intl.formatMessage(messages.deleteModelGroups), selectedModelTypeGroups);
    const deleteAttributesMessage = createMessageForDelete(intl.formatMessage(messages.deleteAttributes), selectedAttributeTypes);
    const deleteGroupAttributesMessage = createMessageForDelete(intl.formatMessage(messages.deleteAttributeGroups), selectedAttributeTypeGroups);
    const deleteEdgesMessage = createMessageForDelete(intl.formatMessage(messages.deleteEdges), selectedEdgeTypes);
    const deleteEdgeGroupsMessage = createMessageForDelete(intl.formatMessage(messages.deleteEdgeGroups), selectedEdgeTypeGroups);
    const deletePresetImagesMessage = createMessageForDelete(intl.formatMessage(messages.deletePresetImages), selectedPresetImages);
    const deletePresetImageGroupsMessage = createMessageForDelete(intl.formatMessage(messages.deletePresetImageGroups), selectedPresetImageGroups);

    return {
        deleteMessage: deleteModelsMessage || deleteAttributesMessage || deleteEdgesMessage || deletePresetImagesMessage,
        deleteGroupsMessage: deleteModelGroupsMessage || deleteGroupAttributesMessage || deleteEdgeGroupsMessage || deletePresetImageGroupsMessage,
    };
};

export const getSymbolsDeleteMessages = (symbols: Symbol[], selectedRowKeys: string[]) => {
    const intl = LocalesService.useIntl();
    const symbolsForDelete: string | undefined = LocalesService.internationalStringArrayToString(
        symbols?.filter((symbol: Symbol) => selectedRowKeys.some((key) => key === symbol.id))
    );

    return selectedRowKeys.length ? (
        <Alert message={<><b>{`${intl.formatMessage(messages.deleteSymbols)}: `}</b>{symbolsForDelete}</>} type="warning" />
    ) : (
        ''
    );
};