export const ADD_SERVER_PROFILE_DATA = 'ADD_SERVER_PROFILE_DATA';

export const GET_ALL_SERVER_PROFILES_DATA = 'GET_ALL_SERVER_PROFILES_DATA';

export const SWITCH_ACTIVE_SERVER_PROFILE = 'CHANGE_ACTIVE_SERVER_PROFILE';

export const LOAD_ACTIVE_SERVER_PROFILE_REQUEST = 'LOAD_ACTIVE_SERVER_PROFILE_REQUEST';

export const LOAD_ACTIVE_SERVER_PROFILE_SUCCESS = 'LOAD_ACTIVE_SERVER_PROFILE_SUCCESS';

export const SWITCH_ACTIVE_SERVER_PROFILE_SUCCESS = 'CHANGE_ACTIVE_SERVER_PROFILE_SUCCESS';

export const OPEN_ACTIVE_SERVER_PROFILE_SELECTOR = 'OPEN_ACTIVE_SERVER_PROFILE_SELECTOR';

export const SERVER_PROFILE_SET_STATE = 'SERVER_PROFILE_SET_STATE';

export const DELETE_SERVER_PROFILE_DATA = 'DELETE_SERVER_PROFILE_DATA';

export const GET_ALL_SERVER_PROFILES = 'GET_ALL_SERVER_PROFILES';

export const UPDATE_USER_SERVER_PROFILES = 'UPDATE_USER_SERVER_PROFILES';

export const UPDATE_TRINSITIVE_PROFILE_IDS = 'UPDATE_TRINSITIVE_PROFILE_IDS';