import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import icReadMode from '../../../../../../resources/icons/ic-read-mode.svg';
import icEditMode from '../../../../../../resources/icons/ic-edit-mode.svg';
import messages from '../../../../messages/CommonToolbar.messages';
import theme from './EditModeButton.scss';
import { EditorMode } from '../../../../../../models/editorMode';
import { NodeId } from '../../../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { editorModeChangedPrepareAction } from '../../../../../../actions/editor.actions';
import { workspaceTabSetParams } from '../../../../../../actions/tabs.actions';
import { UserProfileSelectors } from '../../../../../../selectors/userProfile.selectors';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

interface IEditModeButtonProps {
    compact: boolean | undefined;
    isActiveScheme: boolean;
    isReadMode: boolean | undefined;
    nodeId: NodeId;
    modelTypeId: string | undefined;
    unlockDisabled: boolean;
};

export const EditModeButton: FC<IEditModeButtonProps> = (props) => {
    const { compact, isActiveScheme, isReadMode, nodeId, modelTypeId } = props;
    const intl = useIntl();
    const isReadOnly: boolean = !useSelector(UserProfileSelectors.isModelUpdatable(nodeId, modelTypeId)) || props.unlockDisabled;
    const dispatch = useDispatch();

    const editModeTooltipTitle = props.unlockDisabled
        ? intl.formatMessage(messages.unlockDisabled)
        : isReadMode
            ? intl.formatMessage(messages.toEditMode)
            : intl.formatMessage(messages.toReadMode);

    const onModeChange = (mode: EditorMode) => {
        dispatch(editorModeChangedPrepareAction(mode));
    };

    const isBlockNavigationArrows = (workspaceId: NodeId, undoManager: { undo: boolean; redo: boolean }) => {
        dispatch(workspaceTabSetParams(workspaceId, { undoManager }));
    };

    const handleModeChange = () => {
        if (isReadMode) {
            onModeChange(EditorMode.Edit);
        } else {
            onModeChange(EditorMode.Read);
            isBlockNavigationArrows(nodeId, { undo: false, redo: false });
        }
    };

    return (
        <div className={compact ? theme.modeGroup_compact : theme.modeGroup_full}>
            <Button
                dataTest={isReadMode ? "graph-general-toolbar_mode-button_read" : "graph-general-toolbar_mode-button_edit"}
                onClick={handleModeChange}
                size="large"
                visualStyle={{type: "text"}}
                disabled={isReadOnly}
                tooltip={isActiveScheme ? editModeTooltipTitle : ''}
                icon={{...(isReadMode ? icReadMode : icEditMode), width: 35, height: 35}}
            />
        </div>
    );
};
