import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import AccessPermissionsOwnersComponent from '../components/AccessPermissionsOwners.component';
import { TAccessPermissionsOwnersProps, TAccessPermissionsOwnersActionProps } from '../AccessPermissionsOwners.types';
import { UserDTOAccessesEnum } from '../../../../serverapi/api';
import {
    openAccessPermissionOwnersEditingTab,
    fetchAccessOwnerInfo,
} from '../../../../actions/accessPermissions.actions.types';
import { AccessPermissonsSelectors } from '../../../../selectors/accessPermissions.selectors';
import { withWindowResize } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';

const mapStateToProps = (state: TRootState): Partial<TAccessPermissionsOwnersProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const owners = serverId ? AccessPermissonsSelectors.byServerId(serverId)(state) : undefined;

    return {
        serverId,
        owners,
    };
};

const mapDispatchToProps: (dispatch) => TAccessPermissionsOwnersActionProps = (dispatch) => ({
    onEditClicked: (accessType: UserDTOAccessesEnum) => dispatch(openAccessPermissionOwnersEditingTab({ accessType })),
    fetchData: (serverId: string) => dispatch(fetchAccessOwnerInfo(serverId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withWindowResize(AccessPermissionsOwnersComponent));
