import type { TFormValues, TImageAttrs, TImageLinkDialogProps } from './WikiImageLinkDialog.types';
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import messages from './WikiImageLinkDialog.messages';
import { injectIntl } from 'react-intl';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

const ImageLinkDialogComponent = (props: TImageLinkDialogProps) => {
    const { intl } = props;
    const dispatch = useDispatch();
    const [form] = Form.useForm<TFormValues>();

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({ url: '', alt: '', title: '' });
    }, [form]);

    const onClose = () => {
        dispatch(closeDialog(DialogType.IMAGE_LINK_DIALOG));
    };

    const onSubmit = (attrs: TImageAttrs) => {
        props.onSubmit(attrs);
        onClose();
    };

    const handleSubmit = () => {
        if (form) {
            form.validateFields()
                .then(({ url, alt, title }: TFormValues) => onSubmit({ src: url, alt, title }))
                .catch(() => undefined);
        }
    };

    const children = (
        <Form autoComplete='off' form={form}>
            <Form.Item
                label={intl.formatMessage(messages.urlLabel)}
                name="url"
                rules={[{ required: true, message: intl.formatMessage(messages.formValidationMessageRequiredUrl) }]}
            >
                <Input data-test="wiki-toolbar-group_add-external-image-button_add-url" />
            </Form.Item>

            <Form.Item label={intl.formatMessage(messages.altLabel)} name="alt">
                <Input data-test="wiki-toolbar-group_add-external-image-button_add-alt" />
            </Form.Item>

            <Form.Item label={intl.formatMessage(messages.titleLabel)} name="title">
                <Input data-test="wiki-toolbar-group_add-external-image-button_add-title" />
            </Form.Item>
        </Form>
    );

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.formDeclineButton),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.formConfirmButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.formName)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
        >
            {children}
        </Dialog>
    );
};

const ImageLinkDialogWithIntl = injectIntl(ImageLinkDialogComponent);

export { ImageLinkDialogWithIntl as ImageLinkDialog };

