import { Row } from 'antd';
import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import messages from './ExternalSessions.messages';
import theme from './ExternalSessions.scss';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TExternalSessionsHeaderProps = {
    serverId: string;
    deleteButtonDisabled: boolean;
    onDeleteCreationDialog: () => void;
};

export const ExternalSessionsHeader: FC<TExternalSessionsHeaderProps> = (props) => {
    const { serverId, deleteButtonDisabled, onDeleteCreationDialog } = props;
    const intl = useIntl();

    const dispatch = useDispatch();

    const openCreationDialog = useCallback(() => {
        dispatch(openDialog(DialogType.ADD_EXTERNAL_SESSION, { serverId }));
    }, [serverId]);

    return (
        <Row className={theme.headerRow}>
            <Button dataTest="external-sessions_add-key-btn" visualStyle="primary" size="large" onClick={openCreationDialog}>
                {intl.formatMessage(messages.create)}
            </Button>
            <Button dataTest="external-sessions_delete-key-btn" size="large" disabled={deleteButtonDisabled} onClick={onDeleteCreationDialog}>
                {intl.formatMessage(messages.deleteButtonText)}
            </Button>
        </Row>
    );
};
