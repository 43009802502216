import { put, select, takeEvery } from 'redux-saga/effects';
import { CHANGE_USER_PASSWORD, OPEN_USER_ACCOUNT_TAB } from '../actionsTypes/userAccount.actionTypes';
import { TChangeUserPasswordAction } from '../actions/userAccount.actions.types';
import { LocalesService } from '../services/LocalesService';
import { TWorkspaceTab } from '../models/tab.types';
import { workspaceActivateTab, workspaceAddTab } from '../actions/tabs.actions';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import messages from '../modules/UserAccountTab/userAccount.messages';
import { PersonalDaoService } from '../services/dao/PersonalDAOService';
import { PersonalProfile } from '../serverapi/api';
import { setPersonalProfile } from '../actions/userAccount.actions';
import { UsersDAOService } from '../services/dao/UsersDAOService';
import { closeDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { showNotification } from '../actions/notification.actions';
import { v4 as uuid } from 'uuid';
import { NotificationType } from '../models/notificationType';
import { TabsSelectors } from '../selectors/tabs.selectors';

function* handleOpenUserAccountTab() {
    const intl = LocalesService.useIntl();
    const serverId: string = yield select(ServerSelectors.serverId);
    const tab: TWorkspaceTab | undefined = yield select(
        TabsSelectors.byId({
            id: WorkSpaceTabTypes.USER_ACCOUNT,
            repositoryId: WorkSpaceTabTypes.USER_ACCOUNT,
            serverId,
        }),
    );
    if (tab) {
        yield put(workspaceActivateTab(tab));
    } else {
        const userAccountTab: TWorkspaceTab = <TWorkspaceTab>{
            title: intl.formatMessage(messages.userAccountTab),
            type: WorkSpaceTabTypes.USER_ACCOUNT,
            nodeId: {
                id: WorkSpaceTabTypes.USER_ACCOUNT,
                repositoryId: WorkSpaceTabTypes.USER_ACCOUNT,
                serverId,
            },
            mode: EditorMode.Read,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        const profile: PersonalProfile = yield PersonalDaoService.getPersonalProfile();
        yield put(setPersonalProfile(profile));
        yield put(workspaceAddTab(userAccountTab));
    }
}

function* handleChangeUserPassword({ payload: { oldPassword, newPassword } }: TChangeUserPasswordAction) {
    yield UsersDAOService.changeCurrentUserPassword(oldPassword, newPassword);
    yield put(closeDialog(DialogType.CHANGE_PASSWORD_DIALOG));
    yield put(
        showNotification({
            id: uuid(),
            type: NotificationType.CHANGE_USER_PASSWORD_SUCCESS,
        }),
    );
}

export function* userAccountSaga() {
    yield takeEvery(OPEN_USER_ACCOUNT_TAB, handleOpenUserAccountTab);
    yield takeEvery(CHANGE_USER_PASSWORD, handleChangeUserPassword);
}
