// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EPCSelectRowTitleDialog__listItem__FlcfK{cursor:pointer;padding-left:10px !important}.EPCSelectRowTitleDialog__listItem__FlcfK:hover{background-color:var(--mainColor)}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/EPCSelectRowTitleDialog/EPCSelectRowTitleDialog.scss"],"names":[],"mappings":"AAAA,0CACI,cAAA,CACA,4BAAA,CAIJ,gDACI,iCAAA","sourcesContent":[".listItem {\r\n    cursor: pointer;\r\n    padding-left: 10px !important;\r\n}\r\n\r\n\r\n.listItem:hover {\r\n    background-color: var(--mainColor);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": "EPCSelectRowTitleDialog__listItem__FlcfK"
};
export default ___CSS_LOADER_EXPORT___;
