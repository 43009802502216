import { defineMessages } from 'react-intl';

export default defineMessages({
    monitoringRefresh: {
        id: 'AdminTools.monitoring.refresh',
        defaultMessage: 'Обновить',
    },
    monitoringClose: {
        id: 'AdminTools.monitoring.close',
        defaultMessage: 'Закрыть',
    },
    monitoringScriptEngine: {
        id: 'AdminTools.monitoring.scriptEngine',
        defaultMessage: 'Сервис выполнения скриптов',
    },
    monitoringImgService: {
        id: 'AdminTools.monitoring.imgService',
        defaultMessage: 'Сервис получения изображений',
    },
    monitoringLdapService: {
        id: 'AdminTools.monitoring.monitoringLdapService',
        defaultMessage: 'LDAP сервис',
    },
    monitoringMailServer: {
        id: 'AdminTools.monitoring.monitoringMailServer',
        defaultMessage: 'Сервис отправки почты',
    },
    monitoringKeycloakService: {
        id: 'AdminTools.monitoring.monitoringKeycloakService',
        defaultMessage: 'Keycloak сервис',
    },
});
