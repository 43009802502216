// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventDialogs__guidInput___oiLq{margin-bottom:10px}.EventDialogs__eventRow__gsDys{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex}.EventDialogs__eventColumn__g2_qB{margin-right:15px}.EventDialogs__eventColumn__g2_qB>.ant-checkbox-wrapper{width:10px;margin-right:15px}", "",{"version":3,"sources":["webpack://./src/modules/SimulationModeling/components/SimulationModeling/SettingsSidebar/Dialog/EventDialogs.scss"],"names":[],"mappings":"AACA,gCACI,kBAAA,CAGJ,+BACI,0BAAA,CAAA,0BAAA,CAAA,mBAAA,CAGJ,kCAKI,iBAAA,CAJA,wDACI,UAAA,CACA,iBAAA","sourcesContent":["\r\n.guidInput {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.eventRow {\r\n    display: inline-flex;\r\n}\r\n\r\n.eventColumn {\r\n    & > :global(.ant-checkbox-wrapper) {\r\n        width: 10px;\r\n        margin-right: 15px;\r\n    }\r\n    margin-right: 15px;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guidInput": "EventDialogs__guidInput___oiLq",
	"eventRow": "EventDialogs__eventRow__gsDys",
	"eventColumn": "EventDialogs__eventColumn__g2_qB"
};
export default ___CSS_LOADER_EXPORT___;
