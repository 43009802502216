import React, { FC, RefObject, useState } from 'react';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { useIntl } from 'react-intl';
import { Form, FormInstance } from 'antd';
import theme from './GreetingText.scss';
import messages from './GreetingText.messages';
import startScreenmessages from '../../../../StartScreen/components/StartScreen.messages';
import { InternationalString } from '@/serverapi/api';
import { SystemPropertiesFormItemNames } from '../../SystemProperties.types';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '@/selectors/systemProperties.selectors';

type TGreetingTextProps = {
    formRef: RefObject<FormInstance<any>>;
};

export const GreetingText: FC<TGreetingTextProps> = (props) => {
    const intl = useIntl();

    const greetingText = useSelector(SystemPropertiesSelectors.getGreetingText);
    const [newGreetingText, setNewGreetingText] = useState<InternationalString | undefined>(greetingText);

    const { formRef } = props;
    const form = formRef.current;

    const handleNameInput = (multiLangValue: InternationalString) => {
        form?.setFieldValue(SystemPropertiesFormItemNames.greetingText, multiLangValue);
        setNewGreetingText(multiLangValue);
    };

    return (
        <div className={theme.container}>
            <MultiLangInputDialog
                generalForm={form}
                label={intl.formatMessage(messages.title)}
                mainInputName={SystemPropertiesFormItemNames.greetingTextInput}
                multiLangValue={newGreetingText}
                onChange={handleNameInput}
                placeholder={intl.formatMessage(startScreenmessages.title)}
                textarea
                maxLength={450}
                autoSize={{ minRows: 6, maxRows: 6 }}
                showCounter
            />
            {/* Form.Item name="greatingText" создан для хранения значения greatingText в форме, визуально не отображается */}
            <Form.Item name={SystemPropertiesFormItemNames.greetingText} initialValue={newGreetingText} noStyle />
        </div>
    );
};
