import React from 'react';
import { calcTextWidth } from '@/utils/textWidth.utils';
import theme from './MatrixMainHeader.scss';
import { TMainHeaderLanes } from '../Matrix.types';

const sidePadding: number = 5;
const laneHeight: number = 15;
const font: string = '700 12px Segoe UI';

export const getTextLanes = (
    isCol: boolean,
    text: string,
    columnHeaderHeight: number,
    rowHeaderWidth: number,
): TMainHeaderLanes => {
    const calcLanesCount = (topPadding: number): number => {
        const defaultPadding: number = isCol ? 20 : 5;

        return Math.trunc((columnHeaderHeight - topPadding - defaultPadding) / laneHeight);
    };

    const calcLaneWidth = (topPadding: number, index: number): number => {
        return isCol
            ? ((columnHeaderHeight - topPadding - laneHeight * (index + 1)) / columnHeaderHeight) * rowHeaderWidth -
                  sidePadding
            : ((topPadding + laneHeight * index) / columnHeaderHeight) * rowHeaderWidth - sidePadding;
    };

    const getTextLanes = (topPadding: number): [number, number, JSX.Element[], boolean] => {
        const lanesCount: number = calcLanesCount(topPadding);
        const lanes: JSX.Element[] = [];
        let letterIndex: number = 0;

        let filledLanesCount: number = 0;
        let isTextOutside: boolean = false;

        for (let i = 0; i < lanesCount; i++) {
            const laneWidth: number = calcLaneWidth(topPadding, i);

            let currentLaneText: string = '';

            while (text.length > letterIndex && calcTextWidth(currentLaneText + text[letterIndex], font) < laneWidth) {
                currentLaneText += text[letterIndex];
                letterIndex++;
            }

            isTextOutside = i === lanesCount - 1 && letterIndex < text.length;

            if (currentLaneText !== '') {
                const isOnelaneText: boolean = letterIndex === text.length && filledLanesCount === 0;

                filledLanesCount += 1;

                const lane: JSX.Element = (
                    <div
                        key={`ColsTextLanes_${i}`}
                        className={theme.textLane}
                        style={{
                            height: `${laneHeight}px`,
                            width: `${laneWidth}px`,
                            justifyContent: `${isOnelaneText ? 'center' : 'initial'}`,
                        }}
                    >
                        {!isTextOutside ? currentLaneText : (currentLaneText += '...')}
                    </div>
                );
                lanes.push(lane);
            }
        }

        const bottomSpace: number = columnHeaderHeight - topPadding - filledLanesCount * laneHeight;

        return [bottomSpace, topPadding, lanes, isTextOutside];
    };

    let topPadding: number = isCol ? 5 : 25;
    let bottomSpace: number;
    let topSpace: number;
    let lanes: JSX.Element[] = [];
    let showTooltip: boolean = false;
    do {
        topPadding += 1;
        [bottomSpace, topSpace, lanes, showTooltip] = getTextLanes(topPadding);
    } while (topSpace < bottomSpace);

    return { showTooltip, lanes, topPadding, sidePadding };
};
