import {
    PermissionModel,
    PermissionModelPermissionEnum,
    PrincipalDescriptor,
    PrincipalPermissions,
} from '../../../../serverapi/api';
import { isUndefined } from 'is-what';
import { IPrincipalPermissionsColumnData, ResultPermissions } from './PermissionTables.types';

export function isPrincipalHasPatternInKeyForSearch(
    userPermissions: PrincipalPermissions,
    keyForSeach: string,
    principals: PrincipalDescriptor[],
    pattern: string,
): boolean {
    return !!principals
        .find((e) => e.id === userPermissions.principalId)
        ?.[keyForSeach]?.toLowerCase()
        ?.includes(pattern.toLowerCase());
}

function filterAndMapPermissions(data: PrincipalPermissions[]): PrincipalPermissions[] {
    const allowedPermissions: string[] = ['READ', 'CREATE', 'UPDATE', 'DELETE', 'CONTROL'];

    return data
        .map((principal) => ({
            ...principal,
            permissions:
                principal.permissions?.filter(
                    (permission) => permission.permission && allowedPermissions.includes(permission.permission),
                ) ?? [],
        }))
        .filter((principal) => principal.permissions?.length);
}

export function getFilteredData(
    pattern: string,
    data: PrincipalPermissions[],
    principals: PrincipalDescriptor[],
    filterType?: string,
): PrincipalPermissions[] {
    const matchesPattern = (principal: PrincipalPermissions): boolean => {
        return (
            isPrincipalHasPatternInKeyForSearch(principal, 'login', principals, pattern) ||
            isPrincipalHasPatternInKeyForSearch(principal, 'name', principals, pattern)
        );
    };

    let filteredData: PrincipalPermissions[] = !pattern ? data : data.filter(matchesPattern);

    filteredData = filterType ? filterAndMapPermissions(filteredData) : filteredData;

    if (filterType === ResultPermissions.usersWithPermissions) {
        filteredData = filteredData.filter((principal) =>
            principal.permissions?.some((permission) => permission.isGranting),
        );
    } else if (filterType === ResultPermissions.usersWithoutPermissions) {
        filteredData = filteredData.filter((principal) =>
            principal.permissions?.every((permission) => !permission.isGranting),
        );
    }

    return filteredData;
}

export function getPermissionByEnum(actionType: PermissionModelPermissionEnum, permissions?: Array<PermissionModel>) {
    let model: PermissionModel | undefined;
    if (!isUndefined(permissions)) {
        model = permissions.find((e) => e.permission === actionType);
    }

    return model;
}

export function getColumnsData(
    principals: Array<PrincipalDescriptor>,
    rawData: Array<PrincipalPermissions>,
    filter: string,
): IPrincipalPermissionsColumnData[] {
    const principalIds: number[] = principals.map((p) => p.id);
    const data: PrincipalPermissions[] = rawData.filter((d) =>
        d.principalId ? principalIds.includes(d.principalId) : false,
    );
    const filteredData: PrincipalPermissions[] = getFilteredData(filter, data, principals);
    const columnsData: IPrincipalPermissionsColumnData[] = filteredData.map((e) => {
        const item: IPrincipalPermissionsColumnData = {};
        item.principalPermissions = e;
        item.principal = principals.find((t) => t.id === e.principalId);
        item.authCreate = getPermissionByEnum('CREATE', e.permissions);
        item.authRead = getPermissionByEnum('READ', e.permissions);
        item.authWrite = getPermissionByEnum('UPDATE', e.permissions);
        item.authDelete = getPermissionByEnum('DELETE', e.permissions);
        item.authAdministration = getPermissionByEnum('CONTROL', e.permissions);

        return item;
    });

    return columnsData;
}
