import { defineMessages } from 'react-intl';

export default defineMessages({
    profileAddBtnLabel: {
        id: 'profileAddBtnLabel',
        defaultMessage: 'Добавить',
    },
    profileName: {
        id: 'profileName',
        defaultMessage: 'Название профиля',
    },
    profileUsersGroupsColTitle: {
        id: 'profileUsersGroupsColTitle',
        defaultMessage: 'Пользователи/группы',
    },
    deleteUserProfileQuestion: {
        id: 'deleteUserProfileQuestion',
        defaultMessage: 'Удаление профиля пользователя',
    },
    deleteUserProfileContent: {
        id: 'deleteUserProfileContent',
        defaultMessage: 'Вы действительно хотите удалить профиль пользователя',
    },
});
