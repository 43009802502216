// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SymbolTextEditorDialog__symbolGraphicalText__EGCJ0 .SymbolTextEditorDialog__textArea___2Gqn{height:100%;width:100%;resize:none}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/Dialog/SymbolTextEditorDialog.scss"],"names":[],"mappings":"AAEI,6FACI,WAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".symbolGraphicalText {\r\n\r\n    .textArea{\r\n        height: 100%;\r\n        width: 100%;\r\n        resize: none\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"symbolGraphicalText": "SymbolTextEditorDialog__symbolGraphicalText__EGCJ0",
	"textArea": "SymbolTextEditorDialog__textArea___2Gqn"
};
export default ___CSS_LOADER_EXPORT___;
