import { TReducer } from '../utils/types';
import { MatrixType } from '../serverapi/api';
import { IMatrixModelTypeData, TMatrixModelTypeState } from './matrixModelType.reducer.types';
import { MATRIX_MODEL_TYPE_REQUEST_SUCCESS } from '@/actionsTypes/matrixModelType.actionTypes';

const initial: TMatrixModelTypeState = {};

export const INITIAL_MATRIX_MODEL_TYPE_SERVER_STATE: IMatrixModelTypeData = {
    byId: {},
};

export const matrixModelTypeReducer: TReducer<TMatrixModelTypeState> = (state = initial, action) => {
    switch (action.type) {
        case MATRIX_MODEL_TYPE_REQUEST_SUCCESS: {
            const {
                payload: { matrixModelTypes, presetId },
            } = action;
            const matrixModelTypesMap = matrixModelTypes.reduce(
                (result: {}, item: MatrixType) => ({ ...result, [item.id]: item }),
                {},
            );

            return {
                ...state,
                [presetId]: {
                    byId: {
                        ...matrixModelTypesMap,
                    },
                },
            };
        }

        default:
            return state;
    }
};
