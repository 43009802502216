import { Checkbox, Col, InputNumber, Row } from 'antd';
import React, { FC } from 'react';
import theme from './KanbanCardEditor.scss';
import AlignButton from '../../../../../../FloatingAttributes/components/common/Buttons/AlignButton.component';
import { FontColorPickerButton } from '../../../../../../UIKit/components/FontSettings/FontSettings.component';
import icHorizontal from '../../../../../../../resources/icons/ic-border-horizontal.svg';
import icVertical from '../../../../../../../resources/icons/ic-border-vertical.svg';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import icLeft from '../../../../../../../resources/icons/ic-ribbon-text-left.svg';
import icCenter from '../../../../../../../resources/icons/ic-ribbon-text-center.svg';
import icRight from '../../../../../../../resources/icons/ic-ribbon-text-right.svg';
import icTop from '../../../../../../../resources/icons/ic-vertical-align-top.svg';
import icMiddle from '../../../../../../../resources/icons/ic-vertical-align-center.svg';
import icBottom from '../../../../../../../resources/icons/ic-vertical-align-bottom.svg';
import { KanbanCardSectorSettings, KanbanCardSectorStyleSettings } from '../../../../../../../serverapi/api';
import { KanbanHorizontalAlign, KanbanVerticalAlign } from '../../../../../../../models/kanban.types';
import { useIntl } from 'react-intl';
import messages from './KanbanCardEditor.messages';

type TCardSectorFormattingSettingsProps = {
    sector: KanbanCardSectorSettings;
    onChangeSettings: (sector: KanbanCardSectorSettings) => void;
};

const KanbanCardSectorFormattingSettings: FC<TCardSectorFormattingSettingsProps> = (props) => {
    const intl = useIntl();
    const { styles, name } = props.sector;

    const onAlignChange = (value, property) =>
        props.onChangeSettings({
            ...props.sector,
            styles: {
                ...styles,
                [property]: value,
            },
        });

    const onPaddingChange = (value, property) =>
        props.onChangeSettings({
            ...props.sector,
            styles: {
                ...styles,
                padding: {
                    ...styles.padding,
                    [property]: value,
                },
            },
        });

    const onBackgroundColorChange = (value: string) => {
        props.onChangeSettings({
            ...props.sector,
            styles: {
                ...styles,
                backgroundColor: value,
            },
        });
    };

    const onBorderDisplayChange = (e: CheckboxChangeEvent) => {
        const newStyles: KanbanCardSectorStyleSettings = {
            verticalAlign: styles.verticalAlign,
            horizontalAlign: styles.horizontalAlign,
            padding: styles.padding,
            backgroundColor: styles.backgroundColor,
        };
        if (!Boolean(styles.border)) {
            newStyles.border = {
                top: true,
                right: true,
                bottom: true,
                left: true,
                color: '#b3b3b3',
                width: 2,
            };
        }
        props.onChangeSettings({
            ...props.sector,
            styles: newStyles,
        });
    };

    const onBorderColorChange = (value: string) => {
        props.onChangeSettings({
            ...props.sector,
            styles: {
                ...props.sector.styles,
                border: {
                    ...props.sector.styles.border,
                    color: value,
                },
            } as KanbanCardSectorStyleSettings,
        });
    };

    const onBorderWidthChange = (value: number) => {
        props.onChangeSettings({
            ...props.sector,
            styles: {
                ...props.sector.styles,
                border: {
                    ...props.sector.styles.border,
                    width: value,
                },
            } as KanbanCardSectorStyleSettings,
        });
    };

    const onBorderSideToggle = (side: string) => {
        props.onChangeSettings({
            ...props.sector,
            styles: {
                ...styles,
                border: {
                    ...styles.border,
                    [side]: !styles.border?.[side],
                },
            } as KanbanCardSectorStyleSettings,
        });
    };

    return (
        <div className={theme.cardSectorFormattingContainer}>
            <div className={theme.cardSectorTitle}>{`${intl.formatMessage(messages.sector)} ${name}`}</div>
            <div>{intl.formatMessage(messages.locationWithinCell)} </div>
            <div className={theme.cardSectorAlignSelector}>
                <Row gutter={[2, 2]}>
                    <Col>
                        <AlignButton
                            tooltip={intl.formatMessage(messages.leftCorner)}
                            icon={icLeft}
                            onClick={() => onAlignChange(KanbanHorizontalAlign.LEFT, 'horizontalAlign')}
                            selected={styles.horizontalAlign === KanbanHorizontalAlign.LEFT}
                        />
                    </Col>
                    <Col>
                        <AlignButton
                            tooltip={intl.formatMessage(messages.center)}
                            icon={icCenter}
                            onClick={() => onAlignChange(KanbanHorizontalAlign.CENTER, 'horizontalAlign')}
                            selected={styles.horizontalAlign === KanbanHorizontalAlign.CENTER}
                        />
                    </Col>
                    <Col>
                        <AlignButton
                            tooltip={intl.formatMessage(messages.rightCorner)}
                            icon={icRight}
                            onClick={() => onAlignChange(KanbanHorizontalAlign.RIGHT, 'horizontalAlign')}
                            selected={styles.horizontalAlign === KanbanHorizontalAlign.RIGHT}
                        />
                    </Col>
                </Row>

                <Row gutter={[2, 2]}>
                    <Col>
                        <AlignButton
                            tooltip={intl.formatMessage(messages.top)}
                            icon={icTop}
                            onClick={() => onAlignChange(KanbanVerticalAlign.TOP, 'verticalAlign')}
                            selected={styles.verticalAlign === KanbanVerticalAlign.TOP}
                        />
                    </Col>
                    <Col>
                        <AlignButton
                            tooltip={intl.formatMessage(messages.center)}
                            icon={icMiddle}
                            onClick={() => onAlignChange(KanbanVerticalAlign.CENTER, 'verticalAlign')}
                            selected={styles.verticalAlign === KanbanVerticalAlign.CENTER}
                        />
                    </Col>
                    <Col>
                        <AlignButton
                            tooltip={intl.formatMessage(messages.bottom)}
                            icon={icBottom}
                            onClick={() => onAlignChange(KanbanVerticalAlign.BOTTOM, 'verticalAlign')}
                            selected={styles.verticalAlign === KanbanVerticalAlign.BOTTOM}
                        />
                    </Col>
                </Row>
            </div>
            <div className={theme.cardSectorPaddingSelector}>
                <div className={theme.cardSectorPaddingLabel}>{intl.formatMessage(messages.cellPaddings)}</div>
                <div>
                    <InputNumber
                        min={0}
                        max={500}
                        value={styles.padding.top}
                        onChange={(value: number) => onPaddingChange(value, 'top')}
                    />
                    <InputNumber
                        min={0}
                        max={500}
                        value={styles.padding.right}
                        onChange={(value: number) => onPaddingChange(value, 'right')}
                    />
                    <InputNumber
                        min={0}
                        max={500}
                        value={styles.padding.bottom}
                        onChange={(value) => onPaddingChange(value, 'bottom')}
                    />
                    <InputNumber
                        min={0}
                        max={500}
                        value={styles.padding.left}
                        onChange={(value) => onPaddingChange(value, 'left')}
                    />
                </div>
            </div>
            <div className={theme.cardSectorBackgroundColorSelector}>
                <div className={theme.cardSectorBackgroundColorSelectorLabel}>
                    {intl.formatMessage(messages.cellFilling)}
                </div>
                <FontColorPickerButton
                    value={styles.backgroundColor}
                    onChange={onBackgroundColorChange}
                />
            </div>

            <div className={theme.cardSectorBorderSelector}>
                <Checkbox checked={Boolean(styles.border)} onChange={onBorderDisplayChange}>
                    {intl.formatMessage(messages.cellBorder)}
                </Checkbox>
                {Boolean(styles.border) ? (
                    <div>
                        <div className={theme.cardSectorBorderColorWidthSelector}>
                            <div className={theme.cardSectorBorderColorSelector}>
                                <div className={theme.cardSectorBorderColorSelectorLabel}>
                                    {intl.formatMessage(messages.borderColor)}
                                </div>
                                <FontColorPickerButton
                                    value={styles.border?.color || '#b3b3b3'}
                                    onChange={onBorderColorChange}
                                />
                            </div>
                            <div className={theme.cardSectorBorderWidthSelector}>
                                <div className={theme.cardSectorBorderWidthSelectorLabel}>
                                    {intl.formatMessage(messages.borderThickness)}
                                </div>
                                <InputNumber
                                    min={0}
                                    max={500}
                                    value={styles.border?.width}
                                    onChange={onBorderWidthChange}
                                />
                            </div>
                        </div>
                        <div className={theme.cardSectorBorderDirectionSelector}>
                            <div className={theme.cardSectorBorderDirectionLabelSelector}>
                                {intl.formatMessage(messages.borders)}
                            </div>
                            <div className={theme.cardSectorBorderDirectionBtns}>
                                <AlignButton
                                    icon={icHorizontal}
                                    onClick={() => onBorderSideToggle('bottom')}
                                    selected={styles.border?.bottom || false}
                                    tooltip={intl.formatMessage(messages.bottom)}
                                />
                                <AlignButton
                                    icon={icHorizontal}
                                    onClick={() => onBorderSideToggle('top')}
                                    selected={styles.border?.top || false}
                                    tooltip={intl.formatMessage(messages.top)}
                                />
                                <AlignButton
                                    icon={icVertical}
                                    onClick={() => onBorderSideToggle('left')}
                                    selected={styles.border?.left || false}
                                    tooltip={intl.formatMessage(messages.left)}
                                />
                                <AlignButton
                                    icon={icVertical}
                                    onClick={() => onBorderSideToggle('right')}
                                    selected={styles.border?.right || false}
                                    tooltip={intl.formatMessage(messages.right)}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export { KanbanCardSectorFormattingSettings };
