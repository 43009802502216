// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Messages__btnContainer__SnIAh{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;padding-top:16px;padding-right:16px}.Messages__btnContainer__SnIAh .Messages__updateButton__B6E_K{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;padding:4px 12px 6px 12px;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;border-radius:4px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/ServerSettings/components/Messages/Messages.scss"],"names":[],"mappings":"AAAA,+BACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CACA,gBAAA,CACA,kBAAA,CACA,8DACE,0BAAA,CAAA,0BAAA,CAAA,mBAAA,CACA,yBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,iBAAA","sourcesContent":[".btnContainer {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  padding-top: 16px;\r\n  padding-right: 16px;\r\n  .updateButton {\r\n    display: inline-flex;\r\n    padding: 4px 12px 6px 12px;\r\n    justify-content: center;\r\n    align-items: flex-start;\r\n    border-radius: 4px;\r\n  }  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnContainer": "Messages__btnContainer__SnIAh",
	"updateButton": "Messages__updateButton__B6E_K"
};
export default ___CSS_LOADER_EXPORT___;
