import { connect } from 'react-redux';
import { WikiTableDialog } from '../../dialogs/WikiTableDialog/WikiTableDialog.component';
import { TTableDialogProps } from '../../dialogs/WikiTableDialog/WikiTableDialog.types';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { closeDialog } from '../../../actions/dialogs.actions';
import { TWikiTable } from '../../../models/tab.types';

const mapStateToProps = (): Partial<TTableDialogProps> => {
    return {
        formInitData: {
            rows: 2,
            cols: 2,
        },
    };
};

const mapDispatchToProps = (dispatch, ownProps: TTableDialogProps) => {
    return {
        onClose: () => {
            dispatch(closeDialog(DialogType.TABLE_EDIT_DIALOG_WIKI));
        },
        onSubmit: (wikiTable: TWikiTable) => {
            dispatch(closeDialog(DialogType.TABLE_EDIT_DIALOG_WIKI));
            if (ownProps.onSubmit) {
                ownProps.onSubmit(wikiTable);
            }
        },
    };
};

export const WikiTableDialogContainer = connect(mapStateToProps, mapDispatchToProps)(WikiTableDialog);
