import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { ModelSelectors } from './model.selectors';
import { ModelNode, ModelType, NodeId } from '../serverapi/api';
import { TreeSelectors } from './tree.selectors';
import { ModelTypeSelectors } from './modelType.selectors';
import { getSymbolsFromModelType } from '../utils/symbol.utils';

const getNavigation = (state: TRootState) => state.navigator;

export const getActiveTabs = createSelector(getNavigation, ({ activeTabs }) => activeTabs);

export const getCountActiveTabs = createSelector(getActiveTabs, (activeTabs) => activeTabs.length);

export const getSymbolsByModelId = (modelId: NodeId | undefined) =>
    createSelector(
        (state: TRootState) => state,
        (state) => {
            if (!modelId) {
                return [];
            }

            const model: ModelNode | undefined = ModelSelectors.byId(modelId)(state);
            if (!model?.modelTypeId) {
                return [];
            }

            const presetId: string | undefined = TreeSelectors.presetById(modelId)(state);

            const modelType: ModelType | undefined = ModelTypeSelectors.byId(
                { modelTypeId: model.modelTypeId, serverId: modelId.serverId },
                presetId,
            )(state);

            return getSymbolsFromModelType(modelType);
        },
    );
