import { v4 as uuid } from 'uuid';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { objectDefinitionService } from '@/services/ObjectDefinitionService';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { ObjectDefinitionNode, ObjectInstance, Symbol } from '@/serverapi/api';
import { getStore } from '@/store';
import { saveObjectDefinition } from '@/actions/entities/objectDefinition.actions';
import { SymbolTypeId } from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';

export const getPoolSymbolData = (graph: BPMMxGraph, rootCellValue: ObjectInstance): ObjectInstance[] => {
    const { objectDefinitionId } = rootCellValue;

    if (!objectDefinitionId) {
        console.error('No objectDefinitionId found');

        return [];
    }

    const nodeId = {
        id: objectDefinitionId,
        serverId: graph.id.serverId,
        repositoryId: graph.id.repositoryId,
    };
    const objectDefinition: ObjectDefinitionImpl | undefined = objectDefinitionService().getObjectDefinition(nodeId);

    if (!objectDefinition) {
        console.error('No objectDefinition found');

        return [];
    }
    const objectSymbol = graph.modelType?.symbols.find((s: Symbol) => s.id === objectDefinition?.idSymbol);

    if (!objectSymbol) {
        console.error('No object symbol found');

        return [];
    }

    const symbolTypeId =
        objectSymbol?.symbolTypeId === SymbolTypeId.VERTICAL_POOL
            ? SymbolTypeId.VERTICAL_SWIMLANE
            : SymbolTypeId.HORIZONTAL_SWIMLANE;

    const symbol = graph.modelType?.symbols.find(
        (s: Symbol) => s.symbolTypeId === symbolTypeId || s.objectType === symbolTypeId || s.id === symbolTypeId,
    );

    if (!symbol) {
        console.error('No swimlane symbol found');

        return [];
    }

    return [
        genSwimlane(graph, symbol, objectDefinition),
        genSwimlane(graph, symbol, objectDefinition),
        genSwimlane(graph, symbol, objectDefinition),
    ];
};

function genSwimlane(graph: BPMMxGraph, symbol: Symbol, poolObjectDefinition: ObjectDefinitionNode): ObjectInstance {
    const { repositoryId, serverId } = graph.id;
    const id = uuid();
    const store = getStore();

    const { parentNodeId } = poolObjectDefinition;
    const objectDefinition = objectDefinitionService().createObjectDefinition(serverId, {
        nodeId: {
            id,
            serverId,
            repositoryId,
        },
        objectTypeId: symbol.objectType,
        type: 'OBJECT',
        name: symbol?.name,
        idSymbol: symbol?.id,
        parentNodeId,
        isDirty: true,
    } as ObjectDefinitionImpl);

    store.dispatch(saveObjectDefinition(serverId, objectDefinition));

    return new ObjectInstanceImpl({
        id: uuid(),
        parent: '1',
        symbolId: symbol?.id,
        type: 'object',
        objectDefinitionId: id,
    });
}

export function getIsTitleHidden(symbolTypeId: string | undefined, symbols: Symbol[] | undefined): boolean {
    const symbol: Symbol | undefined = symbols?.find((s: Symbol) => s.id === symbolTypeId);

    return !!symbol?.labelStyle?.includes('noLabel=1');
}
