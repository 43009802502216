import { MxCell } from '../../mxgraph/mxgraph';
import { TCurrentUserProfile } from '../../reducers/userProfile.reducer.types';
import { DiagramElement, EdgeInstance, ObjectInstance, PresetTypesAcl } from '../../serverapi/api';

export const nvlAcl = (id: string = '', acl: PresetTypesAcl | undefined): PresetTypesAcl => {
    return {
        id: acl?.id ?? id,
        create: acl?.create ?? true,
        read: acl?.read ?? true,
        update: acl?.update ?? true,
        delete: acl?.delete ?? true,
    };
};

export class ProfileBllService {
    public static isSymbolDeletable = (profile: TCurrentUserProfile, symbolId: string = ''): boolean =>
        Boolean(
            nvlAcl(symbolId, profile?.symbolAcls[symbolId]).delete &&
                nvlAcl(symbolId, profile?.symbolAcls[symbolId]).update,
        );

    public static isSymbolEditable = (profile: TCurrentUserProfile, symbolId: string = ''): boolean =>
        Boolean(nvlAcl(symbolId, profile?.symbolAcls[symbolId]).update);

    public static isEdgeDeletable = (profile: TCurrentUserProfile, edgeId: string = '') =>
        Boolean(
            nvlAcl(edgeId, profile?.edgeTypeAcls[edgeId]).delete &&
                nvlAcl(edgeId, profile?.edgeTypeAcls[edgeId]).update,
        );

    public static isEdgeEditable = (profile: TCurrentUserProfile, edgeId: string = '') =>
        Boolean(nvlAcl(edgeId, profile?.edgeTypeAcls[edgeId]).update);

    public static isAttributeViewable = (profile?: TCurrentUserProfile, attributeTypeId: string = '') =>
        profile?.attributeTypeAcls?.[attributeTypeId]?.read ?? true;

    public static filterCellsDelete = (cells: MxCell[] = [], profile: TCurrentUserProfile | undefined): MxCell[] => {
        return cells.filter((c) => {
            const value: DiagramElement = c.getValue();
            if (!value || !profile) {
                return true;
            }
            if (value.type === 'object') {
                return ProfileBllService.isSymbolDeletable(profile, (value as ObjectInstance).symbolId);
            }
            if (value.type === 'edge') {
                return ProfileBllService.isEdgeDeletable(profile, (value as EdgeInstance).edgeTypeId);
            }

            return true;
        });
    };
}
