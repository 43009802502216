import { TReducer } from '../utils/types';
import {
    TObjectDefinitionFormStatus,
    TObjectDefinitionFormData,
    TObjectDefinitionFormInitData,
} from '../modules/Models/components/ObjectDefinition/ObjectDefinition.types';
import { OBJECT_DEFINITION_DIALOG_SUBMIT_RESULT } from '../actionsTypes/objectDefinitionDialog.actionTypes';
import { NodeId } from '../serverapi/api';
import { TreeNode } from '../models/tree.types';
import { TObjectDefinitionDialogState } from './objectDefinitionDialog.reducer.types';

const initial: TObjectDefinitionDialogState = {
    objectDefinitionFormData: <TObjectDefinitionFormData>{
        objectDefinitionType: {},
        objectDefinitionName: '',
    },

    objectDefinitionFormInitData: <TObjectDefinitionFormInitData>{
        parentNodeId: {} as NodeId,
        objectDefinitionTypes: [],
        objectDefinitionDialogTree: [],
        selectedNode: {} as TreeNode,
    },

    objectDefinitionFormStatus: <TObjectDefinitionFormStatus>{
        objectDefinitionPathStatus: 'success',
        objectDefinitionTypeStatus: 'success',
        objectDefinitionNameStatus: 'success',
    },
};

export const ObjectDefinitionDialogReducer: TReducer<TObjectDefinitionDialogState> = (state = initial, action) => {
    switch (action.type) {
        case OBJECT_DEFINITION_DIALOG_SUBMIT_RESULT: {
            const { result, error } = action.payload;
            const { objectDefinitionFormStatus } = state;
            if (result === 'error') {
                objectDefinitionFormStatus.error = error;
                state.objectDefinitionFormStatus = { ...objectDefinitionFormStatus };
            }

            return { ...state };
        }

        default: {
            return state;
        }
    }
};
