import { defineMessages } from 'react-intl';

export default defineMessages({
    newFolderType: {
        id: 'FolderTypeTab.newFolderType',
        defaultMessage: 'Новый тип папки',
    },
    name: {
        id: 'FolderTypeTab.name',
        defaultMessage: 'Название',
    },
    icon: {
        id: 'FolderTypeTab.icon',
        defaultMessage: 'Иконка',
    },
    addNewIcon: {
        id: 'FolderTypeTab.addNewIcon',
        defaultMessage: 'Новая иконка',
    },
    folderType: {
        id: 'FolderType.folderType',
        defaultMessage: 'Тип папки',
    },
    description: {
        id: 'FolderType.description',
        defaultMessage: 'Описание',
    },
    editFolderType: {
        id: 'FolderType.editFolderType',
        defaultMessage: 'Редактирование типа папки',
    },
    generalSettings: {
        id: 'FolderType.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    modelTypes: {
        id: 'FolderType.modelTypes',
        defaultMessage: 'Типы моделей',
    },
    objectTypes: {
        id: 'FolderType.objectTypes',
        defaultMessage: 'Типы объектов',
    },
    folderTypes: {
        id: 'FolderType.folderTypes',
        defaultMessage: 'Типы папок',
    },
    elements: {
        id: 'FolderType.elements',
        defaultMessage: 'Элементы',
    },
    breadCrumbs: {
        id: 'FolderType.breadCrumbs',
        defaultMessage: '{presetName} > {folderType} > {folderAction}',
    },
    deleteFolderType: {
        id: 'FolderType.deleteFolderType',
        defaultMessage: 'Удалить выбранные типы папок?',
    },
    deleteFolderTypes: {
        id: 'FolderType.deleteFolderTypes',
        defaultMessage: 'Будут удалены типы папок: ',
    },
    allowFoldersApproval: {
        id: 'FolderType.allowFoldersApproval',
        defaultMessage: 'Разрешить согласования типа папок',
    },
    attributes: {
        id: 'FolderType.attributes',
        defaultMessage: 'Атрибуты',
    },
});
