import React, { useCallback } from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { useIntl } from 'react-intl';
import messages from './ConfirnationDialog.messages';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TConfirmationDialogProps = {
    onSubmit: () => void;
    title: string;
    question: string;
    OKButtonText: string;
};

export const ConfirmationDialog = ({ onSubmit, title, question, OKButtonText }: TConfirmationDialogProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const onClose = useCallback(() => {
        dispatch(closeDialog(DialogType.CONFIRMATION));
    }, []);

    const handleSubmit = () => {
        onSubmit();
        onClose();
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'cancel-delete-key_btn',
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: OKButtonText,
                    visualStyle: 'primary',
                    dataTest: 'confirm-delete-key_btn',
                    danger: true,
                },
            ]}
        />
    );

    return (
        <Dialog open onOk={handleSubmit} onCancel={onClose} title={title} footer={footer}>
            <div>{question}</div>
        </Dialog>
    );
};
