import { CharacterMetadata, ContentBlock, ContentState } from 'draft-js';
import { TextAlignRenderer } from '../../renderers/TextAlign/TextAlignRenderer.component';
import { ContentToken, StateInline } from 'remarkable/lib';

const findTextAlignEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
) => {
    contentBlock.findEntityRanges((character: CharacterMetadata) => {
        const entityKey = character.getEntity();

        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'TEXTALIGN';
    }, callback);
};

function parser(state: StateInline, silent: boolean) {
    const start = state.pos;
    const isOpen = state.src.slice(start, start + 11) === '<TEXTALIGN_';
    const isClose = state.src.slice(start, start + 12) === '</TEXTALIGN>';

    if (!(isOpen || isClose) || silent) {
        return false;
    }

    const tail = state.src.slice(start);
    const openMatch = /^<TEXTALIGN_(left|right|center)>/g.exec(tail);
    const closeMatch = /^<\/TEXTALIGN>/g.exec(tail);

    if (!openMatch && !closeMatch) {
        state.pos = start;
        state.parser.tokenize(state);

        return false;
    }

    if (isOpen && openMatch) {
        if (!silent) {
            const content = <ContentToken>{ type: 'TEXT_ALIGN_open', level: state.level++ };
            Object.assign(content, { data: { align: openMatch[1] } });
            state.push(content);
            state.pos += openMatch[0].length;
            state.parser.tokenize(state);
        }
    }

    if (isClose && closeMatch) {
        state.push(<ContentToken>{ type: 'TEXT_ALIGN_close', level: --state.level });
        state.pos += closeMatch[0].length;
        state.parser.tokenize(state);
    }

    return true;
}

const TextAlignDecorator = {
    strategy: findTextAlignEntities,
    component: TextAlignRenderer,
    parser,
};

export { TextAlignDecorator };
