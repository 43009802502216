import { defineMessages } from 'react-intl';

export default defineMessages({
    main: {
        id: 'MainMenu.main',
        defaultMessage: 'Главная',
    },
    paste: {
        id: 'MainMenu.paste',
        defaultMessage: 'Вставка',
    },
    format: {
        id: 'MainMenu.format',
        defaultMessage: 'Формат',
    },
    layout: {
        id: 'MainMenu.layout',
        defaultMessage: 'Макет',
    },
    analyze: {
        id: 'MainMenu.analyze',
        defaultMessage: 'Анализ',
    },
    simulation: {
        id: 'MainMenu.simulation',
        defaultMessage: 'Симуляция',
    },
    view: {
        id: 'MainMenu.view',
        defaultMessage: 'Вид',
    },
    services: {
        id: 'MainMenu.services',
        defaultMessage: 'Службы',
    },
    administration: {
        id: 'MainMenu.administration',
        defaultMessage: 'Администрирование',
    },
});
