import React from 'react';
import theme from './ApprovalDialog.scss';
import messages from './ApprovalDialog.messages';
import { ApprovalAssistantDTO, ApprovalUserDTO, PrincipalDescriptor } from '@/serverapi/api';
import { Icon } from '../UIKit';
import classNames from 'classnames';
import icArrowDownDark from '../../resources/icons/icArrowDownDark.svg';
import { useIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import { getAntdPopupContainer } from '@/utils/getAntdPopupContainer';
import { getDisplayName, sortUsers } from '@/utils/users.utils';
import { DragDropContextComponent } from './DragDropContext.component';
import { StagesBllService } from '@/services/bll/ApprovalDialogStagesBLLService';
import { DropResult } from 'react-beautiful-dnd';
import { TStageNameAndApprovalUsersComponent } from './StageSettingsTab.types';

export const StageNameAndApprovalUsersComponent = ({
    selectedStage,
    isTemplate,
    isEditingDisabled,
    onChangeStages,
    stages,
    stageId,
    stageFormRef,
    showApprovalUsersErrorTemplate,
    showApprovalUsersError,
    isSelectUserOpen,
    setIsSelectUserOpen,
    notSelectedUsers,
    isCreateMode,
    userIdToAddAssistantTo,
    setUserIdToAddAssistantTo,
    users,
}: TStageNameAndApprovalUsersComponent) => {
    const intl = useIntl();

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const stageList = StagesBllService.changeName(stages, stageId, e.target.value);

        onChangeStages(stageList);
    };

    const onApprovalUserRemove = (id: number) => {
        const stageList = StagesBllService.approvalUserRemove(stages, stageId, id);

        onChangeStages(stageList);
    };

    const onApprovalAssistantRemove = (approvalUserId: number, assistantId: number) => {
        const stageList = StagesBllService.approvalAssistantRemove(stages, stageId, approvalUserId, assistantId);

        onChangeStages(stageList);
    };

    const editStageUsersDto = (approvalUsersDTO: ApprovalUserDTO[]) => {
        const stageList = StagesBllService.changeApprovalUsersDto(stages, stageId, approvalUsersDTO);

        onChangeStages(stageList);
    };

    const onApprovalUserAdd = (id: number) => {
        stageFormRef.current?.resetFields(['ApprovalUsers']);

        const approvalUser = users.find((user) =>
            userIdToAddAssistantTo ? user.id === userIdToAddAssistantTo : user.id === id,
        );
        const assistantUser = users.find((user) => userIdToAddAssistantTo && user.id === id);

        if (!approvalUser || (userIdToAddAssistantTo && !assistantUser)) {
            return;
        }

        const newUserName = getDisplayName(assistantUser || approvalUser);

        let approvalUsersDTO: ApprovalUserDTO[];

        if (userIdToAddAssistantTo && assistantUser && selectedStage.approvalUsersDTO) {
            approvalUsersDTO =
                selectedStage.approvalUsersDTO?.map((user) => {
                    if (user.principalId === userIdToAddAssistantTo) {
                        const newAssistantUser: ApprovalAssistantDTO = {
                            assistantId: assistantUser.id,
                            assistantLogin: assistantUser.login,
                            assistantName: newUserName,
                        };

                        return (
                            user.assistants
                                ? { ...user, assistants: [...user.assistants, newAssistantUser] }
                                : { ...user, assistants: [newAssistantUser] }
                        ) as ApprovalUserDTO;
                    }

                    return user;
                }) || [];
        } else {
            const newApprovalUser = {
                principalId: approvalUser.id,
                login: approvalUser.login,
                name: newUserName,
            } as ApprovalUserDTO;

            approvalUsersDTO = selectedStage.approvalUsersDTO
                ? [...selectedStage.approvalUsersDTO!, newApprovalUser]
                : [newApprovalUser];
        }

        setIsSelectUserOpen(false);
        setUserIdToAddAssistantTo(null);

        editStageUsersDto(approvalUsersDTO);
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination || !selectedStage.approvalUsersDTO) return;

        if (!isCreateMode && !isTemplate) {
            const userToReplace = selectedStage.approvalUsersDTO![result.destination.index];
            if (!!userToReplace.vote || !!userToReplace.comment) return;
        }
        const stageList = StagesBllService.dragEnd(
            stages,
            stageId,
            result.source.index,
            result.destination.index,
            selectedStage.approvalUsersDTO,
        );
        onChangeStages(stageList);
    };

    return (
        <>
            <Form.Item
                label={intl.formatMessage(messages.stageName)}
                name="StageName"
                initialValue={selectedStage.name}
                required
                rules={[
                    {
                        whitespace: true,
                        message: intl.formatMessage(messages.youEnteredOnlyWhitespaces),
                    },
                    {
                        required: true,
                        message: intl.formatMessage(messages.thisFieldIsRequired),
                    },
                ]}
            >
                <Input
                    disabled={isTemplate && isEditingDisabled}
                    value={selectedStage.name}
                    maxLength={60}
                    onChange={onChangeName}
                />
            </Form.Item>
            <Form.Item
                label={intl.formatMessage(messages.listOfApprovers)}
                required
                name="ApprovalUsers"
                className={classNames({
                    [theme.userSelectorError]:
                        (isTemplate && showApprovalUsersErrorTemplate) || (!isTemplate && showApprovalUsersError),
                })}
            >
                <Select
                    open={isSelectUserOpen}
                    onFocus={() => {
                        setIsSelectUserOpen(true);
                    }}
                    onBlur={() => {
                        setIsSelectUserOpen(false);
                    }}
                    suffixIcon={<Icon spriteSymbol={icArrowDownDark} />}
                    placeholder={intl.formatMessage(messages.selectUser)}
                    className={theme.w100}
                    onChange={onApprovalUserAdd}
                    disabled={!isTemplate && isEditingDisabled}
                    popupClassName={theme.dropdown}
                    getPopupContainer={getAntdPopupContainer}
                    virtual={false}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return ((option?.children ?? '') as string)
                            .toLowerCase()
                            .includes(input.toLocaleLowerCase().trim());
                    }}
                >
                    {sortUsers(notSelectedUsers).map((user: PrincipalDescriptor) => {
                        const { id } = user;
                        const displayName = getDisplayName(user);

                        return (
                            <Select.Option value={id} key={id}>
                                {displayName}
                            </Select.Option>
                        );
                    })}
                </Select>
                {(isTemplate && showApprovalUsersErrorTemplate) || (!isTemplate && showApprovalUsersError) ? (
                    <div className="ant-form-item-explain-error">
                        {intl.formatMessage(messages.thisFieldIsRequired)}
                    </div>
                ) : null}
            </Form.Item>
            {selectedStage.approvalUsersDTO ? (
                <DragDropContextComponent
                    isTemplate={isTemplate}
                    isCreateMode={isCreateMode}
                    isEditingDisabled={isEditingDisabled}
                    userIdToAddAssistantTo={userIdToAddAssistantTo}
                    approvalUsersDTO={selectedStage.approvalUsersDTO}
                    setIsSelectUserOpen={setIsSelectUserOpen}
                    setUserIdToAddAssistantTo={setUserIdToAddAssistantTo}
                    onDragEnd={onDragEnd}
                    onApprovalUserRemove={onApprovalUserRemove}
                    onApprovalAssistantRemove={onApprovalAssistantRemove}
                />
            ) : null}
        </>
    );
};
