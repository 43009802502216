import { MxCell, MxPopupMenu } from 'MxGraph';
import { ComplexSymbol } from '../ComplexSymbol.class';
import { CUSTOM_BOUND_INDICATOR } from '../ComplexSymbol.constants';
import ObjectInstanceSerializer from '../serializers/ObjectInstanceSerializer.class';
import messages from '../ComplexSymbol.messages';
import { EditorMode } from '@/models/editorMode';

export abstract class UMLSymbol extends ComplexSymbol {
    serializer = new ObjectInstanceSerializer();

    public get isConnectable(): boolean {
        return true;
    }

    protected isTitleHidden() {
        return true;
    }

    public hasCustomBound() {
        const cellValue = this.rootCell?.getValue() || this.rootCellValue;

        return cellValue?.metaInfo?.includes(CUSTOM_BOUND_INDICATOR);
    }

    public setCustomBound(value: boolean) {
        const rootCellValue = this.rootCell.getValue();
        const metaInfo = value
            ? `${CUSTOM_BOUND_INDICATOR};`
            : rootCellValue.metaInfo.replaceAll(`${CUSTOM_BOUND_INDICATOR};`, '');

        this.rootCell.setValue({ ...rootCellValue, metaInfo });

        if (!value) {
            this.redraw();
        }
    }

    public loadPopupMenu(menu: MxPopupMenu) {
        const { mode } = this.graph;
        const hasCustomSize = this.hasCustomBound();

        menu.addItem(
            this.graph.intl.formatMessage(hasCustomSize ? messages.unsetFixedSize : messages.setFixedSize),
            null,
            () => this.setCustomBound(!hasCustomSize),
            null,
            '',
            mode === EditorMode.Edit,
        );
    }

    public isCellMovable(cell: MxCell): boolean {
        return this.isRootCell(cell);
    }
}
