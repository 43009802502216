import { put, takeEvery } from "redux-saga/effects";
import { CHECK_BROWSER } from "../actionsTypes/checkBrowser.actionTypes";
import { openDialog } from "../actions/dialogs.actions";
import { DialogType } from "../modules/DialogRoot/DialogRoot.constants";
import { checkBrowserAndVersion } from "../services/utils/checkBrowserAndVersion";

function* checkBrowser() {
    const { isUnsupportedBrowser, browser, currentVersion, minVersion } = checkBrowserAndVersion();
    if (isUnsupportedBrowser) {
        yield put(openDialog(DialogType.CHECK_BROWSER_DIALOG, { name: browser, isUnsupportedBrowser }));
    } else if (minVersion) {
        yield put(openDialog(DialogType.CHECK_BROWSER_DIALOG, { name: browser, currentVersion, minVersion }));
    }
}

export function* checkBrowserSaga() {
    yield takeEvery(CHECK_BROWSER, checkBrowser);
}