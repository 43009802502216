import React from 'react';
import theme from './ChangePositionButtons.scss';
import { Button } from '../UIKit/components/Button/Button.component';

type TChangePositionButtonsProps = {
    upButtonDisabled: boolean;
    downButtonDisabled: boolean;
    onUp: () => void;
    onDown: () => void;
};

export const ChangePositionButtons = (props: TChangePositionButtonsProps) => {
    const { downButtonDisabled, upButtonDisabled, onUp: handleUpPosition, onDown: handleDownPosition } = props;

    return (
        <span className={theme.container}>
            <Button
                disabled={upButtonDisabled}
                onClick={(e) => {
                    e.stopPropagation();
                    handleUpPosition();
                }}
                dataTest="change-position-up_btn"
            >
                &uarr;
            </Button>
            <Button
                disabled={downButtonDisabled}
                onClick={(e) => {
                    e.stopPropagation();
                    handleDownPosition();
                }}
                dataTest="change-position-down_btn"
            >
                &darr;
            </Button>
        </span>
    );
};
