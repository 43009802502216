import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'DataTypesTab.name',
        defaultMessage: 'Название',
    },
    id: {
        id: 'DataTypesTab.id',
        defaultMessage: 'ID',
    },
    allowed: {
        id: 'DataTypesTab.allowed',
        defaultMessage: 'Разрешено',
    },
    allowAll: {
        id: 'DataTypesTab.allowAll',
        defaultMessage: 'Разрешить все',
    },
});
