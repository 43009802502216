import { Form, Typography } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../SystemProperties.messages';
import theme from '../../SystemProperties.scss';
import { CheckStatus, SystemPropertiesFormItemNames, TPropsWithFormRef } from '../../SystemProperties.types';
import { SystemPropertiesDaoService } from '../../../../../../src/services/dao/SystemPropertiesDaoService';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { useSelector } from 'react-redux';
import { FormItemInputWithLabel } from '../FormItemInputWithLabelComponent/FormItemInputWithLabel.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

export const ImageSettings = (props: TPropsWithFormRef) => {
    const intl = useIntl();
    const { Text } = Typography;
    const { formRef } = props;

    const uiUrl = useSelector(SystemPropertiesSelectors.getUiUrl);
    const imageUrl = useSelector(SystemPropertiesSelectors.getImageUrl);

    const [imageServiceStatus, setImageServiceStatus] = useState<CheckStatus>();

    const imageServiceStatusToCheckStatus = (status: string): CheckStatus => {
        switch (status) {
            case 'IMAGE_SERVICE_IS_ACTIVE':
                return CheckStatus.success;
            case 'UI_NOT_FOUND':
                return CheckStatus.internalFail;
            default:
                return CheckStatus.error;
        }
    };

    const handleCheckImageService = async () => {
        setImageServiceStatus(CheckStatus.validating);
        const form = formRef.current;

        try {
            const result = await SystemPropertiesDaoService.checkImageService(form?.getFieldsValue());

            setImageServiceStatus(imageServiceStatusToCheckStatus(result));
        } catch {
            setImageServiceStatus(CheckStatus.error);
        }
    };

    return (
        <div className={theme.formTab}>
            <FormItemInputWithLabel
                initialValue={uiUrl}
                name={SystemPropertiesFormItemNames.uiUrl}
                label={intl.formatMessage(messages.imageSilaUrl)}
                placeholder="http://localhost:8080/"
                data-test="server-settings_image_ui-adress-input"
            />
            <FormItemInputWithLabel
                initialValue={imageUrl}
                name={SystemPropertiesFormItemNames.imageUrl}
                label={intl.formatMessage(messages.imageUrl)}
                placeholder="http://localhost:8084/"
                data-test="server-settings_image_service-adress-input"
            />
            <Form.Item
                wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 16, offset: 8 },
                }}
            >
                <Button onClick={handleCheckImageService} dataTest="server-settings_image_check-server-btn">
                    {intl.formatMessage(messages.checkServer)}
                </Button>
                <span style={{ margin: '0 12px' }}>
                    {imageServiceStatus === CheckStatus.validating && (
                        <Text type="secondary">{`${intl.formatMessage(messages.check)}...`}</Text>
                    )}
                    {imageServiceStatus === CheckStatus.success && <Text>{intl.formatMessage(messages.works)}</Text>}
                    {imageServiceStatus === CheckStatus.internalFail && (
                        <Text type="warning">{intl.formatMessage(messages.notFoundUI)}</Text>
                    )}
                    {imageServiceStatus === CheckStatus.error && (
                        <Text type="danger">{intl.formatMessage(messages.notWork)}</Text>
                    )}
                </span>
            </Form.Item>
        </div>
    );
};
