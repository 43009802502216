// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WikiLinkDialog__iconContainer__y5Cey svg{width:20px;height:28px;margin:-10px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/WikiLinkDialog/WikiLinkDialog.scss"],"names":[],"mappings":"AAAA,0CACI,UAAA,CACA,WAAA,CACH,YAAA,CACA,cAAA","sourcesContent":[".iconContainer svg {\r\n    width: 20px;\r\n    height: 28px;\r\n\tmargin: -10px;\r\n\tcursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": "WikiLinkDialog__iconContainer__y5Cey"
};
export default ___CSS_LOADER_EXPORT___;
