import { TReducer } from '../utils/types';
import { GroupDTO } from '../serverapi/api';
import {
    FETCH_ALL_GROUPS_REQUEST,
    FETCHED_ALL_GROUPS_SUCCESS,
    EDIT_GROUP_DATA_BEGIN,
    EDIT_GROUP_DATA_END,
    DELETE_GROUP_SUCCESS,
    SAVE_GROUP_MAINDATA_CHANGES,
    FETCHED_GROUP_BYID_SUCCESS,
} from '../actionsTypes/groups.actionTypes';
import { TGroupsState } from './groups.reducer.types';
import { cloneDeep } from 'lodash-es';

const initial: TGroupsState = {
    byServerId: {},
    groupEdit: { byId: {} },
};

export const groupsReducer: TReducer<TGroupsState> = (state = initial, action) => {
    switch (action.type) {
        case FETCH_ALL_GROUPS_REQUEST: {
            const { serverId } = action.payload;

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        byId: {},
                    },
                },
            };
        }

        case FETCHED_ALL_GROUPS_SUCCESS: {
            const {
                payload: { serverId, groups },
            } = action;
            const groupsArr: GroupDTO[] = !Array.isArray(groups) ? [groups] : groups;
            const groupsMap = groupsArr.reduce(
                (result: {}, item: GroupDTO) => ({ ...result, [item.id || '']: item }),
                {},
            );

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        byId: {
                            ...(state.byServerId[serverId] ? state.byServerId[serverId].byId : {}),
                            ...groupsMap,
                        },
                    },
                },
            };
        }
        case FETCHED_GROUP_BYID_SUCCESS: {
            const { groupData, serverId } = action.payload;
            const byIdNew = { ...state.byServerId[serverId].byId };
            byIdNew[groupData.id || 0] = { ...groupData };

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        byId: {
                            ...byIdNew,
                        },
                    },
                },
            };
        }
        case EDIT_GROUP_DATA_BEGIN: {
            const { groupData } = action.payload;

            return {
                ...state,
                groupEdit: {
                    byId: {
                        ...state.groupEdit.byId,
                        [groupData.id]: groupData,
                    },
                },
            };
        }
        case EDIT_GROUP_DATA_END: {
            const { groupId } = action.payload;
            const newGroupEditState = cloneDeep(state.groupEdit);
            delete newGroupEditState.byId[groupId];

            return {
                ...state,
                groupEdit: newGroupEditState,
            };
        }
        case DELETE_GROUP_SUCCESS: {
            const { groupId, serverId } = action.payload;
            const byIdNew = { ...state.byServerId[serverId].byId };
            delete byIdNew[groupId];

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        byId: {
                            ...byIdNew,
                        },
                    },
                },
            };
        }
        case SAVE_GROUP_MAINDATA_CHANGES: {
            const { groupData } = action.payload;

            return {
                ...state,
                groupEdit: {
                    byId: {
                        ...state.groupEdit.byId,
                        [groupData.id]: groupData,
                    },
                },
            };
        }

        default:
            return state;
    }
};
