import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { ServerAuthDialog } from '../components/ServerAuthDialog.component';
import { TServerAuthDialogProps, TUserConnectInfo } from '../ServerForm.types';
import { login } from '../../../actions/authorization.actions';
import { TServerEntity } from '../../../models/entities.types';
import { getAuthorizationState } from '../../../selectors/authorization.selectors';

type TServerAuthDialogContainerProps = {
    serverId: string;
};

const mapStateToProps = (
    state: TRootState,
    props: TServerAuthDialogContainerProps,
): Partial<TServerAuthDialogProps> => {
    const server: TServerEntity | undefined = ServerSelectors.serverList(state).find((s) => s.id === props.serverId);
    const authState = getAuthorizationState(state);
    const pending = (authState && authState.pending) || false;

    return {
        error: authState.error,
        pending,
        server,
    };
};

const mapDispatchToProps = (dispatch): Partial<TServerAuthDialogProps> => ({
    onSubmit: (user: TUserConnectInfo, server: TServerEntity) =>
        dispatch(login(server.id, server.url, server.name, user.username, user.password, user.remember, false)),
    onCancel: () => dispatch(closeDialog(DialogType.AUTHORIZATION)),
});

export const ServerAuthDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ServerAuthDialog);
