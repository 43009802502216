import { TreeNode } from '../models/tree.types';

enum modelTypes {
    WIKI = 'wiki',
    MATRIX = 'matrix',
    KANBAN = 'Kanban',
}

export class ModelTypeUtils {
    /**
     * Метод для получения modelTypeId для wiki, matrix и других элементов,
     * не имеющих modelTypeId
     */
    public static getIdByTreeNode(treeNode: TreeNode): string {
        if (treeNode.modelTypeId) {
            return treeNode.modelTypeId;
        }
        if (modelTypes[treeNode.type]) {
            return modelTypes[treeNode.type];
        }

        return '';
    }
}
