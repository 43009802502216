//@test-ignore_ru

import { EPCGridRowType } from './EPCGrid.const';

export const defaultEPCGridLayout = {
    cells: [],
    rowHeaderWidth: 20,
    columnHeaderHeight: 20,
    columns: [
        {
            id: 'c1',
            name: { en: 'General process', ru: 'Организационные элементы и прикладная система' },
            headerSize: 200,
        },
        {
            id: 'c2',
            name: { en: '', ru: '' },
            headerSize: 600,
        },
    ],
    rows: [
        {
            id: 'r1',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r2',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r3',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r4',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r5',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r6',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r7',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r8',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r9',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r10',
            name: { en: '', ru: 'Выполняет и поддерживает' },
        },
        {
            id: 'r11',
            name: { en: '', ru: 'Прочее' },
        },
    ],
};

export const OrgUnitColumnSymbols = [
    'ST_ORG_UNIT_2',
    '73345b60-36c0-11e5-05b7-db7cafd96ef7',
    'ST_EMPL_TYPE',
    'ST_PERS_EXT',
    'ST_GRP',
    'ST_POS',
    'ST_APPL_SYS_TYPE',
];

export const MainColumnSymbols = [
    'ST_EV',
    '3f5f7330-7118-11e2-3463-e4115bf4fdb9',
    'd6e8a7b0-7ce6-11e2-3463-e4115bf4fdb9',
    'ST_FUNC',
    'a65774e0-95da-11ea-05b7-db7cafd96ef7',
    'a7de6350-c765-11e2-69e4-ac8112d1b401',
    'ST_PRCS_IF',
    '75f2e570-bdd3-11e5-05b7-db7cafd96ef7',
    'fd841c20-cc37-11e6-05b7-db7cafd96ef7',
    '53a01270-95da-11ea-05b7-db7cafd96ef7',
    'ST_SOLAR_FUNC',
    '65f965c0-a9cd-11e3-05b7-db7cafd96ef7',
    'ST_OPR_AND_1',
    'ST_OPR_OR_1',
    'ST_OPR_XOR_1',
    'ST_ORG_UNIT_2',
    '73345b60-36c0-11e5-05b7-db7cafd96ef7',
    'ST_EMPL_TYPE',
    'ST_PERS_EXT',
    'ST_GRP',
    'ST_CLST',
    '9e3a6c91-cc39-11e6-05b7-db7cafd96ef7',
    'b6f2dd50-cac3-11e3-05b7-db7cafd96ef7',
    'ST_TECH_TERM',
    'ST_FILE',
    'ST_DOC',
    '7096d320-cf42-11e2-69e4-ac8112d1b401',
    'ST_CRD_FILE',
    '690387a0-cc39-11e6-05b7-db7cafd96ef7',
    'ST_FOLD',
    'ST_PHONE',
    'ST_EMAIL_1',
    'ST_SCRN',
    'ST_APPL_SYS_TYPE',
    'ST_PERFORM',
    'ST_RISK_1',
    '7da01bf0-c218-11ea-05b7-db7cafd96ef7',
    'ST_SAP_NETWEAV_BUSINESS_SCENARIO',
    'ST_POS',
    'ST_INFO_CARR_CD',
    'ST_SOCKET',
    'c78ae840-95da-11ea-05b7-db7cafd96ef7',
];

export const generalTargetsSymbolsId = [
    'ST_FUNC',
    'a65774e0-95da-11ea-05b7-db7cafd96ef7',
    'a7de6350-c765-11e2-69e4-ac8112d1b401',
    'ST_PRCS_IF',
    '75f2e570-bdd3-11e5-05b7-db7cafd96ef7',
    'fd841c20-cc37-11e6-05b7-db7cafd96ef7',
    '53a01270-95da-11ea-05b7-db7cafd96ef7',
    'ST_SOLAR_FUNC',
    '65f965c0-a9cd-11e3-05b7-db7cafd96ef7',
    'ST_SAP_NETWEAV_BUSINESS_SCENARIO',
];

export const generalSourcesSymbolId = [
    'ST_ORG_UNIT_2',
    '73345b60-36c0-11e5-05b7-db7cafd96ef7',
    'ST_EMPL_TYPE',
    'ST_PERS_EXT',
    'ST_GRP',
    'ST_POS',
];

export const exeptionEdgeTypesMap = {
    [EPCGridRowType.CT_EXEC_CT_CAN_SUPP]: 'CT_CAN_SUPP_1',
};

export const commonEdgeTypesMap = {
    [EPCGridRowType.CT_EXEC_CT_CAN_SUPP]: 'CT_EXEC_2',
    [EPCGridRowType.CT_MUST_BE_INFO_ABT]: 'CT_MUST_BE_INFO_ABT_1',
    [EPCGridRowType.CT_MUST_BE_INFO_ON_CNC]: 'CT_MUST_BE_INFO_ON_CNC_1',
    [EPCGridRowType.CT_MUST_BE_INFO_ABT_RES]: 'CT_MUST_INFO_ABT_RES',
    [EPCGridRowType.CT_IS_TECH_RESP]: 'CT_IS_TECH_RESP_1',
    [EPCGridRowType.CT_IS_DP_RESP]: 'CT_IS_DP_RESP_1',
    [EPCGridRowType.CT_AGREES]: 'CT_AGREES',
    [EPCGridRowType.CT_DECID_ON]: 'CT_DECID_ON',
    [EPCGridRowType.CT_CONTR_TO]: 'CT_CONTR_TO_1',
    [EPCGridRowType.CT_HAS_CONSLT_ROLE_IN]: 'CT_HAS_CONSLT_ROLE_IN_1',
};

