import type { FavoriteNodeDTO, NodeId } from '@/serverapi/api';
import type {
    TFavoritesAddAction,
    TFavoritesFetchRequestAction,
    TFavoritesFetchSuccessAction,
    TFavoritesRemoveAction,
} from './favorites.actions.types';
import {
    FAVORITES_ADD,
    FAVORITES_FETCH_REQUEST,
    FAVORITES_FETCH_SUCCESS,
    FAVORITES_REMOVE,
} from '@/actionsTypes/favorites.actionTypes';

export const favoritesFetchRequest = (serverId: string): TFavoritesFetchRequestAction => ({
    type: FAVORITES_FETCH_REQUEST,
    payload: { serverId },
});

export const favoritesFetchSuccess = (favorites: FavoriteNodeDTO[]): TFavoritesFetchSuccessAction => ({
    type: FAVORITES_FETCH_SUCCESS,
    payload: { favorites },
});

export const favoritesAdd = (nodeId: NodeId): TFavoritesAddAction => ({
    type: FAVORITES_ADD,
    payload: { nodeId },
});

export const favoritesRemove = (nodeId: NodeId): TFavoritesRemoveAction => ({
    type: FAVORITES_REMOVE,
    payload: { nodeId },
});
