import React from 'react';
import TextAlignComponent from './controls/TextAlign.component';
import theme from './RichTextEditorToolbar.scss';
import FontFamilyComponent from './controls/FontFamily.component';
import FontSizeComponent from './controls/FontSize.component';
import InlineStyleComponent from './controls/InlineStyle.component';
import FontColorComponent from './controls/FontColor.component';
import ListsComponent from './controls/Lists.component';
import BGColorComponent from './controls/BGColor.component';
import ImageComponent from './controls/Image.component';
import TableComponent from './controls/Table.component';
import LinkComponent from './controls/Link.component';
import EraserComponent from './controls/Eraser.component';
import LineHeightComponent from './controls/LineHeight.component';
import classnames from 'classnames';
import DividerComponent from './controls/Divider.component';
import IndentComponent from './controls/Indent.component';
import getStateObserver from '../common/sharedState.class';
import ControlsContext from './Controls.context';

const RichTextEditorToolbar = ({ id, handlers }) => {
    const stateObserverStateObserver = getStateObserver(id);
    const { openImageDialog, openLinkDialog, uploadFile } = handlers;

    return (
        <div className={classnames(theme.container, theme.container_compact)} data-test="wiki-toolbar-group">
            {stateObserverStateObserver && (
                <ControlsContext.Provider value={stateObserverStateObserver}>
                    <div className={theme.controlsGroup} data-test="wiki-toolbar-group_font-settings">
                        <FontFamilyComponent />
                        <FontSizeComponent />
                        <LineHeightComponent />
                    </div>
                    <div className={theme.controlsGroup}>
                        <FontColorComponent />
                        <BGColorComponent />
                    </div>
                    <DividerComponent />
                    <div className={theme.controlsGroup}>
                        <InlineStyleComponent />
                    </div>
                    <DividerComponent />
                    <div className={theme.controlsGroup}>
                        <TextAlignComponent />
                        <IndentComponent />
                    </div>
                    <DividerComponent />
                    <div className={theme.controlsGroup}>
                        <ListsComponent />
                    </div>
                    <DividerComponent />
                    <div className={theme.controlsGroup}>
                        <LinkComponent openDialog={openLinkDialog} />
                        <ImageComponent openDialog={openImageDialog} uploadFile={uploadFile} />
                        <TableComponent />
                    </div>
                    <DividerComponent />
                    <div className={theme.controlsGroup} data-test="wiki-toolbar-group_eraser-button">
                        <EraserComponent />
                    </div>
                </ControlsContext.Provider>
            )}
        </div>
    );
};

export default RichTextEditorToolbar;
