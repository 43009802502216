import * as React from 'react';
import { useIntl } from 'react-intl';
import PrincipalLabel from '../PrincipalLabel/PrincipalLabel.component';
import messages from '../../messages/InstancePermissionTable.messages';
import { PermissionSwitch } from '../PermissionSwitch/PermissionSwitch.component';
import theme from './InstancePermissionsTable.scss';
import {
    PermissionModel,
    PermissionModelPermissionEnum,
    PrincipalDescriptor,
    PrincipalPermissions,
} from '../../../../serverapi/api';
import { isUndefined } from 'is-what';
import { getColumnsData } from './PermissionTablesUtils';
import { IPrincipalPermissionsColumnData } from './PermissionTables.types';
import { TableUIKit } from '../../../UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '../../../UIKit/components/Table/TableUIKit.types';
import { uniq } from 'lodash-es';
import { Alert, Spin } from 'antd';

type TInstancePermissionsTableProps = {
    loading: boolean;
    filter: string;
    data: Array<PrincipalPermissions>;
    principals: Array<PrincipalDescriptor>;
    selectedIds: number[];
    onChange: (principalId: number, column: string, isGranting: boolean | undefined) => void;
    onSelectionChanged: (selectedIds: number[]) => void;
    error: string | null;
};

export const InstancePermissionsTable = (props: TInstancePermissionsTableProps) => {
    const { onSelectionChanged, onChange, data, filter, selectedIds, principals, error, loading } = props;
    const intl = useIntl();

    if (loading) {
        return (
            <div className={theme.spinnerContainer}>
                <Spin />
            </div>
        );
    }

    const processAndRender = (
        model: PermissionModel | undefined,
        actionType: PermissionModelPermissionEnum,
        record: IPrincipalPermissionsColumnData,
    ) => {
        const currIsGranting: boolean | undefined = !isUndefined(model) ? model.isGranting : undefined;

        return renderColumns(currIsGranting, nextGranting(currIsGranting), record, actionType);
    };

    const nextGranting = (granting: boolean | undefined): boolean | undefined => {
        if (granting === undefined) {
            return true;
        }
        if (granting === true) {
            return false;
        }

        return undefined;
    };

    const renderColumns = (
        currIsGranting: boolean | undefined,
        nextIsGranting: boolean | undefined,
        record: IPrincipalPermissionsColumnData,
        actionType: PermissionModelPermissionEnum,
    ): JSX.Element => {
        const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
            onChange(record!.principalPermissions!.principalId!, actionType, nextIsGranting);
            e.stopPropagation();
        };

        return (
            <div className={theme.permissionCell} onClick={onClick}>
                <PermissionSwitch isGranting={currIsGranting} propagation />
            </div>
        );
    };

    const columns: TColumn[] = [
        {
            title: intl.formatMessage(messages.authority),
            dataKey: 'principal',
            withoutSorter: true,
            width: 305,
        },
        {
            title: intl.formatMessage(messages.create),
            dataKey: 'authCreate',
            withoutSorter: true,
        },
        {
            title: intl.formatMessage(messages.read),
            dataKey: 'authRead',
            withoutSorter: true,
        },
        {
            title: intl.formatMessage(messages.write),
            dataKey: 'authWrite',
            withoutSorter: true,
        },
        {
            title: intl.formatMessage(messages.delete),
            dataKey: 'authAdministration',
            withoutSorter: true,
        },
        {
            title: intl.formatMessage(messages.administration),
            dataKey: 'authDelete',
            withoutSorter: true,
        },
    ];

    const renderPermissionData = (
        model: PermissionModel | undefined,
        actionType: PermissionModelPermissionEnum,
        record: IPrincipalPermissionsColumnData,
    ): JSX.Element => {
        return (
            <div className={theme.permissionCell} data-test={`permission-table_column_${actionType}`}>
                {processAndRender(model, actionType, record)}
            </div>
        );
    };

    const columnsData: IPrincipalPermissionsColumnData[] = getColumnsData(principals, data, filter);
    const tableData: TTableData[] = columnsData.map((data) => ({
        principal: <PrincipalLabel {...data.principal!} />,
        authCreate: renderPermissionData(data.authCreate, 'CREATE', data),
        authRead: renderPermissionData(data.authRead, 'READ', data),
        authWrite: renderPermissionData(data.authWrite, 'UPDATE', data),
        authDelete: renderPermissionData(data.authDelete, 'DELETE', data),
        authAdministration: renderPermissionData(data.authAdministration, 'CONTROL', data),
        checked: selectedIds.find((id) => data.principal?.id === id),
        principalId: data.principal?.id,
    }));

    const handleCheckPrincipals = (rows: TTableData[], checked: boolean) => {
        const principalsList: PrincipalDescriptor[] = principals.filter((principal) =>
            rows.find((row) => row.principalId === principal.id),
        );
        if (checked) {
            onSelectionChanged(uniq([...selectedIds, ...principalsList.map((principal) => principal.id)]));
        } else {
            onSelectionChanged(selectedIds.filter((id) => !rows.find((row) => row.principalId === id)));
        }
    };

    return (
        <>
            {error && <Alert type="error" message={error} showIcon />}
            <div className={theme.tableWrapper} data-test="permission-dialog_permission-table">
                <TableUIKit columns={columns} tableData={tableData} withCkeckBox checkRows={handleCheckPrincipals} />
            </div>
        </>
    );
};
