import { put, takeEvery } from 'redux-saga/effects';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction } from '../modules/Tree/models/tree';
import { IWorkspaceTabFindDuplicatesParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { workspaceAddTab } from '../actions/tabs.actions';
import messages from '../modules/FindDuplicates/messages/FindDuplicatesTab.messages';
import { LocalesService } from '../services/LocalesService';
import { FIND_DUPLICATES_DATA_REQUEST, MERGE_DATA_REQUEST } from '@/actionsTypes/findDuplicates.actionTypes';
import { FindDuplicatesDaoService } from '@/services/dao/FindDuplicatesDaoService';
import {
    findDuplicatesDataFailure,
    findDuplicatesDataSuccess,
    mergeDataRequestFailure,
    mergeDataRequestSuccess,
    setStatuses,
} from '@/actions/findDuplicates.actions';
import { FindDuplicatesResponse, MergeResponse } from '@/serverapi/api';
import { TFindDuplicatesAction, TMergeDataRequestAction } from '@/actions/findDuplicates.actions.types';

function* handleOpenFindDuplicatesTab({ payload: { nodeId, action } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.FIND_DUPLICATES) {
        const intl = LocalesService.useIntl();
        const tab: TWorkspaceTab = {
            title: intl.formatMessage(messages.header),
            nodeId,
            type: WorkSpaceTabTypes.FIND_DUPLICATES,
            mode: EditorMode.Read,
            params: {
                nodeId,
            } as IWorkspaceTabFindDuplicatesParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };

        yield put(workspaceAddTab(tab));
    }
}

function* handleFindDuplicatesDataRequest({ payload: { nodeId, findOnlyChildDuplicates } }: TFindDuplicatesAction) {
    try {
        const data: FindDuplicatesResponse = yield FindDuplicatesDaoService.getData(nodeId, findOnlyChildDuplicates);
        yield put(findDuplicatesDataSuccess(nodeId, data));
    } catch (error) {
        yield put(findDuplicatesDataFailure());
        throw error;
    }
}

function* handleMergeDataRequest({ payload: { mergeRequest, nodeId } }: TMergeDataRequestAction) {
    try {
        const data: MergeResponse = yield FindDuplicatesDaoService.merge(mergeRequest);
        yield put(mergeDataRequestSuccess(nodeId, data));
        yield put(setStatuses(nodeId, data.list));
    } catch (error) {
        yield put(mergeDataRequestFailure());
        throw error;
    }
}

export function* findDuplicatesSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenFindDuplicatesTab);
    yield takeEvery(FIND_DUPLICATES_DATA_REQUEST, handleFindDuplicatesDataRequest);
    yield takeEvery(MERGE_DATA_REQUEST, handleMergeDataRequest);
}
