export const FOLDER_TYPE_REQUEST_SUCCESS = 'FOLDER_TYPE_REQUEST_SUCCESS';

export const FOLDER_TYPE_SAVE = 'FOLDER_TYPE_SAVE';

export const FOLDER_TYPE_DELETE = 'FOLDER_TYPE_DELETE';

export const FOLDER_TYPE_DELETE_SUCCESS = 'FOLDER_TYPE_DELETE_SUCCESS';

export const FOLDER_TYPE_SAVE_SUCCESS = 'FOLDER_TYPE_SAVE_SUCCESS';

export const FOLDER_TYPE_OPEN_EDIT_TAB = 'FOLDER_TYPE_OPEN_EDIT_TAB';
