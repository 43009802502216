import { all, call, put, takeEvery } from 'redux-saga/effects';
import { GET_QUERY_SELECT_ATTRIBUTE_VALUES } from '../actionsTypes/querySelectAttributeTypeValues.actionTypes';
import {
    TGetQuerySelectAttributeTypeValuesAction,
    TQueryData,
} from '../actions/querySelectAttributeTypeValues.actions.types';
import { AttributeQuerySelectDAOService } from '../services/dao/AttributeQuerySelectDaoService';
import {
    AttributeType,
    AttributeValueQueryModelSymbolsValueParam,
    AttributeValueQueryTypeIdValueParam,
    AttributeValueQueryParam,
} from '../serverapi/api';
import { querySelectAttributeTypeValuesRequestSuccess } from '../actions/querySelectAttributeTypeValues.actions';
import { filterAttributeTypesByValueTypes } from '../modules/ObjectPropertiesDialog/components/utils';

function* loadQuerySelectAttributesTypesValues({ payload }: TGetQuerySelectAttributeTypeValuesAction) {
    const { presetId, modelId, attributeTypes } = payload;

    const querySelectAttributesTypes: AttributeType[] = filterAttributeTypesByValueTypes(attributeTypes, [
        'QUERY_SELECT',
        'QUERY_MULTI_SELECT',
    ]);

    const queryValues: TQueryData[] = yield all(
        querySelectAttributesTypes.map((querySelectAttributesType) => {
            let param: AttributeValueQueryParam;
            switch (querySelectAttributesType.queryParams?.queryAttributeType) {
                case 'PRESETS': {
                    param = { queryAttributeType: 'PRESETS' };
                    break;
                }
                case 'MODEL_SYMBOLS': {
                    param = {
                        queryAttributeType: 'MODEL_SYMBOLS',
                        modelId,
                    } as AttributeValueQueryModelSymbolsValueParam;
                    break;
                }
                case 'SPREADSHEET': {
                    param = {
                        queryAttributeType: 'SPREADSHEET',
                        attributeTypeId: { presetId, id: querySelectAttributesType.id },
                    } as AttributeValueQueryTypeIdValueParam;
                    break;
                }
                default:
                    break;
            }

            return call(() => AttributeQuerySelectDAOService.getAttributeQueryData(param, querySelectAttributesType.id));
        }),
    );
    yield put(querySelectAttributeTypeValuesRequestSuccess(presetId, queryValues));
}

export function* querySelectAttributesTypesValues() {
    yield takeEvery(GET_QUERY_SELECT_ATTRIBUTE_VALUES, loadQuerySelectAttributesTypesValues);
}
