import { defineMessages } from 'react-intl';

export default defineMessages({
    fieldRequiredError: {
        id: 'fieldRequiredError',
        defaultMessage: 'Поле должно быть заполнено',
    },
    formStatusError: {
        id: 'formStatusError',
        defaultMessage: 'Ошибка формы',
    },
    confirmButton: {
        id: 'confirmButton',
        defaultMessage: 'Да',
    },
    cancelButton: {
        id: 'formDefault.cancelButton',
        defaultMessage: 'Отмена',
    },
    saveButtonLabel: {
        id: 'saveButtonLabel',
        defaultMessage: 'Сохранить',
    },
    addButtonLabel: {
        id: 'addButtonLabel',
        defaultMessage: 'Добавить',
    },
    deleteButtonLabel: {
        id: 'deleteButtonLabel',
        defaultMessage: 'Удалить',
    },
    OkButton: {
        id: 'OkButton',
        defaultMessage: 'OK',
    },
    NoButton: {
        id: 'NoButton',
        defaultMessage: 'Нет',
    },
    saveChangesQuestion: {
        id: 'saveChangesQuestion',
        defaultMessage: 'Сохранить изменения?',
    },
    choiceButtonLabel: {
        id: 'choiceButtonLabel',
        defaultMessage: 'Выбрать',
    },
});
