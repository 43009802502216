import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { TRootState } from '../../../reducers/root.reducer.types';
import { PrincipalsSelectors } from '../../../selectors/principals.selectors';
import { AttributeValuePrincipal } from '../../../serverapi/api';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { PrincipalAttributeDialog } from '../components/PrincipalAttributeType/PrincipalAttributeDialog.component';
import { TPrincipalAttributeDialogProps } from '../components/PrincipalAttributeType/PrincipalAttributeDialog.types';

const mapStateToProps = (state: TRootState, props: TPrincipalAttributeDialogProps): TPrincipalAttributeDialogProps => {
    return {
        ...props,
        groups: PrincipalsSelectors.getGroups(state),
        users: PrincipalsSelectors.getUsers(state),
    };
};

const mapDispatchToProps = (dispatch, props: TPrincipalAttributeDialogProps) => ({
    onCancel: () => dispatch(closeDialog(DialogType.PRICIPAL_ATTRIBUTE_DIALOG)),
    onSubmit: (value: AttributeValuePrincipal) => {
        props?.onSubmit(value);
        dispatch(closeDialog(DialogType.PRICIPAL_ATTRIBUTE_DIALOG));
    },
});

export const PrincipalAttributeDialogContainer = connect(mapStateToProps, mapDispatchToProps)(PrincipalAttributeDialog);
