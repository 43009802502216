import { defineMessages } from 'react-intl';

export default defineMessages({
    principalServerProfile: {
        id: 'principalServerProfile',
        defaultMessage: 'Редактирование профиля',
    },
});




