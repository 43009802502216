import React, { useCallback, useMemo } from 'react';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { getMenuItem } from '../../../../utils/antdMenuItem.utils';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceRemoveMultipleTabs, workspaceRemoveTabRequest } from '../../../../actions/tabs.actions';
import { MenuInfo } from 'rc-menu/lib/interface';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { TWorkspaceTabTitleContextMenuProps } from '../../Workspace.types';
import { IntlShape, useIntl } from 'react-intl';
import messages from '../../messages/WorkspaceTabTitleContextMenu.messages';
import { favoritesAdd, favoritesRemove } from '@/actions/favorites.actions';
import { FavoriteNodeDTO } from '@/serverapi/api';
import { FavoritesSelectors } from '@/selectors/favorites.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { ItemType } from 'antd/es/menu/hooks/useItems';

export const WorkspaceTabTitleContextMenu = ({
    tab,
    children,
    visible,
    onVisibilityChange,
}: TWorkspaceTabTitleContextMenuProps) => {
    const intl: IntlShape = useIntl();
    const dispatch = useDispatch();
    const tabs: TWorkspaceTab[] = useSelector(TabsSelectors.getTabList);
    const favoriteNodes: FavoriteNodeDTO[] = useSelector(FavoritesSelectors.nodes);
    const favouriteMenuItemStatus: ItemType = useSelector(FavoritesSelectors.getFavouriteTabStatus(tab));
    const tabIndex: number = useMemo(
        () => tabs.findIndex((_tab) => compareNodeIds(_tab.nodeId, tab.nodeId)),
        [tabs, tab],
    );
   
    const onClick = useCallback(
        (menuInfo: MenuInfo) => {
            menuInfo.domEvent.stopPropagation();
            onVisibilityChange();
            switch (menuInfo.key) {
                case 'addToFavourite':
                    dispatch(favoritesAdd(tab.nodeId));
                    break;
                case 'deleteFromFavourite':
                    dispatch(favoritesRemove(tab.nodeId));
                    break;
                case 'closeCurrent':
                    dispatch(workspaceRemoveTabRequest(tab));
                    break;
                case 'closeAnother':
                    dispatch(
                        workspaceRemoveMultipleTabs(tabs.filter((_tab) => !compareNodeIds(_tab.nodeId, tab.nodeId))),
                    );
                    break;
                case 'closeLeft':
                    dispatch(workspaceRemoveMultipleTabs(tabs.filter((_tab, index) => index < tabIndex)));
                    break;
                case 'closeRight':
                    dispatch(workspaceRemoveMultipleTabs(tabs.filter((_tab, index) => index > tabIndex)));
                    break;
                case 'closeAll':
                    dispatch(workspaceRemoveMultipleTabs(tabs));
                    break;
            }
        },
        [tabs, tab],
    );
    const items: MenuProps['items'] = useMemo(() => {
        return [
            favouriteMenuItemStatus,
            getMenuItem(<div>{intl.formatMessage(messages.closeCurrent)}</div>, 'closeCurrent', undefined, undefined, undefined, undefined, 'workspace-tab-name_context-menu-item_close-current'),
            getMenuItem(<div>{intl.formatMessage(messages.closeAnother)}</div>, 'closeAnother', undefined, undefined, undefined, undefined, 'workspace-tab-name_context-menu-item_close-another'),
            getMenuItem(<div>{intl.formatMessage(messages.closeLeft)}</div>, 'closeLeft', undefined, undefined, undefined, undefined, 'workspace-tab-name_context-menu-item_close-left'),
            getMenuItem(<div>{intl.formatMessage(messages.closeRight)}</div>, 'closeRight', undefined, undefined, undefined, undefined, 'workspace-tab-name_context-menu-item_close-right'),
            getMenuItem(<div>{intl.formatMessage(messages.closeAll)}</div>, 'closeAll', undefined, undefined, undefined, undefined, 'workspace-tab-name_context-menu-item_close-all'),
        ];
    }, [onClick, favoriteNodes]);

    return (
        <Dropdown
            trigger={['click']}
            menu={{ items, onClick }}
            open={visible}
            onOpenChange={onVisibilityChange}
            destroyPopupOnHide
        >
            {children}
        </Dropdown>
    );
};
