// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SummarySourceInput__label___DeD1{font-size:12px;line-height:18px;margin-bottom:4px}.SummarySourceInput__input__jR1wh{border-radius:4px !important;margin-top:4px}.SummarySourceInput__select__VTcHR{margin-top:4px;width:100%}.SummarySourceInput__select__VTcHR>div{border-radius:4px !important}.SummarySourceInput__inputContainer___oWcb{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/modules/Dashboard/WidgetSettingsPanel/SummarySourceInput/SummarySourceInput.scss"],"names":[],"mappings":"AAAA,kCACI,cAAA,CACA,gBAAA,CACA,iBAAA,CAEJ,kCACI,4BAAA,CACA,cAAA,CAGJ,mCACI,cAAA,CACA,UAAA,CACA,uCACI,4BAAA,CAIR,2CACI,iBAAA","sourcesContent":[".label {\r\n    font-size: 12px;\r\n    line-height: 18px;\r\n    margin-bottom: 4px;\r\n}\r\n.input {\r\n    border-radius: 4px !important;\r\n    margin-top: 4px;\r\n}\r\n\r\n.select {\r\n    margin-top: 4px;\r\n    width: 100%;\r\n    > div {\r\n        border-radius: 4px !important;\r\n    }\r\n}\r\n\r\n.inputContainer {\r\n    margin-bottom: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "SummarySourceInput__label___DeD1",
	"input": "SummarySourceInput__input__jR1wh",
	"select": "SummarySourceInput__select__VTcHR",
	"inputContainer": "SummarySourceInput__inputContainer___oWcb"
};
export default ___CSS_LOADER_EXPORT___;
