import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import icListIndentDescrease from 'icons/toolbar/controls/ic-indent-decrease.svg';
import icListIndentIncrease from 'icons/toolbar/controls/ic-indent-increase.svg';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { Dropdown } from '../../../Toolbar/Dropdown.component';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('paragraph').indent;

const getChangedBlock = (editor: Editor, action: string) => {
    switch (action) {
        case 'indent':
            return editor.chain().focus().indent().run();
        case 'outdent':
            return editor.chain().focus().outdent().run();
        default:
            return false;
    }
};

export const IndentComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, string>({ stateObserver, getFocusedBlock, getChangedBlock });
    const onChange = (activeActionId: string) => {
        setCurrentState(activeActionId);
    };

    const menuItems = [
        {
            value: 'indent',
            tooltip: intl.formatMessage(messages.indent),
            spriteSymbol: icListIndentIncrease,
            dataTest: 'wiki-toolbar-group_font-indent-button_increase',
        },
        {
            value: 'outdent',
            tooltip: intl.formatMessage(messages.outdent),
            spriteSymbol: icListIndentDescrease,
            dataTest: 'wiki-toolbar-group_font-indent-button_descrease',
        },
    ];

    return (
        <Dropdown<string>
            items={menuItems}
            title={intl.formatMessage(messages.textIndentation)}
            compact
            defaultSprite={icListIndentIncrease}
            onSelect={onChange}
            dataTest="wiki-toolbar-group_font-indent-button"
        />
    );
};
