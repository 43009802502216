export const OPEN_NOTIFICATION_TEMPLATES_TAB = 'OPEN_NOTIFICATION_TEMPLATES_TAB';

export const LOAD_NOTIFICATION_TEMPLATES = 'LOAD_NOTIFICATION_TEMPLATES';

export const ADD_NOTIFICATION_TEMPLATES = 'ADD_NOTIFICATION_TEMPLATES';

export const SAVE_NOTIFICATION_TEMPLATES = 'SAVE_NOTIFICATION_TEMPLATES';

export const CREATE_NOTIFICATION_TEMPLATES = 'CREATE_NOTIFICATION_TEMPLATES';

export const DELETE_NOTIFICATION_TEMPLATES = 'DELETE_NOTIFICATION_TEMPLATES';