import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { PRESET_SETTINGS_INITIAL_KANBAN_CARD_TYPE_SERVER_STATE } from '../../reducers/presetSettings/presetSettingsKanbanCardType.reducer';

const kanbanCardTypeStateSelector = (state: TRootState) => state.presetSettings.kanbanCardTypes;

export namespace PresetSettingsKanbanCardTypeSelectors {
    export const byPresetId = (presetId) =>
        createSelector(kanbanCardTypeStateSelector, (state) => {
            return state.byPresetId[presetId] || PRESET_SETTINGS_INITIAL_KANBAN_CARD_TYPE_SERVER_STATE;
        });

    export const listByPresetId = (presetId: string) =>
        createSelector(byPresetId(presetId), (state) => Object.values(state.byId));

    export const listByObjectTypeId = (presetId: string, objectTypeId: string) =>
        createSelector(listByPresetId(presetId), (state) =>
            state.filter((cardType) => cardType.objectTypeId === objectTypeId),
        );

    export const listToDeleteByPresetId = (presetId: string) =>
        createSelector(byPresetId(presetId), (state) => state.kanbanCardTypesForDelete);
}
