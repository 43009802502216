import { MxCellOverlay, MxCellState, MxPoint, MxRectangle } from '../mxgraph';

export default class BPMMxDecompositionCellOverlay extends MxCellOverlay {
    getEdgeDecompositionIconOffset(cellState: MxCellState, axis: 'x' | 'y', offset: number, scale: number): number {
        const lengthOfFirstSegment = cellState.segments[0] / scale;
        // первый сегмент считаем коротким если он меньше чем offset
        const isShortFirstSegment = lengthOfFirstSegment < offset;
        const axisOfShortSegment = cellState.absolutePoints[0].x !== cellState.absolutePoints[1].x ? 'x' : 'y';
        // вычисляем вдоль какой оси идет первый сегмент чтобы для этой оси возвращать lengthOfFirstSegment в качестве отступа
        const segmentOnCurrentAxis = axisOfShortSegment === axis;

        const zero: number = Math.round(cellState.absolutePoints[0][axis]);
        const one: number = Math.round(cellState.absolutePoints[1][axis]);
        const two: number | undefined = Math.round(cellState.absolutePoints[2]?.[axis]);

        if (Math.abs(zero - one) === 1 && !isShortFirstSegment) {
            return 0;
        }

        if (two && zero > two && isShortFirstSegment) {
            // возвращая lengthOfFirstSegment в качестве отступа иконка всегда будет находится на связи независимо от длины lengthOfFirstSegment
            return segmentOnCurrentAxis ? -lengthOfFirstSegment : -offset;
        }

        if (zero > one) {
            // если первый сегмент не короткий то отступы у иконки могут быть большими и она хорошо помещается
            return -offset;
        }

        if (two && zero < two && isShortFirstSegment) {
            return segmentOnCurrentAxis ? lengthOfFirstSegment : offset;
        }

        if (zero < one) {
            return offset;
        }

        return 0;
    }

    getEdgeOffset(edgeState: MxCellState) {
        const { scale } = edgeState.view;
        const absOffset = 25 / scale;
        const x = this.getEdgeDecompositionIconOffset(edgeState, 'x', absOffset, scale) + this.offset.x;
        const y = this.getEdgeDecompositionIconOffset(edgeState, 'y', absOffset, scale) + this.offset.y;

        return new MxPoint(x * scale, y * scale);
    }

    getBounds(state: MxCellState) {
        const bounds = MxCellOverlay.prototype.getBounds.apply(this, arguments);

        if (state.view.graph.getModel().isEdge(state.cell)) {
            const s = state.view.scale;
            const w = this.image.width;
            const h = this.image.height;
            const pt = state.absolutePoints[0];
            const offset = this.getEdgeOffset(state);

            return new MxRectangle(
                Math.round(pt.x - (w * this.defaultOverlap - offset.x) * s),
                Math.round(pt.y - (h * this.defaultOverlap - offset.y) * s),
                w * s,
                h * s,
            );
        }

        return bounds;
    }
}
