import { PresetImage, PresetImageGroup } from '../../serverapi/api';
import { apiBundle } from '../api/api-bundle';

export class PresetImagesDaoService {

    public static async createPresetImage(
        serverId: string,
        presetImage: PresetImage,
    ): Promise<void> {
        await apiBundle(serverId).presetImage.create({ body: presetImage });
    }

    public static async setPresetImageBulkSave(serverId: string, presetImages: PresetImage[]): Promise<void> {
        await apiBundle(serverId).presetImage.bulkSave({ body: presetImages });
    }

    public static async presetImageBulkDelete(serverId: string, presetImages: PresetImage[]): Promise<void> {
        await apiBundle(serverId).presetImage.bulkDelete({ body: presetImages });
    }


    public static async presetImageGroupBulkDelete(
        serverId: string,
        presetImageGroups: PresetImageGroup[],
    ): Promise<void> {
        await apiBundle(serverId).presetImageGroup.bulkDelete({ body: presetImageGroups });
    }

    public static async deletePresetImagesAndGroupsRequest(
        serverId,
        presetImages: PresetImage[],
        presetImageGroups: PresetImageGroup[],
    ): Promise<void> {
        await this.presetImageBulkDelete(serverId, presetImages);
        await this.presetImageGroupBulkDelete(serverId, presetImageGroups);
    }

    public static async getGivenPresetImageGroup(
        serverId: string,
        presetId: string,
        presetImageGroupId: string,
    ): Promise<PresetImageGroup> {
        const givenPresetImageGroup = await apiBundle(serverId).presetImageGroup.get({ presetId, presetImageGroupId });

        return givenPresetImageGroup;
    }

    public static async setPresetImageGroupBulkSave(
        serverId: string,
        presetImageGroups: PresetImageGroup[],
    ): Promise<void> {
        await apiBundle(serverId).presetImageGroup.bulkSave({ body: presetImageGroups });
    }

    public static async createPresetImageGroup(
        serverId: string,
        presetImageGroup: PresetImageGroup,
    ): Promise<void> {
        await apiBundle(serverId).presetImageGroup.create({ body: presetImageGroup });
    }
}
