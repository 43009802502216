import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'MoveToSelectDialog.title',
        defaultMessage: 'Перейти',
    },
    decomposition: {
        id: 'MoveToSelectDialog.decomposition',
        defaultMessage: 'Декомпозиция',
    },
    objectInTree: {
        id: 'MoveToSelectDialog.objectInTree',
        defaultMessage: 'Найти в Навигаторе',
    },
});
