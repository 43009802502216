export const PRESET_IMAGE_REQUEST_SUCCESS = 'PRESET_IMAGE_REQUEST_SUCCESS';

export const PRESET_IMAGE_DELETE_REQUEST_SUCCESS = 'PRESET_IMAGE_DELETE_REQUEST_SUCCESS';

export const DELETE_PRESET_IMAGE = 'DELETE_PRESET_IMAGE';

export const EDIT_PRESET_IMAGE = 'EDIT_PRESET_IMAGE';

export const DELETE_PRESET_IMAGE_AND_GROUP = 'DELETE_PRESET_IMAGE_AND_GROUP';

export const CREATE_PRESET_IMAGE = 'CREATE_PRESET_IMAGE';

export const SUBMIT_PRESET_IMAGE = 'SUBMIT_PRESET_IMAGE';

