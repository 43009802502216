export const SAVE_PRESET_PROFILE = 'SAVE_PRESET_PROFILE';

export const OPEN_EDIT_PRESET_PROFILE_TAB = 'OPEN_EDIT_PRESET_PROFILE_TAB';

export const GET_PRESET_PROFILES_SUCCESS = 'GET_PRESET_PROFILES_SUCCESS';

export const ADD_PRESET_PROFILE = 'ADD_PRESET_PROFILE';

export const DELETE_PRESET_PROFILE = 'DELETE_PRESET_PROFILE';

export const EDIT_PRESET_PROFILE = 'EDIT_PRESET_PROFILE';

export const COPY_PRESET_PROFILE = 'COPY_PRESET_PROFILE';
