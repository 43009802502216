import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { deleteVisioPage } from '../../../../../actions/import.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import style from './ImportVisioCollapseTableHeader.component.scss';
import messages from '../../messages/ImportVisioCollapseTableHeader.messages';
import { useIntl } from 'react-intl';

type TTextBlockProps = {
    header: string;
    text: string;
    onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
};

const TextBlock = ({ header, text, onClick }: TTextBlockProps) => {
    const intl = useIntl();

    return (
        <div className={style.textBlock}>
            <span>
                {header}
                {`: `}
            </span>
            {text === '' ? (
                <span className={`${style.text} ${style.red}`} onClick={onClick}>
                    {`<${intl.formatMessage(messages.notSelect)}>`}
                </span>
            ) : (
                <span className={style.text}>{text}</span>
            )}
        </div>
    );
};

export const ImportVisioCollapseTableHeader = ({
    fileName,
    visioPageName,
    silaModelTypeId,
    silaModelName,
    silaModelTypeName,
    visioPageId,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClickDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(deleteVisioPage({ fileName, visioPageId }));
    };

    const handleClickEdit = (e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(
            openDialog(DialogType.EDIT_IMPORT_VISIO_PAGE_HEADER, {
                fileName,
                visioPageName,
                silaModelTypeId,
                silaModelName,
                silaModelTypeName,
                visioPageId,
            }),
        );
    };

    return (
        <div className={style.headerContainer}>
            <div className={style.textContainer}>
                <TextBlock header={intl.formatMessage(messages.file)} text={fileName} />
                <TextBlock header={intl.formatMessage(messages.page)} text={visioPageName} />
                <TextBlock
                    header={intl.formatMessage(messages.modelType)}
                    text={silaModelTypeName}
                    onClick={handleClickEdit}
                />
                <TextBlock header={intl.formatMessage(messages.modelName)} text={silaModelName} />
            </div>
            <div className={style.iconContainer}>
                <EditTwoTone style={{ fontSize: '16px' }} onClick={handleClickEdit} />
                <DeleteTwoTone twoToneColor="#AA0000" style={{ fontSize: '16px' }} onClick={handleClickDelete} />
            </div>
        </div>
    );
};
