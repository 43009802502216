import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';

const getAllPermissionsDialogState = (state: TRootState) => state.allPermissions;

export const getAllPermissions = createSelector(getAllPermissionsDialogState, (state) => state.data);

export const getServerId = createSelector(getAllPermissionsDialogState, (state) => state.serverId);

export const getAllPermissionsWithLeastOneGranting = createSelector(getAllPermissions, (state) =>
    state?.filter((permission) => permission.permissions?.some((el) => el.isGranting)),
);

export const getAllPermissionsIsLoading = createSelector(getAllPermissionsDialogState, (state) => state.loading);

export const getAllPermissionsError = createSelector(getAllPermissionsDialogState, (state) => state.error);
