import { Form, Upload } from 'antd';
import theme from '../../SystemProperties.scss';
import DeletedIcon from '../../../../../resources/icons/Deleted.svg';
import { Icon as UIKitIcon } from '../../../../UIKit/components/Icon/Icon.component';
import UploadIcon from '../../../../../resources/icons/Upload.svg';
import { useIntl } from 'react-intl';
import messages from '../../SystemProperties.messages';
import React from 'react';
import { SystemPropertiesFormItemNames } from '../../SystemProperties.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

export const UserLogoUpload = ({ logo, greetingLogo, getValueFromEvent, onDeleteFile, beforeUpload, dropFile }) => {
    const intl = useIntl();

    return (
        <div className={theme.uploadLogoContainer}>
            <Form.Item
                initialValue={greetingLogo}
                name={SystemPropertiesFormItemNames.greetingLogo}
                className={theme.formItemUpload}
                label={intl.formatMessage(messages.greetingLogo)}
                getValueFromEvent={getValueFromEvent}
            >
                {logo ? (
                    <div className={theme.imageContainer}>
                        <div className={theme.uploadImageWrapper}>
                            <img src={logo} className={theme.uploadImage} />
                        </div>
                        <div className={theme.buttonWrapper}>
                            <Button size="small" onClick={onDeleteFile} icon={DeletedIcon}>
                                {intl.formatMessage(messages.deleteButton)}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={theme.uploadArea}>
                        <Upload.Dragger
                            name="file"
                            multiple={false}
                            showUploadList={false}
                            accept=".svg"
                            beforeUpload={beforeUpload}
                            customRequest={dropFile}
                        >
                            <div className={theme.uploadAreaTextWrapper}>
                                <div className={theme.uploadAreaTitle}>
                                    <span className={theme.uploadAreaTitleIcon}>
                                        <UIKitIcon className={theme.uploadSvg} spriteSymbol={UploadIcon} />
                                    </span>
                                    <span className={theme.uploadAreaTitleText}>
                                        {intl.formatMessage(messages.uploadLogo)}
                                    </span>
                                </div>
                                <div className={theme.uploadDescriptionSize}>
                                    {intl.formatMessage(messages.fileMaxSize)}
                                </div>
                                <div className={theme.uploaLogodDescriptionType}>
                                    {intl.formatMessage(messages.uploaLogodDescriptionType)}
                                </div>
                            </div>
                        </Upload.Dragger>
                    </div>
                )}
            </Form.Item>
            <div className={theme.alignContentBlock} />
        </div>
    );
};
