import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { Node } from '../serverapi/api';
import { TNodesState } from '../reducers/nodes.reducer.types';
import { compareNodeIds } from '../utils/nodeId.utils';

const getState = (state: TRootState) => state.nodes;

export const nodesSelector = createSelector<TRootState, TNodesState, Node[]>(getState, (state) => state.values());

export const nodeSelectorByNodeId = (nodeId) =>
    createSelector<TRootState, TNodesState, Node | undefined>(getState, (state) =>
        state.values().find((node) => compareNodeIds(node.nodeId, nodeId)),
    );
