// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportRepositoryDialog__itemType__noEQ9 .ant-form-item-label label{white-space:normal}.ImportRepositoryDialog__conflictResolutionStrategyBlock__gVZU_ .ant-input-lg{width:10%}.ImportRepositoryDialog__conflictResolutionStrategyBlock__gVZU_ .ant-input-suffix{position:static;position:initial}", "",{"version":3,"sources":["webpack://./src/modules/ImportDialog/components/ImportRepositoryDialog/ImportRepositoryDialog.scss"],"names":[],"mappings":"AAEQ,oEACI,kBAAA,CAOJ,8EACI,SAAA,CAEJ,kFACI,eAAA,CAAA,gBAAA","sourcesContent":[".itemType {\r\n    :global {\r\n        .ant-form-item-label label {\r\n            white-space: normal;\r\n        }\r\n    }\r\n}\r\n\r\n.conflictResolutionStrategyBlock {\r\n    :global {\r\n        .ant-input-lg {\r\n            width: 10%;\r\n        }\r\n        .ant-input-suffix {\r\n            position: initial;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemType": "ImportRepositoryDialog__itemType__noEQ9",
	"conflictResolutionStrategyBlock": "ImportRepositoryDialog__conflictResolutionStrategyBlock__gVZU_"
};
export default ___CSS_LOADER_EXPORT___;
