import { CURRENT_USER_PROFILE_REQUEST_SUCCESS } from "../actionsTypes/profile.actionTypes";
import { ProfileAcls } from "../serverapi/api";
import { TUserProfileRequestSuccess } from "./userProfile.actions.types";

export const currentUserProfileRequestSuccess = (serverId: string, presetId: string, profileAcls: ProfileAcls): TUserProfileRequestSuccess => ({
    type: CURRENT_USER_PROFILE_REQUEST_SUCCESS,
    payload: {
        serverId, presetId, profileAcls
    },
});
