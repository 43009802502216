import { LabelStyle } from '../models/labelStyle';
import { ButtonEditLabelState } from '../models/buttonEditLabelState';
import { TEdgeTypeSelectorState } from '../models/edgeTypeSelectorState.types';
import { MainMenuItems } from '../models/mainMenu';
import {
    GENERAL_MENU_CLICK,
    GENERAL_MENU_CLOSE,
    GENERAL_MENU_COLLAPSE,
    GENERAL_MENU_EXPAND,
    GENERAL_MENU_LABEL_STYLE_CHANGE,
    GENERAL_MENU_OPEN,
    GENERAL_MENU_TOGGLE_STYLE_BUTTONS,
    GENERAL_MENU_UPDATE_AVAILABLE_EDGE_TYPES,
} from '../actionsTypes/generalMenu.actionTypes';
import {
    TGeneralMenuOpenAction,
    TGeneralMenuCloseAction,
    TMainMenuClickAction,
    TGeneralMenuCollapseAction,
    TGeneralMenuExpandAction,
    TGeneralMenuLabelStyleChangeAction,
    TGeneralMenuToggleStyleButtonsAction,
    TGeneralMenuUpdateAvailableEdgeTypes,
} from './generalMenu.actions.types';

export const generalMenuOpen = (): TGeneralMenuOpenAction => ({
    type: GENERAL_MENU_OPEN,
});

export const generalMenuClose = (): TGeneralMenuCloseAction => ({
    type: GENERAL_MENU_CLOSE,
});

export const generalMenuClick = (key: MainMenuItems): TMainMenuClickAction => ({
    type: GENERAL_MENU_CLICK,
    payload: {
        key,
    },
});

export const generalMenuCollapse = (): TGeneralMenuCollapseAction => ({
    type: GENERAL_MENU_COLLAPSE,
});

export const generalMenuExpand = (): TGeneralMenuExpandAction => ({
    type: GENERAL_MENU_EXPAND,
});

export const generalMenuLabelStyleChangeAction = (
    action: LabelStyle,
    value: number | string | boolean,
    isActive?: boolean,
): TGeneralMenuLabelStyleChangeAction => ({
    type: GENERAL_MENU_LABEL_STYLE_CHANGE,
    payload: {
        action,
        value,
        isActive,
    },
});

export const generalMenuToggleStyleButtonsAction = (
    buttonEditLabelState: ButtonEditLabelState,
): TGeneralMenuToggleStyleButtonsAction => ({
    type: GENERAL_MENU_TOGGLE_STYLE_BUTTONS,
    payload: {
        buttonEditLabelState,
    },
});

export const generalMenuUpdateAvailableEdgeTypesAction = (
    edgeTypeSelectorState: TEdgeTypeSelectorState,
): TGeneralMenuUpdateAvailableEdgeTypes => ({
    type: GENERAL_MENU_UPDATE_AVAILABLE_EDGE_TYPES,
    payload: {
        edgeTypeSelectorState,
    },
});
