import {
    TSetSessionSearchDataAction,
    TSetSessionSearchTextAction,
    TSetSessionActiveAction,
    TSetSessionRangePayload,
    TSetSessionRangeAction,
    TGetSessionDataAction,
    TUserSessionLogoutAction,
    TUserSessionEndAction,
} from './sessions.actions.types';
import { SessionHistory } from '../serverapi/api';
import {
    SET_SESSION_SEARCH_DATA,
    SET_SESSION_SEARCH_TEXT,
    SET_SESSION_ACTIVE,
    SET_SESSION_RANGE,
    GET_SESSION_DATA,
    USER_SESSION_LOGOUT,
    USER_SESSION_END,
} from '../actionsTypes/sessions.actionTypes';

export const setSessionSearchData: (payload: SessionHistory[]) => TSetSessionSearchDataAction = (payload) => ({
    type: SET_SESSION_SEARCH_DATA,
    payload,
});

export const setSessionSearchText: (payload: string) => TSetSessionSearchTextAction = (payload) => ({
    type: SET_SESSION_SEARCH_TEXT,
    payload,
});

export const setSessionActive: (payload: boolean) => TSetSessionActiveAction = (payload) => ({
    type: SET_SESSION_ACTIVE,
    payload,
});

export const setSessionRange: (payload: TSetSessionRangePayload) => TSetSessionRangeAction = (payload) => ({
    type: SET_SESSION_RANGE,
    payload,
});

export const getSessionData = (): TGetSessionDataAction => ({
    type: GET_SESSION_DATA,
});

export const userSessionLogout: (userId: number) => TUserSessionLogoutAction = (userId) => ({
    type: USER_SESSION_LOGOUT,
    payload: {
        userId,
    },
});

export const userSessionEnd: (userId: number) => TUserSessionEndAction = (userId) => ({
    type: USER_SESSION_END,
    payload: {
        userId,
    },
});
