import { SELECT_OBJECT_DEFINITION_DIALOG_PREPARE_DATA_SUCCESS } from '../actionsTypes/selectObjectDefinitionDialog.actionTypes';
import { TReducer } from '../utils/types';
import { TSelectObjectDefinitionDialogState } from './selectObjectDefinitionDialog.reducer.types';

const initial: TSelectObjectDefinitionDialogState = {
    oldName: '',
    objectTypeTitle: '',
    isObjectDeletable: false,
    pathsMap: new Map<string, string>(),
};

export const selectObjectDefinitionDialogReducer: TReducer<TSelectObjectDefinitionDialogState> = (
    state = initial,
    action,
) => {
    switch (action.type) {
        case SELECT_OBJECT_DEFINITION_DIALOG_PREPARE_DATA_SUCCESS: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};
