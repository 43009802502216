import React, { FC } from 'react';
import classnames from 'classnames';
import theme from './TreeItem.scss';
import { ExpandStatus } from '@/reducers/tree.reducer.types';
import { Spin } from 'antd';
import icArrowDown from '../../../../resources/icons/ic-ribbon-arrow-down_2.svg';
import { Icon } from '@/modules/UIKit';

type TTreeItemToggler = {
    expandStatus: ExpandStatus;
};

export const TreeItemToggler: FC<TTreeItemToggler> = (props) => {
    const { expandStatus } = props;
    const className = classnames(theme.togglerIcon, {
        [theme.togglerIcon_expanded]: expandStatus === ExpandStatus.OPEN,
    });
    if (expandStatus === ExpandStatus.LOADING) {
        return <Spin className={theme.toggleSpinner} size="small" />;
    }

    return <Icon className={className} dataTest={`tree-menu_expand-icon-${expandStatus}`} spriteSymbol={icArrowDown} />;
};
