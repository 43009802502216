import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import atributeSelectIcon from '@/resources/icons/atributeSelect.svg';
import deleteIcon from '@/resources/icons/Deleted.svg';
import { NodeId, ReportColumnData } from '@/serverapi/api';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AttributeSelectDialog } from './AttributeSelectDialog/AttributeSelectDialog.component';
import messages from './ColumnSettings.messages';
import theme from './ColumnSettings.scss';
import { reportDeleteColumn } from '../../../actions/report.actions';

type TColumnSettingsProps = {
    reportNodeId: NodeId;
    disableDelete: boolean;
    currentColumn?: ReportColumnData;
};

export const ColumnSettings: FC<TColumnSettingsProps> = ({ disableDelete, reportNodeId, currentColumn }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isAttributeDialogOpen, setIsAttributeDialogOpen] = useState<boolean>(false);

    const deleteColumnHandler = () => {
        if (currentColumn?.columnId) {
            dispatch(reportDeleteColumn(reportNodeId, currentColumn?.columnId));
        }
    };

    return (
        <>
            <div className={theme.group}>
                <div className={theme.attributeInput}>
                    <Input
                        label={intl.formatMessage(messages.attribute)}
                        className={theme.input}
                        name="attributeInput"
                        placeholder={intl.formatMessage(messages.attributePlaceholder)}
                        disabled
                        value={currentColumn?.columnName || ''}
                    />
                    <Button icon={atributeSelectIcon} width={30} onClick={() => setIsAttributeDialogOpen(true)} />
                </div>
            </div>
            <div className={theme.container}>
                <Button disabled={disableDelete} icon={deleteIcon} onClick={deleteColumnHandler}>
                    {intl.formatMessage(messages.deleteColumn)}
                </Button>
            </div>
            <AttributeSelectDialog
                reportNodeId={reportNodeId}
                open={isAttributeDialogOpen}
                currentColumn={currentColumn}
                setOpen={setIsAttributeDialogOpen}
            />
        </>
    );
};
