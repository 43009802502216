import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../../../../../../models/bpm/bpm-model-impl';
import { EndObjectInstance, ModelNode, SimulationRun, StartObjectInstance } from '../../../../../../serverapi/api';
import { EndObjectEditorDialog } from './EndObjectEditorDialog.component';
import { StartEventEditorDialog } from './StartEventEditorDialog.component';

type TEventEditorProps = WrappedComponentProps & {
    model?: ModelNode;
    object?: ObjectDefinitionImpl;
    objectDiagramElement?: ObjectInstanceImpl;
    startObject?: StartObjectInstance;
    endObject?: EndObjectInstance;
    startEventEditorDialogVisible: boolean;
    endEventEditorDialogVisible: boolean;
    openedSimulation?: SimulationRun;
    onSubmit: (simulationRun: SimulationRun) => void;
    onClose: () => void;
};

const EventEditorDialogsComponent = (props: TEventEditorProps) => {
    const { startObject, endObject, openedSimulation, startEventEditorDialogVisible, endEventEditorDialogVisible } =
        props;

    return (
        <div>
            {startEventEditorDialogVisible && (
                <StartEventEditorDialog
                    model={props.model}
                    object={props.object}
                    startObject={startObject}
                    objectDiagramElement={props.objectDiagramElement}
                    onClose={props.onClose}
                    onSubmitStartObject={(startObj: StartObjectInstance) => {
                        if (openedSimulation) {
                            const simulation = JSON.parse(JSON.stringify(openedSimulation));
                            if (startObject) {
                                simulation.simulationRunParameters.startObjectInstances = [
                                    ...(simulation.simulationRunParameters.startObjectInstances.map((o) =>
                                        o.id.elementId === startObject.id?.elementId ? startObj : o,
                                    ) || []),
                                ];
                            } else {
                                simulation.simulationRunParameters.startObjectInstances = [
                                    ...(simulation.simulationRunParameters.startObjectInstances || []),
                                    startObj,
                                ];
                            }
                            props.onSubmit(simulation);
                        }
                    }}
                />
            )}
            {endEventEditorDialogVisible && (
                <EndObjectEditorDialog
                    model={props.model}
                    object={props.object}
                    endObject={endObject}
                    objectDiagramElement={props.objectDiagramElement}
                    onClose={props.onClose}
                    onSubmitEndObject={(endObj: EndObjectInstance) => {
                        if (openedSimulation) {
                            const simulation = JSON.parse(JSON.stringify(openedSimulation));
                            if (endObject) {
                                simulation.simulationRunParameters.endObjectInstances = [
                                    ...(simulation.simulationRunParameters.endObjectInstances.map((o) =>
                                        o.id.elementId === endObject.id?.elementId ? endObj : o,
                                    ) || []),
                                ];
                            } else {
                                simulation.simulationRunParameters.endObjectInstances = [
                                    ...(simulation.simulationRunParameters.endObjectInstances || []),
                                    endObj,
                                ];
                            }
                            props.onSubmit(simulation);
                        }
                    }}
                />
            )}
        </div>
    );
};

const IntlComponent = injectIntl(EventEditorDialogsComponent);

export { IntlComponent as EventEditorDialogs };
