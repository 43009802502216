import React from 'react';

type TCaretOutlined = {
    isUpOutlined?: boolean;
    className?: string;
};

export const CaretOutlined = (props: TCaretOutlined) => {
    const { isUpOutlined, className } = props;

    return (
        <div
            className={className}
            style={{ transform: isUpOutlined ? 'unset' : 'rotate(180deg)', marginTop: isUpOutlined ? '4px' : '-4px' }}
        >
            <svg
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="caret-up"
                width="11px"
                height="11px"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
            </svg>
        </div>
    );
};
