import { ServerProfile } from '../../serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';

export class ServerProfilesDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getAll(): Promise<ServerProfile[]> {
        const api = this.getApi();
        const serverProfiles: ServerProfile[] = await api.serverProfile.getAll();

        return serverProfiles;
    }

    public static async getActiveProfile(): Promise<ServerProfile> {
        const api = this.getApi();
        const serverProfiles: ServerProfile = await api.serverProfile.getActiveProfile({});

        return serverProfiles;
    }

    public static async create(serverProfile: ServerProfile): Promise<void> {
        const api = this.getApi();
        await api.serverProfile.create({ body: serverProfile });
    }

    public static async delete(profileId: string): Promise<void> {
        const api = this.getApi();
        await api.serverProfile._delete({ profileId });
    }
}
