// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TreeItemContextMenu__iconContextMenuItem__qzhLy{margin-right:15px;vertical-align:sub}", "",{"version":3,"sources":["webpack://./src/modules/Tree/components/TreeItemContextMenu/TreeItemContextMenu.scss"],"names":[],"mappings":"AAAA,iDACI,iBAAA,CACA,kBAAA","sourcesContent":[".iconContextMenuItem {\r\n    margin-right: 15px;\r\n    vertical-align: sub;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContextMenuItem": "TreeItemContextMenu__iconContextMenuItem__qzhLy"
};
export default ___CSS_LOADER_EXPORT___;
