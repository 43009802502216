// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabs__tabs__oLA2D{width:100%}.Tabs__tabs__oLA2D .ant-tabs-tab{font-size:14px;color:var(--text);padding-left:10px;margin-left:-10px;padding-right:10px}.Tabs__tabs__oLA2D .ant-tabs-tab:hover{color:var(--text) !important}.Tabs__tabs__oLA2D .ant-tabs-tab-active{background-color:rgba(0,0,0,0) !important}.Tabs__tabs__oLA2D .ant-tabs-tab-active .ant-tabs-tab-btn{color:var(--text) !important;text-shadow:none !important}.Tabs__tabs__oLA2D .ant-tabs-tab-btn:focus{color:var(--text) !important}.Tabs__tabs__oLA2D .ant-tabs-ink-bar{background:var(--mainColor) !important}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Tabs/Tabs.scss"],"names":[],"mappings":"AAAA,mBACI,UAAA,CAGI,iCACI,cAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,kBAAA,CACA,uCACI,4BAAA,CAGR,wCACI,yCAAA,CACA,0DACI,4BAAA,CACA,2BAAA,CAGR,2CACI,4BAAA,CAEJ,qCACI,sCAAA","sourcesContent":[".tabs {\r\n    width: 100%;\r\n\r\n    :global {\r\n        .ant-tabs-tab {\r\n            font-size: 14px;\r\n            color: var(--text);\r\n            padding-left: 10px;\r\n            margin-left: -10px;\r\n            padding-right: 10px;\r\n            &:hover {\r\n                color: var(--text) !important;\r\n            }\r\n        }\r\n        .ant-tabs-tab-active {\r\n            background-color: transparent !important;\r\n            .ant-tabs-tab-btn {\r\n                color: var(--text) !important;\r\n                text-shadow: none !important;\r\n            }\r\n        }\r\n        .ant-tabs-tab-btn:focus {\r\n            color: var(--text) !important;\r\n        }\r\n        .ant-tabs-ink-bar {\r\n            background: var(--mainColor) !important;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "Tabs__tabs__oLA2D"
};
export default ___CSS_LOADER_EXPORT___;
