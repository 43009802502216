import { instancesBPMMxGraphMap } from '../../mxgraph/bpm-mxgraph-instance-map';
import { ObjectModelConnections } from '../../serverapi/api';
import { TModelTypes, TObjectConnectionData } from '../types/NavigatorEdgePropertyBll.types';
import { edgeDefinitionService } from '../EdgeDefinitionService';

export function getExternalEdgeConnections(
    objectModelConnections: ObjectModelConnections[],
    modelTypes: TModelTypes,
    unknownText: string,
    repositoryId: string,
    serverId: string,
): TObjectConnectionData[] {
    const result: TObjectConnectionData[] = [];
    
    objectModelConnections.forEach((objectConnection) => {
        const modelName = objectConnection.modelName!;
        const modelTypeId = objectConnection.modelTypeId!;
        const modelId = objectConnection.modelId!;
        const modelType = modelTypes[modelTypeId];
        const objectConnectionData: TObjectConnectionData = {
            modelName,
            modelId,
            connections: [],
        };
        objectConnection.connections?.forEach((connection) => {
            let objectTypeName: string = unknownText;
            let edgeTypeName: string = unknownText;

            if (modelType) {
                const objectType = modelType.objectTypes.find(
                    (objectType) => objectType.id === connection.connectedObjectTypeId,
                );
                const edgeType = modelType.edgeTypes.find((edgeType) => edgeType.id === connection.edgeTypeId);
                objectTypeName = objectType?.name || unknownText;
                edgeTypeName = edgeType?.name || unknownText;
            }
            // связь может быть не найдена когда ее удалили с модели но модель еще не сохранена,
            // поэтому еще проверяем если ли связь на графе открытом в другой табе
            const isEdgeFoundInGraph: boolean = !!edgeDefinitionService().findCellByEdgeInstanceId(
                { id: modelId, repositoryId, serverId },
                connection.edgeInstanceId,
            );
            const isModelOpenInTab: boolean = !!instancesBPMMxGraphMap.has({ id: modelId, repositoryId, serverId });

            if (!isModelOpenInTab || isEdgeFoundInGraph)
                objectConnectionData.connections.push({
                    connectedObjectName: connection.connectedObjectName,
                    objectTypeName,
                    edgeTypeName,
                    isOutgoingEdge: connection.isOutgoingEdge,
                    connectedObjectInstanceId: connection.connectedObjectInstanceId,
                });
        });
        result.push(objectConnectionData);
    });

    return result.filter((res) => res.connections.length);
}
