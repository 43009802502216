import React from 'react';
import { Dropdown } from 'antd';
import theme from './SymbolGeneratorDialog.scss';
import { TSymbolsSettings } from './SymbolGenerator.types';
import { getSymbolAmountOfBorders } from './utils/defaultSymbolSettings';
import { FrontMenuItem } from './FrontMenuItem.component';
import { getIcons } from './utils/getIcons.utils';
import { SymbolSettingsTypeEnum } from '../../../../../../../../serverapi/api';

type TSymbolBordersSelectorProps = {
    disabled: boolean;
    menu: React.ReactElement<any, string>;
    toggleMenu: () => void;
    open: boolean;
    symbolType: SymbolSettingsTypeEnum;
    bordersVisibility: "strokeWidthVisibility" | "roundingVisibility";
    bordersType: "rounding" | "strokeWidth";
    currentStyles: TSymbolsSettings;
    setStyles: React.Dispatch<React.SetStateAction<TSymbolsSettings>>;
    onlyInteger?: boolean;
    maxValue?: number;
    dataTest?: string;
};

export const SymbolBordersSelector = ({
    disabled,
    menu,
    toggleMenu,
    open,
    bordersVisibility,
    bordersType,
    currentStyles,
    setStyles,
    symbolType,
    onlyInteger,
    maxValue,
    dataTest,
}: TSymbolBordersSelectorProps) => {
    const frontMenuItem = (
        <FrontMenuItem
            bordersVisibility={bordersVisibility}
            bordersType={bordersType}
            currentStyles={currentStyles}
            setCurrentStyles={setStyles}
            symbolType={symbolType}
            src={getIcons(symbolType, 'borders')?.[0]}
            onlyInteger={onlyInteger}
            maxValue={maxValue || 50}
        />
    );

    return (
        <div className={theme.borderObjectButtonsContainer} data-test={dataTest}>
            <Dropdown
                disabled={getSymbolAmountOfBorders(symbolType) === 1 ? true : disabled}
                onOpenChange={toggleMenu}
                overlay={menu}
                open={open}
                placement="bottomLeft"
                overlayStyle={{ width: 250 }}
                destroyPopupOnHide
            >
                <div style={{ width: '250px' }}>{frontMenuItem}</div>
            </Dropdown>
        </div>
    );
};
