import type { TSpriteSymbol } from '@/models/spriteSymbol.types';
import type { TooltipPlacement } from 'antd/es/tooltip';
import React from 'react';
import { Dropdown } from 'antd';
import theme from './Dropdown.scss';
import { Button } from './Button.component';

interface IPopoverProps {
    title: string;
    disabledTitle?: string;
    overlay?: React.ReactElement;
    arrow?: boolean;
    compact?: boolean;
    disabled?: boolean;
    defaultSprite?: TSpriteSymbol;
    tooltipPlacement?: TooltipPlacement;
    onOpenChange?: (visible: boolean) => void;
    className?: string;
    buttonStyle?: React.CSSProperties;
    dataTest?: string;
}

function createAlignMenu(props: IPopoverProps) {
    const { overlay } = props;

    return <div className={theme.overlayContainer}>{overlay}</div>;
}

export const Popover = (props: IPopoverProps) => {
    const {
        title,
        disabledTitle = '',
        arrow = true,
        compact,
        disabled,
        defaultSprite,
        tooltipPlacement = 'top',
        buttonStyle,
        className,
        dataTest,
    } = props;

    return (
        <Dropdown overlay={createAlignMenu(props)} disabled={disabled}>
            <div>
                <Button
                    icon={defaultSprite}
                    title={title}
                    tooltipTitle={!disabled ? title : disabledTitle}
                    tooltipPlacement={tooltipPlacement}
                    arrow={arrow}
                    compact={compact}
                    disabled={disabled}
                    style={buttonStyle}
                    className={className}
                    dataTest={dataTest}
                />
            </div>
        </Dropdown>
    );
};

