import React from 'react';
import theme from './UserManagment.scss';
import admTheme from '../../style/AdminTools.scss';
import { UserManagmentUserList } from '../components/UserManagmentUserList.component';
import UsersGroupsListContainer from '../../UsersGroupsList/containers/UsersGroupsList.container';
import LicensesDistributionContainer from '../../LicensesDistribution/containers/LicensesDistribution.container';
import AccessPermissionsOwners from '../../AccessPermissionsOwners/containers/AccessPermissionsOwners.container';
import UserProfilesContainer from '../../UserProfiles/containers/UserProfiles.container';
import messages from '../messages/userManagment.messages';
import { Tabs } from 'antd';
import { useIntl } from 'react-intl';

const UserManagmentComponent = () => {
    const intl = useIntl();

    const tabs = [
        {
            label: <span data-test="user-managment_user-list-tab">{intl.formatMessage(messages.userList)}</span>,
            key: '1',
            children: <UserManagmentUserList />,
        },
        {
            label: <span data-test="user-managment_user-groups-tab">{intl.formatMessage(messages.groupsOfUsers)}</span>,
            key: '2',
            children: <UsersGroupsListContainer />,
        },
        {
            label: (
                <span data-test="user-managment_license-distribution-tab">
                    {intl.formatMessage(messages.licensesDistribution)}
                </span>
            ),
            key: '3',
            children: <LicensesDistributionContainer />,
        },
        {
            label: (
                <span data-test="user-managment_function-privileges-tab">
                    {intl.formatMessage(messages.accessesRights)}
                </span>
            ),
            key: '4',
            children: <AccessPermissionsOwners />,
        },
        {
            label: (
                <span data-test="user-managment_user-profiles-tab">{intl.formatMessage(messages.userProfiles)}</span>
            ),
            key: '5',
            children: <UserProfilesContainer />,
        },
    ];

    return (
        <div className={theme.container} data-test="user-managment_container">
            <div className={admTheme.containerInner}>
                <Tabs defaultActiveKey="1" tabPosition="left" items={tabs} size="large" />
            </div>
        </div>
    );
};

export default UserManagmentComponent;
