import { Tabs as AntTabs, TabsProps } from 'antd';
import classNames from 'classnames';

import React from 'react';
import theme from './Tabs.scss';

export const Tabs = Object.assign(
    (props: TabsProps) => {
        return <AntTabs {...props} className={classNames(theme.tabs, props.className)} />;
    },
    {
        TabPane: AntTabs.TabPane,
    },
);
