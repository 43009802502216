import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import messages from './Search.messages';
import theme from './Search.scss';
import { MxCell } from '../../../../mxgraph/mxgraph';
import { DebounceInput } from 'react-debounce-input';
import {
    getFoundCells,
    onCentered,
    onClearSelection,
    onFoundVisible,
    setSingleSelection,
    viewCellsActive,
    viewCellsInactive,
} from '../../../../services/bll/SearchByModelBllService';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';
import { TSearchableCells } from '../../../../services/bll/SearchByModelBllService.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TSearchComponentProps = {
    searchableCells: TSearchableCells;
    graph: BPMMxGraph;
    onClear: (cell: MxCell) => void;
} & WrappedComponentProps;

const SearchComponent = (props: TSearchComponentProps) => {
    const { intl, graph, searchableCells, onClear } = props;

    const [counter, setCounter] = useState<number>(1);
    const [foundCells, setFoundCells] = useState<MxCell[]>([]);

    const isFound: boolean = Boolean(foundCells.length);
    const countOfFound: number = foundCells.length;

    const inputEl: React.RefObject<any> = useRef();

    const handleCentered = (index: number) => {
        const resultId = index - 1;
        const cellForCentered = foundCells[resultId];
        onCentered(cellForCentered, graph);
    };

    const clearSelection = () => {
        onClearSelection(graph);
        inputEl.current?.focus();
    };

    const counterHandlers = {
        change: (count: number) => {
            handleCentered(count);
            setCounter(count);
        },
        up: () => {
            const count = counter === 1 ? countOfFound : counter - 1;
            counterHandlers.change(count);
        },
        down: () => {
            const count = counter === countOfFound ? 1 : counter + 1;
            counterHandlers.change(count);
        },
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value?.trim().toLowerCase();
        if (!inputValue) {
            clearSelection();
            setFoundCells([]);
            setCounter(1);
            viewCellsActive(graph);

            return;
        }

        const resultCells = getFoundCells(inputValue, searchableCells);

        setFoundCells(resultCells);
    };

    const handleClose = () => {
        const resultId = counter - 1;
        const cellForClear = foundCells[resultId];

        onClear(cellForClear);
        setCounter(1);
        setFoundCells([]);
        if (inputEl.current) inputEl.current.value = '';
        setSingleSelection(graph, false);
    };

    useEffect(() => {
        if (isFound) {
            onFoundVisible(foundCells, graph);
            counterHandlers.change(1);
            inputEl.current?.focus();
        } else if (inputEl.current?.value) {
            clearSelection();
            viewCellsInactive(graph);
        }
    }, [foundCells]);

    useEffect(() => {
        inputEl.current?.focus();

        return () => {
            handleClose();
        };
    }, []);

    const searchCounter: string = `${isFound ? counter : 0} ${intl.formatMessage(messages.ofText)} ${countOfFound}`;

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Enter':
                case 'ArrowDown':
                counterHandlers.down();
                inputEl.current?.focus();
                break;

            case 'Escape':
                handleClose();
                break;

            case 'ArrowUp':
                counterHandlers.up();
                inputEl.current?.focus();
                break;
        }
    };

    return (
        <div data-test="search-bar_panel-container" className={theme.wrapper}>
            <DebounceInput
                data-test="search-bar_search-query_input"
                inputRef={inputEl}
                className={theme.input}
                debounceTimeout={500}
                forceNotifyByEnter
                onChange={handleChange}
                onKeyDown={onKeyDownHandler}
            />
            <span className={theme.text}>{searchCounter}</span>
            <Button dataTest="search-bar_up-arrow_btn" visualStyle="text" size="small" onClick={counterHandlers.up}>
                <UpOutlined />
            </Button>
            <Button dataTest="search-bar_down-arrow_btn" visualStyle="text" size="small" onClick={counterHandlers.down}>
                <DownOutlined />
            </Button>
            <Button dataTest="search-bar_close_btn" visualStyle="text" size="small" onClick={handleClose}>
                <CloseOutlined />
            </Button>
        </div>
    );
};

const SearchIntl = injectIntl(SearchComponent);

export { SearchIntl as Search };
