export class PsdTable {
    name: string;
    orientation: string;
    rows: PsdVector[];
    columns: PsdVector[];

    constructor(obj?: string) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class PsdVector {
    name: string;
    allowedSymbols: string[];

    constructor(obj?: PsdVector) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class PsdCell {
    name: string;
    allowedSymbols: string[];
    type: psdCellType;
    rowIndex: number = 0;
    columnIndex: number = 0;

    constructor(obj?: PsdCell) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export enum psdCellType {
    HORIZONTAL_HEADER,
    VERTICAL_HEADER,
    CELL,
    MAIN_TABLE,
    BPMN_LANE,
    BPMN_POOL,
}
