import {
    CHANGE_NAVIGATOR_TREE_SEARCH_STATUS,
    SET_FOUND_NODE_IDS,
    SET_SEARCH_STRING,
    SET_MOVE_DIRECTION,
    CHANGE_FOUND_NODE_IDS_BY_SEARCH_STRING,
    FIND_NODE_IDS_BY_SEARCH_STRING,
} from '../actionsTypes/navigatorTreeSearch.actionsTypes';
import { TReducer } from '../utils/types';
import { TNavigatorTreeSearchState } from './navigatorTreeSearch.reducer.types';
import { getCorrectedNodeIdIndex } from '../modules/Navigator/navigatorUtils/navigatorTreeSearch.utils';
import { compareNodeIds } from '../utils/nodeId.utils';
import { getFoundNodeIds } from '../modules/Tree/utils/tree.utils';
import { NodeId } from '../serverapi/api';
import { isEqual } from 'lodash-es';

const initial: TNavigatorTreeSearchState = {
    isActive: false,
    searchString: '',
    foundNodeIds: [],
    selectedFoundNodeId: undefined,
};

export const navigatorTreeSearchReducer: TReducer<TNavigatorTreeSearchState> = (state = initial, action) => {
    switch (action.type) {
        case CHANGE_NAVIGATOR_TREE_SEARCH_STATUS: {
            const { isActive } = action.payload;

            return {
                ...state,
                isActive,
            };
        }
        case SET_SEARCH_STRING: {
            const { searchString } = action.payload;

            return {
                ...state,
                searchString,
            };
        }
        case SET_FOUND_NODE_IDS: {
            const { foundNodeIds } = action.payload;
            const { selectedFoundNodeId } = state;
            const oldFoundNodeIds: NodeId[] = state.foundNodeIds;

            return {
                ...state,
                foundNodeIds: isEqual(foundNodeIds, oldFoundNodeIds) ? oldFoundNodeIds : foundNodeIds,
                selectedFoundNodeId: foundNodeIds.find((nodeId) => compareNodeIds(nodeId, selectedFoundNodeId)),
            };
        }
        case SET_MOVE_DIRECTION: {
            const { moveDirection } = action.payload;
            const { foundNodeIds, selectedFoundNodeId } = state;
            const currentNodeIdIndex: number = foundNodeIds.findIndex((nodeId) =>
                compareNodeIds(nodeId, selectedFoundNodeId),
            );
            if (currentNodeIdIndex === -1)
                return {
                    ...state,
                    selectedFoundNodeId: foundNodeIds.length ? foundNodeIds[0] : undefined,
                };
            const newIndex: number = moveDirection === 'up' ? currentNodeIdIndex - 1 : currentNodeIdIndex + 1;
            const correctedNewIndex: number = getCorrectedNodeIdIndex(newIndex, foundNodeIds.length);

            const newSelectedFoundNodeId: NodeId = foundNodeIds[correctedNewIndex];

            return {
                ...state,
                selectedFoundNodeId: newSelectedFoundNodeId,
            };
        }
        case CHANGE_FOUND_NODE_IDS_BY_SEARCH_STRING: {
            const { data } = action.payload;
            const searchStr: string = state.searchString;
            const foundNodeIds: NodeId[] = getFoundNodeIds(searchStr, data);
            const oldFoundNodeIds: NodeId[] = state.foundNodeIds;

            if (isEqual(foundNodeIds, oldFoundNodeIds)) return state;

            return {
                ...state,
                foundNodeIds,
            };
        }

        case FIND_NODE_IDS_BY_SEARCH_STRING: {
            const { data, str } = action.payload;
            const foundNodeIds: NodeId[] = getFoundNodeIds(str, data);
            const oldFoundNodeIds: NodeId[] = state.foundNodeIds;
            const { selectedFoundNodeId } = state;

            return {
                ...state,
                foundNodeIds: isEqual(foundNodeIds, oldFoundNodeIds) ? oldFoundNodeIds : foundNodeIds,
                searchString: str,
                selectedFoundNodeId: foundNodeIds.find((nodeId) => compareNodeIds(nodeId, selectedFoundNodeId)),
            };
        }

        default: {
            return state;
        }
    }
};
