export const SET_SESSION_SEARCH_DATA = 'SET_SESSION_SEARCH_DATA';

export const SET_SESSION_SEARCH_TEXT = 'SET_SESSION_SEARCH_TEXT';

export const SET_SESSION_ACTIVE = 'SET_SESSION_ACTIVE';

export const SET_SESSION_RANGE = 'SET_SESSION_RANGE';

export const GET_SESSION_DATA = 'GET_SESSION_DATA';

export const USER_SESSION_LOGOUT = 'USER_SESSION_LOGOUT';

export const USER_SESSION_END = 'USER_SESSION_END';
