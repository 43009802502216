import { TReducer } from '../utils/types';
import { FETCHED_ACCESS_PERMISSION_INFO_SUCCESS } from '../actionsTypes/accessPermissions.actionTypes';
import { TAccessPermissionsState } from './accessPermissions.reducer.types';

const initial: TAccessPermissionsState = {
    byServerId: {},
};

export const accessesReducer: TReducer<TAccessPermissionsState> = (state = initial, action) => {
    switch (action.type) {
        case FETCHED_ACCESS_PERMISSION_INFO_SUCCESS: {
            // const { serverId, owners } = action.payload
            // return {
            //     ...state,
            //     byServerId: {
            //         ...state.byServerId,
            //         [serverId]: owners
            //     }
            // };
            const { serverId, owners } = action.payload;
            const ownersMap = new Map(state.byServerId[serverId]);
            owners.forEach((value, key) => {
                ownersMap.set(key, value);
            });

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: ownersMap,
                },
            };
        }
        default:
            return state;
    }
};
