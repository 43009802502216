import { defineMessages } from 'react-intl';

export default defineMessages({
    createTitle: {
        id: 'CreatePresetImageDialog.createTitle',
        defaultMessage: 'Новая иконка',
    },
    editTitle: {
        id: 'CreatePresetImageDialog.editTitle',
        defaultMessage: 'Редактирование иконки',
    },
    generalSettings: {
        id: 'CreatePresetImageDialog.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    name: {
        id: 'CreatePresetImageDialog.name',
        defaultMessage: 'Название',
    },
    uploadText: {
        id: 'CreatePresetImageDialog.uploadText',
        defaultMessage: 'Загрузить файл',
    },
    uploadDescription: {
        id: 'CreatePresetImageDialog.uploadDescription',
        defaultMessage: 'Общий объем не должен превышать',
    },
    uploadDescriptionSize: {
        id: 'CreatePresetImageDialog.uploadDescriptionSize',
        defaultMessage: '50 Мбайт',
    },
    presetImage: {
        id: 'CreatePresetImageDialog.presetImage',
        defaultMessage: 'Иконка методологии',
    },
    id: {
        id: 'CreatePresetImageDialog.id',
        defaultMessage: 'ID',
    },
    presetImageGroups: {
        id: 'CreatePresetImageDialog.presetImageGroups',
        defaultMessage: 'Группа иконок',
    },
    graphical: {
        id: 'CreatePresetImageDialog.graphical',
        defaultMessage: 'Изображение',
    },
    deleteImage: {
        id: 'CreatePresetImageDialog.deleteImage',
        defaultMessage: 'Удалить иконку "{name}"?',
    },
    cancel: {
        id: 'CreatePresetImageDialog.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'CreatePresetImageDialog.save',
        defaultMessage: 'Сохранить',
    },
    create: {
        id: 'CreatePresetImageDialog.create',
        defaultMessage: 'Создать',
    },
});