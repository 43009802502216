import type { BPMMxGraph } from '../bpmgraph';
import { MxCell, MxMouseEvent, MxSelectionCellsHandler } from '../mxgraph';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolManager.class';

export class BPMMxSelectionCellsHandler extends MxSelectionCellsHandler {
    mouseDown(sender: BPMMxGraph, me: MxMouseEvent) {
        if (me.state && !ComplexSymbolManager.isSelectableCell(me?.state?.cell)) {
            const currentCells: MxCell[] = this.graph.getSelectionModel().cells;
            const currentSelectedCellId: string = currentCells && currentCells.length > 0 ? currentCells[0].id : '';
            const connectionCell = ComplexSymbolManager.getSelectableCell(me?.state?.cell);

            if (connectionCell && (currentSelectedCellId === '' || me.state.cell.id !== currentSelectedCellId)) {
                const result = this.graph.view.getState(connectionCell);
                me.state = result;
            }
        }

        super.mouseDown(sender, me);
    }
}
