import React from 'react';
import { useIntl } from 'react-intl';
import theme from '../ApprovalTab/approvalTab.scss';
import messages from '../messages/approval.messages';
import { IconDeleteBin } from '../../UIKit/components/Icons/IconDeleteBin';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TDeleteButtonProps = {
    handleClick: () => void;
    isDisabled: boolean;
}

export const DeleteButton = ({ handleClick, isDisabled }: TDeleteButtonProps): JSX.Element => {
    const intl = useIntl();

    return (
        <Button dataTest="admin-approvals_delete_btn" onClick={handleClick} disabled={isDisabled}>
            <span className={theme.buttonContent}>
                <IconDeleteBin className={isDisabled ? theme.disabledIconContainer : theme.activeIconContainer} />
                {intl.formatMessage(messages.delete)}
            </span>
        </Button>
    );
};
