import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
    scriptContextTypes,
    TCheckedItems,
} from '../../src/modules/ObjectPropertiesDialog/components/ScriptContext/scriptContext.types';
import {
    getAllCheckedItems,
    getUncheckedItems,
    renderOnDBRow,
    renderOnEdgeRow,
    renderOnFilesRow,
    renderOnFolderRow,
    renderOnModelsTypeRow,
    renderOnNodesRow,
    renderOnObjectRow,
    renderOnObjectInstancesRow,
    renderOnObjectInstancesWithBindingModelsRow,
    renderOnScriptRow,
} from '../../src/utils/scriptContext.utils';
import { AllowedScriptContext, ScriptNode } from '../serverapi/api';
import messages from '../modules/ObjectPropertiesDialog/messages/ScriptContext.messages';
import { RowWithButtons } from '../../src/modules/ObjectPropertiesDialog/components/ScriptContext/RowWithButtons.component';

export const useEventDataSource = (script: ScriptNode) => {
    const scriptContext: AllowedScriptContext | undefined = script.allowedScriptContext;
    const intl = useIntl();

    const dataSource = [
        {
            type: intl.formatMessage(messages.runningOnModels),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnModelsTypeRow(scriptContext)}
                    type={scriptContextTypes.runningOnModels}
                    script={script}
                />
            ),
        },
        {
            type: intl.formatMessage(messages.runningOnEdges),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnEdgeRow(scriptContext)}
                    type={scriptContextTypes.runningOnEdges}
                    script={script}
                />
            ),
        },
        {
            type: intl.formatMessage(messages.runningOnObjects),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnObjectRow(scriptContext)}
                    type={scriptContextTypes.runningOnObjects}
                    script={script}
                />
            ),
        },
        {
            type: intl.formatMessage(messages.runningOnObjectInstances),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnObjectInstancesRow(scriptContext)}
                    type={scriptContextTypes.runningOnObjectInstances}
                    script={script}
                />
            ),
        },
        {
            type: intl.formatMessage(messages.runningOnObjectInstancesWithBindingModelTypes),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnObjectInstancesWithBindingModelsRow(scriptContext)}
                    type={scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes}
                    script={script}
                />
            ),
        },
        {
            type: intl.formatMessage(messages.runningOnFiles),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnFilesRow(scriptContext)}
                    type={scriptContextTypes.runningOnFiles}
                    script={script}
                />
            ),
        },
        {
            type: intl.formatMessage(messages.runningOnDB),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnDBRow(scriptContext)}
                    type={scriptContextTypes.runningOnDB}
                    script={script}
                />
            ),
        },
        {
            type: intl.formatMessage(messages.runningOnScripts),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnScriptRow(scriptContext)}
                    type={scriptContextTypes.runningOnScripts}
                    script={script}
                />
            ),
        },
        {
            type: intl.formatMessage(messages.runningOnFolders),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnFolderRow(scriptContext)}
                    type={scriptContextTypes.runningOnFolders}
                    script={script}
                />
            ),
        },
        {
            type: intl.formatMessage(messages.runningOnSpecificElement),
            paramValue: (
                <RowWithButtons
                    rowData={renderOnNodesRow(scriptContext)}
                    type={scriptContextTypes.runningOnSpecificElement}
                    script={script}
                />
            ),
        },
    ];

    return dataSource;
};

export const useCheckedItems = (): TCheckedItems => {
    const [checkedItems, setCheckedItems] = useState<string[] | undefined>(undefined);
    const setCheckedItemsWithDublicates = (
        allItemKeys: string[],
        event,
        defaultCheckedKeys: string[],
        contextType: scriptContextTypes,
    ) => {
        setCheckedItems(getAllCheckedItems(allItemKeys, event, checkedItems || defaultCheckedKeys, contextType));
    };

    const setUncheckedItemsWithDublicates = (defaultCheckedKeys: string[], event, contextType: scriptContextTypes) => {
        setCheckedItems(getUncheckedItems(checkedItems || defaultCheckedKeys, event, contextType));
    };

    return { checkedItems, setCheckedItemsWithDublicates, setUncheckedItemsWithDublicates };
};
