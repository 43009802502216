const addFontColorStyle = (size: number) => ({
    [`color-${  size}`]: {
        open: () => `<COLOR${  size  }>`,
        close: () => '</COLOR>',
    },
});

const fontColorStyles = {
    color: addFontColorStyle,
};

// @TODO: Output in draftjs-utils - need fix it
const addFontColorBlockStyle = (color: string) => ({
    [`color-${  color}`]: {
        open: () => `<span style="color: ${  color  };">`,
        close: () => '</span>',
    },
});

const fontColorBlockStyles = {
    color: addFontColorBlockStyle,
};

export { fontColorBlockStyles, fontColorStyles };
