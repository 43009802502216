import { RefreshPageDialog } from './RefreshPageDialog.component';
import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeDialog(DialogType.REFRESH_PAGE_DIALOG)),
    onSubmit: () => window.location.reload(),
});

export const RefreshPageDialogContainer = connect(null, mapDispatchToProps)(RefreshPageDialog);
