import type { TEraseDeletedNodeDialogSubmitAction } from './eraseDeletedNodeDialog.actions.types';
import type { NodeId } from '@/serverapi/api';
import { ERASE_DELETED_NODE_DIALOG_SUBMIT } from '../actionsTypes/eraseDeletedNodeDialog.actionTypes';

export const eraseDeletedNodeDialogSubmit = (
    nodeId: NodeId,
    closeTabsIds: NodeId[],
): TEraseDeletedNodeDialogSubmitAction => ({
    type: ERASE_DELETED_NODE_DIALOG_SUBMIT,
    payload: { nodeId, closeTabsIds },
});

