import React, { FC } from 'react';
import theme from './DatetimeSchedulerSettings.scss';
import messages from './DatetimeSchedulerSettings.messages';
import { useIntl } from 'react-intl';
import { InputNumber } from 'antd';

type TDailySchedulerPeriodProps = {
    repeatDaily: number | undefined;
    onRepeatDailyChange: (repeatDaily: number) => void;
};

export const DailySchedulerPeriod: FC<TDailySchedulerPeriodProps> = (props) => {
    const { repeatDaily, onRepeatDailyChange } = props;
    const intl = useIntl();

    return (
        <div className={theme.schedulerSettings}>
            <div className={theme.schedulerSettingsLabel}>{intl.formatMessage(messages.daylyLabel)}</div>
            <InputNumber
                min={1}
                max={99}
                style={{ width: '120px' }}
                value={repeatDaily || 1}
                onChange={onRepeatDailyChange}
                addonAfter={intl.formatMessage(messages.daylyAddon)}
                onKeyDown={(event) => {
                    if (event.key === ',' || event.key === '.') {
                        event.preventDefault();
                    }
                }}
                type="number"
            />
        </div>
    );
};
