export type TSelectObjectFormData = {
    strategy: number;
};

export enum SelectedStrategy {
    createNew = 1,
    useExisting = 2,
    setNewName = 3,
    showInTree = 4,
    save = 5,
}
