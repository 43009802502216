import { defineMessages } from 'react-intl';

export default defineMessages({
    modelLinkDialogTitle: {
        id: 'modelLinkDialogTitle',
        defaultMessage: 'Предупреждение',
    },
    modelLinkDialogMessage1: {
        id: 'modelLinkDialogMessage1',
        defaultMessage:
            'В текущем браузере уже запущен клиент, ссылка открыта в нем. Перейдите на вкладку браузера с активной сессией.',
    },
    modelLinkDialogMessage2: {
        id: 'modelLinkDialogMessage2',
        defaultMessage:
            'Если вы желаете открыть ссылку в текущем клиенте, нажмите кнопку ниже, при этом сессия в других вкладках браузера будет прекращена.',
    },
    appHaveAuthTabDialogMessage1: {
        id: 'appHaveAuthTabDialogMessage1',
        defaultMessage:
            'В текущем браузере уже запущен клиент. Перейдите на вкладку браузера с активной сессией.',
    },
    appHaveAuthTabDialogMessage2: {
        id: 'appHaveAuthTabDialogMessage2',
        defaultMessage:
            'Если вы желаете начать работу в текущем клиенте, нажмите кнопку ниже, при этом сессия в других вкладках браузера будет прекращена.',
    },
    modelLinkNoAccess: {
        id: 'modelLinkNoAccess',
        defaultMessage: 'Нет прав на открытие элемента по ссылке!',
    },
    openInCurrentClient: {
        id: 'openInCurrentClient',
        defaultMessage: 'Открыть в текущем клиенте',
    },
    startInCurrentClient: {
        id: 'startInCurrentClient',
        defaultMessage: 'Начать в текущем клиенте',
    },
});
