import { SAVE_DATA_AND_SELECTED_DATA } from '../actionsTypes/printModel.actionTypes';
import { TReducer } from '../utils/types';
import { TPrintModelState } from './printModel.reducer.types';

const initial: TPrintModelState = {
    data: undefined,
    selectedData: undefined,
};

export const printModelReducer: TReducer<TPrintModelState> = (state = initial, action) => {
    switch (action.type) {
        case SAVE_DATA_AND_SELECTED_DATA: {
            const { data, selectedData } = action.payload;

            return {...state, ...{ data, selectedData }};
        }
        default:
            return state;
    }
};
