import type {
    AttributeType,
    EdgeType,
    ModelEdgeDefinition,
    ModelType,
    ObjectType,
    Symbol,
} from '../../../../../../../serverapi/api';
import { some } from 'lodash-es';
import { ComplexSymbolManager } from '../../../../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { SymbolTypeId } from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { EdgeSymbolType } from '../EdgesTab/EdgesTab.constants';
import { TModelEdgeWithTableData } from '../EdgesTab/EdgesTab.types';
import { Locale } from '@/modules/Header/components/Header/header.types';

export const checkPoolsHaveLanes = (modelTypeSymbols: Symbol[]): boolean => {
    const symbolsHaveHPool = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.HORIZONTAL_POOL,
    );
    const symbolsHaveVPool = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.VERTICAL_POOL,
    );
    const symbolsHaveVLane = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.VERTICAL_SWIMLANE,
    );
    const symbolsHaveHLane = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.HORIZONTAL_SWIMLANE,
    );
    const symbolsHaveCrossPool = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.CROSS,
    );

    if (symbolsHaveHPool && !symbolsHaveHLane) {
        return false;
    }

    if (symbolsHaveVPool && !symbolsHaveVLane) {
        return false;
    }

    if (symbolsHaveCrossPool && (!symbolsHaveVLane || !symbolsHaveHLane)) {
        return false;
    }

    return true;
};

export const checkIsDoubleEdgeDefinition = (
    modelTypeEdgeDefinitions: ModelEdgeDefinition[],
    addedEdgeDefinition: ModelEdgeDefinition,
): boolean => {
    const paramEdgeDefinition = {
        edgeType: addedEdgeDefinition.edgeType,
        modelTypeId: addedEdgeDefinition.modelTypeId,
        source: addedEdgeDefinition.source,
        destination: addedEdgeDefinition.destination,
        anySourceAllowed: addedEdgeDefinition.anySourceAllowed,
        anyTargetAllowed: addedEdgeDefinition.anyTargetAllowed,
    };

    return some(modelTypeEdgeDefinitions, paramEdgeDefinition);
};

export const getObjectAvailableAttributeTypes = (
    availableAttributes: AttributeType[],
    objectTypeId: string,
    modelType: ModelType,
) => {
    const objectType: ObjectType | undefined = modelType.objectTypes.find((el) => el.id === objectTypeId);
    const definitionAttributes: AttributeType[] = availableAttributes.filter((el) =>
        objectType?.nodeAttributes.some((nodeAttr) => nodeAttr.id === el.id),
    );
    const instanceAttributes: AttributeType[] = availableAttributes.filter((el) =>
        objectType?.diagramElementAttributes.some((instAttr) => instAttr.id === el.id),
    );

    return { definitionAttributes, instanceAttributes };
};

export const getEdgeAvailableAttributeTypes = (availableAttributes: AttributeType[], edgeType: EdgeType) => {
    const definitionAttributes: AttributeType[] = availableAttributes.filter((el) =>
        edgeType?.attributeTypes.some((attrTypes) => attrTypes.id === el.id),
    );
    const instanceAttributes: AttributeType[] = availableAttributes.filter((el) =>
        edgeType?.diagramElementAttributes.some((instAttr) => instAttr.id === el.id),
    );

    return { definitionAttributes, instanceAttributes };
};
export const sortByEdgeTypeName = (edgeTypes: EdgeType[]): EdgeType[] => {
    return edgeTypes.sort((a: EdgeType, b: EdgeType) => {
        const nameA: string = a.name ?? '';
        const nameB: string = b.name ?? '';

        return nameA.localeCompare(nameB, Locale.ru);
    });
}

export const getTypePriority = (type: string): number => {
    switch (type) {
        case EdgeSymbolType.SYMBOL:
            return 1;
        case EdgeSymbolType.OBJECT:
            return 2;
        case EdgeSymbolType.ANY:
            return 3;
        default:
            return 4;
    }
};

const getSourceType = (edge: TModelEdgeWithTableData): EdgeSymbolType => {
    if (edge.anySourceAllowed) return EdgeSymbolType.ANY;

    if (edge.source) return EdgeSymbolType.SYMBOL;

    return EdgeSymbolType.OBJECT;
};

const getDestinationType = (edge: TModelEdgeWithTableData): EdgeSymbolType => {
    if (edge.anyTargetAllowed) return EdgeSymbolType.ANY;

    if (edge.destination) return EdgeSymbolType.SYMBOL;

    return EdgeSymbolType.OBJECT;
};

const getName = (
    type: EdgeSymbolType,
    id: string | undefined,
    objectId: string | undefined,
    modelType: ModelType,
): string => {
    switch (type) {
        case EdgeSymbolType.SYMBOL:
            const symbol = modelType.symbols.find((s) => s.id === id);
            return symbol ? symbol.name : id || '';
        case EdgeSymbolType.OBJECT:
            const object = modelType.objectTypes.find((o) => o.id === objectId);
            return object ? object.name : objectId || '';
        case EdgeSymbolType.ANY:
            return EdgeSymbolType.ANY;
        default:
            return '';
    }
};

export const sortEdges = (edges: TModelEdgeWithTableData[], modelType: ModelType): TModelEdgeWithTableData[] => {
    return edges.sort((edgeA, edgeB) => {
        const sourceTypeA: EdgeSymbolType = getSourceType(edgeA);
        const sourceTypeB: EdgeSymbolType = getSourceType(edgeB);

        if (getTypePriority(sourceTypeA) !== getTypePriority(sourceTypeB)) {
            return getTypePriority(sourceTypeA) - getTypePriority(sourceTypeB);
        }

        const sourceNameA: string = getName(sourceTypeA, edgeA.source, edgeA.sourceObject, modelType);
        const sourceNameB: string = getName(sourceTypeB, edgeB.source, edgeB.sourceObject, modelType);

        if (sourceNameA !== sourceNameB) {
            return sourceNameA.localeCompare(sourceNameB);
        }

        const destinationTypeA: EdgeSymbolType = getDestinationType(edgeA);
        const destinationTypeB: EdgeSymbolType = getDestinationType(edgeB);

        if (getTypePriority(destinationTypeA) !== getTypePriority(destinationTypeB)) {
            return getTypePriority(destinationTypeA) - getTypePriority(destinationTypeB);
        }

        const destinationNameA: string = getName(
            destinationTypeA,
            edgeA.destination,
            edgeA.destinationObject,
            modelType,
        );
        const destinationNameB: string = getName(
            destinationTypeB,
            edgeB.destination,
            edgeB.destinationObject,
            modelType,
        );

        if (destinationNameA !== destinationNameB) {
            return destinationNameA.localeCompare(destinationNameB);
        }

        return edgeA.edgeTypeName.localeCompare(edgeB.edgeTypeName);
    });
};
