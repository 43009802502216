import { TreeItemType } from '../models/tree';
import serverIcon from '../../../resources/icons/ic-tree-server-on.svg';
import serverOffIcon from '../../../resources/icons/ic-tree-server-off.svg';
import folderIcon from '../../../resources/icons/ic-tree-folder.svg';
import scriptFolderIcon from '../../../resources/icons/ic-tree-scriptfolder.svg';
import modelIcon from '../../../resources/icons/ic-tree-model-active.svg';
import scriptIcon from '../../../resources/icons/ic-tree-script.svg';
import wikiIcon from '../../../resources/icons/ic-tree-wiki.svg';
import databaseIcon from '../../../resources/icons/ic-tree-database-on.svg';
import iconMatrix from '../../../resources/icons/ic-tree-model-matrix.svg';
import iconSettings from '../../../resources/icons/ic-list-settings.svg';
import iconGroovy from '../../../resources/icons/ic-groovy.svg';
import iconJavaScript from '../../../resources/icons/ic-javascript.svg';
import iconKotlin from '../../../resources/icons/ic-kotlin.svg';
import textFile from '../../../resources/icons/file-text.svg';
import wordFile from '../../../resources/icons/file-word.svg';
import excelFile from '../../../resources/icons/file-excel.svg';
import file from '../../../resources/icons/file.svg';
import icImage from '../../../resources/icons/icImage.svg';
import spreadsheetIcon from '../../../resources/icons/ic-spreadsheet.svg';
import edgeIcon from '../../../resources/icons/edge.svg';
import dashboardIcon from '../../../resources/icons/dashboard.svg';
import userAccountIcon from '../../../resources/icons/userAccount.svg';

const TreeItemIcons = {
    [TreeItemType.Server]: serverIcon,
    ServerOFF: serverOffIcon,
    [TreeItemType.Folder]: folderIcon,
    [TreeItemType.ScriptFolder]: scriptFolderIcon,
    [TreeItemType.Model]: modelIcon,
    [TreeItemType.Wiki]: wikiIcon,
    [TreeItemType.Matrix]: iconMatrix,
    [TreeItemType.Report]: modelIcon,
    [TreeItemType.Script]: scriptIcon,
    [TreeItemType.Repository]: databaseIcon,
    [TreeItemType.AdminTool]: iconSettings,
    [TreeItemType.SimulationModeling]: modelIcon, // todo: нужна иконка
    GROOVY: iconGroovy,
    JS: iconJavaScript,
    KOTLIN: iconKotlin,
    [TreeItemType.FileFolder]: folderIcon, // todo: нужна иконка
    [TreeItemType.File]: file, // 'FILE'
    [TreeItemType.Spreadsheet]: spreadsheetIcon,
    [TreeItemType.Kanban]: modelIcon, // todo: такая же как у модели?
    [TreeItemType.EdgeDefinition]: edgeIcon,
    [TreeItemType.Dashboard]: dashboardIcon,
    [TreeItemType.UserAccount]: userAccountIcon,

    EXCEL_FILE: excelFile,
    WORD_FILE: wordFile,
    TEXT_FILE: textFile,
    IMG_FILE: icImage,
};

export enum FILE_ICON_TYPES {
    'EXCEL_FILE',
    'WORD_FILE',
    'TEXT_FILE',
    'IMG_FILE',
    'FILE',
}

export default TreeItemIcons;
