export const OPEN_LICENSEOWNERS_EDITING_TAB = 'OPEN_LICENSEOWNERS_EDITING_TAB';

export const FETCH_LICENSES = 'FETCH_LICENSES';

export const FETCH_LICENSES_SUCCESS = 'FETCH_LICENSES_SUCCESS';

export const FETCH_LICENSES_OWNERS_INFO = 'FETCH_LICENSES_OWNERS_INFO';

export const FETCH_LICENSES_OWNERS_INFO_SUCCESS = 'FETCH_LICENSES_OWNERS_INFO_SUCCESS';

export const SAVE_INFO_OF_LICENSEOWNERS = 'SAVE_INFO_OF_LICENSEOWNERS';

export const OPEN_LICENSES_TAB = 'OPEN_LICENSES_TAB';

export const UPLOADING_LICENS_REQUEST = 'UPLOADING_LICENS_REQUEST';

export const UPLOAD_LICENSE = 'UPLOAD_LICENSE';

export const DELETE_LICENSE = 'DELETE_LICENSE';
