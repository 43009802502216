import {
    MATRIX_MODEL_TYPE_ADD_ATTRIBUTE_TYPES,
    CHANGE_MATRIX_MODEL_TYPE_DESCRIPTION,
    CHANGE_MATRIX_MODEL_TYPE_ENABLE_MODEL,
    CHANGE_MATRIX_MODEL_TYPE_ID,
    CHANGE_MATRIX_MODEL_TYPE_NAME,
    ADD_STORE_EDIT_MATRIX_MODEL_TYPE_WORKSPACE_TAB,
    MATRIX_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES,
    MATRIX_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS,
    CHANGE_MATRIX_MODEL_TYPE_ALLOW_APPROVALS,
    CHANGE_MATRIX_MODEL_TYPE_GROUP,
} from '../../actionsTypes/workspaceTab/editMatrixModelTypeWorkspaceTab.actionTypes';
import {
    TAddAttributeTypesAction,
    TAddAttributeTypesPayload,
    TChangeMatrixModelTypeDescriptionAction,
    TChangeMatrixModelTypeDescriptionPayload,
    TChangeMatrixModelTypeEnableModelAction,
    TChangeMatrixModelTypeEnableModelPayload,
    TChangeMatrixModelTypeIdAction,
    TChangeMatrixModelTypeIdPayload,
    TChangeMatrixModelTypeNameAction,
    TChangeMatrixModelTypeNamePayload,
    TRemoveAttributeTypesAction,
    TRemoveAttributeTypesGroupsAction,
    TRemoveAttributeTypesGroupsPayload,
    TRemoveAttributeTypesPayload,
    TAddStoreEditMatrixModelTypeWorkspaceTabAction,
    TAddStoreEditmatrixModelTypeWorkspaceTabPayload,
    TChangeMatrixModelTypeAllowApprovalsPayload,
    TChangeModelTypeAllowApprovalsAction,
    TChangeMatrixModelTypeGroupAction,
    TChangeMatrixModelTypeGroupPayload,
} from './editMatrixModelTypeWorkspaceTab.actions.types';

export const addStoreEditMatrixModelTypeWorkspaceTab = (
    payload: TAddStoreEditmatrixModelTypeWorkspaceTabPayload,
): TAddStoreEditMatrixModelTypeWorkspaceTabAction => ({
    type: ADD_STORE_EDIT_MATRIX_MODEL_TYPE_WORKSPACE_TAB,
    payload,
});

export const changeMatrixModelTypeName = (
    payload: TChangeMatrixModelTypeNamePayload,
): TChangeMatrixModelTypeNameAction => ({
    type: CHANGE_MATRIX_MODEL_TYPE_NAME,
    payload,
});

export const changeMatrixModelTypeId = (payload: TChangeMatrixModelTypeIdPayload): TChangeMatrixModelTypeIdAction => ({
    type: CHANGE_MATRIX_MODEL_TYPE_ID,
    payload,
});

export const changeMatrixModelTypeDescription = (
    payload: TChangeMatrixModelTypeDescriptionPayload,
): TChangeMatrixModelTypeDescriptionAction => ({
    type: CHANGE_MATRIX_MODEL_TYPE_DESCRIPTION,
    payload,
});

export const changeMatrixModelTypeGroup = (
    payload: TChangeMatrixModelTypeGroupPayload,
): TChangeMatrixModelTypeGroupAction => ({
    type: CHANGE_MATRIX_MODEL_TYPE_GROUP,
    payload,
});

export const changeMatrixModelTypeEnableModel = (
    payload: TChangeMatrixModelTypeEnableModelPayload,
): TChangeMatrixModelTypeEnableModelAction => ({
    type: CHANGE_MATRIX_MODEL_TYPE_ENABLE_MODEL,
    payload,
});

export const matrixModelTypeAddAttributeTypes = (payload: TAddAttributeTypesPayload): TAddAttributeTypesAction => ({
    type: MATRIX_MODEL_TYPE_ADD_ATTRIBUTE_TYPES,
    payload,
});

export const matrixModelTypeRemoveAttributeTypes = (
    payload: TRemoveAttributeTypesPayload,
): TRemoveAttributeTypesAction => ({
    type: MATRIX_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES,
    payload,
});

export const matrixModelTypeRemoveAttributeTypesGroups = (
    payload: TRemoveAttributeTypesGroupsPayload,
): TRemoveAttributeTypesGroupsAction => ({
    type: MATRIX_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS,
    payload,
});

export const changeMatrixModelTypeAllowApprovals = (
    payload: TChangeMatrixModelTypeAllowApprovalsPayload,
): TChangeModelTypeAllowApprovalsAction => ({
    type: CHANGE_MATRIX_MODEL_TYPE_ALLOW_APPROVALS,
    payload,
});
