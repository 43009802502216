import {
    CANVAS_SETTINGS_SET_DOT_SPACE,
    CANVAS_SETTINGS_TOGGLE_GRID,
} from '../actionsTypes/canvas-settings.actionTypes';
import {
    TCanvasSettingsSetDotSpaceAction,
    TCanvasSettingsToggleGridAction,
} from './canvas-settings.actions.types';

export const toggleGrid = (payload): TCanvasSettingsToggleGridAction => ({
    type: CANVAS_SETTINGS_TOGGLE_GRID,
    payload,
});

export const setDotSpace = (payload): TCanvasSettingsSetDotSpaceAction => ({
    type: CANVAS_SETTINGS_SET_DOT_SPACE,
    payload,
});
