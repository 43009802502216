import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { FileUploadDialog } from '../components/FileUploadDialog.component';
import { importNodeDialogSubmit } from '../../../actions/exportNotation.actions';
import { TFileUploadDialogProps, TFileUploadImportDialogOwnProps } from '../types/FileUploadDialog.types';

const mapDispatchToProps = (dispatch, ownProps: TFileUploadImportDialogOwnProps): Partial<TFileUploadDialogProps> => ({
    onClose: () => dispatch(closeDialog(DialogType.IMPORT_UPLOAD_DIALOG)),
    onSubmit: (file: File) =>
        dispatch(importNodeDialogSubmit({ file, parentNodeId: ownProps.parentNodeId, type: ownProps.type })),
});

export const FileUploadImportDialogContainer = connect(null, mapDispatchToProps)(FileUploadDialog);
