export const OPEN_NOTIFICATIONS_TAB = 'OPEN_NOTIFICATIONS_TAB';

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';

export const CLOSE_NOTIFICATIONS_TAB = 'CLOSE_NOTIFICATIONS_TAB';

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';

export const SAVE_NOTIFICATION = 'SAVE_NOTIFICATION';

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';