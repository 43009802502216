import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { TUsersGroupsListActionProps, TUsersGroupsListProps } from '../UsersGroupsList.types';
import UsersGroupsList from '../components/UsersGroupsList.component';
import { fetchAllGroupsRequest, openEdititngGroupDataTab, deleteGroup } from '../../../../actions/groups.actions';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { GroupsSelectors } from '../../../../selectors/groups.selectors';
import { GroupDTO } from '../../../../serverapi/api';
import { EditingTabMode } from '../../data/admintool.types';

const mapStateToProps = (state: TRootState): Partial<TUsersGroupsListProps> => {
    const serverId = MetaDataSelectors.getCurrentServerId(state);

    return {
        serverId,
        groups: GroupsSelectors.getAll(serverId)(state),
    };
};

const mapDispatchToProps: (dispatch) => TUsersGroupsListActionProps = (dispatch) => ({
    onEditClicked: (groupData: GroupDTO) =>
        dispatch(openEdititngGroupDataTab({ groupData, mode: EditingTabMode.EDIT })),
    fetchAllGroups: (serverId: string) => dispatch(fetchAllGroupsRequest(serverId)),
    onAddClicked: () =>
        dispatch(openEdititngGroupDataTab({ groupData: { id: 0, groupName: '' }, mode: EditingTabMode.ADD })),
    onDeleteClicked: (groupId: number) => dispatch(deleteGroup(groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersGroupsList);
