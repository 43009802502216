import { ISpreadsheetNode } from '../../models/bpm/bpm-model-impl.types';
import { LockInfoDTO, NodeId } from '../../serverapi/api';
import { setServerIdToNodeOriginal } from '../../utils/nodeId.utils';
import { apiBundle } from '../api/api-bundle';

export class SpreadsheetDaoService {
    public static async getSpreadsheetById(
        serverId: string,
        repositoryId: string,
        spreadsheetId: string,
    ): Promise<ISpreadsheetNode> {
        const spreadsheet = (await apiBundle(serverId).spreadsheet.byId({
            repositoryId,
            spreadsheetId,
        })) as ISpreadsheetNode;
        setServerIdToNodeOriginal(spreadsheet, serverId);

        return spreadsheet;
    }

    public static async saveSpreadsheet(serverId, requestBody: { body: ISpreadsheetNode }): Promise<ISpreadsheetNode> {
        const spreadsheet = (await apiBundle(serverId).spreadsheet.save(requestBody)) as ISpreadsheetNode;
        setServerIdToNodeOriginal(spreadsheet, serverId);

        return spreadsheet;
    }

    public static async lockSpreadsheet(nodeId: NodeId): Promise<LockInfoDTO> {
        const lockInfo = await apiBundle(nodeId.serverId).spreadsheet.lock({
            repositoryId: nodeId.repositoryId,
            spreadsheetId: nodeId.id,
        });

        return lockInfo;
    }

    public static async unlockSpreadsheet(nodeId: NodeId): Promise<void> {
        await apiBundle(nodeId.serverId).spreadsheet.unlock({
            repositoryId: nodeId.repositoryId,
            spreadsheetId: nodeId.id,
        });
    }
}
