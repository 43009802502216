export const IMPORT_DIALOG_SET_STATE = 'IMPORT_DIALOG_SET_STATE';

export const IMPORT_DIALOG_SUBMIT_NODE = 'IMPORT_DIALOG_SUBMIT_NODE';

export const IMPORT_DIALOG_SUBMIT_ALL = 'IMPORT_DIALOG_SUBMIT_ALL';

export const IMPORT_DIALOG_INIT = 'IMPORT_DIALOG_INIT';

export const IMPORT_PRESET_DIALOG_REQUEST_DONE = 'IMPORT_PRESET_DIALOG_REQUEST_DONE';

export const IMPORT_DIALOG_CANCEL = 'IMPORT_DIALOG_CANCEL';

export const IMPORT_DIALOG_IMPORT_REQUEST_DONE = 'IMPORT_DIALOG_IMPORT_REQUEST_DONE';

export const IMPORT_DIALOG_APPLY_NAME = 'IMPORT_DIALOG_APPLY_NAME';

export const IMPORT_DIALOG_REPLACE_CONFIRM = 'IMPORT_DIALOG_REPLACE_CONFIRM';
