import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { AttributeType, AttributeTypeGroup } from '../../serverapi/api';
import { ApiBundle, apiBundle } from '../api/api-bundle';

export class AttributeTypesDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async deleteAttributeTypes(serverId: string, attributeTypes: AttributeType[]): Promise<void> {
        const attributeTypeList: AttributeType[] = attributeTypes.map((at) => {
            return {
                id: at.id,
                presetId: at.presetId,
                name: '',
            };
        });

        await apiBundle(serverId).attributeType.bulkDelete({ body: attributeTypeList });
    }

    public static async attributeTypeGroupBulkDelete(
        serverId,
        attributeTypeGroups: AttributeTypeGroup[],
    ): Promise<void> {
        await apiBundle(serverId).attributeTypeGroup.bulkDelete({ body: attributeTypeGroups });
    }

    public static async saveAttributeType(serverId, attributeTypes: AttributeType[]): Promise<void> {
        await apiBundle(serverId).attributeType.bulkSave({ body: attributeTypes });
    }

    public static async createAttributeType(serverId, attributeType: AttributeType): Promise<void> {
        await apiBundle(serverId).attributeType.create({ body: attributeType });
    }

    public static async deleteAttributeTypesAndGroupsRequest(
        serverId,
        attributeTypes: AttributeType[],
        attributeTypeGroups: AttributeTypeGroup[],
    ): Promise<void> {
        await this.deleteAttributeTypes(serverId, attributeTypes);
        await this.attributeTypeGroupBulkDelete(serverId, attributeTypeGroups);
    }

    public static async getAttributeTypesByModelTypeId(
        presetId: string,
        modelTypeId: string,
    ): Promise<AttributeType[]> {
        const api = this.getApi();
        return await api.attributeType.byModelTypeId({ presetId, modelTypeId });
    }
}
