import React, { ChangeEvent } from 'react';
import theme from './ScaleSlider.scss';
import { Keys } from '../../../../utils/keys';
import { Menu, Dropdown } from 'antd';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';
import { DEFAULT_ZOOM_VALUES } from '../../../../config/config';
import { convertToNumber } from '../../../../utils/ConvertToNumber.utils';

interface IZoomValueProps {
    value: number;
    onChange(value: number): void;
}

interface IZoomValueState {
    editMode: boolean;
    value: number;
}

export class ZoomValue extends React.Component<IZoomValueProps, IZoomValueState> {
    constructor(props: IZoomValueProps) {
        super(props);
        this.state = {
            editMode: false,
            value: props.value,
        };
        this.setValue = this.setValue.bind(this);
        this.changeZoomDropdown = this.changeZoomDropdown.bind(this);
    }

    onChangeValue = (): void => {
        const { MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL } = BPMMxGraph;

        const num = this.state.value || this.props.value;
        const numMoreThanMin = num < MIN_ZOOM_LEVEL ? MIN_ZOOM_LEVEL : num;
        const newZoomValue = numMoreThanMin > MAX_ZOOM_LEVEL ? MAX_ZOOM_LEVEL : num;

        this.props.onChange(newZoomValue);
    };

    setValue(e: ChangeEvent<HTMLInputElement>): void {
        const { value } = e.target;

        this.setState({
            value: Number(convertToNumber(value)),
        });
    }

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === Keys.ENTER) this.onBlurInput();
    };

    onBlurInput = (): void => {
        this.onChangeValue();
        this.changeView();
    };

    changeView = () => {
        this.setState({
            editMode: !this.state.editMode,
            value: this.props.value,
        });
    };

    changeZoomDropdown(): JSX.Element {
        return (
            <Menu>
                {DEFAULT_ZOOM_VALUES.map((item) => {
                    const onChange = () => this.props.onChange(item);

                    return (
                        <Menu.Item key={item}>
                            <div onClick={onChange}>{`${item  }%`}</div>
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
    }

    render(): JSX.Element {
        return (
            <>
                {!this.state.editMode && (
                    <Dropdown destroyPopupOnHide overlay={this.changeZoomDropdown} placement="bottom">
                        <div className={theme.value} onClick={this.changeView}>
                            {`${this.props.value  }%`}
                        </div>
                    </Dropdown>
                )}
                {this.state.editMode && (
                    <input
                        className={theme.inputValue}
                        onBlur={this.onBlurInput}
                        onKeyPress={this.handleKeyPress}
                        onChange={this.setValue}
                        value={this.state.value || ''}
                        autoFocus
                    />
                )}
            </>
        );
    }
}
