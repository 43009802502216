import { TBrowserAndVersion } from "../types/Browsers.types";

export enum Browsers {
    Chrome = 'Chrome',
    Edge = 'Edge',
    Chromium = 'Chromium',
    Firefox = 'Firefox',
    IE = 'IE',
    Safari = 'Safari',
    Opera = 'Opera',
    YaBrowser = 'YaBrowser',
    Unkown = 'Unkown',
}

export const MIN_CHROME_VERSION: string = '96.0.0.0';

export const MIN_CHROMIUM_VERSION: string = '99.0.0.0';

export const MIN_EDGE_VERSION: string = '96.0.0.0';

export const MIN_YABROWSER_VERSION: string = '22.0.0.0;'

export const SUPPORTED_BROWSERS: TBrowserAndVersion[] = [
    { browser: Browsers.Chrome, version: MIN_CHROME_VERSION },
    { browser: Browsers.Chromium, version: MIN_CHROMIUM_VERSION },
    { browser: Browsers.Edge, version: MIN_EDGE_VERSION },
    { browser: Browsers.YaBrowser, version: MIN_YABROWSER_VERSION },
];
