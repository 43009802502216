import { INode } from '../../models/bpm/bpm-model-impl.types';
import {IRepositoryApi, Node, NodeId, RepositoryNode} from '../../serverapi/api';
import { setServerIdToNodeInterface, setServerIdToNodeOriginal } from '../../utils/nodeId.utils';
import { apiBundle } from '../api/api-bundle';

export class RepositoryDAOService {
    public static async getRepositories(serverId: string): Promise<Node[]> {
        const repositories: Node[] = await apiBundle(serverId).tree.rootNodes();

        repositories.forEach((n) => setServerIdToNodeInterface(n, serverId));

        return repositories;
    }

    public static async delete(nodeId: NodeId): Promise<boolean> {
        if (!nodeId?.serverId) {
            return Promise.resolve(false);
        }
        const api: IRepositoryApi = apiBundle(nodeId.serverId).repository;

        return api.deleteRepository({ ...nodeId });
    }

    public static async save(serverId: string, node: RepositoryNode): Promise<RepositoryNode> {
        const savedNode: RepositoryNode = await apiBundle(serverId).repository.save({ body: node });
        setServerIdToNodeOriginal(savedNode as unknown as INode, serverId);

        return savedNode;
    }
}
