import { LOCALE_SELECT } from '../actionsTypes/locale.actionTypes';
import { Locale } from '../modules/Header/components/Header/header.types';
import { LocalesService } from '../services/LocalesService';
import { TLocaleSelectAction } from './locale.actions.types';

export const localeSelect = (locale: Locale): TLocaleSelectAction => {
    LocalesService.setLocale(locale);

    return {
        type: LOCALE_SELECT,
        payload: locale,
    };
};
