import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Input, Row } from 'antd';
import messages from './NotificationSettingsDialog.messages';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './NotificationSettingsDialog.scss';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { LARGE_DIALOG_WIDTH } from '../../../config/config';
import { closeDialog } from '../../../actions/dialogs.actions';
import {
    InternationalString,
    NotificationDTO,
    NotificationDTORecipientsTypeEnum,
    NotificationDTOTypeEnum,
} from '@/serverapi/api';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { TNotificationSettingsDialogProps } from './NotificationSettingsDialog.types';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import { notificationRecipients, notificationTypes } from './NotificationSettings.utils';
import { UsersSelect } from './UsersSelect/UsersSelect';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import { createNotification, saveNotification } from '@/actions/notifications.actions';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

const initState: NotificationDTO = {
    id: '',
    name: { ru: '', en: '' },
    recipientsType: 'ALL',
    type: 'SAVE_TREE_NODE',
};

export const NotificationSettingsDialog: FC<TNotificationSettingsDialogProps> = (props) => {
    const { isEditing, templates } = props;

    const intl = useIntl();
    const dispatch = useDispatch();
    const [notificationSettings, setNotificationSettings] = useState<NotificationDTO>(initState);
    const [trigget, setTrigger] = useState<boolean>(false);
    const currentLocale = useSelector(getCurrentLocale);

    useEffect(() => {
        if (isEditing) {
            setNotificationSettings(props.data);
        } else {
            const id = uuid();
            setNotificationSettings((prev) => ({ ...prev, id }));
        }
    }, []);

    const [generalForm] = Form.useForm();

    const onCloseDialog = () => dispatch(closeDialog(DialogType.NOTIFICATION_SETTINGS_DIALOG));

    const handleDispatch = () => {
        if (isEditing) {
            dispatch(saveNotification(notificationSettings));
        } else {
            dispatch(createNotification(notificationSettings));
        }
    };

    const handleSubmit = () => {
        setTrigger((prev) => !prev);
        generalForm.validateFields();
        const { id, name, type, recipientsType, templateId } = notificationSettings;
        const isValid = id && name && type && recipientsType && templateId;
        if (isValid) {
            handleDispatch();
            onCloseDialog();
        }
    };

    const handleNameInput = (name: InternationalString) => {
        setNotificationSettings((prev) => ({ ...prev, name }));
    };

    const handleTypeSelect = (type: NotificationDTOTypeEnum) => {
        setNotificationSettings((prev) => ({ ...prev, type }));
    };

    const handleRecipientsSelect = (recipientsType: NotificationDTORecipientsTypeEnum) => {
        setNotificationSettings((prev) => ({ ...prev, recipientsType, emailList: [], principalsIds: [] }));
    };

    const handlePrincipalsIdsSelect = (principalsIds: number[]) => {
        setNotificationSettings((prev) => ({ ...prev, principalsIds, emailList: [] }));
    };

    const handleEmailsInput = (emailList: string[]) => {
        setNotificationSettings((prev) => ({ ...prev, emailList, principalsIds: [] }));
    };

    const handleTemplateSelect = (templateId: string) => {
        setNotificationSettings((prev) => ({ ...prev, templateId }));
    };

    const notificationTypeOptions = notificationTypes.map((type) => {
        const label = messages[type] ? intl.formatMessage(messages[type]) : '';

        return <Select.Option key={type} label={label} value={type} />;
    });

    const notificationRecipientsOptions = notificationRecipients.map((recipients) => {
        const label = messages[recipients] ? intl.formatMessage(messages[recipients]) : '';

        return <Select.Option key={recipients} label={label} value={recipients} />;
    });

    const templatesOptions = templates.map((template) => {
        const label = LocalesService.internationalStringToString(template.name, currentLocale);

        return <Select.Option key={template.id} label={label} value={template.id} />;
    });

    const getTemplateNameById = (templateId?: string) => {
        const foundTemplate = templates.find((template) => template.id === templateId);

        return LocalesService.internationalStringToString(foundTemplate?.name, currentLocale);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCloseDialog,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: isEditing ? intl.formatMessage(messages.edit) : intl.formatMessage(messages.create),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onCloseDialog}
            title={isEditing ? intl.formatMessage(messages.editTitle) : intl.formatMessage(messages.title)}
            open
            width={LARGE_DIALOG_WIDTH}
            className={theme.dialogContainer}
            footer={footer}
        >
            <Form autoComplete='off' form={generalForm} layout="vertical">
                <Col className={theme.col}>
                    <Row className={theme.inputTitle}>{intl.formatMessage(messages.ID)}</Row>
                    <Row className={theme.inputContainer}>
                        <Input disabled value={notificationSettings.id} />
                    </Row>
                </Col>
                <Col data-test="notificationNameContainer" className={theme.col}>
                    <MultiLangInputDialog
                        generalForm={generalForm}
                        label={intl.formatMessage(messages.notificationName)}
                        mainInputName="notificationName"
                        onChange={handleNameInput}
                        multiLangValue={notificationSettings.name}
                        formItemClassName={theme.inputContainer}
                        required
                        placeholder={intl.formatMessage(messages.setNotificatonName)}
                        data-test="notificationName"
                    />
                </Col>
                <Col className={theme.col}>
                    <Select
                        label={intl.formatMessage(messages.notificationType)}
                        onChange={handleTypeSelect}
                        value={intl.formatMessage(messages[notificationSettings.type])}
                        dropdownClassName={theme.dropdown}
                        selectTextClassName={theme.selectText}
                        showSearch
                        required
                        errorTrigger={trigget}
                    >
                        {notificationTypeOptions}
                    </Select>
                </Col>
                <Col className={theme.col}>
                    <Select
                        label={intl.formatMessage(messages.recipients)}
                        onChange={handleRecipientsSelect}
                        value={intl.formatMessage(messages[notificationSettings.recipientsType])}
                        dropdownClassName={theme.dropdown}
                        selectTextClassName={theme.selectText}
                        required
                        errorTrigger={trigget}
                        showSearch
                        data-test="recipients"
                    >
                        {notificationRecipientsOptions}
                    </Select>
                </Col>

                <UsersSelect
                    selectedIds={notificationSettings.principalsIds}
                    emails={notificationSettings.emailList}
                    recipientType={notificationSettings.recipientsType}
                    onSelect={handlePrincipalsIdsSelect}
                    onEmailsInput={handleEmailsInput}
                    data-test="recipientsType"
                />
                <Col className={theme.col}>
                    <Select
                        label={intl.formatMessage(messages.templates)}
                        onChange={handleTemplateSelect}
                        dropdownClassName={theme.dropdown}
                        selectTextClassName={theme.selectText}
                        value={getTemplateNameById(notificationSettings.templateId)}
                        showSearch
                        placeholder={intl.formatMessage(messages.templatesPlaceholder)}
                        required
                        errorTrigger={trigget}
                    >
                        {templatesOptions}
                    </Select>
                </Col>
            </Form>
        </Dialog>
    );
};
