export enum SequenceEdgeTypesId {
    RECURSIVE_MESSAGE = 'c_uml_recursive_message',
    DURATION = 'c_uml_duration',
    ASYNCHRON_MESSAGE = 'c_uml_asynchron_message',
}

export const edgeTerminalOffsets = [0.5, 0.542, 0.6, 0.658];

export const STYLE_MAIN_EDGE_ID = 'mainEdgeId';

export const forkEdgeOffset = 40;
