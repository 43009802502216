// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextBlockSettings__label__EZVJn{font-size:12px;line-height:18px;margin-bottom:4px}.TextBlockSettings__colorPicker__txlgB{width:100%;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:start;padding-left:8px;border-radius:4px !important;-webkit-box-sizing:content-box;box-sizing:content-box;min-height:32px}.TextBlockSettings__inputContainer__hYCDo{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/modules/Dashboard/WidgetSettingsPanel/TextBlockSettings/TextBlockSettings.scss"],"names":[],"mappings":"AAAA,iCACI,cAAA,CACA,gBAAA,CACA,iBAAA,CAEJ,uCACI,UAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,qBAAA,CACA,gBAAA,CACA,4BAAA,CACA,8BAAA,CAAA,sBAAA,CACA,eAAA,CAGJ,0CACI,iBAAA","sourcesContent":[".label {\r\n    font-size: 12px;\r\n    line-height: 18px;\r\n    margin-bottom: 4px;\r\n}\r\n.colorPicker {\r\n    width: 100%;\r\n    justify-content: start;\r\n    padding-left: 8px;\r\n    border-radius: 4px !important;\r\n    box-sizing: content-box;\r\n    min-height: 32px;\r\n}\r\n\r\n.inputContainer {\r\n    margin-bottom: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "TextBlockSettings__label__EZVJn",
	"colorPicker": "TextBlockSettings__colorPicker__txlgB",
	"inputContainer": "TextBlockSettings__inputContainer__hYCDo"
};
export default ___CSS_LOADER_EXPORT___;
