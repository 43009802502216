import {
    ADD_NOTIFICATION_TEMPLATES,
    SAVE_NOTIFICATION_TEMPLATES,
    CREATE_NOTIFICATION_TEMPLATES,
    LOAD_NOTIFICATION_TEMPLATES,
    DELETE_NOTIFICATION_TEMPLATES,
} from '@/actionsTypes/notificationTemplates.actionTypes';
import { NotificationTemplateDTO } from '@/serverapi/api';
import {
    TAddNotificationTemplatesAction,
    TCreateNotificationTemplatesAction,
    TDeleteNotificationTemplatesAction,
    TLoadNotificationTemplatesAction,
    TSaveNotificationTemplatesAction,
} from './notificationTemplates.actions.types';

export const loadNotificationTemplatesAction = (): TLoadNotificationTemplatesAction => ({
    type: LOAD_NOTIFICATION_TEMPLATES,
});

export const addNotificationTemplates = (
    notificationTemplates: NotificationTemplateDTO[],
): TAddNotificationTemplatesAction => ({
    type: ADD_NOTIFICATION_TEMPLATES,
    payload: { notificationTemplates },
});

export const saveNotificationTemplate = (
    notificationTemplate: NotificationTemplateDTO,
): TSaveNotificationTemplatesAction => ({
    type: SAVE_NOTIFICATION_TEMPLATES,
    payload: { notificationTemplate },
});

export const createNotificationTemplate = (
    notificationTemplate: NotificationTemplateDTO,
): TCreateNotificationTemplatesAction => ({
    type: CREATE_NOTIFICATION_TEMPLATES,
    payload: { notificationTemplate },
});

export const deleteNotificationTemplate = (notificationTemplateId: string): TDeleteNotificationTemplatesAction => ({
    type: DELETE_NOTIFICATION_TEMPLATES,
    payload: { notificationTemplateId },
});
