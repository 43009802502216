import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'NotificationTemplatesDialog.title',
        defaultMessage: 'Новый шаблон уведомлений',
    },
    editTitle: {
        id: 'NotificationTemplatesDialog.editTitle',
        defaultMessage: 'Редактирование шаблона уведомлений',
    },
    cancel: {
        id: 'NotificationTemplatesDialog.cancel',
        defaultMessage: 'Отмена',
    },
    create: {
        id: 'NotificationTemplatesDialog.create',
        defaultMessage: 'Создать',
    },
    save: {
        id: 'NotificationTemplatesDialog.save',
        defaultMessage: 'Сохранить',
    },
    ID: {
        id: 'NotificationTemplatesDialog.ID',
        defaultMessage: 'ID',
    },
    templateName: {
        id: 'NotificationTemplatesDialog.templateName',
        defaultMessage: 'Название шаблона',
    },
    letterSubject: {
        id: 'NotificationTemplatesDialog.letterSubject',
        defaultMessage: 'Тема письма',
    },
    letterText: {
        id: 'NotificationTemplatesDialog.letterText',
        defaultMessage: 'Текст письма',
    },
    setTemplateName: {
        id: 'NotificationTemplatesDialog.setTemplateName',
        defaultMessage: 'Укажите название шаблона',
    },
    setSubject: {
        id: 'NotificationTemplatesDialog.setSubject',
        defaultMessage: 'Укажите тему письма',
    },
    setText: {
        id: 'NotificationTemplatesDialog.setText',
        defaultMessage: 'Укажите текст письма',
    },
});
