import { Form, Typography } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../SystemProperties.messages';
import theme from '../../SystemProperties.scss';
import { CheckStatus, SystemPropertiesFormItemNames, TPropsWithFormRef } from '../../SystemProperties.types';
import { SystemPropertiesDaoService } from '../../../../../../src/services/dao/SystemPropertiesDaoService';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { FormItemInputWithLabel } from '../FormItemInputWithLabelComponent/FormItemInputWithLabel.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

export const ScriptEngineSettings = (props: TPropsWithFormRef) => {
    const intl = useIntl();
    const { Text } = Typography;
    const { formRef } = props;
    const scriptEngineUrl = useSelector(SystemPropertiesSelectors.getScriptEngineUrl);
    const serverCallbackUrl = useSelector(SystemPropertiesSelectors.getServerCallbackUrl);

    const [scriptEngineStatus, setScriptEngineStatus] = useState<CheckStatus>();

    const scriptEngineStatusToCheckStatus = (status: string): CheckStatus => {
        switch (status) {
            case 'SCRIPT_ENGINE_IS_ACTIVE':
                return CheckStatus.success;
            case 'BPM_NOT_FOUND':
                return CheckStatus.internalFail;
            default:
                return CheckStatus.error;
        }
    };

    const handleCheckScriptEngine = async () => {
        setScriptEngineStatus(CheckStatus.validating);
        const form = formRef.current;

        try {
            const result = await SystemPropertiesDaoService.checkScriptEngine(form?.getFieldsValue());
            setScriptEngineStatus(scriptEngineStatusToCheckStatus(result));
        } catch (e) {
            setScriptEngineStatus(CheckStatus.error);
        }
    };

    return (
        <div className={theme.formTab}>
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.scriptEngineUrl}
                initialValue={scriptEngineUrl}
                label={intl.formatMessage(messages.scriptEngineUrl)}
                placeholder="http://localhost:8082/"
                data-test="server-settings_script_server-url-input"
            />
            <FormItemInputWithLabel
                initialValue={serverCallbackUrl}
                name={SystemPropertiesFormItemNames.serverCallbackUrl}
                label={intl.formatMessage(messages.serverCallbackUrl)}
                placeholder="http://localhost:8080/"
                data-test="server-settings_script_server-callback-url-input"
            />
            <Form.Item
                wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 16, offset: 8 },
                }}
            >
                <Button onClick={handleCheckScriptEngine} dataTest="server-settings_script_check-server-btn">
                    {intl.formatMessage(messages.checkServer)}
                </Button>
                <span style={{ margin: '0 12px' }}>
                    {scriptEngineStatus === CheckStatus.validating && (
                        <Text type="secondary">{`${intl.formatMessage(messages.check)}...`}</Text>
                    )}
                    {scriptEngineStatus === CheckStatus.success && <Text>{intl.formatMessage(messages.works)}</Text>}
                    {scriptEngineStatus === CheckStatus.internalFail && (
                        <Text type="warning">{intl.formatMessage(messages.addressNotFound)}</Text>
                    )}
                    {scriptEngineStatus === CheckStatus.error && (
                        <Text type="danger">{intl.formatMessage(messages.notWork)}</Text>
                    )}
                </span>
            </Form.Item>
        </div>
    );
};
