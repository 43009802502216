import type { TRootState } from '@/reducers/root.reducer.types';
import type { BPMMxGraph } from '../mxgraph/bpmgraph';
import type { NodeId, Symbol } from '../serverapi/api';
import { DefaultId } from '../serverapi/api';
import { MxConstants, MxStencil, MxStencilRegistry, MxUtils } from '../mxgraph/mxgraph';
import { PictureSymbolConstants } from '../models/pictureSymbolConstants';
import { BpmMxEditorBLLService } from '@/services/bll/BpmMxEditorBLLService';
import { getStore } from '@/store';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { SymbolSelectors } from '@/selectors/symbol.selectors';

type TSymbolStyle = { [style: string]: string | number };

export const DEFAULT_SYMBOL_STYLE: TSymbolStyle = {
    [MxConstants.STYLE_SPACING_LEFT]: 30,
    [MxConstants.STYLE_MOVABLE]: 1,
};

// не изменяемый символ
export const NOT_READABLE_SYMBOL_ID = 'not_readable_symbol';

export const isNotReadableSymbolStyle = (style: String) => style.startsWith(`${NOT_READABLE_SYMBOL_ID};`);

export const NOT_READABLE_SYMBOL: Symbol = {
    id: NOT_READABLE_SYMBOL_ID,
    presetId: DefaultId.DEFAULT_PRESET_ID,
    name: NOT_READABLE_SYMBOL_ID,
    color: '#d0d0d0',
    style: 'editable=0;movable=0;resizable=0;html=1;fillColor=#d0d0d0;',
    graphical:
        '<shape aspect="variable" h="56.0" name="rectangle" strokewidth="inherit" w="164.0"><connections/><background><fillcolor color="LightGray"/><path><move x="4.0" y="0.0"/>         <line x="160.0" y="0.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="164.0" x-axis-rotation="0.0" y="4.0"/>         <line x="164.0" y="52.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="160.0" x-axis-rotation="0.0" y="56.0"/>         <line x="4.0" y="56.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="0.0" x-axis-rotation="0.0" y="52.0"/>         <line x="0.0" y="4.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="4.0" x-axis-rotation="0.0" y="0.0"/>         <close/>       </path>       <fill/>       <fillcolor color=""/>     </background>     <foreground>      <image x="10" y="10" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAACXElEQVRYhdXXS29NURTA8V+1AyrxSPR6hBHxHminQoiRR8NH0F4GN8zFcyZiLjQdYNK0MeQToB9AvBl5JQiioiZKGOx93JPmntfNLfEf7r3WXuusvfY6a/GP6aoo34Od2I71WIRf+IrnmMRd/Oi0A5twAoNYjId4g3dxfwVWYyumcAsX8bSsI1nUMIoZ3MYQ+nLk+zCMO1FnJJ7RFgN4jQfY14b+fjzCK/RXVR7EN4xhfhvGExZgHNM4UFZpIBo/r3qStqILF6IThZGoCWEf65DxtBMTeCk/h4wKd76gg8YTevEYV7IENgiZu3cOjCcMCjVic6vN68JTy+IkDpcwMhRls7iLq7MXe/ApKmdRx080cmQaUWa44JyP0eYf9gihyU0QHIkGjrfYOxr3jhWcUYu2dqUXz+FegWJC8pWNgrU87uMMzTCsFWp7GZIsvpRauyREJTPDZ/Ea69IOLMHbksqtnKhiXLS1DOZVUJoTkghMYWUFvYZm2GlGomwUVuF9euGsv5+Ep9MLu+MBywsU855a3hNNU4tyO9OLPUJxKCogZQtRPUfmCD6ge/bGNaGTyeJUgYMJ9SibxaQWpZjQZH5XoXFog0PCD29TlsCI0EbNxe94IZ7gcp5QTWgaxnW+IbmBF2IByqNfaJ8udMiJLqFFn8a2skoHosKE0Mm0S6/w5dNCl1yJfuE6HgudTFUOCnf+QoUvn02fUF5nhE6mLn/QWC6880nhRV1WcOdl73ij5mi2VHPgSEazlViDLfiMm8K9Pys6uGqSdWOHMJxuEIZT+KI5nE4K1fD/4Dc0O4naDJAtAgAAAABJRU5ErkJggg==" w="18" h="18"/><fillstroke/>     </foreground>   </shape>',
    objectType: NOT_READABLE_SYMBOL_ID, // заглушка для ид объекта
    labelStyle: 'strokeColor=none;fillColor=none;silaText=1;fontColor=#000000;strokeWidth=1;noLabel=1;',
    icon: '',
    showLabel: false,
};

// символ без определения
export const NOT_ACCESS_SYMBOL_ID = 'not_access_symbol';

export const isNotAccessSymbolStyle = (style: String) => style.startsWith(`${NOT_ACCESS_SYMBOL_ID};`);

export const NOT_ACCESS_SYMBOL: Symbol = {
    id: NOT_ACCESS_SYMBOL_ID,
    presetId: DefaultId.DEFAULT_PRESET_ID,
    name: NOT_ACCESS_SYMBOL_ID,
    color: '#e0e0e0',
    style: 'noLabel=1;editable=0;movable=0;resizable=0;html=1;fillColor=#e0e0e0;',
    graphical:
        '<shape aspect="variable" h="56.0" name="rectangle" strokewidth="inherit" w="164.0"><connections/><background><fillcolor color="LightGray"/><path><move x="4.0" y="0.0"/>         <line x="160.0" y="0.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="164.0" x-axis-rotation="0.0" y="4.0"/>         <line x="164.0" y="52.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="160.0" x-axis-rotation="0.0" y="56.0"/>         <line x="4.0" y="56.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="0.0" x-axis-rotation="0.0" y="52.0"/>         <line x="0.0" y="4.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="4.0" x-axis-rotation="0.0" y="0.0"/>         <close/>       </path>       <fill/>       <fillcolor color=""/>     </background>     <foreground>      <image x="10" y="10" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAB+UlEQVRoge2Zy0oDMRSGPy2IYJ9AvHQhfQjrA3hZCC70IUTFh/C2UNciiBsvuFAEH0ZwI9qLayso2loXOcOIrc5MJulEmg9ChjD5c35ym2TA47FCnwXNMWAemAMKwIiUl4F74Aa4Bh4ttG2EEeAAaACtiNQELlBGnWIBeEEF+QqcAItAERiSVASWgFPgTd6to3rPCdaBT1RgJ6ihFcU4cEbYO2vWoovJggTyASxr1F+Ruk0y7JlRwuGkYyJgVTSegWEDcSXmiHA4peVctA4NaCViDLU6vRJvTkRRQC0ADcLlOhH9mg3PAzngEnjQ1PjOPXAlmlpzRdfIrOTXmvU7EWjNGdSM5A41picMahZF89agZiR1aTRvUDNPuEl2jeBTwxld3TniHN6Ia/SEkQFgB6jSfp4IiDp3JE2/6VaALYkpMdsWAk2bNnWMVKTypE5lw5QIeyYxtvYKXf6Mpycm+7/CG3ENb8Q1bBoZBPaBGurrYE/Kuk7afWSf9p15N4t40hqp0W7kKYVeZhtio0PZu63GbBo5jllmnbRDK0f70MrZiuevHz2tGO/EaTxue3G1Omr4fSQm5W/Pmf1qM3EemUHdDT8A01nF4w9WWeCNuIY34ho9YaQqeakbgUQwJbnWBd0W2V+R/kwbOkYGxExwdZplKouJXy+xvwCa8grIE/iNkQAAAABJRU5ErkJggg==" w="18" h="18"/><fillstroke/>     </foreground>   </shape>',
    objectType: NOT_ACCESS_SYMBOL_ID, // заглушка для ид объекта
    labelStyle: 'strokeColor=none;fillColor=none;silaText=1;fontColor=#000000;strokeWidth=1;noLabel=1;',
    icon: '',
    showLabel: false,
};

// символ без методологии
export const DEFAULT_SYMBOL_ID = 'default_symbol';

export const isSymbolHasNotDefinition = (style: String) => style.startsWith(`${DEFAULT_SYMBOL_ID};`);

export const DEFAULT_SYMBOL: Symbol = {
    id: DEFAULT_SYMBOL_ID,
    presetId: DefaultId.DEFAULT_PRESET_ID,
    name: DEFAULT_SYMBOL_ID,
    color: '#f0f0f0',
    style: 'noLabel=1;editable=0;movable=0;resizable=0;html=1;fillColor=#f0f0f0;',
    graphical:
        '<shape aspect="variable" h="56.0" name="rectangle" strokewidth="inherit" w="164.0"><connections/><background><fillcolor color="DarkGray"/><path><move x="4.0" y="0.0"/>         <line x="160.0" y="0.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="164.0" x-axis-rotation="0.0" y="4.0"/>         <line x="164.0" y="52.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="160.0" x-axis-rotation="0.0" y="56.0"/>         <line x="4.0" y="56.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="0.0" x-axis-rotation="0.0" y="52.0"/>         <line x="0.0" y="4.0"/>         <arc large-arc-flag="0.0" rx="4.0" ry="4.0" sweep-flag="1.0" x="4.0" x-axis-rotation="0.0" y="0.0"/>         <close/>       </path>       <fill/>       <fillcolor color=""/>     </background>     <foreground>      <image x="10" y="10" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICAgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItYWxlcnQtY2lyY2xlIj48Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMCI+PC9jaXJjbGU+PGxpbmUgeDE9IjEyIiB5MT0iOCIgeDI9IjEyIiB5Mj0iMTIiPjwvbGluZT48bGluZSB4MT0iMTIiIHkxPSIxNiIgeDI9IjEyIiB5Mj0iMTYiPjwvbGluZT48L3N2Zz4=" w="18" h="18"/><fillstroke/>     </foreground>   </shape>',
    objectType: DEFAULT_SYMBOL_ID, // заглушка для ид объекта
    labelStyle: 'strokeColor=none;fillColor=none;silaText=1;fontColor=#000000;strokeWidth=1;',
    icon: '',
    showLabel: false,
};

export const SYSTEM_STYLES = [NOT_ACCESS_SYMBOL_ID, NOT_READABLE_SYMBOL_ID, DEFAULT_SYMBOL_ID];

export const isSystemStyle = (style: string): boolean => !!SYSTEM_STYLES.find((s) => style?.startsWith(`${s};`));

export class SymbolsService {
    static readonly TAG_STYLE = 'style';
    static readonly TAG_STYLE_RULE = 'add';
    static readonly ATTR_STYLE_KEY = 'as';
    static readonly ATTR_STYLE_VALUE = 'value';

    applyStylesToGraph(graph: BPMMxGraph, symbols?: Symbol[]) {
        if (!symbols) {
            return;
        }

        const stylesheet = graph.getStylesheet();

        symbols.forEach((sym) => {
            const symStyle = this.processSymbol(sym);
            stylesheet.putCellStyle(sym.id, symStyle);
        });
        stylesheet.putCellStyle(DEFAULT_SYMBOL_ID, this.processSymbol(DEFAULT_SYMBOL));
        stylesheet.putCellStyle(NOT_READABLE_SYMBOL_ID, this.processSymbol(NOT_READABLE_SYMBOL));
        stylesheet.putCellStyle(NOT_ACCESS_SYMBOL_ID, this.processSymbol(NOT_ACCESS_SYMBOL));
    }

    prepareImageLinkToShow = (value: string, serverUrl: string): string => {
        return `${serverUrl}/${PictureSymbolConstants.DOWNLOAD_LINK}/${value}/;`;
    };

    deleteOpacity = (style: string): string => {
        const result = style.replace(/opacity=\d+;/i, '').replace(/textOpacity=\d+;/i, '');

        return result;
    };

    prepareLabelStyleToSave = (style: string): string => {
        let labelStyle = style;
        labelStyle = MxUtils.setStyle(labelStyle, MxConstants.STYLE_FILLCOLOR, 'none');
        labelStyle = MxUtils.setStyle(labelStyle, MxConstants.STYLE_STROKECOLOR, 'none');
        labelStyle = MxUtils.setStyle(labelStyle, MxConstants.STYLE_DASHED, null);
        labelStyle = BpmMxEditorBLLService.unsetLabelStatus(labelStyle);

        return labelStyle;
    };

    // eslint-disable-next-line class-methods-use-this
    processSymbol(symbol: Symbol): TSymbolStyle {
        const xml = MxUtils.parseXml(symbol.graphical);
        const mxStencil = new MxStencil(xml.firstChild);
        MxStencilRegistry.addStencil(symbol.id, mxStencil);

        const styleRules = xml.querySelectorAll(`${SymbolsService.TAG_STYLE} > ${SymbolsService.TAG_STYLE_RULE}`);

        const style: TSymbolStyle = { ...DEFAULT_SYMBOL_STYLE };
        style[MxConstants.STYLE_SHAPE] = symbol.id;
        style[MxConstants.STYLE_FONTCOLOR] = symbol.color;

        for (const rule of styleRules) {
            const attr = rule.getAttribute(SymbolsService.ATTR_STYLE_KEY);
            if (attr) {
                style[attr] = rule.getAttribute(SymbolsService.ATTR_STYLE_VALUE);
            }
        }

        return style;
    }

    getSymbolByNodeId(nodeId: NodeId, symbolId: string): Symbol | undefined {
        const store = <TRootState>getStore().getState();
        const presetId: string = TreeSelectors.presetById(nodeId)(store);
        const symbol: Symbol | undefined = SymbolSelectors.byId(
            { id: symbolId, serverId: nodeId.serverId },
            presetId,
        )(store);

        return symbol;
    }
}

let symbolServiceInstance: SymbolsService;

export function symbolService(): SymbolsService {
    if (!symbolServiceInstance) {
        symbolServiceInstance = new SymbolsService();
    }

    return symbolServiceInstance;
}
