import React, { FC } from 'react';
import theme from './ProcessIndicator.scss';
import { Spin } from 'antd';

interface IProcessIndicatorProps {
    value?: string;
}

export const ProcessIndicator: FC<IProcessIndicatorProps> = (props) => {
    const { value } = props;

    return (
        <div className={theme.container}>
            <span className={theme.text}>{value}</span>
            <Spin size="small" />
        </div>
    );
};
