import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { NodeId } from '../../../../../serverapi/api';
import { Dialog } from '../../../../UIKit/components/Dialog/Dialog.component';
import messages from '../../../messages/NewSimulationNodeDialog.messages';
import { DialogFooterButtons } from '../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TNewSimulationNodeDialogProps = {
    parentNodeId: NodeId;
    onClose: () => void;
    onSubmit: (simulationModelTitle: string, parentNodeId: NodeId) => void;
};

type TFormValues = {
    simulationModelTitle: string;
};

export const NewSimulationNodeDialog = (props: TNewSimulationNodeDialogProps) => {
    const { onClose, parentNodeId, onSubmit } = props;
    const intl = useIntl();
    const [form] = Form.useForm();
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const handleSubmit = () => {
        form.validateFields()
            .then((values: TFormValues) => {
                setSubmitDisabled(true);
                onSubmit(values.simulationModelTitle.trim(), parentNodeId);
            })
            .catch(() => undefined);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'create-element_im_cancel-button',
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.create),
                    visualStyle: 'primary',
                    dataTest: 'create-element_im_save-button',
                    disabled: isSubmitDisabled,
                },
            ]}
        />
    );

    return (
        <Dialog
            open
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.simulationAddTitle)}
            footer={footer}
        >
            <Form autoComplete='off' form={form} layout="vertical">
                <Form.Item
                    label={intl.formatMessage(messages.name)}
                    name="simulationModelTitle"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: intl.formatMessage(messages.requiredField),
                        },
                    ]}
                >
                    <Input
                        data-test="imitation-model-form_model-name-input"
                        placeholder={intl.formatMessage(messages.namePlaceholder)}
                        autoComplete="off"
                        autoFocus
                    />
                </Form.Item>
            </Form>
        </Dialog>
    );
};
