import { defineMessages } from 'react-intl';

export default defineMessages({
    Name: {
        id: 'AttributeSelectDialog.Name',
        defaultMessage: 'Наименование',
    },
    ModelType: {
        id: 'AttributeSelectDialog.ModelType',
        defaultMessage: 'Тип модели',
    },
    ObjectType: {
        id: 'AttributeSelectDialog.ObjectType',
        defaultMessage: 'Тип объекта',
    },
    EdgeType: {
        id: 'AttributeSelectDialog.EdgeType',
        defaultMessage: 'Тип связи',
    },
    CreatedAt: {
        id: 'AttributeSelectDialog.CreatedAt',
        defaultMessage: 'Дата создания',
    },
    CreatedBy: {
        id: 'AttributeSelectDialog.CreatedBy',
        defaultMessage: 'Создал',
    },
    UpdatedAt: {
        id: 'AttributeSelectDialog.UpdatedAt',
        defaultMessage: 'Дата изменения',
    },
    UpdatedBy: {
        id: 'AttributeSelectDialog.UpdatedBy',
        defaultMessage: 'Изменил',
    },
    DeletedBy: {
        id: 'AttributeSelectDialog.DeletedBy',
        defaultMessage: 'Удалил',
    },
    DeletedAt: {
        id: 'AttributeSelectDialog.DeletedAt',
        defaultMessage: 'Дата удаления',
    },
    FolderType: {
        id: 'AttributeSelectDialog.FolderType',
        defaultMessage: 'Тип папки',
    },
    SymbolType: {
        id: 'AttributeSelectDialog.SymbolType',
        defaultMessage: 'Тип символа',
    },
    UserEditDisabled: {
        id: 'AttributeSelectDialog.UserEditDisabled',
        defaultMessage: 'Запрещено редактирование пользователю',
    },
    ScriptEngineEditDisabled: {
        id: 'AttributeSelectDialog.ScriptEngineEditDisabled',
        defaultMessage: 'Запрещено редактирование скрипту',
    },
    Confidential: {
        id: 'AttributeSelectDialog.Confidential',
        defaultMessage: 'Конфиденциально',
    },
    NodeType: {
        id: 'AttributeSelectDialog.NodeType',
        defaultMessage: 'Сущность',
    },
});
