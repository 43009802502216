import type { StyledAttributeType } from '../../../models/bpm/bpm-model-impl';
import type {
    TMetodologySymbolAttributesEditorContainerOwnProps,
    TFloatingAttributesDialogProps,
} from '../FloatingAttributes.types';
import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { FloatingAttributesDialog } from '../components/FloatingAttributesDialog.component';
import { updateModelTypeWithFloatingAttributesStyles } from '../../../actions/methodologySetting.actions';
import { LocalesService } from '../../../services/LocalesService';
import { getStyledAttributeTypes } from '../FloatingAttributes.utils';

const mapStateToProps = (
    _,
    props: TMetodologySymbolAttributesEditorContainerOwnProps,
): Partial<TFloatingAttributesDialogProps> => {
    const { open, definitionAttributes, instanceAttributes, presetStyles, symbol, modelType } = props;
    const styledAttributeTypes: StyledAttributeType[] = getStyledAttributeTypes(
        presetStyles,
        definitionAttributes,
        instanceAttributes,
        modelType.attributes,
    );
    const elementName = LocalesService.internationalStringToString(symbol?.multilingualName);

    return { open, styledAttributeTypes, elementName, presetId: modelType.presetId };
};

const mapDispatchToProps = (dispatch, ownProps: TMetodologySymbolAttributesEditorContainerOwnProps) => {
    return {
        onCancel() {
            dispatch(closeDialog(DialogType.EDIT_SYMBOL_FLOATING_ATTRIBUTES_DIALOG));
        },
        onChange(attributeTypes: StyledAttributeType[]) {
            dispatch(
                updateModelTypeWithFloatingAttributesStyles({
                    attributeTypes,
                    serverNode: ownProps.serverNode,
                    symbol: ownProps.symbol,
                    modelType: ownProps.modelType,
                }),
            );
            dispatch(closeDialog(DialogType.EDIT_SYMBOL_FLOATING_ATTRIBUTES_DIALOG));
        },
    };
};

export const SymbolTypeFloatingAttributesDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FloatingAttributesDialog);
