import { NotificationDTO } from '@/serverapi/api';
import { put, select, takeEvery } from 'redux-saga/effects';
import { LocalesService } from '@/services/LocalesService';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { IWorkspaceTabServerSettingsParams, TWorkspaceTab } from '../models/tab.types';
import { defaultWorkspaceTabActions } from '../models/tab';
import { workspaceAddTab } from '../actions/tabs.actions';
import admintoolMessages from '../modules/AdminTools/messages/admintool.messages';
import { loadNotificationTemplatesAction } from '@/actions/notificationTemplates.actions';
import {
    CREATE_NOTIFICATION,
    DELETE_NOTIFICATION,
    LOAD_NOTIFICATIONS,
    OPEN_NOTIFICATIONS_TAB,
    SAVE_NOTIFICATION,
} from '@/actionsTypes/notifications.actionTypes';
import {
    TCreateNotificationAction,
    TDeleteNotificationAction,
    TOpenNotificationsTabAction,
    TSaveNotificationAction,
} from '@/actions/notifications.actions.types';
import { addNotifications, loadNotificationsAction } from '@/actions/notifications.actions';
import { NotificationsDAOService } from '@/services/dao/NotificationsDAOService';

function* loadNotifications() {
    const notifications: NotificationDTO[] = yield NotificationsDAOService.getAll();

    yield put(addNotifications(notifications));
}

function* handleOpenNotificationsTab(action: TOpenNotificationsTabAction) {
    const { node } = action.payload;
    
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    yield put(loadNotificationsAction());
    yield put(loadNotificationTemplatesAction());

    const notificationsTab: TWorkspaceTab = {
        title: intl.formatMessage(admintoolMessages[WorkSpaceTabTypes.NOTIFICATIONS]),
        nodeId: node.nodeId,
        type: WorkSpaceTabTypes.NOTIFICATIONS,
        mode: EditorMode.Read,
        params: {} as IWorkspaceTabServerSettingsParams,
        actions: defaultWorkspaceTabActions,
    };

    yield put(workspaceAddTab(notificationsTab));
}

function* handleSaveNotification(action: TSaveNotificationAction) {
    const { notification } = action.payload;
    yield NotificationsDAOService.save(notification);
    yield put(loadNotificationsAction());
}

function* handleCreateNotification(action: TCreateNotificationAction) {
    const { notification } = action.payload;
    yield NotificationsDAOService.create(notification);
    yield put(loadNotificationsAction());
}

function* handleDeleteNotification(action: TDeleteNotificationAction) {
    const { notificationId } = action.payload;
    yield NotificationsDAOService.delete(notificationId);
    yield put(loadNotificationsAction());
}

export function* notificationsSaga() {
    yield takeEvery(OPEN_NOTIFICATIONS_TAB, handleOpenNotificationsTab);
    yield takeEvery(LOAD_NOTIFICATIONS, loadNotifications);
    yield takeEvery(SAVE_NOTIFICATION, handleSaveNotification);
    yield takeEvery(CREATE_NOTIFICATION, handleCreateNotification);
    yield takeEvery(DELETE_NOTIFICATION, handleDeleteNotification);
}
