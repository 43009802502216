import React, { useState } from 'react';
import { Form, Modal, Select } from 'antd';
import { NodeId, ExportRequestDecompositionsSettingEnum } from '../../../serverapi/api';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { IntlShape } from 'react-intl';
import messages from './ExportDialog.messages';
import style from './ExportDialog.scss';
import { TreeItemType } from '../../Tree/models/tree';
import { treeExportNode } from '../../../actions/tree.actions';
import {
    ALL_DECOMPOSITIONS,
    DECOMPOSITIONS_FIRST_LVL,
    NO_DECOMPOSITIONS,
} from '../../../actionsTypes/exportDialog.actionTypes';
import { shouldCallModalSubmitHandler } from '../../../services/utils/ModalHelper';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TExportDialogProps = {
    nodeId: NodeId;
    nodeType: TreeItemType;
    name: string;
    intl: IntlShape;
};

export const ExportDialog = ({ nodeId, nodeType, name, intl }: TExportDialogProps) => {
    const dispatch = useDispatch();
    const [decompositionsSetting, setDecompositionsSetting] = useState<string>(NO_DECOMPOSITIONS);

    const handleCancelClick = () => {
        dispatch(closeDialog(DialogType.EXPORT_DIALOG));
    };

    const handleOkClick = () => {
        dispatch(
            treeExportNode({
                nodeId,
                name,
                exportRequest: {
                    decompositionsSetting: decompositionsSetting as ExportRequestDecompositionsSettingEnum,
                },
            }),
        );
        dispatch(closeDialog(DialogType.EXPORT_DIALOG));
    };

    const getDecompositionSettings = () => {
        switch (nodeType) {
            case TreeItemType.Matrix:
            case TreeItemType.Model:
                return (
                    <Form.Item
                        className={style.modelDecompositions}
                        label={intl.formatMessage(messages.decompositionModelSettingLabel)}
                        labelAlign="left"
                    >
                        <Select defaultValue={NO_DECOMPOSITIONS} onChange={(v) => setDecompositionsSetting(v)}>
                            <Select.Option key="1" value={NO_DECOMPOSITIONS}>
                                {intl.formatMessage(messages.decompositionModelNo)}
                            </Select.Option>
                            <Select.Option key="2" value={DECOMPOSITIONS_FIRST_LVL}>
                                {intl.formatMessage(messages.decompositionModelFirstLvl)}
                            </Select.Option>
                            <Select.Option key="3" value={ALL_DECOMPOSITIONS}>
                                {intl.formatMessage(messages.decompositionModelAll)}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                );
            case TreeItemType.Folder:
                return (
                    <Form.Item
                        className={style.folderDecompositions}
                        label={intl.formatMessage(messages.decompositionFolderSettingLabel)}
                    >
                        <Select
                            className={style.folderDecompositionsSelect}
                            defaultValue={NO_DECOMPOSITIONS}
                            onChange={(v) => setDecompositionsSetting(v)}
                        >
                            <Select.Option key="1" value={NO_DECOMPOSITIONS}>
                                {intl.formatMessage(messages.decompositionFolderNo)}
                            </Select.Option>
                            <Select.Option key="2" value={ALL_DECOMPOSITIONS}>
                                {intl.formatMessage(messages.decompositionFolderAll)}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                );
            default:
                return <></>;
        }
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCancelClick,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'ok',
                    onClick: handleOkClick,
                    value: intl.formatMessage(messages.continueButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    handleOkClick();
                }
            }}
        >
            <Modal
                open
                title={intl.formatMessage(messages.dialogTitle)}
                footer={footer}
                onOk={handleOkClick}
                onCancel={handleCancelClick}
            >
                <Form autoComplete='off' style={{ display: 'flex', flexDirection: 'column' }}>{getDecompositionSettings()}</Form>
            </Modal>
        </div>
    );
};
