import { NodeId, EventDescriptorMacros } from '../serverapi/api';
import {
    GET_MODEL_EVENTS_REQUEST,
    GET_MODEL_EVENTS_SUCCESS,
    SAVE_MODEL_EVENTS,
} from '../actionsTypes/modelEvents.actionsTypes';
import {
    TGetModelEventsRequestAction,
    TGetModelEventsSuccessAction,
    TSaveModelEventsAction,
} from './modelEvents.actions.types';

export const getModelEventsRequest = (nodeId: NodeId): TGetModelEventsRequestAction => ({
    type: GET_MODEL_EVENTS_REQUEST,
    payload: {
        nodeId,
    },
});

export const getModelEventsSuccess = (
    nodeId: NodeId,
    events: EventDescriptorMacros[],
): TGetModelEventsSuccessAction => ({
    type: GET_MODEL_EVENTS_SUCCESS,
    payload: {
        nodeId,
        events,
    },
});

export const saveModelEvents = (nodeId: NodeId, body: EventDescriptorMacros[]): TSaveModelEventsAction => ({
    type: SAVE_MODEL_EVENTS,
    payload: {
        nodeId,
        body,
    },
});
