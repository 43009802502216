import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { DEFAULT_DIALOG_WIDTH } from '../../../../config/config';
import { ModelType, NodeId } from '../../../../serverapi/api';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { ModelFormContainer } from '../../containers/ModelForm.container';
import messages from '../../messages/ModelDialog.messages';
import { FormInstance } from 'antd';
import { TModelDialogProps } from './ModelDialog.types';
import theme from './ModelDialog.scss';
import { TreeNode } from '@/models/tree.types';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TModelDialogState = {
    modelType?: ModelType;
    parentNodeId: NodeId;
    isSubmitEnabled: boolean;
    errors: {
        modelType: boolean;
        modelLocation: boolean;
    };
};

type TFormValues = {
    modelName: string;
    modelType: string;
};

class ModelDialog extends Component<TModelDialogProps> {
    state: TModelDialogState = {
        modelType: undefined,
        isSubmitEnabled: this.props.isModelEditor,
        parentNodeId: this.props.formInitData.parentNodeId,
        errors: {
            modelType: false,
            modelLocation: false,
        },
    };

    serverIsRoot: boolean = !this.props.formInitData.parentNodeId?.id;

    formRef = React.createRef<FormInstance>();

    handleSubmit = () => {
        const form = this.formRef.current;
        const { modelType, parentNodeId } = this.state;

        if (form) {
            form.validateFields()
                .then((values: TFormValues) => {
                    const { modelName } = values;
                    if (modelType && parentNodeId.id) {
                        this.props.onSubmit({
                            parentNodeId,
                            modelTypeId: modelType!.id,
                            modelName: modelName.trim(),
                        });
                        this.setState({ isSubmitEnabled: false });
                    }
                })
                .catch(() => undefined)
                .finally(() => {
                    if (!this.state.isSubmitEnabled) return;
                    if (!modelType || !parentNodeId.id) {
                        this.setState({
                            errors: {
                                modelType: !modelType,
                                modelLocation: !parentNodeId.id,
                            },
                        });
                    }
                });
        }
    };

    onParentNodeIdChange = (parentNode: TreeNode): void => {
        let { modelType } = this.state;
        const parentNodeId: NodeId = parentNode.nodeId;
        if (!parentNodeId || parentNodeId.repositoryId !== this.state.parentNodeId.repositoryId) {
            modelType = undefined; // если нет выбранного родительского узла или не совпадает репозиторий то обнуляем тип модели
        }
        this.setState(
            (prevState: TModelDialogState) => {
                return {
                    parentNodeId,
                    modelType,
                    errors: {
                        modelType: prevState.errors.modelType,
                        modelLocation: false,
                    },
                };
            },
            () => {
                this.props.onParentNodeIdChange(parentNode);
            },
        );
    };

    onSelectType = (modelType: ModelType) => {
        this.setState((state: TModelDialogState) => ({
            modelType: state.modelType === modelType ? undefined : modelType,
            errors: {
                modelType: false,
                modelLocation: state.errors.modelLocation,
            },
        }));
    };

    render() {
        const { open, serverId, onClose, intl } = this.props;
        const { isSubmitEnabled } = this.state;
        const customFooter = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'back',
                        onClick: onClose,
                        dataTest: 'window-creation-model_close',
                        value: intl.formatMessage(messages.modelFormCancelButton),
                    },
                    {
                        key: 'submit',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.modelFormConfirmButton),
                        visualStyle: 'primary',
                        disabled: !isSubmitEnabled,
                        dataTest: 'window-creation-model_save',
                        tooltip: !isSubmitEnabled ? intl.formatMessage(messages.needLicense) : '',
                    },
                ]}
            />
        );

        return (
            <Dialog
                onCancel={onClose}
                onOk={this.handleSubmit}
                title={intl.formatMessage(messages.modelFormName)}
                open={open}
                width={DEFAULT_DIALOG_WIDTH}
                className={theme.modelFormContainer}
                footer={customFooter}
            >
                <ModelFormContainer
                    serverIsRoot={this.serverIsRoot}
                    serverId={serverId}
                    parentNodeId={this.state.parentNodeId}
                    onParentNodeIdChange={this.onParentNodeIdChange}
                    onSelectType={this.onSelectType}
                    formRef={this.formRef}
                    modelType={this.state.modelType}
                    allowAnyModelType
                    errors={this.state.errors}
                    openedSelectNode={this.props.openedSelectNode}
                />
            </Dialog>
        );
    }
}

const ModelDialogWithIntl = injectIntl(ModelDialog);

export { ModelDialogWithIntl as ModelDialog };
