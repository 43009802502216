export const IMAGE_TO_CLIPBOARD = 'IMAGE_TO_CLIPBOARD';

export const SAVE_IMAGE = 'SAVE_IMAGE';

export const SAVE_PDF = 'SAVE_PDF';

export const SAVE_IMAGE_SVG = 'SAVE_IMAGE_SVG';

export const PRINT_MODEL = 'PRINT_MODEL';

export const OPEN_IMAGE_DOWNLOAD_SETTINGS = 'OPEN_IMAGE_DOWNLOAD_SETTINGS';

export const SAVE_GRAY_SCALE = 'SAVE_GRAY_SCALE';

export const SAVE_HIDE_DECOMPOSITION = 'SAVE_HIDE_DECOMPOSITION';

export const GET_SVG = 'GET_SVG';
