import { TPublishedModelsTableData } from '@/modules/AdminTools/PublishedModelsTab/PublishedModelTab.types';
import { TRootState } from '@/reducers/root.reducer.types';
import { Node } from '@/serverapi/api';
import { getRepositories } from './tree.selectors';
import { TreeNode } from '@/models/tree.types';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { getCurrentLocale } from './locale.selectors';
import { createSelector } from 'reselect';
import { dateFormat, timestampToMomentDate } from '@/utils/date.time.utils';
import { ServerSelectors } from './entities/server.selectors';

const publishedModelsStateSelector = (state: TRootState) => state.publishedModels;

const getAllRepositories = (state: TRootState) => {
    const serverId: string = ServerSelectors.serverId(state);
    return getRepositories(serverId)(state);
};

export namespace PubslishedModelsSelectors {
    export const getAll = (state: TRootState) => publishedModelsStateSelector(state);

    export const getPublishedModelsTableData = createSelector<
        TRootState,
        TreeNode[],
        Locale,
        Node[],
        TPublishedModelsTableData[]
    >(
        getAllRepositories,
        getCurrentLocale,
        getAll,
        (repositories: TreeNode[], locale: Locale, publishedModels: Node[]) => {
            return publishedModels.map((model) => {
                const repositoryNode: TreeNode | undefined = repositories.find(
                    (node) => node.nodeId.id === model.nodeId.repositoryId,
                );
                return {
                    id: model.nodeId,
                    repositoryName: repositoryNode?.multilingualName?.[locale] || repositoryNode?.name || '',
                    modelName: model.multilingualName?.[locale] || model.name,
                    publishedBy: model.publishedBy,
                    publishedAt: model.publishedAt,
                    publishedAtStr: `${timestampToMomentDate(model.publishedAt || -1)?.format(dateFormat)}`,
                };
            });
        },
    );
}
