import { SymbolType } from '../../models/Symbols.constants';
import { ObjectDefinitionImpl } from '../../models/bpm/bpm-model-impl';
import { PickOut } from '../../models/pick-out';
import { MxCell } from '../../mxgraph';
import { TSelectedAndUnSelectedCellsByPickOut } from '../types/selectCellsBLLService.types';
import { DiagramElement, DiagramElementTypeEnum, ObjectInstance } from '../../serverapi/api';
import { BPMMxGraph } from '../../mxgraph/bpmgraph';
import { SymbolTypeId } from '../../mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';

function isDefined<T>(value: T | null | undefined): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

export const getCellsWithoutLabel = (selectedCells: MxCell[], allCellsWithChildren: MxCell[]): MxCell[] => {
    if (!allCellsWithChildren.length) return [];
    const elementInstancesWithoutLabel: (MxCell | undefined)[] = selectedCells.map((selectedCell) => {
        if (selectedCell.getValue().type === SymbolType.LABEL)
            return allCellsWithChildren.find((cell) => cell.id === selectedCell.getValue().mainCellId);

        return selectedCell;
    });

    return elementInstancesWithoutLabel.filter(isDefined);
};

export const getElementInstances = (selectedCells: MxCell[], allCellsWithChildren: MxCell[]): DiagramElement[] => {
    return getCellsWithoutLabel(selectedCells, allCellsWithChildren).map((cell) => cell.getValue());
};

export const getSameTypeCellsToSelect = (
    type: DiagramElementTypeEnum,
    allcells: MxCell[],
    selectedCells: MxCell[],
): MxCell[] => {
    const isHasType: boolean = selectedCells.some((cell) => cell.getValue().type === type);

    return isHasType ? allcells.filter((cell) => cell.getValue().type === type) : [];
};

export const getObjectsToSelectByObjectType = (
    allCells: MxCell[],
    selectedCells: MxCell[],
    allObDef: ObjectDefinitionImpl[],
): MxCell[] => {
    const selectedObjectInstances: DiagramElement[] = getElementInstances(selectedCells, allCells);
    const selectedDefinitionObjectTypeIds: string[] = allObDef
        .filter((obDef) =>
            selectedObjectInstances
                .map((objectInstance) => (objectInstance as ObjectInstance).objectDefinitionId)
                .includes(obDef.nodeId.id),
        )
        .map((def) => def.objectTypeId);
    const sameDefinionsByObjectTypeId: ObjectDefinitionImpl[] = allObDef.filter((obDef) =>
        selectedDefinitionObjectTypeIds.includes(obDef.objectTypeId),
    );

    return allCells.filter((cell) =>
        sameDefinionsByObjectTypeId.some((def) => def.nodeId.id === cell.getValue().objectDefinitionId),
    );
};

export const getSelectedAndUnSelectedCellsByPickOut = (
    cellsWithoutLabelsAndComments: MxCell[],
    selectedCells: MxCell[],
    allObDef: ObjectDefinitionImpl[],
    pickOut: PickOut,
): TSelectedAndUnSelectedCellsByPickOut => {
    switch (pickOut) {
        case PickOut.PickOutAll: {
            return { cellsToSelect: cellsWithoutLabelsAndComments };
        }
        case PickOut.ReversePickOut: {
            const selectedCellsWithoutLabels: MxCell[] = getCellsWithoutLabel(
                selectedCells,
                cellsWithoutLabelsAndComments,
            );
            const notSelectedCells: MxCell[] = cellsWithoutLabelsAndComments.filter(
                (cell) => !selectedCellsWithoutLabels.includes(cell),
            );

            return { cellsToSelect: notSelectedCells, cellsToUnSelect: selectedCells };
        }
        case PickOut.PickOutBySymbol: {
            const selectedElementIInstances: DiagramElement[] = getElementInstances(
                selectedCells,
                cellsWithoutLabelsAndComments,
            );
            const selectedSymbolIds: string[] = selectedElementIInstances
                .map((object) => (object as ObjectInstance).symbolId)
                .filter(Boolean);
            const sameSymbolsAsSelected: MxCell[] = cellsWithoutLabelsAndComments.filter((cell) =>
                selectedSymbolIds.includes(cell.getValue().symbolId),
            );

            return {
                cellsToSelect: [
                    ...sameSymbolsAsSelected,
                    ...getSameTypeCellsToSelect('shape', cellsWithoutLabelsAndComments, selectedCells),
                    ...getSameTypeCellsToSelect('edge', cellsWithoutLabelsAndComments, selectedCells),
                ],
            };
        }
        case PickOut.PickOutByObjectType: {
            const objectsToSelect: MxCell[] = getObjectsToSelectByObjectType(
                cellsWithoutLabelsAndComments,
                selectedCells,
                allObDef,
            );

            return {
                cellsToSelect: [
                    ...objectsToSelect,
                    ...getSameTypeCellsToSelect('shape', cellsWithoutLabelsAndComments, selectedCells),
                    ...getSameTypeCellsToSelect('edge', cellsWithoutLabelsAndComments, selectedCells),
                ],
            };
        }
        default: {
            return {};
        }
    }
};

export const getCellsForSelect = (graph: BPMMxGraph): MxCell[] => {
    const allCells: MxCell[] = Object.values(graph.getModel().cells);
    const cellsForSelect: MxCell[] = allCells.filter(
        (cell) =>
            cell.getValue() !== undefined && // value может быть пустой строкой
            cell.getValue() !== null &&
            cell.getValue().type !== SymbolType.COMMENT &&
            cell.getValue().type !== SymbolType.LABEL &&
            // TODO устранить использование complexSymbolRef
            // обращение к complexSymbolRef должно происходить только внутри ComplexSymbolManager или класса конкретного символа
            cell.parent?.complexSymbolRef?.complexSymbolTypeId !== SymbolTypeId.VR,
    );

    return cellsForSelect;
};
