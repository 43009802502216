import {
    SIMULATIONS_REQUEST_SUCCESS,
    SIMULATION_DELETE,
    SIMULATION_MODELING_ADD,
    SIMULATION_MODELING_ADD_REPORT,
    SIMULATION_MODELING_GET_REPORT,
    SIMULATION_MODELING_OPEN,
    SIMULATION_MODELING_RUN,
    SIMULATION_MODELING_SUBMIT_NODE,
    SIMULATION_SETTINGS_REQUEST_SUCCESS,
    SIMULATION_SETTINGS_SAVE,
    SIMULATION_UPDATE,
} from '../actionsTypes/simulationModeling.actionTypes';
import { NodeId, SimulationRun, SimulationSettings } from '../serverapi/api';
import {
    TSimulationRunsRequestPayload,
    TSimulationRunsRequestAction,
    TSimulationUpdateAction,
    TSimulationDeleteAction,
    TSimulationSettingsSaveAction,
    TSimulationSettingsRequestPayload,
    TSimulationSettingsRequestAction,
    TOpenSimulationModelingAction,
    TRunSimulationAction,
    TAddNewSimulationAction,
    TSubmitSimulationNodeAction,
    TGetReportSimulationAction,
    TAddReportSimulationAction,
} from './simulationModeling.actions.types';

export const simulationRunsRequestSuccess = (payload: TSimulationRunsRequestPayload): TSimulationRunsRequestAction => ({
    type: SIMULATIONS_REQUEST_SUCCESS,
    payload,
});

export const updateSimulationRun = (simulation: SimulationRun): TSimulationUpdateAction => ({
    type: SIMULATION_UPDATE,
    payload: {
        simulation,
    },
});

export const deleteSimulationRun = (simulationRun: SimulationRun): TSimulationDeleteAction => ({
    type: SIMULATION_DELETE,
    payload: {
        simulationRun,
    },
});

export const saveSimulationSettings = (
    simulationSettings: SimulationSettings,
    simulationId: NodeId,
): TSimulationSettingsSaveAction => ({
    type: SIMULATION_SETTINGS_SAVE,
    payload: {
        simulationSettings,
        simulationId,
    },
});

export const simulationSettingsRequestSuccess = (
    payload: TSimulationSettingsRequestPayload,
): TSimulationSettingsRequestAction => ({
    type: SIMULATION_SETTINGS_REQUEST_SUCCESS,
    payload,
});

export const openSimulationModeling = (nodeId: NodeId): TOpenSimulationModelingAction => ({
    type: SIMULATION_MODELING_OPEN,
    payload: { nodeId },
});

export const runSimulation = (simulation: SimulationRun): TRunSimulationAction => ({
    type: SIMULATION_MODELING_RUN,
    payload: { simulation },
});

export const addNewSimulation = (simulation: SimulationRun): TAddNewSimulationAction => ({
    type: SIMULATION_MODELING_ADD,
    payload: { simulation },
});

export const submitSimulationNode = (name: string, parentNodeId: NodeId): TSubmitSimulationNodeAction => ({
    type: SIMULATION_MODELING_SUBMIT_NODE,
    payload: { name, parentNodeId },
});

export const getReport = (simulation: SimulationRun): TGetReportSimulationAction => ({
    type: SIMULATION_MODELING_GET_REPORT,
    payload: { simulation },
});

export const addReportToState = (simulation: SimulationRun): TAddReportSimulationAction => ({
    type: SIMULATION_MODELING_ADD_REPORT,
    payload: { simulation },
});