import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TUserAccountState } from '../reducers/userAccount.reducer.types';
import { dateFormat, timestampToStringDate } from '../utils/date.time.utils';
import { TPersonalProfileData, TUserAccountData } from '../modules/UserAccountTab/userAccountData.types';
import { PersonalProfile } from '@/serverapi/api';

const getState = (state: TRootState): TUserAccountState => state.userAccount;

export namespace UserAccountSelectors {
    export const getFullName = createSelector<TRootState, TUserAccountState, string>(getState, (state) => {
        const { firstName, lastName, middleName } = state.personalProfileData;
        return `${lastName || ''} ${firstName} ${middleName || ''}`.trim().replaceAll('  ', ' ');
    });

    export const getTimeChangePassword = createSelector<TRootState, TUserAccountState, string>(getState, (state) => {
        return timestampToStringDate(state.timeChangePassword, dateFormat);
    });

    export const getAccountData = createSelector<TRootState, TUserAccountState, TUserAccountData>(getState, (state) => {
        return state.personalProfileData;
    });

    export const getPasswordChangePermission = createSelector<TRootState, PersonalProfile, boolean>(
        getState,
        (state) => {
            const isPermitted: boolean = !!state.passwordChangePermission;

            return isPermitted;
        },
    );
    export const getPersonalProfileData = createSelector<TRootState, PersonalProfile, TPersonalProfileData>(
        getState,
        (state) => {
            const data: TPersonalProfileData = {
                licenses: state.licenses || [],
                accesses: state.accesses || [],
                groupNames: state.groupNames || [],
                profiles: state.profiles || [],
            };

            return data;
        },
    );
}
