// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserList__buttonsBox___ZphZ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:32px}.UserList__tableWrapper__sQeJN{padding-left:16px;padding-top:8px;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/UserManagement/components/UserList/UserList.scss"],"names":[],"mappings":"AAAA,6BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,UAAA,CAGJ,+BACI,iBAAA,CACA,eAAA,CACA,WAAA","sourcesContent":[".buttonsBox {\r\n    display: flex;\r\n    align-items: center;\r\n    width: 32px;\r\n}\r\n\r\n.tableWrapper {\r\n    padding-left: 16px;\r\n    padding-top: 8px;\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsBox": "UserList__buttonsBox___ZphZ",
	"tableWrapper": "UserList__tableWrapper__sQeJN"
};
export default ___CSS_LOADER_EXPORT___;
