import { defineMessages } from 'react-intl';

export default defineMessages({
    count: {
        id: 'StatisticsTab.count',
        defaultMessage: 'Количество',
    },
    deletedCount: {
        id: 'StatisticsTab.deletedCount',
        defaultMessage: 'Количество удаленных',
    },
    modelsCount: {
        id: 'StatisticsTab.modelsCount',
        defaultMessage: 'Модели',
    },
    objectsCount: {
        id: 'StatisticsTab.objectsCount',
        defaultMessage: 'Определения объектов',
    },
    foldersCount: {
        id: 'StatisticsTab.foldersCount',
        defaultMessage: 'Папки',
    },
    filesCount: {
        id: 'StatisticsTab.filesCount',
        defaultMessage: 'Файлы',
    },
    otherNodesCount: {
        id: 'StatisticsTab.otherNodesCount',
        defaultMessage: 'Другие элементы',
    },
});
