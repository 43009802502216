import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NodeId, ReportNode, UserProperty } from '@/serverapi/api';
import { SAVE_TIMEOUT } from '@/utils/consts';
import { getUserProperty } from '@/selectors/authorization.selectors';

type TUseAutoSaveProps = {
    isEditMode: boolean;
    nodeId: NodeId;
    //тип может быть расширен при необходимости
    dependences?: (ReportNode | undefined)[];
    callback: () => void;
};

export const useAutoSave = ({ isEditMode, nodeId, dependences, callback }: TUseAutoSaveProps) => {
    const property: UserProperty | undefined = useSelector(getUserProperty);
    const saveTimeOut: number = property?.autoSaveTimeout || SAVE_TIMEOUT;

    useEffect(() => {
        const interval = setInterval(() => {
            if (isEditMode) {
                callback();
            }
        }, saveTimeOut);

        return () => {
            clearInterval(interval);
        };
    }, [isEditMode, nodeId, ...(dependences || [])]);
};
