import { connect } from 'react-redux';
import { workspaceTabSetAction, workspaceTabSetParams } from '../../../actions/tabs.actions';
import { TGetActiveContentTypeSelector, TWorkspaceTab } from '../../../models/tab.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { TExecutingProcess } from '../../../reducers/statusBar.reducer.types';
import { StatusBarSelectors } from '../../../selectors/statusBar.selector';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { NodeId } from '../../../serverapi/api';
import { TreeItemType } from '../../Tree/models/tree';
import { StatusBar } from '../components/StatusBar/StatusBar.component';
import { TStatusBarActionsProps, TStatusBarStoreProps } from '../StatusBar.types';

const mapStateToProps = (state: TRootState) => {
    const activeTab: TWorkspaceTab | undefined = TabsSelectors.getActiveTab(state);
    const processes: TExecutingProcess[] = StatusBarSelectors.getProcesses(state);
    const contentType: TGetActiveContentTypeSelector = TabsSelectors.getActiveContentType(state);

    return {
        focusSearch: activeTab?.actions?.focusSearch ?? false,
        scaleValue: activeTab?.params?.zoomLevel ?? 100,
        disableZoom:
            contentType !== TreeItemType.Model &&
            contentType !== TreeItemType.Matrix &&
            contentType !== TreeItemType.Script &&
            contentType !== TreeItemType.Wiki,
        activeSchemeId: !activeTab || activeTab.nodeId,
        processes,
    } as TStatusBarStoreProps;
};

const mapDispatchToProps = (dispatch) =>
    ({
        onActionChange: (nodeId: NodeId, name: string, value: string | boolean) => {
            dispatch(workspaceTabSetAction({ nodeId, name, value }));
        },
        onScaleChange: (id: NodeId, value: number) => {
            dispatch(
                workspaceTabSetParams(id, {
                    zoomLevel: value,
                }),
            );
        },
        onFitClick: (nodeId: NodeId) => {
            dispatch(workspaceTabSetAction({ nodeId, name: 'zoomFit', value: true }));
        },
    } as TStatusBarActionsProps);

export const StatusBarContainer = connect(mapStateToProps, mapDispatchToProps)(StatusBar);
