import { xorWith } from 'lodash-es';
import { NodeId } from '../../serverapi/api';
import { compareNodeIds } from '../../utils/nodeId.utils';

export const getCheckedApprovalIds = (
    checkedApprovalIds: NodeId[],
    changedApprovalIds: NodeId[],
): NodeId[] => {
    return xorWith(checkedApprovalIds, changedApprovalIds, compareNodeIds) as NodeId[];
};

export const getCheckedApprovalTemplatesIds = (
    checkedApprovalIds: string[],
    changedApprovalIds: string[],
): string[] => {
    return xorWith(checkedApprovalIds, changedApprovalIds);
};
