import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';
import { TPresetVersions } from '../../sagas/types/notation.types';
import { PresetMetaData } from '../../serverapi/api';

export class PresetDAOService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getPresetMetaData(presetId: string): Promise<PresetMetaData> {
        const api = this.getApi();
        const result = await api.preset.getPresetMetaData({ presetId });

        return result;
    }

    public static async getPresetVersions(presetIds: string[]): Promise<TPresetVersions> {
        const api = this.getApi();
        const result: TPresetVersions = {};

        await Promise.all(presetIds.map(async (presetId) => {
            const presetVersion: number = await api.preset.getPresetVersion({ presetId }).catch((e) => -1);
            result[presetId] = presetVersion;
        }));

        return result;
    }
}
