import { Select } from 'antd';
import { CopyTwoTone } from '@ant-design/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { addVisioMatching, mapValueToAllPages } from '../../../../../actions/import.actions';
import { ImportSelectors } from '../../../../../selectors/import.selectors';
import { TTypeMap } from '../../../../../selectors/import.selectors.types';
import { VisioListElementTypeEnum } from '../ImportVisioCollapseTable/ImportVisioCollapseTable';
import messages from '../../messages/ImportVisioCollapseTableRow.messages';
import style from './ImportVisioCollapseTableRow.scss';
import { ObjectMappersAdapter } from '../../../../../adapters/import/ObjectMappers.adapter';
import { EdgeMappersAdapter } from '../../../../../adapters/import/EdgeMapper.adapter';
import { EdgeType } from '../../../../../serverapi/api';

type TSelecOption = {
  label: string;
  value: string | number;
}

type TNameProps = {
    name?: string;
    valueList?: string[];
};

type TImportVisioCollapseTableRowProps = {
    list?: (ObjectMappersAdapter | EdgeMappersAdapter)[];
    fileName: string;
    visioPageId: string;
    silaModelTypeId: string;
    type: VisioListElementTypeEnum;
};

type TListItem = ObjectMappersAdapter | EdgeMappersAdapter;

const getObjectOptionText = (option: TTypeMap): string => `${option.objectName}: ${option.symbolName}`;

const getEdgeOptionText = (option: TTypeMap): string => `${option.objectName}`;

const replaceNull = (str: string): string | null => (str === 'null' ? null : str);

const toTypeMap = (edge: EdgeType): TTypeMap => ({
    objectName: edge.name,
    objectType: edge.id,
    symbolId: null,
    symbolName: '',
});

const Name = ({ name, valueList = [] }: TNameProps): JSX.Element => {
    const intl = useIntl();
    const example = valueList
        .slice(0, 2)
        .map((item) => (item ? `"${item}"` : '""'))
        .join(', ');

    return (
        <div className={style.nameContainer}>
            <span className={style.name}>{name || ''}</span>
            <span>{`(${example} ${valueList.length > 3 ? intl.formatMessage(messages.etc) : ''})`}</span>
        </div>
    );
};

const Options = (
    mapOptions: TTypeMap[],
    value: (option: TTypeMap) => string,
    text: (option: TTypeMap) => string,
): TSelecOption[] =>
    mapOptions.map((option) => {
      return {
      label: text(option),
      value: value(option)
    }}
    );

export const ImportVisioCollapseTableRow = ({
    list,
    fileName,
    visioPageId,
    silaModelTypeId,
    type,
}: TImportVisioCollapseTableRowProps) => {
    const dispatch = useDispatch();
    const typeIdList: TTypeMap[] = useSelector(ImportSelectors.filteredSimbolsListByModelType(silaModelTypeId));
    const edgeList: EdgeType[] = useSelector(ImportSelectors.filterEdgesListByModelType(silaModelTypeId));
    const intl = useIntl();

    const doNotProcess = [
        {
            objectName: intl.formatMessage(messages.doNotProcess),
            name: intl.formatMessage(messages.doNotProcess),
            id: null,
            objectType: null,
            symbolId: null,
            symbolName: '',
            type: 'null: null',
        },
    ];

    const frame = [
        {
            objectName: intl.formatMessage(messages.frame),
            objectType: 'frame.type.vr',
            symbolId: 'frame.type.vr.s.header',
            symbolName: intl.formatMessage(messages.header),
            type: 'frame.type.vr: frame.type.vr.s.header',
        },
        {
            objectName: intl.formatMessage(messages.frame),
            objectType: 'frame.type.vr',
            symbolId: 'frame.type.vr.s.laneslist',
            symbolName: intl.formatMessage(messages.laneslist),
            type: 'frame.type.vr: frame.type.vr.s.laneslist',
        },
        {
            objectName: intl.formatMessage(messages.frame),
            objectType: 'frame.type.vr',
            symbolId: 'frame.type.vr.s.swimlane',
            symbolName: intl.formatMessage(messages.swimlane),
            type: 'frame.type.vr: frame.type.vr.s.swimlane',
        },
    ];

    const handleMapToAll = (obj: TListItem) => () => {
        dispatch(
            mapValueToAllPages({
                fileName,
                visioPageId,
                visioMasterId: obj.visioMasterId!,
            }),
        );
    };

    const handleSelect = (obj: TListItem) => (selectedItem: string): void => {
        const [silaTypeId, silaSymbolId] = selectedItem.split(' ');

        dispatch(
            addVisioMatching({
                fileName,
                visioPageId,
                visioMasterId: obj.visioMasterId!,
                silaSymbolId: replaceNull(silaSymbolId),
                silaTypeId: replaceNull(silaTypeId),
            }),
        );
    };

    const objectMapOptions = (): TTypeMap[] => [
        ...doNotProcess,
        ...[...typeIdList, ...frame].sort(
            (a, b) =>
                a.objectName?.localeCompare(b.objectName || '') || a.symbolName?.localeCompare(b.symbolName || '') || 0,
        ),
    ];

    const edgeMapOptions = (): TTypeMap[] => [
        ...doNotProcess,
        ...edgeList.sort((a, b) => a.name.localeCompare(b.name)).map((e) => toTypeMap(e)),
    ];

    function getTextValue(obj: TListItem): string | undefined {
        switch (type) {
            case VisioListElementTypeEnum.OBJECT: {
                const option = objectMapOptions().find(
                    (o) => o.objectType === obj.silaTypeId && o.symbolId === obj.silaSymbolId,
                );

                return option ? getObjectOptionText(option) : undefined;
            }
            case VisioListElementTypeEnum.EDGE: {
                const option = edgeMapOptions().find((e) => e.objectType === obj.silaTypeId);

                return option ? getEdgeOptionText(option) : undefined;
            }
            default:
                return undefined;
        }
    }

    function getOptions(): TSelecOption[] {
        switch (type) {
            case VisioListElementTypeEnum.OBJECT:
                return Options(
                    objectMapOptions(),
                    (o: TTypeMap) => `${o.objectType} ${o.symbolId}`,
                    getObjectOptionText,
                );
            case VisioListElementTypeEnum.EDGE:
                return Options(edgeMapOptions(), (o: TTypeMap) => `${o.objectType}`, getEdgeOptionText);
            default:
                return [];
        }
    }

    return (
        <>
            {list?.map((obj: TListItem) => (
                <div className={style.row} key={obj.visioMasterId}>
                    <Name name={obj.visioMasterName} valueList={obj.visioShapes} />

                    <div className={style.dropdownContainer}>
                        <Select
                            data-test={`import-visio_table_object-row_${obj.visioMasterName}`}
                            showSearch
                            placeholder={intl.formatMessage(messages.notSelected)}
                            className={style.typeSelect}
                            onChange={handleSelect(obj)}
                            value={getTextValue(obj)}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={getOptions()}
                        />
                    </div>

                    <div className={style.iconContainer} onClick={handleMapToAll(obj)}>
                        <CopyTwoTone style={{ fontSize: '20px' }} />
                    </div>
                </div>
            ))}
        </>
    );
};
