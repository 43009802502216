import type { TCurrentUserProfile } from '../../../../reducers/userProfile.reducer.types';
import type { NodeId, Symbol } from '../../../../serverapi/api';
import type { MxCell } from '../../../../mxgraph/mxgraph';
import React, { FC } from 'react';
import menuItemTheme from '../items/MenuItem.scss';
import theme from './GraphObjectToolbar.scss';
import cx from 'classnames';
import messages from '../../messages/CommonToolbar.messages';
import { FormatButton } from '../GraphGeneralToolbar/ToolbarItems/FormatButton/FormatButton.component';
import { ClearFormatButton } from '../GraphGeneralToolbar/ToolbarItems/ClearFormatButton/ClearFormatButton.component';
import { useIntl } from 'react-intl';
import { createCommonTooltipMessage } from '../../utils';
import { ToolbarButtonsBllService } from '../../../../services/bll/ToolbarButtonsBllService';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { useSelector } from 'react-redux';
import { EditorMode } from '../../../../models/editorMode';
import { UserProfileSelectors } from '../../../../selectors/userProfile.selectors';
import { ProfileBllService } from '../../../../services/bll/ProfileBllService';
import { ObjectInstanceImpl } from '../../../../models/bpm/bpm-model-impl';
import { SymbolSelectors } from '../../../../selectors/symbol.selectors';
import { ComplexSymbolManager } from '../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { ModelTypes } from '../../../../models/ModelTypes';

const getSelectedCellsEditable = (cells: MxCell[], userProfile: TCurrentUserProfile | undefined): boolean => {
    return cells.reduce(
        (acc, item) =>
            item?.getValue()?.type !== 'object'
                ? acc
                : acc && !!userProfile && ProfileBllService.isSymbolEditable(userProfile, item.value.idSymbol),
        true,
    );
};

const getObjectWithoutSymbol = (cells: MxCell[], symbols: Symbol[]): boolean => {
    return cells.reduce(
        (acc, item) =>
            item?.value?.type !== 'object'
                ? acc
                : !!symbols.find((symbol) => symbol.id === new ObjectInstanceImpl(item.getValue()).symbolId) || acc,
        true,
    );
};

type TGraphObjectToolbarFormatSettingsProps = {
    nodeId: NodeId;
    rawSelectedCells: MxCell[];
    hasSelectedOnlyImages: boolean;
    selectedCells: MxCell[];
    isEntityEditable: boolean;
    compact: boolean;
    modelTypeId?: string;
    presetId: string;
};

export const GraphObjectToolbarFormatSettings: FC<TGraphObjectToolbarFormatSettingsProps> = ({
    nodeId,
    rawSelectedCells,
    selectedCells,
    isEntityEditable,
    compact,
    modelTypeId,
    presetId,
    hasSelectedOnlyImages,
}) => {
    const intl = useIntl();
    const editorMode = useSelector(TabsSelectors.getEditorModeById(nodeId));
    const userProfile = useSelector(UserProfileSelectors.selectUserProfileByNodeId(nodeId));
    const symbols: Symbol[] = useSelector(SymbolSelectors.byServerIdPresetId(nodeId.serverId, presetId));

    const isSelectedCellsEditable: boolean = getSelectedCellsEditable(selectedCells, userProfile);
    const objectWithoutSymbol: boolean = getObjectWithoutSymbol(selectedCells, symbols);
    const fixedStyleComplexCells = rawSelectedCells.filter(
        (cell) => ComplexSymbolManager.isComplexSymbolCell(cell) && !ComplexSymbolManager.isCellStyleEditable(cell),
    );
    const poolOrSwimlaneCellSelected = fixedStyleComplexCells.length >= 1;
    const isSequenceGraph: boolean = modelTypeId === ModelTypes.SEQUENCE_DIAGRAM;

    const { clearFormatBtnDisabled, formatBtnDisabled } = ToolbarButtonsBllService.objectButtonsVisibility({
        isReadMode: editorMode === EditorMode.Read,
        isActiveToolbar: !!nodeId,
        isSelectedCellsEditable,
        objectWithoutSymbol,
        hasOneOrMoreElSelected: selectedCells.length >= 1,
        isEntityEditable,
        poolOrSwimlaneCellSelected,
        isSequenceGraph,
        hasSelectedOnlyImages,
    });

    return (
        <span className={cx(menuItemTheme.spanGroupRow, theme.spanGroupRow, compact && theme.compact)}>
            <FormatButton
                disabled={formatBtnDisabled}
                tooltipTitle={createCommonTooltipMessage(
                    intl.formatMessage(messages.format),
                    formatBtnDisabled,
                    poolOrSwimlaneCellSelected || hasSelectedOnlyImages
                        ? intl.formatMessage(messages.poolOrSwimlaneOrImageUnavailable)
                        : intl.formatMessage(messages.selectElementOnCanvas),
                )}
            />
            <ClearFormatButton
                availableSelectedCells={selectedCells}
                disabled={clearFormatBtnDisabled}
                tooltipTitle={createCommonTooltipMessage(
                    intl.formatMessage(messages.clearFormat),
                    clearFormatBtnDisabled,
                    poolOrSwimlaneCellSelected || hasSelectedOnlyImages
                        ? intl.formatMessage(messages.poolOrSwimlaneOrImageUnavailable)
                        : intl.formatMessage(messages.selectElementOnCanvas),
                )}
            />
        </span>
    );
};
