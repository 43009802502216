// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InformationItem__item__SngtA{display:-webkit-box;display:-ms-flexbox;display:flex}.InformationItem__dot__WYV40{height:8px;width:8px;margin-top:7px;margin-right:10px;border-radius:4px}.InformationItem__red___bFPv{background:#a00}.InformationItem__green__j9rky{background:#0a4}.InformationItem__yellow__Vxxgw{background:#ffd429}.InformationItem__gray__U9P8M{background:#bdbfc0}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Monitoring/components/InformationItem/InformationItem.scss"],"names":[],"mappings":"AACI,8BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGJ,6BACI,UAAA,CACA,SAAA,CACA,cAAA,CACA,iBAAA,CAEA,iBAAA,CAGJ,6BACI,eAAA,CAGJ,+BACI,eAAA,CAGJ,gCACI,kBAAA,CAGJ,8BACI,kBAAA","sourcesContent":[":local {\r\n    .item {\r\n        display: flex;\r\n    }\r\n\r\n    .dot {\r\n        height: 8px;\r\n        width: 8px;\r\n        margin-top: 7px;\r\n        margin-right: 10px;\r\n\r\n        border-radius: 4px;\r\n    }\r\n\r\n    .red {\r\n        background: #AA0000;\r\n    }\r\n\r\n    .green {\r\n        background: #00AA44;\r\n    }\r\n\r\n    .yellow {\r\n        background: #FFD429;\r\n    }\r\n\r\n    .gray {\r\n        background: #bdbfc0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "InformationItem__item__SngtA",
	"dot": "InformationItem__dot__WYV40",
	"red": "InformationItem__red___bFPv",
	"green": "InformationItem__green__j9rky",
	"yellow": "InformationItem__yellow__Vxxgw",
	"gray": "InformationItem__gray__U9P8M"
};
export default ___CSS_LOADER_EXPORT___;
