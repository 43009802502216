import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';

export function getSideLimitingScale(side: number, limit: number): number {
    return side > limit ? limit / side : 1;
}

export function getLimitingScale(width: number, height: number, widthLimit: number, heightLimit: number): number {
    const widthScale: number = getSideLimitingScale(width, widthLimit);
    const heightScale: number = getSideLimitingScale(height, heightLimit);

    return Math.min(widthScale, heightScale);
}

// дефалтный множитель увеличивает изображение, иначе видно пиксели
// у канваса есть ограничение по размерам, поэтому большие модели вписываем в пределы
export function getImageScale(
    width: number,
    height: number,
    primaryScale: number,
    maxWidth: number,
    maxHeight: number,
): number {
    return primaryScale * getLimitingScale(width * primaryScale, height * primaryScale, maxWidth, maxHeight);
}

export function getGrayScale(isPrint: boolean | undefined): string {
    return !isPrint && LocalStorageDaoService.isGrayScaleActive() ? 'grayscale(1)' : 'none';
}

export function getHideDecomposition(isPrint: boolean | undefined): boolean {
    return !isPrint && LocalStorageDaoService.isHiddenDecompositionActive();
}