import { Form, FormInstance } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import theme from './PresetProfileGeneralSettings.scss';
import messages from './PresetProfileGeneralSettings.messages';
import { InternationalString, PresetProfile, ServerProfile } from '../../../../../../../serverapi/api';
import { useSelector } from 'react-redux';
import { clone } from 'lodash-es';
import { ServerProfileSelectors } from '../../../../../../../selectors/serverProfile.selectors';
import { PresetProfileSelectors } from '../../../../../../../selectors/presetSettings/presetProfile.selectors';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { Select } from '../../../../../../UIKit/components/Select/Select.component';
import { LocalesService } from '../../../../../../../services/LocalesService';

type TPresetProfileEditGeneralSettings = {
    presetProfile: PresetProfile;
    serverId: string;
    presetId: string;
    generalForm: FormInstance<any> | undefined;
    hideServerProfileSetter?: boolean;
    setPresetProfile: (presetProfile: PresetProfile) => void;
};

const PresetProfileEditGeneralSettings: FC<TPresetProfileEditGeneralSettings> = (props) => {
    const { presetProfile, serverId, presetId, generalForm, hideServerProfileSetter, setPresetProfile } = props;
    const intl = useIntl();
    const { serverProfiles } = useSelector(ServerProfileSelectors.byServerId(serverId));
    const presetProfiles = useSelector(PresetProfileSelectors.byPresetId({ presetId, serverId }))?.presetProfiles || [];
    const serverProfilesForDisplay = serverProfiles.filter((sProfile) => {
        const currentTakenServerProfile = sProfile.id === presetProfile.serverProfileId;
        if (currentTakenServerProfile) {
            return true;
        }

        const isOccupiedPresetProfile = presetProfiles.some((pProfile) => pProfile.serverProfileId === sProfile.id);

        return !isOccupiedPresetProfile;
    });

    const selectServerProfile = (serverProfileId: string) => {
        if (!serverProfileId) {
            const newPresetProfile = clone(presetProfile);
            delete newPresetProfile.serverProfileId;
            setPresetProfile(newPresetProfile);
        } else {
            setPresetProfile({ ...presetProfile, serverProfileId });
        }
    };

    const onChangeName = (value: InternationalString) => {
        setPresetProfile({ ...presetProfile, multilingualName: value });
    };

    const onChangeDescription = (value: InternationalString) => {
        setPresetProfile({ ...presetProfile, multilingualDescription: value });
    };

    const setSelectValue = () => {
        const profile: ServerProfile | undefined = serverProfilesForDisplay.find(
            (p) => p.id === presetProfile.serverProfileId,
        );

        if (!profile?.multilingualName) return intl.formatMessage(messages.withoutPresetProfile);

        return LocalesService.internationalStringToString(profile.multilingualName);
    };

    return (
        <Form layout="vertical" form={generalForm} className={theme.form}>
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.newProfile)}
                multiLangValue={presetProfile.multilingualName}
                onChange={onChangeName}
                required
                mainInputName="multilingualName"
                mainInputClassName={theme.input}
                generalForm={generalForm}
                label={intl.formatMessage(messages.name)}
                data-test="access-rights-profile-name-input"
            />
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.description)}
                multiLangValue={presetProfile.multilingualDescription}
                onChange={onChangeDescription}
                textarea
                mainInputName="multilingualDescription"
                mainInputClassName={theme.textArea}
                generalForm={generalForm}
                label={intl.formatMessage(messages.description)}
                data-test="access-rights-profile-description-input"
            />
            {!hideServerProfileSetter && (
                <div className={theme.selectWrapper}>
                    <Select
                        originalTheme
                        onChange={selectServerProfile}
                        value={setSelectValue()}
                        data-test="access-rights-profile-correlate-edit"
                        label={intl.formatMessage(messages.correspondingServerProfile)}
                    >

                        {[
                            { id: 'empty', name: intl.formatMessage(messages.withoutPresetProfile) },
                            ...serverProfilesForDisplay,
                        ].map((sProfile) => (
                            <Select.Option key={sProfile.id} value={sProfile.id} label={sProfile.name} />
                        ))}
                    </Select>
                </div>
            )}
        </Form>
    );
};

export default PresetProfileEditGeneralSettings;
