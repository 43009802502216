import { useEffect, useRef, useState } from 'react';

export const useCloseDropdownOnScroll = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const [isClosedAllMenu, setIsClosedAllMenu] = useState(false);

    const handleScroll = () => {
        setIsClosedAllMenu(true);
    };

    useEffect(() => {
        const scrollBody = tableRef.current?.querySelector('.ant-table-body');

        if (scrollBody) {
            scrollBody.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollBody) {
                scrollBody.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (isClosedAllMenu) setIsClosedAllMenu(false);
    }, [isClosedAllMenu]);

    return {tableRef, closedAllMenu: isClosedAllMenu};
};
