// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorWrapper__message__pW68a{color:#ff4d4f;min-height:24px;font-size:12px;line-height:1.5715;-webkit-transition:color .3s cubic-bezier(0.215, 0.61, 0.355, 1);transition:color .3s cubic-bezier(0.215, 0.61, 0.355, 1);padding-top:2px}.ErrorWrapper__border___02UQ>:first-child>:first-child{border-color:#ff4d4f !important;border-width:1px;border-style:solid;border-radius:2px}", "",{"version":3,"sources":["webpack://./src/modules/FloatingAttributes/components/common/Inputs/ErrorWrapper.scss"],"names":[],"mappings":"AAAA,8BACI,aAAA,CACA,eAAA,CACA,cAAA,CACA,kBAAA,CACA,gEAAA,CAAA,wDAAA,CACA,eAAA,CAGJ,uDACI,+BAAA,CACA,gBAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":[".message {\r\n    color: #ff4d4f;\r\n    min-height: 24px;\r\n    font-size: 12px;\r\n    line-height: 1.5715;\r\n    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);\r\n    padding-top: 2px;\r\n}\r\n\r\n.border > :first-child > :first-child {\r\n    border-color: #ff4d4f !important;\r\n    border-width: 1px;\r\n    border-style: solid;\r\n    border-radius: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "ErrorWrapper__message__pW68a",
	"border": "ErrorWrapper__border___02UQ"
};
export default ___CSS_LOADER_EXPORT___;
