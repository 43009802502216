import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'SilaAcceptAgreement.cancel',
        defaultMessage: 'Отмена',
    },
    accept: {
        id: 'SilaAcceptAgreement.accept',
        defaultMessage: 'Принять',
    },
    agreement: {
        id: 'AcceptAgreement.silaAgreement',
        defaultMessage: 'Лицензионное соглашение SILA Union',
    },
});
