import React, { SyntheticEvent, useState } from 'react';
import theme from './approvalTable.scss';
import messages from '../messages/approval.messages';
import { useIntl } from 'react-intl';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TElementNamesProps = {
    elementNames: string[];
    setNeedToRender: (needToRender: boolean) => void;
};

export const ElementNames = (props: TElementNamesProps): JSX.Element => {
    const intl = useIntl();
    const [showList, setShowList] = useState<boolean>(false);
    const { elementNames, setNeedToRender } = props;

    const [firstElementName, ...elementNodesInHiddenList] = elementNames;

    const handleClick = (event: SyntheticEvent<HTMLElement, Event>) => {
        event.stopPropagation();
        setShowList(true);
        setNeedToRender(true);
    };

    return (
        <div className={theme.elementNamesContainer}>
            <div title={firstElementName} className={theme.displayEllipsis}>
                {firstElementName}
            </div>
            {elementNodesInHiddenList.length > 0 && !showList && (
                <Button visualStyle="text" size="small" onClick={handleClick}>
                    {`${intl.formatMessage(messages.more)} ${elementNodesInHiddenList.length}`}
                </Button>
            )}
            {showList &&
                elementNodesInHiddenList.map((name) => {
                    return (
                        <div key={name} title={name} className={theme.displayEllipsis}>
                            {' '}
                            {name}
                        </div>
                    );
                })}
        </div>
    );
};
