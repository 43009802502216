export const OFFSET_TOP_BOTTOM = 60;

export const OFFSET_LEFT_RIGHT = 20;

export const CELLS_GAP = 60;

export const SWIMLANE_NO_LABEL_FLAG = 'swimlaneNoLabel';

export const DEFAULT_CONTENT_COLUMN_WIDTH = 200;

export const DEFAULT_CONTENT_COLUMN_HEIGHT = 200;
