import {
    RECENT_ADD_MODEL,
    RECENT_LOAD_SUCCESS,
    RECENT_REFRESH,
    RECENT_UPDATE_MODEL,
} from '../actionsTypes/recent.actionTypes';
import {TRecentModel, TRecentModelMap} from '../models/recentModel.types';
import { TRecentRefreshAction,
    TRecentLoadSuccessAction,
    TRecentAddModelAction,
    TRecentUpdateModelAction } from './recent.actions.types';

export const recentRefresh = (): TRecentRefreshAction => ({
    type: RECENT_REFRESH,
});

export const recentLoadSuccess = (models: TRecentModelMap = {}): TRecentLoadSuccessAction => ({
    type: RECENT_LOAD_SUCCESS,
    payload: {
        models,
    },
});

export const recentAddModel = (model: TRecentModel): TRecentAddModelAction => ({
    type: RECENT_ADD_MODEL,
    payload: {
        model,
    },
});

export const recentUpdateModel = (model: TRecentModel): TRecentUpdateModelAction => ({
    type: RECENT_UPDATE_MODEL,
    payload: {
        model,
    },
});

