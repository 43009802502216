import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { Symbol } from '../../serverapi/api';

const symbolEditorStateSelector = (state: TRootState) => state.symbolEditor;

export namespace SymbolEditorSelectors {
    export const editableSymbol = (compositeId: { symbolId: string; serverId: string; presetId: string }) =>
        createSelector(symbolEditorStateSelector, (state): Symbol | undefined => state.data.get(compositeId));
}
