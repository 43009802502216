import { connect } from 'react-redux';
import { ObjectDefinitionImpl } from '../../../models/bpm/bpm-model-impl';
import { EditorMode } from '../../../models/editorMode';
import { IWorkspaceTabItemModelParams } from '../../../models/tab.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { FullModelDefinition, NodeId, ModelNode, PresetImage } from '../../../serverapi/api';
import { setServerIdToNodeInterface } from '../../../utils/nodeId.utils';
import { GridTypeOnCanvas } from '../../MainMenu/components/GraphGeneralToolbar/GridTypeOnCanvasConstant';
import { Editor } from '../components/Editor/Editor.component';
import { TEditorProps } from '../Editor.types';
import { PresetImageSelectors } from '../../../selectors/presetSettings/presetImage.selectors';
import { updateAllCellsOverlays } from '../../../actions/overlay.actions';
import { addAutoSaveModelIntervalId, deleteAutoSaveModelIntervalId } from '@/actions/autoSaveModelIntervalIds.actions';

type TEditorContainerOwnProps = {
    definition: FullModelDefinition;
};

const parseMindMapModel = (model: ModelNode): [] => {
    try {
        return (model.graph && JSON.parse(model.graph)?.graph) || [];
    } catch (e) {
        return [];
    }
};

const mapStateToProps = (state: TRootState, ownProps: TEditorContainerOwnProps): Partial<TEditorProps> => {
    const { definition } = ownProps;
    const objects: ObjectDefinitionImpl[] = definition.objects as ObjectDefinitionImpl[];
    const serverId = '';
    const model = setServerIdToNodeInterface(definition.model, serverId);

    const presetImages: PresetImage[] = PresetImageSelectors.listAllByPreset(
        serverId,
        ownProps.definition.modelType?.presetId || '',
    )(state);

    return {
        model,
        id: `view${definition.model.nodeId.id}`,
        serverId,
        modelType: definition.modelType,
        isGridEnabled: false,
        gridType: GridTypeOnCanvas.noGrid,
        // elements: definition.model.elements,
        graph: parseMindMapModel(model),
        serverUrl: '',
        symbols: definition.symbols || [],
        actions: {
            undo: false,
            redo: false,
            focusSearch: false,
            zoomFit: false,
            cut: false,
            paste: false,
            delete: false,
            copy: false,
        },
        activeKey: { serverId, repositoryId: '', id: '' } as NodeId,
        objectDefinitions: objects,
        filters: {},
        params: {} as IWorkspaceTabItemModelParams,
        mode: EditorMode.Read,
        draggedNode: undefined,
        commentsEnabledSchemesIds: [],
        presetImages,
    };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapDispatchToProps: (dispatch, ownProps: TEditorContainerOwnProps) => Partial<TEditorProps> = (dispatch) => ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onInit: (nodeId: NodeId) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDestroy: (nodeId: NodeId) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFileDrop: (draggedFile: File) => {},
    onLock: () => {},
    onUnlock: () => {},
    onChangeModel: () => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onParamsChange: (name: string, value: {}) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onActionChange: (name: string, value: boolean) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDragEnterObject: (supportedObjectTypeSymbols: any[], nodeId: NodeId) => {},
    updateAllCellsOverlays: (graphId: NodeId) => dispatch(updateAllCellsOverlays(graphId)),
    onAddAutoSaveModelIntervalId: (id: number) => dispatch(addAutoSaveModelIntervalId(id)),
    onDeleteAutoSaveModelIntervalId: (id: number) => dispatch(deleteAutoSaveModelIntervalId(id)),
});

export const SimpleEditorContainer = connect(mapStateToProps, mapDispatchToProps)(Editor);
