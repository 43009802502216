import { TMessage } from '../utils/forms.types';
import {
    MODEL_DIALOG_INIT,
    MODEL_DIALOG_PARENT_NODE_ID_CHANGED,
    MODEL_DIALOG_SET_STATE,
    MODEL_DIALOG_SUBMIT_DATA,
    MODEL_DIALOG_SUBMIT_RESULT,
} from '../actionsTypes/modelDialog.actionTypes';
import {
    TPayloadModelDialogInitAction,
    TModelDialogInitAction,
    TPayloadModelDialogSetStateAction,
    TModelDialogSetStateAction,
    TPayloadModelDialogSubmitData,
    TModelDialogSubmitData,
    TModelDialogSubmitResult,
    TPayloadModelDialogParentNodeIdChangedAction,
    TModelDialogParentNodeIdChangedAction,
} from './modelDialog.actions.types';

export const modelDialogInit = (payload: TPayloadModelDialogInitAction): TModelDialogInitAction => ({
    type: MODEL_DIALOG_INIT,
    payload,
});

export const modelDialogSetState = (payload: TPayloadModelDialogSetStateAction): TModelDialogSetStateAction => ({
    type: MODEL_DIALOG_SET_STATE,
    payload,
});

export const modelDialogSubmit = (payload: TPayloadModelDialogSubmitData): TModelDialogSubmitData => ({
    type: MODEL_DIALOG_SUBMIT_DATA,
    payload,
});

export const modelDialogSubmitResult = (result: string, error?: TMessage): TModelDialogSubmitResult => ({
    type: MODEL_DIALOG_SUBMIT_RESULT,
    payload: {
        result,
        error,
    },
});

export const modelDialogParentNodeIdChanged = (
    payload: TPayloadModelDialogParentNodeIdChangedAction,
): TModelDialogParentNodeIdChangedAction => ({
    type: MODEL_DIALOG_PARENT_NODE_ID_CHANGED,
    payload,
});
