import { ApprovalTemplateDTO } from '@/serverapi/api';
import { cloneDeep } from 'lodash-es';
import { TReducer } from '@/utils/types';
import {
    ADD_APPROVAL_TEMPLATES,
    CHANGE_CHECK_ALL_APPROVAL_TEMPLATES,
    CHECK_APPROVAL_TEMPLATES,
    DELETE_APPROVAL_TEMPLATES_FROM_STORE,
    EDIT_APPROVAL_TEMPLATE,
} from '@/actionsTypes/approvalTemplates.actionTypes';
import { getCheckedApprovalTemplatesIds } from './utils/approval.reducer.utils';
import { TApprovalTemplates, TApprovalTemplatesState } from './approvalTemplates.reducer.types';
import { getApprovalTemplatesIds } from '@/services/bll/ApprovalTemplatesBLLService';

const initial: TApprovalTemplatesState = {
    byId: {} as TApprovalTemplates,
    editingApprovalTemplate: {} as ApprovalTemplateDTO,
    checkedApprovalTemplatesIds: [],
};

export const approvalTemplatesReducer: TReducer<TApprovalTemplatesState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_APPROVAL_TEMPLATES: {
            const {
                payload: { approvalTemplates },
            } = action;
            const stateClone: TApprovalTemplatesState = cloneDeep(state);
            approvalTemplates.forEach((approvalTemplate) => {
                stateClone.byId[approvalTemplate.id] = { ...approvalTemplate };
            });
            stateClone.editingApprovalTemplate = {} as ApprovalTemplateDTO;

            return stateClone;
        }

        case EDIT_APPROVAL_TEMPLATE: {
            const {
                payload: { approvalTemplate },
            } = action;

            const stateClone: TApprovalTemplatesState = cloneDeep(state);

            return { ...stateClone, editingApprovalTemplate: approvalTemplate };
        }

        case CHECK_APPROVAL_TEMPLATES: {
            const {
                payload: { id },
            } = action;
            const stateClone: TApprovalTemplatesState = cloneDeep(state);
            const checkedApprovalsIds: string[] = stateClone.checkedApprovalTemplatesIds;
            const newApprovalIds: string[] = getCheckedApprovalTemplatesIds(checkedApprovalsIds, id);

            stateClone.checkedApprovalTemplatesIds = newApprovalIds;

            return stateClone;
        }

        case CHANGE_CHECK_ALL_APPROVAL_TEMPLATES: {
            const stateClone: TApprovalTemplatesState = cloneDeep(state);
            const checkedApprovalsIds: string[] = stateClone.checkedApprovalTemplatesIds;
            const approvalIds: string[] = getApprovalTemplatesIds(stateClone.byId);
            const newApprovalIds: string[] =
                checkedApprovalsIds.length !== approvalIds.length ? approvalIds.map((id: string) => id) : [];

            stateClone.checkedApprovalTemplatesIds = newApprovalIds;

            return stateClone;
        }

        case DELETE_APPROVAL_TEMPLATES_FROM_STORE: {
            const {
                payload: { approvalTemplateIds },
            } = action;

            const stateClone: TApprovalTemplatesState = cloneDeep(state);
            const { checkedApprovalTemplatesIds } = state;

            approvalTemplateIds.forEach((approvalTemplateId) => {
                delete stateClone.byId[approvalTemplateId];
            });
            stateClone.checkedApprovalTemplatesIds = checkedApprovalTemplatesIds.filter(
                (idx) => !approvalTemplateIds.find((id) => idx === id),
            );

            return stateClone;
        }

        default:
            return state;
    }
};
