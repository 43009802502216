import { MailMessageDTO } from '@/serverapi/api';
import { TReducer } from '@/utils/types';
import { ADD_MESSAGES } from '@/actionsTypes/messages.actionTypes';
import { TMessagesState } from './messages.reducer.types';

const initial: MailMessageDTO[] = [];

export const messagesReducer: TReducer<TMessagesState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_MESSAGES: {
            const {
                payload: { messages },
            } = action;

            return messages;
        }

        default:
            return state;
    }
};

