import React from 'react';
import { useIntl } from 'react-intl';
import messages from './DeleteConfirmationDialog.messages';
import theme from './DeleteConfirmationDialog.scss';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { closeDialog } from '../../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../../DialogRoot/DialogRoot.constants';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TDeleteConfirmationDialogProps = {
    open: boolean;
    onDelete: () => void;
    deleteQuestion?: string;
    dialogContent?: React.ReactNode;
};

export const DeleteConfirmationDialog = (props: TDeleteConfirmationDialogProps) => {
    const { onDelete, deleteQuestion, dialogContent, open } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const onCancel = () => dispatch(closeDialog(DialogType.DELETE_CONFIRMATION));

    const onOk = () => {
        onDelete();
        dispatch(closeDialog(DialogType.DELETE_CONFIRMATION));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'type-group-name-button-cancel',
                },
                {
                    key: 'ok',
                    onClick: onOk,
                    value: intl.formatMessage(messages.delete),
                    visualStyle: 'primary',
                    dataTest: 'type-group-name-button-ok',
                    danger: true,
                },
            ]}
        />
    );

    return (
        <Dialog
            title={<span className={theme.title}>{deleteQuestion || intl.formatMessage(messages.deleteTitle)}</span>}
            className={classnames(theme.dialog, !dialogContent ? theme.emptyDialogContent : '')}
            onCancel={onCancel}
            onOk={onOk}
            open={open}
            footer={footer}
        >
            {dialogContent}
        </Dialog>
    );
};
