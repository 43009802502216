import { defineMessages } from 'react-intl';

export default defineMessages({
    straight: {
        id: 'EdgeStyle.straight',
        defaultMessage: 'Прямая',
    },
    orthogonal: {
        id: 'EdgeStyle.orthogonal',
        defaultMessage: 'Прямоугольная форма ломаной линии',
    },
    simple: {
        id: 'EdgeStyle.simple',
        defaultMessage: 'Простой',
    },
    isometric: {
        id: 'EdgeStyle.isometric',
        defaultMessage: 'Изометрический',
    },
    curved: {
        id: 'EdgeStyle.curved',
        defaultMessage: 'Геометрическая кривая',
    },
    entityRelation: {
        id: 'EdgeStyle.entityRelation',
        defaultMessage: 'Связь сущностей',
    },
});
