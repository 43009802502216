import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import { FontSizeSelect } from '@/modules/MainMenu/components/FontSizeSelect/FontSizeSelect.component';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../RichTextEditorToolbar.scss';

const getFocusedBlock = (editorState) => {
    const fontFamilyStyle = getSelectionCustomInlineStyle(editorState, ['FONTSIZE']);
    if (fontFamilyStyle) {
        return fontFamilyStyle.FONTSIZE?.replace('fontsize-', '');
    }

    return undefined;
};

const getChangedBlock = (editorState, value) => toggleCustomInlineStyle(editorState, 'fontSize', value);

const FontSizeComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <div className={theme.tooltipContainer} data-test="wiki-toolbar-group_select-font-size-button">
            <FontSizeSelect
                onChange={setCurrentState}
                value={value || ''}
                className={theme.selectFontSize}
                tooltipTitle={intl.formatMessage(messages.fontSize)}
            />
        </div>
    );
};

export default FontSizeComponent;
