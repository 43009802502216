export const OPEN_EVENT_ACCESS_TAB = 'OPEN_EVENT_ACCESS_TAB';

export const FETCH_ALL_EVENTS = 'FETCH_ALL_EVENTS';

export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';

export const CREATE_EVENT = 'CREATE_EVENT';

export const DELETE_EVENT = 'DELETE_EVENT';

export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';

export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';

export const EVENT_DIALOG_ADD_NODE_ID = 'EVENT_DIALOG_ADD_NODE_ID';

export const EVENT_DIALOG_ADD_NODE_PATH = 'EVENT_DIALOG_ADD_NODE_PATH';

export const EVENT_DIALOG_DELETE_NODE_ID = 'EVENT_DIALOG_DELETE_NODE_ID';

export const EVENT_DIALOG_DELETE_PARENT_NODE_ID = 'EVENT_DIALOG_DELETE_PARENT_NODE_ID';

export const EVENT_DIALOG_DELETE_SCRIPT_NODE_ID = 'EVENT_DIALOG_DELETE_SCRIPT_NODE_ID';

export const UPDATE_EVENT = 'UPDATE_EVENT';

export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';

