import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'NewDashboardDialog.name',
        defaultMessage: 'Имя дашборда',
    },
    cancel: {
        id: 'NewDashboardDialog.cancel',
        defaultMessage: 'Отмена',
    },
    create: {
        id: 'NewDashboardDialog.create',
        defaultMessage: 'Создать',
    },
    title: {
        id: 'NewDashboardDialog.Title',
        defaultMessage: 'Создать дашборд',
    },
    requiredField: {
        id: 'NewDashboardDialog.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    namePlaceholder: {
        id: 'NewDashboardDialog.namePlaceholder',
        defaultMessage: 'Имя нового дашборда'
    }
});
