import { getUMLMessageTemplate } from './sequenceSymbolDiagramElements';
import { SymbolTypeId } from '../ComplexSymbol.constants';
import MetaInfoComplexSymbol from '../MetaInfoComplexSymbol.class';
import ShapeInstanceSerializer from '../serializers/ShapeInstanceSerializer.class';
import { ComplexSymbolManager } from '../../ComplexSymbolManager.class';
import { EdgeInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { v4 as uuid } from 'uuid';
import { MxCell, MxCellState } from 'MxGraph';
import { EdgeType } from '@/serverapi/api';
import { MxGeometry, MxConstants } from '@/mxgraph/mxgraph';
import { SequenceGraph } from '@/mxgraph/SequenceGraph/SequenceGraph';
import { SequenceEdgeTypesId } from '@/mxgraph/SequenceGraph/SequenceConstants';

export class UMLMessage extends MetaInfoComplexSymbol {
    complexSymbolTypeId: SymbolTypeId.UML_MESSAGE;
    template: string = getUMLMessageTemplate();
    serializer: ShapeInstanceSerializer = new ShapeInstanceSerializer();
    graph: SequenceGraph;

    public startConnection(source: MxCell, edgeType?: EdgeType, availableEdgeTypes: EdgeType[] = []) {
        const asynchMessage: EdgeType | undefined = availableEdgeTypes.find(
            (type) => type.id === SequenceEdgeTypesId.ASYNCHRON_MESSAGE,
        );
        const frameParent: MxCell | undefined = ComplexSymbolManager.getComplexSymbolRootCell(source);
        if (frameParent && asynchMessage) {
            const edgeStyle: string = asynchMessage.edgeStyle || '';
            const cellState: MxCellState = this.graph.view.getState(frameParent);
            const edgeInstance: EdgeInstanceImpl = new EdgeInstanceImpl({
                id: uuid(),
                style: edgeStyle,
                edgeType: asynchMessage,
                edgeTypeId: asynchMessage.id,
                source: frameParent.id,
                target: null,
                name: '',
                invisible: false,
            });

            const edge: MxCell = new MxCell(edgeInstance, new MxGeometry(), edgeStyle);
            edge.setEdge(true);
            const edgeState: MxCellState = this.graph.view.createState(edge);
            edgeState.style[MxConstants.STYLE_ROUNDED] = false;
            this.graph.connectionHandler.start(cellState, null, null, edgeState, [asynchMessage]);
        }
    }
}
