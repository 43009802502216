import React, { useRef, useState } from 'react';
import { TInputTypeEnum } from '../EditableText/EditableText.types';
import { EditableText } from '../EditableText/EditableText.component';
import theme from './MultiLangEditableText.component.scss';
import { MultiLangStringDialogComponent } from '../../../ObjectPropertiesDialog/components/MultiLangStringDialogComponent';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../services/LocalesService';
import { AttributeValueString, InternationalString } from '../../../../serverapi/api';
import edit from '../../../../resources/icons/edit.svg';
import { MultiLangUrlDialogComponent } from '../../../ObjectPropertiesDialog/components/MultiLangUrlDialogComponent';
import { TAttributeUrlValues } from '../../../ObjectPropertiesDialog/components/AttributeTab.types';
import classNames from 'classnames';
import { Button } from '../Button/Button.component';

type TMultiLangEditableTextProps = {
    disabled: boolean;
    allowEmptyValue?: boolean;
    record: AttributeValueString;
    onChange: (formValues: InternationalString | string | TAttributeUrlValues) => void;
    clearSelecting: () => void;
    onClickLink?: () => void;
    inputType?: TInputTypeEnum;
    dataTestContainer?: string;
    dataTestBtn?: string;
    isUrlType?: boolean;
    withoutMultiLang?: boolean;
    editable?: boolean;
    allowMultiLangEmptyValue?: boolean;
    text: string | undefined;
    className?: string;
    focusOnChange?: boolean;
};

export const MultiLangEditableText = (props: TMultiLangEditableTextProps) => {
    const {
        disabled,
        record,
        clearSelecting,
        inputType,
        allowEmptyValue,
        dataTestContainer,
        dataTestBtn,
        onChange,
        onClickLink,
        isUrlType,
        withoutMultiLang,
        editable = true,
        allowMultiLangEmptyValue,
        text,
        className,
        focusOnChange,
    } = props;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const editButtonRef = useRef<HTMLButtonElement>(null);

    const currentLocale = useSelector(getCurrentLocale);
    const curLocale: string = LocalesService.convertToString(currentLocale).toLowerCase();

    const onActivateEditable = () => {
        clearSelecting();
        setIsEditing(true);
    };
    const onDeactivateEditable = () => {
        setIsEditing(false);
    };

    const handleEditAttributeBtn = (
        e: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement, MouseEvent>,
    ) => {
        setIsEditing(true);
        e.stopPropagation();
    };

    const setFocusToButton = () => {
        focusOnChange && setTimeout(() => editButtonRef.current?.focus(), 10);
    };

    const handleChange = (text: InternationalString | string | TAttributeUrlValues) => {
        setFocusToButton();
        onChange(text);
    };

    return (
        <>
            <div className={theme.editableElementLarge} data-test={dataTestContainer}>
                <EditableText
                    text={text || ''}
                    isEditing={isEditing}
                    disabled={disabled}
                    onActivateEditable={onActivateEditable}
                    onDeactivateEditable={onDeactivateEditable}
                    onChange={handleChange}
                    allowEmptyValue={allowEmptyValue}
                    inputType={inputType}
                    isUrlType={isUrlType}
                    onClickLink={onClickLink}
                    className={className}
                />
            </div>
            {editable && (
                <div
                    className={classNames(theme.editableElementButtons, {
                        [theme.editableElementButtons_hidden]: isEditing,
                    })}
                >
                    <Button
                        ref={editButtonRef}
                        dataTest={dataTestBtn}
                        icon={edit}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleEditAttributeBtn(e);
                            }
                        }}
                        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleEditAttributeBtn(e)}
                    />
                    {!withoutMultiLang &&
                        (isUrlType ? (
                            <MultiLangUrlDialogComponent handleOk={onChange} record={record} />
                        ) : (
                            <MultiLangStringDialogComponent
                                handleOk={onChange}
                                record={record}
                                curLocale={curLocale}
                                allowEmptyValue={allowMultiLangEmptyValue}
                            />
                        ))}
                </div>
            )}
        </>
    );
};
