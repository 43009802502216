import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    FETCH_ALL_USERS,
    FETCH_USER_BYID,
    FETCH_USER_SESSIONS,
    FETCH_USERS_BY_GROUPID_REQUEST,
    OPEN_EDIT_USER_PERMISSIONS_DIALOG,
    OPEN_USERDATA_EDITING_TAB,
    OPEN_USERS_MANAGMENT_TAB,
    SUBMIT_USER_DATA,
    SET_USERS_BLOCK,
    DELETE_USERS,
} from '../actionsTypes/users.actionTypes';
import {
    editUserDataBegin,
    editUserDataEnd,
    fetchAllUsersRequest,
    fetchUserByIdSuccess,
    fetchUsersByGroupIdSuccess,
    fetchUserSessionsSuccess,
    usersFetchedSuccess,
} from '../actions/users.actions';
import {
    TDeleteUsersAction,
    TFetchAllUsersRequestAction,
    TFetchUserByIdAction,
    TFetchUsersByGroupIdAction,
    TFetchUserSessionsAction,
    TSetUsersBlockAction,
    TOpenEditUserPermissionsDialogAction,
    TOpenUserDataEditingTabAction,
    TOpenUserManagementTabAction,
    TSubmitUserDataAction,
} from '../actions/users.actions.types';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTab, TWorkspaceTabItemParams } from '../models/tab.types';
import { EditorMode } from '../models/editorMode';
import { workspaceAddTab, workspaceRemoveTabRequest } from '../actions/tabs.actions';
import { admintoolSelect } from '../actions/admintools.actions';
import { AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';
import { NodeId, UserDTO, UserSessionHistory } from '../serverapi/api';
import { MetaDataSelectors } from '../selectors/admintools.selectors';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { TabsSelectors } from '../selectors/tabs.selectors';
import userMessages from '../modules/AdminTools/UserManagement/messages/userManagment.messages';
import { userService } from '../services/dao/UsersDAOService';
import { NotificationType } from '../models/notificationType';
import { showNotificationByType } from '../actions/notification.actions';
import { ServerErrorType } from '../models/serverErrorType';
import { getUser } from '../selectors/authorization.selectors';
import { LocalesService } from '../services/LocalesService';

function* handleOpenUsersManagmentTab({ payload: { node } }: TOpenUserManagementTabAction) {
    yield put(
        admintoolSelect({
            currentServerId: node.nodeId.serverId,
            activeTypeId: AdminToolTreeType.USER_MANAGMENT,
        }),
    );

    const userManagment: NodeId = {
        id: WorkSpaceTabTypes.USER_MANAGMENT,
        repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
        serverId: node.nodeId.serverId,
    };
    yield put(
        workspaceAddTab({
            title: node.name,
            mode: EditorMode.Read,
            params: {
                closable: false,
                serverId: node.nodeId.serverId,
            } as TWorkspaceTabItemParams,
            actions: { ...defaultWorkspaceTabActions },
            type: WorkSpaceTabTypes.ADMINTOOLS_TAB,
            nodeId: userManagment,
        }),
    );
}

function* handleFetchAllUsersRequest({ payload: { serverId } }: TFetchAllUsersRequestAction) {
    const users: UserDTO[] = yield call(() => userService().getAll({ serverId }));
    if (users) {
        yield put(usersFetchedSuccess({ serverId, users }));
    }
}

function* handleOpenUserEditingTab({ payload: { userData, mode } }: TOpenUserDataEditingTabAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    if (!serverId) {
        throw new Error(`Cannot find server`);
    }
    const intl = LocalesService.useIntl();
    yield put(admintoolSelect({ currentServerId: serverId, activeTypeId: AdminToolTreeType.USERDATA_EDITING_TAB }));
    yield put(editUserDataBegin(userData));
    const contentLoadingPageTab: TWorkspaceTab = {
        title: mode === 'edit' ? `${userData.login}` : intl.formatMessage(userMessages.newUser),
        nodeId: { id: `${userData.id}`, repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT, serverId },
        type: WorkSpaceTabTypes.USERDATA_EDITING_TAB,
        mode: EditorMode.Read,
        params: {
            closable: false,
            serverId,
        } as TWorkspaceTabItemParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* handleSetUsersBlock({ payload: { usersId, blockStatus } }: TSetUsersBlockAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    const currentUser: UserDTO | undefined = yield select(getUser);
    const availableUsersId = usersId.filter((id) => id !== currentUser?.id);
    const userId = usersId.find((id) => id === currentUser?.id);
    yield call(() => userService().setBlockStatus(availableUsersId, blockStatus, serverId));
    if (userId) {
        yield call(() => userService().setBlockStatus([userId], blockStatus, serverId));
    }
    yield put(fetchAllUsersRequest({ serverId }));
}

function* handleSubmitUserData({ payload: { userData, isNewUser } }: TSubmitUserDataAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    let userSaved: UserDTO | null = null;
    try {
        if (isNewUser) {
            userSaved = yield call(() =>
                userService().create({
                    user: userData,
                    serverId,
                }),
            );
        } else {
            userSaved = yield call(() =>
                userService().save({
                    user: userData,
                    serverId,
                }),
            );
        }
    } catch (e) {
        if (e.status === ServerErrorType.DUPLICATE_ENTITY) {
            yield put(showNotificationByType(NotificationType.USER_LOGIN_CONFLICT));
        } else {
            throw e;
        }
    }

    if (userSaved && userSaved.id) {
        yield put(fetchUserByIdSuccess({ userData: userSaved, serverId }));
        yield put(editUserDataEnd(userSaved.id));

        const tab: TWorkspaceTab = yield select(
            TabsSelectors.byId({
                id: `${userData.id}`,
                repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
                serverId,
            } as NodeId),
        );
        yield put(workspaceRemoveTabRequest(tab));
    }
}

function* handleFetchUserById({ payload: { userId } }: TFetchUserByIdAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);

    const userData: UserDTO = yield call(() => userService().get({ id: userId, serverId }));
    if (userData) {
        yield put(fetchUserByIdSuccess({ userData, serverId }));
    }
}

function* handlefetchUsersByGroupIdRequest({ payload: { serverId, groupId } }: TFetchUsersByGroupIdAction) {
    const users: UserDTO[] = yield call(() => userService().getByGroup({ groupId, serverId }));
    if (users) {
        yield put(fetchUsersByGroupIdSuccess({ users, groupId, serverId }));
    }
}

function* handleOpenPermissionDialog({ payload: { userId, dialogTypeId } }: TOpenEditUserPermissionsDialogAction) {
    switch (dialogTypeId) {
        case DialogType.EDIT_ASSIGN_USER_LICENSE:
            yield put(openDialog(DialogType.EDIT_ASSIGN_USER_LICENSE, { userId }));
            break;
        case DialogType.EDIT_ASSIGN_USER_GROUPS:
            yield put(openDialog(DialogType.EDIT_ASSIGN_USER_GROUPS, { userId }));
            break;
        case DialogType.EDIT_USER_DBACCESS_PERMISSIONS:
            yield put(openDialog(DialogType.EDIT_USER_DBACCESS_PERMISSIONS, { userId }));
            break;
        case DialogType.EDIT_USER_FUNCTIONAL_PERMISSIONS:
            yield put(openDialog(DialogType.EDIT_USER_FUNCTIONAL_PERMISSIONS, { userId }));
            break;
        default:
            break;
    }
}

function* hadleDeleteUsersRequest({ payload: { usersId } }: TDeleteUsersAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    const currentUser: UserDTO | undefined = yield select(getUser);
    const availableUsersId = usersId.filter((id) => id !== currentUser?.id);
    const currentUserId = usersId.find((id) => id === currentUser?.id);
    yield call(() => userService().delete({ usersId: availableUsersId, serverId }));
    if (currentUserId) yield call(() => userService().delete({ usersId: [currentUserId], serverId }));
    yield put(fetchAllUsersRequest({ serverId }));
}

function* fetchUserSessions({ payload: { userId } }: TFetchUserSessionsAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    const sessions: UserSessionHistory[] = yield call(() => userService().sessionHistory({ id: userId, serverId }));
    yield put(fetchUserSessionsSuccess(userId, sessions));
}

export function* usersSaga() {
    yield takeEvery(OPEN_USERS_MANAGMENT_TAB, handleOpenUsersManagmentTab);
    yield takeEvery(OPEN_USERDATA_EDITING_TAB, handleOpenUserEditingTab);
    yield takeEvery(SET_USERS_BLOCK, handleSetUsersBlock);
    yield takeEvery(SUBMIT_USER_DATA, handleSubmitUserData);
    yield takeEvery(FETCH_USER_BYID, handleFetchUserById);
    yield takeEvery(FETCH_USERS_BY_GROUPID_REQUEST, handlefetchUsersByGroupIdRequest);
    yield takeEvery(OPEN_EDIT_USER_PERMISSIONS_DIALOG, handleOpenPermissionDialog);
    yield takeEvery(DELETE_USERS, hadleDeleteUsersRequest);
    yield takeEvery(FETCH_USER_SESSIONS, fetchUserSessions);
    yield takeEvery(FETCH_ALL_USERS, handleFetchAllUsersRequest);
}
