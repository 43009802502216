import React, { FC, useEffect, useRef } from 'react';
import theme from './PieChart.scss';
import messages from '../widget.messages';
import { useIntl } from 'react-intl';
import { TChartData, TWidgetProps } from '../widget.types';
import {
    Chart,
    LinearScale,
    CategoryScale,
    PointElement,
    ChartConfiguration,
    ChartData,
    Legend,
    Title,
    PieController,
    ArcElement,
} from 'chart.js';
import { Col } from 'antd';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const PieChart: FC<TWidgetProps> = ({ data, title }) => {
    const intl = useIntl();
    const { datasets, labels } = data as TChartData;
    datasets[0].borderColor = '#fff';
    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        Chart.register(
            PieController,
            LinearScale,
            ArcElement,
            CategoryScale,
            PointElement,
            Legend,
            Title,
            ChartDataLabels,
        );
    }, []);

    const chartData: ChartData<any, any[], any> = {
        labels: labels,
        datasets,
    };

    const config: ChartConfiguration<any, any[], any> = {
        type: 'doughnut',
        data: chartData,
        plugins: [ChartDataLabels],
        options: {
            plugins: {
                legend: {
                    position: 'bottom',
                    display: true,
                    labels: {
                        filter: (item) => !!item.text,
                        usePointStyle: true,
                        boxWidth: 10,
                        pointStyle: 'rect',
                        boxHeight: 10,
                    },
                    title: {
                        display: true,
                    },
                },
                title: {
                    display: true,
                    text: title,
                    padding: {
                        bottom: 20,
                        top: 5,
                    },
                },
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                },
            },
            offset: 10,
            maintainAspectRatio: false,
            events: [],
        },
    };

    useEffect(() => {
        let lineChart: Chart<any, number[], string> | null = null;
        if (ref.current) {
            lineChart = new Chart(ref.current, config);
        }
        return () => {
            lineChart && lineChart.destroy();
        };
    }, [ref, data, title]);

    const checkData = datasets.some(({ data }) => data.length);

    return (
        <div className={theme.lineChartContainer}>
            {checkData ? (
                <canvas ref={ref}></canvas>
            ) : (
                <Col style={{ width: '100%' }}>
                    <div className={theme.header}>{title}</div>
                    <div className={theme.text}>{intl.formatMessage(messages.noData)}</div>
                </Col>
            )}
        </div>
    );
};
