import { Modal } from 'antd';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { shouldCallModalSubmitHandler } from '../../../../../../../services/utils/ModalHelper';
import { TCommonType, TGroup } from '../../util/GroupedTypesTable.types';
import messages from './GroupSelectionDialog.messages';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TDialogProps = WrappedComponentProps & {
    groups: TCommonType[];
    onSubmit: (groupId?: TCommonType) => void;
    onClose: () => void;
};

const GroupSelectionDialogComponent = (props: TDialogProps) => {
    const { intl, onClose, onSubmit, groups } = props;
    const [groupId, setGroupId] = useState<string>('');
    const currentLocale = useSelector(getCurrentLocale);

    const selectedGroup: TCommonType | undefined = groups.find((group) => group.id === groupId);
    const selectValue: string = LocalesService.internationalStringToString(
        selectedGroup?.multilingualName,
        currentLocale,
    );

    const onOk = () => onSubmit(selectedGroup);

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onOk,
                    value: intl.formatMessage(messages.move),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    onSubmit(selectedGroup);
                }
            }}
        >
            <Modal
                open
                onOk={onOk}
                onCancel={onClose}
                title={intl.formatMessage(messages.groupSelectionDialog)}
                footer={footer}
            >
                <Select
                    originalTheme
                    label={intl.formatMessage(messages.selectGroupTitle)}
                    value={selectValue}
                    onChange={(val: string) => setGroupId(val)}
                >
                    {groups.map((group: TGroup) => {
                        return (
                            <Select.Option
                                key={group.id}
                                value={group.id}
                                label={LocalesService.internationalStringToString(
                                    group.multilingualName,
                                    currentLocale,
                                )}
                            />
                        );
                    })}
                </Select>
            </Modal>
        </div>
    );
};

const IntlComponent = injectIntl(GroupSelectionDialogComponent);

export { IntlComponent as GroupSelectionDialog };
