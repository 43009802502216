import type {
    TRestoreDeletedNodeDialogSubmitActionPayload,
    TRestoreDeletedNodeDialogSubmitAction,
} from './restoreDeletedNodeDialog.actions.types';
import { RESTORE_DELETED_NODE_DIALOG_SUBMIT } from '../actionsTypes/restoreDeletedNodeDialog.actionTypes';

export const restoreDeletedNodeDialogSubmit = (
    payload: TRestoreDeletedNodeDialogSubmitActionPayload,
): TRestoreDeletedNodeDialogSubmitAction => ({
    type: RESTORE_DELETED_NODE_DIALOG_SUBMIT,
    payload,
});
