import { defineMessages } from 'react-intl';

export default defineMessages({
    generalSettings: {
        id: 'ReportModelType.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    name: {
        id: 'ReportModelType.name',
        defaultMessage: 'Название',
    },
    description: {
        id: 'ReportModelType.description',
        defaultMessage: 'Описание',
    },
    modelTypeGroups: {
        id: 'ReportModelType.modelTypeGroups',
        defaultMessage: 'Группа типа модели',
    },
    attributes: {
        id: 'ReportModelType.attributes',
        defaultMessage: 'Атрибуты справочника',
    },
    navigatorTitle: {
        id: 'ReportModelType.navigatorTitle',
        defaultMessage: 'Тип модели',
    },
    enableModel: {
        id: 'ReportModelType.enableModel',
        defaultMessage: 'Видимость типа модели при создании',
    },
    allowApprovals: {
        id: 'ReportModelType.allowApprovals',
        defaultMessage: 'Разрешить согласование типа модели',
    },
    modelType: {
        id: 'ReportModelType.modelType',
        defaultMessage: 'Вид модели',
    },
    report: {
        id: 'ReportModelType.report',
        defaultMessage: 'Справочник',
    },
});
