import { NotificationTemplateDTO } from '@/serverapi/api';
import { TReducer } from '@/utils/types';
import { TNotificationTemplatesState } from './notificationTemplates.reducer.types';
import { ADD_NOTIFICATION_TEMPLATES } from '@/actionsTypes/notificationTemplates.actionTypes';

const initial: NotificationTemplateDTO[] = [];

export const notificationTemplatesReducer: TReducer<TNotificationTemplatesState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION_TEMPLATES: {
            const {
                payload: { notificationTemplates },
            } = action;

            return notificationTemplates;
        }

        default:
            return state;
    }
};
