import React, { FC } from 'react';
import theme from './ApprovalsTab.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { hasModelEditorLicense } from '@/selectors/authorization.selectors';
import icApprovalsNotFound from '../../../../resources/icons/ic-aprovals-not-found.svg';
import icCreateApproval from '../../../../resources/icons/ic-create-approval.svg';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import messages from './ApprovalsTab.messages';
import { createApproval, openApprovalTemplateDialog } from '@/actions/approval.actions';
import { NodeId } from '@/serverapi/api';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { Approval } from './Approval.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TApprovalsTab = {
    approvedItemNodeId: NodeId;
    toggledApprovalId: string;
};

export const ApprovalsTab: FC<TApprovalsTab> = ({ approvedItemNodeId, toggledApprovalId }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const isModelEditor = useSelector(hasModelEditorLicense);
    const approvals = useSelector(ApprovalSelectors.byApprovedItemIdArrNoDraft(approvedItemNodeId));

    const onCreateApprovalClick = () => {
        dispatch(createApproval({ approvedItemNodeId }));
    };

    const onApprovalTemplateSelectClick = () => {
        dispatch(openApprovalTemplateDialog({ approvedItemNodeId }));
    };

    return (
        <>
            {approvals.length === 0 ? (
                <div className={theme.approvalsNotFoundContainer}>
                    <Icon spriteSymbol={icApprovalsNotFound} className={theme.approvalNotFoundIcon} />
                    <div>{intl.formatMessage(messages.approvalIsNotCreatedYet)}</div>
                    {isModelEditor ? (
                        <>
                            <Button visualStyle={{type: "text"}} onClick={onCreateApprovalClick} icon={icCreateApproval}>
                                {intl.formatMessage(messages.createApproval)}
                            </Button>
                            <Button
                                visualStyle={{type: "text"}}
                                onClick={onApprovalTemplateSelectClick}
                                icon={icCreateApproval}
                            >
                                {intl.formatMessage(messages.chooseTemplate)}
                            </Button>
                        </>
                    ) : null}
                </div>
            ) : (
                <div className={theme.approvals}>
                    <div className={theme.buttonWrapper}>
                        <Button onClick={onCreateApprovalClick} visualStyle={{type: "text"}} icon={icCreateApproval}>
                            {intl.formatMessage(messages.newApproval)}
                        </Button>
                        <Button onClick={onApprovalTemplateSelectClick} visualStyle={{type: "text"}} icon={icCreateApproval}>
                            {intl.formatMessage(messages.chooseTemplate)}
                        </Button>
                    </div>
                    {approvals.map((approval) => {
                        return (
                            <Approval
                                approval={approval}
                                key={approval.id.id}
                                approvedItemId={approvedItemNodeId.id}
                                toggled={toggledApprovalId === approval.id.id}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};
