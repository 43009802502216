// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconWithText__menuIcon__bddxi{display:inline-block;margin-right:8px;height:16px;width:16px;vertical-align:middle}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/IconWithText/IconWithText.scss"],"names":[],"mappings":"AAAA,+BACE,oBAAA,CACA,gBAAA,CACA,WAAA,CACA,UAAA,CACA,qBAAA","sourcesContent":[".menuIcon {\r\n  display: inline-block;\r\n  margin-right: 8px;\r\n  height: 16px;\r\n  width: 16px;\r\n  vertical-align: middle;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuIcon": "IconWithText__menuIcon__bddxi"
};
export default ___CSS_LOADER_EXPORT___;
