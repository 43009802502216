import type { TImageUploadDialogProps, TImageUploadDialogState } from '../types/FileUploadDialog.types';
import * as React from 'react';
import FileUploadDialog from './FileUploadDialog.component';
import messages from '../messages/FileUploadDialog.messages';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './FileUploadDialog.scss';
import { injectIntl } from 'react-intl';
import { Input } from 'antd';

class ImageUploadDialog extends FileUploadDialog<TImageUploadDialogProps, TImageUploadDialogState> {
    state: TImageUploadDialogState = {
        alt: '',
        title: '',
    };

    handleSubmit = () => {
        const { file, alt, title } = this.state;

        if (file) {
            this.props.onSubmit(file, alt, title);
        }
    };

    renderInputs() {
        const { intl } = this.props;
        const { alt, title } = this.state;

        return (
            <div>
                <div className={theme.fieldsContainer}>
                    {intl.formatMessage(messages.altLabel)}
                    <Input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({
                                alt: e.currentTarget.value,
                            })
                        }
                        value={alt || ''}
                        className={theme.input}
                    />
                </div>
                <div className={theme.fieldsContainer}>
                    {intl.formatMessage(messages.titleLabel)}
                    <Input
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({
                                title: e.currentTarget.value,
                            })
                        }
                        value={title || ''}
                        className={theme.input}
                        data-test="dragger_file-downloaded"
                    />
                </div>
            </div>
        );
    }

    render() {
        const { open, intl, onClose } = this.props;

        return (
            <Dialog
                className={theme.dialog}
                onCancel={onClose}
                open={open}
                title={intl.formatMessage(messages.dialogTitle)}
                footer={this.renderFooter()}
            >
                {this.renderDropper()}
                {this.renderInputs()}
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ImageUploadDialog);

export { ImageUploadDialog as default, IntlComponent as ImageUploadDialog };

