import { call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { addPresetProfile, editPresetProfile } from '../actions/presetProfile.actions';
import {
    TCopyPresetProfileAction,
    TOpenEditPresetProfileTabAction,
    TSavePresetProfileAction,
} from '../actions/presetProfile.actions.types';
import { workspaceAddTab, workspaceRemoveTabByNodeId } from '../actions/tabs.actions';
import {
    COPY_PRESET_PROFILE,
    OPEN_EDIT_PRESET_PROFILE_TAB,
    SAVE_PRESET_PROFILE,
} from '../actionsTypes/presetProfile.actionTypes';
import { EditorMode } from '../models/editorMode';
import { TServerEntity } from '../models/entities.types';
import { defaultWorkspaceTabActions } from '../models/tab';
import { IWorkspaceTabPresetProfileParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { LocalesService } from '../services/LocalesService';
import messages from '../modules/AdminTools/Methodology/components/Presets/PresetProfile/PresetProfileTab/PresetProfileTab.messages';
import { TPresetProfileEntityTypes } from '../modules/AdminTools/Methodology/components/Presets/PresetProfile/presetProfile.types';
import { InternationalString, PresetProfile, PresetTypesAcl } from '../serverapi/api';
import { getAclsByTypeSuccess } from '../actions/acl.actions';
import { TPresetId } from '../modules/AdminTools/Methodology/components/Presets/TPresetId.types';
import { MethodologySettingDaoService } from '../services/dao/MethodologySettingDaoService';

function* getProfileAcls(serverId: string, presetId: string, profileId: string) {
    const aclsTypes: string[] = Object.keys(TPresetProfileEntityTypes);
    for (const type of aclsTypes) {
        const acls: PresetTypesAcl[] = yield MethodologySettingDaoService.getAclsByType(
            serverId,
            presetId,
            profileId,
            TPresetProfileEntityTypes[type],
        );
        yield put(
            getAclsByTypeSuccess({
                acls,
                presetId,
                serverId,
                type: TPresetProfileEntityTypes[type],
                profileId,
            }),
        );
    }
}

function* handleOpenEditPresetProfileTab(action: TOpenEditPresetProfileTabAction) {
    const { preset, mode, serverNode, copiedPresetProfileId } = action.payload;
    const { serverId } = serverNode.nodeId;
    const { id: presetId } = preset;
    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);
    const multilingualName: InternationalString | undefined = action.payload.presetProfile?.multilingualName;

    const presetProfile = action.payload.presetProfile || {
        id: uuid(),
        name: '',
        presetId,
        description: '',
    };

    // Если presetProfile новый - создаем для него дефолтные Acls для всех TPresetProfileEntityTypes и помещаем в стор
    // иначе загружаем с бэка и помещаем в стор
    if (!action.payload.presetProfile) {
        const aclsTypes: string[] = Object.keys(TPresetProfileEntityTypes);
        for (const type of aclsTypes) {
            const acls: PresetTypesAcl[] = [];
            yield put(
                getAclsByTypeSuccess({
                    acls,
                    presetId,
                    serverId,
                    type: TPresetProfileEntityTypes[type],
                    profileId: presetProfile.id,
                }),
            );
        }
    } else {
        yield getProfileAcls(serverId, presetId, presetProfile.id);
    }

    const tabTitleSuffix = multilingualName
        ? `: ${LocalesService.internationalStringToString(multilingualName, currentLocale)}`
        : '';

    const editProfilePageTab: TWorkspaceTab & TPresetId = {
        title: `${intl.formatMessage(messages[`editProfile.${mode}`])}${tabTitleSuffix}`,
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${presetProfile.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_PRESET_PROFILE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            mode,
            serverNode,
            preset,
            presetProfile,
            copiedPresetProfileId,
        } as IWorkspaceTabPresetProfileParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(editProfilePageTab));
}

function* handleSavePresetProfile({ payload }: TSavePresetProfileAction) {
    const { serverNode, presetProfile, mode, tabNodeId } = payload;
    const { presetId } = presetProfile;
    const { serverId } = serverNode.nodeId;
    const server: TServerEntity = yield select(ServerSelectors.server(payload.serverNode.nodeId.serverId));

    if (serverNode) {
        yield call(() => server.api.profile.saveProfiles({ body: presetProfile }));
        if (mode === 'create') {
            yield put(addPresetProfile({ presetProfile, serverId, presetId }));
        } else if (mode === 'edit') {
            yield put(editPresetProfile({ presetProfile, serverId, presetId }));
        }
        if (tabNodeId) yield put(workspaceRemoveTabByNodeId(tabNodeId));
    }
}

function* handleCopyPresetProfile({ payload }: TCopyPresetProfileAction) {
    const { serverNode, presetProfile, copiedPresetProfileId, presetId, tabNodeId } = payload;
    const { serverId } = serverNode.nodeId;
    const server: TServerEntity = yield select(ServerSelectors.server(payload.serverNode.nodeId.serverId));

    if (serverNode) {
        const savedProfile: PresetProfile = yield call(() =>
            server.api.profile.copy({ presetId, profileId: copiedPresetProfileId, body: presetProfile }),
        );
        yield put(addPresetProfile({ presetProfile: savedProfile, serverId, presetId }));
        yield getProfileAcls(serverId, presetId, presetProfile.id);
        if (tabNodeId) yield put(workspaceRemoveTabByNodeId(tabNodeId));
    }
}

export function* presetProfileSaga() {
    yield takeEvery(SAVE_PRESET_PROFILE, handleSavePresetProfile);
    yield takeEvery(OPEN_EDIT_PRESET_PROFILE_TAB, handleOpenEditPresetProfileTab);
    yield takeEvery(COPY_PRESET_PROFILE, handleCopyPresetProfile);
}
