type TElement = number | string;

/**
 * Функция проверяет содержится ли элемент в массиве и возвращает массив, из которого исключен этот элемент если он там был
 * или массив с этим элементом, если в массиве его не было
 *  */

export const toggleArrayItem = <T extends TElement>(element: T, array: T[] = []): T[] => {
    if (array.includes(element)) {
        return array.filter((selectedElement) => selectedElement !== element);
    }

    return [...array, element];
};
