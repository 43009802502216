import type { EdgeDefinitionNode, NodeId, ObjectDefinitionNode } from '@/serverapi/api';
import type { MxCell } from 'MxGraph';
import { put, select, takeEvery } from 'redux-saga/effects';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { EDGE_MANAGEMENT_DIALOG_OPEN } from '@/actionsTypes/edgeManagement.actionTypes';
import { getActiveGraph } from '@/selectors/editor.selectors';
import { instancesBPMMxGraphMap } from '@/mxgraph/bpm-mxgraph-instance-map';
import { EdgeDefinitionDAOService } from '@/services/dao/EdgeDefinitionDAOService';
import { objectDefinitionService } from '@/services/ObjectDefinitionService';
import { nodeService } from '@/services/NodeService';

function* handleViewEdgeManagementDialog() {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    const activeGraph = activeGraphId && instancesBPMMxGraphMap.get(activeGraphId);

    if (!activeGraph) {
        return;
    }

    const objectDefinitions: ObjectDefinitionNode[] = objectDefinitionService().findAllObjectsInGraph(activeGraphId);
    const objectDefinitionsIds = objectDefinitions.map(({ nodeId }) => nodeId.id);
    const edgeDefinitions: EdgeDefinitionNode[] = yield EdgeDefinitionDAOService.searchExistingEdgeDefinitions(
        activeGraphId,
        objectDefinitionsIds,
        objectDefinitionsIds,
    );
    const applicableEdgeDefinitions: EdgeDefinitionNode[] = edgeDefinitions.filter(({ edgeTypeId }) =>
        activeGraph.modelType?.edgeTypes.some((el) => el.id === edgeTypeId),
    );

    const edgeDefinitionsData = applicableEdgeDefinitions.map((el) => {
        const edgeInstances: MxCell[] = nodeService().findModelCellsByDefinitionId(el.nodeId.id, activeGraph);
        const edgeType = activeGraph.modelType?.edgeTypes.find(({ id }) => id === el.edgeTypeId);
        const sourceObjectDefinition = objectDefinitions.find(
            ({ nodeId }) => nodeId.id === el.sourceObjectDefinitionId,
        );
        const targetObjectDefinition = objectDefinitions.find(
            ({ nodeId }) => nodeId.id === el.targetObjectDefinitionId,
        );

        return {
            edgeDefinition: el,
            edgeInstances,
            edgeType,
            sourceObjectDefinition,
            targetObjectDefinition,
        };
    });

    yield put(openDialog(DialogType.EDGE_MANAGEMENT_DIALOG, { data: edgeDefinitionsData }));
}

export function* initEdgeManagementDialog() {
    yield takeEvery(EDGE_MANAGEMENT_DIALOG_OPEN, handleViewEdgeManagementDialog);
}

