import React, { ReactNode } from 'react';
import cn from 'classnames';
import style from './InformationItem.scss';

export enum ColorEnum {
    RED = 'red',
    GREEN = 'green',
    YELLOW = 'yellow',
    GRAY = 'gray',
}

type TInformationItemProps = {
    color: ColorEnum;
    children: ReactNode;
};

export const InformationItem = ({ color, children }: TInformationItemProps) => {
    return (
        <div className={style.item}>
            <span data-test={`${children}_${color}`}
                className={cn(style.dot, {
                    [style.red]: color === ColorEnum.RED,
                    [style.green]: color === ColorEnum.GREEN,
                    [style.yellow]: color === ColorEnum.YELLOW,
                    [style.gray]: color === ColorEnum.GRAY,
                })}
            />
            <h3>{children}</h3>
        </div>
    );
};
