import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'MoveLayerSelect.title',
        defaultMessage: 'Переместить',
    },
    moveLevelUp: {
        id: 'MoveLayerSelect.moveLevelUp',
        defaultMessage: 'Переместить вперед',
    },
    moveLevelTop: {
        id: 'MoveLayerSelect.moveLevelTop',
        defaultMessage: 'На передний план',
    },
    moveLevelDown: {
        id: 'MoveLayerSelect.moveLevelDown',
        defaultMessage: 'Переместить назад',
    },
    moveLevelBottom: {
        id: 'MoveLayerSelect.moveLevelBottom',
        defaultMessage: 'На задний план',
    },
    selectElementInEditMode: {
        id: 'MoveLayerSelect.selectElementInEditMode',
        defaultMessage: 'Выберите элемент в режиме редактирования',
    },
    selectElementOnCanvas: {
        id: 'MoveLayerSelect.selectElementOnCanvas',
        defaultMessage: 'Выберите элемент на холсте',
    },
});
