import { SCRIPT_DASHBOARD_DOWNLOAD_LOG } from "@/actionsTypes/scriptDashboard.actionTypes";
import { TLogFileDownloadAction } from "./scriptDashboard.actions.types";

export const downloadLogFile = (operationId: string, serverId: string): TLogFileDownloadAction => ({
    type: SCRIPT_DASHBOARD_DOWNLOAD_LOG,
    payload: {
        operationId,
        serverId
    },
});
