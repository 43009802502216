import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import icRemoveApprovalStage from '../../resources/icons/ic-remove-approval-stage.svg';
import icMoveUpApprovalStage from '../../resources/icons/ic-approval-arrow-up.svg';
import icMoveDownApprovalStage from '../../resources/icons/ic-approval-arrow-down.svg';
import theme from './ApprovalDialog.scss';
import messages from './ApprovalDialog.messages';
import { TStageTab } from './ApprovalDialog.types';
import { Button } from '../UIKit/components/Button/Button.component';

export const StageTab: FC<TStageTab> = (props) => {
    const { 
        stage,
        index,
        arr,
        isNotMovableUp,
        isNotMovableDown,
        isNotDeletable,
        onStageMove,
        onStageRemove
    } = props;

    const intl = useIntl();

    return (
        <div className={theme.stage}>
            <div title={stage.name} className={theme.stageTabPane}>
                {stage.name || intl.formatMessage(messages.newStage)}
            </div>
            <div className={theme.flex}>
                {index > 0 && !isNotMovableUp ? (
                    <Button
                        visualStyle="text"
                        size="small"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onStageMove(index, true);
                        }}
                        icon={icMoveUpApprovalStage}
                    />
                ) : null}

                {index < arr.length - 1 && !isNotMovableDown ? (
                    <Button
                        visualStyle="text"
                        size="small"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onStageMove(index, false);
                        }}
                        icon={icMoveDownApprovalStage}
                    />
                ) : null}
                {!isNotDeletable ? (
                    <Button
                        visualStyle="text"
                        size="small"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onStageRemove(stage.id);
                        }}
                        icon={icRemoveApprovalStage}
                    />
                ) : null}
            </div>
        </div>
    );
};
