import {
    SERVER_ADD_BATCH,
    SERVER_DELETE,
    SERVER_EDIT,
    SERVER_EDIT_SUCCESS,
    SERVER_CHANGE_ONLINE_STATUS,
    OPEN_SERVER_AUTH_DIALOG,
    CLEAR_SERVERS_SESSION,
} from '../../actionsTypes/entities/servers.actionTypes';
import { TServerEntity } from '../../models/entities.types';
import { TServerForm } from '../../modules/ServerForm/ServerForm.types';
import {
    TPartialServerEntity,
    TServerAddBatchAction,
    TServerEditAction,
    TServerEditSuccessAction,
    TServerDeleteAction,
    TServerChangeOnlineStatusAction,
    TOpenAuthServerDialogAction,
    TClearServersSession,
} from './servers.actions.types';

export const addServerBatch = (servers: TServerEntity[]): TServerAddBatchAction => ({
    type: SERVER_ADD_BATCH,
    payload: {
        servers,
    },
});

export const editServer = (entity: TServerForm): TServerEditAction => ({
    type: SERVER_EDIT,
    payload: {
        entity,
    },
});

export const editServerSuccess = (entity: TPartialServerEntity): TServerEditSuccessAction => ({
    type: SERVER_EDIT_SUCCESS,
    payload: {
        entity,
    },
});

export const deleteServer = (serverId: string): TServerDeleteAction => ({
    type: SERVER_DELETE,
    payload: serverId,
});

export const changeOnlineServerStatus = (id: string, online: boolean): TServerChangeOnlineStatusAction => ({
    type: SERVER_CHANGE_ONLINE_STATUS,
    payload: { id, online },
});

export const openAuthServerDialog = (serverId: string): TOpenAuthServerDialogAction => ({
    type: OPEN_SERVER_AUTH_DIALOG,
    payload: { serverId },
});

export const clearServersSession = (): TClearServersSession => ({
    type: CLEAR_SERVERS_SESSION,
});

