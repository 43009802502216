import * as Stomp from 'stompjs';

export class Subscription implements Stomp.Subscription {
    readonly endpointId: string;
    readonly id: string;
    readonly msgHandler: (msg: any) => void;
    readonly unsubscribe: () => void

    constructor(endpointId: string, subscriptionId: string, msgHandler: (msg: any) => void, unsubscribe: () => void) {
        this.endpointId = endpointId;
        this.id = subscriptionId;
        this.msgHandler = msgHandler;
        this.unsubscribe = unsubscribe;
    }
}


